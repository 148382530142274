import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LoaderUI from "../shared/loader/index";
import { Button } from "reactstrap";
// import FormBottomMessage from "../components/Auth/FormBottomMessage";
import FormBottomMessage from "../components/auth-module/FormBottomMessage";
import AppLogo from "../shared/AppLogo";
import {auth} from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const PublicLayout = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  let loggedIn = useSelector((state) => state.user.loggedIn);
  let registered = useSelector((state) => state.user.registered);
  // let isLoading = useSelector(state => state.general.loading);
  let userDetails = useSelector((state) => state.user.user_details);
  let clinic_details = useSelector(state => state.clinicReducer)
  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;

  const [user, loading, error] = useAuthState(auth);
  
  // if (registered) {
  // 	history.push('/pricing')
  // 	return null;
  // }


  useEffect(() => {

  if (loggedIn && userDetails && user && !loading){

      
    if (permissions && !permissions?.active && userDetails.role !== "pet-owner") {

      console.log('sending to no access from public', !(!permissionsData), permissions, userDetails, loggedIn);
      history.push("/no-access")
      //return null
    }
    else if(userDetails.role === 'pet-owner'){
      history.push('/pet-owner-health-snapshot')
      //return null
    }
  
    else if (
      (permissions?.active)
      // ||      userDetails.role === "sub-admin"
    ) {
      history.push("/appointments");
      //return null;
    } else if (userDetails.role === "super-admin") {
      history.push("/super-admin/clinic");
    } else if (
    (  userDetails.role === "clinic" ||
      userDetails.role === "clinic-admin" ||
      userDetails.role === "clinic-staff")
    ) {
      if (userDetails.payment_verified) {
        history.push("/clinic/dashboard");
        //return null;
      } else {
        history.push("/clinic/pricing");
      }
    } else if (userDetails.role === "pet-owner") {
      history.push("/pet-owner-health-snapshot");
    } else {
      if (userDetails.payment_verified) {
        history.push("/patients");
        //return null;
      } else {
        if (userDetails.signup_completed) {
          history.push("/patients");
        } else {
          if (userDetails.is_registered) {
            // if (userDetails.selected_plan) {
            if (userDetails.is_pet_added) {
              history.push("/signup/add-more-pet");
            } else {
              history.push("/signup/add-pets");
            }
            // } else {
            // 	history.push("/signup/select-plan")
            // }
          }
        }
      }
    }
  }

  else {
    dispatch({type: 'SET_NEW_PET_MODAL', payload: false})



  }



},[userDetails, loggedIn, permissions, user, loading] )
  return (
    <>
      <div className="signup-wrapper d-flex flex-fill">
        {children}
        {/* <div className="col-blc right-col"> */}
        {/* {isLoading && <LoaderUI
						loader={isLoading}
						overlay={true}
						overlayRadius='rounded-0'
						size=""
					/>} */}

        {/* <Link to="/register" >Create an Account</Link> */}
        {/* <FormBottomMessage text={'Don’t have an account?'} link={'Register here'} from={'login'} /> */}
        {/* </div> */}
      </div>
    </>
  );
};

PublicLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,
};

PublicLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
};

export default PublicLayout;
