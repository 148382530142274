import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Input } from 'reactstrap';
import './index.scss';

const SearchBlock2 = ({ className = '', placeholder = '', onChange = () => { } }) => {
	return (
		<div className={`search-blc2 position-relative ${className}`} onChange={onChange}>
			<Input type="text" placeholder={placeholder} />
			<span className='position-absolute d-inline-flex align-items-center justify-content-center'>
				<FontAwesomeIcon icon={faSearch} />
			</span>
		</div>
	);
}

export default SearchBlock2;