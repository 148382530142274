import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import Accordions from "./Accordions";
import NodataFoundBlock from "../../../../../shared/NoDataFoundBlock";
import { getOrdersWithCategoryByPetId } from "../../../../../context/actions/patient-module/orders";
import { useDispatch, useSelector } from "react-redux";
import LoaderUI from "../../../../../shared/loader";
import { useParams } from "react-router-dom";
import OrderFormPanel from "./OrderFormPanel";
import './index.scss';


/**
 * Orders page, contains lists of orders for patient within patient details, also contains flow for creating new orders
 * @returns {JSX.Element} Component template
 */

const Orders = () => {

	const [loadingOverlay, setLoadingOverlay] = useState(false)

	const { permissionsData } = useSelector((state) => state.clinicPermissionReducer);
	const permissions = permissionsData;

	const dispatch = useDispatch();

	const params = useParams();

	const petId = params.petId


	const { orders, loading } = useSelector(state => state.orderReducer)

	const [orderPanelState, setOrderPanelState] = useState(false)


	const handleGetOrders = () => {
		dispatch(getOrdersWithCategoryByPetId(petId, () => { }))
	}


	useEffect(() => {
		handleGetOrders();
		
	}, [])


	return (
		<>
			{loadingOverlay && <LoaderUI overlay={true} />}
			{loading && <LoaderUI />}
			<div className="orders-block">
				<div className="d-flex flex-row justify-content-between">
					
					<div className="ms-auto d-flex flex-row">
						

						{permissions?.edit_orders && <Button onClick={() => { setOrderPanelState(true) }} color="link" className="add-btn d-flex align-items-center ms-auto">
							<span className="material-icons">
								add_circle
							</span>
						</Button>}
					</div>
				</div>
				<div className="orders-listing-wrapper">
					{(orders?.length <= 0) ? <NodataFoundBlock /> :
						<table className="table">

							<tbody>
								<tr>
									<td colSpan="7" className="p-0">

										<div className="table-view-blc">
											<Accordions data={orders} />
										</div>

									</td>
								</tr>
							</tbody>
						</table>
					}
				</div>
			</div>

			{orderPanelState &&
				<>
					<div onClick={() => { setOrderPanelState(!orderPanelState) }}
						className={`new-order-panel-overlay position-fixed ${orderPanelState ? 'active' : ''}`}></div>
					<OrderFormPanel toggleOrderPanel={() => {
						handleGetOrders();
						setOrderPanelState(false)
					}} isActive={orderPanelState} className={`${orderPanelState ? 'active' : ''}`} />
				</>}
		</>
	);
};

export default Orders;
