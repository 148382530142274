import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import { Button, Popover, PopoverBody, Input } from "reactstrap";
import {
  addContactPerson,
  deleteContactPerson,
  updateContactPerson,
} from "../../../../context/actions/contactPerson";
import { getPetDetailsById } from "../../../../context/actions/patient-module/patient/pet";
import { RiDeleteBin5Line } from "react-icons/ri";
import Swal from "sweetalert2";
import { formatPhoneNumber } from "utils/common";

const ProfileContact = ({ target }) => {
  const { petDetails } = useSelector((state) => state.petReducer);
  const [isMore, setIsMore] = useState(false);
  const [popover, setPopover] = useState(false);
  const dispatch = useDispatch();

  let defaultContactBodyState = {
    person_name: "",
    phone: "",
    email: "",
    address: "",
    contact_id: "",
  };
  const [contactBody, setContactBody] = useState({
    ...defaultContactBodyState,
  });

  const popoverToggle = () => {
    setPopover(!popover);
    setContactBody({ ...defaultContactBodyState });
  };

  const closePopOver = () => {
    setPopover(false);
    setContactBody({ ...defaultContactBodyState });
  };

  const handleInputChange = (e) => {
    setContactBody({
      ...contactBody,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddEditContactPerson = () => {
    if (contactBody.contact_id && contactBody.contact_id != "") {
      dispatch(
        updateContactPerson(
          { ...contactBody, user_id: petDetails?.pet_owner },
          (res) => {
            if (res.status) {
              setContactBody(defaultContactBodyState);
              closePopOver();
              dispatch(getPetDetailsById(petDetails?._id));
            }
          }
        )
      );
    } else {
      let contactBodyData = { ...contactBody };
      delete contactBodyData.contact_id;

      dispatch(
        addContactPerson(
          { ...contactBodyData, user_id: petDetails?.pet_owner },
          (res) => {
            if (res.status) {
              setContactBody(defaultContactBodyState);
              setPopover(false);
              dispatch(getPetDetailsById(petDetails?._id));
            }
          }
        )
      );
    }
  };

  const handleEditContact = (item) => {
    setPopover(true);
    setContactBody({
      person_name: item.person_name,
      email: item.email,
      address: item.address,
      phone: item.phone,
      contact_id: item._id,
    });
  };

  const deleteContactPersonConfirm = (e, document_id) => {
    e.preventDefault();

    Swal.fire({
      title: "<strong>Are you sure you want to delete this data>?</strong>",
      icon: "warning",
      html: `Once deleted your data cannot be recovered`,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Confirm",
      confirmButtonAriaLabel: "Confirm",
      cancelButtonText: "Cancel",
      cancelButtonAriaLabel: "Cancel",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(deleteContactPerson(document_id, (response) => {
          dispatch(getPetDetailsById(petDetails?._id));
        }));
      } else if (result.isDenied) {
        Swal.fire("Your data is safe", "", "info");
      } else if (result.isDismissed) {
        Swal.fire("Your data is safe", "", "info");
      }
    });
  };

  //   console.log("petDetails-----", petDetails);

  return (
    <>
      <div className="profile-contact row-block">
        <div className="head section-head d-flex align-items-center">
          <div className="left-col">Contact Person</div>
          <div className="right-col ms-auto">
            {popover ? (
              <Button
                id={target}
                onClick={() => closePopOver()}
                color="link"
                className="close-btn d-inline-flex"
              >
                <span className="icomoon-cross-btn"></span>
              </Button>
            ) : (
              <Button
                id={target}
                onClick={() => setPopover(true)}
                color="link"
                className="add-btn d-inline-flex"
              >
                <span className="icomoon-plus-btn"></span>
              </Button>
            )}

            <Popover
              placement="bottom-end"
              isOpen={popover}
              toggle={popoverToggle}
              target={target}
            >
              <PopoverBody className={"new-tag"}>
                <Input
                  placeholder="Add Person Name"
                  name="person_name"
                  value={contactBody.person_name}
                  onChange={handleInputChange}
                />
                <Input
                  placeholder="Add Phone"
                  name="phone"
                  value={contactBody.phone}
                  onChange={handleInputChange}
                />
                <Input
                  placeholder="Add Address"
                  name="address"
                  value={contactBody.address}
                  onChange={handleInputChange}
                />

                <Input
                  placeholder="Add Email"
                  name="email"
                  value={contactBody.email}
                  onChange={handleInputChange}
                />

                <div className="btn-cell d-flex">
                  <Button
                    color="primary"
                    onClick={handleAddEditContactPerson}
                    className="flex-fill"
                  >
                    {contactBody.contact_id ? "Edit" : "Add"} Contact Person
                  </Button>
                </div>
              </PopoverBody>
            </Popover>
          </div>
        </div>
        <div className="inner">
          {(petDetails?.contactPerson || []).map((item, index) => {
            // eslint-disable-line
            let comp = (
              <div className="contact-box d-flex justify-content-between">
               <div className=" d-flex">
               <div className="pic rounded-circle overflow-hidden flex-shrink-0">
                  <img
                    className="img-fluid w-100 h-100"
                    src={
                      item?.image ||
                      require("../../../../assets/img/vetRecorEMR/user-thumbnail.png")
                    }
                    alt="User"
                  />
                </div>
                <div className="txt d-flex flex-column">
                  <label>{`${item?.person_name} ${
                    item?.primary ? "(Primary)" : ""
                  }`}</label>
                  <label>
                    <span className="icon icomoon-phone-chat"></span>
                    { formatPhoneNumber(item?.phone)}
                  </label>
                  <label>
                    <span className="icon icomoon-location"></span>
                    {item?.address}
                  </label>
                </div>
              </div>

                {!item?.primary && (
                  <div>
                    <Button
                      color="link"
                      className="edit-btn px-1 edit-btn1"
                      onClick={() => {
                        handleEditContact(item);
                      }}
                    >
                      <span className="icomoon-pencil"></span>
                    </Button>

                    <Button
                      onClick={(e) => deleteContactPersonConfirm(e, item._id)}
                      color="link"
                      className="edit-btn  px-1 edit-btn1"
                    >
                      <RiDeleteBin5Line />
                    </Button>
                  </div>
                )}
              </div>
            );
            if (!isMore && index < 1) {
              return comp;
            } else if (isMore) {
              return comp;
            }
          })}

          <Button
            onClick={() => setIsMore(!isMore)}
            color="link"
            className="add-btn"
          >
            {isMore
              ? "Less"
              : petDetails?.contactPerson?.length > 1
              ? "+" +
                (petDetails?.contactPerson?.length - 1).toString() +
                " More"
              : ""}
          </Button>
        </div>
      </div>
    </>
  );
};

export default ProfileContact;
