import { useFormik } from "formik";
import React, { useEffect } from "react";

export default function SpecialNotes({
  setActiveForm,
  updateFormData,
  formData,
}) {
  const formik = useFormik({
    initialValues: {
      usage_direction: "",
      emergency_usage: "",
    },
    onSubmit: (values) => {
      updateFormData((prevFormData) => ({
        ...prevFormData,
        values: {
          ...prevFormData.values,
          special_notes: [values],
        },
      }));
      setActiveForm("singleProductDetail");
    },
  });
  useEffect(() => {
    if (formData?.values?.special_notes && Object.keys(formData).length !== 0) {
      formik.setValues(formData?.values?.special_notes[0]);
    }
  }, [formData]);

  return (
    <div className="ml-5 bg-[#F8F8F8E5] rounded-lg ">
      <div className=" p-3">
        <p className="font-['Inter'] text-[#282E65] font-[700] mb-2">
          Special Notes
        </p>

        <div className="flex justify-between ">
          <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
              Usage Directions
            
            </p>
          <textarea
            id="message"
            rows="4"
            class="block p-2.5 w-[81%] text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
            placeholder="Write Directions here..."
            {...formik.getFieldProps("usage_direction")}
          ></textarea>
        </div>
        <div className="flex justify-between my-2 ">
          <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
              Emergency Usage
            
            </p>
          <textarea
            id="message"
            rows="4"
            class="block p-2.5 w-[81%] text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
            placeholder="Write Description here..."
            {...formik.getFieldProps("emergency_usage")}
          ></textarea>
        </div>

        <div className="flex justify-end mt-2">
          <button
            type="button"
            onClick={() => setActiveForm("addPackages")}
            className="px-4 py-2  rounded-lg text-[#11CA9D] font-['Inter']"
          >
            Back
          </button>
          <button
            type="button"
            onClick={formik.handleSubmit}
            className="bg-[#11CA9D] px-3.5 py-2  rounded-lg text-[#fff] font-['Inter']"
          >
            Save & Proceed
          </button>
        </div>
      </div>
    </div>
  );
}

// special note
