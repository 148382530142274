import { useEffect, useState } from "react";
import { Col, Input, Row, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import alertpng from "assets/img/alerts.png";
import PetProfile from "assets/img/PetProfile.png";
import NodataFoundBlock from "shared/NoDataFoundBlock";
import moment from "moment";
import { FiEdit } from "react-icons/fi";
import { PetForm } from "./PetForm";
import { IoCloseCircleOutline } from "react-icons/io5";
import { removeAllergy } from "context/actions/patient-module/patient/pet";
import { getPetOwnerPets } from "context/actions/pet-owner-dashboard";
import { Offcanvas } from "react-bootstrap";
import { getAllergies } from "context/actions/allergies";
import { addAllergy } from "context/actions/patient-module/patient/pet";
import "./Pets.scss";

const Pets = () => {
  const dispatch = useDispatch();
  const { petOwnerPetList } = useSelector(
    (state) => state.petOwnerDashboardReducer
  );
  const userDetail = useSelector((state) => state.user.user_details);
  const allAllergies = useSelector((state) => state?.allergyReducer?.allergies);
  const [isAllergyAdd, setIsAllergyAdd] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [editData, setEditData] = useState(false);
  const [formVal, setFormVal] = useState({
    allergy: "",
    reaction: "",
    notes: "",
  });
  const handleAddAllergy = () => {
    dispatch(
      addAllergy(
        {
          pet_id: isAllergyAdd,
          allergy: formVal.allergy,
          reaction: formVal.reaction,
          notes: formVal.notes,
        },
        (response) => {
          if (response?.status) {
            dispatch(getPetOwnerPets(userDetail?.id));
            setIsAllergyAdd("");
          }
        }
      )
    );
  };
  const handleRemoveAllergy = (petID, item) => {
    dispatch(
      removeAllergy(
        {
          pet_id: petID,
          allergy: item.allergy,
          reaction: item.reaction,
          notes: "",
        },
        (response) => {
          if (response?.status) {
            dispatch(getPetOwnerPets(userDetail?.id));
          }
        }
      )
    );
  };
  useEffect(() => {
    setFormVal({
      allergy: "",
      reaction: "",
      notes: "",
    });
    dispatch(getAllergies(() => {}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllergyAdd]);
  return (
    <div id="pets-container">
      {isAllergyAdd && (
        <Offcanvas
          show
          backdrop="static"
          placement="end"
          className="select-group-list-right"
          id="pet-2"
          onHide={() => {
            setIsAllergyAdd("");
          }}
        >
          <Offcanvas.Header closeButton className="p-2">
            <Offcanvas.Title>Add New Allergy</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="px-2 pb-2 pt-0">
            <div className="mb-2 group-add-name">
              <div> Allergy Information & Reaction</div>
              <div className="mt-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Allergy Name"
                  value={formVal.allergy}
                  onChange={(e) => {
                    setFormVal((prev) => {
                      return { ...prev, allergy: e.target.value };
                    });
                  }}
                />
              </div>
              <div className="mt-2">
                <Input
                  className="reactions-select-dropdown text-gray-500"
                  type="select"
                  name="select"
                  placeholder="Please Select Reaction"
                  value={formVal.reaction}
                  onChange={(e) => {
                    setFormVal((prev) => {
                      return { ...prev, reaction: e.target.value };
                    });
                  }}
                >
                  <option value="">Please Select Reaction</option>
                  {allAllergies?.map((allergy, i) => {
                    return (
                      <>
                        <option key={i} value={allergy?._id}>
                          {allergy?.tag}
                        </option>
                      </>
                    );
                  })}
                </Input>
              </div>

              <div className="mt-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Notes (Optional)"
                  value={formVal.notes}
                  onChange={(e) => {
                    setFormVal((prev) => {
                      return { ...prev, notes: e.target.value };
                    });
                  }}
                />
              </div>

              <div className="d-flex justify-content-end gap-3 mt-3">
                <button
                  className=" w-[40%] cancel-allergy-btn"
                  onClick={() => {
                    setIsAllergyAdd("");
                  }}
                >
                  Cancel
                </button>

                <button
                  disabled={!formVal.reaction || !formVal.allergy}
                  style={{ backgroundColor: "#11ca9d" }}
                  className="w-[40%] btn btn-success"
                  onClick={handleAddAllergy}
                >
                  SAVE
                </button>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      )}
      {isAdd && (
        <PetForm
          editData={editData}
          onHide={() => {
            setIsAdd(false);
            setEditData(null);
          }}
        />
      )}

      {!petOwnerPetList || petOwnerPetList?.length === 0 ? (
        <NodataFoundBlock />
      ) : (
        petOwnerPetList?.map((elm, index) => {
          const {
            pet_name,
            dob,
            gender,
            speciesName,
            breedName,
            pet_notes,
            color,
            pet_image,
            rabies_number,
            petCareInfo,
            deceased, active
          } = elm;
          return (
            <div className="Pets_box" key={index}>
              <Row>
                <Col xl={3} lg={4} sm={12}>
                  <div className="box py-2">
                    <div className="fa-center gap-3">
                      <div>
                        <h1>Patient Information</h1>
                      </div>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          setEditData(elm);
                          setIsAdd(true);
                        }}
                      >
                        <FiEdit />
                      </div>
                    </div>
                    
                  </div>
                </Col>
                <Col xl={6} lg={6} sm={12}>
                  <div className="box">
                    <Table className="table_box">
                      <tr>
                        <td className="custom-w">
                          Patient Name<span>*</span>
                        </td>
                        <td>{pet_name}</td>
                      </tr>
                      <tr>
                        <td className="custom-w">
                          Date of Birth<span>*</span>
                        </td>
                        <td>
                          {moment(dob, "YYYY-MM-DD").format("MM/DD/YYYY")}
                        </td>
                      </tr>
                      <tr>
                        <td className="custom-w">
                          Species<span>*</span>
                        </td>
                        <td>{speciesName}</td>
                      </tr>
                      <tr>
                        <td className="custom-w">
                          Gender<span>*</span>
                        </td>
                        <td className="capitalize">{gender}</td>
                      </tr>
                      <tr>
                        <td className="custom-w">
                          Breed<span>*</span>
                        </td>
                        <td>{breedName}</td>
                      </tr>
                      <tr>
                        <td className="custom-w">
                          Color<span>*</span>
                        </td>
                        <td>{color || "--"}</td>
                      </tr>

                      <tr>
                        <td className="custom-w">
                          Status<span>*</span>
                        </td>
                        <td>{deceased ? 'Deceased' : active ? 'Active' : 'Inactve'}</td>
                      </tr>

                      <tr>
                        <td className="custom-w">Rabies Tag No.</td>
                        <td>{rabies_number || "--"}</td>
                      </tr>
                      {/* <tr>
                        <td className="custom-w">Behavior Tags</td>
                        <td className="fa-center gap-2">
                          {petCareInfo?.behaviour_tags?.length > 0
                            ? petCareInfo?.behaviour_tags?.map(
                                (bElm, bIndex) => {
                                  return (
                                    <div
                                      key={bIndex}
                                      className={`text-12-400 text-center ps-1 pe-1 mb-0 behaviour-tag-font rounded-sm text-white ${
                                        bElm.bseverity === "Low"
                                          ? "bg-cabf"
                                          : bElm.bseverity === "Moderate"
                                          ? "bg-9323"
                                          : bElm.bseverity === "High"
                                          ? "bg-5c30"
                                          : ""
                                      }`}
                                    >
                                      {bElm.btag}
                                    </div>
                                  );
                                }
                              )
                            : "--"}
                        </td>
                      </tr> */}
                      <tr>
                        <td className="custom-w">Allergies</td>
                        {petCareInfo?.allergies?.length > 0 ? (
                          <td className="fa-center gap-2">
                            {petCareInfo?.allergies?.map((aElm, aIndex) => {
                              return (
                                <div key={aIndex} className="fa-center gap-1">
                                  <div
                                    className={`text-12-400 text-center ps-1 pe-1 mb-0 behaviour-tag-font rounded-sm text-white ${
                                      aElm.severity === "Low"
                                        ? "bg-cabf"
                                        : aElm.severity === "Moderate"
                                        ? "bg-9323"
                                        : aElm.severity === "High"
                                        ? "bg-5c30"
                                        : ""
                                    }`}
                                  >{`${aElm.allergy} : ${aElm.tag}`}</div>
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => {
                                      handleRemoveAllergy(elm?._id, aElm);
                                    }}
                                  >
                                    <IoCloseCircleOutline color="#dc3545" />
                                  </div>
                                </div>
                              );
                            })}
                            <span
                              className="select"
                              onClick={() => {
                                setIsAllergyAdd(elm?._id);
                              }}
                            >
                              Please Select
                            </span>
                          </td>
                        ) : (
                          <td
                            className="select"
                            onClick={() => {
                              setIsAllergyAdd(elm?._id);
                            }}
                          >
                            Please Select
                          </td>
                        )}
                      </tr>
                      {/* <tr>
                        <td className="custom-w">
                          Patient Notes (For office use only)
                        </td>
                        <td>{pet_notes || "--"}</td>
                      </tr> */}
                    </Table>
                  </div>
                </Col>

                <Col xl={1} lg={2} sm={2}>
                  {pet_image && (
                    <div className="profile_pic">
                      <h6>Profile Picture</h6>
                      <img src={pet_image} alt="" />
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          );
        })
      )}

      <div
        className="add_pet"
        onClick={() => {
          setIsAdd(true);
        }}
      >
        <span className="icomoon-plus-btn"></span>
        <span>
          Add Pet
        </span>
      </div>
    </div>
  );
};

export default Pets;
