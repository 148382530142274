import React, { useEffect, useRef } from "react";
import { Button } from "reactstrap";
import UserPlaceholder from "../../../../../../../shared/UserPlaceholder";
import { dateTimeFormat } from "../../../../../../../utils/common";
import "./index.scss";
import { Offcanvas } from "react-bootstrap";
import { useState } from "react";
import { Row, Input, Label, InputGroup, Col, FormGroup } from "reactstrap";
import { AiOutlineMessage } from "react-icons/ai";
// import { PORT, apiUrl } from "../../../../../../../environment";
import {
  imageBaseUrl,
  reportPhotosPath,
} from "../../../../../../../environment";
import ReportImageViewer from "../ReportImageViewer";
import {
  addReportsNotes,
  getAllReportsNotes,
} from "../../../../../../../context/actions/patient-module/overview/report";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const ReportItem = ({ item, isHideMsgInput }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [note, setNote] = useState("");
  const [notesList, setNotesList] = useState([]);
  const [notesFilterList, setNotesFilterList] = useState([]);
  const [senderList, setSenderList] = useState([]);
  const msgRef = useRef();
  const removeLeftSpace = (str = "") => {
    return str.replace(/^\s+|\s+$/g, "");
  };
  const userDetails = useSelector((state) => state.user.user_details);

  const handleSendNote = () => {
    const reportID = item?._id;

    let doctorID = "";
    if (userDetails) {
      doctorID = userDetails?._id;
    }

    dispatch(
      addReportsNotes(
        {
          note: note,
          report_id: reportID,
          doctor_id: doctorID,
        },
        (res) => {
          setNote("");
          fetchNotes();
        }
      )
    );
  };

  const fetchNotes = () => {
    dispatch(
      getAllReportsNotes({ petId: item?._id }, (res) => {
        setNotesList(res?.response_data || []);
        setNotesFilterList(res?.response_data || []);
        if(msgRef) msgRef?.current?.scrollIntoView({ behavior: "smooth" });
        const newlist = res?.response_data;
        let uniqueIds = new Set();
        let result = newlist
          ?.map((element) => {
            return {
              id: element?.doctor_id._id,
              label:
                element?.doctor_id?.firstname +
                " " +
                element?.doctor_id?.lastname,
            };
          })
          .filter((item) => {
            if (!uniqueIds.has(item.id)) {
              uniqueIds.add(item.id);
              return true;
            }
            return false;
          });

        setSenderList(result);
      })
    );
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (show) {
      fetchNotes();
    }
  }, [show]);

  const getNotesBySenderId = (id) => {
    if (id !== "") {
      const filterdata = notesList?.filter((ele) => id === ele.doctor_id._id);
      setNotesFilterList(filterdata);
    } else {
      setNotesFilterList(notesList);
    }
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="medication-plan-offcanvas x-ray-reports"
      >
        <Offcanvas.Header closeButton className="pb-0">
          <Offcanvas.Title>{item?.report_type} Report</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-2 medication-plan-block">
          <Row className="h-100 overflow-auto">
            <Col lg={6}>
              <ReportImageViewer
                // loadURL={`${imageBaseUrl}${reportPhotosPath}/${item?.report_link}`}
                loadURL={`${item?.report_link}`}
                handleClose={handleClose}
              />
              {/* <img style={{objectFit:'cover', width: '100%', maxHeight: '80%', height: '100%'}}
                className="img-fluid"
                src={`${imageBaseUrl}${reportPhotosPath}/${item?.report_link}`}
                // src={`${apiUrl}/4200/photos/report/${item?.report_link}`}
              /> */}
            </Col>
            <Col lg={6} className="flex flex-col">
              <h3 className="card-heading mb-3 u-none">{item?.report_name}</h3>
              <p className="card-subheading mb-4 u-none">{item?.description}</p>
              <div className="d-flex align-items-center mb-3">
                <div className="d-flex flex-col">
                  <p className="card-subheading">Imported From</p>

                  <p className="card-subheading">Created At</p>
                  <p className="card-subheading">Radiology Date</p>
                  <p className="card-subheading">Requested By</p>
                </div>
                <div className="d-flex ml-[10px] flex-col">
                  <p className="card-subheading">:</p>
                  <p className="card-subheading">:</p>
                  <p className="card-subheading">:</p>
                  <p className="card-subheading">:</p>
                </div>
                <div className="d-flex flex-col">
                  <p className="card-subheading ms-5">--</p>

                  <p className="card-subheading ms-5">
                    {dateTimeFormat(item?.createdAt, "MM/DD/yyyy hh:mm a")}
                  </p>
                  <p className="card-subheading ms-5">
                    {dateTimeFormat(item?.x_ray_date, "MM/DD/yyyy hh:mm a")}
                  </p>
                  <p className="card-subheading ms-5">
                    {item?.requested_by ? (
                      <>
                        {item?.requested_by?.title}{" "}
                        {item?.requested_by?.firstname}{" "}
                        {item?.requested_by?.lastname}
                      </>
                    ) : (
                      <>{"--"}</>
                    )}
                  </p>
                </div>

                <span className="border text-[14px] border-gray rounded-lg  font-['Inter'] flex items-center gap-1 px-[5px] py-1 mr-2 ml-auto">
                  <a
                    href={`${item?.report_link}`}
                    // href={`${imageBaseUrl}${reportPhotosPath}/${item?.report_link}`}
                    target="_blank"
                    download
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <mask
                        id="mask0_8107_185252"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x={0}
                        y={0}
                        width={24}
                        height={24}
                      >
                        <rect width={24} height={24} fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_8107_185252)">
                        <path
                          d="M12 15.2492C11.8833 15.2492 11.771 15.2286 11.663 15.1872C11.5543 15.1452 11.4583 15.0742 11.375 14.9742L8.25 11.8742C8.11667 11.7409 8.05 11.5659 8.05 11.3492C8.05 11.1326 8.11667 10.9576 8.25 10.8242C8.4 10.6742 8.579 10.5949 8.787 10.5862C8.99567 10.5782 9.175 10.6492 9.325 10.7992L11.25 12.7242V5.07422C11.25 4.85755 11.321 4.67822 11.463 4.53622C11.6043 4.39489 11.7833 4.32422 12 4.32422C12.2167 4.32422 12.396 4.39489 12.538 4.53622C12.6793 4.67822 12.75 4.85755 12.75 5.07422V12.7242L14.675 10.7992C14.8083 10.6659 14.9833 10.5992 15.2 10.5992C15.4167 10.5992 15.6 10.6742 15.75 10.8242C15.8833 10.9576 15.95 11.1282 15.95 11.3362C15.95 11.5449 15.8833 11.7242 15.75 11.8742L12.625 14.9742C12.5417 15.0742 12.446 15.1452 12.338 15.1872C12.2293 15.2286 12.1167 15.2492 12 15.2492ZM6.3 19.4992C5.8 19.4992 5.375 19.3242 5.025 18.9742C4.675 18.6242 4.5 18.1992 4.5 17.6992V15.7492C4.5 15.5326 4.571 15.3532 4.713 15.2112C4.85433 15.0699 5.03333 14.9992 5.25 14.9992C5.46667 14.9992 5.64567 15.0699 5.787 15.2112C5.929 15.3532 6 15.5326 6 15.7492V17.6992C6 17.7659 6.03333 17.8326 6.1 17.8992C6.16667 17.9659 6.23333 17.9992 6.3 17.9992H17.7C17.7667 17.9992 17.8333 17.9659 17.9 17.8992C17.9667 17.8326 18 17.7659 18 17.6992V15.7492C18 15.5326 18.0707 15.3532 18.212 15.2112C18.354 15.0699 18.5333 14.9992 18.75 14.9992C18.9667 14.9992 19.146 15.0699 19.288 15.2112C19.4293 15.3532 19.5 15.5326 19.5 15.7492V17.6992C19.5 18.1992 19.325 18.6242 18.975 18.9742C18.625 19.3242 18.2 19.4992 17.7 19.4992H6.3Z"
                          fill="#3D3EFD"
                        />
                      </g>
                    </svg>
                  </a>
                </span>
              </div>

              <div className="d-flex align-items-center justify-content-between mb-3">
                <h3 className="doctor-notes-heading">Doctor's Notes</h3>
                <div className="w-[30%]">
                  <select
                    className="border w-full py-2 px-2 rounded-lg"
                    aria-label="messages type"
                    onChange={({ target: { value } }) =>
                      getNotesBySenderId(value)
                    }
                  >
                    <option value="">All</option>
                    {senderList?.map((opt) => (
                      <option value={opt.id}>{opt.label}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="doctors-msgs">
                {notesFilterList.length === 0 ? (
                  <div className="empty-notes-area">
                    <div>
                      <div className="flex justify-center">
                        <AiOutlineMessage size={30} />
                      </div>
                      <div className="mt-2">Notes not found.</div>
                    </div>
                  </div>
                ) : (
                  notesFilterList?.map((elm, index) => {
                    const { note: sNote, doctor_id, createdAt } = elm;
                    const { firstname, lastname } = doctor_id;
                    const noteDate = moment(createdAt).format("MM/DD/YYYY");
                    return (
                      <div
                        className={
                          notesFilterList.length - 1 === index
                            ? "d-flex"
                            : "d-flex mb-3"
                        }
                        key={index}
                      >
                        <img
                          src={require("../../../../../../../assets/img/pug.png")}
                          style={{ width: 32, height: 32 }}
                          className="rounded-circle"
                        />
                        <div className="ms-2">
                          <div className="d-flex align-items-center">
                            <p className="chater-name">{`${firstname} ${lastname}`}</p>
                            <span className="chat-date ms-1">{noteDate}</span>
                          </div>
                          <div className="chat-bubble chat-bubble--left">
                            <p>{sNote}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
                <div ref={msgRef} />
              </div>
              {!isHideMsgInput && (
                <div class="chat-box-tray">
                  <input
                    type="text"
                    placeholder="Start writing message..."
                    value={note}
                    onChange={(e) => {
                      setNote(e.target.value);
                    }}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13 && e.target.value) {
                        handleSendNote();
                      } else if (e.target.value) {
                        setNote(e.target.value);
                      }
                    }}
                  />


                <button className='btn btn-primary py-1' onClick={() => {
                      if (note) {
                        handleSendNote();
                      }
                    }} type="submit">Send</button>
                 {/* <i
                    class="material-icons cp"
                    onClick={() => {
                      if (note) {
                        handleSendNote();
                      }
                    }}
                  >
                    send
                  </i> */}
                </div>
              )}
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
      <div className="report-item" onClick={handleShow}>
        <div className="head">
          {/* <h2>{item?.report_title}</h2> */}
          <h2>{item?.report_name}</h2>
          <h3>
            <span style={{ textTransform: "capitalize" }}>Modified </span>
            {dateTimeFormat(item?.updatedAt, "MM/DD/yyyy hh:mm a")}
          </h3>
          {/* <h3><span style={{ textTransform: "capitalize" }}>{item?.last_action} </span>{dateTimeFormat(item?.updatedAt, "MM/DD/yyyy hh:mm a")}</h3> */}
        </div>
        <div className="report-icons d-flex align-items-center">
          <span className="d-inline-flex">
            <img
              className="img-fluid"
              src={
                require("../../../../../../../assets/img/vetRecorEMR/medical/icon/bone.svg")
                  .default
              }
              alt="User"
            />
          </span>
          <span className="d-inline-flex">
            <img
              className="img-fluid"
              src={
                require("../../../../../../../assets/img/vetRecorEMR/medical/icon/xray.svg")
                  .default
              }
              alt="User"
            />
          </span>
          <span className="d-inline-flex">
            <img
              className="img-fluid"
              src={
                require("../../../../../../../assets/img/vetRecorEMR/medical/icon/prescription.svg")
                  .default
              }
              alt="User"
            />
          </span>
        </div>
        <div className="treat-by-cell d-flex align-items-center">
          <div className="pic d-inline-flex rounded-circle overflow-hidden flex-shrink-0">
            {/* {item?.examinBy?.user_image ? <img className="img-fluid w-100 h-100" src={item?.examinBy?.user_image || require('../../../../../../../assets/img/vetRecorEMR/user-thumbnail.png')} alt="User" /> :
							<UserPlaceholder />} */}
            <img
              className="img-fluid w-100 h-100"
              src={item?.user_image}
              alt="User"
            />{" "}
            <UserPlaceholder />
          </div>
          {/* <div className="txt">{`${item?.examinBy?.title ? item?.examinBy?.title + ' ' : ''} ${item?.examinBy?.firstname} ${item?.examinBy?.lastname}`}</div> */}
          <div className="txt">
            Dr.{" "}
            {`${item?.addedByUser?.firstname} ${item?.addedByUser?.lastname}`}
          </div>
          <div className="icon d-inline-flex">
            <span className="material-symbols-outlined">auto_mode</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportItem;
