import React from "react";
import { Button } from "reactstrap";
import AddSlotButton from "../AddSlotButton";
import SlotItemName from "../SlotItemName";
import SlotItemTime from "../SlotItemTime";
import SlotUnavailable from "../SlotUnavailable";
import ViewMoreSlot from "../ViewMoreSlot";
import UserPicCell from "./UserPicCell";
import SlotTimeFrame from "../SlotTimeFrame";
import "./index.scss";
import { dayTimeSpan } from "../../../utils/calendar/constant";
import { Fragment } from "react";

const CalendarWeekDayWithoutDoctors = ({ dateVal, appointments }) => {
  return (
    <>
      <div className="calendar-day-view overflow-auto">
        <table className="table">
          <thead>
            <tr>
              <th className="syn-btn-wrapper">
                {/* <Button color="link" className="d-inline-flex syn-btn p-0">
                                    <span className="material-symbols-outlined">
                                        sync
                                    </span>
                                </Button> */}
              </th>
              {/* {
                                doctorsList && doctorsList.length > 0 && doctorsList.map((doctor, i) => (
                                    <th className="user-pic-cell-wrapper" key={i}>
                                        <UserPicCell doctor={doctor} />

                                    </th>
                                ))
                            } */}
            </tr>
          </thead>
          <tbody>
            {dayTimeSpan.map((item, i) => {
              return (
                <tr key={i}>
                  <th className="time-cell-wrapper">
                    <div className="time-cell">
                      {item?.value}
                      <span className="d-block">{item?.name}</span>
                    </div>
                  </th>
                  {
                    // doctorsList && doctorsList.length > 0 && doctorsList.map((doctor, i2) => (
                    <Fragment>
                      {appointments &&
                      appointments?.filter((data) =>
                        data.appointment_date === dateVal &&
                        data.appointment_timings.filter((ele) => {
                          return (
                            ele.appointment_from[0] === item.value &&
                            ele.appointment_from[1] === item.name
                          );
                        }).length > 0
                          ? true
                          : false
                      ).length > 0 ? (
                        <td className="p-0 no-hover">
                          <div className="data-container">
                            <SlotTimeFrame
                              className="day-view-slot"
                              addSlot={false}
                              data={appointments}
                              item={item}
                            />
                          </div>
                        </td>
                      ) : (
                        <td className="p-0 no-hover">
                          <div className="data-container">
                            <SlotTimeFrame
                              className="day-view-slot"
                              addSlot={true}
                            />
                          </div>
                        </td>
                      )}
                    </Fragment>
                    // ))
                  }
                  {/* <td className="no-hover">
										<div className="data-container">
											<SlotItemName />
											<SlotItemTime className="clinic" />
											<SlotItemTime className="phone" />
											<SlotItemTime className="video-conference" />
										</div>
									</td> */}
                  {/* <td className="no-hover">
										<div className="data-container blank-slot d-flex align-items-center justify-content-center position-absolute">
											<SlotUnavailable />
										</div>
									</td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CalendarWeekDayWithoutDoctors;

/*

const CalendarWeekDay = ({ columns, data, dateVal }) => {
  const filteredData = data.filter((d) => d.surgery_date === dateVal);
  const findOperatingRoomColumn = (name) => {
    const idx = columns.findIndex((data) => data === name);
    return idx + 1;
  };
  return (
    <>
      <div className="calendar-day-view overflow-auto">
        <div
          className={`overflow-auto min-w-screen relative grid grid-rows-[auto,repeat(10,120px)] max-h-[85vh]`}
          style={{
            gridTemplateColumns: `70px repeat(${columns.length}, minmax(150px, 1fr))`,
            gridTemplateRows: `auto repeat(${dayTimeSpan.length}, 120px)`,
          }}
        >
          {columns.map((d, i) => (
            <React.Fragment key={d}>
              {i === 0 && (
                <div className="row-start-[1] col-start-[1] border-slate-200 border-r p-1.5 sticky left-0 bg-white"></div>
              )}
              <h6
                className={`row-start-[1] col-start-[${
                  i + 2
                }] sticky top-0 bg-white border-b border-r px-2.5 capitalize text-xs xl:text-sm font-medium py-2`}
              >
                {d}
              </h6>
            </React.Fragment>
          ))}
          {dayTimeSpan.map((t, i) => (
            <React.Fragment key={t.name + t.value}>
              {i === 0 && (
                <div className="row-start-[1] col-start-[1] place-self-center p-1.5 sticky z-10 left-0 top-0 bg-white">
                  <Button color="link" className="d-inline-flex syn-btn p-0">
                    <span className="material-symbols-outlined">sync</span>
                  </Button>
                </div>
              )}
              <div
                className={`row-start-[${
                  i + 2
                }] col-start-[1] text-center border-slate-200 border-r text-sm xl:text-base p-1.5  text-slate-400 uppercase sticky left-0 bg-white font-medium`}
              >
                {t.value}
                <br /> {t.name}
              </div>
            </React.Fragment>
          ))}
          {dayTimeSpan.map((_, i) =>
            columns.map((_, j) => (
              <div
                key={i + Date.now() + Math.random()}
                className={`border-slate-200 border-r border-b last:border-b-0 last:border-r-0`}
                style={{
                  gridRowStart: `${i + 2}`,
                  gridColumnStart: `${j + 2}`,
                }}
              ></div>
            ))
          )}
          {filteredData.map((data, i) => {
            const startHour = Number(data?.surgery_start_time?.split(":")[0]);
            const endHour = Number(data?.surgery_end_time?.split(":")[0]);
            const startRow = startHour - Number(dayTimeSpan[0].value) + 2;
            const rowSpan = endHour - startHour;

            const column = findOperatingRoomColumn(
              data?.operating_room_id?.name
            );

            return (
              <div
                key={i + Date.now() + Math.random()}
                className={`row-span-[${rowSpan}] bg-gray-100 border rounded-lg m-1 p-1 overflow-hidden `}
                style={{
                  gridRowStart: `${startRow}`,
                  gridColumnStart: `${column + 1}`,
                  gridRowEnd: `span ${rowSpan}`,
                }}
              >
                5555
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CalendarWeekDay;

*/

/*
<table className="table">
          <thead>
            <tr>
              <th className="syn-btn-wrapper">
                <Button color="link" className="d-inline-flex syn-btn p-0">
                  <span className="material-symbols-outlined">sync</span>
                </Button>
              </th>
              {columns &&
                columns.length > 0 &&
                columns.map((doctor, i) => (
                  <th className="user-pic-cell-wrapper" key={i}>
                    <UserPicCell doctor={doctor} />
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {dayTimeSpan.map((item, i) => (
              <tr key={i}>
                <th className="time-cell-wrapper">
                  <div className="time-cell">
                    {item?.value}
                    <span className="d-block">{item?.name}</span>
                  </div>
                </th>
                {columns &&
                  columns.length > 0 &&
                  columns.map((doctor, i2) => (
                    <Fragment key={i2}>
                      {doctor?.appointments &&
                      doctor?.appointments?.filter(
                        (data) =>
                          data.appointment_date === dateVal &&
                          data.appointment_from[0] === item.value &&
                          data.appointment_from[1] === item.name
                      ).length > 0 ? (
                        <td className="p-0 no-hover">
                          <div className="data-container">
                            <SlotTimeFrame
                              className="day-view-slot"
                              addSlot={false}
                              data={doctor.appointments}
                              item={item}
                            />
                          </div>
                        </td>
                      ) : (
                        <td className="p-0 no-hover">
                          <div className="data-container">
                            <SlotTimeFrame
                              className="day-view-slot"
                              addSlot={true}
                            />
                          </div>
                        </td>
                      )}
                    </Fragment>
                  ))}
                {/* <td className="no-hover">
                                        <div className="data-container">
                                            <SlotItemName />
                                            <SlotItemTime className="clinic" />
                                            <SlotItemTime className="phone" />
                                            <SlotItemTime className="video-conference" />
                                        </div>
                                    </td> 
                 <td className="no-hover">
                                        <div className="data-container blank-slot d-flex align-items-center justify-content-center position-absolute">
                                            <SlotUnavailable />
                                        </div>
                                    </td> 
              </tr>
            ))}
          </tbody>
        </table>

*/
