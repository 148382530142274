import React, { useState } from "react";
import { Button, Table, FormGroup, Input, InputGroup } from "reactstrap";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import './index.scss'
import SwitchCell from "../../../shared/SwitchCell";
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as CrossSvg } from "../../../assets/img/close-square-svgrepo-com.svg"
import * as Yup from "yup";


const ServiceBlock = ({ className = '', services, handleServiceQuantityChange }) => {

    return (
        <>
            <div className={`package-info-blc w-100 ${className}`}>
                {(services?.length > 0) &&
                    <div className="table-listing-blc overflow-auto w-100">

                        <Table striped responsive>
                            <thead>

                                <tr>
                                    <th className="bg-transparent">
                                        Name
                                    </th>
                                    <th className="bg-transparent" style={{ minWidth: '120px' }}>
                                        Quantity
                                    </th>
                                    <th className="bg-transparent" style={{ minWidth: '120px' }}>
                                        Unit Price
                                    </th>
                                    <th className="bg-transparent" style={{ minWidth: '120px' }}>
                                        Total Price
                                    </th>
                                </tr>
                            </thead>
                            {services?.length > 0 &&
                                services.map((data, index) => {


                                    return (
                                        <tbody>
                                            <tr className="rounded-[12px]">
                                                <td className="py-2">
                                                    {data?.serviceName}
                                                </td>
                                                <td className="py-2">
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        value={data?.quantity}
                                                        onChange={(e) => handleServiceQuantityChange(e, index)}
                                                        placeholder="Enter Quantity"
                                                        className="w-50 border px-1 w-auto text-center bg-[#ffffff] border-1"

                                                    />
                                                </td>
                                                <td >${data?.unit_price.toLocaleString("en-US", {
                                                    minimumFractionDigits: 2,
                                                })}</td>
                                                <td >
                                                    ${parseInt(+data?.amount).toLocaleString("en-US", {
                                                        minimumFractionDigits: 2,
                                                    })}
                                                </td>
                                            </tr>
                                        </tbody>
                                    );
                                })}
                        </Table>

                    </div>}
            </div>
        </>
    );
};

export default ServiceBlock;
