import React, { useEffect, useState, useRef } from "react";
import { Button, Input, Row,  FormGroup, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown } from "reactstrap";
import { updateClinicSettings } from "../../../../../context/actions/clinicSettings";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import './index.scss';
import { InputGroup } from "react-bootstrap";
import { Formik, Form, FieldArray , Field} from "formik";
import { tasksInit, tasksSchema} from "./schema";
import "react-datepicker/dist/react-datepicker.css";





const EditReminderTimes = ({ className = '', closeNewPointPanel, appointmentType}) => {

    const dispatch = useDispatch();
    const {clinic_settings} = useSelector(state => state.clinicSettingsReducer)


    const handleUpdate = (values) => {

        let update = {
         [appointmentType]: values?.tasks?.map(i => {
            const {_id, ...vals} = i;
            return vals
        }),
        clinic_id: clinic_settings.clinic_id,
        update_query: "reminder_time"}

        dispatch(updateClinicSettings(update, () => { }));
        closeNewPointPanel();
    }
    console.log(appointmentType)

    const initialVals = {
        tasks: (clinic_settings?.reminder_time?.[appointmentType] || [])
    }




    return (
        <>
            <div style={{minWidth: '25%', maxWidth: '25%'}} className={`vaccine-schedule-panel-wrapper position-fixed d-flex flex-column overflow-auto ${className}`}>
                <div className="head d-flex align-items-center">
                    <h2 style={{textTransform: 'capitalize'}}>{appointmentType} Reminder Times</h2>
                    <Button color="link" className="ms-auto panel-close-btn" onClick={() => {
                        closeNewPointPanel()
                    }}>
                        <span className="material-symbols-outlined">
                            close
                        </span>
                    </Button>
                </div>

                <div className="inner align-items-center mt-2">

                    
                <Formik
                    initialValues={initialVals}
                    enableReinitialize
                    validationSchema={tasksSchema}
                    onSubmit={handleUpdate}
                    >
                    {(formik) => (
                        <Form className=" overflow-y-auto overflow-x-hidden">

                       

                        <FieldArray name="tasks">
                            {({ remove, push }) => (
                            <>    
                                <div
                                style={{
                                    display: "flex",
                                }}
                                className="flex-column gap-2"
                                >
                                {formik?.values?.tasks?.length > 0 &&
                                    formik?.values?.tasks?.map(
                                    (task, index) => (
                                        <>
                                        <div className="w-full rounded-lg bg-stone-50/90 py-[10px] px-[15px] inline-flex gap-[30px]">
                                        

                                            <div className="grow flex flex-col justify-center items-start">
                                            <Row className="flex mb-2">
                                    
                                                <FormGroup className="input-wrapper2 mb-2">
                                                <div className="row-block flex gap-[20px] items-center w-full">
                                                    <InputGroup style={{width: '100%'}}>
                                                    <div style={{ maxWidth: '50%'}} className="form-floating position-relative text-input">

                                                    <Input
                                                        id={`tasks.${index}.value`}
                                                        placeholder="Enter Time"
                                                        onChange={(e) => {
                                                        formik.handleChange(e);
                                                        }}
                                                        value={
                                                        formik.values.tasks?.[index]?.value
                                                        }
                                                        type='Number'
                                                    />

                                                    <label htmlFor={`tasks.${index}.time.value`}>Value</label>

                                                    </div>

                                                    <div style={{maxWidth: '40%'}} className="form-floating position-relative select-input">


                                                    <Input
                                                    id={`tasks.${index}.unit`}
                                                    placeholder="Select Unit"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                    }}
                                                    value={
                                                        formik.values.tasks?.[index].unit
                                                    }
                                                    type="select"
                                                    >
                                                        <option defaultValue>Select Unit</option>
                                                        <option>days</option>
                                                        <option>weeks</option>
                                                        <option>months</option>                                                       
                                                    </Input>

                                                    </div>



                                                    </InputGroup>
                                                    </div>
                                                </FormGroup>
                                                        
                                                                                                
                                                
                                            </Row> 
                                            </div>

                                            <div className=" h-20 relative">
                                            <button
                                                className="hover:drop-shadow-md"
                                                onClick={() => remove(index)}
                                            >
                                                <img
                                                className="img-fluid w-100 h-100"
                                                src={
                                                    require("../../../../../assets/img/delete_gray.svg")
                                                    .default
                                                }
                                                alt="Badge"
                                                />
                                            </button>
                                            </div>
                                        </div>
                                        </>
                                    )
                                    )}
                                </div>

                          
                                <Row className="my-4">
                                    <div className="w-full">
                                    <Button
                                        onClick={() => {
                                        push({value: 0, unit: 'days'} )
                                        }}
                                        color="link"
                                        className="add-section-medication-btn d-flex justify-content-center w-full"
                                    >
                                        <span className="icomoon-plus-btn"></span>
                                    </Button>
                                    </div>
                                </Row>
                                
                            </>
                            )}
                        </FieldArray>



                       
                        <div className="flex w-full justify-end">
                            <Button type="submit" color="success" className="w-[150px] ">
                                Save
                            </Button>
                        </div>

                    </Form> )}
                    </Formik>
                </div>
            </div >
        </>
    );
};

export default EditReminderTimes;
