import React from "react";
import StoryFilterBlock2 from '../../../../../../shared/StoryFilterBlock2'
import StoryListItem2 from "./StoryListItem2";
import RecommendationBlock from "../../../../../../shared/PatientStories/RecommendationBlock";
import './index.scss';
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { getAllPatientStories } from "../../../../../../context/actions/patientStories";
import NodataFoundBlock from "../../../../../../shared/NoDataFoundBlock";

const PatientStories2 = ({ userId, className = '' }) => {
	const dispatch = useDispatch();
	const [patientStories, setPatientStories] = useState(false)

	const handleGetAllPatientStories = () => {
		dispatch(getAllPatientStories(userId, {}, (result) => {
			if (result && !result.errors) {
				setPatientStories(result)
			} else {
				setPatientStories(false)
			}
		}))
	}

	useEffect(() => {
		if (userId) {
			handleGetAllPatientStories()
		}
	}, [userId])

	return (
		<>
			<div className={`patient-story-wrapper2 d-flex ${className}`}>
				<div className="left-col flex-fill">
					<StoryFilterBlock2 className="responsive" />
					<div className="story-list-blc">
						{
							!patientStories || patientStories.length <= 0 ? <NodataFoundBlock className="layout-md" /> : patientStories && patientStories.map((patientStory, index) => (
								<StoryListItem2 className="responsive" patientStory={patientStory} key={index} />
							))
						}
					</div>
				</div>
				<div className="right-col">
					<RecommendationBlock userId={userId} />
				</div>
			</div>
		</>
	);
};

export default PatientStories2;