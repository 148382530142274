const initState = {
    loading: false,
    conditionList: false,
    currentConditionList: false,
    petProblemList: []
}

export const currentConditionReducer = (state = initState, action) => {
    switch (action.type) {
        case "CONDITION_LOADING":
            return { ...state, loading: action.payload }

        case "GET_CONDITION_LIST":
            return { ...state, conditionList: action.payload }
        case "GET_CURRENT_CONDITION_LIST":
            return { ...state, currentConditionList: action.payload }
        case "GET_PET_PROBLEM_LIST":
            return { ...state, petProblemList: action.payload }

        default:
            return state
    }
}