import React, { Fragment, useEffect, useState } from "react";
import { Button } from "reactstrap";
import { getNextWeekSpanDays } from "../../../../utils/calendar/constant";
import LoaderUI from "../../../loader";
import NodataFoundBlock from "../../../NoDataFoundBlock";
import UserPlaceholder from "../../../UserPlaceholder";
import AddSlotButton from "../../AddSlotButton";
import SlotItemName from "./SlotItemName";
import "../index.scss";
import moment from "moment";
import { useSelector } from "react-redux";

export const CalendarWeekViewBoarding = ({
  calendarViewData,
  kennelData,
  allKennels,
  setBoarding,
}) => {
  const [dates, setDates] = useState([]);
  const [loader, showLoader] = useState(false);

  useEffect(() => {
    showLoader(true);
    const date = getNextWeekSpanDays(
      calendarViewData.span_date,
      calendarViewData.span_month,
      calendarViewData.last_week_span
        ? [calendarViewData.year_value - 1, calendarViewData.year_value]
        : calendarViewData.year_value
    );

    if (date.length) {
      date.forEach((val) => {
        const dateDay = moment(val.full_date, "DD-MM-YYYY").format("dddd");
        val.day = dateDay.toLowerCase();
      });
    }
    // console.log(date);
    setDates(date);
    showLoader(false);
  }, [calendarViewData]);

  var naiveReverse = function (string) {
    let str =
      string?.split("-")[2] +
      "-" +
      string?.split("-")[1] +
      "-" +
      string?.split("-")[0];
    // return string.split('').reverse().join('');
    return str;
  };

  console.log("dates", dates);
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );

  const daysOfWeek = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const startDayIndex = daysOfWeek.indexOf(
    clinic_settings.calendar_start_day.toLowerCase()
  );
  const orderedDaysOfWeek = [
    ...daysOfWeek.slice(startDayIndex),
    ...daysOfWeek.slice(0, startDayIndex),
  ];

  return (
    <>
      {loader && <LoaderUI overlay />}

      <div
        className={`calendar-week-view  ${
          !kennelData && kennelData.length <= 0
            ? "overflow-hidden"
            : "overflow-auto"
        }`}
      >
        <table className="table">
          <thead>
            <tr>
              <th className="user-filter-blc-wrapper">
                <div className="user-filter-blc d-flex align-items-center">
                  <h3 className="ttl">Kennels</h3>
                  <div className="right-col ms-auto d-inline-flex align-items-center">
                    <Button color="link" className="d-inline-flex">
                      <span className="material-symbols-outlined">
                        filter_list
                      </span>
                    </Button>
                    <Button color="link" className="d-inline-flex">
                      <span className="material-symbols-outlined">sync</span>
                    </Button>
                  </div>
                </div>
              </th>
              {/* {dates.map((date, i) => (
                  <th className="day-cell-hd text-center cell-width" key={i}>
                    {date?.day}
                    <span className="d-block">{date?.date}</span>
                  </th>
                ))} */}

              {orderedDaysOfWeek.map((day, i) => {
                const foundDate = dates?.find((dat) => dat.day === day);
                const formattedDay = foundDate
                  ? moment(foundDate.full_date, "DD-MM-YYYY").format("ddd")
                  : "";
                return (
                  <th className="day-cell-hd text-center cell-width" key={i}>
                    {formattedDay?.toUpperCase()}
                    <span className="d-block">{foundDate?.date}</span>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {allKennels &&
              allKennels?.map((currentKennel, index) => {
                const kennelAppointments = kennelData?.filter(
                  (appointment) =>
                    appointment?.kennel?.kennel_name ===
                    currentKennel?.kennel_name
                );

                return (
                  <tr key={index}>
                    <th className="user-pic-cell-wrapper">
                      <div className="ml-3 user-pic-cell d-inline-flex align-items-center">
                        <div className="pic rounded-circle overflow-hidden">
                          <UserPlaceholder />
                        </div>
                        <div className="txt ">
                          <h4>{currentKennel?.kennel_name}</h4>
                        </div>
                      </div>
                    </th>
                    {dates.map((date, i2) => {
                      const kennelAppointmentsForDate = kennelAppointments
                        .filter((appointment) =>
                          moment(date?.full_date, "DD-MM-YYYY").isBetween(
                            appointment?.boarding_start_date,
                            appointment?.boarding_end_date,
                            "day",
                            "[]"
                          )
                        )
                        .sort((a, b) =>
                          moment(a?.boarding_start_date).isAfter(
                            b?.boarding_start_date
                          )
                        );
                      return (
                        <td key={i2}>
                          {kennelAppointmentsForDate.length > 0 ? (
                            <div className="data-container">
                              {kennelAppointmentsForDate.map(
                                (appointment, apptIndex) => (
                                  <SlotItemName
                                    key={apptIndex}
                                    appointmentData={appointment}
                                    click={() => setBoarding(appointment)}
                                  />
                                )
                              )}
                            </div>
                          ) : (
                            <div className="data-container blank-slot add-slot-btn-wrapper d-flex align-items-center justify-content-center position-relative">
                              <AddSlotButton />
                            </div>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            {!kennelData ||
              (kennelData?.length === 0 && (
                <tr className="calendar-no-data-rw">
                  <td colSpan="8">
                    <NodataFoundBlock message="No appointment found of these dates" />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
