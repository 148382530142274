import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Table } from "reactstrap";
import moment from "moment";
import { useHistory } from "react-router";
import "./index.scss";
import { useState } from "react";

const VitalsAndNotes = ({ appointmentData, handleNoteSave }) => {
  const data = useSelector((state) => state.appointmentDetailPanelReducer.data);

  const [showWeightInput, setShowWeightInput] = useState(false);
  const [showEditHistory, setShowEditHistory] = useState(false);

  const [showNotesInput, setShowNotesInput] = useState(false);

  const { petDetails } = useSelector((state) => state.petReducer);

  const [notes, setNotes] = useState(false);

  useEffect(() => {
    setNotes(appointmentData?.notes);
    setShowNotesInput(false);
    setShowEditHistory(false);
  }, []);

  useEffect(() => {
    setShowEditHistory(false);
  }, [petDetails]);

  useEffect(() => {
    setNotes(appointmentData?.notes);
    setShowNotesInput(false);
    // setShowEditHistory(false);
  }, [appointmentData?.notes, petDetails]);    
  
  const { permissionsData } = useSelector(
		(state) => state.clinicPermissionReducer
	  );
	const permissions = permissionsData;
  return (
    <>
      <div className={`schedule-information-card`}>
        <div className="txt">
          <h2>Vitals & Doctor Notes</h2>
        </div>
        <div className="information-group d-flex flex-row gap-3">
          <div className="txt d-flex flex-column pd-10 gap-2">
            <p>Temperature</p>
            <p>Weight</p>
            <p>Doctor Notes</p>
            {/* {appointmentData?.pastNotes?.length > 0 && <p>Edited By</p>} */}
          </div>

          <div className="txt d-flex flex-column pd-10 w-100 gap-2">
            <p>{petDetails?.temperatures?.[0]?.temperature || "--"} F</p>
            {/*
                        showWeightInput ?
                        <Input type="number" name="weight" value={data?.weight}
                                    onChange={evt => {setData({...data, weight: evt.target.value})}} placeholder="0" /> :
                        
                        data?.weight ? <p onClick={() => {setShowWeightInput(!showWeightInput)}}>{data.weight}</p> : 
                        <p onClick={() => {setShowWeightInput(!showWeightInput)}} className="add-info">Add Info</p> */}

            <p> {petDetails?.weights?.[0]?.weight || "--"} lbs</p>
            {showNotesInput ? (
              <div style={{ gap: "20px", overflow: 'scroll' }} className="d-flex flex-col">
                <textarea

                  className="resize-y rounded-md overflow-hidden outline outline-1 outline-[#0909541F] p-2"
                  onChange={(evt) => setNotes(evt.target.value)}
                  name="notes"
                  value={notes}
                  placeholder=""
                />
                <div className="d-flex w-100 justify-content-between  flex-row gap-[10px]">
                <Button
                              style={{padding: 0, fontSize: '23px'}}

                onClick={() => {
                        setNotes(appointmentData?.notes);
                        setShowNotesInput(!showNotesInput);}} color="link" className="edit-btn">
                  <i className="icomoon-cross-btn"></i>
                </Button>
                <Button 
              style={{padding: 0, fontSize: '23px'}}

                onClick={() => {   handleNoteSave(notes);
                    setShowNotesInput(!showNotesInput);}}  color="link" className="edit-btn">

                  <i  className="icomoon-check-btn"></i>
                </Button>
                {/* <Button
                  onClick={() => {
                    handleNoteSave(notes);
                    setShowNotesInput(!showNotesInput);
                  }}
                  className="mt-auto mb-auto"
                  
                  size={"xs"}
                  color="primary"
                  type="submit"
                >
                  <FontAwesomeIcon icon={faFloppyDisk} />
                </Button>
                <Button
                  onClick={() => {
                    setNotes(appointmentData?.notes);
                    setShowNotesInput(!showNotesInput);
                  }}
                  className="mt-auto mb-auto"
                  style={{ maxHeight: "50px" }}
                  size={'xs'}
                  color="danger"
                  type="submit"
                >
                  X
                </Button> */}
                </div>
              </div>
            ) : appointmentData?.notes ? (
              <div style={{ gap: "10px" }} className="d-flex flex-row">
                <p className="note">{appointmentData?.notes}</p>
                        {permissions?.edit_appointments &&
                    <p
                  onClick={() => {
                    setShowNotesInput(!showNotesInput);
                  }}
                  className="add-info"
                >
                  Edit
                </p>
                        }
              </div>
            ) : (
                          permissions?.edit_appointments &&
        <p
                onClick={() => {
                  setShowNotesInput(!showNotesInput);
                }}
                className="add-info"
              >
                Add Info
              </p>
                   
            )}
            {/* <div className="d-flex justify-content-between">
              {appointmentData?.pastNotes?.length > 0 && (
                <>
                  <p>
                    {appointmentData?.lastEditedBy?.firstname}{" "}
                    {appointmentData?.lastEditedBy?.lastname}
                    {appointmentData?.lastEditedBy?.firstname ? " on " : ""}
                    {appointmentData?.lastEditedAt
                      ? moment(appointmentData?.lastEditedAt).format(
                          "MM/DD/YYYY hh:mma"
                        )
                      : ""}
                  </p>
                  <p
                    onClick={() => setShowEditHistory(!showEditHistory)}
                    className="add-info "
                  >
                    {showEditHistory
                      ? "Hide edit history"
                      : "Show Edit History"}
                  </p>
                </>
              )}
            </div> */}
          </div>
        </div>
        <div className="information-group d-flex flex-row gap-3">
          <div className="txt d-flex flex-column pd-10 gap-2">

          {appointmentData?.pastNotes?.length > 0 && <p>Edited By</p>}
          </div>
          <div className="txt d-flex flex-column pd-10 w-100 gap-2">

          <div className="d-flex w-100 justify-content-between">
              {appointmentData?.pastNotes?.length > 0 && (
                <>
                  <p style={{wordBreak: 'break-all'}}>
                    {appointmentData?.lastEditedBy?.firstname}{" "}
                    {appointmentData?.lastEditedBy?.lastname}
                    {appointmentData?.lastEditedBy?.firstname ? " on " : ""}
                    {appointmentData?.lastEditedAt
                      ? moment(appointmentData?.lastEditedAt).format(
                          "MM/DD/YYYY hh:mma"
                        )
                      : ""}
                  </p>
                  <p
                    onClick={() => setShowEditHistory(!showEditHistory)}
                    className="add-info "
                  >
                    {showEditHistory
                      ? "Hide edit history"
                      : "Show Edit History"}
                  </p>
                </>
              )}
            </div>
            </div>
            </div>


        {showEditHistory && (
          <div className="edit-history-table">
            {appointmentData?.pastNotes.length > 0 && (
              <Table striped>
                <thead>
                  <tr>
                    <th>Modified By</th>
                    <th>Modified On</th>
                    <th>Doctor Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {appointmentData?.pastNotes.map((Item, index) => (
                    <tr key={index}>
                      <td>
                        <img src={Item?.editedBy?.user_image} alt="" />{" "}
                        {Item?.editedBy?.firstname}{" "}
                        {Item?.editedBy?.lastname}
                      </td>
                      <td>
                        {moment(Item?.editedAt).format("MM/DD/YYYY hh:mm a")}
                      </td>
                      <td style={{overflowWrap: 'break-word'}}>{Item?.noteEditHistory}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default VitalsAndNotes;
