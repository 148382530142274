import moment from "moment";
import { Offcanvas } from "react-bootstrap";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import pdfFile from "assets/img/icons/pdf.svg";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getAppointmentDetails } from "context/actions/appointment";
import { getAppointmentTimingDisplayValues } from "utils/calendar/constant";
import "./AppointmentDetails.scss";
import ChatRoom from "components/video-call-module/ChatRoom";
import { collection, doc, getDocs, query, where } from "firebase/firestore";
import {
  fetchChatMessages,
  getSelectedRoomData,
} from "context/actions/realTimeChat";
import db from "./../../../../firebase";
import { Button } from "reactstrap";
import { useEffect, useState } from "react";

const AppointmentDetails = ({
  id,
  onHide,
  handleStop,
  handleStart,
  appointment_data,
}) => {
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );

  const joinVideoCall = () => {
    if (appointment_type === "video-call") {
      const win = window.open(`/video-chat/${id}`, "_blank");
      win.focus();
    }
  };

  const dispatch = useDispatch();
  const [pageData, setPageData] = useState(null);
  const [canSendMessage, setCanSendMessage] = useState(false);
  const [roomId, setRoomId] = useState(null);
  const fetchAppointmentChatDetails = async (id) => {
    const q = query(
      collection(db, "chatRooms"),
      where("appointment_id", "==", id)
    );
    const querySnapshot = await getDocs(q);

    const roomId = querySnapshot.docs[0].id;
    const roomsRef = doc(db, "chatRooms", roomId);
    return dispatch(
      fetchChatMessages(roomsRef, "", (res) => {
        if (res) {
          dispatch(
            getSelectedRoomData("group", roomId, (res) => {
              setRoomId(roomId);
            })
          );
        }
      })
    );
  };
  const getPageData = () => {
    dispatch(
      getAppointmentDetails(id, (res) => {
        setPageData(res?.id ? res : null);
        console.log(res)
        if (res.appointment_type === "video-call") {
          const unsub = fetchAppointmentChatDetails(res?.id);
          if (
            !res?.appointment_end ||
            (res.appointment_end &&
              moment().isBefore(moment(res?.appointment_end).add(48, "hours")))
          ) {
            setCanSendMessage(true);
          } else {
            setCanSendMessage(false);
          }
          return unsub
        }
        else return () => {}
      })
    );
  };

  const closePanel = () => {
    onHide();
    setRoomId(null);
    dispatch({ type: "MESSAGE_LIST", payload: [] });
    dispatch({ type: "SELECT_ROOM", payload: {} });
  };

  useEffect(() => {
    const unsub = getPageData();
    // return (() => {if(unsub)unsub()})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    appointment_date,
    appointment_timings,
    appointment_type,
    doctor_id = {},
    reason,
    description,
    appointment_status,
    appointment_start, appointment_end,
    notes,
    patient_intake_forms = [],
  } = pageData || {};


  return (
    <Offcanvas show={true} placement="end" onHide={closePanel}>
      <Offcanvas.Body className="p-0 ps-1 pe-1">
        <div id="appointment-details-container">
          <div className="p-1">
            <div className="fb-center mb-2 gap-4">
              {/* <div className="text-18-600 color-2E65">
                Schedule{" "}
                {`${moment(
                  appointment_date,
                  "DD-MM-YYYY"
                ).format("MM/DD/YYYY")} ${getAppointmentTimingDisplayValues(
                  appointment_timings
                )?.join(", ")} `}
              </div> */}
              <div className="d-flex cursor-pointer" onClick={closePanel}>
                <IoMdClose size={20} />
              </div>
            </div>
            <div className="appointment-schedule-information-card">
              <div className="text-18-600 color-2E65 mb-2">
                Schedule Information
              </div>
              <div className="">
                <div className="fa-center mb-1">
                  <div className="w-99 text-14-500 color-2E65 mb-1">
                    Schedule
                  </div>
                  <div className="text-14-400 color-2E65 mb-1">{`${moment(
                    appointment_date,
                    "DD-MM-YYYY"
                  ).format("MM/DD/YYYY")} ${getAppointmentTimingDisplayValues(
                    appointment_timings
                  ).join(", ")} `}</div>
                </div>
                <div className="fa-center mb-1">
                  <div className="w-99 text-14-500 color-2E65">
                    Service Type
                  </div>
                  <div className="text-14-400 color-2E65">{`${appointment_type}`}</div>
                </div>
                <div className="fa-center mb-1">
                  <div className="w-99 text-14-500 color-2E65">Doctor</div>
                  <div className="text-14-400 color-2E65">{`${doctor_id?.title} ${doctor_id?.firstname} ${doctor_id?.lastname}`}</div>
                </div>
                <div className="fa-center mb-1">
                  <div className="w-99 text-14-500 color-2E65">Reason</div>
                  <div className="text-14-400 color-2E65">{`${reason}`}</div>
                </div>
                <div className="d-flex mb-1">
                  <div className="w-99 text-14-500 color-2E65">Description</div>
                  <div className="text-14-400 color-2E65 info-block-con">{`${
                    description || "--"
                  }`}</div>
                </div>

                
                
                <div className="btn-cell d-flex">
                {appointment_start ? (
                  appointment_end ? (
                    <div className={`appointment-status completed w-100 h-100`}>
                      {appointment_type === "surgery"
                        ? "Surgery"
                        : "Appointment"}{" "}
                      Completed
                    </div>
                  ) : appointment_type === "video-call"  && moment().isSameOrAfter(moment(`${appointment_timings?.[0]?.appointment_date} ${appointment_timings?.[0]?.slot_time}`, 'DD-MM-YYYY hh:mm a').subtract(5, 'minutes'))? (
                    <div
                      onClick={joinVideoCall}
                      className="appointment-status in-progress w-100 h-100"
                    >
                      Join Video Call
                    </div>
                  ) : (
                    <div className="appointment-status in-progress w-100 h-100">
                      {appointment_type === "surgery"
                        ? "Surgery"
                        : "Appointment"}{" "}
                      In Progress
                    </div>
                  )
                ) : (
                  appointment_type === "video-call"  && moment().isSameOrAfter(moment(`${appointment_data?.appointment_timings?.[0]?.appointment_date} ${appointment_data?.appointment_timings?.[0]?.slot_time}`, 'DD-MM-YYYY hh:mm a').subtract(5, 'minutes')) ? 
                  <Button
                    color="primary"
                    className="w-100 h-100"
                    onClick={() => {
                      handleStart();
                    }}
                  >
                    Start Video Call
                  </Button>
                  :<></>
                )}
              </div>
              </div>
            </div>
            {appointment_status && (
              <div className="appointment-schedule-status mt-2">
                <div className="text-18-600 color-2E65 mb-2">
                  Schedule Status
                </div>
                <div className="status-block">{appointment_status}</div>
              </div>
            )}
            {notes && (
              <div className="doctor-notes mt-2">
                <div className="text-18-600 color-2E65 mb-2">Doctor Notes</div>
                <div className="text-14-400 color-2E65 w-350">{notes}</div>
              </div>
            )}
            {patient_intake_forms?.length > 0 && (
              <div className="patient-intake-card mt-2">
                <div className="txt">
                  <h2>Patient Intake Form</h2>
                </div>
                {patient_intake_forms.map((elm, index) => {
                  const { form_title, form_url } = elm;
                  return (
                    <div
                      className="txt intake-form d-flex flex-row"
                      key={index}
                    >
                      {/* <p>{form_title}</p> */}
                      <div className="fa-center gap-2">
                        <div className="h-20 d-flex">
                          <img src={pdfFile} alt="pdfFile" />
                        </div>
                        <div className="text-16-400 color-2E65">
                          {form_title}
                        </div>
                      </div>
                      <div className="intake-options d-flex flex-row">
                        {form_url && (
                          <div className="fa-center gap-3 cursor-pointer">
                            <div
                              onClick={() => {
                                dispatch({
                                  type: "SET_FILE_VIEWER_DATA",
                                  data: {
                                    title: form_title,
                                    fileURL: form_url,
                                  },
                                });
                              }}
                            >
                              <MdOutlineRemoveRedEye
                                size={20}
                                color="#919191"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {/* <div className="appointment-schedule-information-card">
            <div className="text-18-600 color-2E65 mb-2">
              Schedule Information
            </div>
            <div className="">
              <div className="fa-center mb-1">
                <div className="w-99 text-14-500 color-2E65 mb-1">Schedule</div>
                <div className="text-14-400 color-2E65 mb-1">{`${moment(
                  appointment_data?.appointment_date,
                  "DD-MM-YYYY"
                ).format("MM/DD/YYYY")} ${getAppointmentTimingDisplayValues(
                  appointment_data?.appointment_timings
                )?.join(", ")} `}</div>
              </div>
              <div className="fa-center mb-1">
                <div className="w-99 text-14-500 color-2E65">Service Type</div>
                <div className="text-14-400 color-2E65">{`${appointment_data?.appointment_type}`}</div>
              </div>
              <div className="fa-center mb-1">
                <div className="w-99 text-14-500 color-2E65">Doctor</div>
                <div className="text-14-400 color-2E65">
                  <span className="mr-1">{doctor_id?.title} </span>{" "}
                  <>
                    {" "}
                    {clinic_settings?.doctor_name_format === 0 ? (
                      <>
                        {" "}
                        <span className="mr-1">
                          {" "}
                          {doctor_id?.lastname}
                        </span> , <span> {doctor_id?.firstname}</span>{" "}
                      </>
                    ) : (
                      <>
                        {" "}
                        <span className="mr-1">
                          {doctor_id?.firstname}{" "}
                        </span>{" "}
                        <span className=""> {doctor_id?.lastname} </span>{" "}
                      </>
                    )}{" "}
                  </> */}
                  {/* {`${doctor_id?.title} ${doctor_id?.firstname} ${doctor_id?.lastname}`} */}
                {/* </div>
              </div>
              <div className="fa-center mb-1">
                <div className="w-99 text-14-500 color-2E65">Reason</div>
                <div className="text-14-400 color-2E65">{`${appointment_data?.reason}`}</div>
              </div>
              <div className="d-flex mb-1">
                <div className="w-99 text-14-500 color-2E65">Description</div>
                <div className="text-14-400 color-2E65 info-block-con">{`${
                  appointment_data?.description || "--"
                }`}</div>
              </div>
              <div className="btn-cell d-flex">
                {appointment_data?.appointment_start ? (
                  appointment_data?.appointment_end ? (
                    <div className={`appointment-status completed w-100 h-100`}>
                      {appointment_data?.appointment_type === "surgery"
                        ? "Surgery"
                        : "Appointment"}{" "}
                      Completed
                    </div>
                  ) : appointment_data?.appointment_type === "video-call"  && moment().isSameOrAfter(moment(`${appointment_data?.appointment_timings?.[0]?.appointment_date} ${appointment_data?.appointment_timings?.[0]?.slot_time}`, 'DD-MM-YYYY hh:mm a').subtract(5, 'minutes'))? (
                    <div
                      onClick={joinVideoCall}
                      className="appointment-status in-progress w-100 h-100"
                    >
                      Join Video Call
                    </div>
                  ) : (
                    <div className="appointment-status in-progress w-100 h-100">
                      {appointment_data?.appointment_type === "surgery"
                        ? "Surgery"
                        : "Appointment"}{" "}
                      In Progress
                    </div>
                  )
                ) : (
                  appointment_data?.appointment_type === "video-call"  && moment().isSameOrAfter(moment(`${appointment_data?.appointment_timings?.[0]?.appointment_date} ${appointment_data?.appointment_timings?.[0]?.slot_time}`, 'DD-MM-YYYY hh:mm a').subtract(5, 'minutes')) ? 
                  <Button
                    color="primary"
                    className="w-100 h-100"
                    onClick={() => {
                      handleStart();
                    }}
                  >
                    Start Video Call
                  </Button>
                  :<></>
                )}
              </div>
              <div className="action-btns d-flex">
                {appointment_data?.appointment_start ? (
                  appointment_data?.appointment_end ? (
                    <> </>
                  ) : (
                    <Button
                      color="link"
                      onClick={() => {
                        handleStop();
                      }}
                      className="flex-fill"
                    >
                      Stop
                    </Button>
                  )
                ) : (
                  <>
                    <Button color="link" className="flex-fill">
                      Cancel
                    </Button> */}
                    {/*<Button color="link" className="flex-fill">Delay</Button>*/}
                  {/* </> */}
                {/* )} */}
                {/*<Button color="link" className="flex-fill">Message</Button>*/}
              {/* </div>
            </div>
          </div> */}
          {/* {appointment_status && (
            <div className="appointment-schedule-status mt-2">
              <div className="text-18-600 color-2E65 mb-2">Schedule Status</div>
              <div className="status-block">{appointment_status}</div>
            </div>
          )}
          {notes && (
            <div className="doctor-notes mt-2">
              <div className="text-18-600 color-2E65 mb-2">Doctor Notes</div>
              <div className="text-14-400 color-2E65 w-350">{notes}</div>
            </div>
          )} */}
          {/* <div className="patient-intake-card mt-2">
            <div className="txt">
              <h2>Patient Intake Form</h2>
            </div>
            <div className="txt intake-form d-flex flex-row">
              <p>Intake Form</p>
              <div className="intake-options d-flex flex-row">
                <p>Completed</p>
                <Button color="link" className="share-btn p-0">
                  <i className="icomoon-share p-0"></i>
                </Button>
                <Button color="link" className=" p-0 download-btn">
                  <span className="icomoon-download"></span>
                </Button>
              </div>
            </div>
          </div> */}
          {roomId && appointment_type === "video-call" && (
            <div className="appointment-chat ">
              <ChatRoom
                roomId={roomId}
                isFullWidth={true}
                canSendMessage={canSendMessage}
              />
            </div>
          )}
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AppointmentDetails;
