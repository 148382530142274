import React, { useEffect, useRef } from "react";
import "./index.scss";
import { Formik } from "formik";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Row, Badge, Button, Col, Form, Label } from "react-bootstrap";
import SearchBlock2 from "shared/SearchBlock2";
import { IoSearchOutline } from "react-icons/io5";
import { RiDeleteBinFill, RiOilFill } from "react-icons/ri";
import { useState } from "react";
import { FormGroup, Input, InputGroup } from "reactstrap";
import NewProductSelect from "./NewProductSelect";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewOrder,
  getHomeMedicationOrders,
  getTreatmentByPetId,
  getTreatmentDetailById,
} from "../../../../../../../context/actions/patient-module/orders";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { profile_picture_size } from "../../../../../../../environment";
import { addNewTreatment } from "../../../../../../../context/actions/patient-module/orders";
import Select from "react-select";
import { elements } from "chart.js";
import { toast } from "react-toastify";
import { toasterConfig } from "../../../../../../../utils/constants";
import { LuMinusCircle } from "react-icons/lu";
import { MdInsertDriveFile } from "react-icons/md";
import { getClinicTreatmentPlan } from "context/actions/clinicSettings";

const TreatmentPlanAdd = ({
  show,
  handleClose,
  templateOptions,
  setTreatmentData,
}) => {
  const dispatch = useDispatch();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [clinicTemplateList, setClinicTemplateList] = useState([]);
  const [isNewProductSelect, setIsNewProductSelect] = useState(false);

  const [cardData, setCardData] = useState([]);

  const [titleName, setTitleName] = useState("");

  const [title, setTitle] = useState(false);

  const [treatmentPlans, setTreatmentPlans] = useState([]);

  const initalInputView = {
    isInfoEditing: "",
    isNewProductSelect: "",
    isFrequencyEditing: "",
    isorderTypeEditing: "",
    isStartDateEditing: "",
    isDurationEditing: "",
    isNotesEditing: "",
    isDurationTypeEditing: "",
  };
  const [viewInputs, setViewInputs] = useState(initalInputView);

  const handleInputViews = (key, i) => {
    setViewInputs((prev) => ({ ...initalInputView, [key]: i }));
  };

  const handleKeyPress = (event, index, name) => {
    if (event.key === "Enter") {
      handleInputViews(name, index);
    }
  };

  const userDetail = useSelector((state) => state.user.user_details);

  const params = useParams();
  const petId = params.petId;

  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );

  useEffect(() => {
    dispatch(
      getClinicTreatmentPlan(clinic_settings?.clinic_id, (response) => {
        let arr = [];
        response?.response_data?.map((data) => {
          let obj = { id: data?._id, label: data?.treatmentName };
          arr.push(obj);
        });
        setClinicTemplateList(response?.response_data);
        setTreatmentPlans(arr);
        setCardData([]);
      })
    );
  }, []);

  const setCardDataFn = (obj) => {
    let data = cardData.concat(obj);
    setCardData(data);
    setIsNewProductSelect(false);
  };

  const deleteItem = (index) => {
    let dupData = cardData.filter((data, i) => i !== index);
    const tempObj = Object.entries(viewInputs)
      .filter(([key, value]) => value === index)
      .reduce((acc, [key]) => {
        acc[key] = "";
        return acc;
      }, {});
    setViewInputs((prev) => ({ ...initalInputView, ...tempObj }));
    setCardData(dupData);
  };

  const handleOnFileChange = (theFileObj, i) => {
    let dupData = cardData.map((data, index) => {
      if (index === i) {
        data?.files.push(theFileObj);
      }
      return data;
    });
    setCardData(dupData);
  };

  const addCardData = (value, index, key) => {
    let data = [...cardData];
    data[index][key] = value;
    setCardData(data);
  };

  const handleTemplateSave = () => {
    // if(cardData.length <= 0) return;
    let endDate = new Date(cardData[0]?.start_date);
    if (cardData[0]?.duration_type === "Days") {
      endDate.setDate(endDate.getDate() + parseInt(cardData[0]?.duration));
    } else if (cardData[0]?.duration_type === "Weeks") {
      endDate.setDate(endDate.getDate() + parseInt(cardData[0]?.duration) * 7);
    }

    if (titleName === '') {
      toast.error('Treatment Plan Name is not allowed to be empty.', toasterConfig);
      return;
    }

    const instructionErr =
      cardData.filter((data) => data?.instructions === "").length > 0
        ? true
        : false;
    if (instructionErr)
      return toast.error(
        "Instruction is not allowed to be empty.",
        toasterConfig
      );

    const frequencyErr =
      cardData.filter((data) => data?.frequency === "").length > 0
        ? true
        : false;
    if (frequencyErr)
      return toast.error(
        "Frequency is not allowed to be empty.",
        toasterConfig
      );

    const startDateErr =
      cardData.filter((data) => data?.start_date === "").length > 0
        ? true
        : false;
    if (startDateErr)
      return toast.error(
        "Start Date is not allowed to be empty.",
        toasterConfig
      );

    const durationErr =
      cardData.filter((data) => data?.duration === "").length > 0
        ? true
        : false;
    if (durationErr)
      return toast.error("Duration is not allowed to be empty.", toasterConfig);

    const durationTypeErr = cardData.filter(data => data?.duration_type === '').length > 0 ? true : false;
    if (durationTypeErr) return toast.error('Duration type is not allowed to be empty.', toasterConfig);

    const orderTypeErr =
      cardData.filter((data) => data?.type === "product" && (data?.order_type === "" || data.order_type === undefined))?.length > 0
        ? true
        : false;
    if (orderTypeErr)
      return toast.error(
        "Order type is not allowed to be empty.",
        toasterConfig
      );

    // cardData.forEach((data, i) => {
    //   if(data?.instructions === '') {
    //     toast.error('Instruction is not allowed to be empty.', toasterConfig);
    //     return;
    //   }
    //   if(data?.frequency === '') {
    //     toast.error('Frequency is not allowed to be empty.', toasterConfig);
    //     return;
    //   }
    //   if(data?.start_date === '') {
    //     toast.error('Start Date is not allowed to be empty.', toasterConfig);
    //     return;
    //   }
    //   if(data?.duration === '') {
    //     toast.error('Duration is not allowed to be empty.', toasterConfig);
    //     return;
    //   }
    //   if(data?.duration_type === '') {
    //     toast.error('Duration Unit is not allowed to be empty.', toasterConfig);
    //     return;
    //   }
    //   if(data?.notes === '') {
    //     toast.error('Notes is not allowed to be empty.', toasterConfig);
    //     return;
    //   }
    // });

    let oldFiles = [];
    let newFiles = [];

    cardData[0]?.files.map((file) => {
      if (typeof file === "string") {
        oldFiles.push(file);
      } else if (typeof file === "object") {
        newFiles.push(file);
      }
      return <></>;
    });


    const allData = {
      pet_id: petId,
      created_by: userDetail?._id,
      treatment_type: cardData[0]?.type,
      treatmentName: titleName,
      instruction_name: cardData[0]?.instruction_name,
      instructions: cardData[0]?.instructions,
      name: cardData[0]?.type === "instruction" ? cardData[0]?.instruction_name : cardData[0]?.product_name,
      frequency: cardData[0]?.frequency,
      start_Date: moment(cardData[0]?.start_date, "YYYY-MM-DDThh:mm").format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      ),
      end_Date: moment(endDate, "YYYY-MM-DDThh:mm").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      // end_Date: endDate,
      duration: cardData[0]?.duration,
      duration_unit: cardData[0]?.duration_type,
      notes: cardData[0]?.notes,
      product_id: cardData[0]?.id,
      existAttachments: JSON.stringify(oldFiles),
      attachments: newFiles,
      order_type: cardData[0].order_type,
    };

    if (
      cardData[0]?.type === "product" ||
      (cardData[0]?.type === "instruction" && cardData[0]?.files.length === 0)
    ) {
      delete allData?.attachments;
    }
    if (cardData[0]?.type === "instruction") {
      delete allData?.product_id;
    }

    let formData = new FormData();
    for (const key in allData) {
      // formData.append(key, allData[key]);
      if (key === "attachments") {
        if (allData[key]?.length > 0) {
          allData?.attachments?.forEach((file) => {
            formData.append("attachments", file);
          });
        }
      } else {
        formData.append(key, allData[key]);
      }
    }

    dispatch(
      addNewTreatment(formData, (response) => {
        if (response?.status) {
          if (cardData.length > 1) {
            cardData.forEach((data, i) => {
              if (i > 0) {
                const endDate = new Date(data?.start_date);
                if (data?.duration_type === "Days")
                  endDate.setDate(endDate.getDate() + parseInt(data?.duration));
                else if (data?.duration_type === "Weeks")
                  endDate.setDate(
                    endDate.getDate() + parseInt(data?.duration) * 7
                  );

                let existFiles = [];
                let attachFiles = [];

                data?.files.map((file) => {
                  if (typeof file === "string") {
                    existFiles.push(file);
                  } else if (typeof file === "object") {
                    attachFiles.push(file);
                  }
                  return <></>;
                });
                const payload = {
                  treatment_id: response?.response_data,
                  pet_id: petId,
                  created_by: userDetail?._id,
                  treatment_type: data.type,
                  treatmentName: titleName,
                  instructions: data?.instructions,
                  frequency: data?.frequency,
                  name: data?.type === "instruction" ? data?.instruction_name : data?.product_name,
                  start_Date: data?.start_date,
                  end_Date: endDate,
                  duration: data?.duration,
                  duration_unit: data?.duration_type,
                  notes: data?.notes,
                  product_id: data?.id,
                  instruction_name: data?.instruction_name,
                  order_type: data?.order_type,
                  existAttachments: JSON.stringify(existFiles),
                  attachments: attachFiles,
                };
                if (
                  data?.type === "product" ||
                  (data?.type === "instruction" && data?.files?.length === 0)
                ) {
                  delete payload?.attachments;
                }
                if (data?.type === "instruction") {
                  delete payload?.product_id;
                  delete payload?.order_type;
                }

                let formData = new FormData();
                for (const key in payload) {
                  if (key === "attachments") {
                    if (payload[key]?.length > 0) {
                      payload.attachments?.forEach((file) => {
                        formData.append("attachments", file);
                      });
                    }
                  } else {
                    formData.append(key, payload[key]);
                  }
                }

                dispatch(addNewTreatment(formData, (responseNew) => { }));
              }
            });
          }
        }
        handleCloseBtn();
      })
    );
  };

  const handleCloseBtn = () => {
    setViewInputs({
      isInfoEditing: "",
      isNewProductSelect: "",
      isFrequencyEditing: "",
      isorderTypeEditing: "",
      isStartDateEditing: "",
      isDurationEditing: "",
      isNotesEditing: "",
      isDurationTypeEditing: "",
    });
    setTitleName("");
    setTitle(false);
    setCardData([]);
    dispatch(
      getTreatmentByPetId(petId, (response) => {
        setTreatmentData(response?.response_data);
      })
    );
    setSelectedTemplate("");
    dispatch(
      getClinicTreatmentPlan(clinic_settings?.clinic_id, (response) => {
        let arr = [];
        response?.response_data?.map((data) => {
          let obj = { id: data?._id, label: data?.treatmentName };
          arr.push(obj);
        });
        setClinicTemplateList(response?.response_data);
        setTreatmentPlans(arr);
        setCardData([]);
      })
    );
    handleClose();
  };

  const handleSelectTemplate = (e) => {
    setSelectedTemplate(e);
    const selectedPlanData = clinicTemplateList.find((ele) => ele._id === e.id);

    if (selectedPlanData?._id) {
      const tempCardData = [];
      //for append instruction
      selectedPlanData?.instructionDetails?.forEach((element) => {
        tempCardData.push({
          type: "instruction",
          id: "",
          product_name: element?.name,

          instructions: element?.instructions,
          frequency: "",
          start_date: "",
          duration: "",
          duration_type: "",
          notes: element?.notes,
          files: element?.attachments,
        });
      });
      //for append products
      selectedPlanData?.productDetails.forEach((product) => {
        tempCardData.push({
          type: "product",
          id: product?.product_id,
          product_name: product?.name,
          instructions: "",
          frequency: "",
          start_date: "",
          duration: "",
          duration_type: "",
          notes: product?.notes,
          files: [],
        });
      });
      setCardData((prev) => [...prev, ...tempCardData]);
    }
  };

  const deleteFile = (i, j) => {
    cardData[i].files.splice(j, 1);
    setCardData((prev) => [...cardData]);
  };

  return (
    <>
      <Offcanvas
        className="treatment_plan_add"
        show={show}
        onHide={handleCloseBtn}
        placement={"end"}
        style={{ maxWidth: '60%', minWidth: '60%' }}

      >
        {isNewProductSelect ? (
          <div
            style={{ minWidth: '60%', maxWidth: '60%' }} className={`medication-panel-wrapper fixed flex flex-col active`}
          >
            <NewProductSelect
              titleName={titleName}
              setIsNewProductSelect={setIsNewProductSelect}
              setCardDataFn={setCardDataFn}
            />
          </div>
        ) : (
          <>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <FormGroup className="input-wrapper mb-0">
                  {/*!title ? <p onClick={() => setTitle(true)}>{titleName === '' ? "[Treatment_plan_name_goes here]" : titleName}</p> :*/}
                  <Input
                    type="text"
                    value={titleName}
                    onChange={(e) => setTitleName(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") setTitle(false);
                    }}
                    placeholder="Treatment Plan Name"
                  />
                </FormGroup>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Row className="serach_header">
                {/* .filter(opt => opt.label.toLowerCase().includes(selectedTemplate?.label?.toLowerCase())) */}

                <Col md={6}>
                  <Select
                    isSearchable={true}
                    className="w-100 custom-select  font-['Inter']text-[#282E65] text-sm"
                    classNames={{
                      placeholder: () => "text-[#282E65] text-sm rounded-lg ",
                    }}
                    placeholder={"Select Template"}
                    options={treatmentPlans}
                    value={selectedTemplate}
                    onInputChange={(val) => {
                      setSelectedTemplate((prev) => ({ label: val }));
                    }}
                    onChange={handleSelectTemplate}
                  />
                  {/* <SearchBlock2 placeholder="Search Template" /> */}
                </Col>
                <Col md={6}>
                  <div className="d-flex justify-content-md-end justify-content-center   gap-2">
                    <Button
                      outline="true"
                      color="base-color"
                      className="w-[150px] btn btn-success "
                      onClick={() => {
                        setIsNewProductSelect(true);
                      }}
                    >
                      Add Product
                    </Button>

                    <Button
                      outline="true"
                      color="base-color"
                      className="w-[150px] btn btn-success "
                      onClick={() => {
                        setCardDataFn({
                          type: "instruction",
                          id: "",
                          product_name: "",
                          instructions: "",
                          frequency: "",
                          start_date: "",
                          duration: "",
                          duration_type: "",
                          notes: "",
                          files: [],
                        });
                      }}
                    // type="submit"
                    >
                      Add Instructions
                    </Button>
                  </div>
                </Col>
              </Row>
              <div className="instructions_box">
                {cardData.length > 0 &&
                  cardData?.map((data, i) => {
                    return (
                      <div className="instructions_row_box my-2" key={i}>
                        <Row className="my-2">
                          <Col md={5}>
                            <div className="instructions_left">
                              <div
                                style={{
                                  gap: "10px",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Badge style={{ width: "min-content" }}>
                                  {" "}
                                  {data?.type === "instruction"
                                    ? "Instructions"
                                    : "Product"}{" "}
                                </Badge>
                                <div>
                                  <label className="pb-1">Name:</label>
                                  <div className="flex px-4 py-3 bg-white rounded-lg border border-indigo-950 border-opacity-10 justify-start items-center gap-2 mb-2 ">
                                    <div className="grow  text-base font-normal">
                                      {data?.product_name &&
                                        data?.product_name !== "" ? (
                                        data?.product_name
                                      ) : (
                                        <>
                                          <input
                                            type="text"
                                            value={data?.instruction_name}
                                            onChange={(e) =>
                                              addCardData(
                                                e.target.value,
                                                i,
                                                "instruction_name"
                                              )
                                            }
                                            placeholder="Enter Instruction Name"
                                          />
                                        </>
                                      )}
                                    </div>
                                    {/*<div className="justify-center items-center gap-2.5 flex">
                                        <div className="w-[52px] text-right text-indigo-600 text-sm font-normal font-['Inter'] leading-[21px]">
                                          Change
                                        </div>
                      </div>*/}
                                  </div>
                                </div>
                              </div>
                              {data?.type === "instruction" && (
                                <div className="add_fils d-flex flex-wrap gap-3 my-2">
                                  {data?.files?.map((file, j) => {
                                    const extensionKey = !file?.name ? file : file?.name;
                                    const sliceFileURL = extensionKey.split("?")?.[0];
                                    if (['jpg', 'jpeg', 'png', 'gif'].includes(sliceFileURL.substring(sliceFileURL.lastIndexOf('.') + 1))) {
                                      const imgPath = file?.name ? URL.createObjectURL(file) : file
                                      return (
                                        <div
                                          className="position-relative"
                                          key={j}
                                        >
                                          <img
                                            className="file_box"
                                            src={imgPath}
                                            alt="img"
                                          />
                                          <span
                                            className="file-delete-icon"
                                            onClick={() => {
                                              deleteFile(i, j);
                                            }}
                                          >
                                            <LuMinusCircle color={"red"} />
                                          </span>
                                        </div>
                                      );
                                    } else if (['pdf'].includes(sliceFileURL.substring(sliceFileURL.lastIndexOf('.') + 1))) {
                                      return (
                                        <div
                                          className="position-relative"
                                          key={j}
                                        >
                                          <img
                                            className="file_box"
                                            src={require("../../../../../../../assets/img/pdf.png")}
                                            alt="img"
                                          />
                                          <span
                                            className="file-delete-icon"
                                            onClick={() => {
                                              deleteFile(i, j);
                                            }}
                                          >
                                            <LuMinusCircle color={"red"} />
                                          </span>
                                        </div>
                                      );
                                    } else if (['mp3', 'mp4'].includes(sliceFileURL.substring(sliceFileURL.lastIndexOf('.') + 1))) {
                                      return (
                                        <div
                                          className="position-relative"
                                          key={j}
                                        >
                                          <img
                                            className="file_box"
                                            src={require(`../../../../../../../assets/img/mp4.png`)}
                                            alt="img"
                                          />
                                          <span
                                            className="file-delete-icon"
                                            onClick={() => {
                                              deleteFile(i, j);
                                            }}
                                          >
                                            <LuMinusCircle color={"red"} />
                                          </span>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          className="position-relative"
                                          key={j}
                                        >
                                          <MdInsertDriveFile size={50} />
                                          <span
                                            className="file-delete-icon"
                                            onClick={() => {
                                              deleteFile(i, j);
                                            }}
                                          >
                                            <LuMinusCircle color={"red"} />
                                          </span>
                                        </div>
                                      );
                                    }
                                  })}
                                  {/* <img
                                  className="file_box"
                                  src={require("../../../../../../../assets/img/pdf.png")}
                                  alt="img"
                                />

                                <img
                                  className="file_box"
                                  src={require("../../../../../../../assets/img/mp4.png")}
                                  alt="img"
                                /> */}

                                  <button type="button" class="add_file_btn">
                                    <i class="icomoon-plus-btn"></i>
                                    <Input
                                      type="file"
                                      id="myfile"
                                      name="myfile"
                                      onChange={(e) =>
                                        handleOnFileChange(e.target.files[0], i)
                                      }
                                    />
                                  </button>
                                </div>
                              )}
                            </div>
                          </Col>
                          <Col md={7}>
                            <div className="instructions_right">
                              <div className="info_box">
                                <div className="title">
                                  <label>
                                    Instructions<span>*</span>
                                  </label>
                                </div>
                                <div className="add_info">
                                  {viewInputs.isInfoEditing === i ? (
                                    <>
                                      <FormGroup className="input-wrapper mb-0">
                                        <Input
                                          type="text"
                                          value={data?.instructions}
                                          onChange={(e) =>
                                            addCardData(
                                              e.target.value,
                                              i,
                                              "instructions"
                                            )
                                          }
                                          onKeyPress={(e) =>
                                            handleKeyPress(e, i, "info")
                                          }
                                          placeholder="Instructions"
                                        />
                                      </FormGroup>
                                    </>
                                  ) : (
                                    <>
                                      {data?.instructions ? (
                                        <>
                                          <p
                                            onClick={() => {
                                              handleInputViews(
                                                "isInfoEditing",
                                                i
                                              );
                                            }}
                                          >
                                            {data?.instructions}
                                          </p>
                                        </>
                                      ) : (
                                        <label
                                          onClick={() => {
                                            handleInputViews(
                                              "isInfoEditing",
                                              i
                                            );
                                          }}
                                        >
                                          Add Info
                                        </label>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>

                              <div className="info_box">
                                <div className="title">
                                  <label>
                                    Frequency<span>*</span>
                                  </label>
                                </div>
                                <div className="add_info">
                                  {viewInputs.isFrequencyEditing === i ? (
                                    <InputGroup>
                                      <Input
                                        placeholder="Select Duration"
                                        value={data?.frequency}
                                        onKeyPress={(e) =>
                                          handleKeyPress(
                                            e,
                                            i,
                                            "isFrequencyEditing"
                                          )
                                        }
                                        onChange={(e) =>
                                          addCardData(
                                            e.target.value,
                                            i,
                                            "frequency"
                                          )
                                        }
                                        type="select"
                                      >
                                        <option>Select Frequency</option>
                                        <option>q5m</option>
                                        <option>q15m</option>
                                        <option>q30m</option>
                                        <option>q2h</option>
                                        <option>q4h</option>
                                        <option>q6h</option>
                                        <option>q8h</option>
                                        <option>q12h</option>
                                        <option>q24h</option>
                                        <option>daily</option>
                                        <option>daily at bedtime</option>
                                        <option>BID</option>
                                        <option>TID</option>
                                        <option>QID</option>
                                        <option>every other day</option>
                                        <option>once</option>
                                      </Input>
                                    </InputGroup>
                                  ) : (
                                    <>
                                      {data?.frequency ? (
                                        <p
                                          onClick={() => {
                                            handleInputViews(
                                              "isFrequencyEditing",
                                              i
                                            );
                                          }}
                                        >
                                          {" "}
                                          {data?.frequency}{" "}
                                        </p>
                                      ) : (
                                        <label
                                          onClick={() => {
                                            handleInputViews(
                                              "isFrequencyEditing",
                                              i
                                            );
                                          }}
                                        >
                                          Add Info
                                        </label>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>

                              <div className="info_box">
                                <div className="title">
                                  <label>
                                    Start Date<span>*</span>
                                  </label>
                                </div>
                                <div className="add_info">
                                  {viewInputs.isStartDateEditing === i ? (
                                    <FormGroup className="input-wrapper mb-0">
                                      <div className="form-floating position-relative text-input">
                                        <Input
                                          type="datetime-local"
                                          value={data?.start_date}
                                          onKeyPress={(e) =>
                                            handleKeyPress(
                                              e,
                                              i,
                                              "isStartDateEditing"
                                            )
                                          }
                                          onChange={(e) =>
                                            addCardData(
                                              e.target.value,
                                              i,
                                              "start_date"
                                            )
                                          }
                                          className="form-control"
                                          placeholder="Enter Start Date"
                                        />
                                      </div>
                                    </FormGroup>
                                  ) : (
                                    <>
                                      {data?.start_date ? (
                                        <p
                                          onClick={() => {
                                            handleInputViews(
                                              "isStartDateEditing",
                                              i
                                            );
                                          }}
                                        >
                                          {" "}
                                          {data?.start_date}{" "}
                                        </p>
                                      ) : (
                                        <label
                                          onClick={() => {
                                            handleInputViews(
                                              "isStartDateEditing",
                                              i
                                            );
                                          }}
                                        >
                                          Add Info
                                        </label>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>

                              <div className="info_box">
                                <div className="title">
                                  <label>
                                    Duration<span>*</span>
                                  </label>
                                </div>
                                <div className="add_info">
                                  {viewInputs.isDurationEditing === i ? (
                                    <div className="right-col flex-fill">
                                      <InputGroup>
                                        <Input
                                          type="number"
                                          min="1"
                                          placeholder="Add Duration"
                                          value={data?.duration}
                                          onKeyPress={(e) =>
                                            handleKeyPress(
                                              e,
                                              i,
                                              "isDurationEditing"
                                            )
                                          }
                                          onChange={(e) =>
                                            addCardData(
                                              e.target.value,
                                              i,
                                              "duration"
                                            )
                                          }
                                        />
                                        <Input
                                          placeholder="Select Duration"
                                          type="select"
                                          value={data?.duration_type}
                                          onKeyPress={(e) =>
                                            handleKeyPress(
                                              e,
                                              i,
                                              "isDurationTypeEditing"
                                            )
                                          }
                                          onChange={(e) =>
                                            addCardData(
                                              e.target.value,
                                              i,
                                              "duration_type"
                                            )
                                          }
                                        >
                                          <option>Select Unit</option>
                                          <option>Days</option>
                                          <option>Weeks</option>
                                        </Input>
                                      </InputGroup>
                                    </div>
                                  ) : (
                                    <>
                                      {data?.duration && data?.duration_type ? (
                                        <p
                                          onClick={() => {
                                            handleInputViews(
                                              "isDurationEditing",
                                              i
                                            );
                                          }}
                                        >
                                          {data?.duration} -{" "}
                                          {data?.duration_type}
                                        </p>
                                      ) : (
                                        <label
                                          onClick={() => {
                                            handleInputViews(
                                              "isDurationEditing",
                                              i
                                            );
                                          }}
                                        >
                                          Add Info
                                        </label>
                                      )}
                                    </>
                                  )}

                                  {/* Display the duration based on user input */}
                                </div>
                              </div>
                              {data?.type === "product" &&
                                <div className="info_box">
                                  <div className="title">
                                    <label>
                                      Order type<span>*</span>
                                    </label>
                                  </div>
                                  <div className="add_info">
                                    {viewInputs.isorderTypeEditing === i ? (
                                      <InputGroup>
                                        <Input
                                          placeholder="Select Duration"
                                          value={data?.order_type}
                                          onKeyPress={(e) =>
                                            handleKeyPress(
                                              e,
                                              i,
                                              "isorderTypeEditing"
                                            )
                                          }
                                          onChange={(e) =>
                                            addCardData(
                                              e.target.value,
                                              i,
                                              "order_type"
                                            )
                                          }
                                          type="select"
                                        >
                                          <option value="">Select order type</option>
                                          <option value="in-house">In House</option>
                                          <option value="e-prescribe">E-prescribe</option>
                                          <option value="print-script">Print-script</option>
                                        </Input>
                                      </InputGroup>
                                    ) : (
                                      <>
                                        {data?.order_type ? (
                                          <p
                                            onClick={() => {
                                              handleInputViews(
                                                "isorderTypeEditing",
                                                i
                                              );
                                            }}
                                          >
                                            {" "}
                                            {data?.order_type}{" "}
                                          </p>
                                        ) : (
                                          <label
                                            onClick={() => {
                                              handleInputViews(
                                                "isorderTypeEditing",
                                                i
                                              );
                                            }}
                                          >
                                            Add Info
                                          </label>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              }

                              {/* <div className="info_box">
                                <div className="title">
                                  <label>Notes</label>
                                </div>
                                <div className="add_info">
                                  {viewInputs.isNotesEditing === i ? (
                                    <textarea
                                      name=""
                                      className="100%"
                                      id=""
                                      value={data?.notes}
                                      onKeyPress={(e) =>
                                        handleKeyPress(e, i, "isNotesEditing")
                                      }
                                      onChange={(e) =>
                                        addCardData(e.target.value, i, "notes")
                                      }
                                      rows="5"
                                    ></textarea>
                                  ) : (
                                    <>
                                      {data?.notes ? (
                                        <p
                                          onClick={() => {
                                            handleInputViews(
                                              "isNotesEditing",
                                              i
                                            );
                                          }}
                                        >
                                          {data?.notes}{" "}
                                        </p>
                                      ) : (
                                        <label
                                          onClick={() => {
                                            handleInputViews(
                                              "isNotesEditing",
                                              i
                                            );
                                          }}
                                        >
                                          Add Info
                                        </label>
                                      )}
                                    </>
                                  )}
                                 
                                </div>
                              </div> */}
                            </div>
                          </Col>

                          <div style={{ width: '20px' }} className="delete_btn">
                            <img
                              className="file_box"
                              src={require("../../../../../../../assets/img/delete.png")}
                              alt="img"
                              onClick={() => deleteItem(i)}
                            />
                          </div>
                        </Row>
                      </div>
                    );
                  })}

                {cardData.length > 0 && (
                  <div className="d-flex gap-2 my-3 justify-content-end">
                    <Button
                      outline="true"
                      color="base-color"
                      className="w-[150px] btn btn-cancel "
                    >
                      Cancel
                    </Button>

                    <Button
                      outline="true"
                      color="base-color"
                      className="w-[150px] btn btn-primary "
                      onClick={handleTemplateSave}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </div>
            </Offcanvas.Body>
          </>
        )}
      </Offcanvas>
    </>
  );
};

export default TreatmentPlanAdd;
