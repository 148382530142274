const initState = {
  loading: false,
  orders: [],
  procedureOrders: [],
  inpatientOrders: [],
  homeMedicationOrders: [],
};

export const orderReducer = (state = initState, action) => {
  switch (action.type) {
    case "ORDERS_LOADING":
      return { ...state, loading: action.payload };

    case "ORDERS":
      return { ...state, orders: action.payload };

    case "PROCEDURES_ORDERS":
      return { ...state, procedureOrders: action.payload };

    case "INPATIENT_ORDERS":
      return { ...state, inpatientOrders: action.payload };

    case "HOME_MEDICATION_ORDERS":
      return { ...state, homeMedicationOrders: action.payload };

    default:
      return state;
  }
};
