import { useState } from "react";
import "./PetOwnerBilling.scss";
import InvoiceTab from "./petOwnerBillingTab/InvoiceTab";
import EstimateTab from "./petOwnerBillingTab/EstimateTab";
import { useSelector } from "react-redux";

const PetOwnerBilling = () => {
  const [Tab, setTab] = useState(1);
  const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);

  return (
    <>
      {clinic_settings?.pet_owner_preferences?.billing &&

      <div id="pet-owner-billing-container">
        <div className="head">
          <div class="text-24-700 color-2E65">Billing</div>
          
        </div>


        <ul class="n_tab">
          <li class="nav-item">
            <p
              class={`${Tab === 1 ? "active" : ""} av-link`}
              onClick={() => setTab(1)}
            >
              Invoice
            </p>
            <p
              class={`${Tab === 2 ? "active" : ""} av-link`}
              onClick={() => setTab(2)}
            >
              Estimate
            </p>
          </li>
        </ul>
        {Tab === 1 ? <InvoiceTab /> : <EstimateTab />}
      </div>}
    </>
  );
};

export default PetOwnerBilling;
