import { getAllCity, getAllSpecies } from "context/actions/app-content";
import { getAllPets } from "context/actions/patient-module/patient/pet";
import { useClinicSpecies } from "hooks/useClinicSpecies";
import {useClinicBreed} from "hooks/useClinicBreed"
import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "reactstrap";
import ListingFilter from "shared/Calendar/PatientListPanel/ListingFilter";
import ImagePlaceholder from "shared/ImagePlaceholder";
import NodataFoundBlock from "shared/NoDataFoundBlock";
import SortColumn from "shared/Sort/Column";
import { handleSortTable } from "utils/constants";

const PetSelection = ({ onHide, setPatient }) => {
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const dispatch = useDispatch();
  const [searchFilters, setSearchFilters] = useState(false);
  const [petLists, setPetLists] = useState(false);
  const [searchedPetLists, setSearchedPetLists] = useState(false);
  const clinicSpecies = useClinicSpecies()
  const clinicBreed = useClinicBreed();
  const handleFilterPetList = (searchVal) => {
    if (searchVal.length > 0) {
      const searchData = {
        ...searchFilters,
        pet_name: searchVal.toLowerCase(),
      };
      setSearchFilters(searchData);
      const filteredPet = handleFilterList(searchData);
      setSearchedPetLists(filteredPet);
    } else {
      setSearchedPetLists(petLists);
    }
  };

  const handleFilterList = (searchData) => {
    return petLists.filter(function (item) {
      for (var key in searchData) {
        let itemKey;
        if (key === "city") {
          itemKey = item["pet_owner"][key];
        } else {
          itemKey = item[key].toLowerCase();
        }
        if (itemKey === undefined || !itemKey.includes(searchData[key]))
          return false;
      }
      return true;
    });
  };
  const onSelectCity = (e) => {
    let value = e.target.value;
    if (value) {
      const searchData = { ...searchFilters, city: value };
      setSearchFilters(searchData);
      const filteredPet = handleFilterList(searchData);
      setSearchedPetLists(filteredPet);
    } else {
      setSearchedPetLists(petLists);
    }
  };

  const onSelectGender = (e) => {
    let value = e.target.value;
    if (value) {
      const searchData = { ...searchFilters, gender: value };
      setSearchFilters(searchData);
      const filteredPet = handleFilterList(searchData);
      setSearchedPetLists(filteredPet);
    } else {
      setSearchedPetLists(petLists);
    }
  };

  const onSelectSpecies = (e) => {
    let value = e.target.value;
    if (value) {
      const searchData = { ...searchFilters, species: value };
      setSearchFilters(searchData);
      const filteredPet = handleFilterList(searchData);
      setSearchedPetLists(filteredPet);
    } else {
      setSearchedPetLists(petLists);
    }
  };
  const handleColumnSort = (sortyOrder, columnName) => {
    const data = searchedPetLists.sort(handleSortTable(columnName, sortyOrder));
    setSearchedPetLists((searchedPetLists) => [...data]);
  };
  const handleGetAllPets = () => {
    dispatch(
      getAllPets(clinic_details?._id, {}, (result) => {
        if (result && !result.errors) {
          setPetLists(result);
          setSearchedPetLists(result);
        } else {
          setPetLists(false);
          setSearchedPetLists(false);
        }
      })
    );
  };
  useEffect(() => {
    handleGetAllPets();
    dispatch(getAllCity({}, () => {}));
    dispatch(getAllSpecies({}, () => {}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Offcanvas
      show
      onHide={onHide}
      placement="end"
      id="pet-selection-offcanvas"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Select Patient</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ListingFilter
          fieldVal={(value) => handleFilterPetList(value)}
          onSelectCity={onSelectCity}
          onSelectGender={onSelectGender}
          onSelectSpecies={onSelectSpecies}
        />
        <div className="inner overflow-auto">
          <div className="table-listing-blc overflow-auto">
            <Table responsive striped className="no-data-table">
              <thead>
                <tr>
                  <th>
                    <Button
                      color="link"
                      className="d-inline-flex align-items-center sort-btn"
                    >
                      Patient Info.
                      <SortColumn
                        sortLatest={() => handleColumnSort(-1, "pet_name")}
                        sortOldest={() => handleColumnSort(1, "pet_name")}
                      />
                    </Button>
                  </th>
                  <th style={{ minWidth: "150px" }}>
                    <Button
                      color="link"
                      className="d-inline-flex align-items-center sort-btn"
                    >
                      Animal / Breed
                      <SortColumn
                        sortLatest={() => handleColumnSort(-1, "species")}
                        sortOldest={() => handleColumnSort(1, "species")}
                      />
                    </Button>
                  </th>
                  <th>
                    <Button
                      color="link"
                      className="d-inline-flex align-items-center sort-btn"
                    >
                      Gender
                      <SortColumn
                        sortLatest={() => handleColumnSort(-1, "gender")}
                        sortOldest={() => handleColumnSort(1, "gender")}
                      />
                    </Button>
                  </th>
                  <th>
                    <Button
                      color="link"
                      className="d-inline-flex align-items-center sort-btn"
                    >
                      City
                      <SortColumn
                        sortLatest={() => handleColumnSort(-1, "city")}
                        sortOldest={() => handleColumnSort(1, "city")}
                      />
                    </Button>
                  </th>
                  {/* <th >Kennel</th> */}
                  <th style={{ width: "100px" }}>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {searchedPetLists && searchedPetLists.length > 0 ? (
                  searchedPetLists.map((pet, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <div className="text-start pic-cell d-inline-flex align-items-center">
                            <div className="pic d-inline-flex position-relative">
                              {pet?.pet_image ? (
                                <img
                                  className="img-fluid w-100 h-100"
                                  src={pet?.pet_image}
                                  alt="Patient"
                                />
                              ) : (
                                <ImagePlaceholder />
                              )}
                            </div>
                            {pet?.pet_name}
                          </div>
                        </td>
                        <td>
                          {clinicSpecies(pet?.breed?.species_id?._id) || pet?.breed?.species_id?.species_name} /{" "}
                          {clinicBreed(pet?.breed?._id) ||  pet?.breed?.breed}
                        </td>
                        <td>{pet?.gender === "male" ? "Male" : "Female"} </td>
                        <td>{pet?.pet_owner?.city || "--"}</td>
                        <td>
                          <Button
                            color="link"
                            onClick={() => {
                              setPatient(pet);
                            }}
                          >
                            Select
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="no-data-rw">
                    <td colSpan="5">
                      <NodataFoundBlock type={'search result'} />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default PetSelection;
