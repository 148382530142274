import React, { useEffect, useState } from "react";
import { Button, Table, Label, Input, Col, Row, FormGroup } from "reactstrap";
import { getAllAppointmentsByPatientId } from "../../../../../../context/actions/appointment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NodataFoundBlock from "../../../../../../shared/NoDataFoundBlock";
import moment from "moment";
import { noteInit, noteSchema } from "./schema";
import { Form, Formik, FieldArray, Field, ErrorMessage } from "formik";
import {
  addNewNote,
  getNotesByPetId,
} from "../../../../../../context/actions/patient-module/notes";
import ScheduleSelection from "./ScheduleSelection";
import TemplateSelection from "./TemplateSelection";
import SectionView from "../NoteDetail/SectionView";
import { getFormAndTemplates } from "context/actions/formTemplates";

const NewNotePanel = ({ isActive, toggleNotePanel, extend, closingFn }) => {
  const dispatch = useDispatch();
  const [appointments, setAppointments] = useState();
  const [vals, setVals] = useState();

  const [isSelectingTemplate, setIsSelectingTemplate] = useState(false);

  const params = useParams();
  const petId = params.petId;

  const userDetails = useSelector((state) => state.user.user_details);
  const {
    formTemplates: { patientnotestemplates },
  } = useSelector((state) => state.formTemplateReducer);

  const getAppointments = () => {
    dispatch(
      getAllAppointmentsByPatientId(petId, (response) => {
        setAppointments(response);
      })
    );
  };

  const handleSelectExtends = (note) => {
    setVals({
      appointment: note.appointment,
      extends: [...note.extends.map(i => i?._id), note?._id],
    });
  };

  const handleGetNotes = () => {
    dispatch(getNotesByPetId(petId, () => { }));
  };

  const getTemplates = () => {
    dispatch(getFormAndTemplates({}, () => { }));
  };

  const handleSelectAppointment = (selectedAppointment) => {
    setVals({ ...vals, appointment: selectedAppointment });
  };

  const submitFormCallback = (response) => {
    if (response?.status && !response?.errors) {
      setVals(undefined);
      closingFn();
      toggleNotePanel();
      handleGetNotes();
    }
  };

  const handleTemplateSelection = (template, formik) => {
    console.log(template)
    formik.setFieldValue(
      `sections.${formik.values.sections.length - 1}.fields`,
      template?.fields?.map((field) => {
        return {
          field: field,
          value: "",
        };
      })
    );
    formik.setFieldValue(
      `sections.${formik.values.sections.length - 1}.title`,
      template?.title
    );
    console.log(formik.values)

    setIsSelectingTemplate(false);
  };

  const submitForm = (values, { errors }) => {
    const addNoteDispatchFunction = addNewNote(
      {
        extends: extend != null ? vals.extends : undefined,
        appointment: vals.appointment._id,
        pet_id: petId,
        created_by: userDetails._id,
        sections: values.sections.map((section) => {
          return {
            title: section?.title,//patientnotestemplates?.[section?.template]?.title,
            fields: section?.fields,
          };
        }),
      },
      submitFormCallback
    );

    dispatch(addNoteDispatchFunction);
  };

  useEffect(() => {
    if (extend != null) {
      handleSelectExtends(extend);
    }
  }, [extend]);

  useEffect(() => {
    getTemplates();
    getAppointments();
  }, []);
  return (
    <>
      <div
        className={`overflow-auto notes-panel-wrapper fixed flex flex-col  ${isActive && "active"
          }`}
      >
        <div className="head d-flex align-items-center">
          <h2>{extend != null ? "Build Off" : "Create New"} Note</h2>
          <Button
            color="link"
            className="ms-auto panel-close-btn"
            onClick={() => {
              toggleNotePanel();
              setVals(undefined);
              closingFn();
            }}
          >
            <span className="material-symbols-outlined">close</span>
          </Button>
        </div>
        {/* select schedule modal section start */}
        {!extend && (
          <ScheduleSelection
            vals={vals}
            appointments={appointments}
            selectedAppointmentfn={handleSelectAppointment}
          />
        )}
        {/* select schedule modal section end */}

        {vals && (
          <>
            {extend && (
              <div style={{ marginBottom: '5px' }}>
                <div className=" w-full flex items-center justify-between p-[10px] rounded-md bg-[#F8F8F8]/90 mb-2">
                  {vals.appointment && (
                    <span className="text-sm mb-0">
                      Schedule :{" "}
                      {moment(
                        vals.appointment.appointment_date,
                        "DD-MM-YYYY"
                      ).format("MMM D, YYYY")}{" "}
                      | {vals.appointment.appointment_time}
                    </span>
                  )}
                </div>
                {
                  extend.sections.map((section) => (
                    <>
                      {/*  <p style={{fontSize:'13px'}}>{moment(extend?.createdAt).format('MM/DD/YYYY hh:mm a')}</p> */}


                      <SectionView isExtend={true} section={section} /></>))}

                {extend?.extends?.map((item, i) => {
                  return (
                    <div>

                      {/*<p style={{fontSize:'13px'}}>{moment(item?.createdAt).format('MM/DD/YYYY hh:mm a')}</p>*/}
                      {
                        item?.sections?.map((section, i) =>
                          <SectionView isExtend={true} key={i} section={section} />)}
                    </div>)
                }
                )}
              </div>
            )}
            <Formik
              initialValues={noteInit}
              enableReinitialize
              validationSchema={noteSchema}
              onSubmit={submitForm}
            >
              {(formik) => (
                <Form style={{ height: '100%' }}>
                  <FieldArray name="sections">
                    {({ remove, push }) => (
                      <>
                        {isSelectingTemplate && (
                          <TemplateSelection
                            patientnotestemplates={patientnotestemplates}
                            selectTemplatefn={(templateIndex) => {
                              handleTemplateSelection(templateIndex, formik);
                            }}
                          />
                        )}

                        <div
                          style={{
                            display: isSelectingTemplate ? "none" : "flex",
                          }}
                          className={`note-detail-wrapper flex-column `}
                        >
                          {formik?.values?.sections?.length > 0 &&
                            formik?.values?.sections?.map((section, index) => (
                              <FormGroup
                                className="note-content-block content-box d-flex flex-column"
                                key={index}

                              >
                                {formik?.values?.sections?.[index]?.title && (
                                  <div className="flex justify-between mb-2">
                                    <h2 className="capitalize font-bold text-md">
                                      {section.title}
                                    </h2>
                                    <button
                                      className="hover:drop-shadow-md"
                                      onClick={() => remove(index)}
                                    >
                                      <img
                                        className="img-fluid w-100 h-100"
                                        src={
                                          require("../../../../../../assets/img/delete.svg")
                                            .default
                                        }
                                        alt="Badge"
                                      />
                                    </button>
                                  </div>
                                )}

                                {formik?.values?.sections?.[index]?.fields?.map(
                                  (field, j) => (
                                    <>
                                      <Row className="flex mb-2">
                                        <div className="row-block flex items-center w-full">
                                          <label
                                            for={`${patientnotestemplates?.[
                                              formik?.values?.sections?.[
                                                index
                                              ]?.template
                                            ]?.title
                                              }-${field?.field}`}
                                            className=" w-36 mr-4 text-sm"
                                          >
                                            {field?.field}
                                          </label>
                                          <div className="right-col flex-fill">
                                            <textarea
                                              onChange={(e) =>
                                                formik.handleChange(e)
                                              }
                                              className="resize-y rounded-md outline outline-1 outline-[#0909541F] p-2"
                                              rows="2"
                                              cols="40"
                                              id={`${patientnotestemplates?.[
                                                formik?.values?.sections?.[
                                                  index
                                                ]?.template
                                              ]?.title
                                                }-${field?.field}`}
                                              name={`sections.${index}.fields.${j}.value`}
                                              tag={Field}
                                            />
                                          </div>
                                        </div>
                                      </Row>

                                      <div className="w-full flex justify-end">
                                        <span className="text-sm text-red-400">
                                          {
                                            formik.errors?.sections?.[index]
                                              ?.fields?.[j]?.value
                                          }
                                        </span>
                                      </div>
                                    </>
                                  )
                                )}
                              </FormGroup>
                            ))}
                          <Row>
                            <div className="w-full">
                              <Button
                                onClick={() => {

                                  push({
                                    title: "",
                                    template: "Select a template",
                                    fields: [],
                                  });
                                  setIsSelectingTemplate(true);
                                }}
                                color="link"
                                className="add-section-note-btn d-flex justify-content-center w-full"
                              >
                                <span className="icomoon-plus-btn"></span>
                              </Button>
                            </div>
                          </Row>

                          {formik.errors.sections &&
                            typeof formik.errors.sections === "string" && (
                              <span className="text-sm text-red-400">
                                {formik.errors.sections}
                              </span>
                            )}

                          <div className="flex w-full justify-end p-4 gap-4">
                            <Button
                              onClick={() => {
                                toggleNotePanel();
                                setVals(undefined);
                                closingFn();
                              }}
                              outline
                              color="base-color"
                              className="w-[150px] "
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              color="success"
                              className="w-[150px] "
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </FieldArray>
                </Form>
              )}
            </Formik>
          </>
        )}
      </div>
    </>
  );
};

export default NewNotePanel;
