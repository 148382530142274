import { toast } from "react-toastify";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";

export const getOperatingRoomsByClinicId = (clinic_id, params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();
    ApiClient.get(
      `${apiUrl}${PORT}/operating-room/get-all-operating-rooms-by-clinic/${clinic_id}`,
      params,
      token,
      dispatch, getState
    ).then((res) => {
      if (res) {
        dispatch({ type: "GET_OPERATING_ROOMS", payload: res?.response_data });
        return callback(res?.status);
      } else {
        dispatch({ type: "GET_OPERATING_ROOMS", payload: [] });

        toast.error("Kennels are not fetched");
        return callback(res?.status);
      }
    });
  };
};

export const getOperatingRoomsByClinicIdWithAppointments = (
  clinic_id,
  params,
  callback
) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();
    ApiClient.get(
      `${apiUrl}${PORT}/operating-room/get-all-operating-rooms-by-clinic-with-appointments/${clinic_id}`,
      params,
      token,
      dispatch, getState
    ).then((res) => {
      if (res) {
        dispatch({ type: "GET_OPERATING_ROOMS", payload: res?.response_data });
        return callback(res);
      } else {
        dispatch({ type: "GET_OPERATING_ROOMS", payload: [] });

        toast.error("Operating rooms data are not fetched");
        return callback(res);
      }
    });
  };
};

export const deleteOperatingRooms = (id) => async (dispatch, getState) => {
  const {
    user: { token }
  } = getState();
  const response = await ApiClient.put(
    `${apiUrl}${PORT}/operating-room/delete-operating-room-details/${id}`,
    {},
    token,
    dispatch, getState
  );
  return response;
};


export const getSurgeriesByClinicId = (callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    ApiClient.get(
      `${apiUrl}${PORT}/surgery/get-all-surgeries-by-clinic/${clinic_details?._id}`,
      {},
      token,
      dispatch, getState
    ).then((res) => {
      if (res) {
        return callback(res);
      } else {
        toast.error("Surgery data are not fetched");
        return callback(res);
      }
    });
  };
}

export const addOperatingRoom = (body, callback) => {

  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/operating-room/add-new-operating-room`,
      body,
      token,
      dispatch, getState
    ).then((res) => {
      if (res) {
        return callback(res);
      } else {
        toast.error("Could not add new OR");
        return callback(res);
      }
    });
  };
}


export const updateOperatingRoom = (id, body, callback) => {

  return (dispatch, getState) => {
    const {
      user: { token }
    } = getState();
    ApiClient.put(
      `${apiUrl}${PORT}/operating-room/update-operating-room-details/${id}`,
      body,
      token,
      dispatch, getState
    ).then((res) => {
      if (res) {
        return callback(res);
      } else {
        toast.error("Could not update OR");
        return callback(res);
      }
    });
  };
}


