import { useEffect, useState } from "react";
import { BsFillPinAngleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";
import moment from "moment";
import {
  getOcularByPetId,
  addNewOcular,
  addOcularDetails,
  deleteParentOcular,
  deleteOcularDetails,
} from "context/actions/patient-module/flowsheets/ocular";
import archive from "assets/img/archive.png";
import NodataFoundBlock from "../../../../../../shared/NoDataFoundBlock";
import eyeImage from "../../../../../../assets/img/both-eye.webp";
import DeletePopup from "../DeletePopup";
import OcularCell from "./OcularCell";
import DatePopover from "../DatePopover";
// import FilterComponent from "../FilterComponent";
import FilterOptions from "../FilterOptions";
import "./Ocular.scss";
import Spinner from "shared/Spinner";

const ocularKeys = [
  {
    key: "eye",
    title: "Eye",
    options: [
      { value: "left", label: "Left" },
      { value: "right", label: "Right" },
      //{ value: "both eyes", label: "Both Eyes" },
    ],
  },
  {
    key: "added_date",
    title: "Added Date & Time",
  },
  {
    key: "lesion_size",
    title: "Lesion Size",
  },
  {
    key: "characteristics",
    title: "Characteristics",
  },
  {
    key: "treatment",
    title: "Treatment",
  },
  {
    key: "active",
    title: "Status",
  },
  {
    key: "notes",
    title: "Notes",
  },
];

const Ocular = ({ petId }) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.user_details);
  const [pageData, setPageData] = useState([]);
  const [deleteData, setDeleteData] = useState(null);
  const [editData, setEditData] = useState(null);
  const [selectingNewPoint, setSelectingNewPoint] = useState(false);
  const [point, setPoint] = useState(null);
  const [expandList, setExpandList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [optionType, setOptionType] = useState("2");
  const [filterData, setFilterData] = useState({
    searchText: "",
    selectedDates: [],
    date: "",
  });

  const handleExpand = (gIndex) => {
    let list = expandList;
    if (expandList.includes(gIndex)) {
      list = list.filter((o) => o !== gIndex);
    } else {
      list = [...list, gIndex];
    }
    setExpandList(list);
  };
  const selectPoint = (event) => {
    let dim = event.target.getBoundingClientRect();
    let x = event.clientX - dim.left;
    let y = event.clientY - dim.top;
    setPoint({ x: x, y: y });
  };
  const handleAddPoint = async () => {
    const response = await dispatch(
      addNewOcular({
        pet_id: petId,
        created_by: userDetails?._id,
        coordinate: point,
      })
    );
    if (response?.status) {
      handleExpand(pageData?.length);
      handleAddEditDetails({
        locationId: response?.response_data?._id,
        createdAt: moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      });
      setSelectingNewPoint(false);
      setPoint(null);
    }
  };
  const handleAddEditDetails = async (payload) => {
    const res = await dispatch(addOcularDetails(payload));
    if (res?.status) {
      fetchPageData(filterData);
    }
    // setEditData(null);
  };
  const handleWoundDelete = async (id) => {
    setDeleteData(null);
    const res = await dispatch(deleteParentOcular(id));
    if (res?.status) {
      setTimeout(() => {
        fetchPageData(filterData);
      }, 1000);
    }
  };
  const handleWoundDetailsDelete = async (data) => {
    setDeleteData(null);
    const res = await dispatch(deleteOcularDetails(data));
    if (res?.status) {
      setTimeout(() => {
        fetchPageData(filterData);
      }, 1000);
    }
  };
  const handleDelete = async () => {
    if (deleteData?.type === "1") {
      handleWoundDelete(deleteData?.locationId);
    } else {
      handleWoundDetailsDelete({
        locationId: deleteData?.locationId,
        locationDetailId: deleteData.locationDetailId,
      });
    }
  };
  const handleResponse = (resData) => {
    const newData = [];
    resData.forEach((o) => {
      const headerData = o?.locationDetails?.map((e) => {
        const isAnyVal =
          e?.eye ||
          e?.added_date ||
          e?.lesion_size ||
          e?.characteristics ||
          e?.treatment ||
          e?.notes;
        return {
          id: e?.locationDetailId,
          date: e?.createdAt || "",
          isValueExist: isAnyVal,
        };
      });
      let bodyData = [];
      if (o?.locationDetails?.length > 0) {
        bodyData = ocularKeys?.map((e) => {
          const data = o?.locationDetails?.map((elm) => {
            return {
              ...elm,
              value: elm?.[e?.key] || "",
            };
          });
          return { ...e, data: data };
        });
      }
      let newObject = {
        id: o?._id,
        coordinate: o?.coordinate || [],
        value: o?.locationDetails?.find((o) => o.eye)?.eye || "location_name",
        header: headerData,
        data: bodyData,
        deleted: o?.deleted,
      };
      newData.push(newObject);
    });
    setPageData(newData);
  };
  const fetchPageData = async (fData) => {
    setIsLoading(true);
    const res = await dispatch(
      getOcularByPetId({
        pet_id: petId,
        // filterDates: fData?.selectedDates,
      })
    );
    handleResponse(res?.response_data || []);
    setIsLoading(false);
  };
  useEffect(() => {
    if (filterData?.date) {
      fetchPageData(filterData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    let currentDate = moment().format("DD-MM-YYYY");
    let newData = {
      searchText: "",
      selectedDates: [currentDate],
      date: currentDate,
    };
    setFilterData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let displayData = pageData?.filter(
    (o) =>
      optionType === "1" ||
      (optionType === "2" && !o.deleted) ||
      (optionType === "3" && o.deleted)
  );
  displayData = displayData?.map((elm) => {
    return {
      ...elm,
      data: elm?.data?.filter((o) =>
        o.title?.toLowerCase().includes(filterData?.searchText?.toLowerCase())
      ),
    };
  });

  if (displayData?.every((o) => o?.data?.length === 0)) {
    displayData = [];
  }

  return (
    <div id="ocular-container">
      {deleteData?.type && (
        <DeletePopup
          handleDelete={handleDelete}
          onHide={() => {
            setDeleteData(null);
          }}
        />
      )}
      {/* {filterData?.date && (
        <FilterComponent
          filterData={filterData}
          setFilterData={setFilterData}
        />
      )} */}
      <div
        className="w-100 d-flex position-relative mt-2"
        onClick={selectPoint}
      >
        <img
          className="ocular-img"
          src={eyeImage}
          alt="left-eye"
          loading="lazy"
        />
        {selectingNewPoint && point?.x && (
          <div
            className="img-point"
            style={{
              left: `${point.x - 10}px`,
              top: `${point.y - 10}px`,
            }}
          />
        )}
        {!selectingNewPoint &&
          displayData?.length > 0 &&
          displayData?.map((o, i) => {
            return (
              <div
                key={i}
                onClick={() => {
                  handleExpand(i);
                }}
                className="img-point"
                style={{
                  left: `${o?.coordinate?.x - 10}px`,
                  top: `${o?.coordinate?.y - 10}px`,
                }}
              >
                {i + 1}
              </div>
            );
          })}
      </div>
      {isLoading ? (
        <div className="mx-auto">
          <Spinner height={150} />
        </div>
      ) : (
        <div className="ocular-body">
          <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="fa-center gap-3">
              <div className="head-title">Ocular</div>
            <FilterOptions
              firstOption="All Locations"
              optionType={optionType}
              onClick={(e) => {
                setOptionType(e);
              }}
            />
          </div>

            {selectingNewPoint ? (
              <div className="new-pin">
                <i
                  style={{ height: "20px", width: "20px" }}
                  onClick={() => {
                    setSelectingNewPoint(false);
                    setPoint(null);
                  }}
                  className="icomoon-cross-btn"
                ></i>

              <i
                style={{ height: "20px", width: "20px" }}
                onClick={() => {
                  handleAddPoint();
                }}
                className="icomoon-check-btn"
              ></i>
            </div>
          ) : (
            <div
              className="d-flex align-items-center gap-2 pin-span"
              onClick={() => {
                setPoint(null);
                setSelectingNewPoint(true);
              }}
            >
              <span>Pin New Location</span>
              <span className="pin">
                <BsFillPinAngleFill />
              </span>
            </div>
          )}
        </div>
        <div className="sub-title mb-3">
          Select a location on the eye to document lesions
        </div>
        {displayData?.length === 0 ? (
          <NodataFoundBlock />
        ) : (
          <Accordion activeKey={expandList} onSelect={handleExpand}>
            {displayData?.map((elm, index) => {
              const { id, value, header, data, deleted } = elm;
              return (
                <Accordion.Item eventKey={index} key={index}>
                  <Accordion.Header>
                    <div className="d-flex fa-center justify-content-between w-100">
                      <div className="location-detail-block">
                        <div className="location-number">{index + 1}</div>
                        <div className="location-title capitalize">{value}</div>
                      </div>

                      {!deleted && (
                        <div className="d-flex align-items-center gap-2">
                          <div
                            className="h-19 d-flex"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setDeleteData({
                                locationId: id,
                                locationDetailId: "",
                                type: "1",
                              });
                            }}
                          >
                            <img src={archive} alt="archive" />
                          </div>
                          <div
                            className="d-flex"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleAddEditDetails({
                                locationId: id,
                                createdAt: moment().format(
                                  "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                ),
                              });
                            }}
                          >
                            <span className="icomoon-plus-btn" />
                          </div>
                        </div>
                      )}
                    </div>
                  </Accordion.Header>
                  {header?.length > 0 && (
                    <Accordion.Body>
                      <div className="head-row">
                        <div>Title</div>
                        {header?.map((dateElem, dateIndex) => {
                          const { date, isValueExist } = dateElem;
                          const cellDate = moment(
                            date,
                            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                          ).format("MM/DD/YYYY");
                          const cellTime = moment(
                            date,
                            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                          ).format("hh:mmA");
                          const isDateEdit =
                            editData?.locationDetailId === dateElem?.id &&
                            editData?.fieldId === "createdAt";

                          return (
                            <div
                              key={dateIndex}
                              className="d-flex gap-2 position-relative"
                            >
                              <span
                                className="cursor-pointer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  if (!deleted) {
                                    setEditData({
                                      locationId: id,
                                      locationDetailId: dateElem?.id,
                                      fieldId: "createdAt",
                                      fieldValue: moment(
                                        date,
                                        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                      ).format("YYYY-MM-DDTHH:mm"),
                                    });
                                  }
                                }}
                              >
                                {cellDate}
                                <br />
                                {cellTime}
                              </span>
                              {isDateEdit && (
                                <DatePopover
                                  defaultValue={date}
                                  value={editData?.fieldValue}
                                  setEditData={setEditData}
                                  onChange={(e) => {
                                    setEditData((prev) => {
                                      return {
                                        ...prev,
                                        fieldValue: e.target.value,
                                      };
                                    });
                                  }}
                                  handleSave={(e) => {
                                    handleAddEditDetails({
                                      locationId: editData.locationId,
                                      locationDetailId:
                                        editData.locationDetailId,
                                      createdAt: moment(
                                        editData.fieldValue,
                                        "YYYY-MM-DDTHH:mm"
                                      ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                                    });
                                  }}
                                />
                              )}
                              {!isValueExist && !deleted && (
                                <span
                                  className="remove_btn"
                                  onClick={() => {
                                    setDeleteData({
                                      locationId: id,
                                      locationDetailId: dateElem?.id,
                                      type: "2",
                                    });
                                  }}
                                >
                                  -
                                </span>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      {data?.map((rowElem, rowIndex) => {
                        return (
                          <div className="data-row" key={rowIndex}>
                            <div>{rowElem?.title}</div>
                            {rowElem?.data?.map((cellElem, cellIndex) => {
                              const isAnyFieldValue =
                                header[cellIndex]?.isValueExist;
                              return (
                                <div
                                  key={cellIndex}
                                  onClick={() => {
                                    if (
                                     ( (editData?.fieldId !== rowElem?.key ||
                                        editData?.locationDetailId !==
                                          cellElem?.locationDetailId) &&
                                      !deleted) && rowElem.key !== 'active'
                                    ) {
                                      const isDatePicker = [
                                        "added_date",
                                      ].includes(rowElem?.key);
                                      setEditData({
                                        locationId: id,
                                        locationDetailId:
                                          cellElem?.locationDetailId,
                                        fieldId: rowElem?.key,
                                        options: rowElem?.options,
                                        fieldValue: isDatePicker
                                          ? cellElem?.value
                                            ? moment(
                                                cellElem?.value,
                                                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                              ).format("YYYY-MM-DDTHH:mm")
                                            : ""
                                          : cellElem?.value,
                                      });
                                    }
                                  }}
                                >
                                  <OcularCell
                                    deleted={deleted}
                                    data={cellElem}
                                    isAnyFieldValue={isAnyFieldValue}
                                    cellTitle={rowElem?.title}
                                    cellKey={rowElem?.key}
                                    editData={editData}
                                    setEditData={setEditData}
                                    handleStatusChange={(e) => {
                                      handleAddEditDetails({
                                        locationId: id,
                                        locationDetailId:
                                          cellElem.locationDetailId,
                                        active: !e,
                                      });
                                    }}
                                    handleEditDetails={(e) => {
                                      handleAddEditDetails({
                                        locationId: e.locationId,
                                        locationDetailId: e.locationDetailId,
                                        [e.fieldId]: e.fieldValue,
                                      });
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </Accordion.Body>
                  )}
                </Accordion.Item>
              );
            })}
          </Accordion>
        )}
        
      </div>)}
    </div>
  );
};

export default Ocular;
