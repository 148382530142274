import React, {useState, useEffect} from "react";
import { Input } from "reactstrap";
import { BsSearch } from "react-icons/bs";
import "./index.scss";

const SearchBlock = ({ className = "", onChangeSearch }) => {
  const [changeFilter, setChangeFilter] = useState('');

  useEffect(() => {
    if(onChangeSearch) {
      onChangeSearch(changeFilter);
    }
  }, [changeFilter]);
  return (
    <>
      <div className={`search-blc position-relative input-cell ${className}`}>
        <Input type="text" placeholder="Search" onChange={(e) => setChangeFilter(e.target.value)} />
        <span className="position-absolute d-inline-flex align-items-center justify-content-center">
          <BsSearch className="h-4 w-4" />
        </span>
      </div>
    </>
  );
};

export default SearchBlock;
