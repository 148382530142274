import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "reactstrap";
import "./index.scss";
import { updateClinicTaskPanelAction, updateClinicTaskDetails } from "../../../context/actions/clinictask";
import { getAllClinicLiveAppointments } from "../../../context/actions/clinictask";
import { useFormik } from "formik";
import { updateTaskSchema } from "./schema";
import moment from "moment";


const UpdateClinicTask = ({className = "", appointment, task, fetchData }) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.user?.user_details);
  //const [task, setTask] = useState(null);
  const [status, setStatus] = useState(null);
  const {clinic_settings} = useSelector(state => state?.clinicSettingsReducer)

  const closeUpdateTaskPanel = () => {
      dispatch(updateClinicTaskPanelAction(false));
  };

  const taskOptions = appointment?.tasks?.map((item, i) => {
      return { 'value': i, 'label': item?.task_name };
  });

  const statusOptions = [
    {'label': 'Done', 'value': 'done'},
    {'label': 'Active', 'value': 'active'},
    {'label': 'Cancelled', 'value': 'cancelled'},
    {'label': 'Archived', 'value': 'archived'},
  ];

  useEffect((() => {
    }), []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      task_name: task?.task_name,
      task_time: moment(task?.task_time).format('HH:mm'),
      reminder_time: moment(task?.reminder_time).format('HH:mm'),
      task_status: statusOptions.filter(function(item) {
        return task?.task_status === item?.value;
      })?.[0]
    },
    onSubmit: (values, { resetForm }) => {
      const startdate =  appointment?.appointment_start 
      ? moment(appointment?.appointment_start).format("YYYY-MM-DD")
      : moment(appointment?.appointment_date).format("YYYY-MM-DD");

      let postedobj = {
        task_name: values?.task_name,
        task_status: status?.value,
        task_time: moment(startdate + ' ' + values.task_time, 'YYYY-MM-DD HH:mm').toDate(),
        reminder_time: moment(startdate + ' ' + values.reminder_time, 'YYYY-MM-DD HH:mm').toDate(),
        appointment_id: appointment?._id,
        created_by: userDetails?._id,
      }

      if(task?._id){
      postedobj._id = task?._id
      }
      dispatch(updateClinicTaskDetails(postedobj, (response) => {
        if(response.statusCode === 200){
          dispatch(getAllClinicLiveAppointments(clinic_settings?.clinic_id, {}, () => {}))
          dispatch(updateClinicTaskPanelAction(false));
          resetForm();
        }
      }));
    },
    validationSchema:updateTaskSchema
  });



  /*

  const changetask = (selectedOption) => {
      var data =  appointment?.tasks.filter(function(item, i) {
        return selectedOption.value === i;
      });

      formik.setFieldValue("task_name", data[0].task_name);
      formik.setFieldValue("task_time", moment(data[0].task_time).format("HH:mm"));
      formik.setFieldValue("reminder_time", moment(data[0].reminder_time).format("HH:mm"));
      formik.setFieldValue("clinic_task_id", selectedOption);
      setTask(selectedOption)

      var selectedstatus =  statusOptions.filter(function(item) {
        return data[0].task_status === item?.value;
      });
      setStatus(selectedstatus[0]);
      formik.setFieldValue("task_status", selectedstatus[0]);
  }*/

  return (
    <>
      <div
        className={`patients-panel-wrapper position-fixed d-flex flex-column overflow-auto 
      ${className}
      
      `}
      >
        <div className="head d-flex align-items-center">
          <h2>Update Task</h2>
          <Button
            color="link"
            className="ms-auto panel-close-btn"
            onClick={closeUpdateTaskPanel}
          >
            <span className="material-symbols-outlined">close</span>
          </Button>
        </div>

        <Form onSubmit={formik.handleSubmit}>
          <div className="mt-2 space-y-2">
            <div className="flex items-center gap-x-2 w-full">
            

              <div className="w-1/2">
                <label htmlFor="task_name" className="required-input text-xs text-gray-500">
                  Task Name
                </label>

                <input
                  id="task_name"
                  name="task_name"
                  type="text"
                  value={formik?.values?.task_name}
                  onChange={formik.handleChange}
                  className="w-full rounded-xl border overflow-hidden h-12 px-2.5"
                />
                {formik?.errors?.task_name && formik?.touched?.task_name && formik?.errors?.task_name}
              </div>               
            </div>

            <div className="flex items-center gap-x-2 w-full">
              <div className="w-1/2">
                <label htmlFor="task_time" className="required-input text-xs text-gray-500">
                  Task Time
                </label>

                <input
                  id="task_time"
                  name="task_time"
                  type="time"
                  value={formik?.values?.task_time}
                  onChange={formik.handleChange}
                  className="w-full rounded-xl border overflow-hidden h-12 px-2.5"
                />
                {formik?.errors?.task_time && formik?.touched?.task_time && formik?.errors?.task_time}
              </div>
              <div className="w-1/2">
                <label htmlFor="reminder_time" className="required-input text-xs text-gray-500">
                  Reminder Time
                </label>

                <input
                  id="reminder_time"
                  name="reminder_time"
                  type="time"
                  value={formik?.values?.reminder_time}
                  onChange={formik.handleChange}
                  className="w-full rounded-lg border overflow-hidden h-12 px-2.5"
                />
                {formik?.errors?.reminder_time && formik?.touched?.reminder_time && formik?.errors?.reminder_time}
              </div>          
            </div>

            <div className="flex items-center gap-x-2 w-full">
              <div className="w-1/2">
                <label htmlFor="task_status" className="required-input text-xs text-gray-500">
                  Status
                </label>
                <Select
                  name="task_status"
                  options={statusOptions}
                  value={status}
                  onChange={(item) => setStatus(item)}
                />  
                {formik?.errors?.task_status && formik?.touched?.task_status && formik?.errors?.task_status}             
              </div>          
            </div>
            
            <div className="flex justify-end items-center gap-2 mt-3">
              <button
                className="px-4 py-2.5 rounded-lg bg-gray-100"
                onClick={closeUpdateTaskPanel}
              >
                Cancel
              </button>
              <button  type="submit" className="px-4 py-2.5 rounded-lg text-white bg-[#11CA9D]">
                Save
              </button>
            </div>
          </div>
        </Form>
          
      </div>
    </>
  );
};

export default UpdateClinicTask;
