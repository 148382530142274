import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckBox from "../../../../shared/CheckBox";
import './index.scss';
import { Accordion } from "react-bootstrap";
import { updatePermission } from "context/actions/clinicPermission";
import TableListRolePermissionFilterBlock from "shared/TableListRolePermissionFilterBlock";
import { roleModulesList } from "utils/contants/roleModulesList";

const RolePermission = () => {

    const dispatch = useDispatch();
    const { allRolePermission } = useSelector(state => state.allPermissionReducer);
    const { clinic_settings: { clinic_id } } = useSelector(state => state.clinicSettingsReducer);
    const userDetails = useSelector((state) => state.user.user_details);
    const [role, setRole] = useState({ id: 'All', label: "All" });
    const [search, setSearch] = useState('');
    const handleChangePermissions = (role, key, value) => {
        const updatePer = { ...allRolePermission[role], [key]: value }
        const payload = {
            clinic_id: clinic_id,
            role_name: role,
            permission: updatePer,
            update_query: "permission"
        }
        dispatch(updatePermission(payload, (response) => {
            if (response.status) {
                if (role === userDetails?.role) {
                    dispatch({ type: "GET_CLINIC_PERMISSION_FOR_USER", payload: updatePer })
                   // localStorage.setItem("permission", updatePer)
                }
                dispatch({ type: "GET_CLINIC_PERMISSION", payload: { ...allRolePermission, [role]: updatePer } })

            }
        }))
    }
    const hanldeSearch = (value) => {
        setSearch(value)
    }
    const filterByRoleName = (val) => {
        setRole(val);
    }

    return (
        <>
            <div className="role-permission-blc">
                <TableListRolePermissionFilterBlock search={search} roles={Object.keys(allRolePermission)?.map(opt => ({ id: opt, label: opt })) || []} selectedRole={role} onSearch={hanldeSearch} onSelectRole={filterByRoleName} />
                {/* {!rolePermissions ? <NodataFoundBlock /> : */}
                <div className="table-listing-blc overflow-auto">
                    <div>
                        <div className="head_row">
                            <div style={{ minWidth: '300px' }}>Title Information </div>
                            {Object.keys(allRolePermission)?.filter(keys => role?.id === 'All' || role?.id === keys)?.map((ele, i) => {
                                return <React.Fragment key={ele}>
                                    <div style={{ minWidth: '140px', textTransform: "capitalize" }}   >
                                        <CheckBox
                                            type='checkbox'
                                            className={`${ele?.toLowerCase() !== 'admin' ? "head_check" : ""} `}
                                            onChange={() => handleChangePermissions(ele, "active", !allRolePermission?.[ele]?.active)}
                                            ischecked={allRolePermission?.[ele]?.active}
                                            checked={allRolePermission?.[ele]?.active ? true : false}
                                            disabled={ele === 'admin'}
                                            id={ele}
                                        />{ele}</div>
                                </React.Fragment>
                            })}
                        </div>

                        {roleModulesList
                            ?.flatMap((modules) =>
                                modules.keys
                                    ?.filter((item) =>
                                        item.label.toLowerCase().includes(search.toLowerCase())
                                    ).length > 0 ? modules : null

                            )?.filter(Boolean).map(module => {
                                return <div className="data_row">
                                    <Accordion className="w-100">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header className="px-0">{module.title}</Accordion.Header>
                                            <Accordion.Body className="px-0">
                                                {module.keys?.filter((item) =>
                                                    item.label.toLowerCase().includes(search.toLowerCase())
                                                ).map(rowData => {
                                                    return <div className=" detail_row d-flex " key={rowData?.id}>
                                                        <div style={{ minWidth: '300px' }} className="detail-label">{rowData?.label}</div>
                                                        {Object.keys(allRolePermission)?.filter(keys => role?.id === 'All' || role?.id === keys)?.map((key, i) => {
                                                            return <React.Fragment key={i}>
                                                                <div style={{ minWidth: '140px' }} id="view_appointments">
                                                                    {allRolePermission?.[key]?.hasOwnProperty(rowData?.id) ?
                                                                        <CheckBox type='checkbox'
                                                                            ischecked={allRolePermission?.[key]?.[rowData?.id]}
                                                                            checked={allRolePermission?.[key]?.[rowData?.id]}
                                                                            id={key?.[rowData?.id]}
                                                                            onChange={(e) => handleChangePermissions(key, rowData?.id, !allRolePermission?.[key]?.[rowData?.id])}
                                                                            name={rowData?.id}
                                                                            disabled={key === "admin" || !allRolePermission?.[key]?.active}
                                                                        /> : '--'}

                                                                </div>
                                                            </React.Fragment>
                                                        })
                                                        }
                                                    </div>
                                                })}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            }
                            )}

                    </div>



                </div>
            </div>
        </>
    )
}

export default RolePermission;