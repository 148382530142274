import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Nav, NavItem, NavLink } from "reactstrap";
import { RiMenuSearchLine } from "react-icons/ri";
import MedicalHistory from "./MedicalHistory";
import TestResults from "./TestResults";
import Vitals from "./Vitals";
import Documents from "./Documents";
import MedicationPlans from "./MedicationPlans";
import TreatmentPlanBlock from "./TreatmentPlanBlock/Index";
import { petGenderValuesShort } from "utils/constants";
import "./PetOwnerHealthRecords.scss";

const DetailTab = ({ label, value }) => (
  <div className="d-flex flex-start gap-3 py-3 px-3">
    <div className="tab">
      <div className="text-13-400 color-2E65">{label}</div>
      <div className="text-13-400 color-8693">{value || "--"}</div>
    </div>
  </div>
);

const PetOwnerHealthRecords = () => {
  const { petDetails } = useSelector((state) => state.petOwnerDashboardReducer);
  const petId = petDetails?._id;
  const [activeTab, setActiveTab] = React.useState("1");


  const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);


  const {
    pet_name,
    pet_image,
    active,
    vrn,
    dob,
    identity,
    rabies_number,
    license_number,
    microchip_number,
    breedName,
    speciesName,
    color,
    gender,
    cpr,
    deceased,
    iv_location,
  } = petDetails || {};

  const calculateAge = () => {
    const birthDate = moment(dob);
    const currentDate = moment();
    const diff = moment.duration(currentDate.diff(birthDate));
    const years = diff.years();
    const months = diff.months();
    return `${years} years, ${months} months`;
  };

  return (
    <div id="pet-owner-health-records-container">
      <div className="text-24-700 color-2E65">Health Records</div>

      {/* <div className="d-flex justify-content-end mt-2">
        <div className="top-search-block">
          <RiMenuSearchLine size={24} color="#788693" />
          <input
            type="text"
            placeholder="Search medical conditions, lab reports, documents and surgical records..."
          />
        </div>
      </div> */}
      <div className="d-flex justify-content-start mt-2">
        <div className="pet-profile-view-card flex-wrap">
          <div className="d-flex flex-start gap-3 py-3 px-3">
            <div className="f-center bg-eeee color-cfcf h-32 w-32 rounded">
              {pet_image ? (
                <img src={pet_image} alt="pet-img" />
              ) : (
                <span className="material-symbols-outlined">
                  sound_detection_dog_barking
                </span>
              )}
            </div>
            <div className="tab" style={{ width: "75px" }}>
              <h2
                style={{ textTransform: "capitalize" }}
                className="text-16-700 color-2E65"
              >
                {pet_name}
              </h2>
              <h3 className={`text-14-400 color-8693`}>VRN: {vrn || "--"}</h3>
              <h4 className={deceased ? 'inactive' : active ? 'active': 'inactive'}>{deceased ? 'Deceased' : active ? "Active" : "Inactive"}</h4>
            </div>
          </div>
          {[
            ...(deceased ? [] : [{ label: "Date of Birth", value: moment(dob, "YYYY-MM-DD").format("MM/DD/YYYY") }]),
            ...(deceased ? []: [{ label: "Age", value: dob ? calculateAge() : "--" }]),
            {
              label: "Identity",
              value: `${petGenderValuesShort[gender]} / ${breedName} / ${color}`,
            },
            { label: "Rabies Tag No.", value: rabies_number || "--" },
            { label: "License No.", value: license_number || "--" },
            { label: "Microchip No.", value: microchip_number || "--" },
            { label: "CPR", value: cpr || "--" },
            { label: "IV Locations", value: iv_location || "--" },
          ].map((item, index) => (
            <DetailTab key={index} label={item.label} value={item.value} />
          ))}
        </div>
      </div>

      <Nav tabs className="d-flex tab_row1 mb-2">
        <NavItem>
          <NavLink
            className={activeTab === "1" ? "active" : ""}
            onClick={() => setActiveTab("1")}
          >
            Medical History
          </NavLink>
        </NavItem>

        {clinic_settings?.pet_owner_preferences?.test_results &&
        <NavItem>
          <NavLink
            className={activeTab === "2" ? "active" : ""}
            onClick={() => setActiveTab("2")}
          >
            Test Results
          </NavLink>
        </NavItem>}
        {clinic_settings?.pet_owner_preferences?.vitals &&
        <NavItem>
          <NavLink
            className={activeTab === "3" ? "active" : ""}
            onClick={() => setActiveTab("3")}
          >
            Vitals
          </NavLink>
        </NavItem>}

        {clinic_settings?.pet_owner_preferences?.treatment_plans &&
        <NavItem>
          <NavLink
            className={activeTab === "4" ? "active" : ""}
            onClick={() => setActiveTab("4")}
          >
            Treatments Plans
          </NavLink>
        </NavItem>}
        {clinic_settings?.pet_owner_preferences?.medication_plans &&
        <NavItem>
          <NavLink
            className={activeTab === "5" ? "active" : ""}
            onClick={() => setActiveTab("5")}
          >
            Medication Plans
          </NavLink>
        </NavItem>}
        <NavItem>
          <NavLink
            className={activeTab === "6" ? "active" : ""}
            onClick={() => setActiveTab("6")}
          >
            Documents
          </NavLink>
        </NavItem>
      </Nav>

      {activeTab === "1" && <MedicalHistory />}
      {activeTab === "2" && <TestResults />}
      {activeTab === "3" && <Vitals />}
      {activeTab === "4" && <TreatmentPlanBlock petId={petId} />}
      {activeTab === "6" && <Documents petId={petId} />}

      {activeTab === "5" && <MedicationPlans />}
    </div>
  );
};

export default PetOwnerHealthRecords;
