const initState = {
  loading: true,
  holidays: [],
};

export const holidayReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, loading: action.payload };

    case "GET_HOLIDAY":
      return { ...state, holidays: action.payload };

    default:
      return state;
  }
};
