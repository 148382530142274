import {
  updateDiagnosticService,
} from "context/actions/diagnostic-service";
import { Formik } from "formik";

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Input, InputGroup } from "reactstrap";
import LoaderUI from "shared/loader";
import { object, string, number } from "yup";

let diagnosticServiceSchema = object({
  test_name: string().required(),
  invoice_price: number().required(),
});

const EditDiagnosticService = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const formRef = useRef();
  const dispatch = useDispatch();
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );

  const { singleDiagnosticService } = useSelector(
    (state) => state.inventoryReducer
  );
  const history = useHistory();
  const {
    _id,
    antech_code,
    idexx_code,
    listPrice,
    invoice_price,
    test_name
  } = singleDiagnosticService || {};

  const initialValues = {
    // clinic_id: clinic_settings.clinic_id,
   
    test_name: test_name || "",
    

    // inventory_image: "",
    invoice_price: invoice_price || "",
  };

  const validationSchema = diagnosticServiceSchema;



  const handleUpdate = async (values) => {
    const updatedPayload = {
      ...values,
      service_id: _id
    };
    setBtnLoading(true);
    dispatch(
      updateDiagnosticService(updatedPayload, (res) => {
        if (res?.status) {
          if (formRef?.current) {
            formRef?.current?.resetForm();
          }
          history.push("/inventory");
          dispatch({ type: "Single_Diagnostic_Service", payload: {} });
        }
      })
    );
    setBtnLoading(false);
  };
  return (
    <div id="edit-product-inventory-container" className="cp-10">
      <div
        className="text-15-500 color-3EFD pointer"
        onClick={() => {
          history.push("/inventory");
          dispatch({ type: "Single_Diagnostic_Service", payload: {} });
        }}
      >
        Back{" "}
      </div>

      <div className="text-16-500 color-0000 cmt-10">
        Edit Diagnostic Service
      </div>

      <div className="cmt-20 bg-f8f8">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleUpdate}
          innerRef={formRef}
        >
          {(props) => {
            const {
              values,
              errors,
              touched,
              setFieldValue,
              getFieldProps,
              handleSubmit,
            } = props;
            return (
              <form>
                <div className=" p-3">
                  {/* <p className="font-['Inter'] text-[#282E65] font-[700] mb-2">
                    Product Information
                  </p> */}

                  <div className="flex  justify-between items-baseline cmt-20">
                    <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
                      Supplier{" "}
                      <span className="text-[#282E65] font-['Inter'] text-[18px]">
                        *
                      </span>
                    </p>

                    <Input
                      disabled
                      style={{
                        textTransform: "capitalize",
                        fontSize: "0.875rem",
                        lineHeight: "1.25rem",
                      }}
                      className="w-[81%]"
                      value={idexx_code ? "Idexx" : antech_code ? "Antech" : 'Unknown'} />
                    
                  </div>
                  <div className="flex justify-between  items-center cmt-20">
                    <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
                      Service Name{" "}
                      <span className="text-[#282E65] font-['Inter'] text-[18px]">
                        *
                      </span>
                    </p>
                    <input
                      type="text"
                      id="first_name"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5 "
                      placeholder="Enter Product Name"
                      required
                      value={values?.test_name}
                      {...getFieldProps("test_name")}
                    />
                  </div>

                  <div className="flex justify-end ">
                    {touched.test_name && errors.test_name ? (
                      <p className="text-[#dc3545] font-['Inter'] text-[12px]">
                        Please Enter a Test Name
                      </p>
                    ) : null}
                  </div>


                  {(idexx_code || antech_code) &&
                  <>
                  <div className="flex justify-between  items-center cmt-20">
                    <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
                      {idexx_code ? "Idexx" : "Antech"}{" Order Code "}
                      <span className="text-[#282E65] font-['Inter'] text-[18px]">
                        *
                      </span>
                    </p>
                    <input
                      disabled
                      type="text"
                      id="first_name"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5 "
                      value={idexx_code ? idexx_code : antech_code}
                    />
                  </div>
                  </>}

                  {/* <div className="flex  justify-between items-baseline cmt-20">
                    <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
                      Category{" "}
                      <span className="text-[#282E65] font-['Inter'] text-[18px]">
                        *
                      </span>
                    </p>
                    <Input
                      type={"select"}
                      id="countries"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5 "
                      name="category_id"
                      style={{
                        textTransform: "capitalize",
                        fontSize: "0.875rem",
                        lineHeight: "1.25rem",
                      }}
                      {...getFieldProps("category_id")}
                    >
                      <option selected className="font-['Inter']">
                        Select a category
                      </option>
                      {(diagnosticCategories || []).map((item, i) => (
                        <option key={i} value={item._id}>
                          {item?.category}
                        </option>
                      ))}
                    </Input>
                  </div>
                  <div className="flex justify-end">
                    {touched.category_id && errors.category_id ? (
                      <p className="text-[#dc3545] font-['Inter'] text-[12px]">
                        Please Select a Catgory
                      </p>
                    ) : null}
                  </div> */}
                  {/* <div className="flex justify-between  cmt-20 ">
                    <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
                      Description
                    </p>
                    <textarea
                      id="message"
                      rows="4"
                      className="block p-2.5 w-[81%] text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
                      placeholder="Write Description Here..."
                      {...getFieldProps("description")}
                    ></textarea>
                  </div> */}
                  

                  <div className="flex justify-between  items-center cmt-20">
                    <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
                      List Price ($)
                      <span className="text-[#282E65] font-['Inter'] text-[18px]">
                        *
                      </span>
                    </p>
                    <input
                      disabled
                      type="text"
                      id="first_name"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5 "
                      value={listPrice}
                    
                    />
                  </div>


                  <div className="flex justify-between  items-center cmt-20">
                    <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
                      Invoice Price ($)
                      <span className="text-[#282E65] font-['Inter'] text-[18px]">
                        *
                      </span>
                    </p>
                    <input
                      type="text"
                      id="first_name"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5 "
                      placeholder="Enter Product Cost"
                      required
                      {...getFieldProps("invoice_price")}
                    />
                  </div>

                  <div className="flex justify-end">
                    {touched.invoice_price && errors.invoice_price ? (
                      <p className="text-[#dc3545] font-['Inter'] text-[12px]">
                        Please Enter an Invoice Price
                      </p>
                    ) : null}
                  </div>
                  

                  

                  <div className="d-flex flex-row justify-end cmt-20">
                    <button
                      type="button"
                      className="px-4 py-2 position bottom-[39px] text-[#11CA9D] font-['Inter']"
                      onClick={() => {
                        history.push("/inventory");
                        dispatch({
                          type: "Single_Diagnostic_Service",
                          payload: {},
                        });
                      }}
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="bg-[#11CA9D] px-3.5 py-2 position bottom-[39px] rounded-lg text-[#fff] font-['Inter']"
                    >
                      Update & Proceed
                      {btnLoading && <LoaderUI />}
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default EditDiagnosticService;
