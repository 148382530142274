import React from "react";

const SectionView = ({ section }) => {
  return (
    <>
      <div className="note-content-block content-box">
        <h2 className="capitalize">{section?.title}</h2>
        <div className="inner">
          <div className="items-info">
            {section?.fields.map((item, i) => (
              <div key={i} className="item-rw d-flex">
                <div className="title">{item?.field}</div>
                <div className="desc">{item?.value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionView;
