import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button, ButtonDropdown, DropdownToggle, Table } from "reactstrap";
import { getPointOfSalesByClinicId } from "../../../context/actions/pointOfSale";
import CheckBox from "../../../shared/CheckBox";
import LoaderUI from "../../../shared/loader";
import NodataFoundBlock from "../../../shared/NoDataFoundBlock";
import PaginationBlock from "../../../shared/PaginationBlock";
import UserPlaceholder from "../../../shared/UserPlaceholder";
import TableListPointOfSaleFilterWrapper from "../../../shared/TableListPointOfSaleFilterWrapper";

const PointOfSale = () => {
  const history = useHistory();
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const dispatch = useDispatch();
  //const { inventory } = useSelector((state) => state.inventoryReducer);
  //const { pointOfSales } = useSelector((state) => state.inventoryReducer);
  const [pointOfSales, setPointOfSales] = useState();
  const userDetails = useSelector((state) => state.user.user_details);
  const {clinic_details} = useSelector((state) => state.clinicReducer);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    // handleGetInventories();
    handleGetPointOfSales();
  }, []);

  // const handleGetInventories = () => {
  //   dispatch(
  //     getPOSByClinicId({ page, limit }, () => {
  //       //setLoading(false);
  //     })
  //   );
  // };

  const handleGetPointOfSales = () => {
    dispatch(
      getPointOfSalesByClinicId(/*userDetails._id*/ clinic_details?._id, {page, limit}, (res) => {
        setLoading(false);
        setPointOfSales(res?.response_data);
        //console.log("done", res);
      })
    );
  };

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  // if (loading) {
  //   return <LoaderUI />;
  // }

  const tempData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  return (
    <>
      <div className="product-list-wrapper">
        <TableListPointOfSaleFilterWrapper />
        {pointOfSales?.docs.length > 0 ? (
          <div className="table-listing-blc overflow-auto">
            <Table striped responsive className="checkbox-listing">
              <thead>
                <tr>
                  <th>
                    <CheckBox
                      type="checkbox"
                      ischecked={false}
                      className="no-label"
                    />
                  </th>
                  <th style={{ minWidth: "150px" }}>Order ID</th>
                  <th style={{ minWidth: "160px" }}>Created On</th>
                  <th style={{ minWidth: "90px" }}>Amount</th>
                  <th style={{ minWidth: "90px" }}>Payment</th>
                  <th style={{ minWidth: "100px" }}>Patient</th>
                  <th style={{ minWidth: "160px" }}>Placed By</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
                {pointOfSales?.docs?.map((item, i) => {
                  const createdAt = new Date(item.createdAt);
                  return (
                    <tr key={i}>
                      <td>
                        <CheckBox
                          type="checkbox"
                          ischecked={false}
                          className="no-label"
                        />
                      </td>
                      <td>
                        <Button
                          color="link"
                          className="p-0 text-start pic-cell pic-cell-type-2 d-inline-flex align-items-center w-100"
                          onClick={() => {/*
                            history.push(`/product-detail/${item._id}`);
                          */}}
                        >
                          <span>{item.order_id ?? "POS-32165"}</span>
                        </Button>
                      </td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {createdAt.toLocaleString("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                          hour12: false,
                        })}
                      </td>
                      <td>
                        {/* ${item.items?.reduce((a, curr) => a + curr.amount, 0)} */}
                        $100.00
                      </td>
                      <td>
                        <span
                          className={
                            "status-cell " +
                            (item.payment === "Pending"
                              ? "pending-cell"
                              : "success-cell")
                          }
                        >
                          {item.payment}
                        </span>
                      </td>
                      <td>
                        <div className="pic-cell d-inline-flex align-items-center">
                          <div className="pic d-inline-flex flex-shrink-0">
                            <img
                              className="img-fluid w-100 h-100"
                              src={require("../../../assets/img/vetRecorEMR/product-thumbnail-square.png")}
                              alt="Product"
                            />
                            {/* <UserPlaceholder /> */}
                          </div>
                          <span>{item.patient_name}</span>
                        </div>
                      </td>
                      <td>
                        <div className="pic-cell d-inline-flex align-items-center">
                          <div className="pic rounded-circle overflow-hidden d-inline-flex flex-shrink-0">
                            <img
                              className="img-fluid w-100 h-100"
                              src={require("../../../assets/img/vetRecorEMR/product-thumbnail-square.png")}
                              alt="Product"
                            />
                            {/* <UserPlaceholder /> */}
                          </div>
                          <span>
                            {/* {item.placed_by_details?.firstname}{" "}
                            {item.placed_by_details?.lastname} */}
                            Dr. Canny
                          </span>
                        </div>
                      </td>
                      <td
                        className="text-clip-cell"
                        style={{
                          minWidth: "260px",
                          maxWidth: "260px",
                          width: "100%",
                        }}
                      >
                        {/* {item.note} */}
                        Test Description
                      </td>
                      {permissions?.edit_POS &&
                        <td className="action-cell">
                          <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle color="link" className="more-btn">
                              <span className="icomoon-more"></span>
                            </DropdownToggle>
                          </ButtonDropdown>
                        </td>
                      }
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <PaginationBlock
              limit={limit}
              page={page}
              pagingCounter={pointOfSales?.pagingCounter || 1}
              docLength={pointOfSales?.docs?.length || 0}
              docPage={pointOfSales.page || 1}
              totalPages={pointOfSales.totalPages || 1}
              totalDocs={pointOfSales.totalDocs || 0}
              _handleLimit={(val) => {
                setLimit(val);
              }}
              _handlePageChange={setPage}
            />
          </div>
        ) : (
          <NodataFoundBlock />
        )}
      </div>
    </>
  );
};

export default PointOfSale;
