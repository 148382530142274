import { Formik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { Offcanvas, Spinner } from "react-bootstrap";
import { Button, Nav, NavItem, NavLink } from "reactstrap";
import { TextInput } from "shared/FormsInput";
import PhoneNumberInput from "shared/FormsInput/PhoneInput";
import UploadImageBlock from "shared/UploadImageBlock";
import UserPlaceholder from "shared/UserPlaceholder";
import SelectExistingOwner from "components/patient-module/AddPatientDetail/SelectExistingOwner";
import { toast } from "react-toastify";
import { toasterConfig } from "utils/constants";
import { useDispatch } from "react-redux";
import { checkPetOwnerEmailExist } from "context/actions/patient-module/patient/pet";

const OwnerSelection = ({ onHide, setOwner, petOwnerId }) => {
  const dispatch = useDispatch();
  const [btnLoading, setBtnLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [initialValue, setInitialValue] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    address: "",
    imageFile: "",
    imageUrl: "",
  });
  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("First name is required."),
    lastname: Yup.string().required("Last name is required."),
    phone: Yup.string().required("Phone number is required."),
    email: Yup.string()
      .required("Email is required.")
      .email("Email must be a valid email"),
  });
  const handleChangePetOwner = async (data) => {
    if (data?._id) {
      if (data?._id === petOwnerId) {
        toast.error("Existing owner selected!", toasterConfig);
      } else {
        setOwner(data);
      }
    } else {
      setBtnLoading(true);
      const res = await dispatch(checkPetOwnerEmailExist(data.email));
      if (res?.status) {
        setOwner(data);
      }
      setBtnLoading(false);
    }
  };
  return (
    <Offcanvas
      show
      onHide={onHide}
      placement="end"
      id="pet-owner-selection-offcanvas"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Select Owner</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="pet-owner-selection">
        <div className="owner-tab-blc">
          <Nav tabs className="flex-shrink-0">
            <NavItem>
              <NavLink
                className={{ active: activeTab === "1" }}
                onClick={() => {
                  setActiveTab("1");
                }}
              >
                New
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={{ active: activeTab === "2" }}
                onClick={() => {
                  setActiveTab("2");
                }}
              >
                Existing
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        {activeTab === "1" ? (
          <Formik
            initialValues={initialValue}
            validationSchema={validationSchema}
            onSubmit={handleChangePetOwner}
          >
            {(props) => {
              const { values, errors, handleChange, handleSubmit } = props;
              const {
                firstname,
                lastname,
                phone,
                email,
                address,
                imageFile,
                imageUrl,
              } = values;
              return (
                <form>
                  <TextInput
                    isRequired
                    type="text"
                    name="firstname"
                    id="firstname"
                    placeholder="Enter First Name"
                    label="First Name"
                    labelVisible
                    value={firstname}
                    onChange={handleChange}
                  />

                  <TextInput
                    isRequired
                    labelVisible
                    type="text"
                    name="lastname"
                    id="lastname"
                    placeholder="Enter Last Name"
                    label="Last Name"
                    value={lastname}
                    onChange={handleChange}
                  />
                  <div className="form-group">
                    <label className="required-input required">
                      Phone Number
                    </label>
                    <PhoneNumberInput
                      name="phone"
                      id="phone"
                      className="mb-0"
                      value={phone}
                      onChange={(e) => {
                        handleChange({
                          target: { id: "phone", value: "+" + e },
                        });
                      }}
                    />
                    {errors?.phone && (
                      <div className="d-block invalid-feedback">
                        {errors?.phone}
                      </div>
                    )}
                  </div>
                  <TextInput
                    isRequired
                    labelVisible
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter email"
                    label="Email"
                    value={email}
                    onChange={handleChange}
                  />
                  <TextInput
                    type="text"
                    name="address"
                    id="address"
                    placeholder="Enter Address Information"
                    isRequired={false}
                    label="Address"
                    labelVisible
                    value={address}
                    onChange={handleChange}
                    autoComplete="shipping address-line1"
                  />
                  <div className="mb-3 form-group">
                    <label className="">Profile Picture</label>
                    <div className="pic-cell2">
                      <div className="pic d-inline-flex rounded-circle overflow-hidden position-relative">
                        <span className="upload-cell position-absolute w-100 h-100 d-inline-flex align-items-center justify-content-center">
                          <UploadImageBlock
                            petImage={imageFile}
                            sendPetImage={(value) => {
                              console.log("sendPetImage", value);
                              //   setImageFile(value);
                            }}
                            petImageObj={(value) => {
                              console.log("petImageObj", value);
                              if (value) {
                                handleChange({
                                  target: {
                                    id: "imageFile",
                                    value: value,
                                  },
                                });
                                handleChange({
                                  target: {
                                    id: "imageUrl",
                                    value: URL.createObjectURL(value),
                                  },
                                });
                              }
                            }}
                          />
                        </span>

                        {imageUrl ? (
                          <img
                            className="img-fluid w-100 h-100"
                            src={imageUrl}
                            alt="User"
                          />
                        ) : (
                          <UserPlaceholder />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end gap-2">
                    <Button
                      color="link"
                      onClick={onHide}
                      className="btn btn-outline-light"
                    >
                      Cancel
                    </Button>
                    <Button
                      color="success"
                      type="button"
                      className="btn btn-outline-light"
                      onClick={handleSubmit}
                    >
                      Save & Proceed{" "}
                      {btnLoading && <Spinner size="sm" className="ms-2" />}
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        ) : (
          <SelectExistingOwner handleChangePetOwner={handleChangePetOwner} />
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default OwnerSelection;
