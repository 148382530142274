import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Input } from "reactstrap"; // eslint-disable-line
import DateBoxInput from "shared/FormsInput/DateBoxInput";
import DateRange from "shared/FormsInput/DateRange";

const TableListFilterBlock = ({
  onSearch,
  onSelectPaymentmode,
  onSelectStatus,
  filterPayload,
  onChangeDate,
}) => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  return (
    <>
      <div
        className="table-list-filter-blc d-flex align-items-center"
        id="filter-block-container"
      >
        <div className="search-cell col-cell position-relative">
          <span className="icomoon-search d-inline-flex align-items-center justify-content-center position-absolute"></span>
          <Input
            placeholder="Search"
            onChange={onSearch}
            value={filterPayload?.searchPayer}
          />
        </div>
        <div className="date-range-container cme-10">
          <DateRange
            inputClassName="custom-input-grey"
            placeholder="Select Date"
            id="boarding"
            name="boarding"
            startDateProp={startDate}
            endDateProp={endDate}
            setStartDateProp={setStartDate}
            setEndDateProp={setEndDate}
            onChange={(sDate, eDate) => {
              const startDate = sDate ? moment(sDate).format("YYYY-MM-DD") : "";
              const endDate = eDate ? moment(eDate).format("YYYY-MM-DD") : "";
              onChangeDate(startDate, endDate);
            }}
            isClearable
          />
        </div>

        <div className="input-cell col-cell">
          <Input
            type="select"
            name="select"
            onChange={onSelectStatus}
            value={filterPayload?.status}
          >
            <option value="">All Status</option>
            <option value="Pending">Pending</option>
            <option value="Fully Paid">Fully Paid</option>
            <option value="Partial Paid">Partial Paid</option>
            <option value="Pending Review">Pending Review</option>
          </Input>
        </div>
        <div className="input-cell col-cell">
          <Input
            type="select"
            name="select"
            onChange={onSelectPaymentmode}
            value={filterPayload?.payment_method}
          >
            <option value="">All Mode</option>
            {/* <option value="">Credit Card</option> */}
          </Input>
        </div>
      </div>
    </>
  );
};

export default TableListFilterBlock;
