import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { MdOutlinePets } from "react-icons/md";
import { FaRegCalendarCheck } from "react-icons/fa";
import { RiMapPinAddLine } from "react-icons/ri";
import { IoCloseOutline } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import { Button } from "reactstrap";
import warning from "assets/img/warning.png";
import { useDispatch, useSelector } from "react-redux";
import {
  changeAppointmentStatus,
  getAllDoctors,
} from "context/actions/appointment";
import NodataFoundBlock from "shared/NoDataFoundBlock";
import moment from "moment";
import { toast } from "react-toastify";
import { toasterConfig } from "utils/constants";
import { getPrivateRoomList } from "context/actions/realTimeChat";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { useClinicSpecies } from "hooks/useClinicSpecies";
import db from "../../../firebase";

toast.configure();

const ScheduleRequests = () => {

  const clinicSpecies = useClinicSpecies()
  const history = useHistory();
  const dispatch = useDispatch();
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const userDetails = useSelector((state) => state.user.user_details);

  const [pageData, setPageData] = useState([]);
  const [privateChatsListing, setPrivateChatsListing] = useState([]);
  const handleChangeStatus = async (id, status) => {
    let response = await dispatch(
      changeAppointmentStatus({
        appointmentId: id,
        status: status,
      })
    );
    if (response?.success) {
      getPageData();
      toast.success(response?.message, toasterConfig);
    } else {
      toast.error(response?.message, toasterConfig);
    }
  };
  const getPageData = () => {
    dispatch(
      getAllDoctors(clinic_details?._id, {}, (result) => {
        let resList = [];
        if (Array.isArray(result)) {
          resList = result.filter(
            (elm) =>
              elm.appointments?.filter((o) => o.isAppointmentAproved === 0)
                ?.length > 0
          );
          setPageData(resList);
        }
      })
    );
    const unsub = dispatch(
      getPrivateRoomList(userDetails?._id, "", (response) => {
        setPrivateChatsListing(response);
      })
    );
    return unsub
  };
  const handleChatList = async (ele) => {
    const isRoomExist = privateChatsListing?.find(
      (element) =>
        element?.memberId.includes(ele?.patient_id?.pet_owner?.id) &&
        element?.memberId.includes(userDetails?._id)
    );
    if (isRoomExist?.id) {

      history.push(`/message-center/private/${isRoomExist?.id}`);
      console.log("demo");
    } else {
      const roomsCollectionRef = collection(db, "privateChats");

      let payload = {
        createdAt: serverTimestamp(),
        createdBy: userDetails?._id,
        memberId: [userDetails?._id],
        members: [
          {
            id: userDetails?._id,
            role: userDetails?.role,
            img: userDetails?.user_image,
            name: userDetails?.firstname + " " + userDetails?.lastname || "",
            phone: userDetails?.phone || "",
            unreadCount: 0,
          },
          {
            id: ele?.patient_id?.pet_owner?.id,
            role: ele?.patient_id?.pet_owner?.role,
            img: ele?.patient_id?.pet_owner?.user_image,
            name:
              ele?.patient_id?.pet_owner?.firstname +
                " " +
                ele?.patient_id?.pet_owner?.lastname || "",
            phone: ele?.patient_id?.pet_owner?.phone || "",
            unreadCount: 0,
          },
        ],
      };
      const roomDocRef = await addDoc(roomsCollectionRef, payload);
      if (roomDocRef.id) {
        toast.success("New chat created.");
        history.push(`/message-center/private/${roomDocRef.id}`);
        dispatch({ type: "MESSAGE_LIST", payload: [] });
      }
    }
  };

  useEffect(() => {
    const unsub = getPageData();
    // return(() => {if(typeof unsub === 'function')unsub()})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic_details]);

  return (
    <div className="ms-3 mt-2 me-2">
      {pageData.length === 0 ? (
        <NodataFoundBlock />
      ) : (
        pageData?.map((elm, index) => {
          const { user, appointments } = elm;
          let name = `${user.title} ${user.firstname} ${user.lastname}`;
          let displayList = appointments?.filter(
            (o) => o.isAppointmentAproved === 0
          );
          return (
            <div className="bg-f8f8 mb-1 cp-10 d-flex gap-2" key={index}>
              <div className="w-230" style={{ minWidth: "230px" }}>
                <div className="d-flex gap-2">
                  {user?.user_image ? (
                    <div className="f-center">
                      <img
                        src={user?.user_image}
                        alt="user_image"
                        className="h-49 w-49 f-center rounded-circle"
                      />
                    </div>
                  ) : (
                    <div className="h-49 w-49 f-center rounded-circle bg-e3e3">
                      <FaUser color="#788C9F" />
                    </div>
                  )}
                  <div>
                    <div className="text-16-400 color-2E65">{name}</div>
                    <div className="text-13-400 color-2E65">
                      {displayList.length} New Appointments
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-grow-1" style={{ minWidth: "1px" }}>
                <div
                  className="d-flex overflow-auto overflow-y-hidden gap-2"
                  style={
                    {
                      //   minWidth: "calc(100% - 385px)",
                      // overflowY: "auto",
                    }
                  }
                >
                  {displayList?.map((cElem, cIndex) => {
                    const {
                      _id,
                      patient_id,
                      appointment_date,
                      appointment_timings,
                    } = cElem;
                    const { pet_name, pet_image, breed } = patient_id;

                    return (
                      <div
                        className="bg-ffff br-4 p-1 w-280"
                        key={cIndex}
                        style={{ minWidth: "280px" }}
                      >
                        <div className="fb-center mb-1">
                          <div className="fa-center gap-1 ">
                            {pet_image ? (
                              <div className="f-center">
                                <img
                                  src={pet_image}
                                  alt="pet_image"
                                  className="h-20 w-20 br-4"
                                />
                              </div>
                            ) : (
                              <div className="bg-f8f8 f-center h-20 w-20 p-1">
                                <MdOutlinePets />
                              </div>
                            )}
                            <div className="text-13-400 color-2E65">
                              {pet_name} / {clinicSpecies(breed?.species_id?._id) || breed?.species_id?.species_name}
                            </div>
                          </div>
                          <div>
                            <img src={warning} alt="warning" className="h-16" />
                          </div>
                        </div>
                        <div className="fa-center gap-2 mb-1">
                          <span className="w-20 fj-center">
                            <FaRegCalendarCheck color="#282E65" size={14} />
                          </span>
                          <span className="text-14-400 color-2E65">
                            {moment(appointment_date, "DD-MM-YYYY").format(
                              "MMM DD, YYYY"
                            )}
                          </span>
                          <span className="text-14-400 color-2E65">
                            {appointment_timings?.[0]?.appointment_time}
                          </span>
                        </div>
                        <div className="fa-center gap-2 mb-1">
                          <span className="w-20 fj-center">
                            <RiMapPinAddLine color="#282E65" />
                          </span>
                          <span className="text-14-400 color-2E65">
                            {clinic_details?.address || "--"}
                          </span>
                        </div>
                        <div className="fa-center gap-2 mb-1">
                          <div
                            className="b-f8f8 w-28 h-28 f-center p-1 br-4 cursor-pointer"
                            onClick={() => {
                              handleChangeStatus(_id, 2);
                            }}
                          >
                            <IoCloseOutline />
                          </div>
                          <div
                            className="b-f8f8 w-28 h-28 f-center p-1 br-4 cursor-pointer"
                            onClick={() => {
                              handleChatList(cElem);
                            }}
                          >
                            <MdOutlineMailOutline />
                          </div>
                          <div className="flex-grow-1">
                            <Button
                              color="success"
                              className="w-100 h-28 p-0 text-12-600 br-6"
                              onClick={() => {
                                handleChangeStatus(_id, 1);
                              }}
                            >
                              Confirm
                            </Button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default ScheduleRequests;
