import React from "react";
import "./index.css";

const StepsBlock = ({ step }) => {
	let list = [
		{
			title: "Vitals",
		},
		{
			title: "Diagnostic Testing",
		},
		{
			title: "Imaging",
		},
		{
			title: "Procedures",
		},
		{
			title: "Prescription Routing",
		},
		{
			title: "New Orders",
		},
		{
			title: "Reconciliation",
		},
		{
			title: "Problem List",
		},
		{
			title: "D/C Instructions",
		},
	]

	return (
		<>
			<div className="horizontal steps-blc">
				<ul className="list-unstyled d-flex items-center justify-content-around ">
					{list.map((item, i) => (

						<li key={i} className={`  position-relative text-center w-[70%] ${step >= i + 1 ? 'active' : ''}`}>
							<span className="icon d-inline-flex align-items-center justify-content-center flex-shrink-0 rounded-circle m-0">
								<span className="icomoon-check"></span>
							</span>
							<div className="txt">
								<h3>
									{item.title}
								</h3>
								<p>{item.desc}</p>
							</div>
						</li>
					))}
				</ul>
			</div>
		</>
	);
};

export default StepsBlock;
