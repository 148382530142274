import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  updateBrandLogo,
  updateClinicSettings,
  updateInvoicePreferencesLogo,
  updateMonogram,
} from "../../../../../context/actions/clinicSettings";
import BrowseFileInput from "../../../../../shared/BrowseFileInput";
import CheckBox from "../../../../../shared/CheckBox";
import { toasterConfig } from "../../../../../utils/constants";

toast.configure();

const BrandLogo = ({}) => {
  const dispatch = useDispatch();
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );
  const [settings, setSettings] = useState({ ...clinic_settings });

  const handleUpdateSettings = (data) => {
    let body = {
      ...data,
      clinic_id: clinic_settings.clinic_id,
      update_query: "other_settings",
    };
    dispatch(updateClinicSettings(body, () => {}));
  };

  const handleUpdateOnClick = (e, key) => {
    let value = e.target.checked;

    switch (key) {
      case "is_brand_logo":
        setSettings((p) => (p = { ...p, is_brand_logo: value }));
        handleUpdateSettings({ is_brand_logo: value });
        break;

      case "is_monogram":
        setSettings((p) => (p = { ...p, is_monogram: value }));
        handleUpdateSettings({ is_monogram: value });
        break;

      default:
        break;
    }
  };

  const handleUpdateBrandLogo = (file) => {
    if (file) {
      let formData = new FormData();
      formData.append("brand_logo", file);

      dispatch(updateBrandLogo(formData, () => {}));
    } else {
      toast.error("No file selected for brand logo!", toasterConfig);
    }
  };

  const handleUpdateMomogram = (file) => {
    if (file) {
      let formData = new FormData();
      formData.append("monogram", file);

      dispatch(updateMonogram(formData, () => {}));
    } else {
      toast.error("No file selected for monogram!", toasterConfig);
    }
  };

  let brandFullLogo = "";
  let brandMonogramLogo = "";
  if (clinic_settings?.brand_logo_url) {
    brandFullLogo = clinic_settings?.brand_logo_url
      ?.split("?")?.[0]
      ?.split("/")
      ?.slice(-1)
      ?.pop();
  }
  if (clinic_settings?.monogram_url) {
    brandMonogramLogo = clinic_settings?.monogram_url
      ?.split("?")?.[0]
      ?.split("/")
      ?.slice(-1)
      ?.pop();
  }

  return (
    <>
      <div className="tab-row d-flex">
        <div className="tab-col lft">
          <div className="info-block">
            <h3>Brand logo</h3>
            <div className="brand-logo-form">
              <div className="row-block">
                {/* <CheckBox type='checkbox' label='Full Logo' checked={!!settings?.is_brand_logo} onChange={(e) => handleUpdateOnClick(e, "is_brand_logo")} /> */}
                <h6 className="mb-2">Full Logo</h6>
                <BrowseFileInput
                  onChange={handleUpdateBrandLogo}
                  message={brandFullLogo || ""}
                />
              </div>
              <div className="row-block">
                <h6 className="mb-2">Monogram (Optional)</h6>
                {/* <CheckBox type='checkbox' label='Monogram (Optional)' checked={!!settings?.is_monogram} onChange={(e) => handleUpdateOnClick(e, "is_monogram")} /> */}
                <BrowseFileInput
                  onChange={handleUpdateMomogram}
                  message={brandMonogramLogo || ""}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="tab-col flex-fill">{/* Brand Logo View */}</div>
      </div>
    </>
  );
};

export default BrandLogo;
