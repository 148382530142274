import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Table, DropdownToggle, ButtonDropdown } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import LoaderUI from "../../../shared/loader";
import { sidebarPanelAction } from "../../../context/actions/sidebarPanelAction";
import PageHeader from "../../../shared/PageHeader";
import TableListPatientsFilterWrapper from "../../../shared/TableListPatientsFilterWrapper";
import CheckBox from "../../../shared/CheckBox";
import UserPlaceholder from "../../../shared/UserPlaceholder";
import ImagePlaceholder from "../../../shared/ImagePlaceholder";
import "./index.css";
import { getPetListWithFilter } from "../../../context/actions/patient-module/patient/pet";
import PaginationBlock from "../../../shared/PaginationBlock";
import { dateFromDateTime } from "../../../utils/common";
import NodataFoundBlock from "../../../shared/NoDataFoundBlock";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import PatientsTable from "./PatientsTable";
import PetOwnersTable from "./PetOwnersTable";
import { getAllCity, getAllSpecies } from "context/actions/app-content";
import TableListExistingPetOwnerFilterWrapper from "../AddPatientDetail/SelectExistingOwner/TableListExistingPetOwnerFilterWrapper";
import ChangePetOwner from "./ChangePetOwner";

const Patients = () => {
  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  // console.log(permissions.view_medical_history ,"permissionspermissions");

  const [activeTab, setActiveTab] = useState("1");
  const dispatch = useDispatch();
  const history = useHistory();
  //const { loading } = useSelector(state => state.petReducer)
  const [loader, showLoader] = useState(false);
  const [dropdownOpen, setOpen] = useState(false);
  const [changeOwner, setChangeOwner] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [filterParams, setFilterParams] = useState({
    searchText: "",
    city: "",
    species_id: "",
    gender: "",
    status: "",
  });

  const [pageForPetOwners, setPageForPetOwners] = useState(1);
  const [limitForPetOwners, setLimitForPetOwners] = useState(10);

  const [filterParamsForPetOwners, setFilterParamsForPetOwners] = useState({
    searchText: "",
    city: "",
    species_id: "",
    gender: "",
    //status: ''
  });

  useEffect(() => {
    handleGetPetList();
  }, [page, limit]);

  const handleGetPetList = () => {
    dispatch(
      getPetListWithFilter({ page, limit, ...filterParams }, () => {
        //setPage()
      })
    );
  };

  useEffect(() => {
    setPage(1);
    handleGetPetList();
  }, [filterParams]);
  useEffect(() => {
		// if (petList) {
			dispatch(getAllCity({}, () => { }))
			dispatch(getAllSpecies({}, () => { }))
		// }
	}, [])

  const sidebarPanelState = useSelector(
    (state) => state.sidebarPanelReducer.isSidePanel
  );
  const showSidePanel = () => {
    dispatch(sidebarPanelAction(!sidebarPanelState));
  };

  return (
    <>
      {changeOwner && (
        <ChangePetOwner
          handleSuccess={() => {
            handleGetPetList();
            setChangeOwner(false);
          }}
          onHide={() => {
            setChangeOwner(false);
          }}
        />
      )}
      <div className="page-wrapper position-relative d-flex flex-column flex-fill overflow-auto">
        <div className="page-header-wrapper d-flex align-items-center flex-shrink-0 right-column-visible pb-2">
          <Button
            className="sidebar-toggle-btn d-inline-flex align-items-center"
            color="link"
            onClick={showSidePanel}
          >
            <span className="material-symbols-outlined">menu</span>
          </Button>
          <div className="row-block d-flex align-items-center flex-fill">
            {/* <PageHeader className='dashboard-head flex-flex-shrink-0' title="Patients"></PageHeader> */}

            <Nav tabs className="d-flex tab_row1">
              <NavItem>
                {permissions?.view_patients && (
                  <NavLink
                    className={activeTab === "1" ? "active" : ""}
                    onClick={() => setActiveTab("1")}
                  >
                    Patients
                  </NavLink>
                )}
              </NavItem>
              {permissions?.view_patient_owners && (
                <NavItem>
                  <NavLink
                    className={activeTab === "2" ? "active" : ""}
                    onClick={() => setActiveTab("2")}
                  >
                    Patient Owners
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            {permissions?.add_patients && (
              <div className="right-col ms-auto">
                <div className="btn-blc d-flex gap-2">
                  <Button
                    color="success"
                    className="add-btn"
                    onClick={() => {
                      history.push(`/patients/add-patient`);
                    }}
                  >
                    Add new
                  </Button>
                  <Button
                    color="success"
                    className="add-btn text-nowrap cps-10 cpe-10"
                    onClick={() => {
                      setChangeOwner(true);
                    }}
                  >
                    Change Owner
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <TabContent activeTab={activeTab} className="">
          {permissions?.view_patients && (
            <TabPane tabId="1">
              <div className="page-content d-flex flex-column flex-fill overflow-auto">
                <TableListPatientsFilterWrapper
                  filterParams={filterParams}
                  setFilterParams={setFilterParams}
                />
                <PatientsTable
                  limit={limit}
                  page={page}
                  setLimit={setLimit}
                  setPage={setPage}
                />
              </div>
            </TabPane>
          )}
          {permissions?.view_patient_owners && (
            <TabPane tabId="2">
              <div className="page-content d-flex flex-column flex-fill overflow-auto">
                <TableListExistingPetOwnerFilterWrapper
                  filterParams={filterParamsForPetOwners}
                  setFilterParams={setFilterParamsForPetOwners}
                />

                {/*
                <TableListPatientsFilterWrapper
                  filterParams={filterParamsForPetOwners}
                  setFilterParams={setFilterParamsForPetOwners}
                />*/}
                <PetOwnersTable
                  filterParams={filterParamsForPetOwners}
                  limit={limitForPetOwners}
                  page={pageForPetOwners}
                  setLimit={setLimitForPetOwners}
                  setPage={setPageForPetOwners}
                />
              </div>
            </TabPane>
          )}
        </TabContent>
      </div>
    </>
  );
};

export default Patients;
