import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import UserPlaceholder from "../../../../../shared/UserPlaceholder";
import {
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { useFormik } from "formik";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import {
  getOwnerAndPetsDetails,
  updatePetOwnerDetails,
  updatePetOwnerImage,
} from "../../../../../context/actions/pet-owner-dashboard";
import AppLogo2 from "../../../../../shared/AppLogo2";

// import "./index.scss";

const OwnerProfilehead = () => {
  const { ownerAndPetDetails } = useSelector(
    (state) => state.petOwnerDashboardReducer
  );

  const [ownerImage, setOwnerImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  console.log("ownerAndPetDetails", ownerAndPetDetails);

  const dispatch = useDispatch();

  const [showEditModal, setShowEditModal] = useState(false);

  const [profileData, setProfileData] = useState({
    firstname: ownerAndPetDetails?.firstname,
    lastname: ownerAndPetDetails?.lastname,
    phone: ownerAndPetDetails?.phone,
    email: ownerAndPetDetails?.email,
    address: ownerAndPetDetails?.address,
    _id: ownerAndPetDetails?._id,
  });

  useEffect(() => {
    setProfileData({
      firstname: ownerAndPetDetails?.firstname,
      lastname: ownerAndPetDetails?.lastname,
      phone: ownerAndPetDetails?.phone,
      email: ownerAndPetDetails?.email,
      address: ownerAndPetDetails?.address,
      _id: ownerAndPetDetails?._id,
    });

    formik.setValues({
      firstname: ownerAndPetDetails?.firstname,
      lastname: ownerAndPetDetails?.lastname,
      phone: ownerAndPetDetails?.phone,
      email: ownerAndPetDetails?.email,
      address: ownerAndPetDetails?.address,
      _id: ownerAndPetDetails?._id,
    });
  }, [ownerAndPetDetails]);

  useEffect(() => {
    if (ownerImage) {
      console.log("we have an image and have changed it");
      setImageUrl((p) => (p = URL.createObjectURL(ownerImage)));
    }
  }, [ownerImage]);

  const formik = useFormik({
    initialValues: profileData,

    onSubmit: (values, { resetForm }) => {
      dispatch(
        updatePetOwnerDetails(values, (resp) => {
          if (resp.status) {
            console.log("updatePetOwnerDetails", resp);

            dispatch(
              getOwnerAndPetsDetails(
                {
                  user_id: ownerAndPetDetails?._id,
                },
                (response) => {}
              )
            );
          }
        })
      );

      if (ownerImage) {
        console.log("need to update image as well");
        let petOwnerFormData = new FormData();
        petOwnerFormData.append("pet_owner", ownerAndPetDetails?._id);
        petOwnerFormData.append(
          "pet_owner_image",
          ownerImage,
          ownerImage?.name
        );

        dispatch(
          updatePetOwnerImage(petOwnerFormData, (response) => {
            if (response) {
              setShowEditModal(false);
            }
          })
        );
      } else {
        setShowEditModal(false);
      }
    },
  });

  return (
    <>
      <div className="profile-head px-0 pt-3">
        <AppLogo2 />

        {/* <div class="btn-blc d-inline-flex align-items-center">
          <button
            onClick={() => {
              setShowEditModal(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-pen"
              viewBox="0 0 16 16"
            >
              <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
            </svg>
          </button>

          <Button color="link pe-0" className="view-user-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                fill="#282E65"
                d="M12 9.225c.767 0 1.417-.267 1.95-.8.533-.533.8-1.183.8-1.95 0-.767-.267-1.417-.8-1.95a2.653 2.653 0 00-1.95-.8c-.767 0-1.417.267-1.95.8a2.654 2.654 0 00-.8 1.95c0 .767.267 1.417.8 1.95.533.533 1.183.8 1.95.8zM6.3 13.85a7.48 7.48 0 012.575-1.912A7.536 7.536 0 0112 11.275c1.1 0 2.142.221 3.125.663A7.48 7.48 0 0117.7 13.85c.1 0 .175-.03.225-.088A.314.314 0 0018 13.55V2.3c0-.067-.033-.133-.1-.2s-.133-.1-.2-.1H6.3c-.067 0-.133.033-.2.1s-.1.133-.1.2v11.25c0 .083.025.154.075.212.05.059.125.088.225.088zm0 1.65c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 014.5 13.7V2.3c0-.5.175-.925.525-1.275C5.375.675 5.8.5 6.3.5h11.4c.5 0 .925.175 1.275.525.35.35.525.775.525 1.275v11.4c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H6.3zm-4 4c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 01.5 17.7V5.25a.726.726 0 01.75-.75.728.728 0 01.75.75V17.7c0 .083.03.154.088.213A.289.289 0 002.3 18h12.45c.217 0 .396.07.538.212a.731.731 0 01.212.538c0 .217-.07.396-.212.538a.731.731 0 01-.538.212H2.3zM12 7.725c-.35 0-.646-.12-.887-.362a1.208 1.208 0 01-.363-.888c0-.35.121-.646.363-.888.241-.241.537-.362.887-.362s.646.12.887.362c.242.242.363.538.363.888s-.121.646-.363.888a1.206 1.206 0 01-.887.362zM8.325 14h7.35a5.736 5.736 0 00-1.712-.912A6.16 6.16 0 0012 12.775a6.15 6.15 0 00-1.962.313A5.734 5.734 0 008.325 14z"
              ></path>
            </svg>
          </Button>
        </div> */}

        {/* <div className="title d-flex justify-content-between">
          <Button
            color="link"
            className="back-btn ps-0"
            onClick={() => history.goBack()}
          >
            Go Back
          </Button>
          <Button color="link" className="view-user-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                fill="#282E65"
                d="M12 9.225c.767 0 1.417-.267 1.95-.8.533-.533.8-1.183.8-1.95 0-.767-.267-1.417-.8-1.95a2.653 2.653 0 00-1.95-.8c-.767 0-1.417.267-1.95.8a2.654 2.654 0 00-.8 1.95c0 .767.267 1.417.8 1.95.533.533 1.183.8 1.95.8zM6.3 13.85a7.48 7.48 0 012.575-1.912A7.536 7.536 0 0112 11.275c1.1 0 2.142.221 3.125.663A7.48 7.48 0 0117.7 13.85c.1 0 .175-.03.225-.088A.314.314 0 0018 13.55V2.3c0-.067-.033-.133-.1-.2s-.133-.1-.2-.1H6.3c-.067 0-.133.033-.2.1s-.1.133-.1.2v11.25c0 .083.025.154.075.212.05.059.125.088.225.088zm0 1.65c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 014.5 13.7V2.3c0-.5.175-.925.525-1.275C5.375.675 5.8.5 6.3.5h11.4c.5 0 .925.175 1.275.525.35.35.525.775.525 1.275v11.4c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H6.3zm-4 4c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 01.5 17.7V5.25a.726.726 0 01.75-.75.728.728 0 01.75.75V17.7c0 .083.03.154.088.213A.289.289 0 002.3 18h12.45c.217 0 .396.07.538.212a.731.731 0 01.212.538c0 .217-.07.396-.212.538a.731.731 0 01-.538.212H2.3zM12 7.725c-.35 0-.646-.12-.887-.362a1.208 1.208 0 01-.363-.888c0-.35.121-.646.363-.888.241-.241.537-.362.887-.362s.646.12.887.362c.242.242.363.538.363.888s-.121.646-.363.888a1.206 1.206 0 01-.887.362zM8.325 14h7.35a5.736 5.736 0 00-1.712-.912A6.16 6.16 0 0012 12.775a6.15 6.15 0 00-1.962.313A5.734 5.734 0 008.325 14z"
              ></path>
            </svg>
          </Button>
        </div> */}
        <div className="user-profile-cell d-inline-flex align-items-center">
          <div className="pic position-relative">
            {ownerAndPetDetails?.user_image ? (
              <img
                className="img-fluid w-100 h-100"
                src={
                  ownerAndPetDetails?.user_image ||
                  require("../../../../../assets/img/vetRecorEMR/user-thumbnail.png")
                }
                alt="Pet"
              />
            ) : (
              <UserPlaceholder className="img-fluid w-100 h-100" />
            )}
          </div>

          <div className="txt">
            <h2 style={{ textTransform: "capitalize" }}>
              {ownerAndPetDetails?.firstname +
                " " +
                ownerAndPetDetails?.lastname}
            </h2>
            <h4 className="active">
              {ownerAndPetDetails?.active ? "Active" : "Inactive"}
            </h4>
          </div>

          <div className="txt ms-2 d-flex">
            <button
              onClick={() => {
                setShowEditModal(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-pen"
                viewBox="0 0 16 16"
              >
                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
              </svg>
            </button>
            <Button color="link pe-0" className="view-user-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  fill="#282E65"
                  d="M12 9.225c.767 0 1.417-.267 1.95-.8.533-.533.8-1.183.8-1.95 0-.767-.267-1.417-.8-1.95a2.653 2.653 0 00-1.95-.8c-.767 0-1.417.267-1.95.8a2.654 2.654 0 00-.8 1.95c0 .767.267 1.417.8 1.95.533.533 1.183.8 1.95.8zM6.3 13.85a7.48 7.48 0 012.575-1.912A7.536 7.536 0 0112 11.275c1.1 0 2.142.221 3.125.663A7.48 7.48 0 0117.7 13.85c.1 0 .175-.03.225-.088A.314.314 0 0018 13.55V2.3c0-.067-.033-.133-.1-.2s-.133-.1-.2-.1H6.3c-.067 0-.133.033-.2.1s-.1.133-.1.2v11.25c0 .083.025.154.075.212.05.059.125.088.225.088zm0 1.65c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 014.5 13.7V2.3c0-.5.175-.925.525-1.275C5.375.675 5.8.5 6.3.5h11.4c.5 0 .925.175 1.275.525.35.35.525.775.525 1.275v11.4c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H6.3zm-4 4c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 01.5 17.7V5.25a.726.726 0 01.75-.75.728.728 0 01.75.75V17.7c0 .083.03.154.088.213A.289.289 0 002.3 18h12.45c.217 0 .396.07.538.212a.731.731 0 01.212.538c0 .217-.07.396-.212.538a.731.731 0 01-.538.212H2.3zM12 7.725c-.35 0-.646-.12-.887-.362a1.208 1.208 0 01-.363-.888c0-.35.121-.646.363-.888.241-.241.537-.362.887-.362s.646.12.887.362c.242.242.363.538.363.888s-.121.646-.363.888a1.206 1.206 0 01-.887.362zM8.325 14h7.35a5.736 5.736 0 00-1.712-.912A6.16 6.16 0 0012 12.775a6.15 6.15 0 00-1.962.313A5.734 5.734 0 008.325 14z"
                ></path>
              </svg>
            </Button>
          </div>
        </div>
        <div className="profile-btns d-flex px-0">
          <Button color="link" className="flex-fill">
            Message
          </Button>
          <Button color="link" className="flex-fill">
            [Second Action]
          </Button>
        </div>
      </div>

      <Modal
        className="edit-modal"
        isOpen={showEditModal}
        toggle={() => setShowEditModal(false)}
      >
        <ModalHeader>Edit Details</ModalHeader>

        <ModalBody>
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <div className="profile-cell">
                <h4>Profile Picture</h4>
                <div className="pic-cell pet">
                  <div className="pic d-inline-flex overflow-hidden position-relative">
                    <span className="upload-cell position-absolute w-100 h-100 d-inline-flex align-items-center justify-content-center">
                      <Input
                        type="file"
                        className="position-absolute"
                        onChange={(e) => setOwnerImage(e?.target?.files?.[0])}
                      />
                      <FontAwesomeIcon icon={faCamera} />
                    </span>
                    <img
                      className="img-fluid w-100 h-100"
                      src={
                        imageUrl ||
                        ownerAndPetDetails?.user_image ||
                        require("../../../../../assets/img/vetRecorEMR/pet-thumbnail.png")
                      }
                      alt="User"
                    />
                    {/* <PetPlaceholder /> */}
                  </div>
                </div>
              </div>
            </FormGroup>

            <FormGroup>
              <Label htmlFor="firstname">First Name</Label>
              <Input
                type="text"
                name="firstname"
                id="firstname"
                placeholder="Enter First Name"
                label="First Name"
                className="mb-0"
                value={formik.values.firstname}
                onChange={formik.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="lastname">Last Name</Label>
              <Input
                type="text"
                name="lastname"
                id="lastname"
                placeholder="Enter Last Name"
                label="Last Name"
                className="mb-0"
                value={formik.values.lastname}
                onChange={formik.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Enter Email"
                isRequired={false}
                className="mb-0"
                labelVisible={false}
                value={formik?.values?.email}
                onChange={formik.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="phone">Phone</Label>
              <Input
                type="text"
                name="phone"
                id="phone"
                placeholder="Enter Phone"
                isRequired={false}
                className="mb-0"
                labelVisible={false}
                value={formik?.values?.phone}
                onChange={formik.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="address">Address</Label>
              <Input
                type="text"
                name="address"
                id="address"
                placeholder="Enter Address"
                isRequired={false}
                className="mb-0"
                labelVisible={false}
                value={formik?.values?.address}
                onChange={formik.handleChange}
              />
            </FormGroup>

            <Button type="submit">Update</Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OwnerProfilehead;
