import React, { useState } from "react";
import { Button, Collapse } from "reactstrap";
import CostBlock from "../CostBlock";
import PackageInfoBlock from "../PackageInfoBlock";
import SpecialNotes from "../SpecialNotes";
import "./index.scss";
import { useSelector } from "react-redux";

const ProductAccordion = ({ className = "" }) => {
  const [loadIndex, setLoadIndex] = useState(1);
  const [elemIndex, setElemIndex] = useState(null);
  const [addNewPackage, setAddNewPackage] = useState(false);
  const [toggle, setToggle] = useState(false);
  const toggleFunction = (index) => {
    if (loadIndex === index) {
      setLoadIndex(null);
    } else if (elemIndex === index) {
      setToggle(!toggle);
    } else {
      setToggle(true);
    }
    setElemIndex(index);
    setLoadIndex(null);
  };

  const toggleAddNewPackage = () => {
    setAddNewPackage(!addNewPackage);
  };

  const { permissionsData } = useSelector((state) => state.clinicPermissionReducer);
  const permissions = permissionsData;
  return (
    <>
      <div className="product-accordion-wrapper">
        <div className="accordion-blc d-flex flex-column border-top-0">
          <Button
            color="link"
            className={`accordion-btn text-start shadow-none text-left d-flex align-items-center ${loadIndex === 0 || (0 === elemIndex && toggle) ? "active" : ""
              }`}
            onClick={() => permissions?.edit_products && toggleFunction(0)}
          >
            <label>Cost</label>
            {/*permissions?.edit_products &&
              <span className="material-icons ms-auto icon-btn">
                {"add_circle"}
              </span>
            */}
          </Button>
          <Collapse isOpen={true}>
            <CostBlock />
          </Collapse>
        </div>
        <div className="accordion-blc d-flex flex-column">
          <Button
            color="link"
            className={`accordion-btn text-start shadow-none text-left d-flex align-items-center ${loadIndex === 1 || (1 === elemIndex && toggle) ? "active" : ""
              }`}
            onClick={() => permissions?.edit_products && toggleAddNewPackage()}
          >
            <label>Packages</label>
            {permissions?.edit_products &&
              <span className="material-icons ms-auto icon-btn">
                {addNewPackage ? "remove_circle" : "add_circle"}
              </span>
            }
          </Button>
          <Collapse isOpen={true}>
            <PackageInfoBlock
              addNew={addNewPackage}
              toggleAddFn={toggleAddNewPackage}
            />
          </Collapse>
        </div>
        {/*
        <div className="accordion-blc d-flex flex-column">
          <Button
            color="link"
            className={`accordion-btn text-start shadow-none text-left d-flex align-items-center ${loadIndex === 2 || (2 === elemIndex && toggle) ? "active" : ""
              }`}
            onClick={() => permissions?.edit_products && toggleFunction(2)}
          >
            <label>Custom Fields</label>
            {permissions?.edit_products &&
              <span className="material-icons ms-auto icon-btn">
                <span className="material-icons ms-auto icon-btn">
                  {"add_circle"}
                </span>
              </span>
            }
          </Button>
          <Collapse isOpen={true}>Custom Fields</Collapse>
        </div>
        <div className="accordion-blc d-flex flex-column">
          <Button
            color="link"
            className={`accordion-btn text-start shadow-none text-left d-flex align-items-center ${loadIndex === 3 || (3 === elemIndex && toggle) ? "active" : ""
              }`}
            onClick={() => permissions?.edit_products && toggleFunction(3)}
          >
            <label>Special Notes</label>
            {permissions?.edit_products &&
              <span className="material-icons ms-auto icon-btn">
                {"add_circle"}
              </span>
            }
          </Button>
          <Collapse isOpen={true}>
            <SpecialNotes />
          </Collapse>
          </div>*/}
      </div>
    </>
  );
};
export default ProductAccordion;
