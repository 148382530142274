import React from "react";
import StaffprofileEducationItem from './StaffprofileEducationItem';
import NoDataFoundBlock from "../../../../../../../shared/NoDataFoundBlock";

const StaffprofileEducation = ({ educationDetails }) => {
	const dummyData = [
		{
			id: 1,
			school_name: "Benguet State University",
			subject: "Doctor of Veterinary Medicine, Agriculture",
			year_from: "2007",
			year_to: "2013",
			education_certificates: [{ id: 1, certificate: "" }, { id: 2, certificate: "" }]

		},
		{
			id: 2,
			school_name: "Benguet State University",
			subject: "Doctor of Veterinary Medicine, Agriculture",
			year_from: "2007",
			year_to: "2013",
			education_certificates: [{ id: 1, certificate: "" }, { id: 2, certificate: "" }]

		},
		{
			id: 3,
			school_name: "Benguet State University",
			subject: "Doctor of Veterinary Medicine, Agriculture",
			year_from: "2007",
			year_to: "2013",
			education_certificates: [{ id: 1, certificate: "" }, { id: 2, certificate: "" }]

		},
		{
			id: 4,
			school_name: "Benguet State University",
			subject: "Doctor of Veterinary Medicine, Agriculture",
			year_from: "2007",
			year_to: "2013",
			education_certificates: [{ id: 1, certificate: "" }, { id: 2, certificate: "" }]

		},
	]
	return (
		<>
			<div className="staff-profile-education responsive">
				<h4 className="section-title cmb-10">Education</h4>
				<div className="inner d-flex flex-wrap">
					{
						!dummyData || dummyData.length <= 0 ? <NoDataFoundBlock className="layout-sm" /> : dummyData && dummyData?.map((education, index) => (
							<div key={index} className="col-cell d-flex flex-column">
								<StaffprofileEducationItem education={education} />
							</div>
						))
					}
				</div>
			</div>
		</>
	);
};

export default StaffprofileEducation;
