import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import db from "../../firebase";
import ApiClient from "api-client";
import { PORT, apiUrl } from "environment";
import { toasterConfig } from "utils/constants";
import { getPreSignUrl } from "./messagePanelAction";
const { toast } = require("react-toastify");

toast.configure();

export const getUserGroupList = (id, params, callback) => {
  return (dispatch, getState) => {
    const { user } = getState();
    const roomsRef = collection(db, "chatRooms");
    const chatQuery = query(
      roomsRef,
      where("membersId", "array-contains", user?.user_details?._id || "")
    );
    const unsub = onSnapshot(chatQuery, (snapshot) => {
      const response = snapshot?.docs
        ?.filter((doc) => !doc.data().appointment_id)
        ?.map((doc) => ({ ...doc.data(), id: doc?.id }));
      dispatch({
        type: "GET_GROUP_LIST",
        payload: response.sort(
          (a, b) => b.createdAt.seconds - a.createdAt.seconds
        ),
      });

      let unreadMessageCount = 0;
      response?.forEach((ele) =>
        ele.members?.forEach((member) => {
          if (member?.id === user?.user_details._id) {
            unreadMessageCount =
              unreadMessageCount + (member?.unreadCount || 0);
          }
        })
      );
      dispatch({
        type: "UNREAD_MESSAGE_GROUP_COUNT",
        payload: unreadMessageCount || 0,
      });
    });
    return unsub;
  };
};
export const getPrivateRoomList = (roomId, params, callback) => {
  return (dispatch, getState) => {
    const { user } = getState();
    const privateChatRef = collection(db, "privateChats");
    const chatQuery = query(
      privateChatRef,
      where("memberId", "array-contains", user?.user_details?._id || "")
    );
    const unsub = onSnapshot(chatQuery, (snapshot) => {
      const response = snapshot?.docs?.map((doc) => ({
        ...doc.data(),
        id: doc?.id,
      }));
      dispatch({
        type: "GET_PRIVATE_CHAT_LIST",
        payload: response?.sort(
          (a, b) => b.createdAt?.seconds - a.createdAt?.seconds
        ),
      });
      let unreadMessageCount = 0;
      response?.forEach((ele) =>
        ele.members?.forEach((member) => {
          if (member?.id === user?.user_details._id) {
            unreadMessageCount = unreadMessageCount + member.unreadCount;
          }
        })
      );
      dispatch({
        type: "UNREAD_MESSAGE_CHAT_COUNT",
        payload: unreadMessageCount || 0,
      });
      return callback(response);
    });
    return unsub;
  };
};

export const getSelectedRoomData = (type, id, callback) => {
  return (dispatch) => {
    if (!id) return callback(false);
    if (type === "group") {
      const roomsRef = doc(db, "chatRooms", id);
      getDoc(roomsRef)
        .then((doc) => {
          if (doc.exists()) {
            dispatch({
              type: "SELECT_ROOM",
              payload: { ...doc?.data(), id: doc?.id },
            });
            return callback(true);
          }
          dispatch({ type: "SELECT_ROOM", payload: {} });
          return callback(false);
        })
        .catch((error) => {
          console.error("Error getting document:", error);
          return callback(false);
        });
    } else {
      const roomsRef = doc(db, "privateChats", id);
      getDoc(roomsRef)
        .then((doc) => {
          if (doc.exists()) {
            dispatch({
              type: "SELECT_ROOM",
              payload: { ...doc?.data(), id: doc?.id },
            });
            return callback(true);
          }
          dispatch({ type: "SELECT_ROOM", payload: {} });
          return callback(false);
        })
        .catch((error) => {
          console.error("Error getting document:", error);
          return callback(false);
        });
    }
  };
};
export const fetchChatMessages = (roomData, searchKeyword, callback) => {
  return (dispatch) => {
    if (!roomData?.id) return callback(false);
    const privateChatRef = collection(db, "messages");
    const unsubscribe = onSnapshot(
      query(privateChatRef, where("roomId", "==", roomData?.id || "")),
      (snapshot) => {
        // dispatch({ type: "SELECT_ROOM", payload: roomData })
        let messageList = snapshot?.docs
          ?.map((doc) => ({ ...doc.data(), id: doc?.id }))
          ?.filter((message) =>
            message.message.toLowerCase().includes(searchKeyword?.toLowerCase())
          );
        //presign url
        const filterImagesData = messageList?.filter((o) => o?.attachments || o?.audios);
        if (filterImagesData && filterImagesData?.length) {
          dispatch(
            getPreSignUrl({ data: filterImagesData }, (res) => {
              if (res?.status) {
                messageList = messageList?.map((elem) => {
                  const dataFromRes = res?.response_data?.find(
                    (o) => o?.id === elem?.id
                  );
                  if (dataFromRes?.id) {
                    elem = dataFromRes;
                  }
                  return elem;
                });
                dispatch({ type: "MESSAGE_LIST", payload: messageList });
              }
            })
          );
        } else {
          dispatch({ type: "MESSAGE_LIST", payload: messageList });
        }

        return callback({ status: 200 });
      }
    );
    return unsubscribe
  };
};
export const fetchChatMessagesByFilters = (payload, roomId, callback) => {
  return (dispatch) => {
    const { senderId, messageType, searchKeyword } = payload;
    const privateChatRef = collection(db, "messages");

    let messageQuery = query(privateChatRef, where("roomId", "==", roomId || ""));

    if (senderId && messageType)
      messageQuery = query(
        privateChatRef,
        where("roomId", "==", roomId || ""),
        where("sender_id", "==", senderId || ""),
        where("message_type", "array-contains", messageType || "")
      );
    else if (senderId && !messageType)
      messageQuery = query(
        privateChatRef,
        where("roomId", "==", roomId || ""),
        where("sender_id", "==", senderId || "")
      );
    else if (!senderId && messageType)
      messageQuery = query(
        privateChatRef,
        where("roomId", "==", roomId || ""),
        where("message_type", "array-contains", messageType || "")
      );

    const unsubscribe = onSnapshot(messageQuery, (snapshot) => {
      let messageList = snapshot?.docs
        ?.map((doc) => ({ ...doc.data(), id: doc?.id }))
        ?.filter((message) =>
          message.message.toLowerCase().includes(searchKeyword?.toLowerCase())
        );
      //presign url
      const filterImagesData = messageList?.filter((o) => o?.attachments || o?.audios);
      if (filterImagesData && filterImagesData?.length) {
        dispatch(
          getPreSignUrl({ data: filterImagesData }, (res) => {
            if (res?.status) {
              messageList = messageList?.map((elem) => {
                const dataFromRes = res?.response_data?.find(
                  (o) => o?.id === elem?.id
                );
                if (dataFromRes?.id) {
                  elem = dataFromRes;
                }
                return elem;
              });
              dispatch({ type: "MESSAGE_LIST", payload: messageList });
            }
          })
        );
      } else {
        dispatch({ type: "MESSAGE_LIST", payload: messageList });
      }
      return callback({ status: 200, data: messageList });
    });

    return unsubscribe
  };
};

export const sendNewMEssage = (payload, callback) => {
  return async () => {
    const messagesRef = collection(db, "messages");
    const newMess = await addDoc(messagesRef, payload);
    if (newMess.id) {
      return callback(true);
    }
    return callback(false);
  };
};
export const updateRoomData = (chatType, updatedData) => {
  const roomId = updatedData?.id;
  delete updatedData.id;
  if (chatType === "private") {
    const roomsRef = doc(db, "privateChats", roomId);
    getDoc(roomsRef).then((doc) => {
      if (doc.exists()) {
        const docRef = doc(db, "privateChats", roomId);
        setDoc(docRef, updatedData)
          .then(() => {
            console.log("Document successfully written!");
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
      }
    });

    const docRef = doc(db, "privateChats", roomId);
    setDoc(docRef, updatedData)
      .then(() => {
        console.log("Document successfully written!");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  } else if (chatType === "group") {
    const docRef = doc(db, "chatRooms", roomId);
    setDoc(docRef, updatedData)
      .then(() => {
        console.log("Document successfully written!");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  }
};
export const updateUnreadCount = (senderId, chatType, callback) => {
  return async (dispatch, getState) => {
    const { selectedRoom } = getState().realTimeChatReducer;
    const docName = chatType === "private" ? "privateChats" : "chatRooms";
    const roomDocRef = doc(db, docName, selectedRoom?.id);

    // Get the current document data
    const roomDocSnapshot = await getDoc(roomDocRef);
    const roomDocData = roomDocSnapshot.data();
    roomDocData?.members?.forEach((element) => {
      if (element?.id !== senderId)
        element.unreadCount = (element.unreadCount || 0) + 1;
    });
    const dataToSet = { ...roomDocData, id: selectedRoom?.id };
    updateRoomData(chatType, dataToSet);
  };
};
export const clearUnreadMessageCount = (chatType, userId, callback) => {
  return async (dispatch, getState) => {
    let isRoomUpdate = false;
    const { selectedRoom } = getState().realTimeChatReducer;
    const docName = chatType === "private" ? "privateChats" : "chatRooms";
    if(selectedRoom?.id && docName){
      const roomDocRef = doc(db, docName, selectedRoom?.id);

      // Get the current document data
      const roomDocSnapshot = await getDoc(roomDocRef);
      const roomDocData = roomDocSnapshot.data();
      roomDocData?.members?.forEach((element) => {
        if (element?.id === userId && element.unreadCount > 0) {
          isRoomUpdate = true;
          element.unreadCount = 0;
        }
      });
      const dataToSet = { ...roomDocData, id: selectedRoom?.id };
      if (isRoomUpdate) {
        dispatch(updateRoomData(chatType, dataToSet));
      }
    }
  };
};
export const updateMessageForSeenBy = (data) => {
  return async (dispatch, getState) => {
    const { messageData, userId, chatType } = data;
    const messagesRef = doc(db, "messages", messageData?.id);
    const messageToSet = {
      ...messageData,
      seen_by: [...messageData.seen_by, userId],
    };
    delete messageToSet.id;
    setDoc(messagesRef, messageToSet)
      .then(() => {
        console.log("Message seen successfully!");
        dispatch(clearUnreadMessageCount(chatType, userId));
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  };
};
export const sendSMS = (payload, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();

    ApiClient.post(
      `${apiUrl}${PORT}/chat/sendMessages`,
      payload,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (response?.status) {
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};
