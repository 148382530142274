import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPetDetailsById } from "../../context/actions/patient-module/patient/pet";

import { HiXMark } from "react-icons/hi2";
import KennelInfoViewUpdate from "./KennelInfoViewUpdate";


export default function ViewEditBoarding({ data, setShow, update}) {

  const dialogRef = useRef(null);

  useEffect(() => {
    
  }, []);



  const handleBackdropClick = (event) => {
    if (event.target === dialogRef.current) {
      setShow(false);
    }
  };



  return (
    <>
      <div
        ref={dialogRef}
        onClick={handleBackdropClick}
        className="fixed inset-0 w-screen h-screen bg-black bg-opacity-25 z-10"
      />
      <div
        className={
          "fixed right-0 h-screen z-30 top-0 px-5 py-2.5 border-r w-full overflow-auto sm:w-[70vw] md:w-[60vw] lg:w-[50vw] bg-white"
        }
      >
        <button
          onClick={() => setShow(false)}
          className="absolute top-3 p-1 right-3 rounded-full hover:bg-gray-100"
        >
          <HiXMark className="w-5 h-5" />
        </button>
        <KennelInfoViewUpdate  setShow={setShow} data={data} update={update} />
      </div>
    </>
  );
}
