import React, { useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import "yup-phone-lite"

import { useDispatch } from "react-redux";
import UserPlaceholder from "shared/UserPlaceholder"
import { Button, Spinner } from "reactstrap";
import { updatePetOwnerDetails, updatePetOwnerImage } from "context/actions/pet-owner-dashboard";
import AddressAutoCompleteInput from "shared/AddressAutoCompleteInputStandard";
import UploadImageBlock from "shared/UploadImageBlock";
import PhoneNumberInput from "shared/FormsInput/PhoneInput";
import { getPetOwnerDetails } from "context/actions/pet-owner-dashboard";

const PetOverViewForm = ({ editData, onHide, handleSuccess }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(editData?.user_image)
  const [imageFile, setImageFile] = useState("")

  const dispatch = useDispatch();

  const initialValues = {
    firstname: editData?.firstname || "",
    lastname: editData?.lastname || "",
    email: editData?.email || "",
    phone: editData?.phone || "",
    address: editData?.address || "",
    city: editData?.city || "",
    state: editData?.state || "",
    country: editData?.country || "",
    zipcode: editData?.zipcode || "",
    _id: editData?._id || "",
  };

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("First name is required"),
    lastname: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string().phone().required(),
    address: Yup.string().required("Address is required"),
    // city: Yup.string().required("City is required"),
    // state: Yup.string().required("State is required"),
    // country: Yup.string().required("Country is required"),
    // gender: Yup.string().required("Gender is required"),
    // zipcode: Yup.string().required("Zipcode is required"),
  });

  const handleSubmit = async (values) => {
    setBtnLoading(true);
    dispatch(updatePetOwnerDetails(values, async  () => {
      if (imageFile && editData?._id) {
        var imgPayloadData = new FormData();
        imgPayloadData.append("pet_owner", editData?._id);
        imgPayloadData.append("pet_owner_image", imageFile,
        imageFile?.name);
        dispatch(updatePetOwnerImage(imgPayloadData, async () => {

          const response = await dispatch(getPetOwnerDetails(editData?._id));
          if(response?.status){
            dispatch({type: "USER_DETAILS", data: response?.response_data})
          }
          onHide();
          handleSuccess();
          setBtnLoading(false);
        }));
      }
      else {
        const response = await dispatch(getPetOwnerDetails(editData?._id));
        if(response?.status){
          dispatch({type: "USER_DETAILS", data: response?.response_data})
        }
        onHide();
        handleSuccess();
        setBtnLoading(false);
      }

    }));
   
  };

  return (
    <Offcanvas show={true} onHide={onHide} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{editData ? "Edit " : ""}User Details</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            const { values, errors, touched, setFieldValue, handleChange, handleSubmit } = props;
            const {
              firstname,
              lastname,
              email,
              phone,
              address
            } = values;

            const {
              firstname: err_firstname,
              lastname: err_lastname,
              email: err_email,
              phone: err_phone,
              address: err_address
            } = errors;

            return (
              <Form>

                <Form.Group className="col-md-6">
                  <label>Profile Picture</label>
                  <div className="pic-cell pet">
                    <div className="pic d-inline-flex overflow-hidden position-relative">
                      <span className="upload-cell position-absolute w-100 h-100 d-inline-flex align-items-center justify-content-center">
                        <UploadImageBlock
                          petImage={imageUrl}
                          sendPetImage={() => {}}
                          petImageObj={(value) => {
                            setImageUrl(
      
                              URL.createObjectURL(value)
                            );
                            setImageFile(value);
                          }}
                        />
                      </span>
                      {imageUrl ? (
                        <img
                          className="img-fluid w-100 h-100"
                          src={imageUrl}
                          alt="User"
                        />
                      ) : (
                        <UserPlaceholder style={{ borderRadius: "10px" }} />
                      )}
                    </div>
                  </div>
                </ Form.Group>
                <Form.Group controlId="formFirstname">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstname"
                    value={firstname}
                    onChange={handleChange}
                    className="mb-2"
                  />
                  {err_firstname && (
                    <div className="text-12-400 text-danger mt-1">
                      {err_firstname}
                    </div>
                  )}
                </Form.Group>

                <Form.Group controlId="formLastname">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastname"
                    value={lastname}
                    onChange={handleChange}
                    className="mb-2"
                  />
                  {err_lastname && (
                    <div className="text-12-400 text-danger mt-1">
                      {err_lastname}
                    </div>
                  )}
                  
                </Form.Group>

                <Form.Group controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    className="mb-2"
                  />
                  {err_email && (
                    <div className="text-12-400 text-danger mt-1">
                      {err_email}
                    </div>
                  )}
                  
                </Form.Group>

                <Form.Group controlId="formPhone">
                  <Form.Label>Phone</Form.Label>

                  <PhoneNumberInput
											style={{ maxWidth: "100%", height: '48px', color: '#1d1d1d', fontSize: '16px', fontWeight: '400',  fontFamily: 'Inter, sans-serif'}}
											placeholder="Phone Number"											 
											name="phone"
											 id="phone"
											error={err_phone}
											touched={touched?.phone}

											//  className="mb-[20px]"
									
											 value={phone}
											 onChange={(e) => {setFieldValue('phone', '+'+e);
										 }} />
                  {/* <Form.Control
                    type="text"
                    name="phone"
                    value={phone}
                    onChange={handleChange}
                    className="mb-2"
                  />
                  {err_phone && (
                    <div className="text-12-400 text-danger mt-1">
                      {err_phone}
                    </div>
                  )} */}
                  
                </Form.Group>

                {/* <Form.Group controlId="formGender">
                  <Form.Label>Gender</Form.Label>
                  <Form.Control
                    as="select"
                    name="gender"
                    value={gender}
                    onChange={handleChange}
                    className="mb-2"
                  >
                    <option value="" disabled>
                      Select Gender
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </Form.Control>
                  {err_gender && (
                    <div className="text-12-400 text-danger mt-1">
                      {err_gender}
                    </div>
                  )}
                </Form.Group> */}
                <Form.Group controlId="formAddress">
                <Form.Label>Address</Form.Label>
                <AddressAutoCompleteInput
											label={'Home Address'}
											style={{color: '#1d1d1d', fontSize: '16px', fontWeight: '600', fontFamily: 'Inter'}}
											className="input-wrapper"
											error={err_address}
											touched={touched?.address}


											onSelect={(event) => {
												console.log(event)
											  setFieldValue(`address`, event?.address + ', ' + event?.postal);
											// formik.setFieldValue(`latitude`, event?.lat);
											// formik.setFieldValue(`longitude`, event?.lng);
											}}
											value={address} />
                </Form.Group>

                {/* <Form.Group controlId="formAddress">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    value={address}
                    onChange={handleChange}
                    className="mb-2"
                  />
                  {err_address && (
                    <div className="text-12-400 text-danger mt-1">
                      {err_address}
                    </div>
                  )}
                  
                </Form.Group>

                <Form.Group controlId="formCity">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={city}
                    onChange={handleChange}
                    className="mb-2"
                  />
                  {err_city && (
                    <div className="text-12-400 text-danger mt-1">
                      {err_city}
                    </div>
                  )}
                 
                </Form.Group>

                <Form.Group controlId="formState">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    name="state"
                    value={state}
                    onChange={handleChange}
                    className="mb-2"
                  />
                   {err_state && (
                    <div className="text-12-400 text-danger mt-1">
                      {err_state}
                    </div>
                  )}
                  
                </Form.Group>

                <Form.Group controlId="formCountry">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    name="country"
                    value={country}
                    onChange={handleChange}
                    className="mb-2"
                  />
                   {err_country && (
                    <div className="text-12-400 text-danger mt-1">
                      {err_country}
                    </div>
                  )}
                  
                </Form.Group>

                <Form.Group controlId="formZipcode">
                  <Form.Label>Zipcode</Form.Label>
                  <Form.Control
                    type="text"
                    name="zipcode"
                    value={zipcode}
                    onChange={handleChange}
                    className="mb-2"
                  />
                  {err_zipcode && (
                    <div className="text-12-400 text-danger mt-1">
                      {err_zipcode}
                    </div>
                  )}
                </Form.Group> */}

                <Button variant="primary" type="submit" onClick={handleSubmit}>
                  {editData ? "Update" : "Submit"}
                  {btnLoading && (
                    <Spinner
                      animation="border"
                      role="status"
                      size="sm"
                      className="ms-2"
                    />
                  )}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export { PetOverViewForm };
