import React from 'react';
import { useLang } from './Inpleoi18n';
import { IntlProvider } from 'react-intl';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import enMessages from './messages/en.json';

const allMessages = {
    en: enMessages
}

const I18nProvider = ({ children }) => {
    const locale = useLang()
    const messages = allMessages[locale]
    return (
        <IntlProvider locale={locale} messages={messages}>
            {children}
        </IntlProvider>
    )
}

export { I18nProvider }
