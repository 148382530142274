
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../../../api-client";
import { apiUrl, PORT } from "../../../../environment";
import { toasterConfig } from "../../../../utils/constants";
import moment from "moment";

toast.configure();
/**
 * All Api endpoints to be call
*/

/**
 * get all pet details
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns 
 */
export const getAllReportsByPetId = (petId, date,params, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, } = getState();
        if (petId) {
            dispatch({ type: "REPORT_LOADING", payload: true })
            const path = date?.endDate ? `${apiUrl}${PORT}/report/get-all/${petId}?date=${date?.reportDate}&endDate=${date?.endDate}` : date?.reportDate ?  `${apiUrl}${PORT}/report/get-all/${petId}?date=${date?.reportDate}`:  `${apiUrl}${PORT}/report/get-all/${petId}`;
            ApiClient.get(path, params, token, dispatch, getState).then((response) => {
                dispatch({ type: "REPORT_LOADING", payload: false })
                if (!response) {
                    return callback();
                }
                else if (response?.status) {                    
                    dispatch({ type: "GET_REPORT_LIST", payload: response?.response_data?.response })
                    return callback(response);
                } else {
                    dispatch({ type: "GET_REPORT_LIST", payload: [] })
                    return callback(response);
                }
            })
        } else {

            return callback({ status: false });
        }
    };
};

/**
 * get all vaccination dues details
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns 
 */
export const addNewPetXrayReport = (formData, petId, currentDate, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, } = getState();

        dispatch({ type: "REPORT_LOADING", payload: true })
        ApiClient.postFormData(`${apiUrl}${PORT}/report/add-report`, formData, token, dispatch, getState).then((response) => {
            dispatch({ type: "REPORT_LOADING", payload: false })
            if (!response) {
                return callback();
            }
            else if (response?.status) {
                toast.success(response.message, toasterConfig);
                dispatch(getAllReportsByPetId(petId,{ reportDate: moment(currentDate).toISOString() }))
                // dispatch({ type: "PET_DOCUMENT_LIST", payload: response?.response_data })
                return callback(response);
            } else {
                toast.error(response.message, toasterConfig);

                return callback(response);
            }
        })

    };
};


/**
 * get all vaccination dues details
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns 
 */
export const updatePetReport = (formData, petId, currentDate, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, } = getState();

        dispatch({ type: "REPORT_LOADING", payload: true })
        ApiClient.putFormData(`${apiUrl}${PORT}/report/update`, formData, token, dispatch, getState).then((response) => {
            dispatch({ type: "REPORT_LOADING", payload: false })
            if (!response) {
                return callback();
            }
            else if (response?.status) {
                toast.success(response.message, toasterConfig);
                dispatch(getAllReportsByPetId(petId, { reportDate: moment(currentDate).toISOString() }))
                // dispatch({ type: "PET_DOCUMENT_LIST", payload: response?.response_data })
                return callback(response);
            } else {
                toast.error(response.message, toasterConfig);

                return callback(response);
            }
        })

    };
};


/**
 * get all pet details
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns 
 */
export const getAllReportsNotes = (payload, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, } = getState();
        if (payload.petId) {
            ApiClient.get(`${apiUrl}${PORT}/report-doctor-note/get-all/${payload.petId}`, {}, token, dispatch, getState).then((response) => {
                    return callback(response);
            })
        } if (payload.petId && payload.doctorId) {
            ApiClient.get(`${apiUrl}${PORT}/report-doctor-note/get-all/${payload.petId}?doctorId=${payload.doctorId}`, {}, token, dispatch).then((response) => {
                    return callback(response);
            })
        }  else {
            return callback({ status: false });
        }
    };
};

/**
 * get all pet details
 * @param {Object} payload
 * @param {Function} callback
 * @returns 
 */
export const addReportsNotes = ( payload,callback) => {
    return (dispatch, getState) => {
        const {
          user: { token },
        } = getState();
          ApiClient.post(
            `${apiUrl}${PORT}/report-doctor-note/add`,
            payload,
            token,
            dispatch, getState
          ).then((response) => {
            callback(response)
          });
      };
};