import React, { useState } from "react";
import { Button, Table, FormGroup, Input, InputGroup } from "reactstrap";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import './index.scss'
import { ReactComponent as DeleteSvg } from '../../../assets/img/delete.svg'
import { ReactComponent as CheckSvg } from '../../../assets/img/check_circle.svg'
import SwitchCell from "../../../shared/SwitchCell";
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as CrossSvg } from "../../../assets/img/close-square-svgrepo-com.svg"
import { ReactComponent as PencilEditSvg } from "../../../assets/img/pencil-edit-button-svgrepo-com.svg"

import * as Yup from "yup";


const ItemsBlock = ({ className = '', addNew, setAddItem, lineItems, setLineItems }) => {

	const handleSubmit = (values) => {
		setLineItems((lineItemsPrev) => [...lineItemsPrev, values])
		// setAddItem(false)
	}

	const [editVal, setEditVal] = useState(null)

	let lineItemDefaultState = {
		id: uuidv4(),
		line_item_name: "",
		quantity: 1,
		note: "",
		price: ""
	}

	const removeLineItem = ( id) => {
		// e.preventDefault();
		let lineItemsUpdated = lineItems.filter((ele) => ele.id !== id)
		setLineItems(lineItemsUpdated)
	}

	const itemSchema = Yup.object().shape({
		// id: Yup.string().required(),
		line_item_name: Yup.string().required(),
		quantity: Yup.number().required(),
		price: Yup.number().required(),
		note: Yup.string()
	});


	


	return (
		<>
			<div className={`package-info-blc ${className}`}>
				{(lineItems?.length > 0 || addNew) &&
					<div className="table-listing-blc overflow-auto">
						<Formik
							initialValues={editVal || lineItemDefaultState}
							enableReinitialize
							validationSchema={itemSchema}
							onSubmit={(vals, { resetForm }) => {
								if (editVal) {
									// Update the item in the lineItems array with the edited values
									const updatedLineItems = lineItems.map((item) =>
										item.id === editVal.id ? vals : item
									);
									setLineItems(updatedLineItems);
									setEditVal(null); // Clear the editItem state
								}
								else {
									handleSubmit(vals)

								}
								resetForm(lineItemDefaultState);
							}}>

							{(formik) => (
								<Form>
									<Table responsive className="table-striped">
										<thead>
											<tr>
												<th className="bg-transparent">Name</th>
												<th className="bg-transparent" style={{ minWidth: '120px' }}>Notes</th>
												<th className="bg-transparent" style={{ minWidth: '120px' }}>Quantity</th>
												<th className="bg-transparent" style={{ minWidth: '250px' }}>Amount</th>
												

											</tr>
										</thead>
										<tbody>

											{addNew && 

												<tr className="bg-[#ffffff]">

													<td className="me-2">

														<Input value={formik?.values?.line_item_name} onChange={(e) => formik.handleChange(e)} name="line_item_name" placeholder="-Add Info-" type="text" />

													</td>
													<td className="me-2">

														<Input value={formik?.values?.note} onChange={(e) => formik.handleChange(e)} name="note" placeholder="-Add Info-" />

													</td>
													<td className="me-2">

														<Input type="number" value={formik?.values?.quantity} onChange={(e) => formik.handleChange(e)} name="quantity" placeholder="-Add Info-" />

													</td>

													<td className="me-2">

														<Input type="number" step=".01" value={formik?.values?.price} onChange={(e) => formik.handleChange(e)} name="price" placeholder="-Add Info-" />

													</td>

												
													<td>
														<div className="flex items-center justify-end">
															<button className={'p-1'} style={{ width: '35px' }} size="sm" type="submit" ><CheckSvg /></button>
															<button type="button" class="edit-btn btn btn-link" onClick={(e) => setAddItem(false)}><DeleteSvg /></button>

														</div>
													</td>


												</tr>
											}
											{
												(lineItems || []).map((item, i) =>
													<tr key={i}>
														{/* <td>{item?.unitDetails?.unit}</td> */}
														<td>{item?.line_item_name}</td>
														<td>
															{item?.note}
														</td>
														<td>
															{item?.quantity}
														</td>
														<td>
															${item?.price.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
														</td>
														


														{/* <td>
															<SwitchCell labelVisible={true} className={item.stock_status ? '' : 'disable'} label={item.stock_status ? "In-Stock" : "Out-Stock"} />
														</td> */}
														<td>
															<div className="flex items-center justify-end">
																<button className={'p-1'} style={{ width: '35px' }} size="sm" type="button" onClick={(e) => {
																	setAddItem(true	)
																	setEditVal(item)
																}}><PencilEditSvg fill="gray" width={18} height={18} /></button>
																<button type="button" class="edit-btn btn btn-link" onClick={() => removeLineItem( item.id)}><DeleteSvg /></button>

															</div>
														</td>
													</tr>

												)
											}
										</tbody>
									</Table>
								</Form>)}
						</Formik>
					</div>}
			</div>
		</>
	);
};

export default ItemsBlock;
