import React from "react";
import Select from "react-select";
import { CiCircleCheck } from "react-icons/ci";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { IoReload } from "react-icons/io5";
import moment from "moment";
import { BsFillImageFill } from "react-icons/bs";

const DrainsTubesCell = ({
  data,
  cellKey,
  editData,
  cellTitle,
  setEditData,
  isFieldAccess,
  handleEditDetails,
  handleStatusChange,
  deleted,
  setDisplayURL,
}) => {
  const { value, isAnyFieldValue, locationDetailId } = data;
  const isSelectInput = ["type"].includes(cellKey);
  const isDatePicker = ["placement_date", "removed_date"].includes(cellKey);
  const isStatusBtn = ["active"].includes(cellKey);
  const isEdit =
    cellKey === editData?.fieldId &&
    locationDetailId === editData?.locationDetailId;
  const isPhoto = cellKey === "drain_tube_document";
  return (
    <React.Fragment>
      {isFieldAccess ? (
        <>
          {isEdit ? (
            isSelectInput ? (
              <span className="dropdown-container">
                <Select
                  isClearable
                  placeholder="Select Type"
                  id={`${cellKey}_${locationDetailId}`}
                  options={editData?.options?.map((o) => {
                    return {
                      value: o,
                      label: o,
                    };
                  })}
                  value={value ? { value: value, label: value } : ""}
                  onChange={(e) => {
                    let sVal = e?.value || "";
                    handleEditDetails({
                      ...editData,
                      fieldValue: sVal,
                    });
                    setTimeout(() => {
                      setEditData(null);
                    }, 500);
                  }}
                />
              </span>
            ) : isDatePicker ? (
              <span className="postion-relative">
                <span>--Select Date--</span>
                <div className="date-tile-edit-popover shadow">
                  <div>
                    <input
                      type="datetime-local"
                      value={editData?.fieldValue || ""}
                      onChange={(e) => {
                        setEditData((prev) => {
                          return {
                            ...prev,
                            fieldValue: e.target.value,
                          };
                        });
                      }}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-end mt-3 gap-2">
                    <IoIosCloseCircleOutline
                      size={20}
                      color="red"
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (value) {
                          handleEditDetails({
                            ...editData,
                            fieldId: cellKey,
                            fieldValue: "",
                          });
                          setTimeout(() => {
                            setEditData(null);
                          }, 500);
                        } else {
                          setEditData(null);
                        }
                      }}
                    />
                    <CiCircleCheck
                      size={20}
                      color="#11ca9d"
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (editData?.fieldValue) {
                          if (
                            editData?.fieldValue !==
                            moment(value, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
                              "YYYY-MM-DDTHH:mm"
                            )
                          ) {
                            handleEditDetails(editData);
                            setTimeout(() => {
                              setEditData(null);
                            }, 500);
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </span>
            ) : (
              <span className="iv-input-block">
                <input
                  name={cellKey}
                  type="text"
                  placeholder={`Enter ${cellTitle}`}
                  value={editData?.fieldValue || ""}
                  onChange={(e) => {
                    setEditData((prev) => {
                      return {
                        ...prev,
                        fieldValue: e.target.value,
                      };
                    });
                  }}
                  onBlur={() => {
                    if (`${value}` !== `${editData?.fieldValue}`) {
                      handleEditDetails(editData);
                    } else {
                      setEditData(null);
                    }
                  }}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      if (`${value}` !== `${editData?.fieldValue}`) {
                        handleEditDetails(editData);
                      } else {
                        setEditData(null);
                      }
                    } else if (e.keyCode === 27) {
                      setEditData(null);
                    } else {
                      // Nothing
                    }
                  }}
                />
              </span>
            )
          ) : isStatusBtn ? (
            <span
              className={value ? "active-btn" : "inactive-btn"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleStatusChange(value);
              }}
            >
              {value ? "Active" : "Inactive"}
            </span>
          ) : isPhoto ? (
            <>
              <span className="fa-center gap-3">
                {value && (
                  <span
                    className="cursor-pointer relative"
                    onClick={() => {
                      setDisplayURL(value);
                    }}
                  >
                    {value && !deleted && (
                      <span
                        className="bg-dc35 rounded-circle h-16 w-16 absolute end-0 top-0 f-center"
                        style={{
                          marginRight: "-5px",
                          marginTop: "-5px",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleEditDetails({
                            fieldId: "drain_tube_document",
                            fieldValue: "",
                          });
                        }}
                      >
                        <IoClose color="#ffff" size={12} />
                      </span>
                    )}
                    <img
                      src={value}
                      alt="val-img"
                      className="h-49 w-49 br-10"
                    />
                  </span>
                )}
                <span className={deleted ? "" : "add_file_block"}>
                  {value ? (
                    <IoReload />
                  ) : (
                    <BsFillImageFill size={20} color="#a9a9a9" />
                  )}

                  {!deleted && (
                    <input
                      type="file"
                      accept="image/png, image/jpg, image/jpeg"
                      onChange={(e) => {
                        if (e?.target?.files?.[0]) {
                          handleEditDetails({
                            fieldId: "drain_tube_document",
                            fieldValue: e?.target?.files?.[0],
                          });
                        }
                      }}
                    />
                  )}
                </span>
              </span>
            </>
          ) : value ? (
            isDatePicker ? (
              <span>
                {`${moment(value, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
                  "MM/DD/YYYY"
                )} at ${moment(value, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
                  "hh:mm a"
                )}`}
              </span>
            ) : (
              <span>{value}</span>
            )
          ) : isAnyFieldValue ? (
            <span>--</span>
          ) : (
            <span className="add_info">{deleted ? "--" : "--Add Info--"}</span>
          )}
        </>
      ) : (
        <span className="no-access-field" />
      )}
    </React.Fragment>
  );
};

export default DrainsTubesCell;
