import React, { Fragment, useEffect, useState } from "react";
import { Button } from "reactstrap";
import { getNextWeekSpanDays } from "../../../utils/calendar/constant";
import LoaderUI from "../../loader";
import NodataFoundBlock from "../../NoDataFoundBlock";
import UserPlaceholder from "../../UserPlaceholder";
import AddSlotButton from "../AddSlotButton";
import SlotItemName from "../SlotItemName";
import SlotItemTime from "../SlotItemTime";
import SlotUnavailable from "../SlotUnavailable";
import ViewMoreSlot from "../ViewMoreSlot";
import "./index.scss";
import moment from "moment";
import { useSelector } from "react-redux";

export const CalendarWeekView = ({
  isBoarding,
  isOperatingRoom,
  calendarViewData,
  doctorsList,
}) => {
  const [dates, setDates] = useState([]);
  const [loader, showLoader] = useState(false);

  const [showMore, setShowMore] = useState({});

  const [appointmentsCounts, setAppointmentsCounts] = useState({});

  useEffect(() => {
    showLoader(true);
    let dateArray = [];
    var startDate = moment(calendarViewData?.week_start_date, "DD-MM-YYYY");
    let endDate = moment(calendarViewData?.week_end_date, "DD-MM-YYYY");
    while (startDate.isSameOrBefore(endDate, "day")) {
      dateArray.push({
        day: startDate.format("dddd")?.toLowerCase(),
        date: +startDate.format("D"),
        full_date: startDate.format("DD-MM-YYYY"),
      });
      startDate.add(1, "days");
    }
    console.log(dateArray);
    setDates(dateArray);
    let initialCounts = {};
    let initialShow = {};
    console.log(doctorsList)
    doctorsList?.forEach((doc) => {
      initialCounts[doc?._id] = {};
      initialShow[doc?._id] = {}

   
        dateArray?.forEach((val, i) => {
          initialShow[doc?._id][i] = false;

          initialCounts[doc?._id][i] = 2;
        }); 
  
  })

    setAppointmentsCounts(initialCounts);
    setShowMore(initialShow)
    console.log(initialCounts, 'init counts')
    console.log(initialShow, 'initshow')

    showLoader(false);
  }, [calendarViewData, doctorsList]);

  var naiveReverse = function (string) {
    let str =
      string?.split("-")[2] +
      "-" +
      string?.split("-")[1] +
      "-" +
      string?.split("-")[0];
    return str;
  };



  const toggleAppointments = (id, rowIndex) => {

    let docs = doctorsList.filter((item) => item?._id === id);
    let doc = docs?.[0]
    console.log(doc, 'doc');
    console.log(appointmentsCounts, 'appointmentCounts')
    setAppointmentsCounts((prevState) => ({
      ...prevState,
      [id] : { ...prevState?.[id],
      [rowIndex]: prevState[id][rowIndex] === 2 ? doc?.appointments.length : 2 }
    }));
  };

  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );

  const daysOfWeek = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const startDayIndex = daysOfWeek.indexOf(
    clinic_settings.calendar_start_day?.toLowerCase()
  );
  const orderedDaysOfWeek = [
    ...daysOfWeek.slice(startDayIndex),
    ...daysOfWeek.slice(0, startDayIndex),
  ];
  return (
    <>
      {loader && <LoaderUI overlay />}
      {isBoarding || isOperatingRoom ? (
        <div
          className={`calendar-week-view  ${
            !doctorsList && doctorsList.length <= 0
              ? "overflow-hidden"
              : "overflow-auto"
          }`}
        >
          <table className="table">
            <thead>
              <tr>
                <th className="user-filter-blc-wrapper">
                  <div className="user-filter-blc d-flex align-items-center">
                    <h3 className="ttl">Patient Name</h3>
                    <div className="right-col ms-auto d-inline-flex align-items-center">
                      <Button color="link" className="d-inline-flex">
                        <span className="material-symbols-outlined">
                          filter_list
                        </span>
                      </Button>
                      <Button color="link" className="d-inline-flex">
                        <span className="material-symbols-outlined">sync</span>
                      </Button>
                    </div>
                  </div>
                </th>
                {orderedDaysOfWeek.map((day, i) => {
                  const foundDate = dates?.find((dat) => dat.day === day);
                  const formattedDay = foundDate
                    ? moment(foundDate.date, "DD-MM-YYYY").format("ddd")
                    : "";
                  return (
                    <th className="day-cell-hd text-center cell-width" key={i}>
                      {formattedDay.toUpperCase()}
                      <span className="d-block">{foundDate?.date}</span>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {doctorsList && doctorsList?.length > 0 ? (
                doctorsList.map((doctor, i) => (

                  <tr key={i}>
                    <th className="user-pic-cell-wrapper">
                      <div className="ml-3 user-pic-cell d-inline-flex align-items-center">
                        <div className="pic rounded-circle overflow-hidden">
                          {doctor?.patient_id?.pet_image ||
                          doctor?.pet_image ? (
                            <img
                              className="img-fluid w-100 h-100"
                              src={
                                doctor?.pet_image ||
                                doctor?.patient_id?.pet_image
                              }
                              alt="User"
                            />
                          ) : (
                            <UserPlaceholder />
                          )}
                        </div>
                        <div className="txt ">
                          {
                            <h4>
                              {doctor?.pet_name || doctor?.patient_id?.pet_name}
                            </h4>
                          }
                        </div>
                      </div>
                    </th>
                    {dates?.map((date, i2) => (
                      <Fragment key={i2}>
                        {doctorsList &&
                        doctorsList.filter(
                          (data) =>
                            naiveReverse(data?.boarding_date) ||
                            naiveReverse(data?.surgery_date) === date?.full_date
                        ).length > 0 ? (
                          <td className="no-hover">
                            <div className="data-container">
                              {doctorsList
                                .sort((a, b) => {
                                  if (
                                    a.boarding_start_time ||
                                    a.surgery_start_time <
                                      b.boarding_start_time ||
                                    b.surgery_start_time
                                  ) {
                                    return -1;
                                  }
                                  if (
                                    a.boarding_start_time[1] ||
                                    a.surgery_start_time[1] >
                                      b.boarding_start_time[1] ||
                                    b.surgery_start_time[1]
                                  ) {
                                    return 1;
                                  }

                                  return (
                                    a.boarding_start_time[0] ||
                                    a.surgery_start_time[1] -
                                      b.boarding_start_time[0] ||
                                    b.surgery_start_time[1]
                                  );
                                })
                                .map((appointment, apptIndex) => (
                                  <Fragment key={apptIndex}>
                                    {naiveReverse(
                                      appointment.boarding_date ||
                                        appointment.surgery_date
                                    ) == date?.full_date && (
                                      <SlotItemName
                                        appointmentData={appointment}
                                      />
                                    )}
                                  </Fragment>
                                ))}
                            </div>
                          </td>
                        ) : (
                          <td>
                            <div className="data-container blank-slot add-slot-btn-wrapper d-flex align-items-center justify-content-center position-relative">
                              <AddSlotButton />
                            </div>
                          </td>
                        )}
                      </Fragment>
                    ))}
                    {/* <td>
                      <div className="data-container blank-slot add-slot-btn-wrapper d-flex align-items-center justify-content-center position-absolute">
                        <AddSlotButton />
                      </div>
                    </td>
                    <td className="no-hover">
                      <div className="data-container">
                        <SlotItemTime />
                        <SlotItemTime className="clinic" />
                        <SlotItemTime className="phone" />
                        <SlotItemTime className="video-conference" />
                        <SlotItemName />
                        <ViewMoreSlot />
                      </div>
                    </td>
                    <td className="no-hover">
                      <div className="data-container blank-slot d-flex align-items-center justify-content-center position-absolute">
                        <SlotUnavailable />
                      </div>
                    </td>
                    <td className="no-hover">
                      <div className="data-container blank-slot d-flex align-items-center justify-content-center position-absolute">
                        <SlotUnavailable />
                      </div>
                    </td>
                    <td className="no-hover">
                      <div className="data-container blank-slot d-flex align-items-center justify-content-center position-absolute">
                        <SlotUnavailable />
                      </div>
                    </td>
                    <td className="no-hover">
                      <div className="data-container blank-slot d-flex align-items-center justify-content-center position-absolute">
                        <SlotUnavailable />
                      </div>
                    </td>
                    <td className="no-hover">
                      <div className="data-container blank-slot d-flex align-items-center justify-content-center position-absolute">
                        <SlotUnavailable />
                      </div>
                    </td> */}
                  </tr>
                ))
              ) : (
                <tr className="calendar-no-data-rw">
                  <td colSpan="8">
                    <NodataFoundBlock message="No appointment found of these dates" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div
          className={`calendar-week-view  ${
            !doctorsList && doctorsList.length <= 0
              ? "overflow-hidden"
              : "overflow-auto"
          }`}
        >
          <table className="table">
            <thead>
              <tr>
                <th className="user-filter-blc-wrapper">
                  <div className="user-filter-blc d-flex align-items-center">
                    <h3 className="ttl">Doctor Name</h3>
                    <div className="right-col ms-auto d-inline-flex align-items-center">
                      <Button color="link" className="d-inline-flex">
                        <span className="material-symbols-outlined">
                          filter_list
                        </span>
                      </Button>
                      <Button color="link" className="d-inline-flex">
                        <span className="material-symbols-outlined">sync</span>
                      </Button>
                    </div>
                  </div>
                </th>
                {/* 
                  {dates.map((date, i) => (
                    <th className="day-cell-hd text-center cell-width" key={i}>
                      {date?.day}
                      <span className="d-block">{date?.date}</span>
                    </th>
                  ))} */}

                {orderedDaysOfWeek.map((day, i) => {
                  const foundDate = dates?.find((dat) => dat.day === day);
                  const formattedDay = foundDate
                    ? moment(foundDate.full_date, "DD-MM-YYYY").format("ddd")
                    : "";
                  return (
                    <th className="day-cell-hd text-center cell-width" key={i}>
                      {formattedDay.toUpperCase()}
                      <span className="d-block">{foundDate?.date}</span>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {doctorsList && doctorsList?.length > 0 ? (
                doctorsList.map((doctor, i) => (
                  <tr key={i}>
                    <th className="user-pic-cell-wrapper">
                      <div className="user-pic-cell d-inline-flex align-items-center">
                        <div className="pic rounded-circle overflow-hidden">
                          {doctor?.user ? (
                            doctor?.user?.user_image ? (
                              <img
                                className="img-fluid w-100 h-100"
                                src={doctor?.user?.user_image}
                                alt="User"
                              />
                            ) : (
                              <UserPlaceholder />
                            )
                          ) : doctor?.pet_image ? (
                            <img
                              className="img-fluid w-100 h-100"
                              src={doctor?.pet_image}
                              alt="User"
                            />
                          ) : (
                            <UserPlaceholder />
                          )}
                        </div>
                        <div className="txt">
                          {doctor?.user ? (
                            <>
                              <h4>
                                {doctor?.user?.title}{" "}
                                <>
                                  {clinic_settings?.doctor_name_format === 0 ? (
                                    <>
                                      <span className="">
                                        {doctor?.user?.lastname}
                                      </span>
                                      {" , "}
                                      <span className="">
                                        {doctor?.user?.firstname}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span className="mr-1">
                                        {doctor?.user?.firstname}
                                      </span>
                                      <span> {doctor?.user?.lastname}</span>{" "}
                                    </>
                                  )}{" "}
                                </>
                              </h4>
                              <h5>
                                {doctor?.appointments?.length > 1
                                  ? doctor?.appointments?.length +
                                    " Appointments"
                                  : doctor?.appointments?.length +
                                    " Appointment"}
                              </h5>
                            </>
                          ) : (
                            <h4>{doctor?.pet_name}</h4>
                          )}
                        </div>
                      </div>
                    </th>
                    {dates?.map((date, i2) => (
                      <Fragment key={i2}>
                        {doctor?.appointments &&
                        doctor?.appointments?.filter(
                          (data) => data.appointment_date === date?.full_date
                        ).length > 0 ? (
                          <td className="no-hover">
                            <div className="data-container">
                              {doctor.appointments
                                ?.filter(
                                  (data) =>
                                    data.appointment_date === date?.full_date
                                )
                                ?.sort((a, b) => {
                                  if (
                                    a?.appointment_timings[0]
                                      ?.appointment_from[1] <
                                    b?.appointment_timings[0]
                                      ?.appointment_from[1]
                                  ) {
                                    return -1;
                                  }
                                  if (
                                    a?.appointment_timings[0]
                                      ?.appointment_from[1] >
                                    b?.appointment_timings[0]
                                      ?.appointment_from[1]
                                  ) {
                                    return 1;
                                  }

                                  return (
                                    moment(
                                      a?.appointment_timings[0]?.slot_time,
                                      "hh:mm a"
                                    ).valueOf() -
                                    moment(
                                      b?.appointment_timings[0]?.slot_time,
                                      "hh:mm a"
                                    ).valueOf()
                                  );
                                }).slice(0, appointmentsCounts?.[doctor?._id]?.[i2] || 2)
                                .map((appointment, apptIndex) =>

                                  {
                                    // console.log("appointment: ", apptIndex[apptIndex - 1]?.appointment_timings[0]?.appointment_time, appointment?.appointment_timings[0]?.appointment_time );
                                    return (
                                      <Fragment key={apptIndex}>
                                      <div>
                                        {/* {apptIndex[apptIndex - 1]?.appointment_timings[0]?.appointment_time === appointment?.appointment_timings[0]?.appointment_time   ? 
                                      "hello" : "not match" } */}
                                      </div>

                                        {appointment.appointment_date ===
                                          date?.full_date && (
                                          <SlotItemName
                                            appointmentData={appointment}
                                          />
                                        )}
                                      </Fragment>
                                    );
                                  }
                                )}
                                  {doctor?.appointments?.length  &&
                              doctor?.appointments?.filter(
                                (data) =>
                                  data.appointment_date === date?.full_date
                              )?.length > 2 && (
                                <p
                                  className="text-center cursor-pointer"
                                  onClick={() => {
                                    toggleAppointments(doctor?._id, i2);
                                    setShowMore((prevMap) => {
                                      const updatedShowMore =  ! (prevMap?.[doctor?._id]?.[i2] ? true : false);
                                      return { ...prevMap, [doctor?._id]:  {...prevMap?.[doctor?._id],  [i2]: updatedShowMore } };
                                    });
                                  }}
                                >
                                  {/* {`${
                                    showMore[i2] || showMore[i2] === undefined
                                      ? `+ ${
                                          appointments.filter(
                                            (data) =>
                                              data.appointment_date ===
                                              day?.full_date
                                          ).length - 2
                                        }  More`
                                      : "View Less"
                                  }`} */}


                                  {/* Updated here */}

                                  {showMore?.[doctor?._id]?.[i2] ? "View Less" : `+ ${doctor?.appointments.filter((data) => data.appointment_date === date?.full_date).length - 2} More`}

                                </p>
                              )}
                            </div>
                          </td>
                        ) : (
                          <td>
                            <div className="data-container blank-slot add-slot-btn-wrapper d-flex align-items-center justify-content-center position-relative">
                              <AddSlotButton />
                            </div>
                          </td>
                        )}
                      </Fragment>
                    ))}
                    {/* <td>
										<div className="data-container blank-slot add-slot-btn-wrapper d-flex align-items-center justify-content-center position-absolute">
											<AddSlotButton />
										</div>
									</td>
									<td className="no-hover">
										<div className="data-container">
											<SlotItemTime />
											<SlotItemTime className="clinic" />
											<SlotItemTime className="phone" />
											<SlotItemTime className="video-conference" />
											<SlotItemName />
											<ViewMoreSlot />
										</div>
									</td>
									<td className="no-hover">
										<div className="data-container blank-slot d-flex align-items-center justify-content-center position-absolute">
											<SlotUnavailable />
										</div>
									</td>
									<td className="no-hover">
										<div className="data-container blank-slot d-flex align-items-center justify-content-center position-absolute">
											<SlotUnavailable />
										</div>
									</td>
									<td className="no-hover">
										<div className="data-container blank-slot d-flex align-items-center justify-content-center position-absolute">
											<SlotUnavailable />
										</div>
									</td>
									<td className="no-hover">
										<div className="data-container blank-slot d-flex align-items-center justify-content-center position-absolute">
											<SlotUnavailable />
										</div>
									</td>
									<td className="no-hover">
										<div className="data-container blank-slot d-flex align-items-center justify-content-center position-absolute">
											<SlotUnavailable />
										</div>
									</td> */}
                  </tr>
                ))
              ) : (
                <tr className="calendar-no-data-rw">
                  <td colSpan="8">
                    <NodataFoundBlock message="No appointment found of these dates" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export const CalendarWeekViewBoarding = ({
  calendarViewData,
  kennelData,
  allKennels,
}) => {
  const [dates, setDates] = useState([]);
  const [hardcodeDate, setHardcodeDate] = useState("23-06-2023");
  const [loader, showLoader] = useState(false);

  useEffect(() => {
    showLoader(true);
    const date = getNextWeekSpanDays(
      calendarViewData.span_date,
      calendarViewData.span_month,
      calendarViewData.last_week_span
        ? [calendarViewData.year_value - 1, calendarViewData.year_value]
        : calendarViewData.year_value
    );
    // console.log(date);
    if (date.length) {
      date.forEach((val) => {
        const dateDay = moment(val.full_date, "DD-MM-YYYY").format("dddd");
        val.day = dateDay.toLowerCase();
      });
    }
    setDates(date);
    showLoader(false);
  }, [calendarViewData]);

  var naiveReverse = function (string) {
    let str =
      string?.split("-")[2] +
      "-" +
      string?.split("-")[1] +
      "-" +
      string?.split("-")[0];
    // return string.split('').reverse().join('');
    return str;
  };

  const daysOfWeek = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );

  const startDayIndex = daysOfWeek.indexOf(
    clinic_settings.calendar_start_day?.toLowerCase()
  );
  const orderedDaysOfWeek = [
    ...daysOfWeek.slice(startDayIndex),
    ...daysOfWeek.slice(0, startDayIndex),
  ];

  return (
    <>
      {loader && <LoaderUI overlay />}

      <div
        className={`calendar-week-view  ${
          !kennelData && kennelData.length <= 0
            ? "overflow-hidden"
            : "overflow-auto"
        }`}
      >
        <table className="table">
          <thead>
            <tr>
              <th className="user-filter-blc-wrapper">
                <div className="user-filter-blc d-flex align-items-center">
                  <h3 className="ttl">Kennels</h3>
                  <div className="right-col ms-auto d-inline-flex align-items-center">
                    <Button color="link" className="d-inline-flex">
                      <span className="material-symbols-outlined">
                        filter_list
                      </span>
                    </Button>
                    <Button color="link" className="d-inline-flex">
                      <span className="material-symbols-outlined">sync</span>
                    </Button>
                  </div>
                </div>
              </th>
              {orderedDaysOfWeek.map((day, i) => {
                const foundDate = dates?.find((dat) => dat.day === day);
                const formattedDay = foundDate
                  ? moment(foundDate.date, "DD-MM-YYYY").format("ddd")
                  : "";
                return (
                  <th className="day-cell-hd text-center cell-width" key={i}>
                    {formattedDay.toUpperCase()}
                    <span className="d-block">{foundDate?.date}</span>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {allKennels &&
              allKennels?.map((currentKennel, index) => {
                const kennelAppointments = kennelData?.filter(
                  (appointment) =>
                    appointment?.kennel?.kennel_name ===
                    currentKennel?.kennel_name
                );

                return (
                  <tr key={index}>
                    <th className="user-pic-cell-wrapper">
                      <div className="ml-3 user-pic-cell d-inline-flex align-items-center">
                        <div className="pic rounded-circle overflow-hidden">
                          <UserPlaceholder />
                        </div>
                        <div className="txt ">
                          <h4>{currentKennel?.kennel_name}</h4>
                        </div>
                      </div>
                    </th>
                    {dates.map((date, i2) => {
                      const kennelAppointmentsForDate = kennelAppointments
                        .filter((appointment) =>
                          moment(date?.full_date, "DD-MM-YYYY").isBetween(
                            appointment?.boarding_start_date,
                            appointment?.boarding_end_date,
                            "day",
                            "[]"
                          )
                        )
                        .sort((a, b) =>
                          moment(a?.boarding_start_date).isAfter(
                            b?.boarding_start_date
                          )
                        );
                      return (
                        <td key={i2}>
                          {kennelAppointmentsForDate.length > 0 ? (
                            <div className="data-container">
                              {kennelAppointmentsForDate.map(
                                (appointment, apptIndex) => (
                                  <SlotItemName
                                    key={apptIndex}
                                    appointmentData={appointment}
                                  />
                                )
                              )}
                            </div>
                          ) : (
                            <div className="data-container blank-slot add-slot-btn-wrapper d-flex align-items-center justify-content-center position-relative">
                              <AddSlotButton />
                            </div>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            {!kennelData ||
              (kennelData?.length === 0 && (
                <tr className="calendar-no-data-rw">
                  <td colSpan="8">
                    <NodataFoundBlock message="No appointment found of these dates" />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export const CalendarWeekViewOperating = ({
  calendarViewData,
  appointments,
  allRooms,
}) => {
  const [dates, setDates] = useState([]);
  const [hardcodeDate, setHardcodeDate] = useState("23-06-2023");
  const [loader, showLoader] = useState(false);

  useEffect(() => {
    showLoader(true);
    const date = getNextWeekSpanDays(
      calendarViewData.span_date,
      calendarViewData.span_month,
      calendarViewData.last_week_span
        ? [calendarViewData.year_value - 1, calendarViewData.year_value]
        : calendarViewData.year_value
    );
    // console.log(date);
    if (date.length) {
      date.forEach((val) => {
        const dateDay = moment(val.full_date, "DD-MM-YYYY").format("dddd");
        val.day = dateDay.toLowerCase();
      });
    }
    setDates(date);
    showLoader(false);
  }, [calendarViewData]);

  var naiveReverse = function (string) {
    let str =
      string?.split("-")[2] +
      "-" +
      string?.split("-")[1] +
      "-" +
      string?.split("-")[0];
    // return string.split('').reverse().join('');
    return str;
  };

  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );
  const daysOfWeek = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const startDayIndex = daysOfWeek.indexOf(
    clinic_settings.calendar_start_day?.toLowerCase()
  );

  const orderedDaysOfWeek = [
    ...daysOfWeek.slice(startDayIndex),
    ...daysOfWeek.slice(0, startDayIndex),
  ];

  return (
    <>
      {loader && <LoaderUI overlay />}

      <div
        className={`calendar-week-view  ${
          !appointments && appointments.length <= 0
            ? "overflow-hidden"
            : "overflow-auto"
        }`}
      >
        <table className="table">
          <thead>
            <tr>
              <th className="user-filter-blc-wrapper">
                <div className="user-filter-blc d-flex align-items-center">
                  <h3 className="ttl">Operating Rooms</h3>
                  <div className="right-col ms-auto d-inline-flex align-items-center">
                    <Button color="link" className="d-inline-flex">
                      <span className="material-symbols-outlined">
                        filter_list
                      </span>
                    </Button>
                    <Button color="link" className="d-inline-flex">
                      <span className="material-symbols-outlined">sync</span>
                    </Button>
                  </div>
                </div>
              </th>
              {orderedDaysOfWeek.map((day, i) => {
                const foundDate = dates?.find((dat) => dat.day === day);
                const formattedDay = foundDate
                  ? moment(foundDate.full_date, "DD-MM-YYYY").format("ddd")
                  : "";
                return (
                  <th className="day-cell-hd text-center cell-width" key={i}>
                    {formattedDay.toUpperCase()}
                    <span className="d-block">{foundDate?.date}</span>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {allRooms &&
              allRooms?.map((currenRoom, index) => {
                const kennelAppointments = currenRoom.appointments;
                return (
                  <tr key={index}>
                    <th className="user-pic-cell-wrapper">
                      <div className="ml-3 user-pic-cell d-inline-flex align-items-center">
                        <div className="pic rounded-circle overflow-hidden">
                          <UserPlaceholder />
                        </div>
                        <div className="txt ">
                          <h4>{currenRoom?.name}</h4>
                        </div>
                      </div>
                    </th>
                    {dates.map((date, i2) => {
                      const kennelAppointmentsForDate =
                        kennelAppointments.filter(
                          (appointment) =>
                            // naiveReverse(appointment?.appointment_date) ===
                            // date?.full_date
                            appointment?.appointment_date === date?.full_date
                        );
                      // console.log("kennelAppointmentsForDate", currenRoom.name, kennelAppointmentsForDate)
                      return (
                        <td key={i2}>
                          {kennelAppointmentsForDate.length > 0 ? (
                            <div className="data-container">
                              {kennelAppointmentsForDate.map(
                                (appointment, apptIndex) => {
                                  return (
                                    <SlotItemName
                                      key={apptIndex}
                                      appointmentData={appointment}
                                    />
                                  );
                                }
                              )}
                            </div>
                          ) : (
                            <div className="data-container blank-slot add-slot-btn-wrapper d-flex align-items-center justify-content-center position-relative">
                              <AddSlotButton />
                            </div>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};
