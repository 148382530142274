import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Popover,
  PopoverBody,
  Input,
  Form,
  FormGroup,
} from "reactstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { getAllergies } from "../../../../../context/actions/allergies";
import { CiCircleMinus } from "react-icons/ci";

import "./index.scss";
import { toast } from "react-toastify";

const ProfileAllergy = ({ setAllergies, allergies = [] }) => {
  const [popover, setPopover] = useState(false);
  const [allergyValue, setAllergyValue] = useState("");
  const [reactionsValue, setReactionsValue] = useState("");
  const [isAllergyAdd, setIsAllergyAdd] = useState(false);
  //const [allergies, setAllergies] = useState([])
  const [notesValue, setNotesValue] = useState("");
  const [id, setId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const dispatch = useDispatch();

  const popoverToggle = () => {
    setPopover(!popover);
  };

  const allAllergies = useSelector((state) => state?.allergyReducer?.allergies);

  useEffect(() => {
    dispatch(getAllergies((res) => {}));
  }, []);

  const handleAddAllergy = () => {
    let ab = allergies.some((el) => el.allergy === allergyValue);
    if (allergyValue === "" || reactionsValue === "") {
      toast.error(
        "Allergy Name or Reaction value are not allowed to be empty."
      );
    } else if (ab) {
      toast.error("Allergy Name already exists.");
    } else {
      setAllergies([
        ...allergies,
        {
          allergy: allergyValue,
          reaction: reactionsValue,
          notes: notesValue,
          id: id,
        },
      ]);
      setIsAllergyAdd(false);
      setAllergyValue("");
      setReactionsValue("");
      setNotesValue("");
      popoverToggle();
      setIsEdit(false);
    }
  };

  const handleEditAllergy = () => {
    let oldObj = allergies[id];
    oldObj = {
      ...oldObj,
      allergy: allergyValue,
      reaction: reactionsValue,
      notes: notesValue,
      id: id,
    };
    allergies[id] = oldObj;
    setAllergies(allergies);
    setIsAllergyAdd(false);
    setIsEdit(false);
    setAllergyValue("");
    setReactionsValue("");
    setNotesValue("");
    setId("");
  };

  const editAllergy = (elem) => {
    setIsEdit(true);
    setIsAllergyAdd(true);
    setAllergyValue(elem?.allergy);
    setReactionsValue(elem?.reaction);
    setNotesValue(elem?.notes);
    setId(elem?.id);
  };

  const handleDeleteAllergy = (elem) => {
    let newObj = allergies.filter((e) => e.id !== elem.id);
    setAllergies(newObj);
  };

  const [isMore, setIsMore] = useState(false);
  return (
    <>
      <div className="add-allergy row-block d-flex">
        <label>Allergies</label>
        <div className="allergy-list right-col flex-fill d-flex gap-2 flex-row relative">
          {allergies?.map((elem, index) => {
            let colorCode = allAllergies?.find(
              (o) => o?._id === elem?.reaction
            )?.severity;
            let reaction = allAllergies?.find(
              (o) => o?._id === elem?.reaction
            )?.tag;
            elem.id = index;
            return (
              <div className="d-flex gap-1">
                <span
                  key={index}
                  className={`text-center ps-1 pe-1 mb-0 ${
                    colorCode === "Low"
                      ? "bg-teal-500 text-white rounded-sm"
                      : colorCode === "Moderate"
                      ? "bg-yellow-500 text-white rounded-sm"
                      : colorCode === "High"
                      ? "bg-orange-500 text-white rounded-sm"
                      : ""
                  }`}
                  onClick={() => editAllergy(elem)}
                >
                  {elem?.allergy}: {reaction}
                </span>
                <CiCircleMinus
                  color="red"
                  size="22px"
                  onClick={() => handleDeleteAllergy(elem)}
                />
              </div>
            );
          })}
          {/* <span className="item-cell">Allergies to Medications</span>
						<span className="item-cell">[Allergic Information will goes here]</span> */}
          <Button
            id="add-allergy"
            onClick={() => {
              isAllergyAdd ? setIsAllergyAdd(false) : setIsAllergyAdd(true);
            }}
            color="link"
            className="add-btn mt-auto mb-auto d-inline-flex absolute top-0 right-0"
          >
            <span className="icomoon-plus-btn"></span>
          </Button>
          {/* <Popover
            placement="bottom-end"
            isOpen={popover}
            toggle={popoverToggle}
            target="add-allergy"
          >
            <PopoverBody className={"new-tag"}>
              <Input
                placeholder="Add Allergy"
                onChange={(event) => {
                  setAllergyValue(event?.target?.value);
                }}
              />
              <Input
                placeholder="Add Reaction"
                onChange={(event) => {
                  setReactionValue(event?.target?.value);
                }}
              />
              <div className="btn-cell d-flex">
                <Button
                  color="primary"
                  onClick={handleAddAllergy}
                  className="flex-fill"
                >
                  Add Allergy
                </Button>
              </div>
            </PopoverBody>
          </Popover> */}
          {isAllergyAdd && (
            <Offcanvas
              show
              backdrop="static"
              placement="end"
              className="select-group-list-right"
              style={{minWidth: '30%', maxWidth: '30%'}}
              onHide={() => {
                setIsAllergyAdd(false);
                setAllergyValue("");
                setReactionsValue("");
                setNotesValue("");
                setIsEdit(false);
              }}
            >
              <Offcanvas.Header closeButton className="p-2">
                <Offcanvas.Title>
                  {isEdit ? "Edit" : "Add"} New Allergy
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="px-2 pb-2 pt-0">
                <div className="mb-2 group-add-name">
                  <div> Allergy Information & Reaction</div>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Allergy Name"
                      onChange={(e) => setAllergyValue(e?.target?.value)}
                      value={allergyValue}
                    />
                  </div>
                  <div className="mt-2">
                    <Input
                      className="reactions-select-dropdown text-gray-500"
                      type="select"
                      name="select"
                      placeholder="Please Select Reaction"
                      value={reactionsValue}
                      onChange={(e) => {
                        setReactionsValue(e?.target?.value);
                      }}
                    >
                      <option value="">Please Select Reaction</option>
                      {allAllergies?.map((allergy, i) => (
                        <option key={i} value={allergy?._id}>
                          {allergy?.tag}
                        </option>
                      ))}
                    </Input>
                  </div>

                  <div className="mt-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Add Notes (Optional)"
                      onChange={(e) => setNotesValue(e?.target?.value)}
                      value={notesValue}
                    />
                  </div>

              

                  <div className="d-flex justify-content-end gap-3 mt-3">
                   

                    <button
                      className="save-button bg-teal-500 text-white rounded-lg pl-[35px] pr-[35px] pt-[10] pb-[10]"
                      onClick={isEdit ? handleEditAllergy : handleAddAllergy}
                    >
                      SAVE
                    </button>
                  </div>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileAllergy;
