import * as Yup from "yup";
/**
 * @description declaration form schema for client validations
 */

export const noteSchema = Yup.object().shape({
  sections: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string().required("Please select a template"),
        fields: Yup.array().of(
          Yup.object().shape({
            field: Yup.string(),
            value: Yup.string(),
          })
        ),
      })
    )
    .required("Sections are required")
    .min(1, "Please add a section"),
});

/**
 * @description params intilaization
 */

export const noteInit = {
  // extends: null,
  sections: [],
};
