import React from "react";
import { Button } from "reactstrap";
import "./index.scss";

const AvatarGroup = ({ avatars }) => {
 
  return (
    <div className="avatar flex pl-1 w-full h-full">
      {avatars?.slice(0,6)?.map((avatar, index) => (
        <img
          key={index}
          src={avatar ? avatar : "https://randomuser.me/api/portraits/thumb/men/5.jpg" }
          alt="user"
          className="rounded-full h-full -ml-1"
        />
      ))}
      <Button
        // onClick={() => setIsMore(!isMore)}
        color="link"
        className="add-btn"
      >
      {avatars?.length > 6 ? <>+{avatars?.length - 6} More</>: ""}
      </Button>
    </div>
  );
};
export default AvatarGroup;
