import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import AllergyAdd from "../AllergyAdd";
import BehaviorAdd from "../BehaviorAdd";
import { useDispatch, useSelector } from "react-redux";
import { getAllergies } from "../../../../../context/actions/allergies";
import { getBehaviours } from "../../../../../context/actions/behaviours";

const TagsAndColors = () => {
  const [severityValue, setSeverityValue] = useState("");
  const [allergyName, setAllergyName] = useState("");
  const [allergyId, setAllergyId] = useState("");
  const [severityBehaviourValue, setSeverityBehaviourValue] = useState("");
  const [behaviourValue, setBehaviourValue] = useState("");
  const [behaviourId, setBehaviourId] = useState("");
  const [allergyTagsData, setAllergyTagsData] = useState([]);
  const [behaviourTagsData, setBehaviourTagsData] = useState([]);

  const [isAllergyAdd, setIsAllergyAdd] = useState(false);
  const [isBehaviorAdd, setIsBehaviorAdd] = useState(false);
  const behaviourTags = [
    {
      id: 1,
      tagname: "Sociability And Aggressiveness",
      tagClassName: "tag-one",
    },
    {
      id: 2,
      tagname: "Chase-Pronenes",
      tagClassName: "tag-two",
    },
    {
      id: 3,
      tagname: "Tagname",
      tagClassName: "tag-three",
    },
    {
      id: 4,
      tagname: "Tagname",
      tagClassName: "tag-four",
    },
    {
      id: 5,
      tagname: "Tagname",
      tagClassName: "tag-five",
    },
  ];

  const handleUpdateText = (data) => {
    setAllergyTagsData([
      ...allergyTagsData,
      { ...severityValue, tageName: data },
    ]);
    // setBehaviourTagsData([...behaviourTagsData,{...severityValue,tageName:data}])
    // tempAllergytags.push({...allergytags,tageName:data})
    // setIsAllergyAdd(false)
  };

  const handleUpdateBehaviourText = (data) => {
    setBehaviourTagsData([
      ...behaviourTagsData,
      { ...severityBehaviourValue, tageName: data },
    ]);
  };

  const dispatch = useDispatch();

  const allAllergies = useSelector((state) => state?.allergyReducer?.allergies);
  const allBehaviours = useSelector(
    (state) => state?.behaviourReducer?.behaviours
  );
  console.log("allBehaviours", allBehaviours);

  useEffect(() => {
    dispatch(getAllergies((res) => {}));
  }, [isAllergyAdd]);

  useEffect(() => {
    dispatch(getBehaviours((res) => {}));
  }, [isBehaviorAdd]);

  const fetchBehaviourData = (behaviourData) => {
    console.log("behaviourData", behaviourData);
    setSeverityBehaviourValue(behaviourData?.severity);
    setBehaviourValue(behaviourData?.tag);
    setBehaviourId(behaviourData?._id);
    setIsBehaviorAdd(true);
  };

  const fetchAllergyData = (allergyData) => {
    console.log("allergyData", allergyData);
    setSeverityValue(allergyData?.severity);
    setAllergyName(allergyData?.tag);
    setAllergyId(allergyData?._id);
    setIsAllergyAdd(true);
  };

  return (
    <div className="tags-colors">
      <div className="mb-4">
        <div className="d-flex align-items-center">
          <div className="left-col">Behavior Tags</div>
          <div className="right-col ms-auto d-inline-flex align-items-center">
            <Button
              color="link"
              className="add-btn"
              onClick={() => {
                setIsBehaviorAdd(true);
              }}
            >
              <i className="icomoon-plus-btn"></i>
            </Button>
          </div>
        </div>
        <div></div>
        {isBehaviorAdd && (
          <BehaviorAdd
            severityBehaviourValue={severityBehaviourValue}
            behaviourValue={behaviourValue}
            behaviourId={behaviourId}
            setIsBehaviorAdd={setIsBehaviorAdd}
            setSeverityBehaviourValue={setSeverityBehaviourValue}
            setBehaviourValue={setBehaviourValue}
            setBehaviourId={setBehaviourId}
            handleUpdateBehaviourText={handleUpdateBehaviourText}
          />
        )}
        <div className="d-flex gap-1 flex-wrap">
          {allBehaviours?.map((elem, index) => {
            const { severity, tag, _id } = elem;
            return (
              <span
                key={_id}
                className={
                  severity == "Low"
                    ? "bg-teal-500 text-center ps-1 pe-1 text-white rounded-sm mr-1"
                    : severity == "Moderate"
                    ? "bg-yellow-500 text-center ps-1 pe-1 text-white rounded-sm mr-1"
                    : severity == "High"
                    ? "bg-orange-500 text-center ps-1 pe-1 text-white rounded-sm mr-1"
                    : "text-center ps-1 pe-1 mr-1"
                }
                onClick={() => fetchBehaviourData(elem)}
              >
                {tag}
              </span>
            );
          })}
        </div>
      </div>
      <div className="mb-2">
        <div className="d-flex align-items-center">
          <div className="left-col">Allergy Tags</div>
          <div className="right-col ms-auto d-inline-flex align-items-center">
            <Button
              color="link"
              className="add-btn"
              onClick={() => {
                setIsAllergyAdd(true);
              }}
            >
              <i className="icomoon-plus-btn"></i>
            </Button>
          </div>
        </div>
        <div></div>
        {isAllergyAdd && (
          <AllergyAdd
            severityValue={severityValue}
            allergyName={allergyName}
            allergyId={allergyId}
            setIsAllergyAdd={setIsAllergyAdd}
            setSeverityValue={setSeverityValue}
            setAllergyName={setAllergyName}
            setAllergyId={setAllergyId}
            handleUpdateText={handleUpdateText}
          />
        )}
        <div className="d-flex gap-1 flex-wrap">
          {allAllergies?.length > 0 &&
            allAllergies?.map((elem, index) => {
              const { severity, tag, _id } = elem;
              return (
                <span
                  key={_id}
                  className={
                    severity == "Low"
                      ? "bg-teal-500 text-center ps-1 pe-1 text-white rounded-sm mr-1"
                      : severity == "Moderate"
                      ? "bg-yellow-500 text-center ps-1 pe-1 text-white rounded-sm mr-1"
                      : severity == "High"
                      ? "bg-orange-500 text-center ps-1 pe-1 text-white rounded-sm mr-1"
                      : "text-center ps-1 pe-1 mr-1"
                  }
                  onClick={() => fetchAllergyData(elem)}
                >
                  {tag}
                </span>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default TagsAndColors;
