import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Popover,
  PopoverBody,
  Input,
  Form,
  FormGroup,
} from "reactstrap";

import "./index.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import { CiCircleMinus } from "react-icons/ci";
import { toast } from "react-toastify";

const ProfileTags = ({ setBehaviourTags, behaviour_tags = [] }) => {
  const [popover, setPopover] = useState(false);
  const [tagValue, setTagValue] = useState(false);
  const [severityValue, setSeverityValue] = useState(false);
  //const [behaviour_tags, setBehaviourTags] = useState([])

  const [isBehaviourAdd, setIsBehaviourAdd] = useState(false);
  const [reactionsValue, setReactionsValue] = useState("");
  const [notesValue, setNotesValue] = useState("");

  const dispatch = useDispatch();

  const popoverToggle = () => {
    setPopover(!popover);
  };

  const allBehaviours = useSelector(
    (state) => state?.behaviourReducer?.behaviours
  );

  const handleAddBehavior = () => {
    let ab = behaviour_tags.some((el) => el.severity === reactionsValue);
    if (reactionsValue === "") {
      toast.error("Behaviour is not allowed to be empty.");
    } else if (ab) {
      toast.error("Behaviour already exists.");
    } else {
      setBehaviourTags([
        ...behaviour_tags,
        { tag: "", notes: notesValue, severity: reactionsValue },
      ]);
      popoverToggle();
      setIsBehaviourAdd(false);
      setReactionsValue("");
      setNotesValue("");
    }
  };

  let severityToColor = {
    aggressive: "orange",
    assertive: "yellow",
    neutral: "cyan",
    passive: "green",
  };

  const handleDeleteBehaviour = (item) => {
    let newObj = behaviour_tags.filter((e) => e.id !== item?.id);
    setBehaviourTags(newObj);
  };

  let colors = ["orange", "yellow", "cyan", "green"];
  return (
    <>
      <div className="add-tags row-block d-flex">
        <label>Behaviour Tags</label>
        <div className="tag-list right-col d-flex flex-fill gap-2 relative">
          {behaviour_tags?.map((item, index) => {
            let colorCode = allBehaviours?.find(
              (o) => o?._id === item.severity
            )?.severity;
            let tagName = allBehaviours?.find(
              (o) => o?._id === item.severity
            )?.tag;
            item.id = index;
            return (
              <div className="d-flex gap-1">
                <span
                  key={index}
                  className={`text-center ps-1 pe-1 mb-0 ${
                    colorCode === "Low"
                      ? "bg-teal-500 text-white rounded-sm"
                      : colorCode === "Moderate"
                      ? "bg-yellow-500 text-white rounded-sm"
                      : colorCode === "High"
                      ? "bg-orange-500  text-white rounded-sm"
                      : ""
                  }`}
                >
                  {tagName}
                </span>
                <CiCircleMinus
                  color="red"
                  size="22px"
                  onClick={() => handleDeleteBehaviour(item)}
                />
              </div>
            );
          })}
          {/* <span className="d-inline-flex yellow">[tagname]</span>
						<span className="d-inline-flex cyan">Chase-proneness</span> */}
          <Button
            id="behaviour-tag"
            onClick={() =>
              isBehaviourAdd
                ? setIsBehaviourAdd(false)
                : setIsBehaviourAdd(true)
            }
            color={"link"}
            className="add-btn mt-auto mb-auto  d-inline-flex absolute right-0 top-0"
          >
            <span className="icomoon-plus-btn"></span>
          </Button>{" "}
          {/* <Popover
            placement="bottom-end"
            isOpen={popover}
            toggle={popoverToggle}
            target="behaviour-tag"
          >
            <PopoverBody className={"new-tag"}>
              <Input
                placeholder="Add Behaviour Tag"
                onChange={(event) => {
                  setTagValue(event?.target?.value);
                }}
              />
              <Input
                type="select"
                placeholder="Select Severity"
                onChange={(event) => {
                  setSeverityValue(event?.target?.value);
                }}
              >
                <option>Select Severity</option>
                <option>aggressive</option>
                <option>assertive</option>
                <option>neutral</option>
                <option>passive</option>
              </Input>
              <div className="btn-cell d-flex">
                <Button
                  color="primary"
                  onClick={handleAddTag}
                  className="flex-fill"
                >
                  Add Tag
                </Button>
              </div>
            </PopoverBody>
          </Popover> */}
          {isBehaviourAdd && (
            <Offcanvas
              show
              backdrop="static"
              placement="end"
              className="select-group-list-right-behaviour"
              style={{minWidth: '30%', maxWidth:"30%"}}
              onHide={() => {
                setIsBehaviourAdd(false);
                setReactionsValue("");
                setNotesValue("");
              }}
            >
              <Offcanvas.Header closeButton className="p-2">
                <Offcanvas.Title>Add New Behaviour</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="px-2 pb-2 pt-0">
                <div className="mb-2 group-add-name">
                  <div> Behaviour Information & Reaction</div>
                  {/* <div className="mt-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Behaviour Name"
                      onChange={(e) => setBehaviorName(e?.target?.value)}
                    />
                  </div> */}
                  <div className="mt-2">
                    <Input
                      className="reactions-select-dropdown"
                      type="select"
                      name="select"
                      placeholder="Please Select Behaviour"
                      value={reactionsValue}
                      onChange={(e) => {
                        setReactionsValue(e?.target?.value);
                      }}
                    >
                      <option value="">Please Select Behaviour</option>
                      {allBehaviours?.map((behaviour, i) => (
                        <option key={i} value={behaviour?._id}>
                          {behaviour?.tag}
                        </option>
                      ))}
                    </Input>
                  </div>

                  <div className="mt-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Add Notes (Optional)"
                      onChange={(e) => setNotesValue(e?.target?.value)}
                      value={notesValue}
                    />
                  </div>

                 
                  <div className="d-flex justify-content-end gap-3 mt-3">
                    

                    <button
                      className="save-button bg-teal-500 text-white rounded-lg pl-[35px] pr-[35px] pt-[10] pb-[10]"
                      onClick={handleAddBehavior}
                    >
                      SAVE
                    </button>
                  </div>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileTags;
