import "react-toastify/dist/ReactToastify.css";
import ApiClient from "api-client";
import { PORT, apiUrl } from "environment";
import { toast } from "react-toastify";

toast.configure();
/**
 * All Api endpoints to be call
 * Post   -- /pet/get-list
 */

/**
 *
 * @param {Function} callback
 * @returns
 */

export const getPetOwnerDoctorList = (params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();
    
    if (user) {
      dispatch({ type: "PET_OWNER_DOCTOR_LIST_LOADING", payload: true });
      ApiClient.get(
        `${apiUrl}${PORT}/pet-owner-dashboard/get-doctor-details/`,params,
        token, dispatch, getState
      ).then((response) => {
        dispatch({ type: "PET_OWNER_DOCTOR_LIST_LOADING", payload: false });
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({type: "GET_PET_OWNER_DOCTOR_LIST",payload: response?.response_data,})
          return callback(response?.response_data);
        } else {
          // toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    } else {
      return callback(null);
    }
  };
};
