const initState = {
    loading: false,
    results: { 
        intake: [],
        output: []
    },
    stats: {
        intake: 0,
        output: 0,
        difference: 0
    }
}

export const intakeOutputReducer = (state = initState, action) => {
    switch (action.type) {
        case "IO_RESULTS_LOADING":
            return { ...state, loading: action.payload }

        case "IO_RESULTS":
            return { ...state, results: action.payload }
        case "IO_STATS": 
            return {...state, stats: action.payload }
        case "IO_STATS_LOADING":
            return { ...state, loading: action.payload }
        


        default:
            return state
    }
}
