import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Table } from "reactstrap";
import { updatePatientNotesTemplate } from "../../../../../context/actions/patientINotesTemplate";
import SwitchCell from "../../../../../shared/SwitchCell";
import NewTemplateModal from "./NewTemplateModal";
import NewTemplatePanel from "./NewTemplatePanel";
import { useSelector } from "react-redux";
import { RiDeleteBin5Line } from "react-icons/ri";

const PatientNotesTemplateBlock = ({ list }) => {
  const dispatch = useDispatch();
  const [newTemplate, setNewTemplate] = useState(false);
  const [selectedTemplateToEdit, setSelectedTemplateToEdit] = useState();

  const handleUpdateTemplateStatus = (item) => {
    let body = {
      _id: item?._id,
      active: !item?.active,
    };
    dispatch(updatePatientNotesTemplate(body, () => {}));
  };

  const { permissionsData } = useSelector(     (state) => state.clinicPermissionReducer   );
	const permissions = permissionsData;
  return (
    <>
      <div className="form-template-blc">
        <div className="head d-flex">
          <div className="left-col">
            <h3>Patient Notes Templates</h3>
          </div>
          {permissions?.add_patient_note_templates &&
            <div className="right-col ms-auto">
              <Button
                onClick={() => setNewTemplate(true)}
                color="link"
                className="d-inline-flex align-items-center justify-content-center add-btn p-0"
              >
                <span className="icomoon-plus-btn"></span>
              </Button>
            </div>
          }
        </div>
        <div className="table-listing-blc overflow-auto">
          <Table responsive className="checkbox-listing">
            <thead>
              <tr>
                <th style={{ minWidth: "150px" }}>Name</th>
                <th style={{width: '100%'}}>Description</th>
                <th>Active</th>
                {permissions?.edit_patient_note_templates &&
                  <th style={{textAlign: 'right'}}>Actions</th>
                }
              </tr>
            </thead>
            <tbody>
              {(list || []).map((item, index) => (
                <tr key={index}>
                  <td>{item?.title}</td>
                  <td>{item?.description || "--"}</td>
                  <td>
                    <SwitchCell
                      onClick={() => permissions?.edit_patient_note_templates && handleUpdateTemplateStatus(item)}
                      className={item?.active ? "" : "disable"}
                     
                      label="Active"
                    />
                  </td>
                  {permissions?.edit_patient_note_templates &&
                    <td style={{ textAlign: "right" }} className="action-cell">
                      <Button
                        onClick={() => {
                          setSelectedTemplateToEdit(item);
                          setNewTemplate(true);
                        }}
                        color="link"
                        className="edit-btn"
                      >
                        <span className="icomoon-pencil"></span>
                      </Button>
                      <Button
                           
                           color="link"
                           className="edit-btn"
                         >
                           <RiDeleteBin5Line />
                         </Button>
                    </td>
                  }
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      {/* {newTemplate && <NewTemplateModal openViewBlock={(value) => setNewTemplate(value)}/>} */}

      {/* notes modal backdrop start */}
      {newTemplate && (
        <>
          <div
            className={` fixed top-0 right-0 bottom-0 left-0 z-[9] bg-black/20 transition-all duration-300 ease-in `}
          ></div>
        </>
      )}
      {/* notes modal backdrop end */}
      {newTemplate && (
        <NewTemplatePanel
          openViewBlock={() => {
            setNewTemplate(!newTemplate);
            setSelectedTemplateToEdit(undefined);
          }}
          isActive={newTemplate}
          templateToEdit={selectedTemplateToEdit}
        />
      )}
    </>
  );
};

export default PatientNotesTemplateBlock;
