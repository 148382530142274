import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import Overview from "./Overview";
import LoaderUI from "../../../../shared/loader";
import NodataFoundBlock from "../../../../shared/NoDataFoundBlock";
import Notes from "./Notes";
import { useParams } from "react-router-dom";
import MedicalHistory from "./MedicalHistory";
import Documents from "./Documents";
import DiagnosticTesting from "./DiagnosticTesting";
import Visits from "./Visits";
import Orders from "./Orders";
import Mar from "./Mar";
import Messages from "./Messages";
import NotesInfo from "./Notes/NotesInfo/NotesInfo";
import Vitals from "./Vitals";
import MedicationPlans from "./MedicationPlanBlock";
import Invoices from "./invoices-module"
// import "./index.scss";

const TabScreens = () => {
  const params = useParams();
  const petId = params.petId;
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <div className="tab-wrapper tab-patient-detail d-flex flex-column flex-fill w-100">
        <Nav tabs className="flex-shrink-0 pet_details_tabs">
          <NavItem>
            <NavLink
              className={{ active: activeTab === "1" }}
              onClick={() => {
                toggle("1");
              }}
            >
              Medical History
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={{ active: activeTab === "2" }}
              onClick={() => {
                toggle("2");
              }}
            >
              Visits
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={{ active: activeTab === "3" }}
              onClick={() => {
                toggle("3");
              }}
            >
              {/* Diagnostic Testing */}
              Test Results
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={{ active: activeTab === "4" }}
              onClick={() => {
                toggle("4");
              }}
            >
              Vitals
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={{ active: activeTab === "5" }}
              onClick={() => {
                toggle("5");
              }}
            >
              Medication Plans
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={{ active: activeTab === "6" }}
              onClick={() => {
                toggle("6");
              }}
            >
              Messages
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={{ active: activeTab === "7" }}
              onClick={() => {
                toggle("7");
              }}
            >
              Billing
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={{ active: activeTab === "8" }}
              onClick={() => {
                toggle("8");
              }}
            >
              Documents
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent
          activeTab={activeTab}
          className="d-flex flex-column flex-fill  position-relative me-0 me-md-3"
        >
          <div style={{marginTop:"55px"}}>
            <TabPane tabId="1">{activeTab == "1" && <MedicalHistory />}</TabPane>

            <TabPane tabId="2">
              {activeTab == "2" && <Visits petId={petId} />}
            </TabPane>

            <TabPane tabId="3">
              {activeTab == "3" && <DiagnosticTesting petId={petId} />}
            </TabPane>

            <TabPane tabId="4">
              {activeTab == "4" && <Vitals petId={petId} />}
            </TabPane>
            <TabPane tabId="5">
              {activeTab == "5" && <MedicationPlans petId={petId} />}
            </TabPane>

            <TabPane tabId="6">
              {activeTab == "6" && <Messages petId={petId} />}
            </TabPane>

            <TabPane tabId="7">
              {activeTab == "7" && <Invoices petId={petId} />}
            </TabPane>

            <TabPane tabId="8">
              {activeTab == "8" && <Documents petId={petId} />}
            </TabPane>
          </div>
        </TabContent>
      </div>
    </>
  );
};

export default TabScreens;
