import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { setAppointmentDetailPanel, setAppointmentDetailData } from "../../../context/actions/appointmentDetailPanelAction";
import { getPetDetailsById } from "../../../context/actions/patient-module/patient/pet";
import UserPlaceholder from '../../../shared/UserPlaceholder';
import { useClinicSpecies } from "hooks/useClinicSpecies";
import { useClinicBreed } from "hooks/useClinicBreed";

const SlotItemTime = (
	{
		className = '',
		style = {},
		appointment,
		timeBand,
		timingslot
	}
) => {

	const clinicSpecies = useClinicSpecies()
	const clinicBreed = useClinicBreed()

	const dispatch = useDispatch()

	const appointmentDetailsState = useSelector(state => state.appointmentDetailPanelReducer.isAppointmentDetailPanel);


	const showAppointmentDetails = () => {
		dispatch(setAppointmentDetailData(appointment));
		dispatch(getPetDetailsById(appointment?.patient_id?._id), {}, () => { });
		dispatch(setAppointmentDetailPanel(!appointmentDetailsState));
	}




	return (
		<>
			<Button onClick={showAppointmentDetails} style={style} color="link" 
			className={`slot-item d-flex align-items-center ${className} ${!appointment?.notes && 'no-description'} ${timingslot?.duration === '0.25' && 'no-time'}`}>
				{
					// appointment.appointment_timings && Array.isArray(appointment.appointment_timings) ?
					// 	appointment.appointment_timings.map((ele) => {
					// 		if (ele.appointment_from[0] === timeBand.value && ele.appointment_from[1] === timeBand.name) {
					// 			return (
					// 				ele?.duration !== '0.25' && <div className="time-txt">
					// 					{ele.appointment_time}
					// 				</div>
					// 			)
					// 		}

					// 	})

					// 	: 
					timingslot?.duration !== '0.25' && <div className="time-txt" style={{textTransform: 'lowercase'}}>
						{timingslot.appointment_time}
					</div>
				}
				
				<div className="pics-cell ms-auto flex-shrink-0 d-inline-flex align-items-center">
					{appointment?.appointment_type !== 'appointment-block' &&
					<div className="pic overflow-hidden">
						{
							appointment?.patient_id?.pet_image ? <img className="img-fluid w-100 h-100" src={appointment?.patient_id?.pet_image} alt="User" /> : <UserPlaceholder />
						}
					</div>
					}
					{
					appointment?.appointment_type !== 'appointment-block' ? (
					<p>{appointment?.patient_id?.pet_name} /{" "} {clinicSpecies(appointment?.patient_id?.breed?.species_id?._id) ||  appointment?.patient_id?.breed?.species_id?.species_name} / {clinicBreed(appointment?.patient_id?.breed?._id) || appointment?.patient_id?.breed?.breed}</p>
					)
					:
					<p>{appointment?.description}</p>
					}
				</div>
				{
					// appointment.appointment_timings && Array.isArray(appointment.appointment_timings) ? (
					// 	appointment.appointment_timings.map((ele) => {
					// 		if (ele.appointment_from[0] === timeBand.value && ele.appointment_from[1] === timeBand.name) {
					// 			return (
					// 				(ele.duration === '1' || ele.duration === '0.75') && <div className="desc-txt text-clip clip-2">
					// 					{
					// 						appointment?.notes ? appointment.notes : '-'
					// 					}
					// 				</div>
					// 			)
					// 		}

					// 	})
					// ) :
					(timingslot.duration === '1' || timingslot.duration === '0.75') && <div className="desc-txt text-clip clip-2">
						{
							appointment?.notes ? appointment.notes : '-'
						}
					</div> 
				}
			</Button>
		</>
	);



	// -----------------------------------------------------------OLD CODE BELOW--------------------------------------------------------- //
	// -----------------------------------------------------------OLD CODE BELOW--------------------------------------------------------- //
	// -----------------------------------------------------------OLD CODE BELOW--------------------------------------------------------- //
	// -----------------------------------------------------------OLD CODE BELOW--------------------------------------------------------- //
	return (
		<>
			<Button onClick={showAppointmentDetails} style={style} color="link" className={`
			slot-item d-flex align-items-center 
			${className}
			${!appointment?.notes && 'no-description'}
			${appointment?.duration === '0.25' && 'no-time'}
			`}>
				{
					appointment?.duration !== '0.25' && <div className="time-txt">
						{appointment?.appointment_time}
					</div>
				}

				<div className="pics-cell ms-auto flex-shrink-0 d-inline-flex align-items-center">
					<div className="pic overflow-hidden">
						{
							appointment?.patient_id?.pet_image ? <img className="img-fluid w-100 h-100" src={appointment?.patient_id?.pet_image} alt="User" /> : <UserPlaceholder />
						}
					</div>
					{appointment?.patient_id?.breed?.breed} / {appointment?.patient_id?.breed?.species_id?.species_name}
				</div>
				{
					(appointment.duration === '1' || appointment.duration === '0.75') && <div className="desc-txt text-clip clip-2">
						{
							appointment?.notes ? appointment.notes : '-'
						}
					</div>
				}
			</Button>
		</>
	);
};



export default SlotItemTime;
