import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";
toast.configure();
/**
 * All Api endpoints to be call
 * post   -- /clinic-reports/get-all-inventory-reports/:clinic_id
 */

/**
 *
 * @param {Object} params
 * @param {Fuction} callback
 * @returns
 */

export const getInventoryReports = (data, callback) => {
    return (dispatch, getState) => {
        dispatch({ type: "ISLOADING", data: true });
        const {
            user: { token },
        } = getState();
        ApiClient.post(
            `${apiUrl}${PORT}/clinic-reports/get-all-inventory-reports`,
            data,
            token,
            dispatch, getState
        )
            // .then((res) => {
            //     return res;
            // })
            .then((response) => {
                if (!response) {
                    return callback();
                } else if (response) {
                    dispatch({
                        type: "GET_PRODUCT_DATA",
                        payload: response?.response_data,
                    });
                    return callback(response);
                } else {
                    return callback(false);
                }
            });
    };
};

export const getScheduleAppointmentList = (data, callback) => {
    return (dispatch, getState) => {
        dispatch({ type: "ISLOADING", data: true });
        const {
            user: { token },
        } = getState();
        ApiClient.post(
            `${apiUrl}${PORT}/clinic-reports/get-all-appointment-reports`,
            data,
            token,
            dispatch, getState
        )
            // .then((res) => {
            //     return res;
            // })
            .then((response) => {
                if (!response) {
                    return callback();
                } else if (response) {
                    dispatch({
                        type: "GET_PRODUCT_DATA",
                        payload: response?.response_data,
                    });
                    return callback(response);
                } else {
                    return callback(false);
                }
            });
    };
};

export const getScheduleAppointmentMonthwiseList = (data, callback) => {
    return (dispatch, getState) => {
        dispatch({ type: "ISLOADING", data: true });
        const {
            user: { token },
        } = getState();
        ApiClient.post(
            `${apiUrl}${PORT}/clinic-reports/get-all-report-appointments`,
            data,
            token,
            dispatch, getState
        )
            // .then((res) => {
            //     return res;
            // })
            .then((response) => {
                if (!response) {
                    return callback();
                } else if (response) {
                    dispatch({
                        type: "GET_PRODUCT_DATA",
                        payload: response?.response_data,
                    });
                    return callback(response);
                } else {
                    return callback(false);
                }
            });
    };
};

export const getPatientRecordsList = (data, callback) => {
    return (dispatch, getState) => {
        dispatch({ type: "ISLOADING", data: true });
        const {
            user: { token },
        } = getState();
        ApiClient.post(
            `${apiUrl}${PORT}/clinic-reports/get-all-patient-reports`,
            data,
            token,
            dispatch, getState
        )
            // .then((res) => {
            //     return res;
            // })
            .then((response) => {
                if (!response) {
                    return callback();
                } else if (response) {
                    dispatch({
                        type: "GET_PRODUCT_DATA",
                        payload: response?.response_data,
                    });
                    return callback(response);
                } else {
                    return callback(false);
                }
            });
    };
};

export const getSinglePatientRecords = (data, callback) => {
    return (dispatch, getState) => {
        dispatch({ type: "ISLOADING", data: true });
        const {
            user: { token },
        } = getState();
        ApiClient.post(
            `${apiUrl}${PORT}/clinic-reports/get-single-patient-reports`,
            data,
            token,
            dispatch, getState
        )
            // .then((res) => {
            //     return res;
            // })
            .then((response) => {
                if (!response) {
                    return callback();
                } else if (response) {
                    dispatch({
                        type: "GET_PRODUCT_DATA",
                        payload: response?.response_data,
                    });
                    return callback(response);
                } else {
                    return callback(false);
                }
            });
    };
};

export const getBoardingsRecords = (data, callback) => {
    return (dispatch, getState) => {
        dispatch({ type: "ISLOADING", data: true });
        const {
            user: { token },
        } = getState();
        ApiClient.post(
            `${apiUrl}${PORT}/clinic-reports/get-boarding-reports`,
            data,
            token,
            dispatch, getState
        )
            // .then((res) => {
            //     return res;
            // })
            .then((response) => {
                if (!response) {
                    return callback();
                } else if (response) {
                    dispatch({
                        type: "GET_PRODUCT_DATA",
                        payload: response?.response_data,
                    });
                    return callback(response);
                } else {
                    return callback(false);
                }
            });
    };
};

export const getOperatinRecords = (data, callback) => {
    return (dispatch, getState) => {
        dispatch({ type: "ISLOADING", data: true });
        const {
            user: { token },
        } = getState();
        ApiClient.post(
            `${apiUrl}${PORT}/clinic-reports/get-all-operating-room-reports`,
            data,
            token,
            dispatch, getState
        )
            // .then((res) => {
            //     return res;
            // })
            .then((response) => {
                if (!response) {
                    return callback();
                } else if (response) {
                    dispatch({
                        type: "GET_PRODUCT_DATA",
                        payload: response?.response_data,
                    });
                    return callback(response);
                } else {
                    return callback(false);
                }
            });
    };
};
export const getReferralManagementRecords = (data, callback) => {
    return (dispatch, getState) => {
        dispatch({ type: "ISLOADING", data: true });
        const {
            user: { token },
        } = getState();
        ApiClient.post(
            `${apiUrl}${PORT}/clinic-reports/get-referral-reports`,
            data,
            token,
            dispatch, getState
        )
            // .then((res) => {
            //     return res;
            // })
            .then((response) => {
                if (!response) {
                    return callback();
                } else if (response) {
                    dispatch({
                        type: "GET_PRODUCT_DATA",
                        payload: response?.response_data,
                    });
                    return callback(response);
                } else {
                    return callback(false);
                }
            });
    };
};

export const getInvoiceRecordRecords = (data, callback) => {
    return (dispatch, getState) => {
        dispatch({ type: "ISLOADING", data: true });
        const {
            user: { token },
        } = getState();
        ApiClient.post(
            `${apiUrl}${PORT}/clinic-reports/get-invoice-reports`,
            data,
            token,
            dispatch, getState
        )
            // .then((res) => {
            //     return res;
            // })
            .then((response) => {
                if (!response) {
                    return callback();
                } else if (response) {
                    dispatch({
                        type: "GET_PRODUCT_DATA",
                        payload: response?.response_data,
                    });
                    return callback(response);
                } else {
                    return callback(false);
                }
            });
    };
};
export const getstaffManagement = (data, callback) => {
    return (dispatch, getState) => {
        dispatch({ type: "ISLOADING", data: true });
        const {
            user: { token },
        } = getState();
        ApiClient.post(
            `${apiUrl}${PORT}/clinic-reports/get-satff-management-reports`,
            data,
            token,
            dispatch, getState
        ).then((response) => {
            if (!response) {
                return callback();
            } else if (response) {
                dispatch({
                    type: "GET_PRODUCT_DATA",
                    payload: response?.response_data,
                });
                return callback(response);
            } else {
                return callback(false);
            }
        });
    };
};
