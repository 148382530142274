import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
// import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  getStaffAvailability,
  getStaffClinicList,
} from "context/actions/staffManagement";
import AddSchedule from "./AddSchedule";
import "./AvailabilityOrUnavailability.scss";
const headBar = [
  {
    title: "Su",
    blank: 0,
  },
  {
    title: "Mo",
    blank: 1,
  },
  {
    title: "Tu",
    blank: 2,
  },
  {
    title: "We",
    blank: 3,
  },
  {
    title: "Th",
    blank: 4,
  },
  {
    title: "Fr",
    blank: 5,
  },
  {
    title: "Sa",
    blank: 6,
  },
];
const AvailabilityOrUnavailability = ({ staffMemberId, doctorId, status }) => {
  const dispatch = useDispatch();
  // const { staffManagementId } = useParams();
  const [wSize, setWSize] = useState(window.innerWidth);
  const [date, setDate] = useState(moment());
  const [isAdd, setIsAdd] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [type, setType] = useState("all");
  const [clinic, setClinic] = useState("all");
  const [clinicList, setClinicList] = useState([]);
  const { holidays } = useSelector((state) => state.holidayReducer);

  const handleChangeYear = (type) => {
    let sYear = +date.format("YYYY");
    if (type === "next") {
      sYear = sYear + 1;
    } else {
      sYear = sYear - 1;
    }
    setDate(moment(`01-01-${sYear}`, "DD-MM-YYYY"));
  };

  const handleResize = () => {
    setWSize(window.innerWidth);
  };

  const getDates = (startDate, endDate) => {
    var dates = [];

    var currDate = moment(startDate, "DD-MM-YYYY").startOf("day");
    var lastDate = moment(endDate, "DD-MM-YYYY").startOf("day");

    while (currDate.isSameOrBefore(lastDate, "day")) {
      dates.push(currDate.clone());
      currDate.add(1, "days");
    }

    return dates;
  };

  const handleResponse = (data) => {
    let dateList = [];
    data.forEach((o) => {
      let clinic_id = o?.clinicDetails?.[0]?._id || "";
      let clinic_name = o?.clinicDetails?.[0]?.clinic_name || "";
      let allDates = getDates(o?.start_date, o?.end_date);
      allDates.forEach((date) => {
        let isWeekOff = false;
        if (
          o.isWeekendOff &&
          ["Saturday", "Sunday"].includes(date.format("dddd"))
        ) {
          isWeekOff = true;
        }
        let fDate = date.format("DD-MM-YYYY");
        let fIndex = dateList?.findIndex((d) => d.date === fDate);
        if (fIndex === -1) {
          let aList = [];
          let uaList = [];
          if (o?.isAvailable) {
            aList.push({
              start_time: o.start_time,
              end_time: o.end_time,
              clinic_id: clinic_id,
              clinic_name: clinic_name,
            });
          } else {
            uaList.push({
              start_time: o.start_time,
              end_time: o.end_time,
              isunavailable: true,
              clinic_id: clinic_id,
              clinic_name: clinic_name,
            });
          }
          dateList.push({
            date: fDate,
            availableList: aList,
            uavailableList: uaList,
            isWeekOff: isWeekOff,
            clinic_id: clinic_id,
          });
        } else {
          let existData = dateList[fIndex];
          let aList = existData.availableList;
          let uaList = existData.uavailableList;
          if (o?.isAvailable) {
            aList.push({
              start_time: o.start_time,
              end_time: o.end_time,
              clinic_id: clinic_id,
              clinic_name: clinic_name,
            });
          } else {
            uaList.push({
              start_time: o.start_time,
              end_time: o.end_time,
              isunavailable: true,
              clinic_id: clinic_id,
              clinic_name: clinic_name,
            });
          }
          dateList[fIndex] = {
            date: fDate,
            availableList: aList,
            uavailableList: uaList,
            isWeekOff: existData.isWeekOff,
            clinic_id: clinic_id,
          };
        }
      });
    });

    setDataList(dateList);
  };

  const getPageData = async () => {
    const clinicRes = await dispatch(
      getStaffClinicList({ staff_id: staffMemberId })
    );
    setClinicList(Array.isArray(clinicRes) ? clinicRes : []);
    const response = await dispatch(
      getStaffAvailability({ staff_id: staffMemberId })
    );
    if (Array.isArray(response)) {
      let newList = response?.filter((o) => o.status === 1);
      handleResponse(newList);
    } else {
      setDataList([]);
    }
  };

  useEffect(() => {
    getPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffMemberId]);

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });
  const selectedYear = date.format("YYYY");
  const isSmall = wSize < 1440;

  return (
    <div id="availability-or-unavailability-container">
      <div className="d-flex gap-2">
        <div className="flex-grow-1">
          <div className="fb-center mb-3">
            <div className="fa-center gap-2">
              <div className="f-select">
                <select
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                >
                  <option value="all">All Types</option>
                  <option value="available">Available</option>
                  <option value="unavailable">Unavailable</option>
                </select>
              </div>
              <div className="f-select">
                <select
                  value={clinic}
                  onChange={(e) => {
                    setClinic(e.target.value);
                  }}
                >
                  <option value="all">All locations</option>
                  {clinicList?.map((elm, index) => {
                    return (
                      <option
                        key={index}
                        value={elm?._id}
                        className="capitalize"
                      >
                        {elm?.clinic_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {isSmall && (
                <div
                  className="text-14-400 color-ffff bg-3EFD cursor-pointer br-4 ps-2 pe-2 pt-1 pb-1"
                  onClick={() => {
                    setIsAdd(true);
                  }}
                >
                  Manage Schedule
                </div>
              )}
            </div>
            <div className="year-block fa-center gap-1">
              <div
                className="bg-f8f8 h-32 left-nav-btn"
                onClick={() => {
                  handleChangeYear("prev");
                }}
              >
                <FaChevronLeft color="#282E65" size={7} />
              </div>
              <div className="bg-f8f8 h-32 color-2E65 f-center center-btn text-14-400">
                {selectedYear}
              </div>
              <div
                className="bg-f8f8 h-32 right-nav-btn"
                onClick={() => {
                  handleChangeYear("next");
                }}
              >
                <FaChevronRight color="#282E65" size={7} />
              </div>
            </div>
          </div>
          <div className="calendar-month-block">
            {Array.from(Array(12).keys()).map((_, mIndex) => {
              const renderMonth =
                mIndex + 1 < 10 ? `0${mIndex + 1}` : mIndex + 1;
              const monthDate = moment(
                `01-${mIndex + 1}-${selectedYear}`,
                "DD-M-YYYY"
              );
              const startOfMonth = moment(monthDate).startOf("month");
              const endOfMonth = moment(monthDate).endOf("month");
              const monthStartWeek = startOfMonth.format("dd");
              const monthLastDay = +endOfMonth.format("DD");
              const blankBoxes = headBar.find(
                (o) => o.title === monthStartWeek
              )?.blank;

              return (
                <div key={mIndex} className="">
                  <div className="text-20-500 color-2E65">
                    {`${moment(
                      `01-${mIndex + 1}-${selectedYear}`,
                      "DD-M-YYYY"
                    ).format("MMMM")} ${selectedYear}`}
                  </div>
                  <div>
                    <div className="d-flex w-100">
                      {headBar.map((elm, index) => {
                        return (
                          <div key={index} className="week-title">
                            {elm.title}
                          </div>
                        );
                      })}
                    </div>
                    <div className="d-flex flex-wrap w-100 cmb-26">
                      {Array.from(Array(blankBoxes).keys()).map((_, index) => {
                        return (
                          <div key={index} className="month-day blank-cell" />
                        );
                      })}
                      {Array.from(Array(monthLastDay).keys()).map(
                        (_, index) => {
                          const renderDay = index + 1;
                          let lastNum = Math.ceil(
                            (monthLastDay + blankBoxes) / 7
                          );
                          let rowNum = Math.ceil((renderDay + blankBoxes) / 7);
                          const cellDate = `${
                            renderDay < 10 ? `0${renderDay}` : renderDay
                          }-${renderMonth}-${selectedYear}`;

                          let holidayList = [];
                          holidays &&
                            holidays?.forEach((o) => {
                              let holidayDate = moment(
                                o.date,
                                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                              ).format("DD-MM-YYYY");
                              if (o?.isRecurring) {
                                holidayDate = `${moment(
                                  holidayDate,
                                  "DD-MM-YYYY"
                                ).format("DD-MM")}-${moment(
                                  cellDate,
                                  "DD-MM-YYYY"
                                ).format("YYYY")}`;
                              }
                              if (holidayDate === cellDate) {
                                holidayList.push(o);
                              }
                            });
                          let findCellData = dataList?.find(
                            (d) => d.date === cellDate
                          );
                          let availableHour = 0;
                          let slotList = [];
                          if (findCellData) {
                            if (type === "all" || type === "available") {
                              slotList = [...findCellData?.availableList];
                            }
                            if (type === "all" || type === "unavailable") {
                              slotList = [
                                ...slotList,
                                ...findCellData?.uavailableList,
                              ];
                            }

                            findCellData?.availableList?.forEach((aTime) => {
                              let cHour = moment(
                                aTime?.end_time,
                                "hh:mm A"
                              ).diff(
                                moment(aTime?.start_time, "hh:mm A"),
                                "hours"
                              );
                              availableHour = availableHour + cHour;
                            });
                          }
                          slotList = slotList.slice().sort((a, b) => {
                            const startTimeA = moment(a.start_time, "hh:mm A");
                            const startTimeB = moment(b.start_time, "hh:mm A");
                            return startTimeA.diff(startTimeB);
                          });
                          slotList = slotList?.filter(
                            (o) => o.clinic_id === clinic || clinic === "all"
                          );
                          let isAnyAllDayHoliday = holidayList?.some(
                            (o) =>
                              o?.duration === "all-day" && o?.status === "close"
                          );
                          return (
                            <div
                              key={index}
                              className={`month-day with-day ${
                                rowNum === 1
                                  ? "f-row-cell"
                                  : lastNum === rowNum
                                  ? "l-row-cell"
                                  : ""
                              }`}
                            >
                              <div className="fb-center cmb-8">
                                <span className="text-11-500 color-2E65">
                                  {renderDay}
                                </span>
                                {!isAnyAllDayHoliday &&
                                  availableHour > 0 &&
                                  !findCellData?.isWeekOff &&
                                  (type === "all" || type === "available") &&
                                  (clinic === "all" ||
                                    clinic === findCellData?.clinic_id) && (
                                    <span className="text-11-500 color-2E65">
                                      {availableHour} Hrs Available
                                    </span>
                                  )}
                              </div>
                              {!isAnyAllDayHoliday && (
                                <>
                                  {findCellData?.isWeekOff ? (
                                    <div
                                      className={`fb-center bg-5129 color-ffff br-4 ps-1 pe-1 mt-1 pt-1 pb-1 ${
                                        type === "all" || type === "unavailable"
                                          ? clinic === "all" ||
                                            clinic === findCellData?.clinic_id
                                            ? ""
                                            : "d-none"
                                          : "d-none"
                                      }`}
                                    >
                                      <span className="text-11-500">
                                        All day unavailable
                                      </span>
                                    </div>
                                  ) : (
                                    slotList?.map((elm, sIndex) => {
                                      return (
                                        <div
                                          className={`fb-center color-ffff br-4 ps-1 pe-1 cmb-2 pt-1 pb-1 ${
                                            elm.isunavailable
                                              ? "bg-5129"
                                              : "bg-aaaa"
                                          }`}
                                          key={sIndex}
                                        >
                                          <span className="text-11-500">
                                            {elm.start_time} - {elm.end_time}
                                          </span>
                                          {!elm.isunavailable && (
                                            <span className="f-center flex-nowrap text-10-500 b-ffff h-16 w-16 br-4 capitalize">
                                              {elm?.clinic_name.charAt(0)}
                                            </span>
                                          )}
                                        </div>
                                      );
                                    })
                                  )}
                                </>
                              )}
                              {holidayList?.map((elm, hIndex) => {
                                return (
                                  <div
                                    key={hIndex}
                                    style={{ backgroundColor: "#f5e3e3" }}
                                    className="br-4 p-1 w-100 mt-1"
                                  >
                                    <div className="text-[#DE2B2B] text-11-400">
                                      {elm?.duration === "custom"
                                        ? elm?.time
                                        : "All Day Holiday"}
                                    </div>
                                    <div className="text-11-400 color-2E65 mt-1">
                                      {elm?.memo || ""}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {isSmall ? (
          <Offcanvas
            show={isAdd}
            placement="end"
            onHide={() => {
              setIsAdd(false);
            }}
          >
            <Offcanvas.Body>
              <div className="right-form-block">
                <div className="top-block">
                  <div className="text-24-700 color-2E65">Manage Schedule</div>
                  <div className="text-12-400 color-8693 cmb-20">
                    Choose options to publish master schdeule
                  </div>
                  <AddSchedule
                    dataList={dataList}
                    clinicList={clinicList}
                    status={status}
                    doctorId={doctorId}
                    staffId={staffMemberId}
                    handleSuccess={() => {
                      getPageData();
                      setIsAdd(false);
                    }}
                  />
                </div>
                <div className="mt-3 ps-2">
                  {clinicList?.map((elm, index) => {
                    return (
                      <div className="d-flex gap-2 mb-2" key={index}>
                        <span className="fa-center flex-nowrap text-12-700 color-2E65 b-e3e3 p-1 h-20 w-20 br-4 capitalize">
                          {elm?.clinic_name.charAt(0)}
                        </span>
                        <span className="text-13-400 color-8693 capitalize">
                          {elm?.clinic_name}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        ) : (
          <div className="right-form-block">
            <div className="top-block">
              <div className="text-24-700 color-2E65">Manage Schedule</div>
              <div className="text-12-400 color-8693 cmb-20">
                Choose options to publish master schdeule
              </div>
              <AddSchedule
                dataList={dataList}
                clinicList={clinicList}
                status={status}
                doctorId={doctorId}
                staffId={staffMemberId}
                handleSuccess={() => {
                  getPageData();
                  setIsAdd(false);
                }}
              />
            </div>
            <div className="mt-3 ps-2">
              {clinicList?.map((elm, index) => {
                return (
                  <div className="d-flex gap-2 mb-2" key={index}>
                    <span className="fa-center flex-nowrap text-12-700 color-2E65 b-e3e3 p-1 h-20 w-20 br-4 capitalize">
                      {elm?.clinic_name.charAt(0)}
                    </span>
                    <span className="text-13-400 color-8693 capitalize">
                      {elm?.clinic_name}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AvailabilityOrUnavailability;
