import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Button } from "reactstrap";
import "./index.scss";
const BackButton = ({ className = '', label = "Back", onClick = () => { } }) => (
	<>
		<Button color="link"
			className={`shadow-none back-btn d-inline-flex align-items-center ${className}`}
			onClick={onClick}>
			<span className="icon d-inline-flex ">
				<FontAwesomeIcon icon={faChevronLeft} />
			</span>
			<label>{label}</label>
		</Button>
	</>
);

export default BackButton;