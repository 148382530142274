import React, { useEffect, useState } from "react";
import moment from "moment";
import DateBoxTimeInput from "../../../FormsInput/DateBoxTimeInput";
import { useDispatch } from "react-redux";
import { updateTask } from "../../../../context/actions/taskAndReminder";
import "./index.scss"


const TaskObject = ({task}) => {


    const dispatch = useDispatch();

    const [expand, setExpand] = useState(false);
    const [date, setDate] = useState('')

    const toggleExpand = () => {
        setExpand(!expand);
    }

    const [changeTime, setChangeTime] = useState(false);

    const toggleChangeTime = () => {
        setChangeTime(!changeTime);
    }
    


    const handleComplete = () => {

        dispatch(updateTask({_id: task?._id, completed: true}, () => {
        } ))

    }


    const handleChangeTime = () => {
        if(date && new Date(date)){

        dispatch(updateTask({_id: task?._id, date: new Date(date)}, () => {
            toggleChangeTime();
        } ))

        }

    }




    return (

        <div className="reminder-warpper mt-2">
            <div className="reminder-heading d-flex justify-content-between w-100 mb-2">
                <h4>{task?.name}</h4>
                <div className="d-flex">
                    <span onClick={toggleExpand}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            transform={`rotate(${expand ? '0' : '180'})`}
                            viewBox="0 0 16 16"
                            fill="none"
                        >
                            <path
                                d="M8 8.47917L6.20833 6.6875C6.05556 6.53472 5.87731 6.45833 5.6736 6.45833C5.46991 6.45833 5.29399 6.53472 5.14583 6.6875C4.99306 6.83565 4.91667 7.01158 4.91667 7.21527C4.91667 7.41898 4.99263 7.5968 5.14456 7.74873L7.48044 10.0846C7.63237 10.2365 7.80682 10.3125 8.00379 10.3125C8.20076 10.3125 8.37311 10.2361 8.52083 10.0833L10.8572 7.74694C11.008 7.5962 11.0833 7.42014 11.0833 7.21875C11.0833 7.01736 11.0069 6.83696 10.8542 6.67754C10.7014 6.5314 10.5243 6.45833 10.3229 6.45833C10.1215 6.45833 9.94529 6.53387 9.79421 6.68496L8 8.47917ZM8.00575 16C6.9047 16 5.86806 15.7917 4.89583 15.375C3.92361 14.9583 3.07292 14.3854 2.34375 13.6562C1.61458 12.9271 1.04167 12.0767 0.625 11.105C0.208333 10.1334 0 9.09519 0 7.99046C0 6.88571 0.208333 5.85069 0.625 4.88542C1.04167 3.92014 1.61458 3.07292 2.34375 2.34375C3.07292 1.61458 3.92332 1.04167 4.89496 0.625C5.86661 0.208333 6.90481 0 8.00954 0C9.11429 0 10.1493 0.208333 11.1146 0.625C12.0799 1.04167 12.9271 1.61458 13.6562 2.34375C14.3854 3.07292 14.9583 3.92169 15.375 4.89008C15.7917 5.85849 16 6.89321 16 7.99425C16 9.09531 15.7917 10.1319 15.375 11.1042C14.9583 12.0764 14.3854 12.9271 13.6562 13.6562C12.9271 14.3854 12.0783 14.9583 11.1099 15.375C10.1415 15.7917 9.10679 16 8.00575 16Z"
                                fill="#788C9F"
                            />
                        </svg>
                    </span>
                    { task?.completed ? 
                    <></> :
                    <span className="ms-2">
                        <svg
                            onClick={handleComplete}
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            viewBox="0 0 16 16"
                            fill="none"
                        >
                            <path
                                d="M6.9375 8.875L5.6875 7.64583C5.53472 7.49306 5.36111 7.41667 5.16667 7.41667C4.97222 7.41667 4.79861 7.49306 4.64583 7.64583C4.49306 7.79861 4.41667 7.9757 4.41667 8.17708C4.41667 8.37847 4.49306 8.55556 4.64583 8.70833L6.41667 10.4792C6.56944 10.6319 6.74306 10.7083 6.9375 10.7083C7.13194 10.7083 7.30556 10.6319 7.45833 10.4792L11.3542 6.58333C11.5069 6.43056 11.5833 6.25347 11.5833 6.05208C11.5833 5.85069 11.5069 5.67361 11.3542 5.52083C11.2014 5.36806 11.0278 5.29167 10.8333 5.29167C10.6389 5.29167 10.4653 5.36806 10.3125 5.52083L6.9375 8.875ZM8 16C6.90278 16 5.86806 15.7917 4.89583 15.375C3.92361 14.9583 3.07292 14.3854 2.34375 13.6562C1.61458 12.9271 1.04167 12.0764 0.625 11.1042C0.208333 10.1319 0 9.09722 0 8C0 6.88889 0.208333 5.85069 0.625 4.88542C1.04167 3.92014 1.61458 3.07292 2.34375 2.34375C3.07292 1.61458 3.92361 1.04167 4.89583 0.625C5.86806 0.208333 6.90278 0 8 0C9.11111 0 10.1493 0.208333 11.1146 0.625C12.0799 1.04167 12.9271 1.61458 13.6562 2.34375C14.3854 3.07292 14.9583 3.92014 15.375 4.88542C15.7917 5.85069 16 6.88889 16 8C16 9.09722 15.7917 10.1319 15.375 11.1042C14.9583 12.0764 14.3854 12.9271 13.6562 13.6562C12.9271 14.3854 12.0799 14.9583 11.1146 15.375C10.1493 15.7917 9.11111 16 8 16Z"
                                fill="#11CA9D"
                            />
                        </svg>

                    </span>}
                </div>
            </div>
            {expand &&
            <>
            {task?.assigned_to &&
            <p>
                Assigned To: {task?.assigned_to?.firstname} {task?.assigned_to?.lastname}
            </p>}
            <p>
                {task?.notes}
            </p>
            </>}


            {!changeTime ?
            <div className="d-flex justify-content-between">
                <p>{task?.user?.firstname} {task?.user?.lastname}</p>

            
                <>
                <p>
                    {moment(task?.date).format('DD MMM [at] hh:MM A')}
                </p>
                <span onClick={toggleChangeTime}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={14}
                        height={15}
                        viewBox="0 0 14 15"
                        fill="none"
                    >
                        <path
                            d="M7 14.5C6.02778 14.5 5.11713 14.3153 4.26806 13.9458C3.41898 13.5764 2.68009 13.0773 2.0514 12.4486C1.42269 11.8199 0.923611 11.081 0.554167 10.2319C0.184722 9.38287 0 8.47222 0 7.5C0 6.52778 0.184722 5.61713 0.554167 4.76806C0.923611 3.91898 1.42269 3.18009 2.0514 2.5514C2.68009 1.92269 3.41898 1.42361 4.26806 1.05417C5.11713 0.684722 6.02778 0.5 7 0.5C8.125 0.5 9.16319 0.743056 10.1146 1.22917C11.066 1.71528 11.8611 2.375 12.5 3.20833V1.25C12.5 1.0375 12.5715 0.859375 12.7144 0.715624C12.8573 0.571874 13.0344 0.5 13.2456 0.5C13.4569 0.5 13.6354 0.571874 13.7812 0.715624C13.9271 0.859375 14 1.0375 14 1.25V4.75C14 4.9625 13.9281 5.14063 13.7844 5.28438C13.6406 5.42813 13.4625 5.5 13.25 5.5H9.75C9.5375 5.5 9.35938 5.42855 9.21562 5.28565C9.07187 5.14273 9 4.96565 9 4.7544C9 4.54313 9.07187 4.36458 9.21562 4.21875C9.35938 4.07292 9.5375 4 9.75 4H11.2292C10.7305 3.37778 10.1162 2.88889 9.3864 2.53333C8.65658 2.17778 7.86111 2 7 2C5.48611 2 4.19097 2.53819 3.11458 3.61458C2.03819 4.69097 1.5 5.98611 1.5 7.5C1.5 9.01389 2.03819 10.309 3.11458 11.3854C4.19097 12.4618 5.48611 13 7 13C8.41667 13 9.64236 12.5312 10.6771 11.5938C11.7118 10.6562 12.3056 9.5 12.4583 8.125C12.4861 7.93056 12.5764 7.77778 12.7292 7.66667C12.8819 7.55556 13.056 7.5 13.2513 7.5C13.4588 7.5 13.6354 7.56597 13.7812 7.69792C13.9271 7.82986 13.9861 7.99306 13.9583 8.1875C13.7778 9.96528 13.0312 11.4618 11.7188 12.6771C10.4062 13.8924 8.83333 14.5 7 14.5ZM7.75 6.875L9.625 8.75C9.77778 8.90278 9.85069 9.07639 9.84375 9.27083C9.83681 9.46528 9.75694 9.63889 9.60417 9.79167C9.45139 9.94444 9.27431 10.0208 9.07292 10.0208C8.87153 10.0208 8.69444 9.94444 8.54167 9.79167L6.48517 7.73517C6.41172 7.66172 6.35417 7.57855 6.3125 7.48565C6.27083 7.39274 6.25 7.29674 6.25 7.19763V4.24325C6.25 4.03265 6.32292 3.85613 6.46875 3.71369C6.61458 3.57123 6.79167 3.5 7 3.5C7.20833 3.5 7.38542 3.57187 7.53125 3.71562C7.67708 3.85937 7.75 4.0375 7.75 4.25V6.875Z"
                            fill="#788C9F"
                        />
                    </svg>

                </span>
                </>
            </div> : 


                <>


                <div className="d-flex justify-content-between">
                <p>{task?.user?.firstname} {task?.user?.lastname}</p>
                </div>

                <div className="d-flex justify-content-between gap-[10px] mt-[5px]">
                                
                <input
                    type="datetime-local"
                    className="form-control inline-date-time"
                    placeholder="date and time"
                    value={date}
                    onChange={(e) => setDate(e?.target?.value)}
                

                />      
                <div style={{display: 'flex', justifyContent:'flex-end', alignItems: 'center', gap: '10px'}}>
                <svg onClick={toggleChangeTime} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="cancel">
                        <mask id="mask0_10911_18612" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
                        <rect id="Bounding box" y="0.5" width="24" height="24" fill="#D9D9D9"/>
                        </mask>
                        <g mask="url(#mask0_10911_18612)">
                        <path id="cancel_2" d="M7.87308 16.6269C8.01794 16.7718 8.19358 16.8442 8.39998 16.8442C8.60638 16.8442 8.78201 16.7718 8.92688 16.6269L12 13.5538L15.0981 16.6519C15.2365 16.7903 15.408 16.8554 15.6125 16.8471C15.817 16.8387 15.9884 16.7653 16.1269 16.6269C16.2718 16.482 16.3442 16.3064 16.3442 16.1C16.3442 15.8936 16.2718 15.7179 16.1269 15.5731L13.0538 12.5L16.1519 9.40188C16.2903 9.26342 16.3554 9.09196 16.3471 8.88748C16.3387 8.68299 16.2653 8.51153 16.1269 8.37308C15.982 8.22819 15.8064 8.15575 15.6 8.15575C15.3936 8.15575 15.2179 8.22819 15.0731 8.37308L12 11.4461L8.90188 8.34808C8.76343 8.20961 8.59196 8.14454 8.38748 8.15288C8.18299 8.16121 8.01153 8.23461 7.87308 8.37308C7.72819 8.51794 7.65575 8.69358 7.65575 8.89998C7.65575 9.10638 7.72819 9.28201 7.87308 9.42688L10.9462 12.5L7.84808 15.5981C7.70961 15.7365 7.64454 15.908 7.65288 16.1125C7.66121 16.317 7.73461 16.4884 7.87308 16.6269ZM12 22C10.6807 22 9.44325 21.7519 8.2875 21.2557C7.13174 20.7596 6.12629 20.0839 5.27115 19.2288C4.41604 18.3737 3.7404 17.3682 3.24425 16.2125C2.74809 15.0567 2.5 13.8192 2.5 12.5C2.5 11.1807 2.74808 9.94325 3.24425 8.7875C3.7404 7.63173 4.41603 6.62628 5.27115 5.77115C6.12628 4.91603 7.13173 4.2404 8.2875 3.74425C9.44325 3.24808 10.6807 3 12 3C13.3192 3 14.5567 3.24808 15.7125 3.74425C16.8682 4.2404 17.8737 4.91603 18.7288 5.77115C19.5839 6.62628 20.2596 7.63173 20.7557 8.7875C21.2519 9.94325 21.5 11.1807 21.5 12.5C21.5 13.8192 21.2519 15.0567 20.7557 16.2125C20.2596 17.3682 19.5839 18.3737 18.7288 19.2288C17.8737 20.0839 16.8682 20.7596 15.7125 21.2557C14.5567 21.7519 13.3192 22 12 22Z" fill="#DE2B2B"/>
                        </g>
                        </g>
                </svg>


                <svg onClick={handleChangeTime} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="check_circle">
                        <mask id="mask0_10911_18615" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
                        <rect id="Bounding box" y="0.5" width="24" height="24" fill="#D9D9D9"/>
                        </mask>
                        <g mask="url(#mask0_10911_18615)">
                        <path id="check_circle_2" d="M10.5808 14.6462L8.23265 12.2981C8.09689 12.1596 7.93025 12.0904 7.73275 12.0904C7.53527 12.0904 7.35827 12.1686 7.20175 12.3252C7.05827 12.4686 6.98653 12.6436 6.98653 12.85C6.98653 13.0564 7.05896 13.232 7.20383 13.3769L9.94805 16.1211C10.1186 16.2916 10.3295 16.3769 10.5808 16.3769C10.832 16.3769 11.0429 16.2916 11.2134 16.1211L16.8019 10.5327C16.9403 10.3969 17.0096 10.2303 17.0096 10.0328C17.0096 9.83527 16.9313 9.65827 16.7748 9.50175C16.6313 9.35827 16.4564 9.28653 16.25 9.28653C16.0436 9.28653 15.8679 9.35896 15.7231 9.50383L10.5808 14.6462ZM12.0017 22C10.6877 22 9.45269 21.7506 8.29655 21.252C7.1404 20.7533 6.13472 20.0765 5.2795 19.2217C4.42427 18.3669 3.74721 17.3616 3.24833 16.206C2.74944 15.0504 2.5 13.8156 2.5 12.5017C2.5 11.1877 2.74933 9.95268 3.248 8.79655C3.74667 7.6404 4.42342 6.63472 5.27825 5.7795C6.1331 4.92427 7.13834 4.24721 8.29398 3.74833C9.44959 3.24944 10.6844 3 11.9983 3C13.3122 3 14.5473 3.24933 15.7034 3.748C16.8596 4.24667 17.8652 4.92342 18.7205 5.77825C19.5757 6.6331 20.2527 7.63834 20.7516 8.79398C21.2505 9.94959 21.5 11.1844 21.5 12.4983C21.5 13.8122 21.2506 15.0473 20.752 16.2034C20.2533 17.3596 19.5765 18.3652 18.7217 19.2205C17.8669 20.0757 16.8616 20.7527 15.706 21.2516C14.5504 21.7505 13.3156 22 12.0017 22Z" fill="#3D3EFD"/>
                        </g>
                        </g>
                </svg>
                </div>
                </div>


                </>
            
            
            
            }
        </div>
    )
} 

export default TaskObject