import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";

toast.configure();
/**
 * All Api endpoints to be call
 * Get   -- /supplier/get-all
 */

/**
 *
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */

export const addNewSupplier = (data, callback) => {
  //
  return (dispatch, getState) => {
    dispatch({ type: "SUPPLIER_LOADING", data: data });
    const {
      user: { token },
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/supplier/create`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      dispatch({ type: "SUPPLIER_LOADING", data: data });
      if (!response) {
        //
        return callback();
      } else if (!response && response.errors) {
        //
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response) {
        //
        toast.dismiss();
        toast.success(response.message, toasterConfig);
        localStorage.setItem("supplier_id", response.response_data?._id);
        return callback(response);
      } else {
        //
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};
export const getAllSupplierListWithFilter = (params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
      clinicReducer: { clinic_details },
    } = getState();
    params.clinic_id = clinic_details?._id;
    params.user_id = user?._id;
    dispatch({ type: "SUPPLIER_LOADING", payload: true });
    ApiClient.get(`${apiUrl}${PORT}/supplier/get-all`, params, token, dispatch, getState).then(
      (response) => {
        dispatch({ type: "SUPPLIER_LOADING", payload: false });
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({
            type: "GET_SUPPPLIERS",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      }
    );
  };
};



export const getAllSupplierListByClinicId= (clinic_id, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
      clinicReducer: { clinic_details },
    } = getState();
    ApiClient.get(`${apiUrl}${PORT}/supplier/get-all${clinic_id}`, {}, token, dispatch, getState).then(
      (response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {

          return callback(response.data);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response.data);
        }
      }
    );
  };
};
