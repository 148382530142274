import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import UserPlaceholder from '../../../shared/UserPlaceholder';
import "./index.scss";
import { useSelector } from "react-redux";

const UserListMonth = ({ calendarView, doctorsList, doctorAppointments, setClinicAppointment }) => {
	const [doctorsData, setDoctorsData] = useState(doctorsList)
	const [selectedDoctor, setSelectedDoctor] = useState(doctorsList && doctorsList[0]);

	const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);

	const handleSelectDoctor = (doctor) => {
		setSelectedDoctor(doctor);
		doctorAppointments(doctor.appointments)
		const restData = doctorsList.filter(data => data._id !== doctor._id);
		setDoctorsData(restData)
	}

	useEffect(() => {
		doctorAppointments([]);
		/*if(doctorsList.length > 0){
			handleSelectDoctor(doctorsList[0])
		}*/
	}, [calendarView]);

	useEffect(() => {
		setDoctorsData(doctorsList);
		setSelectedDoctor(false);
		setClinicAppointment()
		//doctorAppointments(doctorsList && doctorsList[0]?.appointments)
	}, []);
	return (
		<>
			<div className="user-list-month-view d-flex">
				{
					selectedDoctor && <div onClick={() => {
						setDoctorsData(doctorsList);
						setSelectedDoctor(false);
						setClinicAppointment()}} className="user-cell active d-inline-flex align-items-center">
						<div className="pic rounded-circle">
							{
								selectedDoctor?.user?.user_image ? <img className="img-fluid" src={selectedDoctor?.user?.user_image} alt={`User`} /> : <UserPlaceholder />
							}
						</div>
						<div className="txt">
							<h3>{selectedDoctor?.user?.title}  {clinic_settings?.doctor_name_format === 0  ? <> <span className="mr-1">{selectedDoctor?.user?.lastname} </span>, <span> {selectedDoctor?.user?.firstname}</span> </> : <>  <span className="mr-1"> {selectedDoctor?.user?.firstname}  </span> <span >{selectedDoctor?.user?.lastname}</span> </>}   </h3>
							<h4>{selectedDoctor?.appointments?.length > 1 ? selectedDoctor?.appointments?.length + ' Appointments' : selectedDoctor?.appointments?.length + ' Appointment'} </h4>
						</div>
					</div>
				}
				<div className="user-list-blc d-flex flex-fill overflow-auto">
					{
						doctorsData && doctorsData.length > 0 && doctorsData.map((item, i) => (
							<Button color="link" key={i} className={`pic-cell d-inline-flex align-items-center justify-content-center p-0`} onClick={() => handleSelectDoctor(item)}>
								<span className="pic overflow-hidden rounded-circle">
									{
										item?.user?.user_image ? <img className="img-fluid" src={item?.user?.user_image} alt={`${item?.user?.title} ${item?.user?.firstname} ${item?.user?.lastname}`} /> : <UserPlaceholder />
									}
								</span>



							</Button>
						)
						)
					}
				</div>
			</div>
		</>
	);
};

export default UserListMonth;
