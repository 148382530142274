import React from "react";
import { useField } from "formik";
import { FormFeedback, FormGroup, Input } from "reactstrap";
// import { FormattedMessage } from 'react-intl';
const EmailInput = (props) => {
  const [field, meta] = useField(props.name);
  return (
    <FormGroup className={`input-wrapper ${props?.className}`}>
      {props.label && <><label htmlFor={props?.id} >
        <span className={props.required && 'required'}>{props.label}</span>
      </label></>}
      <div className={`input-cell position-relative ${props.icon === 'icon' && 'icon'} ${props.iconbutton === 'iconButton' && 'iconButton'}`}>
        {
          props.icon === 'icon' &&
          <span className="icon position-absolute h-100 d-inline-flex align-items-center justify-content-center">
            <i className="icomoon-email"></i>
          </span>
        }


        <Input
          {...field}
          {...props}
          //   className={`${props?.inputClass}`}
          autoComplete="off"
        />
      </div>


      {meta.touched && meta.error && (
        <FormFeedback className="d-block"> {meta?.error}
          {/* <FormattedMessage id={meta.error} values={{
            name: props?.placeholder || 'This'
          }} /> */}
        </FormFeedback>
      )}
    </FormGroup>
  );
};
export default EmailInput;