import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import logo from "assets/img/logo.png"


const FaviIcon = () => {
      const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);
      const {user_details} = useSelector(state => state?.user)
    const newFaviconUrl =  clinic_settings?.monogram_url && user_details?.role !== 'pet-owner' && !user_details?.pet_owner ? clinic_settings?.monogram_url : logo //" http://localhost:4200/photos/clinics/6014f9d4-042b-48a5-82cc-10afad4a72b0mp4.png"

  return (
   <>
      <Helmet>
        <link rel="icon" href={newFaviconUrl} />
        <title> VetRecor</title>
      </Helmet>
   </>
  );
};

export default FaviIcon;
