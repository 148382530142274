import React from "react";
import "./index.scss";
import PlanBlock from "./PlanBlock";

const ProfileSubscription = () => {
  let wellnessPlanObj = {
    Gold: {
      planType: "golden",
      planName: "Golden Care",
      planPriceStr: "Price $300 (One Time)",
      planPrice: 300,
      className: "golden-plan",
      startDate: "10/12/22",
    },
    Silver: {
      planType: "silver",
      planName: "Silver Care",
      planPriceStr: "Price $100 (One Time)",
      planPrice: 100,
      className: "super-plan",
    },
    Bronze: {
      planType: "primary",
      planName: "Primary Care",
      planPriceStr: "Price $200 (One Time)",
      planPrice: 200,
      className: "primary-plan",
    },
  };
  return (
    <div className="subscription gap-2 d-inline-flex align-items-center golden-plan">
      <PlanBlock
        className={wellnessPlanObj.Gold.className}
        planType={wellnessPlanObj.Gold.planType}
        startDate={wellnessPlanObj.Gold.startDate}
        planName={wellnessPlanObj.Gold.planName}
      />
    </div>
  );
};

export default ProfileSubscription;
