import "./index.scss";
import { getAndSearchProductsAndServices } from "../../../context/actions/inventory";
import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import * as Yup from "yup";
import { FieldArray, Form, Formik, getIn } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import {
  defaultDischargePatientFormData,
  defaultProblemConditionDiagnosis,
} from "../../../context/reducers/dischargePatientReducer";
import Select from "react-select";
import { getAllDoctors } from "../../../context/actions/appointment";

const Index = forwardRef((props, ref) => {
  const formikRef = useRef(null);
  const debug = false;

  const dispatch = useDispatch();

  const { dischargePatientFormData } = useSelector(
    (state) => state.dischargePatientReducer
  );

  // console.log("dischargePatientFormData----------", dischargePatientFormData);

  const { clinic_details } = useSelector((state) => state.clinicReducer);

  const [doctorsList, setDoctorsList] = useState([]);

  let resetFormFunc;
  let validateFormFun;

  const validationSchema = Yup.object().shape({
    problems: Yup.array().of(
      Yup.object().shape({
        pet_id: Yup.string().required("Pet Id is required."),
        diagnosed_by: Yup.string().required("Select a doctor."),
        start_date: Yup.date()
          .typeError("Please enter a valid date.")
          .required("Start date is required."),
        end_date: Yup.date().nullable().optional(),
        description: Yup.string().required("Description is required."),
        status: Yup.string().required("Status is required."),
      })
    ),
  });

  // Function to submit the Formik form
  const submitForm = () => {
    if (formikRef.current) {
      formikRef.current.handleSubmit();
    }
  };

  useImperativeHandle(ref, () => ({
    submitForm,
  }));

  let pushFunc;

  const handleInputChange = (value, field, index) => {
    let dischargePatientData = JSON.parse(
      JSON.stringify(dischargePatientFormData)
    );
    let findEle = dischargePatientData.added_diagnosis[index];

    if (!findEle) {
      dischargePatientData.added_diagnosis.push({ [field]: value });
    } else {
      findEle[field] = value;
    }

    dispatch({
      type: "DISCHARGE_PATIENT_FORM_DATA",
      payload: dischargePatientData,
    });
  };

  const removeItemFromRedux = (index) => {
    let dischargePatientData = JSON.parse(
      JSON.stringify(dischargePatientFormData)
    );
    let filteredOrders = dischargePatientData.added_diagnosis.filter(
      (ele, idx) => idx != index
    );
    dischargePatientData.added_diagnosis = filteredOrders;
    dispatch({
      type: "DISCHARGE_PATIENT_FORM_DATA",
      payload: dischargePatientData,
    });
  };

  useEffect(() => {
    validateFormFun().then((errors) => {
      if (Object.keys(errors).length === 0) {
        props.setFormSubmitted(true);
      } else {
        props.setFormSubmitted(false);
      }
    });

    if(clinic_details?._id){

      dispatch(
        getAllDoctors(clinic_details._id, {}, (result) => {
          if (result && !result.errors) {
            setDoctorsList(result);
          } else {
            setDoctorsList(false);
          }
        })
      );}
  }, [clinic_details]);

  return (
    <div className="row-block d-flex mt-3">
      <div className="head w-[25%] me-3">
        <h3>Add Diagnosis</h3>
        <div className="order-btn mt-3">
          <button
            className="btn btn-outline-light mb-2"
            onClick={(e) => {
              let obj = JSON.parse(
                JSON.stringify(defaultProblemConditionDiagnosis[0])
              );
              obj.pet_id = props.petId;
              pushFunc(obj);

              let dischargePatientData = JSON.parse(
                JSON.stringify(dischargePatientFormData)
              );
              dischargePatientData.added_diagnosis.push(obj);

              dispatch({
                type: "DISCHARGE_PATIENT_FORM_DATA",
                payload: { ...dischargePatientData },
              });
            }}
          >
            Add New
          </button>
        </div>
      </div>

      <div className="inner  w-[73%]">
        {/* {<h1>{JSON.stringify(dischargePatientFormData?.added_diagnosis)}</h1>} */}
        <Formik
          // ref={formikRef}
          innerRef={formikRef}
          initialValues={{
            problems: dischargePatientFormData?.added_diagnosis,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { validateForm }) => {
            console.log("here-");
            validateForm().then((errors) => {
              console.log("ERRORS---", errors);
              if (Object.keys(errors).length === 0) {
                console.log("SETTING--- TRUE");
                props.setFormSubmitted(true);
              } else {
                console.log("SETTING--- false");
                props.setFormSubmitted(false);
              }
            });
            // resetFormFunc();
            // console.log("onSubmit", JSON.stringify(values, null, 2));
          }}
          enableReinitialize={true}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            isValid,
            setFieldValue,
            submitForm,
            resetForm,
            status,
            setTouched,
            validateForm,
          }) => {
            resetFormFunc = resetForm;
            validateFormFun = validateForm;
            if (errors && errors?.problems && errors?.problems?.length) {
              props.setFormSubmitted(false);
            } else {
              props.setFormSubmitted(true);
            }
            return (
              <Form noValidate autoComplete="off">
                <FieldArray name="problems">
                  {({ push, remove }) => {
                    pushFunc = push;
                    return (
                      <div>
                        {values.problems &&
                          values.problems.map((p, index) => {
                            const diagnosed_by = `problems[${index}].diagnosed_by`;
                            const touchedDiagnosedBy = getIn(
                              touched,
                              diagnosed_by
                            );
                            const errorDiagnosedBy = getIn(
                              errors,
                              diagnosed_by
                            );

                            const start_date = `problems[${index}].start_date`;
                            const touchedStartDate = getIn(touched, start_date);
                            const errorStartDate = getIn(errors, start_date);

                            const end_date = `problems[${index}].end_date`;
                            const touchedEndDate = getIn(touched, end_date);
                            const errorEndDate = getIn(errors, end_date);

                            const description = `problems[${index}].description`;
                            const touchedDescription = getIn(
                              touched,
                              description
                            );
                            const errorDescription = getIn(errors, description);

                            const status = `problems[${index}].status`;
                            const touchedStatus = getIn(touched, status);
                            const errorStatus = getIn(errors, status);

                            // console.log("errors", errors)

                            return (
                              <div key={index}>
                                <div className="outer-discharge-table position-relative mb-3">
                                  <table class="w-full">
                                    <tr>
                                      <td className="vertical-top">
                                        Diagnosed By
                                      </td>
                                      <td>
                                        <span>
                                          <select
                                            placeholder="Select Doctor"
                                            label="Select Doctor"
                                            name={diagnosed_by}
                                            value={p.diagnosed_by}
                                            required
                                            helperText={
                                              touchedDiagnosedBy &&
                                              errorDiagnosedBy
                                                ? errorDiagnosedBy
                                                : ""
                                            }
                                            error={Boolean(
                                              touchedDiagnosedBy &&
                                                errorDiagnosedBy
                                            )}
                                            onChange={(e) => {
                                              handleChange(e);
                                              handleInputChange(
                                                e.target.value,
                                                "diagnosed_by",
                                                index
                                              );
                                            }}
                                            onBlur={handleBlur}
                                          >
                                            <option
                                              selected
                                              disabled
                                              value=""
                                              key={Math.random().toString()}
                                            >
                                              Select Doctor
                                            </option>
                                            {doctorsList?.map((item, index) => {
                                              // console.log("DOCTOR----", item)
                                              return (
                                                <option
                                                  value={item.user._id}
                                                  key={index}
                                                >{`${item?.user?.title} ${item?.user?.firstname} ${item?.user?.lastname}`}</option>
                                              );
                                            })}
                                          </select>
                                          {touchedDiagnosedBy &&
                                          errorDiagnosedBy ? (
                                            <div className="text-danger">
                                              {errorDiagnosedBy}
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="vertical-top">
                                        Start Date
                                      </td>
                                      <td>
                                        {
                                          <span className="padding-start">
                                            {" "}
                                            <DatePicker
                                              selected={
                                                p.start_date &&
                                                p.start_date != "" &&
                                                new Date(p.start_date)
                                              }
                                              onChange={(date) => {
                                                // Set the selected date in Formik
                                                setFieldValue(start_date, date);

                                                handleInputChange(
                                                  date,
                                                  "start_date",
                                                  index
                                                );
                                              }}
                                              onBlur={handleBlur}
                                              showTimeSelect
                                              timeFormat="hh:mm aa"
                                              timeIntervals={15}
                                              placeholderText="Select Date"
                                              dateFormat="MMMM d, yyyy h:mm aa"
                                              className="d-block absoulte date-blocker w-auto max-[600px]:min-w-[330px]"
                                            />
                                            {touchedStartDate &&
                                            errorStartDate ? (
                                              <div className="text-danger">
                                                {errorStartDate}
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                        }
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="vertical-top">End Date</td>
                                      <td>
                                        <span className="padding-start">
                                          <DatePicker
                                            selected={
                                              p.end_date &&
                                              p.end_date != "" &&
                                              new Date(p.end_date)
                                            }
                                            onChange={(date) => {
                                              // Set the selected date in Formik
                                              setFieldValue(end_date, date);
                                              handleInputChange(
                                                date,
                                                "end_date",
                                                index
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            showTimeSelect
                                            timeFormat="hh:mm aa"
                                              timeIntervals={15}
                                              placeholderText="Select Date"
                                              dateFormat="MMMM d, yyyy h:mm aa"
                                            className="d-block absoulte date-blocker w-auto max-[600px]:min-w-[330px]"
                                          />
                                          {touchedEndDate && errorEndDate ? (
                                            <div className="text-danger">
                                              {errorEndDate}
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="vertical-top">
                                        Description
                                      </td>
                                      <td>
                                        {/* <InputGroup id="rate"> */}
                                        <span className="padding-start">
                                          <input
                                            type="text"
                                            label="Enter Description"
                                            name={description}
                                            placeholder="Enter Description"
                                            value={p.description}
                                            required
                                            helperText={
                                              touchedDescription &&
                                              errorDescription
                                                ? errorDescription
                                                : ""
                                            }
                                            error={Boolean(
                                              touchedDescription &&
                                                errorDescription
                                            )}
                                            onChange={(e) => {
                                              handleChange(e);
                                              handleInputChange(
                                                e.target.value,
                                                "description",
                                                index
                                              );
                                            }}
                                            onBlur={handleBlur}
                                          />
                                          {touchedDescription &&
                                          errorDescription ? (
                                            <div className="text-danger">
                                              {errorDescription}
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="vertical-top">Status</td>
                                      <td>
                                        <span>
                                          <select
                                            placeholder="Select Status"
                                            label="Select Status"
                                            name={status}
                                            value={p.status}
                                            required
                                            helperText={
                                              touchedStatus && errorStatus
                                                ? errorStatus
                                                : ""
                                            }
                                            error={Boolean(
                                              touchedStatus && errorStatus
                                            )}
                                            onChange={(e) => {
                                              handleChange(e);
                                              handleInputChange(
                                                e.target.value,
                                                "status",
                                                index
                                              );
                                            }}
                                            onBlur={handleBlur}
                                          >
                                            <option>Select Status</option>
                                            <option>In Diagnosis</option>
                                            <option>Completed</option>
                                          </select>

                                          {touchedStatus && errorStatus ? (
                                            <div className="text-danger">
                                              {errorStatus}
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </td>
                                    </tr>
                                  </table>
                                  <span
                                    className="icomoon-trash order-del"
                                    onClick={() => {
                                      remove(index);
                                      removeItemFromRedux(index);
                                    }}
                                  ></span>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  }}
                </FieldArray>

                {debug && (
                  <>
                    <pre style={{ textAlign: "left" }}>
                      <strong>Values</strong>
                      <br />
                      {JSON.stringify(values, null, 2)}
                    </pre>
                    <pre style={{ textAlign: "left" }}>
                      <strong>Errors</strong>
                      <br />
                      {JSON.stringify(errors, null, 2)}
                    </pre>
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
});

export default Index;
