import React, { useEffect, useState } from "react";
import { Button, FormGroup, FormFeedback,  Input} from "reactstrap";
import { useHistory } from "react-router";
import {Formik, Form} from "formik"
import "react-datepicker/dist/react-datepicker.css";
import './index.scss';




const NewPointPanel = ({ className = '', closeNewPointPanel, refresh, addPoint}) => {
	const [points, setPoints] = useState([]);

	const toothPoints = {
		['13-lower-right'] : {
			//shape: "circle",
			name: "13 Lower Right",
			x: 237, 
			y: 568,
			//preFillColor: "blue"
		},
		['13-lower-left'] : {
			//shape: "circle",
			name: "13 Lower Left",
			x: 161, 
			y: 568,
			//preFillColor: "blue"
		},
		['12-lower-right'] : {
			//shape: "circle",
			name: "12 Lower Right",
			x: 226, 
			y: 581,
			//preFillColor: "blue"
		},
		['12-lower-left'] : {
			//shape: "circle",
			name: "12 Lower Left",
			x: 173, 
			y: 581,
			//preFillColor: "blue"
		},
		['11-lower-right'] : {
			//shape: "circle",
			name: "11 Lower Right",
			x: 209, 
			y: 586,
			//preFillColor: "blue"
		},
		['11-lower-left'] : {
			//shape: "circle",
			name: "11 Lower Left",
			x: 190, 
			y: 586,
			//preFillColor: "blue"
		},
		['13-upper-right'] : {
			//shape: "circle",
			name: "13 Upper Right",
			x: 237, 
			y: 83,
			//preFillColor: "blue"
		},
		['12-upper-right'] : {
			//shape: "circle",
			name: "12 Upper Right",
			x: 224, 
			y: 71
			//preFillColor: "blue"
		},
		['11-upper-right'] : {
			//shape: "circle",
			name: "11 Upper Right",
			x: 209, 
			y: 68
			//preFillColor: "blue"
		},
		['11-upper-left'] : {
			//shape: "circle",
			name: "11 Upper Left",
			x: 190, 
			y: 68
			//preFillColor: "blue"
		},
		['12-upper-left'] : {
			//shape: "circle",
			name: "12 Upper Left",
			x: 176, 
			y: 71
			//preFillColor: "blue"
		},

		['13-upper-left'] : {
			//shape: "circle",
			name: "13 Upper Left",
			x: 162, 
			y: 83,
			//preFillColor: "blue"
		},

		['C1-upper-left'] : {
			//shape: "circle",
			name: "C1 Upper Left",
			x: 145, 
			y: 100,
			//preFillColor: "blue"
		},
		['C1-upper-right'] : {
			//shape: "circle",
			name: "C1 Upper Right",
			x: 254, 
			y: 102,
			//preFillColor: "blue"
		},
		
		["P1-upper-left"]: {
			//shape: "circle",
			name: "P1 Upper Left",
			x: 137,
			y: 141,
			//preFillColor: "blue"
		},
		["P1-upper-right"]: {
			//shape: "circle",
			name: "P1 Upper Right",
			x: 262,
			y: 141,
			//preFillColor: "blue"
		},
		["P2-upper-left"]:{
			//shape: "circle",
			name: "P2 Upper Left",
			x: 130, 
			y: 169,
			//preFillColor: "blue"
		},
		["P2-upper-right"]:{
			//shape: "circle",
			name: "P2 Upper Right",
			x: 269, 
			y: 169,
			//preFillColor: "blue"
		},
		["P3-upper-left"]:{
			//shape: "circle",
			name: 'P3 Upper Left',
			x: 115, 
			y: 199,
			//preFillColor: "blue"
		},
		["P3-upper-right"]:{
			//shape: "circle",
			name: 'P3 Upper Right',
			x: 283, 
			y: 199,
			//preFillColor: "blue"
		},
		["P4-upper-left"]:{
			//shape: "circle",
			name: "P4 Upper Left", 
			x :96, 
			y: 234
			//preFillColor: "blue"
		}
		,
		["P4-upper-right"]:{
			//shape: "circle",
			name: "P4 Upper Right", 
			x: 301, 
			y: 234
			//preFillColor: "blue"
		}
		,
		["M1-upper-left"]:{
			//shape: "circle",
			name: "M1 Upper Left", 
			x: 91, 
			y: 276
			//preFillColor: "blue"
		},
		["M1-upper-right"]:{
			//shape: "circle",
			name: "M1 Upper Right", 
			x: 308, 
			y: 276
			//preFillColor: "blue"
		},
		["M2-upper-left"]:{
			//shape: "circle",
			name: "M2 Upper Left", 
			x :93, y :306
			//preFillColor: "blue"
		},
		["M2-upper-right"]:{
			//shape: "circle",
			name: "M2 Upper Right", 
			x :305, y :306
			//preFillColor: "blue"
		},
		
		["M3-lower-left"]:{
			//shape: "circle",
			name: "M3 Lower Left", 
			x: 97, 
			y: 351
			//preFillColor: "blue"
		}
		,
		["M3-lower-right"]:{
			//shape: "circle",
			name: "M3 Lower Right", 
			x: 303, 
			y: 351
			//preFillColor: "blue"
		}
		,
		["M2-lower-left"]:{
			//shape: "circle",
			name: "M2 Lower Left", 
			x: 98, 
			y: 371
			//preFillColor: "blue"
		},
		["M2-lower-right"]:{
			//shape: "circle",
			name: "M2 Lower Right", 
			x: 301, 
			y: 371
			//preFillColor: "blue"
		},
		["M1-lower-left"]:{
			//shape: "circle",
			name: "M1 Lower Left", 
			x: 100, 
			y:407
			//preFillColor: "blue"
		},
		["M1-lower-right"]:{
			//shape: "circle",
			name: "M1 Lower Right", 
			x: 299, 
			y:407
			//preFillColor: "blue"
		},
		["P4-lower-left"]:{
			//shape: "circle",
			name: "P4 Lower Left", 
			x: 116, 
			y: 450,
			//preFillColor: "blue"
		},
		["P4-lower-right"]:{
			//shape: "circle",
			name: "P4 Lower Right", 
			x: 283, 
			y: 450,
			//preFillColor: "blue"
		},
		["P3-lower-left"]:{
			//shape: "circle",
			name: "P3 Lower Left", 
			x: 127, 
			y: 479
			//preFillColor: "blue"
		},
		["P3-lower-right"]:{
			//shape: "circle",
			name: "P3 Lower Right", 
			x: 270, 
			y: 479
			//preFillColor: "blue"
		},
		["P2-lower-left"]:{
			//shape: "circle",
			name: "P2 Lower Left", 
			x: 137, 
			y: 500,
			//preFillColor: "blue"
		},
		["P2-lower-right"]:{
			//shape: "circle",
			name: "P2 Lower Right", 
			x: 262, 
			y: 500,
			//preFillColor: "blue"
		},
		["P1-lower-left"]:{
			//shape: "circle",
			name: "P1 Lower Left", 
			x: 143, 
			y: 518,
			//preFillColor: "blue"
		},
		["P1-lower-right"]:{
			//shape: "circle",
			name: "P1 Lower Right", 
			x: 256, 
			y: 518,
			//preFillColor: "blue"
		},
		["C1-lower-left"]:{
			//shape: "circle",
			name: "C1 Lower Left", 
			x: 147, 
			y: 545
			//preFillColor: "blue"
		}
		,
		["C1-lower-right"]:{
			//shape: "circle",
			name: "C1 Lower Right", 
			x: 250, 
			y: 545
			//preFillColor: "blue"
		}

	}


	const [areas, setAreas] = useState([
		{
			name:"13-lower-left",
			shape: "poly",
			coords: [115, 579, 151, 583, 152, 562, 166, 562, 164, 577, 153, 587, 118, 590]
		},
		{
			name:"12-lower-left",
			shape: "poly",
			coords: [127, 595, 162, 598, 165, 581, 176, 574, 176, 589, 166, 601, 130, 605]
		},
		{
			name:"11-lower-left",
			shape: "poly",
			coords: [141, 610, 174, 614, 183, 587, 191, 579, 195, 593, 178, 617, 144, 621]
		},
		{
			name:"13-upper-right",
			shape: "poly",
			coords: [229, 93, 244, 93, 251, 70, 285, 69, 285, 69, 246, 61]
		},
		{
			name:"12-upper-right",
			shape: "poly",
			coords: [217, 78, 229, 78, 238, 57, 272, 56, 272, 44, 231, 47]
		},
		{
			name:"11-upper-right",
			shape: "poly",
			coords: [203, 74, 215, 74, 227, 43, 261, 41, 261, 31, 221, 31]
		},
		{
			name:"11-upper-left",
			shape: "poly",
			coords: [142, 32, 179, 33, 198, 72, 186, 74, 174, 42, 144, 41]
		},
		{
			name:"11-lower-right",
			shape: "poly",
			coords: [201, 584, 220, 619, 258, 620, 257, 611, 225, 615, 212, 580]
		},
		{
			name:"12-lower-right",
			shape: "poly",
			coords: [218, 578, 234, 602, 272, 605, 271, 595, 237, 597, 227, 573]
		},
		{
			name:"13-lower-right",
			shape: "poly",
			coords: [230, 566, 246, 586, 283, 589, 282, 579, 249, 581, 239, 263]
		},
		{
			name:"12-upper-left",
			shape: "poly",
			coords: [129, 45, 169, 49, 183, 74, 173, 80, 163, 58, 131, 56]
		},
		{
			name:"13-upper-left",
			shape: "poly",
			coords: [114, 58, 153, 63, 172, 83, 160, 95, 147, 72, 117, 70]
		},

		{
			name:"C1-upper-left",
			shape: "rect",
			coords: [81,78, 154, 123]
		},
		{
			name:"C1-upper-right",
			shape: "rect",
			coords: [247, 87, 315, 117]
		},
		{
			name:"P1-upper-left",
			shape: "rect",
			coords: [79, 130, 147, 151]
		},
		{
			name:"P1-upper-right",
			shape: "rect",
			coords: [257, 134, 319, 149]
		},
		{
			name:"P2-upper-left",
			shape: "rect",
			coords: [72, 156, 141, 183]
		},
		{
			name:"P2-upper-right",
			shape: "rect",
			coords: [261, 160, 329, 179]
		},
		{
			name:"P3-upper-left",
			shape: "rect",
			coords: [57, 185, 129, 215]
		},
		{
			name:"P3-upper-right",
			shape: "rect",
			coords: [273, 188, 342, 213]
		},
		{
			name:"P4-upper-left",
			shape: "rect",
			coords: [36, 220, 120, 260]
		}
		,
		{
			name:"P4-upper-right",
			shape: "rect",
			coords: [283, 223, 366, 257]
		}
		,
		{
			name:"M1-upper-left",
			shape: "rect",
			coords: [26, 262, 110, 294]
		}
		,
		{
			name:"M1-upper-right",
			shape: "rect",
			coords: [291, 265, 372, 292]
		}
		,
		{
			name:"M2-upper-left",
			shape: "rect",
			coords: [25, 298, 112, 315]
		},
		{
			name:"M2-upper-right",
			shape: "rect",
			coords: [292, 299, 373, 316]
		},
		{
			name:"M3-lower-left",
			shape: "rect",
			coords: [33, 341, 108, 357]
		}
		,
		{
			name:"M3-lower-right",
			shape: "rect",
			coords: [296, 345, 365, 357]
		}
		,
		{
			name:"M2-lower-left",
			shape: "rect",
			coords: [35, 365, 110, 380]
		},
		{
			name:"M2-lower-right",
			shape: "rect",
			coords: [294, 362, 365, 378]
		},
		{
			name:"M1-lower-left",
			shape: "rect",
			coords: [37, 385, 119, 420]
		},
		{
			name:"M1-lower-right",
			shape: "rect",
			coords: [281, 388, 363, 420]
		},
		{
			name:"P4-lower-left",
			shape: "rect",
			coords: [56, 435, 127, 460]
		},
		{
			name:"P4-lower-right",
			shape: "rect",
			coords: [275, 435, 341, 460]
		},
		{
			name:"P3-lower-left",
			shape: "rect",
			coords: [70, 468, 140, 488]
		},
		{
			name:"P3-lower-right",
			shape: "rect",
			coords: [265, 468, 328, 488]
		},
		{
			name:"P2-lower-left",
			shape: "rect",
			coords: [76, 491, 147, 509]
		},
		{
			name:"P2-lower-right",
			shape: "rect",
			coords: [256, 491, 321, 509]
		},
		{
			name:"P1-lower-left",
			shape: "rect",
			coords: [85, 510, 150, 520]
		},
		{
			name:"P1-lower-right",
			shape: "rect",
			coords: [250, 510, 313, 520]
		},
		{
			name:"C1-lower-left",
			shape: "rect",
			coords: [90, 528, 161, 558]
		}
		,
		{
			name:"C1-lower-right",
			shape: "rect",
			coords: [239, 528, 307, 558]
		}


	])

	let map = {
		name: "dental-diagram",
		areas: areas
	}


	const formObjToSchema = (obj) => {

		var schema = {
			type: "object",
			properties: {
	
			},
			required: []
		}
	
		var config ={
			errMessages: {
	
			}
		}
	
		Object.keys(obj).map((key, i ) => {
			schema.properties[obj[key].val_name] = {
				type: (obj[key].type === 'input' || obj[key].type === 'select' ? 'string' :  'date'),
				//required: (obj[key]?.required || false),
			}
			if (obj[key].required) {
				schema.required = [...schema.required, obj[key].val_name ]
				config.errMessages[obj[key].val_name] = {required: `${key} is required`}
			}
		})
	
		const { buildYup } = require("schema-to-yup");
		const yupSchema = buildYup(schema, config);
		// console.log('yup schema', yupSchema)
		return yupSchema;
	
	}
	
	const initValsFromFormObj = (obj) => {
	
		var init = {}
	
		Object.keys(obj).map((key, i ) => {
			init[obj[key].val_name] = ''
		})
		return init;
	
	}
	

	



	const formInfo = {
			['Skull Type']:{value:'', type:'input', val_name:'skull_type'},
			['Oral Hygiene']: {value:'', type:'input', val_name: 'oral_hygiene' },
			['Remarks']: {value:'', type:'input', val_name: 'remarks' },
			Occlusion: {value: '' , type: 'input', val_name: 'occlusion' },
			['Tooth Abnormalities']: {value: '' , type: 'input', val_name: 'tooth_abnormalities' },
			['Added Date & Time'] : {value: '', type: 'date', val_name: 'added_date', required: true},

		
	}

	const [ formVal, setFormVal ] = useState(
		{
			remarks: '',
			skull_type: '',
			oral_hygiene: '',
			occlusion: '',
			tooth_abnormalities: '',
			added_date: ''
		}
	)

	const selectPoint= (event) => {

		let dim = event.target.getBoundingClientRect();
		let x = event.clientX - dim.left;
		let y = event.clientY - dim.top;
		setPoints([...points, {x: x, y: y}])
	}


	const handleareaclick = (area) => {
		//console.log(area.name)
		//console.log(areas)
		//setPoints([...points, {...toothPoints[area.name]}])
		if (points.some(e => JSON.stringify(e) === JSON.stringify(toothPoints[area.name]))){
			console.log('removing ' + area.name)
			let to_remove = points.findIndex(e => JSON.stringify(e) === JSON.stringify(toothPoints[area.name]))
			console.log(to_remove)
			setPoints((prev) => prev.filter((data, index) => index !== to_remove))
			console.log(points)

		}
		else {
			console.log('adding ' + area.name);
		setPoints((prev) =>[...prev, 
			toothPoints[area.name]
		])}
		}

	const handlePointClick = (point) => {

			let to_remove = points.findIndex(e => JSON.stringify(e) === JSON.stringify(point))
			console.log(to_remove)
			setPoints((prev) => prev.filter((data, index) => index !== to_remove))
			console.log(points)



	}



	

	useEffect(() => {

		setFormVal({
		
			skull_type: '',
			oral_hygiene: '',
			occlusion: '',
			tooth_abnormalities: '',
			added_date: ''
		})

		setPoints([])
		
	}, [className]);

	const handleAddPoint = (values) => {
		
		Object.keys(values).forEach(key => {
            if (values[key] === '' || values[key] === null) {
                     delete values[key];
              }
			else if (key === 'added_date' ) {
				values[key] = new Date(values[key])

		}
		});
	
		addPoint({...values, coordinates: points}, response => { 
			if(response?.status) {
			closeNewPointPanel()
			refresh();
			}

		})

	}
	useEffect(() => {

	}, [areas])





	return (
		<>
			<div className={`dental-new-point-panel-wrapper position-fixed d-flex flex-column overflow-auto ${className}`}>
				<div className="head d-flex align-items-center">
					<h2>Add New Dental Record</h2>
					<Button color="link" className="ms-auto panel-close-btn" onClick={closeNewPointPanel}>
						<span className="material-symbols-outlined">
							close
						</span>
					</Button>
				</div>
			 
				<div className="inner overflow-auto align-items-center">
					<FormGroup>
					<div className='ms-auto mr-auto' style={{position: 'relative', width: 'fit-content'}}>
						
							<img className="dog-img" alt={'Dental Diagram'} usemap={'#dental-diagram'} src={require("../../../../../../../assets/img/dental-diagram.png")}></img>
							<map name="dental-diagram">
								{areas.map((area, i) =>
									<area key={i} shape={area.shape} coords={area.coords} onClick={() => handleareaclick(area)} ></area> 
								)}
							</map>
							
								{points.map((point, i) => 
									<div onClick={() => handlePointClick(point)}
									style={{
											position: 'absolute',
											left: `${point.x - 10}px`,
											top: `${point.y - 10}px`,
											backgroundColor:'#3D3EFD',
											width: 20,
											height: 20,
											borderRadius: 100,
											fontFamily: 'Inter',
											fontStyle: 'normal',
											fontWeight: 600,
											fontSize: '14px',
											lineHeight: '150%',
											textAlign:"center",
											color: '#FFFFFF'
										}}>
										</div>) 
									}
					</div>
					{points.length < 1 && <FormFeedback style={{display: 'block'}} invalid>Please Select The Affected Teeth</FormFeedback>}

					</FormGroup>



					<Formik initialValues={initValsFromFormObj(formInfo)}
                        enableReinitialize
                        validationSchema={formObjToSchema(formInfo)}
                        onSubmit={(values, {resetForm}) => {
							
								handleAddPoint(values);
								resetForm();}}> 

					{(formik) => (
				
					<Form>


						
						<div className="form-block">
								<FormGroup className="input-wrapper">
									<div className="form-floating position-relative text-input">
										<Input disabled type="text" className="form-control" id={`Selected Teeth`} placeholder={`Selected Teeth`} value={(points.map((data, index) => data.name )).join(', ')} /> {/*+ ((index === (points.length - 1) ?  "" : ', ')))} />*/}
										<label for={`Selected Teeth`}>{`Selected Teeth`}</label>
									</div>
								</FormGroup>
						</div>
				
						


						{Object.keys(formInfo).map((key, i) => {
							switch (formInfo[key].type){
								case "input":
									return (
										<>
											<div key={i} className="form-block">
												<FormGroup className="input-wrapper">
													<div className="form-floating position-relative text-input">
														<Input invalid={formik?.errors?.[formInfo[key].val_name] && formik?.touched?.[formInfo[key].val_name]} name={formInfo[key].val_name} type="text" className="form-control" id={`${key}`} placeholder={`Enter ${key}`} value={formik?.values?.[formInfo[key]?.val_name] /*formVal[formInfo[key].val_name]*/}  onChange ={evt =>{

																																																									formik.handleChange(evt)

																																																									/*setFormVal({...formVal, [formInfo[key].val_name]:  evt.target.value})*/
																																																									
																																																									}}/>
														<label for={`${key}`}>{`${key}`}</label>
														{formik?.errors?.[formInfo[key].val_name] && formik?.touched?.[formInfo[key].val_name] ? (
															<FormFeedback>{formik?.errors?.[formInfo[key].val_name]}</FormFeedback>
														) : null}
													</div>
												</FormGroup>
											</div>
										</>
									)
									case "select":
										return (
											<>
												<div key={i} className="form-block">
													<FormGroup className="input-wrapper">
														<div className="form-floating position-relative text-input">
															<Input name={formInfo[key].val_name}  type="select" className="form-control" id={`${key}`} placeholder={`Enter ${key}`}  value={formik?.values?.[formInfo[key]?.val_name] /*formVal[formInfo[key].val_name]*/} onChange ={evt =>{ console.log(evt.target.value) 
																																																											formik.handleChange(evt)
																																																											setFormVal({...formVal,[formInfo[key].val_name]:  evt.target.value  })}}>
																{formInfo[key].options.map((option, j) => {
																	return(
																		<option key={j}>{option}</option>
																	)
																})}
															</Input>
															<label for={`${key}`}>{`${key}`}</label>
														</div>
													</FormGroup>
												</div>
											</>
										)
									case "date":
										return (
											<>
												<div key={i} className="form-block">
												<FormGroup className="input-wrapper">
														<div className="form-floating position-relative text-input">
															<Input invalid={formik?.errors?.[formInfo[key].val_name] && formik?.touched?.[formInfo[key].val_name]} name={formInfo[key].val_name} type="datetime-local" className="form-control" id={`${key}`} placeholder={`Enter ${key}`} value={formik?.values?.[formInfo[key]?.val_name]}  onChange ={evt =>{
																																																										formik.handleChange(evt)
																																																										setFormVal({...formVal,  [formInfo[key].val_name]:  evt.target.value})
																																							}}/>
															<label for={`${key}`}>{`${key}`}</label>
															{formik?.errors?.[formInfo[key].val_name] && formik?.touched?.[formInfo[key].val_name] ? (
															<FormFeedback invalid>{formik?.errors?.[formInfo[key].val_name]}</FormFeedback>
															) : null}
														</div>
												</FormGroup>
											</div>


												
											</>
										)
							}
						})}

							<div className=" form-block d-flex justify-content-end btn-blc">
								<Button color="success" type="submit" className="add-btn">Save</Button>
							</div>
						
					</Form>

					)}
				</Formik>
				</div>
			</div>
		</>
	);
};

export default NewPointPanel;
