const initState = {
    loading: false,
    results: []
    
}
    

export const woundsReducer = (state = initState, action) => {
    switch (action.type) {
        case "WOUNDS_LOADING":
            return { ...state, loading: action.payload }

        case "WOUNDS":
            return { ...state, results: action.payload }
    
        default:
            return state
    }
}
