import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "reactstrap";
import { sidebarPanelAction } from "../../../context/actions/sidebarPanelAction";
import UserPlaceholder from "../../UserPlaceholder";
import './index.scss';

const UserProfileButton = ({ userName, userImage }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();

	return (
		<>
			<Button color="link" className={`user-profile-btn d-flex align-items-center text-start w-100 ${location.pathname === "/manage-profile" ? "active" : ''}`} onClick={() => { history.push('/manage-profile'); dispatch(sidebarPanelAction(false)) }}>
				<div className="pic rounded-circle overflow-hidden">
					{
						userImage ? <img className="img-fluid" src={userImage} alt="User" /> : <UserPlaceholder />
					}

				</div>
				<div className="txt">
					<h4>{userName}</h4>
					<h5>Manage Profile</h5>
				</div>
			</Button>
		</>
	)
};
export default UserProfileButton;