import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "./index.scss";
import Pagination from "react-js-pagination";
import { Button } from "reactstrap";
const PaginationBlock = ({
  limit,
  page,
  pagingCounter,
  docLength,
  docPage,
  totalPages,
  totalDocs,
  _handlePageChange,
  _handleLimit,

}) => {
  const [lmt, setLmt] = useState();
  const [cPage, setCpage] = useState();

  useEffect(() => {
    setLmt(limit);
    setCpage(page);
  }, [limit, page]);


  return (
    <>
      <div className="pagination-blc d-md-flex overflow-auto align-items-center justify-content-between">
        <div className="page-count">
          {`Showing ${pagingCounter?.toLocaleString("en-US")} - 
					${docPage == totalPages ? totalDocs?.toLocaleString("en-US") : (docLength * page).toLocaleString("en-US")} 
					out of 
					${totalDocs?.toLocaleString("en-US")}`}{" "}
        </div>

        {/* <Button color="link" className="prev-btn">
						<FontAwesomeIcon icon={faAngleLeft} />
					</Button>
					<div className="items  d-flex align-items-center">
						<Button color="link">1</Button>
						<Button color="primary">2</Button>
						<Button color="link">...</Button>
						<Button color="link">9</Button>
						<Button color="link">10</Button>
					</div>
					<Button color="link" className="next-btn" disabled>
						<FontAwesomeIcon icon={faAngleRight} />
					</Button> */}
        <Pagination
          innerClass="pagination justify-content-end pb-10 mt-auto"
          itemClass="page-item"
          linkClass="page-link"

          // nextPageText="Next"
          // firstPageText="First"
          // lastPageText="Last"
          activePage={cPage}
          itemsCountPerPage={Number(lmt)}
          totalItemsCount={totalDocs}
          onChange={(activePage) => {
            _handlePageChange(activePage);
          }}
        />
      </div>
    </>
  );
};
export default PaginationBlock;
