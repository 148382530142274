import React from "react";
import "./index.scss";

const NoTimeSlot = (
	{
		className = '',
		label = 'No Slot Available!!',
		icon = 'mood_bad'
	}
) => {
	return (
		<>
			<div color="link" className={`no-time-slot flex-fill d-flex align-items-center justify-content-center ${className}`}>
				<div className="inner d-inline-flex align-items-center">
					<span className="material-symbols-outlined">
						{icon}
					</span>
					{label}
				</div>
			</div>
		</>
	);
}

export default NoTimeSlot;