// Layout Types
import NotFound from "../shared/NoRecord";
import { DefaultLayout } from "../layouts";
import NoAccess from "components/no-access";

// Route Views 

const NotFoundRoute = [
  {
    path: "/no-access",
    layout: DefaultLayout,
    component: NoAccess
  },
  {
    layout: DefaultLayout,
    component: NotFound
  },
 
];
export default NotFoundRoute;
