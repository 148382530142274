const initState = {
    loading: false,
    planList: false,
}

export const medicationPlanReducer = (state = initState, action) => {
    switch (action.type) {
        case "MEDICATION_LOADING":
            return { ...state, loading: action.payload }

        case "GET_MEDIACTION_PLANS":
            return { ...state, planList: action.payload }

        default:
            return state
    }
}