import React from 'react';
import { Offcanvas } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Input } from "reactstrap";
import { BsSearch } from "react-icons/bs";
import { PiCalendarCheckLight } from "react-icons/pi";
import moment from "moment";
import { RiMapPinAddLine } from "react-icons/ri";
import autoMode from "assets/img/autoMode.png";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { AiOutlineClockCircle } from "react-icons/ai";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";

const ScheduleVisit = ({ setScheduleVisit, scheduleVisit, appointments, setSelectedAppointment, roomId, chatList }) => {
    const [appointmentList, setAppointmentList] = useState([]);
    let petId = chatList?.members?.find((member) => {
        if (member?.role === 'pet-owner') {
            return member?.id;
        }
    });
    useEffect(() => {
        if (petId?.id && appointments?.length > 0) {
            const newList = appointments?.filter((appData) => {
                if (appData?.patient_id?.pet_owner === petId?.id) {
                    return appData;
                }
            })
            setAppointmentList(newList)
        } else {
            setAppointmentList(appointments)
        }
    }, [appointments])

    const handleAppData = (appInfo) => {
        setSelectedAppointment(appInfo);
        setScheduleVisit(false);
    }
    const searchDoctor = (value) => {
        if (value !== '' && petId?.id) {
            const filterData = appointments?.filter((appData) => {
                const name = appData?.doctor_details?.title + " " + appData?.doctor_details?.firstname + " " + appData?.doctor_details?.lastname;            
                if (appData?.patient_id?.pet_owner === petId?.id && (name.toLowerCase().includes(value.toLowerCase()) || appData?.reason?.toLowerCase().includes(value.toLowerCase()))) {
                    return appData;
                }
                return false
            });
            setAppointmentList(filterData)
        } else if (value !== '' && !petId?.id) {
            const filterData = appointments?.filter((appData) => {
                const name = appData?.doctor_details?.title + " " + appData?.doctor_details?.firstname + " " + appData?.doctor_details?.lastname;
                if (name?.toLowerCase().includes(value.toLowerCase()) || appData?.reason?.toLowerCase().includes(value.toLowerCase())) {
                    return appData;
                }
                return false
            });
            setAppointmentList(filterData)
        } else if (value === '' && petId?.id) {
            const filterData = appointments?.filter((appData) => {
                if (appData?.patient_id?.pet_owner === petId?.id) {
                    return appData;
                }
                return false
            })
            setAppointmentList(filterData)
        } else {
            setAppointmentList(appointments)
        }

    }
    return (
        <>
            <Offcanvas
                show={scheduleVisit}
                onHide={() => { setScheduleVisit(false) }}
                placement="end"
                className="schedule-visit-offcanvas"
            >
                <Offcanvas.Header closeButton className="pb-0">
                    <Offcanvas.Title>Schedule Visits</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="pt-2 schedule-visit-block">
                    <div className='main-block'>
                        <div className={`search-blc position-relative input-cell`}>
                            <Input type="text" placeholder="Search" onChange={(e) => searchDoctor(e.target.value)} />
                            <span className="position-absolute d-inline-flex align-items-center justify-content-center">
                                <BsSearch className="h-4 w-4" />
                            </span>
                        </div>
                        <div className='next-upcoming pt-2 pb-2'>
                            <p className='text-lg font-semibold pb-2'>Next Upcoming This Week</p>
                            {appointmentList?.length > 0 && appointmentList?.map((appt, i) => {
                                const date1 = new Date();
                                const date2 = new Date(moment(appt?.appointment_date, "DD-MM-YYYY").toString());

                                if (date2 >= date1) {
                                    if (date2 > date1 || (date2.getTime() === date1.getTime() && (moment(appt?.appointment_time?.substring(0, 6)).format("HH:mmA") > moment(new Date()).format('hh:mmA')))) {
                                        let Difference_In_Time = date2.getTime() - date1.getTime();

                                        // To calculate the no. of days between two dates
                                        let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
                                        return (
                                            <div className='pt-1'>
                                                <div id="next-upcoming-container" onClick={() => handleAppData(appt)}>
                                                    <div className='btn-block'>
                                                        <button type="button" class="btn-sm w-full bg-amber-100 text-amber-500 text-sm border border-amber-500 rounded-md"><span className='text-xs'>START IN 00:00:48</span></button>
                                                    </div>
                                                    <div className="item-block">
                                                        <div className="data-block">
                                                            <div className="d-flex gap-2">
                                                                <div className="f-center bg-eeee color-cfcf h-49 w-49 rounded-circle">
                                                                    <span className="material-symbols-outlined text-24-600">
                                                                        person
                                                                    </span>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <div className="text-16-400 color-2E65">
                                                                        {appt?.doctor_details?.title} {" "} {appt?.doctor_details?.firstname} {' '} {appt?.doctor_details?.lastname}
                                                                    </div>
                                                                    <div className="text-13-400 color-2E65">DVM</div>
                                                                </div>
                                                                <div className="pt-1">
                                                                    <img src={autoMode} alt="load" />
                                                                </div>
                                                            </div>
                                                            <div className="fa-center gap-2 mt-2">
                                                                <div className="text-16-400 color-2E65">
                                                                    {appt?.reason}
                                                                </div>
                                                            </div>
                                                            <div className="fa-center gap-2 mt-2">
                                                                <div className="calendar-icon">
                                                                    <MdOutlineCalendarMonth />
                                                                </div>
                                                                <div className="fa-center gap-2 text-14-400 color-2E65">
                                                                    <div>
                                                                        {moment(appt?.appointment_date, "DD-MM-YYYY").format("MMM D, YYYY")}
                                                                        <span>
                                                                            {date1 > date2
                                                                                ? ` In ${Difference_In_Days} Days`
                                                                                : (new Date().getTime() === new Date(appt?.appointment_date).getTime() && (moment(appt?.appointment_time?.substring(0, 6)).format("HH:mmA") > moment(new Date()).format('hh:mmA')))
                                                                                    ? 'In hours'
                                                                                    : ''
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="fa-center gap-2 mt-2">
                                                                <div><AiOutlineClockCircle /></div>
                                                                <div className="text-14-400 color-2E65">{appt?.appointment_time || appt?.appointment_timings?.[0]?.appointment_time}</div>
                                                            </div>
                                                            <div className="fa-center gap-2 mt-2">
                                                                <div>
                                                                    <RiMapPinAddLine />
                                                                </div>
                                                                <div className="text-14-400 color-2E65">West Coast Clinic</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                }
                            })}
                        </div>
                        <div className='next-upcoming pt-2 pb-2'>
                            <p className='text-lg font-semibold pb-2'>Past Visits</p>

                            {appointmentList?.length > 0 && appointmentList?.map((appt, i) => {
                                const date1 = new Date();
                                const date2 = new Date(moment(appt?.appointment_date, "DD-MM-YYYY").toString());
                                if (date1 >= date2) {
                                    if (date1 > date2 || (date1.getTime() === date2.getTime() && (moment(new Date()).format('hh:mmA') > moment(appt?.appointment_time?.substring(0, 6)).format("HH:mmA")))) {
                                        let Difference_In_Time = date1.getTime() - date2.getTime();

                                        // To calculate the no. of days between two dates
                                        let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
                                        return (
                                            <div className='pt-1'>
                                                <div id="next-upcoming-container" onClick={() => handleAppData(appt)}>
                                                    <div className="item-block">
                                                        <div className="data-block">
                                                            <div className="d-flex gap-2">
                                                                <div className="f-center bg-eeee color-cfcf h-49 w-49 rounded-circle">
                                                                    <span className="material-symbols-outlined text-24-600">
                                                                        person
                                                                    </span>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <div className="text-16-400 color-2E65">
                                                                        {appt?.doctor_details?.title} {" "} {appt?.doctor_details?.firstname} {' '} {appt?.doctor_details?.lastname}
                                                                    </div>
                                                                    <div className="text-13-400 color-2E65">DVM</div>
                                                                </div>
                                                                <div className="pt-1">
                                                                    <img src={autoMode} alt="load" />
                                                                </div>
                                                            </div>
                                                            <div className="fa-center gap-2 mt-2">
                                                                <div className="text-16-400 color-2E65">
                                                                   {appt?.reason}
                                                                </div>
                                                            </div>
                                                            <div className="fa-center gap-2 mt-2">
                                                                <div className="calendar-icon">
                                                                    <MdOutlineCalendarMonth />
                                                                </div>
                                                                <div className="fa-center gap-2 text-14-400 color-2E65">
                                                                    <div>
                                                                        {moment(appt?.appointment_date, "DD-MM-YYYY").format("MMM D, YYYY")}
                                                                        <span>
                                                                            {date1 === date2
                                                                                ? ' (Today)'
                                                                                : ''
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="fa-center gap-2 mt-2">
                                                                <div><AiOutlineClockCircle /></div>
                                                                <div className="text-14-400 color-2E65">{appt?.appointment_time || appt?.appointment_timings?.[0]?.appointment_time}</div>
                                                            </div>
                                                            <div className="fa-center gap-2 mt-2">
                                                                <div>
                                                                    <RiMapPinAddLine />
                                                                </div>
                                                                <div className="text-14-400 color-2E65">West Coast Clinic</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }

                                }

                            })}
                            {/* <div id="next-upcoming-container">
                                <div className="item-block">
                                    <div className="data-block">
                                        <div className="d-flex gap-2">
                                            <div className="f-center bg-eeee color-cfcf h-49 w-49 rounded-circle">
                                                <span className="material-symbols-outlined text-24-600">
                                                person
                                                </span>
                                            </div>
                                            <div className="flex-grow-1">
                                            <div className="text-16-400 color-2E65">
                                                Dr. Martha William
                                            </div>
                                            <div className="text-13-400 color-2E65">DVM</div>
                                            </div>
                                            <div className="pt-1">
                                            <img src={autoMode} alt="load" />
                                            </div>
                                        </div>
                                        <div className="fa-center gap-2 mt-2">
                                            <div className="text-16-400 color-2E65">
                                                vaccination
                                            </div>
                                        </div>
                                        <div className="fa-center gap-2 mt-2">
                                            <div className="calendar-icon">
                                                <MdOutlineCalendarMonth />
                                            </div>
                                            <div className="fa-center gap-2 text-14-400 color-2E65">
                                                <div>
                                                    {moment(new Date(), "DD-MM-YYYY").format("MMM D, YYYY")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            

                            <div className='pt-1'></div>
                            <div id="next-upcoming-container">
                                <div className="item-block">
                                    <div className="data-block">
                                        <div className="d-flex gap-2">
                                            <div className="f-center bg-eeee color-cfcf h-49 w-49 rounded-circle">
                                                <span className="material-symbols-outlined text-24-600">
                                                person
                                                </span>
                                            </div>
                                            <div className="flex-grow-1">
                                            <div className="text-16-400 color-2E65">
                                                Dr. Martha William
                                            </div>
                                            <div className="text-13-400 color-2E65">DVM</div>
                                            </div>
                                            <div className="pt-1">
                                            <img src={autoMode} alt="load" />
                                            </div>
                                        </div>
                                        <div className="fa-center gap-2 mt-2">
                                            <div className="text-16-400 color-2E65">
                                                vaccination
                                            </div>
                                        </div>
                                        <div className="fa-center gap-2 mt-2">    
                                            <div className="calendar-icon">
                                                <MdOutlineCalendarMonth />
                                            </div>
                                            <div className="fa-center gap-2 text-14-400 color-2E65">
                                                <div>
                                                    {moment(new Date(), "DD-MM-YYYY").format("MMM D, YYYY")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default ScheduleVisit;