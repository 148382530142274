import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";

toast.configure();
/**
 * Waiting Room Api endpoints
 * /settings/get-by-clinic-id/:clinic_id
*/

/**
 * Get waiting Rooms
 * @param {Object} params
 * @param {Function} callback
 * @returns 
 */
export const getWaitingRoomList = (params, callback) => {
    return async (dispatch, getState) => {
        const { user: { token }, clinicReducer: { clinic_details } } = getState();
        let clinicId = clinic_details ? clinic_details?._id : params.clinicId;
        if (clinicId) {
            ApiClient.get(`${apiUrl}${PORT}/waiting-room/get/${clinicId}`, params, token, dispatch, getState).then(response => {
                // dispatch({ type: "LOADING", payload: false })

                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    dispatch({ type: "GET_WAITING_ROOM", payload: response?.response_data })
                    return callback(response);
                } else {
                    //toast.error(response.message, toasterConfig);
                    return callback(response);
                }
            })
        }
    };
};
/**
 * Get waiting Rooms
 * @param {Object} params
 * @param {Function} callback
 * @returns 
 */
export const createWaitingRoomList = (formData, callback) => {
    return async (dispatch, getState) => {
        const { user: { token } } = getState();
        ApiClient.postFormData(`${apiUrl}${PORT}/waiting-room/create`, formData, token, dispatch, getState).then(response => {
            if (!response) {
                return callback();
            }
            else if (response?.status) {
                dispatch(getWaitingRoomList())
                toast.success(response.message, toasterConfig);
                return callback(response);
            } else {
                toast.error(response.message, toasterConfig);
                return callback(response);
            }
        })
    }
};
export const updateWaitingRoomList = (formData, callback) => {
    return async (dispatch, getState) => {
        const { user: { token } } = getState();
        ApiClient.putFormData(`${apiUrl}${PORT}/waiting-room/update`, formData, token, dispatch, getState).then(response => {
            if (!response) {
                return callback();
            }
            else if (response?.status) {
                dispatch(getWaitingRoomList())
                toast.success(response.message, toasterConfig);
                return callback(response);
            } else {
                toast.error(response.message, toasterConfig);
                return callback(response);
            }
        })
    }
};

export const deleteWaitingRoomListItem = (payload) => async (dispatch, getState) => {
    const { user: { token } } = getState();
    const response = await ApiClient.put(`${apiUrl}${PORT}/waiting-room/remove-attachment`, payload, token, dispatch, getState)

    if (!response?.status) {
        toast.error(response.message, toasterConfig);
    }
    return response
}