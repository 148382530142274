import * as Yup from "yup";
/**
 * @description declaration form schema for client validations
 */

export const petSchema = Yup.object().shape({
  pet_name: Yup.string().required("Pet name is required."),
  dob: Yup.string().required("Birth date is required."),
  species: Yup.string().required("Species is required."),
  gender: Yup.string().required("Gender is required."),
  breed: Yup.string().required("Breed is required."),
  color: Yup.string().required("Color is required."),
});


