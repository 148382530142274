export const validation = {
  validateEmail: val => {
    // eslint-disable-next-line
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      val
    );
  },
  validatePassword: val => {
    // eslint-disable-next-line
    return /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*[\s0-9!@#$%^~&*-_^"^'^=^*^`^@^#^%^&^(^)^!^:^;^?^/^.^{^}^<^>^,^\\])[\sA-Za-z0-9!@#~$%^&*-_^"^'^`^*^@^#^%^&^(^)^{^}^!^=^:^;^?^/^<^>^.^,^\\]{8,100}$/.test(
      val
    );
  },
  expirationDate: val => {
    if (!val) {
      return false
    }
    const today = new Date()
    const monthToday = today.getMonth() + 1
    const yearToday = today
      .getFullYear()
      .toString()
      .substr(-2)
    const [expMonth, expYear] = val.split('/')

    if (Number(expYear) < Number(yearToday)) {
      return false
    } else if (
      Number(expMonth) < monthToday &&
      Number(expYear) <= Number(yearToday)
    ) {
      return false
    }
    return true
  },
  expirationMonth: val => {
    if (!val) {
      return false
    }
    const [expMonth] = val.split('/')
    if (Number(expMonth) > 12) {
      return false
    }
    return true
  }
};


export const validationMessage = {
  fieldReq: `This field is required.`,
  emailReq: `Email is required.`,
  passwordReq: `Password is required.`,
  emailInvalid: `Email address is not valid.`,
  numberMaxInvalid: (length = 20) => `This field should contain  ${length} digits only.`,
  passLength: `Password must be atleast 8 characters long.`,
  textMaxInvalid: (length = 20) => `This field should not exceed ${length} characters.`,
  imageReq:`Only jpeg, png format is allowed`

}






