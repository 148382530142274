import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import autoLoad from "assets/img/autoMode.png";
import { IoIosSearch } from "react-icons/io";
import moment from "moment";
import DatePicker from "react-datepicker";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import NoDataFound from "shared/NoDataFoundBlock";
import { Spinner } from "reactstrap";
import redCancel from "assets/img/icons/redCancel.png";
import greenCheck from "assets/img/icons/greenCheck.png";
import { BsCapsule } from "react-icons/bs";
import { getHomeMedicationOrders } from "context/actions/patient-module/orders";
import {
  updateMedicationReportStatus,
  updateUnkownStatusData,
} from "context/actions/patient-module/overview/medication-plan";
import "react-datepicker/dist/react-datepicker.css";
import "./MedicationPlans.scss";
import { titleCaseString } from "utils/common";
import { toast } from "react-toastify";
import { toasterConfig } from "utils/constants";

const MedicationPlans = () => {
  const dispatch = useDispatch();
  const newRef = useRef(null);
  const { homeMedicationOrders } = useSelector((state) => state.orderReducer);
  const { petDetails } = useSelector((state) => state.petOwnerDashboardReducer);
  const userDetail = useSelector((state) => state.user.user_details);
  const [activePlan, setActivePlan] = useState(null);
  const [pageLoader, setPageLoader] = useState(true);
  const [dateRange, setDateRange] = useState([null, null]);
  const [filterData, setFilterData] = useState({
    searchText: "",
    type: "all",
  });
  const [startDate, endDate] = dateRange;
  const medicationPlanList = async () => {
    await dispatch(
      getHomeMedicationOrders({ pet_id: petDetails?._id }, () => {})
    );
    setPageLoader(false);
  };
  const getPercentComplete = (newData) => {
    let totalDose = 0;
    getOrderDates(newData)?.forEach((elm) => {
      const { timeList } = elm;
      totalDose = totalDose + timeList?.length;
    });

    const takenDoses = newData?.medician_reports?.filter(
      (o) => o.plan_status === "taken"
    )?.length;
    let count = Math.ceil((takenDoses / totalDose) * 100);
    return count;
  };
  const getOrderDates = (newItem) => {
    var dates = [];
    var currDate = moment(
      newItem?.first_dose,
      "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
    ).startOf("day");
    var lastDate = moment(newItem?.first_dose, "YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      .add(newItem?.duration_num, newItem?.duration_unit.toLowerCase())
      .startOf("day");
    while (currDate.isBefore(lastDate, "day")) {
      dates.push(currDate.clone());
      currDate.add(1, "days");
    }
    let newData = [];
    dates.forEach((o, i) => {
      if (i === 0) {
        let fromTime = moment(
          newItem?.first_dose,
          "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
        ).format("DD-MM-YYYY hh:mm A");
        let toTime = `${moment(
          newItem?.first_dose,
          "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
        ).format("DD-MM-YYYY")} 11:59 PM`;
        let newTimeArray = getDoseAndTimeIntarval(
          newItem?.frequency,
          fromTime,
          toTime
        );
        newData.push({
          date: o,
          timeList: newTimeArray?.timeList,
        });
      } else {
        let prevList = newData?.[i - 1];
        let prevDate = prevList?.date?.format("DD-MM-YYYY");
        let fromTime = `${prevDate} ${
          prevList?.timeList?.[prevList?.timeList?.length - 1]
        }`;

        let toTime = `${moment(o, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
          "DD-MM-YYYY"
        )} 11:59 PM`;

        let newTimeArray = getDoseAndTimeIntarval(
          newItem?.frequency,
          fromTime,
          toTime
        );
        let timeListData = newTimeArray?.timeList;
        timeListData?.shift();
        newData.push({
          date: o,
          timeList: timeListData,
        });
      }
    });
    return newData;
  };
  const getDoseAndTimeIntarval = (frequency, fromTime, toTime) => {
    let intarvalTime = 5;
    let intarvalType = "minutes";
    switch (frequency) {
      case "q5m":
        intarvalTime = 5;
        intarvalType = "minutes";
        break;
      case "q15m":
        intarvalTime = 15;
        intarvalType = "minutes";
        break;
      case "q30m":
        intarvalTime = 30;
        intarvalType = "minutes";
        break;
      case "q2h":
        intarvalTime = 2;
        intarvalType = "hours";
        break;
      case "q4h":
        intarvalTime = 4;
        intarvalType = "hours";
        break;
      case "q6h":
        intarvalTime = 6;
        intarvalType = "hours";
        break;
      case "q8h":
        intarvalTime = 8;
        intarvalType = "hours";
        break;
      case "q12h":
        intarvalTime = 12;
        intarvalType = "hours";
        break;
      case "q24h":
        intarvalTime = 24;
        intarvalType = "hours";
        break;
      case "daily":
        intarvalTime = 1;
        intarvalType = "days";
        break;
      case "daily at bedtime":
        intarvalTime = 24;
        intarvalType = "hours";
        break;
      case "BID":
        intarvalTime = 12;
        intarvalType = "hours";
        break;
      case "TID":
        intarvalTime = 8;
        intarvalType = "hours";
        break;
      case "QID":
        intarvalTime = 6;
        intarvalType = "hours";
        break;
      case "every other day":
        intarvalTime = 1;
        intarvalType = "days";
        break;
      case "once":
        intarvalTime = 1;
        intarvalType = "days";
        break;

      default:
        break;
    }
    const startTime = moment(fromTime, "DD-MM-YYYY hh:mm A");
    const endTime = moment(toTime, "DD-MM-YYYY hh:mm A");
    const minutesArray = [];
    for (
      let currentMoment = startTime.clone();
      currentMoment.isBefore(endTime);
      currentMoment.add(intarvalTime, intarvalType)
    ) {
      minutesArray.push(currentMoment.format("hh:mm A"));
    }
    return { timeList: minutesArray };
  };
  const getDateRange = (first_dose, duration_num, duration_unit) => {
    // Parse the start date
    const startDate = new Date(first_dose);

    // Calculate the end date based on duration_unit and duration_num
    const endDate = new Date(startDate);
    switch (duration_unit) {
      case "Days":
        endDate.setDate(startDate.getDate() + duration_num);
        break;
      case "Weeks":
        endDate.setDate(startDate.getDate() + 7 * duration_num);
        break;
      case "Months":
        endDate.setMonth(startDate.getMonth() + duration_num);
        break;
      case "Years":
        endDate.setFullYear(startDate.getFullYear() + duration_num);
        break;
      default:
        break;
    }

    // return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
    return `${moment(startDate).format("MMM DD")} - ${moment(endDate).format(
      "MMM DD"
    )}`;
  };
  const handleSelectDose = async (type, data, iDate, iTime) => {
    const response = await dispatch(
      updateMedicationReportStatus({
        order_id: data?._id,
        item: data?.item,
        created_by: userDetail?._id,
        pet_id: petDetails?._id,
        date: moment(iDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        time: iTime,
        plan_status: type,
        createdDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      })
    );
    if (response?.status) {
      medicationPlanList();
    } else {
      toast.error(response?.message, toasterConfig);
    }
  };
  const getDateTitle = (nDate) => {
    const fDate = nDate.format("DD-MM-YYYY");
    const today = moment().format("DD-MM-YYYY");
    const tomorrow = moment().add(1, "days").format("DD-MM-YYYY");
    const yesterday = moment().subtract(1, "days").format("DD-MM-YYYY");
    let prefix =
      fDate === today
        ? "Today"
        : fDate === yesterday
        ? "Yesterday"
        : fDate === tomorrow
        ? "Tomorrow"
        : nDate.format("dddd");
    return `${prefix}, ${nDate.format("MMM D YYYY")}`;
  };

  useEffect(() => {
    if (homeMedicationOrders?.length > 0) {
      setActivePlan(homeMedicationOrders?.[0]);
    }
  }, [homeMedicationOrders]);

  useEffect(() => {
    if (petDetails?._id) {
      setPageLoader(true);
      medicationPlanList();
    } else {
      setPageLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petDetails?._id]);

  useEffect(() => {
    setTimeout(() => {
      newRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }, 1000);
    if (activePlan?._id) {
      const addUnknownTimestamp = async () => {
        const filtersData = [];
        getOrderDates(activePlan)?.forEach((elem) => {
          const { date, timeList } = elem;
          timeList?.forEach((time) => {
            const mergeDateTime = moment(
              `${date.format("YYYY-MM-DD")} ${time}`
            );
            const dateTime = moment(`${date.format("YYYY-MM-DD")} ${time}`);
            const newmergeDateTime = dateTime.add(72, "hours");
            const currentDate = moment();
            const diffInHours = currentDate?.diff(mergeDateTime, "hours");
            const isUnknownValue = diffInHours >= 72;

            const MRreportData = activePlan?.medician_reports?.find(
              (o) =>
                o?.date === date.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") &&
                o?.time === time
            );

            if (
              isUnknownValue &&
              !MRreportData?.isUnknown &&
              !MRreportData?.plan_status
            ) {
              filtersData.push({
                order_id: activePlan?._id,
                item: activePlan?.item,
                created_by: userDetail?._id,
                pet_id: petDetails?._id,
                date: moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                unknownCreatedDate: moment(newmergeDateTime).format(
                  "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                ),
                time: time,
                isUnknown: true,
              });
            }
          });
        });

        if (filtersData?.length > 0) {
          const response = await dispatch(
            updateUnkownStatusData({ orders: filtersData })
          );
          if (response?.status) {
            medicationPlanList();
          }
        }
      };
      addUnknownTimestamp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePlan]);

  const {
    created_by: createdBy,
    item_details: itemDetails,
    frequency: frequencyVal,
    form: formVal,
    dose: doseVal,
  } = activePlan || {};
  let finalData = [];
  homeMedicationOrders?.forEach((e) => {
    let isAdded = false;
    if (
      filterData?.type === "all" ||
      filterData?.type === e?.item_details?.product_name
    ) {
      isAdded = e?.item_details?.product_name
        ?.toLowerCase()
        ?.includes(filterData?.searchText?.toLowerCase())
        ? true
        : false;
      if (startDate && endDate) {
        const sDate = moment(startDate);
        const eDate = moment(endDate);

        const givenDate = moment(e?.first_dose, "YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        if (givenDate.isBetween(sDate, eDate, null, "[]")) {
          isAdded = true;
        } else {
          isAdded = false;
        }
      }
    }

    if (isAdded) {
      finalData.push(e);
    }
  });

  return (
    <div id="medication-plans-container">
      {pageLoader ? (
        <div className="f-center h-100 w-100">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <div className="left-block">
            <div className="fa-center gap-2 mb-2">
              <div className="search-container">
                <IoIosSearch color="#788693" className="search-i" />
                <input
                  type="text"
                  placeholder="Search"
                  value={filterData?.searchText}
                  onChange={(e) => {
                    setFilterData((prev) => {
                      return { ...prev, searchText: e?.target?.value };
                    });
                  }}
                />
              </div>
              <div className="dropdown-block">
                <select
                  value={filterData.type}
                  onChange={(e) => {
                    setFilterData((prev) => {
                      return { ...prev, type: e?.target?.value };
                    });
                  }}
                >
                  <option value="all">All Reports</option>
                  {homeMedicationOrders?.map((elm, index) => {
                    return (
                      <option
                        value={elm?.item_details?.product_name}
                        key={index}
                      >
                        {elm?.item_details?.product_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="date-picker-blc">
                <DatePicker
                  showIcon
                  placeholderText="Select Date"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                />
              </div>
            </div>
            {finalData?.length === 0 ? (
              <NoDataFound type={filterData?.searchText !== "" ||
              filterData?.type !== "all" || startDate || endDate ? 'search result': ''} />
            ) : (
              <div className="bg-E4E4E447 cp-10 d-flex gap-2 mb-1 flex-wrap">
                {finalData?.map((elm, index) => {
                  return (
                    <div
                      key={index}
                      className="mw-250 bg-ffff cp-10 br-10 cursor-pointer"
                      onClick={() => {
                        setActivePlan(elm);
                      }}
                    >
                      <div className="text-16-400 color-2E65">
                        {elm?.item_details?.product_name}
                      </div>
                      <div className="text-13-400 color-8C9F mt-2 mb-2">
                        {getDateRange(
                          elm?.first_dose,
                          elm?.duration_num,
                          elm?.duration_unit
                        )}
                      </div>
                      <div className="medication-plan-item bg-ffff p-0">
                        <div className="chart-blc d-flex align-items-center mb-1">
                          <div className="chart-item">
                            <CircularProgressbarWithChildren
                              styles={buildStyles({
                                rotation: 0.5,
                                pathColor: "#11CA9D",
                                textColor: "#f88",
                                trailColor: "inherit",
                              })}
                              strokeWidth={10}
                              value={getPercentComplete(elm)}
                            >
                              <div
                                style={{
                                  fontSize: 10,
                                  fontWeight: "bold",
                                  padding: "7px 0px",
                                  backgroundColor: "#ececec",
                                  borderRadius: 50,
                                  textAlign: "center",
                                  width: 35,
                                }}
                              >
                                {("  " + getPercentComplete(elm)).slice(-3)}%
                              </div>
                            </CircularProgressbarWithChildren>
                          </div>

                          <div className="medication-profilee">
                            <h4>{elm?.status}</h4>
                            <p className="bg-E4E4 color-2E65 text-13-400 br-2 ps-1 pe-1 mt-1">
                              Medication
                            </p>
                          </div>
                        </div>
                        <div className="fa-center gap-2 mt-2">
                          <div className="f-center bg-eeee color-cfcf h-20 w-20 rounded-circle">
                            {elm?.created_by?.user_image ? (
                              <img
                                src={elm?.created_by?.user_image}
                                alt="badge-img"
                              />
                            ) : (
                              <span className="material-symbols-outlined text-14-400">
                                person
                              </span>
                            )}
                          </div>
                          <div className="text-13-500 color-2E65">
                            {elm?.created_by?.title}{" "}
                            {elm?.created_by?.firstname}{" "}
                            {elm?.created_by?.lastname}
                          </div>
                          <div className="flex-grow-1 d-flex justify-content-end me-2">
                            <img src={autoLoad} alt="auoLoad" />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {activePlan && (
            <div className="right-block">
              <div className="text-18-600 color-2E65 mb-1">
                Currently Active Plan
              </div>
              <div className="bg-E4E4E447 cp-20 h-100 overflow-auto me-1">
                <div className="text-20-700 color-2E65">Medication Report</div>
                <div className="fa-center gap-2 mt-1">
                  <div className="f-center bg-eeee color-cfcf h-20 w-20 rounded-circle">
                    {createdBy?.user_image ? (
                      <img src={createdBy?.user_image} alt="badge-img" />
                    ) : (
                      <span className="material-symbols-outlined text-14-400">
                        person
                      </span>
                    )}
                  </div>
                  <div className="text-13-400 color-2E65">
                    Dr. {createdBy?.firstname} {createdBy?.lastname}
                  </div>
                  <div className="medication-plan-item">
                    <div className="chart-blc d-flex align-items-center mb-1">
                      <div className="chart-item">
                        <CircularProgressbarWithChildren
                          styles={buildStyles({
                            rotation: 0.5,
                            pathColor: "#11CA9D",
                            textColor: "#f88",
                            trailColor: "inherit",
                          })}
                          strokeWidth={10}
                          value={getPercentComplete(activePlan)}
                        >
                          <div
                            style={{
                              fontSize: 10,
                              fontWeight: "bold",
                              padding: "7px 0px",
                              backgroundColor: "#ececec",
                              borderRadius: 50,
                              textAlign: "center",
                              width: 35,
                            }}
                          >
                            {("  " + getPercentComplete(activePlan)).slice(-3)}%
                          </div>
                        </CircularProgressbarWithChildren>
                      </div>

                      <div className="medication-profilee">
                        <h4>{itemDetails?.product_name}</h4>
                        <p>
                          {getDateRange(
                            activePlan?.first_dose,
                            activePlan?.duration_num,
                            activePlan?.duration_unit
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="medication-list-container mt-3">
                  {getOrderDates(activePlan)?.map((elem, i) => {
                    const { date, timeList } = elem;
                    // const timeArray = getDoseAndTimeIntarval(
                    //   activePlan?.frequency,
                    //   i === 0 ? activePlan?.first_dose : ""
                    // );
                    const totalTaken = activePlan?.medician_reports?.filter(
                      (o) =>
                        o.plan_status === "taken" &&
                        o?.date === date.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                    );

                    return (
                      <div key={i}>
                        <div className="fb-center">
                          <div className="text-16-500 color-2E65">
                            {getDateTitle(date)}
                          </div>
                          <div>{`${totalTaken?.length}/${timeList?.length}`}</div>
                        </div>
                        {timeList?.map((elem, index) => {
                          const mergeDateTime = moment(
                            `${date.format("YYYY-MM-DD")} ${elem}`
                          );
                          const currentDate = moment();
                          const diffInHours = currentDate?.diff(
                            mergeDateTime,
                            "hours"
                          );
                          const isUnknown = diffInHours > 72;
                          let isEditable = true;
                          let isView = false;
                          const cDate = moment().format("DD-MM-YYYY");
                          const cTime = moment();
                          let nextElem = timeList?.[index + 1] || "11:59 PM";
                          if (cDate === date.format("DD-MM-YYYY")) {
                            const startTime = moment(elem, "hh:mm A");
                            const endTime = moment(nextElem, "hh:mm A");
                            if (cTime.isBetween(startTime, endTime)) {
                              //isEditable = true;
                              isView = true;
                            }
                          }

                          const findObject = activePlan?.medician_reports?.find(
                            (o) =>
                              o?.date ===
                                date.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") &&
                              o.time === elem
                          );
                          let isTaken = false;
                          let userFirstName,
                            userLastName,
                            createdDate,
                            notesValue;
                          if (findObject?._id) {
                            isTaken = findObject?.plan_status === "taken";
                            isEditable = false;
                            userFirstName =
                              findObject?.added_by?.[0]?.firstname;
                            userLastName = findObject?.added_by?.[0]?.lastname;
                            createdDate = moment(findObject?.createdDate)
                              .utc()
                              .format("MMM DD YYYY hh:mm a");
                            notesValue = findObject?.note;
                          }
                          const isTimeVisible = [
                            "q5m",
                            "q15m",
                            "q30m",
                            "q2h",
                            "q4h",
                            "q6h",
                            "q8h",
                            "q12h",
                            "BID",
                            "TID",
                            "QID",
                          ].includes(activePlan.frequency);
                          return (
                            <div
                              className="medication-list-wrapper my-3"
                              key={index}
                            >
                              <div
                                className={
                                  isTimeVisible ? "fb-center" : "d-none"
                                }
                              >
                                <div className="d-flex gap-2">
                                  <h4>{elem}</h4>
                                  <h5 className="color-5129 text-15-600">
                                    {findObject?.isUnknown &&
                                      `System Marked Unknown at ${moment(
                                        findObject?.unknownCreatedDate,
                                        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                      ).format("MMM DD YYYY hh:mm a")}`}
                                  </h5>
                                </div>
                                {/* <p className="text-14-400 color-8693">
                                  {index + 1}
                                </p> */}
                              </div>
                              {isView && <div ref={newRef} />}
                              {isEditable ? (
                                <div className="fb-center bg-eef2 br-10 cp-10 mt-2">
                                  <div className="w-100">
                                    <div className="text-16-400 color-2E65">
                                      {itemDetails?.product_name} ({doseVal}{" "}
                                      {formVal})
                                    </div>

                                    <div
                                      className="d-flex align-items-center justify-content-between mt-2"
                                      style={{ width: "14rem" }}
                                    >
                                      <div className="d-flex align-items-center gap-1">
                                        <div>
                                          <BsCapsule color="#11CA9D" />
                                        </div>
                                        <div className="text-13-400 color-2E65 name-block">
                                          {frequencyVal}{" "}
                                          {itemDetails?.product_name} ({doseVal}{" "}
                                          {formVal})
                                        </div>
                                      </div>
                                      {!isUnknown && (
                                        <div className="d-flex align-items-center gap-2">
                                          <img
                                            src={redCancel}
                                            alt="cancel"
                                            className="h-18 w-18 cursor-pointer"
                                            onClick={() => {
                                              handleSelectDose(
                                                "not taken",
                                                activePlan,
                                                date,
                                                elem
                                              );
                                            }}
                                          />
                                          <img
                                            src={greenCheck}
                                            alt="check"
                                            className="h-18 w-18 cursor-pointer"
                                            onClick={() => {
                                              handleSelectDose(
                                                "taken",
                                                activePlan,
                                                date,
                                                elem
                                              );
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <ul className="ps-4 outer-list-items position-relative">
                                  <li className="list-outer position-relative">
                                    <span
                                      className={
                                        isTaken
                                          ? "taken-steps mt-1"
                                          : "not-taken-steps mt-1"
                                      }
                                    >
                                      {isTaken ? "Taken" : "Not Taken"}
                                    </span>
                                    <ul className="child-listt mt-2">
                                      <li className="d-flex align-items-center">
                                        <BsCapsule
                                          color={isTaken ? "#11CA9D" : ""}
                                        />
                                        <span className="ms-2">
                                          {!isTaken && "Not Given by "}
                                          {!isTaken &&
                                            userFirstName &&
                                            userFirstName}{" "}
                                          {!isTaken &&
                                            userLastName &&
                                            userLastName}{" "}
                                          {!isTaken &&
                                            userFirstName &&
                                            userLastName &&
                                            "on"}{" "}
                                          {!isTaken && createdDate}
                                          {/* {frequencyVal}{" "}
                                          {itemDetails?.product_name} ({doseVal}{" "}
                                          {formVal}) */}
                                        </span>
                                      </li>
                                    </ul>
                                    <span className="taken-steps mt-1">
                                      {isTaken ? "Given by " : ""}
                                      {isTaken &&
                                        userFirstName &&
                                        userFirstName}{" "}
                                      {isTaken && userLastName && userLastName}{" "}
                                      {isTaken &&
                                        userFirstName &&
                                        userLastName &&
                                        "on"}{" "}
                                      {isTaken ? createdDate : ""}
                                    </span>
                                    {notesValue && (
                                      <span className="taken-steps mt-1">
                                        {`Note: ${
                                          notesValue
                                            ? titleCaseString(notesValue)
                                            : "-"
                                        }`}
                                      </span>
                                    )}
                                  </li>
                                </ul>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MedicationPlans;
