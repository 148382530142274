import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  updateInventoryPackage,
  addPackageToInventory,
  getInventoryDetailsById,
} from "../../../../context/actions/inventory";
import {
  Button,
  Table,
  FormGroup,
  Input,
  InputGroup,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import { packageInit, packageSchema } from "./schema";
import moment from "moment";
import SwitchCell from "../../../../shared/SwitchCell";
import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const PackageInfoBlock = ({ className = "", addNew, toggleAddFn }) => {
  const dispatch = useDispatch();

  const params = useParams();
  const inventoryId = params?.inventoryId;

  const [selectedPackage, setSelectedPackage] = useState();

  const {
    inventoryDetails: { packages },
    inventoryDetails,
  } = useSelector((state) => state.inventoryReducer);

  const { permissionsData } = useSelector((state) => state.clinicPermissionReducer);
  const permissions = permissionsData;
  //   const { loading } = useSelector((state) => state.appContentReducer);
  //   console.log(loading);

  const handleSubmit = (values) => {
    values.expiration_date = moment(values?.expiration_date, 'YYYY-MM-DD').toDate();
    values.manufacture_date = moment(values?.manufacture_date, 'YYYY-MM-DD').toDate();
    if (selectedPackage) {
      values.package_id = values._id;
      dispatch(
        updateInventoryPackage({ ...values }, (res) => {
          handleGetInventoryDetails();
        })
      );
    } else {
      dispatch(
        addPackageToInventory(
          { ...values, inventory_id: inventoryDetails?._id },
          (res) => {
            handleGetInventoryDetails();
          }
        )
      );
    }
  };

  const handleGetInventoryDetails = () => {
    dispatch(
      getInventoryDetailsById(inventoryId, {}, (res) => {
        // console.log("detail",res,inventoryDetails,productList)
      })
    );
  };

  const setPackageEditing = (item) => {
    item.expiration_date = moment(item?.expiration_date).format("YYYY-MM-DD");
    item.manufacture_date = moment(item?.manufacture_date).format("YYYY-MM-DD");
    setSelectedPackage(item);
    toggleAddFn(true);
  };

  const postSubmit = (values, resetForm) => {
    handleSubmit(values);
    resetForm(packageInit);
    setSelectedPackage(undefined);
    toggleAddFn();
  };

  return (
    <>
      <div className={`package-info-blc ${className}`}>
        <div className="table-listing-blc overflow-auto">
          <Modal isOpen={addNew} className="max-w-[700px] w-full">
            <ModalHeader>
              <p className="text-xl font-bold">
                {selectedPackage ? "Edit" : "Add"} Package
              </p>
              <Button
                color="link"
                onClick={() => {
                  setSelectedPackage(undefined);
                  toggleAddFn();
                }}
                className="modal-"
              >
                x
              </Button>
            </ModalHeader>
            <ModalBody>
              <div className="flex flex-col p-4 ">
                <Formik
                  initialValues={selectedPackage ?? packageInit}
                  enableReinitialize
                  validationSchema={packageSchema}
                  onSubmit={(vals, { resetForm, errors }) => {
                    postSubmit(vals, resetForm);
                  }}
                >
                  {(formik) => (
                    <Form>
                      <div className="flex flex-col gap-3">
                        {/* <div className="row-block flex items-center ">
                          <label className="required-input w-36 mr-4">
                            Unit of measure
                          </label>
                          <div className="right-col flex-fill">
                            <Input
                              bsSize="sm"
                              value={formik?.values?.unit_of_measure}
                              onChange={(e) => formik.handleChange(e)}
                              name="unit_of_measure"
                              placeholder="Unit"
                              type="select"
                            >
                              <option value="undefined">Choose Unit</option>
                              <option>mL</option>
                              <option>Vial</option>
                            </Input>
                          </div>
                        </div> */}

                        <div className="row-block flex items-center">
                          <label className="required-input w-36 mr-4">
                            Lot Number
                          </label>
                          <div className="right-col flex-fill">
                            <Input
                              bsSize="sm"
                              value={formik?.values?.lot_number}
                              onChange={(e) => formik.handleChange(e)}
                              name="lot_number"
                            />
                          </div>
                        </div>

                        {/* <div className="row-block flex items-center">
                          <label className=" w-36 mr-4">Sub Package</label>
                          <div className="right-col flex-fill">
                            <InputGroup>
                              <Input
                                bsSize="sm"
                                className={"w-[70%]"}
                                value={formik?.values?.sub_package?.amount}
                                onChange={(e) => formik.handleChange(e)}
                                name="sub_package.amount"
                                type="number"
                                placeholder="Amount"
                              />
                              <Input
                                bsSize="sm"
                                className={"w-[30%]"}
                                value={
                                  formik?.values?.sub_package?.unit_of_measure
                                }
                                onChange={(e) => formik.handleChange(e)}
                                name="sub_package.unit_of_measure"
                                placeholder="Unit"
                                type="select"
                              >
                                <option value="undefined">Choose Unit</option>
                                <option value="mL">mL</option>
                                <option value="L">L</option>
                              </Input>
                            </InputGroup>
                            {formik.errors.sub_package?.unit_of_measure && (
                              <span className="text-sm text-red-500">
                                {formik.errors.sub_package?.unit_of_measure}
                              </span>
                            )}
                          </div>
                        </div> */}

                        <div className="row-block flex items-center">
                          <label className="required-input w-36 mr-4">
                            Quantity
                          </label>
                          <div className="right-col flex-fill">
                            <Input
                              bsSize="sm"
                              value={formik?.values?.quantity}
                              onChange={(e) => formik.handleChange(e)}
                              placeholder="Quantity"
                              name="quantity"
                              type="number"
                            />
                          </div>
                        </div>

                        <div className="row-block flex items-center">
                          <label className="required-input w-36 mr-4">
                            Manufacture Date
                          </label>
                          <div className="right-col flex-fill">
                            <Input
                              bsSize="sm"
                              value={formik?.values?.manufacture_date}
                              onChange={(e) => formik.handleChange(e)}
                              name="manufacture_date"
                              type="date"
                            />
                          </div>
                        </div>

                        <div className="row-block flex items-center">
                          <label className="required-input w-36 mr-4">
                            Expiration Date
                          </label>
                          <div className="right-col flex-fill">
                            <Input
                              bsSize="sm"
                              value={formik?.values?.expiration_date}
                              onChange={(e) => formik.handleChange(e)}
                              name="expiration_date"
                              type="date"
                            />
                            {formik.errors.expiration_date && (
                              <span className="text-sm text-red-500">
                                {formik.errors.expiration_date}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="flex w-full justify-end">
                          <Button
                            size="sm"
                            className={"p-1"}
                            style={{ width: "85px" }}
                            type="submit"
                            color="success"
                          >
                            Save
                            {/* {loading ? (
                              <FontAwesomeIcon
                                icon={faSpinner}
                                className="animate-spin"
                              />
                            ) : (
                              "Save"
                            )} */}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </ModalBody>
          </Modal>

          {(packages?.length > 0 || addNew) && (
            <Table striped responsive>
              <thead>
                <tr>
                  {/* <th className="bg-transparent" style={{ textWrap: "wrap" }}>
                    Unit of measure
                  </th> */}
                  <th className="bg-transparent" style={{ textWrap: "wrap" }}>
                    Lot Number
                  </th>
                  {/*<th className="bg-transparent" style={{ minWidth: '120px' }}>SKU</th>*/}
                  {/* <th
                    className="bg-transparent"
                    style={{ minWidth: "250px", textWrap: "wrap" }}
                  >
                    Sub Package
                  </th> */}
                  <th
                    className="bg-transparent"
                    style={{ minWidth: "130px", textWrap: "wrap" }}
                  >
                    Quantity
                  </th>
                  <th
                    className="bg-transparent"
                    style={{ minWidth: "130px", textWrap: "wrap" }}
                  >
                   Available Quantity
                  </th>
                  <th
                    className="bg-transparent"
                    style={{ minWidth: "130px", textWrap: "wrap" }}
                  >
                    Manufacturing Date
                  </th>
                  <th
                    className="bg-transparent"
                    style={{ minWidth: "130px", textWrap: "wrap" }}
                  >
                    Expiration Date
                  </th>
                  <th className="bg-transparent" style={{ minWidth: "130px" }}>
                    Status
                  </th>
                  <th className="bg-transparent text-end">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {(packages || []).map((item, i) => (
                  <tr key={i}>
                    {/* <td>{item?.unitDetails?.unit}</td> */}
                    {/* <td>{item?.unit_of_measure}</td> */}
                    <td>{item?.lot_number}</td>
                    {/* <td>
                      {item?.sub_package?.amount
                        ? `${item?.sub_package?.amount} ${item?.sub_package?.unit_of_measure}`
                        : "--"}
                    </td> */}
                    <td>{item?.quantity}</td>
                    <td>{item?.remaining_stock}</td>
                    <td>
                      {moment(item?.manufacture_date).format("MM/DD/YYYY")}
                    </td>
                    <td>
                      {moment(item?.expiration_date).format("MM/DD/YYYY")}
                    </td>
                    <td>
                      <SwitchCell
                        labelVisible={true}
                        className={item.stock_status ? "" : "disable"}
                        label={item.stock_status ? "In-Stock" : "Out-Stock"}
                      />
                    </td>

                    <td style={{textAlign: 'end'}} className="action-cell">
                      {permissions?.edit_products &&
                        <Button
                          color="link"
                          className="edit-btn"
                          onClick={() => setPackageEditing(item)}
                        >
                          <span className="icomoon-pencil"></span>
                        </Button>
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    </>
  );
};

export default PackageInfoBlock;
