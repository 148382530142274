import React, { useEffect, useState } from "react";
import WaitingRoomList from "./WaitingRoomList";
import {

  Button,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import NodataFoundBlock from "../../../../shared/NoDataFoundBlock";
import AddOr from "./AddOr";
import OperatingRoomList from "./OperatingRoomList";
import KennelsList from "./Kennels";
import AddKennel from "./AddKennel";

import AddWaitingRoom from "./AddWaitingRoom";


const Configurations = () => {
  const [dropdownOpen, setOpen] = useState(false);
  const [newPointPanelState, setNewPointPanelState] = useState(false);
  const [addNewKennel, setAddNewKennel] = useState(false);
  const [isAddWaitingRoom, setIsAddWaitingRoom] = useState(false);
  const [editData, setEditData] = useState(null);


  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );



  const [selectedOperatingRoom, setSelectedOperatingRoom] = useState(null);

  useEffect(() => {
    if (selectedOperatingRoom) {
      setNewPointPanelState(true);
    }
  }, [selectedOperatingRoom]);

  const [selectedKennel, setSelectedKennel] = useState(null);
  const [selectedWaitingRoom, setSelectedWaitingRoom] = useState(null);

  const [editColor, setEditColor] = useState(false);
  const [editDuration, setEditDuration] = useState(false);
  const [editReminderTimes, setEditReminderTimes] = useState(false);
 

  const [editTasks, setEditTasks] = useState(false);


  useEffect(() => {
    if (selectedKennel) {
      setAddNewKennel(true);
    }
  }, [selectedKennel]);

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData || localStorage.getItem("permission");

  return (
    <>
      {!clinic_settings ? (
        <NodataFoundBlock />
      ) : (
        <div className="telehealth-tab">
          <div className="tab-row d-flex">
            <div className="tab-col lft">
              <div className="info-block">
                <h3>Waiting Rooms</h3>
              </div>
              <div className="status-rw d-flex align-items-center">
                <span className="swtich-cell2 d-inline-flex align-items-center">
                  <span class="icomoon-check icon"></span>
                  <span className="d-inline-flex rounded-circle circle-icon ms-auto"></span>
                </span>
                Active
              </div>
            </div>
            <div className="tab-col flex-fill">
              <div>
                <WaitingRoomList
                  handleEdit={(e) => {
                    setIsAddWaitingRoom(true);
                    setEditData(e)
                  }}
                  isAddWaitingRoom={isAddWaitingRoom}
                  setSelectedWaitingRoom={setSelectedWaitingRoom}

                />
              </div>
              {permissions?.edit_operating_rooms && (
                <Button
                  color="link"
                  className="add-note-btn d-flex justify-content-center"
                  onClick={() => {
                    setIsAddWaitingRoom(true);
                  }}
                >
                  <span className="icomoon-plus-btn"></span>
                </Button>
              )}
            </div>
          </div>
          {permissions?.view_operating_rooms && (
            <div className="tab-row d-flex">
              <div className="tab-col lft">
                <div className="info-block">
                  <h3>Operating Rooms</h3>
                </div>
              </div>
              <div className="tab-col flex-fill">
                <div>
                  <OperatingRoomList
                    setSelectedOperatingRoom={setSelectedOperatingRoom}
                    newPointPanelState={newPointPanelState}
                  />
                </div>
                {permissions?.edit_operating_rooms && (
                  <Button
                    color="link"
                    className="add-note-btn d-flex justify-content-center"
                    onClick={() => setNewPointPanelState(true)}
                  >
                    <span className="icomoon-plus-btn"></span>
                  </Button>
                )}
              </div>
            </div>
          )}

          <div className="tab-row d-flex">
            <div className="tab-col lft">
              <div className="info-block">
                <h3>Kennels</h3>
              </div>
            </div>
            <div className="tab-col flex-fill">
              <div>
                <KennelsList
                  addNewKennel={addNewKennel}
                  setSelectedKennel={setSelectedKennel}
                />
              </div>
              <Button
                color="link"
                className="add-note-btn d-flex justify-content-center"
                onClick={() => setAddNewKennel(true)}
              >
                <span className="icomoon-plus-btn"></span>
              </Button>
            </div>
          </div>
        </div>
        )}



          

      <div
        className={`new-point-panel-overlay position-fixed ${newPointPanelState ||
          addNewKennel ||
          editColor ||
          editDuration ||
          editTasks ||
          editReminderTimes
          ? "active"
          : ""
          }`}
      ></div>
      <AddOr
        closeNewPointPanel={() => {
          setNewPointPanelState(false);
          setSelectedOperatingRoom(null);
        }}
        className={`${newPointPanelState ? "active" : ""}`}
        selectedOperatingRoom={selectedOperatingRoom}
        setSelectedOperatingRoom={setSelectedOperatingRoom}
      />

      

     

      <AddWaitingRoom
        editData={editData}
        closeNewPointPanel={() => {
          setIsAddWaitingRoom(false);
          setSelectedWaitingRoom(null)
          setEditData(null)
        }}
        className={`${isAddWaitingRoom ? "active" : ""}`}
        selectedWaitingRoom={selectedWaitingRoom}
        setSelectedWaitingRoom={setSelectedWaitingRoom}
      />

      <AddKennel
        closeNewPointPanel={() => {
          setAddNewKennel(false);
          setSelectedKennel(null);
        }}
        className={`${addNewKennel ? "active" : ""}`}
        selectedKennel={selectedKennel}
        setSelectedKennel={setSelectedKennel}
      />
    
    </>
  );
};

export default Configurations;
