const initState = {
  loading: false,
  petOwnerDoctorListData: [],
};

export const petOwnerDoctorListReducer = (state = initState, action) => {

  switch (action.type) {
    case "PET_OWNER_DOCTOR_LIST_LOADING":
      return { ...state, loading: action.payload };
    case "GET_PET_OWNER_DOCTOR_LIST":
      return {
        ...state,
        petOwnerDoctorListData: action.payload, 
      };
    default:
      return state;
  }
};
