import React, { useState } from "react";
import { Button, Table, FormGroup, Input, InputGroup } from "reactstrap";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import { ReactComponent as DeleteSvg } from '../../../assets/img/delete.svg'
import { ReactComponent as CheckSvg } from '../../../assets/img/check_circle.svg'
import './index.scss'
import SwitchCell from "../../../shared/SwitchCell";
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as CrossSvg } from "../../../assets/img/close-square-svgrepo-com.svg"
import * as Yup from "yup";
import { ReactComponent as PencilEditSvg } from "../../../assets/img/pencil-edit-button-svgrepo-com.svg"
import { Icons } from "utils/contants/Icons";


const DiscountBlock = ({ className = '', addNew, setAddDiscount, discounts, setDiscounts }) => {
	const [editVal, setEditVal] = useState(null);

	const handleSubmit = (values) => {
		setDiscounts((discountsPrev) => [...discountsPrev, { ...values, discount_type: values.discount_type ? "percentage" : "amount" }])
	}

	const itemSchema = Yup.object().shape({
		id: Yup.string().required(),
		discount_name: Yup.string().required(),
		// discount_type: Yup.string().required(),
		discount_percentage: Yup.number().required(),
	});

	let discountDefaultState = {
		id: uuidv4(),
		discount_name: "",
		discount_type: true,
		discount_percentage: ""
	}

	const removeDiscount = (e, id) => {
		e.preventDefault();
		let discountsUpdated = discounts.filter((ele) => ele.id !== id)
		setDiscounts(discountsUpdated)
	}

	console.log("discounts", discounts)



	return (
		<>
			<div className={`package-info-blc ${className}`}>
				{(discounts?.length > 0 || addNew) &&
					<div className="table-listing-blc overflow-auto">
						<Formik
							initialValues={editVal || discountDefaultState}
							enableReinitialize
							validationSchema={itemSchema}
							onSubmit={(vals, { resetForm }) => {
								if (editVal) {
									// Update the item in the lineItems array with the edited values
									const updatedLineItems = discounts.map((item) =>
										item.id === editVal.id ? vals : item
									);
									setDiscounts(updatedLineItems);
									setEditVal(null); // Clear the editItem state
								}
								else {
									handleSubmit(vals)
								}
					

								resetForm(discountDefaultState);
							}}>

							{(formik) => (
								<Form>
									<Table responsive className="table-striped">
										<thead>
											<tr>
												{/* <th className="bg-transparent">Type</th> */}
												<th className="bg-transparent" style={{ minWidth: '120px' }}>Name</th>
												<th className="bg-transparent" style={{ minWidth: '120px' }}>Value</th>
											</tr>
										</thead>
										<tbody>

											{addNew &&

												<tr>

													{/* <td>
														<Input value={formik?.values?.discount_type} onChange={(e) => formik.handleChange(e)} name="discount_type" placeholder="Discount Type" type="select">
															<option value={""} selected={true} disabled={true}>Please Select</option>
															<option value={"amount"}>Amount</option>
															<option value={"percentage"}>Percentage</option>
														</Input>
													</td> */}
													<td>

														<input className="inputName" value={formik?.values?.discount_name} onChange={(e) => formik.handleChange(e)} name="discount_name" placeholder="-Add Info-" />

													</td>
													<td>
														<div className="d-flex discount-button">
															<div
																id="discount_percentage"
																onClick={() => {
																	formik.setFieldValue("discount_type", !formik?.values.discount_type);
																}}
																className="message-type"
															>
																<span
																>
																	{formik?.values.discount_type === true ? "%" : "$"}
																</span>
																<Icons.DownArrow />
															</div>
															<input type="number" value={formik?.values?.discount_percentage} onChange={(e) => formik.handleChange(e)} name="discount_percentage" placeholder="-Add Info-" />
														</div>
													</td>


													{/* <td></td> */}
													<td style={{ textAlign: 'right' }}>
														<div className="flex items-center justify-end">
															<button className={'p-1'} style={{ width: '35px' }} size="sm" type="submit" color="success"><CheckSvg /></button>
															<button type="button" class="edit-btn btn btn-link" onClick={(e) => setAddDiscount(false)}><DeleteSvg /></button>

														</div>
													</td>


												</tr>
											}
											{
												(discounts || []).map((item, i) =>
													<tr key={i}>
														{/* <td>{item?.unitDetails?.unit}</td> */}
														<td style={{ textTransform: 'capitalize' }}>{item?.discount_type}</td>
														<td>
															{item?.discount_name}
														</td>
														<td>
															{item?.discount_type === 'amount' && '$'}
															{item?.discount_percentage?.toLocaleString("en-US", {
																minimumFractionDigits: 2,
															})}{item?.discount_type === 'percentage' && '%'}
														</td>

														<td>
															<div className="flex items-center justify-end">
																<button className={'p-1'} style={{ width: '35px' }} size="sm" type="button" onClick={() => {
																	setAddDiscount(true)
																	setEditVal(item)
																}}><PencilEditSvg fill="gray" width={18} height={18} /></button>
																<button type="button" class="edit-btn btn btn-link" onClick={(e) => removeDiscount(e, item.id)}><DeleteSvg /></button>

															</div>
														</td>
													</tr>

												)
											}
										</tbody>
									</Table>
								</Form>)}
						</Formik>
					</div>}
			</div>
		</>
	);
};

export default DiscountBlock;
