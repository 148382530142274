import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { addNewAppointment, getAppointmentDetails, updateAppointmentDetails } from "../../context/actions/appointment";
import { toasterConfig } from "../../utils/constants";
import LoaderUI from "../loader";
import AppointmentAccordion from "./AppointmentAccordion";
import AppointmentSteps from "./AppointmentSteps";
import { setAppointmentDetailData } from "context/actions/appointmentDetailPanelAction";
import { getAllHoliday } from "context/actions/holiday";

import "./index.scss";

const EditAppointmentSections = ({ patientData, userDetails, appointmentDetails }) => {
  const routeParams = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loader, showLoader] = useState(false);
  const [activeAccordianVal, setActiveAccordianVal] =
    useState("appointment_info");
  const [surgeryId, setSurgeryId] = useState("");
  const [directAppointment, setDirectAppointment] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [intakeForm, setIntakeForm] = useState([]);



  const [scheduleApptData, setScheduleApptData] = useState({
    doctor_id: "",
    appointment_date: "",
    appointment_time: [],
    slot_time: [],
  });

  const [appointmentInfo, setAppointmentinfo] = useState({
    appointment_type: "",
    reason: "",
    notes: "",
    condition: ""
  });



  useEffect(() => {

    if (appointmentDetails) {
      setAppointmentinfo({
        appointment_type: appointmentDetails?.appointment_type,
        reason: appointmentDetails?.reason,
        notes: appointmentDetails?.notes,
        description: appointmentDetails?.description
      })
      setAppointmentSlotDuration(
        clinic_settings.appointment_types_durations[
        appointmentDetails.appointment_type
        ]
      );

    }


    console.log(appointmentInfo, 'appointmentInfo')

  }, [appointmentDetails])


  useEffect(() => {

    console.log(appointmentDetails, 'appointmentDetails')

    if (appointmentDetails) {
      console.log('appointment details', appointmentDetails)
      setAppointmentinfo({
        appointment_type: appointmentDetails?.appointment_type,
        reason: appointmentDetails?.reason,
        notes: appointmentDetails?.notes,
        description: appointmentDetails?.description
      })
      setAppointmentSlotDuration(
        clinic_settings.appointment_types_durations[
        appointmentDetails.appointment_type
        ]
      );

    }
    dispatch(getAllHoliday("", () => {}));

  }, [])
  const [selectedOperatingRoomSlots, setSelectedOperatingRoomSlots] = useState([]);
  const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);


  const [appointmentSlotDuration, setAppointmentSlotDuration] = useState(30);


  const appointmentTypeChange = (e) => {
    setAppointmentinfo({
      ...appointmentInfo,
      appointment_type: e.target.value,
    });

    setAppointmentSlotDuration(
      clinic_settings.appointment_types_durations[
      e.target.value
      ]
    );
  };
  const appointmentReason = (e) => {
    setAppointmentinfo({ ...appointmentInfo, reason: e.target.value });
  };
  const appointmentCondition = (e) => {
    setAppointmentinfo({ ...appointmentInfo, condition: e.target.value });
  };
  const appointmentDescription = (e) => {
    setAppointmentinfo({ ...appointmentInfo, description: e.target.value });
  };

  const handleAddAppointment = () => {
    showLoader(true);
    if (appointmentInfo.appointment_type === "") {
      showLoader(false);
      toast.error("Appoitnment type is required", toasterConfig);
      return;
    } else if (!appointmentInfo.reason || appointmentInfo.reason.length === 0) {
      showLoader(false);
      toast.error("Appoitnment reason is required", toasterConfig);
      return;
    } else if (!scheduleApptData.doctor_id) {
      showLoader(false);
      toast.error("Doctor is required", toasterConfig);
      return;
    } else if (!scheduleApptData.appointment_date) {
      showLoader(false);
      toast.error("Appoitnment date is required", toasterConfig);
      return;
    }
    //  else if (!scheduleApptData.appointment_time) {
    //   showLoader(false);
    //   toast.error("Appoitnment time is required", toasterConfig);
    //   return;
    // } else if (!scheduleApptData.slot_time) {
    //   showLoader(false);
    //   toast.error("slot time is required", toasterConfig);
    //   return;
    // }

    else if (!scheduleApptData.appointment_time || scheduleApptData.appointment_time.length == 0) {
      showLoader(false);
      toast.error("Appoitnment time is required", toasterConfig);
      return;
    } else if (!scheduleApptData.slot_time || scheduleApptData.slot_time.length == 0) {
      showLoader(false);
      toast.error("slot time is required", toasterConfig);
      return;
    }

    else {
      const data = {
        created_by: userDetails._id,
        ///patient_id: appointmentDetails.petId,
        doctor_id: scheduleApptData.doctor_id,
        appointment_type: appointmentInfo.appointment_type,
        appointment_date: scheduleApptData.appointment_date,
        appointment_time: scheduleApptData.appointment_time,
        reason: appointmentInfo.reason,
        condition: appointmentInfo.condition,
        duration: appointmentSlotDuration,
        appointment_starts: scheduleApptData.slot_time,
        slot_time: scheduleApptData.slot_time,
        description: appointmentInfo?.description,
        // notes: (appointmentInfo?.notes || ''),
        selected_operating_rooms: selectedOperatingRoomSlots,
        override_existing_bookings: isActive,
        patient_intake_forms: intakeForm,

        // operating_room_id: operatingRoomId || ""
        //surgery_id: surgeryId,

      };
      if (surgeryId !== "") {
        data.surgery_id = surgeryId
      }
      dispatch(
        updateAppointmentDetails(appointmentDetails?._id, data, (result) => {
          if (result && !result.errors) {
            dispatch(getAppointmentDetails(routeParams.appointmentId), (res) => {
              if (res) dispatch(setAppointmentDetailData(res));
            })
            showLoader(false);
            history.push("/appointments");
          } else {
            showLoader(false);
          }
        })
      );
    }
  };

  return (
    <>
      {loader && <LoaderUI overlay />}
      <div className="add-appointment-sections d-flex flex-column overflow-auto">
        <div className="appointment-header d-flex align-items-center">
          <div className="left-col">
            Edit
            <span>Appointment</span>
          </div>
          <div className="right-col flex-fill d-flex align-items-center">
            <AppointmentSteps
              appointmentInfo={appointmentInfo}
              activeAccordianVal={activeAccordianVal}
              intakeForm={intakeForm}
              appointmentTime={scheduleApptData?.appointment_time || []}
            />
            <div className="btns-blc d-inline-flex align-items-center ms-auto">
              <Button
                color="link"
                className="btn-outline-light cancel-btn"
                onClick={() => history.push("/appointments")}
              >
                Cancel
              </Button>
              <Button
                color="success"
                className="book-btn"
                onClick={handleAddAppointment}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
        <div className="inner-content-wrapper overflow-auto">
          <AppointmentAccordion
            userDetails={userDetails}
            appointmentTypeChange={appointmentTypeChange}
            appointmentReason={appointmentReason}
            appointmentCondition={appointmentCondition}
            appointmentDescription={appointmentDescription}
            activeAccordian={(value) => setActiveAccordianVal(value)}
            doctorAppt={(value) => setScheduleApptData(value)}
            appointmentInfoProp={appointmentInfo}
            patientData={patientData}
            setSurgeryId={setSurgeryId}
            setSelectedOperatingRoomSlots={setSelectedOperatingRoomSlots}
            selectedOperatingRoomSlots={selectedOperatingRoomSlots}
            appointmentSlotDuration={appointmentSlotDuration}
            directAppt={0}
            setDirectAppointment={setDirectAppointment}
            setIsActive={setIsActive}
            intakeForm={intakeForm}
            setIntakeForm={setIntakeForm}
          />
        </div>
      </div>
    </>
  );
};

export default EditAppointmentSections;
