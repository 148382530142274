import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../../../api-client";
import { apiUrl, PORT } from "../../../../environment";
import { toasterConfig } from "../../../../utils/constants";

toast.configure();
/**
 * All Api endpoints to be call
 */

/**
 * @description function use to add appointment discharge patient form
 * @param {*} data accepting object
 */
export const createPdf = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/discharge-patient-form/create-pdf`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response?.status) {
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * @description function use to add appointment discharge patient form
 * @param {*} data accepting object
 */
export const AddNewDischargePatientForm = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/discharge-patient-form/add`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response?.status) {
        toast.dismiss();
        toast.success(response.message, toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * @description function use to update appointment discharge patient form
 * @param {*} data accepting object
 */
export const UpdateDischargePatientForm = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/discharge-patient-form/update`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response?.status) {
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};
export const uploadAttachment = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.postFormData(
      `${apiUrl}${PORT}/discharge-patient-form/add-treatment-plan-attachment`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response?.status) {
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};
export const deleteTreatmentAttachment  = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.put(
      `${apiUrl}${PORT}/discharge-patient-form/delete-attachment`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response?.status) {
        toast.dismiss();
        toast.success(response.message, toasterConfig);
        return callback(response);
      } else {
        return callback();
      }
    });
  };
};

/**
 * @description function use to fetch appointment discharge patient form
 * @param {*} data accepting object
 */
export const FetchAppointmentDischargePatientForm = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.get(
      `${apiUrl}${PORT}/discharge-patient-form/appointmentDichargePatientForm/${data.appointment_id}`,
      {},
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response?.status) {
        dispatch({
          type: "DISCHARGE_PATIENT_FORM_DATA",
          payload: response?.response_data,
        });
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * @description function use to fetch appointment discharge patient form
 * @param {*} data accepting object
 */
export const getAppointmentDischargePatientDetails = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.get(
      `${apiUrl}${PORT}/discharge-patient-form/get-details/${data.appointment_id}`,
      {},
      token,
      dispatch, getState
    ).then((response) => {

      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response?.status) {
        dispatch({
          type: "UPDATE_APPOINTMENT_AND_DISCHARGE_PATIENT_DETAILS",
          payload: response?.response_data,
        });

        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        return callback(response);
      } else {
        dispatch({
          type: "UPDATE_APPOINTMENT_AND_DISCHARGE_PATIENT_DETAILS",
          payload: null,
        });

        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};
