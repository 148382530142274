const initState = {
  loading: true,
  clinic_settings: false,
  rolePermissions: false,
  permissionLoading: true,
  formTemplates: false,
  clinicSpecies: [],
  clinicBreeds: []
};

export const clinicSettingsReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, loading: action.payload };

    case "GET_CLINIC_SETTINGS":
      return { ...state, clinic_settings: action.payload };

    case "PERMISSION_LOADING":
      return { ...state, permissionLoading: action.payload };

    case "GET_ROLE_PERMISSIONS":
      return { ...state, rolePermissions: action.payload };


    case "GET_ALL_CLINIC_SPECIES":
      return { ...state, clinicSpecies: action.payload };

    case "GET_ALL_CLINIC_BREEDS":
      return { ...state, clinicBreeds: action.payload };
  

    case "GET_FORM_TEMPLATES":
      return { ...state, formTemplates: action.payload };
    case "RESET_CLINIC_SETTINGS":
      return {
        loading: true,
        clinic_settings: false,
        rolePermissions: false,
        permissionLoading: true,
        formTemplates: false,
      };
    case "LOGOUT":
        return initState;
    default:
      return state;
  }
};
