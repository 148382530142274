import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { getSurgicalHistoryList } from "../../../../../../context/actions/patient-module/medical-history";
import UserPlaceholder from "../../../../../../shared/UserPlaceholder";
import { dateFromDateTime } from "../../../../../../utils/common";
import MedicalBreifBox from "../MedicalBreifBox";
import "./index.scss";
import NodataFoundBlock from "../../../../../../shared/NoDataFoundBlock";

const SurgicalHistoryBlock = ({ petId, add }) => {
  const { surgicalHistory } = useSelector(
    (state) => state.medicalHistoryReducer
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (petId) {
      dispatch(
        getSurgicalHistoryList(petId, {}, () => {
          setLoading(false);
        })
      );
    }
  }, [petId]);

  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );
  return (
    <>
      <div className="surgical-history-block">
        <div className="head section-head d-flex align-items-center">
          <div className="left-col">
            <h3>Surgical History</h3>
          </div>
          {/* <div className="right-col ms-auto d-inline-flex align-items-center">
						<Button onClick={() => add()} color="link" className="add-btn">
							<i className="icomoon-plus-btn"></i>
						</Button>
					</div> */}
        </div>
        <div className="inner ">
          <div className="surgical-items-rw d-flex overflow-auto">
            {surgicalHistory?.length >= 1 ? (
              (surgicalHistory || []).map((item, index) => {
                return (
                  <div key={index} className="item-col">
                    <MedicalBreifBox
                      data={{
                        title: item?.type,
                        description: item?.notes,
                        // nameWithTitle: `${item?.doctor_details?.title ? item?.doctor_details?.title + ' ' : ''} ${item?.doctor_details?.firstname} ${item?.doctor_details?.lastname}`,
                        nameWithTitle: `${
                          clinic_settings?.doctor_name_format === 0
                            ? `${
                                item?.doctor_details?.title
                                  ? item?.doctor_details?.title + " "
                                  : ""
                              } 
						
						 ${item?.doctor_details?.lastname}  ${" ,"} ${item?.doctor_details?.firstname}
						`
                            : `${
                                item?.doctor_details?.title
                                  ? item?.doctor_details?.title + " "
                                  : ""
                              } 
						
						${item?.doctor_details?.firstname} ${item?.doctor_details?.lastname}
						`
                        }`,
                        image: item?.doctor_details?.user_image,
                        date: dateFromDateTime(item?.date, "MM/DD/yyyy"),
                      }}
                    />
                  </div>
                );
              })
            ) : (
              <NodataFoundBlock />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(SurgicalHistoryBlock);
