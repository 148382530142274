import { getAllDoctors } from "context/actions/appointment";
import { addNewPetXrayReport } from "context/actions/patient-module/overview/report";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FormGroup,
  Input,
  FormFeedback,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Form,
} from "reactstrap";
import UploadBlock from "shared/UploadBlock";
const AddRadiology = ({ petId, currentDate, handleClose }) => {
  const dispatch = useDispatch();

  const { clinic_details } = useSelector((state) => state?.clinicReducer);
  const userDetails = useSelector((state) => state.user.user_details);

  let defaultInputState = {
    notes: "",
    added_by: userDetails?._id,
    description: "",
    x_ray_date: "",
    pet_id: petId,
    report_type: "",
    report_link: "",
    report_name: "",
  };
  const [inputs, setInputs] = useState(defaultInputState);
  const [images, setImages] = useState([]);
  const [imagesarr, setImagesarr] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [doctorsList, setDoctorsList] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(false);
  useEffect(() => {
    console.log(clinic_details, "report clinic details ");

    if (clinic_details?._id) {
      dispatch(
        getAllDoctors(clinic_details._id, {}, (result) => {
          if (result && !result.errors) {
            setDoctorsList(result);
          } else {
            setDoctorsList(false);
          }
        })
      );
    }
  }, [clinic_details]);

  const handleInputChange = (e) => {
    e.preventDefault();
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const radiologyResetForm = () => {
    setImageFiles([]);
    setImages([]);
    setImagesarr([]);
    setInputs(defaultInputState);
  };

  useEffect(() => {
    return () => {
      radiologyResetForm();
    };
  }, []);

  const createUpdatePetReport = () => {
    let formdata = new FormData();

    if (images && images.length > 0) {
      for (let image of images) {
        formdata.append("report", image, image.name);
      }
    }

    formdata.append("pet_id", petId);
    formdata.append("added_by", userDetails?._id);
    formdata.append("report_name", inputs.report_name);
    formdata.append("report_type", inputs.report_type);
    formdata.append("description", inputs.description);
    formdata.append("x_ray_date", inputs.x_ray_date);
    formdata.append("notes", inputs.notes);
    if (selectedDoctor) {
      console.log(selectedDoctor);
      formdata.append("requested_by", selectedDoctor);
    }

    dispatch(
      addNewPetXrayReport(formdata, petId, currentDate, (response) => {
        if (response && !response.errors) {
          radiologyResetForm();
          handleClose();
        } else {
        }
      })
    );
  };
  return (
    <div className="inner align-items-center mt-2">
      <Form>
        <FormGroup className="input-wrapper2 mb-2">
          {/* <div className="form-floating position-relative text-input"> */}
          <UploadBlock
            images={images}
            setImages={setImages}
            imagesarr={imagesarr}
            setImagesarr={setImagesarr}
            imageFiles={imageFiles}
            setImageFiles={setImageFiles}
            documentimages={null}
            documentname={null}
            accept={
              "*/dicom,.dcm, image/dcm, */dcm, .dicom, image/png, image/jpg, image/jpeg, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/gzip, application/zip, application/x-rar-compressed "
            }
            multiple={false}
          />
          {/* </div> */}
        </FormGroup>
        <FormGroup className="input-wrapper2 mb-2">
          <div className="form-floating position-relative text-input">
            <input
              type="text"
              className="form-control"
              id="report_name"
              name="report_name"
              placeholder="Name"
              value={inputs.report_name}
              onChange={handleInputChange}
            />
            <label htmlFor="report_name">Title</label>
          </div>
        </FormGroup>
        <FormGroup className="input-wrapper2 mb-2">
          <div className="form-floating position-relative text-input">
            <select
              className="form-control"
              id="report_type"
              name="report_type"
              placeholder="Report Type"
              value={inputs.report_type}
              onChange={handleInputChange}
            >
              <option value={""}>Select Radiology Type</option>
              <option>Ultrasound</option>
              <option>X-Ray</option>
              <option>MRI</option>
              <option>CT</option>
            </select>
            <label htmlFor="report_type">Radiology Type</label>
          </div>
        </FormGroup>

        <FormGroup className="input-wrapper2">
          <div className="form-floating position-relative text-input">
            <Input
              type="select"
              className="form-control"
              id={"doctor"}
              value={selectedDoctor}
              onChange={(evt) => setSelectedDoctor(evt?.target?.value)}
            >
              {doctorsList?.map((item, index) => {
                return (
                  <option
                    value={item?.user?._id}
                    key={index}
                  >{`${item?.user?.title} ${item?.user?.firstname} ${item?.user?.lastname}`}</option>
                );
              })}
            </Input>
            <label for={`doctor`}>{"Requested By"}</label>
          </div>
        </FormGroup>

        <FormGroup className="input-wrapper2 mb-2">
          <div className="form-floating position-relative text-input">
            <input
              type="text"
              className="form-control"
              id="description"
              name="description"
              placeholder="description"
              value={inputs.description}
              onChange={handleInputChange}
            />
            <label htmlFor="description">Description</label>
          </div>
        </FormGroup>

        <FormGroup className="input-wrapper2 mb-2">
          <div className="form-floating position-relative text-input">
            <input
              type="datetime-local"
              className="form-control"
              id="x_ray_date"
              name="x_ray_date"
              placeholder="X-Ray Date"
              value={inputs.x_ray_date}
              onChange={handleInputChange}
            />
            <label htmlFor="x_ray_date">Radiology Date</label>
          </div>
        </FormGroup>

        <div className="btn-blc border_btn mt-3 text-end">
          <button
            type="button"
            class="flex-fill btn border-btn"
            onClick={() => {
              handleClose();
              radiologyResetForm();
            }}
          >
            CANCEL
          </button>
          <Button
            color="success"
            onClick={() => {
              createUpdatePetReport();
            }}
            className="add-btn"
          >
            CREATE RADIOLOGY
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddRadiology;
