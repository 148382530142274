import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { icons } from "../../../../utils/superAdminIcons/constants";
// import clinicImg from "../../../../assets/img/superAdmin/images/logo.png";
import { editClinicLogo } from "../../../../context/actions/clinic";
import "./index.scss";
import { formatPhoneNumber } from "utils/common";

const ClinicCard = ({ data, setDeleteID, setEditData, handleSuccess }) => {
  const [cardData, setCardData] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  // const [imgURL, setImgURL] = useState(data?.clinicLogo);
  const handleIconChange = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let selectedFile = e.target.files?.[0];
    if (selectedFile) {
      let fileType = selectedFile?.type?.split("/")?.pop();
      if (["png", "jpg", "jpeg"].includes(fileType)) {
        let formData = new FormData();
        formData.append("brand_logo", selectedFile, selectedFile?.name);
        const response = await dispatch(editClinicLogo(data?._id, formData));
        if (response?.status) {
          handleSuccess();
        }
      }
    }
  };
  const handleProfileClick = (id) => {
    const path = `/super-admin/clinic-profile/${data?._id}`;
    history.push(path);
  };

  const handleDollarClick = () => {};
  const handleLockClick = () => {};
  useEffect(() => {
    setCardData(data);
  }, [data]);

  return (
    <div
      className="clinicCard-container"
      // onClick={() => {
      //   handleProfileClick(data?._id);
      // }}
    >
      <div className="fb-center flex-nowrap">
        <div className="position-relative">
          <img
            // onError={(e) => {
            //   setImgURL(clinicImg);
            // }}
            src={cardData?.clinicLogo}
            alt="clinic"
            className="rounded-circle w-20 aspect-ratio-1"
          />
          <label
            htmlFor={`getFile1_${data?._id}`}
            className="file_upload edit-icon-img shadow"
          >
            <img src={icons.editIcon} alt="edit" />
          </label>
          <input
            type="file"
            id={`getFile1_${data?._id}`}
            className="hide"
            onChange={handleIconChange}
            accept="image/png, image/jpg, image/jpeg"
          />
        </div>
        <div
          className="f-center flex-column"
          onClick={() => {
            handleProfileClick(data?._id);
          }}
        >
          <h3 className="text-xl font-semibold color-8693">
            {data?.staffCount}
          </h3>
          <p className="text-xs font-normal color-8693">Staff</p>
        </div>
        <div
          className="f-center flex-column"
          onClick={() => {
            handleProfileClick(data?._id);
          }}
        >
          <h3 className="text-xl font-semibold color-8693">
            {data?.petOwnerCount}
          </h3>
          <p className="text-xs font-normal color-8693">Pet Owners</p>
        </div>
      </div>
      <p
        className="text-xl font-semibold my-2"
        onClick={() => {
          handleProfileClick(data?._id);
        }}
      >
        {data?.clinic_name}
      </p>
      <p
        className="text-base font-normal mb-1"
        onClick={() => {
          handleProfileClick(data?._id);
        }}
      >
        {data?.email}
      </p>
      <p
        className="text-base font-normal mb-1"
        onClick={() => {
          handleProfileClick(data?._id);
        }}
      >
        {formatPhoneNumber(  data?.phone)}
      </p>
      <p
        className="text-base font-normal mb-1"
        onClick={() => {
          handleProfileClick(data?._id);
        }}
      >
        {data?.address ? data?.address : "99 Marinabay Road, United States"}
      </p>
      <div
        className="fa-center gap-3"
        onClick={() => {
          handleProfileClick(data?._id);
        }}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setDeleteID(data?._id);
          }}
        >
          <img src={icons.deleteIcon} alt="Delete" />
        </button>
        {/* <button onClick={handleDollarClick}>
          <img src={icons.dollarIcon} alt="Dollar" />
        </button>
        <button onClick={handleLockClick}>
          <img src={icons.lockIcon} alt="Lock" />
        </button> */}
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setEditData(data);
          }}
        >
          <img src={icons.editIcon} alt="Edit" />
        </button>
      </div>
    </div>
  );
};

export default ClinicCard;
