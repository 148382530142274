import React from "react";
import { AiFillAlipayCircle, AiFillDislike, AiFillLike, AiOutlineClockCircle } from "react-icons/ai";
import { TiVideoOutline } from "react-icons/ti";
import { Button, Input } from "reactstrap";
import UserPlaceholder from '../../../../../../shared/UserPlaceholder';
import "./index.scss";

const ChatBlock = () => {
	return (
		<>
			<div className="chat-block patient_chat_block">
				<div className="chat-room pe-2">
					<div className="bg-[#EBF5FF] flex  rounded-md py-[8px] px-[9px] justify-content-between mb-3" style={{ width: "100%" }}>
						<div className="flex flex-col gap-3">
							<p className="text-[14px] font-['Inter'] ">
								Rate doctor for the appointment
							</p>
							<h4 className="text-[22px] font-[600] font-['Inter'] ">
								Will you recommend this Doctor?
							</h4>

							<div className="flex gap-2">
								<button className="bg-[#fff] rounded-md px-3.5 py-1.5 flex justify-center items-center">
									<AiFillLike color="#11CA9D" size={24} />
									<span className="text-[18px] font-['Inter'] pl-2">Yes</span>
								</button>
								<button className="bg-[#fff] rounded-md px-3.5 py-1.5 flex justify-center items-center">
									<AiFillDislike color="#DE2B2B" size={24} />
									<span className="text-[18px] font-['Inter'] pl-2">No</span>
								</button>
							</div>
						</div>
						<div className="p-[6px]  h-fit w-[265px]  bg-[#Ffff] rounded-lg cursor-pointer">
							{/* <div className="w-full rounded-md bg-[#ffff]"> */}
							<div className="w-full">
								<div className="flex justify-between  items-start">
									<p className="flex items-center">
										<img className="w-[45px] h-[45px] mr-[4px] rounded-full bg-gray-200" src="/static/media/user-thumbnail.adef342b4bb503aac3bc.png" alt="User" />
										<div className="pl-2">
											<span className="font-['Inter'] font-[400] capitalize text-[16px]">
												Dr. Martha William
											</span>
											<span className="font-['Inter'] font-[400] capitalize text-[13px] d-block">
												DVM
											</span>
										</div>
									</p>
									<img
										src={require("../../../../../../assets/img/autoMode.png")}
										alt="auto"
										className="w-[17px] h-[17px] mt-2 mr-1"
									/>
								</div>
							</div>
							<div className="flex justify-start gap-2 mt-2">
								<p className="font-['Inter'] text-[#282E65] flex flex-col gap-[10px]">
									<span className=" text-[13px] flex items-center gap-[3px]">
										<AiOutlineClockCircle size={20} />
										09:00 am - 10:00 am
									</span>
									<span className="text-[14px] flex capitalize items-center gap-[3px]">
										<TiVideoOutline size={20} />
										Video Call Consultancy
									</span>
								</p>
							</div>
							{/* </div> */}
						</div>
					</div>
					<div className="chat-row">
						<div className="d-flex align-items-center">
							<div className="pic d-inline-flex rounded-circle overflow-hidden flex-shrink-0">
								<img className="img-fluid w-100 h-100" src={require('../../../../../../assets/img/vetRecorEMR/user-thumbnail.png')} alt="Audio" />
								{/* <UserPlaceholder /> */}
							</div>
							<span className="text-[#282E65] text-[14px] font-[500] font-['Inter'] mb-0">Artimis</span>
							<span className="chat-time text-[14px] mx-2 mb-0">06/20/2023 at 05:15 PM</span>
						</div>
						<div className="mt-1 chat-bubble position-relative d-inline-block align-items-center rightchat">
							Typesetting industry.
							<div className="media-blc">
								<img className="img-fluid" src={require('../../../../../../assets/img/vetRecorEMR/audio-clip.png')} alt="Audio" style={{ maxWidth: "300px" }} />
							</div>
						</div>
					</div>
					<div className="chat-row d-flex justify-content-end right-side-chat">
						<div className="info">
							<h4 className="d-flex">Dr. Ammy Rodes <span className="chat-time text-[14px] mx-2">06/20/2023 at 05:15 PM</span></h4>
							<div className="chat-bubble-rw d-flex flex-column align-items-end">
								<div className="chat-bubble">
									<p>Where can I get some?</p>
								</div>
							</div>
						</div>
					</div>
					<div className="chat-row">
						<div className="d-flex align-items-center">
							<div className="pic d-inline-flex rounded-circle overflow-hidden flex-shrink-0">
								<img className="img-fluid w-100 h-100" src={require('../../../../../../assets/img/vetRecorEMR/user-thumbnail.png')} alt="Audio" />
								{/* <UserPlaceholder /> */}
							</div>
							<span className="text-[#282E65] text-[14px] font-[500] font-['Inter'] mb-0">Artimis</span>
							<span className="chat-time text-[14px] mx-2 mb-0">06/20/2023 at 05:15 PM</span>
							<span className="chat_label_text mb-0">Suggestion</span>
						</div>
						<div className="mt-1 chat-bubble position-relative d-inline-block align-items-center rightchat">
							Typesetting industry.
							<div className=" ml-[7px] h-fit space-y-1.5 bg-[#Ffff] rounded-lg my-2 cursor-pointer suggesation_box1">
								<div className="w-full"><div className="flex justify-between  items-start">
									<p className="flex items-center">
										<img className="w-[20px] h-[20px] mr-[4px] rounded-full bg-gray-200" src="/static/media/user-thumbnail.adef342b4bb503aac3bc.png" alt="User" />
										<div className="pl-2">
											<span className="font-['Inter'] font-[400] capitalize text-[13px]">Dr. Martha William DVM</span>
										</div>
									</p>
								</div>
								</div>
								<div className="flex justify-start gap-2 mt-2">
									<p className="font-['Inter'] text-[#282E65] font-[400] text-[14px] gap-[10px]">I'm used to fact that in all messengers that I use, I can click the arrow icon "reply"</p>
								</div>
							</div>
						</div>
					</div>
					<div className="chat-row d-flex justify-content-end right-side-chat">
						<div className="info">
							<h4 className="d-flex">Dr. Ammy Rodes <span className="chat-time text-[14px] mx-2">06/20/2023 at 05:15 PM</span></h4>
							<div className="chat-bubble-rw d-flex flex-column align-items-end">
								<div className="chat-bubble">
									<p>Where can I get some?</p>
								</div>
							</div>
						</div>
					</div>
					<div className="chat-row">
						<div className="d-flex align-items-center">
							<div className="pic d-inline-flex rounded-circle overflow-hidden flex-shrink-0">
								<img className="img-fluid w-100 h-100" src={require('../../../../../../assets/img/vetRecorEMR/user-thumbnail.png')} alt="Audio" />
								{/* <UserPlaceholder /> */}
							</div>
							<span className="text-[#282E65] text-[14px] font-[500] font-['Inter'] mb-0">Artimis</span>
							<span className="chat-time text-[14px] mx-2 mb-0">06/20/2023 at 05:15 PM</span>
							<span className="chat_label_text mb-0">Appointment</span>
						</div>
						<div className="mt-1 chat-bubble position-relative d-inline-block align-items-center rightchat">
							{/* {chat?.message} */}
							Summary Information.
							<div className="p-[6px] ml-[7px] h-fit w-[265px] space-y-1.5 bg-[#Ffff] rounded-lg my-2 cursor-pointer">
								<div className="w-full">
									<div className="flex justify-between  items-start">
										<p className="flex items-center">
											<img className="w-[40px] h-[40px] mr-[4px] rounded-full bg-gray-200" src="/static/media/user-thumbnail.adef342b4bb503aac3bc.png" alt="User" />
											<div className="pl-2">
												<span className="font-['Inter'] font-[400] capitalize text-[14px]">
													Dr. Martha William
												</span>
												<span className="font-['Inter'] font-[400] capitalize text-[14px] d-block">
													DVM
												</span>
											</div>
										</p>
										<img
											src={require("../../../../../../assets/img/autoMode.png")}
											alt="auto"
											className="w-[17px] h-[17px] mt-2 mr-1"
										/>
									</div>
								</div>
								<div className="flex justify-start gap-2 mt-2">
									<p className="font-['Inter'] text-[#282E65] flex flex-col gap-[10px]">
										<span className=" text-[13px] flex items-center gap-[3px] mb-0">
											<AiFillAlipayCircle size={20} />
											09:00 am - 10:00 am
										</span>
										<span className="text-[14px] flex capitalize items-center gap-[3px]">
											<TiVideoOutline size={20} />
											Video Call Consultancy
										</span>
									</p>
								</div>
								{/* </div> */}
							</div>
						</div>
					</div>


				</div>
				<div className="chat-foot">
					<div className="input-block">
						<Input type="text" placeholder="Message" />
					</div>
				</div>
			</div>
		</>
	);
};

export default ChatBlock;
