import React, { useEffect, useState } from "react";
import Accordions from "./Accordions";
import AccordionsForRangeValue from "./AccordionsForRangeValue";
import FilterBlock from "./FilterBlock";
import "./index.scss";
import NodataFoundBlock from "../../../../../shared/NoDataFoundBlock";

import { getTestResultsByPetId } from "../../../../../context/actions/patient-module/diagnostic-testing";
import { getTestResultsByPetIdAndDate } from "../../../../../context/actions/patient-module/diagnostic-testing";

import NewResultPanel from "./NewResultPanel";

import { useDispatch, useSelector } from "react-redux";
import { getAntechPatientResult } from "../../../../../context/actions/patient-module/diagnostic-testing";


import moment from "moment";
import jsPDF from "jspdf";

import * as htmlToImage from "html-to-image";



import { getAllReportsByPetId } from "context/actions/patient-module/overview/report";
import { cloneDeep } from "lodash";
import ReportsBlock from "./ReportsBlock";
import { diagnosticTestingReducer } from "context/reducers/patient-module/diagnosticTestingReducer";
import { getActiveIntegrations } from "../../../../../context/actions/clinicSettings";



const DiagnosticTesting = ({ petId }) => {
  const dispatch = useDispatch();
  const [currentDate, setCurrentDate] = useState(new Date())
  const [isDownload, setIsDownload] = useState(false);
  const [filterDates, setFilterDates] = useState([]);
  const [availableReportDates, setAvailableReportDates] = useState([]);

  const [panelState, setPanelState] = useState(false);

  const { reports } = useSelector((state) => state?.reportReducer);

  const { testResults } = useSelector(
    (state) => state?.diagnosticTestingReducer
  );

  const { diagnosisTestResults, loading } = useSelector(
    (state) => state?.diagnosticTestingReducer
  );
  const testData = { data: [] };

  const [currentView, setCurrentView] = useState("tableView");
  const [changeFilter, setChangeFilter] = useState("");
  const [changeModality, setChangeModality] = useState("");
  const [activeIntegrations, setActiveIntegrations] = useState([])


  const handleCurrentView = (data) => {
    setCurrentView(data);
  };

  const handleChangeFilter = (data) => {
    setChangeFilter(data);
  };


  const handleChangeModality = (data) => {
    setChangeModality(data);
  };

  const handleGetTestResults = () => {
    dispatch(
      getTestResultsByPetId(petId, (response) => {
        return;
      })
    );
  };

  
  const getIntegrations = () => {
    dispatch(getActiveIntegrations((response) => {
        if(response?.status && response?.response_data?.length > 0){
            setActiveIntegrations(response?.response_data)
        }
    }));

}

  const handleGetTestReports = (date, filterName, modality) => {

    dispatch(
      getTestResultsByPetIdAndDate(
        {
          pet_id: petId,
          reportDate: moment(date).toISOString(),
          search: filterName,
          modality: modality
        },
        (response) => {
          return;
        }
      )
    );
  };

  const getDate = (e) => {
    setCurrentDate(e);
  };


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (currentView === "tableView") {
        handleGetTestReports(currentDate, changeFilter, changeModality);
        dispatch(getAllReportsByPetId(petId, { reportDate: moment(currentDate).toISOString() }, "", (res) => {
          setAvailableReportDates(res?.response_data?.reportDate)
        }));
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [changeFilter, currentDate, changeModality]);

  useEffect(() => {
    // console.log(currentView);
    if (currentView === "listView") {
      handleGetTestResults();
    }
  }, [currentView]);


  const viewAntechPatientLevel = () => {

      dispatch(getAntechPatientResult(petId, (res) => {
        if(res?.status && res?.response_data?.uiURL){
     
            dispatch({
              type: "SET_LAB_RESULTS_VIEWER_DATA",
              data: {
                title: 'Antech Patient Record',
                url: res?.response_data?.uiURL
              },
            });
  
        }
      }))
  }


  useEffect(() => {
    getIntegrations();
    
  }, []);

  const downloadReport = () => {
    setIsDownload(true);
    setTimeout(() => {
      managePdfPages();
    }, 500);
  };
  const managePdfPages = () => {
    var nodes = document.querySelectorAll(".pdf-data");
    const pdf = new jsPDF("p", "mm", "a4");
    const pageHeight = pdf.internal.pageSize.getHeight() - 20;

    const addImageToPDF = (index, currentY) => {
      if (index < nodes.length) {
        const node = nodes[index];
        htmlToImage.toPng(node).then((dataUrl) => {
          const img = new Image();
          img.src = dataUrl;

          // Adjust image size and position as needed
          let pdfHeight;
          const pdfWidth = pdf.internal.pageSize.getWidth();
          if (
            node.clientHeight > pdf.internal.pageSize.getHeight() ||
            node.clientHeight < pdf.internal.pageSize.getHeight()
          ) {
            pdfHeight = (node.clientHeight * pdfWidth) / node.clientWidth;
          } else {
            pdfHeight = pdf.internal.pageSize.getHeight();
          }

          if (currentY + pdfHeight > pageHeight && index != 0) {
            // Move to the next page
            pdf.addPage();
            currentY = 0; // Reset currentY for the new page
          }

          // Add the image to the current page
          pdf.addImage(img, "PNG", 0, currentY, pdfWidth, pdfHeight);
          currentY += pdfHeight;

          // Add a new page for the next image if there are more images
          if (index < nodes.length - 1) {
            addImageToPDF(index + 1, currentY);
          } else {
            // Save the PDF once all images are added
            pdf.save(
              `diagnostic_testing_${moment(currentDate).format(
                "DD_MM_YYYY"
              )}.pdf`
            );
            setTimeout(() => {
              setIsDownload(false);
            }, 3000);
          }
        });
      }
    };

    addImageToPDF(0, 0);
  };

  useEffect(() => {
    let date = new Set()
    if(availableReportDates?.length > 0){
      let clone = cloneDeep(availableReportDates)
      clone?.forEach(item => date.add(item))
    }
    if (diagnosisTestResults?.reportDate?.length > 0) {
      diagnosisTestResults?.reportDate?.forEach(item => date.add(item))
    }
    setFilterDates(Array.from(date))

  }, [diagnosisTestResults, availableReportDates])

  return (
    <>
      <div className="diagnostic-testing-block">

        {activeIntegrations?.filter(i => i?.app === 'Antech')?.length > 0 && (
        <div style={{maxWidth: '200px'}} className="share-report  border border-1 border-gray-100 rounded-lg text-[12px] font-semibold text-center justify-center pt-[3px] pb-[3px]">
          <button onClick={() => {viewAntechPatientLevel()}}>ANTECH PATIENT HISTORY</button>
        </div>)}
        <FilterBlock
          openPanel={() => {
            setPanelState(true);
          }}
          getDate={getDate}
          reportDate={filterDates}
          onViewChange={handleCurrentView}
          onChangeFilter={handleChangeFilter}
          onChangeModality={handleChangeModality}
          currentView={currentView}
          recentDate={diagnosisTestResults?.recentDate}
          reportList={diagnosisTestResults?.reportList}
          listData={testResults?.data}
        />

        {/* {currentView == "tableView" &&
          diagnosisTestResults?.reportList?.length !== 0 && (
            <>
              <div className="diagnostic-listing-report-summary-heading pb-[5px] mt-2  gap-[4px]">
                <h3 className="font-semibold text-lg">Report Summary</h3>
              </div>
              <div className="diagnostic-listing-report-summary flex flex-row grid grid-cols-4 gap-3 text-[14px]">
                <div className="Summary grid gap-y-[5px] col-start-1 col-span-3">
                  <div className="report-data flex flex-row grid grid-cols-5 gap-4">
                    <div className="report-label">Result Date</div>
                    <div className="report-label-data col-start-2 col-span-4">
                      {moment(
                        diagnosisTestResults?.reportList?.[0]
                          ?.reportsByDate?.[0]?.resultDate
                      ).format("MM/DD/YYYY")}{" "}
                    </div>
                  </div>
                  <div className="report-data flex flex-row grid grid-cols-5 gap-4">
                    <div className="report-label">Order Date</div>
                    <div className="report-label-data col-start-2 col-span-4">
                      {moment(
                        diagnosisTestResults?.reportList?.[0]
                          ?.reportsByDate?.[0]?.resultDate
                      ).format("MM/DD/YYYY")}{" "}
                    </div>
                  </div>
                  <div className="report-data flex flex-row grid grid-cols-5 gap-4">
                    <div className="report-label">Order By</div>
                    <div className="report-label-data col-start-2 col-span-4 flex flex-col space-x-7">
                      <div className="pic d-inline-flex position-relative">
                        <img
                          className="w-[20px] h-[20px] mr-[4px] bg-gray-200"
                          src={
                            diagnosisTestResults?.reportList?.[0]
                              ?.reportsByDate?.[0]?.reports?.[0]
                              ?.userProfileImage ||
                            require("../../../../../assets/img/vetRecorEMR/user-thumbnail.png")
                          }
                          alt="owner"
                          style={{ borderRadius: "100px" }}
                        />
                      </div>
                      <div>
                        {" "}
                        {
                          diagnosisTestResults?.reportList?.[0]
                            ?.reportsByDate?.[0]?.reports?.[0]?.userFirstName
                        }{" "}
                        {
                          diagnosisTestResults?.reportList?.[0]
                            ?.reportsByDate?.[0]?.reports?.[0].userLastName
                        }
                      </div>
                    </div>
                  </div>
                  <div className="report-data flex flex-row grid grid-cols-5 gap-4">
                    <div className="report-label">Test Summary</div>
                    <div className="report-label-data col-start-2 col-span-4">
                      --
                    </div>
                  </div>
                </div>
                <div className="download-share-data pr-[10px]">
                  <>
                    <div className="download-report w-full border border-1 rounded-lg border-gray-100 text-[12px] font-semibold text-center justify-center pt-[3px] pb-[3px] mb-[8px]">
                      <button
                        className={isDownload ? "disabled" : ""}
                        onClick={isDownload ? "" : downloadReport}
                      >
                        DOWNLOAD REPORT{" "}
                        {isDownload && (
                          <span>
                            <div
                              class="spinner-border spinner-border-sm"
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          </span>
                        )}
                      </button>
                    </div>
                    <div className="share-report w-full border border-1 border-gray-100 rounded-lg text-[12px] font-semibold text-center justify-center pt-[3px] pb-[3px]">
                      <button>SHARE REPORT</button>
                    </div>
                  </>
                </div>
              </div>
            </>
          )} */}

        <div className="panel-div mt-[10px]">
          <div className="text-[14px] grid gap-x-2 gap-y-2">
            <div className="table-view-blc min-w-full" id="test-ex">
              {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <div className="spinner-border" role="status" />
                </div>

              ) : (
                <>
                  {currentView === "tableView" ? (
                    !reports?.[0]?._id && diagnosisTestResults?.reportList?.length === 0 ? (
                      <NodataFoundBlock type={'medical history'}  />
                    ) : (
                      <div>

                        {reports?.length ?
                          <div className="row-block">
                            <ReportsBlock petId={petId} currentDate={currentDate} />
                          </div> : null
                        }
                        <AccordionsForRangeValue
                          currentDate={currentDate}
                          data={diagnosisTestResults?.reportList}
                          isDownload={isDownload}
                        />
                      </div>
                    )
                  ) : testResults?.data?.length === 0 && reports?.length === 0 ? (
                    <NodataFoundBlock type={'medical history'}  />

                  ) : (
                    <>
                      {testResults?.data?.length > 0 && <Accordions
                        data={testResults?.data}
                        dates={testResults?.dates}
                        openPanel={() => {
                          setPanelState(true);
                        }}
                      />}

                      {reports?.length > 0 && <div className="row-block">
                        <ReportsBlock petId={petId} currentDate={currentDate} />
                      </div>}

                    </>
                  )}

                  {/* 
                    {
                      reports?.length === 0 ? (
                        <NodataFoundBlock />
                      ) : (
                        <div className="row-block mt-3">
                        <ReportsBlock petId={petId} currentDate={currentDate} />
                      </div> 
                     )
                    } */}



                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`new-result-panel-overlay position-fixed ${panelState ? "active" : ""
          }`}
      ></div>
      <NewResultPanel
        closePanel={() => {
          handleGetTestReports(currentDate);
          setPanelState(false);
        }}
        currentDate={currentDate}
        className={`${panelState ? "active" : ""}`}
        addPoint={(val) => {
          console.log(val);
        }}
      />
    </>
  );
};

export default DiagnosticTesting;
