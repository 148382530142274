export const setAppointmentDetailPanel = (param) => {
	return (dispatch) => {
		dispatch({ type: "SET_APPOINTMENTDETAIL_PANEL", data: param });
	}
};


export const setAppointmentDetailData = (param) => {
	return (dispatch) => {
		dispatch({ type: "SET_APPOINTMENTDETAIL_DATA", data: param });
	}
};