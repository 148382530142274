const initState = {
    incomingReferrals: {},
    outgoingReferrals: {}
}

export const referralReducer = (state = initState, action) => {
    switch (action.type) {
        case "GET_INCOMING_REFERRALS":
            return { ...state, incomingReferrals: action.payload }
        case "GET_OUTGOING_REFERRALS":
            return { ...state, outgoingReferrals: action.payload }
        default:
            return state
    }
}