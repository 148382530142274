import React, { useEffect, useState } from "react";
import {
  Button,
  Collapse,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Input,
} from "reactstrap";
import TextInput from "../../../shared/FormsInput/TextBox";
import { appointmentTypes } from "../../../utils/constants";
import ScheduleFilter from "../ScheduleFilter";
import ScheduleList from "../ScheduleList";
import "./index.scss";
import { format } from "date-fns";
import OperatingRoomSchedule from "../OperatingRoomSchedule";
import { useSelector } from "react-redux";
import PatientIntakeFormList from "../PatientIntakeFormList";

const AppointmentAccordion = ({
  patientData,
  userDetails,
  appointmentTypeChange,
  appointmentInfoProp,
  appointmentReason,
  appointmentCondition,
  appointmentDescription,
  activeAccordian,
  doctorAppt,
  setSurgeryId,
  setSelectedOperatingRoomSlots,
  selectedOperatingRoomSlots,
  appointmentSlotDuration,
  directAppt,
  setDirectAppointment,
  setIsActive,
  intakeForm,
  setIntakeForm,
}) => {
  const [appointmentInfo, setAppointmentInfo] = useState(false);
  const [selectedDoctorInfo, setSelectedDoctorInfo] = useState(false);
  const [loadIndex, setLoadIndex] = useState(1);
  const [elemIndex, setElemIndex] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [locationChange, setLocationChange] = useState(false);
  const [doctorNameFilter, setDoctorNameFilter] = useState(false);
  const [selectedApptDate, setSelectedApptDate] = useState(
    format(new Date(), "dd-MM-yyyy")
  );
  const [selectedApptSlot, setSelectedApptSlot] = useState(null);

  const [selectedApptSlots, setSelectedApptSlots] = useState([]);
  const [slotTiming, setSlotTiming] = useState([]);
  const [overrideToggle, setOverrideToggle] = useState(false);

  // console.log("selectedApptDate", selectedApptDate);
  // console.log("selectedApptSlot", selectedApptSlot);

  // const { clinic_settings } = useSelector(
  //   (state) => state.clinicSettingsReducer
  // );

  const [doctorsList, setDoctorsList] = useState([]);

  // const { operatingRooms } = useSelector((state) => state.operatingRoomReducer);

  const toggleFunction = (index) => {
    // console.log("toggleFunction", index);
    if (loadIndex === index) {
      setLoadIndex(null);
    } else if (elemIndex === index) {
      setToggle(!toggle);
    } else {
      setToggle(true);
    }
    setElemIndex(index);
    setLoadIndex(null);
  };

  const [activeTab, setActiveTab] = useState("1");
  const tabToggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const onLocationChange = (e) => {
    setLocationChange(e.target.value);
  };

  useEffect(() => {
    if (selectedApptDate || selectedApptSlot) {
      // setOperatingRoomId(null)
      setSlotTiming([]);
      setSelectedOperatingRoomSlots([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApptDate, selectedApptSlot]);

  useEffect(() => {
    setIsActive(overrideToggle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overrideToggle]);

  // const [allAppointments, setAllAppointments] = useState([]);

  // useEffect(() => {
  //   if (doctorsList.length > 0) {
  //     let allAppointments = [];
  //     doctorsList.forEach((ele) => {
  //       allAppointments.push(...ele.appointments);
  //     });
  //     setAllAppointments(allAppointments);
  //   }
  // }, [doctorsList]);

  return (
    <>
      <div className="appointment-accordion-wrapper">
        <div className="accordion-blc d-flex flex-column">
          <Button
            color="link"
            className={`accordion-btn text-start shadow-none text-left d-flex align-items-center ${
              loadIndex === 1 || (1 === elemIndex && toggle) ? "active" : ""
            }`}
            onClick={() => {
              toggleFunction(1);
              activeAccordian("appointment_info");
            }}
          >
            <span className="material-icons icon-btn">
              {loadIndex === 1 || (1 === elemIndex && toggle)
                ? "expand_less"
                : "expand_more"}
            </span>
            <label>Appointment Information</label>
          </Button>
          <Collapse
            style={{ visibility: "visible" }}
            isOpen={loadIndex === 1 || (1 === elemIndex && toggle)}
          >
            <div className="collapse-data-blc">
              <div className="appointment-info-tab-blc">
                <Nav tabs className="flex-shrink-0">
                  <NavItem>
                    <NavLink
                      className={{ active: activeTab === "1" }}
                      onClick={() => {
                        tabToggle("1");
                      }}
                    >
                      New
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={{ active: activeTab === "2" }}
                      // onClick={() => { tabToggle('2'); }}
                    >
                      Recurrence
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} className="overflow-auto">
                  <TabPane tabId="1">
                    <div
                      className={`appointment-form-blc ${
                        appointmentInfo && "view-mode"
                      }`}
                    >
                      <div className="row-block d-flex">
                        <label className="required-input">Type</label>
                        <div className="right-col flex-fill">
                          <Input
                            type="select"
                            value={appointmentInfoProp?.appointment_type}
                            name="appointment-type"
                            disabled={appointmentInfo}

                            onChange={(e) => {
                              appointmentTypeChange(e);
                            }}
                          >
                            <option value="">Select Type</option>
                            {appointmentTypes.map(
                              (apptType, i) =>
                                apptType?.value !== "appointment-block" && (
                                  <option key={i} value={apptType.value}>
                                    {apptType.label}
                                  </option>
                                )
                            )}
                          </Input>
                        </div>
                      </div>
                      <div className="row-block d-flex">
                        <label className="required-input">Reason</label>
                        <div className="right-col flex-fill">
                          <TextInput
                            type="text"
                            name="reason"
                            value={appointmentInfoProp?.reason}

                            id="reason"
                            placeholder="Reason option"
                            isRequired={true}
                            label="Condition"
                            className="mb-0"
                            labelVisible={false}
                            disabled={appointmentInfo}
                            onChange={appointmentReason}
                          />
                        </div>
                      </div>
                      <div className="row-block d-flex">
                        <label className="input">Description</label>
                        <div className="right-col flex-fill">
                          <TextInput
                            type="text"
                            name="description"
                            id="enterDescription"
                            placeholder="Enter Description"
                            value={appointmentInfoProp?.description}

                            isRequired={false}
                            label="Description"
                            className="mb-0"
                            labelVisible={false}
                            disabled={appointmentInfo}
                            onChange={appointmentDescription}
                          />
                        </div>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">Recurrence</TabPane>
                </TabContent>
              </div>
            </div>
          </Collapse>
        </div>
        <div className="accordion-blc d-flex flex-column">
          <Button
            color="link"
            className={`accordion-btn text-start shadow-none text-left d-flex align-items-center ${
              loadIndex === 2 || (2 === elemIndex && toggle) ? "active" : ""
            }`}
            onClick={() => {
              toggleFunction(2);
              activeAccordian("schedule_appointment");
            }}
          >
            <span className="material-icons icon-btn">
              {loadIndex === 2 || (2 === elemIndex && toggle)
                ? "expand_less"
                : "expand_more"}
            </span>
            <label>Schedule Appointment</label>
          </Button>
          <Collapse isOpen={loadIndex === 2 || (2 === elemIndex && toggle)}>
            <div className="collapse-data-blc schedule-appointment-blc">
              {
                // !selectedDoctorInfo && <ScheduleFilter
                // 	checkAppointmentSlot={(value) => setSelectedApptDate(value)}
                // 	onLocationChange={(value) => onLocationChange(value)}
                // 	doctorFilter={(value) => setDoctorNameFilter(value)}
                // />

                <ScheduleFilter
                  checkAppointmentSlot={(value) => setSelectedApptDate(value)}
                  onLocationChange={(value) => onLocationChange(value)}
                  doctorFilter={(value) => setDoctorNameFilter(value)}
                  directAppt={0}
                  setDirectAppointment={setDirectAppointment}
                  setOverrideToggle={setOverrideToggle}
                />
              }
              <ScheduleList
                doctorInfo={selectedDoctorInfo}
                isDoctorData={(value) => {
                  setSelectedDoctorInfo(value);
                }}
                doctorAppt={(value) => doctorAppt(value)}
                userDetails={userDetails}
                selectedApptDate={selectedApptDate}
                locationChange={locationChange}
                doctorNameFilter={doctorNameFilter}
                setSelectedApptSlot={setSelectedApptSlot}
                setDoctorsList={setDoctorsList}
                doctorsList={doctorsList}
                selectedApptSlots={selectedApptSlots}
                setSelectedApptSlots={setSelectedApptSlots}
                slotTiming={slotTiming}
                setSlotTiming={setSlotTiming}
                appointmentSlotDuration={appointmentSlotDuration}
                directAppt={0}
                setDirectAppointment={setDirectAppointment}
                overrideToggle={overrideToggle}
              />
            </div>
          </Collapse>
        </div>
        <div className="accordion-blc d-flex flex-column">
          <Button
            color="link"
            className={`accordion-btn text-start shadow-none text-left d-flex align-items-center ${
              loadIndex === 3 || (3 === elemIndex && toggle) ? "active" : ""
            }`}
            onClick={() => {
              activeAccordian("patient_intake_form");
              toggleFunction(3);
            }}
          >
            <span className="material-icons icon-btn">
              {loadIndex === 3 || (3 === elemIndex && toggle)
                ? "expand_less"
                : "expand_more"}
            </span>
            <label>Patient Intake Form</label>
          </Button>
          <Collapse isOpen={loadIndex === 3 || (3 === elemIndex && toggle)}>
            <div className="collapse-data-blc">
              <PatientIntakeFormList
                intakeForm={intakeForm}
                setIntakeForm={setIntakeForm}
              />
            </div>
          </Collapse>
        </div>
        {appointmentInfoProp?.appointment_type === "surgery" && (
          <div className="accordion-blc d-flex flex-column">
            <Button
              color="link"
              className={`accordion-btn text-start shadow-none text-left d-flex align-items-center ${
                loadIndex === 4 || (4 === elemIndex && toggle) ? "active" : ""
              }`}
              onClick={() => toggleFunction(4)}
            >
              <span className="material-icons icon-btn">
                {loadIndex === 4 || (4 === elemIndex && toggle)
                  ? "expand_less"
                  : "expand_more"}
              </span>
              <label>Operating Room Schedule</label>
            </Button>
            <Collapse isOpen={loadIndex === 4 || (4 === elemIndex && toggle)}>
              {/* <div className="collapse-data-blc">
								<div className="appointment-info-tab-blc">

									<div className={`appointment-form-blc ${appointmentInfo && 'view-mode'}`}>
										<div className="row-block d-flex">
											<label className="required-input">Select Operating Room</label>
											<div className="right-col flex-fill">
												<Input type="select" name="appointment-type" disabled={!selectedApptDate} onChange={(e)=> setOperatingRoomId(e.target.value)} value={operatingRoomId}>
													<option value=''>Select Operating Room</option>
													{
														operatingRooms.map((room, i) => (
															<option key={i} value={room._id} disabled={
																allAppointments.filter(appointment => appointment?.appointment_date === selectedApptDate && appointment?.slot_time === selectedApptSlot && appointment?.operating_room_id == room._id).length > 0 ? true : false
															}>{room.name}</option>
														))
													}
												</Input>
											</div>
										</div>
									</div>

								</div>
							</div> */}

              <div className="collapse-data-blc">
                <OperatingRoomSchedule
                  patientData={patientData}
                  doctorInfo={selectedDoctorInfo}
                  setSelectedOperatingRoomSlots={setSelectedOperatingRoomSlots}
                  selectedOperatingRoomSlots={selectedOperatingRoomSlots}
                  selectedApptSlots={selectedApptSlots}
                  slotTiming={slotTiming}
                  selectedApptDate={selectedApptDate}
                />
              </div>
            </Collapse>
          </div>
        )}
      </div>
    </>
  );
};

export default AppointmentAccordion;
