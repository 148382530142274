import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import TextBoxInput from "../../../../shared/FormsInput/TextBoxInput";
import { UserLoginFireAuth, checkSecurityCode, login, resendSecurityCode } from '../../../../context/actions/user';
import { toasterConfig } from '../../../../utils/constants';
import CheckBox from '../../../../shared/CheckBox';
import { enterSecurityCodeInit, enterSecurityCodeSchema } from './enterSecurityCodeSchema';

const EnterCodeModal = ({ openViewBlock, userId, userLoginData, sendLoader, verificationCodeMessage }) => {
    const dispatch = useDispatch();
    const [modal, setModal] = useState(true);
    const [saveDevice, setSaveDevice] = useState(false);
    const [payload, setPayload] = useState(enterSecurityCodeInit);

    const toggle = () => {
        setModal(!modal);
        openViewBlock(!modal);
    }

    const handleCancelModal = () => {
        setModal(false);
        openViewBlock(false);
    }

    /**
     * @description method call to manage code change (focus next input field)
     * @param {Object} formik form object
     * @param {Object} e form object
     */
    const handleCodeChange = (formik, e) => {
        const { maxLength, value, name } = e.target;
        const [fieldName, fieldIndex] = name.split("_");
        let fieldIntIndex = parseInt(fieldIndex);
        formik.setFieldValue(`code_${fieldIntIndex}`, value)

        // Check if no of char in field == maxlength
        if (value.length >= maxLength) {

            // It should not be last input field
            if (fieldIntIndex < 7) {

                // Get the next input field using it's name
                const nextfield = document.querySelector(
                    `input[name=code_${fieldIntIndex + 1}]`
                );
                // If found, focus the next field
                if (nextfield !== null) {
                    nextfield.focus();
                }
            }
        }
    };

    /**
     * @description method call to delete entered code
     */
    const handleDeleteEnteredCode = (actions) => {
        actions.resetForm()
    }

    /**
     * @description method call to submit valid form
     * @param {Object} params form params
     * @param {Instance} status form status
     */
    const submitForm = (params, actions) => {
        sendLoader(true);
        if (
          params.code_1 &&
          params.code_1.length === 1 &&
          params.code_2 &&
          params.code_2.length === 1 &&
          params.code_3 &&
          params.code_3.length === 1 &&
          params.code_4 &&
          params.code_4.length === 1 &&
          params.code_5 &&
          params.code_5.length === 1 &&
          params.code_6 &&
          params.code_6.length === 1
        ) {
          const data = {
            code:
              params.code_1 +
              params.code_2 +
              params.code_3 +
              params.code_4 +
              params.code_5 +
              params.code_6,
            user_id: userId,
            save_device: saveDevice,
            current_device: window.navigator.userAgent,
          };
          dispatch(
            checkSecurityCode(data, (result) => {
              if (result) {
                
    
                if (!result.code_invalid && result.can_login) {
    
                dispatch(UserLoginFireAuth(userLoginData, (result) => {
                  sendLoader(false);
                }))
                } else {
    
                  sendLoader(false);
                  if (result.code_invalid) {
                    handleDeleteEnteredCode(actions);
                    toast.error("Invalid verification code", toasterConfig);
                  } else if (result.code_expired) {
                    handleDeleteEnteredCode(actions);
                    toast.error(
                      "Verification Code has been expired",
                      toasterConfig
                    );
                  } else {
                    handleDeleteEnteredCode(actions);
                    toast.error("Invalid verification code", toasterConfig);
                  }
                }
                // if(result.codeMatched){
                //     dispatch(login(userLoginData, (result) => {
                //         sendLoader(false)
                //     }))
                // }else{
                //     sendLoader(false)
                //     toast.error("Invalid security code", toasterConfig)
                // }
              } else {
                toast.error(
                  "unable to login please try after sometime.",
                  toasterConfig
                );
                handleCancelModal();
                sendLoader(false);
              }
            })
          );
        }
        sendLoader(false);
      };
    /**
     * @description method call to resend security code
     */
    const handleResendCode = () => {
        sendLoader(true)
        dispatch(resendSecurityCode({ email: userLoginData.email }, (result) => {
            if (result) {
                if (result.code_resent) {
                    toast.success(result.message, toasterConfig);
                    sendLoader(false)
                } else {
                    toast.error("unable to resend code, please try after sometime.", toasterConfig);
                    sendLoader(false)
                }
            } else {
                sendLoader(false)
            }
        }))
    }
    return (
        <>
            <Modal isOpen={modal} className="signup-sm-modal code-modal">
                <ModalHeader>
                    Enter 6 digit code
                    <Button color='link' onClick={toggle} className="modal-">
                        <i className='icomoon-close-square'></i>
                    </Button>
                </ModalHeader>
                <ModalBody>
                    <Formik
                        initialValues={payload}
                        enableReinitialize
                        validationSchema={enterSecurityCodeSchema}
                        onSubmit={(values, actions) => submitForm(values, actions)}
                    >
                        {(formik) => (<Form>
                            <div className="signup-form-block">
                                <div className="inner overflow-hidden">
                                    <h5 className="instruction-msg">{verificationCodeMessage.message ? verificationCodeMessage.message : 'Please use the 6 digit verification code sent to your email to proceed'}</h5>
                                    <div className="justify-content-between code-input-rw d-flex">
                                        <TextBoxInput
                                            name="code_1"
                                            type="text"
                                            maxLength="1"
                                            value={formik?.values?.code_1}
                                            onChange={(v) => { handleCodeChange(formik, v) }}
                                        />
                                        <TextBoxInput
                                            name="code_2"
                                            type="text"
                                            maxLength="1"
                                            value={formik?.values?.code_2}
                                            onChange={(v) => { handleCodeChange(formik, v) }}
                                        />
                                        <TextBoxInput
                                            name="code_3"
                                            type="text"
                                            maxLength="1"
                                            value={formik?.values?.code_3}
                                            onChange={(v) => { handleCodeChange(formik, v) }}
                                        />
                                        <TextBoxInput
                                            name="code_4"
                                            type="text"
                                            maxLength="1"
                                            value={formik?.values?.code_4}
                                            onChange={(v) => { handleCodeChange(formik, v) }}
                                        />
                                        <TextBoxInput
                                            name="code_5"
                                            type="text"
                                            maxLength="1"
                                            value={formik?.values?.code_5}
                                            onChange={(v) => { handleCodeChange(formik, v) }}
                                        />
                                        <TextBoxInput
                                            name="code_6"
                                            type="text"
                                            maxLength="1"
                                            value={formik?.values?.code_6}
                                            onChange={(v) => { handleCodeChange(formik, v) }}
                                        />
                                    </div>
                                    <div className="remember-code">
                                        <CheckBox type='checkbox' label='Remember code for this device' ischecked={false} onChange={(e) => setSaveDevice(e.target.checked)} />
                                    </div>
                                    <p className="fw-500 text-end">Didn't receive an email? <a className='link text-decoration-none' onClick={handleResendCode}>Resend</a></p>
                                    {/* <p className="text-danger fw-500">Note : You will need this code in future login for security purpose</p> */}
                                </div>
                            </div>
                            <div className="btn-cell d-flex">
                                <Button
                                    className="flex-fill"
                                    color="primary"
                                    type='submit'
                                    onClick={submitForm}
                                >
                                    Login
                                </Button>
                            </div>
                        </Form>)}
                    </Formik>
                </ModalBody>
            </Modal>
        </>
    );

}

export default EnterCodeModal;