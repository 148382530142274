import React, { useState, useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useSelector } from "react-redux";
import { dateFromDateTime, formatPhoneNumber, timeFromDateTime } from "../../../utils/common";
import NoDataFound from "../NoDataFound";
import moment from "moment";
import { imageBaseUrl, documentPhotosPath } from "../../../environment";
import { vitalKeys } from "../../../utils/constants";

const data_from_date = (data_list, date) => {
  for (let item of data_list) {
    if (item?.date == date) {
      return item;
    }
  }
  return null;
};

const Avs = ({ show, handleClose }) => {
  const { appointmentAndDischargePatientDetails } = useSelector(
    (state) => state.petOwnerDashboardReducer
  );

  const [largeVitalKeysArr, setLargeVitalKeysArr] = useState([]);

  console.log(
    "appointmentAndDischargePatientDetails",
    appointmentAndDischargePatientDetails
  );

  useEffect(() => {
    if (
      appointmentAndDischargePatientDetails &&
      appointmentAndDischargePatientDetails.vitalsToggles
    ) {
      let largeVitalKeysArr = [];
      let size = 4;
      let filteredVitalsKeys = vitalKeys.filter(
        (ele) => appointmentAndDischargePatientDetails.vitalsToggles[ele["key"]]
      );
      for (
        var vital_key = 0;
        vital_key < filteredVitalsKeys.length;
        vital_key += size
      ) {
        largeVitalKeysArr.push(
          filteredVitalsKeys.slice(vital_key, vital_key + size)
        );
      }

      setLargeVitalKeysArr(largeVitalKeysArr);
    }
  }, [appointmentAndDischargePatientDetails]);

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="end"
      className="border-0 patient-offcanvas"
    >
      <Offcanvas.Header closeButton></Offcanvas.Header>
      <Offcanvas.Body>
        <div className="right-slide-offcanvas p-3 mb-3">
          <table
            width="100%"
            style={{
              fontFamily: '"Inter"',
              padding: 20,
              margin: "auto",
            }}
          >
            <tbody>
              <tr>
                <td style={{ width: "100%" }}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <th
                          colSpan={2}
                          style={{
                            color: "#282E65",
                            paddingBottom: 10,
                            fontFamily: "Inter",
                            fontSize: 18,
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          Visit Summaries
                        </th>
                      </tr>
                      <tr>
                        <td style={{ color: "#282E65" }}>
                          <h4
                            style={{
                              fontSize: 14,
                              margin: 0,
                              fontWeight: 600,
                            }}
                          >
                            {
                              appointmentAndDischargePatientDetails
                                ?.appointment_details?.patient_id?.pet_name
                            }
                          </h4>
                          <p
                            style={{
                              fontSize: 12,
                              margin: "3px 0 0",
                            }}
                          >
                            Assessment ID:{" "}
                            {
                              appointmentAndDischargePatientDetails
                                ?.appointment_details?._id
                            }
                          </p>
                          <p
                            style={{
                              fontSize: 12,
                              margin: "3px 0 0",
                            }}
                          >
                            Observation Date:{" "}
                            {dateFromDateTime(
                              appointmentAndDischargePatientDetails
                                ?.appointment_details?.appointment_start,
                              "MM-DD-yyyy"
                            )}{" "}
                            {timeFromDateTime(
                              appointmentAndDischargePatientDetails
                                ?.appointment_details?.appointment_start,
                              "hh:mm a"
                            )}
                          </p>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <table style={{ width: "100%" }}>
                            <tbody>
                              <tr>
                                <td>
                                  <h4
                                    style={{
                                      fontSize: 14,
                                      margin: 0,
                                      fontWeight: 600,
                                    }}
                                  >
                                    {
                                      appointmentAndDischargePatientDetails
                                        ?.clinic_details?.clinic_name
                                    }
                                  </h4>
                                  <p
                                    style={{
                                      fontSize: 12,
                                      margin: "3px 0 0",
                                    }}
                                  >
                                    {
                                      appointmentAndDischargePatientDetails
                                        ?.clinic_details?.address
                                    }
                                  </p>
                                  <p
                                    style={{
                                      fontSize: 12,
                                      margin: "3px 0 0",
                                    }}
                                  >
                                    Email:{" "}
                                    {
                                      appointmentAndDischargePatientDetails
                                        ?.clinic_details?.email
                                    }
                                  </p>
                                  <p
                                    style={{
                                      fontSize: 12,
                                      margin: "3px 0 0",
                                    }}
                                  >
                                    Phone:{" "}
                                    {
                                      formatPhoneNumber(
                                        appointmentAndDischargePatientDetails
                                          ?.clinic_details?.phone)
                                    }
                                  </p>
                                </td>
                                <td style={{ width: 50 }}>
                                  <img
                                    src={require("../../../assets/img/logo.png")}
                                    style={{
                                      marginLeft: "auto",
                                    }}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2} style={{ height: 20 }} />
                      </tr>
                    </tbody>
                  </table>
                  <table
                    style={{
                      width: "100%",
                      borderLeft: "2px solid  #3D3EFD",
                      background: "rgba(248, 248, 248, 0.90)",
                      tableLayout: "fixed",
                    }}
                  >
                    <thead>
                      <tr>
                        <td
                          className="recent-color text-capitalize"
                          style={{
                            color: "#3D3EFD",
                            fontSize: 13,
                            fontWeight: 600,
                            padding: 8,
                          }}
                        >
                          Vitals :{" "}
                          {appointmentAndDischargePatientDetails?.vitals_selected_fetch_filter !==
                            null
                            ? appointmentAndDischargePatientDetails?.vitals_selected_fetch_filter.replaceAll(
                              "_",
                              " "
                            )
                            : ""}
                        </td>
                        <td
                          style={{
                            color: "#788693",
                            textAlign: "end",
                            fontSize: 12,
                          }}
                        ></td>
                      </tr>
                    </thead>
                  </table>

                  {appointmentAndDischargePatientDetails &&
                    appointmentAndDischargePatientDetails?.vitals &&
                    appointmentAndDischargePatientDetails?.vitals.length > 0 ? (
                    <table style={{ width: "100%" }}>
                      <tbody>
                        {appointmentAndDischargePatientDetails?.vitals?.map(
                          (ele) => {
                            return ele?.results?.map((item) => {
                              return largeVitalKeysArr?.map((smallArr) => {
                                return (
                                  <tr>
                                    {smallArr?.map((vitalField) => {
                                      return (
                                        <td
                                          style={{
                                            color: "#282E65",
                                            paddingTop: 10,
                                          }}
                                        >
                                          <p
                                            style={{
                                              fontSize: 12,
                                              margin: "3px 0 0",
                                            }}
                                          >
                                            {vitalField.name}
                                          </p>
                                          <p
                                            style={{
                                              fontSize: 13,
                                              margin: "3px 0 0",
                                              fontWeight: 500,
                                            }}
                                          >
                                            {item[vitalField.key]}
                                          </p>
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              });
                            });
                          }
                        )}
                      </tbody>
                    </table>
                  ) : (
                    <NoDataFound />
                  )}

                  <table
                    style={{
                      width: "100%",
                      borderLeft: "2px solid  #3D3EFD",
                      tableLayout: "fixed",
                      background: "rgba(248, 248, 248, 0.90)",
                      padding: 8,
                    }}
                  >
                    <thead>
                      <tr>
                        <td
                          className="recent-color text-capitalize"
                          style={{
                            color: "#3D3EFD",
                            fontSize: 13,
                            fontWeight: 600,
                            padding: 8,
                          }}
                        >
                          Medications Administered :{" "}
                          {appointmentAndDischargePatientDetails?.medications_administered_selected_fetch_filter !==
                            null
                            ? appointmentAndDischargePatientDetails?.medications_administered_selected_fetch_filter?.replaceAll(
                              "_",
                              " "
                            )
                            : ""}
                        </td>
                        <td
                          style={{
                            color: "#788693",
                            textAlign: "end",
                            fontSize: 12,
                          }}
                        ></td>
                      </tr>
                    </thead>
                  </table>
                  {appointmentAndDischargePatientDetails?.marsOrders &&
                    appointmentAndDischargePatientDetails?.marsOrders?.length >
                    0 ? (
                    <table
                      style={{
                        width: "100%",
                        borderBottom: "1px solid #EEEEF2",
                        borderCollapse: "collapse",
                        color: "#282E65",
                        tableLayout: "fixed",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td colSpan={3} style={{ height: 10 }}></td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: 12,
                              borderBottom: "1px solid #EEEEF2",
                            }}
                          >
                            Medication Name
                          </td>
                          <td
                            style={{
                              borderBottom: "1px solid #EEEEF2",
                              fontSize: 12,
                            }}
                          >
                            Dose
                          </td>
                          <td
                            style={{
                              borderBottom: "1px solid #EEEEF2",
                              fontSize: 12,
                            }}
                          >
                            Date
                          </td>
                        </tr>
                        {appointmentAndDischargePatientDetails?.marsOrders?.map(
                          (item, i) => (
                            <tr>
                              <td
                                style={{
                                  fontSize: 13,
                                  fontWeight: 500,
                                  backgroundColor: "rgba(248, 248, 248, 0.90)",
                                  padding: "6px 0",
                                }}
                              >
                                {item?.medication_name}
                              </td>
                              <td
                                style={{
                                  fontSize: 13,
                                  fontWeight: 500,
                                  backgroundColor: "rgba(248, 248, 248, 0.90)",
                                  padding: "6px 0",
                                }}
                              >
                                {item?.dose}
                              </td>
                              <td
                                style={{
                                  fontSize: 13,
                                  fontWeight: 500,
                                  backgroundColor: "rgba(248, 248, 248, 0.90)",
                                  padding: "6px 0",
                                }}
                              >
                                {moment(item?.date).format(
                                  "MM/DD/YYYY hh:mm a"
                                )}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  ) : (
                    <NoDataFound />
                  )}

                  <table
                    style={{
                      width: "100%",
                      borderLeft: "2px solid  #3D3EFD",
                      marginTop: 20,
                      background: "rgba(248, 248, 248, 0.90)",
                      padding: 8,
                      tableLayout: "fixed",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          className="recent-color text-capitalize"
                          style={{
                            color: "#3D3EFD",
                            fontSize: 13,
                            fontWeight: 600,
                            padding: 8,
                          }}
                        >
                          Diagnostic Testing :{" "}
                          {appointmentAndDischargePatientDetails?.diagnostic_tests_selected_fetch_filter !==
                            null
                            ? appointmentAndDischargePatientDetails?.diagnostic_tests_selected_fetch_filter.replaceAll(
                              "_",
                              " "
                            )
                            : ""}
                        </td>
                        <td
                          style={{
                            color: "#788693",
                            textAlign: "end",
                            fontSize: 12,
                          }}
                        ></td>
                      </tr>
                    </tbody>
                  </table>

                  {appointmentAndDischargePatientDetails &&
                    appointmentAndDischargePatientDetails?.diagnosticResults &&
                    appointmentAndDischargePatientDetails?.diagnosticResults
                      ?.data &&
                    appointmentAndDischargePatientDetails?.diagnosticResults?.data
                      .length > 0 &&
                    appointmentAndDischargePatientDetails?.diagnosticResults
                      ?.dates &&
                    appointmentAndDischargePatientDetails?.diagnosticResults
                      ?.dates.length > 0 ? (
                    <table
                      style={{
                        width: "100%",
                        borderBottom: "1px solid #EEEEF2 ",
                        borderCollapse: "collapse",
                        color: "#282E65",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td colSpan={3} style={{ height: 10 }}></td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderBottom: "1px solid #EEEEF2",
                              fontSize: 12,
                            }}
                          >
                            Test Name
                          </td>
                          <td
                            style={{
                              borderBottom: "1px solid #EEEEF2 ",
                              fontSize: 12,
                            }}
                          >
                            Range
                          </td>
                          <td
                            style={{
                              borderBottom: "1px solid #EEEEF2 ",
                              fontSize: 12,
                            }}
                          >
                            Value
                          </td>
                        </tr>
                        {appointmentAndDischargePatientDetails?.diagnosticResults?.data?.map(
                          (item, i) =>
                            item?.tests?.map((test, j) => {
                              const isEven = j % 2 === 0;
                              const styles = isEven
                                ? {
                                  fontSize: 13,
                                  fontWeight: 500,
                                  backgroundColor:
                                    "rgba(248, 248, 248, 0.90)",
                                  padding: "6px 0",
                                }
                                : {
                                  fontSize: 13,
                                  fontWeight: 500,
                                  padding: "6px 0",
                                };

                              return (
                                <tr key={i}>
                                  <td style={styles}>
                                    {test?.test?.test_name}
                                  </td>
                                  {test?.test?.min_value !== null ? (
                                    <td style={styles}>
                                      {`${test?.test?.min_value}-${test?.test?.max_value}`}
                                    </td>
                                  ) : (
                                    <td style={styles}>--</td>
                                  )}
                                  {appointmentAndDischargePatientDetails?.diagnosticResults?.dates?.map(
                                    (date, d) => {
                                      const result = data_from_date(
                                        test?.results,
                                        date?._id
                                      );

                                      return (
                                        <div key={date?._id}>
                                          {result !== null ? (
                                            test?.test?.min_value !== null ? (
                                              parseFloat(result?.result) >=
                                                parseFloat(
                                                  test?.test.min_value
                                                ) &&
                                                parseFloat(result?.result) <=
                                                parseFloat(
                                                  test?.test?.max_value
                                                ) ? (
                                                <td style={styles}>
                                                  {result?.result}
                                                </td>
                                              ) : parseFloat(result?.result) <
                                                parseFloat(
                                                  test?.test?.min_value
                                                ) ? (
                                                <td
                                                  style={styles}
                                                >{`${result?.result} L`}</td>
                                              ) : (
                                                <td
                                                  style={styles}
                                                >{`${result?.result} H`}</td>
                                              )
                                            ) : (
                                              <td style={styles}>
                                                {result?.result}
                                              </td>
                                            )
                                          ) : (
                                            <td style={styles}>--</td>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </tr>
                              );
                            })
                        )}
                      </tbody>
                    </table>
                  ) : (
                    <NoDataFound />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="right-slide-offcanvas p-3 mb-3">
          <table
            width="100%"
            style={{
              fontFamily: '"Inter"',
              padding: 20,
              margin: "auto",
            }}
          >
            <tbody>
              <tr>
                <td style={{ width: "100%" }}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <th
                          colSpan={2}
                          style={{
                            color: "#282E65",
                            paddingBottom: 10,
                            fontFamily: "Inter",
                            fontSize: 18,
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          Visit Summaries
                        </th>
                      </tr>
                      <tr>
                        <td style={{ color: "#282E65" }}>
                          <h4
                            style={{
                              fontSize: 14,
                              margin: 0,
                              fontWeight: 600,
                            }}
                          >
                            {
                              appointmentAndDischargePatientDetails
                                ?.appointment_details?.patient_id?.pet_name
                            }
                          </h4>
                          <p
                            style={{
                              fontSize: 12,
                              margin: "3px 0 0",
                            }}
                          >
                            Assessment ID:{" "}
                            {
                              appointmentAndDischargePatientDetails
                                ?.appointment_details?._id
                            }
                          </p>
                          <p
                            style={{
                              fontSize: 12,
                              margin: "3px 0 0",
                            }}
                          >
                            Observation Date:{" "}
                            {dateFromDateTime(
                              appointmentAndDischargePatientDetails
                                ?.appointment_details?.appointment_start,
                              "MM-DD-yyyy"
                            )}{" "}
                            {timeFromDateTime(
                              appointmentAndDischargePatientDetails
                                ?.appointment_details?.appointment_start,
                              "hh:mm a"
                            )}
                          </p>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <table style={{ width: "100%" }}>
                            <tbody>
                              <tr>
                                <td>
                                  <h4
                                    style={{
                                      fontSize: 14,
                                      margin: 0,
                                      fontWeight: 600,
                                    }}
                                  >
                                    {
                                      appointmentAndDischargePatientDetails
                                        ?.clinic_details?.clinic_name
                                    }
                                  </h4>
                                  <p
                                    style={{
                                      fontSize: 12,
                                      margin: "3px 0 0",
                                    }}
                                  >
                                    {
                                      appointmentAndDischargePatientDetails
                                        ?.clinic_details?.address
                                    }
                                  </p>
                                  <p
                                    style={{
                                      fontSize: 12,
                                      margin: "3px 0 0",
                                    }}
                                  >
                                    Email:{" "}
                                    {
                                      appointmentAndDischargePatientDetails
                                        ?.clinic_details?.email
                                    }
                                  </p>
                                  <p
                                    style={{
                                      fontSize: 12,
                                      margin: "3px 0 0",
                                    }}
                                  >
                                    Phone:{" "}
                                    {

                                      formatPhoneNumber(
                                        appointmentAndDischargePatientDetails
                                          ?.clinic_details?.phone)
                                    }
                                  </p>
                                </td>
                                <td style={{ width: 50 }}>
                                  <img
                                    src={require("../../../assets/img/logo.png")}
                                    style={{
                                      marginLeft: "auto",
                                    }}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2} style={{ height: 20 }} />
                      </tr>
                    </tbody>
                  </table>

                  <table
                    style={{
                      width: "100%",
                      borderLeft: "2px solid  #3D3EFD",
                      tableLayout: "fixed",
                      background: "rgba(248, 248, 248, 0.90)",
                      padding: 8,
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          className="recent-color"
                          style={{
                            color: "#3D3EFD",
                            fontSize: 13,
                            fontWeight: 600,
                            padding: 8,
                          }}
                        >
                          Procedures
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {appointmentAndDischargePatientDetails &&
                    appointmentAndDischargePatientDetails?.procedures &&
                    appointmentAndDischargePatientDetails?.procedures.length >
                    0 ? (
                    <table
                      style={{
                        width: "100%",
                        borderBottom: "1px solid #EEEEF2 ",
                        borderCollapse: "collapse",
                        color: "#282E65",
                        tableLayout: "fixed",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td colSpan={3} style={{ height: 10 }}></td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderBottom: "1px solid #EEEEF2",
                              fontSize: 12,
                            }}
                          >
                            Medication Name
                          </td>
                          <td
                            style={{
                              borderBottom: "1px solid #EEEEF2",
                              fontSize: 12,
                            }}
                          >
                            Status
                          </td>
                          <td
                            style={{
                              borderBottom: "1px solid #EEEEF2",
                              fontSize: 12,
                            }}
                          >
                            Date
                          </td>
                          <td
                            style={{
                              borderBottom: "1px solid #EEEEF2 ",
                              fontSize: 12,
                            }}
                          >
                            Notes
                          </td>
                        </tr>
                        {appointmentAndDischargePatientDetails?.procedures
                          ?.length > 0 &&
                          appointmentAndDischargePatientDetails?.procedures?.map(
                            (item) => {
                              <tr>
                                <td
                                  style={{
                                    fontSize: 13,
                                    fontWeight: 500,
                                    backgroundColor:
                                      "rgba(248, 248, 248, 0.90)",
                                    padding: "8px 0",
                                  }}
                                >
                                  {item?.name}
                                </td>
                                <td
                                  style={{
                                    fontSize: 13,
                                    fontWeight: 500,
                                    backgroundColor:
                                      "rgba(248, 248, 248, 0.90)",
                                    padding: "8px 0",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "#169677",
                                      backgroundColor: "#DBF8F1",
                                      fontSize: 12,
                                      padding: 3,
                                      lineHeight: "normal",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {item?.status}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: 13,
                                    fontWeight: 500,
                                    backgroundColor:
                                      "rgba(248, 248, 248, 0.90)",
                                    padding: "8px 0",
                                  }}
                                >
                                  {dateFromDateTime(
                                    item?.first_dose,
                                    "MM/DD/YYYY"
                                  )}{" "}
                                  {dateFromDateTime(
                                    item?.first_dose,
                                    "hh:mm A"
                                  )}
                                </td>
                                <td
                                  style={{
                                    fontSize: 13,
                                    fontWeight: 500,
                                    backgroundColor:
                                      "rgba(248, 248, 248, 0.90)",
                                    padding: "8px 0",
                                  }}
                                >
                                  {item?.notes}
                                </td>
                              </tr>;
                            }
                          )}
                      </tbody>
                    </table>
                  ) : (
                    <NoDataFound />
                  )}

                  <table
                    style={{
                      width: "100%",
                      borderLeft: "2px solid  #3D3EFD",
                      tableLayout: "fixed",
                      marginTop: 20,
                      background: "rgba(248, 248, 248, 0.90)",
                      padding: 8,
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          className="recent-color"
                          style={{
                            color: "#3D3EFD",
                            fontSize: 13,
                            fontWeight: 600,
                            padding: 8,
                          }}
                        >
                          Prescription Routing
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: "#282E65",
                            padding: "10px 0",
                            fontSize: 13,
                            fontWeight: 400,
                          }}
                          className="text-capitalize"
                        >
                          {appointmentAndDischargePatientDetails?.prescription_routing !==
                            null
                            ? appointmentAndDischargePatientDetails?.prescription_routing.replaceAll(
                              "_",
                              "-"
                            )
                            : ""}{" "}
                          for Discharge
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table
                    style={{
                      width: "100%",
                      borderLeft: "2px solid  #3D3EFD",
                      tableLayout: "fixed",
                      marginTop: 20,
                      background: "rgba(248, 248, 248, 0.90)",
                      padding: 8,
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          className="recent-color"
                          style={{
                            color: "#3D3EFD",
                            fontSize: 13,
                            fontWeight: 600,
                            padding: 8,
                          }}
                        >
                          Order for Discharge
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  {appointmentAndDischargePatientDetails &&
                    appointmentAndDischargePatientDetails?.order_for_discharge &&
                    appointmentAndDischargePatientDetails?.order_for_discharge
                      .length > 0 ? (
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td style={{ height: 10 }} />
                        </tr>
                        {appointmentAndDischargePatientDetails
                          ?.order_for_discharge.length > 0 &&
                          appointmentAndDischargePatientDetails?.order_for_discharge.map(
                            (item) => {
                              return (
                                <tr key={item?.product_service_id}>
                                  <td>
                                    <table
                                      style={{
                                        width: "100%",
                                        padding: 10,
                                        backgroundColor:
                                          "rgba(248, 248, 248, 0.90)",
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{
                                              color: "#282E65",
                                              verticalAlign: "top",
                                            }}
                                          >
                                            <p
                                              style={{
                                                fontSize: 12,
                                                margin: "3px 0 0",
                                              }}
                                              className="text-capitalize"
                                            >
                                              Frequency
                                            </p>
                                            <p
                                              style={{
                                                fontSize: 13,
                                                margin: "3px 0 0",
                                                fontWeight: 500,
                                              }}
                                            >
                                              {item?.frequency}
                                            </p>
                                          </td>
                                          <td
                                            style={{
                                              color: "#282E65",
                                              verticalAlign: "top",
                                            }}
                                          >
                                            <p
                                              style={{
                                                fontSize: 12,
                                                margin: "3px 0 0",
                                              }}
                                            >
                                              Start Date &amp; Time
                                            </p>
                                            <p
                                              style={{
                                                fontSize: 13,
                                                margin: "3px 0 0",
                                                fontWeight: 500,
                                              }}
                                            >
                                              {dateFromDateTime(
                                                item?.start_datetime,
                                                "MM/DD/yyyy"
                                              )}{" "}
                                              {timeFromDateTime(
                                                item?.start_datetime,
                                                "hh:mm A"
                                              )}
                                            </p>
                                          </td>
                                          <td
                                            style={{
                                              color: "#282E65",
                                              verticalAlign: "top",
                                            }}
                                          >
                                            <p
                                              style={{
                                                fontSize: 12,
                                                margin: "3px 0 0",
                                              }}
                                            >
                                              Duration
                                            </p>
                                            <p
                                              style={{
                                                fontSize: 13,
                                                margin: "3px 0 0",
                                                fontWeight: 500,
                                              }}
                                            >
                                              {item?.duration_num}{" "}
                                              {item?.duration_unit}
                                            </p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            colSpan={3}
                                            style={{
                                              fontSize: 13,
                                              margin: "3px 0 0",
                                              color: "#282E65",
                                              padding: "10px 0 0",
                                            }}
                                          >
                                            Comments : {item?.notes}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </table>
                  ) : (
                    <NoDataFound />
                  )}

                  <table
                    style={{
                      width: "100%",
                      borderLeft: "2px solid  #3D3EFD",
                      tableLayout: "fixed",
                      marginTop: 20,
                      background: "rgba(248, 248, 248, 0.90)",
                      padding: 8,
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          className="recent-color"
                          style={{
                            color: "#3D3EFD",
                            fontSize: 13,
                            fontWeight: 600,
                            padding: 8,
                          }}
                        >
                          Medication and Order Reconciliation
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  {(appointmentAndDischargePatientDetails &&
                    appointmentAndDischargePatientDetails?.home_medication_and_orders &&
                    appointmentAndDischargePatientDetails
                      .home_medication_and_orders.length > 0) ||
                    (appointmentAndDischargePatientDetails?.inpatient_orders &&
                      appointmentAndDischargePatientDetails?.inpatient_orders
                        .length > 0) ? (
                    <table
                      style={{
                        width: "100%",
                        borderBottom: "1px solid #EEEEF2 ",
                        borderCollapse: "collapse",
                        color: "#282E65",
                        tableLayout: "fixed",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td colSpan={3} style={{ height: 10 }}></td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              borderBottom: "1px solid #EEEEF2 ",
                              fontSize: 12,
                            }}
                          >
                            Medication Name
                          </td>
                          <td
                            style={{
                              borderBottom: "1px solid #EEEEF2 ",
                              fontSize: 12,
                            }}
                          >
                            Frequency
                          </td>
                          <td
                            style={{
                              borderBottom: "1px solid #EEEEF2 ",
                              fontSize: 12,
                            }}
                          >
                            Status
                          </td>
                        </tr>
                        {appointmentAndDischargePatientDetails
                          ?.home_medication_and_orders.length > 0 &&
                          appointmentAndDischargePatientDetails?.home_medication_and_orders.map(
                            (item) => {
                              return (
                                <tr>
                                  <td
                                    style={{
                                      fontSize: 13,
                                      fontWeight: 500,
                                      backgroundColor:
                                        "rgba(248, 248, 248, 0.90)",
                                      padding: "6px 0",
                                    }}
                                  >
                                    {item?.name}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: 13,
                                      fontWeight: 500,
                                      backgroundColor:
                                        "rgba(248, 248, 248, 0.90)",
                                      padding: "6px 0",
                                    }}
                                  >
                                    {item?.notes}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: 13,
                                      fontWeight: 500,
                                      backgroundColor:
                                        "rgba(248, 248, 248, 0.90)",
                                      padding: "6px 0",
                                      color: "#11CA9D",
                                    }}
                                  >
                                    {item?.status}
                                  </td>
                                </tr>
                              );
                            }
                          )}

                        {appointmentAndDischargePatientDetails?.inpatient_orders
                          .length > 0 &&
                          appointmentAndDischargePatientDetails?.inpatient_orders.map(
                            (item) => {
                              return (
                                <tr>
                                  <td
                                    style={{
                                      fontSize: 13,
                                      fontWeight: 500,
                                      backgroundColor:
                                        "rgba(248, 248, 248, 0.90)",
                                      padding: "6px 0",
                                    }}
                                  >
                                    {item?.name}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: 13,
                                      fontWeight: 500,
                                      backgroundColor:
                                        "rgba(248, 248, 248, 0.90)",
                                      padding: "6px 0",
                                    }}
                                  >
                                    {item?.notes}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: 13,
                                      fontWeight: 500,
                                      backgroundColor:
                                        "rgba(248, 248, 248, 0.90)",
                                      padding: "6px 0",
                                      color: "#11CA9D",
                                    }}
                                  >
                                    {item?.status}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </table>
                  ) : (
                    <NoDataFound />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="right-slide-offcanvas p-3 mb-3">
          <table
            width="100%"
            style={{
              fontFamily: '"Inter"',
              padding: 20,
              margin: "auto",
            }}
          >
            <tbody>
              <tr>
                <td style={{ width: "100%" }}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <th
                          colSpan={2}
                          style={{
                            color: "#282E65",
                            paddingBottom: 10,
                            fontFamily: "Inter",
                            fontSize: 18,
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          Visit Summaries
                        </th>
                      </tr>
                      <tr>
                        <td style={{ color: "#282E65" }}>
                          <h4
                            style={{
                              fontSize: 14,
                              margin: 0,
                              fontWeight: 600,
                            }}
                          >
                            {
                              appointmentAndDischargePatientDetails
                                ?.appointment_details?.patient_id?.pet_name
                            }
                          </h4>
                          <p
                            style={{
                              fontSize: 12,
                              margin: "3px 0 0",
                            }}
                          >
                            Assessment ID:{" "}
                            {
                              appointmentAndDischargePatientDetails
                                ?.appointment_details?.clinic_details?._id
                            }
                          </p>
                          <p
                            style={{
                              fontSize: 12,
                              margin: "3px 0 0",
                            }}
                          >
                            Observation Date:{" "}
                            {dateFromDateTime(
                              appointmentAndDischargePatientDetails
                                ?.appointment_details?.appointment_start,
                              "MM-DD-yyyy"
                            )}{" "}
                            {timeFromDateTime(
                              appointmentAndDischargePatientDetails
                                ?.appointment_details?.appointment_start,
                              "hh:mm a"
                            )}
                          </p>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <table style={{ width: "100%" }}>
                            <tbody>
                              <tr>
                                <td>
                                  <h4
                                    style={{
                                      fontSize: 14,
                                      margin: 0,
                                      fontWeight: 600,
                                    }}
                                  >
                                    {
                                      appointmentAndDischargePatientDetails
                                        ?.clinic_details?.clinic_name
                                    }
                                  </h4>
                                  <p
                                    style={{
                                      fontSize: 12,
                                      margin: "3px 0 0",
                                    }}
                                  >
                                    {
                                      appointmentAndDischargePatientDetails
                                        ?.clinic_details?.address
                                    }
                                  </p>
                                  <p
                                    style={{
                                      fontSize: 12,
                                      margin: "3px 0 0",
                                    }}
                                  >
                                    Email:{" "}
                                    {
                                      appointmentAndDischargePatientDetails
                                        ?.clinic_details?.email
                                    }
                                  </p>
                                  <p
                                    style={{
                                      fontSize: 12,
                                      margin: "3px 0 0",
                                    }}
                                  >
                                    Phone:{" "}
                                    {

                                      formatPhoneNumber(
                                        appointmentAndDischargePatientDetails
                                          ?.clinic_details?.phone)
                                    }
                                  </p>
                                </td>
                                <td style={{ width: 50 }}>
                                  <img
                                    src={require("../../../assets/img/logo.png")}
                                    style={{
                                      marginLeft: "auto",
                                    }}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2} style={{ height: 20 }} />
                      </tr>
                    </tbody>
                  </table>

                  <table
                    style={{
                      width: "100%",
                      borderLeft: "2px solid  #3D3EFD",
                      tableLayout: "fixed",
                      background: "rgba(248, 248, 248, 0.90)",
                      padding: 8,
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          className="recent-color"
                          style={{
                            color: "#3D3EFD",
                            fontSize: 13,
                            fontWeight: 600,
                            padding: 8,
                          }}
                        >
                          Problem List
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {appointmentAndDischargePatientDetails &&
                    appointmentAndDischargePatientDetails?.problems &&
                    appointmentAndDischargePatientDetails?.problems.length > 0 ? (
                    <div className="d-flex align-items-center flex-wrap mt-2">
                      {appointmentAndDischargePatientDetails?.problems?.length >
                        0 &&
                        appointmentAndDischargePatientDetails?.problems?.map(
                          (item) => {
                            return (
                              <div
                                className="d-flex align-items-center"
                                style={{
                                  backgroundColor: "rgba(228, 228, 228, 0.28)",
                                  padding: 10,
                                  borderRadius: 10,
                                  minWidth: "100px",
                                  marginBottom: "10px",
                                  marginRight: "10px",
                                }}
                              >
                                <div style={{ fontSize: "12px" }}>
                                  {item?.description}
                                </div>
                                {item?.status === "active" ? (
                                  <span
                                    style={{
                                      color: "#169677",
                                      backgroundColor: "#DBF8F1",
                                      fontSize: 12,
                                      padding: "3px 5px",
                                      lineHeight: "normal",
                                      textTransform: "capitalize",
                                      borderRadius: 4,
                                      marginLeft: 10,
                                    }}
                                  >
                                    active
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      color: "#7660FF",
                                      backgroundColor: "#E0E9FF",
                                      fontSize: 12,
                                      padding: "3px 5px",
                                      lineHeight: "normal",
                                      textTransform: "capitalize",
                                      borderRadius: 4,
                                      marginLeft: 10,
                                    }}
                                  >
                                    Resolved
                                  </span>
                                )}
                              </div>
                            );
                          }
                        )}
                    </div>
                  ) : (
                    <NoDataFound />
                  )}

                  <table
                    style={{
                      width: "100%",
                      borderLeft: "2px solid  #3D3EFD",
                      tableLayout: "fixed",
                      marginTop: 20,
                      background: "rgba(248, 248, 248, 0.90)",
                      padding: 8,
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          className="recent-color"
                          style={{
                            color: "#3D3EFD",
                            fontSize: 13,
                            fontWeight: 600,
                            padding: 8,
                          }}
                        >
                          Discharge Instructions
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* <div className="d-flex flex-wrap mt-2 justify-content-between"> */}
                  <div className="d-flex flex-wrap mt-2 justify-content-between">
                    {appointmentAndDischargePatientDetails &&
                      appointmentAndDischargePatientDetails?.discharge_instructions &&
                      appointmentAndDischargePatientDetails
                        ?.discharge_instructions.length > 0 ? (
                      appointmentAndDischargePatientDetails?.discharge_instructions.map(
                        (item) => {
                          return (
                            // <div className="d-flex flex-wrap mt-2 justify-content-between">
                            <div
                              className="d-flex mb-2"
                              style={{
                                backgroundColor: "#f7f7f7",
                                padding: "10px",
                                borderRadius: "10px",
                                color: "#282e65",
                                fontSize: "13px",
                                width: "49%",
                              }}
                            >
                              <div
                                style={{
                                  verticalAlign: "top",
                                  width: 60,
                                  textAlign: "center",
                                  borderRadius: 6,
                                  width: "40%",
                                }}
                              >
                                <img
                                  // src={`${imageBaseUrl}${documentPhotosPath}/${item.document_link}`}
                                  style={{
                                    height: "70px",
                                    objectFit: "contain",
                                  }}
                                  src={
                                    item.document_link
                                      .split(".")[1]
                                      .toLowerCase() != "pdf"
                                      ? `${imageBaseUrl}${documentPhotosPath}/${item.document_link}`
                                      : require("../../../assets/img/PDF_file_icon.svg.png")
                                  }
                                />
                              </div>
                              <div style={{ paddingLeft: 10 }}>
                                <h4
                                  style={{
                                    margin: "0 0 8px",
                                  }}
                                >
                                  {item?.document_name}
                                </h4>
                                <p style={{ margin: 0 }}>{item?.notes}</p>
                              </div>
                            </div>
                            // </div>
                          );
                        }
                      )
                    ) : (
                      <NoDataFound />
                    )}
                  </div>

                  {/* </div> */}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {appointmentAndDischargePatientDetails &&
          appointmentAndDischargePatientDetails.discharge_instructions &&
          appointmentAndDischargePatientDetails?.discharge_instructions.map(
            (item, index) => {
              if (item.document_link.split(".")[1].toLowerCase() != "pdf") {
                return (
                  <div className="right-slide-offcanvas p-3 mb-3">
                    <img
                      style={{ width: "100%" }}
                      src={`${imageBaseUrl}${documentPhotosPath}/${item.document_link}`}
                      alt=""
                    />
                  </div>
                );
              } else {
                return (
                  <div className="right-slide-offcanvas p-3 mb-3">
                    <object
                      data={`${imageBaseUrl}${documentPhotosPath}/${item.document_link}`}
                      type="application/pdf"
                      width="100%"
                      height="240px"
                    ></object>
                  </div>
                );
              }
            }
          )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default Avs;
