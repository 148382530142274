import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import 'react-image-crop/src/ReactCrop.scss'
import ReactCrop from "react-image-crop" 
import { toast } from "react-toastify";
import { toasterConfig } from '../../../utils/constants';
const CropImageModal = ({ openViewBlock, imgObject, imgCroppedBlob, sendCroppedImg }) => {

    const [modal, setModal] = useState(true); 
	const [image, setImage] = useState(null);
    // const [result, setResult] = useState(null);    
    const [crop, setCrop] = useState({ aspect: 1}); 
     
    const toggle = () => {
        setModal(!modal);
        openViewBlock(!modal)
    }

    const handleCancelModal = () => {
        setModal(false)
        openViewBlock(false)
    } 

	const onImageLoad = (e) => { 
		setImage(e.currentTarget) 
		// const { width, height, naturalWidth } = e.currentTarget  
	}

    useEffect(() => {
        toast.warning("Image size should be less than 1 MB, kindly crop the image to proceed further", toasterConfig)
    },[])

    // const getCroppedImg = async () => {
	// 	try {
	// 		const canvas = document.createElement("canvas"); 
	// 		const scaleX = image.naturalWidth / image.width;
	// 		const scaleY = image.naturalHeight / image.height;
	// 		canvas.width = crop.width;
	// 		canvas.height = crop.height;
	// 		const ctx = canvas.getContext("2d");
	// 		ctx.drawImage(
	// 			image,
	// 			crop.x * scaleX,
	// 			crop.y * scaleY,
	// 			crop.width * scaleX,
	// 			crop.height * scaleY,
	// 			0,
	// 			0,
	// 			crop.width,
	// 			crop.height
	// 		);

	// 		const base64Image = canvas.toDataURL("image/jpeg", 1);
	// 		canvas.toBlob((blob) => {
	// 			const newImg = document.createElement('img');
	// 			const url = URL.createObjectURL(blob);
			  
	// 			newImg.onload = () => {
	// 			  // no longer need to read the blob so it's revoked
	// 			  URL.revokeObjectURL(url);
	// 			};
			  
	// 			newImg.src = url;
	// 			console.log("to blob ",newImg);
	// 			document.body.appendChild(newImg);
	// 		  });
			
	// 		setResult(base64Image);
	// 		console.log(result);
	// 	} catch (e) {
	// 		console.log("crop the image ", e.message);
	// 	}
	// };

    const getCroppedImage = () => {
        const imageCanvas = document.createElement("canvas");

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        imageCanvas.width = crop.width;
        imageCanvas.height = crop.height;
        const imgCx = imageCanvas.getContext("2d");
        imgCx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
        const base64Image = imageCanvas.toDataURL("image/jpeg", 1);
        // setResult(base64Image)
        sendCroppedImg(base64Image)     
        imageCanvas.toBlob((blob) => {
            if (!blob) {
                imgCroppedBlob(false)
                return;
            } 
            blob.name = imgObject.name;
            // blob.lastModified = imgObject.lastModified;
            // blob.lastModifiedDate = imgObject.lastModifiedDate;
            let imageURL;
            window.URL.revokeObjectURL(imageURL);
            // imageURL = window.URL.createObjectURL(blob);
            // console.log("imageUrl ",base64Image);
            imgCroppedBlob(blob)
            console.log("blob ", blob);
            handleCancelModal()
            // setImageUrl(blob);
        }, imgObject.type);

    }
    return (
        <>
            <Modal zIndex={2000} isOpen={modal} toggle={toggle} className="payment-detail-modal signup-sm-modal">
                <ModalHeader>
                    Crop Image
                    <Button color='link' onClick={toggle} className="modal-close-btn">
                        <i className='icomoon-close-square'></i>
                    </Button>
                </ModalHeader>
                <ModalBody> 
                        <ReactCrop
                            src={URL.createObjectURL(imgObject)}
                            crop={crop}
                            onChange={setCrop}
                            aspect={1}
                        >
                            <img src={URL.createObjectURL(imgObject)} onLoad={onImageLoad} alt="img" />
                        </ReactCrop>
                        {/* <Button color='primary' className="cropButton" onClick={getCroppedImage}
                        > Crop</Button>  */}
                </ModalBody>
                <ModalFooter className="border-0">
                    <Button color='primary' className='flex-fill' onClick={getCroppedImage}>Crop</Button>
                </ModalFooter>
            </Modal>
        </>
    );

}

export default CropImageModal;