const initState = {
    loading: false,
    petList: false,
    petAndOwnerDetails: false,
    petDetails: false,
    petFormData: false,
    petDetailsSide: false,
    sideLoading: false
}

export const petReducer = (state = initState, action) => {
    switch (action.type) {
        case "PET_LOADING":
            return { ...state, loading: action.payload }
            
        case "PET_SIDE_LOADING":
            return { ...state, sideLoading: action.payload }

        case "GET_PET_LIST":
            return { ...state, petList: action.payload }

        case "GET_PET_AND_OWNER":
            return { ...state, petAndOwnerDetails: action.payload }

        case "GET_PET_DETAILS":
            return { ...state, petDetails: action.payload }

        case "GET_PET_DETAILS_SIDE":
            return { ...state, petDetailsSide: action.payload }

        case "PET_FORM_DATA":
            return { ...state, petFormData: action.payload }

        default:
            return state
    }
}