import React, { useEffect, useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Input, Table } from "reactstrap";
import NodataFoundBlock from "shared/NoDataFoundBlock";
import LoaderUI from "shared/loader";
import { ageList, titleCaseString } from "utils/common";
import { Icons } from "utils/contants/Icons";
import "./BoardingReport.scss";
import { useDispatch, useSelector } from "react-redux";
import { getBoardingsRecords } from "context/actions/clinic-reports";
import DateRange from "shared/FormsInput/DateRange";
import { getAllSpecies, getAllBreeds } from "context/actions/app-content";
import moment from "moment";
import PaginationBlock from "shared/PaginationBlock";
import PetPlaceholder from "shared/PetPlaceholder";
import { useClinicSpecies } from "hooks/useClinicSpecies";
import {useClinicBreed} from "hooks/useClinicBreed"

const BoardingReport = () => {
  const clinicSpecies = useClinicSpecies()
  const clinicBreed = useClinicBreed()
  const history = useHistory();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState({
    data: [],
    loading: true,
  });
  const { species, breeds } = useSelector((state) => state.appContentReducer);
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [limit, setLimit] = useState(5);
  const [boardingPage, setBoardingPage] = useState([]);
  const [boardingPaging, setBoardingPaging] = useState([]);
  const [filterObj, setFilterObj] = useState({
    species_id: "",
    start_date: "",
    end_date: "",
    age_range: "",
    breed_id: "",
  });

  const fetchData = async (obj) => {
    dispatch(
      getBoardingsRecords({ clinic_id: clinic_details?._id, ...obj }, (res) => {
        setTableData((prev) => {
          return {
            ...prev,
            data: res,
            loading: false,
          };
        });
      })
    );
  };

  useEffect(() => {
    fetchData(filterObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterObj]);

  useEffect(() => {
    dispatch(getAllSpecies({}, () => {}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tableData?.data?.response_data?.[0]?.boradingData) {
      const updatedOrPages = boardingTable?.map((arr, _) => 1);
      setBoardingPage(updatedOrPages);
      setBoardingPaging(updatedOrPages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData?.data?.response_data?.[0]?.boradingData]);

  const getBoardingPaginateData = (dataArray, tabIndex) => {
    const startIndex = (boardingPage[tabIndex] - 1) * limit;
    const endIndex = startIndex + limit;
    return dataArray?.slice(startIndex, endIndex) || [];
  };
  const boardingData = tableData?.data?.response_data?.[0]?.boradingData;
  const boardingPurpose = {
    inClinic: boardingData?.filter(
      (item) => item?.boarding_purpose === "in clinic"
    ),

    routineCheckup: boardingData?.filter(
      (item) => item?.boarding_purpose === "routine checkup"
    ),

    someRoutineTests: boardingData?.filter(
      (item) => item?.boarding_purpose === "some routine tests"
    ),
  };
  const boardingTable = [
    {
      id: 1,
      title: "Boarding Report (In clinic)",
      data: boardingPurpose?.inClinic || [],
    },
    {
      id: 2,
      title: "Boarding Report (Routine checkup)",
      data: boardingPurpose?.routineCheckup || [],
    },
    {
      id: 1,
      title: "Boarding Report (Some routine tests)",
      data: boardingPurpose?.someRoutineTests || [],
    },
  ];

  const averageSpeciesData = tableData?.data?.response_data?.[0]?.speciesData;

  const handleDownload = () => {
    const csvContent = generateCSV(boardingTable);
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "Boarding Report.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const generateCSV = (data) => {
    let csv = "";
    data?.forEach((boardData) => {
      if (boardData?.data?.length > 0) {
        // Add headers based on the first item in the category
        csv += `\n\n ${boardData.title}\nPatient Info,DOB,Species Name,Boarding Purpose,Kennel Name,Start Date,End Date\n`;
        boardData?.data?.forEach((item) => {
          const {
            petDetails,
            boarding_purpose,
            kennelDetails,
            boarding_start_date,
            boarding_end_date,
          } = item;
          csv += `${titleCaseString(petDetails?.pet_name)},${moment(
            petDetails?.dob
          ).format("MM/DD/YYYY")},${titleCaseString(
            clinicSpecies(petDetails?.speciesDetails?._id)|| petDetails?.speciesDetails?.species_name
          )},${titleCaseString(boarding_purpose)},${titleCaseString(
            kennelDetails?.kennel_name
          )},${moment(boarding_start_date).format("MM/DD/YYYY")} at ${moment(
            boarding_start_date
          )
            .utc()
            .format("h:mm a")},${moment(boarding_end_date).format(
            "MM/DD/YYYY"
          )} at ${moment(boarding_end_date).utc().format("h:mm a")}\n`;
        });
      }
    });

    return csv;
  };

  return (
    <div id="boarding-report-container" className="p-1 mt-1">
      <div className="row">
        <div className="col-md-3">
          <div className="">
            <div className="d-flex">
              <div
                className="d-flex gap-2 align-items-center cmb-20 pointer"
                onClick={() => history.push("/reports")}
              >
                <BsChevronLeft color="color-3EFD" />
                <span className="text-13-600 color-3EFD">VIEW ALL REPORTS</span>
              </div>
            </div>

            <div className="bg-filter-class">
              <div className="text-24-700 color-2E65">Configure Report</div>
              <div className="text-14-400 color-8693">
                Select filter and generate report
              </div>
              <div className="filter-block cmt-20 cmb-20">
                <div className="cmb-10">
                  <Input
                    type="select"
                    name="select"
                    value={filterObj?.species_id}
                    placeholder="All Species"
                    onChange={(e) => {
                      setFilterObj((prev) => ({
                        ...prev,
                        species_id: e?.target?.value,
                      }));
                      if (e?.target?.value) {
                        dispatch(getAllBreeds(e?.target?.value, () => {}));
                      }
                    }}
                  >
                    <option value="">All Species</option>
                    {species?.map((e, i) => {
                      return (
                        <option value={e?._id} key={i} id={e?._id}>
                          {clinicSpecies(e?._id) || e?.species_name}
                        </option>
                      );
                    })}
                  </Input>
                </div>

                {filterObj?.species_id && (
                  <div className="cmb-10">
                    <Input
                      type="select"
                      name="select"
                      value={filterObj?.breed_id}
                      placeholder="By Breed"
                      onChange={(e) => {
                        setFilterObj((prev) => ({
                          ...prev,
                          breed_id: e?.target?.value,
                        }));
                      }}
                    >
                      <option value="">By Breed</option>
                      {breeds?.map((e, i) => {
                        return (
                          <option value={e?._id} key={i} id={e?._id}>
                            {clinicBreed(e?._id) || titleCaseString(e?.breed)}
                          </option>
                        );
                      })}
                    </Input>
                  </div>
                )}

                <div className="cmb-10">
                  <Input
                    type="select"
                    name="select"
                    value={filterObj?.age_range}
                    placeholder="By Age"
                    onChange={(e) => {
                      setFilterObj((prev) => ({
                        ...prev,
                        age_range: e?.target?.value,
                      }));
                    }}
                  >
                    <option value="">By Age</option>
                    {ageList?.map((e, i) => {
                      return (
                        <option value={e?.id} key={i} id={e?.id}>
                          {e?.value}
                        </option>
                      );
                    })}
                  </Input>
                </div>

                <div className="cmb-10">
                  <DateRange
                    placeholder="Please select date"
                    id="boarding"
                    name="boarding"
                    startDateProp={startDate}
                    endDateProp={endDate}
                    setStartDateProp={setStartDate}
                    setEndDateProp={setEndDate}
                    onChange={(sDate, eDate) => {
                      setFilterObj((prev) => ({
                        ...prev,
                        start_date: sDate
                          ? moment(sDate).format("YYYY-MM-DD")
                          : "",
                        end_date: eDate
                          ? moment(eDate).format("YYYY-MM-DD")
                          : "",
                      }));
                    }}
                    isClearable
                  />
                </div>
              </div>
              <div className="cmb-15">
                {/* <Button className="g-report">Generate Report</Button> */}
                <div
                  className="text-center color-2E65 text-13-600 cmt-20 pointer"
                  onClick={() => {
                    setFilterObj({
                      species_id: "",
                      start_date: "",
                      end_date: "",
                      age_range: "",
                      breed_id: "",
                    });
                    setStartDate(null);
                    setEndDate(null);
                  }}
                >
                  RESET FILTER
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-9 details-section">
          <div className="cpt-20 cpe-20 cpb-20">
            <div className="d-flex justify-content-between align-items-center cmb-20">
              <div>
                <div className="text-24-700 color-2E65">Boarding Records</div>
                {/* <p className="text-14-400 color-8693 text-wrap">
                  Boarding Records help track the clinic’s medical supplies,
                  medications, and equipment to ensure that everything is
                  well-stocked and up to date.
                </p> */}
              </div>
              <div className="d-flex gap-2 br-8">
                <div
                  className="cp-8 bg-8C9F28 pointer"
                  onClick={() => {
                    handleDownload();
                  }}
                >
                  <img src={Icons.download} alt="download" />
                </div>
                <div className="cp-8 bg-8C9F28 br-8">
                  <img src={Icons.share} alt="share" />
                </div>
              </div>
            </div>

            <div className="cmb-20 card-block row flex-wrap gy-3">
              <div className="text-15-700">
                Average Length of Stay By Species
              </div>
              {averageSpeciesData?.map((data, index) => {
                return (
                  <div
                    className="col-md-2 col-sm-4 flex-grow-1 avrage-card"
                    key={index}
                  >
                    <div className="items-details-month">
                      <div className="text-13-600 cmb-10">
                        {titleCaseString( clinicSpecies(data?._id) || data?.species_name)}
                      </div>
                      <div className="text-13-600">
                        Average Days: {data?.averageDays?.toFixed(2)}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="cmb-20 card-block row flex-wrap gy-3">
              <div className="text-15-700">Average Length of Stay by Age</div>
              {tableData?.data?.response_data?.[0]?.ageAverage?.map(
                (data, index) => {
                  return (
                    <div
                      className="col-md-2 col-sm-4 flex-grow-1 avrage-card"
                      key={index}
                    >
                      <div className="items-details-month">
                        <div className="text-13-600 cmb-10">
                          Age Range: {data?.age_range}
                        </div>
                        <div className="text-13-600">
                          Average Days: {data?.averageLengthOfStay?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
              {tableData?.data?.response_data?.[0]?.breedAverage?.map(
                (data, index) => {
                  return (
                    <div
                      className="col-md-2 col-sm-4 flex-grow-1 avrage-card"
                      key={index}
                    >
                      <div className="items-details-month">
                        <div className="text-13-600 cmb-10">
                          Breed Name: {data?.breed}
                        </div>
                        <div className="text-13-600">
                          Average Days: {data?.averageLengthOfStay?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>

            {boardingTable?.map((elem, index) => {
              const totalItems = elem?.data?.length;
              const totalPages = Math.ceil(totalItems / limit);
              const paginateData = getBoardingPaginateData(elem?.data, index);
              return (
                <div className="table-one cmb-20" key={index}>
                  <div className="text-14-600 color-2E65 cmb-10">
                    {elem?.title} ({elem?.data?.length})
                  </div>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Patient Info.</th>
                        <th>DOB</th>
                        <th>Species Name</th>
                        <th>Boarding Purpose</th>
                        <th>Kennel Name</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        {/* <th>Start Time</th> */}
                        {/* <th>End Time</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData?.loading && (
                        <tr className="no-data-rw">
                          <td
                            colSpan="8"
                            className="d-flex justify-content-center cpt-50 cpb-50"
                          >
                            <LoaderUI />
                          </td>
                        </tr>
                      )}
                      {!tableData?.data?.loading && paginateData?.length > 0 ? (
                        paginateData?.map((dataElem, dataIndex) => {
                          const {
                            petDetails,
                            boarding_purpose,
                            kennelDetails,
                            boarding_start_date,
                            boarding_end_date,
                          } = dataElem;

                          return (
                            <tr key={dataIndex}>
                              <td className="text-nowrap">
                                <div className="d-flex gap-2 align-items-center">
                                  <div
                                    className="flex-shrink-0"
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  >
                                    {petDetails?.pet_image ? (
                                      <img
                                        className="img-fluid w-100 h-100"
                                        src={petDetails?.pet_image}
                                        alt="Product"
                                        style={{
                                          borderRadius: "8px",
                                        }}
                                      />
                                    ) : (
                                      <PetPlaceholder />
                                    )}
                                  </div>

                                  <div>
                                    {titleCaseString(petDetails?.pet_name)}
                                  </div>
                                </div>
                              </td>
                              <td className="text-nowrap">
                                {moment(petDetails?.dob).format("MM/DD/YYYY")}
                              </td>
                              <td className="text-nowrap">
                                {titleCaseString(
                                   clinicSpecies(petDetails?.speciesDetails?._id) || petDetails?.speciesDetails?.species_name
                                )}
                              </td>
                              <td className="text-nowrap">
                                {titleCaseString(boarding_purpose)}
                              </td>
                              <td className="text-nowrap">
                                {titleCaseString(kennelDetails?.kennel_name)}
                              </td>
                              <td className="text-nowrap">
                                {`${moment(boarding_start_date).format(
                                  "MM/DD/YYYY"
                                )} at ${moment(boarding_start_date)
                                  .utc()
                                  .format("h:mm a")}`}
                              </td>
                              <td className="text-nowrap">
                                {`${moment(boarding_end_date).format(
                                  "MM/DD/YYYY"
                                )} at ${moment(boarding_end_date)
                                  .utc()
                                  .format("h:mm a")}`}
                              </td>
                              {/* <td className="text-nowrap">
                                {moment(boarding_start_date)
                                  .utc()
                                  .format("h:mm a")}
                              </td>
                              <td className="text-nowrap">
                                {moment(boarding_end_date)
                                  .utc()
                                  .format("h:mm a")}
                              </td> */}
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="no-data-rw">
                          <td colSpan="11">
                            <NodataFoundBlock />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {elem?.data?.length > 0 && (
                    <PaginationBlock
                      limit={limit}
                      page={boardingPage[index] || 1}
                      pagingCounter={boardingPaging[index] || 1}
                      docLength={limit || 0}
                      docPage={boardingPage[index]}
                      totalPages={totalPages || 1}
                      totalDocs={elem?.data?.length || 0}
                      _handleLimit={(val) => {
                        setLimit(val);
                      }}
                      _handlePageChange={(e) => {
                        if (e > boardingPage[index]) {
                          const diff = e - boardingPage[index];
                          setBoardingPaging((prev) => {
                            const newData = [...prev];
                            newData[index] = newData[index] + limit * diff;
                            return newData;
                          });
                        } else if (e < boardingPage[index]) {
                          const diff = boardingPage[index] - e;
                          setBoardingPaging((prev) => {
                            const newData = [...prev];
                            newData[index] = Math.max(
                              newData[index] - limit * diff,
                              0
                            );
                            return newData;
                          });
                        }
                        setBoardingPage((prevPages) => {
                          const updatedPages = prevPages?.map((page, pIndex) =>
                            pIndex === index ? e : page
                          );
                          return updatedPages;
                        });
                      }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoardingReport;
