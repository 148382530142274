import React, { useEffect, useRef, useState } from "react";
import { FaAngleDown, FaUser } from "react-icons/fa";
import { Input } from "reactstrap";
import "./DoctorFilter.scss";

export const DoctorFilter = ({ doctorsList, selectedDoctorList, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const myRef = useRef();
  const handleClickOutside = (e) => {
    if (myRef && myRef?.current && !myRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
  return (
    <div id="doctor-filter">
      <div
        className="fb-center gap-2 br-4 pt-2 pb-2 ps-3 pe-3 cursor-pointer  bg-[#e4e4e447]"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <span className="text-14-400 text-[#808080] un">
          {selectedDoctorList?.length > 0
            ? `Selected doctor (${selectedDoctorList?.length})`
            : "Select doctor"}
        </span>
        <span className="text-14-400 text-[#808080]">
          <FaAngleDown />
        </span>
      </div>
      {isOpen && (
        <div className="shadow doctor-selection-popup" ref={myRef}>
          {doctorsList?.map((elm, index) => {
            const { title, firstname, lastname } = elm.user;
            let isExist = selectedDoctorList?.some((o) => o?._id === elm?._id);
            return (
              <div className="fb-center bb-e3e3 cp-8 cps-10 un" key={index}>
                <div className="fa-center gap-2">
                  <div className="check-box-block">
                    <Input
                      type="checkbox"
                      checked={isExist}
                      onChange={() => {
                        onSelect(elm);
                      }}
                    />
                  </div>
                  <div className="fa-center gap-2">
                    <div className="rounded-circle h-20 w-20 bg-eeee f-center">
                      <FaUser size={8} color="#788693" />
                    </div>
                    <div className="text-14-400 color-2E65 d-name-block">
                      {`${title} ${firstname} ${lastname}`}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
