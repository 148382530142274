import React, { useEffect, useState } from "react";
import moment from "moment";
import { Collapse } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { CiCircleCheck } from "react-icons/ci";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { getPetDetailsByIdNoLoading } from "context/actions/patient-module/patient/pet";
import {
  updateVitals,
  deleteVitals,
  addVitals,
  getVitals,
} from "../../../../../../context/actions/patient-module/flowsheets/vitals";
import NodataFoundBlock from "../../../../../../shared/NoDataFoundBlock";
import FilterComponent from "./FilterComponent";
import DeletePopup from "../DeletePopup";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import { toasterConfig } from "utils/constants";
import "./Vitals.scss";
import Spinner from "shared/Spinner";
toast.configure();

const VitalsKeys = [
  { key: "temperature", name: "Temperature (F)" },
  { key: "temperature_method", name: "Temperature Method" },
  { key: "heart_rate", name: "Heart Rate (BPM)" },
  { key: "heart_rate_source", name: "Heart Rate Source" },
  { key: "heart_rate_method", name: "Heart Rate Method" },
  { key: "cardiac_rhythm", name: "Cardiac Rhythm" },
  { key: "respiratory_rate", name: "Respiratory Rate (BPM)" },
  { key: "blood_pressure", name: "Blood Pressure" },
  {
    key: "blood_pressure_location",
    name: "Blood Pressure Location",
  },
  { key: "blood_pressure_method", name: "Blood Pressure Method" },
  { key: "cuff_size", name: "Cuff Size" },
  { key: "oxygen_saturation", name: "Oxygen Saturation (%)" },
  { key: "spo2_location", name: "SpO2 Location" },
  { key: "oxygen_flow_rate", name: "Oxygen Flow Rate" },
  // { key: "oxygen_saturation", name: "Oxygen Saturation" },
  { key: "oxygen_delivery", name: "Oxygen Delivery" },
];

const painAssessmentkeys = [
  { key: "pain_score", name: "Pain Score" },
  { key: "pain_goal", name: "Pain Goal" },
  { key: "pain_location", name: "Pain Location" },
  { key: "pain_reaction", name: "Pain Reaction" },
  { key: "pain_type", name: "Pain Type" },
  { key: "response_to_palpation", name: "Response to Palpation" },
  { key: "body_tension", name: "Body Tension" },
];

const measurementsKeys = [{ key: "weight", name: "Weight (lbs)" }];

const dropdownFields = [
  "temperature_method",
  "heart_rate_method",
  "blood_pressure_location",
  "blood_pressure_method",
  "spo2_location",
];

const fixedOption = {
  temperature_method: ["Rectal", "Axilla", "Digital Aural"],
  heart_rate_method: ["Monitor", "Apical", "Peripheral"],
  blood_pressure_location: [
    "Left Forelimb",
    "Right Forelimb",
    "Left Hindlimb",
    "Right Hindlimb",
  ],
  blood_pressure_method: ["Manual", "NIBP"],
  spo2_location: ["Ear", "Tongue", "Cheek", "Lip"],
};

const Vitals = ({ petId }) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.user_details);
  const [pageData, setPageData] = useState([]);
  const [isVitals, setIsVitals] = useState(true);
  const [isPain, setIsPain] = useState(false);
  const [isMeasurement, setIsMeasurement] = useState(false);
  const [recentDate, setRecentDate] = useState("");
  const [dateList, setDateList] = useState([]);
  const [editID, setEditID] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const [fieldValue, setFieldValue] = useState("");
  const [filterData, setFilterData] = useState({
    searchText: "",
    selectedDates: [],
    date: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleAdd = async () => {
    const payload = {
      pet_id: petId,
      created_by: userDetails?._id,
      date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    };
    const response = await dispatch(addVitals(payload));
    if (response?.status) {
      fetchPageData(filterData);
    }
    if (response?.message) {
      if (response?.status) {
        toast.success(response.message, toasterConfig);
      } else {
        toast.error(response.message, toasterConfig);
      }
    }
  };
  const handleEdit = async (payload) => {
    const res = await dispatch(updateVitals(payload));
    if (res?.status) {
      fetchPageData(filterData);
      setTimeout(() => {
        // setEditID("");
        // setFieldValue("");
        dispatch(getPetDetailsByIdNoLoading(petId, {}, () => {}));
      }, 1000);
    }
  };
  const handleDelete = async () => {
    setDeleteID("");
    const res = await dispatch(deleteVitals(deleteID));
    if (res?.status) {
      setTimeout(() => {
        fetchPageData(filterData);
      }, 1000);
    }
  };
  const handleSelect = (data) => {
    setEditID(`${data?.key}_${data._id}`);
    setFieldValue(data?.value || "");
    setTimeout(() => {
      let elem = document.getElementById(`${data?.key}_${data._id}`);
      if (elem) {
        elem.focus();
      }
    }, 100);
  };
  const handleResponse = (id, title, bodyKeysList, resData) => {
    let finalKey = {};
    let headerArray = [];
    let bodyKeys = bodyKeysList;
    resData?.forEach((cElem) => {
      let isAnyVal = [
        ...VitalsKeys,
        ...painAssessmentkeys,
        ...measurementsKeys,
      ].some((o) => cElem[o?.key]);
      headerArray.push({
        id: cElem?._id,
        date: cElem?.date,
        isValueExist: isAnyVal,
      });
    });
    bodyKeys = bodyKeys?.map((o) => {
      const data = resData?.map((e) => {
        return {
          ...e,
          value: e?.[o?.key] || "",
        };
      });
      return { ...o, data: data };
    });
    finalKey.id = id;
    finalKey.title = title;
    finalKey.dates = headerArray;
    finalKey.data = bodyKeys;
    return finalKey;
  };
  const fetchPageData = async (fData) => {
    setIsLoading(true);
    const res = await dispatch(
      getVitals({
        pet_id: petId,
        filterDates: fData?.selectedDates,
      })
    );
    let lastDate = res?.response_data?.recentDate
      ? moment(
          res?.response_data?.recentDate,
          "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
        ).format("DD-MM-YYYY")
      : "";
    let resDates = res?.response_data?.reportDate || [];
    let resData = res?.response_data?.reportList || [];
    let finalData = [];
    if (resData?.length > 0) {
      let vitalData = handleResponse(1, "Vitals", VitalsKeys, resData);
      let painData = handleResponse(
        2,
        "Pain Assessment",
        painAssessmentkeys,
        resData
      );
      let mesureData = handleResponse(
        3,
        "Measurements",
        measurementsKeys,
        resData
      );
      finalData = [vitalData, painData, mesureData];

      if (finalData.length > 0) {
        finalData = finalData.sort(function (a, b) {
          return a.id - b.id;
        });
      }
    }

    setPageData(finalData);
    setRecentDate(lastDate);
    setDateList([...new Set(resDates)]);
    setIsLoading(false);
  };

  useEffect(() => {
    if (filterData?.date) {
      fetchPageData(filterData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    let currentDate = moment().format("DD-MM-YYYY");
    let newData = {
      searchText: "",
      selectedDates: [currentDate],
      date: currentDate,
    };
    setFilterData(newData);
    fetchPageData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displayData = pageData.map((elm) => {
    return {
      ...elm,
      data: elm.data?.filter((o) =>
        o.name?.toLowerCase().includes(filterData?.searchText?.toLowerCase())
      ),
    };
  });
  return (
    <div id="vitals-container">
      {deleteID && (
        <DeletePopup
          onHide={() => {
            setDeleteID("");
          }}
          handleDelete={handleDelete}
        />
      )}

      <div className="title-head-block">
        <div className="title-block">Vitals</div>
        <div onClick={handleAdd}>
          <span className="icomoon-plus-btn" />
        </div>
      </div>
      {filterData?.date && (
        <FilterComponent
          recentDate={recentDate}
          dateList={dateList}
          filterData={filterData}
          setFilterData={setFilterData}
        />
      )}

      {isLoading ? (
        <div className="mx-auto">
          <Spinner height={150} />
        </div>
      ) : (
        <>
          {" "}
          {!displayData?.some((o) => o.data.length > 0) ? (
            <NodataFoundBlock type={'medical history'}/>
          ) : (
            <div className="mt-3 vitals-body-block">
              {displayData?.map((elm, index) => {
                const { title, dates, data } = elm;
                const isAccord =
                  title === "Vitals"
                    ? isVitals
                    : title === "Pain Assessment"
                    ? isPain
                    : isMeasurement;
                return (
                  <div
                    key={index}
                    className={`mb-1 ${data.length === 0 ? "d-none" : ""}`}
                  >
                    <div
                      className="vitals-item-title-block"
                      onClick={() => {
                        if (title === "Vitals") {
                          setIsVitals(!isVitals);
                        } else if (title === "Pain Assessment") {
                          setIsPain(!isPain);
                        } else {
                          setIsMeasurement(!isMeasurement);
                        }
                      }}
                    >
                      <div>
                        {isAccord ? <BsChevronUp /> : <BsChevronDown />}
                      </div>
                      <div>{title}</div>
                    </div>
                    <Collapse isOpen={isAccord}>
                      <div>
                        <div className="header-date-row">
                          <div className="left-title-block" />
                          {dates.map((dateElem, dateIndex) => {
                            const { date, isValueExist, id } = dateElem;
                            const cellDate = moment(
                              date,
                              "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                            ).format("MM/DD/YYYY");
                            const cellTime = moment(
                              date,
                              "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                            ).format("hh:mmA");
                            const isField = `date${index}_${id}` === editID;
                            return (
                              <div key={dateIndex} className="date-title-block">
                                <div className="date-block">
                                  <>
                                    <span
                                      className="mb-0 cursor-pointer"
                                      onClick={() => {
                                        handleSelect({
                                          key: `date${index}`,
                                          _id: id,
                                          value: moment(
                                            date,
                                            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                          ).format("YYYY-MM-DDTHH:mm"),
                                        });
                                      }}
                                    >
                                      {cellDate}
                                    </span>
                                    {!isValueExist && !isField && (
                                      <span
                                        className="date-delete mb-0"
                                        onClick={(e) => {
                                          setDeleteID(id);
                                        }}
                                      >
                                        {/* <CiCircleMinus color="red" size={20} /> */}
                                        <div className="remove_btn">-</div>
                                      </span>
                                    )}
                                  </>
                                </div>
                                <div
                                  className="time-block cursor-pointer"
                                  onClick={() => {
                                    handleSelect({
                                      key: `date${index}`,
                                      _id: id,
                                      value: moment(
                                        date,
                                        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                      ).format("YYYY-MM-DDTHH:mm"),
                                    });
                                  }}
                                >
                                  {cellTime}
                                </div>
                                {isField && (
                                  <div className="date-tile-edit-popover shadow">
                                    <div>
                                      <input
                                        type="datetime-local"
                                        value={fieldValue}
                                        onChange={(e) => {
                                          setFieldValue(e.target.value);
                                        }}
                                      />
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end mt-3 gap-2">
                                      <IoIosCloseCircleOutline
                                        size={20}
                                        color="red"
                                        className="cursor-pointer"
                                        onClick={() => {
                                          setEditID("");
                                          setFieldValue("");
                                        }}
                                      />
                                      <CiCircleCheck
                                        size={20}
                                        color="#11ca9d"
                                        className="cursor-pointer"
                                        onClick={() => {
                                          if (fieldValue) {
                                            if (
                                              fieldValue ===
                                              moment(
                                                date,
                                                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                              ).format("YYYY-MM-DDTHH:mm")
                                            ) {
                                              setEditID("");
                                              setFieldValue("");
                                            } else {
                                              handleEdit({
                                                date: moment(
                                                  fieldValue,
                                                  "YYYY-MM-DDTHH:mm"
                                                ).format(
                                                  "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                                ),
                                                _id: id,
                                                created_by: userDetails?._id,
                                              });
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                        <div className="body-row-container">
                          {data.map((dataElem, dataIndex) => {
                            return (
                              <div className="body-row" key={dataIndex}>
                                <div className="left-title-block">
                                  {dataElem?.name}
                                </div>
                                {dataElem?.data?.map((valElem, valIndex) => {
                                  const { value, _id } = valElem;
                                  const isField =
                                    `${dataElem?.key}_${_id}` === editID;
                                  let isAnyFieldValue =
                                    dates[valIndex]?.isValueExist;
                                  const isDropdown = dropdownFields.includes(
                                    dataElem?.key
                                  );
                                  let dropDownOptions =
                                    fixedOption[dataElem?.key] || [];
                                  if (isDropdown && isField && value) {
                                    dropDownOptions.push(value);
                                  }
                                  dropDownOptions = [
                                    ...new Set(dropDownOptions),
                                  ];

                                  return (
                                    <div
                                      key={valIndex}
                                      className="body-cell"
                                      onClick={() => {
                                        handleSelect({
                                          key: dataElem?.key,
                                          _id,
                                          value,
                                        });
                                      }}
                                    >
                                      {isField ? (
                                        isDropdown ? (
                                          <CreatableSelect
                                            isClearable
                                            id={`${dataElem?.key}_${_id}`}
                                            placeholder={`Enter ${dataElem.name}`}
                                            options={dropDownOptions?.map(
                                              (o) => {
                                                return {
                                                  value: o,
                                                  label: o,
                                                };
                                              }
                                            )}
                                            value={
                                              fieldValue
                                                ? {
                                                    value: fieldValue,
                                                    label: fieldValue,
                                                  }
                                                : ""
                                            }
                                            onChange={(e) => {
                                              let sVal = e?.value || "";
                                              if (sVal !== value) {
                                                handleEdit({
                                                  [dataElem?.key]: sVal,
                                                  _id: valElem?._id,
                                                  created_by: userDetails?._id,
                                                });
                                                setTimeout(() => {
                                                  setFieldValue("");
                                                  setEditID("");
                                                }, 500);
                                              }
                                            }}
                                          />
                                        ) : (
                                          <div className="text-input-block">
                                            <input
                                              id={`${dataElem?.key}_${_id}`}
                                              placeholder={`Enter ${dataElem.name}`}
                                              type="text"
                                              value={fieldValue}
                                              onChange={(e) => {
                                                setFieldValue(e.target.value);
                                              }}
                                              onBlur={() => {
                                                if (
                                                  `${value}` !== `${fieldValue}`
                                                ) {
                                                  handleEdit({
                                                    [dataElem?.key]: fieldValue,
                                                    _id: valElem?._id,
                                                    created_by:
                                                      userDetails?._id,
                                                  });
                                                } else {
                                                  setFieldValue("");
                                                  setEditID("");
                                                }
                                              }}
                                              onKeyUp={(e) => {
                                                if (e.keyCode === 13) {
                                                  if (
                                                    `${value}` !==
                                                    `${fieldValue}`
                                                  ) {
                                                    handleEdit({
                                                      [dataElem?.key]:
                                                        fieldValue,
                                                      _id: valElem?._id,
                                                      created_by:
                                                        userDetails?._id,
                                                    });
                                                  } else {
                                                    setFieldValue("");
                                                    setEditID("");
                                                  }
                                                } else if (e.keyCode === 27) {
                                                  setFieldValue("");
                                                  setEditID("");
                                                } else {
                                                  // Nothing
                                                }
                                              }}
                                            />
                                          </div>
                                        )
                                      ) : value ? (
                                        <div>{value}</div>
                                      ) : isAnyFieldValue ? (
                                        <div>--</div>
                                      ) : (
                                        <div className="add-info-block">
                                          --Add Info--
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Collapse>
                  </div>
                );
              })}
            </div>
          )}{" "}
        </>
      )}
    </div>
  );
};

export default Vitals;
