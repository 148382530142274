import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import "./index.scss";
import { toast } from "react-toastify";
import { apiUrl, PORT } from "../../environment";
import { ReactComponent as CrossSvg } from "../../assets/img/close-square-svgrepo-com.svg"
import { v4 as uuidv4 } from 'uuid';
import Calendar from "../../assets/img/calendar_today.svg";
import ItemsBlock from "./ItemsBlock";
import Discount from "./ItemsBlock/discount";
import Taxes from "./ItemsBlock/taxes";
import ApiClient from "../../api-client";
import { getAllActiveServicesByPetId } from "../../context/actions/service";
import { getAndSearchAllPets } from "../../context/actions/patient-module/patient/pet";
import ProductBlock from "./ItemsBlock/products";
import ServiceBlock from "./ItemsBlock/services";
import { addEstimate } from "context/actions/invoice";

export default function AddEstimate() {
  const [selectedPet, setSelectedPet] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [status, setStatus] = useState("");
  const [discount, setDiscount] = useState("");
  const [tax, setTax] = useState("");
  const [issuedDate, setIssuedDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [description, setDescription] = useState("");
  const { petList } = useSelector((state) => state.petReducer);
  const { inventory } = useSelector((state) => state.inventoryReducer);
  const userDetail = useSelector((state) => state.user.user_details);
  const token = useSelector((state) => state.user.token);
  const [products, setProducts] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [payer, setPayer] = useState(false);
  const history = useHistory();
  const [service, setService] = useState([]);

  const [services, setServices] = useState([])



  const statuses = [
    { value: "Pending", label: "Pending" },
    { value: "Fully Paid", label: "Fully Paid" },
    { value: "Partial Paid", label: "Partial Paid" },
    { value: "Pending Review", label: "Pending Review" },
  ];

  let lineItemDefaultState = {
    id: uuidv4(),
    line_item_name: "",
    quantity: 1,
    note: "",
    price: 0
  }

  const [lineItems, setLineItems] = useState([])
  const dispatch = useDispatch()
  const { clinic_details } = useSelector((state) => state.clinicReducer);



  useEffect(() => {
    dispatch(getAndSearchAllPets({}), () => {}) 
   
  }, []);




  // useEffect(() => {
  //   setLineItems([lineItemDefaultState])
  //   setTaxes([taxDefaultState])
  //   setDiscounts([discountDefaultState])
  // }, [])


  const addNewLineItem = (e) => {
    e.preventDefault();
    setLineItems((lineItemsPrev) => [...lineItemsPrev, lineItemDefaultState])
  }

  const removeLineItem = (e, id) => {
    e.preventDefault();
    let lineItemsUpdated = lineItems.filter((ele) => ele.id != id)
    setLineItems(lineItemsUpdated)
  }

  const handleLineItemChange = (e, index) => {
    e.preventDefault();
    let lineItemsArr = [...lineItems];

    let prevEle = lineItemsArr[index];

    let updatedEle = { ...prevEle, [e.target.name]: e.target.value };

    lineItemsArr[index] = updatedEle;
    setLineItems(lineItemsArr)
  }


  let taxDefaultState = {
    id: uuidv4(),
    tax_name: "",
    tax_percentage: 0
  }

  const [taxes, setTaxes] = useState([])


  const addNewTax = (e) => {
    e.preventDefault();
    setTaxes((taxesPrev) => [...taxesPrev, taxDefaultState])
  }

  const removeTax = (e, id) => {
    e.preventDefault();
    let taxesUpdated = taxes.filter((ele) => ele.id != id)
    setTaxes(taxesUpdated)
  }

  const handleTaxChange = (e, index) => {
    e.preventDefault();
    let taxesArr = [...taxes];

    let prevEle = taxesArr[index];

    let updatedEle = { ...prevEle, [e.target.name]: e.target.value };

    taxesArr[index] = updatedEle;
    setTaxes(taxesArr)
  }


  let discountDefaultState = {
    id: uuidv4(),
    discount_name: "",
    discount_type: "",
    discount_percentage: 0
  }

  const [discounts, setDiscounts] = useState([])


  const addNewDiscount = (e) => {
    e.preventDefault();
    setDiscounts((discountsPrev) => [...discountsPrev, discountDefaultState])
  }


  const removeDiscount = (e, id) => {
    e.preventDefault();
    let discountsUpdated = discounts.filter((ele) => ele.id != id)
    setDiscounts(discountsUpdated)
  }

  const handleDiscountChange = (e, index) => {
    e.preventDefault();
    let discountsArr = [...discounts];

    let prevEle = discountsArr[index];

    let updatedEle = { ...prevEle, [e.target.name]: e.target.value };

    discountsArr[index] = updatedEle;
    setDiscounts(discountsArr)
  }


  const handleProductQuantityChange = (e, index) => {
    let productsArr = products
    let ele = productsArr[index];
    ele.quantity = e.target.value;
    ele.amount = ele.quantity * ele.unit_price
    productsArr[index] = ele;
    setProducts([...productsArr])
  }

  const handleServiceQuantityChange = (e, index) => {
    let servicesArr = services
    let ele = servicesArr[index];
    ele.quantity = e.target.value;
    ele.amount = ele.quantity * ele.unit_price
    servicesArr[index] = ele;
    setService([...servicesArr])
  }

  const handelChange = (e) => {
    const { petOwner, _id } = e;
    setSelectedPet(e);
    setPayer(e);
   
  };



  const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);


  const addInvoiceHandle = () => {
    const data = {
      description: description,
      products: products,
      patient_id: selectedPet?._id,
      discount: parseInt(discount),
      tax: parseInt(tax),
      payee: payer?.petOwner?._id,
      clinic_id: clinic_settings?.clinic_id,
      created_by: userDetail?._id,
      services: service,
      lineItems: lineItems,
      discounts: discounts,
      taxes: taxes
    };


    dispatch(addEstimate(data, (res) => {
      history.push("/invoices");
    }))
  };

  const [invoiceTotal, setInvoiceTotal] = useState(0)
  const [totalWithoutTaxesAndDiscounts, setTotalWithoutTaxesAndDiscounts] = useState(0)

  useEffect(() => {
    let total = 0;
    let discountsTotal = 0
    let taxTotal = 0

    lineItems.forEach((ele) => {
      if (ele.line_item_name != '') {
        total += +ele.price
      }
    })

    service.forEach((ele) => {
      total += parseFloat(ele.amount)
    })
    products.forEach((ele) => {
      total += parseFloat(ele.amount)
    })

    setTotalWithoutTaxesAndDiscounts(total)

    if (total > 0) {
      discounts.forEach((ele) => {
        if (ele.discount_name != '') {
          if (ele.discount_type == "amount") {
            let discountValInt = +ele.discount_percentage;
            discountsTotal += discountValInt;
          }
          else if (ele.discount_type == "percentage") {
            let totalInt = +total
            let discountValInt = +ele.discount_percentage;
            let percentageVal = totalInt * discountValInt / 100;
            discountsTotal += percentageVal;
          }
        }
      })
    }
    if (total > 0) {
      taxes.forEach((ele) => {
        if (ele.tax_name != '') {
          let totalInt = +total
          let taxTotalInt = +ele.tax_percentage;
          let percentageVal = totalInt * taxTotalInt / 100
          taxTotal += percentageVal;
        }
      })
    }

    let finalTotal = total + taxTotal - discountsTotal

    setInvoiceTotal(parseFloat(finalTotal))

  }, [lineItems, discounts, taxes, service, products])

  const [addItem, setAddItem] = useState(false)
  const [addService, setAddService] = useState(false)
  const [addProduct, setAddProduct] = useState(false)
  const [addDiscount, setAddDiscount] = useState(false)
  const [addTax, setAddTax] = useState(false)

  return (
    <>
    <div className="p-3">
      <div className="pb-3 flex  align-items-center gap-y-3 justify-between">
        <div>
          <p className="text-[20px] text-[#282E65] font-[600]">Calculate Estimations</p>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="flex-1 pe-2 w-[70%]">
          
          <div className="flex  w-[100%]">
            <div className=" pe-2 w-[100%]">
              <div className="bg-[#f8f8f8] rounded-lg mt-0 mx-0 p-[15px]">
                <h2 className="font-['Inter']  text-[#282E65] font-[600] my-3 text-[24px]">
                  Patient & Payer Information
                </h2>
                <div className="flex gap-3 ">
                  <div className="w-100">
                    <div className="bg-[#f8f8f8] rounded-lg mt-0 mx-0 ] pt-15px  ">
                      <div className="flex w-[100%] items-center">
                        <div className="w-50 p-0">
                          <label className="block mb-2 text-sm font-['Inter']  text-[#282E65] text-[16px] font-[400]">
                            Patient Information
                          </label>
                          <Select
                            className="w-100 py-2.5 px-0 custom-select  font-['Inter']text-[#282E65] text-sm"
                            styles={{
                              placeholder: (baseStyles) => ({
                                ...baseStyles,
                                color: '#282E65' ,
                              }),
                            }}
                            options={petList && petList?.length > 0 && petList?.map((data) => ({
                              id: data?._id,
                              value: `${data?.pet_name} (${data?.vrn})`,
                              label: (
                                <div className="flex items-center gap-1 ">
                                  <img
                                    src={data?.pet_image}
                                    className="w-[35px] h-[35px] rounded-full"
                                  />{" "}
                                  {data?.pet_name} ({data?.vrn})
                                </div>
                              ),
                              ...data,
                            }))}
                            placeholder={'Please Select Patient'}
                            value={selectedPet}
                            // isMulti={true}

                            onChange={handelChange}
                          />
                        </div>

                        <div className={payer ? "w-[71%]" : "w-[71%]"}>

                          <div className="flex items-center gap-2 ">
                            {payer ? (
                              <>
                                <div className="ms-4 mt-4 flex items-center">
                                  <img
                                    src={payer?.petOwner?.image}
                                    className="w-[35px] h-[35px] rounded-full"
                                  />
                                  <p className="ms-3">{payer?.petOwner?.owner_name}</p>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                  
                    </div>
                  </div>

                </div>


              </div>

              <div className="bg-[#f8f8f8] rounded-lg mt-4 mx-0 p-[15px]  ">
                <h2 className="font-['Inter']  text-[#282E65]  my-3 font-[600]  text-[24px]">
                  Products info
                </h2>
                
                <div className="relative py-2 flex justify-between items-center">
                    <label className="block mb-2 mt-4 text-sm font-['Inter']  text-[#282E65] text-[16px] font-[400]">
                      Products
                    </label>
                    <div>
                      <button className="add-btn bg-[transparent] border-[#e5e7eb] border-solid border-2 font-[600] mt-3 font-['Inter'] text-[#3D3EFD] text-[12px] uppercase px-3.5 py-2.5 rounded-lg w-[100%]" type="button" onClick={() => setAddProduct(!addProduct)}>ADD NEW</button>


                    </div>

                  </div>

                  


                  <ProductBlock addNew={addProduct} setAddItem={setAddProduct} lineItems={products} setLineItems={setProducts} />

                 {/*} <ProductBlock handleProductQuantityChange={handleProductQuantityChange} products={products} />*/}


              
                <div className="w-100">

          


                  <div className="relative py-2 flex justify-between items-center">
                    <label className="block mb-2 mt-4 text-sm font-['Inter']  text-[#282E65] text-[16px] font-[400]">
                      Services
                    </label>
                    <div>
                      <button className="add-btn bg-[transparent] border-[#e5e7eb] border-solid border-2 font-[600] mt-3 font-['Inter'] text-[#3D3EFD] text-[12px] uppercase px-3.5 py-2.5 rounded-lg w-[100%]" type="button" onClick={() => setAddService(!addService)}>ADD NEW</button>


                    </div>

                  </div>
                  <ServiceBlock addNew={addService} setAddItem={setAddService} lineItems={service} setLineItems={setService} />

                  

                  <div className="relative py-2 flex justify-between items-center">
                    <label className="block mb-2 mt-4 text-sm font-['Inter']  text-[#282E65] text-[16px] font-[400]">
                      Items
                    </label>
                    <div>
                      <button className="add-btn bg-[transparent] border-[#e5e7eb] border-solid border-2 font-[600] mt-3 font-['Inter'] text-[#3D3EFD] text-[12px] uppercase px-3.5 py-2.5 rounded-lg w-[100%]" type="button" onClick={() => setAddItem(!addItem)}>ADD NEW</button>


                    </div>

                  </div>
                  <ItemsBlock addNew={addItem} setAddItem={setAddItem} lineItems={lineItems} setLineItems={setLineItems} />
                  <div className="relative py-2 flex justify-between items-center">
                    <label className="block mb-2 mt-4 text-sm font-['Inter']  text-[#282E65] text-[16px] font-[400]">
                      Discounts
                    </label>
                    <div>
                      <button type="button" className="add-btn bg-[transparent] border-[#e5e7eb] border-solid border-2 font-[600] mt-3 font-['Inter'] text-[#3D3EFD] text-[12px] uppercase px-3.5 py-2.5 rounded-lg w-[100%]" onClick={() => setAddDiscount(!addDiscount)}>ADD NEW</button>


                    </div>
                  </div>
                  <Discount addNew={addDiscount} setAddDiscount={setAddDiscount} discounts={discounts} setDiscounts={setDiscounts} />
                  <div className="relative py-2 flex justify-between items-center">
                    <label className="block mb-2 mt-4 text-sm font-['Inter']  text-[#282E65] text-[16px] font-[400]">
                      Taxes
                    </label>
                    <div>
                      <button type="button" className="add-btn bg-[transparent] border-[#e5e7eb] border-solid border-2 font-[600] mt-3 font-['Inter'] text-[#3D3EFD] text-[12px] uppercase px-3.5 py-2.5 rounded-lg w-[100%]" onClick={() => setAddTax(!addTax)}>ADD NEW</button>


                    </div>
                  </div>
                  <Taxes addNew={addTax} setAddTax={setAddTax} taxes={taxes} setTaxes={setTaxes} />
                </div>
              </div>

              {/* services section */}




              <div className="flex justify-end  mt-1 mx-5 p-[15px]">

              </div>
            </div>
          </div>

        </div>
        <div className="appointment_row invoice-appointment-row">
          <div className="bg-[#f8f8f8] rounded-lg mx-0 p-[15px] ">
            <h2 className="font-['Inter']  text-[#282E65] text-[24px] font-[600] my-3">
              Estimate Summary
            </h2>
            {service.length > 0 &&
              <>
                <h3 className="mt-2 mb-1">Services</h3>
                {
                  <div className="flex gap-3 ">
                    <div className="w-100">

                      <table class="table table-auto w-[100%] table-striped invoice-summary-table">
                        <thead>
                          <tr>
                            <th style={{width: '40%'}} className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1">Name</th>
                            <th style={{width: '10%'}} className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1">Qty</th>
                            <th  style={{width: '20%'}}className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1">Price</th>
                            <th style={{width: '30%'}} className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1">Total Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            service?.map((ele, index) => {
                              return (
                                <tr className="bg-[#f2f2f2]">
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1"> {ele.serviceName}</td>
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1"> {ele.quantity}</td>
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">${(+ele?.unit_price)?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}</td>
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">${ele.amount.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}</td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>

                  </div>
                }
              </>
            }



            {
              products?.length > 0 &&
              <>
                <h3 className="mt-2 mb-1">Products</h3>
                {
                  <div className="flex gap-3 ">
                    <div className="w-100">

                      <table class="table table-auto w-[100%] table-striped invoice-summary-table">
                        <thead>
                          <tr>
                            <th style={{width: '40%'}} className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1">Name</th>
                            <th style={{width: '10%'}} className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1">Qty</th>
                            <th style={{width: '20%'}} className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1">Price</th>
                            <th style={{width: '30%'}} className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1">Total Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            products?.map((ele, index) => {
                              return (
                                <tr className="bg-[#f2f2f2]">
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1"> {ele.product_name}</td>
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1"> {ele.quantity}</td>
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">${ele.unit_price.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}</td>
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">${ele.amount.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}</td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>

                  </div>
                }

              </>
            }

            {lineItems?.length > 0 &&
              <>

              <h3 className="mt-3 font-['Inter']  text-[#282E65] text-[16px] font-[400] mt-2 mb-2">Items List</h3>
                <div className="flex gap-3 ">
                  <div className="w-100">
                    <table class="table table-auto w-[100%] table-striped invoice-summary-table">
                      <thead>
                        <tr>
                          <th style={{width: '40%'}} className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1">Name</th>
                          <th style={{width: '30%'}} className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1">Qty</th>
                          {/* <th className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1">Price</th> */}
                          <th style={{width: '30%'}} className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          lineItems.map((ele, index) => {
                            if (ele.line_item_name != '') {
                              return (

                                <tr className="bg-[#f2f2f2]">
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">{ele.line_item_name}</td>
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">{ele.quantity.toLocaleString('en-US')}</td>
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">${ele.price.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}</td>
                                  {/* <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">${ele.amount}</td> */}
                                </tr>

                              )
                            }

                          })
                        }


                      </tbody>
                    </table>




                  </div>

                </div>
              </>

            }

            {discounts?.length > 0 &&

              <>

                <h3 className="mt-3 font-['Inter']  text-[#282E65] text-[16px] font-[400] mt-2 mb-2">Discounts</h3>

                <div className="flex gap-3 ">
                  <div className="w-100">
                    <table class="table table-auto w-[100%] table-striped invoice-summary-table">
                      <thead>
                        <tr>
                          <th style={{width: '40%'}} className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1">Name</th>
                          <th style={{width: '30%'}} className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1">Value</th>
                          <th style={{width: '30%'}} className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          discounts.map((ele, index) => {
                            if (ele.discount_name != '') {
                              return (
                                < tr className="bg-[#f2f2f2]">
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">{ele.discount_name}</td>

                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1"> {ele.discount_type !== "percentage" ? "$" : ""}{ele.discount_percentage.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}{ele.discount_type == "percentage" ? "%" : ""}</td>

                                  {(ele.discount_type == "percentage") ? <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">${(+ele.discount_percentage / 100 * totalWithoutTaxesAndDiscounts).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}</td> : <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">${ele.discount_percentage.toLocaleString('en-US').toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}</td>}
                                </tr>
                              )
                            }
                          })
                        }

                      </tbody>
                    </table>
                  </div>

                </div>
              </>
            }

            {taxes?.length > 0 &&
              <>

                <h3 className="mt-3 font-['Inter']  text-[#282E65] text-[16px] font-[400] mt-2 mb-2">Taxes</h3>

                <div className="flex gap-3 ">
                  <div className="w-100">
                    <table class="table table-auto w-[100%] table-striped invoice-summary-table">
                      <thead>
                        <tr>
                          <th style={{width: '40%'}} className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1">Name</th>
                          <th style={{width: '30%'}} className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1">%</th>
                          <th style={{width: '30%'}} className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          taxes.map((ele, index) => {
                            if (ele.tax_name != '') {
                              return (< tr className="bg-[#f2f2f2]">
                                <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">{ele.tax_name}</td>
                                <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">{ele.tax_percentage.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}%</td>
                                <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">${(+ele.tax_percentage / 100 * totalWithoutTaxesAndDiscounts).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}</td>
                              </tr>)
                            }
                          })
                        }

                      </tbody>
                    </table>
                  </div>

                </div>

              </>
            }
            <br />
            <hr />
            <div className="flex gap-3 mt-2 justify-between">

              <div className="w-50">
                <p className="font-['Inter']  text-[#282E65] text-[14px] font-[600] ">
                  Total
                </p>
              </div>

              <div className="w-50 text-right">
                <p className="font-['Inter']  text-[#282E65] text-[14px] font-[600] ">
                  ${invoiceTotal.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
                </p>
              </div>

            </div>
            <div className="w-[100%]">
              <button
                onClick={addInvoiceHandle}
                className="bg-[#3D3EFD] mt-3 font-['Inter'] text-[#fff] text-[14px] uppercase px-3.5 py-2.5 rounded-lg w-[100%]"
              >
                Save Estimate
              </button>
            </div>
          </div>
        </div>

      </div >
</div>




    </>
  );


}

