const initialState = {
	isSidePanel: false
}


export const sidebarPanelReducer = (state = initialState, action) => {
	switch (action.type) {

		case "SET_SIDEPANEL": {
			return { ...state, isSidePanel: action.data }
		}

		default:
			return state;

	}
};