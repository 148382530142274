import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button } from "reactstrap";
import moment from "moment";
import PatientListPanel from "shared/Calendar/PatientListPanel";
import { patientListPanelAction } from "context/actions/patientListPanelAction";
import { Modal, Spinner } from "react-bootstrap";
import {Input}  from "reactstrap";
import { MdOutlinePets } from "react-icons/md";
import { getPetDetailsNoReducer } from "context/actions/patient-module/patient/pet";
import { assignUnallocatedResult } from "context/actions/clinicSettings";
import "./index.scss";
import { getPendingWorkList, getUnallocatedInHouse, getUnallocatedRefLab } from "context/actions/clinicSettings";
import { getActiveIntegrations } from "context/actions/clinicSettings";
import PaginationBlock from "shared/PaginationBlock";
import NodataFoundBlock from "shared/NoDataFoundBlock";

const WorkList = () => {
  const dispatch = useDispatch();

  const [pendingWorkList, setPendingWorkList] = useState([])
  const [activeIntegrations, setActiveIntegrations] = useState([]);

  const [unallocatedRefLab, setUnallocatedRefLab] = useState([]);
  const [selectedResult, setSelectedResult] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [selectedPatient, setSelectedPatient] = useState(false)

  const [unallocatedLimit, setUnallocatedLimit] = useState(5);
  const [unallocatedPage, setUnallocatedPage] = useState(1);

  const [filterData, setFilterData] = useState({
    search: ''
  })



  const patientListPanelState = useSelector(
    (state) => state.patientListPanelReducer.isPatientListPanel
  );
  const { clinic_details }= useSelector(
    (state) => state.clinicReducer
  );
  
  const handleSave = () => {
    if(selectedPatient && selectedResult){
    setBtnLoading(true)
    dispatch(assignUnallocatedResult({result: selectedResult?._id, patient: selectedPatient?._id, clinic_id: clinic_details?._id}, (response) => {
      if(response?.status){
        
        setUnallocatedPage(1)
        setSelectedPatient(false);
        setSelectedResult(false);
        setBtnLoading(false)
        getUnallocated();

      }
    }))}

  }

  const onHide = () => {
    setSelectedPatient(false);
    setSelectedResult(false);

  }

  const getPatientDetails = (patient_id) => {
    dispatch(getPetDetailsNoReducer(patient_id,  (response) => {
      if(response?.status){
        setSelectedPatient(response?.response_data)
        dispatch(patientListPanelAction(false))
      }
    }))
  }


  const getUnallocated = () => {
    dispatch(getUnallocatedRefLab({page: unallocatedPage, limit: unallocatedLimit, ...filterData}, (response) => {
      if(response?.status){
        setUnallocatedRefLab(response?.response_data)
      }
    }))


  }

  const getData = () => {

    dispatch(getPendingWorkList((response) => {
      if(response?.status){
        setPendingWorkList(response?.response_data)
      }
    }))
   getUnallocated();

  }

  useEffect(() => {
    getUnallocated()
  }, [unallocatedLimit, unallocatedPage])


  useEffect(() => {

    setUnallocatedPage(1)
    getUnallocated()

  }, [filterData])


  useEffect(() => {
    getData();

    dispatch(getActiveIntegrations((response) => {
      if(response?.status && response?.response_data?.length){
          setActiveIntegrations(response?.response_data)
      }
  }));
  }, [])
 

  return (
    <>

    {selectedPatient && (
        <Modal
          show
         
      
          onHide={() => {
            setSelectedPatient(null);
            onHide();
          }}
        >
          <Modal.Body>
            <h4 className="text-center text-26-600 color-2E65 mb-5 pt-3">
              Are you sure?
            </h4>
            <p className="fa-center justify-content-center gap-2 text-16-400 color-2E65">
              <div>You want to assign result {selectedResult?.idexxOrderId || selectedResult?.antechClinicAccession || selectedResult?.antechLabAccession} to </div>
              <div className="fa-center gap-1">
                {selectedPatient?.pet_image ? (
                  <span>
                    <img
                      src={selectedPatient?.pet_image}
                      alt="pet_image"
                      className="br-4 h-20"
                    />
                  </span>
                ) : (
                  <span className="br-4 h-20 w-20 f-center bg-e3e3">
                    <MdOutlinePets size={15} />
                  </span>
                )}
                <span className="text-16-600 color-2E65">
                  {selectedPatient?.pet_name}
                </span>
              </div>
            </p>

            <div className="table-listing-blc overflow-auto">
         

          <Table responsive className="checkbox-listing">
            <thead>
              <tr>
                <th>Consult</th>
                <th>Lab</th>
                <th>Patient</th>
                <th>Owner</th>
                <th>Date & Time</th>
                <th>Vet</th>
                <th>Service(s)</th>
                <th>Notes</th>
              </tr>
            </thead>
          
            <tbody>
                <tr>
                  <td>{selectedResult?.idexxOrderId || selectedResult?.antechClinicAccession}</td>
                  <td>{selectedResult?.idexxOrderId ?  'Idexx': 'Antech'}</td>
                  <td>{selectedResult?.petName}</td>
                  <td>{`${selectedResult?.ownerFirstName} ${selectedResult?.ownerLastName}`}</td>

                  <td>{moment(selectedResult?.updatedDate).format('MM/DD/YYYY hh:mm a')}</td>
                  <td>{selectedResult?.veterinarian || '--'}</td>
                  <td>{selectedResult?.unitCodes  ? selectedResult?.unitCodes?.join(", ") : selectedResult?.test_results?.map(i => i?.test_details?.test_name).join(", ")}</td>
                  <td>{selectedResult?.clinic_id?.clinic_name}</td>
                  <td></td>
  
                </tr>
              </tbody> 
          </Table>
          </div>
                
            <div className="d-flex justify-content-center gap-3 mt-5 pb-3">
              <Button
                color="link"
                onClick={() => {
                  onHide();
                  setSelectedPatient(false);
                  setSelectedResult(false)
                }}
                className="btn btn-outline-light cps-40 cpe-40"
              >
                No
              </Button>
              <Button
                color="success"
                className="btn btn-outline-light cps-40 cpe-40"
                onClick={handleSave}
              >
                Yes {btnLoading && <Spinner size="sm" className="ms-2" />}
              </Button>
            </div>
          </Modal.Body>
        </Modal>)
      }


      <div className="form-template-wrapper">


      {activeIntegrations?.filter(i => i?.app === 'Idexx')?.length > 0 ? 
      <>
      <div className="form-template-blc">
        <div className="head d-flex">
          <div className="left-col">
            <h3>IDEXX VetLab Station Pending Requests</h3>
          </div>
      
          
        </div>
        <div className="table-listing-blc overflow-auto">
        {pendingWorkList?.length > 0 ?
          <Table striped responsive className="checkbox-listing">
            <thead>
              <tr>
                <th style={{ minWidth: "150px" }}>Contact</th>
                <th>Patient</th>
                <th>Consult</th>
                <th>Date & Time</th>
                <th>Vet</th>
                <th>Machine</th>
                <th>Service</th>
                <th>Notes</th>
              </tr>
            </thead>
          
            <tbody>
              
             {(pendingWorkList || []).map((item, index) => (
                <tr key={index}>
                  <td>{item?.pet_id ? item?.pet_id?.pet_owner?.firstname + '  ' + item?.pet_id?.pet_owner?.lastname : '--'}</td>
                  <td>{item?.pet_id?.name || '--'}</td>
                  <td>{item?.idexx_code}</td>
                  <td>{moment(item?.updatedDate).format('MM/DD/YYYY hh:mm a')}</td>
                  <td>{item?.veterinarian}</td>
                  <td></td>
                  <td>{item?.clinic_id?.clinic_name}</td>
                  <td></td>
                  
                </tr>
              ))}
                          </tbody>

          </Table>  :
              <NodataFoundBlock />
              
              }
        </div>
      </div> 
      
      </>
        : <></>}


      <div className="form-template-blc">
        <div className="head d-flex">
          <div className="left-col">
            <h3>Unallocated Diagnostics</h3>
          </div>
            {/* <div className="right-col ms-auto">
              <Button
                onClick={() => {}}
                color="link"
                className="d-inline-flex align-items-center justify-content-center add-btn p-0"
              >
                <span className="icomoon-plus-btn"></span>
              </Button>
            </div> */}
            
          
        </div>

        <div
          className={`table-supplier-filter-blc-wrapper d-flex align-items-center overflow-auto flex-shrink-0`}
        >
      <div className="left-col">
        <div  className="table-list-filter-blc d-flex align-items-center">
          <div className="search-cell col-cell position-relative">
            <span className="icomoon-search d-inline-flex align-items-center justify-content-center position-absolute"></span>
            <Input style={{backgroundColor:'white'}} placeholder="Search" value={filterData?.search} onChange={(e) =>  setFilterData((prev) => ({
                  ...prev,
                  search: e.target.value,
                }))} />
          </div>
          <div className="input-cell col-cell">
            <Input style={{backgroundColor:'white'}} type="select" name="select"  value={filterData?.lab}  onChange={(e) =>  setFilterData((prev) => ({
                  ...prev,
                  lab: e.target.value,
                }))}>
              <option value="">All Labs</option>
              {activeIntegrations?.filter(i => i?.app === 'Antech')?.length > 0 && <option value="antech">Antech</option> }
              {activeIntegrations?.filter(i => i?.app === 'Idexx')?.length > 0 && <option value="idexx">Idexx</option> }

            </Input>
				  </div>
          <div className="input-cell col-cell">
            <Input style={{backgroundColor:'white'}} type="select" name="select"  value={filterData?.modality}  onChange={(e) =>  setFilterData((prev) => ({
                  ...prev,
                  modality: e.target.value,
                }))}>
              <option value="">All Modalities</option>
              <option value="INHOUSE">In House</option> 
              <option value="REFLAB">Reference Lab</option>
            </Input>
				  </div>
        </div>
        </div>
        </div>
        <div className="table-listing-blc overflow-auto">
          { unallocatedRefLab?.docs?.length > 0 ?

          <Table responsive className="checkbox-listing">
            <thead>
              <tr>
                <th>Consult</th>
                <th>Lab</th>
                <th>Patient</th>
                <th>Owner</th>
                <th>Date & Time</th>
                <th>Vet</th>
                <th>Service(s)</th>
                <th>Notes</th>
              </tr>
            </thead>
          
            <tbody>
          
            { (unallocatedRefLab?.docs || []).map((item, index) => (
                <tr key={index}>
                  <td>{item?.idexxOrderId || item?.antechClinicAccession}</td>
                  <td>{item?.idexxOrderId ?  'Idexx': 'Antech'}</td>
                  <td>{item?.petName}</td>
                  <td>{`${item?.ownerFirstName} ${item?.ownerLastName}`}</td>

                  <td>{moment(item?.updatedDate).format('MM/DD/YYYY hh:mm a')}</td>
                  <td>{item?.veterinarian || '--'}</td>
                  {/*<td>{item?.test_results?.map(i => i?.test_details?.test_name).join(", ")}</td>*/}
                  <td>{item?.unitCodes?.join(", ") || item?.test_results?.map(i => i?.test_details?.test_name).join(", ")}</td>

                  <td>{item?.clinic_id?.clinic_name}</td>
                  <td></td>
                  <td style={{ textAlign: "right" }} className="action-cell">
                      <Button
                        onClick={() => {
                          setSelectedResult(item)
                          dispatch(patientListPanelAction(true))
                        }}
                        color="link"
                      
                      >
                       Assign To Patient
                      </Button>
                    </td>
                </tr>
              ))}
              </tbody> 
          </Table> :
              <NodataFoundBlock type={filterData?.search !== "" || filterData?.lab && filterData?.lab !== "" || filterData?.modality && filterData?.modality !== "" ? 'search result' : ''
              } />}
          <PaginationBlock
          limit={unallocatedLimit}
          page={unallocatedPage}
          pagingCounter={unallocatedRefLab?.pagingCounter || 1}
          docLength={unallocatedRefLab?.docs?.length || 0}
          docPage={unallocatedRefLab?.page || 1}
          totalPages={unallocatedRefLab?.totalPages || 1}
          totalDocs={unallocatedRefLab?.totalDocs || 0}
          _handleLimit={(val) => {
            setUnallocatedLimit(val);
          }}
          _handlePageChange={(val) => {
            setUnallocatedPage(val)
          }}
        />
        </div>
      </div>
       
      </div>
      <div
        className={`patient-panel-overlay position-fixed ${patientListPanelState ? "active" : ""
          }`}
        onClick={() => dispatch(patientListPanelAction(false))}
      ></div>
      <PatientListPanel
        className={`${patientListPanelState ? "active" : ""}`}
        clinicId={clinic_details?._id}
        callable={getPatientDetails}
      />
    </>
  );
};

export default WorkList;
