import React from "react";
import moment from "moment";
import './index.scss';
import UserPlaceholder from "../../../../../../../shared/UserPlaceholder";

const NoteBreifBox = (
	{
		className = '',
		note
	}
) => {

	return (
		<>
			<div className={`note-breif-box d-flex ${className}`}>
				<div className="date d-inline-flex align-items-center justify-content-center flex-shrink-0">
					<div className="inner">
						{note?.appointment?.appointment_date ? 
						<>
						{moment(note?.appointment?.appointment_date, 'DD-MM-YYYY').format('MMM')}
						<span className="d-block">
							{moment(note?.appointment?.appointment_date, 'DD-MM-YYYY').format('D')}
						</span>
						</>
						: <></>}
					</div>
				</div>
				<div className="info w-100 m-auto">
					<h2 className="mb-1">{note?.appointment?.reason}</h2>
					{note?.clinic &&
						<div className="item d-flex align-items-center">
							<div className="pic h-auto d-inline-flex flex-shrink-0">
								<span className="material-symbols-outlined">
									<svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 14 17" fill="none">
										<path d="M7.00147 9.854C7.15414 9.854 7.27914 9.8055 7.37647 9.7085C7.4738 9.61117 7.52247 9.48617 7.52247 9.3335V7.354H9.50147C9.65414 7.354 9.77914 7.3055 9.87647 7.2085C9.9738 7.11117 10.0225 6.98617 10.0225 6.8335C10.0225 6.6805 9.9738 6.5555 9.87647 6.4585C9.77914 6.36117 9.65414 6.3125 9.50147 6.3125H7.52247V4.3335C7.52247 4.1805 7.4738 4.0555 7.37647 3.9585C7.27914 3.86117 7.15414 3.8125 7.00147 3.8125C6.8488 3.8125 6.7238 3.86117 6.62647 3.9585C6.52914 4.0555 6.48047 4.1805 6.48047 4.3335V6.3125H4.50147C4.3488 6.3125 4.2238 6.36117 4.12647 6.4585C4.02914 6.5555 3.98047 6.6805 3.98047 6.8335C3.98047 6.98617 4.02914 7.11117 4.12647 7.2085C4.2238 7.3055 4.3488 7.354 4.50147 7.354H6.48047V9.3335C6.48047 9.48617 6.52914 9.61117 6.62647 9.7085C6.7238 9.8055 6.8488 9.854 7.00147 9.854ZM7.00147 15.271C8.79314 13.6737 10.1438 12.1563 11.0535 10.719C11.9631 9.28133 12.418 8.04167 12.418 7C12.418 5.361 11.8971 4.02067 10.8555 2.979C9.8138 1.93733 8.52914 1.4165 7.00147 1.4165C5.4738 1.4165 4.18914 1.93733 3.14747 2.979C2.1058 4.02067 1.58497 5.361 1.58497 7C1.58497 8.04167 2.0468 9.28133 2.97047 10.719C3.8938 12.1563 5.23747 13.6737 7.00147 15.271ZM7.00147 16.4165C6.91814 16.4165 6.8348 16.4027 6.75147 16.375C6.66814 16.3473 6.5918 16.3057 6.52247 16.25C4.50847 14.4583 3.00847 12.7917 2.02247 11.25C1.03614 9.70833 0.542969 8.29167 0.542969 7C0.542969 4.986 1.1923 3.37833 2.49097 2.177C3.78964 0.975667 5.29314 0.375 7.00147 0.375C8.7098 0.375 10.2133 0.975667 11.512 2.177C12.8106 3.37833 13.46 4.986 13.46 7C13.46 8.29167 12.9668 9.70833 11.9805 11.25C10.9945 12.7917 9.49447 14.4583 7.48047 16.25C7.42514 16.3057 7.3558 16.3473 7.27247 16.375C7.18914 16.4027 7.0988 16.4165 7.00147 16.4165Z" fill="#282E65" />
									</svg>
								</span>
							</div>
							<div className="txt">{note?.clinic?.address} West Coast Clinic</div>
						</div>
					}
					<div className="item user d-flex align-items-center justify-content-between">
						<div className="d-flex">
							<div className="pic h-auto rounded-circle overflow-hidden flex-shrink-0">
								{/*<img className="img-fluid w-100 h-100" src={require('../../../../../../assets/img/vetRecorEMR/user-thumbnail.png')} alt="User" /> */}
								<UserPlaceholder />
							</div>
							<div className="txt">{`${note?.created_by?.title ? note?.created_by?.title + ' ' : ''}${note?.created_by?.firstname} ${note?.created_by?.lastname}`}</div>
						</div>
						<div className="icon d-inline-flex">
							<span className="material-symbols-outlined">auto_mode</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default NoteBreifBox;
