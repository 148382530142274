const NoDataFound = () =>{
    return (
        <div className="py-3">
        <img
          src={require("../../assets/img/no-date-found.png")}
          className="mx-auto"
        />
      </div>
    )
}

export default NoDataFound