import { FieldArray, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Input,
  InputGroup,
  FormGroup,
  Row,
} from "reactstrap";
import { vaccineScheduleSchema} from "./schema";
import { useState } from "react";
import MedicationSelection from "./MedicationSelection";
import { addNewVaccineSchedule, getAllVaccineSchedules, updateVaccineSchedule } from "../../../../../context/actions/vaccineSchedule";
import { useClinicSpecies } from "hooks/useClinicSpecies";




const VaccineScheduleFormPanel = ({
  isActive,
  toggleVaccineSchedulePanel,
  vaccineSchedule
}) => {
  const dispatch = useDispatch();

  const clinicSpecies = useClinicSpecies();

  const {clinic_details} = useSelector(state => state.clinicReducer)
  const {species} = useSelector(state => state?.appContentReducer)
  const [isSelectingItem, setIsSelectingItem] = useState(false);

  const vaccineScheduleInit = {
    type: vaccineSchedule?.type || '',
    species: vaccineSchedule?.species?._id || '',
    durations: vaccineSchedule?.durations || [], 
    items: vaccineSchedule?.items || [],
  
  };



  const submitForm = (values, { errors }) => {
    console.log({...values, clinic_id: clinic_details?._id});

    const callback = (response) => {
      if(response?.status){
      dispatch(getAllVaccineSchedules(clinic_details?._id, () => {}));
    toggleVaccineSchedulePanel();}}
    if(vaccineSchedule)
      {
        dispatch(updateVaccineSchedule(
          {...values,
          _id: vaccineSchedule?._id, 
          durations: values?.durations?.map(i => {return({...i, type: values?.type === 'Young' ? 'one-time' : 'recurring' })}), 
          clinic_id: clinic_details?._id}, 
          callback))
      }
    else{  
      dispatch(
        addNewVaccineSchedule({...values, 
          durations: values?.durations?.map(i => {return({...i, type: values?.type === 'Young' ? 'one-time' : 'recurring' })}), 
          clinic_id: clinic_details?._id}, 
          callback))}
  };

  return (
    <>
      <div
        className={`vaccine-schedule-panel-wrapper fixed flex flex-col ${
          isActive && "active"
        }`}
        style={{maxWidth: '40%', minWidth: '40%'}}

      >
        <Formik
          initialValues={vaccineScheduleInit}
          enableReinitialize
          validationSchema={vaccineScheduleSchema}
          onSubmit={submitForm}
        >
          {(formik) => (
            <Form className="max-h-[95%] overflow-y-auto overflow-x-hidden">


             
              
              <FieldArray name="items">
                {({ remove, push }) => (
                  <>
                    {isSelectingItem && (
                      <MedicationSelection
                      selectMedicationFn={(values) => {
                          push(values);
                          setIsSelectingItem(false);
                        }}
                        closePanel={toggleVaccineSchedulePanel}
                      />
                    )}

                    {!isSelectingItem && (
                      <>
                      <div className="head d-flex flex-column align-items-center">
                        <div className="d-flex flex-row w-100">
                          <h2>{vaccineSchedule ? 'Edit' : 'Add'} Vaccine Schedule</h2>
                          <Button
                            color="link"
                            className="ms-auto panel-close-btn"
                            onClick={toggleVaccineSchedulePanel}
                          >
                            <span className="material-symbols-outlined">
                              close
                            </span>
                          </Button>
                        </div>
                      </div>


									
											<FormGroup>
                        <div className="d-flex flex-row align-items-center justify-between gap-[20px]">
                        <label>Species</label>
												<Input
                            style={{width: '70%'}}

													type="select"
													name="species"
													id="species"
													isRequired={true}
													value={formik?.values?.species || species[0]}
													onChange={(e) => {
														
														formik.handleChange(e);
													}}
												>
                          <option defaultValue value=''>Select Species</option>
													{(species || []).map((item, i) =>
														<option key={i} value={item._id} style={{ textTransform: "capitalize" }}>{clinicSpecies(item?._id) || item.species_name}</option>
													)}
												</Input>
                        </div>
												{formik?.errors?.species && formik?.touched?.species && formik?.errors?.species}

											</FormGroup>

                      <FormGroup>
                        <div className="d-flex flex-row align-items-center justify-between gap-[20px]">

                        <label>Type</label>
												<Input
                        style={{width: '70%'}}
													type="select"
													name="type"
													id="type"
													isRequired={true}
													value={formik?.values?.type}
													onChange={(e) => {
														
														formik.handleChange(e);
													}}
												>
                            <option defaultValue>Select Age</option>

														<option value={'Young'} >Young</option>
                            <option value={'Adult'} >Adult</option>
                            <option value={'Elderly'} >Elderly</option>

												
												</Input>
                        </div>
												{formik?.errors?.type && formik?.touched?.type && formik?.errors?.type}
											</FormGroup>
                      </>
                      )}

							
                    {!isSelectingItem  && (
                      <div className="head d-flex flex-column align-items-center">
                        <div className="d-flex flex-row w-100">
                          <h4>Items</h4>
                        </div>
                      </div>
                    )}

                    <div
                      style={{
                        display: isSelectingItem ? "none" : "flex",
                      }}
                      className="flex-column gap-2"
                    >
                      {formik?.values?.items?.length > 0 &&
                        formik?.values?.items?.map(
                          (item, index) => (
                            <>
                              <div className="w-full justify-between  rounded-lg bg-stone-50/90 py-[10px] px-[15px] inline-flex gap-[30px]">
                                <div className="max-w-[400px] w-full">
                                  <div className="flex px-4 py-3 bg-white rounded-lg border border-indigo-950 border-opacity-10 justify-start items-center gap-2 mb-2 ">
                                    <div className="grow  text-base font-normal">
                                      {item?.product_name || item?.service_name }
                                    </div>
                                  </div>
                                  <div className="self-stretch text-slate-500 text-sm font-normal font-['Inter'] leading-[21px]">
                                    {item.product_description || item.service_name}
                                  </div>

                                </div>
                                <div className="w-20 h-20 relative">
                                  <button
                                    className="hover:drop-shadow-md"
                                    onClick={() => remove(index)}
                                  >
                                    <img
                                      className="img-fluid w-100 h-100"
                                      src={
                                        require("../../../../../assets/img/delete_gray.svg")
                                          .default
                                      }
                                      alt="Badge"
                                    />
                                  </button>
                                </div>
                              </div>
                            </>
                          )
                        )}
                    </div>

                    {!isSelectingItem && (
                      <Row className="my-4">
                        <div className="w-full">
                          <Button
                            onClick={() => {
                              setIsSelectingItem(true);
                            }}
                            color="link"
                            className="add-section-medication-btn d-flex justify-content-center w-full"
                          >
                            <span className="icomoon-plus-btn"></span>
                          </Button>
                        </div>
                      </Row>
                    )}
                  </>
                )}
              </FieldArray>
              <FieldArray name="durations">
                {({ remove, push }) => (
                  <>

                    {!isSelectingItem  && (
                      <div className="head d-flex flex-column align-items-center">
                        <div className="d-flex flex-row w-100">
                          <h4>Times</h4>
                        </div>
                      </div>
                    )}

                    <div
                      style={{
                        display: isSelectingItem ? "none" : "flex",
                      }}
                      className="flex-column gap-2"
                    >
                      {formik?.values?.durations?.length > 0 &&
                        formik?.values?.durations?.map(
                          (duration, index) => (
                            <>
                              <div className="w-full rounded-lg bg-stone-50/90 py-[10px] px-[15px] inline-flex gap-[30px]">
                              

                                <div className="grow flex flex-col justify-center items-start">
                                  <Row className="flex mb-2">
                                    <div className="row-block flex items-center w-full">
                                      <label
                                        for={`durations.${index}.value`}
                                        className="w-36 mr-4 text-sm"
                                      >
                                        Values
                                      </label>
                                      <div className="right-col flex-fill">
                                        <InputGroup>
                                          <Input
                                            id={`durations.${index}.value`}
                                            placeholder="Select Value"
                                            onChange={(e) => {
                                              formik.handleChange(e);
                                            }}
                                            value={
                                              formik.values.durations?.[index]
                                                .value
                                            }
                                            type="Number"
                                          />
                                          <Input
                                          id={`durations.${index}.unit`}
                                          placeholder="Select Unit"
                                          onChange={(e) => {
                                            formik.handleChange(e);
                                          }}
                                          value={
                                            formik.values.durations?.[index]
                                              .unit
                                          }
                                          type="select"
                                        >
                                          <option defaultValue>Select Unit</option>
                                          <option>Weeks</option>
                                          <option>Months</option>
                                          <option>Years</option>
                                        </Input>
                                        </InputGroup>
                                          
                                      </div>
                                    </div>
                                  </Row> 
                                  

                                  

                              

                                  

                                  
                                </div>

                                <div className="w-20 h-20 relative">
                                  <button
                                    className="hover:drop-shadow-md"
                                    onClick={() => {
                                      //checkRemoveDuration(duration)
                                      remove(index);}}
                                  >
                                    <img
                                      className="img-fluid w-100 h-100"
                                      src={
                                        require("../../../../../assets/img/delete_gray.svg")
                                          .default
                                      }
                                      alt="Badge"
                                    />
                                  </button>
                                </div>
                              </div>
                            </>
                          )
                        )}
                    </div>

                    {!isSelectingItem && (
                      <Row className="my-4">
                        <div className="w-full">
                          <Button
                            onClick={() => {
                              push({type: formik?.values?.type === 'Young' ? 'one-time' : 'recurring', value: 0, unit: '', name: ''})
                            }}
                            color="link"
                            className="add-section-medication-btn d-flex justify-content-center w-full"
                          >
                            <span className="icomoon-plus-btn"></span>
                          </Button>
                        </div>
                      </Row>
                    )}
                  </>
                )}
              </FieldArray>
              {!isSelectingItem && (
                <div className="flex w-full justify-end">
                  <Button type="submit" color="success" className="w-[150px] ">
                    Save
                  </Button>
                </div>
              )}
            </Form>
          )}
        </Formik>

     
      </div>
    </>
  );
};

export default VaccineScheduleFormPanel;
