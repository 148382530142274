const initState = {
    messageLoading: false,
    groupList: [],
    privateChatList: [],
    selectedRoom: {},
    messagesList: [],
    unreadChatMsg: 0,
    unreadGroupMsg: 0,
    
}

export const realTimeChatReducer = (state = initState, action) => {
    switch (action.type) {
        case "MESSAGE_LOADING":
            return { ...state, messageLoading: action.payload }

        case "GET_GROUP_LIST":
            return { ...state, groupList: action.payload }

        case "GET_PRIVATE_CHAT_LIST":
            return { ...state, privateChatList: action.payload }

        case "SELECT_ROOM":
            return { ...state, selectedRoom: action.payload }
        case "MESSAGE_LIST":
            return { ...state, messagesList: action.payload }
        case "UNREAD_MESSAGE_CHAT_COUNT":
            return { ...state, unreadChatMsg: action.payload }
        case "UNREAD_MESSAGE_GROUP_COUNT":
            return { ...state, unreadGroupMsg: action.payload }
        default:
            return state
    }
}