import React, { useEffect, useRef } from "react";
import { Button, Input } from "reactstrap";
import SearchBlock from "./SearchBlock";
import DropdownBlock from "./DropdownBlock";
import "./index.scss";
import {
  getCurrentDay,
  getCurrentMonth,
  getCurrentWeekSpan,
  getDayValue,
  getMonthValue,
  getWeekSpanValue,
} from "../../../utils/calendar/constant";
import { useState } from "react";

import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { LuDownload } from "react-icons/lu";
import { LuDot } from "react-icons/lu";
import moment from "moment";
import { MdOutlineTableRows } from "react-icons/md";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";

const CalenderFilterMonthDay = ({
  sendCalendarView,
  calendarViewValue,
  getDate,
  openPanel,
  reportDate,
  onViewChange,
  onChangeFilter,
  onChangeModality = () => {},
  reportList,
  recentDate
}) => {
  const [currentDate, setCurrentDate] = useState(moment([moment().year(), moment().month(), moment().date()]).toDate());

  const [viewType, setViewType] = useState("tableView");
  const [modality, setModality] = useState("");


  const { diagnosisTestResults } = useSelector(
    (state) => state.diagnosticTestingReducer
  );

  const { permissionsData } = useSelector((state) => state.clinicPermissionReducer);
	const permissions = permissionsData;
  const myRef = useRef();

  const scrollToElement = () => {
    myRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  const getNextMonth = () => {
    const nextMonth = new Date(currentDate);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    nextMonth.setDate(1);
    setCurrentDate(nextMonth);
  };
  const getPreviousMonth = () => {
    const previousMonth = new Date(currentDate);
    previousMonth.setMonth(previousMonth.getMonth() - 1);
    previousMonth.setDate(1);
    setCurrentDate(previousMonth);
  };

  const daysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getMonthData = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.toLocaleString("default", { month: "short" });
    const days = daysInMonth(currentDate.getMonth(), year);

    const firstDayOfMonth = new Date(year, currentDate.getMonth(), 1).getDay();
    const lastDayOfMonth = new Date(
      year,
      currentDate.getMonth(),
      days
    ).getDay();

    return {
      year,
      month,
      days,
      firstDayOfMonth,
      lastDayOfMonth,
    };
  };

  const renderCalendar = () => {
    const monthData = getMonthData();
    const calendar = [];

    // Render days of the current month with weekdays
    for (let i = 1; i <= monthData.days; i++) {
      const date = new Date(monthData.year, currentDate.getMonth(), i);
      const weekday = date.toLocaleDateString("en-US", { weekday: "short" });
      calendar[i] = weekday;
    }
    return calendar;
  };

  const changeViewType = () => {
    viewType == "tableView"
      ? setViewType("listView")
      : setViewType("tableView");
  };

  useEffect(() => {
    getDate(currentDate);
    scrollToElement();
  }, [currentDate]);

  useEffect(() => {
    onViewChange(viewType);
  }, [viewType]);

  useEffect(() => {
    onChangeModality(modality)
  }, [modality])

  const downloadReport = () => {
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`Date: ${moment(currentDate).format("DD-MM-YYYY")}`, 14, 10);

    reportList.forEach((category, i) => {
      category.reportsByDate.forEach((reportsByDate, j) => {
        doc.autoTable({ html: `#new-table-${i}-${j}` });
      });
    });
    doc.save(
      `diagnostic_testing_${moment(currentDate).format("DD_MM_YYYY")}.pdf`
    );
  };
  return (
    <>
      <div className=" cancel-margin diagnostic-filter-block calendar-filter-blc justify-between w-100 flex flex-col md:flex-row overflow-auto flex-shrink-0">
        <div className="left-col inline-flex items-center">
          <SearchBlock onChangeFilter={onChangeFilter} />

          { viewType ? 
            <div className="item-col w-100">
            <Input onChange={(e) => setModality(e.target.value)} value={modality} type="select" name="select" id="exampleSelect">
                <option value="">All Modalities</option>
                <option value="REFLAB">Reference Lab</option>
                <option value="INHOUSE">In House</option>
              </Input>
            </div> : 
            <></>}

          

          {/* <DropdownBlock /> */}
        </div>
        <div className="right-col flex items-center flex-row gap-2">


       {diagnosisTestResults?.recentDate && ( 

          <Button
          color="link"
          className="list-view-type-btn d-inline-flex align-items-center"
              onClick={() => {
            //     const lastDate = Math.max(...reportDate.map(age => parseInt(age, 10))) + '-' + getMonthData().month + '-' + getMonthData().year;
            // let latest_date = moment(lastDate, 'DD-MMM-YYYY')
           //setCurrentDate(moment([latest_date?.year(), latest_date?.month(), latest_date?.date()]).toDate())

             setCurrentDate(
              moment(recentDate).startOf('day').toDate()
             )
            }
          }
          >

              Last Result
            </Button>
          )} 
          {/* {reportList?.length != 0 && (
            <div className="download-icon">
              <span className="txt d-inline-flex align-items-center justify-content-center">
                <LuDownload onClick={downloadReport} />
              </span>
            </div>
          )} */}
       
          <div className="date-blc input-cell d-inline-flex">
            <Button
              color="link"
              className="arw-btn lft d-inline-flex align-items-center justify-content-center p-0"
              onClick={getPreviousMonth}
            >
              <BsChevronLeft className="h-4 w-4" />
            </Button>
            <span className="txt d-inline-flex align-items-center justify-content-center">
              {`${getMonthData().month} ${getMonthData().year}`}
            </span>
            <Button
              color="link"
              className="arw-btn rt d-inline-flex align-items-center justify-content-center p-0"
              onClick={getNextMonth}
            >
              <BsChevronRight className="h-4 w-4" />
            </Button>
          </div>

          <div className="right-col ms-auto">
            <Button
              color="link"
              className="list-view-type-btn d-inline-flex align-items-center"
              onClick={changeViewType}
            >
              <span className="d-inline-flex">
                {viewType == "tableView" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="10"
                    fill="none"
                    viewBox="0 0 18 10"
                    style={{ color: "black" }}
                  >
                    <path
                      fill="#788693"
                      d="M1.074 1.75a.74.74 0 01-.525-.212A.706.706 0 01.324 1C.324.784.4.605.55.464A.74.74 0 011.074.25h12.2a.726.726 0 01.75.75.726.726 0 01-.75.75h-12.2zm0 4a.74.74 0 01-.525-.212A.706.706 0 01.324 5a.71.71 0 01.225-.538.742.742 0 01.525-.212h12.2a.728.728 0 01.75.75.726.726 0 01-.75.75h-12.2zm0 4c-.2 0-.375-.07-.525-.211A.71.71 0 01.324 9a.71.71 0 01.225-.538.742.742 0 01.525-.212h12.2a.728.728 0 01.75.75.728.728 0 01-.75.75h-12.2zm15.775.076a.84.84 0 01-.6-.238A.777.777 0 0116 9a.8.8 0 01.238-.588.797.797 0 01.587-.237c.234 0 .434.079.6.237a.78.78 0 01.25.588.797.797 0 01-.237.587.799.799 0 01-.588.238zm0-8a.84.84 0 01-.6-.238A.777.777 0 0116 1a.8.8 0 01.238-.588.797.797 0 01.587-.237c.234 0 .434.079.6.237a.78.78 0 01.25.588.797.797 0 01-.237.587.799.799 0 01-.588.238zm0 4a.84.84 0 01-.6-.238A.777.777 0 0116 5a.8.8 0 01.238-.588.797.797 0 01.587-.237c.234 0 .434.079.6.237a.78.78 0 01.25.588.797.797 0 01-.237.587.799.799 0 01-.588.238z"
                    ></path>
                  </svg>
                ) : (
                  <MdOutlineTableRows />
                )}
              </span>
              {viewType == "tableView" ? "List View" : "Table View"}
            </Button>

            
            {
              permissions?.edit_diagnostic_testing &&  viewType == "tableView" && (
                <Button
                  onClick={openPanel}
                  color="link"
                  className="ms-auto add-btn d-inline-flex"
                >
                  <span className="material-icons">add_circle</span>
                </Button>
              )
            }

          </div>
        </div>
      </div>
      <div className="calendar-grid">
        <div className="flex mt-2  gap-[4px] scroll overflow-auto pb-2">
          {renderCalendar().map((d, index) => {
            const displayMatch = index < 10 ? `0${index}` : index;
            const isMatch = reportDate?.includes(`${displayMatch}`);
            const colorName =
              currentDate.getDate() == displayMatch ? "white" : "blue";
            return (
              <button
                key={index}
                onClick={() => {
                  let curDate = index;
                  let curMonth = currentDate.getMonth();
                  let curYear = currentDate.getFullYear();
                  setCurrentDate(moment([curYear, curMonth, curDate]).toDate());
                }}
                className={`rounded-md border border-1 min-w-[40px] ${
                  currentDate.getDate() == displayMatch
                    ? "bg-[#3D3EFD] text-white"
                    : "bg-white text-black"
                } py-1`}
              >
                <p
                  className="text-center flex flex-col date-container mt-0 mr-0"
                  style={{ lineHeight: "1.4" }}
                >
                  <span
                    ref={currentDate.getDate() == displayMatch ? myRef : null}
                    className="font-['Inter'] text-[14px] font-[500] mb-0"
                  >
                    {displayMatch}
                  </span>
                  <span
                    class={`dot ${isMatch ? "visible" : "invisible"}`}
                    style={{ backgroundColor: colorName }}
                  ></span>
                  <span className="font-['Inter'] text-[12px] font-[300] mb-0">
                    {d.slice(0, 2)}
                  </span>
                </p>
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CalenderFilterMonthDay;
