import React from 'react';
import './index.scss';

const PetPlaceholder = ({
    className = '',
    icon = "sound_detection_dog_barking",
    style
}) => {
    return (
        <>
            <div style={style} className={`pet-placeholder d-flex align-items-center justify-content-center w-100 h-100 ${className}`}>
                <span className="material-symbols-outlined">
                    {icon}
                </span>
            </div>
        </>
    );
}

export default PetPlaceholder;