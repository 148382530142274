import React, { useEffect, useState } from "react";
import ProfileHead from "./ProfileHead";
import ProfileDescription from "./ProfileDescription";
import ProfileChart from "./ProfileChart";
import ProfileTags from "./ProfileTags";
import ProfileAllergy from "./ProfileAllergy";
import ProfileContact from "./ProfileContact";
import ProfileVaccination from "./ProfileVaccination";
import { useDispatch, useSelector } from "react-redux";
import ProfilePlan from "./ProfilePlan";
import "./index.scss";

import { values } from "pdf-lib";
import {
  getPetDetailsById,
  updatePetdetails,
} from "../../context/actions/patient-module/patient/pet";
import { useParams } from "react-router-dom";
import { getAllBreeds, getAllSpecies } from "../../context/actions/app-content";
import LoaderUI from "shared/loader";
const PatientProfile = () => {
  const { loading, petDetails } = useSelector((state) => state.petReducer);

  const dispatch = useDispatch();
  const params = useParams();

  // useEffect(() => {
  //   dispatch(getPetDetailsById(params.petId, {}, () => {
  //     console.log(petDetails, 'petdetails from patient profile')
  //   }));
  //   // setState()
  // }, []);
  // console.log("petDetails1", petDetails);

  return (
    <>
      {loading ? <LoaderUI /> :
        <div className="patient-profile d-flex flex-column overflow-aut h-100">
          <ProfileHead />
          <div className="inner overflow-auto">
            <ProfileDescription />
            <ProfileChart />
            <ProfileTags
              showRemoveIcon={false}
              target="behaviour-tag-main"
            // behaviourData={petDetails?.petCareInfo?.behaviour_tags}
            />
            <ProfileAllergy showRemoveIcon={false} target="add-allergy-main" />
            <ProfileContact />

          <ProfileVaccination />
          {/*petDetails?.wellness_plan_id && <ProfilePlan />*/}
        </div>
      </div>}
    </>
  );
};

export default PatientProfile;
