import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPetDetailsById } from "../../../context/actions/patient-module/patient/pet";
import AddAppointmentSections from "../../../shared/AddAppointmentSections";
import PatientProfile from "../../../shared/PatientProfile";
import "./index.scss";

const AddNewAppointment = () => {
	const routeParams = useParams();
	const dispatch = useDispatch();
	const userDetails = useSelector(state => state.user.user_details)
	const petDetail=useSelector(state=>state.petReducer.petDetails)
	useEffect(() => {
		dispatch(getPetDetailsById(routeParams.petId, {}, () => {}))
	}, [routeParams])
	return (
		<>
			<div className="page-wrapper add-new-appointment-wrapper position-relative d-flex overflow-auto">
				<div className="left-col d-flex flex-column overflow-auto">
					<PatientProfile />
				</div>
				<div className="right-col flex-fill d-flex flex-column overflow-auto">
					<AddAppointmentSections userDetails={userDetails} patientData={petDetail} />
				</div>
			</div>
		</>
	);
};

export default AddNewAppointment;
