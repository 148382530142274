import React, { useState, useEffect } from "react";
import { Button, Input } from "reactstrap";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { resetPassword, passwordExpired } from "../../../context/actions/user";
import { resetPasswordSchema, resetPasswordInit } from "./schema";
import PasswordInput from "../../../shared/FormsInput/PasswordInput";
import Head from "../Head";
import './../SignupModule.scss';
import { useParams, useHistory } from "react-router-dom";
import { REGEX, toasterConfig } from "../../../utils/constants";
import PasswordFormMessage from "../../../shared/PasswordFormMessage";

toast.configure();

const ResetPassword = () => {
	let { passwordtoken } = useParams();
	let history = useHistory();
	const dispatch = useDispatch();
	const [disabled, setDisabled] = useState(false);
	const [showPasswordStrengthBlock, setShowPasswordStrengthBlock] = useState(false);
	const [passwordValue, setPasswordValue] = useState(false);

	/**
	 * @description method call to check password strength
	 * @param {Object} params form params
	 * @param {Object} event form event
	 */
	const handlePasswordStrengthCheck = (formik, event) => {
		const value = event.target.value;
		formik.setFieldValue("password", value);
		if ((REGEX.NEW_PASSWORD).test(value)) {
			setPasswordValue(value)
			setShowPasswordStrengthBlock(false)
		} else {
			setPasswordValue(value)
			setShowPasswordStrengthBlock(true)
		}
	};

	/**
	 * @description method call to submit valid form
	 * @param {Object} params form params
	 * @param {Instance} status form status
	 */
	const submitForm = async (params) => {
		setDisabled(true);
		params.passwordtoken = passwordtoken;
		dispatch(
			resetPassword(params, (result) => {
				setDisabled(false);
				if (result) {
					history.push('/login');
				}
			})
		);
	};

	useEffect(() => {
		let params = {};
		params.passwordtoken = passwordtoken;
		dispatch(
			passwordExpired(params, (result) => {
				if (!result) {
					toast.error("This password token has been expired", toasterConfig);
					history.push('/login');
				}
			})
		);

	}, []);

	return (

		<div className="col-blc ms-auto mr-auto right-col">
		<div className="align-self-stretch">
			<div className="login-blc m-auto signup-box">
				<div className="signup-head">
					<h2>Create New Password</h2>
					<h3>Enter the Reset Password Code you received in your <span>registered email address.</span></h3>
				</div>
				<Formik
					initialValues={resetPasswordInit}
					enableReinitialize
					validationSchema={resetPasswordSchema}
					onSubmit={submitForm}
				>
					{(formik) => (<Form >
						<PasswordInput
							name="forgot_password_code"
							type="password"
							placeholder="Enter Code"
							id="enterCode"
							value={formik?.values?.forgot_password_code}
							onChange={formik.handleChange}
							icon={'icon'}
							required={true}
						/>
						<PasswordInput
							name="password"
							type="password"
							placeholder="Create New Password"
							id="newPassword"
							value={formik?.values?.password}
							// onChange={formik.handleChange}
							onChange={(v) => handlePasswordStrengthCheck(formik, v)}
							icon={'icon'}
							required={true}
						/>
						<PasswordInput
							type="password"
							name="confirmPassword"
							placeholder="Confirm New Password"
							id="confirmPassword"
							value={formik?.values?.confirmPassword}
							onChange={formik.handleChange}
							icon={'icon'}
							required={true}
						/>
						{
							showPasswordStrengthBlock && <PasswordFormMessage inputPassword={passwordValue} />
						}
						<div className="btn-cell d-flex">
							<Button disabled={disabled} color="primary" >
								save
							</Button>
						</div>
					</Form>)}
				</Formik>
			</div>
		</div>
		</div>
	);
};

export default ResetPassword;
