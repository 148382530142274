import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";

toast.configure();

export const addInventoryPOS = (data, callback) => {
  return (dispatch, getState) => {

    const {
      user: { token },
    } = getState();
    ApiClient
      .post(`${apiUrl}${PORT}/point-of-sale/create`, data,token, dispatch, getState)
      .then((res) => {
        const responseData = res.data;
        toast.dismiss();
        toast.success(responseData.message, toasterConfig);
        callback(responseData); // Invoke the callback function
      })
      .catch((err) => {
        toast.error(err.message, toasterConfig);
      });
  };
};

export const getPointOfSalesByClinicId = (clinic_id, params, callback) => {
  return (dispatch, getState) => {
    console.log("here");
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();

    if (clinic_details) {
      ApiClient.get(
        `${apiUrl}${PORT}/point-of-sale/get-all-pos/${clinic_id}`,
        params,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          console.log("res", response);
          // dispatch({
          //   type: "GET_POINT_OF_SALES",
          //   payload: response?.response_data,
          // });
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    } else {
      return callback(null);
    }
  };
};
