import * as Yup from "yup";
import { REGEX } from "../../../utils/constants";
/**
 * @description declaration form schema for client validations
 */
export const loginSchema = Yup.object().shape({
    password: Yup.string()
        .required("Password field is required"),
    email: Yup.string()
        .email("Invalid email address")
        .required("Email address field  is required")
});

/**
 * @description params intilaization 
 */

export const loginInit = {
    password: '',
    email: ''
}