const initState = {
    allRolePermission: {}
}

export const allPermissionReducer = (state = initState, action) => {
    switch (action.type) {
        case "GET_CLINIC_PERMISSION":
            return { ...state, allRolePermission: action.payload }
        default:
            return state
    }
}