import React, { useEffect, useState } from "react";
import Accordions from "./Accordions";
import FilterBlock from "./FilterBlock";
import './index.scss';
import NodataFoundBlock from "../../../../../shared/NoDataFoundBlock";
import MonthDayList from "./MonthDayList";

import { getTestResultsByPetId } from "../../../../../context/actions/patient-module/diagnostic-testing";
import { getPetDetailsById } from "../../../../../context/actions/patient-module/patient/pet";
import NewResultPanel from "./NewResultPanel";


import { useDispatch, useSelector } from "react-redux";

import LoaderUI from "../../../../../shared/loader";

const DiagnosticTesting = ({ petId }) => {

	const dispatch = useDispatch();


	const [panelState, setPanelState] = useState(false)

	const userDetails = useSelector(state => state.user.user_details);


	const { testResults, loading } = useSelector(state => state.diagnosticTestingReducer)
	const testData = { data: [] }


	const handleGetTestResults = () => {
		dispatch(getTestResultsByPetId(petId, (response) => {
			return
		}))
	}

	useEffect(() => {
		handleGetTestResults();
	}, [])


	return (
		<>
			{loading && <LoaderUI />}
			<div className="diagnostic-testing-block">
				
				<FilterBlock openPanel={() => { setPanelState(true) }} />
				<div className="diagnostic-listing-wrapper">
					{(testResults?.data?.length <= 0) ? <NodataFoundBlock /> : 
					<table className="table">
						<tbody>
							<tr>
								<td colSpan="7" className="p-0">
									<div className="table-view-blc">						 
											<Accordions data={testResults?.data} dates={testResults?.dates} />
									</div>

								</td>
							</tr>
						</tbody>
					</table>
					}
				</div>
			</div>
			{/* {panelState && */}

			{/* <> */}



			<div
				className={`new-result-panel-overlay position-fixed ${panelState ? 'active' : ''}`}></div>
			<NewResultPanel closePanel={() => {
				handleGetTestResults();
				setPanelState(false)
			}} className={`${panelState ? 'active' : ''}`} addPoint={(val) => { console.log(val) }} />


			{/* </>} */}
		</>
	);
};

export default DiagnosticTesting;
