import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";

toast.configure();
/**
 * All Api endpoints to be call
 * Post   -- /pet/get-list
 */

export const getOwnerAndPetsDetails = (params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    dispatch({ type: "PET_OWNER_DASHBOARD_LOADING", payload: true });
    ApiClient.get(
      `${apiUrl}${PORT}/pet-owner-dashboard/get-pet-owner-with-pets`,
      { user_id: params.user_id },
      token,
      dispatch, getState
    ).then((response) => {
      dispatch({ type: "PET_OWNER_DASHBOARD_LOADING", payload: false });
      if (!response) {
        return callback();
      } else if (response?.status) {
        dispatch({
          type: "GET_OWNER_AND_PET_DETAILS",
          payload: response?.response_data,
        });
        return callback(response);
      } else {
        dispatch({
          type: "GET_OWNER_AND_PET_DETAILS",
          payload: null,
        });
        //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};

/**
 * @description function use to get appointments for a pet owner
 * @param {*} params accepting object
 */
export const getAllAppointmentsOfPetOwner = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    dispatch({ type: "PET_OWNER_DASHBOARD_LOADING", data: true });
    ApiClient.post(
      `${apiUrl}${PORT}/pet-owner-dashboard/get-all-appointments-for-pet-owner`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      dispatch({ type: "PET_OWNER_DASHBOARD_LOADING", payload: false });
      if (!response) {
        return callback();
      } else if (response?.status) {
        dispatch({
          type: "UPDATE_PET_OWNER_APPOINTMENTS",
          payload: response?.response_data,
        });
        return callback(response);
      } else {
        dispatch({
          type: "UPDATE_PET_OWNER_APPOINTMENTS",
          payload: [],
        });
        //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};

export const getClinicDetailsByAddedBy = (params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();

    dispatch({ type: "PET_OWNER_DASHBOARD_LOADING", payload: true });
    ApiClient.get(
      `${apiUrl}${PORT}/clinic/get-clinic/${params?.added_by}`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      dispatch({ type: "PET_OWNER_DASHBOARD_LOADING", payload: false });

      if (!response) {
        return callback();
      } else if (response?.status) {
        dispatch({
          type: "UPDATE_CLINIC_DETAILS",
          payload: response?.response_data,
        });
        return callback(response);
      } else {
        dispatch({ type: "UPDATE_CLINIC_DETAILS", payload: null });

        // //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};

export const updatePetOwnerDetails = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();

    dispatch({ type: "PET_OWNER_DASHBOARD_LOADING", payload: true });
    ApiClient.put(
      `${apiUrl}${PORT}/pet-owner-dashboard/update-pet-owner-details`,
      body,
      token,
      dispatch, getState
    ).then((response) => {
      dispatch({ type: "PET_OWNER_DASHBOARD_LOADING", payload: false });

      if (!response) {
        return callback();
      } else if (response?.status) {
        return callback(response);
      } else {
        // //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};

export const updatePetOwnerImage = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.postFormData(
      `${apiUrl}${PORT}/pet/add-pet-owner-image`,
      body,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (response?.status) {
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};

export const getPetOwnerPets = (id) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  const response = await ApiClient.get(
    `${apiUrl}${PORT}/pet-owner-dashboard/get-pet-details/${id}`,
    {},
    token,
    dispatch, getState
  );
  dispatch({
    type: "SET_PET_OWNER_PET_LIST",
    payload: response?.response_data?.[0]?._id ? response?.response_data : [],
  });

  if(response?.response_data?.length <= 0){
    console.log('set new pet modal', response)
    dispatch({type: "SET_NEW_PET_MODAL", payload: true})
  } else {
    console.log('unset new pet modal', response)

    dispatch({type: "SET_NEW_PET_MODAL", payload: false})
  }
  return response;
};


export const getVerticalInsuranceQuote = (pet_id, callback, breed) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.get(
      `${apiUrl}${PORT}/pet-owner-dashboard/vertical-insurance/get-quote/${pet_id}`,
      {breed: breed},
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (response?.status) {
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};


export const getPolicyDocument = (pet_id, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.get(
      `${apiUrl}${PORT}/pet-owner-dashboard/vertical-insurance/get-policy-document/${pet_id}`,
      {},
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (response?.status) {
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};




export const getInsuranceClientSecret = (email) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  const response = await ApiClient.get(
    `${apiUrl}${PORT}/pet-owner-dashboard/get-insurance-client-secret/${email}`,
    {},
    token,
    dispatch, getState
  );
  return response;
};



export const getExistingPolicy = (pet_id, callback) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  const response = await ApiClient.get(
    `${apiUrl}${PORT}/pet-owner-dashboard/vertical-insurance/get-existing-policy/${pet_id}`,
    {},
    token,
    dispatch, getState
  ).then((response) => {
    if (!response) {
      return callback();
    } else if (response?.status) {
      return callback(response);
    } else {
      //toast.error(response.message, toasterConfig);
      return callback(response);
    }
  });
};

export const completePurchase = (data, callback) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  const response = await ApiClient.post(
    `${apiUrl}${PORT}/pet-owner-dashboard/purchase-insurance`,
    data,
    token,
    dispatch, getState
  );
  return callback(response);
};

export const getPetOwnerHealthSnapshot = (id) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  return await ApiClient.get(
    `${apiUrl}${PORT}/pet-owner-dashboard/get-pet-details-with-owner?pet_id=${id}`,
    {},
    token,
    dispatch, getState
  );
};

export const getPetOwnerDetails = (userId) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  return await ApiClient.get(
    `${apiUrl}${PORT}/pet-owner-dashboard/get-pet-owner-details/${userId}`,
    {},
    token, dispatch, getState
  );
};

export const getPetOwnerPetAppointments =
  (payload) => async (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    return await ApiClient.post(
      `${apiUrl}${PORT}/pet-owner-dashboard/get-all-pet-appointments`,
      payload,
      token,
      dispatch, getState
    );
  };

export const updateTreatmentReportStatus =
  (payload) => async (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    const response = await ApiClient.post(
      `${apiUrl}${PORT}/treatment/add-treatment-plan-report`,
      payload,
      token,
      dispatch
    );
    if (response?.errors?.msg) {
      toast.error(response?.errors?.msg, toasterConfig);
    } else if (response?.statusCode === 500) {
      toast.error(response?.message, toasterConfig);
    } else {
    }
    return response;
  };



export const updateTreatmentStatus = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.put(`${apiUrl}${PORT}/treatment/update-treatment`, data, token, dispatch).then(
      (response) => {
        if (!response) {
          return callback();
        } else if (!response && response.errors) {
          toast.error(response.errors.msg, toasterConfig);
          return callback(response);
        } else if (response?.status) {
          toast.dismiss();
          toast.success(response.msg, toasterConfig);
          return callback(response);
        } else {
          toast.error(response.message, toasterConfig);
          return callback();
        }
      }
    );
  };
};


export const updateTreatmentUnkownStatusData =
  (payload, callback) => async (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    const response = await ApiClient.post(
      `${apiUrl}${PORT}/treatment/add-Unknown-treatment-plan-reports`,
      payload,
      token,
      dispatch
    );
    if (!response) {
      return callback();
    } else if (!response && response.message) {
      toast.error(response.message, toasterConfig);
      return callback(response);
    } else if (response?.status) {
      toast.dismiss();
      return callback(response);
    } else {
      toast.error(response.message, toasterConfig);
      return callback();
    }
  }


