import React from "react";
import "./index.scss";
import ProfileHead from "./ProfileHead";
import ProfileDescription from "./ProfileDescription";
import ProfileContact from "./ProfileContact";

import {  useSelector } from "react-redux";
import ProfileSubscription from "./ProfileSubscription";
import ProfileActiveUser from "./ProfileActiveUser";

const ClinicProfile = () => {
  const {clinic_details} = useSelector((state) => state.clinicReducer)
  //   const params = useParams();

  //   useEffect(() => {

  //     dispatch(getClinicDetailsById(params.petId, {}, () => {}));
  //     // setState()
  //   }, []);


  return (
    <>
      <div className="clinic-profile d-flex flex-column overflow-auto ">
        <ProfileHead clinicData={clinic_details} />
        <div className="inner overflow-auto">
          <ProfileDescription clinicData={clinic_details} />
          <ProfileActiveUser />
          <ProfileContact clinicData={clinic_details} />
          <ProfileSubscription />
        </div>
      </div>
    </>
  );
};

export default ClinicProfile;
