import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button, ButtonDropdown, DropdownToggle, Table } from "reactstrap";
import { getServicesByClinicId } from "../../../context/actions/service";
import CheckBox from "../../../shared/CheckBox";
import NodataFoundBlock from "../../../shared/NoDataFoundBlock";
import PaginationBlock from "../../../shared/PaginationBlock";
import UserPlaceholder from "../../../shared/UserPlaceholder";
import { dateFromDateTime } from "../../../utils/common";
import TableListSupplierFilterWrapper from "../../../shared/TableListSupplierFilterWrapper";
import { getBundlesByClinicIdWithPagination } from "../../../context/actions/bundles";


const Bundles = ({ }) => {
  const history = useHistory();
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const dispatch = useDispatch();
  const bundles = useSelector((state) => state.inventoryReducer.bundles);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const userDetails = useSelector((state) => state.user.user_details);
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const [bundlesData, setBundlesData] = useState({});


  useEffect(() => {
    clinic_details &&
      dispatch(
        getBundlesByClinicIdWithPagination(
          clinic_details?._id,
          { page, limit },
          (res) => {
            if (res && res.status) {
              setBundlesData(res.response_data);
            } else {
              setBundlesData([]);
            }
          }
        )
      );
  }, [clinic_details, page, limit]);

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  return (
    <>
      <div className="product-list-wrapper">
        <TableListSupplierFilterWrapper />
        {bundlesData?.docs?.length > 0 ? (
          <div className="table-listing-blc overflow-auto">
            <Table striped responsive className="checkbox-listing">
              <thead>
                <tr>
                  <th>
                    <CheckBox
                      type="checkbox"
                      ischecked={false}
                      className="no-label"
                    />
                  </th>
                  <th>Name</th>
                  <th>Products Included</th>
                  {/* <th style={{ minWidth: "130px" }}>Amount</th> */}
                  {/* <th style={{ minWidth: "100px" }}>Patient</th>
                  <th style={{ minWidth: "80px" }}>Placed By</th>
                  <th>Notes</th> */}
                  {permissions?.edit_bundles &&
                    <th className="text-end">&nbsp;</th>
                  }
                </tr>
              </thead>
              <tbody>
                {bundlesData?.docs?.map((item, i) => {
                  const prodlength = item?.products?.length;
                  const limitedProd =
                    prodlength > 2
                      ? item?.products?.slice(0, 2)
                      : item?.products;
                  const extra =
                    prodlength > 2 ? prodlength - limitedProd.length : null;
                  return (
                    <tr key={i}>
                      <td>
                        <CheckBox
                          type="checkbox"
                          ischecked={false}
                          className="no-label"
                        />
                      </td>
                      <td>
                        <Button
                          color="link"
                          className="p-0 text-start d-inline-flex align-items-center w-100"
                          onClick={() => {
                            permissions?.edit_bundles && history.push(
                              "/inventory/add-new-bundle-in-inventory",
                              {
                                bundle_id: item._id,
                              }
                            );
                            // handleOpenBundleDrawer(item);
                            //history.push(`/product-detail/${item._id}`);
                          }}
                        >
                          {item?.bundle_name}
                        </Button>
                      </td>
                      <td>
                        <div className="d-inline-flex">
                          {limitedProd?.length > 0 ? limitedProd.map((prod, i) => {
                            return (
                              <div
                                color="link"
                                className="p-0 text-start pic-cell pic-cell-type-2 d-inline-flex align-items-center w-100 truncate_btn"
                                // onClick={() => {
                                //   history.push(`/product-detail/${item?._id}`);
                                // }}
                                key={i}
                              >
                                <div className="pic d-inline-flex flex-shrink-0">
                                  {prod?.image ? (
                                    <img
                                      className="img-fluid w-100 h-100"
                                      src={prod?.image}
                                      alt="Product"
                                      style={{
                                        borderRadius: "0",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      className="img-fluid w-100 h-100"
                                      src={require("../../../assets/img/vetRecorEMR/product-thumbnail-square.png")}
                                      alt="Product"
                                      style={{
                                        borderRadius: "0",
                                      }}
                                    />
                                  )}
                                  {/* <UserPlaceholder /> */}
                                </div>
                                <span>{prod?.product_name}</span>
                              </div>
                            );
                          }) :

                            '--'
                          }
                          <div className="pl-px d-inline-flex align-items-center">
                            {extra && `+${extra}`}
                          </div>
                        </div>
                      </td>
                      {/* <td>{item?.service_price}</td> */}
                      {/* <td>
                      <div className="p-0 text-start pic-cell d-inline-flex align-items-center w-100">
                        <div className="pic d-inline-flex position-relative">
                          <img
                            className="img-fluid w-100 h-100"
                            src={
                              item?.pet_image
                                ? item?.pet_image
                                : require("../../../assets/img/dog.png")
                            }
                            alt="Patient"
                          />
                        </div>
                        {item?.patient_name}
                      </div>
                    </td> */}
                      {/* <td>
                      <div
                        className="pic-cell d-inline-flex align-items-center w-100"
                        onClick={() => {
                          history.push(`/product-detail/${item._id}`);
                        }}
                      >
                        <div className="pic d-inline-flex position-relative overflow-hidden rounded-circle">
                          {item?.placedBy?.image ? (
                            <img
                              className="img-fluid w-100 h-100"
                              src={item?.placedBy?.image}
                              alt="Patient"
                            />
                          ) : (
                            <UserPlaceholder />
                          )}
                        </div>
                       
                        Dr. Canny
                      </div>
                    </td>   */}
                      {/* {item.description ? (
                      <td
                        className="text-clip-cell"
                        style={{ minWidth: "260px", maxWidth: "260px" }}
                      >
                        {item?.description}
                      </td>
                    ) : (
                      <td
                        className="text-clip-cell"
                        style={{
                          minWidth: "260px",
                          maxWidth: "260px",
                          width: "100%",
                        }}
                      >
                        <Button color="link" className="p-0 w-100 text-start">
                          Add Notes
                        </Button>
                      </td>
                    )}
                   */}
                      {permissions?.edit_bundles &&
                        <td style={{ textAlign: 'end' }} className="action-cell">
                          <Button color="link" className="edit-btn">
                            <span className="icomoon-pencil"></span>
                          </Button>
                          <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle color="link" className="more-btn">
                              <span className="icomoon-more"></span>
                            </DropdownToggle>{" "}
                          </ButtonDropdown>
                        </td>
                      }
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <PaginationBlock
              limit={limit}
              page={page}
              pagingCounter={bundles?.pagingCounter || 1}
              docLength={bundles?.docs?.length || 0}
              docPage={bundles.page || 1}
              totalPages={bundles.totalPages || 1}
              totalDocs={bundles.totalDocs || 0}
              _handleLimit={(val) => {
                setLimit(val);
              }}
              _handlePageChange={setPage}
            />
          </div>
        ) : (
          <NodataFoundBlock />
        )}
      </div>
    </>
  );
};

export default Bundles;
