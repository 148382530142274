import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { apiUrl, PORT } from "../../../environment";

export default function CostAndTaxes({
  setActiveForm,
  updateFormData,
  formData,
}) {


  const formik = useFormik({
    initialValues: {
      product_cost: "",
      taxes: "",
      discount: "",
    },
    onSubmit: (values) => {
      updateFormData((prevFormData) => ({
        ...prevFormData,
        values,
      }));

      console.log(formData, values)
      setActiveForm("specialNotes");
    },
  });

  useEffect(() => {
    if (formData && Object.keys(formData).length !== 0) {
      formik.setValues(formData.values);
    }
  }, [formData]);
  return (
    <div className="ml-5 bg-[#F8F8F8E5] rounded-lg ">
      <div className=" p-3">
        <p className="font-['Inter'] text-[#282E65] font-[700] mb-2">
          Cost & Taxes
        </p>
        {/* <div className="flex  justify-between items-baseline">
          <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
            Select Supplier
          </p>
          <select
            id="countries"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5 "
            name="supplier"
            onChange={handelSelectSupplier}
          >
            <option selected className="font-['Inter']">
              Select a supplier
            </option>
            {suppliers.map((data) => {
              return (
                <option value={data.supplier_name}>{data.supplier_name}</option>
              );
            })}
          </select>
        </div> */}
        <div className="flex justify-between  items-center my-2">
            <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
              Product Cost ($)
              <span className="text-[#282E65] font-['Inter'] text-[18px]">
                *
              </span>
            </p>
          <input
            type="text"
            id="first_name"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5 "
            placeholder="Enter Product Cost"
            required
            {...formik.getFieldProps("product_cost")}
          />
        </div>
        <div className="flex justify-between  items-center my-2">
        <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
              Taxes (%)
            </p>
          <input
            type="text"
            id="first_name"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5 "
            placeholder="Enter Tax Percentage"
            required
            {...formik.getFieldProps("taxes")}
          />
        </div>
        <div className="flex justify-between  items-center my-2">
        <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
              Discount (%)
            </p>
          <input
            type="text"
            id="first_name"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5 "
            placeholder="Enter Discount"
            required
            {...formik.getFieldProps("discount")}
          />
        </div>
        <div className="flex justify-end mt-2">
          <button
            type="button"
            onClick={() => setActiveForm("productInfo")}
            className=" px-4 py-2  rounded-lg text-[#11CA9D] font-['Inter']"
          >
            Back
          </button>
          <button
            type="button"
            onClick={formik.handleSubmit}
            className="bg-[#11CA9D] px-3.5 py-2  rounded-lg text-[#fff] font-['Inter']"
          >
            Save & Proceed
          </button>
        </div>
      </div>
    </div>
  );
}

// cost and taxes
