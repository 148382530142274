import { Row, Table } from "reactstrap";
import { Col } from "react-bootstrap";
import alertpng from "assets/img/alerts.png";
import BadgeImage from "assets/img/Badge-profile.png";
import Task_Alt from "assets/img/task_alt.png";
import { useDispatch, useSelector } from "react-redux";
import "./Overview.scss";
import { FiEdit } from "react-icons/fi";
import { useEffect, useState } from "react";
import { PetOverViewForm } from "./PetOverViewForm";
import { getPetOwnerDetails } from "context/actions/pet-owner-dashboard";
import { formatPhoneNumber } from "utils/common";

const Overview = ({ userData }) => {
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.user.user_details);


  //const [userDetail, setUserDetail] = useState(null);
  const [isAdd, setIsAdd] = useState(false);
  const [editData, setEditData] = useState(false);

  // const getData = async () => {
  //   const response = await dispatch(getPetOwnerDetails(userData?._id));
  //   if (response?.status) {
  //     setUserDetail(response?.response_data);
  //   }
  // };

  // useEffect(() => {
  //   getData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const {
    email,
    phone,
    firstname,
    lastname,
    address,
   
  } = userDetail || {};

  return (
    <div id="overview-container">
      <div className="overview_box">
        <Row>
          <Col xl={4} lg={4}>
            <div className="box py-2">
              <div className="fa-center gap-3">
                <div>
                  <h1>Contact Infromation</h1>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setEditData(userDetail);
                    setIsAdd(true);
                  }}
                >
                  <FiEdit />
                </div>
              </div>
             
            </div>
          </Col>
          <Col xl={6} lg={6}>
            <div className="box">
              <Table>
                <tr>
                  <td className="custom-w">Name</td>{" "}
                  <td>{`${firstname || '--'} ${lastname || '--'}`}</td>
                  </tr>
              {/*</Table>
            </div>
          </Col>
        </Row>
      </div>

      <div className="overview_box">
        <Row>
          <Col xl={4} lg={4}>
            <div className="box py-2">
              <div className="fa-center gap-3">
                <div>
                  <h1>Contact Infromation</h1>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setEditData(userDetail);
                    setIsAdd(true);
                  }}
                >
                  
                </div>
              </div>
              
            </div>
          </Col>
          <Col xl={6} lg={6}>
            <div className="box">
              <Table> */}
                <tr>
                  <td className="custom-w">Address</td>{" "}
                  <td>{address || "--"}</td>
                </tr>

                {/* <tr>
                  <td className="custom-w">City/Town </td>{" "}
                  <td>{city || "--"}</td>
                </tr>
                <tr>
                  <td className="custom-w">State/Province/Region </td>{" "}
                  <td>{state || "--"}</td>
                </tr>
                <tr>
                  <td className="custom-w">Zip/Postal Code </td>{" "}
                  <td>{zipcode || "--"}</td>
                </tr>
                <tr>
                  <td className="custom-w">Country</td>{" "}
                  <td>{country || "--"}</td>
                </tr> */}
                <tr>
                  <td className="custom-w">Phone Number </td>{" "}
                  <td>{formatPhoneNumber(phone) || "--"}</td>
                </tr>
                <tr>
                  <td className="custom-w">Email </td> <td>{email || "--"}</td>
                </tr>
              </Table>
            </div>
          </Col>
        </Row>
      </div>

      {isAdd && (
        <PetOverViewForm
          editData={editData}
          onHide={() => {
            setIsAdd(false);
            setEditData(null);
          }}
          handleSuccess={() => {
          
          }}
        />
      )}
    </div>
  );
};

export default Overview;
