import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Routes from "./routers";
import withTracker from "./withTracker";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-circular-progressbar/dist/styles.css";
import "./global.css";
import "./App.scss";
import { PersistGate } from "redux-persist/es/integration/react";
import { Provider, useSelector } from "react-redux";
import configureStore from "./config";
import history from "./history";
import LoaderUI from "./shared/loader";
// import { socket, SocketContext } from './context/actions/socket';
import { InpleoI18nProvider } from "./components/i18n/Inpleoi18n";
import { I18nProvider } from "./components/i18n/i18nProvider";
import Root from "./Root";
import FaviIcon from "FaviIcon";
import { injectStore } from "api-client";


const App = () => {
  /************ store configration *********/
  const { persistor, store } = configureStore(history);
  injectStore(store)

  return (
    
    <InpleoI18nProvider>
      <Provider store={store}>
        <FaviIcon />
        <PersistGate loading={<LoaderUI />} persistor={persistor}>
          <Router basename={process.env.REACT_APP_BASENAME}>
            <I18nProvider>
              <Root />
            </I18nProvider>
          </Router>
        </PersistGate>
      </Provider>
    </InpleoI18nProvider>
  );
};

export default App;
