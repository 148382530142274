import React from "react";
import { format } from "date-fns";
import ImagePlaceholder from '../../../../../../../../shared/ImagePlaceholder';

const StaffprofileEducationItem = ({ education }) => {

	return (
		<>
			<div className="staff-profile-education-item d-flex h-100">
				<div className="pic d-inline-flex overflow-hidden flex-shrink-0">
					<img className="img-fluid" src={require('../../../../../../../../assets/img/vetRecorEMR/logo-thumbnail.png')} alt="" />
					{/* <ImagePlaceholder /> */}
				</div>
				<div className="info">
					<h2 className="text-14-400 color-2E65">{education?.school_name}</h2>
					<h3 className="text-14-400 color-8693">{education?.subject}</h3>
					<h3>{format(new Date(education?.year_from), 'yyyy')} - {format(new Date(education?.year_to), 'yyyy')}</h3>
					{
						education?.education_certificates?.length > 0 && <ul className="thumbnail-list list-unstyled d-flex">
							{
								education?.education_certificates.map((certificate, index) => (
									<li key={index}>
										<span className="d-inline-flex overflow-hidden mt-2">
											<img className="img-fluid" src={require('../../../../../../../../assets/img/vetRecorEMR/certificate-thumbnail-sm.png')} alt="" />
										</span>
									</li>
								))
							}
						</ul>
					}

				</div>
			</div>
		</>
	);
};

export default StaffprofileEducationItem;