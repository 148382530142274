import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../../../../api-client";
import { apiUrl, PORT } from "../../../../../environment";
import { toasterConfig } from "../../../../../utils/constants";

toast.configure();
/**
 * All Api endpoints to be call
 */

/**
 * @description function use to add appointment discharge patient form
 * @param {*} data accepting object
 */
export const AddNewDischargeInstructionDocument = (
  data,
  clinic_id,
  callback
) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.postFormData(
      `${apiUrl}${PORT}/discharge-instruction/add`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response?.status) {
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        dispatch(FetchAllDischargeInstructionsForClinic(clinic_id));
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * get all vaccination dues details
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */
export const UpdateDischargeInstructionDocument = (
  formData,
  clinic_id,
  callback
) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.putFormData(
      `${apiUrl}${PORT}/discharge-instruction/update`,
      formData,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response?.status) {
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        dispatch(FetchAllDischargeInstructionsForClinic(clinic_id));
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * @description function use to fetch appointment discharge patient form
 * @param {*} data accepting object
 */
export const FetchAllDischargeInstructionsForClinic = (
  clinic_id,
  callback
) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    if (clinic_id) {
            ApiClient.get(
        `${apiUrl}${PORT}/discharge-instruction/get-all-for-clinic/${clinic_id}`,
        {},
              token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({
            type: "SET_DISCHARGE_INSTRUCTIONS",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          dispatch({ type: "SET_DISCHARGE_INSTRUCTIONS", payload: [] });
          return callback(response);
        }
      });
    } else {
      return callback({ status: false });
    }
  };
};

/**
 * @description function use for to delete organization logo
 * @param {*} params accepting object
 */
export const DeleteDischargeInstructionDocument = (
  document_id,
  clinic_id,
  callback
) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.delete(
      `${apiUrl}${PORT}/discharge-instruction/delete/${document_id}`,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response?.status) {
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        dispatch(FetchAllDischargeInstructionsForClinic(clinic_id));
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};
