import './index.scss';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { joinRoom } from "../../context/actions/video"
import db from "../../firebase"


import { addDoc, collection, serverTimestamp, getDocs, arrayUnion, query, updateDoc, where, doc } from "firebase/firestore";
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Room from './Room';
import { getSelectedRoomData, fetchChatMessages } from 'context/actions/realTimeChat';
import LoaderUI from 'shared/loader';
import ZoomVideo, { ConnectionState, ReconnectReason } from "@zoom/videosdk";
import { toast } from 'react-toastify';
import { getAppointmentDetails } from 'context/actions/appointment';
import { getWaitingRoomList } from 'context/actions/waitingRoom';


const VideoCall = () => {
    const { zmClient } = useSelector((state) => state.videoReducer)

    const userDetails = useSelector((state) => state.user.user_details);
    const { data: appointmentDetails } = useSelector(
        (state) => state.appointmentDetailPanelReducer
    );
    const [roomId, setRoomId] = useState(null)
    const routeParams = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [token, setToken] = useState('');
    const [loading, setIsLoading] = useState(true);
    const [loadingText, setLoadingText] = useState('');
    const [isFailover, setIsFailover] = useState(false);
    const [status, setStatus] = useState('closed');
    const [mediaStream, setMediaStream] = useState(null);
    const [isSupportGalleryView, setIsSupportGalleryView] = useState(true);
    const SELF_VIDEO_ID = "ZOOM_WEB_SDK_SELF_VIDEO";


    useEffect(() => {
        if (routeParams?.appointment_id && Object.keys(zmClient)?.length) {
            dispatch(joinRoom({ identity: userDetails?.firstname + ' ' + userDetails?.lastname, roomName: routeParams?.appointment_id }, async (response) => {
                if (response.statusCode === 400) {
                    toast.error(response.message);
                    window.close();
                    return;
                }
                setToken(response.token)
                init(response.token);
                createGroupRoom()
            }))
            dispatch(
                getAppointmentDetails(routeParams?.appointment_id, (res) => {
                    const clinicId = res?.clinic_id
                    dispatch(getWaitingRoomList({ clinicId }, (data) => {
                    }));
                }))

        }
        return () => {
            ZoomVideo.destroyClient();
        };
    }, [routeParams])
    const createGroupRoom = async () => {
        try {
            if (routeParams?.appointment_id) {
                const q = query(collection(db, 'chatRooms'), where("appointment_id", '==', routeParams?.appointment_id));
                const querySnapshot = await getDocs(q);
                if (querySnapshot.empty) {

                    const roomsCollectionRef = collection(db, 'chatRooms');
                    const memberData = [{ id: userDetails?._id, name: userDetails?.firstname + " " + userDetails?.lastname || "", unreadCount: 0 }]
                    const petDetails = {
                        id: appointmentDetails?.patient_id?._id,
                        name: appointmentDetails?.patient_id?.pet_name,
                        image: appointmentDetails?.patient_id?.pet_image || "",
                        animal: appointmentDetails?.patient_id?.breed?.species_id?.species_name || "",
                        breed: appointmentDetails?.patient_id?.breedDetails?.breed || ""
                    }
                    const roomDocRef = await addDoc(roomsCollectionRef, {
                        roomName: `Appointment ${routeParams?.appointment_id}`,
                        createdAt: serverTimestamp(),
                        membersId: [userDetails?._id],
                        members: memberData,
                        createdBy: userDetails?._id,
                        userImgs: [userDetails?.user_image],
                        appointment_id: routeParams?.appointment_id,
                        petDetails
                    });
                    if (roomDocRef?.id) {
                        dispatch(getSelectedRoomData('group', roomDocRef.id, (res) => {
                            if (res) {
                                setRoomId(roomDocRef.id)

                                dispatch(fetchChatMessages(roomDocRef, "", (res) => {

                                }))
                            }
                        }))
                    }

                } else {
                    const roomId = querySnapshot.docs?.[0].id;
                    const roomsRef = doc(db, 'chatRooms', roomId)
                    await updateDoc(roomsRef, {
                        membersId: arrayUnion(userDetails?._id),
                        userImgs: arrayUnion(userDetails?.user_image),
                        members: arrayUnion({ id: userDetails?._id, name: userDetails?.firstname + " " + userDetails?.lastname || "", unreadCount: 0 })

                    });
                    dispatch(fetchChatMessages(roomsRef, "", (res) => {
                        if (res) {
                            dispatch(getSelectedRoomData('group', roomId, (res) => {
                                setRoomId(roomId)
                            }))
                        }
                    }))
                }

            }
        } catch (errors) {
            console.log("errors", errors);
        }
    }
    const init = async (token) => {

        const galleryViewWithoutSAB = Number(!window.crossOriginIsolated) === 1 && !window.crossOriginIsolated;
        await zmClient.init('en-US', `Global`, {
            // webEndpoint: 'zoom.us',
            // stayAwake: true,
            // leaveOnPageUnload: true,
            // enforceMultipleVideos: true,
            // leaveOnPageUnload: true
            stayAwake: true,
            leaveOnPageUnload: true,
            enforceMultipleVideos: true
        });
        try {
            setLoadingText('Joining the session...');
            const stream = zmClient.getMediaStream();
            await zmClient.join(routeParams?.appointment_id, token, userDetails?.firstname + ' ' + userDetails?.lastname, "").then(() => {

            }).catch((error) => {
                console.log(error)
            })
            setMediaStream(stream);
            setIsSupportGalleryView(stream.isSupportMultipleVideos());
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            //   message.error(e.reason);
        }
    };



    const onLeaveOrJoinSession = useCallback(async () => {
        if (status === 'closed') {
            setIsLoading(true);
            await zmClient.join(routeParams?.appointment_id, token, userDetails?.firstname + ' ' + userDetails?.lastname, "");

            setIsLoading(false);
        } else if (status === 'connected') {
            setIsLoading(true)
            await zmClient.leave();
            window.close();
        }
    }, [zmClient, status, routeParams, token]);

    const onConnectionChange = useCallback(
        (payload) => {
            if (payload.state === ConnectionState.Reconnecting) {
                setIsLoading(true);
                setIsFailover(true);
                setStatus('connecting');
                const { reason, subsessionName } = payload;
                if (reason === ReconnectReason.Failover) {
                    setLoadingText('Session Disconnected,Try to reconnect');
                } else if (reason === ReconnectReason.JoinSubsession || reason === ReconnectReason.MoveToSubsession) {
                    setLoadingText(`Joining ${subsessionName}...`);
                } else if (reason === ReconnectReason.BackToMainSession) {
                    setLoadingText('Returning to Main Session...');
                }
            } else if (payload.state === ConnectionState.Connected) {
                setStatus('connected');
                if (isFailover) {
                    setIsLoading(false);
                }
                window.zmClient = zmClient;
                window.mediaStream = zmClient.getMediaStream();

            } else if (payload.state === ConnectionState.Closed) {
                setStatus('closed');
                dispatch({ type: 'reset-media' });
                if (payload.reason === 'ended by host') {
                }
                //history.push("/")
            }
        },
        [isFailover, zmClient]
    );
    const onMediaSDKChange = useCallback((payload) => {
        const { action, type, result } = payload;
        dispatch({ type: `${type}-${action}`, payload: result === 'success' });
    }, []);

    useEffect(() => {
        if (Object.keys(zmClient)?.length) {

            zmClient?.on('connection-change', onConnectionChange);
            zmClient?.on('media-sdk-change', onMediaSDKChange);
        }
        return () => {
            if (Object.keys(zmClient)?.length) {
                zmClient?.off('connection-change', onConnectionChange);
                zmClient?.off('media-sdk-change', onMediaSDKChange);
            }
        };
    }, [zmClient, onConnectionChange, onMediaSDKChange]);

    return (
        <>
            {/* {loading ? loadingText : "joined"} */}
            {
                loading
                    ? <div className="lobby">
                        <LoaderUI />
                    </div>
                    :
                    <>
                        <Room roomId={roomId} mediaStream={mediaStream} onLeaveOrJoinSession={onLeaveOrJoinSession} isSupportGalleryView={isSupportGalleryView} />

                    </>
            }
        </>
    )




}

export default VideoCall;
