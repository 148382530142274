import React, { useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, FormGroup, Input, Spinner, FormFeedback } from "reactstrap";
import { useDispatch } from "react-redux";
import { addClinic, editClinic } from "../../../../context/actions/clinic";
import "./index.scss";
import AddressAutoCompleteInput from "../../../../shared/AddressAutoCompleteInput";
import "yup-phone-lite";
import PhoneNumberInput from "shared/FormsInput/PhoneInput";

const AddNewClinicPanel = ({
  isShow,
  closePanel,
  editData,
  handelSuccess,
  is_master_clinic,
  master_clinic_id,
}) => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const [btnLoader, setBtnLoader] = useState(false);
  const onSubmit = (values) => {
    setBtnLoader(true);
    let valuePayload = values;
    if (master_clinic_id) {
      valuePayload.master_clinic_id = master_clinic_id;
    }
    if (editData) {
      handleEdit(valuePayload);
    } else {
      handleAdd(valuePayload);
    }
  };
  const handleAdd = async (values) => {
    const response = await dispatch(addClinic(values));
    if (response?.status) {
      handelSuccess();
      resetForm();
    }
    setBtnLoader(false);
  };
  const handleEdit = async (values) => {
    const response = await dispatch(
      editClinic({ ...values, clinic_id: editData?._id })
    );
    if (response?.status) {
      handelSuccess();
      resetForm();
    }
    setBtnLoader(false);
  };
  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm();
    }
  };
  const initialValues = {
    clinic_name: editData?.clinic_name || "",
    specialties: editData?.specialties || "",
    email: editData?.email || "",
    clinic_type: editData?.clinic_type || "",
    phone: editData?.phone || "",
    address: editData?.address || "",
    latitude: editData?.latitude || "",
    longitude: editData?.longitude || "",
    is_master_clinic: is_master_clinic,
    // master_clinic_id: master_clinic_id || "",
  };

  const validationSchema = Yup.object().shape({
    clinic_name: Yup.string().required("Clinic name is required."),
    specialties: Yup.string().required("Specialties is required."),
    clinic_type: Yup.string().required("Clinic type is required."),
    phone: Yup.string().required("Phone number is required."),
    address: Yup.string().required("Address is required."),
    email: Yup.string()
      .required("Email is required.")
      .email("Email must be a valid email"),
  });

  return (
    <>
      <div
        className={`new-clinic-panel-wrapper position-fixed d-flex flex-column overflow-auto ${
          isShow ? "active" : ""
        }`}
      >
        <div className="head d-flex align-items-center">
          <h2>
            {editData ? "Edit" : "Add"}{" "}
            {is_master_clinic ? "Clinic" : "Sub Clinic"}
          </h2>
          <Button
            color="link"
            className="ms-auto panel-close-btn"
            onClick={() => {
              closePanel();
              resetForm();
            }}
          >
            <span className="material-symbols-outlined">close</span>
          </Button>
        </div>

        <div className="inner d-flex  pt-2">
          <Formik
            enableReinitialize
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(props) => {
              const {
                values,
                handleChange,
                errors,
                handleSubmit,
                setFieldValue,
                touched,
              } = props;
              const {
                clinic_name,
                specialties,
                email,
                clinic_type,
                phone,
                address,
                latitude,
                longitude,
              } = values;
              return (
                <form>
                  <div className="form-group-wrapper">
                    <FormGroup className="input-wrapper-clinic input-wrapper">
                      <div className="form-floating position-relative text-input">
                        <Input
                          type="text"
                          id="clinic_name"
                          name="clinic_name"
                          value={clinic_name}
                          onChange={handleChange}
                          placeholder="Add Clinic Name"
                          invalid={errors?.clinic_name ? true : false}
                        />
                        <label htmlFor="clinic_name">Clinic Name</label>
                      </div>
                    </FormGroup>
                  </div>

                  <div className="form-group-wrapper ">
                    <FormGroup className="input-wrapper-clinic input-wrapper">
                      <div className="form-floating position-relative text-input">
                        <Input
                          type="text"
                          id="specialties"
                          name="specialties"
                          value={specialties}
                          onChange={handleChange}
                          placeholder="Add Specialties"
                          invalid={errors?.address ? true : false}
                        />
                        <label htmlFor="specialties">Specialties</label>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="form-group-wrapper ">
                    <FormGroup className="input-wrapper-clinic input-wrapper">
                      <div className="form-floating position-relative text-input">
                        <Input
                          type="text"
                          id="email"
                          name="email"
                          value={email}
                          onChange={handleChange}
                          placeholder="Add Email Address"
                          invalid={errors?.email ? true : false}
                        />
                        <label htmlFor="email">Email Address</label>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="form-group-wrapper ">
                    <FormGroup className="input-wrapper-clinic input-wrapper">
                      <div className="form-floating position-relative text-input">
                        <Input
                          type="text"
                          id="clinic_type"
                          name="clinic_type"
                          value={clinic_type}
                          onChange={handleChange}
                          placeholder="Add Clinic Type"
                          invalid={errors?.clinic_type ? true : false}
                        />
                        <label htmlFor="clinic_type">Clinic Type</label>
                      </div>
                    </FormGroup>
                  </div>
                  <div className=" ">
                    <div className="">
                      <PhoneNumberInput
                        type="text"
                        id="phone"
                        style={{ width: "100%" }}
                        name="phone"
                        value={phone}
                        onChange={(e) => {
                          console.log("+" + e);
                          setFieldValue("phone", "+" + e);
                        }}
                        placeholder="Add Phone Number"
                      />
                    </div>
                  </div>
                  {/* <div className="form-group-wrapper ">
                    <FormGroup className="input-wrapper-clinic input-wrapper">
                      <div className="form-floating position-relative text-input">
                        <Input
                          type="text"
                          id="address"
                          name="address"
                          value={address}
                          onChange={handleChange}
                          placeholder="Add Address Information"
                          invalid={errors?.address ? true : false}
                        />
                        <label htmlFor="address">Address Information</label>
                      </div>
                    </FormGroup>
                  </div> */}
                  <AddressAutoCompleteInput
                    onSelect={(event) => {
                      setFieldValue(`address`, event?.address);
                      setFieldValue(`latitude`, event?.lat);
                      setFieldValue(`longitude`, event?.lng);
                    }}
                    value={address}
                  />
                  {/* <div className="form-group-wrapper ">
                    <FormGroup className="input-wrapper-clinic input-wrapper">
                      <div className="form-floating position-relative text-input">
                        <Input
                          type="number"
                          id="latitude"
                          name="latitude"
                          value={latitude}
                          onChange={handleChange}
                          placeholder="Add latitude"
                        />
                        <label htmlFor="latitude">Latitude</label>
                      </div>
                    </FormGroup>
                  </div> */}
                  {/* <div className="form-group-wrapper ">
                    <FormGroup className="input-wrapper-clinic input-wrapper">
                      <div className="form-floating position-relative text-input">
                        <Input
                          type="number"
                          id="longitude"
                          name="longitude"
                          value={longitude}
                          onChange={handleChange}
                          placeholder="Add Longitude"
                        />
                        <label htmlFor="longitude">Longitude</label>
                      </div>
                    </FormGroup>
                  </div> */}
                  <div className="btn-blc border_btn mt-3 text-end">
                    <Button
                      className="flex-fill btn cancle-btn"
                      onClick={closePanel}
                    >
                      CANCEL
                    </Button>
                    <Button
                      color="success"
                      type="button"
                      className="btn add-btn"
                      onClick={handleSubmit}
                    >
                      {editData ? "Submit" : "Add new"}{" "}
                      {btnLoader && (
                        <Spinner
                          animation="border"
                          role="status"
                          size="sm"
                          className="ms-2"
                        />
                      )}
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddNewClinicPanel;
