import React, { useEffect, useState } from "react";
import { Button, Input } from "reactstrap"; // eslint-disable-line
//import { getAndSearchPointOfSales } from "../../../context/actions/inventory";
import { useDispatch, useSelector } from "react-redux";
import DateRange from "shared/FormsInput/DateRange";
import moment from "moment";
import { getClinicListByClinicId } from "context/actions/referral";
import { titleCaseString } from "utils/common";

const TableListFilterBlock = ({
	onSelectClinic,
	onSelectStatus,
	onSearch,
	onChangeDate,
	clinicList,
	filterPayload,
}) => {
	const { incomingReferrals } = useSelector((state) => state.inventoryReducer); // eslint-disable-line
	const dispatch = useDispatch(); // eslint-disable-line

	// const [inputText, setInputText] = useState("");
	// const [selectedStatus, setSelectedStatus] = useState("");
	// const [selectedTimeframe, setSelectedTimeframe] = useState("");
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);



	const [statuses, setStatuses] = useState([]); // eslint-disable-line

	// useEffect(() => {
	// 	handleGetIncomingReferrals();
	// }, [inputText, selectedStatus, selectedTimeframe]);

	// const handleGetIncomingReferrals = () => {

	// 					dispatch(getAndSearchIncomingReferrals({ petName: selectedPetname, searchText: inputText,  timeframe: selectedTimeframe }, () => {
	// 					}))
	// };

	// const handleReferrals = () => {
	// 	/*
	// 				let names = incomingReferrals.docs.map((obj) => obj.status);
	// 				names = [...new Set(names)]
	// 				setPetNames(names) */
	// };

	// useEffect(() => {
	// 	handleReferrals();
	// }, []);




	return (
		<>
			<div className="table-list-filter-blc d-flex align-items-center">
				<div className="search-cell col-cell position-relative">
					<span className="icomoon-search d-inline-flex align-items-center justify-content-center position-absolute"></span>
					<Input placeholder="Search" onChange={onSearch} value={filterPayload?.searchText} />
				</div>
				<div className="input-cell col-cell">
					<Input
						style={{ textTransform: "capitalize" }}
						type="select"
						name="select"
						onChange={onSelectStatus}
					>
						<option value="">All Status</option>
						<option value="Pending">Pending</option>
						<option value="Declined">Declined</option>
						<option value="Approved">Approved</option>
						<option value="Cancelled">Cancelled</option>
					</Input>
				</div>

				<div className="input-cell col-cell">
					<Input
						style={{ textTransform: "capitalize" }}
						type="select"
						name="select"
						onChange={onSelectClinic}
					>
						<option value="">All Clinic</option>
						{clinicList?.map((list) =>
							<option value={list?.clinic_id}>{titleCaseString(list?.clinic_name)}</option>
						)}
						{/* <option value="doctor">Pending</option>
            <option value="vet-tech">Declined</option>
            <option value="receptionist">Approved</option> */}
						{/*roles?.map((item) => <option value={item.role}>{item.role}</option>)*/}
					</Input>
				</div>

				<div className="col-cell mt-sm-0 mt-2 ">
					<DateRange
						id="dateRange"
						name="dateRange"
						placeholder="Select Date"
						startDateProp={startDate}
						endDateProp={endDate}
						setStartDateProp={setStartDate}
						setEndDateProp={setEndDate}
						inputClassName="custom-input-grey"
						onChange={(sDate, eDate) => {
							const startDate = sDate ? moment(sDate).format("YYYY-MM-DD") : "";
							const endDate = eDate ? moment(eDate).format("YYYY-MM-DD") : "";
							onChangeDate(startDate, endDate);
						}}
						isClearable
					/>
				</div>
			</div>
		</>
	);
};

export default TableListFilterBlock;
