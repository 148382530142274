import React, { useEffect, useState } from "react";
import moment from "moment";
import { IoMdClose } from "react-icons/io";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import redCancel from "assets/img/icons/redCancel.png";
import greenCheck from "assets/img/icons/greenCheck.png";
import { BsCapsule } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import "./MedicationReport.scss";
import {
  updateMedicationReportStatus,
  updateUnkownStatusData,
} from "context/actions/patient-module/overview/medication-plan";
import { titleCaseString } from "utils/common";
import { toast } from "react-toastify";
import { toasterConfig } from "../../../../utils/constants";
import { getHomeMedicationOrders } from "context/actions/patient-module/orders";
import { getPetOwnerHealthSnapshot } from "context/actions/pet-owner-dashboard";

toast.configure();

const MedicationReport = ({
  onHide,
  data = [],
  petID,
  userID,
  getPageData,
}) => {
  const [sIndex, setSIndex] = useState(data?.length > 0 ? 0 : "");
  const [isExpand, setIsExpand] = useState(false);
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state?.user?.user_details);
  const getPercentComplete = (newData) => {
    let totalDose = 0;
    getOrderDates(newData)?.forEach((elm) => {
      const { timeList } = elm;
      totalDose = totalDose + timeList?.length;
    });

    const takenDoses = newData?.medication_reports?.filter(
      (o) => o.plan_status === "taken"
    )?.length;
    let count = Math.ceil((takenDoses / totalDose) * 100);
    return count;
  };
  const getDateRange = (first_dose, duration_num, duration_unit) => {
    // Parse the start date
    const startDate = new Date(first_dose);

    // Calculate the end date based on duration_unit and duration_num
    const endDate = new Date(startDate);
    switch (duration_unit) {
      case "Days":
        endDate.setDate(startDate.getDate() + duration_num);
        break;
      case "Weeks":
        endDate.setDate(startDate.getDate() + 7 * duration_num);
        break;
      case "Months":
        endDate.setMonth(startDate.getMonth() + duration_num);
        break;
      case "Years":
        endDate.setFullYear(startDate.getFullYear() + duration_num);
        break;
      default:
        break;
    }

    // return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
    return `${moment(startDate).format("MMM DD")} - ${moment(endDate).format(
      "MMM DD"
    )}`;
  };
  const getOrderDates = (newItem) => {
    var dates = [];
    var currDate = moment(
      newItem?.first_dose,
      "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
    ).startOf("day");
    var lastDate = moment(newItem?.first_dose, "YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      .add(newItem?.duration_num, newItem?.duration_unit.toLowerCase())
      .startOf("day");
    while (currDate.isBefore(lastDate, "day")) {
      dates.push(currDate.clone());
      currDate.add(1, "days");
    }
    let newData = [];
    dates.forEach((o, i) => {
      if (i === 0) {
        let fromTime = moment(
          newItem?.first_dose,
          "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
        ).format("DD-MM-YYYY hh:mm A");
        let toTime = `${moment(
          newItem?.first_dose,
          "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
        ).format("DD-MM-YYYY")} 11:59 PM`;
        let newTimeArray = getDoseAndTimeIntarval(
          newItem?.frequency,
          fromTime,
          toTime
        );
        newData.push({
          date: o,
          timeList: newTimeArray?.timeList,
        });
      } else {
        let prevList = newData?.[i - 1];
        let prevDate = prevList?.date?.format("DD-MM-YYYY");
        let fromTime = `${prevDate} ${
          prevList?.timeList?.[prevList?.timeList?.length - 1]
        }`;

        let toTime = `${moment(o, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
          "DD-MM-YYYY"
        )} 11:59 PM`;

        let newTimeArray = getDoseAndTimeIntarval(
          newItem?.frequency,
          fromTime,
          toTime
        );
        let timeListData = newTimeArray?.timeList;
        timeListData?.shift();
        newData.push({
          date: o,
          timeList: timeListData,
        });
      }
    });
    return newData;
  };
  const getDoseAndTimeIntarval = (frequency, fromTime, toTime) => {
    let intarvalTime = 5;
    let intarvalType = "minutes";
    switch (frequency) {
      case "q5m":
        intarvalTime = 5;
        intarvalType = "minutes";
        break;
      case "q15m":
        intarvalTime = 15;
        intarvalType = "minutes";
        break;
      case "q30m":
        intarvalTime = 30;
        intarvalType = "minutes";
        break;
      case "q2h":
        intarvalTime = 2;
        intarvalType = "hours";
        break;
      case "q4h":
        intarvalTime = 4;
        intarvalType = "hours";
        break;
      case "q6h":
        intarvalTime = 6;
        intarvalType = "hours";
        break;
      case "q8h":
        intarvalTime = 8;
        intarvalType = "hours";
        break;
      case "q12h":
        intarvalTime = 12;
        intarvalType = "hours";
        break;
      case "q24h":
        intarvalTime = 24;
        intarvalType = "hours";
        break;
      case "daily":
        intarvalTime = 1;
        intarvalType = "days";
        break;
      case "daily at bedtime":
        intarvalTime = 24;
        intarvalType = "hours";
        break;
      case "BID":
        intarvalTime = 12;
        intarvalType = "hours";
        break;
      case "TID":
        intarvalTime = 8;
        intarvalType = "hours";
        break;
      case "QID":
        intarvalTime = 6;
        intarvalType = "hours";
        break;
      case "every other day":
        intarvalTime = 1;
        intarvalType = "days";
        break;
      case "once":
        intarvalTime = 1;
        intarvalType = "days";
        break;

      default:
        break;
    }
    const startTime = moment(fromTime, "DD-MM-YYYY hh:mm A");
    const endTime = moment(toTime, "DD-MM-YYYY hh:mm A");
    const minutesArray = [];
    for (
      let currentMoment = startTime.clone();
      currentMoment.isBefore(endTime);
      currentMoment.add(intarvalTime, intarvalType)
    ) {
      minutesArray.push(currentMoment.format("hh:mm A"));
    }
    return { timeList: minutesArray };
  };
  const renderPlanCard = (newData) => {
    return (
      <div className="medication-plan-item">
        <div className="chart-blc d-flex align-items-center mb-1">
          <div className="chart-item">
            <CircularProgressbarWithChildren
              styles={buildStyles({
                rotation: 0.5,
                pathColor: "#11CA9D",
                textColor: "#f88",
                trailColor: "inherit",
              })}
              strokeWidth={10}
              value={getPercentComplete(newData)}
            >
              <div
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  padding: "7px 0px",
                  backgroundColor: "#ececec",
                  borderRadius: 50,
                  textAlign: "center",
                  width: 35,
                }}
              >
                {("  " + getPercentComplete(newData)).slice(-3)}%
              </div>
            </CircularProgressbarWithChildren>
          </div>

          <div className="medication-profilee">
            <h4>{newData?.plan_name}</h4>
            <p>
              {getDateRange(
                newData?.first_dose,
                newData?.duration_num,
                newData?.duration_unit
              )}
            </p>
          </div>
        </div>
      </div>
    );
  };
  const handleSelectDose = async (type, data, iDate, iTime) => {
    const response = await dispatch(
      updateMedicationReportStatus({
        order_id: data?._id,
        item: data?.item,
        created_by: userDetails?._id,
        pet_id: petID,
        date: moment(iDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        time: iTime,
        plan_status: type,
        createdDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      })
    );
    if (response?.status) {
      getPageData();
    } else {
      toast.error(response?.message, toasterConfig);
    }
  };
  const getDateTitle = (nDate) => {
    const fDate = nDate.format("DD-MM-YYYY");
    const today = moment().format("DD-MM-YYYY");
    const tomorrow = moment().add(1, "days").format("DD-MM-YYYY");
    const yesterday = moment().subtract(1, "days").format("DD-MM-YYYY");
    let prefix =
      fDate === today
        ? "Today"
        : fDate === yesterday
        ? "Yesterday"
        : fDate === tomorrow
        ? "Tomorrow"
        : nDate.format("dddd");
    return `${prefix}, ${nDate.format("MMM D YYYY")}`;
  };
  const displayData = data?.[sIndex] || {};
  const {
    // first_dose,
    // duration_num,
    // duration_unit = "Days",
    doctor_first_name: doctorFirstName,
    doctor_last_name: doctorLastName,
    doctor_image: doctorImage,
    plan_name: planName,
    frequency: frequencyVal,
    form: formVal,
    dose: doseVal,
  } = displayData;

  useEffect(() => {
    if (displayData) {
      const addUnknownTimestamp = async () => {
        const filtersData = [];
        getOrderDates(displayData)?.forEach((elem) => {
          const { date, timeList } = elem;
          timeList?.forEach((time) => {
            const mergeDateTime = moment(
              `${date.format("YYYY-MM-DD")} ${time}`
            );
            const dateTime = moment(`${date.format("YYYY-MM-DD")} ${time}`);
            const newmergeDateTime = dateTime.add(72, "hours");
            const currentDate = moment();
            const diffInHours = currentDate?.diff(mergeDateTime, "hours");
            const isUnknownValue = diffInHours >= 72;

            const MRreportData = displayData?.medication_reports?.find(
              (o) =>
                o?.date === date.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") &&
                o?.time === time
            );

            if (
              isUnknownValue &&
              !MRreportData?.isUnknown &&
              !MRreportData?.plan_status
            ) {
              filtersData.push({
                order_id: displayData?._id,
                item: displayData?.item,
                created_by: userDetails?._id,
                pet_id: petID,
                date: moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                unknownCreatedDate: moment(newmergeDateTime).format(
                  "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                ),
                time: time,
                isUnknown: true,
              });
            }
          });
        });

        if (filtersData?.length > 0) {
          const response = await dispatch(
            updateUnkownStatusData({ orders: filtersData })
          );
          if (response?.status) {
            getPageData();
          }
        }
      };
      addUnknownTimestamp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sIndex]);

  return (
    <div id="medication-report-container">
      <div className="fb-center">
        <div className="text-20-700 color-2E65">Medication Report</div>
        {onHide && (
          <div className="d-flex cursor-pointer" onClick={onHide}>
            <IoMdClose size={20} />
          </div>
        )}
      </div>
      <div className="fa-center gap-2 mt-1">
        <div className="f-center bg-eeee color-cfcf h-20 w-20 rounded-circle">
          {doctorImage ? (
            <img src={doctorImage} alt="badge-img" />
          ) : (
            <span className="material-symbols-outlined text-14-400">
              person
            </span>
          )}
        </div>
        <div className="text-13-400 color-2E65">
          Dr. {doctorFirstName} {doctorLastName}
        </div>
      </div>
      {isExpand ? (
        <div className="mt-2">
          {data?.map((elm, index) => {
            return (
              <div
                key={index}
                className="cursor-pointer mb-2"
                onClick={() => {
                  setSIndex(index);
                  setIsExpand(false);
                }}
              >
                {renderPlanCard(elm)}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="p-block">
          <div className="f-block" />
          <div className="s-block" />
          <div
            className="t-block cursor-pointer"
            onClick={() => {
              if (data?.length > 1) {
                setIsExpand(true);
              }
            }}
          >
            {renderPlanCard(displayData)}
          </div>
        </div>
      )}

      <div className="medication-list-container mt-3">
        {getOrderDates(displayData)?.map((elem, i) => {
          const { date, timeList } = elem;
          // const timeArray = getDoseAndTimeIntarval(
          //   displayData?.frequency,
          //   i === 0 ? displayData?.first_dose : ""
          // );
          const totalTaken = displayData?.medication_reports?.filter(
            (o) =>
              o.plan_status === "taken" &&
              o?.date === date.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
          );
          return (
            <div key={i}>
              <div className="fb-center">
                <div className="text-16-500 color-2E65">
                  {getDateTitle(date)}
                </div>
                <div>{`${totalTaken?.length}/${timeList?.length}`}</div>
              </div>
              {timeList?.map((elem, index) => {
                const mergeDateTime = moment(
                  `${date.format("YYYY-MM-DD")} ${elem}`
                );
                const currentDate = moment();
                const diffInHours = currentDate?.diff(mergeDateTime, "hours");
                const isUnknown = diffInHours > 72;
                let isEditable = true;
                const cDate = moment().format("DD-MM-YYYY");
                const cTime = moment();
                let nextElem = timeList?.[index + 1] || "11:59 PM";
                if (cDate === date.format("DD-MM-YYYY")) {
                  const startTime = moment(elem, "hh:mm A");
                  const endTime = moment(nextElem, "hh:mm A");
                  if (cTime.isBetween(startTime, endTime)) {
                    isEditable = true;
                  }
                }

                const findObject = displayData?.medication_reports.find(
                  (o) =>
                    o?.date === date.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") &&
                    o.time === elem
                );
                let isTaken = false;
                let userFirstName, userLastName, createdDate, notesValue;
                if (findObject?._id) {
                  isTaken = findObject?.plan_status === "taken";
                  isEditable = false;
                  userFirstName = findObject?.added_by?.[0]?.firstname;
                  userLastName = findObject?.added_by?.[0]?.lastname;
                  createdDate = moment(findObject?.createdDate)
                    .utc()
                    .format("MMM DD YYYY hh:mm a");
                  notesValue = findObject?.note;
                }
                const isTimeVisible = [
                  "q5m",
                  "q15m",
                  "q30m",
                  "q2h",
                  "q4h",
                  "q6h",
                  "q8h",
                  "q12h",
                  "BID",
                  "TID",
                  "QID",
                ].includes(displayData.frequency);
                return (
                  <div className="medication-list-wrapper my-3" key={index}>
                    <div className={isTimeVisible ? "fb-center" : "d-none"}>
                      <div className="d-flex gap-2">
                        <h4>{elem}</h4>
                        <h5 className="color-5129 text-15-600">
                          {findObject?.isUnknown &&
                            `System Marked Unknown at ${moment(
                              findObject?.unknownCreatedDate,
                              "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                            ).format("MMM DD YYYY hh:mm a")}`}
                        </h5>
                      </div>
                      {/* <p className="text-14-400 color-8693">{index + 1}</p> */}
                    </div>

                    {isEditable ? (
                      <div className="fb-center bg-eef2 br-10 cp-10 mt-2">
                        <div className="w-100">
                          <div className="text-16-400 color-2E65">
                            {planName} ({doseVal} {formVal})
                          </div>
                          <div className="d-flex align-items-center gap-2 mt-2">
                            <div>
                              <BsCapsule color="#11CA9D" />
                            </div>
                            <div className="text-13-400 color-2E65 name-block">
                              {frequencyVal} {planName} ({doseVal} {formVal})
                            </div>
                            {!isUnknown && (
                              <div className="taken-button-container gap-2">
                                <img
                                  src={redCancel}
                                  alt="cancel"
                                  className="h-18 w-18 cursor-pointer"
                                  onClick={() => {
                                    handleSelectDose(
                                      "not taken",
                                      displayData,
                                      date,
                                      elem
                                    );
                                  }}
                                />
                                <img
                                  src={greenCheck}
                                  alt="check"
                                  className="h-18 w-18 cursor-pointer"
                                  onClick={() => {
                                    handleSelectDose(
                                      "taken",
                                      displayData,
                                      date,
                                      elem
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <ul className="ps-4 outer-list-items position-relative">
                        <li className="list-outer position-relative">
                          <span
                            className={
                              isTaken
                                ? "taken-steps mt-1"
                                : "not-taken-steps mt-1"
                            }
                          >
                            {isTaken ? "Taken" : "Not Given"}
                          </span>
                          <ul className="child-listt mt-2">
                            <li className="d-flex align-items-center">
                              <BsCapsule color={isTaken ? "#11CA9D" : ""} />
                              <span className="ms-2">
                                {!isTaken && "Not Given by "}
                                {!isTaken &&
                                  userFirstName &&
                                  userFirstName}{" "}
                                {!isTaken && userLastName && userLastName}{" "}
                                {!isTaken &&
                                  userFirstName &&
                                  userLastName &&
                                  "on"}{" "}
                                {!isTaken && createdDate}
                                {/* {frequencyVal} {planName} ({doseVal} {formVal}) */}
                              </span>
                            </li>
                          </ul>
                          <span className="taken-steps mt-1">
                            {isTaken ? "Given by " : ""}
                            {isTaken && userFirstName && userFirstName}{" "}
                            {isTaken && userLastName && userLastName}{" "}
                            {isTaken && userFirstName && userLastName && "on"}{" "}
                            {isTaken ? createdDate : ""}
                          </span>
                          {notesValue && (
                            <span className="taken-steps mt-1">
                              {`Note: ${
                                notesValue ? titleCaseString(notesValue) : "-"
                              }`}
                            </span>
                          )}
                        </li>
                      </ul>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MedicationReport;
