import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Select from "react-select";
import "./index.scss";
import { toast } from "react-toastify";
import { apiUrl, PORT } from "../../environment";
import { ReactComponent as CrossSvg } from "../../assets/img/close-square-svgrepo-com.svg";
import { v4 as uuidv4 } from "uuid";
import Calendar from "../../assets/img/calendar_today.svg";
import ItemsBlock from "./ItemsBlock";
import Discount from "./ItemsBlock/discount";
import Taxes from "./ItemsBlock/taxes";
import ApiClient from "../../api-client";
import { addInvoice, updateInvoice, getAllActiveOrder, getSingleInvoiceById } from "context/actions/invoice";
import { getAllActiveServicesByPetId } from "../../context/actions/service";
import { getAndSearchAllPets } from "../../context/actions/patient-module/patient/pet";
import ProductBlock from "./ItemsBlock/products";
import ServiceBlock from "./ItemsBlock/services";
import DiagnosticServiceBlock from "./ItemsBlock/diagnostic-services";

import moment from "moment";
import { toasterConfig } from "utils/constants";


export default function AddInvoice() {
  const [selectedPet, setSelectedPet] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [status, setStatus] = useState("");
  const [discount, setDiscount] = useState(0);
  const [tax, setTax] = useState(0);
  const [issuedDate, setIssuedDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [description, setDescription] = useState("");
  const { petList } = useSelector((state) => state.petReducer);
  const { inventory } = useSelector((state) => state.inventoryReducer);
  const userDetail = useSelector((state) => state.user.user_details);
  const token = useSelector((state) => state.user.token);
  const [products, setProducts] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [payer, setPayer] = useState(false);
  const history = useHistory();
  const [selectedService, setSelectedService] = useState([]);
  const [service, setService] = useState([]);

  const { invoiceId } = useParams();

  const { state } = useLocation();

  // const [services, setServices] = useState([
  //   {
  //     serviceName: "Video Call Consultancy",
  //     unit_price: 200,
  //     amount: 200,
  //     description: "for online checkup",
  //   },
  //   {
  //     serviceName: "CT Scan",
  //     unit_price: 400,
  //     amount: 400,
  //     description: "facilitate the vetrecor patients",
  //   },
  //   {
  //     serviceName: "Routine Test",
  //     unit_price: 150,
  //     amount: 150,
  //     description: "online mode",
  //   },
  // ]);

  const [services, setServices] = useState([]);
  const [diagnosticServices, setDiagnosticServices] = useState([]);


  const statuses = [
    { value: "Pending", label: "Pending" },
    { value: "Fully Paid", label: "Fully Paid" },
    { value: "Partial Paid", label: "Partial Paid" },
    { value: "Pending Review", label: "Pending Review" },
  ];

  let lineItemDefaultState = {
    id: uuidv4(),
    line_item_name: "",
    quantity: 1,
    note: "",
    price: 0,
  };

  const [lineItems, setLineItems] = useState([]);
  const dispatch = useDispatch();
  const { clinic_details } = useSelector((state) => state.clinicReducer);

  useEffect(() => {
    if (clinic_details && !invoiceId) {
      dispatch(
        getAllActiveServicesByPetId(selectedPet?._id, {}, (res) => {
          if (res.status) {
            let data = res.response_data?.map((ele) => {
            
              return {
                quantity: 1,
                serviceName: ele.serviceDetails?.service_name,
                unit_price: ele.serviceDetails?.service_price
                  ? +ele.serviceDetails?.service_price
                  : 0,
                amount: ele.serviceDetails?.service_price
                  ? +ele?.serviceDetails?.service_price
                  : 0,
              };
            });
            setServices(data);
            setService(data);
          } else {
            setServices([]);
            setService([]);
          }
        })
      );
    }
  }, [selectedPet]);

  useEffect(() => {

  
    dispatch(getAndSearchAllPets({}, () => {
      if(invoiceId){
        getSingleInvoice(); 
      }
    })) 
   
  }, []);

  // useEffect(() => {
  //   setLineItems([lineItemDefaultState])
  //   setTaxes([taxDefaultState])
  //   setDiscounts([discountDefaultState])
  // }, [])

  const addNewLineItem = (e) => {
    e.preventDefault();
    setLineItems((lineItemsPrev) => [...lineItemsPrev, lineItemDefaultState]);
  };

  const removeLineItem = (e, id) => {
    e.preventDefault();
    let lineItemsUpdated = lineItems.filter((ele) => ele.id != id);
    setLineItems(lineItemsUpdated);
  };

  const handleLineItemChange = (e, index) => {
    e.preventDefault();
    let lineItemsArr = [...lineItems];

    let prevEle = lineItemsArr[index];

    let updatedEle = { ...prevEle, [e.target.name]: e.target.value };

    lineItemsArr[index] = updatedEle;
    setLineItems(lineItemsArr);
  };

  let taxDefaultState = {
    id: uuidv4(),
    tax_name: "",
    tax_percentage: 0,
  };

  const [taxes, setTaxes] = useState([]);

  const addNewTax = (e) => {
    e.preventDefault();
    setTaxes((taxesPrev) => [...taxesPrev, taxDefaultState]);
  };

  const removeTax = (e, id) => {
    e.preventDefault();
    let taxesUpdated = taxes.filter((ele) => ele.id != id);
    setTaxes(taxesUpdated);
  };

  const handleTaxChange = (e, index) => {
    e.preventDefault();
    let taxesArr = [...taxes];

    let prevEle = taxesArr[index];

    let updatedEle = { ...prevEle, [e.target.name]: e.target.value };

    taxesArr[index] = updatedEle;
    setTaxes(taxesArr);
  };

  let discountDefaultState = {
    id: uuidv4(),
    discount_name: "",
    discount_type: "",
    discount_percentage: 0,
  };

  const [discounts, setDiscounts] = useState([]);

  const addNewDiscount = (e) => {
    e.preventDefault();
    setDiscounts((discountsPrev) => [...discountsPrev, discountDefaultState]);
  };

  const removeDiscount = (e, id) => {
    e.preventDefault();
    let discountsUpdated = discounts.filter((ele) => ele.id != id);
    setDiscounts(discountsUpdated);
  };

  const handleDiscountChange = (e, index) => {
    e.preventDefault();
    let discountsArr = [...discounts];

    let prevEle = discountsArr[index];

    let updatedEle = { ...prevEle, [e.target.name]: e.target.value };

    discountsArr[index] = updatedEle;
    setDiscounts(discountsArr);
  };

  const handleProductQuantityChange = (e, index) => {
    let productsArr = products;
    let ele = productsArr[index];
    ele.quantity = e.target.value;
    ele.price = ele.quantity * ele.unit_price;
    // ele.discountInPer = 0;
    ele.amount = ele.quantity * ele.unit_price;
    // ele.price = ele.price - ele.discountInPer;

    productsArr[index] = ele;
    setProducts([...productsArr]);
  };

  // const validateDiscount = (value) => {
  //   return Math.min(100, Math.max(0, parseFloat(value) || 0));
  // };

  // const handleProductDiscountChange = (e, index) => {
  //   let productsArr = [...products];
  //   let ele = { ...productsArr[index] };
  //   ele.discountInPer = validateDiscount(e.target.value);
  //   ele.discount = (ele.discountInPer / 100) * ele.price;
  //   ele.amount = ele.price - ele.discount;

  //   productsArr[index] = ele;
  //   setProducts([...productsArr]);
  // };

  const handleProductDiscountChange = (value, index, discountText) => {
    const productsArr = [...products];
    const ele = { ...productsArr[index] };
    if (discountText[index] === "percentage") {
      ele.discountInPer = value.trim() !== "" ? parseFloat(value) : 0;
      ele.discount = (ele.discountInPer / 100) * ele.price;
      ele.amount = ele.price - ele.discount;
      ele.discountType = discountText[index];
    } else {
      let disValue = value.trim() !== "" ? parseInt(value) : 0;
      ele.discount = value;
      ele.amount = ele.price - disValue;
      ele.discountType = "amount";
    }
    productsArr[index] = ele;
    setProducts(productsArr);
  };

  const handleServiceQuantityChange = (e, index) => {
    let servicesArr = services;
    let ele = servicesArr[index];
    ele.quantity = e.target.value;
    ele.amount = ele.quantity * ele.unit_price;
    servicesArr[index] = ele;
    setService([...servicesArr]);
  };


  const handleDiagnosticServiceQuantityChange = (e, index) => {
    let servicesArr = diagnosticServices;
    let ele = servicesArr[index];
    ele.quantity = e.target.value;
    ele.amount = ele.quantity * ele.unit_price;
    servicesArr[index] = ele;
    setDiagnosticServices([...servicesArr]);
  };

  const handelChange = (e) => {
    const { petOwner, _id } = e;
    setSelectedPet(e);
    setPayer(e);

    dispatch(
      getAllActiveServicesByPetId(_id, {}, (res) => {
        if (res.status) {
          let data = res.response_data?.map((ele) => {
            
            return {
              quantity: 1,
              serviceName: ele.serviceDetails?.service_name,
              unit_price: ele.serviceDetails?.service_price
                ? +ele.serviceDetails?.service_price
                : 0,
              amount: ele.serviceDetails?.service_price
                ? +ele?.serviceDetails?.service_price
                : 0,
            };
          });
          setServices(data);
          setService(data);
        } else {
          setServices([]);
          setService([]);
        }
      })
    );

    dispatch(getAllActiveOrder(_id), ((orders) => {
      if (orders?.response_data?.length > 0) {
        toast.success("order-items have been fetched");
        let productsArr = [];
        let testsArr = [];
        //let servicesArr = [];
        orders?.response_data.forEach((ele) => {
          // let services = ele?.service_details;
          // services?.forEach((item) => {
          //   const service_obj = {
          //     quantity: ele?.quantity_occupied,
          //     serviceName: item?.service_name,
          //     unit_price: item?.service_price ? +item?.service_price : 0,
          //     amount:
          //       item?.service_price && ele?.quantity_occupied
          //         ? +item?.service_price * ele?.quantity_occupied
          //         : 0,
          //   };

          //   servicesArr.push(service_obj);
          // });

          let items = ele.item_details;
          let mars = ele?.mar_details;
          let test = ele?.test_details;
          if(test){
            console.log('test details', test)
            const {test_name, invoice_price} = test;
            testsArr.push({
              name: test_name, 
              quantity: 1, 
              unit_price: +invoice_price, 
              amount: +invoice_price, 
              price: +invoice_price,
              id: uuidv4()
            });


          }
          let marTotal = 0;
          items.forEach((item) => {
            if (mars?.length > 0) {
              mars?.map((val) => {
                if (val?.marQuantity) {
                  marTotal += val?.marQuantity;
                }
              });
            }
            const {
              product_name,
              product_cost,
              quantity_occupied,
              image,
              _id,
              discount,
            } = item;
            console.log(quantity_occupied)
            // let quant = 1
            let quant = mars.length
              ? marTotal
                ? marTotal
                : 1
              : quantity_occupied
                ? quantity_occupied
                : 1;
            const product_obj = {
              prod_image: image,
              quantity: quant ,
              product_name: product_name,
              product_id: _id,
              unit_price: product_cost ? parseInt(product_cost) : 0,
              amount: product_cost ? parseInt(product_cost) * quant : 0,
              id: uuidv4(),
              price: product_cost ? parseInt(product_cost) * quant : 0,
              discount: parseInt(discount),
            };

            // productsArr.push(product_obj);
            const isMedicineType = [
              "medications",
              "vaccine",
              "Infusion",
              "infusion",
            ].includes(ele.categoryID?.[0]?.category);
            if (isMedicineType && mars?.length > 0) {
              productsArr.push(product_obj);
            } else if (!isMedicineType) {
              productsArr.push(product_obj);
            }
          });
        });
        // setProducts([...products, ...productsArr]);
        setProducts([...productsArr]);
        setDiagnosticServices([...testsArr]);
        // setService([...servicesArr]);
        // setServices([...servicesArr]);

        // const item = orders?.response_data[0]?.item_details[0];
        // set_order_id(orders?.response_data[0]?._id);
        // // console.log("item", item);
        // const { product_name, product_cost, image, _id } = item;
        // let quant = 1
        // const product_obj = {
        //   prod_image: image,
        //   quantity: quant,
        //   product_name: product_name,
        //   product_id: _id,
        //   unit_price: parseInt(product_cost),
        //   amount: parseInt(product_cost) * quant,
        //   id: uuidv4()
        // };

        // console.log("product obj", product_obj);
        // products.push(product_obj);
        // setProducts([...products, product_obj]);
      } else {
        toast.success("order-items have been fetched");

        // toast.error("Please make order first");
        setProducts([]);
      }
    }));
    // ApiClient.get(`${apiUrl}${PORT}/order/get-all-active/${_id}`, {}, token)
    //   .then((res) => {
    
    //     return res;
    //   })
    //   .then((orders) => {
    //     if (orders?.response_data?.length > 0) {
    //       toast.success("order-items have been fetched");
    //       let productsArr = [];
    //       let testsArr = [];
    //       //let servicesArr = [];
    //       orders?.response_data.forEach((ele) => {
    //         // let services = ele?.service_details;
    //         // services?.forEach((item) => {
    //         //   const service_obj = {
    //         //     quantity: ele?.quantity_occupied,
    //         //     serviceName: item?.service_name,
    //         //     unit_price: item?.service_price ? +item?.service_price : 0,
    //         //     amount:
    //         //       item?.service_price && ele?.quantity_occupied
    //         //         ? +item?.service_price * ele?.quantity_occupied
    //         //         : 0,
    //         //   };

    //         //   servicesArr.push(service_obj);
    //         // });

    //         let items = ele.item_details;
    //         let mars = ele?.mar_details;
    //         let test = ele?.test_details;
    //         if(test){
    //           console.log('test details', test)
    //           const {test_name, invoice_price} = test;
    //           testsArr.push({
    //             name: test_name, 
    //             quantity: 1, 
    //             unit_price: +invoice_price, 
    //             amount: +invoice_price, 
    //             price: +invoice_price,
    //             id: uuidv4()
    //           });


    //         }
    //         let marTotal = 0;
    //         items.forEach((item) => {
    //           if (mars?.length > 0) {
    //             mars?.map((val) => {
    //               if (val?.marQuantity) {
    //                 marTotal += val?.marQuantity;
    //               }
    //             });
    //           }
    //           const {
    //             product_name,
    //             product_cost,
    //             quantity_occupied,
    //             image,
    //             _id,
    //             discount,
    //           } = item;
    //           console.log(quantity_occupied)
    //           // let quant = 1
    //           let quant = mars.length
    //             ? marTotal
    //               ? marTotal
    //               : 1
    //             : quantity_occupied
    //               ? quantity_occupied
    //               : 1;
    //           const product_obj = {
    //             prod_image: image,
    //             quantity: quant ,
    //             product_name: product_name,
    //             product_id: _id,
    //             unit_price: product_cost ? parseInt(product_cost) : 0,
    //             amount: product_cost ? parseInt(product_cost) * quant : 0,
    //             id: uuidv4(),
    //             price: product_cost ? parseInt(product_cost) * quant : 0,
    //             discount: parseInt(discount),
    //           };

    //           // productsArr.push(product_obj);
    //           const isMedicineType = [
    //             "medications",
    //             "vaccine",
    //             "Infusion",
    //             "infusion",
    //           ].includes(ele.categoryID?.[0]?.category);
    //           if (isMedicineType && mars?.length > 0) {
    //             productsArr.push(product_obj);
    //           } else if (!isMedicineType) {
    //             productsArr.push(product_obj);
    //           }
    //         });
    //       });
    //       // setProducts([...products, ...productsArr]);
    //       setProducts([...productsArr]);
    //       setDiagnosticServices([...testsArr]);
    //       // setService([...servicesArr]);
    //       // setServices([...servicesArr]);

    //       // const item = orders?.response_data[0]?.item_details[0];
    //       // set_order_id(orders?.response_data[0]?._id);
    //       // // console.log("item", item);
    //       // const { product_name, product_cost, image, _id } = item;
    //       // let quant = 1
    //       // const product_obj = {
    //       //   prod_image: image,
    //       //   quantity: quant,
    //       //   product_name: product_name,
    //       //   product_id: _id,
    //       //   unit_price: parseInt(product_cost),
    //       //   amount: parseInt(product_cost) * quant,
    //       //   id: uuidv4()
    //       // };

    //       // console.log("product obj", product_obj);
    //       // products.push(product_obj);
    //       // setProducts([...products, product_obj]);
    //     } else {
    //       toast.success("order-items have been fetched");

    //       // toast.error("Please make order first");
    //       setProducts([]);
    //     }
    //   })
    //   .catch((err) => { });
  };
  const handelChangeProduct = (e) => {
    setSelectedProduct(e);
    const product_obj = {
      quantity: quantity,
      product_name: e[e.length - 1]?.product_name,
      product_id: e[e.length - 1]?._id,
      amount: parseInt(e[e.length - 1]?.product_cost) * quantity,
    };

    products.push(product_obj);
    setProducts(products);
  };
  const handelChangeService = (e) => {
    // console.log("value Product", e);
    setSelectedService(e);
    const service_obj = {
      quantity: quantity,
      serviceName: e?.serviceName,
      // service: e[e.length - 1]?._id,
      unit_price: e.unit_price,
      amount: e.amount,
      //quantity: 1
    };

    // pr.push(product_obj);
    // setProducts(products);
    // setService([...service, service_obj]);
    setService([service_obj]);
  };
  const handelChangeStatus = (e) => {
    // setSelectedProduct(e)
    setStatus(e);
  };

  // const handelQuantity=(e,i)=>{
  //   selectedProduct[i]
  //   let value=e.target.value
  //   setQuantity({`"quantity"`:value})
  // }

  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );

  const addInvoiceHandle = () => {
    if(!selectedPet?._id) return toast.error("Please select patient!", toasterConfig)
    const data = {
      // _id: invoiceId ? invoiceId :'',
      description: description,
      issued_date: issuedDate,
      due_date: dueDate,
      status: status?.value,
      orders: products,
      patient_id: selectedPet?._id,
      discount: parseInt(discount),
      tax: parseInt(tax),
      payee: payer?.petOwner?._id,
      // clinic_id: userDetail?._id,
      clinic_id: clinic_settings?.clinic_id,
      created_by: userDetail?._id,
      //order_id: order_id,
      services: service,
      tests: diagnosticServices,
      lineItems: lineItems,
      discounts: discounts,
      taxes: taxes,

    };

    // upadet payload 
    const upadtedata = {
      _id: invoiceId ? invoiceId : '',
      description: description,
      issued_date: issuedDate,
      due_date: dueDate,
      status: status?.value,
      orders: products,
      patient_id: selectedPet?._id,
      discount: parseInt(discount),
      tax: parseInt(tax),
      payee: payer?.petOwner?._id,
      clinic_id: userDetail?._id,
      clinic_id: clinic_settings?.clinic_id,
      created_by: userDetail?._id,
      // order_id: order_id,
      tests: diagnosticServices,
      services: service,
      lineItems: lineItems,
      discounts: discounts,
      taxes: taxes,

    }


    if (invoiceId) {
   
     dispatch(updateInvoice(upadtedata, (res) => {
          if(res?.statusCode === 200 && res?.status){
            toast.success(res?.message);
            history.push("/invoices")
          }else{
            toast.error(res?.message);
          }
          return res.data;
        }))

    } else {
      dispatch(addInvoice( data, (res) => {
          if(res?.statusCode === 201 && res?.status){
            toast.success(res?.message);
            history.push("/invoices")
          }else{
            toast.error(res?.message);
          }
          return res.data;
        }))
    }



  };





  const getSingleInvoice = async () => {
    try {


      dispatch(getSingleInvoiceById(invoiceId, (res) => {
      const invoiceData = res.response_data[0];

      // console.log("v>>>>>,", invoiceData);
      setDescription(invoiceData?.description || '');
      setIssuedDate(invoiceData?.issued_date || '');
      setDueDate(invoiceData?.due_date || '');
      setLineItems(invoiceData?.lineItems?.map(item => {item.id = item._id
      return item} ));
      setDiscounts(invoiceData?.discounts?.map(item => {item.id = item._id
        return item} ))
      setTaxes(invoiceData?.taxes?.map(item => {item.id = item._id
        return item} ))

      setServices(invoiceData?.services)
      setService(invoiceData?.services)
      setDiagnosticServices(invoiceData?.tests)

      setProducts(invoiceData?.orders)

      const invoiceStatus = { value: invoiceData?.status, label: invoiceData?.status };
      setStatus(invoiceStatus);

      dispatch(getAndSearchAllPets({}, (response) => {

        if(response?.status){


          if (invoiceData?.patient_id) {
            console.log(petList, 'petList')
            const patientData = response?.response_data?.find(data => data._id === invoiceData?.patient_id);
            console.log('patient found ')

            if (patientData?._id) {
              console.log('found at')
              const details = {
                id: patientData?._id,
                value: `${patientData?.pet_name} (${patientData?.vrn})`,
                label: (
                  <div className="flex items-center gap-1 ">
                    <img
                      src={patientData?.pet_image}
                      className="w-[35px] h-[35px] rounded-full"
                    />{" "}
                    {patientData?.pet_name} ({patientData?.vrn})
                  </div>
                ),
                ...patientData,
              }


            setSelectedPet(details);
            setPayer(details);
              // handelChange(details)
            } else {
              console.log('no patient found ')
            }
          }}}))
        }))

    } catch (err) {
      console.log("Error fetching invoice data:", err);
    }
  };
  useEffect(() => {
    if(invoiceId) getSingleInvoice();
  }, [invoiceId]);





  const [invoiceTotal, setInvoiceTotal] = useState(0);
  const [totalWithoutTaxesAndDiscounts, setTotalWithoutTaxesAndDiscounts] =
    useState(0);

  useEffect(() => {
    let total = 0;
    let discountsTotal = 0;
    let taxTotal = 0;

    lineItems.forEach((ele) => {
      if (ele.line_item_name != "") {
        total += +ele.price;
      }
    });

    service?.forEach((ele) => {
      total += parseFloat(ele.amount)
    })

    diagnosticServices?.forEach((ele) => {
      total += parseFloat(ele.amount)
    })
    products?.forEach((ele) => {
      total += parseFloat(ele.amount)
    })

    setTotalWithoutTaxesAndDiscounts(total);

    if (total > 0) {
      discounts?.forEach((ele) => {
        if (ele.discount_name != '') {
          if (ele.discount_type == "amount") {
            let discountValInt = +ele.discount_percentage;
            discountsTotal += discountValInt;
          } else if (ele.discount_type == "percentage") {
            let totalInt = +total;
            let discountValInt = +ele.discount_percentage;
            let percentageVal = (totalInt * discountValInt) / 100;
            discountsTotal += percentageVal;
          }
        }
      });
    }
    if (total > 0) {
      taxes.forEach((ele) => {
        if (ele.tax_name != "") {
          let totalInt = +total;
          let taxTotalInt = +ele.tax_percentage;
          let percentageVal = (totalInt * taxTotalInt) / 100;
          taxTotal += percentageVal;
        }
      });
    }

    let finalTotal = total + taxTotal - discountsTotal;

    setInvoiceTotal(parseFloat(finalTotal));
  }, [lineItems, discounts, taxes, service, products, diagnosticServices]);

  const [addItem, setAddItem] = useState(false);
  const [addDiscount, setAddDiscount] = useState(false);
  const [addTax, setAddTax] = useState(false);

  return (
    <>
      <div className="p-3">
        <div className="pb-3 flex  align-items-center gap-y-3 justify-between">
          <div>
            <p className="text-[20px] text-[#282E65] font-[600]">Invoices</p>
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="flex-1 pe-2 w-[70%]">

            <div className="flex  w-[100%]">
              <div className=" pe-2 w-[100%]">
                <div className="bg-[#f8f8f8] rounded-lg mt-0 mx-0 p-[15px]">
                  <h2 className="font-['Inter']  text-[#282E65] font-[600] my-3 text-[24px]">
                    Invoice Information
                  </h2>
                  <div className="flex gap-3 ">
                    <div className="w-100">
                      <label className="block mb-2 text-sm text-[16px] font-[400] font-['Inter']  text-[#282E65]">
                        Invoice Information
                      </label>
                      <div className="flex information">
                        <div className="w-50 me-2 relative">

                          <input
                            type="text"
                            onFocus={(e) => (e.target.type = "date")}
                            onBlur={(e) => {
                              if (issuedDate === '') e.target.type = "text";
                            }}
                            className="bg-[#fff] border-gray-300 font-['Inter'] border-none text-[#282E65] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-100 p-2.5 relative"
                            placeholder="Issued Date"
                            defaultValue={issuedDate && moment(issuedDate).format("MM/DD/YYYY")}
                            name="date"
                            onChange={(e) => setIssuedDate(moment(e.target.value).toDate())}
                          />

                          <img className="absolute" src={Calendar} />
                        </div>

                        <div className="w-50 me-2 relative">
                          <input
                            type="text"
                            onFocus={(e) => (e.target.type = "date")}
                            onBlur={(e) => {
                              if (issuedDate === '')
                                e.target.type = "text"
                            }
                            }
                            className="bg-[#fff]  border-gray-300 font-['Inter'] border-none	 text-[#282E65] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-100 p-2.5"
                            placeholder="Due Date"
                            defaultValue={dueDate && moment(dueDate).format("MM/DD/YYYY")}
                            onChange={(e) => setDueDate(moment(e.target.value).toDate())}
                          />
                          <img className="absolute" src={Calendar} />
                        </div>

                        <div className="w-50">
                          <Select
                            className="w-100 custom-select  font-['Inter']text-[#282E65] text-sm"
                            classNames={{
                              placeholder: () => 'text-[#282E65] text-sm rounded-lg ',

                            }}
                            placeholder={'Select Status'}
                            options={statuses}
                            value={status}
                            // defaultValue={status}

                            onChange={handelChangeStatus}
                          />


                        </div>
                      </div>
                      <div className="w-100 mt-3">
                        <textarea
                          type="notes"
                          className="bg-[#f2f2f2]  border-none font-['Inter']  text-[#788693] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-100 p-2.5 "
                          placeholder="Invoice notes (If any)"
                          value={description ? description : ""}
                          name="description"
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                        />
                      </div>
                      <div className="bg-[#f8f8f8] rounded-lg mt-0 mx-0 ] pt-15px  ">
                        <div className="flex w-[100%] items-center">
                          <div className="w-50 p-0">
                            <label className="block mb-2 text-sm font-['Inter']  text-[#282E65] text-[16px] font-[400]">
                              Patient Information
                            </label>
                            <Select
                              className="w-100 py-2.5 px-0 custom-select  font-['Inter']text-[#282E65] text-sm"
                              styles={{
                                placeholder: (baseStyles) => ({
                                  ...baseStyles,
                                  color: '#282E65',
                                }),
                              }}
                              isDisabled={invoiceId ? true : false}
                              options={petList && petList?.length > 0 && petList?.map((data) => ({
                                id: data?._id,
                                value: `${data?.pet_name} (${data?.vrn})`,
                                label: (
                                  <div className="flex items-center gap-1 ">
                                    <img
                                      src={data?.pet_image}
                                      className="w-[35px] h-[35px] rounded-full"
                                    />{" "}
                                    {data?.pet_name} ({data?.vrn})
                                  </div>
                                ),
                                ...data,
                              }))}
                              placeholder={'Please select Patient'}
                              value={selectedPet}
                              // isMulti={true}

                              onChange={handelChange}
                            />
                          </div>

                          <div className={payer ? "w-[71%]" : "w-[71%]"}>
                            <div className="flex items-center gap-2 ">
                              {payer ? (
                                <>
                                  <div className="ms-4 mt-4 flex items-center">
                                    <img
                                      src={payer?.petOwner?.image}
                                      className="w-[35px] h-[35px] rounded-full"
                                      alt="petOwner"

                                    />
                                    <p className="ms-3">
                                      {payer?.petOwner?.owner_name}
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <div className=" rounded-lg">
                        {products?.length > 0 ? (
                          <table className="float-right w-[460px] text-sm text-left text-gray-500 rounded-lg">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                              <tr>
                                <th scope="col" className="px-3 py-3">
                                  Product name
                                </th>
                                <th scope="col" className="px-3 py-3 w-[51px]">
                                  Quantity
                                </th>
                                <th scope="col" className="px-3 py-3">
                                  Unit Price
                                </th>
                                <th scope="col" className="px-3 py-3">
                                  Total Price
                                </th>
                              </tr>
                            </thead>

                            {products?.length > 0 &&
                              products.map((data, index) => {
                                return (
                                  <tbody>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                      <td className="px-3 py-4 flex items-center gap-1">
                                        <img
                                          className=" w-[35px] h-[35px] rounded-lg"
                                          src={data?.prod_image}
                                        />{" "}
                                        {data.product_name}
                                      </td>
                                      <td className="px-3 py-4 text-center">
                                        <input
                                          type="number"
                                          min={1}
                                          value={+data.quantity}
                                          onChange={(e) => handleProductQuantityChange(e, index)}
                                          placeholder="Enter quantity"
                                          className="w-[20px] border px-1 w-auto text-center"
                                          style={{ maxWidth: "50px" }}
                                        />
                                      </td>
                                      <td className="px-3 py-4">{data?.unit_price}</td>
                                      <td className="px-3 py-4">
                                        {parseInt(+data?.amount)}
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                          </table>
                        ) : (
                          ""
                        )}
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-[#f8f8f8] rounded-lg mt-4 mx-0 p-[15px]  ">
                  <h2 className="font-['Inter']  text-[#282E65]  my-3 font-[600]  text-[24px]">
                    Products info
                  </h2>
                  <div className="flex w-[100%]">
                    <ProductBlock
                      handleProductQuantityChange={handleProductQuantityChange}
                      handleProductDiscountChange={handleProductDiscountChange}
                      products={products}
                    />

                  {/* <div className=" rounded-lg">
                    {service?.length > 0 ? (
                      <table className="float-right w-[460px] text-sm text-left text-gray-500 dark:text-gray-400 rounded-lg">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th scope="col" className="px-3 py-3">
                              Service name
                            </th>
                            <th scope="col" className="px-3 py-3 w-[51px]">
                              Quantity
                            </th>
                            <th scope="col" className="px-3 py-3">
                              Unit Price
                            </th>
                            <th scope="col" className="px-3 py-3">
                              Total Price
                            </th>
                          </tr>
                        </thead>

                        {service?.length > 0 &&
                          service.map((data, index) => {
                            return (
                              <tbody>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                  <td className="px-3 py-4">{data?.serviceName}</td>
                                  <td className="px-3 py-4 text-center">
                                    <input
                                      type="number"
                                      min={1}
                                      step={"1"}
                                      value={data.quantity}
                                      onChange={(e) => handleServiceQuantityChange(e, index)}
                                      placeholder="Enter quantity"
                                      className="w-[20px] border px-1 w-auto text-center"
                                      style={{ maxWidth: "50px" }}
                                    />
                                  </td>
                                  <td className="px-3 py-4">{parseInt(data?.unit_price)}</td>
                                  <td className="px-3 py-4">{parseInt(data?.amount)}</td>
                                </tr>
                              </tbody>
                            );
                          })}
                      </table>
                    ) : (
                      ""
                    )}
                  </div> */}
                  </div>
                  <div className="w-100">
                    {services?.length > 0 && (
                      <>
                        <label className="block mb-2 text-sm font-['Inter']  text-[#282E65] text-[16px] font-[400]">
                          Service Information
                        </label>
                        {/*<Select
                    className="w-100 custom-select"
                    placeholder={'Please select service type'}
                    options={
                      services.length > 0 &&
                      services?.map((data) => ({
                        value: data?.serviceName,
                        label: (
                          <div className="flex items-center gap-1">
                            {data?.serviceName}
                          </div>
                        ),
                        ...data,
                      }))
                    }
                    value={selectedService}
                    // isMulti={true}
                    onChange={handelChangeService}
                  />*/}
                        <ServiceBlock
                          services={services}
                          handleServiceQuantityChange={
                            handleServiceQuantityChange
                          }
                        />
                      </>
                    )}

                    {diagnosticServices?.length > 0 && (
                      <>
                        <label className="block mb-2 text-sm font-['Inter']  text-[#282E65] text-[16px] font-[400]">
                          Diagnostic Services
                        </label>
                       
                        <DiagnosticServiceBlock
                          services={diagnosticServices}
                          handleServiceQuantityChange={
                            handleDiagnosticServiceQuantityChange
                          }
                        />
                      </>
                    )}

                    <div className="relative py-2 flex justify-between items-center">
                      <label className="block mb-2 mt-4 text-sm font-['Inter']  text-[#282E65] text-[16px] font-[400]">
                        Items
                      </label>
                      <div>
                        <button
                          className="add-btn bg-[transparent] border-[#e5e7eb] border-solid border-2 font-[600] mt-3 font-['Inter'] text-[#3D3EFD] text-[12px] uppercase px-3.5 py-2.5 rounded-lg w-[100%]"
                          type="button"
                          onClick={() => setAddItem(!addItem)}
                        >
                          ADD NEW
                        </button>
                      </div>
                    </div>
                    <ItemsBlock
                      addNew={addItem}
                      setAddItem={setAddItem}
                      lineItems={lineItems}
                      setLineItems={setLineItems}
                    />
                    <div className="relative py-2 flex justify-between items-center">
                      <label className="block mb-2 mt-4 text-sm font-['Inter']  text-[#282E65] text-[16px] font-[400]">
                        Discounts
                      </label>
                      <div>
                        <button
                          type="button"
                          className="add-btn bg-[transparent] border-[#e5e7eb] border-solid border-2 font-[600] mt-3 font-['Inter'] text-[#3D3EFD] text-[12px] uppercase px-3.5 py-2.5 rounded-lg w-[100%]"
                          onClick={() => setAddDiscount(!addDiscount)}
                        >
                          ADD NEW
                        </button>
                      </div>
                    </div>
                    <Discount
                      addNew={addDiscount}
                      setAddDiscount={setAddDiscount}
                      discounts={discounts}
                      setDiscounts={setDiscounts}
                    />
                    <div className="relative py-2 flex justify-between items-center">
                      <label className="block mb-2 mt-4 text-sm font-['Inter']  text-[#282E65] text-[16px] font-[400]">
                        Taxes
                      </label>
                      <div>
                        <button
                          type="button"
                          className="add-btn bg-[transparent] border-[#e5e7eb] border-solid border-2 font-[600] mt-3 font-['Inter'] text-[#3D3EFD] text-[12px] uppercase px-3.5 py-2.5 rounded-lg w-[100%]"
                          onClick={() => setAddTax(!addTax)}
                        >
                          ADD NEW
                        </button>
                      </div>
                    </div>
                    <Taxes
                      addNew={addTax}
                      setAddTax={setAddTax}
                      taxes={taxes}
                      setTaxes={setTaxes}
                    />
                  </div>
                </div>

                {/* services section */}

                <div className="flex justify-end  mt-1 mx-5 p-[15px]"></div>
              </div>
            </div>
          </div>
          <div className="appointment_row invoice-appointment-row">
            <div className="bg-[#f8f8f8] rounded-lg mx-0 p-[15px] ">
              <h2 className="font-['Inter']  text-[#282E65] text-[24px] font-[600] my-3">
                Invoice Summary
              </h2>
              {diagnosticServices.length > 0 && (
                <>
                  <h3 className="mt-2 mb-1">Diagnostic Services</h3>
                  {
                    <div className="flex gap-3 ">
                      <div className="w-100">
                        <table class="table table-auto w-[100%] table-striped invoice-summary-table">
                          <thead>
                            <tr>
                              <th
                                style={{ width: "40%" }}
                                className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1"
                              >
                                Service name
                              </th>
                              <th
                                style={{ width: "10%" }}
                                className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1"
                              >
                                Qty
                              </th>
                              <th
                                style={{ width: "20%" }}
                                className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1"
                              >
                                Price
                              </th>
                              <th
                                style={{ width: "30%" }}
                                className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1"
                              >
                                Total Price
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {diagnosticServices?.map((ele, index) => {
                              return (
                                <tr className="bg-[#f2f2f2]">
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">
                                    {" "}
                                    {ele.name}
                                  </td>
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">
                                    {" "}
                                    {ele.quantity}
                                  </td>
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">
                                    $
                                    {ele.unit_price.toLocaleString("en-US", {
                                      minimumFractionDigits: 2,
                                    })}
                                  </td>
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">
                                    $
                                    {ele.amount.toLocaleString("en-US", {
                                      minimumFractionDigits: 2,
                                    })}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  }
                </>
              )}
              {service.length > 0 && (
                <>
                  <h3 className="mt-2 mb-1">Services</h3>
                  {
                    <div className="flex gap-3 ">
                      <div className="w-100">
                        <table class="table table-auto w-[100%] table-striped invoice-summary-table">
                          <thead>
                            <tr>
                              <th
                                style={{ width: "40%" }}
                                className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1"
                              >
                                Service name
                              </th>
                              <th
                                style={{ width: "10%" }}
                                className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1"
                              >
                                Qty
                              </th>
                              <th
                                style={{ width: "20%" }}
                                className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1"
                              >
                                Price
                              </th>
                              <th
                                style={{ width: "30%" }}
                                className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1"
                              >
                                Total Price
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {service?.map((ele, index) => {
                              return (
                                <tr className="bg-[#f2f2f2]">
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">
                                    {" "}
                                    {ele.serviceName}
                                  </td>
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">
                                    {" "}
                                    {ele.quantity}
                                  </td>
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">
                                    $
                                    {ele.unit_price.toLocaleString("en-US", {
                                      minimumFractionDigits: 2,
                                    })}
                                  </td>
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">
                                    $
                                    {ele.amount.toLocaleString("en-US", {
                                      minimumFractionDigits: 2,
                                    })}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  }
                </>
              )}

              {products?.length > 0 && (
                <>
                  <h3 className="mt-2 mb-1">Products</h3>
                  {
                    <div className="flex gap-3 ">
                      <div className="w-100">
                        <table class="table table-auto w-[100%] table-striped invoice-summary-table">
                          <thead>
                            <tr>
                              <th
                                style={{ width: "40%" }}
                                className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1"
                              >
                                Name
                              </th>
                              <th
                                style={{ width: "10%" }}
                                className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1"
                              >
                                Qty
                              </th>
                              <th
                                style={{ width: "20%" }}
                                className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1"
                              >
                                Price
                              </th>
                              <th
                                style={{ width: "30%" }}
                                className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1"
                              >
                                Total Price
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {products?.map((ele, index) => {
                              return (
                                <tr className="bg-[#f2f2f2]">
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">
                                    {" "}
                                    {ele.product_name}
                                  </td>
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">
                                    {" "}
                                    {ele.quantity}
                                  </td>
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">
                                    $
                                    {ele.unit_price.toLocaleString("en-US", {
                                      minimumFractionDigits: 2,
                                    })}
                                  </td>
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">
                                    $
                                    {ele.amount.toLocaleString("en-US", {
                                      minimumFractionDigits: 2,
                                    })}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  }
                </>
              )}

              {lineItems?.length > 0 && (
                <>
                  <h3 className="mt-3 font-['Inter']  text-[#282E65] text-[16px] font-[400] mt-2 mb-2">
                    Items List
                  </h3>
                  <div className="flex gap-3 ">
                    <div className="w-100">
                      <table class="table table-auto w-[100%] table-striped invoice-summary-table">
                        <thead>
                          <tr>
                            <th
                              style={{ width: "40%" }}
                              className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1"
                            >
                              Name
                            </th>
                            <th
                              style={{ width: "30%" }}
                              className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1"
                            >
                              Qty
                            </th>
                            {/* <th className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1">Price</th> */}
                            <th
                              style={{ width: "30%" }}
                              className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1"
                            >
                              Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {lineItems.map((ele, index) => {
                            if (ele.line_item_name != "") {
                              return (
                                <tr className="bg-[#f2f2f2]">
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">
                                    {ele.line_item_name}
                                  </td>
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">
                                    {ele.quantity.toLocaleString("en-US")}
                                  </td>
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">
                                    $
                                    {ele.price.toLocaleString("en-US", {
                                      minimumFractionDigits: 2,
                                    })}
                                  </td>
                                  {/* <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">${ele.amount}</td> */}
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}

              {discounts?.length > 0 && (
                <>
                  <h3 className="mt-3 font-['Inter']  text-[#282E65] text-[16px] font-[400] mt-2 mb-2">
                    Discounts
                  </h3>

                  <div className="flex gap-3 ">
                    <div className="w-100">
                      <table class="table table-auto w-[100%] table-striped invoice-summary-table">
                        <thead>
                          <tr>
                            <th
                              style={{ width: "40%" }}
                              className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1"
                            >
                              Name
                            </th>
                            <th
                              style={{ width: "30%" }}
                              className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1"
                            >
                              Value
                            </th>
                            <th
                              style={{ width: "30%" }}
                              className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1"
                            >
                              Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {discounts.map((ele, index) => {
                            if (ele.discount_name != "") {
                              return (
                                <tr className="bg-[#f2f2f2]">
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">
                                    {ele.discount_name}
                                  </td>

                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">
                                    {" "}
                                    {ele.discount_type !== "percentage"
                                      ? "$"
                                      : ""}
                                    {ele.discount_percentage.toLocaleString(
                                      "en-US",
                                      {
                                        minimumFractionDigits: 2,
                                      }
                                    )}
                                    {ele.discount_type == "percentage"
                                      ? "%"
                                      : ""}
                                  </td>

                                  {ele.discount_type == "percentage" ? (
                                    <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">
                                      $
                                      {(
                                        (+ele.discount_percentage / 100) *
                                        totalWithoutTaxesAndDiscounts
                                      ).toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                      })}
                                    </td>
                                  ) : (
                                    <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">
                                      $
                                      {ele.discount_percentage.toLocaleString(
                                        "en-US",
                                        {
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                    </td>
                                  )}
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}

              {taxes?.length > 0 && (
                <>
                  <h3 className="mt-3 font-['Inter']  text-[#282E65] text-[16px] font-[400] mt-2 mb-2">
                    Taxes
                  </h3>

                  <div className="flex gap-3 ">
                    <div className="w-100">
                      <table class="table table-auto w-[100%] table-striped invoice-summary-table">
                        <thead>
                          <tr>
                            <th
                              style={{ width: "40%" }}
                              className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1"
                            >
                              Name
                            </th>
                            <th
                              style={{ width: "30%" }}
                              className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1"
                            >
                              %
                            </th>
                            <th
                              style={{ width: "30%" }}
                              className="font-['Inter']  text-[#788C9F] text-[13px] font-[400] py-2 px-1"
                            >
                              Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {taxes.map((ele, index) => {
                            if (ele.tax_name != "") {
                              return (
                                <tr className="bg-[#f2f2f2]">
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">
                                    {ele.tax_name}
                                  </td>
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">
                                    {ele.tax_percentage.toLocaleString(
                                      "en-US",
                                      {
                                        minimumFractionDigits: 2,
                                      }
                                    )}
                                    %
                                  </td>
                                  <td className="font-['Inter']  text-[#282E65] text-[14px] font-[400] py-2 px-1">
                                    $
                                    {(
                                      (+ele.tax_percentage / 100) *
                                      totalWithoutTaxesAndDiscounts
                                    ).toLocaleString("en-US", {
                                      minimumFractionDigits: 2,
                                    })}
                                  </td>
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
              <br />
              <hr />
              <div className="flex gap-3 mt-2 justify-between">
                <div className="w-50">
                  <p className="font-['Inter']  text-[#282E65] text-[14px] font-[600] ">
                    Total
                  </p>
                </div>

                <div className="w-50 text-right">
                  <p className="font-['Inter']  text-[#282E65] text-[14px] font-[600] ">
                    $
                    {invoiceTotal.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                    })}
                  </p>
                </div>
              </div>
              <div className="w-[100%]">
                <button
                  onClick={addInvoiceHandle}
                  className="bg-[#3D3EFD] mt-3 font-['Inter'] text-[#fff] text-[14px] uppercase px-3.5 py-2.5 rounded-lg w-[100%]"
                >
                  Save invoice
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ----------------------------------------------------------------------------------------------------------------------------- */}
    </>
  );
  return (
    <>
      <div className="bg-[#f8f8f8] rounded-lg mt-5  mx-5 p-[15px]">
        <h2 className="font-['Inter']  text-[#282E65] text-[18px] font-[600] my-3">
          Add Invoice
        </h2>
        <div className="flex gap-3 ">
          <div className="w-50">
            <label className="block mb-2  text-sm font-medium  font-['Inter']  text-[#282E65]">
              Description
            </label>
            <input
              type="text"
              id="first_name"
              className="bg-[#fff] border border-gray-300 font-['Inter']  text-[#282E65] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5"
              placeholder="invoice description"
              name="description"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </div>
        </div>
      </div>

      {/* invoice status section */}
      <div className="bg-[#f8f8f8] rounded-lg mt-1 mx-5 p-[15px] ">
        <h2 className="font-['Inter']  text-[#282E65] text-[18px] my-3 font-[600]">
          Invoice Status
        </h2>
        <div className="flex">
          <div className=" w-50">
            <label className="block mb-2 text-sm font-medium  font-['Inter']  text-[#282E65]">
              Issued Date
            </label>
            <input
              type="date"
              className="bg-[#fff] border border-gray-300 font-['Inter']  text-[#282E65] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 "
              placeholder="Issued Date"
              // value={issuedDate}
              name="date"
              onChange={(e) => setIssuedDate(new Date(e.target.value))}
            />
          </div>

          <div className="w-50">
            <label className="block mb-2 text-sm font-medium  font-['Inter']  text-[#282E65]">
              Due Date
            </label>
            <input
              type="date"
              className="bg-[#fff] border border-gray-300 font-['Inter']  text-[#282E65] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5"
              placeholder="Due Date"
              // value={dueDate}
              onChange={(e) => setDueDate(new Date(e.target.value))}
            />
          </div>

          <div className="w-50">
            <label className="block mb-2 text-sm font-medium  font-['Inter']  text-[#282E65]">
              Status
            </label>
            <Select
              className="w-80"
              options={statuses}
              value={status}
              onChange={handelChangeStatus}
            />
          </div>
        </div>
      </div>

      {/* patient info section */}

      <div className="bg-[#f8f8f8] rounded-lg mt-1 mx-5 p-[15px] ">
        <h2 className="font-['Inter']  text-[#282E65] text-[18px] my-3 font-[600]">
          Patient Info
        </h2>
        <div className="flex w-[84%]">
          <div className="w-50">
            <label className="block mb-2 text-sm font-medium  font-['Inter']  text-[#282E65]">
              Pets
            </label>
            <Select
              className="w-80"
              options={petList?.docs?.map((data) => ({
                value: data?.pet_name,
                label: (
                  <div className="flex items-center gap-1">
                    <img
                      src={data?.pet_image}
                      className="w-[35px] h-[35px] rounded-full"
                    />{" "}
                    {data?.pet_name}
                  </div>
                ),
                ...data,
              }))}
              value={selectedPet}
              // isMulti={true}

              onChange={handelChange}
            />
          </div>

          <div className={payer ? "w-[71%]" : "w-[71%]"}>
            <label className="block mb-2 text-sm font-medium  font-['Inter']  text-[#282E65]">
              Payer
            </label>

            <div className="flex items-center gap-2">
              {payer ? (
                <>
                  <img
                    src={payer?.petOwner?.image}
                    className="w-[35px] h-[35px] rounded-full"
                  />
                  <p>{payer?.petOwner?.owner_name}</p>
                </>
              ) : (
                <p>Payer info should be here</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Product info section */}
      <div className="bg-[#f8f8f8] rounded-lg mt-1 mx-5 p-[15px] ">
        <h2 className="font-['Inter']  text-[#282E65] text-[18px] my-3 font-[600]">
          Products info
        </h2>
        <div className="flex w-[73%]">
          {/* <div className="w-50">
            <label className="block mb-2 text-sm font-medium  font-['Inter']  text-[#282E65]">
              Products
            </label>
            <Select
              className="w-80"
              isMulti={true}
              options={
                inventory.length > 0 &&
                inventory?.map((data) => ({
                  value: data?._id,
                  label: (
                    <div className="flex items-center gap-1">
                      <img
                        src={data?.image}
                        alt="image"
                        className="w-[35px] h-[35px] rounded-full"
                      />{" "}
                      {data?.product_name}
                    </div>
                  ),
                  ...data,
                }))
              }
              value={selectedProduct}
              // isMulti={true}
              onChange={handelChangeProduct}
            />
          </div>  */}

          <div className=" rounded-lg">
            {products?.length > 0 ? (
              <table className="float-right w-[460px] text-sm text-left text-gray-500 rounded-lg">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                  <tr>
                    <th scope="col" className="px-3 py-3">
                      Product name
                    </th>
                    <th scope="col" className="px-3 py-3 w-[51px]">
                      Quantity
                    </th>
                    <th scope="col" className="px-3 py-3">
                      Unit Price
                    </th>
                    <th scope="col" className="px-3 py-3">
                      Total Price
                    </th>
                  </tr>
                </thead>

                {products?.length > 0 &&
                  products.map((data, index) => {
                    return (
                      <tbody>
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <td className="px-3 py-4 flex items-center gap-1">
                            <img
                              className=" w-[35px] h-[35px] rounded-lg"
                              src={data?.prod_image}
                            />{" "}
                            {data.product_name}
                          </td>
                          <td className="px-3 py-4 text-center">
                            <input
                              type="number"
                              min={1}
                              value={+data.quantity}
                              onChange={(e) =>
                                handleProductQuantityChange(e, index)
                              }
                              placeholder="Enter quantity"
                              className="w-[20px] border px-1 w-auto text-center"
                              style={{ maxWidth: "50px" }}
                            />
                          </td>
                          <td className="px-3 py-4">{data?.unit_price}</td>
                          <td className="px-3 py-4">
                            {parseInt(+data?.amount)}
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
              </table>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {/* services section */}
      <div className="bg-[#f8f8f8] rounded-lg mt-1 mx-5 p-[15px] ">
        <h2 className="font-['Inter']  text-[#282E65] text-[18px] my-3 font-[600]">
          Services info
        </h2>
        <div className="flex w-[73%]">
          <div className="w-50">
            <label className="block mb-2 text-sm font-medium  font-['Inter']  text-[#282E65]">
              Services
            </label>
            <Select
              className="w-80"
              options={
                services.length > 0 &&
                services?.map((data) => ({
                  value: data?.serviceName,
                  label: (
                    <div className="flex items-center gap-1">
                      {data?.serviceName}
                    </div>
                  ),
                  ...data,
                }))
              }
              value={selectedService}
              // isMulti={true}
              onChange={handelChangeService}
            />
          </div>

          <div className=" rounded-lg">
            {service?.length > 0 ? (
              <table className="float-right w-[460px] text-sm text-left text-gray-500 dark:text-gray-400 rounded-lg">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-3 py-3">
                      Service name
                    </th>
                    <th scope="col" className="px-3 py-3 w-[51px]">
                      Quantity
                    </th>
                    <th scope="col" className="px-3 py-3">
                      Unit Price
                    </th>
                    <th scope="col" className="px-3 py-3">
                      Total Price
                    </th>
                  </tr>
                </thead>

                {service?.length > 0 &&
                  service.map((data, index) => {
                    return (
                      <tbody>
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <td className="px-3 py-4">{data?.serviceName}</td>
                          <td className="px-3 py-4 text-center">
                            <input
                              type="number"
                              min={1}
                              step={"1"}
                              value={data.quantity}
                              onChange={(e) =>
                                handleServiceQuantityChange(e, index)
                              }
                              placeholder="Enter quantity"
                              className="w-[20px] border px-1 w-auto text-center"
                              style={{ maxWidth: "50px" }}
                            />
                          </td>
                          <td className="px-3 py-4">
                            {parseInt(data?.unit_price)}
                          </td>
                          <td className="px-3 py-4">
                            {parseInt(data?.amount)}
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
              </table>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <div className="bg-[#f8f8f8] rounded-lg mt-5  mx-5 p-[15px]">
        <h2 className="font-['Inter']  text-[#282E65] text-[18px] font-[600] my-3">
          Add Items
        </h2>
        <div className="flex justify-end  mt-1 mx-5 p-[15px]">
          <button
            onClick={addNewLineItem}
            className="bg-[#11CA9D] font-['Inter'] text-[#fff] uppercase px-3.5 py-2.5 rounded-lg"
          >
            +
          </button>
        </div>
        {lineItems?.map((ele, index) => {
          return (
            <div className="flex gap-3 ">
              <div className="w-50">
                <label className="block mb-2  text-sm font-medium  font-['Inter']  text-[#282E65]">
                  Item Name
                </label>
                <input
                  type="text"
                  id="line_item_name"
                  className="bg-[#fff] border border-gray-300 font-['Inter']  text-[#282E65] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5"
                  placeholder="Item Name"
                  name="line_item_name"
                  value={ele.line_item_name}
                  onChange={(e) => {
                    handleLineItemChange(e, index);
                  }}
                />
              </div>

              <div className="w-50">
                <label className="block mb-2 text-sm font-medium  font-['Inter']  text-[#282E65]">
                  Note
                </label>
                <input
                  type="text"
                  id="note"
                  className="bg-[#fff] border border-gray-300 font-['Inter']  text-[#282E65] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 "
                  placeholder="Note"
                  value={ele.note}
                  name="note"
                  onChange={(e) => {
                    handleLineItemChange(e, index);
                  }}
                />
              </div>

              <div className="w-50">
                <label className="block mb-2 text-sm font-medium  font-['Inter']  text-[#282E65]">
                  Quantity
                </label>
                <input
                  type="number"
                  id="price"
                  name="quantity"
                  step="1"
                  min="1"
                  className="bg-[#fff] border border-gray-300 font-['Inter']  text-[#282E65] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5"
                  placeholder="Quantity"
                  value={ele.quantity}
                  onChange={(e) => {
                    handleLineItemChange(e, index);
                  }}
                />
              </div>

              <div className="w-50">
                <label className="block mb-2 text-sm font-medium  font-['Inter']  text-[#282E65]">
                  Price
                </label>
                <input
                  type="number"
                  id="price"
                  name="price"
                  className="bg-[#fff] border border-gray-300 font-['Inter']  text-[#282E65] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5"
                  placeholder="Price"
                  value={ele.price}
                  onChange={(e) => {
                    handleLineItemChange(e, index);
                  }}
                />
              </div>

              <div className="flex justify-end  mt-1 mx-5 p-[15px]">
                <button
                  onClick={(e) => removeLineItem(e, ele.id)}
                  className="font-['Inter'] text-[#fff] uppercase  rounded-lg"
                >
                  <CrossSvg style={{ height: "40px", width: "40px" }} />
                </button>
              </div>
            </div>
          );
        })}
      </div>

      <div className="bg-[#f8f8f8] rounded-lg mt-5  mx-5 p-[15px]">
        <h2 className="font-['Inter']  text-[#282E65] text-[18px] font-[600] my-3">
          Add Taxes
        </h2>
        <div className="flex justify-end  mt-1 mx-5 p-[15px]">
          <button
            onClick={addNewTax}
            className="bg-[#11CA9D] font-['Inter'] text-[#fff] uppercase px-3.5 py-2.5 rounded-lg"
          >
            +
          </button>
        </div>
        {taxes?.map((ele, index) => {
          return (
            <div className="flex gap-3 ">
              <div className="w-50">
                <label className="block mb-2  text-sm font-medium  font-['Inter']  text-[#282E65]">
                  Tax Name
                </label>
                <input
                  type="text"
                  id="tax_name"
                  className="bg-[#fff] border border-gray-300 font-['Inter']  text-[#282E65] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5"
                  placeholder="Tax Name"
                  name="tax_name"
                  value={ele.tax_name}
                  onChange={(e) => {
                    handleTaxChange(e, index);
                  }}
                />
              </div>

              <div className="w-50">
                <label className="block mb-2 text-sm font-medium  font-['Inter']  text-[#282E65]">
                  Tax Percentage
                </label>
                <input
                  type="number"
                  id="tax_percentage"
                  name="tax_percentage"
                  className="bg-[#fff] border border-gray-300 font-['Inter']  text-[#282E65] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5"
                  placeholder="Tax Percentage"
                  value={ele.tax_percentage}
                  onChange={(e) => {
                    handleTaxChange(e, index);
                  }}
                />
              </div>

              <div className="flex justify-end  mt-1 mx-5 p-[15px]">
                <button
                  onClick={(e) => removeTax(e, ele.id)}
                  className="font-['Inter'] text-[#fff] uppercase  rounded-lg"
                >
                  <CrossSvg style={{ height: "40px", width: "40px" }} />
                </button>
              </div>
            </div>
          );
        })}
      </div>

      <div className="bg-[#f8f8f8] rounded-lg mt-5  mx-5 p-[15px]">
        <h2 className="font-['Inter']  text-[#282E65] text-[18px] font-[600] my-3">
          Add Discounts
        </h2>
        <div className="flex justify-end  mt-1 mx-5 p-[15px]">
          <button
            onClick={addNewDiscount}
            className="bg-[#11CA9D] font-['Inter'] text-[#fff] uppercase px-3.5 py-2.5 rounded-lg"
          >
            +
          </button>
        </div>
        {discounts.map((ele, index) => {
          return (
            <div className="flex gap-3 ">
              <div className="w-50">
                <label className="block mb-2  text-sm font-medium  font-['Inter']  text-[#282E65]">
                  Discount Type
                </label>
                <select
                  type="text"
                  id="discount_type"
                  className="bg-[#fff] border border-gray-300 font-['Inter']  text-[#282E65] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5"
                  placeholder="Discount Type"
                  name="discount_type"
                  value={ele.discount_type}
                  onChange={(e) => {
                    handleDiscountChange(e, index);
                  }}
                >
                  <option value={""} selected={true} disabled={true}>
                    Select Type
                  </option>
                  <option value={"amount"}>Amount</option>
                  <option value={"percentage"}>Percentage</option>
                </select>
              </div>

              <div className="w-50">
                <label className="block mb-2  text-sm font-medium  font-['Inter']  text-[#282E65]">
                  Discount Name
                </label>
                <input
                  type="text"
                  id="discount_name"
                  className="bg-[#fff] border border-gray-300 font-['Inter']  text-[#282E65] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5"
                  placeholder="Discount Name"
                  name="discount_name"
                  value={ele.discount_name}
                  onChange={(e) => {
                    handleDiscountChange(e, index);
                  }}
                />
              </div>

              <div className="w-50">
                <label className="block mb-2 text-sm font-medium  font-['Inter']  text-[#282E65]">
                  Discount Percentage
                </label>
                <input
                  type="number"
                  id="discount_percentage"
                  name="discount_percentage"
                  className="bg-[#fff] border border-gray-300 font-['Inter']  text-[#282E65] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5"
                  placeholder="Discount Percentage"
                  value={ele.discount_percentage}
                  onChange={(e) => {
                    handleDiscountChange(e, index);
                  }}
                />
              </div>

              <div className="flex justify-end  mt-1 mx-5 p-[15px]">
                <button
                  onClick={(e) => removeDiscount(e, ele.id)}
                  className="font-['Inter'] text-[#fff] uppercase  rounded-lg"
                >
                  <CrossSvg style={{ height: "40px", width: "40px" }} />
                </button>
              </div>
            </div>
          );
        })}
      </div>

      <div className="bg-[#f8f8f8] rounded-lg mt-5  mx-5 p-[15px]">
        <h2 className="font-['Inter']  text-[#282E65] text-[18px] font-[600] my-3">
          Invoice Details
        </h2>
        {service.length > 0 && (
          <>
            <h3>Services</h3>
            {service?.map((ele, index) => {
              return (
                <div className="flex gap-3 ">
                  <div className="w-50">
                    <p>
                      {ele.serviceName} (&times; {ele.quantity})
                    </p>
                  </div>
                  <div className="w-50">
                    <p>$ {ele.amount}</p>
                  </div>
                </div>
              );
            })}
          </>
        )}

        {products?.length > 0 && (
          <>
            <h3>Products</h3>
            {products.map((ele, index) => {
              return (
                <div className="flex gap-3 ">
                  <div className="w-50">
                    <p>
                      {ele.product_name} (&times; {ele.quantity})
                    </p>
                  </div>
                  <div className="w-50">
                    <p>$ {ele.amount}</p>
                  </div>
                </div>
              );
            })}
          </>
        )}

        {lineItems?.length > 0 && (
          <>
            <h3>Items List</h3>
            {lineItems.map((ele, index) => {
              if (ele.line_item_name != "") {
                return (
                  <div className="flex gap-3 ">
                    <div className="w-50">
                      <p>
                        {ele.line_item_name} (&times; {ele.quantity})
                      </p>
                    </div>
                    <div className="w-50">
                      <p>$ {ele.price}</p>
                    </div>
                  </div>
                );
              }
            })}
          </>
        )}

        {discounts?.length > 0 && (
          <>
            <h3>Discounts</h3>

            {discounts.map((ele, index) => {
              if (ele.discount_name != "") {
                return (
                  <div className="flex gap-3 ">
                    <div className="w-50">
                      <p>{ele.discount_name}</p>
                    </div>
                    <div className="w-50">
                      <p>
                        {ele.discount_type !== "percentage" ? "$" : ""}{" "}
                        {ele.discount_percentage}{" "}
                        {ele.discount_type == "percentage" ? "%" : ""}
                      </p>
                    </div>
                  </div>
                );
              }
            })}
          </>
        )}

        {taxes?.length > 0 && (
          <>
            <h3>Taxes</h3>
            {taxes.map((ele, index) => {
              if (ele.tax_name != "") {
                return (
                  <div className="flex gap-3 ">
                    <div className="w-50">
                      <p>{ele.tax_name}</p>
                    </div>
                    <div className="w-50">
                      <p>{ele.tax_percentage} %</p>
                    </div>
                  </div>
                );
              }
            })}
          </>
        )}
        <br />
        <hr />
        <div className="flex gap-3 ">
          <div className="w-50">
            <p>Total</p>
          </div>

          <div className="w-50">
            <p>$ {invoiceTotal}</p>
          </div>
        </div>
      </div>

      <div className="flex justify-end  mt-1 mx-5 p-[15px]">
        <button
          onClick={addInvoiceHandle}
          className="bg-[#11CA9D] font-['Inter'] text-[#fff] uppercase px-3.5 py-2.5 rounded-lg"
        >
          Save invoice
        </button>
      </div>
    </>
  );
}
