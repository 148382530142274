import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import {
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import PetPlaceholder from "../../../PetPlaceholder"; // eslint-disable-line
import "./index.scss";
import { MdPets } from "react-icons/md";
import { useFormik } from "formik";
import {
  getPetDetailsById,
  getPetDetailsByPetId,
  updatePetdetails,
} from "../../../../context/actions/patient-module/patient/pet";
import { useParams } from "react-router-dom";
import ProfileAllergy from "../ProfileAllergy";
import ProfileTags from "../ProfileTags";
import {
  getAllBreeds,
  getAllSpecies,
} from "../../../../context/actions/app-content";
import ProfileTagsEdit from "../ProfileTags/index-edit";
import ProfileAllergyEdit from "../ProfileAllergy/index-edit";
import { getActiveAppointmentsByPatientId } from "../../../../context/actions/appointment";
import { defaultDischargePatientFormData } from "../../../../context/reducers/dischargePatientReducer";
import { AddNewDischargePatientForm } from "../../../../context/actions/patient-module/discharge-patient-form";
import PatientListPanel from "../../../Calendar/PatientListPanel";
import { patientListPanelAction } from "../../../../context/actions/patientListPanelAction";
import { BsChevronRight } from "react-icons/bs";

const PatientHead = ({ close, showHealthRightCanvas }) => {
  const petDetails = useSelector((state) => state.petReducer.petDetailsSide);
  const { data: appointmentDetails } = useSelector(
    (state) => state.appointmentDetailPanelReducer
  );
  console.log("petDetails", petDetails);
  const { species, breeds } = useSelector((state) => state.appContentReducer);
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [now, setNow] = useState(moment());
  const [activeAppointments, setActiveAppointments] = useState([]);
  const [show, setShow] = useState(false);

  const patientListPanelState = useSelector(
    (state) => state.patientListPanelReducer.isPatientListPanel
  );

  const openPatientPanel = () => {
    dispatch(patientListPanelAction(!patientListPanelState));
  };

  const { user } = useSelector((state) => state.user);

  const { clinic_details } = useSelector((state) => state.clinicReducer);

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  const getPetDetails = (id) => { 

    dispatch(getPetDetailsByPetId(id, {}, () => {
     dispatch(patientListPanelAction(false))
    }))
  }

  const [state, setState] = useState({
    dob: moment(petDetails?.dob).format("YYYY-MM-DD"),
    rabies_number: petDetails?.rabies_number,
    license_number: petDetails?.license_number,
    microchip_number: petDetails?.microchip_number,
    cpr: petDetails?.cpr,
    gender: petDetails?.gender,
    breed: petDetails?.breed?.breed,
    color: petDetails?.color,
    allergy_tag: petDetails?.petCareInfo?.allergies
      ? petDetails?.petCareInfo?.allergies?.map((item) => item?.reaction)
      : [],
    behaviour_tag: petDetails?.petCareInfo?.behaviour_tags?.map(
      (item) => item?.tag
    ),
    id: petDetails?._id,
  });

  useEffect(() => {

    if(petDetails?._id){
    setState({
      dob: moment(petDetails?.dob).format("YYYY-MM-DD"),
      rabies_number: petDetails?.rabies_number,
      license_number: petDetails?.license_number,
      microchip_number: petDetails?.microchip_number,
      cpr: petDetails?.cpr,
      gender: petDetails?.gender,
      breed: petDetails?.breed?._id,
      color: petDetails?.color,
      allergy_tag: petDetails?.petCareInfo?.allergies?.map(
        (item) => item?.reaction
      ),
      behaviour_tag: petDetails?.petCareInfo?.behaviour_tags?.map(
        (item) => item?.tag
      ),
      id: petDetails?._id,
    });

    formik.setValues({
      dob: moment(petDetails?.dob).format("YYYY-MM-DD"),
      rabies_number: petDetails?.rabies_number,
      license_number: petDetails?.license_number,
      microchip_number: petDetails?.microchip_number,
      cpr: petDetails?.cpr,
      gender: petDetails?.gender,
      breed: petDetails?.breed?._id,
      color: petDetails?.color,
      allergy_tag: petDetails?.petCareInfo?.allergies?.map(
        (item) => item?.reaction
      ),
      behaviour_tag: petDetails?.petCareInfo?.behaviour_tags?.map(
        (item) => item?.tag
      ),
      id: petDetails?._id,
    });

   

    handleGetActiveAppointments();
    }
  }, [petDetails]);
  const handleRedirection = () => {
    if (location.pathname.includes("add-new-appointment")) {
      history.push("/appointments");
    }
  };

  const handleGetActiveAppointments = () => {
    dispatch(
      getActiveAppointmentsByPatientId(petDetails?._id, (res) => {
        if (res) {
          setActiveAppointments(res);
        }
      })
    );
  };

  const handleViewProfile = () => {
    history.push(`/patient-detail/${petDetails?._id}`);
    close();
  };

  useEffect(() => {
    if(petDetails?._id){
      handleGetActiveAppointments();
    }
  }, [appointmentDetails]);

  useEffect(() => {

    if(petDetails?.species?._id){
    dispatch(
      getAllSpecies({}, (res) => {
        if (res?.status) {
          dispatch(getAllBreeds(petDetails?.species?._id, () => {}));
        }
      })
    );
    handleGetActiveAppointments();}

    setInterval(() => {
      setNow(moment());
    }, 1000);
  }, [petDetails]);
  function formatDate(date) {
    const day = date.getDate().toString().padStart(2, "0"); // Get day and pad with leading zero if necessary
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get month (months are 0-indexed) and pad with leading zero if necessary
    const year = date.getFullYear().toString(); // Get full year

    return `${month}/${day}/${year}`;
  }
  const formik = useFormik({
    initialValues: state,

    onSubmit: (values, { resetForm }) => {
      console.log("values", formatDate(new Date(values.dob)));
      for (var propName in values) {
        if (
          values[propName] === null ||
          values[propName] === undefined ||
          values[propName] === ""
        ) {
          delete values[propName];
        }
        if (propName === "dob") {
          values[propName] = moment(values[propName]).toDate();
        }
      }
      let payload = {
        ...values,
        //dob: new Date(values.dob).toISOString(),
      };
      const res = dispatch(
        updatePetdetails(payload, {}, (response) => {
          console.log("response=====>", response);
          setShow(false);
        })
      );
    },
  });

  const createDischargePatientForm = () => {
    // console.log("defaultDischargePatientFormData", defaultDischargePatientFormData)
    if (
      defaultDischargePatientFormData &&
      clinic_details &&
      appointmentDetails
    ) {
      dispatch(
        AddNewDischargePatientForm(
          {
            ...defaultDischargePatientFormData,
            order_for_discharge:
              defaultDischargePatientFormData.order_for_discharge.map((ele) => {
                delete ele._id;
                return ele;
              }),
            clinic_id: clinic_details._id,
            appointment_id: appointmentDetails._id,
            pet_id: petDetails._id,
            added_by: user._id,
          },
          (response) => {
            if (response?.status) {
              history.push("/dischargePatient", {
                pet_id: petDetails._id,
                appointmentDetails: appointmentDetails,
              });
            } else {
            }
          }
        )
      );
    }
  };
  let totalAmount = 0;
  petDetails?.invoiceDetails?.forEach((elem) => {
    totalAmount += elem?.totalAmount || 0;
  });
  // console.log("appointmentDetails", appointmentDetails)
  // useEffect(() => {

  //   dispatch(getPetDetailsById(petDetails.petId, {}, () => {}));

  // }, []);
  // console.log(formatDate(new Date("2021-01-14")));
  return (
    <>
      <div className="profile-head">
        <div className="d-flex flex-row gap-[15px]">
          <div className="patient-profile-cell d-inline-flex align-items-center gap-[15px]">
            <div className="pic  position-relative">
              {petDetails?.pet_image ? (
                <img
                  className="img-fluid"
                  src={petDetails?.pet_image}
                  alt="Pet"
                />
              ) : (
                <PetPlaceholder />
              )}
              {/* <PetPlaceholder /> */}
              {/* <span className="badge-icon position-absolute">
							<img className="img-fluid" src={require('../../../assets/img/vetRecorEMR/pet-badge-icon-yellow.svg')} alt="Badge" />
						</span> */}
              {/*petDetails?.wellness_plan_id &&
              <span className="badge-icon position-absolute">
                <img className="img-fluid" src={require('../../../assets/img/vetRecorEMR/pet-badge-icon-yellow.svg')} alt="Badge" /> 
                <div className="bg-[#DD9323] inline w-[20px] p-[2px] absolute rounded-[5px] top-[7px] left-[-26px]">
                  <MdPets color="#fff" size={15} />
                </div>
          </span>*/}
            </div>
            <div className="txt">
              <h2>
                {petDetails?.pet_name}
              </h2>
              <h3>{petDetails?.vrn}</h3>
              <h4 className="active">
                {petDetails?.active ? "Active" : "Inactive"}
              </h4>
            </div>
          </div>
          {permissions?.edit_patients_details && (
            <div className="d-flex justify-content-center align-items-center flex-col txt">
              <div onClick={openPatientPanel} className="change">
                Change
              </div>
            </div>
          )}
        </div>
        {/*
        <div className="profile-btns d-flex mb-2 pb-1">
          <Button color="link" className="flex-fill">Message</Button>
          <Button color="link" className="flex-fill">Share Profile</Button>
        </div>*/}

        {petDetails && 
        <>

        <div className="profile-btns d-flex mb-2 pb-1">
          {/*<Button color="link" className="flex-fill">Share Profile</Button>*/}
          <Button
            color="link"
            onClick={handleViewProfile}
            className="flex-fill"
          >
            View Profile
          </Button>
        </div>

        <div className="button-container">
          <button
            type="button"
            class="health-button-right-sidebar text-12-600"
            onClick={showHealthRightCanvas}
          >
            HEALTH SNAPSHOT
          </button>
        </div>
        </>}

        {activeAppointments?.length > 0 ? (
          <div className="discharge-card mt-2 p-3 text-center">
            <h4 className="pb-2">
              Time in Clinic :{" "}
              <span minutes>
                {moment
                  .duration(
                    now.diff(moment(activeAppointments[0]?.appointment_start))
                  )
                  .format("D [days, ]H [hours, ]m [minutes]")}
              </span>
            </h4>
            <button
              type="button"
              class="btn btn-primary w-100"
              onClick={() => {
                createDischargePatientForm();
                // history.push("/dischargePatient", {
                //   pet_id: petDetails._id,
                //   appointmentDetails: activeAppointments[0],
                // });
              }}
            >
              Discharge
            </button>
          </div>
        ) : (
          <></>
        )}
        {totalAmount > 0 && (
          <div className="mt-2 p-2 amount-card">
            <div className="fb-center">
              <div>
                <span className="amount-value">${totalAmount.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}</span>
                <p className="amount-text pt-1">Outstanding Invoices</p>
              </div>

              <BsChevronRight color="#ffffff" size={16} />
            </div>
          </div>
        )}
        {/*
                <div className="outstanding_invoice position-relative mt-2">
                    <h4>$52,800</h4>
                    <h3 className="mb-0">Outstanding Invoices</h3>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <mask id="mask0_6529_110610" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
                            <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_6529_110610)">
                            <path d="M8.87344 17.6234C8.72344 17.4734 8.64844 17.2984 8.64844 17.0984C8.64844 16.8984 8.72344 16.7234 8.87344 16.5734L12.9484 12.4984L8.87344 8.42344C8.72344 8.27344 8.64844 8.09844 8.64844 7.89844C8.64844 7.69844 8.72344 7.52344 8.87344 7.37344C9.02344 7.22344 9.19844 7.14844 9.39844 7.14844C9.59844 7.14844 9.77344 7.22344 9.92344 7.37344L14.4234 11.8734C14.5068 11.9568 14.5694 12.0524 14.6114 12.1604C14.6528 12.2691 14.6734 12.3818 14.6734 12.4984C14.6734 12.6151 14.6528 12.7274 14.6114 12.8354C14.5694 12.9441 14.5068 13.0401 14.4234 13.1234L9.92344 17.6234C9.77344 17.7734 9.59844 17.8484 9.39844 17.8484C9.19844 17.8484 9.02344 17.7734 8.87344 17.6234Z" fill="white" />
                        </g>
                    </svg>
                        </div>*/}
      </div>
      <Modal className="edit-modal" isOpen={show} toggle={() => setShow(false)}>
        <ModalHeader>Edit Details</ModalHeader>

        <ModalBody>
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label for="exampleEmail">Date of Birth</Label>
              <Input
                type="date"
                name="dob"
                id="dob"
                placeholder="Enter Date of Birth"
                label="Date of Birth"
                className="mb-0"
                value={formik.values.dob}
                onChange={formik.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword">Rabies tag no. </Label>
              <Input
                type="text"
                name="rabies_number"
                id="rabies_number"
                placeholder="Enter Rabies Tag Number"
                isRequired={false}
                className="mb-0"
                labelVisible={false}
                value={formik?.values?.rabies_number}
                onChange={formik.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">License no.</Label>
              <Input
                type="text"
                name="license_number"
                id="license_number"
                placeholder="Enter License Number"
                isRequired={false}
                className="mb-0"
                labelVisible={false}
                value={formik?.values?.license_number}
                onChange={formik.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword">Microchip no. </Label>
              <Input
                type="text"
                name="microchip_number"
                id="microchip_number"
                placeholder="Enter Microchip Number"
                isRequired={false}
                className="mb-0"
                labelVisible={false}
                value={formik?.values?.microchip_number}
                onChange={formik.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">CPR</Label>
              <Input
                type="text"
                name="cpr"
                id="cpr"
                placeholder="Enter CPR Status"
                isRequired={false}
                className="mb-0"
                labelVisible={false}
                value={formik?.values?.cpr}
                onChange={formik.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword">Identity </Label>
              <div className="border-box px-3 py-3">
                <FormGroup>
                  <Label for="exampleSelect">Select Gender</Label>
                  <Input
                    type="select"
                    name="gender"
                    onChange={formik.handleChange}
                    value={formik.values.gender}
                    id="exampleSelect"
                  >
                    <option value="male">Male</option>
                    <option value="neutered-male">Neutered Male</option>
                    <option value="female">Female</option>
                    <option value="spayed-female">Spayed Female</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="exampleEmail">Breed</Label>
                  <Input
                    type="select"
                    name="breed"
                    id="breed"
                    required
                    value={formik.values.breed}
                    onChange={formik.handleChange}
                  >
                    <option value="">Select a breed</option>

                    {/* Map through breeds and render breed options */}
                    {(breeds || []).map((item) => (
                      <option
                        key={item._id}
                        value={item._id}
                        style={{ textTransform: "capitalize" }}
                      >
                        {item.breed}
                      </option>
                    ))}
                  </Input>

                  {/* <Input
                    type="text"
                    name="breed"
                    id="breed"
                    placeholder="Enter breed Status"
                    isRequired={false}
                    className="mb-0"
                    labelVisible={false}
                    value={formik?.values?.breed}
                    onChange={formik.handleChange}
                  /> */}
                </FormGroup>

                <FormGroup>
                  <Label for="exampleEmail">Color</Label>
                  <Input
                    type="text"
                    name="color"
                    id="color"
                    placeholder=""
                    className="mb-0"
                    value={formik?.values?.color}
                    onChange={formik.handleChange}
                  />
                </FormGroup>
              </div>

              <ProfileAllergyEdit
                setAllergies={(val) => formik.setFieldValue("allergy_tag", val)}
                allergies={formik?.values?.allergy_tag}
                showRemoveIcon={true}
              />

              <ProfileTagsEdit
                setBehaviourTags={(val) =>
                  formik.setFieldValue("behaviour_tag", val)
                }
                behaviour_tags={formik?.values?.behaviour_tag}
                showRemoveIcon={true}
              />
            </FormGroup>

            <Button type="submit">Update</Button>
          </Form>
        </ModalBody>
      </Modal>
      <div
        className={`patient-panel-overlay position-fixed ${
          patientListPanelState ? "active" : ""
        }`}
        onClick={() => dispatch(patientListPanelAction(false))}
      ></div>
      <PatientListPanel
        className={`${patientListPanelState ? "active" : ""}`}
        callable={getPetDetails}
        clinicId={clinic_details?._id}
      />
    </>
  );
};

export default PatientHead;
