import React from "react"
import { Spinner as ReactstrapSpinner } from "reactstrap"
function Spinner({ height, width, ...props }) {
    return <>
        <div style={{ height: height || 0, width: width || "auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <ReactstrapSpinner {...props} />
        </div>
    </>;
}

export default Spinner