import React, { useState } from "react";
import UserThumbnailListWrapper from "./UserThumbnailListWrapper";
import UserProfileHead2 from "../../../../shared/UserProfileHead2";
import StaffProfileTabBlock from "./StaffProfileTabBlock";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getStaffManagementUserData,
} from "../../../../context/actions/staffManagement";
import { useEffect } from "react";
import LoaderUI from "../../../../shared/loader";

const StaffDetail = () => {
  const params = useParams();
  const userDetails = useSelector((state) => state.user.user_details);
  const dispatch = useDispatch();
  const [loader, showLoader] = useState(false);
  const [staffMemberDetails, showStaffMemberDetails] = useState(false);
  const [staffOtherMembers, showStaffOtherMembers] = useState(false);

  const handleStaffManagementUserDetails = () => {
    showLoader(true);
    dispatch(
      getStaffManagementUserData(params.staffManagementId, {}, (result) => {
        if (result && !result.errors) {
          showStaffMemberDetails(result.staff_data);
          // console.log(staffMemberDetails)
          showStaffOtherMembers(result.other_users);
          showLoader(false);
        } else {
          showLoader(false);
          showStaffMemberDetails(false);
          showStaffOtherMembers(false);
        }
      })
    );
  };

  useEffect(() => {
    handleStaffManagementUserDetails();
  }, [params.staffManagementId]);

  return (
    <>
      {loader && <LoaderUI overlay />}
      <div className="page-wrapper position-relative d-flex flex-column flex-fill overflow-auto">
        <div className="page-content no-header d-flex flex-column flex-fill overflow-auto">
          <UserThumbnailListWrapper
            className="responsive"
            staffOtherMembers={staffOtherMembers}
          />
          <div className="page-content-inner d-flex flex-column flex-fill overflow-auto">
            <UserProfileHead2
              className="responsive"
              userDetails={staffMemberDetails?.user}
            />
            <StaffProfileTabBlock staffMemberDetails={staffMemberDetails} />
          </div>
        </div>
      </div>
    </>
  );
};

export default StaffDetail;
