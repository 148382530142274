import * as Yup from "yup";
/**
 * @description declaration form schema for client validations
 */
export const templateSchema = Yup.object().shape({
    title: Yup.string().required("Title field is required"),
    fields: Yup.array()
});

/**
 * @description params intilaization 
 */

export const templateInit = {
   title: '',
   fields: ['']
}