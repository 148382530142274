import { Button, Table } from "reactstrap";
import SearchBlock2 from "../../../../../../../shared/SearchBlock2";
import NodataFoundBlock from "../../../../../../../shared/NoDataFoundBlock";
import { useDispatch, useSelector } from "react-redux";
import {
  getAndSearchProductList,
  getAndSearchProductListByCategory,
} from "../../../../../../../context/actions/inventory";
import { useEffect } from "react";

const MedicationSelection = ({ selectMedicationFn, closePanel }) => {
  const { productList } = useSelector((state) => state.inventoryReducer);
  const dispatch = useDispatch();

  const handleGetAndSearchProductList = (e) => {
    let text = e?.target?.value;
    console.log('search text', text)
    dispatch(
      getAndSearchProductListByCategory
      ({ searchText: text, categories: ["medications"] },
      (response) => {
         
      })
    );
  };

  useEffect(() => {
    handleGetAndSearchProductList();
  }, []);

  return (
    <>
      <div className="head d-flex flex-column align-items-center">
        <div className="d-flex flex-row w-100">
          <h2>Select Products/Services</h2>
          <Button
            color="link"
            className="ms-auto panel-close-btn"
            onClick={closePanel}
          >
            <span className="material-symbols-outlined">close</span>
          </Button>
        </div>
        <div className="d-flex flex-row w-100">
          <SearchBlock2
            placeholder="Search"
            onChange={handleGetAndSearchProductList}
          />
        </div>
      </div>

      <div className="inner overflow-auto">
        <div className="table-listing-blc overflow-auto">
          <Table responsive className="no-data-table">
            <thead>
              <tr>
                <th>Component</th>
                <th>Details</th>
                <th>Category</th>
                <th style={{ width: "100px" }}>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {productList && productList?.length > 0 ? (
                productList?.map((item, i) => {
                  return (
                    <tr key={i}>
                      {item?.product_name ? (
                        <td>{`${item?.product_name} (${item?.sku})`}</td>
                      ) : (
                        <td>{item?.service_name}</td>
                      )}
                      <td>{item?.description}</td>
                      <td>{item?.category}</td>
                      <td>
                        <Button
                          color="link"
                          onClick={() => {
                            selectMedicationFn({
                              product_id: item?._id,
                              product_name: item?.product_name,
                              product_description: item?.description,
                            });
                          }}
                        >
                          Select
                        </Button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="no-data-rw">
                  <td colSpan="5">
                    <NodataFoundBlock />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default MedicationSelection;
