import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Model from "shared/3dModel";
import { BsFillPinAngleFill } from "react-icons/bs";
import {
  getDrainsTubesByPetId,
  addNewDrainsTubes,
  addNewDrainsTubesDetails,
  deleteParentDrains,
  deleteDrainsDetails,
} from "context/actions/patient-module/flowsheets/DrainsTubes";

import { BsFillImageFill } from "react-icons/bs";
import NodataFoundBlock from "../../../../../../shared/NoDataFoundBlock";
import archive from "assets/img/archive.png";
import Accordion from "react-bootstrap/Accordion";
import moment from "moment";
import DrainsTubesCell from "./DrainsTubesCell";
import DeletePopup from "../DeletePopup";
import DatePopover from "../DatePopover";
// import FilterComponent from "../FilterComponent";
import FilterOptions from "../FilterOptions";
import "./DrainsTubes.scss";
import Spinner from "shared/Spinner";

const drainsTubesKeys = [
  {
    title: "Type",
    key: "type",
    dependent: "",
    options: [
      "NG/OG Tube",
      "Feeding Tube",
      "Chest Tube",
      "Chest Tube Output",
      "Urinary Catheter",
    ],
  },
  {
    title: "Placement Date & Time",
    key: "placement_date",
    dependent: "",
  },
  {
    title: "Catheter Type",
    key: "catheter_type",
    dependent: "Urinary Catheter",
  },
  {
    title: "Catheter Size",
    key: "catheter_size",
    dependent: "Urinary Catheter",
  },
  {
    title: "Indication",
    key: "indication",
    dependent: "Urinary Catheter",
  },
  {
    title: "Urine Output (mL)",
    key: "urine_output",
    dependent: "Urinary Catheter",
  },
  {
    title: "Suction Setting/Drainage Method",
    key: "suction_setting",
    dependent: "NG/OG Tube",
  },
  {
    title: "Drainage Color",
    key: "drainage_color",
    dependent: "NG/OG Tube",
  },
  {
    title: "Drainage Consistency",
    key: "drainage_consistency",
    dependent: "NG/OG Tube",
  },
  {
    title: "Clamp Status/Tolerance",
    key: "clamp_status",
    dependent: "NG/OG Tube",
  },
  {
    title: "Flush Irrigation",
    key: "flush_irrigation",
    dependent: "NG/OG Tube",
  },
  {
    title: "External Flush Intake (mL)",
    key: "external_flush_intake",
    dependent: "NG/OG Tube",
  },
  {
    title: "Output (mL)",
    key: "output",
    dependent: "NG/OG Tube",
  },
  {
    title: "Tube Type",
    key: "tube_type",
    dependent: "Feeding Tube",
  },
  {
    title: "Interventions Prior To Feeding",
    key: "interventions",
    dependent: "Feeding Tube",
  },
  {
    title: "Residual Refed (mL)",
    key: "residual_refed",
    dependent: "Feeding Tube",
  },
  {
    title: "Residual Discarded (mL)",
    key: "residual_discarded",
    dependent: "Feeding Tube",
  },
  {
    title: "Feeding Type",
    key: "feeding_type",
    dependent: "Feeding Tube",
  },
  {
    title: "Feeding Tube Formula",
    key: "feeding_formula",
    dependent: "Feeding Tube",
  },
  {
    title: "Feeding Action",
    key: "feeding_action",
    dependent: "Feeding Tube",
  },
  {
    title: "Feeding Tube Rate",
    key: "feeding_rate",
    dependent: "Feeding Tube",
  },
  {
    title: "Feeding Tube Intake (mL)",
    key: "feeding_intake",
    dependent: "Feeding Tube",
  },
  {
    title: "Tube Flush Intake (mL)",
    key: "tube_flush_intake",
    dependent: "Feeding Tube",
  },
  {
    title: "Chest Tube WDL",
    key: "wdl",
    dependent: "Chest Tube",
  },
  {
    title: "Device/Suction",
    key: "device_suction",
    dependent: "Chest Tube",
  },
  {
    title: "Device Care",
    key: "device_care",
    dependent: "Chest Tube",
  },
  {
    title: "Air Leak/Fluctuation",
    key: "air_leak",
    dependent: "Chest Tube",
  },
  {
    title: "Air Leak/Fluctuation (Retired)",
    key: "air_leak_retired",
    dependent: "Chest Tube",
  },
  {
    title: "Drainage Description",
    key: "drainage_description",
    dependent: "Chest Tube",
  },
  {
    title: "Dressing Status",
    key: "dressing_status",
    dependent: "Chest Tube",
  },
  {
    title: "Dressing Care",
    key: "dressing_care",
    dependent: "Chest Tube",
  },
  {
    title: "Site Assessment",
    key: "site_assessment",
    dependent: "Chest Tube",
  },
  {
    title: "Subcutaneous Emphysema",
    key: "subcutaneous_emphysema",
    dependent: "Chest Tube",
  },
  {
    title: "Securement",
    key: "securement",
    dependent: "Chest Tube",
  },
  {
    title: "Safety",
    key: "safety",
    dependent: "Chest Tube",
  },
  {
    title: "Connected to Chest Drainage",
    key: "connected_to_chest_drainage",
    dependent: "Chest Tube",
  },
  {
    title: "Chest Tube Charges ($)",
    key: "chest_tube_charges",
    dependent: "Chest Tube",
  },
  {
    title: "#1 Drainage System",
    key: "drainage_system_1",
    dependent: "Chest Tube Output",
  },
  {
    title: "#2 Drainage System",
    key: "drainage_system_2",
    dependent: "Chest Tube Output",
  },
  {
    title: "#3 Drainage System",
    key: "drainage_system_3",
    dependent: "Chest Tube Output",
  },
  {
    title: "#4 Drainage System",
    key: "drainage_system_4",
    dependent: "Chest Tube Output",
  },
  {
    title: "#5 Drainage System",
    key: "drainage_system_5",
    dependent: "Chest Tube Output",
  },
  {
    title: "#6 Drainage System",
    key: "drainage_system_6",
    dependent: "Chest Tube Output",
  },
  {
    title: "#7 Drainage System",
    key: "drainage_system_7",
    dependent: "Chest Tube Output",
  },
  {
    title: "#8 Drainage System",
    key: "drainage_system_8",
    dependent: "Chest Tube Output",
  },
  {
    title: "Removed Date & Time",
    key: "removed_date",
    dependent: "",
  },
  {
    title: "Notes",
    key: "notes",
    dependent: "",
  },
  {
    title: "Status",
    key: "active",
    dependent: "",
  },
  {
    title: "Photo",
    key: "drain_tube_document",
    dependent: "",
  },
];

const DrainsTubes = ({ petId, setDisplayURL }) => {
  const dispatch = useDispatch();
  const { petDetails } = useSelector((state) => state.petReducer);
  const userDetails = useSelector((state) => state.user.user_details);
  const [selectingNewPoint, setSelectingNewPoint] = useState(false);
  const [point, setPoint] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [pageData, setPageData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [expandList, setExpandList] = useState([]);
  const [optionType, setOptionType] = useState("2");
  const [filterData, setFilterData] = useState({
    searchText: "",
    selectedDates: [],
    date: "",
  });

  const handleExpand = (gIndex) => {
    let list = expandList;
    if (expandList.includes(gIndex)) {
      list = list.filter((o) => o !== gIndex);
    } else {
      list = [...list, gIndex];
    }
    setExpandList(list);
  };
  const handleDrainDelete = async (id) => {
    setDeleteData(null);
    const res = await dispatch(deleteParentDrains(id));
    if (res?.status) {
      setTimeout(() => {
        fetchPageData(filterData);
      }, 1000);
    }
  };

  const handleDrainDetailsDelete = async (data) => {
    setDeleteData(null);
    const res = await dispatch(deleteDrainsDetails(data));
    if (res?.status) {
      setTimeout(() => {
        fetchPageData(filterData);
      }, 1000);
    }
  };

  const handleDelete = async () => {
    if (deleteData?.type === "1") {
      handleDrainDelete(deleteData?.locationId);
    } else {
      handleDrainDetailsDelete({
        locationId: deleteData?.locationId,
        locationDetailId: deleteData.locationDetailId,
      });
    }
  };

  const handleAddPoint = async () => {
    const res = await dispatch(
      addNewDrainsTubes({
        pet_id: petId,
        created_by: userDetails?._id,
        coordinate: point,
      })
    );
    if (res?.status) {
      handleExpand(pageData?.length);
      handleAddEditDetails({
        locationId: res?.response_data?._id,
        createdAt: moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      });
      setSelectingNewPoint(false);
      setPoint(null);
    }
  };

  const handleAddEditDetails = async (payload) => {
    const formData = new FormData();
    for (const key in payload) {
      formData?.append(key, payload[key]);
    }
    const res = await dispatch(addNewDrainsTubesDetails(formData));
    if (res?.status) {
      fetchPageData(filterData);
    }
    // setEditData(null);
  };

  const handleResponse = (resData) => {
    const newData = [];
    resData.forEach((o) => {
      const headerData = o?.locationDetails?.map((e) => {
        const isAnyVal =
          e?.type || e?.placement_date || e?.removed_date || e?.notes
            ? true
            : false;
        return {
          id: e?.locationDetailId,
          date: e?.createdAt || "",
          isValueExist: isAnyVal,
        };
      });
      let bodyData = [];
      if (o?.locationDetails?.length > 0) {
        const findType = o?.locationDetails?.find((i) => i?.type)?.type || "";
        bodyData = drainsTubesKeys?.map((e) => {
          const data = o?.locationDetails?.map((elm) => {
            return {
              ...elm,
              value: elm?.[e?.key] || "",
            };
          });
          let isRowShow = true;
          if (
            (e?.dependent && findType && e?.dependent !== findType) ||
            (e?.dependent && !findType)
          ) {
            isRowShow = false;
          }
          return { ...e, data: data, isRowShow: isRowShow };
        });
      }
      let newObject = {
        id: o?._id,
        coordinate: o?.coordinate || [],
        value: o?.locationDetails?.find((o) => o.type)?.type || "",
        header: headerData || [],
        data: bodyData?.filter((o) => o.isRowShow),
        deleted: o?.deleted,
      };
      newData.push(newObject);
    });
    setPageData(newData);
  };

  const fetchPageData = async (fData) => {
    setIsLoading(true);
    const res = await dispatch(
      getDrainsTubesByPetId({
        pet_id: petId,
        // filterDates: fData?.selectedDates,
      })
    );
    handleResponse(res?.response_data || []);
    setIsLoading(false);
  };

  useEffect(() => {
    if (filterData?.date) {
      fetchPageData(filterData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    let currentDate = moment().format("DD-MM-YYYY");
    let newData = {
      searchText: "",
      selectedDates: [currentDate],
      date: currentDate,
    };
    setFilterData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let displayData = pageData?.filter(
    (o) =>
      optionType === "1" ||
      (optionType === "2" && !o.deleted) ||
      (optionType === "3" && o.deleted)
  );
  displayData = displayData?.map((elm) => {
    return {
      ...elm,
      data: elm?.data?.filter((o) =>
        o.title?.toLowerCase().includes(filterData?.searchText?.toLowerCase())
      ),
    };
  });

  if (displayData?.every((o) => o?.data?.length === 0)) {
    displayData = [];
  }

  return (
    <div id="drains-tubes-container">
      {deleteData?.type && (
        <DeletePopup
          handleDelete={handleDelete}
          onHide={() => {
            setDeleteData(null);
          }}
        />
      )}
     
      <div
        style={{
          position: "relative",
          backgroundColor: "#EBECF0",
          width: "100%",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          marginTop: "5px",
        }}
      >
        <div style={{ width: "413px", height: "300px" }}>
          <Model
            selectPoint={handleExpand}
            points={displayData?.map((o) => o.coordinate)}
            isNewPoint={selectingNewPoint}
            setPoint={setPoint}
            species={petDetails?.species?.species_name}
          />
        </div>

        <div
          className="scroll-control"
          style={{
            position: "absolute",
            right: "34px",
            bottom: "74px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <img
            style={{ width: "80px", height: "80px" }}
            src={require("../../../../../../assets/img/vetRecorEMR/modelScroll.png")}
            alt="model"
          />
          <p
            style={{
              color: "#788C9F",
              maxWidth: "161px",
              textAlign: "center",
              fontFamily: "Inter",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "150%",
            }}
          >
            Drag to rotate the image on 360 degrees
          </p>
        </div>
      </div>
      <div className="drains-tubes-body">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="fa-center gap-3">
            <div className="head-title">Drains/Tubes</div>
            <FilterOptions
              firstOption="All Locations"
              optionType={optionType}
              onClick={(e) => {
                setOptionType(e);
              }}
            />
          </div>

          {selectingNewPoint ? (
            <div className="new-pin">
              <i
                style={{ height: "20px", width: "20px" }}
                onClick={() => {
                  setSelectingNewPoint(false);
                }}
                className="icomoon-cross-btn"
              ></i>

              <i
                style={{ height: "20px", width: "20px" }}
                onClick={() => {
                  handleAddPoint();
                }}
                className="icomoon-check-btn"
              ></i>
            </div>
          ) : (
            <div
              className="d-flex align-items-center gap-2 pin-span"
              onClick={() => {
                setSelectingNewPoint(true);
              }}
            >
              <span>Pin New Location</span>
              <span className="pin">
                <BsFillPinAngleFill />
              </span>
            </div>
          )}
        </div>
        {isLoading ? (
          <div className="mx-auto">
            <Spinner height={150} />
          </div>
        ) : (
          <>
            {displayData?.length === 0 ? (
              <NodataFoundBlock type={'medical history'} />
            ) : (
              <Accordion activeKey={expandList} onSelect={handleExpand}>
                {displayData?.map((elm, index) => {
                  const { id, value, header, data, deleted } = elm;
                  return (
                    <Accordion.Item eventKey={index} key={index}>
                      <Accordion.Header>
                        <div className="d-flex fa-center justify-content-between w-100">
                          <div className="location-detail-block">
                            <div className="location-number">{index + 1}</div>
                            <div className="location-title">{value}</div>
                          </div>

                      {!deleted && (
                        <div className="d-flex align-items-center gap-2">


                          {data?.filter(elem => elem?.key === 'drain_tube_document')?.[0]?.data?.filter(i => i?.value !== "")?.length > 0 &&

                          <BsFillImageFill
                            size={20}
                            color="#a9a9a9"
                          />
                          }
                          <div
                            className="h-19 d-flex"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setDeleteData({
                                locationId: id,
                                locationDetailId: "",
                                type: "1",
                              });
                            }}
                          >
                            <img src={archive} alt="archive" />
                          </div>
                          <div
                            className="d-flex"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleAddEditDetails({
                                locationId: id,
                                createdAt: moment().format(
                                  "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                ),
                              });
                            }}
                          >
                            <span className="icomoon-plus-btn" />
                          </div>
                        </div>
                      )}
                    </div>
                  </Accordion.Header>
                  {header.length > 0 && (
                    <Accordion.Body>
                      <div className="head-row">
                        <div>Test Name</div>
                        {header?.map((dateElem, dateIndex) => {
                          const { date, isValueExist } = dateElem;
                          const cellDate = moment(
                            date,
                            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                          ).format("MM/DD/YYYY");
                          const cellTime = moment(
                            date,
                            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                          ).format("hh:mmA");
                          const isDateEdit =
                            editData?.locationDetailId === dateElem?.id &&
                            editData?.fieldId === "createdAt";
                          return (
                            <div
                              key={dateIndex}
                              className="d-flex gap-2 position-relative"
                            >
                              <span
                                className="cursor-pointer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  if (!deleted) {
                                    setEditData({
                                      locationId: id,
                                      locationDetailId: dateElem?.id,
                                      fieldId: "createdAt",
                                      fieldValue: moment(
                                        date,
                                        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                      ).format("YYYY-MM-DDTHH:mm"),
                                    });
                                  }
                                }}
                              >
                                {cellDate}
                                <br />
                                {cellTime}
                              </span>
                              {isDateEdit && (
                                <DatePopover
                                  defaultValue={date}
                                  value={editData?.fieldValue}
                                  setEditData={setEditData}
                                  onChange={(e) => {
                                    setEditData((prev) => {
                                      return {
                                        ...prev,
                                        fieldValue: e.target.value,
                                      };
                                    });
                                  }}
                                  handleSave={() => {
                                    handleAddEditDetails({
                                      locationId: editData.locationId,
                                      locationDetailId:
                                        editData.locationDetailId,
                                      createdAt: moment(
                                        editData.fieldValue,
                                        "YYYY-MM-DDTHH:mm"
                                      ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                                    });
                                  }}
                                />
                              )}
                              {!isValueExist && !deleted && (
                                <span
                                  className="remove_btn"
                                  onClick={() => {
                                    setDeleteData({
                                      locationId: id,
                                      locationDetailId: dateElem?.id,
                                      type: "2",
                                    });
                                  }}
                                >
                                  -
                                </span>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      {data?.map((rowElem, rowIndex) => {
                        return (
                          <div className="data-row" key={rowIndex}>
                            <div>{rowElem?.title}</div>
                            {rowElem?.data?.map((cellElem, cellIndex) => {
                              const isAnyFieldValue =
                                header[cellIndex]?.isValueExist;
                              let isFieldAccess = true;
                              if (rowElem?.dependent) {
                                isFieldAccess =
                                  cellElem?.type === rowElem?.dependent;
                              }

                              return (
                                <div
                                  key={cellIndex}
                                  onClick={() => {
                                    if (isFieldAccess) {
                                      if (
                                        (editData?.fieldId !== rowElem?.key ||
                                          editData?.locationDetailId !==
                                            cellElem?.locationDetailId) &&
                                        !deleted &&
                                        rowElem?.key !== "drain_tube_document"
                                      ) {
                                        const isDatePicker = [
                                          "placement_date",
                                          "removed_date",
                                        ].includes(rowElem?.key);
                                        setEditData({
                                          locationId: id,
                                          locationDetailId:
                                            cellElem?.locationDetailId,
                                          fieldId: rowElem?.key,
                                          options: rowElem?.options,
                                          fieldValue: isDatePicker
                                            ? cellElem?.value
                                              ? moment(
                                                  cellElem?.value,
                                                  "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                                ).format("YYYY-MM-DDTHH:mm")
                                              : ""
                                            : cellElem?.value,
                                          dependent: rowElem?.dependent,
                                        });
                                      }
                                    }
                                  }}
                                >
                                  <DrainsTubesCell
                                    deleted={deleted}
                                    isFieldAccess={isFieldAccess}
                                    data={cellElem}
                                    isAnyFieldValue={isAnyFieldValue}
                                    cellTitle={rowElem?.title}
                                    cellKey={rowElem?.key}
                                    editData={editData}
                                    setEditData={setEditData}
                                    setDisplayURL={setDisplayURL}
                                    handleStatusChange={(e) => {
                                      handleAddEditDetails({
                                        locationId: id,
                                        locationDetailId:
                                          cellElem.locationDetailId,
                                        active: !e,
                                      });
                                    }}
                                    handleEditDetails={(e) => {
                                      handleAddEditDetails({
                                        locationId: e.locationId || id,
                                        locationDetailId:
                                          e.locationDetailId ||
                                          cellElem.locationDetailId,
                                        [e.fieldId]: e.fieldValue,
                                      });
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </Accordion.Body>
                  )}
                </Accordion.Item>
              );
            })}

          </Accordion>
          
        )}
        </>)}
      </div>
    </div>
  );
};

export default DrainsTubes;
