import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../../../../api-client";
import { apiUrl, PORT } from "../../../../../environment";
import { toasterConfig } from "../../../../../utils/constants";

toast.configure();

export const getBloodProduct = (payload) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  return await ApiClient.post(
    `${apiUrl}${PORT}/blood-product/get-all-with-date-filter`,
    payload,
    token,
    dispatch, getState
  );
};

export const addBloodProduct = (payload) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  if (token) {
    const response = await ApiClient.post(
      `${apiUrl}${PORT}/blood-product/add`,
      payload,
      token,
      dispatch, getState
    );

    return response;
  }
};

export const updateBloodProduct = (payload) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  if (token) {
    const response = await ApiClient.put(
      `${apiUrl}${PORT}/blood-product/update`,
      payload,
      token,
      dispatch, getState
    );
    if (response?.message) {
      if (response?.status) {
        toast.success(response.message, toasterConfig);
      } else {
        toast.error(response.message, toasterConfig);
      }
    }
    return response;
  }
};

export const deleteBloodProduct = (id) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  if (token) {
    const response = await ApiClient.put(
      `${apiUrl}${PORT}/blood-product/remove-bloodProducts/${id}`,
      {},
      token,
      dispatch, getState
    );
    if (response?.message) {
      if (response?.status) {
        toast.success(response.message, toasterConfig);
      } else {
        toast.error(response.message, toasterConfig);
      }
    }
    return response;
  }
};
