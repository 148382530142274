import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "reactstrap";
import FormTemplateBlock from "../../../../shared/FormTemplateBlock";
import EmailFormTemplateBlock from "./EmailFormTemplateBlock";
import "./index.scss";
import PatientIntakeFormBlock from "./PatientIntakeFormBlock";
import SMSFormTemplateBlock from "./SMSFormTemplateBlock";
import PatientNotesTemplateBlock from "./PatientNotesTemplateBlock";
import DischargeInstructionDocument from "./DischargeInstructionDocument";
import TreatmentPlanAdd from "components/patient-module/PatientDetail/TabScreens/Overview/TreatmentPlanBlock/TreatmentPlanAdd";
import TreatmentPlanTemplateBlock from "./TreatmentPlanTemplateBlock";
import { getFormAndTemplates } from "context/actions/formTemplates";

const FormTemplate = () => {
  const dispatch = useDispatch();
  const { formTemplates } = useSelector((state) => state.formTemplateReducer);
  console.log("%c Line:16 🍿 formTemplates", "color:#f5ce50", formTemplates);
// const [formTemplates, setFormTemplates] = useState({})
  useEffect(() => {
    handleAllFormTemplates();
  }, []);

  const handleAllFormTemplates = () => {
    dispatch(getFormAndTemplates({}, () => {
      console.log("getFormAndTemplates called")
    }));
  };

  return (
    <>
      <div className="form-template-wrapper">
        <DischargeInstructionDocument
          list={(formTemplates && formTemplates.patientintakeforms) || []}
        />
        <TreatmentPlanTemplateBlock 
         list={(formTemplates && formTemplates?.treatmentPlanTemplates) || []}
        />
        <PatientNotesTemplateBlock
          list={formTemplates && formTemplates?.patientnotestemplates}
        />
        <PatientIntakeFormBlock
          list={(formTemplates && formTemplates.patientintakeforms) || []}
        />

        {/*
        <EmailFormTemplateBlock
          list={(formTemplates && formTemplates.emailtemplates) || []}
        />
        <SMSFormTemplateBlock
          list={(formTemplates && formTemplates.smstemplates) || []}
        />*/}
      </div>
    </>
  );
};

export default FormTemplate;
