import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import db from "../../../../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { Icons } from "utils/contants/Icons";
import CheckBox from "shared/CheckBox";
import "./index.scss";
import { getUserGroupList } from "context/actions/realTimeChat";
import useDebounce from "hooks/useDebounce";
import { getAllStaffManagementUsers } from "context/actions/staffManagement";

const CreateGroup = ({
  show,
  handleClose,
  staffMembersList,
  setStaffMembersList,
}) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.user_details);
  const [search, setSearch] = useState("");
  const searchDebouncedValue = useDebounce(search, 300);
  const [newGroupDetails, setNewGroupDetails] = useState({
    name: "",
    memberIds: [],
  });

  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );


  const onClose = () => {
    handleClose();
    setNewGroupDetails({
      name: "",
      memberIds: [],
    });
    setSearch("");
  };
  const handleTextInput = (e) => {
    e.persist();
    if (e.target.name === "group-name") {
      setNewGroupDetails((prev) => ({ ...prev, name: e.target.value }));
    }
  };
  const handleAddCheck = (e, data) => {

    console.log('trying to add to group')
    if (e.target.checked) {
      setNewGroupDetails((prev) => ({
        ...prev,
        memberIds: [...prev.memberIds, data.user?._id],
      }));

    } else {

     
      setNewGroupDetails((prev) => {
        return {
          ...prev,
          memberIds: [
            ...prev.memberIds.filter((ele) => ele !== data.user?._id),
          ],
        };
      });

    }
  };

  const handleCheckedAll = (e) => {
    if (e.target.checked) {
      setNewGroupDetails((prev) => ({
        ...prev,
        memberIds: staffMembersList?.map((ele) => ele?.user?._id),
      }));
    } else {
      setNewGroupDetails((prev) => {
        return { ...prev, memberIds: [] };
      });
    }
  };

  const createGroupRoom = async () => {
    if (newGroupDetails.name === "") return toast.error("Enter Group Name");
    if (newGroupDetails.memberIds.length <= 0)
      return toast.error("Select Group Members");
    try {
      const roomsCollectionRef = collection(db, "chatRooms");
      const memberData = [
        {
          id: userDetails?._id,
          name: userDetails?.firstname + " " + userDetails?.lastname || "",
          unreadCount: 0,
        },
      ];
      const tempImgArr = staffMembersList
        ?.map(({ user }) => {
          const matchingMember = newGroupDetails.memberIds.find(
            (mem) => mem === user?._id
          );
          if (matchingMember) {
            memberData.push({
              id: matchingMember,
              name: user?.firstname,
              unreadCount: 0,
            });
          }
          return matchingMember && user?.user_image;
        })
        .filter(Boolean);
      const roomDocRef = await addDoc(roomsCollectionRef, {
        roomName: newGroupDetails?.name,
        createdAt: serverTimestamp(),
        membersId: [userDetails?._id, ...newGroupDetails.memberIds],
        members: memberData,
        createdBy: userDetails?._id,
        userImgs: [userDetails?.user_image, ...tempImgArr],
      });
      if (roomDocRef.id) {
        toast.success("New group created.");
        dispatch(getUserGroupList());
        onClose();
      }
    } catch (errors) {
      console.log("errors", errors);
    }
  };
  useEffect(() => {
    dispatch(
      getAllStaffManagementUsers(
        
        { searchText: search },
        (result) => {
          if (result && !result.errors) {
            setStaffMembersList(result);
          }
        }
      )
    );
  }, [searchDebouncedValue]);

  return (
    <Offcanvas
      show={show}
      onHide={onClose}
      backdrop="static"
      placement="end"
      className="select-group-list"
    >
      <Offcanvas.Header closeButton className="p-2">
        <Offcanvas.Title>Create Group</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="px-2 pb-2 pt-0">
        <div className="d-flex flex-column h-100">
          <div className="mb-2 group-add-name">
            <input
              type="text"
              className="form-control"
              placeholder="Add Group Name"
              name="group-name"
              value={newGroupDetails.name}
              onChange={handleTextInput}
            />
          </div>
          <div className="mb-2 search-group-wrapp position-relative">
            <Icons.SearchIcon />
            <input
              type="text"
              className="form-control"
              placeholder="search"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>

          <div className="table-listing-blc overflow-auto">
            <div className="table-responsive">
              <table className="checkbox-listing table table-striped group-add-listing">
                <thead>
                  <tr>
                    <th>
                      <CheckBox
                        type="checkbox"
                        ischecked={false}
                        className="no-label"
                        onChange={handleCheckedAll}
                      />
                    </th>
                    <th style={{ minWidth: 90 }}>Title</th>
                    <th style={{ minWidth: 128 }}>City</th>
                  </tr>
                </thead>
                <tbody>
                  {staffMembersList?.length > 0 &&
                    staffMembersList?.map((ele, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <CheckBox
                              type="checkbox"
                              ischecked={newGroupDetails.memberIds?.includes(
                                ele?.user?._id
                              )}
                              className="no-label"
                              onChange={(e) => handleAddCheck(e, ele)}
                              checked={newGroupDetails.memberIds?.includes(
                                ele?.user?._id
                              )}
                            />
                          </td>
                          <td>
                            <button
                              type="button"
                              className="p-0 text-start pic-cell d-inline-flex align-items-center w-100 btn btn-link"
                            >
                              <div className="pic d-inline-flex position-relative">
                                <div className="img-placeholder position-relative d-flex align-items-center justify-content-center w-100 h-100 img-fluid w-50 h-50">
                                  {ele?.user.user_image ? (
                                    <img src={ele?.user.user_image} alt="i" />
                                  ) : (
                                    <span className="no-image d-inline-flex align-items-center justify-content-center position-absolute">
                                      <Icons.DummyImg />
                                    </span>
                                  )}
                                </div>
                              </div>
                              {ele?.user_type === "doctor" ? "Dr." : null}
                              {ele?.user?.firstname} {ele?.user?.lastname}
                            </button>
                          </td>
                          <td>{ele?.user?.city}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="mt-auto d-flex align-items-center justify-content-end gap-2">
            <button className="btn cancel-btn" onClick={onClose}>
              Cancel
            </button>
            <button className="btn btn-3D3EFD" onClick={createGroupRoom}>
              Create Group
            </button>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default CreateGroup;
