import React from "react";
import { useParams } from "react-router-dom";
import ChatGroupListPetOwner from "./PetChatGroupList";
import ChatRoom from "components/message-center/ChatRoom";


const PetOwnerMessages = () => {
  const routeParams = useParams();

  return (
  <>
    <div className="h-screen">
      <div className="row g-0">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 col-xl-3 col-lg-3 border-right">
              <ChatGroupListPetOwner routeParams={routeParams} />
            </div>
              <ChatRoom roomId={routeParams?.room_id} chatType={routeParams?.type} isRightSection={false} />
          </div>
        </div>
      </div>
    </div>
  </>
  );
};

export default PetOwnerMessages;
