import React, { useEffect, useState } from "react";
import { getTreatmentByPetId } from "../../../../context/actions/patient-module/orders";
import { useDispatch } from "react-redux";
import TreatmentPlanItem from "components/patient-module/PatientDetail/TabScreens/Overview/TreatmentPlanBlock/TreatmentPlanItem";
import "./index.scss";
import NodataFoundBlock from "shared/NoDataFoundBlock";

const TreatmentPlanBlock = ({ petId }) => {
  const dispatch = useDispatch();
  const [treatmentData, setTreatmentData] = useState([]);

  const getPageData = (id) => {
    dispatch(
      getTreatmentByPetId(id, (response) => {
        setTreatmentData(response?.response_data);
      })
    );
  };

  useEffect(() => {
    getPageData(petId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="treatment-block">
        <div className="head section-head d-flex align-items-center">
          {/* <div className="left-col">
            <h3>Treatment Plans</h3>
          </div> */}
          {/* <div className="right-col ms-auto d-inline-flex align-items-center">
            <Button color="link" className="add-btn" onClick={handleShow}>
              <i className="icomoon-plus-btn"></i>
            </Button>
          </div> */}
        </div>

        <div className="inner">
          <div className="report-items-rw">
            <div className="item-col-wrapper d-flex flex-wrap">
              {treatmentData?.length > 0 ?
                treatmentData?.map((data, i) => {
                  return (
                    <React.Fragment key={i}>
                      <TreatmentPlanItem
                        data={data}
                        pet_Id={petId}
                        getPageData={getPageData}
                      />
                    </React.Fragment>
                  );
                }) : 
                <NodataFoundBlock />
                }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TreatmentPlanBlock;
