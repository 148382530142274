import React, { useEffect, useState } from "react";
import { formatPhoneNumber } from "utils/common";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Button } from "reactstrap";
import {
  getClinicDetailsByUserId,
  getHospitalDetailsById,
  updateGeneralInfo,
} from "context/actions/clinic";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const FormBlock = () => {
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const dispatch = useDispatch();

  const clinicId = clinic_details?._id;

  const [generalInfo, setGeneralInfo] = useState({
    clinic_id: clinicId,
    clinic_name: clinic_details?.clinic_name,
    clinic_domain: clinic_details?.clinic_domain,
    email: clinic_details?.email,
    phone: formatPhoneNumber(clinic_details?.phone),
    address: clinic_details?.address,
  });

  const HandleChange = (e) => {
    const { name, value } = e.target;
    setGeneralInfo((prevGeneralInfo) => ({
      ...prevGeneralInfo,
      [name]: value,
    }));
  };

  const handleInputBlur = async () => {
    try {
      await dispatch(updateGeneralInfo(generalInfo));
      // dispatch(getClinicDetailsByUserId());
      dispatch(getHospitalDetailsById(clinicId, {}, () => {}));
    } catch (error) {
      console.error("Error updating general info:", error);
    }
  };

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);

      setGeneralInfo((prevGeneralInfo) => ({
        ...prevGeneralInfo,
        address: selectedAddress,
      }));

      await dispatch(updateGeneralInfo(generalInfo));

      // dispatch(getClinicDetailsByUserId());
      dispatch(getHospitalDetailsById(clinicId, {}, () => {}));
    } catch (error) {
      console.error("Error selecting address", error);
    }
  };

  return (
    <>
      <div className="form-block">
        <FormGroup className="input-wrapper2">
          <div className="form-floating position-relative text-input">
            <input
              type="text"
              className="form-control"
              id="clinicName"
              name="clinic_name"
              placeholder="Enter First Name"
              value={generalInfo.clinic_name}
              onChange={HandleChange}
              onBlur={handleInputBlur}
            />
            <label for="clinicName">Clinic Name</label>
          </div>
        </FormGroup>
        <FormGroup className="input-wrapper2">
          <div className="form-floating position-relative text-input">
            <input
              type="text"
              className="form-control"
              id="domainName"
              name="clinic_domain"
              placeholder="Enter Domain Name "
              value={generalInfo.clinic_domain}
              onChange={HandleChange}
              onBlur={handleInputBlur}
            />
            <label for="domainName">Domain Name</label>
          </div>
        </FormGroup>
        <FormGroup className="input-wrapper2">
          <div className="form-floating position-relative text-input">
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="Email address"
              onChange={HandleChange}
              onBlur={handleInputBlur}
              value={generalInfo.email}
            />
            <label for="email">Email address</label>
          </div>
        </FormGroup>
        <FormGroup className="input-wrapper2">
          <div className="form-floating position-relative text-input">
            <input
              type="phone"
              className="form-control"
              id="phone"
              name="phone"
              onChange={HandleChange}
              onBlur={handleInputBlur}
              placeholder="Phone"
              value={generalInfo.phone}
            />
            <label for="phone">Phone</label>
          </div>
        </FormGroup>
        <div className="form-group-wrapper d-flex">
          <FormGroup className="input-wrapper2 flex-fill">
            {/* <div className="form-floating position-relative text-input">
              <input
                type="text"
                className="form-control"
                id="address"
                name="address"
                placeholder="Enter First Name"
                onChange={HandleChange}
				onBlur={handleInputBlur}
                value={generalInfo.address}
              />
              <label for="address">Head Office Address Information</label>
            </div> */}

            <PlacesAutocomplete
              value={generalInfo.address}
              onChange={(newAddress) =>
                setGeneralInfo((prev) => ({ ...prev, address: newAddress }))
              }
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="position-relative">
                  <div className="form-group-wrapper d-flex">
                    <FormGroup className="input-wrapper2 flex-fill">
                      <div className="form-floating position-relative text-input">
                        <input
                          type="text"
                          id="address"
                          name="address"
                          placeholder="Enter First Name"
                          {...getInputProps({
                            placeholder: "Enter your address...",
                            className: "location-search-input form-control",
                          })}
                          value={generalInfo.address}
                          onBlur={handleInputBlur}
                        />
                        <label htmlFor="address">
                          Head Office Address Information
                        </label>
                      </div>
                    </FormGroup>
                    <Button
                      color="link"
                      className="d-inline-flex align-items-center justify-content-center"
                    >
                      <i className="icomoon-location-current"></i>
                    </Button>
                  </div>
                  {loading || suggestions.length ? (
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => (
                        <div
                          className="pointer"
                          {...getSuggestionItemProps(suggestion)}
                          key={suggestion.placeId}
                        >
                          {suggestion.description}
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              )}
            </PlacesAutocomplete>
          </FormGroup>
        </div>
      </div>
    </>
  );
};

export default FormBlock;
