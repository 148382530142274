import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table, Form } from "reactstrap";
import { useHistory } from "react-router";
import "./index.scss";
import ImagePlaceholder from "../../../shared/ImagePlaceholder";
import { addClinicTaskPanelAction, createClinicTaskDetails } from "../../../context/actions/clinictask";
import { getAllClinicLiveAppointments } from "../../../context/actions/clinictask";
import { useFormik } from "formik";
import TextInput from '../../../shared/FormsInput/TextBox';
import { addTaskInitialValue, addTaskSchema } from "./schema";
import moment from "moment";

const NewClinicTask = ({className = "", appointment, callable, fetchData }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const [initialValue, setInitialValue] = useState(addTaskInitialValue);
  const userDetails = useSelector((state) => state?.user?.user_details);
  const {clinic_settings} = useSelector((state) => state?.clinicSettingsReducer)

  const closeAddTaskPanel = () => {
      dispatch(addClinicTaskPanelAction(false));
  };

  useEffect((() => {

    }), []);


  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: (values, { resetForm }) => {
      const startdate =  appointment?.appointment_start 
      ? moment(appointment?.appointment_start).format("YYYY-MM-DD")
      : moment(appointment?.appointment_date).format("YYYY-MM-DD");

      let postedobj = {
        task_name: values.task_name,
        task_time: moment(startdate + ' ' + values.task_time, 'YYYY-MM-DD HH:mm').toDate(),
        reminder_time: moment(startdate + ' ' + values.reminder_time, 'YYYY-MM-DD HH:mm').toDate(),
        appointment_id: appointment?._id,
        created_by: userDetails?._id,
      }
      dispatch(createClinicTaskDetails(postedobj, (response) => {
        if(response.statusCode === 201){
          dispatch(getAllClinicLiveAppointments(clinic_settings?.clinic_id, {}, () => {}))
          dispatch(addClinicTaskPanelAction(false));
          resetForm();
        }
      }));
    },
    validationSchema:addTaskSchema
  });

  return (
    <>
      <div
        className={`patients-panel-wrapper position-fixed d-flex flex-column overflow-auto 
      ${className}
      
      `}
      >
        <div className="head d-flex align-items-center">
          <h2>Add Task</h2>
          <Button
            color="link"
            className="ms-auto panel-close-btn"
            onClick={closeAddTaskPanel}
          >
            <span className="material-symbols-outlined">close</span>
          </Button>
        </div>

        <Form onSubmit={formik.handleSubmit}>
          <div className="mt-2 space-y-2">
            <div className="flex items-center gap-x-2 w-full">
              <div className="w-1/2">
                <label htmlFor="task_name" className="required-input text-xs text-gray-500">
                  Task Name
                </label>

                <input
                  id="task_name"
                  name="task_name"
                  type="text"
                  value={formik?.values?.task_name}
                  onChange={formik.handleChange}
                  className="w-full rounded-xl border overflow-hidden h-12 px-2.5"
                />
                {formik?.errors?.task_name && formik?.touched?.task_name && formik?.errors?.task_name}
              </div>

              <div className="w-1/2">
                <label htmlFor="task_time" className="required-input text-xs text-gray-500">
                  Task Time
                </label>

                <input
                  id="task_time"
                  name="task_time"
                  type="time"
                  value={formik?.values?.task_time}
                  onChange={formik.handleChange}
                  className="w-full rounded-xl border overflow-hidden h-12 px-2.5"
                />
                {formik?.errors?.task_time && formik?.touched?.task_time && formik?.errors?.task_time}
              </div>           
            </div>

            <div className="flex items-center gap-x-2 w-full">
              <div className="w-1/2">
                <label htmlFor="reminder_time" className="required-input text-xs text-gray-500">
                  Reminder Time
                </label>

                <input
                  id="reminder_time"
                  name="reminder_time"
                  type="time"
                  value={formik?.values?.reminder_time}
                  onChange={formik.handleChange}
                  className="w-full rounded-lg border overflow-hidden h-12 px-2.5"
                />
                {formik?.errors?.reminder_time && formik?.touched?.reminder_time && formik?.errors?.reminder_time}
              </div>          
            </div>
            
            <div className="flex justify-end items-center gap-2 mt-3">
              <button
                className="px-4 py-2.5 rounded-lg bg-gray-100"
                onClick={closeAddTaskPanel}
              >
                Cancel
              </button>
              <button  type="submit" className="px-4 py-2.5 rounded-lg text-white bg-[#11CA9D]">
                Save
              </button>
            </div>
          </div>
        </Form>
          
      </div>
    </>
  );
};

export default NewClinicTask;
