import { useState } from "react";
import { useSelector } from "react-redux";
import { Nav, NavItem, NavLink } from "reactstrap";
import { IoShareSocial } from "react-icons/io5";
import Overview from "./Overview";
import Pets from "./Pets";
import "./PetOwnerProfile.scss";

const PetOwnerProfile = () => {
  const userDetail = useSelector((state) => state.user.user_details);
  const [activeTab, setActiveTab] = useState("1");
  const { user_image, firstname, lastname } = userDetail || {};
  return (
    <div id="pet-owner-profile">
      <div className="text-24-700 color-2E65">Profile Details</div>
      
      <div className="d-flex gap-3 mt-1">
        <div className="f-center bg-eeee color-cfcf h-99 w-99 rounded-circle">
          {user_image ? (
            <img src={user_image} alt="badge-img" className="rounded-circle" />
          ) : (
            <span className="material-symbols-outlined text-50-400">
              person
            </span>
          )}
        </div>
        <div className="flex-grow-1">
          <div className="d-flex justify-content-between">
            <div className="text-24-600 color-2E65">
              {firstname} {lastname}
            </div>
            {/* <div className="d-flex cursor-pointer">
              <span className="bg-F8F8 p-1 ps-2 pe-2 br-4 mb-0">
                <IoShareSocial color="#3D3EFD" />
              </span>
            </div> */}
          </div>
          <div className="text-16-400 color-2E65 mt-1">
            {/* Client ID : CL46235 */}
          </div>
          <div className="text-success mt-1">Active</div>
        </div>
      </div>
      <Nav tabs className="d-flex tab_row1 mb-2">
        <NavItem>
          <NavLink
            className={activeTab === "1" ? "active" : ""}
            onClick={() => setActiveTab("1")}
          >
            Overview
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "2" ? "active" : ""}
            onClick={() => setActiveTab("2")}
          >
            Pets
          </NavLink>
        </NavItem>
      </Nav>
      {activeTab === "1" && <Overview userData={userDetail} />}
      {activeTab === "2" && <Pets />}
    </div>
  );
};

export default PetOwnerProfile;
