import moment from "moment";
import React, { useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Input } from "reactstrap";
import DateRange from "shared/FormsInput/DateRange";
import { Icons } from "utils/contants/Icons";
import "./WellnessPlanReports.scss";

const WellnessPlanReports = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterObj, setFilterObj] = useState({
    species_id: "",
    start_date: "",
    end_date: "",
    age_range: "",
  });
  const [tableData, setTableData] = useState({
    data: [],
    loading: true,
  });
  return (
    <div id="wellnessPlan-reports-container" className="p-1 mt-1">
      <div className="row">
        <div className="col-md-3">
          <div className="">
            <div className="d-flex">
              <div
                className="d-flex gap-2 align-items-center cmb-20 pointer"
                onClick={() => history.push("/reports")}
              >
                <BsChevronLeft color="color-3EFD" />
                <span className="text-13-600 color-3EFD">VIEW ALL REPORTS</span>
              </div>
            </div>

            <div className="bg-filter-class">
              <div className="text-24-700 color-2E65">Configure Report</div>
              <div className="text-14-400 color-8693">
                Select filter and generate report
              </div>
              <div className="filter-block cmt-20 cmb-20">
                <div className="cmb-10">
                  <Input
                    type="select"
                    name="select"
                    value={filterObj?.species_id}
                    placeholder="All Species"
                    onChange={(e) => {
                      setFilterObj((prev) => ({
                        ...prev,
                        species_id: e?.target?.value,
                      }));
                    }}
                  >
                    <option value="">All Species</option>
                    {/* {species?.map((e, i) => {
                      return (
                        <option value={e?._id} key={i} id={e?._id}>
                          {e?.species_name}
                        </option>
                      );
                    })} */}
                  </Input>
                </div>

                {/* <div className="cmb-10">
                  <Input
                    type="select"
                    name="select"
                    value={filterObj?.age_range}
                    placeholder="By Age"
                    onChange={(e) => {
                      setFilterObj((prev) => ({
                        ...prev,
                        age_range: e?.target?.value,
                      }));
                    }}
                  >
                    <option value="">By Age</option>
                    {ageList?.map((e, i) => {
                      return (
                        <option value={e?.id} key={i} id={e?.id}>
                          {e?.value}
                        </option>
                      );
                    })}
                  </Input>
                </div> */}

                <div className="cmb-10">
                  <DateRange
                    placeholder="Please select date"
                    id="boarding"
                    name="boarding"
                    startDateProp={startDate}
                    endDateProp={endDate}
                    setStartDateProp={setStartDate}
                    setEndDateProp={setEndDate}
                    onChange={(sDate, eDate) => {
                      setFilterObj((prev) => ({
                        ...prev,
                        start_date: sDate
                          ? moment(sDate).format("YYYY-MM-DD")
                          : "",
                        end_date: eDate
                          ? moment(eDate).format("YYYY-MM-DD")
                          : "",
                      }));
                    }}
                    isClearable
                  />
                </div>
              </div>
              <div className="cmb-15">
                {/* <Button className="g-report">Generate Report</Button> */}
                <div
                  className="text-center color-2E65 text-13-600 cmt-20 pointer"
                  // onClick={() => {
                  //   setFilterObj({
                  //     species_id: "",
                  //     start_date: "",
                  //     end_date: "",
                  //     age_range: "",
                  //   });
                  //   setStartDate(null);
                  //   setEndDate(null);
                  // }}
                >
                  RESET FILTER
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-9 details-section">
          <div className="cpt-20 cpe-20 cpb-20">
            <div className="d-flex justify-content-between align-items-center cmb-20">
              <div>
                <div className="text-24-700 color-2E65">Boarding Records</div>
                <p className="text-14-400 color-8693 text-wrap">
                  Boarding Records help track the clinic’s medical supplies,
                  medications, and equipment to ensure that everything is
                  well-stocked and up to date.
                </p>
              </div>
              <div className="d-flex gap-2 br-8">
                <div
                  className="cp-8 bg-8C9F28 pointer"
                  // onClick={() => {
                  //   handleDownload();
                  // }}
                >
                  <img src={Icons.download} alt="download" />
                </div>
                <div className="cp-8 bg-8C9F28 br-8">
                  <img src={Icons.share} alt="share" />
                </div>
              </div>
            </div>

            <div className="cmb-20 card-block row flex-wrap gy-3">
              <div className="text-15-700">Average lengths of stay by age</div>
              {tableData?.data?.response_data?.[0]?.ageAverage?.map(
                (data, index) => {
                  return (
                    <div
                      className="col-md-2 col-sm-4 flex-grow-1 avrage-card"
                      key={index}
                    >
                      <div className="items-details-month">
                        <div className="text-13-600 cmb-10">
                          Age Range: {data?.age_range}
                        </div>
                        <div className="text-13-600">
                          Average Days: {data?.averageLengthOfStay?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WellnessPlanReports;
