import React from "react";
import ChatBlock from "./ChatBlock";
import UpcomingVisitCard from "./UpcomingVisitCard";

import "./index.scss";


const Messages = () => {


    return (
        <>

            <div className="messages-block d-flex flex-row pet_owner_messages_wrap">
                <div className="left-col">
                    <ChatBlock />
                </div>
                <div className="right-col">
                    <UpcomingVisitCard />

                    <div className="filter-block">
                        <div className="list-section">
                            <div className="title">
                                Filter By
                            </div>
                            <div className="list">
                                <div className="list-item">
                                    <div className="icon"> <img src={require("../../../../../assets/img/vetRecorEMR/user-thumbnail.png")} /></div>
                                    <div className="name">Dr. Martha William</div>
                                </div>
                                <div className="list-item">
                                    <div className="icon"> <img src={require("../../../../../assets/img/vetRecorEMR/user-thumbnail.png")} /></div>
                                    <div className="name">Dr. Martha William</div>
                                </div>
                                <div className="list-item">
                                    <div className="icon"> <img src={require("../../../../../assets/img/vetRecorEMR/user-thumbnail.png")} /></div>
                                    <div className="name">Dr. Martha William</div>
                                </div>
                                <div className="list-item">
                                    <div className="icon"> <img src={require("../../../../../assets/img/vetRecorEMR/user-thumbnail.png")} /></div>
                                    <div className="name">Dr. Martha William</div>
                                </div>
                                <div className="list-item">
                                    <div className="icon"> <img src={require("../../../../../assets/img/vetRecorEMR/user-thumbnail.png")} /></div>
                                    <div className="name">Dr. Martha William</div>
                                </div>
                                <div className="list-item">
                                    <div className="icon"> <img src={require("../../../../../assets/img/vetRecorEMR/user-thumbnail.png")} /></div>
                                    <div className="name">Dr. Martha William</div>
                                </div>
                                <div className="list-item">
                                    <div className="icon"> <img src={require("../../../../../assets/img/vetRecorEMR/user-thumbnail.png")} /></div>
                                    <div className="name">Dr. Martha William</div>
                                </div>
                                <div className="list-item">
                                    <div className="icon"> <img src={require("../../../../../assets/img/vetRecorEMR/user-thumbnail.png")} /></div>
                                    <div className="name">Dr. Martha William</div>
                                </div>
                            </div>
                        </div>


                        <div className="list-section">
                            <div className="title">
                                Files & Documents
                            </div>
                            <div className="list">
                                <div className="list-item">
                                    <div className="icon"> <img src={require("../../../../../assets/img/vetRecorEMR/user-thumbnail.png")} /></div>
                                    <div className="name">Dr. Martha William</div>
                                </div>
                                <div className="list-item">
                                    <div className="icon"> <img src={require("../../../../../assets/img/vetRecorEMR/user-thumbnail.png")} /></div>
                                    <div className="name">Dr. Martha William</div>
                                </div>
                                <div className="list-item">
                                    <div className="icon"> <img src={require("../../../../../assets/img/vetRecorEMR/user-thumbnail.png")} /></div>
                                    <div className="name">Dr. Martha William</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )

}

export default Messages