import React from "react";
import { PublicLayout } from "../layouts";
import ReportImageViewer from "../shared/ReportImageViewer";



const DicomViewerRoutes = [
  {
    path: "/dicom-viewer/:url",
    exact: true,
    layout: PublicLayout,
    component: ReportImageViewer
  }
 
];

export default DicomViewerRoutes;
