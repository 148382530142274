import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from 'reactstrap';
import './index.scss';
import { useClinicSpecies } from "hooks/useClinicSpecies";



const SpeciesAgePreferences = ({ setSelectedSpecies, setPanelState }) => {


    const handleSelect = (type) => {
        setSelectedSpecies(type);
        setPanelState(true)


    }

    const { species, breeds } = useSelector((state) => state.appContentReducer);
    const clinicSpecies = useClinicSpecies()

    const { permissionsData } = useSelector((state) => state.clinicPermissionReducer);
    const permissions = permissionsData;

    return (
        <>
            <div className="waiting-room-list">
                {species?.map((item, i) => (
                    <div className="item-row d-flex">
                        <div className="left-col">
                            <div className="room-info">
                                <h4>{clinicSpecies(item?._id) || item?.species_name}</h4>
                            </div>
                        </div>
                        {permissions?.edit_vaccine_schedules &&
                            <div className="right-col d-inline-flex align-items-center">
                                <Button onClick={() => handleSelect(item)} color="link" className="d-inline-flex align-items-center justify-content-center">
                                    <span className="icomoon-pencil"></span>
                                </Button>
                            </div>
                        }
                    </div>
                ))}

            </div>
        </>
    );



};

export default SpeciesAgePreferences;
