import React from "react";
import moment from "moment";
import { CiSearch } from "react-icons/ci";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { Button } from "reactstrap";

const FilterComponent = ({
  filterData,
  setFilterData,
  recentDate,
  dateList,
}) => {
  const { date, searchText, selectedDates } = filterData;
  const handleSelectDate = (cDay) => {
    let nData = selectedDates;
    let gDate = `${cDay}-${selectedMonth}-${selectedYear}`;
    if (nData.includes(gDate)) {
      if (nData.length > 1) {
        nData = nData.filter((o) => o !== gDate);
      }
    } else {
      nData = [...nData, gDate];
    }
    setFilterData((prev) => {
      return {
        ...prev,
        selectedDates: nData,
      };
    });
  };
  const handleFilterDate = (type) => {
    let cDate = moment(date, "DD-MM-YYYY");
    let month = +moment(cDate).format("MM");
    let year = +moment(cDate).format("YYYY");
    if (type === "Prev") {
      year = month === 1 ? year - 1 : year;
      month = month === 1 ? 12 : month - 1;
    } else {
      year = month === 12 ? year + 1 : year;
      month = month === 12 ? 1 : month + 1;
    }
    setFilterData((prev) => {
      return {
        ...prev,
        selectedDates: [`01-${month < 10 ? `0${month}` : month}-${year}`],
        date: moment(
          `01-${month < 10 ? `0${month}` : month}-${year}`,
          "DD-M-YYYY"
        ).format("DD-MM-YYYY"),
      };
    });
  };

  const mDate = moment(date, "DD-MM-YYYY");
  const displayDate = moment(mDate, "DD-MM-YYYY").format("MMM YYYY");
  const monthCount = +moment(mDate).endOf("month").format("D");
  const selectedMonth = moment(mDate).format("MM");
  const selectedYear = moment(mDate).format("YYYY");

  return (
    <div className="vitals-filter-block">
      <div className="d-flex align-items-center justify-content-between">
        <div className="vitals-search-block">
          <CiSearch className="icon-block" />
          <input
            type="text"
            placeholder="Search"
            value={searchText}
            onChange={(e) => {
              setFilterData((prev) => {
                return { ...prev, searchText: e.target.value };
              });
            }}
          />
        </div>
        <div className="right-filter-block fa-center gap-2">
          {recentDate && (
            <Button
              color="link"
              className="list-view-type-btn d-inline-flex align-items-center"
              onClick={() => {
                setFilterData((prev) => {
                  let cDate = moment(recentDate, "DD-MM-YYYY");
                  let month = +moment(cDate).format("MM");
                  let year = +moment(cDate).format("YYYY");
                  return {
                    ...prev,
                    selectedDates: [recentDate],
                    date: moment(
                      `01-${month < 10 ? `0${month}` : month}-${year}`,
                      "DD-M-YYYY"
                    ).format("DD-MM-YYYY"),
                  };
                });
              }}
            >
              Last Result
            </Button>
          )}
          <div className="fa-center gap-1">
            <div
              onClick={() => {
                handleFilterDate("Prev");
              }}
            >
              <BsChevronLeft />
            </div>
            <div>{displayDate}</div>
            <div
              onClick={() => {
                handleFilterDate("Next");
              }}
            >
              <BsChevronRight />
            </div>
          </div>
        </div>
      </div>
      <div className="vitals-filter-day-container">
        {Array(monthCount)
          .fill()
          .map((_, i) => {
            const cDay = i + 1;
            const dDay = cDay < 10 ? `0${cDay}` : `${cDay}`;
            const isActive = selectedDates.includes(
              `${dDay}-${selectedMonth}-${selectedYear}`
            );
            const weekDayName = moment(
              `${cDay}-${selectedMonth}-${selectedYear}`,
              "D-MM-YYYY"
            ).format("dd");
            let isDayData = dateList?.some((o) => o === dDay);
            return (
              <React.Fragment key={i}>
                {/* {isActive && <span ref={dateRef} />} */}
                <span
                  className={`day-block ${isActive ? "active" : ""}`}
                  onClick={() => {
                    handleSelectDate(dDay);
                  }}
                >
                  {isDayData && <span className="a-data-dot" />}
                  <div>{cDay}</div>
                  <div>{weekDayName}</div>
                </span>
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default FilterComponent;
