import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";

toast.configure();
/**
 * All Api endpoints to be call
 * Get -- /holiday/get-all/:clinic_id
 */

/**
 *
 * @param {Object} params
 * @returns
 */
export const getAllHoliday = (id, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    let clinicID = clinic_details?._id || id;
    if (clinicID) {
      ApiClient.get(
        `${apiUrl}${PORT}/holiday/get-all/${clinic_details?._id}`,
        {},
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({ type: "GET_HOLIDAY", payload: response?.response_data });
          return callback(response);
        } else {
          dispatch({ type: "GET_HOLIDAY", payload: [] });
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    }
  };
};

export const addHoliday = (payload) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  const response = await ApiClient.post(
    `${apiUrl}${PORT}/holiday/add-new`,
    payload,
    token,
    dispatch, getState
  );
  if (!response?.status) {
    toast.error(response?.message, toasterConfig);
  }
  return response;
};
