import * as Yup from "yup";

/**
 * @description declaration form schema for add pet  validations
 */
export const addPetSchema = Yup.object().shape({
    dob: Yup.string()
        .required("dob is required"),
    gender: Yup.string()
        .required("gender is required"),
    species: Yup.string()
        .required("species is required"),
    breed: Yup.string()
        .required("breed is required"),
    color: Yup.string()
        .required("color is required"),
    pet_name: Yup.string()
        .required("pet name is required"),
    rabies_number: Yup.string().nullable(),
    microchip_number: Yup.string().nullable(),
    cpr: Yup.string().nullable(),

    license_number: Yup.string().nullable(),
    pet_notes: Yup.string().nullable(),
    allergy_tag: Yup.array().of(Yup.object().shape({
        allergy: Yup.string(),
        reaction: Yup.string()
    })),
    behaviour_tag: Yup.array().of(Yup.object().shape({
        tag: Yup.string().nullable(),
        severity: Yup.string()
    })),
});


/**
 * @description params intilaization 
 */

export const addPetInitialValue = {
    pet_name: '',
    dob: '',
    gender: 'male',
    species: '',
    breed: '',
    color: '',
    pet_notes: '',
    rabies_number: '',
    microchip_number: '',
    license_number: '',
    cpr: '',
    allergy_tag: [],
    behaviour_tag: [],
}