import "./index.scss";
import { getAndSearchProductsAndServices } from "../../../context/actions/inventory";
import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import * as Yup from "yup";
import { FieldArray, Form, Formik, getIn } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import {
  defaultDischargePatientFormData,
  defaultOrderForDischarge,
} from "../../../context/reducers/dischargePatientReducer";
import Select from "react-select";
import moment from "moment";

const Index = forwardRef((props, ref) => {
  const formikRef = useRef(null);
  const debug = false;

  const dispatch = useDispatch();

  let resetFormFunc;
  let validateFormFun;

  const { productServiceList } = useSelector((state) => state.inventoryReducer);

  const { dischargePatientFormData } = useSelector(
    (state) => state.dischargePatientReducer
  );

  const customFilterOption = (option, rawInput) => {
    // Extract text content from the JSX element (option.label) and convert to lowercase
    const label =
      option.label && option.label.props && option.label.props.children;
    if (label && typeof label === "string") {
      return label.toLowerCase().includes(rawInput.toLowerCase());
    }
    return false;
  };

  const customFilter = (option, inputValue) => {
    // console.log('custom filter', option)

    if (inputValue) {
      // Convert option label and inputValue to lowercase for case-insensitive matching
      const label = option?.label?.toLowerCase();
      const searchValue = inputValue?.toLowerCase();

      // Return true if the label contains the inputValue
      return label.includes(searchValue);
    }
    // If no inputValue is provided, display all options
    return true;
  };

  // console.log("dischargePatientFormData ---- ", dischargePatientFormData);
  const validationSchema = Yup.object().shape({
    forms: Yup.array().of(
      Yup.object().shape({
        product_service_id: Yup.string().required(
          "Product or Service is required."
        ),
        product_service_name: Yup.string().required(
          "Product or Service name is required"
        ),
        // product_service_category: Yup.string().required(
        //   "Product or Service category is required"
        // ),
        frequency: Yup.string().optional(),
        dose: Yup.number("Only numbers are allowed.").required(
          "Dose is required."
        ),
        form: Yup.string().required("Form is required."),
        rate_val: Yup.number().nullable().optional(),
        rate_time: Yup.string().optional(),
        route: Yup.string().optional(),
        prn: Yup.string().optional(),
        start_datetime: Yup.date()
          .typeError("Please enter a valid date.")
          .required("Start datetime is required."),
        second_dose: Yup.date().nullable().optional(),
        duration_num: Yup.number().nullable().optional(),
        duration_unit: Yup.string().optional(),
        notes: Yup.string().optional(),
      })
    ),
  });

  // Function to submit the Formik form
  const submitForm = () => {
    if (formikRef.current) {
      formikRef.current.handleSubmit();
    }
  };

  useImperativeHandle(ref, () => ({
    submitForm,
  }));

  let pushFunc;

  const handleInputChange = (value, field, index) => {
    let dischargePatientData = JSON.parse(
      JSON.stringify(dischargePatientFormData)
    );
    let findEle = dischargePatientData.order_for_discharge[index];

    if (!findEle) {
      dischargePatientData.order_for_discharge.push({ [field]: value });
    } else {
      findEle[field] = value;
    }

    dispatch({
      type: "DISCHARGE_PATIENT_FORM_DATA",
      payload: dischargePatientData,
    });
  };

  // console.log("formSubmitted","appointmentDischargeFormData", props.appointmentDischargeFormData)
  // console.log("formSubmitted", props.formSubmitted)
  const removeItemFromRedux = (index) => {
    let dischargePatientData = JSON.parse(
      JSON.stringify(dischargePatientFormData)
    );
    let filteredOrders = dischargePatientData.order_for_discharge.filter(
      (ele, idx) => idx != index
    );
    dischargePatientData.order_for_discharge = filteredOrders;
    dispatch({
      type: "DISCHARGE_PATIENT_FORM_DATA",
      payload: dischargePatientData,
    });
  };

  const handleGetAndSearchProductList = (inputVal) => {
    dispatch(
      getAndSearchProductsAndServices({ searchText: inputVal }, (response) => {
        console.log(response);
      })
    );
  };

  useEffect(() => {
    handleGetAndSearchProductList();

    validateFormFun().then((errors) => {
      if (Object.keys(errors).length === 0) {
        props.setFormSubmitted(true);
      } else {
        props.setFormSubmitted(false);
      }
    });

  }, []);

  let newProductServiceList = []
  if (productServiceList && productServiceList?.length > 0) {
    newProductServiceList = productServiceList?.filter((item, i) => {
      item.isAvailable = true;
      if (item.type === "service") {
        const getUnavailableStock = item?.ser_product?.find(
          (prod) => prod.available_stock <= 0
        );
        if (
          getUnavailableStock?._id &&
          item?.ser_product?.length > 0
        )
          item.isAvailable = false;
      } else if (item.type === "product") {
        let isAnyFutureExpDate = false;
        if (item?.packages && item?.packages?.length > 0) {
          isAnyFutureExpDate = item?.packages?.some((o) =>
            moment(
              o?.expiration_date,
              "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
            ).isSameOrAfter(moment())
          );
        }
        if (
          !item?.available_stock ||
          item?.available_stock <= 0 ||
          !isAnyFutureExpDate
        ) {
          item.isAvailable = false;
        } else {
          return item
        }
      } else if (item.bundle_name) {
        const getUnavailableStock = item?.products?.find(
          (prod) => prod.available_stock === 0
        );
        if (
          getUnavailableStock?._id ||
          item?.products.length === 0
        ) {
          item.isAvailable = false;
        } else {

          return item
        }
      }
    })
  }


  // console.log("dischargePatientFormData", dischargePatientFormData)

  // useEffect(() => {
  //   if (props.apiSuccess && props.apiSuccess != "" && props.apiSuccess) {
  //     resetFormFunc();
  //     props.setApiSuccess("");
  //   }
  // }, [props.apiSuccess]);

  return (
    <div className="row-block d-flex mt-3">
      <div className="head w-[25%] me-3">
        <h3>Order for Discharge</h3>

        <div className="input-group my-3">
          <Select
            className="w-[80%]"
            isMulti={false}
            filterOption={customFilter}
            options={
              newProductServiceList &&
              newProductServiceList.length > 0 &&
              newProductServiceList?.map((data) => ({
                value: data?.product_name || data?.service_name || data?.bundle_name,
                label: data?.product_name || data?.service_name || data?.bundle_name,
                ...data,
              }))
            }
            // value={selectedDischargeInstructions}
            // // // isMulti={true}
            onChange={(e) => {
              let obj = JSON.parse(
                JSON.stringify(defaultOrderForDischarge[0])
              );

              obj.product_service_id = e._id;
              obj.product_service_name = e?.product_name || e.service_name

              pushFunc(obj);

              let dischargePatientData = JSON.parse(
                JSON.stringify(dischargePatientFormData)
              );
              dischargePatientData.order_for_discharge.push(obj);

              dispatch({
                type: "DISCHARGE_PATIENT_FORM_DATA",
                payload: { ...dischargePatientData },
              });
            }}
          />
        </div>
        <div className="order-btn"></div>
      </div>

      <div className="inner  w-[73%]">
        {/* {<h1>{JSON.stringify(dischargePatientFormData?.order_for_discharge)}</h1>} */}

        <Formik
          // ref={formikRef}
          innerRef={formikRef}
          initialValues={{
            forms: dischargePatientFormData?.order_for_discharge,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { validateForm }) => {
            validateForm().then((errors) => {
              console.log("ERRORS---", errors);
              if (Object.keys(errors).length === 0) {
                console.log("SETTING--- TRUE");
                props.setFormSubmitted(true);
              } else {
                console.log("SETTING--- false");
                props.setFormSubmitted(false);
              }
            });
            // resetFormFunc();
            // console.log("onSubmit", JSON.stringify(values, null, 2));
          }}
          enableReinitialize={true}

        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            isValid,
            setFieldValue,
            submitForm,
            resetForm,
            status,
            setTouched,
            validateForm
          }) => {
            resetFormFunc = resetForm;
            validateFormFun = validateForm
            // console.log("values 1----", values);
            // console.log("errors 1----", errors);
            // // console.log("STATUS", status)
            // console.log("touched", touched)
            // if (
            //   props.appointmentDischargeFormData &&
            //   Object.entries(props.appointmentDischargeFormData).length > 0
            // ) {
            //   props.setFormSubmitted(true);
            // } else {
            // if (touched && touched.forms && touched.forms.length > 0) {
            //   if (errors && errors.forms && errors.forms.length) {
            //     props.setFormSubmitted(false);
            //   } else {
            //     props.setFormSubmitted(true);
            //   }
            // } else {
            //   props.setFormSubmitted(false);
            // }
            // }
            if (errors && errors.forms && errors.forms.length) {
              props.setFormSubmitted(false);
            } else {
              props.setFormSubmitted(true);
            }
            return (
              <Form noValidate autoComplete="off">
                <FieldArray name="forms">
                  {({ push, remove }) => {
                    pushFunc = push;
                    return (
                      <div>
                        {values.forms &&
                          values.forms.map((p, index) => {
                            let selectedProductService =
                              productServiceList.find(
                                (ele) => ele._id == p.product_service_id
                              );

                            const dose = `forms[${index}].dose`;
                            const touchedDose = getIn(touched, dose);
                            const errorDose = getIn(errors, dose);

                            const form = `forms[${index}].form`;
                            const touchedForm = getIn(touched, form);
                            const errorForm = getIn(errors, form);

                            const rate_val = `forms[${index}].rate_val`;
                            const touchedRateVal = getIn(touched, rate_val);
                            const errorRateVal = getIn(errors, rate_val);

                            const rate_time = `forms[${index}].rate_time`;
                            const touchedRateTime = getIn(touched, rate_time);
                            const errorRateTime = getIn(errors, rate_time);

                            const route = `forms[${index}].route`;
                            const touchedRoute = getIn(touched, route);
                            const errorRoute = getIn(errors, route);

                            const prn = `forms[${index}].prn`;
                            const touchedPrn = getIn(touched, prn);
                            const errorPrn = getIn(errors, prn);

                            const frequency = `forms[${index}].frequency`;
                            const touchedfrequency = getIn(touched, frequency);
                            const errorfrequency = getIn(errors, frequency);

                            const start_datetime = `forms[${index}].start_datetime`;
                            const touchedStartDateTime = getIn(
                              touched,
                              start_datetime
                            );
                            const errorStartDateTime = getIn(
                              errors,
                              start_datetime
                            );

                            const second_dose = `forms[${index}].second_dose`;
                            const touchedSecondDose = getIn(
                              touched,
                              second_dose
                            );
                            const errorSecondDose = getIn(errors, second_dose);

                            const duration_num = `forms[${index}].duration_num`;
                            const touchedDurationNum = getIn(
                              touched,
                              duration_num
                            );
                            const errorDurationNum = getIn(
                              errors,
                              duration_num
                            );

                            const duration_unit = `forms[${index}].duration_unit`;
                            const touchedDurationUnit = getIn(
                              touched,
                              duration_unit
                            );
                            const errorDurationUnit = getIn(
                              errors,
                              duration_unit
                            );

                            const notes = `forms[${index}].notes`;
                            const touchedNotes = getIn(touched, notes);
                            const errorNotes = getIn(errors, notes);

                            // console.log("errors", errors)

                            return (
                              <div key={index}>
                                <div className="outer-discharge-table position-relative mb-3">
                                  <table className="w-full">
                                    <tr>
                                      <td className="vertical-top">
                                        Product/ Service
                                      </td>
                                      <td className="p-0">
                                        <span className="padding-start">
                                          {selectedProductService?.product_name ||
                                            selectedProductService?.service_name}
                                        </span>

                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="vertical-top">Dose</td>
                                      <td className="d-flex add-dose-input padding-start">
                                        {/* <InputGroup id="dose"> */}
                                        <span>
                                          <input
                                            type="number"
                                            label="Add Dose"
                                            name={dose}
                                            step="any"
                                            min="0"
                                            placeholder="Add Dose"
                                            value={p.dose}
                                            required
                                            helperText={
                                              touchedDose && errorDose
                                                ? errorDose
                                                : ""
                                            }
                                            error={Boolean(
                                              touchedDose && errorDose
                                            )}
                                            onChange={(e) => {
                                              handleChange(e);
                                              handleInputChange(
                                                parseFloat(e.target.value),
                                                "dose",
                                                index
                                              );
                                            }}
                                            onBlur={handleBlur}
                                          />
                                          {touchedDose && errorDose ? (
                                            <div className="text-danger">
                                              {errorDose}
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                        <span>
                                          <select
                                            placeholder="Select Form"
                                            label="Select Unit"
                                            name={form}
                                            min="1"
                                            value={p.form}
                                            required
                                            helperText={
                                              touchedForm && errorForm
                                                ? errorForm
                                                : ""
                                            }
                                            error={Boolean(
                                              touchedForm && errorForm
                                            )}
                                            onChange={(e) => {
                                              handleChange(e);
                                              handleInputChange(
                                                e.target.value,
                                                "form",
                                                index
                                              );
                                            }}
                                            onBlur={handleBlur}
                                          >
                                            <option>Select Form</option>
                                            <option>mL</option>
                                            <option>L</option>
                                            <option>tablet</option>
                                            <option>capsule</option>
                                            <option>mg</option>
                                            <option>g</option>
                                            <option>patch</option>
                                            <option>spray</option>
                                            <option>drop</option>
                                          </select>
                                          {touchedForm && errorForm ? (
                                            <div className="text-danger">
                                              {errorForm}
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </td>
                                    </tr>

                                    {selectedProductService?.category.toLowerCase() ===
                                      "vaccine" ||
                                      selectedProductService?.category.toLowerCase() ===
                                      "infusion" ||
                                      selectedProductService?.category.toLowerCase() ===
                                      "medications" ? (
                                      <>
                                        {selectedProductService?.category.toLowerCase() ===
                                          "Infusion" && (
                                            <tr row>
                                              <td className="vertical-top">
                                                Rate
                                              </td>
                                              <td>
                                                {/* <InputGroup id="rate"> */}
                                                <input
                                                  type="number"
                                                  label="Add Rate Value"
                                                  name={rate_val}
                                                  min="1"
                                                  placeholder="Add Rate Value"
                                                  value={p.rate_val}
                                                  required
                                                  helperText={
                                                    touchedRateVal && errorRateVal
                                                      ? errorRateVal
                                                      : ""
                                                  }
                                                  error={Boolean(
                                                    touchedRateVal && errorRateVal
                                                  )}
                                                  onChange={(e) => {
                                                    handleChange(e);
                                                    handleInputChange(
                                                      e.target.value,
                                                      "rate_val",
                                                      index
                                                    );
                                                  }}
                                                  onBlur={handleBlur}
                                                />
                                                {touchedRateVal &&
                                                  errorRateVal ? (
                                                  <div className="text-danger">
                                                    {errorRateVal}
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                                <select
                                                  placeholder="Select Unit"
                                                  label="Select Unit"
                                                  name={rate_time}
                                                  value={p.rate_time}
                                                  required
                                                  helperText={
                                                    touchedRateTime &&
                                                      errorRateTime
                                                      ? errorRateTime
                                                      : ""
                                                  }
                                                  error={Boolean(
                                                    touchedRateTime &&
                                                    errorRateTime
                                                  )}
                                                  onChange={(e) => {
                                                    handleChange(e);
                                                    handleInputChange(
                                                      e.target.value,
                                                      "rate_time",
                                                      index
                                                    );
                                                  }}
                                                  onBlur={handleBlur}
                                                >
                                                  <option>
                                                    Select Rate Duration
                                                  </option>
                                                  <option>mL/hr</option>
                                                  <option>L/hr</option>
                                                </select>

                                                {touchedRateTime &&
                                                  errorRateTime ? (
                                                  <div className="text-danger">
                                                    {errorRateTime}
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </td>
                                            </tr>
                                          )}

                                        <tr>
                                          <td className="vertical-top">
                                            Route
                                          </td>
                                          <td>
                                            <select
                                              placeholder="Select Route"
                                              label="Select Route"
                                              name={route}
                                              value={p.route}
                                              required
                                              helperText={
                                                touchedRoute && errorRoute
                                                  ? errorRoute
                                                  : ""
                                              }
                                              error={Boolean(
                                                touchedRoute && errorRoute
                                              )}
                                              onChange={(e) => {
                                                handleChange(e);
                                                handleInputChange(
                                                  e.target.value,
                                                  "route",
                                                  index
                                                );
                                              }}
                                              onBlur={handleBlur}
                                              id="route"
                                            >
                                              <option>Select Route</option>
                                              <option>IM</option>
                                              <option>IV</option>
                                              <option>IV Push</option>
                                              <option>PO</option>
                                              <option>SubQ</option>
                                              <option>Rectal</option>
                                              <option>Inhalation</option>
                                              <option>Nasal</option>
                                              <option>Ocular</option>
                                              <option>Otic</option>
                                              <option>Topical</option>
                                              <option>Transdermal</option>
                                            </select>

                                            {touchedRoute && errorRoute ? (
                                              <div className="text-danger">
                                                {errorRoute}
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                        </tr>

                                        <tr>
                                          <td className="vertical-top">PRN</td>
                                          <td>
                                            <input
                                              type="checkbox"
                                              name={prn}
                                              value={
                                                p.prn == "false" ? false : true
                                              }
                                              required
                                              helperText={
                                                touchedPrn && errorPrn
                                                  ? errorPrn
                                                  : ""
                                              }
                                              error={Boolean(
                                                touchedPrn && errorPrn
                                              )}
                                              checked={
                                                p.prn == "false" ? false : true
                                              }
                                              onChange={(e) => {
                                                console.log(
                                                  "e.target.value",
                                                  e.target.value
                                                );
                                                e.target.value =
                                                  e.target.value == "false"
                                                    ? true
                                                    : false;
                                                handleChange(e);
                                                handleInputChange(
                                                  e.target.value,
                                                  "prn",
                                                  index
                                                );
                                              }}
                                              onBlur={handleBlur}
                                              id="prn"
                                            />
                                            {touchedPrn && errorPrn ? (
                                              <div className="text-danger">
                                                {errorPrn}
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                        </tr>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {selectedProductService?.category.toLowerCase() !==
                                      "vaccine" && (
                                        <tr>
                                          <td className="vertical-top">
                                            Frequency
                                          </td>
                                          <td sm={8} className="p-0">
                                            <select
                                              placeholder="Select Frequency"
                                              label="Select Frequency"
                                              name={frequency}
                                              value={p.frequency}
                                              required
                                              helperText={
                                                touchedfrequency && errorfrequency
                                                  ? errorfrequency
                                                  : ""
                                              }
                                              error={Boolean(
                                                touchedfrequency && errorfrequency
                                              )}
                                              onChange={(e) => {
                                                handleChange(e);
                                                handleInputChange(
                                                  e.target.value,
                                                  "frequency",
                                                  index
                                                );
                                              }}
                                              onBlur={handleBlur}
                                              id="frequency"
                                            >
                                              <option>Select Frequency</option>
                                              <option>q5m</option>
                                              <option>q15m</option>
                                              <option>q30m</option>
                                              <option>q2h</option>
                                              <option>q4h</option>
                                              <option>q6h</option>
                                              <option>q8h</option>
                                              <option>q12h</option>
                                              <option>q24h</option>
                                              <option>daily</option>
                                              <option>daily at bedtime</option>
                                              <option>BID</option>
                                              <option>TID</option>
                                              <option>QID</option>
                                              <option>every other day</option>
                                              <option>once</option>
                                            </select>
                                            {touchedfrequency &&
                                              errorfrequency ? (
                                              <div className="text-danger">
                                                {errorfrequency}
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                        </tr>
                                      )}

                                    <tr>
                                      <td className="vertical-top">{`${selectedProductService?.category.toLowerCase() ===
                                        "vaccine" ||
                                        selectedProductService?.category.toLowerCase() ===
                                        "infusion" ||
                                        selectedProductService?.category.toLowerCase() ===
                                        "medications"
                                        ? "First Dose"
                                        : "Start"
                                        } Date/Time`}</td>
                                      <td>
                                        {
                                          <span className="padding-start">
                                            {" "}
                                            <DatePicker
                                              selected={
                                                p.start_datetime &&
                                                p.start_datetime != "" &&
                                                new Date(p.start_datetime)
                                              }
                                              onChange={(date) => {
                                                // Set the selected date in Formik
                                                setFieldValue(
                                                  start_datetime,
                                                  date
                                                );

                                                handleInputChange(
                                                  date,
                                                  "start_datetime",
                                                  index
                                                );
                                              }}
                                              onBlur={handleBlur}
                                              showTimeSelect
                                              timeFormat="hh:mm aa"
                                              timeIntervals={15}
                                              placeholderText="Select Date"
                                              dateFormat="MMMM d, yyyy h:mm aa"
                                              className="d-block absoulte date-blocker w-auto max-[600px]:min-w-[330px]"
                                            />
                                            {touchedStartDateTime &&
                                              errorStartDateTime ? (
                                              <div className="text-danger">
                                                {errorStartDateTime}
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                        }
                                      </td>
                                    </tr>

                                    {selectedProductService?.category.toLowerCase() ===
                                      "vaccine" && (
                                        <tr>
                                          <td className="vertical-top">
                                            Second Dose Date/Time
                                          </td>
                                          <td className="padding-start">
                                            <DatePicker
                                              selected={
                                                p.second_dose &&
                                                p.second_dose != "" &&
                                                new Date(p.second_dose)
                                              }
                                              onChange={(date) => {
                                                // Set the selected date in Formik
                                                setFieldValue(second_dose, date);
                                                handleInputChange(
                                                  date,
                                                  "second_dose",
                                                  index
                                                );
                                              }}
                                              onBlur={handleBlur}
                                              showTimeSelect
                                              timeFormat="hh:mm aa"
                                              timeIntervals={15}
                                              placeholderText="Select Date"
                                              dateFormat="MMMM d, yyyy h:mm aa"
                                              className="d-block absoulte date-blocker w-auto max-[600px]:min-w-[330px] "
                                            />
                                            {touchedSecondDose &&
                                              errorSecondDose ? (
                                              <div className="text-danger">
                                                {errorSecondDose}
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                        </tr>
                                      )}

                                    {selectedProductService?.category.toLowerCase() !==
                                      "vaccine" &&
                                      selectedProductService?.frequency?.toLowerCase() !==
                                      "once" && (
                                        <tr>
                                          <td className="vertical-top">
                                            Duration:
                                          </td>
                                          <td className="duration-input padding-start d-flex">
                                            {/* <InputGroup id="duration"> */}
                                            <div>

                                              <input
                                                name={duration_num}
                                                value={p.duration_num}
                                                required
                                                helperText={
                                                  touchedDurationNum &&
                                                    errorDurationNum
                                                    ? errorDurationNum
                                                    : ""
                                                }
                                                error={Boolean(
                                                  touchedDurationNum &&
                                                  errorDurationNum
                                                )}
                                                onChange={(e) => {
                                                  handleChange(e);
                                                  handleInputChange(
                                                    e.target.value,
                                                    "duration_num",
                                                    index
                                                  );
                                                }}
                                                onBlur={handleBlur}
                                                label="Add Duration"
                                                type="number"
                                                min="1"
                                                placeholder="Add Duration"
                                              />

                                              {touchedDurationNum &&
                                                errorDurationNum ? (
                                                <div className="text-danger">
                                                  {errorDurationNum}
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <div>
                                              <select
                                                label="Select Duration"
                                                name={duration_unit}
                                                value={p.duration_unit}
                                                required
                                                helperText={
                                                  touchedDurationUnit &&
                                                    errorDurationUnit
                                                    ? errorDurationUnit
                                                    : ""
                                                }
                                                error={Boolean(
                                                  touchedDurationUnit &&
                                                  errorDurationUnit
                                                )}
                                                onChange={(e) => {
                                                  handleChange(e);
                                                  handleInputChange(
                                                    e.target.value,
                                                    "duration_unit",
                                                    index
                                                  );
                                                }}
                                                onBlur={handleBlur}
                                                placeholder="Select Duration"
                                              >
                                                <option>Select Unit</option>
                                                <option>Days</option>
                                                <option>Weeks</option>
                                              </select>

                                              {touchedDurationUnit &&
                                                errorDurationUnit ? (
                                                <div className="text-danger">
                                                  {errorDurationUnit}
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            {/* </InputGroup> */}
                                          </td>
                                        </tr>
                                      )}

                                    <tr>
                                      <td className="vertical-top">Notes:</td>
                                      <td className="padding-start">
                                        <input
                                          name={notes}
                                          value={p.notes}
                                          required
                                          helperText={
                                            touchedNotes && errorNotes
                                              ? errorNotes
                                              : ""
                                          }
                                          error={Boolean(
                                            touchedNotes && errorNotes
                                          )}
                                          onChange={(e) => {
                                            handleChange(e);
                                            handleInputChange(
                                              e.target.value,
                                              "notes",
                                              index
                                            );
                                          }}
                                          onBlur={handleBlur}
                                          label="Add Notes"
                                          type="text"
                                          placeholder="Add Notes"
                                        />

                                        {touchedNotes && errorNotes ? (
                                          <div className="text-danger">
                                            {errorNotes}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                    </tr>
                                  </table>
                                  <span
                                    className="icomoon-trash order-del"
                                    onClick={() => {
                                      remove(index);
                                      removeItemFromRedux(index);
                                    }}
                                  ></span>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  }}
                </FieldArray>

                {debug && (
                  <>
                    <pre style={{ textAlign: "left" }}>
                      <strong>Values</strong>
                      <br />
                      {JSON.stringify(values, null, 2)}
                    </pre>
                    <pre style={{ textAlign: "left" }}>
                      <strong>Errors</strong>
                      <br />
                      {JSON.stringify(errors, null, 2)}
                    </pre>
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
});

export default Index;


