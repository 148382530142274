import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import TextInput from "../../../../shared/FormsInput/TextBox";
import PetPlaceholder from "../../../../shared/PetPlaceholder";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { addPetInitialValue, addPetSchema } from "./schema";
import {
  getAllBreeds,
  getAllSpecies,
} from "../../../../context/actions/app-content";
import { petGenderValues } from "../../../../utils/constants";
import ProfileAllergy from "./ProfileAllergy";
import ProfileTags from "./ProfileTags";
import { v4 as uuidv4 } from "uuid";
import { AiOutlineFile } from "react-icons/ai";
import UploadImageBlock from "../../../../shared/UploadImageBlock";
import { useClinicSpecies } from "hooks/useClinicSpecies";
import { useClinicBreed } from "hooks/useClinicBreed";

const PetInfo = ({
  step,
  onFileChange,
  file,
  onSubmit = () => { },
  onBack = () => { },
}) => {
  const { behaviourTags, allergiesTags } = useSelector(
    (state) => state.appContentReducer
  );
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const dispatch = useDispatch();
  const { petAndOwnerDetails, petFormData } = useSelector(
    (state) => state.petReducer
  );
  const { species, breeds } = useSelector((state) => state.appContentReducer);
  const [initialValue, setInitialValue] = useState(addPetInitialValue);
  const [loading, setLoading] = useState(true);

  const clinicBreed = useClinicBreed();

  const clinicSpecies = useClinicSpecies();

  useEffect(() => {
    dispatch(
      getAllSpecies({}, (res) => {
        if (res?.status) {
          dispatch(getAllBreeds(species[0]._id, () => { }));
        }
      })
    );
  }, []);

  useEffect(() => {
    console.log("file is", file);
    if (file) {
      if (imageFile) {
        URL.revokeObjectURL(imageFile);
      }
      setImageFile(URL.createObjectURL(file));
    }
  }, [file]);

  useEffect(() => {
    if (petAndOwnerDetails) {
      setInitialValue(
        (p) =>
        (p = {
          pet_name: petAndOwnerDetails?.pet_details?.pet_name,
          dob: petAndOwnerDetails?.pet_details?.dob,
          gender: petAndOwnerDetails?.pet_details?.gender,
          species: petAndOwnerDetails?.pet_details?.species,
          breed: petAndOwnerDetails?.pet_details?.breed,
          color: petAndOwnerDetails?.pet_details?.color,
          pet_notes: petAndOwnerDetails?.pet_details?.pet_notes,
          allergy_tag: petAndOwnerDetails?.pet_details?.allergy_tag,
          behaviour_tag: petAndOwnerDetails?.pet_details?.behaviour_tag,
        })
      );
      setImageUrl(petAndOwnerDetails?.pet_details?.pet_image);
    } else if (petFormData?.pet_name) {
      //data from redux state
      setInitialValue(
        (p) =>
        (p = {
          ...petFormData,
        })
      );
    } else {
      setInitialValue(
        (p) =>
        (p = {
          ...p,
          species: species?.length > 0 ? species[0]._id : "",
          breed: breeds?.length > 0 ? breeds[0]._id : "",
          //allergy_tag: allergiesTags?.length > 0 ? allergiesTags[0].tag : '',
          //behaviour_tag: behaviourTags?.length > 0 ? behaviourTags[0].tag : '',
        })
      );
    }
    setLoading(false);
  }, [petAndOwnerDetails]);

  const handleChangeSpecies = (e) => {
    let value = e.target.value;
    if (value) {
      dispatch(getAllBreeds(value, () => { }));
    }
  };

  const [selectedfile, SetSelectedFile] = useState([]);
  const [Files, SetFiles] = useState([]);

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const InputChange = (e) => {
    // --For Multiple File Input
    console.log("e.target.files", e.target.files);
    let selectedFiles = e.target.files;
    let images = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      images.push(selectedFiles[i]);
      let reader = new FileReader();
      let file = selectedFiles[i];
      reader.onloadend = () => {
        SetSelectedFile((preValue) => {
          return [
            ...preValue,
            {
              id: uuidv4(),
              filename: selectedFiles[i].name,
              filetype: selectedFiles[i].type,
              fileimage: reader.result,
              datetime:
                selectedFiles[i].lastModifiedDate.toLocaleString("en-IN"),
              filesize: filesizes(selectedFiles[i].size),
            },
          ];
        });
      };
      if (selectedFiles[i]) {
        reader.readAsDataURL(file);
      }
    }
  };

  const DeleteSelectFile = (id) => {
    if (window.confirm("Are you sure you want to delete this Image?")) {
      const result = selectedfile.filter((data) => data.id !== id);
      SetSelectedFile(result);
    } else {
      // alert('No');
    }
  };

  const FileUploadSubmit = async (e) => {
    e.preventDefault();

    // form reset on submit
    e.target.reset();
    if (selectedfile.length > 0) {
      for (let index = 0; index < selectedfile.length; index++) {
        SetFiles((preValue) => {
          return [...preValue, selectedfile[index]];
        });
      }
      SetSelectedFile([]);
    } else {
      alert("Please select file");
    }
  };

  const DeleteFile = async (id) => {
    if (window.confirm("Are you sure you want to delete this Image?")) {
      const result = Files.filter((data) => data.id !== id);
      SetFiles(result);
    } else {
      // alert('No');
    }
  };

  return (
    <>
      {!loading && (
        <Formik
          initialValues={initialValue}
          validationSchema={addPetSchema}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <div className="pet-info-block d-flex">
                <div className="left-col flex-fill">
                  <div className="description-blc">
                    <div className="row-block d-flex">
                      <label className="required-input">Patient Name </label>
                      <div className="right-col flex-fill">
                        <TextInput
                          type="text"
                          name="pet_name"
                          id="pet_name"
                          placeholder="Enter Pet Name"
                          isRequired={true}
                          label="Pet Name"
                          className="mb-0"
                          labelVisible={false}
                          value={formik?.values?.pet_name}
                          onChange={formik.handleChange}
                        />
                        {formik?.errors?.pet_name &&
                          formik?.touched?.pet_name &&
                          formik?.errors?.pet_name}
                      </div>
                    </div>
                    <div className="row-block d-flex">
                      <label className="required-input">Date of Birth</label>
                      <div className="right-col flex-fill">
                        <Input
                          type="date"
                          name="dob"
                          id="dob"
                          placeholder="Enter Date of Birth"
                          label="Date of Birth"
                          className="mb-0"
                          value={formik?.values?.dob}
                          onChange={formik.handleChange}
                        />
                        {formik?.errors?.dob &&
                          formik?.touched?.dob &&
                          formik?.errors?.dob}
                      </div>
                    </div>
                    <div className="row-block d-flex">
                      <label className="required-input">Species</label>
                      <div className="right-col flex-fill">
                        <FormGroup>
                          <Input
                            type="select"
                            name="species"
                            id="species"
                            isRequired={true}
                            value={formik?.values?.species || species[0]}
                            onChange={(e) => {
                              handleChangeSpecies(e);
                              formik.handleChange(e);
                            }}
                          >
                            {(species || []).map((item, i) => (
                              <option
                                key={i}
                                value={item._id}
                                style={{ textTransform: "capitalize" }}
                              >
                                {clinicSpecies(item?._id) || item.species_name}
                              </option>
                            ))}
                          </Input>
                          {formik?.errors?.species &&
                            formik?.touched?.species &&
                            formik?.errors?.species}
                        </FormGroup>
                      </div>
                    </div>
                    {/* <div className="row-block d-flex">
										<label className="required-input">Animal Type</label>
										<div className="right-col flex-fill">
											<TextInput
												type="text"
												name="species"
												id="species"
												placeholder="Enter Animal Type"
												isRequired={true}
												label="Animal Type"
												className="mb-0"
												labelVisible={false}
												value={formik?.values?.species}
												onChange={formik.handleChange}

											/>
											{formik?.errors?.species && formik?.touched?.species && formik?.errors?.species}
										</div>
									</div> */}
                    {/* <div className="row-block d-flex">
										<label className="required-input">Gender</label>
										<div className="right-col flex-fill">
											<TextInput
												type="text"
												name="gender"
												id="gender"
												placeholder="Enter Gender"
												isRequired={true}
												label="Gender"
												className="mb-0"
												labelVisible={false}
												value={formik?.values?.gender}
												onChange={formik.handleChange}
											/>
											{formik?.errors?.gender && formik?.touched?.gender && formik?.errors?.gender}
										</div>
									</div> */}

                    <div className="row-block d-flex">
                      <label className="required-input">Gender</label>
                      <div className="right-col flex-fill">
                        <FormGroup>
                          <Input
                            type="select"
                            name="gender"
                            id="gender"
                            isRequired={true}
                            value={
                              formik?.values?.gender || petGenderValues[0].value
                            }
                            onChange={formik.handleChange}
                          >
                            {(petGenderValues || []).map((item, i) => (
                              <option
                                key={i}
                                value={item.value}
                                style={{ textTransform: "capitalize" }}
                              >
                                {item.label}
                              </option>
                            ))}
                          </Input>
                          {formik?.errors?.gender &&
                            formik?.touched?.gender &&
                            formik?.errors?.gender}
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row-block d-flex">
                      <label className="required-input">Breed</label>
                      <div className="right-col flex-fill">
                        <FormGroup>
                          <Input
                            type="select"
                            name="breed"
                            id="breed"
                            isRequired={true}
                            value={formik?.values?.breed}
                            onChange={formik.handleChange}
                          >
                            {(breeds || []).map((item, i) => (
                              <option
                                key={i}
                                value={item._id}
                                style={{ textTransform: "capitalize" }}
                              >
                                {clinicBreed(item?._id) ||  item.breed}
                              </option>
                            ))}
                          </Input>
                          {formik?.errors?.breed &&
                            formik?.touched?.breed &&
                            formik?.errors?.breed}
                        </FormGroup>
                      </div>
                    </div>
                    {/* <div className="row-block d-flex">
										<label className="required-input">Breed</label>
										<div className="right-col flex-fill">
											<TextInput
												type="text"
												name="breed"
												id="breed"
												placeholder="Enter Breed"
												isRequired={true}
												label="Breed"
												className="mb-0"
												labelVisible={false}
												value={formik?.values?.breed}
												onChange={formik.handleChange}
											/>
											{formik?.errors?.breed && formik?.touched?.breed && formik?.errors?.breed}
										</div>
									</div> */}
                    <div className="row-block d-flex">
                      <label className="required-input">Color</label>
                      <div className="right-col flex-fill">
                        <TextInput
                          type="text"
                          name="color"
                          id="color"
                          placeholder="Enter Color"
                          isRequired={true}
                          className="mb-0"
                          labelVisible={false}
                          value={formik?.values?.color}
                          onChange={formik.handleChange}
                        />
                        {formik?.errors?.color &&
                          formik?.touched?.color &&
                          formik?.errors?.color}
                      </div>
                    </div>
                    <div className="row-block d-flex">
                      <label className="input">Rabies Tag Number</label>
                      <div className="right-col flex-fill">
                        <TextInput
                          type="text"
                          name="rabies_number"
                          id="rabies_number"
                          placeholder="Enter Rabies Tag Number"
                          isRequired={false}
                          className="mb-0"
                          labelVisible={false}
                          value={formik?.values?.rabies_number}
                          onChange={formik.handleChange}
                        />
                        {formik?.errors?.rabies_number &&
                          formik?.touched?.rabies_number &&
                          formik?.errors?.rabies_number}
                      </div>
                    </div>
                    <div className="row-block d-flex">
                      <label className="input">Microchip Number</label>
                      <div className="right-col flex-fill">
                        <TextInput
                          type="text"
                          name="microchip_number"
                          id="microchip_number"
                          placeholder="Enter Microchip Number"
                          isRequired={false}
                          className="mb-0"
                          labelVisible={false}
                          value={formik?.values?.microchip_number}
                          onChange={formik.handleChange}
                        />
                        {formik?.errors?.microchip_number &&
                          formik?.touched?.microchip_number &&
                          formik?.errors?.microchip_number}
                      </div>
                    </div>
                    <div className="row-block d-flex">
                      <label className="input">License Number</label>
                      <div className="right-col flex-fill">
                        <TextInput
                          type="text"
                          name="license_number"
                          id="license_number"
                          placeholder="Enter License Number"
                          isRequired={false}
                          className="mb-0"
                          labelVisible={false}
                          value={formik?.values?.license_number}
                          onChange={formik.handleChange}
                        />
                        {formik?.errors?.license_number &&
                          formik?.touched?.license_number &&
                          formik?.errors?.license_number}
                      </div>
                    </div>
                    <div className="row-block d-flex">
                      <label className="input">CPR Status</label>
                      <div className="right-col flex-fill">
                        <TextInput
                          type="text"
                          name="cpr"
                          id="cpr"
                          placeholder="Enter CPR Status"
                          isRequired={false}
                          className="mb-0"
                          labelVisible={false}
                          value={formik?.values?.cpr}
                          onChange={formik.handleChange}
                        />
                        {formik?.errors?.cpr &&
                          formik?.touched?.cpr &&
                          formik?.errors?.cpr}
                      </div>
                    </div>
                    <ProfileAllergy
                      setAllergies={(val) =>
                        formik.setFieldValue("allergy_tag", val)
                      }
                      allergies={formik?.values?.allergy_tag}
                    />
                    <ProfileTags
                      setBehaviourTags={(val) =>
                        formik.setFieldValue("behaviour_tag", val)
                      }
                      behaviour_tags={formik?.values?.behaviour_tag}
                    />
                    {/*
									<div className="row-block d-flex">
										<label>Behaviour Tags</label>
										<div className="right-col flex-fill">
											<FormGroup>
												<Input type="select" name="behaviour_tag" id="behaviour_tag" value={formik?.values?.behaviour_tag} onChange={formik.handleChange}>
													{(behaviourTags || []).map((item, i) =>
														<option key={i} value={item.tag} style={{ textTransform: "capitalize" }}>{item.tag}</option>
													)}
												</Input>
												{formik?.errors?.behaviour_tag && formik?.touched?.behaviour_tag && formik?.errors?.behaviour_tag}
											</FormGroup>
										</div>
									</div>
									<div className="row-block d-flex">
										<label>Allergies</label>
										<div className="right-col flex-fill">
											<FormGroup>
												<Input type="select" name="allergy_tag" id="allergy_tag" value={formik?.values?.allergy_tag} onChange={formik.handleChange}>
													{(allergiesTags || []).map((item, i) =>
														<option key={i} value={item.tag} style={{ textTransform: "capitalize" }}>{item.tag}</option>
													)}
												</Input>
												{formik?.errors?.allergy_tag && formik?.touched?.allergy_tag && formik?.errors?.allergy_tag}
											</FormGroup>
										</div>
									</div>*/}
                    <div className="row-block d-flex">
                      <label>Patient Notes (For office use only)</label>
                      <div className="right-col flex-fill">
                        <TextInput
                          type="textarea"
                          name="pet_notes"
                          id="pet_notes"
                          placeholder="Enter Patient Notes"
                          isRequired={true}
                          className="mb-0"
                          labelVisible={false}
                          value={formik?.values?.pet_notes}
                          onChange={formik.handleChange}
                        />
                        {formik?.errors?.pet_notes &&
                          formik?.touched?.pet_notes &&
                          formik?.errors?.pet_notes}
                      </div>
                    </div>

                    {/* 
									<div className="row-block d-flex">
										<label>Select Documents</label>
										<div className="right-col flex-fill">

											<div className="kb-data-box">
												<form onSubmit={FileUploadSubmit}>
													<div className="kb-file-upload">
														<div className="file-upload-box">
															<input type="file" id="fileupload" className="file-upload-input" onChange={InputChange} multiple />
															<span>Drag and drop or <span className="file-link">Choose your files</span></span>
														</div>
													</div>
													<div className="kb-attach-box mb-3">
														{
															selectedfile.map((data, index) => {
																const { id, filename, filetype, fileimage, datetime, filesize } = data;
																return (
																	<div className="file-atc-box" key={id}>
																		{
																			filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
																				<div className="file-image"> <img src={fileimage} alt="" /></div> :
																				<div className="file-image">
																					<AiOutlineFile />
																				</div>
																		}
																		<div className="file-detail">
																			<h6>{filename}</h6>
																			<p></p>
																			<p><span>Size : {filesize}</span><span className="ml-2">Modified Time : {datetime}</span></p>
																			<div className="file-actions">
																				<button type="button" className="file-action-btn" onClick={() => DeleteSelectFile(id)}>Delete</button>
																			</div>
																		</div>
																	</div>
																)
															})
														}
													</div>
													<div className="kb-buttons-box">
														<button type="submit" className="btn btn-primary form-submit">Upload</button>
													</div>
												</form>
												{Files.length > 0 ?
													<div className="kb-attach-box">
														<hr />
														{
															Files.map((data, index) => {
																const { id, filename, filetype, fileimage, datetime, filesize } = data;
																return (
																	<div className="file-atc-box" key={index}>
																		{
																			filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
																				<div className="file-image"> <img src={fileimage} alt="" /></div> :
																				<div className="file-image">
																					<AiOutlineFile />
																				</div>
																		}
																		<div className="file-detail">
																			<h6>{filename}</h6>
																			<p><span>Size : {filesize}</span><span className="ml-3">Modified Time : {datetime}</span></p>
																			<div className="file-actions">
																				<button className="file-action-btn" onClick={() => DeleteFile(id)}>Delete</button>
																				<a href={fileimage} className="file-action-btn" download={filename}>Download</a>
																			</div>
																		</div>
																	</div>
																)
															})
														}
													</div>
													: ''}
											</div>
										</div>
									</div> */}
                  </div>
                </div>
                <div className="right-col">
                  <div className="profile-cell text-center">
                    <h4>Profile Picture</h4>
                    <div className="pic-cell pet">
                      <div className="pic d-inline-flex overflow-hidden position-relative">
                        <span className="upload-cell position-absolute w-100 h-100 d-inline-flex align-items-center justify-content-center">
                          {/*
												<Input type="file" className="position-absolute" onChange={onFileChange} />
												<FontAwesomeIcon icon={faCamera} />*/}
                          <UploadImageBlock
                            petImage={imageFile}
                            sendPetImage={(value) => {
                              console.log("sendPetImage", value);
                              setImageFile(value);
                            }}
                            petImageObj={(value) => {
                              console.log("petImageObj", value);
                              onFileChange(value);
                            }}
                          />
                        </span>
                        {imageUrl || imageFile ? (
                          <img
                            className="img-fluid w-100 h-100"
                            src={imageUrl || imageFile}
                            alt="Pet"
                          />
                        ) : (
                          <PetPlaceholder style={{ borderRadius: "10px" }} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {step == 1 && (
                <div className="proceed-btns d-flex align-items-center justify-content-between">
                  <Button
                    color="link"
                    onClick={onBack}
                    className="btn btn-outline-light"
                  >
                    Back
                  </Button>
                  <Button
                    color="success"
                    type="submit"
                    className="btn btn-outline-light"
                  >
                    Save & Proceed
                  </Button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default PetInfo;
