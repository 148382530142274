import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import { updateVitalToggle } from "../../../context/actions/vital-toggle";
import NoDataFound from "../NoDataFound";
import { vitalKeys } from "../../../utils/constants";



const Vitals = () => {
  const { testResults: vitals, vitalsListing } = useSelector(
    (state) => state.vitalTestingReducer
  );
  const userDetails = useSelector((state) => state.user.user_details);
  const { dischargePatientFormData } = useSelector(
    (state) => state.dischargePatientReducer
  );

  const dispatch = useDispatch();

  const { vitalsToggles } = useSelector((state) => state.vitalsTogglesReducer);

  const handleToggle = (key, value) => {
    dispatch({
      type: "UPDATE_VITALS_TOGGLES",
      payload: { ...vitalsToggles, [key]: value },
    });

    dispatch(
      updateVitalToggle(
        {
          [key]: value,
          discharge_patient_form_id: dischargePatientFormData._id,
          created_by: userDetails._id,
        },
        () => {}
      )
    );
  };


  return (
    <div className="table-responsive">
      {vitalsListing && Array.isArray(vitalsListing) && vitalsListing.length > 0 ? (
        <table className="vital-table-responsive">
          <thead>
            <tr>
              <th style={{ width: "10px" }}></th>
              <th>
                <p>Test Name</p>
              </th>
              {vitalsListing &&
                Array.isArray(vitalsListing) &&
                vitalsListing.length > 0 &&
                vitalsListing.map((ele) => {
                  return (ele?.results).map((res, j) => {
                    let date = new Date(res?.date);
                    return (
                      <th key={j}>
                        <p>
                          {date.toLocaleDateString("en-US")} <br />
                          {date.toLocaleTimeString("en-US")}
                        </p>
                      </th>
                    );
                  });
                })}
            </tr>
          </thead>

          <tbody>
            {vitalKeys?.map((label, j) => {
              return (
                // <div>
                <tr key={j}>
                  <td style={{ width: "10px" }}>
                    <div className="toggle-item-blc">
                      <div className="item-rw d-flex">
                        <div className="form-check form-switch">
                          <input
                            checked={!!vitalsToggles[label.key]}
                            onClick={(e) => {
                              handleToggle([label.key], e.target.checked);
                            }}
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                          />
                        </div>
                      </div>
                    </div>
                  </td>

                  <td>{label?.name}</td>

                  {vitalsListing &&
                    Array.isArray(vitalsListing) &&
                    vitalsListing?.length > 0 &&
                    vitalsListing.map((ele) => {
                      return (ele?.results).map((res, k) => {
                        return (
                          <td key={k}>
                            {res?.[label?.key] ? res?.[label?.key] : "--"}
                          </td>
                        );
                      });
                    })}
                </tr>
                // </div>
              );
            })}
          </tbody>
        </table>
      ) : (
        <NoDataFound />
      )}
    </div>
  );
};

export default Vitals;
