import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button, ButtonDropdown, DropdownToggle, Table } from "reactstrap";
import { getServicesByClinicId } from "../../../context/actions/service";
import CheckBox from "../../../shared/CheckBox";
import NodataFoundBlock from "../../../shared/NoDataFoundBlock";
import PaginationBlock from "../../../shared/PaginationBlock";
import UserPlaceholder from "../../../shared/UserPlaceholder";
import { dateFromDateTime } from "../../../utils/common";
import ServiceFilters from "./ServiceFilters";
import { getAllInventoryCategories } from "context/actions/app-content";
import useDebounce from "hooks/useDebounce";

const Services = ({ handleOpenDrawerButton }) => {
  const history = useHistory();
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const dispatch = useDispatch();
  const inventory = useSelector((state) => state.inventoryReducer.services);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const userDetails = useSelector((state) => state.user.user_details);
  const {clinic_details} = useSelector((state) => state.clinicReducer);
  const [inventoryData, setInventoryData] = useState([]);
  const [filterData, setFilterData] = useState({
    search: "",
    categoryId: "",
  });
  const searchDebouncedValue = useDebounce(filterData.search, 300);
  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  useEffect(() => {
    dispatch(getAllInventoryCategories((res) => {}));
  }, [])
  
  useEffect(() => {
    dispatch(
      getServicesByClinicId(/*userDetails?._id*/ clinic_details?._id, {page, limit,...filterData}, (res) => {
        setInventoryData(res);
      })
    );
  }, [dispatch, clinic_details?._id /*userDetails?._id*/, page, limit, filterData.categoryId,searchDebouncedValue]);

  return (
    <>
      <div className="product-list-wrapper">
        <ServiceFilters setFilterData={setFilterData} />
        {inventoryData?.docs?.length > 0 ? (
          <div className="table-listing-blc overflow-auto">
            <Table striped responsive className="checkbox-listing">
              <thead>
                <tr>
                  <th>
                    <CheckBox
                      type="checkbox"
                      ischecked={false}
                      className="no-label"
                    />
                  </th>
                  <th style={{width: '40%'}}>Name</th>
                  <th style={{width: '40%'}}>Category</th>
                  <th style={{ minWidth: "130px" }}>Amount</th>
                  {/* <th style={{ minWidth: "100px" }}>Patient</th>
                  <th style={{ minWidth: "80px" }}>Placed By</th>
                  <th>Notes</th> */}
                  {permissions?.edit_services &&
                    <th className="text-end">&nbsp;</th>
                  }
                </tr>
              </thead>
              <tbody>
                {inventoryData?.docs?.map((item, i) => (
                  <tr key={i}>
                    <td>
                      <CheckBox
                        type="checkbox"
                        ischecked={false}
                        className="no-label"
                      />
                    </td>
                    <td>
                      <Button
                        color="link"
                        className="p-0 text-start d-inline-flex align-items-center w-100"
                        onClick={() => {
                          handleOpenDrawerButton(item);
                        }}
                      >
                        {item?.service_name}
                      </Button>
                    </td>
                    <td className="capitalize">{item?.service_category?.category}</td>
                    <td>${parseFloat(item?.service_price)?.toLocaleString('en-US', {minimumFractionDigits: 2})}</td>
                    
                    {permissions?.edit_services &&
                      <td style={{textAlign: 'end'}} className="action-cell">
                        <Button color="link" className="edit-btn">
                          <span className="icomoon-pencil"></span>
                        </Button>
                        <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                          <DropdownToggle color="link" className="more-btn">
                            <span className="icomoon-more"></span>
                          </DropdownToggle>{" "}
                        </ButtonDropdown>
                      </td>
                    }
                  </tr>
                ))}
              </tbody>
            </Table>
            <PaginationBlock
              limit={limit}
              page={page}
              pagingCounter={inventory?.pagingCounter || 1}
              docLength={inventory?.docs?.length || 0}
              docPage={inventory.page || 1}
              totalPages={inventory.totalPages || 1}
              totalDocs={inventory.totalDocs || 0}
              _handleLimit={(val) => {
                setLimit(val);
              }}
              _handlePageChange={setPage}
            />
          </div>
        ) : (
          <NodataFoundBlock type={ filterData?.search !==  "" || 
          filterData?.categoryId !== "" ? 'search result' : ''} />
        )}
      </div>
    </>
  );
};

export default Services;
