import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "./index.scss";
const PasswordFormMessage = ({ inputPassword }) => {
	const [upper, setUpper] = useState(false);
	const [lower, setLower] = useState(false);
	const [character, setCharacter] = useState(false);
	const [number, setNumber] = useState(false);
	const [specialCharater, setSpecialCharater] = useState(false);
	const [noSpace, setNoSpace] = useState(false);

	useEffect(() => {
		handleCheckPassword()
	}, [inputPassword])

	const handleCheckPassword = () => {
		var pwdVal = inputPassword;

		if (/[A-Z]/.test(pwdVal)) {
			setUpper(false);
		} else {
			setUpper(true);
		}

		if (/[a-z]/.test(pwdVal)) {
			setLower(false);
		} else {
			setLower(true);
		}

		if (/[0-9]/.test(pwdVal)) {
			setNumber(false);
		} else {
			setNumber(true);
		}

		if (pwdVal.length < 8 || pwdVal > 40) {
			setCharacter(true);
		} else {
			setCharacter(false);
		}

		if (/[!@#$&*%^)(_+]/.test(pwdVal)) {
			setSpecialCharater(false);
		} else {
			setSpecialCharater(true);
		}

		if (/[-\s]/.test(pwdVal)) {
			setNoSpace(true);
		} else {
			setNoSpace(false);
		}
	}
	return (
		<>
			<div className="password-form-msg">
				<Col sm="12" className="password-warning d-flex align-items-center">
					<span className="icon">
						<img className="img-fluid" src={require('../../assets/img/vetRecor/warning.svg')} alt="Warning" />
					</span>
					<span className="txt">
						Your password must contain a minimum of 8 characters included with at least 1 upper case letter, 1 number, and 1 special character. </span>
				</Col>
				<Col sm="12" className="helper-msg">
					<ul className="list-unstyled d-flex flex-wrap">
						<li className={`d-flex align-items-center ${!character ? 'text-success' : 'text-danger'}`}>
							<span>
								{
									character ? <span className="rounded-circle"></span> : <FontAwesomeIcon icon={faCheck} />
								}
							</span>
							<label>
								8 Characters
							</label>
						</li>
						<li className={`d-flex align-items-center ${!number ? 'text-success' : 'text-danger'}`}>
							<span>
								{
									number ? <span className="rounded-circle"></span> : <FontAwesomeIcon icon={faCheck} />
								}
							</span>
							<label>
								At least one number
							</label>
						</li>
						<li className={`d-flex align-items-center ${!specialCharater ? 'text-success' : 'text-danger'}`}>
							<span>
								{
									specialCharater ? <span className="rounded-circle"></span> : <FontAwesomeIcon icon={faCheck} />
								}
							</span>
							<label>
								1 Special character
							</label>
						</li>
						<li className={`d-flex align-items-center ${!noSpace ? 'text-success' : 'text-danger'}`}>
							<span>
								{
									noSpace ? <span className="rounded-circle"></span> : <FontAwesomeIcon icon={faCheck} />
								}
							</span>
							<label>
								No spaces
							</label>
						</li>
						<li className={`d-flex align-items-center ${!upper ? 'text-success' : 'text-danger'}`}>
							<span>
								{
									upper ? <span className="rounded-circle"></span> : <FontAwesomeIcon icon={faCheck} />
								}
							</span>
							<label>
								At least one capital letter
							</label>
						</li>
					</ul>
				</Col>
			</div>
		</>
	)
};
export default PasswordFormMessage;