import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Table, DropdownToggle, ButtonDropdown } from "reactstrap";
import { useSelector } from "react-redux";

import CheckBox from "../../../../shared/CheckBox";
import UserPlaceholder from "../../../../shared/UserPlaceholder";

import PaginationBlock from "../../../../shared/PaginationBlock";
import { dateFromDateTime } from "../../../../utils/common";
import NodataFoundBlock from "../../../../shared/NoDataFoundBlock";
import LoaderUI from "../../../../shared/loader";
import PetPlaceholder from "../../../../shared/PetPlaceholder";
import ImagePlaceholder from "../../../../shared/ImagePlaceholder";
import { petGenderValues } from "utils/constants";
import { useClinicSpecies } from "hooks/useClinicSpecies";
import { useClinicBreed } from "hooks/useClinicBreed";

const PatientsTable = ({ limit, page, setLimit, setPage }) => {
  const history = useHistory();
  const clinicBreed = useClinicBreed()
  const clinicSpecies = useClinicSpecies()
  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  const { petList, loading } = useSelector((state) => state.petReducer);

  const handleProfileClick = (item) => {
    if (permissions?.view_patients_details) {
      history.push(`/patient-detail/${item?._id}`);
    }
  };

  if (loading) {
    return <LoaderUI />;
  }

  return (
    <>
      {petList?.docs?.length > 0 ? (
        <div className="table-listing-blc overflow-auto">
          <Table striped responsive className="checkbox-listing">
            <thead>
              <tr>
                <th>
                  <CheckBox
                    type="checkbox"
                    ischecked={false}
                    className="no-label"
                  />
                </th>
                <th style={{ minWidth: "90px" }}>Patient Info.</th>
                <th style={{ minWidth: "97px" }}>VRN</th>
                <th style={{ minWidth: "100px" }}>Status</th>
                {/*<th style={{ minWidth: "144px" }}>Wellness Plan</th>*/}
                <th style={{ minWidth: "130px" }}>Animal / Breed</th>
                <th style={{ minWidth: "80px" }}>Gender</th>
                <th style={{ minWidth: "110px" }}>Last Visit</th>
                <th style={{ minWidth: "148px" }}>Owner</th>
                <th style={{ minWidth: "128px" }}>City</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {(petList?.docs).map((item, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <CheckBox
                        type="checkbox"
                        ischecked={false}
                        className="no-label"
                      />
                    </td>
                    <td>
                      <Button
                        color="link"
                        className="p-0 text-start pic-cell d-inline-flex align-items-center w-100"
                        onClick={() => {
                          handleProfileClick(item);
                        }}
                      >
                        <div className="pic d-inline-flex position-relative">
                          {item?.pet_image ? (
                            <img
                              className="img-fluid w-100 h-100"
                              src={
                                item?.pet_image
                                  ? item?.pet_image
                                  : require("../../../../assets/img/vetRecorEMR/product-thumbnail-square.png")
                              }
                              alt="Patient"
                            />
                          ) : (
                            <ImagePlaceholder className="img-fluid w-50 h-50" />
                          )}
                        </div>
                        {item?.pet_name || '--'}
                      </Button>
                    </td>
                    <td>{item.vrn || '--'}</td>
                    <td>
                      <span className="status-cell">
                        {!!item?.active ? "Active" : "Inactive"}
                      </span>
                      {/* <span className="status-cell success-cell">Paid</span>
												<span className="status-cell pending-cell">Pending</span> */}
                    </td>
                    {/*
                    <td>
                      {item?.wellness_plan_id ? (
                        <div className="pic-cell d-inline-flex align-items-center w-100">
                          <div className="pic d-inline-flex position-relative">
                            <img
                              className="img-fluid"
                              src={require("../../../../assets/img/vetRecorEMR/pet-badge-icon-yellow.svg")}
                              alt="Patient"
                            />
                            {
                              //<img className="img-fluid" src={require('../../../assets/img/vetRecorEMR/pet-badge-icon-blue.svg')} alt="Patient" />
                              //<img className="img-fluid" src={require('../../../assets/img/vetRecorEMR/pet-badge-icon-green.svg')} alt="Patient" />
                            }
                          </div>
                          Golden Care
                        </div>
                      ) : (
                        <>--</>
                      )}
                      </td>*/}
                    <td>
                      {clinicSpecies(item?.speciesDetails?._id) || item?.speciesDetails?.species_name || '--'} /{" "}
                      {clinicBreed(item?.breedDetails?._id) ||  item?.breedDetails?.breed || '--'}
                    </td>
                    <td>{petGenderValues?.filter(i => i?.value ===item?.gender)?.[0]?.label || '--'}</td>
                    <td>
                      {item?.lastVisitedAt
                        ? dateFromDateTime(item?.lastVisitedAt, "MM/DD/yyyy")
                        : "--"}
                    </td>
                    <td>
                      <div className="pic-cell d-inline-flex align-items-center w-100">
                        <div className="pic d-inline-flex position-relative overflow-hidden rounded-circle">
                          {item?.petOwner?.image ? (
                            <img
                              className="img-fluid w-100 h-100"
                              src={item?.petOwner?.image}
                              alt="Patient"
                            />
                          ) : (
                            <UserPlaceholder />
                          )}
                        </div>
                        {item?.petOwner?.owner_name || '--'}
                      </div>
                    </td>
                    <td>{item?.petOwner?.city || '--'}</td>
                    <td className="action-cell">
                      <div className="d-flex justify-content-end">
                      {permissions?.edit_patients_details && (
                        <Button color="link" className="edit-btn">
                          <span className="icomoon-pencil"></span>
                        </Button>
                      )}

                      <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle color="link" className="more-btn">
                          <span className="icomoon-more"></span>
                        </DropdownToggle>
                        {
                          // <DropdownMenu>
                          //<DropdownItem>Item One</DropdownItem>
                          //<DropdownItem>Item Two</DropdownItem>
                          //</DropdownMenu>
                        }
                      </ButtonDropdown>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <PaginationBlock
            limit={limit}
            page={petList.page || 1}
            pagingCounter={petList?.pagingCounter || 1}
            docLength={petList?.docs?.length || 0}
            docPage={petList.page || 1}
            totalPages={petList.totalPages || 1}
            totalDocs={petList.totalDocs || 0}
            _handleLimit={(val) => {
              setLimit(val);
            }}
            _handlePageChange={(pageNumber) => {
              setPage(pageNumber);
            }}
          />
        </div>
      ) : (
        <NodataFoundBlock type={petList?.totalDocs === 0 ? '' : 'search result'} />
      )}
    </>
  );
};

export default PatientsTable;
