import React from "react";
import "./index.scss";
const PageHeader = ({ title, subTitle, className = '' }) => (
	<>
		<div className={`page-header ${className}`}>
			<h2 className="d-flex align-items-center">{title}</h2>
			{
				subTitle && <h3>{subTitle}</h3>
			}

		</div>
	</>
);
export default PageHeader;