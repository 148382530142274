const initState = {
  loading: false,
  patientList: false,
};

export const patientIntakeFormReducer = (state = initState, action) => {
  switch (action.type) {
    case "PET_LOADING":
      return { ...state, loading: action.payload };

    case "GET_CLINIC_PATIENT":
      return { ...state, patientList: action.payload };

    default:
      return state;
  }
};
