import React, {useState} from "react";
import {
	Bar
  } from "react-chartjs-2";
import 'chart.js/auto';

import { useSelector } from "react-redux";
import moment from "moment";
import "./index.scss";

const ProfileChart = () => {
	const { petDetails } = useSelector(state => state.petReducer);
	var weights = [...(petDetails?.weights || [])]?.reverse();
	var temps = [...(petDetails?.temperatures || [])]?.reverse();

	const tempData = {
		labels: temps?.map(item => moment(item?.date).format('MM/DD/YYYY')),
		datasets: [
		  {
			borderRadius: 100,
			label: 'Temperature',
			data: temps?.map(item => item?.temperature),
			backgroundColor: 'rgba(169, 182, 193, 0.30)',
			hoverBackgroundColor: '#409DE0',
			maxBarThickness: 6

		  }
		],
	  };

	  const weightData = {
		labels: weights?.map(item => moment(item?.date).format('MM/DD/YYYY')),
		datasets: [
		  {
			borderRadius: 100,
			label: 'Weight',
			data: weights?.map(item => item?.weight),
			backgroundColor: 'rgba(169, 182, 193, 0.30)',
			hoverBackgroundColor: '#409DE0',
			maxBarThickness: 6


		  }
		],
	  };


	
	const chartOptions = {responsive: true, 
						
		plugins: {
			tooltip: {
				enabled: true,
				displayColors:false,
				xAlign: 'center',
				yAlign: 'bottom',
				callbacks: {
					title: function(context) {
						return ''//`${context.label}: ${context.parsed.y}`;
					  },
					label: function(context){
						return `${context.label}: ${context.parsed.y}`;
					}
				}
				}, 
			legend: {display: false}},   // Modify the axis by adding scales
										scales: {
											// to remove the labels
											x: {
											ticks: {
												display: false,
											},
											border:{
												display:false
											  },

											// to remove the x-axis grid
											grid: {
												drawBorder: false,
												display: false,
											},
											},
											// to remove the y-axis labels
											y: {
												border:{
													display:false
												},
											ticks: {
												display: false,
												beginAtZero: true,
											},
											// to remove the y-axis grid
											grid: {
												drawBorder: false,
												display: false,
											},
											},
										}, }
	  

	

	return (
		<>

			<div className="profile-chart d-flex row-block">
				{petDetails?.temperatures?.length > 0 &&
				<div className="chart-col d-flex flex-column">
					{/* <SmallBarGraph height={100} /> */}
					<div className="ttl d-flex">
						<div className="left-col">
							Temperature
						</div>
						<div className="right-col ms-auto">
							{petDetails?.temperatures?.[0]?.temperature} F
						</div>
					</div>
					<div className="inner mt-auto">
						<Bar data={tempData} 
						options={chartOptions} /> 
					</div>
				</div>}
				{petDetails?.weights?.length > 0 &&
				<div className="chart-col d-flex flex-column">
					<div className="ttl d-flex">
						<div className="left-col">
							Weight
						</div>
						<div className="right-col ms-auto">
							{petDetails?.weights?.[0]?.weight} lbs
						</div>
					</div>
					<div className="inner mt-auto">
					<Bar data={weightData} 
						options={chartOptions} /> 
						{/*<img className="img-fluid" src={require('../../../../../assets/img/vetRecorEMR/chart-bar.png')} alt="Chart" />*/}
					</div>
				</div>}
			</div>
		</>
	);
};

export default ProfileChart;
