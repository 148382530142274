import React, { useEffect } from "react";
import UserPicCell from "./UserPicCell";
// import TimeCell2 from "./TimeCell2";
// import TimeCell from "./TimeCell";
// import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllDoctorsDetails } from "../../../context/actions/appointment";
import { useState } from "react";
import NodataFoundBlock from "../../NoDataFoundBlock";
import NoTimeSlot from "./NoTimeSlot";
import moment from "moment";
import TimeCellWrapper from "./TimeCellWrapper";
import LoaderUI from "shared/loader";
import "./index.scss";

const ScheduleList = ({
  doctorInfo,
  isDoctorData,
  userDetails,
  slotTiming,
  setSlotTiming,
  selectedApptDate,
  locationChange,
  doctorNameFilter,
  doctorAppt,
  setSelectedApptSlot,
  setDoctorsList,
  doctorsList,
  setSelectedApptSlots,
  selectedApptSlots,
  appointmentSlotDuration,
  directAppt,
  setDirectAppointment,
  overrideToggle,
}) => {
  const dispatch = useDispatch();
  const [searchDoctosList, setSearchDoctosList] = useState([]);
  const [newDoctorList, setNewDoctorList] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [slotTiming, setSlotTiming] = useState(false);
  // const [slotTiming, setSlotTiming] = useState([]);
  const { clinic_details } = useSelector((state) => state?.clinicReducer);
  const { holidays } = useSelector((state) => state.holidayReducer);
  const getDates = (startDate, endDate) => {
    var dates = [];

    var currDate = moment(startDate, "DD-MM-YYYY").startOf("day");
    var lastDate = moment(endDate, "DD-MM-YYYY").startOf("day");

    while (currDate.isSameOrBefore(lastDate, "day")) {
      dates.push(currDate.clone());
      currDate.add(1, "days");
    }

    return dates;
  };
  const handleReponseList = (data) => {
    let newList = data?.map((element) => {
      let dateList = [];
      if (element?.staffAvailability) {
        let newStaffList = element?.staffAvailability?.filter(
          (o) => o.status === 1
        );
        newStaffList?.forEach((o) => {
          let clinic_id = o?.clinicDetails?.[0]?._id || "";
          let clinic_name = o?.clinicDetails?.[0]?.clinic_name || "";
          let allDates = getDates(o?.start_date, o?.end_date);
          allDates.forEach((date) => {
            let isWeekOff = false;
            let isHolidayOff =
              holidays &&
              holidays?.some(
                (olm) =>
                  moment(olm.date, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
                    "DD-MM-YYYY"
                  ) === date.format("DD-MM-YYYY") && olm.status === "close"
              );

            if (
              (o.isWeekendOff &&
                ["Saturday", "Sunday"].includes(date.format("dddd"))) ||
              isHolidayOff
            ) {
              isWeekOff = true;
            }
            let fDate = date.format("DD-MM-YYYY");
            let fIndex = dateList?.findIndex((d) => d.date === fDate);
            if (fIndex === -1) {
              let aList = [];
              if (o?.isAvailable) {
                aList.push({
                  start_time: o.start_time,
                  end_time: o.end_time,
                  clinic_id: clinic_id,
                  clinic_name: clinic_name,
                });
              }
              dateList.push({
                date: fDate,
                availableList: aList,
                isWeekOff: isWeekOff,
                clinic_id: clinic_id,
              });
            } else {
              let existData = dateList[fIndex];
              let aList = existData.availableList;
              if (o?.isAvailable) {
                aList.push({
                  start_time: o.start_time,
                  end_time: o.end_time,
                  clinic_id: clinic_id,
                  clinic_name: clinic_name,
                });
              }
              dateList[fIndex] = {
                date: fDate,
                availableList: aList,
                isWeekOff: existData.isWeekOff,
                clinic_id: clinic_id,
              };
            }
          });
        });
      }
      return { ...element, newDateList: dateList };
    });
    setNewDoctorList(newList);
  };

  const handleSlotTimingV2 = (doctorData, selectedSlot) => {
    let selectedSlotsList = selectedApptSlots;
    let listApptTimes = slotTiming;
    if (selectedDoctor?._id !== doctorData?._id) {
      selectedSlotsList = [];
      listApptTimes = [];
    }
    if (selectedSlotsList.includes(selectedSlot?.time)) {
      selectedSlotsList = selectedSlotsList.filter(
        (o) => o !== selectedSlot?.time
      );
      listApptTimes = listApptTimes.filter((o) => o !== selectedSlot?.dTime);
    } else {
      selectedSlotsList.push(selectedSlot.time);
      listApptTimes.push(selectedSlot.dTime);
    }
    isDoctorData(doctorData);
    setSelectedDoctor(doctorData);

    doctorAppt({
      appointment_date: selectedApptDate,
      doctor_id: doctorData.user._id,
      appointment_time: listApptTimes,
      slot_time: selectedSlotsList,
    });
    setSelectedApptSlots(selectedSlotsList);
    setSlotTiming(listApptTimes);
    setDirectAppointment(false);
  };
  // const handleSlotTiming = (doctorData, selectedSlot) => {
  //   isDoctorData(doctorData);
  //   setSelectedDoctor(doctorData);

  //   const slotsTime = doctorData?.availability?.slots_time;
  //   let slots = [];
  //   for (let time of slotsTime) {
  // let start_time = moment(
  //   doctorData.availability?.date + " " + time.start_time,
  //   "DD/MM/YYYY h:mm a"
  // );
  // let end_time = moment(
  //   doctorData.availability?.date + " " + time.end_time,
  //   "DD/MM/YYYY h:mm a"
  // );

  //     var loop = start_time;
  //     while (loop < end_time) {
  //       slots.push(loop.format("h:mm a"));
  //       loop = moment(loop).add(
  //         appointmentSlotDuration ? +appointmentSlotDuration : 30,
  //         "minutes"
  //       );
  //     }
  //   }

  //   const index = slots.findIndex((i) => {
  //     if (i === selectedSlot) {
  //       return i;
  //     }
  //   });
  //   const apptTime = `${slots[index]} - ${slots[index + 1]}`;

  //   let findExist = selectedApptSlots.find((ele) => ele === selectedSlot);
  //   if (findExist) {
  //     if (JSON.stringify(doctorInfo) === JSON.stringify(doctorData)) {
  //       let listApptTimes = [...slotTiming].filter((ele) => ele !== apptTime);
  //       let selectedSlotsList = [...selectedApptSlots].filter(
  //         (ele) => ele !== selectedSlot
  //       );
  //       doctorAppt({
  //         appointment_date: selectedApptDate,
  //         doctor_id: doctorData.user._id,
  //         appointment_time: listApptTimes,
  //         slot_time: selectedSlotsList,
  //       });
  //       setSelectedApptSlots(selectedSlotsList);
  //       setSlotTiming(listApptTimes);
  //       if (selectedSlotsList.length > 0 && directAppt) {
  //         setDirectAppointment(true);
  //       } else {
  //         setDirectAppointment(false);
  //       }
  //     } else {
  //       let listApptTimes = [apptTime];
  //       let selectedSlotsList = [selectedSlot];
  //       doctorAppt({
  //         appointment_date: selectedApptDate,
  //         doctor_id: doctorData.user._id,
  //         appointment_time: listApptTimes,
  //         slot_time: selectedSlotsList,
  //       });
  //       setSelectedApptSlots([selectedSlot]);
  //       setSlotTiming([apptTime]);
  //       if (selectedSlotsList.length > 0 && directAppt) {
  //         setDirectAppointment(true);
  //       } else {
  //         setDirectAppointment(false);
  //       }
  //     }
  //   } else {
  //     if (JSON.stringify(doctorInfo) === JSON.stringify(doctorData)) {
  //       let listApptTimes = [...slotTiming, apptTime];
  //       let selectedSlotsList = [...selectedApptSlots, selectedSlot];
  //       doctorAppt({
  //         appointment_date: selectedApptDate,
  //         doctor_id: doctorData.user._id,
  //         appointment_time: listApptTimes,
  //         slot_time: selectedSlotsList,
  //       });

  //       setSelectedApptSlots(selectedSlotsList);
  //       setSlotTiming(listApptTimes);
  //       if (selectedSlotsList.length > 0 && directAppt) {
  //         setDirectAppointment(true);
  //       } else {
  //         setDirectAppointment(false);
  //       }
  //     } else {
  //       let listApptTimes = [apptTime];
  //       let selectedSlotsList = [selectedSlot];
  //       doctorAppt({
  //         appointment_date: selectedApptDate,
  //         doctor_id: doctorData.user._id,
  //         appointment_time: listApptTimes,
  //         slot_time: selectedSlotsList,
  //       });
  //       setSelectedApptSlots([selectedSlot]);
  //       setSlotTiming([apptTime]);
  //       if (selectedSlotsList.length > 0 && directAppt) {
  //         setDirectAppointment(true);
  //       } else {
  //         setDirectAppointment(false);
  //       }
  //     }
  //   }
  // };

  const handleGetAllDoctorsDetails = () => {
    setIsLoading(true);
    dispatch(
      getAllDoctorsDetails(
        clinic_details?._id,
        selectedApptDate,
        {},
        (result) => {
          if (result && !result.errors) {
            setDoctorsList(result);
            setSearchDoctosList(result);
          } else {
            setDoctorsList(false);
            setSearchDoctosList([]);
          }
          setIsLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    handleGetAllDoctorsDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApptDate]);

  useEffect(() => {
    if (doctorNameFilter.length > 0) {
      const filteredData = doctorsList.filter((doctor) => {
        const fullName =
          `${doctor?.user?.title}${doctor?.user?.firstname}${doctor?.user?.lastname}`.toLowerCase();
        const reversedFullName =
          `${doctor?.user?.lastname}${doctor?.user?.firstname}${doctor?.user?.title}`.toLowerCase();
        const trimmedSearchValue = doctorNameFilter
          .replace(/\s+/g, "")
          .toLowerCase();
        return (
          fullName.includes(trimmedSearchValue) ||
          reversedFullName.includes(trimmedSearchValue)
        );
      });
      setSearchDoctosList(filteredData);
    } else {
      setSearchDoctosList(doctorsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorNameFilter]);

  useEffect(() => {
    if (locationChange) {
      const filteredData = doctorsList.filter(
        (doctor) => doctor?.user?.city === locationChange
      );
      setSearchDoctosList(filteredData);
    } else {
      setSearchDoctosList(doctorsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationChange]);

  useEffect(() => {
    handleReponseList(searchDoctosList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDoctosList]);

  return (
    <>
      <div className="appointment-list-view overflow-auto">
        {isLoading ? (
          <LoaderUI />
        ) : (
          <table className="table">
            <thead>
              {searchDoctosList?.length === 0 ? (
                <NodataFoundBlock message="No doctor found" />
              ) : (
                newDoctorList?.map((doctor, i) => {
                  const { newDateList, appointments } = doctor;
                  let isNoTimeFound = false;
                  let fData = newDateList?.find(
                    (o) => o.date === selectedApptDate
                  );
                  let slotList = [];
                  let slots = [];
                  if (fData?.date) {
                    isNoTimeFound = !fData?.isWeekOff;
                    slotList = [...fData?.availableList];
                    if (isNoTimeFound) {
                      slotList?.forEach((sTime) => {
                        let startTime = moment(
                          `${selectedApptDate} ${sTime?.start_time}`,
                          "DD-MM-YYYY hh:mm A"
                        );
                        let endTime = moment(
                          `${selectedApptDate} ${sTime?.end_time}`,
                          "DD-MM-YYYY hh:mm A"
                        );
                        var loop = startTime;
                        while (loop < endTime) {
                          let nextSlot = moment(loop).add(
                            appointmentSlotDuration
                              ? +appointmentSlotDuration
                              : 30,
                            "minutes"
                          );
                          slots.push({
                            time: loop.format("h:mm a"),
                            dTime: `${loop.format(
                              "h:mm a"
                            )} - ${nextSlot.format("h:mm a")}`,
                          });

                          loop = nextSlot;
                        }
                      });
                    }
                  }
                  return (
                    <React.Fragment key={i}>
                      <tr key={i}>
                        <th style={{ width: "280px" }}>
                          <UserPicCell
                            doctorInfo={doctorInfo}
                            doctorDetails={doctor}
                          />
                        </th>
                        <th>
                          {/* <TimeCellWrapper
                          doctor={doctor}
                          selectedApptSlots={selectedApptSlots}
                          selectedDoctor={selectedDoctor}
                          selectedApptDate={selectedApptDate}
                          handleSlotTiming={handleSlotTiming}
                          NoTimeSlot={NoTimeSlot}
                          appointmentSlotDuration={appointmentSlotDuration}
                          overrideToggle={overrideToggle}
                        /> */}
                          {isNoTimeFound ? (
                            <div className="d-flex gap-2 flex-wrap">
                              {slots?.map((time, tIndex) => {
                                let isActive =
                                  selectedApptSlots.includes(time.time) &&
                                  selectedDoctor?._id === doctor?._id;
                                let isExist = !overrideToggle
                                  ? appointments?.some(
                                      (o) =>
                                        o.appointment_date ===
                                          selectedApptDate &&
                                        o.appointment_timings?.some(
                                          (e) => e.slot_time === time?.time
                                        ) &&
                                        o.isAppointmentAproved === 1
                                    )
                                  : false;
                                return (
                                  <span
                                    className={`br-4 ps-2 pe-2 pt-1 pb-1 cursor-pointer ${
                                      isExist ? "opacity-50" : ""
                                    } ${
                                      isActive
                                        ? "b-3EFD bg-3EFD color-ffff"
                                        : "b-cfcf"
                                    }`}
                                    onClick={() => {
                                      if (!isExist) {
                                        handleSlotTimingV2(doctor, time);
                                      }
                                    }}
                                    key={tIndex}
                                  >
                                    {time.time}
                                  </span>
                                );
                              })}
                            </div>
                          ) : (
                            <NoTimeSlot />
                          )}
                        </th>
                      </tr>
                      <tr className="space-rw">
                        <th colSpan="2">&nbsp;</th>
                      </tr>
                    </React.Fragment>
                  );
                })
              )}
            </thead>
          </table>
        )}
      </div>
    </>
  );
};

export default ScheduleList;
