import React from "react";
import { useSelector } from "react-redux";
import { Button } from 'reactstrap';
import mp4File from "assets/img/mp4.png";
import './index.scss';

const WaitingRoomList = ({ handleEdit }) => {
	const { waitingRoomList } = useSelector(state => state.waitingRoomReducer);
	return (
		<>
			<div className="waiting-room-list">
				{
					(waitingRoomList?.length > 0 ? waitingRoomList : [])?.map((item, index) => {
						return (
							<div className="item-row d-flex" key={index}>
								<div className="left-col">
									<div className="room-info">
										<h4>{item?.room_name || "[Waiting Room Name]"}</h4>
										<h5 style={{ textTransform: 'capitalize' }}>{item?.pet_category_name || "[Pet categories will goes here]"}</h5>
										<h5>{item?.weight_range || "[Current conditions and CPR Tags will goes here]"}</h5>
									</div>
								</div>
								<div className="right-col d-inline-flex align-items-center">
									<div className="product-pic-blc d-inline-flex overflow-auto">
										{item?.attachments?.map((wImg, wIndex) => {
											const { file, fileType } = wImg
											return <div className={`pic-cell overflow-hidden ${wIndex > 2 ? "d-none" : ""}`} key={wIndex}>
												<img className="img-fluid" src={fileType === "mp4" ? mp4File : file} alt="file" />
											</div>
										})}

									</div>
									<Button color="link" className="d-inline-flex align-items-center justify-content-center" onClick={() => {
										if (handleEdit) {
											handleEdit(item)
										}
									}}>
										<span className="icomoon-pencil"></span>
									</Button>
								</div>
							</div>
						)
					})
				}
			</div>
		</>
	);
};

export default WaitingRoomList;
