import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { sidebarPanelAction } from "../../../context/actions/sidebarPanelAction";
// import UserPlaceholder from "../../UserPlaceholder";
import testUser from "../../../assets/img/user2.png";
import "./index.scss";

const SuperAdminProfile = ({ userName, userImage, lastLogedIn }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  function formatDate(inputDate) {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    const formattedDate = new Date(inputDate).toLocaleDateString(
      "en-US",
      options
    );

    return formattedDate.replace(",", " at");
  }

  const formattedDate = formatDate(lastLogedIn);

  return (
    <div
      className={`d-flex flex-column align-items-center text-center user-profile  ${
        location.pathname === "/super-admin/manage-profile" ? "active" : ""
      }`}
      onClick={() => {
        history.push("/super-admin/manage-profile");
        dispatch(sidebarPanelAction(false));
      }}
    >
      {/* {userImage ? ( */}
      <img className="rounded-circle mt-5" src={testUser} alt="User" />
      {/* ) : (
        <UserPlaceholder />
      )} */}
      <div className="txt">
        <h4 className="copyright-txt text-center">{userName}</h4>
        <h6 className="copyright-txt text-center last-logged-in">
          Last Logged In
        </h6>
        <h6 className="copyright-txt text-center">08/28/2023 at 10:30PM</h6>
      </div>
    </div>
  );
};

export default SuperAdminProfile;
