const initState = {
    loading: true,
    vaccineSchedules: []
}

export const vaccineScheduleReducer = (state = initState, action) => {
    switch (action.type) {
        case "VACCINE_SCHEDULE_LOADING":
            return { ...state, loading: action.payload }

        case "GET_VACCINE_SCHEDULE":
            return { ...state, vaccineSchedules: action.payload }

        default:
            return state
    }
}