import "./select-operating-room.scss"
export default function SelectOperatingRoom({
  operatingRooms = [],
  selectedRoom,
  setSelectedRoom,
  setAppointments
}) {
  const handleRoomClick = (room) => {
    if(room?._id === selectedRoom?._id){
      setSelectedRoom(null);
      let appointments = [];
      operatingRooms.map(e => appointments = [...appointments, ...e.appointments]);
      setAppointments(appointments)
    } else {
    const foundRoom = operatingRooms.find((item) => item._id === room._id);
    setSelectedRoom(foundRoom);
    setAppointments(foundRoom.appointments)
    }
  };

  return (
    <div className="flex gap-2 mb-3">
      {operatingRooms.map((room) => (
        <button
          onClick={() => handleRoomClick(room)}
          className={`operating-room flex gap-2 items-center px-4 py-3 bg-[#f8f8f8] ${
            room._id === selectedRoom?._id
              ? "active selected-operating-room border-b-2 border-blue hover:border-blue" //"border-b-2 border-blue hover:border-blue"
              :"operating-room-card hover:border-b-2 hover:border-blue" //"hover:border-b-2 hover:border-blue"
          }`}
          
         
        >
          {/*<span className="material-icons text-[#cfcfcf]">account_circle</span>*/}
          <span>{room.name}</span>
        </button>
      ))}
    </div>
  );
}
