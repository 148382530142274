import React from "react";
import { capitalizeFirstLetter } from "../../../../utils/common";
import "./index.scss";

const GeneralInfoBlock = ({ userGeneralInfo, className = '' }) => {

	return (
		<>
			<div className={`general-info-blc ${className}`}>
				<p>
					<label>Name</label>
					<span>{userGeneralInfo?.firstname} {userGeneralInfo?.lastname}</span>
				</p>
				<p>
					<label>Gender</label>
					{/* <span>Female (Preferred Pronouns: She)</span> */}
					<span>{userGeneralInfo?.gender && capitalizeFirstLetter(userGeneralInfo?.gender)}</span>
				</p>
				<p>
					<label>License No.</label>
					<span>{userGeneralInfo?.license_number}</span>
				</p>
				<p>
					<label>Employement Type</label>
					<span>{userGeneralInfo?.employment_type === 'full-time' ? 'Full Time' : 'Part Time'}</span>
				</p>
				<p>
					<label>Employment Date</label>
					<span>10/28/2022</span>
				</p>
			</div>
		</>
	);
};

export default GeneralInfoBlock;
