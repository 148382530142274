import React, { useEffect, useState } from "react";
import TableListFilterBlock from "./TableListFilterBlock";


const TableListExistingPetOwnerFilterWrapper = ({ className = '' , filterParams, setFilterParams}) => {

	//const [filterParams, setFilterParams] = useState(false);

	

	/*
	useEffect(() => {
		if (filterParams) {
			
			dispatch(getPetListWithFilter({...filterParams}, () => {
			}))
		}
	}, [filterParams]) // eslint-disable-line
	*/
	

	const handleSearchPetList = (e) => {
		let value = e.target.value;
		setFilterParams(p => ({ ...p, searchText: value }))
	}
	const onSelectStatus = (e) => {
		let value = e.target.value;
		setFilterParams(p => ({ ...p, status: value === "false" ? false : true }))
	}
	return (
		<>
			<div className={`table-list-patients-filter-blc-wrapper d-flex align-items-center overflow-auto flex-shrink-0 ${className}`}>
				<div className="left-col">
					<TableListFilterBlock filterParams={filterParams} onSearch={handleSearchPetList}  onSelectStatus={onSelectStatus} />
				</div>
			</div>
		</>
	);
};

export default TableListExistingPetOwnerFilterWrapper;
