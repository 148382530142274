import React, { Fragment, useState } from "react";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UserLoginFireAuth, checkLoginFirstTime, login } from "../../../context/actions/user";
import { Formik, Form } from "formik";
import { loginSchema, loginInit } from "./schema";
import Head from "../Head";
import PasswordInput from "../../../shared/FormsInput/PasswordInput";
import EmailInput from "../../../shared/FormsInput/EmailInput";
// import CreateCodeModal from "./CreateCodeModal";
import EnterCodeModal from "./EnterCodeModal";
import LoaderUI from '../../../shared/loader';
import { toasterConfig } from "../../../utils/constants";
import CheckBox from '../../../shared/CheckBox';
import AppLogo from "../../../shared/AppLogo";

toast.configure();
const Login = () => {
	const dispatch = useDispatch();
	const [disabled, setDisabled] = useState(false);
	const [loader, showLoader] = useState(false)
	const [userDetails, setUserDetails] = useState(false)
	const [userLoginData, setUserLoginData] = useState(false)
	const [verificationCodeMessage, setVerificationCodeMessage] = useState(false)
	const [codeCreated, setCodeCreated] = useState({ isCodeCreated: true })
	const [enterCode, setEnterCode] = useState(false)


	/**
	 * @description method call to submit valid form
	 * @param {Object} params form params
	 * @param {Instance} status form status
	 */
	const submitForm = async (params) => {
		setDisabled(true);
		showLoader(true)
		params.current_device = window.navigator.userAgent
		// new ----------------------------------------
		 dispatch(UserLoginFireAuth(params, (result) => {
		 	console.log("%c Line:60 🍣 result", "color:#4fff4B", result);
		 	setDisabled(false);
		 	showLoader(false)
		 }))

		//old--------------------------
		/*dispatch(checkLoginFirstTime(params, (result) => {
			console.log(result)
			if (result) {
				setUserDetails(result.user_id)
				if (result.can_login) {
					// dispatch(
					// 	login(params, (result) => {
					// 		setDisabled(false);
					// 		showLoader(false)
					// 	})
					// );
					dispatch(UserLoginFireAuth(params, (result) => {
						setDisabled(false);
						showLoader(false)
					}))

				} else {
					if (result.can_login && result.device_logged) {
						// dispatch(
						// 	login(params, (result) => {
						// 		setDisabled(false);
						// 		showLoader(false)
						// 	})
						// );
						dispatch(UserLoginFireAuth(params, (result) => {
							setDisabled(false);
							showLoader(false)
						}))
					} else {
						setVerificationCodeMessage(result)
						setUserLoginData(params)
						setEnterCode(true)
						setDisabled(false);
						showLoader(false)
						return
					}
				}
			} else {
				setDisabled(false);
				showLoader(false)
			}
			setDisabled(false);
			showLoader(false)
		}))*/
	};

	return (
		<>
			{
				loader && <LoaderUI overlay />
			}
			<div className="col-blc left-col position-relative overflow-auto">
				<div className="head">
					<div className="logo-rw">
						<AppLogo />
					</div>
					{/*
					<h1>
						<span className="txt-1">Discover </span>
						<span className="txt-2">the world’s <span>Contra to popular Ipsum simple</span></span>
					</h1>*/}
				</div>
				<div className="bg position-absolute">
					<img className="img-fluid" src={require('../../../../src/assets/img/vetRecorEMR/login-pic-txt.png')} alt="" />
				</div>
			</div>
			<div className="col-blc right-col">
				<div className=" align-self-stretch">
					<h3 className="inner_link text-right">Not a member? <Link to="/register">Sign Up</Link></h3>
					<div className="login-blc m-auto signup-box">

						<div className="signup-head">
							<h2>Login to VetRecor</h2>
							{/*
							<div className="d-flex flex-wrap my-4 pt-2 pb-1">
								<Link to="/" className="social_link d-inline-flex align-items-center">
									<svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
										<path d="M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z" fill="#FFC107" />
										<path d="M3.15234 7.3455L6.43784 9.755C7.32684 7.554 9.47984 6 11.9993 6C13.5288 6 14.9203 6.577 15.9798 7.5195L18.8083 4.691C17.0223 3.0265 14.6333 2 11.9993 2C8.15834 2 4.82734 4.1685 3.15234 7.3455Z" fill="#FF3D00" />
										<path d="M12.0002 22.0003C14.5832 22.0003 16.9302 21.0118 18.7047 19.4043L15.6097 16.7853C14.5719 17.5745 13.3039 18.0014 12.0002 18.0003C9.39916 18.0003 7.19066 16.3418 6.35866 14.0273L3.09766 16.5398C4.75266 19.7783 8.11366 22.0003 12.0002 22.0003Z" fill="#4CAF50" />
										<path d="M21.8055 10.0415H21V10H12V14H17.6515C17.2571 15.1082 16.5467 16.0766 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z" fill="#1976D2" />
									</svg>
									Sign In with google
								</Link>
								<Link to="/" className="social_link d-inline-flex text-center  align-items-center justify-content-center">
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
										<path d="M20 1.89415C19.2644 2.21527 18.473 2.43246 17.6431 2.52945C18.4905 2.02971 19.1407 1.23897 19.4477 0.295208C18.6548 0.758204 17.7762 1.09408 16.842 1.27512C16.0935 0.490408 15.0271 0 13.8462 0C11.5805 0 9.74295 1.80891 9.74295 4.03924C9.74295 4.35583 9.77937 4.66399 9.84975 4.9598C6.43961 4.79141 3.41596 3.18343 1.39203 0.738925C1.03889 1.33537 0.836317 2.02971 0.836317 2.76984C0.836317 4.17087 1.56125 5.40774 2.66165 6.1319C1.98935 6.11112 1.35622 5.92947 0.803268 5.62613C0.802962 5.6433 0.802962 5.66047 0.802962 5.67734C0.802962 7.63445 2.21794 9.26684 4.09468 9.63766C3.75073 9.73044 3.3875 9.77953 3.01386 9.77953C2.74886 9.77953 2.49212 9.75453 2.24181 9.70754C2.76385 11.3119 4.27889 12.4798 6.07485 12.5126C4.67028 13.5962 2.90125 14.2417 0.978304 14.2417C0.647816 14.2417 0.320389 14.2227 0 14.1851C1.81493 15.3316 3.97228 16 6.28936 16C13.8367 16 17.9641 9.8452 17.9641 4.50705C17.9641 4.33203 17.9602 4.15762 17.9522 3.98471C18.7546 3.41538 19.4501 2.70417 20 1.89415Z" fill="#282E65" />
									</svg>
								</Link>
								<Link to="/" className="social_link d-inline-flex text-center  align-items-center justify-content-center">
									<svg xmlns="http://www.w3.org/2000/svg" width="11" height="18" viewBox="0 0 11 18" fill="none">
										<path d="M11 6.92308H7.33333V4.15385C7.33333 3.38954 7.9904 3.46154 8.8 3.46154H10.2667V0H7.33333C4.90307 0 2.93333 1.85954 2.93333 4.15385V6.92308H0V10.3846H2.93333V18H7.33333V10.3846H9.53333L11 6.92308Z" fill="#282E65" />
									</svg>
								</Link>
							</div>
							<div className="or_line d-flex align-items-center mb-2">
								<svg width="186" height="2" viewBox="0 0 186 2" fill="none" xmlns="http://www.w3.org/2000/svg">
									<line x1="185.5" y1="1" x2="0.5" y2="1.00002" stroke="#788693" stroke-dasharray="2 2" />
								</svg>
								<span>
									Or
								</span>
								<svg width="186" height="2" viewBox="0 0 186 2" fill="none" xmlns="http://www.w3.org/2000/svg">
									<line x1="185.5" y1="1" x2="0.5" y2="1.00002" stroke="#788693" stroke-dasharray="2 2" />
								</svg>
							</div>
								*/}
						</div>

						<Formik
							initialValues={loginInit}
							enableReinitialize
							validationSchema={loginSchema}
							onSubmit={submitForm}
						>
							{(formik) => (<Form >
								<EmailInput
									name="email"
									type="text"
									placeholder="Enter email address"
									id="email"
									value={formik?.values?.email}
									onChange={formik.handleChange}
									icon={'icon'}
									required={true}
									className="email"
								/>
								<PasswordInput
									type="password"
									name="password"
									placeholder="Enter password"
									id="password"
									value={formik?.values?.password}
									onChange={formik.handleChange}
									icon={'icon'}
									required={true}
									forgotLink={true}
								/>
								<div className="remember-rw">
									<CheckBox type='checkbox' label='Remember me' ischecked={false} />
								</div>
								<div className="btn-cell d-flex">
									<Button
										disabled={disabled}
										color="primary"
									>
										Login
									</Button>
								</div>
							</Form>)}
						</Formik>
						{
							enterCode && <EnterCodeModal 
							openViewBlock={(value) => setEnterCode(value)} 
							userId={userDetails} 
							userLoginData={userLoginData}
								sendLoader={(value) => showLoader(value)}
								verificationCodeMessage={verificationCodeMessage}
							/>
						}
					</div>
				</div>
			</div>
		</>
	);
};

export default Login;