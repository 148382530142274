import React from "react";
import { Button, Input } from "reactstrap"; // eslint-disable-line

const TableListFilterBlock = ({ onSearch = () => { } }) => {

	return (
		<>
			<div className="table-list-filter-blc d-flex align-items-center">
				<div className="search-cell col-cell position-relative">
					<span className="icomoon-search d-inline-flex align-items-center justify-content-center position-absolute"></span>
					<Input placeholder="Search" onChange={onSearch} />
				</div>
			
			</div>
		</>
	);
};

export default TableListFilterBlock;
