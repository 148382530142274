import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";

toast.configure();
/**
 * All Api endpoints to be call
 * Get -- /clinic/get-clinics/:user_id
 * Put -- /clinic/update
 * Get -- /clinic/get-forms-templates/:clinic_id
 */

/**
 *
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */
export const addContactPerson = (params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();

    ApiClient.post(`${apiUrl}${PORT}/contact-person/add`, params, token, dispatch, getState).then(
      (response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          toast.success(response.message, toasterConfig);
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      }
    );
  };
};

/**
 *
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */
export const updateContactPerson = (params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();

    ApiClient.put(
      `${apiUrl}${PORT}/contact-person/update`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (response?.status) {
        toast.success(response.message, toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};



/**
 * @description function use for to delete organization logo
 * @param {*} params accepting object
 */
export const deleteContactPerson = (
  document_id,
  callback
) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.delete(
      `${apiUrl}${PORT}/contact-person/${document_id}`,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response?.status) {
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};



/**
 *
 * @param {String} clinic_id
 * @param {Object} params
 * @returns
 */
export const getAllContactsByPet = (params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();
    ApiClient.get(
      `${apiUrl}${PORT}/contact-person/get-all/${params?.pet_id}`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (response?.status) {
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};

/**
 *
 * @param {String} clinic_id
 * @param {Object} params
 * @returns
 */
export const getAllClinics = (params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();
    dispatch({ type: "CLINIC_LOADING", payload: true });
    ApiClient.get(`${apiUrl}${PORT}/clinic/get-all`, params, token, dispatch, getState).then(
      (response) => {
        dispatch({ type: "CLINIC_LOADING", payload: false });

        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({
            type: "GET_ALL_CLINIC",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      }
    );
  };
};
