import { Form, Formik, FieldArray, Field, ErrorMessage } from "formik";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { templateInit, templateSchema } from "./schema";
import {
  createTemplate,
  updatePatientNotesTemplate,
} from "../../../../../../context/actions/patientINotesTemplate";
import {
  Button,
  Row,
  Input,
  Label,
  InputGroup,
  Col,
  FormGroup,
} from "reactstrap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Draggable from "../../../../../../shared/Draggable";

const NewTemplatePanel = ({ openViewBlock, isActive, templateToEdit }) => {
  const dispatch = useDispatch();

  const submitForm = (values) => {
    if (templateToEdit) {
      let body = {
        _id: values._id,
        clinic_id: values.clinit_id,
        created_by: values.created_by,
        title: values.title,
        description: values.description,
        fields: values.fields,
        active: values.active,
      };
      dispatch(
        updatePatientNotesTemplate(body, (response) => {
          if (response?.status && !response?.errors) {
            openViewBlock();
          }
        })
      );
    } else {
      dispatch(
        createTemplate(values, (response) => {
          if (response?.status && !response?.errors) {
            openViewBlock();
          }
        })
      );
    }
  };

  const moveItem = useCallback((fromIndex, toIndex, formik) => {
    const newFields = [...formik.values.fields];
    const [movedItem] = newFields.splice(fromIndex, 1);
    newFields.splice(toIndex, 0, movedItem);
    formik.setFieldValue("fields", newFields);
  }, []);

  return (
    <>
      <div
        className={`notes-template-panel-wrapper fixed flex flex-col shadow-md space-y-2 h-full  ${
          isActive && "active"
        }`}
      >
        <div className="head d-flex align-items-center">
          <h2>{templateToEdit ? 'Edit' : 'Add New'} Note Template</h2>
          <Button
            color="link"
            className="ms-auto panel-close-btn"
            onClick={() => {
              openViewBlock();
            }}
          >
            <span className="material-symbols-outlined">close</span>
          </Button>
        </div>

    
        <Formik
          initialValues={templateToEdit ?? templateInit}
          enableReinitialize
          validationSchema={templateSchema}
          onSubmit={submitForm}
        >
          {(formik) => (
            <Form className="flex-grow max-h-[75%] overflow-y-auto overflow-x-hidden overscroll-contain p-1">
              <FormGroup className="mb-2">
                <div className="form-floating position-relative text-input">
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    placeholder="Template Name"
                    value={formik?.values?.title}
                    style={{ padding: "10px 16px 0" }}
                    onChange={formik.handleChange}
                  />
                  <label for="">Name</label>
                </div>
              </FormGroup>

              <FormGroup className="mb-4">
                <div className="form-floating position-relative text-input">
                  <input
                    type="text"
                    className="form-control"
                    id="description"
                    placeholder="Template Description"
                    value={formik?.values?.description}
                    style={{ padding: "10px 16px 0" }}
                    onChange={formik.handleChange}
                  />
                  <label for="">Description</label>
                </div>
              </FormGroup>

              <DndProvider backend={HTML5Backend}>
                <FieldArray name="fields">
                  {({ insert, remove, push, reset }) => (
                    <>
                      <div className="d-flex flex-column justify-center gap-2 mb-2">
                        {formik?.values?.fields?.length > 0 &&
                          formik?.values?.fields?.map((field, index) => (
                            <Draggable
                              key={index}
                              index={index}
                              callback={(dragIndex, hoverIndex) =>
                                moveItem(dragIndex, hoverIndex, formik)
                              }
                            >
                              <FormGroup className="d-flex flex-row gap-4 align-items-center items-center p-[10px] rounded-md mb-0 justify-evenly bg-[#E4E4E447]">
                                <button
                                  className="hover:drop-shadow-md w-10 h-10"
                                  onClick={() => remove(index)}
                                >
                                  <img
                                    className="img-fluid w-100 h-100"
                                    src={
                                      require("../../../../../../assets/img/drag_handle.svg")
                                        .default
                                    }
                                    alt="Drag Handle"
                                  />
                                </button>

                                <Label className="font-bold whitespace-nowrap mb-0">
                                  Field Title
                                </Label>
                                <Input
                                  name={`fields.${index}`}
                                  tag={Field}
                                  placeholder="-Add Info-"
                                />
                                

                                <button

                                  style={{minWidth: '20px', minHeight: '20px'}}

                                  className=""
                                  onClick={() => remove(index)}
                                >
                                  <img
                                    className="img-fluid"
                                    src={
                                      require("../../../../../../assets/img/delete_gray.svg")
                                        .default
                                    }
                                    alt="Delete"
                                  />
                                </button>
                              </FormGroup>
                            </Draggable>
                          ))}
                      </div>
                      <Row className="mb-4">
                        <div className="w-full">
                          <Button
                            onClick={() => push("")}
                            color="link"
                            className="add-section-note-btn d-flex justify-content-center w-full"
                          >
                            <span   className="icomoon-plus-btn"></span>
                          </Button>
                        </div>
                      </Row>
                    </>
                  )}
                </FieldArray>
              </DndProvider>
              <div className="fixed bottom-0 w-full bg-transparent -translate-x-[20px]">
                <div className="flex w-full justify-end p-4 gap-2 mr-4">
                
                  <Button type="submit" color="success" className="w-[150px] ">
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default NewTemplatePanel;
