import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "../../../context/actions/user";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";

const Logout = ({setIsActiveRole = () => {}}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    setIsActiveRole(true)
    dispatch(
      logout(() => {
        history.push("/login");
      })
    );
  };
  return (
    <Button
      color="link"
      className="d-flex align-items-center text-start w-100 petowner_logout_btn"
      onClick={handleLogout}
      style={{ fontSize: "18px", position: "absolute", bottom: "0" }}
    >
      <span className="logout-icon mb-0 me-2">
        <FontAwesomeIcon icon={faSignOut} />
      </span>
      <label>Logout</label>
    </Button>
  );
};

export default Logout;
