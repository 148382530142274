import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "reactstrap";
import { apiUrl, PORT } from "../../../environment";
import TimeCell from "../ScheduleList/TimeCell";

import "./operatingroom.scss";
import { getOperatingRoomsByClinicIdWithAppointments } from "../../../context/actions/operatingRoomAction";
import TimeCell2 from "../ScheduleList/TimeCell2";

export default function OperatingRoomSchedule({
  patientData,
  doctorInfo,
  setSurgeryId,
  selectedOperatingRoomSlots,
  setSelectedOperatingRoomSlots,
  slotTiming,
  selectedApptDate
}) {
  // console.log("selectedApptDate", selectedApptDate)
  // console.log("pateint detail in operating room",patientData,doctorInfo)

  const dispatch = useDispatch()
  const [slots, setSlots] = useState([]);
  // const [operatingRooms, setOperatingRooms] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  // const [operatingRoomId, setOperatingRoomId] = useState("");
  const [isOperatingRooomSlotOpen, setIsOperatingRoomSlotOpen] =
    useState(false);
  const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);
  const { operatingRooms } = useSelector(state => state.operatingRoomReducer);


  const userDetails = useSelector((state) => state.user.user_details);

  function getHours(startTimeStr) {
    let startTimeHours = startTimeStr.split(" ")[0]
    let startTimeAmOrPm = startTimeStr.split(" ")[1]
    let startHoursInt = +startTimeHours.split(":")[0]
    let timeToAdd = (startTimeAmOrPm.toLowerCase() == "am" ? 0 : 12)
    let actualStartHoursInt = (startHoursInt == 12 && startTimeAmOrPm.toLowerCase() == "pm") ? 0 : startHoursInt + timeToAdd;
    return actualStartHoursInt
  }

  const [slotRanges, setSlotRanges] = useState([]);

  useEffect(() => {
    if (slotTiming.length > 0) {
      let updated = slotTiming.map((ele) => {
        let startTimeStr = ele.split("-")[0].trim()
        let startTime = getHours(startTimeStr)

        let endTimeStr = ele.split("-")[1].trim()
        let endTime = getHours(endTimeStr)

        return {
          startTimeStr,
          startTime,
          endTimeStr,
          endTime
        }
      })
      setSlotRanges([...updated])
    }
    else {
      setSlotRanges([])
    }


  }, [slotTiming])


  const handleOpenModal = (id, slot) => {
    let operating_rooms = [...selectedOperatingRoomSlots]

    let findAny = operating_rooms.find((ele) => ele.operating_room_id == id);
    if (findAny) {
      if (!findAny.operating_room_slots.includes(slot)) {
        findAny.operating_room_slots.push(slot)
      }
      else {
        let selectedSlots = findAny.operating_room_slots.filter((ele) => ele != slot)
        if (selectedSlots.length > 0) {
          findAny.operating_room_slots = selectedSlots
        } else {
          operating_rooms = operating_rooms.filter((ele) => ele.operating_room_id != id)
        }
      }
    }
    else {
      // operating_rooms = [...operating_rooms, { operating_room_id: id, operating_room_slots: [slot] }]
      operating_rooms = [{ operating_room_id: id, operating_room_slots: [slot] }]
    }
    setSelectedOperatingRoomSlots(operating_rooms);
    // setIsOpen(true);
  };

  // console.log("selectedOperatingRoomSlots", selectedOperatingRoomSlots)
  const handleCloseModal = () => {
    setIsOpen(false);
  };
  const timeSlots = () => {
    var timeSlots = [];
    // Populate the time slot array with 24-hour time slots
    for (var hour = 6; hour <= 20; hour++) {
      var timeSlot = hour.toString().padStart(2, "0") + ":00"; // Format the hour as HH:00
      let timeSlotHour = timeSlot.split(":")[0];
      //   console.log("time",parseInt(timeSlotHour))
      if (parseInt(timeSlotHour) >= 12) {
        let fromatedTimePm;
        if (parseInt(timeSlotHour) == 12) {
          fromatedTimePm = parseInt(timeSlotHour) + " pm";
          timeSlots.push(fromatedTimePm);
        } else {
          fromatedTimePm = parseInt(timeSlotHour) - 12 + " pm";
          timeSlots.push(fromatedTimePm);
        }
      } else {
        timeSlots.push(parseInt(timeSlotHour) + " am");
      }
    }

    // Display the time slot array
    // console.log("timeSlots", timeSlots);
    return timeSlots;
  };


  const customTimeSlots = (startHour, endHour, intervalMinutes) => {
    var timeSlots = [];

    // Validate input parameters
    if (startHour < 0 || startHour > 23 || endHour < 0 || endHour > 23 || intervalMinutes <= 0) {
      console.error("Invalid input parameters");
      return timeSlots;
    }

    // Populate the time slot array with custom time slots
    for (var hour = startHour; hour <= endHour; hour++) {
      for (var minute = 0; minute < 60; minute += intervalMinutes) {
        var formattedHour = hour.toString().padStart(2, "0");
        var formattedMinute = minute.toString().padStart(2, "0");
        var timeSlot = formattedHour + ":" + formattedMinute;
        let timeSlotHour = timeSlot.split(":")[0];

        if (parseInt(timeSlotHour) >= 12) {
          let formattedTimePm;
          if (parseInt(timeSlotHour) == 12) {
            formattedTimePm = parseInt(timeSlotHour) + ":" + formattedMinute + " pm";
            timeSlots.push(formattedTimePm);
          } else {
            formattedTimePm = parseInt(timeSlotHour) - 12 + ":" + formattedMinute + " pm";
            timeSlots.push(formattedTimePm);
          }
        } else {
          timeSlots.push(parseInt(timeSlotHour) + ":" + formattedMinute + " am");
        }
      }
    }

    // Display the custom time slot array
    // console.log("customTimeSlots", timeSlots);
    return timeSlots;
  };

  // console.log("operatingRooms", operatingRooms)
  useEffect(() => {
    // console.log("time slotss",timeSlots())
    // setSlots(timeSlots());
    setSlots(customTimeSlots(8, 23, 30))
   
    dispatch(
      getOperatingRoomsByClinicIdWithAppointments(clinic_settings.clinic_id, { active: true }, (res) => {
      })
    )
  }, []);


  const disabledBookedSlots = (bookedslots, idx) => {
    // console.log("booked slots",bookedslots)
    let slot = bookedslots[bookedslots.length - 1];
    let start_time =
      slot?.surgery_start_time.split(":")[0] +
      " " +
      slot?.surgery_start_time.split(":")[1].split(" ")[1];
    let end_time =
      slot?.surgery_end_time.split(":")[0] +
      " " +
      slot?.surgery_end_time.split(":")[1].split(" ")[1];
    // console.log("slot",slot,start_time,end_time)
    for (let i = slots.indexOf(start_time); i <= slots.indexOf(end_time); i++) {
      // return true
      if (i == idx) {
        // console.log("idx",idx)
        return true;
      }
    }
    // console.log("startIdx",slots.indexOf('7 am'))
    // if(idx==1||idx==2){

    //     return true
    // }
  };
  return (
    <div>
      {/* {
        slots.length>0?slots.map((slot,i)=>{
           return(
            <TimeCell
            key={i}
            slot={slot}
            // disabled={(doctor?.availability?.slots_time?.length - 1) === i ? true : (doctor?.appointments.filter(appointment => appointment?.appointment_date === selectedApptDate && appointment?.slot_time === slot).length > 0 ? true : false)}
            // onClick={() => handleSlotTiming(doctor, slot)}
        />
           )
        }):"No Tiime"
    
    } */}
      {/* {
        operatingRooms.length>0&&
        operatingRooms.map((data)=>{
            return(
                <div>
                    {data.name}
                    {data?.booked_slots.length==0&&slots.map((slot,j)=>{
                        return (
                           
                            <TimeCell slot={slot} />
                            
                        )
                    })}
                </div>
            )
        })

    } */}

      <div className="appointment-list-view ">
        <table className="table" style={{ minWidth: "auto" }}>
          <thead>
            {operatingRooms.map((doctor, i) => {
              let selectedDateAppointments = doctor && doctor.appointments ? doctor.appointments.filter((ele) => {
                return ele.appointment_date == selectedApptDate
              }) : []

              // console.log("selectedDateAppointments", doctor.name, selectedDateAppointments)

              let appointmentOperatingRoomSlots = [];

              selectedDateAppointments.forEach((appt) => {
                appt.selected_operating_rooms.forEach((ele) => {
                  let slots = ele.operating_room_slots
                  appointmentOperatingRoomSlots.push(...slots)
                })
              })
              // console.log("appointmentOperatingRoomSlots", doctor.name, appointmentOperatingRoomSlots)
              return (
                <>
                  <tr key={i}>
                    <th style={{ width: "280px" }}>
                      {/* <UserPicCell doctorInfo={doctorInfo} doctorDetails={doctor} /> */}
                      {doctor.name}
                    </th>
                    <th>
                      <div className="time-cell-wrapper d-flex flex-wrap">
                        {slots.map((slot, i) => {
                          let findAny = selectedOperatingRoomSlots.find((ele) => ele.operating_room_id == doctor._id);
                          let ifSelected = false
                          if (findAny) {
                            if (!findAny.operating_room_slots.includes(slot)) {
                              ifSelected = false
                            }
                            else {
                              ifSelected = true
                            }
                          }
                          else {
                            ifSelected = false
                          }
                          let slotInt = getHours(slot)

                          let ifInSelectedTimeSlots = slotRanges.filter((ele) => {
                            return (ele.startTimeStr.replace(/^0+/, '') == slot.replace(/^0+/, ''))
                          }).length > 0 ? true : false;

                          let slotUsed = appointmentOperatingRoomSlots.includes(slot)

                          // console.log("slot", slot, "slotUsed", slotUsed, "ifInSelectedTimeSlots", ifInSelectedTimeSlots)

                          return (
                            <TimeCell2
                              key={i}
                              className={ifSelected ? "selected-button" : ""}

                              slot={slot}
                              // disabled={(doctor?.availability?.slots_time?.length - 1) === i ? true : (doctor?.appointments.filter(appointment => appointment?.appointment_date === selectedApptDate && appointment?.slot_time === slot).length > 0 ? true : false)}
                              disabled={
                                slotUsed
                                || !ifInSelectedTimeSlots
                                // || disabledBookedSlots(doctor.booked_slots, i)
                              }
                              onClick={() => handleOpenModal(doctor?._id, slot)}
                            />
                          )
                        })}

                        {/* {slots.map((slot, i) => {
                        let findAny = selectedOperatingRoomSlots.find((ele) => ele.operating_room_id == doctor._id);
                        let ifSelected = false
                        if (findAny) {
                          if (!findAny.operating_room_slots.includes(slot)) {
                            ifSelected = false
                          }
                          else {
                            ifSelected = true
                          }
                        }
                        else {
                          ifSelected = false
                        }
                        let slotInt = getHours(slot)
                        // console.log("slot", slot, slotInt)
                        let ifInSelectedTimeSlots = slotRanges.filter((ele) => {

                          // return slotInt >= ele.startTime && slotInt < ele.endTime
                          return slotInt == ele.startTime
                        }).length > 0 ? true : false
                        // console.log("ifInSelectedTimeSlots", slot, ifInSelectedTimeSlots)
                        return (
                          <TimeCell2
                            key={i}
                            className={ifSelected ? "selected-button" : ""}

                            slot={slot}
                            // disabled={(doctor?.availability?.slots_time?.length - 1) === i ? true : (doctor?.appointments.filter(appointment => appointment?.appointment_date === selectedApptDate && appointment?.slot_time === slot).length > 0 ? true : false)}
                            disabled={!ifInSelectedTimeSlots || disabledBookedSlots(doctor.booked_slots, i)}
                            onClick={() => handleOpenModal(doctor?._id, slot)}
                          />
                        )
                      })} */}
                      </div>
                    </th>
                  </tr>
                  <tr className="space-rw">
                    <th colSpan="2">&nbsp;</th>
                  </tr>
                </>
              )
            })}
          </thead>
        </table>
      </div>

   
    </div>
  );
}

// operatingroom schedule
