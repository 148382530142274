import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import NoteBreifBox from "./NoteBreifBox";
import NoteDetail from "./NoteDetail";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";

import "./index.scss";
import { getNotesByPetId } from "../../../../../../context/actions/patient-module/notes";

const NotesPanel = ({ className, setShowNotes }) => {
  // const params = useParams();
  // const dispatch = useDispatch();

  const [extend, setExtend] = useState(null);

  const { notes } = useSelector((state) => state?.notesReducer);


  const [activeTab, setActiveTab] = useState(0);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [newNote, setNewNote] = useState(false);

  return (
    <>
      <div
        className={`notes-panel-wrapper position-fixed d-flex flex-column overflow-auto ${className}`}
      >
        <div className="head d-flex align-items-center">
          <h2>Notes</h2>
          <Button
            color="link"
            className="ms-auto panel-close-btn"
            onClick={() => {
              setShowNotes(false);
            }}
          >
            <span className="material-symbols-outlined">close</span>
          </Button>
        </div>

        <div className="notes-block d-flex h-100 relative">
          <div className="left-col d-flex flex-column overflow-auto">
            <div className="note-head d-flex flex-column">
              <div className="search-cell position-relative">
                <Input type="text" placeholder="Search" />
                <span className="position-absolute d-inline-flex align-items-center justify-content-center mb-0">
                  <span className="material-symbols-outlined mb-0">search</span>
                </span>
              </div>
            </div>
            <div className="note-list-wrapper overflow-auto">
              <Nav tabs className="flex-column overflow-visible">
                {notes?.map((note, i) => (
                  <>
                    <NavItem>
                      <NavLink
                        active={activeTab === i}
                        className={"w-100"}
                        onClick={() => {
                          toggle(i);
                        }}
                      >
                        <NoteBreifBox className="w-100" note={note} />
                      </NavLink>
                    </NavItem>
                  </>
                ))}
              </Nav>
            </div>
          </div>
          <div className="right-col flex-fill overflow-auto ml-2">
            <div className="note-tab-wrapper">
              <TabContent
                activeTab={activeTab}
                className="flex-fill overflow-auto position-relative"
              >
                {/* <LoaderUI FullWindow={false} inContainer={true} overlay={true} /> */}
                {/* <NodataFoundBlock className="h-100" /> */}

                {notes?.map((note, i) => (
                  <TabPane tabId={i}>
                    <NoteDetail
                      build={() => {
                        setExtend(note);
                        setNewNote(true);
                      }}
                      sections={note?.sections}
                    />
                  </TabPane>
                ))}
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotesPanel;
