import React, { useState } from "react";
// import { RiCalendar2Line, RiTimerLine } from "react-icons/ri";

import { dobInYearMonthDays } from "../../utils/common";
import CustomSelect from "../../shared/SelectBox";
import { useSelector, useDispatch } from "react-redux";
import { getAvailableKennels } from "../../context/actions/kennels";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Button, Input } from "reactstrap";
import moment from "moment";
import { faUnderline } from "@fortawesome/free-solid-svg-icons";
import { useClinicBreed } from "hooks/useClinicBreed";
import { addBoarding, isPetBoarded } from "context/actions/boarding";

const KennelInfo = ({
  patient,
  setStep,
  setShow,
  setIsPetAddedToTheKennel,
  isPetAddedToTheKennel,
  changePatient
}) => {
  const userDetails = useSelector((state) => state.user.user_details);
  const { clinic_details } = useSelector((state) => state.clinicReducer);

  const clinicBreed = useClinicBreed()

  const dispatch = useDispatch();

  const allKennels = useSelector((state) => state?.kennelReducer?.kennels);
  const [date, setDate] = useState();
  const [end_date, setEndDate] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [kennel, setKennel] = useState("");
  const [boardingPurpose, setBoardingPurpose] = useState("");
  const [notes, setNotes] = useState("");

  function parseDateTime(date, startTime) {
    const dateTimeString = `${date}T${startTime}:00.000Z`;
    return dateTimeString;
  }

  // const kennels = ["kennel 1", "kennel 2", "kennel 3", "kennel 4", "kennel 5", "kennel 6", "kennel 7", "kennel 8", "kennel 9", "kennel 10"];
  const boardingPurposeArr = ["Hospitalization", "ICU", "PACU", "Observation", "Procedure"];
  console.log("date", date);
  console.log("startTime", startTime);

  useEffect(() => {
    if (
      date !== undefined &&
      startTime !== undefined &&
      end_date !== undefined &&
      endTime !== undefined
    ) {
      console.log(date + " " + startTime, end_date + " " + endTime);
      // dispatch(getAvailableKennels({start_date: moment(date + ' ' + startTime, 'YYYY-MM-DD HH:mm').toISOString(), end_date: moment(end_date + ' ' + endTime, 'YYYY-MM-DD HH:mm').toISOString(), clinic_id: clinic_details?._id}, () => {} ))
      dispatch(
        getAvailableKennels(
          {
            start_date: parseDateTime(date, startTime),
            end_date: parseDateTime(end_date, endTime),
            clinic_id: clinic_details?._id,
          },
          () => {}
        )
      );
    }
  }, [date, startTime, endTime, end_date]);

  const assignKennelToPatient = () => {
    // console.log("patient info", patient,date,startTime,endTime,kennel,boardingPurpose,notes)
    const { added_by, _id, pet_name, pet_image, speciesData, gender } = patient;
    const species_name = speciesData?.species_name;
    if(kennel === "") {
      toast.warn('Please Select a Kennel');
      return
    } else if (boardingPurpose === ""){
      toast.warn('Please Select a Purpose');
      return
    }
    
    const kennel_info = {
      patient_id: _id,
      boarding_start_time: startTime,
      boarding_end_time: endTime,
      boarding_date: date,
      boarding_end_date: end_date,

      kennel: kennel,
      notes: notes,
      boarding_purpose: boardingPurpose,
      gender: gender,
      pet_image: pet_image,
      pet_name: pet_name,
      species_name: species_name,
    };
    let postedobj = {
      created_by: userDetails?._id,
      clinic_id: clinic_details?._id,
      // kennel_info:[kennel_info]
      patient_id: _id,
      // boarding_start_date: moment(date + ' ' + startTime, 'YYYY-MM-DD HH:mm').toDate(),
      // boarding_end_date: moment(end_date + ' ' + endTime, 'YYYY-MM-DD HH:mm').toDate(),

      boarding_start_date: new Date(`${date} ${startTime}`),
      boarding_end_date: moment(
        end_date + " " + endTime,
        "YYYY-MM-DD HH:mm"
      ).toDate(),

      //boarding_start_time: startTime,
      //boarding_end_time: endTime,
      //boarding_end_date: end_date,
      //boarding_date: date,
      kennel: kennel,
      notes: notes,
      boarding_purpose: boardingPurpose,
      gender: gender,
      pet_image: pet_image,
      pet_name: pet_name,
      species_name: species_name,
    };
    // openPatientPanel()

    dispatch(addBoarding(postedobj, (data) => {
      toast.success(data.message);
      //call api for pet is now onboard now

      dispatch(isPetBoarded(_id, { isBoarded: true }, (data) => {
        toast.success(data.message);
        setShow(false);
        setIsPetAddedToTheKennel(!isPetAddedToTheKennel);
      }))
    }))
  };

  return (
    <div>
      <h2 className="text-3xl font-bold">New Kennel Boarding</h2>
      <div className="mt-4 space-y-2">
        <div className="flex items-center justify-between">
          <p className="text-lg font-medium">Patient Information</p>
          <button className="text-[#3D3EFD] hover:underline" onClick={changePatient}>Change</button>
        </div>
        <div className="bg-gray-50 rounded-xl p-2 flex items-center gap-x-2">
          <div>
            <img
              style={{width: '50px', height: '50px'}}
              src={patient?.pet_image}
              alt="patient"
              className=" bg-gray-100 rounded-lg object-cover"
            />
          </div>
          <div className="space-y-2.5">
            <h4 className="text-lg capitalize font-medium">
              {patient?.pet_name}{" "}
              <span className="ml-1 text-sm">(VRN {patient?.vrn})</span>
            </h4>
            <p className="text-sm capitalize">
              {dobInYearMonthDays(new Date(patient?.dob) || new Date())} Old
              (DOB: {moment(patient?.dob).format("MM/DD/YYYY")}) /{" "}
              {patient?.gender} / {clinicBreed(patient?.breed?._id)  || patient?.breed?.breed} /{" "}
              {patient?.color ?? "No color"}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-2 space-y-2">
        <p className="text-lg font-medium">Kennel Boarding Duration</p>
        <div className="flex items-center gap-x-2 w-full">
          <div className="w-1/2">
            <label htmlFor="date" className="text-xs text-gray-500">
              Start Date
            </label>

            <input
              id="date"
              name="date"
              type="date"
              onChange={(e) => setDate(e.target.value)}
              className="w-full rounded-xl border overflow-hidden h-12 px-2.5"
            />
          </div>
          <div className="w-1/2">
            <label htmlFor="startTime" className="text-xs text-gray-500">
              Start Time
            </label>

            <input
              id="startTime"
              name="startTime"
              type="time"
              onChange={(e) => setStartTime(e.target.value)}
              className="w-full rounded-lg border overflow-hidden h-12 px-2.5"
            />
          </div>
        </div>

        <div className="flex items-center gap-x-2 w-full">
          <div className="w-1/2">
            <label htmlFor="end_date" className="text-xs text-gray-500">
              End Date
            </label>

            <input
              id="end_date"
              name="end_date"
              type="date"
              onChange={(e) => setEndDate(e.target.value)}
              className="w-full rounded-xl border overflow-hidden h-12 px-2.5"
            />
          </div>
          <div className="w-1/2">
            <label htmlFor="endTime" className="text-xs text-gray-500">
              End Time
            </label>

            <input
              id="endTime"
              name="endTime"
              type="time"
              onChange={(e) => setEndTime(e.target.value)}
              className="w-full rounded-lg border overflow-hidden h-12 px-2.5"
            />
          </div>
        </div>
        <div className="space-y-2 my-2">
          <p className="text-lg font-medium">Boarding Information</p>
          {/* <CustomSelect
            tasks={kennels}
            setSelectedTask={setKennel}
            selectedTask={kennel}
            placeholder="Kennel Type"
          /> */}

          {/* <label htmlFor="kennels" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select an option</label> */}
          <Input type="select" id="kennels" className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " value={kennel} onChange={(e) => setKennel(e.target.value)}>
            <option value="">Select a Kennel</option>
            {
              allKennels.map((kennel, i) => {
                return <option key={i} disabled={kennel?.available ? null : true} value={kennel?._id}>{kennel?.kennel_name}</option>
              })
            }
          </Input>


          <Input type="select" placeholder="Boarding Purpose" onChange={(e) => {setBoardingPurpose(e.target.value)}}>
            <option value="">Select a Purpose</option>

            {boardingPurposeArr?.map((item) => {return (
              <option>{item}</option>
            )})

            }

          </Input>
          <input
            type="text"
            className="p-2.5 rounded-lg border w-full"
            placeholder="Add Notes"
            onChange={(e) => {
              setNotes(e.target.value);
            }}
          />
        </div>
        <div className="flex justify-end items-center gap-2 mt-3">
          <button
            onClick={() => setStep(1)}
            className="px-4 py-2.5 rounded-lg bg-gray-100"
          >
            Cancel
          </button>
          <button
            onClick={assignKennelToPatient}
            className="px-4 py-2.5 rounded-lg text-white bg-[#11CA9D]"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default KennelInfo;
