import moment from "moment";
import React, { useEffect } from "react";
import { Button } from "reactstrap";
import PetPlaceholder from "../../../../../../shared/PetPlaceholder";
import { dobInYearMonthDays } from "utils/common";


function dateAgo(date) {
    try {
        var startDate = new Date(date);
        var diffDate = new Date(new Date() - startDate);
        return ((diffDate.toISOString().slice(0, 4) - 1970) + "Y " +
            diffDate.getMonth() + "M " + (diffDate.getDate() - 1) + "D");
    }
    catch (err) {
        console.log("err", err)
        return ""
    }

}

function convertTimestampToDate(datetimestamp) {
    try {
        let date = moment(datetimestamp).format('YYYY-MM-DD')
        return date
    }
    catch (err) {
        console.log("err", err)
        return ""
    }
}

let wellnessPlanObj = {
    "Gold": {
        planType: "golden",
        planName: "Golden Care",
        planPriceStr: "Price $300 (One Time)",
        planPrice: 300,
        className: "golden-plan"
    },
    "Silver": {
        planType: "silver",
        planName: "Silver Care",
        planPriceStr: "Price $100 (One Time)",
        planPrice: 100,
        className: "super-plan"
    },
    "Bronze": {
        planType: "primary",
        planName: "Primary Care",
        planPriceStr: "Price $200 (One Time)",
        planPrice: 200,
        className: "primary-plan"
    }
}

const PetDetails = ({ pet }) => {


    return (
        <>
            <div className="profile-head my_pet_box1">
                <div className="user-profile-cell d-inline-flex align-items-center">
                    <div className="pic position-relative">
                        {pet?.pet_image ? 
                        <img class="img-fluid w-100 h-100" src={pet?.pet_image || "/static/media/pet-thumbnail.0fabb91ec3fefdf05384.png"} alt="Pet" />  
                        :
                        <PetPlaceholder style={{borderRadius: '10px'}} className="img-fluid w-100 h-100" />
                        }
                        {/*
                        
                        <span className="badge-icon position-absolute">
                            <div className="bg-[#DD9323] inline w-[20px] p-[2px] absolute rounded-[5px] top-[7px] left-[-26px]">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" color="#fff" height="15" width="15" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <circle cx="4.5" cy="9.5" r="2.5"></circle><circle cx="9" cy="5.5" r="2.5"></circle>
                                    <circle cx="15" cy="5.5" r="2.5"></circle><circle cx="19.5" cy="9.5" r="2.5"></circle>
                                    <path d="M17.34 14.86c-.87-1.02-1.6-1.89-2.48-2.91-.46-.54-1.05-1.08-1.75-1.32-.11-.04-.22-.07-.33-.09-.25-.04-.52-.04-.78-.04s-.53 0-.79.05c-.11.02-.22.05-.33.09-.7.24-1.28.78-1.75 1.32-.87 1.02-1.6 1.89-2.48 2.91-1.31 1.31-2.92 2.76-2.62 4.79.29 1.02 1.02 2.03 2.33 2.32.73.15 3.06-.44 5.54-.44h.18c2.48 0 4.81.58 5.54.44 1.31-.29 2.04-1.31 2.33-2.32.31-2.04-1.3-3.49-2.61-4.8z"></path>
                                </svg>
                            </div>
                    </span>*/}
                    </div>
                    <div className="txt">
                        <h2>{pet.pet_name}</h2>
                        <h3>{pet.vrn}</h3>
                        <h4 className="active">{pet.active ? "Active" : "Inactive"}</h4>
                    </div>
                </div>
                {/*
                <div className="outstanding_invoice position-relative">
                    <h4>$52,800</h4>
                    <h3 className="mb-0">Outstanding Invoices</h3>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <mask id="mask0_6529_110610" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
                            <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_6529_110610)">
                            <path d="M8.87344 17.6234C8.72344 17.4734 8.64844 17.2984 8.64844 17.0984C8.64844 16.8984 8.72344 16.7234 8.87344 16.5734L12.9484 12.4984L8.87344 8.42344C8.72344 8.27344 8.64844 8.09844 8.64844 7.89844C8.64844 7.69844 8.72344 7.52344 8.87344 7.37344C9.02344 7.22344 9.19844 7.14844 9.39844 7.14844C9.59844 7.14844 9.77344 7.22344 9.92344 7.37344L14.4234 11.8734C14.5068 11.9568 14.5694 12.0524 14.6114 12.1604C14.6528 12.2691 14.6734 12.3818 14.6734 12.4984C14.6734 12.6151 14.6528 12.7274 14.6114 12.8354C14.5694 12.9441 14.5068 13.0401 14.4234 13.1234L9.92344 17.6234C9.77344 17.7734 9.59844 17.8484 9.39844 17.8484C9.19844 17.8484 9.02344 17.7734 8.87344 17.6234Z" fill="white" />
                        </g>
                    </svg>
                    </div>*/}
                <div className="profile-description row-block mt-2 pt-1">
                    <p className="d-flex">
                        <label>Date of Birth</label>
                        <span>{moment(pet.dob).format('MM/DD/YYYY')}</span>
                    </p >
                    <p className="d-flex">
                        <label>Age</label>
                        <span>{dobInYearMonthDays(new Date(pet?.dob || new Date()))} old</span>
                    </p>
                    <p className="d-flex">
                        <label>Identity</label>
                        {/* <span>MN / Pug / Black</span> */}
                        <span>{pet.identity || pet.speciesDetails && pet.speciesDetails.species_name + " / " + pet.breedDetails.breed + " / " + pet.color}</span>
                    </p>
                    <p className="d-flex">
                        <label>Rabies Tag No.</label>
                        {/* <span>RBS8564235</span> */}
                        <span>{pet.rabies_number || '--'}</span>
                    </p>
                    <p className="d-flex">
                        <label>Microchip No.</label>
                        {/* <span>MRC4561230</span> */}
                        <span>{pet.microchip_number || '--'}</span>
                    </p>
                    <p className="d-flex">
                        <label>CPR</label>
                        {/* <span>Advance Life Support</span> */}
                        <span>{pet?.cpr || '--'}</span>
                    </p>
                    <p className="d-flex">
                        <label>IV Locations</label>
                        {/* <span>Right Jugular, Left Medial Saphenous</span> */}
                        <span>{pet?.iv_locations ? typeof pet?.iv_locations === 'string' ? pet?.iv_locations : pet?.iv_locations?.map(e => e?.locationDetails?.filter(o => o?.location)?.map(i => i?.location).join(", ")).join(", ")  || "--": '--'}</span>
                    </p>
                </div>
                {/*
                <div className="profile-plan row-block">
                    <div className="plan-box d-flex align-items-center">
                        <div className="pic">
                            <img className="img-fluid w-100 h-100" src={require('../../../../../../assets/img/vetRecorEMR/pet-badge-icon-yellow.svg').default} alt="Badge" />
                        </div>
                        {
                            pet && pet.wellnessPlanDetails && Object.entries(pet.wellnessPlanDetails).length > 0 && (
                                <div className="txt">
                                    <h3>{pet.wellnessPlanDetails.name}</h3>
                                    {pet.wellness_plan_added_date && pet.wellness_plan_added_date != '' && <h4>Purchased on {convertTimestampToDate(pet?.wellness_plan_added_date)}</h4>}
                                </div>
                            )
                        }

                    </div>
                    </div>*/}
            </div>
        </>
    );
};

export default PetDetails;
