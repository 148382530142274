
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../../../api-client";
import { apiUrl, PORT } from "../../../../environment";
import { toasterConfig } from "../../../../utils/constants";

toast.configure();
/**
 * All Api endpoints to be call
*/

/**
 * get all vaccination dues details
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns 
 */
export const getVaccinationDetailsList = (petId, params, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, } = getState();
        if (petId) {
            dispatch({ type: "MEDICAL_HISTORY_LOADING", payload: true })
            ApiClient.get(`${apiUrl}${PORT}/vaccination-due/get-all/${petId}`, params, token, dispatch, getState).then((response) => {
                dispatch({ type: "MEDICAL_HISTORY_LOADING", payload: false })
                if (!response) {
                    dispatch({ type: "VACCINATION_DUE_LIST", payload: [] })
                    return callback();
                }
                else if (response?.status) {
                    dispatch({ type: "VACCINATION_DUE_LIST", payload: response?.response_data })
                    return callback(response);
                } else {
                    dispatch({ type: "VACCINATION_DUE_LIST", payload: [] })
                    return callback(response);
                }
            })
        } else {
            return callback({ status: false });
        }
    };
};

/**
 * get all vaccination dues details
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns 
 */
export const getSurgicalHistoryList = (petId, params, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, } = getState();
        if (petId) {
            dispatch({ type: "MEDICAL_HISTORY_LOADING", payload: true })
            ApiClient.get(`${apiUrl}${PORT}/medical-history/get-all-surgical-history/${petId}`, params, token, dispatch, getState).then((response) => {
                dispatch({ type: "MEDICAL_HISTORY_LOADING", payload: false })
                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    dispatch({ type: "SURGICAL_HISTORY", payload: response?.response_data })
                    return callback(response);
                } else {
                    dispatch({ type: "SURGICAL_HISTORY", payload: response?.response_data })
                    return callback(response);
                }
            })
        } else {
            return callback({ status: false });
        }
    };
};


/**
 * @description function use to add new surgical history
 * @param {*} params accepting object
 */
export const addSurgicalHistory = (data, callback) => {
    return (dispatch, getState) => {
        const { user: { token } } = getState();
        ApiClient.post(`${apiUrl}${PORT}/medical-history/add-surgical-history`, data, token, dispatch, getState).then((response) => {
            if (!response) {
                return callback();
            }
            else if (!response && response.errors) {
                toast.error(response.errors.msg, toasterConfig);
                return callback(response);
            } else if (response) {
                toast.dismiss();
                toast.success(response.msg, toasterConfig);
                return callback(response);
            } else {
                //toast.error(response.message, toasterConfig);
                return callback();
            }
        });
    };
};

