import React from "react";
import "./MedicalHistory.scss";
import { useEffect, useState } from "react";
import UserPlaceholder from "shared/UserPlaceholder";
import {
  dateDiffFromNow,
  dateFromDateTime,
  dueDaysFromDate,
} from "utils/common";
import MedicalBreifBox from "../../PatientDetail/TabScreens/MedicalHistory/MedicalBreifBox";
import {
  Button,
  Table,
  DropdownToggle,
  ButtonDropdown,
  Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getConditionByPetId } from "context/actions/patient-module/overview/current-condition";
import {
  getSurgicalHistoryList,
  getVaccinationDetailsList,
} from "context/actions/patient-module/medical-history";
import NodataFoundBlock from "shared/NoDataFoundBlock";
import moment from "moment";
const MedicalHistory = () => {
  const [dropdownOpen, setOpen] = useState(true);
  const toggle = () => setOpen(!dropdownOpen);
  const dispatch = useDispatch();
  const [pageLoader, setPageLoader] = useState(true);
  const { petDetails } = useSelector((state) => state.petOwnerDashboardReducer);
  const { surgicalHistory, vaccinationList } = useSelector(
    (state) => state.medicalHistoryReducer
  );

  const { conditionList } = useSelector(
    (state) => state.currentConditionReducer
  );
  useEffect(() => {
    if (petDetails?._id) {
      setPageLoader(true);
      dispatch(getConditionByPetId(petDetails?._id, {}, () => {}));
      dispatch(getSurgicalHistoryList(petDetails?._id, {}, () => {}));
      dispatch(getVaccinationDetailsList(petDetails?._id, {}, () => {}));
      setPageLoader(false);
    }
  }, [petDetails?._id]);

  const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);
  
  return (
    <div id="medical-history-container">
      {pageLoader ? (
        <div className="f-center h-50">
          <Spinner animation="border" />
        </div>
      ) : (
        <div className="condition-block p-3">
           {(!conditionList?.length || conditionList?.length === 0 )&&
          (!vaccinationList?.length || vaccinationList?.length === 0) &&
          (!surgicalHistory?.length || surgicalHistory?.length === 0)? (
            <NodataFoundBlock type="medical history" />
          ) : (
            <>
              {conditionList?.length > 0 && (
                <>
                  <div className="head section-head d-flex align-items-center">
                    <div className="left-col">
                      <h3>Medical Conditions</h3>
                    </div>
                  </div>
                  {[...(conditionList || [])]
                    .filter((condition) => condition?.status === "In Diagnosis")
                    .map((condition) => (
                      <>
                        <div key={condition?._id} className="inner my-2">
                          <div className="conditon-box">
                            <div className="head d-flex align-items-center mb-0">
                              <div className="left-col brief_col">
                                <p style={{ textTransform: "capitalize" }}>
                                  {condition?.description?.toString()}
                                </p>
                              </div>
                              {/* <div className="right-col ms-auto">
                                <Button
                                  color="link"
                                  className="position-relative"
                                >
                                  Add Notes
                                </Button>
                                <Button
                                  color="link"
                                  className="position-relative"
                                >
                                  Share Details
                                </Button>
                              </div> */}
                            </div>
                            <div className="inner">
                              <div className="breif-head-info d-flex align-items-center flex-wrap mb-2">
                                <div className="item-cell d-inline-flex align-items-center">
                                  <div className="pic d-inline-flex rounded-circle overflow-hidden">
                                    {/* {condition?.diagnosedBy?.user_image ? (
                            <img
                              className="img-fluid w-100 h-100"
                              src={
                                condition?.diagnosedBy?.user_image ||
                                require("../../../../../../assets/img/vetRecorEMR/user-thumbnail.png")
                              }
                              alt="User"
                            />
                          ) : (
                            <UserPlaceholder />
                          )} */}
                                    <UserPlaceholder />
                                  </div>
                                  <div className="txt">
                                    {`${
                                      condition?.diagnosedBy?.title
                                        ? condition?.diagnosedBy?.title + " "
                                        : ""
                                    } ${condition?.diagnosedBy?.firstname} ${
                                      condition?.diagnosedBy?.lastname
                                    }`}
                                  </div>
                                  <div className="icon d-inline-flex">
                                    <span className="material-symbols-outlined">
                                      auto_mode
                                    </span>
                                  </div>
                                </div>
                                {/* {condition?.end_date && ( */}
                                <div className="item-cell d-inline-flex align-items-center">
                                  <div className="txt">
                                    Start Date:{" "}
                                    {dateFromDateTime(
                                      condition?.start_date,
                                      "MM/DD/yyyy"
                                    )}
                                  </div>
                                </div>
                                {/* )} */}
                                <div className="item-cell d-inline-flex align-items-center">
                                  <div className="txt">Status :</div>
                                  <div
                                    className="status inprogress"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {condition?.status}
                                  </div>
                                </div>
                              </div>
                              <div className="breif-desc">
                                <p>{condition?.notes}</p>
                              </div>
                              <div className="thumbnail-list d-flex flex-wrap">
                                {(condition?.images || []).map((img, index) => (
                                  <span
                                    key={index}
                                    className="d-inline-flex overflow-hidden"
                                  >
                                    <img
                                      className="img-fluid w-100 h-100"
                                      src={
                                        img ||
                                        require("../../../../../src/assets/img/vetRecorEMR/pet-thumbnail2.png")
                                      }
                                      alt="Pet"
                                    />
                                  </span>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}

                  <div className="row-block">
                    <div className="row flex-md-row flex-column">
                      {[...(conditionList || [])]
                        ?.filter(
                          (condition) => condition?.status === "Completed"
                        )
                        ?.map((item, i) => (
                          <div
                            key={i}
                            className="item-col col-12 col-xl-4 col-md-6 col-sm-12 mb-2 mb-lg-2"
                          >
                            {/* <MedicalBreifBox
                              data={{
                                title: item?.type?.toString(),
                                startDate: dateFromDateTime(
                                  item?.start_date,
                                  "MM/DD/yyyy"
                                ),
                                endDate: `${
                                  item?.end_date
                                    ? dateFromDateTime(
                                        item?.end_date,
                                        "MM/DD/yyyy"
                                      )
                                    : ""
                                }`,
                                nameWithTitle: `${
                                  item?.diagnosedBy?.title
                                    ? item?.diagnosedBy?.title + " "
                                    : ""
                                } ${item?.diagnosedBy?.firstname} ${
                                  item?.diagnosedBy?.lastname
                                }`,
                                image: item?.diagnosedBy?.user_image,
                                description: item?.notes,
                              }}
                            /> */}

<MedicalBreifBox
                              data={{
                                title: item?.type?.toString(),
                                startDate: dateFromDateTime(
                                  item?.start_date,
                                  "MM/DD/yyyy"
                                ),
                                endDate: `${
                                  item?.end_date
                                    ? dateFromDateTime(
                                        item?.end_date,
                                        "MM/DD/yyyy"
                                      )
                                    : ""
                                }`,
                                nameWithTitle: `${
                                  item?.diagnosedBy?.title
                                    ? item?.diagnosedBy?.title + " "
                                    : ""
                                }  ${	clinic_settings?.doctor_name_format  === 0  ? 
                                  <span> {item?.diagnosedBy?.lastname} {item?.diagnosedBy?.firstname}</span> 
                                 : <span>{item?.diagnosedBy?.firstname} {item?.diagnosedBy?.lastname} </span> 
                                }}`,
                                image: item?.diagnosedBy?.user_image,
                                description: item?.notes,
                              }}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              )}

              {surgicalHistory?.length > 0 && (
                <>
                  <div className="head section-head d-flex align-items-center my-3">
                    <div className="left-col">
                      <h3>Surgical History</h3>
                    </div>
                  </div>
                  <div className="row-block">
                    <div className="row flex-md-row flex-column">
                      {[...(surgicalHistory || [])]?.map((item, i) => (
                        <div
                          key={i}
                          className="item-col col-12 col-xl-4 col-md-6 col-sm-12 mb-2 mb-lg-2"
                        >
                          <MedicalBreifBox
                            data={{
                              title: item?.type,
                              description: item?.notes,
                              // nameWithTitle: `${
                              //   item?.doctor_details?.title
                              //     ? item?.doctor_details?.title + " "
                              //     : ""
                              // } ${item?.doctor_details?.firstname} ${
                              //   item?.doctor_details?.lastname
                              // }`,

                              nameWithTitle: `${
                                clinic_settings?.doctor_name_format === 0
                                  ? `${
                                      item?.doctor_details?.title
                                        ? item?.doctor_details?.title + " "
                                        : ""
                                    } 
                  
                   ${item?.doctor_details?.lastname}  ${" ,"} ${item?.doctor_details?.firstname}
                  `
                                  : `${
                                      item?.doctor_details?.title
                                        ? item?.doctor_details?.title + " "
                                        : ""
                                    } 
                  
                  ${item?.doctor_details?.firstname} ${item?.doctor_details?.lastname}
                  `
                              }`,
                              
                              image: item?.doctor_details?.user_image,
                              date: dateFromDateTime(item?.date, "MM/DD/yyyy"),
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}

              {vaccinationList?.length > 0 && (
                <>
                  <div className="head section-head d-flex align-items-center my-3">
                    <div className="left-col">
                      <h3>Vaccinations</h3>
                    </div>
                  </div>
                  <div className="table-listing-blc vaccination-listing">
                    <Table className="checkbox-listing ">
                      <thead>
                        <tr>
                          <th style={{ minWidth: "192px" }}>Vaccine Name</th>
                          {/* <th>Dose</th> */}
                          <th style={{ minWidth: "165px" }}>Status</th>
                          <th style={{ minWidth: "106px" }}>Due Date</th>
                          <th style={{ minWidth: "106px" }}>Administered On</th>
                          <th style={{ minWidth: "175px" }}>Administered By</th>
                          <th style={{ minWidth: "50px" }} className="text-end">
                            &nbsp;
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {vaccinationList.map((item, i) => {
                          const dueDate = item?.due_dates?.[0];
                          return (
                            <>
                              <tr key={i}>
                                <td style={{ textTransform: "capitalize" }}>
                                  {item?.product_name}
                                </td>
                                {/* <td>-</td> */}
                                <td>
                                  <span
                                    className={`${
                                      dateDiffFromNow(dueDate).status == "IN"
                                        ? "success-cell"
                                        : dateDiffFromNow(dueDate).status ==
                                          "OUT"
                                        ? "inactive-cell"
                                        : "pending-cell"
                                    } status-cell`}
                                  >
                                    {dueDaysFromDate(dueDate)}
                                  </span>
                                </td>
                                <td>
                                  {dateFromDateTime(dueDate, "MM/DD/yyyy")}
                                </td>
                                <td>
                                  {item?.administrations?.length > 0
                                    ? moment(
                                        item?.administrations?.[0]?.date
                                      ).format("MM/DD/YYYY")
                                    : "--"}
                                </td>
                                <td>
                                  {item?.administrations?.length > 0 && (
                                    <div className="pic-cell d-inline-flex align-items-center">
                                      <div className="pic rounded-circle d-inline-flex overflow-hidden">
                                        {item?.administrations?.[0]?.created_by
                                          ?.user_image ? (
                                          <img
                                            className="rounded-circle w-100 h-100"
                                            src={
                                              item?.administrations?.[0]
                                                ?.created_by?.user_image ||
                                              require("../../../../assets/img/vetRecorEMR/user-thumbnail.png")
                                            }
                                            alt="User"
                                          />
                                        ) : (
                                          <UserPlaceholder />
                                        )}
                                      </div>
                                      {`${
                                        item?.administrations?.[0]?.created_by
                                          ?.title
                                          ? item?.administrations?.[0]
                                              ?.created_by?.title + " "
                                          : ""
                                      } ${
                                        item?.administrations?.[0]?.created_by
                                          ?.firstname
                                      } ${
                                        item?.administrations?.[0]?.created_by
                                          ?.lastname
                                      }`}
                                    </div>
                                  )}
                                </td>
                                <td className="action-cell">
                                  <ButtonDropdown
                                    isOpen={dropdownOpen}
                                    toggle={toggle}
                                  >
                                    <DropdownToggle
                                      color="link"
                                      className="more-btn"
                                    >
                                      <span className="icomoon-more"></span>
                                    </DropdownToggle>
                                  </ButtonDropdown>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default MedicalHistory;
