import React from "react";
import './index.scss';
import { useSelector } from "react-redux";

const CalendarFoot = () => {
	const {clinic_settings} = useSelector(state => state.clinicSettingsReducer)
	return (
		<>
			<div className="calendar-foot text-center">
				<div className="item clinic d-inline-flex align-items-center">
					<span style={{backgroundColor: clinic_settings?.color_preferences?.['in-clinic'] }} className="d-inline-flex rounded-circle" ></span>
					In Clinic
				</div>
				<div className="item phone d-inline-flex align-items-center">
					<span style={{backgroundColor: clinic_settings?.color_preferences?.['phone-call'] }} className="d-inline-flex rounded-circle"></span>
					Phone Call
				</div>
				<div className="item d-inline-flex align-items-center">
					<span style={{backgroundColor: clinic_settings?.color_preferences?.['video-call'] }}  className="d-inline-flex rounded-circle"></span>
					Video Call
				</div>
				<div className="item video-conference d-inline-flex align-items-center">
					<span style={{backgroundColor: clinic_settings?.color_preferences?.['surgery'] }} className="d-inline-flex rounded-circle"></span>
					Surgery
				</div>
				<div className="item video-conference d-inline-flex align-items-center">
					<span style={{backgroundColor: clinic_settings?.color_preferences?.['appointment-block'] }} className="d-inline-flex rounded-circle"></span>
					Appointment Block
				</div>
			</div>
		</>
	);
};

export default CalendarFoot;
