import React, { useState } from 'react';
import AppLogo from "../../../shared/AppLogo";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import './terms.css';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button } from 'reactstrap';
const TermsOfServices = () => {

    const [activeTab, setActiveTab] = useState('1');
    return (
        <>
            <div className="col-blc left-col terms_left_col position-relative overflow-auto">
                <div className="head">
                    <div className="logo-rw">
                        <AppLogo />
                    </div>
                    <h1>
                        <span className="txt-2">Discover  the world’s Contra to popular Ipsum simple</span>
                    </h1>
                </div>
            </div>
            <div className="col-blc right-col terms_right_col">
                <div className="overflow-auto align-self-stretch">
                    <div className="login-blc m-auto signup-box">

                        <div className="signup-head">
                            <div className="d-flex justify-content-between pr-2">
                                <h2>Terms of Service</h2>
                                <Link to="/login">
                                    Logout
                                </Link>
                            </div>
                            <div className='flex_div d-flex w-100 m-auto'>
                                <div className='left_terms_col'>
                                    <TabContent activeTab={activeTab} className='tab_height'>
                                        <TabPane tabId="1">
                                            <h4>
                                                Updated Febuary 17, 2021
                                            </h4>
                                            <h3>This Terms of Service describes the rights and responsibilities that apply to your use of Vet’s websites, services, and mobile app, each owned and operated by Vet Ltd.</h3>
                                            <h5>Heading 1</h5>
                                            <h3>1</h3>
                                            <h5>Heading 2</h5>
                                            <h3>2</h3>
                                            <h5>Heading 3</h5>
                                            <h3>3</h3>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <h4>
                                                Updated Febuary 17, 2021
                                            </h4>
                                            <h3>This Terms of Service describes the rights and responsibilities that apply to your use of Vet’s websites, services, and mobile app, each owned and operated by Vet Ltd.</h3>
                                            <h5>Heading 1</h5>
                                            <h3>1</h3>
                                            <h5>Heading 2</h5>
                                            <h3>2</h3>
                                            <h5>Heading 3</h5>
                                            <h3>3</h3>
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <h4>
                                                Updated Febuary 17, 2021
                                            </h4>
                                            <h3>This Terms of Service describes the rights and responsibilities that apply to your use of Vet’s websites, services, and mobile app, each owned and operated by Vet Ltd.</h3>
                                            <h5>Heading 1</h5>
                                            <h3>1</h3>
                                            <h5>Heading 2</h5>
                                            <h3>2</h3>
                                            <h5>Heading 3</h5>
                                            <h3>3</h3>
                                        </TabPane>
                                    </TabContent>
                                </div>
                                <div className='right_terms_col text-center'>
                                    <Nav tabs className='d-flex justify-content-end flex-column align-items-end border-0 tab_options'>
                                        <NavItem>
                                            <NavLink className={activeTab == '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                                Terms of Service
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={activeTab == '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                                                Basic Information
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={activeTab == '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
                                                Business details
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                            </div>
                            <div className="btn-cell d-flex mt-4 pt-3 mb-0">
                                <Button
                                    color="primary"
                                >
                                    I AGREE
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TermsOfServices;