import React from "react";
import { Spinner } from 'reactstrap'; // eslint-disable-line
import "./loader.scss";
const LoaderUI = (
	{
		className = '',
		loader = true,
		overlay = false,
		FullWindow = true,
		color = 'primary',
		size = '',
		inContainer = false,
		message = false
	}
) => (
	<>
		<div className={`loaderWrapper d-flex align-items-center justify-content-center 
            ${className} 
            ${FullWindow ? 'position-fixed' : ''}
			${inContainer ? 'position-absolute' : ''}
            `}>
			{
				overlay ? <span className={`loaderOverlay position-absolute`}></span> : null
			}
			{
				loader ?
					<div className="spinner-blc text-center">
						<Spinner color='primary' /> 
						{/*
						<lottie-player src="https://lottie.host/2d3e7e86-8fa7-406b-b666-b129f1437dfb/bfqVWN6Ri5.json" background="transparent" speed="1" loop autoplay></lottie-player>
						*/}
						{/* {
							message && <span className="d-block">{message}</span>
						} */}

					</div>
					: null
			}
		</div>
	</>
);

export default LoaderUI;