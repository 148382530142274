import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button, ButtonDropdown, DropdownToggle, Table } from "reactstrap";
import {
	getIncomingReferralsByClinicId,
	getOutgoingReferralsByClinicId,
	approveReferral,
	declineReferral,
} from "../../../context/actions/referral";
import CheckBox from "../../../shared/CheckBox";
import LoaderUI from "../../../shared/loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ImagePlaceholder from "../../../shared/ImagePlaceholder";
import AppLogoSmall from "../../../shared/AppLogoSmall";

import NodataFoundBlock from "../../../shared/NoDataFoundBlock";
import PaginationBlock from "../../../shared/PaginationBlock";
import TableListIncomingReferralFilterWrapper from "../../../shared/TableListIncomingReferralFilterWrapper";
import TableListOutgoingReferralFilterWrapper from "../../../shared/TableListOutgoingReferralFilterWrapper";

const Referrals = ({
	refresh,
	type,
	referrals,
	loading,
	page,
	limit,
	setPage,
	setLimit,
	filterPayload,
	setFilterPayload,
	clinicList,
	incomingfilterPayload,
	setIncomingFilterPayload,
	incomingClinicList
}) => {
	const history = useHistory();
	const [dropdownOpen, setOpen] = useState(false);
	const toggle = () => setOpen(!dropdownOpen);
	//const [referrals, setReferrals] = useState({})
	const dispatch = useDispatch();
	const { incomingReferrals, outgoingReferrals } = useSelector(
		(state) => state.referralReducer
	);
	//const [loading, setLoading] = useState(true)
	//const [page, setPage] = useState(1)
	//const [limit, setLimit] = useState(10)

	/*useEffect(() => {
			
			handleGetReferrals();
		}, [page, limit])*/
	const { permissionsData } = useSelector(
		(state) => state.clinicPermissionReducer
	);
	const permissions = permissionsData;
	const handleApprove = (ref) => {
		dispatch(
			approveReferral(ref, {}, () => {
				refresh();
			})
		);
	};

	const handleDecline = (ref) => {
		dispatch(
			declineReferral(ref, {}, () => {
				refresh();
			})
		);
	};

	

	/*
    
		const handleGetReferrals = () => {
			
			if(type &&  type === 'outgoing'){
				dispatch(getOutgoingReferralsByClinicId({ page, limit }, () => {
					setReferrals(outgoingReferrals)
    
					setLoading(false);
			}))
			} else if (type && type === 'incoming') {
				dispatch(getIncomingReferralsByClinicId({page, limit}, () =>{
					setReferrals(incomingReferrals);
					setLoading(false);
				}))
			}
			
		}*/

	const tempData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

	return (
		<>
			<div className="product-list-wrapper">
				{type === "incoming" && <TableListIncomingReferralFilterWrapper
					setIncomingFilterPayload={setIncomingFilterPayload}
					incomingfilterPayload={incomingfilterPayload}
					incomingClinicList={incomingClinicList} />}
				{type === "outgoing" && (
					<TableListOutgoingReferralFilterWrapper
						setFilterPayload={setFilterPayload}
						filterPayload={filterPayload}
						clinicList={clinicList}
					/>
				)}
				<div className="table-listing-blc overflow-auto">
					{referrals && referrals?.docs?.length > 0 ? (
						<>
							<Table striped responsive className="checkbox-listing">
								<thead>
									<tr>
										<th>
											<CheckBox
												type="checkbox"
												ischecked={false}
												className="no-label"
											/>
										</th>
										<th style={{ minWidth: "150px" }}>Patient Info.</th>
										{type === "incoming" ? (
											<th style={{ minWidth: "160px" }}>Referred By</th>
										) : (
											<th style={{ minWidth: "160px" }}>Referred To</th>
										)}
										<th style={{ minWidth: "90px" }}>Clinic Address</th>
										<th style={{ minWidth: "90px" }}>Refer Date</th>
										{type === "outgoing" && (
											<>
												<th style={{ minWidth: "100px" }}>Status</th>
												<th style={{ minWidth: "100px" }}>Action Date</th>
											</>
										)}

										<th style={{ minWidth: "160px" }}>Reason</th>
										<th>&nbsp;</th>
									</tr>
								</thead>
								<tbody>
									{loading && <LoaderUI />}
									{!loading &&
										referrals?.docs?.map((item, i) => {
											const referDate = new Date(item.refer_date);
											const actionDate = item.action_date
												? new Date(item.action_date)
												: null;
											return (
												<tr key={i}>
													<td>
														<CheckBox
															type="checkbox"
															ischecked={false}
															className="no-label"
														/>
													</td>
													<td>
														<div className="text-start pic-cell d-inline-flex align-items-center">
															<div className="pic d-inline-flex position-relative">
																{item?.patient_details?.pet_image ? (
																	<img
																		className="img-fluid w-100 h-100"
																		src={item?.patient_details?.pet_image}
																		alt="Patient"
																	/>
																) : (
																	<ImagePlaceholder />
																)}
															</div>
															<Button
																color="link"
																className="p-0 text-start pic-cell pic-cell-type-2 d-inline-flex align-items-center w-100"
																onClick={() => {
																	history.push(
																		`/patient-detail/${item.patient_details._id}`
																	);
																}}
															>
																<span>{item.patient_details.pet_name}</span>
															</Button>
														</div>
													</td>
													{type === "outgoing" && (
														<>
															<td>
																<div className="text-start pic-cell d-inline-flex align-items-center justi">
																	<AppLogoSmall />
																	{item.referred_to_details?.clinic_name}
																</div>
															</td>
															<td>
																<div className="align-items-center">
																	{item.referred_to_details.address || "--"}
																</div>
															</td>{" "}
														</>
													)}
													{type === "incoming" && (
														<>
															<td className="align-items-center">
																<div className="text-start pic-cell d-inline-flex align-items-center">
																	<AppLogoSmall />
																	{item.referred_by_details.clinic_name || "--"}
																</div>
															</td>
															<td>
																<div className="align-items-center">
																	{item.referred_by_details.address || "--"}
																</div>
															</td>{" "}
														</>
													)}

													<td style={{ whiteSpace: "nowrap" }}>
														{referDate.toLocaleString("en-US", {
															year: "numeric",
															month: "2-digit",
															day: "2-digit",
														})}
													</td>
													{type === "outgoing" && (
														<>
															<td>
																<div className="align-items-center">
																	<span
																		className={
																			"status-cell " +
																			(item.status === "Pending"
																				? "pending-cell"
																				: item.status === "Approved"
																					? "success-cell"
																					: "inactive-cell")
																		}
																	>
																		{item.status}
																	</span>
																</div>
															</td>
															<td style={{ whiteSpace: "nowrap" }}>
																{actionDate
																	? actionDate.toLocaleString("en-US", {
																		year: "numeric",
																		month: "2-digit",
																		day: "2-digit",
																	})
																	: "--"}
															</td>
														</>
													)}

													<td>
														<div className="align-items-center">
															{item.reason || "--"}
														</div>
													</td>

													<td className="action-cell">
														{type === "outgoing" ? (
															<>
																{/*
												{item.status === "Pending" ?
												
												<Button color="link" className="edit-btn">
													<FontAwesomeIcon icon={faTrash} />
												</Button>
												
												:
												<Button color="link" className="edit-btn">
													<span className="icomoon-share"></span>
												</Button>
												
												}
												<ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
													<DropdownToggle color="link" className="more-btn">
														<span className="icomoon-more"></span>
													</DropdownToggle>
												</ButtonDropdown>
												*/}
															</>
														) : item?.status === "Pending" ? (
															permissions?.edit_referral_management ? (
																<>
																	<Button
																		onClick={() => {
																			handleDecline(item?._id);
																		}}
																		color="link"
																		className="edit-btn"
																	>
																		<i className="icomoon-cross-btn"></i>
																	</Button>
																	<Button
																		onClick={() => {
																			handleApprove(item?._id);
																		}}
																		color="link"
																		className="edit-btn"
																	>
																		<i className="icomoon-check-btn"></i>
																	</Button>
																</>
															) : (
																""
															)
														) : (
															<>
																<span
																	className={`status-cell ${item?.status === "Declined"
																		? "inactive-cell"
																		: "success-cell"
																		}`}
																>
																	{item?.status}
																</span>
															</>
														)}
													</td>
												</tr>
											);
										})}
								</tbody>
							</Table>
							<PaginationBlock
								limit={limit}
								page={page}
								pagingCounter={referrals?.pagingCounter || 1}
								docLength={referrals?.docs?.length || 0}
								docPage={referrals?.page || 1}
								totalPages={referrals?.totalPages || 1}
								totalDocs={referrals?.totalDocs || 0}
								_handleLimit={(val) => {
									setLimit(val);
								}}
								_handlePageChange={setPage}
							/>
						</>
					) : (
						<NodataFoundBlock type={ (type === "incoming" && (incomingfilterPayload?.searchText !== "" || 
						incomingfilterPayload?.status !== "" ||
						incomingfilterPayload?.clinicId !== "" ||
						incomingfilterPayload?.start_date !== "" || 
						incomingfilterPayload?.end_date !== "") )|| (type === "outgoing" && (filterPayload?.searchText !== "" || 
						filterPayload?.status !== "" ||
						filterPayload?.clinicId !== "" ||
						filterPayload?.start_date !== "" || 
						filterPayload?.end_date !== "") ) ? 'search result' : ''} />
					)}
				</div>
			</div>
		</>
	);
};

export default Referrals;
