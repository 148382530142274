import { useEffect, useState } from "react";
import { Modal, Button, Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import ClinicCard from "./ClinicCard";
import TableListSuperAdminFilterBlock from "../../../shared/TableListSuperAdminFilterBlock";
import PageHeader from "../../../shared/PageHeader";
import { sidebarPanelAction } from "../../../context/actions/sidebarPanelAction";
import AddNewClinicPanel from "./AddNewClinicPanel";
import {
  getAllHospitals,
  deleteHospitalById,
} from "../../../context/actions/clinic";
import "../../../assets/css/superAdmin/color.scss";
import "../../../assets/css/superAdmin/common.scss";
import "./index.scss";
import NodataFoundBlock from "../../../shared/NoDataFoundBlock";

const AllClinics = () => {
  const dispatch = useDispatch();
  const [panelState, setPanelState] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [deleteID, setDeleteID] = useState("");
  const [editData, setEditData] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [responseData, setResponseData] = useState({});

  // const [allHospitals, setAllHospitals] = useState([]);
  const [timer, setTimer] = useState("");
  const sidebarPanelState = useSelector(
    (state) => state.sidebarPanelReducer.isSidePanel
  );

  const handleSearch = (e) => {
    let value = e.target.value;
    setSearchText(value);
    let time = timer;
    clearTimeout(time);
    time = setTimeout(() => {
      getAllClinics({ searchText: value });
    }, 800);
    setTimer(time);
  };
  const showSidePanel = () => {
    dispatch(sidebarPanelAction(!sidebarPanelState));
  };
  const handleAddNew = () => {
    setPanelState(true);
  };
  const getAllClinics = async (filterData) => {
    const res = await dispatch(getAllHospitals(filterData));
    setResponseData(res?.response_data || {});

    // setAllHospitals(result?.response_data?.docs);
  };
  const handleDeleteClicnic = async () => {
    setDeleteLoading(true);
    const response = await dispatch(
      deleteHospitalById({ clinicIds: deleteID })
    );
    if (response?.status) {
      getAllClinics();
    }
    setDeleteLoading(false);
    setDeleteID("");
  };

  useEffect(() => {
    localStorage.removeItem("super_clinic_id");
    getAllClinics({ searchText: searchText });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { docs = [] } = responseData;

  return (
    <>
      <Modal isOpen={deleteID ? true : false}>
        <div className="relative">
          <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
            <svg
              className="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
            <p className="mb-4 text-gray-500 dark:text-gray-300">
              Are you sure you want to delete this item?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <button
                onClick={() => {
                  setDeleteID("");
                }}
                data-modal-toggle="deleteModal"
                type="button"
                className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                No, cancel
              </button>
              <button
                onClick={handleDeleteClicnic}
                type="submit"
                className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900"
              >
                Yes, I'm sure{" "}
                {deleteLoading && (
                  <Spinner animation="border" role="status" size="sm" />
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <div className="page-wrapper position-relative d-flex flex-column flex-fill overflow-auto">
        <div className="page-header-wrapper d-flex align-items-center flex-shrink-0 right-column-visible">
          <Button
            className="sidebar-toggle-btn d-inline-flex align-items-center"
            color="link"
            onClick={showSidePanel}
          >
            <span className="material-symbols-outlined">menu</span>
          </Button>
          <div className="row-block d-flex justify-between align-items-center flex-fill">
            <div className="inline-flex pl-1 d-flex flex-column items-start">
              <PageHeader
                className="dashboard-head flex-flex-shrink-0 mb-0"
                title="Clinics"
              ></PageHeader>
              
            </div>
            <div className="ms-auto ml-0">
              <div className="btn-blc">
                <Button
                  color="success"
                  className="add-btn py-2 h-auto"
                  onClick={handleAddNew}
                >
                  Add new
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="page-header-wrapper">
          <TableListSuperAdminFilterBlock
            handleSearch={handleSearch}
            searchText={searchText}
          />

          <div className="my-3 row g-2 clinic-list-scroll">
            {docs.length === 0 ? (
              <NodataFoundBlock />
            ) : (
              docs?.map((ele, i) => {
                return (
                  <div
                    key={i}
                    className="col-lg-3 col-md-4 col-sm-6 col-12 pointer"
                  >
                    <ClinicCard
                      data={ele}
                      setDeleteID={setDeleteID}
                      setEditData={setEditData}
                      handleSuccess={() => {
                        getAllClinics({ searchText: searchText });
                      }}
                    />
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>

      <div>
        <div
          className={`new-clinic-panel-overlay position-fixed ${
            panelState || editData ? "active" : ""
          }`}
        >
          <AddNewClinicPanel
            is_master_clinic={true}
            isShow={panelState || editData}
            editData={editData}
            handelSuccess={() => {
              setPanelState(false);
              setEditData(null);
              getAllClinics();
            }}
            closePanel={() => {
              setPanelState(false);
              setEditData(null);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AllClinics;
