import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { dobInYearMonthDays } from "../../../utils/common";
import "./index.scss";
import { petGenderValues } from "utils/constants";

const ProfileDescription = () => {
	const { petDetails } = useSelector(state => state.petReducer);

	
	return (
		<>
			<div className="profile-description row-block">
				<p className="d-flex">
					<label>Date of Birth</label>
					<span>{moment(petDetails?.dob).format('MM/DD/YYYY') || '--'}</span>
				</p>
				<p className="d-flex">
					<label>Age</label>
					<span>{dobInYearMonthDays(new Date(petDetails?.dob || new Date()))} old</span>
				</p>
				<p className="d-flex">
					<label>Identity</label>
					<span>{petGenderValues?.filter(i => i?.value === petDetails?.gender)?.[0]?.label || '--'} / {petDetails?.breed?.breed || '--'} / {petDetails?.color || '--'}</span>
				</p>
				<p className="d-flex">
					<label>Rabies Tag No.</label>
					<span>{petDetails?.rabies_number || '--'}</span>
				</p>
				<p className="d-flex">
					<label>License No.</label>
					<span>{petDetails?.license_number || '--'}</span>
				</p>
				<p className="d-flex">
					<label>Microchip No.</label>
					<span>{petDetails?.microchip_number || '--'}</span>
				</p>
				<p className="d-flex">
					<label>CPR</label>
					<span>{petDetails?.cpr || '--'}</span>
				</p>
				<p className="d-flex">
					<label>IV Locations</label>
					<span>{petDetails?.iv_locations ? typeof petDetails?.iv_locations === 'string' ? petDetails?.iv_locations : petDetails?.iv_locations?.map(e => e?.locationDetails?.filter(o => o?.location)?.map(i => i?.location)?.filter(i => i && i !== '')?.join(", "))?.filter(i => i && i !== '')?.join(", ")  || "--": '--'}</span>
				</p>
			
			</div>
		
		</>
	);
};

export default ProfileDescription;
