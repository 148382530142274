import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button, ButtonDropdown, DropdownToggle, Table } from "reactstrap";
import {
  getPurchaseOrdersByClinicId,
} from "../../../context/actions/inventory";
import CheckBox from "../../../shared/CheckBox";
import LoaderUI from "../../../shared/loader";
import NodataFoundBlock from "../../../shared/NoDataFoundBlock";
import PaginationBlock from "../../../shared/PaginationBlock";
import TableListPurchaseOrderFilterWrapper from "../../../shared/TableListPurchaseOrderFilterWrapper";
import { roleItems } from "../../../utils/constants";

const PurchaseOrder = () => {
  const history = useHistory();
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const dispatch = useDispatch();
  const { inventory } = useSelector((state) => state.inventoryReducer);
  const { purchaseOrders } = useSelector((state) => state.inventoryReducer);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    handleGetPurchaseOrders();
  }, [page, limit]);


  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  const handleGetPurchaseOrders = () => {
    dispatch(
      getPurchaseOrdersByClinicId({ page, limit }, () => {
        setLoading(false);
      })
    );
  };

  if (loading) {
    return <LoaderUI />;
  }

  const tempData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  return (
    <>
      <div className="product-list-wrapper">
        <TableListPurchaseOrderFilterWrapper />
        {purchaseOrders?.docs.length > 0 ? (
          <div className="table-listing-blc overflow-auto">
            <Table striped responsive className="checkbox-listing">
              <thead>
                <tr>
                  <th style={{ minWidth: "100px" }}>PO Number</th>
                  <th style={{ minWidth: "136px" }}>Order Type</th>
                  <th style={{ minWidth: "136px" }}>Supplier</th>
                  <th style={{ minWidth: "100px" }}>Status</th>
                  <th style={{ minWidth: "106px" }}>Items</th>
                  <th style={{ minWidth: "160px" }}>Order Placed on</th>
                  <th style={{ minWidth: "160px" }}>Completed on</th>
                  <th>Description</th>
                  {permissions?.edit_POS &&
                    <th>&nbsp;</th>
                  }
                </tr>
              </thead>
              <tbody>
                {purchaseOrders?.docs?.map((item, i) => {
                  const createdAt = new Date(item.created_date);
                  const completedAt = new Date(item.complete_date);

                  return (
                    <tr key={i}>
                      <td>
                        <Button
                          color="link"
                          className="p-0 text-start pic-cell pic-cell-type-2 d-inline-flex align-items-center w-100"
                          onClick={() => {
                            history.push(`/product-detail/${item._id}`);
                          }}
                        >
                          <span>{item.po_number}</span>
                        </Button>
                      </td>
                      <td>{item.order_type}</td>
                      <td>{item.supplierDetails?.supplier_name}</td>
                      <td>
                        <span
                          className={
                            "status-cell " +
                            (item.status === "Pending"
                              ? "pending-cell"
                              : "success-cell")
                          }
                        >
                          {item.status ? item.status : "NA"}
                        </span>
                      </td>
                      <td>{item.items?.length} Item(s)</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {createdAt.toLocaleString("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                          hour12: false,
                        })}
                      </td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {completedAt.toLocaleString("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                          hour12: false,
                        })}
                      </td>
                      <td
                        className="text-clip-cell"
                        style={{
                          minWidth: "190px",
                          maxWidth: "190px",
                          width: "100%",
                        }}
                      >
                        {item.description}
                      </td>
                      {permissions?.edit_POS &&
                        <td className="action-cell">
                          <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle color="link" className="more-btn">
                              <span className="icomoon-more"></span>
                            </DropdownToggle>
                          </ButtonDropdown>
                        </td>
                      }
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <PaginationBlock
              limit={limit}
              page={page}
              pagingCounter={purchaseOrders?.pagingCounter || 1}
              docLength={purchaseOrders?.docs?.length || 0}
              docPage={purchaseOrders.page || 1}
              totalPages={purchaseOrders.totalPages || 1}
              totalDocs={purchaseOrders.totalDocs || 0}
              _handleLimit={(val) => {
                setLimit(val);
              }}
              _handlePageChange={setPage}
            />
          </div>
        ) : (
          <NodataFoundBlock />
        )}
      </div>
    </>
  );
};

export default PurchaseOrder;
