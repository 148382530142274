import React from "react";
import { Button } from "reactstrap";
import './index.scss';

const AddSlotButton = () => {
	return (
		<>
			<Button color="link" className="d-flex align-items-center justify-content-center flex-fill align-self-stretch p-0 add-slot-btn">
				<span className="material-symbols-outlined">
					add
				</span>
			</Button>
		</>
	);
};

export default AddSlotButton;
