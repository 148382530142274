import React, { useState } from "react";
import { Button } from "reactstrap";
import "./index.scss";
import AvatarGroup from "./AvatarGroup";
import { useSelector } from "react-redux";

const ProfileActiveUser = ({ clinicData }) => {
  const [isMore, setIsMore] = useState(false);
  const [avatars,setAvatars] = useState([]);
  const {clinic_details} = useSelector((state) => state.clinicReducer)

  return (
    <>
      <div className="profile-users row-block">
        <div className="head section-head d-flex align-items-center">
          <div className="left-col">Active Users</div>
          <div className="right-col ms-auto">
            <Button color="link" className="d-inline-flex add-btn">
              <span className="icomoon-plus-btn"></span>
            </Button>
          </div>
        </div>
        <div className="inner">
          <AvatarGroup avatars={clinic_details?.activeUsers?.map(ele => ele?.user_image ? ele?.user_image : false)} />
        </div>
      </div>
    </>
  );
};

export default ProfileActiveUser;
