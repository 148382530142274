import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

/**
 * Modal containing an iframe which displays the link passed in via the fileViewerData field of the user reducer
 * @returns {JSX.Element} Component template
 */

const FullScreenFileViewer = () => {
  const dispatch = useDispatch();
  const { fileViewerData } = useSelector((state) => state.user);
  return (
    <>
  
   


      {fileViewerData && (
        <Modal
          onHide={() => {
            dispatch({
              type: "SET_FILE_VIEWER_DATA",
              data: null,
            });
          }}
          fullscreen
          centered
          show
        >
          <Modal.Header closeButton>
            <Modal.Title>{fileViewerData?.title || ""}</Modal.Title>
          </Modal.Header>
          {fileViewerData?.fileURL?.includes("http") ? (
          
              <iframe src={fileViewerData?.fileURL} title="File Viewer" />
          ) : (
            <div className="text-22-500 color-2E65 f-center h-100">
              Invalid URL found.
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default FullScreenFileViewer;
