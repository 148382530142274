import * as Yup from "yup";
import { REGEX } from "../../../utils/constants";
/**
 * @description declaration form schema for client validations
 */
export const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email address")
        .required("Email address field  is required")
});




/**
 * @description params intilaization 
 */

export const forgotPasswordInit = {
    email: ''
}