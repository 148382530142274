import { getAllSpecies } from "context/actions/app-content";
import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FormGroup } from "reactstrap";
import UploadBlock from "shared/UploadBlock";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  createPatientIntakeForm,
  updatePatientIntakeForm,
} from "context/actions/patientIntakeForm";
import { getFormAndTemplates } from "context/actions/formTemplates";
import "./PatientIntakeForm.scss";

const PatientIntakeForm = ({ onHide, editData }) => {
  const dispatch = useDispatch();
  const species = useSelector((state) => state.appContentReducer.species);

  useEffect(() => {
    dispatch(getAllSpecies({}, () => {}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [images, setImages] = useState([]);
  const [imagesarr, setImagesarr] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const handleSave = (val) => {
    if (editData) {
      let payload = { ...val, template_id: editData?._id };
      payload.is_active = editData.is_active;
      if (imageFiles.length > 0) {
        payload["patient-intake-form"] = imageFiles[0];
      }
      handleEdit(payload);
    } else {
      handleAdd({
        ...val,
        "patient-intake-form": imageFiles[0],
      });
    }
  };
  const handleAdd = (payload) => {
    var payloadFormData = new FormData();

    for (var key in payload) {
      payloadFormData.append(key, payload[key]);
    }
    dispatch(
      createPatientIntakeForm(payloadFormData, (res) => {
        if (res.status) {
          dispatch(getFormAndTemplates({}, () => {}));
          onHide();
        }
      })
    );
  };
  const handleEdit = (payload) => {
    var payloadFormData = new FormData();
    for (var key in payload) {
      payloadFormData.append(key, payload[key]);
    }
    dispatch(
      updatePatientIntakeForm(payloadFormData, (res) => {
        if (res.status) {
          dispatch(getFormAndTemplates({}, () => {}));
          onHide();
        }
      })
    );
  };
  const validationSchema = Yup.object().shape({});
  const initialValues = {
    form_type: editData?.form_type || "",
    form_title: editData?.form_title || "",
    form_notes: editData?.form_notes || "",
    pet_specie_id: editData?.pet_specie_id || "",
    is_active: true,
  };
  return (
    <Offcanvas
      show
      onHide={onHide}
      placement="end"
      id="patient-intake-form-offcanvas"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Patient Intake Form</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="w-100">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          {(props) => {
            const { values, handleChange, handleSubmit } = props;
            const { form_type, form_title, form_notes, pet_specie_id } = values;
            return (
              <Form>
                <FormGroup className="input-wrapper2 mb-2">
                  <UploadBlock
                    images={images}
                    setImages={setImages}
                    imagesarr={imagesarr}
                    setImagesarr={setImagesarr}
                    imageFiles={imageFiles}
                    setImageFiles={setImageFiles}
                    documentimages={editData?.form_url || null}
                    documentname={
                      editData?.form_url
                        ? editData?.form_url?.split("/").pop()
                        : null
                    }
                    accept="application/pdf"
                  />
                </FormGroup>
                <FormGroup className="input-wrapper2 mb-2">
                  <div className="form-floating position-relative text-input">
                    <select
                      className="form-control"
                      id="pet_specie_id"
                      placeholder="Species"
                      value={pet_specie_id}
                      onChange={handleChange}
                    >
                      <option value="">Select Species</option>
                      {(species || []).map((item, i) => (
                        <option
                          key={i}
                          value={item._id}
                          style={{ textTransform: "capitalize" }}
                        >
                          {item.species_name}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="pet_specie_id">Species</label>
                  </div>
                </FormGroup>
                <FormGroup className="input-wrapper2 mb-2">
                  <div className="form-floating position-relative text-input">
                    <input
                      type="text"
                      className="form-control"
                      id="form_type"
                      placeholder="Type"
                      value={form_type}
                      onChange={handleChange}
                    />
                    <label htmlFor="form_type">Type</label>
                  </div>
                </FormGroup>
                <FormGroup className="input-wrapper2 mb-2">
                  <div className="form-floating position-relative text-input">
                    <input
                      type="text"
                      className="form-control"
                      id="form_title"
                      placeholder="Title"
                      value={form_title}
                      onChange={handleChange}
                    />
                    <label htmlFor="form_title">Title</label>
                  </div>
                </FormGroup>
                <FormGroup className="input-wrapper2 mb-2">
                  <div className="form-floating position-relative text-input">
                    <input
                      type="text"
                      className="form-control"
                      id="form_notes"
                      placeholder="Notes"
                      value={form_notes}
                      onChange={handleChange}
                    />
                    <label htmlFor="form_notes">Notes</label>
                  </div>
                </FormGroup>

                <div className="btn-blc border_btn mt-3 text-end">
                  <Button
                    color="success"
                    onClick={handleSubmit}
                    className="add-btn"
                    disabled={
                      !form_type ||
                      !form_title ||
                      !form_notes ||
                      !pet_specie_id ||
                      (!editData && images.length === 0)
                    }
                  >
                    {editData ? "UPDATE" : "CREATE"}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default PatientIntakeForm;
