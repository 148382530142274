import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import PageHeader from "../../../shared/PageHeader";
import { getAllClinicLiveAppointments, addClinicTaskPanelAction, updateClinicTaskPanelAction } from "../../../context/actions/clinictask";
import "./index.scss";
import { dobInYearMonthDays, timeFromDateTime } from "../../../utils/common";
import NewClinicTask from "./NewClinicTask";
import UpdateClinicTask from "./UpdateClinicTask";
import moment from "moment"
import LoaderUI from "shared/loader";

function customTemplate() {
    return this.duration.asSeconds() >= 86400 ? "w [weeks], d [days]" : "h [hours], m [minutes]";
}



const LiveClinic = () => {
    const history = useHistory();
    const routeParams = useParams();
    const dispatch = useDispatch();
    const { clinic_settings } = useSelector(state => state.clinicSettingsReducer)
    const loading = useSelector(state => state.clinicTaskReducer.loading);
    const clinicLiveAppointments = useSelector(state => state.clinicTaskReducer.clinic_live_appointments)
    // eslint-disable-next-line no-unused-vars
    const [searchTerm, setSearchTerm] = useState("");
    const [now, setNow] = useState(moment())
    const [appointment, setAppointment] = useState({})
    const [selectedTask, setSelectedTask] = useState(false)

    const { permissionsData } = useSelector(
        (state) => state.clinicPermissionReducer
    );
    const permissions = permissionsData;
    const addTaskPanelState = useSelector(
        (state) => state.clinicTaskReducer.isAddTaskPanel
    );
    const updateTaskPanelState = useSelector(
        (state) => state.clinicTaskReducer.isUpdateTaskPanel
    );

    const closeAddTaskPanel = () => {
        dispatch(addClinicTaskPanelAction(false));
    };

    // const openAddTaskPanel = (value) => {
    //     setAppointment({ ...value })
    //     dispatch(addClinicTaskPanelAction(!addTaskPanelState));
    // };

    const closeUpdateTaskPanel = () => {
        dispatch(updateClinicTaskPanelAction(false));
    };

    const openUpdateTaskPanel = (value) => {
        setAppointment({ ...value })
        dispatch(updateClinicTaskPanelAction(!updateTaskPanelState));
    };

    useEffect(() => {
        setInterval(() => {
            setNow(moment())
        }, 1000)
        closeAddTaskPanel();
        closeUpdateTaskPanel();
        dispatch(getAllClinicLiveAppointments(clinic_settings?.clinic_id, {}, () => { }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routeParams])

    const openAppointment = () => {
        history.push("/appointments/");
    };

    // const blankTasks = (total) => {
    //     let data = "";
    //     for (let i = 0; i < (6 - total); i++) {
    //         data += "<td></td>";
    //     }
    //     return data;
    // }

    const handleSearchOption = (keyword) => {
        setSearchTerm(keyword);
        dispatch(getAllClinicLiveAppointments(clinic_settings?.clinic_id, {
            keyword: keyword
        }, () => { }))
    }

    return (
        <>
            {loading && <LoaderUI /> }
            <div className="page-wrapper position-relative d-flex flex-column flex-fill overflow-auto">
                <div className="page-header-wrapper d-flex align-items-center flex-shrink-0 pb-0 right-column-visible">
                    <Button
                        className="sidebar-toggle-btn d-inline-flex align-items-center"
                        color="link"

                    >
                        <span className="material-symbols-outlined">menu</span>
                    </Button>
                    <div className="row-block d-flex align-items-center flex-fill">
                        <div className="d-flex align-items-center">
                            <button type="button" class="d-flex align-items-center text-start btn btn-link p-0 pe-3" onClick={openAppointment}>
                                <img src={require("../../../assets/img/appoint-back.png")}   alt=""/>
                            </button>
                            <div>
                                <PageHeader
                                    className="dashboard-head flex-flex-shrink-0"
                                    title="Live Clinic View"
                                ></PageHeader>
                                <p className="block text-[#788693] font-['Inter'] font-[400] font-[14]">

                                </p>
                            </div>
                        </div>

                        <div className="right-col ms-auto d-flex">
                            <div className="btn-blc gray-bgg me-3 p-2 d-flex align-items-center">
                                <div className="me-2">
                                    <img src={require("../../../assets/img/clock-appoint.png")}   alt=""/>

                                </div>
                                <div >
                                    <h4>{moment(now).format('h:mm A')}</h4>
                                    {/*<p>New york</p>*/}
                                </div>
                            </div>
                            <div className="btn-blc gray-bgg p-2 d-flex align-items-center">
                                {/* <div className="me-2">
                                    <img src={require("../../../assets/img/cloud.png")}   alt=""/>


                                </div> */}
                                <div>
                                    <h4>{moment(now).format('MMM Do, YYYY')}{' | '}{moment(now).format('dddd')}</h4>
                                    {/*<p>21º C Partly Cloudy</p>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-content no-header overflow-auto pb-0">
                    <div className="calendar-filter-blc justify-between flex flex-col md:flex-row overflow-auto flex-shrink-0">
                        <div className="left-col inline-flex items-center">
                            <div className="search-blc position-relative input-cell ">
                                <input placeholder="Search" type="text" className="form-control" onChange={(e) => handleSearchOption(e.target.value)} />
                                <span className="position-absolute d-inline-flex align-items-center justify-content-center">
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" className="h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                                    </svg>
                                </span>
                            </div>
                            <div className="dropdown-blc input-cell">
                                <select name="select" className="form-control">
                                    <option>All Departments</option>
                                </select>
                            </div>
                            <div className="dropdown-blc input-cell">
                                <select name="select" className="form-control">
                                    <option>All Locations</option>
                                </select>
                            </div>
                            <div className="dropdown-blc input-cell">
                                <select name="select" className="form-control">
                                    <option>All Patients</option>
                                </select>
                            </div>
                        </div>
                        <div className="right-col flex items-center flex-row gap-2 px-2">
                            <div className="inline-flex gap-x-[1px] items-center text-sm overflow-hidden bg-gray-100 rounded-md ">
                                <button className="px-2.5 py-1.5 inline-flex items-center justify-center hover:bg-gray-200 false">Timeline View</button>
                                <button className="px-3 py-1.5 inline-flex items-center justify-center hover:bg-gray-200 text-[#3d3efd] bg-gray-200">Easy View</button>
                            </div>
                        </div>
                    </div>
                </div>


                {clinicLiveAppointments.length > 0 && clinicLiveAppointments.map((a) => (
                    <div className="list-view-table gray-bgg rounded-bordered mx-4 table-responsive table-responsive mb-1" key={a?._id}>
                    <table className="table">
                        {/*{position: 'sticky', left: 0, zIndex: 1, opacity: 1, backgroundColor: '#f7f7f7', paddingLeft:'10px'}*/}
                        <tr>
                            <td >
                                <div  style={{width: '250px', display:'flex', flexDirection: 'row', gap: '10px'}} className="inner-table-view">
                                {/* <table  style={{tableLayout :'fixed', maxWidth: '250px'}} className="inner-table-view"> */}
                                    {/* <tr> */}

                                    <div style={{display: 'flex', flexDirection: 'column'}}>

                                        <div className="profile-dog">
                                        <img 
                                        src={
                                            a?.patient?.pet_image ||
                                            require("../../../assets/img/vetRecorEMR/pet-thumbnail.png")
                                        }
                                        />
                                        </div>
                                        <span className="mins-box">{moment(a?.appointment_start).fromNow(true) /*moment.duration(now.diff(moment(a?.appointment_start))).format(customTemplate, {trim: 'both'})*/}</span>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <h4  onClick={() =>{
                                history.replace(
                                    `/patient-detail/${a?.patient?._id}`
                                )}}>{a?.patient?.pet_name} ({a?.patient?.vrn})</h4>
                                            <p>{dobInYearMonthDays(new Date(a?.patient?.dob || new Date()))} old</p>
                                            <p style={{textTransform: 'capitalize'}}>Current Location : {a?.appointment_status || '--'}</p>

                                    </div>
                                        {/* <td>
                                            <div className="profile-dog">
                                                <img 
                                                src={
                                                    a?.patient?.pet_image ||
                                                    require("../../../assets/img/vetRecorEMR/pet-thumbnail.png")
                                                }
                                                />
                                            </div>
                                            <span className="mins-box">{moment.duration(now.diff(moment(a?.appointment_start))).format(customTemplate, {trim: 'both'})}</span>
                                        </td>
                                        <td className="border-0">
                                            <h4>{a?.patient?.pet_name} ({a?.patient?.vrn})</h4>
                                            <p>{dobInYearMonthDays(new Date(a?.patient?.dob || new Date()))} old</p>
                                            <p>Current Location : {a?.appointment_status || '--'}</p>
                                        </td>
                                    </tr> */}
                                   {/* <img src={require("../../../assets/img/warning.png")} className="warning-img"/> */}
                                {/* </table> */}
                                </div>
                            </td>

                                {a?.tasks.length > 0 && a?.tasks.map((task) => (


                                    <td style={{maxWidth: '150px !important'}} className={task?.task_status === 'cancelled' ? 'cancelled-state' : ''}>
                                        <div className="d-flex flex-row justify-content-between">
                                            <h4>{task?.item_details ? task?.item_details?.product_name + ' (' + task?.dose + ' ' + task?.form + ')' : task?.task_name}</h4>
                                            {task?.item_details ?

                                                <></>
                                                :
                                                permissions?.edit_live_clinic ?
                                                    <span onClick={() => {
                                                        setSelectedTask(task);
                                                        openUpdateTaskPanel(a);

                                                    }} className="icomoon-pencil"></span>
                                                    :
                                                    ''}

                                        </div>
                                        <div className="d-flex justify-content-between align-items-end end-div">
                                            <p>{timeFromDateTime(task?.task_time, 'hh:mm A')}</p>

                                            {task?.task_status == 'done' ? (
                                                <p className="state-done">Done</p>
                                            ) : task?.task_status == 'cancelled' ? (
                                                <p className="state-cancel">Cancelled</p>
                                            ) : (
                                                <p></p>
                                            )}

                                        </div>
                                    </td>
                                ))}

                                {Array.from({ length: 6 - a?.tasks.length }, (_, index) => index).map((et) => (
                                    <td className="blankdata"></td>
                                ))}

                            </tr>
                        </table>
                        {/*}
                    <div className="btn-wrappers h-100"> 
                        <button type="button" style={{float: 'right'}}  className="text-center btn add-btn rounded-0" onClick={() => openAddTaskPanel(a)}>
                            <img src={require("../../../assets/img/Toys.png")} /><span>Add </span>
                        </button>
                        <button type="button" className=" text-center btn edit-btn rounded-0" onClick={() => openUpdateTaskPanel(a)}>
                            <img src={require("../../../assets/img/editToys.png")} /> <span>Edit</span></button> 
                    </div>*/}
                    </div>)
                )}
            </div>

            <div
                className={`patient-panel-overlay position-fixed ${addTaskPanelState ? "active" : ""
                    }`}
                onClick={() => dispatch(addClinicTaskPanelAction(false))}
            ></div>
            <NewClinicTask
                className={`${addTaskPanelState ? "active" : ""}`}
                appointment={appointment}
            />

            <div
                className={`patient-panel-overlay position-fixed ${updateTaskPanelState ? "active" : ""
                    }`}
                onClick={() => dispatch(updateClinicTaskPanelAction(false))}
            ></div>
            <UpdateClinicTask
                className={`${updateTaskPanelState ? "active" : ""}`}
                appointment={appointment}
                task={selectedTask}
            />

        </>

    );

};


export default LiveClinic;