const initialState = {
  registered: false,
  loggedIn: false,
  // otp_in: false,
  token: false,
  operator: [],
  count: 0,
  locations: [],
  logo: null,
  user_details: false,
  check_user_email: false,
  fileViewerData: null,
  labOrderViewerData: null,
  labResultsViewerData: null,

};

export const user = (state = initialState, action) => {
  switch (action.type) {
    case "REGISTER":
      return { ...state, registered: true, loggedIn: true, ...action.data };
    case "LOGIN":
      return {
        ...state,
        loggedIn: true,
        logo: action.data?.user?.organization_detail?.blob_name,
        ...action.data,
      };
    case "UPDATE_TOKEN":
      return { ...state, token: action.data };
    case "UPDATE_SUBSCRIPTIONS": {
      return {
        ...state,
        user: { ...state.user, current_subscription: action.data },
      };
    }
    case "OTP":
      return { ...state, ...{ otp_in: true }, ...action.data };
    case "OPERATOR_LIST":
      return {
        ...state,
        operator: action.data,
        count: action.data.totalDocs,
        locations: action.data?.locations,
      };

    case "UPDATE_USER": {
      let optData = state.user.operator.docs;
      let optList = optData.map((item) => {
        if (item._id === action.data.id) {
          item.email = action.data.email;
          item.name = action.data.name;
        }
        return item;
      });
      return { ...state, user: { ...state.user, operator: { optList } } };
    }
    case "UPDATE_PROFILE":
      return { ...state, ...action.data };

    case "UPDATE_USER_DETAILS":
      return { ...state, ...action.data };

    case "UPDATE_LOGO":
      return { ...state, logo: action.data?.blob_name };

    case "LOGOUT":
      return initialState;

    case "USER_DETAILS":
      return {
        ...state,
        user_details: { ...state.user_details, ...action.data },
      };

    case "CHECK_USER_EMAIL":
      return { ...state, check_user_email: action.data };
    case "SET_FILE_VIEWER_DATA":
      return { ...state, fileViewerData: action.data };
    case "SET_LAB_ORDER_VIEWER_DATA":
      return { ...state, labOrderViewerData: action.data };
    case "SET_LAB_RESULTS_VIEWER_DATA":
      return { ...state, labResultsViewerData: action.data };

    default:
      return state;
  }
};
