import * as Yup from "yup";
import "yup-phone-lite";
/**
 * @description declaration form schema for add pet owner validations
 */



export const addPetOwnerSchema = Yup.object().shape({
    firstname: Yup.string()
        .required("firstname is required"),
    lastname: Yup.string()
        .required("lastname is required"),
    phone: Yup.string()//.phone('US')
        .required("phone number is required"),
    email: Yup.string()
        .required("email is required").email("email is not in a valid format"),
    address: Yup.string().optional(),
});


/**
 * @description params intilaization 
 */

export const addPetOwnerInitialValue = {
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    address: '',
}
