import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";
toast.configure();

export const sendInvoiceAsMail = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.postFormData(
      `${apiUrl}${PORT}/invoice/send-mail-to-pet-owner`,
      body,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response?.status) {
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        return callback(response);
      }
    });
  };
};



export const getAllInvoiceData = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      body.clinic_id = clinic_details?._id;

      ApiClient.post(
        `${apiUrl}${PORT}/invoice/get-filterd-invoice`,
        body,
        token,
        dispatch, getState
      ).then((response) => {

        if (!response) {
          return callback();
        } else if (!response && response.errors) {
          toast.error(response.errors.msg, toasterConfig);
          return callback(response);
        } else if (response?.status) {
          toast.dismiss();
          toast.success(response.msg, toasterConfig);
          return callback(response);
        }
      });
    }
  };
};




/**
 * Function to call update invoice status endpoint from API
 * @param {Object} body
 * @param {Function} callback
 * @returns
 */
export const updateInvoiceStatus = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.put(`${apiUrl}${PORT}/invoice/update-invoice-status`, body, token, dispatch, getState).then(
      (response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          toast.success(response?.message, toasterConfig);
          return callback(response);
        } else {
          toast.error(response.message, toasterConfig);
          return callback(response);
        }
      }
    );
  };
};

export const updateInvoice = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.put(`${apiUrl}${PORT}/invoice/update`, body, token, dispatch, getState).then(
      (response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          toast.success(response?.message, toasterConfig);
          return callback(response);
        } else {
          toast.error(response.message, toasterConfig);
          return callback(response);
        }
      }
    );
  };
};


export const addEstimate = (data, callback) => {



  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      data.clinic_id = clinic_details?._id;
      ApiClient
      .post(`${apiUrl}${PORT}/estimate/add`, data, token, dispatch, getState).then((response) => {
        if (!response) {
          return callback();
        } else if (!response && response.errors) {
          toast.error(response.errors.msg, toasterConfig);
          return callback(response);
        } else if (response?.status) {
          toast.dismiss();
          toast.success(response.msg, toasterConfig);
          return callback(response);
        }
      });
    }
  };
}



export const getAllEstimatesData = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      body.clinic_id = clinic_details?._id;

      ApiClient.post(
        `${apiUrl}${PORT}/estimate/get-filtred`,
        body,
        token,
        dispatch, getState
      ).then((response) => {

        if (!response) {
          return callback();
        } else if (!response && response.errors) {
          toast.error(response.errors.msg, toasterConfig);
          return callback(response);
        } else if (response?.status) {
          toast.dismiss();
          toast.success(response.msg, toasterConfig);
          return callback(response);
        }
      });
    }
  };
};


export const petOwnerPayInvoice = (id, callback) => {

  return (dispatch, getState) => {
    const {
      user: { token }
    } = getState();
    
      

      ApiClient.get(
        `${apiUrl}${PORT}/invoice/pay-pet-owner/${id}`,
        {},
        token,
        dispatch, getState
      ).then((response) => {

        if (!response) {
          return callback();
        } else if (!response && response.errors) {
          toast.error(response.errors.msg, toasterConfig);
          return callback(response);
        } else if (response?.status) {
          toast.dismiss();
          toast.success(response.msg, toasterConfig);
          return callback(response);
        }
      });
    
  };

}



export const payInvoice = (id, callback) => {

  return (dispatch, getState) => {
    const {
      user: { token }
    } = getState();
      ApiClient.get(
        `${apiUrl}${PORT}/invoice/pay/${id}`,
        {},
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (!response && response.errors) {
          toast.error(response.errors.msg, toasterConfig);
          return callback(response);
        } else if (response?.status) {
          toast.dismiss();
          toast.success(response.msg, toasterConfig);
          return callback(response);
        }
      });
  };
}

export const getInvoicesByPetId = (id, callback) => {


  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
      ApiClient.get(
        `${apiUrl}${PORT}/invoice/get-all-for-pet/${id}`,
        {},
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (!response && response.errors) {
          toast.error(response.errors.msg, toasterConfig);
          return callback(response.data);
        } else if (response?.status) {
          toast.dismiss();
          toast.success(response.msg, toasterConfig);
          return callback(response.data);
        }
      });
  };

}

export const addInvoice = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
      ApiClient.post(
        `${apiUrl}${PORT}/invoice/add`,
        data,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (!response && response.errors) {
          toast.error(response.errors.msg, toasterConfig);
          return callback(response);
        } else if (response?.status) {
          toast.dismiss();
          toast.success(response.msg, toasterConfig);
          return callback(response);
        }
      });
  };

}

export const getAllActiveOrder = (_id, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.get(`${apiUrl}${PORT}/order/get-all-active/${_id}`, {}, token, dispatch, getState).then((response) => {
        if (!response) {
          return callback();
        } else if (!response && response.errors) {
          toast.error(response.errors.msg, toasterConfig);
          return callback(response);
        } else if (response?.status) {
          toast.dismiss();
          toast.success(response.msg, toasterConfig);
          return callback(response);
        }
      });
  };

}

export const getSingleInvoiceById = (invoiceId, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.get(`${apiUrl}${PORT}/invoice/get-single-invoice/${invoiceId}`, {}, token, dispatch, getState).then((response) => {
        if (!response) {
          return callback();
        } else if (!response && response.errors) {
          toast.error(response.errors.msg, toasterConfig);
          return callback(response);
        } else if (response?.status) {
          toast.dismiss();
          toast.success(response.msg, toasterConfig);
          return callback(response);
        }
      });
  };

}


export const addAutoInvoice = (invoiceData, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
      ApiClient.post(
        `${apiUrl}${PORT}/invoice/add-auto`,
        invoiceData,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (!response && response.errors) {
          toast.error(response.errors.msg, toasterConfig);
          return callback(response);
        } else if (response?.status) {
          toast.dismiss();
          toast.success(response.msg, toasterConfig);
          return callback(response);
        }
      });
  };
}
