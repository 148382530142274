import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInventoriesByClinicId } from "../../../context/actions/inventory";
import { getAllServiceCategories } from "../../../context/actions/app-content";

import Select from "react-select";

export default function OrderInfo({ setFormData }) {
  const [data, setData] = useState({
    service_name: "",
    service_category: "",
    service_price: "",
    service_tax: "",
    doctor_commission: "",
    category_id: '',
  });

  const { serviceCategories } = useSelector(state => state.appContentReducer)
  const {clinic_details} = useSelector(state => state.clinicReducer)

  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.user_details);
  const [productData, setProductData] = useState([]);

  const handleChange = (field, value) => {
    setData((prevData) => {
      const newData = { ...prevData, [field]: value };
      setFormData(newData);
      return newData;
    });
  };

  useEffect(() => {
    dispatch(getAllServiceCategories( (res) => {
      if (res?.status) {
      }
    }))
    dispatch(
      getInventoriesByClinicId(clinic_details?._id, {}, (res) => {
        const productData = res?.response_data?.docs?.map((item) => ({
          value: item._id,
          label: item.product_name,
        }));
        console.log("data", productData);
        setProductData(productData);
      })
    );
  }, []);

  return (
    <>
      <h5 className="text-lg font-semibold uppercase text-[#282E65]">
        Service Information
      </h5>
      <ul className="space-y-1">
        <li className="flex flex-col md:flex-row gap-1">
          <label
            htmlFor="service_name"
            className="text-sm md:text-base w-full md:w-44"
          >
            Service Name
          </label>
          <input
            id="service_name"
            type="text"
            placeholder="Add Name"
            value={data.service_name}
            onChange={(e) => handleChange("service_name", e.target.value)}
            className="w-full md:w-56 cursor-pointer bg-gray-100 active:bg-gray-100 focus:bg-gray-200 rounded-md focus:px-2.5 md:px-2.5 py-1.5"
          />
        </li>
        <li className="flex flex-col md:flex-row gap-1">
          <label
            htmlFor="service_category"
            className="text-sm md:text-base w-full md:w-44"
          >
            Category
          </label>
            <select
            style={{textTransform: 'capitalize'}}
              id="service_category"
              className="w-full md:w-56 cursor-pointer bg-gray-100 active:bg-gray-100 focus:bg-gray-200 rounded-md focus:px-2.5 md:px-2.5 py-1.5"
              value={data?.category_id}
              onChange={(e) => handleChange("category_id", e.target.value)}
            >
              <option value={''} selected className="font-['Inter']">
                Select a category
              </option>
              {(serviceCategories || []).map((item, i) =>
                <option key={i} value={item._id} style={{ textTransform: "capitalize" }}>{item?.category}</option>
              )}
            </select>
        </li>
        <li className="flex flex-col md:flex-row gap-1">
          <label
            htmlFor="service_price"
            className="text-sm md:text-base w-full md:w-44"
          >
            Price ($)
          </label>
          <input
            id="service_price"
            type="text"
            placeholder="Enter Price"
            value={data.service_price}
            onChange={(e) => handleChange("service_price", e.target.value)}
            className="w-full md:w-56 bg-gray-100 cursor-pointer focus:bg-gray-200 rounded-md focus:px-2.5 md:px-2.5 py-1.5"
          />
        </li>
        <li className="flex flex-col md:flex-row gap-1">
          <label
            htmlFor="service_tax"
            className="text-sm md:text-base w-full md:w-44"
          >
            Tax (%)
          </label>
          <input
            id="service_tax"
            type="text"
            placeholder="Enter Tax Percentage"
            value={data.service_tax}
            onChange={(e) => handleChange("service_tax", e.target.value)}
            className="w-full md:w-56 bg-gray-100 cursor-pointer focus:bg-gray-200 rounded-md focus:px-2.5 md:px-2.5 py-1.5"
          />
        </li>
        <li className="flex flex-col md:flex-row gap-1">
          <label
            htmlFor="doctor_commission"
            className="text-sm md:text-base w-full md:w-44"
          >
            Doctor's Commission (%)
          </label>
          <input
            id="doctor_commission"
            type="text"
            placeholder="Enter Doctor's Comission"
            value={data.doctor_commission}
            onChange={(e) => handleChange("doctor_commission", e.target.value)}
            className="w-full md:w-56 bg-gray-100 cursor-pointer focus:bg-gray-200 rounded-md focus:px-2.5 md:px-2.5 py-1.5"
          />
        </li>
        {/*
        <li className="flex flex-col md:flex-row gap-1">
          <label
            htmlFor="service_products"
            className="text-sm md:text-base w-full md:w-44"
          >
            Products
          </label>
          <div className="w-auto">
            <Select
              isMulti
              options={productData}
              classNamePrefix="react-select"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  maxHeight: 300,
                }),
              }}
              onChange={(selectedOptions) => {
                const selectedValues = selectedOptions.map((option) => ({
                  product_id: option.value,
                  name: option.label,
                }));
                handleChange("service_products", selectedValues);
              }}
            />
          </div>
            </li>*/}
      </ul>
    </>
  );
}
