import React, { useRef, useState } from "react";
import { Input } from "reactstrap";
import Select from 'react-select';
import { Icons } from "utils/contants/Icons";

const TableListRolePermissionFilterBlock = ({ roles,selectedRole, search,onSearch, onSelectRole }) => {
	// const selectRef = useRef(null);
	
	const handleChange = (e) => {
		onSelectRole(e)

	}
	return (
		<>
		<div className="table-list-patients-filter-blc-wrapper">
			<div className="left-col">
			<div className="table-list-filter-blc d-flex align-items-center">
				{/* <div className="search-cell col-cell position-relative">
					<span className="icomoon-search d-inline-flex align-items-center justify-content-center position-absolute"></span>
					<Input placeholder="Search" onChange={onSearch} />
				</div> */}
				<div className="search-group-input  col-cell search-cell position-relative me-2">
					<Icons.SearchIcon />
					<input type="text" className="form-control" placeholder="Search" value={search} onChange={(e) => {
						onSearch(e.target.value)
					}} />
				</div>
				<div className="input-cell col-cell mt-2">
					<Select value={selectedRole}
						onChange={handleChange}
						options={[{id: "All", label: "All"},...roles]}
						styles={{
							control: (provided) => ({
								...provided,
								wordWrap: "normal",
								backgroundColor: "#f8f8f8",
								color: "#000000 !important",
								border: "1px solid #eeeef2",
								fontSize: "13px",
								outline: "none",
								padding: "0px",
								marginTop: "-10px",
								boxShadow: "none",
								borderRadius: "10px"
							}),
							indicatorSeparator: (provided) => ({
								...provided,
								width: 0,
								backgroundColor: 'transparent',
								margin: 0,
							}),
							dropdownIndicator: (provided) => ({
								...provided,
								marginLeft: "-12px"
							}),
							valueContainer: (provided) => ({
								...provided,
								paddingLeft: "2px",
							}),
							singleValue: (provided) => ({
								...provided,
								
							}),
							menu: (provided) => ({
								...provided,
								color: "#000000",
								background: "white",
							}),
							menuList: (provided) => ({
								...provided,
								fontSize: "13px",
								boxShadow: "2px -1px 300px -26px rgba(255,255,255,1)",
							}),
							option: (provided, state) => ({
								...provided,
								color: "black !important",
								backgroundColor: "white !important",
								textTransform: "capitalize",
								':hover': {
									backgroundColor: "#bed2ef !important"
									// color:"white",
								},
							}),
						}}
					/>
					{/* <select
					
						onChange={(e) => {
							console.log("%c Line:23 🥛 e", "color:#e41a6a", e);
							onSelectRole(e)
						}}
					>
						<option value={""} selected={true} disabled={true}>Select Type</option>
						<option value={"amount"}>Amount</option>
						<option value={"percentage"}>Percentage</option>
					</select> */}
					{/* <Input type="select" name="select" id="selects" onChange={(e) => {
					onSelectRole(e)
					console.log("selectRef", selectRef)
				}} innerRef={selectRef}>
					<option value={"all"}>View By Roles</option>
					<option value={"all"}>All</option>
					{(roles || []).map((item, index) => <option value={item._id}>{item.role}</option>)}
				</Input> */}
				</div>
			</div >
			</div>
			</div>
		</>
	);
};

export default TableListRolePermissionFilterBlock;
