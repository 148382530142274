import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Input } from "reactstrap"; // eslint-disable-line
import DateBoxInput from "shared/FormsInput/DateBoxInput";
import DateRange from "shared/FormsInput/DateRange";
import { formatPhoneNumber } from "utils/common";
import { getActiveRoles } from "context/actions/clinicPermission";
import { useDispatch } from "react-redux";

const TableListFilterBlock = ({
  onSearch,
  onSelectGender,
  onSelectRole,
  onSelectStatus,
  onChangeDate
}) => {
  // const { cities, roles } = useSelector((state) => state.appContentReducer);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const [roles, setRoles] = useState([])

  useEffect(() => {

      dispatch(getActiveRoles({}, (res) => {
        if (res?.status) {
            setRoles(res?.response_data)
        } 
      }))}, [])



  return (
    <>
      <div className="table-list-filter-blc d-flex align-items-center flex-wrap">
        <div className="search-cell col-cell position-relative mt-sm-0 mt-2">
          <span className="icomoon-search d-inline-flex align-items-center justify-content-center position-absolute"></span>
          <Input placeholder="Search" onChange={onSearch} />
        </div>
        <div className="input-cell col-cell mt-sm-0 mt-2">
          <Input
            style={{ textTransform: "capitalize" }}
            type="select"
            name="select"
            onChange={onSelectRole}
          >
            <option value="">All Roles</option>
            {/* <option value="doctor">Doctor</option>
            <option value="vet-tech">Vet-tech</option>
            <option value="receptionist">Receptionist</option> */}
            {roles?.map((item) => <option value={item}>{item}</option>)}
          </Input>
        </div>

        <div className="input-cell col-cell  mt-sm-0 mt-2">
          <Input type="select" name="select" onChange={onSelectStatus}>
            <option value="">All</option>
            <option value="active">Active</option>
            <option value="in-active">Inactive</option>
          </Input>
        </div>
        <div className="col-cell mt-sm-0 mt-2 ">
          <DateRange
            id="dateRange"
            name="dateRange"
            placeholder="Select Hire Date"
            startDateProp={startDate}
            endDateProp={endDate}
            setStartDateProp={setStartDate}
            setEndDateProp={setEndDate}
            inputClassName="custom-input-grey"
            onChange={(sDate, eDate) => {
              const startDate = sDate
                ? moment(sDate).format("YYYY-MM-DD")
                : "";
              const endDate = eDate
              ? moment(eDate).format("YYYY-MM-DD")
              : ""
              onChangeDate(startDate, endDate);
           
            }}
            isClearable
          />
        </div>
        {/*
        <div className="input-cell col-cell">
          <Input type="select" name="select" onChange={onSelectGender}>
            <option value="">All Genders</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </Input>
        </div>*/}

        
      </div>
    </>
  );
};

export default TableListFilterBlock;
