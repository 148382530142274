const initState = {
    loading: false,
    testResults: { 
        dates: [], 
        data: []
    },
    tests_loading: false,
    tests: [],
    diagnosisTestResults: {
        data: []
    }

}

export const diagnosticTestingReducer = (state = initState, action) => {
    switch (action.type) {
        case "DIAGNOSTIC_RESULTS_LOADING":
            return { ...state, loading: action.payload }

        case "DIAGNOSTIC_RESULTS":
            return { ...state, testResults: action.payload }

        case "DIANOSTIC_TESTS_LOADING" : 
            return {...state, tests_loading: action.payload}

        case "DIAGNOSTIC_TESTS":
            return {...state, tests: action.payload}

        case "DIAGNOSTIC_TEST_RESULTS":
            return { ...state, diagnosisTestResults: action.payload }

        default:
            return state
    }
}
