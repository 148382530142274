import { useState } from "react";

import Description from "../add-description";
import AddData from "./AddData";
import EditService from "./EditService";

export default function AddServicesTable({ ServiceDetails }) {
  const [list, setList] = useState(ServiceDetails?.service_products);

  const handleDescriptionChange = (description, index) => {
    const updatedList = [...list];
    updatedList[index].description = description;
    setList(updatedList);
  };

  const handleDelete = (index) => {
    const updatedList = list.filter((_, i) => i !== index);
    setList(updatedList);
  };
  return (
    <>
      <h5 className="text-lg font-semibold text-[#282E65]">Products</h5>
      <AddData list={list} setList={setList} />

      <div className="w-full overflow-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-400 uppercase border-b">
            <tr>
              <th style={{width: '25%'}} scope="col" className="px-2 py-2.5 whitespace-nowrap">
                Name  
              </th>
              <th style={{width: '25%'}} scope="col" className="px-2 py-2.5 whitespace-nowrap">
                Details 
              </th>
              <th style={{width: '25%'}} scope="col" className="px-2 py-2.5 whitespace-nowrap">
                Category
              </th>
              <th style={{width: '100%'}} scope="col" className="px-2 py-2.5">
                <span className="sr-only">Action</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.map((l, i) => (
              <tr className="hover:bg-gray-200">
                <td scope="row" className="px-2 py-2.5 max-w-24 truncate">
                  {l?.product_name}
                </td>
                <td className="px-2 py-2.5">{l?.product_description || '--'}</td>
                <td style={{textTransform: 'capitalize'}} className="px-2 py-2.5">{l?.category}</td>
                <td style={{textAlign: 'end', width: '100%'}} className="px-2 py-2.5 inline-flex gap-x-2">

                  <div className="w-100 d-flex flex-row justify-content-end">
                 
                  <EditService index={i} list={list} setList={setList} />
                  <button
                    onClick={() => handleDelete(i)}
                    className=" text-gray-400 hover:text-gray-500"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {list?.length === 0 && (
          <p className="w-full text-center py-5">No products found</p>
        )}
      </div>
    </>
  );
}
