import React, { useEffect, useState } from "react";
import ProfileHead from "./ProfileHead";
import ProfileDescription from "./ProfileDescription";
import ProfileChart from "./ProfileChart";
import ProfileTags from "./ProfileTags";
import ProfileAllergy from "./ProfileAllergy";
import ProfileContact from "./ProfileContact";
import ProfileVaccination from "./ProfileVaccination";
import { useDispatch, useSelector } from "react-redux";
import ProfilePlan from "./ProfilePlan";
import "./index.scss";

import { values } from "pdf-lib";
import {
  getPetDetailsById,
  updatePetdetails,
} from "../../../context/actions/patient-module/patient/pet";
import { useParams } from "react-router-dom";
import {
  getAllBreeds,
  getAllSpecies,
} from "../../../context/actions/app-content";
import AppLogo2 from "../../AppLogo2";
import Logout from "../../../components/pet-owner-dashboard/Logout";

const PatientProfile = () => {
  const { petDetails } = useSelector((state) => state.petReducer);

  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(getPetDetailsById(params.petId, {}, () => {}));
    // setState()
  }, []);

  return (
    <>
      <div className="patient-profile d-flex flex-column overflow-auto">
        <AppLogo2 />
        <ProfileHead setShow={setShow} />
        <div className="inner overflow-auto">
          <ProfileDescription />
          <ProfileChart />
          {/* <ProfileTags showRemoveIcon={false} /> */}
          <ProfileAllergy showRemoveIcon={false} target="add-allergy-main" />
          <ProfileContact target={"add-contact-user"} />

          <Logout />

          {/*<ProfileVaccination />*/}
          {/*petDetails?.wellness_plan_id && <ProfilePlan />*/}
        </div>
      </div>
    </>
  );
};

export default PatientProfile;
