import React from "react";
import moment from "moment";
import { AiOutlineClockCircle } from "react-icons/ai";



const RemainderCard = ({ task }) => {

    return (<div className="reminder-warpper-message mt-2">
        <div className="reminder-heading d-flex justify-content-between w-100 mb-2">
            <h4>{task?.name}</h4>
            <div>
                
            </div>
        </div>
        <p>
                Assigned To: {task?.assigned_to?.name}
            </p>
            
            <p className="flex gap-1 items-center ">
            <AiOutlineClockCircle size="18" color="#000000" />  {moment(task?.date).format('MMM DD [at] hh:MM A')}
            </p>
    </div>)
}

export default RemainderCard