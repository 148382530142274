const initState = {
    meetingLoading: true,
    zmClient: null,
    audio: {
        encode: false,
        decode: false
    },
    video: {
        encode: false,
        decode: false
    },
    share: {
        encode: false,
        decode: false
    }
}

export const videoReducer = (state = initState, action) => {
    switch (action.type) {
        case "MEETING_LOADING":
            return { ...state, meetingLoading: action.payload }


        case "GET_ZOOM_CLIENT":
            return { ...state, zmClient: action.payload }
        case 'audio-encode': {
            state.audio.encode = action.payload;
            return { ...state }
        }
        case 'audio-decode': {
            state.audio.decode = action.payload;
            return { ...state }
        }
        case 'video-encode': {
            state.video.encode = action.payload;
            return { ...state }
        }
        case 'video-decode': {
            state.video.decode = action.payload;
            return { ...state }
        }
        case 'share-encode': {
            state.share.encode = action.payload;
            return { ...state }
        }
        case 'share-decode': {
            state.share.decode = action.payload;
            return { ...state }
        }
        case 'reset-media': {
            state = {
                ...state,
                audio: {
                    encode: false,
                    decode: false
                },
                video: {
                    encode: false,
                    decode: false
                },
                share: {
                    encode: false,
                    decode: false
                }
            };
            return { ...state }
        }
        default:
            return state
    }
}