import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Form,
  FormGroup,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
// import SwitchCell from "../../../../../shared/SwitchCell";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";
import { toasterConfig } from "../../../utils/constants";
import {
  PORT,
  apiUrl,
  imageBaseUrl,
  documentPhotosPath,
} from "../../../environment";
import UploadBlock from "../../UploadBlock";
import { useParams } from "react-router-dom";
import {
  addNewPetDocument,
  updatePetDocument,
} from "../../../context/actions/patient-module/document";

const AddEditPetDocument = ({
  className = "",
  closePetDocumentAddEditPanelFunction,
  selectedDocument,
  setSelectedDocument,
}) => {
  const token = useSelector((state) => state?.user?.token);

  const userDetails = useSelector((state) => state?.user?.user_details);

  const { petDetails } = useSelector((state) => state.petOwnerDashboardReducer);
  const petId2 = petDetails?._id;

  const params = useParams();
  const petId = params.petId;

  const [images, setImages] = useState([]);
  const [imagesarr, setImagesarr] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);

  const dispatch = useDispatch();

  let defaultInputState = {
    document_name: "",
    document_type: "Intake Form",
    notes: "",
    valid_till: "",
    pet_id: petId,
  };

  const [inputs, setInputs] = useState(defaultInputState);
  console.log("inputs -", inputs);
  useEffect(() => {
    if (selectedDocument) {
      setInputs({
        document_name: selectedDocument.document_name,
        document_type: selectedDocument.document_type,
        notes: selectedDocument.notes,
        valid_till: selectedDocument.valid_till,
      });
    }
  }, [selectedDocument]);

  const handleInputChange = (e) => {
    e.preventDefault();
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };


  const resetForm = () => {
    setImageFiles([]);
    setImages([]);
    setImagesarr([]);
    setInputs(defaultInputState);
    setSelectedDocument(null);
  };

  useEffect(() => {
    return () => {
      console.log("cleaned up");
      resetForm()
    };
  }, []);

  const createUpdatePetDocument = () => {
    if (!selectedDocument) {
      let formdata = new FormData();

      if (images && images.length > 0) {
        for (let image of images) {
          formdata.append("document", image, image.name);
        }
      }

      formdata.append("pet_id", userDetails?.role === "pet-owner" ? petId2: petId);
      formdata.append("added_by", userDetails?._id);
      formdata.append("document_name", inputs.document_name);
      formdata.append("document_type", inputs.document_type);
      formdata.append("notes", inputs.notes);
      formdata.append("valid_till", inputs.valid_till);

      dispatch(
        addNewPetDocument(formdata, userDetails?.role === "pet-owner" ? petId2 : petId, (response) => {
          if (response && !response.errors) {
            resetForm();
            closePetDocumentAddEditPanelFunction();
          } else {
          }
        })
      );
    } else {
      let formdata = new FormData();

      if (images && images.length > 0) {
        for (let image of images) {
          formdata.append("document", image, image.name);
        }
      }
      formdata.append("image_document_link", selectedDocument.document_link);
      formdata.append("document_id", selectedDocument._id);

      formdata.append("pet_id",userDetails?.role === "pet-owner" ? petId2 : petId);
      formdata.append("added_by", userDetails?._id);
      formdata.append("document_name", inputs.document_name);
      formdata.append("document_type", inputs.document_type);
      formdata.append("notes", inputs.notes);
      formdata.append("valid_till", inputs.valid_till);

      dispatch(
        updatePetDocument(formdata, userDetails?.role === "pet-owner" ? petId2 : petId, (response) => {
          if (response && !response.errors) {
            // resetForm()
          } else {
          }
        })
      );
    }
  };
  return (
    <>
      <div
        className={`ocular-new-point-panel-wrapper position-fixed d-flex flex-column overflow-auto ${className}`}
      >
        <div className="head d-flex align-items-center">
          <h2>
            {selectedDocument && selectedDocument._id
              ? "Edit Pet Document"
              : "Add New Pet Document"}
          </h2>
          <Button
            color="link"
            className="ms-auto panel-close-btn"
            onClick={() => {
              closePetDocumentAddEditPanelFunction();
              resetForm();
            }}
          >
            <span className="material-symbols-outlined">close</span>
          </Button>
        </div>

        <div className="inner align-items-center mt-2">
          <Form>
            <FormGroup className="input-wrapper2 mb-2">
              {/* <div className="form-floating position-relative text-input"> */}
              <UploadBlock
                images={images}
                setImages={setImages}
                imagesarr={imagesarr}
                setImagesarr={setImagesarr}
                imageFiles={imageFiles}
                setImageFiles={setImageFiles}
                documentimages={
                  selectedDocument
                    ? `${imageBaseUrl}${documentPhotosPath}/${selectedDocument.document_link}`
                    : null
                }
                documentname={
                  selectedDocument ? selectedDocument.document_name : null
                }
                accept={
                  "image/png, image/jpg, image/jpeg, application/pdf"
                }
                multiple={false}
              />
              {/* </div> */}
            </FormGroup>

            <FormGroup className="input-wrapper2 mb-2">
              <div className="form-floating position-relative text-input">
                <input
                  type="text"
                  className="form-control"
                  id="document_name"
                  name="document_name"
                  placeholder="Document Name"
                  value={inputs.document_name}
                  onChange={handleInputChange}
                />
                <label htmlFor="document_name">Document Name</label>
              </div>
            </FormGroup>

            <FormGroup className="input-wrapper2 mb-2">
              <div className="form-floating position-relative text-input">
                <select
                  className="form-control"
                  id="document_type"
                  name="document_type"
                  type="text"
                  placeholder="Document Type"
                  value={inputs.document_type}
                  onChange={handleInputChange}
                >
                  <option value={"Intake Form"}>Intake Form</option>
                  <option value={"After Visit Summary"}>After Visit Summaries</option>
                  <option value={"Estimates"}>Estimates</option>
                  <option value={"Certificates"}>Certificates</option>
                  <option value={"Invoices"}>Invoices</option>
                  <option value={"Other"}>Other</option>
                </select>
                <label htmlFor="document_type">Document Type</label>
              </div>
            </FormGroup>

            <FormGroup className="input-wrapper2 mb-2">
              <div className="form-floating position-relative text-input">
                <input
                  type="text"
                  className="form-control"
                  id="notes"
                  name="notes"
                  placeholder="Notes"
                  value={inputs.notes}
                  onChange={handleInputChange}
                />
                <label htmlFor="notes">Notes</label>
              </div>
            </FormGroup>

            <FormGroup className="input-wrapper2 mb-2">
              <div className="form-floating position-relative text-input">
                <input
                  type="date"
                  className="form-control"
                  id="valid_till"
                  name="valid_till"
                  placeholder="Valid Till"
                  value={inputs.valid_till}
                  onChange={handleInputChange}
                />
                <label htmlFor="valid_till">Valid Until</label>
              </div>
            </FormGroup>

            <div className="btn-blc border_btn mt-3 text-end">
            
              <Button
                color="success"
                onClick={() => {
                  createUpdatePetDocument();
                }}
                className="add-btn"
              >
                {selectedDocument && selectedDocument._id
                  ? "SAVE"
                  : "ADD"}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditPetDocument;
