import React from "react";
import moment from "moment";
import { getAppointmentTimingDisplayValues } from "utils/calendar/constant";
import { FaUser } from "react-icons/fa";
import "./index.scss";

const UpcomingVisitCard = ({ data, onClick }) => {
  return (
    <>
      {/* <div onClick={onClick} className="upcoming-visit-item my-2 h-100"> */}
      <div onClick={onClick} className="upcoming-visit-item">
        {moment(data?.appointment_date, "DD-MM-YYYY").isSame(
          moment(),
          "day"
        ) && moment(data?.appointment_date + " " +data?.appointment_timings[0]?.slot_time,  "DD-MM-YYYY h:mm a").isAfter(moment()) && (
          <div className="time-to-start-banner">
            <div className="time-to-start-text">
              Start in{" "}
              {moment(
                data?.appointment_date + " " + data?.appointment_timings?.sort((a, b) => moment(`1970-01-01 ${a.slot_time}`, `YYYY-MM-DD hh:mm a`).valueOf() - moment(`1970-01-01 ${b.slot_time}`, 'YYYY-MM-DD hh:mm a').valueOf())?.[0]?.slot_time,
                "DD-MM-YYYY h:mm a"
              ).fromNow(true)}
            </div>
          </div>
        )}
        <div className="doctor-info">
          <div className="picture">
            {data?.doctor_id?.user_image ? (
              <img src={data?.doctor_id?.user_image} alt="user-img" />
            ) : (
              <div className="f-center h-100 bg-e3e3 rounded-circle">
                <FaUser color="#788693" size={20} />
              </div>
            )}
          </div>
          <div className="info">
            <div className="name-title">
              <div className="name">
                {data?.doctor_id?.title} {data.doctor_id.firstname}{" "}
                {data.doctor_id.lastname}
                {/*Dr. Martha William*/}
              </div>
              <div className="title">(DVM)</div>
            </div>
          </div>
        </div>
        <div className="appointment-info">
          <div className="date">
            <div className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <mask
                  id="mask0_6549_29828"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                >
                  <rect width="20" height="20" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_6549_29828)">
                  <path
                    d="M12.686 13.457C12.7834 13.554 12.9014 13.6025 13.04 13.6025C13.179 13.6025 13.311 13.547 13.436 13.436C13.5334 13.325 13.5855 13.1965 13.5925 13.0505C13.5992 12.9049 13.547 12.7764 13.436 12.665L10.561 9.79003V6.20703C10.561 6.06803 10.5089 5.94986 10.4045 5.85253C10.3005 5.75553 10.1722 5.70703 10.0195 5.70703C9.88053 5.70703 9.75886 5.75553 9.65453 5.85253C9.55053 5.94986 9.49853 6.07486 9.49853 6.22753V9.93603C9.49853 10.0334 9.51586 10.1202 9.55053 10.1965C9.5852 10.2729 9.63036 10.3457 9.68603 10.415L12.686 13.457ZM9.99853 17.915C8.9152 17.915 7.89086 17.7067 6.92553 17.29C5.9602 16.8734 5.11986 16.3075 4.40453 15.5925C3.68953 14.8772 3.1237 14.0369 2.70703 13.0715C2.29036 12.1062 2.08203 11.0819 2.08203 9.99853C2.08203 8.9152 2.29036 7.89086 2.70703 6.92553C3.1237 5.9602 3.68953 5.12003 4.40453 4.40503C5.11986 3.6897 5.9602 3.1237 6.92553 2.70703C7.89086 2.29036 8.9152 2.08203 9.99853 2.08203C11.0819 2.08203 12.1062 2.29036 13.0715 2.70703C14.0369 3.1237 14.877 3.6897 15.592 4.40503C16.3074 5.12003 16.8734 5.9602 17.29 6.92553C17.7067 7.89086 17.915 8.9152 17.915 9.99853C17.915 11.0819 17.7067 12.1062 17.29 13.0715C16.8734 14.0369 16.3074 14.8772 15.592 15.5925C14.877 16.3075 14.0369 16.8734 13.0715 17.29C12.1062 17.7067 11.0819 17.915 9.99853 17.915ZM9.99853 16.8735C11.8875 16.8735 13.5055 16.1999 14.8525 14.8525C16.1999 13.5055 16.8735 11.8875 16.8735 9.99853C16.8735 8.10953 16.1999 6.49153 14.8525 5.14453C13.5055 3.7972 11.8875 3.12353 9.99853 3.12353C8.10953 3.12353 6.49153 3.7972 5.14453 5.14453C3.7972 6.49153 3.12353 8.10953 3.12353 9.99853C3.12353 11.8875 3.7972 13.5055 5.14453 14.8525C6.49153 16.1999 8.10953 16.8735 9.99853 16.8735Z"
                    fill="#282E65"
                  />
                </g>
              </svg>
            </div>
            <div className="day w-100" style={{ textOverflow: "ellipsis" }}>
              {`${moment(data?.appointment_date, "DD-MM-YYYY ").format(
                "MMM D, YYYY"
              )} (${moment(
                data?.appointment_date +
                  " " +
                  data?.appointment_timings?.sort(
                    (a, b) =>
                      moment(
                        `1970-01-01 ${a.slot_time}`,
                        `YYYY-MM-DD hh:mm a`
                      ).valueOf() -
                      moment(
                        `1970-01-01 ${b.slot_time}`,
                        "YYYY-MM-DD hh:mm a"
                      ).valueOf()
                  )?.[0]?.slot_time,
                "DD-MM-YYYY h:mm a"
              ).fromNow()})`}
              <br />
              {`${
                getAppointmentTimingDisplayValues(
                  data?.appointment_timings
                )?.[0]
              }`}
              {/*09:00 am - 10:00 am (Today) */}
            </div>
          </div>
          {data?.appointment_type === "video-call" ||
          data?.appointment_type === "phone-call" ||
          data?.appointment_type === "video-conference" ? (
            <div className="time">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <mask
                    id="mask0_6549_29834"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="20"
                    height="21"
                  >
                    <rect y="0.5" width="20" height="20" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_6549_29834)">
                    <path
                      d="M9.99853 9.81103C9.5402 9.81103 9.15486 9.65486 8.84253 9.34253C8.52986 9.02986 8.37353 8.64436 8.37353 8.18603C8.37353 7.7277 8.52986 7.3422 8.84253 7.02953C9.15486 6.7172 9.5402 6.56103 9.99853 6.56103C10.4569 6.56103 10.8422 6.7172 11.1545 7.02953C11.4672 7.3422 11.6235 7.7277 11.6235 8.18603C11.6235 8.64436 11.4672 9.02986 11.1545 9.34253C10.8422 9.65486 10.4569 9.81103 9.99853 9.81103ZM6.14453 18.207C6.14453 17.9154 6.2452 17.6689 6.44653 17.4675C6.64786 17.2659 6.89436 17.165 7.18603 17.165H9.47753C9.47753 15.915 9.2762 14.9949 8.87353 14.4045C8.47086 13.8145 7.8597 13.2487 7.04003 12.707C6.26236 12.207 5.6582 11.5715 5.22753 10.8005C4.7972 10.0299 4.58203 9.15836 4.58203 8.18603C4.58203 6.67203 5.1097 5.39086 6.16503 4.34253C7.2207 3.29386 8.49853 2.76953 9.99853 2.76953C11.5125 2.76953 12.7939 3.29386 13.8425 4.34253C14.8909 5.39086 15.415 6.67203 15.415 8.18603C15.415 9.14436 15.1894 10.0229 14.738 10.8215C14.2867 11.6202 13.686 12.2555 12.936 12.7275C12.1027 13.2555 11.4915 13.8145 11.1025 14.4045C10.7139 14.9949 10.5195 15.915 10.5195 17.165H12.811C13.1167 17.165 13.3667 17.2659 13.561 17.4675C13.7554 17.6689 13.8525 17.9154 13.8525 18.207H6.14453ZM9.99853 12.561C11.2069 12.561 12.2382 12.1339 13.0925 11.2795C13.9465 10.4255 14.3735 9.39436 14.3735 8.18603C14.3735 6.9777 13.9465 5.94653 13.0925 5.09253C12.2382 4.2382 11.2069 3.81103 9.99853 3.81103C8.7902 3.81103 7.75886 4.2382 6.90453 5.09253C6.05053 5.94653 5.62353 6.9777 5.62353 8.18603C5.62353 9.39436 6.05053 10.4255 6.90453 11.2795C7.75886 12.1339 8.7902 12.561 9.99853 12.561Z"
                      fill="#282E65"
                    />
                  </g>
                </svg>
              </div>
              <div className="time-range w-100">
                {data?.appointment_type === "video-call" ||
                data?.appointment_type === "video-conference"
                  ? "Video Call Consultancy"
                  : "Phone Call"}
              </div>
            </div>
          ) : (
            <div className="location">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <mask
                    id="mask0_6549_29630"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="20"
                    height="21"
                  >
                    <rect y="0.5" width="20" height="20" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_6549_29630)">
                    <path
                      d="M10.0015 11.854C10.1541 11.854 10.2791 11.8055 10.3765 11.7085C10.4738 11.6112 10.5225 11.4862 10.5225 11.3335V9.354H12.5015C12.6541 9.354 12.7791 9.3055 12.8765 9.2085C12.9738 9.11117 13.0225 8.98617 13.0225 8.8335C13.0225 8.6805 12.9738 8.5555 12.8765 8.4585C12.7791 8.36117 12.6541 8.3125 12.5015 8.3125H10.5225V6.3335C10.5225 6.1805 10.4738 6.0555 10.3765 5.9585C10.2791 5.86117 10.1541 5.8125 10.0015 5.8125C9.8488 5.8125 9.7238 5.86117 9.62647 5.9585C9.52914 6.0555 9.48047 6.1805 9.48047 6.3335V8.3125H7.50147C7.3488 8.3125 7.2238 8.36117 7.12647 8.4585C7.02914 8.5555 6.98047 8.6805 6.98047 8.8335C6.98047 8.98617 7.02914 9.11117 7.12647 9.2085C7.2238 9.3055 7.3488 9.354 7.50147 9.354H9.48047V11.3335C9.48047 11.4862 9.52914 11.6112 9.62647 11.7085C9.7238 11.8055 9.8488 11.854 10.0015 11.854ZM10.0015 17.271C11.7931 15.6737 13.1438 14.1563 14.0535 12.719C14.9631 11.2813 15.418 10.0417 15.418 9C15.418 7.361 14.8971 6.02067 13.8555 4.979C12.8138 3.93733 11.5291 3.4165 10.0015 3.4165C8.4738 3.4165 7.18914 3.93733 6.14747 4.979C5.1058 6.02067 4.58497 7.361 4.58497 9C4.58497 10.0417 5.0468 11.2813 5.97047 12.719C6.8938 14.1563 8.23747 15.6737 10.0015 17.271ZM10.0015 18.4165C9.91814 18.4165 9.8348 18.4027 9.75147 18.375C9.66814 18.3473 9.5918 18.3057 9.52247 18.25C7.50847 16.4583 6.00847 14.7917 5.02247 13.25C4.03614 11.7083 3.54297 10.2917 3.54297 9C3.54297 6.986 4.1923 5.37833 5.49097 4.177C6.78964 2.97567 8.29314 2.375 10.0015 2.375C11.7098 2.375 13.2133 2.97567 14.512 4.177C15.8106 5.37833 16.46 6.986 16.46 9C16.46 10.2917 15.9668 11.7083 14.9805 13.25C13.9945 14.7917 12.4945 16.4583 10.4805 18.25C10.4251 18.3057 10.3558 18.3473 10.2725 18.375C10.1891 18.4027 10.0988 18.4165 10.0015 18.4165Z"
                      fill="#282E65"
                    />
                  </g>
                </svg>
              </div>
              <div
                style={{ textTransform: "capitalize" }}
                className="location-name"
              >
                {data?.clinic}
              </div>
            </div>
          )}
        </div>
        <div className="appointment-description">
          <div className="appointment-title">
            {data?.reason}
            {/*Routine checkup*/}
          </div>
          <div
            style={{ textOverflow: "ellipsis", overflow: "hidden" }}
            className="appointment-description-body"
          >
            {data?.notes}
            {/*A visit to a primary care physician for the purpose of maintaining wellness.*/}
          </div>
          {data.appointment_start && !data.appointment_end && (
            <div className="btn-cell d-flex">
              <div className="appointment-status in-progress w-100 h-100">
                {data?.appointment_type === "surgery"
                  ? "Surgery"
                  : "Appointment"}{" "}
                In Progress
              </div>
            </div>
          )}
        </div>
        <div className="actions mt-auto">
          {data?.appointment_type.includes("video") && (
            <div className="start-video-btn">
              <div className="start-video-text">Start Video Call</div>
            </div>
          )}
          <div className="action-btn-group">
            <div className="action-btn">Cancel</div>
            <div className="action-btn">Delay</div>
            <div className="action-btn">Message</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpcomingVisitCard;
