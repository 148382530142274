import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button, ButtonDropdown, DropdownToggle, Table } from "reactstrap";
import {
  getInventoriesByClinicId,
  getSuppliersByClinicId,
  updateInventory,
} from "../../../context/actions/inventory";
import CheckBox from "../../../shared/CheckBox";
import NodataFoundBlock from "../../../shared/NoDataFoundBlock";
import PaginationBlock from "../../../shared/PaginationBlock";
import SwitchCell from "../../../shared/SwitchCell";
import TableListProductFilterWrapper from "../../../shared/TableListProductFilterWrapper";
import { dateFromDateTime } from "../../../utils/common";
import useDebounce from "hooks/useDebounce";

const Products = () => {
  const history = useHistory();
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const dispatch = useDispatch();
  const inventory = useSelector((state) => state.inventoryReducer.inventory);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);
  const [inventoryData, setInventoryData] = useState([]);
  const [filter, setFilter] = useState({
    categoryId: "",
    supplierId: "",
    stockStatus: "",
    expiredStatus: ""
  })
  const [search, setSearch] = useState("")
  const searchDebouncedValue = useDebounce(search, 300);
  useEffect(() => {
    dispatch(getSuppliersByClinicId(clinic_settings.clinic_id, {}, () => { }))
  }, [])

  useEffect(() => {
    dispatch(
      getInventoriesByClinicId(
        /*userDetails?._id*/ clinic_details?._id,
        { page, limit, search, ...filter },
        (res) => {
          setInventoryData(res?.response_data?.docs);
        }
      )
    );
  }, [page, limit, filter, searchDebouncedValue]);

  const handleUpdateStockUpdate = (item) => {
    dispatch(
      updateInventory(
        {
          inventory_id: item?._id,
          unit_id: item?.unit_id,
          category_id: item?.category_id,
          supplier_id: item?.supplier_id,
          product_name: item?.product_name,
          stock_status: !item?.stock_status,
        },
        () => {
        }
      )
    );
  };

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  // console.log("inventoryData----", inventoryData)
  // console.log("inventory----", inventory)
  // if (loading) {
  //   return <LoaderUI />;
  // }



  return (
    <>
      <div className="product-list-wrapper">
        <TableListProductFilterWrapper setFilter={setFilter} setSearch={setSearch} />
        {/* {(inventory && inventory?.docs?.length > 0) ? <div className="table-listing-blc overflow-auto"> */}
        {inventoryData?.length > 0 ? (
          <div className="table-listing-blc overflow-auto">
            <Table striped responsive className="checkbox-listing">
              <thead>
                <tr>
                  <th>
                    <CheckBox
                      type="checkbox"
                      ischecked={false}
                      className="no-label"
                    />
                  </th>
                  <th>Name</th>
                  <th style={{ minWidth: "120px" }}>SKU</th>
                  <th style={{ minWidth: "130px" }}>Status</th>
                  <th style={{ minWidth: "130px" }}>Category</th>

                  <th style={{ minWidth: "100px" }}>Price</th>
                  <th style={{ minWidth: "80px" }}>Unit</th>
                  <th style={{ minWidth: "80px" }}>Qty</th>
                  <th>Description</th>
                  {permissions?.edit_products &&
                    <th className="text-end">&nbsp;</th>
                  }
                </tr>
              </thead>
              <tbody>
                {inventoryData?.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <CheckBox
                          type="checkbox"
                          ischecked={false}
                          className="no-label"
                        />
                      </td>
                      <td>
                        <Button
                          color="link"
                          className="p-0 text-start pic-cell pic-cell-type-2 d-inline-flex align-items-center w-100 truncate_btn"
                          onClick={() => {
                            history.push(`/product-detail/${item?._id}`);
                          }}
                        >
                          <div className="pic d-inline-flex flex-shrink-0">
                            {item?.image ? (
                              <img
                                className="img-fluid w-100 h-100"
                                src={item?.image}
                                alt="Product"
                                style={{
                                  borderRadius: "0",
                                }}
                              />
                            ) : (
                              <img
                                className="img-fluid w-100 h-100"
                                src={require("../../../assets/img/vetRecorEMR/product-thumbnail-square.png")}
                                alt="Product"
                                style={{
                                  borderRadius: "0",
                                }}
                              />
                            )}
                            {/* <UserPlaceholder /> */}
                          </div>
                          <span>{item?.product_name}</span>
                        </Button>
                      </td>
                      <td>{item?.sku}</td>
                      <td>
                        <SwitchCell
                          onClick={() => handleUpdateStockUpdate(item)}
                          labelVisible={true}
                          className={
                            item.available_stock
                              ? ""
                              : "disable"
                          }
                          label={
                            item.available_stock > 0
                              ? "In-Stock"
                              : "Out of Stock"
                          }
                        />
                      </td>
                      <td style={{ textTransform: "capitalize" }}>
                        {item?.category}
                      </td>
                      <td>
                        $
                        {parseFloat(item?.product_cost)?.toLocaleString(
                          "en-US",
                          { minimumFractionDigits: 2 }
                        )}
                      </td>
                      <td>{item?.unit_of_measure}</td>
                      <td>{item?.available_stock}</td>

                      {item?.description ? (
                        <td
                          className="text-clip-cell"
                          style={{ maxWidth: "180px" }}
                        >
                          {item?.description}
                        </td>
                      ) : (
                        <td
                          className="text-clip-cell"
                          style={{
                            minWidth: "260px",
                            maxWidth: "260px",
                            width: "100%",
                          }}
                        >
                          --
                        </td>
                      )}
                      {permissions?.edit_products &&
                        <td className="action-cell">
                          <Button color="link" className="edit-btn" onClick={() => {
                            dispatch({ type: "Single_Product_Inventory", payload: item })
                            history.push(`/inventory/edit-existing-product-in-inventory`);
                          }}>
                            <span className="icomoon-pencil"></span>
                          </Button>
                          <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle color="link" className="more-btn">
                              <span className="icomoon-more"></span>
                            </DropdownToggle>{" "}
                          </ButtonDropdown>
                        </td>
                      }
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <PaginationBlock
              limit={limit}
              page={page}
              pagingCounter={inventory?.pagingCounter || 1}
              docLength={inventory?.docs?.length || 0}
              docPage={inventory.page || 1}
              totalPages={inventory.totalPages || 1}
              totalDocs={inventory.totalDocs || 0}
              _handleLimit={(val) => {
                setLimit(val);
              }}
              _handlePageChange={setPage}
            />
          </div>
        ) : (
          <NodataFoundBlock type={ filter?.categoryId !== "" || 
          filter?.supplierId !==  "" || 
          filter?.stockStatus !== "" ||
          filter?.expiredStatus !== "" || search !== ""  ? 'search result': ''} />
        )}
      </div>
    </>
  );
};

export default Products;
