
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";

toast.configure();

/**
 * @description function use to save chat messages
 * @param {*} params accepting object
 */
export const joinRoom = (data, callback) => {
    return (dispatch, getState) => {
      const {
        user: { token },
      } = getState();
      //
      ApiClient.post(
        `${apiUrl}${PORT}/video-call/join-room`,
        data,
        token,
        dispatch, getState
      ).then((response) => {
        //
        if (!response) {
          callback(response);
        } else if (response && !response.errors) {
          callback(response);
        } else if (response?.code === 404) {
          console.log("err msg", response.message);
          toast.warn(response.message);
          callback();
        } else {
          toast.error(response.errors.msg, toasterConfig);
          console.log("err msg", response.errors.msg);
          callback(response);
        }
      });
    };
  };