import React, { useEffect, useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { Button, Input, Table } from "reactstrap";
import SwitchCell from "shared/SwitchCell";
import { Icons } from "utils/contants/Icons";
import "./InventoryManagement.scss";
import { getInventoryReports } from "context/actions/clinic-reports";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getSuppliersByClinicId } from "context/actions/inventory";
import { USDollar, dateFromDateTime, titleCaseString } from "utils/common";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { getAllInventoryCategories } from "context/actions/app-content";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler, // 1. Import Filler plugin
} from "chart.js";

import NodataFoundBlock from "shared/NoDataFoundBlock";
import PaginationBlock from "shared/PaginationBlock";
import DateRange from "shared/FormsInput/DateRange";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler // 1. Register Filler plugin
);

const InventoryManagement = () => {
  const history = useHistory();

  const expiringFilterArray = [
    {
      id: 1,
      text: "Expiring in next 3 Months",
      month: 3,
    },
    {
      id: 2,
      text: "Expiring in next 6 Months",
      month: 6,
    },
    {
      id: 3,
      text: "Expiring in next 9 Months",
      month: 9,
    },
  ];
  const dispatch = useDispatch();

  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );
  const [productData, setProductData] = useState([]);
  const [limit, setLimit] = useState(5);
  const [startByDate, setStartByDate] = useState(null);
  const [endByDate, setEndByDate] = useState(null);
  const [inventoryPage, setInventoryPage] = useState([]);
  const [inventoryPaging, setInventoryPaging] = useState([]);
  const [servicePage, setServicePage] = useState([]);
  const [servicePaging, setServicePaging] = useState([]);
  const [supplierPage, setSupplierPage] = useState(1);
  const [supplierPaging, setSupplierPaging] = useState(1);
  const [expiredProductList, setExpiredProductList] = useState([]);
  const [filterProductData, setFilterProductData] = useState([]);

  const [resData, setResData] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [supplierId, setSupplierId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [expireDate, setExpireDate] = useState("");

  const [filterData, setfilterData] = useState({
    categoryValue: "",
    supplierValue: "",
    expiringValue: "3",
    stock_status: "",
    sDate: "",
    eDate: "",
  });

  const { inventoryCategories } = useSelector(
    (state) => state.appContentReducer
  );

  const tableData = [
    {
      tableId: 1,
      title: "Available Products",
      data: productData || [],
      count: productData?.length || 0,
    },
    {
      tableId: 2,
      title: `Expiring In The Next ${filterData?.expiringValue || 3}  Months`,
      data: filterProductData || [],
      count: filterProductData?.length || 0,
    },
    {
      tableId: 3,
      title: "Expired Products",
      data: expiredProductList || [],
      count: expiredProductList?.length || 0,
    },
  ];

  const handleGetSuppliers = () => {
    dispatch(
      getSuppliersByClinicId(clinic_settings?.clinic_id, {}, (res) => {
        setSuppliers(res?.response_data?.docs);
      })
    );
  };

  useEffect(() => {
    handleGetSuppliers();
    dispatch(
      getAllInventoryCategories((res) => {
        if (res?.status) {
        }
      })
    );

    dispatch(
      getInventoryReports(
        {
          clinic_id: clinic_details?._id,
          supplier_id: supplierId,
          category_id: categoryId,
          stock_status: filterData?.stock_status,
          startDate: filterData?.sDate,
          endDate: filterData?.eDate,
        },
        (res) => {
          setResData(res);

          const availableData = res?.response_data[0]?.inventoryData?.filter(
            (data) => {
              const currentDate = moment();
              const expiredDate = moment(data?.max_expiration_date);
              return expiredDate.isAfter(currentDate);
            }
          );
          setProductData(availableData);
          //upcoming expire data
          const newData = res?.response_data[0]?.inventoryData?.filter(
            (item) => {
              if (
                filterData?.expiringValue &&
                item?.max_expiration_date !== null
              ) {
                // ====
                const startDate = moment();

                // Calculate the end date based on the number of months
                const endDate = startDate
                  .clone()
                  .add(filterData?.expiringValue, "months");

                // Provided date to check
                const checkDate = moment(item?.max_expiration_date);

                // Check if the provided date is within the range
                const isWithinRangew = checkDate.isBetween(
                  startDate,
                  endDate,
                  null,
                  "[]"
                ); // '[]' includes both start and end dates
                // =====
                const currentDate = moment();
                const expirationDate = moment(
                  item?.max_expiration_date,
                  "MM/DD/YYYY"
                );
                const monthsDifference = expirationDate.diff(
                  currentDate,
                  "months"
                );
                const isWithinRange =
                  monthsDifference >= 0 &&
                  monthsDifference <= parseInt(filterData?.expiringValue);
                return isWithinRangew;
              } else {
                // console.log("else called");
                return false;
              }
            }
          );
          // const expriedData = res?.response_data?.filter((item) => {
          //   if (
          //     item?.max_expiration_date !== null
          //   ) {
          //     const startDate = moment();

          //     // Calculate the end date based on the number of months
          //     const endDate = startDate.clone().add(numberOfMonths, 'months');

          //     // Provided date to check
          //     const checkDate = moment("02-05-2024", "MM-DD-YYYY");

          //     // Check if the provided date is in the past
          //     const isPastDate = checkDate.isBefore(startDate);

          //     // Check if the provided date is within the range
          //     const isWithinRangew = checkDate.isBefore(
          //       startDate,
          //       endDate,
          //       null,
          //       "[]"
          //     );
          //     return isWithinRangew;
          //   } else {
          //     console.log("else called");
          //     return false;
          //   }
          // });
          setFilterProductData(newData);
          // expired data
          const expiredData = res?.response_data[0]?.inventoryData?.filter(
            (data) => {
              const currentDate = moment();
              const expiredDate = moment(data?.max_expiration_date);
              return expiredDate.isBefore(currentDate);
            }
          );

          setExpiredProductList(expiredData);
        }
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    supplierId,
    categoryId,
    filterData?.expiringValue,
    filterData?.stock_status,
    filterData?.sDate,
    filterData?.eDate,
  ]);

  useEffect(() => {
    if (resData) {
      const updatedByPages = tableData?.map((arr, _) => 1);
      setInventoryPage(updatedByPages);
      setInventoryPaging(updatedByPages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resData]);

  useEffect(() => {
    if (resData?.response_data?.[0]?.serviceData) {
      const updatedByPages = resData?.response_data?.[0]?.serviceData?.map(
        (arr, _) => 1
      );
      setServicePage(updatedByPages);
      setServicePaging(updatedByPages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resData?.response_data?.[0]?.serviceData]);

  const handleDownload = () => {
    const csvContent = generateCSV();
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "Inventory Management.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const generateCSV = () => {
    let csv = "";

    tableData?.forEach((item) => {
      const { title } = item;
      if (item?.data?.length > 0) {
        csv += `\n ${title}\nProduct Name,SKU,Status,Category,Unit,Qty,Expiration\n`;
        item?.data?.forEach((item) => {
          csv += `${titleCaseString(item?.product_name)},${item?.sku},${
            item?.stock_status ? "In-Stock" : ""
          },${item?.categories ? titleCaseString(item?.categories?.category) : "-"},${
            item?.unit_of_measure ? item?.unit_of_measure : "-"
          },${item?.available_stock ? item?.available_stock : "-"},${moment(
            item?.max_expiration_date
          ).format("MM/DD/YYYY")}\n`;
        });
      }
    });

    resData?.response_data?.[0]?.serviceData?.forEach((item) => {
      const { category } = item;
      if (item?.data?.length > 0) {
        csv += `\n Service (${category})\nService Name,Service Price,Total Orders\n`;
        item?.data?.forEach((item) => {
          const { service_name, service_price, totalOrders } = item;
          csv += `${titleCaseString(service_name)},$${
            service_price
          },${totalOrders}\n`;
        });
      }
    });

    csv += `\n Supplier\nSupplier Name,Inventory Count,Orders Count\n`;
    resData?.response_data?.[0]?.supplierData?.forEach((item) => {
      const { orderCount, supplier } = item;
      csv += `${titleCaseString(supplier?.supplier_name)}, ${
        supplier?.num_items
      },${orderCount}\n`;
    });

    return csv;
  };

  const getPaginatedData = (dataArray, tabIndex) => {
    const startIndex = (inventoryPage[tabIndex] - 1) * limit;
    const endIndex = startIndex + limit;
    return dataArray?.slice(startIndex, endIndex) || [];
  };
  const getPaginatedServiceData = (dataArray, tabIndex) => {
    const startIndex = (servicePage[tabIndex] - 1) * limit;
    const endIndex = startIndex + limit;
    return dataArray?.slice(startIndex, endIndex) || [];
  };

  const supplierTotalpages = Math.ceil(
    resData?.response_data?.[0]?.supplierData?.length / limit
  );
  const supplierData = resData?.response_data?.[0]?.supplierData?.slice(
    (supplierPage - 1) * limit,
    supplierPage * limit
  );

  return (
    <div id="inventory-management-container" className="p-1 mt-1">
      <div className="row">
        <div className="col-md-3">
          <div className="">
            <div className="d-flex">
              <div
                className="d-flex gap-2 align-items-center cmb-20 pointer"
                onClick={() => history.push("/reports")}
              >
                <BsChevronLeft color="color-3EFD" />
                <span className="text-13-600 color-3EFD">VIEW ALL REPORTS</span>
              </div>
            </div>

            <div className="bg-filter-class">
              <div className="text-24-700 color-2E65">Configure Report</div>
              <div className="text-14-400 color-8693">
                Select filter and generate report
              </div>
              <div className="filter-block cmt-20 cmb-20">
                <div className="cmb-10">
                  <Input
                    type="select"
                    name="select"
                    value={filterData?.categoryValue}
                    id={categoryId}
                    placeholder="Categories"
                    onChange={(e) => {
                      setfilterData((prev) => ({
                        ...prev,
                        categoryValue: e?.target?.value,
                      }));
                      setCategoryId(e?.target?.value);
                    }}
                  >
                    <option value="">All Categories</option>
                    {inventoryCategories?.length > 0 &&
                      inventoryCategories?.map((elem, index) => {
                        return (
                          <option key={index} id={elem?._id} value={elem?._id}>
                            {titleCaseString(elem?.category)}
                          </option>
                        );
                      })}
                  </Input>
                </div>
                <div className="cmb-10">
                  <Input
                    type="select"
                    name="select"
                    value={filterData?.supplierValue}
                    id={supplierId}
                    placeholder="Suppliers"
                    onChange={(e) => {
                      setfilterData((prev) => ({
                        ...prev,
                        supplierValue: e?.target?.value,
                      }));
                      setSupplierId(e?.target?.value);
                    }}
                  >
                    <option value="">All Suppliers</option>
                    {suppliers?.map((elem, index) => {
                      return (
                        <option key={index} id={elem?._id} value={elem?._id}>
                          {titleCaseString(elem?.supplier_name)}
                        </option>
                      );
                    })}
                  </Input>
                </div>
                <div className="cmb-15">
                  <Input
                    type="select"
                    name="select"
                    value={filterData?.stock_status}
                    placeholder="Status"
                    onChange={(e) => {
                      setfilterData((prev) => ({
                        ...prev,
                        stock_status: e?.target?.value,
                      }));
                    }}
                  >
                    <option value="">All</option>
                    <option value="in-stock">In-Stock only</option>
                    <option value="out-stock">Out-of-Stock only</option>
                  </Input>
                </div>

                <div className="cmb-10">
                  <Input
                    type="select"
                    name="select"
                    value={filterData?.expiringValue}
                    id={expireDate}
                    placeholder="Expiring"
                    onChange={(e) => {
                      setfilterData((prev) => ({
                        ...prev,
                        expiringValue: e?.target?.value,
                      }));
                      setExpireDate(e?.target?.value);
                    }}
                  >
                    {/* <option value="">Expiring</option> */}
                    {expiringFilterArray?.map((el, ind) => {
                      return (
                        <option key={ind} id={el?.month} value={el?.month}>
                          {el?.text}
                        </option>
                      );
                    })}
                  </Input>
                </div>
                <div className="">
                  <DateRange
                    placeholder="Select date for service"
                    id="boarding"
                    name="boarding"
                    startDateProp={startByDate}
                    endDateProp={endByDate}
                    setStartDateProp={setStartByDate}
                    setEndDateProp={setEndByDate}
                    onChange={(sDate, eDate) => {
                      setfilterData((prev) => ({
                        ...prev,
                        sDate: sDate ? moment(sDate).format("MM-DD-YYYY") : "",
                        eDate: eDate ? moment(eDate).format("MM-DD-YYYY") : "",
                      }));
                    }}
                    isClearable
                  />
                </div>
              </div>
              <div className="cmb-15">
                {/* <Button className="g-report">Generate Report</Button> */}
                <div
                  className="text-center color-2E65 text-13-600 cmt-20 pointer"
                  onClick={() => {
                    setSupplierId("");
                    setCategoryId("");
                    setfilterData({
                      categoryValue: "",
                      supplierValue: "",
                      expiringValue: "3",
                      stock_status: "",
                      sDate: "",
                      eDate: "",
                    });
                    setStartByDate(null);
                    setEndByDate(null);
                  }}
                >
                  RESET FILTER
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-9 details-section">
          <div className="cpt-20 cpe-20 cpb-20">
            <div className="d-flex justify-content-between align-items-center cmb-20">
              <div>
                <div className="text-24-700 color-2E65">
                  Inventory Management
                </div>
                {/* <p className="text-14-400 color-8693 text-wrap">
                  Inventory reports help track the clinic’s medical supplies,
                  medications, and equipment to ensure that everything is
                  well-stocked and up to date.
                </p> */}
              </div>
              <div className="d-flex gap-2 br-8">
                <div
                  className="cp-8 bg-8C9F28 pointer"
                  onClick={() => {
                    handleDownload();
                  }}
                >
                  <img src={Icons.download} alt="download" />
                </div>
                <div className="cp-8 bg-8C9F28 br-8">
                  <img src={Icons.share} alt="share" />
                </div>
              </div>
            </div>

            {tableData?.map((elem, index) => {
              const { title, data } = elem;
              const totalItems = data?.length;
              const totalPages = Math?.ceil(totalItems / limit);
              const paginatedData = getPaginatedData(data, index);
              return (
                <div className="table-one cmb-20" key={index}>
                  <div className="text-14-600 color-2E65 cmb-10">
                    {title} ({data?.length})
                  </div>

                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Product Name</th>
                        <th>SKU</th>
                        <th>Status</th>
                        <th>Category</th>
                        <th>Unit</th>
                        <th>Qty</th>
                        <th>Expiration</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedData?.length > 0 ? (
                        paginatedData?.map((dataElem, dataIndex) => {
                          const {
                            image,
                            product_name,
                            sku,
                            stock_status,
                            categories,
                            unit_of_measure,
                            available_stock,
                            max_expiration_date,
                          } = dataElem;
                          return (
                            <tr key={dataIndex}>
                              <td>
                                <div className="d-flex gap-2 align-items-center">
                                  <div className="pic d-inline-flex flex-shrink-0 align-items-center">
                                    {image ? (
                                      <img
                                        className="img-fluid w-100 h-100"
                                        src={image}
                                        alt="Product"
                                        style={{
                                          borderRadius: "0",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        className="img-fluid w-100 h-100"
                                        src={Icons?.productThumbnailSsquare}
                                        alt="Product"
                                        style={{
                                          borderRadius: "0",
                                        }}
                                      />
                                    )}
                                  </div>
                                  <div>{product_name}</div>
                                </div>
                              </td>
                              <td>{sku}</td>
                              <td>{stock_status ? "In-Stock" : ""}</td>
                              <td>{titleCaseString(categories?.category)}</td>
                              <td>{unit_of_measure ? titleCaseString(unit_of_measure) : '--'}</td>
                              <td>{available_stock || '--'}</td>
                              <td>
                                {max_expiration_date
                                  ? dateFromDateTime(
                                      max_expiration_date,
                                      "MM/DD/yyyy"
                                    )
                                  : ""}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="no-data-rw">
                          <td colSpan="11">
                            <NodataFoundBlock />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {paginatedData?.length > 0 && (
                    <PaginationBlock
                      limit={limit}
                      page={inventoryPage[index] || 1}
                      pagingCounter={inventoryPaging[index] || 1}
                      docLength={limit || 0}
                      docPage={inventoryPage[index]}
                      totalPages={totalPages || 1}
                      totalDocs={elem?.data?.length || 0}
                      _handleLimit={(val) => {
                        setLimit(val);
                      }}
                      _handlePageChange={(e) => {
                        if (e > inventoryPage[index]) {
                          const diff = e - inventoryPage[index];
                          setInventoryPaging((prev) => {
                            const updatedPaging = [...prev];
                            updatedPaging[index] =
                              updatedPaging[index] + limit * diff;
                            return updatedPaging;
                          });
                        } else if (e < inventoryPage[index]) {
                          const diff = inventoryPage[index] - e;

                          setInventoryPaging((prev) => {
                            const updatedPaging = [...prev];
                            updatedPaging[index] = Math.max(
                              updatedPaging[index] - limit * diff,
                              0
                            );
                            return updatedPaging;
                          });
                        }
                        setInventoryPage((prevPages) => {
                          const updatedPages = prevPages?.map((page, pIndex) =>
                            pIndex === index ? e : page
                          );
                          return updatedPages;
                        });
                      }}
                    />
                  )}
                </div>
              );
            })}

            {resData?.response_data?.[0]?.serviceData?.map((elem, index) => {
              const { category, data } = elem;
              const totalItems = data?.length;
              const totalPages = Math?.ceil(totalItems / limit);
              const paginatedData = getPaginatedServiceData(data, index);
              return (
                <div className="table-one cmb-20" key={index}>
                  <div className="text-14-600 color-2E65 cmb-10">
                    {titleCaseString(category)} ({data?.length})
                  </div>

                  <Table responsive>
                    <thead>
                      <tr>
                        <th style={{width: '50%'}}>Service Name</th>
                        <th style={{width: '25%'}}>Service Price</th>
                        <th style={{width: '25%'}}>Total Orders</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedData?.length > 0 ? (
                        paginatedData?.map((dataElem, dataIndex) => {
                          const { service_name, service_price, totalOrders } =
                            dataElem;

                          return (
                            <tr key={dataIndex}>
                              <td>{titleCaseString(service_name)}</td>
                              <td>
                                {service_price &&
                                  USDollar.format(service_price)}
                              </td>
                              <td>{totalOrders}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="no-data-rw">
                          <td colSpan="11">
                            <NodataFoundBlock />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {paginatedData?.length > 0 && (
                    <PaginationBlock
                      limit={limit}
                      page={servicePage[index] || 1}
                      pagingCounter={servicePaging[index] || 1}
                      docLength={limit || 0}
                      docPage={servicePage[index]}
                      totalPages={totalPages || 1}
                      totalDocs={elem?.data?.length || 0}
                      _handleLimit={(val) => {
                        setLimit(val);
                      }}
                      _handlePageChange={(e) => {
                        if (e > servicePage[index]) {
                          const diff = e - servicePage[index];
                          setServicePaging((prev) => {
                            const updatedPaging = [...prev];
                            updatedPaging[index] =
                              updatedPaging[index] + limit * diff;
                            return updatedPaging;
                          });
                        } else if (e < servicePage[index]) {
                          const diff = servicePage[index] - e;

                          setServicePaging((prev) => {
                            const updatedPaging = [...prev];
                            updatedPaging[index] = Math.max(
                              updatedPaging[index] - limit * diff,
                              0
                            );
                            return updatedPaging;
                          });
                        }
                        setServicePage((prevPages) => {
                          const updatedPages = prevPages?.map((page, pIndex) =>
                            pIndex === index ? e : page
                          );
                          return updatedPages;
                        });
                      }}
                    />
                  )}
                </div>
              );
            })}

            <div className="table-one cmb-20">
              <div className="text-14-600 color-2E65 cmb-10">
                Supplier ({resData?.response_data?.[0]?.supplierData?.length})
              </div>

              <Table responsive>
                <thead>
                  <tr>
                    <th style={{width: '50%'}}>Supplier Name</th>
                    <th style={{width: '25%'}}>Inventory Count</th>
                    <th style={{width: '25%'}}>Orders Count</th>
                  </tr>
                </thead>
                <tbody>
                  {supplierData?.length > 0 ? (
                    supplierData?.map((dataElem, dataIndex) => {
                      const { orderCount, supplier } = dataElem;
                      return (
                        <tr key={dataIndex}>
                          <td>{titleCaseString(supplier?.supplier_name)}</td>
                          <td>{supplier?.num_items || '--'}</td>
                          <td>{orderCount}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="no-data-rw">
                      <td colSpan="11">
                        <NodataFoundBlock />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {supplierData?.length > 0 && (
                <PaginationBlock
                  limit={limit}
                  page={supplierPage}
                  pagingCounter={supplierPaging || 1}
                  docLength={limit || 0}
                  docPage={supplierPage || 1}
                  totalPages={supplierTotalpages || 1}
                  totalDocs={
                    resData?.response_data?.[0]?.supplierData?.length || 0
                  }
                  _handleLimit={(val) => {
                    setLimit(val);
                  }}
                  _handlePageChange={(e) => {
                    if (e > supplierPage) {
                      const diff = e - supplierPage;
                      setSupplierPaging((prev) => prev + limit * diff);
                    } else if (e < supplierPage) {
                      const diff = supplierPage - e;
                      setSupplierPaging((prev) =>
                        Math.max(prev - limit * diff, 0)
                      );
                    }
                    setSupplierPage(e);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryManagement;
