import React, { useEffect, useState } from "react";
import moment from "moment";
import { Collapse } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import NodataFoundBlock from "../../../../shared/NoDataFoundBlock";
import "./Vitals.scss";
import FilterComponent from "./FilterComponent";
import { getVitals } from "context/actions/patient-module/flowsheets/vitals";

const VitalsKeys = [
  { key: "temperature", name: "Temperature,C (F)" },
  { key: "temperature_method", name: "Temperature Method" },
  { key: "heart_rate", name: "Heart Rate (BPM)" },
  { key: "heart_rate_source", name: "Heart Rate Source" },
  { key: "heart_rate_method", name: "Heart Rate Method" },
  { key: "cardiac_rhythm", name: "Cardiac Rhythm" },
  { key: "respiratory_rate", name: "Respiratory Rate (Per Min)" },
  { key: "blood_pressure", name: "Blood Pressure" },
  {
    key: "blood_pressure_location",
    name: "Blood Pressure Location",
  },
  { key: "blood_pressure_method", name: "Blood Pressure Method" },
  { key: "cuff_size", name: "Cuff Size" },
  { key: "oxygen_saturation", name: "Oxygen Saturation" },
  { key: "spo2_location", name: "SpO2 Location" },
  { key: "oxygen_flow_rate", name: "Oxygen Flow Rate" },
  // { key: "oxygen_saturation", name: "Oxygen Saturation" },
  { key: "oxygen_delivery", name: "Oxygen Delivery" },
];

const painAssessmentkeys = [
  { key: "pain_score", name: "Pain Score" },
  { key: "pain_goal", name: "Pain Goal" },
  { key: "pain_location", name: "Pain Location" },
  { key: "pain_reaction", name: "Pain Reaction" },
  { key: "pain_type", name: "Pain Type" },
  { key: "response_to_palpation", name: "Response to Palpation" },
  { key: "body_tension", name: "Body Tension" },
];

const measurementsKeys = [{ key: "weight", name: "Weight" }];

const Vitals = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.user_details);
  const { petDetails } = useSelector((state) => state.petOwnerDashboardReducer);
  const [pageData, setPageData] = useState([]);
  const [isVitals, setIsVitals] = useState(true);
  const [isPain, setIsPain] = useState(false);
  const [isMeasurement, setIsMeasurement] = useState(false);
  const [editID, setEditID] = useState(false);
  const [recentDate, setRecentDate] = useState("");
  const [dateList, setDateList] = useState([]);
  const [filterData, setFilterData] = useState({
    searchText: "",
    selectedDates: [],
    date: "",
  });

  const handleResponse = (id, title, bodyKeysList, resData) => {
    let finalKey = {};
    let headerArray = [];
    let bodyKeys = bodyKeysList;
    resData?.forEach((cElem) => {
      let isAnyVal = [
        ...VitalsKeys,
        ...painAssessmentkeys,
        ...measurementsKeys,
      ].some((o) => cElem[o?.key]);
      headerArray.push({
        id: cElem?._id,
        date: cElem?.date,
        isValueExist: isAnyVal,
      });
    });
    bodyKeys = bodyKeys?.map((o) => {
      const data = resData?.map((e) => {
        return {
          ...e,
          value: e?.[o?.key] || "",
        };
      });
      return { ...o, data: data };
    });
    finalKey.id = id;
    finalKey.title = title;
    finalKey.dates = headerArray;
    finalKey.data = bodyKeys;
    return finalKey;
  };
  const fetchPageData = async (fData) => {
    const res = await dispatch(
      getVitals({
        pet_id: petDetails?._id,
        filterDates: fData?.selectedDates,
      })
    );
    let lastDate = res?.response_data?.recentDate
      ? moment(
          res?.response_data?.recentDate,
          "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
        ).format("DD-MM-YYYY")
      : "";
    let resDates = res?.response_data?.reportDate || [];
    let resData = res?.response_data?.reportList || [];

    let finalData = [];
    if (resData?.length > 0) {
      let vitalData = handleResponse(1, "Vitals", VitalsKeys, resData);
      let painData = handleResponse(
        2,
        "Pain Assessment",
        painAssessmentkeys,
        resData
      );
      let mesureData = handleResponse(
        3,
        "Measurements",
        measurementsKeys,
        resData
      );
      finalData = [vitalData, painData, mesureData];

      if (finalData.length > 0) {
        finalData = finalData.sort(function (a, b) {
          return a.id - b.id;
        });
      }
    }

    setPageData(finalData);
    setRecentDate(lastDate);
    setDateList([...new Set(resDates)]);
  };

  useEffect(() => {
    if (filterData?.date) {
      fetchPageData(filterData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    let currentDate = moment().format("DD-MM-YYYY");
    let newData = {
      searchText: "",
      selectedDates: [currentDate],
      date: currentDate,
    };
    setFilterData(newData);
    fetchPageData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displayData = pageData.map((elm) => {
    return {
      ...elm,
      data: elm.data?.filter((o) =>
        o.name?.toLowerCase().includes(filterData?.searchText?.toLowerCase())
      ),
    };
  });

  return (
    <div id="vitals-container">
      {filterData?.date && (
        <FilterComponent
          recentDate={recentDate}
          dateList={dateList}
          filterData={filterData}
          setFilterData={setFilterData}
        />
      )}

      {!displayData?.some((o) => o.data.length > 0) ? (
        <NodataFoundBlock type={filterData?.searchText !== "" || filterData?.date !== "" || filterData?.selectedDates?.length > 0 ? 'search result' : 'medical history'} />
      ) : (
        <div className="mt-3 vitals-body-block">
          {displayData?.map((elm, index) => {
            const { title, dates, data } = elm;
            const isAccord =
              title === "Vitals"
                ? isVitals
                : title === "Pain Assessment"
                ? isPain
                : isMeasurement;
            return (
              <div
                key={index}
                className={`mb-1 ${data.length === 0 ? "d-none" : ""}`}
              >
                <div
                  className="vitals-item-title-block"
                  onClick={() => {
                    if (title === "Vitals") {
                      setIsVitals(!isVitals);
                    } else if (title === "Pain Assessment") {
                      setIsPain(!isPain);
                    } else {
                      setIsMeasurement(!isMeasurement);
                    }
                  }}
                >
                  <div>{isAccord ? <BsChevronUp /> : <BsChevronDown />}</div>
                  <div>{title}</div>
                </div>
                <Collapse isOpen={isAccord}>
                  <div>
                    <div className="header-date-row">
                      <div className="left-title-block" />
                      {dates.map((dateElem, dateIndex) => {
                        const { date, isValueExist, id } = dateElem;
                        const cellDate = moment(
                          date,
                          "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                        ).format("MM/DD/YYYY");
                        const cellTime = moment(
                          date,
                          "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                        ).format("hh:mmA");
                        const isField = `date${index}_${id}` === editID;
                        return (
                          <div key={dateIndex} className="date-title-block">
                            <div className="date-block">{cellDate}</div>
                            <div className="time-block cursor-pointer">
                              {cellTime}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="body-row-container">
                      {/* .filter((user) =>
                        user.firstName
                          .toLowerCase()
                          .includes(searchText.toLowerCase())
                      ) */}
                      {data.map((dataElem, dataIndex) => {
                        return (
                          <div className="body-row" key={dataIndex}>
                            <div className="left-title-block">
                              {dataElem?.name}
                            </div>
                            {dataElem?.data?.map((valElem, valIndex) => {
                              const { value, _id } = valElem;

                              return (
                                <div key={valIndex} className="body-cell">
                                  {value ? <div>{value}</div> : <div>--</div>}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Collapse>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Vitals;
