import moment from "moment";
import { Button } from "reactstrap";
import ReactDiffViewer from 'react-diff-viewer-continued';

const ScheduleEdit = ({
  isActive,
  toggleNotePanel,
  extend,
  closingFn,
  scheduleEditDetails,
}) => {

  const newStyles = {
    variables: {
      dark: {
        highlightBackground: '#fefed5',
        highlightGutterBackground: '#ffcd3c',
      },
    },
    line: {
      padding: '5px 2px',
      '&:hover': {
        background: '#a26ea1',
      },
    },
    contentText: {
      fontFamily: 'Inter',
      fontSize: '14px'
    },
    marker:{
      display: 'none'
    },
    wordRemoved : {
      color: '#de2b2b'
    },
    wordAdded: {
      color:'#11ca9f'
    },
  };


  return (
    <>
      <div
        className={`notes-panel-wrapper fixed flex flex-col  ${
          isActive && "active"
        }`}
      >
        <div className="head d-flex align-items-center">
          <h2>
            {scheduleEditDetails?.modify_by_user?.firstname}{" "}
            {scheduleEditDetails?.modify_by_user?.lastname} on{" "}
            {moment(scheduleEditDetails?.date).format("MM/DD/YYYY")} at{" "}
            {moment(scheduleEditDetails?.date).format("h:mm a")}
          </h2>
          <Button
            color="link"
            className="ms-auto panel-close-btn"
            onClick={() => {
              toggleNotePanel();
              closingFn();
            }}
          >
            <span className="material-symbols-outlined">close</span>
          </Button>
        </div>
        {scheduleEditDetails?.section?.length > 0 &&
          scheduleEditDetails?.section?.map((elm) => (
            <>
              <div className="note-detail-wrapper">
                <div className="note-content-block content-box cursor-pointer">
                  <div className="d-flex justify-content-between">
                    <h2 className="capitalize fw-bold mb-2">{elm?.title}</h2>
                    <span className="text-[12px]">
                      {moment(scheduleEditDetails?.date).format(
                        "YYYY-MM-DD h:mm a"
                      )}
                    </span>
                  </div>
                  <div className="inner">
                    <div className="items-info">
                      {elm?.fields.map((item, i) => (
                        <div key={i} className="item-rw d-flex">
                          <div className="title">{item?.field}</div>
                          <div className="desc"></div>
                          {item?.old_value ? <ReactDiffViewer oldValue={item?.old_value} newValue={item?.value} splitView={true} hideLineNumbers={true} styles={newStyles} /> : item?.value}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
      </div>
    </>
  );
};

export default ScheduleEdit;
