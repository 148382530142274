import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "reactstrap";
import moment from "moment";
import "moment-duration-format";
import PetPlaceholder from "../../../../PetPlaceholder"; // eslint-disable-line
import "./index.scss";
import { setAppointmentDetailPanel } from "../../../../../context/actions/appointmentDetailPanelAction";
import { MdPets } from "react-icons/md";
import { appointmentDetailPanelReducer } from "../../../../../context/reducers/appointmentDetailReducer";
import { AddNewDischargePatientForm } from "../../../../../context/actions/patient-module/discharge-patient-form";
import { defaultDischargePatientFormData } from "../../../../../context/reducers/dischargePatientReducer";
import {
  addNewVitalToggles,
  fetchVitalsTogglesForDischargPatientNavigator,
} from "../../../../../context/actions/vital-toggle";
import { BsChevronRight } from "react-icons/bs";

const PatientHead = ({showHealthRightCanvas}) => {
  const { petDetails } = useSelector((state) => state.petReducer);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [now, setNow] = useState(moment());
  const { data: appointmentDetails } = useSelector(
    (state) => state.appointmentDetailPanelReducer
  );

  moment.updateLocale('en', {
    relativeTime: {
          future: "in %s",
          past:   "%s ago",
          s:  "a few seconds",
          ss:  "%d seconds",
          m:  "1 minute",
          mm: "%d minutes",
          h:  "1 hour",
          hh: "%d hours",
          d:  "1 day",
          dd: "%d days",
          M:  "1 month",
          MM: "%d months",
          y:  "1 year",
          yy: "%d years"
    }
  })

  const userDetails = useSelector((state) => state.user.user_details);

  const { user } = useSelector((state) => state.user);

  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  const handleRedirection = () => {
    if (location.pathname.includes("add-new-appointment")) {
      history.push("/appointments");
    }
  };

  const handleViewProfile = () => {
    dispatch(setAppointmentDetailPanel(false))
    history.push(`/patient-detail/${petDetails?._id}`);
  };
  let totalAmount = 0;
  petDetails?.invoiceDetails?.forEach((elem) => {
    totalAmount += elem?.totalAmount || 0;
  });

  useEffect(() => {
    setInterval(() => {
      setNow(moment());
    }, 1000);
  }, []);

  const createDischargePatientForm = () => {
    // console.log("defaultDischargePatientFormData", defaultDischargePatientFormData)

    if (
      defaultDischargePatientFormData &&
      clinic_details &&
      appointmentDetails
    ) {
      dispatch(
        AddNewDischargePatientForm(
          {
            ...defaultDischargePatientFormData,
            order_for_discharge:
              defaultDischargePatientFormData.order_for_discharge.map((ele) => {
                delete ele._id;
                return ele;
              }),
            clinic_id: clinic_details._id,
            appointment_id: appointmentDetails._id,
            pet_id: petDetails._id,
            added_by: user._id,
          },
          (response) => {
            // console.log("response-----", response);
            if (response?.status) {
              dispatch(
                addNewVitalToggles(
                  {
                    discharge_patient_form_id: response.response_data._id,
                    created_by: userDetails._id,
                  },
                  (res) => {
                    // console.log("res----", res);
                    if (res.status) {
                      delete res.response_data.createdAt;
                      delete res.response_data.updatedAt;
                      dispatch({
                        type: "UPDATE_VITALS_TOGGLES",
                        payload: { ...res.response_data },
                      });
                      dispatch(setAppointmentDetailPanel(false))


                      history.push("/dischargePatient", {
                        pet_id: petDetails._id,
                        appointmentDetails: appointmentDetails,
                      });
                    }
                  }
                )
              );
            } else {
            }
          }
        )
      );
    }
  };

  return (
    <>
      <div className="profile-head">
        <div className="user-profile-cell d-inline-flex align-items-center">
          <div className="pic position-relative">
            <img
              className="img-fluid w-100 h-100"
              src={
                petDetails?.pet_image ||
                require("../../../../../assets/img/vetRecorEMR/pet-thumbnail.png")
              }
              alt="Pet"
            />
            {/* <PetPlaceholder /> */}
            {/* <span className="badge-icon position-absolute">
							<img className="img-fluid" src={require('../../../assets/img/vetRecorEMR/pet-badge-icon-yellow.svg')} alt="Badge" />
						</span> */}
            {/*petDetails?.wellness_plan_id &&
                            <span className="badge-icon position-absolute">
                                 <img className="img-fluid" src={require('../../../assets/img/vetRecorEMR/pet-badge-icon-yellow.svg')} alt="Badge" />
                                <div className="bg-[#DD9323] inline w-[20px] p-[2px] absolute rounded-[5px] top-[7px] left-[-26px]">
                                    <MdPets color="#fff" size={15} />
                                </div>
                            </span>*/}
          </div>
          <div className="txt">
            <h2>
              {petDetails?.pet_name}
            </h2>
            <h3>{petDetails?.vrn}</h3>
            <h4 className="active">
              {petDetails?.active ? "Active" : "Inactive"}
            </h4>
          </div>
        </div>
        {permissions?.view_patients_details && <div className="profile-btns d-flex mb-2 pb-1">
          {/*<Button color="link" className="flex-fill">Share Profile</Button>*/}
          <Button
            color="link"
            onClick={handleViewProfile}
            className="flex-fill"
          >
            View Profile
          </Button>
        </div>}
        <div className="button-container">
          <button
            type="button"
            class="health-button-right-sidebar text-12-600"
            onClick={showHealthRightCanvas}
          >
            HEALTH SNAPSHOT
          </button>
        </div>

        {appointmentDetails?.appointment_start &&
        !appointmentDetails?.appointment_end ? (
          <div className="discharge-card mt-2 p-3 text-center">
            <h4 className="pb-2">
              Time in Clinic :{" "}
              <span minutes>
                {moment
                  .duration(
                    now.diff(moment(appointmentDetails?.appointment_start))
                  )
                  .humanize()}
              </span>
            </h4>
            {permissions?.edit_appointments && (
              <button
                type="button"
                class="btn btn-primary w-100"
                onClick={() => {
                  createDischargePatientForm();
                }}
              >
                Discharge
              </button>
            )}
          </div>
        ) : (
          <></>
        )}
        {totalAmount > 0 && (
          <div className="mt-2 p-2 amount-card">
            <div className="fb-center">
              <div>
                <span className="amount-value">${totalAmount.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}</span>
                <p className="amount-text pt-1">Outstanding Invoices</p>
              </div>
              <BsChevronRight color="#ffffff" size={16} />
            </div>
          </div>
        )}
        {/*
                <div className="outstanding_invoice position-relative mt-2">
                    <h4>$52,800</h4>
                    <h3 className="mb-0">Outstanding Invoices</h3>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <mask id="mask0_6529_110610" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
                            <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_6529_110610)">
                            <path d="M8.87344 17.6234C8.72344 17.4734 8.64844 17.2984 8.64844 17.0984C8.64844 16.8984 8.72344 16.7234 8.87344 16.5734L12.9484 12.4984L8.87344 8.42344C8.72344 8.27344 8.64844 8.09844 8.64844 7.89844C8.64844 7.69844 8.72344 7.52344 8.87344 7.37344C9.02344 7.22344 9.19844 7.14844 9.39844 7.14844C9.59844 7.14844 9.77344 7.22344 9.92344 7.37344L14.4234 11.8734C14.5068 11.9568 14.5694 12.0524 14.6114 12.1604C14.6528 12.2691 14.6734 12.3818 14.6734 12.4984C14.6734 12.6151 14.6528 12.7274 14.6114 12.8354C14.5694 12.9441 14.5068 13.0401 14.4234 13.1234L9.92344 17.6234C9.77344 17.7734 9.59844 17.8484 9.39844 17.8484C9.19844 17.8484 9.02344 17.7734 8.87344 17.6234Z" fill="white" />
                        </g>
                    </svg>
                        </div>*/}
      </div>
    </>
  );
};

export default PatientHead;
