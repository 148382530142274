import React from "react";

import { Button } from "reactstrap";
import "./index.scss";
import { useHistory } from "react-router-dom";
import clinicImg from "../../../assets/img/superAdmin/images/clinicLogo.png";
import { useSelector } from "react-redux";

const ClinicHead = () => {
  const history = useHistory();
  const {clinic_details} = useSelector((state) => state.clinicReducer)
  const handleRedirection = () => {
    history.goBack();
  };

  return (
    <div className="profile-head">
      <div className="title d-flex justify-content-between">
        <Button
          color="link"
          className="back-btn ps-0"
          onClick={handleRedirection}
        >
          Go Back
        </Button>
        <div className="btn-blc d-inline-flex align-items-center">
          <Button color="link pe-0" className="view-user-btn">
            <svg
              width="18"
              height="20"
              viewBox="0 0 18 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.37492 12.7083V13.7917C7.37492 14.243 7.5329 14.6267 7.84888 14.9427C8.16485 15.2587 8.54853 15.4167 8.99992 15.4167C9.45131 15.4167 9.83499 15.2587 10.151 14.9427C10.4669 14.6267 10.6249 14.243 10.6249 13.7917V12.7083H11.7083C12.1596 12.7083 12.5433 12.5503 12.8593 12.2344C13.1753 11.9184 13.3333 11.5347 13.3333 11.0833C13.3333 10.6319 13.1753 10.2483 12.8593 9.93228C12.5433 9.61631 12.1596 9.45832 11.7083 9.45832H10.6249V8.37499C10.6249 7.9236 10.4669 7.53992 10.151 7.22395C9.83499 6.90798 9.45131 6.74999 8.99992 6.74999C8.54853 6.74999 8.16485 6.90798 7.84888 7.22395C7.5329 7.53992 7.37492 7.9236 7.37492 8.37499V9.45832H6.29158C5.8402 9.45832 5.45652 9.61631 5.14054 9.93228C4.82457 10.2483 4.66658 10.6319 4.66658 11.0833C4.66658 11.5347 4.82457 11.9184 5.14054 12.2344C5.45652 12.5503 5.8402 12.7083 6.29158 12.7083H7.37492ZM2.49992 19.75C1.90409 19.75 1.39402 19.5378 0.96971 19.1135C0.545405 18.6892 0.333252 18.1792 0.333252 17.5833V7.83332C0.333252 7.49027 0.409988 7.16527 0.56346 6.85832C0.716932 6.55138 0.929085 6.2986 1.19992 6.09999L7.69992 1.22499C8.07908 0.936101 8.51242 0.791656 8.99992 0.791656C9.48742 0.791656 9.92075 0.936101 10.2999 1.22499L16.7999 6.09999C17.0708 6.2986 17.2829 6.55138 17.4364 6.85832C17.5898 7.16527 17.6666 7.49027 17.6666 7.83332V17.5833C17.6666 18.1792 17.4544 18.6892 17.0301 19.1135C16.6058 19.5378 16.0958 19.75 15.4999 19.75H2.49992Z"
                fill="#282E65"
              />
            </svg>
          </Button>
        </div>
      </div>
      <div className="user-profile-cell d-inline-flex align-items-center">
        <div className="pic position-relative">
          <img
            className="w-100 h-100 rounded-full"
            src={clinic_details?.clinicLogo ? clinic_details.clinicLogo : clinicImg}
            alt="clinic"
          />
        </div>
        <div className="txt">
          <h2 style={{ textTransform: "capitalize" }}>
            {clinic_details?.clinic_name
              ? clinic_details.clinic_name
              : "[Clinic-Name goes here]"}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default ClinicHead;
