import * as Yup from "yup";
/**
 * @description declaration form schema for client validations
 */

export const orderSchema = Yup.object().shape({
    _id: Yup.string().required(),
    status: Yup.string().required(),
    item: Yup.string(),
    service: Yup.string(), 
    item_details: Yup.object(),
    service_details :Yup.object(),
    category_id: Yup.string(),
    category: Yup.object(),
    form: Yup.string(),
    dose: Yup.number(),//.required("Dose information is required"),
    route: Yup.string(),//.required("Route is required"),
    frequency: Yup.string(),
    rate_val: Yup.string(),
    rate_time: Yup.string(),
    duration_num: Yup.number(),
    duration_unit: Yup.string(),
    prn: Yup.boolean(),
    first_dose: Yup.date().required('First Dose is required'),

    notes: Yup.string(),
});


