import React, { useEffect, useState } from 'react';
import { Button, Input } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./index.scss";
import { useParams } from 'react-router-dom';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
//import { deletePetImage, updatePetImage } from '../../../../context/actions/pet';
import { profile_picture_size } from '../../environment';
import CropImageModal from './CropImageModal';


const UploadImageBlock = ({ petImage, petImageObj, sendPetImage, petId, className = '', petVrn }) => { 
	const params = useParams();
	const [chosedFile, setChosedFile] = useState(petImage ? petImage : '');
	const dispatch = useDispatch();
	const [imgObject, setImgObject] = useState(false); 
	const [showCropImage, setShowCropImage] = useState(false);

	const handleUploadPetImage = (imageObject) => { 
		/*if (params.petId || petId) { 
			const thePetId = params.petId ? params.petId : petId
			let formData = new FormData(); 
			console.log("imageObject ",imageObject);
			formData.append("pet_image", imageObject) 
			//dispatch(updatePetImage(thePetId, formData, ((result) => {
				//if (result) {
					setChosedFile(URL.createObjectURL(imageObject)) 
					//console.log("resultr ", result);
				//} 
			//}))) 
		} else {*/

		if(parseInt(profile_picture_size) >= parseInt(imageObject?.size)){
			petImageObj(imageObject);  
		}
		else { 
			setShowCropImage(true)
		}
			//setChosedFile(URL.createObjectURL(imageObject)) 
		//}
	}

	/**
	 * @description method call to set & send image object 
	 * @param {Object} event accepting object 
    */
	const handleOnFileChange = event => {
		let theFileObj = event.target.files[0];
		setImgObject(event.target.files[0])
		if (parseInt(profile_picture_size) >= parseInt(event.target.files[0].size)) {
			//setChosedFile(URL.createObjectURL(theFileObj))
			petImageObj(theFileObj);  
			//handleUploadPetImage(theFileObj)
		} else { 
			setShowCropImage(true)
		}
	};


	useEffect(() => {
		//setChosedFile(petImage)
	}, [petImage])

     

	/**
	 * @description method call to remove selected image 
	
	const handleRemoveImage = () => {
		if (params.petId || petId) {
			const thePetId = params.petId ? params.petId : petId
			dispatch(deletePetImage(thePetId, {}, ((result) => {
				if (result) {
					sendPetImage(false);
					setChosedFile('')
					console.log("result ", result);
				}
			})))
		} else {
			setChosedFile('')
		}
	}

     */
	return (
		<>
			<div className={`upload-img-blc d-flex align-items-center ${className}`}>
				<div className='upload-btn'>
				
						<Input type="file" className="position-absolute"
							accept="image/*"
							onClick={e => (e.target.value = null)}
							onChange={handleOnFileChange}
					 />
						<FontAwesomeIcon icon={faCamera} />

				</div>
				{
					showCropImage && <CropImageModal
						openViewBlock={(value) => setShowCropImage(value)}
						imgObject={imgObject}
						imgCroppedBlob={(value) => handleUploadPetImage(value) }
						sendCroppedImg={(value) => console.log('send cropped img', value)} />
				}

			</div>
		</>
	);

}
export default UploadImageBlock;