const initState = {
    loading: false,
    results: []
}

export const bloodProductsReducer = (state = initState, action) => {
    switch (action.type) {
        case "BLOOD_PRODUCTS_LOADING":
            return { ...state, loading: action.payload }

        case "BLOOD_PRODUCTS":
            return { ...state, results: action.payload }

        default:
            return state
    }
}
