import React, { useEffect, useState } from "react";
import { Button, FormGroup, Input, FormFeedback, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";
import moment from "moment";
import { Formik, Form } from "formik";
import { getAllTests } from "../../../../../../context/actions/patient-module/diagnostic-testing";
import { formSubscriptionItems } from "final-form";
import { addNewResult } from "../../../../../../context/actions/patient-module/diagnostic-testing";
import { getAllDoctors } from "context/actions/appointment";
import { addNewPetXrayReport } from "context/actions/patient-module/overview/report";
import UploadBlock from "shared/UploadBlock";
import AddRadiology from "./AddRadiology";


const formObjToSchema = (obj) => {
  var schema = {
    type: "object",
    properties: {},
    required: [],
  };

  var config = {
    errMessages: {},
  };

  Object.keys(obj).map((key, i) => {
    schema.properties[obj[key].val_name] = {
      type:
        obj[key].type === "input" || obj[key].type === "select"
          ? "string"
          : "date",
      //required: (obj[key]?.required || false),
    };
    if (obj[key].required) {
      schema.required = [...schema.required, obj[key].val_name];
      config.errMessages[obj[key].val_name] = {
        required: `${key} is required`,
      };
    }
  });

  const { buildYup } = require("schema-to-yup");
  const yupSchema = buildYup(schema, config);
  return yupSchema;
};

const initValsFromFormObj = (obj) => {
  var init = {};

  Object.keys(obj).map((key, i) => {
    init[obj[key].val_name] = obj[key].value;
  });
  return init;
};

const NewResultPanel = ({ className = "", closePanel, refresh, addPoint, currentDate }) => {
  const dispatch = useDispatch();

  const params = useParams();

  const { tests_loading, tests } = useSelector(
    (state) => state.diagnosticTestingReducer
  );
  const { clinic_details } = useSelector(state => state?.clinicReducer);
  const userDetails = useSelector((state) => state.user.user_details);
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  useEffect(() => {
    dispatch(
      getAllTests((res) => {
        console.log(res);
      })
    );
  }, []);

  const formInfo = {
    Test: {
      value: "",
      type: "select",
      value: "",
      val_name: "diagnostic_test_id",
      options: tests,
      required: true,
    },
    Value: {
      value: "",
      type: "input",
      value: "",
      val_name: "result",
      required: true,
    },
    Date: {
      value: "",
      type: "date",
      value: "",
      val_name: "date",
      required: true,
    },
  };

  const [formVal, setFormVal] = useState({
    diagnostic_test_id: "0",
    result: "",
    date: "",
  });

  useEffect(() => { }, []);

  const handleAddPoint = (values) => {
    dispatch(
      addNewResult(
        {
          ...values,
          date: moment(values?.date).toDate(),
          created_by: userDetails?._id,
          pet_id: params?.petId,
        },
        (response) => {
          if (response?.status) {
            closePanel();
            refresh();
          }
        }
      )
    );
  };


  // form handling of radiology reports
  
  return (
    <>
      <div
        className={`new-result-panel-wrapper position-fixed d-flex flex-column overflow-auto ${className}`}
      >
        <div className="head d-flex align-items-center">
          <h2>Add New Result</h2>
          <Button
            color="link"
            className="ms-auto panel-close-btn"
            onClick={closePanel}
          >
            <span className="material-symbols-outlined">close</span>
          </Button>
        </div>
        <Nav tabs className="flex-shrink-0">
          {/* <NavItem>
						
					</NavItem> */}
          <NavItem>

            <NavLink
              className={{ active: activeTab === "1" }}
              onClick={() => {
                toggle("1");
              }}
            >
              Diagnostic Testing
            </NavLink>
            <NavLink
              className={{ active: activeTab === "2" }}
              onClick={() => {
                toggle("2");
              }}
            >
              Radiology Report
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent
          activeTab={activeTab}
          className="d-flex flex-column flex-fill overflow-auto position-relative"
        >
          <TabPane tabId="1">
            <div className="inner overflow-auto align-items-center">
              <Formik
                initialValues={initValsFromFormObj(formInfo)}
                enableReinitialize
                validationSchema={formObjToSchema(formInfo)}
                onSubmit={(values, { resetForm }) => {
                  handleAddPoint(values);
                  resetForm();
                }}
              >
                {(formik) => (
                  <Form>
                    {Object.keys(formInfo).map((key, i) => {
                      switch (formInfo[key].type) {
                        case "input":
                          return (
                            <>
                              <div key={i} className="form-block">
                                <FormGroup className="input-wrapper">
                                  <div className="form-floating position-relative text-input">
                                    <Input
                                      invalid={
                                        formik?.errors?.[formInfo[key].val_name] &&
                                        formik?.touched?.[formInfo[key].val_name]
                                      }
                                      name={formInfo[key].val_name}
                                      type="text"
                                      className="form-control"
                                      id={`${key}`}
                                      placeholder={`Enter ${key}`}
                                      value={
                                        formik?.values?.[formInfo[key]?.val_name]
                                      }
                                      onChange={formik.handleChange}
                                    />
                                    <label for={`${key}`}>{`${key}`}</label>
                                    {formik?.errors?.[formInfo[key].val_name] &&
                                      formik?.touched?.[formInfo[key].val_name] ? (
                                      <FormFeedback>
                                        {formik?.errors?.[formInfo[key].val_name]}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </FormGroup>
                              </div>
                            </>
                          );
                        case "select":
                          return (
                            <>
                              <div key={i} className="form-block">
                                <FormGroup className="input-wrapper">
                                  <div className="form-floating position-relative text-input">
                                    <Input
                                      name={formInfo[key].val_name}
                                      type="select"
                                      className="form-control"
                                      id={`${key}`}
                                      placeholder={`Enter ${key}`}
                                      value={
                                        formik?.values?.[formInfo[key]?.val_name]
                                      }
                                      onChange={formik.handleChange}
                                    >
                                      <option value="">Select</option>
                                      {formInfo[key].options.map((option, j) => {
                                        return (
                                          <option
                                            value={option?._id}
                                            key={j}
                                            selected={j === 0}
                                          >
                                            {option?.test_name}
                                          </option>
                                        );
                                      })}
                                    </Input>
                                    <label for={`${key}`}>{`${key}`}</label>
                                    {formik?.errors?.[formInfo[key].val_name] &&
                                      formik?.touched?.[formInfo[key].val_name] ? (
                                      <FormFeedback>
                                        {formik?.errors?.[formInfo[key].val_name]}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </FormGroup>
                              </div>
                            </>
                          );
                        case "date":
                          return (
                            <>
                              <div key={i} className="form-block">
                                <FormGroup className="input-wrapper">
                                  <div className="form-floating position-relative text-input">
                                    <Input
                                      invalid={
                                        formik?.errors?.[formInfo[key].val_name] &&
                                        formik?.touched?.[formInfo[key].val_name]
                                      }
                                      name={formInfo[key].val_name}
                                      type="datetime-local"
                                      className="form-control"
                                      id={`${key}`}
                                      placeholder={`Enter ${key}`}
                                      value={
                                        formik?.values?.[formInfo[key]?.val_name]
                                      }
                                      onChange={formik.handleChange}
                                    />
                                    <label for={`${key}`}>{`${key}`}</label>
                                    {formik?.errors?.[formInfo[key].val_name] &&
                                      formik?.touched?.[formInfo[key].val_name] ? (
                                      <FormFeedback invalid>
                                        {formik?.errors?.[formInfo[key].val_name]}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </FormGroup>
                              </div>
                            </>
                          );
                      }
                    })}

                    <div className="btn-blc">
                      <Button color="success" type="submit" className="add-btn">
                        Add new
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </TabPane>
          <TabPane tabId="2">
            <AddRadiology petId={params?.petId} currentDate={currentDate} handleClose={closePanel} />
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default NewResultPanel;
