import { useEffect, useState } from "react";
import "./EstimateTab.scss";
import { useDispatch, useSelector } from "react-redux";
// import NodataFoundBlock from "../../shared/NoDataFoundBlock";
import TotalRevenueEstimate from "./TotalRevenueEstimate";
import { AiOutlineSearch } from "react-icons/ai";
import { Input } from "reactstrap";
import SwitchCell from "shared/SwitchCell";
import { VscListUnordered } from "react-icons/vsc";
import CheckBox from "shared/CheckBox";
import { getEstimateByPetId } from "context/actions/allergies";
import moment from "moment";
import NodataFoundBlock from "shared/NoDataFoundBlock";
import PaginationBlock from "shared/PaginationBlock";

const BackDrop = (props) => {
  return <div className="backdrop" onClick={props.closeDrawer}></div>;
};

const EstimateTab = () => {
  const dispatch = useDispatch();
  const [estimateData, setEstimateData] = useState([]);
  const { petDetails } = useSelector((state) => state.petOwnerDashboardReducer);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pageCount, setPageCount] = useState([]);
  const [drawerOpenEstimate, setDrawerOpenEstimate] = useState(false);
  const [selectedEstimate, setSelectedEstimate] = useState(null);

  function handleBackdropClick() {
    setDrawerOpenEstimate(false);
    setSelectedEstimate(null);
  }

  function handleOpenDrawerEstimateButton(data) {
    setDrawerOpenEstimate(!drawerOpenEstimate);
    setSelectedEstimate(drawerOpenEstimate ? null : data ? data : null);
  }

  let petId = petDetails?._id;

  const getEstimateBill = async () => {
    const res = await dispatch(getEstimateByPetId({ petId }));
    setEstimateData(res?.response_data?.docs);
  };

  useEffect(() => {
    getEstimateBill();
  }, [page, limit]);

  return (
    <div className="estimate_box">
      <div className="flex flex-col md:flex-row gap-2 px-2 sm:px-4 md:p-3 justify-between items-start">
        <div className="flex gap-2 lg:items-center flex-col lg:flex-row w-full">
          <div className="relative w-full lg:w-52">
            <input
              type="text"
              id="first_name"
              className="bg-[#fff] w-full pl-11 border border-gray-300 text-gray-900 text-sm rounded-lg h-11 focus:outline-none"
              placeholder="Search"
              required
            />
            <span className="absolute top-[9px] left-[4px] pl-[5px]">
              <AiOutlineSearch size={25} color="#788693" />
            </span>
          </div>
          <div className="flex gap-2 flex-col md:flex-row">
            <div>
              <Input
                type="select"
                name="select"
                className="h-[42px] w-full md:w-52"
              >
                <option>Last 7 Days</option>
                <option>Last 2 days</option>
              </Input>
            </div>
            <div>
              <Input
                type="select"
                name="select"
                className="h-[42px] w-full md:w-52"
              >
                <option>All Status</option>
                <option>Partially paid</option>
                <option>fully paid</option>
              </Input>
            </div>
            <div>
              <Input
                type="select"
                name="select"
                className="h-[42px] w-full md:w-52"
              >
                <option>All Mode</option>
                <option>Cash</option>
                <option>Paypal</option>
                <option>Credit Card</option>
              </Input>
            </div>
            <div className="border-0 md:border-[2px]  md:rounded-lg md:px-2 py-2">
              <SwitchCell labelVisible={true} label={"Show Status"} />
            </div>
          </div>
        </div>
        <div className="self-end md:self-start">
          <span className="border text-[14px] border-gray rounded-lg  font-['Inter'] flex items-center gap-1 px-[5px] py-2.5 mr-2">
            <VscListUnordered size={20} />
            Columns{" "}
          </span>
        </div>
      </div>
      <div>
        {drawerOpenEstimate ? (
          <TotalRevenueEstimate
            show={drawerOpenEstimate}
            cancel={drawerOpenEstimate}
            setCancel={setDrawerOpenEstimate}
            estimateData={selectedEstimate}
          />
        ) : (
          ""
        )}

        {drawerOpenEstimate && <BackDrop closeDrawer={handleBackdropClick} />}
      </div>

      <div className="min-h-96">
        {estimateData?.length > 0 ? (
          <>
            <div className="relative p-3 sm:rounded-lg overflow-x-auto">
              <table striped responsive className="checkbox-input w-100">
                <thead className=" text-gray-500   dark:text-gray-400">
                  <tr
                    style={{
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontFamily: "Inter",
                    }}
                    className="text-[#788C9F] border-b "
                  >
                    <th
                      scope="col "
                      className="px-2 py-2 flex items-center"
                      style={{ minWidth: "130px" }}
                    >
                      <CheckBox
                        type="checkbox"
                        ischecked={false}
                        className="no-label"
                      />
                      <label
                        style={{ fontWeight: "500" }}
                        for="default-checkbox"
                        className="ml-2  text-[#788C9F]"
                      >
                        Estimate ID
                      </label>
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-2"
                      style={{ fontWeight: "500", minWidth: "90px" }}
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-2"
                      style={{ fontWeight: "500", minWidth: "130px" }}
                    >
                      Processed on
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-2"
                      style={{ fontWeight: "500", minWidth: "100px" }}
                    >
                      Payer
                    </th>
                  </tr>
                </thead>
                <tbody
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  {estimateData?.map((data) => {
                    return (
                      <tr className=" ">
                        <td className="flex items-center ml-2 py-2">
                          <CheckBox
                            type="checkbox"
                            ischecked={false}
                            className="no-label"
                          />
                          <label
                            for="default-checkbox"
                            className="ml-2 text-sm font-medium text-[#3D3EFD] cursor-pointer"
                            onClick={() => handleOpenDrawerEstimateButton(data)}
                          >
                            ES{data?._id.substring(5, 12)}
                          </label>
                        </td>
                        <td className="px-2 py-2 text-[#282E65]">
                          $
                          {data.totalAmount?.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                          })}
                        </td>
                        <td className="px-2 py-2 text-[#282E65]">
                          {moment(data?.createdAt)?.format(
                            "MM/DD/YYYY, [at] hh:mma"
                          )}
                        </td>
                        <td className="px-2 py-2 flex items-center gap-1 text-[#282E65]">
                          <img
                            className="w-[20px] h-[20px] rounded-full"
                            src={data?.payer_detail[0]?.user_image}
                            alt="user"
                          />
                          <span className="text-[14px]">
                            {data?.payer_detail[0]?.firstname +
                              " " +
                              data?.payer_detail[0]?.lastname}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="billpagination">
              <PaginationBlock
                limit={limit}
                page={page}
                pagingCounter={pageCount?.pagingCounter || 1}
                docLength={1}
                docPage={1}
                totalPages={pageCount?.totalPages || 1}
                totalDocs={pageCount?.totalDocs || 1}
                _handleLimit={(val) => {
                  setLimit(val);
                }}
                _handlePageChange={setPage}
              />
            </div>
          </>
        ) : (
          <NodataFoundBlock type={ 'search result' }/>
          //   <h1>hii</h1>
        )}
      </div>
    </div>
  );
};

export default EstimateTab;
