import React, { useEffect, useRef, useState } from "react";
import { Button, Row, FormGroup } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { noteInit, noteSchema } from "./schema";
import { Form, Formik, FieldArray, Field, ErrorMessage } from "formik";
import {
  getNotesByPetId,
  getNotesForPetForAppointment,
  updateNote,
} from "../../../../../../context/actions/patient-module/notes";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { debounce } from "lodash";
// import TemplateSelection from "./TemplateSelection";

const OverViewEdit = ({
  isActive,
  toggleNotePanel,
  extend,
  closingFn,
  section,
  note,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const petId = params.petId;
  const [sectionValues, setSectionValues] = useState(note);
  const { notes } = useSelector((state) => state?.notesReducer);

  const originalSectionValues = useRef(null);

  const debouncedUpdateField = debounce((sectionIndex, fieldIndex, value) => {
    const isDraft = sectionValues?.drafStatus === 1;
    const data = isDraft ? sectionValues?.draft : sectionValues?.sections;
    try {
      const updatedFields = data?.map((elem, index) => {
        if (index === sectionIndex) {
          return {
            ...elem,
            fields: elem?.fields?.map((fData, i) => {
              if (i === fieldIndex) {
                return {
                  ...fData,
                  value: value,
                };
              }
              return fData;
            }),
          };
        }
        return elem;
      });

      const newUpdatedFields = updatedFields?.map((pElem) => {
        return {
          title: pElem?.title,
          fields: pElem?.fields?.map((cElem) => {
            return {
              field: cElem?.field,
              value: cElem?.value,
            };
          }),
        };
      });

      const oldValue = sectionValues?.sections?.map((oElem) => {
        return {
          title: oElem?.title,
          fields: oElem?.fields?.map((cElem) => {
            return {
              field: cElem?.field,
              value: cElem?.value,
            };
          }),
        };
      });

      const payload = {
        _id: note?._id,
        appointment: note?.appointment?._id,
        modify_by: note?.created_by?._id,
        date: new Date(),
        drafStatus: 1,
        sections: oldValue,
        draft: newUpdatedFields,
      };
      console.log("payload", payload);

      dispatch(
        updateNote(payload, (res) => {
          if (res?.status) {
            dispatch(getNotesByPetId(petId, () => {}));
          }
        })
      );
    } catch (error) {
      console.error("Error updating field:", error);
    }
  }, 500);

  const submitForm = async (values, { errors }) => {
    values = values?.map((pElem, index) => {
      const originalFields = originalSectionValues.current[index]?.fields || [];

      return {
        title: pElem?.title,
        fields: pElem?.fields?.map((cElem, i) => {
          const originalValue = originalFields[i]?.value || " ";

          return {
            field: cElem?.field,
            value: cElem?.value,
            old_value: cElem?.value === originalValue ? "" : originalValue,
          };
        }),
      };
    });

    const payLoad = {
      _id: note?._id,
      appointment: note?.appointment?._id,
      modify_by: note?.created_by?._id,
      date: new Date(),
      sections: values,
      drafStatus: 0,
    };
    dispatch(
      updateNote(payLoad, (response) => {
        if (response?.status) {
          dispatch(getNotesByPetId(petId, () => {}));
          toggleNotePanel();
          closingFn();
        }
      })
    );
  };

  useEffect(() => {
    setSectionValues(note);
    originalSectionValues.current = note?.sections;
  }, [note]);

  return (
    <>
      <div
        className={`notes-panel-wrapper fixed flex flex-col  ${
          isActive && "active"
        }`}
      >
        <div className="head d-flex align-items-center">
          <h2>Edit Note</h2>
          <Button
            color="link"
            className="ms-auto panel-close-btn"
            onClick={() => {
              toggleNotePanel();
              closingFn();
            }}
          >
            <span className="material-symbols-outlined">close</span>
          </Button>
        </div>

        <div className=" w-full flex items-center justify-between p-[10px] rounded-md bg-[#F8F8F8]">
          {/* {!vals && (
          <span className="text-sm mb-0">Please select a schedule</span>
        )} */}
          {note && (
            <span className="text-sm mb-0">
              Schedule :{" "}
              {moment(note?.appointment?.appointment_date, "DD-MM-YYYY").format(
                "MMM D, YYYY"
              )}{" "}
              {/* | {note.note?.createdAt} */}
            </span>
          )}
        </div>

        <Formik
          initialValues={
            sectionValues?.drafStatus === 1
              ? sectionValues?.draft
              : sectionValues?.sections
          }
          enableReinitialize
          validationSchema={noteSchema}
          onSubmit={submitForm}
        >
          {(props) => {
            const { values, handleChange } = props;
            return (
              <Form className="max-h-[90%] overflow-y-auto overflow-x-hidden mt-3">
                <FieldArray name="sections">
                  <div
                    //   style={{
                    //     display: isSelectingTemplate ? "none" : "flex",
                    //   }}
                    className={`note-detail-wrapper flex-column `}
                  >
                    {values?.map((elem, index) => {
                      return (
                        <FormGroup
                          className="note-content-block content-box d-flex flex-column"
                          // key={index}
                        >
                          <div className="flex justify-between mb-2">
                            <h2 className="capitalize font-bold text-md">
                              {elem?.title}
                            </h2>
                          </div>
                          {elem?.fields?.map((multielm, i) => {
                            return (
                              <Row className="flex mb-2" key={i}>
                                <div className="row-block flex items-center w-full">
                                  <label
                                    for={`values[${index}][fields][${i}][value]`}
                                    className=" w-36 mr-4 text-sm"
                                  >
                                    {multielm?.field}
                                  </label>
                                  <div className="right-col flex-fill">
                                    <textarea
                                      onChange={(e) => {
                                        handleChange(e);
                                        debouncedUpdateField(
                                          index,
                                          i,
                                          e?.target?.value
                                        );
                                      }}
                                      className="resize-y rounded-md outline outline-1 outline-[#0909541F] p-2"
                                      rows="2"
                                      cols="40"
                                      value={multielm?.value}
                                      id={`[${index}][fields][${i}][value]`}
                                      // id={`value`}
                                      tag={Field}
                                    />
                                  </div>
                                </div>
                              </Row>
                            );
                          })}
                          <>
                            {/* <div className="w-full flex justify-end">
                              <span className="text-sm text-red-400">
                                {
                                  formik.errors?.sections?.[index]?.fields?.[j]
                                    ?.value
                                }
                              </span>
                            </div> */}
                          </>
                        </FormGroup>
                      );
                    })}
                  </div>
                </FieldArray>
                <div className="flex w-full justify-end p-4 gap-4">
                  <Button
                    onClick={() => {
                      toggleNotePanel();
                      closingFn();
                    }}
                    outline
                    color="base-color"
                    className="w-[150px] "
                  >
                    Cancel
                  </Button>
                  <Button type="submit" color="success" className="w-[150px] ">
                    Save
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default OverViewEdit;
