import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Input } from "reactstrap";
import './index.scss';
import DateRange from "shared/FormsInput/DateRange";
import moment from "moment";
import { titleCaseString } from "utils/common";

const FilterBlock = ({ filterPayload, setFilterPayload, clinicData, doctorData }) => {
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)


	const handleSearch = (e) => {
		let value = e.target.value;
		setFilterPayload((p) => (p = { ...p, searchText: value }));
	};

	const onChangeDate = (startDate, endDate) => {
		setFilterPayload((prev) => ({ ...prev, start_date: startDate, end_date: `${endDate}T23:59:00.000Z` }))
	};

	const onSelectType = (e) => {
		let value = e.target.value;
		setFilterPayload(
			(p) => (p = { ...p, type: value })
		);
	};

	const onSelectClinic = (e) => {
		let value = e.target.value;
		setFilterPayload(
			(p) => (p = { ...p, clinic_id: value })
		);
	}

	const onSelectDoctor = (e) => {
		let value = e.target.value;
		setFilterPayload(
			(p) => (p = { ...p, doctor_id: value })
		);
	}


	return (
		<>
			<div className="diagnostic-filter-block d-flex align-items-center">
				<div className="left-col d-flex align-items-center">
					<div className="item-col">
						<div className="search-cell position-relative">
							<Input type="text" placeholder='Search' onChange={handleSearch}
								value={filterPayload?.searchText} />
							<span className='position-absolute d-inline-flex align-items-center justify-content-center'>
								<span class="material-symbols-outlined">
									search
								</span>
							</span>
						</div>
					</div>

					<div className="date-range-container cme-10">
						<DateRange
							inputClassName="custom-input-grey"
							placeholder="Select Date"
							id="boarding"
							name="boarding"
							startDateProp={startDate}
							endDateProp={endDate}
							setStartDateProp={setStartDate}
							setEndDateProp={setEndDate}
							onChange={(sDate, eDate) => {
								const startDate = sDate ? moment(sDate).format("YYYY-MM-DD") : "";
								const endDate = eDate ? moment(eDate).format("YYYY-MM-DD") : "";
								onChangeDate(startDate, endDate);
							}}
							isClearable
						/>
					</div>

					<div className="item-col">
						<Input type="select" name="select" id="exampleSelect" onChange={onSelectType}
							value={filterPayload?.type}>
							<option value="">All Types</option>
							<option value="in-clinic">In-clinic</option>
							<option value="phone-call">Phone-call</option>
							<option value="video-call">Video-call</option>
						</Input>
					</div>
					<div className="item-col">
						<Input type="select" name="select" id="exampleSelect2" onChange={onSelectClinic}>
							<option value="">All Clinics</option>
							{
								clinicData?.map((val, index) => {
									return (
										<option key={index} value={val?._id}>{titleCaseString(val?.clinic_name)}</option>
									)
								})
							}
						</Input>
					</div>

					<div className="item-col">
						<Input type="select" name="select" id="exampleSelect2" onChange={onSelectDoctor}>
							<option value="">All Doctors</option>
							{
								doctorData?.map((val, index) => {
									return (
										<option key={index} value={val?._id}>{titleCaseString(val?.doctor_name)} </option>
									)
								})
							}
						</Input>
					</div>

				</div>
			</div>
		</>
	);
};

export default FilterBlock;
