import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "reactstrap";
import { RiDeleteBin5Line } from "react-icons/ri";

import AddTreatmentPlan from "./AddTreatmentPlan";
import SwitchCell from "shared/SwitchCell";
import { getFormAndTemplates, updateTreatmentPlanKey } from "context/actions/clinicSettings";

const TreatmentPlanTemplateBlock = ({ list }) => {
    const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
    const dispatch = useDispatch();
    const [selectedRow, setSelectedRow] = useState();
    const { clinic_settings } = useSelector(
        (state) => state.clinicSettingsReducer
    );

    const handleAddPatientIntakeForm = () => {
        setSelectedRow(null);
        setIsAddEditModalOpen(true);
    };

    const handleEditForm = (item) => {
        setSelectedRow(item);
        setIsAddEditModalOpen(true);
    };

    const closeAddEditDischargeInstructionPanel = (item) => {
        setSelectedRow(null);
        setIsAddEditModalOpen(false);
    };

    const handleUpdateTemplateStatus = (key,item) => {
        let body = {
          [key]: !item[key],
        };
        dispatch(updateTreatmentPlanKey(item?._id, body, (res) => {
            if (res.status) {
                dispatch(getFormAndTemplates({}, () => {}))
            }
        }));
    };


    return (
        <>
            <div className="form-template-blc">
                <div className="head d-flex">
                    <div className="left-col">
                        <h3>Treatment Plan Templates</h3>
                        <p>
                            
                        </p>
                    </div>
                    <div className="right-col ms-auto">
                        <Button
                            onClick={handleAddPatientIntakeForm}
                            color="link"
                            className="d-inline-flex align-items-center justify-content-center add-btn p-0"
                        >
                            <span className="icomoon-plus-btn"></span>
                        </Button>
                    </div>
                </div>
                <div className="table-listing-blc overflow-auto">
                    <Table responsive className="checkbox-listing">
                        <thead>
                            <tr>
                                <th style={{ minWidth: "192px" }}>Name</th>
                                <th style={{width: '100%'}}>Notes</th>
                                <th>Active</th>
                                <th style={{textAlign:'right'}}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list?.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="text-clip-cell flex-grow-1">
                                            {item?.treatmentName}
                                        </td>
                                        <td className="text-clip-cell flex-grow-1" style={{ minWidth: "190px", maxWidth: "190px" }} >
                                            {item?.notes|| '--'}
                                        </td>
                                        <td>
                                            <SwitchCell
                                                onClick={() => handleUpdateTemplateStatus("isActive",item)}
                                                className={item?.isActive ? "" : "disable"}
                                                
                                            />
                                        </td>
                                        <td style={{textAlign: 'right'}} className="action-cell" >
                                            <Button
                                                color="link"
                                                className="edit-btn"
                                                onClick={() => {
                                                    handleEditForm(item);
                                                }}
                                            >
                                                <span className="icomoon-pencil"></span>
                                            </Button>

                                            <Button
                                                onClick={() => handleUpdateTemplateStatus("deleted",item)}
                                                color="link"
                                                className="edit-btn"
                                            >
                                                <RiDeleteBin5Line />
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>

                {isAddEditModalOpen && (
                    <>
                        <div
                            className={`patient-panel-overlay position-fixed ${isAddEditModalOpen ? "active" : ""
                                }`}
                            onClick={() => closeAddEditDischargeInstructionPanel()}
                        ></div>

                        <AddTreatmentPlan
                            show={isAddEditModalOpen}
                            handleClose={closeAddEditDischargeInstructionPanel}
                            selectedPlan={selectedRow}
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default TreatmentPlanTemplateBlock;
