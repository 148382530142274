import React, { useEffect } from "react";
import { Button, Input } from "reactstrap";
import SearchBlock from "./SearchBlock";
import DropdownBlock from "./DropdownBlock";
import "./index.scss";
import { MdOutlineTableRows } from "react-icons/md";
import {
  getCurrentDay,
  getCurrentMonth,
  getCurrentWeekSpan,
  getDayValue,
  getMonthValue,
  getWeekSpanValue,
} from "../../../utils/calendar/constant";
import { useState } from "react";

import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { LuDownload } from "react-icons/lu";
import jsPDF from "jspdf";
import moment from "moment";

const CalendarFilterWeekDay = ({
  sendCalendarView,
  calendarViewValue,
  onViewChange,
  listData,
  tableViews = true
}) => {
  const [calendarView, setCalendarView] = useState("day");
  const [monthdata, setMonthData] = useState();
  const [monthVal, setMonthVal] = useState("Jan 1971");

  const [viewType, setViewType] = useState("listView");
  const [search, setSearch] = useState("");
  const [modality, setModality] = useState("")

  const handleCalendarView = (viewType) => {
    setCalendarView(viewType);
    sendCalendarView(viewType);
    if (viewType === "week") {
      handleWeekData("", true, "");
    } else {
      handleDateData("", true, "");
    }
  };

  useEffect(() => {
    if (calendarView === "week") {
      handleWeekData("", true, "");
    } else {
      handleDateData("", true, "");
    }
  }, []);

  const onChangeSearch = (searchData) => {
    setSearch(searchData);
  };



  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (calendarView === "week") {
        handleWeekData(search, false, modality);
      } else {
        handleDateData(search, false, modality);
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [search, modality]);

  const changeViewType = () => {
    viewType == "tableView"
      ? setViewType("listView")
      : setViewType("tableView");
  };

  useEffect(() => {
    if (onViewChange) {
      onViewChange(viewType);
    }
  }, [viewType]);

  const handleMonthChange = (changeType, search) => {
    if (calendarView === "week") {
      const weekData = getWeekSpanValue(
        monthdata.date_value,
        monthdata.month_value,
        monthdata.year_value,
        changeType
      );
      setMonthData(weekData);
      setMonthVal(weekData.data);
      calendarViewValue({
        view_type: "week",
        date_value: weekData.date_value,
        month_value: weekData.month_value,
        year_value: weekData.year_value,
        span_date: weekData.span_date,
        span_month: weekData.span_month,
        last_week_span: weekData.last_week_span,
        search: search,
      });
    } else {
      const dayData = getDayValue(
        monthdata.date_value,
        monthdata.month_value,
        monthdata.year_value,
        changeType
      );
      setMonthData(dayData);
      setMonthVal(dayData.date);
      calendarViewValue({
        view_type: "day",
        date_value: dayData.date_value,
        month_value: dayData.month_value,
        year_value: dayData.year_value,
        search: search,
      });
    }
  };

  const handleWeekData = (search = "",  init, modality = "") => {
    const weekData = init ? getCurrentWeekSpan() : monthdata;
    setMonthData(weekData);
    setMonthVal(weekData.data);
    calendarViewValue({
      view_type: "week",
      date_value: weekData.date_value,
      month_value: weekData.month_value,
      year_value: weekData.year_value,
      span_date: weekData.span_date,
      span_month: weekData.span_month,
      last_week_span: weekData.last_week_span,
      search: search,
      modality: modality
    });
  };

  const handleDateData = (search = "", init, modality="") => {
    let currDate;
    if (monthdata) {
      currDate = init ? getCurrentDay() : monthdata;
    } else {
      currDate = getCurrentDay();
    }
    setMonthData(currDate);
    setMonthVal(currDate.date);
    calendarViewValue({
      view_type: "day",
      date_value: currDate.date_value,
      month_value: currDate.month_value,
      year_value: currDate.year_value,
      search: search,
      modality: modality
    });
  };

  const downloadReport = () => {
    const doc = new jsPDF();
    doc.setFontSize(12);
    let currentDate = new Date();
    currentDate = moment(currentDate).format("YYYY");
    let monthValDate = `${monthVal}, ${currentDate}`;
    doc.text(`Date: ${monthValDate}`, 14, 10);

    listData.forEach((map, i) => {
      doc.autoTable({ html: `#list-table-${i}` });
    });
    doc.save(`diagnostic_testing_list_${monthValDate}.pdf`);
  };

  return (
    <>
      <div className=" cancel-margin calendar-filter-blc justify-between w-100 flex flex-col md:flex-row overflow-auto diagnostic-filter-block flex-shrink-0">
        <div className="left-col inline-flex items-center">
          <SearchBlock onChangeSearch={onChangeSearch} />
            { viewType ? 
            <div className="item-col w-100">
            <Input type="select" name="select" id="exampleSelect" value={modality} onChange={(e) => setModality(e.target.value)}>
                <option value="">All Modalities</option>
                <option value="REFLAB">Reference Lab</option>
                <option value="INHOUSE">In House</option>
              </Input>
            </div> : 
            <></>}


          {/* <DropdownBlock /> */}
        </div>
        <div className="right-col flex items-center flex-row gap-2">
          {/* {listData?.length != 0 &&
            <div className="download-icon">
                <span className="txt d-inline-flex align-items-center justify-content-center">
                  <LuDownload onClick={downloadReport} />
                </span>
            </div>
          } */}
          <div
            className={`date-blc input-cell d-inline-flex 
					${calendarView === "week" && "week-view"}
					`}
          >
            <Button
              color="link"
              className="arw-btn lft d-inline-flex align-items-center justify-content-center p-0"
              onClick={() => handleMonthChange("previous", search)}
            >
              <BsChevronLeft className="h-4 w-4" />
            </Button>
            <span className="txt d-inline-flex align-items-center justify-content-center">
              {monthVal}
            </span>
            <Button
              color="link"
              className="arw-btn rt d-inline-flex align-items-center justify-content-center p-0"
              onClick={() => handleMonthChange("next", search)}
            >
              <BsChevronRight className="h-4 w-4" />
            </Button>
          </div>
          <div className="date-type-blc input-cell d-inline-flex align-items-center">
            <Button
              color="link"
              className={`p-0 d-inline-flex align-items-center justify-content-center lft ${
                calendarView === "day" && "active"
              }`}
              onClick={() => handleCalendarView("day")}
            >
              Day
            </Button>
            <Button
              color="link"
              className={`p-0 d-inline-flex align-items-center justify-content-center ${
                calendarView === "week" && "active"
              }`}
              onClick={() => handleCalendarView("week")}
            >
              Week
            </Button>
          </div>
          { tableViews ? 
          <div className="right-col ms-auto">
            <Button
              color="link"
              className="list-view-type-btn d-inline-flex align-items-center"
              onClick={changeViewType}
            >
              <span className="d-inline-flex">
                {viewType == "tableView" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="10"
                    fill="none"
                    viewBox="0 0 18 10"
                    style={{ color: "black" }}
                  >
                    <path
                      fill="#788693"
                      d="M1.074 1.75a.74.74 0 01-.525-.212A.706.706 0 01.324 1C.324.784.4.605.55.464A.74.74 0 011.074.25h12.2a.726.726 0 01.75.75.726.726 0 01-.75.75h-12.2zm0 4a.74.74 0 01-.525-.212A.706.706 0 01.324 5a.71.71 0 01.225-.538.742.742 0 01.525-.212h12.2a.728.728 0 01.75.75.726.726 0 01-.75.75h-12.2zm0 4c-.2 0-.375-.07-.525-.211A.71.71 0 01.324 9a.71.71 0 01.225-.538.742.742 0 01.525-.212h12.2a.728.728 0 01.75.75.728.728 0 01-.75.75h-12.2zm15.775.076a.84.84 0 01-.6-.238A.777.777 0 0116 9a.8.8 0 01.238-.588.797.797 0 01.587-.237c.234 0 .434.079.6.237a.78.78 0 01.25.588.797.797 0 01-.237.587.799.799 0 01-.588.238zm0-8a.84.84 0 01-.6-.238A.777.777 0 0116 1a.8.8 0 01.238-.588.797.797 0 01.587-.237c.234 0 .434.079.6.237a.78.78 0 01.25.588.797.797 0 01-.237.587.799.799 0 01-.588.238zm0 4a.84.84 0 01-.6-.238A.777.777 0 0116 5a.8.8 0 01.238-.588.797.797 0 01.587-.237c.234 0 .434.079.6.237a.78.78 0 01.25.588.797.797 0 01-.237.587.799.799 0 01-.588.238z"
                    ></path>
                  </svg>
                ) : (
                  <MdOutlineTableRows />
                )}
              </span>
              {viewType == "tableView" ? "List View" : "Table View"}
            </Button>
          </div> : 
          <></>}
        </div>
      </div>
    </>
  );
};

export default CalendarFilterWeekDay;
