import React, { useEffect, useRef, useState } from "react";
import { appointmentTypes } from "utils/constants";
import { Input } from "reactstrap";
import { FaAngleDown } from "react-icons/fa";
import "./AppointmentTypeFilter.scss";

const AppointmentTypeFilter = ({ selectedAppointmentType, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const myRef = useRef();
  const handleClickOutside = (e) => {
    if (myRef && myRef?.current && !myRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
  return (
    <div id="appointment-type-filter-container">
      <div
        className="fb-center gap-2 br-4 pt-2 pb-2 ps-3 pe-3 cursor-pointer  bg-[#e4e4e447]"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <span className="text-14-400 text-[#808080] un">
          {selectedAppointmentType?.length > 0
            ? `Selected type (${selectedAppointmentType?.length})`
            : "Select type"}
        </span>
        <span className="text-14-400 text-[#808080]">
          <FaAngleDown />
        </span>
      </div>
      {isOpen && (
        <div className="shadow appointment-type-selection-popup" ref={myRef}>
          {appointmentTypes?.map((elm, index) => {
            const { label, value } = elm;
            return (
              <div className="fb-center bb-e3e3 cp-8 cps-10 un" key={index}>
                <div className="fa-center gap-2">
                  <div className="check-box-block">
                    <Input
                      type="checkbox"
                      checked={selectedAppointmentType?.includes(value)}
                      onChange={() => {
                        onSelect(value);
                      }}
                    />
                  </div>

                  <div className="text-14-400 color-2E65 d-name-block">
                    {`${label}`}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AppointmentTypeFilter;
