import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import PatientListPanel from "../../shared/Calendar/PatientListPanel";

import { getLimitedAppointments } from "../../context/actions/appointment";
import { patientListPanelAction } from "../../context/actions/patientListPanelAction";

import { IoMdAddCircle } from "react-icons/io";
import { AiOutlineClockCircle } from "react-icons/ai";
import { TiVideoOutline } from "react-icons/ti";

import { getPetDetailsById } from "../../context/actions/patient-module/patient/pet";
import { useHistory } from "react-router-dom";
import { getAppointmentTimingDisplayValues } from "utils/calendar/constant";
import { useClinicSpecies } from "hooks/useClinicSpecies";
import { useClinicBreed} from "hooks/useClinicBreed"
import Spinner from "shared/Spinner";

const UserAppointmentCard = ({
  petPic,
  petName,
  gender,
  time,
  consultancyMode,
  breed,
  specie,
  doctor,
}) => {

  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );
  return (
    <div className="p-[6px] ml-[7px] h-fit w-100 space-y-1.5 bg-[#F8F8F8E5] rounded-lg my-2 cursor-pointer">
      <div className="w-[100%]">
        <div className="flex justify-between  items-center">
          <p className="flex items-center">
            <img
              style={{ maxWidth: "24px", maxHeight: "24px" }}
              className="w-[24px] h-[24px] mr-[4px] rounded-full bg-gray-200"
              src={doctor.user_image}
              alt="pic"
            />
            <span className="font-['Inter'] font-[500] capitalize text-[12px]">
              {doctor.title}
              {clinic_settings?.doctor_name_format === 0 ? (
                <span>
                  {" "}
                  {doctor.lastname} {doctor.firstname}{" "}
                </span>
              ) : (
                <span>
                  {" "}
                  {doctor.firstname} {doctor.lastname}{" "}
                </span>
              )}
              {/* {doctor.firstname} {doctor.lastname} */}
            </span>
          </p>
          <img
            src={require("../../assets/img/autoMode.png")}
            alt="auto"
            style={{ maxWidth: "17px", maxHeight: "17px" }}
            className="w-[17px] h-[17px] "
          />
        </div>
      </div>
      <div className="flex items-start gap-2">
        <img
          className="mt-1 h-[65px] w-[65px] bg-gray-200 rounded-lg"
          src={petPic}
          alt="Patient"
        />
        <p className="font-['Inter'] text-[#282E65] flex flex-col gap-[10px]">
          <span className=" text-[#282E65] font-[700] pt-3">
            {petName}
          </span>
          <span className="capitalize">
            {gender} / {specie} ({breed})
          </span>
          <span className=" text-[13px] flex items-center gap-[3px]">
            <AiOutlineClockCircle size={20} />
            {time}
          </span>
          <span className="text-[13px] flex capitalize items-center gap-[3px]">
            {/*<TiVideoOutline size={20} />*/}
            {consultancyMode}
          </span>
        </p>
      </div>
      <div></div>
    </div>
  );
};

export default function Appointments() {
  const dispatch = useDispatch();
  const [appointments, setAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectDate] = useState(new Date());
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );
  const clinicSpecies = useClinicSpecies()
  const clinicBreed = useClinicBreed()
  const [days, setDays] = useState([]);
  const { clinic_details } = useSelector((state) => state.clinicReducer);

  const history = useHistory();

  const patientListPanelState = useSelector(
    (state) => state.patientListPanelReducer.isPatientListPanel
  );

  const addNewAppointmentUrl = (petId) => {
    history.push("/add-new-appointment/" + petId);
    dispatch(getPetDetailsById(petId), () => {
      dispatch(patientListPanelAction(false));
    });
  };

  useEffect(() => {
    const day = selectedDate.getDate().toString().padStart(2, "0");
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
    const year = selectedDate.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    // console.log(formattedDate);
    setIsLoading(true);
    dispatch(
      getLimitedAppointments(
        formattedDate,
        clinic_settings?.clinic_id,
        (result) => {
          setIsLoading(false);
          setAppointments(result);
        }
      )
    );
  }, [selectedDate]);

  const getNext7Days = () => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(); // Create a new Date object
      date.setDate(date.getDate() + i); // Add 'i' days to the current date
      days.push(date); // Push the date to the 'days' array
    }
    return days;
  };
  useEffect(() => {
    setDays(getNext7Days());
  }, []);

  const openPatientPanel = () => {
    dispatch(patientListPanelAction(!patientListPanelState));
  };

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  const weekdays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

  return (
    <div>
      <div className=" ml-1">
        <p className="text-[#282E65] font-['Inter'] flex justify-between">
          <span className="font-[600]">Appointments</span>
          {permissions?.edit_appointments && (
            <span className="flex items-center font-[500]">
              <IoMdAddCircle
                onClick={openPatientPanel}
                color="#11CA9D"
                size={25}
              />
            </span>
          )}
        </p>
      </div>
      <div className="flex mt-2 w-100 gap-[4px]">
        {days.map((d) => {
          const date = new Date(d);
          return (
            <button
              onClick={() => setSelectDate(date)}
              key={date.getDay()}
              className={`rounded-md border border-1 w-100 ${
                selectedDate.getDate() === date.getDate()
                  ? "bg-[#3D3EFD] text-white"
                  : "bg-white text-black"
              }  py-1`}
            >
              <p
                className="text-center flex flex-col"
                style={{ lineHeight: "1.4" }}
              >
                <span className="font-['Inter'] text-[12px] font-[300]">
                  {weekdays[date.getDay()]}
                </span>
                <span className="font-['Inter'] text-[14px] font-[500] mb-0">
                  {date.getDate()}
                </span>
              </p>
            </button>
          );
        })}
      </div>
      {isLoading ? (
        <div className="mx=auto">
          <Spinner height={150} />
        </div>
      ) : (
        <>
          {appointments?.length === 0 && (
            <p className="text-gray-500 text-center py-10">No Appointments</p>
          )}
          {appointments?.length > 0 &&
            appointments?.map((a) => (
              <UserAppointmentCard
                petName={a.patient_id?.pet_name}
                petPic={a.patient_id?.pet_image}
                gender={a.patient_id?.gender}
                time={getAppointmentTimingDisplayValues(
                  a?.appointment_timings
                )?.join(", ")}
                consultancyMode={a.appointment_type}
                breed={clinicBreed(a?.patient_id?.breed?._id) ||  a.patient_id?.breed?.breed}
                specie={clinicSpecies(a.patient_id?.breed?.species_id?._id) || a.patient_id?.breed?.species_id?.species_name}
                doctor={a.doctor_id}
              />
            ))}
        </>
      )}

      <div
        className={`patient-panel-overlay position-fixed ${
          patientListPanelState ? "active" : ""
        }`}
        onClick={() => dispatch(patientListPanelAction(false))}
      ></div>
      <PatientListPanel
        className={`${patientListPanelState ? "active" : ""}`}
        clinicId={clinic_details?._id}
        callable={addNewAppointmentUrl}
      />
    </div>
  );
}
