import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAndFilterDiagnosticResults } from "../../../../../../context/actions/patient-module/diagnostic-testing";
import CalendarFilterWeekDay from "../../../../../../shared/Calendar/CalendarFilterWeekDay";
import CalenderFilterMonthDay from "../../../../../../shared/Calendar/CalenderFilterMonthDay";
import { Button, Input } from "reactstrap";
import './index.scss';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { getAllReportsByPetId } from "context/actions/patient-module/overview/report";

const FilterBlock = ({ openPanel, recentDate, getDate, reportDate, onViewChange, onChangeFilter, onChangeModality = () => {}, currentView, reportList, listData }) => {

    const params = useParams();

    const dispatch = useDispatch();


    const [viewData, setViewData] = useState(false);
    const [viewValue, setViewValue] = useState('day');

    const handleGetResults = () => {
        if (viewData) {
            let startDate, endDate, search, modality
            let body = { pet_id: params.petId }
            if (viewData?.view_type === 'day') {
                startDate = (new Date(viewData?.year_value, viewData?.month_value - 1, viewData?.date_value)).toISOString()
                endDate = (new Date(viewData?.year_value, viewData?.month_value - 1, viewData?.date_value + 1)).toISOString()

            } else {
                startDate = (new Date(viewData?.year_value, viewData?.span_month - 1, viewData?.span_date)).toISOString()
                endDate = (new Date(viewData?.year_value, viewData?.month_value - 1, viewData?.date_value + 1)).toISOString()
            }
            search = viewData?.search;
            modality = viewData?.modality;

            body = { ...body, startDate: startDate, endDate: endDate, search: search, modality: modality}
            dispatch(getAndFilterDiagnosticResults(body, (result) => { }))
            dispatch(getAllReportsByPetId(params.petId, {reportDate: startDate,endDate:endDate},"", () =>{}));
        }

    }


    const handleFilterChange = (value) => {
        setViewData(value);
    }


    useEffect(() => {
        handleGetResults();
    }, [viewData]);


    return (
        <>
            <div className="cancel-margin diagnostic-filter-block align-items-center">
                {(currentView == 'tableView') ?
                    <CalenderFilterMonthDay sendCalendarView={(value) => {
                        setViewValue(value);
                    }} calendarViewValue={(value) => {

                        handleFilterChange(value)
                    }} getDate={getDate} openPanel={openPanel} recentDate={recentDate} reportDate={reportDate} onViewChange={onViewChange} onChangeFilter={onChangeFilter} onChangeModality={onChangeModality} reportList={reportList} />
                    :

                    <CalendarFilterWeekDay sendCalendarView={(value) => {
                        setViewValue(value);
                    }} calendarViewValue={(value) => {
                        handleFilterChange(value)
                        getDate(moment(`${value.date_value}-${value.month_value}-${value.year_value}`, "DD-MM-YYYY").toISOString())
                    }} onViewChange={onViewChange} listData={listData} />
                }
            </div>
        </>
    );
};

export default FilterBlock;
