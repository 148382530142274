import { useCallback, useEffect, useState } from 'react';


/**
 * Default order of video:
 *  1. video's participants first
 *  2. self on the background position
 */
export function useGalleryLayout(
  zmClient,
  activeVideo,
  pagination,
) {
  const [currentUser, setCurrentUser] = useState();
  const [participants, setParticipants] = useState([]);
  const [subscribedVideos, setSubscribedVideos] = useState([]);
  const { page, pageSize, totalPage, totalSize } = pagination;
 

  const updateUser = useCallback(() => {
    const participants = zmClient.getAllUser();
    setParticipants(participants);

    const currentUser = zmClient.getCurrentUserInfo();
    setCurrentUser(currentUser);

  }, [zmClient]);
  useEffect(() => {
    zmClient.on('user-updated', updateUser);
    zmClient.on("user-removed", updateUser);
    zmClient.on('user-added', updateUser);
    return () => {
      zmClient.off('user-updated', () => { });
      zmClient.off('user-added', () => { });
      zmClient.off("user-removed", updateUser);
    };
  }, [updateUser, zmClient]);


  useEffect(() => {
    if (currentUser && participants.length > 0) {
      const videoParticipants = participants.filter((user) => user.bVideoOn && user.userId !== currentUser.userId).map((user) => user.userId);
      setSubscribedVideos(videoParticipants);
    }
  }, [zmClient, pageSize, page, totalPage, currentUser, participants]);
  useEffect(() => {
    updateUser()
  }, [zmClient, activeVideo]);

  return {
    currentUser,
    participants,
    subscribedVideos
  }
}
