import { Form, Formik, FieldArray, Field, ErrorMessage } from 'formik';
import React, { useEffect, useState } from 'react';
import TextInput from '../../../../../shared/FormsInput/FormInput';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Row, Input, Label, InputGroup, Col, FormGroup, Table, FormFeedback } from 'reactstrap';
import { addNewUser } from '../../../../../context/actions/staffManagement';
import { userInit, userSchema } from './schema';
import { getActiveRoles } from 'context/actions/clinicPermission';


import PhoneNumberInput from 'shared/FormsInput/PhoneInput';


const NewUserModal = ({ extend = null, openViewBlock, refresh }) => {
    const dispatch = useDispatch();
    const [modal, setModal] = useState(true);
    const [roles, setRoles] = useState([])

    const { formTemplates: { patientnotestemplates } } = useSelector(state => state.formTemplateReducer)

    const userDetails = useSelector((state) => state.user.user_details);
    const { clinic_details } = useSelector((state) => state.clinicReducer)



    const toggle = () => {
        setModal(false);
        openViewBlock(false);
    }

    useEffect(() => {
        
            dispatch(getActiveRoles({}, (res) => {
                if (res?.status) {
                    setRoles(res?.response_data?.map((role) => ({value: role, label: role})))
                }
            }))
        
    }, [])


    const submitForm = (values) => {
        dispatch(addNewUser({ ...values, added_by: userDetails._id, clinic_id: clinic_details?._id }, (response) => {
            /*
            if(response?.status && !response?.errors) {
                toggle();
                refresh();
            }*/
            if (response?.message === 'mail_sent') {
                toggle();
                refresh();
            }
        }))
    }


    return (
        <>

            <Formik
                initialValues={userInit}
                enableReinitialize
                validationSchema={userSchema}
                onSubmit={submitForm}
            >
                {(formik) => (
                    <Modal size='lg' isOpen={modal} toggle={toggle}>
                        <Form>
                            <ModalHeader>
                                Create New User
                                <Button color='link' onClick={toggle}>
                                    <i className='icomoon-close-square'></i>
                                </Button>
                            </ModalHeader>
                            <ModalBody>

                                <TextInput
                                    name="email"
                                    placeholder="Enter user email"
                                    value={formik?.values?.email}
                                    onChange={formik.handleChange}
                                    isRequired={true}
                                    label="Email Address"
                                />
                        
                        	
                        <PhoneNumberInput
											 
                    
                            label="Phone Number"
                            name="phone"
                            id="phone"
                            required={false}
                            className="mb-[20px]"
                            value={formik?.values?.phone}
                            onChange={(e) => {formik.setFieldValue('phone', '+'+e);
                        }} />
                        {(formik.errors.phone && formik.touched.phone) && 
                        <FormFeedback className="d-block"> {formik?.errors?.phone}
                            
                        </FormFeedback>}
                                <TextInput
                                    name="firstname"
                                    type="text"
                                    placeholder="Enter user first name"
                                    value={formik?.values?.firstname}
                                    onChange={formik.handleChange}
                                    label="First Name"
                                    isRequired={true}
                                />

                        {/*formik?.errors?.firstname && formik?.touched?.firstname && formik?.errors?.firstname*/}

                                <TextInput
                                    name="lastname"
                                    type="text"
                                    placeholder="Enter user last Name"
                                    value={formik?.values?.lastname}
                                    onChange={formik.handleChange}
                                    label="Last Name"
                                    id={"lastname"}
                                    isRequired={true}
                                />
                        {/*formik?.errors?.lastname && formik?.touched?.lastname && formik?.errors?.lastname*/}

                                <TextInput
                                    name="title"
                                    type="text"
                                    placeholder="Enter user title"
                                    value={formik?.values?.title}
                                    onChange={formik.handleChange}
                                    isRequired={true}
                                    label="Title"
                                    id={"title"}
                                />

                        {/*formik?.errors?.title && formik?.touched?.title && formik?.errors?.title*/} 

                        <FormGroup className='input-wrapper'>

                                <label htmlFor={'role'} >
                                    <span className={'required'}>{'Role'}</span>
                                </label>
                                <Input
                                    name="role"
                                    type="select"
                                    placeholder="Enter user role"
                                    value={formik?.values?.role}
                                    onChange={formik.handleChange}
                                    isRequired={true}
                                    id={"role"}
                                >
                                    <option value="">Select A Role</option>
                                    {roles?.map((opt) => (<option value={opt?.value} key={opt?.value} style={{textTransform: "capitalize" }}>{opt?.label}</option>))}
                                </Input>
                        

                                {(formik.errors.role && formik.touched.role) && 
                                     <FormFeedback className="d-block"> {formik?.errors?.role}
                                       </FormFeedback>}
                        </FormGroup>
                        {/*formik?.errors?.role && formik?.touched?.role && formik?.errors?.role*/} 


                            </ModalBody>

                            <ModalFooter>
                                <Row className='w-100 d-flex flex-row'>
                                    <Col xs={6} className='ms-auto mr-auto' >
                                        <div className="btn-cell d-flex flex-column">
                                            <Button
                                                className="flex-fill "
                                                color="danger"
                                                style={{ minWidth: '0px' }}
                                                onClick={toggle}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </Col>

                                    <Col xs={6} className='ms-auto mr-auto' >
                                        <div className="btn-cell d-flex flex-column">
                                            <Button
                                                className="flex-fill"
                                                color="primary"
                                                style={{ minWidth: '0px' }}
                                                type="submit"
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </ModalFooter>
                        </Form>
                    </Modal>
                )}
            </Formik>
        </>
    );

}

export default NewUserModal;