import React from "react";
import AppLogo2 from "shared/AppLogo2";
import { Button } from "reactstrap";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import BadgeImage from "../../../assets/img/Badge-profile.png";
import PetDropdown from "./PetDropdown";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "context/actions/user";
import "./Sidebar.scss";

const Sidebar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const userDetail = useSelector((state) => state.user.user_details);
  const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);

  const handleLogout = () => {
    // showLoader(true);
    dispatch(
      logout(() => {
        history.push("/login");
      })
    );
  };
  const sidebarOptions = [
    {
      title: "Health Snapshot",
      icons: <span className="material-icons">dashboard</span>,
      url: "/pet-owner-health-snapshot",
    },
    {
      title: "Appointments",
      icons: <span className="material-icons">event_available</span>,
      url: "/pet-owner-appointments",
    },
    {
      title: "Pet’s Health Records",
      icons: <span className="icomoon-pet-foot"></span>,
      url: "/pet-owner-health-records",
    },
    {
      title: "Messages",
      icons: <span className="material-icons">message</span>,
      url: "/pet-owner-messages",
    },
    {
      title: "My Pet Services",
      icons: <span className="material-icons">spa</span>,
      url: "/pet-owner-pet-services",
    },
  ];


  if(clinic_settings?.pet_owner_preferences?.billing){
    sidebarOptions.push({
      title: "Billing",
      icons: <span className="material-icons">request_quote</span>,
      url: "/pet-owner-billing",
    })
  }
  const { firstname, lastname, user_image } = userDetail || {};
  return (
    <div id="sidebar-container">
      <AppLogo2 />
      <PetDropdown />
      <div className="inner-wrapper">
        <div className="sidebar-menu">
          <ul className="list-unstyled">
            {sidebarOptions.map((elm, index) => {
              const { title, icons, url } = elm;
              const isActive = url === location.pathname;
              return (
                <li className={isActive ? "active" : ""} key={index}>
                  <Button
                    onClick={() => {
                      history.push(url);
                    }}
                    color="link"
                    className="d-flex align-items-center text-start w-100"
                  >
                    {icons}
                    <label>{title}</label>
                  </Button>
                </li>
              );
            })}
            <li className="">
              <Button
                onClick={handleLogout}
                color="link"
                className="d-flex align-items-center text-start w-100"
              >
                <span className="material-symbols-outlined">logout</span>
                <label>Logout</label>
              </Button>
            </li>
          </ul>
        </div>
      </div>
      <div className="bottom-block">
        <div>
          {/* <div className="subscription-block">
            <div className="d-flex">
              <img src={BadgeImage} alt="user-img" />
            </div>
            <div>
              <div className="gold-title">Golden Care</div>
              <div className="gold-sub-title">
                Purchased on
                <br />
                10/12/22
              </div>
            </div>
          </div> */}
          <div
            className="user-block cursor-pointer"
            onClick={() => {
              history.push("/pet-owner-profile");
            }}
          >
            <div className="f-center bg-eeee color-cfcf h-49 w-49 rounded-circle">
              {user_image ? (
                <img
                  src={user_image}
                  alt="badge-img"
                  className="rounded-circle"
                />
              ) : (
                <span className="material-symbols-outlined">person</span>
              )}
            </div>
            <div>
              <div className="name-block">
                {firstname} {lastname}
              </div>
              <div className="owner-block">Pet Owner</div>
            </div>
          </div>
          <div className="copyright-block">
            <div>Copyright © 2024</div>
            <div>| Privacy Policy</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
