import React from "react";
import "./index.scss";

const StaffProfileSummary = ({ staffMemberDetails }) => {
	return (
		<>
			<div className="staff-profile-summary">
				<h4 className="section-title">About/Summary</h4>
				<p className="text-14-400 color-2E65">
					Passionate for helping others with 3+ years of HR experience and 5
					years of Operations experience. Eager to boost customer satisfaction
					and employee moral by promoting quality and productivity. Tenacious in
					identifying and pursing opportunities to improve business operations
					and positive relationship building.{" "}
				</p>
			</div>
		</>
	);
};

export default StaffProfileSummary;
