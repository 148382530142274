import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
// import './index.css';
import ScheduleSelectWeek from "../../../../../../../shared/Calendar/ScheduleSelectWeek";
import { Button } from "reactstrap";
import moment from "moment";
import {
  getAvailabilityDetails,
  saveAvailability,
} from "../../../../../../../context/actions/staffManagement";
const Availability = ({ schedule, setSchedule }) => {
  const dispatch = useDispatch();
  // const [schedule, setSchedule] = useState([]);
  const [isEdited, setEdited] = useState(false);

  // const update = () => {

  //     if(operatingRoomDetails){

  //         const dates = schedule.reduce((dates, item) => {
  //             const date = moment(item).format('DD-MM-YYYY')
  //             if (!dates[date]) {
  //             dates[date] = [];
  //             }
  //             dates[date].push(moment(item).format('h:mm a'));
  //             return dates;
  //         }, {});

  //         // Edit: to add it in the array format instead
  //         const groupArrays = Object.keys(dates).map((date) => {
  //             return {
  //             date,
  //             slots_time: dates[date]
  //             };
  //         });
  //         //console.log(groupArrays);

  //         groupArrays.forEach( (i) => {
  //             let obj = {doctor_id: operatingRoomDetails?.doctor?._id, date: i?.date, slots_time: i?.slots_time}

  //             dispatch(saveAvailability(obj, (res) => {
  //                 handleGetAvailability();
  //                 console.log(res)}));
  //         }
  //         )

  //     }

  // }

  // const handleGetAvailability = () => {
  //     if(operatingRoomDetails){
  //         dispatch(getAvailabilityDetails(operatingRoomDetails?.doctor?._id, {}, (res) => {
  //             var dates = []
  //             for(let item of res){
  //                 for(let time of item?.slots_time){
  //                     let d = new Date(moment(item?.date + ' ' + time, 'DD/MM/YYYY h:mm a'));
  //                     dates.push(d.toString());
  //                 }
  //             }
  //             setSchedule(dates);
  //             setEdited(false);

  //         }))
  //         }
  // }

  // useEffect(() => {
  //     handleGetAvailability();
  // }
  // , [staffMemberDetails])

  return (
    <>
      <div className="staff-profile-overview-blc">
        <div className="row-block">
          <div className="staff-profile-summary">
            <div
              className="d-flex w-100 flex-row p-2"
              style={{ justifyContent: "space-between" }}
            >
              <h4 className="section-title">Availability</h4>
              {/* <Button
                                // onClick={update}
                                color={'primary'}>Save</Button> */}
            </div>
            <ScheduleSelectWeek
              schedule={schedule}
              setSchedule={(data) => {
                setSchedule(data);
                setEdited(true);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Availability;
