import { logout } from "context/actions/user";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Sidebar from "./Sidebar";
import "./PetOwnerDashboardLayout.scss";
import { inactivityMessage } from "../../utils/messages";
import { getClinicSettingsByClinicId } from "context/actions/clinicSettings";
import { useIdleTimer } from "react-idle-timer";
import { timeout, toasterConfig } from "../../utils/constants";
import NewPetModal from "../../shared/NewPetModal"
import { toast } from "react-toastify";
import { disableNetwork } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import {auth} from  "../../firebase"


const PetOwnerDashboardLayout = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  let userDetails = useSelector((state) => state.user.user_details);


  const [user, loading, error] = useAuthState(auth);


  useEffect(() => { 
    if (loading) return;
    else if (!user) {
      dispatch({ type: "LOGOUT", data: {} });
      history.push('/login')

    };}, [user, loading])


  const { petOwnerPetList, newPetModal} = useSelector(
    (state) => state.petOwnerDashboardReducer
  );
  // useEffect(() => {
  //   if (!(userDetails || localStorage.getItem("USER"))) {
  //     dispatch(
  //       logout(() => {
  //         history.push("/login");
  //       })
  //     );
  //   }


  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userDetails]);


  // useEffect( () => { 
  //   if(userDetails){
  //     if(petOwnerPetList?.length <= 0) { 
  //       console.log('list is less or eq to 0', petOwnerPetList)

  //       console.log(newPetModal)

  //       dispatch({type: "SET_NEW_PET_MODAL", payload: true})
      
      
  //     }else {
        
  //       dispatch({type: "SET_NEW_PET_MODAL", payload: false})


        
  //     }
  //   }
  //   }, [petOwnerPetList])



  const onIdle = () => {
    dispatch(
      logout(() => {
        history.push("/login");
        toast.success(inactivityMessage, toasterConfig);
      })
    );
  };
  useIdleTimer({
    timeout,
    onIdle,
  });

  return (
    <div id="pet-owner-dashboard-layout-container">
      <Sidebar />
      <div className="right-body-container w-100">{children}</div>
 
    </div>
  );
};

export default PetOwnerDashboardLayout;
