import React from "react";
import { FormFeedback, FormGroup, Input } from "reactstrap";

const TextBox = ({
  type = "text",
  placeholder,
  labelVisible = true,
  label,
  id,
  className,
  value,
  icon = false,
  iconUser = false,
  iconPhone = false,
  iconmoon = false,
  customIcon = false,
  onChange,
  maxlength,
  disabled,
  error,
  isRequired,
}) => {
  return (
    <>
      <FormGroup className={`input-wrapper ${className}`}>
        {labelVisible && (
          <label htmlFor={id} className={isRequired ? "required-input" : ""}>
            {label}
          </label>
        )}
        <div className={`input-cell position-relative ${icon && "icon"}`}>
          {icon && (
            <span className="icon position-absolute h-100 d-inline-flex align-items-center justify-content-center">
              {iconUser && <i className="icomoon-user"></i>}
              {iconPhone && <i className="icomoon-phon"></i>}
              {iconmoon && <i className={iconmoon}></i>}
              {customIcon === "search" && (
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    fill="none"
                    viewBox="0 0 21 21"
                  >
                    <circle
                      cx="9.767"
                      cy="9.767"
                      r="8.989"
                      stroke="#080829"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    ></circle>
                    <path
                      stroke="#080829"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M16.018 16.485L19.542 20"
                    ></path>
                  </svg>
                </span>
              )}
            </span>
          )}
          <Input
            id={id}
            type={type}
            placeholder={placeholder}
            value={value}
            className={className}
            onChange={onChange}
            maxLength={maxlength}
            disabled={disabled}
          />
        </div>
        {error && <div className="text-12-400 text-danger mt-1">{error}</div>}
      </FormGroup>
    </>
  );
};
export default TextBox;
