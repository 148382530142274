import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";

toast.configure();

/**
 * @description function use to get all staff management users
 * @param {*} params accepting object
 */
export const getAllStaffManagementUsers = ( params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details }
    } = getState();
    dispatch({ type: "ISLOADING", data: true });
    ApiClient.get(
      `${apiUrl}${PORT}/staff-management/get-all-staff-management-users/${clinic_details?._id}`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      dispatch({ type: "ISLOADING", data: false });
      if (response && !response.errors) {
        return callback(response);
      } else if (response?.code === 404) {
        toast.warn(response.message);
        return callback();
      } else {
        toast.error(response.errors.msg, toasterConfig);
        return callback();
      }
    });
  };
};

export const addNewUser = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    if (token) {
      ApiClient.post(
        `${apiUrl}${PORT}/staff-management/add-user`,
        data,
        token,
        dispatch, getState
      ).then((response) => {
        if (response && !response?.errors) {
          toast.success(response?.message);
          return callback(response);
        } else if (response?.code === 404) {
          toast.warn(response.message);
          return callback();
        } else {
          toast.error(response.errors.msg, toasterConfig);
          return callback();
        }
      });
    }
  };
};

/**
 * @description function use to get staff management user
 * @param {*} params accepting object
 */
export const getStaffManagementUserData = (id, params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    dispatch({ type: "ISLOADING", data: true });
    ApiClient.get(
      `${apiUrl}${PORT}/staff-management/get-staff-management-user/${id}`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      dispatch({ type: "ISLOADING", data: false });
      if (response && !response.errors) {
        return callback(response);
      } else if (response?.code === 404) {
        toast.warn(response.message);
        return callback();
      } else {
        toast.error(response.errors.msg, toasterConfig);
        return callback();
      }
    });
  };
};

//new API for display staff management list  in  staff management module
export const getStaffManagementList = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details }
    } = getState();
    dispatch({ type: "ISLOADING", data: true });
    if(clinic_details) data.clinic_id = clinic_details?._id
    ApiClient.post(
      `${apiUrl}${PORT}/staff-management/get-all-staff-management-with-filter`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (response && !response.errors) {
        return callback(response);
      } else if (response?.code === 404) {
        toast.warn(response.message);
        return callback();
      } else {
        toast.error(response.errors.msg, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * @description function use to get staff management user
 * @param {*} params accepting object
 */
export const getAvailabilityDetails = (doctor_id, params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    dispatch({ type: "ISLOADING", data: true });
    ApiClient.get(
      `${apiUrl}${PORT}/doctor-availability/get-availability-details/${doctor_id}`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      dispatch({ type: "ISLOADING", data: false });
      if (response && !response.errors) {
        return callback(response);
      } else if (response?.code === 404) {
        toast.warn(response.message);
        return callback();
      } else {
        toast.error(response.errors.msg, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * @description function use to get staff management user
 * @param {*} params accepting object
 */
export const saveAvailability = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    dispatch({ type: "ISLOADING", data: true });
    ApiClient.put(
      `${apiUrl}${PORT}/doctor-availability/save-availability`,
      body,
      token,
      dispatch, getState
    ).then((response) => {
      dispatch({ type: "ISLOADING", data: false });
      if (response && !response.errors) {
        return callback(response);
      } else if (response?.code === 404) {
        toast.warn(response.message);
        return callback();
      } else {
        toast.error(response.errors.msg, toasterConfig);
        return callback();
      }
    });
  };
};

export const getStaffClinicList = (payload) => (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  return ApiClient.post(
    `${apiUrl}${PORT}/doctor-availability/get-doctor-wise-clinics`,
    payload,
    token,
    dispatch, getState
  );
};
export const getStaffAvailability = (payload) => (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  return ApiClient.post(
    `${apiUrl}${PORT}/doctor-availability/get-staff-availability`,
    payload,
    token,
    dispatch, getState
  );
};

export const addStaffAvailability = (payload) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  const response = await ApiClient.post(
    `${apiUrl}${PORT}/doctor-availability/add-staff-availability`,
    payload,
    token,
    dispatch, getState
  );
  if (response?.errors?.msg?.[0]?.msg) {
    toast.error(response?.errors?.msg?.[0]?.msg, toasterConfig);
  }
  return response;
};

export const getStaffAvailabilityByClinic =
  (payload) => async (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    const response = await ApiClient.post(
      `${apiUrl}${PORT}/doctor-availability/get-staff-availability-details`,
      payload,
      token,
      dispatch, getState
    );
    if (response?.errors?.msg?.[0]?.msg) {
      toast.error(response?.errors?.msg?.[0]?.msg, toasterConfig);
    }
    return response;
  };
export const updateStaffAvailabilityStatus =
  (payload) => async (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    const response = await ApiClient.put(
      `${apiUrl}${PORT}/doctor-availability/change-staff-availability`,
      payload,
      token,
      dispatch, getState
    );
    if (response?.errors?.msg?.[0]?.msg) {
      toast.error(response?.errors?.msg?.[0]?.msg, toasterConfig);
    } else if (response?.statusCode === 400) {
      toast.error(response?.message, toasterConfig);
    }
    return response;
  };
export const getClinicAvailabilityDetails =
  (id, data, params) => async (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    const response = await ApiClient.get(
      `${apiUrl}${PORT}/appointment/get-all-month-wise-doctors/${id}/${data}`,
      params,
      token,
      dispatch, getState
    );
    return response;
  };
