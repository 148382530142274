import { toast } from "react-toastify";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";

toast.configure();
/**
 * All Api endpoints to be call
 * Get -- /clinic/get-clinics/:user_id
 * Put -- /clinic/update
 * Get -- /clinic/get-forms-templates/:clinic_id
 */

/**
 *
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */

//END POINT DEPARTED ACCORDING TO NEW FLOW.
export const getClinicDetailsByUserId = (params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();
    //if (user?.role === "admin" || user?.role === "clinic") {

    dispatch({ type: "CLINIC_LOADING", payload: true });
    // ApiClient.get(
    //   `${apiUrl}${PORT}/clinic/get-clinic/${
    //     user?.role === "admin" || user?.role === "clinic"
    //       ? user?._id
    //       : user?.added_by
    //   }`,
    //   params,
    //   token
    // )
    ApiClient.get(
      `${apiUrl}${PORT}/clinic/get-clinic/${user?.primary_clinic}`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      dispatch({ type: "CLINIC_LOADING", payload: false });

      if (!response) {
        return callback();
      } else if (response?.status) {
        dispatch({ type: "GET_CLINIC", payload: response?.response_data });
        return callback(response);
      } else {
        // //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
    /*} else {
            callback(null)
        }*/
  };
};

/**
 *
 * @param {Object} body
 * @param {Function} callback
 * @returns
 */
export const updateClinicDetails = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    ApiClient.put(`${apiUrl}${PORT}/clinic/update`, body, token, dispatch, getState).then(
      (response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          // dispatch(getClinicDetailsByUserId());
          getHospitalDetailsById(clinic_details?._id, {}, () => {});
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      }
    );
  };
};

/**
 *
 * @param {String} clinic_id
 * @param {Object} params
 * @returns
 */
export const getAllClinicsByUserId = (params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();
    dispatch({ type: "CLINIC_LOADING", payload: true });
    ApiClient.get(
      `${apiUrl}${PORT}/clinic/get-clinics/${user?._id}`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      dispatch({ type: "CLINIC_LOADING", payload: false });

      if (!response) {
        return callback();
      } else if (response?.status) {
        dispatch({ type: "GET_ALL_CLINIC", payload: response?.response_data });
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};

/**
 *
 * @param {String} clinic_id
 * @param {Object} params
 * @returns
 */
export const getAllClinics = (params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    //dispatch({ type: "CLINIC_LOADING", payload: true });
    ApiClient.get(
      `${apiUrl}${PORT}/clinic/get-all`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      //dispatch({ type: "CLINIC_LOADING", payload: false });

      if (!response) {
        return callback();
      } else if (response?.status) {
        // dispatch({
        //   type: "GET_ALL_CLINIC_SEARCH",
        //   payload: response?.response_data,
        // });
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};

export const getAllHospitals = (params) => async (dispatch, getState) => {
  // dispatch({ type: "CLINIC_LOADING", payload: true });
  const {
    user: { token },
  } = getState();
  const response = await ApiClient.post(
    `${apiUrl}${PORT}/clinic/get-all-master-clinics`,
    params,
    token,
    dispatch, getState
  );
  // dispatch({ type: "CLINIC_LOADING", payload: false });
  return response;
};

/**
 *
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */
export const getHospitalDetailsById = (id, params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    dispatch({ type: "CLINIC_LOADING", payload: true });
    ApiClient.get(
      `${apiUrl}${PORT}/clinic/get-single-clinic/${id}`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      dispatch({ type: "CLINIC_LOADING", payload: false });

      if (!response) {
        return callback();
      } else if (response?.status) {
        dispatch({ type: "GET_CLINIC", payload: response?.response_data?.[0] });
        return callback(response);
      } else {
        return callback(response);
      }
    });
  };
};

export const deleteHospitalById = (id) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  return await ApiClient.put(
    `${apiUrl}${PORT}/clinic/delete`,
    id,
    token,
    dispatch, getState
  );
};

export const addClinic = (payload) => async (dispatch, getState) => {
  console.log("addClinicpayload", payload);
  const {
    user: { token },
  } = getState();
  const response = await ApiClient.post(
    `${apiUrl}${PORT}/clinic/add-new`,
    payload,
    token,
    dispatch, getState
  );
  if (!response?.status && response?.message) {
    //toast.error(response.message, toasterConfig);
  }
  return response;
};

export const editClinic = (payload) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  const response = await ApiClient.put(
    `${apiUrl}${PORT}/clinic/update`,
    payload,
    token,
    dispatch, getState
  );
  if (!response?.status && response?.message) {
    //toast.error(response.message, toasterConfig);
  }
  return response;
};
export const DeleteClinic = (id) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  return await ApiClient.put(
    `${apiUrl}${PORT}/clinic/delete`,
    id,
    token,
    dispatch, getState
  );
};
export const getSingleClinicDetails =
  (clinicID, callback) => async (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    return await ApiClient.get(
      `${apiUrl}${PORT}/clinic/get-single-clinic/${clinicID}`,
      {},
      token,
      dispatch, getState
    );
  };

export const getAllClinicStaff = (payload) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  return await ApiClient.post(
    `${apiUrl}${PORT}/staff-management/get-all-staff-management-super-admin/${payload.clinicID}`,
    payload,
    token,
    dispatch, getState
  );
};
export const DeleteClinicStaff = (id) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  return await ApiClient.put(
    `${apiUrl}${PORT}/staff-management/user-delete-by-super-admin`,
    id,
    token,
    dispatch, getState
  );
};
export const addClinicStaff = (payload) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  return await ApiClient.post(
    `${apiUrl}${PORT}/staff-management/user-add-by-super-admin`,
    payload,
    token,
    dispatch, getState
  );
};

export const editClinicStaff = (payload) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  return await ApiClient.put(
    `${apiUrl}${PORT}/staff-management/user-update-by-super-admin/${payload.staffID}`,
    payload,
    token,
    dispatch, getState
  );
};
export const editClinicLogo =
  (clinicID, payload) => async (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    const response = await ApiClient._putFormData(
      `${apiUrl}${PORT}/clinic-settings/update-brand-logo/${clinicID}`,
      payload,
      token,
      dispatch, getState
    );
    if (!response?.status && response?.message) {
      //toast.error(response.message, toasterConfig);
    }
    return response;
  };
export const getAllSubClinic =
  (clinicID, payload) => async (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    return await ApiClient.get(
      `${apiUrl}${PORT}/clinic/get-sub-clinic/${clinicID}?${payload}`,
      payload,
      token,
      dispatch, getState
    );
  };
export const getAllUserClinic = () => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  return await ApiClient.get(
    `${apiUrl}${PORT}/clinic/get-user-clinics`,
    {},
    token,
    dispatch, getState
  );
};

export const getrGeneralInfo =
  (clinicID, payload) => async (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    const response = await ApiClient.put(
      `${apiUrl}${PORT}/clinic-settings/update-general-info/${clinicID}`,
      payload,
      token,
      dispatch, getState
    );
    if (!response?.status && response?.message) {
      //toast.error(response.message, toasterConfig);
    }
    return response;
  };

export const updateGeneralInfo = (payload) => async (dispatch, getState) => {
  try {
    const {
      user: { token },
    } = getState();
    const response = await ApiClient.put(
      `${apiUrl}${PORT}/clinic-settings/update-general-info/`,
      payload,
      token,
      dispatch, getState
    );

    if (!response?.status && response?.message) {
      // Handle the error, for example, display an error message to the user
      console.error(response.message);
    }

    return response;
  } catch (error) {
    console.error("Error in updateGeneralInfo action:", error);
    throw error; // Rethrow the error to let the calling code handle it
  }
};

export const updateLegalInformation =
  (payload) => async (dispatch, getState) => {
    try {
      const {
        user: { token },
      } = getState();
      const response = await ApiClient.put(
        `${apiUrl}${PORT}/clinic-settings/update`,
        payload,
        token,
        dispatch, getState
      );

      if (!response?.status && response?.message) {
        // Handle the error, for example, display an error message to the user
        console.error(response.message);
      }

      return response;
    } catch (error) {
      console.error("Error in updateGeneralInfo action:", error);
      throw error; // Rethrow the error to let the calling code handle it
    }
  };
