const initState = {
  loading: true,
  clinic_details: false,
  clinics: [],
  clinicList: [],
  primaryClinic: "",
};

export const clinicReducer = (state = initState, action) => {
  switch (action.type) {
    case "CLINIC_LOADING":
      return { ...state, loading: action.payload };

    case "GET_CLINIC":
      return { ...state, clinic_details: action.payload };

    case "GET_ALL_CLINIC":
      return { ...state, clinics: action.payload };

    case "GET_ALL_HOSPITAL":
      return { ...state, clinics: action.payload };

    case "SET_CLINIC_LIST":
      return { ...state, clinicList: action.payload };

    case "SET_PRIMARY_CLINIC":
      return { ...state, primaryClinic: action.payload };
    case "LOGOUT":
      return initState;

    default:
      return state;
  }
};
