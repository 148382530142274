import React from "react";
import { Button } from "reactstrap";
import moment from "moment";
import { dayTimeSpan } from "../../../../utils/calendar/constant";

const DayData = ({ columns, data, dateVal, setBoarding }) => {
  const findKennlColumn = (name) => {
    const idx = columns.findIndex((data) => data === name);
    return idx + 1;
  };
  return (
    <>
      <div className="overflow-auto">
        <div
          className={`overflow-auto min-w-screen relative grid grid-rows-[auto,repeat(10,120px)] max-h-[85vh]`}
          style={{
            gridTemplateColumns: `70px repeat(${columns.length}, minmax(150px, 1fr))`,
            gridTemplateRows: `auto repeat(${dayTimeSpan.length}, 120px)`,
          }}
        >
          {columns.map((d, i) => (
            <React.Fragment key={d}>
              {i === 0 && (
                <div className="row-start-[1] col-start-[1] border-slate-200 border-r p-1.5 sticky left-0 bg-white"></div>
              )}
              <h6
                className={`row-start-[1] col-start-[${
                  i + 2
                }] sticky top-0 bg-white border-b border-r px-2.5 capitalize text-base xl:text-base font-medium py-2`}
              >
                {d}
              </h6>
            </React.Fragment>
          ))}
          {dayTimeSpan.map((t, i) => (
            <React.Fragment key={t.name + t.value}>
              {i === 0 && (
                <div className="row-start-[1] col-start-[1] place-self-center p-1.5 sticky z-10 left-0 top-0 bg-white">
                  <Button color="link" className="d-inline-flex syn-btn p-0">
                    <span className="material-symbols-outlined">sync</span>
                  </Button>
                </div>
              )}
              <div
                className={`row-start-[${
                  i + 2
                }] col-start-[1] text-center border-slate-200 border-r text-sm xl:text-base p-1.5  text-slate-400 uppercase sticky left-0 bg-white font-medium`}
              >
                {t.value}
                <br /> {t.name}
              </div>
            </React.Fragment>
          ))}
          {dayTimeSpan.map((_, i) =>
            columns.map((_, j) => (
              <div
                key={i + Date.now() + Math.random()}
                className={`border-slate-200 border-r border-b`}
                style={{
                  gridRowStart: `${i + 2}`,
                  gridColumnStart: `${j + 2}`,
                }}
              ></div>
            ))
          )}
          {data?.map((b, i) => {
            const startHour = moment(dateVal).isSame(b?.boarding_start_date, 'day') ?  moment(b?.boarding_start_date).hours() : 0
            const endHour = moment(dateVal).isSame(b?.boarding_end_date, 'day') ? moment(b?.boarding_end_date).hours(): 24
            const startRow = startHour + 2;
            const rowSpan = endHour - startHour;

            const column = findKennlColumn(b?.kennel?.kennel_name);

            return (
              <div
                onClick={() => setBoarding(b)}
                key={i + Date.now() + Math.random()}
                className={`row-span-[${rowSpan}] bg-gray-100 border rounded-lg m-1 p-1 overflow-hidden `}
                style={{
                  gridRowStart: `${startRow}`,
                  gridColumnStart: `${column + 1}`,
                  gridRowEnd: `span ${rowSpan}`,
                }}
              >
                <BoardingCard obj={b} />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default DayData;

const PetImage = ({ src }) => {
  return (
    <img
      src={src}
      alt="pet-img"
      className="w-14 h-full max-h-[100px] object-cover rounded-md"
    />
  );
};

const PetName = ({ name }) => {
  return (
    <p className="capitalize font-semibold text-sm mb-2 md:text-base xl:text-lg">
      {name}
    </p>
  );
};

const PetGender = ({ gender }) => {
  return (
    <p className="capitalize text-xs lg:text-sm xl:text-base mb-2">{gender}</p>
  );
};

const PetTypeAndBreed = ({ type, breed }) => {
  return (
    <p className="text-xs mb-2">
      {type} {breed && <span>({breed})</span>}
    </p>
  );
};

const PetPurpose = ({ purpose }) => {
  return <p className="text-sm font-medium mb-2">{purpose}</p>;
};

const ExpectedDischargeDate = ({ expectedDischargeDate }) => {
  return (
    <p className="text-sm mb-2">
      Expected Discharge date
      <br />
      <span className="mt-1">
        {/* {new Date(expectedDischargeDate).toLocaleDateString()} */}
        {expectedDischargeDate?.replace(/-/g, "/")}
      </span>
    </p>
  );
};

const BoardingCard = ({ obj }) => {
  return (
    <div className="flex flex-col items-center text-center">
      <PetImage src={obj.pet_image} />
      <PetName name={obj.pet_name} />
      {obj.gender && <PetGender gender={obj.gender} />}
      {obj.type && <PetTypeAndBreed type={obj.type} breed={obj.breed} />}
      {obj.boarding_purpose && <PetPurpose purpose={obj.boarding_purpose} />}
      {obj.boarding_date && (
        <ExpectedDischargeDate expectedDischargeDate={obj?.boarding_end_date} />
      )}
    </div>
  );
};
