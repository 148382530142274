import React, { useState } from "react";
import Modal from "../../../../shared/Modal";
import { useSelector } from "react-redux";

const AddData = ({ list, setList }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const addData = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    const formData = Object.fromEntries(form.entries());
    const { name, code, amount, description } = formData;

    if (!name || !code || !amount) {
      alert("Please complete all fields!");
      return;
    }

    setList((prev) => [...prev, { name, code, amount, description }]);
    handleCloseModal();
  };

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  return (
    <>
      {permissions?.edit_services &&
        <button
          onClick={handleOpenModal}
          className="absolute top-3 right-3 text-white bg-green-500 rounded-full p-0.5"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6v12m6-6H6"
            />
          </svg>
        </button>
      }
      <Modal isOpen={isOpen} onClose={handleCloseModal} showCloseButton={true}>
        <form onSubmit={addData} className="p-3 md:p-5 space-y-4">
          <h4 className="text-2xl">Add New Service</h4>
          <div className="space-y-1">
            <label className="text-sm text-gray-500">Service Name</label>
            <input
              placeholder="Add Service Name"
              name="name"
              className="bg-gray-100 w-full rounded-md px-2.5 py-1.5 placeholder:text-sm"
            />
          </div>
          <div className="space-y-1">
            <label className="text-sm text-gray-500">Service Code</label>
            <input
              placeholder="Add Service Code"
              name="code"
              className="bg-gray-100 w-full rounded-md px-2.5 py-1.5 placeholder:text-sm"
            />
          </div>
          <div className="space-y-1">
            <label className="text-sm text-gray-500">Service Amount</label>
            <input
              placeholder="Add Service Price"
              name="amount"
              className="bg-gray-100 w-full rounded-md px-2.5 py-1.5 placeholder:text-sm"
            />
          </div>
          <div className="space-y-1">
            <label className="text-sm text-gray-500">Description</label>
            <textarea
              name="description"
              rows={4}
              className="resize-none w-full bg-gray-100 rounded-md px-2.5 py-1.5"
            ></textarea>
          </div>
          <div className="flex gap-x-2 items-center">
            <button
              type="reset"
              onClick={handleCloseModal}
              className="w-1/2 bg-gray-100 hover:bg-gray-200 py-2 rounded-md"
            >
              Discard
            </button>
            <button
              type="submit"
              className="w-1/2 bg-indigo-500 text-white py-2 rounded-md"
            >
              Add Data
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddData;
