import React, { useEffect } from "react";
import moment from "moment";
import TimeCell2 from "../TimeCell2";

function checkInBetween(time_to_compare, time_one, time_two) {
  time_to_compare = `01/01/1960 ${time_to_compare}`;
  time_one = `01/01/1960 ${time_one}`;
  time_two = `01/01/1960 ${time_two}`;

  time_to_compare = new Date(time_to_compare).getTime();
  time_one = new Date(time_one).getTime();
  time_two = new Date(time_two).getTime();

  return time_one <= time_to_compare && time_to_compare < time_two;
}
const TimeCellWrapper = ({
  doctor,
  selectedApptSlots,
  selectedDoctor,
  selectedApptDate,
  handleSlotTiming,
  NoTimeSlot,
  appointmentSlotDuration,
  overrideToggle,
}) => {
  const slotsTime = doctor?.availability?.slots_time || [];

  let slots = [];

  for (let time of slotsTime) {
    let start_time = moment(
      doctor.availability?.date + " " + time.start_time,
      "DD/MM/YYYY h:mm a"
    );
    let end_time = moment(
      doctor.availability?.date + " " + time.end_time,
      "DD/MM/YYYY h:mm a"
    );

    var loop = start_time;
    while (loop < end_time) {
      slots.push(loop.format("h:mm a"));
      loop = moment(loop).add(
        appointmentSlotDuration ? +appointmentSlotDuration : 30,
        "minutes"
      );
    }
  }
  //   console.log("SLOTS 22---------", slots);
  return (
    <div className="time-cell-wrapper d-flex flex-wrap">
      {doctor?.availability ? (
        slots.map((slot, i) => {
          let findAny =
            selectedApptSlots.includes(slot) &&
            doctor._id == selectedDoctor._id;
          return (
            <TimeCell2
              className={findAny ? "selected-button" : ""}
              key={i}
              slot={slot}
              // disabled={(doctor?.availability?.slots_time?.length - 1) === i ? true : (doctor?.appointments.filter(appointment => appointment?.appointment_date === selectedApptDate && appointment?.slot_time === slot).length > 0 ? true : false)}

              disabled={
                // slots?.length - 1 === i
                //   ? true
                //   :
                !overrideToggle
                  ? doctor?.appointments.filter((appointment) => {
                      return appointment.appointment_timings.filter(
                        (timing) => {
                          return (
                            timing.appointment_date == selectedApptDate &&
                            // timing.slot_time === slot
                            checkInBetween(timing.slot_time, slot, slots[i + 1])
                          );
                        }
                      ).length > 0
                        ? true
                        : false;
                    }).length > 0
                    ? true
                    : false
                  : false
              }
              onClick={() => {
                // if (!findAny) {
                // 	setSelectedApptSlots((prevState) => [...prevState, slot])
                // }
                // else {
                // 	let filtered = selectedApptSlots.filter((ele) => ele != slot)
                // 	setSelectedApptSlots(filtered)
                // }
                handleSlotTiming(doctor, slot);
              }}
            />
          );
        })
      ) : (
        <NoTimeSlot />
      )}
    </div>
  );
};

export default TimeCellWrapper;
