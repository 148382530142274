import React from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import UserPlaceholder from "../../../../../../shared/UserPlaceholder";
import { dateFromDateTime } from "../../../../../../utils/common";
import "./index.scss";

const MedicationPlanItem = ({ item }) => {
  // let colors = {
  // 	yellow: "#e0ce2a",
  // 	grey: "#6a6b6a",
  // 	green: "#00ba41"
  // }
  // const getColorAccordingToStatus = (status) => {
  // 	if (status === "stopped") {
  // 		return colors.grey;
  // 	} else if (status === "completed") {
  // 		return colors.green;
  // 	} else {
  // 		return colors.yellow
  // 	}
  // }

  function getDateRange(first_dose, duration_num, duration_unit) {
    // Parse the start date
    const startDate = new Date(first_dose);

    // Calculate the end date based on duration_unit and duration_num
    const endDate = new Date(startDate);
    switch (duration_unit) {
      case "Days":
        endDate.setDate(startDate.getDate() + duration_num);
        break;
      case "Weeks":
        endDate.setDate(startDate.getDate() + 7 * duration_num);
        break;
      case "Months":
        endDate.setMonth(startDate.getMonth() + duration_num);
        break;
      case "Years":
        endDate.setFullYear(startDate.getFullYear() + duration_num);
        break;
      default:
        break;
    }

    return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
  }

  return (
    <>
      <div className="medication-plan-item">
        <div className="head">
          <h2 style={{ textTransform: "capitalize" }}>
            {item?.item_details?.product_name}
          </h2>
          <h3>
            {item && (
              <span>
                {getDateRange(
                  item?.first_dose,
                  item?.duration_num,
                  item?.duration_unit
                )}
              </span>
            )}
          </h3>
        </div>
        <div className="chart-blc d-flex align-items-center">
          <div className="chart-item">
            <CircularProgressbarWithChildren
              styles={buildStyles({
                rotation: 0.5,
                pathColor: "#E0CE2A",
                textColor: "#f88",
                trailColor: "inherit",
              })}
              strokeWidth={10}
              value={75}
            >
              <div
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  padding: 7,
                  backgroundColor: "#ececec",
                  borderRadius: 50,
                }}
              >
                75%
              </div>
            </CircularProgressbarWithChildren>
            {/* <img className="img-fluid w-100 h-100" src={require('../../../../../../../assets/img/vetRecorEMR/chart-percentage.png')} alt="Chart" /> */}
          </div>
          Active
        </div>
        <div className="treat-by-cell d-inline-flex align-items-center">
          <div className="pic d-inline-flex rounded-circle overflow-hidden flex-shrink-0">
            <img
              className="img-fluid w-100 h-100"
              src={item?.created_by?.user_image}
              alt="User"
            />
            <UserPlaceholder />
          </div>
          <div className="txt">
            {item?.created_by?.firstname} {item?.created_by?.lastname}
          </div>
          <div className="icon d-inline-flex">
            <span className="material-symbols-outlined">auto_mode</span>
          </div>
        </div>
      </div>

      {/* <div className="medication-plan-item">
				<div className="head">
					<h2 style={{ textTransform: "capitalize" }}>{item?.plan_title}</h2>
					<h3>{`${dateFromDateTime(item?.start_date, "MM/DD/yyyy")} - ${dateFromDateTime(item?.end_date, "MM/DD/yyyy")}`}</h3>
				</div>
				<div className="chart-blc d-flex align-items-center">
					<div className="chart-item">
						<CircularProgressbarWithChildren
							styles={buildStyles({
								rotation: 0.50,
								pathColor: getColorAccordingToStatus(item.status),
								textColor: '#f88',
								trailColor: 'inherit',
							})}
							strokeWidth={10} value={item?.status_percentage}>
							<div style={{
								fontSize: 10,
								fontWeight: "bold",
								padding: 7,
								backgroundColor: "#ececec",
								borderRadius: 50
							}}>
								{item?.status_percentage}%
							</div>
						</CircularProgressbarWithChildren>
						<img className="img-fluid w-100 h-100" src={require('../../../../../../../assets/img/vetRecorEMR/chart-percentage.png')} alt="Chart" /> */}
      {/* </div >
			{ item?.status }
				</div >
	<div className="treat-by-cell d-inline-flex align-items-center">
		<div className="pic d-inline-flex rounded-circle overflow-hidden flex-shrink-0">
			{item?.mentoringBy?.user_image ? <img className="img-fluid w-100 h-100" src={item?.mentoringBy?.user_image || require('../../../../../../../assets/img/vetRecorEMR/user-thumbnail.png')} alt="User" /> :
				<UserPlaceholder />}
		</div>
		<div className="txt">{`${item?.mentoringBy?.title ? item?.mentoringBy?.title + ' ' : ''} ${item?.mentoringBy?.firstname} ${item?.mentoringBy?.lastname}`}</div>
		<div className="icon d-inline-flex">
			<span className="material-symbols-outlined">
				auto_mode
			</span>
		</div>
	</div>
			</div > * /} */}

      {/* <div className="medication-plan-item">
				<div className="head">
					<h2>[Medication Plan]</h2>
					<h3>[Start Date - End Date]</h3>
				</div>
				<div className="chart-blc d-flex align-items-center">
					<div className="chart-item">
						<img className="img-fluid w-100 h-100" src={require('../../../../../../../assets/img/vetRecorEMR/chart-percentage.png')} alt="Chart" />
					</div>
					Active
				</div>
				<div className="treat-by-cell d-flex align-items-center">
					<div className="pic d-inline-flex rounded-circle overflow-hidden flex-shrink-0">
						<img className="img-fluid w-100 h-100" src={require('../../../../../../../assets/img/vetRecorEMR/user-thumbnail.png')} alt="User" />
						<UserPlaceholder />
					</div>
					<div className="txt flex-fill">Dr. Brant Hillton (DVM)</div>
					<div className="icon d-inline-flex">
						<span className="material-symbols-outlined">
							auto_mode
						</span>
					</div>
				</div>
			</div> */}
    </>
  );
};

export default MedicationPlanItem;
