import React, { useState } from "react";
import { Button } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../../context/actions/user";
import Head from "../Head";
import { Formik, Form } from "formik";
import { forgotPasswordSchema, forgotPasswordInit } from "./schema";
import EmailInput from "../../../shared/FormsInput/EmailInput";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import AppLogo from "../../../shared/AppLogo";

const ForgotPassword = () => {
	const dispatch = useDispatch();
	const [disabled, setDisabled] = useState(false);

	/**
	 * @description method call to submit valid form
	 * @param {Object} params form params 
	 * @param {Instance} status form status
	 */
	const submitForm = (params) => {
		setDisabled(true)
		dispatch(
			forgotPassword(params, (result) => {
				setDisabled(false);
			})
		);
	}

	return (
		<>
			<div className="col-blc left-col position-relative overflow-auto">
				<div className="head">
					<div className="logo-rw">
						<AppLogo />
					</div>
					<h1>
						<span className="txt-1">Discover </span>
						<span className="txt-2">the world’s <span>Contra to popular Ipsum simple</span></span>
					</h1>
				</div>
				<div className="bg position-absolute">
					<img className="img-fluid" src={require('../../../../src/assets/img/vetRecorEMR/login-pic-txt.png')} alt="" />
				</div>
			</div>
			<div className="col-blc right-col">
				<div className="overflow-auto align-self-stretch">
					<div className="login-blc m-auto signup-box">
						<h3 className="inner_link text-right">
							<Link to="/login">
								<svg className="ml-auto" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M0.742188 11.6562C0.414062 11.9844 0.398438 12.5703 0.75 12.9141C1.09375 13.2578 1.67969 13.25 2.00781 12.9219L6.78906 8.13281L11.5781 12.9219C11.9141 13.2578 12.4922 13.2578 12.8359 12.9141C13.1719 12.5625 13.1797 11.9922 12.8359 11.6562L8.05469 6.86719L12.8359 2.08594C13.1797 1.75 13.1797 1.17188 12.8359 0.828125C12.4844 0.492188 11.9141 0.484375 11.5781 0.820312L6.78906 5.60938L2.00781 0.820312C1.67969 0.492188 1.08594 0.476562 0.75 0.828125C0.40625 1.17188 0.414062 1.75781 0.742188 2.08594L5.53125 6.86719L0.742188 11.6562Z" fill="#404D61" />
								</svg>
							</Link>
						</h3>
						<div className="signup-head">
							<h2>Forgot password?</h2>
							<h3>Enter the email address you used when you joined and <span>we’ll send you instructions to reset your password.</span></h3>
						</div>
						<Formik
							initialValues={forgotPasswordInit}
							enableReinitialize
							validationSchema={forgotPasswordSchema}
							onSubmit={submitForm}
						>
							{(formik) => (<Form >
								<EmailInput
									name="email"
									type="text"
									placeholder="Enter email address"
									id="email"
									value={formik?.values?.email}
									onChange={formik.handleChange}
									icon={'icon'}
									required={true}
									className="email"
								/>
								<div className="btn-cell d-flex">
									<Button
										disabled={disabled}
										color="primary"
									>
										Reset Password
									</Button>
								</div>
							</Form>)}
						</Formik>
					</div>
				</div>
			</div>
		</>
	);
};

export default ForgotPassword;