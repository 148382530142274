import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from 'reactstrap';
import { sidebarPanelAction } from "../../context/actions/sidebarPanelAction";
import Disabled from "../../shared/Disabled";
import PageHeader from "../../shared/PageHeader";


const WellnessPlans = () => {

    const dispatch = useDispatch();
	const sidebarPanelState = useSelector(state => state.sidebarPanelReducer.isSidePanel);

	const showSidePanel = () => {
		dispatch(sidebarPanelAction(!sidebarPanelState))
	}

    return (
        <>
			<div className="page-wrapper position-relative d-flex flex-column flex-fill overflow-auto">
				<div className="page-header-wrapper d-flex align-items-center flex-shrink-0 right-column-visible">
					<Button
						className="sidebar-toggle-btn d-inline-flex align-items-center"
						color="link"
						onClick={showSidePanel}>
						<span className="material-symbols-outlined">
							menu
						</span>
					</Button>
					<div className="row-block d-flex align-items-center flex-fill">
						<PageHeader className='dashboard-head flex-flex-shrink-0' title="Wellness Plans"></PageHeader>
					</div>
				</div>
                <div className="page-content d-flex flex-column flex-fill overflow-auto">
                    <Disabled />

                </div>
            </div>
        </>
    )
    }
    export default WellnessPlans;