import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";
import { getFormAndTemplates } from "./formTemplates";

toast.configure();
/**
 * All Api endpoints to be call
 * Get -- /app-content/get-roles
*/

/**
 * 
 * @param {Object} body
 * @param {Function} clinic_id
 * @returns 
 */
export const updatePatientNotesTemplate = (body, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, clinicReducer: { clinic_details } } = getState();
        if (clinic_details) {
            ApiClient.put(`${apiUrl}${PORT}/patient-notes-template/update`, body, token, dispatch, getState).then((response) => {

                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    dispatch(getFormAndTemplates({}, () => { }))
                    toast.success(response.message, toasterConfig);
                    return callback(response);
                } else {
                    //toast.error(response.message, toasterConfig);
                    return callback(response);
                }
            })
        }
    };
};



/**
 * 
 * @param {Object} params
 * @param {Fuction} callback
 * @returns 
 */
export const createTemplate = (body, callback) => {
    return (dispatch, getState) => {
        const { user: { token, user_details}, clinicReducer: { clinic_details } } = getState();
        if ( clinic_details) {
            body.clinic_id = clinic_details?._id;
            body.created_by = user_details?._id;
            ApiClient.post(`${apiUrl}${PORT}/patient-notes-template/create/`, body, token, dispatch, getState).then((response) => {
                
            if (!response) {
                return callback();
            }
            else if (!response && response.errors) {
                toast.error(response.errors.msg, toasterConfig);
                return callback(response);
            } else if (response) {
                toast.dismiss();
                toast.success(response.msg, toasterConfig);
                dispatch(getFormAndTemplates({}, () => { }))
                return callback(response);
            } else {
                //toast.error(response.message, toasterConfig);
                return callback();
            }
        })
    }
    }
};









