import React, { useEffect, useState } from "react";
import FilterBlock from "./FilterBlock";

import { useDispatch, useSelector } from "react-redux";

import { getAllAppointmentsByPatientId } from "../../../../../context/actions/appointment";
import AppointmentDetailsPanel from "../../../../../shared/Calendar/AppointmentDetailsPanel";
import NodataFoundBlock from "../../../../../shared/NoDataFoundBlock";
import moment from "moment";
import LoaderUI from "../../../../../shared/loader";
import { getPetDetailsById } from "../../../../../context/actions/patient-module/patient/pet";
import {
  setAppointmentDetailPanel,
  setAppointmentDetailData,
} from "../../../../../context/actions/appointmentDetailPanelAction";

import UpcomingVisitCard from "./UpcomingVisitCard";
import "./index.scss";
import PastVisitCard from "./PastVisitCard";
import { getAllAppointmentsOfPetOwner } from "../../../../../context/actions/pet-owner-dashboard";
import { getAppointmentDischargePatientDetails } from "../../../../../context/actions/patient-module/discharge-patient-form";
import Avs from "../../../Avs";

const Visits = ({ petId }) => {
  const [loading, setLoading] = useState(true);
  const [appointments, setAppointments] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const [past, setPast] = useState([]);
  const dispatch = useDispatch();

  const { ownerAndPetDetails } = useSelector(
    (state) => state.petOwnerDashboardReducer
  );

  const [showAvs, setShowAvs] = useState(false);

  // const appointmentDetailsState = useSelector(
  //   (state) => state.appointmentDetailPanelReducer.isAppointmentDetailPanel
  // );

  // const appointmentDetailPanelState = useSelector(
  //   (state) => state.appointmentDetailPanelReducer.isAppointmentDetailPanel
  // );

  const showAppointmentDetails = (appointmentData) => {
    console.log("appointment details", appointmentData);

    // dispatch(setAppointmentDetailData(appointmentData));
    dispatch(
      getAppointmentDischargePatientDetails(
        {
          appointment_id: appointmentData._id,
        },
        (resp) => {
          resp && resp.status ? setShowAvs(true) : setShowAvs(false);
        }
      )
    );
    //dispatch(getPetDetailsById(appointmentData?.patient_id?._id, {},  () => { }));
    // dispatch(setAppointmentDetailPanel(!appointmentDetailsState));
  };

  let beforeToday = (item) => {
    let date = moment(item.appointment_date, "DD-MM-YYYY");
    let today = moment();
    return date.isBefore(today);
  };

  let afterToday = (item) => {
    let date = moment(item.appointment_date, "DD-MM-YYYY");
    let today = moment();
    return date.isSameOrAfter(today);
  };

  const handleGetAppointments = async () => {
    if (ownerAndPetDetails) {
      let pet_ids = ownerAndPetDetails.pet_details.map((ele) => ele._id);

      dispatch(
        getAllAppointmentsOfPetOwner(
          {
            patient_id: pet_ids,
            //added_by: ownerAndPetDetails.added_by,
          },
          (response) => {
            if (response && response.status) {
              setAppointments(response.response_data);
              let up = response.response_data.filter(afterToday);
              let pa = response.response_data.filter(beforeToday);
              setUpcoming(up);
              setPast(pa);
            }
          }
        )
      );
    }
  };

  useEffect(() => {
    if (ownerAndPetDetails) {
      handleGetAppointments();
      // dispatch(setAppointmentDetailPanel(false));
    }
  }, [ownerAndPetDetails]);

  //   useEffect(() => {
  //     if (!appointmentDetailPanelState) {
  //       handleGetAppointments();
  //     }
  //   }, [appointmentDetailPanelState]);

  return (
    <>
      <div className="visits-wrapper">
        <FilterBlock />
        {appointments.length == 0 ? (
          <NodataFoundBlock />
        ) : (
          <>
            {upcoming.length != 0 && (
              <div className="upcoming-visits">
                <div className="head">
                  <span>Next Upcoming This Week</span>
                </div>
                <div className="body">
                  {upcoming.map((item, i) => {
                    return (
                      <UpcomingVisitCard
                        key={i}
                        onClick={() => {
                          showAppointmentDetails(item);
                        }}
                        data={item}
                      />
                    );
                  })}
                </div>
              </div>
            )}

            {past.length != 0 && (
              <div className="past-visits">
                <div className="head">
                  <span>Past Visits</span>
                </div>
                <div className="body h-100 pb-3">
                  {past.map((item, i) => {
                    return (
                      <PastVisitCard
                        onClick={() => {
                          showAppointmentDetails(item);
                        }}
                        data={item}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {/*<Avs show={showAvs} handleClose={() => setShowAvs(false)} />*/}

      {/* <div
        className={`patient-panel-overlay position-fixed ${
          appointmentDetailPanelState ? "active" : ""
        }`}
        onClick={() => dispatch(setAppointmentDetailPanel(false))}
      ></div>
      <AppointmentDetailsPanel
        className={`${appointmentDetailPanelState ? "active" : ""}`}
      /> */}
    </>
  );
};

export default Visits;
