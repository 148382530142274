import React, { useState, useEffect } from 'react'
import { Offcanvas, Spinner } from 'react-bootstrap'
import './index.scss';
import { Button, Input, InputGroup } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import PasswordInput from '../../../../../shared/FormsInput/PasswordInput';
import { addIntegrationCredential } from 'context/actions/clinicSettings';
import { loginSchema, loginInit } from './schema';
import { Formik, Form } from 'formik';

const AddApp = ({ show, handleClose, app }) => {


    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false);




    const dispatch = useDispatch();
    const { clinic_details } = useSelector(state => state.clinicReducer);

    const submitForm = (data) => {

        setLoading(true)

        Object.keys(data)?.map((item) => {
             if(data[item] == null || data[item] == undefined || data[item] == '') {
                delete data[item]
             }
            }
         )

        dispatch(addIntegrationCredential({app: app, ...data}, (response) => {
            setLoading(false);
            if(response?.status){
                handleClose();
            }
        }));



    }

   

    return (

        <Offcanvas show={show} onHide={handleClose} placement="end" className="select-group-list addnewrole gap-[20px]">
            <Offcanvas.Header closeButton className="">
                <Offcanvas.Title>Add {app} Integration</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="px-2 pb-2 pt-0">
                <div className="d-flex  flex-column h-100">
                <Formik
							initialValues={loginInit}
							enableReinitialize
							validationSchema={loginSchema}
							onSubmit={submitForm}
						>
							{(formik) => (<Form style={{gap: '20px', display:'flex', flexDirection:'column'}}>
								<Input
									name="username"
									type="text"
									placeholder="Enter Username"
									id="username"
									value={formik?.values?.username}
									onChange={formik.handleChange}
									icon={'icon'}
									required={true}
									className="username"
								/>
								<PasswordInput
									type="password"
									name="password"
									placeholder="Enter password"
									id="password"
									value={formik?.values?.password}
									onChange={formik.handleChange}
									icon={'icon'}
									required={true}
								/>

                                {app === 'Idexx' &&
                                
                                <>

                                <div className="item-rw d-flex">
                                    <div className="form-check form-switch">
                                        <input name="partial_results" onChange={formik.handleChange} checked={formik?.values?.partial_results} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                    </div>
                                    <div className="txt">
                                        <h4>Allow Partial Result</h4>
                                    </div>
                                </div>

                                <div className="item-rw d-flex">
                                    <div className="form-check form-switch">
                                        <input name="view_pdf" onChange={e => formik.setFieldValue('view_pdf', e.target.checked)} checked={formik?.values?.view_pdf} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                    </div>
                                    <div className="txt">
                                        <h4>View Result PDF</h4>
                                    </div>
                                </div>


                                <label>Fetch Results Every:</label>

                                <InputGroup>

                                    <Input
                                        name="frequency_value"
                                        value={formik?.values?.frequency_value}
                                        placeholder="Enter Frequency"
                                        onChange={formik.handleChange}
                                        type="Number"
                                    />
                                    <Input
                                        placeholder="Select Frequency Unit"
                                        onChange={formik.handleChange}
                                        name={'frequency_unit'}
                                        value={formik?.values?.frequency_unit}
                                        type="select"
                                        >
                                        <option value="seconds">seconds</option>
                                        <option value="minutes">minutes</option>
                                    </Input>
                                </InputGroup>
                                

                                </>


                                }


                                {app === 'Antech' &&       

                                <>
                                
                                <label>Antech Clinic Id:</label>

                                <Input
                                name="ClinicID"
                                value={formik?.values?.ClinicID}
                                placeholder="Enter Clinic Id"
                                onChange={formik.handleChange}
                                type="String"
                                />
                                </>}


								<div className="btn-cell d-flex">
									<Button
										disabled={disabled}
										color="primary"
                                        type="submit"
									>
										Authenticate {loading && <Spinner size="sm" className="ms-1" />}
									</Button>
								</div>
							</Form>)}
						</Formik>

                </div>
            </Offcanvas.Body>
        </Offcanvas>

    )
}

export default AddApp;