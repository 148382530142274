import React from "react";
import AddSlotButton from "../../../../shared/Calendar/AddSlotButton";
import SlotItemName from "./SlotItemName";
import "./index.scss";
import { useEffect } from "react";
import { getDaysForCalendarMonth } from "../../../../utils/calendar/constant";
import { useState } from "react";
import LoaderUI from "../../../../shared/loader";
import moment from "moment";

const CalendarMonthView = ({ calendarViewData, appointments, allRooms, setBoarding }) => {
  const [dates, setDates] = useState([]);
  const [loader, showLoader] = useState(false);

  useEffect(() => {
    showLoader(true);
    const date = getDaysForCalendarMonth(
      1,
      calendarViewData.month_value,
      calendarViewData.year_value
    );
    setDates(date);
    showLoader(false);
  }, [calendarViewData]);

  function convertDateFormat(dateString) {
    const parts = dateString.split("-");
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];

    const convertedDate = `${year}-${month}-${day}`;
    return convertedDate;
  }

  return (
    <>
      {loader && <LoaderUI overlay />}
      <div className="calendar-month-view overflow-auto flex-fill">
        <table className="table">
          <thead>
            <tr>
              <th className="text-center">Sunday</th>
              <th className="text-center">Monday</th>
              <th className="text-center">Tuesday</th>
              <th className="text-center">Wednesday</th>
              <th className="text-center">Thursday</th>
              <th className="text-center">Friday</th>
              <th className="text-center">Saturday</th>
            </tr>
          </thead>
          <tbody>
            {dates.length > 0 &&
              dates.map((date, i) => {
                return (
                  <tr key={i}>
                    {date.map((day, i2) => {
                      const hasAppointments =
                        appointments?.filter((data) => {
                    
                          return (
                            moment(day?.full_date, "DD-MM-YYYY").isBetween(data?.boarding_start_date, data?.boarding_end_date, 'day', '[]')
                            /*
                            data.boarding_date ===
                            convertDateFormat(day?.full_date)*/
                          );
                        }).length > 0;
                      return (
                        <td
                          key={i2}
                          className={
                            day?.current_date ? "current-day-cell" : ""
                          }
                        >
                          <div
                            className={`day-no text-end position-absolute ${
                              !day?.is_current_month && "disable"
                            } ${
                              day?.current_date &&
                              "current d-inline-flex align-items-center justify-content-center rounded-circle"
                            }`}
                          >
                            {day?.date}
                          </div>
                          <div className="data-container">
                            {hasAppointments ? (
                              appointments
                                .filter(
                                  (appt) =>
                                  moment(day?.full_date, "DD-MM-YYYY").isBetween(appt?.boarding_start_date, appt?.boarding_end_date, 'day', '[]')
                                  /*
                                    appt.boarding_date ===
                                    convertDateFormat(day?.full_date)*/
                                )
                                .sort((a, b) => {
                                  moment(a?.boarding_start_date).isAfter(b?.boarding_start_date)
                                  /*
                                  const aFrom =
                                    a.boarding_start_time.split(":");
                                  const bFrom =
                                    b.boarding_start_time.split(":");
                                  return aFrom[0] === bFrom[0]
                                    ? aFrom[1] - bFrom[1]
                                    : aFrom[0] - bFrom[0];*/
                                })
                                .map((appt, apptIndex) => (
                                  <SlotItemName
                                    onClick={() => setBoarding(appt)}
                                    key={apptIndex}
                                    boardingData={appt}
                                  />
                                ))
                            ) : (
                              <div className="data-container d-flex n-items-center justify-content-center position-relative">
                                <AddSlotButton />
                              </div>
                            )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CalendarMonthView;
