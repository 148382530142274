import React, { useEffect, useState } from "react";
import { Button, FormFeedback, FormGroup, Input } from "reactstrap";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getPetDetailsById } from "../../../../../../context/actions/patient-module/patient/pet";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { Formik, Form } from "formik";

import "react-datepicker/dist/react-datepicker.css";
import './index.scss';
import { toast } from "react-toastify";
import { toasterConfig } from "../../../../../../utils/constants";


const formObjToSchema = (obj) => {

	var schema = {
		type: "object",
		properties: {

		},
		required: []
	}

	var config = {
		errMessages: {

		}
	}

	Object.keys(obj).map((key, i) => {
		schema.properties[obj[key].val_name] = {
			type: (obj[key].type === 'input' || obj[key].type === 'select' ? 'string' : 'date'),
			//required: (obj[key]?.required || false),
		}
		if (obj[key].required) {
			schema.required = [...schema.required, obj[key].val_name]
			config.errMessages[obj[key].val_name] = { required: `${key} is required` }
		}
	})

	const { buildYup } = require("schema-to-yup");
	const yupSchema = buildYup(schema, config);
	// console.log('yup schema', yupSchema)
	return yupSchema;

}

const initValsFromFormObj = (obj) => {

	var init = {}

	Object.keys(obj).map((key, i) => {
		init[obj[key].val_name] = ''
	})
	return init;

}




const NewPointPanel = ({ className = '', closeNewPointPanel, refresh, addPoint }) => {
	const history = useHistory();
	const [type, setType] = useState('Vitals')
	const dispatch = useDispatch()
	const params = useParams()
	const petId = params.petId;	
	const formInfo = {
		Vitals: {
			['Recorded Date and Time']: { value: '', type: 'date', val_name: 'date', required: true },
			Temperature: { value: '', type: 'input', val_name: 'temperature' },
			['Temperature Method']: { value: '', type: 'input', val_name: 'temperature_method' },
			['Heart Rate']: { value: '', type: 'input', val_name: 'heart_rate' },
			['Heart Rate Method']: { value: '', type: 'input', val_name: 'heart_rate_method' },
			['Cardiac Rhythm']: { value: '', type: 'input', val_name: 'cardiac_rhythm' },
			['Respiratory Rate']: { value: '', type: 'input', val_name: 'respiratory_rate' },
			['Blood Pressure']: { value: '', type: 'input', val_name: 'blood_pressure' },
			['Blood Pressure Location']: { value: '', type: 'input', val_name: 'blood_pressure_location' },
			['Blood Pressure Method']: { value: '', type: 'input', val_name: 'blood_pressure_method' },
			['Cuff Size']: { value: '', type: 'input', val_name: 'cuff_size' },
			['Oxygen Saturation']: { value: '', type: 'input', val_name: 'oxygen_saturation' },
			['SpO2 Location']: { value: '', type: 'input', val_name: 'spo2_location' },
			['Oxygen Flow Rate']: { value: '', type: 'input', val_name: 'oxygen_flow_rate' },
			['Oxygen Saturation']: { value: '', type: 'input', val_name: 'oxygen_saturation' },
			['Oxygen Delivery']: { value: '', type: 'input', val_name: 'oxygen_delivery' },
		},
		['Pain Assessment']: {
			['Recorded Date and Time']: { value: '', type: 'date', val_name: 'date', required: true },
			['Pain Score']: { value: '', type: 'input', val_name: 'pain_score' },
			['Pain Goal']: { value: '', type: 'input', val_name: 'pain_goal' },
			['Pain Location']: { value: '', type: 'input', val_name: 'pain_location' },
			['Pain Reaction']: { value: '', type: 'input', val_name: 'pain_reaction' },
			['Pain Type']: { value: '', type: 'input', val_name: 'pain_type' },
			['Response To Palpatation']: { value: '', type: 'input', val_name: 'response_to_palpation' },
			['Body Tension']: { value: '', type: 'input', val_name: 'body_tension' },
		},
		['Measurements']: {
			['Recorded Date and Time']: { value: '', type: 'date', val_name: 'date', required: true },
			['Weight']: { value: '', type: 'input', val_name: 'weight' },

		}
	}

	const [formVal, setFormVal] = useState(
		{
			['Vitals']: {
			},
			['Pain Assessment']: {
			},
			// Measurements Categories
			['Measurements']: {
			}
		}
	)






	useEffect(() => {

	}, []);

	const handleAddPoint = (values) => {

		Object.keys(values).forEach(key => {
			if (values[key] === '' || values[key] === null) {
				delete values[key];
			} else if (key === 'date') {
				values[key] = new Date(values[key])

			}
		});
		addPoint({ ...values, type: type }, response => {
			console.log("response", response?.status)
			if (response?.status) {
				closeNewPointPanel()
				dispatch(getPetDetailsById(petId, () => {}));
				refresh();
			}

		})

	}




	return (
		<>
			<div className={`ocular-new-point-panel-wrapper position-fixed d-flex flex-column overflow-auto ${className}`}>
				<div className="head d-flex align-items-center">
					<h2>Add New Vital Measurement</h2>
					<Button color="link" className="ms-auto panel-close-btn" onClick={closeNewPointPanel}>
						<span className="material-symbols-outlined">
							close
						</span>
					</Button>
				</div>

				<div className="inner overflow-auto align-items-center">

					<Formik initialValues={initValsFromFormObj(formInfo[type])}
						enableReinitialize
						validationSchema={formObjToSchema(formInfo[type])}
						onSubmit={(values, { resetForm }) => {
							// handleAddPoint(values)
							Object.keys(values).forEach(key => {
								if (values[key] === '' || values[key] === null) {
									delete values[key];
								}
								else if (key === 'date') {
									values[key] = new Date(values[key])
					
								}
							});
							addPoint({ ...values, type: type }, response => {
								//console.log("response", response?.status)
								if (response?.status) {
									closeNewPointPanel();
									dispatch(getPetDetailsById(petId, () => {}));
									refresh();
									resetForm();
									toast.success(response.message, toasterConfig)
								}
								else {
									//toast.error(response.message, toasterConfig)
								}

							})
						}}>

						{(formik) => (

							<Form>
								<div className="form-block">
									<FormGroup className="input-wrapper">
										<div className="form-floating position-relative text-input">
											<Input type="select" className="form-control" id={'type'} value={type} onChange={(evt) => setType(evt?.target?.value)}>

												<option>Vitals</option>
												<option>Pain Assessment</option>
												<option>Measurements</option>

											</Input>
											<label for={`type`}>{`type`}</label>
										</div>
									</FormGroup>
								</div>


								{Object.keys(formInfo[type]).map((key, i) => {
									switch (formInfo[type][key].type) {
										case "input":
											return (
												<>
													<div key={i} className="form-block">
														<FormGroup className="input-wrapper">
															<div className="form-floating position-relative text-input">
																<Input
																	invalid={formik?.errors?.[formInfo[type][key].val_name] && formik?.touched?.[formInfo[type][key].val_name]}
																	type="text"
																	name={formInfo[type][key].val_name} className="form-control" id={`${type}-${key}`}
																	placeholder={`Enter ${type} ${key}`}
																	value={formik.values?.[formInfo[type][key].val_name]}
																	onChange={evt => { formik.handleChange(evt) }}
																/>
																<label for={`${type}-${key}`}>{`${key}`}</label>
																{formik?.errors?.[formInfo[type][key].val_name] && formik?.touched?.[formInfo[type][key].val_name] ? (
																	<FormFeedback invalid>{formik?.errors?.[formInfo[type][key].val_name]}</FormFeedback>
																) : null}
															</div>
														</FormGroup>
													</div>
												</>
											)
										case "select":
											return (
												<>
													<div key={i} className="form-block">
														<FormGroup className="input-wrapper">
															<div className="form-floating position-relative text-input">
																<Input
																	invalid={formik?.errors?.[formInfo[type][key].val_name] && formik?.touched?.[formInfo[type][key].val_name]}
																	type="select" name={formInfo[type][key].val_name}
																	className="form-control" id={`${type}-${key}`}
																	placeholder={`Enter ${type} ${key}`}
																	value={formik.values?.[formInfo[type][key].val_name]}
																	onChange={evt => { formik.handleChange(evt) }}
																>

																	<option value={''}>{`Select ${key}`}</option>
																	{formInfo[type][key].options.map((option, j) => {
																		return (
																			<option key={j}>{option}</option>
																		)
																	})}


																</Input>
																<label for={`${type}-${key}`}>{`${key}`}</label>
																{formik?.errors?.[formInfo[type][key].val_name] && formik?.touched?.[formInfo[type][key].val_name] ? (
																	<FormFeedback invalid>{formik?.errors?.[formInfo[type][key].val_name]}</FormFeedback>
																) : null}
															</div>
														</FormGroup>
													</div>
												</>
											)
										case "date":
											return (
												<>
													<div key={i} className="form-block">
														<FormGroup className="input-wrapper">
															<div className="form-floating position-relative text-input">
																<Input invalid={formik?.errors?.[formInfo[type][key].val_name] && formik?.touched?.[formInfo[type][key].val_name]}
																	type="datetime-local"
																	name={formInfo[type][key].val_name}
																	className="form-control" id={`${type}-${key}`}
																	placeholder={`Enter ${type} ${key}`}
																	value={formik?.values?.[formInfo[type][key].val_name]}
																	onChange={evt => { formik.handleChange(evt) }}
																/>
																<label for={`${type}-${key}`}>{`${key}`}</label>

																{formik?.errors?.[formInfo[type][key].val_name] && formik?.touched?.[formInfo[type][key].val_name] ? (
																	<FormFeedback invalid>{formik?.errors?.[formInfo[type][key].val_name]}</FormFeedback>
																) : null}
															</div>
														</FormGroup>
													</div>




												</>
											)
									}
								})}

								<div className="btn-blc">
									<Button type="submit" color="success" className="add-btn">Add new</Button>
								</div>

							</Form>
						)}
					</Formik>
				</div>
			</div>
		</>
	);
};

export default NewPointPanel;
