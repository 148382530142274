const initState = {
  loading: false,
  dates_loading: false,
  mars: { dates: [], categories: [] },
  dates: [],
  marsOrders: [],
  marordersLoading:false
};

export const marReducer = (state = initState, action) => {
  switch (action.type) {
    case "MARS_LOADING":
      return { ...state, loading: action.payload };

    case "MARS_DATES_LOADING":
      return { ...state, dates_loading: action.payload };

    case "MARS":
      return { ...state, mars: action.payload };

    case "MARS_DATES":
      return { ...state, dates: action.payload };

    case "MARS_ORDERS_LOADING":
      return { ...state, marordersLoading: action.payload };

    case "MARS_ORDERS":
      return { ...state, marsOrders: action.payload };

    default:
      return state;
  }
};
