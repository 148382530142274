const initState = {
    loading: false,
    vaccinationList: false,
    surgicalHistory: false
}

export const medicalHistoryReducer = (state = initState, action) => {
    switch (action.type) {
        case "MEDICAL_HISTORY_LOADING":
            return { ...state, loading: action.payload }

        case "VACCINATION_DUE_LIST":
            return { ...state, vaccinationList: action.payload }

        case "SURGICAL_HISTORY":
            return { ...state, surgicalHistory: action.payload }

        default:
            return state
    }
}