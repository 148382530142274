import "./FilterOptions.scss";

const FilterOptions = ({ firstOption = "All", optionType = "1", onClick }) => {
  return (
    <div id="filter-options-container">
      <div
        className={optionType === "2" ? "active" : ""}
        onClick={() => {
          onClick("2");
        }}
      >
        Active
      </div>
      <div
        className={optionType === "3" ? "active" : ""}
        onClick={() => {
          onClick("3");
        }}
      >
        Archived
      </div>
      <div
        className={optionType === "1" ? "active" : ""}
        onClick={() => {
          onClick("1");
        }}
      >
        {firstOption}
      </div>
    </div>
  );
};

export default FilterOptions;
