import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "reactstrap";
import UserPlaceholder from "../../../../../shared/UserPlaceholder";


// import "./index.scss";

const OwnerProfilehead = () => {
    const { petAndOwnerDetails } = useSelector(state => state.petReducer);
    const history = useHistory()
    const dispatch = useDispatch();
    return (
        <>
            <div className="profile-head px-0 pt-2">
                <div className="title d-flex justify-content-between">
                    <Button color="link" className="back-btn ps-0" onClick={() => {
                        history.goBack();
                    }}>Go Back</Button>
                    <Button color="link" className="view-user-btn">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="none"
                            viewBox="0 0 20 20"
                        >
                            <path
                                fill="#282E65"
                                d="M12 9.225c.767 0 1.417-.267 1.95-.8.533-.533.8-1.183.8-1.95 0-.767-.267-1.417-.8-1.95a2.653 2.653 0 00-1.95-.8c-.767 0-1.417.267-1.95.8a2.654 2.654 0 00-.8 1.95c0 .767.267 1.417.8 1.95.533.533 1.183.8 1.95.8zM6.3 13.85a7.48 7.48 0 012.575-1.912A7.536 7.536 0 0112 11.275c1.1 0 2.142.221 3.125.663A7.48 7.48 0 0117.7 13.85c.1 0 .175-.03.225-.088A.314.314 0 0018 13.55V2.3c0-.067-.033-.133-.1-.2s-.133-.1-.2-.1H6.3c-.067 0-.133.033-.2.1s-.1.133-.1.2v11.25c0 .083.025.154.075.212.05.059.125.088.225.088zm0 1.65c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 014.5 13.7V2.3c0-.5.175-.925.525-1.275C5.375.675 5.8.5 6.3.5h11.4c.5 0 .925.175 1.275.525.35.35.525.775.525 1.275v11.4c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H6.3zm-4 4c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 01.5 17.7V5.25a.726.726 0 01.75-.75.728.728 0 01.75.75V17.7c0 .083.03.154.088.213A.289.289 0 002.3 18h12.45c.217 0 .396.07.538.212a.731.731 0 01.212.538c0 .217-.07.396-.212.538a.731.731 0 01-.538.212H2.3zM12 7.725c-.35 0-.646-.12-.887-.362a1.208 1.208 0 01-.363-.888c0-.35.121-.646.363-.888.241-.241.537-.362.887-.362s.646.12.887.362c.242.242.363.538.363.888s-.121.646-.363.888a1.206 1.206 0 01-.887.362zM8.325 14h7.35a5.736 5.736 0 00-1.712-.912A6.16 6.16 0 0012 12.775a6.15 6.15 0 00-1.962.313A5.734 5.734 0 008.325 14z"
                            ></path>
                        </svg>
                    </Button>
                </div>
                <div className="user-profile-cell d-inline-flex align-items-center">
                    <div className="pic position-relative">
                        {petAndOwnerDetails?.user_image ?
                            <img className="img-fluid w-100 h-100" src={petAndOwnerDetails?.user_image || require('../../../../../assets/img/vetRecorEMR/user-thumbnail.png')} alt="Pet" />
                            : <UserPlaceholder className="img-fluid w-100 h-100" />}
                    </div>
                    <div className="txt">
                        <h2 style={{ textTransform: "capitalize" }}>{petAndOwnerDetails?.firstname + " " + petAndOwnerDetails?.lastname}</h2>
                        <h4 className="active">{petAndOwnerDetails?.active ? "Active" : "Inactive"}</h4>
                    </div>
                </div>
                <div className="profile-btns d-flex px-0">
                    <Button color="link" className="flex-fill">Message</Button>

                </div>
            </div>
        </>
    );
};

export default OwnerProfilehead;
