import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { sidebarPanelAction } from "../../../../context/actions/sidebarPanelAction";
import ClinicStaff from "./ClinicStaff";
import ClinicBranch from "./ClinicBranch";
import ClinicInfo from "./ClinicInfo";
import "./index.scss";

const ClinicDeatil = () => {
  const [isAdd, setIsAdd] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const history = useHistory();
  const sidebarPanelState = useSelector(
    (state) => state.sidebarPanelReducer.isSidePanel
  );
  const dispatch = useDispatch();

  const showSidePanel = () => {
    dispatch(sidebarPanelAction(!sidebarPanelState));
  };

  const handleAddNew = () => {
    setIsAdd(true);
  };
  useEffect(() => {
    dispatch({ type: "RESET_CLINIC_SETTINGS", payload: {} });
    dispatch({ type: "GET_WAITING_ROOM", payload: [] });
    dispatch({ type: "GET_HOLIDAY", payload: [] });
  }, []);

  return (
    <>
      <div className="page-wrapper page-header-wrapper position-relative d-flex flex-column flex-fill overflow-auto">
        <div className="d-flex align-items-center flex-shrink-0 right-column-visible">
          <Button
            className="sidebar-toggle-btn d-inline-flex align-items-center"
            color="link"
            onClick={showSidePanel}
          >
            <span className="material-symbols-outlined">menu</span>
          </Button>
          <div className="row-block d-flex justify-between align-items-center flex-fill">
            <div
              onClick={() => {
                history.goBack();
              }}
              className="color-3EFD inline-flex items-center text-sm md:text-base uppercase gap-x-2 text-blue-600 tracking-tighter hover:underline underline-offset-2 cursor-pointer"
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                fontSize={23}
                className="text-blue-500 color-3EFD"
              />
              Back to List
            </div>
            <div className="ms-auto ml-0">
              <div className="btn-blc">
                <Button
                  color="success"
                  className="add-btn py-2 h-auto"
                  onClick={handleAddNew}
                >
                  Add new
                </Button>
              </div>
            </div>
          </div>
        </div>

        <ClinicInfo />

        <div className="d-flex flex-column flex-fill overflow-auto">
          <div className="tab-wrapper tab-wrapper-inventory  d-flex flex-column flex-fill overflow-auto">
            <Nav tabs className="d-flex nav-tab">
              <NavItem>
                <NavLink
                  className={activeTab === "1" ? "active" : ""}
                  onClick={() => setActiveTab("1")}
                >
                  Clinic Staff
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "2" ? "active" : ""}
                  onClick={() => setActiveTab("2")}
                >
                  Clinics
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab} className="">
              <TabPane tabId="1">
                <div className="d-flex flex-column flex-fill overflow-auto">
                  {activeTab === "1" && (
                    <ClinicStaff isAdd={isAdd} setIsAdd={setIsAdd} />
                  )}
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="d-flex flex-column flex-fill overflow-auto">
                  {activeTab === "2" && (
                    <ClinicBranch isAdd={isAdd} setIsAdd={setIsAdd} />
                  )}
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClinicDeatil;
