import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button, ButtonDropdown, DropdownToggle, NavItem, Nav, NavLink, Table, TabContent, TabPane } from "reactstrap";
import { getServicesByClinicId } from "../../../context/actions/service";
import CheckBox from "../../../shared/CheckBox";
import NodataFoundBlock from "../../../shared/NoDataFoundBlock";
import PaginationBlock from "../../../shared/PaginationBlock";
import UserPlaceholder from "../../../shared/UserPlaceholder";
import { dateFromDateTime } from "../../../utils/common";
import { getDiagnosticResultsByClinicId } from "../../../context/actions/diagnostic-result";
import { getDiagnosticServicesByClinicId } from "../../../context/actions/diagnostic-service";
import ServiceFilters from "./ServiceFilters";
import { getAllInventoryCategories } from "context/actions/app-content";
import useDebounce from "hooks/useDebounce";

const DiagnosticServices = ({ handleOpenDrawerButton }) => {
  const history = useHistory();
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const dispatch = useDispatch();
  const inventory = useSelector((state) => state.inventoryReducer.services);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const userDetails = useSelector((state) => state.user.user_details);
  const {clinic_details} = useSelector((state) => state.clinicReducer);
  const [inventoryData, setInventoryData] = useState([]);

  const [filterData, setFilterData] = useState({
    search: "",
    lab: "",
    modality: "",
    category: ""
  });

  

  const searchDebouncedValue = useDebounce(filterData.search, 300);
  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  
  useEffect(() => {

    console.log('calling set page use effect')

    setPage(1);

    dispatch(
      getDiagnosticServicesByClinicId( clinic_details?._id, {page, limit,...filterData}, (res) => {
        setInventoryData(res);
      })
    );

  }, [dispatch, clinic_details, filterData, searchDebouncedValue]) // filterData.lab, filterData.modality, filterData.category ,searchDebouncedValue]);

  useEffect(() => {
    console.log('calling get data use effect')
    dispatch(
      getDiagnosticServicesByClinicId( clinic_details?._id, {page, limit,...filterData}, (res) => {
        setInventoryData(res);
      })
    );

  }, [page, limit])


  return (
    <>   
          <div className="product-list-wrapper">
            <ServiceFilters setFilterData={setFilterData} />
            {inventoryData?.docs?.length > 0 ? (
              <div className="table-listing-blc overflow-auto">
                <Table striped responsive className="checkbox-listing">
                  <thead>
                    <tr>
                      <th>
                        <CheckBox
                          type="checkbox"
                          ischecked={false}
                          className="no-label"
                        />
                      </th>
                      <th >Name</th>
                      <th>Lab</th>
                      <th>Code </th>
                      <th>Category</th>
                      <th>Modality</th>
                      <th>Invoice Price</th>
                      <th>List Price</th>


                      {/* <th style={{ minWidth: "100px" }}>Patient</th>
                      <th style={{ minWidth: "80px" }}>Placed By</th>
                      <th>Notes</th> */}
                      {permissions?.edit_diagnostic_services &&
                        <th className="text-end">&nbsp;</th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {inventoryData?.docs?.map((item, i) => (
                      <tr key={i}>
                        <td>
                          <CheckBox
                            type="checkbox"
                            ischecked={false}
                            className="no-label"
                          />
                        </td>
                        <td>
                          <Button
                            color="link"
                            className="p-0 text-start d-inline-flex align-items-center w-100"
                            onClick={() => {
                              handleOpenDrawerButton(item);
                            }}
                          >
                            {item?.test_name}
                          </Button>
                        </td>
                        <td className="capitalize">{item?.antech_code ? "Antech" : item?.idexx_code ? 'Idexx': '--'}</td>
                        <td className="capitalize">{item?.antech_code ? item?.antech_code : item?.idexx_code ? item?.idexx_code: '--'}</td>
                        <td>{item?.diagnosticCategory?.category}</td>
                        <td>{item?.inHouse ? 'In House' : 'Reference Lab'}</td>
                        <td className="capitalize">{item?.invoice_price ? (+item?.invoice_price)?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        }) : '--'}</td>
                        <td className="capitalize">{item?.listPrice ? (+item?.listPrice)?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        }) : '--'}</td>



                        
                        {permissions?.edit_diagnostic_services &&
                          <td style={{textAlign: 'end'}} className="action-cell">
                            <Button color="link" className="edit-btn" onClick={ () =>{
                              dispatch({ type: "Single_Diagnostic_Service", payload: item })
                                history.push(`/inventory/edit-diagnostic-service`);}}>
                              <span className="icomoon-pencil"></span>
                            </Button>
                            <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                              <DropdownToggle color="link" className="more-btn">
                                <span className="icomoon-more"></span>
                              </DropdownToggle>{" "}
                            </ButtonDropdown>
                          </td>
                        }
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <PaginationBlock
                  limit={limit}
                  page={page}
                  pagingCounter={inventoryData?.pagingCounter || 1}
                  docLength={inventoryData?.docs?.length || 0}
                  docPage={inventoryData.page || 1}
                  totalPages={inventoryData.totalPages || 1}
                  totalDocs={inventoryData.totalDocs || 0}
                  _handleLimit={(val) => {
                    setLimit(val);
                  }}
                  _handlePageChange={setPage}
                />
              </div>

                 
        ) : (
          <NodataFoundBlock  type={filterData?.search !== "" ||
          filterData?.lab !== "" ||
          filterData?.modality !==  "" ||
          filterData?.category !== "" ? 'search result': ''} />
        )}
      </div>
    </>
  );
};

export default DiagnosticServices;
