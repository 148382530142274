import React from 'react'

const NodataFoundBlock = ({ className = '', message = 'No data found', type= '' }) => {

 // <div className={`not-data-found-blc d-flex flex-column align-items-center justify-content-center flex-fill text-center ${className}`}>
        //     <div className="inner">
        //         <div className="pic">
        //             <img className="img-fluid" src={require('../../assets/img/vetRecor/no-record-found.png')} alt="Not Found" />
        //         </div>
        //         <h2>{message}</h2>
        //     </div>
        // </div>

    if(type === 'medical history'){
    return (

        <div className={` d-flex flex-column align-items-center justify-content-center flex-fill text-center ${className}`}>
            <img width={200} height={140} src={require("../../assets/img/vetRecor/EmptyState.svg").default} />
            <div style={{width: '100%', textAlign: 'center', color: '#788C9F', fontSize: 24, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word'}}>No Medical History Recorded</div>            
            <div style={{width: '100%', textAlign: 'center', color: '#788C9F', fontSize: 16, fontFamily: 'Inter', fontWeight: '400',  wordWrap: 'break-word'}}>Medical information has not been recorded for this section</div>       
        </div>
    )}
    else if(type === 'appointment'){
        return(
        <div className={` d-flex flex-column align-items-center justify-content-center flex-fill text-center ${className}`}>
        <img width={200} height={140} src={require("../../assets/img/vetRecor/EmptyStateAppointment.svg").default} />
        <div style={{width: '100%', textAlign: 'center', color: '#788C9F', fontSize: 24, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word'}}>Your schedule is empty</div>            
        <div style={{width: '100%', textAlign: 'center', color: '#788C9F', fontSize: 16, fontFamily: 'Inter', fontWeight: '400',  wordWrap: 'break-word'}}>There are no appointments scheduled</div>       
    </div>)

    }  else if(type === 'search result'){
        return(
        <div className={` d-flex flex-column align-items-center justify-content-center flex-fill text-center ${className}`}>
        <img width={200} height={140} src={require("../../assets/img/vetRecor/EmptyStateSearch.svg").default} />
        <div style={{width: '100%', textAlign: 'center', color: '#788C9F', fontSize: 24, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word'}}>No results found</div>            
        <div style={{width: '100%', textAlign: 'center', color: '#788C9F', fontSize: 16, fontFamily: 'Inter', fontWeight: '400',  wordWrap: 'break-word'}}>Try adjusting your search to find what you are looking for</div>       
    </div>)

    }
    
    else{
        return(
        <div className={` d-flex flex-column align-items-center justify-content-center flex-fill text-center ${className}`}>
        <img width={200} height={140} src={require("../../assets/img/vetRecor/EmptyStateAppointment.svg").default} />
        <div style={{width: '100%', textAlign: 'center', color: '#788C9F', fontSize: 24, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word'}}>No Records</div>            
        <div style={{width: '100%', textAlign: 'center', color: '#788C9F', fontSize: 16, fontFamily: 'Inter', fontWeight: '400',  wordWrap: 'break-word'}}>Data has not been recorded for this section</div>       
    </div>)

    }
}

export default NodataFoundBlock