// import { v4 as uuidv4 } from 'uuid';

export let defaultOrderForDischarge = [
  {
    product_service_id: "",
    // product_service_name: "",
    frequency: "",
    dose: "",
    form: "",
    rate_val: "",
    rate_time: "",
    route: "",
    prn: "false",
    start_datetime: null,
    second_dose: null,
    duration_num: "",
    duration_unit: "",
    notes: "",
  },
];

export let defaultProblemConditionDiagnosis = [
  {
    pet_id: "",
    diagnosed_by: "",
    start_date: null,
    end_date: null,
    description: "",
    status: "",
  },
];

export let defaultDischargePatientFormData = {
  vitals: [],
  vitals_selected_fetch_filter: null,
  marsOrders: [],
  medications_administered_selected_fetch_filter: null,
  diagnosticResults: {},
  diagnostic_tests_selected_fetch_filter: null,
  order_for_discharge: [],
  treatmentPlan_for_discharge : [],
  procedures: [],
  prescription_routing: "e_prescribe",
  home_medication_and_orders: [],
  inpatient_orders: [],
  discharge_instructions: [],
  added_diagnosis: [],
  problems: [],
};

export let defaultTreatmentPlanForDischarge = [
  {
    plan_name: "",
    product_instruction_id: "",
    name: "",
    type:"", //"instruction or product"
    frequency: "",
    start_datetime: null,
    duration_num: "",
    duration_unit: "",
    notes: "",
    attachments:[]
  },
];


const initState = {
  loading: false,
  dischargePatientFormData: JSON.parse(
    JSON.stringify({
      ...defaultDischargePatientFormData,
    })
  ),
  dischargeInstructions: [],
  diagnosticToggle: {},
};



export const dischargePatientReducer = (state = initState, action) => {
  switch (action.type) {
    case "DISCHARGE_PATIENT_FORM_LOADING":
      return { ...state, loading: action.payload };

    case "DISCHARGE_PATIENT_FORM_DATA":
      return { ...state, dischargePatientFormData: action.payload };

    case "SET_DISCHARGE_INSTRUCTIONS":
      return { ...state, dischargeInstructions: action.payload };

      case "UPDATE_DIAGNOSTIC_TOGGLES":
        return {
          ...state,
          diagnosticToggle: action.payload,
        };

    default:
      return state;
  }
};



