import React from 'react'
import "./index.scss";

const SortColumn = ({ sortLatest, sortOldest }) => {
    return (
        <span className="d-inline-flex flex-column sort-column-arrow position-relative">
            <span className="material-symbols-outlined up position-absolute" onClick={sortLatest}>
                arrow_drop_up
            </span>
            <span className="material-symbols-outlined down position-absolute" onClick={sortOldest}>
                arrow_drop_down
            </span>
        </span>
    )
}

export default SortColumn