import React, { useState } from "react";
import NodataFoundBlock from "../../../../../../../shared/NoDataFoundBlock";
import moment from "moment";
import { Table } from "reactstrap";

const ScheduleSelection = ({ vals, appointments, selectedAppointmentfn }) => {
  const [schedulesDropdownVisible, setScheduleDropdownVisible] =
    useState(false);

  return (
    <div className="mb-2 ">
      <div className="w-full h-full relative">
        <div className=" w-full flex items-center justify-between p-[10px] rounded-md bg-[#F8F8F8]">
          {!vals && (
            <span className="text-sm mb-0">Please select an appointment</span>
          )}
          {vals && (
            <span className="text-sm mb-0">
              Schedule :{" "}
              {moment(vals.appointment.appointment_date, "DD-MM-YYYY").format(
                "MMM D, YYYY"
              )}{" "}
              | {vals.appointment.appointment_time}
            </span>
          )}
          <a
            className="text-[--var(blue)] text-sm"
            onClick={() => {
              setScheduleDropdownVisible(!schedulesDropdownVisible);
            }}
          >
            {schedulesDropdownVisible ? "Close" : "Change"}
          </a>
        </div>
        <div
          className={`notes-schedule-modal-selector overflow-auto absolute top-9 shadow-md bg-[#F8F8F8] rounded-md  ${schedulesDropdownVisible ? "h-[250px] p-1 " : "h-0"
            } transition-all duration-100`}
        >
          <Table responsive striped>
            <tbody>
              {appointments && appointments?.length > 0 ? (
                appointments?.map((appointment, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        {appointment?.appointment_date
                          ? moment(
                            appointment.appointment_date,
                            "DD-MM-YYYY"
                          ).format("MMM D, YYYY")
                          : "--"}
                      </td>
                      <td>
                        {" | "}
                        {appointment.appointment_time}
                      </td>
                      <td className="w-fit">
                        <p className="max-w-[100px] truncate">
                          {appointment.reason}
                        </p>
                      </td>
                      <td>
                        <button
                          className="hover:drop-shadow-md"
                          onClick={() => {
                            selectedAppointmentfn(appointment);
                            setScheduleDropdownVisible(
                              !schedulesDropdownVisible
                            );
                          }}
                        >
                          <img
                            className="img-fluid w-100 h-100"
                            src={
                              require("../../../../../../../assets/img/check_circle.svg")
                                .default
                            }
                            alt="Badge"
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="no-data-rw">
                  <td colSpan="5">
                    <NodataFoundBlock />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ScheduleSelection;
