import React, { useEffect, useState } from 'react';
import "./index.scss";
import { useDispatch, useSelector } from 'react-redux';
import { fetchChatMessages, sendNewMEssage, updateMessageForSeenBy, updateUnreadCount } from 'context/actions/realTimeChat';
import NodataFoundBlock from 'shared/NoDataFoundBlock';
import { Icons } from 'utils/contants/Icons';
import { sendFiles } from 'context/actions/messagePanelAction';
import { toast } from 'react-toastify';
import { LuMinusCircle } from 'react-icons/lu';
import { MdInsertDriveFile } from 'react-icons/md';

const ChatRoom = ({ roomId, isFullWidth = false, canSendMessage = true}) => {
        const chatType = 'group';
    const dispatch = useDispatch();
    const { messagesList, selectedRoom } = useSelector(state => state.realTimeChatReducer);
    const userDetails = useSelector((state) => state.user.user_details);
    const [selectedUser, setSelectedUser] = useState("")
    const [messageListing, setMessageListing] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [messageType, setMessageType] = useState("direct");
    const [allFiles, setAllFiles] = useState([]);
    const [isSendDisable, setIsSendDisable] = useState(true);   // for send button disable


    const sendMessage = async (e) => {
        e.preventDefault();
        if (isSendDisable) return;
        const payload = {
            createdAt: new Date(),
            is_deleted: false,
            message: newMessage,
            message_source: messageType || "direct",
            message_type: ["Direct Message"],
            roomId: roomId,
            seen_by: [userDetails?._id],
            sender_id: userDetails?._id,
            // seen_by: ["6377307cb9936f8fc580f33d"],
            // sender_id: "6377307cb9936f8fc580f33d",
        }
        if (allFiles?.length > 0) {
            const formData = new FormData();
            allFiles?.forEach((file) => {
                formData.append("attachments", file);
            });
            if (selectedRoom.petDetails?.id) {
                formData.append("pet_id", selectedRoom.petDetails?.id);
                formData.append("added_by", userDetails?._id);
              }
            const res = await dispatch(sendFiles(formData));
            if (res?.status) {
                toast.success(res?.message);
                payload.message_type.push("Attachment");
                payload.attachments = res?.response_data;

            } else {
                toast.error("File not sent!");
            }
        }
        dispatch(sendNewMEssage(payload, () => {
            setNewMessage("");
            setAllFiles([]);
        }))
        dispatch(updateUnreadCount(userDetails?._id, chatType, () => { }))

    }

    const handleOnFileChange = (e) => {
        const file = e.target.files[0];
        let files = [...allFiles];
        files?.push(file);
        setAllFiles(files);
    };

    const deleteFile = (j) => {
        let files = [...allFiles];
        files.splice(j, 1);
        setAllFiles((prev) => [...files]);
    };

    useEffect(() => {
        if (messagesList?.length) {
            const sortedMessages = messagesList?.sort((a, b) => a.createdAt.seconds - b.createdAt.seconds);
            // Format the date in the desired format
            const formattedMessages = sortedMessages.map((message) => {
                const date = new Date(message.createdAt.seconds * 1000); // Convert seconds to milliseconds
                const formattedDate = date.toLocaleDateString(undefined, {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                });
                const formattedTime = date.toLocaleTimeString(undefined, {
                    hour: 'numeric',
                    minute: 'numeric',
                });
                const formattedDateTime = `${formattedDate} at ${formattedTime}`;
                const senderData = selectedRoom?.members?.find(details => details?.id === message?.sender_id)
                // Add the formatted date to the message object
                if (!message?.seen_by?.includes(userDetails?._id)) dispatch(updateMessageForSeenBy({ messageData: message, userId: userDetails?._id }))
                return { ...message, formattedDateTime, senderData };
            });
            setMessageListing(formattedMessages)
        } else {
            setMessageListing([])
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messagesList]);
    useEffect(() => {
        if (!roomId) {
            dispatch({ type: "SELECT_ROOM", payload: {} })
            dispatch({ type: "MESSAGE_LIST", payload: [] });
        } else {
            dispatch(fetchChatMessages({id: roomId},"" , () =>{}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (
            newMessage !== "" ||
            allFiles?.length > 0
        ) {
            setIsSendDisable(false);
        } else {
            setIsSendDisable(true);
        }
    }, [allFiles, newMessage]);


    return (

        <div style={{ maxWidth: isFullWidth ? "100%" : "20% "  }} className={`d-flex flex-col`}>
            <div style={{ width: '100%', justifyContent: 'space-between', alignItems: 'flex-start', display: 'inline-flex' }}>
                <div style={{ borderRadius: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 5, display: 'inline-flex' }}>
                    <div style={{ color: '#282E65', fontFamily: 'Nunito Sans', fontWeight: '700', wordWrap: 'break-word' }} className={`${isFullWidth ? "text-[20px] mb-2" : "text-[24px]"} `}>Messages</div>
                </div>
            </div>
            <div className={`h-full ps-0 d-flex `}>
                <div className="h-full pe-2 w-full chat-container">

                    <div className={`${messageListing?.length && "overflow-auto"} chat-panel`}>
                        {messageListing?.length ? <>
                            {messageListing?.map((msgData, i) => {
                                const isUserDataShow = msgData?.formattedDateTime === messageListing?.[i - 1]?.formattedDateTime && msgData?.sender_id === messageListing?.[i - 1]?.sender_id;
                                return <React.Fragment key={i}>
                                    {msgData?.sender_id !== userDetails?._id ?
                                        <div className="row" >
                                            <div className="col-md-9 d-flex">
                                                {!isUserDataShow ? <img src={require("../../../assets/img/avatarPic.png")} style={{ width: 32, height: 32 }} className="rounded-circle" alt="" /> : <div style={{ width: "32px" }} />}
                                                <div className="ms-2 mb-3">
                                                    {!isUserDataShow && <div className="d-flex align-items-center">
                                                        <p className="chater-name">{msgData?.senderData?.name}</p>
                                                        <span className="chat-date ms-1">{msgData?.formattedDateTime}</span>
                                                        <span className="chat-tagname ms-1">tagname</span>
                                                    </div>}
                                                    <div className="chat-bubble chat-bubble--left">
                                                        {Array.isArray(msgData?.message_type) ? (
                                                            <>
                                                                {msgData?.message_type?.map((types, i) => (
                                                                    <React.Fragment key={i}>
                                                                        {(types === "Direct Message" ||
                                                                            types === "SMS") && (
                                                                                <span>{msgData?.message}</span>
                                                                            )}
                                                                        {types === "Attachment" &&
                                                                            msgData?.attachments?.length > 0 && (
                                                                                <>
                                                                                    {msgData?.attachments?.map(
                                                                                        (file, j) => {
                                                                                            const extensionKey =
                                                                                                !file?.name
                                                                                                    ? file
                                                                                                    : file?.name;
                                                                                            if (
                                                                                                [
                                                                                                    "jpg",
                                                                                                    "jpeg",
                                                                                                    "png",
                                                                                                    "gif",
                                                                                                ].includes(
                                                                                                    extensionKey?.substring(
                                                                                                        extensionKey?.lastIndexOf(
                                                                                                            "."
                                                                                                        ) + 1
                                                                                                    )
                                                                                                )
                                                                                            ) {
                                                                                                const imgPath = file?.name
                                                                                                    ? URL.createObjectURL(
                                                                                                        file
                                                                                                    )
                                                                                                    : file;
                                                                                                return (
                                                                                                    <div
                                                                                                        className="position-relative"
                                                                                                        key={j}
                                                                                                    >
                                                                                                        <a
                                                                                                            href={imgPath}
                                                                                                            target="__blank"
                                                                                                        >
                                                                                                            <img
                                                                                                                className="file_box"
                                                                                                                src={imgPath}
                                                                                                                alt="img"
                                                                                                            />
                                                                                                        </a>
                                                                                                    </div>
                                                                                                );
                                                                                            } else if (
                                                                                                ["pdf"].includes(
                                                                                                    extensionKey?.substring(
                                                                                                        extensionKey?.lastIndexOf(
                                                                                                            "."
                                                                                                        ) + 1
                                                                                                    )
                                                                                                )
                                                                                            ) {
                                                                                                return (
                                                                                                    <div
                                                                                                        className="position-relative"
                                                                                                        key={j}
                                                                                                    >
                                                                                                        <a
                                                                                                            href={extensionKey}
                                                                                                            target="__blank"
                                                                                                        >
                                                                                                            <img
                                                                                                                className="file_box"
                                                                                                                src={require("../../../assets/img/pdf.png")}
                                                                                                                alt="img"
                                                                                                            />
                                                                                                        </a>
                                                                                                    </div>
                                                                                                );
                                                                                            } else if (
                                                                                                ["mp3", "mp4"].includes(
                                                                                                    extensionKey?.substring(
                                                                                                        extensionKey?.lastIndexOf(
                                                                                                            "."
                                                                                                        ) + 1
                                                                                                    )
                                                                                                )
                                                                                            ) {
                                                                                                return (
                                                                                                    <div
                                                                                                        className="position-relative"
                                                                                                        key={j}
                                                                                                    >
                                                                                                        <a
                                                                                                            href={extensionKey}
                                                                                                            target="__blank"
                                                                                                        >
                                                                                                            <img
                                                                                                                className="file_box"
                                                                                                                src={require(`../../../assets/img/mp4.png`)}
                                                                                                                alt="img"
                                                                                                            />
                                                                                                        </a>
                                                                                                    </div>
                                                                                                );
                                                                                            } else {
                                                                                                return (
                                                                                                    <div
                                                                                                        className="position-relative"
                                                                                                        key={j}
                                                                                                    >
                                                                                                        <a
                                                                                                            href={extensionKey}
                                                                                                            target="__blank"
                                                                                                        >
                                                                                                            <MdInsertDriveFile
                                                                                                                size={50}
                                                                                                            />
                                                                                                        </a>
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                    </React.Fragment>))}
                                                            </>)
                                                            :
                                                            (msgData?.message)
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                        <div className="row justify-content-end">
                                            <div className="col-md-9 ">
                                                {!isUserDataShow && <div className="d-flex align-items-center ms-auto">
                                                    <span className="chat-date text-end w-100" >{msgData?.formattedDateTime}</span>
                                                </div>}
                                                <div className="chat-bubble chat-bubble--right">{Array.isArray(msgData?.message_type) ? (
                                                    <>
                                                        {msgData?.message_type?.map((types, i) => (
                                                            <React.Fragment key={i}>
                                                                {(types === "Direct Message" ||
                                                                    types === "SMS") && (
                                                                        <span>{msgData?.message}</span>
                                                                    )}
                                                                {types === "Attachment" &&
                                                                    msgData?.attachments?.length > 0 && (
                                                                        <>
                                                                            {msgData?.attachments?.map(
                                                                                (file, j) => {
                                                                                    const extensionKey =
                                                                                        !file?.name
                                                                                            ? file
                                                                                            : file?.name;
                                                                                    
                                                                                    if (
                                                                                        [
                                                                                            "jpg",
                                                                                            "jpeg",
                                                                                            "png",
                                                                                            "gif",
                                                                                        ].includes(
                                                                                            extensionKey?.substring(
                                                                                                extensionKey?.lastIndexOf(
                                                                                                    "."
                                                                                                ) + 1
                                                                                            )
                                                                                        )
                                                                                    ) {
                                                                                        const imgPath = file?.name
                                                                                            ? URL.createObjectURL(
                                                                                                file
                                                                                            )
                                                                                            : file;
                                                                                        return (
                                                                                            <div
                                                                                                className="position-relative"
                                                                                                key={j}
                                                                                            >
                                                                                                <a
                                                                                                    href={imgPath}
                                                                                                    target="__blank"
                                                                                                >
                                                                                                    <img
                                                                                                        className="file_box"
                                                                                                        src={imgPath}
                                                                                                        alt="img"
                                                                                                    />
                                                                                                </a>
                                                                                            </div>
                                                                                        );
                                                                                    } else if (
                                                                                        ["pdf"].includes(
                                                                                            extensionKey?.substring(
                                                                                                extensionKey?.lastIndexOf(
                                                                                                    "."
                                                                                                ) + 1
                                                                                            )
                                                                                        )
                                                                                    ) {
                                                                                        return (
                                                                                            <div
                                                                                                className="position-relative"
                                                                                                key={j}
                                                                                            >
                                                                                                <a
                                                                                                    href={extensionKey}
                                                                                                    target="__blank"
                                                                                                >
                                                                                                    <img
                                                                                                        className="file_box"
                                                                                                        src={require("../../../assets/img/pdf.png")}
                                                                                                        alt="img"
                                                                                                    />
                                                                                                </a>
                                                                                            </div>
                                                                                        );
                                                                                    } else if (
                                                                                        ["mp3", "mp4"].includes(
                                                                                            extensionKey?.substring(
                                                                                                extensionKey?.lastIndexOf(
                                                                                                    "."
                                                                                                ) + 1
                                                                                            )
                                                                                        )
                                                                                    ) {
                                                                                        return (
                                                                                            <div
                                                                                                className="position-relative"
                                                                                                key={j}
                                                                                            >
                                                                                                <a
                                                                                                    href={extensionKey}
                                                                                                    target="__blank"
                                                                                                >
                                                                                                    <img
                                                                                                        className="file_box"
                                                                                                        src={require(`../../../assets/img/mp4.png`)}
                                                                                                        alt="img"
                                                                                                    />
                                                                                                </a>
                                                                                            </div>
                                                                                        );
                                                                                    } else {
                                                                                        return (
                                                                                            <div
                                                                                                className="position-relative"
                                                                                                key={j}
                                                                                            >
                                                                                                <a
                                                                                                    href={extensionKey}
                                                                                                    target="__blank"
                                                                                                >
                                                                                                    <MdInsertDriveFile
                                                                                                        size={50}
                                                                                                    />
                                                                                                </a>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                }
                                                                            )}
                                                                        </>
                                                                    )}
                                                            </React.Fragment>))}
                                                    </>)
                                                    :
                                                    (msgData?.message)
                                                }</div>
                                            </div>
                                        </div>
                                    }
                                </React.Fragment>
                            })}
                        </> :
                            <NodataFoundBlock />
                        }


                    </div>
                    {canSendMessage && <div className="row position-relative">
                        <div className="col-12">
                            <div className="appointment-card-in-input">
                                {allFiles?.length > 0 && (
                                    <div className="add_files py-3">
                                        {allFiles?.map((file, j) => {
                                            const extensionKey = !file?.name ? file : file?.name;
                                            if (
                                                ["jpg", "jpeg", "png", "gif"].includes(
                                                    extensionKey?.substring(
                                                        extensionKey?.lastIndexOf(".") + 1
                                                    )
                                                )
                                            ) {
                                                const imgPath = file?.name
                                                    ? URL.createObjectURL(file)
                                                    : file;
                                                return (
                                                    <div className="position-relative" key={j}>
                                                        <img className="file_box" src={imgPath} alt="img" />
                                                        <span
                                                            className="file-delete-icon"
                                                            onClick={() => {
                                                                deleteFile(j);
                                                            }}
                                                        >
                                                            <LuMinusCircle color={"red"} />
                                                        </span>
                                                    </div>
                                                );
                                            } else if (
                                                ["pdf"].includes(
                                                    extensionKey?.substring(
                                                        extensionKey?.lastIndexOf(".") + 1
                                                    )
                                                )
                                            ) {
                                                return (
                                                    <div className="position-relative" key={j}>
                                                        <img
                                                            className="file_box"
                                                            src={require("../../../assets/img/pdf.png")}
                                                            alt="img"
                                                        />
                                                        <span
                                                            className="file-delete-icon"
                                                            onClick={() => {
                                                                deleteFile(j);
                                                            }}
                                                        >
                                                            <LuMinusCircle color={"red"} />
                                                        </span>
                                                    </div>
                                                );
                                            } else if (
                                                ["mp3", "mp4"].includes(
                                                    extensionKey?.substring(
                                                        extensionKey?.lastIndexOf(".") + 1
                                                    )
                                                )
                                            ) {
                                                return (
                                                    <div className="position-relative" key={j}>
                                                        <img
                                                            className="file_box"
                                                            src={require(`../../../assets/img/mp4.png`)}
                                                            alt="img"
                                                        />
                                                        <span
                                                            className="file-delete-icon"
                                                            onClick={() => {
                                                                deleteFile(j);
                                                            }}
                                                        >
                                                            <LuMinusCircle color={"red"} />
                                                        </span>
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div className="position-relative" key={j}>
                                                        <MdInsertDriveFile size={50} />
                                                        <span
                                                            className="file-delete-icon"
                                                            onClick={() => {
                                                                deleteFile(j);
                                                            }}
                                                        >
                                                            <LuMinusCircle color={"red"} />
                                                        </span>
                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-12">
                            <form className="chat-box-tray" onSubmit={sendMessage} >
                                <input type="text" placeholder="Message..." value={newMessage} onChange={(e) => { setNewMessage(e.target.value) }} className="private-Message-input" disabled={!roomId} />
                                <div className='d-flex align-items-center justify-content-center gap-2 me-2'>
                                    <button
                                        className="cursor-pointer attachment-btn"
                                        disabled={!roomId}
                                        type="button"
                                    >
                                        <Icons.AttachFileIcon
                                            width={20}
                                            height={20}
                                        />
                                        <input
                                            type="file"
                                            id="myfile"
                                            name="myfile"
                                            disabled={!roomId}
                                            onChange={handleOnFileChange}
                                        />
                                    </button>
                                    <button className='btn btn-primary py-1' disabled={!roomId || isSendDisable} type="submit">Send</button>
                                </div>
                            </form>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default ChatRoom;
