import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";

toast.configure();

/**
 * @description function use to get all patient stories
 * @param {*} params accepting object
 */
export const getAllPatientStories = (id, params, callback) => {
    return (dispatch, getState) => {
        const { user: { token } } = getState();
        
        ApiClient.get(`${apiUrl}${PORT}/patient-story/get-all-patient-stories/${id}`, params, token, dispatch, getState).then(
            (response) => {
                
                if (response && !response.errors) {
                    return callback(response);
                } else if (response?.code === 404) {
                    toast.warn(response.message);
                    return callback();
                } else {
                    toast.error(response.errors.msg, toasterConfig);
                    return callback();
                }
            }
        );
    };
};

/**
 * @description function use to get patient stories stats
 * @param {*} params accepting object
 */
export const getPatientStoriesStats = (id, params, callback) => {
    return (dispatch, getState) => {
        const { user: { token } } = getState();
        
        ApiClient.get(`${apiUrl}${PORT}/patient-story/get-patient-stories-stats/${id}`, params, token, dispatch, getState).then(
            (response) => {
                
                if (response && !response.errors) {
                    return callback(response);
                } else if (response?.code === 404) {
                    toast.warn(response.message);
                    return callback();
                } else {
                    toast.error(response.errors.msg, toasterConfig);
                    return callback();
                }
            }
        );
    };
};