import React from "react";
import './index.scss';


const UpcomingVisitCard = () => {



    return (

        <>
            <div className="upcoming-visit-item">
                <div className="time-to-start-banner">
                    <div className="time-to-start-text">
                        START IN 00:00:48
                    </div>
                </div>
                <div className="doctor-info">
                    <div className="picture">
                        <img src={require("../../../../../../assets/img/vetRecorEMR/user-thumbnail.png")} />
                    </div>
                    <div className="info">
                        <div className="name-title">
                            <div className="name">
                                Dr. Brant Hillton
                            </div>
                            <div className="title">
                                DVM
                            </div>
                        </div>
                        <div className="action">

                        </div>

                    </div>
                </div>
                <div className="appointment-info">
                    <div className="date">
                        <div className="icon">

                        </div>
                        <div className="day">
                            Sep 10, 2022
                        </div>
                        <div className="days-from-now">
                            (In 3 days)
                        </div>

                    </div>
                    <div className="time">
                        <div className="icon">
                        </div>
                        <div className="time-range">
                            09:00 am - 10:00 am
                        </div>

                    </div>
                    <div className="location">
                        <div className="icon">

                        </div>
                        <div classname="location-name">
                            West Coast Clinic

                        </div>

                    </div>

                </div>
                <div className="appointment-description">
                    <div className="appointment-title">
                        Vaccination
                    </div>
                    <div className="appointment-description-body">
                        Bordetella Bronchiseptica
                        This highly infectious bacterium causes severe fits of coughing.
                    </div>


                </div>
                <div className="actions">
                    <div className="start-video-btn">
                        <div className="start-video-text">
                            Start Video Call
                        </div>
                    </div>
                    <div className="action-btn-group">
                        <div className="action-btn">
                            Cancel
                        </div>
                        <div className="action-btn">
                            Delay
                        </div>
                        <div className="action-btn">
                            Message
                        </div>
                    </div>
                </div>

            </div>


        </>


    )


}


export default UpcomingVisitCard;