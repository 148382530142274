import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table, DropdownToggle, ButtonDropdown } from "reactstrap";
import { getVaccinationDetailsList } from "../../../../../../context/actions/patient-module/medical-history";
import NodataFoundBlock from "../../../../../../shared/NoDataFoundBlock";
import Spinner from "../../../../../../shared/Spinner";
import UserPlaceholder from "../../../../../../shared/UserPlaceholder";
import {
  dateDiffFromNow,
  dateFromDateTime,
  dueDaysFromDate,
} from "../../../../../../utils/common";
import moment from "moment";
import "./index.scss";

const VaccinationBlock = ({ petId, viewAll }) => {
  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const [tableRowLength, setTableRowLength] = useState(5);
  const tempData = [0, 0, 0, 0, 0, 0];
  const [loading, setLoading] = useState(true);
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );
  const [vaccinationList, setVaccinationList] = useState([]);
  //const { vaccinationList } = useSelector(state => state.medicalHistoryReducer)
  const dispatch = useDispatch();
  useEffect(() => {
    if (petId) {
      dispatch(
        getVaccinationDetailsList(petId, {}, (res) => {
          setLoading(false);

          if (res?.status) {
            setVaccinationList(res?.response_data);
          }
        })
      );
    }
  }, [petId]);

  useEffect(() => {
    if (petId) {
      dispatch(
        getVaccinationDetailsList(petId, {}, (res) => {
          setLoading(false);

          if (res?.status) {
            setVaccinationList(res?.response_data);
          }
        })
      );
    }
  }, []);

  return (
    <>
      <div className="vaccination-list-block">
        <div className="head section-head d-flex align-items-center">
          <div className="left-col">
            <h3>Vaccinations</h3>
          </div>

          {viewAll && vaccinationList?.length > 5 && (
            <div className="right-col ms-auto d-inline-flex align-items-center">
              <div
                className="text-14-400 color-2e65 pointer"
                onClick={() => {
                  if (vaccinationList?.length > 5) {
                    if (tableRowLength === 5) {
                      setTableRowLength(vaccinationList?.length);
                    } else {
                      setTableRowLength(5);
                    }
                  }
                }}
              >
                {tableRowLength === 3 ? "View All" : "View less"}
              </div>
            </div>
          )}
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <div className="inner">
            {vaccinationList?.length > 0 ? (
              <div className="table-listing-blc overflow-auto">
                <Table responsive striped className="checkbox-listing">
                  <thead>
                    <tr>
                      <th style={{ minWidth: "192px" }}>Vaccine Name</th>
                      <th style={{ minWidth: "165px" }}>Status</th>
                      <th style={{ minWidth: "106px" }}>Due Date</th>
                      <th style={{ minWidth: "106px" }}>Administered On</th>
                      <th style={{ minWidth: "190px" }}>Administered By</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vaccinationList
                      ?.slice(
                        0,
                        viewAll ? tableRowLength : vaccinationList?.length
                      )
                      ?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td style={{ textTransform: "capitalize" }}>
                              {item?.product_name}
                            </td>

                            <td>
                              <span
                                className={`${
                                  dateDiffFromNow(item?.due_dates?.[0])
                                    .status == "IN"
                                    ? "success-cell"
                                    : dateDiffFromNow(item?.due_dates?.[0])
                                        .status == "OUT"
                                    ? "inactive-cell"
                                    : "pending-cell"
                                } status-cell`}
                              >
                                {dueDaysFromDate(item?.due_dates?.[0])}
                              </span>
                            </td>
                            <td>
                              {dateFromDateTime(
                                item?.due_dates?.[0],
                                "MM/DD/yyyy"
                              )}
                            </td>

                            <td>
                              {item?.administrations?.length > 0
                                ? moment(
                                    item?.administrations?.[0]?.date
                                  ).format("MM/DD/YYYY")
                                : "--"}
                            </td>
                            <td>
                              {item?.administrations?.length > 0 && (
                                <div className="pic-cell d-inline-flex align-items-center">
                                  <div className="pic rounded-circle d-inline-flex overflow-hidden">
                                    {item?.administrations?.[0]?.created_by
                                      ?.user_image ? (
                                      <img
                                        className="rounded-circle w-100 h-100"
                                        src={
                                          item?.administrations?.[0]?.created_by
                                            ?.user_image ||
                                          require("../../../../../../assets/img/vetRecorEMR/user-thumbnail.png")
                                        }
                                        alt="User"
                                      />
                                    ) : (
                                      <UserPlaceholder />
                                    )}
                                  </div>
                                  {`${
                                    item?.administrations?.[0]?.created_by
                                      ?.title
                                      ? item?.administrations?.[0]?.created_by
                                          ?.title + " "
                                      : ""
                                  } ${
                                    item?.administrations?.[0]?.created_by
                                      ?.firstname
                                  } ${
                                    item?.administrations?.[0]?.created_by
                                      ?.lastname
                                  }`}
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            ) : (
              <NodataFoundBlock type={'medical history'} />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(VaccinationBlock);
