import React from "react";
import "./index.scss";
import yellowBadge from "../../../../assets/img/vetRecorEMR/pet-badge-icon-yellow-shadow.svg";
import purpleBadge from "../../../../assets/img/vetRecorEMR/pet-badge-purple-shadow.svg";
import greenBadge from "../../../../assets/img/vetRecorEMR/pet-badge-icon-green-shadow.svg";

const PlanBlock = ({
  className = "",
  planType = "",
  startDate = "",
  planName = "",
}) => {
  return (
    <div className={`plan-type-box d-flex align-items-center ${className}`}>
      <div className="pic">
        {planType === "super" && <img src={purpleBadge} alt="Badge" />}
        {planType === "golden" && <img src={yellowBadge} alt="Badge" />}
        {planType === "primary" && <img src={greenBadge} alt="Badge" />}
      </div>
      <div className="txt">
        <h3>{planName}</h3>
        <h5>Purchased on {startDate}</h5>
      </div>
    </div>
  );
};

export default PlanBlock;
