import moment from "moment";
import React, { useEffect, useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Input, Table } from "reactstrap";
import { Icons } from "utils/contants/Icons";
import "./ReferralManagementReports.scss";
import { getReferralManagementRecords } from "context/actions/clinic-reports";
import LoaderUI from "shared/loader";
import NodataFoundBlock from "shared/NoDataFoundBlock";
import { allRange, refferalByStatus, titleCaseString } from "utils/common";
import ImagePlaceholder from "shared/ImagePlaceholder";
import AppLogoSmall from "shared/AppLogoSmall";
import DateRange from "shared/FormsInput/DateRange";
import PaginationBlock from "shared/PaginationBlock";

const ReferralManagementReports = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const [limit, setLimit] = useState(5);
  const [incommingPage, setIncommingPage] = useState(1);
  const [incommingPaging, setIncommingPaging] = useState(1);
  const [outGoingpage, setOutGoingpage] = useState(1);
  const [outGoingpaging, setOutGoingpaging] = useState(1);
  const [referralByPage, setReferralByPage] = useState([]);
  const [referralByPaging, setReferralByPaging] = useState([]);
  const [referralToPaging, setReferralToPaging] = useState([]);
  const [referralToPage, setReferralToPage] = useState([]);
  const [filterObj, setFilterObj] = useState({
    time_range: "",
    commonly_referral_to_start: "",
    commonly_referral_to_end: "",
    commonly_referral_by_start: "",
    commonly_referral_by_end: "",
    inCoomingReferralStatus: "",
    outgoingReferralStatus: "",
  });
  const [tableData, setTableData] = useState({
    data: [],
    loading: true,
  });
  const [startByDate, setStartByDate] = useState(null);
  const [endByDate, setEndByDate] = useState(null);
  const [startToDate, setStartToDate] = useState(null);
  const [endToDate, setEndToDate] = useState(null);
  const getTableData = async (obj) => {
    dispatch(
      getReferralManagementRecords(
        { clinic_id: clinic_details?._id, ...obj },
        (res) => {
          setTableData((prev) => {
            return {
              ...prev,
              data: res,
              loading: false,
            };
          });
        }
      )
    );
  };
  useEffect(() => {
    getTableData(filterObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterObj]);

  const refferalData = tableData?.data?.response_data?.[0];
  const inCommingTotalPages = Math?.ceil(
    refferalData?.incommingReferralReport?.length / limit
  );
  const outGoingTotalPages = Math?.ceil(
    refferalData?.outgoingReferralReport?.length / limit
  );

  const statusData = refferalData?.statusReport;

  const inCommingCurrentData = refferalData?.incommingReferralReport?.slice(
    (incommingPage - 1) * limit,
    incommingPage * limit
  );
  const outGoingCurrentData = refferalData?.outgoingReferralReport?.slice(
    (outGoingpage - 1) * limit,
    outGoingpage * limit
  );

  const inCommingRefferalTable = [
    {
      id: 1,
      title: "Incoming Referrals",
      data: inCommingCurrentData || [],
    },
  ];
  const outGoingRefferalTable = [
    {
      id: 1,
      title: "Outgoing Referrals",
      data: outGoingCurrentData || [],
    },
  ];

  useEffect(() => {
    if (refferalData?.commonlyReferralBy) {
      const updatedByPages = refferalData?.commonlyReferralBy?.map(
        (arr, _) => 1
      );

      setReferralByPage(updatedByPages);
      setReferralByPaging(updatedByPages);
    }
  }, [refferalData?.commonlyReferralBy]);

  useEffect(() => {
    if (refferalData?.commonlyReferralTo) {
      const updatedToPages = refferalData?.commonlyReferralTo?.map(
        (arr, _) => 1
      );

      setReferralToPage(updatedToPages);
      setReferralToPaging(updatedToPages);
    }
  }, [refferalData?.commonlyReferralTo]);

  const handleDownload = () => {
    const csvContent = generateCSV();
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "Refferal Management Report.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const generateCSV = () => {
    let csv = "";
    if (refferalData?.incommingReferralReport?.length > 0) {
      csv += `\n\n Incoming referrals\nPatient Info,Referred By,Clinic Address,Refer Date,Status\n`;
      refferalData?.incommingReferralReport?.forEach((item) => {
        const { patient_details, referred_to_details, refer_date, status } =
          item;
        const referDate = moment(refer_date).format("MM/DD/YYYY");
        csv += `${titleCaseString(patient_details?.pet_name)},${titleCaseString(
          referred_to_details?.clinic_name
        )},"${referred_to_details?.address || "--"}",${referDate},${status}\n`;
      });
    }

    if (refferalData?.outgoingReferralReport?.length > 0) {
      // Add headers based on the first item in the category
      csv += `\n\n Outgoing referrals\nPatient Info,Referred To,Clinic Address,Refer Date,Status,Action Date,Reason\n`;
      refferalData?.outgoingReferralReport?.forEach((item) => {
        const {
          patient_details,
          referred_to_details,
          refer_date,
          status,
          action_date,
          reason,
        } = item;
        const referDate = moment(refer_date).format("MM/DD/YYYY");
        const actionDate = action_date
          ? moment(action_date).format("MM/DD/YYYY")
          : null;
        csv += `${titleCaseString(patient_details?.pet_name)},${titleCaseString(
          referred_to_details?.clinic_name
        )},"${referred_to_details?.address || "--"}",${referDate},${status},${
          actionDate ? actionDate : "-"
        },${reason ? reason : "-"}\n`;
      });
    }
    refferalData?.commonlyReferralBy?.forEach((item) => {
      // Add headers based on the first item in the category
      if (item?.data?.length > 0) {
        csv += `\n\n ${"Referral By"} ${
          item?.referred_by
        }\nPatient Info,Referred To,Clinic Address,Refer Date,Status,Action Date,Reason\n`;
        item?.data?.forEach((item) => {
          const {
            patient_details,
            referred_to_details,
            refer_date,
            status,
            action_date,
            reason,
          } = item;
          const referDate = moment(refer_date).format("MM/DD/YYYY");
          const actionDate = action_date
            ? moment(action_date).format("MM/DD/YYYY")
            : null;
          csv += `${titleCaseString(
            patient_details?.pet_name
          )},${titleCaseString(referred_to_details?.clinic_name)},"${
            referred_to_details?.address || "--"
          }",${referDate},${status},${actionDate ? actionDate : "-"},${
            reason ? reason : "-"
          }\n`;
        });
      }
    });
    refferalData?.commonlyReferralTo?.forEach((item) => {
      // Add headers based on the first item in the category
      if (item?.data?.length > 0) {
        csv += `\n\n ${"Referral To"} ${
          item?.referred_to
        }\nPatient Info,Referred To,Clinic Address,Refer Date,Status,Action Date,Reason\n`;
        item?.data?.forEach((item) => {
          const {
            patient_details,
            referred_to_details,
            refer_date,
            status,
            action_date,
            reason,
          } = item;
          const referDate = moment(refer_date).format("MM/DD/YYYY");
          const actionDate = action_date
            ? moment(action_date).format("MM/DD/YYYY")
            : null;
          csv += `${titleCaseString(
            patient_details?.pet_name
          )},${titleCaseString(referred_to_details?.clinic_name)},"${
            referred_to_details?.address || "--"
          }",${referDate},${status},${actionDate ? actionDate : "-"},${
            reason ? reason : "-"
          }\n`;
        });
      }
    });
    return csv;
  };

  const getPaginatedData = (dataArray, tabIndex) => {
    const startIndex = (referralByPage[tabIndex] - 1) * limit;
    const endIndex = startIndex + limit;
    return dataArray?.slice(startIndex, endIndex) || [];
  };
  const getRefferToData = (dataArray, tabIndex) => {
    const startIndex = (referralToPage[tabIndex] - 1) * limit;
    const endIndex = startIndex + limit;
    return dataArray?.slice(startIndex, endIndex) || [];
  };

  return (
    <div id="referral-management-reports-container" className="p-1 mt-1">
      <div className="row">
        <div className="col-md-3">
          <div className="">
            <div className="d-flex">
              <div
                className="d-flex gap-2 align-items-center cmb-20 pointer"
                onClick={() => history.push("/reports")}
              >
                <BsChevronLeft color="color-3EFD" />
                <span className="text-13-600 color-3EFD">VIEW ALL REPORTS</span>
              </div>
            </div>

            <div className="bg-filter-class">
              <div className="text-24-700 color-2E65">Configure Report</div>
              <div className="text-14-400 color-8693">
                Select filter and generate report
              </div>
              <div className="filter-block cmt-20 cmb-20">
                <div className="cmb-10">
                  <Input
                    type="select"
                    name="select"
                    value={filterObj?.time_range}
                    placeholder="All Species"
                    onChange={(e) => {
                      setFilterObj((prev) => ({
                        ...prev,
                        time_range: e?.target?.value,
                      }));
                    }}
                  >
                    <option value="">All Time</option>
                    {allRange?.map((e, i) => {
                      return (
                        <option value={e?.id} key={i} id={e?.id}>
                          {e?.value}
                        </option>
                      );
                    })}
                  </Input>
                </div>

                <div className="cmb-10">
                  <DateRange
                    placeholder="Select Date for Referrals From"
                    id="boarding"
                    name="boarding"
                    startDateProp={startByDate}
                    endDateProp={endByDate}
                    setStartDateProp={setStartByDate}
                    setEndDateProp={setEndByDate}
                    onChange={(sDate, eDate) => {
                      setFilterObj((prev) => ({
                        ...prev,
                        commonly_referral_by_start: sDate
                          ? moment(sDate).format("YYYY-MM-DD")
                          : "",
                        commonly_referral_by_end: eDate
                          ? moment(eDate).format("YYYY-MM-DD")
                          : "",
                      }));
                    }}
                    isClearable
                  />
                </div>
                <div className="cmb-10">
                  <DateRange
                    placeholder="Select Date for Referrals To"
                    id="boarding"
                    name="boarding"
                    startDateProp={startToDate}
                    endDateProp={endToDate}
                    setStartDateProp={setStartToDate}
                    setEndDateProp={setEndToDate}
                    onChange={(sDate, eDate) => {
                      setFilterObj((prev) => ({
                        ...prev,
                        commonly_referral_to_start: sDate
                          ? moment(sDate).format("YYYY-MM-DD")
                          : "",
                        commonly_referral_to_end: eDate
                          ? moment(eDate).format("YYYY-MM-DD")
                          : "",
                      }));
                    }}
                    isClearable
                  />
                </div>

                <div className="cmb-10">
                  <Input
                    type="select"
                    name="select"
                    value={filterObj?.inCoomingReferralStatus}
                    placeholder="For Incomming Referral"
                    onChange={(e) => {
                      setFilterObj((prev) => ({
                        ...prev,
                        inCoomingReferralStatus: e?.target?.value,
                      }));
                      setIncommingPage(1);
                      setIncommingPaging(1);
                    }}
                  >
                    <option value="">All Incoming Referrals</option>
                    {refferalByStatus?.map((e, i) => {
                      return (
                        <option value={e?.value} key={i} id={e?.value}>
                          {e?.value}
                        </option>
                      );
                    })}
                  </Input>
                </div>

                <div className="cmb-10">
                  <Input
                    type="select"
                    name="select"
                    value={filterObj?.outgoingReferralStatus}
                    placeholder="For Outgoing Referral"
                    onChange={(e) => {
                      setFilterObj((prev) => ({
                        ...prev,
                        outgoingReferralStatus: e?.target?.value,
                      }));
                      setIncommingPage(1);
                      setOutGoingpaging(1);
                    }}
                  >
                    <option value="">All Outgoing Referrals</option>
                    {refferalByStatus?.map((e, i) => {
                      return (
                        <option value={e?.value} key={i} id={e?.value}>
                          {e?.value}
                        </option>
                      );
                    })}
                  </Input>
                </div>
              </div>
              <div className="cmb-15">
                {/* <Button className="g-report">Generate Report</Button> */}
                <div
                  className="text-center color-2E65 text-13-600 cmt-20 pointer"
                  onClick={() => {
                    setFilterObj({
                      time_range: "",
                      commonly_referral_to_start: "",
                      commonly_referral_to_end: "",
                      commonly_referral_by_start: "",
                      commonly_referral_by_end: "",
                      inCoomingReferralStatus: "",
                      outgoingReferralStatus: "",
                    });
                    setStartByDate(null);
                    setEndByDate(null);
                    setStartToDate(null);
                    setEndToDate(null);
                    setIncommingPage(1);
                    setIncommingPaging(1);
                    setOutGoingpage(1);
                    setOutGoingpaging(1);
                  }}
                >
                  RESET FILTER
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-9 details-section">
          <div className="cpt-20 cpe-20 cpb-20">
            <div className="d-flex justify-content-between align-items-center cmb-20">
              <div>
                <div className="text-24-700 color-2E65">
                  Referral Management Records
                </div>
                {/* <p className="text-14-400 color-8693 text-wrap">
                  Referral Management Records help track the clinic’s medical
                  supplies, medications, and equipment to ensure that everything
                  is well-stocked and up to date.
                </p> */}
              </div>
              <div className="d-flex gap-2 br-8">
                <div
                  className="cp-8 bg-8C9F28 pointer"
                  onClick={() => {
                    handleDownload();
                  }}
                >
                  <img src={Icons.download} alt="download" />
                </div>
                <div className="cp-8 bg-8C9F28 br-8">
                  <img src={Icons.share} alt="share" />
                </div>
              </div>
            </div>
            <div className="cmb-30 card-block row flex-wrap gy-3">

            {statusData?.map((data, index) => {
                return (
                  <div
                    className="col-md-2 col-sm-4 flex-grow-1 avrage-card"
                    key={index}
                  >
                    <div className="items-details">
                      <div className="chart-item">
                       
                      </div>
                      {/* <div className="text-13-500">Average Time</div> */}
                      <div className="text-13-500">{data?.name}</div>
                      <div className="text-13-700">{`${data?.count}`}</div>
                    </div>
                  </div>
                );
              })}
              </div>
            {inCommingRefferalTable?.map((tabData, tabIndex) => {
              return (
                <div className="table-one cmb-20" key={tabIndex}>
                  <div className="text-14-600 color-2E65 cmb-10">
                    {tabData?.title} (
                    {refferalData?.incommingReferralReport?.length})
                  </div>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th style={{width: '10%'}}>Patient</th>
                        <th style={{width: '10%'}} >Referred By</th>
                        <th style={{width: '40%'}}>Clinic Address</th>
                        <th style={{width: '10%'}}>Refer Date</th>
                        <th style={{width: '10%'}} >Status</th>
                        <th style={{width: '10%'}}>Action Date</th>
                        <th style={{width: '10%'}}>Reason</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData?.loading && (
                        <tr className="no-data-rw">
                          <td
                            colSpan="8"
                            className="d-flex justify-content-center cpt-50 cpb-50"
                          >
                            <LoaderUI />
                          </td>
                        </tr>
                      )}
                      {!tableData?.data?.loading &&
                      tabData?.data?.length > 0 ? (
                        tabData?.data?.map((dataElem, dataIndex) => {
                          const {
                            patient_details,
                            referred_to_details,
                            refer_date,
                            status,
                            action_date,
                            reason,
                          } = dataElem;
                          const referDate =
                            moment(refer_date).format("MM/DD/YYYY");
                          const actionDate = action_date
                            ? moment(action_date).format("MM/DD/YYYY")
                            : null;
                          return (
                            <tr key={dataIndex}>
                              <td className="text-nowrap">
                                <div className="text-start pic-cell d-inline-flex align-items-center">
                                  <div className="pic d-inline-flex position-relative">
                                    {patient_details?.pet_image ? (
                                      <img
                                        className="img-fluid w-100 h-100"
                                        src={patient_details?.pet_image}
                                        alt="Patient"
                                      />
                                    ) : (
                                      <ImagePlaceholder />
                                    )}
                                  </div>
                                  <div>
                                    {titleCaseString(patient_details?.pet_name)}
                                  </div>
                                </div>
                              </td>
                              <td className="text-nowrap">
                                <div className="text-start pic-cell d-inline-flex align-items-center justi">
                                  <AppLogoSmall />
                                  {titleCaseString(
                                    referred_to_details?.clinic_name
                                  )}
                                </div>
                              </td>
                              <td className="text-nowrap">
                                {referred_to_details?.address || "--"}
                              </td>
                              <td className="text-nowrap">{referDate}</td>

                              <td className="text-nowrap">{status}</td>
                              <td className="text-nowrap">
                                {actionDate ? actionDate : "-"}
                              </td>
                              <td className="text-nowrap">
                                {reason ? reason : "-"}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="no-data-rw">
                          <td colSpan="11">
                            <NodataFoundBlock />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {refferalData?.incommingReferralReport?.length > 0 && (
                    <PaginationBlock
                      limit={limit}
                      page={incommingPage}
                      pagingCounter={incommingPaging || 1}
                      docLength={limit || 0}
                      docPage={incommingPage || 1}
                      totalPages={inCommingTotalPages || 1}
                      totalDocs={
                        refferalData?.incommingReferralReport?.length || 0
                      }
                      _handleLimit={(val) => {
                        setLimit(val);
                      }}
                      _handlePageChange={(e) => {
                        if (e > incommingPage) {
                          const diff = e - incommingPage;

                          setIncommingPaging((prev) => prev + limit * diff);
                        } else if (e < incommingPage) {
                          const diff = incommingPage - e;
                          setIncommingPaging((prev) =>
                            Math.max(prev - limit * diff, 0)
                          );
                        }
                        setIncommingPage(e);
                      }}
                    />
                  )}
                </div>
              );
            })}
            {outGoingRefferalTable?.map((tabData, tabIndex) => {
              return (
                <div className="table-one cmb-20" key={tabIndex}>
                  <div className="text-14-600 color-2E65 cmb-10">
                    {tabData?.title} (
                    {refferalData?.outgoingReferralReport?.length})
                  </div>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th style={{width: '10%'}}>Patient</th>
                        <th style={{width: '10%'}} >Referred to</th>
                        <th style={{width: '40%'}}>Clinic Address</th>
                        <th style={{width: '10%'}}>Refer Date</th>
                        <th style={{width: '10%'}} >Status</th>
                        <th style={{width: '10%'}}>Action Date</th>
                        <th style={{width: '10%'}}>Reason</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      {tableData?.loading && (
                        <tr className="no-data-rw">
                          <td
                            colSpan="8"
                            className="d-flex justify-content-center cpt-50 cpb-50"
                          >
                            <LoaderUI />
                          </td>
                        </tr>
                      )}
                      {!tableData?.data?.loading &&
                      tabData?.data?.length > 0 ? (
                        tabData?.data?.map((dataElem, dataIndex) => {
                          const {
                            patient_details,
                            referred_to_details,
                            refer_date,
                            status,
                            action_date,
                            reason,
                          } = dataElem;
                          const referDate =
                            moment(refer_date).format("MM/DD/YYYY");

                          const actionDate = action_date
                            ? moment(action_date).format("MM/DD/YYYY")
                            : null;
                          return (
                            <tr key={dataIndex}>
                              <td className="text-nowrap">
                                <div className="text-start pic-cell d-inline-flex align-items-center">
                                  <div className="pic d-inline-flex position-relative">
                                    {patient_details?.pet_image ? (
                                      <img
                                        className="img-fluid w-100 h-100"
                                        src={patient_details?.pet_image}
                                        alt="Patient"
                                      />
                                    ) : (
                                      <ImagePlaceholder />
                                    )}
                                  </div>
                                  <div>
                                    {titleCaseString(patient_details?.pet_name)}
                                  </div>
                                </div>
                              </td>
                              <td className="text-nowrap">
                                <div className="text-start pic-cell d-inline-flex align-items-center justi">
                                  <AppLogoSmall />
                                  {titleCaseString(
                                    referred_to_details?.clinic_name
                                  )}
                                </div>
                              </td>
                              <td className="text-nowrap">
                                {referred_to_details?.address || "--"}
                              </td>
                              <td className="text-nowrap">{referDate}</td>
                              <td className="text-nowrap">{status}</td>
                              <td className="text-nowrap">
                                {actionDate ? actionDate : "-"}
                              </td>
                              <td className="text-nowrap">
                                {reason ? reason : "-"}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="no-data-rw">
                          <td colSpan="11">
                            <NodataFoundBlock />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {refferalData?.outgoingReferralReport?.length > 0 && (
                    <PaginationBlock
                      limit={limit}
                      page={outGoingpage}
                      pagingCounter={outGoingpaging || 1}
                      docLength={limit || 0}
                      docPage={outGoingpage || 1}
                      totalPages={outGoingTotalPages || 1}
                      totalDocs={
                        refferalData?.outgoingReferralReport?.length || 0
                      }
                      _handleLimit={(val) => {
                        setLimit(val);
                      }}
                      _handlePageChange={(e) => {
                        if (e > outGoingpage) {
                          const diff = e - outGoingpage;
                          setOutGoingpaging((prev) => prev + limit * diff);
                        } else if (e < outGoingpage) {
                          const diff = outGoingpage - e;
                          setOutGoingpaging((prev) =>
                            Math.max(prev - limit * diff, 0)
                          );
                        }
                        setOutGoingpage(e);
                      }}
                    />
                  )}
                </div>
              );
            })}
            {refferalData?.commonlyReferralBy?.map((tabData, tabIndex) => {
              const totalItems = tabData?.data?.length;
              const totalPages = Math?.ceil(totalItems / limit);
              const paginatedData = getPaginatedData(tabData?.data, tabIndex);

              return (
                <div className="table-one cmb-20" key={tabIndex}>
                  <div className="text-14-600 color-2E65 cmb-10">
                    Referrals From {tabData.referred_by} ({tabData?.data?.length})
                  </div>

                  <Table responsive>
                    <thead>
                      <tr>

                        <th style={{width: '10%'}}>Patient</th>
                        <th style={{width: '10%'}} >Referred to</th>
                        <th style={{width: '40%'}}>Clinic Address</th>
                        <th style={{width: '10%'}}>Refer Date</th>
                        <th style={{width: '10%'}} >Status</th>
                        <th style={{width: '10%'}}>Action Date</th>
                        <th style={{width: '10%'}}>Reason</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData?.loading && (
                        <tr className="no-data-rw">
                          <td
                            colSpan="8"
                            className="d-flex justify-content-center cpt-50 cpb-50"
                          >
                            <LoaderUI />
                          </td>
                        </tr>
                      )}
                      {!tableData?.data?.loading &&
                      paginatedData?.length > 0 ? (
                        paginatedData?.map((dataElem, dataIndex) => {
                          const {
                            patient_details,
                            referred_to_details,
                            refer_date,
                            status,
                            action_date,
                            reason,
                          } = dataElem;
                          const referDate =
                            moment(refer_date).format("MM/DD/YYYY");

                          const actionDate = action_date
                            ? moment(action_date).format("MM/DD/YYYY")
                            : null;
                          return (
                            <tr key={dataIndex}>
                              <td className="text-nowrap">
                                <div className="text-start pic-cell d-inline-flex align-items-center">
                                  <div className="pic d-inline-flex position-relative">
                                    {patient_details?.pet_image ? (
                                      <img
                                        className="img-fluid w-100 h-100"
                                        src={patient_details?.pet_image}
                                        alt="Patient"
                                      />
                                    ) : (
                                      <ImagePlaceholder />
                                    )}
                                  </div>
                                  <div>
                                    {titleCaseString(patient_details?.pet_name)}
                                  </div>
                                </div>
                              </td>
                              <td className="text-nowrap">
                                <div className="text-start pic-cell d-inline-flex align-items-center justi">
                                  <AppLogoSmall />
                                  {titleCaseString(
                                    referred_to_details?.clinic_name
                                  )}
                                </div>
                              </td>
                              <td className="text-nowrap">
                                {referred_to_details?.address || "--"}
                              </td>
                              <td className="text-nowrap">{referDate}</td>
                              <td className="text-nowrap">{status}</td>
                              <td className="text-nowrap">
                                {actionDate ? actionDate : "-"}
                              </td>
                              <td className="text-nowrap">
                                {reason ? reason : "-"}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="no-data-rw">
                          <td colSpan="11">
                            <NodataFoundBlock />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {tabData?.data?.length > 0 && (
                    <PaginationBlock
                      limit={limit}
                      page={referralByPage[tabIndex] || 1}
                      pagingCounter={referralByPaging[tabIndex] || 1}
                      docLength={limit || 0}
                      docPage={referralByPage[tabIndex]}
                      totalPages={totalPages || 1}
                      totalDocs={tabData?.data?.length || 0}
                      _handleLimit={(val) => {
                        setLimit(val);
                      }}
                      _handlePageChange={(e) => {
                        if (e > referralByPage[tabIndex]) {
                          const diff = e - referralByPage[tabIndex];
                          setReferralByPaging((prev) => {
                            const updatedPaging = [...prev];
                            updatedPaging[tabIndex] =
                              updatedPaging[tabIndex] + limit * diff;
                            return updatedPaging;
                          });
                        } else if (e < referralByPage[tabIndex]) {
                          const diff = referralByPage[tabIndex] - e;

                          setReferralByPaging((prev) => {
                            const updatedPaging = [...prev];
                            updatedPaging[tabIndex] = Math.max(
                              updatedPaging[tabIndex] - limit * diff,
                              0
                            );
                            return updatedPaging;
                          });
                        }
                        setReferralByPage((prevPages) => {
                          const updatedPages = prevPages?.map((page, index) =>
                            index === tabIndex ? e : page
                          );
                          return updatedPages;
                        });
                      }}
                    />
                  )}
                </div>
              );
            })}
            {refferalData?.commonlyReferralTo?.map((tabData, tabIndex) => {
              const totalItems = tabData?.data?.length;
              const totalPages = Math?.ceil(totalItems / limit);
              const paginatedData = getRefferToData(tabData?.data, tabIndex);

              return (
                <div className="table-one cmb-20" key={tabIndex}>
                  <div className="text-14-600 color-2E65 cmb-10">
                    Referrals To {tabData.referred_to} ({tabData?.data?.length})
                  </div>

                  <Table responsive>
                    <thead>
                      <tr>


                      <th style={{width: '10%'}}>Patient</th>
                        <th style={{width: '10%'}} >Referred to</th>
                        <th style={{width: '40%'}}>Clinic Address</th>
                        <th style={{width: '10%'}}>Refer Date</th>
                        <th style={{width: '10%'}} >Status</th>
                        <th style={{width: '10%'}}>Action Date</th>
                        <th style={{width: '10%'}}>Reason</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      {tableData?.loading && (
                        <tr className="no-data-rw">
                          <td
                            colSpan="8"
                            className="d-flex justify-content-center cpt-50 cpb-50"
                          >
                            <LoaderUI />
                          </td>
                        </tr>
                      )}
                      {!tableData?.data?.loading &&
                      paginatedData?.length > 0 ? (
                        paginatedData?.map((dataElem, dataIndex) => {
                          const {
                            patient_details,
                            referred_to_details,
                            refer_date,
                            status,
                            action_date,
                            reason,
                          } = dataElem;
                          const referDate =
                            moment(refer_date).format("MM/DD/YYYY");

                          const actionDate = action_date
                            ? moment(action_date).format("MM/DD/YYYY")
                            : null;

                          return (
                            <tr key={dataIndex}>
                              <td className="text-nowrap">
                                <div className="text-start pic-cell d-inline-flex align-items-center">
                                  <div className="pic d-inline-flex position-relative">
                                    {patient_details?.pet_image ? (
                                      <img
                                        className="img-fluid w-100 h-100"
                                        src={patient_details?.pet_image}
                                        alt="Patient"
                                      />
                                    ) : (
                                      <ImagePlaceholder />
                                    )}
                                  </div>
                                  <div>
                                    {titleCaseString(patient_details?.pet_name)}
                                  </div>
                                </div>
                              </td>
                              <td className="text-nowrap">
                                <div className="text-start pic-cell d-inline-flex align-items-center justi">
                                  <AppLogoSmall />
                                  {titleCaseString(
                                    referred_to_details?.clinic_name
                                  )}
                                </div>
                              </td>
                              <td className="text-nowrap">
                                {referred_to_details?.address || "--"}
                              </td>
                              <td className="text-nowrap">{referDate}</td>
                              <td className="text-nowrap">{status}</td>
                              <td className="text-nowrap">
                                {actionDate ? actionDate : "-"}
                              </td>
                              <td className="text-nowrap">
                                {reason ? reason : "-"}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="no-data-rw">
                          <td colSpan="11">
                            <NodataFoundBlock />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {tabData?.data?.length > 0 && (
                    <PaginationBlock
                      limit={limit}
                      page={referralToPage[tabIndex] || 1}
                      pagingCounter={referralToPaging[tabIndex] || 1}
                      docLength={limit || 0}
                      docPage={referralToPage[tabIndex] || 1}
                      totalPages={totalPages || 1}
                      totalDocs={tabData?.data?.length || 0}
                      _handleLimit={(val) => {
                        setLimit(val);
                      }}
                      _handlePageChange={(e) => {
                        if (e > referralToPage[tabIndex]) {
                          const diff = e - referralToPage[tabIndex];
                          setReferralToPaging((prev) => {
                            const updatedPaging = [...prev];
                            updatedPaging[tabIndex] =
                              updatedPaging[tabIndex] + limit * diff;
                            return updatedPaging;
                          });
                        } else if (e < referralToPage[tabIndex]) {
                          const diff = referralToPage[tabIndex] - e;

                          setReferralToPaging((prev) => {
                            const updatedPaging = [...prev];
                            updatedPaging[tabIndex] = Math.max(
                              updatedPaging[tabIndex] - limit * diff,
                              0
                            );
                            return updatedPaging;
                          });
                        }

                        setReferralToPage((prevPages) => {
                          const updatedPages = prevPages?.map((page, index) =>
                            index === tabIndex ? e : page
                          );
                          return updatedPages;
                        });
                      }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralManagementReports;
