import React, { useEffect, useState } from "react";
import { Button, Input } from "reactstrap"; // eslint-disable-line
import { getAndSearchPointOfSales } from "../../../context/actions/inventory";
import { useDispatch, useSelector } from "react-redux";

const TableListFilterBlock = () => {

	const { pointOfSales } = useSelector(state => state.inventoryReducer);
	const dispatch = useDispatch();



	const [inputText, setInputText] = useState('');
	const [selectedPetname, setSelectedPetname] = useState('');
	const [selectedTimeframe, setSelectedTimeframe] = useState('');

	const [petNames, setPetNames] = useState([])


	useEffect(() => {
		handleGetPointOfSales();
	}, [inputText, selectedPetname, selectedTimeframe])// eslint-disable-line




	const handleGetPointOfSales = () => {
		dispatch(getAndSearchPointOfSales({ petName: selectedPetname, searchText: inputText, timeframe: selectedTimeframe }, () => {
		}))
	}

	const handlePetnames = () => {
		let names = pointOfSales?.docs?.map((obj) => obj.patientDetails.name);
		names = [...new Set(names)]
		setPetNames(names)


	}

	useEffect(() => {
		handlePetnames();
	}, []); // eslint-disable-line


	return (
		<>
			<div className="table-list-filter-blc d-flex align-items-center">
				<div className="search-cell col-cell position-relative">
					<span className="icomoon-search d-inline-flex align-items-center justify-content-center position-absolute"></span>
					<Input placeholder="Search" onChange={(e) => setInputText(e.target.value)} />
				</div>
				<div className="input-cell col-cell">
					<Input type="select" name="select" onChange={(e) => setSelectedTimeframe(e.target.value)}>
						<option>Date Range</option>
						<option>Last 7 Days</option>
					</Input>
				</div>
				<div className="input-cell col-cell">
					<Input type="select" name="select" onChange={(e) => setSelectedPetname(e.target.value)}>
						<option>All Pets</option>
						{(petNames || []).map((item) =>
							<option>{item}</option>
						)}
					</Input>
				</div>
			</div>
		</>
	);
};

export default TableListFilterBlock;
