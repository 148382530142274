import { Offcanvas } from "react-bootstrap";
import * as Yup from "yup";
import { Formik } from "formik";
import TextInput from "shared/FormsInput/TextBox";
import { Button, FormGroup, Input, Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { petGenderValues } from "utils/constants";
import PetPlaceholder from "shared/PetPlaceholder";
import UploadImageBlock from "shared/UploadImageBlock";
import { getAllBreeds } from "context/actions/app-content";
import {
  addPetForOwner,
  updatePetForOwner,
  updatePetImageForOwner,
} from "context/actions/patient-module/pet-owner";
import { useState } from "react";
import { getPetOwnerPets } from "context/actions/pet-owner-dashboard";
import moment from "moment";
import Select from "react-select";


export const PetForm = ({ onHide, editData }) => {
  const dispatch = useDispatch();
  const { petDetails } = useSelector(
    (state) => state.petOwnerDashboardReducer
  );
  const userDetail = useSelector((state) => state.user.user_details);
  const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);

  const { species: speciesList, breeds } = useSelector(
    (state) => state.appContentReducer
  );
  const [btnLoading, setBtnLoading] = useState(false);
  const handleSave = async (values) => {
    setBtnLoading(true);
    let payload = { ...values };
    delete payload.imageFile;
    delete payload.imageUrl;
    if (payload?.allergy_tag?.length === 0) {
      delete payload.allergy_tag;
    }

    if (editData) {
      let payloadData = {};
      for (let key in payload) {
        if (payload[key]) {
          payloadData[key] = payload[key];
        }
      }
      handleEdit(payload, values);
    } else {
      let payloadData = new FormData();
      for (let key in payload) {
        if (payload[key]) {
          payloadData.append(key, payload[key]);
        }
      }
      handleAdd(payloadData, values);
    }
  };
  const handleAdd = async (payloadData, values) => {
    const response = await dispatch(addPetForOwner(payloadData));
    if (response?.status && response?.statusCode === 201) {
      uploadProfile(response?.response_data?._id, values);
    } else {
      setBtnLoading(false);
    }
  };

  const handleEdit = async (payloadData, values) => {
    const response = await dispatch(
      updatePetForOwner(editData?._id, payloadData)
    );
    if (response?.status && response?.statusCode === 200) {
      uploadProfile(editData?._id, values);
    } else {
      setBtnLoading(false);
    }
  };

  const uploadProfile = async (petID, values) => {

    if (values?.imageFile) {
      var imgPayloadData = new FormData();
      imgPayloadData.append("pet", petID);
      imgPayloadData.append("pet_image", values?.imageFile);
      await dispatch(updatePetImageForOwner(imgPayloadData));
    }
      const response = await dispatch(getPetOwnerPets(userDetail?.id));
      if(petDetails?._id == petID){
        if(response?.response_data?.length > 0){
          const data = response?.response_data?.find(pet => pet?._id === petID)
          if(data) {
            dispatch({
              type: "SET_PET_DETAILS",
              payload: data,
            });
          }
        }
      }
      setBtnLoading(false);
      onHide();
  };

  const validationSchema = Yup.object().shape({
    pet_name: Yup.string().required("Pet name is required."),
    dob: Yup.string().required("Birth date is required."),
    species: Yup.string().required("Species is required."),
    gender: Yup.string().required("Gender is required."),
    breed: Yup.string().required("Breed is required."),
    color: Yup.string().required("Color is required."),
    deceased: Yup.bool().required("Status is required"),

  });

  const initialValues = {
    pet_name: editData?.pet_name || "",
    dob: editData?.dob
      ? moment(editData?.dob, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format("YYYY-MM-DD")
      : "",
    gender: editData?.gender || "",
    species: editData?.species || "",
    breed: editData?.breed || "",
    color: editData?.color || "",
    pet_notes: editData?.pet_notes || "",
    rabies_number: editData?.rabies_number || "",
    microchip_number: editData?.microchip_number || "",
    license_number: editData?.license_number || "",
    cpr: editData?.cpr || "",
    allergy_tag: [],
    added_by: editData?.added_by || userDetail?._id,
    deceased: editData?.deceased || false,
    //clinic: "6372362ea71fc304403f8d6e",
    pet_owner: userDetail?._id,
    imageUrl: editData?.pet_image || "",
    imageFile: "",
  };
  return (
    <Offcanvas show={true} onHide={onHide} placement="end" id="pet-1">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          {editData ? "Edit " : ""}Pet Information
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Formik
          onSubmit={handleSave}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {(props) => {
            const {
              values,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
            } = props;
            const {
              pet_name,
              color,
              dob,
              species,
              gender,
              breed,
              rabies_number,
              microchip_number,
              license_number,
              cpr,
              deceased,
              //   allergy_tag,
              //   behaviour_tag,
              pet_notes,
              imageUrl,
              //   imageFile,
            } = values;
            const {
              pet_name: err_pet_name,
              color: err_color,
              dob: err_dob,
              species: err_species,
              gender: err_gender,
              breed: err_breed,
              deceased: err_deceased,
            } = errors;

            return (
              <form className="row">
                <FormGroup className="col-md-6">
                  <label>Profile Picture</label>
                  <div className="pic-cell pet">
                    <div className="pic d-inline-flex overflow-hidden position-relative">
                      <span className="upload-cell position-absolute w-100 h-100 d-inline-flex align-items-center justify-content-center">
                        <UploadImageBlock
                          petImage={imageUrl}
                          sendPetImage={() => {}}
                          petImageObj={(value) => {
                            setFieldValue(
                              "imageUrl",
                              URL.createObjectURL(value)
                            );
                            setFieldValue("imageFile", value);
                          }}
                        />
                      </span>
                      {imageUrl ? (
                        <img
                          className="img-fluid w-100 h-100"
                          src={imageUrl}
                          alt="Pet"
                        />
                      ) : (
                        <PetPlaceholder style={{ borderRadius: "10px" }} />
                      )}
                    </div>
                  </div>
                  {/* <div className="h-99 w-99">
                    <PetPlaceholder style={{ borderRadius: "10px" }} />
                  </div> */}
                </FormGroup>
                <div className="mb-2 col-md-6">
                  <TextInput
                    isRequired
                    id="pet_name"
                    value={pet_name}
                    label="Pet Name"
                    error={err_pet_name}
                    onChange={handleChange}
                    placeholder="Enter Pet Name"
                  />
                </div>
                <FormGroup className="col-md-6">
                  <label className="required-input">Date of Birth</label>
                  <Input
                    id="dob"
                    type="date"
                    value={dob}
                    label="Date of Birth"
                    onChange={handleChange}
                    placeholder="Enter Date of Birth"
                  />
                  {err_dob && (
                    <div className="text-12-400 text-danger mt-1">
                      {err_dob}
                    </div>
                  )}
                </FormGroup>
                <FormGroup className="col-md-6">
                  <label className="required-input">Species</label>


                  <Select
                      className="w-100 h-[48px]"
                                                placeholder={'Select Species'}
                                                value={
                                                  speciesList?.find(i => i?._id == species) || ""}
              getOptionLabel={data => data?.species_name }
              getOptionValue={data => data?._id}
                                                options={
                                                speciesList }

                                                styles={{
                                                  control: (baseStyles, state) => (
                                                    {...baseStyles,
                                                      fontSize: '16px',
                                                      height: '48px',
                                                      borderRadius: '8px',
                                                      color: '#1d1d1d',
                                                      padding: '0.375rem 15px',
                                                      backgroundColor: '#fff',
                                                      border: '1px solid rgba(8, 8, 41, 0.1)',
                                                      boxShadow: '0px 4px 10px rgba(61, 57, 137, 0.05)',
                                                    })
                                                }}
                                                // isMulti={true}
                                                onChange={i => {
                                                  console.log(i)

                                                  dispatch(getAllBreeds(i?._id, () => {}));
                    
                                                  setFieldValue("breed", "");
                                                  setFieldValue("species", i?._id)
              

                                                }}
                                    />
                
                  {err_species && (
                    <div className="text-12-400 text-danger mt-1">
                      {err_species}
                    </div>
                  )}
                </FormGroup>
                <FormGroup className="col-md-6">
                  <label className="required-input">Gender</label>

                  <Select
                      className="w-100 h-[48px]"
                                                placeholder={'Select Gender'}
                                                value={petGenderValues?.find( i => i?.value == gender)
                                  
                                                }
                                                options={petGenderValues}

                                                styles={{
                                                  control: (baseStyles, state) => (
                                                    {...baseStyles,
                                                      fontSize: '16px',
                                                      height: '48px',
                                                      borderRadius: '8px',
                                                      color: '#1d1d1d',
                                                      padding: '0.375rem 15px',
                                                      backgroundColor: '#fff',
                                                      border: '1px solid rgba(8, 8, 41, 0.1)',
                                                      boxShadow: '0px 4px 10px rgba(61, 57, 137, 0.05)',
                                                    }),
                                                  input: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    textTransform: 'capitalize'
                                                  })
                                                }}
                                                // isMulti={true}
                                                onChange={i => {
                                              
                    
                                                  setFieldValue("gender", i?.value);
              

                                                }}
                                    />
                  {/* <Input
                    id="gender"
                    type="select"
                    value={gender}
                    onChange={handleChange}
                  >
                    <option value="">Select gender</option>
                    {(petGenderValues || []).map((item, i) => (
                      <option
                        key={i}
                        value={item.value}
                        style={{ textTransform: "capitalize" }}
                      >
                        {item.label}
                      </option>
                    ))}
                  </Input> */}
                  {err_gender && (
                    <div className="text-12-400 text-danger mt-1">
                      {err_gender}
                    </div>
                  )}
                </FormGroup>
                 {clinic_settings?.pet_owner_preferences?.set_status &&
                <FormGroup className="col-md-6">
                  <label className="required-input">Status</label>

                  <Select
                      className="w-100 h-[48px]"
                                                placeholder={'Select Status'}
                                                value={
                                                  {value: deceased, label: deceased ? 'Deceased' : 'Alive'}}
                                           options={
                                                [{value: true, label: 'Deceased'}, {value: false, label: 'Alive'}] }

                                                styles={{
                                                  control: (baseStyles, state) => (
                                                    {...baseStyles,
                                                      fontSize: '16px',
                                                      height: '48px',
                                                      borderRadius: '8px',
                                                      color: '#1d1d1d',
                                                      padding: '0.375rem 15px',
                                                      backgroundColor: '#fff',
                                                      border: '1px solid rgba(8, 8, 41, 0.1)',
                                                      boxShadow: '0px 4px 10px rgba(61, 57, 137, 0.05)',
                                                    })
                                                }}
                                                // isMulti={true}
                                                onChange={i => {
                                              
                    
                                                  setFieldValue("deceased", i?.value);
              

                                                }}
                                    />
                

                
                  {/* <Input
                    id="deceased"
                    type="select"
                    value={deceased}
                    onChange={handleChange}
                  >
                    <option value={false}>Alive</option>
                    <option value={true}>Deceased</option>
    
                  </Input> */}
                  {err_deceased && (
                    <div className="text-12-400 text-danger mt-1">
                      {err_deceased}
                    </div>
                  )}
                </FormGroup>}


                <FormGroup className="col-md-6">
                  <label className="required-input">Breed</label>


                  <Select
                      className="w-100 h-[48px]"
                                                placeholder={'Select Breed'}
                                                value={
                                                  breeds?.find(i => i?._id == breed) || ""}
              getOptionLabel={data => data?.breed }
              getOptionValue={data => data?._id}
                                                options={
                                                breeds }

                                                styles={{
                                                  control: (baseStyles, state) => (
                                                    {...baseStyles,
                                                      fontSize: '16px',
                                                      height: '48px',
                                                      borderRadius: '8px',
                                                      color: '#1d1d1d',
                                                      padding: '0.375rem 15px',
                                                      backgroundColor: '#fff',
                                                      border: '1px solid rgba(8, 8, 41, 0.1)',
                                                      boxShadow: '0px 4px 10px rgba(61, 57, 137, 0.05)',
                                                    })
                                                }}
                                                // isMulti={true}
                                                onChange={i => {
                                              
                    
                                                  setFieldValue("breed", i?._id);
              

                                                }}
                                    />
                
                  {err_breed && (
                    <div className="text-12-400 text-danger mt-1">
                      {err_breed}
                    </div>
                  )}
                </FormGroup>
                {/* <FormGroup className="col-md-6">
                  <label className="required-input">Breed</label>
                  <Input
                    id="breed"
                    type="select"
                    value={breed}
                    disabled={!species}
                    onChange={handleChange}
                  >
                    <option value="">Select breed</option>
                    {(breeds || []).map((item, i) => (
                      <option
                        key={i}
                        value={item._id}
                        style={{ textTransform: "capitalize" }}
                      >
                        {item.breed}
                      </option>
                    ))}
                  </Input>
                  {err_breed && (
                    <div className="text-12-400 text-danger mt-1">
                      {err_breed}
                    </div>
                  )}
                </FormGroup> */}
                <div className="col-md-6 mb-2">
                  <TextInput
                    isRequired
                    id="color"
                    value={color}
                    label="Color"
                    error={err_color}
                    onChange={handleChange}
                    placeholder="Enter color"
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <TextInput
                    id="rabies_number"
                    value={rabies_number}
                    label="Rabies Tag Number"
                    onChange={handleChange}
                    placeholder="Enter rabies tag number"
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <TextInput
                    id="microchip_number"
                    value={microchip_number}
                    label="Microchip Number"
                    onChange={handleChange}
                    placeholder="Enter microchip number"
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <TextInput
                    id="license_number"
                    value={license_number}
                    label="License Number"
                    onChange={handleChange}
                    placeholder="Enter license number"
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <TextInput
                    id="cpr"
                    value={cpr}
                    label="CPR Status"
                    onChange={handleChange}
                    placeholder="Enter CPR status"
                  />
                </div>
                {/* <FormGroup className="col-md-6">
                  <label>Patient Notes (For office use only)</label>
                  <Input
                    id="pet_notes"
                    type="textarea"
                    value={pet_notes}
                    onChange={handleChange}
                    rows="2"
                  />
                </FormGroup> */}
              
                <div className="d-flex align-items-center justify-content-end">
                  <Button
                    color="success"
                    type="button"
                    className="btn btn-outline-light"
                    onClick={handleSubmit}
                    disabled={
                      btnLoading ||
                      JSON.stringify(initialValues) === JSON.stringify(values)
                    }
                  >
                    {editData ? "Update" : "Submit"}
                    {btnLoading && (
                      <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                        className="ms-2"
                      />
                    )}
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
