import { useLayoutEffect } from 'react';

class ResizeObserver {
  constructor(callback) {
    this.callback = callback;
  }

  observe(target) {
    this.target = target;
  }

  unobserve() {
    this.target = null;
  }

  disconnect() {
    this.target = null;
    this.callback = null;
  }
}

function useSizeCallback(target, callback) {
  useLayoutEffect(() => {
    if (!target) {
      return () => {
        // Cleanup logic if needed
      };
    }

    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        callback({
          width: entry.target.clientWidth,
          height: entry.target.clientHeight,
        });
      });
    });

    resizeObserver.observe(target);

    return () => {
      resizeObserver.unobserve(target);
      resizeObserver.disconnect();
    };
  }, [target, callback]);
}

export { useSizeCallback, ResizeObserver };
