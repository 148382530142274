import React, { useState } from "react";
import Modal from "../../../../shared/Modal";

const EditService = ({ index, list, setList }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const addData = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    const formData = Object.fromEntries(form.entries());
    const { name, code, amount, description } = formData;

    if (!name || !code || !amount) {
      alert("Please complete necessary fields!");
      return;
    }
    const updatedList = [...list];
    updatedList[index] = { name, code, amount, description };
    setList(updatedList);
    handleCloseModal();
  };
  return (
    <>
      <button
        onClick={handleOpenModal}
        className=" text-gray-400 hover:text-gray-500"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
        </svg>
      </button>
      <Modal isOpen={isOpen} onClose={handleCloseModal} showCloseButton={true}>
        <form onSubmit={addData} className="p-3 md:p-5 space-y-4">
          <h4 className="text-2xl">Add New Service</h4>
          <div className="space-y-1">
            <label className="text-sm text-gray-500">Service Name</label>
            <input
              placeholder="Add Service Name"
              name="name"
              defaultValue={list[index].name ?? ""}
              className="bg-gray-100 w-full rounded-md px-2.5 py-1.5 placeholder:text-sm"
            />
          </div>
          <div className="space-y-1">
            <label className="text-sm text-gray-500">Service Code</label>
            <input
              placeholder="Add Service Code"
              name="code"
              defaultValue={list[index].code ?? ""}
              className="bg-gray-100 w-full rounded-md px-2.5 py-1.5 placeholder:text-sm"
            />
          </div>
          <div className="space-y-1">
            <label className="text-sm text-gray-500">Service Amount</label>
            <input
              placeholder="Add Service Price"
              name="amount"
              defaultValue={list[index].amount ?? ""}
              className="bg-gray-100 w-full rounded-md px-2.5 py-1.5 placeholder:text-sm"
            />
          </div>
          <div className="space-y-1">
            <label className="text-sm text-gray-500">Description</label>
            <textarea
              name="description"
              rows={4}
              className="resize-none w-full bg-gray-100 rounded-md px-2.5 py-1.5"
            >
              {list[index].description ?? ""}
            </textarea>
          </div>
          <div className="flex gap-x-2 items-center">
            <button
              type="reset"
              onClick={handleCloseModal}
              className="w-1/2 bg-gray-100 hover:bg-gray-200 py-2 rounded-md"
            >
              Discard
            </button>
            <button
              type="submit"
              className="w-1/2 bg-blue-500 text-white py-2 rounded-md"
            >
              Add Data
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default EditService;
