import React, { useEffect, useState } from "react";
import { Button, Collapse, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { BiCheck } from "react-icons/bi";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import jsPDF from "jspdf";

import * as htmlToImage from "html-to-image";


import "./index.scss";
import {getIdexxResult, getAntechResult} from "context/actions/patient-module/diagnostic-testing/index"
import { getActiveIntegrations } from "context/actions/clinicSettings";

const AccordionsForRangeValue = ({ data, currentDate }) => {
  const [toggleIds, setToggleIds] = useState([]);
  const [viewIdexxPDF, setViewIdexxPDF] = useState(false)
  const dispatch = useDispatch();

  const {labResultsViewerData} = useSelector(state => state?.user)

  const [isDownload, setIsDownload] = useState(false);

  const downloadReport = () => {
    setIsDownload(true);
    setTimeout(() => {
      managePdfPages();
    }, 500);
  };


  const viewIdexxResult = (result_id) => {
    dispatch(getIdexxResult(result_id, (res) => {
      if(res?.status && res?.response_data?.uiURL){
        dispatch({
          type: "SET_FILE_VIEWER_DATA",
          data: {
            title: 'Idexx Result',
            fileURL: res?.response_data?.uiURL
            },
          });

      }
    }))

  }



  const viewAntechResult = (result_id) => {
    dispatch(getAntechResult(result_id, (res) => {
      if(res?.status && res?.response_data?.uiURL){

        console.log('setting antech result', res)
   
          dispatch({
            type: "SET_LAB_RESULTS_VIEWER_DATA",
            data: {
              title: 'Antech Result',
              url: res?.response_data?.uiURL,
            },
          }).then(console.log(labResultsViewerData)
        );


      }
    }))

  }




  const managePdfPages = () => {
    var nodes = document.querySelectorAll(".pdf-data");
    const pdf = new jsPDF("p", "mm", "a4");
    const pageHeight = pdf.internal.pageSize.getHeight() - 20;

    const addImageToPDF = (index, currentY) => {
      if (index < nodes.length) {
        const node = nodes[index];
        htmlToImage.toPng(node).then((dataUrl) => {
          const img = new Image();
          img.src = dataUrl;

          // Adjust image size and position as needed
          let pdfHeight;
          const pdfWidth = pdf.internal.pageSize.getWidth();
          if (
            node.clientHeight > pdf.internal.pageSize.getHeight() ||
            node.clientHeight < pdf.internal.pageSize.getHeight()
          ) {
            pdfHeight = (node.clientHeight * pdfWidth) / node.clientWidth;
          } else {
            pdfHeight = pdf.internal.pageSize.getHeight();
          }

          if (currentY + pdfHeight > pageHeight && index != 0) {
            // Move to the next page
            pdf.addPage();
            currentY = 0; // Reset currentY for the new page
          }

          // Add the image to the current page
          pdf.addImage(img, "PNG", 0, currentY, pdfWidth, pdfHeight);
          currentY += pdfHeight;

          // Add a new page for the next image if there are more images
          if (index < nodes.length - 1) {
            addImageToPDF(index + 1, currentY);
          } else {
            // Save the PDF once all images are added
            pdf.save(
              `diagnostic_testing_${moment(currentDate).format(
                "DD_MM_YYYY"
              )}.pdf`
            );
            setTimeout(() => {
              setIsDownload(false);
            }, 3000);
          }
        });
      }
    };

    addImageToPDF(0, 0);
  };

  useEffect(() => {
    const categoryIds = [];
    data?.map((category, i) => {
      return categoryIds.push(i);
    });
    setToggleIds(categoryIds);
  }, [data]);


  useEffect(() => {
    dispatch(getActiveIntegrations((response) => {
            if(response?.status && response?.response_data?.length > 0){
              if(response?.response_data?.filter(a => (a?.app === 'Idexx' && a?.view_pdf))?.length > 0){
                setViewIdexxPDF(true)
              }
            }
        }))
  }, [])

  const toggleFunction = (index) => {
    let ids = [...toggleIds];
    if (ids.includes(index)) {
      ids = ids.filter((i) => i !== index);
    } else {
      ids.push(index);
    }
    setToggleIds(ids);
  };

  return (
    <div id="pdf-data">
    <div
      className="diagnostic-accordion-wrapper"
      id="diagnostic-accordion-wrapper"
    >
      {data?.map((report, i) => {
        return (



              <>
              <div style={{backgroundColor: 'white', padding: '10px'}}>
              <div className="diagnostic-listing-report-summary-heading pb-[5px] mt-2  gap-[4px]">
                <h3 className="font-semibold text-lg">Report Summary</h3>
              </div>
              <div className="diagnostic-listing-report-summary flex flex-row grid grid-cols-4 gap-3 text-[14px]">
                <div className="Summary grid gap-y-[5px] col-start-1 col-span-3">
                  <div className="report-data flex flex-row grid grid-cols-5 gap-4">
                    <div className="report-label">Result Date</div>
                    <div className="report-label-data col-start-2 col-span-4">
                      {moment(
                        report?.resultDetails?.updatedDate).format("MM/DD/YYYY HH:MM:SS")}{" "}
                    </div>
                  </div>
                  <div className="report-data flex flex-row grid grid-cols-5 gap-4">
                    <div className="report-label">Order Date</div>
                    <div className="report-label-data col-start-2 col-span-4">
                      {moment(
                        report?.resultDetails?.orderReceivedDate
                      ).format("MM/DD/YYYY HH:MM:SS")}{" "}
                    </div>
                  </div>
                  <div className="report-data flex flex-row grid grid-cols-5 gap-4">
                    <div className="report-label">Order By</div>
                    <div className="report-label-data col-start-2 col-span-4 flex flex-col space-x-7">
                      {/* <div className="pic d-inline-flex position-relative">
                        <img
                          className="w-[20px] h-[20px] mr-[4px] bg-gray-200"
                          src={
                            diagnosisTestResults?.reportList?.[0]
                              ?.reportsByDate?.[0]?.reports?.[0]
                              ?.userProfileImage ||
                            require("../../../../../assets/img/vetRecorEMR/user-thumbnail.png")
                          }
                          alt="owner"
                          style={{ borderRadius: "100px" }}
                        />
                      </div> */}
                      <div>
                        {" "}
                        {
                          report?.resultDetails?.veterinarian || '--'
                        }{" "}
                      </div>
                    </div>
                  </div>
                  <div className="report-data flex flex-row grid grid-cols-5 gap-4">
                    <div className="report-label">Status</div>
                    <div className="report-label-data col-start-2 col-span-4">
                      {report?.resultDetails?.status}
                    </div>
                  </div>
                  <div className="report-data flex flex-row grid grid-cols-5 gap-4">
                    <div className="report-label">Test Summary</div>
                    <div className="report-label-data col-start-2 col-span-4">
                        {report?.resultDetails?.notes?.content || report?.resultDetails?.unitCodes?.join(', ') || '--'}
                    </div>
                  </div>
                </div>
                <div className="download-share-data pr-[10px]">
                  <>
                    <div className="download-report w-full border border-1 rounded-lg border-gray-100 text-[12px] font-semibold text-center justify-center pt-[3px] pb-[3px] mb-[8px]">
                      <button
                        className={isDownload ? "disabled" : ""}
                        onClick={isDownload ? "" : downloadReport}
                      >
                        DOWNLOAD REPORT{" "}
                        {isDownload && (
                          <span>
                            <div
                              class="spinner-border spinner-border-sm"
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          </span>
                        )}
                      </button>
                    </div>
                    <div className="share-report w-full border border-1 border-gray-100 rounded-lg text-[12px] font-semibold text-center justify-center pt-[3px] pb-[3px] mb-[8px]">
                      <button>SHARE REPORT</button>
                    </div>
                  </>

                  { (viewIdexxPDF && report?.resultDetails?.idexxResultId) && 

                    <div className="share-report w-full border border-1 border-gray-100 rounded-lg text-[12px] font-semibold text-center justify-center pt-[3px] pb-[3px]">
                      <button onClick={() => {viewIdexxResult(report?.resultDetails?.idexxResultId)}}>VIEW IDEXX RESULT</button>
                    </div>
                  }



                  { (report?.resultDetails?.antechLabAccession) && 

                  <div className="share-report w-full border border-1 border-gray-100 rounded-lg text-[12px] font-semibold text-center justify-center pt-[3px] pb-[3px]">
                    <button onClick={() => {viewAntechResult(report?.resultDetails?.antechLabAccession)}}>VIEW ANTECH RESULT</button>
                  </div>
                  }
                </div>
              </div>

              </div>
            
          <div className="accordion-blc d-flex flex-column pdf-data">
            <div className="accordion-btn-wrapper d-flex align-items-center">
              <Button
                color="link"
                className={`accordion-btn text-start shadow-none text-left d-flex align-items-center active`}
                onClick={() => toggleFunction(i)}
              >
                {toggleIds.includes(i) ? <FaChevronUp /> : <FaChevronDown />} 
                {/* <label className="ms-2">{category?.category}</label> */}
              </Button>
            </div>
            <Collapse isOpen={isDownload ? true : toggleIds.includes(i)}>
              <div className="overflow-auto scroll-visibility">
                <div
                  className={`flex flex-row w-fit ${
                    isDownload ? "flex-wrap gap-2" : ""
                  }`}
                >
                  {report?.categories?.map((category, j) => {
                    return (
                      <>
                        <div className="collapse-data-blc w-[400px]">
                          <div className="pt-[5px] pb-[5px] pl-[5px]">
                            {/* Result Time{" "}
                            {moment(dateWiseData?.resultDate).format(
                              "hh:mm:ss a"
                            )} */}
                            {category?.category}
                          </div>
                          <Table responsive striped id={`new-table-${i}-${j}`}>
                            <thead>
                              <tr>
                                <th className="text-[13px]">Test Name</th>
                                <th className="reange-cell text-[13px]">
                                  Range
                                </th>
                                <th className="text-[13px]">Value</th>
                                {/* <th className="text-[13px]"></th> */}
                              </tr>
                            </thead>

                            <tbody>
                              {category?.reports?.map((test, k) => {
                                return (
                                  <>
                                    <tr key={k} className="text-[12px]">
                                      <td>{test?.testName}</td>
                                      {test?.referenceRange && test?.referenceRange !== "" ? (
                                        <td style={{whiteSpace: 'nowrap'}}>{`${test?.referenceRange}`}</td>
                                      ) : (
                                        <td>--</td>
                                      )}

                                        <td className={test?.outOfRange ? test?.outOfRangeFlag?.includes('L') ? `text-primary`: 'text-danger' : '' }>
                                          {test?.result} {test?.outOfRangeFlag || ''}
                                        </td>
                                      {/* <td>
                                        {test?.result >
                                          test?.low &&
                                        test?.result <
                                          test?.high ? (
                                          <BiCheck style={{ color: "green" }} />
                                        ) : (
                                          ""
                                        )}
                                      </td> */}
                                    </tr>
                                  </>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </Collapse>
          </div>
          </>
        );
      })}
  
    </div>
    </div>
  );
};
export default AccordionsForRangeValue;
