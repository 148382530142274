import { FieldArray, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, InputGroup, Row, FormFeedback, FormGroup } from "reactstrap";
import { orderInit, orderSchema } from "./schema";
import { useState, useEffect } from "react";
import ItemSelection from "./ItemSelection";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getIVLSMachines } from "../../../../../../context/actions/patient-module/diagnostic-testing";
import moment from "moment";
import { getMARSByPetId, getMarsOrdersByPet } from "../../../../../../context/actions/patient-module/mars";
import { addNewOrder, getHomeMedicationOrders, getOrdersWithCategoryByPetId } from "../../../../../../context/actions/patient-module/orders";
import { placeDiagnosticOrder } from "context/actions/patient-module/diagnostic-testing";
import { useClinicSpeciesDetails } from "hooks/useClinicSpecies";
import { getActiveIntegrations } from "context/actions/clinicSettings";
import { getAllAntechSpeciesByClinicId, getAllIdexxSpeciesByClinicId } from "context/actions/external-species";
import { getIdexxBreedsBySpecies, getAntechBreedsBySpecies } from "context/actions/external-breeds";
import {useClinicBreedDetails}  from "hooks/useClinicBreed"


/**
 * Panel containing form for generating new patient orders
 * @param {boolean} isActive indicates status of Order Form Panel
 * @param {function toggleMedicationPanel(params) { closes medication panel }}
 * @returns 
 */

const OrderFormPanel = ({
  isActive,
  toggleOrderPanel
}) => {
  const dispatch = useDispatch();

  const clinicSpecies = useClinicSpeciesDetails();

  const clinicBreed = useClinicBreedDetails();

  const userDetails = useSelector((state) => state.user.user_details);
  const {clinic_details } = useSelector(
    (state) => state.clinicReducer
  );

  const [ivlsMachines, setIVLSMachines] = useState(false);

  const params = useParams();
  const petId = params.petId;
  const { petDetails } =useSelector((state) => state.petReducer)
  const [isSelectingItem, setIsSelectingItem] = useState(false);

  const [idexxSpecies, setIdexxSpecies] = useState([])
  const [antechSpecies, setAntechSpecies] = useState([])
  const [idexxBreeds, setIdexxBreeds] = useState([])
  const [antechBreeds, setAntechBreeds] = useState([])


  /**
   * function to submit order form and generate appropriate orders
   * called by formik upon submit
   * @param {values} - formik values to be submitted to add orders endpoint
   */
  const submitForm = (values) => {




    addOrders(values?.items, values?.ivls, {antech_species: values?.antech_species, antech_breed: values?.antech_breed, idexx_species: values?.idexx_species, idexx_breed: values?.idexx_breed})

  };

  /**
   * function to fetch related data, orders and MAR data
   * @returns 
   */

  const fetchData = async () => {
    dispatch(
      getOrdersWithCategoryByPetId(petId, () => {
        dispatch(getMARSByPetId(petId, (result) => { }));
        dispatch(getMarsOrdersByPet(petId, (result) => { }));

      })
    );

    return 1;
  };
  /**
   * function to call appropriate endpoints for each order
   * calling the diagnostic testing order endpoint for test and the inventory order endpoint otherwise
   * @param {Array} items - list of all items to be ordered
   * @param {String} ivls - IVLS machine (required when ordering an at home idexx test)
   * @param {Object} refData - contains species and breed values for lab test orders
   */

  const addOrders = (items, ivls, refData) => {


    // case for diagnostic test services
    if(items?.filter(i =>  i?.test_name)?.length > 0){
      dispatch(placeDiagnosticOrder({...refData, tests: items?.filter(i => i?.test_name)?.map(i => i?.product_id), pet_id:  petId, ivls: ivls, created_by: userDetails._id },  (response) => {

        if(response?.response_data?.length > 0){
          dispatch({
            type: "SET_LAB_ORDER_VIEWER_DATA",
            data: {
              pet: petId,
              urls: response?.response_data,
              index: 0
            },
          });

        }
      }));
    }

    // case for all other items
    for (const i of items.filter(j => !j?.test_name)) {
        let {
          product_name,
          product_id,
          product_description,
          service_name,
          description,
          category,
          ...order_details
        } = i;


        if (order_details?.first_dose) {
          order_details.first_dose = moment(order_details?.first_dose).toDate();
        }
        let order = {
          ...order_details,
          created_by: userDetails._id,
          pet_id: petId,
          type: "in-patient",
          category: category,
          order_type: "in-house",
          //item: product_id,
        };
        if (i?.service_name) {
          order.service = product_id
        } else if (i?.product_name) {
          order.item = product_id
        } else if (i?.test_name){
          order.test_id = product_id
        }
        delete order?.unit_of_measure;
        delete order?.sub_package;

      dispatch(addNewOrder(order, (result) => {
        fetchData();
        return result}));
      }
      dispatch(getHomeMedicationOrders({ pet_id: petId }, () => { }));
      toggleOrderPanel();
      //fetchData();
    }

  // grab reference and ivls data upon render if clinic has active lab integrations
  useEffect(() => {
      // get active integrations
      dispatch(getActiveIntegrations((response) => {
        if(response?.status){ 
          // get idexx ref data and ivls machines if clinic has an active idexx integration
          if( response?.response_data?.length > 0 &&  response?.response_data?.find(i => i?.app === 'Idexx')){
            dispatch(getIVLSMachines((response) => {
              if(response && response?.status) setIVLSMachines(response?.response_data?.ivlsDeviceList)
              }
            ))

              dispatch(getAllIdexxSpeciesByClinicId(clinic_details?._id, {}, (res) => {
                if(res){
                  setIdexxSpecies(res)
                }
              }))
          }
          //get antech ref data if clinic has an active antech integration
          if( response?.response_data?.length > 0 &&  response?.response_data?.find(i => i?.app === 'Antech')){
            dispatch(getAllAntechSpeciesByClinicId(clinic_details?._id, {}, (res) => {
              if(res){
                setAntechSpecies(res)
                
              }
            }))
          }
        }
      }))

  }, [])


  // get antech breeds if species changes
  useEffect(()=> {
    if(clinicSpecies(petDetails?.species?._id)?.antech_mapping?.code){
      var code = clinicSpecies(petDetails?.species?._id)?.antech_mapping?.code
      getAntechBreedFromSpecies(code)
    } 
  }, [antechSpecies])


  // get idexx breeds if species changes
  useEffect(()=> {
    if(clinicSpecies(petDetails?.species?._id)?.idexx_mapping?.code){
      var code = clinicSpecies(petDetails?.species?._id)?.idexx_mapping?.code
      getIdexxBreedFromSpecies(code)
    } 
  }, [idexxSpecies])


  /**
   * function to get antech breeds and set them to local state, called when species changes
   * @param {String} code 
   */

  const getAntechBreedFromSpecies = (code) => {
    var item = antechSpecies.find(i => i?.code === code);
    if(item){
      dispatch(getAntechBreedsBySpecies(item?._id, (res) =>{
        if(res?.status){
          setAntechBreeds(res?.response_data)

        }
      }))
    }  
  }

  /**
   * function to get idexx breeds and set them to local state, called when selected idexx species changes
   * @param {String} code 
   */

  const getIdexxBreedFromSpecies = (code) => {
    var item = idexxSpecies.find(i => i?.code === code);
    if(item){
      dispatch(getIdexxBreedsBySpecies(item?._id,(res) =>{
        if(res?.status){
          setIdexxBreeds(res?.response_data)
        }
      }))
    }
  }

  console.log(orderSchema)

  return (
    <>
      <div
        style={{ maxWidth: '60%' }}
        className={`medication-panel-wrapper fixed flex flex-col ${isActive && "active"
          }`}
      >
        <Formik
          initialValues={{...orderInit, antech_species: clinicSpecies(petDetails?.species?._id)?.antech_mapping?.code, idexx_species: clinicSpecies(petDetails?.species?._id)?.idexx_mapping?.code, antech_breed: clinicBreed(petDetails?.breed?._id)?.antech_mapping?.code, idexx_breed: clinicBreed(petDetails?.breed?._id)?.idexx_mapping?.code}}
          enableReinitialize
          validationSchema={orderSchema}
          onSubmit={submitForm}
        >
          {(formik) => (
            <Form className="max-h-[95%] overflow-y-auto overflow-x-hidden">
              {formik?.errors ? <div>{Object.keys(formik.errors)}</div> : <></>}
              <FieldArray name="items">
                {({ remove, push }) => (
                  <>
                    {isSelectingItem && (
                      <ItemSelection
                        selectMedicationFn={(values) => {
                          push(values);
                          setIsSelectingItem(false);
                        }}
                        push={push}
                        exitSearch={() => { setIsSelectingItem(false)}
                      }
                        closePanel={toggleOrderPanel}
                      />
                    )}

                    {!isSelectingItem && (
                      <div className="head d-flex flex-column align-items-center">
                        <div className="d-flex flex-row w-100">
                          <h2>Orders</h2>
                          <Button
                            color="link"
                            className="ms-auto panel-close-btn"
                            onClick={toggleOrderPanel}
                          >
                            <span className="material-symbols-outlined">
                              close
                            </span>
                          </Button>
                        </div>
                      </div>
                    )}
                    <div
                      style={{
                        display: isSelectingItem ? "none" : "flex",
                      }}
                      className="flex-column gap-2"
                    >
                      {formik?.values?.items?.length > 0 &&
                        formik?.values?.items?.map(
                          (medication, index) => {
                            const unitOpt = medication?.unit_of_measure ? [medication?.unit_of_measure] : []
                            if (medication?.sub_package?.unit_of_measure) unitOpt.push(medication?.sub_package?.unit_of_measure)
                            return <>
                              <div className="w-full rounded-lg bg-stone-50/90 py-[10px] px-[15px] inline-flex gap-[30px]">
                                <div className="max-w-[400px] w-full">
                                  <div className="flex px-4 py-3 bg-white rounded-lg border border-indigo-950 border-opacity-10 justify-start items-center gap-2 mb-2 ">
                                    <div className="grow  text-base font-normal">
                                      {medication?.product_name || medication?.service_name || medication?.test_name}
                                    </div>
                                  </div>
                                  <div className="self-stretch text-slate-500 text-sm font-normal font-['Inter'] leading-[21px]">
                                    {medication?.description}
                                  </div>
                                  {formik.touched?.items?.[index]?.dose &&
                                    formik.errors.items?.[index]
                                      ?.dose && (
                                      <div className="flex w-full mt-4 items-center gap-2">
                                        <img
                                          className="img-fluid w-6 h-6"
                                          src={
                                            require("../../../../../../assets/img/alert.svg")
                                              .default
                                          }
                                          alt="Badge"
                                        />
                                        {
                                          formik.errors.items?.[index]
                                            .dose
                                        }
                                      </div>
                                    )}

                                  {formik.touched.items?.[index]
                                    ?.route && formik.errors.items?.[index]
                                      ?.route && (
                                      <div className="flex w-full mt-4 items-center gap-2">
                                        <img
                                          className="img-fluid w-6 h-6"
                                          src={
                                            require("../../../../../../assets/img/alert.svg")
                                              .default
                                          }
                                          alt="Badge"
                                        />
                                        {formik.errors.items?.[index].route}
                                      </div>
                                    )}


                                  {formik.touched.items?.[index]
                                    ?.frequency &&
                                    formik.errors.items?.[index]
                                      ?.frequency && (
                                      <div className="flex w-full mt-4 items-center gap-2">
                                        <img
                                          className="img-fluid w-6 h-6"
                                          src={
                                            require("../../../../../../assets/img/alert.svg")
                                              .default
                                          }
                                          alt="Badge"
                                        />
                                        {
                                          formik.errors.items?.[index]
                                            .frequency
                                        }
                                      </div>
                                    )}

                                </div>

                                <div className="grow flex flex-col justify-center items-start">
                                  
                                  {medication?.category === "vaccine" ||
                                    medication?.category === "Infusion" || medication.category === "medications" || 
                                    medication?.category === "items" ? (
                                    <>
                                      <Row className="flex mb-2">
                                        <div className="row-block flex items-center w-full">
                                          <label
                                            for={`items.${index}.dose`}
                                            className=" w-36 mr-4 text-sm"
                                          >
                                            Dose Information
                                          </label>
                                          <div className="right-col flex-fill">
                                            <InputGroup>
                                              <Input
                                                id={`items.${index}.dose`}
                                                type="number"
                                                min="1"
                                                onChange={(e) => {
                                                  formik.handleChange(e);
                                                }}
                                                placeholder={"Add Info"}
                                              />
                                              <Input
                                                id={`items.${index}.form`}
                                                placeholder="Select Unit"
                                                onChange={(e) => {
                                                  formik.handleChange(e);
                                                }}
                                                value={
                                                  formik.values.items?.[
                                                    index
                                                  ].form
                                                }
                                                type="select"
                                              >
                                                <option>Select Form</option>
                                                {[...new Set(unitOpt)]?.map(opt => <option>{opt}</option>)}
                                               
                                              </Input>
                                            </InputGroup>
                                          </div>
                                        </div>
                                      </Row>

                                      <Row className="flex mb-2">
                                        <div className="row-block flex items-center w-full">
                                          <label
                                            for={`items.${index}.route`}
                                            className="w-36 mr-4 text-sm"
                                          >
                                            Route
                                          </label>
                                          <div className="right-col flex-fill">
                                            <Input
                                              id={`items.${index}.route`}
                                              placeholder="Select Route"
                                              onChange={(e) => {
                                                formik.handleChange(e);
                                              }}
                                              value={
                                                formik.values.items?.[
                                                  index
                                                ].route
                                              }
                                              type="select"
                                            >
                                              <option>Select Route</option>
                                              <option>IM</option>
                                              <option>IV</option>
                                              <option>IV Push</option>
                                              <option>PO</option>
                                              <option>SubQ</option>
                                              <option>Rectal</option>
                                              <option>Inhalation</option>
                                              <option>Nasal</option>
                                              <option>Ocular</option>
                                              <option>Otic</option>
                                              <option>Topical</option>
                                              <option>Transdermal</option>
                                            </Input>
                                          </div>
                                        </div>
                                      </Row>



                                      <Row className="flex mb-2">
                                        <div className="row-block flex items-center w-full">
                                          <label
                                            for={`items.${index}.prn`}
                                            className="w-36 mr-4 text-sm"
                                          >
                                            PRN
                                          </label>
                                          <div className="right-col flex-fill">
                                            <Input
                                              id={`items.${index}.prn`}
                                              value={
                                                formik.values.items?.[index]
                                                  .prn
                                              }
                                              onChange={(event) => {
                                                formik.handleChange(event);
                                              }}
                                              type="checkbox"
                                            />
                                          </div>
                                        </div>
                                      </Row>
                                    </>) : (
                                    <></>
                                  )}

                                  {medication?.category !== "vaccine" && !medication.test_name && (

                                    <Row className="flex mb-2">
                                      <div className="row-block flex items-center w-full">
                                        <label
                                          for={`items.${index}.frequency`}
                                          className="w-36 mr-4 text-sm"
                                        >
                                          Frequency
                                        </label>
                                        <div className="right-col flex-fill">
                                          <Input
                                            id={`items.${index}.frequency`}
                                            placeholder="Select Frequency"
                                            onChange={(e) => {
                                              formik.handleChange(e);
                                            }}
                                            value={
                                              formik.values.items?.[index]
                                                .frequency
                                            }
                                            type="select"
                                          >
                                            <option>Select Frequency</option>
                                            <option>q5m</option>
                                            <option>q15m</option>
                                            <option>q30m</option>
                                            <option>q2h</option>
                                            <option>q4h</option>
                                            <option>q6h</option>
                                            <option>q8h</option>
                                            <option>q12h</option>
                                            <option>q24h</option>
                                            <option>daily</option>
                                            <option>daily at bedtime</option>
                                            <option>BID</option>
                                            <option>TID</option>
                                            <option>QID</option>
                                            <option>every other day</option>
                                            <option>weekly</option>
                                            <option>bi-weekly</option>
                                            <option>monthly</option>
                                            <option>once</option>
                                          </Input>
                                        </div>
                                      </div>
                                    </Row>

                                  )}


                                  {medication?.category !== "vaccine" && !medication?.test_name &&
                                    medication?.frequency !== "once" && (

                                      <Row className="flex mb-2">
                                        <div className="row-block flex items-center w-full">
                                          <label
                                            for={`items.${index}.duration_num`}
                                            className="w-36 mr-4 text-sm"
                                          >
                                            Duration
                                          </label>
                                          <div className="right-col flex-fill">
                                            <InputGroup>
                                              <Input
                                                id={`items.${index}.duration_num`}
                                                type="number"
                                                min="1"
                                                placeholder="Add Duration"
                                                onChange={(evt) => {
                                                  formik.handleChange(evt);
                                                }}
                                                value={
                                                  formik.values.items?.[index]
                                                    .duration_num
                                                }
                                              />
                                              <Input
                                                id={`items.${index}.duration_unit`}
                                                placeholder="Select Duration"
                                                onChange={(evt) => {
                                                  formik.handleChange(evt);
                                                }}
                                                value={
                                                  formik.values.items?.[index]
                                                    .duration_unit
                                                }
                                                type="select"
                                              >
                                                <option>Select Unit</option>
                                                <option>Days</option>
                                                <option>Weeks</option>
                                              </Input>
                                            </InputGroup>
                                          </div>
                                        </div>
                                      </Row>)}

                                  {    !medication?.test_name &&

                                  <>

                                  <Row className="flex mb-2">
                                    <div className="row-block flex items-center w-full">
                                      <label
                                        for={`items.${index}.first_dose`}
                                        className="w-36 mr-4 text-sm"
                                      >
                                        {`${medication?.category === "vaccine" ||
                                          medication?.category === "Infusion" ||
                                          medication?.category === "items"
                                          ? "First Dose"
                                          : "Start"
                                          } Date & Time`}
                                      </label>
                                      <div className="right-col flex-fill">
                                        <Input
                                          id={`items.${index}.first_dose`}
                                          value={
                                            formik.values.items?.[index]
                                              .first_dose
                                          }
                                          onChange={(event) => {
                                            formik.handleChange(event);
                                          }}
                                          type="datetime-local"
                                        />
                                      </div>
                                    </div>
                                  </Row>

                                  <Row className="flex mb-2">
                                    <div className="row-block flex items-center w-full">
                                      <label
                                        for={`items.${index}.notes`}
                                        className="w-36 mr-4 text-sm"
                                      >
                                        Notes
                                      </label>
                                      <div className="right-col flex-fill">
                                        <textarea
                                          onChange={(e) =>
                                            formik.handleChange(e)
                                          }
                                          className="resize-y rounded-md outline outline-1 outline-[#0909541F] p-2"
                                          rows="2"
                                          cols="40"
                                          id={`items.${index}.notes`}
                                          name={`items.${index}.notes`}
                                        />
                                      </div>
                                    </div>
                                  </Row>
                                  </>
                                  }
                                 
                                </div>

                                <div style={{ minWidth: '20px', maxWidth: '20px' }} className=" relative">
                                  <button
                                    className="hover:drop-shadow-md"
                                    onClick={() => remove(index)}
                                  >
                                    <img
                                      className="img-fluid w-100 h-100"
                                      src={
                                        require("../../../../../../assets/img/delete_gray.svg")
                                          .default
                                      }
                                      alt="Badge"
                                    />
                                  </button>
                                </div>
                              </div>
                            </>
                          }
                        )}
                    </div>

                    {!isSelectingItem && (
                      <Row className="my-4">
                        <div className="w-full">
                          <Button
                            onClick={() => {
                              setIsSelectingItem(true);
                            }}
                            color="link"
                            className="add-section-medication-btn d-flex justify-content-center w-full"
                          >
                            <span className="icomoon-plus-btn"></span>
                          </Button>
                        </div>
                      </Row>
                    )}
                  </>
                )}
              </FieldArray>


              {!isSelectingItem && <>


              {formik?.values?.items?.filter(item => item?.inHouse === true && item?.idexx_code !== undefined)?.length > 0 && ivlsMachines && 

                                  <Row className="flex mb-2">
                                    <div className="row-block flex items-center w-full">
                                      <label
                                        for={`ivls`}
                                        className="w-36 mr-4 text-sm"
                                      >
                                        IVLS Station
                                      </label>
                                      <div className="right-col flex-fill">
                                      <Input
                                        id={`ivls`}
                                        placeholder="Select IVLS"
                                        name="ivls"
                                        onChange={(evt) => {
                                          formik.handleChange(evt);
                                        }}
                                        value={
                                          formik.values
                                            .ivls
                                        }
                                        type="select"
                                      >
                                        <option>Select IVLS Unit</option>
                                        {ivlsMachines.map((machine, y) => {return(
                                          <option value={machine?.deviceSerialNumber} key={y}>{machine?.displayName} ({machine?.deviceSerialNumber})</option> 
                                        )})}

                                        
                                      </Input>

                                      {formik?.errors?.ivls &&
                                      formik?.touched?.ivls ? (
                                      <FormFeedback style={{display: 'flex'}} invalid>
                                        {formik?.errors?.ivls}
                                      </FormFeedback>
                                    ) : null}
                                      </div>
                                    </div>
                                   
                                  </Row>
                                  

                                  
                                  }


                {formik?.values?.items?.filter(item => item?.antech_code !== undefined)?.length > 0  && antechSpecies &&


                        <>
                                  <Row className="flex mb-2">
                                    <div className="row-block flex items-center w-full">
                                      <label
                                        for={`antech_species`}
                                        className="w-36 mr-4 text-sm"
                                      >
                                        Antech Species
                                      </label>
                                      <div className="right-col flex-fill">
                                      <Input
                                        id={`antech_species`}
                                        placeholder="Select Antech Species"
                                        name="antech_species"
                                        onChange={(evt) => {
                                          formik.handleChange(evt);
                                          formik.setFieldValue('antech_breed', undefined)
                                          getAntechBreedFromSpecies(evt.target.value)
                                        }}
                                        value={
                                          formik.values
                                            .antech_species
                                        }
                                        type="select"
                                      >
                                        <option value="">Select Antech Species</option>
                                        {antechSpecies?.map((item, y) => {return(
                                          <option value={item?.code} key={y}>{item?.name} ({item?.code})</option> 
                                        )})}

                                        
                                      </Input>
                                      {formik?.errors?.antech_species &&
                                      formik?.touched?.antech_species ? (
                                      <FormFeedback style={{display: 'flex'}} invalid>
                                        {formik?.errors?.antech_species}
                                      </FormFeedback>
                                    ) : null}
                                      </div>
                                    </div>
                                  </Row>


                              <>
                              {antechBreeds &&
                              <Row className="flex mb-2">
                                <div className="row-block flex items-center w-full">
                                  <label
                                    for={`antech_breed`}
                                    className="w-36 mr-4 text-sm"
                                  >
                                    Antech Breed
                                  </label>
                                  <div className="right-col flex-fill">
                                  <Input
                                    id={`antech_breed`}
                                    name={`antech_breed`}
                                    placeholder="Select Antech Breed"
                                    onChange={(evt) => {
                                      formik.handleChange(evt);
                                    }}
                                    value={
                                      formik.values
                                        .antech_breed
                                    }
                                    type="select"

                                  >
                                    <option value="">Select Antech Breed</option>
                                    {antechBreeds?.map((item, y) => {return(
                                      <option value={item?.code} key={y}>{item?.name} ({item?.code})</option> 
                                    )})}

                                    
                                  </Input>
                                  {formik?.errors?.antech_breed &&
                                      formik?.touched?.antech_breed ? (
                                      <FormFeedback style={{display: 'flex'}} invalid>
                                        {formik?.errors?.antech_breed}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </div>
                              </Row>}
                              </>
                            </>}



                   
                {formik?.values?.items?.filter(item => item?.idexx_code !== undefined)?.length > 0  && idexxSpecies &&


                    <>
                              <Row className="flex mb-2">
                                <div className="row-block flex items-center w-full">
                                  <label
                                    for={`idexx_species`}
                                    className="w-36 mr-4 text-sm"
                                  >
                                    Idexx Species
                                  </label>
                                  <div className="right-col flex-fill">
                                  <Input
                                    id={`idexx_species`}
                                    placeholder="Select Idexx Species"
                                    name="idexx_species"
                                    onChange={(evt) => {
                                      formik.handleChange(evt);
                                      formik.setFieldValue('idexx_breed', undefined)
                                      getIdexxBreedFromSpecies(evt.target.value)
                                    }}
                                    value={
                                      formik.values
                                        .idexx_species
                                    }
                                    type="select"
                                  >
                                    <option value="">Select Idexx Species</option>
                                    {idexxSpecies?.map((item, y) => {return(
                                          <option value={item?.code} key={y}>{item?.name} ({item?.code})</option> 
                                      )})}

                                   

                                    
                                  </Input>
                                  {formik?.errors?.idexx_species &&
                                      formik?.touched?.idexx_species ? (
                                      <FormFeedback style={{display: 'flex'}} invalid>
                                        {formik?.errors?.idexx_species}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </div>
                              </Row>


                          <>
                          {idexxBreeds &&
                          <Row className="flex mb-2">
                            <div className="row-block flex items-center w-full">
                              <label
                                for={`idexx_breed`}
                                className="w-36 mr-4 text-sm"
                              >
                                Idexx Breed
                              </label>
                              <div className="right-col flex-fill">
                              <Input
                                id={`idexx_breed`}
                                placeholder="Select Idexx Breed"
                                name="idexx_breed"
                                onChange={(evt) => {
                                  formik.handleChange(evt);
                                }}
                                value={
                                  formik.values
                                    .idexx_breed
                                }
                                type="select"

                              >
                                <option>Select Idexx Breed</option>
                                {idexxBreeds?.map((item, y) => {return(
                                  <option value={item?.code} key={y}>{item?.name} ({item?.code})</option> 
                                )})}

                                
                              </Input>

                              {formik?.errors?.idexx_breed &&
                                      formik?.touched?.idexx_breed ? (
                                      <FormFeedback style={{display: 'flex'}} invalid>
                                        {formik?.errors?.idexx_breed}
                                      </FormFeedback>
                                    ) : null}
                              </div>
                            </div>
                          </Row>}
                          </>
                        </>}
                    </>}
              {!isSelectingItem && (
                <div className="flex w-full justify-end">
                  <Button type="submit" color="success" className="w-[150px] ">
                    Save
                  </Button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
     
    </>
  );
};

export default OrderFormPanel;
