import React from "react";
import moment from "moment";
import { getAppointmentTimingDisplayValues } from "../../../../../../utils/calendar/constant";
import { FaUser } from "react-icons/fa";
import "./index.scss";
import { useSelector } from "react-redux";

const PastVisitCard = ({ data, onClick }) => {
  return (
    <>
      <div onClick={onClick} className="past-visit-item w-100">
        <div className="doctor">
          <div className="doctor-info">
            <div className="picture">
              {data?.doctor_id?.user_image ? (
                <img src={data?.doctor_id?.user_image} alt="user-img" />
              ) : (
                <div className="f-center h-100 bg-e3e3 rounded-circle">
                  <FaUser color="#788693" size={20} />
                </div>
              )}
            </div>

            <div className="doctor-text">
              <div className="doctor-name-title">
                <div className="doctor-name">
                  {data?.doctor_id?.title} {data?.doctor_id?.firstname}{" "}
                  {data?.doctor_id?.lastname}
                </div>
                <div className="doctor-title">(DVM)</div>
              </div>
            </div>
          </div>
          <div className="visit-date-location">
            <div className="visit-date-time">
              <div className="calendar-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <mask
                    id="mask0_6549_62916"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="20"
                    height="21"
                  >
                    <rect y="0.5" width="20" height="20" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_6549_62916)">
                    <path
                      d="M8.62647 15.0015L6.81397 13.21C6.71664 13.1126 6.66797 12.9945 6.66797 12.8555C6.66797 12.7168 6.71664 12.5918 6.81397 12.4805C6.9113 12.3835 7.0328 12.335 7.17847 12.335C7.32447 12.335 7.45297 12.3835 7.56397 12.4805L9.08497 13.9805L12.3765 10.668C12.4875 10.571 12.6125 10.5225 12.7515 10.5225C12.8905 10.5225 13.0155 10.571 13.1265 10.668C13.2238 10.7793 13.2758 10.9043 13.2825 11.043C13.2895 11.182 13.2375 11.307 13.1265 11.418L9.52247 15.0015C9.39747 15.1405 9.24814 15.21 9.07447 15.21C8.9008 15.21 8.75147 15.1405 8.62647 15.0015ZM4.23047 18.418C3.86947 18.418 3.56047 18.2895 3.30347 18.0325C3.04647 17.7758 2.91797 17.4668 2.91797 17.1055V5.35547C2.91797 4.99447 3.04647 4.68547 3.30347 4.42847C3.56047 4.17147 3.86947 4.04297 4.23047 4.04297H5.62647V3.04297C5.62647 2.8903 5.68197 2.75847 5.79297 2.64747C5.9043 2.53614 6.0363 2.48047 6.18897 2.48047C6.35564 2.48047 6.49114 2.53614 6.59547 2.64747C6.69947 2.75847 6.75147 2.8903 6.75147 3.04297V4.04297H13.2725V3.04297C13.2725 2.8763 13.3245 2.74097 13.4285 2.63697C13.5328 2.53264 13.6613 2.48047 13.814 2.48047C13.9806 2.48047 14.1161 2.53264 14.2205 2.63697C14.3245 2.74097 14.3765 2.8763 14.3765 3.04297V4.04297H15.7725C16.1335 4.04297 16.4425 4.17147 16.6995 4.42847C16.9565 4.68547 17.085 4.99447 17.085 5.35547V17.1055C17.085 17.4668 16.9565 17.7758 16.6995 18.0325C16.4425 18.2895 16.1335 18.418 15.7725 18.418H4.23047ZM4.23047 17.3765H15.7725C15.8558 17.3765 15.9216 17.3521 15.97 17.3035C16.0186 17.2548 16.043 17.1888 16.043 17.1055V8.98047H3.95997V17.1055C3.95997 17.1888 3.98414 17.2548 4.03247 17.3035C4.08114 17.3521 4.14714 17.3765 4.23047 17.3765ZM3.95997 7.93897H16.043V5.35547C16.043 5.27214 16.0186 5.20964 15.97 5.16797C15.9216 5.1263 15.8558 5.10547 15.7725 5.10547H4.23047C4.14714 5.10547 4.08114 5.1263 4.03247 5.16797C3.98414 5.20964 3.95997 5.27214 3.95997 5.35547V7.93897Z"
                      fill="#282E65"
                    />
                  </g>
                </svg>
              </div>
              <div className="date">
                {moment(data?.appointment_date, "DD-MM-YYYY").format(
                  "MMM D, YYYY"
                )}
              </div>
              <div className="line"></div>
              <div className="time">
                {/*data?.appointment_timings?.sort((a,b) => moment(`1970-01-01 ${a.slot_time}`, `YYYY-MM-DD hh:mm a`).valueOf() - moment(`1970-01-01 ${b.slot_time}`,'YYYY-MM-DD hh:mm a').valueOf()).map((ele) => ele?.appointment_time).join("\n")*/}
                {data?.appointment_timings
                  ? getAppointmentTimingDisplayValues(
                      data?.appointment_timings
                    ).join("\n")
                  : "--"}
              </div>
            </div>

            <div className="visit-location">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <mask
                    id="mask0_6549_62924"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="20"
                    height="21"
                  >
                    <rect y="0.5" width="20" height="20" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_6549_62924)">
                    <path
                      d="M10.0015 11.854C10.1541 11.854 10.2791 11.8055 10.3765 11.7085C10.4738 11.6112 10.5225 11.4862 10.5225 11.3335V9.354H12.5015C12.6541 9.354 12.7791 9.3055 12.8765 9.2085C12.9738 9.11117 13.0225 8.98617 13.0225 8.8335C13.0225 8.6805 12.9738 8.5555 12.8765 8.4585C12.7791 8.36117 12.6541 8.3125 12.5015 8.3125H10.5225V6.3335C10.5225 6.1805 10.4738 6.0555 10.3765 5.9585C10.2791 5.86117 10.1541 5.8125 10.0015 5.8125C9.8488 5.8125 9.7238 5.86117 9.62647 5.9585C9.52914 6.0555 9.48047 6.1805 9.48047 6.3335V8.3125H7.50147C7.3488 8.3125 7.2238 8.36117 7.12647 8.4585C7.02914 8.5555 6.98047 8.6805 6.98047 8.8335C6.98047 8.98617 7.02914 9.11117 7.12647 9.2085C7.2238 9.3055 7.3488 9.354 7.50147 9.354H9.48047V11.3335C9.48047 11.4862 9.52914 11.6112 9.62647 11.7085C9.7238 11.8055 9.8488 11.854 10.0015 11.854ZM10.0015 17.271C11.7931 15.6737 13.1438 14.1563 14.0535 12.719C14.9631 11.2813 15.418 10.0417 15.418 9C15.418 7.361 14.8971 6.02067 13.8555 4.979C12.8138 3.93733 11.5291 3.4165 10.0015 3.4165C8.4738 3.4165 7.18914 3.93733 6.14747 4.979C5.1058 6.02067 4.58497 7.361 4.58497 9C4.58497 10.0417 5.0468 11.2813 5.97047 12.719C6.8938 14.1563 8.23747 15.6737 10.0015 17.271ZM10.0015 18.4165C9.91814 18.4165 9.8348 18.4027 9.75147 18.375C9.66814 18.3473 9.5918 18.3057 9.52247 18.25C7.50847 16.4583 6.00847 14.7917 5.02247 13.25C4.03614 11.7083 3.54297 10.2917 3.54297 9C3.54297 6.986 4.1923 5.37833 5.49097 4.177C6.78964 2.97567 8.29314 2.375 10.0015 2.375C11.7098 2.375 13.2133 2.97567 14.512 4.177C15.8106 5.37833 16.46 6.986 16.46 9C16.46 10.2917 15.9668 11.7083 14.9805 13.25C13.9945 14.7917 12.4945 16.4583 10.4805 18.25C10.4251 18.3057 10.3558 18.3473 10.2725 18.375C10.1891 18.4027 10.0988 18.4165 10.0015 18.4165Z"
                      fill="#282E65"
                    />
                  </g>
                </svg>
              </div>
              <div className="location">{data?.clinic}</div>
            </div>
          </div>
        </div>
        <div className="visit-info">
          <div className="description">
            <div className="header">
              <div className="title">{data?.reason}</div>
              <div className="actions">
                <div className="add-notes">
                  <div className="add-notes-btn">Add Notes</div>
                </div>
                <div className="line"></div>
                <div className="view-details">
                  <div className="view-details-btn">View Details</div>
                </div>
              </div>
            </div>
            <div className="body">{data?.notes}</div>
          </div>
          <div className="measurements">
            {data?.temperatures?.length > 0 && (
              <div className="temp">
                <div className="temp-text">
                  Temp: {data?.temperatures?.[0] || "--"} F
                </div>
              </div>
            )}
            {data?.weights?.length > 0 && (
              <div className="weight">
                <div className="weight-text">
                  Weight: {data?.weights?.[0] || "--"} lbs
                </div>
              </div>
            )}
            {data?.heart_rates?.length > 0 && (
              <div className="heart-rate">
                <div className="heart-rate-text">
                  Heart Rate: {data?.heart_rates?.[0] || "--"} bpm
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PastVisitCard;
