import React, { useEffect } from "react";
import PetDetails from "./PetDetails";
import { useSelector } from "react-redux";
import "../../index.scss";

const MyPets = () => {
  //   const { petAndOwnerDetails } = useSelector((state) => state.petReducer);

  const { ownerAndPetDetails } = useSelector(
    (state) => state.petOwnerDashboardReducer
  );

  return (
    <>
      <div className="w-100 m-auto my-pets">
        <div className="row">
          {ownerAndPetDetails &&
            ownerAndPetDetails.pet_details.map((ele) => {
              return (
                <div className="col-lg-6 col-xl-4 mb-4">
                  <PetDetails pet={ele} />
                </div>
              );
            })}

          {/* <div className="col-md-6">
                            <PetDetails />
                        </div> */}

          {/* <div className="col-lg-6 col-xl-4 mb-3">
            <div className="addner_mypet_box">Add New</div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default MyPets;
