import React from 'react';
import './index.scss';

const UserPlaceholder = ({
    className = '',
    icon = "account_circle",
}) => {
    return (
        <>
            <div className={`user-placeholder d-flex align-items-center justify-content-center rounded-circle w-100 h-100 ${className}`}>
                <span className="material-icons">
                    {icon}
                </span>
            </div>
        </>
    );
}

export default UserPlaceholder;