import React from "react";
import { useSelector } from "react-redux";
import '../common-block-info.scss';

const CostBlock = () => {
	const { inventoryDetails } = useSelector(state => state.inventoryReducer);

	return (
		<>
			<div className="cost-info-blc">
				{/* <h3 className="section-ttl">Cost</h3> */}
				<div className="inner">
					<div className="item-rw d-flex">
						<div className="label-cell">
							Supplier Info.
						</div>
						<div className="desc-cell flex-fill">
							{/* Midigroup USA */}
							{inventoryDetails?.supplier}
							{inventoryDetails?.supplier_detail?.[0]?.supplier_name}

						</div>
					</div>
					<div className="item-rw d-flex">
						<div className="label-cell">
							Product Cost
						</div>
						<div className="desc-cell flex-fill">
							{/* $100 per capsule with retail price $3 */}
							${parseFloat(inventoryDetails?.product_cost)?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        }) || '--'}
						</div>
					</div>
					<div className="item-rw d-flex">
						<div className="label-cell">
							Current Inventory
						</div>
						<div className="desc-cell flex-fill">
							{/* 250 capsule */}
							{inventoryDetails?.current_inventory || '--'}
						</div>
					</div>
					<div className="item-rw d-flex">
						<div className="label-cell">
							Taxes
						</div>
						<div className="desc-cell flex-fill">
							{/* Federal : 0.00% | State : 4.50% | Local : 5.00% */}
							{inventoryDetails?.taxes ? inventoryDetails?.taxes : "--"}
						</div>
					</div>
					<div className="item-rw d-flex">
						<div className="label-cell">
							Discount (If any)
						</div>
						<div className="desc-cell flex-fill">
							{inventoryDetails?.discount + '%' || '--'}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CostBlock;
