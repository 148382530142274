import React from "react";
import SectionView from "./SectionView";
import { Button, Row } from "reactstrap";
import SubjectiveInfo from "./SubjectiveInfo";
import ObjectiveInfo from "./ObjectiveInfo";
import VitalInfo from "./VitalInfo";
import PlanInfo from "./PlanInfo";
import NoteInfo from "./NoteInfo";
import './index.scss';

const NoteDetail = ({ sections, build }) => {

	return (
		<>
			<div className="note-detail-wrapper">
				{sections?.map((section, i) =>
					<SectionView key={i} section={section} />
				)}
				{/*
				<SubjectiveInfo />
				<ObjectiveInfo />*/}
				{/*
				<div className="mt-2 mb-4 pt-1">
					<Button className="ms-auto mr-auto d-flex" color={'primary'} onClick={build}> Build off this note</Button>
				</div>*/}
			</div>


		</>
	);
};

export default NoteDetail;
