import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button, ButtonDropdown, DropdownToggle, NavItem, Nav, NavLink, Table, TabContent, TabPane, Input } from "reactstrap";
import CheckBox from "../../../../shared/CheckBox";
import NodataFoundBlock from "../../../../shared/NoDataFoundBlock";
import PaginationBlock from "../../../../shared/PaginationBlock";
import {getAllAntechSpeciesByClinicId, getAllIdexxSpeciesByClinicId} from "../../../../context/actions/external-species"
import useDebounce from "hooks/useDebounce";
import { getActiveIntegrations } from "../../../../context/actions/clinicSettings";
import { getClinicSpeciesByClinicId, updateClinicSpecies } from "../../../../context/actions/clinic-species";
import { getAllIdexxBreedByClinicId, getAllAntechBreedByClinicId } from "context/actions/external-breeds";
import { Formik, Form } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import { useClinicSpecies } from "hooks/useClinicSpecies";
import BreedFilters from "./BreedFilters"
import { updateClinicBreed, getClinicBreedsByClinicId } from "context/actions/clinic-breed";
/**
 * @description declaration form schema for client validations
 */
const itemSchema = Yup.object().shape({
  
  vals: Yup.array().of(
   Yup.object().shape({
    name: Yup.string(),
    idexx_mapping: Yup.string(),
    antech_mapping: Yup.string()
    
}))});



const Breeds = ({ handleOpenDrawerButton }) => {
  const history = useHistory();
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [integrations, setIntegrations] = useState([])


  const userDetails = useSelector((state) => state.user.user_details);
  const {clinic_details} = useSelector((state) => state.clinicReducer);
  const [breedsData, setBreedsData] = useState([])
  const [idexxBreeds, setIdexxBreeds] = useState([])
  const [antechBreeds, setAntechBreeds] = useState([])
  const [editVals, setEditVals] = useState(Array(10)); 




  const [isEdit, setIsEdit] = useState({}); 

  const [filterData, setFilterData] = useState({
    search: "",
    species: ""
    });

  

  const searchDebouncedValue = useDebounce(filterData.search, 300);
  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  const clinicSpecies = useClinicSpecies();


    
  useEffect(() => {
    dispatch(getActiveIntegrations((response) => {
      if(response?.status) setIntegrations(response?.response_data)
    }))
  } , [])

  const update = (values) => {
    dispatch(updateClinicBreed(values, () => {
      getData()
    }))
  }


  useEffect(() => {
    if(integrations?.length > 0 && integrations?.filter(j => j?.app ==='Idexx')?.length > 0){
      dispatch(getAllIdexxBreedByClinicId(clinic_details?._id, {}, (res) => {
        if(res){
          setIdexxBreeds(res)
        }
      }))
    }
      if(integrations?.length > 0 && integrations?.filter(j => j?.app ==='Antech')?.length > 0){
        dispatch(getAllAntechBreedByClinicId(clinic_details?._id, {}, (res) => {
          if(res){
            console.log(res?.response_data, 'response data')
            setAntechBreeds(res)
          }
        }))
    }


  }, [integrations])

  const getData = () => {
    dispatch(
      getClinicBreedsByClinicId({page, limit,...filterData}, (res) => {
        setBreedsData(res);
      })
    );
  }

  
  useEffect(() => {
   getData()
  setEditVals(Array(10)); 

   setIsEdit({}); 


  }, [dispatch, clinic_details?._id, page, limit]);

  useEffect(() => {


    setPage(1)
    getData()
    
  }, [ filterData.species ,searchDebouncedValue])


  return (
    <>   
          <div className="product-list-wrapper">
            <BreedFilters setFilterData={setFilterData} />
            {breedsData?.docs?.length > 0 ? (
              <div className="table-listing-blc">
                 <Formik
                    initialValues={{vals: editVals}}
                    enableReinitialize
                    validationSchema={itemSchema}
                    onSubmit={() => {}}>

							{(formik) => (
								<Form>


                <Table striped  className="checkbox-listing">
                  <thead>
                    <tr>
                      <th>
                        <CheckBox
                          type="checkbox"
                          ischecked={false}
                          className="no-label"
                        />
                      </th>
                      <th style={{width: '40%'}}>Name</th>
                      <th>Internal Value</th>
                      <th>Species</th>


                      {integrations?.filter(i => i?.app ==='Idexx')?.length > 0 && <th>Idexx Map</th>}
                      {integrations?.filter(i => i?.app ==='Antech')?.length > 0 && <th>Antech Map</th>}
                      {permissions?.edit_services &&
                        <th className="text-end">&nbsp;</th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {breedsData?.docs?.map((item, i) => (
                      <tr key={i}>

                      {!isEdit[i] ? 
                      <>
                        <td>
                          <CheckBox
                            type="checkbox"
                            ischecked={false}
                            className="no-label"
                          />
                        </td>
                        <td>
                          <Button
                            color="link"
                            className="p-0 text-start d-inline-flex align-items-center w-100"
                           
                          >
                            {item?.name}
                          </Button>
                        </td>
                        <td>
                         {item?.breed_info?.breed}
                        </td>
                        <td>
                         {item?.species_info?.name}
                        </td>
                        {integrations?.filter(j => j?.app ==='Idexx')?.length > 0 && <td>{item?.idexx_mapping ? item?.idexx_mapping?.name  + ' (' +idexxBreeds.find(k => k?._id === item?.idexx_mapping?._id)?.species_id?.name +")" : '--'}</td>}
                        {integrations?.filter(j => j?.app ==='Antech')?.length > 0 && <td>{item?.antech_mapping ? item?.antech_mapping?.name + ' (' +antechBreeds.find(k => k?._id === item?.antech_mapping?._id)?.species_id?.name +")" : '--'}</td>}                        
                        {permissions?.edit_services &&
                          <td style={{textAlign: 'end'}} className="action-cell">
                            <Button color="link" className="edit-btn" onClick={ () =>{
                              setIsEdit({...isEdit, [i]: true})
                              setEditVals([...editVals.slice(0, i), {name: item?.name, antech_mapping: item?.antech_mapping?._id, idexx_mapping: item?.idexx_mapping?._id}, ...editVals.slice(i + 1)])
                              }}>
                              <span className="icomoon-pencil"></span>
                            </Button>
                            <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                              <DropdownToggle color="link" className="more-btn">
                                <span className="icomoon-more"></span>
                              </DropdownToggle>{" "}
                            </ButtonDropdown>
                          </td>
                        }
                        </>
                  : 
                    <>
                      <td>
                        <CheckBox
                          type="checkbox"
                          ischecked={false}
                          className="no-label"
                        />
                      </td>
                     
                      <td>
                        <Input
                          size={'sm'}
                          bsSize="sm"
                          className="text-start d-inline-flex align-items-center w-100"
                          style={{fontSize: '13px', height: '100%'}}
                          name={`vals[${i}].name`}
                          onChange={formik.handleChange}
                          value={formik?.values?.vals?.[i]?.name}
                          
                        />
                      </td>
                      <td>
                      {item?.breed_info?.breed }
                        </td>
                        <td>
                         {item?.species_info?.name}
                        </td>
                      {integrations?.filter(j => j?.app ==='Idexx')?.length > 0 &&
                      <td> <Select maxMenuHeight={150} styles={{menu: (baseStyles) => ({...baseStyles, zIndex: '100', overflow: 'visible'})}} onChange={(value => (formik.setFieldValue(`vals[${i}]idexx_mapping`, value?.value )))}   value={idexxBreeds?.filter(option => 
                        option?._id === formik?.values?.vals?.[i]?.idexx_mapping)?.map((option) => {return{value: option?._id, label: option?.name +  ' (' + option?.species_id?.name + ")"}})} options={idexxBreeds?.map((option) => {return{value: option?._id, label: option?.name + ' (' + option?.species_id?.name + ')'}})}/>
                      </td>}
                      {integrations?.filter(j => j?.app ==='Antech')?.length > 0 &&
                      <td> <Select maxMenuHeight={150} styles={{menu: (baseStyles) => ({...baseStyles, zIndex: '100', overflow: 'visible'})}} onChange={(value => (formik.setFieldValue(`vals[${i}]antech_mapping`, value?.value )))}  value={antechBreeds?.filter(option => 
                        option?._id === formik?.values?.vals?.[i]?.antech_mapping)?.map((option) => {return{value: option?._id, label: option?.name + ' (' + option?.species_id?.name + ")"}})} options={antechBreeds?.map((option) => {return{value: option?._id, label: (option?.name + ' (' + option?.species_id?.name + ')' )}})}/>
                      </td>}                       
                      {permissions?.edit_services &&
                        <td style={{textAlign: 'end'}} className="action-cell">

                                  <Button
																		onClick={() => {
                                      setIsEdit({...isEdit, [i]: false})
                                      setEditVals([...editVals.slice(0, i), {}, ...editVals.slice(i + 1)]);
																		}}
																		color="link"
																		className="edit-btn"
																	>
																		<i className="icomoon-cross-btn"></i>
																	</Button>
																	<Button
																		onClick={() => {
                                      setIsEdit({...isEdit, [i]: false})
                                      setEditVals([...editVals.slice(0, i), {}, ...editVals.slice(i + 1)]);
                                      update({...formik?.values?.vals?.[i], _id: item?._id})
																		}}
																		color="link"
																		className="edit-btn"
																	>
																		<i className="icomoon-check-btn"></i>
																	</Button>
                        
                          <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle color="link" className="more-btn">
                              <span className="icomoon-more"></span>
                            </DropdownToggle>{" "}
                          </ButtonDropdown>
                        </td>
                      }
                     </>}
                      </tr>
                      
                    ))}
                  </tbody>
                </Table>
                </Form> )}
                </Formik>
                <PaginationBlock
                  limit={limit}
                  page={page}
                  pagingCounter={breedsData?.pagingCounter || 1}
                  docLength={breedsData?.docs?.length || 0}
                  docPage={breedsData.page || 1}
                  totalPages={breedsData.totalPages || 1}
                  totalDocs={breedsData.totalDocs || 0}
                  _handleLimit={(val) => {
                    setLimit(val);
                  }}
                  _handlePageChange={setPage}
                />
              </div>

                 
        ) : (
          <NodataFoundBlock type={filterData?.search !== "" || 
          filterData?.species !== "" ? 'search result' : ''} />
        )}
      </div>
    </>
  );
};

export default Breeds;
