

const initState = {
   kennels:[]
}

export const kennelReducer = (state = initState, action) => {
    switch (action.type) {
        case "GET_KENNELS":
            return { ...state, kennels: action.payload }
        default:
            return state
    }
}