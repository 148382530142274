import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import './index.scss';

const ProductsList = ({ inventoryId, productList }) => {
	const history = useHistory();

	return (
		<>
			<ul className="product-list list-unstyled">
				{productList?.length > 0 &&
					productList.map((item, i) => {
						return (
							<li key={i} className={`d-flex flex-column ${inventoryId === item._id ? 'active' : ''}`}>
								<Button color="link" className="d-inline-flex text-start"
									onClick={() => { history.push(`/product-detail/${item._id}`); }}
								>
									<div className="pic flex-shrink-0">
										<div className="inner">
											{item.image ?
												<img className="img-fluid w-100 h-100" src={item.image} alt="Product" /> :
												<img className="img-fluid w-100 h-100" src={require('../../../../assets/img/vetRecorEMR/product-thumbnail-square.png')} alt="Product" />
											}
										</div>
									</div>
									<div className="txt">
										<label>{item.product_name}</label>
										<span className="d-block">{item.sku}</span>
									</div>
								</Button>
							</li>
						)
					})
				}
			</ul>
		</>
	);
};

export default ProductsList;
