import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { dueDaysFromDate } from "../../../../../utils/common";
import "./index.scss";
import { getVaccinationDetailsList } from "context/actions/patient-module/medical-history";
import moment from "moment"
import { BiDotsVerticalRounded } from "react-icons/bi";

const ProfileVaccination = () => {
	const { petDetails } = useSelector(state => state.petReducer);

	const { vaccinationList } = useSelector(state => state.medicalHistoryReducer);
	const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);
	const dispatch = useDispatch();
	useEffect(() => {
		if (petDetails?._id) {
			dispatch(getVaccinationDetailsList(petDetails?._id, {}, () => {
			}))
		}
	}, [petDetails?._id])

	useEffect(() => {
		if (petDetails?._id) {
			dispatch(getVaccinationDetailsList(petDetails?._id, {}, () => {
			}))
		}
	}, []);

	const getDateRange = (num, duration_unit) => {
		const duration_num = parseInt(num);
		const startDate = new Date();
		const endDate = new Date(startDate);
		switch (duration_unit) {
		  case "days":
			endDate.setDate(startDate.getDate() + duration_num);
			break;
		  case "weeks":
			endDate.setDate(startDate.getDate() + 7 * duration_num);
			break;
		  case "months":
			endDate.setMonth(startDate.getMonth() + duration_num);
			break;
		  default:
			break;
		}
		return `${moment(endDate).toISOString()}`;
	};

	const vaccineDate = getDateRange(clinic_settings?.vaccination_due?.num, clinic_settings?.vaccination_due?.unit);
	
	return (
		<>
			<div className="profile-vaccination row-block">
				<div className="head section-head d-flex align-items-center">
					<div className="left-col">
						Vaccination Due
					</div>
					<div className="right-col ms-auto">
						<Button color="link" className="add-btn d-inline-flex">
							<span className="icomoon-plus-btn"></span>
						</Button>
					</div>
				</div>
				<div className="inner">
					<div className="item-list d-flex flex-column">
					{vaccinationList?.length > 0 &&
						vaccinationList?.map((item, i) => {
							//const daysOfDueDate = moment(item?.due_dates?.[0]).fromNow();
							//const daysOfVaccineDate = moment(vaccineDate)?.days;
							if (moment(item?.due_dates?.[0]).isBefore(moment(vaccineDate))) {
								//const numOfDays = daysOfVaccineDate - daysOfDueDate;
								return (
									<>
										<Button color="link" className="w-100 d-inline-flex align-items-center item-cell">
											<label style={{textAlign:'left'}}>{item?.product_name}</label>
											<span style={{textAlign:'right'}} className={`due_date ms-auto hover-hide`}>
												{dueDaysFromDate(item?.due_dates?.[0])}
											</span>
											<span className="ms-auto hover-view">Send Reminder</span>
											<span className="hover-view more-data"><BiDotsVerticalRounded /></span>
										</Button>
									</>);
							} else {
								return <></>;
							}
						})
					}
					</div>
				</div>
			</div>
		</>
	);
};

export default ProfileVaccination;
