import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, Button, } from 'reactstrap';
import { getActiveIntegrations } from "context/actions/clinicSettings";
import './index.scss';
import AddApp from "./AddApp";
import EditApp from "./EditApp";

const Apps = () => {

    const dispatch = useDispatch();

    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);

    const [activeIntegrations, setActiveIntegrations] = useState([])
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const [app, setApp] = useState(null);


    const handleClose = () => {
        getData()
        setShow(false)
    }
    
    const handleEditClose = () => {
        getData()
        setShowEdit(false)

    }


    const getData = () => {
        dispatch(getActiveIntegrations((response) => {
            if(response?.status){
                setActiveIntegrations(response?.response_data)
            }
        }));

    }

    useEffect(() => {
       getData()

    }, [])

    const imageFromApp = {
        'Idexx': 'idexx-logo.jpeg',
        'Antech': 'antech-logo-01.svg'
    }



    return (
        <>
            <div className="apps-wrapper">
                <div className="tab-row d-flex">
                    <div className="tab-col lft">
                        <div className="info-block">
                            <h3>Your Apps</h3>
                        </div>
                    </div>
                    <div className="tab-col flex-fill">
                        <div className="app-list-blc">

                            {activeIntegrations?.map((integration, i) => {
                            return(
                            <>
                            <div className="row-block d-flex align-items-center">
                                <div className="left-col d-flex align-items-center">
                                    <div className="app-icon">
                                        {imageFromApp[integration?.app] && 
                                        <img className="img-fluid" src={require(`../../../../assets/img/vetRecorEMR/${imageFromApp[integration?.app]}`)} alt="" /> }
                                    </div>
                                    <div className="app-info">
                                        <h4>{integration?.app}</h4>
                                    </div>
                                </div>
                                <div className="right-col ms-auto">
                                    <ButtonDropdown onClick={() => {
                                        setApp(integration)
                                        setShowEdit(true)
                                        }
                                        } isOpen={dropdownOpen} toggle={toggle} className="dropdown-cell">
                                        <DropdownToggle color="link">
                                            <i className="icomoon-more"></i>
                                        </DropdownToggle>
                                        {/* <DropdownMenu>
                                            <DropdownItem>Item One</DropdownItem>
                                            <DropdownItem>Item Two</DropdownItem>
                                        </DropdownMenu> */}
                                    </ButtonDropdown>
                                </div>
                            </div>
                            </>
                            )})}
                           
                        </div>
                    </div>
                </div>

                <div className="tab-row d-flex">
                    <div className="tab-col lft">
                        <div className="info-block">
                            <h3>Available Apps</h3>
                        </div>
                    </div>
                    <div className="tab-col flex-fill">
                        <div className="app-list-blc">
                            <div className="row-block d-flex align-items-center">
                                <div className="left-col d-flex align-items-center">
                                    <div className="app-icon">
                                        <img className="img-fluid" src={require('../../../../assets/img/vetRecorEMR/mail-chimp.png')} alt="" />
                                    </div>
                                    <div className="app-info">
                                        <h4>Mailchimp</h4>
                                        <h5>Email Newsletters</h5>
                                    </div>
                                </div>
                                <div className="right-col ms-auto">
                                    <Button color="link" className="add-btn p-0">
                                        <span className="icomoon-plus-btn"></span>
                                    </Button>
                                </div>
                            </div>
                            <div className="row-block d-flex align-items-center">
                                <div className="left-col d-flex align-items-center">
                                    <div className="app-icon">
                                        <img className="img-fluid" src={require('../../../../assets/img/vetRecorEMR/stripe.png')} alt="" />
                                    </div>
                                    <div className="app-info">
                                        <h4>Stripe</h4>
                                        <h5>Payment Method</h5>
                                    </div>
                                </div>
                                <div className="right-col ms-auto">
                                    <Button color="link" className="add-btn p-0">
                                        <span className="icomoon-plus-btn"></span>
                                    </Button>
                                </div>
                            </div>
                            {activeIntegrations?.filter(i => i?.app ==='Idexx')?.length === 0 &&
                            <div className="row-block d-flex align-items-center">
                                <div className="left-col d-flex align-items-center">
                                    <div className="app-icon">
                                        <img className="img-fluid" src={require('../../../../assets/img/vetRecorEMR/idexx-logo.jpeg')} alt="" />
                                    </div>
                                    <div className="app-info">
                                        <h4>IDEXX</h4>
                                        <h5>Diagnostic Testing</h5>
                                    </div>
                                </div>
                                <div className="right-col ms-auto">
                                    <Button onClick={() => {
                                        setApp('Idexx')
                                        setShow(true)
                                    }} color="link" className="add-btn p-0">
                                        <span className="icomoon-plus-btn"></span>
                                    </Button>
                                </div>
                            </div>}
                            {activeIntegrations?.filter(i => i?.app ==='Antech')?.length === 0 &&
                            <div className="row-block d-flex align-items-center">
                                <div className="left-col d-flex align-items-center">
                                    <div className="app-icon">
                                        <img className="img-fluid" src={require('../../../../assets/img/vetRecorEMR/antech-logo-01.svg').default} alt="" />
                                    </div>
                                    <div className="app-info">
                                        <h4>Antech</h4>
                                        <h5>Diagnostic Testing</h5>
                                    </div>
                                </div>
                                <div className="right-col ms-auto">
                                    <Button onClick={() => {
                                        setApp('Antech')
                                        setShow(true)
                                    }} color="link" className="add-btn p-0">
                                        <span className="icomoon-plus-btn"></span>
                                    </Button>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>

            <AddApp show={show} app={app} handleClose={handleClose} />

            <EditApp show={showEdit} integration={app} handleClose={handleEditClose} />
        </>
    )
}

export default Apps;