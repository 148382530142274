import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "reactstrap";
import { useHistory } from "react-router";
import {
  getAppointmentDetails,
  updateAppointmentDetails,
  cancelAppointmeent,
} from "../../../../context/actions/appointment";
import "./index.scss";
import moment from "moment";
import { useState } from "react";
import { getAppointmentTimingDisplayValues } from "utils/calendar/constant";
import { setAppointmentDetailPanel } from "context/actions/appointmentDetailPanelAction";
import { appointmentTypes } from "utils/constants";
import Swal from "sweetalert2";

const ScheduleInformation = ({ appointment_data, handleStart, handleStop, closePanel }) => {
  const history = useHistory();
  const dispatch = useDispatch();



  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );
  /*
    const dispatch = useDispatch()

    const joinVideoCall = () => {
        if (appointment_data?.appointment_type === "video-call") {
            const win = window.open(`/video-chat/${appointment_data?._id}`, "_blank");
            win.focus();
        }
    }
    useEffect(() => {
        handleGetAppointmentDetails();
     }, [])
     */

  const joinVideoCall = () => {
    if (appointment_data?.appointment_type === "video-call") {
      const win = window.open(`/video-chat/${appointment_data?._id}`, "_blank");
      win.focus();
    }
  };     
  
  
  const handleEditAppointment = () => {
        dispatch(setAppointmentDetailPanel(false))
        history.push(`/edit-appointment/${appointment_data?._id}`)
     }



  const handleCancelAppointment = () => {
    Swal.fire({
      title: "<strong>Are you sure you want to cancel?</strong>",
      icon: "warning",
      html: `Once canceled your appointment cannot be recovered`,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Cancel",
      confirmButtonAriaLabel: "Cancel",
      cancelButtonText: "Go Back",
      cancelButtonAriaLabel: "Go Back",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(cancelAppointmeent(appointment_data?._id, (response) => { }));
      } else if (result.isDenied) {
        Swal.fire("Your data is safe", "", "info");
      } else if (result.isDismissed) {
        Swal.fire("Your data is safe", "", "info");
      }
    });
  };

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  return (
    <>
      <div className={`schedule-information-card`}>
        <div className="txt">
          <h2>Schedule Information</h2>
        </div>
        <div className="information-group d-flex flex-row">
          <div className="txt d-flex flex-column pd-10">
            <p>Schedule</p>
          </div>
          <div className="txt d-flex flex-column pd-10">
            {/* <p>{`${appointment_data?.appointment_date} ${appointment_data?.appointment_time} (Duration ${appointment_data?.duration >= 60 ? appointment_data?.duration / 60 + ' hours ' : ''}${appointment_data?.duration % 60} minutes)`}</p> */}

            <p>{`${moment(
              appointment_data?.appointment_date,
              "DD-MM-YYYY"
            ).format("MM/DD/YYYY")} ${getAppointmentTimingDisplayValues(
              appointment_data?.appointment_timings
            ).join(", ")} `}</p>
          </div>
        </div>

        <div className="information-group d-flex flex-row">
          <div className="txt d-flex flex-column pd-10">
            <p>Service Type</p>
          </div>
          <div className="txt d-flex flex-column pd-10">
            <p>{`${appointment_data?.appointment_type}`}</p>
          </div>
        </div>
        <div className="information-group d-flex flex-row">
          <div className="txt d-flex flex-column pd-10">
            <p>Doctor</p>
          </div>
          <div className="txt d-flex  pd-10">
            <>
              {clinic_settings?.doctor_name_format === 0 ? (
                <>
                  <p>
                    {" "}
                    {appointment_data?.doctor_id?.lastname}, {appointment_data?.doctor_id?.firstname} </p>
                </>
              ) : (
                <>
                  <p>
                    {" "}
                    {appointment_data?.doctor_id?.firstname}{" "}{appointment_data?.doctor_id?.lastname} </p>
                </>
              )}
            </>
            {/* <p>{`${appointment_data?.doctor_id?.firstname} ${appointment_data?.doctor_id?.lastname}`}</p> */}
          </div>
        </div>
        <div className="information-group d-flex flex-row">
          <div className="txt d-flex flex-column pd-10">
            <p>Reason</p>
          </div>
          <div className="txt d-flex flex-column pd-10">
            <p>{`${appointment_data?.reason}`}</p>
          </div>
        </div>

        <div className="information-group d-flex flex-row">
          <div className="txt d-flex flex-column pd-10">
            <p>Description</p>
          </div>
          <div className="txt d-flex flex-column pd-10">
            <p>{`${appointment_data?.description
              ? appointment_data?.description
              : "--"
              }`}</p>
          </div>
        </div>

        <div className="btn-cell d-flex">
          {appointment_data?.appointment_start ? (
            appointment_data?.appointment_end ? (
              <div
                className={`appointment-status completed w-100 h-100 ${permissions?.edit_appointments ? "" : "disabled"
                  } `}
              >
                {appointment_data?.appointment_type === "surgery"
                  ? "Surgery"
                  : "Appointment"}{" "}
                Completed
              </div>
            ) : appointment_data?.appointment_type === "video-call" ? (
              <div
                onClick={joinVideoCall}
                className="appointment-status in-progress w-100 h-100"
              >
                Join Video Call
              </div>
            ) : (
              <div className="appointment-status in-progress w-100 h-100">
                {appointment_data?.appointment_type === "surgery"
                  ? "Surgery"
                  : "Appointment"}{" "}
                In Progress
              </div>
            )
          ) : (
            <Button
              color="primary"
              className={`w-100 h-100 ${permissions?.edit_appointments ? "" : "disabled"
                }`}
              onClick={() => {
                handleStart();
              }}
            >
              Start{" "}
              {appointment_data?.appointment_type === "video-call"
                ? "Video Call"
                : "Appointment"}
            </Button>
          )}
        </div>
        <div className="action-btns d-flex">
          {appointment_data?.appointment_start ? (
            appointment_data?.appointment_end ? (
              <> </>
            ) : (
              <Button
                color="link"
                onClick={() => {
                  handleStop();
                }}
                className={`flex-fill ${permissions?.edit_appointments ? "" : "disabled"
                  }`}
              >
                Stop
              </Button>
            )
          ) : (
            <>
              <Button
                color="link"
                onClick={() => {
                  /*handleCancelAppointment()*/
                }}
                className="flex-fill"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleEditAppointment();
                }}
                color="link"
                className="flex-fill"
              >
                Edit
              </Button>
            </>
          )}
          {/*<Button color="link" className="flex-fill">Message</Button>*/}
        </div>
      </div>
    </>
  );
};

export default ScheduleInformation;
