import React, { useEffect, useRef, useState } from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import UserPlaceholder from "../../../../../../../shared/UserPlaceholder";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getTreatmentDetailById } from "../../../../../../../context/actions/patient-module/orders";
import { Offcanvas } from "react-bootstrap";
import redCancel from "assets/img/icons/redCancel.png";
import greenCheck from "assets/img/icons/greenCheck.png";
import {
  updateTreatmentReportStatus,
  updateTreatmentStatus,
  updateTreatmentUnkownStatusData,
} from "context/actions/pet-owner-dashboard";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Spinner } from "reactstrap";
import "./index.scss";
import { titleCaseString } from "utils/common";
import { toast } from "react-toastify";
import { toasterConfig } from "utils/constants";

const TreatmentPlanItem = ({ data, pet_Id, getPageData }) => {
  const userDetails = useSelector((state) => state.user.user_details);
  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  const dispatch = useDispatch();
  const newRef = useRef(null);
  const { petId } = useParams();
  const [show, setShow] = useState(false);
  const [noteValue, setNoteValue] = useState("");

  const [singleCardData, setSingleCardData] = useState(null);
  const [treatmentStatus, setTreatmentStatus] = useState(null);

  const [dateTimeData, setDateTimeData] = useState([]);
  const [treatmentData, setTreatmentData] = useState([]);
  const [loader, setLoader] = useState(false);
  // const [toggleDays, setToggleDays] = useState(false);
  // const [Length, setLength] = useState(5);

  const getPercentComplete = () => {
    let total = 0;
    let totalTaken = (
      data?.treatment_reports?.filter(
        (o) => o?.product_plan_status === "taken"
      ) || []
    )?.length;
    let conData = getData(data?.mergedData || []);
    conData?.forEach((o) => {
      o?.data?.forEach((e) => {
        total = total + e?.timeList?.length;
      });
    });
    let count = Math.ceil((totalTaken / total) * 100);
    return count;
  };
  // const getPercentComplete = () => { //  from careplan
  //   let startDate = moment(data?.minStartDate);
  //   let endDate = moment(data?.maxEndDate);
  //   let currentDate = moment();

  //   let val =
  //     startDate.diff(currentDate, "hours") /
  //     startDate?.diff(data?.maxEndDate, "hours");
  //   if (val > 1) {
  //     return 100;
  //   } else if (val < 0) {
  //     return 0;
  //   } else {
  //     return Math.round(val * 100);
  //   }
  // };


  const getDateRange = (start_date, end_date) => {
    // Parse the start date
    let startDate = new Date(start_date);
    startDate = moment(startDate).format("MM/DD/YYYY");

    // Calculate the end date based on duration_unit and duration_num
    let endDate = new Date(end_date);
    endDate = moment(endDate).format("MM/DD/YYYY");

    return `${startDate} - ${endDate}`;
    // return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
  };

  const handleShow = (id) => {
    dispatch(
      getTreatmentDetailById(id, (res) => {
        let data = res?.response_data?.[0] || null;
        if (data) {
          const dateIntarval = getOrderDates(
            data?.minStartDate,
            data?.maxEndDate
          );
          let dateData = [];
          let { productDetails = [], instructionDetails = [] } = data || {};
          let conData = getData([...productDetails, ...instructionDetails]);
          setTreatmentData(conData);
          dateIntarval?.forEach((o) => {
            let currentDataTimeList = [];
            conData?.forEach((d) => {
              const findData = d?.data?.find(
                (obj) => obj.dDate === o.format("DD-MM-YYYY")
              );
              if (findData?.timeList) {
                currentDataTimeList = [
                  ...currentDataTimeList,
                  ...findData?.timeList,
                ];
              }
            });
            const sortedTimeArray = currentDataTimeList.sort((a, b) => {
              return moment(a, "hh:mm A").diff(moment(b, "hh:mm A"));
            });
            const uniqueTimes = Array.from(new Set(sortedTimeArray));
            dateData.push({
              date: o,
              timeList: uniqueTimes,
            });
          });
          setDateTimeData(dateData);

          // ------------------------------ for unknown status

          const filtersData = [];
          dateData?.forEach((elem) => {
            const { date, timeList } = elem;

            timeList?.forEach((time) => {
              const mergeDateTime = moment(
                `${date.format("YYYY-MM-DD")} ${time}`
              );
              const dateTime = moment(`${date.format("YYYY-MM-DD")} ${time}`);
              const newmergeDateTime = dateTime.add(72, "hours");

              const currentDate = moment();
              const diffInHours = currentDate?.diff(mergeDateTime, "hours");
              const isUnknownValue = diffInHours >= 72;

              let matchList = conData?.filter((o) =>
                o.data.some(
                  (e) =>
                    e.dDate === date.format("DD-MM-YYYY") &&
                    e.timeList.includes(time)
                )
              );

              const alreadyExisistObj = data?.treatment_reports?.find(
                (o) =>
                  o?.date === date.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") &&
                  o.time === time
              );

              matchList?.forEach((matchElem) => {
                if (
                  isUnknownValue &&
                  !alreadyExisistObj?.isUnknown &&
                  !alreadyExisistObj?.plan_status
                ) {
                  filtersData.push({
                    plan_id: matchElem?._id,
                    treatment_id: data?._id,
                    created_by: userDetails?._id,
                    pet_id: pet_Id,
                    date: moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                    unknownCreatedDate: moment(newmergeDateTime).format(
                      "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                    ),
                    time: time,
                    isUnknown: true,
                  });
                }
              });
            });
          });

          if (filtersData?.length > 0) {
            dispatch(
              updateTreatmentUnkownStatusData(
                { orders: filtersData },
                (res) => {
                  if (res?.status) {
                    dispatch(
                      getTreatmentDetailById(id, (res) => {
                        if (res?.status) {
                          setSingleCardData(res?.response_data?.[0] || null);
                          setTreatmentStatus(res?.response_data?.[0]?.status);
                          setLoader(false);
                        }
                      })
                    );
                  }
                }
              )
            );
          } else {
            setSingleCardData(data);
            setTreatmentStatus(data?.status);
            setLoader(false);
          }
        }
        setSingleCardData(data);
        setTreatmentStatus(data?.status);
        setLoader(false);
      })
    );
    setShow(true);
  };

  const handleClose = () => {
    setSingleCardData(null);
    // setLength(5);
    setShow(false);
  };

  const getOrderDates = (start, end) => {
    var dates = [];

    const minStartDateMoment = moment(start, "YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    const maxEndDateMoment = moment(end, "YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    let currDate = minStartDateMoment.clone().startOf("day");
    const lastDate = maxEndDateMoment.startOf("day");
    while (currDate.isSameOrBefore(lastDate, "day")) {
      dates.push(currDate.clone());
      currDate.add(1, "days");
    }

    return dates;
  };

  // const getOrderDates = () => { //from careplan
  //   var dates = [];

  //   var currDate = moment(singleCardData[0]?.minStartDate).startOf("day");
  //   var lastDate = moment(singleCardData[0]?.maxEndDate).startOf("day");

  //   while (currDate.isBefore(lastDate, "day")) {
  //     dates.push(currDate.clone());
  //     currDate.add(1, "days");
  //   }
  //   return dates;
  // };


  const getDoseAndTimeIntarval = (frequency, fromTime, toTime) => {
    let intarvalTime = 5;
    let intarvalType = "minutes";
    switch (frequency) {
      case "q5m":
        intarvalTime = 5;
        intarvalType = "minutes";
        break;
      case "q15m":
        intarvalTime = 15;
        intarvalType = "minutes";
        break;
      case "q30m":
        intarvalTime = 30;
        intarvalType = "minutes";
        break;
      case "q2h":
        intarvalTime = 2;
        intarvalType = "hours";
        break;
      case "q4h":
        intarvalTime = 4;
        intarvalType = "hours";
        break;
      case "q6h":
        intarvalTime = 6;
        intarvalType = "hours";
        break;
      case "q8h":
        intarvalTime = 8;
        intarvalType = "hours";
        break;
      case "q12h":
        intarvalTime = 12;
        intarvalType = "hours";
        break;
      case "q24h":
        intarvalTime = 24;
        intarvalType = "hours";
        break;
      case "daily":
        intarvalTime = 1;
        intarvalType = "days";
        break;
      case "daily at bedtime":
        intarvalTime = 24;
        intarvalType = "hours";
        break;
      case "BID":
        intarvalTime = 12;
        intarvalType = "hours";
        break;
      case "TID":
        intarvalTime = 8;
        intarvalType = "hours";
        break;
      case "QID":
        intarvalTime = 6;
        intarvalType = "hours";
        break;
      case "every other day":
        intarvalTime = 1;
        intarvalType = "days";
        break;
      case "once":
        intarvalTime = 1;
        intarvalType = "days";
        break;

      default:
        break;
    }
    const startTime = moment(fromTime, "DD-MM-YYYY hh:mm A");
    const endTime = moment(toTime, "DD-MM-YYYY hh:mm A");
    const minutesArray = [];
    for (
      let currentMoment = startTime.clone();
      currentMoment.isBefore(endTime);
      currentMoment.add(intarvalTime, intarvalType)
    ) {
      minutesArray.push(currentMoment.format("hh:mm A"));
    }
    return { timeList: minutesArray };
  };

  const getData = (nData) => {
    let returnValue = [];
    nData?.forEach((o) => {
      const { start_Date, end_Date, frequency } = o;
      let fDates = getOrderDates(start_Date, end_Date);
      let newData = [];
      fDates.forEach((e, i) => {
        if (i === 0) {
          let fromTime = moment(
            start_Date,
            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
          ).format("DD-MM-YYYY hh:mm A");
          let toTime = `${moment(
            start_Date,
            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
          ).format("DD-MM-YYYY")} 11:59 PM`;
          let newTimeArray = getDoseAndTimeIntarval(
            frequency,
            fromTime,
            toTime
          );
          newData.push({
            date: e,
            dDate: e.format("DD-MM-YYYY"),
            timeList: newTimeArray?.timeList,
          });
        } else {
          let prevList = newData?.[i - 1];
          let prevDate = prevList?.date?.format("DD-MM-YYYY");
          let fromTime = `${prevDate} ${prevList?.timeList?.[prevList?.timeList?.length - 1]
            }`;

          let toTime = `${moment(e, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
            "DD-MM-YYYY"
          )} 11:59 PM`;
          let newTimeArray = getDoseAndTimeIntarval(
            frequency,
            fromTime,
            toTime
          );
          let timeListData = newTimeArray?.timeList;
          timeListData?.shift();
          newData.push({
            date: e,
            dDate: e.format("DD-MM-YYYY"),
            timeList: timeListData,
          });
        }
      });
      returnValue.push({ ...o, data: newData });
    });
    return returnValue;
  };

  const getDateTitle = (nDate) => {
    const fDate = nDate.format("DD-MM-YYYY");
    const today = moment().format("DD-MM-YYYY");
    const tomorrow = moment().add(1, "days").format("DD-MM-YYYY");
    const yesterday = moment().subtract(1, "days").format("DD-MM-YYYY");
    let prefix =
      fDate === today
        ? "Today"
        : fDate === yesterday
          ? "Yesterday"
          : fDate === tomorrow
            ? "Tomorrow"
            : nDate.format("dddd");
    return `${prefix}, ${nDate.format("MMM D YYYY")}`;
  };

  const handleSelectDose = async (type, data, iDate, iTime, noteValue) => {
    const response = await dispatch(
      updateTreatmentReportStatus({
        plan_id: data?._id,
        treatment_id: singleCardData?._id,
        created_by: userDetails?._id,
        pet_id: petId || pet_Id || "",
        date: moment(iDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        time: iTime,
        instruction_plan_status: type,
        product_plan_status: type,
        createdDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        note: noteValue,
      })
    );
    if (response?.status) {
      handleShow(singleCardData?._id);
      getPageData(petId || pet_Id || "");
      setNoteValue("");
    } else {
      toast.error(response?.message, toasterConfig);
    }
  };

  const handleStatusChange = (status, treatmentId) => {
    let body = { treatment_id: treatmentId, status: status };
    dispatch(
      updateTreatmentStatus(body, (res) => {
        if (res?.status) {
          dispatch(
            getTreatmentDetailById(treatmentId, (res) => {
              if (res?.status) {
                setTreatmentStatus(res?.response_data?.[0]?.status);
              }
            })
          );
        }
      })
    );
  };
  useEffect(() => {
    setTimeout(() => {
      newRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }, 500);
  }, [treatmentData]);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        style={{ minWidth: '25%' }}

        placement="end"
        className="treatment-plan-offcanvas"
      >
        <Offcanvas.Header closeButton className="pb-0">
          <Offcanvas.Title>Treatment Report</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-2 treatment-plan-block">
          {loader ? (
            <div className="f-center h-100 w-250">
              <Spinner animation="border" role="status" />
            </div>
          ) : (
            <>
              <div className="profile-cell d-inline-flex align-items-center">
                <div className="pic d-inline-flex rounded-circle overflow-hidden">
                  <img
                    className="img-fluid w-100 h-100"
                    src={`${singleCardData?.user_image !== null
                      ? singleCardData?.user_image
                      : ""
                      }`}
                    alt="User"
                  />
                </div>
                <div className="txt">
                  {singleCardData?.firstName} {singleCardData?.lastName}
                </div>
                <div className="icon d-inline-flex">
                  <span className="material-symbols-outlined">auto_mode</span>
                </div>
              </div>
              <div className="treatment-plan-item bg-transparent">
                <div className="chart-blc d-flex align-items-center mb-1">
                  <div className="chart-item">
                    <CircularProgressbarWithChildren
                      styles={buildStyles({
                        rotation: 0.5,
                        pathColor: `${new Date(singleCardData?.maxEndDate) < new Date()
                          ? "#169677"
                          : new Date(singleCardData?.minStartDate) <
                            new Date() &&
                            new Date(singleCardData?.maxEndDate) > new Date()
                            ? "#fadf48"
                            : new Date(singleCardData?.minStartDate) >
                              new Date()
                              ? "#ececec"
                              : "#ececec"
                          }`,
                        textColor: "#f88",
                        trailColor: "inherit",
                      })}
                      strokeWidth={10}
                      value={getPercentComplete()}
                    >
                      <div
                        style={{
                          fontSize: 10,
                          fontWeight: "bold",
                          padding: "7px 0px",
                          backgroundColor: "#ececec",
                          borderRadius: 50,
                          textAlign: "center",
                          width: 35,
                        }}
                      >
                        {("  " + getPercentComplete()).slice(-3)}%
                      </div>
                    </CircularProgressbarWithChildren>
                  </div>

                  <div className="treatment-profilee">
                    <h4 style={{ textTransform: "capitalize" }}>
                      {singleCardData?.treatmentName}
                    </h4>
                    <p>
                      {getDateRange(
                        singleCardData?.minStartDate,
                        singleCardData?.maxEndDate
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex gap-3 align-items-center cmb-20">
                {/* <div className="">
                  <span
                    className={`state-wrapper-${
                      new Date(singleCardData?.maxEndDate) < new Date()
                        ? "completed"
                        : new Date(singleCardData?.minStartDate) < new Date() &&
                          new Date(singleCardData?.maxEndDate) > new Date()
                        ? "active"
                        : new Date(singleCardData?.minStartDate) > new Date()
                        ? "ordered"
                        : ""
                    }`}
                  >
                    {new Date(singleCardData?.maxEndDate) < new Date()
                      ? "Completed"
                      : new Date(singleCardData?.minStartDate) < new Date() &&
                        new Date(singleCardData?.maxEndDate) > new Date()
                      ? "Active"
                      : new Date(singleCardData?.minStartDate) > new Date()
                      ? "Ordered"
                      : ""}
                  </span>
                  <span className="state-wrapper-completed d-none">
                    completed
                  </span>
                </div> */}

                <div className="treatment-status-container">
                  <select
                    disabled={userDetails?.role !== "admin"}
                    className={`status status-${treatmentStatus}`}
                    value={treatmentStatus}
                    onChange={(e) => {
                      permissions?.edit_orders &&
                        handleStatusChange(e.target.value, singleCardData?._id);
                    }}
                  >
                    <option className={`status status-Ordered`}>Ordered</option>
                    <option className={`status status-Active`}>Active</option>
                    <option className={`status status-Stopped`}>Stopped</option>
                    <option className={`status status-Completed`}>
                      Completed
                    </option>
                  </select>
                </div>
              </div>

              <div className="all-types">
                {dateTimeData?.map((elem, index) => {
                  const { date, timeList } = elem;
                  const takenList = singleCardData?.treatment_reports?.filter(
                    (o) =>
                      o?.date === date.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") &&
                      o?.product_plan_status === "taken"
                  );
                  return (
                    <div key={index} className="treatment-list-wrapper">
                      <div className="fb-center mb-2">
                        <div>
                          <h4>{getDateTitle(date)}</h4>
                        </div>
                        <div className="pe-2">
                          {takenList?.length}/{timeList?.length}
                        </div>
                      </div>
                      {timeList?.map((time, timeIndex) => {
                        // const mergeDateTime = moment(
                        //   `${date.format("YYYY-MM-DD")} ${time}`
                        // );
                        // const currentDate = moment();
                        // const diffInHours = currentDate?.diff(
                        //   mergeDateTime,
                        //   "hours"
                        // );
                        // const isUnknown = diffInHours >= 72;
                        let matchList = treatmentData?.filter((o) =>
                          o.data.some(
                            (e) =>
                              e.dDate === date.format("DD-MM-YYYY") &&
                              e.timeList.includes(time)
                          )
                        );
                        let isTimeVisible = false;
                        [
                          "q5m",
                          "q15m",
                          "q30m",
                          "q2h",
                          "q4h",
                          "q6h",
                          "q8h",
                          "q12h",
                          "BID",
                          "TID",
                          "QID",
                        ].forEach((element) => {
                          if (
                            matchList
                              ?.map((o) => o?.frequency)
                              ?.includes(element)
                          ) {
                            isTimeVisible = true;
                          }
                        });
                        const tempObject =
                          singleCardData?.treatment_reports?.find(
                            (o) =>
                              o?.date ===
                              date.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") &&
                              o.time === time
                          );

                        return (
                          <div key={timeIndex} className="cmb-20">
                            <div className="d-flex gap-2">
                              {isTimeVisible && <h4>{time}</h4>}
                            </div>
                            {matchList?.map((data, dataIndex) => {
                              const { product_id, instructions } = data;
                              let name =
                                data?.details?.product_name ||
                                data?.name;
                              let isEditable = true;
                              let isView = false;
                              const cDate = moment().format("DD-MM-YYYY");
                              const cTime = moment();
                              let nextElem =
                                timeList?.[timeIndex + 1] || "11:59 PM";
                              if (cDate === date.format("DD-MM-YYYY")) {
                                const startTime = moment(time, "hh:mm A");
                                const endTime = moment(nextElem, "hh:mm A");

                                if (cTime.isBetween(startTime, endTime)) {
                                  isEditable = true;
                                  isView = true;
                                }
                              }

                              let takenString = "";
                              let userFirstName,
                                userLastName,
                                createdDate,
                                notesValue;
                              const findObject =
                                singleCardData?.treatment_reports?.find(
                                  (o) =>
                                    o?.date ===
                                    date.format(
                                      "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                    ) &&
                                    o.time === time &&
                                    o?.plan_id === data?._id
                                );
                              if (findObject?._id) {
                                takenString = findObject?.product_plan_status;
                                isEditable = false;
                                userFirstName =
                                  findObject?.added_by[0]?.firstname;
                                userLastName =
                                  findObject?.added_by[0]?.lastname;
                                createdDate = moment(findObject?.createdDate)
                                  .utc()
                                  .format("MMM DD YYYY hh:mm a");
                                notesValue = findObject?.note;
                              }

                              return (
                                <React.Fragment key={dataIndex}>
                                  {isView && <div ref={newRef} />}
                                  {isEditable ? (
                                    <div className="bg-eef2 br-10 cp-10 mt-2">
                                      <h5 className="color-5129 text-15-600">
                                        {tempObject?.isUnknown &&
                                          `System Marked Unknown at ${moment(
                                            tempObject?.unknownCreatedDate,
                                            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                          ).format("MMM DD YYYY hh:mm a")}`}
                                      </h5>
                                      {userDetails?.role === "admin" && (
                                        <div className="note-container cmt-5 gap-3">
                                          Note:
                                          <textarea
                                            onChange={(e) => {
                                              e.preventDefault();
                                              setNoteValue(e.target.value);
                                            }}
                                            className="resize-y rounded-md outline outline-1 outline-[#0909541F] p-2"
                                            rows="1"
                                            cols="30"
                                            id="medical-note"
                                            name="medical-note"
                                            placeholder="Add note (optional)"
                                          />
                                        </div>
                                      )}
                                      <div className="fb-center">
                                        <div>
                                          {product_id
                                            ? "Product: "
                                            : "Instruction: "}
                                          {name}
                                        </div>

                                        {userDetails?.role === "pet-owner" ? (
                                          !tempObject?.isUnknown && (
                                            <div className="fa-center gap-2">
                                              <img
                                                src={redCancel}
                                                alt="cancel"
                                                className="h-18 w-18 cursor-pointer"
                                                onClick={() => {
                                                  handleSelectDose(
                                                    "not taken",
                                                    data,
                                                    date,
                                                    time,
                                                    noteValue
                                                  );
                                                }}
                                              />
                                              <img
                                                src={greenCheck}
                                                alt="check"
                                                className="h-18 w-18 cursor-pointer"
                                                onClick={() => {
                                                  handleSelectDose(
                                                    "taken",
                                                    data,
                                                    date,
                                                    time,
                                                    noteValue
                                                  );
                                                }}
                                              />
                                            </div>
                                          )
                                        ) : (
                                          <div className="fa-center gap-2">
                                            <img
                                              src={redCancel}
                                              alt="cancel"
                                              className="h-18 w-18 cursor-pointer"
                                              onClick={() => {
                                                handleSelectDose(
                                                  "not taken",
                                                  data,
                                                  date,
                                                  time,
                                                  noteValue
                                                );
                                              }}
                                            />
                                            <img
                                              src={greenCheck}
                                              alt="check"
                                              className="h-18 w-18 cursor-pointer"
                                              onClick={() => {
                                                handleSelectDose(
                                                  "taken",
                                                  data,
                                                  date,
                                                  time,
                                                  noteValue
                                                );
                                              }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                      <div className="w-230 text-13-400 mt-1">
                                        {titleCaseString(instructions)}
                                      </div>
                                    </div>
                                  ) : (
                                    <ul className="ps-4 outer-list-items position-relative mt-2">
                                      <li className="list-outer position-relative">
                                        <div className="mb-2 fb-center gap-2">
                                          <div className="taken-steps">
                                            {(product_id
                                              ? "Product: "
                                              : "Instruction: ") + name}
                                          </div>
                                          {takenString && (
                                            <div
                                              className={`pe-2 text-14-400 capitalize ${takenString === "taken"
                                                ? "color-CA9D"
                                                : "color-dc35"
                                                }`}
                                            >
                                              {takenString === "taken" ? "taken" : "Not Given"}
                                            </div>
                                          )}
                                        </div>
                                        <div className="w-230">
                                          {titleCaseString(instructions)}
                                        </div>
                                        <div>
                                          {takenString === "taken" ? "Given by " : "Not Given by"}{" "}
                                          {takenString &&
                                            userFirstName &&
                                            userFirstName}{" "}
                                          {takenString &&
                                            userLastName &&
                                            userLastName}{" "}
                                          {takenString &&
                                            userFirstName &&
                                            userLastName &&
                                            "on"}{" "} {takenString ? createdDate : ""}
                                        </div>
                                        {notesValue && (
                                          <div>{`Note: ${notesValue
                                            ? titleCaseString(notesValue)
                                            : "-"
                                            }`}</div>
                                        )}
                                      </li>
                                    </ul>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
              {/* {getOrderDates().length > 5 && (
            <button
              type="button"
              className="btn btn-success w-full mt-2"
              onClick={() => {
                setToggleDays(!toggleDays);
                setLength(Length === 5 ? getOrderDates().length + 1 : 5);
              }}
            >
              {Length === 5
                ? `+${getOrderDates().length - 5} more`
                : "Show Less"}
            </button>
          )} */}
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>

      <div className="item-col mb-2 ">
        <div
          className="treatment-plan-item cursor-pointer"
          onClick={() => {
            setLoader(true);
            handleShow(data?._id);
          }}
        >
          <div className="head">
            <h2 style={{ textTransform: "capitalize" }}>
              {data?.treatmentPlanName}
            </h2>
            <h3>
              <span>{getDateRange(data?.minStartDate, data?.maxEndDate)}</span>
            </h3>
          </div>
          <div className="chart-blc d-flex align-items-center">
            <div className="chart-item">
              <CircularProgressbarWithChildren
                styles={buildStyles({
                  rotation: 0.5,
                  pathColor: "#E0CE2A",
                  textColor: "#f88",
                  trailColor: "inherit",
                })}
                strokeWidth={10}
                value={getPercentComplete()}
              >
                <div
                  style={{
                    width: 35,
                    padding: "7px 0px",
                    backgroundColor: "#ececec",
                    borderRadius: 50,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: 10,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    {("  " + getPercentComplete()).slice(-3)}%
                  </div>
                </div>
              </CircularProgressbarWithChildren>
            </div>
            Active
          </div>
          <div className="treat-by-cell d-inline-flex align-items-center">
            <div className="pic d-inline-flex rounded-circle overflow-hidden flex-shrink-0">
              <img
                className="img-fluid w-100 h-100"
                src={`${data?.user_image !== null ? data?.user_image : ""}`}
                alt="User"
              />
              <UserPlaceholder />
            </div>
            <div className="txt">
              {data?.firstName} {data?.lastName}
            </div>
            <div className="icon d-inline-flex">
              <span className="material-symbols-outlined">auto_mode</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TreatmentPlanItem;
