import React from "react";
import { Button } from "reactstrap";
// import AddSlotButton from "../AddSlotButton";
// import SlotItemName from "../SlotItemName";
// import SlotItemTime from "../SlotItemTime";
// import SlotUnavailable from "../SlotUnavailable";
// import ViewMoreSlot from "../ViewMoreSlot";
import UserPicCell from "./UserPicCell";
import SlotTimeFrame from "../SlotTimeFrame";
import "./index.scss";
import { dayTimeSpan } from "../../../utils/calendar/constant";
import { Fragment } from "react";

const CalendarWeekDay = ({ doctorsList = [], dateVal }) => {
  console.log(doctorsList)
  return (
    <>
      <div className="calendar-day-view overflow-auto">
        <table className="table">
          <thead>
            <tr>
              <th className="syn-btn-wrapper">
                <Button color="link" className="d-inline-flex syn-btn p-0">
                  <span className="material-symbols-outlined">sync</span>
                </Button>
              </th>
              {doctorsList?.map((doctor, i) => (
                <th className="user-pic-cell-wrapper" key={i}>
                  <UserPicCell doctor={doctor} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dayTimeSpan.map((item, i) => {
              return (
                <tr key={i}>
                  <th className="time-cell-wrapper">
                    <div className="time-cell">
                      {item?.value}
                      <span className="d-block">{item?.name}</span>
                    </div>
                  </th>
                  {doctorsList?.map((doctor, i2) => {
                    let isMatch = false;
                    let prevData = null;
                    doctor?.appointments?.forEach((d) => {
                      d.appointment_timings.forEach((t) => {
                        let startHour = t.appointment_time
                          ?.split(" - ")?.[0]
                          ?.split(":")?.[0];
                        let endHour = t.appointment_time
                          ?.split(" - ")
                          ?.pop()
                          ?.split(":")?.[0];
                        let endMinute = t.appointment_time
                          ?.split(" - ")
                          ?.pop()
                          ?.split(":")?.[1]
                          ?.split(" ")?.[0];
                        let endName = t.appointment_time
                          ?.split(" - ")
                          ?.pop()
                          ?.split(" ")
                          ?.pop();
                        if (
                          +startHour === item.value - 1 &&
                          +endHour === item.value &&
                          endName === item.name &&
                          +endMinute > 0
                        ) {
                          prevData = d;
                        }
                        if (
                          t.appointment_from[0] === item.value &&
                          t.appointment_from[1] === item.name
                        ) {
                          isMatch = true;
                        }
                      });
                    });

                    return (
                      <Fragment key={i2}>
                        {isMatch ? (
                          <td className="p-0 no-hover">
                            <div className="data-container">
                              <SlotTimeFrame
                                className="day-view-slot"
                                addSlot={false}
                                data={doctor.appointments}
                                doctor={doctor}
                                item={item}
                                prevData={prevData}
                              />
                            </div>
                          </td>
                        ) : (
                          <td className="p-0 no-hover">
                            <div className="data-container">
                              <SlotTimeFrame
                                className="day-view-slot"
                                addSlot={true}
                                prevData={prevData}
                              />
                            </div>
                          </td>
                        )}
                      </Fragment>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CalendarWeekDay;

export const CalendarWeekDayForOperatingRoom = ({
  operatingRooms,
  dateVal,
}) => {
  return (
    <>
      <div className="calendar-day-view overflow-auto">
        <table className="table">
          <thead>
            <tr>
              <th className="syn-btn-wrapper">
                <Button color="link" className="d-inline-flex syn-btn p-0">
                  <span className="material-symbols-outlined">sync</span>
                </Button>
              </th>
              {operatingRooms &&
                operatingRooms?.length > 0 &&
                operatingRooms?.map((room, i) => (
                  <th className="user-pic-cell-wrapper" key={i}>
                    {room.name}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {dayTimeSpan.map((item, i) => {
              return (
                <tr key={i}>
                  <th className="time-cell-wrapper">
                    <div className="time-cell">
                      {item?.value}
                      <span className="d-block">{item?.name}</span>
                    </div>
                  </th>
                  {operatingRooms &&
                    operatingRooms.length > 0 &&
                    operatingRooms.map((room, i2) => (
                      <Fragment key={i2}>
                        {room?.appointments &&
                        room?.appointments?.filter((data) =>
                          data.appointment_date === dateVal &&
                          data.appointment_timings.filter((ele) => {
                            return (
                              ele.appointment_from[0] === item.value &&
                              ele.appointment_from[1] === item.name
                            );
                          }).length > 0
                            ? true
                            : false
                        ).length > 0 ? (
                          <td className="p-0 no-hover">
                            <div className="data-container">
                              <SlotTimeFrame
                                className="day-view-slot"
                                addSlot={false}
                                data={room.appointments}
                                item={item}
                              />
                            </div>
                          </td>
                        ) : (
                          <td className="p-0 no-hover">
                            <div className="data-container">
                              <SlotTimeFrame
                                className="day-view-slot"
                                addSlot={true}
                              />
                            </div>
                          </td>
                        )}
                      </Fragment>
                    ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
