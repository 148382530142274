import { getAllInventoryCategories } from "context/actions/app-content";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input } from "reactstrap";

const TableListFilterBlock = ({ setFilter, setSearch }) => {
  const dispatch = useDispatch();
  const { inventoryCategories } = useSelector(
    (state) => state.appContentReducer
  );
  const { supplierData } = useSelector((state) => state.supplierReducer);
  useEffect(() => {
    if (!inventoryCategories?.length) {
      dispatch(getAllInventoryCategories(() => {}));
    }
  }, []);

  return (
    <>
      <div className="table-list-filter-blc d-flex align-items-center">
        <div className="search-cell col-cell position-relative">
          <span className="icomoon-search d-inline-flex align-items-center justify-content-center position-absolute"></span>
          <Input
            placeholder="Search"
            onChange={(e) => setSearch((prev) => e.target.value)}
          />
        </div>
        <div className="input-cell col-cell">
          <Input
            type="select"
            name="select"
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, expiredStatus: e.target.value }))
            }
          >
            <option value="">All Products</option>
            <option value="Expired Only">Expired</option>
            <option value="Partially Expired">Partially Expired </option>
            <option value="Not Expired"> Not Expired </option>
          </Input>
        </div>
        <div className="input-cell col-cell">
          <Input
            type="select"
            name="select"
            style={{ textTransform: "capitalize" }}
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, categoryId: e.target.value }))
            }
          >
            <option value="">All Categories</option>
            {inventoryCategories &&
              inventoryCategories?.map((cat) => (
                <option key={cat?._id} value={cat?._id} className="capitalize">
                  {cat?.category}
                </option>
              ))}
          </Input>
        </div>
        <div className="input-cell col-cell">
          <Input
            type="select"
            name="select"
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, stockStatus: e.target.value }))
            }
          >
            <option value="">All Status</option>
            <option value="In Stock"> In Stock</option>
            <option value="Off Stock">Out of Stock</option>
          </Input>
        </div>
        <div className="input-cell col-cell">
          <Input
            type="select"
            name="select"
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, supplierId: e.target.value }))
            }
          >
            <option value="">All Suppliers</option>
            {supplierData?.docs?.map((supplier) => {
              return (
                <option
                  key={supplier?._id}
                  value={supplier?._id}
                  className="capitalize"
                >
                  {supplier?.supplier_name}
                </option>
              );
            })}
          </Input>
        </div>
        <div className="more-input-cell col-cell">
          <Button color="link" className="d-inline-flex align-items-center">
            More
            <span class="material-symbols-outlined">add</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default TableListFilterBlock;
