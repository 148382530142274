const initState = {
    loading: true,
    roles: false,
    races: false,
    cities: false,
    species: [],
    breeds: [],
    behaviourTags: false,
    allergiesTags: false,
    inventoryCategories: false,
    serviceCategories: false,

}

export const appContentReducer = (state = initState, action) => {
    switch (action.type) {
        case "LOADING":
            return { ...state, loading: action.payload }

        case "GET_ROLES":
            return { ...state, roles: action.payload }

        case "GET_RACE":
            return { ...state, races: action.payload }

        case "GET_SPECIES":
            return { ...state, species: action.payload }

        case "GET_BREEDS":
            return { ...state, breeds: action.payload }

        case "GET_INVENTORY_CATEGORIES":
            return { ...state, inventoryCategories: action.payload }

        case "GET_SERVICE_CATEGORIES":
            return { ...state, serviceCategories: action.payload }

        case "GET_CITIES":
            return { ...state, cities: action.payload }

        case "GET_BEHAVIOUR_TAGS":
            return { ...state, behaviourTags: action.payload }

        case "GET_ALLERGIES_TAGS":
            return { ...state, allergiesTags: action.payload }

        default:
            return state
    }
}