import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";
toast.configure();
/**
 * All Api endpoints to be call
 * Get   -- /inventory/get-all/:clinic_id
 * Get   -- /point-of-sale/get-all/:clinic_id
 * Get   -- /inventory/get-details/:inventory_id
 * Get   -- /inventory/get-inventory-list/:clinic_id
 * Post  -- /inventory/add
 * Post  -- /inventory/add-description
 * Post  -- /inventory/add-tag
 * Post  -- /inventory/remove-tag
 * Post  -- /inventory/add-special-note
 * Post  -- /inventory/remove-special-note
 * Post  -- /inventory/add-package
 * Post  -- /inventory/add-tag-image/:inventory_id
 * Post  -- /inventory/remove-tag-image
 * Put   -- /inventory/update
 * Put   -- /inventory/update-package
 * Put   -- /inventory/update-image/:inventory_id
 */

/**
 *
 * @param {Object} params
 * @param {Fuction} callback
 * @returns
 */

export const getInventoriesForCharts = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/inventory/get-inventories-for-charts`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response) {
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

export const getPackagesOfSpecificProduct = (inventory_id, callback) => {
  return (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  ApiClient
    .get(`${apiUrl}${PORT}/inventory/get-packages/${inventory_id}`, {}, token, dispatch, getState)
    .then((res) => {
      return callback(res.data);
    })
    .catch((err) => {
      // console.log("error in fetching packages", err.message);
    });
  }
};

export const addPackageToInventory = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/inventory/add-package`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response) {
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

export const updateInventoryPackage = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.put(
      `${apiUrl}${PORT}/inventory/update-package`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response) {
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

export const addInventoryProduct = (data, callback) => {
  return (dispatch, getState) => {
   
    const {
      user: { token },
    } = getState();
    ApiClient
      .postFormData(`${apiUrl}${PORT}/inventory/add`, data, token, dispatch, getState)
      .then((res) => {
        const responseData = res;
        localStorage.setItem("inventory_id", responseData?.response_data?._id);
        toast.dismiss();
        toast.success(res.message, toasterConfig);
        callback(responseData); // Invoke the callback function
      })
      .catch((err) => {
        toast.error(err.message, toasterConfig);
      });
  };
};
export const addSpecialNoteToInventory = (data, callback) => {
  //
  return (dispatch, getState) => {

  const {
    user: { token },
  } = getState();
  ApiClient
    .post(`${apiUrl}${PORT}/inventory/add-special-note`, data, token, dispatch, getState)
    .then((res) => {
      //
      return res.data;
    })
    .then((data) => {
      //
      // console.log("special note added", data);
      // localStorage.setItem("inventory_id",data?.response_data?._id)
      toast.dismiss();

      toast.success(data.message, toasterConfig);
    })
    .catch((err) => {
      // console.log("err inproduct addition", err.message);
      toast.error(err.message, toasterConfig);
    });
  }
  
};

export const getInventoriesByClinicId = (clinic_id, payload, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      ApiClient.post(
        `${apiUrl}${PORT}/inventory/get-all/${clinic_id}`,
        payload,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response) {
          // dispatch({
          //   type: "GET_INVENTORIES",
          //   payload: response?.response_data,
          // });
          dispatch({
            type: "GET_INVENTORIES",
            payload: response?.response_data,
          });
          return callback(response);
          // return callback(true);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(false);
        }
      });
    }
    // else {
    //   return callback(null);
    // }
  };
};

export const getInventoriesByClinicIdWithoutLimit = (
  clinic_id,
  params,
  callback
) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      ApiClient.get(
        `${apiUrl}${PORT}/inventory/get-all-without-limit/${clinic_id}`,
        params,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response) {
          dispatch({
            type: "GET_INVENTORIES",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    }
  };
};

export const getTotalInventoriesByClinicId = (clinicId, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    if (clinicId) {
      ApiClient.get(
        // `${apiUrl}${PORT}/inventory/get-inventory-list/${clinicId}`,
        `${apiUrl}${PORT}/inventory/get-all/${clinicId}`,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({
            type: "GET_TOTAL_INVENTORIES",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    } else {
      return callback(null);
    }
  };
};
export const getTotalInventoriesByClinicByYear = (param, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    if (param) {
      ApiClient.get(
        // `${apiUrl}${PORT}/inventory/get-inventory-list/${clinicId}`,
        `${apiUrl}${PORT}/inventory/get-clinic-inventory-list`,
        param,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({
            type: "GET_TOTAL_INVENTORIES_BY_YEAR",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    } else {
      return callback(null);
    }
  };
};

export const getInventoryCardsData = (clinic_id, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.get(
      `${apiUrl}${PORT}/inventory/get-inventory-cards-data/${clinic_id}/${2023}`,
      {},
      token,
      dispatch, getState
    )
      .then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          console.log("response", response);
          return callback(response?.response_data);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      })
      .catch((error) => {
        console.log(error);
        return callback();
      });
  };
};

/**
 * @param {String} inventoryId
 * @param {Object} params
 * @param {Fuction} callback
 * @returns
 */
export const getInventoryDetailsById = (inventoryId, params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    if (inventoryId) {
      ApiClient.get(
        // `${apiUrl}${PORT}/inventory/get-details/${inventoryId}`,
        `${apiUrl}${PORT}/inventory/get-single-product/${inventoryId}`,
        params,
        token,
        dispatch, getState
      ).then((response) => {
        //
        if (!response) {
          //
          return callback();
        } else if (response?.status) {
          //
          // console.log("")
          dispatch({
            type: "GET_INVENTORY_DETAILS",
            // payload: response?.response_data,
            payload: response?.status[0],
          });

          return callback(response);
        } else {
          //
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    }
  };
};

/**
 * @param {Object} body
 * @param {Fuction} callback
 * @returns
 */
export const getAndSearchProductList = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      body.clinic_id = clinic_details?._id;
      ApiClient.post(
        `${apiUrl}${PORT}/inventory/get-and-search`,
        body,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({
            type: "GET_PRODUCT_LIST",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    }
  };
};

/**
 * @param {Object} body
 * @param {Fuction} callback
 * @returns
 */
export const getAndSearchProductListByCategory = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      body.clinic_id = clinic_details?._id;
      ApiClient.post(
        `${apiUrl}${PORT}/inventory/get-and-search-by-category`,
        body,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({
            type: "GET_PRODUCT_LIST",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    }
  };
};

/**
 * @param {Object} body
 * @param {Fuction} callback
 * @returns
 */
export const getAndSearchProductsAndServices = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      body.clinic_id = clinic_details?._id;
      ApiClient.post(
        `${apiUrl}${PORT}/inventory/get-and-search-with-service`,
        body,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({
            type: "GET_PRODUCT_SERVICE_LIST",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    }
  };
};



/**
 * @param {Object} body
 * @param {Fuction} callback
 * @returns
 */
export const getAndSearchProductsServicesDiagnostics = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      body.clinic_id = clinic_details?._id;
      ApiClient.post(
        `${apiUrl}${PORT}/inventory/get-and-search-service-diagnostic`,
        body,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({
            type: "GET_PRODUCT_SERVICE_DIAGNOSTIC_LIST",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    }
  };
};


/**
 *
 * @param {Object} body
 * @param {Function} callback
 * @returns
 */
export const updateInventory = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.put(
      `${apiUrl}${PORT}/inventory/update`,
      body,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (response?.status) {
        dispatch(getInventoriesByClinicId({}, () => { }));
        toast.success(response?.message, toasterConfig);
        return callback(response);
      } else {
        toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};

/**
 *
 * @param {Object} body
 * @param {Function} callback
 * @returns
 */
export const updateInventoryDetails = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.put(`${apiUrl}${PORT}/inventory/update-inventory-details`, body, token, dispatch, getState).then(
      (response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          toast.success(response?.message, toasterConfig);
          return callback(response);
        } else {
          toast.error(response.message, toasterConfig);
          return callback(response);
        }
      }
    );
  };
};


// point of sale

/**
 *
 * @param {Object} params
 * @param {Fuction} callback
 * @returns
 */
export const getPointOfSalesByClinicId = (params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();

    if (clinic_details) {
      ApiClient.get(
        `${apiUrl}${PORT}/point-of-sale/get-all/${clinic_details?._id}`,
        params,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({
            type: "GET_POINT_OF_SALES",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    } else {
      return callback(null);
    }
  };
};

/**
 * @param {Object} body
 * @param {Fuction} callback
 * @returns
 */
export const getAndSearchPointOfSales = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      body.clinic_id = clinic_details?._id;

      ApiClient.post(
        `${apiUrl}${PORT}/point-of-sale/get-and-search`,
        body,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({
            type: "GET_POINT_OF_SALES",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    }
  };
};

// purchase orders

/**
 *
 * @param {Object} params
 * @param {Fuction} callback
 * @returns
 */
export const getPurchaseOrdersByClinicId = (params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      ApiClient.get(
        `${apiUrl}${PORT}/purchase-order/get-all/${clinic_details?._id}`,
        params,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({
            type: "GET_PURCHASE_ORDERS",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    } else {
      return callback(null);
    }
  };
};

/**
 * @param {Object} body
 * @param {Fuction} callback
 * @returns
 */
export const getAndSearchPurchaseOrders = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      body.clinic_id = clinic_details?._id;

      ApiClient.post(
        `${apiUrl}${PORT}/purchase-order/get-and-search`,
        body,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({
            type: "GET_PURCHASE_ORDERS",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    }
  };
};

/**
 * @param {Object} body
 * @param {Fuction} callback
 * @returns
 */

//refactor this function according to add inventory
export const AddProductInventory = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/inventory/add`,
      body,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (response?.status) {
        //change dispatch function
        // dispatch({
        //   type: "GET_PURCHASE_ORDERS",
        //   payload: response?.response_data,
        // });
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};

// -------------------------Supplier Section-----------------------------

export const addNewSupplier = (data, callback) => {
  //
  return (dispatch, getState) => {
    dispatch({ type: "SUPPLIER_LOADING", data: data });
    const {
      user: { token },
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/supplier/create`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      dispatch({ type: "SUPPLIER_LOADING", data: data });
      if (!response) {
        //
        return callback();
      } else if (!response && response.errors) {
        //
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response.status) {
        //
        console.log("here in", response);
        toast.dismiss();
        toast.success(response.message, toasterConfig);
        //localStorage.setItem("supplier_id",response.response_data?._id)
        return callback(response);
      } else {
        //
        //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};

export const getSuppliersByClinicId = (clinic_id, params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      ApiClient.get(
        `${apiUrl}${PORT}/supplier/get-all/${clinic_id}`,
        params,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response) {
          dispatch({
            type: "GET_SUPPLIERS",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(false);
        }
      });
    } else {
      return callback(null);
    }
  };
};

export const getSuppliersByClinicIdWithoutLimit = (
  clinic_id,
  params,
  callback
) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      ApiClient.get(
        `${apiUrl}${PORT}/supplier/get-all-without-limit/${clinic_id}`,
        params,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response) {
          dispatch({
            type: "GET_SUPPLIERS",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(false);
        }
      });
    } else {
      return callback(null);
    }
  };
};
