import React, { useState } from "react";
import { Button, Table, FormGroup, Input, InputGroup } from "reactstrap";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import './index.scss'
import { ReactComponent as DeleteSvg } from '../../../assets/img/delete.svg'
import { ReactComponent as CheckSvg } from '../../../assets/img/check_circle.svg'
import SwitchCell from "../../../shared/SwitchCell";
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as CrossSvg } from "../../../assets/img/close-square-svgrepo-com.svg"
import * as Yup from "yup";
import { ReactComponent as PencilEditSvg } from "../../../assets/img/pencil-edit-button-svgrepo-com.svg"


const TaxBlock = ({ className = '', addNew, setAddTax, taxes, setTaxes }) => {

	const [editVal, setEditVal] = useState(null)


	const handleSubmit = (values) => {
		setTaxes((taxesPrev) => [...taxesPrev, values])
		setAddTax(false)

	}

	const itemSchema = Yup.object().shape({
		id: Yup.string().required(),
		tax_name: Yup.string().required(),
		tax_percentage: Yup.number().required(),
	});


	let taxDefaultState = {
		id: uuidv4(),
		tax_name: "",
		tax_percentage: undefined
	}


	const removeTax = (e, id) => {
		e.preventDefault();
		let discountsUpdated = taxes.filter((ele) => ele.id != id)
		setTaxes(discountsUpdated)
	}


	return (
		<>
			<div className={`package-info-blc ${className}`}>
				{(taxes?.length > 0 || addNew) &&
					<div className="table-listing-blc overflow-auto">
						<Formik
							initialValues={editVal || taxDefaultState}
							enableReinitialize
							validationSchema={itemSchema}
							onSubmit={(vals, { resetForm }) => {
								// console.log("ERR", errors)
								if (editVal) {
									// Update the item in the lineItems array with the edited values
									const updatedLineItems = taxes.map((item) =>
										item.id === editVal.id ? vals : item
									);
									setTaxes(updatedLineItems);
									setEditVal(null); // Clear the editItem state
								}
								else {
									handleSubmit(vals)

								}
								resetForm(taxDefaultState);
							}}>

							{(formik) => (
								<Form>
									<Table responsive className="table-striped">
										<thead>
											<tr>
												<th className="bg-transparent">Name</th>
												<th className="bg-transparent" style={{ minWidth: '120px' }}>Percentage</th>
											</tr>
										</thead>
										<tbody>

											{(addNew || editVal) &&

												<tr className="bg-[#ffffff]">

													<td>

														<Input value={formik?.values?.tax_name} onChange={(e) => formik.handleChange(e)} name="tax_name" placeholder="-Add Info-" type="text" />

													</td>
													<td>

														<Input type="number" value={formik?.values?.tax_percentage} onChange={(e) => formik.handleChange(e)} name="tax_percentage"placeholder="-Add Info-" />

													</td>


													{/* <td></td> */}
													<td>
														<div className="flex items-center justify-end"><button class="edit-btn btn btn-link" onClick={(e) => {
															setEditVal(null)
															setAddTax(false)}}><DeleteSvg /></button>
														<button className={'p-1'} style={{ width: '35px' }} size="sm" type="submit" color="success"><CheckSvg /></button>
														</div>
														</td>


												</tr>
											}
											{
												(taxes || []).filter(item => item?.id !== editVal?.id).map((item, i) =>
													<tr key={i}>
														{/* <td>{item?.unitDetails?.unit}</td> */}
														<td>{item?.tax_name}</td>
														<td>
															{item?.tax_percentage}
														</td>

														<td>
															<div className="flex items-center justify-end">
																<button type="button" class="edit-btn btn btn-link" onClick={(e) => removeTax(e, item.id)}><DeleteSvg /></button>
																<button className={'p-1'} style={{ width: '35px' }} size="sm" type="button" onClick={() => {
																	setEditVal(item)
																}}><PencilEditSvg fill="gray" width={18} height={18} /></button>
															</div>
														</td>
													</tr>

												)
											}
										</tbody>
									</Table>
								</Form>)}
						</Formik>
					</div>}
			</div>
		</>
	);
};

export default TaxBlock;
