import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PatientSelection from "./PatientSelection";
import CertificateForm from "./CertificateForm";
import { getMarsOrdersByPet } from "context/actions/patient-module/mars";
import "./CertificateCreation.scss";

const CertificateCreation = ({ onHide }) => {
  const dispatch = useDispatch();
  const [selectedPet, setSelectedPet] = useState(null);
  const handlePetSelection = (petdata) => {
    setSelectedPet(petdata);
  };
  const { marsOrders } = useSelector((state) => state.marReducer);
  const getPetMarData = async (petID) => {
    dispatch(getMarsOrdersByPet(petID, {}, () => {}));
  };
  useEffect(() => {
    if (selectedPet) {
      getPetMarData(selectedPet?._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPet]);
  let productList = marsOrders?.filter((o) =>
    o.orders.product.product_name.toLowerCase().includes("rabies")
  );
  return (
    <>
      {selectedPet ? (
        <CertificateForm
          selectedPet={selectedPet}
          handlePetSelection={handlePetSelection}
          productList={productList}
          onHide={() => {
            setSelectedPet(null);
            onHide();
          }}
        />
      ) : (
        <PatientSelection
          onHide={onHide}
          handlePetSelection={handlePetSelection}
        />
      )}
    </>
  );
};

export default CertificateCreation;
