// BrowseFileInput component
import React from "react";
import { Button, Input } from "reactstrap";
import "./index.scss";

const BrowseFileInput = ({
  className = "",
  placeholder = "",
  onChange,
  message = ".jpeg, .jpg, .png, and .pdf only",
  ...rest
}) => {
  // const [file, setFile] = useState(null);

  const handleSetFile = (e) => {
    const selectedFile = e.target.files[0];
    // setFile(selectedFile);
    onChange(selectedFile); // Pass the selected file directly to the onChange callback
  };

  return (
    <div
      className={`browse-file-blc d-flex align-items-center position-relative ${className}`}
    >
      <Input
        type="file"
        onChange={handleSetFile}
        className="form-control position-absolute form-control-file"
        placeholder={placeholder}
        {...rest}
      />
      <span
        style={{
          textWrap: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {/* {file ? file.name : message} */}
        {message || ".jpeg, .jpg, .png, and .pdf only"}
      </span>
      <Button color="success" className="shadow-none p-0 ms-auto">
        Browse File
      </Button>
    </div>
  );
};

export default BrowseFileInput;
