import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Collapse, Table } from "reactstrap";
import CheckBox from "../../../../shared/CheckBox";
import VaccineScheduleFormPanel from "./VaccineScheduleFormPanel";
import { getAllVaccineSchedules, updateVaccineSchedule } from "../../../../context/actions/vaccineSchedule";
import moment from "moment"
import "./index.scss";
import { useClinicSpecies } from "hooks/useClinicSpecies";

const Accordions = () => {

	const clinicSpecies = useClinicSpecies()

	const [isNewVaccineSchedule, setNewVaccineSchedule] = useState(false);
	const {clinic_details} = useSelector(state => state.clinicReducer);
	const {vaccineSchedules} = useSelector(state => state.vaccineScheduleReducer)
	const [vaccineSchedule, setVaccineSchedule] = useState(false);
	//const {testResults} = useSelector(state => state.vitalTestingReducer);
	const dispatch = useDispatch();
	const [loadIndex, setLoadIndex] = useState(0);
	const [elemIndex, setElemIndex] = useState(0);
	const [toggle, setToggle] = useState(true);
	const toggleFunction = (index) => {
		if (loadIndex === index) {
			setLoadIndex(null);
		} else if (elemIndex === index) {
			setToggle(!toggle);
		} else {
			setToggle(true);
		}
		setElemIndex(index);
		setLoadIndex(null);
	}
	useEffect(() => {
		dispatch(getAllVaccineSchedules(clinic_details?._id, () => {}))
	}, [])


	const handleTimeCheck = (schedule_index, item_index, duration, bool) => {
		vaccineSchedules[schedule_index].items[item_index].durations[duration?._id] ={...duration, bool: bool}
		


		dispatch(updateVaccineSchedule({...vaccineSchedules?.[schedule_index],  species: vaccineSchedules?.[schedule_index]?.species?._id} , (res) => {
			if(res?.status){
				dispatch(getAllVaccineSchedules(clinic_details?._id, () => {}))
			}

		}))

	}

	const { permissionsData } = useSelector(
        (state) => state.clinicPermissionReducer
    );
    const permissions = permissionsData;
	return (
		<>
			<div className="vaccine-schedule-accordion-wrapper">
				{permissions?.edit_vaccine_schedules &&
					<div className="right-col ms-auto d-inline-flex align-items-center">
								<Button
								color="link"
								className="add-btn"
								onClick={() => {
									setVaccineSchedule(false);
									setNewVaccineSchedule(true);}}
								>
								<i className="icomoon-plus-btn"></i>
								</Button>
					</div>
				}
				{vaccineSchedules?.map((item, i) => {
					return(
					<div key={i} className="accordion-blc d-flex flex-column">
						<div className="accordion-btn-wrapper d-flex align-items-center">
							<Button color="link" className={`accordion-btn text-start shadow-none text-left d-flex align-items-center ${(loadIndex === i) || (i === elemIndex && toggle) ? 'active' : ''}`} onClick={() => toggleFunction(i)}>
								<span className="material-icons icon-btn">
									{(loadIndex === i) || (i === elemIndex && toggle) ? 'expand_less' : 'expand_more'}
								</span>
								<label style={{textTransform: 'capitalize'}}>{item?.type} {clinicSpecies(item?.species?._id) || item?.species?.species_name} Schedule</label>
								</Button>
								{permissions?.edit_vaccine_schedules &&
									<Button onClick={() => {
										setVaccineSchedule(item);
										setNewVaccineSchedule(true);
									}} color="link" className="accordion-btn edit-btn">
										<span className="icomoon-pencil"></span>
									</Button>
								}
							
						</div>
						<Collapse isOpen={(loadIndex === i) || (i === elemIndex && toggle)}>
							<div className="collapse-data-blc">
								<Table responsive >

									<thead>
										<tr>
											<th>
											&nbsp;
											</th>
											{item?.durations?.sort((a, b) => {return moment.duration(a?.value, a?.unit.toLowerCase()).get('hours') - moment.duration(b?.value, b?.unit.toLowerCase()).get('hours')})?.map((duration, j) => {
												
												return(
											
													<th key={j}>
														<span>{duration?.type  === 'recurring' ? 'Every ': ''}{duration?.value} {duration?.unit}</span>
													</th>
											)

											})}
											
											
										</tr>
									</thead>

									<tbody>
										{
											item?.items?.map((product, j) => {
												return (
													<tr key={j}>
														<td>{product?.product_name}</td>
														{item?.durations?.sort((a, b) => {return moment.duration(a?.value, a?.unit?.toLowerCase()).get('hours') - moment.duration(b?.value, b?.unit?.toLowerCase()).get('hours')})?.map((duration, k) => {
															return (<td key={k}>  
															<div className="d-flex justify-content-center align-items-center">
														
															<CheckBox
																disabled={!permissions?.edit_vaccine_schedules}
																type="checkbox"
																checked={product?.durations?.[duration?._id]?.bool}
																onChange={e => handleTimeCheck(i, j, duration, e?.currentTarget?.checked)}
																className="no-label mt-0"

															/>
															</div>

													
															  
														</td>)
														})}
													</tr>
												)
											})
										}
									</tbody>
								</Table>
							</div>
						</Collapse>
					</div>
				)})}


{isNewVaccineSchedule && (
            <>
              <div
                className={` fixed top-0 right-0 bottom-0 left-0 z-[9] bg-black/20 transition-all duration-300 ease-in `}
              ></div>
            </>
          )}

{isNewVaccineSchedule && (
            <VaccineScheduleFormPanel
			  vaccineSchedule={vaccineSchedule}
              isActive={isNewVaccineSchedule}
              toggleVaccineSchedulePanel={() => {
                setNewVaccineSchedule(false);
              }}
            />
          )}

			</div>
		</>
	);
}
export default Accordions;