import React, { useRef } from "react";

function Modal({ isOpen, onClose, showCloseButton = false, children }) {
  const dialogRef = useRef(null);

  const handleModalClose = () => {
    if (onClose) {
      onClose();
    }
  };

  // Handle the backdrop click to close the modal
  const handleBackdropClick = (event) => {
    if (event.target === dialogRef.current) {
      handleModalClose();
    }
  };

  return (
    <>
      {isOpen && (
        <div
          ref={dialogRef}
          onClick={handleBackdropClick}
          className="fixed inset-0 flex items-center z-50 justify-center bg-black bg-opacity-50"
        >
          <dialog open className="bg-white rounded-md shadow-lg">
            {showCloseButton && (
              <button
                onClick={handleModalClose}
                className="absolute top-1 right-1 text-gray-500 hover:bg-gray-200 rounded-full p-1 focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            )}
            {children}
          </dialog>
        </div>
      )}
    </>
  );
}

export default Modal;
