import { Form, Formik,  } from 'formik';
import React, { useEffect, useState } from 'react';
import TextInput from "shared/FormsInput/TextBox";
import { useDispatch, useSelector } from 'react-redux';
import {Modal} from "react-bootstrap";
import { Button, Input, FormGroup } from 'reactstrap';
import {  petSchema } from './schema';
import PetPlaceholder from 'shared/PetPlaceholder';
import { getAllSpecies } from 'context/actions/app-content';
import {
    addPetForOwner,
    updatePetForOwner,
    updatePetImageForOwner,
  } from "context/actions/patient-module/pet-owner";
import { getAllBreeds } from "context/actions/app-content";

import UploadImageBlock from "shared/UploadImageBlock";
import { petGenderValues } from "utils/constants";
import Select from "react-select"



import { getPetOwnerPets } from "context/actions/pet-owner-dashboard";

import {Spinner} from 'reactstrap';


const NewPetModal = ({ extend = null, openViewBlock, refresh }) => {
    const dispatch = useDispatch();
    const [modal, setModal] = useState(true);
    const [speciesState, setSpecies] = useState(null)

    const userDetails = useSelector((state) => state.user.user_details);
    const [btnLoading, setBtnLoading] = useState(false);
    const { species: speciesList, breeds } = useSelector(
        (state) => state.appContentReducer
      );
      
    useEffect(() => {dispatch(getAllSpecies({}, (res) => { }))}, [])

    const {newPetModal, canHideNewPetModal} = useSelector(state => state.petOwnerDashboardReducer)
    const onHide = () => {
        dispatch(
            {
                type: "SET_NEW_PET_MODAL",
                payload: false ,
            }
        );
        dispatch({
          type: "SET_CAN_HIDE_NEW_PET_MODAL",
          payload: false
        })
    }
    const uploadProfile = async (petID, values) => {
        if (values?.imageFile) {
          var imgPayloadData = new FormData();
          imgPayloadData.append("pet", petID);
          imgPayloadData.append("pet_image", values?.imageFile);
          const response = await dispatch(updatePetImageForOwner(imgPayloadData));
          if(response?.status){
             const pet_res = await dispatch(getPetOwnerPets(userDetails?.id));
             if(pet_res?.status){
                setBtnLoading(false);
                onHide();
             }
          }
        } else {
          const response = await dispatch(getPetOwnerPets(userDetails?.id));
          if(response?.status){
            setBtnLoading(false);
            onHide();
          }
        }
      };


    const initialValues = {
        pet_name:  "",
        dob: "",
        gender: "",
        species:  "",
        breed: "",
        color: "",
        pet_notes:  "",
        rabies_number: "",
        microchip_number: "",
        license_number:  "",
        cpr:  "",
        allergy_tag: [],
        added_by: userDetails?._id,
        pet_owner: userDetails?._id,
        imageUrl:  "",
        imageFile: "",
      };





    const toggle = () => {
        setModal(false);
        openViewBlock(false);
    }


    const handleSave = async (values) => {
        setBtnLoading(true);
        let payload = { ...values };
        delete payload.imageFile;
        delete payload.imageUrl;
        if (payload?.allergy_tag?.length === 0) {
          delete payload.allergy_tag;
        }
    
       
        let payloadData = new FormData();
        for (let key in payload) {
        if (payload[key]) {
            payloadData.append(key, payload[key]);
        }
        }
        handleAdd(payloadData, values);
       
      };

      const handleAdd = async (payloadData, values) => {
        const response = await dispatch(addPetForOwner(payloadData));
        if (response?.status && response?.statusCode === 201) {
          uploadProfile(response?.response_data?._id, values);
        } else {
          setBtnLoading(false);
        }
      };

    return (
        <>


{newPetModal && (


        <Modal
        //   onHide={() => {
        //     dispatch({
        //       type: "SET_NEW_PET_MODAL",
        //       payload: false ,
        //     });
        //   }}
          centered
          size="xl"
          show
        >

            <Modal.Header>
                Add New Pet
                </Modal.Header>

          <Modal.Body>

      

<Formik
          onSubmit={handleSave}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={petSchema}
        >
          {(props) => {
            const {
              values,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
            } = props;
            const {
              pet_name,
              color,
              dob,
              species,
              gender,
              breed,
              rabies_number,
              microchip_number,
              license_number,
              cpr,
              //   allergy_tag,
              //   behaviour_tag,
              pet_notes,
              imageUrl,
              //   imageFile,
            } = values;
            const {
              pet_name: err_pet_name,
              color: err_color,
              dob: err_dob,
              species: err_species,
              gender: err_gender,
              breed: err_breed,
            } = errors;

            return (
              <Form className='w-100'>

                <div className='row'>

                <FormGroup className="col-md-6">
                  <label>Pet Picture</label>
                  <div className="pic-cell pet">
                    <div className="pic d-inline-flex overflow-hidden position-relative">
                      <span className="upload-cell position-absolute w-100 h-100 d-inline-flex align-items-center justify-content-center">
                        <UploadImageBlock
                          petImage={imageUrl}
                          sendPetImage={() => {}}
                          petImageObj={(value) => {
                            setFieldValue(
                              "imageUrl",
                              URL.createObjectURL(value)
                            );
                            setFieldValue("imageFile", value);
                          }}
                        />
                      </span>
                      {imageUrl ? (
                        <img
                          className="img-fluid w-100 h-100"
                          src={imageUrl}
                          alt="Pet"
                        />
                      ) : (
                        <PetPlaceholder style={{ borderRadius: "10px" }} />
                      )}
                    </div>
                  </div>
                  {/* <div className="h-99 w-99">
                    <PetPlaceholder style={{ borderRadius: "10px" }} />
                  </div> */}
                </FormGroup>
                <div className="mb-2 col-md-6">
                  <TextInput
                    id="pet_name"
                    value={pet_name}
                    label="Pet Name"
                    error={err_pet_name}
                    touched={props.touched.pet_name}
                    onChange={handleChange}
                    placeholder="Enter Pet Name"
                    isRequired={true}
                  />
                </div>
                </div>
                <div className='row'>
                
                <FormGroup className="col-md-6">
                  <label className="required-input">Date of Birth</label>
                  <Input
                    id="dob"
                    type="date"
                    value={dob}
                    label="Date of Birth"
                    onChange={handleChange}
                    placeholder="Enter Date of Birth"
                  />
                  {err_dob && (
                    <div className="text-12-400 text-danger mt-1">
                      {err_dob}
                    </div>
                  )}
                </FormGroup>
                <FormGroup className="col-md-6">
                  <label className="required-input">Gender</label>

                  <Select
                      className="w-100 h-[48px]"
                                                placeholder={'Select Gender'}
                                                value={petGenderValues?.find( i => i?.value == gender)
                                  
                                                }
                                                options={petGenderValues}

                                                styles={{
                                                  control: (baseStyles, state) => (
                                                    {...baseStyles,
                                                      fontSize: '16px',
                                                      height: '48px',
                                                      borderRadius: '8px',
                                                      color: '#1d1d1d',
                                                      padding: '0.375rem 15px',
                                                      backgroundColor: '#fff',
                                                      border: '1px solid rgba(8, 8, 41, 0.1)',
                                                      boxShadow: '0px 4px 10px rgba(61, 57, 137, 0.05)',
                                                    }),
                                                  input: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    textTransform: 'capitalize'
                                                  })
                                                }}
                                                // isMulti={true}
                                                onChange={i => {
                                              
                    
                                                  setFieldValue("gender", i?.value);
              

                                                }}
                                    />
                  {props.errors.gender && (
                    <div className="text-12-400 text-danger mt-1">
                      {props.errors.gender}
                    </div>
                  )}
                </FormGroup>
                </div>
                <div className='row'>

                <FormGroup className="col-md-6">
                  <label className="required-input">Species</label>


                  <Select
                      className="w-100 h-[48px]"
                                                placeholder={'Select Species'}
                                                value={
                                                  speciesList?.find(i => i?._id == species) || ""}
              getOptionLabel={data => data?.species_name }
              getOptionValue={data => data?._id}
                                                options={
                                                speciesList }

                                                styles={{
                                                  control: (baseStyles, state) => (
                                                    {...baseStyles,
                                                      fontSize: '16px',
                                                      height: '48px',
                                                      borderRadius: '8px',
                                                      color: '#1d1d1d',
                                                      padding: '0.375rem 15px',
                                                      backgroundColor: '#fff',
                                                      border: '1px solid rgba(8, 8, 41, 0.1)',
                                                      boxShadow: '0px 4px 10px rgba(61, 57, 137, 0.05)',
                                                    })
                                                }}
                                                // isMulti={true}
                                                onChange={i => {
                                                  console.log(i)

                                                  dispatch(getAllBreeds(i?._id, () => {}));
                    
                                                  setFieldValue("breed", "");
                                                  setFieldValue("species", i?._id)
              

                                                }}
                                    />
                
                  {err_species && (
                    <div className="text-12-400 text-danger mt-1">
                      {err_species}
                    </div>
                  )}
                </FormGroup>
                <FormGroup className="col-md-6">
                  <label className="required-input">Breed</label>


                  <Select
                      className="w-100 h-[48px]"
                                                placeholder={'Select Breed'}
                                                value={
                                                  breeds?.find(i => i?._id == breed) || ""}
              getOptionLabel={data => data?.breed }
              getOptionValue={data => data?._id}
                                                options={
                                                breeds }

                                                styles={{
                                                  control: (baseStyles, state) => (
                                                    {...baseStyles,
                                                      fontSize: '16px',
                                                      height: '48px',
                                                      borderRadius: '8px',
                                                      color: '#1d1d1d',
                                                      padding: '0.375rem 15px',
                                                      backgroundColor: '#fff',
                                                      border: '1px solid rgba(8, 8, 41, 0.1)',
                                                      boxShadow: '0px 4px 10px rgba(61, 57, 137, 0.05)',
                                                    })
                                                }}
                                                // isMulti={true}
                                                onChange={i => {
                                              
                    
                                                  setFieldValue("breed", i?._id);
              

                                                }}
                                    />
                
                  {err_breed && (
                    <div className="text-12-400 text-danger mt-1">
                      {err_breed}
                    </div>
                  )}
                </FormGroup>
                </div>
                <div className='row'>
            
                <div className="col-md-6 mb-2">
                  <TextInput
                    id="color"
                    value={color}
                    label="Color"
                    touched={props?.touched?.color}
                    error={err_color}
                    onChange={handleChange}
                    placeholder="Enter color"
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <TextInput
                    id="license_number"
                    value={license_number}
                    label="License Number"
                    onChange={handleChange}
                    placeholder="Enter license number"
                  />
                </div>
                </div>
                <div className='row'>
                <div className="col-md-6 mb-2">
                  <TextInput
                    id="rabies_number"
                    value={rabies_number}
                    label="Rabies Tag Number"
                    onChange={handleChange}
                    placeholder="Enter rabies tag number"
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <TextInput
                    id="microchip_number"
                    value={microchip_number}
                    label="Microchip Number"
                    onChange={handleChange}
                    placeholder="Enter microchip number"
                  />
                </div>
                </div>
                <div className='row'>
              
                {/* <div className="col-md-6 mb-2">
                  <TextInput
                    id="cpr"
                    value={cpr}
                    label="CPR Status"
                    onChange={handleChange}
                    placeholder="Enter CPR status"
                  />
                </div> */}
                </div>               
               
                <div className={`d-flex w-100 align-items-center ${canHideNewPetModal ? "justify-content-between" : "justify-content-end" }`}>
                  {canHideNewPetModal ? <Button outline={true} color="danger" style={{borderRadius: '8px'}} type="button" onClick={onHide} className="btn btn-outline-light">Close</Button> :<></>}
                  <Button
                    color="success"
                    type="button"
                    className="btn btn-outline-light"
                    onClick={handleSubmit}
                    disabled={
                      btnLoading ||
                      JSON.stringify(initialValues) === JSON.stringify(values)
                    }
                  >
                    Submit
                    {btnLoading && (
                      <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                        className="ms-2"
                      />
                    )}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
        </Modal.Body>

        </Modal>)}
     
        </>
    );

}

export default NewPetModal;