import React, { useRef, useState, useEffect } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { IoIosPause, IoMdPlay } from "react-icons/io";
const AudioPlayer = ({ className, audioFile, IsShowPalyButtons }) => {
    const waveformRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const wavesurferRef = useRef(null);

    useEffect(() => {
        const wavesurfer = WaveSurfer.create({
            container: waveformRef.current,
            waveColor: '#b8dbf6',
            progressColor: '#409de0',
            url: audioFile,
            barGap: 1.5,
            barRadius: 40,
            barWidth: 2.5,
            width: 100,
            cursorWidth: 0,
            height: 30
        });

        wavesurferRef.current = wavesurfer;
        if (typeof audioFile === "string") {
            wavesurfer.load(audioFile);
        } else {
            wavesurfer.loadBlob(audioFile);
        }

        return () => {
            wavesurfer.destroy();
        };
    }, [audioFile]);

    const handlePlayPause = () => {
        if (isPlaying) {
            wavesurferRef.current && wavesurferRef.current.pause();
        } else {
            wavesurferRef.current && wavesurferRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };
    const formatTime = () => {
        const timeInSeconds = wavesurferRef.current?.getDuration()
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        let duration = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
        if (hours > 0) {
            duration = `${hours < 10 ? '0' : ''}${hours}:` + duration
        }
        return duration;
    };
    return (
        <div className='d-flex  items-center gap-2 h-[30px] my-2'>
            <button onClick={handlePlayPause} className='h-[24px] w-[24px] rounded-circle bg-[#409DE0] flex items-center justify-center'>{isPlaying ? <IoIosPause color="#ffffff" /> : <IoMdPlay color="#ffffff" />}</button>
            <div ref={waveformRef}></div>
            <p className='text-[13px] text-[#282E65]'>{formatTime()}</p>
        </div>
    );
};

export default AudioPlayer;
