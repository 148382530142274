import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Button, Input } from "reactstrap";
import { toast } from "react-toastify";
import { Formik, Form, ErrorMessage } from "formik";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../../context/actions/user";
import { signUpSchema, signUpInit } from "./schema";
import TextInput from "../../../shared/FormsInput/FormInput";
import EnterCodeModal from "../Login/EnterCodeModal";
import AddressAutoCompleteInput from "shared/AddressAutoCompleteInputStandard";

import { apiUrl, PORT } from "environment";
import Head from "../Head";
import './../SignupModule.scss';
import { Link } from "react-router-dom";
import AppLogo from "../../../shared/AppLogo";
import PhoneNumberInput from "shared/FormsInput/PhoneInput";
import ApiClient from "api-client";
toast.configure();

const SignUp = () => {

	const dispatch = useDispatch();
	const [disabled, setDisabled] = useState(false);
	const [verificationCodeMessage, setVerificationCodeMessage] = useState(false)
	const [enterCode, setEnterCode] = useState(false)
	const [userLoginData, setUserLoginData] = useState(false)
	const [userDetails, setUserDetails] = useState(false)
	const [loader, showLoader] = useState(false)





	const token = useSelector((state) => state.user.token);

	/**
	 * @description method call to submit valid form
	 * @param {Object} params form params
	 * @param {Instance} status form status
	 */
	const submitForm = async (params, status) => {
		// update payload
		//checkout();

		params.email = params.email.toLowerCase();
		params.name = params.firstname + " " + params.lastname;
		params.role = "pet-owner";

		delete params.acceptTerms
		// End
		setDisabled(true);
		dispatch(
			register(params, (result) => {

				setUserDetails(result.user_id)

				if(!result?.can_login) {
					console.log(result)
					setVerificationCodeMessage(result)
					setUserLoginData(params)
					setEnterCode(true)
					setDisabled(false);
					showLoader(false)
					return
				}


				setDisabled(false);
			})
		);
	};

	// const checkout = () => {
	// 	ApiClient.get(
	// 		`${apiUrl}${PORT}/checkout`,
	// 		{},
	// 		token,
	// 		dispatch
	// 	  ).then((response) => {

	// 		if(response?.response_data?.url) {

	// 			window.open(response?.response_data?.url, "_self");

	// 		}
	// 	  })
	// }

	return (
		<>
			<div className="col-blc left-col signup_box position-relative overflow-auto">
				<div className="head">
					<div className="logo-rw">
						<AppLogo />
					</div>
					{/* <h1>
						<span className="txt-1">Discover </span>
						<span className="txt-2">the world’s <span>Contra to popular Ipsum simple</span></span>
					</h1> */}
				</div>
			</div>
			<div className="col-blc right-col">
				<div className=" align-self-stretch">
					<div className="login-blc m-auto signup-box">
						{/* <Head heading="Welcome! Please signup to continue." className="signup-page" /> */}
						<h3 className="inner_link text-right">Already have an account? <Link to="/login">Sign In</Link></h3>
						<div className="login-blc m-auto signup-box">
							<div className="signup-head">
								<h2>Sign Up</h2>
							</div>


							<Formik
								initialValues={signUpInit}
								enableReinitialize
								validationSchema={signUpSchema}
								onSubmit={submitForm}
							>
								{(formik) => (<Form >

									{/* {formik.errors && formik.touched && <div>{JSON.stringify(formik?.errors)} </div>	} */}
									<div className="row">
										<div className="col-md-6 mb-0">
											<TextInput
												name="firstname"
												type="text"
												placeholder="First Name"
												value={formik?.values?.firstname}
												onChange={formik.handleChange}
												// label="First Name"
												isRequired={true}
											/>
										</div>
										<div className="col-md-6 mb-0">
											<TextInput
												name="lastname"
												type="text"
												placeholder="Last name"
												value={formik?.values?.lastname}
												onChange={formik.handleChange}
												// label="Last Name"
												id={"lastname"}
											/>
										</div>
										<div className="col-md-12 mb-0">
											<TextInput
												name="email"
												placeholder="Enter email address"
												value={formik?.values?.email}
												onChange={formik.handleChange}
												error={formik?.errors?.email}
												touched={formik?.touched?.email}

												//isRequired={true}
											// label="Email Address"
											/>
										</div>
										<div className="col-md-5 mb-0">
											{/* <TextInput
												name="phone"
												placeholder="Phone number"
											// value={formik?.values?.email}
											// onChange={formik.handleChange}
											// isRequired={true}
											// label="Email Address"
											/> */}

											<PhoneNumberInput
											style={{ maxWidth: "100%", color: '#1d1d1d', fontSize: '16px', fontWeight: '600', fontFamily: 'Inter'}}
											placeholder="Phone Number"											 
											name="phone"
											 id="phone"
											error={formik.errors.phone}
											touched={formik.touched.phone}

											//  className="mb-[20px]"
									
											 value={formik?.values?.phone}
											 onChange={(e) => {formik.setFieldValue('phone', '+'+e);
										 }} />

										 
										</div>
										<div className="col-md-7 mb-0">
											<AddressAutoCompleteInput
											label={'Home Address'}
											style={{color: '#1d1d1d', fontSize: '16px', fontWeight: '600', fontFamily: 'Inter'}}
											className="input-wrapper"
											error={formik?.errors?.address}
											touched={formik?.touched?.address}


											onSelect={(event) => {
												console.log(event)
											formik.setFieldValue(`address`, event?.address + ', ' + event?.postal);
											// formik.setFieldValue(`latitude`, event?.lat);
											// formik.setFieldValue(`longitude`, event?.lng);
											}}
											value={formik.values.address}
										/>
										</div>
										<div className="col-md-12 mb-0">
											<TextInput
												name="password"
												type="password"
												placeholder="Create new password"
												id="newPassword"
												value={formik?.values?.password}
												isRequired={true}
												// label="Password"
												onChange={formik.handleChange}
											/>
										</div>
										<div className="col-md-12 mb-0">
											<TextInput
												type="password"
												name="confirmPassword"
												placeholder="Confirm Password"
												id="confirmPassword"
												// label="Confirm Password"
												isRequired={true}
												value={formik?.values?.confirmPassword}
												onChange={formik.handleChange}
											/>
										</div>
										
									</div>

									<div className="term-blc">
										<label className={`d-flex ${formik.errors.acceptTerms && formik.touched.acceptTerms ? 'is-invalid' : ''}`}>
											<span className="checkbox-blc position-relative">
												<Input
													type="checkbox"
													className="position-absolute"
													name="acceptTerms"
													onChange={(e) => { formik.setFieldValue('acceptTerms', e.target.checked); }}
												/>
												<span className="icon-box d-inline-flex align-items-center justify-content-center">
													<span className="icon d-inline-flex">
														<FontAwesomeIcon icon={faCheck} />
													</span>
												</span>
											</span>
											<span>
												By clicking <strong>Create Account</strong> below, you agree to
												our terms of service and <a rel="noopener noreferrer" target="_blank" href="https://www.vetrecor.com/company/privacy-policy">privacy statement</a>.
											</span>
										</label>
										<ErrorMessage name="acceptTerms" component="div" className="invalid-feedback w-100" />
									</div>
									<div className="btn-cell">
										<Button type="submit"  disabled={disabled} className="flex-fill" color="primary" >
											Create Account
										</Button>
									</div>
								</Form>)}
							</Formik>

							{
							enterCode && <EnterCodeModal openViewBlock={(value) => setEnterCode(value)} userId={userDetails} userLoginData={userLoginData}
								sendLoader={(value) => showLoader(value)}
								verificationCodeMessage={verificationCodeMessage}
							/>
							}
						</div>

					</div>
				</div>
			</div>
		</>
	);
};

export default SignUp;
