import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../index.scss";
import ChatRoom from "../ChatRoom";
import { useCanvasDimension } from "./hooks/useCanvasDimension";
import { useActiveVideo } from "./hooks/useActiveVideo";
import { usePagination } from "./hooks/usePagination";
import { useGalleryLayout } from "./hooks/useGalleryLayout";
import { VideoHeader } from "./VideoHeader";
import { icons } from "utils/superAdminIcons/constants";
import { VideoQuality } from "@zoom/videosdk";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { getAppointmentDetails } from "context/actions/appointment";
import { getPetDetailsByPetId } from "context/actions/patient-module/patient/pet";
import WaitingRoom from "./WaitingRoom";

const Room = ({
    roomId,
    mediaStream,
    onLeaveOrJoinSession,
    isSupportGalleryView,
}) => {
    const {
        zmClient,
        video: { decode: isVideoDecodeReady },
    } = useSelector((state) => state.videoReducer);

    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();



    const { data: appointmentDetails } = useSelector(
        (state) => state.appointmentDetailPanelReducer
    );

    const [weightPet, setweightPet] = useState([]);


    const [slideData, setSlideData] = useState({
        file: "",
        fileType: "",
        duration: 0,
        index: 0,
    });
    const [isShareScreen, setIsShareScreen] = useState(false);
    const [activeUser, setActiveUser] = useState();
    const videoRef = useRef(null);
    const canvasDimension = useCanvasDimension(mediaStream, videoRef);
    const activeVideo = useActiveVideo(mediaStream, zmClient);
    const { page, pageSize, totalPage, totalSize, setPage } = usePagination(
        zmClient,
        canvasDimension
    );

    const [visibleParticipants, setVisibleParticipants] = useState([]);
    const { currentUser, participants, subscribedVideos } = useGalleryLayout(
        zmClient,
        activeVideo,
        {
            page,
            pageSize,
            totalPage,
            totalSize,
        }
    );
    useEffect(() => {
        dispatch(
            getAppointmentDetails(params?.appointment_id, (res) => {
                dispatch(
                    getPetDetailsByPetId(res?.patient_id?._id, {}, (data) => {
                        setweightPet(data?.response_data);
                    })
                );
            })
        );
    }, []);

    const [waitingRoom, setWaitingRoom] = useState(true);
    useEffect(() => {
        const IsScreenShared = participants.some((ele) => ele.sharerOn);
        setIsShareScreen(IsScreenShared);
        if (!IsScreenShared) {
            setActiveUser();
            if (activeVideo && activeVideo !== currentUser.userId) {
                const actUser = participants.find((ele) => ele.userId === activeVideo);
                actUser?.userId &&
                    stopRenderingVideo(
                        mediaStream,
                        `canvas-${actUser.userId}`,
                        actUser.userId
                    );
                setActiveUser(actUser);
                setVisibleParticipants(
                    participants
                        ?.filter((o) => o.userId !== actUser?.userId)
                        ?.slice()
                        .reverse()
                );
            } else if (
                participants.length >= 2 &&
                (!activeVideo || activeVideo === currentUser.userId)
            ) {
                const actUser = participants.find(
                    (ele) => ele.userId !== currentUser.userId
                );
                actUser?.userId &&
                    stopRenderingVideo(
                        mediaStream,
                        `canvas-${actUser.userId}`,
                        actUser.userId
                    );
                setActiveUser(actUser);
                setVisibleParticipants(
                    participants
                        ?.filter((o) => o.userId !== actUser?.userId)
                        ?.slice()
                        .reverse()
                );
            }
            if (participants.length === 1) {
                setActiveUser();
                setVisibleParticipants(participants);
            }
        } else {
            activeUser?.userId &&
                stopRenderingVideo(
                    mediaStream,
                    "active-user-canvas",
                    activeUser.userId
                );
            setActiveUser();
            setVisibleParticipants(participants?.slice().reverse());
        }
    }, [participants, activeVideo]);

    const renderingVideo = async (stream, canvasId, userId) => {
        console.log("render-video")
        const canvasEle = document.getElementById(canvasId);
        const canvasAspect = canvasEle?.width / canvasEle?.height;
        const videoAspect = 16 / 9;

        let scaledWidth;
        let scaledHeight;

        if (videoAspect > canvasAspect) {
            scaledWidth = canvasEle?.width;
            scaledHeight = canvasEle?.width / videoAspect;
        } else {
            scaledWidth = canvasEle?.height * videoAspect;
            scaledHeight = canvasEle?.height;
        }
        let quality = 3;
        if (scaledHeight >= 510) {

            // GROUP HD
            quality = VideoQuality.Video_720P;
        } else if (scaledHeight >= 270) {

            quality = VideoQuality.Video_360P;
        } else if (scaledHeight >= 180) {

            quality = VideoQuality.Video_180P;
        }
        if (canvasEle) {
            // const widthFinal = userId === activeUser?.userId ? 1920 : canvasEle?.width;
            // const heightFinal = userId === activeUser?.userId ? 1080 : scaledHeight;
            // const widthFinal =
            //     userId === currentUser?.userId ? 1920 : canvasEle?.width;
            // const heightFinal = userId === currentUser?.userId ? 1080 : scaledHeight;
            // console.log(canvasEle, userId, widthFinal, heightFinal);
            await stream
                ?.renderVideo(
                    canvasEle,
                    userId,
                    canvasEle?.width,
                    scaledHeight,
                    0,
                    0,
                    quality
                )
                .then((ele) => {
                    console.log("ele>>>>>>>>>>>>>>>>>>>>>>>>>", ele);
                })
                .catch((err) => console.log("err>>>>>>>>>>>>>>", err));
        }
    };
    const stopRenderingVideo = async (stream, canvasId, userId) => {
        const canvasEle = document.getElementById(canvasId);
        if (canvasEle) {
            await stream.stopRenderVideo(canvasEle, userId);
        }
    };

    useEffect(() => {
        let timer;
        if (activeUser && activeUser.bVideoOn) {
            timer = setTimeout(() => {
                console.log("called active user render video")
                renderingVideo(mediaStream, "active-user-canvas", activeUser.userId);
            }, 700);
        }
        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [mediaStream, activeUser]);

    const renderShareScreen = async (stream, userId) => {
        await stream
            .startShareView(
                document.querySelector("#my-screen-share-content-canvas"),
                userId
            )
            .then(() => {
                setIsShareScreen(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const enableAudio = async () => {
        await mediaStream?.startAudio();
    };
    useEffect(() => {
        if (currentUser?.audio === "") {
            enableAudio();
        }
    }, [currentUser?.audio]);

    const setNewSlide = (list, newIndex) => {
        let file = list?.[newIndex]?.file;
        let fileType = list?.[newIndex]?.fileType;
        let duration = list?.[newIndex]?.duration;
        let duration_type = list?.[newIndex]?.duration_type;
        let newDuration = 0;
        if (duration_type === "minute") {
            newDuration = duration * 60;
        } else {
            newDuration = duration;
        }
        setSlideData({
            file: file,
            fileType: fileType,
            duration: newDuration,
            index: newIndex,
        });
    };


    return (
        <>
            <VideoHeader
                mediaStream={mediaStream}
                onLeaveOrJoinSession={onLeaveOrJoinSession}
            />
            <div className="room-wrapper">
                <div className="room">
                    <div className="room-inner-block">
                        {/* --- Other user start --- */}
                        <div
                            className={`active-user-block ${activeUser ? "d-flex" : "d-none"
                                }`}
                            style={{
                                background: activeUser?.bVideoOn ? "transparent" : "#f2f2f7",
                            }}
                        >
                            <canvas
                                className={`active-user-canvas ${activeUser?.bVideoOn ? "d-block" : "d-none"
                                    }`}
                                id="active-user-canvas"
                            />
                            <span className="active-user-name">
                                {activeUser?.displayName}
                            </span>
                        </div>

                        {/* --- Other user end --- */}
                        <WaitingRoom isShareScreen={isShareScreen} weightPet={weightPet} activeUser={activeUser} participants={participants} />
                        {/* --- Share screen start --- */}
                        <div
                            className={`share-screen-block ${isShareScreen ? "d-flex" : "d-none"
                                }`}
                        >
                            <video
                                className={`${currentUser?.sharerOn ? "d-block" : "d-none"}`}
                                id="my-screen-share-content-video"
                            />
                            <canvas
                                className={`${!currentUser?.sharerOn ? "d-block" : "d-none"}`}
                                id="my-screen-share-content-canvas"
                            />
                        </div>
                        {/* --- Share screen end --- */}
                    </div>

                    {/* --- Gallery view start --- */}
                    <ul className="gallery-view-block">
                        {visibleParticipants?.map((user, index) => {
                            const { displayName, audio, muted, bVideoOn, userId, sharerOn } =
                                user;
                            const isLocalUser = userId === currentUser.userId;

                            if (bVideoOn) {
                                // if (bVideoOn && !isLocalUser) {

                                const canvasId = isLocalUser ? userId : `canvas-${userId}`;
                                setTimeout(() => {
                                    renderingVideo(mediaStream, canvasId, userId);
                                }, 700);
                            }
                            if (sharerOn && !isLocalUser) {
                                setTimeout(() => {
                                    renderShareScreen(mediaStream, userId);
                                }, 1000);
                            }
                            return (
                                <div
                                    key={userId}
                                    id={`vid-con-${userId}`}
                                    className="avatar"
                                    // className={`avatar ${isLocalUser && "self-User"}`}
                                    style={{
                                        background: bVideoOn ? "transparent" : "#f2f2f7",
                                    }}
                                >
                                    <video
                                        id={userId}
                                        className={`self-video ${isLocalUser && bVideoOn ? "d-block" : "d-none"
                                            }`}
                                    />
                                    <canvas
                                        id={`canvas-${userId}`}
                                        className={`self-video ${!isLocalUser && bVideoOn ? "d-block" : "d-none"
                                            }`}
                                    />
                                    <div className="avatar-content">
                                        <span className="avatar-name">{displayName}</span>
                                        <span
                                            className={`avatar-audio ${!muted ? "icon-bg-blue" : "icon-bg-red"
                                                }`}
                                        >
                                            <img
                                                src={
                                                    !muted ? icons.audioUnmuteIcon : icons.audioMuteIcon
                                                }
                                                alt="mute"
                                            />
                                        </span>
                                    </div>
                                </div>
                            );
                        })}
                        {/* <video id={currentUser.userId} className={`self-video ${currentUser && currentUser?.bVideoOn ? "d-block" : "d-none"}`} /> */}
                    </ul>
                    {/* --- Gallery view end --- */}
                </div>
                <ChatRoom roomId={roomId} />
            </div>
        </>
    );
};

export default Room;
