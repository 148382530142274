import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  ButtonDropdown,
  DropdownToggle,
  Table,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap";
import {
  deletePetDocumentAction,
  getAllPetDocumentsByPetId,
} from "../../../../../context/actions/patient-module/document";
import LoaderUI from "../../../../../shared/loader";
import NodataFoundBlock from "../../../../../shared/NoDataFoundBlock";
import UploadBlock from "../../../../../shared/UploadBlock";
import UserPlaceholder from "../../../../../shared/UserPlaceholder";
import { dateFromDateTime } from "../../../../../utils/common";
import "./index.scss";
import { imageBaseUrl, documentPhotosPath } from "../../../../../environment";
import Swal from "sweetalert2";
import { RiDeleteBin5Line } from "react-icons/ri";
import AddEditPetDocument from "../../../../../shared/PetDocument/AddEdit";

const Documents = ({ petId }) => {
  const [dropdownOpen, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleDropdown = () => setOpen(!dropdownOpen);

  const tempData = [0, 0, 0, 0, 0, 0];
  const dispatch = useDispatch();
  const { loading, petDocumentList, openPetDocumentAddEditPanel } = useSelector(
    (state) => state.petDocumentReducer
  );
  const [selectedDocument, setSelectedDocument] = useState(null);

  useEffect(() => {
    if (petId) {
      dispatch(getAllPetDocumentsByPetId(petId, {}, () => {}));
    }
  }, [petId]);

  const openPetDocumentAddEditPanelFunction = () => {
    dispatch({
      type: "OPEN_CLOSE_ADD_EDIT_PET_DOCUMENT_PANEL",
      payload: true,
    });
  };

  const closePetDocumentAddEditPanelFunction = () => {
    dispatch({
      type: "OPEN_CLOSE_ADD_EDIT_PET_DOCUMENT_PANEL",
      payload: false,
    });
    setSelectedDocument(null);
  };

  const deletePetDocument = (e, document_id) => {
    e.preventDefault();

    Swal.fire({
      title: "<strong>Are you sure you want to delete this document?</strong>",
      icon: "warning",
      html: `Once deleted this document cannot be recovered`,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Confirm",
      confirmButtonAriaLabel: "Confirm",
      cancelButtonText: "Cancel",
      cancelButtonAriaLabel: "Cancel",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(deletePetDocumentAction(document_id, petId, (response) => {}));
      } else if (result.isDenied) {
        Swal.fire("Your data is safe", "", "info");
      } else if (result.isDismissed) {
        Swal.fire("Your data is safe", "", "info");
      }
    });
  };

  return (
    <>
      <div className="documents-block">
        {/* <div className="right-col ms-auto">
					<div className="btn-blc">
						<Button color="success" className="add-btn" onClick={openPetDocumentAddEditPanelFunction}>Add new</Button>
					</div>
				</div> */}
        {/* <div className="row-block">
					<UploadBlock />
				</div> */}
        <div className="row-block available-docs">
          <div className="title-header d-flex align-items-center flex-fill">
            <div className="head ">
              <h2>Available Documents</h2>
            </div>
            <div className="right-col ms-auto">
              <div className="btn-blc">
                <Button
                  color="success"
                  className="add-btn btn btn-success"
                  onClick={openPetDocumentAddEditPanelFunction}
                >
                  Add new
                </Button>
              </div>
            </div>
          </div>

          {/* {loading ? (
            <LoaderUI />
          ) : */}
          {petDocumentList?.length > 0 ? (
            <div className="table-listing-blc overflow-auto">
              <Table responsive striped className="checkbox-listing">
                <thead>
                  <tr>
                    <th style={{ minWidth: "192px" }}>Document Name</th>
                    <th>Type</th>
                    <th style={{ minWidth: "106px" }}>Valid Till</th>
                    <th style={{ minWidth: "205px" }}>Added by</th>
                    <th>Notes</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {petDocumentList.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          {/* [Document Name] */}
                          <a
                            href={`${item.document_link}`}
                            // href={`${imageBaseUrl}${documentPhotosPath}/${item.document_link}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item?.document_name}
                          </a>
                        </td>
                        <td>
                          {/* [Document Type] */}
                          {item?.document_type}
                          {/* {item?.document_name.split(".")[1]} */}
                        </td>
                        <td>
                          {/* 05/17/2022 */}
                          {item?.valid_till ? dateFromDateTime(item?.valid_till) : '--'}
                        </td>
                        <td>
                          <div className="p-0 text-start pic-cell d-inline-flex align-items-center">
                            {/* <div className="pic rounded-circle d-inline-flex overflow-hidden">
															<img className="rounded-circle w-100 h-100" src={require('../../../../../assets/img/vetRecorEMR/user-thumbnail.png')} alt="User" />
															<UserPlaceholder />
														</div>
														Dr. Connie Reinhardt */}
                            <div className="pic rounded-circle d-inline-flex overflow-hidden">
                              {item?.addedBy?.user_image ? (
                                <img
                                  className="rounded-circle w-100 h-100"
                                  src={
                                    item?.addedBy?.user_image ||
                                    require("../../../../../assets/img/vetRecorEMR/user-thumbnail.png")
                                  }
                                  alt="User"
                                />
                              ) : (
                                <UserPlaceholder />
                              )}
                            </div>
                            {`${
                              item?.addedBy?.title
                                ? item?.addedBy?.title + " "
                                : ""
                            } ${item?.addedBy?.firstname} ${
                              item?.addedBy?.lastname
                            }`}
                          </div>
                        </td>
                        <td
                          className="text-clip-cell"
                          style={{ minWidth: "190px", maxWidth: "190px" }}
                        >
                          {/* This highly infectious bacterium causes severe fits of coughing, whooping, vomiting, and, in */}
                          {item?.notes}
                        </td>
                        <td className="action-cell">
                          <Button
                            color="link"
                            className="edit-btn"
                            onClick={() => {
                              setSelectedDocument(item);
                              openPetDocumentAddEditPanelFunction();
                            }}
                          >
                            <span className="icomoon-pencil"></span>
                          </Button>

                          <Button
                            onClick={(e) => deletePetDocument(e, item._id)}
                            color="link"
                            className="edit-btn"
                          >
                            {/* <span className="icomoon-pencil"></span> */}
                            <RiDeleteBin5Line />
                          </Button>
                          {/* <ButtonDropdown tether isOpen={dropdownOpen && i == activeIndex ? true : false} toggle={() => {
														toggleDropdown()
														setActiveIndex(i)
													}}>
														<DropdownToggle color="link" className="more-btn">
															<span className="icomoon-more"></span>
														</DropdownToggle>
														<DropdownMenu>
															<DropdownItem>Edit</DropdownItem>
															<DropdownItem onClick={(e) => deletePetDocument(e, item._id)}>Delete</DropdownItem>
														</DropdownMenu>
													</ButtonDropdown> */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          ) : (
            <NodataFoundBlock />
          )}
        </div>

        {openPetDocumentAddEditPanel && (
          <>
            <div
              className={`patient-panel-overlay position-fixed ${
                openPetDocumentAddEditPanel ? "active" : ""
              }`}
              onClick={() => closePetDocumentAddEditPanelFunction()}
            ></div>
            <AddEditPetDocument
              className={`${openPetDocumentAddEditPanel ? "active" : ""}`}
              closePetDocumentAddEditPanelFunction={
                closePetDocumentAddEditPanelFunction
              }
              selectedDocument={selectedDocument}
              setSelectedDocument={setSelectedDocument}
            />
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(Documents);
