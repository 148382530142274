import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import logoImg from "../../../../assets/img/logo.png";
import { AudioChangeAction, MutedSource, VideoCapturingState } from '@zoom/videosdk';

const SELF_VIDEO_ID = 'ZOOM_WEB_SDK_SELF_VIDEO';

export const VideoHeader = ({ onLeaveOrJoinSession, mediaStream }) => {
    const { data: appointmentDetails } = useSelector(
        (state) => state.appointmentDetailPanelReducer
    );
    const { zmClient } = useSelector((state) => state.videoReducer);
    const [isMuted, setIsMuted] = useState(
        !!zmClient.getCurrentUserInfo()?.muted
    );
    const [isShareScreen, setIsShareScreen] = useState(false);
    const [isStartedVideo, setIsStartedVideo] = useState(zmClient.getCurrentUserInfo()?.bVideoOn)
    const [isBlur, setIsBlur] = useState(false);
    const [isStartedAudio, setIsStartedAudio] = useState(
        zmClient.getCurrentUserInfo() && zmClient.getCurrentUserInfo().audio !== ""
    );
    const [isMicrophoneForbidden, setIsMicrophoneForbidden] = useState(false);
    const unmuteVideo = async () => {
        if (mediaStream?.isRenderSelfViewWithVideoElement()) {
            const videoElement = document.getElementById(
                zmClient.getCurrentUserInfo()?.userId
            );
            if (videoElement) {

                await mediaStream?.startVideo({
                    videoElement: videoElement,
                    fullHd: true,
                    // hd: true,
                    // height: 1080,
                    // width: 1920
                });
            }
        } else {
            const startVideoOptions = {
                hd: true,
                ptz: mediaStream?.isBrowserSupportPTZ(),
                // height: 1080,
                // width:1920
            };
            if (mediaStream?.isSupportVirtualBackground() && isBlur) {
                Object.assign(startVideoOptions, {
                    virtualBackground: { imageUrl: "blur" },
                });
            }
            await mediaStream?.startVideo(startVideoOptions);
            if (!mediaStream?.isSupportMultipleVideos()) {
                const canvasElement = document.querySelector(
                    `#my-self-view-canvas`
                );
                mediaStream?.renderVideo(
                    canvasElement,
                    zmClient.getSessionInfo().userId,
                    canvasElement.width,
                    canvasElement.height,
                    0,
                    0,
                    3
                );
            }
        }
        setIsStartedVideo(true);

    };
    const muteVideo = async () => {
        await mediaStream?.stopVideo();
        setIsStartedVideo(false);
    };
    const onVideoCaptureChange = useCallback((payload) => {
        if (payload.state === VideoCapturingState.Started) {
            setIsStartedVideo(true);
        } else {
            setIsStartedVideo(false);
        }
    }, []);
    useEffect(() => {
        zmClient.on('video-capturing-change', onVideoCaptureChange);
        return () => {
            zmClient.off('video-capturing-change', onVideoCaptureChange);
        };
    }, [
        zmClient,
        onVideoCaptureChange
    ]);
    useEffect(() => {
        if (mediaStream && zmClient.getSessionInfo().isInMeeting) {
            mediaStream.subscribeAudioStatisticData();
            mediaStream.subscribeVideoStatisticData();
            mediaStream.subscribeShareStatisticData();
        }
        return () => {
            if (zmClient.getSessionInfo().isInMeeting) {
                mediaStream?.unsubscribeAudioStatisticData();
                mediaStream?.unsubscribeVideoStatisticData();
                mediaStream?.unsubscribeShareStatisticData();
            }
        };
    }, [mediaStream, zmClient]);

    //micro phone
    const onMicrophoneClick = useCallback(async () => {
        if (isStartedAudio) {
            if (isMuted) {
                await mediaStream?.unmuteAudio();
            } else {
                await mediaStream?.muteAudio();
            }
        } else {
            try {
                await mediaStream?.startAudio();
            } catch (e) {
                if (
                    e.type === "INSUFFICIENT_PRIVILEGES" &&
                    e.reason === "USER_FORBIDDEN_MICROPHONE"
                ) {
                    setIsMicrophoneForbidden(true);
                }
                console.warn(e);
            }
            // await mediaStream?.unmuteAudio();
            setIsStartedAudio(true);
        }
    }, [mediaStream, isStartedAudio, isMuted]);

    const onHostAudioMuted = useCallback((payload) => {
        const { action, source, type } = payload;
        if (action === AudioChangeAction.Join) {
            setIsStartedAudio(true);
            //   setAudio(type);
        } else if (action === AudioChangeAction.Leave) {
            setIsStartedAudio(false);
        } else if (action === AudioChangeAction.Muted) {
            setIsMuted(true);
            if (source === MutedSource.PassiveByMuteOne) {
                // console.log("%c Line:153 🍅 Host muted you");
            }
        } else if (action === AudioChangeAction.Unmuted) {
            setIsMuted(false);
            if (source === "passive") {
                // console.log("%c Line:159 🍎 Host unmuted you");
            }
        }
    }, []);

    useEffect(() => {
        zmClient.on("current-audio-change", onHostAudioMuted);
        return () => {
            zmClient.off("current-audio-change", onHostAudioMuted);
        };
    }, [zmClient, onHostAudioMuted]);

    //share screen functionality

    const onScreenShareClick = useCallback(async () => {
        if (isShareScreen) {
            // mediaStream.stopShareView();
            mediaStream.stopShareScreen();
            setIsShareScreen(false);
        } else {
            const videoEle = document.querySelector("#my-screen-share-content-video")
            await mediaStream.startShareScreen(
                videoEle
            ).catch(err => console.log("err>>>>>", err));
            setIsShareScreen(true);
        }
    }, [mediaStream]);

    const onStopSharing = useCallback(
        async (payload) => {
            if (payload.reason === "StopScreenCapture") {
                setIsShareScreen(false);
            }
        },
        [zmClient]
    );

    const onActiveSharingChange = useCallback(
        async (payload) => {
            if (payload.state === "Active") {
                mediaStream.startShareView(
                    document.querySelector("#my-screen-share-content-canvas"),
                    payload.userId
                );
            } else if (payload.state === "Inactive") {
                mediaStream.stopShareView();
            }
        },
        [zmClient]
    );

    const onShareStatusChange = useCallback(
        async (payload) => {
            // console.log("onShareStatusChange sharing ", payload)
        },
        [zmClient]
    );

    const onPassiveShareStop = useCallback(
        async (payload) => {
            // console.log("onPassiveShareStop sharing ", payload)
        },
        [zmClient]
    );

    useEffect(() => {
        zmClient.on("passively-stop-share", onStopSharing);
        zmClient.on("active-share-change", onActiveSharingChange);
        zmClient.on("peer_share_state_change", onShareStatusChange);
        zmClient.on("passively_stop_share", onPassiveShareStop);

        return () => {
            zmClient.off("passively-stop-share", () => { });
            zmClient.off("active-share-change", () => { });
            zmClient.off("peer_share_state_change", () => { });
            zmClient.off("passively_stop_share", () => { });
        };
    }, [zmClient, onStopSharing]);


    return (
        <div className="room-header">
            <div className="room-info">
                <img alt="" src={logoImg}></img>
                <div className="text-info">
                    <span className="room-name">{appointmentDetails?.reason}</span>
                    <span className="appointment-time">
                        {moment(
                            appointmentDetails?.appointment_date,
                            "DD-MM-YYYY"
                        ).format("MMM D, YYYY")}{" "}
                        |{" "}
                        {appointmentDetails?.appointment_timings?.[0]?.slot_time?.toUpperCase()}
                    </span>
                </div>
            </div>

            <div className="actions">
                {!isMuted ? (
                    <div
                        onClick={onMicrophoneClick}
                        className={`icon active`}>
                        <img
                            alt=""
                            src={
                                require("../../../../assets/img/icons/microphone-2.svg").default

                            }
                        />
                    </div>
                ) : (
                    <div
                        onClick={onMicrophoneClick}
                        className={`icon `}>
                        <img
                            alt=""
                            src={
                                require("../../../../assets/img/icons/microphone-2-inactive.svg")
                                    .default
                            }
                        />
                    </div>
                )}
                {!isStartedVideo ? (
                    <div
                        onClick={unmuteVideo}
                        className={`icon`}>
                        <img
                            alt=""
                            src={
                                require("../../../../assets/img/icons/video-inactive.svg")
                                    .default
                            }
                        />
                    </div>
                ) : (
                    <div
                        onClick={muteVideo}
                        className={`icon active`}>
                        <img
                            alt=""
                            src={require("../../../../assets/img/icons/video.svg").default}
                        />
                    </div>
                )}

                {!isShareScreen ? (
                    <div className={`icon`}>
                        <img
                            onClick={onScreenShareClick}
                            src={
                                require("../../../../assets/img/icons/send-square-inactive.svg")
                                    .default
                            }
                            alt=""
                        />
                    </div>
                ) : (
                    <div className={`icon active`}>
                        <img
                            onClick={onScreenShareClick}
                            src={
                                require("../../../../assets/img/icons/send-square.svg").default
                            }
                            alt=""
                        />
                    </div>
                )}
            </div>

            <div className="end-call">
                <button onClick={onLeaveOrJoinSession}>
                    <span>end call</span>
                </button>
            </div>
        </div>
    )
}
