import { useEffect, useRef, useState } from "react";
import "./TimePicker.scss";
import moment from "moment";

const TimePicker = ({
  label,
  placeholder,
  minIntarval,
  startTime,
  id,
  onChange,
  resetCount,
}) => {
  const myRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [val, setVal] = useState({
    hour: "",
    min: "",
    for: "",
  });
  useEffect(() => {
    if (val.hour && val.min && val.for) {
      if (
        startTime &&
        moment(`${val.hour}:${val.min} ${val.for}`, "hh:mm A").isSameOrBefore(
          moment(`${startTime}`, "hh:mm A")
        )
      ) {
        setVal({
          hour: "",
          min: "",
          for: "",
        });
        onChange({
          id: id,
          value: "",
        });
      } else {
        onChange({
          id: id,
          value: `${val.hour}:${val.min} ${val.for}`,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val]);

  useEffect(() => {
    setVal({
      hour: "",
      min: "",
      for: "",
    });
  }, [resetCount]);

  const handleClickOutside = (e) => {
    if (myRef && myRef?.current && !myRef.current.contains(e.target)) {
      if (isOpen) {
        setIsOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  let sTime = "";
  if (val.hour && val.min && val.for) {
    sTime = `${val.hour}:${val.min} ${val.for}`;
  }
  let minuteArray = Array.from(Array(60).keys());
  if (minIntarval === "15") {
    minuteArray = [0, 15, 30, 45];
  }

  return (
    <div id="time-picker-container">
      <div
        className={`select-content-block ${
          label ? "" : "d-flex align-items-center"
        }`}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {label && (
          <div className="text-12-400 color-8693">
            {sTime ? placeholder : label}
          </div>
        )}
        <div className="text-14-400 color-2E65">{sTime || placeholder}</div>
      </div>
      {isOpen && (
        <div className="shadow br-4 time-menu-popover p-2" ref={myRef}>
          <div className="list-con overflow-auto">
            {Array.from(Array(12).keys()).map((_, index) => {
              let dHour = index + 1 < 10 ? `0${index + 1}` : `${index + 1}`;

              return (
                <span
                  key={index}
                  className={`list-block ${
                    val.hour === dHour ? "active-block" : ""
                  }`}
                  onClick={() => {
                    setVal((prev) => {
                      return { ...prev, hour: dHour };
                    });
                  }}
                >
                  {dHour}
                </span>
              );
            })}
          </div>
          <div className="list-con overflow-auto">
            {minuteArray.map((elm, index) => {
              let dMin = elm < 10 ? `0${elm}` : `${elm}`;
              return (
                <span
                  key={index}
                  className={`list-block ${
                    val.min === dMin ? "active-block" : ""
                  }`}
                  onClick={() => {
                    setVal((prev) => {
                      return { ...prev, min: dMin };
                    });
                  }}
                >
                  {dMin}
                </span>
              );
            })}
          </div>

          <div className="list-con overflow-auto">
            <span
              className={`list-block ${val.for === "AM" ? "active-block" : ""}`}
              onClick={() => {
                setVal((prev) => {
                  return { ...prev, for: "AM" };
                });
              }}
            >
              AM
            </span>
            <span
              className={`list-block ${val.for === "PM" ? "active-block" : ""}`}
              onClick={() => {
                setVal((prev) => {
                  return { ...prev, for: "PM" };
                });
              }}
            >
              PM
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimePicker;
