import React, { useState } from "react";
import { Button, Table, FormGroup, Input, InputGroup } from "reactstrap";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import "./index.scss";
import SwitchCell from "../../../shared/SwitchCell";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as CrossSvg } from "../../../assets/img/close-square-svgrepo-com.svg";
import * as Yup from "yup";
import { Icons } from "utils/contants/Icons";

const ProductBlock = ({
  className = "",
  products,
  handleProductQuantityChange,
  handleProductDiscountChange,
}) => {
  const [discounts, setDiscounts] = useState(Array(products?.length).fill(""));
  const [discountText, setDiscountText] = useState(Array(products?.length).fill("percentage"));

  const handleDiscountChange = (value, index) => {
    const newDiscounts = [...discounts];
    newDiscounts[index] = value;
    setDiscounts(newDiscounts);
    handleProductDiscountChange(newDiscounts[index], index, discountText)
  };

  const handleDiscountTypeChange = (index) => {
    const newMessageTypes = [...discountText];
    newMessageTypes[index] = newMessageTypes[index] === "percentage" ? "amount" : "percentage";
    setDiscountText(newMessageTypes);
    handleProductDiscountChange(discounts[index], index, newMessageTypes)
  };
  return (
    <>
      <div className={`package-info-blc w-100 ${className}`}>
        {products?.length > 0 && (
          <div className="table-listing-blc overflow-auto w-100">
            <Table striped responsive>
              <thead>
                <tr>
                  <th className="bg-transparent">Name</th>
                  <th className="bg-transparent" style={{ minWidth: "120px" }}>
                    Quantity
                  </th>
                  <th className="bg-transparent" style={{ minWidth: "120px" }}>
                    Unit Price
                  </th>
                  <th className="bg-transparent" style={{ minWidth: "120px" }}>
                    Discount
                  </th>
                  <th className="bg-transparent" style={{ minWidth: "120px" }}>
                    Total Price
                  </th>
                </tr>
              </thead>
              {products?.length > 0 &&
                products?.map((data, index) => {
                  return (
                    <tbody>
                      <tr>
                        <td>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <img
                              style={{ marginRight: "5px" }}
                              className=" w-[35px] h-[35px] rounded-lg"
                              src={data?.prod_image}
                              alt="prod"
                            />{" "}
                            {data.product_name}
                          </div>
                        </td>
                        <td>
                          <input
                            type="number"
                            min={0}
                            value={data.quantity}
                            onChange={(e) =>
                              handleProductQuantityChange(e, index)
                            }
                            placeholder="Enter Quantity"
                            className="w-50 border px-1 w-auto text-center bg-[#ffffff] border-1"
                          />
                        </td>
                        <td>
                          $
                          {data?.unit_price.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                          })}
                        </td>
                        <td>
                          <div className="d-flex discount-button">
                            {/* <input
                            type="number"
                            value={data.discountInPer}
                            onChange={(e) =>
                              handleProductDiscountChange(e.target.value, index)
                            }
                            placeholder="Enter Discount"
                            className="w-50 border px-1 w-auto text-center bg-[#ffffff] border-1"
                          /> */}
                            <button
                              id={index}
                              type="button"
                              onClick={() => handleDiscountTypeChange(index)}
                              className="message-type"
                            >
                              <span
                                id={index}
                              >
                                {discountText[index] === "percentage" ? "%" : "$"}
                              </span>
                              <Icons.DownArrow />
                            </button>

                            <input
                              id={index}
                              type="text"
                              placeholder={discountText[index] === "percentage" ? "Discount in %" : "Discount in $"}
                              value={discounts[index]}
                              // onChange={(e) => handleProductDiscountChange(e.target.value, index, discountText)}
                              onChange={(e) => handleDiscountChange(e.target.value, index)}
                              className="private-Message-input"
                            />
                          </div>
                        </td>
                        <td>
                          $
                          {parseFloat(+data?.amount).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
            </Table>
          </div>
        )}
      </div>
    </>
  );

  return (
    <div className=" rounded-lg">
      {products?.length > 0 ? (
        <table className="float-right w-[460px] text-sm text-left text-gray-500 rounded-lg">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              <th scope="col" className="px-3 py-3">
                Product name
              </th>
              <th scope="col" className="px-3 py-3 w-[51px]">
                Quantity
              </th>
              <th scope="col" className="px-3 py-3">
                Unit Price
              </th>
              <th scope="col" className="px-3 py-3">
                Total Price
              </th>
            </tr>
          </thead>

          {products?.length > 0 &&
            products.map((data, index) => {
              return (
                <tbody>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <td className="px-3 py-4 flex items-center gap-1">
                      <img
                        className=" w-[35px] h-[35px] rounded-lg"
                        src={data?.prod_image}
                      />{" "}
                      {data.product_name}
                    </td>
                    <td className="px-3 py-4 text-center">
                      <input
                        type="number"
                        min={1}
                        value={+data.quantity}
                        onChange={(e) => handleProductQuantityChange(e, index)}
                        placeholder="Enter quantity"
                        className="w-[20px] border px-1 w-auto text-center"
                        style={{ maxWidth: "50px" }}
                      />
                    </td>
                    <td className="px-3 py-4">{data?.unit_price}</td>
                    <td className="px-3 py-4">{parseInt(+data?.amount)}</td>
                  </tr>
                </tbody>
              );
            })}
        </table>
      ) : (
        ""
      )}
    </div>
  );
};

export default ProductBlock;
