import React, { Fragment } from "react";
import SlotItemTime from "../SlotItemTime";
import AddSlotButton from "../AddSlotButton";
import SlotUnavailable from "../SlotUnavailable";
import SlotItemName from "../SlotItemName";
import ViewMoreSlot from "../ViewMoreSlot";
import "./index.scss";
import { useEffect } from "react";
import { getDaysForCalendarMonth } from "../../../utils/calendar/constant";
import moment from "moment";
import { useState } from "react";
import LoaderUI from "../../loader";
import { date } from "yup";
import { useSelector } from "react-redux";

export function CalendarMonthViewOperatingRoom({
  calendarViewData,
  appointments,
  selectedRoom,
}) {
  const [dates, setDates] = useState([]);
  const [loader, showLoader] = useState(false);

  useEffect(() => {
    showLoader(true);
    const date = getDaysForCalendarMonth(
      1,
      calendarViewData.month_value,
      calendarViewData.year_value
    );
 
    setDates(date);
    showLoader(false);
  }, [calendarViewData]);

  return (
    <>
      {loader && <LoaderUI overlay />}
      <div className="calendar-month-view overflow-auto flex-fill">
        <table className="table">
          <thead>
            <tr>
              <th className="text-center">Sunday </th>
              <th className="text-center">Monday </th>
              <th className="text-center">Tuesday</th>
              <th className="text-center">Wednesday</th>
              <th className="text-center">Thursday</th>
              <th className="text-center">Friday</th>
              <th className="text-center">Saturday</th>
            </tr>
          </thead>
          <tbody>
            {dates.length > 0 &&
              dates.map((date, i) => {
                return (
                  <tr key={i}>
                    {date.map((day, i2) => (
                      <td
                        key={i2}
                        className={`
												${day?.current_date && "current-day-cell"}
												`}
                      >
                        <div
                          className={`day-no text-end position-absolute ${
                            !day?.is_current_month && "disable"
                          } ${
                            day?.current_date &&
                            "current d-inline-flex align-items-center justify-content-center rounded-circle"
                          }`}
                        >
                          {day?.date}
                        </div>
                        {appointments &&
                        appointments?.filter(
                          (data) => data.appointment_date === day?.full_date
                        ).length > 0 ? (
                          <div className="data-container">
                            {appointments
                              ?.sort((a, b) => {
                                return (
                                  moment(
                                    a?.appointment_timings?.sort(
                                      (a, b) =>
                                        moment(
                                          `1970-01-01 ${a.slot_time}`,
                                          `YYYY-MM-DD hh:mm a`
                                        ).valueOf() -
                                        moment(
                                          `1970-01-01 ${b.slot_time}`,
                                          "YYYY-MM-DD hh:mm a"
                                        ).valueOf()
                                    )?.[0]?.slot_time,
                                    "hh:mm a"
                                  ).valueOf() -
                                  moment(
                                    b?.appointment_timings?.sort(
                                      (a, b) =>
                                        moment(
                                          `1970-01-01 ${a.slot_time}`,
                                          `YYYY-MM-DD hh:mm a`
                                        ).valueOf() -
                                        moment(
                                          `1970-01-01 ${b.slot_time}`,
                                          "YYYY-MM-DD hh:mm a"
                                        ).valueOf()
                                    )?.[0]?.slot_time,
                                    "hh:mm a"
                                  ).valueOf()
                                );
                              })
                              .map((appt, apptIndex) => (
                                <Fragment key={apptIndex}>
                                  {appt.appointment_date === day?.full_date && (
                                    <SlotItemName appointmentData={appt} />
                                  )}
                                </Fragment>
                              ))}
                          </div>
                        ) : (
                          <div className="data-container d-flex aligadd-slot-btn-wrapper n-items-center justify-content-center position-relative">
                            <Fragment>
                              <AddSlotButton />
                            </Fragment>
                          </div>
                        )}
                      </td>

                      // 	<td className="no-hover">
                      //     <div className="day-no text-end position-absolute w-100">{date?.date}</div>
                      //     <div className="data-container">
                      //         <SlotItemTime />
                      //         <SlotItemTime className="clinic" />
                      //         <SlotItemTime className="phone" />
                      //         <SlotItemTime className="video-conference" />
                      //         <SlotItemName />
                      //         <ViewMoreSlot />
                      //     </div>
                      // </td>
                      // <td>
                      //     <div className="day-no text-end position-absolute w-100">{date?.date}</div>
                      //     <div className="data-container blank-slot add-slot-btn-wrapper d-flex align-items-center justify-content-center position-absolute">
                      //         <AddSlotButton />
                      //     </div>
                      // </td>
                      // <td className="no-hover">
                      //     <div className="day-no text-end position-absolute w-100">{date?.date}</div>
                      //     <div className="data-container blank-slot d-flex align-items-center justify-content-center position-absolute">
                      //         <SlotUnavailable />
                      //     </div>
                      // </td>
                      // <td>
                      //     <div className="day-no text-end position-absolute w-100">{date?.date}</div>
                      //     <div className="data-container blank-slot add-slot-btn-wrapper d-flex align-items-center justify-content-center position-absolute">
                      //         <AddSlotButton />
                      //     </div>
                      // </td>
                      // <td>
                      //     <div className="day-no text-end position-absolute w-100">{date?.date}</div>
                      //     <div className="data-container blank-slot add-slot-btn-wrapper d-flex align-items-center justify-content-center position-absolute">
                      //         <AddSlotButton />
                      //     </div>
                      // </td>
                      // <td>
                      //     <div className="day-no text-end position-absolute w-100">{date?.date}</div>
                      //     <div className="data-container blank-slot add-slot-btn-wrapper d-flex align-items-center justify-content-center position-absolute">
                      //         <AddSlotButton />
                      //     </div>
                      // </td>
                    ))}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
}

const CalendarMonthView = ({ calendarViewData, appointments, allRooms }) => {
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );

  const [dates, setDates] = useState([]);
  const [loader, showLoader] = useState(false);
  const [showMore, setShowMore] = useState({});
  const [appointmentsCounts, setAppointmentsCounts] = useState({});

  useEffect(() => {
    showLoader(true);
    const date = getDaysForCalendarMonth(
      1,
      calendarViewData.month_value,
      calendarViewData.year_value
    );
    
    setDates(date);
    showLoader(false);

    let initialCounts = {};
    date?.forEach((val, i) => {
      val.forEach((a, i2) => {
      initialCounts[i * 7 + i2] = 2;
      })
    });
    setShowMore({})
    setAppointmentsCounts(initialCounts);

  }, [  calendarViewData]);

  const toggleAppointments = (rowIndex) => {
    setAppointmentsCounts((prevState) => ({
      ...prevState,
      [rowIndex]: prevState[rowIndex] === 2 ? appointments.length : 2,
    }));
  };

  return (
    <>
      {loader && <LoaderUI overlay />}
      <div className="calendar-month-view overflow-auto flex-fill">
        <table className="table">
          <thead>
            <tr>
              <th className="text-center">Sunday </th>
              <th className="text-center">Monday</th>
              <th className="text-center">Tuesday</th>
              <th className="text-center">Wednesday</th>
              <th className="text-center">Thursday</th>
              <th className="text-center">Friday</th>
              <th className="text-center">Saturday</th>
            </tr>
          </thead>
          <tbody>
            {dates.length > 0 &&
              dates.map((date, i) => {
                return (
                  <tr key={i}>
                    {date.map((day, i2) => (
                      <td
                        key={i2}
                        className={`
												${day?.current_date && "current-day-cell"}
												`}
                      >
                        <div
                          className={`day-no text-end position-absolute ${
                            !day?.is_current_month && "disable"
                          } ${
                            day?.current_date &&
                            "current d-inline-flex align-items-center justify-content-center rounded-circle"
                          }`}
                        >
                          {day?.date}
                        </div>
                        {appointments &&
                        appointments?.filter(
                          (data) => data.appointment_date === day?.full_date
                        ).length > 0 ? (
                          <div className="data-container">
                            {appointments
                              ?.filter(
                                (data) =>
                                  data?.appointment_date === day?.full_date
                              )
                              .sort((a, b) => {
                                return (
                                  moment(
                                    a?.appointment_timings?.sort(
                                      (a, b) =>
                                        moment(
                                          `1970-01-01 ${a.slot_time}`,
                                          `YYYY-MM-DD hh:mm a`
                                        ).valueOf() -
                                        moment(
                                          `1970-01-01 ${b.slot_time}`,
                                          "YYYY-MM-DD hh:mm a"
                                        ).valueOf()
                                    )?.[0]?.slot_time,
                                    "hh:mm a"
                                  ).valueOf() -
                                  moment(
                                    b?.appointment_timings?.sort(
                                      (a, b) =>
                                        moment(
                                          `1970-01-01 ${a.slot_time}`,
                                          `YYYY-MM-DD hh:mm a`
                                        ).valueOf() -
                                        moment(
                                          `1970-01-01 ${b.slot_time}`,
                                          "YYYY-MM-DD hh:mm a"
                                        ).valueOf()
                                    )?.[0]?.slot_time,
                                    "hh:mm a"
                                  ).valueOf()
                                );
                              })
                              .slice(0, appointmentsCounts[(i2 * 7) + i] || 2)
                              .map((appt, apptIndex) => (
                                <Fragment key={apptIndex}>
                                  {appt.appointment_date === day?.full_date && (
                                    <SlotItemName appointmentData={appt} />
                                  )}
                                </Fragment>
                              ))}

                            {appointments &&
                              appointments?.filter(
                                (data) =>
                                  data.appointment_date === day?.full_date
                              ).length > 2 && (
                                <p
                                  className="text-center cursor-pointer"
                                  onClick={() => {
                                    toggleAppointments((i2 * 7) + i);

                                    setShowMore((prevMap) => {
                                      const updatedShowMore = !prevMap[(i2 * 7) + i];
                                      return { ...prevMap, [(i2 * 7) + i]: updatedShowMore };
                                    });
                                  }}
                                >
                                  {/* {`${
                                    showMore[i2] || showMore[i2] === undefined
                                      ? `+ ${
                                          appointments.filter(
                                            (data) =>
                                              data.appointment_date ===
                                              day?.full_date
                                          ).length - 2
                                        }  More`
                                      : "View Less"
                                  }`} */}


                                  {/* Updated here */}

                                  {showMore[(i2 * 7) + i] ? "View Less" : `+ ${appointments.filter((data) => data.appointment_date === day?.full_date).length - 2} More`}

                                </p>
                              )}
                          </div>
                        ) : (
                          <div className="data-container d-flex n-items-center justify-content-center position-relative">
                            <Fragment>
                              <AddSlotButton />
                            </Fragment>
                          </div>
                        )}
                      </td>

                      // 	<td className="no-hover">
                      //     <div className="day-no text-end position-absolute w-100">{date?.date}</div>
                      //     <div className="data-container">
                      //         <SlotItemTime />
                      //         <SlotItemTime className="clinic" />
                      //         <SlotItemTime className="phone" />
                      //         <SlotItemTime className="video-conference" />
                      //         <SlotItemName />
                      //         <ViewMoreSlot />
                      //     </div>
                      // </td>
                      // <td>
                      //     <div className="day-no text-end position-absolute w-100">{date?.date}</div>
                      //     <div className="data-container blank-slot add-slot-btn-wrapper d-flex align-items-center justify-content-center position-absolute">
                      //         <AddSlotButton />
                      //     </div>
                      // </td>
                      // <td className="no-hover">
                      //     <div className="day-no text-end position-absolute w-100">{date?.date}</div>
                      //     <div className="data-container blank-slot d-flex align-items-center justify-content-center position-absolute">
                      //         <SlotUnavailable />
                      //     </div>
                      // </td>
                      // <td>
                      //     <div className="day-no text-end position-absolute w-100">{date?.date}</div>
                      //     <div className="data-container blank-slot add-slot-btn-wrapper d-flex align-items-center justify-content-center position-absolute">
                      //         <AddSlotButton />
                      //     </div>
                      // </td>
                      // <td>
                      //     <div className="day-no text-end position-absolute w-100">{date?.date}</div>
                      //     <div className="data-container blank-slot add-slot-btn-wrapper d-flex align-items-center justify-content-center position-absolute">
                      //         <AddSlotButton />
                      //     </div>
                      // </td>
                      // <td>
                      //     <div className="day-no text-end position-absolute w-100">{date?.date}</div>
                      //     <div className="data-container blank-slot add-slot-btn-wrapper d-flex align-items-center justify-content-center position-absolute">
                      //         <AddSlotButton />
                      //     </div>
                      // </td>
                    ))}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CalendarMonthView;
