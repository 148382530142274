const initState = {
    formTemplates: [],
}

export const formTemplateReducer = (state = initState, action) => {
    switch (action.type) {
        case "GET_FORM_TEMPLATES":
            return { ...state, formTemplates: action.payload }
        default:
            return state
    }
}