const initState = {
    permissionsData: false
}

export const clinicPermissionReducer = (state = initState, action) => {
    switch (action.type) {
        case "GET_CLINIC_PERMISSION_FOR_USER":
            return { ...state, permissionsData: action.payload }
        case "LOGOUT":
            return initState;
        default:
            return state
    }
}