import { toast } from "react-toastify";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";

export const getBoardingByClinicIdWithDate = (date, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
      clinicReducer: { clinic_details },
    } = getState();

    ApiClient.get(
      `${apiUrl}${PORT}/boarding/get?clinic_id=${clinic_details?._id}&date=${date}`,
      {},
      token,
      dispatch, getState
    ).then((res) => {
      if (res) {
        return callback(res);
      } else {
        toast.error("Boardings are not fetched");
        return callback(res);
      }
    });
  };
};



export const getBoardingByClinicId = ( callback) => {
    return (dispatch, getState) => {
      const {
        user: { token, user },
        clinicReducer: { clinic_details },
      } = getState();
  
      ApiClient.get(
        `${apiUrl}${PORT}/boarding/get?clinic_id=${clinic_details?._id}`,
        {},
        token,
        dispatch, getState
      ).then((res) => {
        if (res) {
          return callback(res);
        } else {
          toast.error("Boardings are not fetched");
          return callback(res);
        }
      });
    };
  };

  export const updateBoarding = (id, body, callback) => {

    return (dispatch, getState) => {
        const {
          user: { token, user },
          clinicReducer: { clinic_details },
        } = getState();
    
        ApiClient.post(
          `${apiUrl}${PORT}/boarding/update/${id}`,
          body,
          token,
          dispatch, getState
        ).then((res) => {
          if (res) {
            return callback(res);
          } else {
            toast.error("Boardings are not fetched");
            return callback(res);
          }
        });
    };
  }



  export const addBoarding = (body, callback) => {

    return (dispatch, getState) => {
        const {
          user: { token, user },
        } = getState();
    
        ApiClient.post(
          `${apiUrl}${PORT}/boarding/add`,
          body,
          token,
          dispatch, getState
        ).then((res) => {
          if (res) {
            return callback(res);
          } else {
            toast.error("Boardings are not fetched");
            return callback(res);
          }
        });
    };
  }

  export const isPetBoarded = (id, body, callback) => {

    return (dispatch, getState) => {
        const {
          user: { token, user },
        } = getState();
    
        ApiClient.post(
          `${apiUrl}${PORT}/pet/isPetBoarded/${id}`,
          body,
          token,
          dispatch, getState
        ).then((res) => {
          if (res) {
            return callback(res);
          } else {
            toast.error("Boardings are not fetched");
            return callback(res);
          }
        });
    };



  }
  
