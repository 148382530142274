import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const WaitingRoom = ({
    isShareScreen,
    weightPet,
    activeUser,
    participants,
}) => {
    const userDetails = useSelector((state) => state.user.user_details);
    const { waitingRoomList } = useSelector((state) => state.waitingRoomReducer);

    const [waitingRoom, setWaitingRoom] = useState(true);
    const [waitingList, setWaitingList] = useState([]);
    const [count, setCount] = useState(0);
    const [slideData, setSlideData] = useState({
        file: "",
        fileType: "",
        duration: 0,
        index: 0,
    });

    let petCategory = weightPet?.species?.species_name;
    let petWeight = weightPet?.weights?.[0]?.weight;
    let findType = "";
    if (petWeight <= 8) {
        findType = "X-Small";
    } else if (petWeight > 8 && petWeight <= 22) {
        findType = "Small";
    } else if (petWeight > 22 && petWeight <= 55) {
        findType = "Medium";
    } else if (petWeight > 55 && petWeight <= 100) {
        findType = "Large";
    } else {
        findType = "Giant";
    }
    const setNewSlide = (list, newIndex) => {
        let file = list?.[newIndex]?.file;
        let fileType = list?.[newIndex]?.fileType;
        let duration = list?.[newIndex]?.duration;
        let duration_type = list?.[newIndex]?.duration_type;
        let newDuration = 0;
        if (duration_type === "minute") {
            newDuration = duration * 60;
        } else {
            newDuration = duration;
        }
        setSlideData({
            file: file,
            fileType: fileType,
            duration: newDuration,
            index: newIndex,
        });
    };

    useEffect(() => {
        let finalList = [];
        if (petWeight && petCategory) {
            waitingRoomList
                ?.filter(
                    (o) =>
                        o?.weight_range === findType && o?.pet_category_name === petCategory
                )
                ?.forEach((e) => {
                    e?.attachments?.forEach((a) => {
                        finalList.push({
                            file: a.file,
                            fileType: a.fileType,
                            duration: a.duration_num,
                            duration_type: a.duration_unit,
                        });
                    });
                });
        }

        if (finalList?.length > 0) {
            setNewSlide(finalList, 0);
        }
        // setWaitingList(finalList?.splice(0, 4))
        setWaitingList(finalList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [waitingRoomList, petWeight, petCategory]);
    useEffect(() => {
        if (count >= slideData?.duration) {
            let nextIndex = waitingList?.[slideData?.index + 1]
                ? slideData?.index + 1
                : 0;
            setNewSlide(waitingList, nextIndex);
            setCount(1);
        } else {
            setTimeout(() => {
                setCount(count + 1);
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);
    return (
        <div
            className={`waiting-room-block w-100 h-100 start-0 top-0 bg-0000 rounded-[10px] ${!(isShareScreen || activeUser || participants.length > 2)
                ? "d-block"
                : "d-none"
                }`}
        >
            {userDetails?.role === "pet-owner" ? (
                <React.Fragment>
                    {waitingRoom && (
                        <div
                            style={{ height: "calc(100vh - 115px)" }}
                            className="w-100 f-center relative"
                        >
                            { }
                            <div
                                className="absolute top-1 end-1 text-14-500 color-2E65 bg-ffff br-4 ps-2 pe-2"
                                style={{ zIndex: "999" }}
                            >
                                {slideData?.file !== undefined &&
                                    `Next slide in ${slideData?.duration - count} s`}
                            </div>
                            {slideData?.file !== undefined ? (
                                slideData?.fileType === "mp4" ? (
                                    <div className="w-100">
                                        <video autoPlay width="100%">
                                            <source src={slideData?.file} type="video/mp4" />
                                        </video>
                                    </div>
                                ) : (
                                    <div className="w-100">
                                        <img
                                            src={slideData?.file}
                                            alt="slide"
                                            className="fit-image"
                                        />
                                    </div>
                                )
                            ) : (
                                <div className="waiting-room">
                                    <h1
                                        style={{
                                            position: "absolute",
                                            top: "10%",
                                            width: "100%",
                                            textAlign: "center",
                                            zIndex: 1,
                                            fontSize: "30px",
                                        }}
                                    >
                                        Waiting for other participants
                                    </h1>
                                    <img
                                        alt="waiting"
                                        src={require("../../../../assets/img/vetRecorEMR/login-pic2.png")}
                                    ></img>
                                </div>
                            )}
                        </div>
                    )}
                </React.Fragment>
            ) : (
                <div className="waiting-room">
                    <h1
                        style={{
                            position: "absolute",
                            top: "10%",
                            width: "100%",
                            textAlign: "center",
                            zIndex: 1,
                            fontSize: "30px",
                        }}
                    >
                        Waiting for other participants
                    </h1>
                    <img
                        src={require("../../../../assets/img/vetRecorEMR/login-pic2.png")}
                        alt="waiting"
                    ></img>
                </div>
            )}
        </div>
    );
};

export default WaitingRoom;