import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Model from "shared/3dModel";
import NodataFoundBlock from "../../../../../../shared/NoDataFoundBlock";
import { BsFillImageFill, BsFillPinAngleFill } from "react-icons/bs";
import { CiCircleCheck } from "react-icons/ci";
import { IoClose } from "react-icons/io5";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoReload } from "react-icons/io5";
import Accordion from "react-bootstrap/Accordion";
import Select from "react-select";
import moment from "moment";
import {
  getIVsByPetId,
  addNewIV,
  addIVDetails,
  deleteIVDetails,
  deleteParentIV,
} from "context/actions/patient-module/flowsheets/IV";
import { getPetDetailsByIdNoLoading } from "context/actions/patient-module/patient/pet";
import archive from "assets/img/archive.png";
import DeletePopup from "../DeletePopup";
import DatePopover from "../DatePopover";
// import FilterComponent from "../FilterComponent";
import FilterOptions from "../FilterOptions";
import "./IVAssess.scss";
import Spinner from "shared/Spinner";

const ivKeys = [
  {
    key: "iv_type",
    title: "IV Type",
  },
  {
    key: "location",
    title: "IV Location",
  },
  {
    key: "insertion_date_and_time",
    title: "Insertion Date & Time",
  },
  {
    key: "site_assessment",
    title: "Site Assessment",
  },
  {
    key: "length",
    title: "Length",
  },
  {
    key: "no_of_lumens",
    title: "No. of Lumens",
  },
  {
    key: "lumen_status_1",
    title: "Lumen 1 Status",
  },
  {
    key: "lumen_status_2",
    title: "Lumen 2 Status",
  },
  {
    key: "lumen_status_3",
    title: "Lumen 3 Status",
  },
  {
    key: "lumen_status_4",
    title: "Lumen 4 Status",
  },
  {
    key: "lumen_status_5",
    title: "Lumen 5 Status",
  },
  {
    key: "lumen_status_6",
    title: "Lumen 6 Status",
  },
  {
    key: "lumen_status_7",
    title: "Lumen 7 Status",
  },
  {
    key: "line_care",
    title: "Line Care",
  },
  {
    key: "notes",
    title: "Notes",
  },
  {
    key: "ivs_document",
    title: "Photo",
  },
];

const dropdownOption = {
  iv_type: ["Peripheral", "Midline", "PICC", "Central Line"],
  location: [
    "Right Jugular",
    "Left Jugular",
    "Right Medial Saphenous",
    "Left Medial Saphenous",
    "Right Cephalic",
    "Left Cephalic",
    "Right Lateral Saphenous",
    "Left Lateral Saphenous",
    "Right Forelimb",
    "Left Forelimb",
    "Right Hindlimb",
    "Left Hindlimb",
  ],
  no_of_lumens: ["1", "2", "3", "4", "5", "6", "7"],
  line_care: ["flushed", "infusing", "capped"],
  dressing_status: [
    "Clean dry and intact",
    "dressing changed",
    "dressing reinforced",
  ],
};

const IVAssess = ({ petId, setDisplayURL }) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.user_details);
  const { petDetails } = useSelector((state) => state.petReducer);
  const [deleteData, setDeleteData] = useState(null);
  const [selectingNewPoint, setSelectingNewPoint] = useState(false);
  const [point, setPoint] = useState(false);
  const [pageData, setPageData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [expandList, setExpandList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [optionType, setOptionType] = useState("2");
  const [filterData, setFilterData] = useState({
    searchText: "",
    selectedDates: [],
    date: "",
  });
  const handleExpand = (gIndex) => {
    let list = expandList;
    if (expandList.includes(gIndex)) {
      list = list.filter((o) => o !== gIndex);
    } else {
      list = [...list, gIndex];
    }
    setExpandList(list);
  };
  const handleNumaricValue = (e) => {
    let val = e.target.value;
    if (["length", "no_of_lumens"].includes(e.target.name)) {
      val = val.replace(/[^0-9]+/g, "").replace(/^0/, "");
    }
    return val;
  };
  const handleEdit = () => {
    let fVal =
      editData?.fieldId === "insertion_date_and_time"
        ? moment(editData?.fieldValue, "YYYY-MM-DDTHH:mm").format(
            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
          )
        : editData?.fieldValue;

    handleEditDetails({
      locationId: editData?.locationId,
      locationDetailId: editData?.locationDetailId,
      [editData?.fieldId]: fVal || "",
    });
  };
  const handleEditDetails = async (payload) => {
    const formData = new FormData();
    for (const key in payload) {
      formData?.append(key, payload[key]);
    }
    const res = await dispatch(addIVDetails(formData));
    if (res?.status) {
      fetchPageData(filterData);
      if (payload?.location !== undefined) {
        dispatch(getPetDetailsByIdNoLoading(petId, {}, () => {}));
      }
      // setEditData(null);
    }
  };
  const handleIvDelete = async (id) => {
    setDeleteData(null);
    const res = await dispatch(deleteParentIV(id));
    if (res?.status) {
      setTimeout(() => {
        fetchPageData(filterData);
        dispatch(getPetDetailsByIdNoLoading(petId, {}, () => {}))
      }, 1000);
    }
  };
  const handleIvDetailsDelete = async (data) => {
    setDeleteData(null);
    const res = await dispatch(deleteIVDetails(data));
    if (res?.status) {
      setTimeout(() => {
        fetchPageData(filterData);
      }, 1000);
    }
  };
  const handleDelete = async () => {
    if (deleteData?.type === "1") {
      handleIvDelete(deleteData?.locationId);
    } else {
      handleIvDetailsDelete({
        locationId: deleteData?.locationId,
        locationDetailId: deleteData.locationDetailId,
      });
    }
  };
  const handleAddPoint = async () => {
    const res = await dispatch(
      addNewIV({
        pet_id: petId,
        created_by: userDetails?._id,
        coordinate: point,
      })
    );
    if (res?.status) {
      handleExpand(pageData?.length);
      handleAdd(res?.response_data?._id);
      setSelectingNewPoint(false);
      setPoint(null);
    }
  };
  const handleAdd = async (id) => {
    handleEditDetails({
      locationId: id,
      createdAt: moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    });
  };
  const handleResponse = (resData) => {
    const newData = [];
    resData.forEach((o) => {
      const headerData = o?.locationDetails.map((e) => {
        let isAnyVal = ivKeys.some((o) => e[o?.key]);
        return {
          id: e?.locationDetailId,
          date: e?.createdAt || "",
          isValueExist: isAnyVal,
        };
      });
      let bodyData = [];
      let noOfLumens =
        o?.locationDetails?.find((e) => e?.no_of_lumens)?.no_of_lumens || 0;
      if (o?.locationDetails?.length > 0) {
        bodyData = ivKeys?.map((e) => {
          let isShowRow = true;
          if (e.key.includes("lumen_status")) {
            isShowRow = +noOfLumens >= +e.key?.replace("lumen_status_", "");
          }
          const data = o?.locationDetails?.map((elm) => {
            return {
              ...elm,
              value: elm?.[e?.key] || "",
            };
          });
          return { ...e, data: data, isShowRow: isShowRow };
        });
      }

      let newObject = {
        id: o?._id,
        coordinate: o?.coordinate || [],
        value: o?.locationDetails?.find((o) => o.location)?.location || "",
        header: headerData,
        data: bodyData?.filter((o) => o.isShowRow),
        deleted: o?.deleted,
      };
      newData.push(newObject);
    });
    setPageData(newData);
  };
  const fetchPageData = async (fData) => {
    setIsLoading(true);
    const res = await dispatch(
      getIVsByPetId({
        pet_id: petId,
        // filterDates: fData?.selectedDates,
      })
    );
    handleResponse(res?.response_data || []);
    setIsLoading(false);
  };
  useEffect(() => {
    if (filterData?.date) {
      fetchPageData(filterData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    let currentDate = moment().format("DD-MM-YYYY");
    let newData = {
      searchText: "",
      selectedDates: [currentDate],
      date: currentDate,
    };
    setFilterData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let displayData = pageData?.filter(
    (o) =>
      optionType === "1" ||
      (optionType === "2" && !o.deleted) ||
      (optionType === "3" && o.deleted)
  );
  displayData = displayData?.map((elm) => {
    return {
      ...elm,
      data: elm?.data?.filter((o) =>
        o.title?.toLowerCase().includes(filterData?.searchText?.toLowerCase())
      ),
    };
  });
  if (displayData?.every((o) => o?.data?.length === 0)) {
    displayData = [];
  }
  console.log(displayData, 'display data')
  return (
    <div id="iv-assess-container">
      {deleteData?.type && (
        <DeletePopup
          onHide={() => {
            setDeleteData(null);
          }}
          handleDelete={handleDelete}
        />
      )}
      {/* {filterData?.date && (
        <FilterComponent
          filterData={filterData}
          setFilterData={setFilterData}
        />
      )} */}
      <div
        style={{
          position: "relative",
          backgroundColor: "#EBECF0",
          width: "100%",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          marginTop: "5px",
        }}
      >
        <div style={{ width: "413px", height: "300px" }}>
          <Model
            selectPoint={handleExpand}
            points={displayData?.map((o) => o.coordinate)}
            isNewPoint={selectingNewPoint}
            setPoint={setPoint}
            species={petDetails?.species?.species_name}
          />
        </div>

        <div
          className="scroll-control"
          style={{
            position: "absolute",
            right: "34px",
            bottom: "74px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <img
            style={{ width: "80px", height: "80px" }}
            src={require("../../../../../../assets/img/vetRecorEMR/modelScroll.png")}
            alt="3d"
          />
          <p
            style={{
              color: "#788C9F",
              maxWidth: "161px",
              textAlign: "center",
              fontFamily: "Inter",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "150%",
            }}
          >
            Drag to rotate the image on 360 degrees
          </p>
        </div>
      </div>
      <div className="iv-assess-body">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="fa-center gap-3">
            <div className="head-title">IV Access</div>
            <FilterOptions
              firstOption="All Locations"
              optionType={optionType}
              onClick={(e) => {
                setOptionType(e);
              }}
            />
          </div>

          {selectingNewPoint ? (
            <div className="new-pin">
              <i
                style={{ height: "20px", width: "20px" }}
                onClick={() => {
                  setSelectingNewPoint(false);
                }}
                className="icomoon-cross-btn"
              ></i>

              <i
                style={{ height: "20px", width: "20px" }}
                onClick={() => {
                  handleAddPoint();
                }}
                className="icomoon-check-btn"
              ></i>
            </div>
          ) : (
            <div
              className="d-flex align-items-center gap-2 pin-span"
              onClick={() => {
                setSelectingNewPoint(true);
              }}
            >
              <span>Pin New Location</span>
              <span className="pin">
                <BsFillPinAngleFill />
              </span>
            </div>
          )}
        </div>
        {isLoading ? (
          <div className="mx-auto">
            <Spinner height={150} />
          </div>
        ) : (
          <>
            {displayData?.length === 0 ? (
              <NodataFoundBlock type={'medical history'} />
            ) : (
              <Accordion activeKey={expandList} onSelect={handleExpand}>
                {displayData?.map((elm, index) => {
                  const { id, value, header, data, deleted } = elm;
                  return (
                    <Accordion.Item eventKey={index} key={index}>
                      <Accordion.Header>
                        <div className="d-flex fa-center justify-content-between w-100">
                          <div className="location-detail-block">
                            <div className="location-number">{index + 1}</div>
                            <div className="location-title">{value}</div>
                          </div>

                      {!deleted && (
                        <div className="d-flex align-items-center gap-2">

                          {data?.filter(elem => elem?.key === 'ivs_document')?.[0]?.data?.filter(i => i?.value !== "")?.length > 0 &&

                            <BsFillImageFill
                              size={20}
                              color="#a9a9a9"
                            />
                          }
                          <div
                            className="h-19 d-flex"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setDeleteData({
                                locationId: id,
                                locationDetailId: "",
                                type: "1",
                              });
                            }}
                          >
                            <img src={archive} alt="archive" />
                          </div>
                          <div
                            className="d-flex"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleAdd(id);
                            }}
                          >
                            <span className="icomoon-plus-btn" />
                          </div>
                        </div>
                      )}
                    </div>
                  </Accordion.Header>
                  {header.length > 0 && (
                    <Accordion.Body>
                      <div className="head-row">
                        <div>Test Name</div>
                        {header?.map((dateElem, dateIndex) => {
                          const { date, isValueExist } = dateElem;
                          const cellDate = moment(
                            date,
                            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                          ).format("MM/DD/YYYY");
                          const cellTime = moment(
                            date,
                            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                          ).format("hh:mmA");
                          const isDateEdit =
                            editData?.locationDetailId === dateElem?.id &&
                            editData?.fieldId === "createdAt";
                          return (
                            <div
                              key={dateIndex}
                              className="d-flex gap-2 position-relative"
                            >
                              <span
                                className="cursor-pointer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  if (!deleted) {
                                    setEditData({
                                      locationId: id,
                                      locationDetailId: dateElem?.id,
                                      fieldId: "createdAt",
                                      fieldValue: moment(
                                        date,
                                        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                      ).format("YYYY-MM-DDTHH:mm"),
                                    });
                                  }
                                }}
                              >
                                {cellDate}
                                <br />
                                {cellTime}
                              </span>
                              {isDateEdit && (
                                <DatePopover
                                  defaultValue={date}
                                  value={editData?.fieldValue}
                                  setEditData={setEditData}
                                  onChange={(e) => {
                                    setEditData((prev) => {
                                      return {
                                        ...prev,
                                        fieldValue: e.target.value,
                                      };
                                    });
                                  }}
                                  handleSave={() => {
                                    handleEditDetails({
                                      locationId: editData.locationId,
                                      locationDetailId:
                                        editData.locationDetailId,
                                      createdAt: moment(
                                        editData.fieldValue,
                                        "YYYY-MM-DDTHH:mm"
                                      ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                                    });
                                    setEditData(null);
                                  }}
                                />
                              )}
                              {!isValueExist && !deleted && (
                                <span
                                  className="remove_btn"
                                  onClick={() => {
                                    setDeleteData({
                                      locationId: id,
                                      locationDetailId: dateElem?.id,
                                      type: "2",
                                    });
                                  }}
                                >
                                  -
                                </span>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      {data?.map((rowElem, rowIndex) => {
                        return (
                          <div className="data-row" key={rowIndex}>
                            <div>{rowElem?.title}</div>
                            {rowElem?.data?.map((cellElem, cellIndex) => {
                              const { value, locationDetailId } = cellElem;
                              const isAnyFieldValue =
                                header[cellIndex]?.isValueExist;
                              const isdroupdown = [
                                "iv_type",
                                "location",
                                "line_care",
                                "dressing_status",
                                "no_of_lumens",
                              ].includes(rowElem?.key);
                              const isDate =
                                rowElem?.key === "insertion_date_and_time";
                              const isPhoto = rowElem?.key === "ivs_document";
                              const isField =
                                id === editData?.locationId &&
                                locationDetailId ===
                                  editData?.locationDetailId &&
                                rowElem?.key === editData?.fieldId;
                              let dropDownOptions =
                                dropdownOption[rowElem?.key] || [];

                                  dropDownOptions = [
                                    ...new Set(dropDownOptions),
                                  ];

                              return (
                                <div
                                  key={cellIndex}
                                  onClick={() => {
                                    if (
                                      (editData?.fieldId !== rowElem?.key ||
                                        editData?.locationDetailId !==
                                          cellElem?.locationDetailId) &&
                                      !deleted &&
                                      !isPhoto
                                    ) {
                                      setEditData({
                                        locationId: id,
                                        locationDetailId: locationDetailId,
                                        fieldId: rowElem?.key,
                                        fieldValue: value,
                                      });
                                    }
                                  }}
                                >
                                  {isField ? (
                                    isDate ? (
                                      <span className="postion-relative">
                                        <span>--Select Date--</span>
                                        <div className="date-tile-edit-popover shadow">
                                          <div>
                                            <input
                                              type="datetime-local"
                                              value={editData.fieldValue}
                                              onChange={(e) => {
                                                setEditData((prev) => {
                                                  return {
                                                    ...prev,
                                                    fieldValue: e.target.value,
                                                  };
                                                });
                                              }}
                                            />
                                          </div>
                                          <div className="d-flex align-items-center justify-content-end mt-3 gap-2">
                                            <IoIosCloseCircleOutline
                                              size={20}
                                              color="red"
                                              className="cursor-pointer"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                if (value) {
                                                  handleEditDetails({
                                                    locationId: id,
                                                    locationDetailId:
                                                      locationDetailId,
                                                    [rowElem?.key]: "",
                                                  });
                                                  setEditData(null);
                                                } else {
                                                  setEditData(null);
                                                }
                                              }}
                                            />
                                            <CiCircleCheck
                                              size={20}
                                              color="#11ca9d"
                                              className="cursor-pointer"
                                              onClick={() => {
                                                if (editData.fieldValue) {
                                                  if (
                                                    editData.fieldValue ===
                                                    moment(
                                                      value,
                                                      "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                                    ).format("YYYY-MM-DDTHH:mm")
                                                  ) {
                                                    setEditData(null);
                                                  } else {
                                                    handleEdit();
                                                    setTimeout(() => {
                                                      setEditData(null);
                                                    }, 500);
                                                  }
                                                }
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </span>
                                    ) : isdroupdown ? (
                                      <div className="dropdown-container ">
                                        <Select
                                          isClearable={
                                            rowElem?.key === "no_of_lumens" &&
                                            value
                                              ? false
                                              : true
                                          }
                                          isDisabled={
                                            value &&
                                            rowElem?.key === "no_of_lumens" &&
                                            cellIndex !== 0
                                          }
                                          id={`${rowElem?.key}_${id}`}
                                          options={dropDownOptions?.map((o) => {
                                            return {
                                              value: o,
                                              label: o,
                                            };
                                          })}
                                          value={
                                            value
                                              ? { value: value, label: value }
                                              : ""
                                          }
                                          onChange={(e) => {
                                            let sVal = e?.value || "";
                                            handleEditDetails({
                                              locationId: id,
                                              locationDetailId:
                                                locationDetailId,
                                              [rowElem?.key]: sVal,
                                            });
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <span className="iv-input-block">
                                        <input
                                          name={rowElem?.key}
                                          type="text"
                                          placeholder={`Enter ${rowElem?.title}`}
                                          value={editData?.fieldValue || ""}
                                          onChange={(e) => {
                                            setEditData((prev) => {
                                              return {
                                                ...prev,
                                                fieldValue:
                                                  handleNumaricValue(e),
                                              };
                                            });
                                          }}
                                          onBlur={() => {
                                            if (
                                              `${value}` !==
                                              `${editData?.fieldValue}`
                                            ) {
                                              handleEdit();
                                            } else {
                                              setEditData(null);
                                            }
                                          }}
                                          onKeyUp={(e) => {
                                            if (e.keyCode === 13) {
                                              if (
                                                `${value}` !==
                                                `${editData?.fieldValue}`
                                              ) {
                                                handleEdit();
                                              } else {
                                                setEditData(null);
                                              }
                                            } else if (e.keyCode === 27) {
                                              setEditData(null);
                                            } else {
                                              // Nothing
                                            }
                                          }}
                                        />
                                      </span>
                                    )
                                  ) : value || isPhoto ? (
                                    isPhoto ? (
                                      <>
                                        <span className="fa-center gap-3">
                                          {value && (
                                            <span
                                              className="cursor-pointer relative"
                                              onClick={() => {
                                                setDisplayURL(value);
                                              }}
                                            >
                                              {value && !deleted && (
                                                <span
                                                  className="bg-dc35 rounded-circle h-16 w-16 absolute end-0 top-0 f-center"
                                                  style={{
                                                    marginRight: "-5px",
                                                    marginTop: "-5px",
                                                  }}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleEditDetails({
                                                      locationId: id,
                                                      locationDetailId:
                                                        locationDetailId,
                                                      ivs_document: "",
                                                    });
                                                  }}
                                                >
                                                  <IoClose
                                                    color="#ffff"
                                                    size={12}
                                                  />
                                                </span>
                                              )}
                                              <img
                                                src={value}
                                                alt="val-img"
                                                className="h-49 w-49 br-10"
                                              />
                                            </span>
                                          )}
                                          <span
                                            className={
                                              deleted ? "" : "add_file_block"
                                            }
                                          >
                                            {value ? (
                                              <IoReload />
                                            ) : (
                                              <BsFillImageFill
                                                size={20}
                                                color="#a9a9a9"
                                              />
                                            )}
                                            {!deleted && (
                                              <input
                                                type="file"
                                                accept="image/png, image/jpg, image/jpeg"
                                                onChange={(e) => {
                                                  if (e?.target?.files?.[0]) {
                                                    handleEditDetails({
                                                      locationId: id,
                                                      locationDetailId:
                                                        locationDetailId,
                                                      ivs_document:
                                                        e?.target?.files?.[0],
                                                    });
                                                  }
                                                }}
                                              />
                                            )}
                                          </span>
                                        </span>
                                      </>
                                    ) : isDate ? (
                                      <span>
                                        {`${moment(
                                          cellElem?.value,
                                          "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                        ).format("MM/DD/YYYY")} at ${moment(
                                          cellElem?.value,
                                          "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                        ).format("hh:mm a")}`}
                                      </span>
                                    ) : (
                                      <span>{value}</span>
                                    )
                                  ) : isAnyFieldValue ? (
                                    <>
                                      <span>--</span>
                                    </>
                                  ) : (
                                    <span className="add_info">
                                      {deleted ? "--" : "--Add Info--"}
                                    </span>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </Accordion.Body>
                  )}
                </Accordion.Item>
              );
            })}
          </Accordion>
        )}
        </>)}
      </div>
    </div>
  );
};

export default IVAssess;
