import React, { useState } from 'react';
import { sidebarPanelAction } from "../../context/actions/sidebarPanelAction";
import { Button, Collapse } from "reactstrap";
import PageHeader from "../../shared/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import ScheduleFilter from "../../shared/AddAppointmentSections/ScheduleFilter";
import ScheduleList from "../../shared/AddAppointmentSections/ScheduleList";
import { format } from "date-fns";
import TextInput from "../../shared/FormsInput/TextBox";
import LoaderUI from "../../shared/loader";
import { addNewAppointment } from "../../context/actions/appointment";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { toasterConfig } from "../../utils/constants";
import "./index.scss"

const AppointmentBlock = () => {
    const dispatch = useDispatch();

    const [selectedApptDate, setSelectedApptDate] = useState(
        format(new Date(), "dd-MM-yyyy")
    );
    const [locationChange, setLocationChange] = useState('');
    const [doctorNameFilter, setDoctorNameFilter] = useState('');
    const [selectedDoctorInfo, setSelectedDoctorInfo] = useState(false);
    const [selectedApptSlot, setSelectedApptSlot] = useState(null);
    const [doctorsList, setDoctorsList] = useState([]);
    const [selectedApptSlots, setSelectedApptSlots] = useState([]);
    const [slotTiming, setSlotTiming] = useState([]);
    const [directAppointment, setDirectAppointment] = useState(false);
    const [loader, showLoader] = useState(false);
    const [description, setDescription] = useState('');
    const [overrideToggle, setOverrideToggle] = useState(false);
    const history = useHistory();

    const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);


    const [scheduleApptData, setScheduleApptData] = useState({
        doctor_id: "",
        appointment_date: "",
        appointment_time: [],
        slot_time: [],
    });
    const [appointmentSlotDuration, setAppointmentSlotDuration] = useState(30);

    const userDetails = useSelector(state => state.user.user_details)

    const onLocationChange = (e) => {
        setLocationChange(e.target.value);
    };

    const sidebarPanelState = useSelector(
        (state) => state.sidebarPanelReducer.isSidePanel
    );

    const showSidePanel = () => {
        dispatch(sidebarPanelAction(!sidebarPanelState));
    };

    const handleAddAppointment = () => {
      showLoader(true);

      if (!scheduleApptData.doctor_id) {
        showLoader(false);
        toast.error("Doctor is required", toasterConfig);
        return;
      } else if (!scheduleApptData.appointment_date) {
        showLoader(false);
        toast.error("Appoitnment date is required", toasterConfig);
        return;
      } else if (!scheduleApptData.appointment_time || scheduleApptData.appointment_time.length == 0) {
        showLoader(false);
        toast.error("Appoitnment time is required", toasterConfig);
        return;
      } else if (!scheduleApptData.slot_time || scheduleApptData.slot_time.length == 0) {
        showLoader(false);
        toast.error("slot time is required", toasterConfig);
        return;
      } else {
        const data = {
          created_by: userDetails._id,
          doctor_id: scheduleApptData.doctor_id,
          appointment_type: 'appointment-block',
          clinic_id: clinic_settings?.clinic_id,
          appointment_date: scheduleApptData.appointment_date,
          appointment_time: scheduleApptData.appointment_time,
          duration: appointmentSlotDuration,
          appointment_starts: scheduleApptData.slot_time,
          slot_time: scheduleApptData.slot_time,
          description: description,
          override_existing_bookings: overrideToggle
        };
        dispatch(
          addNewAppointment(data, (result) => {
            if (result && !result.errors) {
              showLoader(false);
              history.push("/appointments");
            } else {
              showLoader(false);
            }
          })
        );
      }
    };

  return (
    <>
    {loader && <LoaderUI overlay />}
      <div className="page-wrapper appointment-block-wrapper position-relative d-flex flex-column flex-fill overflow-auto">
        <div className="page-header-wrapper d-flex align-items-center flex-shrink-0 pb-0 right-column-visible">
          <Button
            className="sidebar-toggle-btn d-inline-flex align-items-center"
            color="link"
            onClick={showSidePanel}
          >
            <span className="material-symbols-outlined">menu</span>
          </Button>
          <div className="row-block d-flex align-items-center flex-fill">
            <PageHeader
              className="dashboard-head flex-flex-shrink-0"
              title="Schedule Appointment Block"
            ></PageHeader>
            <div className="right-col ms-auto d-flex align-items-center">
                <div className="btn-blc">
                    <Button color="success" className="add-btn p-2" onClick={handleAddAppointment}>Schedule Block</Button>
                </div>
            </div>
            </div>
        </div>
        <div className='page-content no-header d-flex flex-column flex-fill overflow-auto pb-0'>

        <div className="appointment-form-blc max-w-[700px] mb-10">
            <div className="row-block d-flex items-center flex justify-center">
            <label className="input">Description</label>             
             <div className="right-col flex-fill">
                <TextInput
                  type="text"
                  name="description"
                  id="enterDescription"
                  placeholder="Enter Description"
                  isRequired={false}
                  label="Description"
                  className="mb-0"
                  labelVisible={false}
                 
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
            </div>

            <div className="collapse-data-blc schedule-appointment-blc">
                {
                <ScheduleFilter
                checkAppointmentSlot={(value) => {
                  console.log("value",value)
                  setSelectedApptDate(value)
                }}
                onLocationChange={(value) => onLocationChange(value)}
                doctorFilter={(value) => setDoctorNameFilter(value)}
                directAppt={0}
                setDirectAppointment={setDirectAppointment}
                setOverrideToggle={setOverrideToggle}
                />
                }
                <ScheduleList
                doctorInfo={selectedDoctorInfo}
                isDoctorData={(value) => {
                  setSelectedDoctorInfo(value);
                }}
                doctorAppt={(value) => setScheduleApptData(value)}
                userDetails={userDetails}
                selectedApptDate={selectedApptDate}
                locationChange={locationChange}
                doctorNameFilter={doctorNameFilter}
                setSelectedApptSlot={setSelectedApptSlot}
                setDoctorsList={setDoctorsList}
                doctorsList={doctorsList}
                selectedApptSlots={selectedApptSlots}
                setSelectedApptSlots={setSelectedApptSlots}
                slotTiming={slotTiming}
                setSlotTiming={setSlotTiming}
				        appointmentSlotDuration={appointmentSlotDuration}
                directAppt={0}
                setDirectAppointment={setDirectAppointment}
                overrideToggle={overrideToggle}
              />
            </div>
            
        </div>
    </div>
    </>
  )
}

export default AppointmentBlock;
