import moment from "moment";
import React from "react";
import { CiCircleCheck } from "react-icons/ci";
import { IoIosCloseCircleOutline } from "react-icons/io";
import ReactSelect from "react-select";

const BloodProductsCell = ({
  data,
  cellKey,
  editData,
  setEditData,
  handleEditData,
  isAnyFieldValue,
}) => {
  const { value, _id } = data;
  const isSelectInput = [
    "blood_unit_type",
    "blood_unit_product",
    "special_considerations",
    "patient_blood_type"
  ].includes(cellKey);
  const isDate = ["expiration_date", "time_stopped"].includes(cellKey);
  let displayValue = value;
  if (isDate && value) {

    displayValue = `${moment(value, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
      "MM/DD/YYYY"
    )}${ cellKey === 'time_stopped' ? ' at ' + moment(value, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format("hh:mm a"): ''}`;
  }
  const { fieldId, fieldValue } = editData || {};
  const isEdit = editData?.id === _id && fieldId === cellKey;
  return (
    <React.Fragment>
      {isEdit ? (
        isSelectInput ? (
          <span className="dropdown-container">
            <ReactSelect
              isClearable
              placeholder="Select value"
              id={`${cellKey}_${_id}`}
              options={editData?.options?.map((o) => {
                return { value: o, label: o };
              })}
              value={value ? { value: value, label: value } : ""}
              onChange={(e) => {
                handleEditData({
                  fieldId: cellKey,
                  fieldValue: e?.value || "",
                  id: _id,
                });
                setTimeout(() => {
                  setEditData(null);
                }, 500);
              }}
            />
          </span>
        ) : isDate ? (
          <span className="position-relative">
            Select Date
            <div className="date-tile-edit-popover shadow">
              <div>
                <input
                  type={cellKey === 'time_stopped' ? "datetime-local" : 'date'}
                  value={fieldValue || ""}
                  onChange={(e) => {
                    setEditData((prev) => {
                      return { ...prev, fieldValue: e.target.value };
                    });
                  }}
                />
              </div>
              <div className="d-flex align-items-center justify-content-end mt-3 gap-2">
                <IoIosCloseCircleOutline
                  size={20}
                  color="red"
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (value) {
                      handleEditData({
                        fieldId: cellKey,
                        fieldValue: "",
                        id: _id,
                      });
                      setTimeout(() => {
                        setEditData(null);
                      }, 500);
                    } else {
                      setEditData(null);
                    }
                  }}
                />
                <CiCircleCheck
                  size={20}
                  color="#11ca9d"
                  className="cursor-pointer"
                  onClick={() => {
                    if (fieldValue) {
                      if (
                        fieldValue ===
                        moment(value, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
                          "YYYY-MM-DDTHH:mm"
                        )
                      ) {
                        setEditData(null);
                      } else {
                        handleEditData(editData);
                        setTimeout(() => {
                          setEditData(null);
                        }, 500);
                      }
                    }
                  }}
                />
              </div>
            </div>
          </span>
        ) : (
          <span className="text-input-block">
            <input
              id={`${cellKey}_${_id}`}
              placeholder="Enter value"
              type="text"
              value={fieldValue || ""}
              onChange={(e) => {
                setEditData((prev) => {
                  return { ...prev, fieldValue: e.target.value };
                });
              }}
              onBlur={() => {
                if (`${value}` !== `${editData?.fieldValue}`) {
                  handleEditData(editData);
                } else {
                  setEditData(null);
                }
              }}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  if (`${value}` !== `${fieldValue}`) {
                    handleEditData(editData);
                  } else {
                    setEditData(null);
                  }
                } else if (e.keyCode === 27) {
                  setEditData(null);
                } else {
                  // Nothing
                }
              }}
            />
          </span>
        )
      ) : displayValue ? (
        <span>{displayValue}</span>
      ) : isAnyFieldValue ? (
        <span>--</span>
      ) : (
        <span className="add_info">--Add Info--</span>
      )}
    </React.Fragment>
  );
};

export default BloodProductsCell;
