import { Button, Table } from "reactstrap";
import SearchBlock2 from "../../../../../../../../shared/SearchBlock2";
import NodataFoundBlock from "../../../../../../../../shared/NoDataFoundBlock";
import { useDispatch, useSelector } from "react-redux";
import {
  getAndSearchProductList,
  getAndSearchProductListByCategory,
} from "../../../../../../../../context/actions/inventory";
import { useEffect } from "react";

const NewProductSelect = ({ titleName, setIsNewProductSelect, setCardDataFn }) => {
  const { productList } = useSelector((state) => state.inventoryReducer);
  const dispatch = useDispatch();

  const handleGetAndSearchProductList = (e) => {
    let text = e?.target?.value;
    dispatch(
      getAndSearchProductListByCategory
        ({ searchText: text, categories: ["equipment", "supplements", 'food'] },
          (response) => {
          })
    );
  };

  useEffect(() => {
    handleGetAndSearchProductList();
  }, []);

  return (
    <>
      <div className="head d-flex flex-column align-items-center">
        <div className="d-flex flex-row w-100">
          <h2>Select Products/Services</h2>
          <Button
            color="link"
            className="ms-auto panel-close-btn"
            onClick={() => setIsNewProductSelect(false)}
          >
            <span className="material-symbols-outlined">close</span>
          </Button>
        </div>
        <div className="d-flex flex-row w-100">
          <SearchBlock2
            placeholder="Search"
            onChange={handleGetAndSearchProductList}
          />
        </div>
      </div>

      <div className="inner overflow-auto">
        <div className="table-listing-blc overflow-auto">
          <Table responsive className="no-data-table">
            <thead>
              <tr>
                <th>Component</th>
                <th>Details</th>
                <th>Category</th>
                <th style={{ width: "100px" }}>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {productList && productList?.length > 0 ? (
                productList?.filter(prod => prod?.packages?.[prod?.packages?.length - 1]
                  ?.stock_status)?.map((item, i) => {
                    return (
                      <tr key={i}>
                        {item?.product_name ? (
                          <td>{`${item?.product_name} (${item?.sku})`}</td>
                        ) : (
                          <td>{item?.service_name}</td>
                        )}
                        <td>{item?.description || '--'}</td>
                        <td style={{ textTransform: 'capitalize' }}>{item?.category}</td>
                        <td>
                          <Button
                            color="link"
                            onClick={() => {
                              setCardDataFn({
                                type: 'product',
                                id: item?._id,
                                product_name: item?.product_name,
                                instructions: '',
                                frequency: '',
                                start_date: '',
                                duration: '',
                                duration_type: '',
                                notes: '',
                                files: []
                              });
                            }}
                          >
                            Select
                          </Button>
                        </td>
                      </tr>
                    );
                  })
              ) : (
                <tr className="no-data-rw">
                  <td colSpan="5">
                    <NodataFoundBlock />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default NewProductSelect;
