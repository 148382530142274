import React, { useEffect, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { useParams } from "react-router-dom";
import ScheduleCalendar from "./ScheduleCalendar";
import Messages from "./Messages";
import MyPets from "./MyPets";
import "./index.css";
import MessageFilter from "./Messages/MessageFilter";
import { useDispatch, useSelector } from "react-redux";
import { getAllAppointmentsOfPetOwner } from "../../../../context/actions/appointment";
import CalenderAppointments from "./ScheduleCalendar/CalenderAppointments";
import Visits from "./Visits";

const PetOwnerDashboardTabs = () => {
  const params = useParams();
  const petId = params.petId;
  const [activeTab, setActiveTab] = useState("1");
  const dispatch = useDispatch();

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

//   const { petAndOwnerDetails } = useSelector((state) => state.petReducer);
//   const userDetails = useSelector((state) => state.user.user_details);

  // useEffect(() => {
  //     if (petAndOwnerDetails, userDetails) {

  //         let pet_ids = petAndOwnerDetails.pet_details.map((ele) => ele._id)
  //         dispatch(getAllAppointmentsOfPetOwner({
  //             patient_id: pet_ids,
  //             user_id: userDetails?._id
  //         }, (resp) => {
  //             console.log("resp", resp)
  //         }))

  //     }

  // }, [petAndOwnerDetails, userDetails])

  return (
    <>
      <div className="tab-wrapper tab-patient-detail d-flex flex-column flex-fill">
        <Nav tabs className="flex-shrink-0">
          <NavItem>
            <NavLink
              className={{ active: activeTab === "1" }}
              onClick={() => {
                toggle("1");
              }}
            >
              Visits
              {/* Schedule Calendar */}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={{ active: activeTab === "2" }}
              onClick={() => {
                toggle("2");
              }}
            >
              Messages
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={{ active: activeTab === "3" }}
              onClick={() => {
                toggle("3");
              }}
            >
              My Pets
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent
          activeTab={activeTab}
          className="d-flex flex-column flex-fill position-relative me-3"
        >
          {/* <LoaderUI FullWindow={false} inContainer={true} overlay={true} /> */}
          {/* <NodataFoundBlock className="h-100" /> */}
          <TabPane tabId="1">
            <Visits />
          </TabPane>
          <TabPane tabId="2">
            <div className="messages-block d-flex flex-row">
              <div className="left-col">
                <Messages />
              </div>
              <div className="right-col owner_filter_right">
                <MessageFilter />
              </div>
            </div>
          </TabPane>
          <TabPane tabId="3" className="h-100">
            <MyPets />
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default PetOwnerDashboardTabs;
