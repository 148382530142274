import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Collapse, Table } from "reactstrap";
import "./index.scss";

const Accordions = () => {

	const {testResults} = useSelector(state => state.vitalTestingReducer);
	const [loadIndex, setLoadIndex] = useState(0);
	const [elemIndex, setElemIndex] = useState(0);
	const [toggle, setToggle] = useState(true);
	const toggleFunction = (index) => {
		if (loadIndex === index) {
			setLoadIndex(null);
		} else if (elemIndex === index) {
			setToggle(!toggle);
		} else {
			setToggle(true);
		}
		setElemIndex(index);
		setLoadIndex(null);
	}

	const keys = {
		
			['Vitals']:[
			
			
			{key: 'temperature', name: 'Temperature'},
			{key: 'temperature_method', name: 'Temperature Method'},
			{key: 'heart_rate', name: "Heart Rate"},
			// {key: 'heart_rate_source', name: 'Heart Rate Source'},
			{key: 'heart_rate_method', name: 'Heart Rate Method'},
			{key: 'cardiac_rhythm', name: 'Cardiac Rhythm'},
			{key: 'respiratory_rate', name: 'Respiratory Rate'},
			{key: 'blood_pressure', name:'Blood Pressure'},
			{key: 'blood_pressure_location', name: 'Blood Pressure Location'},
			{key: 'blood_pressure_method', name: 'Blood Pressure Method'},
			{key: 'cuff_size', name: 'Cuff Size'},
			{key: 'oxygen_saturation', name: 'Oxygen Saturation'},
			{key: 'spo2_location', name: 'SpO2 Location'},
			{key: 'oxygen_flow_rate', name: "Oxygen Flow Rate"},
			{key: 'oxygen_saturation', name: "Oxygen Saturation"},
			{key: 'oxygen_delivery', name: 'Oxygen Delivery'},
			],
			['Pain Assessment']: [
			// Pain Assessment Categories
		
			{key: 'pain_score', name: 'Pain Score'},
			{key: 'pain_goal', name: 'Pain Goal'},
			{key: 'pain_location', name: 'Pain Location'},
			{key: 'pain_reaction', name: 'Pain Reaction'},
			{key: 'pain_type', name: 'Pain Type'},
			{key: 'response_to_palpation', name: 'Response to Palpation'},
			{key: 'body_tension', name: 'Body Tension'},
			],
			// Measurements Categories
			['Measurements'] : [
			{key: 'weight', name: 'Weight'}
			]
	}


	



	return (
		<>
			<div className="diagnostic-accordion-wrapper">
				{testResults?.map((item, i) => {
					return(
					<div key={i} className="accordion-blc d-flex flex-column">
						<div className="accordion-btn-wrapper d-flex align-items-center">
							<Button color="link" className={`accordion-btn text-start shadow-none text-left d-flex align-items-center ${(loadIndex === i) || (i === elemIndex && toggle) ? 'active' : ''}`} onClick={() => toggleFunction(i)}>
								<span className="material-icons icon-btn">
									{(loadIndex === i) || (i === elemIndex && toggle) ? 'expand_less' : 'expand_more'}
								</span>
								<label>{item?.type}</label>
							</Button>
							
						</div>
						<Collapse isOpen={(loadIndex === i) || (i === elemIndex && toggle)}>
							<div className="collapse-data-blc">
								<Table responsive striped>

									<thead>
										<tr>
											<th>
												Test Name
											</th>
											{item?.results?.map((res, j) => {
												let date = new Date(res?.date)
												return(
											
													<th key={j}>
														<span>{date.toLocaleDateString('en-US')}<br/>{date.toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" })}</span>
													</th>
											)

											})}
											
											
										</tr>
									</thead>

									<tbody>
										{
											keys?.[item?.type]?.map((label, j) => {
												return (
													<tr key={j}>
														<td>{label?.name}</td>
														{item?.results?.map((result, k) => {
															return (<td key={k}>{result?.[label?.key] ? result?.[label?.key] : '--'}</td>)
														})}
													</tr>
												)
											})
										}
									</tbody>
								</Table>
							</div>
						</Collapse>
					</div>
				)})}

			</div>
		</>
	);
}
export default Accordions;