import React, { useState } from "react";
import { Button } from "reactstrap";
import UserPlaceholder from "../../../../shared/UserPlaceholder";
import {
  setAppointmentDetailPanel,
  setAppointmentDetailData,
} from "../../../../context/actions/appointmentDetailPanelAction";
import { getPetDetailsById } from "../../../../context/actions/patient-module/patient/pet";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const SlotItemName = ({ className = "", boardingData, onClick }) => {
  const dispatch = useDispatch();

  const appointmentDetailsState = useSelector(
    (state) => state.appointmentDetailPanelReducer.isAppointmentDetailPanel
  );

  
  return (
    <>
      <Button
        onClick={onClick}
        color="link"
        className={`slot-item d-flex flex-column ${className} ${
          boardingData?.boarding_purpose === "in-clinic"
            ? "clinic"
            : boardingData?.boarding_purpose === "phone-call"
            ? "phone"
            : boardingData?.boarding_purpose === "video-conference"
            ? "video-conference"
            : ""
        }`}
      >
        <div className="time-txt" style={{textTransform: 'lowercase'}}>
          {moment(boardingData?.boarding_start_date).format('MM/DD/YYYY hh:mm a')} -  {moment(boardingData?.boarding_end_date).format('MM/DD/YYYY hh:mm a')}
        </div>

        <div className="picCell d-inline-flex align-items-center flex-shrink-0">
          <div className="cell overflow-hidden">
            <img
              className="img-fluid w-100 h-100"
              src={boardingData?.pet_image}
              alt="User"
            />
            : <UserPlaceholder />
          </div>
          {boardingData?.pet_name}
        </div>
      </Button>
    </>
  );
};

export default SlotItemName;
