import React from "react";
import { Button } from "reactstrap";
import PasswordBox from "../../../shared/FormsInput/PasswordBox";
import Head from "../Head";
// import './index.scss';
import './index.scss';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import AppLogo from "../../../shared/AppLogo";

const ChangePasswordBlock = (
	{
		className = ''
	}
) => {
	return (
		<>
			<div className="col-blc left-col position-relative overflow-auto">
				<div className="head">
					<div className="logo-rw">
						<AppLogo />
					</div>
					<h1>
						<span className="txt-1">Discover </span>
						<span className="txt-2">the world’s <span>Contra to popular Ipsum simple</span></span>
					</h1>
				</div>
				<div className="bg position-absolute">
					<img className="img-fluid" src={require('../../../../src/assets/img/vetRecorEMR/login-pic-txt.png')} alt="" />
				</div>
			</div>
			<div className="col-blc right-col">
				<div className="align-self-stretch">
					<div className={`login-blc m-auto signup-box ${className}`}>
						<Head heading="Change Password" /> 
						<h3 className="inner_link text-right">
							<Link to="/">
								<svg className="ml-auto" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M0.742188 11.6562C0.414062 11.9844 0.398438 12.5703 0.75 12.9141C1.09375 13.2578 1.67969 13.25 2.00781 12.9219L6.78906 8.13281L11.5781 12.9219C11.9141 13.2578 12.4922 13.2578 12.8359 12.9141C13.1719 12.5625 13.1797 11.9922 12.8359 11.6562L8.05469 6.86719L12.8359 2.08594C13.1797 1.75 13.1797 1.17188 12.8359 0.828125C12.4844 0.492188 11.9141 0.484375 11.5781 0.820312L6.78906 5.60938L2.00781 0.820312C1.67969 0.492188 1.08594 0.476562 0.75 0.828125C0.40625 1.17188 0.414062 1.75781 0.742188 2.08594L5.53125 6.86719L0.742188 11.6562Z" fill="#404D61" />
								</svg>
							</Link>
						</h3>
						<div className="signup-head">
							<h2>Create New Password</h2>
							<h3>Enter the Reset Password Code you got in your registered email address.</h3>
						</div>
						<div className="inner create_new_password">
							<PasswordBox
								id=""
								placeholder="Enter Code"
								className="col-sm-12"
								icon
								iconButton
							/>
							<PasswordBox
								id="newPassword"
								// label="New Password"
								placeholder="Create New Password"
								className="col-sm-12"
								icon
								iconButton
							/>
							<PasswordBox
								type="text"
								id="confirmNewPassword"
								// label="Confirm New Password"
								placeholder="Confirm New Password"
								className="col-sm-12"
								icon
								iconButton
							/>
							<div className="password-warning d-flex align-items-center">
								<span className="icon">
									<img className="img-fluid" src={require('../../../assets/img/vetRecor/warning.svg')} alt="Warning" />
								</span>
								{/* <span className="txt">
							Your password must contain a minimum of 8 characters included with at least 1 upper case letter, 1 number, and 1 special character ( you can not use the same last three passwords.) </span> */}
								<span className="txt">
									Your password must contain a minimum of 8 characters included with at least 1 upper case letter, 1 number, and 1 special character. </span>
							</div>
							<div className="btn-cell">
								<Button color="primary" className="flex-fill">
									Save
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ChangePasswordBlock;
