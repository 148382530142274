import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import UserPlaceholder from '../../../../../../shared/UserPlaceholder';
import { dateFromDateTime } from "../../../../../../utils/common";
import MedicalBreifBox from "../MedicalBreifBox";
import "./index.scss";
import { getConditionByPetId } from "../../../../../../context/actions/patient-module/overview/current-condition";
import Spinner from "../../../../../../shared/Spinner";
import NodataFoundBlock from "../../../../../../shared/NoDataFoundBlock";

const ConditionBlock = ({ petId, add }) => {
	const { conditionList } = useSelector(state => state.currentConditionReducer);
	const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);
	
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true)
	useEffect(() => {
		if (petId) {
			dispatch(getConditionByPetId(petId, {}, () => {
				setLoading(false)
			}))
		}
	}, [petId])
	return (
		<>
			<div className="condition-block">
				<div className="head section-head d-flex align-items-center">
					<div className="left-col">
						<h3>Medical Conditions</h3>
					</div>
					<div className="right-col ms-auto d-inline-flex align-items-center">
						<Button color="link" className="view-all-btn">
							View All
						</Button>
						{/* <Button onClick={add} color="link" className="add-btn">
							<i className="icomoon-plus-btn"></i>
						</Button> */}
					</div>
				</div>
				{loading ? <Spinner height={200} width="auto" /> : 
					<>
					
					{conditionList?.length <= 0 ? <NodataFoundBlock/> : 
					<>

					{[...(conditionList || [])].filter(condition => condition?.status === 'In Diagnosis').map((condition) => (
					<>
					<div className="inner my-2">
						<div className="conditon-box">
							<div className="head d-flex align-items-center mb-0">
								<div className="left-col brief_col">
									<p style={{ textTransform: "capitalize" }}>
											{condition?.description?.toString()}
									</p>
								</div>
								{/* <div className="right-col ms-auto">
									<Button color="link" className="position-relative">
										Add Notes
									</Button>
									<Button color="link" className="position-relative">
										Share Details
									</Button>
								</div> */}
							</div>
							<div className="inner">
								<div className="breif-head-info d-flex align-items-center flex-wrap mb-2">
									<div className="item-cell d-inline-flex align-items-center">
										<div className="pic d-inline-flex rounded-circle overflow-hidden">
											{condition?.diagnosedBy?.user_image ? <img className="img-fluid w-100 h-100" src={condition?.diagnosedBy?.user_image || require('../../../../../../assets/img/vetRecorEMR/user-thumbnail.png')} alt="User" />
															: <UserPlaceholder />}
											{/* : <UserPlaceholder /> */}
										</div>
										<div className="txt">
											{`${condition?.diagnosedBy?.title ? condition?.diagnosedBy?.title + ' ' : ''}
											 ${
												clinic_settings?.doctor_name_format === 0 ?  `${condition?.diagnosedBy?.lastname} ${","} ${condition?.diagnosedBy?.firstname}` :  `${condition?.diagnosedBy?.firstname} ${condition?.diagnosedBy?.lastname}`
											 }
											`}
										</div>
										<div className="icon d-inline-flex">
											<span className="material-symbols-outlined">
												auto_mode
											</span>
										</div>
									</div>
									<div className="item-cell d-inline-flex align-items-center">
										<div className="txt">Start Date: {dateFromDateTime(condition?.start_date, "MM/DD/yyyy")}
										</div>
										<div className="status inprogress" style={{ textTransform: "capitalize" }}>
											{condition?.status} 
										</div>
									</div>

									{ condition?.end_date &&

									<div className="item-cell d-inline-flex align-items-center">
										<div className="txt">End Date: {dateFromDateTime(condition?.end_date, "MM/DD/yyyy") || '--'}
										</div>

									</div>}
						
								</div>
								<div className="breif-desc">
									<p>
										{condition?.notes}
									</p>
								</div>
								<div className="thumbnail-list d-flex flex-wrap">
									{(condition?.images || []).map((img, index) => (
													<span className="d-inline-flex overflow-hidden">
														<img className="img-fluid w-100 h-100" src={img || require('../../../../../../assets/img/vetRecorEMR/pet-thumbnail2.png')} alt="Pet" />
													</span>
									))}
									{/*
									<span className="d-inline-flex overflow-hidden">
										<img className="img-fluid w-100 h-100" src={require('../../../../../../assets/img/vetRecorEMR/pet-thumbnail2.png')} alt="Pet" />
									</span>
									<span className="d-inline-flex overflow-hidden">
										<img className="img-fluid w-100 h-100" src={require('../../../../../../assets/img/vetRecorEMR/pet-thumbnail2.png')} alt="Pet" />
									</span>
									<span className="d-inline-flex overflow-hidden">
										<img className="img-fluid w-100 h-100" src={require('../../../../../../assets/img/vetRecorEMR/pet-thumbnail2.png')} alt="Pet" />
									</span>
									<span className="d-inline-flex overflow-hidden">
										<img className="img-fluid w-100 h-100" src={require('../../../../../../assets/img/vetRecorEMR/pet-thumbnail2.png')} alt="Pet" />
									</span>
									<span className="d-inline-flex overflow-hidden">
										<img className="img-fluid w-100 h-100" src={require('../../../../../../assets/img/vetRecorEMR/pet-thumbnail2.png')} alt="Pet" />
									</span>*/}
								</div>
							</div>
						</div>
					</div>
					</>
					)
					)}

					<div className="row-block">
						<div  className="breif-medical-rw d-flex overflow-auto">
							{[...(conditionList || [])]?.filter(condition => condition?.status === 'Completed')?.map((item, i) => {
								return (
									<div key={i} className="item-col mb-1">
										{/* <MedicalBreifBox data={{
															title: item?.description?.toString(),
															startDate: dateFromDateTime(item?.start_date, "MM/DD/yyyy"),
															endDate: dateFromDateTime(item?.end_date, "MM/DD/yyyy"),
															nameWithTitle: `${item?.diagnosedBy?.title ? item?.diagnosedBy?.title + ' ' : ''} ${item?.diagnosedBy?.firstname} ${item?.diagnosedBy?.lastname}`,
															image: item?.diagnosedBy?.user_image,
															description: item?.notes
														}} /> */}

<MedicalBreifBox data={{
															title: item?.description?.toString(),
															startDate: dateFromDateTime(item?.start_date, "MM/DD/yyyy"),
															endDate: dateFromDateTime(item?.end_date, "MM/DD/yyyy"),
															nameWithTitle: `${item?.diagnosedBy?.title ? item?.diagnosedBy?.title + ' ' : ''} ${	clinic_settings?.doctor_name_format  === 0  ? 
																<span> {item?.diagnosedBy?.lastname} {" ,"} hello {item?.diagnosedBy?.firstname}</span> 
															   : <span>{item?.diagnosedBy?.firstname} {item?.diagnosedBy?.lastname} </span> 
															  }}`,
															image: item?.diagnosedBy?.user_image,
															description: item?.notes
														}} />
									</div>
								)
								}
							)}
						</div>
					</div>
					</>
				}
				</>
				}
					
			</div>

		</>
	);
};

export default React.memo(ConditionBlock);
