import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Input } from "reactstrap";
import './index.scss';

const FilterBlock = () => {


	return (
		<>
			<div className="diagnostic-filter-block d-flex align-items-center">
				<div className="left-col d-flex align-items-center">
					<div className="item-col">
						<div className="search-cell position-relative">
							<Input type="text" placeholder='Search' />
							<span className='position-absolute d-inline-flex align-items-center justify-content-center'>
								<span class="material-symbols-outlined">
									search
								</span>
							</span>
						</div>
					</div>
					<div className="item-col">
						<Input type="select" name="select" id="exampleSelect">
							<option>Default Filter name</option>
							<option>2</option>
						</Input>
					</div>
					<div className="item-col">
						<Input type="select" name="select" id="exampleSelect2">
							<option>Default Filter name</option>
							<option>2</option>
						</Input>
					</div>

				</div>
			</div>
		</>
	);
};

export default FilterBlock;
