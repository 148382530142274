import React from "react";
import { Button, Input } from "reactstrap";
import "./index.scss";

const TimeCell2 = (
	{
		className = '',
		onClick = {},
		disabled = false,
		slot,
	}
) => {
	return (
		<>
			<Button disabled={disabled} style={{textTransform: 'lowercase'}} color="link" className={`time-cell-2 d-inline-flex align-items-center justify-content-center ${className}`} onClick={onClick}>
				{slot}
			</Button>
		</>
	);
}

export default TimeCell2;