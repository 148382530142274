import React, { useEffect, useState } from "react";
import { Button, FormFeedback, FormGroup, Input } from "reactstrap";
import { Formik, Form } from "formik";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getAllDoctors } from "../../../../../../context/actions/appointment";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";

const formObjToSchema = (obj) => {
  var schema = {
    type: "object",
    properties: {},
    required: [],
  };

  var config = {
    errMessages: {},
  };

  Object.keys(obj).map((key, i) => {
    schema.properties[obj[key].val_name] = {
      type:
        obj[key].type === "input" || obj[key].type === "select"
          ? "string"
          : "date",
      //required: (obj[key]?.required || false),
    };
    if (obj[key].required) {
      schema.required = [...schema.required, obj[key].val_name];
      config.errMessages[obj[key].val_name] = {
        required: `${key} is required`,
      };
    }
  });

  const { buildYup } = require("schema-to-yup");
  const yupSchema = buildYup(schema, config);
  return yupSchema;
};

const initValsFromFormObj = (obj) => {
  var init = {};

  Object.keys(obj).map((key, i) => {
    init[obj[key].val_name] = "";
  });
  return init;
};

const NewPointPanel = ({
  className = "",
  closeNewPointPanel,
  type = "Conditions",
  refresh,
  addPoint, 
}) => {
  const [doctorsList, setDoctorsList] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(0);
  const userDetails = useSelector((state) => state.user.user_details);
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const dispatch = useDispatch();


	const formInfo = {
		['Conditions']: {  
			['Start Date & Time'] : {value: '', type: 'date', val_name: 'start_date', required: true},
			['End Date & Time'] : {value: '', type: 'date', val_name: 'end_date'},
				
			Name :{value:'', type:'input' , val_name:'description', required: true},
			Status:{value:'', type:'select' , val_name:'status', options: [ 'In Diagnosis', 'Completed'], required: true},
		},
		['Surgery']: {
			['Date & Time']: {value: '', type: 'date', val_name:'date', required:true},
			Notes :{value:'', type:'input' , val_name:'notes'},
			Name :{value:'', type:'input' , val_name:'type', required:true}
		}
		
	}



	const [ formVal, setFormVal ] = useState(
		{ 
			['Vaccinations']: {},
			['Conditions']: {},
			['Surgery']: {},
		 
		}
	)

  useEffect(() => {
    setFormVal({
      ["Vaccinations"]: {},
      ["Conditions"]: {},
      ["Surgery"]: {},
    });
  }, [type]);

  useEffect(() => {
    console.log(clinic_details, "conditions clinic detail");
    if (clinic_details?._id) {
      dispatch(
        getAllDoctors(clinic_details._id, {}, (result) => {
          if (result && !result.errors) {
            setDoctorsList(result);
          } else {
            setDoctorsList(false);
          }
        })
      );
    }
  }, [clinic_details]);

  const handleAddPoint = (values) => {
    let doctorSection = {};
    let dateSection = {};
    Object.keys(values).forEach((key) => {
      if (values[key] === "" || values[key] === null) {
        delete values[key];
      }
    });

    if (type === "Conditions") {
      doctorSection = { diagnosed_by: doctorsList[selectedDoctor]?.user?._id };
      if (values?.start_date)
        dateSection = { start_date: moment(values?.start_date).toDate() };
      if (values?.end_date)
        dateSection = { end_date: moment(values?.end_date).toDate() };
    } else {
      doctorSection = { doctor_id: doctorsList[selectedDoctor]?.user?._id };
      if (values?.date) dateSection = { date: moment(values?.date).toDate() };
    }

    addPoint({ ...values, ...doctorSection, ...dateSection }, (response) => {
      if (response?.status) {
        closeNewPointPanel();
        refresh();
      }
    });
  };

  return type === "" ? (
    <> </>
  ) : (
    <>
      <Formik
        initialValues={initValsFromFormObj(formInfo[type])}
        enableReinitialize
        validationSchema={formObjToSchema(formInfo[type])}
        onSubmit={(values, { resetForm }) => {
          handleAddPoint(values);
          resetForm();
        }}
      >
        {(formik) => (
          <div
            className={`ocular-new-point-panel-wrapper position-fixed d-flex flex-column overflow-auto ${className}`}
          >
            <div className="head d-flex align-items-center">
              <h2>
                Add New{" "}
                {type === "Conditions" ? "Condition" : "Surgical History"}
              </h2>
              <Button
                color="link"
                className="ms-auto panel-close-btn"
                onClick={closeNewPointPanel}
              >
                <span className="material-symbols-outlined">close</span>
              </Button>
            </div>

            <div className="inner overflow-auto align-items-center">
              <Form>
                <div className="form-block">
                  <FormGroup className="input-wrapper2">
                    <div className="form-floating position-relative text-input">
                      <Input
                        type="select"
                        className="form-control"
                        id={"doctor"}
                        value={selectedDoctor}
                        onChange={(evt) =>
                          setSelectedDoctor(evt?.target?.value)
                        }
                      >
                        {doctorsList?.map((item, index) => {
                          return (
                            <option
                              value={index}
                              key={index}
                            >{`${item?.user?.title} ${item?.user?.firstname} ${item?.user?.lastname}`}</option>
                          );
                        })}
                      </Input>
                      <label for={`doctor`}>
                        {type === "Conditions" ? `Diagnosed By` : "Doctor"}
                      </label>
                    </div>
                  </FormGroup>
                </div>

                {Object.keys(formInfo[type]).map((key, i) => {
                  if (
                    formInfo[type][key]?.dependent &&
                    formik?.values?.type !== formInfo[type][key]?.dependent
                  )
                    return <></>;

                  switch (formInfo[type][key].type) {
                    case "input":
                      return (
                        <>
                          <div key={i} className="form-block">
                            <FormGroup className="input-wrapper">
                              <div className="form-floating position-relative text-input">
                                <Input
                                  invalid={
                                    formik?.errors?.[
                                      formInfo[type][key].val_name
                                    ] &&
                                    formik?.touched?.[
                                      formInfo[type][key].val_name
                                    ]
                                  }
                                  type="text"
                                  name={formInfo[type][key].val_name}
                                  className="form-control"
                                  id={`${type}-${key}`}
                                  placeholder={`Enter ${type} ${key}`}
                                  value={
                                    formik.values?.[
                                      formInfo[type][key].val_name
                                    ]
                                  }
                                  onChange={(evt) => {
                                    formik.handleChange(evt);
                                  }}
                                />
                                <label for={`${type}-${key}`}>{`${key}`}</label>
                                {formik?.errors?.[
                                  formInfo[type][key].val_name
                                ] &&
                                formik?.touched?.[
                                  formInfo[type][key].val_name
                                ] ? (
                                  <FormFeedback invalid>
                                    {
                                      formik?.errors?.[
                                        formInfo[type][key].val_name
                                      ]
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </FormGroup>
                          </div>
                        </>
                      );
                    case "select":
                      return (
                        <>
                          <div key={i} className="form-block">
                            <FormGroup className="input-wrapper">
                              <div className="form-floating position-relative text-input">
                                <Input
                                  invalid={
                                    formik?.errors?.[
                                      formInfo[type][key].val_name
                                    ] &&
                                    formik?.touched?.[
                                      formInfo[type][key].val_name
                                    ]
                                  }
                                  type="select"
                                  name={formInfo[type][key].val_name}
                                  className="form-control"
                                  id={`${type}-${key}`}
                                  placeholder={`Enter ${type} ${key}`}
                                  value={
                                    formik.values?.[
                                      formInfo[type][key].val_name
                                    ]
                                  }
                                  onChange={(evt) => {
                                    formik.handleChange(evt);
                                  }}
                                >
                                  <option value={""}>{`Select ${key}`}</option>
                                  {formInfo[type][key].options.map(
                                    (option, j) => {
                                      return <option key={j}>{option}</option>;
                                    }
                                  )}
                                </Input>
                                <label for={`${type}-${key}`}>{`${key}`}</label>
                                {formik?.errors?.[
                                  formInfo[type][key].val_name
                                ] &&
                                formik?.touched?.[
                                  formInfo[type][key].val_name
                                ] ? (
                                  <FormFeedback invalid>
                                    {
                                      formik?.errors?.[
                                        formInfo[type][key].val_name
                                      ]
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </FormGroup>
                          </div>
                        </>
                      );
                    case "date":
                      return (
                        <>
                          <div key={i} className="form-block">
                            <FormGroup className="input-wrapper">
                              <div className="form-floating position-relative text-input">
                                <Input
                                  invalid={
                                    formik?.errors?.[
                                      formInfo[type][key].val_name
                                    ] &&
                                    formik?.touched?.[
                                      formInfo[type][key].val_name
                                    ]
                                  }
                                  type="datetime-local"
                                  name={formInfo[type][key].val_name}
                                  className="form-control"
                                  id={`${type}-${key}`}
                                  placeholder={`Enter ${type} ${key}`}
                                  value={
                                    formik?.values?.[
                                      formInfo[type][key].val_name
                                    ]
                                  }
                                  onChange={(evt) => {
                                    formik.handleChange(evt);
                                  }}
                                />
                                <label for={`${type}-${key}`}>{`${key}`}</label>

                                {formik?.errors?.[
                                  formInfo[type][key].val_name
                                ] &&
                                formik?.touched?.[
                                  formInfo[type][key].val_name
                                ] ? (
                                  <FormFeedback invalid>
                                    {
                                      formik?.errors?.[
                                        formInfo[type][key].val_name
                                      ]
                                    }
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </FormGroup>
                          </div>
                        </>
                      );
                  }
                })}

							<div className="d-flex justify-content-end btn-blc">
								<Button type="submit" color="success" className="add-btn">Add</Button>
							</div>
						
					</Form>
				</div>
			</div>)}
			</Formik>
		
		</>
			
					
	);
};

export default NewPointPanel;
