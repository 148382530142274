import React, { useEffect, useRef, useState, forwardRef } from "react";
import { Suspense } from "react";

import {
  useThree,
  Canvas,
  useFrame,
  useLoader,
  meshBasicMaterial,
  meshStandardMaterial,
} from "@react-three/fiber";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
//import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { OBJLoader } from 'three/addons/loaders/OBJLoader.js';

import {
  Stats,
  OrbitControls,
  Html,
  Text,
  RenderTexture,
  PerspectiveCamera,
  Decal,
} from "@react-three/drei";
import { useMemo } from "react";
import dogUrl from "../../assets/models/uploads_files_739127_Husky.obj";
import catUrl from "../../assets/models/uploads_files_913270_Cat.fbx";

const Animal = ({
  species = "Cat",
  points,
  isNewPoint,
  selectPoint,
  sendSetPoint,
}) => {
  const { gl, camera, scene } = useThree();

  const fbx = useLoader(
    species === "Cat" ? FBXLoader : OBJLoader,
    species === "Cat" ? catUrl : dogUrl
  );
  const BasicMaterialClear = <meshBasicMaterial color="#000000" />;
  const scale = species === "Cat" ? [0.015, 0.015, 0.015] : [0.25, 0.25, 0.25];
  const outer_scale =
    species === "Cat"
      ? [0.01501, 0.01501, 0.01500001]
      : [0.2501, 0.2501, 0.2501];
  const position = species === "Cat" ? [0, -2, 0] : [-1, 0, 0];
  const model = useMemo(() => fbx.clone(true), []);
  model.children.forEach((mesh, i) => {
    mesh.material = BasicMaterialClear;
  });

  const [point, setPoint] = useState(false);
  const ref = useRef();
  return (
    <>
      <mesh
        position={position}
        receiveShadow
        ref={ref}
        rotation={[0, -2 * (Math.PI / 4), 0]}
        scale={scale}
      >
        <primitive object={fbx} />
      </mesh>
      <mesh
        position={position}
        onClick={(e) => {
          e.stopPropagation();
          setPoint([
            e.intersections[0].point.x,
            e.intersections[0].point.y,
            e.intersections[0].point.z,
          ]);
          sendSetPoint([
            e.intersections[0].point.x,
            e.intersections[0].point.y,
            e.intersections[0].point.z,
          ]);
        }}
        rotation={[0, -2 * (Math.PI / 4), 0]}
        scale={outer_scale}
      >
        <primitive object={model} />
      </mesh>

      {isNewPoint
        ? point && <Marker ref={ref} coord={point} />
        : points.length > 0 &&
          points.map((coord, i) => (
            <Marker
              sendOnClick={() => {
                selectPoint(i);
              }}
              key={i}
              ref={ref}
              text={i + 1}
              coord={coord}
            />
          ))}
    </>
  );
};

const Marker = forwardRef(function (
  { text = false, sendOnClick = () => {}, coord },
  ref
) {
  const [hidden, set] = useState(false);

  return (
    <Html
      zIndexRange={[1]}
      rotation={[0, 0, 0]}
      occlude={ref ? [ref] : []}
      style={{
        transition: "all 0.5s",
        opacity: hidden ? 0.2 : 1,
        transform: `scale(${hidden ? 0.5 : 1})`,
      }}
      onOcclude={set}
      position={coord}
    >
      <div
        onClick={sendOnClick}
        style={{
          textAlign: "center",
          fontSize: "11px",
          backgroundColor: "blue",
          color: "white",
          borderRadius: "100px",
          width: "15px",
          height: "15px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p>{text || ""}</p>
      </div>
    </Html>
  );
});

const Model = ({
  species = "cat",
  points = [],
  isNewPoint = true,
  setPoint = () => {},
  selectPoint = () => {},
}) => {
  return (
    <Canvas gl={{ preserveDrawingBuffer: true }} frameloop="demand" shadows>
      <Suspense fallback={null}>
        <color attach="background" args={["#EBECF0"]} />
        <ambientLight intensity={1} />
        <pointLight position={[0, -2, 0]} intensity={4} />
        <pointLight position={[1.5, -2, 0]} intensity={2} />
        <pointLight position={[-1.5, -2, 0]} intensity={2} />
        <directionalLight castShadow intensity={1.5} shadow-mapSize={1024} />
        <Animal
          species={species}
          points={points}
          isNewPoint={isNewPoint}
          sendSetPoint={setPoint}
          selectPoint={selectPoint}
        />{" "}
        :
        <OrbitControls />
      </Suspense>
    </Canvas>
  );
};

export default Model;
