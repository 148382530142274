import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Popover,
  PopoverBody,
  Input,
  Modal,
  ModalBody,
} from "reactstrap";
import {
  getPetDetailsById,
  removeAllergy,
} from "../../../../context/actions/patient-module/patient/pet";

import { addAllergy } from "../../../../context/actions/patient-module/patient/pet";
import "./index.scss";
import { BiMinus } from "react-icons/bi";
import { useFormik } from "formik";
const ProfileAllergyEdit = ({ showRemoveIcon, refresh }) => {
  const [popover, setPopover] = useState(false);
  const [allergyValue, setAllergyValue] = useState(false);
  const [reactionValue, setReactionValue] = useState(false);

  const dispatch = useDispatch();

  const popoverToggle = () => {
    setPopover(!popover);
  };

  const petDetails = useSelector((state) => state.petReducer.petDetailsSide);
  const formik = useFormik({
    initialValues: {
      allergy: "",
      reaction: "",
    },
    onSubmit: (values, { resetForm }) => {
      console.log(values);

      dispatch(
        addAllergy(
          {
            pet_id: petDetails._id,
            allergy: values.allergy || "",
            reaction: values.reaction,
          },
          (response) => {
            if (response?.status) {
              //dispatch(getPetDetailsById(petDetails._id, {}, () => { }));
              refresh(); 
            }
          }
        )
      );
      setPopover(false);
      resetForm();
    },
  });

  const handleAddAllergy = () => {
    dispatch(addAllergy({ pet_id: petDetails._id, allergy: allergyValue, reaction: reactionValue }, (response) => {
      if (response?.status) {

        refresh();
        popoverToggle();
        /*
        dispatch(getPetDetailsById(petDetails._id, {}, () => {
          popoverToggle();
        }))*/
      }
    }))

  }

  const handleRemoveAllergy = (item) => {
    // Dispatch an action to remove the allergy item by its unique ID
    dispatch(
      removeAllergy(
        {
          pet_id: petDetails._id,
          allergy: item.allergy,
          reaction: item.reaction,
        },
        (response) => {
          if (response?.status) {
            //dispatch(getPetDetailsById(petDetails._id, {}, () => { }));
            refresh();
          }
        }
      )
    );
  };
  const [isMore, setIsMore] = useState(false);
  return (
    <>
      <div className="profile-allergy row-block">
        <div className="head section-head d-flex align-items-center">
          <div className="left-col">Allergies</div>
          <div className="right-col ms-auto">
            {popover ? (
              <Button
                id="edit-allergy-right"
                onClick={() => setPopover(false)}
                color="link"
                className="close-btn d-inline-flex" style={{fontSize:'19px',padding:'10px'}}
              >
                <span className="icomoon-cross-btn"></span>
              </Button>
            ) : (
              <Button
                id="edit-allergy-right"
                onClick={() => setPopover(true)}
                color="link"
                className="add-btn d-inline-flex"
              >
                <span className="icomoon-plus-btn"></span>
              </Button>
            )}

            <Popover placement="bottom-end" isOpen={popover} toggle={popoverToggle} target="edit-allergy-right">
              <PopoverBody className={'new-tag'}>
                <Input placeholder="Add Allergy" onChange={(event) => { setAllergyValue(event?.target?.value) }} />
                <Input placeholder="Add Reaction" onChange={(event) => { setReactionValue(event?.target?.value) }} />
                <div className="btn-cell d-flex">
                  <Button color="primary" onClick={handleAddAllergy} className="flex-fill">
                    Add Allergy
                  </Button>
                </div>
              </PopoverBody>
            </Popover>

            {/* <Modal
              placement="bottom-end"
              isOpen={popover}
              toggle={() => setPopover(false)}
              target="add-allergy"
            >
              <ModalBody className={"new-tag"}>
                <form onSubmit={formik.handleSubmit}>
                  <Input
                    placeholder="Add Allergy"
                    name="allergy"
                    value={formik.values.allergy}
                    onChange={formik.handleChange}
                  />
                  <Input
                    placeholder="Add Reaction"
                    name="reaction"
                    value={formik.values.reaction}
                    onChange={formik.handleChange}
                  />
                  <div className="btn-cell d-flex">
                    <Button type="submit" color="primary" className="flex-fill">
                      Add Allergy
                    </Button>
                  </div>
                </form>
              </ModalBody>
            </Modal> */}
          </div>
        </div>
        <div className="inner">
          <div className="allergy-list d-flex flex-column ">
            {(petDetails?.petCareInfo?.allergies || []).map((item, index) => {
              // eslint-disable-line
              if (!isMore && index < 2) {
                return (
                  <div className="item-cell d-flex flex-column position-relative">
                    {
                      showRemoveIcon && (
                        <button
                          type="button"
                          className="bg-green-400 rounded-full minus text-white p-1 dash d-flex align-items-center"
                          onClick={() => handleRemoveAllergy(item)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-dash-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                          </svg>
                        </button>
                      )
                    }


                    <span>Allergy: {item?.allergy}</span>
                    <span>Reaction: {item?.reaction}</span>
                  </div>
                );
              } else if (isMore) {
                return (
                  <div className="item-cell d-flex flex-column position-relative">
                    {
                      showRemoveIcon && (
                        <button
                          type="button"
                          className="bg-green-400 rounded-full minus text-white p-1 dash d-flex align-items-center"
                          onClick={() => handleRemoveAllergy(item)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-dash-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                          </svg>
                        </button>
                      )
                    }

                    <span>Allergy: {item?.allergy}</span>
                    <span>Reaction: {item?.reaction}</span>
                  </div>
                );
              }
            })}
            {/* <span className="item-cell">Allergies to Medications</span>
						<span className="item-cell">[Allergic Information will goes here]</span> */}
          </div>
          <Button
            onClick={() => setIsMore(!isMore)}
            color="link"
            className="add-btn"
          >
            {isMore
              ? "Less"
              : petDetails?.petCareInfo?.allergies?.length > 2
                ? "+" +
                (petDetails?.petCareInfo?.allergies?.length - 2).toString() +
                " More"
                : ""}
          </Button>
        </div>
      </div>
    </>
  );
};

export default ProfileAllergyEdit;
