import React from "react";
import './index.scss';
import StaffProfileItem from "./StaffProfileItem";
import StaffProfileFeature from "./StaffProfileFeature";

const StaffOverviewHead = ({ className = '', staffMemberDetails }) => {


	return (
		<>
			<div className={`staff-overview-head d-flex ${className}`}>
				<div className="left-col">
					<StaffProfileItem staffMemberDetails={staffMemberDetails} />
				</div>
				<div className="right-col ms-auto">
					<StaffProfileFeature staffMemberDetails={staffMemberDetails} />
				</div>
			</div>
		</>
	);
};

export default StaffOverviewHead;
