import moment from "moment";

/*
 * constants used for week days
 */
export const weekDays = [
  {
    name: "Monday",
    value: 1,
  },
  {
    name: "Tuesday",
    value: 2,
  },
  {
    name: "Wednesday",
    value: 3,
  },
  {
    name: "Thursday",
    value: 4,
  },
  {
    name: "Friday",
    value: 5,
  },
  {
    name: "Saturday",
    value: 6,
  },
  {
    name: "Sunday",
    value: 0,
  },
];

/*
 * constants used for short week days
 */
export const shortWeekDays = [
  {
    name: "SUN",
    value: 0,
  },
  {
    name: "MON",
    value: 1,
  },
  {
    name: "TUE",
    value: 2,
  },
  {
    name: "WED",
    value: 3,
  },
  {
    name: "THU",
    value: 4,
  },
  {
    name: "FRI",
    value: 5,
  },
  {
    name: "SAT",
    value: 6,
  },
];

/*
 * constants used for months
 */
export const months = [
  {
    name: "January",
    value: 1,
  },
  {
    name: "February",
    value: 2,
  },
  {
    name: "March",
    value: 3,
  },
  {
    name: "April",
    value: 4,
  },
  {
    name: "May",
    value: 5,
  },
  {
    name: "June",
    value: 6,
  },
  {
    name: "July",
    value: 7,
  },
  {
    name: "August",
    value: 8,
  },
  {
    name: "September",
    value: 9,
  },
  {
    name: "October",
    value: 10,
  },
  {
    name: "November",
    value: 11,
  },
  {
    name: "December",
    value: 12,
  },
];

/*
 * constants used for short months
 */
export const shortMonths = [
  {
    name: "Jan",
    value: 1,
  },
  {
    name: "Feb",
    value: 2,
  },
  {
    name: "Mar",
    value: 3,
  },
  {
    name: "Apr",
    value: 4,
  },
  {
    name: "May",
    value: 5,
  },
  {
    name: "Jun",
    value: 6,
  },
  {
    name: "Jul",
    value: 7,
  },
  {
    name: "Aug",
    value: 8,
  },
  {
    name: "Sep",
    value: 9,
  },
  {
    name: "Oct",
    value: 10,
  },
  {
    name: "Nov",
    value: 11,
  },
  {
    name: "Dec",
    value: 12,
  },
];

/*
 * constants used for day time span
 */
export const dayTimeSpan = [
  {
    name: "am",
    value: 12,
  },
  {
    name: "am",
    value: 1,
  },
  {
    name: "am",
    value: 2,
  },
  {
    name: "am",
    value: 3,
  },
  {
    name: "am",
    value: 4,
  },
  {
    name: "am",
    value: 5,
  },
  {
    name: "am",
    value: 6,
  },
  {
    name: "am",
    value: 7,
  },
  {
    name: "am",
    value: 8,
  },
  {
    name: "am",
    value: 9,
  },
  {
    name: "am",
    value: 10,
  },
  {
    name: "am",
    value: 11,
  },
  {
    name: "pm",
    value: 12,
  },
  {
    name: "pm",
    value: 1,
  },
  {
    name: "pm",
    value: 2,
  },
  {
    name: "pm",
    value: 3,
  },
  {
    name: "pm",
    value: 4,
  },
  {
    name: "pm",
    value: 5,
  },
  {
    name: "pm",
    value: 6,
  },
  {
    name: "pm",
    value: 7,
  },
  {
    name: "pm",
    value: 8,
  },
  {
    name: "pm",
    value: 9,
  },
  {
    name: "pm",
    value: 10,
  },
  {
    name: "pm",
    value: 11,
  },
];

/*
 * constants used for get month short name
 * @ month
 */
export const getMonthShortName = (month) => {
  const monthName = shortMonths.filter((data) => data.value === month)[0].name;
  return monthName;
};

/*
 * constants used for get date data
 * @ date
 */
export const getDateData = (date) => {
  const currentDate = moment(new Date());
  const dateObj = moment(date);
  return {
    day: shortWeekDays.filter((data) => data.value === dateObj.day())[0].name,
    date: dateObj.date(),
    current_date:
      currentDate.dayOfYear() === dateObj.dayOfYear() &&
        currentDate.year() === dateObj.year()
        ? true
        : false,
  };
};

/*
 * constants used for get month data
 * @ date
 */
export const getMonthData = (date) => {
  const monthObj = moment(date);
  const month = monthObj.month() + 1;
  return {
    name: shortMonths.filter((data) => data.value === month)[0].name,
    value: month,
    year: monthObj.year(),
  };
};

/*
 * constants used for days in months
 * @ month
 * @ year
 */
export const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};

/*
 * constants used for get next day
 * @ currentDate
 * @ currentMonth
 * @ year
 */
export const getNextDay = (currentDay, currentMonth, year) => {
  const currentDate = new Date(year, currentMonth - 1, currentDay).getTime();
  const nextDate = new Date(currentDate + 1000 * 60 * 60 * 24 * 2 - 1);
  return getMonthData(nextDate).name + " " + getDateData(nextDate).date;
};

/*
 * constants used for get current day
 * @ currentDate
 * @ currentMonth
 * @ year
 */
export const getCurrentDay = () => {
  const currentDate = new Date().getTime();
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const data = {
    date: getMonthData(currentDate).name + " " + getDateData(currentDate).date,
    date_value: getDateData(currentDate).date,
    month_value: currentMonth + 1,
    year_value: currentYear,
  };
  return data;
};

/*
 * constants used for get previous day
 * @ currentDate
 * @ currentMonth
 * @ year
 */
export const getPreviousDay = (currentDay, currentMonth, year) => {
  const currentDate = new Date(year, currentMonth - 1, currentDay).getTime();
  const previousDate = new Date(currentDate - 1000 * 60 * 60 * 24 * 1);
  return getMonthData(previousDate).name + " " + getDateData(previousDate).date;
};

/*
 * constants used for get day value
 * @ currentDay
 * @ currentMonth
 * @ year
 * @ dayType
 */
export const getDayValue = (currentDay, currentMonth, year, dayType) => {
  let dateVal;
  let dateIntVal;
  let monthFor;
  const currentDate = new Date(year, currentMonth - 1, currentDay).getTime();

  if (dayType === "next") {
    dateIntVal = new Date(currentDate + 1000 * 60 * 60 * 24 * 2 - 1);
    if (currentDay === 31 && currentMonth === 12) {
      monthFor = 1;
    } else {
      monthFor = getMonthData(dateIntVal).value;
    }
  } else {
    dateIntVal = new Date(currentDate - 1000 * 60 * 60 * 24 * 1);
    if (currentDay === 1 && currentMonth === 1) {
      monthFor = 12;
    } else {
      monthFor = getMonthData(dateIntVal).value;
    }
  }

  dateVal = getMonthData(dateIntVal).name + " " + getDateData(dateIntVal).date;
  const data = {
    date: dateVal,
    date_value: getDateData(dateIntVal).date,
    month_value: monthFor,
    year_value: year,
  };
  return data;
};

/*
 * constants used for get next month
 * @ currentMonth
 * @ year
 */
export const getNextMonth = (currentMonth, year) => {
  let nextMonth;
  let yearValue;
  if (currentMonth === 11) {
    nextMonth = 12;
  } else {
    nextMonth = new Date(year, currentMonth + 2, 0).getMonth();
  }
  const nextMonthName = shortMonths.filter(
    (data) => data.value === nextMonth
  )[0];
  if (nextMonthName.value === 1) {
    yearValue = year + 1;
  } else {
    yearValue = year;
  }
  return nextMonthName.name + " " + yearValue;
};

/*
 * constants used for get month value
 * @ currentMonth
 * @ year
 */
export const getMonthValue = (currentMonth, year, monthType) => {
  // console.log(currentMonth)
  let monthFor;
  let yearValue;
  if (monthType === "next") {
    if (currentMonth === 11) {
      monthFor = 0;
    } else if (currentMonth === 12) {
      monthFor = 0;
    } else {
      // console.log(new Date(year, (currentMonth + 2), 0).toDateString())
      monthFor = new Date(year, currentMonth + 2, 0).getMonth();
    }
  } else {
    if (currentMonth === 0) {
      monthFor = 11;
    } else {
      // console.log(new Date(year, (currentMonth - 0), 0).toDateString())
      monthFor = new Date(year, currentMonth - 0, 0).getMonth();
    }
  }

  //const monthForName = shortMonths.filter(data => data.value === monthFor)[0]; edit CHANGEBACK
  const monthForName = shortMonths.filter(
    (data) => data.value === monthFor + 1
  )[0];

  if (monthForName.value === 1) {
    yearValue =
      monthType === "next"
        ? year + 1 /*: currentMonth === 1 ? year - 1*/
        : year;
  } else {
    yearValue = year;
  }

  const data = {
    month_year: monthForName.name + " " + yearValue,
    month_value: monthFor,
    year_value: yearValue,
  };
  return data;
};

/*
 * constants used for get current month
 * @ currentMonth
 * @ year
 */
export const getCurrentMonth = () => {
  let currentMonth = new Date().getMonth();
  let currentYear = new Date().getFullYear();
  const currentMonthName = shortMonths.filter(
    (data) => data.value === currentMonth + 1
  )[0];
  let currentDate = new Date().getDate();
  const dateNow = new Date(currentYear, currentMonth, currentDate).getTime();
  const veryNextDate = getDateData(new Date(dateNow + 1000)).date;
  const nextDate = new Date(dateNow + 1000 * 60 * 60 * 24 * 7 - 1);

  const data = {
    month_year: currentMonthName?.name + " " + currentYear,
    month_value: currentMonth, // maybe wrong value TIM CHECKOUT
    //date_value: getDateData(nextDate).date,
    //span_date: veryNextDate,
    //span_month: getMonthData(nextDate).value,
    year_value: currentYear,
    //data: getMonthShortName(currentMonth) + " " + currentYear,
  };
  // console.log(data);
  return data;
};

/*
 * constants used for get previous month
 * @ currentMonth
 * @ year
 */
export const getPreviousMonth = (currentMonth, year) => {
  let previousMonth;
  let yearValue;
  if (currentMonth === 1) {
    previousMonth = 12;
  } else {
    previousMonth = new Date(year, currentMonth - 0, 0).getMonth();
  }
  const previousMonthName = shortMonths.filter(
    (data) => data.value === previousMonth
  )[0];
  if (previousMonthName.value === 12) {
    yearValue = year - 1;
  } else {
    yearValue = year;
  }
  return previousMonthName.name + " " + yearValue;
};

/*
 * constants used for get next week span
 * @ currentDate
 * @ currentMonth
 * @ year
 */
export const getNextWeekSpan = (currentDate, currentMonth, year) => {
  const dateNow = new Date(year, currentMonth - 1, currentDate).getTime();
  const nextDate = new Date(dateNow + 1000 * 60 * 60 * 24 * 7 - 1);
  const spanFrom = getMonthShortName(currentMonth) + " " + currentDate;
  const spanTo = getMonthData(nextDate).name + " " + getDateData(nextDate).date;
  const result = {
    month_value: currentMonth - 1,
    year_value: year,
    date_value: currentDate,
    data: spanFrom + " - " + spanTo,
  };
  return result;
};

/*
 * constants used for get previous week span
 * @ currentDate
 * @ currentMonth
 * @ year
 */
export const getPreviousWeekSpan = (currentDate, currentMonth, year) => {
  const dateNow = new Date(year, currentMonth - 1, currentDate).getTime();
  const previousDate = new Date(dateNow - 1000 * 60 * 60 * 24 * 6);
  const spanFrom =
    getMonthData(previousDate).name + " " + getDateData(previousDate).date;
  const spanTo = getMonthShortName(currentMonth) + " " + currentDate;
  const result = {
    month_value: currentMonth - 1,
    year_value: year,
    date_value: currentDate,
    data: spanFrom + " - " + spanTo,
  };
  return result;
};

/*
 * constants used for get current week span
 * @ currentDate
 * @ currentMonth
 * @ year
 */
export const getCurrentWeekSpan = () => {
  let currentMonth = new Date().getMonth() + 1;
  let currentYear = new Date().getFullYear();
  let currentDate = new Date().getDate();

  const dateNow = new Date(
    currentYear,
    currentMonth - 1,
    currentDate
  ).getTime();
  const veryNextDate = getDateData(new Date(dateNow + 1000)).date;
  // eslint-disable-next-line
  const spanMonth = getMonthData(new Date(dateNow + 1000)).value;
  const nextDate = new Date(dateNow + 1000 * 60 * 60 * 24 * 7 - 1);
  const spanFrom = getMonthShortName(currentMonth) + " " + currentDate;
  const spanTo = getMonthData(nextDate).name + " " + getDateData(nextDate).date;
  const data = {
    month_value: getMonthData(nextDate).value,
    year_value: currentYear,
    date_value: getDateData(nextDate).date,
    span_date: veryNextDate,
    span_month: currentMonth,
    data: spanFrom + " - " + spanTo,
  };

  return data;
};

/*
 * constants used for get week span value
 * @ currentMonth
 * @ year
 */
export const getWeekSpanValue = (currentDate, currentMonth, year, weekType) => {
  let weekSpanDate;
  let data;
  let spanTo;
  let datePrevious;
  const newdateTimeStamp = new Date(
    year,
    currentMonth - 1,
    currentDate + 1
  ).getTime();
  const veryNextDate = getDateData(new Date(newdateTimeStamp + 1000)).date;
  const spanMonth = getMonthData(new Date(newdateTimeStamp + 1000)).value;
  if (weekType === "next") {
    const nextDate = new Date(
      year,
      currentMonth - 1,
      currentDate + 1
    ).getDate();
    const nextMonth = new Date(
      year,
      currentMonth - 1,
      currentDate + 1
    ).getMonth();
    const spanFrom =
      getMonthShortName(nextMonth + 1) +
      " " +
      (weekType === "next" ? nextDate : currentDate - 1);
    const dateNow = new Date(year, currentMonth - 1, currentDate + 1).getTime();
    weekSpanDate = new Date(dateNow + 1000 * 60 * 60 * 24 * 7 - 1);
    spanTo =
      getMonthData(weekSpanDate).name + " " + getDateData(weekSpanDate).date;
    data = spanFrom + " - " + spanTo;
  } else {
    const dateNow = new Date(year, currentMonth - 1, currentDate - 1).getTime();
    datePrevious = new Date(dateNow - 1000 * 60 * 60 * 24 * 12);
    const spanFrom =
      getMonthData(datePrevious).name + " " + getDateData(datePrevious).date;
    weekSpanDate = new Date(dateNow - 1000 * 60 * 60 * 24 * 6);
    spanTo =
      getMonthData(weekSpanDate).name + " " + getDateData(weekSpanDate).date;
    data = spanFrom + " - " + spanTo;
  }

  const spanDateVal =
    weekType === "next" ? veryNextDate : getDateData(datePrevious).date;
  const spanMonthVal =
    weekType === "next" ? spanMonth : getMonthData(datePrevious).value;
  const result = {
    month_value: getMonthData(weekSpanDate).value,

    year_value: getMonthData(datePrevious).year,
    year_value: getMonthData(weekSpanDate).year,
    date_value: getDateData(weekSpanDate).date,
    span_date:
      weekType === "next" ? veryNextDate : getDateData(datePrevious).date,
    span_month:
      weekType === "next" ? spanMonth : getMonthData(datePrevious).value,
    data,
    last_week_span: spanDateVal > 25 && spanMonthVal === 12 ? true : false,

    // last_week_span: veryNextDate > 25 && currentMonth === 12 ? true : false,
    // previous_week_span: (spanDateVal < 7 && spanMonthVal === 1) ? true : false,
  };
  return result;
};

/*
 * constants used for get next week span days
 * @ currentDate
 * @ currentMonth
 * @ year
 */
export const getNextWeekSpanDays = (currentDate, currentMonth, year) => {
  const nextSpanDays = [];
  for (let index = 0; index < 7; index++) {
    let yearValue = typeof year === "object" ? year[0] : year;
    const dateNow = new Date(yearValue, currentMonth - 1, currentDate);
    const fullDate = new Date(
      dateNow.getTime() + 1000 * 60 * 60 * 24 * (index + 1) - 1
    );
    const monthValue = fullDate.getMonth();
    yearValue = fullDate.getFullYear();
    const { day, date } = getDateData(fullDate);
    nextSpanDays.push({
      day,
      date,
      full_date: `${("0" + date).slice(-2)}-${("0" + (monthValue + 1)).slice(
        -2
      )}-${yearValue}`,
    });
  }
  return nextSpanDays;
};

/*
 * function used to get a string with condensed appointment timings
 * @ timings - an array of appointment timings
 */

export const getAppointmentTimingDisplayValues = (timings) => {
  // let vals = timings?.sort((a,b) => moment(`1970-01-01 ${a.slot_time}`, `YYYY-MM-DD hh:mm a`).valueOf() - moment(`1970-01-01 ${b.slot_time}`,'YYYY-MM-DD hh:mm a').valueOf())?.map((ele) => {
  //   // return duplicateValue?.map((duplicate, i) => {
  //   //   if(duplicate?.date === ele?.appointment_date && duplicate?.time === ele?.appointment_time){
  //   //     console.log('xyz');
  //   //     return ele?.appointment_time;
  //   //   }
  //   //   return null;
  //   // })
  //   return ele?.appointment_time

  // })
  let vals = timings
    ?.filter((o) => o.appointment_time)
    ?.sort(
      (a, b) =>
        moment(`1970-01-01 ${a.slot_time}`, `YYYY-MM-DD hh:mm a`).valueOf() -
        moment(`1970-01-01 ${b.slot_time}`, "YYYY-MM-DD hh:mm a").valueOf()
    )
    .map((ele) => ele?.appointment_time);
  if (vals) {
    // console.log('timings',  timings?.sort((a,b) => moment(`1970-01-01 ${a.slot_time}`, `YYYY-MM-DD hh:mm a`).valueOf() - moment(`1970-01-01 ${b.slot_time}`,'YYYY-MM-DD hh:mm a').valueOf()).map((ele) => ele?.appointment_time))

    let new_arr = [];
    let i = 0;
    while (i < vals.length) {
      let val = vals[i]?.split("-")?.[0]?.trim();
      let second_time = vals[i]?.split("-")?.[1]?.trim();
      while (
        i + 1 < vals.length &&
        vals[i]?.split("-")?.[1]?.trim() ===
        vals[i + 1]?.split("-")?.[0]?.trim()
      ) {
        second_time = vals[i + 1]?.split("-")?.[1]?.trim();
        i++;
      }
      new_arr.push(val + " - " + second_time);

      i++;
    }

    return new_arr;
  } else {
    return [];
  }
};

/*
 * constants used for get previous week span days
 * @ currentDate
 * @ currentMonth
 * @ year
 */
export const getPreviousWeekSpanDays = (currentDate, currentMonth, year) => {
  const dateNow = new Date(year, currentMonth - 1, currentDate);
  const previousSpanDays = [];
  for (let index = 0; index < 7; index++) {
    const fullDate = new Date(
      dateNow.getTime() - 1000 * 60 * 60 * 24 * (index - 1) - 1
    );
    const { day, date } = getDateData(fullDate);
    previousSpanDays.push({
      day,
      date,
    });
  }
  return previousSpanDays;
};

/*
 * constants used for get current week span days
 * @ currentDate
 * @ currentMonth
 * @ year
 */
export const getDaysForCalendarMonth = (currentDate, currentMonth, year) => {
  const date = new Date(year, currentMonth, currentDate);
  const firstDayOfMonth = moment(date).startOf("month");
  const firstDayOfCal = firstDayOfMonth.clone().startOf("week");
  const lastDayOfMonth = firstDayOfMonth.clone().endOf("month");
  const lastDayOfCal = lastDayOfMonth.clone().endOf("week");
  const temp = firstDayOfCal.clone();

  const days = [];
  while (temp.isBefore(lastDayOfCal)) {
    const dateVal = temp.toDate();
    const monthValue = getMonthData(dateVal).value;
    const fullDate = `${("0" + dateVal.getDate()).slice(-2)}-${(
      "0" +
      (dateVal.getMonth() + 1)
    ).slice(-2)}-${dateVal.getFullYear()}`;
    days.push({
      full_date: fullDate,
      date: getDateData(dateVal).date,
      day: getDateData(dateVal).day,
      month: monthValue,
      is_current_month: monthValue === currentMonth ? true : false,
      current_date: getDateData(dateVal).current_date,
    });
    temp.add(1, "day");
  }

  const weekDays = 7;
  const monthData = [];
  let weekData = [];
  for (let i = 0; i < days.length; i++) {
    weekData.push(days[i]);
    if (weekData.length === weekDays) {
      monthData.push(weekData);
      weekData = [];
    }
  }

  if (weekData.length > 0) {
    monthData.push(weekData);
  }

  return monthData;
};

export const getCurrentWeekSpanValueNew = (startDay) => {
  const daysOfWeek = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const today = new Date();
  const currentDayIndex = daysOfWeek.indexOf(startDay);
  let dayDifference = today.getDay() - currentDayIndex;

  if (dayDifference < 0) {
    dayDifference = dayDifference + 7;
  }
  const startDate = new Date(today);

  startDate.setDate(today.getDate() - dayDifference);
  let start_date = moment(startDate);
  let end_date = moment(startDate).add(6, "days");
  const weekDates = [];
  for (let i = 0; i < 7; i++) {
    const date = new Date(startDate);
    date.setDate(startDate.getDate() + i);
    weekDates.push(date.toISOString().split("T")[0]);
  }
  const result = {
    month_value: new Date(weekDates[0]).getMonth() + 1,
    year_value: today.getFullYear(),
    date_value: moment(weekDates[0]).date(),
    span_date: +moment(weekDates[0], "YYYY-MM-DD").format("DD"),
    span_month: new Date(weekDates[0]).getMonth() + 1,
    data: `${moment(weekDates[0], "YYYY-MM-DD").format("MMM DD")} - ${moment(
      weekDates[6],
      "YYYY-MM-DD"
    ).format("MMM DD")}`,
    week_start_date: start_date.format("DD-MM-YYYY"),
    week_end_date: end_date.format("DD-MM-YYYY"),
    week_display_date: `${start_date.format("MMM DD")} - ${end_date.format(
      "MMM DD"
    )}`,
  };
  return result;
};

export const getWeekSpanDaysValue = (startDate, endDate, weekType) => {
  const prevStartDate = moment(startDate, "DD-MM-YYYY");
  const prevEndDate = moment(endDate, "DD-MM-YYYY");
  let week_start_date = prevStartDate;
  let week_end_date = prevEndDate;
  if (weekType === "next") {
    week_start_date = prevEndDate.clone().add(1, "days");
    week_end_date = prevEndDate.clone().add(7, "days");
  } else {
    week_start_date = prevStartDate.clone().subtract(7, "days");
    week_end_date = prevStartDate.clone().subtract(1, "days");
  }
  const result = {
    month_value: +week_start_date.format("M"),
    year_value: +week_start_date.format("YYYY"),
    date_value: +week_start_date.format("D"),
    span_date: +week_start_date.format("D"),
    span_month: +week_start_date.format("M"),
    last_week_span: false,
    data: `${week_start_date.format("MMM DD")} - ${week_end_date.format(
      "MMM DD"
    )}`,
    week_start_date: week_start_date.format("DD-MM-YYYY"),
    week_end_date: week_end_date.format("DD-MM-YYYY"),
    week_display_date: `${week_start_date.format(
      "MMM DD"
    )} - ${week_end_date.format("MMM DD")}`,
  };
  return result;
};
// export const getWeekSpanDaysValue = (
//   date,
//   monthNumber,
//   yearNumber,
//   weekType
// ) => {

//   const year = parseInt(yearNumber, 10);
//   const month = parseInt(monthNumber, 10) - 1;
//   const day = parseInt(date, 10);
//   const currentDate = new Date(year, month, day);
//   const dateArrayTemp = [];
//   if (weekType === "next") {
//     for (let i = 1; i <= 7; i++) {
//       const nextDate = new Date(currentDate);
//       nextDate.setDate(currentDate.getDate() + i + 7);
//       const formattedDate = nextDate.toISOString().split("T")[0];
//       dateArrayTemp.push(formattedDate);
//     }
//   } else {
//     for (let i = 0; i <= 6; i++) {
//       const previousDate = new Date(currentDate);
//       previousDate.setDate(currentDate.getDate() - i);
//       dateArrayTemp.unshift(previousDate.toISOString().split("T")[0]);
//     }
//   }
//   const newdateTimeStamp = new Date(
//     yearNumber,
//     monthNumber - 1,
//     date + 1
//   ).getTime();
//   const veryNextDate = getDateData(new Date(newdateTimeStamp + 1000)).date;
//   const result = {
//     month_value: new Date(dateArrayTemp[0]).getMonth() + 1,
//     year_value: new Date(dateArrayTemp[0]).getFullYear(),
//     date_value: moment(dateArrayTemp[0]).date(),
//     span_date: new Date(dateArrayTemp[0]).getDate(),
//     span_month: new Date(dateArrayTemp[0]).getMonth() + 1,
//     data:
//       new Date(dateArrayTemp[0]).toLocaleDateString("en-US", {
//         month: "short",
//         day: "numeric",
//       }) +
//       " - " +
//       new Date(dateArrayTemp[6]).toLocaleDateString("en-US", {
//         month: "short",
//         day: "numeric",
//       }),
//     last_week_span: veryNextDate > 25 && monthNumber === 12 ? true : false,
//   };
//   return result;
// };
