import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ConditionBlock from "./ConditionBlock";
import MedicalBreifBox from './MedicalBreifBox';
import SurgicalHistoryBlock from "./SurgicalHistoryBlock";
import './index.scss';
import VaccinationBlock from "./VaccinationBlock";
import { getConditionByPetId, addCondition } from "../../../../../context/actions/patient-module/overview/current-condition";
import { getSurgicalHistoryList, addSurgicalHistory } from "../../../../../context/actions/patient-module/medical-history";
import NewPointPanel from "./NewPointPanel";

import { useParams } from "react-router-dom";

const MedicalHistory = () => {
	const params = useParams();
	const petId = params.petId;
	const dispatch = useDispatch();
	const [newPointPanelState, setNewPointPanelState] = useState(false)
	const [type, setType] = useState('Conditions')



	const handleGetResults = () => {
		if(type === 'Conditions') {
			dispatch(getConditionByPetId(petId, {}, () => {
			
			}))

		} else if (type === 'Surgery') {
			dispatch(getSurgicalHistoryList(petId, () => {}))

		} else if (type === 'Vaccinations') {

		}
	}



	const handleAddPoint = (data, callback) => {
		let info = {pet_id: petId}
		if(type === 'Conditions'){
			dispatch(addCondition({...data, ...info}, (response) => {
				if (response?.status) { 
					handleGetResults(); 
					setNewPointPanelState(false);
				}
			}))

		} else if (type === 'Surgery') {
			dispatch(addSurgicalHistory({...data, ...info}, (response) => {
				if (response?.status) { 
					handleGetResults(); 
					setNewPointPanelState(false);
				}
			}))

		} else if (type === 'Vaccinations') {

		}
	}


	return (
		<>
			<div className="medical-history-block">
				<div className="row-block">
					<ConditionBlock add={() => {
						setType('Conditions');
						setNewPointPanelState(true);
					}} petId={petId} />
				</div>
				{/* <div className="row-block">
					<div className="breif-medical-rw d-flex flex-wrap">
						<div className="item-col">
							<MedicalBreifBox />
						</div>
						<div className="item-col">
							<MedicalBreifBox />
						</div>
						<div className="item-col">
							<MedicalBreifBox />
						</div>
					</div>
				</div> */}
				<div className="row-block">
					<SurgicalHistoryBlock add={() => {
						setType('Surgery');
						setNewPointPanelState(true);
					}} petId={petId} />
				</div>
				
				<div className="row-block">
					<VaccinationBlock petId={petId} />
				</div>
			</div>


			<div className={`new-point-panel-overlay position-fixed ${newPointPanelState ? 'active' : ''}`}></div>
			<NewPointPanel type={type} closeNewPointPanel={() => {setNewPointPanelState(false)}} refresh={handleGetResults} addPoint={handleAddPoint} className={`${newPointPanelState ? 'active' : ''}`} />
	


		</>
	);
};

export default MedicalHistory;
