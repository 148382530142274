import React, { useCallback, useEffect, useState } from "react";
import { Button } from "reactstrap";
import BackButton from "../../../shared/BackButton";
import SearchBlock2 from "../../../shared/SearchBlock2";
import ProductsList from "./ProductsList";
import GeneralInfoBlock from "./GeneralInfoBlock";
import "./index.scss";
import CostBlock from "./CostBlock";
import ProductAccordion from "./ProductAccordion";
import ProductOrderBlock from "./ProductOrderBlock";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoaderUI from "../../../shared/loader";
import NodataFoundBlock from "../../../shared/NoDataFoundBlock";
import {
  getAndSearchProductList,
  getInventoryDetailsById,
} from "../../../context/actions/inventory";
import { IoIosArrowBack } from "react-icons/io";

//backdrop
const BackDrop = (props) => {
  return <div className="backdrop" onClick={props.closeDrawer}></div>;
};

const ProductsDetail = () => {
  const history = useHistory()
  const [drawerOpen, setDrawerOpen] = useState(false);
  const params = useParams();
  const inventoryId = params?.inventoryId;
  const dispatch = useDispatch();
  const [show, setShow] = useState(false)
  let drawerClasses = show ? "side-drawer me-0 open" : "me-0 side-drawer";

  const { inventoryDetails, productList } = useSelector(
    (state) => state.inventoryReducer
  );
  const [loading, setLoading] = useState(true);

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  function handleOpenDrawerButton() {
    setDrawerOpen(!drawerOpen);
    setShow(true)
  }
  function handleBackdropClick() {
    setDrawerOpen(false);
  }

  useEffect(() => {
    handleGetInventoryDetails();
  }, [inventoryId]);

  useEffect(() => {
    handleGetAndSearchProductList();
  }, []);

  const handleGetInventoryDetails = () => {
    dispatch(
      getInventoryDetailsById(inventoryId, {}, (res) => {
        // console.log("detail",res,inventoryDetails,productList)
        setLoading(false);
      })
    );
  };

  const handleGetAndSearchProductList = (e) => {
    let text = e?.target?.value;
    dispatch(getAndSearchProductList({ searchText: text }, () => { }));
  };

  if (loading) {
    return <LoaderUI />;
  }

  return (
    <>
      {!inventoryDetails ? (
        <NodataFoundBlock />
      ) : (
        <>
          <div className="pl-4">
            <p
              onClick={() => {
                history.goBack();
              }}
              className="flex items-center cursor-pointer relative left-[-13px] pt-4"
            >
              <span className="pr-2">
                <IoIosArrowBack size={20} color="#3D3EFD" />
              </span>{" "}
              <span className="font-['Roboto'] text-[#3D3EFD] font-[500] uppercase">
                Back to List
              </span>
            </p>

          </div>

          <div className="d-block d-xxl-flex d-xl-flex d-lg-flex d-md-flex  pl-4 pt-2 pr-4 justify-between align-items-center">
            <p className="font-['Inter']  flex flex-col mb-2"><span className="font-[700]"></span>
            </p>
            {permissions?.edit_products &&
              <button onClick={handleOpenDrawerButton} className="mb-2 px-3.5 py-2 font-['Inter'] text-[18px] font-[500] text-white bg-[#11CA9D] rounded-lg">Products</button>
            }
          </div>
          {drawerOpen ?
            <div className="product-detail-wrapper position-relative d-flex flex-fill overflow-auto">
              <div className={show ? drawerClasses + " " + "!w-[400px] !pt-[20px] left-col d-flex flex-column overflow-auto flex-shrink-0 overflow-auto" : "left-col d-flex flex-column overflow-auto flex-shrink-0 overflow-auto"}>
                <div className="head d-flex flex-column">
                  <BackButton label="Back to List" />
                  <h2 className="sidebar-ttl">All Products</h2>
                  <SearchBlock2 placeholder="Search" onChange={handleGetAndSearchProductList} />
                  <Button color="link" className="add-product-btn radius d-flex align-items-center justify-content-center">
                    <span className="material-icons">
                      add_circle
                    </span>
                  </Button>
                </div>
                <div className="inner d-flex flex-column flex-fill overflow-auto">
                  <ProductsList inventoryId={inventoryId} productList={productList} />
                </div>
              </div>
            </div>
            : ""}
          {drawerOpen && <BackDrop closeDrawer={handleBackdropClick} />}
          <div className="product-detail-wrapper position-relative d-flex flex-fill overflow-auto">
            {/* comment actual code  */}
            {/* <div className="left-col d-flex flex-column overflow-auto flex-shrink-0 overflow-auto">
						<div className="head d-flex flex-column">
							<BackButton label="Back to List" />
							<h2 className="sidebar-ttl">All Products</h2>
							<SearchBlock2 placeholder="Search" onChange={handleGetAndSearchProductList} />
							<Button color="link" className="add-product-btn radius d-flex align-items-center justify-content-center">
								<span className="material-icons">
									add_circle
								</span>
							</Button>
						</div>
						<div className="inner d-flex flex-column flex-fill overflow-auto">
							<ProductsList inventoryId={inventoryId} productList={productList} />
						</div>
					</div> */}

            <div className="mid-col flex-fill overflow-auto">
              <div className="row-block">
                <GeneralInfoBlock />
              </div>
              <div className="row-block border-0">
                <ProductAccordion />
              </div>
            </div>
            {/*
            <div className="right-col flex-shrink-0 d-flex flex-column overflow-auto">
              <ProductOrderBlock />
            </div>*/}
          </div>
        </>
      )}
    </>
  );
};

export default ProductsDetail;
