import Suppliers from "components/inventory-module/Suppliers";
import { getAllInventoryCategories } from "context/actions/app-content";
import {
  getSuppliersByClinicId,
  updateInventory,
  updateInventoryDetails,
} from "context/actions/inventory";
import { Formik } from "formik";

import React, { useEffect, useRef, useState } from "react";
import { MdCancel } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Input, InputGroup } from "reactstrap";
import TextBoxInput from "shared/FormsInput/TextBoxInput";
import LoaderUI from "shared/loader";
import { Icons } from "utils/contants/Icons";
import { lazy } from "yup";
import { object, string, boolean, number } from "yup";

let productInfoSchema = object({
  product_name: string().required(),
  manufacture: string().required(),
  sku: string().required(),
  category_id: string().required(),
  inventory_image: string().nullable().optional(),
  unit_of_measure: string().required(),
  //upc: '',
  sub_package: object({
    unit_of_measure: lazy((i, o) => {
      const { unit_of_measure } = o?.context;
      if (
        (unit_of_measure === "tablet" || unit_of_measure === "patch",
        unit_of_measure === "capsule",
        unit_of_measure === "vial")
      ) {
        return string().required(
          "Sub package is required when unit is tablet, vial, patch, or capsule"
        );
      } else {
        return string().optional();
      }
    }),
    amount: lazy((i, o) => {
      const { unit_of_measure } = o?.context;
      if (
        (unit_of_measure === "tablet" || unit_of_measure === "patch",
        unit_of_measure === "capsule",
        unit_of_measure === "vial")
      ) {
        return number().required(
          "Sub package is required when unit is tablet, vial, patch, or capsule"
        );
      } else {
        return number().optional();
      }
    }),
  }),
  description: string().optional(),
  vettech_scanout: boolean().required(),
  doctor_scanout: boolean().required(),
  // createdOn: date().default(() => new Date()),
});

const EditProductInventory = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const formRef = useRef();

  const [isImgSrc, setisImgSrc] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.user_details);
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const { inventoryCategories } = useSelector(
    (state) => state.appContentReducer
  );
  const { singleProductInInventory } = useSelector(
    (state) => state.inventoryReducer
  );
  const history = useHistory();
  const {
    _id,
    supplier_id,
    product_name,
    manufacture,
    sku,
    category_id,
    unit_of_measure,
    description,
    doctor_scanout,
    vettech_scanout,
    product_cost,
    taxes,
    discount,
    sub_package,
    special_notes,
  } = singleProductInInventory || {};

  const initialValues = {
    // clinic_id: clinic_settings.clinic_id,
    supplier_id: supplier_id || "",
    product_name: product_name || "",
    manufacture: manufacture || "",
    sku: sku || "",
    category_id: category_id || "",
    unit_of_measure: unit_of_measure || undefined,
    //upc: '',
    sub_package: {
      unit_of_measure: sub_package?.unit_of_measure || undefined,
      amount: sub_package?.amount || undefined,
    },
    description: description || "",
    // inventory_image: "",
    doctor_scanout: doctor_scanout || false,
    vettech_scanout: vettech_scanout || false,
    image: isImgSrc ? isImgSrc : "",
    product_cost: product_cost || "",
    taxes: taxes || "",
    discount: discount || "",
    usage_direction: special_notes[0]?.usage_direction || "",
    emergency_usage: special_notes[0]?.emergency_usage || "",
  };

  const validationSchema = productInfoSchema;
  const handleGetSuppliers = () => {
    dispatch(
      getSuppliersByClinicId(clinic_settings?.clinic_id, {}, (res) => {
        setSuppliers(res?.response_data?.docs);
      })
    );
  };
  useEffect(() => {
    handleGetSuppliers();
    dispatch(
      getAllInventoryCategories((res) => {
        if (res?.status) {
        }
      })
    );
  }, []);

  const handleUpdate = async (values) => {
    const newPayload = {
      ...values,
      inventory_id: _id,
      special_notes: [
        {
          usage_direction: values.usage_direction || "",
          emergency_usage: values.emergency_usage || "",
        },
      ],
    };
    const { usage_direction, emergency_usage, ...updatedPayload } = newPayload;
    setBtnLoading(true);
    dispatch(
      updateInventoryDetails(updatedPayload, (res) => {
        if (res?.status) {
          if (formRef?.current) {
            formRef?.current?.resetForm();
          }
          history.push("/inventory");
          dispatch({ type: "Single_Product_Inventory", payload: {} });
        }
      })
    );
    setBtnLoading(false);
  };
  return (
    <div id="edit-product-inventory-container" className="cp-10">
      <div
        className="text-15-500 color-3EFD pointer"
        onClick={() => {
          history.push("/inventory");
          dispatch({ type: "Single_Product_Inventory", payload: {} });
        }}
      >
        Back{" "}
      </div>

      <div className="text-16-500 color-0000 cmt-10">
        Edit Product in Inventory
      </div>

      <div className="cmt-20 bg-f8f8">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleUpdate}
          innerRef={formRef}
        >
          {(props) => {
            const {
              values,
              errors,
              touched,
              setFieldValue,
              getFieldProps,
              handleSubmit,
            } = props;
            return (
              <form>
                <div className=" p-3">
                  {/* <p className="font-['Inter'] text-[#282E65] font-[700] mb-2">
                    Product Information
                  </p> */}

                  <div className="flex  justify-between items-baseline cmt-20">
                    <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
                      Supplier{" "}
                      <span className="text-[#282E65] font-['Inter'] text-[18px]">
                        *
                      </span>
                    </p>

                    <Input
                      defaultValue=""
                      style={{
                        textTransform: "capitalize",
                        fontSize: "0.875rem",
                        lineHeight: "1.25rem",
                      }}
                      type="select"
                      className="w-[81%]"
                      value={values?.supplier_id}
                      placeholder="Please Select A Supplier"
                      onChange={(e) => {
                        setFieldValue("supplier_id", e.target.value);
                      }}
                    >
                      <option value="" hidden>
                        Select a Supplier
                      </option>
                      {suppliers.map((data) => {
                        return (
                          <option value={data._id}>{data.supplier_name}</option>
                        );
                      })}
                    </Input>
                  </div>
                  <div className="flex justify-between  items-center cmt-20">
                    <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
                      Product Name{" "}
                      <span className="text-[#282E65] font-['Inter'] text-[18px]">
                        *
                      </span>
                    </p>
                    <input
                      type="text"
                      id="first_name"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5 "
                      placeholder="Enter Product Name"
                      required
                      value={values?.product_name}
                      {...getFieldProps("product_name")}
                    />
                  </div>

                  <div className="flex justify-end ">
                    {touched.product_name && errors.product_name ? (
                      <p className="text-[#dc3545] font-['Inter'] text-[12px]">
                        Please Enter a Product Name
                      </p>
                    ) : null}
                  </div>
                  <div className="flex justify-between  items-center cmt-20">
                    <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
                      Manufacturer Info{" "}
                      <span className="text-[#282E65] font-['Inter'] text-[18px]">
                        *
                      </span>
                    </p>
                    <input
                      type="text"
                      id="first_name"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5 "
                      placeholder="Enter Manufacturer Info."
                      required
                      {...getFieldProps("manufacture")}
                    />
                  </div>
                  <div className="flex justify-end">
                    {touched.manufacture && errors.manufacture ? (
                      <p className="text-[#dc3545] font-['Inter'] text-[12px]">
                        Please Enter Manufacture Information
                      </p>
                    ) : null}
                  </div>
                  <div className="flex justify-between  items-center cmt-20">
                    <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
                      SKU{" "}
                      <span className="text-[#282E65] font-['Inter'] text-[18px]">
                        *
                      </span>
                    </p>
                    <input
                      type="text"
                      id="first_name"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5 "
                      placeholder="Enter SKU"
                      required
                      {...getFieldProps("sku")}
                    />
                  </div>
                  <div className="flex justify-end">
                    {touched.sku && errors.sku ? (
                      <p className="text-[#dc3545] font-['Inter'] text-[12px]">
                        Please Enter a SKU
                      </p>
                    ) : null}
                  </div>

                  <div className="flex  justify-between items-baseline cmt-20">
                    <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
                      Category{" "}
                      <span className="text-[#282E65] font-['Inter'] text-[18px]">
                        *
                      </span>
                    </p>
                    <Input
                      type={"select"}
                      id="countries"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5 "
                      name="category_id"
                      style={{
                        textTransform: "capitalize",
                        fontSize: "0.875rem",
                        lineHeight: "1.25rem",
                      }}
                      {...getFieldProps("category_id")}
                    >
                      <option selected className="font-['Inter']">
                        Select a category
                      </option>
                      {(inventoryCategories || []).map((item, i) => (
                        <option key={i} value={item._id}>
                          {item?.category}
                        </option>
                      ))}
                    </Input>
                  </div>
                  <div className="flex justify-end">
                    {touched.category_id && errors.category_id ? (
                      <p className="text-[#dc3545] font-['Inter'] text-[12px]">
                        Please Select a Catgory
                      </p>
                    ) : null}
                  </div>
                  {/* for uint of measure */}
                  <div className="flex  justify-between items-baseline cmt-20">
                    <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
                      Unit of measure
                      <span className="text-[#282E65] font-['Inter'] text-[18px]">
                        *
                      </span>
                    </p>
                    <Input
                      type="select"
                      id="countries"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5 "
                      name="unit_of_measure"
                      style={{ fontSize: "0.875rem", lineHeight: "1.25rem" }}
                      {...getFieldProps("unit_of_measure")}
                    >
                      <option
                        selected
                        className="font-['Inter']"
                        value="undefined"
                        disabled
                      >
                        Select A Unit
                      </option>
                      <option value="mL">mL</option>
                      <option value="Vial">Vial</option>
                      <option value="Item">Item</option>
                      <option value="%">%</option>



                      <option value="L">L</option>
                      <option value="tablet">tablet</option>
                      <option value="capsule">capsule</option>
                      <option value="mg">mg</option>
                      <option value="g">g</option>
                      <option value="patch">patch</option>
                      <option value="spray">spray</option>
                      <option value="drop">drop</option>
                    </Input>
                  </div>
                  <div className="flex justify-end w-[33%]">
                    {touched.unit_of_measure && errors.unit_of_measure ? (
                      <p className="text-[#dc3545] font-['Inter'] text-[12px]">
                        Please select a unit
                      </p>
                    ) : null}
                  </div>
                  {/* for subunits */}
                  {(values?.unit_of_measure === "vial" ||
                    values?.unit_of_measure === "tablet" ||
                    values?.unit_of_measure === "capsule" ||
                    values?.unit_of_measure === "patch") && (
                    <>
                      <div className="flex  justify-between items-baseline cmt-20">
                        <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
                          Sub Package
                          <span className="text-[#282E65] font-['Inter'] text-[18px]">
                            *
                          </span>
                        </p>
                        <InputGroup style={{ width: "81%" }}>
                          <Input
                            style={{
                              fontSize: "0.875rem",
                              lineHeight: "1.25rem",
                            }}
                            type="text"
                            id="sub_package.amount"
                            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[50%] p-2.5 "
                            placeholder="Enter Amount"
                            required
                            {...getFieldProps("sub_package.amount")}
                          />
                          <Input
                            style={{
                              fontSize: "0.875rem",
                              lineHeight: "1.25rem",
                            }}
                            type="select"
                            id="sub_package.unit_of_measure"
                            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[50%] p-2.5 "
                            name="sub_package.unit_of_measure"
                            {...getFieldProps("sub_package.unit_of_measure")}
                          >
                            <option
                              selected
                              className="font-['Inter']"
                              value="undefined"
                              disabled
                            >
                              Select a Unit
                            </option>
                            <option value="mL">mL</option>
                            <option value="L">L</option>
                            <option value="tablet">tablet</option>
                            <option value="capsule">capsule</option>
                            <option value="mg">mg</option>
                            <option value="g">g</option>
                            <option value="patch">patch</option>
                            <option value="spray">spray</option>
                            <option value="drop">drop</option>
                          </Input>
                        </InputGroup>
                      </div>
                      <div className="flex justify-end w-[33%]">
                        {(touched.sub_package?.unit_of_measure &&
                          errors.sub_package?.unit_of_measure) ||
                        (touched.sub_package?.amount &&
                          errors.sub_package?.amount) ? (
                          <p className="text-[#dc3545] font-['Inter'] text-[12px]">
                            Please fill this field
                          </p>
                        ) : null}
                      </div>
                    </>
                  )}
                  <div className="flex justify-between  cmt-20 ">
                    <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
                      Description
                    </p>
                    <textarea
                      id="message"
                      rows="4"
                      className="block p-2.5 w-[81%] text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
                      placeholder="Write Description Here..."
                      {...getFieldProps("description")}
                    ></textarea>
                  </div>
                  <div className="flex justify-between cmt-20">
                    <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
                      Product Image{" "}
                    </p>

                    <div className="d-flex flex-row p-2.5 w-[81%] ">
                      <img
                        className="w-[120px] h-[120px] rounded-[8px]"
                        src={isImgSrc ? isImgSrc : Icons?.productPlaceholder}
                        alt="product"
                      />

                      <input
                        className="text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
                        id="file_input"
                        type="file"
                        onChange={(e) => {
                          setFieldValue("inventory_image", e.target.files[0]);
                          localStorage.setItem(
                            "inventory_image",
                            e.target.files[0]
                          );
                          let reader = new FileReader();

                          reader.onload = function () {
                            setisImgSrc(reader.result);
                          };
                          reader.readAsDataURL(e.target.files[0]);
                        }}
                      />

                      <div className="absolute pt-[5px] left-[125px]">
                        {isImgSrc && (
                          <button
                            className=""
                            onClick={() => {
                              setisImgSrc(false);
                            }}
                          >
                            <MdCancel size={20} color="#11CA9D" />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex flkex-row w-[100%] justify-end">
                    <div className="flex flex-col  border border-gray-200 rounded dark:border-gray-700 w-[81%]  bg-[#fff]">
                      <div className="flex items-center pl-4">
                        <input
                          id="doctor_scanout"
                          type="checkbox"
                          value={values?.doctor_scanout}
                          checked={values?.doctor_scanout}
                          name="doctor_scanout"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                          {...getFieldProps("doctor_scanout")}
                        />
                        <label
                          htmlFor="doctor_scanout"
                          className="w-full py-3 ml-2 text-[13px] font-['Inter'] text-[#282E65] font-[600] "
                        >
                          Is Doctor scan out?{" "}
                          <span className="text-[#282E65] font-['Inter'] text-[18px]">
                            *
                          </span>
                        </label>
                      </div>

                      <div className="flex items-center pl-4">
                        <input
                          id="vettech_scanout"
                          type="checkbox"
                          value={values?.vettech_scanout}
                          checked={values?.vettech_scanout}
                          name="vettech_scanout"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                          {...getFieldProps("vettech_scanout")}
                        />
                        <label
                          htmlFor="vettech_scanout"
                          className="w-full py-2 ml-2 text-[13px] font-['Inter'] text-[#282E65] font-[600] "
                        >
                          Is Vettech scan out?{" "}
                          <span className="text-[#282E65] font-['Inter'] text-[18px]">
                            *
                          </span>
                        </label>
                      </div>
                      <div className="flex justify-end w-[33%]">
                        {(touched.vettech_scanout || touched.doctor_scanout) &&
                        (errors.vettech_scanout || errors.doctor_scanout) ? (
                          <p className="text-[#dc3545] font-['Inter'] text-[12px]">
                            Please check this field
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between  items-center cmt-20">
                    <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
                      Product Cost ($)
                      <span className="text-[#282E65] font-['Inter'] text-[18px]">
                        *
                      </span>
                    </p>
                    <input
                      type="text"
                      id="first_name"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5 "
                      placeholder="Enter Product Cost"
                      required
                      {...getFieldProps("product_cost")}
                    />
                  </div>
                  <div className="flex justify-between  items-center cmt-20">
                    <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
                      Taxes (%)
                    </p>
                    <input
                      type="text"
                      id="first_name"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5 "
                      placeholder="Enter Tax Percentage"
                      required
                      {...getFieldProps("taxes")}
                    />
                  </div>
                  <div className="flex justify-between  items-center cmt-20">
                    <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
                      Discount (%)
                    </p>
                    <input
                      type="text"
                      id="first_name"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5 "
                      placeholder="Enter Discount"
                      required
                      {...getFieldProps("discount")}
                    />
                  </div>

                  <div className="flex justify-between cmt-20">
                    <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
                      Usage Directions
                    </p>
                    <textarea
                      id="message"
                      rows="4"
                      class="block p-2.5 w-[81%] text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
                      placeholder="Write Directions here..."
                      {...getFieldProps("usage_direction")}
                    ></textarea>
                  </div>
                  <div className="flex justify-between cmt-20">
                    <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
                      Emergency Usage
                    </p>
                    <textarea
                      id="message"
                      rows="4"
                      class="block p-2.5 w-[81%] text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
                      placeholder="Write Description here..."
                      {...getFieldProps("emergency_usage")}
                    ></textarea>
                  </div>

                  <div className="d-flex flex-row justify-end cmt-20">
                    <button
                      type="button"
                      className="px-4 py-2 position bottom-[39px] text-[#11CA9D] font-['Inter']"
                      onClick={() => {
                        history.push("/inventory");
                        dispatch({
                          type: "Single_Product_Inventory",
                          payload: {},
                        });
                      }}
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="bg-[#11CA9D] px-3.5 py-2 position bottom-[39px] rounded-lg text-[#fff] font-['Inter']"
                    >
                      Update & Proceed
                      {btnLoading && <LoaderUI />}
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default EditProductInventory;
