import React from "react";
import { Button, Input } from "reactstrap"; // eslint-disable-line

const TableListFilterBlock = ({ onSearch = () => {},searchText }) => {
  return (
    <>
      <div className="table-list-filter-blc d-flex align-items-center">
        <div className="search-cell col-cell !w-40 position-relative">
          <span className="icomoon-search d-inline-flex align-items-center justify-content-center position-absolute"></span>
          <Input placeholder="Search" onChange={onSearch} className="w-100" value={searchText}/>
        </div>
        {/* <div className="input-cell col-cell">
          <Input type="select" name="select" className="w-100">
            <option>All Cities</option>
            <option>NewYork</option>
            <option>Londan</option>
            <option>Peris</option>
            <option>Delhi</option>
          </Input>
        </div> */}
      </div>
    </>
  );
};

export default TableListFilterBlock;
