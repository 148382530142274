import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Input } from "reactstrap";
import { addNewSkill, deleteSkill } from "../../../../context/actions/userSkill";
import "./index.scss";

const SkillItemBlock = ({ userId, allSkills, skillUpdated }) => {
	const dispatch = useDispatch();
	const [skillValue, setSkillValue] = useState('');

	const handleDeleteSkill = (skillId) => {
		dispatch(deleteSkill(skillId, {}, (result) => {
			if (result && !result.errors) {
				skillUpdated(true)
			} else {
				// console.log("error ", result)
			}
		}))
	}

	const handleAddSkill = () => {
		if (skillValue) {
			const data = {
				skill: skillValue,
				user: userId
			}
			dispatch(addNewSkill(data, (result) => {
				if (result && !result.errors) {
					setSkillValue('')
					skillUpdated(true)
				} else {
					// console.log("error ", result)
				}
			}))
		}
	}
	return (
		<>
			<div className="skill-item-blc">
				<div className="input-group search-cell">
					<Input type="text" placeholder="Add New Skill/Speciality" value={skillValue} onChange={(e) => setSkillValue(e.target.value)} />
					<Button className="input-group-text shadow-none d-inline-flex align-items-center justify-content-center p-0" onClick={handleAddSkill}>
						<i className="icomoon-search"></i>
					</Button>
				</div>
				<div className="chip-items-list">
					{
						allSkills.map((skill, index) => (
							<span className="chip-item d-inline-flex align-items-center text-center" key={index}>
								{skill?.skill}
								<Button color="link" className="d-inline-flex align-items-center justify-content-center" onClick={() => handleDeleteSkill(skill._id)}>
									<i className="icomoon-cross"></i>
								</Button>
							</span>
						))
					}
				</div>
			</div>
		</>
	);
};

export default SkillItemBlock;
