import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { sidebarPanelAction } from "../../context/actions/sidebarPanelAction";
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Patients from "./Patients";
import { getClinicSettings } from "context/actions/clinicSettings";
import { getClinicDetailsByUserId } from "context/actions/clinic";
//import PetOwners from "./PetOwners";

export default function PatientModule() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(0);
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );
  const routeMapping = {
    0: "/patients/add-patient",
    1: "/patients/add-pet-owner",
  };

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  const sidebarPanelState = useSelector(
    (state) => state.sidebarPanelReducer.isSidePanel
  );
  const showSidePanel = () => {
    dispatch(sidebarPanelAction(!sidebarPanelState));
  };

  // useEffect(() => {
  //   // console.log("clinic_details",clinic_settings)
  //   if (!clinic_settings?._id || !clinic_details?._id) {

  //     console.log("clinic_settings  called by API ")
  //     dispatch(getClinicDetailsByUserId({}, response => {
  //       if (response?.status) {
  //         dispatch(getClinicSettings({}, () => { }));
  //       }
  //     }))
  //   }
  // }, []);

  return (
    <>
      {/*
      <div className="flex justify-between items-end px-1 border-b py-2">
        <div className="d-flex flex-row">

        <Button
          className="sidebar-toggle-btn d-inline-flex align-items-center"
          color="link"
          onClick={showSidePanel}>
          <span className="material-symbols-outlined">
            menu
          </span>
        </Button>
        <Nav>
          <NavItem>
            <NavLink
              className={
                activeTab === 0
                  ? "text-[#3D3EFD] relative after:content-[''] after:absolute after:left-4 after:rounded-full after:-bottom-2.5 after:w-5 after:border-2 after:border-[#3D3EFD] "
                  : "hover:text-[#3D3EFD] text-black"
              }
              onClick={() => handleTabChange(0)}
            >
              Pets
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={
                activeTab === 1
                  ? "text-[#3D3EFD] relative after:content-[''] after:absolute after:left-4 after:rounded-full after:-bottom-2.5 after:w-6 after:border-2 after:border-[#3D3EFD] "
                  : "hover:text-[#3D3EFD] text-black"
              }
              onClick={() => handleTabChange(1)}
            >
              Pet Owners
            </NavLink>
          </NavItem>
        </Nav>
        </div>
        {activeTab === 0 &&
        <Button
          color="success"
          className="add-btn"
          onClick={() => {
            history.push(routeMapping[activeTab]);
          }}
        >
          Add new
        </Button>}
      </div>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={0}>
          <Patients key="patients" />
        </TabPane>
        <TabPane tabId={1}>
          <PetOwners key="petowners" />
        </TabPane>
      </TabContent>
      */}
      <Patients />
    </>
  );
}
