import React, { useEffect, useState } from "react";
import { Button } from 'reactstrap';
import GeneralInfoBlock from "./GeneralInfoBlock";
import SkillItemBlock from "./SkillItemBlock";
import EducationInfoBlock from './EducationInfoBlock';
import DocumentFilesBlock from "./DocumentFilesBlock";
import AddressBlock from './AddressBlock'
import "./index.scss";
import { useDispatch } from "react-redux";
import { checkEmergencyContact, getAllContactsInfo } from "../../../context/actions/userContact";
import { checkEducationCertificate, getAllEducationsDetail } from "../../../context/actions/userEducation";
import { getAllExperiencesDetail } from "../../../context/actions/userExperience";
import ExperienceInfoBlock from "./ExperienceInfoBlock";
import { getAllDocumentsDetail } from "../../../context/actions/userDocument";
import { getAllSkills } from "../../../context/actions/userSkill";

const Overview = ({ userGeneralInfo }) => {
	const dispatch = useDispatch();
	const [allContactsInfo, setAllContactsInfo] = useState(false);
	const [hasEmergencyContact, setHasEmergencyContact] = useState(false);
	const [allSkills, setAllSkills] = useState(false);
	const [allEducationsDetail, setAllEducationsDetail] = useState(false);
	const [hasEducationCertificate, setHasEducationCertificate] = useState(false);
	const [allExperiencesDetail, setAllExperiencesDetail] = useState(false);
	const [allDocumentsDetail, setAllDocumentsDetail] = useState(false);

	const handleGetAllContactsInfo = () => {
		dispatch(getAllContactsInfo(userGeneralInfo._id, {}, (result) => {
			if (result && !result.errors) {
				setAllContactsInfo(result)
			} else {
				setAllContactsInfo(false)
			}
		}))
	}

	const handleCheckEmergencyContact = () => {
		dispatch(checkEmergencyContact(userGeneralInfo._id, {}, (result) => {
			if (result && !result.errors) {
				setHasEmergencyContact(result)
			} else {
				setHasEmergencyContact(false)
			}
		}))
	}

	const handleGetAllSkills = () => {
		dispatch(getAllSkills(userGeneralInfo._id, {}, (result) => {
			if (result && !result.errors) {
				setAllSkills(result)
			} else {
				setAllSkills(false)
			}
		}))
	}

	const handleGetAllEducationsDetail = () => {
		dispatch(getAllEducationsDetail(userGeneralInfo._id, {}, (result) => {
			if (result && !result.errors) {
				setAllEducationsDetail(result)
			} else {
				setAllEducationsDetail(false)
			}
		}))
	}

	const handleCheckEducationCertificate = () => {
		dispatch(checkEducationCertificate(userGeneralInfo._id, {}, (result) => {
			if (result && !result.errors) {
				setHasEducationCertificate(result)
			} else {
				setHasEducationCertificate(false)
			}
		}))
	}

	const handleGetAllExperiencesDetail = () => {
		dispatch(getAllExperiencesDetail(userGeneralInfo._id, {}, (result) => {
			if (result && !result.errors) {
				setAllExperiencesDetail(result)
			} else {
				setAllExperiencesDetail(false)
			}
		}))
	}

	const handleGetAllDocumentsDetail = () => {
		dispatch(getAllDocumentsDetail(userGeneralInfo._id, {}, (result) => {
			if (result && !result.errors) {
				setAllDocumentsDetail(result)
			} else {
				setAllDocumentsDetail(false)
			}
		}))
	}

	useEffect(() => {
		handleGetAllContactsInfo();
		handleCheckEmergencyContact();
		handleGetAllSkills();
		handleGetAllEducationsDetail();
		handleCheckEducationCertificate()
		handleGetAllExperiencesDetail();
		handleGetAllDocumentsDetail();
	}, [])
	return (
		<>
			<div className="user-overview-tab">
				<div className="tab-row d-flex">
					<div className="tab-col lft">
						<div className="info-block">
							<h3>General Information</h3>
						</div>
						{/*}
						<div className="msg-rw d-flex align-items-center">
							<span className="icomoon-alert"></span>
							Domian Name Is Required <Button color="link">Verify</Button>
	</div>*/}
						{
							!hasEmergencyContact && <div className="msg-rw d-flex align-items-center">
								<span className="icomoon-alert"></span>
								Emergency Contact <Button color="link">Add</Button>
							</div>
						}
					</div>
					<div className="tab-col flex-fill">
						<GeneralInfoBlock className="responsive" userGeneralInfo={userGeneralInfo} />
					</div>
				</div>
				<div className="tab-row d-flex">
					<div className="tab-col lft skills">
						<div className="info-block">
							<h3>Skills/Specialities</h3>
						</div>
					</div>
					<div className="tab-col flex-fill">
						{
							allSkills && <SkillItemBlock
								userId={userGeneralInfo._id}
								allSkills={allSkills}
								skillUpdated={(value) => handleGetAllSkills()} />
						}
					</div>
				</div>
				<div className="tab-row d-flex">
					<div className="tab-col lft">
						<div className="info-block">
							<h3>Contact Information</h3>
						</div>

						{
							allContactsInfo && allContactsInfo.length > 0 ? (
								!allContactsInfo[0].email && <div className="msg-rw d-flex align-items-center">
									<span className="icomoon-alert"></span>
									Email Address Is Required <Button color="link">Verify</Button>
								</div>
							) : '-'
						}
					</div>
					<div className="tab-col flex-fill">
						<AddressBlock className="responsive" allContactsInfo={allContactsInfo} />
					</div>
				</div>
				{/*
				<div className="tab-row d-flex">
					<div className="tab-col lft">
						<div className="info-block">
							<h3>Education</h3>
							{
								!hasEducationCertificate && <div className="msg-rw d-flex align-items-center">
									<span className="icomoon-alert"></span>
									Add atleast 1 certification<Button color="link">Add</Button>
								</div>
							}
						</div>
					</div>
					<div className="tab-col flex-fill">
						{
							allEducationsDetail && <EducationInfoBlock allEducationsDetail={allEducationsDetail} />
						}
					</div>
				</div>
				{
					allExperiencesDetail && <ExperienceInfoBlock allExperiencesDetail={allExperiencesDetail} />
				}
				<div className="tab-row d-flex">
					<div className="tab-col lft">
						<div className="info-block">
							<h3>Documents</h3>
							{
								allDocumentsDetail.length < 1 && <div className="msg-rw d-flex align-items-center">
									<span className="icomoon-alert"></span>
									Add atleast 1 certification<Button color="link">Add</Button>
								</div>
							}
						</div>
					</div>
					<div className="tab-col flex-fill">
						{
							allDocumentsDetail && <DocumentFilesBlock className="responsive" allDocumentsDetail={allDocumentsDetail} />
						}
					</div>
				</div>*/}
			</div>
		</>
	);
};

export default Overview;
