import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";

toast.configure();

export const addBundle = (data, callback) => {
  return (dispatch, getState) => {
    

    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    ApiClient
      .postFormData(`${apiUrl}${PORT}/bundles/add`, data, token, dispatch, getState)
      .then((res) => {
        const responseData = res.data;
        toast.dismiss();
        toast.success(responseData.message, toasterConfig);
        callback(responseData); // Invoke the callback function
      })
      .catch((err) => {
        toast.error(err.message, toasterConfig);
      });
  };
};

export const updateBundle = (data, callback) => {
  return (dispatch, getState) => {
    
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    ApiClient
      .putFormData(`${apiUrl}${PORT}/bundles/update`, data, token, dispatch, getState)
      .then((res) => {
        const responseData = res.data;
        toast.dismiss();
        toast.success(responseData.message, toasterConfig);
        callback(responseData); // Invoke the callback function
      })
      .catch((err) => {
        toast.error(err.message, toasterConfig);
      });
  };
};

export const getBundlesByClinicIdWithPagination = (clinic_id, params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      ApiClient.get(
        `${apiUrl}${PORT}/bundles/get-all-with-pagination/${clinic_id}`,
        params,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response && response.status) {

          dispatch({
            type: "GET_BUNDLES",
            payload: response.response_data,
          });
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(false);
        }
      });
    }
    // else {
    //   return callback(null);
    // }
  };
};

export const getBundleDetails = (bundle_id, callback) => {
    return (dispatch, getState) => {
      const {
        user: { token },
        clinicReducer: { clinic_details },
      } = getState();
      if (clinic_details) {
        ApiClient.get(
          `${apiUrl}${PORT}/bundles/get-bundle-details/${bundle_id}`,
          {},
          token,
          dispatch, getState
        ).then((response) => {
          if (!response) {
            return callback();
          } else if (response && response.status) {
  
            dispatch({
              type: "GET_BUNDLES",
              payload: response.response_data,
            });
            return callback(response);
          } else {
            //toast.error(response.message, toasterConfig);
            return callback(false);
          }
        });
      }
      // else {
      //   return callback(null);
      // }
    };
  };

export const getAllActiveServicesByClinicId = (clinic_id, params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      ApiClient.get(
        `${apiUrl}${PORT}/service/get-all-active-services/${clinic_id}`,
        params,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response) {
          return callback(response);
          // return callback(true);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(false);
        }
      });
    }
    // else {
    //   return callback(null);
    // }
  };
};
