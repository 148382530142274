import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import TreatmentPlanItem from "./TreatmentPlanItem";
import TreatmentPlanAdd from "./TreatmentPlanAdd";
import { getTreatmentByPetId } from "../../../../../../context/actions/patient-module/orders";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import "./index.scss";
import NodataFoundBlock from "shared/NoDataFoundBlock";

const TreatmentPlanBlock = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();

  const params = useParams();
  const petId = params.petId;

  const [treatmentData, setTreatmentData] = useState([]);

  const getPageData = (id) => {
    dispatch(
      getTreatmentByPetId(id, (response) => {
        setTreatmentData(response?.response_data);
      })
    );
  };

  useEffect(() => {
    getPageData(petId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="treatment-block">
        <div className="head section-head d-flex align-items-center">
          <div className="left-col">
            <h3>Treatment Plans</h3>
          </div>
          <div className="right-col ms-auto d-inline-flex align-items-center">
            <Button color="link" className="add-btn" onClick={handleShow}>
              <i className="icomoon-plus-btn"></i>
            </Button>
          </div>
        </div>

        <div className="inner">
          <div className="report-items-rw">
            <div className="item-col-wrapper d-flex flex-wrap">
              {treatmentData && treatmentData?.length > 0 ?
              <>
                {treatmentData?.map((data, i) => {
                  return (
                    <React.Fragment key={i}>
                      <TreatmentPlanItem
                        data={data}
                        getPageData={getPageData}
                        pet_Id={petId}
                      />
                    </React.Fragment>
                  );
                })}
                </>:
                <NodataFoundBlock />}
            </div>
          </div>
        </div>
      </div>

      <TreatmentPlanAdd
        handleClose={handleClose}
        show={show}
        templateOptions={treatmentData?.map((ele) => ({
          value: ele?._id,
          label: ele?.treatmentPlanName,
        }))}
        setTreatmentData={setTreatmentData}
      />
    </>
  );
};

export default TreatmentPlanBlock;
