import React, { useEffect, useState } from "react";
import NodataFoundBlock from "shared/NoDataFoundBlock";
import moment from "moment";
import { titleCaseString } from "utils/common";
import { Icons } from "utils/contants/Icons";
import LoaderUI from "shared/loader";
import DateRange from "shared/FormsInput/DateRange";
import { BsChevronLeft } from "react-icons/bs";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOperatinRecords } from "context/actions/clinic-reports";
import { Table } from "reactstrap";
import "./OperatingRoomReport.scss";
import PaginationBlock from "shared/PaginationBlock";
import PetPlaceholder from "shared/PetPlaceholder";

const OperatingRoomReport = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [limit, setLimit] = useState(5);
  const [surgeriesPage, setSurgeriesPage] = useState(1);
  const [surgeriesPaging, setSurgeriesPaging] = useState(1);
  const [orPage, setOrPage] = useState([]);
  const [orPaging, setOrPaging] = useState([]);
  const [reasonPage, setReasonPage] = useState([]);
  const [reasonPaging, setReasonPaging] = useState([]);
  const [speciesPage, setSpeciesPage] = useState([]);
  const [speciesPaging, setSpeciesPaging] = useState([]);

  const [filterObj, setFilterObj] = useState({
    appointment_start_date: "",
    appointment_end_date: "",
  });
  const [tableData, setTableData] = useState({
    loading: true,
    data: [],
  });
  const fetchData = (obj) => {
    dispatch(
      getOperatinRecords({ clinic_id: clinic_details?._id, ...obj }, (res) => {
        setTableData((prev) => {
          return {
            ...prev,
            data: res,
            loading: false,
          };
        });
      })
    );
  };

  useEffect(() => {
    fetchData(filterObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterObj]);

  useEffect(() => {
    if (tableData?.data?.operatingRoomSpecific) {
      const updatedOrPages = tableData?.data?.operatingRoomSpecific?.map(
        (arr, _) => 1
      );

      setOrPage(updatedOrPages);
      setOrPaging(updatedOrPages);
    }
  }, [tableData?.data?.operatingRoomSpecific]);

  useEffect(() => {
    if (tableData?.data?.reasonSpecific) {
      const updatedOrPages = tableData?.data?.reasonSpecific?.map(
        (arr, _) => 1
      );

      setReasonPage(updatedOrPages);
      setReasonPaging(updatedOrPages);
    }
  }, [tableData?.data?.reasonSpecific]);

  useEffect(() => {
    if (tableData?.data?.speciesSpecific) {
      const updatedOrPages = tableData?.data?.speciesSpecific?.map(
        (arr, _) => 1
      );

      setSpeciesPage(updatedOrPages);
      setSpeciesPaging(updatedOrPages);
    }
  }, [tableData?.data?.speciesSpecific]);

  const surgiesTotalPage = Math.ceil(
    tableData?.data?.surgeries?.length / limit
  );
  const newSurgiesData = tableData?.data?.surgeries?.slice(
    (surgeriesPage - 1) * limit,
    surgeriesPage * limit
  );

  const getPaginatedData = (dataArray, tabIndex) => {
    const startIndex = (orPage[tabIndex] - 1) * limit;
    const endIndex = startIndex + limit;
    return dataArray?.slice(startIndex, endIndex) || [];
  };
  const reasonPaginatedData = (dataArray, tabIndex) => {
    const startIndex = (reasonPage[tabIndex] - 1) * limit;
    const endIndex = startIndex + limit;
    return dataArray?.slice(startIndex, endIndex) || [];
  };
  const getSpeciesPaginateData = (dataArray, tabIndex) => {
    const startIndex = (speciesPage[tabIndex] - 1) * limit;
    const endIndex = startIndex + limit;
    return dataArray?.slice(startIndex, endIndex) || [];
  };

  const handleDownload = () => {
    const csvContent = generateCSV(tableData);
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "Operating Room Report.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const generateCSV = (tdata) => {
    let csv = "";
    //surgeries
    csv += `\n\n Surgeries\nPet Name,DOB,Doctor,Service Type,Status,Surgery Date,Surgery Start,Surgery End,Reason,Note\n`;
    tdata?.data?.surgeries?.forEach((sData) => {
      const {
        patient_id,
        doctor_details,
        appointment_type,
        appointment_status,
        appointment_date,
        appointment_start,
        appointment_end,
        reason,
        notes,
      } = sData;
      const appStartData = sData?.appointment_start
        ? `${moment(appointment_start).format("MM/DD/YYYY")} at ${moment(
            appointment_start
          )
            .utc()
            .format("h:mm:ss a")}`
        : "-";

      const appEndData = sData?.appointment_end
        ? `${moment(appointment_end).format("MM/DD/YYYY")} at ${moment(
            appointment_end
          )
            .utc()
            .format("h:mm:ss a")}`
        : "-";

      csv += `${titleCaseString(patient_id?.pet_name)},${moment(
        patient_id?.dob
      ).format("MM/DD/YYYY")},${titleCaseString(
        `Dr. ${doctor_details?.firstname} ${doctor_details?.lastname}`
      )},${titleCaseString(appointment_type)},${titleCaseString(
        appointment_status
      )},${appointment_date},${appStartData},${appEndData},${titleCaseString(
        reason
      )},${titleCaseString(notes)}\n`;
    });

    //Operating Room's Tables
    tdata?.data?.operatingRoomSpecific?.forEach((oData) => {
      if (oData?.data?.length > 0) {
        csv += `\n\n Operating Room: ${titleCaseString(
          oData?.operating_room_name
        )} (Completed)\nPet Name,DOB,Doctor,Service Type,Status,Surgery Date,Surgery Start,Surgery End,Reason,Note\n`;
        oData?.data?.forEach((o) => {
          const {
            patient_id,
            doctor_details,
            appointment_type,
            appointment_status,
            appointment_date,
            appointment_start,
            appointment_end,
            reason,
            notes,
          } = o;
          const appStartData = o?.appointment_start
            ? `${moment(appointment_start).format("MM/DD/YYYY")} at ${moment(
                appointment_start
              )
                .utc()
                .format("h:mm:ss a")}`
            : "-";

          const appEndData = o?.appointment_end
            ? `${moment(appointment_end).format("MM/DD/YYYY")} at ${moment(
                appointment_end
              )
                .utc()
                .format("h:mm:ss a")}`
            : "-";
          csv += `${titleCaseString(patient_id?.pet_name)},${moment(
            patient_id?.dob
          ).format("MM/DD/YYYY")},${titleCaseString(
            `Dr. ${doctor_details?.firstname} ${doctor_details?.lastname}`
          )},${titleCaseString(appointment_type)},${titleCaseString(
            appointment_status
          )},${appointment_date},${appStartData},${appEndData},${titleCaseString(
            reason
          )},${titleCaseString(notes)}\n`;
        });
      }
    });

    //Reason's Tables
    tdata?.data?.reasonSpecific?.forEach((oData) => {
      if (oData?.data?.length > 0) {
        csv += `\n\n Reason: ${titleCaseString(
          oData?.reason
        )}\nPet Name,DOB,Doctor,Service Type,Status,Surgery Date,Surgery Start,Surgery End,Reason,Note\n`;
        oData?.data?.forEach((o) => {
          const {
            patient_id,
            doctor_details,
            appointment_type,
            appointment_status,
            appointment_date,
            appointment_start,
            appointment_end,
            reason,
            notes,
          } = o;
          const appStartData = o?.appointment_start
            ? `${moment(appointment_start).format("MM/DD/YYYY")} at ${moment(
                appointment_start
              )
                .utc()
                .format("h:mm:ss a")}`
            : "-";

          const appEndData = o?.appointment_end
            ? `${moment(appointment_end).format("MM/DD/YYYY")} at ${moment(
                appointment_end
              )
                .utc()
                .format("h:mm:ss a")}`
            : "-";
          csv += `${titleCaseString(patient_id?.pet_name)},${moment(
            patient_id?.dob
          ).format("MM/DD/YYYY")},${titleCaseString(
            `Dr. ${doctor_details?.firstname} ${doctor_details?.lastname}`
          )},${titleCaseString(appointment_type)},${titleCaseString(
            appointment_status
          )},${appointment_date},${appStartData},${appEndData},${titleCaseString(
            reason
          )},${titleCaseString(notes)}\n`;
        });
      }
    });

    //Species  Tables
    tdata?.data?.speciesSpecific?.forEach((oData) => {
      if (oData?.data?.length > 0) {
        csv += `\n\n Species: ${titleCaseString(
          oData?.species_name
        )}\nPatient,Age Range,DOB,Doctor,Service Type,Status,Surgery Date,Surgery Start,Surgery End,Reason,Note\n`;
        oData?.data?.forEach((o) => {
          const {
            patient_id,
            doctor_details,
            appointment_type,
            appointment_status,
            appointment_date,
            appointment_start,
            appointment_end,
            reason,
            notes,
          } = o;
          const appStartData = o?.appointment_start
            ? `${moment(appointment_start).format("MM/DD/YYYY")} at ${moment(
                appointment_start
              )
                .utc()
                .format("h:mm:ss a")}`
            : "-";

          const appEndData = o?.appointment_end
            ? `${moment(appointment_end).format("MM/DD/YYYY")} at ${moment(
                appointment_end
              )
                .utc()
                .format("h:mm:ss a")}`
            : "-";
          csv += `${titleCaseString(patient_id?.pet_name)},${titleCaseString(
            patient_id?.ageRange
          )},${moment(patient_id?.dob).format("MM/DD/YYYY")},${titleCaseString(
            `Dr. ${doctor_details?.firstname} ${doctor_details?.lastname}`
          )},${titleCaseString(appointment_type)},${titleCaseString(
            appointment_status
          )},${appointment_date},${appStartData},${appEndData},${titleCaseString(
            reason
          )},${titleCaseString(notes)}\n`;
        });
      }
    });
    return csv;
  };

  return (
    <div id="operating-room-report-container">
      <div className="row">
        <div className="col-md-3">
          <div className="">
            <div className="d-flex">
              <div
                className="d-flex gap-2 align-items-center cmb-20 pointer"
                onClick={() => history.push("/reports")}
              >
                <BsChevronLeft color="color-3EFD" />
                <span className="text-13-600 color-3EFD">VIEW ALL REPORTS</span>
              </div>
            </div>

            <div className="bg-filter-class">
              <div className="text-24-700 color-2E65">Configure Report</div>
              <div className="text-14-400 color-8693">
                Select filter and generate report
              </div>
              <div className="filter-block cmt-20 cmb-20">
                {/* <div className="cmb-10">
                  <Input
                    type="select"
                    name="select"
                    value={filterObj?.species_id}
                    placeholder="All Species"
                    onChange={(e) => {
                      setFilterObj((prev) => ({
                        ...prev,
                        species_id: e?.target?.value,
                      }));
                    }}
                  >
                    <option value="">All Species</option>
                    {species?.map((e, i) => {
                      return (
                        <option value={e?._id} key={i} id={e?._id}>
                          {e?.species_name}
                        </option>
                      );
                    })}
                  </Input>
                </div> */}

                <div>
                  <DateRange
                    placeholder="Please select date"
                    id="boarding"
                    name="boarding"
                    startDateProp={startDate}
                    endDateProp={endDate}
                    setStartDateProp={setStartDate}
                    setEndDateProp={setEndDate}
                    onChange={(sDate, eDate) => {
                      setFilterObj((prev) => ({
                        ...prev,
                        appointment_start_date: sDate
                          ? moment(sDate).format("YYYY-MM-DD")
                          : "",
                        appointment_end_date: eDate
                          ? moment(eDate).format("YYYY-MM-DD")
                          : "",
                      }));
                    }}
                    isClearable
                  />
                </div>
              </div>
              <div className="cmb-15">
                {/* <Button className="g-report">Generate Report</Button> */}
                <div
                  className="text-center color-2E65 text-13-600 cmt-20 pointer"
                  onClick={() => {
                    setFilterObj({
                      appointment_start_date: "",
                      appointment_end_date: "",
                    });
                    setStartDate(null);
                    setEndDate(null);
                  }}
                >
                  RESET FILTER
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-9 details-section">
          <div className="cpt-20 cpe-20 cpb-20">
            <div className="d-flex justify-content-between align-items-center cmb-20">
              <div>
                <div className="text-24-700 color-2E65">
                  Operating Room Records
                </div>
                {/* <p className="text-14-400 color-8693 text-wrap">
                  Operating Room Records help track the clinic’s medical
                  supplies, medications, and equipment to ensure that everything
                  is well-stocked and up to date.
                </p> */}
              </div>
              <div className="d-flex gap-2 br-8">
                <div
                  className="cp-8 bg-8C9F28 pointer"
                  onClick={() => {
                    handleDownload();
                  }}
                >
                  <img src={Icons.download} alt="download" />
                </div>
                <div className="cp-8 bg-8C9F28 br-8">
                  <img src={Icons.share} alt="share" />
                </div>
              </div>
            </div>

            <div className="cmb-20 card-block row flex-wrap gy-3">
              {/* <div className="text-15-700">Number of surgeries completed</div> */}
              {tableData?.data?.operatingRoomSpecific?.map((data, index) => {
                return (
                  <div
                    className="col-md-2 col-sm-4 flex-grow-1 avrage-card"
                    key={index}
                  >
                    <div className="items-details-month">
                      <div className="text-13-600 cmb-10">
                        {titleCaseString(data?.operating_room_name)}
                      </div>
                      <div className="text-13-600">
                        Total Surgeries: {data?.surgeries_count}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="table-one cmb-30">
              <div className="text-14-600 color-2E65 cmb-10">
                Surgeries ({tableData?.data?.surgeries?.length})
              </div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Patient</th>
                    <th>DOB</th>
                    <th>Doctor</th>
                    <th>Service Type</th>
                    <th>Status</th>
                    <th>Surgery Date</th>
                    <th>Surgery Start</th>
                    <th>Surgery End</th>
                    <th>Reason</th>
                    <th>Note</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.loading && (
                    <tr className="no-data-rw">
                      <td
                        colSpan="8"
                        className="d-flex justify-content-center cpt-50 cpb-50"
                      >
                        <LoaderUI />
                      </td>
                    </tr>
                  )}

                  {!tableData?.loading && newSurgiesData?.length > 0 ? (
                    newSurgiesData?.map((dataElem, dataIndex) => {
                      const {
                        patient_id,
                        doctor_details,
                        appointment_type,
                        appointment_status,
                        appointment_date,
                        appointment_start,
                        appointment_end,
                        reason,
                        notes,
                      } = dataElem;
                      return (
                        <tr key={dataIndex}>
                          <td className="text-nowrap">
                            <div className="d-flex gap-2 align-items-center">
                              <div
                                className="flex-shrink-0"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                              >
                                {patient_id?.pet_image ? (
                                  <img
                                    className="img-fluid w-100 h-100"
                                    src={patient_id?.pet_image}
                                    alt="Product"
                                    style={{
                                      borderRadius: "8px",
                                      width: '20px',
                                      height: '20px',

                                    }}
                                  />
                                ) : (
                                  <PetPlaceholder />
                                )}
                              </div>
                              <div>{titleCaseString(patient_id?.pet_name)}</div>
                            </div>
                          </td>
                          <td className="text-nowrap">
                            {moment(patient_id?.dob).format("MM/DD/YYYY")}
                          </td>
                          <td className="text-nowrap">
                            {titleCaseString(
                              `Dr. ${doctor_details?.firstname} ${doctor_details?.lastname}`
                            )}
                          </td>
                          <td className="text-nowrap">
                            {titleCaseString(appointment_type)}
                          </td>
                          <td className="text-nowrap">
                            {titleCaseString(appointment_status)}
                          </td>
                          <td className="text-nowrap">{moment(appointment_date, 'DD-MM-YYYY').format('MM/DD/YYYY')}</td>
                          <td className="text-nowrap">
                            
                     
                              {dataElem?.appointment_start
                                ? moment(appointment_start).format("MM/DD/YYYY [at] h:mm:ss a")
                                : "-"}
                             
                          </td>
                          <td className="text-nowrap">
                            
                          {dataElem?.appointment_end
                                ? moment(appointment_end).format("MM/DD/YYYY [at] h:mm:ss a")
                                : "-"}
                          </td>
                          <td className="text-nowrap">
                            {titleCaseString(reason)}
                          </td>
                          <td className="text-nowrap">
                            {titleCaseString(notes)}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="no-data-rw">
                      <td colSpan="11">
                        <NodataFoundBlock />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {tableData?.data?.surgeries?.length > 0 && (
                <PaginationBlock
                  limit={limit}
                  page={surgeriesPage}
                  pagingCounter={surgeriesPaging || 1}
                  docLength={limit || 0}
                  docPage={surgeriesPage || 1}
                  totalPages={surgiesTotalPage || 1}
                  totalDocs={tableData?.data?.surgeries?.length || 0}
                  _handleLimit={(val) => {
                    setLimit(val);
                  }}
                  _handlePageChange={(e) => {
                    if (e > surgeriesPage) {
                      const diff = e - surgeriesPage;
                      setSurgeriesPaging((prev) => prev + limit * diff);
                    } else if (e < surgeriesPage) {
                      const diff = surgeriesPage - e;
                      setSurgeriesPaging((prev) =>
                        Math.max(prev - limit * diff, 0)
                      );
                    }
                    setSurgeriesPage(e);
                  }}
                />
              )}
            </div>
            <div className="cmb-30">
              <div className="text-15-600 color-2E65 cmb-10">
                Operating Rooms
              </div>
              {tableData?.data?.operatingRoomSpecific?.map(
                (tabData, tabIndex) => {
                  const totalItems = tabData?.data?.length;
                  const totalPages = Math?.ceil(totalItems / limit);
                  const paginatedData = getPaginatedData(
                    tabData?.data,
                    tabIndex
                  );
                  return (
                    <div className="table-one cmb-20" key={tabIndex}>
                      <div className="text-14-500 color-2E65 cmb-10">
                        {`Operating Room: ${titleCaseString(
                          tabData?.operating_room_name
                        )} (Completed)`}{" "}
                        ({tabData?.data?.length})
                      </div>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Patient</th>
                            <th>DOB</th>
                            <th>Doctor</th>
                            <th>Service Type</th>
                            <th>Status</th>
                            <th>Surgery Date</th>
                            <th>Surgery Start</th>
                            <th>Surgery End</th>
                            <th>Reason</th>
                            <th>Note</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData?.loading && (
                            <tr className="no-data-rw">
                              <td
                                colSpan="8"
                                className="d-flex justify-content-center cpt-50 cpb-50"
                              >
                                <LoaderUI />
                              </td>
                            </tr>
                          )}

                          {!tableData?.loading && paginatedData?.length > 0 ? (
                            paginatedData?.map((dataElem, dataIndex) => {
                              const {
                                patient_id,
                                doctor_details,
                                appointment_type,
                                appointment_status,
                                appointment_date,
                                appointment_start,
                                appointment_end,
                                reason,
                                notes,
                              } = dataElem;

                              return (
                                <tr key={dataIndex}>
                                  <td className="text-nowrap">
                                    <div className="d-flex gap-2 align-items-center">
                                      <div
                                        className="flex-shrink-0"
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      >
                                        {patient_id?.pet_image ? (
                                          <img
                                            className="img-fluid w-100 h-100"
                                            src={patient_id?.pet_image}
                                            alt="Patient"
                                            style={{
                                              borderRadius: "8px",
                                            }}
                                          />
                                        ) : (
                                          <PetPlaceholder />
                                        )}
                                      </div>
                                      <div>
                                        {titleCaseString(patient_id?.pet_name)}
                                      </div>
                                    </div>
                                  </td>
                                  <td className="text-nowrap">
                                    {moment(patient_id?.dob).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </td>
                                  <td className="text-nowrap">
                                    {titleCaseString(
                                      `Dr. ${doctor_details?.firstname} ${doctor_details?.lastname}`
                                    )}
                                  </td>
                                  <td className="text-nowrap">
                                    {titleCaseString(appointment_type)}
                                  </td>
                                  <td className="text-nowrap">
                                    {titleCaseString(appointment_status)}
                                  </td>
                                  <td className="text-nowrap">
                                    {appointment_date
                                ? moment(appointment_date, 'DD-MM-YYYY').format("MM/DD/YYYY")
                                : "-"}
                                  </td>
                                  <td className="text-nowrap">
                                  {dataElem?.appointment_start
                                ? moment(appointment_start).format("MM/DD/YYYY [at] h:mm:ss a")
                                : "-"}
                                  </td>
                                  <td className="text-nowrap">
                                  {dataElem?.appointment_end
                                ? moment(appointment_end).format("MM/DD/YYYY [at] h:mm:ss a")
                                : "-"}
                                  </td>
                                  <td className="text-nowrap">
                                    {titleCaseString(reason)}
                                  </td>
                                  <td className="text-nowrap">
                                    {titleCaseString(notes)}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr className="no-data-rw">
                              <td colSpan="11">
                                <NodataFoundBlock />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      {tabData?.data?.length > 0 && (
                        <PaginationBlock
                          limit={limit}
                          page={orPage[tabIndex] || 1}
                          pagingCounter={orPaging[tabIndex] || 1}
                          docLength={limit || 0}
                          docPage={orPage[tabIndex]}
                          totalPages={totalPages || 1}
                          totalDocs={tabData?.data?.length || 0}
                          _handleLimit={(val) => {
                            setLimit(val);
                          }}
                          _handlePageChange={(e) => {
                            if (e > orPage[tabIndex]) {
                              const diff = e - orPage[tabIndex];
                              setOrPaging((prev) => {
                                const newData = [...prev];
                                newData[tabIndex] =
                                  newData[tabIndex] + limit * diff;
                                return newData;
                              });
                            } else if (e < orPage[tabIndex]) {
                              const diff = orPage[tabIndex] - e;
                              setOrPaging((prev) => {
                                const newData = [...prev];
                                newData[tabIndex] = Math.max(
                                  newData[tabIndex] - limit * diff,
                                  0
                                );
                                return newData;
                              });
                            }
                            setOrPage((prevPages) => {
                              const updatedPages = prevPages?.map(
                                (page, index) => (index === tabIndex ? e : page)
                              );
                              return updatedPages;
                            });
                          }}
                        />
                      )}
                    </div>
                  );
                }
              )}
            </div>
            <div className="cmb-30">
              <div className="text-15-600 color-2E65 cmb-10">
                Reason's Tables
              </div>
              {tableData?.data?.reasonSpecific?.map((tabData, tabIndex) => {
                const totalItems = tabData?.data?.length;
                const totalPages = Math.ceil(totalItems / limit);
                const paginatedData = reasonPaginatedData(
                  tabData?.data,
                  tabIndex
                );
                return (
                  <div className="table-one cmb-20" key={tabIndex}>
                    <div className="text-14-500 color-2E65 cmb-10">
                      {`Reason: ${titleCaseString(tabData?.reason)}`} (
                      {tabData?.data?.length})
                    </div>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Patient</th>
                          <th>DOB</th>
                          <th>Doctor</th>
                          <th>Service Type</th>
                          <th>Status</th>
                          <th>Surgery Date</th>
                          <th>Surgery Start</th>
                          <th>Surgery End</th>
                          <th>Reason</th>
                          <th>Note</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData?.loading && (
                          <tr className="no-data-rw">
                            <td
                              colSpan="8"
                              className="d-flex justify-content-center cpt-50 cpb-50"
                            >
                              <LoaderUI />
                            </td>
                          </tr>
                        )}

                        {!tableData?.loading && paginatedData?.length > 0 ? (
                          paginatedData?.map((dataElem, dataIndex) => {
                            const {
                              patient_id,
                              doctor_details,
                              appointment_type,
                              appointment_status,
                              appointment_date,
                              appointment_start,
                              appointment_end,
                              reason,
                              notes,
                            } = dataElem;

                            return (
                              <tr key={dataIndex}>
                                <td className="text-nowrap">
                                  <div className="d-flex gap-2 align-items-center">
                                    <div
                                      className="flex-shrink-0"
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    >
                                      {patient_id?.pet_image ? (
                                        <img
                                          className="img-fluid w-100 h-100"
                                          src={patient_id?.pet_image}
                                          alt="Product"
                                          style={{
                                            borderRadius: "8px",
                                          }}
                                        />
                                      ) : (
                                        <PetPlaceholder />
                                      )}
                                    </div>
                                    <div>
                                      {titleCaseString(patient_id?.pet_name)}
                                    </div>
                                  </div>
                                </td>
                                <td className="text-nowrap">
                                  {moment(patient_id?.dob).format("MM/DD/YYYY")}
                                </td>
                                <td className="text-nowrap">
                                  {titleCaseString(
                                    `Dr. ${doctor_details?.firstname} ${doctor_details?.lastname}`
                                  )}
                                </td>
                                <td className="text-nowrap">
                                  {titleCaseString(appointment_type)}
                                </td>
                                <td className="text-nowrap">
                                  {titleCaseString(appointment_status)}
                                </td>
                                <td className="text-nowrap">
                                {dataElem?.appointment_date
                                ? moment(appointment_date, 'DD-MM-YYYY').format("MM/DD/YYYY")
                                : "-"}                                </td>
                                <td className="text-nowrap">
                                {dataElem?.appointment_start
                                ? moment(appointment_start).format("MM/DD/YYYY [at] hh:mm a" )
                                : "-"}   
                                </td>
                                <td className="text-nowrap">
                                {dataElem?.appointment_end 
                                ? moment(appointment_end).format("MM/DD/YYYY [at] hh:mm a" )
                                : "-"}   
                                </td>
                                <td className="text-nowrap">
                                  {titleCaseString(reason)}
                                </td>
                                <td className="text-nowrap">
                                  {titleCaseString(notes)}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="no-data-rw">
                            <td colSpan="11">
                              <NodataFoundBlock />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    {tabData?.data?.length > 0 && (
                      <PaginationBlock
                        limit={limit}
                        page={reasonPage[tabIndex] || 1}
                        pagingCounter={reasonPaging[tabIndex] || 1}
                        docLength={limit || 0}
                        docPage={reasonPage[tabIndex]}
                        totalPages={totalPages || 1}
                        totalDocs={tabData?.data?.length || 0}
                        _handleLimit={(val) => {
                          setLimit(val);
                        }}
                        _handlePageChange={(e) => {
                          if (e > reasonPage[tabIndex]) {
                            const diff = e - reasonPage[tabIndex];
                            setReasonPaging((prev) => {
                              const newData = [...prev];
                              newData[tabIndex] =
                                newData[tabIndex] + limit * diff;
                              return newData;
                            });
                          } else if (e < reasonPage[tabIndex]) {
                            const diff = reasonPage[tabIndex] - e;
                            setReasonPaging((prev) => {
                              const newData = [...prev];
                              newData[tabIndex] = Math.max(
                                newData[tabIndex] - limit * diff,
                                0
                              );
                              return newData;
                            });
                          }
                          setReasonPage((prevPages) => {
                            const updatedPages = prevPages?.map((page, index) =>
                              index === tabIndex ? e : page
                            );
                            return updatedPages;
                          });
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </div>

            <div className="cmb-30">
              <div className="text-15-600 color-2E65 cmb-10">
                Species Tables
              </div>
              {tableData?.data?.speciesSpecific?.map((tabData, tabIndex) => {
                const totalItems = tabData?.data?.length;
                const totalPages = Math.ceil(totalItems / limit);
                const paginateData = getSpeciesPaginateData(
                  tabData?.data,
                  tabIndex
                );
                return (
                  <div className="table-one cmb-20" key={tabIndex}>
                    <div className="text-14-500 color-2E65 cmb-10">
                      {`Species: ${titleCaseString(tabData?.species_name)}`} (
                      {tabData?.data?.length})
                    </div>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Patient</th>
                          <th>Age Range</th>
                          <th>DOB</th>
                          <th>Doctor</th>
                          <th>Service Type</th>
                          <th>Status</th>
                          <th>Surgery Date</th>
                          <th>Surgery Start</th>
                          <th>Surgery End</th>
                          <th>Reason</th>
                          <th>Note</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData?.loading && (
                          <tr className="no-data-rw">
                            <td
                              colSpan="8"
                              className="d-flex justify-content-center cpt-50 cpb-50"
                            >
                              <LoaderUI />
                            </td>
                          </tr>
                        )}

                        {!tableData?.loading && paginateData?.length > 0 ? (
                          paginateData?.map((dataElem, dataIndex) => {
                            const {
                              patient_id,
                              doctor_details,
                              appointment_type,
                              appointment_status,
                              appointment_date,
                              appointment_start,
                              appointment_end,
                              reason,
                              notes,
                            } = dataElem;

                            return (
                              <tr key={dataIndex}>
                                <td className="text-nowrap">
                                  <div className="d-flex gap-2 align-items-center">
                                    <div
                                      className="flex-shrink-0"
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    >
                                      {patient_id?.pet_image ? (
                                        <img
                                          className="img-fluid w-100 h-100"
                                          src={patient_id?.pet_image}
                                          alt="Product"
                                          style={{
                                            borderRadius: "8px",
                                          }}
                                        />
                                      ) : (
                                        <PetPlaceholder />
                                      )}
                                    </div>
                                    <div>
                                      {titleCaseString(patient_id?.pet_name)}
                                    </div>
                                  </div>
                                </td>
                                <td className="text-nowrap">
                                  {titleCaseString(patient_id?.ageRange)}
                                </td>
                                <td className="text-nowrap">
                                  {moment(patient_id?.dob).format("MM/DD/YYYY")}
                                </td>
                                <td className="text-nowrap">
                                  {titleCaseString(
                                    `Dr. ${doctor_details?.firstname} ${doctor_details?.lastname}`
                                  )}
                                </td>
                                <td className="text-nowrap">
                                  {titleCaseString(appointment_type)}
                                </td>
                                <td className="text-nowrap">
                                  {titleCaseString(appointment_status)}
                                </td>
                                <td className="text-nowrap">
                                {dataElem?.appointment_date
                                ? moment(appointment_date, 'DD-MM-YYYY').format("MM/DD/YYYY" )
                                : "-"}                                   </td>
                                <td className="text-nowrap">
                                {dataElem?.appointment_start
                                ? moment(appointment_start).format("MM/DD/YYYY [at] hh:mm a" )
                                : "-"}  
                                </td>
                                <td className="text-nowrap">
                                {dataElem?.appointment_end
                                ? moment(appointment_end).format("MM/DD/YYYY [at] hh:mm a" )
                                : "-"}  
                                </td>
                                <td className="text-nowrap">
                                  {titleCaseString(reason)}
                                </td>
                                <td className="text-nowrap">
                                  {titleCaseString(notes)}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="no-data-rw">
                            <td colSpan="11">
                              <NodataFoundBlock />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    {tabData?.data?.length > 0 && (
                      <PaginationBlock
                        limit={limit}
                        page={speciesPage[tabIndex] || 1}
                        pagingCounter={speciesPaging[tabIndex] || 1}
                        docLength={limit || 0}
                        docPage={speciesPage[tabIndex]}
                        totalPages={totalPages || 1}
                        totalDocs={tabData?.data?.length || 0}
                        _handleLimit={(val) => {
                          setLimit(val);
                        }}
                        _handlePageChange={(e) => {
                          if (e > speciesPage[tabIndex]) {
                            const diff = e - speciesPage[tabIndex];
                            setSpeciesPaging((prev) => {
                              const newData = [...prev];
                              newData[tabIndex] =
                                newData[tabIndex] + limit * diff;
                              return newData;
                            });
                          } else if (e < speciesPage[tabIndex]) {
                            const diff = speciesPage[tabIndex] - e;
                            setSpeciesPaging((prev) => {
                              const newData = [...prev];
                              newData[tabIndex] = Math.max(
                                newData[tabIndex] - limit * diff,
                                0
                              );
                              return newData;
                            });
                          }
                          setSpeciesPage((prevPages) => {
                            const updatedPages = prevPages?.map((page, index) =>
                              index === tabIndex ? e : page
                            );
                            return updatedPages;
                          });
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OperatingRoomReport;
