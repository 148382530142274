const initState = {
    loading: false,
    surgeriesData: false
}

export const surgeriesReducer = (state = initState, action) => {
    // 
    switch (action.type) {
        case "SURGERY_LOADING":
            // 
            return { ...state, loading: action.payload }

        case "GET_SURGERIES":
            // 
            return { ...state, surgeriesData: action.payload }

        default:
            return state
    }
}