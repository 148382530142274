import React, { useEffect, useState } from "react";
import { IoMdAddCircle, IoIosRemoveCircle } from "react-icons/io";
import { Collapse } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl, PORT } from "../../../environment";
import { getSuppliersByClinicId } from "context/actions/inventory";
import './index.css'

export default function SingleProductDetail({ formData }) {
  const dispatch = useDispatch();
  const [loadIndex, setLoadIndex] = useState(1);
  const history = useHistory();
  const [elemIndex, setElemIndex] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);


  const handleGetSuppliers = () => {
    dispatch(
      getSuppliersByClinicId(clinic_settings?.clinic_id, {}, (res) => {
        setSuppliers(res?.response_data?.docs);
      })
    );
  };
  const toggleFunction = (index) => {
    if (loadIndex === index) {
      setLoadIndex(null);
    } else if (elemIndex === index) {
      setToggle(!toggle);
    } else {
      setToggle(true);
    }
    setElemIndex(index);
    setLoadIndex(null);
  };

  const resetBtn = () => {
    history.push("inventory");
  };

  const formateDate = (date) => {
    let useDate = new Date(date);
    let formatedDate = `${useDate.getDate()}/${useDate.getMonth() + 1
      }/${useDate.getFullYear()}`;
    return formatedDate;
  };

  useEffect(() => {
    handleGetSuppliers();

  }, [])

  const { inventoryCategories } = useSelector(state => state.appContentReducer)


  return (
    <div className="relative bottom-[20px]">
      {/* Supplier info */}
      <div className="ml-5 bg-[#F8F8F8E5] rounded-lg w-100 mb-1">
        <div className=" p-3">
          <div className="flex justify-between mb-3">
            <p className="font-[Inter] font-[700] text-[18px] text-[#282E65]">
              Supplier Information
            </p>
            <button className="px-2.5 p-0 font-[400]" onClick={resetBtn}>
              Reset
            </button>
          </div>
          <div className=" flex flex-col gap-[10px]">
            <div className="font-['Inter'] w-full flex gap-10">
              <p className="text-[#282E65]  w-[302px] font-['Inter'] font-[500] text-[16px]">
                Supplier
              </p>
              <p
                style={{textTransform: 'capitalize'}}
                className={
                  "text-[#282E65] w-[350px] font-['Inter'] text-[16px]"
                }
              >
                {suppliers?.filter(i => i?._id === formData?.values?.supplier_id)?.[0]?.supplier_name || '--'}
              </p>
            </div>

        
          </div>
        </div>
      </div>

      {/* Product info */}
      <div className="ml-5 bg-[#F8F8F8E5] rounded-lg w-100 mb-1 position-relative">
        <div className=" p-3">
          <p className="font-[Inter] font-[700] text-[18px] text-[#282E65] mb-3">
            Product Information
          </p>
          <div className="absolute flex w-[95%] justify-end"></div>
          <div className=" flex flex-col gap-[10px]">
            <div className="font-['Inter'] w-full flex gap-10">
              <p className="text-[#282E65]  w-[302px] font-['Inter'] font-[400] text-[16px]">
                Product Name
              </p>
              <p
                className={
                  "text-[#282E65] w-[350px] font-['Inter'] text-[16px]"
                }
              >
                {formData?.values?.product_name}
              </p>
            </div>

            
            <div className="font-['Inter'] w-full flex gap-10">
              <p className="text-[#282E65]  w-[302px] font-['Inter'] font-[400] text-[16px]">
                Category
              </p>
              <p
                style={{textTransform: 'capitalize'}}
                className={ 
                  "text-[#282E65] w-[350px] font-['Inter'] text-[16px]"
                }
              >
                {inventoryCategories?.filter(i => i?._id === formData?.values?.category_id)?.[0]?.category || '--'}
              </p>
            </div>
 
            
            <div className="font-['Inter'] w-full flex gap-10">
              <p className="text-[#282E65]  w-[302px] font-['Inter'] font-[400] text-[16px]">
                Unit of measure
              </p>
              <p
                className={
                  "text-[#282E65] w-[350px] font-['Inter'] text-[16px]"
                }
              >
                {formData?.values?.unit_of_measure} 
              </p>
            </div>
            <div className="font-['Inter'] w-full flex gap-10">
              <p className="text-[#282E65]  w-[302px] font-['Inter'] font-[400] text-[16px]">
                Sub Package
              </p>
              <p
                className={
                  "text-[#282E65] w-[350px] font-['Inter'] text-[16px]"
                }
              >
              {formData?.values?.sub_package?.amount ? `${formData?.values?.sub_package?.amount}${formData?.values?.sub_package?.unit_of_measure}` : "--"}
              </p>
            
            </div>
            <div className="font-['Inter'] w-full flex gap-10">
              <p className="text-[#282E65]  w-[302px] font-['Inter'] font-[400] text-[16px]">
                Description
              </p>
              <p
                className={
                  "text-[#282E65] w-[350px] font-['Inter'] text-[16px]"
                }
              >
                {formData?.values?.description || '--'}
              </p>
            </div>
            <div className="font-['Inter'] w-full flex gap-10">
              <p className="text-[#282E65]  w-[302px] font-['Inter'] font-[400] text-[16px]">
                Manufacturer Info.
              </p>
              <p
                className={
                  "text-[#282E65] w-[350px] font-['Inter'] text-[16px]"
                }
              >
                {formData?.values?.manufacture}
              </p>
            </div>
            <div className="font-['Inter'] w-full flex gap-10">
              <p className="text-[#282E65]  w-[302px] font-['Inter'] font-[400] text-[16px]">
                SKU
              </p>
              <p
                className={
                  "text-[#282E65] w-[350px] font-['Inter'] text-[16px]"
                }
              >
                {formData?.values?.sku}
              </p>
            </div>
            <div className="font-['Inter'] w-full flex gap-10">
              <p className="text-[#282E65]  w-[302px] font-['Inter'] font-[400] text-[16px]">
                Product Description
              </p>
              <p
                className={
                  "text-[#282E65] w-[350px] font-['Inter'] text-[16px]"
                }
              >
                {formData?.values?.product_description || '--'}
              </p>
              {/* <button className="btn-link">Add Info</button> */}
            </div>
            <div className="flex gap-2 mt-3">
              <div>
                <img
                  className="bg-[#fff] border w-[200px] border-gray rounded-lg"
                  src={formData?.values?.image}
                  alt="product"
                />
              </div>
              <div className="flex items-center justify-center rounded-lg border-2 border-gray-400 border-dotted w-[40px]">
                <IoMdAddCircle color="#11CA9D" size={25} />
              </div>
            </div>
          </div>
        </div>
        <div className="qr_img1 text-center">
          <img src={require("../../../assets/img/qr.png")} alt="img" />
          <p className="mb-0 mt-2 pt-1">Regenerate QR Code</p>
        </div>
      </div>

      {/* Coast and taxes info block */}

      <div className="ml-5 bg-[#F8F8F8E5] rounded-lg w-100 mb-1">
        <div className=" p-3">
          <p className="font-[Inter] font-[700] text-[18px] text-[#282E65] mb-3">
            Cost & Taxes
          </p>

          <div className=" flex flex-col gap-[10px]">
            

            <div className="font-['Inter'] w-full flex gap-10">
              <p className="text-[#282E65]  w-[302px] font-['Inter'] font-[400] text-[16px]">
                Product Cost
              </p>
              <p
                className={
                  "text-[#282E65] w-[350px] font-['Inter'] text-[16px]"
                }
              >
                {formData?.values?.product_cost ? '$' + parseFloat(formData?.values?.product_cost)?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        }) : '--'}
              </p>
            </div>
           
            <div className="font-['Inter'] w-full flex gap-10">
              <p className="text-[#282E65]  w-[302px] font-['Inter'] font-[400] text-[16px]">
                Taxes
              </p>
              <p
                className={
                  "text-[#282E65] w-[350px] font-['Inter'] text-[16px]"
                }
              >
                {formData?.values?.taxes ? formData?.values?.taxes + '%' : '--' }
              </p>
            </div>
            <div className="font-['Inter'] w-full flex gap-10">
              <p className="text-[#282E65]  w-[302px] font-['Inter'] font-[400] text-[16px]">
                Discount (If any)
              </p>
              <p
                className={
                  "text-[#282E65] w-[350px] font-['Inter'] text-[16px]"
                }
              >
                {formData?.values?.discount ?  formData?.values?.discount +'%': '--'}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* packeages onfo block */}
    

      {/* special notes */}
      
    </div>
  );
}

// single product
