import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SearchBlock2 from "../../../shared/SearchBlock2";
import { getAndSearchProductList } from "../../../context/actions/inventory";
import { Table, Button } from "reactstrap";
import NodataFoundBlock from "../../../shared/NoDataFoundBlock";


export default function AddProductsTable({ setFormData }) {
	const dispatch = useDispatch();

	const [selectedList, setSelectedList] = useState([])
	const { productList } = useSelector(state => state.inventoryReducer)

	const handleSelect = (list) => {
		setSelectedList(list);

		console.log(list.map(item => { return ({ name: item?.product_name, product_id: item?._id }) }))
		setFormData(list.map(item => { return ({ name: item?.product_name, product_id: item?._id }) }))
	}


	const handleGetAndSearchProductList = (e) => {
		let text = e?.target?.value;
		dispatch(getAndSearchProductList({ searchText: text }, (response) => {
		}))
	}

	useEffect(() => {
		handleGetAndSearchProductList();
	}, []);





return (
<>
<h5 className="text-lg font-semibold uppercase text-[#282E65]">
  Add Products
</h5>
<div className="d-flex flex-row w-100">
      <SearchBlock2 placeholder="Search" onChange={handleGetAndSearchProductList} />
</div>
<div className="table-listing-blc overflow-auto">
							<Table responsive>
								<thead>
									<tr>
										<th>Product Name</th>
										<th>Details</th>
										<th>Category</th>
										<th style={{ width: '100px' }}>&nbsp;</th>
									</tr>
								</thead>
								<tbody>
									{ (selectedList && selectedList.length > 0) &&
										selectedList.map((item, i) => {
											return(
												<tr style={{  background: '#DBF8F1' }} key={i}>
													<td  style={{  background: '#DBF8F1' }}>{`${item?.product_name} (${item?.sku})`}</td>
													<td  style={{  background: '#DBF8F1' }} >{item?.description || '--'}</td>
													<td  style={{  background: '#DBF8F1', textTransform: 'capitalize' }}>{item?.category}</td>
													<td  style={{  background: '#DBF8F1' }}>
														<Button color="link"  onClick={() => {
																					let copy = [...selectedList];
																					copy.splice(i, 1);
																					handleSelect(copy)
																					}}>
															Remove
														</Button>
													</td>
												</tr>
											)
											})
									}

									{
										(productList && productList.length > 0) ? productList.map((item, i) => {
											return (
												<tr key={i}>
													<td>{`${item?.product_name} (${item?.sku})`}</td>
													<td>{item?.description || '--'}</td>
													<td style={{textTransform: 'capitalize'}}>{item?.category}</td>
													<td>
														<Button color="link"  onClick={() => {
                                                handleSelect([...selectedList, item])

											}}>
												Select
											</Button>
										</td>
									</tr>
								)
							}) :
								<tr className="no-data-rw">
									<td colSpan="5">
										<NodataFoundBlock />
									</td>
								</tr>
						}
					</tbody>
				</Table>
			</div>


		</>)
}