import { MdOutlineRemoveRedEye } from "react-icons/md";
import pdfFile from "assets/img/icons/pdf.svg";
import "./index.scss";
import { useDispatch } from "react-redux";

const PatientIntake = ({ data = [] }) => {
  const dispatch = useDispatch();
  return (
    <>
      <div className={`patient-intake-card`}>
        <div className="txt">
          <h2>Patient Intake Form</h2>
        </div>
        {data.map((elm, index) => {
          const { form_title, form_url } = elm;
          return (
            <div className="txt intake-form d-flex flex-row" key={index}>
              <div className="fa-center gap-2">
                <div className="h-20 d-flex">
                  <img src={pdfFile} alt="pdfFile" />
                </div>
                <div className="text-16-400 color-2E65">{form_title}</div>
              </div>
              <div className="intake-options d-flex flex-row">
                {form_url && (
                  <div className="fa-center gap-3 cursor-pointer">
                    <div
                      onClick={() => {
                        dispatch({
                          type: "SET_FILE_VIEWER_DATA",
                          data: {
                            title: form_title,
                            fileURL: form_url,
                          },
                        });
                      }}
                    >
                      <MdOutlineRemoveRedEye size={20} color="#919191" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default PatientIntake;
