import React, { useState } from "react";
import { Button, FormGroup } from "reactstrap";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import "./index.scss";
import { useEffect } from "react";
const AddressAutoCompleteInput = ({
  onSelect,
  value,
  label = "Head Office Address Information",
  touched, error
}) => {
  const [address, setAddress] = useState();
  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setAddress(selectedAddress);
      onSelect({ address: selectedAddress, postal: results?.[0]?.address_components?.find(i => i?.types?.includes('postal_code'))?.long_name, ...latLng });
    } catch (error) {
      console.error("Error selecting address", error);
    }
  };
  useEffect(() => {
    if (value === "") setAddress(undefined);
  }, [value]);
  return (
    <PlacesAutocomplete
      value={address}
      onChange={(newAddress) => {
        setAddress(newAddress)}}
      onSelect={handleSelect}
      searchOptions={{types: ['street_address']}}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="position-relative">
          <div className="form-group-wrapper d-flex">
            <FormGroup className="input-wrapper2 flex-fill">
              <div className="form-floating position-relative text-input">
                <input
                  type="text"
                  id="address"
                  name="address"
                  placeholder="Enter First Name"
                  {...getInputProps({
                    placeholder: "Enter your address...",
                    className: "location-search-input form-control",
                  })}
                  value={address === undefined ? value : address}
                />
                <label for="address">{label}</label>
              </div>
            </FormGroup>
            <Button
              color="link"
              className="d-inline-flex align-items-center justify-content-center"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <i className="icomoon-location-current"></i>
            </Button>
          </div>
          {loading || suggestions.length ? (
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => (
                <div
                  className="pointer"
                  {...getSuggestionItemProps(suggestion)}
                  key={suggestion.placeId}
                >
                  {suggestion.description}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default AddressAutoCompleteInput;
