import { updateClinicSettings } from "context/actions/clinicSettings";
import React, { useState } from "react";
import { IoCloseCircleSharp, IoCheckmarkCircleSharp } from "react-icons/io5";
import { useDispatch } from "react-redux";

const MedicationDelayThreshold = ({ clinic_settings }) => {
  const dispatch = useDispatch();
  const { delay_duration, delay_unit } = clinic_settings;

  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState("");
  const [type, setType] = useState("");
  const handleSave = () => {
    let body = {
      clinic_id: clinic_settings.clinic_id,
      update_query: "other_settings",
      delay_duration: value || "1",
      delay_unit: type,
    };
    dispatch(
      updateClinicSettings(body, (res) => {
        if (res?.status) {
          setIsEdit(false);
        }
      })
    );
  };
  return (
    <div className="bg-ffff p-2 pe-3">
      {isEdit ? (
        <div className="fb-center gap-3">
          <div className="fa-center gap-3">
            <div className="medication-delay-input">
              <input
                type="text"
                value={value}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    setValue("1");
                  }
                }}
                onChange={(e) => {
                  let noSpaces = e.target.value
                    .replace(/\s/g, "")
                    .replace(/[^0-9]/g, "");
                  if (noSpaces.charAt(0) === "0") {
                    noSpaces = noSpaces.replace(/^./, "");
                  }
                  setValue(noSpaces);
                }}
              />
            </div>
            <div className="medication-delay-select">
              <select
                value={type}
                onChange={(e) => {
                  setType(e.target.value);
                }}
              >
                <option value="hours">Hours</option>
                <option value="minutes">Minutes</option>
              </select>
            </div>
          </div>
          <div className="fa-center gap-2">
            <IoCheckmarkCircleSharp
              size={25}
              color="#11ca9d"
              className="cursor-pointer"
              onClick={handleSave}
            />
            <IoCloseCircleSharp
              size={25}
              color="#dc3545"
              className="cursor-pointer"
              onClick={() => {
                setIsEdit(false);
              }}
            />
          </div>
        </div>
      ) : (
        <div className="fb-center">
          <div className="text-18-600 color-2E65">
            {delay_unit ? `${delay_duration} ${delay_unit}` : "1 hours"}
          </div>
          <div
            className="fa-center text-13-400 cursor-pointer"
            onClick={() => {
              setIsEdit(true);
              setValue(delay_duration || "1");
              setType(delay_unit || "hours");
            }}
          >
            <span className="icomoon-pencil" />
          </div>
        </div>
      )}
    </div>
  );
};

export default MedicationDelayThreshold;
