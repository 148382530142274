import moment from "moment";
import React, { useState } from "react";
import { Button } from "reactstrap";
import UserPlaceholder from "shared/UserPlaceholder";
import OverViewEdit from "../../ScheduleEdit/OverViewEdit";

const SectionView = ({ section, note, isExtend = false }) => {
  const [overEditNotes, setOverEditNotes] = useState(false);
  const [extend, setExtend] = useState(null);
  return (
    <>
      <div className="note-content-block content-box cursor-pointer" >
        <div className="d-flex justify-content-between">
          <h2 className=" mb-2"><span className="capitalize fw-bold">{section?.title} </span>
            {/* <span className="text-sm ms-1 text-red-600">({note?.drafStatus ? "draft" : "*"})</span> */}
          </h2>


          {isExtend ? <></> : <span className="d-flex text-[12px] gap-[10px]">Last Edit: {moment(note?.editHistory?.length ? note?.editHistory?.[0]?.date : note?.createdAt)?.format("MM/DD/YYYY hh:mm a")} {/*moment(note?.createdAt).format('MM/DD/YYYY h:mm a')*/}
            {<span onClick={isExtend ? () => { } : () => {
              setOverEditNotes(true);
              setExtend(null);
            }} style={{ visibility: isExtend ? 'hidden' : 'visible' }}
              className="icomoon-pencil"></span>}
          </span>}
        </div>
        <div className="inner">
          <div className="items-info">
            {section?.fields.map((item, i) => (
              <div key={i} className="item-rw d-flex">
                <div className="title">{item?.field}</div>
                <div className="desc">{item?.value}</div>
              </div>
            ))}
          </div>
        </div>
      </div >

      {/* overEditNotes modal backdrop start */}
      {overEditNotes && (
        <>
          <div
            className={` fixed top-0 right-0 bottom-0 left-0 z-[9] bg-black/20 transition-all duration-300 ease-in `}
          ></div>
        </>
      )}
      {/* overEditNotes modal backdrop end */}
      <OverViewEdit
        note={note}
        section={section}
        isActive={overEditNotes}
        toggleNotePanel={() => setOverEditNotes(!overEditNotes)}
        extend={extend}
        closingFn={() => {
          if (extend) {
            setExtend(null);
          }
        }}
      />
    </>
  );
};

export default SectionView;
