import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl, PORT } from "../../../environment";
import { object, string } from "yup";
import { getAllSupplierListByClinicId, getAllSupplierListWithFilter } from "context/actions/supplier";
let supplierInfoSchema = object({
  supplier_id: string().required(),
  order_type: string().required(),
  po_number: string().required(),
  description: string().optional(),
  // createdOn: date().default(() => new Date()),
});

export default function SupplierInfo({
  setActiveForm,
  updateFormData,
  formData,
}) {
  const dispatch = useDispatch()
  const userDetails = useSelector((state) => state.user.user_details);
  const [suppliers, setSuppliers] = useState([]);
  const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);
 

  const formik = useFormik({
    initialValues: {
      // clinic_id: userDetails?._id,
      clinic_id: clinic_settings.clinic_id,
      supplier_id: "",
      order_type: "",
      po_number: "",
      description: "",
    },
    validationSchema: supplierInfoSchema,
    onSubmit: (values) => {
      updateFormData((prevFormData) => ({
        ...prevFormData,
        values,
      }));
      setActiveForm("productInfo");
    },
  });
  useEffect(() => {
    if (clinic_settings) {


      dispatch(getAllSupplierListByClinicId(clinic_settings?.clinic_id, (data) => {
        console.log("fetched data", data?.response_data);
        setSuppliers(data?.response_data);
      }))
      
    }

  }, [clinic_settings]);

  useEffect(() => {
    if (formData && Object.keys(formData).length !== 0) {
      formik.setValues(formData.values);
    }
  }, [formData]);

  return (
    <div className="ml-5 bg-[#F8F8F8E5] rounded-lg  ">
      <div className=" p-3">
        <p className="font-['Inter'] text-[#282E65] font-[700] mb-2">
          Supplier Information
        </p>
        <div className="flex justify-end w-[33%]">
          {formik.touched.supplier_name && formik.errors.supplier_name ? (
            <p className="text-[#dc3545] font-['Inter'] text-[12px]">
              Please fill this field
            </p>
          ) : null}
        </div>
        <div className="flex  justify-between items-baseline my-2">
          <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
            Supplier{" "}
            <span className="text-[#282E65] font-['Inter'] text-[18px]">*</span>
          </p>
          <select
            id="supplier_name"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5"
            name="supplier_name"
            {...formik.getFieldProps("supplier_id")}
          >
            <option selected>Select Supplier</option>
            {suppliers.map((data) => {
              return <option value={data._id}>{data.supplier_name}</option>;
            })}
          </select>
        </div>
        <div className="flex  justify-between items-baseline my-2">
          <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
            Order Type{" "}
            <span className="text-[#282E65] font-['Inter'] text-[18px]">*</span>
          </p>
          <select
            id="countries"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5"
            name="order_type"
            {...formik.getFieldProps("order_type")}
          // onChange={handelSelectOrderType}
          >
            <option selected>Select order type</option>
            <option value="Purchase Order">Purchase Order</option>
            <option value="Sale Order">Sale Order</option>
          </select>
        </div>
        <div className="flex justify-end w-[33%]">
          {formik.touched.order_type && formik.errors.order_type ? (
            <p className="text-[#dc3545] font-['Inter'] text-[12px]">
              Please fill this field
            </p>
          ) : null}
        </div>
        <div className="flex justify-between  items-center my-2">
          <label
            htmlFor="first_name"
            className="block mb-2 text-sm font-medium text-[#282E65] font-['Inter']"
          >
            PO Number{" "}
            <span className="text-[#282E65] font-['Inter'] text-[18px]">*</span>
          </label>
          <input
            type="text"
            id="first_name"
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5"
            placeholder="Enter PO number"
            required
            {...formik.getFieldProps("po_number")}
          />
        </div>
        <div className="flex justify-end w-[33%]">
          {formik.touched.po_number && formik.errors.po_number ? (
            <p className="text-[#dc3545] font-['Inter'] text-[12px]">
              Please fill this field
            </p>
          ) : null}
        </div>
        <div className="flex justify-between ">
          <label
            htmlFor="message"
            className="block mb-2 text-sm font-medium text-[#282E65] font-['Inter']"
          >
            Description
          </label>
          <textarea
            id="message"
            rows="4"
            className="block p-2.5 w-[81%] text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Write Description here..."
            {...formik.getFieldProps("description")}
          ></textarea>
        </div>
        <div className="flex justify-end mt-2">
          <button
            type="button"
            onClick={formik.handleSubmit}
            className="bg-[#11CA9D] px-3.5 py-2  rounded-lg text-[#fff] font-['Inter']"
          >
            Save & Proceed
          </button>
        </div>
      </div>
    </div>
  );
}

// supplier
