import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Popover,
  PopoverBody,
  Input,
  Form,
  ModalBody,
  Modal,
} from "reactstrap";
import Offcanvas from "react-bootstrap/Offcanvas";

import {
  addBehaviourTag,
  removeBehaviourTag,
} from "../../../context/actions/patient-module/patient/pet";
import { getPetDetailsByIdNoLoading } from "../../../context/actions/patient-module/patient/pet";
import "./index.scss";
import { BiMinus } from "react-icons/bi";
import { useFormik } from "formik";
import {
  BsCheckLg,
  BsFillCheckCircleFill,
  BsFillTrashFill,
} from "react-icons/bs";
import { getBehaviours } from "../../../context/actions/behaviours";

const ProfileTags = ({ showRemoveIcon, target }) => {
  const { permissionsData } = useSelector((state) => state.clinicPermissionReducer);
  const permissions = permissionsData;
  const [popover, setPopover] = useState(false);
  const [tagValue, setTagValue] = useState(false);
  const [reactionsValue, setReactionsValue] = useState("");

  const [notesValue, setNotesValue] = useState("");

  const [behaviourName, setBehaviorName] = useState("");

  const [isBehaviourAdd, setIsBehaviourAdd] = useState(false);
  const [behaviourTagData, setBehaviourTagData] = useState([]);

  const [severityItem, setSeverityItem] = useState({});

  const severity = [
    {
      id: 1,
      severityName: "Low",
      tagClassName: "low",
      colorName: "lightseagreen",
    },
    {
      id: 2,
      severityName: "Moderate",
      tagClassName: "moderate",
      colorName: "darkgoldenrod",
    },
    {
      id: 3,
      severityName: "High",
      tagClassName: "high",
      colorName: "orange",
    },
  ];
  const reactionsOptions = [
    { value: "Low" },
    { value: "Moderate" },
    { value: "High" },
    { value: "aggressive" },
  ];

  const dispatch = useDispatch();

  const popoverToggle = () => {
    setPopover(!popover);
  };

  const { petDetails } = useSelector((state) => state.petReducer);

  const allBehaviours = useSelector(
    (state) => state?.behaviourReducer?.behaviours
  );

  const formik = useFormik({
    initialValues: {
      tag: "",
      servive: "",
    },
    onSubmit: (values, { resetForm }) => {
      dispatch(
        addBehaviourTag(
          {
            pet_id: petDetails._id,
            tag: values.tag || "",
            severity: values.servive,
          },
          (response) => {
            if (response?.status) {
              dispatch(getPetDetailsByIdNoLoading(petDetails._id, {}, () => {}));
            }
          }
        )
      );
      setPopover(false);
      resetForm();
    },
  });





  // const handleAddTag = (e) => {
  //   e.preventDefault();
  // };

  const handleAddBehavior = () => {
    dispatch(
      addBehaviourTag(
        {
          pet_id: petDetails._id,
          tag: tagValue || "",
          severity: reactionsValue,
          notes: notesValue,
        },
        (response) => {
          if (response?.status) {
            dispatch(
              getPetDetailsByIdNoLoading(petDetails._id, {}, () => {
                popoverToggle();
                setIsBehaviourAdd(false);
                setBehaviorName("");
                setReactionsValue("");
                setNotesValue("");
              })
            );
          }
        }
      )
    );
  };

  const handleRemoveTag = (item) => {
    dispatch(
      removeBehaviourTag(
        {
          pet_id: petDetails._id,
          tag: item.tag || "",
          severity: item.severity,
        },
        (response) => {
          if (response?.status) {
            dispatch(getPetDetailsByIdNoLoading(petDetails._id, {}, () => {}));
          }
        }
      )
    ); // Dispatch an action to remove the behavior tag by its unique ID
    // dispatch(
    //   getPetDetailsById(petDetails._id, {}, () => {
    //     // popoverToggle();
    //   })
    // );
  };
  let severityToColor = {
    aggressive: "orange",
    assertive: "yellow",
    neutral: "cyan",
    passive: "green",
  };
  let colors = ["orange", "yellow", "cyan", "green"];

  const handleUpdateText = (data) => {
    setBehaviourTagData([
      ...behaviourTagData,
      { ...severityItem, tageName: data },
    ]);
    // tempAllergytags.push({...allergytags,tageName:data})
    // setIsAllergyAdd(false)
  };

  const handleCancelBehaviour = () => {
    setReactionsValue("");
    setNotesValue("");
  };

  useEffect(() => {
    dispatch(getBehaviours((res) => {}))
  }, [])




  return (
    <>
      <div className="profile-tags row-block">
        <div className="head section-head d-flex align-items-center">
          <div className="left-col">Behaviour Tags</div>

          {(permissions?.edit_patients_details && permissions?.add_behavior_tag) && 

          <div className="right-col ms-auto">
            <Button
              type="button"
              id={target}
              onClick={() =>
                isBehaviourAdd
                  ? setIsBehaviourAdd(false)
                  : setIsBehaviourAdd(true)
              }
              color={"link"}
              className="add-btn  d-inline-flex"
            >
              <span className="icomoon-plus-btn"></span>
            </Button>

            {/* <Popover placement="bottom-end" isOpen={popover} toggle={popoverToggle} target={target}>
              <PopoverBody className={'new-tag'}>
                <Input placeholder="Add Behaviour Tag" onChange={(event) => { setTagValue(event?.target?.value) }} />
                <Input type="select" placeholder="Select Severity" onChange={(event) => { setSeverityValue(event?.target?.value) }}>
                  <option>Select Severity</option>
                  <option>aggressive</option>
                  <option>assertive</option>
                  <option>neutral</option>
                  <option>passive</option>
                </Input>
                <div className="btn-cell d-flex">
                  <Button color="primary" onClick={handleAddTag} className="flex-fill">
                    Add Tag
                  </Button>
                </div>
              </PopoverBody>
            </Popover> */}
            {/* <Modal
              placement="bottom-end"
              isOpen={popover}
              toggle={() => setPopover(false)}
              target="behaviour-tag"
            >
              <ModalBody className={"new-tag"}>
                <Input
                  placeholder="Add Behaviour Tag"
                  name="tag"
                  value={formik.values.tag}
                  onChange={formik.handleChange}
                />
                <Form onSubmit={formik.handleSubmit}>
                  <Input
                    type="select"
                    placeholder="Select Severity"
                    onChange={formik.handleChange}
                    value={formik.values.servive}
                    name="servive"
                  >
                    <option value="">Select Severity</option>
                    <option value="aggressive">aggressive</option>
                    <option value="assertive">assertive</option>
                    <option value="neutral">neutral</option>
                    <option value="passive">passive</option>
                  </Input>
                  <div className="btn-cell d-flex">
                    <Button type="submit" color="primary" className="flex-fill">
                      Add Tag
                    </Button>
                  </div>
                </Form>
              </ModalBody>
            </Modal> */}
          </div>}
        </div>
        <div className="patients-overview">
          <div className="d-flex gap-1 flex-wrap">
            {petDetails?.petCareInfo?.behaviour_tags?.length > 0 &&
              petDetails?.petCareInfo?.behaviour_tags?.map((elem, index) => {
                return (
                  <span
                    key={index}
                    className={`text-center ps-1 pe-1 ${
                      elem?.bseverity === "Low"
                        ? "bg-teal-500 text-white rounded-sm"
                        : elem?.bseverity === "Moderate"
                        ? "bg-yellow-500 text-white rounded-sm"
                        : elem?.bseverity === "High"
                        ? "bg-orange-500  text-white rounded-sm"
                        : ""
                    }`}
                  >
                    {elem.btag}
                  </span>
                );
              })}
          </div>
          {/* {isBehaviourAdd && (
            <div className=" mt-3">
              <div>
                <div className="reactions-add d-flex gap-2 align-items-center">
                  <div className="d-flex gap-1">
                    <span>Reactions</span>
                    <span style={{ color: "red" }}>*</span>
                  </div>
                  <Input
                    className="reactions-select-dropdown"
                    type="select"
                    name="select"
                    placeholder="Select value"
                    value={reactionsValue}
                    onChange={(e) => {
                      setReactionsValue(e?.target?.value);
                    }}
                  >
                    <option value="">Select</option>
                    {reactionsOptions?.map((item, i) => (
                      <option key={i} value={item?.value}>
                        {item?.value}
                      </option>
                    ))}
                  </Input>
                </div>
              </div>

              <div className="reactions-add d-flex gap-2 align-items-center mt-3">
                <div className="d-flex gap-1">
                  <span>Severity</span>
                  <span style={{ color: "red" }}>*</span>
                </div>
                <div className="severity-text d-flex gap-2">
                  {severity?.map((elem, index) => {
                    const { severityName, tagClassName, id } = elem;
                    return (
                      <span
                        className={`d-flex gap-2 align-items-center1 ${tagClassName}`}
                        key={index}
                        id={id}
                        onClick={() => {
                          setSeverityItem(elem);
                        }}
                      >
                        {severityName}{" "}
                        {id === severityItem?.id && (
                          <BsCheckLg style={{ color: "white" }} />
                        )}
                      </span>
                    );
                  })}
                </div>
              </div>

              <div className="d-flex gap-2 mt-3">
                <BsFillTrashFill
                  style={{ color: "red", cursor: "pointer" }}
                  size={25}
                  onClick={() => {
                    setReactionsValue("");
                    setSeverityItem({});
                  }}
                />
                <BsFillCheckCircleFill
                  style={{ color: "blueviolet", cursor: "pointer" }}
                  size={25}
                  onClick={() => {
                    handleUpdateText(reactionsValue);
                    setReactionsValue("");
                    setSeverityItem({});
                  }}
                />
              </div>
            </div>
          )} */}
          {isBehaviourAdd && (
            <Offcanvas
            style={{minWidth: '30%', maxWidth: '30%'}}

              show
              backdrop="static"
              placement="end"
              className="select-group-list-right-behaviour"
              onHide={() => {
                setIsBehaviourAdd(false);
                setReactionsValue("");
                setNotesValue("");
              }}
            >
              <Offcanvas.Header closeButton className="p-2">
                <Offcanvas.Title>Add New Behavior</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="px-2 pb-2 pt-0">
                <div className="mb-2 group-add-name">
                  <div> Behavior Information</div>
                  {/* <div className="mt-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Behaviour Name"
                      onChange={(e) => setBehaviorName(e?.target?.value)}
                    />
                  </div> */}
                  <div className="mt-2">
                    <Input
                      className="reactions-select-dropdown"
                      type="select"
                      name="select"
                      placeholder="Please Select Behavior"
                      value={reactionsValue}
                      onChange={(e) => {
                        setReactionsValue(e?.target?.value);
                      }}
                    >
                      <option value="">Please Select Behavior</option>
                      {allBehaviours?.map((behaviour, i) => (
                        <option key={i} value={behaviour?._id}>
                          {behaviour?.tag}
                        </option>
                      ))}
                    </Input>
                  </div>

                  <div className="mt-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Add Notes (Optional)"
                      onChange={(e) => setNotesValue(e?.target?.value)}
                      value={notesValue}
                    />
                  </div>


                  <div className="d-flex justify-content-end gap-3 mt-3">
                    <button
                    className=" w-[40%] cancel-allergy-btn"
                    onClick={handleCancelBehaviour}
                    >
                      Cancel
                    </button>

                    <button
                        className="w-[40%] btn btn-success"
                        onClick={handleAddBehavior}
                    >
                      SAVE
                    </button>
                  </div>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          )}
        </div>
        {/* <div className="inner">
          <div className="tag-list flex-wrap d-flex justify-content-start">
            {(petDetails?.petCareInfo?.behaviour_tags || []).map(
              (item, index) => (
                <>
                  <div className="b-tags d-flex align-items-center mb-2">
                    <span
                      key={index}
                      className={`d-inline-flex mb-0 ${severityToColor[item?.severity]
                        }`}
                    >
                      {item?.tag && item?.tag !== "" ? item?.tag : item?.severity}
                    </span>
                    {
                      showRemoveIcon && (
                        <button
                          type="button"
                          color="link"
                          className="bg-green-400 rounded-full text-white justify-content-center me-2"
                          onClick={() => handleRemoveTag(item)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-dash-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                          </svg>
                        </button>
                      )
                    }

                  </div>
                </>
              )
            )}
             <span className="d-inline-flex yellow">[tagname]</span>
						<span className="d-inline-flex cyan">Chase-proneness</span> 
          </div>
        </div> */}
      </div>
    </>
  );
};

export default ProfileTags;
