import React, { } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import './index.scss';
const ImagePlaceholder = ({ className = '' }) => {
    return (
        <>
            <div className={`img-placeholder position-relative d-flex align-items-center justify-content-center w-100 h-100 ${className}`}>
                {/* <img className="img-fluid" src={require('../../assets/img/img-placeholder.png')} alt="Placeholder" /> */}
                <span className="no-image d-inline-flex align-items-center justify-content-center position-absolute">
                    <FontAwesomeIcon icon={faImage} />
                </span>
            </div>
        </>
    )
};
export default ImagePlaceholder;
