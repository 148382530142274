import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "reactstrap";

import SwitchCell from "../../../../../shared/SwitchCell";
import AddEditDischargeInstructionDocument from "./AddEditDischargeInstructionDocument";
import { imageBaseUrl, documentPhotosPath } from "../../../../../environment";
import UserPlaceholder from "../../../../../shared/UserPlaceholder";
import { RiDeleteBin5Line } from "react-icons/ri";
import {
  DeleteDischargeInstructionDocument,
  FetchAllDischargeInstructionsForClinic,
} from "../../../../../context/actions/patient-module/discharge-patient-form/discharge-instructions-documents";
import Swal from "sweetalert2";

const DischargeInstructionDocument = ({ list }) => {
  const [isNewIntakeFormModalOpen, setIsNewIntakeFormModalOpen] =
    useState(false);
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState();
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );

  const { dischargeInstructions } = useSelector(
    (state) => state.dischargePatientReducer
  );

  const handleAddPatientIntakeForm = () => {
    setSelectedRow(null);
    setIsNewIntakeFormModalOpen(true);
  };

  const handleEditForm = (item) => {
    setSelectedRow(item);
    setIsNewIntakeFormModalOpen(true);
  };

  const closeAddEditDischargeInstructionPanel = (item) => {
    setSelectedRow(null);
    setIsNewIntakeFormModalOpen(false);
  };

  useEffect(() => {
    if (selectedRow != null) {
      const updatedRow = list.find((item) => item._id === selectedRow._id);
      setSelectedRow(updatedRow);
    }
  }, [list]);


  useEffect(() => {
    if (clinic_settings) {
      dispatch(
        FetchAllDischargeInstructionsForClinic(
          clinic_settings.clinic_id,
          (res) => {}
        )
      );
    }
  }, [clinic_settings]);

  const deleteDischargInstruction = (e, document_id) => {
    e.preventDefault();

    Swal.fire({
      title: "<strong>Are you sure you want to delete this document?</strong>",
      icon: "warning",
      html: `Once deleted, your data cannot be recovered`,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Confirm",
      confirmButtonAriaLabel: "Confirm",
      cancelButtonText: "Cancel",
      cancelButtonAriaLabel: "Cancel",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(
          DeleteDischargeInstructionDocument(
            document_id,
            clinic_settings.clinic_id,
            (response) => {}
          )
        );
      } else if (result.isDenied) {
        Swal.fire("Your data is safe", "", "info");
      } else if (result.isDismissed) {
        Swal.fire("Your data is safe", "", "info");
      }
    });
  };

  const { permissionsData } = useSelector(     (state) => state.clinicPermissionReducer   );
	const permissions = permissionsData;
  return (
    <>
      <div className="form-template-blc">
        <div className="head d-flex">
          <div className="left-col">
            <h3>Discharge Instructions</h3>
            <p>
              
            </p>
          </div>
          {permissions?.add_discharge_instructions &&
            <div className="right-col ms-auto">
              <Button
                onClick={handleAddPatientIntakeForm}
                color="link"
                className="d-inline-flex align-items-center justify-content-center add-btn p-0"
              >
                <span className="icomoon-plus-btn"></span>
              </Button>
            </div>
          }
        </div>
        <div className="table-listing-blc overflow-auto">
          {/* <Table responsive className="checkbox-listing">
            <thead>
              <tr>
                <th style={{ width: "150px" }}>Type</th>
                <th style={{ width: "150px" }}>Specie</th>
                <th style={{ width: "200px" }}>Title</th>
                <th>Notes</th>
                <th>Active</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {(list || []).map((item, index) => (
                <tr key={item.form_title}>
                  <td>{item.form_type}</td>
                  <td>{item.petcategory}</td>
                  <td>{item.form_title}</td>
                  <td>{item.form_notes}</td>
                  <td>
                    <SwitchCell
                      onClick={() => handleUpdateFormStatus(item)}
                      className={item.is_active ? "" : "disable"}
                      labelVisible={item.is_active}
                      label="Active"
                    />
                  </td>
                  <td>
                    <Button size="sm" onClick={() => handleEditForm(item)}>
                      Edit
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table> */}
          <Table responsive className="checkbox-listing">
            <thead>
              <tr>
                <th style={{ minWidth: "192px" }}>Name</th>
                <th style={{ minWidth: "205px" }}>Added By</th>
                <th style={{width: '100%'}}>Notes</th>
                {permissions?.edit_discharge_instructions &&
                  <th style={{textAlign: 'right'}}>Actions</th>
                }
              </tr>
            </thead>
            <tbody>
              {dischargeInstructions &&
                dischargeInstructions.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>
                          <a
                          // href={`${imageBaseUrl}${documentPhotosPath}/${item.document_link}`}
                          href={`${item.document_link}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item?.document_name || '--'} 
                          </a>
                      </td>

                      <td>
                        <div className="p-0 text-start pic-cell d-inline-flex align-items-center">
                          <div className="pic rounded-circle d-inline-flex overflow-hidden">
                            {item?.addedBy?.user_image ? (
                              <img
                                className="rounded-circle w-100 h-100"
                                src={
                                  item?.addedBy?.user_image ||
                                  require("../../../../../assets/img/vetRecorEMR/user-thumbnail.png")
                                }
                                alt="User"
                              />
                            ) : (
                              <UserPlaceholder />
                            )}
                          </div>
                          {`${
                            item?.addedBy?.title
                              ? item?.addedBy?.title + " "
                              : ""
                          } ${item?.addedBy?.firstname} ${
                            item?.addedBy?.lastname
                          }`}
                        </div>
                      </td>
                      <td
                        className="text-clip-cell"
                        style={{ minWidth: "190px", maxWidth: "190px" }}
                      >
                        {item?.notes || '--'}
                      </td>
                      {permissions?.edit_discharge_instructions &&
                        <td style={{textAlign: 'right'}} className="action-cell">
                          <Button
                            color="link"
                            className="edit-btn"
                            onClick={() => {
                              handleEditForm(item);
                            }}
                          >
                            <span className="icomoon-pencil"></span>
                          </Button>

                          <Button
                            onClick={(e) =>
                              deleteDischargInstruction(e, item._id)
                            }
                            color="link"
                            className="edit-btn"
                          >
                            <RiDeleteBin5Line />
                          </Button>
                        </td>
                      }
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>

        {isNewIntakeFormModalOpen && (
          <>
            <div
              className={`patient-panel-overlay position-fixed ${
                isNewIntakeFormModalOpen ? "active" : ""
              }`}
              onClick={() => closeAddEditDischargeInstructionPanel()}
            ></div>

            <AddEditDischargeInstructionDocument
              className={`${isNewIntakeFormModalOpen ? "active" : ""}`}
              closeAddEditDischargeInstructionPanel={() =>
                closeAddEditDischargeInstructionPanel()
              }
              selectedDocument={selectedRow}
              setSelectedDocument={setSelectedRow}
            />
          </>
        )}
      </div>
    </>
  );
};

export default DischargeInstructionDocument;
