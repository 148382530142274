import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonGroup } from "reactstrap";
import { useHistory } from "react-router";
import './index.scss';
import { useState } from "react";



const ScheduleStatus = ({ data, handleUpdate }) => {

    const [status, setStatus] = useState('Confirmed')

    const { permissionsData } = useSelector(
        (state) => state.clinicPermissionReducer
    );
    const permissions = permissionsData;
    return (
        <>
            <div className={`schedule-status-card`}>
                <div className='txt'>
                    <h2>Schedule Status</h2>
                </div>
                    <ButtonGroup >
                        <Button 
                            outline
                            onClick={() => {handleUpdate('confirmed')}}
                            active={data ==='confirmed'}
                            className={`${permissions?.edit_appointments ? '' : 'disabled'}`}
                            
                        >Confirmed</Button>
                        <Button 
                            outline
                            onClick={() => {handleUpdate('waiting room')}}
                            active={data ==='waiting room'}
                            className={`${permissions?.edit_appointments ? '' : 'disabled'}`}
                            
                        >Waiting Room</Button>
                          <Button 
                            outline
                            onClick={() => {handleUpdate('exam room')}}
                            active={data === 'exam room'}
                            className={`${permissions?.edit_appointments ? '' : 'disabled'}`}
                        >Exam Room</Button>
                        <Button 
                            outline
                            onClick={() => {handleUpdate('er')}}
                            active={data === 'er'}
                            className={`${permissions?.edit_appointments ? '' : 'disabled'}`}
                            
                        >ER</Button>
                        <Button 
                            outline
                            onClick={() => {handleUpdate('or')}}
                            active={data === 'or'}
                            className={`${permissions?.edit_appointments ? '' : 'disabled'}`}
                        >OR</Button>
                        <Button 
                            outline
                            onClick={() => {handleUpdate('boarding')}}
                            active={data === 'boarding'}
                            className={`${permissions?.edit_appointments ? '' : 'disabled'}`}
                        >Boarding</Button>
                        <Button 
                            outline
                            onClick={() => {handleUpdate('complete')}}
                            active={data === 'complete'}
                            className={`${permissions?.edit_appointments ? '' : 'disabled'}`}
                        >Complete</Button>
                    </ButtonGroup>
			</div>
		</>
	);
};

export default ScheduleStatus;
