import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";

toast.configure();

export const messagePanelAction = (param) => {
  return (dispatch) => {
    dispatch({ type: "SET_MESSAGEPANEL", data: param });
  };
};

export const getAllAppointmentsByClinicIdForMsg = (id, params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    dispatch({ type: "ISLOADING", data: true });
    ApiClient.get(
      `${apiUrl}${PORT}/appointment/get-all-clinic-appointments/${id}`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      dispatch({ type: "ISLOADING", data: false });
      if (response && !response.errors) {
        return callback(response);
      } else if (response?.code === 404) {
        toast.warn(response.message);
        return callback();
      } else {
        toast.error(response.errors.msg, toasterConfig);
        return callback();
      }
    });
  };
};

export const sendFiles = (body) =>
  async (dispatch, getState) => {
    try {
      const { user: { token } } = getState();
      const response = await ApiClient.postFormData(`${apiUrl}${PORT}/chat/upload-attachments`, body, token, dispatch, getState);
      if (!response) {
        return null;
      }
      else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return response;
      } else if (response?.status) {
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        return response;
      }
    } catch (error) {
      console.log("error", error)
    }
  };





export const getPreSignUrl = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();
    if (user) {
      ApiClient.post(
        `${apiUrl}${PORT}/chat/get-file-path`,
        body,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          // toast.success(response.message, toasterConfig);
          return callback(response);
        } else {
          // toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    } else {
      return callback(null);
    }
  };
};
