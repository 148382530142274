import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  clearUnreadMessageCount,
  fetchChatMessages,
  fetchChatMessagesByFilters,
  sendNewMEssage,
  sendSMS,
  updateMessageForSeenBy,
  updateUnreadCount,
} from "context/actions/realTimeChat";
import NodataFoundBlock from "shared/NoDataFoundBlock";
import { Icons } from "utils/contants/Icons";
import {
  MdOutlineAddAlarm,
  MdOutlineKeyboardVoice,
  MdOutlineMicNone,
} from "react-icons/md";
import { LuAlarmClock, LuCalendarDays } from "react-icons/lu";
import { toast } from "react-toastify";
import useDebounce from "hooks/useDebounce";
import ScheduleVisit from "./ScheduleVisit";
import voiceRecordRange from "../../../assets/img/voice_range.png";
import {
  getAllAppointmentsByClinicIdForMsg,
  sendFiles,
} from "context/actions/messagePanelAction";
import moment from "moment";
import { RiMapPinAddLine } from "react-icons/ri";
import autoMode from "assets/img/autoMode.png";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { AiOutlineClockCircle } from "react-icons/ai";
import { IoMdClose, IoMdCloseCircle } from "react-icons/io";
import { LuMinusCircle } from "react-icons/lu";
import { MdInsertDriveFile } from "react-icons/md";
import {
  setAppointmentDetailData,
  setAppointmentDetailPanel,
} from "context/actions/appointmentDetailPanelAction";
import MicRecorder from "mic-recorder-to-mp3";
import {
  FaRegPauseCircle,
  FaRegPlayCircle,
  FaRegStopCircle,
} from "react-icons/fa";
import { Offcanvas } from "react-bootstrap";
import { toasterConfig } from "utils/constants";
import AudioPlayer from "shared/AudioPlayer";
import { addTask } from "context/actions/taskAndReminder";
import RemainderCard from "./RemainderCard";
const ChatRoom = ({
  roomId,
  chatType,
  isRightSection,
  setChatRoomDetails,
  setIsAppointmentDetailsShow = () => { },
}) => {
  const dispatch = useDispatch();
  const appointmentDetailsState = useSelector(
    (state) => state.appointmentDetailPanelReducer.isAppointmentDetailPanel
  );
  const userLastMessage = useRef(null);
  const { privateChatList } = useSelector((state) => state.realTimeChatReducer);
  const { messagesList, selectedRoom } = useSelector(
    (state) => state.realTimeChatReducer
  );
  const userDetails = useSelector((state) => state.user.user_details);
  const [selectedUser, setSelectedUser] = useState("");
  const [messageListing, setMessageListing] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [messageType, setMessageType] = useState("direct");
  const [msgFilterSelect, setMsgFilterSelect] = useState("");
  const [search, setSearch] = useState("");
  const [scheduleVisit, setScheduleVisit] = useState(false);
  const searchDebouncedValue = useDebounce(search, 300);
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState({});

  const [allFiles, setAllFiles] = useState([]);

  // for send button disable
  const [isSendDisable, setIsSendDisable] = useState(true);
  //for audio recording state
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(new MicRecorder({ bitRate: 128 }));
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [timer, setTimer] = useState(0);
  const [audioFile, setAudioFile] = useState(undefined);
  const [audioPlayer, setAudioPlayer] = useState(new Audio());
  const [isAudioPlay, setIsAudioPlay] = useState(false);

  let chatList = [...privateChatList];

  const [showVoiceMsg, setShowVoiceMsg] = useState(false);

  const [reminderValue, setReminderValue] = useState({
    title: "",
    date: "",
  });
  // for remainder  form hide and show
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);

  var unsub = () => {}

  const handelReminder = (e) => {
    const { name, value } = e.target;
    setReminderValue((reminderValue) => ({ ...reminderValue, [name]: value }));
  };

  chatList = chatList?.filter((chat) => chat?.id === roomId);

  const petOwnerId = chatList
    ?.filter((chat) => chat?.id === roomId)?.[0]
    ?.members?.find((member) => {
      if (member?.role === "pet-owner") {
        return member?.id;
      }
    });
  useEffect(() => {

    if(clinic_details?._id){
    dispatch(
      getAllAppointmentsByClinicIdForMsg(
        /*userDetails._id*/ clinic_details?._id,
        {},
        (result) => {
          if (result && !result.errors) {
            setAppointments(result);
          }
        }
      )
    );
    }
  }, []);
  const removeSelectedAppt = () => {
    setSelectedAppointment({});
  };
  const messageTypeFilterList = [
    { label: "Direct text", value: "Direct Message" },
    { label: "SMS", value: "SMS" },
    { label: "Reminder", value: "Reminder" },
    { label: "Audio", value: "Audio" },
    { label: "Attachment", value: "Attachment" },
    { label: "Link", value: "Link" },
  ];
  const sendMessage = async (e) => {
    e.preventDefault();
    if (isSendDisable || !roomId) return;
    setIsSendDisable(true);

    const payload = {
      createdAt: new Date(),
      is_deleted: false,
      message: newMessage || "",
      message_source: messageType || "direct",
      message_type: newMessage !== "" ? messageType === "SMS" ? ["SMS"] : ['Direct Message'] : [],
      roomId: roomId,
      seen_by: [userDetails?._id],
      sender_id: userDetails?._id,
    };
    if (selectedAppointment?._id) {
      payload.appDetails = {
        _id: selectedAppointment?._id,
        appointment_date: selectedAppointment?.appointment_date || "",
        appointment_time:
          selectedAppointment?.appointment_time ||
          selectedAppointment?.appointment_timings?.[0]?.appointment_time,
        doctor_details: {
          _id: selectedAppointment?.doctor_details?._id,
          firstname: selectedAppointment?.doctor_details?.firstname || "",
          lastname: selectedAppointment?.doctor_details?.lastname || "",
          title: selectedAppointment?.doctor_details?.title || "",
        },
      };

      payload.message_type.push("Appointment");
    }
    if (allFiles?.length > 0) {
      const formData = new FormData();
      allFiles?.forEach((file) => {
        formData.append("attachments", file);
        // formData.append("document_name", file?.name);
      });
      const roomData = chatList
        ?.find((chat) => chat?.id === roomId);
      if (roomData.petDetails?.id) {
        formData.append("pet_id", roomData.petDetails?.id);
        formData.append("added_by", userDetails?._id);
      }
      const res = await dispatch(sendFiles(formData));
      if (res?.status) {
        toast.success(res?.message);
        payload.message_type.push("Attachment");

        payload.attachments = res?.response_data;
      } else {
        toast.error("File not sent!");
      }
    }
    if (audioFile) {
      const formData = new FormData();
      formData.append("attachments", audioFile);
      const resAudio = await dispatch(sendFiles(formData));
      if (resAudio?.status) {
        payload.message_type.push("Audio");

        toast.success(resAudio?.message);
        payload.audios = resAudio?.response_data;
      } else {
        return toast.error("SMS not sent!");
      }
    }
    if (show && (reminderValue.date !== "" && reminderValue.title !== "")) {
      const remainderPayload = {
        type: "reminder",
        name: reminderValue.title,
        date: reminderValue.date,
        assigned_to: chatList?.[0]?.members?.find(ele => ele.id !== userDetails?._id),
        user: userDetails?._id
      }
      dispatch(addTask({ ...remainderPayload, assigned_to: remainderPayload.assigned_to?.id }, (res) => {
        if (res?.status) {
          payload.message_type.push("Reminder");
          payload.reminder = {
            id: res?.response_data?._id,
            ...remainderPayload,
            assigned_to: {
              id: remainderPayload.assigned_to?.id,
              name: remainderPayload.assigned_to?.name
            }
          };
          dispatch(
            sendNewMEssage(payload, () => {
              setNewMessage("");
              setSelectedAppointment({});
              setAllFiles([]);
              setShow(false);
              setReminderValue({
                title: "", date: ""
              })
            })
          );
          dispatch(updateUnreadCount(userDetails?._id, chatType, () => { }));
        }
      })
      );
    } else if (messageType === "sms" && chatType !== "group") {
      const senderData = selectedRoom?.members?.find(
        (details) => details?.id !== userDetails?._id
      );
      payload.message_type = ['SMS']
      setIsSendDisable(true);
      dispatch(
        sendSMS(
          { message: newMessage, contactNumber: senderData?.phone || "" },
          (res) => {
            if (res?.statusCode === 200) {
              toast.success(res?.message);
              dispatch(
                sendNewMEssage(payload, () => {
                  setNewMessage("");
                  setSelectedAppointment({});
                  setAllFiles([]);
                })
              );
              dispatch(updateUnreadCount(userDetails?._id, chatType, () => { }));

            } else {
              toast.error("SMS not sent!");
            }
          }
        )
      );
    } else {
      dispatch(
        sendNewMEssage(payload, () => {
          setNewMessage("");
          setSelectedAppointment({});
          setAllFiles([]);
          setShowVoiceMsg(false);
          setAudioFile(undefined);
          setTimer(0);

          if (!messagesList?.length) {

            const roomData = selectedRoom?.id ? selectedRoom : { id: roomId };
            dispatch(fetchChatMessages(roomData, search, () => { }));
          }
        })
      );
      dispatch(updateUnreadCount(userDetails?._id, chatType, () => { }));
    }
    setIsSendDisable(false);

  };
  useEffect(() => {
    if (messagesList?.length) {
      const sortedMessages = messagesList?.sort(
        (a, b) => a.createdAt.seconds - b.createdAt.seconds
      );
      // Format the date in the desired format
      const formattedMessages = sortedMessages.map((message) => {
        const date = new Date(message.createdAt.seconds * 1000); // Convert seconds to milliseconds
        const formattedDate = date.toLocaleDateString(undefined, {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        });
        const formattedTime = date.toLocaleTimeString(undefined, {
          hour: "numeric",
          minute: "numeric",
        });
        const formattedDateTime = `${formattedDate} at ${formattedTime}`;
        const senderData = selectedRoom?.members?.find(
          (details) => details?.id === message?.sender_id
        );
        // Add the formatted date to the message object
        if (!message?.seen_by?.includes(userDetails?._id)) {
          dispatch(
            updateMessageForSeenBy({
              messageData: message,
              userId: userDetails?._id,
              chatType,
            })
          );
        }
        return { ...message, formattedDateTime, senderData };
      });
      setMessageListing(formattedMessages);
    } else {
      setMessageListing([]);
    }
    return (() => {if(typeof unsub === 'function')unsub()})

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesList]);

  //scroll to the latest message
  useEffect(() => {
    const newUserMsg = userLastMessage.current?.lastElementChild;
    const time = setTimeout(() => {
      if (newUserMsg) newUserMsg?.scrollIntoView({ behavior: "smooth" });
    }, 200);
    return () => clearTimeout(time);
  }, [messageListing]);

  useEffect(() => {
    if (!roomId) {
      console.log(" clear state called")
      dispatch({ type: "SELECT_ROOM", payload: {} });
      dispatch({ type: "MESSAGE_LIST", payload: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return (() => {if(typeof unsub === 'function')unsub()})

  }, []);

  const fetchMessagesBySenderId = () => {
    if (!roomId) return () =>{};
    if (selectedUser !== "" && msgFilterSelect !== "") {
      return dispatch(
        fetchChatMessagesByFilters(
          {
            senderId: selectedUser,
            searchKeyword: search,
            messageType: msgFilterSelect,
          },
          roomId,
          () => { }
        )
      );
    } else if (selectedUser === "" && msgFilterSelect !== "") {
      return dispatch(
        fetchChatMessagesByFilters(
          { searchKeyword: search, messageType: msgFilterSelect },
          roomId,
          () => { }
        )
      );
    } else if (selectedUser !== "" && msgFilterSelect === "") {
      return dispatch(
        fetchChatMessagesByFilters(
          { senderId: selectedUser, searchKeyword: search },
          roomId,
          () => { }
        )
      );
    } else {
      const roomDetails = selectedRoom?.id ? selectedRoom : { id: roomId }
      return dispatch(fetchChatMessages(roomDetails, search, () => { }));
    }
  };
  useEffect(() => {
    const unsubscribe = fetchMessagesBySenderId(); // get message according to search and filter
    unsub = unsubscribe
    // return ( () => {if(typeof unsubscribe === 'function')unsubscribe()})
  }, [searchDebouncedValue, selectedUser, msgFilterSelect]);
  useEffect(() => {
    dispatch(clearUnreadMessageCount(chatType, userDetails?._id));
    setSelectedUser("");
    setSearch("");
    setMsgFilterSelect("");
    setNewMessage("");
    setSelectedAppointment({});
    setAllFiles([]);
    dispatch(setAppointmentDetailPanel(false));
    // return (() => {unsub()})
  }, [roomId]);

  const handleOnFileChange = (e) => {
    const file = e.target.files[0];
    let files = [...allFiles];
    files?.push(file);
    setAllFiles(files);
  };

  const deleteFile = (j) => {
    let files = [...allFiles];
    files.splice(j, 1);
    setAllFiles((prev) => [...files]);
  };
  useEffect(() => {
    if (
      newMessage !== "" ||
      selectedAppointment.hasOwnProperty("_id") ||
      allFiles?.length > 0 ||
      !!audioFile || (reminderValue.date !== "" && reminderValue.title !== "")
    ) {
      setIsSendDisable(false);
    } else {
      setIsSendDisable(true);
    }

  }, [allFiles, selectedAppointment, newMessage, audioFile, reminderValue]);

  const showAppointmentDetails = (AppointmentId) => {
    const appointmentData = appointments?.find(
      (app) => app._id === AppointmentId
    );
    setIsAppointmentDetailsShow();
    dispatch(setAppointmentDetailData(appointmentData));
    dispatch(setAppointmentDetailPanel(!appointmentDetailsState));
  };

  const startRecording = () => {
    setShowVoiceMsg(true);
    recorder
      .start()
      .then(() => {
        setIsTimerRunning(true);
        setIsRecording(true);
      })
      .catch((e) => console.error(e));
  };

  const stopRecording = (isSave) => {
    setIsTimerRunning(false);
    recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        if (isSave) {
          setAudioFile(blob);
        }
        setIsRecording(false);
      })
      .catch((e) => console.error(e));
  };
  const playAudio = () => {
    if (audioFile) {
      const newAudioPlayer = new Audio(URL.createObjectURL(audioFile));
      newAudioPlayer.play();
      newAudioPlayer.getAttribute("src");
      setAudioPlayer(newAudioPlayer);
      setIsAudioPlay(true);
    }
  };

  const stopAudio = () => {
    if (audioPlayer) {
      audioPlayer.pause();
      setIsAudioPlay(false);
    }
  };
  useEffect(() => {
    let timerId;

    if (isTimerRunning) {
      timerId = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }

    return () => clearInterval(timerId);
  }, [isTimerRunning]);
  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };
  return (
    <>
      <div
        className={`${isRightSection ? "col-12" : "col-md-8 col-xl-9 col-lg-9 h-full "
          } ps-sm-0 ps-3 d-flex`}
      >
        <div className="h-full pe-2 w-full" style={{ overflow: "hidden" }}>
          <header>
            {isRightSection && (
              <button
                type="button"
                class=" back-btn ps-0 btn btn-link"
                onClick={() =>
                  setChatRoomDetails({ room_id: undefined, type: undefined })
                }
              >
                Go Back
              </button>
            )}
            <div className="row">
              {chatType === "group" ? (
                <div
                  className={`${isRightSection ? "col-12" : "col-sm-3 col-11"
                    } font-semibold text-[18px] text-[#282E65] d-flex align-items-center`}
                >
                  {selectedRoom?.roomName}
                </div>
              ) : null}
              {!isRightSection && (
                <div
                  className={`${chatType === "group" ? "col-sm-9 col-12" : "col-12"
                    }`}
                >
                  <div
                    className={`flex items-center mx-0 gap-2 my-2 ${chatType === "group" ? "justify-content-end" : ""
                      }`}
                  >
                    <div className="w-[40%] relative">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <Icons.SearchIcon />
                      </div>
                      <input
                        type="search"
                        id="default-search"
                        className="block w-full !py-[18px] pr-1 pl-10 text-base text-gray-900 h-10 rounded-lg border bg-white focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Search"
                        required=""
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>

                    <div className="w-[30%]">
                      <select
                        className="border w-full py-2 px-2 rounded-lg"
                        aria-label="users"
                        onChange={(e) => setSelectedUser(e.target.value)}
                        value={selectedUser}
                      >
                        <option value="">All Persons</option>
                        {selectedRoom?.members?.map((user) => (
                          <option value={user?.id} key={user?.id}>
                            {user?.id !== userDetails?._id ? user?.name : "You"}
                          </option>
                        ))}
                      </select>
                    </div>
                    {
                      chatType === "private" ? (
                        <div className="w-[30%]">
                          <select
                            className="border w-full py-2 px-2 rounded-lg"
                            aria-label="messages type"
                            value={msgFilterSelect}
                            onChange={(e) => setMsgFilterSelect(e.target.value)}
                          >
                            <option value="">All Messages</option>
                            {messageTypeFilterList?.map((opt) => (
                              <option value={opt.value} key={opt.value}>
                                {opt.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : (
                        // {
                        //     selectedRoom.userImgs.length > 6 &&
                        <div className="d-flex gap-1 align-items-center justify-content-center">
                          {selectedRoom?.userImgs
                            ?.slice(0, 5)
                            ?.map((image, i) => {
                              return (
                                <img
                                  src={
                                    image ||
                                    require("../../../assets/img/appoint-profile.png")
                                  }
                                  style={{ width: 32, height: 32 }}
                                  alt="profileImage"
                                  className="rounded-lg me-1"
                                  key={i}
                                />
                              );
                            })}
                        </div>
                      )
                      // }
                    }
                  </div>
                </div>
              )}
            </div>
          </header>
          <div
            className={`${isRightSection ? "chat-panel-250" : "chat-panel-140"
              }`}
          >
            {messageListing?.length ? (
              <>
                {messageListing?.map((msgData, i) => {
                  const isUserDataShow =
                    msgData?.formattedDateTime ===
                    messageListing?.[i - 1]?.formattedDateTime &&
                    msgData?.sender_id === messageListing?.[i - 1]?.sender_id;
                  return (
                    <React.Fragment key={i}>
                      {msgData?.sender_id !== userDetails?._id ? (
                        <div
                          className="row gx-0"
                          id={i === messageListing?.length - 1 ? "last" : ""}
                          ref={
                            i === messageListing?.length - 1
                              ? userLastMessage
                              : null
                          }
                        >
                          <div className="col-md-9 ps-3 d-flex">
                            {!isUserDataShow ? (
                              <img
                                src={require("../../../assets/img/avatarPic.png")}
                                style={{ width: 32, height: 32 }}
                                className="rounded-circle"
                                alt=""
                              />
                            ) : (
                              <div style={{ width: "32px" }} />
                            )}
                            <div className="ms-2 mb-3">
                              {!isUserDataShow && (
                                <div className="d-flex align-items-center">
                                  <p className="chater-name">
                                    {msgData?.senderData?.name}
                                  </p>
                                  <span className="chat-date ms-1">
                                    {msgData?.formattedDateTime}
                                  </span>
                                  <span className="chat-tagname ms-1">
                                    {Array.isArray(msgData?.message_type)
                                      ? msgData?.message_type?.[0]
                                      : msgData?.message_type}
                                  </span>
                                </div>
                              )}
                              <div className="chat-bubble chat-bubble--left">
                                {Array.isArray(msgData?.message_type) ? (
                                  <>
                                    {msgData?.message_type?.map((types, i) => (
                                      <React.Fragment key={i}>
                                        {(types === "Direct Message" ||
                                          types === "SMS") && (
                                            <span>{msgData?.message}</span>
                                          )}
                                        {types === "Appointment" &&
                                          msgData?.appDetails && (
                                            <div className="py-1">
                                              <div id="next-upcoming-container"
                                                onClick={() =>
                                                  showAppointmentDetails(
                                                    msgData?.appDetails?._id
                                                  )
                                                }
                                              >
                                                <div className="item-block">
                                                  <div className="data-block">
                                                    <div className="d-flex gap-2">
                                                      <div className="f-center bg-eeee color-cfcf h-49 w-49 rounded-circle">
                                                        <span className="material-symbols-outlined text-24-600">
                                                          person
                                                        </span>
                                                      </div>
                                                      <div className="flex-grow-1">
                                                        <div className="text-16-400 color-2E65">
                                                          {
                                                            msgData?.appDetails
                                                              ?.doctor_details
                                                              ?.title
                                                          }{" "}
                                                          {
                                                            msgData?.appDetails
                                                              ?.doctor_details
                                                              ?.firstname
                                                          }{" "}
                                                          {
                                                            msgData?.appDetails
                                                              ?.doctor_details
                                                              ?.lastname
                                                          }
                                                        </div>
                                                        <div className="text-13-400 color-2E65">
                                                          DVM
                                                        </div>
                                                      </div>
                                                      <div className="pt-1">
                                                        <img
                                                          src={autoMode}
                                                          alt="load"
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="fa-center gap-2 mt-2">
                                                      <div className="calendar-icon">
                                                        <MdOutlineCalendarMonth />
                                                      </div>
                                                      <div className="fa-center gap-2 text-14-400 color-2E65">
                                                        <div>
                                                          {moment(
                                                            msgData?.appDetails
                                                              ?.appointment_date,
                                                            "DD-MM-YYYY"
                                                          ).format(
                                                            "MMM D, YYYY"
                                                          )}
                                                          <span>
                                                            {new Date() >
                                                              new Date(
                                                                msgData?.appDetails?.appointment_date
                                                              )
                                                              ? ` In ${Math.round(
                                                                (new Date().getTime() -
                                                                  new Date(
                                                                    msgData?.appDetails?.appointment_date
                                                                  ).getTime()) /
                                                                (1000 *
                                                                  3600 *
                                                                  24)
                                                              )} Days`
                                                              : new Date().getTime() ===
                                                                new Date(
                                                                  msgData?.appDetails?.appointment_date
                                                                ).getTime() &&
                                                                moment(
                                                                  msgData?.appDetails?.appointment_time?.substring(
                                                                    0,
                                                                    6
                                                                  )
                                                                ).format(
                                                                  "HH:mmA"
                                                                ) >
                                                                moment(
                                                                  new Date()
                                                                ).format(
                                                                  "hh:mmA"
                                                                )
                                                                ? "In hours"
                                                                : ""}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="fa-center gap-2 mt-2">
                                                      <div>
                                                        <AiOutlineClockCircle />
                                                      </div>
                                                      <div className="text-14-400 color-2E65">
                                                        {msgData?.appDetails
                                                          ?.appointment_time ||
                                                          msgData?.appDetails
                                                            ?.appointment_timings?.[0]
                                                            ?.appointment_time}
                                                      </div>
                                                    </div>
                                                    <div className="fa-center gap-2 mt-2">
                                                      <div>
                                                        <RiMapPinAddLine />
                                                      </div>
                                                      <div className="text-14-400 color-2E65">
                                                        West Coast Clinic
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        {types === "Attachment" &&
                                          msgData?.attachments?.length > 0 && (
                                            <>
                                              {msgData?.attachments?.map(
                                                (file, j) => {
                                                  const extensionKey =
                                                    !file?.name
                                                      ? file
                                                      : file?.name;
                                                  const sliceFileURL = extensionKey.split("?")?.[0];
                                                  if (
                                                    [
                                                      "jpg",
                                                      "jpeg",
                                                      "png",
                                                      "gif",
                                                    ].includes(
                                                      sliceFileURL?.substring(
                                                        sliceFileURL?.lastIndexOf(
                                                          "."
                                                        ) + 1
                                                      )
                                                    )
                                                  ) {
                                                    const imgPath = file?.name
                                                      ? URL.createObjectURL(
                                                        file
                                                      )
                                                      : file;
                                                    return (
                                                      <div
                                                        className="position-relative"
                                                        key={j}
                                                      >
                                                        <a
                                                          href={imgPath}
                                                          target="__blank"
                                                        >
                                                          <img
                                                            className="file_box"
                                                            src={imgPath}
                                                            alt="img"
                                                          />
                                                        </a>
                                                      </div>
                                                    );
                                                  } else if (
                                                    ["pdf"].includes(
                                                      sliceFileURL?.substring(
                                                        sliceFileURL?.lastIndexOf(
                                                          "."
                                                        ) + 1
                                                      )
                                                    )
                                                  ) {
                                                    return (
                                                      <div
                                                        className="position-relative"
                                                        key={j}
                                                      >
                                                        <a
                                                          href={extensionKey}
                                                          target="__blank"
                                                        >
                                                          <img
                                                            className="file_box"
                                                            src={require("../../../assets/img/pdf.png")}
                                                            alt="img"
                                                          />
                                                        </a>
                                                      </div>
                                                    );
                                                  } else if (
                                                    ["mp3", "mp4"].includes(
                                                      sliceFileURL?.substring(
                                                        sliceFileURL?.lastIndexOf(
                                                          "."
                                                        ) + 1
                                                      )
                                                    )
                                                  ) {
                                                    return (
                                                      <div
                                                        className="position-relative"
                                                        key={j}
                                                      >
                                                        <a
                                                          href={extensionKey}
                                                          target="__blank"
                                                        >
                                                          <img
                                                            className="file_box"
                                                            src={require(`../../../assets/img/mp4.png`)}
                                                            alt="img"
                                                          />
                                                        </a>
                                                      </div>
                                                    );
                                                  } else {
                                                    return (
                                                      <div
                                                        className="position-relative"
                                                        key={j}
                                                      >
                                                        <a
                                                          href={extensionKey}
                                                          target="__blank"
                                                        >
                                                          <MdInsertDriveFile
                                                            size={50}
                                                          />
                                                        </a>
                                                      </div>
                                                    );
                                                  }
                                                }
                                              )}
                                            </>
                                          )}
                                        {types === "Audio" && (
                                          <>
                                            <AudioPlayer
                                              audioFile={
                                                msgData?.audios?.[0]
                                              }
                                            />
                                          </>
                                        )}
                                        {types === "Reminder" && msgData?.reminder?.id && (
                                          <RemainderCard task={msgData?.reminder} />
                                        )

                                        }
                                      </React.Fragment>
                                    ))}
                                  </>
                                ) : (
                                  msgData?.message
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        // for user send
                        <div
                          ref={
                            i === messageListing?.length - 1
                              ? userLastMessage
                              : null
                          }
                          className="row justify-content-end "
                        >
                          <div className="col-md-9 ">
                            {!isUserDataShow && (
                              <div className="d-flex align-items-center ms-auto">
                                <span className="chat-date text-end w-100">
                                  {msgData?.formattedDateTime}
                                </span>
                              </div>
                            )}
                            <div className="chat-bubble chat-bubble--right">
                              {Array.isArray(msgData?.message_type) ? (
                                <>
                                  {msgData?.message_type?.map((types, i) => (
                                    <React.Fragment key={i}>
                                      {(types === "Direct Message" ||
                                        types === "SMS") && (
                                          <span>{msgData?.message}</span>
                                        )}
                                      {types === "Appointment" &&
                                        msgData?.appDetails && (
                                          <div className="pt-1">
                                            <div
                                              id="next-upcoming-container"
                                              onClick={() =>
                                                showAppointmentDetails(
                                                  msgData?.appDetails?._id
                                                )
                                              }
                                            >
                                              <div className="item-block">
                                                <div className="data-block">
                                                  <div className="d-flex gap-2">
                                                    <div className="f-center bg-eeee color-cfcf h-49 w-49 rounded-circle">
                                                      <span className="material-symbols-outlined text-24-600">
                                                        person
                                                      </span>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                      <div className="text-16-400 color-2E65">
                                                        {
                                                          msgData?.appDetails
                                                            ?.doctor_details
                                                            ?.title
                                                        }{" "}
                                                        {
                                                          msgData?.appDetails
                                                            ?.doctor_details
                                                            ?.firstname
                                                        }{" "}
                                                        {
                                                          msgData?.appDetails
                                                            ?.doctor_details
                                                            ?.lastname
                                                        }
                                                      </div>
                                                      <div className="text-13-400 color-2E65">
                                                        DVM
                                                      </div>
                                                    </div>
                                                    <div className="pt-1">
                                                      <img
                                                        src={autoMode}
                                                        alt="load"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="fa-center gap-2 mt-2">
                                                    <div className="calendar-icon">
                                                      <MdOutlineCalendarMonth />
                                                    </div>
                                                    <div className="fa-center gap-2 text-14-400 color-2E65">
                                                      <div>
                                                        {moment(
                                                          msgData?.appDetails
                                                            ?.appointment_date,
                                                          "DD-MM-YYYY"
                                                        ).format("MMM D, YYYY")}
                                                        <span>
                                                          {new Date() >
                                                            new Date(
                                                              msgData?.appDetails?.appointment_date
                                                            )
                                                            ? ` In ${Math.round(
                                                              (new Date().getTime() -
                                                                new Date(
                                                                  msgData?.appDetails?.appointment_date
                                                                ).getTime()) /
                                                              (1000 *
                                                                3600 *
                                                                24)
                                                            )} Days`
                                                            : new Date().getTime() ===
                                                              new Date(
                                                                msgData?.appDetails?.appointment_date
                                                              ).getTime() &&
                                                              moment(
                                                                msgData?.appDetails?.appointment_time?.substring(
                                                                  0,
                                                                  6
                                                                )
                                                              ).format(
                                                                "HH:mmA"
                                                              ) >
                                                              moment(
                                                                new Date()
                                                              ).format(
                                                                "hh:mmA"
                                                              )
                                                              ? "In hours"
                                                              : ""}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="fa-center gap-2 mt-2">
                                                    <div>
                                                      <AiOutlineClockCircle />
                                                    </div>
                                                    <div className="text-14-400 color-2E65">
                                                      {msgData?.appDetails
                                                        ?.appointment_time ||
                                                        msgData?.appDetails
                                                          ?.appointment_timings?.[0]
                                                          ?.appointment_time}
                                                    </div>
                                                  </div>
                                                  <div className="fa-center gap-2 mt-2">
                                                    <div>
                                                      <RiMapPinAddLine />
                                                    </div>
                                                    <div className="text-14-400 color-2E65">
                                                      West Coast Clinic
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      {types === "Attachment" &&
                                        msgData?.attachments?.length > 0 && (
                                          <>
                                            {msgData?.attachments?.map(
                                              (file, j) => {
                                                const extensionKey = !file?.name
                                                  ? file
                                                  : file?.name;
                                                const sliceFileURL = extensionKey.split("?")?.[0];
                                                if (
                                                  [
                                                    "jpg",
                                                    "jpeg",
                                                    "png",
                                                    "gif",
                                                  ].includes(
                                                    sliceFileURL?.substring(
                                                      sliceFileURL?.lastIndexOf(
                                                        "."
                                                      ) + 1
                                                    )
                                                  )
                                                ) {
                                                  const imgPath = file?.name
                                                    ? URL.createObjectURL(file)
                                                    : file;
                                                  return (
                                                    <div
                                                      className="position-relative"
                                                      key={j}
                                                    >
                                                      <a
                                                        href={imgPath}
                                                        target="__blank"
                                                      >
                                                        <img
                                                          className="file_box"
                                                          src={imgPath}
                                                          alt="img"
                                                        />
                                                      </a>
                                                    </div>
                                                  );
                                                } else if (
                                                  ["pdf"].includes(
                                                    sliceFileURL?.substring(
                                                      sliceFileURL?.lastIndexOf(
                                                        "."
                                                      ) + 1
                                                    )
                                                  )
                                                ) {
                                                  return (
                                                    <div
                                                      className="position-relative"
                                                      key={j}
                                                    >
                                                      <a
                                                        href={extensionKey}
                                                        target="__blank"
                                                      >
                                                        <img
                                                          className="file_box"
                                                          src={require("../../../assets/img/pdf.png")}
                                                          alt="img"
                                                        />
                                                      </a>
                                                    </div>
                                                  );
                                                } else if (
                                                  ["mp3", "mp4"].includes(
                                                    sliceFileURL?.substring(
                                                      sliceFileURL?.lastIndexOf(
                                                        "."
                                                      ) + 1
                                                    )
                                                  )
                                                ) {
                                                  return (
                                                    <div
                                                      className="position-relative"
                                                      key={j}
                                                    >
                                                      <a
                                                        href={extensionKey}
                                                        target="__blank"
                                                      >
                                                        <img
                                                          className="file_box"
                                                          src={require(`../../../assets/img/mp4.png`)}
                                                          alt="img"
                                                        />
                                                      </a>
                                                    </div>
                                                  );
                                                } else {
                                                  return (
                                                    <div
                                                      className="position-relative"
                                                      key={j}
                                                    >
                                                      <a
                                                        href={extensionKey}
                                                        target="__blank"
                                                      >
                                                        <MdInsertDriveFile
                                                          size={50}
                                                        />
                                                      </a>
                                                    </div>
                                                  );
                                                }
                                              }
                                            )}
                                          </>
                                        )}
                                      {types === "Audio" && (
                                        <>
                                          <AudioPlayer
                                            audioFile={
                                              msgData?.audios?.[0]
                                            }
                                          />
                                        </>
                                      )}
                                      {types === "Reminder" && msgData?.reminder?.id && (
                                        <RemainderCard task={msgData?.reminder} />)}
                                    </React.Fragment>
                                  ))}
                                </>
                              ) : (
                                msgData?.message
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <NodataFoundBlock />
            )}
          </div>
          <div className="row position-relative">
            <div className="col-12">
              <div className="appointment-card-in-input">
                {selectedAppointment?._id ? (
                  <div id="next-upcoming-container py-3">
                    <div className="item-block">
                      <div className="position-relative">
                        <div className="data-block">
                          <div className="d-flex gap-2">
                            <div className="f-center bg-eeee color-cfcf h-49 w-49 rounded-circle">
                              <span className="material-symbols-outlined text-24-600">
                                person
                              </span>
                            </div>
                            <div className="flex-grow-1">
                              <div className="text-16-400 color-2E65">
                                {selectedAppointment?.doctor_details?.title}{" "}
                                {
                                  selectedAppointment?.doctor_details
                                    ?.firstname
                                }{" "}
                                {
                                  selectedAppointment?.doctor_details
                                    ?.lastname
                                }
                              </div>
                              <div className="text-13-400 color-2E65">
                                DVM
                              </div>
                            </div>
                            <div
                              className=" mb-3"
                              onClick={() => {
                                removeSelectedAppt();
                              }}
                            >
                              <LuMinusCircle color={"red"} />
                            </div>
                            {/* <div className="pt-1">
                                <img src={autoMode} alt="load" />
                              </div> */}
                          </div>
                          <div className="fa-center gap-2 mt-2">
                            <div className="calendar-icon">
                              <MdOutlineCalendarMonth />
                            </div>
                            <div className="fa-center gap-2 text-14-400 color-2E65">
                              <div>
                                {moment(
                                  selectedAppointment?.appointment_date,
                                  "DD-MM-YYYY"
                                ).format("MMM D, YYYY")}
                                <span>
                                  {new Date() >
                                    new Date(
                                      selectedAppointment?.appointment_date
                                    )
                                    ? ` In ${Math.round(
                                      (new Date().getTime() -
                                        new Date(
                                          selectedAppointment?.appointment_date
                                        ).getTime()) /
                                      (1000 * 3600 * 24)
                                    )} Days`
                                    : new Date().getTime() ===
                                      new Date(
                                        selectedAppointment?.appointment_date
                                      ).getTime() &&
                                      moment(
                                        selectedAppointment?.appointment_time?.substring(
                                          0,
                                          6
                                        )
                                      ).format("HH:mmA") >
                                      moment(new Date()).format("hh:mmA")
                                      ? "In hours"
                                      : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="fa-center gap-2 mt-2">
                            <div>
                              <AiOutlineClockCircle />
                            </div>
                            <div className="text-14-400 color-2E65">
                              {selectedAppointment?.appointment_time ||
                                selectedAppointment?.appointment_timings?.[0]
                                  ?.appointment_time}
                            </div>
                          </div>
                          <div className="fa-center gap-2 mt-2">
                            <div>
                              <RiMapPinAddLine />
                            </div>
                            <div className="text-14-400 color-2E65">
                              West Coast Clinic
                            </div>
                          </div>
                        </div>

                        {/* <div className="mx-3 cursor-pointer">
                            
                            <IoMdClose onClick={() => removeSelectedAppt()} />
                          </div> */}
                      </div>
                    </div>
                  </div>
                ) : null}

                {allFiles?.length > 0 && (
                  <div className="add_files py-3">
                    {allFiles?.map((file, j) => {
                      const extensionKey = !file?.name ? file : file?.name;
                      if (
                        ["jpg", "jpeg", "png", "gif"].includes(
                          extensionKey?.substring(
                            extensionKey?.lastIndexOf(".") + 1
                          )
                        )
                      ) {
                        const imgPath = file?.name
                          ? URL.createObjectURL(file)
                          : file;
                        return (
                          <div className="position-relative" key={j}>
                            <img className="file_box" src={imgPath} alt="img" />
                            <span
                              className="file-delete-icon"
                              onClick={() => {
                                deleteFile(j);
                              }}
                            >
                              <LuMinusCircle color={"red"} />
                            </span>
                          </div>
                        );
                      } else if (
                        ["pdf"].includes(
                          extensionKey?.substring(
                            extensionKey?.lastIndexOf(".") + 1
                          )
                        )
                      ) {
                        return (
                          <div className="position-relative" key={j}>
                            <img
                              className="file_box"
                              src={require("../../../assets/img/pdf.png")}
                              alt="img"
                            />
                            <span
                              className="file-delete-icon"
                              onClick={() => {
                                deleteFile(j);
                              }}
                            >
                              <LuMinusCircle color={"red"} />
                            </span>
                          </div>
                        );
                      } else if (
                        ["mp3", "mp4"].includes(
                          extensionKey?.substring(
                            extensionKey?.lastIndexOf(".") + 1
                          )
                        )
                      ) {
                        return (
                          <div className="position-relative" key={j}>
                            <img
                              className="file_box"
                              src={require(`../../../assets/img/mp4.png`)}
                              alt="img"
                            />
                            <span
                              className="file-delete-icon"
                              onClick={() => {
                                deleteFile(j);
                              }}
                            >
                              <LuMinusCircle color={"red"} />
                            </span>
                          </div>
                        );
                      } else {
                        return (
                          <div className="position-relative" key={j}>
                            <MdInsertDriveFile size={50} />
                            <span
                              className="file-delete-icon"
                              onClick={() => {
                                deleteFile(j);
                              }}
                            >
                              <LuMinusCircle color={"red"} />
                            </span>
                          </div>
                        );
                      }
                    })}
                  </div>
                )}
              </div>
            </div>

            <div className="col-12">
              <form className="chat-box-tray" onSubmit={sendMessage}>
                {/* {chatType === "group" ? (
                  <>
                    <input
                      type="text"
                      placeholder="Start writing message..."
                      value={newMessage}
                      onChange={(e) => {
                        setNewMessage(e.target.value);
                      }}
                      className="private-Message-input"
                      disabled={!roomId}
                    />
                    <button
                      className="btn btn-primary py-1"
                      disabled={!roomId || newMessage === ""}
                      type="submit"
                    >
                      Send
                    </button>
                  </>
                ) : ( */}
                <>
                  <button type="button"
                    onClick={() =>
                      setMessageType((prev) =>
                        prev === "direct" ? "sms" : "direct"
                      )
                    }
                    className="message-type"
                    disabled={!roomId || userDetails?.role === "pet-owner" || chatType === 'group'}
                  >
                    <span>
                      {messageType === "direct" ? "Message" : "SMS"}
                    </span>
                    <Icons.DownArrow />
                  </button>

                  <input
                    type="text"
                    placeholder="Start writing message..."
                    value={newMessage}
                    onChange={(e) => {
                      setNewMessage(e.target.value);
                    }}
                    className="private-Message-input"
                    disabled={!roomId}
                  />

                  {showVoiceMsg && (
                    <div className="voice_message_box">
                      <MdOutlineKeyboardVoice />
                      <p>
                        {isAudioPlay
                          ? "Recording Playing..."
                          : "Voice Recording..."}
                      </p>
                      {/* {audioFile && <AudioPlayer audioFile={URL.createObjectURL(audioFile)} />} */}
                      {/* <img src={voiceRecordRange} alt="" /> */}


                      {!isAudioPlay && <p>{formatTime(timer)}</p>}
                      {isRecording && (
                        <FaRegStopCircle
                          onClick={() => stopRecording(true)}
                        />
                      )}
                      {audioFile && !isAudioPlay ? (
                        <FaRegPlayCircle onClick={playAudio} />
                      ) : null}
                      {audioFile && isAudioPlay ? (
                        <FaRegPauseCircle onClick={stopAudio} />
                      ) : null}
                      <IoMdCloseCircle
                        onClick={() => {
                          stopRecording(false);
                          setAudioFile(undefined);
                          setShowVoiceMsg(false);
                          setIsAudioPlay(false);
                          setTimer(0);
                        }}
                      />
                    </div>
                  )}

                  {show && chatType !== 'group'(
                    <div className="reminder ">
                      <div className="clock_box">
                        <LuAlarmClock />
                        {/* <p>Reminder</p> */}
                      </div>

                      <div className="select_box">
                        <input
                          type="datetime-local"
                          name="date"
                          value={reminderValue.date}
                          onChange={handelReminder}
                        />
                      </div>

                      <input
                        type="text"
                        placeholder="Add Text..."
                        className="add_nots"
                        name="title"
                        value={reminderValue.title}
                        onChange={handelReminder}
                      />
                      <IoMdCloseCircle
                        className="clock_close_btn"
                        onClick={() => {
                          setShow(false);
                          setReminderValue({
                            title: "",
                            date: "",
                          })
                        }}
                      />
                    </div>
                  )}
                  <div className="d-flex align-items-center justify-content-center gap-2 me-2">
                    {isRightSection ? (
                      <button
                        className="cursor-pointer "
                        type="button"
                        disabled={!roomId}
                      >
                        <Icons.AttachFileIcon width={20} height={20} />
                      </button>
                    ) : (
                      <>
                        {messageType === "direct" ? (
                          <>
                            {!showVoiceMsg && !show && (
                              <div className="d-flex gap-3">
                                <button
                                  className="cursor-pointer attachment-btn"
                                  disabled={!roomId}
                                  type="button"
                                >
                                  <Icons.AttachFileIcon
                                    width={20}
                                    height={20}
                                  />
                                  <input
                                    type="file"
                                    id="myfile"
                                    name="myfile"
                                    disabled={!roomId}
                                    onChange={handleOnFileChange}
                                  />
                                </button>
                                <button
                                  type="button"
                                  className="cursor-pointer"
                                  disabled={!roomId}
                                >
                                  <LuCalendarDays
                                    color="#3D3EFD"
                                    width={20}
                                    height={20}
                                    onClick={() => setScheduleVisit(true)}
                                  />
                                </button>

                                <button
                                  type="button"
                                  className="cursor-pointer"
                                  onClick={startRecording}
                                  disabled={!roomId}
                                >
                                  <MdOutlineMicNone
                                    color="#3D3EFD"
                                    width={20}
                                    height={20}
                                  />
                                </button>

                                {!petOwnerId?.id && chatType !== 'group' && (
                                  <button
                                    className="cursor-pointer"
                                    disabled={!roomId}
                                  >
                                    <MdOutlineAddAlarm
                                      // onClick={() => setShowReminder(true)}
                                      onClick={handleShow}
                                      color="#3D3EFD"
                                      width={20}
                                      height={20}
                                    />
                                  </button>
                                )}
                              </div>
                            )}
                          </>
                        ) : null}
                      </>
                    )}
                    <button
                      className="btn send_btn btn-primary py-1"
                      disabled={!roomId || isSendDisable}
                      type="submit"
                    >
                      Send
                    </button>
                  </div>
                </>

              </form>
            </div>
          </div>
        </div>
      </div>
      {scheduleVisit && (
        <ScheduleVisit
          setScheduleVisit={setScheduleVisit}
          scheduleVisit={scheduleVisit}
          appointments={appointments}
          setSelectedAppointment={setSelectedAppointment}
          roomId={roomId}
          chatList={chatList?.[0]}
        />
      )}
    </>
  );
};

export default ChatRoom;
