import React, { useEffect, useState } from "react";
import WaitingRoomList from "./WaitingRoomList";
import {
  FormGroup,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  Button,
} from "reactstrap";
import OtherPreferences from "./OtherPreferences";
import { useDispatch, useSelector } from "react-redux";
import { getWaitingRoomList } from "../../../../context/actions/waitingRoom";
import "./index.scss";
import NodataFoundBlock from "../../../../shared/NoDataFoundBlock";
import AddOr from "./AddOr";
import OperatingRoomList from "./OperatingRoomList";
import EditSpeciesAges from "./EditSpeciesAges";
import KennelsList from "./Kennels";
import AddKennel from "./AddKennel";
import ColorCodes from "./ColorCodes";
import EditColorCode from "./EditColorCode";
import { appointmentTypes } from "../../../../utils/constants";
import EditAppointmentTypeDuration from "./EditAppointmentTypeDuration";
import EditAppointmentTypeTasks from "./EditAppointmentTypeTasks";
import AppointmentTypeTasks from "./AppointmentTypeTasks";
import TagsAndColors from "./TagsAndColors";
import SpeciesAgePreferences from "./SpeciesAgePreferences";
import ReminderTimes from "./ReminderTimes";
import EditReminderTimes from "./EditReminderTimes";
import AddWaitingRoom from "./AddWaitingRoom";
import MedicationDelayThreshold from "./MedicationDelayThreshold";
import PetOwnerPreferences from "./PetOwnerPreferences"

const Preferences = () => {
  const [dropdownOpen, setOpen] = useState(false);
  const [newPointPanelState, setNewPointPanelState] = useState(false);
  const [addNewKennel, setAddNewKennel] = useState(false);
  const [isAddWaitingRoom, setIsAddWaitingRoom] = useState(false);
  const [editData, setEditData] = useState(null);

  const toggle = () => setOpen(!dropdownOpen);
  const dispatch = useDispatch();
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );

  // useEffect(() => {
  //   handleGetWaitingList();
  // }, []);

  // const handleGetWaitingList = () => {
  //   dispatch(getWaitingRoomList({}, () => {}));
  // };

  const [selectedOperatingRoom, setSelectedOperatingRoom] = useState(null);

  useEffect(() => {
    if (selectedOperatingRoom) {
      setNewPointPanelState(true);
    }
  }, [selectedOperatingRoom]);

  const [selectedKennel, setSelectedKennel] = useState(null);
  const [selectedWaitingRoom, setSelectedWaitingRoom] = useState(null);

  const [editColor, setEditColor] = useState(false);
  const [editDuration, setEditDuration] = useState(false);
  const [editReminderTimes, setEditReminderTimes] = useState(false);
  const [selectedReminderType, setSelectedReminderType] = useState(null);

  const [selectedAppointmentType, setSelectedAppoinmentType] = useState(null);
  const [selectedSpecies, setSelectedSpecies] = useState(null);

  const [editTasks, setEditTasks] = useState(false);
  const [editAges, setEditAges] = useState(false);

  const [selectedColor, setSelectedColor] = useState(null);

  useEffect(() => {
    if (selectedKennel) {
      setAddNewKennel(true);
    }
  }, [selectedKennel]);

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  return (
    <>
      {!clinic_settings ? (
        <NodataFoundBlock />
      ) : (
        <div className="telehealth-tab">

        {/*
        // <div className="telehealth-tab">
        //   <div className="tab-row d-flex">
        //     <div className="tab-col lft">
        //       <div className="info-block">
        //         <h3>Waiting Rooms</h3>
        //       </div>
        //       <div className="status-rw d-flex align-items-center">
        //         <span className="swtich-cell2 d-inline-flex align-items-center">
        //           <span class="icomoon-check icon"></span>
        //           <span className="d-inline-flex rounded-circle circle-icon ms-auto"></span>
        //         </span>
        //         Active
        //       </div>
        //     </div>
        //     <div className="tab-col flex-fill">
        //       <div>
        //         <WaitingRoomList
        //           handleEdit={(e) => {
        //             setIsAddWaitingRoom(true);
        //             setEditData(e)
        //           }}
        //           isAddWaitingRoom={isAddWaitingRoom}
        //           setSelectedWaitingRoom={setSelectedWaitingRoom}

        //         />
        //       </div>
        //       {permissions?.edit_operating_rooms && (
        //         <Button
        //           color="link"
        //           className="add-note-btn d-flex justify-content-center"
        //           onClick={() => {
        //             setIsAddWaitingRoom(true);
        //           }}
        //         >
        //           <span className="icomoon-plus-btn"></span>
        //         </Button>
        //       )}
        //     </div>
        //   </div>
        //   {permissions?.view_operating_rooms && (
        //     <div className="tab-row d-flex">
        //       <div className="tab-col lft">
        //         <div className="info-block">
        //           <h3>Operating Rooms</h3>
        //         </div>
        //       </div>
        //       <div className="tab-col flex-fill">
        //         <div>
        //           <OperatingRoomList
        //             setSelectedOperatingRoom={setSelectedOperatingRoom}
        //             newPointPanelState={newPointPanelState}
        //           />
        //         </div>
        //         {permissions?.edit_operating_rooms && (
        //           <Button
        //             color="link"
        //             className="add-note-btn d-flex justify-content-center"
        //             onClick={() => setNewPointPanelState(true)}
        //           >
        //             <span className="icomoon-plus-btn"></span>
        //           </Button>
        //         )}
        //       </div>
        //     </div>
        //   )}

        //   <div className="tab-row d-flex">
        //     <div className="tab-col lft">
        //       <div className="info-block">
        //         <h3>Kennels</h3>
        //       </div>
        //     </div>
        //     <div className="tab-col flex-fill">
        //       <div>
        //         <KennelsList
        //           addNewKennel={addNewKennel}
        //           setSelectedKennel={setSelectedKennel}
        //         />
        //       </div>
        //       <Button
        //         color="link"
        //         className="add-note-btn d-flex justify-content-center"
        //         onClick={() => setAddNewKennel(true)}
        //       >
        //         <span className="icomoon-plus-btn"></span>
        //       </Button>
        //     </div>
      //   </div>*/}

    

          {permissions?.view_pet_owner_preferences && (
            <div className="tab-row d-flex">
              <div className="tab-col lft">
                <div className="info-block">
                  <h3>Pet Owner Portal</h3>
                </div>
              </div>
              <div className="tab-col flex-fill">
                <div>
                  <PetOwnerPreferences
                  />
                </div>
              </div>
            </div>
          )}
          <div className="tab-row d-flex">
            <div className="tab-col lft">
              <div className="info-block">
                <h3>Appointment Types</h3>
              </div>
            </div>
            <div className="tab-col flex-fill">
              <div className="inner0">
                <ColorCodes
                  setPanelState={setEditColor}
                  setSelectedType={setSelectedAppoinmentType}
                  setSelectedColor={setSelectedColor}
                />
              </div>
            </div>
          </div>
          <div className="tab-row d-flex">
            <div className="tab-col lft">
              <div className="info-block">
                <h3>Tags</h3>
              </div>
            </div>
            <div className="tab-col flex-fill">
              <div className="inner0">
                <TagsAndColors
                // setPanelState={setEditTasks}
                // setSelectedType={setSelectedAppoinmentType}
                />
              </div>
            </div>
          </div>
          <div className="tab-row d-flex">
            <div className="tab-col lft">
              <div className="info-block">
                <h3>Reminder Times</h3>
              </div>
            </div>
            <div className="tab-col flex-fill">
              <div className="inner0">
                <ReminderTimes
                  setPanelState={setEditReminderTimes}
                  setSelectedType={setSelectedReminderType}
                />
              </div>
            </div>
          </div>
          <div className="tab-row d-flex">
            <div className="tab-col lft">
              <div className="info-block">
                <h3>Appointment Tasks</h3>
              </div>
            </div>
            <div className="tab-col flex-fill">
              <div className="inner0">
                <AppointmentTypeTasks
                  setPanelState={setEditTasks}
                  setSelectedType={setSelectedAppoinmentType}
                />
              </div>
            </div>
          </div>

          {/*
          
          <div className="tab-row d-flex">
            <div className="tab-col lft">
              <div className="info-block">
                <h3>Appointment Types Appointment Slot Duration</h3>
              </div>
            </div>
            <div className="tab-col flex-fill">
              <div className="inner0">
                <div className="waiting-room-list">
                  {appointmentTypes.map((apptType, i) => (
                    <div className="item-row d-flex">
                      <div className="left-col">
                        <div className="room-info">
                          <h4>{apptType.label}</h4>
                        </div>
                        <div className="room-info">
                          <h4>
                            {(clinic_settings?.appointment_types_durations?.[
                              apptType?.value
                            ] || 30) + " minutes"}
                          </h4>
                        </div>
                      </div>
                      <div className="right-col d-inline-flex align-items-center">
                        <Button
                          onClick={() => {
                            setEditDuration(true);
                            setSelectedAppoinmentType(apptType);
                          }}
                          color="link"
                          className="d-inline-flex align-items-center justify-content-center"
                        >
                          <span className="icomoon-pencil"></span>
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>*/}
          {permissions?.view_other_preferences && (
            <div className="tab-row d-flex">
              <div className="tab-col lft">
                <div className="info-block">
                  <h3>Other Preferences</h3>
                </div>
              </div>
              <div className="tab-col flex-fill">
                <div className="inner0">
                  <OtherPreferences />
                </div>
              </div>
            </div>
          )}
          <div className="tab-row d-flex align-items-center">
            <div className="tab-col lft">
              <div className="info-block mb-0">
                <h3 className="mb-0">Medication Delay Threshold</h3>
              </div>
            </div>
            <div className="tab-col flex-fill">
              <div className="inner0">
                <MedicationDelayThreshold clinic_settings={clinic_settings} />
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className={`new-point-panel-overlay position-fixed ${newPointPanelState ||
          addNewKennel ||
          editColor ||
          editDuration ||
          editTasks ||
          editReminderTimes
          ? "active"
          : ""
          }`}
      ></div>
      <AddOr
        closeNewPointPanel={() => {
          setNewPointPanelState(false);
          setSelectedOperatingRoom(null);
        }}
        className={`${newPointPanelState ? "active" : ""}`}
        selectedOperatingRoom={selectedOperatingRoom}
        setSelectedOperatingRoom={setSelectedOperatingRoom}
      />

      {editTasks && (
        <EditAppointmentTypeTasks
          closeNewPointPanel={() => {
            setEditTasks(false);
          }}
          appointmentType={selectedAppointmentType}
          className={`${editTasks ? "active" : ""}`}
        />
      )}

      {editReminderTimes && (
        <EditReminderTimes
          closeNewPointPanel={() => {
            setEditReminderTimes(false);
          }}
          appointmentType={selectedReminderType}
          className={`${editReminderTimes ? "active" : ""}`}
        />
      )}

      <EditColorCode
        closeNewPointPanel={() => {
          setEditColor(false);
        }}
        appointmentColor={selectedColor}
        appointmentType={selectedAppointmentType}
        className={`${editColor ? "active" : ""}`}
      />

      {/* {editDuration && (
        <EditAppointmentTypeDuration
          closeNewPointPanel={() => {
            setEditDuration(false);
          }}
          appointmentType={selectedAppointmentType}
          className={`${editDuration ? "active" : ""}`}
        />
      )} */}

      <AddWaitingRoom
        editData={editData}
        closeNewPointPanel={() => {
          setIsAddWaitingRoom(false);
          setSelectedWaitingRoom(null)
          setEditData(null)
        }}
        className={`${isAddWaitingRoom ? "active" : ""}`}
        selectedWaitingRoom={selectedWaitingRoom}
        setSelectedWaitingRoom={setSelectedWaitingRoom}
      />

      <AddKennel
        closeNewPointPanel={() => {
          setAddNewKennel(false);
          setSelectedKennel(null);
        }}
        className={`${addNewKennel ? "active" : ""}`}
        selectedKennel={selectedKennel}
        setSelectedKennel={setSelectedKennel}
      />
    </>
  );
};

export default Preferences;
