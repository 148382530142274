import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Input } from "reactstrap"; // eslint-disable-line
import DateRange from "shared/FormsInput/DateRange";

const TableListFilterBlock = ({
  onSearch,
  estimateFilterPayload,
  onChangeDate
}) => {

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  return (
    <>
      <div className="table-list-filter-blc d-flex align-items-center">
        <div className="search-cell col-cell position-relative">
          <span className="icomoon-search d-inline-flex align-items-center justify-content-center position-absolute"></span>
          <Input
            placeholder="Search"
            onChange={onSearch}
            value={estimateFilterPayload?.searchText}
          />
        </div>
        <div className="date-range-container cme-10">
          <DateRange
            inputClassName="custom-input-grey"
            placeholder="Select Date"
            id="boarding"
            name="boarding"
            startDateProp={startDate}
            endDateProp={endDate}
            setStartDateProp={setStartDate}
            setEndDateProp={setEndDate}
            onChange={(sDate, eDate) => {
              const startDate = sDate ? moment(sDate).format("YYYY-MM-DD") : "";
              const endDate = eDate ? moment(eDate).format("YYYY-MM-DD") : "";
              onChangeDate(startDate, endDate);
            }}
            isClearable
          />
        </div>
      </div>
    </>
  );
};

export default TableListFilterBlock;
