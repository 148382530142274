import React, { useEffect, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Button } from "reactstrap";
import PageHeader from "../../shared/PageHeader";
import UserProfileHead from "../../shared/UserProfileHead";
import Overview from "./Overview";
import { useDispatch, useSelector } from "react-redux";
import {
  getStaffUserDetails,
  getUserGeneralInfo,
} from "../../context/actions/user";
// import PatientStories from "../../shared/PatientStories";
import LoaderUI from "../../shared/loader";
// import NoDataFoundBlock from "../../shared/NoDataFoundBlock";
import { sidebarPanelAction } from "../../context/actions/sidebarPanelAction";
import AvailabilityOrUnavailability from "components/user-module/staff-module/StaffDetail/StaffProfileTabBlock/AvailabilityUnavailaibility/AvailabilityOrUnavailability";

const ManageProfile = () => {
  const userDetails = useSelector((state) => state.user.user_details);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("1");
  const [loader, showLoader] = useState(false);
  const [userGeneralInfo, setUserGeneralInfo] = useState(false);
  const [userInfo, setuserInfo] = useState(null);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const getPageData = async () => {
    let res = await dispatch(getStaffUserDetails(userDetails._id));
    setuserInfo(Array.isArray(res) ? res?.[0] : null);
  };
  useEffect(() => {
    showLoader(true);
    getPageData();
    dispatch(
      getUserGeneralInfo(userDetails._id, {}, (result) => {
        if (result && !result.errors) {
          setUserGeneralInfo(result);
          showLoader(false);
        } else {
          setUserGeneralInfo(false);
          showLoader(false);
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sidebarPanelState = useSelector(
    (state) => state.sidebarPanelReducer.isSidePanel
  );
  const showSidePanel = () => {
    dispatch(sidebarPanelAction(!sidebarPanelState));
  };
  return (
    <>
      {loader ? (
        <LoaderUI overlay />
      ) : (
        <div className="page-wrapper position-relative d-flex flex-column flex-fill overflow-auto pb-0">
          <div className="page-header-wrapper d-flex flex-shrink-0 toggle">
            <Button
              className="sidebar-toggle-btn d-inline-flex align-items-center"
              color="link"
              onClick={showSidePanel}
            >
              <span className="material-symbols-outlined">menu</span>
            </Button>
            <PageHeader
              className="dashboard-head flex-flex-shrink-0"
              title="My Profile"
              subTitle={userGeneralInfo?.short_description}
            ></PageHeader>
          </div>
          <div className="page-content d-flex flex-column flex-fill overflow-auto">
            <UserProfileHead userGeneralInfo={userGeneralInfo} />
            <div className="tab-wrapper tab-wrapper-profile  d-flex flex-column flex-fill overflow-auto">
              <Nav tabs className="flex-shrink-0">
                <NavItem>
                  <NavLink
                    className={{ active: activeTab === "1" }}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Overview
                  </NavLink>
                </NavItem>
                {userDetails?.role === "doctor" && (
                  <NavItem>
                    <NavLink
                      className={{ active: activeTab === "2" }}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      Availability/Unavailability
                    </NavLink>
                  </NavItem>
                )}
                {/*
									<NavItem>
										<NavLink
											className={({ active: activeTab === '2' })}
											onClick={() => { toggle('2'); }}
										>
											Schedule
										</NavLink>
										<NavLink
											className={({ active: activeTab === '3' })}
											onClick={() => { toggle('3'); }}
										>
											Patient Stories
										</NavLink>
									</NavItem>
									*/}
              </Nav>
              <TabContent activeTab={activeTab} className="overflow-auto">
                {/* <NoDataFoundBlock /> */}
                <TabPane tabId="1">
                  {userGeneralInfo && (
                    <Overview userGeneralInfo={userGeneralInfo} />
                  )}
                </TabPane>
                {activeTab === "2" && (
                  <AvailabilityOrUnavailability
                    status={0}
                    doctorId={userInfo?.doctor_details?._id || ""}
                    staffMemberId={
                      userInfo?.staff_managements_details?.[0]?._id || ""
                    }
                  />
                )}
                {/* <TabPane tabId="2">Schedule</TabPane>
                <TabPane tabId="3">
                  <PatientStories
                    className="responsive"
                    userId={userDetails._id}
                  />
                </TabPane> */}
              </TabContent>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ManageProfile;
