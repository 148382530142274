import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table, FormGroup, Input } from "reactstrap";
import ListingFilter from "./ListingFilter";
import { patientListPanelAction } from "../../../context/actions/patientListPanelAction";
import { sendReferral } from "../../../context/actions/referral.js";
import moment from "moment";

import { useHistory } from "react-router";
import './index.scss';
import { getAllPetsAddedBy } from "../../../context/actions/patient-module/patient/pet";
import { getAllPets } from "../../../context/actions/patient-module/patient/pet";
import { getAllClinics } from "../../../context/actions/clinic";
import { useState } from "react";
import NodataFoundBlock from "../../NoDataFoundBlock";
import ImagePlaceholder from "../../../shared/ImagePlaceholder";
import SortColumn from "../../Sort/Column";
import { getAllCity, getAllSpecies } from "../../../context/actions/app-content";
import { handleSortTable } from "../../../utils/constants";
import SelectBox from "../../../shared/FormsInput/SelectBox.js"; // eslint-disable-line
import { formatPhoneNumber } from "utils/common";
import { useClinicSpecies } from "hooks/useClinicSpecies";
import { useClinicBreed } from "hooks/useClinicBreed";

const ageFromDOB = (date) =>{
	const dob = new Date(date); 
	const today = new Date(); 
	var allMonths= today.getMonth() - dob.getMonth() + (12 * (today.getFullYear() - dob.getFullYear())); // eslint-disable-line
	var allYears= today.getFullYear() - dob.getFullYear();
	var partialMonths = today.getMonth() - dob.getMonth();
	if (partialMonths < 0) {
		allYears--;
		partialMonths = partialMonths + 12;
	}
	return {months: partialMonths, years: allYears}
}

const PatientListPanel = ({refresh,  className = '', clinicId }) => {
	const clinicSpecies = useClinicSpecies()
	const clinicBreed = useClinicBreed()

	const history = useHistory();
	const dispatch = useDispatch();
	const [petLists, setPetLists] = useState(false);
	const clinics = useSelector(state => state.clinicReducer.clinics);
	
	const [searchedClinicList, setSearchedClinicList] = useState(false);
	const [reason, setReason] = useState('');
	const [notes, setNotes] = useState('');


	const [searchedPetLists, setSearchedPetLists] = useState(false);
	const [searchedVal, setSearchedVal] = useState(false);// eslint-disable-line
	const [cityFilter, setCityFilter] = useState(false);// eslint-disable-line
	const [genderFilter, setGenderFilter] = useState(false);// eslint-disable-line
	const [speciesFilter, setSpeciesFilter] = useState(false);// eslint-disable-line
	const [searchFilters, setSearchFilters] = useState(false);
	const [selectedPatient, setSelectedPatient ] = useState(false);
	const [selectedClinic, setSelectedClinic ] = useState(false); // eslint-disable-line
	const [clinic, setClinic] = useState(null)
	const [patient, setPatient ] = useState(null);
	//const [patient, setPatient ] = useState(false);


	const closePatientPanel = () => {
		dispatch(patientListPanelAction(false))
	}

	const handleGetAllPets = () => {
		dispatch(getAllPets(clinicId, {}, (result => {
			if (result && !result.errors) {
				setPetLists(result)
				setSearchedPetLists(result)
			} else {
				setPetLists(false)
				setSearchedPetLists(false)
			}
		})))
	}

	const handleGetAllClinics = () => {
		dispatch(getAllClinics({},  (result) => {
			if(result && !result?.errors){
				setSearchedClinicList(result?.response_data)
			} else {
				setSearchedClinicList(false)
			}
		}))
	}

	useEffect(() => {
		console.log('referral patient list panel refresh')

		handleGetAllPets();
		handleGetAllClinics();
		dispatch(getAllCity({}, () => { }))
		dispatch(getAllSpecies({}, () => { }))
		
	}, []);// eslint-disable-line

	const handleFilterList = (searchData) => {
		return petLists.filter(
			function (item) {
				for (var key in searchData) {
					let itemKey;
					if (key === 'city') {
						itemKey = item['pet_owner'][key]
					} else {
						itemKey = item[key].toLowerCase()
					}
					if (itemKey === undefined || !itemKey.includes(searchData[key]))
						return false;
				}
				return true;
			}
		);
	}

	const handleFilterPetList = (searchVal) => {
		if (searchVal.length > 0) {
			const searchData = { ...searchFilters, pet_name: searchVal.toLowerCase() }
			setSearchFilters(searchData);
			const filteredPet = handleFilterList(searchData)
			setSearchedPetLists(filteredPet)
		} else {
			setSearchedPetLists(petLists)
		}
	}

	const onSelectCity = (e) => {
		let value = e.target.value;
		if (value) {
			const searchData = { ...searchFilters, city: value }
			setSearchFilters(searchData);
			const filteredPet = handleFilterList(searchData)
			setSearchedPetLists(filteredPet)
		} else {
			setSearchedPetLists(petLists)
		}

	}

	const onSelectGender = (e) => {
		let value = e.target.value;
		if (value) {
			const searchData = { ...searchFilters, gender: value }
			setSearchFilters(searchData);
			const filteredPet = handleFilterList(searchData)
			setSearchedPetLists(filteredPet)
		} else {
			setSearchedPetLists(petLists)
		}
	}

	const onSelectSpecies = (e) => {
		let value = e.target.value;
		if (value) {
			const searchData = { ...searchFilters, species: value }
			setSearchFilters(searchData);
			const filteredPet = handleFilterList(searchData)
			setSearchedPetLists(filteredPet)
		} else {
			setSearchedPetLists(petLists)
		}
	}

	const handleColumnSort = (type, sortyOrder, columnName) => {
		if(type === 'pet'){
			const data = searchedPetLists.sort(handleSortTable(columnName, sortyOrder));
			setSearchedPetLists(searchedPetLists => [...data]);
		} else if (type === 'clinic'){
			const data = searchedClinicList.sort(handleSortTable(columnName, sortyOrder));
			setSearchedClinicList(searchedClinicList => [...data]);
		}
	}


	const referPatientUrl = (redirectUrl) => { // eslint-disable-line
		history.push(redirectUrl);
		dispatch(patientListPanelAction(false));
	}

	const handleSendReferral = () => {
		dispatch(sendReferral({patient: patient._id, referred_to: clinic._id, reason: reason, notes: notes }, (response) => {
				console.log('in callback')
				refresh();
				closePatientPanel();
		}))
	 }

	const handleReferPatient = (pet) => {
		setPatient(pet);
	//	setSelectedPatient(true);
	}

	const handleReferClinic = (clinic) => {
		setClinic(clinic)
	}


	useEffect(() => {
		if(patient === null) {
			setSelectedPatient(false)
		} else {
			setSelectedPatient(true)
		}
	}, [patient])



	useEffect(() => {
		setSelectedClinic(!(clinic === null))
	}, [clinic])





		



	

	return (
		<>
			<div className={`patients-panel-wrapper position-fixed d-flex flex-column overflow-auto 
			${className}
			
			`}>
				{(selectedPatient === true && selectedClinic === true)  ?
				<>
				<div className="head d-flex align-items-center">
					<h2 className="panel-title">Refer Patient</h2>
					<Button color="link" className="ms-auto panel-close-btn" onClick={closePatientPanel}>
						<span className="material-symbols-outlined">
							close
						</span>
					</Button>
				</div>
				<div className="inner overflow-auto">
					<div className="row-block d-flex">
						<h5 className="left-col info-title">
							Patient Information
						</h5>
						<Button onClick={() => setPatient(null)} style={{textAlign:'right'}}  className="right-col flex-fill" color="link">
								Change
						</Button>
					</div>
					<div className="tab-row d-flex">
						<div className="tab-col d-flex justify-content-left align-items- lft">
							<div className="d-flex info-block">
								<div className="left-col">
									<div className="text-start pic-cell d-inline-flex align-items-center">
										<div className="pic d-inline-flex position-relative">
											{
												patient?.pet_image ? <img style={{width: '50px', height: '50px'}} className="img-fluid" src={patient?.pet_image} alt="Patient" /> : <ImagePlaceholder />
											}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="card-text tab-col lft flex-fill">
							<div className="d-flex info-block">
								<div className =" right-col">
									<span className="card-name">{`${patient?.pet_name} (${patient?.vrn})`}</span>
									<span className="card-body">{`${ageFromDOB(patient?.dob)?.years} years, ${ageFromDOB(patient?.dob)?.months} months old (DOB : ${moment(patient?.dob).format('MM/DD/YYYY')} ) / ${patient?.gender} / ${clinicBreed(patient?.breed?._id) ||  patient?.breed?.breed} / ${patient?.color ? patient?.color : '--' }`}</span>
								</div>
							</div>
						</div>
					</div>
					<div className="row-block d-flex">
						<h5 className="info-title left-col">
							Clinic Information
						</h5>
						<Button style={{textAlign:'right'}}  onClick={() => {setClinic(null)}} className="right-col flex-fill" color="link" >
								Change
						</Button>
					</div>
					<div className="tab-row d-flex">
						<div className="tab-col lft">
							<div className="info-block">
								<span className="card-name">{clinic?.clinic_name}</span>
								<span className="card-body">{clinic?.address ? clinic?.address : ''}{formatPhoneNumber(clinic?.phone) ? formatPhoneNumber(clinic?.phone) : ''} </span>
							</div>
						</div>
					</div>
					<h5 className="left-col info-title">
							Refer Information
					</h5>
					<div className="flex-row d-flex">
						<div className="right-col flex-fill">
						<FormGroup className="input-wrapper no-floating d-flex flex-col flex-fill gap-[10px]">
							<div className="search-cell">
							<Input value={reason} type="text" onChange={(e) => {setReason(e.target.value)}} placeholder="Add Reason" name="reason" />

							</div>
							<Input value={notes} type="text" onChange={(e) => {setNotes(e.target.value)}} placeholder="Add Notes" name="note">
							</Input>
						</FormGroup>
						</div>
					</div>

				
					<div className="row-block d-flex">
						<div className="btns-blc right-col d-inline-flex align-items-center ms-auto">
							<Button color="primary" type="submit" className="btn btn-outline-light referral-btn" onClick={() => handleSendReferral()}>Send Referral</Button>
						</div>
					</div>
				</div>
				
				</>
				
				:
				selectedPatient ? 
				<>


				<div className="head d-flex align-items-center">
					<h2>Select Clinic</h2>
					<Button color="link" className="ms-auto panel-close-btn" onClick={closePatientPanel}>
						<span className="material-symbols-outlined">
							close
						</span>
					</Button>
				</div>
				{/*<ListingFilter
					fieldVal={(value) => handleFilterPetList(value)}
					onSelectCity={onSelectCity}
					onSelectGender={onSelectGender}
					onSelectSpecies={onSelectSpecies}
										/>*/}
				<div className="inner overflow-auto">
					<div className="table-listing-blc overflow-auto">
						<Table responsive striped >
							<thead>
								<tr>
									<th>
										<Button color="link" className="d-inline-flex align-items-center sort-btn">
											Clinic
											<SortColumn
												sortLatest={() => handleColumnSort('clinic',-1, 'clinic_name')}
												sortOldest={() => handleColumnSort('clinic', 1, "clinic_name")}
											/>
										</Button>
									</th>
									<th style={{ minWidth: '150px' }}>
										<Button color="link" className="d-inline-flex align-items-center sort-btn">
											Phone
											<SortColumn
												sortLatest={() => handleColumnSort('clinic', -1, 'phone')}
												sortOldest={() => handleColumnSort('clinic',1, "phone")}
											/>
										</Button>
									</th>
									<th>
										<Button color="link" className="d-inline-flex align-items-center sort-btn">
											Email
											<SortColumn
												sortLatest={() => handleColumnSort('clinic',-1, 'email')}
												sortOldest={() => handleColumnSort('clinic',1, "email")}
											/>
										</Button>
									</th>
									<th style={{ minWidth: '150px' }}>
										<Button color="link" className="d-inline-flex align-items-center sort-btn">
											Addresss
											<SortColumn
												sortLatest={() => handleColumnSort('clinic',-1, 'address')}
												sortOldest={() => handleColumnSort('clinic',1, "address")}
											/>
										</Button>
									</th>
									<th style={{ width: '100px' }}>&nbsp;</th>
								</tr>
							</thead>
							<tbody>
								{
									(searchedClinicList && searchedClinicList?.length > 0) ? searchedClinicList.map((clinic, i) => {
										return (
											<tr key={i}>
												<td>
													<div
														className="text-start pic-cell d-inline-flex align-items-center">
														
														{clinic?.clinic_name}
													</div>
												</td>
												<td>{ formatPhoneNumber(clinic?.phone) || '--'}</td>
												<td>{clinic?.email || '--'} </td>
												<td>{clinic?.address || '--'}</td>
												<td>
													<Button color="link" onClick={() => handleReferClinic(clinic)}>
														Refer
													</Button>
												</td>
											</tr>
										)
									}) :
										<tr className="no-data-rw">
											<td colSpan="5">
												<NodataFoundBlock />
											</td>
										</tr>
								}
							</tbody>
						</Table>
					</div>
				</div>
				</>
		
				
				:
				<>

				<div className="head d-flex align-items-center">
					<h2>Select Patient</h2>
					<Button color="link" className="ms-auto panel-close-btn" onClick={closePatientPanel}>
						<span className="material-symbols-outlined">
							close
						</span>
					</Button>
				</div>
				<ListingFilter
					fieldVal={(value) => handleFilterPetList(value)}
					onSelectCity={onSelectCity}
					onSelectGender={onSelectGender}
					onSelectSpecies={onSelectSpecies}
				/>
				<div className="inner overflow-auto">
					<div className="table-listing-blc overflow-auto">
						<Table responsive striped className="no-data-table">
							<thead>
								<tr>
									<th>
										<Button color="link" className="d-inline-flex align-items-center sort-btn">
											Pet Info.
											<SortColumn
												sortLatest={() => handleColumnSort('pet', -1, 'pet_name')}
												sortOldest={() => handleColumnSort('pet',1, "pet_name")}
											/>
										</Button>
									</th>
									<th style={{ minWidth: '150px' }}>
										<Button color="link" className="d-inline-flex align-items-center sort-btn">
											Animal / Breed
											<SortColumn
												sortLatest={() => handleColumnSort('pet',-1, 'species')}
												sortOldest={() => handleColumnSort('pet',1, "species")}
											/>
										</Button>
									</th>
									<th>
										<Button color="link" className="d-inline-flex align-items-center sort-btn">
											Gender
											<SortColumn
												sortLatest={() => handleColumnSort('pet',-1, 'gender')}
												sortOldest={() => handleColumnSort('pet',1, "gender")}
											/>
										</Button>
									</th>
									<th style={{ minWidth: '150px' }}>
										<Button color="link" className="d-inline-flex align-items-center sort-btn">
											City
											<SortColumn
												sortLatest={() => handleColumnSort('pet',-1, 'city')}
												sortOldest={() => handleColumnSort('pet',1, "city")}
											/>
										</Button>
									</th>
									<th style={{ width: '100px' }}>&nbsp;</th>
								</tr>
							</thead>
							<tbody>
								{
									(searchedPetLists && searchedPetLists.length > 0) ? searchedPetLists.map((pet, i) => {
										return (
											<tr key={i}>
												<td>
													<div
														className="text-start pic-cell d-inline-flex align-items-center">
														<div className="pic d-inline-flex position-relative">
															{
																pet?.pet_image ? <img className="img-fluid w-100 h-100" src={pet?.pet_image} alt="Patient" /> : <ImagePlaceholder />
															}
														</div>
														{pet?.pet_name}
													</div>
												</td>
												<td>{clinicSpecies(pet?.breed?.species_id?._id)  ||  pet?.breed?.species_id?.species_name} / {clinicBreed(pet?.breed?._id) ||  pet?.breed?.breed}</td>
												<td>{pet?.gender === 'male' ? 'Male' : 'Female'} </td>
												<td>{pet?.pet_owner?.city}</td>
												<td>
													<Button color="link" onClick={() => handleReferPatient(pet)}>
														Refer
													</Button>
												</td>
											</tr>
										)
									}) :
										<tr className="no-data-rw">
											<td colSpan="5">
												<NodataFoundBlock type={'search result'} />
											</td>
										</tr>
								}
							</tbody>
						</Table>
					</div>
				</div>
				</>}
			</div>
			
		</>
	);
};

export default PatientListPanel;
