import React, { useState, useEffect } from "react";
import {
  BsCheckLg,
  BsFillCheckCircleFill,
  BsFillTrashFill,
} from "react-icons/bs";
import { Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addAllergy,
  deleteAllergy,
} from "../../../../../context/actions/allergies";

import { ReactComponent as DeleteSvg } from "../../../../../assets/img/delete.svg" 
import { ReactComponent as CheckSvg } from '../../../../../assets/img/check_circle.svg'

const AllergyAdd = ({
  severityValue,
  allergyName,
  allergyId,
  setIsAllergyAdd,
  setSeverityValue,
  setAllergyName,
  setAllergyId,
  handleUpdateText,
}) => {
  const [reactionsValue, setReactionsValue] = useState(allergyName);
  //   const behaviourtags = [
  //     {
  //       id: 1,
  //       tagname: "Sociability And Aggressiveness",
  //       tagClassName: "tag-one",
  //     },
  //     {
  //       id: 2,
  //       tagname: "Chase-Pronenes",
  //       tagClassName: "tag-two",
  //     },
  //     {
  //       id: 3,
  //       tagname: "Tagname",
  //       tagClassName: "tag-three",
  //     },
  //     {
  //       id: 4,
  //       tagname: "Tagname",
  //       tagClassName: "tag-four",
  //     },
  //     {
  //       id: 5,
  //       tagname: "Tagname",
  //       tagClassName: "tag-five",
  //     },
  //   ];
  //   const tempAllergytags = [
  //     {
  //       id: 1,
  //       tagname: "Amaxicillian And Hives",
  //       tagClassName: "a-tag-one",
  //     },
  //     {
  //       id: 2,
  //       tagname: "Anaphylaxes",
  //       tagClassName: "a-tag-two",
  //     },
  //     {
  //       id: 3,
  //       tagname: "Alleries to Medications",
  //       tagClassName: "a-tag-three",
  //     },
  //     {
  //       id: 4,
  //       tagname: "Allergic_Information And Reaction",
  //       tagClassName: "a-tag-four",
  //     },
  //     {
  //       id: 5,
  //       tagname: "Tagname",
  //       tagClassName: "a-tag-five",
  //     },
  //   ];

  const reactionsOptions = [
    { value: "Hives 1" },
    { value: "Amaxicillian And Hives" },
    { value: "Hives 2" },
    { value: "Hives 3" },
    { value: "Anaphylaxes" },
    { value: "Hives 4" },
    { value: "Hives 5" },
    { value: "Alleries to Medications" },
    { value: "Tagname" },
  ];

  const severity = [
    {
      id: 1,
      severityName: "Low",
      tagClassName: "low",
      colorName: "bg-teal-500",
    },
    {
      id: 2,
      severityName: "Moderate",
      tagClassName: "moderate",
      colorName: "bg-yellow-500",
    },
    {
      id: 3,
      severityName: "High",
      tagClassName: "high",
      colorName: "bg-orange-500",
    },
  ];

  useEffect(() => {
    setReactionsValue(allergyName);
  }, [allergyName]);

  const dispatch = useDispatch();

  const saveAllergy = () => {
    dispatch(
      addAllergy(
        { tagId: allergyId, tag: reactionsValue, severity: severityValue },
        (res) => {
          if (res?.statusCode === 200 && res?.status === true) {
            setAllergyName("");
            setSeverityValue("");
            setAllergyId("");
            setIsAllergyAdd(false);
            handleUpdateText(reactionsValue);
          }
        }
      )
    );
  };

  const cancelAllergy = (allergyId) => {
    dispatch(
      deleteAllergy(allergyId, (res) => {
        setSeverityValue("");
        setAllergyName("");
        setAllergyId("");
        setIsAllergyAdd(false);
      })
    );
  };

  return (
    <div className="allergy-add mb-3 d-flex align-items-center justify-content-between">
      <div className="reactions-add d-flex gap-2 align-items-center">
        <div className="d-flex gap-1">
          <span>Reactions</span>
          <span style={{ color: "red" }}>*</span>
        </div>
        {/* <Input className="reactions-select-dropdown" type="select" name="select" placeholder="Select value" value={reactionsValue} onChange={(e) => {
                    setReactionsValue(e?.target?.value)
                }}>
                    <option value="">Select</option>
                {reactionsOptions?.map((item, i) => (
                  <option key={i} value={item?.value}>
                    {item?.value}
                  </option>
                ))}
              </Input> */}
        <input
          type="text"
          className="form-control"
          placeholder="Add Reaction Name"
          value={reactionsValue}
          onChange={(e) => {
            setReactionsValue(e?.target?.value);
          }}
        />
      </div>

      <div className="reactions-add d-flex gap-2 align-items-center">
        <div className="d-flex gap-1">
          <span>Severity</span>
          <span style={{ color: "red" }}>*</span>
        </div>
        <div className="severity-text d-flex gap-2">
          {severity?.map((elem, index) => {
            const { severityName, tagClassName, id, colorName } = elem;
            return (
              <span
                className={`d-flex gap-2 align-items-center1 ${colorName} ${tagClassName} cursor-pointer`}
                key={index}
                id={id}
                onClick={() => {
                  setSeverityValue(severityName);
                }}
              >
                {severityName}{" "}
                {severityName === severityValue && (
                  <BsCheckLg style={{ color: "white" }} />
                )}
              </span>
            );
          })}
        </div>
      </div>
      <div className="d-flex gap-1">


          <button className={'p-1'} style={{ width: '35px' }} size="sm" type="submit" onClick={() => {
            saveAllergy();
              }}><CheckSvg /></button>
            <button type="button" class="" onClick={() => {
            cancelAllergy(allergyId);
              }}><DeleteSvg /></button>

        {/*
        <BsFillTrashFill
          style={{ color: "red", cursor: "pointer" }}
          size={25}
          onClick={() => {
            cancelAllergy(allergyId);
            // setIsAllergyAdd(false);
            // setReactionsValue("");
            // setSeverityValue("");
          }}
        />
        <BsFillCheckCircleFill
          style={{ color: "blueviolet", cursor: "pointer" }}
          size={25}
          onClick={() => {
            saveAllergy();
            // setIsAllergyAdd(false);
            // handleUpdateText(reactionsValue);
            // setReactionsValue("");
            // setSeverityValue("");
          }}
        />*/}
      </div>
    </div>
  );
};

export default AllergyAdd;
