const initState = {
  loading: false,
  testResults: [],
  vitalsListing: []
};

export const vitalTestingReducer = (state = initState, action) => {
  switch (action.type) {
    case "VITAL_RESULTS_LOADING":
      return { ...state, loading: action.payload };

    case "VITAL_RESULTS":
      return { ...state, testResults: action.payload };

    case "VITAL_RESULTS_ARR":
      return { ...state, vitalsListing: action.payload };

    default:
      return state;
  }
};
