// Layout Types
import {
  PetOwnerDashboardLayout,
  PrivateLayout,
  PublicLayout,
  VideoCallLayout,
} from "../layouts";
// Route Views
import Settings from "../components/clinic-module/clinic-settings";
import ManageProfile from "../components/manage-profile";
import StaffManagement from "../components/user-module/staff-module/StaffManagement";
import StaffDetail from "../components/user-module/staff-module/StaffDetail";
import Appointments from "../components/appointment-module";
import AppointmentBlock from "../components/appointment-block";
import Inventory from "../components/inventory-module";
import ProductsDetail from "../components/inventory-module/ProductsDetail";
import Patients from "../components/patient-module";
import PatientDetail from "../components/patient-module/PatientDetail";
import AddPatientDetail from "../components/patient-module/AddPatientDetail";
import Reports from "../components/reports-module";
import ReferralManagement from "../components/referral-management-module";
import AddNewAppointment from "../components/appointment-module/AddNewAppointment";
import Order from "../components/order/Order";
import Boarding from "../components/boarding";
import Invoices from "../components/invoices-module";
import TotalRevenue from "../components/invoices-module/totalRevenue";
import OperatingRoom from "../components/operating-room-module";
import UpdatedOperatingRoom from "../components/operating-room-module/UpdatedOperatingRoom";
import AddProductInventory from "../components/add-product-inventory";
import MessageCenter from "../components/message-center";
import AddServiceInventory from "../components/add-service-inventory";
import AddPosInventory from "../components/add-pos-inventory";
import AddSupplier from "../components/add-supplier-inventory";
import AddInvoice from "../components/add-invoice";
import UpdatedBoarding from "../components/boarding/UpdatedBoarding";
import PetOwnerTab from "../components/patient-module/PetOwnerTab";
import AddPurchaseOrder from "../components/AddPurchaseOrder";
import WellnessPlans from "../components/wellness-plans-module";
import AddInvoiceNew from "../components/add-invoice/index-new";
import DischargePatient from "../components/discharge-patient";
//import DischargePatientNew from "../components/discharge-patient/index_new";
import LiveClinic from "../components/appointment-module/ClinicView/LiveClinic";
import PetOwnerDashboard from "../components/pet-owner-dashboard/PetOwnerHome";
import PetOwnerPetDetail from "../components/pet-owner-dashboard/PatientDetail";
import PetOwnerHealthSnapshot from "../components/pet-owner-dashboard/PetOwnerHealthSnapshot";
import PetOwnerAppointments from "../components/pet-owner-dashboard/PetOwnerAppointments";
import PetOwnerHealthRecords from "../components/pet-owner-dashboard/PetOwnerHealthRecords";
import PetOwnerMessages from "../components/pet-owner-dashboard/PetOwnerMessages";
import PetOwnerBilling from "../components/pet-owner-dashboard/PetOwnerBilling";
import PetOwnerPetServices from "../components/pet-owner-dashboard/PetOwnerPetServices";
import PetOwnerProfile from "../components/pet-owner-dashboard/PetOwnerProfile";
import AddBundleInventory from "../components/add-bundle-inventory";
import VideoCall from "components/video-call-module/index";

import SuperAdminLayout from "../layouts/super";
import SuperAdminDashboard from "../components/super-admin-dashboard/Dashboard";
import AllClinics from "../components/super-admin-dashboard/Clinic";
import ClinicDeatil from "../components/super-admin-dashboard/Clinic/ClinicDeatil";
import ClinicSetting from "../components/super-admin-dashboard/Clinic/ClinicSetting";
import AddEstimate from "components/add-estimate";
import { Redirect } from "react-router-dom";
import EditAppointment from "components/appointment-module/EditAppointment";
import { useSelector } from "react-redux";
import InventoryManagement from "components/reports-module/ReportsItem/InventoryManagement";
import AppointmentScheduleReport from "components/reports-module/ReportsItem/AppointmentScheduleReport/AppointmentScheduleReport";
import PatientRecordsReport from "components/reports-module/ReportsItem/PatientRecordsReport";
import SinglePatientReportDetails from "components/reports-module/ReportsItem/PatientRecordsReport/SinglePatientReportDetails";
import BoardingReports from "components/reports-module/ReportsItem/BoardingReport";
import BoardingReport from "components/reports-module/ReportsItem/BoardingReport";
import OperatingRoomReport from "components/reports-module/ReportsItem/OperatingRoomReport";
import WellnessPlanReports from "components/reports-module/ReportsItem/WellnessPlanReports";
import InvoiceAndEstimatesReport from "components/reports-module/ReportsItem/InvoiceAndEstimatesReport";
import ReferralManagementReports from "components/reports-module/ReportsItem/ReferralManagementReports";
import StaffManagementReports from "components/reports-module/ReportsItem/StaffManagementReports";
import EditProductInventory from "components/EditProductInventory";
import EditDiagnosticService from "components/EditDiagnosticService"
import { useEffect } from "react";

const HomeRoutes = () => {
  const userDetail = useSelector((state) => state.user.user_details);
  // console.log("%c Line:59 🥟 permissionsData", "color:#33a5ff", permissionsData);
  const userRole = userDetail?.role;
  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  useEffect(() => {
    console.log(permissions)
  }, [permissions])
  return [
    {
      path: "/dischargePatient",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: ["admin", "doctor"],
      component: DischargePatient,
    },
    // {
    //   path: "/dischargePatientNew",
    //   exact: true,
    //   layout: PrivateLayout,
    // allowedRoles: ["admin"],
    //   component: DischargePatientNew,
    // },

    /*
    {
      path: "/dashboard",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: ["admin", "doctor"],
      component: Dashboard,
    }, */
    // {
    //   path: "/",
    //   exact: true,
    //   layout: PrivateLayout,
    //   allowedRoles: ["admin", "doctor"],
    //   component: Appointments,
    // },
    // {
    //   path: "/makeOrder",
    //   exact: true,
    //   layout: PrivateLayout,
    //   allowedRoles: ["admin", "doctor"],
    //   component: Order,
    // },
    {
      path: "/settings",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.use_clinic_settings,
      component: Settings,
    },
    {
      path: "/referral-management",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.view_referral_management,
      component: ReferralManagement,
    },
    {
      path: "/reports",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.view_reports,
      component: Reports,
    },
    {
      path: "/reports/inventory-management",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.view_reports,
      component: InventoryManagement,
    },
    {
      path: "/reports/appointment-schedule-report",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.view_reports,
      component: AppointmentScheduleReport,
    },
    {
      path: "/reports/patient-records-report",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.view_reports,
      component: PatientRecordsReport,
    },
    {
      path: "/reports/patient-records-report/details/:id",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.view_reports,
      component: SinglePatientReportDetails,
    },
    {
      path: "/reports/boarding-report",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.view_reports,
      component: BoardingReport,
    },
    {
      path: "/reports/operating-room-report",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.view_reports,
      component: OperatingRoomReport,
    },
    {
      path: "/reports/invoice-and-estimates-report",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.view_reports,
      component: InvoiceAndEstimatesReport,
    },
    {
      path: "/reports/referral-management-report",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.view_reports,
      component: ReferralManagementReports,
    },
    {
      path: "/reports/staff-management-report",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.view_reports,
      component: StaffManagementReports,
    },
    {
      path: "/reports/wellness-report",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.view_reports,
      component: WellnessPlanReports,
    },
    {
      path: "/manage-profile",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: ["admin", "doctor"],
      component: ManageProfile,
    },
    {
      path: "/staff-management",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.view_staff_management,
      component: StaffManagement,
    },
    {
      path: "/wellness-plans",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.view_wellness_plans,
      component: WellnessPlans,
    },
    {
      path: "/staff-management/staff-details/:staffManagementId",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.edit_staff_management,
      component: StaffDetail,
    },
    {
      path: "/appointments",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.view_appointments,
      component: Appointments,
    },
    {
      path: "/inventory",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.view_inventory,
      component: Inventory,
    },

    // {
    //   path: "/operating-room",
    //   exact: true,
    //   layout: PrivateLayout,
    // allowedRoles: ["admin"],
    //   component: OperatingRoom,
    // },
    {
      path: "/operating-room",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.view_operating_rooms,
      component: UpdatedOperatingRoom,
    },
    {
      path: "/product-detail/:inventoryId",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: ["admin", "doctor"],
      component: ProductsDetail,
    },
    {
      path: "/patients",
      exact: true,
      layout: PrivateLayout,
      allowedRoles:
        permissions?.view_patients || permissions?.view_patient_owners,
      component: Patients,
    },
    {
      path: "/boarding",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.view_boarding,
      // component: Boarding,
      component: UpdatedBoarding,
    },
    {
      path: "/invoices",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.view_invoices,
      component: Invoices,
    },
    {
      path: "/invoices/add-invoice",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.edit_invoices,
      component: AddInvoice,
    },
    {
      path: "/invoices/add-estimate",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.edit_estimates,
      component: AddEstimate,
    },

    {
      path: "/invoices/edit-invoice/:invoiceId",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.edit_invoices,
      component: AddInvoice,
    },
    {
      path: "/revenue",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: ["admin", "doctor"],
      component: TotalRevenue,
    },
    {
      path: "/patient-detail/:petId",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.view_patients_details,
      component: PatientDetail,
    },
    // {
    //   path: "/pet-owner",
    //   exact: true,
    //   layout: PrivateLayout,
    // allowedRoles: ["admin"],
    //   component: PetOwnerTab,
    // },
    {
      path: "/pet-owner",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.view_patient_owners,
      component: PetOwnerTab,
    },
    {
      path: "/patients/add-patient",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.add_patients,
      component: AddPatientDetail,
    },
    {
      path: "/add-new-appointment/:petId",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.edit_appointments,
      component: AddNewAppointment,
    },
    {
      path: "/appointment-block",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.edit_appointments,
      component: AppointmentBlock,
    },

    {
      path: "/edit-appointment/:appointmentId",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.edit_appointments,
      component: EditAppointment,
    },
    {
      path: "/inventory/add-new-product-in-inventory",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.edit_products,
      component: AddProductInventory,
    },
    {
      path: "/inventory/edit-existing-product-in-inventory",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.edit_products,
      component: EditProductInventory,
    },
    {
      path: "/inventory/edit-diagnostic-service",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.edit_diagnostic_services,
      component: EditDiagnosticService,
    },
    {
      path: "/message-center",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.view_messages,
      component: MessageCenter,
    },
    // {
    //   path: "/message-center/:room_id",
    //   exact: true,
    //   layout: PrivateLayout,
    //   allowedRoles: ["admin", "doctor"],
    //   component: MessageCenter,
    // },
    {
      path: "/message-center/:type/:room_id",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.view_messages,
      component: MessageCenter,
    },
    {
      path: "/inventory/add-new-service-in-inventory",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.edit_services,
      component: AddServiceInventory,
    },

    {
      path: "/video-chat/:appointment_id",
      exact: true,
      layout: VideoCallLayout,
      allowedRoles: ["admin", "doctor", "pet-owner"],
      component: VideoCall,
    },

    {
      path: "/inventory/edit-existing-product-in-inventory",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.edit_products,
      component: EditProductInventory,
    },

    {
      path: "/inventory/add-new-bundle-in-inventory",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.edit_bundles,
      component: AddBundleInventory,
    },

    {
      path: "/inventory/add-new-order",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.edit_purchase_order,
      component: AddPurchaseOrder,
    },
    {
      path: "/inventory/add-new-pos-in-inventory",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.edit_POS,
      component: AddPosInventory,
    },
    {
      path: "/inventory/add-new-supplier-in-inventory",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: permissions?.edit_suppliers,
      component: AddSupplier,
    },
    {
      path: "/live-clinic",
      exact: true,
      layout: PrivateLayout,
      allowedRoles: ["admin", "doctor"],
      component: LiveClinic,
    },

    {
      path: "/pet-owner-dashboard",
      exact: true,
      layout: PetOwnerDashboardLayout,
      allowedRoles: ["pet-owner"],
      component: PetOwnerDashboard,
    },

    {
      path: "/pet-owner-dashboard/pet-detail/:petId",
      exact: true,
      layout: PetOwnerDashboardLayout,
      allowedRoles: ["pet-owner"],
      component: PetOwnerPetDetail,
    },
    {
      path: "/pet-owner-health-snapshot",
      exact: true,
      layout: PetOwnerDashboardLayout,
      allowedRoles: ["pet-owner"],
      component: PetOwnerHealthSnapshot,
    },
    {
      path: "/pet-owner-appointments",
      exact: true,
      layout: PetOwnerDashboardLayout,
      allowedRoles: ["pet-owner"],
      component: PetOwnerAppointments,
    },
    {
      path: "/pet-owner-health-records",
      exact: true,
      layout: PetOwnerDashboardLayout,
      allowedRoles: ["pet-owner"],
      component: PetOwnerHealthRecords,
    },
    {
      path: "/pet-owner-messages",
      exact: true,
      layout: PetOwnerDashboardLayout,
      allowedRoles: ["pet-owner"],
      component: PetOwnerMessages,
    },
    {
      path: "/pet-owner-messages/:type/:room_id",
      exact: true,
      layout: PetOwnerDashboardLayout,
      allowedRoles: ["pet-owner"],
      component: PetOwnerMessages,
    },
    {
      path: "/pet-owner-billing",
      exact: true,
      layout: PetOwnerDashboardLayout,
      allowedRoles: ["pet-owner"],
      component: PetOwnerBilling,
    },
    {
      path: "/pet-owner-pet-services",
      exact: true,
      layout: PetOwnerDashboardLayout,
      allowedRoles: ["pet-owner"],
      component: PetOwnerPetServices,
    },
    {
      path: "/pet-owner-profile",
      exact: true,
      layout: PetOwnerDashboardLayout,
      allowedRoles: ["pet-owner"],
      component: PetOwnerProfile,
    },

    {
      path: "/super-admin/dashboard",
      exact: true,
      layout: SuperAdminLayout,
      allowedRoles: ["super-admin"],
      component: SuperAdminDashboard,
    },

    {
      path: "/super-admin/clinic",
      exact: true,
      layout: SuperAdminLayout,
      allowedRoles: ["super-admin"],
      component: AllClinics,
    },

    {
      path: "/super-admin/clinic-profile/:clinicId",
      exact: true,
      layout: SuperAdminLayout,
      allowedRoles: ["super-admin"],
      component: ClinicDeatil,
    },
    {
      path: "/super-admin/clinic-setting/:clinicId",
      exact: true,
      layout: SuperAdminLayout,
      allowedRoles: ["super-admin"],
      component: ClinicSetting,
    },
    {
      path: "/super-admin/manage-profile",
      exact: true,
      layout: SuperAdminLayout,
      allowedRoles: ["super-admin"],
      component: ManageProfile,
    },
    {
      path: "/super-admin/:anyOtherRoute*",
      exact: true,
      layout: SuperAdminLayout,
      allowedRoles: ["super-admin"],
      component: () => <Redirect to="/super-admin/clinic" />,
    },
    
  ];
};

export default HomeRoutes;
