import React, { useEffect, useState } from "react";
import { Button, Table, FormGroup, Input, InputGroup } from "reactstrap";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import './index.scss'
import { ReactComponent as DeleteSvg } from '../../../assets/img/delete.svg'
import { ReactComponent as CheckSvg } from '../../../assets/img/check_circle.svg'
import SwitchCell from "../../../shared/SwitchCell";
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as CrossSvg } from "../../../assets/img/close-square-svgrepo-com.svg"
import { ReactComponent as PencilEditSvg } from "../../../assets/img/pencil-edit-button-svgrepo-com.svg"
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { getServicesByClinicId } from "context/actions/service";
import { getServiceListByClinicId } from "context/actions/service";
import { getInventoriesByClinicIdWithoutLimit } from "context/actions/inventory";


import * as Yup from "yup";
import ProductAccordion from "components/inventory-module/ProductsDetail/ProductAccordion";


const ProductBlock = ({ className = '', addNew, setAddItem, lineItems, setLineItems }) => {



	const handleSubmit = (values) => {
		setLineItems((lineItemsPrev) => [...lineItemsPrev, values])
		setAddItem(false)
        setEditVal(null)
	}

	const [selectedService, setSelectedService] = useState(null)

	const [editVal, setEditVal] = useState(null)

	let lineItemDefaultState = {
		_id: undefined,
		product_name: "",
		quantity: 1,
		unit_price: undefined,
		amount: undefined

	}

	const removeLineItem = (e, id) => {
		e.preventDefault();
		let lineItemsUpdated = lineItems.filter((ele) => ele._id != id)
		setLineItems(lineItemsUpdated)
	}

	const itemSchema = Yup.object().shape({
		_id: Yup.string().required(),
		product_name: Yup.string().required(),
		quantity: Yup.number().required(),
		amount: Yup.number().required(),
		unit_price: Yup.number().required()	});

    const {clinic_details} = useSelector((state) => state.clinicReducer);
    const dispatch = useDispatch();
    const [products, setProducts] = useState([]);

    useEffect(() => {
        dispatch(
          getInventoriesByClinicIdWithoutLimit( clinic_details?._id, {},  (res) => {
            console.log(res, 'product list')
           setProducts(res.response_data);
          })
        );
      }, []);


	// console.log("lineItems", lineItems)


	return (
		<>
			<div className={`package-info-blc ${className}`}>
				{(lineItems?.length > 0 || addNew || editVal) &&
					<div className="table-listing-blc">
						<Formik
							initialValues={editVal || lineItemDefaultState}
							enableReinitialize
							validationSchema={itemSchema}
							onSubmit={(vals, { resetForm }) => {
								if (editVal) {
									// Update the item in the lineItems array with the edited values
									const updatedLineItems = lineItems.map((item) =>
										item._id === editVal._id ? vals : item
									);
									setLineItems(updatedLineItems);
									setEditVal(null); // Clear the editItem state
								}
								else {
									handleSubmit(vals)

								}
								resetForm(lineItemDefaultState);
							}}>

							{(formik) => (
								<Form>
									<Table className="table-striped">
										<thead>
											<tr>
												<th className="bg-transparent">Name</th>
											
												<th className="bg-transparent" style={{ minWidth: '120px' }}>Quantity</th>
												<th className="bg-transparent" style={{ minWidth: '250px' }}>Unit Price</th>
												<th className="bg-transparent" style={{ minWidth: '250px' }}>Total Price</th>

											</tr>
										</thead>
										<tbody>

											{addNew &&

												<tr className="bg-[#ffffff]">

													<td className="me-2">

                                                        <Select
                                                                    className="w-100 custom-select"
                                                                    placeholder={'Select Service'}
                                                                    value={{
																		_id: formik?.values?._id,
																		product_name: formik?.values?.product_name
																	}}
																	getOptionLabel={data => data?.product_name}
																	getOptionValue={data => data?._id}
                                                                    options={
                                                                    products?.length > 0 &&
                                                                    products?.map((data) => ({
																		
                                                                        _id: data?._id,
																		product_name : data?.product_name
                                                                        
                                                                        
                                                                    }))

                                                                    }
                                                                    // isMulti={true}
                                                                    onChange={i => {
																		//console.log(i)
																		let product = products.find(e => (e?._id == i?._id))
                                                                        formik.setFieldValue('_id', product?._id);
																		formik.setFieldValue('unit_price', product?.price)
																		formik.setFieldValue('amount',product?.price * formik?.values?.quantity)

                                                                        formik.setFieldValue('product_name', product?.product_name)
																		//setSelectedService(i)
																		console.log(formik.values)

                                                                    }}
                                                        />
													</td>
												
													<td className="me-2">

														<Input type="number" value={formik?.values?.quantity} onChange={(e) => {
															formik.handleChange(e);
															formik.setFieldValue('amount', formik?.values?.unit_price * e?.target?.value);
															}} name="quantity" placeholder="-Add Info-" />

													</td>

													<td className="me-2">

													{formik?.values?.unit_price && <>													
													${formik?.values?.unit_price?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}</>}


														{/*<Input disabled type="number" value={formik?.values?.unit_price} onChange={(e) => formik.handleChange(e)} name="price" placeholder="-Add Info-" />*/}

													</td>

													<td className="me-2">
													{formik?.values?.amount && <>
														${formik?.values?.amount?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}</>}

														{/*<Input disabled type="number" value={formik?.values?.amount} onChange={(e) => formik.handleChange(e)} name="price" placeholder="-Add Info-" />*/}

													</td>

													{/* <td></td> */}
													<td>
														<div className="flex items-center justify-end">
															<button type="button" class="edit-btn btn btn-link" onClick={(e) => {setAddItem(false)
                                                                                                                                setEditVal(null)}}><DeleteSvg /></button>
															<button className={'p-1'} style={{ width: '35px' }} size="sm" type="submit" ><CheckSvg /></button>
														</div>
													</td>


												</tr>
											}
											{
												(lineItems || []).filter(item => item?._id !== editVal?._id).map((item, i) =>
													<tr key={i}>
														{/* <td>{item?.unitDetails?.unit}</td> */}
														<td>
															{item?.product_name}
														</td>
														<td>
															{item?.quantity}
														</td>
														<td>
															${item?.unit_price?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
														</td>
														<td>
															${item?.amount?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
														</td>

														{/* <td>
															<SwitchCell labelVisible={true} className={item.stock_status ? '' : 'disable'} label={item.stock_status ? "In-Stock" : "Out-Stock"} />
														</td> */}
														<td>
															<div className="flex items-center justify-end">
																<button type="button" class="edit-btn btn btn-link" onClick={(e) => removeLineItem(e, item._id)}><DeleteSvg /></button>
																<button className={'p-1'} style={{ width: '35px' }} size="sm" type="button" onClick={() => {
																	setEditVal(item)
                                                                    setAddItem(true)
																}}><PencilEditSvg fill="gray" width={18} height={18} /></button>
															</div>
														</td>
													</tr>

												)
											}
										</tbody>
									</Table>
								</Form>)}
						</Formik>
					</div>}
			</div>
		</>
	);
};

export default ProductBlock;
