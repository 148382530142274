import React, { useState, useEffect } from "react";
import { Button, Input, Collapse } from "reactstrap";
import Accordions from "./Accordions";
import { getDentalByPetId, addNewDental, updateDental, addDentalNote } from "../../../../../../context/actions/patient-module/flowsheets/dental";
import  { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import './index.scss';
import NewPointPanel from "./NewPointPanel";
import NewNotePanel from "./NewNotePanel"
import NodataFoundBlock from "../../../../../../shared/NoDataFoundBlock";
import toast from "react-toastify";
import Spinner from "shared/Spinner";

const Dental = () => {

	const [loadIndex, setLoadIndex] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const params = useParams();


    const petId= params.petId
	

	const dispatch = useDispatch();
	const { results } = useSelector(state => state.dentalReducer);
	const userDetails = useSelector(state => state.user.user_details);

	const [newPointPanelState, setNewPointPanelState] = useState(false)
	const [newNotePanelState, setNewNotePanelState] = useState(false)



	const setIndex = (i) => {
		setLoadIndex(i);
	}

	const handleGetResults = () => {
		setIsLoading(true)
		dispatch(getDentalByPetId( petId, (response) => {
			console.log(response)
			setIsLoading(false)
		}))
    }

	const handleAddNote = (data, callback) => {
		console.log(data)
		//data.append('pet_id', petId)
		data.append('created_by', userDetails?._id)
		data.append('record_id', results?.[loadIndex]._id)
		dispatch(addDentalNote(data, callback))
	}


	const handleAddPoint= (data, callback) => {
		dispatch(addNewDental(  {...data, pet_id: petId, created_by: userDetails._id}, callback))

    }

	

	const handleUpdate = (data) => {
		dispatch(updateDental(data, (result) => {console.log(result)}))
	}

	useEffect(() => {
        handleGetResults();
    }, [])



	return (
		<>
			<div className="dental-block">
				<div className="head section-head d-flex align-items-center">
					<div className="left-col">
						<h3>Dental</h3>
					</div>
					<div className="right-col ms-auto">
						<Button onClick={() => {setNewPointPanelState(!newPointPanelState)}} color="link" className="add-btn d-inline-flex">
							<span className="icomoon-plus-btn"></span>
						</Button>
					</div>
				</div>
				{isLoading ? <div className="mx-auto">
					<Spinner height={150}/></div> :<div className="body">
					<div className='left-col'>
						<div className='pic-frame' style={{position:'relative'}} >
							
								<img className="dog-img" src={require("../../../../../../assets/img/dental-diagram.png")}></img>
								{results?.map((data, i) => 
											data?.coordinates?.map((point, j) =>
													<div key={j}
														onClick={()=> {setIndex(i)}}
														style={{
																position: 'absolute',
																left: `${point.x - 10}px`,
																top: `${point.y - 10}px`,
																backgroundColor: i === loadIndex ?  '#11ca9d' :'#3D3EFD',
																width: 20,
																height: 20,
																borderRadius: 100,
																fontFamily: 'Inter',
																fontStyle: 'normal',
																fontWeight: 600,
																fontSize: '14px',
																lineHeight: '150%',
																textAlign:"center",
																color: '#FFFFFF'
														}}>
														{i + 1}
													</div> 
													)
											)
									}	        
						</div>
					</div>
					<div className='right-col' style={{maxWidth: '100%', overflow:'hidden' }}>
						{
							results.length > 0 ? 
							<>

						
							<div className="brief">
								<div className="text-lg">
									Canine Dental Record
								</div>
								
							</div>
							<div className="description">
								<div className="description-row">
									<div className="text-sm">Remarks</div>
									<div className="text-sm">{results[loadIndex]?.remarks || '--'}</div>
								</div>
								<div className="description-row">
									<div className="text-sm">Skull Type</div>
									<div className="text-sm">{results[loadIndex]?.skull_type || '--'}</div>
								</div>
								<div className="description-row">
									<div className="text-sm">Priodontal Exam</div>
									<div className="text-sm">{results[loadIndex]?.priodontal_exam || '--'}</div>
								</div>
								<div className="description-row">
									<div className="text-sm">Oral Hygiene</div>
									<div className="text-sm">{results[loadIndex]?.oral_hygiene || '--'}</div>

								</div>
								<div className="description-row">
									<div className="text-sm">Tooth Abnormalities</div>
									<div className="text-sm">{results[loadIndex]?.tooth_abnormalities || '--'}</div>
								</div>
								<div className="description-row">
									<div className="text-sm">Occlusion</div>
									<div className="text-sm">{results[loadIndex]?.occlusion || '--'}</div>
								</div>
							</div>
							<div className="d-flex w-100 flex-row">
								<div className="text-lg">Doctor's Notes</div>
								<Button color="link" onClick={() =>  {
									setNewNotePanelState(!newNotePanelState)}} className="ms-auto p-0 align-self-center add-btn d-inline-flex">
									<span className="icomoon-plus-btn"></span>
								</Button>
							</div>


							{results?.[loadIndex]?.notes.length > 0 ?
								results?.[loadIndex]?.notes?.reverse().map((data, i) => 
								
								<div key={i} className="note">
									<div className="doctor-img">
										<img src={data?.addedBy?.user_image} alt="User Image"></img>
									</div>
									<div className="body">
										<div className="note-metadata">
											<div className="name">{data?.addedBy?.firstname} {data?.addedBy?.lastname}</div>
											<div className="date">{(new Date(data?.date)).toLocaleDateString('en-US', {day:'numeric', month:'short', year:'numeric'})}</div>
										</div>
										<div className="comment-box">
											<div style={{fontWeight: '600'}} className="teeth text-sm">

												{data?.teeth?.join(", ")}

											</div>
											<div className="text-sm">
												{data?.note}
											</div>
											<div style={{gap:'4px', display:'flex', flexDirection: 'row'}}>

											{data?.images?.map((img, i) => (
												<a target="_blank" href={img} > <img  style={{borderRadius: '10px', width: '50px', height: '50px'}}src={img} key={i} alt='update-image'></img></a>
											))}
											</div>
										</div>

									</div>
								</div>)
								: 	<NodataFoundBlock type={'medical history'} className="ms-auto mr-auto" /> 
								
								}
							</> :
							<NodataFoundBlock type={'medical history'}  className="ms-auto mr-auto" /> 

						}

						

						{/*

						<Accordions loadIndex={loadIndex} setLoadIndex={setLoadIndex} data={results} remove={(data) => {handleUpdate({...data, resolved_date: new Date(), active: false})
																											   handleGetResults()} }  />
					
						*/}																							   
					</div>
				</div>	}		
				</div>

			<div onClick={() => {setNewPointPanelState(false)}} className={`new-point-panel-overlay position-fixed ${newPointPanelState ? 'active' : ''}`}></div>
			<NewPointPanel  closeNewPointPanel={() => {setNewPointPanelState(false)}} refresh={handleGetResults} addPoint={handleAddPoint} className={`${newPointPanelState ? 'active' : ''}`} />

			<div className={`new-point-panel-overlay position-fixed ${newNotePanelState ? 'active' : ''}`}></div>
			<NewNotePanel  closeNewPointPanel={() => {setNewNotePanelState(false)}} refresh={handleGetResults} addPoint={handleAddNote} record={results?.[loadIndex]} className={`${newNotePanelState ? 'active' : ''}`} />
		</>
	);
};

export default Dental;