/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";
import { FaUser } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { RiMapPinAddLine } from "react-icons/ri";
import { Accordion, Offcanvas, Spinner } from "react-bootstrap";
import TextInput from "shared/FormsInput/TextBox";
import { appointmentTypes, toasterConfig } from "utils/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import {
  addNewAppointment,
  getAllDoctorsDetails,
} from "context/actions/appointment";
import "./AppointmentRequest.scss";
import NodataFoundBlock from "shared/NoDataFoundBlock";

const AppointmentRequest = ({ onHide, handleSuccess }) => {
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.user.user_details);
  const { petDetails } = useSelector((state) => state.petOwnerDashboardReducer);
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );
  const { holidays } = useSelector((state) => state.holidayReducer);
  const [searchText, setSearchText] = useState("");
  const [doctorList, setDoctorList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [dateType, setDateType] = useState("today");
  const [formValue, setFormValue] = useState({
    clinic_id: petDetails?.clinic?.[0],
    created_by: userDetail?._id,
    patient_id: petDetails?._id,
    doctor_id: "",
    appointment_type: "",
    appointment_date: new Date(),
    reason: "",
    condition: "",
    duration: 30,
    description: "",
    override_existing_bookings: false,
    selected_operating_rooms: [],
    appointment_time: [],
    appointment_starts: [],
    slot_time: [],
  });
  const getDates = (startDate, endDate) => {
    var dates = [];

    var currDate = moment(startDate, "DD-MM-YYYY").startOf("day");
    var lastDate = moment(endDate, "DD-MM-YYYY").startOf("day");

    while (currDate.isSameOrBefore(lastDate, "day")) {
      dates.push(currDate.clone());
      currDate.add(1, "days");
    }

    return dates;
  };
  const handleChange = (e) => {
    let fieldName = e.target.id;
    let fieldValue = e.target.value;
    let oldVal = { ...formValue };
    if (fieldName === "appointment_date") {
      oldVal["appointment_time"] = [];
      oldVal["appointment_starts"] = [];
      oldVal["slot_time"] = [];
    }
    if (fieldName === "appointment_type") {
      oldVal["duration"] =
        clinic_settings?.appointment_types_durations?.[fieldValue] || 30;
      oldVal["appointment_time"] = [];
      oldVal["appointment_starts"] = [];
      oldVal["slot_time"] = [];
    }
    oldVal[fieldName] = fieldValue;
    setFormValue(oldVal);
  };
  const handleSlotSelection = (doctorID, timeSlot) => {
    let oldList = formValue.appointment_time;
    if (doctorID !== formValue.doctor_id) {
      oldList = [];
    }
    if (oldList.includes(timeSlot)) {
      oldList = oldList.filter((o) => o !== timeSlot);
    } else {
      oldList.push(timeSlot);
    }
    setFormValue((prev) => {
      return { ...prev, doctor_id: doctorID, appointment_time: oldList };
    });
  };
  const calculateSlot = (startTime, endTime) => {
    let slots = [];
    let nDuration = formValue?.duration || 30;
    let start_time = moment(startTime, "hh:mm A");
    let end_time = moment(endTime, "hh:mm A");
    var loop = start_time;
    while (loop < end_time) {
      let sTime = loop.format("h:mm a");
      loop = moment(loop).add(nDuration, "minutes");
      slots.push({
        sTime: sTime,
        eTime: loop.format("h:mm a"),
      });
    }
    return slots;
  };
  const getDoctorList = async (dDate) => {
    let filterDate = moment(dDate).format("DD-MM-YYYY");
    dispatch(
      getAllDoctorsDetails(formValue?.clinic_id, filterDate, {}, (result) => {
        if (Array.isArray(result)) {
          setDoctorList(result);
        }
      })
    );
  };
  const handleSave = () => {
    if (!formValue.appointment_type) {
      toast.error("Appoitnment type is required", toasterConfig);
      return;
    } else if (!formValue.reason) {
      toast.error("Appoitnment reason is required", toasterConfig);
      return;
    } else if (!formValue.appointment_time.length) {
      toast.error("Appoitnment time is required", toasterConfig);
      return;
    } else {
      setBtnLoading(true);
      let allStartTime = formValue?.appointment_time?.map(
        (o) => o.split(" - ")?.[0]
      );
      const payload = {
        ...formValue,
        appointment_starts: allStartTime,
        slot_time: allStartTime,
        isAppointmentAproved: 0,
        appointment_date: moment(formValue?.appointment_date).format(
          "DD-MM-YYYY"
        ),
      };
      dispatch(
        addNewAppointment(payload, (result) => {
          setBtnLoading(false);
          if (result && !result.errors) {
            handleSuccess();
          }
        })
      );
    }
  };

  useEffect(() => {
    if (formValue?.appointment_date) {
      getDoctorList(formValue?.appointment_date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue?.appointment_date]);

  const {
    appointment_type,
    reason,
    description,
    appointment_date,
    appointment_time,
    doctor_id,
  } = formValue;
  let displayList = [];
  doctorList?.forEach((elm) => {
    let name = `${elm?.user?.title} ${elm?.user?.firstname} ${elm?.user?.lastname}`;
    if (name.toLowerCase().includes(searchText?.toLowerCase())) {
      displayList.push(elm);
    }
  });
  let today = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  let isHolidayOnSelectedDate = false;
  if (appointment_date && holidays && holidays?.length > 0) {
    isHolidayOnSelectedDate = holidays?.some((o) =>
      moment(o.date, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").isSame(
        moment(appointment_date)
      )
    );
  }
  return (
    <Offcanvas
      show={true}
      placement="end"
      onHide={onHide}
      id="AppointmentRequest-offcanvas"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <div className="text-24-600 color-2E65">Request a Appointment</div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-0 ps-2 pe-2 w-100">
        <Accordion defaultActiveKey={["0", "1"]}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Appointment Information</Accordion.Header>
            <Accordion.Body className="row">
              <div className="col-md-2 mb-2 text-14-400 color-2E65 fa-center">
                <span>Type</span>
                <span className="color-dc35 ms-1">*</span>
              </div>
              <div className="col-md-10 mb-2 text-14-400 color-2E65">
                <Input
                  type="select"
                  id="appointment_type"
                  onChange={handleChange}
                  value={appointment_type}
                >
                  <option value="">Type option</option>
                  {appointmentTypes.map(
                    (apptType, i) =>
                      apptType?.value !== "appointment-block" && apptType.value !== 'surgery' && (
                        <option key={i} value={apptType.value}>
                          {apptType.label}
                        </option>
                      )
                  )}
                </Input>
              </div>
              <div className="col-md-2 mb-2 text-14-400 color-2E65 fa-center">
                <span>Reason</span>
                <span className="color-dc35 ms-1">*</span>
              </div>
              <div className="col-md-10 mb-2 text-14-400 color-2E65">
                <TextInput
                  type="text"
                  id="reason"
                  placeholder="Reason option"
                  className="mb-0"
                  value={reason}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-2 mb-2 text-14-400 color-2E65 fa-center">
                <span>Notes</span>
              </div>
              <div className="col-md-10 mb-2 text-14-400 color-2E65">
                <TextInput
                  type="text"
                  id="description"
                  placeholder="Enter description"
                  className="mb-0"
                  value={description}
                  onChange={handleChange}
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Schedule Appointment</Accordion.Header>
            <Accordion.Body>
              <div className="fb-center">
                <div className="date-selection">
                  <span
                    className={dateType === "today" ? "active" : ""}
                    onClick={() => {
                      if (dateType !== "today") {
                        handleChange({
                          target: {
                            id: "appointment_date",
                            value: today,
                          },
                        });
                        setDateType("today");
                      }
                    }}
                  >
                    Today {moment(today).format("D MMM, ddd")}
                  </span>
                  <span
                    className={dateType === "tomorrow" ? "active" : ""}
                    onClick={() => {
                      if (dateType !== "tomorrow") {
                        handleChange({
                          target: {
                            id: "appointment_date",
                            value: tomorrow,
                          },
                        });
                        setDateType("tomorrow");
                      }
                    }}
                  >
                    Tomorrow {moment(tomorrow).format("D MMM, ddd")}
                  </span>
                  <span className={dateType === "" ? "active" : ""}>
                    <DatePicker
                      selected={!dateType ? appointment_date || null : null}
                      onChange={(date) => {
                        setDateType("");
                        handleChange({
                          target: {
                            id: "appointment_date",
                            value: date,
                          },
                        });
                      }}
                      placeholderText="Select Day"
                    />
                  </span>
                </div>
                <div className="fa-center gap-2">
                  {/* <div className="select-block">
                    <select>
                      <option value="">Location Filter</option>
                    </select>
                  </div> */}
                  <div className="search-block">
                    <span className="searhc-icon">
                      <CiSearch color="#788693" />
                    </span>
                    <input
                      type="text"
                      placeholder="Search Doctor"
                      value={searchText}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="mt-3"
                style={{ maxHeight: "330px", overflow: "auto" }}
              >
                {displayList?.length === 0 ? (
                  <div>
                    <NodataFoundBlock />
                  </div>
                ) : (
                  displayList?.map((elem, index) => {
                    const { user, staffAvailability, appointments } = elem;
                    let timeSlotList = [];
                    staffAvailability?.forEach((s) => {
                      const selectedDate =
                        moment(appointment_date).format("DD-MM-YYYY");
                      let sDateArray = getDates(s?.start_date, s?.end_date);
                      const isBetween = sDateArray?.some(
                        (o) => o.format("DD-MM-YYYY") === selectedDate
                      );

                      if (
                        isBetween &&
                        s?.isAvailable &&
                        s?.isAvailable &&
                        !isHolidayOnSelectedDate
                      ) {
                        let slotList = calculateSlot(
                          s?.start_time,
                          s?.end_time
                        );
                        timeSlotList.push(...slotList);
                      }
                    });
                    let name = `${user?.title} ${user?.firstname} ${user?.lastname}`;

                    return (
                      <div
                        className="d-flex gap-2 bg-ffff br-10 cps-15 cpe-10 cpt-10 cpb-10 mb-2"
                        key={index}
                      >
                        <div className="w-300" style={{ minWidth: "300px" }}>
                          <div className="d-flex gap-2">
                            <div className="h-49 w-49 f-center rounded-circle bg-f8f8">
                              <FaUser color="#788C9F" />
                            </div>
                            <div>
                              <div className="text-16-400 color-2E65">
                                {name}
                              </div>
                              <div className="text-13-400 color-2E65">DVM</div>
                            </div>
                          </div>
                          {user?.city && (
                            <div className="fa-center gap-2 mt-2">
                              <span className="d-flex">
                                <RiMapPinAddLine color="#282E65" />
                              </span>
                              <span className="text-14-400 color-2E65">
                                {user?.city}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="flex-grow-1 fa-center">
                          {timeSlotList?.length === 0 ? (
                            <div className="no-time-slot text-center text-14-400 w-100">
                              No Slot Available!!
                            </div>
                          ) : (
                            <div className="fa-center gap-2">
                              {timeSlotList.map((e, eIndex) => {
                                let isExist = appointments?.some(
                                  (et) =>
                                    et.appointment_timings?.some(
                                      (ets) => ets.slot_time === e.sTime
                                    ) &&
                                    et.isAppointmentAproved === 1 &&
                                    et.appointment_date ===
                                      moment(appointment_date).format(
                                        "DD-MM-YYYY"
                                      )
                                );
                                let dTimeSlot = `${e.sTime} - ${e.eTime}`;
                                let isActive =
                                  appointment_time?.includes(dTimeSlot) &&
                                  doctor_id === user?._id;
                                return (
                                  <div
                                    key={eIndex}
                                    className={`text-12-500 color-2E65 bg-f8f8 ps-3 pe-3 pt-1 pb-1 br-4 cursor-pointer ${
                                      isExist ? "opacity-50" : ""
                                    } ${isActive ? "active" : ""}`}
                                    onClick={() => {
                                      if (!isExist) {
                                        handleSlotSelection(
                                          user?._id,
                                          dTimeSlot
                                        );
                                      }
                                    }}
                                  >
                                    {e.sTime}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="d-flex align-items-center justify-content-end gap-2 mt-3">
          <Button
            color="link"
            onClick={onHide}
            className="btn btn-outline-light"
          >
            Cancel
          </Button>
          <Button
            color="success"
            onClick={() => {
              if (!btnLoading) {
                handleSave();
              }
            }}
            className="btn btn-outline-light"
          >
            Send Request{btnLoading && <Spinner size="sm" className="ms-2" />}
          </Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AppointmentRequest;
