import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";

toast.configure();
/**
 * All Api endpoints to be call
 * GET    -- /app-content/get-roles
 * GET    -- /app-content/get-behaviour-tags
 * GET    -- /app-content/get-allergies-tags
*/

/**
 * 
 * @param {String} clinic_id
 * @param {Object} params
 * @returns 
 */
export const getAllRoles = (params, callback) => {
    return (dispatch, getState) => {
        const { user: { token, user }, clinicReducer: { clinic_details } } = getState();
        if (clinic_details) {
            ApiClient.get(`${apiUrl}${PORT}/app-content/get-clinic-roles/${clinic_details?._id}`, params, token, dispatch, getState).then((response) => {

                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    dispatch({ type: "GET_ROLES", payload: response?.response_data })
                    return callback(response);
                } else {
                    ////toast.error(response.message, toasterConfig);
                    return callback(response);
                }
            })
        }
    };
};

/**
 * 
 * @param {String} clinic_id
 * @param {Object} params
 * @returns 
 */
export const getAllRace = (params, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, clinicReducer: { clinic_details } } = getState();
        if (clinic_details) {
            ApiClient.get(`${apiUrl}${PORT}/app-content/get-all-race`, params, token, dispatch, getState).then((response) => {

                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    dispatch({ type: "GET_RACE", payload: response?.response_data })
                    return callback(response);
                } else {
                    ////toast.error(response.message, toasterConfig);
                    return callback(response);
                }
            })
        }
    };
};

/**
 * 
 * @param {Object} params
 * @param {Function} callback
 * @returns 
 */
export const getAllSpecies = (params, callback) => {
    return (dispatch, getState) => {
        const { user: { token } } = getState();
        ApiClient.get(`${apiUrl}${PORT}/app-content/get-all-species`, params, token, dispatch, getState).then((response) => {

            if (!response) {
                return callback();

            }
            else if (response?.status) {
                dispatch({ type: "GET_SPECIES", payload: response?.response_data })
                return callback(response);
            } else {
                ////toast.error(response.message, toasterConfig);
                return callback(response);
            }
        })
    };
};

/**
 * 
 * @param {Object} species
 * @param {Function} callback
 * @returns 
 */
export const getAllBreeds = (species, callback) => {
    return (dispatch, getState) => {
        const { user: { token } } = getState();
        ApiClient.get(`${apiUrl}${PORT}/app-content/get-all-breeds/${species}`, {}, token, dispatch, getState).then((response) => {
            if (!response) {
                return callback();
            }
            else if (response?.status) {
                dispatch({ type: "GET_BREEDS", payload: response?.response_data })
                return callback(response);
            } else {
                ////toast.error(response.message, toasterConfig);
                return callback(response);
            }
        })
    };
};

/**
 * 
 * @param {Object} params
 * @param {Function} callback
 * @returns 
 */
export const getAllCity = (params, callback) => {
    return (dispatch, getState) => {
        const { user: { token } } = getState();
        ApiClient.get(`${apiUrl}${PORT}/app-content/get-all-city`, params, token, dispatch, getState).then((response) => {

            if (!response) {
                return callback();
            }
            else if (response?.status) {
                dispatch({ type: "GET_CITIES", payload: response?.response_data })
                return callback(response);
            } else {
                ////toast.error(response.message, toasterConfig);
                return callback(response);
            }
        })
    };
};

/**
 * 
 * @param {Object} species
 * @param {Function} callback
 * @returns 
 */
export const getAllInventoryCategories = (callback) => {
    return (dispatch, getState) => {
        const { user: { token } } = getState();
        ApiClient.get(`${apiUrl}${PORT}/app-content/get-all-inventory-category`, {}, token, dispatch, getState).then((response) => {
            if (!response) {
                return callback();
            }
            else if (response?.status) {
                dispatch({ type: "GET_INVENTORY_CATEGORIES", payload: response?.response_data })
                return callback(response);
            } else {
                //toast.error(response.message, toasterConfig);
                return callback(response);
            }
        })
    };
};


/**
 * 
 * @param {Object} species
 * @param {Function} callback
 * @returns 
 */
export const getAllServiceCategories = (callback) => {
    return (dispatch, getState) => {
        const { user: { token } } = getState();
        ApiClient.get(`${apiUrl}${PORT}/app-content/get-all-service-category`, {}, token, dispatch, getState).then((response) => {
            if (!response) {
                return callback();
            }
            else if (response?.status) {
                dispatch({ type: "GET_SERVICE_CATEGORIES", payload: response?.response_data })
                return callback(response);
            } else {
                //toast.error(response.message, toasterConfig);
                return callback(response);
            }
        })
    };
};

/**
 * 
 * @param {Object} params
 * @param {Function} callback
 * @returns 
 */
export const getBehaviourTags = (params, callback) => {
    return (dispatch, getState) => {
        const { user: { token } } = getState();
        ApiClient.get(`${apiUrl}${PORT}/app-content/get-behaviour-tags`, params, token, dispatch, getState).then((response) => {

            if (!response) {
                return callback();
            }
            else if (response?.status) {
                dispatch({ type: "GET_BEHAVIOUR_TAGS", payload: response?.response_data })
                return callback(response);
            } else {
                //toast.error(response.message, toasterConfig);
                return callback(response);
            }
        })
    };
};

/**
 * 
 * @param {Object} params
 * @param {Function} callback
 * @returns 
 */
export const getAllergiesTags = (params, callback) => {
    return (dispatch, getState) => {
        const { user: { token } } = getState();
        ApiClient.get(`${apiUrl}${PORT}/app-content/get-allergies-tags`, params, token, dispatch, getState).then((response) => {

            if (!response) {
                return callback();
            }
            else if (response?.status) {
                dispatch({ type: "GET_ALLERGIES_TAGS", payload: response?.response_data })
                return callback(response);
            } else {
                //toast.error(response.message, toasterConfig);
                return callback(response);
            }
        })
    };
};


