import React, { useEffect, useState } from "react";
import clinicImg from "../../../../../assets/img/superAdmin/images/logo.png";
import { Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { getSingleClinicDetails } from "../../../../../context/actions/clinic";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import "./index.scss";
import { formatPhoneNumber } from "utils/common";

const ClinicInfo = () => {
  const params = useParams();
  const { clinicId } = params;
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [imgURL, setImgURL] = useState("");
  const originalDate = new Date();

  const year = originalDate.getFullYear();

  const getClinicInfo = async () => {
    const response = await dispatch(getSingleClinicDetails(clinicId));
    setData(response?.response_data?.[0] || {});
  };

  useEffect(() => {
    getClinicInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { email, phone, address, clinic_name, clinicLogo } = data;

  return (
    <div className="p-4 row profile-info">
      <div className="col-lg-2 col-12">
        <div className="flex gap-4 max-w-400">
          <div className="d-flex items-center justify-center">
            <img
              src={imgURL || clinicLogo || clinicImg}
              alt="clinic"
              onError={(e) => {
                setImgURL(clinicImg);
              }}
              className="rounded-circle w-20 aspect-ratio-1"
            />
          </div>
          <div className="info flex flex-col justify-center">
            <h3>{clinic_name}</h3>
            <h6>Member Since {year}</h6>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-12 flex">
        <div className="info me-auto email">
          <h4>Email</h4>
          <h6>{email}</h6>
        </div>

        <div className="info  phone">
          <h4>Phone</h4>
          <h6>{formatPhoneNumber(phone)}</h6>
        </div>
      </div>
      <div className="col-lg-4 col-12 flex">
        <div className="info me-auto address">
          <h4>Address</h4>
          <h6>{address ? address : "99 Marinabay Road, United States"}</h6>
        </div>

        <div className="add-plan">
          <p>No Plan Available!</p>
          <Button
            color="success"
            className="add-btn add-btn mt-2 d-flex align-items-center"
            // onClick={handleAddNew}
          >
            Add plan
            {/* <span className="mx-auto">Add plan</span> */}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ClinicInfo;
