import * as Yup from "yup";
import { REGEX } from "../../../utils/constants";
import "yup-phone-lite"
/**
 * @description declaration form schema for client validations
 */
export const signUpSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email address field  is required"),
  password: Yup.string()
    .matches(/^\S*$/, 'Space is not allowed')
    .min(8, "Password must be atleast 8 characters long")
    .max(50, "Maximum 50 symbols")
    .required("Password field is required")
    .matches(REGEX.PASSWORD,
      'Password must contain atleast one alphabet, one special character and one numeric value'),
  confirmPassword: Yup.string()
    .required("Confirm password field is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  firstname: Yup.string().trim()
    .required("First Name is required")
    .max(50, "This field should be maximumun 50 characters.")
    .matches(REGEX.NAME,
      'This field only accepts alphabets!'),
  lastname: Yup.string().trim()
    .required("Last Name is required")
    .max(50, "This field should be maximumun 50 characters.")
    .matches(REGEX.NAME,
      'This field only accepts alphabets!'),
  phone: Yup.string().phone().required(),
  address: Yup.string().required("Address is required"),
  acceptTerms: Yup.bool().oneOf([true], 'Please accept the terms and condition')
});

/**
 * @description params intilaization 
 */

export const signUpInit = {
  email: '',
  password: '',
  firstname: '',
  lastname: '',
  phone: '',
  address: '',
  confirmPassword: '',
  acceptTerms: false
}