import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../context/actions/user";
import { toast } from "react-toastify";
import { inactivityMessage } from "../utils/messages";
import { useIdleTimer } from "react-idle-timer";
import { timeout, toasterConfig } from "../utils/constants";
import {auth} from "../firebase"
import { useAuthState } from "react-firebase-hooks/auth";

import RightSidebar from "../shared/RightSidebar";
import SuperAdminSidebar from "../shared/SuperAdminSidebar/superadmin";

const SuperAdminLayout = ({ children, className }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  let userDetails = useSelector((state) => state.user.user_details);
  const [user, loading, error] = useAuthState(auth);



  useEffect(() => { 
    if (loading) return;
    else if (!user) {
      dispatch({ type: "LOGOUT", data: {} });
      history.push('/login')

    };}, [user, loading])
  //   console.log("PrivateLayout userDetails", userDetails);


  // useEffect( () => {

  //   if (!(userDetails || localStorage.getItem("USER"))) {
  //     dispatch(
  //       logout(() => {
  //         history.push("/login");
  //       })
  //     );
  //   }
  // }, [])
  // useEffect( () => {

  //   if (!(userDetails || localStorage.getItem("USER"))) {
  //     dispatch(
  //       logout(() => {
  //         history.push("/login");
  //       })
  //     );
  //   }
  // }, [userDetails])


  const onIdle = () => {
    dispatch(
      logout(() => {
        history.push("/login");
        toast.success(inactivityMessage, toasterConfig);
      })
    );
  };
  useIdleTimer({
    timeout,
    onIdle,
  });
  return (
    <>
      <div className="main-wrapper d-flex flex-row flex-fill overflow-auto">
        <SuperAdminSidebar />
        <div
          className="right-wrapper d-flex flex-column flex-fill overflow-auto super-wrapper"
          style={{ minHeight: "100vh" }}
        >
          {children}
          {/* 
						{isLoading && <LoaderUI
								loader={isLoading}
								overlay={true}
								overlayRadius='rounded-0'
								size=""
							/>} 
					*/}
        </div>
      </div>
    </>
  );
};

export default SuperAdminLayout;
