import React, { useEffect, useState } from "react";
import { Input, Table, Nav, NavItem, NavLink, Button } from "reactstrap";
import "./index.scss";
import { AiOutlineSearch } from "react-icons/ai";
import { VscListUnordered } from "react-icons/vsc";
import { BsCircle } from "react-icons/bs";
import { MdMoving } from "react-icons/md";
import moment from "moment";
import { MdCloudDownload } from "react-icons/md";
import { BsThreeDots } from "react-icons/bs";
import TotalRevenue from "./totalRevenue";
import InvoiceStatsItem from "../../shared/InvoiceStatsItem/InvoiceStatsItem";
import CheckBox from "../../shared/CheckBox";
import SwitchCell from "../../shared/SwitchCell";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TotalRevenueInvoice from "./totalRevenueInvoice";
import NodataFoundBlock from "../../shared/NoDataFoundBlock";
import TotalRevenueEstimate from "./totalRevenueEstimate";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import LoaderUI from "shared/loader";
import TableListInvoicesFilterWrapper from "shared/TableListInvoicesFilterWrapper";
import TableListEstimateFilterWrapper from "shared/TableListEstimateFilterWrapper";
import { getAllEstimatesData, getAllInvoiceData, updateInvoiceStatus } from "context/actions/invoice";
import PaginationBlock from "shared/PaginationBlock";

const BackDrop = (props) => {
  return <div className="backdrop" onClick={props.closeDrawer}></div>;
};

export default function Invoices() {
  const dispatch = useDispatch()
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerOpenEstimate, setDrawerOpenEstimate] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [estimates, setEstimates] = useState([]);
  const [selectedInvoiceStatusData, setSelectedInvoiceStatusData] =
    useState(null);
  const [invoices, setInvoices] = useState([]);
  const history = useHistory();
  const { user_details, token } = useSelector((state) => state.user);
  const { clinic_details } = useSelector((state) => state.clinicReducer);

  const [activeTab, setActiveTab] = useState("1");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [estPage, setEstPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [filterPayload, setFilterPayload] = useState({
    searchText: "",
    status: "",
    payment_method: "",
    start_date: "",
    end_date: ""
  });
  const [estimateFilterPayload, setEstimateFilterPayload] = useState({
    searchText: "",
    start_date: "",
    end_date: ""
  });

  function handleOpenDrawerEstimateButton(invoiceData) {
    setDrawerOpenEstimate(!drawerOpenEstimate);
    setSelectedInvoice(drawerOpen ? null : invoiceData ? invoiceData : null);
  }

  const [showStatusModal, setShowStatusModal] = useState(false);

  

  const fetchData = async () => {
    await dispatch(getAllInvoiceData({ ...filterPayload, clinic_id: clinic_details?._id, limit, page }, (res) => {
      if (res?.status) {

        setInvoices(res?.response_data)
      }
    }))
    // const estimatesList = await fetchEstimates();

  }

  const fetchEstimatesData = async () => {
    await dispatch(getAllEstimatesData({ ...estimateFilterPayload, clinic_id: clinic_details?._id, limit, page: estPage }, (res) => {
      if (res?.status) {

        setEstimates(res?.response_data);
      }
    }))
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, filterPayload]);


  useEffect(() => {
    fetchEstimatesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estPage, limit, estimateFilterPayload]);
  const handleopenStatusModal = (invoiceData) => {
    if (invoiceData?.status !== "Fully Paid") {
      setShowStatusModal(true);
      setSelectedInvoiceStatusData(invoiceData ? invoiceData : null);
    }
  };

  const closeStatusModal = () => {
    setShowStatusModal(false);
    setSelectedInvoiceStatusData(null);
  };

  const getStatusOptions = () => {
    const status = selectedInvoiceStatusData?.status;

    if (status === "Pending") {
      return ["Pending", "Partial Paid", "Fully Paid"];
    } else if (status === "Partial Paid") {
      return ["Partial Paid", "Fully Paid"];
    } else if (status === "Fully Paid") {
      return ["Fully Paid"];
    }

    return ["Pending", "Partial Paid", "Fully Paid"];
  };
  const paymentStatusOptions = getStatusOptions();

  const selectPaymentStatus = (e, selectedValue, invoice_id) => {
    e.preventDefault();

    Swal.fire({
      title:
        "<strong>Are you sure you want to change this invoice's status?</strong>",
      icon: "warning",
      html: `Once status is changed to fully paid, you cannot change the status again`,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Confirm",
      confirmButtonAriaLabel: "Confirm",
      cancelButtonText: "Cancel",
      cancelButtonAriaLabel: "Cancel",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        updateStatus(selectedValue, invoice_id);
      } else if (result.isDenied) {
        Swal.fire("Your data is safe", "", "info");
      } else if (result.isDismissed) {
        Swal.fire("Your data is safe", "", "info");
      }
    });
  };

  const updateStatus = async (value, id) => {

    const payload = {
      invoice_id: id,
      status: value,
    };
    try {
      dispatch(updateInvoiceStatus(payload, response => {
      if (
        response?.data?.statusCode === 200 &&
        response?.data?.message === "Invoice updated successfully!"
      ) {
        dispatch(getAllInvoiceData({ clinic_id: clinic_details?._id }, (res) => {
          if (res?.status) {
            setInvoices(res?.response_data)
          }
        }))
        // const list = await fetchInvoices();
        // setInvoices(list);
      }
      setShowStatusModal(false);
      return response.data?.response_data;
      }))
    } catch (error) {
      console.error("Error fetching invoices:", error.message);
      throw error;
    }
  };
  function handleOpenDrawerButton(invoiceData) {
    setDrawerOpen(!drawerOpen);
    setSelectedInvoice(drawerOpen ? null : invoiceData ? invoiceData : null);
  }

  function handleBackdropClick() {
    setDrawerOpen(false);
    setDrawerOpenEstimate(false);
    setSelectedInvoice(null);
  }

  const formateDate = (date) => {
    let todayDate = new Date(date);
    let month = todayDate.getMonth() + 1;
    let formatted =
      todayDate.getFullYear() + "/" + month + "/" + todayDate.getDate();
    return formatted;
  };
  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;

  return (
    <div>
      {isLoading && <LoaderUI overlay={true} />}
      <div className="p-3 flex  align-items-center gap-y-3 justify-between">
        <div>
          <p className="text-[24px] text-[#282E65] font-[600]">Invoices</p>
        </div>
        {permissions?.edit_invoices && (
          <div className="flex gap-2 items-center">
            <div className="btn-blc">
              <button
                className="appointment-btn me-2"
                onClick={() => history.push("/invoices/add-estimate")}
              >
                Estimation Calculator
              </button>
            </div>
            <div className="btn-blc">
              <Button
                color="success"
                onClick={() => history.push("/invoices/add-invoice")}
                className="add-btn"
              >
                Add new
              </Button>
            </div>
          </div>
        )}
      </div>

      <Nav tabs style={{ marginLeft: ".75rem" }} className="d-flex tab_row1">
        <NavItem>
          <NavLink
            className={activeTab == "1" ? "active" : ""}
            onClick={() => {
              setActiveTab("1")
              setPage(1)
            }}
          >
            Invoices
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab == "2" ? "active" : ""}
            onClick={() => {
              setActiveTab("2")
              setEstPage(1)
            }}
          >
            Estimates
          </NavLink>
        </NavItem>
      </Nav>

      {activeTab === "1" ? (
        <>
          <div style={{ paddingTop: "10px" }}>
            <TableListInvoicesFilterWrapper
              filterPayload={filterPayload}
              setFilterPayload={setFilterPayload}
              setPage={setPage}
            />
          </div>



          {/*  
      <div className="flex px-2 gap-2 invoice_stats">
        <InvoiceStatsItem
          bgClass={"background-dark"}
          stats="$52,800"
          statsIcon={<BsCircle size={30} color="#fff" />}
          subHeading="Total Revenue"
          handelOpnInvoiceDrawer={handleOpenDrawerButton}
        />
        <InvoiceStatsItem
          bgClass={"bg-grey"}
          subHeadingText="text-[#282E65]"
          stats="$10K"
          statsIcon={<MdMoving size={20} color="#11CA9D" />}
          statsPercent="+50%"
          subHeading="Video Call "
        />
        <InvoiceStatsItem
          bgClass={"bg-grey"}
          subHeadingText="text-[#282E65]"
          stats="$4500"
          statsIcon={<MdMoving size={20} color="#11CA9D" />}
          statsPercent="+25%"
          subHeading="Video Conference"
        />
        <InvoiceStatsItem
          bgClass={"bg-grey"}
          subHeadingText="text-[#282E65]"
          stats="$2800"
          statsIcon={<MdMoving size={20} color="#11CA9D" />}
          statsPercent="-10%"
          subHeading="Phone Call"
        />
        <InvoiceStatsItem
          bgClass={"bg-grey"}
          subHeadingText="text-[#282E65]"
          stats="$19500"
          statsIcon={<MdMoving size={20} color="#11CA9D" />}
          statsPercent="+30%"
          subHeading="In Clinic"
        />
      </div>
      */}
          <div>
            {drawerOpen ? (
              <TotalRevenueInvoice
                show={drawerOpen}
                cancel={drawerOpen}
                setCancel={setDrawerOpen}
                invoiceData={selectedInvoice}
            petOwner={false}
                setInvoices={setInvoices}
                getData={fetchData}
              />
            ) : (
              ""
            )}

            {drawerOpen && <BackDrop closeDrawer={handleBackdropClick} />}
          </div>

          <Modal show={showStatusModal} onHide={closeStatusModal}>
            <Modal.Header closeButton>
              <Modal.Title>Are you sure want to change status?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Input
                type="select"
                name="select"
                className="!h-[42px] shadow-none w-[200px] cursor-pointer gap-2 items-center justify-center text-[14px] flex rounded-lg"
                onChange={(e) =>
                  selectPaymentStatus(
                    e,
                    e.target.value,
                    selectedInvoiceStatusData?._id
                  )
                }
                disabled={
                  selectedInvoiceStatusData?.status === "Fully Paid"
                    ? true
                    : false
                }
              >
                {paymentStatusOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Input>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeStatusModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <div  style={{height: 'fit-content'}} className="">
            <div style={{maxHeight: '80vh'}}  className="relative p-3 sm:rounded-lg overflow-auto invoice-table-wrapper">
              {invoices?.docs?.length > 0 ? (
                <>
                  <Table responsive  striped  className="checkbox-input w-100">
                    <thead  style = {{position: 'sticky', top: 0, backgroundColor: '#fff' , zIndex: 1}}className=" text-gray-500   dark:text-gray-400">
                      <tr
                        style={{
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontFamily: "Inter",
                        }}
                        className="text-[#788C9F] border-b "
                      >
                        <th
                          scope="col "
                          className="px-2 py-2 flex items-center"
                          style={{ minWidth: "130px" }}
                        >
                          <CheckBox
                            type="checkbox"
                            ischecked={false}
                            className="no-label"
                          />
                          <label
                            style={{ fontWeight: "500" }}
                            for="default-checkbox"
                            className="ml-2  text-[#788C9F]"
                          >
                            Invoice ID
                          </label>
                        </th>
                        <th
                          scope="col"
                          className="px-2 py-2"
                          style={{ fontWeight: "500", minWidth: "90px" }}
                        >
                          Amount
                        </th>
                        <th
                          scope="col"
                          className="px-2 py-2"
                          style={{ fontWeight: "500", minWidth: "130px" }}
                        >
                          Issued On
                        </th>

                        <th
                          scope="col"
                          className="px-2 py-2"
                          style={{ fontWeight: "500", minWidth: "130px" }}
                        >
                          Date Due
                        </th>

                        <th
                          scope="col"
                          className="px-2 py-2"
                          style={{ fontWeight: "500", minWidth: "100px" }}
                        >
                          Payer
                        </th>
                        <th
                          scope="col"
                          className="px-2 py-2"
                          style={{ fontWeight: "500", minWidth: "90px" }}
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="px-2 py-2"
                          style={{ fontWeight: "500", minWidth: "130px" }}
                        >
                          Payment Method
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {invoices?.docs?.map((data) => {
                        return (
                          <tr className=" ">
                            <td className=" ml-2 py-2">
                              <CheckBox
                                type="checkbox"
                                ischecked={false}
                                className="no-label"
                              />
                              <label
                                for="default-checkbox"
                                className="ml-2 text-sm font-medium text-[#3D3EFD] cursor-pointer"
                                onClick={() => handleOpenDrawerButton(data)}
                              >
                                {data?._id}
                              </label>
                            </td>
                            <td className="px-2 py-2 text-[#282E65]">
                              $
                              {data.totalAmount?.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                              })}
                              {/* {data?.products.map((product, i) => {
                          let total = 0;
                          total = data?.products[i]?.amount + total;
                          return total;
                        })} */}
                            </td>
                            <td className="px-2 py-2 text-[#282E65]">
                              {moment(data?.issued_date)?.format("MM/DD/YYYY")}
                            </td>

                            <td className="px-2 py-2 text-[#282E65]">
                              {moment(data?.due_date)?.format("MM/DD/YYYY")}
                            </td>
                            <td className="px-2 py-2 gap-1 text-[#282E65]">
                              <div className="d-flex flex-row">
                                <img
                                  className="w-[20px] h-[20px] rounded-full"
                                  src={data?.payer_detail?.user_image}
                                  alt="user"
                                />
                                <span className="text-[14px]">
                                  {data?.payer_detail?.firstname +
                                    " " +
                                    data?.payer_detail?.lastname}
                                </span>
                              </div>
                            </td>
                            <td className="px-2 py-2">
                              <select
                                className={`status status-${data?.status?.replace(
                                  /\s+/g,
                                  "-"
                                )}`}
                                value={data?.status}
                                onChange={(e) => {
                                  selectPaymentStatus(
                                    e,
                                    e.target.value,
                                    data?._id
                                  );
                                }}
                              >
                                {paymentStatusOptions.map((option) => (
                                  <option
                                    className={`status status-${option?.replace(
                                      /\s+/g,
                                      "-"
                                    )}`}
                                    key={option}
                                    value={option}
                                  >
                                    {option}
                                  </option>
                                ))}
                              </select>
                              {/*
                      <span className={`status status-${data?.status?.replace(/\s+/g, '-')}`}  onClick={()=>handleopenStatusModal(data)}>
                      {data?.status}
                        </span>*/}
                            </td>
                            <td className="px-2 py-2 ">
                              <p className="flex w-full justify-between text-[#282E65]">
                              {data?.payment_method || '--'}{" "}
                                {permissions?.edit_invoices &&
                                  <span className="flex gap-1 ">
                                    <button onClick={() => history.push(`/invoices/edit-invoice/${data._id}`, { state: { invoice_details: data } })}>
                                      <span className="icomoon-pencil text-[#788C9F]"></span>
                                    </button>
                                    <MdCloudDownload size={23} color="#788C9F" />{" "}
                                    <BsThreeDots size={23} color="#788C9F" />
                                  </span>
                                }
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <PaginationBlock
                    limit={limit}
                    page={page}
                    pagingCounter={invoices?.pagingCounter || 1}
                    docLength={invoices?.docs?.length || 0}
                    docPage={invoices?.page || 1}
                    totalPages={invoices?.totalPages || 1}
                    totalDocs={invoices?.totalDocs || 0}
                    _handleLimit={(val) => { setLimit(val) }}
                    _handlePageChange={setPage}
                  />
                </>

              ) : (
                <NodataFoundBlock type={ filterPayload?.searchText !== "" || 
                filterPayload?.status !== "" || 
                filterPayload?.payment_method !== ""||
                filterPayload?.start_date !==  "" || 
                filterPayload?.end_date !== "" ? 'search result' : ''} />
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div style={{ paddingTop: "10px" }}>
            <TableListEstimateFilterWrapper estimateFilterPayload={estimateFilterPayload} setEstimateFilterPayload={setEstimateFilterPayload} setEstPage={setEstPage} />
          </div>

          <div>
            {drawerOpenEstimate ? (
              // <TotalRevenue
              //   show={drawerOpen}
              //   cancel={drawerOpen}
              //   setCancel={setDrawerOpen}
              // />
              <TotalRevenueEstimate
                show={drawerOpenEstimate}
                cancel={drawerOpenEstimate}
                setCancel={setDrawerOpenEstimate}
                invoiceData={selectedInvoice}
              />
            ) : (
              ""
            )}

        {drawerOpenEstimate && <BackDrop closeDrawer={handleBackdropClick} />}
      </div>
      <div  style={{height: 'fit-content'}} className="">
        <div style={{maxHeight: '80vh'}}  className="relative p-3 sm:rounded-lg overflow-auto invoice-table-wrapper">
          { estimates?.docs?.length > 0 ? (
            <>
            <Table responsive striped className="checkbox-input w-100">
            <thead  style={{position: 'sticky', top: 0, backgroundColor: '#fff' , zIndex: 1, paddingTop: 10}}   className=" text-gray-500   dark:text-gray-400">
              <tr style={{fontSize: '13px', fontStyle: 'normal', fontWeight: '500', fontFamily: 'Inter'}} className="text-[#788C9F] border-b ">
                <th scope="col " className="px-2 py-2 flex items-center" style={{ minWidth: "130px" }}>
                  <CheckBox
                    type="checkbox"
                    ischecked={false}
                    className="no-label"
                  />
                  <label
                    style={{fontWeight: '500'}}
                    for="default-checkbox"
                    className="ml-2  text-[#788C9F]"
                  >
                    Estimate ID
                  </label>
                </th>
                <th scope="col" className="px-2 py-2" style={{fontWeight: '500', minWidth: "90px" }}>
                  Amount
                </th>
                <th scope="col" className="px-2 py-2" style={{fontWeight: '500', minWidth: "130px" }}>
                Processed On                
                </th>
                <th scope="col" className="px-2 py-2" style={{fontWeight: '500', minWidth: "100px" }}>
                  Payer
                </th>
              </tr>
            </thead>
            <tbody style={{fontFamily: 'Inter', fontSize: '14px', fontWeight: '400'}}>
             
               { estimates?.docs?.map((data) => {
                  return (
                    <tr>
                      <td className=" ml-2 py-2">
                        <CheckBox
                          type="checkbox"
                          ischecked={false}
                          className="no-label"
                        />
                        <label
                          for="default-checkbox"
                          className="ml-2 text-sm font-medium text-[#3D3EFD] cursor-pointer"
                          onClick={()=> handleOpenDrawerEstimateButton(data)}
                        >
                          ES{data?._id.substring(5, 12)}
                        </label>
                      </td>
                      <td className="px-2 py-2 text-[#282E65]">
                        ${data.totalAmount?.toLocaleString('en-US', { minimumFractionDigits: 2 })}
                        {/* {data?.products.map((product, i) => {
                          let total = 0;
                          total = data?.products[i]?.amount + total;
                          return total;
                        })} */}
                            </td>
                            <td className="px-2 py-2 text-[#282E65]">
                              {moment(data?.createdAt)?.format(
                                "MM/DD/YYYY [at] hh:mm a"
                              )}
                            </td>
                            <td className="px-2 py-2  gap-1 text-[#282E65]">
                              <div className="d-flex flex-row">
                                <img
                                  className="w-[20px] h-[20px] rounded-full"
                                  src={data?.payer_detail?.user_image}
                                  alt="user"
                                />
                                <span className="text-[14px]">
                                  {data?.payer_detail?.firstname +
                                    " " +
                                    data?.payer_detail?.lastname}
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <PaginationBlock
                    limit={limit}
                    page={estPage}
                    pagingCounter={estimates?.pagingCounter || 1}
                    docLength={estimates?.docs?.length || 0}
                    docPage={estimates?.page || 1}
                    totalPages={estimates?.totalPages || 1}
                    totalDocs={estimates?.totalDocs || 0}
                    _handleLimit={(val) => { setLimit(val) }}
                    _handlePageChange={setEstPage}
                  />
                </>
              ) : (
                <NodataFoundBlock />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
