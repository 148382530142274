import React, { useState } from "react"
import { FormGroup, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, Button, } from 'reactstrap';
const LabReport = () => {
    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);


    return <>
        <div className="form-item-blc d-flex align-items-center flex-fill dropdown_floating">
            <div className="form-group-wrapper d-flex w-100">
                <FormGroup className="input-wrapper2 no-floating flex-fill">
                    <div className="form-floating position-relative text-input">
                        <input disabled type="text" className="form-control" id="reply" placeholder="" value="Publish Lab Results" />
                        <label for="Taxdentificationnumber">Publish Lab Results  </label>
                    </div>
                </FormGroup>
                <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>

                    <DropdownToggle color="link">
                        <i className="icomoon-angle-down"></i>
                    </DropdownToggle>
                    <DropdownMenu >
                        <DropdownItem>
                            Automatically    
                        </DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown>
            </div>
        </div>
    </>
}

export default LabReport