import * as Yup from "yup";
/**
 * @description declaration form schema for client validations
 */
export const loginSchema = Yup.object().shape({
    password: Yup.string()
        .required("Password field is required"),
    username: Yup
        .string()
        .required("Username field  is required"),
    frequency_value: Yup
        .number().
        required('Frequency value is required'),
    frequency_unit: Yup
        .string().
        required('Frequency unit is required'),
    ClinicID: Yup.string(),
    partial_results: Yup.boolean().required(""),
    view_pdf: Yup.boolean().required("")

});

/**
 * @description params intilaization 
 */

export const loginInit = {
    password: '',
    username: '',
    frequency_value: 30,
    frequency_unit: 'seconds',
    ClinicID: '',
    partial_results: true,
    view_pdf: true
}