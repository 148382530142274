import React, { Fragment, useEffect, useState } from "react";
import { Button } from "reactstrap";
import { getNextWeekSpanDays } from "../../../utils/calendar/constant";
import LoaderUI from "../../loader";
import NodataFoundBlock from "../../NoDataFoundBlock";
import UserPlaceholder from "../../UserPlaceholder";
import AddSlotButton from "../AddSlotButton";
import SlotItemName from "../SlotItemName";
import "./index.scss";
import moment from "moment";
import { useSelector } from "react-redux";

export const CalendarWeekViewWithoutDoctors = ({
  isBoarding,
  isOperatingRoom,
  calendarViewData,
  // doctorsList,
  appointments,
}) => {
  const [dates, setDates] = useState([]);
  const [loader, showLoader] = useState(false);

  useEffect(() => {
    showLoader(true);
    const date = getNextWeekSpanDays(
      calendarViewData.span_date,
      calendarViewData.span_month,
      calendarViewData.last_week_span
        ? [calendarViewData.year_value - 1, calendarViewData.year_value]
        : calendarViewData.year_value
    );
    // console.log(date);

    if (date.length) {
      date.forEach((val) => {
        const dateDay = moment(val.full_date, "DD-MM-YYYY").format("dddd");
        val.day = dateDay.toLowerCase();
      });
    }
    setDates(date);
    showLoader(false);
  }, [calendarViewData]);

  var naiveReverse = function (string) {
    console.log("naive", string);
    let str =
      string?.split("-")[2] +
      "-" +
      string?.split("-")[1] +
      "-" +
      string?.split("-")[0];
    // return string.split('').reverse().join('');
    return str;
  };

  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );

  const daysOfWeek = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const startDayIndex = daysOfWeek.indexOf(
    clinic_settings.calendar_start_day.toLowerCase()
  );
  const orderedDaysOfWeek = [
    ...daysOfWeek.slice(startDayIndex),
    ...daysOfWeek.slice(0, startDayIndex),
  ];

  console.log("dates", dates);
  return (
    <>
      {loader && <LoaderUI overlay />}
      {/* <div
                className={`calendar-week-view  ${!doctorsList && doctorsList.length <= 0
                    ? "overflow-hidden"
                    : "overflow-auto"
                    }`}
            > */}

      <div className={`calendar-week-view overflow-hidden`}>
        <table className="table">
          <thead>
            <tr>
              {/* <th className="user-filter-blc-wrapper">
                                <div className="user-filter-blc d-flex align-items-center">
                                    <h3 className="ttl">Doctor Name</h3>
                                    <div className="right-col ms-auto d-inline-flex align-items-center">
                                        <Button color="link" className="d-inline-flex">
                                            <span className="material-symbols-outlined">
                                                filter_list
                                            </span>
                                        </Button>
                                        <Button color="link" className="d-inline-flex">
                                            <span className="material-symbols-outlined">sync</span>
                                        </Button>
                                    </div>
                                </div>
                            </th> */}
              {/* {dates.map((date, i) => (
                                <th className="day-cell-hd text-center cell-width" key={i}>
                                    {date?.day}
                                    <span className="d-block">{date?.date}</span>
                                </th>
                            ))} */}

              {orderedDaysOfWeek.map((day, i) => {
                const foundDate = dates?.find((dat) => dat.day === day);
                const formattedDay = foundDate
                  ? moment(foundDate.date, "DD-MM-YYYY").format("ddd")
                  : "";
                return (
                  <th className="day-cell-hd text-center cell-width" key={i}>
                    {formattedDay.toUpperCase()}
                    <span className="d-block">{foundDate?.date}</span>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {
              // doctorsList && doctorsList.length > 0 ? (
              //     doctorsList.map((doctor, i) => (
              <tr>
                {/* <th className="user-pic-cell-wrapper">
                                        <div className="user-pic-cell d-inline-flex align-items-center">
                                            <div className="pic rounded-circle overflow-hidden">
                                                {doctor?.user ? (
                                                    doctor?.user?.user_image ? (
                                                        <img
                                                            className="img-fluid w-100 h-100"
                                                            src={doctor?.user?.user_image}
                                                            alt="User"
                                                        />
                                                    ) : (
                                                        <UserPlaceholder />
                                                    )
                                                ) : doctor?.pet_image ? (
                                                    <img
                                                        className="img-fluid w-100 h-100"
                                                        src={doctor?.pet_image}
                                                        alt="User"
                                                    />
                                                ) : (
                                                    <UserPlaceholder />
                                                )}
                                            </div>
                                            <div className="txt">
                                                {doctor?.user ? (
                                                    <>
                                                        <h4>
                                                            {doctor?.user?.title} {doctor?.user?.firstname}{" "}
                                                            {doctor?.user?.lastname}
                                                        </h4>
                                                        <h5>
                                                            {doctor?.appointments?.length > 1
                                                                ? doctor?.appointments?.length +
                                                                " Appointments"
                                                                : doctor?.appointments?.length +
                                                                " Appointment"}
                                                        </h5>
                                                    </>
                                                ) : (
                                                    <h4>{doctor?.pet_name}</h4>
                                                )}
                                            </div>
                                        </div>
                                    </th> */}
                {dates.map((date, i2) => (
                  <Fragment key={i2}>
                    {appointments &&
                    appointments?.filter(
                      (data) => data.appointment_date === date?.full_date
                    ).length > 0 ? (
                      <td className="no-hover">
                        <div className="data-container week-vw">
                          {appointments
                            .sort((a, b) => {
                              return (
                                moment(
                                  a?.appointment_timings?.sort(
                                    (a, b) =>
                                      moment(
                                        `1970-01-01 ${a.slot_time}`,
                                        `YYYY-MM-DD hh:mm a`
                                      ).valueOf() -
                                      moment(
                                        `1970-01-01 ${b.slot_time}`,
                                        "YYYY-MM-DD hh:mm a"
                                      ).valueOf()
                                  )?.[0]?.slot_time,
                                  "hh:mm a"
                                ).valueOf() -
                                moment(
                                  b?.appointment_timings?.sort(
                                    (a, b) =>
                                      moment(
                                        `1970-01-01 ${a.slot_time}`,
                                        `YYYY-MM-DD hh:mm a`
                                      ).valueOf() -
                                      moment(
                                        `1970-01-01 ${b.slot_time}`,
                                        "YYYY-MM-DD hh:mm a"
                                      ).valueOf()
                                  )?.[0]?.slot_time,
                                  "hh:mm a"
                                ).valueOf()
                              );
                            })
                            .map((appointment, apptIndex) => (
                              // console.log("aap",naiveReverse(appointment.boarding_date)==(date?.full_date))

                              <Fragment key={apptIndex}>
                                {appointment.appointment_date ===
                                  date?.full_date && (
                                  <SlotItemName appointmentData={appointment} />
                                )}
                              </Fragment>
                            ))}
                        </div>
                      </td>
                    ) : (
                      <td>
                        <div className="data-container blank-slot add-slot-btn-wrapper d-flex align-items-center justify-content-center position-relative">
                          <AddSlotButton />
                        </div>
                      </td>
                    )}
                  </Fragment>
                ))}
                {/* <td>
										<div className="data-container blank-slot add-slot-btn-wrapper d-flex align-items-center justify-content-center position-absolute">
											<AddSlotButton />
										</div>
									</td>
									<td className="no-hover">
										<div className="data-container">
											<SlotItemTime />
											<SlotItemTime className="clinic" />
											<SlotItemTime className="phone" />
											<SlotItemTime className="video-conference" />
											<SlotItemName />
											<ViewMoreSlot />
										</div>
									</td>
									<td className="no-hover">
										<div className="data-container blank-slot d-flex align-items-center justify-content-center position-absolute">
											<SlotUnavailable />
										</div>
									</td>
									<td className="no-hover">
										<div className="data-container blank-slot d-flex align-items-center justify-content-center position-absolute">
											<SlotUnavailable />
										</div>
									</td>
									<td className="no-hover">
										<div className="data-container blank-slot d-flex align-items-center justify-content-center position-absolute">
											<SlotUnavailable />
										</div>
									</td>
									<td className="no-hover">
										<div className="data-container blank-slot d-flex align-items-center justify-content-center position-absolute">
											<SlotUnavailable />
										</div>
									</td>
									<td className="no-hover">
										<div className="data-container blank-slot d-flex align-items-center justify-content-center position-absolute">
											<SlotUnavailable />
										</div>
									</td> */}
              </tr>
              //     ))
              // ) : (
              //     <tr className="calendar-no-data-rw">
              //         <td colSpan="8">
              //             <NodataFoundBlock message="No appointment found of these dates" />
              //         </td>
              //     </tr>
              // )
            }
          </tbody>
        </table>
      </div>
    </>
  );
};

export const CalendarWeekViewBoarding = ({
  calendarViewData,
  kennelData,
  allKennels,
}) => {
  const [dates, setDates] = useState([]);
  const [loader, showLoader] = useState(false);

  useEffect(() => {
    showLoader(true);
    const date = getNextWeekSpanDays(
      calendarViewData.span_date,
      calendarViewData.span_month,
      calendarViewData.last_week_span
        ? [calendarViewData.year_value - 1, calendarViewData.year_value]
        : calendarViewData.year_value
    );

    if (date.length) {
      date.forEach((val) => {
        const dateDay = moment(val.full_date, "DD-MM-YYYY").format("dddd");
        val.day = dateDay.toLowerCase();
      });
    }
    // console.log(date);
    setDates(date);
    showLoader(false);
  }, [calendarViewData]);

  var naiveReverse = function (string) {
    let str =
      string?.split("-")[2] +
      "-" +
      string?.split("-")[1] +
      "-" +
      string?.split("-")[0];
    return str;
  };

  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );

  const daysOfWeek = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const startDayIndex = daysOfWeek.indexOf(
    clinic_settings.calendar_start_day.toLowerCase()
  );
  const orderedDaysOfWeek = [
    ...daysOfWeek.slice(startDayIndex),
    ...daysOfWeek.slice(0, startDayIndex),
  ];

  console.log("dates", dates);
  return (
    <>
      {loader && <LoaderUI overlay />}

      <div
        className={`calendar-week-view  ${
          !kennelData && kennelData.length <= 0
            ? "overflow-hidden"
            : "overflow-auto"
        }`}
      >
        <table className="table">
          <thead>
            <tr>
              <th className="user-filter-blc-wrapper">
                <div className="user-filter-blc d-flex align-items-center">
                  <h3 className="ttl">Kennels</h3>
                  <div className="right-col ms-auto d-inline-flex align-items-center">
                    <Button color="link" className="d-inline-flex">
                      <span className="material-symbols-outlined">
                        filter_list
                      </span>
                    </Button>
                    <Button color="link" className="d-inline-flex">
                      <span className="material-symbols-outlined">sync</span>
                    </Button>
                  </div>
                </div>
              </th>
              {/* {dates.map((date, i) => (
                                <th className="day-cell-hd text-center cell-width" key={i}>
                                    {date?.day}
                                    <span className="d-block">{date?.date}</span>
                                </th>
                            ))} */}

              {orderedDaysOfWeek.map((day, i) => {
                const foundDate = dates?.find((dat) => dat.day === day);
                const formattedDay = foundDate
                  ? moment(foundDate.date, "DD-MM-YYYY").format("ddd")
                  : "";
                return (
                  <th className="day-cell-hd text-center cell-width" key={i}>
                    {formattedDay.toUpperCase()}
                    <span className="d-block">{foundDate?.date}</span>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {allKennels &&
              allKennels?.map((currentKennel, index) => {
                const kennelAppointments = kennelData?.filter(
                  (appointment) =>
                    appointment?.kennel?.kennel_name ===
                    currentKennel?.kennel_name
                );

                return (
                  <tr key={index}>
                    <th className="user-pic-cell-wrapper">
                      <div className="ml-3 user-pic-cell d-inline-flex align-items-center">
                        <div className="pic rounded-circle overflow-hidden">
                          <UserPlaceholder />
                        </div>
                        <div className="txt ">
                          <h4>{currentKennel?.kennel_name}</h4>
                        </div>
                      </div>
                    </th>
                    {dates.map((date, i2) => {
                      const kennelAppointmentsForDate =
                        kennelAppointments.filter(
                          (appointment) =>
                            naiveReverse(appointment?.boarding_date) ===
                            date?.full_date
                        );

                      return (
                        <td key={i2}>
                          {kennelAppointmentsForDate.length > 0 ? (
                            <div className="data-container">
                              {kennelAppointmentsForDate.map(
                                (appointment, apptIndex) => (
                                  <SlotItemName
                                    key={apptIndex}
                                    appointmentData={appointment}
                                  />
                                )
                              )}
                            </div>
                          ) : (
                            <div className="data-container blank-slot add-slot-btn-wrapper d-flex align-items-center justify-content-center position-relative">
                              <AddSlotButton />
                            </div>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            {!kennelData ||
              (kennelData?.length === 0 && (
                <tr className="calendar-no-data-rw">
                  <td colSpan="8">
                    <NodataFoundBlock message="No appointment found of these dates" />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export const CalendarWeekViewOperating = ({
  calendarViewData,
  surgeryData,
  allRooms,
}) => {
  const [dates, setDates] = useState([]);
  const [loader, showLoader] = useState(false);

  useEffect(() => {
    showLoader(true);
    const date = getNextWeekSpanDays(
      calendarViewData.span_date,
      calendarViewData.span_month,
      calendarViewData.last_week_span
        ? [calendarViewData.year_value - 1, calendarViewData.year_value]
        : calendarViewData.year_value
    );
    setDates(date);
    showLoader(false);
  }, [calendarViewData]);

  var naiveReverse = function (string) {
    console.log("naive", string);
    let str =
      string?.split("-")[2] +
      "-" +
      string?.split("-")[1] +
      "-" +
      string?.split("-")[0];
    return str;
  };

  return (
    <>
      {loader && <LoaderUI overlay />}

      <div
        className={`calendar-week-view  ${
          !surgeryData && surgeryData.length <= 0
            ? "overflow-hidden"
            : "overflow-auto"
        }`}
      >
        <table className="table">
          <thead>
            <tr>
              <th className="user-filter-blc-wrapper">
                <div className="user-filter-blc d-flex align-items-center">
                  <h3 className="ttl">Operating Rooms</h3>
                  <div className="right-col ms-auto d-inline-flex align-items-center">
                    <Button color="link" className="d-inline-flex">
                      <span className="material-symbols-outlined">
                        filter_list
                      </span>
                    </Button>
                    <Button color="link" className="d-inline-flex">
                      <span className="material-symbols-outlined">sync</span>
                    </Button>
                  </div>
                </div>
              </th>
              {dates.map((date, i) => (
                <th className="day-cell-hd text-center cell-width" key={i}>
                  {date?.day}
                  <span className="d-block">{date?.date}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {allRooms &&
              allRooms?.map((currenRoom, index) => {
                const kennelAppointments = surgeryData?.filter(
                  (appointment) =>
                    appointment?.operating_room_id?.name === currenRoom?.name
                );

                return (
                  <tr key={index}>
                    <th className="user-pic-cell-wrapper">
                      <div className="ml-3 user-pic-cell d-inline-flex align-items-center">
                        <div className="pic rounded-circle overflow-hidden">
                          <UserPlaceholder />
                        </div>
                        <div className="txt ">
                          <h4>{currenRoom?.name}</h4>
                        </div>
                      </div>
                    </th>
                    {dates.map((date, i2) => {
                      const kennelAppointmentsForDate =
                        kennelAppointments.filter(
                          (appointment) =>
                            naiveReverse(appointment?.surgery_date) ===
                            date?.full_date
                        );

                      return (
                        <td key={i2}>
                          {kennelAppointmentsForDate.length > 0 ? (
                            <div className="data-container">
                              {kennelAppointmentsForDate.map(
                                (appointment, apptIndex) => (
                                  <SlotItemName
                                    key={apptIndex}
                                    appointmentData={appointment}
                                  />
                                )
                              )}
                            </div>
                          ) : (
                            <div className="data-container blank-slot add-slot-btn-wrapper d-flex align-items-center justify-content-center position-relative">
                              <AddSlotButton />
                            </div>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            {!surgeryData ||
              (surgeryData?.length === 0 && (
                <tr className="calendar-no-data-rw">
                  <td colSpan="8">
                    <NodataFoundBlock message="No appointment found of these dates" />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
