import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Table,
} from "reactstrap";
import { sidebarPanelAction } from "../../context/actions/sidebarPanelAction";
import PageHeader from "../../shared/PageHeader";
import Referrals from "./Referrals";
import { patientListPanelAction } from "../../context/actions/patientListPanelAction";
import PatientListPanel from "../../shared/Referrals/PatientListPanel";
import Disabled from "../../shared/Disabled";
import {
  getOutgoingReferralsByClinicId,
  getIncomingReferralsByClinicId,
  getClinicListByClinicId,
  getIncomingClinicListClinicId,
} from "../../context/actions/referral";

import "./index.scss";

const ReferralManagement = () => {
  const dispatch = useDispatch();
  const sidebarPanelState = useSelector(
    (state) => state.sidebarPanelReducer.isSidePanel
  );
  const patientListPanelState = useSelector(
    (state) => state.patientListPanelReducer.isPatientListPanel
  );
  const { incomingReferrals, outgoingReferrals } = useSelector(
    (state) => state.referralReducer
  );
  const [clinicList, setClinicList] = useState([]);
  const [incomingClinicList, setIncomingClinicList] = useState([]);


  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filterPayload, setFilterPayload] = useState({
    searchText: "",
    status: "",
    clinicId: "",
    start_date: "",
    end_date: ""
  });
  const [incomingfilterPayload, setIncomingFilterPayload] = useState({
    searchText: "",
    status: "",
    clinicId: "",
    start_date: "",
    end_date: ""
  });


  const userDetails = useSelector((state) => state.user.user_details);
  const { clinic_details } = useSelector((state) => state.clinicReducer);

  useEffect(() => {
    handleGetReferrals();
  }, [page, limit, filterPayload, incomingfilterPayload]);

  const showSidePanel = () => {
    dispatch(sidebarPanelAction(!sidebarPanelState));
  };

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const openPatientPanel = () => {
    dispatch(patientListPanelAction(!patientListPanelState));
  };

  const handleGetReferrals = () => {
    console.log('handle get referrals getting called a lot')
    const queryParams = new URLSearchParams();
    queryParams.append('limit', limit);
    queryParams.append('page', page);
    setLoading(true);

    if (activeTab && activeTab === "1") {
      dispatch(
        getOutgoingReferralsByClinicId(queryParams, filterPayload, () => {
          //setReferrals(outgoingReferrals)

          setLoading(false);
        })
      );
    } else if (activeTab && activeTab === "2") {
      dispatch(
        getIncomingReferralsByClinicId({ page, limit, ...incomingfilterPayload }, () => {
          //setReferrals(incomingReferrals);
          setLoading(false);
        })
      );
    }
  };

  // useEffect(() => {
  //   console.log('filter payload')
  //   handleGetReferrals();
  // }, [filterPayload]);

  // useEffect(() => {
  //   console.log('filter payload, incoming')
  //   handleGetReferrals();
  // }, [ incomingfilterPayload]);

  useEffect(() => {
    console.log('active Tab')
    if (activeTab && activeTab === "1") {
      setIncomingFilterPayload({
        searchText: "",
        status: "",
        clinicId: "",
        start_date: "",
        end_date: ""
      });
    } else if (activeTab && activeTab === "2") {
      setFilterPayload({
        searchText: "",
        status: "",
        clinicId: "",
        start_date: "",
        end_date: ""
      });
    }
    //.handleGetReferrals();
  }, [activeTab]);
  useEffect(() => {
    console.log('init use effect called')
    dispatch(getClinicListByClinicId((res) => {
      if (res?.status) {
        // Create a new Set to store unique clinic_ids
        const uniqueClinicIds = new Set();
        const newClinicSet = new Set();

        res?.response_data?.forEach(clinic => {
          if (!uniqueClinicIds.has(clinic.clinic_id)) {
            uniqueClinicIds.add(clinic.clinic_id);
            newClinicSet.add(clinic);
          }
        });

        setClinicList(Array.from(newClinicSet));
      }
    }))
    dispatch(getIncomingClinicListClinicId((res) => {
      if (res?.status) {
        // Create a new Set to store unique clinic_ids
        const uniqueIncomingClinicIds = new Set();
        const newIncomingClinicSet = new Set();

        res?.response_data?.forEach(clinic => {
          if (!uniqueIncomingClinicIds.has(clinic.clinic_id)) {
            uniqueIncomingClinicIds.add(clinic.clinic_id);
            newIncomingClinicSet.add(clinic);
          }
        });

        setIncomingClinicList(Array.from(newIncomingClinicSet));
      }
    }))
  }, []);
  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  return (
    <>
      <div className="page-wrapper position-relative d-flex flex-column flex-fill overflow-auto">
        <div className="page-header-wrapper d-flex align-items-center flex-shrink-0 right-column-visible">
          <Button
            className="sidebar-toggle-btn d-inline-flex align-items-center"
            color="link"
            onClick={showSidePanel}
          >
            <span className="material-symbols-outlined">menu</span>
          </Button>

          <div className="row-block d-flex align-items-center flex-fill">
            <PageHeader
              className="dashboard-head flex-flex-shrink-0"
              title="Referral Managment"
            ></PageHeader>
            {permissions?.edit_referral_management && (
              <div className="right-col ms-auto">
                <div className="btn-blc">
                  {activeTab === "1" && (
                    <Button
                      onClick={openPatientPanel}
                      color="success"
                      className="add-btn py-2 h-auto"
                    >
                      ADD NEW
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="page-content d-flex flex-column flex-fill overflow-auto">
          {
            <div className="tab-wrapper tab-wrapper-inventory  d-flex flex-column flex-fill overflow-auto">
              <Nav tabs className="flex-shrink-0">
                <NavItem>
                  <NavLink
                    className={{ active: activeTab === "1" }}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Outgoing
                  </NavLink>
                  <NavLink
                    className={{ active: activeTab === "2" }}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Incoming
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent
                activeTab={activeTab}
                className="flex-fill overflow-auto"
              >
                <TabPane tabId="1">
                  <Referrals
                    refresh={handleGetReferrals}
                    loading={loading}
                    page={page}
                    limit={limit}
                    setPage={setPage}
                    setLimit={setLimit}
                    referrals={outgoingReferrals}
                    type={"outgoing"}
                    filterPayload={filterPayload}
                    setFilterPayload={setFilterPayload}
                    clinicList={clinicList}
                  />
                </TabPane>
                <TabPane tabId="2">
                  <Referrals
                    refresh={handleGetReferrals}
                    loading={loading}
                    page={page}
                    limit={limit}
                    setPage={setPage}
                    setLimit={setLimit}
                    referrals={incomingReferrals}
                    type={"incoming"}
                    incomingfilterPayload={incomingfilterPayload}
                    setIncomingFilterPayload={setIncomingFilterPayload}
                    incomingClinicList={incomingClinicList}
                  />
                </TabPane>
              </TabContent>
            </div>
          }
        </div>
      </div>
      <div
        className={`patient-panel-overlay position-fixed ${patientListPanelState ? "active" : ""
          }`}
        onClick={() => dispatch(patientListPanelAction(false))}
      ></div>
      <PatientListPanel
        refresh={handleGetReferrals}
        className={`${patientListPanelState ? "active" : ""}`}
        clinicId={clinic_details?._id}
      />
    </>
  );
};

export default ReferralManagement;
