import React from "react";
import { Button } from "reactstrap";
import { capitalizeFirstLetter } from "../../utils/common";
import PlaceholderInitial from "../PlaceholderInitial";
import UserPlaceholder from "../UserPlaceholder";
const UserProfileHead = ({ userGeneralInfo }) => {

	return (
		<>
			<div className="user-profile-head d-flex">
				<div className="left-col">
					<div className="user-profile-pic d-flex align-items-center">
						<div className="pic d-inline-flex rounded-circle overflow-hidden">
							{
								userGeneralInfo?.user_image ? <img className="img-fluid w-100 h-100" src={userGeneralInfo?.user_image} alt="User" /> : <UserPlaceholder />
							}
						</div>
						<div className="info">
							<h3>{userGeneralInfo?.firstname} {userGeneralInfo?.lastname}</h3>
							<div className="feature">
								<label className="item-dot d-inline-flex align-items-center">{userGeneralInfo?.speciality}</label>
								{/* <label>Admin ,</label> */}
								<label>{userGeneralInfo?.role && capitalizeFirstLetter(userGeneralInfo?.role)}</label>
							</div>
							<p className={`${userGeneralInfo?.active ? 'text-success' : 'text-danger'}`}>{userGeneralInfo?.active ? 'Active' : 'Inactive'}</p>
						</div>
					</div>
				</div>
				<div className="right-col ms-auto">
					<Button color="link" className="share-btn p-0 d-inline-flex align-items-center justify-content-center">
						<i className="icomoon-share p-0"></i>
					</Button>
				</div>
			</div>
		</>
	);
};

export default UserProfileHead;
