import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
// import { getMedicationPlanByPetId } from "../../../../../../context/actions/patient-module/overview/medication-plan";
// import Spinner from "../../../../../../shared/Spinner";
// import UserPlaceholder from "../../../../../../shared/UserPlaceholder";
import "./index.scss";
import MedicationPlanItem from "./MedicationPlanItem";
import MedicationPlanFormPanel from "./MedicationPlanFormPanel";
import { getHomeMedicationOrders } from "../../../../../context/actions/patient-module/orders";

const MedicationPlanBlock = ({ petId }) => {
  const { homeMedicationOrders } = useSelector((state) => state.orderReducer);
  const [isMore, setIsMore] = useState(false);
  const dispatch = useDispatch();

  const [isNewMedicationPlan, setNewMedicationPlan] = useState(false);

  useEffect(() => {
    if (petId) {
      dispatch(getHomeMedicationOrders({ pet_id: petId }, () => {}));
    }
  }, [petId]);

  return (
    <>
      <div className="medication-plan-block">
        <div className="head section-head d-flex align-items-center">
          <div className="left-col">
            <h3>Medication Plans</h3>
          </div>
          {/* <div className="right-col ms-auto d-inline-flex align-items-center">
            <Button
              color="link"
              className="add-btn"
              onClick={() => setNewMedicationPlan(true)}
            >
              <i className="icomoon-plus-btn"></i>
            </Button>
          </div> */}
        </div>
        <div className="inner">
          <div className="plan-items-rw d-flex flex-wrap">
            {homeMedicationOrders &&
              homeMedicationOrders.map((order, index) => {
                return (
                  <div key={order._id} className="item-col mb-2">
                    <MedicationPlanItem item={order} />
                  </div>
                );
              })}

            {/* <div className="item-col mb-2">
							<MedicationPlanItem />
						</div>
						<div className="item-col mb-2">
							<MedicationPlanItem />
						</div> */}

            {/* <div className="item-col more_link d-flex">
              <Button color="link" className="more-item-btn">
                +10 More
              </Button>
            </div> */}
          </div>

          {/* {loading ? <Spinner height={150} /> : <div className="plan-items-rw d-flex flex-wrap">
						{(planList || []).map((item, index) => {
							if (!isMore && index < 3) {
								return <div key={index} className="item-col">
									<MedicationPlanItem item={item} />
								</div>
							}
							else if (isMore) {
								return <div key={index} className="item-col">
									<MedicationPlanItem item={item} />
								</div>
							}
						})}
						<div className="item-col d-flex">
							<Button onClick={() => setIsMore(!isMore)} color="link" className="more-item-btn">
								{isMore ? 'Less' : planList?.length > 3 ? '+' + (planList?.length - 3).toString() + ' More' : ''}
							</Button>
						</div>
					</div>} */}

          {/* medical Plan modal backdrop start */}
          {isNewMedicationPlan && (
            <>
              <div
                className={` fixed top-0 right-0 bottom-0 left-0 z-[9] bg-black/20 transition-all duration-300 ease-in `}
              ></div>
            </>
          )}
          {/* medical Plan modal backdrop end */}

          {isNewMedicationPlan && (
            <MedicationPlanFormPanel
              isActive={isNewMedicationPlan}
              toggleMedicationPanel={() => {
                setNewMedicationPlan(false);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default MedicationPlanBlock;
