import React, { useState } from "react";
import { Button, InputGroup, Input, FormGroup, FormFeedback } from "reactstrap";
import TextInput from "../FormsInput/FormInput"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import "./index.scss";
import { useEffect } from "react";
const AddressAutoCompleteInput = ({
  onSelect,
  value,
  className,
  placeholder = "Head Office Address Information",
  error, touched
}) => {
  const [address, setAddress] = useState();
  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setAddress(selectedAddress);
      onSelect({ address: selectedAddress, postal: results?.[0]?.address_components?.find(i => i?.types?.includes('postal_code'))?.long_name, ...latLng });
    } catch (error) {
      console.error("Error selecting address", error);
    }
  };
  useEffect(() => {
    if (value === "") setAddress(undefined);
  }, [value]);
  return (
    <div className={className}>
    <PlacesAutocomplete
      className={className}
      value={address}
      onChange={(newAddress) => {
        setAddress(newAddress)}}
      onSelect={handleSelect}
      searchOptions={{types: ['street_address']}}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="position-relative">
          <div className="form-group-wrapper mb-0 input-wrapper d-flex">

            <FormGroup className="w-100">

            <InputGroup className="d-flex flex-fill">
                <Input
                  type="text"
                  id="address"
                  name="address"
                  placeholder="Enter First Name"
                  {...getInputProps({
                    placeholder: "Enter your address...",
                    className: "location-search-input ",
                  })}
                  value={address === undefined ? value : address}
                />
            <Button
              color="link"
              className="d-inline-flex align-items-center justify-content-center"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <i className="icomoon-location-current"></i>
            </Button>
            </InputGroup>
              {touched && error && (
            <FormFeedback className="d-block"> {error}
                {/* <FormattedMessage id={meta.error} values={{
              name: props?.placeholder || 'This'
            }} /> */}
            </FormFeedback>
          ) }
            </FormGroup>

          </div>
          {loading || suggestions.length ? (
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => (
                <div
                  className="pointer"
                  {...getSuggestionItemProps(suggestion)}
                  key={suggestion.placeId}
                >
                  {suggestion.description}
                </div>
              ))}
            </div>
          ) : null}
          </div>
      )}
    </PlacesAutocomplete>
    </div>
  );
};

export default AddressAutoCompleteInput;
