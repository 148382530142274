import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../../../../api-client";
import { apiUrl, PORT } from "../../../../../environment";
import { toasterConfig } from "../../../../../utils/constants";

toast.configure();
/**
 * All Api endpoints to be call
 */

export const getIVsByPetId = (payload) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  if (token) {
    return await ApiClient.post(
      `${apiUrl}${PORT}/iv-assess/get-all`,
      payload,
      token,
      dispatch, getState
    );
  }
};

export const addNewIV = (payload) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();

  if (token) {
    if (payload?.coordinate) {
      const response = await ApiClient.post(
        `${apiUrl}${PORT}/iv-assess/add`,
        payload,
        token,
        dispatch, getState
      );
      if (response?.message) {
        if (response?.status) {
          // toast.success(response.message, toasterConfig);
        } else {
          toast.error(response.message, toasterConfig);
        }
      }
      return response;
    } else {
      toast.error("Please select a point on the model", toasterConfig);
    }
  } else {
    return {};
  }
};

export const addIVDetails = (payload) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();

  if (token) {
    const response = await ApiClient._postFormData(
      `${apiUrl}${PORT}/iv-assess/add-location-details`,
      payload,
      token,
      dispatch, getState
    );
    if (response?.message) {
      if (response?.status) {
        toast.success(response.message, toasterConfig);
      } else {
        toast.error(response.message, toasterConfig);
      }
    }
    return response;
  } else {
    return {};
  }
};

export const deleteParentIV = (id) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  if (token) {
    const response = await ApiClient.post(
      `${apiUrl}${PORT}/iv-assess/remove-pin-location/${id}`, {}, token, dispatch, getState
    );
    if (response?.message) {
      if (response?.status) {
        toast.success(response.message, toasterConfig);
      } else {
        toast.error(response.message, toasterConfig);
      }
    }
    return response;
  } else {
    return {};
  }
};

export const deleteIVDetails = (payload) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();

  if (token) {
    const response = await ApiClient.post(
      `${apiUrl}${PORT}/iv-assess/remove-location-details`,
      payload,
      token,
      dispatch, getState
    );
    if (response?.message) {
      if (response?.status) {
        toast.success(response.message, toasterConfig);
      } else {
        toast.error(response.message, toasterConfig);
      }
    }
    return response;
  } else {
    return {};
  }
};
