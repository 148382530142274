import React from "react";
import UserPlaceholder from "../../../../../../../shared/UserPlaceholder";
import { getTimeAgo } from "../../../../../../../utils/constants";
import "./index.scss";

const StoryListItem2 = ({ className = '', patientStory }) => {

	return (
		<>
			<div className={`story-list-item2 d-flex ${!patientStory?.is_doctor_recommendation && 'inactive'} ${className}`}>
				<div className="left-col">
					<div className="pic-cell text-center">
						<div className="pic d-inline-flex rounded-circle overflow-hidden">
							{
								patientStory?.clinic_id?.user_image ? <img className="img-fluid" src={patientStory?.clinic_id?.user_image} alt="" /> : <UserPlaceholder />
							}

						</div>
						<div className="txt">
							<h4>Timothy William</h4>
							{
								patientStory?.tag === 'video-call' ? 'Video Call' :
									patientStory?.tag === 'video-conference' ? 'Video Conference' :
										patientStory?.tag === 'phone-call' ? 'Phone Call' : 'In Clinic'
							}
						</div>
					</div>
				</div>
				<div className="right-col w-100">
					<div className="story-info-blc">
						<div className="title d-flex align-items-center">
							{
								patientStory?.is_doctor_recommendation ? <div className="left-col d-inline-flex align-items-start">
									<span className="icomoon-thumb-up text-success"></span>
									I recommend the doctor
								</div> : <div className="left-col d-inline-flex align-items-start">
									<span className="icomoon-thumb-up text-danger down"></span>
									I do not recommend the doctor
								</div>
							}
							<div className="right-col flex-shrink-0 ms-auto">
								<span className="time">{getTimeAgo(patientStory?.createdAt)}</span>
							</div>
						</div>
						<div className="sub-ttl d-flex">
							<span>Happy with :</span>
							{
								patientStory?.reason?.length > 0 && <div className="tags d-flex flex-wrap align-items-center">
									{
										patientStory?.reason?.map((reason, index) => (
											<span key={index}>{reason}</span>
										))
									}
								</div>
							}

						</div>
						<div className="info-blc">
							<p>{patientStory?.comment}</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default StoryListItem2;
