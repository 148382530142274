import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { sidebarPanelAction } from "../../../../../context/actions/sidebarPanelAction";
import BackButton from "../../../../../shared/BackButton";
import UserThumbnailList from "./UserThumbnailList";
import "./index.scss";

const UserThumbnailListWrapper = ({ staffOtherMembers, className = "" }) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const sidebarPanelState = useSelector(
    (state) => state.sidebarPanelReducer.isSidePanel
  );
  const showSidePanel = () => {
    dispatch(sidebarPanelAction(!sidebarPanelState));
  };

  return (
    <>
      <div
        className={`user-thumbnail-list-wrapper d-flex align-items-center overflow-auto flex-shrink-0 
			${className}`}
      >
        <div className="left-col d-inline-flex align-items-center">
          <Button
            className="sidebar-toggle-btn staff-detail d-inline-flex align-items-center"
            color="link"
            onClick={showSidePanel}
          >
            <span className="material-symbols-outlined">menu</span>
          </Button>
          <BackButton
            label="Back to List"
            onClick={() => history.push("/staff-management")}
          />
        </div>
        <UserThumbnailList staffOtherMembers={staffOtherMembers} />
      </div>
    </>
  );
};

export default UserThumbnailListWrapper;
