import React from "react";
import { useSelector } from "react-redux";
export const useClinicSpecies = () => {

    const {clinicSpecies }  = useSelector(state => state?.clinicSettingsReducer);

    const findClinicSpecies = (species_id) => {
    if(!clinicSpecies || clinicSpecies?.length <= 0){
        return false

    } else {
        if(clinicSpecies?.filter(i => i?.species_id === species_id)?.length > 0){
            return clinicSpecies?.filter(i => i?.species_id === species_id)?.[0]?.name
        } else {
            return false
        }
    }
    }
    return findClinicSpecies
}


export const useClinicSpeciesDetails = () => {

    const {clinicSpecies }  = useSelector(state => state?.clinicSettingsReducer);

    const findClinicSpecies = (species_id) => {
    if(!clinicSpecies || clinicSpecies?.length <= 0){
        return false

    } else {
        if(clinicSpecies?.filter(i => i?.species_id === species_id)?.length > 0){
            return clinicSpecies?.filter(i => i?.species_id === species_id)?.[0]
        } else {
            return false
        }
    }
    }
    return findClinicSpecies
}

