import React from "react";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import UserPlaceholder from "../../../../../../shared/UserPlaceholder";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const UserThumbnailList = ({ staffOtherMembers }) => {
  let { staffManagementId } = useParams();
  const history = useHistory();

  const handleStaffMemberRedirection = (staffManagementId) => {
    history.push(`/staff-management/staff-details/${staffManagementId}`);
  };

  return (
    <>
      <div className="user-thumbnail-list d-flex align-items-center overflow-auto">
        {staffOtherMembers &&
          staffOtherMembers.map((staffMember, index) => {
            let isActive = staffManagementId === staffMember?.id;

            return (
              <Button
                color="link"
                className={`${
                  isActive ? "b-3EFD" : "b-e3e3"
                } p-0 rounded-circle overflow-hidden`}
                key={index}
                onClick={() => handleStaffMemberRedirection(staffMember?._id)}
              >
                {staffMember?.user?.user_image ? (
                  <img
                    className="img-fluid"
                    src={staffMember?.user?.user_image}
                    alt=""
                  />
                ) : (
                  <UserPlaceholder />
                )}
              </Button>
            );
          })}

        {/*
				<Button color="link" className="p-0 rounded-circle overflow-hidden">
					<img className="img-fluid" src={require('../../../../../../assets/img/vetRecorEMR/user-thumbnail-sm.png')} alt="" />
				</Button>
				<Button color="link" className="p-0 rounded-circle overflow-hidden active">
					<img className="img-fluid" src={require('../../../../../../assets/img/vetRecorEMR/user-thumbnail-sm.png')} alt="" />
				</Button>
				<Button color="link" className="p-0 rounded-circle overflow-hidden">
					<img className="img-fluid" src={require('../../../../../../assets/img/vetRecorEMR/user-thumbnail-sm.png')} alt="" />
				</Button>
				<Button color="link" className="p-0 rounded-circle overflow-hidden">
					<img className="img-fluid" src={require('../../../../../../assets/img/vetRecorEMR/user-thumbnail-sm.png')} alt="" />
				</Button>
				<Button color="link" className="p-0 rounded-circle overflow-hidden">
					<img className="img-fluid" src={require('../../../../../../assets/img/vetRecorEMR/user-thumbnail-sm.png')} alt="" />
				</Button>
				<Button color="link" className="p-0 rounded-circle overflow-hidden">
					<img className="img-fluid" src={require('../../../../../../assets/img/vetRecorEMR/user-thumbnail-sm.png')} alt="" />
				</Button>*/}
      </div>
    </>
  );
};

export default UserThumbnailList;
