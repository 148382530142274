
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../../../../api-client";
import { apiUrl, PORT } from "../../../../../environment";
import { toasterConfig } from "../../../../../utils/constants";

toast.configure();
/**
 * All Api endpoints to be call
*/

/**
 * get all vaccination dues details
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns 
 */
export const getIntakeOutputResultsByPetId= (petId, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, } = getState();
        if (petId) {
            dispatch({ type: "IO_RESULTS_LOADING", payload: true })
            ApiClient.get(`${apiUrl}${PORT}/intake-output-result/get-all/${petId}`, {}, token, dispatch, getState).then((response) => {
                dispatch({ type: "IO_RESULTS_LOADING", payload: false })
                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    dispatch({ type: "IO_RESULTS", payload: response?.response_data[0] })
                    return callback(response);
                } else {
                    return callback(response);
                }
            })
        } else {
            return callback({ status: false });
        }
    };
};



/**
 * @param {Object} body
 * @param {Fuction} callback
 * @returns 
 */
export const getAndFilterIntakeOutputResults = (body, callback) => {
    return (dispatch, getState) => {
        const { user: { token }} = getState();
        if (token) {
            ApiClient.post(`${apiUrl}${PORT}/intake-output-result/get-all-with-filter`, body, token, dispatch, getState).then((response) => {
                dispatch({ type: "IO_RESULTS_LOADING", payload: false })
                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    dispatch({ type: "IO_RESULTS", payload: response?.response_data[0] })
                    return callback(response);
                } else {
                    //toast.error(response.message, toasterConfig);
                    return callback(response);
                }
            })
        }
    };
};


/**
 * get all vaccination dues details
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns 
 */
export const getIntakeOutputStatsByPetId= (data, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, } = getState();
        if (token) {
            dispatch({ type: "IO_STATS_LOADING", payload: true })
            ApiClient.post(`${apiUrl}${PORT}/intake-output-result/get-stats`, data, token, dispatch, getState).then((response) => {
                dispatch({ type: "IO_STATS_LOADING", payload: false })
                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    dispatch({ type: "IO_STATS", payload: response?.response_data[0] })
                    return callback(response);
                } else {
                    return callback(response);
                }
            })
        } else {
            return callback({ status: false });
        }
    };
};


/**
 * @description function use to add new intake output result
 * @param {*} params accepting object
 */
export const addNewIntakeOutput = (data, callback) => {
    return (dispatch, getState) => {
        const { user: { token } } = getState();
        ApiClient.post(`${apiUrl}${PORT}/intake-output-result/add`, data, token, dispatch, getState).then((response) => {
            if (!response) {
                return callback();
            }
            else if (!response && response.errors) {
                toast.error(response.errors.msg, toasterConfig);
                return callback(response);
            } else if (response) {
                toast.dismiss();
                toast.success(response.msg, toasterConfig);
                return callback(response);
            } else {
                //toast.error(response.message, toasterConfig);
                return callback();
            }
        });
    };
};
