import React, { useEffect } from "react";
import {
    InputGroup,
    Input, Label
} from 'reactstrap';
import moment from "moment";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoaderUI from "../../../../../../shared/loader";
import { dobInYearMonthDays } from "../../../../../../utils/common";
import "./index.scss";
import { updateMAR, addMAR } from "../../../../../../context/actions/patient-module/mars";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toasterConfig } from "utils/constants";
import { HiOutlineArrowPathRoundedSquare } from "react-icons/hi2";

const EditMARPanel = ({ category, className, closePanel, refresh, marDetails = false, orderDetails = {} }) => {
   


    const params = useParams();
    const petId = params.petId;
    const userDetails = useSelector((state) => state.user.user_details);
    const dispatch = useDispatch();

    const [formVals, setFormVals] = useState(
        marDetails ? {
            route: marDetails?.route,
            rate_val: marDetails?.rate_val,
            rate_time: marDetails?.rate_time,
            location: marDetails?.location,
            notes: marDetails?.notes,
            dose: marDetails?.dose,
            unit: marDetails?.unit,
            lot_number: marDetails?.lot_number,
            not_administered: marDetails?.not_administered,
            not_administered_reason: marDetails?.not_administered_reason,
            day: (new Date(marDetails?.date)).toLocaleDateString('en-CA'),
            time: (new Date(marDetails?.date)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' })
        } : {});

    const { loading, petDetails } = useSelector(state => state.petReducer);

    const handleUpdate = () => {
        console.log('formVals', formVals)
        let date = moment(`${formVals.day} ${formVals.time}`, 'YYYY-MM-DD hh:mm').toDate()

        if (!formVals.lot_number) return toast.error("Select Lot Number", toasterConfig)

        if (!formVals.lot_number && !formVals.not_administered) return toast.error("Select Lot Number", toasterConfig)
        // validation dose
        if (formVals?.dose === "" || formVals?.dose === undefined && !formVals.not_administered) {
            return toast.error(`Please Enter dose`, toasterConfig)
        } else if (formVals?.dose !== orderDetails?.dose && !formVals.not_administered) {
            return toast.error(`Do not enter a dose other than ${orderDetails?.dose}`);
        }
        // validation unit
        if (formVals?.unit === "Select Unit" || formVals?.unit === undefined && !formVals.not_administered) {
            return toast.error(`Please Select unit`, toasterConfig)
        } else if (orderDetails.form !== formVals?.unit && !formVals.not_administered) {
            return toast.error(`Please Select ${orderDetails?.form} Unit`)
        }


        let { day, time, ...vals } = formVals
        dispatch(updateMAR({ ...vals, _id: marDetails?._id, date: date },
            (response) => {
                if (response?.status) {
                    refresh()
                    closePanel()
                }
            }))

    }

    const handleAdd = () => {
        console.log(`${formVals.day} ${formVals.time}`)
        let date = moment(`${formVals.day} ${formVals.time}`, 'YYYY-MM-DD hh:mm').toDate()
        let { day, time, ...vals } = formVals;
        formVals.dose = +formVals?.dose;


        if (!formVals.lot_number && !formVals.not_administered) return toast.error("Select Lot Number", toasterConfig)
        // validation dose
        if (formVals?.dose === "" || formVals?.dose === undefined && !formVals.not_administered) {
            return toast.error(`Please Enter dose`, toasterConfig)
        } else if (+formVals?.dose !== orderDetails?.dose && !formVals.not_administered) {
            return toast.error(`Please enter the ordered dosage, ${orderDetails?.dose}`);
        }
        // validation unit
        if (formVals?.unit === "Select Unit" || formVals?.unit === undefined && !formVals.not_administered) {
            return toast.error(`Please Select unit`, toasterConfig)
        } else if (orderDetails.form !== formVals?.unit && !formVals.not_administered) {
            return toast.error(`Please Select ${orderDetails?.form} Unit`)
        }
        dispatch(addMAR({ ...vals, date: date, order_id: orderDetails?._id, created_by: userDetails._id },
            (response) => {
                if (response?.status) {
                    refresh()
                    closePanel()
                }
            }
        ))
    }

    const locationFromRoute = (route) => {
        switch (route) {

            case "IM":
                return ['left quadriceps', 'right quadriceps', 'left trapezius', 'right trapezius', 'left tricepts', 'right tricepts', 'left hamstring', 'right hamstring', 'epaxial'];

            case "SubQ":
                return ['left shoulder', 'right shoulder', 'left hip', 'right hip', 'left chest', 'right chest', 'left abdomen', 'right abdomen', 'scruff'];
            case "IV":
                return ['right jugular', 'left jugular', 'right cephalic', 'left cephalic', 'right medial saphenous', 'left medial saphenous', 'right lateral saphenous', 'left lateral saphenous'];
            case "IV Push":
                return ['right jugular', 'left jugular', 'right cephalic', 'left cephalic', 'right medial saphenous', 'left medial saphenous', 'right lateral saphenous', 'left lateral saphenous'];
            case "Nasal":
                return ['left nostril', 'right nostril'];
            case 'Otic':
                return ['left ear', 'right ear'];
            case 'Ocular':
                return ['left eye', 'right eye'];
            default:
                return []
        }

    }
    const quantity_to_be_used = orderDetails?.form === orderDetails?.item_details?.sub_package?.unit_of_measure ? Math.ceil(orderDetails?.dose / orderDetails?.item_details?.sub_package?.amount) : orderDetails?.dose
    //console.log(quantity_to_be_used, 'quantity_to_be_used');
    const unitOpt = orderDetails?.item_details?.unit_of_measure ? [orderDetails?.item_details?.unit_of_measure] : []
    if (orderDetails?.item_details?.sub_package?.unit_of_measure) unitOpt.push(orderDetails?.item_details?.sub_package?.unit_of_measure)

    return (
        <>
            {
                loading ? <LoaderUI /> :
                    <div className={`edit-mar-panel position-fixed d-flex flex-column overflow-auto ${className}`}>

                        {/* maybe use reactstrap form here*/}
                        <div className={'section'}>
                            <div className={'item'} style={{ gap: '10px' }}>

                                <img className="img-fluid" src={/*item?.diagnosedBy?.user_image || */ require('../../../../../../assets/img/vetRecorEMR/user-thumbnail.png')} alt="User" />
                                <div className='text-region'>
                                    <h2>{userDetails.firstname} {userDetails.lastname}</h2>
                                    <p>[{userDetails.role}]</p>
                                </div>
                            </div>
                        </div>

                        <div className={'section'}>
                            <div>Adminstered On</div>
                            <div className='d-flex w-100 flex-row' style={{ gap: '10px' }}>
                                <div className='d-flex flex-fill flex-column'>
                                    <Input onChange={(evt) => { setFormVals({ ...formVals, day: evt.target.value }) }} defaultValue={formVals?.day} type="date" max={moment().format('YYYY-MM-DD')} placeholder="Date" />
                                </div>
                                <div className='d-flex flex-fill flex-column'>
                                    <Input value={formVals.time} onChange={(evt) => {
                                        setFormVals({ ...formVals, time: evt.target.value })
                                        console.log(evt.target.value)
                                    }} type="time" placeholder="Time" />

                                </div>
                            </div>
                        </div>
                        <div className={'section'}>
                            <div>Order Details</div>
                            <div className={'item'}>
                                <div className='text-region'>
                                    <h2>{orderDetails?.item_details?.product_name}</h2>
                                    <p>{`${orderDetails?.dose} ${orderDetails?.form}, ${orderDetails?.route}${orderDetails?.frequency ? ', ' + orderDetails?.frequency : ''}`}</p>
                                </div>
                            </div>
                        </div>
                        {!loading &&
                            <div className={'section'}>
                                <div>Pet Information</div>
                                <div className={'item'} style={{ gap: '10px' }}>
                                    <div>
                                        <img className="img-fluid" src={petDetails?.pet_image || require('../../../../../../assets/img/vetRecorEMR/pet-thumbnail.png')} alt="Pet" />
                                        <span className="badge-icon position-relative">
                                            <img className="img-fluid" src={require('../../../../../../assets/img/vetRecorEMR/pet-badge-icon-yellow.svg')} alt="Badge" />
                                        </span>
                                    </div>
                                    <div className='text-region'>
                                        <h2>{petDetails?.pet_name} ({petDetails?.vrn})</h2>
                                        <p>{dobInYearMonthDays(new Date(petDetails?.dob || new Date()))} old (DOB : {moment(petDetails?.dob).format('MM/DD/YYYY')} ) / {petDetails?.gender} / { } / {petDetails.color}</p>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className={'section'}>
                            <div>Dose Information</div>
                            <Label check>
                                <Input onChange={(evt) => { setFormVals({ ...formVals, not_administered: evt.target.checked }) }} checked={formVals?.not_administered} type="checkbox" />{' '}
                                Not Administered
                            </Label>

                    {formVals?.not_administered && 
                    <Input onChange={(evt) => {setFormVals({...formVals, not_administered_reason: evt.target.value})}} value={formVals?.not_administered_reason} placeholder={'Add reason medication not administered'} /> 
                    }
                       <div  className='d-flex w-50 flex-column'>
                        <Input disabled={formVals?.not_administered}  value={formVals?.lot_number} onChange={(event)=> {setFormVals({...formVals, lot_number: event.target.value })}} type="select">
                        <option value={undefined}>Select Lot Number </option>
                        {orderDetails?.item_details?.packages?.filter(pack => pack?.remaining_stock - quantity_to_be_used > 0)?.map((ele) => {
                            return <option value={ele.lot_number}>{ele.lot_number}</option>
                        })}
                                
                        </Input>
                        </div>
                  
                    <InputGroup>
                        <Input type="number" disabled={formVals?.not_administered} className="w-75" onChange={(evt) => {setFormVals({...formVals, dose: evt.target.value})}}value={formVals?.dose}  placeholder={'Add Dose'}/>
                        <Input disabled={formVals?.not_administered} className="w-25" onChange={(evt) => {setFormVals({...formVals, unit: evt.target.value})}} value={formVals?.unit} type="select">
                            <option>Select Unit</option>
                            {[...new Set(unitOpt)]?.map(opt => <option>{opt}</option>)}
                            {/* <option>mL</option>
                            <option>L</option>
                            <option>tablet</option>
                            <option>capsule</option>
                            <option>mg</option>
                            <option>g</option>
                            <option>patch</option>
                            <option>spray</option>
                            <option>drop</option> */}
                                </Input>
                            </InputGroup>
                            {category === 'Infusion' &&
                                (<InputGroup>
                                    <Input disabled={formVals?.not_administered} className="w-75" onChange={(evt) => { setFormVals({ ...formVals, rate_val: evt.target.value }) }} value={formVals?.rate_val} placeholder={'Add Rate'} />
                                    <Input disabled={formVals?.not_administered} className="w-25" onChange={(evt) => { setFormVals({ ...formVals, rate_time: evt.target.value }) }} value={formVals?.rate_time} type="select">
                                        <option>Select Rate Time</option>
                                        <option>mL/hr</option>
                                        <option>L/hr</option>
                                    </Input>
                                </InputGroup>)
                            }
                            <div className='d-flex w-100 flex-row' style={{ gap: '10px' }}>
                                <div className='d-flex w-50 flex-column'>
                                    <Input disabled={formVals?.not_administered} value={formVals?.route} onChange={(event) => { setFormVals({ ...formVals, route: event.target.value }) }} type="select">
                                        <option>Select Route</option>
                                        <option> {orderDetails?.route} </option>
                                        {/* <option>IV</option>
                                <option>IV Push</option>
                                <option>PO</option>
                                <option>SubQ</option>
                                <option>Rectal</option>
                                <option>Inhalation</option>
                                <option>Nasal</option>
                                <option>Ocular</option>
                                <option>Otic</option>
                                <option>Topical</option>
                                <option>Transdermal</option> */}
                                    </Input>
                                </div>
                                {locationFromRoute(formVals?.route).length > 0  &&
                        <div className='d-flex w-50 flex-column'>
                                    <Input disabled={formVals?.not_administered} value={formVals?.location} onChange={(event) => { setFormVals({ ...formVals, location: event.target.value }) }} type="select">
                                        <option>Select Location</option>
                                        {locationFromRoute(formVals.route).map((location, i) => {
                                            return (<option key={i}>{location}</option>)
                                        })
                                    }
                            </Input>
                            </div>
                            }
                    </div>
                    
                        
                    <Input onChange={(evt) => {setFormVals({...formVals, notes: evt.target.value})}} value={formVals?.notes} placeholder={'Add Notes e.g. Heart Rate, Tempertature, etc.'} /> 
                </div>

                        <div className="button-section">
                            <div className="close-btn" onClick={() => { closePanel() }}>
                                <i className="icomoon-cross"></i>
                            </div>
                            <div className="submit-btn" onClick={marDetails ? handleUpdate : handleAdd}>
                                <i className="icomoon-check"></i>
                            </div>
                        </div>
                    </div>
            }

        </>
    )
}

export default EditMARPanel;