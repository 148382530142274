import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Form,
  FormGroup,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
// import SwitchCell from "../../../../../shared/SwitchCell";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";

import { useParams } from "react-router-dom";
// import { addNewPetDocument, updatePetDocument } from "../../../context/actions/patient-module/document";
import { toasterConfig } from "../../../../../../utils/constants";
import {
  PORT,
  apiUrl,
  imageBaseUrl,
  documentPhotosPath,
} from "../../../../../../environment";
import UploadBlock from "../../../../../../shared/UploadBlock";
import {
  AddNewDischargeInstructionDocument,
  UpdateDischargeInstructionDocument,
} from "../../../../../../context/actions/patient-module/discharge-patient-form/discharge-instructions-documents";

const AddEditDischargeInstructionDocument = ({
  className = "",
  closeAddEditDischargeInstructionPanel,
  selectedDocument,
  setSelectedDocument,
}) => {
  console.log("selectedDocument", selectedDocument);

  const token = useSelector((state) => state?.user?.token);
  const userDetails = useSelector((state) => state?.user?.user_details);
  const params = useParams();
  const petId = params.petId;
  const [images, setImages] = useState([]);
  const [imagesarr, setImagesarr] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );

  const dispatch = useDispatch();

  let defaultInputState = {
    document_name: "",
    notes: "",
  };

  const [inputs, setInputs] = useState(defaultInputState);

  useEffect(() => {
    if (selectedDocument) {
      setInputs({
        document_name: selectedDocument.document_name,
        notes: selectedDocument.notes,
      });
    }
  }, [selectedDocument]);

  const handleInputChange = (e) => {
    e.preventDefault();
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    setImageFiles([]);
    setImages([]);
    setImagesarr([]);
    setInputs(defaultInputState);
    setSelectedDocument(null);
  };

  useEffect(() => {
    return () => {
      console.log("cleaned up");
      resetForm();
    };
  }, []);

  const createUpdatePetDocument = () => {
    if (!selectedDocument) {
      let formdata = new FormData();

      if (images && images.length > 0) {
        for (let image of images) {
          formdata.append("document", image, image.name);
        }
      }

      formdata.append("clinic_id", clinic_settings.clinic_id);
      formdata.append("added_by", userDetails?._id);
      formdata.append("document_name", inputs.document_name);
      formdata.append("notes", inputs.notes);
      console.log("formdata", formdata);

      dispatch(
        AddNewDischargeInstructionDocument(
          formdata,
          clinic_settings.clinic_id,
          (response) => {
            if (response && !response.errors) {
              resetForm();
              closeAddEditDischargeInstructionPanel();
            } else {
            }
          }
        )
      );
    } else {
      let formdata = new FormData();

      if (images && images.length > 0) {
        for (let image of images) {
          formdata.append("document", image, image.name);
        }
      }

      formdata.append("clinic_id", clinic_settings.clinic_id);
      formdata.append("image_document_link", selectedDocument.document_link);
      formdata.append("document_id", selectedDocument._id);
      formdata.append("added_by", userDetails?._id);
      formdata.append("document_name", inputs.document_name);
      formdata.append("notes", inputs.notes);

      dispatch(
        UpdateDischargeInstructionDocument(
          formdata,
          clinic_settings.clinic_id,
          (response) => {
            if (response && !response.errors) {
              // resetForm()
            } else {
            }
          }
        )
      );
    }
  };

  return (
    <>
      <div
        className={`ocular-new-point-panel-wrapper position-fixed d-flex flex-column overflow-auto ${className}`}
      >
        <div className="head d-flex align-items-center">
          <h2>
            {selectedDocument && selectedDocument._id
              ? "Edit Discharge Instruction"
              : "Add Discharge Instruction"}
          </h2>
          <Button
            color="link"
            className="ms-auto panel-close-btn"
            onClick={() => {
              closeAddEditDischargeInstructionPanel();
              resetForm();
            }}
          >
            <span className="material-symbols-outlined">close</span>
          </Button>
        </div>

        <div className="inner align-items-center mt-2">
          <Form>
            <FormGroup className="input-wrapper2 mb-2">
              {/* <div className="form-floating position-relative text-input"> */}
              <UploadBlock
                images={images}
                setImages={setImages}
                imagesarr={imagesarr}
                setImagesarr={setImagesarr}
                imageFiles={imageFiles}
                setImageFiles={setImageFiles}
                documentimages={
                  selectedDocument
                    ? `${imageBaseUrl}${documentPhotosPath}/${selectedDocument.document_link}`
                    : null
                }
                documentname={
                  selectedDocument ? selectedDocument.document_name : null
                }
                accept={
                  "image/png, image/jpg, image/jpeg, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/gzip, application/zip, application/x-rar-compressed"
                }
                multiple={false}
              />
              {/* </div> */}
            </FormGroup>

            <FormGroup className="input-wrapper2 mb-2">
              <div className="form-floating position-relative text-input">
                <input
                  type="text"
                  className="form-control"
                  id="document_name"
                  name="document_name"
                  placeholder="Document Name"
                  value={inputs.document_name}
                  onChange={handleInputChange}
                />
                <label htmlFor="document_name">Document Name</label>
              </div>
            </FormGroup>

          

            <FormGroup className="input-wrapper2 mb-2">
              <div className="form-floating position-relative text-input">
                <input
                  type="text"
                  className="form-control"
                  id="notes"
                  name="notes"
                  placeholder="Notes"
                  value={inputs.notes}
                  onChange={handleInputChange}
                />
                <label htmlFor="notes">Notes</label>
              </div>
            </FormGroup>

            <div className="btn-blc border_btn mt-3 text-end">
              
              <Button
                color="success"
                onClick={() => {
                  createUpdatePetDocument();
                }}
                className="add-btn"
              >
                {selectedDocument && selectedDocument._id
                  ? "UPDATE"
                  : "CREATE"}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddEditDischargeInstructionDocument;
