import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getInventoriesByClinicIdWithoutLimit,
} from "../../../context/actions/inventory";
import NodataFoundBlock from "../../../shared/NoDataFoundBlock";
import CheckBox from "../../../shared/CheckBox";
import { Button, Table } from "reactstrap";

export default function AddProductsTable({ formData, setFormData }) {
  const dispatch = useDispatch();
  const [selectedList, setSelectedList] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  
  useEffect(() => {
    if (formData && formData.products) {
      // console.log("formData.products", formData.products)
      let productIds = formData.products.map((ele) => {
        return {
          product_id: ele._id || ele.product_id,
        };
      });
      console.log("productIds", productIds);
      setSelectedList(productIds);
    }
  }, [formData]);

  useEffect(() => {
    dispatch(
      getInventoriesByClinicIdWithoutLimit(clinic_details?._id, {}, (res) => {
        setInventoryData(res.response_data);
        // console.log(res);
      })
    );
  }, []);

  const handleCheckMarkAll = () => {
    if (selectedList.length == inventoryData.length) {
      setSelectedList([]);
    } else {
      let selected_ids = inventoryData.map((ele) => {
        return {
          product_id: ele._id,
        };
      });
      setSelectedList(selected_ids);
    }
  };

  const handleSelect = (id) => {
    if (selectedList.some((ele) => ele.product_id == id)) {
      let filterData = selectedList.filter((ele) => ele.product_id != id);
      setSelectedList(filterData);
      setFormData(filterData);
    } else {
      let cloneSelectedList = [...selectedList];
      cloneSelectedList.push({ product_id: id });
      console.log("cloneSelectedList", cloneSelectedList);
      setSelectedList(cloneSelectedList);
      setFormData(cloneSelectedList);
    }
  };

  console.log("selectedList", selectedList);

  // const handleGetAndSearchProductList = (e) => {
  //   let text = e?.target?.value;
  //   dispatch(getAndSearchProductList({ searchText: text }, (response) => {}));
  // };

  // useEffect(() => {
  //   handleGetAndSearchProductList();
  // }, []);

  // console.log("selectedList?.length", selectedList?.length);
  // console.log("inventoryData?.length", inventoryData?.length);

  return (
    <>
      <h5 className="text-lg font-semibold uppercase text-[#282E65]">
        Products
      </h5>

      {inventoryData?.length > 0 ? (
        <div className="table-listing-blc overflow-auto">
          <Table striped responsive className="checkbox-listing">
            <thead>
              <tr>
                <th>
                  <CheckBox
                    type="checkbox"
                    ischecked={selectedList?.length == inventoryData?.length}
                    checked={selectedList?.length == inventoryData?.length}
                    className="no-label"
                    onChange={() => handleCheckMarkAll()}
                  />
                </th>
                <th>Name</th>
                <th style={{ minWidth: "120px" }}>SKU</th>

                <th style={{ minWidth: "130px" }}>Category</th>

                <th style={{ minWidth: "100px" }}>Price</th>
                <th style={{ minWidth: "80px" }}>Unit</th>
                <th style={{ minWidth: "80px" }}>Qty</th>
              </tr>
            </thead>
            <tbody>
              {inventoryData?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <CheckBox
                        type="checkbox"
                        ischecked={selectedList.some(
                          (ele) => ele.product_id == item._id
                        )}
                        checked={selectedList.some(
                          (ele) => ele.product_id == item._id
                        )}
                        className="no-label"
                        onChange={() => handleSelect(item._id)}
                      />
                    </td>
                    <td>
                      <Button
                        color="link"
                        className="p-0 text-start pic-cell pic-cell-type-2 d-inline-flex align-items-center w-100 truncate_btn"
                      // onClick={() => {
                      //   history.push(`/product-detail/${item?._id}`);
                      // }}
                      >
                        <div className="pic d-inline-flex flex-shrink-0">
                          {item?.image ? (
                            <img
                              className="img-fluid w-100 h-100"
                              src={item?.image}
                              alt="Product"
                              style={{
                                borderRadius: "0",
                              }}
                            />
                          ) : (
                            <img
                              className="img-fluid w-100 h-100"
                              src={require("../../../assets/img/vetRecorEMR/product-thumbnail-square.png")}
                              alt="Product"
                              style={{
                                borderRadius: "0",
                              }}
                            />
                          )}
                        </div>
                        <span>{item?.product_name}</span>
                      </Button>
                    </td>
                    <td>{item?.sku || '--'}</td>

                    <td style={{textTransform: 'capitalize'}}>{item?.category_id?.category}</td>
                    <td>
                      {item?.product_cost ?

                      <>
                      
                      ${parseFloat(item?.product_cost).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}

                      </>

                      :
                      <>
                      --
                      </>
                        
                      }
                        </td>
                    <td>Vial</td>
                    <td>{item?.quantity || '--'}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {/* <PaginationBlock
            limit={limit}
            page={page}
            pagingCounter={inventory?.pagingCounter || 1}
            docLength={inventory?.docs?.length || 0}
            docPage={inventory.page || 1}
            totalPages={inventory.totalPages || 1}
            totalDocs={inventory.totalDocs || 0}
            _handleLimit={(val) => {
              setLimit(val);
            }}
            _handlePageChange={setPage}
          /> */}
        </div>
      ) : (
        <NodataFoundBlock />
      )}

      {/* <div className="table-listing-blc overflow-auto">
        <Table responsive>
          <thead>
            <tr>
              <th>Componen111t</th>
              <th>Details</th>
              <th>Category</th>
              <th style={{ width: "100px" }}>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {selectedList &&
              selectedList.length > 0 &&
              selectedList.map((item, i) => {
                return (
                  <tr style={{ background: "#DBF8F1" }} key={i}>
                    <td
                      style={{ background: "#DBF8F1" }}
                    >{`${item?.product_name} (${item?.sku})`}</td>
                    <td style={{ background: "#DBF8F1" }}>
                      {item?.description}
                    </td>
                    <td style={{ background: "#DBF8F1" }}>{item?.category}</td>
                    <td style={{ background: "#DBF8F1" }}>
                      <Button
                        color="link"
                        onClick={() => {
                          let copy = [...selectedList];
                          copy.splice(i, 1);
                          handleSelect(copy);
                        }}
                      >
                        Remove
                      </Button>
                    </td>
                  </tr>
                );
              })}

            {productList && productList.length > 0 ? (
              productList.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{`${item?.product_name} (${item?.sku})`}</td>
                    <td>{item?.description}</td>
                    <td>{item?.category}</td>
                    <td>
                      <Button
                        color="link"
                        onClick={() => {
                          handleSelect([...selectedList, item]);
                        }}
                      >
                        Select
                      </Button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className="no-data-rw">
                <td colSpan="5">
                  <NodataFoundBlock />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div> */}
    </>
  );
}
