import React from "react";
import { useSelector } from "react-redux";
import { dobInYearMonthDays, formatPhoneNumber } from "../../../../../utils/common";
const OwnerProfileDescription = () => {
  const { ownerAndPetDetails } = useSelector(
    (state) => state.petOwnerDashboardReducer
  );

  return (
    <>
      <div className="profile-description row-block">
        <p className="d-flex">
          <label>Phone</label>
          <span>
            {formatPhoneNumber(ownerAndPetDetails?.phone)}{" "}
            <small style={{ color: "#409DE0" }}>*</small>
            {/* <br />+1 333 456 7890
                        <br />+1 444 456 7890 */}
          </span>
        </p>
        <p className="d-flex">
          <label>Email Address</label>
          <span>
            <button className="btn-link">{ownerAndPetDetails?.email}</button>
          </span>
        </p>
        <p className="d-flex">
          <label>Title</label>
          <span>Information</span>
        </p>
        <p className="d-flex">
          <label>Title</label>
          <span>Information</span>
        </p>
        <p className="d-flex">
          <label>Address Info.</label>
          <span>{ownerAndPetDetails?.address || "--"}</span>
        </p>
        <div className="mb-3">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3035.8461071105717!2d-79.98317928802798!3d40.45654297131414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8834f3c40ba28ebd%3A0x213be4f83e36b27c!2s48%2026th%20St%2C%20Pittsburgh%2C%20PA%2015222%2C%20USA!5e0!3m2!1sen!2sin!4v1690443447243!5m2!1sen!2sin"
            width="600"
            height="450"
            style={{ borderRadius: "12px" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        {/* <h5>Office Notes</h5>
        <p>For office use only</p>
        <p>Content</p> */}
      </div>
    </>
  );
};

export default OwnerProfileDescription;
