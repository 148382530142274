import { toast } from "react-toastify";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";

export const getBehaviours = (callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
      clinicReducer: { clinic_details },
    } = getState();

    ApiClient.get(
      `${apiUrl}${PORT}/app-content/get-behaviour-tags/${clinic_details?._id}`,
      {},
      token,
      dispatch, getState
    ).then((res) => {
      if (res) {
        dispatch({ type: "GET_BEHAVIOURS", payload: res?.response_data });
        return callback(res?.status);
      } else {
        dispatch({ type: "GET_BEHAVIOURS", payload: [] });
        toast.error("Behaviours are not fetched");
        return callback(res?.status);
      }
    });
  };
};

export const addBehaviour = (payload, callback) => {
  return (dispatch, getState) => {
    
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    payload.clinic_id = clinic_details?._id
    ApiClient.post(
      `${apiUrl}${PORT}/app-content/add-behaviour-tags`,
      payload,
      token,
      dispatch, getState
    ).then((response) => {
      
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response) {
        if (response.status == 200) {
          toast.error(response.message, toasterConfig);
        }
        toast.dismiss();
        if (response.statusCode == 400) {
          toast.error(response.message, toasterConfig);
        } else if (response.statusCode == 200 && response.status == false) {
          toast.error(response.message, toasterConfig);
        } else if (response.statusCode == 200 && response.status == true) {
          toast.success(response.message, toasterConfig);
        }
        return callback(response);
      } else {
        toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

export const deleteBehaviour = (id, callback) => {
  return (dispatch, getState) => {
    
    const {
      user: { token },
    } = getState();
    ApiClient.put(
      `${apiUrl}${PORT}/app-content/remove-behaviour-tags/${id}`,
      {},
      token,
      dispatch, getState
    ).then((response) => {
      
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response) {
        if (response.status == 200) {
          toast.error(response.message, toasterConfig);
        }
        toast.dismiss();
        if (response.statusCode == 400) {
          toast.error(response.message, toasterConfig);
        } else if (response.statusCode == 200 && response.status == false) {
          toast.error(response.message, toasterConfig);
        } else if (response.statusCode == 200 && response.status == true) {
          toast.success(response.message, toasterConfig);
        }
        return callback(response);
      } else {
        toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

//export const addKennel = (body, callback) => {
