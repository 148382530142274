import { getAllInventoryCategories } from "../../../../context/actions/app-content";
import { useDispatch, useSelector } from "react-redux";



export default function OrderInfo({ ServiceDetails }) {
    const dispatch = useDispatch();
    const {inventoryCategories }  = useSelector(state => state.appContentReducer)

    const { permissionsData } = useSelector(
      (state) => state.clinicPermissionReducer
    );
    const permissions = permissionsData;
  return (
    <>
      <h5 className="text-lg font-semibold uppercase text-[#282E65]">
        SERVICE ID: SERVICE-{ServiceDetails?._id.substring(5, 12)}
      </h5>
      <ul className="space-y-1">
        <li className=" flex flex-col md:flex-row gap-1">
          <label
            htmlFor="service_name"
            className="text-sm md:text-base w-full md:w-44"
          >
            Name
          </label>
          <input
            id="service_name"
            type="text"
            value={ServiceDetails?.service_name}
            placeholder="Add name"
            className="w-full md:w-56 cursor-pointer bg-gray-100 active:bg-gray-100 focus:bg-gray-200 rounded-md focus:px-2.5 md:px-2.5 py-1.5"
          />
        </li>
        <li className=" flex flex-col md:flex-row gap-1">
          <label
            htmlFor="service_category"
            className="text-sm md:text-base w-full md:w-44"
          >
            Category
          </label>
          <select
            id="service_category"
            type="text"
            style={{textTransform: 'capitalize'}}
            value={ServiceDetails?.category_id}
            placeholder="Category"
            className="w-full md:w-56  bg-gray-100 cursor-pointer focus:bg-gray-200 rounded-md focus:px-2.5 md:px-2.5 py-1.5"
            disabled={!permissions?.edit_services}
          >
            {(inventoryCategories || []).map((item, i) => (
              <option key={i} value={item?._id}>{item?.category}</option>
            ))}
          </select>
        </li>
        <li className=" flex flex-col md:flex-row gap-1">
          <label
            htmlFor="service_price"
            className="text-sm md:text-base w-full md:w-44"
          >
            Price
          </label>
          <input
            id="service_price"
            type="text"
            value={'$'+(+ServiceDetails?.service_price)?.toLocaleString('en-US', {
              minimumFractionDigits: 2,
            })}
            placeholder="$ 100"
            className="w-full md:w-56  bg-gray-100 cursor-pointer focus:bg-gray-200 rounded-md focus:px-2.5 md:px-2.5 py-1.5"
          />
        </li>
        <li className=" flex flex-col md:flex-row gap-1">
          <label
            htmlFor="service_tax"
            className="text-sm md:text-base w-full md:w-44"
          >
            Tax
          </label>
          <input
            id="service_tax"
            type="text"
            placeholder="No"
            value={ServiceDetails?.service_tax+'%'}
            className="w-full md:w-56  bg-gray-100 cursor-pointer focus:bg-gray-200 rounded-md focus:px-2.5 md:px-2.5 py-1.5"
          />
        </li>
        <li className=" flex flex-col md:flex-row gap-1">
          <label
            htmlFor="service_comission"
            className="text-sm md:text-base w-full md:w-44"
          >
            Doctor's Commission
          </label>
          <input
            id="service_commission"
            type="text"
            placeholder="10%"
            value={ServiceDetails?.doctor_commission+ '%'}
            className="w-full md:w-56  bg-gray-100 cursor-pointer focus:bg-gray-200 rounded-md focus:px-2.5 md:px-2.5 py-1.5"
          />
        </li>
        
      </ul>
    </>
  );
}
