import React, { useState } from "react";
import { HiChevronDown } from "react-icons/hi2";
import { useSelector } from "react-redux";

const CustomSelect = ({
  tasks,
  selectedTask,
  setSelectedTask,
  placeholder = "Select",
  className,
}) => {
  const [open, setOpen] = useState(false);

  const handleChange = (task) => {
    setSelectedTask(task);
    setOpen(false);
  };

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  return (
    <div className={`relative ${className}`}>
      <button
        className="w-full border rounded-lg capitalize p-2.5 text-left relative overflow-hidden"
        onClick={() => (permissions?.edit_boarding ? setOpen(!open) : "")}
      >
        {selectedTask ? selectedTask : placeholder}
        <span className="absolute inline-flex items-center justify-center right-0 top-0 px-3 h-12 bg-gray-100 border-l">
          <HiChevronDown
            className={`w-5 h-5 duration-300 transition-transform ${
              open ? "rotate-180" : ""
            }`}
          />
        </span>
      </button>
      <ul
        className={
          open
            ? "absolute top-12 w-full max-h-56 overflow-auto border bg-white z-10 rounded-lg"
            : "hidden"
        }
      >
        {tasks?.map((task, i) => (
          <li
            key={Date.now() + i + Math.random()}
            onClick={() => handleChange(task)}
            className={`px-2.5 py-1.5 cursor-pointer ${
              task === selectedTask
                ? "bg-blue-500 text-white hover:bg-blue-600 hover:text-white"
                : "hover:bg-gray-100 text-black"
            }`}
          >
            {task}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CustomSelect;
