const initState = {
    loading: true,
    waitingRoomList: [],
}

export const waitingRoomReducer = (state = initState, action) => {
    switch (action.type) {
        case "LOADING":
            return { ...state, loading: action.payload }

        case "GET_WAITING_ROOM":
            return { ...state, waitingRoomList: action.payload }

        default:
            return state
    }
}