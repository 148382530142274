import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import {
  // getCurrentDay,
  // getCurrentMonth,
  getDaysForCalendarMonth,
  // getMonthValue,
} from "utils/calendar/constant";
// import UserPlaceholder from "shared/UserPlaceholder";
import { useDispatch, useSelector } from "react-redux";
// import { getAllDoctors } from "context/actions/appointment";
import LoaderUI from "shared/loader";
import { getClinicAvailabilityDetails } from "context/actions/staffManagement";
import moment from "moment";
import { FaUserCircle } from "react-icons/fa";
import { uniqBy } from "lodash";
import { getAllDoctors } from "context/actions/appointment";
import { getActiveRoles } from "context/actions/clinicPermission";
import { getAllHoliday } from "context/actions/holiday";
import "./ClinicAvilability.scss";

const ClinicAvilability = () => {
  const dispatch = useDispatch();
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const { holidays } = useSelector((state) => state.holidayReducer);

  const [selectedDate, setSelectedDate] = useState(
    moment().format("DD-MM-YYYY")
  );
  const [dates, setDates] = useState([]);
  const [loader, showLoader] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [fullList, setFullList] = useState([]);
  const [appointmentBlock, setAppointmentBlock] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [filterData, setFilterData] = useState({
    searchText: "",
    role_type: "",
    block_type: "",
    holiday_type: "",
  });
  const handleChange = (e) => {
    let targetID = e.target.id;
    let targetValue = e.target.value;
    setFilterData((prev) => {
      return { ...prev, [targetID]: targetValue };
    });
  };

  const handleMonthChange = (changeType) => {
    let sMonth = +moment(selectedDate, "DD-MM-YYYY").format("M");
    let sYear = +moment(selectedDate, "DD-MM-YYYY").format("YYYY");

    if (changeType === "previous") {
      sMonth = sMonth === 1 ? 12 : sMonth - 1;
      sYear = sMonth === 12 ? sYear - 1 : sYear;
    } else {
      sMonth = sMonth === 12 ? 1 : sMonth + 1;
      sYear = sMonth === 1 ? sYear + 1 : sYear;
    }
    setSelectedDate(
      moment(`01-${sMonth}-${sYear}`, "DD-M-YYYY").format("DD-MM-YYYY")
    );
  };

  const fetchActiveRoles = () => {
    dispatch(
      getActiveRoles({}, (res) => {
        let resRoleList = [];

        if (Array.isArray(res?.response_data)) {
          res?.response_data?.forEach((e) => {
            resRoleList.push({
              id: e,
              label: e.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase()),
            });
          });
        }

        setRoleList(resRoleList);
      })
    );
  };

  const getAllClinicAvailability = async () => {
    const response = await dispatch(
      getClinicAvailabilityDetails(
        clinic_details?._id,
        moment(selectedDate, "DD-MM-YYYY").format("DD-MM-YYYY")
      )
    );
    setStaffList(response);
  };

  const getAllDoctorWithAppointment = () => {
    dispatch(
      getAllDoctors(clinic_details?._id, {}, (res) => {
        let apBlockList = [];
        if (Array.isArray(res)) {
          res?.forEach((e) => {
            const { _id, title, firstname, lastname, user_image, role } =
              e?.user;
            e?.appointments?.forEach((o) => {
              if (o?.appointment_type === "appointment-block") {
                let apTimeList = o?.appointment_timings?.map(
                  (o) => o.appointment_time
                );
                apBlockList.push({
                  doctor_id: _id,
                  doctor_image: user_image,
                  doctor_name: `${title} ${firstname} ${lastname}`,
                  role: role,
                  date: o?.appointment_date,
                  time: apTimeList?.toString(),
                });
              }
            });
          });
        }
        setAppointmentBlock(apBlockList);
      })
    );
  };

  useEffect(() => {
    showLoader(true);
    const date = getDaysForCalendarMonth(
      1,
      +moment(selectedDate, "DD-MM-YYYY").format("M") - 1,
      +moment(selectedDate, "DD-MM-YYYY").format("YYYY")
    );
    setDates(date);
    getAllClinicAvailability();
    showLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  useEffect(() => {
    getAllDoctorWithAppointment();
    fetchActiveRoles();
    dispatch(getAllHoliday({}, () => {}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { searchText, role_type, block_type, holiday_type } = filterData;

  return (
    <div id="clinic-availability-id">
      <div className="fb-center px-2">
        <div className="d-flex gap-2">
          <div className="search-cell-conatainer">
            <span className="icomoon-search search-icons"></span>
            <Input
              placeholder="Search"
              id="searchText"
              value={searchText}
              onChange={handleChange}
            />
          </div>
          <div className="role-cell-conatainer">
            <Input
              type="select"
              name="role_type"
              id="role_type"
              value={role_type}
              onChange={handleChange}
            >
              <option value="">All Roles</option>
              {roleList?.map((elm, index) => {
                return (
                  <option value={elm.id} key={index}>
                    {elm.label}
                  </option>
                );
              })}
            </Input>
          </div>
          <div className="role-cell-conatainer">
            <Input
              type="select"
              name="block_type"
              id="block_type"
              value={block_type}
              onChange={handleChange}
            >
              <option value="">All Block Type</option>
              <option value="available">Available</option>
              <option value="unavailable">Unavailable</option>
              <option value="appointment-block">Appointment Block</option>
            </Input>
          </div>
          <div className="role-cell-conatainer">
            <Input
              type="select"
              name="holiday_type"
              id="holiday_type"
              value={holiday_type}
              onChange={handleChange}
            >
              <option value="">All Holidays</option>
              <option value="custom">Partial Day</option>
              <option value="all-day">All Day</option>
            </Input>
          </div>
        </div>
        <div className={`inline-flex text-sm gap-x-[1px]`}>
          <button
            className="inline-flex items-center bg-gray-100 hover:text-[#3d3efd] rounded-tl-md rounded-bl-md hover:bg-gray-200 justify-center p-1.5"
            onClick={() => handleMonthChange("previous")}
          >
            <BsChevronLeft className="h-4 w-4" />
          </button>
          <span className="inline-flex items-center bg-gray-100 justify-center px-2.5 py-1.5">
            {moment(selectedDate, "DD-MM-YYYY").format("MMM YYYY")}
          </span>
          <button
            className="inline-flex items-center bg-gray-100 hover:text-[#3d3efd] rounded-tr-md rounded-br-md hover:bg-gray-200 justify-center p-1.5"
            onClick={() => handleMonthChange("next")}
          >
            <BsChevronRight className="h-4 w-4" />
          </button>
        </div>
      </div>
      {loader && <LoaderUI overlay />}
      <div className="calendar-month-view overflow-auto flex-fill mt-2 px-3">
        <table className="table">
          <thead>
            <tr>
              <th className="text-center">Sunday</th>
              <th className="text-center">Monday</th>
              <th className="text-center">Tuesday</th>
              <th className="text-center">Wednesday</th>
              <th className="text-center">Thursday</th>
              <th className="text-center">Friday</th>
              <th className="text-center">Saturday</th>
            </tr>
          </thead>
          <tbody>
            {dates.length > 0 &&
              dates.map((date, i) => {
                return (
                  <tr key={i}>
                    {date.map((day, i2) => {
                      let holidayList = [];
                      holidays &&
                        holidays?.forEach((o) => {
                          let holidayDate = moment(
                            o.date,
                            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                          ).format("DD-MM-YYYY");
                          if (o?.isRecurring) {
                            holidayDate = `${moment(
                              holidayDate,
                              "DD-MM-YYYY"
                            ).format("DD-MM")}-${moment(
                              selectedDate,
                              "DD-MM-YYYY"
                            ).format("YYYY")}`;
                          }
                          if (
                            holidayDate === day?.full_date &&
                            (holiday_type === "" || holiday_type === o.duration)
                          ) {
                            holidayList.push(o);
                          }
                        });

                      let fApBlockList = appointmentBlock?.filter(
                        (o) =>
                          o.date === day?.full_date &&
                          (searchText === "" ||
                            o.doctor_name
                              .toLowerCase()
                              .includes(searchText.toLowerCase())) &&
                          (role_type === "" || role_type === o.role)
                      );
                      let availableList = [];
                      let unAvailableList = [];

                      staffList?.forEach((e) => {
                        const {
                          start_date,
                          end_date,
                          userDetails,
                          staff_id,
                          start_time,
                          end_time,
                          isAvailable,
                          isWeekendOff,
                          isPublicHolidayOff,
                        } = e;

                        const { title, firstname, lastname, user_image, role } =
                          userDetails;
                        const startDate = moment(start_date, "DD-MM-YYYY");
                        const endDate = moment(end_date, "DD-MM-YYYY");

                        const dateToCheck = moment(
                          day?.full_date,
                          "DD-MM-YYYY"
                        );
                        const isBetween = dateToCheck.isBetween(
                          startDate,
                          endDate,
                          null,
                          "[]"
                        );
                        const dName = `${title} ${firstname} ${lastname}`;

                        if (
                          (searchText === "" ||
                            dName
                              .toLowerCase()
                              .includes(searchText.toLowerCase())) &&
                          (role_type === "" || role_type === role)
                        ) {
                          if (isBetween) {
                            let newObj = {
                              staff_id: staff_id,
                              staff_name: dName,
                              staff_image: user_image,
                              isWeekOff: false,
                              start_time: start_time,
                              end_time: end_time,
                              display_time: `${start_time} - ${end_time}`,
                            };
                            if (
                              isPublicHolidayOff &&
                              holidayList?.some(
                                (o) =>
                                  moment(
                                    o.date,
                                    "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                  ).format("DD-MM-YYYY") === day?.full_date
                              )
                            ) {
                              unAvailableList.push({
                                ...newObj,
                                isPublicHolidayOff: true,
                              });
                            } else {
                              if (isAvailable) {
                                if (
                                  isWeekendOff &&
                                  ["Saturday", "Sunday"].includes(
                                    dateToCheck.format("dddd")
                                  )
                                ) {
                                  unAvailableList.push({
                                    ...newObj,
                                    isWeekOff: true,
                                  });
                                } else {
                                  availableList.push(newObj);
                                }
                              } else {
                                unAvailableList.push(newObj);
                              }
                            }
                          }
                        }
                      });

                      let dAvailableList = uniqBy(availableList, "staff_id");
                      let dUnavailableList = uniqBy(
                        unAvailableList,
                        "staff_id"
                      );

                      let isAvailableFull =
                        dAvailableList?.length <= 8
                          ? true
                          : fullList?.some(
                              (o) =>
                                o.type === "available" &&
                                o.date === day?.full_date
                            );
                      let isUnavailableFull =
                        dUnavailableList?.length <= 8
                          ? true
                          : fullList?.some(
                              (o) =>
                                o.type === "unavailable" &&
                                o.date === day?.full_date
                            );

                      const isFullDayHoliday = holidayList?.some(
                        (o) => o.duration === "all-day" && o?.status === "close"
                      );
                      const isCurrentMonth =
                        +moment(selectedDate, "DD-MM-YYYY").format("M") ===
                        day?.month;
                      if (!isCurrentMonth) {
                        dAvailableList = [];
                        dUnavailableList = [];
                        holidayList = [];
                        fApBlockList = [];
                      }
                      return (
                        <td key={i2}>
                          <div
                            className={`${
                              isFullDayHoliday ? "h-100 d-flex flex-wrap" : ""
                            }${day?.month ? "" : "current-day-cell"}`}
                          >
                            <div
                              className={`day-no text-end position-absolute ${
                                isCurrentMonth ? "" : "disable"
                              } ${
                                day?.current_date &&
                                "current d-inline-flex align-items-center justify-content-center rounded-circle"
                              }`}
                            >
                              {day?.date}
                            </div>
                            {holidayList?.map((elm, hIndex) => {
                              return (
                                <div
                                  key={hIndex}
                                  style={{ backgroundColor: "#FDF3F3" }}
                                  className="br-6 p-1 w-100"
                                >
                                  <div className="text-[#DE2B2B] text-13-400">
                                    {elm?.duration === "custom"
                                      ? elm?.time
                                      : "All Day Holiday"}
                                  </div>
                                  <div className="text-12-400 color-2E65 mt-1">
                                    {elm?.memo || ""}
                                  </div>
                                </div>
                              );
                            })}
                            {!isFullDayHoliday && (
                              <>
                                {dAvailableList?.length > 0 &&
                                  (block_type === "available" ||
                                    block_type === "") && (
                                    <div className="bg-[#E5FFF4] br-6 mt-1 p-1 mb-1 w-100">
                                      <div
                                        className={
                                          isAvailableFull ? "" : "d-flex gap-1"
                                        }
                                      >
                                        {dAvailableList?.map((ele, aindex) => {
                                          const { staff_name } = ele;
                                          const isHide = isAvailableFull
                                            ? false
                                            : aindex > 7;
                                          return (
                                            <div
                                              className={`d-flex align-items-center gap-1 mb-1 ${
                                                isHide ? "d-none" : ""
                                              }`}
                                              key={aindex}
                                            >
                                              <div className="d-flex">
                                                <FaUserCircle
                                                  size={20}
                                                  color="#788c9f"
                                                />
                                              </div>
                                              {isAvailableFull && (
                                                <div className="text-13-400 text-[#282E65] text-hide">
                                                  {staff_name}
                                                </div>
                                              )}
                                            </div>
                                          );
                                        })}
                                        {!isAvailableFull && (
                                          <div
                                            className="text-dark text-13-400 color-2E65 cursor-pointer"
                                            onClick={() => {
                                              setFullList((prev) => {
                                                return [
                                                  ...prev,
                                                  {
                                                    date: day?.full_date,
                                                    type: "available",
                                                  },
                                                ];
                                              });
                                            }}
                                          >
                                            +{dAvailableList?.length - 8} More
                                          </div>
                                        )}
                                      </div>
                                      <p className="text-13-400 text-[#11CA9D] mt-1">
                                        Available
                                      </p>
                                    </div>
                                  )}
                                {dUnavailableList?.length > 0 &&
                                  (block_type === "unavailable" ||
                                    block_type === "") && (
                                    <div className="bg-[#FDF3F3] br-6 mt-1 p-1 mb-1 w-100">
                                      <div
                                        className={
                                          isUnavailableFull
                                            ? ""
                                            : "d-flex gap-1"
                                        }
                                      >
                                        {dUnavailableList?.map(
                                          (ele, aindex) => {
                                            const { staff_name } = ele;
                                            const isHide = isUnavailableFull
                                              ? false
                                              : aindex > 7;
                                            return (
                                              <div key={aindex}>
                                                <div
                                                  className={`d-flex align-items-center gap-1 mb-1 ${
                                                    isHide ? "d-none" : ""
                                                  }`}
                                                >
                                                  <div className="d-flex">
                                                    <FaUserCircle
                                                      size={20}
                                                      color="#788c9f"
                                                    />
                                                  </div>
                                                  {isUnavailableFull && (
                                                    <div className="text-13-400 text-[#282E65] text-hide">
                                                      {staff_name}
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                        {!isUnavailableFull && (
                                          <div
                                            className="text-dark text-13-400 color-2E65 cursor-pointer"
                                            onClick={() => {
                                              setFullList((prev) => {
                                                return [
                                                  ...prev,
                                                  {
                                                    date: day?.full_date,
                                                    type: "unavailable",
                                                  },
                                                ];
                                              });
                                            }}
                                          >
                                            +{dUnavailableList?.length - 8} More
                                          </div>
                                        )}
                                      </div>
                                      <p className="text-13-400 text-[#DE2B2B] mt-1">
                                        Unavailable
                                      </p>
                                    </div>
                                  )}
                                {(block_type === "appointment-block" ||
                                  block_type === "") &&
                                  fApBlockList?.map((ele, fIndex) => {
                                    return (
                                      <div
                                        key={fIndex}
                                        className="br-6 p-1 w-100 mt-1"
                                        style={{ backgroundColor: "#F8E6FD" }}
                                      >
                                        <div className="text-13-400 color-2E65 text-hide">
                                          {ele.time}
                                        </div>
                                        <div className="d-flex align-items-center gap-1 mb-1">
                                          <div className="d-flex">
                                            <FaUserCircle
                                              size={20}
                                              color="#282E65"
                                            />
                                          </div>
                                          {isAvailableFull && (
                                            <div className="text-13-400 color-2E65">
                                              {ele.doctor_name}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}
                              </>
                            )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ClinicAvilability;
