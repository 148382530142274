import { toast } from "react-toastify"
import ApiClient from "../../api-client"
import { apiUrl, PORT } from "../../environment"
import { toasterConfig } from "../../utils/constants"

export const getKennels=(clinic_id,callback)=>{

    return (dispatch, getState)=>{
        const { user: { token, user } } = getState();


        ApiClient.get(`${apiUrl}${PORT}/kennel/get/${clinic_id}`, {}, token, dispatch, getState)
        .then((res)=>{
            console.log(res)
            if(res){

                dispatch({type:"GET_KENNELS", payload:res?.response_data})
                return callback(res?.status)
            }else{
                dispatch({ type: "GET_KENNELS", payload: [] })
                toast.error("Kennels are not fetched")
                return callback(res?.status)
            }
        })
    }
}

export const getAvailableKennels = (params, callback) => {

    return (dispatch, getState) => {
        const { user: { token, user } } = getState();

        ApiClient.get(`${apiUrl}${PORT}/kennel/get-available/`, params, token, dispatch, getState)
            .then((res) => {
                console.log(res)
                if (res?.status) {

                    dispatch({ type: "GET_KENNELS", payload: res?.response_data })
                    return callback(res?.status)
                } else {
                    dispatch({ type: "GET_KENNELS", payload: [] })

                    toast.error("Kennels are not fetched")
                    return callback(res?.status)
                }
            })
    }
}


export const addKennel= (data, callback) => {
    return (dispatch, getState) => {
      
      const { user: { token } } = getState();
      ApiClient.post(`${apiUrl}${PORT}/kennel/add`, data, token, dispatch, getState).then((response) => {
          
          if (!response) {
              return callback();
          }
          else if (!response && response.errors) {
              toast.error(response.errors.msg, toasterConfig);
              return callback(response);
          } else if (response) {
              toast.dismiss();
              toast.success(response.msg, toasterConfig);
              return callback(response);
          } else {
              //toast.error(response.message, toasterConfig);
              return callback();
          }
      });
  };
  };



  

export const updateKennel= (data, callback) => {
    return (dispatch, getState) => {
      
      const { user: { token } } = getState();
      ApiClient.post(`${apiUrl}${PORT}/kennel/update`, data, token, dispatch, getState).then((response) => {
          
          if (!response) {
              return callback();
          }
          else if (!response && response.errors) {
              toast.error(response.errors.msg, toasterConfig);
              return callback(response);
          } else if (response) {
              toast.dismiss();
              toast.success(response.msg, toasterConfig);
              return callback(response);
          } else {
              //toast.error(response.message, toasterConfig);
              return callback();
          }
      });
  };
  };
  

  



//export const addKennel = (body, callback) => {

