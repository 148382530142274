import React, { useState, useEffect } from "react";
import IOAddPoint from "./IOAddPoint";
import IOTableData from "./IOTableData";
import moment from "moment";
import "./index.scss";




const IOTable = ({ type = 'Intake', data, times, view = 'day', refresh }) => {


    const [popover, setPopover] = useState(false)
    const [popoverInfo, setPopoverInfo] = useState({})



    const findResFromTime = (test, time) => {
        let results = []
        for (let i = 0; i < test?.results?.length; i++) {

            if (moment(test?.results?.[i]?.date).isSame(time, view === 'day' ? 'hour' : 'day')) {
                results.push(test.results[i])
            }
        }
        return results;

    }

    useEffect(() => {
        setPopover(false)
        setPopoverInfo({})
    }, [data, times, view])




    return (
        <>
            <table className="io-table w-100 overflow-x-scroll" id="io-table">
                <thead>
                    <tr className="table-header first-col">
                        <th className="table-name">{type}</th>
                        {times?.map((header, i) => { return (<th key={i} className="time">{header.format(view === 'day' ? 'h A' : 'M/D/YYYY')}</th>) })}
                    </tr>
                </thead>
                <tbody>
                    {data?.map((cat, i) => {

                        return (
                            <>
                                <tr className="section-head">
                                    <td key={i}className="section-header first-col" style={{borderRightWidth: '0px'}}><div  >{cat?.name}</div></td>
                                    <td  colSpan={'100%'}  style={{borderLeftWidth: '0px'}} className="section-header"></td>
                                </tr>
                                {
                                    cat?.items?.map((item, t) => {
                                        return (
                                            <tr key={t}>
                                                <td className='test-name first-col' style={{borderRightWidth: '1px', borderCollapse: 'separate', borderSpacing: '0'}}>{item?.item?.name}</td>
                                                {times?.map((time, j) => {
                                                    let res = findResFromTime(item, time)
                                                    return (<IOTableData

                                                        key={j}
                                                        id={`table-data-${type}-${i}-${t}-${j}`}
                                                        results={res}
                                                        onClick={async () => {
                                                            await setPopover(false)
                                                            await setPopoverInfo({ index: `table-data-${type}-${i}-${t}-${j}`, results: res, item_id: item?.item?._id, time: time });
                                                            setPopover(true);
                                                        }
                                                        } />)
                                                }


                                                )}

                                            </tr>)
                                    }
                                    )
                                }
                            </>
                        )


                    }
                    )
                    }

                </tbody>
            </table>
            {popover &&
                <IOAddPoint view={view} refresh={refresh} results={popoverInfo?.results} item_id={popoverInfo?.item_id} time={popoverInfo?.time} index={popoverInfo?.index} popover={popover} popoverToggle={() => { setPopover(!popover) }} />
            }

        </>
    )
}

export default IOTable;