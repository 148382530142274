import React from "react";
import './index.scss';

const StaffProfileFeature = ({ staffMemberDetails }) => {


	return (
		<>
			<ul className="staff-profile-feature list-unstyled">
				<li className="d-flex align-items-center">
					<span className="icomoon-medical-verified"></span>
					{staffMemberDetails?.verification_details ? staffMemberDetails?.verification_details : "-"}
				</li>
				<li className="d-flex align-items-center">
					<span className="icomoon-thumb-up"></span>
					{staffMemberDetails?.success_rate ? `${staffMemberDetails?.success_rate}% Success Stories (${staffMemberDetails?.vote_rate}+ Votes)` : "-"}
				</li>
				<li className="d-flex align-items-center">
					<span className="icomoon-certificate"></span>
					{staffMemberDetails?.verification_details ? staffMemberDetails?.verification_details : "-"}
				</li>
			</ul>
		</>
	);
};

export default StaffProfileFeature;
