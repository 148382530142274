import React, { useEffect, useState } from "react";
import { Button, Collapse, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import "./index.scss";

const Accordions = ({ data, dates }) => {



	const [loadIndex, setLoadIndex] = useState(0);
	const [elemIndex, setElemIndex] = useState(null);
	const [toggle, setToggle] = useState(false);
	const dispatch = useDispatch();

	const toggleFunction = (index) => {
		if (loadIndex === index) {
			setLoadIndex(null);
		} else if (elemIndex === index) {
			setToggle(!toggle);
		} else {
			setToggle(true);
		}
		setElemIndex(index);
		setLoadIndex(null);
	}

	const data_from_date = (data_list, date) => {

		for (let item of data_list) {
			if (item?.date == date) {
				return item
			}
		}
		return null;

	}



	return (
		<>
			<div className="diagnostic-accordion-wrapper">
				{data?.map((item, i) => {
					return (
						<div className="accordion-blc d-flex flex-column">
							<div className="accordion-btn-wrapper d-flex align-items-center">
								<Button color="link" className={`accordion-btn text-start shadow-none text-left d-flex align-items-center ${(loadIndex === i) || (i === elemIndex && toggle) ? 'active' : ''}`} onClick={() => toggleFunction(i)}>
									<span className="material-icons icon-btn">
										{(loadIndex === i) || (i === elemIndex && toggle) ? 'expand_less' : 'expand_more'}
									</span>
									<label>{item.category.category}</label>
								</Button>

							</div>
							<Collapse isOpen={(loadIndex === i) || (i === elemIndex && toggle)}>
								<div className="collapse-data-blc">
									<Table responsive striped>

										<thead>
											<tr>
												<th>
													Test Name
												</th>
												<th className="reange-cell">
													Range
												</th>
												{dates?.map((date, p) => {
													date = moment(date?._id);
													return (
														<th key={p}>
															{`${date?.format('MM/DD/YYYY')}`}<br/>{`${date?.format('hh:mm A')}`}

														</th>)
												})}

											</tr>
										</thead>


										<tbody>
											{item?.tests?.map((test, j) => {
												return (
													<>
														<tr key={j}>
															<td>{test?.test?.test_name}</td>
															{test?.test?.min_value !== null ?
																<td>{`${test?.test?.min_value}-${test?.test?.max_value}`}</td> :
																<td>--</td>
															}
															{dates.map((date, d) => {

																let result = data_from_date(test?.results, date?._id);


																return (

																	result !== null ? test?.test?.min_value !== null ?
																		parseFloat(result?.result) >= parseFloat(test?.test?.min_value) && parseFloat(result?.result) <= parseFloat(test.test.max_value) ?
																			<td key={d}>{result?.result}</td> :
																			parseFloat(result?.result) < parseFloat(test?.test?.min_value) ?
																				<td className="low-val">{result?.result} L</td> :
																				<td className="high-val">{result?.result} H</td>
																		:
																		<td key={d}>{result?.result}</td>

																		:
																		<td key={d}>--</td>
																)
															})}
														</tr>
													</>

												)
											})}


										</tbody>
									</Table>
								</div>
							</Collapse>
						</div>)
				})}

			</div>
		</>
	);
}
export default Accordions;