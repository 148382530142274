import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from 'reactstrap';
import './index.scss';
import { appointmentTypes } from "../../../../../utils/constants";



const AppointmentTypeTasks = ({ setSelectedType,  setPanelState}) => {


    const handleSelect = (type) => {
        setSelectedType(type);
        setPanelState(true)


    }

    return (
        <>
            <div className="waiting-room-list">
                { appointmentTypes.map((apptType, i) => (
                            <div className="item-row d-flex">
                                <div className="left-col">
                                    <div className="room-info">
                                        <h4>{apptType.label}</h4>
                                    </div>
                                </div>
                                <div className="right-col d-inline-flex align-items-center">
                                    <Button onClick={() => handleSelect(apptType)} color="link" className="d-inline-flex align-items-center justify-content-center">
                                        <span className="icomoon-pencil"></span>
                                    </Button>
                                </div>
                            </div>
                        ))}

            </div>
        </>
    );


    
};

export default AppointmentTypeTasks;
