import Logout from 'components/pet-owner-dashboard/Logout'
import React from 'react'

const NoAccess = () => {
  return (
      <div className='w-full h-full'>
          <div className=' my-auto flex items-center flex-column justify-center'>
              <p>Your Role is inactive by Clinic</p>
              <div className='position-relative h-[50px] w-[130px]'>
                  <Logout />
                  
              </div>
          </div>
          
    </div>
  )
}

export default NoAccess