import React from "react";
import { useSelector } from "react-redux";
import { Input } from "reactstrap";
import { petGenderValues } from "../../../../utils/constants";
import './index.scss';
import { useClinicSpecies } from "hooks/useClinicSpecies";

const ListingFilter = ({ fieldVal, onSelectGender, onSelectSpecies, onSelectCity, }) => {
	const { cities, species } = useSelector(state => state.appContentReducer)
	const clinicSpecies = useClinicSpecies()

	return (
		<>
			<div className="listing-filter-blc d-flex align-items-center overflow-auto flex-shrink-0">
				<div className="search-cell col-cell position-relative">
					<span className="icomoon-search d-inline-flex align-items-center justify-content-center position-absolute"></span>
					<Input placeholder="Search" onChange={(e) => fieldVal(e.target.value)} />
				</div>
				<div className="input-cell col-cell">
					<Input type="select" name="select" onChange={onSelectSpecies}>
						<option value="">All Pets</option>
						{(species || []).map((item, i) => <option key={i} value={item?._id}>
							{clinicSpecies(item?._id) || item?.species_name}</option>)}
					</Input>
				</div>
				<div className="input-cell col-cell">
					<Input type="select" name="select" onChange={onSelectGender}>
						<option value="">All Gender</option>
						{petGenderValues.map((item, i) => <option key={i} value={item.value}>{item.label}</option>)}
					</Input>
				</div>
				<div className="input-cell col-cell">
					<Input type="select" name="select" onChange={onSelectCity}>
						<option value="">All Cities</option>
						{(cities || []).map((item, i) => <option key={i} value={item?.city}>{item?.city}</option>)}
					</Input>
				</div>
			</div>
		</>
	);
};

export default ListingFilter;
