import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAndFilterDiagnosticResults } from "context/actions/patient-module/diagnostic-testing";
import NodataFoundBlock from "shared/NoDataFoundBlock";
import Accordions from "../../../patient-module/PatientDetail/TabScreens/DiagnosticTesting/Accordions";
import CalendarFilter from "shared/Calendar/CalendarFilter";
import moment from "moment";
import { Collapse } from "reactstrap";
import { Spinner } from "react-bootstrap";
import { getAllReportsByPetId } from "context/actions/patient-module/overview/report";
import ReportItem from "components/patient-module/PatientDetail/TabScreens/Overview/ReportsBlock/ReportItem";
import "./TestResults.scss";

const TestResults = () => {
  const dispatch = useDispatch();
  const { reports } = useSelector((state) => state.reportReducer);
  const { petDetails } = useSelector((state) => state.petOwnerDashboardReducer);
  const { testResults } = useSelector(
    (state) => state.diagnosticTestingReducer
  );
  const [isReport, setIsReport] = useState(true);
  const [calendarViewData, setCalendarViewData] = useState({
    span_date: new Date().getDate(),
    date_value: new Date().getDate(),
    month_value: new Date().getMonth(),
    span_month: new Date().getMonth(),
    year_value: new Date().getFullYear(),
    view_type: "month",
    searchText: "",
  });
  const [pageLoader, setPageLoader] = useState(true);

  const getPageData = async (filterData) => {
    const {
      view_type,
      span_date,
      span_month,
      date_value,
      month_value,
      year_value,
      searchText,
    } = filterData;
    let startDate = "";
    let endDate = "";
    if (view_type === "day") {
      let dayDate = `${date_value}-${month_value}-${year_value}`;
      startDate = dayDate;
      endDate = dayDate;
    } else if (view_type === "week") {
      startDate = `${span_date}-${span_month}-${year_value}`;
      endDate = `${date_value}-${month_value}-${year_value}`;
    } else if (view_type === "month") {
      const firstDate = `1-${month_value + 1}-${year_value}`;
      const lastdate = moment(firstDate, "D-M-YYYY")
        .endOf("month")
        .format("DD-MM-YYYY");
      startDate = firstDate;
      endDate = lastdate;
    } else {
      // Nothing
    }
    dispatch(
      getAndFilterDiagnosticResults(
        {
          pet_id: petDetails?._id,
          startDate: moment(startDate, "D-M-YYYY").format(
            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
          ),
          endDate: moment(endDate, "D-M-YYYY").format(
            "YYYY-MM-DDT24:mm:ss.SSS[Z]"
          ),
          search: searchText,
        },
        () => {
          setPageLoader(false);
        }
      )
    );

    dispatch(
      getAllReportsByPetId(
        petDetails?._id,
        {
          reportDate: moment(startDate, "D-M-YYYY").format(
            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
          ),
          endDate: moment(endDate, "D-M-YYYY").format(
            "YYYY-MM-DDT24:mm:ss.SSS[Z]"
          ),
        },
        () => {}
      )
    );
  };

  useEffect(() => {
    getPageData(calendarViewData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarViewData]);

  useEffect(() => {
    setPageLoader(true);
    // dispatch(getAllReportsByPetId(petDetails?._id, {}, () => {}));
    getPageData(calendarViewData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petDetails]);
  return (
    <div id="test-results-container">
      {pageLoader ? (
        <div className="f-center h-50">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <CalendarFilter
            isHideDropdown
            searchText={calendarViewData.searchText}
            onSearch={(e) => {
              setCalendarViewData((prev) => {
                return { ...prev, searchText: e.target.value };
              });
            }}
            sendCalendarView={() => {}}
            calendarViewValue={(value) =>
              setCalendarViewData({ ...calendarViewData, ...value })
            }
          />

          {!reports?.[0]?._id && testResults?.data?.length === 0 ? (
            <NodataFoundBlock type="medical history" />
          ) : (
            <div>
              {reports?.length > 0 && (
                <div className="bg-F8F8 mb-2">
                  <div
                    className="fa-center cursor-pointer gap-1 pt-2 pb-2 bb-e3e3 mb-1"
                    onClick={() => {
                      setIsReport(!isReport);
                    }}
                  >
                    <span className="material-icons icon-btn">
                      {isReport ? "expand_less" : "expand_more"}
                    </span>
                    <span className="text-18-600 color-2E65">
                      X-ray Reports
                    </span>
                  </div>
                  <Collapse isOpen={isReport}>
                    {reports?.length > 0 && (
                      <div className="report-items-rw mb-3">
                        <div className="item-col-wrapper d-flex ">
                          {reports?.map((item, index) => {
                            return (
                              <div className="item-col" key={index}>
                                <ReportItem item={item} isHideMsgInput />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </Collapse>
                </div>
              )}
              <Accordions
                isHideAdd
                data={testResults?.data}
                dates={testResults?.dates}
                openPanel={() => {
                  // setPanelState(true);
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TestResults;
