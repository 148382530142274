const initState = {
    loading: false,
    notes: []
    
}
    

export const notesReducer = (state = initState, action) => {
    switch (action.type) {
        case "NOTES_LOADING":
            return { ...state, loading: action.payload }

        case "NOTES":
            return { ...state, notes: action.payload }
    
        default:
            return state
    }
}
