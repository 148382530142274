import * as Yup from "yup";
/**
 * @description declaration form schema for client validations
 */

export const vaccineScheduleSchema = Yup.object().shape({
  type: Yup.string().required(),
  species: Yup.string().required(),
  items: Yup.array().of(
    Yup.object().shape({
      doc: Yup.string(),
      docModel: Yup.string(),
      durations: Yup.object()      
    })
  ),
  durations: Yup.array().of(
    Yup.object().shape({
      _id: Yup.string(),
      type: Yup.string().required(),
      unit: Yup.string().required(),
      value: Yup.number().required(), 
      name: Yup.string()
    })
  ),
});

