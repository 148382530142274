import moment from "moment";
import { PiCalendarCheckLight } from "react-icons/pi";
import { RiMapPinAddLine } from "react-icons/ri";
import "./AppointmentsList.scss";
import { setAppointmentDetailData } from "context/actions/appointmentDetailPanelAction";
import { useDispatch } from "react-redux";

const AppointmentsList = ({ data = {}, setId }) => {
  const dispatch = useDispatch();
  const {
    _id,
    doctor_id,
    clinic,
    appointment_date,
    appointment_timings,
    reason,
    description,
    temperatures,
    weight,
    heart_rate,
    isAppointmentAproved,
    appointment_type,
    appointment_start,
    appointment_end,
  } = data;
  const temperatureValue =
    temperatures?.find((o) => o?.temperatureValue)?.temperatureValue || "";
  const weightValue = weight?.find((o) => o?.weightValue)?.weightValue || "";
  const heartRateValue =
    heart_rate?.find((o) => o?.heartRateValue)?.heartRateValue || "";

  return (
    <div id="appointments-list-container">
      <div className="item-block">
        <div className="left-block">
          <div className="d-flex gap-2">
            <div className="f-center bg-eeee color-cfcf h-49 w-49 rounded-circle">
              {doctor_id?.user_image ? (
                <img src={doctor_id?.user_image} alt="badge-img" />
              ) : (
                <span className="material-symbols-outlined text-24-600">
                  person
                </span>
              )}
            </div>
            <div className="flex-grow-1">
              <div className="text-16-400 color-2E65">
                {doctor_id?.title} {doctor_id?.firstname} {doctor_id?.lastname}
              </div>
              <div className="text-13-400 color-2E65">DVM</div>
            </div>
          </div>
          <div className="d-flex gap-2 mt-2">
            <div className="calendar-icon">
              <PiCalendarCheckLight />
            </div>
            <div className="d-flex align-items-start gap-2 text-14-400 color-2E65">
              <div>
                {moment(appointment_date, "DD-MM-YYYY").format("MMM D, YYYY")}
              </div>
              <div className="line"></div>
              <div>{appointment_timings?.[0]?.appointment_time}</div>
            </div>
          </div>
          <div className="fa-center gap-2 mt-2">
            <div>
              <RiMapPinAddLine />
            </div>
            <div className="ext-14-400 color-2E65">{clinic}</div>
          </div>
        </div>
        <div className="flex-grow-1 ps-2 d-flex flex-column">
          <div className="fb-center">
            <div className="text-16-500 color-2E65">{reason}</div>
            {isAppointmentAproved === 1 && (
              <div className="d-flex justify-content-end gap-2 appo-note-buttons">
                {/* <div className="text-13-400 color-2E65 cursor-pointer">
                  Add Notes
                </div> */}
                {/* <div className="line" /> */}
                <div
                  className="text-13-400 color-2E65 cursor-pointer"
                  onClick={() => {
                    setId(_id);
                    dispatch(setAppointmentDetailData(data));
                  }}
                >
                  View Details
                </div>
              </div>
            )}
          </div>
          <div className="text-14-400 color-2E65 mt-2">{description}</div>
          <div className="d-flex align-items-end flex-grow-1 gap-3 mt-2">
            {temperatureValue && (
              <div className="b-e3e3 rounded text-14-400 color-2E65 ps-2 pe-2 pt-1 pb-1">{`Temp. : ${temperatureValue} F`}</div>
            )}
            {weightValue && (
              <div className="b-e3e3 rounded text-14-400 color-2E65 ps-2 pe-2 pt-1 pb-1">{`Weight : ${weightValue} lbs`}</div>
            )}
            {heartRateValue && (
              <div className="b-e3e3 rounded text-14-400 color-2E65 ps-2 pe-2 pt-1 pb-1">{`Heart Rate : ${heartRateValue} bpm`}</div>
            )}
            {appointment_start &&
              (appointment_end ? (
                <div className="b-e3e3 rounded text-14-400 color-2E65 ps-2 pe-2 pt-1 pb-1 completed-appointment">
                  {appointment_type === "surgery" ? "Surgery" : "Appointment"}{" "}
                  Completed
                </div>
              ) : (
                <div className="b-e3e3 rounded text-14-400 color-2E65 ps-2 pe-2 pt-1 pb-1 in-progress-appointment">
                  {appointment_type === "surgery" ? "Surgery" : "Appointment"}{" "}
                  In Progress
                </div>
              ))}
            {isAppointmentAproved === 0 && (
              <div className="b-e3e3 rounded text-14-400 color-2E65 ps-2 pe-2 pt-1 pb-1 in-pending-appointment w-210 text-center">
                Pending
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentsList;
