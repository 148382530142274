import React, { useState } from "react";
import PetSelection from "./PetSelection";
import OwnerSelection from "./OwnerSelection";
import { Modal, Spinner } from "react-bootstrap";
import { Button } from "reactstrap";
import { MdOutlinePets } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { changePetOwnerWithPet } from "context/actions/patient-module/patient/pet";
import "./ChangePetOwner.scss";

const ChangePetOwner = ({ onHide, handleSuccess }) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.user?.user_details);
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const [patient, setPatient] = useState(null);
  const [owner, setOwner] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const handleSave = async () => {
    setBtnLoading(true);
    const formData = new FormData();
    formData.append("pet_id", patient?._id);
    formData.append("owner_id", owner?._id || "");
    formData.append("firstname", owner?.firstname || "");
    formData.append("lastname", owner?.lastname || "");
    formData.append("email", owner?.email || "");
    formData.append("phone", owner?.phone || "");
    formData.append("address", owner?.address || "");
    formData.append("pet_owner_image", owner?.imageFile || "");
    formData.append("clinic", clinic_details?._id);
    formData.append("added_by", userDetails?._id);
    const response = await dispatch(changePetOwnerWithPet(formData));
    if (response?.status) {
      handleSuccess();
    }
    setBtnLoading(false);
  };
  let newUserImage = "";
  if (owner) {
    newUserImage = owner?._id ? owner?.user_image : owner?.imageUrl;
  }

  return (
    <div>
      {owner ? (
        <Modal
          show
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => {
            setPatient(null);
            setOwner(null);
            onHide();
          }}
        >
          <Modal.Body>
            <h4 className="text-center text-26-600 color-2E65 mb-5 pt-3">
              Are you sure?
            </h4>
            <p className="fa-center justify-content-center gap-2 text-16-400 color-2E65">
              <div>You want to change</div>
              <div className="fa-center gap-1">
                {patient?.pet_image ? (
                  <span>
                    <img
                      src={patient?.pet_image}
                      alt="pet_image"
                      className="br-4 h-20"
                    />
                  </span>
                ) : (
                  <span className="br-4 h-20 w-20 f-center bg-e3e3">
                    <MdOutlinePets size={15} />
                  </span>
                )}
                <span className="text-16-600 color-2E65">
                  {patient?.pet_name}'s
                </span>
              </div>
              <div> owner from</div>
              <div className="fa-center gap-1">
                {patient?.pet_owner?.user_image ? (
                  <span>
                    <img
                      src={patient?.pet_owner?.user_image}
                      alt="user_image"
                      className="h-20 rounded-circle"
                    />
                  </span>
                ) : (
                  <span className="h-20 w-20 f-center bg-e3e3 rounded-circle">
                    <FaUser size={10} />
                  </span>
                )}
                <span className="text-16-600 color-2E65">
                  {`${patient?.pet_owner?.firstname} ${patient?.pet_owner?.lastname}`}
                </span>
              </div>
              <div>to</div>
            </p>
            <p className="fa-center justify-content-center gap-2 text-16-400 color-2E65 mt-2">
              {owner?._id ? "existing owner" : "newly created owner"}
              <div className="fa-center gap-1">
                {newUserImage ? (
                  <span>
                    <img
                      src={newUserImage}
                      alt="user_image"
                      className="h-20 rounded-circle"
                    />
                  </span>
                ) : (
                  <span className="h-20 w-20 f-center bg-e3e3 rounded-circle">
                    <FaUser size={10} />
                  </span>
                )}
                <span className="text-16-600 color-2E65">{`${owner?.firstname} ${owner?.lastname}`}</span>
              </div>
            </p>
            <div className="d-flex justify-content-center gap-3 mt-5 pb-3">
              <Button
                color="link"
                onClick={() => {
                  onHide();
                  setPatient(null);
                  setOwner(null);
                }}
                className="btn btn-outline-light cps-40 cpe-40"
              >
                No
              </Button>
              <Button
                color="success"
                type="button"
                className="btn btn-outline-light cps-40 cpe-40"
                onClick={handleSave}
              >
                Yes {btnLoading && <Spinner size="sm" className="ms-2" />}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      ) : patient ? (
        <OwnerSelection
          petOwnerId={patient?.pet_owner?._id}
          setOwner={setOwner}
          onHide={() => {
            setPatient(null);
            setOwner(null);
            onHide();
          }}
        />
      ) : (
        <PetSelection
          setPatient={setPatient}
          onHide={() => {
            setPatient(null);
            setOwner(null);
            onHide();
          }}
        />
      )}
    </div>
  );
};

export default ChangePetOwner;
