import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
} from "reactstrap";
import NoteBreifBox from "./NoteBreifBox";
import NoteDetail from "./NoteDetail";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { getNotesByPetId } from "../../../../../context/actions/patient-module/notes";
import NewNotePanel from "./NewNotePanel";
import "./index.scss";
import moment from "moment";
import ScheduleEdit from "./ScheduleEdit/ScheduleEdit";
import Spinner from "shared/Spinner";
import PetPlaceholder from "shared/PetPlaceholder";
import NodataFoundBlock from "shared/NoDataFoundBlock";

const Notes = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  const [extend, setExtend] = useState(null);
  const { notes, loading } = useSelector((state) => state?.notesReducer);
  const petId = params.petId;
  const [searchText, setSearchText] = useState("");
  const handleGetNotes = () => {
    dispatch(getNotesByPetId(petId, () => {}));
  };
  useEffect(() => {
    if (petId) {
      handleGetNotes();
    }
  }, [petId]);

  const [activeTab, setActiveTab] = useState("overview");
  const [notesactiveTab, setNotesactiveTab] = useState(0);

  const toggle = (tab) => {
    if (notesactiveTab !== tab) {
      setNotesactiveTab(tab);
      setActiveTab("overview");
    }
  };
  const [newNote, setNewNote] = useState(false);
  const [scheduleEditNotes, setScheduleEditNotes] = useState(false);
  const [scheduleEditDetails, setScheduleEditDetails] = useState();

  const scheduleEditHistoryList = notes?.[notesactiveTab]?.editHistory || [];
  // console.log(notes);
  return (
    <>
      <div className="notes-block d-flex h-100 relative">
        <div className="left-col d-flex flex-column overflow-auto">
          <div className="note-head d-flex flex-column">
            <div className="search-cell position-relative">
              <Input
                type="text"
                placeholder="Search"
                onChange={(e) => setSearchText(e?.target?.value)}
              />
              <span className="position-absolute d-inline-flex align-items-center justify-content-center mb-0">
                <span className="material-symbols-outlined mb-0">search</span>
              </span>
            </div>
            {permissions?.add_patient_notes && (
              <Button
                onClick={() => {
                  setNewNote(true);
                  setExtend(null);
                }}
                color="link"
                style={{marginTop: '10px'}}
                className="add-note-btn d-flex justify-content-center"
              >
                <span className="icomoon-plus-btn"></span>
              </Button>
            )}
          </div>
          {loading ? <div className="mx-auto">
            <Spinner height={150} /></div> : <div className="note-list-wrapper overflow-auto">
            <Nav tabs className="flex-column overflow-visible">
              {notes
                ?.filter(
                  (item) =>
                    item?.appointment?.reason
                      ?.toLowerCase()
                      .indexOf(searchText?.toLowerCase()) !== -1
                )
                ?.map((note, i) => (
                  <>
                    <NavItem>
                      <NavLink
                        active={notesactiveTab === i}
                        className={"w-100"}
                        onClick={() => {
                          toggle(i);
                        }}
                      >
                        <NoteBreifBox className="w-100" note={note} />
                      </NavLink>
                    </NavItem>
                  </>
                ))}
            </Nav>
          </div>}
        </div>
        <div className="right-col flex-fill overflow-auto ml-2">
          <div className="note-tab-wrapper">
            <Nav tabs className="flex-shrink-0">
              <NavItem>
                <NavLink
                  className={{ active: activeTab === "overview" }}
                  onClick={() => {
                    setActiveTab("overview");
                  }}
                >
                  Overview
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={{ active: activeTab === "schedule" }}
                  onClick={() => {
                    setActiveTab("schedule");
                  }}
                >
                  Edit History
                </NavLink>
              </NavItem>
            </Nav>

            {activeTab === "overview" && (
              <>
                {(notes) && notes?.length > 0 ?
                <>
                {notes?.map((note, i) => (
                  <TabContent
                    activeTab={notesactiveTab}
                    className="flex-fill overflow-auto position-relative"
                  >
                    <TabPane tabId={i} className="h-100">
                      <NoteDetail
                        build={() => {
                          setExtend(note);
                          setNewNote(true);
                        }}
                        sections={note?.sections}
                        note={note}
                        extend={note?.extends}
                      />
                    </TabPane>
                  </TabContent>
                ))}
                </> :
                <>
                <NodataFoundBlock />
                </>
                }
              </>
            )}
            {activeTab === "schedule" && (
              scheduleEditHistoryList && scheduleEditHistoryList?.length > 0 ? 
              <Table className="w-100" striped>
                <thead>
                  <tr>
                    <th>Modified By</th>
                    <th>Modified On</th>
                    <th>Doctor Notes</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  
                  <>
                  {scheduleEditHistoryList?.map((item, i) => (
                    <>
                      <tr key={i}>
                        <td>
                          <div className="d-flex flex-row">
                         
                              {item?.modify_by_user?.user_image ? (
                                <img
                                  src={item?.modify_by_user?.user_image}
                                  alt=""
                                  style={{height: '20px', width: '20px'}}
                                />
                              ) : (
                                <PetPlaceholder />
                              )}
                          
                              {item?.modify_by_user.firstname}{" "}
                              {item?.modify_by_user.lastname}
                            </div>
                          
                        </td>
                        <td>
                          {moment(item?.date).format("MM/DD/YYYY hh:mm a")}
                        </td>
                        <td>{item?.section[0]?.title}</td>
                        <td
                          className="cursor-pointer"
                          style={{ color: "#3D3EFD" }}
                          onClick={() => {
                            setScheduleEditNotes(true);
                            setExtend(null);
                            setScheduleEditDetails(item);
                          }}
                        >
                          View
                        </td>
                      </tr>
                    </>
                  ))}
                  </> 
                </tbody>
              </Table> :
               <NodataFoundBlock /> 
            )}
          </div>
        </div>
      </div>
      {(newNote || scheduleEditNotes) && (
        <>
          <div
            className={` fixed top-0 right-0 bottom-0 left-0 z-[9] bg-black/20 transition-all duration-300 ease-in `}
          ></div>
        </>
      )}
      {/* notes modal backdrop end */}

      {/* notes Panel to add notes */}
      <NewNotePanel
        isActive={newNote}
        toggleNotePanel={() => setNewNote(!newNote)}
        extend={extend}
        closingFn={() => {
          if (extend) {
            setExtend(null);
          }
        }}
      />
      {/* notes Panel to add notes*/}

      {/* ScheduleEdit to add notes*/}
      <ScheduleEdit
        isActive={scheduleEditNotes}
        toggleNotePanel={() => setScheduleEditNotes(!scheduleEditNotes)}
        extend={extend}
        scheduleEditDetails={scheduleEditDetails}
        closingFn={() => {
          if (extend) {
            setExtend(null);
          }
        }}
      />
    </>
  );
};

export default React.memo(Notes);
