import React, { useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { addNewSupplier } from "../../context/actions/inventory";
import SupplierInfo from "./SupplierInfo";
import { object, string } from "yup";
let supplierInfoSchema = object({
  supplier_name: string().required(),
  order_type: string().required(),
  po_number: string().required(),
  description: string().optional(),
});
const AddSupplier = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.user_details);
  const history = useHistory();
	const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);


  const formik = useFormik({
    initialValues: {
      // clinic_id: userDetails?._id,
      clinic_id: clinic_settings.clinic_id,
      supplier_name: "",
      order_type: "",
      po_number: "",
      description: "",
    },
    validationSchema: supplierInfoSchema,
    onSubmit: (values) => {
      const updatedFormData = {
        ...values,
        // clinic_id: userDetails?._id,
        clinic_id: clinic_settings.clinic_id,
      };
      console.log("supplier data: ", updatedFormData);
      dispatch(
        addNewSupplier(updatedFormData, (res) => {
          if (res.status) {
            console.log("Supplier added");
            history.push("/inventory");
          }
        })
      );
    },
  });
  return (
    <div className="px-3 py-1 space-y-3 ">
      <header className="flex items-center justify-between py-2 ">
        <button
          onClick={() => {
            history.goBack();
          }}
          className="inline-flex items-center text-sm md:text-base uppercase gap-x-2 text-blue-500 tracking-tighter hover:underline underline-offset-2"
        >
          <FontAwesomeIcon icon={faChevronLeft} fontSize={23} />
          Back to List
        </button>
        <div className="inline-flex gap-x-2 items-center">
          <button className="w-24 uppercase md:w-32 py-2  text-sm md:text-base border duration-300 transition-colors hover:bg-gray-50 rounded-lg">
            Cancel
          </button>
          <button
            type="button"
            onClick={formik.handleSubmit}
            className="w-24 uppercase md:w-32 py-2  text-sm md:text-base drop-shadow bg-[#3D3EFD] duration-300 text-white transition-colors hover:bg-blue-600 rounded-lg"
          >
            Save
          </button>
        </div>
      </header>
      <section className="flex flex-col md:flex-row gap-5 ">
        <div className="w-full md:w-1/4 space-y-3">
          <h3 className="text-xl">
            Create New <br /> Supplier
          </h3>

          <ol className="relative text-gray-500 ml-4">
            <li className="mb-10 ml-8 space-y-1">
              <span className="absolute flex items-center justify-center w-10 h-10 bg-[#282E65] text-white rounded-full -left-[22px]">
                1
              </span>
              <h3 className="font-medium leading-tight text-gray-800">
                Supplier Information
              </h3>
              <p className="text-sm">Add pet and client information</p>
            </li>
          </ol>
        </div>
        <div className="w-full md:w-3/4 space-y-[2px]">
          <div className="w-full bg-[#F8F8F8E5] rounded-t-lg px-3 py-2 space-y-3">
            <SupplierInfo formik={formik} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default AddSupplier;
