import React, {useState} from "react";
import Accordions from "./Accordions";
import SpeciesAgePreferences from "../../clinic-module/clinic-settings/Preferences/SpeciesAgePreferences";
import EditSpeciesAges from "../../clinic-module/clinic-settings/Preferences/EditSpeciesAges";
import "./index.scss"
const VaccineSchedule = () => {


    const [selectedSpecies, setSelectedSpecies] = useState(null);

    const [editAges, setEditAges] = useState(false);

  return (
    <>
            <div style={{gap: '2px', display:'flex', flexDirection:'column'}}>
            <Accordions />

            <div className="tab-wrapper tab-wrapper-setting">
                <div className="tab-content">
                  <div className="tab-row d-flex">
                    <div className="tab-col lft">
                      <div className="info-block">
                        <h3>Species Age Preferences</h3>
                      </div>
                    </div>
                    <div className="tab-col flex-fill">
                      <div className="inner0">
                        <SpeciesAgePreferences
                          setPanelState={setEditAges}
                          setSelectedSpecies={setSelectedSpecies}
                        />
                      </div>
                    </div>
                  </div>

              </div>
            </div>
            </div>

            {editAges && (

              <>

            <div className={`new-point-panel-overlay position-fixed ${editAges ? 'active' : ''}`}></div>

            <EditSpeciesAges
                closeNewPointPanel={() => {
                  setEditAges(false);
                  setSelectedSpecies(null);
                }}
                species={selectedSpecies}
                className={`${editAges ? "active" : ""}`}
            />
            </>
      )}

    </>
  );
};

export default VaccineSchedule;
