import React, { useState } from "react";
import { sidebarPanelAction } from "../../context/actions/sidebarPanelAction";
// import { faSignOut } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faStar } from "@fortawesome/free-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "reactstrap";
// import { logout } from "../../context/actions/user";
import AppLogo from "../AppLogo"
import AppLogo2 from "../AppLogo2";
import LoaderUI from "../loader";
import SuperAdminProfile from "./SuperAdminProfile";
import { logout } from "../../context/actions/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";

const SuperAdminSidebar = ({ className = "" }) => {
  const userDetails = useSelector((state) => state.user.user_details);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [loader, showLoader] = useState(false);

  const handleLogout = () => {
    showLoader(true);
    dispatch(
      logout(() => {
        showLoader(false);
        history.push("/login");
      })
    );
  };

  const sidebarPanelState = useSelector(
    (state) => state.sidebarPanelReducer.isSidePanel
  );
  const closeSidePanel = () => {
    dispatch(sidebarPanelAction(false));
  };
  return (
    <>
      <div
        className={`sidebar-overlayer ${sidebarPanelState ? "active" : ""}`}
        onClick={closeSidePanel}
      ></div>
      {loader && <LoaderUI overlay />}

      <aside
        className={`sidebar d-flex flex-column overflow-auto  ${className}
				${sidebarPanelState ? "active" : ""}`}
      >
        <AppLogo2 />
        <div className="inner-wrapper">
          <div className="sidebar-menu">
            <ul className="list-unstyled">
              {/* <li
                className={
                  location.pathname === "/super-admin-dashboard" ? "active" : ""
                }
              >
                <Button
                  onClick={() => {
                    history.push("/super-admin/dashboard");
                    dispatch(sidebarPanelAction(false));
                  }}
                  color="link"
                  className="d-flex align-items-center text-start w-100"
                >
                  
                  <span className="material-icons">dashboard</span>
                  <label>Dashboard</label>
                </Button>
              </li> */}
              <li
                className={location.pathname === "/super-admin/clinic" ? "active" : ""}
              >
                <Button
                  onClick={() => {
                    history.push("/super-admin/clinic");
                  }}
                  color="link"
                  className="d-flex align-items-center text-start w-100"
                >
                  <span>
                    <svg
                      width="18"
                      height="20"
                      viewBox="0 0 18 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.37492 12.7083V13.7917C7.37492 14.243 7.5329 14.6267 7.84888 14.9427C8.16485 15.2587 8.54853 15.4167 8.99992 15.4167C9.45131 15.4167 9.83499 15.2587 10.151 14.9427C10.4669 14.6267 10.6249 14.243 10.6249 13.7917V12.7083H11.7083C12.1596 12.7083 12.5433 12.5503 12.8593 12.2344C13.1753 11.9184 13.3333 11.5347 13.3333 11.0833C13.3333 10.6319 13.1753 10.2483 12.8593 9.93228C12.5433 9.61631 12.1596 9.45832 11.7083 9.45832H10.6249V8.37499C10.6249 7.9236 10.4669 7.53992 10.151 7.22395C9.83499 6.90798 9.45131 6.74999 8.99992 6.74999C8.54853 6.74999 8.16485 6.90798 7.84888 7.22395C7.5329 7.53992 7.37492 7.9236 7.37492 8.37499V9.45832H6.29158C5.8402 9.45832 5.45652 9.61631 5.14054 9.93228C4.82457 10.2483 4.66658 10.6319 4.66658 11.0833C4.66658 11.5347 4.82457 11.9184 5.14054 12.2344C5.45652 12.5503 5.8402 12.7083 6.29158 12.7083H7.37492ZM2.49992 19.75C1.90409 19.75 1.39402 19.5378 0.96971 19.1135C0.545405 18.6892 0.333252 18.1792 0.333252 17.5833V7.83332C0.333252 7.49027 0.409988 7.16527 0.56346 6.85832C0.716932 6.55138 0.929085 6.2986 1.19992 6.09999L7.69992 1.22499C8.07908 0.936101 8.51242 0.791656 8.99992 0.791656C9.48742 0.791656 9.92075 0.936101 10.2999 1.22499L16.7999 6.09999C17.0708 6.2986 17.2829 6.55138 17.4364 6.85832C17.5898 7.16527 17.6666 7.49027 17.6666 7.83332V17.5833C17.6666 18.1792 17.4544 18.6892 17.0301 19.1135C16.6058 19.5378 16.0958 19.75 15.4999 19.75H2.49992Z"
                        fill="#282E65"
                      />
                    </svg>
                  </span>

                  <label>Clinics</label>
                </Button>
              </li>
              {/* <li
                className={
                  location.pathname === "/operational-staff" ? "active" : ""
                }
              >
                <Button
                  onClick={() => {
                    history.push("/operational-staff");
                  }}
                  color="link"
                  className="d-flex align-items-center text-start w-100"
                >
                  <span>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.99992 8.99999C7.80825 8.99999 6.78811 8.57569 5.9395 7.72708C5.09089 6.87847 4.66658 5.85833 4.66658 4.66666C4.66658 3.47499 5.09089 2.45486 5.9395 1.60624C6.78811 0.757634 7.80825 0.333328 8.99992 0.333328C10.1916 0.333328 11.2117 0.757634 12.0603 1.60624C12.9089 2.45486 13.3333 3.47499 13.3333 4.66666C13.3333 5.85833 12.9089 6.87847 12.0603 7.72708C11.2117 8.57569 10.1916 8.99999 8.99992 8.99999ZM13.3333 17.6667V10.7333C13.7846 10.8778 14.227 11.0312 14.6603 11.1937C15.0937 11.3562 15.518 11.5458 15.9333 11.7625C16.4749 12.0333 16.8992 12.426 17.2062 12.9406C17.5131 13.4552 17.6666 14.0194 17.6666 14.6333V15.5C17.6666 16.0958 17.4544 16.6059 17.0301 17.0302C16.6058 17.4545 16.0958 17.6667 15.4999 17.6667H13.3333ZM6.83325 13.875V10.2458C7.19436 10.1917 7.55547 10.151 7.91658 10.124C8.2777 10.0969 8.63881 10.0833 8.99992 10.0833C9.36103 10.0833 9.72214 10.0969 10.0833 10.124C10.4444 10.151 10.8055 10.1917 11.1666 10.2458V13.875H6.83325ZM0.333252 15.5V14.6333C0.333252 14.0194 0.486724 13.4552 0.793669 12.9406C1.10061 12.426 1.52492 12.0333 2.06659 11.7625C2.48186 11.5458 2.90617 11.3562 3.3395 11.1937C3.77284 11.0312 4.2152 10.8778 4.66658 10.7333V17.6667H2.49992C1.90409 17.6667 1.39402 17.4545 0.96971 17.0302C0.545405 16.6059 0.333252 16.0958 0.333252 15.5Z"
                        fill="#282E65"
                      />
                    </svg>
                  </span>
                  <label>Operational Staff</label>
                </Button>
              </li> */}
              {/* <li
                className={
                  location.pathname === "/support-ticket" ? "active" : ""
                }
              >
                <Button
                  color="link"
                  className="d-flex align-items-center text-start w-100"
                  onClick={() => {
                    history.push("/support-ticket");
                  }}
                >
                  <span>
                    <svg
                      width="18"
                      height="20"
                      viewBox="0 0 18 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.42908 13.1396L4.74783 10.4313C4.62145 10.3229 4.49072 10.2688 4.35567 10.2688C4.21989 10.2688 4.08881 10.3229 3.96242 10.4313C3.85408 10.5576 3.79992 10.6884 3.79992 10.8234C3.79992 10.9592 3.85408 11.0903 3.96242 11.2167L6.83325 14.0604C6.99575 14.241 7.1987 14.3313 7.44209 14.3313C7.6862 14.3313 7.8895 14.241 8.052 14.0604L13.9833 8.12917C14.1096 8.02084 14.1728 7.89445 14.1728 7.75001C14.1728 7.60556 14.1096 7.47917 13.9833 7.37084C13.8749 7.24445 13.7485 7.18126 13.6041 7.18126C13.4596 7.18126 13.3333 7.24445 13.2249 7.37084L7.42908 13.1396ZM2.09367 19.6667C1.60617 19.6667 1.19089 19.4951 0.847835 19.1521C0.50478 18.809 0.333252 18.3938 0.333252 17.9063V4.09376C0.333252 3.60626 0.50478 3.19098 0.847835 2.84792C1.19089 2.50487 1.60617 2.33334 2.09367 2.33334H7.18533C7.077 1.77362 7.20339 1.27239 7.5645 0.829672C7.92561 0.387672 8.40408 0.166672 8.99992 0.166672C9.59575 0.166672 10.0789 0.387672 10.4494 0.829672C10.8192 1.27239 10.9409 1.77362 10.8145 2.33334H15.9062C16.3937 2.33334 16.8089 2.50487 17.152 2.84792C17.4951 3.19098 17.6666 3.60626 17.6666 4.09376V17.9063C17.6666 18.3938 17.4951 18.809 17.152 19.1521C16.8089 19.4951 16.3937 19.6667 15.9062 19.6667H2.09367ZM8.99992 2.82084C9.23464 2.82084 9.42892 2.74392 9.58275 2.59009C9.73586 2.43698 9.81242 2.24306 9.81242 2.00834C9.81242 1.77362 9.73586 1.57934 9.58275 1.42551C9.42892 1.27239 9.23464 1.19584 8.99992 1.19584C8.7652 1.19584 8.57128 1.27239 8.41817 1.42551C8.26434 1.57934 8.18742 1.77362 8.18742 2.00834C8.18742 2.24306 8.26434 2.43698 8.41817 2.59009C8.57128 2.74392 8.7652 2.82084 8.99992 2.82084Z"
                        fill="#282E65"
                      />
                    </svg>
                  </span>
                  <label>Support Tickets</label>
                </Button>
              </li> */}
              {/* <li
                className={location.pathname === "/sup-setting" ? "active" : ""}
              >
                <Button
                  onClick={() => {
                    history.push("/sup-setting");
                  }}
                  color="link"
                  className="d-flex align-items-center text-start w-100"
                >
                  <span className="icomoon-setting"></span>
                  <label>Settings</label>
                </Button>
              </li> */}
              <li>
                <Button
                  color="link"
                  className="d-flex align-items-center text-start w-100"
                  onClick={handleLogout}
                >
                  <span className="logout-icon">
                    <FontAwesomeIcon icon={faSignOut} />
                  </span>
                  <label>Logout</label>
                </Button>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-blc mt-auto">
          <SuperAdminProfile
            userName={`${userDetails?.firstname} ${userDetails?.lastname}`}
            userImage={userDetails.user_image}
            lastLogedIn={userDetails.last_logged_in}
          />
          <div className="copyright-txt mt-2 text-center">
            Copyright &copy; 2024 <span>| Privacy Policy</span>
          </div>
        </div>
      </aside>
    </>
  );
};
export default SuperAdminSidebar;
