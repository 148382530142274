import { toast } from "react-toastify";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";

export const getAllergies = (callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
      clinicReducer: { clinic_details },
    } = getState();

    ApiClient.get(
      `${apiUrl}${PORT}/app-content/get-allergies-tags/${clinic_details?._id}`,
      {},
      token,
      dispatch, getState
    ).then((res) => {
      if (res) {
        dispatch({ type: "GET_ALLERGIES", payload: res?.response_data });
        return callback(res?.status);
      } else {
        dispatch({ type: "GET_ALLERGIES", payload: [] });
        toast.error("Allergies are not fetched");
        return callback(res?.status);
      }
    });
  };
};

export const getBillingByPetId =
  ({ petId, page, limit, stDate, eDate }) =>
  async (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    const response = await ApiClient.post(
      `${apiUrl}${PORT}/invoice/get-all-for-pet-owner?page=${page || 1}&limit=${
        limit || 1
      }`,
      // `${apiUrl}${PORT}/invoice/get-all/${petId}`,
      { petId, page, limit, startDate: stDate, endDate: eDate },

      token,
      dispatch, getState
    );
    return response;
  };

export const getEstimateByPetId =
  ({ petId, page, limit }) =>
  async (dispatch, getState) => {
    console.log("petId", petId);
    const {
      user: { token },
    } = getState();
    const response = await ApiClient.post(
      `${apiUrl}${PORT}/estimate/get-all-by-pet`,
      // `${apiUrl}${PORT}/invoice/get-all/${petId}`,
      { pet_id: petId, page, limit },
      token,
      dispatch, getState
    );
    return response;
  };

export const addAllergy = (payload, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    payload.clinic_id = clinic_details?._id
    ApiClient.post(
      `${apiUrl}${PORT}/app-content/add-allergies-tags`,
      payload,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response) {
        toast.dismiss();
        if (response.statusCode == 400) {
          toast.error(response.message, toasterConfig);
        } else if (response.statusCode == 200 && response.status == false) {
          toast.error(response.message, toasterConfig);
        } else if (response.statusCode == 200 && response.status == true) {
          toast.success(response.message, toasterConfig);
        }
        return callback(response);
      } else {
        toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

export const deleteAllergy = (id, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.put(
      `${apiUrl}${PORT}/app-content/remove-allergies-tags/${id}`,
      {},
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response) {
        toast.dismiss();
        if (response.statusCode == 400) {
          toast.error(response.message, toasterConfig);
        } else if (response.statusCode == 200 && response.status == false) {
          toast.error(response.message, toasterConfig);
        } else if (response.statusCode == 200 && response.status == true) {
          toast.success(response.message, toasterConfig);
        }
        return callback(response);
      } else {
        toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

//export const addKennel = (body, callback) => {
