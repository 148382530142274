import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";
import { getFormAndTemplates } from "./formTemplates";

toast.configure();
/**
 * All Api endpoints to be call
 * Get -- /app-content/get-roles
*/

/**
 * 
 * @param {Object} body
 * @param {Function} clinic_id
 * @returns 
 */
export const updateSMSTemplate = (body, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, clinicReducer: { clinic_details } } = getState();
        if (clinic_details) {
            ApiClient.put(`${apiUrl}${PORT}/sms-template/update`, body, token, dispatch, getState).then((response) => {

                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    dispatch(getFormAndTemplates({}, () => { }))
                    toast.success(response.message, toasterConfig);
                    return callback(response);
                } else {
                    //toast.error(response.message, toasterConfig);
                    return callback(response);
                }
            })
        }
    };
};


