import moment from "moment";
import React, { useEffect, useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Table } from "reactstrap";
import { Icons } from "utils/contants/Icons";
import { getstaffManagement } from "context/actions/clinic-reports";
import { titleCaseString } from "utils/common";
import LoaderUI from "shared/loader";
import ImagePlaceholder from "shared/ImagePlaceholder";
import NodataFoundBlock from "shared/NoDataFoundBlock";
import "./StaffManagementReports.scss";
import PaginationBlock from "shared/PaginationBlock";

const StaffManagementReports = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const [limit, setLimit] = useState(5);
  const [hiringPage, setHiringPage] = useState([]);
  const [hiringPaging, setHiringPaging] = useState([]);
  const [rolePage, setRolePage] = useState([]);
  const [rolePaging, setRolePaging] = useState([]);
  const [tableData, setTableData] = useState({
    data: [],
    loading: true,
  });
  const fetchTableData = () => {
    dispatch(
      getstaffManagement({ clinic_id: clinic_details?._id }, (res) => {
        setTableData((prev) => {
          return {
            ...prev,
            data: res,
            loading: false,
          };
        });
      })
    );
  };
  useEffect(() => {
    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tableData?.data) {
      const updatedToPages = staffTable?.map((arr, _) => 1);
      setHiringPage(updatedToPages);
      setHiringPaging(updatedToPages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData?.data]);

  useEffect(() => {
    if (tableData?.data?.response_data?.[0]?.staff_by_role) {
      const updatedToPages =
        tableData?.data?.response_data?.[0]?.staff_by_role?.map((arr, _) => 1);
      setRolePage(updatedToPages);
      setRolePaging(updatedToPages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData?.data?.response_data?.[0]?.staff_by_role]);

  const staffTable = [
    {
      id: 1,
      title: "Staff Added Within The Last 90 Days",
      data:
        tableData?.data?.response_data?.[0]?.staff_added_within_last_90_days ||
        [],
    },
    {
      id: 2,
      title: "Staff Added Within The Last Year",
      data:
        tableData?.data?.response_data?.[0]?.staff_added_within_last_year || [],
    },
    {
      id: 3,
      title: "Staff Added More Than 1 Year Ago",
      data:
        tableData?.data?.response_data?.[0]
          ?.active_staff_added_more_than_1_year_ago || [],
    },
    {
      id: 4,
      title: "Staff Added Less Than 90 Days Ago",
      data: tableData?.data?.response_data?.[0]?.newStaffReports || [],
    },
  ];

  const getPaginatedData = (dataArray, tabIndex) => {
    const startIndex = (hiringPage[tabIndex] - 1) * limit;
    const endIndex = startIndex + limit;
    return dataArray?.slice(startIndex, endIndex) || [];
  };
  const getPaginatedRoleData = (dataArray, tabIndex) => {
    const startIndex = (rolePage[tabIndex] - 1) * limit;
    const endIndex = startIndex + limit;
    return dataArray?.slice(startIndex, endIndex) || [];
  };

  const handleDownload = () => {
    const csvContent = generateCSV(tableData);
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "Staff Management Report.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const generateCSV = (tableData) => {
    let csv = "";
    staffTable?.forEach((item) => {
      if (item?.data?.length > 0) {
        csv += `\n ${item.title}\nTitle,Gender,Status,Joining Date,Type,Phone,Email,Address line 1,City\n`;
        item?.data?.forEach((elem) => {
          const {
            firstname,
            lastname,
            gender,
            active,
            createdAt,
            role,
            user_contact,
          } = elem;
          csv += `${titleCaseString(`${firstname} ${lastname}`)},${gender},${
            active ? "Active" : "-"
          },${moment(createdAt).format("MM/DD/YYYY")},${titleCaseString(
            role
          )},${user_contact?.phone ? user_contact?.phone : "-"},${
            user_contact?.email ? user_contact?.email : "-"
          },${user_contact?.line1 ? user_contact?.line1 : "-"},${
            user_contact?.city ? user_contact?.city : "-"
          }\n`;
        });
      }
    });

    tableData?.data?.response_data?.[0]?.staff_by_role?.forEach((item) => {
      if (item?.user?.length > 0) {
        csv += `\n Role: ${titleCaseString(item?._id)} ${
          item?.user?.length
        }\nTitle,Gender,Status,Joining Date,Type,Phone,Email,Address line 1,City\n`;
        item?.user?.forEach((el) => {
          const {
            firstname,
            lastname,
            gender,
            active,
            createdAt,
            role,
            user_contact,
          } = el;
          csv += `${titleCaseString(`${firstname} ${lastname}`)},${gender},${
            active ? "Active" : "-"
          },${moment(createdAt).format("MM/DD/YYYY")},${titleCaseString(
            role
          )},${user_contact?.phone ? user_contact?.phone : "-"},${
            user_contact?.email ? user_contact?.email : "-"
          },${user_contact?.line1 ? user_contact?.line1 : "-"},${
            user_contact?.city ? user_contact?.city : "-"
          }\n`;
        });
      }
    });

    return csv;
  };

  return (
    <div id="staff-management-reports-container" className="p-1 mt-1">
      <div className="row">
        <div className="">
          <div className="d-flex">
            <div
              className="d-flex gap-2 align-items-center cmb-20 pointer"
              onClick={() => history.push("/reports")}
            >
              <BsChevronLeft color="color-3EFD" />
              <span className="text-13-600 color-3EFD">VIEW ALL REPORTS</span>
            </div>
          </div>
        </div>
        <div className="details-section">
          <div className="cpt-20 cpe-20 cpb-20">
            <div className="d-flex justify-content-between align-items-center cmb-20">
              <div>
                <div className="text-24-700 color-2E65">
                  Staff Management Reports
                </div>
                {/* <p className="text-14-400 color-8693 text-wrap">
                  Boarding Records help track the clinic’s medical supplies,
                  medications, and equipment to ensure that everything is
                  well-stocked and up to date.
                </p> */}
              </div>
              <div className="d-flex gap-2 br-8">
                <div
                  className="cp-8 bg-8C9F28 pointer"
                  onClick={() => {
                    handleDownload();
                  }}
                >
                  <img src={Icons.download} alt="download" />
                </div>
                <div className="cp-8 bg-8C9F28 br-8">
                  <img src={Icons.share} alt="share" />
                </div>
              </div>
            </div>

            {staffTable?.map((tabData, tabIndex) => {
              const totalItems = tabData?.data?.length;
              const totalPages = Math?.ceil(totalItems / limit);
              const paginatedData = getPaginatedData(tabData?.data, tabIndex);
              return (
                <div className="table-one cmb-20" key={tabIndex}>
                  <div className="text-14-600 color-2E65 cmb-10">
                    {tabData?.title} ({tabData?.data?.length})
                  </div>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Title.</th>
                        <th>Gender</th>
                        <th>Status</th>
                        <th>Joining Date</th>
                        <th>Type</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Address line 1</th>
                        <th>City</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData?.loading && (
                        <tr className="no-data-rw">
                          <td
                            colSpan="8"
                            className="d-flex justify-content-center cpt-50 cpb-50"
                          >
                            <LoaderUI />
                          </td>
                        </tr>
                      )}
                      {!tableData?.data?.loading &&
                      paginatedData?.length > 0 ? (
                        paginatedData?.map((dataElem, dataIndex) => {
                          const {
                            user_image,
                            firstname,
                            lastname,
                            gender,
                            active,
                            createdAt,
                            role,
                            user_contact,
                          } = dataElem;

                          return (
                            <tr key={dataIndex}>
                              <td className="text-nowrap">
                                <div className="text-start pic-cell d-inline-flex align-items-center">
                                  <div className="pic d-inline-flex position-relative">
                                    {user_image ? (
                                      <img
                                        className="img-fluid w-100 h-100"
                                        src={user_image}
                                        alt="Patient"
                                      />
                                    ) : (
                                      <ImagePlaceholder />
                                    )}
                                  </div>
                                  <div>
                                    {titleCaseString(
                                      `${firstname} ${lastname}`
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td className="text-nowrap">{gender}</td>
                              <td className="text-nowrap">
                                {active ? "Active" : "-"}
                              </td>
                              <td className="text-nowrap">
                                {moment(createdAt).format("MM/DD/YYYY")}
                              </td>
                              <td className="text-nowrap">
                                {titleCaseString(role)}
                              </td>
                              <td className="text-nowrap">
                                {user_contact?.phone
                                  ? user_contact?.phone
                                  : "-"}
                              </td>
                              <td className="text-nowrap">
                                {user_contact?.email
                                  ? user_contact?.email
                                  : "-"}
                              </td>
                              <td className="text-nowrap">
                                {user_contact?.line1
                                  ? user_contact?.line1
                                  : "--"}
                              </td>
                              <td className="text-nowrap">
                                {user_contact?.city ? user_contact?.city : "--"}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="no-data-rw">
                          <td colSpan="11">
                            <NodataFoundBlock />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {tabData?.data?.length > 0 && (
                    <PaginationBlock
                      limit={limit}
                      page={hiringPage[tabIndex] || 1}
                      pagingCounter={hiringPaging[tabIndex] || 1}
                      docLength={limit || 0}
                      docPage={hiringPage[tabIndex]}
                      totalPages={totalPages || 1}
                      totalDocs={tabData?.data?.length || 0}
                      _handleLimit={(val) => {
                        setLimit(val);
                      }}
                      _handlePageChange={(e) => {
                        if (e > hiringPage[tabIndex]) {
                          const diff = e - hiringPage[tabIndex];
                          setHiringPaging((prev) => {
                            const updatedPaging = [...prev];
                            updatedPaging[tabIndex] =
                              updatedPaging[tabIndex] + limit * diff;
                            return updatedPaging;
                          });
                        } else if (e < hiringPage[tabIndex]) {
                          const diff = hiringPage[tabIndex] - e;

                          setHiringPaging((prev) => {
                            const updatedPaging = [...prev];
                            updatedPaging[tabIndex] = Math.max(
                              updatedPaging[tabIndex] - limit * diff,
                              0
                            );
                            return updatedPaging;
                          });
                        }
                        setHiringPage((prevPages) => {
                          const updatedPages = prevPages?.map((page, index) =>
                            index === tabIndex ? e : page
                          );
                          return updatedPages;
                        });
                      }}
                    />
                  )}
                </div>
              );
            })}

            {tableData?.data?.response_data?.[0]?.staff_by_role?.map(
              (tData, index) => {
                const totalItems = tData?.user?.length;
                const totalPages = Math?.ceil(totalItems / limit);

                const paginatedData = getPaginatedRoleData(tData?.user, index);
                return (
                  <div className="table-one cmb-20">
                    <div className="text-14-600 color-2E65 cmb-10" key={index}>
                      Role: {titleCaseString(tData?._id)} ({tData?.user?.length})
                    </div>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Gender</th>
                          <th>Status</th>
                          <th>oining Date</th>
                          <th>Type</th>
                          <th>Phone</th>
                          <th>Email</th>
                          <th>Address line 1</th>
                          <th>City</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData?.loading && (
                          <tr className="no-data-rw">
                            <td
                              colSpan="8"
                              className="d-flex justify-content-center cpt-50 cpb-50"
                            >
                              <LoaderUI />
                            </td>
                          </tr>
                        )}
                        {!tableData?.data?.loading &&
                        paginatedData?.length > 0 ? (
                          paginatedData?.map((dataElem, dataIndex) => {
                            const {
                              user_image,
                              firstname,
                              lastname,
                              gender,
                              active,
                              createdAt,
                              role,
                              user_contact,
                            } = dataElem;

                            return (
                              <tr key={dataIndex}>
                                <td className="text-nowrap">
                                  <div className="text-start pic-cell d-inline-flex align-items-center">
                                    <div className="pic d-inline-flex position-relative">
                                      {user_image ? (
                                        <img
                                          className="img-fluid w-100 h-100"
                                          src={user_image}
                                          alt="Patient"
                                        />
                                      ) : (
                                        <ImagePlaceholder />
                                      )}
                                    </div>
                                    <div>
                                      {titleCaseString(
                                        `${firstname} ${lastname}`
                                      )}
                                    </div>
                                  </div>
                                </td>
                                <td className="text-nowrap">{gender}</td>
                                <td className="text-nowrap">
                                  {active ? "Active" : "-"}
                                </td>
                                <td className="text-nowrap">
                                  {moment(createdAt).format("MM/DD/YYYY")}
                                </td>
                                <td className="text-nowrap">
                                  {titleCaseString(role)}
                                </td>
                                <td className="text-nowrap">
                                  {user_contact?.phone
                                    ? user_contact?.phone
                                    : "-"}
                                </td>
                                <td className="text-nowrap">
                                  {user_contact?.email
                                    ? user_contact?.email
                                    : "-"}
                                </td>
                                <td className="text-nowrap">
                                  {user_contact?.line1
                                    ? user_contact?.line1
                                    : "--"}
                                </td>
                                <td className="text-nowrap">
                                  {user_contact?.city
                                    ? user_contact?.city
                                    : "--"}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="no-data-rw">
                            <td colSpan="11">
                              <NodataFoundBlock />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    {tData?.user?.length > 0 && (
                      <PaginationBlock
                        limit={limit}
                        page={rolePage[index] || 1}
                        pagingCounter={rolePaging[index] || 1}
                        docLength={limit || 0}
                        docPage={rolePage[index]}
                        totalPages={totalPages || 1}
                        totalDocs={tData?.user?.length || 0}
                        _handleLimit={(val) => {
                          setLimit(val);
                        }}
                        _handlePageChange={(e) => {
                          if (e > rolePage[index]) {
                            const diff = e - rolePage[index];
                            setRolePaging((prev) => {
                              const updatedPaging = [...prev];
                              updatedPaging[index] =
                                updatedPaging[index] + limit * diff;
                              return updatedPaging;
                            });
                          } else if (e < rolePage[index]) {
                            const diff = rolePage[index] - e;

                            setRolePaging((prev) => {
                              const updatedPaging = [...prev];
                              updatedPaging[index] = Math.max(
                                updatedPaging[index] - limit * diff,
                                0
                              );
                              return updatedPaging;
                            });
                          }
                          setRolePage((prevPages) => {
                            const updatedPages = prevPages?.map(
                              (page, pIndex) => (pIndex === index ? e : page)
                            );
                            return updatedPages;
                          });
                        }}
                      />
                    )}
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffManagementReports;
