import { useDispatch, useSelector } from "react-redux";
import pdfFile from "assets/img/icons/pdf.svg";
import { MdOutlineRemoveRedEye } from "react-icons/md";

const PatientIntakeFormList = ({ intakeForm, setIntakeForm }) => {
  const dispatch = useDispatch();
  const { formTemplates } = useSelector((state) => state.formTemplateReducer);
  const { petDetails } = useSelector((state) => state.petReducer);
  const list = formTemplates?.patientintakeforms?.filter(
    (o) => o.pet_specie_id === petDetails?.species?._id && o.is_active
  );
  const handleSelect = (id) => {
    if (intakeForm.includes(id)) {
      setIntakeForm(intakeForm.filter((o) => o !== id));
    } else {
      //   setIntakeForm([...intakeForm, id]);
      setIntakeForm([id]);
    }
  };

  return (
    <div>
      {list?.map((elm, index) => {
        const { _id, form_title, form_url } = elm;
        let isExist = intakeForm.includes(_id);
        return (
          <div
            key={index}
            className={`fb-center mb-2 bg-ffff p-3 br-10 cursor-pointer ${
              isExist ? "b-3EFD" : "b-dee3"
            }`}
            onClick={() => {
              handleSelect(_id);
            }}
          >
            <div className="fa-center gap-2">
              <div className="h-20 d-flex">
                <img src={pdfFile} alt="pdfFile" />
              </div>
              <div className="text-16-400 color-2E65">{form_title}</div>
            </div>
            {form_url && (
              <div className="fa-center gap-3">
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    dispatch({
                      type: "SET_FILE_VIEWER_DATA",
                      data: {
                        title: form_title,
                        fileURL: form_url,
                      },
                    });
                  }}
                >
                  <MdOutlineRemoveRedEye size={25} color="#919191" />
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default PatientIntakeFormList;
