import React from "react";
import UserPlaceholder from '../../../../../../shared/UserPlaceholder';
import { dateFromDateTime } from "../../../../../../utils/common";
import "./index.scss";

const MedicalBreifBox = ({ data }) => {
	return (
		<>
			<div className="medical-breif-box">
				<div className="head">
					<h2 style={{ textTransform: "capitalize" }}>
						 {data?.title} 
					</h2>
					<h3>
						 {data?.date ? data?.date : `${data?.startDate}-${data?.endDate}`} 
					</h3>
				</div>
				<div className="inner">
					<div className="desc-blc text-clip clip-2">
						<p>
							 {data?.description} 
						</p>
					</div>
					<div className="treat-by-cell d-flex align-items-center">
						<div className="pic d-inline-flex rounded-circle overflow-hidden flex-shrink-0">
							<img className="img-fluid w-100 h-100" src={require('../../../../../../assets/img/vetRecorEMR/user-thumbnail.png')} alt="User" />
							<UserPlaceholder />
							 {data?.image ? <img className="img-fluid w-100 h-100" src={data?.image || require('../../../../../../assets/img/vetRecorEMR/user-thumbnail.png')} alt="User" />
								: <UserPlaceholder />} 
						</div>
						<div className="txt flex-fill">
							 {data?.nameWithTitle} 
						</div>
						
					</div>
				</div>
			</div>
		</>
	);
};

export default MedicalBreifBox;
