import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Table,
  Row,
  Col,
} from "reactstrap";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";

import StepsBlock from "./StepsBlock";

import Vitals from "./Vitals/index";
import HeaderBlock from "./HeaderBlock";

import Offcanvas from "react-bootstrap/Offcanvas";
import { getAllPetDocumentsByPetId } from "../../context/actions/patient-module/document";
import {
  getAndFilterResults,
  getMostRecentVitalForPet,
} from "../../context/actions/patient-module/flowsheets/vitals";
import {
  getAndFilterDiagnosticResults,
  getMostRecentDiagnosticResultsByPetId,
} from "../../context/actions/patient-module/diagnostic-testing";
import { updateAppointmentDetails } from "../../context/actions/appointment";
import { useLocation } from "react-router-dom";
import {
  AddNewDischargePatientForm,
  FetchAppointmentDischargePatientForm,
  UpdateDischargePatientForm,
  createPdf,
} from "../../context/actions/patient-module/discharge-patient-form";
import { defaultDischargePatientFormData } from "../../context/reducers/dischargePatientReducer";
import NewOrderPanel from "./NewOrderPanel";
import {
  addNewOrder,
  addNewTreatment,
  getHomeMedicationOrders,
  getInpatientOrdersByPetId,
  getProceduresByPetId,
  updateOrder,
  updateOrdersStatuses,
} from "../../context/actions/patient-module/orders";
import {
  addMultipleConditions,
  getCurrentConditionByPetId,
  getProblemsByPetId,
  updateConditionsStatuses,
  conditionList,
} from "../../context/actions/patient-module/overview/current-condition";
import { FetchAllDischargeInstructionsForClinic } from "../../context/actions/patient-module/discharge-patient-form/discharge-instructions-documents";

import {
  imageBaseUrl,
  documentPhotosPath,
  apiUrl,
  PORT,
} from "../../environment";
import Select from "react-select";
import moment from "moment";
import AddDiagnosisCondition from "./AddDiagnosisCondition";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  timeFromDateTime,
  dateDiffFromNow,
  dateFromDateTime,
  dobInYearMonthDays,
  formatPhoneNumber,
} from "../../utils/common";
import {
  getMarsOrdersByPetWithDateFilter,
  getMostRecentMarsOrderByPet,
} from "../../context/actions/patient-module/mars";
import {
  addNewVitalToggles,
  updateVitalToggle,
} from "../../context/actions/vital-toggle";
import NoDataFound from "./NoDataFound";
import { vitalKeys } from "../../utils/constants";
import ApiClient from "../../api-client";
import { addAutoInvoice } from "context/actions/invoice";
import NewTreatmentPlan from "./NewTreatmentPlan";

const data_from_date = (data_list, date) => {
  for (let item of data_list) {
    if (item?.date == date) {
      return item;
    }
  }
  return null;
};

const DischargePatient = () => {
  const location = useLocation();

  const history = useHistory();
  const token = useSelector((state) => state.user.token);
  const customFilterOption = (option, rawInput) => {
    // Extract text content from the JSX element (option.label) and convert to lowercase
    const label =
      option.label && option.label.props && option.label.props.children;
    if (label && typeof label === "string") {
      return label.toLowerCase().includes(rawInput.toLowerCase());
    }
    return false;
  };

  const customFilter = (option, inputValue) => {
    if (inputValue) {
      // Convert option label and inputValue to lowercase for case-insensitive matching
      const label = option?.label?.toLowerCase();
      const searchValue = inputValue?.toLowerCase();

      // Return true if the label contains the inputValue
      return label.includes(searchValue);
    }
    // If no inputValue is provided, display all options
    return true;
  };

  const { vitalsToggles } = useSelector((state) => state.vitalsTogglesReducer);
  const [diagnosticId, SetDiagnosticId] = useState([])


  const { dischargePatientFormData, diagnosticToggle, dischargeInstructions } = useSelector((state) => state.dischargePatientReducer);



  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );

  const handleInputChange = (fieldName, value) => {
    dispatch({
      type: "DISCHARGE_PATIENT_FORM_DATA",
      payload: { ...dischargePatientFormData, [fieldName]: value },
    });
  };

  const handleArrOfObjChange = (fieldName, index, fieldToBeUpdated, value) => {
    let clonedDischargePatientFormData = JSON.parse(
      JSON.stringify(dischargePatientFormData)
    );
    let findAny = clonedDischargePatientFormData[fieldName][index];
    findAny[fieldToBeUpdated] = value;
    dispatch({
      type: "DISCHARGE_PATIENT_FORM_DATA",
      payload: { ...clonedDischargePatientFormData },
    });
  };

  const toggleVitalsTab = (tab) => {
    if (dischargePatientFormData?.vitals_selected_fetch_filter != tab) {
      handleInputChange("vitals_selected_fetch_filter", tab);
    }
  };

  const toggleDiagnosisTab = (tab) => {
    if (
      dischargePatientFormData?.diagnostic_tests_selected_fetch_filter != tab
    ) {
      handleInputChange("diagnostic_tests_selected_fetch_filter", tab);
    }
  };

  const toggleMedicationAdministeredTab = (tab) => {
    if (
      dischargePatientFormData?.medications_administered_selected_fetch_filter !=
      tab
    ) {
      handleInputChange("medications_administered_selected_fetch_filter", tab);
    }
  };

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const userDetails = useSelector((state) => state.user.user_details);
  const { petProblemList, conditionList } = useSelector(
    (state) => state.currentConditionReducer
  );

  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const [step, setStep] = useState(0);
  const { testResults: diagnosticResults } = useSelector(
    (state) => state.diagnosticTestingReducer
  );

  const [petId, setPetId] = useState(null);
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [selectedDischargeInstructions, setSelectedDischargeInstructions] =
    useState([]);
  const { testResults: vitals, vitalsListing } = useSelector(
    (state) => state.vitalTestingReducer
  );
  const userDetail = useSelector((state) => state.user.user_details);
  const { marsOrders } = useSelector((state) => state.marReducer);

  const { petDetails } = useSelector((state) => state.petReducer);

  function getStartDateEndDate(type) {
    let startDate;
    let endDate;

    if (type == "from_appointment_details") {
      startDate = new Date(appointmentDetails.appointment_start);
      endDate = new Date();
    }
    if (type == "last_24_hours") {
      startDate = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
      endDate = new Date();
    } else if (type == "last_48_hours") {
      startDate = new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000);
      endDate = new Date();
    } else if (type == "last_72_hours") {
      startDate = new Date(new Date().getTime() - 3 * 24 * 60 * 60 * 1000);
      endDate = new Date();
    } else if (type == "most_recents") {
      startDate = new Date();
      startDate = new Date(startDate.setUTCHours(0, 0, 0, 0));
      endDate = new Date();
    }

    return { startDate, endDate };
  }

  function getStartDateEndDate2(type) {
    // let startDate;
    // let endDate;

    let startDate2;
    let endDate2;

    if (type == "from_appointment_details") {
      // startDate = new Date(appointmentDetails.appointment_start);
      // endDate = new Date();

      startDate2 = moment(appointmentDetails.appointment_start);
      endDate2 = moment();
    }
    if (type == "last_24_hours") {
      // startDate = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
      // endDate = new Date();

      startDate2 = moment().subtract(24, "hours");
      endDate2 = moment();
    } else if (type == "last_48_hours") {

      startDate2 = moment().subtract(48, "hours");
      endDate2 = moment();
    } else if (type == "last_72_hours") {

      startDate2 = moment().subtract(72, "hours");
      endDate2 = moment();
    }


    return {
      startDate2,
      endDate2,
    };
  }

  useEffect(() => {
    if (location && location.state) {
      setPetId(location.state.pet_id);
      setAppointmentDetails(location.state.appointmentDetails);
      dispatch({
        type: "UPDATE_DIAGNOSTIC_TOGGLES",
        payload: {},
      });
    }
  }, [location]);


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [problems, setProblems] = useState([]);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [treatmentPlanFormSubmitted, setTreatmentPlanFormSubmitted] = useState(false);
  const [
    formSubmittedForAddDiagnosisForm,
    setFormSubmittedForAddDiagnosisForm,
  ] = useState(false);

  const [appointmentDischargeFormData, setAppointmentDischargeFormData] =
    useState(null);

  const { orders, inpatientOrders, homeMedicationOrders, procedureOrders } =
    useSelector((state) => state.orderReducer);

  const [rerender, setRerender] = useState(new Date().toString());


  const FetchProcedures = () => {
    try {
      let body = {
        pet_id: petId,
        startDate: new Date(appointmentDetails.appointment_start),
        endDate:
          (appointmentDetails.appointment_end &&
            new Date(appointmentDetails.appointment_end)) ||
          new Date(),
      };

      dispatch(getProceduresByPetId(body, () => { }));
    } catch (err) {
      console.log(err);
    }
  };

  const FetchCurrentConditionsByPetId = () => {
    try {
      dispatch(
        getProblemsByPetId(petId, {}, (res) => {
          // setProblems(
          //   res?.response_data?.map((item) => {
          //     return {
          //       condition_id: item?._id,
          //       description: item?.description,
          //       status: "active",
          //     };
          //   })
          // );
          // let clonedDischargePatientFormData = JSON.parse(
          //   JSON.stringify(dischargePatientFormData)
          // );
          // let conditions = res?.response_data?.filter(
          //   (item) =>
          //     !(clonedDischargePatientFormData?.problems || [])
          //       ?.map((ele) => ele?.condition_id)
          //       ?.includes(item?._id)
          // );
          // let arr = [
          //   ...clonedDischargePatientFormData?.problems,
          //   ...(conditions || [])?.map((item) => {
          //     return {
          //       condition_id: item?._id,
          //       description: item?.description,
          //       status: "active",
          //     };
          //   }),
          // ];
          // handleInputChange("problems", arr);
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const FetchInpatientOrders = () => {
    try {
      let body = {
        pet_id: petId,
        startDate: new Date(appointmentDetails.appointment_start),
        endDate:
          (appointmentDetails.appointment_end &&
            new Date(appointmentDetails.appointment_end)) ||
          new Date(),
      };

      dispatch(getInpatientOrdersByPetId(body, () => { }));
    } catch (err) {
      console.log(err);
    }
  };

  const FetchHomeMedicationOrders = () => {
    try {
      let body = {
        pet_id: petId,
        startDate: new Date(appointmentDetails.appointment_start),
        endDate:
          (appointmentDetails.appointment_end &&
            new Date(appointmentDetails.appointment_end)) ||
          new Date(),
      };

      dispatch(getHomeMedicationOrders(body, () => { }));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (petId && appointmentDetails && appointmentDetails.appointment_start) {
      FetchAppointmentDischargePatientFormFunction().then(() => {
        FetchProcedures();
        FetchInpatientOrders();
        FetchHomeMedicationOrders();
        FetchCurrentConditionsByPetId();
      });
    }
  }, [petId, appointmentDetails]);

  useEffect(() => {
    if (clinic_settings) {
      dispatch(
        FetchAllDischargeInstructionsForClinic(
          clinic_settings.clinic_id,
          (res) => { }
        )
      );
    }
  }, [clinic_settings]);

  const FetchAppointmentDischargePatientFormFunction = async () => {
    dispatch(
      FetchAppointmentDischargePatientForm(
        { appointment_id: appointmentDetails._id },
        (res) => {
          if (res.status && res.response_data) {
            let cloneObj = JSON.parse(JSON.stringify(res.response_data));

            let clonedDischargePatientFormData = {
              order_for_discharge: cloneObj.order_for_discharge.map((ele) => {
                delete ele._id;
                return ele;
              }),
              treatmentPlan_for_discharge: cloneObj.treatmentPlan_for_discharge.map((ele) => {
                delete ele._id;
                return ele;
              }),

              added_diagnosis: cloneObj.added_diagnosis.map((ele) => {
                delete ele._id;
                return ele;
              }),
              // order_for_discharge: cloneObj.order_for_discharge,
              // added_diagnosis: cloneObj.added_diagnosis,

              vitals: cloneObj.vitals.map((ele) => {
                delete ele._id;
                return ele;
              }),

              vitals_selected_fetch_filter:
                cloneObj.vitals_selected_fetch_filter,

              marsOrders: cloneObj.marsOrders.map((ele) => {
                delete ele._id;
                return ele;
              }),

              medications_administered_selected_fetch_filter:
                cloneObj.medications_administered_selected_fetch_filter,

              diagnostic_tests_selected_fetch_filter:
                cloneObj.diagnostic_tests_selected_fetch_filter,

              prescription_routing: cloneObj.prescription_routing,

              procedures: cloneObj.procedures.map((ele) => {
                delete ele._id;
                return ele;
              }),

              home_medication_and_orders:
                cloneObj.home_medication_and_orders.map((ele) => {
                  let obj = { ...ele };
                  delete obj._id;
                  return obj;
                }),

              inpatient_orders: cloneObj.inpatient_orders.map((ele) => {
                let obj = { ...ele };
                delete obj._id;
                return obj;
              }),

              discharge_instructions: cloneObj.discharge_instructions.map(
                (ele) => {
                  return {
                    document_name: ele.document_name,
                    notes: ele.notes,
                    document_link: ele.document_link,
                    document_type: ele.document_type,
                    document_id: ele?.document_id,
                  };
                }
              ),
              problems: cloneObj.problems.map((ele) => {
                delete ele._id;
                return ele;
              }),
              //problems: cloneObj?.problems,
              _id: cloneObj._id,
            };

            dispatch({
              type: "DISCHARGE_PATIENT_FORM_DATA",
              payload: clonedDischargePatientFormData,
            });
            setAppointmentDischargeFormData(clonedDischargePatientFormData);
          } else {
            dispatch({
              type: "DISCHARGE_PATIENT_FORM_DATA",
              payload: { ...defaultDischargePatientFormData },
            });
            setAppointmentDischargeFormData(null);
          }
          setRerender(new Date().toString());
        }
      )
    );
    return 1;
  };

  useEffect(() => {
    if (petId && appointmentDetails && appointmentDetails.appointment_start) {
      fetchVitals(
        dischargePatientFormData &&
          dischargePatientFormData.vitals_selected_fetch_filter
          ? dischargePatientFormData.vitals_selected_fetch_filter
          : "from_appointment_details"
      );

      fetchMedicationsAdministered(
        dischargePatientFormData &&
          dischargePatientFormData.medications_administered_selected_fetch_filter
          ? dischargePatientFormData.medications_administered_selected_fetch_filter
          : "from_appointment_details"
      );

      fetchDiagnosticResults(
        dischargePatientFormData &&
          dischargePatientFormData.diagnostic_tests_selected_fetch_filter
          ? dischargePatientFormData.diagnostic_tests_selected_fetch_filter
          : "from_appointment_details"
      );
    } else if (petId) {
      fetchVitals(
        dischargePatientFormData &&
          dischargePatientFormData.vitals_selected_fetch_filter
          ? dischargePatientFormData.vitals_selected_fetch_filter
          : "last_24_hours"
      );

      fetchMedicationsAdministered(
        dischargePatientFormData &&
          dischargePatientFormData.medications_administered_selected_fetch_filter
          ? dischargePatientFormData.medications_administered_selected_fetch_filter
          : "last_24_hours"
      );

      fetchDiagnosticResults(
        dischargePatientFormData &&
          dischargePatientFormData.diagnostic_tests_selected_fetch_filter
          ? dischargePatientFormData.diagnostic_tests_selected_fetch_filter
          : "last_24_hours"
      );
    }
  }, [
    petId,
    appointmentDetails,
    dischargePatientFormData?.vitals_selected_fetch_filter,
    dischargePatientFormData?.diagnostic_tests_selected_fetch_filter,
    dischargePatientFormData?.medications_administered_selected_fetch_filter,
  ]);

  const fetchVitals = (type) => {
    if (type == "most_recents") {
      dispatch(getMostRecentVitalForPet({ pet_id: petId }, (res) => { }));
    } else {
      // let { startDate, endDate } = getStartDateEndDate(type);
      let {
        // startDate, endDate,
        startDate2,
        endDate2,
      } = getStartDateEndDate2(type);
      dispatch(
        getAndFilterResults(
          {
            startDate: startDate2,
            endDate: endDate2,
            pet_id: petId,
          },
          (res) => { }
        )
      );
    }
  };

  const fetchMedicationsAdministered = (type) => {
    if (type == "most_recents") {
      dispatch(getMostRecentMarsOrderByPet({ pet_id: petId }));
    } else {
      // let { startDate, endDate } = getStartDateEndDate(type);
      let {
        // startDate, endDate,
        startDate2,
        endDate2,
      } = getStartDateEndDate2(type);
      dispatch(
        getMarsOrdersByPetWithDateFilter(
          {
            startDate: startDate2,
            endDate: endDate2,
            pet_id: petId,
          },
          (res) => { }
        )
      );
    }
  };

  const fetchDiagnosticResults = (type) => {
    if (type == "most_recents") {
      dispatch(
        getMostRecentDiagnosticResultsByPetId({ pet_id: petId }, (res) => { })
      );
    } else {
      // let { startDate, endDate } = getStartDateEndDate(type);
      let {
        // startDate, endDate,
        startDate2,
        endDate2,
      } = getStartDateEndDate2(type);
      let body = { startDate: startDate2, endDate: endDate2, pet_id: petId };
      dispatch(getAndFilterDiagnosticResults(body, (res) => { }));
    }
  };

  const childRef = useRef(null); // Create a ref for the child component
  const treatmentPlanChildRef = useRef(null); // Create a ref for the treatment child component
  const childRefForAddDiagnosisForm = useRef(null); // Create a ref for the child component

  const { productServiceList } = useSelector((state) => state.inventoryReducer);

  const addOrders = async () => {
    dischargePatientFormData &&
      productServiceList &&
      dischargePatientFormData.order_for_discharge &&
      dischargePatientFormData.order_for_discharge.forEach(
        (orderitem, index) => {
          let product = productServiceList.find(
            (ele) => ele._id == orderitem.product_service_id
          );
          if (product) {
            delete product.description;
            delete orderitem.product_service_id;

            orderitem.first_dose = orderitem.start_datetime;

            delete orderitem.start_datetime;

            let {
              product_name,
              sku,
              _id,
              image,
              type,
              service_name,
              product_service_name,
              category,
              ...order_details
            } = { ...product, ...orderitem };
            if (order_details?.first_dose) {
              order_details.first_dose = moment(
                order_details?.first_dose
              ).toDate();
            }

            if (order_details?.second_dose) {
              order_details.second_dose = moment(
                order_details?.second_dose
              ).toDate();
            }

            let order = {
              ...order_details,
              created_by: userDetails._id,
              pet_id: petId,
              type: "home",
              order_type: "in-house",
            };

            if (type === "service") order = { ...order, service: _id };
            else if (type === "product") order = { ...order, item: _id };
            const { available_stock, orderData, packages, quantity_occupied, unit_of_measure, sub_package, isAvailable, ...newOrderDetails } = order;
            dispatch(addNewOrder(newOrderDetails, (result) => {  }));
          }
        }
      );
      return 1
  };

  const handleSubmitDischargeForm = () => {
    // Trigger the form submission from the parent component using the ref
    if (childRef.current && childRefForAddDiagnosisForm.current && treatmentPlanChildRef.current) {
      childRef.current.submitForm();
      childRefForAddDiagnosisForm.current.submitForm();
      treatmentPlanChildRef.current.submitForm()
      if (formSubmitted && formSubmittedForAddDiagnosisForm && treatmentPlanFormSubmitted) {
        if (appointmentDischargeFormData) {
          let body = {
            ...dischargePatientFormData,
            order_for_discharge:
              dischargePatientFormData.order_for_discharge.map((ele) => {
                delete ele._id;
                return ele;
              }),
            // discharge_instructions:
            //   dischargePatientFormData?.discharge_instructions?.map(
            //     (ele) => {
            //       ele.documment_id = ele._id;
            //       delete ele._id;
            //       return ele;
            //     }
            //   ),
            clinic_id: clinic_details._id,
            appointment_id: appointmentDetails._id,
            pet_id: petId,
            added_by: user._id,
          };
          dispatch(
            UpdateDischargePatientForm(body, async (response) => {
              if (response?.status) {
                // setApiSuccess("success");

                console.log('discharge patient form complete')
                await addOrders();

                dispatch(updateOrdersStatuses(body));

                body.added_diagnosis &&
                  body.added_diagnosis.length > 0 &&
                  dispatch(
                    addMultipleConditions({ data: body.added_diagnosis })
                  );

                dispatch(updateConditionsStatuses({ problems: body.problems }, () => console.log('completed update conditions')));

                FetchAppointmentDischargePatientFormFunction();

                addPatientTreatmentPlan(body.treatmentPlan_for_discharge)
               

                dispatch(
                  updateVitalToggle(
                    {
                      ...vitalsToggles,
                      discharge_patient_form_id: body._id,
                      created_by: userDetails._id,
                    },
                    () => { console.log('updated vital toggle') }
                  )
                );

                dispatch(
                  createPdf({
                    discharge_patient_form_id: body._id,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    diagnosticResults: Object.keys(diagnosticToggle)?.filter(key => diagnosticToggle[key])
                  }, (pdfresponse) => {
                    if(pdfresponse){
                      console.log('created pdf')
                      dispatch(getAllPetDocumentsByPetId(petId, {}, () => { }))
                    }
                  })
                );
                const invoiceData = {
                  issued_date: new Date(),
                  due_date: new Date(),
                  status: "Pending",
                  patient_id: petId,
                  clinic_id: clinic_details._id,
                  created_by: user._id,
                  services: [],
                  discounts: [],
                };
                dispatch(addAutoInvoice(
                  invoiceData,
                 (res) => {
                  console.log('generated invoice')

                    return res.data;
                  }))
                dispatch(
                  updateAppointmentDetails(
                    appointmentDetails?._id,
                    { appointment_end: new Date(), appointment_status: 'complete' },
                    () => {
                      
                      console.log('update appointment')

                    }
                  )
                );
               
                history.replace(`/patient-detail/${petId}`);
              } else {
                // setApiSuccess("error");
              }
            })
          );
        }
      } else {
      }
    }
  };

  const addPatientTreatmentPlan = (cardData) => {
    if (!cardData?.length) return;
    let endDate = new Date(cardData[0]?.start_datetime);
    if (cardData[0]?.duration_unit === "Days") { endDate.setDate(endDate.getDate() + parseInt(cardData[0]?.duration_num)); }
    else if (cardData[0]?.duration_unit === "Weeks") { endDate.setDate(endDate.getDate() + parseInt(cardData[0]?.duration_num) * 7); }

    const allData = {
      pet_id: petId,
      created_by: userDetail?._id,
      treatment_type: cardData[0]?.type,
      treatmentName: cardData[0]?.plan_name,
      name: cardData[0]?.name,
      instructions: cardData[0]?.instructions,
      frequency: cardData[0]?.frequency,
      start_Date: cardData[0]?.start_datetime,
      end_Date: endDate,
      duration: cardData[0]?.duration_num,
      duration_unit: cardData[0]?.duration_unit,
      notes: cardData[0]?.notes,
      product_id: cardData[0]?.product_instruction_id,
      existAttachments: JSON.stringify(cardData[0]?.attachments?.filter(file => typeof file === 'string')),
      attachments: cardData[0]?.attachments?.filter(file => typeof file === 'object'),
    };

    if (cardData[0]?.type === "product" || (cardData[0]?.type === "instruction" && cardData[0]?.attachments.length === 0)) { delete allData?.attachments; }
    if (cardData[0]?.type === "instruction") {
      delete allData?.product_id;
    }

    let formData = new FormData();
    for (const key in allData) {
      // formData.append(key, allData[key]);
      if (key === "attachments") {
        if (allData[key]?.length > 0) {
          allData?.attachments?.forEach((file) => {
            formData.append("attachments", file)
          })
        }
      } else {
        formData.append(key, allData[key]);
      }
    }

    dispatch(
      addNewTreatment(formData, (response) => {
        if (response?.status) {
          if (cardData.length > 1) {
            cardData.forEach((data, i) => {
              if (i > 0) {
                let endDate = new Date(cardData[0]?.start_datetime);
                if (cardData[0]?.duration_unit === "Days") { endDate.setDate(endDate.getDate() + parseInt(cardData[0]?.duration_num)); }
                else if (cardData[0]?.duration_unit === "Weeks") { endDate.setDate(endDate.getDate() + parseInt(cardData[0]?.duration_num) * 7); }

                const payload = {
                  treatment_id: response?.response_data,
                  pet_id: petId,
                  created_by: userDetail?._id,
                  treatment_type: data.type,
                  treatmentName: data?.plan_name,
                  name: data?.name,
                  instructions: data?.instructions,
                  frequency: data?.frequency,
                  start_Date: data?.start_datetime,
                  end_Date: endDate,
                  duration: data?.duration_num,
                  duration_unit: data?.duration_unit,
                  notes: data?.notes,
                  product_id: data?.product_instruction_id,
                  existAttachments: JSON.stringify(data?.attachments?.filter(file => typeof file === 'string')),
                  attachments: data?.attachments?.filter(file => typeof file === 'object'),
                };
                if (data?.type === "product" || (data?.type === "instruction" && data?.attachments?.length === 0)) { delete payload?.attachments; }
                if (data?.type === "instruction") { delete payload?.product_id; }
                let formData = new FormData();
                for (const key in payload) {
                  if (key === "attachments") {
                    if (payload[key]?.length > 0) {
                      payload.attachments?.forEach((file) => {
                        formData.append("attachments", file)
                      })
                    }
                  } else {
                    formData.append(key, payload[key]);
                  }
                }

                dispatch(
                  addNewTreatment(formData, (responseNew) => { }))
              }
            });
          }
        }
      })
    );
  }

  const [largeVitalKeysArr, setLargeVitalKeysArr] = useState([]);

  useEffect(() => {
    if (vitalsToggles) {
      let largeVitalKeysArr = [];
      let size = 4;
      let filteredVitalsKeys = vitalKeys.filter(
        (ele) => vitalsToggles[ele["key"]]
      );
      for (
        var vital_key = 0;
        vital_key < filteredVitalsKeys.length;
        vital_key += size
      ) {
        largeVitalKeysArr.push(
          filteredVitalsKeys.slice(vital_key, vital_key + size)
        );
      }

      // console.log(largeVitalKeysArr, 'largevitalkeysarr')

      setLargeVitalKeysArr(largeVitalKeysArr);
    }
  }, [vitalsToggles]);

  // useEffect(() => {
  //   if (dischargePatientFormData && vitalsListing) {
  //     let clonedDischargePatientFormData = JSON.parse(
  //       JSON.stringify(dischargePatientFormData)
  //     );
  //     let orderIds =
  //       (clonedDischargePatientFormData?.vitals || [])?.map(
  //         (ele) => ele?.vital_id
  //       ) || [];
  //     let vitalsArr = vitalsListing?.filter(
  //       (item) => !orderIds?.includes(item?._id)
  //     );

  //  let arr = [
  //    ...(clonedDischargePatientFormData?.vitals || []),
  //    ...(vitalsArr || [])?.map((item) => {
  //      let obj_id = item._id;

  //         delete item._id;
  //         delete item.deleted;
  //         delete item.createdAt;
  //         delete item.updatedAt;

  //         return {
  //           vital_id: obj_id,
  //           ...item,
  //         };
  //       }),
  //     ];
  //     handleInputChange("vitals", arr);
  //   }
  // }, [vitalsListing]);

  useEffect(() => {
    if (dischargePatientFormData && vitalsListing) {
      handleInputChange("vitals", vitalsListing);
    }
  }, [vitalsListing]);

  useEffect(() => {
    if (dischargePatientFormData && marsOrders) {

      /*
      let clonedDischargePatientFormData = JSON.parse(
        JSON.stringify(dischargePatientFormData)
      );

      let orderIds =
        (clonedDischargePatientFormData?.marsOrders || [])?.map(
          (ele) => ele?.document_id
        ) || [];

      let vitalsArr = marsOrders?.filter(
        (item) => !orderIds?.includes(item?._id)
      );*/

      let arr = [
        //...(clonedDischargePatientFormData?.marsOrders || []),
        ...(marsOrders || [])?.map((item) => {
          return {
            document_id: item._id,
            medication_name: item?.orders?.product?.product_name,
            dose: item?.dose
              ? item?.dose + " " + item?.unit + " " + item?.route + " "
              : "",
            date: item.date,
          };
        }),
      ];

      handleInputChange("marsOrders", arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marsOrders]);

  useEffect(() => {
    if (dischargePatientFormData && diagnosticResults) {
      handleInputChange("diagnosticResults", diagnosticResults);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagnosticResults]);

  useEffect(() => {
    if (
      dischargePatientFormData &&
      procedureOrders
    ) {

      let clonedDischargePatientFormData = JSON.parse(
        JSON.stringify(dischargePatientFormData)
      );

      let orderIds =
        (clonedDischargePatientFormData?.procedures || [])?.map(
          (ele) => ele?.document_id
        ) || [];
      let orders = procedureOrders?.filter(
        (item) => !orderIds?.includes(item?._id)
      );
      let arr = [
        ...(clonedDischargePatientFormData?.procedures || []),
        ...(orders || [])?.map((item) => {
          return {
            document_id: item._id,
            name:
              item?.item_details?.product_name ||
              item?.service_details?.service_name,
            notes: item?.notes,
            first_dose: item?.first_dose,
            status: item?.status,
          };
        }),
      ];

      handleInputChange("procedures", arr);
    }
  }, [procedureOrders]);

  useEffect(() => {
    if (
      dischargePatientFormData &&
      homeMedicationOrders
    ) {

      let clonedDischargePatientFormData = JSON.parse(
        JSON.stringify(dischargePatientFormData)
      );

      let orderIds =
        (clonedDischargePatientFormData?.home_medication_and_orders || [])?.map(
          (ele) => ele?.document_id
        ) || [];

      let orders = homeMedicationOrders?.filter(
        (item) => !orderIds?.includes(item?._id)
      );

      let arr = [
        ...(clonedDischargePatientFormData?.home_medication_and_orders || []),
        ...(orders || [])?.map((item) => {
          return {
            dose: item?.dose,
            form: item?.form,
            route: item?.route,
            frequency: item?.frequency,
            document_id: item._id,
            name:
              item?.item_details?.product_name ||
              item?.service_details?.service_name,
            notes: item?.notes,
            status: "Continue",
          };
        }),
      ];

      handleInputChange("home_medication_and_orders", arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeMedicationOrders]);

  useEffect(() => {
    if (
      dischargePatientFormData &&
      inpatientOrders
    ) {

      let clonedDischargePatientFormData = JSON.parse(
        JSON.stringify(dischargePatientFormData)
      );
      let orderIds =
        (clonedDischargePatientFormData?.inpatient_orders || [])?.map(
          (ele) => ele?.document_id
        ) || [];

      let orders = inpatientOrders?.filter(
        (item) => !orderIds?.includes(item?._id)
      );

      let arr = [
        ...(clonedDischargePatientFormData?.inpatient_orders || []),
        ...(orders || [])?.map((item) => {
          return {
            document_id: item._id,
            name:
              item?.item_details?.product_name ||
              item?.service_details?.service_name,
            route: item?.route,
            frequency: item?.frequency,
            dose: item?.dose,
            form: item?.form,
            notes: item?.notes,
            status: "Continue",
          };
        }),
      ];

      handleInputChange("inpatient_orders", arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inpatientOrders]);


  useEffect(() => {
    if (
      dischargePatientFormData &&
      petProblemList

    ) {

      let clonedDischargePatientFormData = JSON.parse(
        JSON.stringify(dischargePatientFormData)
      );

      let orderIds = (clonedDischargePatientFormData?.problems || [])?.map(
        (ele) => ele?.condition_id
      );

      let problems =
        petProblemList?.filter((item) => !orderIds?.includes(item?._id)) || [];

      let arr = [
        ...(clonedDischargePatientFormData?.problems || []),
        ...(problems || [])?.map((item) => {
          return {
            condition_id: item._id,
            description: item.description,
            status: "active",
          };
        }),
      ];
      handleInputChange("problems", arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petProblemList]);

  const handleDeleteDischargeInstruction = (index) => {
    setSelectedDischargeInstructions((prev) =>
      prev.filter((ele, idx) => idx != index)
    );
    let finalData = dischargePatientFormData.discharge_instructions.filter(
      (ele, idx) => idx != index
    );
    handleInputChange("discharge_instructions", finalData);
  };

  let timeout;
  let delay = 300;

  useEffect(() => {
    if (appointmentDischargeFormData && dischargePatientFormData) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeout = setTimeout(() => {
        dispatch(
          UpdateDischargePatientForm(
            {
              ...dischargePatientFormData,
              order_for_discharge:
                dischargePatientFormData.order_for_discharge.map((ele) => {
                  delete ele._id;
                  return ele;
                }),
              // discharge_instructions:
              //   dischargePatientFormData?.discharge_instructions?.map(
              //     (ele) => {
              //       ele.documment_id = ele._id;
              //       delete ele._id;
              //       return ele;
              //     }
              //   ),
              clinic_id: clinic_details._id,
              appointment_id: appointmentDetails._id,
              pet_id: petId,
              added_by: user._id,
            },
            (response) => {
              if (response?.status) {
              } else {
              }
            }
          )
        );
      }, delay);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [appointmentDischargeFormData, dischargePatientFormData]);

  useEffect(() => {
    if (dischargeInstructions && dischargeInstructions.length > 0) {
      let documentIds =
        dischargePatientFormData?.discharge_instructions.map(
          (ele) => ele.document_id
        ) || [];
      let selectedDischargeInstructions = [];
      dischargeInstructions.forEach((ele) => {
        if (documentIds.includes(ele._id)) {
          selectedDischargeInstructions.push({
            ...ele,
            value: ele._id,
            label: ele.document_name,
          });
        }
      });
      setSelectedDischargeInstructions(selectedDischargeInstructions);
    }
  }, [dischargeInstructions, dischargePatientFormData?.discharge_instructions]);


  const renderProblemList = () => {
    let html = "";
    if (dischargePatientFormData?.problems?.length > 0) {
      let html = "";
      for (var i = 1; i <= dischargePatientFormData?.problems?.length; i++) {
        if (i % 3 == 1) {
          html += "<tr>";
        }
        if (i % 3 == 0 || i == dischargePatientFormData?.problems?.length) {
          html += "</tr>";
        }

        html +=
          dischargePatientFormData?.problems[i]?.status == "active"
            ? '<td style="background-color: rgba(228, 228, 228, 0.28); padding: 10px; border-radius: 10px;">Elbow Dysplasia <span style="color: rgb(22, 150, 119); background-color: rgb(219, 248, 241); font-size: 12px; padding: 3px 5px; line-height: normal; text-transform: capitalize; border-radius: 4px; margin-left: 10px;">active</span></td>'
            : '<td style="background-color: rgba(228, 228, 228, 0.28); padding: 10px; border-radius: 10px;">[problem_name_goes here] <span style="color: rgb(118, 96, 255); background-color: rgb(224, 233, 255); font-size: 12px; padding: 3px 5px; line-height: normal; text-transform: capitalize; border-radius: 4px; margin-left: 10px;">Resolved</span></td>';
      }
    }
    return html;
  };

  const handleToggle = (key, value) => {
    dispatch({
      type: "UPDATE_DIAGNOSTIC_TOGGLES",
      payload: { ...diagnosticToggle, [key]: value },
    });
  };


  return (
    <>
      <div
        className="page-wrapper add-patient-wrapper position-relative d-flex flex-column"
        key={rerender}
      >
        <div className=" position-relative d-flex flex-column w-[100%]">
          <div className="d-flex header-position ">
            <HeaderBlock />
            <div className="d-flex flex-column w-[70%] patient-header">
              <StepsBlock step={step} />
            </div>
          </div>
        </div>

        <div className="inner-wrapper">
          {
            <div className="right-col flex-fill d-flex flex-column">
              <div className="flex p-[10px] tab-content flex-wrap">
                <div className="patient-all-info pe-lg-3">
                  {/* Step--1 */}
                  {step == 0 && (
                    <>
                      <div className="row-block d-flex">
                        <div className="head w-[25%] me-3">
                          <h3>Vitals</h3>
                        </div>
                        <div className="inner  w-[65%]">
                          <div className="owner-tab-blc discharge-patient-table">
                            <Nav tabs className="flex-shrink-0">
                              <NavItem>
                                <NavLink
                                  className={{
                                    active:
                                      dischargePatientFormData?.vitals_selected_fetch_filter ===
                                      "last_24_hours",
                                  }}
                                  onClick={() => {
                                    if (
                                      dischargePatientFormData?.vitals_selected_fetch_filter ==
                                      "last_24_hours" &&
                                      appointmentDetails &&
                                      appointmentDetails.appointment_start
                                    ) {
                                      toggleVitalsTab(null);
                                    } else {
                                      toggleVitalsTab("last_24_hours");
                                    }
                                  }}
                                >
                                  Last 24 Hours
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={{
                                    active:
                                      dischargePatientFormData?.vitals_selected_fetch_filter ===
                                      "last_48_hours",
                                  }}
                                  onClick={() => {
                                    if (
                                      dischargePatientFormData?.vitals_selected_fetch_filter ==
                                      "last_48_hours" &&
                                      appointmentDetails &&
                                      appointmentDetails.appointment_start
                                    ) {
                                      toggleVitalsTab(null);
                                    } else {
                                      toggleVitalsTab("last_48_hours");
                                    }
                                  }}
                                >
                                  Last 48 Hrs
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={{
                                    active:
                                      dischargePatientFormData?.vitals_selected_fetch_filter ===
                                      "last_72_hours",
                                  }}
                                  onClick={() => {
                                    if (
                                      dischargePatientFormData?.vitals_selected_fetch_filter ==
                                      "last_72_hours" &&
                                      appointmentDetails &&
                                      appointmentDetails.appointment_start
                                    ) {
                                      // fetchVitals("from_appointment_details");
                                      toggleVitalsTab(null);
                                    } else {
                                      // fetchVitals("last_72_hours");
                                      toggleVitalsTab("last_72_hours");
                                    }
                                  }}
                                >
                                  Last 72 Hrs
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={{
                                    active:
                                      dischargePatientFormData?.vitals_selected_fetch_filter ===
                                      "most_recents",
                                  }}
                                  onClick={() => {
                                    if (
                                      dischargePatientFormData?.vitals_selected_fetch_filter ==
                                      "most_recents" &&
                                      appointmentDetails &&
                                      appointmentDetails.appointment_start
                                    ) {
                                      // fetchVitals("from_appointment_details");
                                      toggleVitalsTab(null);
                                    } else {
                                      // fetchVitals("most_recents");
                                      toggleVitalsTab("most_recents");
                                    }
                                  }}
                                >
                                  Most Recent
                                </NavLink>
                              </NavItem>
                            </Nav>
                            <TabContent
                              activeTab={
                                dischargePatientFormData?.vitals_selected_fetch_filter
                              }
                              className="overflow-auto "
                            >
                              <Vitals />
                            </TabContent>
                          </div>
                        </div>
                      </div>

                      <div className="row-block d-flex mt-3">
                        <div className="head w-[25%] me-3">
                          <h3>Medications Administered</h3>
                        </div>
                        <div className="inner  w-[65%]">
                          <div className="owner-tab-blc discharge-patient-table">
                            <Nav tabs className="flex-shrink-0">
                              <NavItem>
                                <NavLink
                                  className={{
                                    active:
                                      dischargePatientFormData?.medications_administered_selected_fetch_filter ===
                                      "last_24_hours",
                                  }}
                                  onClick={() => {
                                    if (
                                      dischargePatientFormData?.medications_administered_selected_fetch_filter ==
                                      "last_24_hours" &&
                                      appointmentDetails &&
                                      appointmentDetails.appointment_start
                                    ) {
                                      toggleMedicationAdministeredTab(null);
                                    } else {
                                      toggleMedicationAdministeredTab(
                                        "last_24_hours"
                                      );
                                    }
                                  }}
                                >
                                  Last 24 Hours
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={{
                                    active:
                                      dischargePatientFormData?.medications_administered_selected_fetch_filter ===
                                      "last_48_hours",
                                  }}
                                  onClick={() => {
                                    if (
                                      dischargePatientFormData?.medications_administered_selected_fetch_filter ==
                                      "last_48_hours" &&
                                      appointmentDetails &&
                                      appointmentDetails.appointment_start
                                    ) {
                                      toggleMedicationAdministeredTab(null);
                                    } else {
                                      toggleMedicationAdministeredTab(
                                        "last_48_hours"
                                      );
                                    }
                                  }}
                                >
                                  Last 48 Hrs
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={{
                                    active:
                                      dischargePatientFormData?.medications_administered_selected_fetch_filter ===
                                      "last_72_hours",
                                  }}
                                  onClick={() => {
                                    if (
                                      dischargePatientFormData?.medications_administered_selected_fetch_filter ==
                                      "last_72_hours" &&
                                      appointmentDetails &&
                                      appointmentDetails.appointment_start
                                    ) {
                                      toggleMedicationAdministeredTab(null);
                                    } else {
                                      toggleMedicationAdministeredTab(
                                        "last_72_hours"
                                      );
                                    }
                                  }}
                                >
                                  Last 72 Hrs
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={{
                                    active:
                                      dischargePatientFormData?.medications_administered_selected_fetch_filter ===
                                      "most_recents",
                                  }}
                                  onClick={() => {
                                    if (
                                      dischargePatientFormData?.medications_administered_selected_fetch_filter ==
                                      "most_recents" &&
                                      appointmentDetails &&
                                      appointmentDetails.appointment_start
                                    ) {
                                      toggleMedicationAdministeredTab(null);
                                    } else {
                                      toggleMedicationAdministeredTab(
                                        "most_recents"
                                      );
                                    }
                                  }}
                                >
                                  Most Recent
                                </NavLink>
                              </NavItem>
                            </Nav>
                            <TabContent
                              activeTab={
                                dischargePatientFormData?.medications_administered_selected_fetch_filter
                              }
                              className="overflow-auto "
                            >
                              {/* {marsOrders && marsOrders.length ? ( */}
                              {marsOrders && marsOrders.length > 0 ? (
                                <Table
                                  striped
                                  className="diagnostic-table"
                                  responsive
                                >
                                  <thead>
                                    <tr>
                                      <td>Medicine Name</td>
                                      <td>Dose</td>
                                      <td>Date</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {marsOrders?.map((item) => {
                                      return (
                                        <>
                                          <tr>
                                            <td>
                                              {
                                                item?.orders?.product
                                                  ?.product_name
                                              }
                                            </td>

                                            <td>
                                              {item?.dose
                                                ? parseFloat(item?.dose) +
                                                " " +
                                                item?.unit +
                                                " " +
                                                item?.route +
                                                " "
                                                : ""}
                                            </td>

                                            <td>
                                              {moment(item?.date).format(
                                                "MM/DD/YYYY hh:mm a"
                                              )}
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              ) : (
                                <NoDataFound  />
                              )}
                            </TabContent>
                          </div>
                        </div>
                      </div>

                      <div className="row-block d-flex mt-3">
                        <div className="head w-[25%] me-3">
                          <h3>Diagnostic Testing</h3>
                        </div>
                        <div className="inner  w-[65%]">
                          <div className="owner-tab-blc discharge-patient-table">
                            <Nav tabs className="flex-shrink-0">
                              <NavItem>
                                <NavLink
                                  className={{
                                    active:
                                      dischargePatientFormData?.diagnostic_tests_selected_fetch_filter ===
                                      "last_24_hours",
                                  }}
                                  onClick={() => {
                                    if (
                                      dischargePatientFormData?.diagnostic_tests_selected_fetch_filter ==
                                      "last_24_hours" &&
                                      appointmentDetails &&
                                      appointmentDetails.appointment_start
                                    ) {
                                      // fetchDiagnosticResults(
                                      //   "from_appointment_details"
                                      // );
                                      toggleDiagnosisTab(null);
                                    } else {
                                      // fetchDiagnosticResults("last_24_hours");
                                      toggleDiagnosisTab("last_24_hours");
                                    }
                                  }}
                                >
                                  Last 24 Hours
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={{
                                    active:
                                      dischargePatientFormData?.diagnostic_tests_selected_fetch_filter ===
                                      "last_48_hours",
                                  }}
                                  onClick={() => {
                                    if (
                                      dischargePatientFormData?.diagnostic_tests_selected_fetch_filter ==
                                      "last_48_hours" &&
                                      appointmentDetails &&
                                      appointmentDetails.appointment_start
                                    ) {
                                      // fetchDiagnosticResults(
                                      //   "from_appointment_details"
                                      // );
                                      toggleDiagnosisTab(null);
                                    } else {
                                      // fetchDiagnosticResults("last_48_hours");
                                      toggleDiagnosisTab("last_48_hours");
                                    }
                                  }}
                                >
                                  Last 48 Hrs
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={{
                                    active:
                                      dischargePatientFormData?.diagnostic_tests_selected_fetch_filter ===
                                      "last_72_hours",
                                  }}
                                  onClick={() => {
                                    if (
                                      dischargePatientFormData?.diagnostic_tests_selected_fetch_filter ==
                                      "last_72_hours" &&
                                      appointmentDetails &&
                                      appointmentDetails.appointment_start
                                    ) {
                                      // fetchDiagnosticResults(
                                      //   "from_appointment_details"
                                      // );
                                      toggleDiagnosisTab(null);
                                    } else {
                                      // fetchDiagnosticResults("last_72_hours");
                                      toggleDiagnosisTab("last_72_hours");
                                    }
                                  }}
                                >
                                  Last 72 Hrs
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={{
                                    active:
                                      dischargePatientFormData?.diagnostic_tests_selected_fetch_filter ===
                                      "most_recents",
                                  }}
                                  onClick={() => {
                                    if (
                                      dischargePatientFormData?.diagnostic_tests_selected_fetch_filter ==
                                      "most_recents" &&
                                      appointmentDetails &&
                                      appointmentDetails.appointment_start
                                    ) {
                                      // fetchDiagnosticResults(
                                      //   "from_appointment_details"
                                      // );
                                      toggleDiagnosisTab(null);
                                    } else {
                                      // fetchDiagnosticResults("most_recents");
                                      toggleDiagnosisTab("most_recents");
                                    }
                                  }}
                                >
                                  Most Recent
                                </NavLink>
                              </NavItem>
                            </Nav>
                            <TabContent
                              activeTab={
                                dischargePatientFormData?.diagnostic_tests_selected_fetch_filter
                              }
                              className="overflow-auto "
                            >
                              {/* <TabPane tabId="5"> */}
                              {diagnosticResults &&
                                diagnosticResults.data &&
                                diagnosticResults.dates &&
                                diagnosticResults.dates.length > 0 &&
                                diagnosticResults.data.length > 0 ? (
                                diagnosticResults.data?.map((item, i) => {
                                  return (
                                    <div className="accordion-blc d-flex flex-column">
                                      <h4 className="testname-head mt-1">
                                        {item?.category?.category}
                                      </h4>

                                      <Table
                                        striped
                                        className="diagnostic-table"
                                        responsive
                                      >
                                        <thead>
                                          <tr>
                                            <td>Test Name</td>
                                            <td>Range</td>
                                            {diagnosticResults.dates?.map(
                                              (date, p) => {
                                                date = new Date(date?._id);
                                                return (
                                                  <td key={p}>
                                                    {date.toLocaleDateString(
                                                      "en-US"
                                                    )}{" "}
                                                    <br />
                                                    {date.toLocaleTimeString(
                                                      "en-US"
                                                    )}
                                                  </td>
                                                );
                                              }
                                            )}
                                          </tr>
                                        </thead>

                                        <tbody>
                                          {item?.tests?.map((test, j) => {
                                            return (
                                              <>
                                                <tr key={j}>
                                                  <td style={{whiteSpace: 'normal !important'}} className="d-flex">
                                                    <div className="toggle-item-blc">
                                                      <div className="item-rw d-flex">
                                                        <div className="form-check form-switch">
                                                          <input
                                                            checked={
                                                              !!diagnosticToggle[
                                                              test?.test
                                                                ?._id
                                                              ]
                                                            }
                                                            onChange={(e) => {
                                                              handleToggle(
                                                                test?.test
                                                                  ?._id,
                                                                e.target.checked
                                                              );
                                                            }}
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="flexSwitchCheckDefault"
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div style={{whiteSpace: 'normal'}}>
                                                    {test?.test?.test_name}
                                                    </div>
                                                  </td>
                                                  {test?.results?.[test?.results?.length - 1]?.referenceRange && test?.results?.[test?.results?.length - 1]?.referenceRange !==
                                                    "" ? (
                                                    <td>{`${test?.results?.[test?.results?.length - 1]?.referenceRange}`}</td>
                                                  ) : (
                                                    <td>--</td>
                                                  )}
                                                  {diagnosticResults.dates.map(
                                                    (date, d) => {
                                                      let result =
                                                        data_from_date(
                                                          test?.results,
                                                          date?._id
                                                        );

                                                      return result !== null ? (
                                                        test?.test?.low ? (
                                                          parseFloat(
                                                            result?.result
                                                          ) >=
                                                            parseFloat(
                                                              test?.test
                                                                ?.low
                                                            ) &&
                                                            parseFloat(
                                                              result?.result
                                                            ) <=
                                                            parseFloat(
                                                              test.test
                                                                .high
                                                            ) ? (
                                                            <td key={d}>
                                                              {result?.result}
                                                            </td>
                                                          ) : parseFloat(
                                                            result?.result
                                                          ) <
                                                            parseFloat(
                                                              test?.test
                                                                ?.low
                                                            ) ? (
                                                            <td className="low-val">
                                                              {result?.result} L
                                                            </td>
                                                          ) : (
                                                            <td className="high-val">
                                                              {result?.result} H
                                                            </td>
                                                          )
                                                        ) : (
                                                          <td key={d}>
                                                            {result?.result}
                                                          </td>
                                                        )
                                                      ) : (
                                                        <td key={d}>--</td>
                                                      );
                                                    }
                                                  )}
                                                </tr>
                                              </>
                                            );
                                          })}
                                        </tbody>
                                      </Table>
                                    </div>
                                  );
                                })
                              ) : (
                                <NoDataFound />
                              )}
                              {/* </TabPane> */}

                              {/* <TabPane tabId="5">
                              <h4 className="testname-head ">Hematology</h4>
                              <Table striped className="diagnostic-table">
                                <thead>
                                  <tr>
                                    <td>Test Name</td>
                                    <td>Range</td>
                                    <td>8/22/2022 08:10AM</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>WBC</td>
                                    <td>6.0-13.0</td>
                                    <td>6.3</td>
                                  </tr>
                                  <tr>
                                    <td>RBC</td>
                                    <td>5.6-8.0</td>
                                    <td>5.4L</td>
                                  </tr>
                                  <tr>
                                    <td>HGB</td>
                                    <td>14-19</td>
                                    <td>18</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </TabPane> */}
                              {/* <TabPane tabId="6">
                              2
                            </TabPane>
                            <TabPane tabId="7">
                              3
                            </TabPane>
                            <TabPane tabId="8">
                              4
                            </TabPane> */}
                            </TabContent>
                          </div>
                        </div>
                      </div>
                      {/*
                      <div className="row-block d-flex mt-3">
                        <div className="head w-[25%] me-3">
                          <h3>Imaging</h3>

                          <div className="order-btn mt-3">
                            <button className="btn btn-outline-light mb-2">
                              Add New
                            </button>
                          </div>
                        </div>
                        <div className="inner  w-[73%]">
                          <div className="owner-tab-blc discharge-patient-table outer-imaging d-flex flex-wrap mb-3">
                            <div className="position-relative imgaging-table flex-1">
                              <table>
                                <tbody>
                                  <tr>
                                    <td>Type</td>
                                    <td>X-Ray</td>
                                  </tr>
                                  <tr>
                                    <td>X-Ray Name</td>
                                    <td>Abdominal Cavity</td>
                                  </tr>
                                  <tr>
                                    <td>Capture Date & Time</td>
                                    <td>
                                      <span>MM/DD/YYYY HH:MM</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="d-flex align-items-center ms-2">
                              <div className="x-ray-img d-flex flex-wrap">
                                <img
                                  src={require("../../assets/img/xray5.png")}
                                />
                                <img
                                  src={require("../../assets/img/xray1.png")}
                                />
                              </div>
                              <div class="file file--upload">
                                <label for="input-file">
                                  <img
                                    src={require("../../assets/img/plusicongreen.png")}
                                  />
                                </label>
                                <input id="input-file" type="file" />
                              </div>
                            </div>
                          </div>
                          <div className="owner-tab-blc discharge-patient-table outer-imaging d-flex flex-wrap">
                            <div className="position-relative imgaging-table flex-1">
                              <table>
                                <tr>
                                  <td>Type</td>
                                  <td>X-Ray</td>
                                </tr>
                                <tr>
                                  <td>X-Ray Name</td>
                                  <td>Abdominal Cavity</td>
                                </tr>
                                <tr>
                                  <td>Capture Date & Time</td>
                                  <td>
                                    <span>MM/DD/YYYY HH:MM</span>
                                  </td>
                                </tr>
                              </table>
                            </div>
                            <div className="d-flex align-items-center ms-3">
                              <div className="x-ray-img  d-flex flex-wrap">
                                <img
                                  src={require("../../assets/img/xray5.png")}
                                />
                                <img
                                  src={require("../../assets/img/xray1.png")}
                                />
                              </div>
                              <div class="file file--upload">
                                <label for="input-file">
                                  <img
                                    src={require("../../assets/img/plusicongreen.png")}
                                  />
                                </label>
                                <input id="input-file" type="file" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>*/}

                      <div className="row-block d-flex mt-3">
                        <div className="head w-[25%] me-3">
                          <h3>Procedures</h3>
                        </div>
                        <div className="inner  w-[73%]">
                          <div className="owner-tab-blc discharge-patient-table">
                            {/* <Table
                              striped
                              className="diagnostic-table"
                              responsive
                            >
                              <thead>
                                <tr>
                                  <td>Order Name</td>
                                  <td>Status</td>
                                  <td>Date</td>
                                  <td>Details</td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>ACL Repair</td>
                                  <td>
                                    <span className="badge state-badge mb-0">
                                      ordered
                                    </span>
                                  </td>
                                  <td>8/22/2022 08:10AM</td>
                                  <td>Dr. Martha William</td>
                                </tr>
                              </tbody>
                            </Table> */}

                            {/* {dischargePatientFormData &&
                              dischargePatientFormData.procedureOrders?.map(
                                (item, i) => {
                                  //Object.keys(tempData).map((key, i ) => {
                                  return ( */}
                            {dischargePatientFormData &&
                              dischargePatientFormData.procedures &&
                              dischargePatientFormData.procedures.length > 0 ? (
                              <Table
                                className="diagnostic-table"
                                borderless
                                responsive
                                striped
                              >
                                <thead>
                                  <tr>
                                    <th width="30%">Order Name</th>
                                    <th width="10%">Status</th>
                                    <th width="20%">Date</th>
                                    <th>Details</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {dischargePatientFormData &&
                                    dischargePatientFormData.procedures?.map(
                                      (order, j) => {
                                        return (
                                          <>
                                            <tr key={j}>
                                              <td width="30%">{order?.name}</td>
                                              <td width="10%">
                                                <span
                                                  style={{
                                                    borderRadius: "3px",
                                                    border:
                                                      "0.5px solid rgba(255, 255, 255, 0.20)",
                                                    padding: "2px 4px",

                                                    color:
                                                      order?.status ===
                                                        "Stopped"
                                                        ? "#DE2B2B"
                                                        : "#169677",
                                                    background:
                                                      order?.status ===
                                                        "Stopped"
                                                        ? "#FFECF0"
                                                        : "#DBF8F1",
                                                  }}
                                                >
                                                  {order?.status}
                                                </span>
                                              </td>
                                              <td width="20%">
                                                {new Date(
                                                  order?.first_dose
                                                ).toLocaleString("en-US", {
                                                  year: "numeric",
                                                  month: "numeric",
                                                  day: "numeric",
                                                  hour: "numeric",
                                                  minute: "numeric",
                                                  hour12: true,
                                                })}
                                              </td>
                                              <td>
                                                {order?.notes
                                                  ? order?.notes
                                                  : "--"}
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      }
                                    )}
                                </tbody>
                              </Table>
                            ) : (
                              <NoDataFound />
                            )}
                            {/* 
                               );
                                 }
                               )} */}
                          </div>
                        </div>
                      </div>

                      <div className="row-block d-flex mt-3">
                        <div className="head w-[25%] me-3">
                          <h3>Prescription Routing</h3>
                        </div>
                        <div className="inner  w-[73%]">
                          <div className="owner-tab-blc discharge-patient-table">
                            <h4 className="testname-head mb-3">
                              For Discharge Rx
                            </h4>
                          </div>
                          <div className="stv-radio-buttons-wrapper">
                            <input
                              type="radio"
                              className="stv-radio-button"
                              name="e_prescribe"
                              value="1"
                              id="button1"
                              checked={
                                dischargePatientFormData?.prescription_routing ==
                                "e_prescribe"
                              }
                              onClick={() => {
                                // setPrescriptionRoutingButton("e_prescribe")
                                handleInputChange(
                                  "prescription_routing",
                                  "e_prescribe"
                                );
                              }}
                            />
                            <label for="button1">E-Prescribe</label>
                            <input
                              type="radio"
                              className="stv-radio-button"
                              name="print_script"
                              value="2"
                              id="button2"
                              checked={
                                dischargePatientFormData?.prescription_routing ==
                                "print_script"
                              }
                              onClick={() => {
                                // setPrescriptionRoutingButton("print_script")
                                handleInputChange(
                                  "prescription_routing",
                                  "print_script"
                                );
                              }}
                            />
                            <label for="button2">Print Script</label>
                          </div>
                        </div>
                      </div>

                      <NewOrderPanel
                        ref={childRef}
                        setFormSubmitted={setFormSubmitted}
                        formSubmitted={formSubmitted}
                        className={"active"}
                        appointmentDischargeFormData={
                          appointmentDischargeFormData
                        }
                      />
                      <NewTreatmentPlan
                        ref={treatmentPlanChildRef}
                        setFormSubmitted={setTreatmentPlanFormSubmitted}
                        formSubmitted={treatmentPlanFormSubmitted}
                        className={"active"}
                        appointmentDischargeFormData={
                          appointmentDischargeFormData
                        }
                      />
                      <div className="row-block d-flex mt-3">
                        <div className="head w-[25%] me-3">
                          <h3>Medication and Order Reconciliation</h3>
                        </div>
                        <div className="inner  w-[73%]">
                          <div className="owner-tab-blc discharge-patient-table">
                            <div className="reconciliation-subtitle mb-3">
                              Home Medication and orders
                            </div>
                          </div>
                          {dischargePatientFormData &&
                            dischargePatientFormData.home_medication_and_orders &&
                            dischargePatientFormData.home_medication_and_orders
                              .length > 0 ? (
                            dischargePatientFormData.home_medication_and_orders.map(
                              (item, i) => {
                                return (
                                  <div
                                    key={i}
                                    className="d-flex medication-order-wrap justify-content-between mb-3"
                                  >
                                    <div>
                                      <h4> {item?.name}</h4>
                                      <p className="text-muted">
                                        {item?.notes}
                                      </p>
                                    </div>
                                    <div>
                                      <div className="stv-radio-buttons-wrapper">
                                        <input
                                          type="radio"
                                          className="stv-radio-button"
                                          name={`home-${i}`}
                                          id={`home-continue-${i}`}
                                          onClick={() => {
                                            handleArrOfObjChange(
                                              `home_medication_and_orders`,
                                              i,
                                              "status",
                                              "Continue"
                                            );
                                          }}
                                          checked={item?.status == "Continue"}
                                        />
                                        <label htmlFor={`home-continue-${i}`}>
                                          Continue
                                        </label>

                                        <input
                                          type="radio"
                                          className="stv-radio-button"
                                          name={`home-${i}`}
                                          id={`home-stop-${i}`}
                                          onClick={() => {
                                            handleArrOfObjChange(
                                              `home_medication_and_orders`,
                                              i,
                                              "status",
                                              "Stopped"
                                            );
                                          }}
                                          checked={item?.status == "Stopped"}
                                        />
                                        <label htmlFor={`home-stop-${i}`}>
                                          Stop{" "}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )
                          ) : (
                            <NoDataFound />
                          )}

                          <div className="owner-tab-blc discharge-patient-table">
                            <div className="reconciliation-subtitle mb-3">
                              Inpatient orders
                            </div>
                          </div>
                          {dischargePatientFormData &&
                            dischargePatientFormData.inpatient_orders &&
                            dischargePatientFormData.inpatient_orders.length >
                            0 ? (
                            dischargePatientFormData.inpatient_orders.map(
                              (item, i) => {
                                return (
                                  <div
                                    key={i}
                                    className="d-flex medication-order-wrap justify-content-between mb-3"
                                  >
                                    <div>
                                      <h4> {item?.name}</h4>
                                      <p className="text-muted">
                                        {item?.notes}
                                      </p>
                                    </div>
                                    <div>
                                      <div className="stv-radio-buttons-wrapper">
                                        <input
                                          type="radio"
                                          className="stv-radio-button"
                                          name={`inpatient-${i}`}
                                          id={`inpatient-continue-${i}`}
                                          onClick={() => {
                                            handleArrOfObjChange(
                                              `inpatient_orders`,
                                              i,
                                              "status",
                                              "Continue"
                                            );
                                          }}
                                          checked={item?.status == "Continue"}
                                        />
                                        <label
                                          htmlFor={`inpatient-continue-${i}`}
                                        >
                                          Continue
                                        </label>

                                        <input
                                          type="radio"
                                          className="stv-radio-button"
                                          name={`inpatient-${i}`}
                                          id={`inpatient-stop-${i}`}
                                          onClick={() => {
                                            handleArrOfObjChange(
                                              `inpatient_orders`,
                                              i,
                                              "status",
                                              "Stopped"
                                            );
                                          }}
                                          checked={item?.status == "Stopped"}
                                        />
                                        <label htmlFor={`inpatient-stop-${i}`}>
                                          Stop{" "}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )
                          ) : (
                            <NoDataFound />
                          )}
                        </div>
                      </div>

                      <AddDiagnosisCondition
                        ref={childRefForAddDiagnosisForm}
                        setFormSubmitted={setFormSubmittedForAddDiagnosisForm}
                        formSubmitted={formSubmittedForAddDiagnosisForm}
                        className={"active"}
                        appointmentDischargeFormData={
                          appointmentDischargeFormData
                        }
                        petId={petId}
                      />

                      <div className="row-block d-flex mt-3">
                        <div className="head w-[25%] me-3">
                          <h3>Problem List</h3>

                          <div className="input-group my-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search Diagnosis"
                            />
                            <span className="input-group-text mb-0">
                              <i className="icomoon-search"></i>
                            </span>
                          </div>

                          {/* <div className="order-btn mt-3">
                            <button className="btn btn-outline-light mb-2">
                              Add New
                            </button>
                          </div> */}
                        </div>
                        {dischargePatientFormData &&
                          dischargePatientFormData?.problems &&
                          dischargePatientFormData?.problems.length > 0 ? (
                          <div className="inner  w-[73%]">
                            <div className="owner-tab-blc">
                              {dischargePatientFormData?.problems?.map(
                                (item, i) => (
                                  <div
                                    key={i}
                                    className="d-flex medication-order-wrap justify-content-between align-items-center"
                                  >
                                    <div>
                                      <h4>{item?.description}</h4>
                                    </div>
                                    <div>
                                      <div className="stv-radio-buttons-wrapper">
                                        <input
                                          type="radio"
                                          className="stv-radio-button"
                                          name={`condition-${i}`}
                                          value="active"
                                          id={`condition-active-${i}`}
                                          checked={
                                            item?.status === "active"
                                              ? true
                                              : false
                                          }
                                          onClick={
                                            () => {
                                              let prob_copy = [
                                                ...dischargePatientFormData?.problems,
                                              ];
                                              prob_copy[i].status = "active";
                                              dispatch({
                                                type: "DISCHARGE_PATIENT_FORM_DATA",
                                                payload: {
                                                  ...dischargePatientFormData,
                                                  problems: [...prob_copy],
                                                },
                                              });
                                            }

                                            // handleInputChange("")
                                          }
                                        />
                                        <label
                                          htmlFor={`condition-active-${i}`}
                                        >
                                          Active
                                        </label>
                                        <input
                                          type="radio"
                                          className="stv-radio-button"
                                          name={`condition-${i}`}
                                          value="resolved"
                                          id={`condition-resolved-${i}`}
                                          checked={
                                            item?.status === "resolved"
                                              ? true
                                              : false
                                          }
                                          onClick={() => {
                                            let prob_copy = [
                                              ...dischargePatientFormData?.problems,
                                            ];
                                            prob_copy[i].status = "resolved";
                                            dispatch({
                                              type: "DISCHARGE_PATIENT_FORM_DATA",
                                              payload: {
                                                ...dischargePatientFormData,
                                                problems: [...prob_copy],
                                              },
                                            });
                                          }}
                                        //setProblemListButton1("resolved")
                                        />
                                        <label
                                          htmlFor={`condition-resolved-${i}`}
                                        >
                                          Resolved{" "}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        ) : (
                          <NoDataFound />
                        )}
                      </div>
                      <div className="row-block d-flex mt-3">
                        <div className="head w-[25%] me-3">
                          <h3>Discharge Instructions</h3>

                          <div className="input-group my-3">
                            {/* <input
                              type="text"
                              className="form-control"
                              placeholder="Search instructions"
                            />
                            <span className="input-group-text mb-0">
                              <i className="icomoon-search"></i>
                            </span> */}

                            <Select
                              className="w-[100%]"
                              isMulti={true}
                              filterOption={customFilter}
                              options={
                                dischargeInstructions &&
                                dischargeInstructions?.length > 0 &&
                                dischargeInstructions?.map((data) => ({
                                  value: data?._id,
                                  label: data?.document_name,
                                  // (
                                  //   data?.document_name

                                  //   // <div className="flex items-center gap-1">
                                  //   // </div>
                                  // ),
                                  ...data,
                                }))
                              }
                              value={selectedDischargeInstructions}
                              onChange={(e) => {
                                setSelectedDischargeInstructions(e);

                                let vals = e.map((ele) => {
                                  return {
                                    document_name: ele.document_name,
                                    notes: ele.notes,
                                    document_link: ele.document_link,
                                    document_type: ele.document_type,
                                    document_id: ele?._id,
                                  };
                                });
                                handleInputChange(
                                  "discharge_instructions",
                                  vals
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="inner  w-[73%]">
                          <div className="owner-tab-blc">
                            {dischargePatientFormData &&
                              dischargePatientFormData.discharge_instructions &&
                              dischargePatientFormData.discharge_instructions
                                .length > 0 ? (
                              dischargePatientFormData.discharge_instructions.map(
                                (ele, index) => {
                                  return (
                                    <div className="d-flex medication-order-wrap align-items-center mb-3 position-relative">
                                      <div
                                        className="medicine-img"
                                        onClick={() => {
                                          window.open(
                                            `${ele.document_link}`,
                                            // `${imageBaseUrl}${documentPhotosPath}/${ele.document_link}`,
                                            "_blank",
                                            "noreferrer"
                                          );
                                        }}
                                      >
                                        <img
                                          src={
                                            ele.document_link
                                              .split(".")[3]?.substring(0,3)
                                              .toLowerCase() != "pdf"
                                              ? `${ele.document_link}`
                                              // ? `${imageBaseUrl}${documentPhotosPath}/${ele.document_link}`
                                              : require("../../assets/img/PDF_file_icon.svg.png")
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <div className="ms-3">
                                        <h3>{ele.document_name}</h3>
                                        <p>{ele.notes}</p>
                                      </div>
                                      <span
                                        className="icomoon-trash order-del"
                                        onClick={() => {
                                          handleDeleteDischargeInstruction(
                                            index
                                          );
                                        }}
                                      ></span>
                                    </div>
                                  );
                                }
                              )
                            ) : (
                              <NoDataFound />
                            )}

                            {/* <div className="d-flex medication-order-wrap align-items-center mb-3">
                              <div className="medicine-img">
                                <img
                                  src={require("../../assets/img/xray1.png")}
                                />
                              </div>
                              <div className="ms-3">
                                <h3>[title]</h3>
                                <p>
                                  Lorem Ipsum is simply dummy text of the
                                  printing industry.
                                </p>
                              </div>
                            </div>
                            <div className="d-flex medication-order-wrap align-items-center">
                              <div className="medicine-img">
                                <img
                                  src={require("../../assets/img/xray1.png")}
                                />
                              </div>
                              <div className="ms-3">
                                <h3>[title]</h3>
                                <p>
                                  Lorem Ipsum is simply dummy text of the
                                  printing industry.
                                </p>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="discharge_report_row">
                  <div className="head text-center">
                    <h3 className="mb-1">Discharge Report</h3>
                  </div>
                  <div className="avs-img-row">
                    <img
                      src={require("../../assets/img/discharge-patient-right-panel-img.png")}
                    />
                    {/* <img src={require("../../assets/img/invoice-img.png")} className="m-auto" /> */}
                    <div className="order-btn pb-3 text-center">
                      <button
                        className="btn btn-outline-light"
                        onClick={handleShow}
                      >
                        Preview AVS
                      </button>
                    </div>

                    <Offcanvas
                      show={show}
                      onHide={handleClose}
                      placement="end"
                      className="border-0 patient-offcanvas"
                      style={{ maxWidth: '50%' }}

                    >
                      <Offcanvas.Body >
                        <div className="right-slide-offcanvas p-3 mb-3">
                          <table
                            width="100%"
                            style={{
                              fontFamily: '"Inter"',
                              padding: 20,
                              margin: "auto",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td style={{ width: "100%" }}>
                                  <table style={{ width: "100%" }}>
                                    <tbody>
                                      <tr>
                                        <th
                                          colSpan={2}
                                          style={{
                                            color: "#282E65",
                                            paddingBottom: 10,
                                            fontFamily: "Inter",
                                            fontSize: 18,
                                            fontWeight: 700,
                                            textAlign: "center",
                                          }}
                                        >
                                          Visit Summaries
                                        </th>
                                      </tr>
                                      <tr>
                                        <td style={{ color: "#282E65" }}>
                                          <h4
                                            style={{
                                              fontSize: 13,
                                              margin: 0,
                                              fontWeight: 600,
                                            }}
                                          >
                                            {
                                              appointmentDetails?.patient_id
                                                ?.pet_name
                                            }
                                          </h4>
                                          <p
                                            style={{
                                              fontSize: 8,
                                              margin: "3px 0 0",
                                              fontWeight: 500,
                                            }}
                                          >
                                            Assessment ID:{" "}
                                            {appointmentDetails?._id}
                                          </p>
                                          <p
                                            style={{
                                              fontSize: 8,
                                              margin: "3px 0 0",
                                              fontWeight: 500,
                                            }}
                                          >
                                            Observation Date:{" "}
                                            {dateFromDateTime(
                                              appointmentDetails?.appointment_start,
                                              "MM-DD-yyyy"
                                            )}{" "}
                                            {timeFromDateTime(
                                              appointmentDetails?.appointment_start,
                                              "hh:mm a"
                                            )}
                                          </p>
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                          <table style={{ width: "100%" }}>
                                            <tbody>
                                              <tr>
                                                <td>
                                                  <h4
                                                    style={{
                                                      fontSize: 13,
                                                      margin: 0,
                                                      fontWeight: 600,
                                                    }}
                                                  >
                                                    {
                                                      clinic_details?.clinic_name
                                                    }
                                                  </h4>
                                                  <p
                                                    style={{
                                                      fontSize: 8,
                                                      margin: "3px 0 0",
                                                      fontWeight: 500,
                                                    }}
                                                  >
                                                    {clinic_details?.address}
                                                  </p>
                                                  <p
                                                    style={{
                                                      fontSize: 8,
                                                      margin: "3px 0 0",
                                                      fontWeight: 500,
                                                    }}
                                                  >
                                                    Email:{" "}
                                                    {clinic_details?.email}
                                                  </p>
                                                  <p
                                                    style={{
                                                      fontSize: 8,
                                                      margin: "3px 0 0",
                                                      fontWeight: 500,
                                                    }}
                                                  >
                                                    Phone:{" "}
                                                    {formatPhoneNumber(
                                                      clinic_details?.phone
                                                    )}
                                                  </p>
                                                </td>
                                                <td style={{ width: 50 }}>
                                                  <img
                                                    src={require("../../assets/img/logo.png")}
                                                    style={{
                                                      marginLeft: "auto",
                                                    }}
                                                  />
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          colSpan={2}
                                          style={{ height: 20 }}
                                        />
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table
                                    style={{
                                      width: "100%",
                                      borderLeft: "2px solid  #3D3EFD",
                                      background: "rgba(248, 248, 248, 0.90)",
                                      tableLayout: "fixed",
                                    }}
                                  >
                                    <thead>
                                      <tr>
                                        <td
                                          className="recent-color text-capitalize"
                                          style={{
                                            color: "#3D3EFD",
                                            fontSize: 13,
                                            fontWeight: 600,
                                            padding: 8,
                                          }}
                                        >
                                          Vitals :{" "}
                                          {dischargePatientFormData?.vitals_selected_fetch_filter !==
                                            null
                                            ? dischargePatientFormData?.vitals_selected_fetch_filter?.replaceAll(
                                              "_",
                                              " "
                                            )
                                            : ""}
                                        </td>
                                        <td
                                          style={{
                                            color: "#788693",
                                            textAlign: "end",
                                            fontSize: 9,
                                          }}
                                        ></td>
                                      </tr>
                                    </thead>
                                  </table>

                                  {dischargePatientFormData &&
                                    dischargePatientFormData?.vitals &&
                                    dischargePatientFormData?.vitals.length >
                                    0 ? (
                                    <table style={{ width: "100%" }}>
                                      <tbody>
                                        {dischargePatientFormData?.vitals?.map(
                                          (ele) => {
                                            return ele?.results?.map((item) => (
                                              <>
                                                <tr>
                                                  <td colSpan={7}>
                                                    <div
                                                      style={{
                                                        paddingLeft: "6px",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "30px",
                                                        color: "#282E65",
                                                        paddingTop: 10,
                                                      }}
                                                    >
                                                      <p
                                                        style={{
                                                          fontSize: 9,
                                                          margin: "3px 0 0",
                                                        }}
                                                      >
                                                        Vital Date And Time
                                                      </p>

                                                      <p
                                                        style={{
                                                          fontSize: 9,
                                                          margin: "3px 0 0",
                                                        }}
                                                      >
                                                        {moment(
                                                          item?.date
                                                        ).format(
                                                          "MM/DD/YYYY hh:mmA"
                                                        )}
                                                      </p>
                                                    </div>
                                                  </td>
                                                </tr>

                                                {largeVitalKeysArr?.map(
                                                  (smallArr) => {
                                                    return (
                                                      <tr>
                                                        {smallArr.map(
                                                          (vitalField) => {
                                                            return (
                                                              <td
                                                                style={{
                                                                  color:
                                                                    "#282E65",
                                                                  paddingTop: 10,
                                                                }}
                                                              >
                                                                <p
                                                                  style={{
                                                                    fontSize: 9,
                                                                    margin:
                                                                      "3px 0 0",
                                                                  }}
                                                                >
                                                                  {
                                                                    vitalField.name
                                                                  }
                                                                </p>
                                                                <p
                                                                  style={{
                                                                    fontSize: 10,
                                                                    margin:
                                                                      "3px 0 0",
                                                                    fontWeight: 500,
                                                                  }}
                                                                >
                                                                  {
                                                                    item[
                                                                    vitalField
                                                                      .key
                                                                    ]
                                                                  }
                                                                </p>
                                                              </td>
                                                            );
                                                          }
                                                        )}
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                              </>
                                            ));
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  ) : (
                                    <NoDataFound />
                                  )}

                                  <table
                                    style={{
                                      width: "100%",
                                      borderLeft: "2px solid  #3D3EFD",
                                      tableLayout: "fixed",
                                      background: "rgba(248, 248, 248, 0.90)",
                                      padding: 8,
                                    }}
                                  >
                                    <thead>
                                      <tr>
                                        <td
                                          className="recent-color text-capitalize"
                                          style={{
                                            color: "#3D3EFD",
                                            fontSize: 13,
                                            fontWeight: 600,
                                            padding: 8,
                                          }}
                                        >
                                          Medications Administered :{" "}
                                          {dischargePatientFormData?.medications_administered_selected_fetch_filter !==
                                            null
                                            ? dischargePatientFormData?.medications_administered_selected_fetch_filter?.replaceAll(
                                              "_",
                                              " "
                                            )
                                            : ""}
                                        </td>
                                      </tr>
                                    </thead>
                                  </table>
                                  {dischargePatientFormData?.marsOrders &&
                                    dischargePatientFormData?.marsOrders?.length >
                                    0 ? (
                                    <table
                                      style={{
                                        width: "100%",
                                        borderBottom: "1px solid #EEEEF2",
                                        borderCollapse: "collapse",
                                        color: "#282E65",
                                        tableLayout: "fixed",
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            colSpan={3}
                                            style={{ height: 10 }}
                                          ></td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              paddingLeft: "6px",

                                              fontSize: 9,
                                              borderBottom: "1px solid #EEEEF2",
                                            }}
                                          >
                                            Medication Name
                                          </td>
                                          <td
                                            style={{
                                              paddingLeft: "6px",

                                              borderBottom: "1px solid #EEEEF2",
                                              fontSize: 9,
                                            }}
                                          >
                                            Dose
                                          </td>
                                          <td
                                            style={{
                                              paddingLeft: "6px",

                                              borderBottom: "1px solid #EEEEF2",
                                              fontSize: 9,
                                            }}
                                          >
                                            Date
                                          </td>
                                        </tr>
                                        {dischargePatientFormData?.marsOrders?.map(
                                          (item, i) => (
                                            <tr
                                              style={{
                                                backgroundColor:
                                                  i % 2 === 0
                                                    ? "rgba(228, 228, 228, 0.28)"
                                                    : "none",
                                              }}
                                            >
                                              <td
                                                style={{
                                                  fontSize: 10,
                                                  fontWeight: 500,

                                                  padding: "10px 6px",
                                                }}
                                              >
                                                {item?.medication_name}
                                              </td>
                                              <td
                                                style={{
                                                  fontSize: 10,
                                                  fontWeight: 500,

                                                  padding: "10px 6px",
                                                }}
                                              >
                                                {parseFloat(item?.dose)}
                                              </td>
                                              <td
                                                style={{
                                                  fontSize: 10,
                                                  fontWeight: 500,

                                                  padding: "10px 6px",
                                                }}
                                              >
                                                {moment(item?.date).format(
                                                  "MM/DD/YYYY hh:mm a"
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  ) : (
                                    <NoDataFound />
                                  )}

                                  <table
                                    style={{
                                      width: "100%",
                                      borderLeft: "2px solid  #3D3EFD",
                                      marginTop: 20,
                                      background: "rgba(248, 248, 248, 0.90)",
                                      padding: 8,
                                      tableLayout: "fixed",
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          className="recent-color text-capitalize"
                                          style={{
                                            color: "#3D3EFD",
                                            fontSize: 13,
                                            fontWeight: 600,
                                            padding: 8,
                                          }}
                                        >
                                          Diagnostic Testing :{" "}
                                          {dischargePatientFormData?.diagnostic_tests_selected_fetch_filter !==
                                            null
                                            ? dischargePatientFormData?.diagnostic_tests_selected_fetch_filter?.replaceAll(
                                              "_",
                                              " "
                                            )
                                            : ""}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  {dischargePatientFormData &&
                                    dischargePatientFormData.diagnosticResults &&
                                    dischargePatientFormData?.diagnosticResults
                                      ?.data &&
                                    dischargePatientFormData?.diagnosticResults
                                      ?.data.length > 0 &&
                                    dischargePatientFormData?.diagnosticResults
                                      ?.dates &&
                                    dischargePatientFormData?.diagnosticResults
                                      ?.dates.length > 0 ? (
                                    <table
                                      style={{
                                        width: "100%",
                                        borderBottom: "1px solid #EEEEF2 ",
                                        borderCollapse: "collapse",
                                        color: "#282E65",
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            colSpan={3}
                                            style={{ height: 10 }}
                                          ></td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              paddingLeft: "6px",
                                              borderBottom: "1px solid #EEEEF2",
                                              fontSize: 9,
                                            }}
                                          >
                                            Test Name
                                          </td>
                                          <td
                                            style={{
                                              paddingLeft: "6px",

                                              borderBottom:
                                                "1px solid #EEEEF2 ",
                                              fontSize: 9,
                                            }}
                                          >
                                            Range
                                          </td>
                                          <td
                                            style={{
                                              paddingLeft: "6px",

                                              borderBottom:
                                                "1px solid #EEEEF2 ",
                                              fontSize: 9,
                                            }}
                                          >
                                            Value
                                          </td>
                                        </tr>
                                        {dischargePatientFormData?.diagnosticResults?.data?.map(
                                          (item, i) =>
                                            item?.tests?.map((test, j) => {
                                              const isEven = j % 2 === 0;
                                              const styles = isEven
                                                ? {
                                                  fontSize: 10,
                                                  fontWeight: 500,
                                                  backgroundColor:
                                                    "rgba(228, 228, 228, 0.28)",
                                                  padding: "10px 6px",
                                                }
                                                : {
                                                  fontSize: 10,
                                                  fontWeight: 500,
                                                  padding: "10px 6px",
                                                };

                                              const isTestChecked =
                                                diagnosticToggle[
                                                  test?.test?._id
                                                ]
                                                  ? true
                                                  : false;


                                              return (
                                                <React.Fragment key={i}>
                                                  {isTestChecked ? (
                                                    <tr>
                                                      <td style={styles}>
                                                        {test?.test?.test_name}
                                                      </td>
                                                      {test?.results?.[test?.results?.length - 1]?.referenceRange && test?.results?.[test?.results?.length - 1]?.referenceRange !==
                                                        "" ? (
                                                        <td style={styles}>
                                                          {`${test?.results?.[test?.results?.length - 1]?.referenceRange}`}
                                                        </td>
                                                      ) : (
                                                        <td style={styles}>
                                                          --
                                                        </td>
                                                      )}
                                                      {dischargePatientFormData?.diagnosticResults?.dates?.map(
                                                        (date, d) => {
                                                          const result =
                                                            data_from_date(
                                                              test?.results,
                                                              date?._id
                                                            );

                                                          return (
                                                            <>
                                                              {result !==
                                                                null ? (
                                                                test?.test
                                                                  ?.low ? (
                                                                  parseFloat(
                                                                    result?.result
                                                                  ) >=
                                                                    parseFloat(
                                                                      test?.test
                                                                        .low
                                                                    ) &&
                                                                    parseFloat(
                                                                      result?.result
                                                                    ) <=
                                                                    parseFloat(
                                                                      test?.test
                                                                        ?.high
                                                                    ) ? (
                                                                    <td
                                                                      style={
                                                                        styles
                                                                      }
                                                                    >
                                                                      {
                                                                        result?.result
                                                                      }
                                                                    </td>
                                                                  ) : parseFloat(
                                                                    result?.result
                                                                  ) <
                                                                    parseFloat(
                                                                      test?.test
                                                                        ?.low
                                                                    ) ? (
                                                                    <td
                                                                      style={
                                                                        styles
                                                                      }
                                                                    >{`${result?.result} L`}</td>
                                                                  ) : (
                                                                    <td
                                                                      style={
                                                                        styles
                                                                      }
                                                                    >{`${result?.result} H`}</td>
                                                                  )
                                                                ) : (
                                                                  <td
                                                                    style={
                                                                      styles
                                                                    }
                                                                  >
                                                                    {
                                                                      result?.result
                                                                    }
                                                                  </td>
                                                                )
                                                              ) : (
                                                                <td
                                                                  style={styles}
                                                                >
                                                                  --
                                                                </td>
                                                              )}
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </tr>
                                                  ) : null}
                                                </React.Fragment>
                                              );
                                            })
                                        )}
                                      </tbody>
                                    </table>
                                  ) : (
                                    <NoDataFound />
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="right-slide-offcanvas p-3 mb-3">
                          <table
                            width="100%"
                            style={{
                              fontFamily: '"Inter"',
                              padding: 20,
                              margin: "auto",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td style={{ width: "100%" }}>
                                  <table style={{ width: "100%" }}>
                                    <tbody>
                                      <tr>
                                        <th
                                          colSpan={2}
                                          style={{
                                            color: "#282E65",
                                            paddingBottom: 10,
                                            fontFamily: "Inter",
                                            fontSize: 18,
                                            fontWeight: 700,
                                            textAlign: "center",
                                          }}
                                        >
                                          Visit Summaries
                                        </th>
                                      </tr>
                                      <tr>
                                        <td style={{ color: "#282E65" }}>
                                          <h4
                                            style={{
                                              fontSize: 13,
                                              margin: 0,
                                              fontWeight: 600,
                                            }}
                                          >
                                            {
                                              appointmentDetails?.patient_id
                                                ?.pet_name
                                            }
                                          </h4>
                                          <p
                                            style={{
                                              fontSize: 8,
                                              margin: "3px 0 0",
                                              fontWeight: 500,
                                            }}
                                          >
                                            Assessment ID:{" "}
                                            {appointmentDetails?._id}
                                          </p>
                                          <p
                                            style={{
                                              fontSize: 8,
                                              margin: "3px 0 0",
                                              fontWeight: 500,
                                            }}
                                          >
                                            Observation Date:{" "}
                                            {dateFromDateTime(
                                              appointmentDetails?.appointment_start,
                                              "MM-DD-yyyy"
                                            )}{" "}
                                            {timeFromDateTime(
                                              appointmentDetails?.appointment_start,
                                              "hh:mm a"
                                            )}
                                          </p>
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                          <table style={{ width: "100%" }}>
                                            <tbody>
                                              <tr>
                                                <td>
                                                  <h4
                                                    style={{
                                                      fontSize: 13,
                                                      margin: 0,
                                                      fontWeight: 600,
                                                    }}
                                                  >
                                                    {
                                                      clinic_details?.clinic_name
                                                    }
                                                  </h4>
                                                  <p
                                                    style={{
                                                      fontSize: 8,
                                                      margin: "3px 0 0",
                                                      fontWeight: 500,
                                                    }}
                                                  >
                                                    {clinic_details?.address}
                                                  </p>
                                                  <p
                                                    style={{
                                                      fontSize: 8,
                                                      margin: "3px 0 0",
                                                      fontWeight: 500,
                                                    }}
                                                  >
                                                    Email:{" "}
                                                    {clinic_details?.email}
                                                  </p>
                                                  <p
                                                    style={{
                                                      fontSize: 8,
                                                      margin: "3px 0 0",
                                                      fontWeight: 500,
                                                    }}
                                                  >
                                                    Phone:{" "}
                                                    {formatPhoneNumber(
                                                      clinic_details?.phone
                                                    )}
                                                  </p>
                                                </td>
                                                <td style={{ width: 50 }}>
                                                  <img
                                                    src={require("../../assets/img/logo.png")}
                                                    style={{
                                                      marginLeft: "auto",
                                                    }}
                                                  />
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          colSpan={2}
                                          style={{ height: 20 }}
                                        />
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table
                                    style={{
                                      width: "100%",
                                      borderLeft: "2px solid  #3D3EFD",
                                      tableLayout: "fixed",
                                      background: "rgba(248, 248, 248, 0.90)",
                                      padding: 8,
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          className="recent-color"
                                          style={{
                                            color: "#3D3EFD",
                                            fontSize: 13,
                                            fontWeight: 600,
                                            padding: 8,
                                          }}
                                        >
                                          Procedures
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  {dischargePatientFormData &&
                                    dischargePatientFormData.procedures &&
                                    dischargePatientFormData.procedures.length >
                                    0 ? (
                                    <table
                                      style={{
                                        width: "100%",
                                        borderBottom: "1px solid #EEEEF2 ",
                                        borderCollapse: "collapse",
                                        color: "#282E65",
                                        tableLayout: "fixed",
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            colSpan={3}
                                            style={{ height: 10 }}
                                          ></td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              paddingLeft: "6px",

                                              borderBottom: "1px solid #EEEEF2",
                                              fontSize: 9,
                                            }}
                                          >
                                            Name
                                          </td>
                                          <td
                                            style={{
                                              paddingLeft: "6px",

                                              borderBottom: "1px solid #EEEEF2",
                                              fontSize: 9,
                                            }}
                                          >
                                            Status
                                          </td>
                                          <td
                                            style={{
                                              paddingLeft: "6px",

                                              borderBottom: "1px solid #EEEEF2",
                                              fontSize: 9,
                                            }}
                                          >
                                            Date
                                          </td>
                                          <td
                                            style={{
                                              paddingLeft: "6px",

                                              borderBottom:
                                                "1px solid #EEEEF2 ",
                                              fontSize: 9,
                                            }}
                                          >
                                            Notes
                                          </td>
                                        </tr>
                                        {dischargePatientFormData?.procedures
                                          ?.length > 0 &&
                                          dischargePatientFormData?.procedures?.map(
                                            (item, i) => {
                                              return (
                                                <tr
                                                  key={i}
                                                  style={{
                                                    background:
                                                      i % 2 === 0
                                                        ? "rgba(228, 228, 228, 0.28)"
                                                        : "none",
                                                  }}
                                                >
                                                  <td
                                                    style={{
                                                      fontSize: 10,
                                                      fontWeight: 500,

                                                      padding: "8px 0",
                                                    }}
                                                  >
                                                    {item?.name}
                                                  </td>
                                                  <td
                                                    style={{
                                                      fontSize: 10,
                                                      fontWeight: 500,

                                                      padding: "8px 0",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          item?.status ===
                                                            "Stopped"
                                                            ? "#DE2B2B"
                                                            : "#169677",
                                                        background:
                                                          item?.status ===
                                                            "Stopped"
                                                            ? "#FFECF0"
                                                            : "#DBF8F1",
                                                        borderRadius: "3px",
                                                        border:
                                                          "0.5px solid rgba(255, 255, 255, 0.20)",
                                                        fontSize: 9,
                                                        padding: "2px 4px",
                                                        lineHeight: "normal",
                                                        textTransform:
                                                          "capitalize",
                                                      }}
                                                    >
                                                      {item?.status}
                                                    </span>
                                                  </td>
                                                  <td
                                                    style={{
                                                      fontSize: 10,
                                                      fontWeight: 500,

                                                      padding: "8px 0",
                                                    }}
                                                  >
                                                    {new Date(
                                                      item?.first_dose
                                                    ).toLocaleString("en-US", {
                                                      year: "numeric",
                                                      month: "numeric",
                                                      day: "numeric",
                                                      hour: "numeric",
                                                      minute: "numeric",
                                                      hour12: true,
                                                    })}
                                                  </td>
                                                  <td
                                                    style={{
                                                      fontSize: 10,
                                                      fontWeight: 500,

                                                      padding: "8px 0",
                                                    }}
                                                  >
                                                    {item?.notes}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                      </tbody>
                                    </table>
                                  ) : (
                                    <NoDataFound />
                                  )}

                                  <table
                                    style={{
                                      width: "100%",
                                      borderLeft: "2px solid  #3D3EFD",
                                      tableLayout: "fixed",
                                      marginTop: 20,
                                      background: "rgba(248, 248, 248, 0.90)",
                                      padding: 8,
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          className="recent-color"
                                          style={{
                                            color: "#3D3EFD",
                                            fontSize: 13,
                                            fontWeight: 600,
                                            padding: 8,
                                          }}
                                        >
                                          Prescription Routing
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table>
                                    <tbody>
                                      <tr>
                                        <td
                                          style={{
                                            color: "#282E65",
                                            padding: "10px 0",
                                            fontSize: 10,
                                            fontWeight: 400,
                                          }}
                                          className="text-capitalize"
                                        >
                                          {dischargePatientFormData?.prescription_routing !==
                                            null
                                            ? dischargePatientFormData?.prescription_routing?.replaceAll(
                                              "_",
                                              "-"
                                            )
                                            : ""}{" "}
                                          for Discharge
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table
                                    style={{
                                      width: "100%",
                                      borderLeft: "2px solid  #3D3EFD",
                                      tableLayout: "fixed",
                                      marginTop: 20,
                                      background: "rgba(248, 248, 248, 0.90)",
                                      padding: 8,
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          className="recent-color"
                                          style={{
                                            color: "#3D3EFD",
                                            fontSize: 13,
                                            fontWeight: 600,
                                            padding: 8,
                                          }}
                                        >
                                          Order for Discharge
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  {dischargePatientFormData &&
                                    dischargePatientFormData.order_for_discharge &&
                                    dischargePatientFormData.order_for_discharge
                                      .length > 0 ? (
                                    <table
                                      style={{
                                        width: "100%",
                                        borderCollapse: "collapse",
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td style={{ height: 10 }} />
                                        </tr>
                                        {dischargePatientFormData
                                          ?.order_for_discharge.length > 0 &&
                                          dischargePatientFormData?.order_for_discharge.map(
                                            (item) => {
                                              console.log('item', item)
                                              return (
                                                <tr
                                                  key={item?.product_service_id}
                                                >
                                                  <td>
                                                    <table
                                                      style={{
                                                        width: "100%",
                                                        padding: 10,
                                                        backgroundColor:
                                                          "rgba(248, 248, 248, 0.90)",
                                                      }}
                                                    >
                                                      <tbody>
                                                        <tr>
                                                          <td
                                                            style={{
                                                              width: "20%",
                                                              color: "#282E65",
                                                              verticalAlign:
                                                                "top",
                                                            }}
                                                          >
                                                            <p
                                                              style={{
                                                                fontSize: 9,
                                                                margin:
                                                                  "3px 0 0",
                                                              }}
                                                              className="text-capitalize"
                                                            >
                                                              Name
                                                            </p>
                                                            <p
                                                              style={{
                                                                fontSize: 10,
                                                                margin:
                                                                  "3px 0 0",
                                                                fontWeight: 500,
                                                              }}
                                                            >
                                                              {item?.product_service_name ||
                                                                "--"}
                                                            </p>
                                                          </td>
                                                          <td
                                                            style={{
                                                              width: "20%",

                                                              color: "#282E65",
                                                              verticalAlign:
                                                                "top",
                                                            }}
                                                          >
                                                            <p
                                                              style={{
                                                                fontSize: 9,
                                                                margin:
                                                                  "3px 0 0",
                                                              }}
                                                            >
                                                              Dose
                                                            </p>
                                                            <p
                                                              style={{
                                                                fontSize: 10,
                                                                margin:
                                                                  "3px 0 0",
                                                                fontWeight: 500,
                                                              }}
                                                            >
                                                              {parseFloat(item?.dose)}{" "}
                                                              {item?.form}
                                                              {", "}
                                                              {item?.route ||
                                                                ""}
                                                            </p>
                                                          </td>
                                                          <td
                                                            style={{
                                                              width: "20%",

                                                              color: "#282E65",
                                                              verticalAlign:
                                                                "top",
                                                            }}
                                                          >
                                                            <p
                                                              style={{
                                                                fontSize: 9,
                                                                margin:
                                                                  "3px 0 0",
                                                              }}
                                                              className="text-capitalize"
                                                            >
                                                              Frequency
                                                            </p>
                                                            <p
                                                              style={{
                                                                fontSize: 10,
                                                                margin:
                                                                  "3px 0 0",
                                                                fontWeight: 500,
                                                              }}
                                                            >
                                                              {item?.frequency}
                                                            </p>
                                                          </td>
                                                          <td
                                                            style={{
                                                              width: "20%",

                                                              color: "#282E65",
                                                              verticalAlign:
                                                                "top",
                                                            }}
                                                          >
                                                            <p
                                                              style={{
                                                                fontSize: 9,
                                                                margin:
                                                                  "3px 0 0",
                                                              }}
                                                            >
                                                              Start Date &amp;
                                                              Time
                                                            </p>
                                                            <p
                                                              style={{
                                                                fontSize: 10,
                                                                margin:
                                                                  "3px 0 0",
                                                                fontWeight: 500,
                                                              }}
                                                            >
                                                              {dateFromDateTime(
                                                                item?.start_datetime,
                                                                "MM/DD/yyyy"
                                                              )}{" "}
                                                              {timeFromDateTime(
                                                                item?.start_datetime,
                                                                "hh:mm A"
                                                              )}
                                                            </p>
                                                          </td>
                                                          <td
                                                            style={{
                                                              width: "20%",

                                                              color: "#282E65",
                                                              verticalAlign:
                                                                "top",
                                                            }}
                                                          >
                                                            <p
                                                              style={{
                                                                fontSize: 9,
                                                                margin:
                                                                  "3px 0 0",
                                                              }}
                                                            >
                                                              Duration
                                                            </p>
                                                            <p
                                                              style={{
                                                                fontSize: 10,
                                                                margin:
                                                                  "3px 0 0",
                                                                fontWeight: 500,
                                                              }}
                                                            >
                                                              {
                                                                item?.duration_num
                                                              }{" "}
                                                              {
                                                                item?.duration_unit
                                                              }
                                                            </p>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td
                                                            colSpan={3}
                                                            style={{
                                                              fontSize: 10,
                                                              margin: "3px 0 0",
                                                              color: "#282E65",
                                                              padding:
                                                                "10px 0 0",
                                                            }}
                                                          >
                                                            Comments :{" "}
                                                            {item?.notes}
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                      </tbody>
                                    </table>
                                  ) : (
                                    <NoDataFound />
                                  )}
                                  {/* treatment plan start */}
                                  <table
                                    style={{
                                      width: "100%",
                                      borderLeft: "2px solid  #3D3EFD",
                                      tableLayout: "fixed",
                                      marginTop: 20,
                                      background: "rgba(248, 248, 248, 0.90)",
                                      padding: 8,
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          className="recent-color"
                                          style={{
                                            color: "#3D3EFD",
                                            fontSize: 13,
                                            fontWeight: 600,
                                            padding: 8,
                                          }}
                                        >
                                          Treatment Plan for Discharge
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  {dischargePatientFormData &&
                                    dischargePatientFormData.treatmentPlan_for_discharge &&
                                    dischargePatientFormData.treatmentPlan_for_discharge
                                      .length > 0 ? (
                                    <table
                                      style={{
                                        width: "100%",
                                        borderCollapse: "collapse",
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td style={{ height: 10 }} />
                                        </tr>
                                        {dischargePatientFormData
                                          ?.treatmentPlan_for_discharge.length > 0 &&
                                          dischargePatientFormData?.treatmentPlan_for_discharge.map(
                                            (item) => {
                                              return (
                                                <tr
                                                  key={item?.product_instruction_id}
                                                >
                                                  <td>
                                                    <table
                                                      style={{
                                                        width: "100%",
                                                        padding: 10,
                                                        backgroundColor:
                                                          "rgba(248, 248, 248, 0.90)",
                                                      }}
                                                    >
                                                      <tbody>
                                                        <tr>
                                                          <td
                                                            style={{
                                                              width: "20%",
                                                              color: "#282E65",
                                                              verticalAlign:
                                                                "top",
                                                            }}
                                                          >
                                                            <p
                                                              style={{
                                                                fontSize: 9,
                                                                margin:
                                                                  "3px 0 0",
                                                              }}
                                                              className="text-capitalize"
                                                            >
                                                              Name
                                                            </p>
                                                            <p
                                                              style={{
                                                                fontSize: 10,
                                                                margin:
                                                                  "3px 0 0",
                                                                fontWeight: 500,
                                                              }}
                                                            >
                                                              {item?.name ||
                                                                "--"}
                                                            </p>
                                                          </td>

                                                          <td
                                                            style={{
                                                              width: "20%",

                                                              color: "#282E65",
                                                              verticalAlign:
                                                                "top",
                                                            }}
                                                          >
                                                            <p
                                                              style={{
                                                                fontSize: 9,
                                                                margin:
                                                                  "3px 0 0",
                                                              }}
                                                              className="text-capitalize"
                                                            >
                                                              Frequency
                                                            </p>
                                                            <p
                                                              style={{
                                                                fontSize: 10,
                                                                margin:
                                                                  "3px 0 0",
                                                                fontWeight: 500,
                                                              }}
                                                            >
                                                              {item?.frequency}
                                                            </p>
                                                          </td>
                                                          <td
                                                            style={{
                                                              width: "20%",

                                                              color: "#282E65",
                                                              verticalAlign:
                                                                "top",
                                                            }}
                                                          >
                                                            <p
                                                              style={{
                                                                fontSize: 9,
                                                                margin:
                                                                  "3px 0 0",
                                                              }}
                                                            >
                                                              Start Date &amp;
                                                              Time
                                                            </p>
                                                            <p
                                                              style={{
                                                                fontSize: 10,
                                                                margin:
                                                                  "3px 0 0",
                                                                fontWeight: 500,
                                                              }}
                                                            >
                                                              {dateFromDateTime(
                                                                item?.start_datetime,
                                                                "MM/DD/yyyy"
                                                              )}{" "}
                                                              {timeFromDateTime(
                                                                item?.start_datetime,
                                                                "hh:mm A"
                                                              )}
                                                            </p>
                                                          </td>
                                                          <td
                                                            style={{
                                                              width: "20%",

                                                              color: "#282E65",
                                                              verticalAlign:
                                                                "top",
                                                            }}
                                                          >
                                                            <p
                                                              style={{
                                                                fontSize: 9,
                                                                margin:
                                                                  "3px 0 0",
                                                              }}
                                                            >
                                                              Duration
                                                            </p>
                                                            <p
                                                              style={{
                                                                fontSize: 10,
                                                                margin:
                                                                  "3px 0 0",
                                                                fontWeight: 500,
                                                              }}
                                                            >
                                                              {
                                                                item?.duration_num
                                                              }{" "}
                                                              {
                                                                item?.duration_unit
                                                              }
                                                            </p>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td
                                                            colSpan={3}
                                                            style={{
                                                              fontSize: 10,
                                                              margin: "3px 0 0",
                                                              color: "#282E65",
                                                              padding:
                                                                "10px 0 0",
                                                            }}
                                                          >
                                                            Comments :{" "}
                                                            {item?.notes}
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                      </tbody>
                                    </table>
                                  ) : (
                                    <NoDataFound />
                                  )}
                                  {/* treatment plan end */}
                                  <table
                                    style={{
                                      width: "100%",
                                      borderLeft: "2px solid  #3D3EFD",
                                      tableLayout: "fixed",
                                      marginTop: 20,
                                      background: "rgba(248, 248, 248, 0.90)",
                                      padding: 8,
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          className="recent-color"
                                          style={{
                                            color: "#3D3EFD",
                                            fontSize: 13,
                                            fontWeight: 600,
                                            padding: 8,
                                          }}
                                        >
                                          Medication and Order Reconciliation
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  {(dischargePatientFormData &&
                                    dischargePatientFormData.home_medication_and_orders &&
                                    dischargePatientFormData
                                      .home_medication_and_orders.length > 0) ||
                                    (dischargePatientFormData.inpatient_orders &&
                                      dischargePatientFormData.inpatient_orders
                                        .length > 0) ? (
                                    <table
                                      style={{
                                        width: "100%",
                                        borderBottom: "1px solid #EEEEF2 ",
                                        borderCollapse: "collapse",
                                        color: "#282E65",
                                        tableLayout: "fixed",
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            colSpan={4}
                                            style={{ height: 10 }}
                                          ></td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              paddingLeft: "6px",

                                              borderBottom:
                                                "1px solid #EEEEF2 ",
                                              fontSize: 9,
                                            }}
                                          >
                                            Medication Name
                                          </td>
                                          <td
                                            style={{
                                              paddingLeft: "6px",

                                              borderBottom:
                                                "1px solid #EEEEF2 ",
                                              fontSize: 9,
                                            }}
                                          >
                                            Dose
                                          </td>
                                          <td
                                            style={{
                                              paddingLeft: "6px",

                                              borderBottom:
                                                "1px solid #EEEEF2 ",
                                              fontSize: 9,
                                            }}
                                          >
                                            Frequency
                                          </td>
                                          <td
                                            style={{
                                              paddingLeft: "6px",

                                              borderBottom:
                                                "1px solid #EEEEF2 ",
                                              fontSize: 9,
                                            }}
                                          >
                                            Status
                                          </td>
                                        </tr>
                                        {(dischargePatientFormData
                                          ?.home_medication_and_orders?.length >
                                          0 ||
                                          dischargePatientFormData
                                            ?.inpatient_orders.length > 0) &&
                                          [
                                            ...(dischargePatientFormData?.home_medication_and_orders ||
                                              []),
                                            ...(dischargePatientFormData?.inpatient_orders ||
                                              []),
                                          ].map((item, i) => {
                                            return (
                                              <tr
                                                style={{
                                                  background:
                                                    i % 2 === 0
                                                      ? "rgba(228, 228, 228, 0.28)"
                                                      : "none",
                                                }}
                                                key={i}
                                              >
                                                <td
                                                  style={{
                                                    fontSize: 10,
                                                    fontWeight: 500,

                                                    padding: "10px 6px",
                                                  }}
                                                >
                                                  {item?.name}
                                                </td>

                                                <td
                                                  style={{
                                                    fontSize: 10,
                                                    fontWeight: 500,

                                                    padding: "10px 6px",
                                                  }}
                                                >
                                                  {item?.dose} {item?.form},{" "}
                                                  {item?.route}
                                                </td>
                                                <td
                                                  style={{
                                                    fontSize: 10,
                                                    fontWeight: 500,

                                                    padding: "10px 6px",
                                                  }}
                                                >
                                                  {item?.frequency}
                                                </td>

                                                <td
                                                  style={{
                                                    fontSize: 10,
                                                    fontWeight: 500,

                                                    padding: "10px 6px",
                                                    color:
                                                      item?.status ===
                                                        "Continue"
                                                        ? "#11CA9D "
                                                        : "#DE2B2B ",
                                                  }}
                                                >
                                                  {item?.status}
                                                </td>
                                              </tr>
                                            );
                                          })}

                                        {/*dischargePatientFormData
                                          ?.inpatient_orders.length > 0 &&
                                          dischargePatientFormData?.inpatient_orders.map(
                                            (item) => {
                                              return (
                                                <tr>
                                                  <td
                                                    style={{
                                                      fontSize: 10,
                                                      fontWeight: 500,
                                                 
                                                      padding: "6px 0",
                                                    }}
                                                  >
                                                    {item?.name}
                                                  </td>
                                                  <td
                                                    style={{
                                                      fontSize: 10,
                                                      fontWeight: 500,
                                                     
                                                      padding: "6px 0",
                                                    }}
                                                  >
                                                    {item?.dose} {item?.form}, {item?.route}
                                                  </td>
                                                  <td
                                                    style={{
                                                      fontSize: 10,
                                                      fontWeight: 500,
                                                    
                                                      padding: "6px 0",
                                                    }}
                                                  >
                                                    {item?.frequency}
                                                  </td>
                                              
                                                  <td
                                                    style={{
                                                      fontSize: 10,
                                                      fontWeight: 500,
                                                      backgroundColor:
                                                        "rgba(248, 248, 248, 0.90)",
                                                      padding: "6px 0",
                                                      color: (item?.status === 'Continue' ?  "#11CA9D " : '#DE2B2B')
                                                    }}
                                                  >
                                                    {item?.status}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )*/}
                                      </tbody>
                                    </table>
                                  ) : (
                                    <NoDataFound />
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="right-slide-offcanvas p-3 mb-3">
                          <table
                            width="100%"
                            style={{
                              fontFamily: '"Inter"',
                              padding: 20,
                              margin: "auto",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td style={{ width: "100%" }}>
                                  <table style={{ width: "100%" }}>
                                    <tbody>
                                      <tr>
                                        <th
                                          colSpan={2}
                                          style={{
                                            color: "#282E65",
                                            paddingBottom: 10,
                                            fontFamily: "Inter",
                                            fontSize: 18,
                                            fontWeight: 700,
                                            textAlign: "center",
                                          }}
                                        >
                                          Visit Summaries
                                        </th>
                                      </tr>
                                      <tr>
                                        <td style={{ color: "#282E65" }}>
                                          <h4
                                            style={{
                                              fontSize: 13,
                                              margin: 0,
                                              fontWeight: 600,
                                            }}
                                          >
                                            {
                                              appointmentDetails?.patient_id
                                                ?.pet_name
                                            }
                                          </h4>
                                          <p
                                            style={{
                                              fontSize: 8,
                                              margin: "3px 0 0",
                                              fontWeight: 500,
                                            }}
                                          >
                                            Assessment ID:{" "}
                                            {appointmentDetails?._id}
                                          </p>
                                          <p
                                            style={{
                                              fontSize: 8,
                                              margin: "3px 0 0",
                                              fontWeight: 500,
                                            }}
                                          >
                                            Observation Date:{" "}
                                            {dateFromDateTime(
                                              appointmentDetails?.appointment_start,
                                              "MM-DD-yyyy"
                                            )}{" "}
                                            {timeFromDateTime(
                                              appointmentDetails?.appointment_start,
                                              "hh:mm a"
                                            )}
                                          </p>
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                          <table style={{ width: "100%" }}>
                                            <tbody>
                                              <tr>
                                                <td>
                                                  <h4
                                                    style={{
                                                      fontSize: 13,
                                                      margin: 0,
                                                      fontWeight: 600,
                                                    }}
                                                  >
                                                    {
                                                      clinic_details?.clinic_name
                                                    }
                                                  </h4>
                                                  <p
                                                    style={{
                                                      fontSize: 8,
                                                      margin: "3px 0 0",
                                                      fontWeight: 500,
                                                    }}
                                                  >
                                                    {clinic_details?.address}
                                                  </p>
                                                  <p
                                                    style={{
                                                      fontSize: 8,
                                                      margin: "3px 0 0",
                                                      fontWeight: 500,
                                                    }}
                                                  >
                                                    Email:{" "}
                                                    {clinic_details?.email}
                                                  </p>
                                                  <p
                                                    style={{
                                                      fontSize: 8,
                                                      margin: "3px 0 0",
                                                      fontWeight: 500,
                                                    }}
                                                  >
                                                    Phone:{" "}
                                                    {formatPhoneNumber(
                                                      clinic_details?.phone
                                                    )}
                                                  </p>
                                                </td>
                                                <td style={{ width: 50 }}>
                                                  <img
                                                    alt="logoImage"
                                                    src={require("../../assets/img/logo.png")}
                                                    style={{
                                                      marginLeft: "auto",
                                                    }}
                                                  />
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          colSpan={2}
                                          style={{ height: 20 }}
                                        />
                                      </tr>
                                    </tbody>
                                  </table>

                                  <table
                                    style={{
                                      width: "100%",
                                      borderLeft: "2px solid  #3D3EFD",
                                      tableLayout: "fixed",
                                      background: "rgba(248, 248, 248, 0.90)",
                                      padding: 8,
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          className="recent-color"
                                          style={{
                                            color: "#3D3EFD",
                                            fontSize: 13,
                                            fontWeight: 600,
                                            padding: 8,
                                          }}
                                        >
                                          Problem List
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  {dischargePatientFormData &&
                                    dischargePatientFormData.problems &&
                                    dischargePatientFormData.problems.length >
                                    0 ? (
                                    <div className="d-flex align-items-center flex-wrap mt-2">
                                      {dischargePatientFormData?.problems
                                        ?.length > 0 &&
                                        dischargePatientFormData?.problems?.map(
                                          (item) => {
                                            return (
                                              <div
                                                className="d-flex align-items-center"
                                                style={{
                                                  backgroundColor:
                                                    "rgba(228, 228, 228, 0.28)",
                                                  padding: 10,
                                                  borderRadius: 10,
                                                  minWidth: "100px",
                                                  marginBottom: "10px",
                                                  marginRight: "10px",
                                                }}
                                              >
                                                <div
                                                  style={{ fontSize: "12px" }}
                                                >
                                                  {item?.description}
                                                </div>
                                                {item?.status === "active" ? (
                                                  <span
                                                    style={{
                                                      color: "#169677",
                                                      backgroundColor:
                                                        "#DBF8F1",
                                                      fontSize: 9,
                                                      padding: "3px 5px",
                                                      lineHeight: "normal",
                                                      textTransform:
                                                        "capitalize",
                                                      borderRadius: 4,
                                                      marginLeft: 10,
                                                    }}
                                                  >
                                                    active
                                                  </span>
                                                ) : (
                                                  <span
                                                    style={{
                                                      color: "#7660FF",
                                                      backgroundColor:
                                                        "#E0E9FF",
                                                      fontSize: 9,
                                                      padding: "3px 5px",
                                                      lineHeight: "normal",
                                                      textTransform:
                                                        "capitalize",
                                                      borderRadius: 4,
                                                      marginLeft: 10,
                                                    }}
                                                  >
                                                    Resolved
                                                  </span>
                                                )}
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                  ) : (
                                    <NoDataFound />
                                  )}

                                  <table
                                    style={{
                                      width: "100%",
                                      borderLeft: "2px solid  #3D3EFD",
                                      tableLayout: "fixed",
                                      marginTop: 20,
                                      background: "rgba(248, 248, 248, 0.90)",
                                      padding: 8,
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          className="recent-color"
                                          style={{
                                            color: "#3D3EFD",
                                            fontSize: 13,
                                            fontWeight: 600,
                                            padding: 8,
                                          }}
                                        >
                                          Discharge Instructions
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  {/* <div className="d-flex flex-wrap mt-2 justify-content-between"> */}
                                  {dischargePatientFormData &&
                                    dischargePatientFormData.discharge_instructions &&
                                    dischargePatientFormData
                                      .discharge_instructions.length > 0 ? (
                                    dischargePatientFormData?.discharge_instructions.map(
                                      (item) => {
                                        return (
                                          <div className="d-flex flex-wrap mt-2 justify-content-between">
                                            <div
                                              className="d-flex mb-2"
                                              style={{
                                                backgroundColor: "#f7f7f7",
                                                padding: "10px",
                                                borderRadius: "10px",
                                                color: "#282e65",
                                                fontSize: "10px",
                                                width: "49%",
                                                gap: "10px",
                                              }}
                                            >
                                              <div

                                                style={{
                                                  verticalAlign: "top",
                                                  maxWidth: '50px',
                                                  maxHeight: '50px',
                                                  textAlign: "center",
                                                  borderRadius: 6,
                                                }}
                                              >
                                                <img
                                                  // src={`${imageBaseUrl}${documentPhotosPath}/${item.document_link}`}
                                                  src={
                                                    item.document_link
                                                    .split(".")[3]?.substring(0,3)
                                                    .toLowerCase() != "pdf"
                                                    ? `${item.document_link}`
                                                      // ? `${imageBaseUrl}${documentPhotosPath}/${item.document_link}`
                                                      : require("../../assets/img/PDF_file_icon.svg.png")
                                                  }
                                                />
                                              </div>
                                              <div
                                                style={{
                                                  gap: "5px",
                                                }}
                                              >
                                                <p>{item?.document_name}</p>
                                                <p>{item?.notes}</p>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )
                                  ) : (
                                    <NoDataFound />
                                  )}
                                  {/* </div> */}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Offcanvas.Body>
                    </Offcanvas>
                  </div>
                  <div
                    style={{ width: "100%" }}
                    className="proceed-btns d-flex align-items-center justify-content-between"
                  >
                    <Button color="link" className="btn btn-outline-light">
                      Cancel
                    </Button>
                    <Button
                      type="button"
                      color="success"
                      className="btn btn-outline-light"
                      onClick={handleSubmitDischargeForm}
                    >
                      Proceed
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default DischargePatient;
