import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";

toast.configure();

export const addInventoryService = (data, callback) => {
  return (dispatch, getState) => {

    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
   
    ApiClient
      .postFormData(`${apiUrl}${PORT}/service/add`, data, token, dispatch, getState)
      .then((res) => {
        const responseData = res.data;
        toast.dismiss();
        toast.success(responseData.message, toasterConfig);
        callback(responseData); // Invoke the callback function
      })
      .catch((err) => {
        toast.error(err.message, toasterConfig);
      });
  };
};

export const getServicesByClinicId = (clinic_id, params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      ApiClient.get(
        `${apiUrl}${PORT}/service/get-all/${clinic_id}`,
        params,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response) {
          // dispatch({
          //   type: "GET_INVENTORIES",
          //   payload: response?.response_data,
          // });
          dispatch({
            type: "GET_SERVICES",
            payload: response,
          });
          return callback(response);
          // return callback(true);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(false);
        }
      });
    }
    // else {
    //   return callback(null);
    // }
  };
};



export const getServiceListByClinicId = (clinic_id, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      ApiClient.get(
        `${apiUrl}${PORT}/service/get-list/${clinic_id}`,
        {},
        token, dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response) {

          return callback(response);
        } else {
          return callback(false);
        }
      });
    }
  };
};


export const getAllActiveServicesByClinicId = (clinic_id, params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      ApiClient.get(
        `${apiUrl}${PORT}/service/get-all-active-services/${clinic_id}`,
        params,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response) {
          return callback(response);
          // return callback(true);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(false);
        }
      });
    }
    // else {
    //   return callback(null);
    // }
  };
};
export const getAllActiveServicesByPetId = (petId, params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      ApiClient.get(
        `${apiUrl}${PORT}/service/get-all-active-services-for-patient/${petId}`,
        params,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response) {
          return callback(response);
          // return callback(true);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(false);
        }
      });
    }
    // else {
    //   return callback(null);
    // }
  };
};
