import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../../../api-client";
import { apiUrl, PORT } from "../../../../environment";
import { toasterConfig } from "../../../../utils/constants";

toast.configure();
/**
 * All Api endpoints to be call
 */

/**
 * get all vaccination dues details
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */
export const getTestResultsByPetId = (payload, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    if (payload?.petId) {
      dispatch({ type: "DIAGNOSTIC_RESULTS_LOADING", payload: true });
      ApiClient.get(
        `${apiUrl}${PORT}/diagnostic-result/get-all/${payload?.petId}`,
        {},
        token, dispatch, getState
      ).then((response) => {
        dispatch({ type: "DIAGNOSTIC_RESULTS_LOADING", payload: false });
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({
            type: "DIAGNOSTIC_RESULTS",
            payload: response?.response_data[0] || { dates: [], data: [] },
          });
          return callback(response);
        } else {
          return callback(response);
        }
      });
    } else {
      return callback({ status: false });
    }
  };
};



/**
 * get idexx req form url
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */
export const getReqForm = (id, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: {clinic_details}
    } = getState();
    if (id && clinic_details?._id) {
      ApiClient.get(
        `${apiUrl}${PORT}/diagnostic-test/get-req-form/${id}`,
        {clinic_id: clinic_details?._id},
        token, dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          return callback(response);
        } else {
          return callback(response);
        }
      });
    } else {
      return callback({ status: false });
    }
  };
};




/**
 * get all vaccination dues details
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */
export const getIVLSMachines = (callback) => {
  return (dispatch, getState) => {
    const {
      clinicReducer: { clinic_details },
      user: { token },
    } = getState();
    if (token && clinic_details?._id) {
      ApiClient.get(
        `${apiUrl}${PORT}/diagnostic-test/get-ivls-machines/${clinic_details?._id}`,
        {},
        token, dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          return callback(response);
        } else {
          return callback(response);
        }
      });
    } else {
      return callback({ status: false });
    }
  };
};


export const getTestResultsByPetIdAndDate = (payload, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    if (payload?.pet_id) {
      dispatch({ type: "DIAGNOSTIC_RESULTS_LOADING", payload: true });
      ApiClient.post(
        `${apiUrl}${PORT}/diagnostic-result/patient-detail-report`,
        payload,
        token, dispatch, getState
      ).then((response) => {
        dispatch({ type: "DIAGNOSTIC_RESULTS_LOADING", payload: false });
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({
            type: "DIAGNOSTIC_TEST_RESULTS",
            payload: response?.response_data || { data: [] },
          });
          return callback(response);
        } else {
          return callback(response);
        }
      });
    } else {
      return callback({ status: false });
    }
  };
};

export const getAllTests = (callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    dispatch({ type: "DIAGNOSTIC_TESTS_LOADING", payload: true });
    ApiClient.get(`${apiUrl}${PORT}/diagnostic-test/get-all`, {}, token, dispatch, getState).then(
      (response) => {
        dispatch({ type: "DIAGNOSTIC_TESTS_LOADING", payload: false });
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({
            type: "DIAGNOSTIC_TESTS",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          return callback(response);
        }
      }
    );
  };
};



/**
 * @description function use to add idexx order to vetrecor db
 * @param {*} data accepting object
 */
export const addAntechOrder = (accession, callback) => {
  return (dispatch, getState) => {
    let data = {}
    const {
      user: { token },
      clinicReducer: {clinic_details}
    } = getState();
    if(clinic_details?._id) data.clinic_id = clinic_details?._id
    if(accession) data.accession = accession
    ApiClient.post(
      `${apiUrl}${PORT}/diagnostic-test/add-antech-order`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback(); 
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response?.status) {
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};


/**
 * @description function use to add idexx order to vetrecor db
 * @param {*} data accepting object
 */
export const addOrder = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: {clinic_details}
    } = getState();
    ApiClient.get(
      `${apiUrl}${PORT}/diagnostic-test/add-order/${data}`,
      {clinic_id: clinic_details?._id},
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response?.status) {
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * @description function use to add idexx order for tests
 * @param {*} data accepting object
 */
export const placeDiagnosticOrder = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: {clinic_details}
    } = getState();
    if(clinic_details?._id) data.clinic_id = clinic_details?._id
    ApiClient.post(
      `${apiUrl}${PORT}/diagnostic-test/order`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response?.status) {
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};



/**
 * @description function use to add idexx order for tests
 * @param {*} data accepting object
 */
export const updateDiagnosticOrder = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: {clinic_details}
    } = getState();
    if(clinic_details?._id) data.clinic_id = clinic_details?._id
    ApiClient.put(
      `${apiUrl}${PORT}/diagnostic-test/update-order`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response?.status) {
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};


/**
 * @description function use to cancel idexx order for tests
 * @param {*} data accepting object
 */
export const cancelDiagnosticOrder = (data, callback) => {
  return (dispatch, getState) => {
  
    const {
      user: { token },
      clinicReducer: {clinic_details}
    } = getState();
    if(clinic_details?._id) data.clinic_id = clinic_details?._id
    ApiClient.put(
      `${apiUrl}${PORT}/diagnostic-test/cancel-order`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response?.status) {
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};


/**
 * @description function use to add new diagnostic result
 * @param {*} data accepting object
 */
export const addNewResult = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/diagnostic-result/add`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response?.status) {
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * @description function use to add new diagnostic result
 * @param {*} data accepting object
 */
export const getAndFilterDiagnosticResults = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    dispatch({ type: "DIAGNOSTIC_RESULTS_LOADING", payload: true });

    ApiClient.post(
      `${apiUrl}${PORT}/diagnostic-result/get-all-with-filter`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      dispatch({ type: "DIAGNOSTIC_RESULTS_LOADING", payload: false });
      if (!response) {
        return callback();
      } else if (response?.status) {
        dispatch({
          type: "DIAGNOSTIC_RESULTS",
          payload: response?.response_data[0],
        });
        return callback(response);
      } else {
        dispatch({
          type: "DIAGNOSTIC_RESTULS",
          payload: { dates: [], data: [] },
        });
        return callback(response);
      }
    });
  };
};

/**
 * @description function use to add new diagnostic result
 * @param {*} data accepting object
 */
export const getMostRecentDiagnosticResultsByPetId = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    dispatch({ type: "DIAGNOSTIC_RESULTS_LOADING", payload: true });

    ApiClient.post(
      `${apiUrl}${PORT}/diagnostic-result/get-most-recent`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      dispatch({ type: "DIAGNOSTIC_RESULTS_LOADING", payload: false });
      if (!response) {
        return callback();
      } else if (response?.status) {
        dispatch({
          type: "DIAGNOSTIC_RESULTS",
          payload: response?.response_data[0],
        });
        return callback(response);
      } else {
        dispatch({ type: "DIAGNOSTIC_RESTULS", payload: [] });
        return callback(response);
      }
    });
  };
};

/**
 * @description function use to add new diagnostic result
 * @param {*} data accepting object
 */
export const getAntechResult = (result_id, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: {clinic_details}
    } = getState();
    var data = {}
    if(clinic_details?._id) data.clinic_id = clinic_details?._id
    ApiClient.get(
      `${apiUrl}${PORT}/diagnostic-test/get-antech-result/${result_id}`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (response?.status) {
      
        return callback(response);
      } else {
       
        return callback(response);
      }
    });
  };
};




/**
 * @description function use to add new diagnostic result
 * @param {*} data accepting object
 */
export const getAntechPatientResult = (patient_id, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: {clinic_details}
    } = getState();
    var data = {}
    if(clinic_details?._id) data.clinic_id = clinic_details?._id
    ApiClient.get(
      `${apiUrl}${PORT}/diagnostic-test/get-antech-patient-result/${patient_id}`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (response?.status) {
      
        return callback(response);
      } else {
       
        return callback(response);
      }
    });
  };
};



/**
 * @description function use to add new diagnostic result
 * @param {*} data accepting object
 */
export const getAntechClinicResult = ( callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: {clinic_details}
    } = getState();
    var data = {}
    if(clinic_details?._id) data.clinic_id = clinic_details?._id
    ApiClient.get(
      `${apiUrl}${PORT}/diagnostic-test/get-antech-clinic-result/${clinic_details?._id}`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (response?.status) {
      
        return callback(response);
      } else {
       
        return callback(response);
      }
    });
  };
};




/**
 * @description function use to add new diagnostic result
 * @param {*} data accepting object
 */
export const getIdexxResult = (result_id, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: {clinic_details}
    } = getState();
    var data = {}
    if(clinic_details?._id) data.clinic_id = clinic_details?._id
    ApiClient.get(
      `${apiUrl}${PORT}/diagnostic-test/get-idexx-result/${result_id}`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (response?.status) {
      
        return callback(response);
      } else {
       
        return callback(response);
      }
    });
  };
};
