import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../../../api-client";
import { apiUrl, PORT } from "../../../../environment";
import { toasterConfig } from "../../../../utils/constants";

toast.configure();
/**
 * All Api endpoints to be call
 */

/**
 * get all mars by pet id
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */
export const getMARSByPetId = (petId, payload, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    if (petId) {
      dispatch({ type: "MARS_LOADING", payload: true });
      ApiClient.post(
        `${apiUrl}${PORT}/mar/get-all/${petId}`,
        payload,
        token,
        dispatch, getState
      ).then((response) => {
        dispatch({ type: "MARS_LOADING", payload: false });
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({ type: "MARS", payload: response?.response_data });
          return callback(response);
        } else {
          dispatch({ type: "MARS", payload: response?.response_data });
          return callback(response);
        }
      });
    } else {
      return callback({ status: false });
    }
  };
};

/**
 * get all mars by pet id
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */
export const getMARSDates = (petId, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    if (petId) {
      dispatch({ type: "MARS_LOADING", payload: true });
      ApiClient.get(
        `${apiUrl}${PORT}/mar/get-dates/${petId}`,
        {},
        token,
        dispatch, getState
      ).then((response) => {
        dispatch({ type: "MARS_DATES_LOADING", payload: false });
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({ type: "MARS_DATES", payload: response?.response_data });
          return callback(response);
        } else {
          return callback(response);
        }
      });
    } else {
      return callback({ status: false });
    }
  };
};

/**
 *
 * @param {Object} body
 * @param {Function} callback
 * @returns
 */
export const updateMAR = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.put(`${apiUrl}${PORT}/mar/update`, body, token, dispatch, getState).then(
      (response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      }
    );
  };
};

/**
 * @description function use to add new appointment
 * @param {*} params accepting object
 */
export const addMAR = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.post(`${apiUrl}${PORT}/mar/add`, data, token, dispatch, getState).then(
      (response) => {
        if (!response) {
          return callback();
        } else if (!response && response.errors) {
          toast.error(response.errors.msg, toasterConfig);
          return callback(response);
        } else if (response?.status) {
          toast.dismiss();
          toast.success(response.message, toasterConfig);
          return callback(response);
        } else {
          toast.error(response.message, toasterConfig);
          return callback();
        }
      }
    );
  };
};

/**
 * get all mars   Orders By Pet
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */

export const getMarsOrdersByPet = (petId, params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    dispatch({ type: "MARS_ORDERS_LOADING", payload: true });
    ApiClient.post(
      `${apiUrl}${PORT}/mar/get-all-mars/${petId}`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      dispatch({ type: "MARS_ORDERS_LOADING", payload: false });

      if (!response) {
        return callback();
      } else if (response?.status) {
        dispatch({ type: "MARS_ORDERS", payload: response?.response_data });

        return callback(response);
      } else {
        dispatch({ type: "MARS_ORDERS", payload: [] });

        //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};

export const getMarsOrdersByPetWithDateFilter = (params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();
    dispatch({ type: "MARS_ORDERS_LOADING", payload: true });
    ApiClient.post(
      `${apiUrl}${PORT}/mar/get-all-mars-with-date-filter`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      dispatch({ type: "MARS_ORDERS_LOADING", payload: false });

      if (!response) {
        return callback();
      } else if (response?.status) {
        dispatch({ type: "MARS_ORDERS", payload: response?.response_data });

        return callback(response);
      } else {
        dispatch({ type: "MARS_ORDERS", payload: [] });

        //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};

export const getMostRecentMarsOrderByPet = (params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();
    dispatch({ type: "MARS_ORDERS_LOADING", payload: true });
    ApiClient.post(
      `${apiUrl}${PORT}/mar/get-most-recent`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      dispatch({ type: "MARS_ORDERS_LOADING", payload: false });

      if (!response) {
        return callback();
      } else if (response?.status) {
        dispatch({ type: "MARS_ORDERS", payload: response?.response_data });

        return callback(response);
      } else {
        dispatch({ type: "MARS_ORDERS", payload: [] });

        //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};
