import React from "react";
import UserPlaceholder from "../../../UserPlaceholder";
import "./index.scss";
import { useSelector } from "react-redux";

const CalendarWeekDay = ({ doctor }) => {
	const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);
	return (
		<>
			{
				doctor?.user?
				<div className="user-pic-cell d-inline-flex align-items-center">
				<div className="pic rounded-circle overflow-hidden">
					{
						doctor?.user?.user_image ? <img className="img-fluid w-100 h-100" src={doctor?.user?.user_image} alt="" /> : <UserPlaceholder />
					}

				</div>
				<div className="txt">
					{/* <h4>{doctor?.user?.title+" "+doctor?.user?.firstname+" "+doctor?.user?.lastname}</h4> */}

					<h4>
                                {doctor?.user?.title}  <> {clinic_settings?.doctor_name_format === 0  ? <> <span className="mr-1">{doctor?.user?.lastname} ,</span>
                                <span className="">  {doctor?.user?.firstname} </span> </> :   <> <span className="mr-1"> {doctor?.user?.firstname}</span>
                               <span>  {doctor?.user?.lastname}</span> </> }    </> 
                              </h4>

				</div>
			</div>:
				<div className="user-pic-cell d-inline-flex align-items-center">
				<div className="pic rounded-circle overflow-hidden">
					{
						doctor?.pet_image ? <img className="img-fluid w-100 h-100" src={doctor?.pet_image} alt="" /> : <UserPlaceholder />
					}

				</div>
				<div className="txt">
					<h4>{doctor?.kennel_name||doctor?.name}</h4>
				</div>
			</div>
			}
			{/* <div className="user-pic-cell d-inline-flex align-items-center">
				<div className="pic rounded-circle overflow-hidden">
					{
						doctor?.user?.user_image ? <img className="img-fluid w-100 h-100" src={doctor?.user?.user_image} alt="" /> : <UserPlaceholder />
					}

				</div>
				<div className="txt">
					<h4>{doctor?.user?doctor?.user?.title+" "+doctor?.user?.firstname+" "+doctor?.user?.lastname:doctor.kennel_name}</h4>
				</div>
			</div> */}
		</>
	);
};

export default CalendarWeekDay;
