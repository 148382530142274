import React, { useEffect, useRef, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Routes from "./routers";
import withTracker from "./withTracker";
import { useDispatch, useSelector } from "react-redux";
import {
  getClinicPermission,
  getClinicSettingsByClinicId,
} from "context/actions/clinicSettings";
import {
  getAllUserClinic,
  getClinicDetailsByUserId,
} from "context/actions/clinic";
import LoaderUI from "shared/loader";
import { getPetOwnerPets } from "context/actions/pet-owner-dashboard";
import FullScreenFileViewer from "shared/FullScreenFileViewer";
import LabOrderViewer from "shared/LabOrderViewer";
import LabResultViewer from "shared/LabResultViewer";
import { getAllSpecies } from "context/actions/app-content";
import { TokenProvider } from "context/TokenContext";
import { getAllClinicSpeciesByClinicId } from "context/actions/clinic-species";
import { getAllClinicBreedsByClinicId } from "context/actions/clinic-breed";
import NewPetModal from "shared/NewPetModal";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import { useHistory } from "react-router-dom";

const Root = () => {
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.user.user_details);
  const [user, loading, error] = useAuthState(auth);

  const { primaryClinic, clinic_details } = useSelector(
    (state) => state.clinicReducer
  );
  const userRole = userDetail?.role;

  const history = useHistory()




  // useEffect(() => { added to comment
  //   if (loading) return;
  //   else if (!user) {
  //     dispatch({ type: "LOGOUT", data: {} });
  //     history.push('/login')

  //   };}, [user, loading])
  const globalInitAPI = async () => {
    if (userDetail?.role === "pet-owner") {
      const response = await dispatch(getPetOwnerPets(userDetail?._id));
      // await dispatch(
      //   getClinicDetailsByUserId({ added_by: userDetail?.id }, () => {})
      // );
     

      // console.log(response)
      if(response?.response_data?.[0] && response?.response_data?.[0]?.primary_clinic){
        dispatch(
          getClinicDetailsByUserId({user_id: response?.response_data?.[0]?.primary_clinic }, () => {
        console.log('getting clinic setting')
        dispatch(getClinicSettingsByClinicId(
          {id: response?.response_data?.[0]?.primary_clinic}, 
          () => {}))
        }));
      }
     


      dispatch(getAllSpecies({}, (res) => { }))

    } else if (userDetail) {
      if (userDetail?._id) setLoading(true);
      getClinicDetailsByUserId({}, async (cData) => {
        dispatch({
          type: "SET_PRIMARY_CLINIC",
          payload: cData?.response_data?._id || "",
        });

        dispatch(getClinicSettingsByClinicId({}, () => {  
          console.log(userDetail?.role, 'user detail role')

          dispatch(
          getClinicPermission(userDetail?.role, (res) => {


            if(res?.status && res?.response_data && !res?.response_data.active){
              
            }
          }))}))
      })
     
      setLoading(false);
    }
  };


  
  useEffect(() => {
    if (userDetail?._id && user && !loading) {
      globalInitAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetail, user, loading]);
  const fetchClinicList = async () => {
    const res = await dispatch(
      getAllUserClinic(userDetail?.primary_clinic, "")
    );
    dispatch({
      type: "SET_CLINIC_LIST",
      payload: res?.response_data || [],
    });
  };
  useEffect(() => {


    if(user && !loading){
  
      if (clinic_details?._id && userDetail?.role === "admin") {
        fetchClinicList();
      }
      dispatch(getClinicSettingsByClinicId({ id: clinic_details?._id }, () => {}))
      dispatch(getClinicSettingsByClinicId({}, () => {  
        console.log(userDetail?.role, 'user detail role')
        
        dispatch(getClinicPermission(userDetail?.role === "super-admin" ? 'admin' : userDetail?.role, (res) => {}))}))


        dispatch(getAllClinicSpeciesByClinicId({}, () => {
          dispatch(getAllClinicBreedsByClinicId({}, () => {}))
        }))

    }

      


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic_details, user, loading]);

  useEffect(() => {
    if (primaryClinic && user && !loading) {
      dispatch(getClinicSettingsByClinicId({ id: primaryClinic }, () => {}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryClinic, user, loading]);

  const [clinicLoading, setLoading] = useState(false);
 
  const routesList = Routes();
  return (
    <TokenProvider>
    <>
      <FullScreenFileViewer />
      <LabOrderViewer />
      <NewPetModal />
      <LabResultViewer />
      { loading || clinicLoading ? (
      
        <LoaderUI />
      ) : (
        <>
          {/* <SocketContext.Provider value={socket}> */}
          <Switch>
            {routesList.map((route, index) => {
              const allowedRoles = route.allowedRoles;
              // if (
              //   (!userDetail) || (allowedRoles === undefined) ||
              //   (allowedRoles !== undefined &&
              //     Array.isArray(allowedRoles) &&
              //     allowedRoles.includes(userRole))
              // ) {
              if (!userDetail || allowedRoles === undefined || allowedRoles) {
                return (
                  <Route
                    // key={index}
                    path={route.path}
                    exact={route.exact}
                    component={withTracker((props) => {
                      return (
                        <route.layout
                          {...props}
                          allowedRoles={route.allowedRoles}
                        >
                          <route.component {...props} />
                        </route.layout>
                      );
                    })}
                  />
                );
              }
              //   else {
              //     console.log(route.path, "WHY HERE");
              //     return (
              //       <Route
              //         //   key={index}
              //         //   path={route.path}
              //         //   exact={route.exact}
              //         component={withTracker((props) => {
              //           return (
              //             <DefaultLayout>
              //               <NoRecord2 />
              //             </DefaultLayout>
              //           );
              //         })}
              //       />
              //     );
              //   }
            })}
          </Switch>
          {/* </SocketContext.Provider> */}
        </>
      )}
      </>
     </TokenProvider>

  );
};

export default Root;
