import React, { useEffect } from "react";
import { Button, Input } from "reactstrap";
import SearchBlock from "./SearchBlock";
import "./index.scss";
import {
  getCurrentWeekSpan,
  getWeekSpanValue,
} from "../../../utils/calendar/constant";
import { useState } from "react";

import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const CalendarFilterWeek = ({ calendarViewValue, isSearch = true }) => {
  const [monthdata, setMonthData] = useState();
  const [monthVal, setMonthVal] = useState("Jan 1971");


  useEffect(() => {
    handleWeekData();

  }, []);


  const handleMonthChange = (changeType) => {

    const weekData = getWeekSpanValue(
      monthdata.date_value,
      monthdata.month_value,
      monthdata.year_value,
      changeType
    );
    setMonthData(weekData);
    setMonthVal(weekData.data);
    calendarViewValue({
      view_type: "week",
      date_value: weekData.date_value,
      month_value: weekData.month_value,
      year_value: weekData.year_value,
      span_date: weekData.span_date,
      span_month: weekData.span_month,
      last_week_span: weekData.last_week_span,
    });

  };

  const handleWeekData = () => {
    const weekData = getCurrentWeekSpan();
    setMonthData(weekData);
    setMonthVal(weekData.data);
    calendarViewValue({
      view_type: "week",
      date_value: weekData.date_value,
      month_value: weekData.month_value,
      year_value: weekData.year_value,
      span_date: weekData.span_date,
      span_month: weekData.span_month,
      last_week_span: weekData.last_week_span,
    });
  };

  return (
    <>
      <div className="calendar-filter-blc justify-between flex flex-col md:flex-row overflow-auto flex-shrink-0">
        <div className="left-col inline-flex items-center">
          {isSearch &&
          <SearchBlock />
          }
   
        </div>
        <div className="right-col flex items-center flex-row gap-2">
          <div
            className={`date-blc input-cell d-inline-flex week-view`}
          >
            <Button
              color="link"
              className="arw-btn lft d-inline-flex align-items-center justify-content-center p-0"
              onClick={() => handleMonthChange("previous")}
            >
              <BsChevronLeft className="h-4 w-4" />
            </Button>
            <span className="txt d-inline-flex align-items-center justify-content-center">
              {monthVal}
            </span>
            <Button
              color="link"
              className="arw-btn rt d-inline-flex align-items-center justify-content-center p-0"
              onClick={() => handleMonthChange("next")}
            >
              <BsChevronRight className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CalendarFilterWeek;

