import { FormikProvider, useFormik } from "formik";
import React, { useState, useEffect } from "react";
import Select from "react-select"; // Import react-select
import { Input, InputGroup } from "reactstrap";
import imgPlaceholder from "../../../assets/img/imgPlaceholder.png";
import { MdCancel } from "react-icons/md";
import { object, string, boolean, number } from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getAllInventoryCategories } from "../../../context/actions/app-content";

import { getSuppliersByClinicId } from "../../../context/actions/inventory";
import { getClinicDetailsByUserId } from "context/actions/clinic";
import { getClinicSettings } from "context/actions/clinicSettings";
import "./index.scss";
import { lazy } from "yup";

let productInfoSchema = object({
  product_name: string().required(),
  manufacture: string().required(),
  sku: string().required(),
  category_id: string().required(),
  inventory_image: string().nullable().optional(),
  unit_of_measure: string().required(),
  //upc: '',
  sub_package: object({
    unit_of_measure: lazy((i, o) => {
      const { unit_of_measure } = o?.context;
      if (
        (unit_of_measure === "tablet" || unit_of_measure === "patch",
        unit_of_measure === "capsule",
        unit_of_measure === "vial")
      ) {
        return string().required(
          "Sub package is required when unit is tablet, vial, patch, or capsule"
        );
      } else {
        return string().optional();
      }
    }),
    amount: lazy((i, o) => {
      const { unit_of_measure } = o?.context;
      if (
        (unit_of_measure === "tablet" || unit_of_measure === "patch",
        unit_of_measure === "capsule",
        unit_of_measure === "vial")
      ) {
        return number().required(
          "Sub package is required when unit is tablet, vial, patch, or capsule"
        );
      } else {
        return number().optional();
      }
    }),
  }),
  description: string().optional(),
  vettech_scanout: boolean().required(),
  doctor_scanout: boolean().required(),
  // createdOn: date().default(() => new Date()),
});

export default function ProductInfo({
  setActiveForm,
  updateFormData,
  formData,
}) {
  const [isImgSrc, setisImgSrc] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.user_details);
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );
  const { clinic_details } = useSelector((state) => state.clinicReducer);

  const formik = useFormik({
    initialValues: {
      // clinic_id: userDetails?._id,
      clinic_id: clinic_settings.clinic_id,
      supplier_id: "",
      product_name: "",
      manufacture: "",
      sku: "",
      category_id: "",
      unit_of_measure: undefined,
      //upc: '',
      sub_package: {
        unit_of_measure: undefined,
        amount: undefined,
      },
      description: "",
      inventory_image: "",
      vettech_scanout: false,
      doctor_scanout: false,
      image: isImgSrc ? isImgSrc : "",
    },
    validationSchema: productInfoSchema,
    onSubmit: (values) => {
      updateFormData((prevFormData) => ({
        ...prevFormData,
        values,
      }));
      setActiveForm("costAndTaxes");
    },
  });

  useEffect(() => {
    if (formData && Object.keys(formData).length !== 0) {
      formik.setValues(formData.values);
    }
  }, [formData]);

  // useEffect(() => {
  //   // console.log("clinic_details",clinic_settings)
  //   if (!clinic_settings?._id || !clinic_details?._id) {
  //     dispatch(
  //       getClinicDetailsByUserId({}, (response) => {
  //         if (response?.status) {
  //           dispatch(getClinicSettings({}, () => { }));
  //         }
  //       })
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleGetSuppliers = () => {
    dispatch(
      getSuppliersByClinicId(clinic_settings?.clinic_id, {}, (res) => {
        setSuppliers(res?.response_data?.docs);
      })
    );
  };
  useEffect(() => {
    handleGetSuppliers();
    dispatch(
      getAllInventoryCategories((res) => {
        if (res?.status) {
        }
      })
    );
  }, []);
  const { inventoryCategories } = useSelector(
    (state) => state.appContentReducer
  );
  console.log("formik, values", formik.values);
  console.log("isImgSrc", isImgSrc);
  return (
    <div className="ml-3  bg-[#F8F8F8E5] rounded-lg ">
      {/* ProductInfo */}
      <div className=" p-3">
        <p className="font-['Inter'] text-[#282E65] font-[700] mb-2">
          Product Information
        </p>
        <FormikProvider value={formik}>
          <div className="flex  justify-between items-baseline my-2">
            <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
              Supplier{" "}
              <span className="text-[#282E65] font-['Inter'] text-[18px]">
                *
              </span>
            </p>

            <Input
              defaultValue=""
              style={{
                textTransform: "capitalize",
                fontSize: "0.875rem",
                lineHeight: "1.25rem",
              }}
              type="select"
              className="w-[81%]"
              value={formik?.values?.supplier_id}
              placeholder="Please Select A Supplier"
              onChange={(e) => {
                formik.setFieldValue("supplier_id", e.target.value);
              }}
            >
              <option value="" hidden>
                Select a Supplier
              </option>
              {suppliers.map((data) => {
                return <option value={data._id}>{data.supplier_name}</option>;
              })}
            </Input>

            {/*
            <Select
              id="supplier_id"
              className="w-[81%]"
              name="supplier_id"
              options={suppliers.map((data) => ({
                value: data._id,
                label: data.supplier_name,
              }))}
              value={
                formik?.values.supplier_id
                  ? {
                    value: formik.values.supplier_id,
                    label: suppliers.find(
                      (data) => data._id === formik.values.supplier_id
                    )?.supplier_name,
                  }
                  : null
              }
              onChange={(selectedOption) => {
                formik.setFieldValue("supplier_id", selectedOption.value);
              }}
            />*/}
          </div>
          <div className="flex justify-between  items-center my-2">
            <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
              Product Name{" "}
              <span className="text-[#282E65] font-['Inter'] text-[18px]">
                *
              </span>
            </p>
            <input
              type="text"
              id="first_name"
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5 "
              placeholder="Enter Product Name"
              required
              {...formik.getFieldProps("product_name")}
            />
          </div>

          <div className="flex justify-end">
            {formik.touched.product_name && formik.errors.product_name ? (
              <p className="text-[#dc3545] font-['Inter'] text-[12px]">
                Please Enter a Product Name
              </p>
            ) : null}
          </div>
          <div className="flex justify-between  items-center my-2">
            <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
              Manufacturer Info{" "}
              <span className="text-[#282E65] font-['Inter'] text-[18px]">
                *
              </span>
            </p>
            <input
              type="text"
              id="first_name"
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5 "
              placeholder="Enter Manufacturer Info."
              required
              {...formik.getFieldProps("manufacture")}
            />
          </div>
          <div className="flex justify-end">
            {formik.touched.manufacture && formik.errors.manufacture ? (
              <p className="text-[#dc3545] font-['Inter'] text-[12px]">
                Please Enter Manufacture Information
              </p>
            ) : null}
          </div>
          <div className="flex justify-between  items-center my-2">
            <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
              SKU{" "}
              <span className="text-[#282E65] font-['Inter'] text-[18px]">
                *
              </span>
            </p>
            <input
              type="text"
              id="first_name"
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5 "
              placeholder="Enter SKU"
              required
              {...formik.getFieldProps("sku")}
            />
          </div>
          <div className="flex justify-end">
            {formik.touched.sku && formik.errors.sku ? (
              <p className="text-[#dc3545] font-['Inter'] text-[12px]">
                Please Enter a SKU
              </p>
            ) : null}
          </div>

          {/*
          <div className="flex justify-between  items-center my-2">
            <label
              htmlFor="first_name"
              className="block mb-2 text-sm font-medium text-[#282E65] font-['Inter'] "
            >
              Quantity{" "}
              <span className="text-[#282E65] font-['Inter'] text-[18px]">
                *
              </span>
            </label>
            <input
              type="number"
              id="first_name"
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5 "
              placeholder="Enter quantity"
              required
              {...formik.getFieldProps("quantity")}
            />
            </div>
          <div className="flex justify-end w-[33%]">
            {formik.touched.quantity && formik.errors.quantity ? (
              <p className="text-[#dc3545] font-['Inter'] text-[12px]">
                Please fill this field
              </p>
            ) : null}
            </div>*/}
          <div className="flex  justify-between items-baseline my-2">
            <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
              Category{" "}
              <span className="text-[#282E65] font-['Inter'] text-[18px]">
                *
              </span>
            </p>
            <Input
              type={"select"}
              id="countries"
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5 "
              name="category_id"
              style={{
                textTransform: "capitalize",
                fontSize: "0.875rem",
                lineHeight: "1.25rem",
              }}
              {...formik.getFieldProps("category_id")}
            >
              <option selected className="font-['Inter']">
                Select a category
              </option>
              {(inventoryCategories || []).map((item, i) => (
                <option key={i} value={item._id}>
                  {item?.category}
                </option>
              ))}
            </Input>
          </div>
          <div className="flex justify-end">
            {formik.touched.category_id && formik.errors.category_id ? (
              <p className="text-[#dc3545] font-['Inter'] text-[12px]">
                Please Select a Catgory
              </p>
            ) : null}
          </div>
          {/* for uint of measure */}
          <div className="flex  justify-between items-baseline my-2">
            <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
              Unit of measure
              <span className="text-[#282E65] font-['Inter'] text-[18px]">
                *
              </span>
            </p>
            <Input
              type="select"
              id="countries"
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[81%] p-2.5 "
              name="unit_of_measure"
              style={{
                fontSize: "0.875rem",
                lineHeight: "1.25rem",
              }}
              {...formik.getFieldProps("unit_of_measure")}
            >
              <option
                selected
                className="font-['Inter']"
                value="undefined"
                disabled
              >
                Select A Unit
              </option>
              <option value="mL">mL</option>
              <option value="Vial">Vial</option>
              <option value="Item">item</option>
              <option value="%">%</option>
              <option value="L">L</option>
              <option value="tablet">tablet</option>
              <option value="capsule">capsule</option>
              <option value="mg">mg</option>
              <option value="g">g</option>
              <option value="patch">patch</option>
              <option value="spray">spray</option>
              <option value="drop">drop</option>
            </Input>
          </div>
          <div className="flex justify-end w-[33%]">
            {formik.touched.unit_of_measure && formik.errors.unit_of_measure ? (
              <p className="text-[#dc3545] font-['Inter'] text-[12px]">
                Please select a unit
              </p>
            ) : null}
          </div>
          {/* for subunits */}
          {(formik?.values?.unit_of_measure === "vial" ||
            formik?.values?.unit_of_measure === "tablet" ||
            formik?.values?.unit_of_measure === "capsule" ||
            formik?.values?.unit_of_measure === "patch") && (
            <>
              <div className="flex  justify-between items-baseline my-2">
                <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
                  Sub Package
                  <span className="text-[#282E65] font-['Inter'] text-[18px]">
                    *
                  </span>
                </p>
                <InputGroup style={{ width: "81%" }}>
                  <Input
                    style={{
                      fontSize: "0.875rem",
                      lineHeight: "1.25rem",
                    }}
                    type="text"
                    id="sub_package.amount"
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[50%] p-2.5 "
                    placeholder="Enter Amount"
                    required
                    {...formik.getFieldProps("sub_package.amount")}
                  />
                  <Input
                    style={{
                      fontSize: "0.875rem",
                      lineHeight: "1.25rem",
                    }}
                    type="select"
                    id="sub_package.unit_of_measure"
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[50%] p-2.5 "
                    name="sub_package.unit_of_measure"
                    {...formik.getFieldProps("sub_package.unit_of_measure")}
                  >
                    <option
                      selected
                      className="font-['Inter']"
                      value="undefined"
                      disabled
                    >
                      Select a Unit
                    </option>
                    <option value="mL">mL</option>
                    <option value="L">L</option>
                    <option value="tablet">tablet</option>
                    <option value="capsule">capsule</option>
                    <option value="mg">mg</option>
                    <option value="g">g</option>
                    <option value="patch">patch</option>
                    <option value="spray">spray</option>
                    <option value="drop">drop</option>
                  </Input>
                </InputGroup>
              </div>
              <div className="flex justify-end w-[33%]">
                {(formik.touched.sub_package?.unit_of_measure &&
                  formik.errors.sub_package?.unit_of_measure) ||
                (formik.touched.sub_package?.amount &&
                  formik.errors.sub_package?.amount) ? (
                  <p className="text-[#dc3545] font-['Inter'] text-[12px]">
                    Please fill this field
                  </p>
                ) : null}
              </div>
            </>
          )}
          <div className="flex justify-between  my-2 ">
            <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
              Description
            </p>
            <textarea
              id="message"
              rows="4"
              className="block p-2.5 w-[81%] text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
              placeholder="Write Description Here..."
              {...formik.getFieldProps("description")}
            ></textarea>
          </div>
          <div className="flex justify-between my-2">
            <p className=" text-sm font-medium font-['Inter'] text-[#282E65] ">
              Product Image{" "}
            </p>

            <div className="d-flex flex-row p-2.5 w-[81%] ">
              <img
                className="w-[120px] h-[120px] rounded-[8px]"
                src={isImgSrc ? isImgSrc : imgPlaceholder}
                alt="product"
              />

              <input
                className="text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
                id="file_input"
                type="file"
                onChange={(e) => {
                  formik.setFieldValue("inventory_image", e.target.files[0]);
                  localStorage.setItem("inventory_image", e.target.files[0]);
                  let reader = new FileReader();

                  reader.onload = function () {
                    setisImgSrc(reader.result);
                    console.log("img src", reader.result);
                  };
                  reader.readAsDataURL(e.target.files[0]);
                }}
              />

              <div className="absolute pt-[5px] left-[125px]">
                {isImgSrc && (
                  <button
                    className=""
                    onClick={() => {
                      setisImgSrc(false);
                    }}
                  >
                    <MdCancel size={20} color="#11CA9D" />
                  </button>
                )}
              </div>
            </div>

            {/*
            <div className="w-[81%] relative  top-[-30px]" >


              <textarea
                id="message"
                rows="4"
                className="block p-2.5 w-full text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
                placeholder="Write Product Description here..."
                {...formik.getFieldProps("product_description")}
              ></textarea>
            </div>*/}
          </div>
          <div className="flex flkex-row w-[100%] justify-end">
            <div className="flex flex-col  border border-gray-200 rounded dark:border-gray-700 w-[81%]  bg-[#fff]">
              <div className="flex items-center pl-4">
                <input
                  id="bordered-checkbox-1"
                  type="checkbox"
                  value=""
                  name="bordered-checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  {...formik.getFieldProps("doctor_scanout")}
                />
                <label
                  htmlFor="bordered-checkbox-1"
                  className="w-full py-3 ml-2 text-[13px] font-['Inter'] text-[#282E65] font-[600] "
                >
                  Is Doctor scan out?{" "}
                  <span className="text-[#282E65] font-['Inter'] text-[18px]">
                    *
                  </span>
                </label>
              </div>

              <div className="flex items-center pl-4">
                <input
                  id="bordered-checkbox-1"
                  type="checkbox"
                  value=""
                  name="bordered-checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  {...formik.getFieldProps("vettech_scanout")}
                />
                <label
                  htmlFor="bordered-checkbox-1"
                  className="w-full py-2 ml-2 text-[13px] font-['Inter'] text-[#282E65] font-[600] "
                >
                  Is Vettech scan out?{" "}
                  <span className="text-[#282E65] font-['Inter'] text-[18px]">
                    *
                  </span>
                </label>
              </div>
              <div className="flex justify-end w-[33%]">
                {(formik.touched.vettech_scanout ||
                  formik.touched.doctor_scanout) &&
                (formik.errors.vettech_scanout ||
                  formik.errors.doctor_scanout) ? (
                  <p className="text-[#dc3545] font-['Inter'] text-[12px]">
                    Please check this field
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="d-flex flex-row justify-end my-2">
            <button
              type="button"
              className="px-4 py-2 position bottom-[39px] text-[#11CA9D] font-['Inter']"
            >
              Back
            </button>
            <button
              type="button"
              onClick={formik.handleSubmit}
              className="bg-[#11CA9D] px-3.5 py-2 position bottom-[39px] rounded-lg text-[#fff] font-['Inter']"
            >
              Save & Proceed
            </button>
          </div>
        </FormikProvider>
      </div>
    </div>
  );
}

//product info
