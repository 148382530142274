import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import BackButton from "../../../shared/BackButton";
import "./index.css";

const HeaderBlock = () => {
	const history = useHistory()
	return (
		<>
			<div className="header-block d-flex align-items-center w-[15%]">
				<div className="left-col">
					<BackButton onClick={() => history.goBack()} label="Discharge Summary" />
				</div>
				{/* <div className="right-col ms-auto">
					<Button color="link" className="add-btn btn-outline-light">Add in bulk</Button>
				</div> */}
			</div>
		</>
	);
};

export default HeaderBlock;
