import React, { useEffect, useState } from "react";
import { Button,  FormGroup, Input, FormFeedback} from "reactstrap";
import {Formik, Form} from "formik";
import { useHistory } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import UploadBlock from "shared/UploadBlock";
import './index.scss';

/// need to add formik to form here
import * as Yup from "yup"; 
const schema = Yup.object().shape({
  note: Yup.string().required('Note is required'), 
  date: Yup.date().required('Date is required')
});

const init = {
	note: '',
	date: '' 
}


//fix validation in morning

const NewNotePanel = ({ className = '', closeNewPointPanel, refresh, addPoint, record}) => {
	const [points, setPoints] = useState([]);

	const [images, setImages] = useState([]);
	const [imagesarr, setImagesarr] = useState([]);
	const [imageFiles, setImageFiles] = useState([]);



	const handlePointClick = (point) => {

			if(points?.includes(point)){

				let to_remove = points.findIndex(e => JSON.stringify(e) === JSON.stringify(point))
				setPoints((prev) => prev.filter((data, index) => index !== to_remove))

			} else {
				setPoints([...points, point])

			}



	}






	

	useEffect(() => {

		setPoints([])
		
	}, [className]);

	const handleAddPoint = (values) => {

		let formdata = new FormData();


		if (images && images.length > 0) {
			for (let image of images) {
			  formdata.append("image", image, image.name);
			}
		  }
		formdata.append("date",  new Date(values?.date));
		for(let point of points){
		  formdata.append("teeth[]", point.name)
		}
		  formdata.append("note", values.note);
	
	





		addPoint(formdata, response => { 
			if(response?.status) {
			closeNewPointPanel()
			setImageFiles([])
			setImagesarr([])
			setImages([])

			refresh();
			}

		})

	}





	return (
		<>

		<Formik initialValues={init}
			enableReinitialize
			validationSchema={schema}
			onSubmit={(values, {resetForm}) => {
					handleAddPoint(values);

					resetForm();}}> 

			{(formik) => (
			<div className={`dental-new-point-panel-wrapper position-fixed d-flex flex-column overflow-auto ${className}`}>
				<div className="head d-flex align-items-center">
					<h2>Add New Dental Note</h2>
					<Button color="link" className="ms-auto panel-close-btn" onClick={() => {
						closeNewPointPanel();
						formik.resetForm();
						setImageFiles([]);
						setImages([]);
						setImagesarr([])
						setPoints([]);
						}}>
						<span className="material-symbols-outlined">
							close
						</span>
					</Button>
				</div>
			 
				<div className="inner overflow-auto align-items-center">
					<div className='ms-auto mr-auto' style={{position: 'relative', width: 'fit-content'}}>
						
							<img className="dog-img" alt={'Dental Diagram'} src={require("../../../../../../../assets/img/dental-diagram.png")}></img>
							
							
								{record?.coordinates?.map((point, i) => 
									<div key={i} onClick={() => handlePointClick(point)}
										style={{
												position: 'absolute',
												left: `${point.x - 10}px`,
												top: `${point.y - 10}px`,
												backgroundColor: points?.includes(point) ? '#11ca9d': '#3D3EFD',
												width: 20,
												height: 20,
												borderRadius: 100,
												fontFamily: 'Inter',
												fontStyle: 'normal',
												fontWeight: 600,
												fontSize: '14px',
												lineHeight: '150%',
												textAlign:"center",
												color: '#FFFFFF'
											}}>
									</div>
								)}
					</div>
				

					<Form>

						<div className="form-block">
							<FormGroup className="input-wrapper">
								<div className="form-floating position-relative text-input">
									<Input disabled type="text" className="form-control" id={`Selected Teeth`} placeholder={`Selected Teeth`} value={(points.map((data, index) => data.name )).join(', ')} /> {/*+ ((index === (points.length - 1) ?  "" : ', ')))} />*/}
									<label for={`Selected Teeth`}>{`Selected Teeth`}</label>
								</div>
								
							</FormGroup>
						</div>

						<div>

							<FormGroup className="input-wrapper2 mb-2">
								{/* <div className="form-floating position-relative text-input"> */}
								<UploadBlock
									images={images}
									setImages={setImages}
									imagesarr={imagesarr}
									setImagesarr={setImagesarr}
									imageFiles={imageFiles}
									setImageFiles={setImageFiles}
									accept={
									"*/dicom,.dcm, image/dcm, */dcm, .dicom, image/png, image/jpg, image/jpeg, application/pdf "
									}
									multiple={true}
								/>
								{/* </div> */}
							</FormGroup>
						</div>

					
						<div className="form-block">
							<FormGroup className="input-wrapper">
								<div className="form-floating position-relative text-input">
									<Input invalid={formik?.errors?.note && formik?.touched?.note} type="text" className="form-control" id={`Note`} name={'note'} placeholder={`Note`} value={formik.values.note} onChange={formik.handleChange} /> {/*+ ((index === (points.length - 1) ?  "" : ', ')))} />*/}
									<label for={`Note`}>{`Note`}</label>
									{formik?.errors?.note && formik?.touched?.note ? (
															<FormFeedback invalid> {formik?.errors?.note}</FormFeedback>
									) : null}
								</div>
							</FormGroup>
						</div>
						<div className="form-block">
								<FormGroup className="input-wrapper">
										<div className="form-floating position-relative text-input">
											<Input invalid={formik?.errors?.date && formik?.touched?.date} type="datetime-local" className="form-control" id={`date`} name={'date'} placeholder={`Enter Date`} value={formik.values.date}  onChange ={formik.handleChange}/>
											<label for={`date`}>{`Date`}</label>
											{formik?.errors?.date && formik?.touched?.date  ? (
															<FormFeedback invalid>{formik?.errors?.date}</FormFeedback>
									) : null}
										</div>
										
								</FormGroup>
						</div>

						<div className="form-block d-flex justify-content-center btn-blc">
							<Button color="success" type={'submit'}className="add-btn">Add new</Button>
						</div>
						
					</Form>
				</div>
			</div>)}
			</Formik>
		</>
	);
};

export default NewNotePanel;
