const initState = {
    loading: true,
    clinictask_details: false,
    clinictasks: [],
    clinic_live_appointments: [],
    isAddTaskPanel: false,
    isUpdateTaskPanel: false,
}

export const clinicTaskReducer = (state = initState, action) => {
    switch (action.type) {
        case "CLINIC_TASK_LOADING":
            return { ...state, loading: action.payload }

        case "GET_CLINIC_TASK":
            return { ...state, clinictask_details: action.payload }

        case "GET_ALL_CLINIC_TASK":
            return { ...state, clinictasks: action.payload }

        case "GET_ALL_CLINIC_LIVE_APPOINTMENTS":
            return { ...state, clinic_live_appointments: action.payload }

        case "SET_ADD_TASK_PANEL": {
            return { ...state, isAddTaskPanel: action.data }
        }
        
        case "SET_UPDATE_TASK_PANEL": {
            return { ...state, isUpdateTaskPanel: action.data }
        }

        default:
            return state
    }
}