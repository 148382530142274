import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";
import { getFormAndTemplates } from "./formTemplates";

toast.configure();

export const createPatientIntakeForm = (formData, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      formData.append("clinic_id", clinic_details?._id);
      ApiClient.postFormData(
        `${apiUrl}${PORT}/patient-intake-form/create`,
        formData,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch(getFormAndTemplates({}, () => {}));
          toast.success(response.message, toasterConfig);
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    }
  };
};

/**
 *
 * @param {Object} body
 * @param {Function} clinic_id
 * @returns
 */
export const updatePatientIntakeForm = (formData, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      ApiClient.putFormData(
        `${apiUrl}${PORT}/patient-intake-form/update`,
        formData,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch(getFormAndTemplates({}, () => {}));
          toast.success(response.message, toasterConfig);
          return callback(response);
        } else {
          toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    }
  };
};

export const getClinicPatients = (params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    // console.log("getstate func",getState())
    ApiClient.get(
      `${apiUrl}${PORT}/patient-intake-form/get-clinic-patient-by-year`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (response?.status) {
        // console.log("patient intake form",response)
        // dispatch(getFormAndTemplates({}, () => { }))
        dispatch({
          type: "GET_CLINIC_PATIENT",
          payload: response?.response_data,
        });
        toast.success(response.message, toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};

export const deletePatientIntakeForm =
  (params) => async (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    let response = await ApiClient.put(
      `${apiUrl}${PORT}/patient-intake-form/remove`,
      params,
      token,
      dispatch, getState
    );
    return response;
  };
