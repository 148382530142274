import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import AvailabilityUnavailaibility from "./AvailabilityUnavailaibility";
import Patients from "./Patients";
import PatientStories2 from "./PatientStories2";
import ScheduleManagement from "./Schedule";
import StaffProfileOverview from "./StaffProfileOverview";
import CalenderAppointments from "./Schedule/CalenderAppointments";
import Preferences from "./Preferences";
import { useSelector } from "react-redux";

const StaffProfileTabBlock = ({ staffMemberDetails }) => {
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;  // console.log(staffMemberDetails);
  return (
    <>
      <div className="tab-wrapper tab-wrapper-staff-detail d-flex flex-column flex-fill overflow-auto">
        <Nav tabs className="flex-shrink-0">
          {permissions?.view_staff_management && (
            <NavItem>
              {permissions?.view_availability && (
                <NavLink
                  className={{ active: activeTab === "1" }}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Overview
                </NavLink>
              )}
              {permissions?.view_availability && (
                <NavLink
                  className={{ active: activeTab === "2" }}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  Schedule
                </NavLink>
              )}

              {permissions?.view_availability && (
                <NavLink
                  className={{ active: activeTab === "3" }}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  Availability
                </NavLink>
              )}
              {permissions?.view_commission && (
                <NavLink
                  className={{ active: activeTab === "5" }}
                  onClick={() => {
                    toggle("5");
                  }}
                >
                  Preferences
                </NavLink>
              )}
              {/*
						<NavLink
							className={({ active: activeTab === '5' })}
							onClick={() => { toggle('5'); }}
						>
							Patient Stories
				</NavLink>*/}
            </NavItem>
          )}
        </Nav>
        <TabContent activeTab={activeTab} className="overflow-auto">
          <TabPane tabId="1">
            <StaffProfileOverview staffMemberDetails={staffMemberDetails} />
          </TabPane>
          <TabPane tabId="2">
            <CalenderAppointments staffMemberDetails={staffMemberDetails} />
            {/* <ScheduleManagement /> */}
          </TabPane>
          {activeTab === "3" && (
            <AvailabilityUnavailaibility
              staffMemberDetails={staffMemberDetails}
            />
          )}
          <TabPane tabId="4">
            <PatientStories2
              className="responsive"
              userId={staffMemberDetails?.user?._id}
            />
          </TabPane>
          <TabPane tabId="5">
            <Preferences className="responsive" />
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default StaffProfileTabBlock;
