import React from "react";
import UserPlaceholder from "../../../../shared/UserPlaceholder";
import "./index.scss";
import { useSelector } from "react-redux";

const UserPicCell = ({ doctorInfo, doctorDetails }) => {
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );

  return (
    <>
      <div className="user-pic-cell2">
        <div className="row-block d-flex">
          <div className="pic d-inline-flex rounded-circle overflow-hidden flex-shrink-0">
            {doctorDetails?.user?.user_image ? (
              <img
                className="img-fluid w-100 h-100"
                src={doctorDetails?.user?.user_image}
                alt="User"
              />
            ) : (
              <UserPlaceholder />
            )}
          </div>
          <div className="txt">
            <h3>
              {doctorDetails?.user?.title}{" "}
              <>
                {clinic_settings.doctor_name_format === 0 ? (
                  <span>
                    {" "}
                    {doctorDetails?.user?.lastname}{" "}
                    {doctorDetails?.user?.firstname}{" "}
                  </span>
                ) : (
                  <span>
                    {" "}
                    {doctorDetails?.user?.firstname}{" "}
                    {doctorDetails?.user?.lastname}{" "}
                  </span>
                )}
              </>{" "}
            </h3>
            <h4>DVM</h4>
          </div>
          {!doctorInfo && (
            <div className="icon">
              <span className="material-symbols-outlined">auto_mode</span>
            </div>
          )}
        </div>
        {!doctorInfo && (
          <div className="location-cell d-inline-flex mw-100">
            <div className="icon">
              <span className="material-symbols-outlined">add_location</span>
            </div>
            <div className="txt">{doctorDetails?.user?.city}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default UserPicCell;
