import React, { useEffect } from "react";
import MedicationPlanBlock from "./MedicationPlanBlock";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentConditionByPetId } from "../../../../../context/actions/patient-module/overview/current-condition";
import { getMedicationPlanByPetId } from "../../../../../context/actions/patient-module/overview/medication-plan";
import { getAllReportsByPetId } from "../../../../../context/actions/patient-module/overview/report";
import { getHomeMedicationOrders } from "../../../../../context/actions/patient-module/orders";
import { reportReducer } from '../../../../../context/reducers/patient-module/overview/reportReducer';
import TreatmentPlanBlock from "./TreatmentPlanBlock/Index";
import "./index.scss";

const CarePlans = ({ petId }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.petReducer);
  const { reports } = useSelector((state) => state.reportReducer);


  useEffect(() => {
    if (petId) {
      dispatch(getCurrentConditionByPetId(petId, {}, () => { }));
      dispatch(getHomeMedicationOrders({ pet_id: petId }, () => { }));
      dispatch(getAllReportsByPetId(petId, {}, () => { }));
    }
  }, [petId]);

  const { permissionsData } = useSelector((state) => state.clinicPermissionReducer);
  const permissions = permissionsData;
  return (
    <>
      <div className="overview-block">
        {/* {permissions?.view_current_condition &&
          <div className="row-block">
            <ConditionBlock />
          </div>
        } */}

        {permissions?.view_medication_plans &&
          <div className="row-block">
            <MedicationPlanBlock />
          </div>
        }

        {/* <div className="row-block">
          <ReportsBlock petId={petId} />
        </div> */}
        {permissions?.view_patient_treatment_plans &&
          <div className="row-block">
            <TreatmentPlanBlock />
          </div>}

        {/* <div className="row-block">
          <SuggestionBlock />
        </div>
        <div className="row-block">
          <RecommendProductBlock />
        </div> */}

      </div>
    </>
  );
};

export default React.memo(CarePlans);
