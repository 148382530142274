import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../../../api-client";
import { apiUrl, PORT } from "../../../../environment";
import { toasterConfig } from "../../../../utils/constants";

toast.configure();
/**
 * All Api endpoints to be call
 */





/**
 * get all vaccination dues details
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */
export const getOrdersByPetId = (petId, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, } = getState();
        if (petId) {
            dispatch({ type: "ORDERS_LOADING", payload: true })
            ApiClient.get(`${apiUrl}${PORT}/order/get-all/${petId}`, {}, token, dispatch, getState).then((response) => {
                dispatch({ type: "ORDERS_LOADING", payload: false })
                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    dispatch({ type: "ORDERS", payload: response?.response_data })
                    return callback(response);
                } else {
                    return callback(response);
                }
            })
        } else {
            return callback({ status: false });
        }
    };
};

export const getOrdersWithCategoryByPetId = (petId, callback) => {
    return (dispatch, getState) => {
        const { user: { token }} = getState();
        if (petId) {
            dispatch({ type: "ORDERS_LOADING", payload: true })
            ApiClient.get(`${apiUrl}${PORT}/order/get-all-with-cat/${petId}`, {}, token, dispatch, getState).then((response) => {
                dispatch({ type: "ORDERS_LOADING", payload: false })
                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    dispatch({ type: "ORDERS", payload: response?.response_data })
                    return callback(response);
                } else {
                    return callback(response);
                }
            })
        } else {
            return callback({ status: false });
        }
    };
};

export const getProceduresByPetId = (body, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, } = getState();
        if (body.pet_id) {
            dispatch({ type: "ORDERS_LOADING", payload: true })
            ApiClient.post(`${apiUrl}${PORT}/order/get-procedures`, body, token, dispatch, getState).then((response) => {
                dispatch({ type: "ORDERS_LOADING", payload: false })
                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    dispatch({ type: "PROCEDURES_ORDERS", payload: response?.response_data })
                    return callback(response);
                } else {
                    return callback(response);
                }
            })
        } else {
            return callback({ status: false });
        }
    };
};

export const getInpatientOrdersByPetId = (body, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, } = getState();
        if (body.pet_id) {
            dispatch({ type: "ORDERS_LOADING", payload: true })
            ApiClient.post(`${apiUrl}${PORT}/order/get-inpatient-orders-by-pet`, body, token, dispatch, getState).then((response) => {
                dispatch({ type: "ORDERS_LOADING", payload: false })
                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    dispatch({ type: "INPATIENT_ORDERS", payload: response?.response_data })
                    return callback(response);
                } else {
                    return callback(response);
                }
            })
        } else {
            return callback({ status: false });
        }
    };
};

export const getHomeMedicationOrders = (body, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, } = getState();
        if (body.pet_id) {
            dispatch({ type: "ORDERS_LOADING", payload: true })
            ApiClient.post(`${apiUrl}${PORT}/order/get-home-medications-orders-by-pet`, body, token, dispatch, getState).then((response) => {
                dispatch({ type: "ORDERS_LOADING", payload: false })
                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    dispatch({ type: "HOME_MEDICATION_ORDERS", payload: response?.response_data })
                    return callback(response);
                } else {
                    return callback(response);
                }
            })
        } else {
            return callback({ status: false });
        }
    };
};

export const getOrderDetails = (id, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, } = getState();
        if (id) {
            ApiClient.get(`${apiUrl}${PORT}/order/get-details/${id}`, {}, token, dispatch, getState).then((response) => {
                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    return callback(response);
                } else {
                    return callback(response);
                }
            })
        } else {
            return callback({ status: false });
        }
    };
};

/**
 * @description function use to add new appointment
 * @param {*} params accepting object
 */
export const addNewOrder = (data, callback) => {
    return async (dispatch, getState) => {
        const {
            user: { token },
        } = getState();
        console.log('gets to add new order', data)
        await ApiClient.post(`${apiUrl}${PORT}/order/add`, data, token, dispatch, getState).then(
            (response) => {
                if (!response) {
                    return callback();
                } else if (!response && response.errors) {
                    toast.error(response.errors.msg, toasterConfig);
                    return callback(response);
                } else if (response?.status) {
                    toast.dismiss();
                    toast.success(response.msg, toasterConfig);
                    return callback(response);
                } else {
                    toast.error(response.message, toasterConfig);
                    return callback();
                }
            }
        );
    };
};

/**
 * @description function use to add new appointment
 * @param {*} params accepting object
 */
export const updateOrder = (data, callback) => {
    return (dispatch, getState) => {
        const {
            user: { token },
        } = getState();
        ApiClient.put(`${apiUrl}${PORT}/order/update`, data, token, dispatch, getState).then(
            (response) => {
                if (!response) {
                    return callback();
                } else if (!response && response.errors) {
                    toast.error(response.errors.msg, toasterConfig);
                    return callback(response);
                } else if (response?.status) {
                    toast.dismiss();
                    toast.success(response.msg, toasterConfig);
                    return callback(response);
                } else {
                    toast.error(response.message, toasterConfig);
                    return callback();
                }
            }
        );
    };
};

/**
 * @description function use to add new appointment
 * @param {*} params accepting object
 */
export const updateOrdersStatuses = (data, callback) => {
    return (dispatch, getState) => {
        const {
            user: { token },
        } = getState();
        ApiClient.put(
            `${apiUrl}${PORT}/order/updateOrdersStatuses`,
            data,
            token,
            dispatch, getState
        ).then((response) => {
            if (!response) {
                return callback();
            } else if (!response && response.errors) {
                toast.error(response.errors.msg, toasterConfig);
                return callback(response);
            } else if (response?.status) {
                toast.dismiss();
                toast.success(response.msg, toasterConfig);
                return callback(response);
            } else {
                //toast.error(response.message, toasterConfig);
                return callback();
            }
        });
    };
};

export const addNewTreatment = (body, callback) => {
    return (dispatch, getState) => {
        const { user: { token } } = getState();
        ApiClient.postFormData(`${apiUrl}${PORT}/treatment/add`, body, token, dispatch, getState).then((response) => {
            if (!response) {
                return callback();
            }
            else if (!response && response.errors) {
                toast.error(response.errors.msg, toasterConfig);
                return callback(response);
            } else if (response?.status) {
                toast.dismiss();
                toast.success(response.msg, toasterConfig);
                return callback(response);
            }
        });
    };
};
export const getTreatmentByPetId = (id, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, } = getState();
        if (id) {
            ApiClient.get(`${apiUrl}${PORT}/treatment/get-all/${id}`, {}, token, dispatch, getState).then((response) => {
                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    return callback(response);
                } else {
                    return callback(response);
                }
            })
        } else {
            return callback({ status: false });
        }
    };
};
export const getTreatmentDetailById = (id, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, } = getState();
        if (id) {
            ApiClient.get(`${apiUrl}${PORT}/treatment/get-single/${id}`, {}, token,dispatch, getState).then((response) => {
                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    return callback(response);
                } else {
                    return callback(response);
                }
            })
        } else {
            return callback({ status: false });
        }
    };
};