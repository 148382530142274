import React, { useRef, useState } from "react";
import { Button, FormGroup, Label } from "reactstrap";
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import SwitchCell from "shared/SwitchCell";
import { CiCalendar } from "react-icons/ci";
import ReactDatePicker from "react-datepicker";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { addStaffAvailability } from "context/actions/staffManagement";
import { toast } from "react-toastify";
import { toasterConfig } from "utils/constants";
import "./AddSchedule.scss";
import TimePicker from "shared/TimePicker";

const AddSchedule = ({
  doctorId,
  staffId,
  handleSuccess,
  clinicList,
  status,
  dataList,
}) => {
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const dispatch = useDispatch();
  const formRef = useRef();
  const [btnLoading, setBtnLoading] = useState(false);
  const [resetCount, setResetCount] = useState(0);
  const { holidays } = useSelector((state) => state.holidayReducer);

  const handleSave = async (values) => {
    const startDate = moment(values?.start_date);
    const endDate = moment(values?.end_date);
    let holidayExist = false;
    holidays?.forEach((elm) => {
      const dateToCheck = moment(
        elm?.date,
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      ).format("DD-MM-YYYY");
      if (
        dateToCheck === startDate.format("DD-MM-YYYY") &&
        dateToCheck === endDate.format("DD-MM-YYYY") &&
        elm.status === "close"
      ) {
        holidayExist = moment(dateToCheck, "DD-MM-YYYY").format("MM-DD-YYYY");
      }
      if (elm.isRecurring) {
        let newDateCheck = `${moment(dateToCheck, "DD-MM-YYYY").format(
          "DD-MM"
        )}-${startDate.format("YYYY")}`;
        if (
          newDateCheck === startDate.format("DD-MM-YYYY") &&
          newDateCheck === endDate.format("DD-MM-YYYY") &&
          elm.status === "close"
        ) {
          holidayExist = moment(newDateCheck, "DD-MM-YYYY").format(
            "MM/DD/YYYY"
          );
        }
      }
    });
    let alreadyExist = false;
    dataList?.forEach((elm) => {
      const dateToCheck = moment(elm?.date, "DD-MM-YYYY");
      const isBetween = dateToCheck.isBetween(startDate, endDate, null, "[]");
      if (isBetween && values?.clinic_id === elm?.clinic_id) {
        alreadyExist = true;
      }
    });
    if (holidayExist) {
      toast.error(`Holiday on ${holidayExist}`, toasterConfig);
    } else if (alreadyExist) {
      toast.error(
        "Availibility/Unavailibility already exist for this clinic and date!",
        toasterConfig
      );
    } else {
      setBtnLoading(true);
      let payload = {
        ...values,
        doctor_id: doctorId,
        staff_id: staffId,
        start_date: moment(values.start_date).format("DD-MM-YYYY"),
        end_date: moment(values.end_date).format("DD-MM-YYYY"),
        status: status,
      };
      const response = await dispatch(addStaffAvailability(payload));
      setBtnLoading(false);
      if (response?.statusCode === 201) {
        handleSuccess();
        setResetCount(resetCount + 1);
        toast.success(response?.message, toasterConfig);
        if (formRef?.current) {
          formRef?.current.resetForm();
        }
      }
    }
  };

  const initialValues = {
    clinic_id: "",
    start_date: null,
    end_date: null,
    start_time: "",
    end_time: "",
    isAvailable: true,
    isWeekendOff: true,
    isPublicHolidayOff: false,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required.")
      .email("Email must be a valid email"),
    password: Yup.string().required("Password is required."),
  });
  return (
    <div id="add-schedule-container">
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {(props) => {
          const { values, setFieldValue } = props;
          const {
            clinic_id,
            start_date,
            end_date,
            isAvailable,
            start_time,
            end_time,
            isWeekendOff,
            // isPublicHolidayOff,
          } = values;
          let clinicName =
            clinicList?.find((o) => o._id === clinic_id)?.clinic_name || "";
          return (
            <form>
              <div>
                <div className="form-item-blc d-flex align-items-center flex-fill dropdown_floating">
                  <div className="form-group-wrapper d-flex w-100">
                    <FormGroup className="input-wrapper2 no-floating flex-fill">
                      <div className="form-floating position-relative text-input">
                        <input
                          disabled
                          type="text"
                          className="form-control"
                          id="reply"
                          value={clinicName || "Select clinic"}
                        />
                        <label for="Taxdentificationnumber">Clinic</label>
                      </div>
                    </FormGroup>
                    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                      <DropdownToggle color="link">
                        <i className="icomoon-angle-down"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        {clinicList?.map((elm, index) => {
                          return (
                            <DropdownItem
                              key={index}
                              className="capitalize"
                              onClick={() => {
                                setFieldValue("clinic_id", elm?._id);
                              }}
                            >
                              {elm?.clinic_name}
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    </ButtonDropdown>
                  </div>
                </div>
              </div>
              <div>
                <FormGroup className="date-range-block" floating>
                  <div className="form-floating">
                    <ReactDatePicker
                      selectsRange
                      endDate={end_date}
                      startDate={start_date}
                      placeholderText="Select date"
                      onChange={(update) => {
                        setFieldValue("start_date", update?.[0] || null);
                        setFieldValue("end_date", update?.[1] || null);
                      }}
                    />
                    <Label
                      for="recurrence"
                      className="text-12-400 color-8693 cps-16"
                    >
                      Date
                    </Label>
                    <span className="select-icon-block">
                      <CiCalendar />
                    </span>
                  </div>
                </FormGroup>
              </div>
              <div className="mb-2">
                <SwitchCell
                  labelVisible
                  label={isAvailable ? "Available Time" : "Unavailable Time"}
                  className={isAvailable ? "" : "disable"}
                  onClick={() => {
                    setFieldValue("isAvailable", !isAvailable);
                  }}
                />
              </div>
              <div className="cmb-10 fa-center gap-2">
                <div className="w-50">
                  <TimePicker
                    label={isAvailable ? "Available" : "Unavailable"}
                    placeholder="Start time"
                    minIntarval="15"
                    id="start_time"
                    resetCount={resetCount}
                    onChange={(e) => {
                      setFieldValue("start_time", e?.value || "");
                    }}
                  />
                </div>
                <div className="flex-grow-1">
                  <TimePicker
                    label={isAvailable ? "Available" : "Unavailable"}
                    placeholder="End time"
                    minIntarval="15"
                    id="end_time"
                    startTime={start_time}
                    resetCount={resetCount}
                    onChange={(e) => {
                      setFieldValue("end_time", e?.value || "");
                    }}
                  />
                </div>
              </div>

              <div className="cmb-10">
                <SwitchCell
                  labelVisible
                  label="Weekend Off"
                  className={isWeekendOff ? "" : "disable"}
                  onClick={(e) => {
                    setFieldValue("isWeekendOff", !isWeekendOff);
                  }}
                />
              </div>
              {/* <div className="cmb-20">
                <SwitchCell
                  labelVisible
                  label="All Public Holidays Off"
                  className={isPublicHolidayOff ? "" : "disable"}
                  onClick={() => {
                    setFieldValue("isPublicHolidayOff", !isPublicHolidayOff);
                  }}
                />
              </div> */}
              <Button
                type="button"
                className="w-100"
                size="sm"
                onClick={() => {
                  handleSave(values);
                }}
                disabled={
                  !clinic_id ||
                  !start_date ||
                  !end_date ||
                  !start_time ||
                  !end_time ||
                  btnLoading
                    ? true
                    : false
                }
              >
                Publish Master Schedule{" "}
                {btnLoading && <Spinner size="sm" className="ms-2" />}
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddSchedule;
