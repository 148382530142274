import React, { useEffect, useState } from "react";
import { RiMenuSearchLine, RiMapPinRangeLine } from "react-icons/ri";
import { WiTime5 } from "react-icons/wi";
import { Button, Spinner } from "reactstrap";
import markEmailUnread from "../../../assets/img/icons/markEmailUnread.svg";
import { useDispatch, useSelector } from "react-redux";
import { Offcanvas } from "react-bootstrap";
import MedicationReport from "./MedicationReport";
import { getPetOwnerHealthSnapshot } from "context/actions/pet-owner-dashboard";
import { getVaccinationDetailsList } from "context/actions/patient-module/medical-history";
import { dateDiffFromNow, dueDaysFromDate } from "utils/common";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import "./PetOwnerHealthSnapshot.scss";
import NodataFoundBlock from "../../../shared/NoDataFoundBlock";

const PetOwnerHealthSnapshot = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userDetail = useSelector((state) => state.user.user_details);
  const { petDetails } = useSelector((state) => state.petOwnerDashboardReducer);
  const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);

  const { unreadChatMsg, unreadGroupMsg } = useSelector(
    (state) => state.realTimeChatReducer
  );
  const { vaccinationList } = useSelector(
    (state) => state.medicalHistoryReducer
  );
  const [pageData, setPageData] = useState(null);
  const [pageLoader, setPageLoader] = useState(true);
  const [isReport, setIsReport] = useState(true);
  const [wSize, setWSize] = useState(window.innerWidth);

  const handleResize = () => {
    setWSize(window.innerWidth);
  };

  const getPageData = async () => {
    if (petDetails) {
      const response = await dispatch(
        getPetOwnerHealthSnapshot(petDetails?._id)
      );
      setPageData(response?.response_data?.[0] || null);
      dispatch(
        getVaccinationDetailsList(petDetails?._id, {}, (res) => {
          if (res?.status) {
            setPageLoader(false);
          }
        })
      );
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
    setPageLoader(true);
    getPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petDetails]);

  const { user_image, firstname } = userDetail || {};
  const { pet_image, pet_name } = petDetails || {};
  const isOverlay = wSize < 1440;

  const {
    appointmentDetails = {},
    dignosesDetails = {},
    MedicationDetails = [],
    totalAppointment,
  } = pageData || {};

  return (
    <div id="pet-owner-health-snapshot-container" className="h-100">
      {pageLoader ? (
        <div className="f-center h-100">
          <Spinner animation="border" />
        </div>
      ) : (
        <div className="d-flex h-100">
          <div className="flex-grow-1 cp-15">
            <div className="fb-center gap-2">
              {/* <div className="top-search-block">
                <input type="text" placeholder="Search for anything..." />
              </div> */}
              <div className="fa-center gap-3">
              {clinic_settings?.pet_owner_preferences?.find_doctor &&

                <Button className="flex-fill" color="primary" type="submit">
                  Find doctor
                </Button>}
                {clinic_settings?.pet_owner_preferences?.send_request &&
                <Button className="flex-fill" color="primary" type="submit">
                  Send request
                </Button>}
              </div>
            </div>
            <div className="info-block">
              {pet_name && (
                <div className="left-container">
                  <div className="f-center bg-eeee color-cfcf h-80 w-80 rounded">
                    {pet_image ? (
                      <img src={pet_image} alt="pet-img" />
                    ) : (
                      <span className="material-symbols-outlined">
                        sound_detection_dog_barking
                      </span>
                    )}
                  </div>
                  <div className="text-18-600 mb-2 mt-1 color-2E65">
                    {pet_name}
                  </div>
                  {petDetails?.active && !petDetails?.deceased ? (
                    <div className="text-13-400 text-success">
                      Active Profile
                    </div>
                  ) : (
                    <div className="text-13-400 text-danger">
                      Inactive Profile
                    </div>
                  )}
                </div>
              )}
              <div>
                <div className="fa-center gap-2">
                  <div className="f-center bg-eeee color-cfcf h-40 w-40 rounded-circle">
                    {user_image ? (
                      <img
                        src={user_image}
                        alt="badge-img"
                        className="rounded-circle"
                      />
                    ) : (
                      <span className="material-symbols-outlined">person</span>
                    )}
                  </div>
                  <div className="text-24-600 color-2E65">
                    Hello, {firstname}!
                  </div>
                </div>
                <div className="text-14-400 mt-2 mb-2 color-2E65">
                  You have {totalAppointment} New Appointments today and{" "}
                  {unreadChatMsg + unreadGroupMsg} unread messages.
                </div>
                {/* <div className="d-flex gap-3">
                  <div>
                    <img src={markEmailUnread} alt="unread" />
                  </div>
                  <div className="text-14-400 color-2E65">
                    <div className="mb-1">Roger Operate at 4:30PM</div>
                    <div>Ordered Betnovita Medicine on urgent basis!</div>
                  </div>
                </div> */}
              </div>
            </div>

              <div className="row mt-3">
               
                  <div className="col-md-6">
                    <div className="card-container-block">
                      <div className="fb-center">
                        <div className="text-18-600 color-2E65">
                          Upcoming Appointment
                        </div>
                        <div
                          className="text-14-400 cursor-pointer color-2E65"
                          onClick={() => {
                            history.push("/pet-owner-appointments");
                          }}
                        >
                          View All
                        </div>
                      </div>

                      {appointmentDetails?._id ? (
                      <div className="mt-2 bg-white p-2 br-10">
                        <div className="fa-center gap-2 ">
                          {/* <div className="d-flex">
                            <img
                              src={avatarPic}
                              alt="u-img"
                              className="hw-50"
                            />
                          </div> */}
                          <div className="f-center bg-eeee color-cfcf h-49 w-49 rounded-circle">
                            {appointmentDetails?.doctor_img ? (
                              <img
                                src={appointmentDetails?.doctor_img}
                                alt="badge-img"
                              />
                            ) : (
                              <span className="material-symbols-outlined text-14-400">
                                person
                              </span>
                            )}
                          </div>
                          <div className="d-flex flex-grow-1">
                            <div className="w-100">
                              <div className="fb-center">
                                <div className="text-16-400 color-2E65">
                                  Dr. {appointmentDetails?.doctor_first_name}{" "}
                                  {appointmentDetails?.doctor_last_name}
                                </div>
                                {/* <div className="d-flex">
                              <img
                                src={autoMode}
                                alt="u-img"
                                className="cursor-pointer"
                              />
                            </div> */}
                              </div>
                              <div className="text-13-400"></div>
                            </div>
                          </div>
                        </div>
                        <div className="text-13-400 color-2E65 fa-center gap-2 mt-2">
                          <div>
                            <WiTime5 size={16} color="#282E65" />
                          </div>
                          <div>
                            {appointmentDetails?.time} (
                            {moment(
                              appointmentDetails?.date,
                              "DD-MM-YYYY"
                            ).fromNow()}
                            )
                          </div>
                        </div>
                        <div className="text-14-400 color-2E65 fa-center gap-2 mt-1">
                          <div>
                            <RiMapPinRangeLine size={16} color="#282E65" />
                          </div>
                          <div>{appointmentDetails?.appointment_type}</div>
                        </div>
                      </div>): <NodataFoundBlock type="appointment" /> }
                    </div>
                  </div>
                
               
                  <div className="col-md-6">
                    <div className="card-container-block">
                      <div className="fb-center">
                        <div className="text-18-600 color-2E65">Diagnoses</div>
                        <div
                          className="text-14-400 color-2E65 cursor-pointer"
                          onClick={() => {
                            history.push("/pet-owner-health-records");
                          }}
                        >
                          View All
                        </div>
                      </div>
                      {dignosesDetails?._id ? (
                      <div className="mt-2 bg-white p-2 br-10 flex-grow-1">
                        <div className="text-16-400 color-2E65">
                          {dignosesDetails?.description}
                        </div>
                        {/* <div className="text-14-400 color-2E65 text-truncate mt-2">
                          {dignosesDetails?.description}
                        </div> */}
                        <div className="d-flex">
                          <div className="text-13-400 color-2E65 chunks ps-1 pe-1 mt-2 border-rounded">
                            {dignosesDetails?.status}
                          </div>
                        </div>
                        <div className="fa-center gap-2 mt-2">
                          {/* <div className="d-flex">
                            <img
                              src={avatarPic}
                              alt="u-img"
                              className="hw-20"
                            />
                          </div> */}
                          <div className="f-center bg-eeee color-cfcf h-20 w-20 rounded-circle">
                            {dignosesDetails?.doctor_img ? (
                              <img
                                src={dignosesDetails?.doctor_img}
                                alt="badge-img"
                              />
                            ) : (
                              <span className="material-symbols-outlined text-14-400">
                                person
                              </span>
                            )}
                          </div>
                          <div className="text-13-400 color-2E65">
                            Dr. {dignosesDetails?.doctor_first_name}{" "}
                            {dignosesDetails?.doctor_last_name}
                          </div>
                          {/* <div className="flex-grow-1 mt-1">
                        <div className="d-flex justify-content-end">
                          <img
                            src={autoMode}
                            alt="u-img"
                            className="cursor-pointer"
                          />
                        </div>
                      </div> */}
                        </div>
                      </div>) : <NodataFoundBlock type="medical history" />}
                    </div>
                  </div>
              </div>
              <div className="card-container-block mt-2">
                <div className="fb-center mb-2">
                  <div className="text-18-600">Vaccination Due</div>
                  <div
                    className="text-14-400 cursor-pointer"
                    onClick={() => {
                      history.push("/pet-owner-health-records");
                    }}
                  >
                    View All
                  </div>
                </div>
                { vaccinationList?.length > 0 ? ( vaccinationList.map((elm, index) => {
                  const dueDate = elm?.due_dates?.[0];
                  return (
                    <div
                      className="fb-center bg-white p-2 ps-3 pe-3 mb-2 vaccination-list-item"
                      key={index}
                    >
                      <div className="text-14-400 color-2E65">
                        {elm.product_name}
                      </div>
                      <div
                        className={`${
                          dateDiffFromNow(elm?.due_dates?.[0]).status == "IN"
                            ? "success-cell"
                            : dateDiffFromNow(elm?.due_dates?.[0]).status ==
                              "OUT"
                            ? "inactive-cell"
                            : "pending-cell"
                        } status-cell`}
                      >
                        {dueDaysFromDate(dueDate)}
                      </div>
                    </div>
                  );
                })) : <NodataFoundBlock type="medical history" />}
              </div>
          
          </div>
          {clinic_settings?.pet_owner_preferences?.medication_reports && MedicationDetails?.length > 0 && (
            <>
              {isOverlay ? (
                <Offcanvas
                  show={isReport}
                  placement="end"
                  id="medication-details-offcanvas"
                >
                  <Offcanvas.Body className="p-0 w-100">
                    <MedicationReport
                      petID={petDetails?._id}
                      userID={userDetail?._id}
                      data={MedicationDetails}
                      getPageData={getPageData}
                      onHide={() => {
                        setIsReport(false);
                      }}
                    />
                  </Offcanvas.Body>
                </Offcanvas>
              ) : (
                <MedicationReport
                  petID={petDetails?._id}
                  userID={userDetail?._id}
                  data={MedicationDetails}
                  getPageData={getPageData}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default PetOwnerHealthSnapshot;
