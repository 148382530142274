import React, { useEffect, useState, useRef } from "react";
import { Button, Form, FormGroup, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import './index.css';
import SwitchCell from "../../../../../shared/SwitchCell";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { toasterConfig } from "../../../../../utils/constants";
import { addOperatingRoom, getOperatingRoomsByClinicId, updateOperatingRoom } from "../../../../../context/actions/operatingRoomAction";
import Availability from "./AvailabilityUnavailaibility/Availability";
import moment from "moment"


const AddOr = ({ className = '', closeNewPointPanel, refresh, addPoint, setSelectedOperatingRoom, selectedOperatingRoom }) => {

    const [type, setType] = useState('Vitals')

    const [dropdownOpen, setOpen] = useState(false);
    const [dropdownOpenn, setOpenn] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);
    const togglee = () => setOpenn(!dropdownOpenn);
    const roomForRef = useRef(null)
    const currentConditionRef = useRef(null)
    const token = useSelector(state => state?.user?.token)
    const userDetails = useSelector(state => state?.user?.user_details)
    const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);
    const dispatch = useDispatch()
    // const [schedule, setSchedule] = useState([]);
    const [slots, setSlots] = useState([])

    let defaultInputState = {
        name: "",
        room_for: "",
        current_conditions: "",
        notes: "",
        active: true
    }

    useEffect(() => {
        if (selectedOperatingRoom) {

            var slotsArr = []
            if(selectedOperatingRoom.availability_slots){
                for (let item of selectedOperatingRoom.availability_slots) {
                    for (let time of item?.slots_time) {
                        let d = new Date(moment(item?.date + ' ' + time, 'DD/MM/YYYY h:mm a'));
                        slotsArr.push(d.toString());
                    }
                }
                // setSchedule(slotsArr);
            }


            setInputs({
                name: selectedOperatingRoom.name,
                room_for: selectedOperatingRoom.room_for,
                current_conditions: selectedOperatingRoom?.current_conditions || "",
                notes: selectedOperatingRoom.notes,
                active: selectedOperatingRoom.active
            })
        }
        else {
            // setSchedule([])
            setInputs(defaultInputState)
        }
    }, [selectedOperatingRoom])

    const [inputs, setInputs] = useState(defaultInputState)

    const handleInputChange = (e) => {
        e.preventDefault();
        setInputs({ ...inputs, [e.target.name]: e.target.value })
    }

    const handleDropDownSelect = (e, field, value) => {
        e.preventDefault();
        if (field == "room_for") {
            roomForRef.current.focus()
        }
        if (field == "current_conditions") {
            currentConditionRef.current.focus()
        }
        setInputs({ ...inputs, [field]: value })
    }

    const handleAddPoint = () => {
        console.log("handleAddPoint")
        addPoint({ type: type }, response => {
            console.log("response", response?.status)
            if (response?.status) {
                closeNewPointPanel()
                refresh();
            }

        })

    }

    const delay = (delayInms) => {
        return new Promise(resolve => setTimeout(resolve, delayInms));
    }



    const createUpdateOperatingRoom = () => {
        if (!selectedOperatingRoom) {
            let body = {
                ...inputs,
                clinic_id: clinic_settings.clinic_id,
                availability_slots: slots,
                created_by: userDetails?._id
            }

            dispatch(addOperatingRoom(body,async (data) => {
                await delay(100)

                setInputs(defaultInputState)
                dispatch(
                    getOperatingRoomsByClinicId(clinic_settings.clinic_id, {}, (res) => {
                    })
                )
                closeNewPointPanel()
                setSelectedOperatingRoom(null)
                // setSchedule([])
                toast.success("Operating room created successfully", toasterConfig)
            } ))
        }
        else {
            let body = {
                ...inputs,
                availability_slots: slots
            }

            dispatch(updateOperatingRoom(selectedOperatingRoom._id, body, (data) => {
                dispatch(
                    getOperatingRoomsByClinicId(clinic_settings.clinic_id, {}, (res) => {
                    })
                )
                setInputs(defaultInputState)
                closeNewPointPanel()
                setSelectedOperatingRoom(null)
                toast.success("Operating room updates successfully", toasterConfig)
            }))
        }

    }


    return (
        <>
            <div className={`ocular-new-point-panel-wrapper position-fixed d-flex flex-column overflow-auto ${className}`}>
                <div className="head d-flex align-items-center">
                    <h2>{selectedOperatingRoom ? "Edit" : "Add New"} Operating Room</h2>
                    <Button color="link" className="ms-auto panel-close-btn" onClick={() => {
                        setInputs(defaultInputState)
                        closeNewPointPanel()
                        setSelectedOperatingRoom(null)
                    }}>
                        <span className="material-symbols-outlined">
                            close
                        </span>
                    </Button>
                </div>

                <div className="inner align-items-center mt-2">
                    <Form>
                        <FormGroup className="input-wrapper2 mb-2">
                            <div className="form-floating position-relative text-input">
                                <input type="text" className="form-control" id="name" name="name" placeholder="Room Title" value={inputs.name} onChange={handleInputChange} />
                                <label htmlFor="name">Room Name</label>
                            </div>
                        </FormGroup>
                        <div className="form-group-wrapper d-flex w-100">
                            <FormGroup className="input-wrapper2 no-floating flex-fill mb-2">
                                <div className="form-floating position-relative text-input">
                                    <input type="text" className="form-control" id="room_for_input" ref={roomForRef} placeholder="Select from dropdown" value={inputs.room_for} name="room_for" />
                                    <label htmlFor="room_for_input">Room For</label>
                                </div>
                            </FormGroup>
                            <ButtonDropdown isOpen={dropdownOpenn} toggle={togglee}>

                                <DropdownToggle color="link">
                                    <i className="icomoon-angle-down"></i>
                                </DropdownToggle>
                                <DropdownMenu >
                                    <DropdownItem onClick={(e) => handleDropDownSelect(e, "room_for", "Cats, Dogs")}>
                                        Cats, Dogs
                                    </DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </div>
                        <div className="form-group-wrapper d-flex w-100">
                            <FormGroup className="input-wrapper2 no-floating flex-fill mb-2">
                                <div className="form-floating position-relative text-input">
                                    <input type="text" className="form-control" id="current_conditions" ref={currentConditionRef} name="current_conditions" value={inputs.current_conditions} placeholder="Select from dropdown" />
                                    <label htmlFor="current_conditions">Current Conditions, CPR</label>
                                </div>
                            </FormGroup>
                            <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>

                                <DropdownToggle color="link">
                                    <i className="icomoon-angle-down"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={(e) => handleDropDownSelect(e, "current_conditions", "Cancer, Advance life support")}>
                                        Cancer, Advance life support
                                    </DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </div>
                        <FormGroup className="input-wrapper2 mb-2">
                            <div className="form-floating position-relative text-input">
                                <input type="text" className="form-control" id="notes" placeholder="Add Notes" name="notes" onChange={handleInputChange} value={inputs.notes} />
                                <label htmlFor="notes">Add Notes </label>
                            </div>
                        </FormGroup>
                        <div className="status-rw d-flex align-items-center my-2" onClick={(e) => {
                            e.target.name = "active"
                            e.target.value = !inputs.active
                            handleInputChange(e)
                        }}>
                            {/* <span className="swtich-cell2 d-inline-flex align-items-center">
                                <span class="icomoon-check icon"></span>
                                <span className="d-inline-flex rounded-circle circle-icon ms-auto"></span>
                            </span> */}
                            {
                                inputs.active ? (
                                    <SwitchCell className={''} labelVisible={true} label="Active" />
                                ) :
                                    (
                                        <SwitchCell className={'disable'} labelVisible={true} label="Inactive" />
                                    )
                            }



                        </div>

                      
                        {/* <Availability schedule={schedule} setSchedule={setSchedule} /> */}


                        <div className="btn-blc border_btn mt-3 text-end">

                            {/*
                            <button type="button" class="flex-fill btn border-btn" onClick={() => {
                                setInputs(defaultInputState)
                                closeNewPointPanel()
                                setSelectedOperatingRoom(null)
                            }}>CANCEL</button>*/}
                            <Button color="success" onClick={() => { createUpdateOperatingRoom() }} className="add-btn">{selectedOperatingRoom ? "UPDATE" : "CREATE ROOM"}</Button>
                        </div>

                    </Form>
                </div>
            </div>
        </>
    );
};

export default AddOr;
