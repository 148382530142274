import React from "react";
import { useDispatch } from "react-redux";
import { Input } from "reactstrap";
import { searchAuditLogs } from "../../../../../context/actions/auditLogs";
import DateBoxInput from "../../../../../shared/FormsInput/DateBoxInput";

const AuditLogFilter = ({ roles, page, limit }) => {
    const auditTypes = ["Modified", "New", "Delete"]
    const dispatch = useDispatch();

    const handleTextSearch = (e) => {
        let value = e.target.value;
        dispatch(searchAuditLogs({ searchText: value }, { page, limit }, () => { }))
    }

    const handleSearchByAuditTypes = (e) => {
        let value = e.target.value;
        dispatch(searchAuditLogs({ auditType: value?.toLowerCase() }, { page, limit }, () => { }))
    }
    return (
        <>
            <div className="table-list-patients-filter-blc-wrapper">
                <div className="left-col">
                <div className="table-list-filter-blc table-list d-flex align-items-center overflow-auto">
                    <div className="search-cell col-cell position-relative">
                        <span className="icomoon-search d-inline-flex align-items-center justify-content-center position-absolute"></span>
                        <Input placeholder="Search" onChange={handleTextSearch} />
                    </div>
                    <div className="input-cell col-cell">
                        <Input style={{textTransform: 'capitalize'}} type="select" name="select">
                            <option value="">All Roles</option>
                            {roles?.map((item) => <option value={item.role}>{item.role}</option>)}
                        </Input>
                    </div>
                    <div className="input-cell col-cell">
                        <Input type="select" name="select" onChange={handleSearchByAuditTypes}>
                            <option  value="">All Types</option>
                            {auditTypes?.map((item) => <option value={item}>{item}</option>)}
                        </Input>
                    </div>
                    {/* <div className="input-cell col-cell">
                        <DateBoxInput
                            id="dob"
                            name="date"
                            placeholder="Date of Birth"
                            value={new Date()}
                            sendInputDate={(value) => {
                                // const birth = format(new Date(value), 'yyyy-MM-dd')
                                // formik.setFieldValue('dob', birth)
                            }}
                            // required={true}
                            todayField={true}
                            className="m-0"
                        />
                    </div> */}
                </div>
                </div>
                </div>
        </>
    );
};

export default AuditLogFilter;
