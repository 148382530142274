import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from 'reactstrap';
import ImagePlaceholder from "../../../../../shared/ImagePlaceholder";
import './index.css';
import { apiUrl, PORT } from "../../../../../environment";
import axios from "axios";
import SwitchCell from "../../../../../shared/SwitchCell";
import { getKennels } from "../../../../../context/actions/kennels";


const KennelsList = ({ addNewKennel, setSelectedKennel }) => {
    const dispatch = useDispatch()

    const userDetails = useSelector((state) => state.user.user_details);
    const {clinic_details} = useSelector((state) => state?.clinicReducer)

    const allKennels = useSelector(state => state?.kennelReducer?.kennels)

    useEffect(() => {
        dispatch(
            getKennels(clinic_details?._id, (res) => {
            })
        );
    }, []);

    return (
        <>
            <div className="waiting-room-list">
                {
                    allKennels?.map((ele) => {
                        return (
                            <div className="item-row d-flex">
                                <div className="left-col">
                                    <div className="room-info">
                                        <h4>{ele.kennel_name}</h4>
                                        {/* <h5>[Operating room facilities information goes here]</h5> */}
                                    </div>
                                </div>
                                <div className="right-col d-inline-flex align-items-center">
                                    {
                                        ele.active ? (
                                            <div className="status-rw d-flex align-items-center">
                                                <span className="swtich-cell2 d-inline-flex align-items-center mb-0">
                                                    <span className="icomoon-check icon"></span>
                                                    <span className="d-inline-flex rounded-circle circle-icon ms-auto"></span>
                                                </span>
                                            </div>
                                        )
                                            : (
                                                <div className="status-rw d-flex align-items-center">
                                                    <span className={`swtichCell d-inline-flex align-items-center disable`}>
                                                        <span className="switch-btn flex-fill d-inline-flex align-items-center flex-shrink-0">
                                                            <span className="icomoon-check icon"></span>
                                                            <span className="d-inline-flex rounded-circle circle-icon ms-auto"></span>
                                                        </span>
                                                    </span>
                                                </div>
                                            )
                                    }



                                    <Button color="link" className="d-inline-flex align-items-center justify-content-center"
                                        onClick={() =>
                                            setSelectedKennel(ele)
                                        }
                                    >
                                        <span className="icomoon-pencil"></span>
                                    </Button>
                                </div>
                            </div>
                        )

                    })
                }

            </div>
        </>
    );

};

export default KennelsList;
