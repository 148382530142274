import React from "react";
// import { useSelector } from "react-redux";
// import moment from "moment";
import "./index.scss";
import { useSelector } from "react-redux";
import { formatPhoneNumber } from "utils/common";

const ProfileDescription = ({ clinicData }) => {
  const {clinic_details} = useSelector((state) => state.clinicReducer)
  return (
    <>
      <div className="profile-description row-block">
        <p className="d-flex">
          <label>Phone</label>
          <span>{clinic_details?.phone ? formatPhoneNumber( clinic_details.phone) : "000-000-0000"}</span>
        </p>
        <p className="d-flex">
          <label>Email</label>
          <span>
            {clinic_details?.email ? clinic_details.email : "paul.miller@email.com"}
          </span>
        </p>
        <p className="d-flex">
          <label>Address</label>
          <span>
            {clinic_details?.address
              ? clinic_details.address
              : "Model City, San Antonio,Los Angeles"}
          </span>
        </p>
      </div>
    </>
  );
};

export default ProfileDescription;
