import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import TextInput from "../../../../shared/FormsInput/TextBox";
import UserPlaceholder from "../../../../shared/UserPlaceholder";
import UploadImageBlock from "../../../../shared/UploadImageBlock";
import "./index.scss";
import { Form, Formik } from "formik";
import { addPetOwnerInitialValue, addPetOwnerSchema } from "./schema";
import { checkPetOwnerEmail } from "../../../../context/actions/patient-module/patient/pet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import EmailInput from "shared/FormsInput/EmailInput";

import PhoneNumberInput from "shared/FormsInput/PhoneInput";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'


const OwnerInfo = ({ step, onFileChange, file, onSubmit = () => {} }) => {
  const dispatch = useDispatch();
  const [imageFile, setImageFile] = useState(file);
  const [imageUrl, setImageUrl] = useState(null);
  const { petAndOwnerDetails, petFormData } = useSelector(
    (state) => state.petReducer
  );
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (file) {
      if (imageFile) {
        URL.revokeObjectURL(imageFile);
      }
      setImageFile(URL.createObjectURL(file));
    }
  }, [file]);

  const [initialValue, setInitialValue] = useState(addPetOwnerInitialValue);

  useEffect(() => {
    if (petAndOwnerDetails) {
      setInitialValue(
        (p) =>
          (p = {
            firstname: petAndOwnerDetails?.firstname,
            lastname: petAndOwnerDetails?.lastname,
            phone: petAndOwnerDetails?.phone,
            email: petAndOwnerDetails?.email,
            address: petAndOwnerDetails?.address,
          })
      );
      setImageUrl(petAndOwnerDetails?.user_image);
    } else if (petFormData?.firstname) {
      //data from redux state
      setInitialValue(
        (p) =>
          (p = {
            ...petFormData,
          })
      );
    }
    setLoading(false);
  }, [petAndOwnerDetails]);

  const handleCancel = () => {
    history.go(0);
  };

	const handleSubmit = (values) => {
		dispatch(checkPetOwnerEmail(values?.email, (res) => {
			if(res && res?.status) {
				onSubmit(values)
			} else {

			}
		}))
	}
	return (
		<>
			{!loading && <Formik
				initialValues={initialValue}
				validationSchema={addPetOwnerSchema}
				onSubmit={handleSubmit}
			>
				{(formik) => (
					<Form>
						<div className="owner-info d-flex">
							<div className="left-col flex-fill">
								<div className="description-blc">
									<div className="row-block d-flex">
										<label className="required-input">First Name </label>
										<div className="right-col flex-fill">
											<TextInput
												type="text"
												name="firstname"
												id="firstname"
												placeholder="Enter First Name"
												isRequired={true}
												label="First Name"
												className="mb-0"
												labelVisible={false}
												value={formik?.values?.firstname}
												onChange={formik.handleChange}
											/>
											{formik?.errors?.firstname && formik?.touched?.firstname && formik?.errors?.firstname}
										</div>
									</div>
									<div className="row-block d-flex">
										<label className="required-input">Last Name</label>
										<div className="right-col flex-fill">
											<TextInput
												type="text"
												name="lastname"
												id="lastname"
												placeholder="Enter Last Name"
												isRequired={true}
												label="Last Name"
												className="mb-0"
												labelVisible={false}
												value={formik?.values?.lastname}
												onChange={formik.handleChange}
											/>
											{formik?.errors?.lastname && formik?.touched?.lastname && formik?.errors?.lastname}
										</div>
									</div>
									<div className="row-block d-flex">
										<label className="required-input">Phone Number</label>
										<div className="right-col flex-fill">
											
											<PhoneNumberInput
											 

											name="phone"
											id="phone"
											required={true}
											className="mb-0"
											value={formik?.values?.phone}
				onChange={(e) => {formik.setFieldValue('phone', '+'+e);
				}} />

					{/*
				
											<PhoneInput
											 

											name="phone"
											id="phone"
											required={true}
											className="mb-0"
											value={formik?.values?.phone}
				onChange={formik.handleChange} />
				
											
											{/*


											<TextInput
												type="text"
												name="phone"
												id="phone"
												placeholder="Enter Phone Number"
												isRequired={true}
												label="Phone Number"
												className="mb-0"
												labelVisible={false}
												value={formik?.values?.phone}
												onChange={formik.handleChange}
											/>*/}
											{formik?.errors?.phone && formik?.touched?.phone && formik?.errors?.phone}
										</div>
									</div>
									<div className="row-block d-flex">
										<label className="required-input">Email Address</label>
										<div className="right-col flex-fill">

										{/*<EmailInput
											name="email"
											type="text"
											placeholder="Enter email address"
											id="email"
											value={formik?.values?.email}
											onChange={formik.handleChange}
											icon={'icon'}
											required={true}
											label="Email Address"
												labelVisible={false}
											className="email mb-0"
				/>*/}
										
											<TextInput
												type="email"
												name="email"
												id="email"
												placeholder="Enter Email Address"
												isRequired={true}
												label="Email Address"
												className="mb-0"
												labelVisible={false}
												value={formik?.values?.email}
												onChange={formik.handleChange}
											/>
											{formik?.errors?.email && formik?.touched?.email && formik?.errors?.email}
										</div>
									</div>
									<div className="row-block d-flex">
										<label>Address Information</label>
										<div className="right-col flex-fill">
												<TextInput
													type="text"
													name="address"
													id="address"
													placeholder="Enter Address Information"
													isRequired={true}
													label="Address"
													className="mb-0"
													labelVisible={false}
													value={formik?.values?.address}
													onChange={formik.handleChange}
													autoComplete="shipping address-line1"
												/>
											{formik?.errors?.address && formik?.touched?.address && formik?.errors?.address}
										</div>
									</div>
									{/* <div className="row-block d-flex">
							<label>Patient Notes
								(For office use only)</label>
							<div className="right-col flex-fill">
								<TextInput
									type="textarea"
									name="pet_note"
									id="pet_note"
									placeholder="Enter Patient Notes"
									isRequired={true}
									className="mb-0"
									labelVisible={false}
								/>
							</div>
						</div> */}
                  </div>
                </div>
                <div className="right-col">
                  <div className="profile-cell text-center">
                    <h4>Profile Picture</h4>
                    <div className="pic-cell">
                      <div className="pic d-inline-flex rounded-circle overflow-hidden position-relative">
                        <span className="upload-cell position-absolute w-100 h-100 d-inline-flex align-items-center justify-content-center">
                          <UploadImageBlock
                            petImage={imageFile}
                            sendPetImage={(value) => {
                              console.log("sendPetImage", value);
                              setImageFile(value);
                            }}
                            petImageObj={(value) => {
                              console.log("petImageObj", value);
                              onFileChange(value);
                            }}
                          />

                          {/*<Input type="file" className="position-absolute" onChange={onFileChange} />
												<FontAwesomeIcon icon={faCamera} />*/}
                        </span>

                        {imageFile || imageUrl ? (
                          <img
                            className="img-fluid w-100 h-100"
                            src={imageFile || imageUrl}
                            alt="User"
                          />
                        ) : (
                          <UserPlaceholder />
                        )}

                        {/* <UserPlaceholder /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {step == 0 && (
                <div className="proceed-btns d-flex align-items-center justify-content-between">
                  <Button
                    color="link"
                    onClick={handleCancel}
                    className="btn btn-outline-light"
                  >
                    Cancel
                  </Button>
                  <Button
                    color="success"
                    type="submit"
                    className="btn btn-outline-light"
                  >
                    Save & Proceed
                  </Button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      }
    </>
  );
};

export default OwnerInfo;
