import { useEffect, useState } from "react";
import moment from "moment";
import FilterComponent from "./FilterComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  getBloodProduct,
  addBloodProduct,
  updateBloodProduct,
  deleteBloodProduct,
} from "../../../../../../context/actions/patient-module/flowsheets/BloodProducts";
import DeletePopup from "../DeletePopup";
import BloodProductsCell from "./BloodProductsCell";
import NodataFoundBlock from "shared/NoDataFoundBlock";
import DatePopover from "../DatePopover";
import { toast } from "react-toastify";
import { toasterConfig } from "utils/constants";
import "./BloodProducts.scss";
import Spinner from "shared/Spinner";
toast.configure();

const BloodProductsKeys = [
  {
    key: "blood_unit_id_number",
    title: "Blood Unit ID Number",
  },
  {
    key: "blood_unit_type",
    title: "Blood Unit Type",
    options: ["A-", "A+", "AB-", "AB+", "B-", "B+", "O-", "O+"],
  },
  {
    key: "blood_unit_product",
    title: "Blood Unit Product",
    options: [
      "Packed Red Blood Cells",
      "Red Blood Cells",
      
      "Fresh Frozen Plasma",
      "Platelets",
    
      "Cryoprecipitate",
      "Whole Blood",
    ],
  },
  {
    key: "special_considerations",
    title: "Special Considerations",
    options: [
      "None",
      "CMV Negative",
      "Irradiated",
      "Leucocytes Rediced",
      "Washed",
      "Sickle Cell Negative",
    ],
  },
  {
    key: "expiration_date",
    title: "Expiration Date & Time",
  },
  {
    key: "patient_blood_type",
    title: "Patient Blood Type",
    options: ["A-", "A+", "AB-", "AB+", "B-", "B+", "O-", "O+"],
  },
  {
    key: "patient_verification",
    title: "Patient Verification",
  },
  {
    key: "volume",
    title: "Volume (mL)",
  },
  {
    key: "transfusion_reaction",
    title: "Transfusion Reaction",
  },
  {
    key: "reaction_type",
    title: "Reaction Type",
  },
  {
    key: "reaction_intervention",
    title: "Reaction Intervention",
  },
  {
    key: "reason_not_completed",
    title: "Reason Not Completed",
  },
  {
    key: "time_stopped",
    title: "Date & Time Stopped",
  },
  {
    key: "volume_infused",
    title: "Volume Infused (mL)",
  },
  {
    key: "treatment_response",
    title: "Treatment Response",
  },
];

const BloodProducts = ({ petId }) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.user_details);
  const [deleteID, setDeleteID] = useState("");
  const [editData, setEditData] = useState(null);
  const [recentDate, setRecentDate] = useState("");
  const [dateList, setDateList] = useState([]);
  const [pageData, setPageData] = useState({
    headerData: [],
    bodyData: [],
  });

  const [filterData, setFilterData] = useState({
    searchText: "",
    selectedDates: [],
    date: "",
  });
const [isLoading, setIsLoading] = useState(false);
  const handleAdd = async () => {
    const payload = {
      pet_id: petId,
      created_by: userDetails?._id,
      date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    };
    const response = await dispatch(addBloodProduct(payload));
    if (response?.status) {
      fetchPageData(filterData);
    }
    if (response?.message) {
      if (response?.status) {
        toast.success(response.message, toasterConfig);
      } else {
        toast.error(response.message, toasterConfig);
      }
    }
  };

  const handleEditDetails = async (fieldData) => {
    const isDate = ["date", "expiration_date", "time_stopped"].includes(
      fieldData?.fieldId
    );
    let fVal =
      isDate && fieldData?.fieldValue
        ? moment(fieldData?.fieldValue, "YYYY-MM-DDTHH:mm").format(
            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
          )
        : fieldData?.fieldValue;
    const payload = {
      _id: fieldData?.id,
      [fieldData?.fieldId]: fVal,
    };
    const response = await dispatch(updateBloodProduct(payload));
    if (response?.status) {
      fetchPageData(filterData);
      // setEditData(null);
    }
  };

  const handleDelete = async () => {
    setDeleteID("");
    const response = await dispatch(deleteBloodProduct(deleteID));
    if (response?.status) {
      setTimeout(() => {
        fetchPageData(filterData);
      }, 1000);
    }
  };

  const handleResponse = (resData) => {
    let bodyKeys = BloodProductsKeys;
    const headerData = resData?.map((o) => {
      let isAnyVal = bodyKeys.some((e) => o[e?.key]);
      return { date: o?.date, id: o?._id, isValueExist: isAnyVal };
    });
    if (resData?.length > 0) {
      bodyKeys = bodyKeys?.map((o) => {
        const data = resData?.map((e) => {
          return {
            ...e,
            value: e?.[o?.key] || "",
          };
        });
        return { ...o, data: data };
      });
    }
    setPageData({
      headerData: headerData,
      bodyData: bodyKeys,
    });
  };

  const fetchPageData = async (APIData) => {
    setIsLoading(true)
    const response = await dispatch(
      getBloodProduct({
        pet_id: petId,
        filterDates: APIData?.selectedDates || [],
      })
    );
    let lastDate = response?.response_data?.recentDate
      ? moment(
          response?.response_data?.recentDate,
          "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
        ).format("DD-MM-YYYY")
      : "";
    let resDates = response?.response_data?.reportDate || [];
    let resData = response?.response_data?.bloodProductReports || [];
    handleResponse(resData);
    setRecentDate(lastDate);
    setDateList([...new Set(resDates)]);
    setIsLoading(false)
  };

  useEffect(() => {
    if (filterData?.date) {
      fetchPageData(filterData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    let currentDate = moment().format("DD-MM-YYYY");
    let newData = {
      searchText: "",
      selectedDates: [currentDate],
      date: currentDate,
    };
    setFilterData(newData);
    fetchPageData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { headerData = [], bodyData = [] } = pageData || {};

  const displayData = bodyData?.filter((o) =>
    o.title?.toLowerCase().includes(filterData?.searchText?.toLowerCase())
  );

  return (<>
   {isLoading ? <Spinner  height={150} /> : <div id="blood-products-container">
      {deleteID && (
        <DeletePopup
          handleDelete={handleDelete}
          onHide={() => {
            setDeleteID("");
          }}
        />
      )}
      <div className="title-head-block">
        <div className="title-block">Blood Products</div>
        <div onClick={handleAdd}>
          <span className="icomoon-plus-btn" />
        </div>
      </div>
      {filterData?.date && (
        <FilterComponent
          recentDate={recentDate}
          dateList={dateList}
          filterData={filterData}
          setFilterData={setFilterData}
        />
      )}
      {displayData?.length === 0 ? (
        <NodataFoundBlock />
      ) : (
        <div className="blood-products-body mt-3">
          <div className="head-block">
            <div className="head-cell">-Title-</div>
            {headerData?.map((elem, index) => {
              const { date, isValueExist, id } = elem;
              const cellDate = moment(
                date,
                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
              ).format("MM/DD/YYYY");
              const cellTime = moment(
                date,
                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
              ).format("hh:mmA");
              const isDateEdit =
                editData?.id === id && editData?.fieldId === "date";
              return (
                <div key={index} className="head-cell">
                  <div className="d-flex gap-2 position-relative">
                    <span
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setEditData({
                          id: id,
                          fieldId: "date",
                          fieldValue: moment(
                            date,
                            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                          ).format("YYYY-MM-DDTHH:mm"),
                        });
                      }}
                    >
                      {cellDate}
                      <br />
                      {cellTime}
                    </span>
                    {isDateEdit && (
                      <DatePopover
                        defaultValue={date}
                        value={editData?.fieldValue}
                        setEditData={setEditData}
                        onChange={(e) => {
                          setEditData((prev) => {
                            return {
                              ...prev,
                              fieldValue: e.target.value,
                            };
                          });
                        }}
                        handleSave={() => {
                          handleEditDetails(editData);
                        }}
                      />
                    )}
                    {!isValueExist && (
                      <span
                        className="date-delete mb-0"
                        onClick={() => {
                          setDeleteID(id);
                        }}
                      >
                        <div className="remove_btn">-</div>
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
            <div className="head-cell" />
          </div>
          <div className="body-content">
            {displayData?.map((elem, index) => {
              const { key, title, data } = elem;
              return (
                <div key={index} className="body-block">
                  <div className="body-cell">{title}</div>
                  {data?.map((dataElem, dataIndex) => {
                    const { value } = dataElem;
                    return (
                      <div
                        className="body-cell"
                        key={dataIndex}
                        onClick={() => {
                          if (
                            editData?.fieldId !== key ||
                            editData?.id !== dataElem?._id
                          ) {
                            const isDate = [
                              "expiration_date",
                              "time_stopped",
                            ].includes(key);
                            setEditData({
                              id: dataElem?._id,
                              options: elem?.options || [],
                              fieldId: key,
                              fieldValue: isDate
                                ? value
                                  ? moment(
                                      value,
                                      "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                    ).format("YYYY-MM-DDTHH:mm")
                                  : ""
                                : value,
                            });
                          }
                        }}
                      >
                        <BloodProductsCell
                          cellKey={key}
                          data={dataElem}
                          editData={editData}
                          setEditData={setEditData}
                          handleEditData={handleEditDetails}
                          isAnyFieldValue={headerData[dataIndex]?.isValueExist}
                        />
                      </div>
                    );
                  })}
                  <div className="body-cell" />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>}
  </>
   
  );
};

export default BloodProducts;
