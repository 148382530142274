import * as Yup from "yup";
/**
 * @description declaration form schema for client validations
 */

export const tasksSchema = Yup.object().shape({

  tasks: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(),
      frequency: Yup.string().required(),
      time: Yup.object().shape({
        value: Yup.number(),
        unit: Yup.string()
      })
    })
  ),
});

export const tasksInit = {
  tasks: [], 
};