import React from "react";
import "./index.scss";

const AppointmentSteps = ({
  appointmentInfo,
  activeAccordianVal,
  intakeForm,
  appointmentTime,
}) => {
  return (
    <>
      <div className="appointment-steps text-center">
        <ul className="list-unstyled m-0 d-flex">
          <li
            className={`d-flex flex-column align-items-center position-relative ${
              activeAccordianVal === "appointment_info" && "active"
            }`}
          >
            <span className="icon d-inline-flex align-items-center justify-content-center flex-shrink-0 rounded-circle">
              {!appointmentInfo.appointment_type || !appointmentInfo.reason ? (
                <span className="material-symbols-outlined">add</span>
              ) : (
                <span className="icomoon-check"></span>
              )}
            </span>
            <div className="txt">
              <h3>Appointment Info.</h3>
            </div>
          </li>
          <li
            className={`d-flex flex-column align-items-center position-relative ${
              activeAccordianVal === "schedule_appointment" && "active"
            }`}
          >
            <span className="icon d-inline-flex align-items-center justify-content-center flex-shrink-0 rounded-circle">
              {appointmentTime?.length === 0 ? (
                <span className="material-symbols-outlined">add</span>
              ) : (
                <span className="icomoon-check"></span>
              )}
            </span>
            <div className="txt">
              <h3>Schedule</h3>
            </div>
          </li>
          <li
            className={`d-flex flex-column align-items-center position-relative ${
              activeAccordianVal === "patient_intake_form" && "active"
            }`}
          >
            <span className="icon d-inline-flex align-items-center justify-content-center flex-shrink-0 rounded-circle">
              {intakeForm?.length === 0 ? (
                <span className="material-symbols-outlined">add</span>
              ) : (
                <span className="icomoon-check"></span>
              )}
            </span>
            <div className="txt">
              <h3>Patient Intake Form</h3>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default AppointmentSteps;
