import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";
import { getHospitalDetailsById } from "./clinic";

toast.configure();
/**
 * Clinic Settings Api endpoints
 * /clinic-settings/get-by-clinic-id/:clinic_id
 * /clinic-settings/get-by-user-id/:user_id
 * /clinic-settings/update
 * /clinic-settings/get-permissions-settings/:clinic_id
 * /clinic-settings/update-invoice-logo/:clinic_id
 * /clinic-settings/update-brand-logo/:clinic_id
 * /clinic-settings/update-monogram/:clinic_id
 * /clinic-settings/update-permission
 * /clinic-settings/update-invoice-logo/:clinic_id
 */

/**
 *
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */
export const getClinicSettingsByClinicId = (params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    const clinicID = params?.id || clinic_details?._id;
    ApiClient.get(
      `${apiUrl}${PORT}/clinic-settings/get-by-clinic-id/${clinicID}`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      dispatch({ type: "CLINIC_LOADING", payload: false });
      if (!response) {
        return callback();
      } else if (response?.status) {
        dispatch({
          type: "GET_CLINIC_SETTINGS",
          payload: response?.response_data,
        });
        dispatch({
          type: "GET_CLINIC_PERMISSION",
          payload: response?.response_data?.permission,
        });

        return callback(response);
      } else {
        // //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};
/**
 *
 * @param {string} role
 * @param {Function} callback
 * @returns
 */
export const getClinicPermission = (role, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
      clinicReducer: { clinic_details },
    } = getState();
    ApiClient.get(
      `${apiUrl}${PORT}/clinic-settings/get-clinic-permissions/${clinic_details?._id}/${role}`,
      {},
      token, dispatch, getState
    ).then((response) => {
      // dispatch({ type: "CLINIC_LOADING", payload: false })

      if (!response) {
        return callback();
      } else if (response?.status) {
        //localStorage.setItem("permission", response?.response_data?.permission);
        dispatch({
          type: "GET_CLINIC_PERMISSION_FOR_USER",
          payload: response?.response_data?.permission,
        });
        return callback(response);
      } else {
        // //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};



/**
 *
 * @param {string} role
 * @param {Function} callback
 * @returns
 */
export const getUnallocatedRefLab = (params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
      clinicReducer: { clinic_details },
    } = getState();
    ApiClient.get(
      `${apiUrl}${PORT}/clinic-settings/get-unallocated-ref-lab/${clinic_details?._id}`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      // dispatch({ type: "CLINIC_LOADING", payload: false })

      if (!response) {
        return callback();
      } else if (response?.status) {
        return callback(response);
      } else {
        return callback(response);
      }
    });
  };
};


/**
 *
 * @param {string} role
 * @param {Function} callback
 * @returns
 */
export const assignUnallocatedResult = (body,callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user }
    
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/clinic-settings/assign-unallocated-result`,
      body,
      token,
      dispatch, getState
    ).then((response) => {
      // dispatch({ type: "CLINIC_LOADING", payload: false })

      if (!response) {
        return callback();
      } else if (response?.status) {
        return callback(response);
      } else {
        return callback(response);
      }
    });
  };
};




/**
 *
 * @param {string} role
 * @param {Function} callback
 * @returns
 */
export const getUnallocatedInHouse = (callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
      clinicReducer: { clinic_details },
    } = getState();
    ApiClient.get(
      `${apiUrl}${PORT}/clinic-settings/get-unallocated-in-house/${clinic_details?._id}`,
      {},
      token,
      dispatch, getState
    ).then((response) => {
      // dispatch({ type: "CLINIC_LOADING", payload: false })

      if (!response) {
        return callback();
      } else if (response?.status) {
        return callback(response);
      } else {
        return callback(response);
      }
    });
  };
};



/**
 *
 * @param {string} role
 * @param {Function} callback
 * @returns
 */
export const getPendingWorkList = (callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
      clinicReducer: { clinic_details },
    } = getState();
    ApiClient.get(
      `${apiUrl}${PORT}/clinic-settings/get-pending-work-list/${clinic_details?._id}`,
      {},
      token,
      dispatch, getState
    ).then((response) => {
      // dispatch({ type: "CLINIC_LOADING", payload: false })

      if (!response) {
        return callback();
      } else if (response?.status) {
        return callback(response);
      } else {
        return callback(response);
      }
    });
  };
};



/**
 * function to fetch active integrations
 * @param {string} role
 * @param {Function} callback
 * @returns
 */
export const getActiveIntegrations = (callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
      clinicReducer: { clinic_details },
    } = getState();
    ApiClient.get(
      `${apiUrl}${PORT}/integration-credential/get/${clinic_details?._id}`,
      {},
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (response?.status) {
        return callback(response);
      } else {
        return callback(response);
      }
    });
  };
};



/**
 *
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */
export const getClinicSettings = (params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();

    ApiClient.get(
      `${apiUrl}${PORT}/clinic-settings/get-by-user-id/${
        user?.role === "admin" || user?.role === "clinic"
          ? user?._id
          : user?.added_by
      }`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      // dispatch({ type: "CLINIC_LOADING", payload: false })

      if (!response) {
        return callback();
      } else if (response?.status) {
        dispatch({
          type: "GET_CLINIC_SETTINGS",
          payload: response?.response_data,
        });
        dispatch({
          type: "GET_CLINIC_PERMISSION",
          payload: response?.response_data?.permission,
        });
        // return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        // return callback(response);
      }
    });
  };
};

/**
 *
 * @param {Object} body
 * @param {Function} callback
 * @returns
 */
export const updateClinicSettings = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.put(
      `${apiUrl}${PORT}/clinic-settings/update`,
      body,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (response?.status) {
        dispatch(getClinicSettingsByClinicId({}, () => {}));
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};

/**
 * Get Roles and permission
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */
export const getClinicRolePermissions = (params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    dispatch({ type: "PERMISSION_LOADING", payload: true });
    ApiClient.get(
      `${apiUrl}${PORT}/clinic-settings/get-permissions-settings/${clinic_details?._id}`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      dispatch({ type: "PERMISSION_LOADING", payload: false });
      if (!response) {
        return callback();
      } else if (response?.status) {
        dispatch({
          type: "GET_ROLE_PERMISSIONS",
          payload: response?.response_data,
        });
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};

/**
 * Update Roles and permission
 * @param {Object} body
 * @param {Function} callback
 * @returns
 */
export const updateRolePermissions = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    body.clinic_id = clinic_details?._id;
    ApiClient.put(
      `${apiUrl}${PORT}/clinic-settings/update-permission`,
      body,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (response?.status) {
        toast.dismiss();
        toast.success(response.message, toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};

/**
 * Get Roles and permission
 * @param {Object} body
 * @param {Function} callback
 * @returns
 */
export const searchRolePermissions = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    body.clinic_id = clinic_details?._id;
    dispatch({ type: "PERMISSION_LOADING", payload: true });
    ApiClient.post(
      `${apiUrl}${PORT}/clinic-settings/search-role-permission`,
      body,
      token,
      dispatch, getState
    ).then((response) => {
      dispatch({ type: "PERMISSION_LOADING", payload: false });
      if (!response) {
        return callback();
      } else if (response?.status) {
        dispatch({
          type: "GET_ROLE_PERMISSIONS",
          payload: response?.response_data,
        });
        return callback(response);
      } else {
        dispatch({ type: "GET_ROLE_PERMISSIONS", payload: false });
        return callback(response);
      }
    });
  };
};
/**
 *
 *
 *   * @param {Object} params
 * @param {Function} callback
 * @returns
 */
export const getFormAndTemplates = (params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      ApiClient.get(
        `${apiUrl}${PORT}/clinic-settings/get-forms-templates/${clinic_details?._id}`,
        params,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          // dispatch({ type: "GET_FORM_TEMPLATES", payload: response?.response_data })
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    }
  };
};

/**
 *
 * @param {Object} formData
 * @param {Function} callback
 * @returns
 */
export const updateInvoicePreferencesLogo = (formData, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      ApiClient._putFormData(
        `${apiUrl}${PORT}/clinic-settings/update-invoice-logo/${clinic_details?._id}`,
        formData,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch(getClinicSettingsByClinicId({}, () => {}));
          toast.success(response.message, toasterConfig);
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    }
  };
};

/**
 *
 * @param {Object} formData
 * @param {Function} callback
 * @returns
 */
export const updateBrandLogo = (formData, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      ApiClient._putFormData(
        `${apiUrl}${PORT}/clinic-settings/update-brand-logo/${clinic_details?._id}`,
        formData,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch(getClinicSettingsByClinicId({}, () => {}));
          dispatch(getHospitalDetailsById(clinic_details?._id, {}, () => {}));
          toast.success(response.message, toasterConfig);
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    }
  };
};

/**
 *
 * @param {Object} formData
 * @param {Function} callback
 * @returns
 */
export const updateMonogram = (formData, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
      clinicReducer: { clinic_details },
    } = getState();
    if (clinic_details) {
      ApiClient._putFormData(
        `${apiUrl}${PORT}/clinic-settings/update-monogram/${clinic_details?._id}`,
        formData,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch(getClinicSettingsByClinicId({}, () => {}));
          toast.success(response.message, toasterConfig);
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    }
  };
};

/**
 *
 * @param {Object} body
 * @param {Function} callback
 * @returns
 */
export const updateClinicGeneralInfo = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.put(
      `${apiUrl}${PORT}/clinic-settings/update-general-info`,
      body,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (response?.status) {
        dispatch(getHospitalDetailsById(body?.clinic_id));
        return callback(response);
      } else {
        toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};

export const addClinicTreatmentPlan = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.postFormData(
      `${apiUrl}${PORT}/clinic-settings/add-treatment-plan-template`,
      body,
      token, dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response?.status) {
        // toast.dismiss();
        // toast.success(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};

export const updateTreatmentPlanKey = (id, body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.put(
      `${apiUrl}${PORT}/clinic-settings/treatment-plan-keys/${id}`,
      body,
      token, dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.message, toasterConfig);
        return callback(response);
      } else if (response?.status) {
        toast.dismiss();
        toast.success(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};
export const updateTreatmentPlanCardDetails = (id, body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.putFormData(
      `${apiUrl}${PORT}/clinic-settings/edit-treatment-plan-details/${id}`,
      body,
      token, dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.message, toasterConfig);
        return callback(response);
      } else if (response?.status) {
        toast.dismiss();
        toast.success(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};
export const removeFileFromTreatmentPlanCard = (id, body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.put(
      `${apiUrl}${PORT}/clinic-settings/remove-file/${id}`,
      body,
      token, dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.message, toasterConfig);
        return callback(response);
      } else if (response?.status) {
        toast.dismiss();
        toast.success(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};
export const removeCardFromPlan = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.put(
      `${apiUrl}${PORT}/clinic-settings/remove-treatment-plan-details`,
      body,
      token, dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.message, toasterConfig);
        return callback(response);
      } else if (response?.status) {
        toast.dismiss();
        toast.success(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};

export const getClinicTreatmentPlan = (id, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    if (id) {
      ApiClient.get(
        `${apiUrl}${PORT}/clinic-settings/get-clinic-treatment-plan/${id}`,
        {},
        token, dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          return callback(response);
        } else {
          return callback(response);
        }
      });
    } else {
      return callback({ status: false });
    }
  };
};



/**
 * Create new integration credential object
 * @param {Object} body
 * @param {Function} callback
 * @returns 
 */
export const addIntegrationCredential = (body, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, clinicReducer: { clinic_details } } = getState();
        body.clinic_id = clinic_details?._id
        ApiClient.post(`${apiUrl}${PORT}/integration-credential/create`, body, token, dispatch, getState).then(response => {
            if (!response) {
                return callback();
            }
            else if (response?.status) {
                return callback(response);
            } else {
                return callback(response);
            }

        });
    }
};


/**
 * Remove integration credential object
 * @param {Object} body
 * @param {Function} callback
 * @returns 
 */
export const removeIntegrationCredential = (id, callback) => {
  return (dispatch, getState) => {
      const { user: { token } } = getState();
     ApiClient.delete(`${apiUrl}${PORT}/integration-credential/remove-integration/${id}`, token, dispatch, getState).then(response => {
          if (!response) {
              return callback();
          }
          else if (response?.status) {
              return callback(response);
          } else {
              return callback(response);
          }

      });
  }
};



/**
 * Create new integration credential object
 * @param {Object} body
 * @param {Function} callback
 * @returns 
 */
export const updateIntegrationCredential = (body, callback) => {
  return (dispatch, getState) => {
      const { user: { token }, clinicReducer: { clinic_details } } = getState();
      body.clinic_id = clinic_details?._id
      ApiClient.put(`${apiUrl}${PORT}/integration-credential/update`, body, token, dispatch, getState).then(response => {
          if (!response) {
              return callback();
          }
          else if (response?.status) {
              return callback(response);
          } else {
              return callback(response);
          }

      });
  }
};
