import HomeRoutes from "./home";
import DicomViewerRoutes from "./dicom-viewer";
import AuthRoutes from "./auth";
import NotFoundRoute from "./notFound";

// const Routes = [
//   ...HomeRoutes,
//   ...AuthRoutes,
//   ...NotFoundRoute,
// ];
const Routes = () => {
  const homeRoutes = HomeRoutes();
  return [...homeRoutes, ...AuthRoutes, ...DicomViewerRoutes, ...NotFoundRoute];
};
export default Routes;
