import React from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input } from "reactstrap";
import "./index.scss";
import DateBoxInput from "../../FormsInput/DateBoxInput";
import { useSelector } from "react-redux";
import { useState } from "react";
import { format } from "date-fns";

const ScheduleFilter = ({ checkAppointmentSlot, onLocationChange, doctorFilter, directAppt, setDirectAppointment, setOverrideToggle }) => {
	const { cities } = useSelector(state => state.appContentReducer);
	const [activeDate, setActiveDate] = useState('today');
	const [selectDate, setSelectDate] = useState(false);
	const [disable, setDisable] = useState('disable');

	const [todayDate, setTodayDate] = useState({
		date: format(new Date(), 'd MMM, EE'),
		check_date: format(new Date(), 'dd-MM-yyyy'),
		is_date: 'today'
	});
	
	const [tomorrowDate, setTomorrowDate] = useState({
		date: format(new Date().setDate((new Date().getDate() + 1)), 'd MMM, EE'),
		check_date: format(new Date().setDate((new Date().getDate() + 1)), 'dd-MM-yyyy'),
		is_date: 'tomorrow'
	});

	const handleSelectDate = (dateFormat, dateType) => {
		setActiveDate(dateType);
		checkAppointmentSlot(dateFormat.check_date)
	}
	const handleToggle = () => {
		if(disable === '') {
			setDisable('disable');
			setOverrideToggle(false);
		} else {
			setDisable('');
			setOverrideToggle(true);
		}
	}
	return (
		<>
			<div className="schedule-filter-blc d-flex align-items-center position-relative">
				<div className="left-col d-flex flex-col gap-[5px]">
					<div className="day-btns d-inline-flex">
						<Button color="link" className={`${activeDate === 'today' && 'active'}`} onClick={() => handleSelectDate(todayDate, 'today')}>Today {todayDate.date}</Button>
						<Button color="link" className={`${activeDate === 'tomorrow' && 'active'}`} onClick={() => handleSelectDate(tomorrowDate, 'tomorrow')}>Tomorrow {tomorrowDate.date}</Button>

						<DateBoxInput
							id="dob"
							name="date"
							placeholder="Select Day"
							sendInputDate={(value) => {
								setActiveDate('select-date')
								checkAppointmentSlot(format(new Date(value), 'dd-MM-yyyy'))
								setSelectDate(new Date(value))
							}}
							value={selectDate && format(selectDate, 'dd-MM-yyyy')}
							todayField={false}
							//future={true}
							className={`m-0 ${activeDate === 'select-date' && 'active'}`}
						/>
					</div>

					{(directAppt === 0) &&
							<div className="toggle-view-cell d-inline-flex align-items-center w-[280px] justify-center h-[34px] rounded-md mr-[10px]" style={{ border: "1px solid #eeeef2"}}>
								Override Existing Bookings
								<label className={`swtich-cell-override d-inline-flex align-items-center ml-[10px] ${disable}`} onClick={handleToggle}>
									<span className="icon"></span>
									<span className="d-inline-flex rounded-circle circle-icon ms-auto mb-0"></span>
								</label>
							</div>
						}
				</div>
				<div className="right-col ms-auto">
					<div className="align-items-end input-cell-blc d-inline-flex">
					
						<div className="item-col dropdown">
							<Input type="select" name="select" onChange={onLocationChange}>
								<option value="">Location Filter</option>
								{(cities || []).map((item, i) => <option key={i} value={item?.city}>{item?.city}</option>)}
							</Input>
						</div>
						<div className="item-col search">
							<div className="search-block position-relative">
								<Input type="text" placeholder="Search Doctor" onChange={(e) => doctorFilter(e.target.value)} />
								<span className='position-absolute d-inline-flex align-items-center justify-content-center mb-0'>
									<FontAwesomeIcon icon={faSearch} />
								</span>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</>
	);
}

export default ScheduleFilter;