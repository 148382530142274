import React from "react";
import { Button } from "reactstrap";
import { capitalizeFirstLetter } from "../../utils/common"; // eslint-disable-line
import PlaceholderInitial from "../PlaceholderInitial"; // eslint-disable-line
import UserPlaceholder from "../UserPlaceholder";
import { useSelector } from "react-redux";

const UserProfileHead2 = ({ userDetails, className = "" }) => {
  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);
  return (
    <>
      <div className={`user-profile-head2 d-flex ${className}`}>
        <div className="left-col">
          <div className="user-profile-pic d-flex align-items-center">
            <div className="pic d-inline-flex rounded-circle overflow-hidden">
              {userDetails?.user_image ? (
                <img
                  className="img-fluid w-100 h-100"
                  src={userDetails?.user_image}
                  alt="User"
                />
              ) : (
                <UserPlaceholder />
              )}
            </div>
            <div className="info">
              <h3>
              {
							clinic_settings?.doctor_name_format  === 0 ? <span> {userDetails?.lastname } {userDetails?.firstname}  </span> :  <span> {userDetails?.firstname} {userDetails?.lastname }  </span>
						  }
                {/* {userDetails?.firstname} {userDetails?.lastname} */}
              </h3>
              <div className="feature">
                <label className="item-dot d-inline-flex align-items-center">
                  {userDetails?.speciality}
                </label>
                <label>Doctor</label>
                {/* <label>[Role 1 e.g. Doctor] ,</label>
								<label>[Rolename 2 e.g Admin]</label> */}
              </div>
              <p
                className={userDetails?.active ? "text-success" : "text-danger"}
              >
                {userDetails?.active ? "Active" : "Inactive"}
              </p>
            </div>
          </div>
        </div>
        <div className="right-col ms-auto">
          <div className="btn-cell d-inline-flex align-items-center">
            {permissions?.view_staff_management && (
              <>
                <Button
                  color="link"
                  className="share-btn p-0 d-inline-flex align-items-center justify-content-center"
                >
                  <i className="icomoon-email-fill p-0"></i>
                </Button>
                <Button
                  color="link"
                  className="share-btn p-0 d-inline-flex align-items-center justify-content-center"
                >
                  <i className="icomoon-share p-0"></i>
                </Button>
              </>
            )}
            {permissions?.edit_staff_management && (
              <Button
                color="link"
                className="share-btn p-0 d-inline-flex align-items-center justify-content-center"
              >
                <i className="icomoon-pencil p-0"></i>
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfileHead2;
