import React from "react";
import AppLogo from "../../../shared/AppLogo";
import './Head.scss';
const Head = (
	{ heading = '', subHeading = false, className = '' }) => {

	return (
		<div className={`signup-head text-center ${className}`}>
			<AppLogo />
			<h3>{heading}</h3>
			{
				subHeading && <h4>
					Enter your username or email address and we will send you a link to reset your password.
				</h4>
			}

		</div>
	);
};

export default Head;
