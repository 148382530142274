import React, { useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../context/actions/user";
import { toast } from "react-toastify";
import { inactivityMessage } from "../utils/messages";
import { useIdleTimer } from "react-idle-timer";
import { timeout, toasterConfig } from "../utils/constants";
import Sidebar from "../shared/Sidebar";
import RightSidebar from "../shared/RightSidebar";
import "./videoCallLayout.scss"
import ZoomVideo from "@zoom/videosdk";

const VideoCallLayout = ({ children, className }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  let userDetails = useSelector((state) => state.user.user_details);
  dispatch({ type: "GET_ZOOM_CLIENT", payload: ZoomVideo.createClient() });

  //   useLayoutEffect(() => {
  // }, [])
  // if (!userDetails || !localStorage.getItem("USER")) {
  //   dispatch(
  //     logout(() => {
  //       history.push("/login");
  //     })
  //   );
  // }

  // const onIdle = () => {
  //   dispatch(
  //     logout(() => {
  //       history.push("/login");
  //       toast.success(inactivityMessage, toasterConfig);
  //     })
  //   );
  // };
  // useIdleTimer({
  //   timeout,
  //   onIdle,
  // });
  return (
    <>
      <div className="video-chat-wrapper">
        {children}
      </div >
    </>
  );
};

export default VideoCallLayout;
