import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateClinicSettings } from "context/actions/clinicSettings";
import "./index.css";


const petOwnerSections = ['find_doctor',
'send_request',
'medication_reports',
'appointment_request',
'test_results',
'vitals',
'treatment_plans',
'medication_plans',
'billing', 'set_status']

const petOwnerMappings =  {

  'find_doctor': 'Find Doctor',
'send_request': 'Send Request',
'medication_reports': 'Medication Reports',
'appointment_request': 'Appointment Requests',
'test_results': 'Test Results',
'vitals': 'Vitals',
'treatment_plans': 'Treatment Plans',
'medication_plans': 'Medication Plans',
'billing': 'Billing',
'set_status': 'Set Patient Status'


}

const PetOwnerPreferences = ({ }) => {
  const dispatch = useDispatch();
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );
  


  const toggleActiveStatus = (ele) => {
    let body = {
      clinic_id: clinic_settings.clinic_id,
      update_query: "pet_owner_preferences"
    };
    body[ele] = !clinic_settings?.pet_owner_preferences?.[ele]
    dispatch(
      updateClinicSettings(body, (res) => {
      })
    );
  };


  useEffect(() => {
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  return (
    <>
      <div className="waiting-room-list">
        {petOwnerSections?.map((ele) => {
          return (
            <div className="item-row d-flex">
              <div className="left-col">
                <div className="room-info">
                  <h4>{petOwnerMappings[ele]}</h4>
                </div>
              </div>
              {permissions?.edit_pet_owner_preferences && (
              <div className="right-col d-inline-flex align-items-center">
            
                  <div onClick={() => {toggleActiveStatus(ele)}} className="status-rw d-flex align-items-center">
                    
                    {clinic_settings?.pet_owner_preferences?.[ele] ? (
                    <span className="swtich-cell2 d-inline-flex align-items-center mb-0">
                      <span className="icomoon-check icon"></span>
                      <span className="d-inline-flex rounded-circle circle-icon ms-auto"></span>
                    </span>):
                    <span
                    className={`swtichCell d-inline-flex align-items-center disable`}
                  >
                    <span
                      className="switch-btn flex-fill d-inline-flex align-items-center flex-shrink-0"
                      style={{ width: "32px", height: "16px" }}
                    >
                      <span className="icomoon-check icon"></span>
                      <span className="d-inline-flex rounded-circle circle-icon ms-auto"></span>
                    </span>
                  </span>

                    }
                  </div>
            
              </div>)}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default PetOwnerPreferences;
