import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../../../../api-client";
import { apiUrl, PORT } from "../../../../../environment";
import { toasterConfig } from "../../../../../utils/constants";

toast.configure();

export const getOcularByPetId = (payload) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  return await ApiClient.post(
    `${apiUrl}${PORT}/ocular/get-all`,
    payload,
    token,
    dispatch, getState
  );
};

export const addNewOcular = (payload) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();

  if (token) {
    if (payload?.coordinate) {
      const response = await ApiClient.post(
        `${apiUrl}${PORT}/ocular/add`,
        payload,
        token,
        dispatch, getState
      );
      if (response?.message) {
        if (response?.status) {
          // toast.success(response.message, toasterConfig);
        } else {
          toast.error(response.message, toasterConfig);
        }
      }
      return response;
    } else {
      toast.error("Please select a point on the model", toasterConfig);
    }
  } else {
    return {};
  }
};

export const addOcularDetails = (payload) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();

  if (token) {
    const response = await ApiClient.post(
      `${apiUrl}${PORT}/ocular/add-location-details`,
      payload,
      token,
      dispatch, getState
    );
    if (response?.message) {
      if (response?.status) {
        toast.success(response.message, toasterConfig);
      } else {
        toast.error(response.message, toasterConfig);
      }
    }
    return response;
  } else {
    return {};
  }
};

export const deleteParentOcular = (id) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  if (token) {
    const response = await ApiClient.post(
      `${apiUrl}${PORT}/ocular/remove-ocular-pin-location/${id}`,
      {},
      token,
      dispatch, getState
    );
    if (response?.message) {
      if (response?.status) {
        toast.success(response.message, toasterConfig);
      } else {
        toast.error(response.message, toasterConfig);
      }
    }
    return response;
  } else {
    return {};
  }
};

export const deleteOcularDetails = (payload) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();

  if (token) {
    const response = await ApiClient.post(
      `${apiUrl}${PORT}/ocular/remove-ocular-location`,
      payload,
      token,
      dispatch, getState
    );
    if (response?.message) {
      if (response?.status) {
        toast.success(response.message, toasterConfig);
      } else {
        toast.error(response.message, toasterConfig);
      }
    }
    return response;
  } else {
    return {};
  }
};
