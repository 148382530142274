import React, { useEffect, useState } from "react";
import ProfileHead from "./ProfileHead";
import ProfileDescription from "./ProfileDescription";
import ProfileChart from "./ProfileChart";
import ProfileTags from "../../PatientProfile/ProfileTags";
import ProfileAllergy from "../../PatientProfile/ProfileAllergy";
import ProfileContact from "./ProfileContact";
import ProfileVaccination from "./ProfileVaccination";
import { useDispatch, useSelector } from "react-redux";
import LoaderUI from "../../loader";
import ProfilePlan from "./ProfilePlan";
import "./index.scss";

import { getPetDetailsByPetId } from "../../../context/actions/patient-module/patient/pet";
import { useParams } from "react-router-dom";
import HealthSnapshotOffcanvas from "shared/PatientProfile/HealthSnapshotOffcanvas";
const PatientProfile = ({ close }) => {
  const { petDetails } = useSelector((state) => state.petReducer);
  const { petDetailsSide, sideLoading } = useSelector(
    (state) => state?.petReducer
  );
  const loading = sideLoading;

  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const [details, setDetails] = useState(null);

  const [isHealthSnapShot, setIsHealthSnapShot] = useState(false);

  const handleShow = () => setIsHealthSnapShot(true);
  const handleClos = () => setIsHealthSnapShot(false);

  useEffect(() => {
     if(petDetailsSide || petDetails){
       dispatch(getPetDetailsByPetId(petDetailsSide?._id ? petDetailsSide?._id : petDetails?._id, {}, (res) => {
        if(res && res?.status ){
          setDetails(res?.response_data)        
        }
      }));
    }
  }, []);
  /*

  useEffect(() => {

    if(petDetails?._id){
      dispatch(getPetDetailsByPetId( petDetails?._id, {}, (res) => {
        if(res && res?.status ){
          setDetails(res?.response_data)
          console.log('in right hand menu fetch',res)
        
        }
      }));
    }

  }, [petDetails?._id])*/

  const getPetData = () => {
    dispatch(
      getPetDetailsByPetId(petDetailsSide?._id, {}, (res) => {
        if (res && res?.status) {
          setDetails(res?.response_data);
        }
      })
    );
  };

  return loading ? (
    <LoaderUI />
  ) : (
    <>
      <HealthSnapshotOffcanvas
        show={isHealthSnapShot}
        close={handleClos}
        pId={petDetailsSide?._id}
      />
      <div className="patient-profile d-flex h-100 flex-column overflow-auto">
        <ProfileHead
          close={close}
          refresh={getPetData}
          setPetDetails={setDetails}
          setShow={setShow}
          showHealthRightCanvas={handleShow}
        />
        <div className="inner overflow-auto">
          <ProfileDescription />
          <ProfileChart />
          <ProfileTags
            setPetDetails={setDetails}
            showRemoveIcon={false}
            target="right-behaviour-tag"
          />
          <ProfileAllergy
            showRemoveIcon={false}
            target="right-behavior-allergy"
          />
          <ProfileContact />

          <ProfileVaccination />
          {/*petDetails?.wellness_plan_id && <ProfilePlan />*/}
        </div>
      </div>
    </>
  );
};

export default PatientProfile;
