import React, { useEffect } from "react";
import PatientProfile from "../../../shared/PatientProfile";
import TabScreens from "./TabScreens";
import "./index.scss";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPetDetailsById } from "../../../context/actions/patient-module/patient/pet";
import LoaderUI from "../../../shared/loader";
import NodataFoundBlock from "../../../shared/NoDataFoundBlock";

const PatientDetail = () => {
  const { loading, petDetails } = useSelector((state) => state.petReducer);
  const params = useParams();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getPetDetailsById(params.petId, {}, () => {}));
  // }, []);

  useEffect(() => {
    dispatch(getPetDetailsById(params.petId, {}, () => {}));
  }, [params?.petId]);

  return (
    <>
      {loading ? (
        <LoaderUI overlay={true} />
      ) : !!petDetails ? (
        <div className="page-wrapper patient-detail-wrapper position-relative d-flex overflow-auto">
          <div className="left-col d-flex flex-column overflow-auto">
            <PatientProfile />
          </div>
          <div className="right-col d-flex flex-fill overflow-auto">
            <TabScreens />
          </div>
        </div>
      ) : (
        <NodataFoundBlock />
      )}
    </>
  );
};

export default PatientDetail;
