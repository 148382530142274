import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Input } from "reactstrap";
import './index.scss';

const CheckBox = (
	{
		className = '',
		labelVisible = true,
		label = '',
		type = 'checkbox',
		name = '',
		onChange,
		checked = false,
		ischecked = true,
		disabled = false
	}
) => {
	return (
		<>
			<label className={`d-inline-flex align-items-center checkbox-wrapper ${className}`}>
				<span className="checkbox-cell position-relative d-inline-flex align-items-center justify-content-center">
					{/* {
						ischecked ? */}
							<Input type={type} className={`position-absolute ${disabled ? "pointer-notallow" : ""}`} name={name} onChange={onChange} checked={checked} disabled={disabled} />
					{/* 	:
						<Input type={type} className="position-absolute" name={name} onChange={onChange}  disabled={disabled}  />
					 } */}
					
					<span className="icon-box d-inline-flex align-items-center justify-content-center">
						<span className="icon d-inline-flex">
							<FontAwesomeIcon icon={faCheck} />
						</span>
					</span>
				</span>
				{
					labelVisible && <span className="txt">{label}</span>
				}
			</label>
		</>
	);
}

export default CheckBox;