import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Table,
} from "reactstrap";
import { sidebarPanelAction } from "../../context/actions/sidebarPanelAction";
import PageHeader from "../../shared/PageHeader";
import InventoryStats from "../../shared/InventoryStats";
import Products from "./Products";
import Services from "./Services";
import ServiceDetails from "./ServicesDetails";
import PointOfSale from "./PointOfSale";
import PurchaseOrder from "./PurchaseOrder";
import Suppliers from "./Suppliers";
import "./index.scss";
import { useHistory } from "react-router-dom";
import AllServiceList from "./AllServices/AllServiceList";
import Bundles from "./Bundles";
import VaccineSchedule from "./VaccineSchedule";
import VaccineScheduleFormPanel from "./VaccineSchedule/Accordions/VaccineScheduleFormPanel";
import DiagnosticServices from "./DiagnosticServices";
import ReferenceData from "./ReferenceData"

const BackDrop = (props) => {
  return <div className="backdrop" onClick={props.closeDrawer}></div>;
};

const Inventory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isNewVaccineSchedule, setNewVaccineSchedule] = useState(false);
  const [selectedServiceDetails, setSelectedServiceDetails] = useState();
  const sidebarPanelState = useSelector(
    (state) => state.sidebarPanelReducer.isSidePanel
  );

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  function handleOpenDrawerButton(item) {
    setSelectedServiceDetails(item);
    setDrawerOpen(!drawerOpen);
  }
  function handleBackdropClick() {
    setDrawerOpen(false);
  }

  const showSidePanel = () => {
    dispatch(sidebarPanelAction(!sidebarPanelState));
  };

  const [activeTab, setActiveTab] = useState("0");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleAddNew = () => {
    const routeMapping = {
      0: "/inventory/add-new-service-in-inventory",
      1: "/inventory/add-new-product-in-inventory",
      2: "/inventory/add-new-pos-in-inventory",
      3: "/inventory/add-new-order",
      4: "/inventory/add-new-supplier-in-inventory",
      6: "/inventory/add-new-bundle-in-inventory",
    };

    let route = '';

    if (activeTab === '0' && permissions?.edit_services) {
      route = routeMapping[String(activeTab)];
    }
    if (activeTab === '1' && permissions?.edit_products) {
      route = routeMapping[String(activeTab)];
    }
    if (activeTab === '6' && permissions?.edit_bundles) {
      route = routeMapping[String(activeTab)];
    }
    if (activeTab === '2' && permissions?.edit_POS) {
      route = routeMapping[String(activeTab)];
    }
    if (activeTab === '4' && permissions?.edit_suppliers) {
      route = routeMapping[String(activeTab)];
    }
    if (activeTab === '3' && permissions?.edit_purchase_order) {
      route = routeMapping[String(activeTab)];
    }

    if (activeTab === "5" && permissions?.edit_vaccine_schedules) {
      setNewVaccineSchedule(true);
    }
    if (route && route !== "inventory/add-new-purchase-order-in-inventory") {
      history.push(route);
    }
  };

  return (
    <>
      <div className="page-wrapper position-relative d-flex flex-column flex-fill overflow-auto">
        <div className="page-header-wrapper d-flex align-items-center flex-shrink-0 right-column-visible">
          <Button
            className="sidebar-toggle-btn d-inline-flex align-items-center"
            color="link"
            onClick={showSidePanel}
          >
            <span className="material-symbols-outlined">menu</span>
          </Button>
          <div className="row-block d-flex align-items-center flex-fill">
            <div>
              <PageHeader
                className="dashboard-head flex-flex-shrink-0 mb-0"
                title="Inventory"
              ></PageHeader>
            </div>
            {permissions?.edit_inventory && !(activeTab === "7" || activeTab === "8") && (
              <div className="right-col ms-auto">
                <div className="btn-blc">
                  <Button
                    color="success"
                    className="add-btn py-2 h-auto me-2"
                    onClick={handleAddNew}
                  >
                    Add new
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="page-content d-flex flex-column flex-fill overflow-auto">
          {/*<InventoryStats />*/}
          <div className="tab-wrapper tab-wrapper-inventory  d-flex flex-column flex-fill overflow-auto">
            <Nav tabs className="flex-shrink-0">
              <NavItem>
                {permissions?.view_services &&
                  <NavLink
                    className={{ active: activeTab === "0" }}
                    onClick={() => {
                      toggle("0");
                    }}
                  >
                    Services
                  </NavLink>
                }
                {permissions?.view_products &&
                  <NavLink
                    className={{ active: activeTab === "1" }}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Products
                  </NavLink>
                }
                {permissions?.view_bundles &&
                  <NavLink
                    className={{ active: activeTab === "6" }}
                    onClick={() => {
                      toggle("6");
                    }}
                  >
                    Bundles
                  </NavLink>
                }
             
              </NavItem>
              <NavItem>
                {/*permissions?.view_POS &&
                  <NavLink
                    className={{ active: activeTab === "2" }}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Point of Sale
                  </NavLink>
                  */}
                {/*permissions?.view_purchase_orders &&
                  <NavLink
                    className={{ active: activeTab === "3" }}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    Purchase Orders
                  </NavLink>
                  */}
                {permissions?.view_suppliers &&
                  <NavLink
                    className={{ active: activeTab === "4" }}
                    onClick={() => {
                      toggle("4");
                    }}
                  >
                    Suppliers
                  </NavLink>
                }
                {permissions?.view_vaccine_schedules &&
                  <NavLink
                    className={{ active: activeTab === "5" }}
                    onClick={() => {
                      toggle("5");
                    }}
                  >
                    Vaccine Schedule
                  </NavLink>
                }


                {permissions?.view_diagnostic_services &&
                  <NavLink
                    className={{ active: activeTab === "7" }}
                    onClick={() => {
                      toggle("7");
                    }}
                  >
                     Diagnostic Services
                  </NavLink>
                }

<NavLink
                    className={{ active: activeTab === "8" }}
                    onClick={() => {
                      toggle("8");
                    }}
                  >
                    Reference Data
                  </NavLink>


                  {/* <NavLink
                    className={{ active: activeTab === "6" }}
                    onClick={() => {
                      toggle("7");
                    }}
                  >
                    Diangostic Tests
                  </NavLink> */}
                
              </NavItem>
            </Nav>
            <TabContent
              activeTab={activeTab}
              className="flex-fill"
            >
              <TabPane tabId="0">
                {permissions?.view_services &&
                  <Services handleOpenDrawerButton={handleOpenDrawerButton} />
                }
              </TabPane>
              <TabPane tabId="1">
                <Products />
              </TabPane>
              <TabPane tabId="2">
                <PointOfSale />
              </TabPane>
              <TabPane tabId="3" className="h-100">
                <PurchaseOrder />
              </TabPane>
              <TabPane tabId="4">
                <Suppliers />
              </TabPane>
              <TabPane tabId="5">
                <VaccineSchedule />
              </TabPane>
              <TabPane tabId="6">
                <Bundles />
              </TabPane>
              {permissions?.view_diagnostic_services &&
              <TabPane tabId="7">
                <DiagnosticServices />
              </TabPane>}
              <TabPane style={{overflow: 'visible'}} tabId="8">
                <ReferenceData />
              </TabPane>
            </TabContent>
          </div>
        </div>
        {drawerOpen ? (
          <ServiceDetails
            show={drawerOpen}
            cancel={drawerOpen}
            setCancel={setDrawerOpen}
            ServiceDetails={selectedServiceDetails}
          />
        ) : (
          ""
        )}
        {drawerOpen && <BackDrop closeDrawer={handleBackdropClick} />}

        {isNewVaccineSchedule && (
          <>
            <div
              className={` fixed top-0 right-0 bottom-0 left-0 z-[9] bg-black/20 transition-all duration-300 ease-in `}
            ></div>
          </>
        )}

        {isNewVaccineSchedule && (
          <VaccineScheduleFormPanel
            isActive={isNewVaccineSchedule}
            toggleVaccineSchedulePanel={() => {
              setNewVaccineSchedule(false);
            }}
          />
        )}
      </div>
    </>
  );
};

export default Inventory;
