import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Model from "shared/3dModel";
import { Accordion } from "react-bootstrap";
import { BsFillPinAngleFill } from "react-icons/bs";
import NodataFoundBlock from "../../../../../../shared/NoDataFoundBlock";
import moment from "moment";
import { BsFillImageFill } from "react-icons/bs";
import {
  getMassesByPetId,
  addNewMasses,
  addMasseDetails,
  deleteParentMasse,
  deleteMasseDetails,
} from "../../../../../../context/actions/patient-module/flowsheets/Masses/Masses";
import archive from "assets/img/archive.png";
import DeletePopup from "../DeletePopup";
import DatePopover from "../DatePopover";
import MassesCell from "./MassesCell";
// import FilterComponent from "../FilterComponent";
import FilterOptions from "../FilterOptions";
import "./Masses.scss";
import Spinner from "shared/Spinner";

const massesKeys = [
  {
    key: "date_time",
    title: "Date & Time",
  },
  {
    key: "size",
    title: "Size (mm)",
  },
  {
    key: "status",
    title: "Mass Status",
  },
  {
    key: "opened_closed",
    title: "Open or Closed",
    options: ["Open", "Closed"],
  },
  {
    key: "drainage",
    title: "Drainage (mL)",
  },
  {
    key: "site_assessment",
    title: "Site Assessment",
  },
  {
    key: "notes",
    title: "Notes",
  },
  {
    key: "mas_document",
    title: "Photo",
  },
];

const Masses = ({ petId, setDisplayURL }) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.user_details);
  const { petDetails } = useSelector((state) => state.petReducer);
  const [selectingNewPoint, setSelectingNewPoint] = useState(false);
  const [point, setPoint] = useState(false);
  const [pageData, setPageData] = useState([]);
  const [deleteData, setDeleteData] = useState(null);
  const [editData, setEditData] = useState(null);
  const [expandList, setExpandList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [optionType, setOptionType] = useState("2");
  const [filterData, setFilterData] = useState({
    searchText: "",
    selectedDates: [],
    date: "",
  });
  const handleExpand = (gIndex) => {
    let list = expandList;
    if (expandList.includes(gIndex)) {
      list = list.filter((o) => o !== gIndex);
    } else {
      list = [...list, gIndex];
    }
    setExpandList(list);
  };
  const handleAddPoint = async () => {
    const res = await dispatch(
      addNewMasses({
        pet_id: petId,
        created_by: userDetails?._id,
        coordinate: point,
      })
    );
    if (res?.status) {
      handleExpand(pageData?.length);
      handleAddEditDetails({
        locationId: res?.response_data?._id,
        createdAt: moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      });
      setSelectingNewPoint(false);
      setPoint(null);
    }
  };

  const handleAddEditDetails = async (payload) => {
    const formData = new FormData();
    for (const key in payload) {
      formData?.append(key, payload[key]);
    }
    const res = await dispatch(addMasseDetails(formData));
    if (res?.status) {
      fetchPageData(filterData);
    }
    // setEditData(null);
  };

  const handleMassesDelete = async (id) => {
    setDeleteData(null);
    const res = await dispatch(deleteParentMasse(id));
    if (res?.status) {
      setTimeout(() => {
        fetchPageData(filterData);
      }, 1000);
    }
  };

  const handleMassesDetailsDelete = async (data) => {
    setDeleteData(null);
    const res = await dispatch(deleteMasseDetails(data));
    if (res?.status) {
      setTimeout(() => {
        fetchPageData(filterData);
      }, 1000);
    }
  };

  const handleDelete = async () => {
    if (deleteData?.type === "1") {
      handleMassesDelete(deleteData?.locationId);
    } else {
      handleMassesDetailsDelete({
        locationId: deleteData?.locationId,
        locationDetailId: deleteData.locationDetailId,
      });
    }
  };

  const handleResponse = (resData) => {
    const newData = [];
    resData.forEach((o) => {
      const headerData = o?.locationDetails.map((e) => {
        let isAnyVal = massesKeys.some((o) => e[o?.key]);
        return {
          id: e?.locationDetailId,
          date: e?.createdAt || "",
          isValueExist: isAnyVal,
        };
      });
      let bodyData = [];
      if (o?.locationDetails?.length > 0) {
        bodyData = massesKeys?.map((e) => {
          const data = o?.locationDetails?.map((elm) => {
            return {
              ...elm,
              value: elm?.[e?.key] || "",
            };
          });

          return { ...e, data: data };
        });
      }
      let newObject = {
        id: o?._id,
        coordinate: o?.coordinate || [],
        value: "",
        header: headerData,
        data: bodyData,
        deleted: o?.deleted,
      };
      newData.push(newObject);
    });
    setPageData(newData);
  };

  const fetchPageData = async (fData) => {
    setIsLoading(true);
    const res = await dispatch(
      getMassesByPetId({
        pet_id: petId,
        // filterDates: fData?.selectedDates,
      })
    );
    handleResponse(res?.response_data || []);
    setIsLoading(false);
  };

  useEffect(() => {
    if (filterData?.date) {
      fetchPageData(filterData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    let currentDate = moment().format("DD-MM-YYYY");
    let newData = {
      searchText: "",
      selectedDates: [currentDate],
      date: currentDate,
    };
    setFilterData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let displayData = pageData?.filter(
    (o) =>
      optionType === "1" ||
      (optionType === "2" && !o.deleted) ||
      (optionType === "3" && o.deleted)
  );
  displayData = displayData?.map((elm) => {
    return {
      ...elm,
      data: elm?.data?.filter((o) =>
        o.title?.toLowerCase().includes(filterData?.searchText?.toLowerCase())
      ),
    };
  });

  if (displayData?.every((o) => o?.data?.length === 0)) {
    displayData = [];
  }
  return (
    <div id="masses-container">
      {deleteData?.type && (
        <DeletePopup
          handleDelete={handleDelete}
          onHide={() => {
            setDeleteData(null);
          }}
        />
      )}
      {/* {filterData?.date && (
        <FilterComponent
          filterData={filterData}
          setFilterData={setFilterData}
        />
      )} */}
      <div
        style={{
          position: "relative",
          backgroundColor: "#EBECF0",
          width: "100%",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          marginTop: "5px",
        }}
      >
        <div style={{ width: "413px", height: "300px" }}>
          <Model
            selectPoint={handleExpand}
            points={displayData?.map((o) => o.coordinate)}
            isNewPoint={selectingNewPoint}
            setPoint={setPoint}
            species={petDetails?.species?.species_name}
          />
        </div>

        <div
          className="scroll-control"
          style={{
            position: "absolute",
            right: "34px",
            bottom: "74px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <img
            style={{ width: "80px", height: "80px" }}
            src={require("../../../../../../assets/img/vetRecorEMR/modelScroll.png")}
            alt="3d"
          />
          <p
            style={{
              color: "#788C9F",
              maxWidth: "161px",
              textAlign: "center",
              fontFamily: "Inter",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "150%",
            }}
          >
            Scroll to rotate the image on 360 degrees
          </p>
        </div>
      </div>
      <div className="masses-body">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="fa-center gap-3">
            <div className="head-title">Masses</div>
            <FilterOptions
              firstOption="All Locations"
              optionType={optionType}
              onClick={(e) => {
                setOptionType(e);
              }}
            />
          </div>
          {selectingNewPoint ? (
            <div className="new-pin">
              <i
                style={{ height: "20px", width: "20px" }}
                onClick={() => {
                  setSelectingNewPoint(false);
                }}
                className="icomoon-cross-btn"
              ></i>

              <i
                style={{ height: "20px", width: "20px" }}
                onClick={() => {
                  handleAddPoint();
                }}
                className="icomoon-check-btn"
              ></i>
            </div>
          ) : (
            <div
              className="d-flex align-items-center gap-2 pin-span"
              onClick={() => {
                setSelectingNewPoint(true);
              }}
            >
              <span>Pin New Location</span>
              <span className="pin">
                <BsFillPinAngleFill />
              </span>
            </div>
          )}
        </div>
        {isLoading ? (
          <div className="mx-auto">
            <Spinner height={150} />
          </div>
        ) : (
          <>
            {displayData?.length === 0 ? (
              <NodataFoundBlock type={'medical history'}/>
            ) : (
              <Accordion activeKey={expandList} onSelect={handleExpand}>
                {displayData?.map((elm, index) => {
                  const { id, value, header, data, deleted } = elm;
                  return (
                    <Accordion.Item eventKey={index} key={index}>
                      <Accordion.Header>
                        <div className="d-flex fa-center justify-content-between w-100">
                          <div className="location-detail-block">
                            <div className="location-number">{index + 1}</div>
                            <div className="location-title d-none">
                              Location Pin : {`[${value}]`}
                            </div>
                          </div>

                      {!deleted && (
                        <div className="d-flex align-items-center gap-2">

                          {data?.filter(elem => elem?.key === 'mas_document')?.[0]?.data?.filter(i => i?.value !== "")?.length > 0 &&

                          <BsFillImageFill
                            size={20}
                            color="#a9a9a9"
                          />
                          }
                          <div
                            className="h-19 d-flex"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setDeleteData({
                                locationId: id,
                                locationDetailId: "",
                                type: "1",
                              });
                            }}
                          >
                            <img src={archive} alt="archive" />
                          </div>
                          <div
                            className="d-flex"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleAddEditDetails({
                                locationId: id,
                                createdAt: moment().format(
                                  "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                ),
                              });
                            }}
                          >
                            <span className="icomoon-plus-btn" />
                          </div>
                        </div>
                      )}
                    </div>
                  </Accordion.Header>
                  {header.length > 0 && (
                    <Accordion.Body>
                      <div className="head-row">
                        <div>Title</div>
                        {header?.map((dateElem, dateIndex) => {
                          const { date, isValueExist } = dateElem;
                          const cellDate = moment(
                            date,
                            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                          ).format("MM/DD/YYYY");
                          const cellTime = moment(
                            date,
                            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                          ).format("hh:mmA");
                          const isDateEdit =
                            editData?.locationDetailId === dateElem?.id &&
                            editData?.fieldId === "createdAt";
                          return (
                            <div
                              key={dateIndex}
                              className="d-flex gap-2 position-relative"
                            >
                              <span
                                className="cursor-pointer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  if (!deleted) {
                                    setEditData({
                                      locationId: id,
                                      locationDetailId: dateElem?.id,
                                      fieldId: "createdAt",
                                      fieldValue: moment(
                                        date,
                                        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                      ).format("YYYY-MM-DDTHH:mm"),
                                    });
                                  }
                                }}
                              >
                                {cellDate}
                                <br />
                                {cellTime}
                              </span>
                              {isDateEdit && (
                                <DatePopover
                                  defaultValue={date}
                                  value={editData?.fieldValue}
                                  setEditData={setEditData}
                                  onChange={(e) => {
                                    setEditData((prev) => {
                                      return {
                                        ...prev,
                                        fieldValue: e.target.value,
                                      };
                                    });
                                  }}
                                  handleSave={() => {
                                    handleAddEditDetails({
                                      locationId: editData.locationId,
                                      locationDetailId:
                                        editData.locationDetailId,
                                      createdAt: moment(
                                        editData.fieldValue,
                                        "YYYY-MM-DDTHH:mm"
                                      ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                                    });
                                  }}
                                />
                              )}
                              {!isValueExist && !deleted && (
                                <span
                                  className="remove_btn"
                                  onClick={() => {
                                    setDeleteData({
                                      locationId: id,
                                      locationDetailId: dateElem?.id,
                                      type: "2",
                                    });
                                  }}
                                >
                                  -
                                </span>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      {data?.map((rowElem, rowIndex) => {
                        return (
                          <div className="data-row" key={rowIndex}>
                            <div>{rowElem?.title}</div>
                            {rowElem?.data?.map((cellElem, cellIndex) => {
                              const isAnyFieldValue =
                                header[cellIndex]?.isValueExist;
                              //   let isFieldAccess = true;
                              //   if (rowElem?.dependent) {
                              //     isFieldAccess =
                              //       cellElem?.type === rowElem?.dependent;
                              //   }
                              return (
                                <div
                                  key={cellIndex}
                                  onClick={() => {
                                    if (
                                      (editData?.fieldId !== rowElem?.key ||
                                        editData?.locationDetailId !==
                                          cellElem?.locationDetailId) &&
                                      !deleted &&
                                      rowElem?.key !== "mas_document"
                                    ) {
                                      const isDatePicker = [
                                        "placement_date",
                                        "removed_date",
                                      ].includes(rowElem?.key);
                                      setEditData({
                                        locationId: id,
                                        locationDetailId:
                                          cellElem?.locationDetailId,
                                        fieldId: rowElem?.key,
                                        options: rowElem?.options,
                                        fieldValue: isDatePicker
                                          ? cellElem?.value
                                            ? moment(
                                                cellElem?.value,
                                                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                              ).format("YYYY-MM-DDTHH:mm")
                                            : ""
                                          : cellElem?.value,
                                        dependent: rowElem?.dependent,
                                      });
                                    }
                                  }}
                                >
                                  <MassesCell
                                    deleted={deleted}
                                    data={cellElem}
                                    isAnyFieldValue={isAnyFieldValue}
                                    cellTitle={rowElem?.title}
                                    cellKey={rowElem?.key}
                                    editData={editData}
                                    setEditData={setEditData}
                                    setDisplayURL={setDisplayURL}
                                    handleEditDetails={(e) => {
                                      handleAddEditDetails({
                                        locationId: e.locationId || id,
                                        locationDetailId:
                                          e.locationDetailId ||
                                          cellElem?.locationDetailId,
                                        [e.fieldId]: e.fieldValue,
                                      });
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </Accordion.Body>
                  )}
                </Accordion.Item>
              );
            })}
          </Accordion>
        )}
        </>)}
      </div>
    </div>
  );
};

export default Masses;
