import React, { useState } from "react";
import { Button, Input, Collapse } from "reactstrap";
import Accordions from "./Accordions";
import NodataFoundBlock from "../../../../../shared/NoDataFoundBlock";
import { useEffect } from "react";
import LoaderUI from "../../../../../shared/loader";
import { useDispatch, useSelector } from "react-redux";
import {
  getResultsByPetId,
  getAndFilterResults,
  addNewVital,
} from "../../../../../context/actions/patient-module/flowsheets/vitals";
import NewPointPanel from "./NewPointPanel";
import "./index.scss";
import CalendarFilterWeek from "../../../../../shared/Calendar/CalendarFilterWeek";

const Vitals = ({ petId }) => {
  const [loadIndex, setLoadIndex] = useState(1);
  const [elemIndex, setElemIndex] = useState(null);
  const [newPointPanelState, setNewPointPanelState] = useState(false);
  const [calendarData, setCalendarData] = useState({});
  const dispatch = useDispatch();
  const { testResults, loading } = useSelector(
    (state) => state.vitalTestingReducer
  );
  const userDetails = useSelector((state) => state.user.user_details);
  const [toggle, setToggle] = useState(false);
  const toggleFunction = (index) => {
    if (loadIndex === index) {
      setLoadIndex(null);
    } else if (elemIndex === index) {
      setToggle(!toggle);
    } else {
      setToggle(true);
    }
    setElemIndex(index);
    setLoadIndex(null);
  };

  const handleGetResults = () => {
    // let startDate = (new Date(calendarData?.year_value, calendarData?.span_month - 1, calendarData?.span_date)).toLocaleString()
    // let endDate = (new Date(calendarData?.year_value, calendarData?.month_value - 1, calendarData?.date_value + 1)).toLocaleString()
    if (Object.entries(calendarData).length > 0) {
      let startDate = new Date(
        Date.UTC(
          calendarData?.year_value,
          calendarData?.span_month - 1,
          calendarData?.span_date
        )
      );
      let endDate = new Date(
        Date.UTC(
          calendarData?.year_value,
          calendarData?.month_value - 1,
          calendarData?.date_value + 1
        )
      );
      console.log(
        "vital api",
        calendarData,
        "startDate",
        startDate,
        "endDate",
        endDate
      );
      dispatch(
        getAndFilterResults(
          { startDate: startDate, endDate: endDate, pet_id: petId },
          (res) => {}
        )
      );
    }

    /*
		dispatch(getResultsByPetId(petId, (response) => {
			console.log(response);
		}))*/
  };
  const handleAddPoint = (data, callback) => {
    dispatch(
      addNewVital(
        { ...data, pet_id: petId, created_by: userDetails._id },
        callback
      )
    );
  };

  console.log("calendarData --- ", calendarData);
  console.log(
    "new Date(calendarData?.year_value, calendarData?.span_month - 1, calendarData?.span_date)) ---- ",
    new Date(
      calendarData?.year_value,
      calendarData?.span_month - 1,
      calendarData?.span_date
    ).toLocaleString()
  );
  const changeViewData = (data) => {
    setCalendarData(data);
    /*
		let startDate = (new Date(data?.year_value, data?.span_month - 1, data?.span_date)).toLocaleString()
		let endDate = (new Date(data?.year_value, data?.month_value - 1, data?.date_value + 1)).toLocaleString()
		dispatch(getAndFilterResults({ startDate: startDate, endDate: endDate, pet_id: petId }, (res) => {  }))*/
  };

  useEffect(() => {
    handleGetResults();
  }, [calendarData]);

  return (
    <>
      {loading && <LoaderUI />}
      <div className="vitals-block">
        <div className="head section-head d-flex align-items-center">
          <div className="left-col">
            <h3>Vitals</h3>
          </div>
          {/* <div className="right-col ms-auto">
						<Button onClick={() => setNewPointPanelState(true)} color="link" className="add-btn d-inline-flex">
							<span className="icomoon-plus-btn"></span>
						</Button>
					</div> */}
        </div>
        <CalendarFilterWeek calendarViewValue={setCalendarData} />
        {testResults?.length > 0 ? (
          <div className="diagnostic-testing-block">
            <div className="diagnostic-listing-wrapper">
              <table className="table">
                <tbody>
                  <tr>
                    <td colSpan="7" className="p-0">
                      {/* Table View */}
                      <div className="table-view-blc">
                        <Accordions />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <NodataFoundBlock />
        )}
      </div>

      <div
        className={`new-point-panel-overlay position-fixed ${
          newPointPanelState ? "active" : ""
        }`}
      ></div>
      <NewPointPanel
        closeNewPointPanel={() => {
          setNewPointPanelState(false);
        }}
        refresh={handleGetResults}
        addPoint={handleAddPoint}
        className={`${newPointPanelState ? "active" : ""}`}
      />
    </>
  );
};

export default Vitals;
