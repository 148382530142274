import InsuranceComponent from "shared/InsuranceComponent";
import { useSelector } from "react-redux";
import "./PetOwnerPetServices.scss";

const PetOwnerPetServices = () => {

  const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);

  return (
  


  <div id="pet-owner-pet-services-container" style={{}/*{position: 'absolute'}*/}>
    <div className="text-24-700 color-2E65">My Pet Services</div>

    {clinic_settings?.allow_vertical_insurance ? 
      <InsuranceComponent /> : <></>}
  </div>
  
  
  );
};

export default PetOwnerPetServices;
