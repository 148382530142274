import NewPetModal from "shared/NewPetModal";

const initState = {
  loading: false,
  ownerAndPetDetails: null,
  petOwnerAppointments: [],
  petOwnerPetList: [],
  clinicDetails: null,
  appointmentAndDischargePatientDetails: null,
  petDetails: null,
  newPetModal: false,
  canHideNewPetModal: false,
  isAddPet: false
};

export const petOwnerDashboardReducer = (state = initState, action) => {
  switch (action.type) {
    case "PET_OWNER_DASHBOARD_LOADING":
      return { ...state, loading: action.payload };

    case "GET_OWNER_AND_PET_DETAILS":
      return { ...state, ownerAndPetDetails: action.payload };

    case "UPDATE_PET_OWNER_APPOINTMENTS":
      return { ...state, petOwnerAppointments: action.payload };

    case "UPDATE_CLINIC_DETAILS":
      return { ...state, clinicDetails: action.payload };
    case "UPDATE_APPOINTMENT_AND_DISCHARGE_PATIENT_DETAILS":
      return {
        ...state,
        appointmentAndDischargePatientDetails: action.payload,
      };
    case "SET_PET_OWNER_PET_LIST":
      return {
        ...state,
        petOwnerPetList: action.payload,
      };
    case "SET_PET_DETAILS":
      return {
        ...state,
        petDetails: action.payload,
      };
    case "SET_NEW_PET_MODAL":
      return {
        ...state,
        newPetModal: action.payload
      };
    case "SET_CAN_HIDE_NEW_PET_MODAL": 
      return{
        ...state,
        canHideNewPetModal: action.payload
      }
    case "LOGOUT":
      return initState
    default:
      return state;
  }
};
