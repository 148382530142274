import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { FormFeedback, Input, FormGroup, Button } from "reactstrap";
import { checkValidations } from "../../utils/checkValidations";
import { validationMessage } from "../../utils/validation";

const PasswordBox = React.forwardRef(
	(
		{
			showPassword,
			placeholder,
			isRequired = true,
			edit = true,
			max = 100,
			handleVal,
			icon = false,
			iconButton = false,
			isLocked = true,
			className = "",
			label = "",
			id = "id",
			handleKeypress,
			value,
			autoComplete = "off",
		},
		ref
	) => {
		const [password, setPassword] = useState({ value: value, isValid: false });
		const [displayPassword, setDisplayPassword] = useState(false);

		const handleDisplayPassword = (e) => {
			e.preventDefault();
			setDisplayPassword(true);
		}
		const handleHidePassword = (e) => {
			e.preventDefault();
			setDisplayPassword(false);
		}
		const getPassword = (name, value) => {
			const val = value.replace(/\s+/g, " ").trim();
			checkValidations(name, val)
				? setPassword({ value: value, isValid: false })
				: setPassword({ value: value, isValid: true });
			handleVal(value);
		};

		return (
			<>
				{/* <label htmlFor="password">{Name}</label>
      {isRequired && <span style={{ 'color': '#ff0000' }}>  *</span>} */}

				<FormGroup className={`input-wrapper ${className}`}>
					<label htmlFor={id}>
						{label}
					</label>
					<div className={`input-cell position-relative ${icon && 'icon'} ${iconButton && 'iconButton'}`}>
						{
							icon &&
							<span className="icon position-absolute h-100 d-inline-flex align-items-center justify-content-center">
								<i className="icomoon-lock"></i>
							</span>
						}
						<Input
							ref={ref}
							id={id}
							placeholder={placeholder}
							name="password"
							value={password.value}
							type={displayPassword ? "text" : "password"}
							maxLength={max + 1}
							disabled={!edit}
							required
							autoComplete={autoComplete}
							invalid={
								Boolean(isRequired && password && password.isValid) ||
								!isLocked ||
								Boolean(password && password.value && password.value.length > max)
							}
							onKeyDown={(e) => e.keyCode === 13 && handleKeypress(e)}
							onChange={($event) => {
								getPassword(
									"password",
									typeof $event === "string" ? $event : $event.target.value
								);
							}}
						/>
						{
							iconButton &&
							<Button color="link" className="p-0 shadow-none show-pass-btn icon position-absolute h-100 d-inline-flex align-items-center justify-content-center">
								{
									displayPassword ?
										<i className="icomoon-eye-slash" onClick={handleHidePassword}></i> :
										<i className="icomoon-eye" onClick={handleDisplayPassword}></i>
								}

							</Button>
						}
					</div>


					{!password.value ? (
						<FormFeedback
							className={
								(password.isValid && !password.value) || !isLocked
									? "customerror"
									: ""
							}
						>
							{validationMessage["passwordReq"]}
						</FormFeedback>
					) : (
						""
					)}
					{password.value && password.value.length ? (
						<FormFeedback
							className={
								password.isValid && password.value.length ? "customerror" : ""
							}
						>
							{validationMessage["passLength"]}
						</FormFeedback>
					) : (
						""
					)}
					{password && password.value && password.value.length > max ? (
						<FormFeedback
							className={
								password && password.value && password.value.length > max
									? "customerror"
									: ""
							}
						>
							{validationMessage["textMaxInvalid"](max)}
						</FormFeedback>
					) : (
						""
					)}
				</FormGroup>
			</>
		);
	}
);
export default PasswordBox;
