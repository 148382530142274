import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import "./index.scss";
import { appointmentTypes } from "../../../../../utils/constants";

import { getOperatingRoomsByClinicId } from "../../../../../context/actions/operatingRoomAction";

const ColorCodes = ({ setSelectedType, setSelectedColor, setPanelState }) => {
  const dispatch = useDispatch();
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );

  useEffect(() => {
    dispatch(
      getOperatingRoomsByClinicId(clinic_settings.clinic_id, {}, (res) => {})
    );
  }, []);

  const handleSelect = (type, color) => {
    setSelectedType(type);
    setSelectedColor(color);
    setPanelState(true);
  };

  return (
    <>
      <div className="waiting-room-list">
        {appointmentTypes.map((apptType, i) => (
          <div className="item-row d-flex">
            <div className="left-col">
              <div
                className="color-square"
                style={{
                  backgroundColor:
                    clinic_settings?.color_preferences?.[apptType?.value],
                }}
              />
              <div className="room-info">
                <h4>{apptType.label}</h4>
              </div>

              <div className="room-info">
                <h4>
                  {(clinic_settings?.appointment_types_durations?.[
                    apptType?.value
                  ] || 30) + " minutes"}
                </h4>
              </div>
            </div>
            <div className="right-col d-inline-flex align-items-center">
              <Button
                onClick={() =>
                  handleSelect(
                    apptType,
                    clinic_settings?.color_preferences?.[apptType?.value]
                  )
                }
                color="link"
                className="d-inline-flex align-items-center justify-content-center"
              >
                <span className="icomoon-pencil"></span>
              </Button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ColorCodes;
