/*
 * @file: index.js
 * @description: It Contain rest functions for api call .
 * @author: Nk
 */
import axios from "axios";
import querystring from "querystring";
import { setAuthorizationToken } from "../auth";
import { toast } from "react-toastify";
import {auth} from "../firebase";

var store 
export const injectStore = _store => {
  store = _store
}

const getClinicId = (getState) => {
  const { clinicReducer: { clinic_details } } = getState();
  return clinic_details?._id
}


toast.configure();
// var config = {
//   headers: {
//     "Content-Type": "application/json",
//     "Access-Control-Allow-Origin": "*",
//   },
// };

axios.interceptors.request.use(
 async config => {
    const firetoken = await auth?.currentUser?.getIdToken()
    // if(!firetoken){
    //   store.dispatch({type: 'LOGOUT', data: {}})
    // }
    config.headers.common['Authorization'] = firetoken;
    return config;
  }, error => {
    return Promise.reject(error);
  }
)


var config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};

var logoutErrFlag = false;
var langHeaders = (getData) => {
  // dbname:window.location.href.split('/')[3]
  const clinic_id = getClinicId(getData)
 
  return {
    headers: {
      ...config.headers,
      lang: "en",
      referrermodule: window.location.href,
      slug: localStorage.getItem("SLUG") ? localStorage.getItem("SLUG") : "",
      clinic: clinic_id || ''      
    },
  };
};



const logout = async  (error, dispatch, callback) => {
  toast.dismiss();
  if (dispatch && error.response.data && error.response.status === 401) {
    console.log('error logout', error)

    // localStorage.removeItem("USER");
    // localStorage.removeItem("TOKEN");
    // localStorage.clear();
    // localStorage.setItem("LOGOUT", true);
    // dispatch({ type: "LOGOUT", data: true });
    //dispatch({type: 'RESET_APP'})

    // if(error?.response?.data?.message === "Token is expired."){
    //   const refreshedToken = await auth.currentUser.getIdToken();
    //   dispatch({ type: "UPDATE_TOKEN", data: refreshedToken });
    //   localStorage.setItem("TOKEN", JSON.stringify(refreshedToken));
    //   callback(refreshedToken)
    // }
    // else {
      // dispatch({ type: "SET_ACTIVE_MAIN_MENU", data: false });
      // dispatch({ type: "SET_ACTIVE_MENU_TITLE", data: "" });
      // dispatch({type: "USER_DETAILS"})

      // dispatch({ type: "LOGOUT", data: true });
      
    // }
  }
};
class ApiClient {
  static async post (url, params, token = null, dispatch = null, getData) {
    toast.dismiss();
    //if (token) setAuthorizationToken(axios, token);
    //const firetoken = await auth?.currentUser?.getIdToken()


    //setAuthorizationToken(axios, firetoken)
    if (dispatch) dispatch({ type: "SORT", data: {} }); // reset previous sorting when post any new records
    return new Promise((fulfill, reject) => {
      axios
        .post(url, JSON.stringify(params), langHeaders(getData))
        .then(function (response) {
          fulfill(response.data);
        })
        .catch(function (error) {
          if (error && error.response) {
            logout(error, dispatch)
            fulfill(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  }

  static async put(url, params, token = null, dispatch = null, getData) {
   //setAuthorizationToken(axios, token);

    // const firetoken = await auth?.currentUser?.getIdToken()
    // setAuthorizationToken(axios, firetoken)
    toast.dismiss();
    return new Promise(function (fulfill, reject) {
      axios
        .put(url, JSON.stringify(params), langHeaders(getData ))
        .then(function (response) {
          fulfill(response.data);
        })
        .catch(function (error) {
          if (error && error.response && !logoutErrFlag) {
            logout(error, dispatch);
            fulfill(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  }

  static async get(url, params, token = null, dispatch = null, getData) {
    //setAuthorizationToken(axios, token);
    // const firetoken = await auth?.currentUser?.getIdToken()
    // console.log(firetoken)
    // setAuthorizationToken(axios, firetoken)
    let query = querystring.stringify(params);
    url = query ? `${url}?${query}` : url;
    return new Promise(function (fulfill, reject) {
      axios
        .get(url, langHeaders(getData))
        .then(function (response) {
          fulfill(response.data);
        })
        .catch(function (error) {
          if (error && error.response && !logoutErrFlag) {
            logout(error, dispatch);
            fulfill(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  }

  static async fetch(url, params, token = null, dispatch = null, getData) {
    //setAuthorizationToken(axios, token);
    toast.dismiss();
    // console.log(await auth.currentUser, 'fire user')

    // const firetoken = await auth?.currentUser?.getIdToken()
    // setAuthorizationToken(axios, firetoken)
    let query = querystring.stringify(params);
    url = query ? `${url}?${query}` : url;
    return new Promise(function (fulfill, reject) {
      axios
        .get(url, langHeaders(getData))
        .then(function (response) {
          fulfill(response.data);
        })
        .catch(function (error) {
          if (error && error.response && !logoutErrFlag) {
            logout(error, dispatch);
            fulfill(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  }

  static async patch(url, params, token = null, dispatch = null, getData) {
    toast.dismiss();
    // console.log(await auth.currentUser, 'fire user')

    // const firetoken = await auth?.currentUser?.getIdToken()
    // setAuthorizationToken(axios, firetoken)

    return new Promise(function (fulfill, reject) {
      axios
        .patch(url, JSON.stringify(params), langHeaders(getData))
        .then(function (response) {
          // toast.dismiss();
          fulfill(response.data);
        })
        .catch(function (error) {
          if (error && error.response && !logoutErrFlag) {
            logout(error, dispatch);
            fulfill(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  }

  static async delete(url, token = null, dispatch = null, getData) {
    toast.dismiss();
    //setAuthorizationToken(axios, token);
    // console.log(await auth.currentUser, 'fire user')

    // const firetoken = await auth?.currentUser?.getIdToken()
    // setAuthorizationToken(axios, firetoken)
    return new Promise(function (fulfill, reject) {
      axios
        .delete(url, langHeaders(getData))
        .then(function (response) {
          //toast.dismiss();
          fulfill(response.data);
        })
        .catch(function (error) {
          if (error && error.response && !logoutErrFlag) {
            logout(error, dispatch);
            fulfill(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  }
  /*************** Form-Data Method without file for Create ***********/
  static async _postFormData(url, params, token = null, dispatch = null, getData) {
    toast.dismiss();
    // console.log(await auth.currentUser, 'fire user')

    // const firetoken = await auth?.currentUser?.getIdToken()
    // setAuthorizationToken(axios, firetoken)
    //setAuthorizationToken(axios, token);
    if (dispatch) dispatch({ type: "SORT", data: {} }); // reset previous sorting when post any new records
    return new Promise(function (fulfill, reject) {
      axios
        .post(url, params, {
          ...langHeaders(getData)
      })

        .then(function (response) {
          // toast.dismiss();
          fulfill(response.data);
        })
        .catch(function (error) {
          if (error && error.response && !logoutErrFlag) {
            logout(error, dispatch);
            fulfill(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  }

  /*************** Form-Data Method for Update ***********/
  static async _putFormData(url, params, token = null, dispatch = null, getData) {
    toast.dismiss();
    // console.log(await auth.currentUser, 'fire user')

    // const firetoken = await auth?.currentUser?.getIdToken()
    // setAuthorizationToken(axios, firetoken)
    //setAuthorizationToken(axios, token);
    return new Promise(function (fulfill, reject) {
      axios
        .put(url, params, {
          ...langHeaders(getData)    })

        .then(function (response) {
          // toast.dismiss();
          fulfill(response.data);
        })
        .catch(function (error) {
          if (error && error.response && !logoutErrFlag) {
            logout(error, dispatch);
            fulfill(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  }

  /*************** Form-Data post with file Method ***********/
  static async postFormData(url, body, token = null, dispatch = null, getData) {
    toast.dismiss();
    // console.log(await auth.currentUser, 'fire user')

    // const firetoken = await auth?.currentUser?.getIdToken()
    // setAuthorizationToken(axios, firetoken)
    //setAuthorizationToken(axios, token);
    return new Promise((fulfill, reject) => {
      axios
        .post(url, body, {
          ...langHeaders(getData)
        })

        .then((response) => {
          toast.dismiss();
          fulfill(response.data);
        })
        .catch((error) => {
          if (error && error.response && !logoutErrFlag) {
            logout(error, dispatch);
            fulfill(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  }

  /*************** Form-Data update with file Method ***********/
  static async putFormData(url, body, token = null, dispatch = null, getData) {
    toast.dismiss();
    // console.log(await auth.currentUser, 'fire user')

    // const firetoken = await auth?.currentUser?.getIdToken()
    // setAuthorizationToken(axios, firetoken)
    //setAuthorizationToken(axios, token);
    return new Promise((fulfill, reject) => {
      axios
        .put(url, body, {
          ...langHeaders(getData),
        })
        .then((response) => {
          toast.dismiss();
          fulfill(response.data);
        })
        .catch((error) => {
          if (error && error.response && !logoutErrFlag) {
            logout(error, dispatch);
            fulfill(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  }
}

export default ApiClient;
