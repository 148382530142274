import * as Yup from "yup";
import "yup-phone-lite"
/**
 * @description declaration form schema for client validations
 */

export const userSchema = Yup.object().shape({
  
    email: Yup.string().required('Email is required').email('email is not in proper format'),
    firstname: Yup.string().required('First name is required'),
    lastname: Yup.string().required('Last Name is required'),
    title: Yup.string().required('Title is required'),
    phone: Yup.string().when('phone', {
      is: (value) => value?.length > 0,
      then: Yup.string().phone(),
      otherwise: Yup.string().optional()})
    ,
    role: Yup.string().required('Role is required'),
   
}, [['phone', 'phone']]);

/**
 * @description params intilaization 
 */

export const userInit = {
  email: '',
  firstname: '',
  lastname: '',
  title: '',
  phone: '',
  role: '',
}