import send from "../../../assets/img/superAdmin/images/icons/share.png";
import download from "../../../assets/img/superAdmin/images/icons/download.png";
import session from "../../../assets/img/superAdmin/images/icons/session.png";
import logout from "../../../assets/img/superAdmin/images/icons/logout.png";
import squareClose from "../../../assets/img/superAdmin/images/icons/squareClose.png";
import clock from "../../../assets/img/superAdmin/images/icons/clock.png";
import calendar from "../../../assets/img/superAdmin/images/icons/calendar.png";
import leftArrow from "../../../assets/img/superAdmin/images/icons/leftArrow.png";
import rightArrow from "../../../assets/img/superAdmin/images/icons/rightArrow.png";
import close from "../../../assets/img/superAdmin/images/icons/close.png";
import appLogo from "../../../assets/img/superAdmin/images/logo.png";
import dashboardIcon from "../../../assets/img/superAdmin/images/icons/dashboardIcon.svg";
import clinicIcon from "../../../assets/img/superAdmin/images/icons/clinicIcon.svg";
import staffIcon from "../../../assets/img/superAdmin/images/icons/staffIcon.svg";
import checkedBag from "../../../assets/img/superAdmin/images/icons/checkedBag.svg";
import settingIcon from "../../../assets/img/superAdmin/images/icons/setting.svg";
import searchIcon from "../../../assets/img/superAdmin/images/icons/search.svg";
import listIcon from "../../../assets/img/superAdmin/images/icons/list.svg";
import deleteIcon from "../../../assets/img/superAdmin/images/icons/delete.svg";
import dollarIcon from "../../../assets/img/superAdmin/images/icons/dollar.svg";
import lockIcon from "../../../assets/img/superAdmin/images/icons/lock.svg";
import editIcon from "../../../assets/img/superAdmin/images/icons/editPencil.svg";
import audioMuteIcon from "../../../assets/img/icons/audioMute.svg";
import audioUnmuteIcon from "../../../assets/img/icons/audioUnmute.svg";

export const icons = {
  send,
  download,
  session,
  logout,
  squareClose,
  clock,
  calendar,
  leftArrow,
  rightArrow,
  close,
  appLogo,
  dashboardIcon, //for slide bar icons
  clinicIcon,
  staffIcon,
  checkedBag,
  settingIcon,
  searchIcon,
  listIcon,
  deleteIcon,
  lockIcon,
  dollarIcon,
  editIcon,
  audioMuteIcon, 
  audioUnmuteIcon
};
