import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Table, DropdownToggle, ButtonDropdown } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import CheckBox from "../../../../shared/CheckBox";
import UserPlaceholder from "../../../../shared/UserPlaceholder";

import PaginationBlock from "../../../../shared/PaginationBlock";
import { dateFromDateTime, formatPhoneNumber } from "../../../../utils/common";
import NodataFoundBlock from "../../../../shared/NoDataFoundBlock";
import LoaderUI from "../../../../shared/loader";
import { getPetOwnersListV2WithFilter } from "../../../../context/actions/patient-module/pet-owner";

const PetOwnersTable = ({ limit, page, setLimit, setPage, filterParams }) => {
  const history = useHistory();

  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const dispatch = useDispatch();
  const { loading, petOwnerList } = useSelector(
    (state) => state.petOwnerReducer
  );
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  const handleProfileClickOwner = (e, id) => {
    e.preventDefault();
    if (permissions?.view_patient_owner_details) {
      history.push("/pet-owner", {
        owner_id: id,
      });
    }
  };

  const handleGetOwnerList = () => {
    if (clinic_details?._id) {
      dispatch(
        getPetOwnersListV2WithFilter(
          {
            page,
            limit,
            role: "pet-owner",
            clinic_id: clinic_details._id,
            ...filterParams,
          },
          () => {
            // setPage();
          }
        )
      );
    }
  };

  useEffect(() => {
    setPage(1)
    handleGetOwnerList();

  }, [filterParams?.searchText, filterParams?.status, clinic_details])

  useEffect(() => {
    handleGetOwnerList();
  }, [page, limit]);

  if (loading) {
    return <LoaderUI />;
  }

  return (
    <>
      {petOwnerList?.docs?.length > 0 ? (
        <div className="table-listing-blc overflow-auto">
          <Table striped responsive className="checkbox-listing">
            <thead>
              <tr>
                <th>
                  <CheckBox
                    type="checkbox"
                    ischecked={false}
                    className="no-label"
                  />
                </th>
                <th style={{ minWidth: "90px" }}>Owner Name</th>
                <th style={{ minWidth: "8px" }}>Status</th>
                <th style={{ minWidth: "100px" }}>Phone</th>
                <th style={{ minWidth: "100px" }}>Email</th>
                {/* <th style={{ minWidth: '128px' }}>City</th> */}
                {permissions?.edit_patient_owners && (
                  <th style={{ minWidth: "128px", textAlign: "right" }}></th>
                )}
              </tr>
            </thead>
            <tbody>
              {petOwnerList?.docs?.map((ele) => {
                return (
                  <tr>
                    <td>
                      <CheckBox
                        type="checkbox"
                        ischecked={false}
                        className="no-label"
                      />
                    </td>
                    <td>
                      <Button
                        color="link"
                        className="p-0 text-start pic-cell d-inline-flex align-items-center"
                        style={{ fontSize: "14px" }}
                        onClick={(e) => handleProfileClickOwner(e, ele._id)}
                      >
                        <div className="pic d-inline-flex position-relative">
                          {ele?.user_image ? (
                            <img
                              className="w-[20px] h-[20px] mr-[4px] bg-gray-200"
                              src={
                                ele?.user_image ||
                                "/static/media/user-thumbnail.adef342b4bb503aac3bc.png"
                              }
                              alt="owner"
                              style={{ borderRadius: "100px" }}
                            />
                          ) : (
                            <UserPlaceholder />
                          )}
                        </div>
                        <span style={{ color: "#282E65" }}>
                          {ele.firstname + " " + ele.lastname}
                        </span>
                      </Button>
                    </td>
                    <td>
                      <span className="status-cell">
                        {ele.active ? "Active" : "Inactive"}
                      </span>
                    </td>
                    <td>{formatPhoneNumber(ele.phone)}</td>
                    <td>{ele.email}</td>
                    {/* <td>Wlington City</td> */}
                    {permissions?.edit_patient_owners && (
                      <td className="action-cell">
                        <div className="justify-content-end d-flex">
                          <Button color="link" className="edit-btn">
                            <span className="icomoon-pencil"></span>
                          </Button>
                          <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle color="link" className="more-btn">
                              <span className="icomoon-more"></span>
                            </DropdownToggle>
                            {/* <DropdownMenu>
																	<DropdownItem>Item One</DropdownItem>
																	<DropdownItem>Item Two</DropdownItem>
																</DropdownMenu> */}
                          </ButtonDropdown>
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <PaginationBlock
            limit={limit}
            page={petOwnerList.page || 1}
            pagingCounter={petOwnerList?.pagingCounter || 1}
            docLength={petOwnerList?.docs?.length || 0}
            docPage={petOwnerList.page || 1}
            totalPages={petOwnerList.totalPages || 1}
            totalDocs={petOwnerList.totalDocs || 0}
            _handleLimit={(val) => {
              setLimit(val);
            }}
            _handlePageChange={(pageNumber) => {
              setPage(pageNumber);
            }}
          />
        </div>
      ) : (
        <NodataFoundBlock type={petOwnerList?.totalDocs === 0 ? '' : 'search result'}/>
      )}
    </>
  );
};

export default PetOwnersTable;
