const initState = {
    loading: true,
    auditLogs: false,
}

export const auditLogsReducer = (state = initState, action) => {
    switch (action.type) {
        case "GET_AUDIT_LOGS":
            return { ...state, auditLogs: action.payload }

        default:
            return state
    }
}