import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllClinicsByUserId } from "../../../../context/actions/clinic";
import LocationInfoBlock from "../../../../shared/LocationInfoBlock";
import NodataFoundBlock from "../../../../shared/NoDataFoundBlock";
import "./index.scss";

const Locations = () => {
  const tempData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const dispatch = useDispatch();
  const { clinics } = useSelector((state) => state.clinicReducer);
  const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //     handleClinicsInfo()
  // }, [])
  // const handleClinicsInfo = () => {
  //     setLoading(true);
  //     dispatch(getAllClinicsByUserId({}, res => {
  //         setLoading(false);
  //     }))
  // }

  return (
    <>
      {" "}
      {!clinics || clinics?.length <= 0 ? (
        <NodataFoundBlock />
      ) : (
        <div className="locations-wrapper d-flex">
          <div className="left-col">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3477.41340168017!2d-98.44542878587703!3d29.35818248213723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x865cf7a1d4f44207%3A0xef97c74ff164b46f!2sModel%20Dr%2C%20San%20Antonio%2C%20TX%2078223%2C%20USA!5e0!3m2!1sen!2sin!4v1669019469407!5m2!1sen!2sin"
              width={600}
              height={450}
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="address-map"
            />
          </div>
          <div className="right-col flex-fill">
            {Array.isArray(clinics) &&
              (clinics || []).map((item, i) => {
                return (
                  <div key={i} className="row-block">
                    <LocationInfoBlock
                      data={{
                        title: item.clinic_name,
                        email: item.email,
                        phone_1: item.phone,
                        phone_2: item.alternate_phone,
                        address: item.address,
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};

export default Locations;
