const initialState = {
	isPatientListPanel: false
}


export const patientListPanelReducer = (state = initialState, action) => {
	switch (action.type) {

		case "SET_PATIENTLIST_PANEL": {
			return {
				...state, isPatientListPanel: action.data
			}
		}

		default:
			return state;

	}
};