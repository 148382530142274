import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CheckBox from "../../../shared/CheckBox";
import "../index.scss";
import { ReactComponent as PlusIconSvg } from "../../../assets/img/pencil-edit-button-svgrepo-com.svg";

export default function OrderInfo({ formData, setFormData }) {
  const [data, setData] = useState({
    bundle_name: "",
    description: "",
    vettech_scanout: false,
    doctor_scanout: false,
    tags: [],
  });

  useEffect(() => {
    if (formData) {
      setData({
        bundle_name:
          formData && formData.bundle_name ? formData.bundle_name : "",
        description:
          formData && formData.description ? formData.description : "",
        vettech_scanout:
          formData && formData.vettech_scanout ? formData.vettech_scanout : "",
        doctor_scanout:
          formData && formData.doctor_scanout ? formData.doctor_scanout : "",
        tags: formData && formData.tags ? formData.tags : [],
      });
    }
  }, [formData]);

  // const { inventoryCategories } = useSelector(
  //   (state) => state.appContentReducer
  // );

  // const dispatch = useDispatch();

  const handleChange = (field, value) => {
    setData((prevData) => {
      const newData = { ...prevData, [field]: value };
      setFormData(newData);
      return newData;
    });
  };

  return (
    <>
      <h5 className="text-lg font-semibold uppercase text-[#282E65]">
        General Information
      </h5>
      <ul className="space-y-1">
        <li className="flex flex-col md:flex-row gap-1">
          <label
            htmlFor="bundle_name"
            className="text-sm md:text-base w-full md:w-44"
          >
            Name
          </label>
          <input
            id="bundle_name"
            type="text"
            placeholder="Add Name"
            value={data.bundle_name}
            onChange={(e) => handleChange("bundle_name", e.target.value)}
            className="w-full md:w-56 cursor-pointer bg-gray-100 active:bg-gray-100 focus:bg-gray-200 rounded-md focus:px-2.5 md:px-2.5 py-1.5"
          />
        </li>

        {/* <li className="flex flex-col md:flex-row gap-1">
          <label
            htmlFor="service_category"
            className="text-sm md:text-base w-full md:w-44"
          >
            Category
          </label>
          <select
            id="service_category"
            className="w-full md:w-56 cursor-pointer bg-gray-100 active:bg-gray-100 focus:bg-gray-200 rounded-md focus:px-2.5 md:px-2.5 py-1.5"
            value={data?.category_id}
            onChange={(e) => handleChange("category_id", e.target.value)}
          >
            <option value={""} selected className="font-['Inter']">
              Select a category
            </option>
            {(inventoryCategories || []).map((item, i) => (
              <option
                key={i}
                value={item._id}
                style={{ textTransform: "capitalize" }}
              >
                {item?.category}
              </option>
            ))}
          </select>
        </li> */}

        <li className="flex flex-col md:flex-row gap-1">
          <label
            htmlFor="description"
            className="text-sm md:text-base w-full md:w-44"
          >
            Description
          </label>
          <input
            id="description"
            type="text"
            placeholder="Add Description"
            value={data.description}
            onChange={(e) => handleChange("description", e.target.value)}
            className="w-full md:w-56 cursor-pointer bg-gray-100 active:bg-gray-100 focus:bg-gray-200 rounded-md focus:px-2.5 md:px-2.5 py-1.5"
          />
        </li>

        <li className="flex flex-col md:flex-row gap-1">
          <label
            htmlFor="Required For Scan Out"
            className="text-sm md:text-base w-full md:w-44"
          >
            Required For Scan Out
          </label>
          <div style={{ display: "flex" }}>
            <div style={{ marginLeft: "10px" }}>
              <CheckBox
                type="checkbox"
                ischecked={data.vettech_scanout}
                label={"Vet Tech"}
                checked={data.vettech_scanout}
                className=""
                name="vet_tech"
                onChange={(e) =>
                  handleChange("vettech_scanout", !data.vettech_scanout)
                }
              />
            </div>
            <div style={{ marginLeft: "10px" }}>
              <CheckBox
                type="checkbox"
                ischecked={data.doctor_scanout}
                label={"Doctor"}
                checked={data.doctor_scanout}
                className=""
                name="doctor"
                onChange={(e) =>
                  handleChange("doctor_scanout", !data.doctor_scanout)
                }
              />
            </div>
          </div>
        </li>

        {/*
        <li className="flex flex-col md:flex-row gap-1">
          <label
            htmlFor="Required For Scan Out"
            className="text-sm md:text-base w-full md:w-44"
          >
            Tags
          </label>
          <div className="item-rw d-flex">
            <div className="desc-cell flex-fill d-flex align-items-center">
              <div className="tag-input-wrapper">
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  name="tag"
                  value={tag}
                  onChange={(e) => {
                    setTag(e.target.value);
                  }}
                />
             
                <button
                  type="button"
                  onClick={() => {
                    if (tag != "") {
                      setData({
                        ...data,
                        tags: [...data.tags, tag],
                      });
                      setFormData({
                        ...data,
                        tags: [...data.tags, tag],
                      });

                      setTag("");
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 448 512"
                  >
                    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                  </svg>
                </button>
              </div>
              <div className="tags ms-3 d-flex align-items-center flex-wrap">
                {data?.tags?.map((item, i) => (
                  <div className="position-relative mb-2">
                    <span
                      className="bg-gray-300 py-1 px-2 me-2 rounded-2"
                      key={i}
                    >
                      {item}
                    </span>
                    <span
                      className="position-absolute cross_svg_img"
                      onClick={() => {
                        let filtered = [...data.tags].filter(
                          (ele, idx) => idx != i
                        );

                        setData({
                          ...data,
                          tags: filtered,
                        });

                        setFormData({
                          ...data,
                          tags: filtered,
                        });
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                      >
                        <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" />
                      </svg>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </li>*/}

        {/* <li className="flex flex-col md:flex-row gap-1">
          <label
            htmlFor="service_price"
            className="text-sm md:text-base w-full md:w-44"
          >
            Price ($)
          </label>
          <input
            id="service_price"
            type="text"
            placeholder="Enter Price"
            value={data.service_price}
            onChange={(e) => handleChange("service_price", e.target.value)}
            className="w-full md:w-56 bg-gray-100 cursor-pointer focus:bg-gray-200 rounded-md focus:px-2.5 md:px-2.5 py-1.5"
          />
        </li>
        <li className="flex flex-col md:flex-row gap-1">
          <label
            htmlFor="service_tax"
            className="text-sm md:text-base w-full md:w-44"
          >
            Tax (%)
          </label>
          <input
            id="service_tax"
            type="text"
            placeholder="Enter tax percentage"
            value={data.service_tax}
            onChange={(e) => handleChange("service_tax", e.target.value)}
            className="w-full md:w-56 bg-gray-100 cursor-pointer focus:bg-gray-200 rounded-md focus:px-2.5 md:px-2.5 py-1.5"
          />
        </li>
        <li className="flex flex-col md:flex-row gap-1">
          <label
            htmlFor="doctor_commission"
            className="text-sm md:text-base w-full md:w-44"
          >
            Doctor's Commission (%)
          </label>
          <input
            id="doctor_commission"
            type="text"
            placeholder="Enter Doctor's Comission"
            value={data.doctor_commission}
            onChange={(e) => handleChange("doctor_commission", e.target.value)}
            className="w-full md:w-56 bg-gray-100 cursor-pointer focus:bg-gray-200 rounded-md focus:px-2.5 md:px-2.5 py-1.5"
          />
        </li> */}
      </ul>
    </>
  );
}
