import React, { useState } from "react";
import { sidebarPanelAction } from "../../context/actions/sidebarPanelAction";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "reactstrap";
import { logout } from "../../context/actions/user";
import AppLogo2 from "../AppLogo2";
import LoaderUI from "../loader";
import UserProfileButton from "./UserProfileButton";
import { Dropdown } from "react-bootstrap";
import { FaAngleDown, FaClinicMedical } from "react-icons/fa";
import { getClinicPermission } from "context/actions/clinicSettings";

const Sidebar = ({ className = "" }) => {
  const userDetails = useSelector((state) => state.user.user_details);
  const { clinic_details, clinicList } = useSelector(
    (state) => state.clinicReducer
  );
  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [loader, showLoader] = useState(false);
  const sidebarPanelState = useSelector(
    (state) => state.sidebarPanelReducer.isSidePanel
  );

  const handleLogout = () => {
    showLoader(true);
    dispatch(
      logout(() => {
        showLoader(false);
        history.push("/login");
      })
    );
  };

  const closeSidePanel = () => {
    dispatch(sidebarPanelAction(false));
  };

  const handleSelectClinic = (data) => {
    dispatch({ type: "GET_CLINIC", payload: data });
    dispatch({
      type: "SET_PRIMARY_CLINIC",
      payload: data?._id || "",
    });
    dispatch(getClinicPermission(userDetails?.role,  ()=>{}))
    history.push("/appointments");
  };

  let selectedClinic =
    clinicList?.find((o) => o?._id === clinic_details?._id) || {};
  const { clinic_name, clinicLogo } = selectedClinic;
  return (
    <>
      <div
        className={`sidebar-overlayer ${sidebarPanelState ? "active" : ""}`}
        onClick={closeSidePanel}
      ></div>
      {loader && <LoaderUI overlay />}

      <aside
        className={`sidebar d-flex flex-column overflow-auto ${className}
				${sidebarPanelState ? "active" : ""}`}
      >
        <AppLogo2 />
        <div className="inner-wrapper">
          {clinicList?.length > 1 && (
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                className="w-100 bg-f8f8 b-f8f8 mb-2 cps-16"
                style={{ textTransform: "unset" }}
              >
                <div className="fb-center">
                  <div className="fa-center gap-2">
                    {clinicLogo ? (
                      <div className="bg-ffff h-22 w-22 br-4 f-center">
                        <img
                          src={clinicLogo}
                          alt="logo"
                          className="fit-image"
                        />
                      </div>
                    ) : (
                      <div className="bg-8693 h-22 w-22 br-4 f-center">
                        <FaClinicMedical color="#f8f8f8" size={15} />
                      </div>
                    )}
                    <div
                      className="text-15-400 color-2E65 clinic-side-name"
                      style={{
                        width: "130px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "start",
                      }}
                    >
                      {clinic_name}
                    </div>
                  </div>
                  <div>
                    <FaAngleDown color="#3d3efd" size={16} />
                  </div>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="w-100 pt-0 pb-0 shadow border-0">
                {clinicList?.map((elm, index) => {
                  return (
                    <Dropdown.Item
                      href="#"
                      key={index}
                      onClick={(e) => {
                        e.preventDefault();
                        if (elm?._id !== selectedClinic?._id) {
                          handleSelectClinic(elm);
                        }
                      }}
                    >
                      <div className="fa-center gap-2 pt-1 pb-1">
                        {elm?.clinicLogo ? (
                          <div className="bg-ffff h-22 w-22 br-4 f-center">
                            <img
                              src={elm?.clinicLogo}
                              alt="logo"
                              className="fit-image"
                            />
                          </div>
                        ) : (
                          <div className="bg-8693 h-22 w-22 br-4 f-center">
                            <FaClinicMedical color="#f8f8f8" size={15} />
                          </div>
                        )}
                        <div
                          className="text-14-400 color-2E65"
                          style={{
                            width: "80%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {elm?.clinic_name}
                        </div>
                      </div>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          )}
          <div className="sidebar-menu">
            <ul className="list-unstyled">
              {/*
              <li
                className={location.pathname === "/dashboard" ? "active" : ""}
              >
                <Button
                  onClick={() => {
                    history.push("/dashboard");
                    dispatch(sidebarPanelAction(false));
                  }}
                  color="link"
                  className="d-flex align-items-center text-start w-100"
                >
                  {/* <span className="icomoon-date-check"></span> 
                  <span className="material-icons">dashboard</span>
                  <label>Dashboard</label>
                </Button>
              </li>*/}
              {permissionsData?.view_appointments && (
                <li
                  className={
                    location.pathname.includes("appointment") ||
                    location.pathname === "/"
                      ? "active"
                      : ""
                  }
                >
                  <Button
                    onClick={() => {
                      history.push("/appointments");
                    }}
                    color="link"
                    className="d-flex align-items-center text-start w-100"
                  >
                    <span className="material-icons">event_available</span>

                    <label>Appointments</label>
                  </Button>
                </li>
              )}
              {(permissionsData?.view_patients ||
                permissionsData?.view_patient_owners) && (
                <li
                  className={
                    location.pathname === "/patients" ||
                    location?.pathname.includes("patient-detail")
                      ? "active"
                      : ""
                  }
                >
                  <Button
                    onClick={() => {
                      history.push("/patients");
                    }}
                    color="link"
                    className="d-flex align-items-center text-start w-100"
                  >
                    <span className="icomoon-pet-foot"></span>
                    <label>Patients</label>
                  </Button>
                </li>
              )}
              {permissionsData?.view_operating_rooms && (
                <li
                  className={
                    location.pathname === "/operating-room" ? "active" : ""
                  }
                >
                  <Button
                    color="link"
                    className="d-flex align-items-center text-start w-100"
                    onClick={() => {
                      history.push("/operating-room");
                    }}
                  >
                    <span className="icomoon-room"></span>
                    <label>Operating Room</label>
                  </Button>
                </li>
              )}
              {permissionsData?.view_boarding && (
                <li
                  className={location.pathname === "/boarding" ? "active" : ""}
                >
                  <Button
                    onClick={() => {
                      history.push("/boarding");
                    }}
                    color="link"
                    className="d-flex align-items-center text-start w-100"
                  >
                    <span className="icomoon-boarding"></span>
                    <label>Boarding</label>
                  </Button>
                </li>
              )}
              {permissionsData?.view_referral_management && (
                <li
                  className={
                    location.pathname === "/referral-management" ? "active" : ""
                  }
                >
                  <Button
                    onClick={() => {
                      history.push("/referral-management");
                    }}
                    color="link"
                    className="d-flex align-items-center text-start w-100"
                  >
                    <span className="icomoon-share"></span>

                    <label>Referral Mgmt.</label>
                  </Button>
                </li>
              )}
              {permissionsData?.view_reports && (
                <li
                  className={location.pathname === "/reports" ? "active" : ""}
                >
                  <Button
                    onClick={() => {
                      history.push("/reports");
                    }}
                    color="link"
                    className="d-flex align-items-center text-start w-100"
                  >
                    <span className="icomoon-report"></span>
                    <label>Reports</label>
                  </Button>
                </li>
              )}
              {permissionsData?.view_messages && (
                <li
                  className={
                    location.pathname === "/message-center" ? "active" : ""
                  }
                >
                  <Button
                    color="link"
                    onClick={() => {
                      history.push("/message-center");
                    }}
                    className="d-flex align-items-center text-start w-100"
                  >
                    <span className="icomoon-message"></span>
                    <label>Messages</label>
                  </Button>
                </li>
              )}
              {permissionsData?.view_invoices && (
                <li
                  className={location.pathname === "/invoices" ? "active" : ""}
                >
                  <Button
                    color="link"
                    onClick={() => {
                      history.push("/invoices");
                    }}
                    className="d-flex align-items-center text-start w-100"
                  >
                    <span className="icomoon-billing"></span>
                    <label>Invoices</label>
                  </Button>
                </li>
              )}
            </ul>
          </div>
          <div className="sidebar-menu">
            <h2>Admin Settings</h2>
            <ul className="list-unstyled">
              {permissionsData?.view_staff_management && (
                <li
                  className={`${
                    location.pathname === "/staff-management" ||
                    location.pathname?.includes(
                      "/staff-management/staff-details/"
                    )
                      ? "active"
                      : ""
                  }`}
                >
                  <Button
                    color="link"
                    className="d-flex align-items-center text-start w-100 staff-icon"
                    onClick={() => {
                      history.push("/staff-management");
                      dispatch(sidebarPanelAction(false));
                    }}
                  >
                    <span className="icomoon-user-multiple"></span>
                    <label>Staff Management</label>
                  </Button>
                  <Button
                    color="link"
                    className="d-flex align-items-center text-start w-100 staff-icon-mini"
                    onClick={() => {
                      history.push("/staff-management");
                      dispatch(sidebarPanelAction(false));
                    }}
                  >
                     <span className="icomoon-user-multiple"></span>
                  </Button>
                </li>
              )}
              {/*permissionsData?.view_wellness_plans && (
                <li
                  className={
                    location.pathname === "/wellness-plans" ? "active" : ""
                  }
                >
                  <Button
                    color="link"
                    className="d-flex align-items-center text-start w-100"
                    onClick={() => {
                      history.push("/wellness-plans");
                      dispatch(sidebarPanelAction(false));
                    }}
                  >
                    <span className="icomoon-leaf"></span>
                    <label>Wellness Plans</label>
                  </Button>
                </li>
                  )*/}
              {permissionsData?.view_inventory && (
                <li
                  className={
                    location.pathname.includes("inventory") ? "active" : ""
                  }
                >
                  <Button
                    onClick={() => {
                      history.push("/inventory");
                      dispatch(sidebarPanelAction(false));
                    }}
                    color="link"
                    className="d-flex align-items-center text-start w-100"
                  >
                    <span className="icomoon-inventory"></span>
                    <label>Inventory</label>
                  </Button>
                </li>
              )}
              {permissionsData?.use_clinic_settings && (
                <li
                  className={location.pathname === "/settings" ? "active" : ""}
                >
                  <Button
                    onClick={() => {
                      history.push("/settings");
                      dispatch(sidebarPanelAction(false));
                    }}
                    color="link"
                    className="d-flex align-items-center text-start w-100"
                  >
                    <span className="icomoon-setting"></span>
                    <label>Clinic Settings</label>
                  </Button>
                </li>
              )}
              <li>
                <Button
                  color="link"
                  className="d-flex align-items-center text-start w-100"
                  onClick={handleLogout}
                >
                  <span className="logout-icon">
                    <FontAwesomeIcon icon={faSignOut} />
                  </span>
                  <label>Logout</label>
                </Button>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-blc mt-auto">
          <UserProfileButton
            userName={`${userDetails?.firstname} ${userDetails?.lastname}`}
            userImage={userDetails.user_image}
          />
          <div className="copyright-txt text-center">
            Copyright &copy; 2024 <span>| Privacy Policy</span>
          </div>
        </div>
      </aside>
    </>
  );
};
export default Sidebar;
