import * as Yup from "yup";
/**
 * @description declaration form schema for client validations
 */
export const loginSchema = Yup.object().shape({
    password: Yup.string(),
    
    username: Yup
        .string(),

    ClinicID: Yup.string(),
       
    frequency_value: Yup
        .number(),
    frequency_unit: Yup
        .string(),
    partial_results: Yup.boolean()
        
});

