import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Popover,
  PopoverBody,
  Input,
  Form,
  ModalBody,
  Modal,
} from "reactstrap";
import {
  addBehaviourTag,
  removeBehaviourTag,
} from "../../../context/actions/patient-module/patient/pet";
import { getPetDetailsById } from "../../../context/actions/patient-module/patient/pet";
import { CiCircleMinus } from "react-icons/ci";
import "./index.scss";
import { BiMinus } from "react-icons/bi";
import { useFormik } from "formik";

const ProfileTagsEdit = ({ showRemoveIcon }) => {
  const [popover, setPopover] = useState(false);
  const [tagValue, setTagValue] = useState(false);
  const [severityValue, setSeverityValue] = useState(false);

  const dispatch = useDispatch();

  const popoverToggle = () => {
    setPopover(!popover);
  };

  const { petDetails } = useSelector((state) => state.petReducer);

  const formik = useFormik({
    initialValues: {
      tag: "",
      servive: "",
    },
    onSubmit: (values, { resetForm }) => {
      dispatch(
        addBehaviourTag(
          {
            pet_id: petDetails._id,
            tag: values.tag || "",
            severity: values.servive,
          },
          (response) => {
            if (response?.status) {
              dispatch(getPetDetailsById(petDetails._id, {}, () => {}));
            }
          }
        )
      );
      setPopover(false);
      resetForm();
    },
  });

  // const handleAddTag = (e) => {
  //   e.preventDefault();
  // };

  const handleAddTag = () => {
    dispatch(
      addBehaviourTag(
        {
          pet_id: petDetails._id,
          tag: tagValue || "",
          severity: severityValue,
        },
        (response) => {
          if (response?.status) {
            dispatch(
              getPetDetailsById(petDetails._id, {}, () => {
                popoverToggle();
              })
            );
          }
        }
      )
    );
  };

  const handleRemoveTag = (item) => {
    dispatch(
      removeBehaviourTag(
        {
          pet_id: petDetails._id,
          tag: item.tag || "",
          severity: item.severity,
          notes: "",
        },
        (response) => {
          if (response?.status) {
            dispatch(getPetDetailsById(petDetails._id, {}, () => {}));
          }
        }
      )
    ); // Dispatch an action to remove the behavior tag by its unique ID
    // dispatch(
    //   getPetDetailsById(petDetails._id, {}, () => {
    //     // popoverToggle();
    //   })
    // );
  };
  let severityToColor = {
    aggressive: "orange",
    assertive: "yellow",
    neutral: "cyan",
    passive: "green",
  };
  console.log(formik.values);
  let colors = ["orange", "yellow", "cyan", "green"];
  return (
    <>
      <div className="profile-tags row-block mt-2">
        <div className="head section-head d-flex align-items-center mb-2">
          <div className="left-col">Behaviour Tags</div>
          <div className="right-col ms-auto">
            {/* {popover ? (
              <Button
                type="button"
                id="edit-behaviour-tag-main"
                onClick={() => setPopover(false)}
                color={"link"}
                className="close-btn  d-inline-flex"
                style={{ fontSize: "19px", padding: "10px" }}
              >
                <span className="icomoon-cross-btn"></span>
              </Button>
            ) : (
              <Button
                type="button"
                id="edit-behaviour-tag-main"
                onClick={() => setPopover(true)}
                color="link"
                className="add-btn d-inline-flex"
              >
                <span className="icomoon-plus-btn"></span>
              </Button>
            )} */}
            {/* <Popover
              placement="bottom-end"
              isOpen={popover}
              toggle={popoverToggle}
              target="edit-behaviour-tag-main"
            >
              <PopoverBody className={"new-tag"}>
                <Input
                  placeholder="Add Behaviour Tag"
                  onChange={(event) => {
                    setTagValue(event?.target?.value);
                  }}
                />
                <Input
                  type="select"
                  placeholder="Select Severity"
                  onChange={(event) => {
                    setSeverityValue(event?.target?.value);
                  }}
                >
                  <option>Select Severity</option>
                  <option>aggressive</option>
                  <option>assertive</option>
                  <option>neutral</option>
                  <option>passive</option>
                </Input>
                <div className="btn-cell d-flex">
                  <Button
                    color="primary"
                    onClick={handleAddTag}
                    className="flex-fill"
                  >
                    Add Tag
                  </Button>
                </div>
              </PopoverBody>
            </Popover> */}
            {/* <Modal
              placement="bottom-end"
              isOpen={popover}
              toggle={() => setPopover(false)}
              target="behaviour-tag"
            >
              <ModalBody className={"new-tag"}>
                <Input
                  placeholder="Add Behaviour Tag"
                  name="tag"
                  value={formik.values.tag}
                  onChange={formik.handleChange}
                />
                <Form onSubmit={formik.handleSubmit}>
                  <Input
                    type="select"
                    placeholder="Select Severity"
                    onChange={formik.handleChange}
                    value={formik.values.servive}
                    name="servive"
                  >
                    <option value="">Select Severity</option>
                    <option value="aggressive">aggressive</option>
                    <option value="assertive">assertive</option>
                    <option value="neutral">neutral</option>
                    <option value="passive">passive</option>
                  </Input>
                  <div className="btn-cell d-flex">
                    <Button type="submit" color="primary" className="flex-fill">
                      Add Tag
                    </Button>
                  </div>
                </Form>
              </ModalBody>
            </Modal> */}
          </div>
        </div>
        <div className="inner">
          <div className="tag-list flex-wrap d-flex gap-2 justify-content-start">
            {(petDetails?.petCareInfo?.behaviour_tags || []).map(
              (item, index) => {
                let colorCode = petDetails?.petCareInfo?.behaviour_tags?.find(
                  (o) => o?._id === item.severity
                )?.bseverity;
                return (
                  <div className="b-tags d-flex align-items-center mb-2">
                    <span
                      key={index}
                      // className={`d-inline-flex mb-0 ${
                      //   severityToColor[item?.severity]
                      // }`}
                      className={`text-center ps-1 pe-1 mb-0 behaviour-tag-font ${
                        colorCode === "Low"
                          ? "bg-teal-500 text-white rounded-sm"
                          : colorCode === "Moderate"
                          ? "bg-yellow-500 text-white rounded-sm"
                          : colorCode === "High"
                          ? "bg-orange-500 text-white rounded-sm"
                          : ""
                      }`}
                    >
                      {/* {item?.tag && item?.tag !== ""
                        ? item?.tag
                        : item?.severity} */}
                      {item?.btag}
                    </span>
                    {showRemoveIcon && (
                      <CiCircleMinus
                        color="red"
                        size="22px"
                        onClick={() => handleRemoveTag(item)}
                      />
                    )}
                    {/* {showRemoveIcon && (
                        <button
                          type="button"
                          color="link"
                          className="bg-green-400 rounded-full text-white justify-content-center me-2"
                          onClick={() => handleRemoveTag(item)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-dash-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                          </svg>
                        </button>
                      )} */}
                  </div>
                );
              }
            )}
            {/* <span className="d-inline-flex yellow">[tagname]</span>
						<span className="d-inline-flex cyan">Chase-proneness</span> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileTagsEdit;
