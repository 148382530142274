import React, { useState } from "react";
// import { RiCalendar2Line, RiTimerLine } from "react-icons/ri";

import { dobInYearMonthDays } from "../../utils/common";
import CustomSelect from "../../shared/SelectBox";
import { useSelector, useDispatch } from "react-redux";
import { getAvailableKennels } from "../../context/actions/kennels";
import moment from "moment";
import { useEffect } from "react";
import { Button, Input } from "reactstrap";
import { toast } from "react-toastify";
import { faUnderline } from "@fortawesome/free-solid-svg-icons";
import { getPetDetailsById } from "../../context/actions/patient-module/patient/pet";
import { updateBoarding } from "context/actions/boarding";
import { useClinicBreed } from "hooks/useClinicBreed";

const KennelInfoViewUpdate = ({ data, setShow, update}) => {
  const userDetails = useSelector(state => state.user.user_details);
  const { clinic_details} = useSelector(state => state.clinicReducer);
  const [isEdit, setEdit] = useState(false)
  const [patient, setPatient] = useState({})

  const dispatch = useDispatch()

  const clinicBreed = useClinicBreed()

  const allKennels = useSelector((state) => state?.kennelReducer?.kennels);
  const [date, setDate] = useState(moment(data?.boarding_start_date).format("YYYY-MM-DD"));
  const [end_date, setEndDate] = useState(moment(data?.boarding_end_date).format("YYYY-MM-DD")); 
  const [startTime, setStartTime] = useState(moment(data?.boarding_start_date).format("HH:mm"));
  const [endTime, setEndTime] = useState(moment(data?.boarding_end_date).format("HH:mm"));
  const [kennel, setKennel] = useState(data?.kennel?._id);
  const [boardingPurpose, setBoardingPurpose] = useState(data?.boarding_purpose);
  const [notes, setNotes] = useState(data?.notes || '');

  // const kennels = ["kennel 1", "kennel 2", "kennel 3", "kennel 4", "kennel 5", "kennel 6", "kennel 7", "kennel 8", "kennel 9", "kennel 10"];
  const boardingPurposeArr = ["Hospitalization", "ICU", "PACU", "Observation", "Procedure"]


  useEffect((() => {
    if(date !== undefined && startTime !== undefined && end_date !== undefined && endTime !== undefined ){
      console.log(date + ' ' + startTime, end_date + ' ' + endTime )
      dispatch(getAvailableKennels({start_date: moment(date + ' ' + startTime, 'YYYY-MM-DD HH:mm').toISOString(), end_date: moment(end_date + ' ' + endTime, 'YYYY-MM-DD HH:mm').toISOString(), clinic_id: clinic_details?._id}, () => {} ))
    }
    }), [date, startTime, endTime, end_date])

  useEffect((() => {
    dispatch(getPetDetailsById(data?.patient_id, {},  (res) => {setPatient(res?.response_data)}))
  }), [] )


  const resetData = () => {

    setDate(moment(data?.boarding_start_date).format("YYYY-MM-DD"));
    setEndDate(moment(data?.boarding_end_date).format("YYYY-MM-DD")); 
    setStartTime(moment(data?.boarding_start_date).format("HH:mm"));
    setEndTime(moment(data?.boarding_end_date).format("HH:mm"));
    setKennel(data?.kennel?._id);
    setBoardingPurpose(data?.boarding_purpose);
    setNotes(data?.notes || '');

  }

  const assignKennelToPatient = () => {
    // console.log("patient info", patient,date,startTime,endTime,kennel,boardingPurpose,notes)
    const { added_by, _id, pet_name, pet_image, speciesData, gender } = patient
    const species_name = speciesData?.species_name
    const kennel_info = {
      patient_id: _id,
      boarding_start_time: startTime,
      boarding_end_time: endTime,
      boarding_date: date,
      boarding_end_date: end_date,

      kennel: kennel,
      notes: notes,
      boarding_purpose: boardingPurpose,
      gender: gender,
      pet_image: pet_image,
      pet_name: pet_name,
      species_name: species_name
    }
    let postedobj = {
      boarding_start_date: moment(date + ' ' + startTime, 'YYYY-MM-DD HH:mm').toDate(),
      boarding_end_date: moment(end_date + ' ' + endTime, 'YYYY-MM-DD HH:mm').toDate(),
      kennel: kennel,
      notes: notes,
      boarding_purpose: boardingPurpose,
      gender: gender,
      pet_image: pet_image,
      pet_name: pet_name,
      species_name: species_name
    }
    // openPatientPanel()

    dispatch(updateBoarding(data?._id, postedobj,(data) => {
      toast.success(data.message)
      update();
      setEdit(false)
    } ))

  }

  const { permissionsData } = useSelector(
		(state) => state.clinicPermissionReducer
	  );
	const permissions = permissionsData;
  return (
    <div>
      <h2 className="text-3xl font-bold">Kennel Boarding</h2>
      <div className="mt-4 space-y-2">
        <div className="flex items-center justify-between">
          <p className="text-lg font-medium">Patient Information</p>
          { !isEdit &&
          <Button onClick={() => setEdit(true)}
                                color="link"
                                className="edit-btn"
                               
                              >
                              <span style={{color: 'grey'}} className="icomoon-pencil"></span>
            </Button>
        }
        </div>
        <div className="bg-gray-50 rounded-xl p-2 flex items-center gap-x-2">
          <div>
            <img
              style={{width: '50px', height: '50px'}}
              src={patient?.pet_image}
              alt="patient"
              className="bg-gray-100 rounded-lg object-cover"
            />
          </div>
          <div className="space-y-2.5">
            <h4 className="text-lg capitalize font-medium">
              {patient?.pet_name}{" "}
              <span className="ml-1 text-sm">(VRN {patient?.vrn})</span>
            </h4>
            <p className="text-sm capitalize">
              {dobInYearMonthDays(new Date(patient?.dob) || new Date)} (DOB:{" "}
              {moment(patient?.dob).format('MM/DD/YYYY')}) / {patient?.gender}{" "}
              / {useClinicBreed(patient?.breed?._id) || patient?.breed?.breed} /{" "}
              {patient?.color ?? "No color"}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-2 space-y-2">
        <p className="text-lg font-medium">Kennel Boarding Duration</p>
        <div className="flex items-center gap-x-2 w-full">
          <div className="w-1/2">
            <label htmlFor="date" className="text-xs text-gray-500">
              Start Date
            </label>

            <input
              disabled={!isEdit}
              id="date"
              name="date"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="w-full rounded-xl border overflow-hidden h-12 px-2.5"
            />
          </div>
          <div className="w-1/2">
            <label htmlFor="startTime" className="text-xs text-gray-500">
              Start Time
            </label>

            <input
              disabled={!isEdit}
              id="startTime"
              name="startTime"
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              className="w-full rounded-lg border overflow-hidden h-12 px-2.5"
            />
          </div>


        </div>

        <div className="flex items-center gap-x-2 w-full">
          <div className="w-1/2">
            <label htmlFor="end_date" className="text-xs text-gray-500">
              End Date
            </label>

            <input
              disabled={!isEdit}
              id="end_date"
              name="end_date"
              type="date"
              value={end_date}
              onChange={(e) => setEndDate(e.target.value)}
              className="w-full rounded-xl border overflow-hidden h-12 px-2.5"
            />
          </div>
          <div className="w-1/2">
            <label htmlFor="endTime" className="text-xs text-gray-500">
              End Time
            </label>

            <input
              disabled={!isEdit}
              id="endTime"
              name="endTime"
              type="time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              className="w-full rounded-lg border overflow-hidden h-12 px-2.5"
            />
          </div>


        </div>
        <div className="space-y-2 my-2">
          <p className="text-lg font-medium">Boarding Information</p>
          {/* <CustomSelect
            tasks={kennels}
            setSelectedTask={setKennel}
            selectedTask={kennel}
            placeholder="Kennel Type"
          /> */}

          {/* <label htmlFor="kennels" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select an option</label> */}
          <Input type="select" disabled={!isEdit} id="kennels" className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " value={kennel} onChange={(e) => setKennel(e.target.value)}>
            {
              allKennels.map((kennel, i) => {
                return <option key={i} disabled={kennel?.available ? null : true} value={kennel?._id}>{kennel?.kennel_name}</option>
              })
            }
          </Input>


          <Input type="select" disabled={!isEdit} placeholder="Boarding Purpose" onChange={(e) => {setBoardingPurpose(e.target.value)}}>
            {boardingPurposeArr?.map((item) => {return (
              <option>{item}</option>
            )})

            }

          </Input>


          {/*


          <CustomSelect
            tasks={boardingPurposeArr}
            setSelectedTask={setBoardingPurpose}
            selectedTask={boardingPurpose}
            placeholder="Boarding Purpose"
          />*/}
          <input
            disabled={!isEdit}
            type="text"
            className="p-2.5 rounded-lg border w-full"
            placeholder="Add Notes"
            value={notes}
            onChange={(e) => { setNotes(e.target.value) }}
          />
        </div>
        <div className="flex justify-end items-center gap-2 mt-3">

          { isEdit &&
          <button
            onClick={() => { 
              resetData();
              setEdit(false)}} 
            className="px-4 py-2.5 rounded-lg bg-gray-100"
          >
            Cancel
          </button>}

          {permissions?.edit_boarding && isEdit && 
            <button onClick={assignKennelToPatient} className="px-4 py-2.5 rounded-lg text-white bg-[#11CA9D]">
              {isEdit ? 'Save' : 'Edit' } 
            </button>
          }
        </div>
      </div>
    </div>
  );
};

export default KennelInfoViewUpdate;
