import * as Yup from "yup";
/**
 * @description declaration form schema for client validations
 */

export const orderSchema = Yup.object().shape({
  items: Yup.array().of(
    Yup.object().shape({
      product_id: Yup.string(),
      product_name: Yup.string(),
      service_name: Yup.string(),
      description: Yup.string().nullable(),
      form: Yup.string(),
      antech_code: Yup.string(),
      dose: Yup.number(),
      route: Yup.string(),
      frequency: Yup.string(),
      rate_val: Yup.string(),
      rate_time: Yup.string(),
      duration_num: Yup.number(),
      duration_unit: Yup.string(),
      prn: Yup.boolean(),
      first_dose: Yup.date(),
      notes: Yup.string()
    })
  ),
  ivls: Yup.string().when('items', {
    is: (medications) => medications?.filter(i => i?.inHouse === true && i?.idexx_code)?.length > 0 ,
    then: Yup.string().required('IVLS is required'),
    otherwise: Yup.string()
  }),

  antech_species: Yup.string().when('items', {
    is: (medications) => medications?.filter(i => i?.antech_code === true)?.length > 0 ,
    then: Yup.string().required('Species is required'),
    otherwise: Yup.string()
  }),
  antech_breed: Yup.string().when('items', {
    is: (medications) => medications?.filter(i => i?.antech_code === true)?.length > 0 ,
    then: Yup.string().required('Species is required'),
    otherwise: Yup.string()
  }),


  idexx_species: Yup.string().when('items', {
    is: (medications) => medications?.filter(i => i?.idexx_code === true)?.length > 0 ,
    then: Yup.string().required('Species is required'),
    otherwise: Yup.string()
  }),
  idexx_breed: Yup.string().when('items', {
    is: (medications) => medications?.filter(i => i?.idexx_code === true)?.length > 0 ,
    then: Yup.string().required('Species is required'),
    otherwise: Yup.string()
  }),

});

export const orderInit = {
  ivls: '',
  items: [],
};
