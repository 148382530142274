import React, {useEffect, useState } from "react";
import { Input } from "reactstrap";
import { getAndSearchPurchaseOrders } from "../../../context/actions/inventory";
import { useDispatch, useSelector } from "react-redux";

const TableListFilterBlock = () => {

	const dispatch = useDispatch();
	const { purchaseOrders } = useSelector(state => state.inventoryReducer);
	const [supplierNames, setSupplierNames] = useState([]);
	const [inputText, setInputText] = useState('');
	const [selectedSupplier, setSelectedSupplier] = useState('');
	const [selectedOrder, setSelectedOrder] = useState('');
	const [selectedTimeframe, setSelectedTimeframe] = useState('');


	useEffect (() => {
		handleGetPurchaseOrders();
	}, [selectedSupplier, inputText, selectedOrder, selectedTimeframe]  ) // eslint-disable-line




	const handleGetPurchaseOrders = () => {
		dispatch(getAndSearchPurchaseOrders({ searchText: inputText, supplier: selectedSupplier, order: selectedOrder, timeframe: selectedTimeframe }, () => {
		}))
	}

	const handleGetSupplierNames = () => {
		let suppliers = purchaseOrders.docs.map((obj) => obj.supplierDetails.supplier_name);
		suppliers = [...new Set(suppliers)]
		setSupplierNames(suppliers)


	}

	useEffect(() => {
		handleGetSupplierNames();
	}, []);// eslint-disable-line




	return (
		<>
			<div className="table-list-filter-blc d-flex align-items-center">
				<div className="search-cell col-cell position-relative">
					<span className="icomoon-search d-inline-flex align-items-center justify-content-center position-absolute"></span>
					<Input placeholder="Search" onChange={(e) => setInputText(e.target.value)} />
				</div>
				<div className="input-cell col-cell">
					<Input onChange={(e) => setSelectedOrder(e.target.value)}
					 type="select" name="select">
					<option>All Orders</option>

					</Input>

				</div>
				<div className="input-cell col-cell">
					<Input onChange={(e) => setSelectedSupplier(e.target.value)}
						type="select" name="select">
						<option>All Suppliers</option>
						{ supplierNames.map((item) => 
							<option>{item}</option>
						)}
					</Input>
				</div>
				<div className="input-cell col-cell">
					<Input onChange={(e) => setSelectedTimeframe(e.target.value)} 
					type="select" name="select">
						<option>All Dates</option>

						<option>Last 7 Days</option>
					</Input>
				</div>
			</div>
		</>
	);
};

export default TableListFilterBlock;
