import React, { useState } from "react";
import { useEffect } from "react";
//import Chart from 'react-apexcharts'
import { useDispatch } from "react-redux";
import { getPatientStoriesStats } from "../../../context/actions/patientStories";
import { donutLabelsValue } from "../../../utils/constants";
import NodataFoundBlock from "../../NoDataFoundBlock";
import "./index.scss";

const RecommendationBlock = ({ userId }) => {
	const dispatch = useDispatch();
	const [patientStoriesStats, setPatientStoriesStats] = useState(false);
	const [options, setOptions] = useState({
		chart: {
			width: 380,
			type: 'donut',
		},
		legend: {
			show: false,
		},
		labels: ['In Clinic', 'Phone Call', 'Video Call', 'Video Conference'],
		colors: ['#e0ce2a', '#9584fc', '#11ca9d', '#409de0'],
		plotOptions: {
			pie: {
				startAngle: -90,
				endAngle: 270,
				donut: {
					size: '65%',
					background: 'transparent',
					labels: donutLabelsValue(0)
				}
			}
		},
		dataLabels: {
			enabled: false
		},
		// fill: {
		// 	type: 'gradient',
		// }, 
		responsive: [{
			breakpoint: 480,
			options: {
				chart: {
					width: 290
				},
				legend: {
					position: 'bottom'
				}
			}
		}]
	});
	const [series, setSeries] = useState([0, 0, 0, 0]);

	const handlePatientStoriesStats = () => {
		dispatch(getPatientStoriesStats(userId, {}, (result) => {
			if (result && !result.errord) {
				setPatientStoriesStats(result)
				setSeries([
					result.in_clinic,
					result.phone_call,
					result.video_call,
					result.video_conference
				])
				setOptions({
					...options,
					plotOptions: {
						pie: {
							donut: {
								labels: donutLabelsValue(result.success_rate)
							}
						}
					}
				})
			} else {
				setPatientStoriesStats(false)
			}
		}))
	}

	useEffect(() => {
		if (userId) {
			handlePatientStoriesStats()
		}
	}, [userId]);// eslint-disable-line

	return (
		<>
			<div className="recommendation-blc text-center">
				<div className="head">
					<h3>Total Recommendations</h3>
					<h4>{patientStoriesStats?.total_feedbacks} Feedback Recieved on Total {patientStoriesStats?.total_appointments} <span>Appointments in last 90 days</span></h4>
					<h5>{patientStoriesStats?.total_feedbacks}/{patientStoriesStats?.total_appointments}</h5>
					{
						patientStoriesStats?.negative_review > 0 && <h6 className="text-danger">{patientStoriesStats?.negative_review} Negative Review</h6>
					}
				</div>
				{
					patientStoriesStats.success_rate === 0 ? <NodataFoundBlock className="layout-sm" message="No stats found" /> : <div className="inner">
						<div className="chart-block d-flex justify-content-center">
							{/* <img className="img-fluid" src={require('../../../assets/img/vetRecorEMR/recommendation-graph.png')} alt="" /> */}
							{/* <Chart options={options} series={series} type="donut" width={290} /> */}
						</div>
						<ul className="recommendation-stats">
							<li className="d-flex align-items-center">
								<label className="d-inline-flex align-items-center">
									<span className="d-inline-flex align-items-center rounded-circle"></span>
									In Clinic
								</label>
								<span className="ms-auto">{patientStoriesStats.in_clinic}%</span>
							</li>
							<li className="d-flex align-items-center">
								<label className="d-inline-flex align-items-center phone-call">
									<span className="d-inline-flex align-items-center rounded-circle"></span>
									Phone Call
								</label>
								<span className="ms-auto">{patientStoriesStats.phone_call}%</span>
							</li>
							<li className="d-flex align-items-center">
								<label className="d-inline-flex align-items-center video-call">
									<span className="d-inline-flex align-items-center rounded-circle"></span>
									Video Call
								</label>
								<span className="ms-auto">{patientStoriesStats.video_call}%</span>
							</li>
							<li className="d-flex align-items-center">
								<label className="d-inline-flex align-items-center video-conference">
									<span className="d-inline-flex align-items-center rounded-circle"></span>
									Video Conference
								</label>
								<span className="ms-auto">{patientStoriesStats.video_conference}%</span>
							</li>
						</ul>
					</div>
				}

			</div>
		</>
	);
};

export default RecommendationBlock;
