import React, { useEffect, useRef, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addAntechOrder } from "../../context/actions/patient-module/diagnostic-testing";
import { Helmet } from "react-helmet";
import { getOrdersWithCategoryByPetId } from "../../context/actions/patient-module/orders";
import { addOrder } from "../../context/actions/patient-module/diagnostic-testing";
import LoaderUI from "shared/loader";


/**
 * iFrame viewer to handle ref lab orders flow
 * @returns {JSX.Element} Component template
 */

const LabOrderViewer = () => {

  const dispatch = useDispatch();
  
  const labOrderViewerData  = useSelector((state) => state.user.labOrderViewerData);

  const [loading, setLoading] = useState(false)

  /**
   * Function to handle getting orders for current patient and subsequently close the modal
   * should only be called upon user closure of modal or after end of all order flows
   */
	const handleGetOrders = () => {
    setLoading(true)
		dispatch(getOrdersWithCategoryByPetId(labOrderViewerData.pet, (res) => {

      if(res?.status){
        dispatch({
          type: "SET_LAB_ORDER_VIEWER_DATA", data: null
        });
        setLoading(false)
      }
    }))
	}

  /**
   * Function to handle navigating to next order window
   * @param {string} id id of idexx order to be fetched if it exists
   */
  const handleNext = (id) => {
    setLoading(true)

    // case for antech order
    if(labOrderViewerData?.urls?.[labOrderViewerData?.index]?.accession){
      dispatch(addAntechOrder(labOrderViewerData?.urls?.[labOrderViewerData?.index]?.accession,(res) => {
        if(res){
          handleIncrement()
        }
      }))
    } 
    //case for idexx order
    else if(id) {
      dispatch(addOrder(id, (response) => {
        if(response?.status){
          handleIncrement()
        }
      }))
    }
  } 


  /**
   * Function to handle incrementing to next order link, or close modal if none exist
   */
  const handleIncrement = () => {

    if(labOrderViewerData?.index + 1 < labOrderViewerData?.urls?.length){

      dispatch({
        type:  "SET_LAB_ORDER_VIEWER_DATA",
        data: {
          ...labOrderViewerData,
          index: labOrderViewerData?.index + 1
        }})
        setLoading(false)

    } else {

      handleClose();

    }

  }
  /**
   * Function to handle closing modal, adding final antech order if it exists
   */

  const handleClose = () => {

    if(labOrderViewerData?.urls?.[labOrderViewerData?.index]?.accession){
      dispatch(addAntechOrder(labOrderViewerData?.urls?.[labOrderViewerData?.index]?.accession,(res) => {
        if(res){
          handleGetOrders();
        }
      }))
    }
    else{
      handleGetOrders()
    }
  }

  /**
   * Event handler for message event to catch order completion message from Idexx
   * @param {Event} e message event
   */
  const recieveMessage = (e) => {
		if (e?.data && e?.data?.length > 0 && e?.data?.indexOf('ui/done') == 0) {
			e.stopPropagation();
			const vals = e?.data?.split('?orderId=')
      handleNext(vals?.[1])
		}
	}

	useEffect(() => {

    console.log(labOrderViewerData, 'lab data')
		window.addEventListener('message', recieveMessage);

		return () => window.removeEventListener("message", recieveMessage);

	}, [labOrderViewerData])


  return (
    <>
  
      <Helmet>
          <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
          <script src="https://code.jquery.com/ui/1.12.1/jquery-ui.js"></script>
      </Helmet>




      {labOrderViewerData && (
        <Modal
          onHide={handleClose}
          fullscreen
          centered
          show
        >



          <Modal.Header closeButton>
            <Modal.Title>{`${labOrderViewerData?.urls[labOrderViewerData?.index]?.accession ? 'Antech' : 'Idexx'} Order Form`  || ""}</Modal.Title>
          </Modal.Header>
          {loading && <LoaderUI overlay />}

          {labOrderViewerData?.urls[labOrderViewerData?.index]?.url?.includes("http") ? (
            <>
            <div style={{height: '100%'}} id="pims-immersion-iframe-container">
              <iframe id="pims-immersion-iframe-app" src={labOrderViewerData?.urls?.[labOrderViewerData?.index]?.url} title="PIMS Immersion" />
            </div>
            <div id="pims-immersion-footer-bar" class="pims-immersion-footer-bar" style={{display: "none"}}>
            <p style={{color: 'white', padding: '8px', marginRight: '25px'}}>Test Orders & Results</p>
            <span id="pims-immersion-footer-bar-max-icon" class="pims-immersion-footer-bar-icon" title="maximize">
                <img src="./marga-assets/icons/Expand_Static.svg" />
            </span>
            <span id="pims-immersion-footer-bar-close-icon" class="pims-immersion-footer-bar-icon" title="close">
                <img src="./marga-assets/icons/Close_Static.svg" />
            </span>
            </div>
            </>
          ) : (
            <div className="text-22-500 color-2E65 f-center h-100">
              Invalid URL found.
            </div>
          )}
          <Modal.Footer>

          {labOrderViewerData?.index + 1 < labOrderViewerData?.urls?.length && labOrderViewerData?.urls?.[labOrderViewerData?.index]?.accession? 
          <Button variant="primary" onClick={handleNext}>
            Next
          </Button>: <></>}

          </Modal.Footer> 
        </Modal>
      )}
    </>
  );
};

export default LabOrderViewer;
