import React, { useEffect, useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Input, Table } from "reactstrap";
import { getAllBreeds, getAllSpecies } from "context/actions/app-content";
import {
  dateDiffFromNow,
  dateFromDateTime,
  dueDaysFromDate,
  exportCSVFile,
  titleCaseString,
} from "utils/common";
import NodataFoundBlock from "shared/NoDataFoundBlock";
import { Icons } from "utils/contants/Icons";
import {
  getPatientRecordsList,
  getSinglePatientRecords,
} from "context/actions/clinic-reports";
import LoaderUI from "shared/loader";
import PaginationBlock from "shared/PaginationBlock";
import Select from "react-select";
import moment from "moment";
import "./PatientRecordsReport.scss";
import DateRange from "shared/FormsInput/DateRange";
import "react-datepicker/dist/react-datepicker.css";
import UserPlaceholder from "shared/UserPlaceholder";
import PetPlaceholder from "shared/PetPlaceholder";

const PatientRecordsReport = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [childLimit, setChildLimit] = useState(5);
  const [treatmentPage, setTreatmentPage] = useState(1);
  const [treatmentPaging, setTreatmentPaging] = useState(1);
  const [medicationPage, setMedicationPage] = useState(1);
  const [medicationPaging, setMedicationPaging] = useState(1);
  const [diagnosisPage, setDiagnosisPage] = useState(1);
  const [diagnosisPaging, setDiagnosisPaging] = useState(1);
  const [resolvedPage, setResolvedPage] = useState(1);
  const [resolvedPaging, setResolvedPaging] = useState(1);
  const [radiologyPage, setRadiologyPage] = useState(1);
  const [radiologyPaging, setRadiologyPaging] = useState(1);
  const [orderPage, setOrderPage] = useState([]);
  const [orderPaging, setOrderPaging] = useState([]);
  const [vaccinationPage, setVaccinationPage] = useState([]);
  const [vaccinationPaging, setVaccinationPaging] = useState([]);
  const [diagnoseStartDate, setDiagnoseStartDate] = useState(null);
  const [diagnoseEndDate, setDiagnoseEndDate] = useState(null);
  const [petId, setPetId] = useState(null);
  const [radiologyStartDate, setRadiologyStartDate] = useState(null);
  const [radiologyEndDate, setRadiologyEndDate] = useState(null);
  const { species, breeds } = useSelector((state) => state.appContentReducer);
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const [selectedValue, setSelectedValue] = useState(null);
  const [newData, setNewData] = useState([]);
  const [filterData, setFilterData] = useState({
    speciesId: "",
    breedId: "",
    searchText: "",
  });

  const [tableData, setTableData] = useState({
    loading: true,
    data: [],
  });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const fetchData = async () => {
    const payload = {
      species_id: filterData?.speciesId,
      breed_id: filterData?.breedId,
      searchText: filterData?.searchText,
    };
    dispatch(
      getPatientRecordsList(
        { page, limit, clinic_id: clinic_details?._id, ...payload },
        (res) => {
          setTableData((prev) => {
            return {
              ...prev,
              data: res?.response_data || [],
              loading: false,
            };
          });
        }
      )
    );
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit]);

  useEffect(() => {
    setPage(1);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    dispatch(getAllSpecies({}, () => {}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (newData) {
      const updatedByPages = orderDataTable?.map((arr, _) => 1);
      setOrderPage(updatedByPages);
      setOrderPaging(updatedByPages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData]);

  useEffect(() => {
    if (newData) {
      const updatedByPages = vacctionationTable?.map((arr, _) => 1);
      setVaccinationPage(updatedByPages);
      setVaccinationPaging(updatedByPages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData]);

  // const handelChange = (e) => {
  //   setSelectedValue(e);
  //   if (e) {
  //     setPetId(e?.id);

  //     dispatch(
  //       getSinglePatientRecords(
  //         { patient_id: e?.id, clinic_id: clinic_details?._id },
  //         (res) => {
  //           setNewData(res?.response_data);
  //         }
  //       )
  //     );
  //   } else {
  //     setSelectedValue(null);
  //     setNewData([]);
  //   }
  // };

  const handelSearch = (e) => {
    if (e) {
      let value = e?.target?.value;
      setFilterData((prev) => ({
        ...prev,
        searchText: value,
      }));
    }
  };

  const handleProfileClick = (dataElem) => {
    setSelectedValue(dataElem);
    setPetId(dataElem?._id);
    if (dataElem) {
      dispatch(
        getSinglePatientRecords(
          { patient_id: dataElem?._id, clinic_id: clinic_details?._id },
          (res) => {
            setNewData(res?.response_data);
          }
        )
      );
    }
  };

  // const newTableData = [
  //   {
  //     id: 1,
  //     title: "Treatment Plan Report",
  //     header: [{ title: "Dr Name" }, { title: "Treatment Name" }],
  //     data: newData?.[0]?.treatmentPlanReports || [],
  //   },
  //   {
  //     id: 2,
  //     title: "Medication Report",
  //     header: [
  //       { title: "Dr Name" },
  //       { title: "Status" },
  //       { title: "Dose" },
  //       { title: "Notes" },
  //       { title: "Product Name" },
  //     ],
  //     data: newData?.[0]?.medicationReports || [],
  //   },
  //   {
  //     id: 3,
  //     title: "Diagnosis Condition Report",
  //     header: [
  //       { title: "Dr Name" },
  //       { title: "Status" },
  //       { title: "Start Date" },
  //     ],
  //     data: newData?.[0]?.diagnosisConditionReport || [],
  //   },
  //   // {
  //   //   id: 4,
  //   //   title: "Patient Report",
  //   //   header: [
  //   //     { title: "Dr Name" },
  //   //     { title: "Status" },
  //   //     { title: "Dose" },
  //   //     { title: "Notes" },
  //   //     { title: "Product Name" },
  //   //   ],
  //   // },
  //   {
  //     id: 4,
  //     title: "Resolved Condition Report",
  //     header: [
  //       { title: "Dr Name" },
  //       { title: "status" },
  //       { title: "Start Date" },
  //       { title: "End Date" },
  //     ],
  //     data: newData?.[0]?.resolvedConditionReport || [],
  //   },
  //   {
  //     id: 5,
  //     title: "Radiology Report",
  //     header: [
  //       { title: "Dr Name" },
  //       { title: "Report Name" },
  //       { title: "X-Ray Date" },
  //     ],
  //     data: newData?.[0]?.radiologyReports || [],
  //   },
  //   {
  //     id: 6,
  //     title: "Order Report",
  //     header: [
  //       { title: "Product Name" },
  //       { title: "Status" },
  //       { title: "Dose" },
  //       { title: "Notes" },
  //       { title: "Type" },
  //     ],
  //     data: newData?.[0]?.orderReports || [],
  //   },
  // ];

  function getDateRange(first_dose, duration_num, duration_unit) {
    // Parse the start date
    const startDate = new Date(first_dose);

    // Calculate the end date based on duration_unit and duration_num
    const endDate = new Date(startDate);
    switch (duration_unit) {
      case "Days":
        endDate.setDate(startDate.getDate() + duration_num);
        break;
      case "Weeks":
        endDate.setDate(startDate.getDate() + 7 * duration_num);
        break;
      case "Months":
        endDate.setMonth(startDate.getMonth() + duration_num);
        break;
      case "Years":
        endDate.setFullYear(startDate.getFullYear() + duration_num);
        break;
      default:
        break;
    }

    return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
  }

  const orderStatusData = {
    Ordered: newData?.[0]?.orderReports?.filter(
      (item) => item.status === "Ordered"
    ),
    Stopped: newData?.[0]?.orderReports?.filter(
      (item) => item.status === "Stopped"
    ),
    Active: newData?.[0]?.orderReports?.filter(
      (item) => item.status === "Active"
    ),
    // Canceled: newData?.[0]?.orderReports?.filter(
    //   (item) => item.status === "Canceled"
    // ),
    Completed: newData?.[0]?.orderReports?.filter(
      (item) => item.status === "Completed"
    ),
  };

  const orderDataTable = [
    {
      id: 1,
      title: "Ordered",
      data: orderStatusData?.Ordered,
    },
    {
      id: 2,
      title: "Stopped",
      data: orderStatusData?.Stopped,
    },
    {
      id: 3,
      title: "Active",
      data: orderStatusData?.Active,
    },
    // {
    //   id: 4,
    //   title: "Canceled",
    //   data: orderStatusData?.Canceled,
    // },
    {
      id: 5,
      title: "Completed",
      data: orderStatusData?.Completed,
    },
  ];

  const vacctionation = {
    OverDue: newData?.[0]?.vaccinationReport?.filter((vaccine) =>
      moment(vaccine?.due_dates?.[0]).isBefore(moment())
    ),
    DueIn: newData?.[0]?.vaccinationReport?.filter((vaccine) =>
      moment(vaccine?.due_dates?.[0]).isAfter(moment())
    ),
  };

  const vacctionationTable = [
    {
      id: 1,
      title: "Overdue",
      data: vacctionation?.OverDue,
    },
    {
      id: 2,
      title: "Up to Date",
      data: vacctionation?.DueIn,
    },
  ];

  const handleDiagnoseDateChange = (startDate, endDate) => {
    const payload = {
      diagnosed_start_date: startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : "",
      diagnosed_end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
    };

    dispatch(
      getSinglePatientRecords({ patient_id: petId, ...payload }, (res) => {
        setNewData(res?.response_data);
      })
    );
  };

  const handleRadiologyDateChange = (startDate, endDate) => {
    const payload = {
      radiology_start_date: startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : "",
      radiology_end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
    };

    dispatch(
      getSinglePatientRecords({ patient_id: petId, ...payload }, (res) => {
        setNewData(res?.response_data);
      })
    );
  };

  const treatmentPlanTotalPages = Math.ceil(
    newData?.[0]?.treatmentPlanReports?.length / childLimit
  );
  const treatmentPlanData = newData?.[0]?.treatmentPlanReports?.slice(
    (treatmentPage - 1) * childLimit,
    treatmentPage * childLimit
  );

  const medicationPlanTotalPages = Math.ceil(
    newData?.[0]?.medicationReports?.length / childLimit
  );
  const medicationReportData = newData?.[0]?.medicationReports?.slice(
    (medicationPage - 1) * childLimit,
    medicationPage * childLimit
  );

  const diagnosisTotalPages = Math.ceil(
    newData?.[0]?.diagnosisConditionReport?.length / childLimit
  );
  const diagnosisReportData = newData?.[0]?.diagnosisConditionReport?.slice(
    (diagnosisPage - 1) * childLimit,
    diagnosisPage * childLimit
  );

  const resolvedTotalPages = Math.ceil(
    newData?.[0]?.resolvedConditionReport?.length / childLimit
  );
  const resolvedReportData = newData?.[0]?.resolvedConditionReport?.slice(
    (resolvedPage - 1) * childLimit,
    resolvedPage * childLimit
  );

  const radiologyTotalPages = Math.ceil(
    newData?.[0]?.radiologyReports?.length / childLimit
  );
  const radiologyReportData = newData?.[0]?.radiologyReports?.slice(
    (radiologyPage - 1) * childLimit,
    radiologyPage * childLimit
  );
  const handleDownload = () => {
    if (selectedValue) {
      //for multiple table
      const csvContent = generateCSV(newData);
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "Single Patient Record Report.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } else {
      const header = [
        "Patient Info",
        "VRN",
        "Status",
        "Wellness Plan",
        "Animal",
        "Breed",
        "Gender",
        "Owner",
      ];
      let csvData = [];
      tableData?.data?.docs?.forEach((data) => {
        const {
          pet_name,
          vrn,
          active,
          speciesDetails,
          breedDetails,
          gender,
          petOwner,
        } = data;
        csvData.push([
          titleCaseString(pet_name),
          vrn,
          active && "Active",
          "Golden care plan",
          titleCaseString(speciesDetails?.species_name),
          titleCaseString(breedDetails?.breed),
          titleCaseString(gender),
          titleCaseString(petOwner?.owner_name),
        ]);
      });

      exportCSVFile(header, csvData, "Patient Records Report.csv");
    }
  };

  const generateCSV = (newData) => {
    // Function to convert data to CSV format
    let csv = "";
    // Add header for Treatment Plan Report table
    csv +=
      "Treatment Plan Report\nCreated By,Treatment Name,Start Date,End Date\n";
    newData?.[0]?.treatmentPlanReports?.forEach((treatment, treatmentIndex) => {
      csv += `${titleCaseString(
        `Dr. ${treatment?.doctor_firstName} ${treatment?.doctor_lastName}`
      )},${titleCaseString(treatment?.treatmentName)},${moment(
        treatment?.instructionDetails?.[treatmentIndex]?.start_Date
      ).format("MM/DD/YYYY")},${moment(
        treatment?.instructionDetails?.[treatmentIndex]?.end_Date
      ).format("MM/DD/YYYY")},\n`;
    });

    // Add header for Medication Report table
    csv += "\n\nMedication Report\nCreated By,Status,Dose,Notes,Product Name\n";
    newData?.[0]?.medicationReports?.forEach((medication) => {
      csv += `${titleCaseString(
        `Dr. ${medication?.doctor_firstName} ${medication?.doctor_lastName}`
      )},${medication?.active && "Active"},${getDateRange(
        medication?.first_dose,
        medication?.duration_num,
        medication?.duration_unit
      )},${titleCaseString(medication?.notes)},${titleCaseString(
        medication?.product_name
      )},\n`;
    });

    // Add header for Diagnosis Condition Report table
    csv +=
      "\n\nDiagnosis Condition Report\nCreated By,Status,Start Date,End Date\n";
    newData?.[0]?.diagnosisConditionReport?.forEach((diagnosis) => {
      csv += `${titleCaseString(
        `Dr. ${diagnosis?.doctor_firstName} ${diagnosis?.doctor_lastName}`
      )},${titleCaseString(diagnosis?.status)},${moment(
        diagnosis?.start_date
      ).format("MM/DD/YYYY")},${moment(diagnosis?.end_date).format(
        "MM/DD/YYYY"
      )},\n`;
    });

    // Add header for Resolved Condition Report table
    csv +=
      "\n\nResolved Condition Report\nCreated By,Status,Start Date,End Date\n";
    newData?.[0]?.resolvedConditionReport?.forEach((resolved) => {
      csv += `${titleCaseString(
        `Dr. ${resolved?.doctor_firstName} ${resolved?.doctor_lastName}`
      )},${titleCaseString(resolved?.status)},${moment(
        resolved?.start_date
      ).format("MM/DD/YYYY")},${moment(resolved?.end_date).format(
        "MM/DD/YYYY"
      )},\n`;
    });

    // Add header for Radiology Report table
    csv += "\n\nRadiology Report\nCreated By,Report Name,X-Ray Date\n";
    newData?.[0]?.radiologyReports?.forEach((radiology) => {
      csv += `${titleCaseString(
        `Dr. ${radiology?.doctor_firstName} ${radiology?.doctor_lastName}`
      )},${titleCaseString(radiology?.report_name)},${moment(
        radiology?.x_ray_date
      ).format("MM/DD/YYYY")}\n`;
    });

    // Add header for Order Report table
    orderDataTable?.forEach((category) => {
      if (category?.data?.length > 0) {
        // Add headers based on the first item in the category
        csv += `\n\n Order Report (${category.title})\nProduct Name,Status,Dose,Notes,Type\n`;
        category?.data?.forEach((order) => {
          csv += `${titleCaseString(order?.product_name)},${titleCaseString(
            order?.status
          )},${moment(order?.first_dose).format(
            "MM/DD/YYYY"
          )},${titleCaseString(order?.notes)},${titleCaseString(
            order?.type
          )}\n`;
        });
      }
    });

    // Add header for Vaccination Report table
    vacctionationTable?.forEach((vaccine) => {
      if (vaccine?.data?.length > 0) {
        // Add headers based on the first item in the category
        csv += `\n\n Vaccination Report (${vaccine.title})\nVaccine Name,Status,Due Date,Administered On,Administered By\n`;
        vaccine?.data?.forEach((vData) => {
          csv += `${titleCaseString(vData?.product_name)},${dueDaysFromDate(
            vaccine?.due_dates?.[0]
          )},${dateFromDateTime(vData?.due_dates?.[0], "MM/DD/yyyy")},${
            vData?.administrations?.length > 0
              ? moment(vData?.administrations?.[0]?.date).format("MM/DD/YYYY")
              : "--"
          },${`${
            vData?.administrations?.[0]?.created_by?.title
              ? vData?.administrations?.[0]?.created_by?.title + " "
              : ""
          } ${vData?.administrations?.[0]?.created_by?.firstname} ${
            vData?.administrations?.[0]?.created_by?.lastname
          }`}\n`;
        });
      }
    });

    return csv;
  };

  const getOrderPaginatedData = (dataArray, tabIndex) => {
    const startIndex = (orderPage[tabIndex] - 1) * childLimit;
    const endIndex = startIndex + childLimit;
    return dataArray?.slice(startIndex, endIndex) || [];
  };

  const getVacinePaginatedData = (dataArray, tabIndex) => {
    const startIndex = (vaccinationPage[tabIndex] - 1) * childLimit;
    const endIndex = startIndex + childLimit;
    return dataArray?.slice(startIndex, endIndex) || [];
  };

  return (
    <div id="patient-records-report-container" className="p-1 mt-1">
      <div className="row">
        <div className="col-md-3">
          <div className="">
            <div className="d-flex">
              <div
                className="d-flex gap-2 align-items-center cmb-20 pointer"
                onClick={() => history.push("/reports")}
              >
                <BsChevronLeft color="color-3EFD" />
                <span className="text-13-600 color-3EFD">VIEW ALL REPORTS</span>
              </div>
            </div>

            <div className="bg-filter-class">
              <div className="text-24-700 color-2E65">Configure Report</div>
              <div className="text-14-400 color-8693">
                Select filter and generate report
              </div>
              <div className="filter-block cmt-20 cmb-20">
                {selectedValue === null && (
                  <div className="cmb-10">
                    <Input
                      type="select"
                      name="select"
                      value={filterData?.speciesId}
                      placeholder="All Species"
                      onChange={(e) => {
                        setFilterData((prev) => ({
                          ...prev,
                          speciesId: e?.target?.value,
                        }));
                        if (e?.target?.value) {
                          dispatch(getAllBreeds(e?.target?.value, () => {}));
                        }
                      }}
                    >
                      <option value="">All Species</option>
                      {species?.map((e, i) => {
                        return (
                          <option value={e?._id} key={i} id={e?._id}>
                            {e?.species_name}
                          </option>
                        );
                      })}
                    </Input>
                  </div>
                )}
                {filterData?.speciesId && (
                  <div className="cmb-10">
                    <Input
                      type="select"
                      name="select"
                      value={filterData?.breedId}
                      placeholder="All Breed"
                      onChange={(e) => {
                        setFilterData((prev) => ({
                          ...prev,
                          breedId: e?.target?.value,
                        }));
                      }}
                    >
                      <option value="">All Breed</option>
                      {breeds?.map((e, i) => {
                        return (
                          <option value={e?._id} key={i} id={e?._id}>
                            {titleCaseString(e?.breed)}
                          </option>
                        );
                      })}
                    </Input>
                  </div>
                )}
                {selectedValue && (
                  <>
                    <div className="cmb-20">
                      <DateRange
                        id="diagnose"
                        name="diagnose"
                        placeholder="Select date for diagnose"
                        isClearable
                        startDateProp={diagnoseStartDate}
                        endDateProp={diagnoseEndDate}
                        setStartDateProp={setDiagnoseStartDate}
                        setEndDateProp={setDiagnoseEndDate}
                        onChange={(dateOne, dateTwo) => {
                          handleDiagnoseDateChange(dateOne, dateTwo);
                        }}
                      />
                    </div>

                    <div className="cmb-20">
                      <DateRange
                        id="radiology"
                        name="radiology"
                        placeholder="Select date for radiology"
                        isClearable
                        startDateProp={radiologyStartDate}
                        endDateProp={radiologyEndDate}
                        setStartDateProp={setRadiologyStartDate}
                        setEndDateProp={setRadiologyEndDate}
                        onChange={(dateOne, dateTwo) => {
                          handleRadiologyDateChange(dateOne, dateTwo);
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="cmb-15">
                {/* <Button className="g-report">Generate Report</Button> */}
                <div
                  className="text-center color-2E65 text-13-600 cmt-20 pointer"
                  onClick={() => {
                    setFilterData({
                      speciesId: "",
                      breedId: "",
                      searchText: "",
                    });
                    setNewData([]);
                    setSelectedValue(null);
                    setDiagnoseEndDate(null);
                    setDiagnoseStartDate(null);
                    setRadiologyStartDate(null);
                    setRadiologyEndDate(null);
                  }}
                >
                  RESET FILTER
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-9 details-section">
          <div className="cpt-20 cpe-20 cpb-20">
            <div className="d-flex justify-content-between align-items-center cmb-20">
              <div>
                <div className="text-24-700 color-2E65">Patient Records</div>
                {/* <p className="text-14-400 color-8693 text-wrap">
                  Patient Records help track the clinic’s medical supplies,
                  medications, and equipment to ensure that everything is
                  well-stocked and up to date.
                </p> */}
              </div>
              {selectedValue && (
                <div className="d-flex gap-2 br-8">
                  <div
                    className="cp-8 bg-8C9F28 pointer"
                    onClick={() => {
                      handleDownload();
                    }}
                  >
                    <img src={Icons.download} alt="download" />
                  </div>
                  <div className="cp-8 bg-8C9F28 br-8">
                    <img src={Icons.share} alt="share" />
                  </div>
                </div>
              )}
            </div>

            {/* <div className="cmb-20">
              <Select
                className="font-['Inter']text-[#282E65] text-sm pointer"
                placeholder="Please Select Patient"
                isClearable={true}
                options={
                  tableData?.data?.docs?.length > 0 &&
                  tableData?.data?.docs?.map((elem) => {
                    return {
                      value: elem?.pet_name,
                      id: elem?._id,
                      data: { elem: elem },
                      label: (
                        <div className="d-flex gap-2 align-items-center">
                          <div
                            className="flex-shrink-0"
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                          >
                            {elem?.pet_image ? (
                              <img
                                className="img-fluid w-100 h-100"
                                src={elem?.pet_image}
                                alt="Product"
                                style={{
                                  borderRadius: "100px",
                                }}
                              />
                            ) : (
                              <PetPlaceholder />
                            )}
                          </div>
                          <div>{titleCaseString(elem?.pet_name)}</div>
                        </div>
                      ),
                    };
                  })
                }
                // onChange={handelChange}
                value={selectedValue}
              />
            </div> */}

            <div className="d-flex gap-3 align-items-center cmb-20">
              {newData?.length > 0 && (
                <Button
                  color="link"
                  className="back-btn ps-0"
                  onClick={() => {
                    setNewData([]);
                    setSelectedValue(null);
                  }}
                >
                  Go Back
                </Button>
              )}
              {newData?.length === 0 && (
                <div className="search-cell-conatainer ">
                  <span className="icomoon-search search-icons"></span>
                  <Input
                    type="text"
                    placeholder="Search"
                    value={filterData?.searchText}
                    onChange={handelSearch}
                  />
                </div>
              )}
            </div>

            {newData?.length > 0 ? (
              <>
                <div className="table-one cmb-20">
                  <div className="text-14-600 color-2E65 cmb-10">
                    Treatment Plans (
                    {newData?.[0]?.treatmentPlanReports?.length})
                  </div>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Created By</th>
                        <th>Treatment Name</th>
                        <th style={{ maxWidth: "15%" }}>Start Date</th>
                        <th style={{ maxWidth: "15%" }}>End Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {treatmentPlanData?.length > 0 ? (
                        treatmentPlanData?.map((dataElem, dataIndex) => {
                          return (
                            <tr key={dataIndex}>
                              <td className="text-nowrap pointer">
                                <div className="d-flex gap-2">
                                  <div
                                    className="flex-shrink-0"
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  >
                                    {dataElem?.doctor_image ? (
                                      <img
                                        className="img-fluid w-100 h-100"
                                        src={dataElem?.doctor_image}
                                        alt="Product"
                                        style={{
                                          borderRadius: "100px",
                                        }}
                                      />
                                    ) : (
                                      <PetPlaceholder />
                                    )}
                                  </div>
                                  <div>
                                    {titleCaseString(
                                      `Dr. ${dataElem?.doctor_firstName} ${dataElem?.doctor_lastName}`
                                    )}
                                  </div>
                                </div>
                              </td>

                              <td>
                                {titleCaseString(dataElem?.treatmentName)}
                              </td>
                              <td>
                                {dataElem?.instructionDetails?.[dataIndex]
                                  ?.start_Date
                                  ? moment(
                                      dataElem?.instructionDetails?.[dataIndex]
                                        ?.start_Date
                                    ).format("MM/DD/YYYY")
                                  : "--"}
                              </td>
                              <td>
                                {dataElem?.instructionDetails?.[dataIndex]
                                  ?.end_Date
                                  ? moment(
                                      dataElem?.instructionDetails?.[dataIndex]
                                        ?.end_Date
                                    ).format("MM/DD/YYYY")
                                  : "--"}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="no-data-rw">
                          <td colSpan="11">
                            <NodataFoundBlock />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {newData?.[0]?.treatmentPlanReports?.length > 0 && (
                    <PaginationBlock
                      limit={childLimit}
                      page={treatmentPage}
                      pagingCounter={treatmentPaging || 1}
                      docLength={childLimit || 0}
                      docPage={treatmentPage || 1}
                      totalPages={treatmentPlanTotalPages || 1}
                      totalDocs={
                        newData?.[0]?.treatmentPlanReports?.length || 0
                      }
                      _handleLimit={(val) => {
                        setChildLimit(val);
                      }}
                      _handlePageChange={(e) => {
                        if (e > treatmentPage) {
                          const diff = e - treatmentPage;

                          setTreatmentPaging(
                            (prev) => prev + childLimit * diff
                          );
                        } else if (e < treatmentPage) {
                          const diff = treatmentPage - e;
                          setTreatmentPaging((prev) =>
                            Math.max(prev - childLimit * diff, 0)
                          );
                        }
                        setTreatmentPage(e);
                      }}
                    />
                  )}
                </div>
                <div className="table-one cmb-20">
                  <div className="text-14-600 color-2E65 cmb-10">
                    Medication Plans ({newData?.[0]?.medicationReports?.length})
                  </div>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Created By</th>
                        <th>Product Name</th>
                        <th>Status</th>
                        <th>Dose</th>
                        <th>Notes</th>

                        <th style={{ maxWidth: "15%" }}>Start Date</th>
                        <th style={{ maxWidth: "15%" }}>End Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {medicationReportData?.length > 0 ? (
                        medicationReportData?.map((dataElem, dataIndex) => {
                          return (
                            <tr key={dataIndex}>
                              <td className="text-nowrap pointer">
                                <div className="d-flex gap-2">
                                  <div
                                    className="flex-shrink-0"
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  >
                                    {dataElem?.doctor_image ? (
                                      <img
                                        className="img-fluid w-100 h-100"
                                        src={dataElem?.doctor_image}
                                        alt="Product"
                                        style={{
                                          borderRadius: "100px",
                                        }}
                                      />
                                    ) : (
                                      <PetPlaceholder />
                                    )}
                                  </div>
                                  <div>
                                    {titleCaseString(
                                      `Dr. ${dataElem?.doctor_firstName} ${dataElem?.doctor_lastName}`
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td>{dataElem?.product_name}</td>

                              <td>{dataElem?.active && "Active"}</td>
                              <td>
                                {dataElem?.dose} {dataElem?.form}
                              </td>
                              <td>{titleCaseString(dataElem?.notes)}</td>

                              <td className="text-nowrwap">
                                {moment(dataElem?.first_dose).format(
                                  "MM/DD/YYYY"
                                )}
                              </td>
                              <td className="text-nowrwap">
                                {
                                  moment(dataElem?.first_dose)
                                    .add(
                                      dataElem?.duration_num,
                                      dataElem?.duration_unit
                                    )
                                    .format("MM/DD/YYYY")
                                  //getDateRange(
                                  //   dataElem?.first_dose,
                                  //   dataElem?.duration_num,
                                  //   dataElem?.duration_unit
                                  // )
                                }
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="no-data-rw">
                          <td colSpan="11">
                            <NodataFoundBlock />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {newData?.[0]?.medicationReports?.length > 0 && (
                    <PaginationBlock
                      limit={childLimit}
                      page={medicationPage}
                      pagingCounter={medicationPaging || 1}
                      docLength={childLimit || 0}
                      docPage={medicationPage || 1}
                      totalPages={medicationPlanTotalPages || 1}
                      totalDocs={newData?.[0]?.medicationReports?.length || 0}
                      _handleLimit={(val) => {
                        setChildLimit(val);
                      }}
                      _handlePageChange={(e) => {
                        if (e > medicationPage) {
                          const diff = e - medicationPage;

                          setMedicationPaging(
                            (prev) => prev + childLimit * diff
                          );
                        } else if (e < medicationPage) {
                          const diff = medicationPage - e;
                          setMedicationPaging((prev) =>
                            Math.max(prev - childLimit * diff, 0)
                          );
                        }
                        setMedicationPage(e);
                      }}
                    />
                  )}
                </div>
                <div className="table-one cmb-20">
                  <div className="text-14-600 color-2E65 cmb-10">
                    Active Conditions (
                    {newData?.[0]?.diagnosisConditionReport?.length})
                  </div>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th style={{ width: "20%" }}>Created By</th>
                        <th style={{ width: "20%" }}>Status</th>
                        <th style={{ maxWidth: "15%" }}>Start Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {diagnosisReportData?.length > 0 ? (
                        diagnosisReportData?.map((dataElem, dataIndex) => {
                          return (
                            <tr key={dataIndex}>
                              <td className="text-nowrap pointer">
                                <div className="d-flex gap-2">
                                  <div
                                    className="flex-shrink-0"
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  >
                                    {dataElem?.doctor_image ? (
                                      <img
                                        className="img-fluid w-100 h-100"
                                        src={dataElem?.doctor_image}
                                        alt="Product"
                                        style={{
                                          borderRadius: "100px",
                                        }}
                                      />
                                    ) : (
                                      <PetPlaceholder />
                                    )}
                                  </div>
                                  <div>
                                    {titleCaseString(
                                      `Dr. ${dataElem?.doctor_firstName} ${dataElem?.doctor_lastName}`
                                    )}
                                  </div>
                                </div>
                              </td>

                              <td>{titleCaseString(dataElem?.status)}</td>
                              <td className="text-nowrwap">
                                {moment(dataElem?.start_date).format(
                                  "MM/DD/YYYY"
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="no-data-rw">
                          <td colSpan="11">
                            <NodataFoundBlock />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {newData?.[0]?.diagnosisConditionReport?.length > 0 && (
                    <PaginationBlock
                      limit={childLimit}
                      page={diagnosisPage}
                      pagingCounter={diagnosisPaging || 1}
                      docLength={childLimit || 0}
                      docPage={diagnosisPage || 1}
                      totalPages={diagnosisTotalPages || 1}
                      totalDocs={
                        newData?.[0]?.diagnosisConditionReport?.length || 0
                      }
                      _handleLimit={(val) => {
                        setChildLimit(val);
                      }}
                      _handlePageChange={(e) => {
                        if (e > diagnosisPage) {
                          const diff = e - diagnosisPage;

                          setDiagnosisPaging(
                            (prev) => prev + childLimit * diff
                          );
                        } else if (e < diagnosisPage) {
                          const diff = diagnosisPage - e;
                          setDiagnosisPaging((prev) =>
                            Math.max(prev - childLimit * diff, 0)
                          );
                        }
                        setDiagnosisPage(e);
                      }}
                    />
                  )}
                </div>
                <div className="table-one cmb-20">
                  <div className="text-14-600 color-2E65 cmb-10">
                    Resolved Conditions (
                    {newData?.[0]?.resolvedConditionReport?.length})
                  </div>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th style={{ width: "20%" }}>Created By</th>
                        <th style={{ width: "20%" }}>Status</th>
                        <th style={{ width: "20%" }}>Start Date</th>
                        <th>End Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {resolvedReportData?.length > 0 ? (
                        resolvedReportData?.map((dataElem, dataIndex) => {
                          return (
                            <tr key={dataIndex}>
                              <td className="text-nowrap pointer">
                                <div className="d-flex gap-2">
                                  <div
                                    className="flex-shrink-0"
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  >
                                    {dataElem?.doctor_image ? (
                                      <img
                                        className="img-fluid w-100 h-100"
                                        src={dataElem?.doctor_image}
                                        alt="Product"
                                        style={{
                                          borderRadius: "100px",
                                        }}
                                      />
                                    ) : (
                                      <PetPlaceholder />
                                    )}
                                  </div>
                                  <div>
                                    {titleCaseString(
                                      `Dr. ${dataElem?.doctor_firstName} ${dataElem?.doctor_lastName}`
                                    )}
                                  </div>
                                </div>
                              </td>

                              <td>{titleCaseString(dataElem?.status)}</td>

                              <td className="text-nowrwap">
                                {moment(dataElem?.start_date).format(
                                  "MM/DD/YYYY"
                                )}
                              </td>
                              <td className="text-nowrwap">
                                {moment(dataElem?.end_date).format(
                                  "MM/DD/YYYY"
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="no-data-rw">
                          <td colSpan="11">
                            <NodataFoundBlock />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {newData?.[0]?.resolvedConditionReport?.length > 0 && (
                    <PaginationBlock
                      limit={childLimit}
                      page={resolvedPage}
                      pagingCounter={resolvedPaging || 1}
                      docLength={childLimit || 0}
                      docPage={resolvedPage || 1}
                      totalPages={resolvedTotalPages || 1}
                      totalDocs={
                        newData?.[0]?.resolvedConditionReport?.length || 0
                      }
                      _handleLimit={(val) => {
                        setChildLimit(val);
                      }}
                      _handlePageChange={(e) => {
                        if (e > resolvedPage) {
                          const diff = e - resolvedPage;

                          setResolvedPaging((prev) => prev + childLimit * diff);
                        } else if (e < resolvedPage) {
                          const diff = resolvedPage - e;
                          setResolvedPaging((prev) =>
                            Math.max(prev - childLimit * diff, 0)
                          );
                        }
                        setResolvedPage(e);
                      }}
                    />
                  )}
                </div>
                <div className="table-one cmb-20">
                  <div className="text-14-600 color-2E65 cmb-10">
                    Radiology Report ({newData?.[0]?.radiologyReports?.length})
                  </div>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th style={{ width: "20%" }}>Created By</th>
                        <th style={{ width: "20%" }}>Report Name</th>
                        <th>X-Ray Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {radiologyReportData?.length > 0 ? (
                        radiologyReportData?.map((dataElem, dataIndex) => {
                          return (
                            <tr key={dataIndex}>
                              <td className="text-nowrap pointer">
                                <div className="d-flex gap-2">
                                  <div
                                    className="flex-shrink-0"
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  >
                                    {dataElem?.doctor_image ? (
                                      <img
                                        className="img-fluid w-100 h-100"
                                        src={dataElem?.doctor_image}
                                        alt="Product"
                                        style={{
                                          borderRadius: "100px",
                                        }}
                                      />
                                    ) : (
                                      <PetPlaceholder />
                                    )}
                                  </div>
                                  <div>
                                    {titleCaseString(
                                      `Dr. ${dataElem?.doctor_firstName} ${dataElem?.doctor_lastName}`
                                    )}
                                  </div>
                                </div>
                              </td>

                              <td>{titleCaseString(dataElem?.report_name)}</td>
                              <td className="text-nowrwap">
                                {moment(dataElem?.x_ray_date).format(
                                  "MM/DD/YYYY"
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="no-data-rw">
                          <td colSpan="11">
                            <NodataFoundBlock />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {newData?.[0]?.radiologyReports?.length > 0 && (
                    <PaginationBlock
                      limit={childLimit}
                      page={radiologyPage}
                      pagingCounter={radiologyPaging || 1}
                      docLength={childLimit || 0}
                      docPage={radiologyPage || 1}
                      totalPages={radiologyTotalPages || 1}
                      totalDocs={newData?.[0]?.radiologyReports?.length || 0}
                      _handleLimit={(val) => {
                        setChildLimit(val);
                      }}
                      _handlePageChange={(e) => {
                        if (e > radiologyPage) {
                          const diff = e - radiologyPage;

                          setRadiologyPaging(
                            (prev) => prev + childLimit * diff
                          );
                        } else if (e < radiologyPage) {
                          const diff = radiologyPage - e;
                          setRadiologyPaging((prev) =>
                            Math.max(prev - childLimit * diff, 0)
                          );
                        }
                        setRadiologyPage(e);
                      }}
                    />
                  )}
                </div>
                {orderDataTable?.map((oData, index) => {
                  const totalItems = oData?.data?.length;
                  const totalPages = Math?.ceil(totalItems / childLimit);
                  const paginatedData = getOrderPaginatedData(
                    oData?.data,
                    index
                  );
                  return (
                    <div className="table-one cmb-20" key={index}>
                      <div className="text-14-600 color-2E65 cmb-10">
                        Orders ({oData?.title}) ({oData?.data?.length})
                      </div>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th style={{ width: "20%" }}>Product Name</th>
                            <th style={{ width: "20%" }}>Status</th>
                            <th style={{ width: "20%" }}>Dose</th>
                            <th style={{ width: "20%" }}>Notes</th>
                            <th style={{ width: "20%" }}>Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paginatedData?.length > 0 ? (
                            paginatedData?.map((dataElem, dataIndex) => {
                              return (
                                <tr key={dataIndex}>
                                  <td>
                                    {titleCaseString(dataElem?.product_name)}
                                  </td>
                                  <td>{titleCaseString(dataElem?.status)}</td>
                                  <td className="text-nowrwap">
                                    {moment(dataElem?.first_dose).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </td>
                                  <td>{titleCaseString(dataElem?.notes)}</td>
                                  <td>{titleCaseString(dataElem?.type)}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr className="no-data-rw">
                              <td colSpan="11">
                                <NodataFoundBlock />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      {oData?.data?.length > 0 && (
                        <PaginationBlock
                          limit={childLimit}
                          page={orderPage[index] || 1}
                          pagingCounter={orderPaging[index] || 1}
                          docLength={childLimit || 0}
                          docPage={orderPage[index]}
                          totalPages={totalPages || 1}
                          totalDocs={oData?.data?.length || 0}
                          _handleLimit={(val) => {
                            setLimit(val);
                          }}
                          _handlePageChange={(e) => {
                            if (e > orderPage[index]) {
                              const diff = e - orderPage[index];
                              setOrderPaging((prev) => {
                                const updatedPaging = [...prev];
                                updatedPaging[index] =
                                  updatedPaging[index] + childLimit * diff;
                                return updatedPaging;
                              });
                            } else if (e < orderPage[index]) {
                              const diff = orderPage[index] - e;

                              setOrderPaging((prev) => {
                                const updatedPaging = [...prev];
                                updatedPaging[index] = Math.max(
                                  updatedPaging[index] - childLimit * diff,
                                  0
                                );
                                return updatedPaging;
                              });
                            }
                            setOrderPage((prevPages) => {
                              const updatedPages = prevPages?.map(
                                (page, pIndex) => (pIndex === index ? e : page)
                              );
                              return updatedPages;
                            });
                          }}
                        />
                      )}
                    </div>
                  );
                })}

                {vacctionationTable?.map((vData, index) => {
                  const totalItems = vData?.data?.length;
                  const totalPages = Math?.ceil(totalItems / childLimit);
                  const paginatedData = getVacinePaginatedData(
                    vData?.data,
                    index
                  );
                  return (
                    <div className="table-one cmb-20" key={index}>
                      <div className="text-14-600 color-2E65 cmb-10">
                        {vData?.title} Vaccinations ({vData?.data?.length})
                      </div>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th style={{ width: "20%" }}>Vaccine Name</th>
                            <th style={{ width: "20%" }}>Status</th>
                            <th style={{ width: "20%" }}>Due Date</th>
                            <th style={{ width: "20%" }}>Administered On</th>
                            <th style={{ width: "20%" }}>Administered By</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paginatedData?.length > 0 ? (
                            paginatedData?.map((dataElem, dataIndex) => {
                              return (
                                <tr key={dataIndex}>
                                  <td>
                                    {titleCaseString(dataElem?.product_name)}
                                  </td>
                                  <td>
                                    <span
                                      className={`${
                                        dateDiffFromNow(
                                          dataElem?.due_dates?.[0]
                                        ).status === "IN"
                                          ? "success-cell"
                                          : dateDiffFromNow(
                                              dataElem?.due_dates?.[0]
                                            ).status === "OUT"
                                          ? "inactive-cell"
                                          : "pending-cell"
                                      } status-cell`}
                                    >
                                      {dueDaysFromDate(
                                        dataElem?.due_dates?.[0]
                                      )}
                                    </span>
                                  </td>
                                  <td>
                                    {dateFromDateTime(
                                      dataElem?.due_dates?.[0],
                                      "MM/DD/yyyy"
                                    )}
                                  </td>

                                  <td className="text-nowrwap">
                                    {dataElem?.administrations?.length > 0
                                      ? moment(
                                          dataElem?.administrations?.[0]?.date
                                        ).format("MM/DD/YYYY")
                                      : "--"}
                                  </td>
                                  <td>
                                    {dataElem?.administrations?.length > 0 && (
                                      <div className="pic-cell d-inline-flex align-items-center">
                                        <div className="pic rounded-circle d-inline-flex overflow-hidden">
                                          {dataElem?.administrations?.[0]
                                            ?.created_by?.user_image ? (
                                            <img
                                              className="rounded-circle w-100 h-100"
                                              src={
                                                dataElem?.administrations?.[0]
                                                  ?.created_by?.user_image ||
                                                Icons.UserThumbline
                                              }
                                              alt="User"
                                            />
                                          ) : (
                                            <UserPlaceholder />
                                          )}
                                        </div>
                                        {`${
                                          dataElem?.administrations?.[0]
                                            ?.created_by?.title
                                            ? dataElem?.administrations?.[0]
                                                ?.created_by?.title + " "
                                            : ""
                                        } ${
                                          dataElem?.administrations?.[0]
                                            ?.created_by?.firstname
                                        } ${
                                          dataElem?.administrations?.[0]
                                            ?.created_by?.lastname
                                        }`}
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr className="no-data-rw">
                              <td colSpan="11">
                                <NodataFoundBlock />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      {vData?.data?.length > 0 && (
                        <PaginationBlock
                          limit={childLimit}
                          page={vaccinationPage[index] || 1}
                          pagingCounter={vaccinationPaging[index] || 1}
                          docLength={childLimit || 0}
                          docPage={vaccinationPage[index]}
                          totalPages={totalPages || 1}
                          totalDocs={vData?.data?.length || 0}
                          _handleLimit={(val) => {
                            setLimit(val);
                          }}
                          _handlePageChange={(e) => {
                            if (e > vaccinationPage[index]) {
                              const diff = e - vaccinationPage[index];
                              setVaccinationPaging((prev) => {
                                const updatedPaging = [...prev];
                                updatedPaging[index] =
                                  updatedPaging[index] + childLimit * diff;
                                return updatedPaging;
                              });
                            } else if (e < vaccinationPage[index]) {
                              const diff = vaccinationPage[index] - e;

                              setVaccinationPaging((prev) => {
                                const updatedPaging = [...prev];
                                updatedPaging[index] = Math.max(
                                  updatedPaging[index] - childLimit * diff,
                                  0
                                );
                                return updatedPaging;
                              });
                            }
                            setVaccinationPage((prevPages) => {
                              const updatedPages = prevPages?.map(
                                (page, pIndex) => (pIndex === index ? e : page)
                              );
                              return updatedPages;
                            });
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="table-one cmb-20">
                <div className="text-14-600 color-2E65 cmb-10">Patient</div>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Patient Info.</th>
                      <th>VRN</th>
                      <th>Status</th>
                      <th>Wellness Plan</th>
                      <th>Animal</th>
                      <th>Breed</th>
                      <th>Gender</th>
                      <th>Owner</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.loading && (
                      <tr className="no-data-rw">
                        <td
                          colSpan="8"
                          className="d-flex justify-content-center cpt-50 cpb-50"
                        >
                          <LoaderUI />
                        </td>
                      </tr>
                    )}
                    {!tableData?.loading &&
                    tableData?.data?.docs?.length > 0 ? (
                      tableData?.data?.docs?.map((dataElem, dataIndex) => {
                        const {
                          pet_name,
                          pet_image,
                          vrn,
                          active,
                          speciesDetails,
                          breedDetails,
                          gender,
                          petOwner,
                        } = dataElem;

                        return (
                          <tr key={dataIndex}>
                            <td className="text-nowrap">
                              {/* <div className="d-flex gap-2">
                                <div
                                  className="flex-shrink-0"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                >
                                  {pet_image ? (
                                    <img
                                      className="img-fluid w-100 h-100"
                                      src={pet_image}
                                      alt="Product"
                                      style={{
                                        borderRadius: "100px",
                                      }}
                                    />
                                  ) : (
                                    <PetPlaceholder />
                                  )}
                                </div>
                                <div>{titleCaseString(pet_name)}</div>
                              </div> */}
                              <Button
                                color="link"
                                className="p-0 text-start pic-cell d-inline-flex align-items-center w-100"
                                onClick={() => {
                                  handleProfileClick(dataElem);
                                }}
                              >
                                <div className="d-flex gap-2">
                                  <div
                                    className="flex-shrink-0"
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  >
                                    {pet_image ? (
                                      <img
                                        className="img-fluid w-100 h-100"
                                        src={pet_image}
                                        alt="Product"
                                        style={{
                                          borderRadius: "100px",
                                        }}
                                      />
                                    ) : (
                                      <PetPlaceholder />
                                    )}
                                  </div>
                                  <div>{titleCaseString(pet_name)}</div>
                                </div>
                              </Button>
                            </td>
                            <td>{vrn}</td>
                            <td className="text-nowrap">
                              {active && "Active"}
                            </td>
                            <td className="text-nowrap">
                              Golden care plan
                              {/* {titleCaseString(appointment_type)} */}
                            </td>
                            <td className="text-nowrap">
                              {titleCaseString(speciesDetails?.species_name)}
                            </td>
                            <td className="text-nowrap">
                              {titleCaseString(breedDetails?.breed)}
                            </td>
                            <td className="text-nowrap">
                              {titleCaseString(gender)}
                            </td>
                            <td className="text-nowrap">
                              <div className="d-flex gap-2">
                                <div
                                  className="flex-shrink-0"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                >
                                  {petOwner?.image ? (
                                    <img
                                      className="img-fluid w-100 h-100"
                                      src={petOwner?.image}
                                      alt="Product"
                                      style={{
                                        borderRadius: "100px",
                                      }}
                                    />
                                  ) : (
                                    <PetPlaceholder />
                                  )}
                                </div>
                                <div>
                                  {titleCaseString(petOwner?.owner_name)}
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="no-data-rw">
                        <td colSpan="11">
                          <NodataFoundBlock />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <PaginationBlock
                  limit={limit}
                  page={page}
                  pagingCounter={tableData?.data?.pagingCounter || 1}
                  docLength={tableData?.data?.docs?.length || 0}
                  docPage={tableData?.data?.page || 1}
                  totalPages={tableData?.data?.totalPages || 1}
                  totalDocs={tableData?.data?.totalDocs || 0}
                  _handleLimit={(val) => {
                    setLimit(val);
                  }}
                  _handlePageChange={setPage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientRecordsReport;
