import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";

toast.configure();
/**
 * Waiting Room Api endpoints
 * /settings/get-by-clinic-id/:clinic_id
*/

/**
 * Get waiting Rooms
 * @param {Object} params
 * @param {Function} callback
 * @returns 
 */
export const getFormAndTemplates = (params, callback) => {
    return (dispatch, getState) => {
        const { user: { token, user }, clinicReducer: { clinic_details } } = getState();
        if (clinic_details) {
            ApiClient.get(`${apiUrl}${PORT}/clinic-settings/get-forms-templates/${clinic_details?._id}`, params, token, dispatch, getState).then((response) => {

                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    dispatch({ type: "GET_FORM_TEMPLATES", payload: response?.response_data })
                    return callback(response);
                } else {
                    //toast.error(response.message, toasterConfig);
                    return callback(response);
                }
            })
        }
    };
};
