import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";
import { getClinicSettings } from "./clinicSettings";

toast.configure();
/**
 * 
 * @param {string} role
 * @param {Function} callback
 * @returns 
 */
export const AddNewRoleToClinicStuff = (data, callback) => {
    return (dispatch, getState) => {
        const { user: { token } } = getState();
        ApiClient.post(`${apiUrl}${PORT}/clinic-settings/add-role-permission`, data, token, dispatch, getState).then(response => {

            if (!response) {
                return callback();
            }
            else if (response?.status) {
                return callback(response);
            } else {
                // //toast.error(response.message, toasterConfig);
                return callback(response);
            }
        })
    };
};
export const updatePermission = (data, callback) => {
    return (dispatch, getState) => {
        const { user: { token } , clinicPermissionReducer :{allRolePermission}} = getState();
        ApiClient.put(`${apiUrl}${PORT}/clinic-settings/update`, data, token, dispatch , getState).then(response => {
            if (!response) {
                return callback();
            }
            else if (response?.status) {
                // dispatch({ type: "GET_CLINIC_PERMISSION", payload: { ...allRolePermission, [data.role_name]: data.permission } })
                return callback(response);
            } else {
                //toast.error(response.message, toasterConfig);
                return callback(response);
            }
        })
    };
};

export const getActiveRoles = (params, callback) => {
    return (dispatch, getState) => {
        const { user: { token } , clinicReducer : {clinic_details}} = getState();
        ApiClient.get(`${apiUrl}${PORT}/clinic-settings/get-all-role/${clinic_details?._id}`, params, token, dispatch, getState).then(
            (response) => {
                if (response && !response.errors) {
                    return callback(response);
                } else if (response?.code === 404) {
                    toast.warn(response.message);
                    return callback();
                } else {
                    toast.error(response.errors.msg, toasterConfig);
                    return callback();
                }
            }
        );
    };
};
