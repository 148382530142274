import { FieldArray, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
  Table,
} from "reactstrap";
import { medicationInit, medicationPlanSchema } from "./schema";
import { useState } from "react";

import MedicationSelection from "./MedicationSelection";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import {
  addNewOrder,
  getHomeMedicationOrders,
} from "../../../../../../../context/actions/patient-module/orders";

const MedicationPlanFormPanel = ({
  isActive,
  toggleMedicationPanel,
  medicationPlanToEdit,
}) => {
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.user.user_details);
  const params = useParams();
  const petId = params.petId;
  const [isSelectingMedication, setIsSelectingMedication] = useState(false);

  const submitForm = (values, { errors }) => {

    for (let i of values.medications) {
      let { product_name, product_id, order_type, product_description, ...order_details } =
        i;

      if (order_details?.first_dose) {
        order_details.first_dose = moment(order_details?.first_dose).format(
          "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
        );
      }
      let order = {
        ...order_details,
        created_by: userDetails._id,
        pet_id: petId,
        type: "home",
        order_type: order_type,
        item: product_id,
      };
      dispatch(
        addNewOrder(order, (result) => {
          dispatch(getHomeMedicationOrders({ pet_id: petId }, () => { }));
          toggleMedicationPanel();
        })
      );
    }
  };

  return (
    <>
      <div
        style={{ maxWidth: '60%' }}
        className={`medication-panel-wrapper fixed flex flex-col ${isActive && "active"
          }`}
      >
        <Formik
          initialValues={medicationInit}
          enableReinitialize
          validationSchema={medicationPlanSchema}
          onSubmit={submitForm}
        >
          {(formik) => (
            <Form className="max-h-[95%] overflow-y-auto overflow-x-hidden">
              <FieldArray name="medications">
                {({ remove, push }) => (
                  <>
                    {isSelectingMedication && (
                      <MedicationSelection
                        selectMedicationFn={(values) => {
                          push(values);
                          setIsSelectingMedication(false);
                        }}
                        closePanel={toggleMedicationPanel}
                      />
                    )}

                    {!isSelectingMedication && (
                      <div className="head d-flex flex-column align-items-center">
                        <div className="d-flex flex-row w-100">
                          <h2>Add Medication Plan</h2>
                          <Button
                            color="link"
                            className="ms-auto panel-close-btn"
                            onClick={toggleMedicationPanel}
                          >
                            <span className="material-symbols-outlined">
                              close
                            </span>
                          </Button>
                        </div>
                      </div>
                    )}

                    <div
                      style={{
                        display: isSelectingMedication ? "none" : "flex",
                      }}
                      className="flex-column gap-2"
                    >
                      {formik?.values?.medications?.length > 0 &&
                        formik?.values?.medications?.map(
                          (medication, index) => (
                            <>
                              <div className="w-full rounded-lg bg-stone-50/90 py-[10px] px-[15px] inline-flex gap-[30px]">
                                <div className="max-w-[400px] w-full">
                                  <div className="flex px-4 py-3 bg-white rounded-lg border border-indigo-950 border-opacity-10 justify-start items-center gap-2 mb-2 ">
                                    <div className="grow  text-base font-normal">
                                      {medication.product_name}
                                    </div>
                                    <div className="justify-center items-center gap-2.5 flex">
                                      <div className="w-[52px] text-right text-indigo-600 text-sm font-normal font-['Inter'] leading-[21px]">
                                        Change
                                      </div>
                                    </div>
                                  </div>
                                  <div className="self-stretch text-slate-500 text-sm font-normal font-['Inter'] leading-[21px]">
                                    {medication.product_description}
                                  </div>

                                  {formik.errors.medications?.[index]?.dose && (
                                    <div className="flex w-full mt-4 items-center gap-2">
                                      <img
                                        className="img-fluid w-6 h-6"
                                        src={
                                          require("../../../../../../../assets/img/alert.svg")
                                            .default
                                        }
                                        alt="Badge"
                                      />
                                      {formik.errors.medications?.[index].dose}
                                    </div>
                                  )}

                                  {formik.errors.medications?.[index]
                                    ?.route && (
                                      <div className="flex w-full mt-4 items-center gap-2">
                                        <img
                                          className="img-fluid w-6 h-6"
                                          src={
                                            require("../../../../../../../assets/img/alert.svg")
                                              .default
                                          }
                                          alt="Badge"
                                        />
                                        {formik.errors.medications?.[index].route}
                                      </div>
                                    )}

                                  {formik.errors.medications?.[index]
                                    ?.order_type && (
                                      <div className="flex w-full mt-4 items-center gap-2">
                                        <img
                                          className="img-fluid w-6 h-6"
                                          src={
                                            require("../../../../../../../assets/img/alert.svg")
                                              .default
                                          }
                                          alt="Badge"
                                        />
                                        {formik.errors.medications?.[index].order_type}
                                      </div>
                                    )}
                                </div>

                                <div className="grow flex flex-col justify-center items-start">
                                  <Row className="flex mb-2">
                                    <div className="row-block flex items-center w-full">
                                      <label
                                        for={`medications.${index}.dose`}
                                        className=" w-36 mr-4 text-sm"
                                      >
                                        Dose Information
                                      </label>
                                      <div className="right-col flex-fill">
                                        <InputGroup>
                                          <Input
                                            id={`medications.${index}.dose`}
                                            type="number"
                                            min="1"
                                            onChange={(e) => {
                                              formik.handleChange(e);
                                            }}
                                            placeholder={"Add Info"}
                                          />
                                          <Input
                                            id={`medications.${index}.form`}
                                            placeholder="Select Unit"
                                            onChange={(e) => {
                                              formik.handleChange(e);
                                            }}
                                            value={
                                              formik.values.medications?.[index]
                                                .form
                                            }
                                            type="select"
                                          >
                                            <option>Select Form</option>
                                            <option>mL</option>
                                            <option>L</option>
                                            <option>tablet</option>
                                            <option>capsule</option>
                                            <option>mg</option>
                                            <option>g</option>
                                            <option>patch</option>
                                            <option>spray</option>
                                            <option>drop</option>
                                          </Input>
                                        </InputGroup>
                                      </div>
                                    </div>
                                  </Row>
                                  <Row className="flex mb-2">
                                    <div className="row-block flex items-center w-full">
                                      <label
                                        for={`medications.${index}.route`}
                                        className="w-36 mr-4 text-sm"
                                      >
                                        Route
                                      </label>
                                      <div className="right-col flex-fill">
                                        <Input
                                          id={`medications.${index}.route`}
                                          placeholder="Select Route"
                                          onChange={(e) => {
                                            formik.handleChange(e);
                                          }}
                                          value={
                                            formik.values.medications?.[index]
                                              .route
                                          }
                                          type="select"
                                        >
                                          <option>Select Route</option>
                                          <option>IM</option>
                                          <option>IV</option>
                                          <option>IV Push</option>
                                          <option>PO</option>
                                          <option>SubQ</option>
                                          <option>Rectal</option>
                                          <option>Inhalation</option>
                                          <option>Nasal</option>
                                          <option>Ocular</option>
                                          <option>Otic</option>
                                          <option>Topical</option>
                                          <option>Transdermal</option>
                                        </Input>
                                      </div>
                                    </div>
                                  </Row>

                                  <Row className="flex mb-2">
                                    <div className="row-block flex items-center w-full">
                                      <label
                                        for={`medications.${index}.frequency`}
                                        className="w-36 mr-4 text-sm"
                                      >
                                        Intake Frequency
                                      </label>
                                      <div className="right-col flex-fill">
                                        <Input
                                          id={`medications.${index}.frequency`}
                                          placeholder="Select Frequency"
                                          onChange={(e) => {
                                            formik.handleChange(e);
                                          }}
                                          value={
                                            formik.values.medications?.[index]
                                              .frequency
                                          }
                                          type="select"
                                        >
                                          <option>Select Frequency</option>
                                          <option>q5m</option>
                                          <option>q15m</option>
                                          <option>q30m</option>
                                          <option>q2h</option>
                                          <option>q4h</option>
                                          <option>q6h</option>
                                          <option>q8h</option>
                                          <option>q12h</option>
                                          <option>q24h</option>
                                          <option>daily</option>
                                          <option>daily at bedtime</option>
                                          <option>BID</option>
                                          <option>TID</option>
                                          <option>QID</option>
                                          <option>every other day</option>
                                          <option>once</option>
                                        </Input>
                                      </div>
                                    </div>
                                  </Row>

                                  <Row className="flex mb-2">
                                    <div className="row-block flex items-center w-full">
                                      <label
                                        for={`medications.${index}.duration_num`}
                                        className="w-36 mr-4 text-sm"
                                      >
                                        Duration
                                      </label>
                                      <div className="right-col flex-fill">
                                        <InputGroup>
                                          <Input
                                            id={`medications.${index}.duration_num`}
                                            type="number"
                                            min="1"
                                            placeholder="Add Duration"
                                            onChange={(evt) => {
                                              formik.handleChange(evt);
                                            }}
                                            value={
                                              formik.values.medications?.[index]
                                                .duration_num
                                            }
                                          />
                                          <Input
                                            id={`medications.${index}.duration_unit`}
                                            placeholder="Select Duration"
                                            onChange={(evt) => {
                                              formik.handleChange(evt);
                                            }}
                                            value={
                                              formik.values.medications?.[index]
                                                .duration_unit
                                            }
                                            type="select"
                                          >
                                            <option>Select Unit</option>
                                            <option>Days</option>
                                            <option>Weeks</option>
                                          </Input>
                                        </InputGroup>
                                      </div>
                                    </div>
                                  </Row>

                                  <Row className="flex mb-2">
                                    <div className="row-block flex items-center w-full">
                                      <label
                                        for={`medications.${index}.first_dose`}
                                        className="w-36 mr-4 text-sm"
                                      >
                                        First Dose
                                      </label>
                                      <div className="right-col flex-fill">
                                        <Input
                                          id={`medications.${index}.first_dose`}
                                          value={
                                            formik.values.medications?.[index]
                                              .first_dose
                                          }
                                          onChange={(event) => {
                                            formik.handleChange(event);
                                          }}
                                          type="datetime-local"
                                        />
                                      </div>
                                    </div>
                                  </Row>

                                  <Row className="flex mb-2">
                                    <div className="row-block flex items-center w-full">
                                      <label
                                        for={`medications.${index}.prn`}
                                        className="w-36 mr-4 text-sm"
                                      >
                                        PRN
                                      </label>
                                      <div className="right-col flex-fill">
                                        <Input
                                          id={`medications.${index}.prn`}
                                          value={
                                            formik.values.medications?.[index]
                                              .prn
                                          }
                                          onChange={(event) => {
                                            formik.handleChange(event);
                                          }}
                                          type="checkbox"
                                        />
                                      </div>
                                    </div>
                                  </Row>

                                  <Row className="flex mb-2">
                                    <div className="row-block flex items-center w-full">
                                      <label
                                        for={`medications.${index}.notes`}
                                        className="w-36 mr-4 text-sm"
                                      >
                                        Notes
                                      </label>
                                      <div className="right-col flex-fill">
                                        <textarea
                                          onChange={(e) =>
                                            formik.handleChange(e)
                                          }
                                          className="resize-y rounded-md outline outline-1 outline-[#0909541F] p-2"
                                          rows="2"
                                          cols="40"
                                          id={`medications.${index}.notes`}
                                          name={`medications.${index}.notes`}
                                        />
                                      </div>
                                    </div>
                                  </Row>
                                  <Row className="flex mb-2">
                                    <div className="row-block flex items-center w-full">
                                      <label
                                        for={`medications.${index}.order_type`}
                                        className="w-36 mr-4 text-sm"
                                      >
                                        Order Type
                                      </label>
                                      <div className="right-col flex-fill">
                                        <Input
                                          id={`medications.${index}.order_type`}
                                          placeholder="Select order type"
                                          onChange={(e) => {
                                            formik.handleChange(e);
                                          }}
                                          value={
                                            formik.values.medications?.[index]
                                              .order_type
                                          }
                                          type="select"
                                        >
                                          <option>Select order type</option>
                                          <option value="in-house">In House</option>
                                          <option value="e-prescribe">E-prescribe</option>
                                          <option value="print-script">Print-script</option>
                                        </Input>
                                      </div>
                                    </div>
                                  </Row>
                                </div>

                                <div style={{ minWidth: '20px' }} className=" relative">
                                  <button
                                    className="hover:drop-shadow-md"
                                    onClick={() => remove(index)}
                                  >
                                    <img
                                      className="img-fluid w-100 h-100"
                                      src={
                                        require("../../../../../../../assets/img/delete_gray.svg")
                                          .default
                                      }
                                      alt="Badge"
                                    />
                                  </button>
                                </div>
                              </div>
                            </>
                          )
                        )}
                    </div>

                    {!isSelectingMedication && (
                      <Row className="my-4">
                        <div className="w-full">
                          <Button
                            onClick={() => {
                              setIsSelectingMedication(true);
                            }}
                            color="link"
                            className="add-section-medication-btn d-flex justify-content-center w-full"
                          >
                            <span className="icomoon-plus-btn"></span>
                          </Button>
                        </div>
                      </Row>
                    )}
                  </>
                )}
              </FieldArray>
              {!isSelectingMedication && (
                <div className="flex w-full justify-end">
                  <Button type="submit" color="success" className="w-[150px] ">
                    Save
                  </Button>
                </div>
              )}
            </Form>
          )}
        </Formik>

        {/* <div className="head d-flex align-items-center">
          <h2>Create New Medication Plan</h2>
          <Button
            color="link"
            className="ms-auto panel-close-btn"
            onClick={() => {
              toggleMedicationPanel();
            }}
          >
            <span className="material-symbols-outlined">close</span>
          </Button>
        </div> */}
      </div>
    </>
  );
};

export default MedicationPlanFormPanel;
