import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Popover,
  PopoverBody,
  Input,
  Modal,
  ModalBody,
} from "reactstrap";
import {
  getPetDetailsByIdNoLoading,
  removeAllergy,
  updateAllergy,
} from "../../../context/actions/patient-module/patient/pet";

import { addAllergy } from "../../../context/actions/patient-module/patient/pet";
import { useFormik } from "formik";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./index.scss";
import { getAllergies } from "../../../context/actions/allergies";
import { CiCircleMinus } from "react-icons/ci";

const ProfileAllergy = ({ showRemoveIcon, target }) => {

  const { permissionsData } = useSelector((state) => state.clinicPermissionReducer);
  const permissions = permissionsData;
  const [popover, setPopover] = useState(false);
  const [allergyValue, setAllergyValue] = useState("");
  const [reactionValue, setReactionValue] = useState(false);
  const [reactionsValue, setReactionsValue] = useState("");
  const [notesValue, setNotesValue] = useState("");

  const [severityValue, setSeverityValue] = useState(false);
  const [severityItem, setSeverityItem] = useState({});
  const [isAllergyAdd, setIsAllergyAdd] = useState(false);
  const [allergyTagData, setAllergyTagData] = useState([]);
  const [originalId, setOriginalId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const dispatch = useDispatch();

  const popoverToggle = () => {
    setPopover(!popover);
  };

  const { petDetails } = useSelector((state) => state.petReducer);

  const allAllergies = useSelector((state) => state?.allergyReducer?.allergies);

  useEffect(() => {
    dispatch(getAllergies((res) => {}));
  }, []);

  const formik = useFormik({
    initialValues: {
      allergy: "",
      reaction: "",
    },
    // onSubmit: (values, { resetForm }) => {
    //   console.log(values);

    //   dispatch(
    //     addAllergy(
    //       {
    //         pet_id: petDetails._id,
    //         allergy: values.allergy || "",
    //         reaction: values.reaction,
    //       },
    //       (response) => {
    //         if (response?.status) {
    //           dispatch(getPetDetailsById(petDetails._id, {}, () => {}));
    //         }
    //       }
    //     )
    //   );
    //   setPopover(false);
    //   resetForm();
    // },
  });

  const handleAddAllergy = () => {
    dispatch(
      addAllergy(
        {
          pet_id: petDetails._id,
          allergy: allergyValue,
          reaction: reactionsValue,
          notes: notesValue,
        },
        (response) => {
          if (response?.status) {
            dispatch(
              getPetDetailsByIdNoLoading(petDetails._id, {}, () => {
                // popoverToggle();
                setIsAllergyAdd(false);
                setPopover(false);
                setAllergyValue("");
                setReactionsValue("");
                setNotesValue("");
              })
            );
          }
        }
      )
    );
  };

  const handleRemoveAllergy = (item) => {
    console.log("react", item);
    // Dispatch an action to remove the allergy item by its unique ID
    dispatch(
      removeAllergy(
        {
          pet_id: petDetails._id,
          allergy: item.allergy,
          reaction: item.reaction,
          notes: "",
        },
        (response) => {
          if (response?.status) {
            dispatch(getPetDetailsByIdNoLoading(petDetails._id, {}, () => {}));
          }
        }
      )
    );
  };
  const [isMore, setIsMore] = useState(false);

  const handleUpdateText = (data) => {
    setAllergyTagData([...allergyTagData, { ...severityItem, tageName: data }]);
    // tempAllergytags.push({...allergytags,tageName:data})
    // setIsAllergyAdd(false)
  };

  const handleCancelAllergy = () => {
    setAllergyValue("");
    setReactionsValue("");
    setNotesValue("");
    setIsEdit(false);
  };

  const editAllergy = (elem) => {
    setAllergyValue(elem?.allergy);
    setReactionsValue(elem?._id);
    setNotesValue(elem?.notes);
    setOriginalId(elem?.originalId);
    setIsAllergyAdd(true);
    setIsEdit(true);
  };

  const handleUpdateAllergy = () => {
    dispatch(
      updateAllergy(
        {
          pet_id: petDetails._id,
          allergy: allergyValue,
          reaction: reactionsValue,
          notes: notesValue,
          tag_id: originalId,
        },
        (response) => {
          if (response?.status) {
            dispatch(
              getPetDetailsByIdNoLoading(petDetails._id, {}, () => {
                // popoverToggle();
                setIsAllergyAdd(false);
                setPopover(false);
                setAllergyValue("");
                setReactionsValue("");
                setNotesValue("");
                setIsEdit(false);
              })
            );
          }
        }
      )
    );
  };

  return (
    <div className="profile-allergy row-block">
      <div className="head section-head d-flex align-items-center mb-2">
        <div className="left-col">Allergies</div>
      
        {!showRemoveIcon && (
          
            (permissions?.edit_patients_details && permissions?.add_allergy) && <div className="right-col ms-auto">
            <Button
              id={target}
              onClick={() => {
                isAllergyAdd ? setIsAllergyAdd(false) : setIsAllergyAdd(true);
              }}
              color="link"
              className="add-btn d-inline-flex"
            >
              <span className="icomoon-plus-btn"></span>
            </Button>
            {/* <Popover
              placement="bottom-end"
              isOpen={popover}
              toggle={popoverToggle}
              target={target}
            >
              <PopoverBody className={"new-tag"}>
                <Input
                  placeholder="Add Allergy"
                  onChange={(event) => {
                    setAllergyValue(event?.target?.value);
                  }}
                />
                <Input
                  placeholder="Add Reaction"
                  onChange={(event) => {
                    setReactionValue(event?.target?.value);
                  }}
                />
                <div className="btn-cell d-flex">
                  <Button
                    color="primary"
                    onClick={handleAddAllergy}
                    className="flex-fill"
                  >
                    Add Allergy
                  </Button>
                </div>
              </PopoverBody>
            </Popover> */}
            {/* <Modal
              placement="bottom-end"
              isOpen={popover}
              toggle={() => setPopover(false)}
              target="add-allergy"
            >
              <ModalBody className={"new-tag"}>
                <form onSubmit={formik.handleSubmit}>
                  <Input
                    placeholder="Add Allergy"
                    name="allergy"
                    value={formik.values.allergy}
                    onChange={formik.handleChange}
                  />
                  <Input
                    placeholder="Add Reaction"
                    name="reaction"
                    value={formik.values.reaction}
                    onChange={formik.handleChange}
                  />
                  <div className="btn-cell d-flex">
                    <Button type="submit" color="primary" className="flex-fill">
                      Add Allergy
                    </Button>
                  </div>
                </form>
              </ModalBody>
            </Modal> */}
          </div>
        )}
          
          
      </div>
      <div className="allergy-overview">
        <div className="d-flex gap-2 flex-wrap w-fit">
          {petDetails?.petCareInfo?.allergies?.length > 0 &&
            petDetails?.petCareInfo?.allergies?.map((elem, index) => {
              return (
                <div className="d-flex gap-1">
                  <span
                    key={index}
                    className={`text-center mb-1 ps-1 pe-1 ${
                      elem?.severity === "Low"
                        ? "bg-teal-500 text-white rounded-sm"
                        : elem?.severity === "Moderate"
                        ? "bg-yellow-500 text-white rounded-sm"
                        : elem?.severity === "High"
                        ? "bg-orange-500  text-white rounded-sm"
                        : ""
                    }`}
                    onClick={() => (!showRemoveIcon ? editAllergy(elem) : "")}
                  >
                    {elem?.allergy}: {elem?.tag || "--"}
                  </span>
                  {showRemoveIcon && (
                    <CiCircleMinus
                      color="red"
                      size="22px"
                      onClick={() => handleRemoveAllergy(elem)}
                    />
                  )}
                  {/* {showRemoveIcon && (
                    <button
                      type="button"
                      color="link"
                      className="bg-green-400 rounded-full text-white justify-content-center me-2 cancel-allergy-btn"
                      onClick={() => handleRemoveAllergy(elem)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-dash-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                      </svg>
                    </button>
                  )} */}
                </div>
              );
            })}
        </div>

        {/* {isAllergyAdd && (
            <ProfileAddAllergyPopup
              onHide={() => {
                setIsAllergyAdd(false);
              }}
              severityItem={severityItem}
              setAllergyTagData={setAllergyTagData}
              setSeverityItem={setSeverityItem}
              handleUpdateText={handleUpdateText}
              allergyTagData={allergyTagData}
            />
            // <div className="add-allergy mt-3">
            //   <div>
            //     <div className="reactions-add d-flex gap-2 align-items-center">
            //       <div className="d-flex gap-1">
            //         <span>Reactions</span>
            //         <span style={{ color: "red" }}>*</span>
            //       </div>
            //       <Input
            //         className="reactions-select-dropdown"
            //         type="select"
            //         name="select"
            //         placeholder="Select value"
            //         value={reactionsValue}
            //         onChange={(e) => {
            //           setReactionsValue(e?.target?.value);
            //         }}
            //       >
            //         <option value="">Select</option>
            //         {reactionsOptions?.map((item, i) => (
            //           <option key={i} value={item?.value}>
            //             {item?.value}
            //           </option>
            //         ))}
            //       </Input>
            //     </div>
            //   </div>

            //   <div className="reactions-add d-flex gap-2 align-items-center mt-3">
            //     <div className="d-flex gap-1">
            //       <span>Severity</span>
            //       <span style={{ color: "red" }}>*</span>
            //     </div>
            //     <div className="severity-text d-flex gap-1">
            //       {severity?.map((elem, index) => {
            //         const { severityName, tagClassName, id } = elem;
            //         return (
            //           <span
            //             className={`d-flex gap-2 align-items-center1 ${tagClassName}`}
            //             key={index}
            //             id={id}
            //             onClick={() => {
            //               setSeverityItem(elem);
            //             }}
            //           >
            //             {severityName}{" "}
            //             {id === severityItem?.id && (
            //               <BsCheckLg style={{ color: "white" }} />
            //             )}
            //           </span>
            //         );
            //       })}
            //     </div>
            //   </div>

            //   <div className="d-flex gap-2 mt-3">
            //     <BsFillTrashFill
            //       style={{ color: "red", cursor: "pointer" }}
            //       size={25}
            //       onClick={() => {
            //         setReactionsValue("");
            //         setSeverityItem({});
            //       }}
            //     />
            //     <BsFillCheckCircleFill
            //       style={{ color: "blueviolet", cursor: "pointer" }}
            //       size={25}
            //       onClick={() => {
            //         handleUpdateText(reactionsValue);
            //         setReactionsValue("");
            //         setSeverityItem({});
            //       }}
            //     />
            //   </div>
            // </div>
          )} */}
      </div>
      {isAllergyAdd && (
        <Offcanvas
        style={{minWidth: '30%', maxWidth: '30%'}}
          show
          backdrop="static"
          placement="end"
          className="select-group-list-right"
          onHide={() => {
            setIsAllergyAdd(false);
            setAllergyValue("");
            setReactionsValue("");
            setNotesValue("");
            setIsEdit(false);
          }}
        >
          <Offcanvas.Header closeButton className="p-2">
            <Offcanvas.Title>
              {isEdit ? "Edit" : "Add"} New Allergy
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="px-2 pb-2 pt-0">
            <div className="mb-2 group-add-name">
              <div> Allergy Information & Reaction</div>
              <div className="mt-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Allergy Name"
                  onChange={(e) => setAllergyValue(e?.target?.value)}
                  value={allergyValue}
                />
              </div>
              <div className="mt-2">
                <Input
                  className="reactions-select-dropdown text-gray-500"
                  type="select"
                  name="select"
                  placeholder="Please Select Reaction"
                  value={reactionsValue}
                  onChange={(e) => {
                    setReactionsValue(e?.target?.value);
                  }}
                >
                  <option value="">Please Select Reaction</option>
                  {allAllergies?.map((allergy, i) => {
                    return (
                      <>
                        <option key={i} value={allergy?._id}>
                          {allergy?.tag}
                        </option>
                      </>
                    );
                  })}
                </Input>
              </div>

              <div className="mt-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Notes (Optional)"
                  onChange={(e) => setNotesValue(e?.target?.value)}
                  value={notesValue}
                />
              </div>

              <div className="d-flex justify-content-end gap-3 mt-3">
                <button
                  className=" w-[40%] cancel-allergy-btn"
                  onClick={handleCancelAllergy}
                >
                  Cancel
                </button>

                <button
                  style={{ backgroundColor: "#11ca9d" }}
                  className="w-[40%] btn btn-success"
                  onClick={
                    showRemoveIcon ? handleUpdateAllergy : handleAddAllergy
                  }
                >
                  SAVE
                </button>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </div>
  );
};

export default ProfileAllergy;
