import React from "react";
import "./index.scss";
import { formatPhoneNumber } from "utils/common";

const AddressBlock = ({ allContactsInfo, className = '' }) => {
	const contactInfo = allContactsInfo[0];
	return (
		<>
			<div className={`adress-blc d-flex ${className}`}>
				<div className="adress-info">
					<p>
						<label>Address line 1</label>
						<span>{contactInfo?.line1 ? contactInfo?.line1 : '-'}</span>
					</p>
					<p>
						<label>Address line 2</label>
						<span>{contactInfo?.line2 ? contactInfo?.line2 : '-'}</span>
					</p>
					<p>
						<label>City/Town</label>
						<span>{contactInfo?.city ? contactInfo?.city : '-'}</span>
					</p>
					<p>
						<label>State/Province/Region</label>
						<span>{contactInfo?.state ? contactInfo?.state : '-'}</span>
					</p>
					<p>
						<label>Zip/Postal Code</label>
						<span>{contactInfo?.zipcode ? contactInfo?.zipcode : '-'}</span>
					</p>
					<p>
						<label>Landmark</label>
						<span>{contactInfo?.landmark ? contactInfo?.landmark : '-'}</span>
					</p>
					<p>
						<label>Phone Number</label>
						<span>{ formatPhoneNumber(contactInfo?.phone) ? formatPhoneNumber(contactInfo?.phone) : '-'}</span>
					</p>
				</div>
				<div className="adres-map">
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3307.828454412587!2d-118.43495868571497!3d33.9969397806211!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2ba66da63de3d%3A0xd6e0cd18b1c05e13!2s12613%20W%20Washington%20Blvd%2C%20Los%20Angeles%2C%20CA%2090066%2C%20USA!5e0!3m2!1sen!2sin!4v1668582779128!5m2!1sen!2sin"
						width={600}
						height={450}
						style={{ border: 0 }}
						allowFullScreen=""
						loading="lazy"
						referrerPolicy="no-referrer-when-downgrade"
						title="address-map"
					/>
				</div>
			</div>
		</>
	);
};

export default AddressBlock;
