import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import { faCamera } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getPetDetailsById,
  getPetDetailsByPetId,
  updatePetImage,
} from "../../../context/actions/patient-module/patient/pet";
import UploadImageBlock from "../../UploadImageBlock";
import moment from "moment";
import {
  Button,
  Form,
  FormGroup,
  // FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import PetPlaceholder from "../../PetPlaceholder"; // eslint-disable-line
import "./index.scss";
// import { MdPets } from "react-icons/md";
import { useFormik } from "formik";
import {
  // getPetDetailsById,
  updatePetdetails,
} from "../../../context/actions/patient-module/patient/pet";
// import { useParams } from "react-router-dom";
import ProfileAllergy from "../ProfileAllergy";
// import ProfileTags from "../ProfileTags";
import {
  getAllBreeds,
  getAllSpecies,
} from "../../../context/actions/app-content";
import ProfileTagsEdit from "../ProfileTags/index-edit";
// import ProfileAllergyEdit from "../ProfileAllergy/index-edit";
import { getActiveAppointmentsByPatientId } from "../../../context/actions/appointment";
import { defaultDischargePatientFormData } from "../../../context/reducers/dischargePatientReducer";
import { AddNewDischargePatientForm } from "../../../context/actions/patient-module/discharge-patient-form";
import {
  addNewVitalToggles,
  // fetchVitalsTogglesForDischargPatientNavigator,
} from "../../../context/actions/vital-toggle";
import HealthSnapshotOffcanvas from "../HealthSnapshotOffcanvas/HealthSnapshotOffcanvas";
import Spinner from "shared/Spinner";
import { BsChevronRight } from "react-icons/bs";

const TimeCounter = ({ fromDateTime }) => {
  const [now, setNow] = useState(moment());

  moment.updateLocale('en', {
    relativeTime: {
         future: "in %s",
         past:   "%s ago",
         s:  "a few seconds",
         ss:  "%d seconds",
         m:  "1 minute",
         mm: "%d minutes",
         h:  "1 hour",
         hh: "%d hours",
         d:  "1 day",
         dd: "%d days",
         M:  "1 month",
         MM: "%d months",
         y:  "1 year",
         yy: "%d years"
   }
 })
  useEffect(() => {
    setInterval(() => {
      setNow(moment());
    }, 1000);
  }, []);

  return (
    <span>
      {moment
        .duration(now.diff(moment(fromDateTime)))
        .humanize()}
        
    </span>
  );
};
const PatientHead = () => {
  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  const { petDetails } = useSelector((state) => state.petReducer);
  const { data: appointmentDetails } = useSelector(
    (state) => state.appointmentDetailPanelReducer
  );
  const { breeds } = useSelector((state) => state.appContentReducer);
  // const params = useParams();
  // const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [activeAppointments, setActiveAppointments] = useState([]);
  const [petImage, setPetImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [isHealthSnapShot, setIsHealthSnapShot] = useState(false);
  const handleClose = () => setIsHealthSnapShot(false);
  const handleShow = () => setIsHealthSnapShot(true);

  const { user } = useSelector((state) => state.user);
  const userDetails = useSelector((state) => state.user.user_details);

  const { clinic_details } = useSelector((state) => state.clinicReducer);

  const [state, setState] = useState({
    dob: moment(petDetails?.dob).format("YYYY-MM-DD"),
    rabies_number: petDetails?.rabies_number,
    license_number: petDetails?.license_number,
    microchip_number: petDetails?.microchip_number,
    cpr: petDetails?.cpr,
    gender: petDetails?.gender,
    breed: petDetails?.breed?._id,
    color: petDetails?.color,
    allergy_tag: petDetails?.petCareInfo?.allergies
      ? petDetails?.petCareInfo?.allergies?.map((item) => item?.reaction)
      : [],
    behaviour_tag: petDetails?.petCareInfo?.behaviour_tags?.map(
      (item) => item?.tag
    ),
    deceased: petDetails?.deceased,
    active: petDetails?.active,
    id: petDetails?._id,
  });

  const handleRedirection = () => {
    history.goBack();
    dispatch({ type: "GET_PET_DETAILS", payload: false });
    /*
    if (location.pathname.includes("add-new-appointment")) {
      history.push("/appointments");
    }*/
  };

  const handleGetActiveAppointments = () => {
    if (petDetails) {
      dispatch(
        getActiveAppointmentsByPatientId(petDetails?._id, (res) => {
          if (res) {
            setActiveAppointments(res);
          }
        })
      );
    }
  };
  useEffect(() => {
    if (petImage) {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
      setImageUrl((p) => (p = URL.createObjectURL(petImage)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petImage]);

  useEffect(() => {
    setState({
      dob: moment(petDetails?.dob).format("YYYY-MM-DD"),
      rabies_number: petDetails?.rabies_number,
      license_number: petDetails?.license_number,
      microchip_number: petDetails?.microchip_number,
      cpr: petDetails?.cpr,
      gender: petDetails?.gender,
      breed: petDetails?.breed?._id,
      color: petDetails?.color,
      allergy_tag: petDetails?.petCareInfo?.allergies?.map(
        (item) => item?.reaction
      ),
      behaviour_tag: petDetails?.petCareInfo?.behaviour_tags?.map(
        (item) => item?.tag
      ),
      deceased: petDetails?.deceased,
      active: petDetails?.active,
      id: petDetails?._id,
    });

    formik.setValues({
      dob: moment(petDetails?.dob).format("YYYY-MM-DD"),
      rabies_number: petDetails?.rabies_number,
      license_number: petDetails?.license_number,
      microchip_number: petDetails?.microchip_number,
      cpr: petDetails?.cpr,
      gender: petDetails?.gender,
      breed: petDetails?.breed?._id,
      color: petDetails?.color,
      allergy_tag: petDetails?.petCareInfo?.allergies?.map(
        (item) => item?.reaction
      ),
      behaviour_tag: petDetails?.petCareInfo?.behaviour_tags?.map(
        (item) => item?.btag
      ),
      deceased: petDetails?.deceased,
      active: petDetails?.active,
      id: petDetails?._id,
    });

    handleGetActiveAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petDetails]);

  useEffect(() => {
    handleGetActiveAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentDetails]);

  useEffect(() => {
    console.log('pet profile details', petDetails)
    dispatch(
      getAllSpecies({}, (res) => {
        if (res?.status) {
          if (petDetails?.species) {
            dispatch(getAllBreeds(petDetails?.species?._id, () => {}));
          }
        }
      })
    );
    // handleGetActiveAppointments();

    // setInterval(() => {
    //   setNow(moment());
    // }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petDetails]);
  function formatDate(date) {
    const day = date.getDate().toString().padStart(2, "0"); // Get day and pad with leading zero if necessary
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get month (months are 0-indexed) and pad with leading zero if necessary
    const year = date.getFullYear().toString(); // Get full year

    return `${month}/${day}/${year}`;
  }
  const formik = useFormik({
    initialValues: state,

    onSubmit: (values, { resetForm }) => {
      console.log("values", formatDate(new Date(values.dob)));
      setIsLoading(true);
      for (var propName in values) {
        if (
          values[propName] === null ||
          values[propName] === undefined ||
          values[propName] === ""
        ) {
          delete values[propName];
        }
        if (propName === "dob") {
          values[propName] = moment(values[propName]).toDate();
        }
      }
      let payload = {
        ...values,
        //dob: new Date(values.dob).toISOString(),
      };
      dispatch(
        updatePetdetails(payload,  (response) => {
          console.log("response=====>", response);
          if(response){
            dispatch(getPetDetailsById(petDetails?._id, {}, () => {}));

          }
        })
      );

      if (petImage) {
        let petFormData = new FormData();
        petFormData.append("pet", petDetails?._id);
        petFormData.append("pet_image", petImage, petImage?.name);
        dispatch(
          updatePetImage(petFormData, (response) => {
            if (response) {
              dispatch(getPetDetailsById(petDetails?._id, {}, () => {}));
            }
          })
        );
      } else {
        setShow(false);
      }
      setIsLoading(false);
    },
  });

  const createDischargePatientForm = () => {
    // console.log("defaultDischargePatientFormData", defaultDischargePatientFormData)
    if (
      defaultDischargePatientFormData &&
      clinic_details &&
      activeAppointments?.length > 0
    ) {
      dispatch(
        AddNewDischargePatientForm(
          {
            ...defaultDischargePatientFormData,
            order_for_discharge:
              defaultDischargePatientFormData.order_for_discharge.map((ele) => {
                delete ele._id;
                return ele;
              }),
            clinic_id: clinic_details._id,
            appointment_id: activeAppointments[0]._id,
            pet_id: petDetails._id,
            added_by: user._id,
          },
          (response) => {
            console.log("response----->>>>>>", response);
            if (response?.status) {
              dispatch(
                addNewVitalToggles(
                  {
                    discharge_patient_form_id: response.response_data._id,
                    created_by: userDetails._id,
                  },
                  (res) => {
                    // console.log("res----", res);
                    if (res.status) {
                      delete res.response_data.createdAt;
                      delete res.response_data.updatedAt;
                      dispatch({
                        type: "UPDATE_VITALS_TOGGLES",
                        payload: { ...res.response_data },
                      });

                      history.push("/dischargePatient", {
                        pet_id: petDetails._id,
                        appointmentDetails: activeAppointments[0],
                      });
                    }
                  }
                )
              );
            } else {
            }
          }
        )
      );
    }
  };
  let totalAmount = 0;
  petDetails?.invoiceDetails?.forEach((elem) => {
    totalAmount += elem?.totalAmount || 0;
  });
  // console.log("appointmentDetails", appointmentDetails)
  // useEffect(() => {

  //   dispatch(getPetDetailsById(petDetails.petId, {}, () => {}));

  // }, []);

  return (
    <>
      <HealthSnapshotOffcanvas show={isHealthSnapShot} close={handleClose} />

      <div className="profile-head">
        <div className="title d-flex justify-content-between">
          <Button
            color="link"
            className="back-btn ps-0"
            onClick={handleRedirection}
          >
            Go Back
          </Button>
          <div class="btn-blc d-inline-flex align-items-center">
            <Button color="link pe-0" className="view-user-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  fill="#282E65"
                  d="M12 9.225c.767 0 1.417-.267 1.95-.8.533-.533.8-1.183.8-1.95 0-.767-.267-1.417-.8-1.95a2.653 2.653 0 00-1.95-.8c-.767 0-1.417.267-1.95.8a2.654 2.654 0 00-.8 1.95c0 .767.267 1.417.8 1.95.533.533 1.183.8 1.95.8zM6.3 13.85a7.48 7.48 0 012.575-1.912A7.536 7.536 0 0112 11.275c1.1 0 2.142.221 3.125.663A7.48 7.48 0 0117.7 13.85c.1 0 .175-.03.225-.088A.314.314 0 0018 13.55V2.3c0-.067-.033-.133-.1-.2s-.133-.1-.2-.1H6.3c-.067 0-.133.033-.2.1s-.1.133-.1.2v11.25c0 .083.025.154.075.212.05.059.125.088.225.088zm0 1.65c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 014.5 13.7V2.3c0-.5.175-.925.525-1.275C5.375.675 5.8.5 6.3.5h11.4c.5 0 .925.175 1.275.525.35.35.525.775.525 1.275v11.4c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H6.3zm-4 4c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 01.5 17.7V5.25a.726.726 0 01.75-.75.728.728 0 01.75.75V17.7c0 .083.03.154.088.213A.289.289 0 002.3 18h12.45c.217 0 .396.07.538.212a.731.731 0 01.212.538c0 .217-.07.396-.212.538a.731.731 0 01-.538.212H2.3zM12 7.725c-.35 0-.646-.12-.887-.362a1.208 1.208 0 01-.363-.888c0-.35.121-.646.363-.888.241-.241.537-.362.887-.362s.646.12.887.362c.242.242.363.538.363.888s-.121.646-.363.888a1.206 1.206 0 01-.887.362zM8.325 14h7.35a5.736 5.736 0 00-1.712-.912A6.16 6.16 0 0012 12.775a6.15 6.15 0 00-1.962.313A5.734 5.734 0 008.325 14z"
                ></path>
              </svg>
            </Button>
          </div>
        </div>
        <div className="patient-profile-cell d-inline-flex align-items-center">
          <div className="pic position-relative">
            <>
              {isLoading ? (
                <Spinner height={50} />
              ) : (
                <>
                  {petDetails?.pet_image ? (
                    <img
                      className="img-fluid w-100 h-100"
                      src={petDetails?.pet_image}
                      alt="Pet"
                    />
                  ) : (
                    <PetPlaceholder />
                  )}
                </>
              )}
            </>

            {/* <PetPlaceholder /> */}
            {/* <span className="badge-icon position-absolute">
							<img className="img-fluid" src={require('../../../assets/img/vetRecorEMR/pet-badge-icon-yellow.svg')} alt="Badge" />
						</span> */}
            {/*petDetails?.wellness_plan_id &&
              <span className="badge-icon position-absolute">
                <img className="img-fluid" src={require('../../../assets/img/vetRecorEMR/pet-badge-icon-yellow.svg')} alt="Badge" /> 
                <div className="bg-[#DD9323] inline w-[20px] p-[2px] absolute rounded-[5px] top-[7px] left-[-26px]">
                  <MdPets color="#fff" size={15} />
                </div>
          </span>*/}
          </div>
          <div className="txt">
            <h2>
              {petDetails?.pet_name}
            </h2>
            <h3>{petDetails?.vrn}</h3>
            <h4 className={petDetails?.deceased ? 'inactive' : petDetails?.active ? "active" : "inactive"}>
              {petDetails?.deceased ? 'Deceased' : petDetails?.active ? "Active" : "Inactive"}
            </h4>
          </div>
          {permissions?.edit_patients_details && (
            <div className="txt ms-2">
              <button
                onClick={() => {
                  setShow(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-pen"
                  viewBox="0 0 16 16"
                >
                  <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                </svg>
              </button>
            </div>
          )}
        </div>
        {/*
        <div className="profile-btns d-flex mb-2 pb-1">
          <Button color="link" className="flex-fill">Message</Button>
          <Button color="link" className="flex-fill">Share Profile</Button>
        </div>*/}

        <div>
          <button
            type="button"
            class="health-button w-100 text-14-500 cpt-5 cpb-5"
            onClick={handleShow}
          >
            HEALTH SNAPSHOT
          </button>
        </div>

        {activeAppointments?.length > 0 ? (
          <div className="discharge-card mt-2 p-3 text-center">
            <h4 className="pb-2">
              Time in Clinic :{" "}
              <TimeCounter
                fromDateTime={activeAppointments[0]?.appointment_start}
              />
            </h4>
            {permissions.edit_appointments && (
              <button
                type="button"
                class="btn btn-primary w-100"
                onClick={() => {
                  createDischargePatientForm();
                  // history.push("/dischargePatient", {
                  //   pet_id: petDetails._id,
                  //   appointmentDetails: activeAppointments[0],
                  // });
                }}
              >
                Discharge
              </button>
            )}
          </div>
        ) : (
          <></>
        )}
        {totalAmount > 0 && (
          <div className=" mt-2 p-2 amount-card">
            <div className="fb-center">
              <div>
                <span className="amount-value">${totalAmount.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}</span>
                <p className="amount-text pt-1">Outstanding Invoices</p>
              </div>

              <BsChevronRight color="#ffffff" size={16} />
            </div>
          </div>
        )}
        {/*
                <div className="outstanding_invoice position-relative mt-2">
                    <h4>$52,800</h4>
                    <h3 className="mb-0">Outstanding Invoices</h3>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <mask id="mask0_6529_110610" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
                            <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_6529_110610)">
                            <path d="M8.87344 17.6234C8.72344 17.4734 8.64844 17.2984 8.64844 17.0984C8.64844 16.8984 8.72344 16.7234 8.87344 16.5734L12.9484 12.4984L8.87344 8.42344C8.72344 8.27344 8.64844 8.09844 8.64844 7.89844C8.64844 7.69844 8.72344 7.52344 8.87344 7.37344C9.02344 7.22344 9.19844 7.14844 9.39844 7.14844C9.59844 7.14844 9.77344 7.22344 9.92344 7.37344L14.4234 11.8734C14.5068 11.9568 14.5694 12.0524 14.6114 12.1604C14.6528 12.2691 14.6734 12.3818 14.6734 12.4984C14.6734 12.6151 14.6528 12.7274 14.6114 12.8354C14.5694 12.9441 14.5068 13.0401 14.4234 13.1234L9.92344 17.6234C9.77344 17.7734 9.59844 17.8484 9.39844 17.8484C9.19844 17.8484 9.02344 17.7734 8.87344 17.6234Z" fill="white" />
                        </g>
                    </svg>
                        </div>*/}
      </div>
      <Modal className="edit-modal" isOpen={show} toggle={() => setShow(false)}>
        <ModalHeader>Edit Details</ModalHeader>

        <ModalBody>
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <div className="profile-cell">
                <h4>Profile Picture</h4>
                <div className="pic-cell pet">
                  <div className="pic d-inline-flex overflow-hidden position-relative">
                    <span className="upload-cell position-absolute w-100 h-100 d-inline-flex align-items-center justify-content-center">
                      {/*
												<Input type="file" className="position-absolute" onChange={onFileChange} />
												<FontAwesomeIcon icon={faCamera} />*/}
                      <UploadImageBlock
                        petImage={petImage}
                        sendPetImage={(value) => {
                          setPetImage(value);
                        }}
                        petImageObj={(value) => {
                          console.log("petImageObj", value);
                          setPetImage(value);
                        }}
                      />
                    </span>
                    {imageUrl || petImage ? (
                      <img
                        className="img-fluid w-100 h-100"
                        src={imageUrl || petImage}
                        alt="Pet"
                      />
                    ) : petDetails?.pet_image ? (
                      <img
                        className="img-fluid w-100 h-100"
                        src={petDetails?.pet_image}
                        alt="Pet"
                      />
                    ) : (
                      <PetPlaceholder />
                    )}
                  </div>
                </div>
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">Date of Birth</Label>
              <Input
                type="date"
                name="dob"
                id="dob"
                placeholder="Enter Date of Birth"
                label="Date of Birth"
                className="mb-0"
                value={formik.values.dob}
                onChange={formik.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword">Rabies tag no. </Label>
              <Input
                type="text"
                name="rabies_number"
                id="rabies_number"
                placeholder="Enter Rabies Tag Number"
                isRequired={false}
                className="mb-0"
                labelVisible={false}
                value={formik?.values?.rabies_number}
                onChange={formik.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">License no.</Label>
              <Input
                type="text"
                name="license_number"
                id="license_number"
                placeholder="Enter License Number"
                isRequired={false}
                className="mb-0"
                labelVisible={false}
                value={formik?.values?.license_number}
                onChange={formik.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword">Microchip no. </Label>
              <Input
                type="text"
                name="microchip_number"
                id="microchip_number"
                placeholder="Enter Microchip Number"
                isRequired={false}
                className="mb-0"
                labelVisible={false}
                value={formik?.values?.microchip_number}
                onChange={formik.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">CPR</Label>
              <Input
                type="text"
                name="cpr"
                id="cpr"
                placeholder="Enter CPR Status"
                isRequired={false}
                className="mb-0"
                labelVisible={false}
                value={formik?.values?.cpr}
                onChange={formik.handleChange}
              />
            </FormGroup>
            <FormGroup>
                  <Label for="exampleSelect">Active</Label>
                  <Input
                    type="select"
                    name="active"
                    onChange={formik.handleChange}
                    value={formik.values.active}
                    id="exampleSelect"
                  >
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="exampleSelect">Status</Label>
                  <Input
                    type="select"
                    name="deceased"
                    onChange={formik.handleChange}
                    value={formik.values.deceased}
                    id="exampleSelect"
                  >
                    <option value={false}>Alive</option>
                    <option value={true}>Deceased</option>
                  </Input>
                </FormGroup>
            <FormGroup>
              <Label for="examplePassword">Identity </Label>
              <div className="border-box px-3 py-3">
                <FormGroup>
                  <Label for="exampleSelect">Select Gender</Label>
                  <Input
                    type="select"
                    name="gender"
                    onChange={formik.handleChange}
                    value={formik.values.gender}
                    id="exampleSelect"
                  >
                    <option value="male">Male</option>
                    <option value="neutered-male">Neutered Male</option>
                    <option value="female">Female</option>
                    <option value="spayed-female">Spayed Female</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="exampleEmail">Breed</Label>
                  <Input
                    type="select"
                    name="breed"
                    id="breed"
                    required
                    value={formik.values.breed}
                    onChange={formik.handleChange}
                  >
                    <option value="">Select a breed</option>

                    {/* Map through breeds and render breed options */}
                    {(breeds || []).map((item) => (
                      <option
                        key={item._id}
                        value={item._id}
                        style={{ textTransform: "capitalize" }}
                      >
                        {item.breed}
                      </option>
                    ))}
                  </Input>

                  {/* <Input
                    type="text"
                    name="breed"
                    id="breed"
                    placeholder="Enter breed Status"
                    isRequired={false}
                    className="mb-0"
                    labelVisible={false}
                    value={formik?.values?.breed}
                    onChange={formik.handleChange}
                  /> */}
                </FormGroup>

                <FormGroup>
                  <Label for="exampleEmail">Color</Label>
                  <Input
                    type="text"
                    name="color"
                    id="color"
                    placeholder=""
                    className="mb-0"
                    value={formik?.values?.color}
                    onChange={formik.handleChange}
                  />
                </FormGroup>
              </div>
              {/* <ProfileAllergyEdit
                setAllergies={(val) => formik.setFieldValue("allergy_tag", val)}
                allergies={formik?.values?.allergy_tag}
                showRemoveIcon={true}
              /> */}
              <ProfileAllergy
                setAllergies={(val) => formik.setFieldValue("allergy_tag", val)}
                allergies={formik?.values?.allergy_tag}
                showRemoveIcon={true}
                target="edit-allergy-main"
              />
              <ProfileTagsEdit
                setBehaviourTags={(val) => {
                  formik.setFieldValue("behaviour_tag", val);
                }}
                behaviour_tags={formik?.values?.behaviour_tag}
                showRemoveIcon={true}
              />
            </FormGroup>

            <Button type="submit">Update</Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PatientHead;
