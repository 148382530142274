import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import AddServicesTable from "./add-services-table";
import OrderInfo from "./order-info";
import { addInventoryService } from "../../context/actions/service";
import AddProductsTable from "./AddProductsTable";

const AddService = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.user_details);
  const history = useHistory();
  const [formData, setFormData] = useState({});
	const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);

  const handleFormData = () => {
    const updatedFormData = {
      ...formData,
      // clinic_id: userDetails?._id,
      clinic_id: clinic_settings.clinic_id,
    };
    dispatch(
      addInventoryService(updatedFormData, (res) => {
        console.log("Service added");
        history.push("/inventory");
      })
    );
  };



  return (
    <div className="px-3 py-1 space-y-3 ">
      <header className="flex items-center justify-between py-2 ">
        <button
          onClick={() => {
            history.goBack();
          }}
          className="inline-flex items-center text-sm md:text-base uppercase gap-x-2 text-blue-500 tracking-tighter hover:underline underline-offset-2"
        >
          <FontAwesomeIcon icon={faChevronLeft} fontSize={23} />
          Back to List
        </button>
        <div className="inline-flex gap-x-2 items-center">
          <button className="w-24 uppercase md:w-32 py-2  text-sm md:text-base border duration-300 transition-colors hover:bg-gray-50 rounded-lg">
            Cancel
          </button>
          <button
            onClick={handleFormData}
            className="w-24 uppercase md:w-32 py-2  text-sm md:text-base drop-shadow bg-[#3D3EFD] duration-300 text-white transition-colors hover:bg-blue-600 rounded-lg"
          >
            Save
          </button>
        </div>
      </header>
      <section className="flex flex-col md:flex-row gap-5 ">
        <div className="w-full md:w-1/4 space-y-3">
          <h3 className="text-xl">
            Create New Service
          </h3>

        

          <ol className="relative text-gray-500 border-l-[3px] border-[#282E65] ml-4">
            <li className="mb-10 ml-8 space-y-1">
              <span className="absolute flex items-center justify-center w-20 h-20 bg-[#282E65] text-white rounded-full -left-[11px]">
                1
              </span>
              <h3 className="font-medium leading-tight text-gray-800">
                Order Information
              </h3>
            </li>
            <li className="mb-10 ml-8 space-y-1">
              <span className="absolute flex items-center justify-center w-20 h-20 bg-[#282E65] text-white rounded-full -left-[11px]">
                2
              </span>
              <h3 className="font-medium leading-tight text-gray-800">
                Products
              </h3>
            </li>
        </ol>
        </div>
        <div className="w-full md:w-3/4 space-y-[2px]">
          <div className="w-full bg-gray-100 rounded-t-lg px-3 py-2 space-y-3">
            <OrderInfo setFormData={(v) => setFormData({...formData, ...v})} />
          </div>
          <div className="w-full bg-gray-100 rounded-t-lg px-3 py-2 space-y-3">
            <AddProductsTable setFormData={(v) => setFormData({...formData, service_products: v})} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default AddService;
