import React, { useEffect } from "react";
import "./index.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Row, Badge, Button, Col } from "react-bootstrap";
import { useState } from "react";
import { FormGroup, Input } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { LuMinusCircle } from "react-icons/lu";
import { MdInsertDriveFile } from "react-icons/md";
import NewProductSelect from "components/patient-module/PatientDetail/TabScreens/Overview/TreatmentPlanBlock/TreatmentPlanAdd/NewProductSelect";
import { toasterConfig } from "utils/constants";
import { addClinicTreatmentPlan, getFormAndTemplates, removeCardFromPlan, removeFileFromTreatmentPlanCard, updateTreatmentPlanCardDetails } from "context/actions/clinicSettings";
import { RiSaveFill } from "react-icons/ri";
const AddTreatmentPlan = ({ show, handleClose, selectedPlan }) => {

  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.user.user_details);
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const [isNewProductSelect, setIsNewProductSelect] = useState(false);

  const [cardData, setCardData] = useState([]);


  const [title, setTitle] = useState(true);// for display and hide input
  const [titleName, setTitleName] = useState("");
  const [realTitleName, setRealTitleName] = useState("");
  const [realNotesName, setRealNotesName] = useState("");
  const [notes, setNotes] = useState('');

  const initialInputView = {
    isInfoEditing: '',
    isNameEditing: '',
  }

  const [viewInputs, setViewInputs] = useState(initialInputView)
  const isEditView = selectedPlan?._id ? true : false;
  useEffect(() => {
    if (selectedPlan?._id) {
      const tempData = []

      selectedPlan?.instructionDetails.forEach(ele => {
        tempData.push({
          files: ele.attachments,
          id: ele._id,
          instructions: ele.instructions,
          product_name: ele.name,
          type: "instruction"
        })
      })
      selectedPlan?.productDetails.forEach(ele => {
        tempData.push({
          files: [],
          id: ele._id,
          product_id: ele.product_id,
          instructions: ele.instructions,
          product_name: ele.name,
          type: "product"
        })
      })
      setTitleName(selectedPlan.treatmentName)
      setRealTitleName(selectedPlan?.treatmentName);
      setRealNotesName(selectedPlan?.notes);
      setNotes(selectedPlan?.notes);
      setCardData(prev => ([...prev, ...tempData]))
    }
  }, [selectedPlan])


  const handleInputViews = (key, i) => {
    setViewInputs((prev) => ({ ...initialInputView, [key]: i }))
  }

  const handleKeyPress = (event, index, name) => {
    if (event.key === "Enter") {
      handleInputViews(name, index)
    }
  };


  const setCardDataFn = (obj) => {

    let data = cardData.concat({
      files: obj.files,
      id: obj.type === "product" ? "" : obj.id,
      product_id: obj.type === "product" ? obj.id : "",
      instructions: obj.instructions,
      product_name: obj.product_name,
      type: obj.type
    });
    setCardData(data);
    setIsNewProductSelect(false);
  };

  const deleteItem = (index, item) => {
    let dupData = cardData.filter((data, i) => i !== index);
    const tempObj = Object.entries(viewInputs)
      .filter(([key, value]) => value === index)
      .reduce((acc, [key]) => {
        acc[key] = "";
        return acc;
      }, {});
    setViewInputs((prev) => ({ ...initialInputView, ...tempObj }))
    setCardData(dupData);
    if (isEditView) {
      const payload = {
        treatment_id: selectedPlan?._id,
        treatment_type: item.type,
        data_id: item.id
      }
      dispatch(removeCardFromPlan(payload, (res) => {

      }))
    }
  };

  const handleOnFileChange = (theFileObj, i) => {
    let dupData = cardData.map((data, index) => {
      if (index === i) {
        data?.files.push(theFileObj);
      }
      return data;
    });
    setCardData(dupData);
  };

  const addCardData = (value, index, key) => {
    let data = [...cardData];
    data[index][key] = value;
    setCardData(data);
  };

  const handleTemplateSave = () => {
    let endDate = new Date(cardData[0].start_date);
    if (cardData[0].duration_type === "Days") { endDate.setDate(endDate.getDate() + parseInt(cardData[0].duration)); }
    else if (cardData[0].duration_type === "Weeks") { endDate.setDate(endDate.getDate() + parseInt(cardData[0].duration) * 7); }

    if (titleName === '') {
      toast.error('Title is not allowed to be empty.', toasterConfig);
      return;
    }
    const nameErr = cardData.filter(data => data?.product_name.trim() === '').length > 0 ? true : false;
    if (nameErr) return toast.error('Name is not allowed to be empty.', toasterConfig);

    const allData = {
      clinic_id: clinic_details?._id,
      created_by: userDetail?._id,
      treatment_type: cardData[0].type,
      treatmentName: titleName,
      instructions: cardData[0].instructions,
      name: cardData[0].product_name,
      product_id: cardData[0].product_id,
      attachments: cardData[0].files,
      notes: notes
    };
    if (cardData[0].type === "product" || (cardData[0].type === "instruction" && cardData[0].files.length === 0)) { delete allData.attachments; }
    if (cardData[0].type === "instruction") {
      delete allData.product_id;
    }

    let formData = new FormData();
    for (const key in allData) {
      // formData.append(key, allData[key]);
      if (key === "attachments" && allData[key]?.length > 0) {
        allData.attachments?.forEach((file) => {
          formData.append("attachments", file)
        })
      } else {
        formData.append(key, allData[key]);
      }
    }

    dispatch(
      addClinicTreatmentPlan(formData, (response) => {
        if (response?.status) {
          if (cardData.length > 1) {
            cardData.forEach((data, i) => {
              if (i > 0) {
                const endDate = new Date(cardData[0].start_date);
                if (cardData[0].duration_type === "Days") endDate.setDate(endDate.getDate() + parseInt(cardData[0].duration));
                else if (cardData[0].duration_type === "Weeks") endDate.setDate(endDate.getDate() + parseInt(cardData[0].duration) * 7);

                const payload = {
                  treatment_id: response?.response_data?.treatment_id,
                  clinic_id: clinic_details?._id,
                  created_by: userDetail?._id,
                  treatment_type: data.type,
                  treatmentName: titleName,
                  instructions: data.instructions,
                  name: data.product_name,
                  product_id: data.product_id,
                  attachments: data.files,
                  notes: notes
                };
                if (data.type === "product" || (data.type === "instruction" && data?.files?.length === 0)) { delete payload.attachments; }
                if (data.type === "instruction") { delete payload.product_id; }

                let formData = new FormData();
                for (const key in payload) {
                  if (key === "attachments" && payload[key]?.length > 0) {
                    payload.attachments?.forEach((file) => {
                      formData.append("attachments", file)
                    })
                  } else {
                    formData.append(key, payload[key]);
                  }
                }

                dispatch(
                  addClinicTreatmentPlan(formData, (responseNew) => {
                    console.log("responseNew.status && i === (cardData?.length - 1)", responseNew.status && i === (cardData?.length - 1))
                    if (responseNew.status && i === (cardData?.length - 1)) {
                      dispatch(getFormAndTemplates({}, () => { }));
                      toast.dismiss();
                      toast.success(response.message, toasterConfig);
                      handleCloseBtn();
                    }
                  }))
              }
            });
          } else {
            toast.dismiss();
            toast.success(response.message, toasterConfig);
            dispatch(getFormAndTemplates({}, () => { }));
            handleCloseBtn();
          }
        }
      })
    );
  };

  const handleCloseBtn = () => {
    setViewInputs(initialInputView);
    setTitleName('');
    setTitle(false);
    setCardData([]);

    handleClose();
    if (isEditView) { dispatch(getFormAndTemplates({}, () => { })); }
  }



  const deleteFile = (i, j) => {
    if (!cardData[i].files[j]?.name) {
      const payload = {
        treatment_type: cardData[i].type,
        data_id: cardData[i].id,
        file_path: cardData[i].files[j]
      }
      dispatch(removeFileFromTreatmentPlanCard(selectedPlan?._id, payload, (res) => {
      }))
    }
    cardData[i].files.splice(j, 1);
    setCardData(prev => ([...cardData]));
  }

  const saveSingleCardData = (data, index) => {
    console.log("%c Line:300 🥃 data", "color:#33a5ff", data);
    if (data.product_name.trim() === "") return toast.error('Name is not allowed to be empty.', toasterConfig);
    const payload = {
      treatment_id: selectedPlan?._id,
      clinic_id: clinic_details?._id,
      created_by: userDetail?._id,
      treatment_type: data.type,
      treatmentName: titleName,
      instructions: data.instructions,
      name: data.product_name,
      product_id: data.product_id,
      attachments: data.files,
    };

    if (data.type === "product" || (data.type === "instruction" && data?.files?.length === 0)) { delete payload.attachments; }
    if (data.type === "instruction") { delete payload.product_id; }
    let formData = new FormData();
    for (const key in payload) {
      if (key === "attachments" && payload[key]?.length > 0) {
        payload.attachments?.forEach((file) => {
          formData.append("attachments", file)
        })
      } else {
        formData.append(key, payload[key]);
      }
    }
    dispatch(
      addClinicTreatmentPlan(formData, (responseNew) => {

        if (responseNew.status) {
          addCardData(responseNew?.response_data?.data_id, index, "id");
          setViewInputs(initialInputView)
          toast.dismiss();
          toast.success(responseNew.message, toasterConfig);
        }
      }))
  }
  const updatePlanCardDetails = (data, index) => {
    const payload = {
      treatment_type: data.type,
      data_id: data.id,
      name: data.product_name,
      instructions: data.instructions,
      attachments: data.files?.filter(ele => ele?.name !== undefined)
    }
    if (data.type === "product" || (data.type === "instruction" && data?.files?.length === 0)) { delete payload.attachments; }
    if (data.type === "instruction") { delete payload.product_id }
    let formData = new FormData();
    for (const key in payload) {
      if (key === "attachments" && payload[key]?.length > 0) {
        payload.attachments?.forEach((file) => {
          formData.append("attachments", file)
        })
      } else {
        formData.append(key, payload[key]);
      }
    }
    dispatch(updateTreatmentPlanCardDetails(selectedPlan._id, formData, (res) => {
      if (res.status) {
        const newData = {
          files: res.response_data?.attachments || [],
          id: res.response_data?._id,
          instructions: res.response_data?.instructions,
          product_name: res.response_data?.name,
          type: data.type
        }
        const updatedCardData = [...cardData.slice(0, index), newData, ...cardData.slice(index + 1)];
        setCardData(updatedCardData);
        setViewInputs(initialInputView)
      }

    }))

  }
  return (
    <>
      <Offcanvas
        className="treatment_plan_add"
        show={show}
        onHide={handleCloseBtn}
        placement={"end"}
        style={{minWidth: '40%', maxWidth: '40%'}}
      >
        {isNewProductSelect ? (
          <div
          style={{minWidth: '40%', maxWidth: '40%'}} className={`medication-panel-wrapper fixed flex flex-col active`}
          >
            <NewProductSelect
              titleName={titleName}
              setIsNewProductSelect={setIsNewProductSelect}
              setCardDataFn={setCardDataFn}
            />
          </div>
        ) :
          (
            <>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  <FormGroup className="input-wrapper mb-0 px-2">
                    {!title ? <p onClick={() => setTitle(true)}>{titleName === '' ? "[Treatment_plan_name_goes here]" : titleName}</p> :
                      <Input
                        type="text"
                        value={titleName}
                        onChange={(e) =>
                          setTitleName(e.target.value)
                        }
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            setTitle(false);
                            if (isEditView && realTitleName !== titleName) {
                              const formData = new FormData();
                              formData.append("treatmentName", titleName);
                              dispatch(updateTreatmentPlanCardDetails(selectedPlan._id, formData, (res) => {
                                if (res.status) { setRealTitleName(titleName) }
                              }))
                            }
                          }
                        }}
                        placeholder="Treatment plan name"
                      />
                    }
                  </FormGroup>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Row className="serach_header">
                  <Col md={6}>
                    <FormGroup className="input-wrapper mb-0 max-w-[252px]">

                      <Input
                        type="text"
                        value={notes}
                        onChange={(e) =>
                          setNotes(e.target.value)
                        }
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (isEditView && realNotesName !== notes) {
                              const formData = new FormData();
                              formData.append("notes", notes);
                              dispatch(updateTreatmentPlanCardDetails(selectedPlan._id, formData, (res) => {
                                if (res.status) { setRealNotesName(notes) }
                              }))
                            }
                            e.target.blur();
                          }
                        }}
                        placeholder="Treatment notes"
                      />
                    </FormGroup>

                  </Col>
                  <Col md={6}>
                    <div className="d-flex justify-content-md-end justify-content-center   gap-2">
                      <Button
                        outline="true"
                        color="base-color"
                        className="w-[150px] btn btn-success "
                        onClick={() => { setIsNewProductSelect(true) }}
                      >
                        Add Product
                      </Button>

                      <Button
                        outline="true"
                        color="base-color"
                        className="w-[150px] btn btn-success "
                        onClick={() => {
                          setCardDataFn({
                            type: 'instruction',
                            id: '',
                            product_name: '',
                            instructions: '',
                            files: []
                          });
                        }}
                      // type="submit"
                      >
                        Add Instructions
                      </Button>
                    </div>
                  </Col>
                </Row>
                <div className="instructions_box">
                  {cardData.length > 0 && cardData?.map((data, i) => {
                    return (
                      <div className="instructions_row_box my-2" key={i}>
                        <Row className="my-2">
                          <Col md={5}>
                            <div className="instructions_left">
                              <Badge> {data?.type === 'instruction' ? 'Instructions' : "Product"} </Badge>
                              <div>
                                  <label className="pb-1">Name:</label>
                                  <div className="flex px-4 py-3 bg-white rounded-lg border border-indigo-950 border-opacity-10 justify-start items-center gap-2 mb-2 ">
                                    <div className="grow  text-base font-normal">
                                        {data?.product_name && data?.product_name !== '' ? 
                                        data?.product_name :
                                        <>
                                          <input
                                            type="text"
                                            value={data?.instruction_name}
                                            onChange={(e) =>
                                              addCardData(e.target.value, i, 'instruction_name')
                                            }
                                
                                            placeholder="Enter Instruction Name"
                                          />
                                        </>}
                                      </div>
                                      {/*<div className="justify-center items-center gap-2.5 flex">
                                        <div className="w-[52px] text-right text-indigo-600 text-sm font-normal font-['Inter'] leading-[21px]">
                                          Change
                                        </div>
                      </div>*/}
                                    </div>
                                </div>
                              
                              {data?.type === 'instruction' &&
                                <div className="add_fils d-flex flex-wrap gap-3 my-2">
                                  {data?.files?.map((file, j) => {
                                    const extensionKey = !file?.name ? file : file?.name;
                                    const sliceFileURL = extensionKey.split("?")?.[0];
                                    if (['jpg', 'jpeg', 'png', 'gif'].includes(sliceFileURL.substring(sliceFileURL.lastIndexOf('.') + 1))) {
                                      const imgPath = file?.name ? URL.createObjectURL(file) : file
                                      return (
                                        <div className="position-relative" key={j}>
                                          <img
                                            className="file_box"
                                            src={imgPath}
                                            alt="img"
                                          />
                                          <span className="file-delete-icon" onClick={() => deleteFile(i, j)}>
                                            <LuMinusCircle color={'red'} />
                                          </span>
                                        </div>
                                      )
                                    } else if (['pdf'].includes(sliceFileURL.substring(sliceFileURL.lastIndexOf('.') + 1))) {
                                      return (
                                        <div className="position-relative" key={j}>
                                          <img
                                            className="file_box"
                                            src={require("../../../../../../assets/img/pdf.png")}
                                            alt="img"
                                          />
                                          <span className="file-delete-icon" onClick={() => deleteFile(i, j)}>
                                            <LuMinusCircle color={'red'} />
                                          </span>
                                        </div>
                                      )
                                    } else if (['mp3', 'mp4'].includes(sliceFileURL.substring(sliceFileURL.lastIndexOf('.') + 1))) {
                                      return (
                                        <div className="position-relative" key={j}>
                                          <img
                                            className="file_box"
                                            src={require(`../../../../../../assets/img/mp4.png`)}
                                            alt="img"
                                          />
                                          <span className="file-delete-icon" onClick={() => deleteFile(i, j)}>
                                            <LuMinusCircle color={'red'} />
                                          </span>
                                        </div>
                                      )
                                    } else {
                                      return (
                                        <div className="position-relative" key={j} onClick={() => deleteFile(i, j)}>
                                          <MdInsertDriveFile size={50} />
                                          <span className="file-delete-icon">
                                            <LuMinusCircle color={'red'} />
                                          </span>
                                        </div>
                                      )
                                    }
                                  })}

                                  <button type="button" class="add_file_btn">
                                    <i class="icomoon-plus-btn"></i>
                                    <Input type="file" id="myfile" name="myfile" onChange={(e) => handleOnFileChange(e.target.files[0], i)} />
                                  </button>
                                </div>
                              }
                            </div>
                          </Col>
                          <Col md={isEditView ? 6 : 7}>
                            <div className="instructions_right">
                              <div className="info_box">
                                <div className="title">
                                  <label>
                                    Name<span>*</span>
                                  </label>
                                </div>
                                <div className="add_info">
                                  {viewInputs.isNameEditing === i ? (
                                    <>
                                      <FormGroup className="input-wrapper mb-0">
                                        <Input
                                          type="text"
                                          value={data?.product_name}
                                          onChange={(e) =>
                                            addCardData(e.target.value, i, 'product_name')
                                          }
                                          onKeyPress={(e) => handleKeyPress(e, i, 'product_name')}
                                          placeholder={`${data?.type === 'instruction' ? "Instruction" : "Product"} name`}
                                        />
                                      </FormGroup>
                                    </>
                                  ) : (
                                    <>
                                      {data?.product_name ? (
                                        <>
                                          <p onClick={() => {
                                            if (data?.type === 'instruction') handleInputViews("isNameEditing", i)
                                          }}>
                                            {data?.product_name}
                                          </p>
                                        </>
                                      ) : (
                                        <label onClick={() => { handleInputViews("isNameEditing", i) }}>Add Info</label>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="info_box">
                                <div className="title">
                                  <label>
                                    Instructions
                                  </label>
                                </div>
                                <div className="add_info">
                                  {viewInputs.isInfoEditing === i ? (
                                    <>
                                      <FormGroup className="input-wrapper mb-0">
                                        <Input
                                          type="text"
                                          value={data?.instructions}
                                          onChange={(e) =>
                                            addCardData(e.target.value, i, 'instructions')
                                          }
                                          onKeyPress={(e) => handleKeyPress(e, i, 'info')}
                                          placeholder="Instructions"
                                        />
                                      </FormGroup>
                                    </>
                                  ) : (
                                    <>
                                      {data?.instructions ? (
                                        <>
                                          <p onClick={() => { handleInputViews("isInfoEditing", i) }}>
                                            {data?.instructions}
                                          </p>
                                        </>
                                      ) : (
                                        <label onClick={() => { handleInputViews("isInfoEditing", i) }}>Add Info</label>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Col>
                          {isEditView && <div className="save_btn mb-2" onClick={() => {
                            if (data.id === "") {
                              saveSingleCardData(data, i)
                            } else {
                              updatePlanCardDetails(data, i)
                            }
                          }}>
                            <RiSaveFill size={20} />
                          </div>}
                          <div className="delete_btn">
                            <img
                              className="file_box"
                              src={require("../../../../../../assets/img/delete.png")}
                              alt="img"
                              onClick={() => deleteItem(i, data)}
                            />
                          </div>
                        </Row>
                      </div>
                    );
                  })}

                  {cardData.length > 0 && !isEditView &&
                    <div className="d-flex gap-2 my-3 justify-content-end">
                      <Button
                        outline="true"
                        color="base-color"
                        className="w-[150px] btn btn-cancel "
                      >
                        Cancel
                      </Button>

                      <Button
                        outline="true"
                        color="base-color"
                        className="w-[150px] btn btn-primary "
                        onClick={handleTemplateSave}
                      >
                        Save
                      </Button>
                    </div>
                  }
                </div>
              </Offcanvas.Body>
            </>
          )}
      </Offcanvas>
    </>
  );
};

export default AddTreatmentPlan;
