import * as Yup from "yup";
/**
 * @description declaration form schema for client validations
 */

export const medicationPlanSchema = Yup.object().shape({
  medications: Yup.array().of(
    Yup.object().shape({
      product_id: Yup.string(),
      product_name: Yup.string(),
      product_description: Yup.string(),
      dose: Yup.number().required("Dose information is required"),
      route: Yup.string().required("Route is required"),
      frequency: Yup.string(),
      duration_num: Yup.number(),
      duration_unit: Yup.string(),
      prn: Yup.boolean(),
      first_dose: Yup.date(),
      notes: Yup.string(),
    })
  ),
});

export const medicationInit = {
  // extends: null,
  medications: [],
};
