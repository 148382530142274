import React, { useState } from "react";
import { TabContent, Nav, NavItem, NavLink } from "reactstrap";
import { Modal } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import Vitals from "./Vitals";
import IVAssess from "./IVAssess";
import IO from "./IO";
import Dental from "./Dental";
import BloodProducts from "./BloodProducts";
import DrainsTubes from "./DrainsTubes";
import Wounds from "./Wounds";
import Ocular from "./Ocular";
import Masses from "./Masses";
import Acupuncture from "./Acupuncture"
import { HiOutlineDownload } from "react-icons/hi";
import "./index.scss";

const Flowsheets = ({ petId }) => {
  const [activeTab, setActiveTab] = useState("1");
  const [displayURL, setDisplayURL] = useState("");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const handleDownload = (fileURL) => {
    let fileName = fileURL?.split("/")?.pop();
    fetch(fileURL)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };
  return (
    <>
      {displayURL && (
        <Modal
          show
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          onHide={() => {
            setDisplayURL("");
          }}
          centered
        >
          <div className="cp-10">
            <div className="d-flex justify-content-end gap-2">
              <div
                className="bg-3EFD cp-5 br-4 cursor-pointer d-flex gap-2 align-items-center cps-10 cpe-10"
                onClick={() => {
                  handleDownload(displayURL);
                }}
              >
                <span>
                  <HiOutlineDownload color="#ffffff" />
                </span>
                <span className="text-14-400 color-ffff">Download</span>
              </div>
              <div
                className="bg-dc35 cp-5 br-4 cursor-pointer cps-10 cpe-10"
                onClick={() => {
                  setDisplayURL("");
                }}
              >
                <IoMdClose color="#ffffff" />
              </div>
            </div>
            <div className="h-575 cp-20">
              <img src={displayURL} alt="view" className="fit-image" />
            </div>
          </div>
        </Modal>
      )}
      <div className="vitals-wrapper flowsheet_tab d-flex h-100">
        <div className="left-col d-flex flex-column overflow-auto">
          <Nav
            tabs
            className="vital-menu-tab text-center flex-column overflow-visible"
          >
            <NavItem>
              <NavLink
                className={{ active: activeTab === "1" }}
                onClick={() => {
                  toggle("1");
                }}
              >
                Vitals
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={{ active: activeTab === "2" }}
                onClick={() => {
                  toggle("2");
                }}
              >
                I&O
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={{ active: activeTab === "3" }}
                onClick={() => {
                  toggle("3");
                }}
              >
                IV Access
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={{ active: activeTab === "5" }}
                onClick={() => {
                  toggle("5");
                }}
              >
                Drains/Tubes
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={{ active: activeTab === "4" }}
                onClick={() => {
                  toggle("4");
                }}
              >
                Masses
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={{ active: activeTab === "6" }}
                onClick={() => {
                  toggle("6");
                }}
              >
                Wounds
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={{ active: activeTab === "7" }}
                onClick={() => {
                  toggle("7");
                }}
              >
                Ocular
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={{ active: activeTab === "8" }}
                onClick={() => {
                  toggle("8");
                }}
              >
                Dental
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={{ active: activeTab === "9" }}
                onClick={() => {
                  toggle("9");
                }}
              >
                Blood Products
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={{ active: activeTab === "10" }}
                onClick={() => {
                  toggle("10");
                }}
              >
                Acupuncture
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <div className="right-col flex-fill overflow-auto">
          <div className="vital-tab-wrapper">
            <TabContent
              activeTab={activeTab}
              className="flex-fill overflow-auto position-relative"
            >
              {/* <LoaderUI FullWindow={false} inContainer={true} overlay={true} /> */}
              {/* <NodataFoundBlock className="h-100" /> */}

              {activeTab === "1" && <Vitals petId={petId} />}
              {activeTab === "2" && <IO petId={petId} />}
              {activeTab === "3" && (
                <IVAssess petId={petId} setDisplayURL={setDisplayURL} />
              )}
              {activeTab === "5" && (
                <DrainsTubes petId={petId} setDisplayURL={setDisplayURL} />
              )}
              {activeTab === "4" && (
                <Masses petId={petId} setDisplayURL={setDisplayURL} />
              )}
              {activeTab === "6" && (
                <Wounds petId={petId} setDisplayURL={setDisplayURL} />
              )}
              {activeTab === "7" && <Ocular petId={petId} />}
              {activeTab === "8" && <Dental />}
              {activeTab === "9" && <BloodProducts petId={petId} />}
              {activeTab === "10" && (
                <Acupuncture petId={petId} setDisplayURL={setDisplayURL} />
              )}
            </TabContent>
          </div>
        </div>
      </div>
    </>
  );
};

export default Flowsheets;
