import React, { useState } from "react";
import {
  FormGroup,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  Button,
  Modal,
  Spinner,
} from "reactstrap";
import UserThumbnailListBlock from "../UserThumbnailListBlock";
import { useSelector } from "react-redux";
import "./index.scss";
import { useDispatch } from "react-redux";
import { DeleteClinic } from "../../context/actions/clinic";
import AddNewClinicPanel from "../../components/super-admin-dashboard/Clinic/AddNewClinicPanel";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { formatPhoneNumber } from "utils/common";

const LocationInfoBlock = ({
  getClinics = () => {},
  // onEditClick=() =>{},
  clinicData,
  staffImg = [],
  data: { title, address, phone_1, phone_2, email },
}) => {
  const { clinicId } = useParams();
  const history = useHistory();
  const [dropdownOpen, setOpen] = useState(false);
  const [IsDelete, setIsDelete] = useState("");
  const [editData, setEditData] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isSubClinicForm, setIsSubClinicForm] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const dispatch = useDispatch();

  const handleDeleteLocationBlock = async () => {
    setDeleteLoading(true);
    const response = await dispatch(
      DeleteClinic({ clinicIds: clinicData?._id })
    );
    if (response?.status) {
      getClinics();
      if (clinicId === clinicData?._id)
        history.push(
          `/super-admin/clinic-profile/${localStorage.getItem(
            "super_clinic_id"
          )}`
        );
    }
    setDeleteLoading(false);
    setIsModal(false);
    setIsDelete("");
  };

	const { permissionsData } = useSelector((state) => state.clinicPermissionReducer );
    const permissions = permissionsData;
  return (
    <>
      <div className="location-info-blc">
        <div className="info-blc">
          <div className="title d-flex align-items-center">
            <h3>{title || "Head Office"}</h3>
            {/* <Button color="link" className="d-inline-flex align-items-center justify-content-center">
							<span className="icomoon-more"></span>
						</Button> */}
            <ButtonDropdown
              isOpen={dropdownOpen}
              toggle={toggle}
              className="dropdown-cell"
            >
              <DropdownToggle color="link">
                <i className="icomoon-more"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={(e) => {
                    e.preventDefault();
                    setIsSubClinicForm(true);
                    setEditData(clinicData);
                  }}
                >
                  Edit
                </DropdownItem>
                {localStorage.getItem("super_clinic_id") !== clinicData?._id ? (
                  <DropdownItem
                    onClick={(e) => {
                      e.preventDefault();
                      setIsDelete(clinicData?._id);
                      setIsModal(true);
                    }}
                  >
                    Delete
                  </DropdownItem>
                ) : null}
              </DropdownMenu>
            </ButtonDropdown>
          </div>
          <div className="d-flex align-items-center row-block">
            <span className="icomoon-location-medical d-inline-flex align-items-center justify-content-center icon"></span>
            {address || "Model City, San Antonio, Texas / Zip Code - 90066"}
          </div>
          <div className="d-flex align-items-center row-block">
            <span className="icomoon-email d-inline-flex align-items-center justify-content-center icon"></span>
            {email || "admin@[clinicname].com"}
          </div>
          <div className="d-flex align-items-center row-block">
            <span className="icomoon-phone-chat d-inline-flex align-items-center justify-content-center icon"></span>
            { formatPhoneNumber(phone_1) || "269-939-9230"}
            <span className="symbol mx-2 mb-0"> / </span>
            {formatPhoneNumber( phone_2 )|| "269-939-9230"}
          </div>
        </div>
        <UserThumbnailListBlock staffImg={staffImg} />
      </div>
      {/* delete model */}
      <Modal isOpen={isModal ? true : false}>
        <div className="relative">
          <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
            <svg
              className="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
            <p className="mb-4 text-gray-500 dark:text-gray-300">
              Are you sure you want to delete this item?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <button
                onClick={() => {
                  setIsModal(false);
                }}
                data-modal-toggle="deleteModal"
                type="button"
                className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                No, cancel
              </button>
              <button
                onClick={handleDeleteLocationBlock}
                type="submit"
                className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900"
              >
                Yes, I'm sure{" "}
                {deleteLoading && (
                  <Spinner animation="border" role="status" size="sm" />
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* for Edit clinic data */}
      <div>
        <div
          className={`new-clinic-panel-overlay position-fixed ${
            isSubClinicForm ? "active" : ""
          }`}
        >
          <AddNewClinicPanel
            is_master_clinic={
              localStorage.getItem("super_clinic_id") === clinicData?._id
                ? true
                : false
            }
            master_clinic_id={localStorage.getItem("super_clinic_id")}
            isShow={isSubClinicForm}
            editData={clinicData}
            handelSuccess={() => {
              setIsSubClinicForm(false);
              setEditData(null);
              getClinics();
            }}
            closePanel={() => {
              setIsSubClinicForm(false);
              setEditData(null);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default LocationInfoBlock;
