import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input } from "reactstrap";
// import SwitchCell from "shared/SwitchCell";
import { useDispatch, useSelector } from "react-redux";
import {
  AiOutlineFileAdd,
  AiFillPlusCircle,
  AiFillCloseCircle,
} from "react-icons/ai";
import mp4File from "assets/img/mp4.png";
// import { LuMinusCircle } from "react-icons/lu";
// import { MdInsertDriveFile } from "react-icons/md";
import {
  createWaitingRoomList,
  updateWaitingRoomList,
  deleteWaitingRoomListItem,
  getWaitingRoomList,
} from "context/actions/waitingRoom";
import "./AddWaitingRoom.scss";
import { Spinner } from "react-bootstrap";

const AddWaitingRoom = ({
  closeNewPointPanel,
  className = "",
  refresh,
  addPoint,
  selectedKennel,
  setSelectedKennel,
  editData,
}) => {
  const dispatch = useDispatch();
  const { species } = useSelector((state) => state.appContentReducer);
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  let defaultInputState = {
    clinic_id: clinic_details?._id,
    room_name: "",
    age_range: "",
    weight_range: "",
    species_id: "",
    waitingDocument: [
      {
        duration_unit: "",
        fileType: "",
        duration_num: "",
        file: "",
        fileData: "",
      },
    ],
  };
  const weightRangeList = [
    { value: "X-Small", label: "X-small" },
    { value: "Small", label: "Small" },
    { value: "Medium", label: "Medium" },
    { value: "Large", label: "Large" },
    { value: "Giant", label: "Giant" },
  ];
  const [inputs, setInputs] = useState(defaultInputState);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isChange, setIsChange] = useState(false);

  const handleInputChange = (e) => {
    e.preventDefault();
    setIsChange(true);
    setInputs({ ...inputs, [e.target.name]: e?.target?.value });
  };
  const handleSave = (e) => {
    setBtnLoading(true);
    e.preventDefault();
    if (editData) {
      handleEdit();
    } else {
      handleAdd();
    }
  };
  const handleAdd = () => {
    const formData = new FormData();
    for (const key in inputs) {
      if (key === "waitingDocument") {
        inputs?.waitingDocument?.forEach((elm, index) => {
          formData?.append("attachments", elm.fileData);
          formData?.append(
            `attachments[${index}][fileName]`,
            elm.fileData?.name
          );
          formData?.append(
            `attachments[${index}][duration_unit]`,
            elm.duration_unit
          );
          formData?.append(
            `attachments[${index}][duration_num]`,
            elm.duration_num
          );
        });
      } else {
        formData?.append(key, inputs[key]);
      }
    }
    dispatch(
      createWaitingRoomList(formData, (res) => {
        if (res?.status) {
          closeNewPointPanel();
          getWaitingRoomData();
        }
        setBtnLoading(false);
      })
    );
  };
  const handleEdit = () => {
    const formData = new FormData();
    formData?.append("waiting_room_id", editData._id);
    for (const key in inputs) {
      if (key === "waitingDocument") {
        inputs?.waitingDocument
          ?.filter((o) => o.fileData)
          ?.forEach((elm, index) => {
            formData?.append("attachments", elm.fileData);
            formData?.append(
              `attachments[${index}][fileName]`,
              elm.fileData?.name
            );
            formData?.append(
              `attachments[${index}][duration_unit]`,
              elm.duration_unit
            );
            formData?.append(
              `attachments[${index}][duration_num]`,
              elm.duration_num
            );
            if (elm?.attachmentId) {
              formData?.append(
                `attachments[${index}][old_attachmentId]`,
                elm?.attachmentId
              );
            }
          });
      } else {
        formData?.append(key, inputs[key]);
      }
    }
    dispatch(
      updateWaitingRoomList(formData, (res) => {
        if (res?.status) {
          closeNewPointPanel();
          getWaitingRoomData();
        }
        setBtnLoading(false);
      })
    );
  };
  const handleRemove = async (id) => {
    const res = await dispatch(
      deleteWaitingRoomListItem({
        waiting_room_id: editData?._id,
        attachmentId: id,
      })
    );
    if (res?.status) {
      getWaitingRoomData();
    }
    return res;
  };
  const getVideoDuration = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");

      video.addEventListener("loadedmetadata", () => {
        // Duration is in seconds
        const duration = video.duration;
        resolve(duration);
      });

      video.addEventListener("error", (error) => {
        reject(error);
      });

      // Set the source of the video element to the file object
      video.src = URL.createObjectURL(file);
    });
  };
  const getWaitingRoomData = () => {
    dispatch(getWaitingRoomList());
  };
  useEffect(() => {
    if (editData) {
      let newData = editData?.attachments?.map((e) => {
        return { ...e, fileData: "" };
      });
      if (newData?.length === 0) {
        newData = [
          {
            duration_unit: "",
            fileType: "",
            duration_num: "",
            file: "",
            fileData: "",
          },
        ];
      }
      setInputs({
        clinic_id: clinic_details?._id,
        room_name: editData?.room_name || "",
        age_range: editData?.age_range || "",
        weight_range: editData?.weight_range || "",
        species_id: editData?.species_id || "",
        waitingDocument: newData,
      });
    } else {
      setInputs(defaultInputState);
    }
    setIsChange(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [className]);
  let disableBtn = true;
  if (editData) {
    disableBtn =
      !isChange ||
      !inputs?.room_name ||
      !inputs?.species_id ||
      !inputs?.weight_range ||
      inputs?.waitingDocument?.some(
        (o) => !o?.file || !o?.duration_num || !o?.duration_unit
      );
  } else {
    disableBtn =
      !isChange ||
      !inputs?.room_name ||
      !inputs?.species_id ||
      !inputs?.weight_range ||
      inputs?.waitingDocument?.some(
        (o) => !o?.fileData || !o?.duration_num || !o?.duration_unit
      );
  }

  return (
    <div
      id="add-waiting-room-conatiner"
      className={`ocular-new-point-panel-wrapper position-fixed d-flex flex-column overflow-auto ${className}`}
    >
      <>
        <div
          className={`ocular-new-point-panel-wrapper position-fixed d-flex flex-column overflow-auto ${className}`}
        >
          <div className="head d-flex align-items-center">
            <h2>{editData ? "Edit Waiting Room" : "Add Waiting Room"}</h2>
            <Button
              color="link"
              className="ms-auto panel-close-btn"
              onClick={() => {
                closeNewPointPanel();
              }}
            >
              <span className="material-symbols-outlined">close</span>
            </Button>
          </div>

          <div className="inner align-items-center mt-2">
            <Form>
              <FormGroup className="input-wrapper2 mb-2">
                <Input
                  type="text"
                  name="room_name"
                  value={inputs?.room_name}
                  onChange={handleInputChange}
                  placeholder="Room Title"
                />
              </FormGroup>

              <FormGroup>
                <Input
                  type="select"
                  name="species_id"
                  id="species_id"
                  isRequired={true}
                  value={inputs?.species_id}
                  onChange={(e) => handleInputChange(e)}
                >
                  <option value="">Select</option>
                  {(species || [])?.map((item, i) => (
                    <option
                      key={i}
                      value={item._id}
                      style={{ textTransform: "capitalize" }}
                    >
                      {item?.species_name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Input
                  type="select"
                  name="weight_range"
                  id="weight_range"
                  isRequired={true}
                  value={inputs?.weight_range}
                  onChange={(e) => handleInputChange(e)}
                >
                  <option value="">Select</option>
                  {(weightRangeList || []).map((item, i) => (
                    <option
                      key={i}
                      value={item.value}
                      style={{ textTransform: "capitalize" }}
                    >
                      {item.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup className="input-wrapper2 mb-2">
                <Input
                  type="number"
                  name="age_range"
                  value={inputs?.age_range}
                  onChange={handleInputChange}
                  placeholder="Age Range"
                />
              </FormGroup>
              <div className="add_fils d-flex flex-wrap gap-3 my-2">
                {inputs.waitingDocument?.map((elm, index) => {
                  const {
                    duration_unit,
                    fileType,
                    duration_num,
                    file,
                    attachmentId,
                  } = elm;
                  let isLast = inputs.waitingDocument.length - 1 === index;

                  return (
                    <div key={index} className="fa-center gap-2">
                      {fileType ? (
                        <div className="reupload">
                          <img
                            className="file_box"
                            src={fileType === "mp4" ? mp4File : file}
                            alt="img"
                          />
                          <Input
                            type="file"
                            id="waiting_document"
                            name="waiting_document"
                            onChange={async (e) => {
                              setIsChange(true);
                              let uFileType =
                                e?.target?.files?.[0]?.type
                                  ?.split("/")
                                  ?.pop() || "";
                              let duration = "";
                              if (uFileType === "mp4") {
                                duration = await getVideoDuration(
                                  e?.target?.files?.[0]
                                );
                              }
                              let oldVal = [...inputs.waitingDocument];
                              oldVal[index].fileData =
                                e?.target?.files?.[0] || "";
                              oldVal[index].duration_num = duration
                                ? Math.ceil(duration)
                                : "";
                              oldVal[index].duration_unit = duration
                                ? "second"
                                : "";
                              oldVal[index].file = e?.target?.files?.[0]
                                ? URL.createObjectURL(e?.target?.files?.[0])
                                : "";
                              oldVal[index].fileType = uFileType;
                              setInputs((prev) => {
                                return {
                                  ...prev,
                                  waitingDocument: oldVal,
                                };
                              });
                            }}
                          />
                        </div>
                      ) : (
                        <div className="add_file_btn">
                          <AiOutlineFileAdd size={20} />
                          <Input
                            type="file"
                            id="waiting_document"
                            name="waiting_document"
                            onChange={async (e) => {
                              setIsChange(true);
                              let uFileType =
                                e?.target?.files?.[0]?.type
                                  ?.split("/")
                                  ?.pop() || "";
                              let duration = "";
                              if (uFileType === "mp4") {
                                duration = await getVideoDuration(
                                  e?.target?.files?.[0]
                                );
                              }
                              let oldVal = [...inputs.waitingDocument];
                              oldVal[index].fileData =
                                e?.target?.files?.[0] || "";
                              oldVal[index].duration_num = duration
                                ? Math.ceil(duration)
                                : "";
                              oldVal[index].duration_unit = duration
                                ? "second"
                                : "";
                              oldVal[index].file = e?.target?.files?.[0]
                                ? URL.createObjectURL(e?.target?.files?.[0])
                                : "";
                              oldVal[index].fileType = uFileType;
                              setInputs((prev) => {
                                return {
                                  ...prev,
                                  waitingDocument: oldVal,
                                };
                              });
                            }}
                          />
                        </div>
                      )}
                      <FormGroup className="d-flex align-items-center gap-2 mb-0">
                        <Input
                          type="select"
                          name="duration_unit"
                          value={duration_unit}
                          onChange={(e) => {
                            setIsChange(true);
                            let oldVal = [...inputs.waitingDocument];
                            oldVal[index].duration_unit = e.target.value;
                            setInputs((prev) => {
                              return {
                                ...prev,
                                waitingDocument: oldVal,
                              };
                            });
                          }}
                        >
                          <option value="">Select</option>
                          <option value="second">Seconds</option>
                          <option value="minute">Minutes</option>
                        </Input>

                        <Input
                          type="number"
                          placeholder="Enter value"
                          value={duration_num}
                          name="duration_num"
                          onChange={(e) => {
                            setIsChange(true);
                            let oldVal = [...inputs.waitingDocument];
                            oldVal[index].duration_num = e.target.value;
                            setInputs((prev) => {
                              return {
                                ...prev,
                                waitingDocument: oldVal,
                              };
                            });
                          }}
                        />
                      </FormGroup>

                      {isLast ? (
                        <div
                          className="b-e3e3 br-10 h-100 f-center ps-3 pe-3 cursor-pointer"
                          onClick={() => {
                            setInputs((prev) => {
                              return {
                                ...prev,
                                waitingDocument: [
                                  ...prev.waitingDocument,
                                  {
                                    duration_unit: "",
                                    fileType: "",
                                    duration_num: "",
                                    file: "",
                                    fileData: "",
                                  },
                                ],
                              };
                            });
                          }}
                        >
                          <AiFillPlusCircle color="#11ca9d" size={20} />
                        </div>
                      ) : (
                        <div
                          className="b-e3e3 br-10 h-100 f-center ps-3 pe-3 cursor-pointer"
                          onClick={async () => {
                            let isDelete = false;
                            if (attachmentId) {
                              let res = await handleRemove(attachmentId);
                              if (res?.status) {
                                isDelete = true;
                              }
                            } else {
                              isDelete = true;
                            }
                            if (isDelete) {
                              setInputs((prev) => {
                                return {
                                  ...prev,
                                  waitingDocument: prev.waitingDocument?.filter(
                                    (_, fIndex) => fIndex !== index
                                  ),
                                };
                              });
                            }
                          }}
                        >
                          <AiFillCloseCircle color="#dc3545" size={20} />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>

              <div className="btn-blc border_btn mt-3 text-end">
                <Button
                  color="success"
                  type="submit"
                  disabled={disableBtn}
                  className="text-nowrap w-300 h-45"
                  onClick={(e) => {
                    if (!btnLoading) {
                      handleSave(e);
                    }
                  }}
                >
                  {editData ? "UPDATE WAITING ROOM" : "CREATE WAITING ROOM"}{" "}
                  {btnLoading && <Spinner size="sm" className="ms-2" />}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </>
    </div>
  );
};

export default AddWaitingRoom;
