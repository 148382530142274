import React from "react";
import { PublicLayout } from "../layouts";
import { Redirect } from "react-router-dom";
import Login from "../components/auth-module/Login";
import SignUp from "../components/auth-module/SignUp";
import ForgotPassword from "../components/auth-module/ForgotPassword";
import ResetPassword from "../components/auth-module/ResetPassword";
import ChangePasswordBlock from "../components/auth-module/ChangePasswordBlock";
import TermsOfServices from "../components/auth-module/terms/TermsOfServices";
import NoAccess from "components/no-access";

const AuthRoutes = [
  {
    path: "/",
    exact: true,
    layout: PublicLayout,
    component: () => <Redirect to="/login" />,
  },
  {
    path: "/login",
    layout: PublicLayout,
    component: Login,
  },
  {
    path: "/forgot-password",
    layout: PublicLayout,
    component: ForgotPassword,
  },
  {
    path: "/register",
    layout: PublicLayout,
    component: SignUp,
  },
  {
    path: "/reset-password/:passwordtoken",
    layout: PublicLayout,
    component: ResetPassword,
  },
  {
    path: "/change-password",
    layout: PublicLayout,
    component: ChangePasswordBlock,
  },
  {
    path: "/terms-of-services",
    layout: PublicLayout,
    component: TermsOfServices,
  },
 
];

export default AuthRoutes;
