import React, { useEffect, useState } from "react";
import FilterBlock from "./FilterBlock";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAppointmentsByPatientId,
  getAllAppointmentsWithFilterOfPetOwner,
  getAllClinicId,
  getAllDoctorId,
} from "../../../../../context/actions/appointment";
import AppointmentDetailsPanel from "../../../../../shared/Calendar/AppointmentDetailsPanel";
import NodataFoundBlock from "../../../../../shared/NoDataFoundBlock";
import moment from "moment";
import {
  setAppointmentDetailPanel,
  setAppointmentDetailData,
} from "../../../../../context/actions/appointmentDetailPanelAction";

import UpcomingVisitCard from "./UpcomingVisitCard";
import "./index.scss";
import PastVisitCard from "./PastVisitCard";

const Visits = ({ petId }) => {
  const [appointments, setAppointments] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const [past, setPast] = useState([]);
  const [clinicData, setClinicData] = useState([]);
  const [doctorData, setdoctorData] = useState([]);
  const dispatch = useDispatch();
  const [filterPayload, setFilterPayload] = useState({
    searchText: "",
    doctor_id: "",
    clinic_id: "",
    type: "",
    start_date: "",
    end_date: "",
  });

  const appointmentDetailsState = useSelector(
    (state) => state.appointmentDetailPanelReducer.isAppointmentDetailPanel
  );

  const appointmentDetailPanelState = useSelector(
    (state) => state.appointmentDetailPanelReducer.isAppointmentDetailPanel
  );

  const { clinic_details } = useSelector((state) => state.clinicReducer);

  const showAppointmentDetails = (appointmentData) => {
    dispatch(setAppointmentDetailData(appointmentData));
    //dispatch(getPetDetailsById(appointmentData?.patient_id?._id, {},  () => { }));
    dispatch(setAppointmentDetailPanel(!appointmentDetailsState));
  };

  let beforeToday = (item) => {
    return !!item?.appointment_end;
    // let date = moment(item.appointment_date, "DD-MM-YYYY");
    // let today = moment();
    // return date.isBefore(today);
  };

  let afterToday = (item) => {
    return !item?.appointment_end;
    // let date = moment(item.appointment_date, "DD-MM-YYYY");
    // let today = moment();
    // return date.isSameOrAfter(today) || (item.appointment_start && !item.appointment_end);
  };

  const handleGetAppointments = async () => {
    dispatch(
      getAllAppointmentsWithFilterOfPetOwner(
        petId,
        { ...filterPayload },
        (res) => {
          const isFilterPayloadEmpty = Object.values(filterPayload)?.some((o) => o !== "")
          if (!isFilterPayloadEmpty) {
            const clinicList = res
              ?.filter((o) => o?.clinic_details?._id)
              ?.map((list) => ({
                clinic_name: list?.clinic_details?.clinic_name,
                _id: list?.clinic_details?._id,
              }));

            const uniqueClinicIds = new Set();
            const uniqueClinics = clinicList?.filter((clinic) => {
              if (uniqueClinicIds?.has(clinic?._id)) {
                return false;
              } else {
                uniqueClinicIds?.add(clinic?._id);
                return true;
              }
            });
            setClinicData(uniqueClinics);


            const doctorList = res?.filter((o) => o?.doctor_id?._id)?.map((list) => ({
              doctor_name: list?.doctor_id?.fullname,
              _id: list?.doctor_id?._id
            }))
            const uniqueDoctorIds = new Set();
            const uniquDoctorList = doctorList?.filter((item) => {
              if (uniqueDoctorIds?.has(item?._id)) {
                return false;
              } else {
                uniqueDoctorIds?.add(item?._id)
                return true;
              }
            })
            setdoctorData(uniquDoctorList)
          }


          setAppointments(res);
          let up = res
            .filter(afterToday)
            ?.sort(
              (a, b) =>
                moment(a.appointment_date, "DD-MM-YYYY") -
                moment(b.appointment_date, "DD-MM-YYYY")
            );
          let pa = res
            .filter(beforeToday)
            ?.sort(
              (a, b) =>
                moment(b.appointment_date, "DD-MM-YYYY") -
                moment(a.appointment_date, "DD-MM-YYYY")
            );
          setUpcoming(up);
          setPast(pa);
        }
      )
    );

    // dispatch(
    //   getAllClinicId(clinic_details._id, (res) => {
    //     setClinicData(res);
    //   })
    // );

    // dispatch(
    //   getAllDoctorId(clinic_details.user_id, (res) => {
    //     setdoctorData(res);
    //   })
    // );
    // dispatch(
    //   getAllAppointmentsByPatientId(petId, (response) => {
    //     setAppointments(response);
    //     let up = response
    //       .filter(afterToday)
    //       ?.sort(
    //         (a, b) =>
    //           moment(a.appointment_date, "DD-MM-YYYY") -
    //           moment(b.appointment_date, "DD-MM-YYYY")
    //       );
    //     let pa = response
    //       .filter(beforeToday)
    //       ?.sort(
    //         (a, b) =>
    //           moment(b.appointment_date, "DD-MM-YYYY") -
    //           moment(a.appointment_date, "DD-MM-YYYY")
    //       );
    //     setUpcoming(up);
    //     setPast(pa);
    //   })
    // );
  };

  useEffect(() => {
    handleGetAppointments(filterPayload);
    dispatch(setAppointmentDetailPanel(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterPayload]);

  useEffect(() => {
    if (!appointmentDetailPanelState) {
      handleGetAppointments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentDetailPanelState]);


  return (
    <>
      <div className="visits-wrapper">
        <FilterBlock
          filterPayload={filterPayload}
          setFilterPayload={setFilterPayload}
          clinicData={clinicData}
          doctorData={doctorData}
        />
        {appointments.length === 0 ? (
          <NodataFoundBlock type={'appointment'} />
        ) : (
          <>
            {upcoming?.length !== 0 && (
              <div className="upcoming-visits">
                <div className="head">
                  <span>Next Upcoming This Week</span>
                </div>
                <div className="body align-items-stretch">
                  {upcoming?.map((item, i) => {
                    return (
                      <UpcomingVisitCard
                        key={i}
                        onClick={() => {
                          showAppointmentDetails(item);
                        }}
                        data={item}
                      />
                    );
                  })}
                </div>
              </div>
            )}

            {past?.length !== 0 && (
              <div className="past-visits mt-2">
                <div className="head">
                  <span>Past Visits</span>
                </div>
                <div className="body h-100 pb-3">
                  {past?.map((item, i) => {
                    return (
                      <PastVisitCard
                        onClick={() => {
                          showAppointmentDetails(item);
                        }}
                        data={item}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div
        className={`patient-panel-overlay position-fixed ${appointmentDetailPanelState ? "active" : ""
          }`}
        onClick={() => dispatch(setAppointmentDetailPanel(false))}
      ></div>
      <AppointmentDetailsPanel
        className={`${appointmentDetailPanelState ? "active" : ""}`}
      />
    </>
  );
};

export default Visits;
