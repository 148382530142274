import React, { useEffect } from "react";
import PetDetails from "./PetDetails";
import { useSelector } from "react-redux";
import "../../index.scss";

const MyPets = () => {

    const { petAndOwnerDetails } = useSelector(state => state.petReducer);
    const { permissionsData } = useSelector(
        (state) => state.clinicPermissionReducer
      );
      const permissions = permissionsData;
    return (
        <>
            <div className="w-100 row m-auto my-pets">
                <div className="row">
                    {
                        petAndOwnerDetails && petAndOwnerDetails.pet_details.map((ele) => {
                            return (
                                <div className="col-md-4">
                                    <PetDetails pet={ele} />
                                </div>
                            )
                        })
                    }

                    {/* <div className="col-md-6">
                            <PetDetails />
                        </div> */}


                


                  {/*permissions?.edit_patients_details && <div className="col-md-4">
                        <div className="addner_mypet_box">
                            Add New
                        </div>
                    </div>*/}
                </div>
            </div>
        </>
    );
};

export default MyPets;
