// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'; 
import { connectAuthEmulator, getAuth, setPersistence, browser, browserSessionPersistence } from "firebase/auth";

/*
const firebaseConfig = {
  apiKey: "AIzaSyAqtVusLOrhsPPo_jU8C5X955T2k7hZjvQ",
  authDomain: "vetrecorpims.firebaseapp.com",
  projectId: "vetrecorpims",
  storageBucket: "vetrecorpims.appspot.com",
  messagingSenderId: "717404997928",
  appId: "1:717404997928:web:17ded24ed516de2f13e81d",
  measurementId: "G-B9D0JZCGRT"
}
// Initialize Firebase local emulator

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app); 
const auth = getAuth(app);

connectAuthEmulator(auth, "http://127.0.0.1:9099");
export {auth};

const db = getFirestore();
connectFirestoreEmulator(db, '127.0.0.1', 8080);

export default db;
*/

/*

  apiKey: "AIzaSyDE1MXSKig7uQhMI_Ur4f7byRLmbvwpk9o",
  authDomain: "vetrecordev.firebaseapp.com",
  projectId: "vetrecordev",
  storageBucket: "vetrecordev.appspot.com",
  messagingSenderId: "881974526005",
  appId: "1:881974526005:web:0dd808808df1eac515b17c"

  apiKey: "AIzaSyCSDNyPu7wjj8cohR8AEzTJ_EJDlQOBtw8",
  authDomain: "vetrecordemo.firebaseapp.com",
  projectId: "vetrecordemo",
  storageBucket: "vetrecordemo.appspot.com",
  messagingSenderId: "409489534937",
  appId: "1:409489534937:web:62514fae0be155727fb3c8"
};
*/

const firebaseConfig = {

  apiKey: "AIzaSyDE1MXSKig7uQhMI_Ur4f7byRLmbvwpk9o",
  authDomain: "vetrecordev.firebaseapp.com",
  projectId: "vetrecordev",
  storageBucket: "vetrecordev.appspot.com",
  messagingSenderId: "881974526005",
  appId: "1:881974526005:web:0dd808808df1eac515b17c"
  
};


// Initialize Firebase 


// const firebaseConfig = {
//   apiKey: "AIzaSyAqtVusLOrhsPPo_jU8C5X955T2k7hZjvQ",
//   authDomain: "vetrecorpims.firebaseapp.com",
//   projectId: "vetrecorpims",
//   storageBucket: "vetrecorpims.appspot.com",
//   messagingSenderId: "717404997928",
//   appId: "1:717404997928:web:17ded24ed516de2f13e81d",
//   measurementId: "G-B9D0JZCGRT"
// }

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const fireauth = getAuth(app);

setPersistence(fireauth, browserSessionPersistence)
export const auth = fireauth



export default getFirestore();

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBEgNyam9X-c9_Boy5lV3b6WBQ6ZljFm1o",
//   authDomain: "inpleo-a92bf.firebaseapp.com",
//   projectId: "inpleo-a92bf",
//   storageBucket: "inpleo-a92bf.appspot.com",
//   messagingSenderId: "807210193267",
//   appId: "1:807210193267:web:7cdd0d1da86a143c393506",
//   measurementId: "G-PPTYMFZ28H"
// };
