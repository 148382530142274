import React from "react";
import SectionView from "./SectionView";
import { Button, Row } from "reactstrap";
import SubjectiveInfo from "./SubjectiveInfo";
import ObjectiveInfo from "./ObjectiveInfo";
import VitalInfo from "./VitalInfo";
import PlanInfo from "./PlanInfo";
import NoteInfo from "./NoteInfo";
import moment from "moment"
import './index.scss';

const NoteDetail = ({ extend, sections, build, note}) => {

	return ( 
		<>
			<div className="note-detail-wrapper">
				{sections?.map((section, i) =>
					<SectionView key={i} section={section} note={note} />
				)}


				{extend?.map((item, i) =>
				{
					return(
					<div style={{gap: '10px', display:'flex', flexDirection: 'column'}}>
		
					<p style={{fontSize:'13px'}}>Original Note Created At: {moment(item?.createdAt).format('MM/DD/YYYY hh:mm a')}</p>
					{
					item?.sections?.map((section, i) => 
					<SectionView isExtend={true} key={i} section={section} />)}
					</div>)} 
				)}
				 
				{/*
				<SubjectiveInfo />
				<ObjectiveInfo />*/}
				
				<div className="mt-2 mb-4 pt-1">
					<Button className="ms-auto mr-auto d-flex" color={'primary'} onClick={build}> Build off</Button>
				</div>
			</div>


		</>
	);
};

export default NoteDetail;
