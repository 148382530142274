import React, { useEffect, useState } from "react";
import { Button, Table } from "reactstrap";
import CheckBox from "../../../../shared/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { getAllAuditLogs } from "../../../../context/actions/auditLogs";
import moment from "moment";
import { getAllRoles } from "../../../../context/actions/app-content";
import AuditLogFilter from "./AuditLogFilter";
import PaginationBlock from "../../../../shared/PaginationBlock";
import UserPlaceholder from "../../../../shared/UserPlaceholder";
import { useHistory } from "react-router-dom";
import NodataFoundBlock from "shared/NoDataFoundBlock";

const AuditLog = () => {
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const dispatch = useDispatch();
  const { auditLogs } = useSelector((state) => state.auditLogsReducer);
  const { roles } = useSelector((state) => state.appContentReducer);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    handleGetAuditLogs();
  }, [page, limit]);
  const handleGetAuditLogs = () => {
    setLoading(true);
    dispatch(
      getAllAuditLogs({ page, limit }, (res) => {
        setLoading(false);
      })
    );
    if (!roles) {
      dispatch(getAllRoles({}, () => {}));
    }
  };

  const _handlePageChange = (page) => {
    setPage(page);
  };

  const handleProfileClick = (item) => {
    history.push(`/patient-detail/${item?.patient?._id}`);
  };

  // console.log("auditLogs", auditLogs)

  return (
    <>
      <div className="audit-log-blc">
        <AuditLogFilter roles={roles || []} />
        {auditLogs?.docs?.length > 0 ?
        <>
        <div className="table-listing-blc overflow-auto">
          <Table responsive className="checkbox-listing">
            <thead>
              <tr>
                <th>
                  <CheckBox
                    type="checkbox"
                    ischecked={false}
                    className="no-label"
                  />
                </th>
                <th style={{ minWidth: "172px" }}>Activity Date</th>
                <th style={{ minWidth: "200px" }}>Modified by</th>
                <th>Audit Type</th>
                <th>Action Taken</th>
                <th style={{ minWidth: "318px" }}>Activity Brief</th>
                <th style={{ minWidth: "180px" }}>Related To</th>
              </tr>
            </thead>
            <tbody>
              {(auditLogs?.docs || []).map((item) => {
                return (
                  <tr>
                    <td>
                      <CheckBox
                        type="checkbox"
                        ischecked={false}
                        className="no-label"
                      />
                    </td>
                    <td>
                      {moment(item.createdAt).format("MM-DD-yyyy hh:mm:ss")}
                    </td>
                    <td>
                      <Button
                        color="link"
                        className="p-0 text-start pic-cell d-inline-flex align-items-center"
                      >
                        <div className="pic d-inline-flex position-relative">
                          {item?.modifiedby?.user_image ? (
                            <img
                              className="img-fluid"
                              src={item?.modifiedby?.user_image}
                              alt=""
                            />
                          ) : (
                            <UserPlaceholder />
                          )}
                        </div>
                        {`${item?.modifiedby?.title || ""} ${
                          item?.modifiedby?.firstname
                        } ${item?.modifiedby?.lastname}`}
                      </Button>
                    </td>
                    <td style={{ textTransform: "capitalize" }}>
                      {item.audit_type}
                    </td>
                    <td>{item.action_taken}</td>
                    <td>{item.modification_brief}</td>
                    {/* <td>
                                                <Button color="link" className="p-0 text-start pic-cell d-inline-flex align-items-center">
                                                    <div className="pic d-inline-flex position-relative">
                                                        {item?.relatedto?.user_image ? <img className="img-fluid" src={item?.relatedto?.user_image} alt="" /> :
                                                            <UserPlaceholder />}
                                                        {
                                                            true ? <span className="position-absolute badge-icon">
                                                                <img className="img-fluid" src={require('../../../../assets/img/vetRecorEMR/foot-badge-yellow.png')} alt="" />
                                                            </span> :
                                                                <span className="position-absolute badge-icon">
                                                                    <img className="img-fluid" src={require('../../../../assets/img/vetRecorEMR/foot-badge-green.png')} alt="" />
                                                                </span>
                                                        }
                                                    </div>
                                                    {`${item?.relatedto?.title || ''} ${item?.relatedto?.firstname} ${item?.relatedto?.lastname}`}
                                                </Button>

                                            </td> */}
                    <td>
                      <Button
                        color="link"
                        className="p-0 text-start pic-cell d-inline-flex align-items-center"
                        onClick={() => {
                          handleProfileClick(item);
                        }}
                      >
                        <div className="pic d-inline-flex position-relative">
                          {item?.patient ? (
                            item?.patient?.pet_image ? (
                              <img
                                className="img-fluid"
                                src={item?.patient?.pet_image}
                                alt=""
                              />
                            ) : (
                              <UserPlaceholder />
                            )
                          ) : (
                            ""
                          )}
                        </div>
                        {`${item?.patient?.pet_name || ""}`}
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <PaginationBlock
          limit={limit}
          page={page}
          pagingCounter={auditLogs?.pagingCounter || 1}
          docLength={auditLogs?.docs?.length || 0}
          docPage={auditLogs.page || 1}
          totalPages={auditLogs.totalPages || 1}
          totalDocs={auditLogs.totalDocs || 0}
          _handleLimit={(val) => {
            setLimit(val);
          }}
          _handlePageChange={_handlePageChange}
        />
        </> : <NodataFoundBlock type={'search result'} />}
      </div>
    </>
  );
};

export default AuditLog;
