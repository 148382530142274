import * as Yup from "yup";
import { REGEX } from "../../../utils/constants";
/**
 * @description declaration form schema for client validations
 */
export const resetPasswordSchema = Yup.object().shape({
  forgot_password_code: Yup.string()
  .required("Security Code is required"),
  password: Yup.string()
    // .matches(/^\S*$/, 'Space is not allowed')
    // .min(8, "Password must be atleast 8 characters long")
    // .max(50, "Maximum 50 symbols")
    .required("Password field is required")
    .matches(REGEX.NEW_PASSWORD,
      'password_pattern'),
  // .matches(REGEX.PASSWORD,
  //   'Password must contain atleast one alphabet, one special character and one numeric value'),
  confirmPassword: Yup.string()
    .required("Confirm password field is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match")
});

/**
 * @description params intilaization 
 */

export const resetPasswordInit = {
  forgot_password_code: '',
  password: '',
  confirmPassword: ''
}