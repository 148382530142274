import React, { useState } from "react";
import { Button } from "reactstrap";
import UserPlaceholder from "../../../../../shared/UserPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useClinicSpecies } from "hooks/useClinicSpecies";
import {useClinicBreed} from "hooks/useClinicBreed"

const SlotItemName = ({ className = "", appointmentData, click }) => {
  console.log("slot", appointmentData);
  const dispatch = useDispatch();
  const clinicSpecies = useClinicSpecies();
  const clinicBreed = useClinicBreed();

  const appointmentDetailsState = useSelector(
    (state) => state.appointmentDetailPanelReducer.isAppointmentDetailPanel
  );

  return (
    <>
      <Button
        onClick={click}
        color="link"
        className={`slot-item d-flex flex-column ${className} ${appointmentData?.appointment_type === "in-clinic"
            ? "clinic"
            : appointmentData?.appointment_type === "phone-call"
              ? "phone"
              : appointmentData?.appointment_type === "video-conference"
                ? "video-conference"
                : ""
          }`}
      >
        <div className="time-txt" style={{textTransform: 'lowercase'}}>
        {moment(appointmentData?.boarding_start_date).format('MM/DD/YYYY h:mm a')} -  {moment(appointmentData?.boarding_end_date).format('MM/DD/YYYY h:mm a')}
        </div>
        {appointmentData?.patient_id?.pet_image ? (
          <div className="picCell d-inline-flex align-items-center flex-shrink-0">
            <div className="cell overflow-hidden">
              {appointmentData?.patient_id?.pet_image ? (
                <img
                  className="img-fluid w-100 h-100"
                  src={appointmentData?.patient_id?.pet_image}
                  alt="User"
                />
              ) : (
                <UserPlaceholder />
              )}
            </div>
            {clinicBreed(appointmentData?.patient_id?.breed?._id) ||   appointmentData?.patient_id?.breed?.breed} /{" "}
            {clinicSpecies(appointmentData?.patient_id?.breed?.species_id?._id) ||  appointmentData?.patient_id?.breed?.species_id?.species_name}
          </div>
        ) : (
          <div className="picCell d-inline-flex align-items-center flex-shrink-0">
            <div className="cell overflow-hidden">
              <img
                className="img-fluid w-100 h-100"
                src={appointmentData?.pet_image}
                alt="User"
              />{" "}
              : <UserPlaceholder />
            </div>
            {appointmentData?.pet_name} / {appointmentData?.gender}
          </div>
        )}
      </Button>
    </>
  );
};

export default SlotItemName;
