import React, {useState, useEffect} from "react";
import moment from "moment";
import { Button, Popover, PopoverBody, Input, FormGroup } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addNewIntakeOutput } from "../../../../../../../../context/actions/patient-module/flowsheets/IO";

import './index.scss';
import { Form } from "formik";






const IOAddPoint = ({results, time, index, item_id, popover, popoverToggle, refresh, view }) => {
    const dispatch = useDispatch()
    const [value, setValue] = useState(false)
    const [timeVal, setTimeVal] = useState(false)
    const [isNewPoint, setIsNewPoint] = useState(results?.length === 0)
    const params = useParams();
	const petId = params.petId;



    const userDetails = useSelector(state => state.user.user_details);



    useEffect(() => {
        if(results?.length === 0) {
            setIsNewPoint(true)
        } else{
            setIsNewPoint(false)
        }
    }, [index, popover, results])

    const handleSave = () => {
        dispatch(addNewIntakeOutput({value: value, 
                                    date: time?.set({
                                                    hour: moment(timeVal, "HH:mm").get('hour'), 
                                                    minute: moment(timeVal, "HH:mm").get("minute")}).toISOString(),
                                    pet_id: petId,
                                    created_by: userDetails._id,
                                    intake_output_item_id: item_id
                                    }, 
                                    (result) => {if (result.status) {
                                        refresh()
                                        popoverToggle();
                                    }
                                        
                                    })
                                                    )
    }





    return (
           
        
            <Popover container={"io-wrapper"} trigger={"legacy"} className={'io-popover'} placement="top" isOpen={popover} toggle={popoverToggle} target={index}>
                
                { ({scheduleUpdate}) =>  ( isNewPoint ?
                <PopoverBody className={'new-tag'}>
                    <FormGroup>
                        <Input type="number" placeholder="Add Value" onChange={(event) => {setValue(event?.target?.value)}}/>
                    </FormGroup>
                    <FormGroup>
                        <Input min={`${time?.format('HH')}:00`} max={`${time?.format('HH')}:59`} type="time" placeholder="Select Time" onChange={(event) => {setTimeVal(event?.target?.value)}}/>
                    </FormGroup>
                    <FormGroup>
                        <div className="btn-cell d-flex">
                            <Button color="primary" onClick={handleSave} className="flex-fill">
                                Save
                            </Button>
                        </div>
                    </FormGroup>
                </PopoverBody>
            :
                <PopoverBody className={'new-tag'}>
                    <table className='points-list'>
                        <caption style={{captionSide: 'top'}} className='time'><div className='time'>{time?.format(view === 'day' ? 'h A' : 'M/D')}</div></caption>
                        <thead>
                        <tr>
                            <th className="time">Time</th>
                            <th className="time">Value</th>
                        </tr>
                        </thead>
                        <tbody>
                    {results?.map((res, i) => {
                        return(
                            <tr key={i} className="data">
                                <td >{moment(res?.date).format("h:mm A")}</td><td style={{textAlign: 'right'}} >{res?.value}</td> 
                            </tr>
                        )
                    })}
                        </tbody>
                    </table>
                    <Button onClick={() => {

                        setIsNewPoint(true);
                        scheduleUpdate()
                    }} color="link"  className="add-btn d-flex">
                        <span className="icomoon-plus-btn"></span>
                    </Button>
                    
                </PopoverBody> )}
            </Popover>
            
    )
}


export default IOAddPoint
