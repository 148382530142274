import React, { useState } from "react";
import { Button } from "reactstrap";
import UserPlaceholder from "../../../shared/UserPlaceholder";
import moment from "moment";
import {
  setAppointmentDetailPanel,
  setAppointmentDetailData,
} from "../../../context/actions/appointmentDetailPanelAction";
import { getPetDetailsById } from "../../../context/actions/patient-module/patient/pet";
import { useDispatch, useSelector } from "react-redux";
import { getAppointmentTimingDisplayValues } from "utils/calendar/constant";
import { useClinicSpecies } from "hooks/useClinicSpecies";
import { useClinicBreed } from "hooks/useClinicBreed";

const SlotItemName = ({ className = "", appointmentData }) => {
  const clinicSpecies = useClinicSpecies()
  const clinicBreed = useClinicBreed()
  const dispatch = useDispatch();
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );

  const appointmentDetailsState = useSelector(
    (state) => state.appointmentDetailPanelReducer.isAppointmentDetailPanel
  );

  const showAppointmentDetails = () => {
    dispatch(setAppointmentDetailData(appointmentData));
    dispatch(
      getPetDetailsById(
        appointmentData?.patient_id?._id || appointmentData?.patient_id,
        {},
        () => {}
      )
    );
    dispatch(setAppointmentDetailPanel(!appointmentDetailsState));
  };

  /*appointmentData?.appointment_type === "in-clinic"
          ? "clinic"
          : appointmentData?.appointment_type === "phone-call"
            ? "phone"
            : appointmentData?.appointment_type === "video-conference"
              ? "video-conference"
              : ""
  */

  return (
    <>
      <Button
        onClick={appointmentData?.patient_id?._id && showAppointmentDetails}
        color="link"
        className={`slot-item d-flex flex-column ${className}`}
        style={{
          backgroundColor:
            clinic_settings?.color_preferences?.[
              appointmentData?.appointment_type
            ],
          border: "1px solid #eeeef2",
        }}
      >
        <div className="time-txt" style={{ textTransform: "lowercase" }}>
          {appointmentData.appointment_timings &&
            appointmentData.appointment_timings.length > 0 &&
            getAppointmentTimingDisplayValues(
              appointmentData.appointment_timings
            )?.map((item) => {
              return (
                <>
                  {item}
                  <br />
                </>
              );
            })}
        </div>
        {appointmentData.boarding_start_time && (
          <div className="time-txt">
            {appointmentData?.boarding_start_time +
              "-" +
              appointmentData?.boarding_end_time}
          </div>
        )}
        {appointmentData.surgery_start_time && (
          <div className="time-txt">
            {appointmentData?.surgery_start_time +
              "-" +
              appointmentData?.surgery_end_time}
          </div>
        )}

        {/* <div className="time-txt">
          {appointmentData?.appointment_time
            ? appointmentData?.appointment_time
            : appointmentData?.boarding_start_time
              ? appointmentData?.boarding_start_time +
              "-" +
              appointmentData?.boarding_end_time
              : appointmentData?.surgery_start_time +
              "-" +
              appointmentData?.surgery_end_time}
        </div> */}
        {appointmentData?.patient_id?.pet_image ? (
          <div className="picCell d-inline-flex align-items-center flex-shrink-0">
            <div className="cell overflow-hidden">
              {appointmentData?.patient_id?.pet_image &&
              appointmentData?.appointment_type !== "appointment-block" ? (
                <img
                  className="img-fluid w-100 h-100"
                  src={appointmentData?.patient_id?.pet_image}
                  alt="User"
                />
              ) : (
                <UserPlaceholder />
              )}
            </div>
            {appointmentData?.patient_id?.pet_name} /{" "}
            {clinicSpecies(appointmentData?.patient_id?.breed?.species_id?._id) || appointmentData?.patient_id?.breed?.species_id?.species_name} /{" "}
            {clinicBreed(appointmentData?.patient_id?.breed?._id) ||   appointmentData?.patient_id?.breed?.breed}
          </div>
        ) : (
          <div className="picCell d-inline-flex align-items-center flex-shrink-0">
            {appointmentData?.appointment_type !== "appointment-block" && (
              <div className="cell overflow-hidden">
                <img
                  className="img-fluid w-100 h-100"
                  src={appointmentData?.pet_image}
                  alt="User"
                />
                {/* : <UserPlaceholder /> */}
              </div>
            )}
            {appointmentData?.appointment_type !== "appointment-block" ? (
              <p>
                 {appointmentData?.patient_id?.pet_name} /{" "}
                {clinicSpecies(appointmentData?.patient_id?.breed?.species_id?._id) || appointmentData?.patient_id?.breed?.species_id?.species_name} /{" "}
                {appointmentData?.patient_id?.breed?.breed}
              </p>
            ) : (
              <p>
                {appointmentData?.doctor_id?.title +
                  " " +
                  appointmentData?.doctor_id?.firstname +
                  " " +
                  appointmentData?.doctor_id?.lastname}{" "}
                / {appointmentData?.description}
              </p>
            )}
          </div>
        )}
      </Button>
    </>
  );
};

export default SlotItemName;
