import React, { useState } from "react";
import { useField } from "formik";
import { FormFeedback, FormGroup, Input } from "reactstrap";
import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import "react-datepicker/dist/react-datepicker.css";
import { months } from "../../utils/constants";
import { FaCalendarAlt } from "react-icons/fa";
// import { FormattedMessage } from 'react-intl';
const DateBoxInput = ({
  name,
  className,
  id,
  label,
  required,
  placeholder,
  sendInputDate,
  smLabel = false,
  value,
  todayField,
  calendarplacement = "top-start",
  future,
}) => {
  const [inputDate, setInputDate] = useState(value);
  const years = range(1970, getYear(new Date()) + (todayField ? 1 : 100), 1);

  // const [field, meta] = useField(name);

  const handleDecreaseMonth = (e, decreaseMonth) => {
    e.preventDefault();
    decreaseMonth();
  };

  const handleIncreaseMonth = (e, increaseMonth) => {
    e.preventDefault();
    increaseMonth();
  };
  return (
    <FormGroup className={`input-wrapper date-wrapper ${className}`}>
      {/* {
				label && <>
					<label htmlFor={id} >
						<span className={required && 'required'}>{label}</span>
					</label>
				</>
			} */}
      {label && (
        <>
          <label htmlFor={id}>
            {label}
            {smLabel && (
              <>
                <span className="sml-txt">{smLabel}</span>
              </>
            )}
            {required && <span className="required"></span>}
          </label>
        </>
      )}
      <div className={`date-cell position-relative`}>
        <DatePicker
          placeholderText={placeholder}
          className="form-control"
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                className="prev-btn"
                onClick={(e) => handleDecreaseMonth(e, decreaseMonth)}
                disabled={prevMonthButtonDisabled}
              >
                {"<"}
              </button>
              <select
                value={getYear(date)}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <button
                className="next-btn"
                onClick={(e) => handleIncreaseMonth(e, increaseMonth)}
                disabled={nextMonthButtonDisabled}
              >
                {">"}
              </button>
            </div>
          )}
          minDate={future ? new Date() : false}
          maxDate={todayField ? new Date() : false}
          selected={inputDate}
          onChange={(date) => {
            setInputDate(date);
            sendInputDate(date);
          }}
          required={required}
          popperPlacement={calendarplacement}
        />

        {/* <Input
          type="date"
          {...field}
          {...props}
          //   className={`${props?.inputClass}`}
          autoComplete="off"
        /> */}
      </div>

      {/* {meta.touched && meta.error && (
				<FormFeedback className="d-block"> {meta?.error}
					<FormattedMessage id={meta.error} values={{
            name: props?.placeholder || 'This'
          }} />
				</FormFeedback>
			)} */}
    </FormGroup>
  );
};
export default DateBoxInput;
