import React, { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import LoaderUI from "../../../../../../shared/loader";
import CalendarFilter from "../../../../../../shared/Calendar/CalendarFilter";


import NodataFoundBlock from "../../../../../../shared/NoDataFoundBlock";
import "./index.scss"
import IOTable from "./IOTable";
import moment from "moment";
import { dayTimeSpan } from "../../../../../../utils/calendar/constant";
import { getNextWeekSpanDays } from "../../../../../../utils/calendar/constant";
import { getIntakeOutputStatsByPetId, getIntakeOutputResultsByPetId, getAndFilterIntakeOutputResults } from "../../../../../../context/actions/patient-module/flowsheets/IO";
import CalendarFilterWeekDay from "../../../../../../shared/Calendar/CalendarFilterWeekDay";


const IO = ({ petId }) => {


    const [elemIndex, setElemIndex] = useState(null);
    const [viewData, setViewData] = useState(false);
    const [viewValue, setViewValue] = useState('day');
    const [times, setTimes] = useState([]);
    const dispatch = useDispatch();
    const { results, stats, loading } = useSelector(state => state.intakeOutputReducer);

    const handleGetStats = () => {
        let d =  new Date();
        dispatch(getIntakeOutputStatsByPetId(  {pet_id: petId, date: d}, (response) => {}))
    }

    const getTimes = () => {
        if (viewValue === 'day') {
            setTimes(dayTimeSpan.map((item) => {
                //console.log(moment([viewData.year_value, viewData.month_value, viewData.date_value, (item.value % 12 + (item.name === 'am' ? 0 : 12))]))
                return moment([viewData.year_value, viewData.month_value - 1, viewData.date_value, (item.value % 12 + (item.name === 'am' ? 0 : 12))])
            }))
        }
        else if (viewValue === 'week') {
            let dates = getNextWeekSpanDays(
                viewData.span_date,
                viewData.span_month,
                viewData.last_week_span ? [(viewData.year_value - 1), viewData.year_value] : viewData.year_value
            )
            setTimes(dates.map(date => moment(date.full_date, "DD-MM-YYYY")))
        }
        else {
            return setTimes([])
        }
    }

    const handleFilterChange = (value) => {
        setViewData(value);
    }

    const handleGetResults = () => {
        if (viewData) {
            let startDate, endDate
            let body = { pet_id: petId }
            let interval = 'day'
            if (viewData?.view_type === 'day') {
                startDate = (new Date(viewData?.year_value, viewData?.month_value - 1, viewData?.date_value)).toLocaleString()
                endDate = (new Date(viewData?.year_value, viewData?.month_value - 1, viewData?.date_value + 1)).toLocaleString()
                interval = 'hour'

            } else {
                startDate = (new Date(viewData?.year_value, viewData?.span_month - 1, viewData?.span_date)).toLocaleString()
                endDate = (new Date(viewData?.year_value, viewData?.month_value - 1, viewData?.date_value + 1)).toLocaleString()
                interval = 'day'
            }

            body = { ...body, startDate: startDate, endDate: endDate, interval: interval }
            dispatch(getAndFilterIntakeOutputResults(body, (result) => { console.log(result) }))
            handleGetStats();

        }

    }

    useEffect(() => {
        getTimes();
        handleGetResults();
    }, [viewData])


    useEffect(() => {

        handleGetStats();
    }, [])


    return (
        <>
            {loading && <LoaderUI />}
            <div className="io-wrapper" id="io-wrapper">
                <div className="header px-2">
                    <div className="left-col page-title mr-auto">Intake & Outputs</div>
                   
                </div>

                <div className="io-stats-blc w-100">
                    <div className="row w-100 m-auto">
                        <div className="col-md-4">
                            <div className="stats-item">
                                <div className="head d-flex align-items-center">
                                    <h3>{stats.difference} ml</h3>
                                    <Button color="link" className="ms-auto">
                                       In last 24 hours
                                    </Button>
                                </div>
                                <div className="inner">
                                    <h4>Balance</h4>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="stats-item">
                                <div className="head d-flex align-items-center">
                                    <h3>{stats.intake} ml</h3>
                                    <Button color="link" className="ms-auto">
                                        In last 24 hours
                                    </Button>
                                </div>
                                <div className="inner">
                                    <h4>Total Intake</h4>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="stats-item">
                                <div className="head d-flex align-items-center">
                                    <h3>{stats.output} ml</h3>
                                    <Button color="link" className="ms-auto">
                                        In last 24 hours
                                    </Button>
                                </div>
                                <div className="inner">
                                    <h4>Total Output</h4>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ width: '100%' }} className="d-flex flex-column flex-fill overflow-auto pb-0">

                    <CalendarFilterWeekDay tableViews={false} sendCalendarView={(value) => {
                        setViewValue(value);
                    }} calendarViewValue={(value) => {
                        handleFilterChange(value)
                    }} />


                </div>
                {
                    results != {} ?
                        <div style={{width: '100%', overflowX: 'scroll'}}>
                            {results?.intake?.length > 0 &&
                                <IOTable refresh={handleGetResults} times={times} view={viewValue} data={results?.intake} type={'Intake'} />}
                            {results?.output?.length > 0 &&
                                <IOTable refresh={handleGetResults} times={times} view={viewValue} data={results?.output} type={'Output'} />}
                        </div>
                        :
                        <NodataFoundBlock />
                }
            </div>

        </>
    )
}

export default IO;