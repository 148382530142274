import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";

toast.configure();

/**
 * @description function use to add new appointment
 * @param {*} params accepting object
 */
export const getAppointmentDetailsByClinicIdAndYear = (
  clinic_id,
  params,
  callback
) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/patient-intake-form/get-patient-appointments/${clinic_id}`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response) {
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * @description function use to get all doctors
 * @param {*} params accepting object
 */
export const getAllDoctors = (id, params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.get(
      `${apiUrl}${PORT}/appointment/get-all-doctors/${id}`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      if (response && !response.errors) {
        return callback(response);
      } else if (response?.code === 404) {
        toast.warn(response.message);
        return callback();
      } else {
        toast.error(response.errors.msg, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * @description function use to get all doctors
 * @param {*} params accepting object
 */
export const getAppointmentsByClinic = (id, params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();

    ApiClient.get(
      `${apiUrl}${PORT}/appointment/get-all-clinic-appointments/${id}`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      if (response && !response.errors) {
        return callback(response);
      } else if (response?.code === 404) {
        toast.warn(response.message);
        return callback();
      } else {
        toast.error(response.errors.msg, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * @description function use to get all doctors
 * @param {*} params accepting object
 */
export const getAllAppointmentsByPatientId = (id, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();

    ApiClient.get(
      `${apiUrl}${PORT}/appointment/get-all-appointments-by-patient-id/${id}`,
      {},
      token,
      dispatch, getState
    ).then((response) => {
      if (response && !response.errors) {
        return callback(response);
      } else if (response?.code === 404) {
        toast.warn(response.message);
        return callback();
      } else {
        toast.error(response.errors.msg, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 post
 */
export const getAllAppointmentsWithFilterOfPetOwner = (id, data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();

    ApiClient.post(
      `${apiUrl}${PORT}/appointment/get-all-filtered-appointments-by-patient-id/${id}`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (response && !response.errors) {
        return callback(response);
      } else if (response?.code === 404) {
        toast.warn(response.message);
        return callback();
      } else {
        toast.error(response.errors.msg, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 post
 */
export const getAllClinicId = (id, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();

    ApiClient.post(
      `${apiUrl}${PORT}/appointment/get-all-clinic-id/${id}`,
      {},
      token,
      dispatch, getState
    ).then((response) => {
      if (response && !response.errors) {
        return callback(response);
      } else if (response?.code === 404) {
        toast.warn(response.message);
        return callback();
      } else {
        toast.error(response.errors.msg, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 post
 */
export const getAllDoctorId = (id, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();

    ApiClient.post(
      `${apiUrl}${PORT}/appointment/get-all-doctor-id/${id}`,
      {},
      token,
      dispatch, getState
    ).then((response) => {
      if (response && !response.errors) {
        return callback(response);
      } else if (response?.code === 404) {
        toast.warn(response.message);
        return callback();
      } else {
        toast.error(response.errors.msg, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * @description function use to get all doctors
 * @param {*} params accepting object
 */
export const getActiveAppointmentsByPatientId = (id, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();

    ApiClient.get(
      `${apiUrl}${PORT}/appointment/get-active-patient-appointments/${id}`,
      {},
      token,
      dispatch, getState
    ).then((response) => {
      if (response && !response.errors) {
        return callback(response);
      } else if (response?.code === 404) {
        toast.warn(response.message);
        return callback();
      } else {
        toast.error(response.errors.msg, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * @description function use to get appointments for a pet owner
 * @param {*} params accepting object
 */
export const getAllAppointmentsOfPetOwner = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/appointment/get-all-appointments-for-pet-owner`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (response && !response.errors) {
        return callback(response);
      } else if (response?.code === 404) {
        toast.warn(response.message);
        return callback();
      } else {
        toast.error(response.errors.msg, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * @description function use to get appointments for a pet owner
 * @param {*} params accepting object
 */
export const getAllAppointmentsOfDoctor = (doctor_id, data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();

    ApiClient.post(
      `${apiUrl}${PORT}/appointment/get-all-appointments-for-doctor/${doctor_id}`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (response && !response.errors) {
        return callback(response);
      } else if (response?.code === 404) {
        toast.warn(response.message);
        return callback();
      } else {
        toast.error(response.errors.msg, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * @description function use to get all doctors details
 * @param {*} params accepting object
 */
export const getAllDoctorsDetails = (id, appointmentDate, params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.get(
      `${apiUrl}${PORT}/appointment/get-all-doctors-details/${id}/${appointmentDate}`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      if (response && !response.errors) {
        return callback(response);
      } else if (response?.code === 404) {
        toast.warn(response.message);
        return callback();
      } else {
        toast.error(response.errors.msg, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * @description function use to get all doctors by date
 * @param {*} params accepting object
 */
export const getAllDoctorsByDate = (id, dateVal, params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();

    ApiClient.get(
      `${apiUrl}${PORT}/appointment/get-all-doctors-by-date/${id}/${dateVal}`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      if (response && !response.errors) {
        return callback(response);
      } else if (response?.code === 404) {
        toast.warn(response.message);
        return callback();
      } else {
        toast.error(response.errors.msg, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * @description function use to add new appointment
 * @param {*} params accepting object
 */
export const addNewAppointment = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/appointment/add-new-appointment`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response) {
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * @description function use to add new appointment
 * @param {*} params accepting object
 */
export const getAppointmentDetails = (id, callback) => {
  return (dispatch, getState) => {
    if (id) {
      const {
        user: { token },
      } = getState();
      ApiClient.get(
        `${apiUrl}${PORT}/appointment/get-appointment-details/${id}`,
        {},
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (!response && response.errors) {
          toast.error(response.errors.msg, toasterConfig);
          return callback(response);
        } else if (response) {
          toast.dismiss();
          toast.success(response.msg, toasterConfig);
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback();
        }
      });
    }
  };
};

/**
 * @description function use to add new appointment
 * @param {*} params accepting object
 */
export const updateAppointmentDetails = (id, data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.put(
      `${apiUrl}${PORT}/appointment/update-appointment-details/${id}`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response && !response.success) {
        toast.error(response.message, toasterConfig);
        return callback(response);
      } else if (response) {
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        return callback(response);
      } else {
        // toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * @description function use to add new appointment
 * @param {*} params accepting object
 */
export const cancelAppointmeent = (id, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.put(
      `${apiUrl}${PORT}/appointment/cancel-appointment/${id}`,
      {},
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response) {
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

export const getLimitedAppointments = (date, clinic_id, callback) => {
  return (dispatch, getState) => {
    //
    const {
      user: { token },
    } = getState();
    ApiClient.get(
      `${apiUrl}${PORT}/appointment/get-limited-appointments/?date=${date}&clinic_id=${clinic_id}`,
      {},
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (response) {
        dispatch({
          type: "GET_LIMITED_APPOINTMENTS",
          payload: response,
        });
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};

export let getCompletedAppointments = (user, callback) => {
  return (dispatch, getState) => {

  const {
    user: { token },
  } = getState();
 
  ApiClient
    .get(
      `${apiUrl}${PORT}/appointment/get-appointment-status/${user}/completed`,  {},
      token,
      dispatch, getState
    )
    .then((res) => {
      return res.data;
    })
    .then((data) => {
      callback(data)
    })
    .catch((err) => {
    });
  }
};
export let changeAppointmentStatus =
  (payload) => async (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    return await ApiClient.post(
      `${apiUrl}${PORT}/appointment/change-appointment-status`,
      payload,
      token,
      dispatch, getState
    );
  };
