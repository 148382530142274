import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../../../api-client";
import { apiUrl, PORT } from "../../../../environment";
import { toasterConfig } from "../../../../utils/constants";
import { addStyle } from "rsuite/esm/DOMHelper";

toast.configure();
/**
 * All Api endpoints to be call
 * Post   -- /pet/get-list
 */

/**
 *
 * @param {Object} body
 * @param {Function} callback
 * @returns
 */
export const getPetListWithFilter = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
      clinicReducer: { clinic_details },
    } = getState();
    if (user) {
      //body.user_id = user?._id;
      if (clinic_details) {
        body.clinic_id = clinic_details?._id;
      }
      dispatch({ type: "PET_LOADING", payload: true });
      ApiClient.post(
        `${apiUrl}${PORT}/pet/get-list`,
        body,
        token,
        dispatch, getState
      ).then((response) => {
        dispatch({ type: "PET_LOADING", payload: false });
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({ type: "GET_PET_LIST", payload: response?.response_data });
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    } else {
      return callback(null);
    }
  };
};

/**
 *
 * @param {Object} body
 * @param {Function} callback
 * @returns
 */
export const checkPetOwnerEmail = (email, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();
    if (user && email) {
      ApiClient.get(
        `${apiUrl}${PORT}/pet/check-pet-owner-email/${email}`,
        {},
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    } else {
      return callback(null);
    }
  };
};

/**
 *
 * @param {Object} body
 * @param {Function} callback
 * @returns
 */
export const getAndSearchAllPets = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
      clinicReducer: { clinic_details },
    } = getState();
    if (user) {
      //body.user_id = user?._id;
      if (clinic_details) {
        body.clinic_id = clinic_details?._id;
      }
      dispatch({ type: "PET_LOADING", payload: true });
      ApiClient.post(
        `${apiUrl}${PORT}/pet/get-and-search-list`,
        body,
        token,
        dispatch, getState
      ).then((response) => {
        dispatch({ type: "PET_LOADING", payload: false });
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({ type: "GET_PET_LIST", payload: response?.response_data });
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    } else {
      return callback(null);
    }
  };
};

/**
 * add pet owner and pet details
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */
export const getPetAndOwnerDetails = (params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    if (params.ownerId && params.petId) {
      dispatch({ type: "PET_LOADING", payload: true });
      ApiClient.get(
        `${apiUrl}${PORT}/pet/get-pet-owner-with-pet`,
        params,
        token,
        dispatch, getState
      ).then((response) => {
        dispatch({ type: "PET_LOADING", payload: false });
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({
            type: "GET_PET_AND_OWNER",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    } else {
      dispatch({ type: "GET_PET_AND_OWNER", payload: false });
      return callback({ status: false });
    }
  };
};

export const getOwnerAndPetsDetails = (params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    if (params.ownerId && params.clinic_id) {
      dispatch({ type: "PET_LOADING", payload: true });
      ApiClient.get(
        `${apiUrl}${PORT}/pet/get-pet-owner-with-pets`,
        params,
        token,
        dispatch, getState
      ).then((response) => {
        dispatch({ type: "PET_LOADING", payload: false });
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({
            type: "GET_PET_AND_OWNER",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    } else {
      dispatch({ type: "GET_PET_AND_OWNER", payload: false });
      return callback({ status: false });
    }
  };
};

/**
 * add pet owner and pet details
 * @param {Object} body
 * @param {Function} callback
 * @returns
 */
export const addPatientDetails = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();
    if (user) {
      dispatch({ type: "PET_LOADING", payload: true });
      ApiClient.postFormData(
        `${apiUrl}${PORT}/pet/add-new-pet-details`,
        body,
        token,
        dispatch, getState
      ).then((response) => {
        dispatch({ type: "PET_LOADING", payload: false });
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch(getPetListWithFilter({}, () => { }));
          toast.success(response.message, toasterConfig);
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    } else {
      dispatch({ type: "PET_LOADING", payload: false });
      return callback(null);
    }
  };
};
/**


/**
 * add pet owner and pet details
 * @param {Object} body
 * @param {Function} callback
 * @returns 
 */
export const addPetOwnerWithPetdetails = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();
    if (user) {
      dispatch({ type: "PET_LOADING", payload: true });
      ApiClient.postFormData(
        `${apiUrl}${PORT}/pet/create-petowner-with-pet`,
        body,
        token,
        dispatch, getState
      ).then((response) => {
        dispatch({ type: "PET_LOADING", payload: false });
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch(getPetListWithFilter({}, () => { }));
          toast.success(response.message, toasterConfig);
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    } else {
      dispatch({ type: "PET_LOADING", payload: false });
      return callback(null);
    }
  };
};
/**
 * add pet owner and pet details
 * @param {Object} body
 * @param {Function} callback
 * @returns
 */
export const updatePetdetails = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();
    if (user) {
      dispatch({ type: "PET_LOADING", payload: true });
      ApiClient.put(
        `${apiUrl}${PORT}/pet/update-pet-details/${body.id}`,
        body,
        token,
        dispatch, getState
      ).then((response) => {
        console.log("=============res", response);
        dispatch({ type: "PET_LOADING", payload: false });

        if (!response) {
          return callback();
        } else if (response?.status) {
          // dispatch(getPetListWithFilter({}, () => { }))
          toast.success(response.msg, toasterConfig);
          return callback(response);
        } else {
          toast.error(response.msg, toasterConfig);
          return callback(response);
        }
      });
    } else {
      dispatch({ type: "PET_LOADING", payload: false });
      return callback(null);
    }
  };
};

/**
 * add pet behaviour tag
 * @param {Object} body
 * @param {Function} callback
 * @returns
 */
export const addBehaviourTag = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();
    if (user) {
      ApiClient.post(
        `${apiUrl}${PORT}/pet-care-info/add-behaviour-tag`,
        body,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          toast.success(response.message, toasterConfig);
          return callback(response);
        } else {
          toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    } else {
      return callback(null);
    }
  };
};

export const removeBehaviourTag = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();
    if (user) {
      ApiClient.post(
        `${apiUrl}${PORT}/pet-care-info/remove-behaviour-tag`,
        body,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          toast.success(response.message, toasterConfig);
          return callback(response);
        } else {
          toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    } else {
      return callback(null);
    }
  };
};
/**
 * add pet behaviour tag
 * @param {Object} body
 * @param {Function} callback
 * @returns
 */
export const addAllergy = (payload, callback) => {
  return async (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();
    if (user) {
      await ApiClient.post(
        `${apiUrl}${PORT}/pet-care-info/add-allergies-tag`,
        payload,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          console.log(response);
          toast.success(response.message, toasterConfig);
          return callback(response);
        } else {
          toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    } else {
      return callback(null);
    }
  };
};

export const updateAllergy = (payload, callback) => {
  return async (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();
    if (user) {
      await ApiClient.post(
        `${apiUrl}${PORT}/pet-care-info/update-allergies-tag`,
        payload,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          console.log(response);
          toast.success(response.message, toasterConfig);
          return callback(response);
        } else {
          toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    } else {
      return callback(null);
    }
  };
};
export const removeAllergy = (body, callback) => {
  console.log("asdfsafdasgfdsf", body);

  return async (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();
    if (user) {
      await ApiClient.post(
        `${apiUrl}${PORT}/pet-care-info/remove-allergies-tag`,
        body,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          toast.success(response.message, toasterConfig);
          return callback(response);
        } else {
          toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    } else {
      return callback(null);
    }
  };
};

/**
 * update pet image
 * @param {Object} body
 * @param {Function} callback
 * @returns
 */
export const updatePetImage = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.postFormData(
      `${apiUrl}${PORT}/pet/add-pet-image`,
      body,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (response?.status) {
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};

/**
 * update wellness plan
 * @param {Object} body
 * @param {Function} callback
 * @returns
 */
export const getWellnessPlans = (clinic_id, params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();

    ApiClient.get(
      `${apiUrl}${PORT}/wellness-plan/get/${clinic_id}`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (response?.status) {
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};

/**
 * update wellness plan
 * @param {Object} body
 * @param {Function} callback
 * @returns
 */
export const updateWellNessPlane = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    if (body.wellness_plan_id && body.pet_id) {
      ApiClient.post(
        `${apiUrl}${PORT}/pet/select-wellness-plan`,
        body,
        token,
        dispatch, getState
      ).then((response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    } else {
      toast.error("please select plan!", toasterConfig);
      return callback(null);
    }
  };
};

/**
 * get all pet details
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */
export const getPetDetailsByPetId = (petId, params, callback) => {
  return (dispatch, getState) => {
    const { user: { token }, } = getState();
    if (petId) {
      dispatch({ type: "PET_SIDE_LOADING", payload: true })
      ApiClient.get(`${apiUrl}${PORT}/pet/get-all-details/${petId}`, params, token, dispatch, getState).then((response) => {
        dispatch({ type: "PET_SIDE_LOADING", payload: false })
        if (!response) {
          return callback();
        }
        else if (response?.status) {
          dispatch({ type: "GET_PET_DETAILS_SIDE", payload: response?.response_data })
          return callback(response);
        } else {
          toast.error(response.message, toasterConfig);
          return callback(response);
        }
      })

    } else {
      dispatch({ type: "GET_PET_DETAILS_SIDE", payload: false })
      toast.error("Pet info is not found!", toasterConfig);
      return callback({ status: false });
    }
  };
};


// /**
//  * get all pet details
//  * @param {String} petId
//  * @param {Object} params
//  * @param {Function} callback
//  * @returns
//  */
// export const getPetDetailsByIdNoLoading = (petId, params, callback) => {
//   return (dispatch, getState) => {
//     const { user: { token }, } = getState();
//     if (petId) {
//       //dispatch({ type: "PET_LOADING", payload: true })
//       ApiClient.get(`${apiUrl}${PORT}/pet/get-all-details/${petId}`, params, token, dispatch).then((response) => {
//         //dispatch({ type: "PET_LOADING", payload: false })
//         if (!response) {
//           console.log('pet details no response', petId)
//           return callback();
//         }
//         else if (response?.status) {
//           dispatch({ type: "GET_PET_DETAILS", payload: response?.response_data })
//           return callback(response);
//         } else {
//           console.log('pet details no status', petId)
//           //toast.error(response.message, toasterConfig);
//           return callback(response);
//         }
//       })
//     } else {
//       dispatch({ type: "GET_PET_DETAILS", payload: false })
//       toast.error("Pet info is not found!", toasterConfig);
//       return callback({ status: false });
//     }
//   };
// };


/**
 * get all pet details
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */
export const getPetDetailsByIdNoLoading = (petId, params, callback) => {
  return (dispatch, getState) => {
    const { user: { token }, } = getState();
    if (petId) {
      //dispatch({ type: "PET_LOADING", payload: true })
      ApiClient.get(`${apiUrl}${PORT}/pet/get-all-details/${petId}`, params, token, dispatch, getState).then((response) => {
        //dispatch({ type: "PET_LOADING", payload: false })
        if (!response) {
          console.log('pet details no response', petId)
          return callback();
        }
        else if (response?.status) {
          dispatch({ type: "GET_PET_DETAILS", payload: response?.response_data })
          return callback(response);
        } else {
          console.log('pet details no status', petId)
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      })
    } else {
      dispatch({ type: "GET_PET_DETAILS", payload: false })
      toast.error("Pet info is not found!", toasterConfig);
      return callback({ status: false });
    }
  };
};


/**
 * get all pet details
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */
export const getPetDetailsNoReducer= (petId, callback) => {
  return (dispatch, getState) => {
    const { user: { token }, } = getState();
    if (petId) {
      ApiClient.get(`${apiUrl}${PORT}/pet/get-all-details/${petId}`, {}, token, dispatch, getState).then((response) => {
        if (!response) {
          console.log('pet details no response', petId)
          return callback();
        }
        else if (response?.status) {
          return callback(response);
        } else {
          console.log('pet details no status', petId)
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      })
    } else {
      toast.error("Pet info is not found!", toasterConfig);
      return callback({ status: false });
    }
  };
};


/**
 * get all pet details
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */
export const getPetDetailsById = (petId, params, callback) => {
  return (dispatch, getState) => {
    const { user: { token }, } = getState();
    if (petId) {
      dispatch({ type: "PET_LOADING", payload: true })
      ApiClient.get(`${apiUrl}${PORT}/pet/get-all-details/${petId}`, params, token, dispatch, getState).then((response) => {
        dispatch({ type: "PET_LOADING", payload: false })
        if (!response) {
          console.log('pet details no response', petId)
          return callback();
        }
        else if (response?.status) {
          dispatch({ type: "GET_PET_DETAILS", payload: response?.response_data })
          return callback(response);
        } else {
          console.log('pet details no status', petId)
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      })
    } else {
      dispatch({ type: "GET_PET_DETAILS", payload: false })
      toast.error("Pet info is not found!", toasterConfig);
      return callback({ status: false });
    }
  };
};

/**
 * @description function use to get all pets
 * @param {*} params accepting object
 */
export const getAllPets = (id, params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.get(
      `${apiUrl}${PORT}/pet/get-all-pet-details/${id}`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      if (response && !response.errors) {
        return callback(response);
      } else if (response?.code === 404) {
        toast.warn(response.message);
        return callback();
      } else {
        toast.error(response.errors.msg, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * @description function use to get all pets
 * @param {*} params accepting object
 */
export const getAllPetsAddedBy = (id, params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.get(
      `${apiUrl}${PORT}/pet/get-all-pets/${id}`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      if (response && !response.errors) {
        return callback(response);
      } else if (response?.code === 404) {
        toast.warn(response.message);
        return callback();
      } else {
        toast.error(response.errors.msg, toasterConfig);
        return callback();
      }
    });
  };
};

export const checkPetOwnerEmailExist =
  (email) => async (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    const response = await ApiClient.get(
      `${apiUrl}${PORT}/pet/check-pet-owner-email/${email}`,
      {},
      token,
      dispatch, getState
    );
    if (!response?.status) {
      toast.error(response.message, toasterConfig);
    }
    return response;
  };
export const changePetOwnerWithPet =
  (payload) => async (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    const response = await ApiClient._postFormData(
      `${apiUrl}${PORT}/pet/change-petowner-with-pet`,
      payload,
      token,
      dispatch, getState
    );
    if (!response?.status) {
      toast.error(response.message, toasterConfig);
    }
    return response;
  };
