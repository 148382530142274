import React, { useEffect, useState } from "react";
import "./index.css";
import OwnerProfileDescription from "./PetOwnerProfileDetails/OwnerProfileDescription";
import OwnerProfilehead from "./PetOwnerProfileDetails/OwnerProfilehead";
import PetOwnerDashboardTabs from "./PetOwnerDashboardTabs";
import { useDispatch, useSelector } from "react-redux";
import LoaderUI from "../../../shared/loader";
import { useLocation } from "react-router-dom";
import {
  getClinicDetailsByAddedBy,
  getOwnerAndPetsDetails,
} from "../../../context/actions/pet-owner-dashboard";
import Logout from "../Logout";

const PetOwnerHome = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const userDetails = useSelector((state) => state.user.user_details);

  useEffect(() => {
    if (userDetails) {
      setLoading(true);
      dispatch(
        getOwnerAndPetsDetails(
          {
            user_id: userDetails._id,
          },
          (response) => {
            if (response?.status) {
              dispatch(
                getClinicDetailsByAddedBy(
                  { added_by: response.response_data.added_by },
                  (response) => {
                    if (response?.status) {
                    }
                  }
                )
              );
            } else {
            }
            setLoading(false);
          }
        )
      );
    }
  }, [userDetails]);

  const toggleSideMenu = (event) => {
    document.body.classList.toggle("sidenav-toggled");
  };

  return (
    <>
      {loading && <LoaderUI overlay={true} />}
      <div className="page-wrapper patient-detail-wrapper position-relative d-flex">
        <div className="left-col d-flex flex-column overflow-auto petOwnerProfile pet-detail-left-side">
          <OwnerProfilehead />
          <OwnerProfileDescription />
          <Logout />
        </div>
        <div className="right-col d-flex flex-fill overflow-auto me-0">
          <button onClick={toggleSideMenu} id="sidebarToggle">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 448 512"
            >
              <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z" />
            </svg>
          </button>
          <PetOwnerDashboardTabs />
        </div>
      </div>
      {/*  <NodataFoundBlock /> */}
    </>
  );
};

export default PetOwnerHome;
