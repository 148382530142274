import React, { useEffect, useState } from "react";
import { getAllPets } from "context/actions/patient-module/patient/pet";
import { Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";
import NodataFoundBlock from "shared/NoDataFoundBlock";
import { useClinicSpecies } from "hooks/useClinicSpecies";
import {useClinicBreed} from "hooks/useClinicBreed";

const PatientSelection = ({ onHide, handlePetSelection }) => {
  const dispatch = useDispatch();
  const clinicSpecies = useClinicSpecies()
  const clinicBreed = useClinicBreed()
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const { species } = useSelector((state) => state.appContentReducer);
  const [searchText, setSearchText] = useState("");
  const [type, setType] = useState("");
  const [petList, setPetList] = useState([]);
  const getPageData = () => {
    dispatch(
      getAllPets(clinic_details?._id, {}, (res) => {
        if (Array.isArray(res)) {
          setPetList(res);
        } else {
          setPetList([]);
        }
      })
    );
  };
  useEffect(() => {
    getPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let displayList = petList?.filter((o) =>
    o.pet_name.toLowerCase().includes(searchText.toLowerCase())
  );
  if (type) {
    displayList = displayList.filter((o) => o?.breed?.species_id?._id === type);
  }
  return (
    <Offcanvas show onHide={onHide} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Select Patient</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="w-100">
        <div className="listing-filter-blc d-flex align-items-center overflow-auto flex-shrink-0 mb-2">
          <div className="search-cell col-cell position-relative mb-0">
            <span className="icomoon-search d-inline-flex align-items-center justify-content-center position-absolute"></span>
            <Input
              placeholder="Search"
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
            />
          </div>
          <div className="input-cell col-cell">
            <Input
              type="select"
              name="select"
              onChange={(e) => {
                setType(e.target.value);
              }}
              value={type}
            >
              <option value="">All Pets</option>
              {(species || []).map((item, i) => (
                <option key={i} value={item?._id}>
                  {clinicSpecies(item?._id) || item?.species_name}
                </option>
              ))}
            </Input>
          </div>
        </div>
        {displayList.length === 0 ? (
          <div>
            <NodataFoundBlock />
          </div>
        ) : (
          <div>
            {displayList?.map((elem, index) => {
              const { pet_image, pet_name, gender, breed, vrn } = elem;
              return (
                <div
                  className="fb-center bg-F8F8 br-10 cp-5 cpe-10 mb-2"
                  key={index}
                >
                  <div className="fa-center gap-2 flex-grow-1">
                    {pet_image ? (
                      <img
                        src={pet_image}
                        alt="pet"
                        className="h-49 w-49 br-10"
                      />
                    ) : (
                      <div className="bg-eded h-49 w-49 br-10 f-center">
                        <span class="material-symbols-outlined color-9d9e">
                          pets
                        </span>
                      </div>
                    )}

                    <div>
                      <div className="color-2E65">
                        <span className="text-16-500 me-2">{pet_name}</span>
                        <span className="text-13-400">({vrn})</span>
                      </div>
                      <div className="text-12-400 color-2E65">
                        {gender === "male" ? "Male" : "Female"} /{" "}
                        {clinicSpecies(breed?.species_id?._id) || breed?.species_id?.species_name} ({breed?.breed})
                      </div>
                    </div>
                  </div>
                  <div
                    className="text-14-400 color-3EFD cursor-pointer"
                    onClick={() => {
                      handlePetSelection(elem);
                    }}
                  >
                    Select
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default PatientSelection;
