import React, { useState, useEffect } from "react";
import {
  BsCheckLg,
  BsFillCheckCircleFill,
  BsFillTrashFill,
} from "react-icons/bs";

import { ReactComponent as DeleteSvg } from "../../../../../assets/img/delete.svg" 
import { ReactComponent as CheckSvg } from '../../../../../assets/img/check_circle.svg'
import { Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addBehaviour,
  deleteBehaviour,
} from "../../../../../context/actions/behaviours";

const BehaviorAdd = ({
  severityBehaviourValue,
  behaviourValue,
  behaviourId,
  setIsBehaviorAdd,
  setSeverityBehaviourValue,
  setBehaviourValue,
  setBehaviourId,
  handleUpdateBehaviourText,
}) => {
  const [reactionsValue, setReactionsValue] = useState(behaviourValue);

  useEffect(() => {
    setReactionsValue(behaviourValue);
  }, [behaviourValue]);

  const cancelBehaviour = (behaviourId = "") => {
    if (behaviourId == "") {
      setIsBehaviorAdd(false);
      setSeverityBehaviourValue("");
      setBehaviourValue("");
      setBehaviourId("");
    } else {
      dispatch(
        deleteBehaviour(behaviourId, (res) => {
          setIsBehaviorAdd(false);
          setSeverityBehaviourValue("");
          setBehaviourValue("");
          setBehaviourId("");
        })
      );
    }
  };

  const severity = [
    {
      id: 1,
      severityName: "Low",
      tagClassName: "low",
      colorName: "bg-teal-500",
    },
    {
      id: 2,
      severityName: "Moderate",
      tagClassName: "moderate",
      colorName: "bg-yellow-500",
    },
    {
      id: 3,
      severityName: "High",
      tagClassName: "high",
      colorName: "bg-orange-500",
    },
  ];

  const dispatch = useDispatch();

  const saveBehaviour = () => {
    dispatch(
      addBehaviour(
        {
          tagId: behaviourId,
          tag: reactionsValue,
          severity: severityBehaviourValue,
        },
        (res) => {
          if (res?.statusCode === 200 && res?.status === true) {
            setSeverityBehaviourValue("");
            setBehaviourValue("");
            setBehaviourId("");
            setIsBehaviorAdd(false);
            handleUpdateBehaviourText(reactionsValue);
          }
        }
      )
    );
  };

  return (
    <div className="allergy-add mb-3 d-flex align-items-center justify-content-between">
      <div className="reactions-add d-flex gap-2 align-items-center">
        <div className="d-flex gap-1">
          <span>Name</span>
          <span style={{ color: "red" }}>*</span>
        </div>
        {/* <Input className="reactions-select-dropdown" type="select" name="select" placeholder="Select value" value={reactionsValue} onChange={(e) => {
                    setReactionsValue(e?.target?.value)
                }}>
                    <option value="">Select</option>
                {reactionsOptions?.map((item, i) => (
                  <option key={i} value={item?.value}>
                    {item?.value}
                  </option>
                ))}
              </Input> */}
        <input
          type="text"
          className="form-control"
          placeholder="Add Behavior"
          value={reactionsValue}
          onChange={(e) => {
            setReactionsValue(e?.target?.value);
          }}
        />
      </div>

      <div className="reactions-add d-flex gap-2 align-items-center">
        <div className="d-flex gap-1">
          <span>Severity</span>
          <span style={{ color: "red" }}>*</span>
        </div>
        <div className="severity-text d-flex gap-2">
          {severity?.map((elem, index) => {
            const { severityName, tagClassName, id, colorName } = elem;
            return (
              <span
                className={`d-flex gap-2 align-items-center1 ${colorName} ${tagClassName} cursor-pointer`}
                key={index}
                id={id}
                onClick={() => {
                  // selectSeverity(elem);
                  setSeverityBehaviourValue(severityName);
                }}
              >
                {severityName}{" "}
                {severityName === severityBehaviourValue && (
                  <BsCheckLg style={{ color: "white" }} />
                )}
              </span>
            );
          })}
        </div>
      </div>
      <div className="d-flex">

        <button className={'p-1'} style={{ width: '35px' }} size="sm" type="submit" onClick={() => {
            saveBehaviour();
          }}><CheckSvg /></button>
        <button type="button" class="" onClick={() => {
            cancelBehaviour(behaviourId);
          }}><DeleteSvg /></button>

       {/* <BsFillTrashFill
          style={{ color: "red", cursor: "pointer" }}
          size={25}
          onClick={() => {
            cancelBehaviour(behaviourId);
          }}
        />
        <BsFillCheckCircleFill
          style={{ color: "blueviolet", cursor: "pointer" }}
          size={25}
          onClick={() => {
            saveBehaviour();
          }}
        />*/}
      </div>
    </div>
  );
};

export default BehaviorAdd;
