import { Button, Input, Table } from "reactstrap";
import SearchBlock2 from "../../../../../../../shared/SearchBlock2";
import NodataFoundBlock from "../../../../../../../shared/NoDataFoundBlock";
import { useDispatch, useSelector } from "react-redux";
import { getAndSearchProductsServicesDiagnostics } from "../../../../../../../context/actions/inventory";
import PaginationBlock from "shared/PaginationBlock";
import { useEffect, useState } from "react";
import Spinner from "shared/Spinner";
import { getAllInventoryCategories } from "context/actions/app-content";
import { getActiveIntegrations } from "context/actions/clinicSettings";
import moment from "moment";
import LoaderUI from "shared/loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { getDiagnosticCategoriesByClinicId } from "context/actions/diagnostic-service";


/**
 * Item Selection Component - search and select component for items including diagnostic services, bundles, services, and inventory items
 * @param {Function} selectMedicationFn - function to be called when selecting an item
 * @param {Function} push - function to be called when adding item to items array, but no other side effects
 * @param {Function} exitSearch - function to exit search section of the form and return to cart
 * @param {Function} closePanel - function to close panel entirely
 * @returns {JSX.Element} Component template
 */

const ItemSelection = ({
  selectMedicationFn,
  push,
  exitSearch,
  closePanel,
}) => {
  const { productServiceDiagnosticList } = useSelector((state) => state.inventoryReducer);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('')
  const [modality, setModality] = useState("")
  const [lab, setLab] = useState("")
  const [diagnosticCategory, setDiagnosticCategory] = useState("")

  const [type, setType] = useState("")

  const [activeIntegrations, setActiveIntegrations] = useState([])
  const [diagnosticCategories, setDiagnosticCategories] = useState([])
  const [category, setCategory] = useState("")

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)

  /**
   * Function for searching for products, services, bundles, or diagnostic services
   */
  const handleGetAndSearchProductList = () => {
    setIsLoading(true);
    dispatch(
      getAndSearchProductsServicesDiagnostics({ searchText, page, limit, modality, lab, category, diagnosticCategory, type}, (response) => {
        setIsLoading(false);
      })
    );
  };
  


  const { inventoryCategories } = useSelector(
    (state) => state.appContentReducer
  );
  useEffect(() => {
    if (!inventoryCategories?.length) {
      dispatch(getAllInventoryCategories(() => {}));
    }


    dispatch(getDiagnosticCategoriesByClinicId((response) => {
      if(response?.status) setDiagnosticCategories(response?.response_data)
    }))

    dispatch(getActiveIntegrations((response) => {
      if(response?.status){
          setActiveIntegrations(response?.response_data?.filter(i => i?.app === 'Antech' || i?.app === 'Idexx'))
      }
    }));
  }, []);


  // reset conditional filters upon category and type change
  useEffect(() => {
    setModality("")
    setLab("")
    setDiagnosticCategory("")

  }, [category, type])


  // reset page and get results upon filter or search change
  useEffect(() => {
    setPage(1)
    handleGetAndSearchProductList();

  }, [modality, searchText, lab, category, diagnosticCategory, type])


  // fetch results upon page or limit change
  useEffect(() => {
    handleGetAndSearchProductList();
  }, [page, limit]);

  const handleSelect = (item) => {

    if (item?.category === "bundle") {
      for (let i of item?.products) {
        push({
          product_id: i?._id,
          product_name: i?.product_name,
          description: i?.description,
          category: i?.category,
          // package: item.packages,
          // available_stock: item?.available_stock,
          sub_package: item?.sub_package,
          unit_of_measure: item?.unit_of_measure,
        });

        exitSearch();
      }
    } else {
      selectMedicationFn({
        product_id: item?._id,
        product_name: item?.product_name,
        test_name: item?.test_name,
        inHouse: item?.inHouse,
        antech_code: item?.antech_code,
        idexx_code: item?.idexx_code,


        service_name: item?.service_name,
        description: item?.description,
        category: item?.category,
        // package: item.packages,
        // available_stock: item?.available_stock,
        sub_package: item?.sub_package,
        unit_of_measure: item?.unit_of_measure,
      });
    }
  };
  return (
    <>
      <div className="head d-flex flex-column align-items-center">
        <div className="d-flex flex-row w-100">
          <h2>Orders</h2>
          <Button
            color="link"
            className="ms-auto panel-close-btn"
            onClick={closePanel}
          >
            <span className="material-symbols-outlined">close</span>
          </Button>
        </div>
        <div style={{marginBottom: '5px'}} className="table-list-point-of-sale-filter-blc-wrapper w-100">
          <div className="left-col">
        
        <div className="d-flex table-list-filter-blc flex-row w-100">
        <div className="col-cell align-items-center d-flex">
        <FontAwesomeIcon onClick={exitSearch} icon={faChevronLeft} fontSize={23} />
        </div>
        <div className="col-cell">
          <SearchBlock2
            placeholder="Search"
            onChange={(e)=> {
              setSearchText(e?.target?.value)
            }}
          />
        </div>
        <div className="input-cell col-cell">
          <Input
          type="select"
          style={{ textTransform: "capitalize", textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
          value={type}
          onChange={(e) => {setType(e?.target?.value)}}
          >
            <option value="">All Types</option>
            <option>Product</option>
            <option>Service</option>
            <option>Bundle</option>
            <option>Diagnostic Service</option>
          </Input>
          </div>

          {(type !== "Bundle" && type !== 'Diagnostic Service') && 

          <div className="input-cell col-cell">
          <Input
          type="select"
          style={{ textTransform: "capitalize" }}
          value={category}
          onChange={(e) => {setCategory(e?.target?.value)}}
          >
            <option value="">All Categories</option>
            {inventoryCategories &&
              inventoryCategories?.map((cat) => (
                <option   style={{ textTransform: "capitalize" }} key={cat?.category} value={cat?.category}>
                  {cat?.category}
                </option>
              ))}
            
          </Input>
          </div>}
          {(type == "Diagnostic Service" || (category === 'Diagnostic Test' && type == "" )) && <>
          <div className="input-cell col-cell">
          <Input
          type="select"
          value={modality}
          onChange={(e) => {setModality(e?.target?.value)}}
          >
            <option value="">All Modalities</option>
            <option value="reflab">Reference Lab</option>
            <option value="inhouse">In House</option>
          </Input>
          </div>
          <div className="input-cell col-cell">
          <Input
          type="select"
          value={lab}
          onChange={(e) => {setLab(e?.target?.value)}}
          >
            <option value="">All Labs</option>
            {activeIntegrations.map(app => (
              <option>{app?.app}</option>
            ))}
          </Input>
          </div>
          <div className="input-cell col-cell" style={{width: 'unset'}}>
            <Input
              type="select"
              name="select"
              style={{textTransform: 'capitalize'}}
              onChange={(e) => {
                setDiagnosticCategory(e?.target?.value)
              }}
            >
              <option value={""}>All Diagnostic Categories</option>
              {diagnosticCategories?.map(item => (
                <option value={item?._id}>{item?.category}</option>
              ))}
            </Input>
          </div>
          </>}
        </div>
        </div>
        </div>
      </div>

      {isLoading ? (
        <div className="mx-auto">
  <LoaderUI />        </div>
      ) : (
        <div className="inner overflow-auto">
          <div className="table-listing-blc overflow-auto">
            <Table responsive className="no-data-table">
              <thead>
                <tr>
                  <th>Component </th>
                  <th>Details</th>
                  <th>Category</th>
                  <th style={{ width: "100px" }}>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {productServiceDiagnosticList && productServiceDiagnosticList?.docs?.length > 0 ? (
                  productServiceDiagnosticList?.docs?.map((item, i) => {
                    item.isAvailable = true;
                    if (item.type === "service") {
                      const getUnavailableStock = item?.ser_product?.find(
                        (prod) => prod.available_stock <= 0
                      );
                      if (
                        getUnavailableStock?._id &&
                        item?.ser_product?.length > 0
                      )
                        item.isAvailable = false;
                    } else if (item.type === "product") {
                      let isAnyFutureExpDate = false;
                      if (item?.packages && item?.packages?.length > 0) {
                        isAnyFutureExpDate = item?.packages?.some((o) =>
                          moment(
                            o?.expiration_date,
                            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                          ).isSameOrAfter(moment())
                        );
                      }
                      if (
                        !item?.available_stock ||
                        item?.available_stock <= 0 ||
                        !isAnyFutureExpDate
                      ) {
                        item.isAvailable = false;
                      }
                    } else if (item.bundle_name) {
                      const getUnavailableStock = item?.products?.find(
                        (prod) => prod.available_stock === 0
                      );
                      if (
                        getUnavailableStock?._id ||
                        item?.products.length === 0
                      )
                        item.isAvailable = false;
                    } 
                    return (
                      <tr key={i}>
                        {item?.product_name ? (
                          <td>{`${item?.product_name} (${item?.sku})`}</td>
                        ) : item?.service_name ? (
                          <td>{item?.service_name}</td>
                        ) : item?.bundle_name ? (
                          <td>{item?.bundle_name}</td>
                        ) : (
                          <td>{item?.test_name + (item?.idexx_code ? ` (IDEXX CODE: ${item?.idexx_code})` : item?.antech_code ? ` (ANTECH CODE: ${item?.antech_code})` : '')}</td>
                        )}
                        <td>{item?.description}</td>
                        <td style={{ textTransform: "capitalize" }}>
                          {item?.category}
                        </td>
                        <td>
                          <button
                            onClick={() => {
                              if (!item.isAvailable) return;

                              handleSelect(item);
                            }}
                            disabled={!item.isAvailable}
                            className={` btn btn-link`}
                          >
                            Select
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="no-data-rw">
                    <td colSpan="5">
                      <NodataFoundBlock />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
              <PaginationBlock
              limit={limit}
              page={productServiceDiagnosticList.page || 1}
              pagingCounter={productServiceDiagnosticList?.pagingCounter || 1}
              docLength={productServiceDiagnosticList?.docs?.length || 0}
              docPage={productServiceDiagnosticList.page || 1}
              totalPages={productServiceDiagnosticList.totalPages || 1}
              totalDocs={productServiceDiagnosticList.totalDocs || 0}
              _handleLimit={(val) => {
                setLimit(val);
              }}
              _handlePageChange={(pageNumber) => {
                setPage(pageNumber);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ItemSelection;
