import React, { useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import db from "../../../../../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { Icons } from "utils/contants/Icons";
import "./index.scss";
import { getPetListWithFilter } from "context/actions/patient-module/patient/pet";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useDebounce from "hooks/useDebounce";
import { getPetOwnerDoctorList } from "context/actions/pet-owner-doctorList";
import NodataFoundBlock from "shared/NoDataFoundBlock";

const CreatePrivateChat = ({
  show,
  handleClose,
  staffMembersList,
  setStaffMembersList,
  petOwnerDoctorList = [],
  setPetOwnerDoctorList,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { privateChatList } = useSelector((state) => state.realTimeChatReducer);
  const { petOwnerList } = useSelector((state) => state.petOwnerReducer);
  const { petList } = useSelector((state) => state.petReducer);
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );
  const userDetails = useSelector((state) => state.user.user_details);
  const [tab, setTab] = useState("Doctor");
  const [search, setSearch] = useState("");
  const searchDebouncedValue = useDebounce(search, 300);
  const onClose = () => {
    handleClose();
    setSearch("");
    setTab("Doctor");
  };

  const createGroupRoom = async (data) => {
    console.log("✌️data --->", data);
    try {
      const roomsCollectionRef = collection(db, "privateChats");
      let payload = {
        createdAt: serverTimestamp(),
        createdBy: userDetails?._id,
        memberId: [userDetails?._id],
        members: [
          {
            id: userDetails?._id,
            role: userDetails?.role,
            img: userDetails?.user_image,
            name: userDetails?.firstname + " " + userDetails?.lastname || "",
            phone: userDetails?.phone || "",
            unreadCount: 0,
          },
        ],
        chatType: "private",
      };
      const memberRole = data?.user_type || data?.user_type || data?.role;
      payload?.memberId?.push(data?._id);
      payload?.members?.push({
        id: data?._id,
        role: memberRole,
        img: data?.user_image,
        name: data?.firstname + " " + data?.lastname || "",
        phone: data?.phone || "",
        unreadCount: 0,
      });
      // payload = { ...payload, chatType: memberRole === "doctor" ? "private" : "private" }

      const isRoomExist = privateChatList?.find(
        (element) =>
          element?.memberId.includes(payload?.memberId[0]) &&
          element?.memberId.includes(payload?.memberId[1])
      );
      if (isRoomExist?.id) {
        history.push(`/pet-owner-messages/private/${isRoomExist?.id}`);
        handleClose();
      } else {
        const roomDocRef = await addDoc(roomsCollectionRef, payload);
        if (roomDocRef.id) {
          toast.success("New chat created.");
          handleClose();
          history.push(`/pet-owner-messages/private/${roomDocRef.id}`);
          dispatch({ type: "MESSAGE_LIST", payload: [] });
        }
      }
    } catch (errors) {
      toast.error("Something went wrong!");
      console.log("errors", errors);
    }
  };

  const handleGetPetList = () => {
    dispatch(getPetListWithFilter({ page: 1, limit: 20 }, () => {}));
  };

  useEffect(() => {
    handleGetPetList();
  }, []);

  useEffect(() => {
    if (tab === "Doctor") {
      dispatch(
        getPetOwnerDoctorList({ searchText: search }, (result) => {
          if (result && !result.errors) {
            setPetOwnerDoctorList(result);
          }
        })
      );
    }
  }, [searchDebouncedValue]);

  const handleTabChange = (key) => {
    setTab(key);
    if (key === "Doctor" && search) {
      dispatch(
        getPetOwnerDoctorList({ searchText: search }, (result) => {
          if (result && !result.errors) {
            setPetOwnerDoctorList(result);
          }
        })
      );
    }
  };

  return (
    <Offcanvas
      show={show}
      onHide={onClose}
      backdrop="static"
      placement="end"
      className="select-user-list-pet"
    >
      <Offcanvas.Header closeButton className="p-2">
        <Offcanvas.Title>Select User </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="px-2 pb-2 pt-0">
        <div className="d-flex flex-column h-100">
          <div className="d-flex align-items-center gap-2 flex-md-nowrap flex-wrap flex-md-row flex-column-reverse">
            <div className="mb-2 search-group-wrapp position-relative">
              <Icons.SearchIcon />
              <input
                type="text"
                className="form-control"
                placeholder="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <ul className="filter-list-tabs">
              <li
                className={`filter-tabs ${tab === "Doctor" ? "active" : ""}`}
                role="tab"
                onClick={() => handleTabChange("Doctor")}
              >
                Doctors
              </li>
            </ul>
          </div>
          <p className="un_tetx my-1">
            Selecting a doctor starts a conversation visible to all involved
            doctors.
          </p>

          <div className="table-listing-blc overflow-auto">
            <div className="table-responsive">
              {tab === "Doctor" && petOwnerDoctorList?.length > 0 ? 
                <table className="checkbox-listing table table-striped group-add-listing">
                  <thead>
                    <tr>
                      <th style={{ minWidth: 245 }}>{tab} Name </th>
                      <th style={{ minWidth: 245 }}>City</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {tab === "Doctor" && (
                      <>
                        {(petOwnerDoctorList || [])
                          ?.filter((data) => {
                            if (tab === "Doctor")
                              return data?.role === "doctor";
                            else return data?.role !== "doctor";
                          })
                          ?.map((ele, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  <button
                                    type="button"
                                    className="p-0 text-start pic-cell d-inline-flex align-items-center w-100 btn btn-link"
                                  >
                                    <div className="pic d-inline-flex position-relative">
                                      <div className="img-placeholder position-relative d-flex align-items-center justify-content-center w-100 h-100 img-fluid w-50 h-50">
                                        {ele?.user_image ? (
                                          <img src={ele?.user_image} alt="i" />
                                        ) : (
                                          <span className="no-image d-inline-flex align-items-center justify-content-center position-absolute">
                                            <Icons.DummyImg />
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    {ele?.user_type === "doctor" ? "Dr." : null}
                                    {/* {ele?.firstname} {ele?.lastname} */}
                                    {ele?.user_type === "doctor" ? (
                                      clinic_settings?.doctor_name_format ===
                                      0 ? (
                                        <span>
                                          {ele?.lastname}{" "}
                                          {ele?.firstname}
                                        </span>
                                      ) : (
                                        <span>
                                          {ele?.firstname}{" "}
                                          {ele?.lastname}
                                        </span>
                                      )
                                    ) : (
                                      <span>
                                        {ele?.lastname}{" "}
                                        {ele?.firstname}
                                      </span>
                                    )}
                                  </button>
                                </td>
                                <td>{ele?.city || "-"}</td>
                                <td>
                                  <button
                                    className="start-message-btn"
                                    onClick={() => createGroupRoom(ele)}
                                  >
                                    Message
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </>
                    )}
                  </tbody>
                </table>
              : <NodataFoundBlock />}
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default CreatePrivateChat;
