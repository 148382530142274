import React, { useRef, useState } from "react";
import { Offcanvas, Spinner } from "react-bootstrap";
import SwitchCell from "shared/SwitchCell";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import { CiCalendar } from "react-icons/ci";
import SignaturePad from "react-signature-canvas";
import { useDispatch, useSelector } from "react-redux";
import logo from "assets/img/icons/logo.png";
import moment from "moment";
import { Button } from "reactstrap";
import BootrsapModal from "react-bootstrap/Modal";
import { PDFDocument, rgb } from "pdf-lib";
import blank from "assets/img/blank.pdf";
import blank2 from "assets/img/blank2.pdf";
import downloadjs from "downloadjs";
import { addNewPetDocument } from "context/actions/patient-module/document";
import { useClinicSpecies } from "hooks/useClinicSpecies";
import {useClinicBreed } from "hooks/useClinicBreed"
const certificateTypeList = [
  {
    value: "Rabies Certificate",
    label: "Rabies Certificate",
  },
  {
    value: "Spay and Neuter Certificate",
    label: "Spay and Neuter Certificate",
  },
];
const CertificateForm = ({
  onHide,
  selectedPet,
  handlePetSelection,
  productList,
}) => {
  const clinicSpecies = useClinicSpecies()
  const clinicBreed = useClinicBreed()
  const dispatch = useDispatch();
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const userDetails = useSelector((state) => state?.user?.user_details);
  const sigCanvas = useRef({});
  const [isSign, setIsSign] = useState(false);
  const [dLoader, setDLoader] = useState(false);
  const [sLoader, setSLoader] = useState(false);
  const [cURL, setCURL] = useState("");
  const [formVal, setFormVal] = useState({
    certificate_type: "",
    vaccine: "",
    issue_date: "",
    veterinarians_name: "",
    veterinarians_license_no: "",
    // isSpayed: false,
    // isVerified: false,
    imageURL: "",
    isCertify: "1",
  });
  const bytesToBase64 = (bytes) => {
    const base64abc = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "+",
      "/",
    ];
    let result = "",
      i,
      l = bytes.length;
    for (i = 2; i < l; i += 3) {
      result += base64abc[bytes[i - 2] >> 2];
      result += base64abc[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
      result += base64abc[((bytes[i - 1] & 0x0f) << 2) | (bytes[i] >> 6)];
      result += base64abc[bytes[i] & 0x3f];
    }
    if (i === l + 1) {
      // 1 octet yet to write
      result += base64abc[bytes[i - 2] >> 2];
      result += base64abc[(bytes[i - 2] & 0x03) << 4];
      result += "==";
    }
    if (i === l) {
      // 2 octets yet to write
      result += base64abc[bytes[i - 2] >> 2];
      result += base64abc[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
      result += base64abc[(bytes[i - 1] & 0x0f) << 2];
      result += "=";
    }
    return result;
  };
  const urlToUnitArray = async (url) => {
    let retunData = [];
    await fetch(url)
      .then((res) => res.arrayBuffer())
      .then((buffer) => {
        retunData = new Uint8Array(buffer);
      });
    return retunData;
  };
  const hexToRgbA = (hex) => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return [(c >> 16) & 255, (c >> 8) & 255, c & 255];
    }
    throw new Error("Bad Hex");
  };
  const handleConvertClick = (base64String, fileName, fileType) => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const file = new File([byteArray], fileName, { type: fileType });
    return file;
  };
  const handleChange = (e) => {
    setFormVal((prev) => {
      return {
        ...prev,
        vaccine: e.id === "certificate_type" ? "" : prev.vaccine,
        [e.id]: e.value,
      };
    });
  };
  const handleDownload = async () => {
    const pdfFileData = await urlToUnitArray(orders ? blank : blank2);
    const pdfDoc = await PDFDocument.load(pdfFileData);
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const { height } = firstPage?.getSize();
    let nC = hexToRgbA("#282e65");
    firstPage.drawText(clinic_details?.clinic_name, {
      x: 470,
      y: height - 205,
      size: 50,
      color: rgb(nC[0] / 255, nC[1] / 255, nC[2] / 255),
    });
    if (clinic_details?.address) {
      firstPage.drawText(clinic_details?.address, {
        x: 470,
        y: height - 280,
        size: 50,
        color: rgb(nC[0] / 255, nC[1] / 255, nC[2] / 255),
      });
    }
    if (clinic_details?.phone) {
      firstPage.drawText(clinic_details?.phone, {
        x: 470,
        y: height - 355,
        size: 50,
        color: rgb(nC[0] / 255, nC[1] / 255, nC[2] / 255),
      });
    }

    let certificateText =
      certificate_type === "Rabies Certificate"
        ? "Rabies Vaccine Certificate"
        : "Spay and Neuter Certificate";
    firstPage.drawText(certificateText.toUpperCase(), {
      x: 300,
      y: height - 625,
      size: 125,
      color: rgb(nC[0] / 255, nC[1] / 255, nC[2] / 255),
    });

    let pList = [
      {
        value: pet_name ? pet_name : "--",
        x: 800,
        y: 795,
      },
      {
        value: breed ? clinicSpecies(breed?.species_id?._id) ||   breed?.species_id?.species_name : "--",
        x: 800,
        y: 890,
      },
      {
        value: breed ? clinicBreed(breed?._id) || breed?.breed : "--",
        x: 800,
        y: 995,
      },
      {
        value: gender === "male" ? "Male" : "Female",
        x: 800,
        y: 1080,
      },
      {
        value: dob
          ? moment(dob, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format("MM/DD/YYYY")
          : "--",
        x: 800,
        y: 1175,
      },
      {
        value: color,
        x: 800,
        y: 1270,
      },
      {
        value: vrn,
        x: 800,
        y: 1365,
      },
      {
        value: `${weight} lbs`,
        x: 800,
        y: 1460,
      },
      {
        value: `${pet_owner.firstname} ${pet_owner.lastname}`,
        x: 2200,
        y: 795,
      },
    ];
    let vList = [
      {
        value: orders
          ? moment(orders?.first_dose, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
              "MM/DD/YYYY"
            )
          : "--",
        x: 800,
        y: 1850,
      },
      {
        value: "--",
        x: 800,
        y: 2015,
      },
      {
        value: orders
          ? moment(orders?.first_dose, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
              "MM/DD/YYYY"
            )
          : "--",
        x: 800,
        y: 2110,
      },
      {
        value: "--",
        x: 800,
        y: 2205,
      },
      {
        value: orders ? orders?.product?.manufacture : "--",
        x: 2200,
        y: 1755,
      },
      {
        value: orders ? orders?.product?.sku : "--",
        x: 2200,
        y: 1850,
      },
      {
        value: orders
          ? moment(
              orders?.product?.expire_date,
              "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
            ).format("MM/DD/YYYY")
          : "--",
        x: 2200,
        y: 1945,
      },
      {
        value: "--",
        x: 2200,
        y: 2040,
      },
    ];
    if (orders) {
      vList.forEach((o) => {
        let nC = hexToRgbA("#282e65");
        firstPage.drawText(o.value, {
          x: o.x,
          y: height - o.y,
          size: 50,
          color: rgb(nC[0] / 255, nC[1] / 255, nC[2] / 255),
        });
      });
    }
    pList.forEach((o) => {
      let nC = hexToRgbA("#282e65");
      firstPage.drawText(o.value, {
        x: o.x,
        y: height - o.y,
        size: 50,
        color: rgb(nC[0] / 255, nC[1] / 255, nC[2] / 255),
      });
    });
    nC = hexToRgbA("#282e65");
    let VaccinatedString = `Vaccinated at ${clinic_details?.clinic_name}`;
    if (certificate_type === "Spay and Neuter Certificate") {
      if (isCertify === "1") {
        VaccinatedString = `Spayed/Neutered on this date at ${clinic_details?.clinic_name}`;
      } else {
        VaccinatedString =
          "Verified to have been sterilized at another location";
      }
    }
    firstPage.drawText(VaccinatedString, {
      x: 300,
      y: height - 2345,
      size: 60,
      color: rgb(nC[0] / 255, nC[1] / 255, nC[2] / 255),
    });

    if (veterinarians_name) {
      firstPage.drawText(veterinarians_name, {
        x: 300,
        y: height - 2600,
        size: 60,
        color: rgb(nC[0] / 255, nC[1] / 255, nC[2] / 255),
      });
    }
    if (veterinarians_license_no) {
      firstPage.drawText(`License Number: ${veterinarians_license_no}`, {
        x: 300,
        y: height - 2700,
        size: 60,
        color: rgb(nC[0] / 255, nC[1] / 255, nC[2] / 255),
      });
    }
    if (issue_date) {
      firstPage.drawText(moment(issue_date).format("MMMM DD, YYYY"), {
        x: 300,
        y: height - 2800,
        size: 60,
        color: rgb(nC[0] / 255, nC[1] / 255, nC[2] / 255),
      });
    }
    if (imageURL) {
      const pngImage = await pdfDoc.embedPng(imageURL);
      firstPage.drawImage(pngImage, {
        x: 300,
        y: height - 3050,
        width: 800,
        height: 200,
      });
    }
    const pdfBytes = await pdfDoc.save();
    var b64 = bytesToBase64(pdfBytes);
    // setCURL(`data:application/pdf;base64,${b64}`);
    // let fileName = `${clinic_details?.clinic_name}_${moment().format(
    //   "DD-MM-YYYY"
    // )}.pdf`;
    let fileName = `${
      certificate_type === "Rabies Certificate"
        ? "Rabies Vaccine Certificate"
        : "Spay and Neuter Certificate"
    }.pdf`;
    return {
      fileName: fileName,
      file: handleConvertClick(b64, fileName, "application/pdf"),
      fileURL: `data:application/pdf;base64,${b64}`,
    };
  };
  const handleSubmit = async () => {
    setSLoader(true);
    let resFile = await handleDownload();
    let fdCertificateName =
      certificate_type === "Rabies Certificate"
        ? "Rabies Vaccine Certificate"
        : "Spay and Neuter Certificate";
    let fdCertificateNote =
      certificate_type === "Rabies Certificate"
        ? "Rabies Vaccine Certificate For Patient"
        : "Spay and Neuter Certificate For Patient";
    let formdata = new FormData();
    formdata.append("document", resFile?.file, resFile.fileName);
    formdata.append("pet_id", selectedPet._id);
    formdata.append("added_by", userDetails?._id);
    formdata.append("document_name", fdCertificateName);
    formdata.append("document_type", "Certificates");
    formdata.append("notes", fdCertificateNote);
    formdata.append("valid_till", "");
    dispatch(
      addNewPetDocument(formdata, selectedPet._id, (response) => {
        if (response && !response.errors) {
          setFormVal({
            vaccine: "",
            issue_date: "",
            veterinarians_name: "",
            veterinarians_license_no: "",
            isSpayed: false,
            isVerified: false,
            imageURL: "",
            isCertify: "1",
          });
          sigCanvas?.current?.clear();
        }
        setSLoader(false);
      })
    );
  };
  const {
    pet_image,
    pet_name,
    gender,
    breed,
    vrn,
    dob,
    color,
    weight,
    pet_owner,
  } = selectedPet;
  const {
    certificate_type,
    issue_date,
    veterinarians_name,
    veterinarians_license_no,
    // isSpayed,
    // isVerified,
    imageURL,
    isCertify,
  } = formVal;
  const newProductList = productList.map((o) => {
    return { ...o, value: o._id, label: o.orders.product.product_name };
  });
  let selectedVaccine = null;
  if (formVal.vaccine) {
    selectedVaccine = newProductList?.find((o) => o.value === formVal.vaccine);
  }
  let VaccinatedString2 = `Vaccinated at ${clinic_details?.clinic_name}`;
  if (certificate_type === "Spay and Neuter Certificate") {
    if (isCertify === "1") {
      VaccinatedString2 = `Spayed/Neutered on this date at ${clinic_details?.clinic_name}`;
    } else {
      VaccinatedString2 =
        "Verified to have been sterilized at another location";
    }
  }
  const { orders } = selectedVaccine || {};
  return (
    <Offcanvas
      show
      onHide={onHide}
      placement="end"
      id="certificate-create-canvas"
    >
      {cURL && (
        <BootrsapModal
          className={`iferp-scroll modal-block-custom`}
          onHide={() => {
            setCURL("");
          }}
          size={"lg"}
          fullscreen
          centered
          show
        >
          <iframe src={cURL} title="if" height={100} />
        </BootrsapModal>
      )}
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Create Certificate</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="w-100">
        <div className="row">
          <div className="col-md-6">
            <div className="bg-F8F8 br-8 cp-10">
              <div className="text-18-600 color-2E65 text-center">Preview</div>
              <div className="shadow bg-ffff br-10 mt-3 cp-10">
                <div className="cps-5 cpe-5 cpt-10 cpb-20 b-eded">
                  <div className="d-flex gap-2">
                    <div className="">
                      <img src={logo} alt="logo" className="h-40 w-40" />
                    </div>
                    <div>
                      <div className="text-8-400 color-2E65">
                        {clinic_details?.clinic_name}
                      </div>
                      {clinic_details?.address && (
                        <div className="text-8-400 color-2E65">
                          {clinic_details?.address}
                        </div>
                      )}
                      {clinic_details?.phone && (
                        <div className="text-8-400 color-2E65">
                          {clinic_details?.phone}
                        </div>
                      )}
                    </div>
                  </div>
                  {certificate_type ? (
                    <div className="text-uppercase text-14-600 color-2E65 mt-3">
                      {certificate_type === "Rabies Certificate"
                        ? "Rabies Vaccine Certificate"
                        : "Spay and Neuter Certificate"}
                    </div>
                  ) : (
                    <div className="mb-5" />
                  )}

                  <div className="w-100 bb-eded mt-1 mb-1" />
                  <div className="w-100">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="col-md-12">
                          <div className="row text-8-400 color-2E65">
                            <div className="col-md-6">Pet’s Name</div>
                            <div className="col-md-6">
                              : {pet_name ? pet_name : "--"}
                            </div>
                            <div className="col-md-6">Species</div>
                            <div className="col-md-6">
                              : {breed ? clinicSpecies(breed?.species_id?._id) ||  breed?.species_id?.species_name : "--"}
                            </div>
                            <div className="col-md-6">Breed</div>
                            <div className="col-md-6">
                              : {breed ? clinicBreed(breed?._id) ||  breed?.breed : "--"}
                            </div>
                            <div className="col-md-6">Sex</div>
                            <div className="col-md-6">
                              : {gender === "male" ? "Male" : "Female"}
                            </div>
                            <div className="col-md-6">Birthdate</div>
                            <div className="col-md-6">
                              :{" "}
                              {dob
                                ? moment(
                                    dob,
                                    "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                  ).format("MM/DD/YYYY")
                                : "--"}
                            </div>
                            <div className="col-md-6">Color</div>
                            <div className="col-md-6">: {color}</div>
                            <div className="col-md-6">Serial No.</div>
                            <div className="col-md-6">: {vrn}</div>
                            <div className="col-md-6">Weight</div>
                            <div className="col-md-6">: {weight} lbs</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12">
                          <div className="row text-8-400 color-2E65">
                            <div className="col-md-6">Owner</div>
                            <div className="col-md-6">
                              : {`${pet_owner.firstname} ${pet_owner.lastname}`}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {orders ? (
                      <>
                        <div className="text-uppercase text-10-600 color-2E65 mt-2 mb-1 text-center">
                          Vaccine Information
                        </div>
                        <div className="w-100 bb-eded mt-1 mb-1" />
                        <div className="row">
                          <div className="col-md-6">
                            <div className="col-md-12">
                              <div className="row text-8-400 color-2E65">
                                <div className="col-md-6">
                                  Date of Vaccination
                                </div>
                                <div className="col-md-6">
                                  :{" "}
                                  {orders
                                    ? moment(
                                        orders?.first_dose,
                                        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                      ).format("MM/DD/YYYY")
                                    : "--"}
                                </div>
                                <div className="col-md-6">Duration</div>
                                <div className="col-md-6">: --</div>
                                <div className="col-md-6">Date Due</div>
                                <div className="col-md-6">
                                  :{" "}
                                  {orders
                                    ? moment(
                                        orders?.first_dose,
                                        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                      ).format("MM/DD/YYYY")
                                    : "--"}
                                </div>

                                <div className="col-md-6">Microchip No.</div>
                                <div className="col-md-6">: --</div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="col-md-12">
                              <div className="row text-8-400 color-2E65">
                                <div className="col-md-6">Manufacturer</div>
                                <div className="col-md-6">
                                  :{" "}
                                  {orders ? orders?.product?.manufacture : "--"}
                                </div>
                                <div className="col-md-6">Serial No.</div>
                                <div className="col-md-6">
                                  : {orders ? orders?.product?.sku : "--"}
                                </div>
                                <div className="col-md-6">Lot Expiration</div>
                                <div className="col-md-6">
                                  :{" "}
                                  {orders
                                    ? moment(
                                        orders?.product?.expire_date,
                                        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                      ).format("MM/DD/YYYY")
                                    : "--"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="cmt-100" />
                    )}
                  </div>
                  <div className="text-10-500 color-2E65 mt-2">
                    {VaccinatedString2}
                  </div>
                  <div className="w-100 bb-eded mt-2" />
                  <div className="mt-2">
                    {veterinarians_name && (
                      <div className="text-10-400 color-2E65">
                        {veterinarians_name}
                      </div>
                    )}
                    {veterinarians_license_no && (
                      <div className="text-10-400 color-2E65">
                        License Number: {veterinarians_license_no}
                      </div>
                    )}
                    <div className="relative mh-60">
                      {issue_date && (
                        <div className="text-10-400 color-2E65">
                          {moment(issue_date).format("MMMM DD, YYYY")}
                        </div>
                      )}
                      {imageURL && (
                        <div className="sign-img-block">
                          {<img src={imageURL} alt="sign" className="h-30" />}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 f-center pb-2">
                {certificate_type &&
                  issue_date &&
                  veterinarians_name &&
                  veterinarians_license_no &&
                  imageURL && (
                    <div
                      className="text-13-600 color-3EFD cursor-pointer"
                      onClick={async () => {
                        if (!dLoader) {
                          setDLoader(true);
                          let res = await handleDownload();
                          if (res?.fileURL) {
                            downloadjs(res?.fileURL, res.fileName);
                          }
                          setDLoader(false);
                        }
                      }}
                    >
                      DOWNLOAD{" "}
                      {dLoader && <Spinner size="sm" className="ms-1" />}
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="bg-F8F8 br-8 cp-10">
              <div className="fb-center bg-ffff br-10 cp-5 cpe-10 mb-2">
                <div className="fa-center gap-2 flex-grow-1">
                  {pet_image ? (
                    <img
                      src={pet_image}
                      alt="pet"
                      className="h-49 w-49 br-10"
                    />
                  ) : (
                    <div className="bg-eded h-49 w-49 br-10 f-center">
                      <span class="material-symbols-outlined color-9d9e">
                        pets
                      </span>
                    </div>
                  )}

                  <div>
                    <div className="color-2E65">
                      <span className="text-16-500 me-2">{pet_name}</span>
                      <span className="text-13-400">({vrn})</span>
                    </div>
                    <div className="text-12-400 color-2E65">
                      {gender === "male" ? "Male" : "Female"} /{" "}
                      {clinicSpecies(breed?.species_id?._id) || breed?.species_id?.species_name} ({clinicBreed(breed?._id) ||  breed?.breed})
                    </div>
                  </div>
                </div>
                <div
                  className="text-14-400 color-3EFD cursor-pointer"
                  onClick={() => {
                    handlePetSelection(null);
                  }}
                >
                  Change
                </div>
              </div>
              <div className="text-16-600 color-2E65 mb-2">
                Certificate Information
              </div>
              <div className="certificate-type-dropdown cmb-5">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Select certificate type"
                  value={
                    certificateTypeList?.find(
                      (o) => o.value === certificate_type
                    ) || null
                  }
                  options={certificateTypeList}
                  onChange={(e) => {
                    handleChange({
                      id: "certificate_type",
                      value: e?.value || "",
                    });
                  }}
                />
              </div>
              {certificate_type === "Rabies Certificate" && (
                <div className="certificate-type-dropdown cmb-5">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Select vaccine"
                    value={selectedVaccine}
                    options={newProductList}
                    onChange={(e) => {
                      handleChange({
                        id: "vaccine",
                        value: e?.value || "",
                      });
                    }}
                  />
                </div>
              )}
              <div className="certificate-issue-date cmb-5">
                <ReactDatePicker
                  selected={formVal.issue_date}
                  placeholderText="Select issue date"
                  onChange={(date) => {
                    handleChange({
                      id: "issue_date",
                      value: date || "",
                    });
                  }}
                />
                <div className="io-block">
                  <CiCalendar size={17} color="#788693" />
                </div>
              </div>
              <div className="certificate-input cmb-5">
                <input
                  type="text"
                  placeholder="Veterinarian’s Name"
                  value={formVal.veterinarians_name}
                  onChange={(e) => {
                    handleChange({
                      id: "veterinarians_name",
                      value: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="certificate-input mb-2">
                <input
                  type="text"
                  placeholder="Veterinarian’s License No."
                  value={formVal.veterinarians_license_no}
                  onChange={(e) => {
                    handleChange({
                      id: "veterinarians_license_no",
                      value: e.target.value,
                    });
                  }}
                />
              </div>
              {certificate_type === "Spay and Neuter Certificate" && (
                <>
                  <div className="text-16-600 color-2E65 mb-2">
                    This is to certify that the above described animal has been
                  </div>
                  <div className="fa-center gap-2 mb-2 s-block">
                    <div className="d-flex">
                      <SwitchCell
                        className={formVal.isCertify === "1" ? "" : "disable"}
                        onClick={() => {
                          handleChange({
                            id: "isCertify",
                            value: formVal.isCertify === "1" ? "2" : "1",
                          });
                        }}
                      />
                    </div>
                    <div className="text-14-500 color-2E65">
                      Spayed/Neutered on this date
                    </div>
                  </div>
                  <div className="d-flex gap-2 mb-2 s-block">
                    <div className="d-flex align-items-start mt-1">
                      <SwitchCell
                        className={formVal.isCertify === "2" ? "" : "disable"}
                        onClick={() => {
                          handleChange({
                            id: "isCertify",
                            value: formVal.isCertify === "1" ? "2" : "1",
                          });
                        }}
                      />
                    </div>
                    <div>
                      <div className="text-14-500 color-2E65">
                        Verified to have been sterilised at another location
                      </div>
                      <div className="text-13-400 color-8693">
                        Description Lorem Ipsum is simply dummy text of the
                        printing setting industry.{" "}
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="fb-center mb-2">
                <div className="text-16-600 color-2E65">
                  Veterinarian’s Signature
                </div>
                <div className="fa-center gap-2">
                  {isSign && (
                    <div
                      className="text-13-400 color-3EFD cursor-pointer"
                      onClick={() => {
                        handleChange({
                          id: "imageURL",
                          value: sigCanvas.current
                            .getTrimmedCanvas()
                            .toDataURL("image/png"),
                        });
                      }}
                    >
                      Save
                    </div>
                  )}
                  <div
                    className="text-13-400 color-3EFD cursor-pointer"
                    onClick={() => {
                      sigCanvas.current.clear();
                      setIsSign(false);
                      handleChange({
                        id: "imageURL",
                        value: "",
                      });
                    }}
                  >
                    Clear
                  </div>
                </div>
              </div>
              <div
                className="relative"
                onClick={() => {
                  setIsSign(true);
                }}
              >
                <SignaturePad
                  canvasProps={{
                    width: 350,
                    height: 142,
                    className: "sigCanvas br-6",
                  }}
                  ref={sigCanvas}
                />
                {/* {!isSign && <div className="sign-text">Sign Here</div>} */}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end gap-2 mt-2">
            <Button
              color="link"
              className="btn-outline-light cps-50 cpe-50"
              onClick={onHide}
            >
              Cancel
            </Button>
            <Button
              color="success"
              className="cps-50 cpe-50"
              disabled={
                !certificate_type ||
                (certificate_type === "Rabies Certificate" &&
                  !selectedVaccine) ||
                !issue_date ||
                !veterinarians_name ||
                !veterinarians_license_no ||
                !imageURL
              }
              onClick={() => {
                if (!sLoader) {
                  handleSubmit();
                }
              }}
            >
              Create {sLoader && <Spinner size="sm" className="ms-1" />}
            </Button>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default CertificateForm;
