import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input } from "reactstrap";
import { sidebarPanelAction } from "../../context/actions/sidebarPanelAction";
import PageHeader from "../../shared/PageHeader";
import CalendarFilter from "../../shared/Calendar/CalendarFilter";
import UserListMonth from "../../shared/Calendar/UserListMonth";
import CalendarMonthView from "../../shared/Calendar/CalendarMonthView";
import { CalendarWeekView } from "../../shared/Calendar/CalendarWeekView";
import CalendarWeekDay from "../../shared/Calendar/CalendarWeekDay";
import CalendarFoot from "../../shared/Calendar/CalendarFoot";
import {
  getAllDoctors,
  getAllDoctorsByDate,
  getAllDoctorsDetails,
  getAppointmentsByClinic,
} from "../../context/actions/appointment";
import PatientListPanel from "../../shared/Calendar/PatientListPanel";
import { patientListPanelAction } from "../../context/actions/patientListPanelAction";
import { setAppointmentDetailPanel } from "../../context/actions/appointmentDetailPanelAction";
import AppointmentDetailsPanel from "../../shared/Calendar/AppointmentDetailsPanel";
import "./index.scss";
import { getPetDetailsById } from "../../context/actions/patient-module/patient/pet";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { appointmentTypes } from "../../utils/constants";
import LoaderUI from "shared/loader";
import UserPlaceholder from "shared/UserPlaceholder";
import SearchBlock from "shared/Calendar/CalendarFilter/SearchBlock";
import { Offcanvas } from "react-bootstrap";
import { RiDraggable } from "react-icons/ri";
import { FaUser } from "react-icons/fa";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Nav, NavItem, NavLink } from "reactstrap";
import ScheduleRequests from "./ScheduleRequests";
import RejectedRequests from "./RejectedRequests";
import { DoctorFilter } from "shared/Calendar/DoctorFilter/DoctorFilter";
import AppointmentTypeFilter from "shared/Calendar/AppointmentTypeFilter";
import { useClinicSpecies } from "../../hooks/useClinicSpecies";

const Appointments = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const [calendarView, setCalendarView] = useState("month");
  const [calendarViewData, setCalendarViewData] = useState({
    span_date: new Date().getDate(),
    date_value: new Date().getDate(),
    month_value: new Date().getMonth(),
    span_month: new Date().getMonth(),
    year_value: new Date().getFullYear(),
  });
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [doctorsList, setDoctorsList] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedAppointmentType, setSelectedAppointmentType] = useState([]);
  const [selectedDoctorList, setSelectedDoctorList] = useState([]);

  const sidebarPanelState = useSelector(
    (state) => state.sidebarPanelReducer.isSidePanel
  );
  const patientListPanelState = useSelector(
    (state) => state.patientListPanelReducer.isPatientListPanel
  );
  const appointmentDetailPanelState = useSelector(
    (state) => state.appointmentDetailPanelReducer.isAppointmentDetailPanel
  );

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const [activeTab, setActiveTab] = useState("1");
  const showSidePanel = () => {
    dispatch(sidebarPanelAction(!sidebarPanelState));
  };

  const setClinicAppointment = () => {
    setLoadingDetails(true);
    dispatch(
      getAppointmentsByClinic(clinic_details?._id, {}, (result) => {
        if (result && !result.errors) {
          setAppointments(result);
        } else {
          setAppointments([]);
        }
        setLoadingDetails(false);
      })
    );
    // setLoadingDetails(false);
  };
  const handleDoctorSelection = (data) => {
    let oldList = [...selectedDoctorList];
    if (oldList?.find((o) => o?._id === data?._id)) {
      oldList = oldList?.filter((o) => o?._id !== data?._id);
    } else {
      oldList = [...selectedDoctorList, data];
    }
    oldList = oldList?.map((o, index) => {
      return {
        ...o,
        order: index + 1,
      };
    });
    setSelectedDoctorList(oldList);
  };
  const handleAppointmentTypeSelection = (data) => {
    let oldData = [...selectedAppointmentType];
    if (oldData?.includes(data)) {
      oldData = oldData?.filter((o) => o !== data);
    } else {
      oldData = [...oldData, data];
    }
    setSelectedAppointmentType(oldData);
  };
  useEffect(() => {
    setClinicAppointment();
    setCalendarView("month");
    setCalendarViewData({
      span_date: new Date().getDate(),
      date_value: new Date().getDate(),
      month_value: new Date().getMonth(),
      span_month: new Date().getMonth(),
      year_value: new Date().getFullYear(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic_details]);

  useEffect(() => {
    handleGetAllDoctors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarView, calendarViewData]);

  const handleGetAllDoctors = () => {
    setLoadingDetails(true);
    if (calendarView === "day") {
      const dateVal = `${("0" + calendarViewData.date_value).slice(-2)}-${(
        "0" + calendarViewData.month_value
      ).slice(-2)}-${calendarViewData.year_value}`;
      dispatch(
        getAllDoctorsByDate(clinic_details?._id, dateVal, {}, (result) => {
          if (result && !result.errors) {
            setDoctorsList(result);
          } else {
            setDoctorsList(false);
          }
          setLoadingDetails(false);
        })
      );
    } else {
      dispatch(
        getAllDoctors(clinic_details?._id, {}, (result) => {
          if (result && !result.errors) {
            setDoctorsList(result);
          } else {
            setDoctorsList(false);
          }
          setLoadingDetails(false);
        })
      );
    }
  };
  const openPatientPanel = () => {
    dispatch(patientListPanelAction(!patientListPanelState));
  };
  const openliveClinic = () => {
    history.push("/live-clinic/");
  };
  const openAppointmentBlock = () => {
    history.push("/appointment-block/");
  };

  const addNewAppointmentUrl = (petId) => {
    history.push("/add-new-appointment/" + petId);
    dispatch(getPetDetailsById(petId), () => {
      dispatch(patientListPanelAction(false));
    });
  };

  // let selectedAppointmentType = isTypeSelect?.map((o) => o.value);
  let selectedAppointmentDoctor = selectedDoctorList?.map((o) => o.user._id);
  let filterDoctorList = [];
  let filterAppointments = [];
  if (doctorsList && doctorsList.length > 0) {
    doctorsList?.forEach((element) => {
      let restData = element?.appointments?.filter((data) => {
        const petName = data?.patient_id?.pet_name.toLowerCase();
        const breedName = data?.patient_id?.breed?.breed?.toLowerCase();
        const speciesName =
          data?.patient_id?.breed?.species_id?.species_name?.toLowerCase();
        let dName = `${petName} ${breedName} ${speciesName}`;

        return searchText ? dName.includes(searchText.toLowerCase()) : true;
      });
      if (selectedAppointmentType?.length) {
        restData = restData?.filter((o) =>
          selectedAppointmentType.includes(o.appointment_type)
        );
      }

      if (
        selectedAppointmentDoctor?.length === 0 ||
        selectedAppointmentDoctor.includes(element?.user?._id)
      ) {
        filterAppointments.push(...restData);
      } else {
        restData = [];
      }
      filterDoctorList.push({
        ...element,
        appointments: restData,
      });
    });
  }

  return (
    <>
      {loadingDetails && <LoaderUI />}

      <div className="page-wrapper position-relative d-flex flex-column flex-fill overflow-auto">
        <div className="page-header-wrapper d-flex align-items-center flex-shrink-0 pb-0 right-column-visible">
          <Button
            className="sidebar-toggle-btn d-inline-flex align-items-center"
            color="link"
            onClick={showSidePanel}
          >
            <span className="material-symbols-outlined">menu</span>
          </Button>

          <div className="row-block d-flex align-items-center flex-fill">
            <PageHeader
              className="dashboard-head flex-flex-shrink-0"
              title="Appointments"
            ></PageHeader>
            <div className="right-col ms-auto d-flex align-items-center">
              <div className="btn-blc">
                <button
                  className="appointment-btn me-2"
                  onClick={openAppointmentBlock}
                >
                  Appointment Block
                </button>
              </div>
              {permissionsData?.view_live_clinic && (
                <div className="btn-blc">
                  <button className="live-btn me-2" onClick={openliveClinic}>
                    Live clinic view
                  </button>
                </div>
              )}
              {permissionsData?.edit_appointments && (
                <div className="btn-blc">
                  <Button
                    color="success"
                    className="add-btn"
                    onClick={openPatientPanel}
                  >
                    Add new
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="tab-wrapper tab-wrapper-staff-detail d-flex flex-column overflow-auto ms-3">
          <Nav tabs className="flex-shrink-0 mb-0">
            <NavItem>
              <NavLink
                className={{ active: activeTab === "1" }}
                onClick={() => {
                  setActiveTab("1");
                }}
              >
                Appointments
              </NavLink>

              <NavLink
                className={{ active: activeTab === "2" }}
                onClick={() => {
                  setActiveTab("2");
                }}
              >
                Schedule Requests
              </NavLink>
              <NavLink
                className={{ active: activeTab === "3" }}
                onClick={() => {
                  setActiveTab("3");
                }}
              >
                Rejected
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        {activeTab === "1" && (
          <div className="page-content no-header d-flex flex-column flex-fill overflow-auto pb-0">
            <div className="d-flex justify-content-between mb-3">
              <div className="d-flex gap-2">
                <div className="cal-filter-search-block">
                  <SearchBlock
                    searchText={searchText}
                    onSearch={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                </div>

                <AppointmentTypeFilter
                  selectedAppointmentType={selectedAppointmentType}
                  onSelect={handleAppointmentTypeSelection}
                />

                {doctorsList?.length > 0 && calendarView === "month" && (
                  <DoctorFilter
                    doctorsList={doctorsList}
                    selectedDoctorList={selectedDoctorList}
                    onSelect={handleDoctorSelection}
                  />
                )}
              </div>
              <CalendarFilter
                sendCalendarView={(value) => {
                  setDoctorsList([]);
                  setCalendarView(value);
                  if (["day", "week"].includes(value)) {
                    setSelectedDoctorList([]);
                  }
                }}
                calendarViewValue={(value) => setCalendarViewData(value)}
              />
            </div>

            <div className="calendar-wrapper d-flex flex-column flex-fill overflow-auto">
              {calendarView === "month" ? (
                <CalendarMonthView
                  calendarViewData={calendarViewData}
                  appointments={filterAppointments}
                />
              ) : calendarView === "week" ? (
                <CalendarWeekView
                  calendarViewData={calendarViewData}
                  doctorsList={filterDoctorList}
                />
              ) : (
                <CalendarWeekDay
                  doctorsList={filterDoctorList}
                  dateVal={`${("0" + calendarViewData.date_value).slice(-2)}-${(
                    "0" + calendarViewData.month_value
                  ).slice(-2)}-${calendarViewData.year_value}`}
                />
              )}
            </div>
            <CalendarFoot /> 
          </div>
        )}
        {activeTab === "2" && <ScheduleRequests />}
        {activeTab === "3" && <RejectedRequests />}
      </div>
      <div
        className={`patient-panel-overlay position-fixed ${patientListPanelState ? "active" : ""
          }`}
        onClick={() => dispatch(patientListPanelAction(false))}
      ></div>
      <PatientListPanel
        className={`${patientListPanelState ? "active" : ""}`}
        clinicId={clinic_details?._id}
        callable={addNewAppointmentUrl}
      />

      <div
        className={`patient-panel-overlay position-fixed ${appointmentDetailPanelState ? "active" : ""
          }`}
        onClick={() => dispatch(setAppointmentDetailPanel(false))}
      ></div>
      <AppointmentDetailsPanel
        className={`${appointmentDetailPanelState ? "active" : ""}`}
      />
    </>
  );
};

export default Appointments;
