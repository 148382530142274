import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";

toast.configure();
/**
 * All Api endpoints to be call
 * Get   -- /supplier/get-all
 */

/**
 *
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */

export const addNewVaccineSchedule = (data, callback) => {
  //
  return (dispatch, getState) => {
    dispatch({ type: "VACCINE_SCHEDULE_LOADING", data: data });
    const {
      user: { token },
    } = getState();

    console.log(data)
    ApiClient.post(
      `${apiUrl}${PORT}/vaccine-schedule/create`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      dispatch({ type: "VACCINE_SCHEDULE_LOADING", data: data });
      if (!response) {
        //
        return callback();
      } else if (!response && response.errors) {
        //
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response) {
        //
        toast.dismiss();
        toast.success(response.message, toasterConfig);
        return callback(response);
      } else {
        //
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};


/**
 * Update Vaccine Schedule
 * @param {Object} body
 * @param {Function} callback
 * @returns 
 */
export const updateVaccineSchedule = (body, callback) => {
  return (dispatch, getState) => {
      const { user: { token }, clinicReducer: { clinic_details } } = getState();
      //body.clinic_id = clinic_details?._id
      ApiClient.put(`${apiUrl}${PORT}/vaccine-schedule/update`, body, token, dispatch, getState).then(response => {
          if (!response) {
              return callback();
          }
          else if (response?.status) {
              toast.dismiss();
              toast.success(response.message, toasterConfig);
              return callback(response);
          } else {
              //toast.error(response.message, toasterConfig);
              return callback(response);
          }

      });
  }
};





export const getAllVaccineSchedules = (clinicId, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
      clinicReducer: { clinic_details },
    } = getState();
    //params.clinic_id = clinic_details?._id;
    //params.user_id = user?._id;
    dispatch({ type: "VACCINE_SCHEDULE_LOADING", payload: true });
    ApiClient.get(`${apiUrl}${PORT}/vaccine-schedule/get-all/${clinicId}`, {}, token, dispatch, getState).then(
      (response) => {
        dispatch({ type: "VACCINE_SCHEDULE_LOADING", payload: false });
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({
            type: "GET_VACCINE_SCHEDULE",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      }
    );
  };
};
