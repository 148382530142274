import React, { useEffect } from "react";
import "./index.scss";
// import { sidebarPanelAction } from "../../context/actions/sidebarPanelAction";
// import PageHeader from "../../components/common/PageHeader";


import { useParams } from "react-router-dom";
import ChatRoom from "./ChatRoom";
import ChatGroupList from "./ChatGroupList";
import AppointmentDetailsPanel from "shared/Calendar/AppointmentDetailsPanel";
import { setAppointmentDetailPanel } from "context/actions/appointmentDetailPanelAction";
import { useSelector, useDispatch } from "react-redux";
import { getSelectedRoomData } from "context/actions/realTimeChat";


/*

1. Get pets from server using id of user given in userDetails object
2. Then get petowner details using petID
3. With these details (follow Room scheme) create chatroom on frontend 
4. Room will be created once user send first message
*/

const MessageCenter = () => {
  const routeParams = useParams();
  const dispatch = useDispatch();
  const appointmentDetailsState = useSelector(
    (state) => state.appointmentDetailPanelReducer.isAppointmentDetailPanel
);
  useEffect(() => {
    if (!routeParams?.room_id) {    
      dispatch({ type: "SELECT_ROOM", payload: {} });
      dispatch({ type: "MESSAGE_LIST", payload: [] });
    } 
},[routeParams])
  // useEffect(() => {
  //     console.log("%c Line:104 🍆 filterRoomData", "color:#6ec1c2", );
  //     if (privateChatsListing?.length && routeParams?.roomId && routeParams?.type === "private") {
  //       const filterRoomData = privateChatsListing.find(ele => ele.id === routeParams?.roomId);
  //       console.log("%c Line:105 🥓 filterRoomData", "color:#f5ce50", filterRoomData);
  //       if (!filterRoomData?.id) history.push("/message-center")
  //     }
  //   }, [privateChatsListing])
    return (
    <>
      {/* chat area  */}
      <div className="h-screen">
        <div className="row g-0">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4 col-xl-3 col-lg-3 border-right">
                <ChatGroupList routeParams={routeParams} />
              </div>
                <ChatRoom roomId={routeParams?.room_id} chatType={routeParams?.type} isRightSection={false} />
            </div>
          </div>
          </div>

          <div
            className={`patient-panel-overlay position-fixed ${appointmentDetailsState ? "active" : ""
              }`}
            onClick={() => dispatch(setAppointmentDetailPanel(false))}
          ></div>
          <AppointmentDetailsPanel style ={{zIndex: '10'}}
                className={`${appointmentDetailsState ? "active" : ""}`}
            />
      </div>

      {/* <CreateGroup show={show} handleClose={handleClose} staffMembersList={staffMembersList} />
      <CreatePrivateChat show={showPrivateChat} handleClose={() => setShowPrivateChat(false)} staffMembersList={staffMembersList} /> */}
      {/* {showNewChatModal && pets.length>0 && ( */}
    
    </>
  );
};

export default MessageCenter;
