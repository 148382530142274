import React from "react";
import './index.scss';
import { formatPhoneNumber } from "utils/common";

const StaffProfileItem = ({ staffMemberDetails }) => {


	return (
		<>
			<ul className="staff-profile-items list-unstyled">
				<li className="d-flex">
					<label>License No.</label>
					<span>{staffMemberDetails?.user?.license_number ? staffMemberDetails?.user?.license_number : "-"}</span>
				</li>
				<li className="d-flex">
					<label>Email</label>
					<span>{staffMemberDetails?.user?.email ? staffMemberDetails?.user?.email : "-"}</span>
				</li>
				<li className="d-flex">
					<label>Phone</label>
					<span>{staffMemberDetails?.user?.phone ? formatPhoneNumber(staffMemberDetails?.user?.phone) : "-"}</span>
				</li>
				<li className="d-flex">
					<label>Address Info</label>
					<span> {staffMemberDetails?.user_contact?.length > 0 ? `${staffMemberDetails?.user_contact?.line1}, ${staffMemberDetails?.user_contact?.city}, ${staffMemberDetails?.user_contact?.zipcode}` : "-"}</span>
				</li>
			</ul>
		</>
	);
};

export default StaffProfileItem;
