import React from "react";
import { useField } from "formik";
import { FormFeedback, FormGroup, Input } from "reactstrap";
// import { FormattedMessage } from 'react-intl';
 const TextInput = (props) => {
  const [field, meta] = useField(props.name);
  return (
    <FormGroup className={`input-wrapper ${props?.className}`}>
      {props.label && <><label htmlFor={props?.id} >
        <span className={props.isRequired && 'required'}>{props.label}</span>
      </label></>}
        <Input
          {...field}
          {...props}
        //   className={`${props?.inputClass}`}
          autoComplete="off"
        />
 
        {meta.touched && meta.error && (
          <FormFeedback className="d-block"> {meta?.error}
              {/* <FormattedMessage id={meta.error} values={{
            name: props?.placeholder || 'This'
          }} /> */}
          </FormFeedback>
        ) }
   	</FormGroup>
  );
};
export default TextInput;