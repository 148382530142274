import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Model from "shared/3dModel";
import { Accordion } from "react-bootstrap";
import { BsFillPinAngleFill, BsFillImageFill } from "react-icons/bs";
import {
  addNewWounds,
  addWoundDetails,
  getWoundsByPetId,
  deleteParentWound,
  deleteWoundDetails,
} from "context/actions/patient-module/flowsheets/wounds";
import NodataFoundBlock from "../../../../../../shared/NoDataFoundBlock";
import archive from "assets/img/archive.png";
import moment from "moment";
import WoundCell from "./WoundCell";
import DeletePopup from "../DeletePopup";
import DatePopover from "../DatePopover";
// import FilterComponent from "../FilterComponent";
import FilterOptions from "../FilterOptions";
import "./Wounds.scss";

const woundsKeys = [
  {
    key: "type",
    title: "Type",
    dependent: "",
    options: ["Pressure Ulcers", "Surgical Wound", "Wound", "Wound Vac"],
  },
  {
    key: "added_date",
    title: "Added Date & Time",
    dependent: "",
    options: [],
  },
  {
    key: "incision_appearance",
    title: "Incision Appearance",
    dependent: "Surgical Wound",
    options: [],
  },
  {
    key: "incision_closure",
    title: "Incision Closure",
    options: ["Staples", "Glue", "Sutures"],
    dependent: "Surgical Wound",
  },
  {
    key: "vac_setting",
    title: "Vac Setting",
    dependent: "Wound Vac",
    options: [],
  },
  {
    key: "vac_dressing",
    title: "Vac Dressing",
    dependent: "Wound Vac",
    options: [],
  },
  {
    key: "fluid_description",
    title: "Fluid Description",
    dependent: "Wound Vac",
    options: [],
  },
  {
    key: "fluid_output",
    title: "Fluid Output",
    dependent: "Wound Vac",
    options: [],
  },
  {
    key: "vac_dressing_change_date",
    title: "Vac Dressing Change Date & Time",
    dependent: "Wound Vac",
    options: [],
  },
  {
    key: "irrigation_flush",
    title: "Irrigation Flush (mL)",
    dependent: "",
    options: [],
  },
  {
    key: "wound_output",
    title: "Wound Output (mL)",
    dependent: "",
    options: [],
  },
  {
    key: "net_output",
    title: "Net Output (mL)",
    dependent: "",
    options: [],
  },
  {
    key: "dressing_change_date",
    title: "Dressing Change Date & Time",
    dependent: "",
    options: [],
  },
  {
    key: "notes",
    title: "Notes",
    dependent: "",
    options: [],
  },
  {
    key: "wound_document",
    title: "Photo",
    dependent: "",
    options: [],
  },
];

const Wounds = ({ petId, setDisplayURL }) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.user_details);
  const { petDetails } = useSelector((state) => state.petReducer);
  const [selectingNewPoint, setSelectingNewPoint] = useState(false);
  const [point, setPoint] = useState(false);
  const [pageData, setPageData] = useState([]);
  const [deleteData, setDeleteData] = useState(null);
  const [editData, setEditData] = useState(null);
  const [expandList, setExpandList] = useState([]);
  const [optionType, setOptionType] = useState("2");
  const [filterData, setFilterData] = useState({
    searchText: "",
    selectedDates: [],
    date: "",
  });

  const handleExpand = (gIndex) => {
    let list = expandList;
    if (expandList.includes(gIndex)) {
      list = list.filter((o) => o !== gIndex);
    } else {
      list = [...list, gIndex];
    }
    setExpandList(list);
  };
  const handleAddPoint = async () => {
    const res = await dispatch(
      addNewWounds({
        pet_id: petId,
        created_by: userDetails?._id,
        coordinate: point,
      })
    );
    if (res?.status) {
      handleExpand(pageData?.length);
      handleAddEditDetails({
        typeId: res?.response_data?._id,
        createdAt: moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      });
      setSelectingNewPoint(false);
      setPoint(null);
    }
  };

  const handleAddEditDetails = async (payload) => {
    const formData = new FormData();
    for (const key in payload) {
      formData?.append(key, payload[key]);
    }
    const res = await dispatch(addWoundDetails(formData));
    if (res?.status) {
      fetchPageData(filterData);
    }
    // setEditData(null);
  };

  const handleWoundDelete = async (id) => {
    setDeleteData(null);
    const res = await dispatch(deleteParentWound(id));
    if (res?.status) {
      setTimeout(() => {
        fetchPageData(filterData);
      }, 1000);
    }
  };

  const handleWoundDetailsDelete = async (data) => {
    setDeleteData(null);
    const res = await dispatch(deleteWoundDetails(data));
    if (res?.status) {
      setTimeout(() => {
        fetchPageData(filterData);
      }, 1000);
    }
  };

  const handleDelete = async () => {
    if (deleteData?.type === "1") {
      handleWoundDelete(deleteData?.typeId);
    } else {
      handleWoundDetailsDelete({
        typeId: deleteData?.typeId,
        typeDetailId: deleteData.typeDetailId,
      });
    }
  };

  const handleResponse = (resData) => {
    const newData = [];
    resData.forEach((o) => {
      const headerData = o?.typeDetails.map((e) => {
        const isAnyVal =
          e?.type || e?.added_date || e?.dressing_change_date || e?.notes
            ? true
            : false;
        return {
          id: e?.typeDetailId,
          date: e?.createdAt || "",
          isValueExist: isAnyVal,
        };
      });
      let bodyData = [];
      if (o?.typeDetails?.length > 0) {
        const findType = o?.typeDetails?.find((i) => i?.type)?.type || "";
        bodyData = woundsKeys?.map((e) => {
          const data = o?.typeDetails?.map((elm) => {
            return {
              ...elm,
              value: elm?.[e?.key] || "",
            };
          });
          let isRowShow = true;
          if (e?.dependent && findType && e?.dependent !== findType) {
            isRowShow = false;
          }
          return { ...e, data: data, isRowShow: isRowShow };
        });
      }
      let newObject = {
        id: o?._id,
        coordinate: o?.coordinate || [],
        value: o?.typeDetails?.find((o) => o.type)?.type || "location_name",
        header: headerData,
        data: bodyData?.filter((o) => o.isRowShow),
        deleted: o?.deleted,
      };
      newData.push(newObject);
    });
    setPageData(newData);
  };

  const fetchPageData = async (fData) => {
    const res = await dispatch(
      getWoundsByPetId({
        pet_id: petId,
        // filterDates: fData?.selectedDates,
      })
    );
    handleResponse(res?.response_data || []);
  };

  useEffect(() => {
    if (filterData?.date) {
      fetchPageData(filterData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    let currentDate = moment().format("DD-MM-YYYY");
    let newData = {
      searchText: "",
      selectedDates: [currentDate],
      date: currentDate,
    };
    setFilterData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let displayData = pageData?.filter(
    (o) =>
      optionType === "1" ||
      (optionType === "2" && !o.deleted) ||
      (optionType === "3" && o.deleted)
  );

  displayData = displayData?.map((elm) => {
    return {
      ...elm,
      data: elm?.data?.filter((o) =>
        o.title?.toLowerCase().includes(filterData?.searchText?.toLowerCase())
      ),
    };
  });

  if (displayData?.every((o) => o?.data?.length === 0)) {
    displayData = [];
  }
  return (
    <div id="wounds-container">
      {deleteData?.type && (
        <DeletePopup
          handleDelete={handleDelete}
          onHide={() => {
            setDeleteData(null);
          }}
        />
      )}
      {/* {filterData?.date && (
        <FilterComponent
          filterData={filterData}
          setFilterData={setFilterData}
        />
      )} */}
      <div
        style={{
          position: "relative",
          backgroundColor: "#EBECF0",
          width: "100%",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          marginTop: "5px",
        }}
      >
        <div style={{ width: "413px", height: "300px" }}>
          <Model
            selectPoint={handleExpand}
            points={displayData?.map((o) => o.coordinate)}
            isNewPoint={selectingNewPoint}
            setPoint={setPoint}
            species={petDetails?.species?.species_name}
          />
        </div>

        <div
          className="scroll-control"
          style={{
            position: "absolute",
            right: "34px",
            bottom: "74px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <img
            style={{ width: "80px", height: "80px" }}
            src={require("../../../../../../assets/img/vetRecorEMR/modelScroll.png")}
            alt="3d"
          />
          <p
            style={{
              color: "#788C9F",
              maxWidth: "161px",
              textAlign: "center",
              fontFamily: "Inter",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "150%",
            }}
          >
            Drag to rotate the image on 360 degrees
          </p>
        </div>
      </div>
      <div className="wounds-body">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="fa-center gap-3">
            <div className="head-title">Wounds</div>
            <FilterOptions
              firstOption="All Locations"
              optionType={optionType}
              onClick={(e) => {
                setOptionType(e);
              }}
            />
          </div>

          {selectingNewPoint ? (
            <div className="new-pin">
              <i
                style={{ height: "20px", width: "20px" }}
                onClick={() => {
                  setSelectingNewPoint(false);
                }}
                className="icomoon-cross-btn"
              ></i>

              <i
                style={{ height: "20px", width: "20px" }}
                onClick={() => {
                  handleAddPoint();
                }}
                className="icomoon-check-btn"
              ></i>
            </div>
          ) : (
            <div
              className="d-flex align-items-center gap-2 pin-span"
              onClick={() => {
                setSelectingNewPoint(true);
              }}
            >
              <span>Pin New Location</span>
              <span className="pin">
                <BsFillPinAngleFill />
              </span>
            </div>
          )}
        </div>
        {displayData?.length === 0 ? (
          <NodataFoundBlock type={'medical history'} />
        ) : (
          <Accordion activeKey={expandList} onSelect={handleExpand}>
            {displayData?.map((elm, index) => {
              const { id, value, header, data, deleted } = elm;
              return (
                <Accordion.Item key={index} eventKey={index}>
                  <Accordion.Header>
                    <div className="d-flex fa-center justify-content-between w-100">
                      <div className="location-detail-block">
                        <div className="location-number">{index + 1}</div>
                        <div className="location-title">{value}</div>
                      </div>

                      {!deleted && (
                        <div className="d-flex align-items-center gap-2">
                          {data?.filter(elem => elem?.key === 'wound_document')?.[0]?.data?.filter(i => i?.value !== "")?.length > 0 &&

                          <BsFillImageFill
                            size={20}
                            color="#a9a9a9"
                          />
                          }
                          <div
                            className="h-19 d-flex"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setDeleteData({
                                typeId: id,
                                typeDetailId: "",
                                type: "1",
                              });
                            }}
                          >
                            <img src={archive} alt="archive" />
                          </div>
                          <div
                            className="d-flex"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleAddEditDetails({
                                typeId: id,
                                createdAt: moment().format(
                                  "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                ),
                              });
                            }}
                          >
                            <span className="icomoon-plus-btn" />
                          </div>
                        </div>
                      )}
                    </div>
                  </Accordion.Header>
                  {header.length > 0 && (
                    <Accordion.Body>
                      <div className="head-row">
                        <div>Title</div>
                        {header?.map((dateElem, dateIndex) => {
                          const { date, isValueExist } = dateElem;
                          const cellDate = moment(
                            date,
                            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                          ).format("MM/DD/YYYY");
                          const cellTime = moment(
                            date,
                            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                          ).format("hh:mmA");
                          const isDateEdit =
                            editData?.typeDetailId === dateElem?.id &&
                            editData?.fieldId === "createdAt";
                          return (
                            <div
                              key={dateIndex}
                              className="d-flex gap-2 position-relative"
                            >
                              <span
                                className="cursor-pointer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  if (!deleted) {
                                    setEditData({
                                      typeId: id,
                                      typeDetailId: dateElem?.id,
                                      fieldId: "createdAt",
                                      fieldValue: moment(
                                        date,
                                        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                      ).format("YYYY-MM-DDTHH:mm"),
                                    });
                                  }
                                }}
                              >
                                {cellDate}
                                <br />
                                {cellTime}
                              </span>
                              {isDateEdit && (
                                <DatePopover
                                  defaultValue={date}
                                  value={editData?.fieldValue}
                                  setEditData={setEditData}
                                  onChange={(e) => {
                                    setEditData((prev) => {
                                      return {
                                        ...prev,
                                        fieldValue: e.target.value,
                                      };
                                    });
                                  }}
                                  handleSave={() => {
                                    handleAddEditDetails({
                                      typeId: editData.typeId,
                                      typeDetailId: editData.typeDetailId,
                                      createdAt: moment(
                                        editData.fieldValue,
                                        "YYYY-MM-DDTHH:mm"
                                      ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                                    });
                                  }}
                                />
                              )}
                              {!isValueExist && !deleted && (
                                <span
                                  className="remove_btn"
                                  onClick={() => {
                                    setDeleteData({
                                      typeId: id,
                                      typeDetailId: dateElem?.id,
                                      type: "2",
                                    });
                                  }}
                                >
                                  -
                                </span>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      {data?.map((rowElem, rowIndex) => {
                        return (
                          <div className="data-row" key={rowIndex}>
                            <div>{rowElem?.title}</div>
                            {rowElem?.data?.map((cellElem, cellIndex) => {
                              const isAnyFieldValue =
                                header[cellIndex]?.isValueExist;
                              let isFieldAccess = true;
                              if (rowElem?.dependent) {
                                isFieldAccess =
                                  cellElem?.type === rowElem?.dependent;
                              }
                              return (
                                <div
                                  key={cellIndex}
                                  onClick={() => {
                                    if (isFieldAccess) {
                                      if (
                                        (editData?.fieldId !== rowElem?.key ||
                                          editData?.typeDetailId !==
                                            cellElem?.typeDetailId) &&
                                        !deleted &&
                                        rowElem?.key !== "wound_document"
                                      ) {
                                        const isDatePicker = [
                                          "placement_date",
                                          "removed_date",
                                          "dressing_change_date",
                                          "vac_dressing_change_date",
                                        ].includes(rowElem?.key);
                                        setEditData({
                                          typeId: id,
                                          typeDetailId: cellElem?.typeDetailId,
                                          fieldId: rowElem?.key,
                                          options: rowElem?.options,
                                          fieldValue: isDatePicker
                                            ? cellElem?.value
                                              ? moment(
                                                  cellElem?.value,
                                                  "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                                ).format("YYYY-MM-DDTHH:mm")
                                              : ""
                                            : cellElem?.value,
                                          dependent: rowElem?.dependent,
                                        });
                                      }
                                    }
                                  }}
                                >
                                  <WoundCell
                                    deleted={deleted}
                                    isFieldAccess={isFieldAccess}
                                    data={cellElem}
                                    isAnyFieldValue={isAnyFieldValue}
                                    cellTitle={rowElem?.title}
                                    cellKey={rowElem?.key}
                                    editData={editData}
                                    setEditData={setEditData}
                                    setDisplayURL={setDisplayURL}
                                    handleEditDetails={(e) => {
                                      handleAddEditDetails({
                                        typeId: e.typeId || id,
                                        typeDetailId:
                                          e.typeDetailId ||
                                          cellElem?.typeDetailId,
                                        [e.fieldId]: e.fieldValue,
                                      });
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </Accordion.Body>
                  )}
                </Accordion.Item>
              );
            })}
          </Accordion>
        )}
      </div>
    </div>
  );
};

export default Wounds;
