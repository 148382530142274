import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";

toast.configure();


  export const getIdexxBreedsBySpecies = (species_id,callback) => {
    return (dispatch, getState) => {
      const {
        user: { token },
        clinicReducer: { clinic_details },
      } = getState();
      if (clinic_details) {
        ApiClient.get(
          `${apiUrl}${PORT}/external-breed/get-idexx-by-species/${species_id}`,
          {},
          token,
          dispatch, getState
        ).then((response) => {
          if (!response) {
            return callback();
          } else if (response && response?.status) {
        
            return callback(response);
          } else {
            return callback(false);
          }
        });
      }
      else {
        return callback(null);
      }
    };
  };






  export const getAntechBreedsBySpecies = (species_id,  callback) => {
    return (dispatch, getState) => {
      const {
        user: { token },
        clinicReducer: { clinic_details },
      } = getState();
      if (clinic_details) {
        ApiClient.get(
          `${apiUrl}${PORT}/external-breed/get-antech-by-species/${species_id}`,
          {},
          token,
          dispatch, getState
        ).then((response) => {
          if (!response) {
            return callback();
          } else if (response && response?.status) {
        
            return callback(response);
          } else {
            return callback(false);
          }
        });
      }
      else {
        return callback(null);
      }
    };
  };





  export const getAllIdexxBreedByClinicId = (clinic_id, params, callback) => {
    return (dispatch, getState) => {
      const {
        user: { token },
        clinicReducer: { clinic_details },
      } = getState();
      if (clinic_details) {
        ApiClient.get(
          `${apiUrl}${PORT}/external-breed/get-idexx/${clinic_id}`,
          params,
          token,
          dispatch, getState
        ).then((response) => {
          if (!response) {
            return callback();
          } else if (response && response?.status) {
        
            return callback(response?.response_data);
          } else {
            return callback(false);
          }
        });
      }
      else {
        return callback(null);
      }
    };
  };






  export const getAllAntechBreedByClinicId = (clinic_id, params, callback) => {
    return (dispatch, getState) => {
      const {
        user: { token },
        clinicReducer: { clinic_details },
      } = getState();
      if (clinic_details) {
        ApiClient.get(
          `${apiUrl}${PORT}/external-breed/get-antech/${clinic_id}`,
          params,
          token,
          dispatch, getState
        ).then((response) => {
          if (!response) {
            return callback();
          } else if (response && response?.status) {
        
            return callback(response?.response_data);
          } else {
            return callback(false);
          }
        });
      }
      else {
        return callback(null);
      }
    };
  };



