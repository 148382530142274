import { useState } from "react";
import { Table, Button } from "reactstrap";
import SearchBlock2 from "../../../../../../../shared/SearchBlock2";

const TemplateSelection = ({ patientnotestemplates, selectTemplatefn }) => {
  const [notesTemplates, setNotesTemplates] = useState(patientnotestemplates);

  const handleTemplateSearch = (event) => {
    const searchString = event.target.value;

    if (searchString.length > 0) {
      setNotesTemplates(
        patientnotestemplates.filter((notes) =>
          notes.title.includes(searchString)
        )
      );
    } else {
      setNotesTemplates(patientnotestemplates);
    }
  };

  return (
    <>
      <div className="d-flex flex-row w-100 my-2">
        <SearchBlock2 placeholder="Search" onChange={handleTemplateSearch} />
      </div>

      <div className="inner overflow-auto">
        <div className="table-listing-blc overflow-auto">
          <Table responsive className="no-data-table">
            <thead>
              <tr>
                <th>Template</th>
                <th>Description</th>
                <th style={{ width: "100px" }}>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {notesTemplates?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{item.title}</td>
                    <td>{item?.description}</td>
                    <td>
                      <Button
                        color="link"
                        onClick={() => selectTemplatefn(item)}
                      >
                        Select
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default TemplateSelection;
