import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Table, DropdownToggle, ButtonDropdown } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import UserPlaceholder from "../../../../../shared/UserPlaceholder";

import { useQuery } from "../../../../../utils/custom-hooks/useQuery";
import LoaderUI from "../../../../../shared/loader";

import PaginationBlock from "../../../../../shared/PaginationBlock";
import NodataFoundBlock from "../../../../../shared/NoDataFoundBlock";
import { formatPhoneNumber } from "utils/common";

const SelectExistingTable = ({
  page,
  limit,
  setPage,
  setLimit,
  handleChangePetOwner,
}) => {
  const query = useQuery();
  const ownerId = query.get("ownerId");

  const dispatch = useDispatch();
  const { loading, petOwnerList } = useSelector(
    (state) => state.petOwnerReducer
  );

  const history = useHistory();

  if (loading) {
    return <LoaderUI />;
  }

  const handleSelect = (id) => {
    if (id) {
      history.replace(`/patients/add-patient/?ownerId=${id}`);
    }
  };

  return (
    <>
      {petOwnerList?.docs?.length > 0 ? (
        <>
          <div className="table-listing-blc overflow-auto">
            <Table striped responsive className="checkbox-listing">
              <thead>
                <tr>
                  <th style={{ minWidth: "200px" }}>Owner Name</th>
                  <th style={{ minWidth: "8px" }}>Status</th>
                  <th>Phone</th>
                  <th>Email</th>
                  {/* <th style={{ minWidth: '128px' }}>City</th> */}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {petOwnerList?.docs?.map((ele) => {
                  return (
                    <tr>
                      <td>
                        <Button
                          color="link"
                          className="p-0 text-start pic-cell d-inline-flex align-items-center"
                          style={{ fontSize: "14px" }}
                        >
                          <div className="pic d-inline-flex position-relative">
                            {ele?.user_image ? (
                              <img
                                className="w-[20px] h-[20px] mr-[4px] bg-gray-200"
                                src={
                                  ele?.user_image ||
                                  "/static/media/user-thumbnail.adef342b4bb503aac3bc.png"
                                }
                                alt="owner"
                                style={{ borderRadius: "100px" }}
                              />
                            ) : (
                              <UserPlaceholder />
                            )}
                          </div>

                          <span style={{ color: "#282E65" }}>
                            {ele.firstname + " " + ele.lastname}
                          </span>
                        </Button>
                      </td>
                      <td>
                        <span className="status-cell">
                          {ele.active ? "Active" : "Inactive"}
                        </span>
                      </td>
                      <td>{formatPhoneNumber(ele.phone)}</td>
                      <td>{ele.email}</td>
                      {/* <td>Wlington City</td> */}
                      <td className="action-cell">
                        <Button
                          disabled={ownerId === ele?._id}
                          onClick={() => {
                            if (handleChangePetOwner) {
                              handleChangePetOwner(ele);
                            } else {
                              handleSelect(ele?._id);
                            }
                          }}
                          color="link"
                        >
                          {ownerId === ele?._id ? "Selected" : "Select"}
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <PaginationBlock
              limit={limit}
              page={petOwnerList.page || 1}
              pagingCounter={petOwnerList?.pagingCounter || 1}
              docLength={petOwnerList?.docs?.length || 0}
              docPage={petOwnerList.page || 1}
              totalPages={petOwnerList.totalPages || 1}
              totalDocs={petOwnerList.totalDocs || 0}
              _handleLimit={(val) => {
                setLimit(val);
              }}
              _handlePageChange={(pageNumber) => {
                setPage(pageNumber);
              }}
            />
          </div>{" "}
        </>
      ) : (
        <NodataFoundBlock type={'search result'}/>
      )}{" "}
    </>
  );
};

export default SelectExistingTable;
