import React, { useEffect, useRef } from "react";
import {
  getCurrentDay,
  getCurrentMonth,
  getCurrentWeekSpan,
  getCurrentWeekSpanValueNew,
  getDateData,
  getDayValue,
  getMonthValue,
  getWeekSpanDaysValue,
  getWeekSpanValue,
} from "utils/calendar/constant";
import { useState } from "react";
import Select from "react-select";
import { appointmentTypes } from "utils/constants";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import SearchBlock from "./SearchBlock";
import "./index.scss";
import { FaAngleDown, FaUser } from "react-icons/fa";
import { Input } from "reactstrap";
import moment from "moment";
import { useSelector } from "react-redux";

const CalendarFilter = ({
  sendCalendarView,
  calendarViewValue,
  isHideDropdown,
  searchText,
  onSearch,
  onDropDownChange,
  dropdownValue,
  handleDoctorSelection,
  doctorsList,
  selectedDoctorList,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const myRef = useRef();
  const handleClickOutside = (e) => {
    if (myRef && myRef?.current && !myRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
  return (
    <>
      <div className="calendar-filter-blc d-flex justify-content-between align-items-start mb-0">
        <div className="left-col inline-flex items-start px-2">
          {onSearch && (
            <SearchBlock searchText={searchText} onSearch={onSearch} />
          )}
        </div>
        <div className="right-col flex items-center flex-row gap-2 px-2">
          <Calendar
            sendCalendarView={sendCalendarView}
            calendarViewValue={calendarViewValue}
          />
        </div>
      </div>
    </>
  );
};

export default CalendarFilter;

export function Calendar({ sendCalendarView, calendarViewValue }) {
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );
  const [calendarView, setCalendarView] = useState("month");
  const [monthdata, setMonthData] = useState();
  const [monthVal, setMonthVal] = useState("Jan 1971");

  const handleCalendarView = (viewType) => {
    setCalendarView(viewType);
    sendCalendarView(viewType);
    if (viewType === "month") {
      handleMonthData();
    } else if (viewType === "week") {
      handleWeekData();
    } else {
      handleDateData();
    }
  };

  useEffect(() => {
    if (calendarView === "month") {
      handleMonthData();
    } else if (calendarView === "week") {
      handleWeekData();
    } else {
      handleDateData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMonthData = () => {
    setMonthData(getCurrentMonth());
    setMonthVal(getCurrentMonth().month_year);
    calendarViewValue({
      view_type: "month",
      month_value: getCurrentMonth().month_value,
      year_value: getCurrentMonth().year_value,
    });
  };

  const handleMonthChange = (changeType) => {
    if (calendarView === "month") {
      let monthDataVal;
      if (changeType === "previous") {
        let yearVal;
        if (monthdata.month_value === 0) {
          yearVal = monthdata.year_value - 1;
        } else {
          yearVal = monthdata.year_value;
        }
        monthDataVal = getMonthValue(
          monthdata.month_value,
          yearVal,
          changeType
        );
      } else {
        monthDataVal = getMonthValue(
          monthdata.month_value,
          monthdata.year_value,
          changeType
        );
      }
      console.log(monthDataVal, "monthDataVal");
      setMonthData(monthDataVal);
      setMonthVal(monthDataVal.month_year);
      calendarViewValue({
        view_type: "month",
        month_value: monthDataVal.month_value,
        year_value: monthDataVal.year_value,
      });
    } else if (calendarView === "week") {
      const weekData = getWeekSpanDaysValue(
        monthdata.week_start_date,
        monthdata.week_end_date,
        changeType
      );
      setMonthData(weekData);
      setMonthVal(weekData?.week_display_date || "");
      calendarViewValue({
        ...weekData,
        view_type: "week",
        date_value: weekData.date_value,
        month_value: weekData.month_value,
        year_value: weekData.year_value,
        span_date: weekData.span_date,
        span_month: weekData.span_month,
        last_week_span: weekData.last_week_span,
      });
    } else {
      const dayData = getDayValue(
        monthdata.date_value,
        monthdata.month_value,
        monthdata.year_value,
        changeType
      );
      setMonthData(dayData);
      setMonthVal(dayData.date);
      calendarViewValue({
        view_type: "day",
        date_value: dayData.date_value,
        month_value: dayData.month_value,
        year_value: dayData.year_value,
      });
    }
  };

  const handleWeekData = () => {
    // const weekDataOldMethod = getCurrentWeekSpan();
    const weekData = getCurrentWeekSpanValueNew(
      clinic_settings?.calendar_start_day || "Sunday"
    );
    setMonthData(weekData);
    setMonthVal(weekData?.week_display_date || "");
    calendarViewValue({
      ...weekData,
      view_type: "week",
      date_value: weekData.date_value,
      month_value: weekData.month_value,
      year_value: weekData.year_value,
      span_date: weekData.span_date,
      span_month: weekData.span_month,
      last_week_span: weekData.last_week_span,
    });
  };

  const handleDateData = () => {
    setMonthData(getCurrentDay());
    setMonthVal(getCurrentDay().date);
    calendarViewValue({
      view_type: "day",
      date_value: getCurrentDay().date_value,
      month_value: getCurrentDay().month_value,
      year_value: getCurrentDay().year_value,
    });
  };

  return (
    <>
      <div
        className={`inline-flex text-sm gap-x-[1px]
					${calendarView === "month" && "month-view"}
					${calendarView === "week" && "week-view"}
					`}
      >
        <button
          className="inline-flex items-center bg-gray-100 hover:text-[#3d3efd] rounded-tl-md rounded-bl-md hover:bg-gray-200 justify-center p-1.5"
          onClick={() => handleMonthChange("previous")}
        >
          <BsChevronLeft className="h-4 w-4" />
        </button>
        <span className="inline-flex items-center bg-gray-100 justify-center px-2.5 py-1.5">
          {monthVal}
        </span>
        <button
          className="inline-flex items-center bg-gray-100 hover:text-[#3d3efd] rounded-tr-md rounded-br-md hover:bg-gray-200 justify-center p-1.5"
          onClick={() => handleMonthChange("next")}
        >
          <BsChevronRight className="h-4 w-4" />
        </button>
      </div>
      <div className="inline-flex gap-x-[1px] items-center text-sm overflow-hidden bg-gray-100 rounded-md ">
        <button
          className={`px-3 py-1.5 inline-flex items-center justify-center hover:bg-gray-200 ${
            calendarView === "day" && "text-[#3d3efd] bg-gray-200"
          }`}
          onClick={() => handleCalendarView("day")}
        >
          Day
        </button>
        <button
          className={`px-2.5 py-1.5 inline-flex items-center justify-center hover:bg-gray-200 ${
            calendarView === "week" && "text-[#3d3efd] bg-gray-200"
          }`}
          onClick={() => handleCalendarView("week")}
        >
          Week
        </button>
        <button
          className={`px-3 py-1.5 inline-flex items-center justify-center hover:bg-gray-200 ${
            calendarView === "month" && "text-[#3d3efd] bg-gray-200"
          }`}
          onClick={() => handleCalendarView("month")}
        >
          Month
        </button>
      </div>
    </>
  );
}
