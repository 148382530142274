import React, { useEffect, useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Input, Table } from "reactstrap";
import {
  appType,
  appointmentDates,
  appointmentStatus,
  exportCSVFile,
  getMonthList,
  lateBy,
  patinetDates,
  titleCaseString,
} from "utils/common";
import { Icons } from "utils/contants/Icons";
import "./AppointmentScheduleReport.scss";
import { getScheduleAppointmentList } from "context/actions/clinic-reports";
import { useDispatch, useSelector } from "react-redux";
import { getAllDoctors } from "context/actions/appointment";
import moment from "moment";
import PaginationBlock from "shared/PaginationBlock";
import NodataFoundBlock from "shared/NoDataFoundBlock";
import PetPlaceholder from "shared/PetPlaceholder";

const AppointmentScheduleReport = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const [appointmentDataList, setAppointmentDataList] = useState([]);
  const [csvDataList, setCSVDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filterData, setfilterData] = useState({
    dateValue: "",
    appointmentValue: "",
    doctorsvalue: "",
    statusValue: "",
    patientLateBy: "",
    monthsValue: "",
    monthStartDate: "",
    monthEndDate: "",
    retained_patient: "",
  });
  const [doctorsId, setDoctorsId] = useState("");
  const [doctorsList, setDoctorsList] = useState([]);
  const [monthId, setMonthId] = useState("");
  const monthName = getMonthList(12);

  const fetchData = () => {
    let payload = {
      time_range: filterData?.dateValue,
      appointment_type: filterData?.appointmentValue,
      doctor_id: doctorsId,
      appointment_status: filterData?.statusValue,
      retained_patient: filterData?.retained_patient,
      startDate: filterData?.monthStartDate,
      endDate: filterData?.monthEndDate,
      patient_late_by:
        filterData?.patientLateBy && parseInt(filterData?.patientLateBy),
    };
    dispatch(
      getScheduleAppointmentList(
        {
          page,
          limit,
          clinic_id: clinic_details?._id,
          ...payload,
        },
        (res) => {
          setAppointmentDataList(res);
        }
      )
    );
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit]);

  useEffect(() => {
    setPage(1);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    dispatch(
      getAllDoctors(clinic_details?._id, {}, (res) => {
        setDoctorsList(res);
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   getAllMonthData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filterData?.monthStartDate, filterData?.monthEndDate]);

  const handleDownload = async (filter) => {
    const header = [
      "Patient",
      "Date",
      "Status",
      "Type",
      "Doctor",
      "Reason",
      "Temperature",
      "Weight",
      "Doctor Notes",
      "DOB",
      "Gender",
    ];
    let csvData = [];

    let payload = {
      time_range: filterData?.dateValue,
      appointment_type: filterData?.appointmentValue,
      doctor_id: doctorsId,
      appointment_status: filterData?.statusValue,
      retained_patient: filterData?.retained_patient,
      startDate: filterData?.monthStartDate,
      endDate: filterData?.monthEndDate,
      patient_late_by:
        filterData?.patientLateBy && parseInt(filterData?.patientLateBy),
    };
    dispatch(
      getScheduleAppointmentList(
        {
          page: 1,
          limit: appointmentDataList?.totalData,
          clinic_id: clinic_details?._id,
          ...payload,
        },
        (res) => {
          res?.paginate?.docs?.forEach((data) => {
            const {
              patient_id,
              appointment_date,
              appointment_status,
              appointment_type,
              doctor_details,
              reason,
              notes,
            } = data;
            csvData.push([
              patient_id?.pet_name ? titleCaseString(patient_id?.pet_name) : '--',
              moment(appointment_date).format("MM/DD/YYYY"),
              titleCaseString(appointment_status),
              titleCaseString(appointment_type),
              `Dr. ${titleCaseString(
                doctor_details?.firstname
              )} ${titleCaseString(doctor_details?.lastname)}`,
              titleCaseString(reason),
              patient_id?.temprature ? `${patient_id?.temprature} F`: '--',
              patient_id?.weight ? `${patient_id?.weight} lbs` : '--',
              notes ? new String(titleCaseString(notes)):  "--",
              moment(patient_id?.dob).format("MM/DD/YYYY"),
              titleCaseString(patient_id?.gender),
            ]);
          });

          exportCSVFile(header, csvData, "Appointment Schedule Report.csv");
        }
      )
    );

    // filter?.monthStartDate === ""
    //   ? appointmentDataList?.paginate?.docs
    //   : monthData;
  };
  const averageCount = appointmentDataList?.typeCounts;
  const averageData = [
    {
      id: 1,
      name: "In-clinic",
      value: averageCount?.["in-clinic"]?.average || 0,
      pathColor: "#198754",
    },
    {
      id: 2,
      name: "Phone-call",
      value: averageCount?.["phone-call"]?.average || 0,
      pathColor: "#0d6efd",
    },
    {
      id: 3,
      name: "Surgery",
      value: averageCount?.surgery?.average || 0,
      pathColor: "#ffc107",
    },
    {
      id: 4,
      name: "Video-call",
      value: averageCount?.["video-call"]?.average || 0,
      pathColor: "#11ca9d",
    },
    {
      id: 5,
      name: "Appointment-block",
      value: averageCount?.["appointment-block"]?.average || 0,
      pathColor: "#282e65",
    },
  ];

  const averageCountOfMonth = [
    {
      id: 1,
      monthName: monthName?.[0]?.id,
      value: 0,
    },
    {
      id: 2,
      monthName: monthName?.[1]?.id,
      value: 0,
    },
    {
      id: 3,
      monthName: monthName?.[2]?.id,
      value: 0,
    },
    {
      id: 4,
      monthName: monthName?.[3]?.id,
      value: 0,
    },
    {
      id: 5,
      monthName: monthName?.[4]?.id,
      value: 0,
    },
    {
      id: 6,
      monthName: monthName?.[5]?.id,
      value: 0,
    },
    {
      id: 7,
      monthName: monthName?.[6]?.id,
      value: 0,
    },
    {
      id: 8,
      monthName: monthName?.[7]?.id,
      value: 0,
    },
    {
      id: 9,
      monthName: monthName?.[8]?.id,
      value: 0,
    },
    {
      id: 10,
      monthName: monthName?.[9]?.id,
      value: 0,
    },
    {
      id: 11,
      monthName: monthName?.[10]?.id,
      value: 0,
    },
    {
      id: 12,
      monthName: monthName?.[11]?.id,
      value: 0,
    },
  ];

  averageCountOfMonth?.forEach((item) => {
    const monthData = appointmentDataList?.monthCount?.find(
      (o) => moment(o?.startDate).format("MMM YYYY") === item?.monthName
    );
    const appointments = monthData ? monthData?.appointments : 0;
    item.value = appointments;
  });

  return (
    <div id="appointment-schedule-container" className="p-1 mt-1">
      <div className="row">
        <div className="col-md-3">
          <div className="">
            <div className="d-flex">
              <div
                className="d-flex gap-2 align-items-center cmb-20 pointer"
                onClick={() => history.push("/reports")}
              >
                <BsChevronLeft color="color-3EFD" />
                <span className="text-13-600 color-3EFD">VIEW ALL REPORTS</span>
              </div>
            </div>

            <div className="bg-filter-class">
              <div className="text-24-700 color-2E65">Configure Report</div>
              <div className="text-14-400 color-8693">
                Select filter and generate report
              </div>
              <div className="filter-block cmt-20 cmb-20">
                <div className="cmb-10">
                  <Input
                    type="select"
                    name="select"
                    value={filterData?.retained_patient}
                    placeholder="All Patients"
                    onChange={(e) => {
                      setfilterData((prev) => ({
                        ...prev,
                        retained_patient: e?.target?.value,
                      }));
                    }}
                  >
                    <option value="">All Patients</option>
                    {patinetDates?.map((e, i) => {
                      return (
                        <option value={e?.id} key={i} id={e?.id}>
                          {e?.value}
                        </option>
                      );
                    })}
                  </Input>
                </div>
                <div className="cmb-10">
                  <Input
                    type="select"
                    name="select"
                    value={filterData?.dateValue}
                    placeholder="All Times"
                    style={{ textTransform: "capitalize" }}
                    onChange={(e) => {
                      setfilterData((prev) => ({
                        ...prev,
                        startDate: "",
                        endDate: "",
                        dateValue: e?.target?.value,
                      }));
                    }}
                  >
                    <option value="">All Times</option>
                    {appointmentDates?.map((e, i) => {
                      return (
                        <option value={e?.id} key={i} id={e?.id}>
                          {e?.value}
                        </option>
                      );
                    })}
                  </Input>
                </div>
                <div className="cmb-10">
                  <Input
                    type="select"
                    name="select"
                    value={filterData?.appointmentValue}
                    placeholder="All Type"
                    onChange={(e) => {
                      setfilterData((prev) => ({
                        ...prev,
                        appointmentValue: e?.target?.value,
                      }));
                    }}
                  >
                    <option value="">All Appointment Type</option>
                    {appType?.map((e, i) => {
                      return (
                        <option value={e?.value} key={i} id={e?.value}>
                          {titleCaseString(e?.value)}
                        </option>
                      );
                    })}
                  </Input>
                </div>
                <div className="cmb-10">
                  <Input
                    type="select"
                    name="select"
                    value={filterData?.doctorsvalue}
                    id={doctorsId}
                    placeholder="All Doctors"
                    onChange={(e) => {
                      setfilterData((prev) => ({
                        ...prev,
                        doctorsvalue: e?.target?.value,
                      }));
                      setDoctorsId(e?.target?.value);
                    }}
                  >
                    <option value="">All Doctors</option>
                    {doctorsList?.map((e, i) => {
                      if (!e?.user) {
                        return null;
                      }
                      const { user } = e;
                      const drName = `Dr. ${titleCaseString(
                        user?.firstname
                      )} ${titleCaseString(user?.lastname)}`;
                      return (
                        <option value={user?._id} key={i} id={user?._id}>
                          {drName && drName}
                        </option>
                      );
                    })}
                  </Input>
                </div>
                <div className="cmb-15">
                  <Input
                    type="select"
                    name="select"
                    value={filterData?.statusValue}
                    placeholder="Status"
                    onChange={(e) => {
                      setfilterData((prev) => ({
                        ...prev,
                        statusValue: e?.target?.value,
                      }));
                    }}
                  >
                    <option value="">All Status</option>
                    {appointmentStatus?.map((e, i) => {
                      return (
                        <option value={e?.value} key={i} id={e?.value}>
                          {titleCaseString(e?.value)}
                        </option>
                      );
                    })}
                  </Input>
                </div>
                <div className="cmb-10">
                  <Input
                    type="select"
                    name="select"
                    value={filterData?.patientLateBy}
                    placeholder="Late By"
                    onChange={(e) => {
                      setfilterData((prev) => ({
                        ...prev,
                        patientLateBy: e?.target?.value,
                      }));
                    }}
                  >
                    <option value="">Late By</option>
                    {lateBy?.map((e, i) => {
                      return (
                        <option value={e?.value} key={i} id={e?.value}>
                          {`${e?.value} min`}
                        </option>
                      );
                    })}
                  </Input>
                </div>
                <div className="">
                  <Input
                    type="select"
                    name="select"
                    value={filterData?.monthsValue}
                    id={monthId}
                    placeholder="All Months"
                    onChange={(e) => {
                      setMonthId(e?.target?.value);
                      setfilterData((prev) => ({
                        ...prev,
                        dateValue: "",
                        monthsValue: e?.target?.value,
                        monthStartDate: e?.target?.value
                          ? moment(e?.target?.value)
                              .startOf("month")
                              .format("MM-DD-YYYY")
                          : "",
                        monthEndDate: e?.target?.value
                          ? moment(e?.target?.value)
                              .endOf("month")
                              .format("MM-DD-YYYY")
                          : "",
                      }));
                    }}
                  >
                    <option value="">All Months</option>
                    {getMonthList(12)?.map((e, i) => {
                      return (
                        <option value={e?.id} key={i} id={e?.id}>
                          {e?.id}
                        </option>
                      );
                    })}
                  </Input>
                </div>
              </div>
              <div className="cmb-15">
                {/* <Button className="g-report">Generate Report</Button> */}
                <div
                  className="text-center color-2E65 text-13-600 cmt-20 pointer"
                  onClick={() => {
                    setfilterData({
                      dateValue: "",
                      appointmentValue: "",
                      doctorsvalue: "",
                      statusValue: "",
                      patientLateBy: "",
                      monthsValue: "",
                      monthStartDate: "",
                      monthEndDate: "",
                    });
                  }}
                >
                  RESET FILTER
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-9 details-section">
          <div className="cpt-20 cpe-20 cpb-20">
            <div className="d-flex justify-content-between align-items-center cmb-20">
              <div>
                <div className="text-24-700 color-2E65">
                  Appointment Reports
                </div>
                {/*<p className="text-14-400 color-8693 text-wrap">
                  Appointment Schedule reports help track the clinic’s medical
                  supplies, medications, and equipment to ensure that everything
                  is well-stocked and up to date.
                </p>*/}
              </div>
              <div className="d-flex gap-2 br-8">
                <div
                  className="cp-8 bg-8C9F28 pointer"
                  onClick={() => {
                    handleDownload(filterData);
                  }}
                >
                  <img src={Icons.download} alt="download" />
                </div>
                <div className="cp-8 bg-8C9F28 br-8">
                  <img src={Icons.share} alt="share" />
                </div>
              </div>
            </div>

            <div className="cmb-30 card-block row flex-wrap gy-3">
              <div className="text-15-700">
                Average Appointment Length
              </div>
              {averageData?.map((data, index) => {
                return (
                  <div
                    className="col-md-2 col-sm-4 flex-grow-1 avrage-card"
                    key={index}
                  >
                    <div className="items-details">
                      <div className="chart-item">
                        {/* <CircularProgressbarWithChildren
                          styles={buildStyles({
                            rotation: 0.5,
                            pathColor: data?.pathColor,
                            textColor: "#f88",
                            trailColor: "inherit",
                          })}
                          strokeWidth={10}
                          value={data?.value}
                        >
                          <div
                            style={{
                              width: 35,
                              padding: "7px 0px",
                              backgroundColor: "#ececec",
                              borderRadius: 50,
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 10,
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              {`${data?.value}`}
                            </div>
                          </div>
                        </CircularProgressbarWithChildren> */}
                      </div>
                      {/* <div className="text-13-500">Average Time</div> */}
                      <div className="text-13-500">{data?.name}</div>
                      <div className="text-13-700">{`${data?.value} min`}</div>
                    </div>
                  </div>
                );
              })}
              {/* <img src={Icons?.imGraph} alt="graph" className="w-100 h-100" /> */}
            </div>
            <div className="cmb-20 card-block row flex-wrap gy-3">
              <div className="text-15-700">
                Number of Appointments per Month
              </div>
              {averageCountOfMonth?.map((data, index) => {
                return (
                  <div
                    className="col-md-2 col-sm-4 flex-grow-1 avrage-card"
                    key={index}
                  >
                    <div className="items-details-month">
                      <div className="text-13-500">{`${data?.monthName} : ${data?.value}`}</div>
                    </div>
                  </div>
                );
              })}
              {/* <img src={Icons?.imGraph} alt="graph" className="w-100 h-100" /> */}
            </div>

            <div className="table-one cmb-20">
              <div className="text-14-600 color-2E65 cmb-10">
                {filterData?.monthStartDate
                  ? moment(filterData?.monthStartDate).format("MMMM")
                  : `Available`}{" "}
                ({appointmentDataList?.paginate?.totalDocs})
                {/* ({appointmentDataList?.paginate?.docs?.length}) */}
              </div>

              <Table responsive>
                <thead>
                  <tr>
                    <th>Patient</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Type</th>
                    <th>Doctor</th>
                    <th>Reason</th>
                    <th>Temperature</th>
                    <th>Weight</th>
                    <th>Doctor Notes</th>
                    <th>DOB</th>
                    <th>Gender</th>
                  </tr>
                </thead>
                <tbody>
                  {appointmentDataList?.paginate?.docs?.length > 0 ? (
                    appointmentDataList?.paginate?.docs?.map(
                      (dataElem, dataIndex) => {
                        const {
                          patient_id,
                          appointment_date,
                          appointment_status,
                          appointment_type,
                          doctor_details,
                          reason,
                          notes,
                        } = dataElem;

                        return (
                          <tr key={dataIndex}>
                            <td>
                              <div className="d-flex gap-1">
                                <div className="text-nowrap pic align-items-center d-inline-flex flex-shrink-0">
                                  {patient_id?.pet_image ? (
                                    <img
                                      className="img-fluid"
                                      src={patient_id?.pet_image}
                                      alt="Product"
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: 100,
                                      }}
                                    />
                                  ) : (
                                    <div
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: 100,
                                      }}
                                    >
                                      <PetPlaceholder />
                                    </div>
                                  )}
                                </div>
                                <div className="text-nowrap d-flex align-items-center">
                                  {titleCaseString(patient_id?.pet_name)}
                                </div>
                              </div>
                            </td>
                            <td>
                              {moment(appointment_date).format("MM/DD/YYYY")}
                            </td>
                            <td className="text-nowrap">
                              {titleCaseString(appointment_status)}
                            </td>
                            <td className="text-nowrap">
                              {titleCaseString(appointment_type)}
                            </td>
                            <td className="text-nowrap">{`Dr. ${titleCaseString(
                              doctor_details?.firstname
                            )} ${titleCaseString(
                              doctor_details?.lastname
                            )}`}</td>
                            <td>{reason ? titleCaseString(reason) : '--'}</td>
                            <td>
                              {patient_id?.temprature ?
                                `${patient_id?.temprature} F` : '--'}
                            </td>
                            <td>
                              {patient_id?.weight ?
                                `${patient_id?.weight} lbs`: '--'}
                            </td>
                            <td className="text-nowrap">
                              {notes ? titleCaseString(notes) : '--'}
                            </td>
                            <td>
                              {moment(patient_id?.dob).format("MM/DD/YYYY")}
                            </td>
                            <td className="text-nowrap">
                              {titleCaseString(patient_id?.gender)}
                            </td>
                          </tr>
                        );
                      }
                    )
                  ) : (
                    <tr className="no-data-rw">
                      <td colSpan="11">
                        <NodataFoundBlock />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {appointmentDataList?.paginate?.docs?.length > 0 && (
                <PaginationBlock
                  limit={limit}
                  page={page}
                  pagingCounter={
                    appointmentDataList?.paginate?.pagingCounter || 1
                  }
                  docLength={appointmentDataList?.paginate?.docs?.length || 0}
                  docPage={appointmentDataList?.paginate?.page || 1}
                  totalPages={appointmentDataList?.paginate?.totalPages || 1}
                  totalDocs={appointmentDataList?.paginate?.totalDocs || 0}
                  _handleLimit={(val) => {
                    setLimit(val);
                  }}
                  _handlePageChange={setPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentScheduleReport;
