import React from "react";
import { useSelector } from "react-redux";
export const useClinicBreed = () => {

    const {clinicBreeds }  = useSelector(state => state?.clinicSettingsReducer);


    const findClinicBreed = (breed_id) => {
    if(!clinicBreeds || clinicBreeds?.length <= 0){
        return false

    } else {
        if(clinicBreeds?.filter(i => i?.breed_id === breed_id)?.length > 0){
            return clinicBreeds?.filter(i => i?.breed_id === breed_id)?.[0]?.name
        } else {
            return false
        }
    }
    }
    return findClinicBreed
}


export const useClinicBreedDetails = () => {

    const {clinicBreeds }  = useSelector(state => state?.clinicSettingsReducer);


    const findClinicBreed = (breed_id) => {
    if(!clinicBreeds || clinicBreeds?.length <= 0){
        return false

    } else {
        if(clinicBreeds?.filter(i => i?.breed_id === breed_id)?.length > 0){
            return clinicBreeds?.filter(i => i?.breed_id === breed_id)?.[0]
        } else {
            return false
        }
    }
    }
    return findClinicBreed
}

