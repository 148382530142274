import React, { useEffect, useState } from "react";
import { Input, Table } from "reactstrap";
import CheckBox from "shared/CheckBox";
import { IoCloseCircleSharp } from "react-icons/io5";
import { FaCircleCheck } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import {
  getStaffAvailabilityByClinic,
  updateStaffAvailabilityStatus,
} from "context/actions/staffManagement";
import { toasterConfig } from "utils/constants";
import NodataFoundBlock from "shared/NoDataFoundBlock";
import "./ScheduleRequests.scss";
toast.configure();

const ScheduleRequests = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.user_details);
  let clinicID = userDetails?.primary_clinic || "";
  const [pageData, setPageData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [type, setType] = useState("all");
  const [ids, setIds] = useState([]);
  const handleCheckbox = (id) => {
    if (ids.includes(id)) {
      setIds(ids.filter((o) => o !== id));
    } else {
      setIds([...ids, id]);
    }
  };
  const handleChangeStatus = async (status, id) => {
    const response = await dispatch(
      updateStaffAvailabilityStatus({
        availiblity_id: id,
        status: status,
      })
    );
    if (response?.statusCode === 200) {
      getData();
      setIds([]);
      toast.success(response?.message, toasterConfig);
    }
  };
  const getData = async () => {
    const response = await dispatch(
      getStaffAvailabilityByClinic({ clinic_id: clinicID })
    );
    if (Array.isArray(response)) {
      setPageData(response);
    } else {
      setPageData([]);
    }
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let displayList = pageData?.filter(
    (o) =>
      (o?.isAvailable && type === "available") ||
      (!o?.isAvailable && type === "unavailable") ||
      type === "all"
  );

  displayList = displayList?.filter((element) => {
    let fName =
      `${element?.userDetails?.firstname} ${element?.userDetails?.lastname}`?.toLowerCase();
    return fName.includes(searchText.toLowerCase());
  });
  let isSelectAll = pageData.length && pageData.length === ids.length;
  return (
    <div id="schedule-requests-container">
      <div className="fb-center">
        <div className="table-list-filter-blc fa-center mb-0">
          <div className="search-cell col-cell position-relative">
            <span className="icomoon-search d-inline-flex align-items-center justify-content-center position-absolute"></span>
            <Input
              placeholder="Search"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
          <div className="input-cell col-cell">
            <Input
              type="select"
              name="select"
              value={type}
              onChange={(e) => {
                setType(e.target.value);
              }}
            >
              <option value="all">All Requests</option>
              <option value="available">Available</option>
              <option value="unavailable">Unavailable</option>
            </Input>
          </div>
        </div>

        {ids.length > 0 && (
          <div className="fa-center gap-2 pe-3">
            <div
              className="fa-center gap-2 br-4 b-e3e3 ps-2 pe-2 pt-1 pb-1 cursor-pointer"
              onClick={() => {
                handleChangeStatus(2, ids);
              }}
            >
              <span>
                <IoCloseCircleSharp color="#DE2B2B" size={20} />
              </span>
              <span className="text-14-400">Reject</span>
            </div>
            <div
              className="fa-center gap-2 br-4 b-e3e3 ps-2 pe-2 pt-1 pb-1 cursor-pointer"
              onClick={() => {
                handleChangeStatus(1, ids);
              }}
            >
              <span>
                <FaCircleCheck color="#11CA9D" size={16} />
              </span>
              <span className="text-14-400">Accept</span>
            </div>
          </div>
        )}
      </div>
      <div className="table-listing-blc overflow-auto">
        {displayList?.length === 0 ? (
          <NodataFoundBlock />
        ) : (
          <Table responsive className="checkbox-listing">
            <thead>
              <tr>
                <th>
                  <CheckBox
                    type="checkbox"
                    checked={isSelectAll}
                    className="no-label"
                    onChange={() => {
                      if (isSelectAll) {
                        setIds([]);
                      } else {
                        setIds(displayList.map((o) => o._id));
                      }
                    }}
                  />
                </th>
                <th>Name</th>
                <th>Dates</th>
                <th>Time</th>
                <th>Request type</th>
                <th className="text-end">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {displayList?.map((elm, index) => {
                const {
                  userDetails = {},
                  start_date,
                  end_date,
                  start_time,
                  end_time,
                  _id,
                  isAvailable,
                } = elm;
                const { title, firstname, lastname, user_image } = userDetails;
                return (
                  <tr key={index}>
                    <td>
                      <CheckBox
                        type="checkbox"
                        checked={ids.includes(_id)}
                        className="no-label"
                        onChange={() => {
                          handleCheckbox(_id);
                        }}
                      />
                    </td>
                    <td className="fa-center gap-2">
                      <div className="f-center bg-eeee color-cfcf h-20 w-20 rounded-circle">
                        {user_image ? (
                          <img
                            src={user_image}
                            alt="badge-img"
                            className="rounded-circle"
                          />
                        ) : (
                          <span className="material-symbols-outlined text-14-400">
                            person
                          </span>
                        )}
                      </div>
                      <div className="text-14-400 color-2E65">
                        {`${title} ${firstname} ${lastname}`}
                      </div>
                    </td>
                    <td className="text-14-400 color-2E65">
                      {`${moment(start_date, "DD-MM-YYYY").format(
                        "MM/DD/YYYY"
                      )} - ${moment(end_date, "DD-MM-YYYY").format(
                        "MM/DD/YYYY"
                      )}`}
                    </td>
                    <td className="text-14-400 color-2E65">
                      {`${start_time} - ${end_time}`}
                    </td>
                    <td className="text-14-400 color-2E65">
                      {isAvailable ? "Available" : "Unavailable"}
                    </td>
                    <td className="fa-center gap-2">
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          handleChangeStatus(2, [_id]);
                        }}
                      >
                        <IoCloseCircleSharp color="#DE2B2B" size={24} />
                      </span>
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          handleChangeStatus(1, [_id]);
                        }}
                      >
                        <FaCircleCheck color="#11CA9D" size={19} />
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default ScheduleRequests;
