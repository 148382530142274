import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toasterConfig } from "../../utils/constants";
import { PORT, apiUrl } from "../../environment";
import ApiClient from "../../api-client";


toast.configure();
/**
 * All Api endpoints to be call
 */



/**
 * @description function use to add new appointment
 * @param {*} params accepting object
 */
export const addNewVitalToggles = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.post(`${apiUrl}${PORT}/vital-toggle/add`, data, token, dispatch, getState).then(
      (response) => {
        if (!response) {
          return callback();
        } else if (!response && response.errors) {
          toast.error(response.errors.msg, toasterConfig);
          return callback(response);
        } else if (response) {
          toast.dismiss();
          toast.success(response.msg, toasterConfig);
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback();
        }
      }
    );
  };
};


/**
 * @description function use to add new appointment
 * @param {*} params accepting object
 */
export const updateVitalToggle = (data, callback) => {
    return (dispatch, getState) => {
      const {
        user: { token },
      } = getState();
      ApiClient.post(`${apiUrl}${PORT}/vital-toggle/update`, data, token, dispatch, getState).then(
        (response) => {
          if (!response) {
            return callback();
          } else if (!response && response.errors) {
            toast.error(response.errors.msg, toasterConfig);
            return callback(response);
          } else if (response) {
            toast.dismiss();
            toast.success(response.msg, toasterConfig);
            return callback(response);
          } else {
            //toast.error(response.message, toasterConfig);
            return callback();
          }
        }
      );
    };
  };



/**
 * @description function use to add new appointment
 * @param {*} params accepting object
 */
export const fetchVitalsTogglesForDischargPatientNavigator = (data, callback) => {
    return (dispatch, getState) => {
      const {
        user: { token },
      } = getState();
      ApiClient.get(`${apiUrl}${PORT}/vital-toggle/${data._id}`, data, token, dispatch, getState).then(
        (response) => {
          if (!response) {
            return callback();
          } else if (!response && response.errors) {
            toast.error(response.errors.msg, toasterConfig);
            return callback(response);
          } else if (response) {
            toast.dismiss();
            toast.success(response.msg, toasterConfig);
            return callback(response);
          } else {
            //toast.error(response.message, toasterConfig);
            return callback();
          }
        }
      );
    };
  };
