import React, { useEffect } from "react";
import PatientProfile from "../../../shared/PetOwnerDashboardSharedComponents/PatientProfile";
import TabScreens from "./TabScreens";
import "./index.scss";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPetDetailsById } from "../../../context/actions/patient-module/patient/pet";
import LoaderUI from "../../../shared/loader";
import NodataFoundBlock from "../../../shared/NoDataFoundBlock";

const PatientDetail = () => {
  const { loading, petDetails } = useSelector((state) => state.petReducer);
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch((params.petId, {}, () => {}));
}, []);                                                                               

  const toggleSideMenu = event => {
    document.body.classList.toggle('sidenav-toggled');
  };

  
  return (
    <>
      {loading ? (
        <LoaderUI />
      ) : !!petDetails ? (
        <div className="page-wrapper patient-detail-wrapper position-relative d-flex">
          <div className="left-col d-flex flex-column overflow-auto pet-detail-left-side">
            <PatientProfile />
          </div>
          <div className="right-col d-flex flex-fill overflow-auto">
            <button onClick={toggleSideMenu} id="sidebarToggle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z" />
              </svg>
            </button>
            <TabScreens />
          </div>
        </div>
      ) : (
        <NodataFoundBlock />
      )}
    </>
  );
};

export default PatientDetail;
