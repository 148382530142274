import React, { useState, useEffect } from "react";
import { Button, Input } from "reactstrap"; // eslint-disable-line
import "./index.scss";
import { v4 as uuidv4 } from "uuid";
import { AiOutlineFile } from "react-icons/ai";
import { addNewPetDocuments } from "../../context/actions/patient-module/document";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const UploadBlock = ({
  className = "",
  multiple,
  accept,
  images,
  setImages,
  imagesarr,
  setImagesarr,
  imageFiles,
  setImageFiles,
  documentimages,
  documentname,
}) => {
  const userDetail = useSelector((state) => state.user.user_details);
  const params = useParams();
  const petId = params.petId;

  const dispatch = useDispatch();

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const changeHandler = (e) => {
    const { files } = e.target;
    const validImageFiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // if (file.type.match(imageTypeRegex)) {
      validImageFiles.push(file);
      // }
    }
    if (validImageFiles.length) {
      setImageFiles(validImageFiles);
      return;
    }
  };

  useEffect(() => {
    const images2 = [],
      fileReaders = [];
    let isCancel = false;
    if (imageFiles.length) {
      imageFiles.forEach((file) => {
        const fileReader = new FileReader();
        fileReaders.push(fileReader);
        fileReader.onload = (e) => {
          const { result } = e.target;
          if (result) {
            // images2.push(result)
            images2.push({
              filename: file.name,
              fileimage: result,
              filetype: file.type,
              id: uuidv4(),
              datetime: file.lastModifiedDate.toLocaleString("en-IN"),
              filesize: filesizes(file.size),
            });
          }
          if (images2.length === imageFiles.length && !isCancel) {
            setImagesarr(images2);
          }
        };
        fileReader.readAsDataURL(file);
      });
    }
    return () => {
      isCancel = true;
      fileReaders.forEach((fileReader) => {
        if (fileReader.readyState === 1) {
          fileReader.abort();
        }
      });
    };
  }, [imageFiles]);

  const handleDelete = (idx) => {
    if (window.confirm("Are you sure you want to delete this Image?")) {
      let filteredImages = imagesarr.filter((image, index) => index != idx);
      setImagesarr(filteredImages);

      let filtered_images = [];
      for (let i = 0; i < images.length; i++) {
        if (i != idx) filtered_images.push(images[i]);
      }
      setImages(filtered_images);
    }
  };

  const uploadDocuments = (e) => {
    e.preventDefault();

    let formdata = new FormData();

    if (images && images.length > 0) {
      for (let image of images) {
        formdata.append("document", image, image.name);
      }
    }

    formdata.append("pet_id", petId);
    formdata.append("added_by", userDetail?._id);

    dispatch(
      addNewPetDocuments(formdata, petId, (response) => {
        // console.log("response",formData,response)

        if (response && !response.errors) {
          setImageFiles([]);
          setImages([]);
          setImagesarr([]);
        } else {
        }
      })
    );
  };
  return (
    <>
      <div
        className={`upload-blc d-flex align-items-center justify-content-center text-center ${className}`}
      >
        <div className="inner position-relative">
          <Input
            type="file"
            className="position-absolute"
            onChange={(e) => {
              changeHandler(e);
              setImages(e.target.files);
            }}
            multiple={multiple ? true : false}
            // accept='image/png, image/jpg, image/jpeg, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/gzip, application/zip, application/x-rar-compressed'
            accept={accept}
          />
          <div className="pic m-auto w-auto h-auto">
            <span className="d-inline-flex align-items-center justify-content-center rounded-circle">
              <img
                className="img-fluid"
                src={
                  require("../../assets/img/vetRecorEMR/upload-icon.svg")
                    .default
                }
                alt="Upload Icon"
              />
            </span>
          </div>
          <div className="txt">
            <h3 className="mt-1">Drag and drop or browse files</h3>
            {/* <h4 className="mt-1">Support zip and rar files</h4> */}
          </div>
        </div>
      </div>

      <div style={{ marginTop: "20px" }}>
        {imagesarr.map((data, index) => {
          const { id, filename, filetype, fileimage, datetime, filesize } =
            data;
          return (
            <div className="file-atc-box" key={id}>
              {filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ? (
                <div className="file-image">
                  {" "}
                  <img src={fileimage} alt="" />
                </div>
              ) : (
                <div className="file-image">
                  <AiOutlineFile />
                </div>
              )}
              <div className="file-detail">
                <h6>{filename}</h6>
                <p></p>
                <p>
                  <span>Size : {filesize}</span>
                  <span className="ml-2">Modified Time : {datetime}</span>
                </p>
                <div className="file-actions">
                  <button
                    type="button"
                    className="file-action-btn"
                    onClick={() => handleDelete(index)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          );
        })}
        {documentimages && imagesarr.length == 0 && (
          <div className="file-atc-box" key={Math.random().toString()}>
            {documentimages.match(/.(jpg|jpeg|png|gif|svg)$/i) ? (
              <div className="file-image">
                {" "}
                <img src={documentimages} alt="" />
              </div>
            ) : (
              <div className="file-image">
                <AiOutlineFile />
              </div>
            )}
            <div className="file-detail">
              <h6>{documentname}</h6>
              {/* <p>

								</p>
								<p><span>Size : {filesize}</span><span className="ml-2">Modified Time : {datetime}</span></p>
								<div className="file-actions">
									<button type="button" className="file-action-btn" onClick={() => handleDelete(index)}>Delete</button>
								</div> */}
            </div>
          </div>
        )}

        {/* {imagesarr.length > 0 && <button type="button" class="upload_btn" onClick={uploadDocuments}>Upload</button>} */}
      </div>
    </>
  );
};

export default UploadBlock;
