import React, { useEffect, useState } from "react";
import { TfiReload } from "react-icons/tfi";
import { Calendar } from "../../shared/Calendar/CalendarFilter";
import "../../shared/Calendar/CalendarFilter/index.scss";
import { useDispatch, useSelector } from "react-redux";
import PatientListPanel from "../../shared/Calendar/PatientListPanel";
import { patientListPanelAction } from "../../context/actions/patientListPanelAction";
import PageHeader from "../../shared/PageHeader";
import AddBoarding from "./AddBoarding";
import { getKennels } from "../../context/actions/kennels";
import { getBoardingByClinicId, getBoardingByClinicIdWithDate } from "context/actions/boarding";

const Boarding = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.user_details);
  const [calendarView, setCalendarView] = useState("day");
  const [calendarViewData, setCalendarViewData] = useState({
    span_date: new Date().getDate(),
    date_value: new Date().getDate(),
    month_value: new Date().getMonth(),
    span_month: new Date().getMonth(),
    year_value: new Date().getFullYear(),
  });
  const [list, setList] = useState([]);
  const [selectedPatient, setSelectPatient] = useState();
  const [show, setShow] = useState(false);
  const [kennelInfo,setKennelInfo]=useState()
  const [isPetAddedToTheKennel,setIsPetAddedToTheKennel]=useState(false)
  const patientListPanelState = useSelector(
    (state) => state.patientListPanelReducer.isPatientListPanel
  );
  const allKennels=useSelector(state=>state?.kennelReducer?.kennels)
  const openPatientPanel = () => {
    dispatch(patientListPanelAction(!patientListPanelState));
  };

  
  const kennels = ["kennel 1", "kennel 2", "kennel 3", "kennel 4", "kennel 5", "kennel 6", "kennel 7", "kennel 8", "kennel 9", "kennel 10"];
// these function will reuse in future
  const filterList = () => {
    debugger
    const { date_value, month_value, year_value } = calendarViewData;
    const selectedDate = new Date();
    selectedDate.setDate(parseInt(date_value));
    selectedDate.setMonth(parseInt(month_value) - 1);
    selectedDate.setFullYear(parseInt(year_value));
    const startDate = new Date(selectedDate);
    startDate.setDate(startDate.getDate() - selectedDate.getDay());
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);

    const filteredList = kennelInfo.filter((item) => {
      // const itemDate = new Date(item.date);
      const itemDate = new Date(item?.boarding_date);
      if (calendarView === "day") {
        return itemDate.getDay() === selectedDate.getDay();
      }
      if (calendarView === "month") {
        return (
          itemDate.getMonth() === selectedDate.getMonth() &&
          itemDate.getFullYear() === selectedDate.getFullYear()
        );
      }
      if (calendarView === "week") {
        return itemDate >= startDate && itemDate <= endDate;
      }
      return true;
    });
    return filteredList;
  };

  useEffect(() => {
    // const list = filterList();
    // setList(list)
    // 2023-06-22
    const { date_value, month_value, year_value } = calendarViewData;
    
    const dateFormate =
      year_value +
      "-" +
      (month_value <= 9 ? "0" + month_value : month_value) +
      "-" +
      date_value;
    
    console.log("calendar",calendarView,dateFormate)
    if(userDetails?._id&&dateFormate&&calendarView!=="week"){
      dispatch(getBoardingByClinicIdWithDate(dateFormate, (data)=>{
        console.log("kennel data",data)
        setKennelInfo(data)
      }))
    }else if(calendarView=="week"){
      dispatch(getBoardingByClinicId((data)=>{
        console.log("kennel data",data)
        setKennelInfo(data)
      }))
    }
    
   
  }, [calendarView, calendarViewData,isPetAddedToTheKennel,userDetails?._id]);

const columnKennels=(num)=>{
    let parsedData=num?.split(" ")
    // console.log("parsed data",parsedData[1])
    const kennelNum=parseInt(parsedData[1])
    return Number(kennelNum)
}

useEffect(()=>{
  dispatch(getKennels(userDetails?._id,(res)=>{
    console.log("kennels",res)
  }))
},[])

  function getTime() {
    const time = [];
// 24 hour dynamic boarding
    for (let i = 6; i <= 20; i++) {
      const hour = i > 12 ? i - 12 : i;
      const format = i >= 12 ? "pm" : "am";
      time.push({
        hour,
        format,
      });
    }

    return time;
  }

  const addPatientOnBoarding = (petId) => {
    setSelectPatient(petId);
    dispatch(patientListPanelAction(!patientListPanelState));
    setShow(true);
  };

  const findKennelColumns=(kennelId)=>{
    // console.log("iddd",operatingRoomId)
    // operatingRooms.map((data,i)=>{
    //     // console.log("iddd data",data)
    //     if(data?._id==operatingRoomId){
    //         console.log("opearting room coumn",i,data)
    //         return i
    //     }
    // })
    // console.log("opearting room coumn",operatingRooms.findIndex((data)=>data?._id===operatingRoomId))
    console.log("kennel info",kennelInfo,kennelId)
    // const idx=kennelInfo.findIndex((data)=>data?.kennel===kennelId)
    const idx=allKennels.findIndex((data)=>data?._id===kennelId)
    if(idx===0){
        return 1
    }else{

        return idx+1
    }
  }

  console.log("kenneell ",kennelInfo)
  return (
    <div className="bg-white_A700 flex flex-col content-start font-inter gap-2.5 shadow-bs1 w-auto sm:w-full md:w-full">
    
      <PageHeader className='dashboard-head flex-flex-shrink-0' title="Boarding"></PageHeader>
      
      <div className="pt-[15px] px-[20px] flex flex-col gap-y-2 md:flex-row justify-between w-full">
        <div className="flex md:flex-row flex-col gap-[4px] ">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-1 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-4 h-4 text-[#788693]"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="font-normal w-full text-sm rounded-md pl-6 py-2 text-left inline-flex items-center bg-[#e4e4e447] text-[#788693]"
              placeholder="Search"
            />
          </div>

          <select
            id="dropdownDefaultButton"
            data-dropdown-toggle="dropdown"
            className="font-normal  text-sm px-2 py-2 bg-[#e4e4e447] rounded-md"
            name="All Kennels"
          >
            <option disabled selected>
              All Kennels
            </option>
            <option value="volvo">Volvo</option>
            <option value="saab">Saab</option>
          </select>
          <select
            id="dropdownDefaultButton"
            data-dropdown-toggle="dropdown"
            className="font-normal text-sm  px-2 py-2 bg-[#e4e4e447] rounded-md"
            name="All Kennels"
          >
            <option disabled selected>
              Default Filter Name
            </option>
            <option value="volvo">1</option>
            <option value="saab">2</option>
          </select>
        </div>
        <div className="flex right-col items-center gap-2 px-2">
          <Calendar
            sendCalendarView={(value) => {
              setCalendarView(value);
            }}
            calendarViewValue={(value) => setCalendarViewData(value)}
          />
        </div>
      </div>
      <div className="px-[20px] pb-[20px] relative rounded-xl overflow-auto">
        <div className="bg-white overflow-hidden">
          <div
            className={`overflow-auto min-w-screen grid grid-rows-[auto,repeat(10,120px)] max-h-[85vh]`}
            style={{
              gridTemplateColumns: `70px repeat(${allKennels.length}, minmax(150px, 1fr))`,
              gridTemplateRows: `auto repeat(${getTime().length}, 120px)`,
            }}
          >
            {allKennels.map((k, i) => (
              <React.Fragment key={i + Date.now() + Math.random()}>
                {i === 0 && (
                  <div className="row-start-[1] col-start-[1] border-slate-100 border-r p-1.5 sticky left-0 bg-white"></div>
                )}
                <button
                  onClick={openPatientPanel}
                  className={`row-start-[1] col-start-[${
                    i + 2
                  }] sticky top-0 bg-white border-b text-center text-xs xl:text-sm font-medium py-2`}
                >
                  {k.kennel_name}
                </button>
              </React.Fragment>
            ))}
            {getTime().map((t, i) => (
              <React.Fragment key={i + Date.now() + Math.random()}>
                {i === 0 && (
                  <div className="row-start-[1] col-start-[1] place-self-center p-1.5 sticky top-0 bg-white">
                    <TfiReload
                      className="w-4 h-4 text-blue-500"
                      color="currentColor"
                    />
                  </div>
                )}
                <div
                  className={`row-start-[${
                    i + 2
                  }] col-start-[1] border-slate-100 border-r text-sm xl:text-base p-1.5 text-right text-slate-400 uppercase sticky left-0 bg-white font-medium`}
                >
                  {t.hour} {t.format}
                </div>
              </React.Fragment>
            ))}
            {getTime().map((_, i) =>
              allKennels.map((_, j) => (
                <div
                  key={i + Date.now() + Math.random()}
                  className={`border-slate-100 border-r`}
                  style={{
                    gridRowStart: `${i + 2}`,
                    gridColumnStart: `${j + 2}`,
                  }}
                ></div>
              ))
            )}

            {/* {list[0]?.kennel_info?.map((l, i) => { */}
            {kennelInfo?.map((l, i) => {
              const startHour = Number(l?.boarding_start_time?.split(":")[0]);
              const endHour = Number(l?.boarding_end_time?.split(":")[0]);
              const startRow = startHour - Number(getTime()[0].hour) + 2;
              const rowSpan = endHour - startHour;
              // const column = columnKennels(l.kennel);
              const column=findKennelColumns(l.kennel)
              // const column = Number(l.kennel);

              return (
                <div
                  key={i + Date.now() + Math.random()}
                  className={`row-span-[${rowSpan}] bg-gray-100 border rounded-lg m-1 p-1 overflow-hidden`}
                  style={{
                    gridRowStart: `${startRow}`,
                    gridColumnStart: `${column + 1}`,
                    gridRowEnd: `span ${rowSpan}`,
                  }}
                >
                  <BoardingCard obj={l} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        className={`patient-panel-overlay position-fixed ${
          patientListPanelState ? "active" : ""
        }`}
        onClick={() => dispatch(patientListPanelAction(false))}
      ></div>
      <PatientListPanel
        className={`${patientListPanelState ? "active" : ""}`}
        clinicId={userDetails._id}
        callable={addPatientOnBoarding}
      />
      {show && (
        <AddBoarding kennels={allKennels} petId={selectedPatient} show={show} setShow={setShow} isPetAddedToTheKennel={isPetAddedToTheKennel} setIsPetAddedToTheKennel={setIsPetAddedToTheKennel} />
      )}
    </div>
  );
};

export default Boarding;

const PetImage = ({ src }) => {
  return (
    <img
      src={src}
      alt="pet-img"
      className="w-14 h-full max-h-[100px] object-cover rounded-md"
    />
  );
};

const PetName = ({ name }) => {
  return (
    <p className="capitalize font-semibold text-sm mb-2 md:text-base xl:text-lg">
      {name}
    </p>
  );
};

const PetGender = ({ gender }) => {
  return (
    <p className="capitalize text-xs lg:text-sm xl:text-base mb-2">{gender}</p>
  );
};

const PetTypeAndBreed = ({ type, breed }) => {
  return (
    <p className="text-xs mb-2">
      {type} {breed && <span>({breed})</span>}
    </p>
  );
};

const PetPurpose = ({ purpose }) => {
  return <p className="text-sm font-medium mb-2">{purpose}</p>;
};

const ExpectedDischargeDate = ({ expectedDischargeDate }) => {
  return (
    <p className="text-sm mb-2">
      Expected Discharge date
      <br />
      <span className="mt-1">
        {/* {new Date(expectedDischargeDate).toLocaleDateString()} */}
        {expectedDischargeDate?.replace(/-/g,"/")}
      </span>
    </p>
  );
};

const BoardingCard = ({ obj }) => {
  // console.log("boarding img",obj)
  return (
    <div className="flex flex-col items-center text-center">
      <PetImage src={obj.pet_image} />
      <PetName name={obj.pet_name} />
      {obj.gender && <PetGender gender={obj.gender} />}
      {obj.type && <PetTypeAndBreed type={obj.type} breed={obj.breed} />}
      {obj.boarding_purpose && <PetPurpose purpose={obj.boarding_purpose} />}
      {obj.boarding_date&& (
        <ExpectedDischargeDate
          expectedDischargeDate={obj.boarding_date}
        />
      )}
    </div>
  );
};
