import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../../../api-client";
import { apiUrl, PORT } from "../../../../environment";
import { toasterConfig } from "../../../../utils/constants";

toast.configure();
/**
 * All Api endpoints to be call
 */

/**
 * get all conditions
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */
export const getConditionByPetId = (petId, params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    if (petId) {
      dispatch({ type: "CONDITION_LOADING", payload: true });
      ApiClient.get(
        `${apiUrl}${PORT}/condition/get-by-pet-id/${petId}`,
        params,
        token, dispatch, getState
      ).then((response) => {
        dispatch({ type: "CONDITION_LOADING", payload: false });
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({
            type: "GET_CONDITION_LIST",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          dispatch({ type: "GET_CONDITION_LIST", payload: false });
          return callback(response);
        }
      });
    } else {
      return callback({ status: false });
    }
  };
};

/**
 * get all pet details
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */
export const getCurrentConditionByPetId = (petId, params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    if (petId) {
      dispatch({ type: "CONDITION_LOADING", payload: true });
      ApiClient.get(
        `${apiUrl}${PORT}/condition/get-current-by-pet-id/${petId}`,
        params,
        token, dispatch, getState
      ).then((response) => {
        dispatch({ type: "CONDITION_LOADING", payload: false });
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({
            type: "GET_CURRENT_CONDITION_LIST",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          dispatch({ type: "GET_CURRENT_CONDITION_LIST", payload: false });
          return callback(response);
        }
      });
    } else {
      return callback({ status: false });
    }
  };
};

/**
 * @description function use to add new appointment
 * @param {*} params accepting object
 */
export const addCondition = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/condition/add`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response) {
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * @description function use to add new appointment
 * @param {*} params accepting object
 */
export const addMultipleConditions = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/condition/addMultipleConditions`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response) {
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * @description function use to add new appointment
 * @param {*} params accepting object
 */
export const updateConditionsStatuses = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.put(
      `${apiUrl}${PORT}/condition/updateConditionsStatuses`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (!response && response.errors) {
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response) {
        toast.dismiss();
        toast.success(response.msg, toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * get all problems
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */
export const getProblemsByPetId = (petId, params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    if (petId) {
      dispatch({ type: "CONDITION_LOADING", payload: true });
      ApiClient.get(
        `${apiUrl}${PORT}/condition/pet-problems-list/${petId}`,
        params,
        token, dispatch, getState
      ).then((response) => {
        dispatch({ type: "CONDITION_LOADING", payload: false });
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({
            type: "GET_PET_PROBLEM_LIST",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          dispatch({ type: "GET_PET_PROBLEM_LIST", payload: false });
          return callback(response);
        }
      });
    } else {
      return callback({ status: false });
    }
  };
};
