import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  ButtonDropdown,
  DropdownToggle,
  Table,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Input,
} from "reactstrap";
import {
  deletePetDocumentAction,
  getAllPetDocumentsByPetId,
} from "../../../../../context/actions/patient-module/document";
import LoaderUI from "../../../../../shared/loader";
import NodataFoundBlock from "../../../../../shared/NoDataFoundBlock";
import UploadBlock from "../../../../../shared/UploadBlock";
import UserPlaceholder from "../../../../../shared/UserPlaceholder";
import { dateFromDateTime } from "../../../../../utils/common";
import "./index.scss";
import { imageBaseUrl, documentPhotosPath } from "../../../../../environment";
import Swal from "sweetalert2";
import { RiDeleteBin5Line } from "react-icons/ri";
import AddEditPetDocument from "../../../../../shared/PetDocument/AddEdit";
import useDebounce from "hooks/useDebounce";
import moment from "moment";

const Documents = ({ petId }) => {
  const [dropdownOpen, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [filterData, setFilterData] = useState({
    search: "",
    type: "",
  });
  const searchDebouncedValue = useDebounce(filterData?.search, 300);
  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  const toggleDropdown = () => setOpen(!dropdownOpen);

  const tempData = [0, 0, 0, 0, 0, 0];
  const dispatch = useDispatch();
  const { loading, petDocumentList, openPetDocumentAddEditPanel } = useSelector(
    (state) => state.petDocumentReducer
  );
  const [selectedDocument, setSelectedDocument] = useState(null);

  useEffect(() => {
    if (petId) {
      dispatch(getAllPetDocumentsByPetId(petId, {}, () => { }));
    }
  }, [petId]);

  const openPetDocumentAddEditPanelFunction = () => {
    dispatch({
      type: "OPEN_CLOSE_ADD_EDIT_PET_DOCUMENT_PANEL",
      payload: true,
    });
  };

  const closePetDocumentAddEditPanelFunction = () => {
    dispatch({
      type: "OPEN_CLOSE_ADD_EDIT_PET_DOCUMENT_PANEL",
      payload: false,
    });
    setSelectedDocument(null);
  };

  const deletePetDocument = (e, document_id) => {
    e.preventDefault();

    Swal.fire({
      title: "<strong>Are you sure you want to delete this document?</strong>",
      icon: "warning",
      html: `Once deleted your data cannot be recovered`,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Confirm",
      confirmButtonAriaLabel: "Confirm",
      cancelButtonText: "Cancel",
      cancelButtonAriaLabel: "Cancel",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(deletePetDocumentAction(document_id, petId, (response) => { }));
      } else if (result.isDenied) {
        Swal.fire("Your data is safe", "", "info");
      } else if (result.isDismissed) {
        Swal.fire("Your data is safe", "", "info");
      }
    });
  };
  const handleSearch = async (e) => {
    let value = e.target.value;
    setFilterData({ ...filterData, search: value });
  };
  const handelSelctOption = (e) => {
    let optionValue = e.target.value;
    setFilterData({ ...filterData, type: optionValue });
  };

  useEffect(() => {
    const response = dispatch(getAllPetDocumentsByPetId(petId, filterData));
  }, [searchDebouncedValue, filterData?.type]);
  return (
    <>
      <div className="documents-block">
        {/* <div className="right-col ms-auto">
					<div className="btn-blc">
						<Button color="success" className="add-btn" onClick={openPetDocumentAddEditPanelFunction}>Add new</Button>
					</div>
				</div> */}
        {/* <div className="row-block">
					<UploadBlock />
				</div> */}
        <div className="row-block available-docs">
          <div className="head ">
            <h2>Available Documents</h2>
          </div>
          <div className="title-header d-flex align-items-center flex-fill">
            <div className="relative w-full lg:w-52">
              <Input
                type="text"
                placeholder="Search"
                className="ps-5"
                value={filterData?.search}
                onChange={handleSearch}
              />
              <span className="absolute top-[11px] left-[4px] pl-[5px]">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth={0}
                  viewBox="0 0 1024 1024"
                  color="#788693"
                  height={25}
                  width={25}
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "rgb(120, 134, 147)" }}
                >
                  <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z" />
                </svg>
              </span>
            </div>
            <div className="flex ms-3 flex-col md:flex-row">
              <div>
                <select
                  name="select"
                  className="h-[35px] w-full md:w-52 form-control"
                  value={filterData?.type}
                  onChange={handelSelctOption}
                >
                  <option value={""}>All</option>
                  <option value={"Intake Form"}>Intake Forms</option>
                  <option value={"After Visit Summary"}>
                    After Visit Summaries
                  </option>
                  <option value={"Estimates"}>Estimates</option>
                  <option value={"Certificates"}>Certificates</option>
                  <option value={"Invoices"}>Invoices</option>
                  <option value={"Other"}>Other</option>
                </select>
              </div>
            </div>
            {permissions?.edit_documents && (
              <div className="right-col ms-auto">
                <div className="btn-blc">
                  <Button
                    color="success"
                    className="add-btn btn btn-success"
                    onClick={openPetDocumentAddEditPanelFunction}
                  >
                    Add new
                  </Button>
                </div>
              </div>
            )}
          </div>

          {loading ? (
            <LoaderUI />
          ) : petDocumentList?.length > 0 ? (
            <div className="table-listing-blc">
              <Table
                style={{ maxHeight: "65vh", overflow: "scroll" }}
                responsive
                striped
                className="checkbox-listing"
              >
                <thead>
                  <tr>
                    <th style={{ minWidth: "192px" }}>Document Name</th>
                    <th>Type</th>
                    <th style={{ minWidth: "106px" }}>Valid Until</th>
                    <th style={{ minWidth: "205px" }}>Added by</th>
                    <th>Notes</th>
                    {permissions?.edit_documents && (
                      <th style={{ textAlign: "right" }}>Actions</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {petDocumentList?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          {/* [Document Name] */}
                          <a
                            href={`${item.document_link}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item?.document_name}
                          </a>
                        </td>
                        <td>
                          {/* [Document Type] */}
                          {item?.document_type}
                          {/* {item?.document_name.split(".")[1]} */}
                        </td>
                        <td>
                          {/* 05/17/2022 */}
                          {item?.valid_till &&
                            item?.valid_till !== "Invalid date"
                            ? moment(item?.valid_till, "YYYY-MM-DD").format(
                              "MM/DD/YYYY"
                            )
                            : "--"}
                        </td>
                        <td>
                          <div className="p-0 text-start pic-cell d-inline-flex align-items-center">
                            {/* <div className="pic rounded-circle d-inline-flex overflow-hidden">
															<img className="rounded-circle w-100 h-100" src={require('../../../../../assets/img/vetRecorEMR/user-thumbnail.png')} alt="User" />
															<UserPlaceholder />
														</div>
														Dr. Connie Reinhardt */}
                            <div className="pic rounded-circle d-inline-flex overflow-hidden">
                              {item?.addedBy?.user_image ? (
                                <img
                                  className="rounded-circle w-100 h-100"
                                  src={
                                    item?.addedBy?.user_image ||
                                    require("../../../../../assets/img/vetRecorEMR/user-thumbnail.png")
                                  }
                                  alt="User"
                                />
                              ) : (
                                <UserPlaceholder />
                              )}
                            </div>
                            {`${item?.addedBy?.title
                                ? item?.addedBy?.title + " "
                                : ""
                              } ${item?.addedBy?.firstname} ${item?.addedBy?.lastname
                              }`}
                          </div>
                        </td>
                        <td
                          className="text-clip-cell"
                          style={{ minWidth: "190px", maxWidth: "190px" }}
                        >
                          {/* This highly infectious bacterium causes severe fits of coughing, whooping, vomiting, and, in */}
                          {item?.notes || "--"}
                        </td>
                        {permissions?.edit_documents && (
                          <td className="action-cell">
                            <div className="d-flex align-items-center justify-content-end">
                              <Button
                                color="link"
                                className="edit-btn"
                                onClick={() => {
                                  setSelectedDocument(item);
                                  openPetDocumentAddEditPanelFunction();
                                }}
                              >
                                <span className="icomoon-pencil"></span>
                              </Button>

                              <Button
                                onClick={(e) => deletePetDocument(e, item._id)}
                                color="link"
                                className="edit-btn"
                              >
                                {/* <span className="icomoon-pencil"></span> */}
                                <RiDeleteBin5Line />
                              </Button>
                            </div>
                            {/* <ButtonDropdown tether isOpen={dropdownOpen && i == activeIndex ? true : false} toggle={() => {
														toggleDropdown()
														setActiveIndex(i)
													}}>
														<DropdownToggle color="link" className="more-btn">
															<span className="icomoon-more"></span>
														</DropdownToggle>
														<DropdownMenu>
															<DropdownItem>Edit</DropdownItem>
															<DropdownItem onClick={(e) => deletePetDocument(e, item._id)}>Delete</DropdownItem>
														</DropdownMenu>
													</ButtonDropdown> */}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          ) : (
            <NodataFoundBlock />
          )}
        </div>

        {openPetDocumentAddEditPanel && (
          <>
            <div
              className={`patient-panel-overlay position-fixed ${openPetDocumentAddEditPanel ? "active" : ""
                }`}
              onClick={() => closePetDocumentAddEditPanelFunction()}
            ></div>
            <AddEditPetDocument
              className={`${openPetDocumentAddEditPanel ? "active" : ""}`}
              closePetDocumentAddEditPanelFunction={
                closePetDocumentAddEditPanelFunction
              }
              selectedDocument={selectedDocument}
              setSelectedDocument={setSelectedDocument}
            />
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(Documents);
