import React, { useEffect } from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import UserPlaceholder from "../../../../../../../shared/UserPlaceholder";
// import { dateFromDateTime } from "../../../../../../../utils/common";
import "./index.scss";
import { Offcanvas } from "react-bootstrap";
import { useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import redCancel from "assets/img/icons/redCancel.png";
import greenCheck from "assets/img/icons/greenCheck.png";
import {
  updateMedicationReportStatus,
  updateUnkownStatusData,
} from "context/actions/patient-module/overview/medication-plan";
import { BsCapsule } from "react-icons/bs";
import {
  getHomeMedicationOrders,
  getOrdersWithCategoryByPetId,
  updateOrder,
} from "context/actions/patient-module/orders";
import { Input } from "reactstrap";
import { dobInYearMonthDays, titleCaseString } from "utils/common";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { toasterConfig } from "utils/constants";

const MedicationPlanItem = ({ item }) => {
  const params = useParams();
  const pId = params?.petId;

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  const dispatch = useDispatch();
  const [noteValue, setNoteValue] = useState("");

  const userDetails = useSelector((state) => state?.user?.user_details);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = async () => {
    const filtersData = [];
    getOrderDates()?.forEach((elem) => {
      const { date, timeList } = elem;

      timeList?.forEach((data) => {
        const mergeDateTime = moment(`${date.format("YYYY-MM-DD")} ${data}`);
        const dateTime = moment(`${date.format("YYYY-MM-DD")} ${data}`);
        const newmergeDateTime = dateTime.add(72, "hours");

        const currentDate = moment();
        const diffInHours = currentDate?.diff(mergeDateTime, "hours");
        const isUnknownValue = diffInHours >= 72;

        const MRreportData = item?.medician_reports?.find(
          (o) =>
            o?.date === date.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") &&
            o.time === data
        );

        if (
          isUnknownValue &&
          !MRreportData?.isUnknown &&
          !MRreportData?.plan_status
        ) {
          filtersData.push({
            order_id: item?._id,
            item: item?.item,
            created_by: userDetails?._id,
            pet_id: item?.pet_id,
            date: moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            unknownCreatedDate: moment(newmergeDateTime).format(
              "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
            ),
            time: data,
            isUnknown: true,
          });
        }
      });
    });
    if (filtersData?.length > 0) {
      const response = await dispatch(
        updateUnkownStatusData({ orders: filtersData })
      );
      if (response?.status) {
        dispatch(getHomeMedicationOrders({ pet_id: item?.pet_id }, () => { }));
        setShow(true);
      }
    } else {
      setShow(true);
    }
  };

  const getOrderDates = () => {
    var dates = [];
    var currDate = moment(
      item?.first_dose,
      "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
    ).startOf("day");
    var lastDate = moment(item?.first_dose, "YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      .add(item?.duration_num, item?.duration_unit.toLowerCase())
      .startOf("day");
    while (currDate.isBefore(lastDate, "day")) {
      dates.push(currDate.clone());
      currDate.add(1, "days");
    }
    let newData = [];
    dates.forEach((o, i) => {
      if (i === 0) {
        let fromTime = moment(
          item?.first_dose,
          "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
        ).format("DD-MM-YYYY hh:mm A");
        let toTime = `${moment(
          item?.first_dose,
          "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
        ).format("DD-MM-YYYY")} 11:59 PM`;
        let newTimeArray = getDoseAndTimeIntarval(
          item?.frequency,
          fromTime,
          toTime
        );
        newData.push({
          date: o,
          timeList: newTimeArray?.timeList,
        });
      } else {
        let prevList = newData?.[i - 1];
        let prevDate = prevList?.date?.format("DD-MM-YYYY");
        let fromTime = `${prevDate} ${prevList?.timeList?.[prevList?.timeList?.length - 1]
          }`;

        let toTime = `${moment(o, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
          "DD-MM-YYYY"
        )} 11:59 PM`;

        let newTimeArray = getDoseAndTimeIntarval(
          item?.frequency,
          fromTime,
          toTime
        );
        let timeListData = newTimeArray?.timeList;
        timeListData?.shift();
        newData.push({
          date: o,
          timeList: timeListData,
        });
      }
    });
    return newData;
  };

  const getDoseAndTimeIntarval = (frequency, fromTime, toTime) => {
    let intarvalTime = 5;
    let intarvalType = "minutes";
    switch (frequency) {
      case "q5m":
        intarvalTime = 5;
        intarvalType = "minutes";
        break;
      case "q15m":
        intarvalTime = 15;
        intarvalType = "minutes";
        break;
      case "q30m":
        intarvalTime = 30;
        intarvalType = "minutes";
        break;
      case "q2h":
        intarvalTime = 2;
        intarvalType = "hours";
        break;
      case "q4h":
        intarvalTime = 4;
        intarvalType = "hours";
        break;
      case "q6h":
        intarvalTime = 6;
        intarvalType = "hours";
        break;
      case "q8h":
        intarvalTime = 8;
        intarvalType = "hours";
        break;
      case "q12h":
        intarvalTime = 12;
        intarvalType = "hours";
        break;
      case "q24h":
        intarvalTime = 24;
        intarvalType = "hours";
        break;
      case "daily":
        intarvalTime = 1;
        intarvalType = "days";
        break;
      case "daily at bedtime":
        intarvalTime = 24;
        intarvalType = "hours";
        break;
      case "BID":
        intarvalTime = 12;
        intarvalType = "hours";
        break;
      case "TID":
        intarvalTime = 8;
        intarvalType = "hours";
        break;
      case "QID":
        intarvalTime = 6;
        intarvalType = "hours";
        break;
      case "every other day":
        intarvalTime = 1;
        intarvalType = "days";
        break;
      case "once":
        intarvalTime = 1;
        intarvalType = "days";
        break;

      default:
        break;
    }
    const startTime = moment(fromTime, "DD-MM-YYYY hh:mm A");
    const endTime = moment(toTime, "DD-MM-YYYY hh:mm A");
    const minutesArray = [];
    for (
      let currentMoment = startTime.clone();
      currentMoment.isBefore(endTime);
      currentMoment.add(intarvalTime, intarvalType)
    ) {
      minutesArray.push(currentMoment.format("hh:mm A"));
    }
    return { timeList: minutesArray };
  };
  const getPercentComplete = () => {
    let totalDose = 0;
    getOrderDates()?.forEach((elm) => {
      const { timeList } = elm;
      totalDose = totalDose + timeList?.length;
    });

    const takenDoses = item?.medician_reports?.filter(
      (o) => o.plan_status === "taken"
    )?.length;
    let count = Math.ceil((takenDoses / totalDose) * 100);
    return count;
  };
  const getDateRange = (first_dose, duration_num, duration_unit) => {
    // Parse the start date
    const startDate = new Date(first_dose);

    // Calculate the end date based on duration_unit and duration_num
    const endDate = new Date(startDate);
    switch (duration_unit) {
      case "Days":
        endDate.setDate(startDate.getDate() + duration_num);
        break;
      case "Weeks":
        endDate.setDate(startDate.getDate() + 7 * duration_num);
        break;
      case "Months":
        endDate.setMonth(startDate.getMonth() + duration_num);
        break;
      case "Years":
        endDate.setFullYear(startDate.getFullYear() + duration_num);
        break;
      default:
        break;
    }

    return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
  };
  const handleSelectDose = async (
    type,
    data,
    iDate,
    iTime,
    noteValue,
    unknown_medication_report_id
  ) => {
    const response = await dispatch(
      updateMedicationReportStatus({
        order_id: data?._id,
        item: data?.item,
        created_by: userDetails?._id,
        pet_id: data?.pet_id,
        date: moment(iDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        time: iTime,
        plan_status: type,
        createdDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        note: noteValue,
        medication_report_id: unknown_medication_report_id,
      })
    );
    if (response?.status) {
      dispatch(getHomeMedicationOrders({ pet_id: item?.pet_id }, () => { }));
      setNoteValue("");
    } else {
      toast.error(response?.message, toasterConfig);
    }
  };
  const getDateTitle = (nDate) => {
    const fDate = nDate.format("DD-MM-YYYY");
    const today = moment().format("DD-MM-YYYY");
    const tomorrow = moment().add(1, "days").format("DD-MM-YYYY");
    const yesterday = moment().subtract(1, "days").format("DD-MM-YYYY");
    let prefix =
      fDate === today
        ? "Today"
        : fDate === yesterday
          ? "Yesterday"
          : fDate === tomorrow
            ? "Tomorrow"
            : nDate.format("dddd");
    return `${prefix}, ${nDate.format("MMM D YYYY")}`;
  };

  const handleStatusChange = (status, order_id, category, inventory_id) => {
    let body = { _id: order_id, status: status, category, item: inventory_id };
    if (status === "Completed") body = { ...body, complete_date: Date.now() };
    dispatch(
      updateOrder(body, (res) => {
        if (res?.status) {
          dispatch(getHomeMedicationOrders({ pet_id: pId }, () => { }));
        }
      })
    );
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="medication-plan-offcanvas"
        style={{ minWidth: "25%" }}
      >
        <Offcanvas.Header closeButton className="pb-0">
          <Offcanvas.Title>Medication Report</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-2 medication-plan-block">
          <div className="profile-cell d-inline-flex align-items-center">
            <div className="pic d-inline-flex rounded-circle overflow-hidden">
              <img
                className="img-fluid w-100 h-100"
                src="http://54.190.192.105:6181/photos/users/female-doctor.png"
                alt="User"
              />
            </div>
            <div className="txt">
              {item?.created_by?.title} {item?.created_by?.firstname}{" "}
              {item?.created_by?.lastname}
            </div>
            <div className="icon d-inline-flex">
              <span className="material-symbols-outlined">auto_mode</span>
            </div>
          </div>
          <div className="medication-plan-item bg-transparent">
            <div className="chart-blc d-flex align-items-center mb-1">
              <div className="chart-item">
                <CircularProgressbarWithChildren
                  styles={buildStyles({
                    rotation: 0.5,
                    pathColor: "#788C9F",
                    textColor: "#f88",
                    trailColor: "inherit",
                  })}
                  strokeWidth={10}
                  value={getPercentComplete()}
                >
                  <div
                    style={{
                      fontSize: 10,
                      fontWeight: "bold",
                      padding: "7px 0px",
                      backgroundColor: "#ececec",
                      borderRadius: 50,
                      textAlign: "center",
                      width: 35,
                    }}
                  >
                    {("  " + getPercentComplete()).slice(-3)}%
                  </div>
                </CircularProgressbarWithChildren>
              </div>

              <div className="medication-profilee">
                <h4>{item?.item_details?.product_name}</h4>
                <p>
                  {getDateRange(
                    item?.first_dose,
                    item?.duration_num,
                    item?.duration_unit
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex gap-3 align-items-center cmb-20">
            <div className="medication-status-container">
              <select
                disabled={!permissions?.edit_order_status}
                className={`status status-${item?.status}`}
                value={item?.status}
                onChange={(e) => {
                  permissions?.edit_orders &&
                    handleStatusChange(
                      e.target.value,
                      item?._id,
                      item?.category?.category,
                      item.item_details?._id
                    );
                }}
              >
                <option className={`status status-Ordered`}>Ordered</option>
                <option className={`status status-Active`}>Active</option>
                <option className={`status status-Stopped`}>Stopped</option>
                <option className={`status status-Completed`}>Completed</option>
              </select>
            </div>
          </div>

          <div className="d-flex justify-content-between profile-cell align-items-center">
            <div className="d-inline-flex align-items-center">
              <div className="pic d-inline-flex rounded-circle overflow-hidden">
                <img
                  className="img-fluid w-100 h-100"
                  src={item?.created_by?.user_image}
                  alt="User"
                />
                <UserPlaceholder />
              </div>
              <div className="txt">
                {item?.created_by?.title} {item?.created_by?.firstname}{" "}
                {item?.created_by?.lastname}
              </div>
            </div>
            <div className="icon d-inline-flex">
              <span className="material-symbols-outlined">auto_mode</span>
            </div>
          </div>
          <p className="p-medication-desc my-2">{item?.notes}</p>

          {getOrderDates()?.map((elem, i) => {
            const { date, timeList } = elem;

            const totalTaken = item?.medician_reports?.filter(
              (o) =>
                o.plan_status === "taken" &&
                o?.date === date.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
            );

            return (
              <div key={i}>
                <div className="fb-center">
                  <div className="text-16-500 color-2E65">
                    {getDateTitle(date)}
                  </div>
                  <div>{`${totalTaken?.length}/${timeList?.length}`}</div>
                </div>
                {timeList?.map((elem, index) => {
                  let isEditable = true;
                  const cDate = moment().format("DD-MM-YYYY");
                  const cTime = moment();
                  let nextElem = timeList?.[index + 1] || "11:59 PM";
                  if (cDate === date.format("DD-MM-YYYY")) {
                    const startTime = moment(elem, "hh:mm A");
                    const endTime = moment(nextElem, "hh:mm A");
                    if (cTime.isBetween(startTime, endTime)) {
                      isEditable = true;
                    }
                  }

                  const findObject = item?.medician_reports?.find(
                    (o) =>
                      o?.date === date.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") &&
                      o.time === elem
                  );

                  let isTaken = false;
                  let userFirstName, userLastName, createdDate, notesValue;
                  if (findObject?._id) {
                    isTaken = findObject?.plan_status === "taken";

                    isEditable = findObject?.isUnknown
                      ? !findObject?.plan_status
                      : false;

                    userFirstName = findObject?.added_by?.[0]?.firstname;
                    userLastName = findObject?.added_by?.[0]?.lastname;
                    createdDate = moment(findObject?.createdDate)
                      .utc()
                      .format("MMM DD YYYY hh:mm a");
                    notesValue = findObject?.note;
                  }
                  const isTimeVisible = [
                    "q5m",
                    "q15m",
                    "q30m",
                    "q2h",
                    "q4h",
                    "q6h",
                    "q8h",
                    "q12h",
                    "BID",
                    "TID",
                    "QID",
                  ].includes(item.frequency);
                  return (
                    <div className="medication-list-wrapper my-3" key={index}>
                      <div className={isTimeVisible ? "fb-center" : "d-none"}>
                        <div className="d-flex gap-2">
                          <h4>{elem} </h4>

                          <h5 className="color-5129 text-15-600">
                            {findObject?.isUnknown &&
                              !findObject?.plan_status &&
                              `System Marked Unknown at ${moment(
                                findObject?.unknownCreatedDate,
                                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                              ).format("MMM DD YYYY hh:mm a")}`}
                          </h5>
                        </div>
                        <p className="text-14-400 color-8693">{index + 1}</p>
                      </div>

                      {isEditable ? (
                        <div className="fb-center bg-eef2 br-10 cp-10 mt-2">
                          <div>
                            <div className="text-16-400 color-2E65">
                              {item?.item_details?.product_name} ({item?.dose}{" "}
                              {item?.form})
                            </div>
                            <div className="note-container cmt-5 gap-3">
                              Note:
                              <textarea
                                onChange={(e) => {
                                  setNoteValue(e.target.value);
                                }}
                                className="resize-y rounded-md outline outline-1 outline-[#0909541F] p-2"
                                rows="1"
                                cols="30"
                                id="medical-note"
                                name="medical-note"
                                placeholder="Add note (optional)"
                              />
                            </div>
                            <div className="fa-center gap-2 mt-2">
                              <div>
                                <BsCapsule color="#11CA9D" />
                              </div>
                              <div className="text-13-400 color-2E65">
                                {item?.frequency}{" "}
                                {item?.item_details?.product_name} ({item?.dose}{" "}
                                {item?.form})
                              </div>
                            </div>
                          </div>
                          <div className="fa-center gap-2">
                            <img
                              src={redCancel}
                              alt="cancel"
                              className="h-18 w-18 cursor-pointer"
                              onClick={() => {
                                handleSelectDose(
                                  "not taken",
                                  item,
                                  date,
                                  elem,
                                  noteValue,
                                  findObject?._id
                                );
                              }}
                            />
                            <img
                              src={greenCheck}
                              alt="check"
                              className="h-18 w-18 cursor-pointer"
                              onClick={() => {
                                handleSelectDose(
                                  "taken",
                                  item,
                                  date,
                                  elem,
                                  noteValue,
                                  findObject?._id
                                );
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <ul className="ps-4 outer-list-items position-relative">
                          <li className="list-outer position-relative">
                            <span className={isTaken ? "taken-steps mt-1" : "not-taken-steps mt-1"}>
                              {isTaken ? "Taken" : "Not Given"}
                            </span>
                            <ul className="child-listt mt-2">
                              <li className="d-flex align-items-center">
                                <BsCapsule color={isTaken ? "#11CA9D" : ""} />
                                <span className="ms-2">
                                  {!isTaken && "Not Given by "}
                                  {!isTaken && userFirstName && userFirstName}{" "}
                                  {!isTaken && userLastName && userLastName}{" "}
                                  {!isTaken &&
                                    userFirstName &&
                                    userLastName &&
                                    "on"}{" "}
                                  {!isTaken && createdDate}
                                  {/* {item?.frequency}{" "}
                                  {item?.item_details?.product_name} (
                                  {item?.dose} {item?.form}) */}
                                </span>
                              </li>
                            </ul>
                            <span className="taken-steps mt-1">
                              {isTaken ? "Given by " : ""}
                              {isTaken && userFirstName && userFirstName}{" "}
                              {isTaken && userLastName && userLastName}{" "}
                              {isTaken &&
                                userFirstName &&
                                userLastName &&
                                "on"}{" "}
                              {isTaken ? createdDate : ""}
                            </span>
                            {notesValue && (
                              <span className="taken-steps mt-1">
                                {`Note: ${notesValue ? titleCaseString(notesValue) : "-"
                                  }`}
                              </span>
                            )}
                          </li>
                        </ul>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </Offcanvas.Body>
      </Offcanvas>

      <div className="medication-plan-item" onClick={handleShow}>
        <div className="head">
          <h2 style={{ textTransform: "capitalize" }}>
            {item?.item_details?.product_name}
          </h2>
          <h3>
            {item && (
              <span>
                {getDateRange(
                  item?.first_dose,
                  item?.duration_num,
                  item?.duration_unit
                )}
              </span>
            )}
          </h3>
        </div>
        <div className="chart-blc d-flex align-items-center">
          <div className="chart-item">
            <CircularProgressbarWithChildren
              styles={buildStyles({
                rotation: 0.5,
                pathColor: "#E0CE2A",
                textColor: "#f88",
                trailColor: "inherit",
              })}
              strokeWidth={10}
              value={getPercentComplete()}
            >
              <div
                style={{
                  width: 35,
                  padding: "7px 0px",
                  backgroundColor: "#ececec",
                  borderRadius: 50,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {("  " + getPercentComplete()).slice(-3)}%
                </div>
              </div>
            </CircularProgressbarWithChildren>
          </div>
          Active
        </div>
        <div className="treat-by-cell d-inline-flex align-items-center">
          <div className="pic d-inline-flex rounded-circle overflow-hidden flex-shrink-0">
            <img
              className="img-fluid w-100 h-100"
              src={item?.created_by?.user_image}
              alt="User"
            />
            <UserPlaceholder />
          </div>
          <div className="txt">
            {item?.created_by?.firstname} {item?.created_by?.lastname}
          </div>
          <div className="icon d-inline-flex">
            <span className="material-symbols-outlined">auto_mode</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default MedicationPlanItem;
