import React from "react";
import StaffOverviewHead from "./StaffOverviewHead";
import StaffProfileSummary from "./StaffProfileSummary";
import StaffprofileEducation from "./StaffprofileEducation";
import './index.scss';
import StaffProfileExperience from "./StaffProfileExperience";
import StaffprofileDocument from "./StaffprofileDocument";

const StaffProfileOverview = ({ staffMemberDetails }) => {
	return (
		<>
			<div className="staff-profile-overview-blc">
				<div className="row-block">
					<StaffOverviewHead className="responsive" staffMemberDetails={staffMemberDetails} />
				</div>
				<div className="row-block">
					<StaffProfileSummary staffMemberDetails={staffMemberDetails} />
				</div>
				<div className="row-block">
					<StaffprofileEducation educationDetails={staffMemberDetails?.user_educations} />
				</div>
				<div className="row-block">
					<StaffProfileExperience experienceDetails={staffMemberDetails?.user_experiences} />
				</div>
				<div className="row-block">
					<StaffprofileDocument documentDetails={staffMemberDetails?.user_documents} />
				</div>
			</div>
		</>
	);
};

export default StaffProfileOverview;
