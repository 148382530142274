import React, { useEffect, useState } from "react";
import { Button, Collapse, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import "./index.scss";
import ReportsBlock from "../../Overview/ReportsBlock";

const Accordions = ({ data,  openPanel, isHideAdd,petId,currentDate }) => {

  const [loadIndex, setLoadIndex] = useState(0);
  const [elemIndex, setElemIndex] = useState(null);
  const [toggle, setToggle] = useState(false);

  const dispatch = useDispatch();

  const toggleFunction = (index) => {
    if (loadIndex === index) {
      setLoadIndex(null);
    } else if (elemIndex === index) {
      setToggle(!toggle);
    } else {
      setToggle(true);
    }
    setElemIndex(index);
    setLoadIndex(null);
  };

  const data_from_date = (data_list, date) => {
    for (let item of data_list) {
      if (item?.date == date) {
        return item;
      }
    }
    return null;
  };

  return (
    <>
      <div className="diagnostic-accordion-wrapper">
        {data?.map((item, i) => {
          let dates = new Set()
          item?.tests?.map((test, i) => {test?.results?.map((result, j) => {dates.add(result?.date)})})
          dates = Array.from(dates);
          return (
              <div className="accordion-blc d-flex flex-column">
                <div className="accordion-btn-wrapper d-flex align-items-center">
                  <Button
                    color="link"
                    className={`accordion-btn text-start shadow-none text-left d-flex align-items-center ${
                      loadIndex === i || (i === elemIndex && toggle)
                        ? "active"
                        : ""
                    }`}
                    onClick={() => toggleFunction(i)}
                  >
                    <span className="material-icons icon-btn">
                      {loadIndex === i || (i === elemIndex && toggle)
                        ? "expand_less"
                        : "expand_more"}
                    </span>
                    <label className="font-bold text-[18px]">
                      {item?.category?.category}
                    </label>
                  </Button>
                  {!isHideAdd && (
                    <Button
                      onClick={openPanel}
                      color="link"
                      className="ms-auto add-btn d-inline-flex"
                    >
                      <span className="material-icons">add_circle</span>
                    </Button>
                  )}
                </div>

                <Collapse
                  isOpen={loadIndex === i || (i === elemIndex && toggle)}
                >
                  <div className="collapse-data-blc accordtion-table">
                    <Table responsive striped id={`list-table-${i}`}>
                      <thead>
                        <tr>
                          <th>Test Name</th>
                          <th className="">Range</th>
                          {dates?.map((date, p) => {
                            date = moment(date);
                            return (
                              <th key={p}>
                                {`${date?.format("MM/DD/YYYY")}`}
                                <br />
                                {`${date?.format("hh:mm A")}`}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>

                      <tbody>
                        {item?.tests?.map((test, j) => {
                          return (
                            <>
                              <tr key={j}>
                                <td>{test?.test?.test_name}</td>
                                {test?.results?.[test?.results?.length - 1]?.referenceRange  && test?.results?.[test?.results?.length - 1]?.referenceRange !== "" ? (
                                  <td  style={{whiteSpace: 'nowrap'}} >{test?.results?.[test?.results?.length - 1]?.referenceRange}</td>
                                ) : (
                                  <td>--</td>
                                )}
                                {dates.map((date, d) => {
                                  let result = data_from_date(
                                    test?.results,
                                    date
                                  );

                                  return result !== null ? (
                                    <td className={result?.outOfRange ? result?.outOfRangeFlag?.includes('L') ? `text-primary`: 'text-danger' : '' }>
                                    {result?.result} {result?.outOfRangeFlag || ''}
                                    </td>
                                  ) : (
                                    <td key={d}>--</td>
                                  );
                                })}
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </Collapse>
              </div>
            
          );
        })}
      </div>
      {/* <div className="row-block">
                        <ReportsBlock petId={petId} currentDate={currentDate} />
                      </div> */}
    </>
  );
};
export default Accordions;
