import React, { useState } from "react";
import { Button, Collapse, Table, Input } from "reactstrap";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import EditOrderDetails from "./EditOrderDetails";
import { updateOrder, getOrdersWithCategoryByPetId } from "../../../../../../context/actions/patient-module/orders";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getReqForm } from "../../../../../../context/actions/patient-module/diagnostic-testing";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFile} from "@fortawesome/free-regular-svg-icons";


import moment from "moment";
import { faPlus } from "@fortawesome/free-solid-svg-icons";


const Accordions = ({ data }) => {
	const { permissionsData } = useSelector((state) => state.clinicPermissionReducer);
	const permissions = permissionsData;
	const [loadIndex, setLoadIndex] = useState(0);
	const [elemIndex, setElemIndex] = useState(null);
const [orderPanelState, setOrderPanelState] = useState(false);
	const [selected, setSelected] = useState(false)
	const [showNewVal, setShowNewVal] = useState(false);
	const [toggle, setToggle] = useState(false);
	const params = useParams();
	const petId = params.petId;
	const dispatch = useDispatch()
	const toggleFunction = (index) => {
		if (loadIndex === index) {
			setLoadIndex(null);
		} else if (elemIndex === index) {
			setToggle(!toggle);
		} else {
			setToggle(true);
		}
		setElemIndex(index);
		setLoadIndex(null);
	}
	const handleShowReqForm = (id) => {

		dispatch(getReqForm(id, (response) => {
			if(response?.status && response?.response_data?.pdfURL){

				dispatch({
					type: "SET_FILE_VIEWER_DATA",
					data: {
					  title: 'Idexx Requisition',
					  fileURL: response?.response_data?.pdfURL,
					},
				  });

			}
		}))

	}


	const handleEditDiagnostic  = (id) => {

		dispatch(getReqForm(id, (response) => {
			if(response?.status && response?.response_data?.uiURL){


				dispatch({
					type: "SET_LAB_ORDER_VIEWER_DATA",
					data: {
					  pet: petId,
					  urls: [{url: response?.response_data?.uiURL}],
					  index: 0
					},
				  });

				// dispatch({
				// 	type: "SET_FILE_VIEWER_DATA",
				// 	data: {
				// 	  title: 'Idexx Order',
				// 	  fileURL: response?.response_data?.uiURL,
				// 	},
				//   });

			}
		}))

	}

	const handleStatusChange = (status, order_id, category, inventory_id) => {
		console.log('inventory_id: ', inventory_id);
		let body = {_id: order_id, status: status,category, item: inventory_id};
		if(status === 'Completed')
			body = {...body, complete_date: Date.now() }
		dispatch(updateOrder(body, (res) => {
			if(res?.status) {
				dispatch(getOrdersWithCategoryByPetId(petId, () => {}))
			}
		}))
	}

const closePanel = () => {
		setSelected(false)
		setOrderPanelState(false)
	} 


	const handleSelect = (order) => {
		setSelected(order);
		setOrderPanelState(true)
	}


	return (
		<>
			<div className="orders-accordion-wrapper">
				{ data?.map((item, i) => { //Object.keys(tempData).map((key, i ) => { 
				return (
				<div className="accordion-blc d-flex flex-column">
					<div className="accordion-btn-wrapper d-flex align-items-center justify-content-between pe-2">
						<Button color="link" className={`accordion-btn text-start shadow-none text-left d-flex align-items-center  active`} >
							{/*<span className="material-icons icon-btn">
								{(loadIndex === i) || (i === elemIndex && toggle) ? 'expand_less' : 'expand_more'}
								</span>*/}
							<label>{item?.category?.category}</label>
						</Button>
					</div>
					<div>
						<div className="collapse-data-blc">
							<Table borderless responsive striped>
							
								<thead>
									<tr>
										<th width="30%" >
											Product Name
										</th>
										<th width="10%">
											Status
										</th>
										<th width="20%">
											{item?.category?.category === 'Diagnostic Test' ? 'Placed' : 'Start'} Date & Time
										</th>
										<th>
											Notes
										</th>
									</tr>
								</thead>
								<tbody>
									{item?.orders?.map((order, j) => {
										return (
										<>
											<tr key={j}>
												<td width="30%" >{order?.item_details?.product_name || order?.service_details?.service_name || order?.test_details?.test_name}</td>
												<td width="10%">
													<select disabled={!permissions?.edit_order_status || order?.test_details} className={`status status-${order?.status}`} value={order?.status} onChange={(e) => {permissions?.edit_orders && handleStatusChange(e.target.value, order?._id ,item?.category?.category, order.item_details?._id)}} >
														<option className={`status status-Ordered`}>Ordered</option>
														<option className={`status status-Cancelled`}>Cancelled</option>
														<option className={`status status-Active`}>Active</option>
														<option className={`status status-Stopped`}>Stopped</option>
														<option className={`status status-Completed`}>Completed</option>
													</select>
												</td>
												<td width="20%">{moment(order?.first_dose).format('MM/DD/YYYY [at] hh:mm a')}</td>
													<td>{order?.notes ? order?.notes : '--'}</td>
													
													<td style={{ textAlign: 'right' }} className="action-cell">
														{order?.idexx_code && !order?.test_details?.inHouse &&  <Button color="link"className="edit-btn" onClick={() => {handleShowReqForm(order?.idexx_code)}}>
														<FontAwesomeIcon icon={faFile} /></Button>}

														{order?.idexx_code &&  order?.status === 'Ordered' &&  <Button color="link"className="edit-btn" onClick={() => {handleEditDiagnostic(order?.idexx_code)}}>
														<FontAwesomeIcon icon={faPlus} /></Button>}
														{
															permissions?.edit_orders && (order?.idexx_code ? (order?.status === 'Ordered' && order?.can_be_modified) : !order?.antechClinicAccession) &&  <Button onClick={() => {
																 handleSelect({...order, category: item?.category})
																}} color="link" className="edit-btn">
															<span className="icomoon-pencil"></span>
														</Button>
														}
													
												</td>
											</tr>
										</>
										)
									})}
								</tbody>
								
							</Table>
						</div>
					</div>
				</div> )})}
				
			</div>

			{orderPanelState &&

<>



			<div
				onClick={closePanel} className={`new-order-panel-overlay position-fixed ${orderPanelState ? 'active' : ''}`}></div>
			<EditOrderDetails closePanel={closePanel} order={selected} className={`${orderPanelState ? 'active' : ''}`} />


</>} 
		</>
	);
}

export default Accordions;