import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";

toast.configure();

export const getClinicSpeciesByClinicId = (params, callback) => {
    return (dispatch, getState) => {
      const {
        user: { token },
        clinicReducer: { clinic_details },
      } = getState();
      if (clinic_details) {
        ApiClient.get(
          `${apiUrl}${PORT}/clinic-species/get-all-paginate/${clinic_details?._id}`,
          params,
          token,
          dispatch, getState
        ).then((response) => {
          if (!response) {
            return callback();
          } else if (response && response?.status) {
        
            return callback(response?.response_data);
          } else {
            return callback(false);
          }
        });
      }
      else {
        return callback(null);
      }
    };
  };


  export const getAllClinicSpeciesByClinicId = (params, callback) => {
    return (dispatch, getState) => {
      const {
        user: { token },
        clinicReducer: { clinic_details },
      } = getState();
      if (clinic_details) {
        ApiClient.get(
          `${apiUrl}${PORT}/clinic-species/get-all/${clinic_details?._id }`,
          params,
          token,
          dispatch, getState
        ).then((response) => {
          if (!response) {
            return callback();
          } else if (response && response?.status) {
              dispatch({
                type: "GET_ALL_CLINIC_SPECIES",
                payload: response?.response_data,
            });
        
            return callback(response?.response_data);
          } else {
            return callback(false);
          }
        });
      }
      else {
        return callback(null);
      }
    };
  };


export const updateClinicSpecies = (data, callback) => {
    return (dispatch, getState) => {
      const {
        user: { token },
        clinicReducer: { clinic_details },
      } = getState();
      if (clinic_details) {
        ApiClient.put(
          `${apiUrl}${PORT}/clinic-species/update`,
          data,
          token,
          dispatch, getState
        ).then((response) => {
          if (!response) {
            return callback();
          } else if (response && response?.status) {
            dispatch(getAllClinicSpeciesByClinicId({}, () => {}))
            return callback(response?.response_data);
          } else {
            return callback(false);
          }
        });
      }
      else {
        return callback(null);
      }
    };
  };