import React from "react";
import { Button } from "reactstrap";
import "./index.scss";

const PlanBlock = (
	{
		className = '',
		planType = '',
		planPrice = '',
		planName = '',
		onSelect = () => { }
	}
) => {

	return (
		<>
			<div className={`plan-block d-flex flex-column flex-fill ${className}`}>
				<div className="head">
					<div className="plan-type-box d-flex align-items-center">
						<div className="pic">
							{
								planType === "super" && <img className="img-fluid" src={require('../../assets/img/vetRecorEMR/pet-badge-purple-shadow.svg')} alt="Badge" />
							}
							{
								planType === "golden" && <img className="img-fluid" src={require('../../assets/img/vetRecorEMR/pet-badge-icon-yellow-shadow.svg')} alt="Badge" />
							}
							{
								planType === "primary" && <img className="img-fluid" src={require('../../assets/img/vetRecorEMR/pet-badge-icon-green-shadow.svg')} alt="Badge" />
							}
						</div>
						<div className="txt">
							<h2>{planName}</h2>
							<h3>{planPrice}</h3>
						</div>
					</div>
				</div>
				<div className="inner plan-description">
					<h4>Services</h4>
					<ul className="list-unstyled">
						<li className="d-flex">
							<span className="icon">
								<img className="img-fluid" src={require('../../assets/img/vetRecorEMR/check-icon.svg')} alt="Check Icon" />
							</span>
							[Servcie namehere]
						</li>
						<li className="d-flex">
							<span className="icon">
								<img className="img-fluid" src={require('../../assets/img/vetRecorEMR/check-icon.svg')} alt="Check Icon" />
							</span>
							[Servcie name]
						</li>
						<li className="d-flex">
							<span className="icon">
								<img className="img-fluid" src={require('../../assets/img/vetRecorEMR/check-icon.svg')} alt="Check Icon" />
							</span>
							[Servcie name]
						</li>
						<li className="d-flex disable">
							<span className="icon">
								<img className="img-fluid" src={require('../../assets/img/vetRecorEMR/error-icon.svg')} alt="Check Icon" />
							</span>
							[Servcie name]
						</li>
						<li className="d-flex disable">
							<span className="icon">
								<img className="img-fluid" src={require('../../assets/img/vetRecorEMR/error-icon.svg')} alt="Check Icon" />
							</span>
							[Servcie name]
						</li>
						<li className="d-flex disable">
							<span className="icon">
								<img className="img-fluid" src={require('../../assets/img/vetRecorEMR/error-icon.svg')} alt="Check Icon" />
							</span>
							[Servcie name]
						</li>
						<li className="d-flex disable">
							<span className="icon">
								<img className="img-fluid" src={require('../../assets/img/vetRecorEMR/error-icon.svg')} alt="Check Icon" />
							</span>
							[Servcie name]
						</li>
					</ul>
				</div>
				<div className="foo-blc mt-auto">
					<Button onClick={onSelect} color="link" className="btn-outline-light w-100">Select Plan</Button>
				</div>
			</div>
		</>
	);
};

export default PlanBlock;
