import isEmail from "validator/lib/isEmail";
import moment from "moment";
import crypto from "crypto";
const algorithm = "aes-256-cbc";
const secret = "MyInpleo";

export const validateEmail = (email) => {
  return isEmail(email, {
    allow_display_name: false,
    require_display_name: false,
    allow_utf8_local_part: true,
    require_tld: true,
    allow_ip_domain: false,
    domain_specific_validation: false,
    blacklisted_chars: "",
  });
};

export const isEmpty = (obj) => Object.keys(obj).length === 0;

/**
 * @description function call to capital first letter each word
 * @param {String} item string
 * @returns string
 */
export const capitalizeFirstLetter = (item) =>
  item
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/(?: |\b)(\w)/g, function (key, p1) {
      return key.toUpperCase();
    });
export const removeUnderscore = (item) => item.replace(/_/g, " ");

/**
 * @description method call for check overlap rectanges, x- axis, y- yxis, h height, w width
 * @param {*} r1 rectange xmlbounds
 * @param {*} r2 rectange jsonbounds
 * @returns boolean
 */
export const intersectRect = (r1, r2) => {
  return (
    r2.x + r2.w > r1.x &&
    r2.x < r1.x + r1.w &&
    r2.y + r2.h > r1.y &&
    r2.y < r1.y + r1.h
  );
};

/**
 * @description function use to change date format
 * @param {Date} date date string
 * @param {String} format accept date format
 */
export const dateFormat = (date, format = "MMMM Do YYYY, h:mm a") =>
  moment(date).format(format);

// r2.x > r1.x && r2.x < (r1.x + r1.w)
/**
 * @description method call to identify current rectange position exists
 * @param {*} rect rectangle
 * @param {*} x
 * @param {*} y
 * @param {*} k scale
 * @returns
 */
export const hit = (rect, x, y, k) =>
  x >= rect.x && x <= rect.x + rect.w && y >= rect.y && y <= rect.y + rect.h;

/**
 * @description function use to find color bounds based on confidence vslued
 * if 0.9 less then yellow, 0.9 graterthen green, else red
 * @param {*} confidence  acceppting nimeric value
 * @returns  return string
 */
export const getBoundsColour = (confidence) => {
  if (confidence > 0.9) {
    return "green";
  } else if (confidence > 0.8) {
    return "yellow";
  } else {
    return "red";
  }
};

/**
 * @description function use to load img elements
 * @param {*} src image path
 * @param {*} height image height
 * @param {*} width image width
 * @returns instance
 */
export const addImageProcess = (src, height = 871, width = 673) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () =>
      resolve({ img: img, height: img.height, width: img.width });
    img.onerror = reject;
    img.height = height;
    img.width = width;
    img.src = src;
  });
};

export const addGaps = (str) =>
  str
    .replace(/\s?/g, "")
    .replace(/(\d{4})/g, "$1 ")
    .trim();

export const addSlash = (expdate) =>
  expdate.replace(/\//g, "").substring(0, 2) +
  (expdate.length > 2 ? "/" : "") +
  expdate.replace(/\//g, "").substring(2, 4);
/**
 * Encrypts text
 * @param {string} text - text to encrypt
 */
export const encrypt = (text) => {
  const cipher = crypto.createCipher(algorithm, secret);
  let crypted = cipher.update(text, "utf8", "hex");
  crypted += cipher.final("hex");
  return crypted;
};

export const getUTCDate = (dateString) => {
  const date = new Date(dateString);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

export const getDefaultScale = (val) => {
  if (val < 800) {
    return 1;
  } else if (val < 1100) {
    return 0.8;
  } else if (val < 1500) {
    return 1.1;
  } else if (val < 1700) {
    return 1.3;
  } else if (val < 1800) {
    return 1.5;
  } else if (val < 2000) {
    return 2.0;
  } else if (val < 2500) {
    return 2.5;
  } else if (val < 3000) {
    return 3.0;
  } else {
    return 4.0;
  }
};

export const convertToCSV = (objArray) => {
  var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  var str = "";
  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (line !== "") line += ",";

      line += `"${(array[i][index])}"`;
    }

    str += line + "\r\n";
  }
  return str;
};

export const exportCSVFile = (headers, items, fileTitle) => {
  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(items);
  var csv = convertToCSV(jsonObject);
  var exportedFilenmae = fileTitle + ".csv" || "export.csv";

  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const camelCase = (str) => {
  return (str.slice(0, 1).toUpperCase() + str.slice(1))
    .replace(/([-_ ]){1,}/g, " ")
    .split(/[-_ ]/)
    .reduce((cur, acc) => {
      return (
        cur + " " + acc &&
        acc[0] &&
        acc[0].toUpperCase() + acc &&
        acc.substring(1)
      );
    });
};

export const fileTypeFocDocs = (fileType) => {
  if (
    fileType === "image/png" ||
    fileType === "image/jpg" ||
    fileType === "image/jpeg" ||
    fileType === "application/pdf" ||
    fileType === "application/vnd.ms-excel" ||
    fileType ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
    fileType === "text/csv" ||
    fileType === "application/msword" ||
    fileType ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkFileType = (fileType) => {
  if (
    fileType === "image/png" ||
    fileType === "image/jpg" ||
    fileType === "image/jpeg"
  ) {
    return "image";
  } else if (fileType === "application/pdf") {
    return "pdf";
  } else {
    return "doc";
  }
};

// export const cardImgs = (cardType) => {
//   let imageUrl;
//   switch (cardType) {
//     case "visa":
//       imageUrl = require(`../../../assets/img/payment/card-logo-visa.svg`);
//       break;
//     case "mastercard":
//       imageUrl =  require(`../../../assets/img/payment/card-logo-mastercard.svg`);
//       break;
//     case "american-express":
//       imageUrl = require(`../../../assets/img/payment/card-logo-amex.svg`);
//       break;
//     default:
//       imageUrl = require(`../../../assets/img/payment/card-logo-unknown.svg`);
//   }
//   return imageUrl;
// }

export const getTimeMeridiem = (time) => {
  return time > "12" ? time + " PM" : time + " AM";
};

/**
 *
 * @param {Date} dateTime
 * @param {String} format
 * @returns
 */
export const timeFromDateTime = (dateTime, format = "HH:mm") => {
  switch (format) {
    case "HH:mm":
      return moment(dateTime).format("HH:mm");

    case "hh:mm a":
      return moment(dateTime).format("hh:mm a");

    case "hh:mm:ss A":
      return moment(dateTime).format("hh:mm:ss A");

    case "hh:mm A":
      return moment(dateTime).format("hh:mm A");

    default:
      return "Invalid DateTime!";
  }
};

/**
 *
 * @param {Object} dateTime
 * @param {Fuction} format
 * @returns
 */
export const dateFromDateTime = (dateTime, format = "yyyy-MM-DD") => {
  switch (format) {
    case "yyyy-MM-DD":
      return moment(dateTime).format("yyyy-MM-DD");

    case "DD-MM-yyyy":
      return moment(dateTime).format("DD-MM-yyyy");

    case "MM-DD-yyyy":
      return moment(dateTime).format("MM-DD-yyyy");

    case "yyyy-DD-MM":
      return moment(dateTime).format("yyyy-DD-MM");

    case "MM/DD/yyyy":
      return moment(dateTime).format("MM/DD/yyyy");

    default:
      return "Invalid DateTime!";
  }
};

/**
 *
 * @param {Object} dateTime
 * @param {Fuction} format
 * @returns
 */
export const dateTimeFormat = (
  dateTime = new Date(),
  format = "mm/DD/yyyy"
) => {
  return moment(dateTime).format(format);
};

/**
 * defference between two dates
 * @param {Date} date
 * @returns
 */
export const dateDiffFromNow = (date = new Date().toISOString()) => {
  var startDate = moment(new Date().toISOString(), "YYYY-MM-DD");
  var endDate = moment(date, "YYYY-MM-DD");
  var result = moment.duration(endDate.diff(startDate)).asDays();
  if (result > 0) {
    return { status: "IN", days: result };
  } else {
    return { status: "OUT", days: result * -1 };
  }
};

/**
 * defference between two dates and return in out days
 * @param {Date} date
 * @returns
 */
export const dueDaysFromDate = (date = new Date().toISOString()) => {
  var startDate = moment(new Date().toISOString(), "YYYY-MM-DD"); //.startOf('day');
  var endDate = moment(date, "YYYY-MM-DD"); //.startOf('day');
  //var result = moment.duration(endDate.diff(startDate)).asDays();
  var result = endDate.diff(startDate, 'days');


  if (result > 0) {
    return "Due in " + moment(endDate).fromNow(true)
  } else {
    return "Overdue " + moment(endDate).fromNow(true)
  }
};

/**
 * defference between two dates
 * @param {Date} date
 * @returns
 */
export const dobInYearMonthDays = (date = new Date().toISOString()) => {
  let a = moment(new Date().toISOString(), "YYYY-MM-DD");
  let b = moment(date, "YYYY-MM-DD");

  let years = a.diff(b, "year");
  b.add(years, "years");

  let months = a.diff(b, "months");
  b.add(months, "months");

  let days = a.diff(b, 'days');
  return `${years > 0 ? years.toString() + ' years, ' : ''} ${months > 0 ? months.toString() + ' months,' : ''} ${days > 0 ? days.toString() + ' days' : ''}`
}


/**
 * phone number format
 * @param {String} number
 * @returns {String}
 */

export const formatPhoneNumber = (number) => {

  if (number) {
    const formatNum = number.substring(0, 2) + ' (' + number.substring(2, 5) + ')' + ' ' +
      number.substring(5, 8) + '-' +
      number.substring(8, 12);
    return formatNum
  }
  else {
    return ''
  }



}


// titlecase string
export const titleCaseString = (value) => {
  if (typeof value !== "string") return "";
  return value.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase()); // Capital first character of each word
};

//appointment data filter
export const appointmentDates = [
  {
    value: "24 hours", id: "last_24_hours",
  },
  {
    value: "Last week", id: "last_week",
  },
  {
    value: "last month", id: "last_month",
  },
  {
    value: "Last quater", id: "last_quarter",
  },
  {
    value: "Last year", id: "last_year",
  },
]

//patient type
export const patinetDates = [
  {
    value: "Retained Patients", id: "past_year",
  },
  {
    value: "Latest Patients", id: "current_year",
  },

]

//appointment type
export const appType = [
  { value: "in-clinic" },
  { value: "phone-call" },
  { value: "surgery" },
  { value: "video-call" },
  { value: "appointment-block" },
]

// appointment status
export const appointmentStatus = [
  { value: "confirmed" },
  { value: "waiting room" },
  { value: "er" },
  { value: "or" },
  { value: "boarding" },
  { value: "complete" },
  { value: "rescheduled" },
]

//patient late by
export const lateBy = [
  { value: "10" },
  { value: "20" },
  { value: "30" },
  { value: "40" },
  { value: "50" },
  { value: "60" },
]

//by age
export const ageList = [
  { value: "0 - 5", id: 5 },
  { value: "6 - 10", id: 10 },
  { value: "11 - 15", id: 15 },
  { value: "16 - 20", id: 20 },
  { value: "21 - 25", id: 25 },
  { value: "26 - 30", id: 30 },

]

//refferal management all range
export const allRange = [

  {
    value: "Last Week", id: "last_week",
  },
  {
    value: "Last Month", id: "last_month",
  },
  {
    value: "Last Quarter", id: "last_quarter",
  },
  {
    value: "Last Year", id: "last_year",
  },
]

//refferal status 
export const refferalByStatus = [
  { value: "Approved" },
  { value: "Pending" },
  { value: "Declined" },
]

//month year list
export const getMonthList = (num) => {
  return [...Array(num)].map((_, i) => {
    return { id: moment().subtract(i, "month").format("MMM YYYY") };
  });
};

//months
export const monthsList = [
  { value: "Jan", id: 1 },
  { value: "Feb", id: 2 },
  { value: "Mar", id: 3 },
  { value: "Apr", id: 4 },
  { value: "May", id: 5 },
  { value: "Jun", id: 6 },
  { value: "Jul", id: 7 },
  { value: "Aug", id: 8 },
  { value: "Sep", id: 9 },
  { value: "Oct", id: 10 },
  { value: "Nov", id: 11 },
  { value: "Dec", id: 12 },
]

//curreny formate
export let USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
})
 

export function isShallowEqual(objA, objB) {
  if (objA === objB) {
    return true;
  }

  if (!objA || !objB) {
    return false;
  }

  const aKeys = Object.keys(objA);
  const bKeys = Object.keys(objB);
  const len = aKeys.length;

  if (bKeys.length !== len) {
    return false;
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    const key = aKeys[i];

    if (objA[key] !== objB[key] || !Object.hasOwn(objB, key)) {
      return false;
    }
  }

  return true;
}
export function isArrayShallowEqual(arrayA, arrayB) {
  const len = arrayA.length;
  if (arrayB.length !== len) {
    return false;
  }
  for (let i = 0; i < len; i++) {
    if (!isShallowEqual(arrayA[i], arrayB[i])) {
      return false;
    }
  }
  return true;
}
