import React from "react";
import { Button } from "reactstrap";
import "./index.scss";
import dummyImg from "../../../src/assets/img/vetRecorEMR/user-thumbnail-sm.png";

const UserThumbnailListBlock = ({ staffImg }) => {
  return (
    <>
      <div className="user-thumbnail-list-blc">
        <h3>Staff Members & Doctors Working </h3>
        <div className="list-row d-flex align-items-center">
          <div className="btns d-flex align-items-center overflow-auto">
            {staffImg?.slice(0, 10)?.map((item, i) => {
              return (
                <Button
                  key={i}
                  color="link"
                  className="p-0 rounded-circle overflow-hidden"
                >
                  <img
                    className="img-fluid"
                    src={`${item ? item : dummyImg}`}
                    alt=""
                  />
                </Button>
              );
            })}
          </div>
          <Button color="link" className="more-btn">
            {staffImg?.length > 10 ? <>+{staffImg?.length - 10} More</> : ""}
          </Button>
        </div>
      </div>
    </>
  );
};

export default UserThumbnailListBlock;
