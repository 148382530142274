import React, { useState } from "react";
import ProfileHead from "./ProfileHead";
import ProfileDescription from './ProfileDescription';
import ProfileChart from './ProfileChart';
//import ProfileTags from './ProfileTags';
import ProfileAllergy from "shared/PatientProfile/ProfileAllergy";
import ProfileTags from "shared/PatientProfile/ProfileTags";
//import ProfileAllergy from '../../ ./ProfileAllergy';
import ProfileContact from './ProfileContact';
import ProfileVaccination from './ProfileVaccination';
import { useSelector } from "react-redux";
import ProfilePlan from './ProfilePlan';
import "./index.scss";
import HealthSnapshotOffcanvas from "shared/PatientProfile/HealthSnapshotOffcanvas";

const PatientProfile = ({ petId }) => {
	const [isHealthSnapShot, setIsHealthSnapShot] = useState(false);

	const handleShow = () => setIsHealthSnapShot(true);
	const handleClos = () => setIsHealthSnapShot(false);
	const { permissionsData } = useSelector(
		(state) => state.clinicPermissionReducer
	  );
	  const permissions = permissionsData;	
	return (
		<>
			
			<HealthSnapshotOffcanvas
        show={isHealthSnapShot}
        close={handleClos}
        pId={petId?._id}
      />
			<div className="patient-profile d-flex flex-column overflow-auto">
				<ProfileHead showHealthRightCanvas={handleShow}/>
				{permissions?.view_patients_details && <div className="inner overflow-auto">
					<ProfileDescription />
					<ProfileChart />
					<ProfileTags />

					
					<ProfileAllergy />
					<ProfileContact />
					
					<ProfileVaccination />
					{/*petDetails?.wellness_plan_id  &&
					<ProfilePlan />*/}
				</div>}
			</div>
		</>
	);
};

export default PatientProfile;
