import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import moment from "moment";
import SlotItemTime from "../SlotItemTime";
import { MdOutlinePets } from "react-icons/md";
import UserPlaceholder from "shared/UserPlaceholder";
import "./index.scss";
import {
  setAppointmentDetailData,
  setAppointmentDetailPanel,
} from "context/actions/appointmentDetailPanelAction";
import { getPetDetailsById } from "context/actions/patient-module/patient/pet";
import { useClinicSpecies } from "hooks/useClinicSpecies";
import { useClinicBreed } from "hooks/useClinicBreed";

const SlotTimeFrame = ({ className = "", addSlot, data, item, prevData }) => {
  const clinicSpecies = useClinicSpecies()
  const clinicBreed = useClinicBreed()
  const dispatch = useDispatch();
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );

  const appointmentDetailsState = useSelector(
    (state) => state.appointmentDetailPanelReducer.isAppointmentDetailPanel
  );

  const showAppointmentDetails = (appointment) => {
    dispatch(setAppointmentDetailData(appointment));
    dispatch(getPetDetailsById(appointment?.patient_id?._id), {}, () => {});
    dispatch(setAppointmentDetailPanel(!appointmentDetailsState));
  };
  // const [appointmentGroupByTime, setAppointmentGroupByTime] = useState({});

  // useEffect(() => {
  //   const tempArr = {};
  //   data?.forEach((element) => {
  //     element?.appointment_timings?.forEach((appT, i) => {
  //       if (tempArr.hasOwnProperty(appT?.slot_time)) {
  //         const tempData = { ...element, appointment_timings: [appT] };
  //         tempArr?.[appT?.slot_time]?.push(tempData);
  //       } else {
  //         const tempData = { ...element, appointment_timings: [appT] };
  //         tempArr[appT?.slot_time] = [tempData];
  //       }
  //     });
  //   });
  //   const timeArray = Object.keys(tempArr);

  //   // Sort the array of time strings
  //   timeArray.sort((a, b) => {
  //     // Convert the time strings to Date objects for comparison
  //     const timeA = new Date(`2000-01-01 ${a}`);
  //     const timeB = new Date(`2000-01-01 ${b}`);

  //     // Compare the Date objects
  //     return timeA - timeB;
  //   });

  //   // Create a new object with sorted keys
  //   const sortedAppointments = {};
  //   timeArray.forEach((time) => {
  //     sortedAppointments[time] = tempArr[time];
  //   });

  //   // Now sortedAppointments contains the appointments object with keys sorted by time
  //   setAppointmentGroupByTime((prev) => sortedAppointments);
  // }, [data]);

  // const renderAPPointmentData = (combineAppointment, i) => {
  //   return (
  //     <>
  //       {combineAppointment?.map((appointment, j) => {
  //         return (
  //           <Fragment key={j}>
  //             {Array.isArray(appointment?.appointment_timings)
  //               ? appointment?.appointment_timings
  //                   .sort(
  //                     (a, b) =>
  //                       moment(
  //                         `1970-01-01 ${a?.slot_time}`,
  //                         `YYYY-MM-DD hh:mm a`
  //                       ).valueOf() -
  //                       moment(
  //                         `1970-01-01 ${b?.slot_time}`,
  //                         "YYYY-MM-DD hh:mm a"
  //                       ).valueOf()
  //                   )
  //                   .map((ele, index) => {
  //                     return (
  //                       <>
  //                         {ele?.appointment_from[0] === item?.value &&
  //                           ele?.appointment_from[1] === item?.name && (
  //                             <>
  //                               <SlotItemTime
  //                                 timeBand={item}
  //                                 appointment={appointment}
  //                                 key={index}
  //                                 style={{
  //                                   top: ele.duration_from,
  //                                   height: ele.duration_to,
  //                                   backgroundColor:
  //                                     clinic_settings?.color_preferences?.[
  //                                       appointment?.appointment_type
  //                                     ],
  //                                   border: "1px solid #eeeef2",
  //                                 }}
  //                                 className={`${
  //                                   ele?.duration === "1" ||
  //                                   ele?.duration === "0.75"
  //                                     ? "multiple-pet description"
  //                                     : "multiple-pet description"
  //                                 }`}
  //                                 timingslot={ele}
  //                               />
  //                             </>
  //                           )}
  //                       </>
  //                     );
  //                   })
  //               : appointment?.appointment_from[0] === item.value &&
  //                 appointment?.appointment_from[1] === item?.name && (
  //                   <SlotItemTime
  //                     appointment={appointment}
  //                     key={i}
  //                     style={{
  //                       top: appointment?.duration_from,
  //                       height: appointment?.duration_to,
  //                     }}
  //                     className={`
  //                                       ${
  //                                         appointment?.duration === "1" ||
  //                                         appointment?.duration === "0.75"
  //                                           ? "multiple-pet description"
  //                                           : "multiple-pet description"
  //                                       }
  //                                       ${
  //                                         appointment?.appointment_type ===
  //                                         "in-clinic"
  //                                           ? "clinic"
  //                                           : appointment?.appointment_type ===
  //                                             "phone-call"
  //                                           ? "phone"
  //                                           : appointment?.appointment_type ===
  //                                             "video-conference"
  //                                           ? "video-conference"
  //                                           : appointment?.appointment_type ===
  //                                             "appointment-block"
  //                                           ? "appointment-block"
  //                                           : ""
  //                                       }
  //                                   `}
  //                   />
  //                 )}
  //           </Fragment>
  //         );
  //       })}
  //     </>
  //   );
  // };

  let sortData = [];
  if (data) {
    data?.forEach((element) => {
      let isMatchSlot = false;
      element?.appointment_timings?.forEach((t) => {
        if (
          t?.appointment_from[0] === item?.value &&
          t?.appointment_from[1] === item?.name
        ) {
          isMatchSlot = true;
        }
      });
      if (isMatchSlot) {
        sortData.push(element);
      }
    });
  }

  let isNextTimeShow = false;
  let nextHeight = 0;
  if (prevData) {
    let prevTime = prevData?.appointment_timings?.[0]?.appointment_time;
    let prevStartTime = prevTime?.split(" - ")?.[0];
    let prevEndTime = prevTime?.split(" - ")?.[1];
    let prevStartMin = prevStartTime?.split(":")?.pop()?.split(" ")?.[0];
    let prevEndMin = prevEndTime?.split(":")?.pop()?.split(" ")?.[0];
    isNextTimeShow = prevStartMin >= 45;
    if (+prevEndMin >= 0 && +prevEndMin <= 15) {
      nextHeight = 33;
    } else if (+prevEndMin > 15 && +prevEndMin <= 30) {
      nextHeight = 70;
    } else if (+prevEndMin > 30 && +prevEndMin <= 45) {
      nextHeight = 110;
    } else if (+prevEndMin > 45) {
      nextHeight = 147;
    } else {
    }
  }
  return (
    <>
      <div
        className={`slot-time-frame d-flex flex-column align-items-center h-100 relative ${className}`}
      >
        {sortData?.length === 0 && !prevData ? (
          <div className="d-flex flex-column h-100 w-100">
            <Button color="link" className="frame-btn flex-fill">
              <span className="material-symbols-outlined">add</span>
            </Button>
            <Button color="link" className="frame-btn flex-fill">
              <span className="material-symbols-outlined">add</span>
            </Button>
            <Button color="link" className="frame-btn flex-fill">
              <span className="material-symbols-outlined">add</span>
            </Button>
            <Button color="link" className="frame-btn flex-fill">
              <span className="material-symbols-outlined">add</span>
            </Button>
          </div>
        ) : (
          <>
            {prevData && (
              <div
                onClick={() => {
                  showAppointmentDetails(prevData);
                }}
                className="cursor-pointer p-1 absolute br-0044 text-13-500 color-2E65"
                style={{
                  top: 0,
                  width: "98%",
                  height: `${nextHeight}px`,
                  backgroundColor:
                    clinic_settings?.color_preferences?.[
                      prevData?.appointment_type
                    ],
                }}
              >
                {isNextTimeShow
                  ? prevData?.appointment_timings?.[0]?.appointment_time
                  : ""}
              </div>
            )}
            {sortData?.map((elm, index) => {
              const {
                patient_id = {},
                description,
                appointment_timings,
                appointment_type,
              } = elm;
              const { pet_image, pet_name, breed } = patient_id;
              let isNext = false;
              let isHideTime = false;
              return (
                <Fragment key={index}>
                  {appointment_timings?.map((ap, apIndex) => {
                    const { appointment_time } = ap;

                    let startTime = appointment_time?.split(" - ")?.[0];
                    let endTime = appointment_time?.split(" - ")?.[1];
                    let topPosition = 3;
                    let slotHeight = 0;
                    let startTimeMinute = startTime
                      ?.split(":")
                      ?.pop()
                      ?.split(" ")?.[0];
                    let endTimeMinute = endTime
                      ?.split(":")
                      ?.pop()
                      ?.split(" ")?.[0];
                    if (+startTimeMinute >= 0 && +startTimeMinute <= 14) {
                      topPosition = 3;
                    } else if (
                      +startTimeMinute >= 15 &&
                      +startTimeMinute <= 29
                    ) {
                      topPosition = 40;
                    } else if (
                      +startTimeMinute >= 30 &&
                      +startTimeMinute <= 44
                    ) {
                      topPosition = 80;
                    } else if (+startTimeMinute >= 45) {
                      topPosition = 115;
                    } else {
                    }
                    if (item?.value !== +endTime?.split(":")?.[0]) {
                      if (+startTimeMinute === 0 && +endTimeMinute === 0) {
                        slotHeight = 143;
                      } else {
                        slotHeight = 143 - topPosition;
                        if (+endTimeMinute > 0) {
                          slotHeight = slotHeight + 6;
                          isNext = true;
                        }
                      }
                    } else {
                      if (+endTimeMinute >= 0 && +endTimeMinute <= 15) {
                        slotHeight = 28;
                        isHideTime = true;
                      } else if (+endTimeMinute >= 16 && +endTimeMinute <= 30) {
                        slotHeight = 68;
                      } else if (+endTimeMinute >= 31 && +endTimeMinute <= 45) {
                        slotHeight = 106;
                      } else if (+endTimeMinute > 45) {
                        slotHeight = 25;
                      } else {
                      }
                    }
                    return (
                      <div
                        onClick={() => {
                          showAppointmentDetails(elm);
                        }}
                        key={apIndex}
                        className={`cursor-pointer p-1 absolute ${
                          isNext ? "br-4400" : "br-4"
                        }`}
                        style={{
                          top: `${topPosition}px`,
                          backgroundColor:
                            clinic_settings?.color_preferences?.[
                              appointment_type
                            ],
                          width: "98%",
                          height: `${slotHeight}px`,
                        }}
                      >
                        <div className="d-flex gap-1 text-13-500 color-2E65 mb-2">
                          {pet_image ? (
                            <span>
                              <img
                                src={pet_image}
                                alt="pet_image"
                                className="h-20 w-20 br-4"
                              />
                            </span>
                          ) : (
                            <span className="bg-ffff h-20 w-20 br-4 f-center">
                              <MdOutlinePets size={14} />
                            </span>
                          )}

                          {appointment_type !== "appointment-block" ? (
                            <span>
                              {pet_name} / {clinicSpecies(breed?.species_id?._id) || breed?.species_id?.species_name} /{" "}
                              {clinicBreed(breed?._id) || breed?.breed}
                            </span>
                          ) : (
                            <span>{description}</span>
                          )}
                        </div>
                        {(!isNext || slotHeight > 40) && !isHideTime && (
                          <div className="text-13-500 color-2E65">
                            {ap?.appointment_time}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </Fragment>
              );
            })}
          </>
        )}
        {/* {appointmentGroupByTime &&
        Object.values(appointmentGroupByTime)?.length ? (
          <>
            {Object.values(appointmentGroupByTime)?.map(
              (combineAppointment, i) => {
                const topValue =
                  moment(
                    combineAppointment?.[0]?.appointment_timings?.[0]
                      ?.slot_time || "00:00 am",
                    "h:mm a"
                  ).minute() >= 30;
                let timeSlotArray =
                  combineAppointment?.[0]?.appointment_timings?.[0]?.appointment_time?.split(
                    " - "
                  );
                let isFullHeight = false;
                if (
                  timeSlotArray?.[0] === `${item?.value}:00 ${item?.name}` &&
                  timeSlotArray?.[1]?.split(":")?.[0] === `${item?.value + 1}`
                ) {
                  isFullHeight = true;
                }
                return (
                  <Fragment key={i}>
                    {combineAppointment.some((appointment) =>
                      appointment?.appointment_timings?.some(
                        (timing) =>
                          timing?.appointment_from[0] === item?.value &&
                          timing?.appointment_from[1] === item?.name
                      )
                    ) ? (
                      <div
                        className={`slot-button-wrapper${
                          isFullHeight
                            ? " h-100"
                            : combineAppointment.some((appointment) =>
                                appointment?.appointment_timings?.some(
                                  (itemVal) => itemVal?.duration_from === "0%"
                                )
                              )
                            ? " slot-above-data"
                            : combineAppointment.some((appointment) =>
                                appointment?.appointment_timings?.some(
                                  (itemVal) => itemVal?.duration_from === "50%"
                                )
                              )
                            ? " slot-below-data"
                            : ""
                        }`}
                        id={`check-temp-${i}`}
                      >
                        {" "}
                        <div className="slot-above">
                          {!topValue &&
                            renderAPPointmentData(combineAppointment, i)}
                        </div>
                        <div className="slot-below">
                          {topValue &&
                            renderAPPointmentData(combineAppointment, i)}
                        </div>
                      </div>
                    ) : null}
                  </Fragment>
                );
              }
            )}
          </>
        ) : (
          <div className="d-flex flex-column h-100">
            <Button color="link" className="frame-btn flex-fill">
              <span className="material-symbols-outlined">add</span>
            </Button>
            <Button color="link" className="frame-btn flex-fill">
              <span className="material-symbols-outlined">add</span>
            </Button>
            <Button color="link" className="frame-btn flex-fill">
              <span className="material-symbols-outlined">add</span>
            </Button>
            <Button color="link" className="frame-btn flex-fill">
              <span className="material-symbols-outlined">add</span>
            </Button>
          </div>
        )} */}
      </div>
    </>
  );
};

export default SlotTimeFrame;
