const initState = {
  allergies: [],
};

export const allergyReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_ALLERGIES":
      return { ...state, allergies: action.payload };
    default:
      return state;
  }
};
