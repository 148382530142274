import React, { useState, useEffect } from 'react'
import { Offcanvas } from 'react-bootstrap'
import './index.scss';
import { Button, Form, FormGroup } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AddNewRoleToClinicStuff } from 'context/actions/clinicPermission';
import { toast, useToast } from 'react-toastify';


const AddNewRole = ({ show, handleClose }) => {

    const [roleInput, setRoleInput] = useState({
        role_name: "",
        active: true,
        notes: ""
    });

    const HandelChange = (e) => {
        const { value, name } = e.target;
        // const activeValue = name === 'active' ? value : noteActive;
        setRoleInput({ ...roleInput, [name]: value });
      
    };

    const dispatch = useDispatch();
    const { clinic_details } = useSelector(state => state.clinicReducer);

    const addNewRole = (e) => {
        e.preventDefault()
        if(roleInput?.role_name.trim() === "") return toast.error("Role name is required.")
        const payload = {
            clinic_id: clinic_details?._id,
           ...roleInput
        }
        handleClose()

        dispatch(AddNewRoleToClinicStuff(payload, (response) => {
            if (response?.statusCode === 201) {
                dispatch({ type: "GET_CLINIC_PERMISSION", payload: response?.response_data?.permission })
                handleClose()
                setRoleInput({
                    role_name: "",
                    active: true,
                    notes: ""
                })
               
            }
        }))
    }

    return (

        <Offcanvas show={show} onHide={handleClose} placement="end" className="select-group-list addnewrole">
            <Offcanvas.Header closeButton className="">
                <Offcanvas.Title>Add New Role</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="px-2 pb-2 pt-0">
                <div className="d-flex flex-column h-100">
                    <Form onSubmit={addNewRole}>
                        <FormGroup className="mb-2">
                            <div className="form-floating position-relative text-input">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="role_name"
                                    name='role_name'
                                    value={roleInput.role_name}
                                    placeholder="Role Name"
                                    onChange={HandelChange}

                                />
                                <label for="">Role Name</label>
                            </div>
                        </FormGroup>


                        <FormGroup className="mb-2">
                            <div className="form-floating position-relative text-input">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="notes"
                                    name='notes'
                                    value={roleInput.notes}
                                    placeholder="Add Note"
                                    onChange={HandelChange}
                                />
                                <label for="">Add Note</label>
                            </div>
                        </FormGroup>


                        <FormGroup className='mb-1 mt-2' onClick={() => HandelChange({target: {name: "active", value: !roleInput.active }})} >

                            {
                                roleInput.active ? <div className="status-rw d-flex align-items-center">
                                    <span className="swtich-cell2 d-inline-flex align-items-center mb-0">
                                        <span className="icomoon-check icon"></span>
                                        <span className="d-inline-flex rounded-circle circle-icon ms-auto"></span>
                                    </span>
                                    <lable> Active </lable>
                                </div> : <div className="status-rw d-flex align-items-center">
                                    <span className={`swtichCell d-inline-flex align-items-center disable`}>
                                        <span className="switch-btn flex-fill d-inline-flex align-items-center flex-shrink-0">
                                            <span className="icomoon-check icon"></span>
                                            <span className="d-inline-flex rounded-circle circle-icon ms-auto"></span>
                                        </span>
                                    </span>
                                </div>
                            }
                        </FormGroup>




                        <p className='mt-2 mb-3 '>Stats description lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>


                        <FormGroup className='d-flex   gap-2 justify-content-end'>
                            <Button style={{ "Fon-Size": "14px" }}
                                className=" cancel_btn py-2 h-auto" onClick={() => handleClose()}>Cancel </Button>
                            <Button style={{ "Fon-Size": "14px" }}
                                color="success"
                                type='submit'
                                className="add-btn py-2 h-auto"
                            >
                                Create
                            </Button>
                        </FormGroup>



                    </Form>

                </div>
            </Offcanvas.Body>
        </Offcanvas>

    )
}

export default AddNewRole;