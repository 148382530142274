

const initState = {
    operatingRooms:[]
 }
 
 export const operatingRoomReducer = (state = initState, action) => {
     switch (action.type) {
         case "GET_OPERATING_ROOMS":
             return { ...state, operatingRooms: action.payload }
         default:
             return state
     }
 }