import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../../../api-client";
import { apiUrl, PORT } from "../../../../environment";
import { toasterConfig } from "../../../../utils/constants";

toast.configure();
/**
 * All Api endpoints to be call
 */

/**
 * get all notes by patient id
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */
export const getNotesByPetId = (petId, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    if (petId) {
      dispatch({ type: "NOTES_LOADING", payload: true });
      ApiClient.get(`${apiUrl}${PORT}/notes/get/${petId}`, {}, token, dispatch, getState).then(
        (response) => {
          dispatch({ type: "NOTES_LOADING", payload: false });
          if (!response) {
            return callback();
          } else if (response?.status) {
            dispatch({ type: "NOTES", payload: response?.response_data });
            return callback(response);
          } else {
            dispatch({ type: "NOTES", payload: response?.response_data });
            return callback(response);
          }
        }
      ).catch(err => {
        dispatch({ type: "NOTES_LOADING", payload: false });
      });
    } else {
      return callback({ status: false });
    }
  };
};

/**
 * get all notes by patient id
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */
export const getNotesForPetForAppointment = (params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    if (params.pet_id && params.appointment_id) {
      dispatch({ type: "NOTES_LOADING", payload: true });
      ApiClient.get(
        `${apiUrl}${PORT}/notes/get-for-pet-for-appointment/${params.pet_id}/${params.appointment_id}`,
        {},
        token,
        dispatch, getState
      ).then((response) => {
        dispatch({ type: "NOTES_LOADING", payload: false });
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({ type: "NOTES", payload: response?.response_data });
          return callback(response);
        } else {
          dispatch({ type: "NOTES", payload: response?.response_data });
          return callback(response);
        }
      });
    } else {
      return callback({ status: false });
    }
  };
};

/**
 * @description function use to add a new patient note
 * @param {*} data accepting object
 */
export const addNewNote = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.post(`${apiUrl}${PORT}/notes/add`, data, token, dispatch, getState).then(
      (response) => {
        if (!response) {
          return callback();
        } else if (!response && response.errors) {
          toast.error(response.errors.msg, toasterConfig);
          return callback(response);
        } else if (response?.status) {
          toast.dismiss();
          toast.success(response.msg, toasterConfig);
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback();
        }
      }
    );
  };
};

/**
 * @description function to update a note document
 * @param {Object} body
 * @param {Function} callback
 * @returns
 */
export const updateNote = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.put(`${apiUrl}${PORT}/notes/update`, body, token, dispatch, getState).then(
      (response) => {
        if (!response) {
          return callback();
        } else if (response?.status) {
          // dispatch({ type: "NOTES", payload: response?.response_data });
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      }
    );
  };
};

/**
 * @description function to update a note document
 * @param {Object} body
 * @param {Function} callback
 * @returns
 */
export const addSectionToNote = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.put(
      `${apiUrl}${PORT}/notes/addsection/${body._id}`,
      body,
      token,
      dispatch, getState
    ).then((response) => {
      if (!response) {
        return callback();
      } else if (response?.status) {
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback(response);
      }
    });
  };
};
