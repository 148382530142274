const initState = {
  loading: false,
  reports: false,
  openPetReportAddEditPanel: false,
};

export const reportReducer = (state = initState, action) => {
  switch (action.type) {
    case "REPORT_LOADING":
      return { ...state, loading: action.payload };

    case "GET_REPORT_LIST":
      return { ...state, reports: action.payload };
    case "OPEN_CLOSE_ADD_EDIT_PET_REPORT_PANEL":
      return { ...state, openPetReportAddEditPanel: action.payload };

    default:
      return state;
  }
};
