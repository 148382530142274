import isEmail from 'validator/lib/isEmail';

export const checkValidations = (name, value, min = 4, max = 100) => {

  switch (name) {
    case 'email':
      return isEmail(value, { allow_display_name: false, require_display_name: false, allow_utf8_local_part: true, require_tld: true, allow_ip_domain: false, domain_specific_validation: false, blacklisted_chars: '' });

    case 'password': {
      return (value.length >= 8) ? true : false;
    }
    case 'text':
      return (value.length >= min && value.length <= max) ? true : false;
    case 'numbervalue':
      return (value.length >= min && value.length <= max) ? true : false;
    case 'number':
      return (/^[0-9]*$/.test(value) && Math.ceil(Math.log10(value + 1)) >= min && Math.ceil(Math.log10(value)) <= max) ? true : false;
    
    default:
      return false;
  }
}