import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { Container } from "reactstrap";

const DefaultLayout = ({ children }) => {
	const [scroll, setScroll] = useState(false);

	let history = useHistory();
	let routerPath = history.location.pathname;
	//if (!user.loggedIn) history.push('/');

	useEffect(() => {
		const mainInnerElem = document.getElementById("mainInner");
		const headerWrapperElem = document.getElementById("headerWrapper").offsetHeight;
		mainInnerElem.style.paddingTop = headerWrapperElem + 'px';

		// Scroll-Event
		const scrollEvent = () => {
			if (window.pageYOffset > 5) {
				setScroll(true);
				document.body.classList.add('on')
			}
			else {
				setScroll(false);
				document.body.classList.remove('on')
			}
		}

		window.addEventListener('scroll', scrollEvent);
		return () => {
			document.addEventListener('scroll', scrollEvent);
		};
	}, [])
	return (
		<>
			<div className={`main-wrapper d-flex flex-column flex-fill 
				${routerPath === "/" ? 'banner-layout' : ''}
				${(
					routerPath === "/login" ||
					routerPath === "/register" ||
					routerPath === "/otp") ? 'signup-pages' : ''}			
				`}>
				<div className="header-wrapper position-fixed" id="headerWrapper">

				</div>
				<div className="main-wrapper-inner d-flex flex-column flex-fill" id="mainInner">
					<Container className="inner-wrapper">
						{children}
					</Container>
				</div>
			</div>
		</>
	)
};

DefaultLayout.propTypes = {
	/**
	 * Whether to display the navbar, or not.
	 */
	noNavbar: PropTypes.bool,
	/**
	 * Whether to display the footer, or not.
	 */
	noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
	noNavbar: false,
	noFooter: false
};

export default DefaultLayout;
