import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPetDetailsById } from "../../context/actions/patient-module/patient/pet";
import Description from "../add-service-inventory/add-description";
import AddAllergy from "./AddAllergy";
import AddBehaviourTags from "./AddBehaviourTags";
import { HiXMark } from "react-icons/hi2";
import KennelInfo from "./KennelInfo";

export default function AddBoarding({ kennels,petId, setShow ,isPetAddedToTheKennel,setIsPetAddedToTheKennel, changePatient}) {
  const [patient, setPatient] = useState();
  const [comments, setComments] = useState("");
  const [allergies, setAllergies] = useState([]);
  const [behaviourTags, setBehaviourTags] = useState([]);
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.user_details);

  const dialogRef = useRef(null);

  useEffect(() => {
    dispatch(
      getPetDetailsById(petId, {}, (result) => {
        if (result && !result.errors) {
          setPatient(result.response_data);
        }
      })
    );
  }, []);

  const handleCommentsChange = (description) => {
    setComments(description);
  };

  const handleBackdropClick = (event) => {
    if (event.target === dialogRef.current) {
      setShow(false);
    }
  };



  return (
    <>
      <div
        ref={dialogRef}
        onClick={handleBackdropClick}
        className="fixed inset-0 w-screen h-screen bg-black bg-opacity-25 z-10"
      />
      <div
        className={
          "fixed right-0 h-screen z-30 top-0 px-5 py-2.5 border-r w-full overflow-auto sm:w-[70vw] md:w-[60vw] lg:w-[50vw] bg-white"
        }
      >
        <button
          onClick={() => setShow(false)}
          className="absolute top-3 p-1 right-3 rounded-full hover:bg-gray-100"
        >
          <HiXMark className="w-5 h-5" />
        </button>
        {/*step === 1 && (
          <>
            <h2 className="text-3xl font-bold">Assign Kennel</h2>
            <div className="w-full my-4 max-w-[400px] border flex divide-x rounded-lg overflow-hidden">
              <button className="bg-gray-100 text-blue-500 w-1/2 py-2.5">
                New
              </button>
              <button className="hover:bg-gray-100 hover:text-blue-500 w-1/2 py-2.5 text-black">
                Existing
              </button>
            </div>
            <section className="relative bg-gray-100 rounded-lg p-2.5">
              <h5 className="text-lg font-semibold  text-[#282E65]">
                Owner Information
              </h5>
              <ul className=" space-y-1">
                <li className="flex items-center">
                  <span className="text-sm md:text-base w-full md:w-44">
                    First Name<span className="text-red-500 ml-1">*</span>
                  </span>
                  <p className="w-full md:w-64 cursor-pointer bg-gray-100 md:px-2.5">
                    {patient?.petOwner?.person_name?.split(" ")[0]}
                  </p>
                </li>
                <li className="flex items-center">
                  <span className="text-sm md:text-base w-full md:w-44">
                    Last Name<span className="text-red-500 ml-1">*</span>
                  </span>

                  <p className="w-full md:w-64 cursor-pointer bg-gray-100 md:px-2.5">
                    {patient?.petOwner?.person_name?.split(" ")[1]}
                  </p>
                </li>
                <li className="flex items-center">
                  <span className="text-sm md:text-base w-full md:w-44">
                    Phone Number<span className="text-red-500 ml-1">*</span>
                  </span>
                  <p className="w-full md:w-64 cursor-pointer bg-gray-100 md:px-2.5">
                    {patient?.petOwner?.phone}
                  </p>
                </li>
                <li className="flex items-center">
                  <span className="text-sm md:text-base w-full md:w-44">
                    Email
                  </span>

                  <p className="w-full md:w-64 cursor-pointer bg-gray-100 md:px-2.5">
                    {patient?.petOwner?.email}
                  </p>
                </li>
                <li className="flex items-center">
                  <span className="text-sm md:text-base w-full md:w-44">
                    Address Information
                  </span>
                  <p className="w-full md:w-64 cursor-pointer bg-gray-100 md:px-2.5">
                    {patient?.petOwner?.address}
                  </p>
                </li>
              </ul>
              <div className="absolute top-[25%] -translate-y-[25%] gap-2 right-4 flex flex-col items-center">
                <span>Profile Picture</span>
                <img
                  src={patient?.petOwner?.image}
                  alt="owner"
                  className="w-24 h-24 rounded-full bg-gray-200 object-cover"
                />
              </div>
            </section>
            <section className="mt-1 relative bg-gray-100 rounded-lg p-2.5">
              <h5 className="text-lg font-semibold  text-[#282E65]">
                Pet Information
              </h5>
              <ul className=" space-y-1">
                <li className="flex items-center">
                  <span className="text-sm md:text-base w-full md:w-44">
                    Pet Name<span className="text-red-500 ml-1">*</span>
                  </span>
                  <p className="w-full md:w-64 capitalize bg-gray-100 md:px-2.5">
                    {patient?.petOwner?.person_name?.split(" ")[0]}{" "}
                  </p>
                </li>
                <li className="flex items-center">
                  <span className="text-sm md:text-base w-full md:w-44">
                    Date of Birth<span className="text-red-500 ml-1">*</span>
                  </span>
                  <p className="w-full md:w-64  bg-gray-100 md:px-2.5">
                    {patient?.dob.split("-").reverse().join("/")}
                  </p>
                </li>
                <li className="flex items-center">
                  <span className="text-sm md:text-base w-full md:w-44">
                    Animal Type<span className="text-red-500 ml-1">*</span>
                  </span>

                  <p className="w-full md:w-64 capitalize bg-gray-100 md:px-2.5">
                    {patient?.speciesData?.species_name ?? "No name"}
                  </p>
                </li>
                <li className="flex items-center">
                  <span className="text-sm md:text-base w-full md:w-44">
                    Gender
                  </span>
                  <p className="w-full md:w-64 capitalize bg-gray-100 md:px-2.5">
                    {patient?.gender}
                  </p>
                </li>
                <li className="flex items-center">
                  <span className="text-sm md:text-base w-full md:w-44">
                    Breed
                  </span>

                  <p className="w-full md:w-64 bg-gray-100 capitalize md:px-2.5">
                    {patient?.breedData?.breed}
                  </p>
                </li>

                <li className="flex items-center">
                  <span className="text-sm md:text-base w-full md:w-44">
                    Colour
                  </span>
                  <p className="w-full md:w-64 bg-gray-100 md:px-2.5">
                    {patient?.color ?? "No Colour"}
                  </p>
                </li>
                <li className="flex items-center">
                  <span className="text-sm md:text-base w-full md:w-44">
                    Behavious Tags
                  </span>
                  <div className="flex items-end flex-wrap gap-1.5 px-2.5">
                    {patient?.behaviour_tag?.length>0?patient?.behaviour_tag?.map((b) => (
                      <span
                        key={Date.now() + Math.random()}
                        className="capitalize p-1 bg-gray-200 rounded-md"
                      >
                        {b.tag}
                      </span>
                    )):behaviourTags.map((b) => (
                      <span
                        key={Date.now() + Math.random()}
                        className="capitalize p-1 bg-gray-200 rounded-md"
                      >
                        {b.tag}
                      </span>
                    ))}
                    <AddBehaviourTags setList={setBehaviourTags} />
                  </div>
                </li>
                <li className="flex items-center">
                  <span className="text-sm md:text-base w-full md:w-44">
                    Allergies
                  </span>
                  <div className="flex items-end flex-wrap gap-1.5 px-2.5">
                    {patient?.alergies?.length>0?patient?.alergies?.map((a) => (
                      <span
                        key={Date.now() + Math.random()}
                        className="capitalize p-1 bg-gray-200 rounded-md"
                      >
                        {a.name}
                      </span>
                    )) :allergies.map((a) => (
                      <span
                        key={Date.now() + Math.random()}
                        className="capitalize p-1 bg-gray-200 rounded-md"
                      >
                        {a.name}
                      </span>
                    ))}
                    <AddAllergy setList={setAllergies} />
                  </div>
                </li>
                <li className="flex flex-col md:flex-row gap-1 items-center">
                  <span className="text-sm md:text-base w-full md:w-44">
                    Patient Notes <br />
                    (For office use only)
                  </span>
                  {patient?.pet_notes?patient?.pet_notes:comments ? (
                    <span className="w-full md:w-64 md:px-2.5 md:max-w-md truncate">
                      {patient?.pet_notes?patient?.pet_notes:comments}
                    </span>
                  ) : (
                    userDetails?.role !== "user" && (
                      <Description
                        initialDescription={comments}
                        index={0}
                        onDescriptionChange={handleCommentsChange}
                        buttonName="info"
                      />
                    )
                  )}
                </li>
              </ul>
              <div className="absolute top-[25%] -translate-y-[25%] gap-2 right-4 flex flex-col items-center">
                <span>Profile Picture</span>
                <img
                  src={patient?.pet_image}
                  alt="pet"
                  className="w-24 h-24 rounded-xl bg-gray-200 object-cover"
                />
              </div>
            </section>
            <div className="flex justify-end items-center gap-2 my-3">
              <button
                onClick={() => setShow(false)}
                className="px-4 py-2.5 rounded-lg bg-gray-100"
              >
                Cancel
              </button>
              <button
                onClick={()=>{handelAddPetTags(petId)}}
                className="px-4 py-2.5 rounded-lg text-white bg-[#11CA9D]"
              >
                Save
              </button>
            </div>
          </>
                    )*/}

        {/*step === 2 && */<KennelInfo changePatient={changePatient} patient={patient} setStep={setStep} setShow={setShow} isPetAddedToTheKennel={isPetAddedToTheKennel} setIsPetAddedToTheKennel={setIsPetAddedToTheKennel} />}
      </div>
    </>
  );
}
