import React, { useState } from "react";
import { useField } from "formik";
import { Button, FormFeedback, FormGroup, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
// import { FormattedMessage } from 'react-intl';
const PasswordInput = (props) => {
	const [field, meta] = useField(props.name);
	const [displayPassword, setDisplayPassword] = useState(false);
	const history = useHistory();

	const handleDisplayPassword = (e) => {
		e.preventDefault();
		setDisplayPassword(true);
	}
	const handleHidePassword = (e) => {
		e.preventDefault();
		setDisplayPassword(false);
	}

	const handleForgotPassword = (e) => {
		e.preventDefault();
		history.push("/forgot-password")
	}
	return (
		<FormGroup className={`input-wrapper ${props?.className}`}>
			{props.label && <><label htmlFor={props?.id} >
				<span className={props.required && 'required'}>{props.label}</span>
			</label></>}
			<div className={`input-cell position-relative 
			${props.icon === 'icon' && 'icon'} 
			${props.iconbutton === 'iconButton' && 'iconButton'}
			${props.forgotLink && 'forgot-pass-link-blc'}
			
			`
			}>
				{
					props.icon === 'icon' &&
					<span className="icon position-absolute h-100 d-inline-flex align-items-center justify-content-center">
						<i className="icomoon-lock"></i>
					</span>
				}

				<Input
					{...field}
					{...props}
					type={displayPassword ? "text" : "password"}
					//   className={`${props?.inputClass}`}
					autoComplete="off"
				/>
				{
					props.iconbutton === 'iconButton' &&
					<Button color="link" className="p-0 shadow-none show-pass-btn icon position-absolute h-100 d-inline-flex align-items-center justify-content-center" onClick={(e) => e.preventDefault()}>
						{
							displayPassword ? <i className="icomoon-eye-slash" onClick={handleHidePassword}></i> : <i className="icomoon-eye" onClick={handleDisplayPassword}></i>
						}

					</Button>
				}
				{
					props.forgotLink && <Button color="link" className="forgot-pass-link p-0 position-absolute" onClick={(e) => handleForgotPassword(e)}>Forgot</Button>
				}
			</div>


			{meta.touched && meta.error !== "password_pattern" && (
				<FormFeedback className="d-block"> {meta?.error}
					{/* <FormattedMessage id={meta.error} values={{
            name: props?.placeholder || 'This'
          }} /> */}
				</FormFeedback>
			)}
		</FormGroup>
	);
};
export default PasswordInput;