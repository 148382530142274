import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import './index.scss';
import Select from "react-select";
import * as Yup from "yup";
import { FieldArray, Form, Formik, getIn } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import { getClinicTreatmentPlan } from 'context/actions/clinicSettings';
import { defaultTreatmentPlanForDischarge } from 'context/reducers/dischargePatientReducer';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getAndSearchProductListByCategory } from 'context/actions/inventory';
import { Input } from "reactstrap";
import { LuMinusCircle } from "react-icons/lu";
import { MdInsertDriveFile } from "react-icons/md";
import { deleteTreatmentAttachment, uploadAttachment } from 'context/actions/patient-module/discharge-patient-form';
const NewTreatmentPlan = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const formikRef = useRef(null);
    const { clinic_settings } = useSelector(
        (state) => state.clinicSettingsReducer
    );
    const { dischargePatientFormData } = useSelector(
        (state) => state.dischargePatientReducer
    );
    const { productList } = useSelector((state) => state.inventoryReducer);


    const [treatmentPlans, setTreatmentPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const debug = false;
    let pushFunc;
    let resetFormFunc;
    let validateFormFun;

    //form-validations-schema
    const validationSchema = Yup.object().shape({

        forms: Yup.array().of(
            Yup.object().shape({
                plan_name: Yup.string().required(
                    "Plan name is required."
                ),
                name: Yup.string().required(
                    "Product or Instruction name is required"
                ),
                frequency: Yup.string().required(
                    "Frequency is required"
                ),
                start_datetime: Yup.date()
                    .typeError("Please enter a valid date.")
                    .required("Start date-time is required."),
                duration_num: Yup.number().required(
                    "Duration is required"
                ),
                duration_unit: Yup.string().required(
                    "Duration unit is required"
                ),
                notes: Yup.string().optional(),
            })
        ),
    });

    // Function to submit the Formik form
    const submitForm = () => {
        if (formikRef.current) {
            formikRef.current.handleSubmit();
        }
    };

    useImperativeHandle(ref, () => ({
        submitForm,
    }));

    // select on change
    const handleSelectTemplate = (e) => {
        setSelectedPlan(e)
        const planDetails = treatmentPlans.find(ele => ele._id === e.id);
        const tempArr = [];
        planDetails?.productDetails?.forEach(element => {
            let obj = JSON.parse(
                JSON.stringify(defaultTreatmentPlanForDischarge[0])
            );
            // obj.plan_name = planDetails?.treatmentName;
            // obj.plan_id = planDetails?._id;
            obj.type = "product";
            obj.product_instruction_id = element._id;
            obj.name = element?.name;
            obj.instructions = element?.instructions;

            pushFunc(obj);
            tempArr.push(obj)
        });
        planDetails?.instructionDetails?.forEach(element => {
            let obj = JSON.parse(
                JSON.stringify(defaultTreatmentPlanForDischarge[0])
            );
            // obj.plan_name = planDetails?.treatmentName;
            // obj.plan_id = planDetails?._id;
            obj.type = "instruction";
            obj.product_instruction_id = element._id;
            obj.name = element?.name;
            obj.instructions = element?.instructions;
            obj.attachments = element?.attachments || [];
            pushFunc(obj);
            tempArr.push(obj)
        });


        let dischargePatientData = JSON.parse(
            JSON.stringify(dischargePatientFormData)
        );
        if (dischargePatientData.treatmentPlan_for_discharge?.length) {
            dischargePatientData.treatmentPlan_for_discharge = [...dischargePatientData.treatmentPlan_for_discharge, ...tempArr];
        } else {
            dischargePatientData.treatmentPlan_for_discharge = [...tempArr];
        }
        dispatch({
            type: "DISCHARGE_PATIENT_FORM_DATA",
            payload: { ...dischargePatientData },
        });
    }


    const handleAddProduct = (e) => {
        setSelectedProduct(e);
        const planDetails = productList.find(prod => prod._id === e.id)

        let obj = JSON.parse(
            JSON.stringify(defaultTreatmentPlanForDischarge[0])
        );
        obj.type = "product";
        obj.plan_name = dischargePatientFormData?.treatmentPlan_for_discharge?.[0]?.plan_name
        obj.product_instruction_id = planDetails._id;
        obj.name = planDetails?.product_name;
        obj.instructions = "";
        pushFunc(obj);

        let dischargePatientData = JSON.parse(
            JSON.stringify(dischargePatientFormData)
        );

        dischargePatientData.treatmentPlan_for_discharge.push(obj)

        dispatch({
            type: "DISCHARGE_PATIENT_FORM_DATA",
            payload: { ...dischargePatientData },
        });
    }
    const handleAddNewInstruction = (e) => {
        setSelectedProduct(e);
        const planDetails = productList.find(prod => prod._id === e.id)

        let obj = JSON.parse(
            JSON.stringify(defaultTreatmentPlanForDischarge[0])
        );
        obj.type = "instruction";
        obj.plan_name = dischargePatientFormData?.treatmentPlan_for_discharge?.[0]?.plan_name;
        obj.instructions = "";
        pushFunc(obj);

        let dischargePatientData = JSON.parse(
            JSON.stringify(dischargePatientFormData)
        );

        dischargePatientData.treatmentPlan_for_discharge.push(obj)

        dispatch({
            type: "DISCHARGE_PATIENT_FORM_DATA",
            payload: { ...dischargePatientData },
        });
    }
    const handleInputChange = (value, field, index) => {
        let dischargePatientData = JSON.parse(
            JSON.stringify(dischargePatientFormData)
        );
        let findEle = dischargePatientData.treatmentPlan_for_discharge?.[index];

        if (!findEle) {
            dischargePatientData.treatmentPlan_for_discharge.push({ [field]: value });
        } else {
            findEle[field] = value;
        }

        dispatch({
            type: "DISCHARGE_PATIENT_FORM_DATA",
            payload: dischargePatientData,
        });
    };

    const removeItemFromRedux = (index) => {
        let dischargePatientData = JSON.parse(
            JSON.stringify(dischargePatientFormData)
        );
        let filteredOrders = dischargePatientData.treatmentPlan_for_discharge.filter(
            (ele, idx) => idx !== index
        );
        dischargePatientData.treatmentPlan_for_discharge = filteredOrders;
        dispatch({
            type: "DISCHARGE_PATIENT_FORM_DATA",
            payload: dischargePatientData,
        });
    };
    const deleteFile = (i, j) => {


        let dischargePatientData = JSON.parse(
            JSON.stringify(dischargePatientFormData)
        );
        const filePath = dischargePatientData.treatmentPlan_for_discharge[i].attachments[j];
        if (filePath.includes("/pet/")) {
            dispatch(deleteTreatmentAttachment({ path: filePath }, (res) => {
                if (res.status) {
                    dischargePatientData.treatmentPlan_for_discharge[i].attachments.splice(j, 1);
                    dispatch({
                        type: "DISCHARGE_PATIENT_FORM_DATA",
                        payload: dischargePatientData,
                    });
                }
            }))
        } else {
            dischargePatientData.treatmentPlan_for_discharge[i].attachments.splice(j, 1);
            dispatch({
                type: "DISCHARGE_PATIENT_FORM_DATA",
                payload: dischargePatientData,
            });
        }
    }

    const handleOnFileChange = (theFileObj, index) => {
        const formData = new FormData();
        formData.append("attachments", theFileObj)
        dispatch(uploadAttachment(formData, (res) => {
            if (res.status) {
                let dischargePatientData = JSON.parse(
                    JSON.stringify(dischargePatientFormData)
                );
                let findEle = dischargePatientData.treatmentPlan_for_discharge?.[index];

                findEle["attachments"] = [...findEle.attachments, ...res.response_data];
                dispatch({
                    type: "DISCHARGE_PATIENT_FORM_DATA",
                    payload: dischargePatientData,
                });
            }

        }))
    };
    const handleGetAndSearchProductList = (e) => {
        let text = e?.target?.value;
        dispatch(
            getAndSearchProductListByCategory
                ({ searchText: text, categories: ["equipment"] },
                    () => {
                    })
        );
    };

    useEffect(() => {
        handleGetAndSearchProductList();
    }, []);

    useEffect(() => {
        dispatch(
            getClinicTreatmentPlan(clinic_settings?.clinic_id, (response) => {
                // setClinicTemplateList(response?.response_data)
                setTreatmentPlans(response?.response_data);
            })
        )
    }, []);
    useEffect(() => {

        validateFormFun().then((errors) => {
            if (Object.keys(errors).length === 0) {
                props.setFormSubmitted(true);
            } else {
                props.setFormSubmitted(false);
            }
        });

    }, []);
    return (
        <div className="row-block d-flex mt-3">
            <div className="head w-[25%] me-3">
                <h3>Treatment Plan for Discharge</h3>
                <div className="input-group my-3">
                    <Select
                        className="w-[80%]"
                        isMulti={false}
                        isSearchable={true}
                        placeholder={'Select Template'}
                        options={treatmentPlans.map(ele => ({ id: ele?._id, label: ele?.treatmentName }))}
                        value={selectedPlan}
                        onChange={handleSelectTemplate}
                    />
                </div>
                <div className="input-group my-3">
                    <Select
                        className="w-[80%]"
                        isMulti={false}
                        isSearchable={true}
                        placeholder={'Select Product'}
                        options={productList?.filter(prod => prod?.packages?.[prod?.packages?.length - 1]
                            ?.stock_status)?.map(ele => ({ id: ele?._id, label: ele?.product_name }))}
                        value={selectedProduct}
                        onChange={handleAddProduct}
                    />
                </div>
                <div className='order-btn'>
                    <button
                        className="btn btn-outline-light mb-2"
                        onClick={handleAddNewInstruction}
                    >
                        Add New Instruction
                    </button>
                </div>

            </div>
            <div className="inner w-[73%]">
                <Formik
                    innerRef={formikRef}
                    initialValues={{
                        plan_name: dischargePatientFormData?.treatmentPlan_for_discharge?.[0]?.plan_name,
                        forms: dischargePatientFormData?.treatmentPlan_for_discharge,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { validateForm }) => {
                        validateForm().then((errors) => {
                            if (Object.keys(errors).length === 0) {
                                props.setFormSubmitted(true);
                            } else {
                                props.setFormSubmitted(false);
                            }
                        });
                    }}
                    enableReinitialize={true}

                >
                    {({
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        isValid,
                        setFieldValue,
                        submitForm,
                        resetForm,
                        status,
                        setTouched,
                        validateForm
                    }) => {
                        resetFormFunc = resetForm;
                        validateFormFun = validateForm

                        if (errors && errors.forms && errors.forms.length) {
                            props.setFormSubmitted(false);
                        } else {
                            props.setFormSubmitted(true);
                        }
                        return (
                            <>
                                <div className="outer-discharge-table  mb-3 ">
                                    <table className="w-full">
                                        <tr>
                                            <td className="vertical-top">
                                                Treatment Plan Name
                                            </td>
                                            <td sm={8} className="p-0">
                                                <input
                                                    name="plan_name"
                                                    value={values.plan_name}
                                                    required

                                                    helperText={
                                                        touched.plan_name && errors.forms?.[0]?.plan_name
                                                            ? errors.forms?.[0]?.plan_name
                                                            : ""
                                                    }
                                                    error={Boolean(
                                                        touched.plan_name && errors.forms?.[0]?.plan_name
                                                    )}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        let dischargePatientData = JSON.parse(
                                                            JSON.stringify(dischargePatientFormData)
                                                        );
                                                        dischargePatientData.treatmentPlan_for_discharge?.forEach(ele => {
                                                            ele.plan_name = e.target.value
                                                        })

                                                        dispatch({
                                                            type: "DISCHARGE_PATIENT_FORM_DATA",
                                                            payload: dischargePatientData,
                                                        });
                                                    }}
                                                    onBlur={handleBlur}
                                                    label="Add Plan Name"
                                                    type="text"
                                                    placeholder="Add Plan Name"
                                                />
                                                {touched.plan_name && errors.forms?.[0]?.plan_name ? (
                                                    <div className="text-danger">
                                                        {errors.forms?.[0]?.plan_name}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </td>
                                        </tr>
                                    </table>

                                </div>
                                <Form noValidate autoComplete="off">

                                    <FieldArray name="forms">
                                        {({ push, remove }) => {
                                            pushFunc = push;

                                            return (
                                                <div>
                                                    {values.forms &&
                                                        values.forms.map((details, index) => {
                                                            const productInstructionName = `forms[${index}].name`;
                                                            const touchedProductInstructionName = getIn(touched, productInstructionName);
                                                            const errorProductInstructionName = getIn(errors, productInstructionName);

                                                            const instructions = `forms[${index}].instructions`;
                                                            const touchedInstructions = getIn(touched, instructions);
                                                            const erroInstructions = getIn(errors, instructions);

                                                            const frequency = `forms[${index}].frequency`;
                                                            const touchedfrequency = getIn(touched, frequency);
                                                            const errorfrequency = getIn(errors, frequency);

                                                            const start_datetime = `forms[${index}].start_datetime`;
                                                            const touchedStartDateTime = getIn(
                                                                touched,
                                                                start_datetime
                                                            );
                                                            const errorStartDateTime = getIn(
                                                                errors,
                                                                start_datetime
                                                            );

                                                            const duration_num = `forms[${index}].duration_num`;
                                                            const touchedDurationNum = getIn(
                                                                touched,
                                                                duration_num
                                                            );
                                                            const errorDurationNum = getIn(
                                                                errors,
                                                                duration_num
                                                            );

                                                            const duration_unit = `forms[${index}].duration_unit`;
                                                            const touchedDurationUnit = getIn(
                                                                touched,
                                                                duration_unit
                                                            );
                                                            const errorDurationUnit = getIn(
                                                                errors,
                                                                duration_unit
                                                            );

                                                            const notes = `forms[${index}].notes`;
                                                            const touchedNotes = getIn(touched, notes);
                                                            const errorNotes = getIn(errors, notes);

                                                            return (
                                                                <div key={index}>
                                                                    <div className="outer-discharge-table position-relative mb-3">
                                                                        <table className="w-full">
                                                                            {/* <tr>
                                                                            <td className="vertical-top">
                                                                                Treatment Plan
                                                                            </td>
                                                                            <td sm={8} className="p-0">
                                                                                <span className="padding-start">
                                                                                    {details?.plan_name}
                                                                                </span>
                                                                            </td>
                                                                        </tr> */}
                                                                            <tr>
                                                                                <td className="vertical-top">
                                                                                    {details?.type === "product" ? "Product": "Instruction"}
                                                                                </td>
                                                                                <td sm={8} className="p-0">
                                                                                    {/* <span className="padding-start">
                                                                                        {details?.name}
                                                                                    </span> */}

                                                                                    <input
                                                                                        name="name"
                                                                                        value={details.name}
                                                                                        required
                                                                                        helperText={
                                                                                            touchedProductInstructionName && errorProductInstructionName
                                                                                                ? errorProductInstructionName
                                                                                                : ""
                                                                                        }
                                                                                        error={Boolean(
                                                                                            touchedProductInstructionName && errorProductInstructionName
                                                                                        )}
                                                                                        onChange={(e) => {
                                                                                            handleChange(e);
                                                                                            handleInputChange(
                                                                                                e.target.value,
                                                                                                "name",
                                                                                                index
                                                                                            );
                                                                                        }}
                                                                                        onBlur={handleBlur}
                                                                                        label="Add Name"
                                                                                        type="text"
                                                                                        placeholder="Add Name"
                                                                                        id="name"
                                                                                    />

                                                                                    {touchedProductInstructionName && errorProductInstructionName ? (
                                                                                        <div className="text-danger">
                                                                                            {errorProductInstructionName}
                                                                                        </div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="vertical-top">
                                                                                    Instructions
                                                                                </td>
                                                                                <td sm={8} className="p-0">
                                                                                    {/* <span className="padding-start">
                                                                                        {details?.name}
                                                                                    </span> */}

                                                                                    <input
                                                                                        name={notes}
                                                                                        value={details.instructions}
                                                                                        required
                                                                                        helperText={
                                                                                            touchedInstructions && erroInstructions
                                                                                                ? erroInstructions
                                                                                                : ""
                                                                                        }
                                                                                        error={Boolean(
                                                                                            touchedInstructions && erroInstructions
                                                                                        )}
                                                                                        onChange={(e) => {
                                                                                            handleChange(e);
                                                                                            handleInputChange(
                                                                                                e.target.value,
                                                                                                "instructions",
                                                                                                index
                                                                                            );
                                                                                        }}
                                                                                        onBlur={handleBlur}
                                                                                        label="instructions"
                                                                                        type="text"
                                                                                        placeholder="Add Instructions"
                                                                                        id="instructions"
                                                                                    />

                                                                                    {touchedInstructions && erroInstructions ? (
                                                                                        <div className="text-danger">
                                                                                            {erroInstructions}
                                                                                        </div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="vertical-top">
                                                                                    Frequency
                                                                                </td>
                                                                                <td sm={8} className="p-0">
                                                                                    <select
                                                                                        placeholder="Select Frequency"
                                                                                        label="Select Frequency"
                                                                                        name={frequency}
                                                                                        value={details.frequency}
                                                                                        required
                                                                                        helperText={
                                                                                            touchedfrequency && errorfrequency
                                                                                                ? errorfrequency
                                                                                                : ""
                                                                                        }
                                                                                        error={Boolean(
                                                                                            touchedfrequency && errorfrequency
                                                                                        )}
                                                                                        onChange={(e) => {
                                                                                            handleChange(e);
                                                                                            handleInputChange(
                                                                                                e.target.value,
                                                                                                "frequency",
                                                                                                index
                                                                                            );
                                                                                        }}
                                                                                        onBlur={handleBlur}
                                                                                        id="frequency"
                                                                                    >
                                                                                        <option>Select Frequency</option>
                                                                                        <option>q5m</option>
                                                                                        <option>q15m</option>
                                                                                        <option>q30m</option>
                                                                                        <option>q2h</option>
                                                                                        <option>q4h</option>
                                                                                        <option>q6h</option>
                                                                                        <option>q8h</option>
                                                                                        <option>q12h</option>
                                                                                        <option>q24h</option>
                                                                                        <option>daily</option>
                                                                                        <option>daily at bedtime</option>
                                                                                        <option>BID</option>
                                                                                        <option>TID</option>
                                                                                        <option>QID</option>
                                                                                        <option>every other day</option>
                                                                                        <option>once</option>
                                                                                    </select>
                                                                                    {touchedfrequency &&
                                                                                        errorfrequency ? (
                                                                                        <div className="text-danger">
                                                                                            {errorfrequency}
                                                                                        </div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="vertical-top">Start Date/Time</td>
                                                                                <td>
                                                                                    {
                                                                                        <span className="padding-start">
                                                                                            {" "}
                                                                                            <DatePicker
                                                                                                selected={
                                                                                                    details.start_datetime &&
                                                                                                    details.start_datetime != "" &&
                                                                                                    new Date(details.start_datetime)
                                                                                                }
                                                                                                onChange={(date) => {
                                                                                                    setFieldValue(start_datetime, date);

                                                                                                    handleInputChange(
                                                                                                        date,
                                                                                                        "start_datetime",
                                                                                                        index
                                                                                                    );
                                                                                                }}
                                                                                                onBlur={handleBlur}
                                                                                                showTimeSelect
                                                                                                timeFormat="hh:mm aa"
                                                                                                timeIntervals={15}
                                                                                                placeholderText="Select Date"
                                                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                                                className="d-block absoulte date-blocker w-auto max-[600px]:min-w-[330px]"
                                                                                            />
                                                                                            {touchedStartDateTime &&
                                                                                                errorStartDateTime ? (
                                                                                                <div className="text-danger">
                                                                                                    {errorStartDateTime}
                                                                                                </div>
                                                                                            ) : (
                                                                                                ""
                                                                                            )}
                                                                                        </span>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="vertical-top">
                                                                                    Duration:
                                                                                </td>
                                                                                <td className="duration-input padding-start d-flex">
                                                                                    {/* <InputGroup id="duration"> */}
                                                                                    <div>
                                                                                        <input
                                                                                            name={duration_num}
                                                                                            value={details.duration_num}
                                                                                            required
                                                                                            helperText={
                                                                                                touchedDurationNum &&
                                                                                                    errorDurationNum
                                                                                                    ? errorDurationNum
                                                                                                    : ""
                                                                                            }
                                                                                            error={Boolean(
                                                                                                touchedDurationNum &&
                                                                                                errorDurationNum
                                                                                            )}
                                                                                            onChange={(e) => {
                                                                                                handleChange(e);
                                                                                                handleInputChange(
                                                                                                    e.target.value,
                                                                                                    "duration_num",
                                                                                                    index
                                                                                                );
                                                                                            }}
                                                                                            onBlur={handleBlur}
                                                                                            label="Add Duration"
                                                                                            type="number"
                                                                                            min="1"
                                                                                            placeholder="Add Duration"
                                                                                        />

                                                                                        {touchedDurationNum &&
                                                                                            errorDurationNum ? (
                                                                                            <div className="text-danger">
                                                                                                {errorDurationNum}
                                                                                            </div>
                                                                                        ) : (
                                                                                            ""
                                                                                        )}
                                                                                    </div>

                                                                                    <div>
                                                                                        <select
                                                                                            label="Select Duration"
                                                                                            name={duration_unit}
                                                                                            value={details.duration_unit}
                                                                                            required
                                                                                            helperText={
                                                                                                touchedDurationUnit &&
                                                                                                    errorDurationUnit
                                                                                                    ? errorDurationUnit
                                                                                                    : ""
                                                                                            }
                                                                                            error={Boolean(
                                                                                                touchedDurationUnit &&
                                                                                                errorDurationUnit
                                                                                            )}
                                                                                            onChange={(e) => {
                                                                                                handleChange(e);
                                                                                                handleInputChange(
                                                                                                    e.target.value,
                                                                                                    "duration_unit",
                                                                                                    index
                                                                                                );
                                                                                            }}
                                                                                            onBlur={handleBlur}
                                                                                            placeholder="Select Duration"
                                                                                        >
                                                                                            <option>Select Unit</option>
                                                                                            <option>Days</option>
                                                                                            <option>Weeks</option>
                                                                                        </select>

                                                                                        {touchedDurationUnit &&
                                                                                            errorDurationUnit ? (
                                                                                            <div className="text-danger">
                                                                                                {errorDurationUnit}
                                                                                            </div>
                                                                                        ) : (
                                                                                            ""
                                                                                        )}
                                                                                    </div>

                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="vertical-top">Notes:</td>
                                                                                <td className="padding-start">
                                                                                    <input
                                                                                        name={notes}
                                                                                        value={details.notes}
                                                                                        required
                                                                                        helperText={
                                                                                            touchedNotes && errorNotes
                                                                                                ? errorNotes
                                                                                                : ""
                                                                                        }
                                                                                        error={Boolean(
                                                                                            touchedNotes && errorNotes
                                                                                        )}
                                                                                        onChange={(e) => {
                                                                                            handleChange(e);
                                                                                            handleInputChange(
                                                                                                e.target.value,
                                                                                                "notes",
                                                                                                index
                                                                                            );
                                                                                        }}
                                                                                        onBlur={handleBlur}
                                                                                        label="Add Notes"
                                                                                        type="text"
                                                                                        placeholder="Add Notes"
                                                                                    />

                                                                                    {touchedNotes && errorNotes ? (
                                                                                        <div className="text-danger">
                                                                                            {errorNotes}
                                                                                        </div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                            {details?.type === "instruction" && <tr>
                                                                                <td className="vertical-top">
                                                                                    Attachments
                                                                                </td>
                                                                                <td sm={8} className="p-0">
                                                                                    <div className="add_fils d-flex flex-wrap gap-3 my-2">
                                                                                        {details?.attachments?.map((file, j) => {
                                                                                            const extensionKey = !file?.name ? file : file?.name;
                                                                                            if (['jpg', 'jpeg', 'png', 'gif'].includes(extensionKey.substring(extensionKey.lastIndexOf('.') + 1))) {
                                                                                                const imgPath = file?.name ? URL.createObjectURL(file) : file
                                                                                                return (
                                                                                                    <div className="position-relative" key={j}>
                                                                                                        <img
                                                                                                            className="file_box"
                                                                                                            src={imgPath}
                                                                                                            alt="img"
                                                                                                        />
                                                                                                        <span className="file-delete-icon" onClick={() => { deleteFile(index, j) }}>
                                                                                                            <LuMinusCircle color={'red'} />
                                                                                                        </span>
                                                                                                    </div>
                                                                                                )
                                                                                            } else if (['pdf'].includes(extensionKey.substring(extensionKey.lastIndexOf('.') + 1))) {
                                                                                                return (
                                                                                                    <div className="position-relative" key={j}>
                                                                                                        <img
                                                                                                            className="file_box"
                                                                                                            src={require("../../../assets/img/pdf.png")}
                                                                                                            alt="img"
                                                                                                        />
                                                                                                        <span className="file-delete-icon" onClick={() => { deleteFile(index, j) }}>
                                                                                                            <LuMinusCircle color={'red'} />
                                                                                                        </span>
                                                                                                    </div>
                                                                                                )
                                                                                            } else if (['mp3', 'mp4'].includes(extensionKey.substring(extensionKey.lastIndexOf('.') + 1))) {
                                                                                                return (
                                                                                                    <div className="position-relative" key={j}>
                                                                                                        <img
                                                                                                            className="file_box"
                                                                                                            src={require(`../../../assets/img/mp4.png`)}
                                                                                                            alt="img"
                                                                                                        />
                                                                                                        <span className="file-delete-icon" onClick={() => { deleteFile(index, j) }}>
                                                                                                            <LuMinusCircle color={'red'} />
                                                                                                        </span>
                                                                                                    </div>
                                                                                                )
                                                                                            } else {
                                                                                                return (
                                                                                                    <div className="position-relative" key={j}>
                                                                                                        <MdInsertDriveFile size={50} />
                                                                                                        <span className="file-delete-icon" onClick={() => { deleteFile(index, j) }}>
                                                                                                            <LuMinusCircle color={'red'} />
                                                                                                        </span>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        })}

                                                                                        <button type="button" class="add_file_btn">
                                                                                            <i class="icomoon-plus-btn"></i>
                                                                                            <Input type="file" id="myfile" name="myfile" onChange={(e) => handleOnFileChange(e.target.files[0], index)} />
                                                                                        </button>
                                                                                    </div>
                                                                                    {/* <span className="padding-start">
                                                                                        {details?.name}
                                                                                    </span> */}
                                                                                </td>
                                                                            </tr>}
                                                                        </table>
                                                                        <span
                                                                            className="icomoon-trash order-del"
                                                                            onClick={() => {
                                                                                remove(index);
                                                                                removeItemFromRedux(index);
                                                                            }}
                                                                        ></span>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            );
                                        }}
                                    </FieldArray>

                                    {debug && (
                                        <>
                                            <pre style={{ textAlign: "left" }}>
                                                <strong>Values</strong>
                                                <br />
                                                {JSON.stringify(values, null, 2)}
                                            </pre>
                                            <pre style={{ textAlign: "left" }}>
                                                <strong>Errors</strong>
                                                <br />
                                                {JSON.stringify(errors, null, 2)}
                                            </pre>
                                        </>
                                    )}
                                </Form>
                            </>

                        );
                    }}
                </Formik>
            </div>
        </div>
    )
})

export default NewTreatmentPlan