import React from "react";
import moment from "moment";
import { CiCircleCheck } from "react-icons/ci";
import { IoIosCloseCircleOutline } from "react-icons/io";
import ReactSelect from "react-select";

const OcularCell = ({
  data,
  cellKey,
  editData,
  cellTitle,
  setEditData,
  handleEditDetails,
  isAnyFieldValue,
  handleStatusChange,
  deleted,
}) => {
  const { value, locationDetailId } = data;
  const isSelectInput = ["eye"].includes(cellKey);
  const isDatePicker = ["added_date"].includes(cellKey);
  const isStatusBtn = ["active"].includes(cellKey);
  const isEdit =
    cellKey === editData?.fieldId &&
    locationDetailId === editData?.locationDetailId;
  return (
    <React.Fragment>
      {isEdit ? (
        isSelectInput ? (
          <span  style={{textTransform:'capitalize'}} className="dropdown-container">
            <ReactSelect
              isClearable
            
              placeholder="Select Type"
              id={`${cellKey}_${locationDetailId}`}
              options={editData?.options}
              value={value ? { value: value, label: value } : ""}
              onChange={(e) => {
                let sVal = e?.value || "";
                handleEditDetails({
                  ...editData,
                  fieldValue: sVal,
                });
                setTimeout(() => {
                  setEditData(null);
                }, 500);
              }}
            />
          </span>
        ) : isDatePicker ? (
          <span className="postion-relative">
            <span>--Select Date--</span>
            <div className="date-tile-edit-popover shadow">
              <div>
                <input
                  type="datetime-local"
                  value={editData?.fieldValue || ""}
                  onChange={(e) => {
                    setEditData((prev) => {
                      return {
                        ...prev,
                        fieldValue: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div className="d-flex align-items-center justify-content-end mt-3 gap-2">
                <IoIosCloseCircleOutline
                  size={20}
                  color="red"
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (value) {
                      handleEditDetails({
                        ...editData,
                        fieldId: cellKey,
                        fieldValue: "",
                      });
                      setTimeout(() => {
                        setEditData(null);
                      }, 500);
                    } else {
                      setEditData(null);
                    }
                  }}
                />
                <CiCircleCheck
                  size={20}
                  color="#11ca9d"
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (editData?.fieldValue) {
                      if (
                        editData?.fieldValue !==
                        moment(value, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
                          "YYYY-MM-DDTHH:mm"
                        )
                      ) {
                        handleEditDetails(editData);
                        setTimeout(() => {
                          setEditData(null);
                        }, 500);
                      }
                    }
                  }}
                />
              </div>
            </div>
          </span>
        ) : (
          <span className="iv-input-block">
            <input
              name={cellKey}
              type="text"
              placeholder={`Enter ${cellTitle}`}
              value={editData?.fieldValue || ""}
              onChange={(e) => {
                setEditData((prev) => {
                  return {
                    ...prev,
                    fieldValue: e.target.value,
                  };
                });
              }}
              onBlur={() => {
                if (`${value}` !== `${editData?.fieldValue}`) {
                  handleEditDetails(editData);
                } else {
                  setEditData(null);
                }
              }}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  if (`${value}` !== `${editData?.fieldValue}`) {
                    handleEditDetails(editData);
                  } else {
                    setEditData(null);
                  }
                } else if (e.keyCode === 27) {
                  setEditData(null);
                } else {
                  // Nothing
                }
              }}
            />
          </span>
        )
      ) : isStatusBtn ? (
        <span
          className={value ? "active-btn" : "inactive-btn"}
        >
          {value ? "Active" : "Inactive"}
        </span>
      ) : value ? (
        isDatePicker ? (
          <span>
            {`${moment(value, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
              "MM/DD/YYYY"
            )} at ${moment(value, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
              "hh:mm a"
            )}`}
          </span>
        ) : (
          <span style={isSelectInput ? {textTransform: 'capitalize'} :{}}>{value}</span>
        )
      ) : isAnyFieldValue ? (
        <span>--</span>
      ) : (
        <span className="add_info">{deleted ? "--" : "--Add Info--"}</span>
      )}
    </React.Fragment>
  );
};

export default OcularCell;
