const initialState = {
	isAppointmentDetailPanel: false,
	data: false,
	todaysAppointments: [],
	completeAppointments: []
}


export const appointmentDetailPanelReducer = (state = initialState, action) => {
	switch (action.type) {

		case "SET_APPOINTMENTDETAIL_PANEL": {
			return {
				...state, isAppointmentDetailPanel: action.data
			}
		}
		case "SET_APPOINTMENTDETAIL_DATA": {
			return {
				...state, data: action.data
			}
		}
		case "GET_LIMITED_APPOINTMENTS":
			return { ...state, todaysAppointments: action.payload };
		case "GET_COMPLETED_APPOINTMENTS":
			return { ...state, completeAppointments: action.payload };


		default:
			return state;

	}
};

