import React, { useEffect, useState } from "react";
import "./index.css";
import OwnerProfileDescription from "./PetOwnerProfileDetails/OwnerProfileDescription";
import OwnerProfilehead from "./PetOwnerProfileDetails/OwnerProfilehead";
import PetOwnerTabScreens from "./PetOwnerTabScreens/index.js";
import { useDispatch, useSelector } from "react-redux";
import { getOwnerAndPetsDetails } from "../../../context/actions/patient-module/patient/pet";
import LoaderUI from "../../../shared/loader";
import { useLocation } from "react-router-dom";


const PetOwnerTab = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const location = useLocation()
    const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);

    useEffect(() => {
        if(clinic_settings && location && location.state){
            setLoading(true)
            dispatch(getOwnerAndPetsDetails({ ownerId: location.state.owner_id, clinic_id: clinic_settings.clinic_id }, () => {
                    setLoading(false)
            }))
        }
    }, [clinic_settings])
    return (
        <>
            {loading ? <LoaderUI overlay={true} />:
            <div className="page-wrapper patient-detail-wrapper position-relative d-flex overflow-auto">
                <div className="left-col d-flex flex-column overflow-auto petOwnerProfile gap-[10px]">
                    <OwnerProfilehead />
                    <OwnerProfileDescription />
                </div>
                <div className="right-col d-flex flex-fill overflow-auto">
                    <PetOwnerTabScreens />
                </div>
            </div>}
            {/*  <NodataFoundBlock /> */}
        </>
    );
};

export default PetOwnerTab;
