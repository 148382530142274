import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import SearchBlock2 from "shared/SearchBlock2";
import "./HealthSnapshotOffcanvas.scss";
import { Input } from "reactstrap";
import { Icons } from "utils/contants/Icons";
import MedicationPlanBlock from "components/patient-module/PatientDetail/TabScreens/Overview/MedicationPlanBlock";
import SurgicalHistoryBlock from "components/patient-module/PatientDetail/TabScreens/MedicalHistory/SurgicalHistoryBlock";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ReportsBlock from "components/patient-module/PatientDetail/TabScreens/Overview/ReportsBlock";
import VaccinationBlock from "components/patient-module/PatientDetail/TabScreens/MedicalHistory/VaccinationBlock";

const HealthSnapshotOffcanvas = ({ show, close, pId }) => {
  const params = useParams();
  const petId = params.petId;

  return (
    <Offcanvas
      show={show}
      onHide={close}
      placement="end"
      className="medication-plan-offcanvas"
      style={{ minWidth: "37%" }}
    >
      <Offcanvas.Header closeButton className="pb-0">
        <Offcanvas.Title>Health Snapshot</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="pt-2 medication-plan-block">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex gap-3 align-items-center ">
            <div className="search-block">
              <SearchBlock2 />
            </div>

            <div className="filter-canvas">
              <Input type="select" name="select" onChange={() => {}}>
                <option value="">Default Filter...</option>
              </Input>
            </div>
          </div>
          <div>
            <div className="cp-8 bg-8C9F28 pointer">
              <img src={Icons.download} alt="download" />
            </div>
          </div>
        </div>

        <div className="cmt-10">
          <MedicationPlanBlock viewAll offCanvasClassName />
        </div>

        <div className="cmt-10">
          <SurgicalHistoryBlock petId={pId ? pId : petId} viewAll />
        </div>

        <div className="cmt-10">
          <ReportsBlock
            petId={pId ? pId : petId}
            title="Procedure & Exams"
            viewAll
            offCanvasReportClassName
          />
        </div>

        <div className="cmt-10">
          <VaccinationBlock petId={pId ? pId : petId} viewAll />
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default HealthSnapshotOffcanvas;
