import React from "react";
import "./index.scss";

const StepsBlock = ({ step }) => {
	let list = [
		{
			title: "Owner Information",
			desc: "Step Description goes here",
		},
		{
			title: "Pet Information",
			desc: "Step Description goes here",
		},
		// {
		// 	title: "Select Plan",
		// 	desc: "Step Description goes here",
		// }
	]

	return (
		<>
			<div className="steps-blc">
				<ul className="list-unstyled">
					{list.map((item, i) => (

						<li key={i} className={`d-flex position-relative ${step >= i + 1 ? 'active' : ''}`}>
							<span className="icon d-inline-flex align-items-center justify-content-center flex-shrink-0 rounded-circle">
								<span className="icomoon-check"></span>
							</span>
							<div className="txt">
								<h3>
									{item.title}
								</h3>
								
							</div>
						</li>
					))}
				</ul>
			</div>
		</>
	);
};

export default StepsBlock;
