import * as Yup from "yup";
// import { REGEX } from "../../../../utils/constants";
/**
 * @description declaration form schema for client validations
 */
export const enterSecurityCodeSchema = Yup.object().shape({
    code_1: Yup.string(), 
    code_2: Yup.string(),
    code_3: Yup.string(),
    code_4: Yup.string(),
    code_5: Yup.string(),
    code_6: Yup.string(),
});

/**
 * @description params intilaization 
 */

export const enterSecurityCodeInit = {
    code_1: '',
    code_2: '',
    code_3: '',
    code_4: '',
    code_5: '',
    code_6: ''
}