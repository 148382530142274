import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";
import { sendPasswordResetEmail, sendSignInLinkToEmail, signInWithEmailAndPassword, getUserByEmail, onAuthStateChanged, signOut, onIdTokenChanged } from "firebase/auth";
import { auth } from "../../firebase"
import { getClinicDetailsByUserId } from "./clinic";
import { getClinicPermission, getClinicSettingsByClinicId } from "./clinicSettings";
toast.configure();

export const register = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.post(`${apiUrl}${PORT}/register`, data, token, dispatch, getState).then(
      (response) => {
        if (response.errors) {
          switch (response.errors.msg) {
            case "EMAIL_ALREADY_EXISTS":
              toast.dismiss();
              toast.error("This email id is already registered", toasterConfig);
              break;
          }
          return callback(response);
        } else if (response) {
          toast.dismiss();
          toast.success("Account has been created Successfully", toasterConfig);
          // dispatch({ type: "REGISTER", data: response });
          // dispatch({
          //   type: "USER_DETAILS",
          //   data: response.user,
          // });
          // localStorage.setItem(
          //   "USER",
          //   JSON.stringify({ id: response.user._id })
          // );
          // localStorage.setItem("TOKEN", JSON.stringify(response.token));
          // localStorage.setItem("LOGOUT", false);
          return callback(response);
        } else {
          //toast.error(response.message);
          return callback();
        }
      }
    );
  };
};


// export const refreshToken = () => async (dispatch) => {

//   try {

//     onIdTokenChanged((userCred) => {
//       console.log('refreshToken')
//       console.log(userCred)
//       if(userCred) {
//         userCred.getIdToken().then((token) => {
//           localStorage.setItem("TOKEN", JSON.stringify(token));
//           localStorage.setItem("LOGOUT", false);
//         })
//       } else {
//         dispatch({ type: "LOGOUT", data: {} });
//         dispatch({type: "RESET_APP"})

//         localStorage.clear();
//         localStorage.setItem("LOGOUT", true);

//       }
//     })

//     onAuthStateChanged((userCred) => {
//       console.log('refreshToken')
//       console.log(userCred)
//       if(userCred) {
//         userCred.getIdToken().then((token) => {
//           //localStorage.setItem("TOKEN", JSON.stringify(token));
//           localStorage.setItem("LOGOUT", false);
//         })
//       } else {
//         dispatch({ type: "LOGOUT", data: {} });
//         //dispatch({type: "RESET_APP"})
//         localStorage.clear()

//         localStorage.setItem("LOGOUT", true);

//       }
//     })

//   } catch (error) {

//     if (error) {
//       dispatch({ type: "LOGOUT", data: {} });

//       console.log("error.code", error.code)
//       console.error(error);
//     }

//   }

// }





export const UserLoginFireAuth = (data, callback) => async (dispatch, getState) => {
  try {
    
    const userDetails = await signInWithEmailAndPassword(
      auth,
      data.email,
      data.password
    );
    if (userDetails) {
      console.log("%c Line:52 🌶 userDetails", "color:#7f2b82", userDetails);
      toast.dismiss();


      localStorage.setItem("permission", false);
      dispatch({
        type: "GET_CLINIC_PERMISSION_FOR_USER",
        payload: false,
      });
  
      // toast.success("Login Successful", toasterConfig);

      // const actionCodeSettings = {
      //   url: `http://localhost:3000/reset-password/${userDetails?.user?.uid}`,
      //   handleCodeInApp: true,
      // };

      // sendSignInLinkToEmail(auth, data.email,actionCodeSettings)
      //   .then(() => {
      //     console.log("Email send message");
      //     // The link was successfully sent
      //     // You can prompt the user to check their email for the verification link
      //     // ...
      //   })
      //   .catch((error) => {
      //     // Handle errors
      //     console.error(error.message);
      //   });
      ApiClient.get(
        `${apiUrl}${PORT}/login-user-data`,
        {},
        userDetails?.user?.accessToken, dispatch, getState
      ).then((response) => {
        if (response?.statusCode === 401) {
          toast.error(
            "User is Inactive, Please contact administrator !",
            toasterConfig
          );
          return callback();
        }
        if (!response.user.payment_verified) {
          localStorage.setItem(
            "USER",
            JSON.stringify({ id: response.user._id })
          );
        }
        // dispatch({
        //   type: "USER_DETAILS",
        //   data: response.user,
        // });
        dispatch({
          type: "LOGIN",
          data: { ...response, token: userDetails?.user?.accessToken },
        });
        dispatch({
          type: "USER_DETAILS",
          data: response.user,
        });
        if(response?.user?.role !== 'pet-owner'){
          dispatch(getClinicDetailsByUserId({}, (detail_response) => {
            if (detail_response?.status) dispatch(getClinicSettingsByClinicId({}, (setting_response)=> {
              console.log(response?.user, 'user detail role')

              if(setting_response?.status) dispatch(getClinicPermission(response?.user?.role, () => {}))
            }))
          }))}
        // localStorage.setItem(
        //   "TOKEN",
        //   JSON.stringify(userDetails?.user?.accessToken)
        // );
        localStorage.setItem("LOGOUT", false);
        if (response && !response.errors) {
          return callback(response);
        } else if (response?.code === 404) {
          toast.warn(response.message);
          return callback();
        } else {
          toast.error(response.errors.msg, toasterConfig);
          return callback();
        }
      });
      
    }
  } catch (error) {
    if (error) {
      switch (error.code) {
        case "auth/invalid-login-credentials":
          toast.dismiss();
          toast.error("Wrong Password", toasterConfig);
          break;
        default:
          break;
      }
      
    }
    console.log("error.code", error.code);
    console.error(error);
    toast.dismiss();
    toast.error("Wrong Password", toasterConfig);
    return callback();
  }
};
export const login = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.post(`${apiUrl}${PORT}/login`, data, token, dispatch, getState).then(
      (response) => {
        
        if (response && response.token) {
          toast.dismiss();
          toast.success("Login Successful", toasterConfig);
          if (!response.user.payment_verified) {
            localStorage.setItem(
              "USER",
              JSON.stringify({ id: response.user._id })
            );
          }
          dispatch({
            type: "USER_DETAILS",
            data: response.user,
          });
          dispatch({ type: "LOGIN", data: response });

          //localStorage.setItem("TOKEN", JSON.stringify(response.token));
          localStorage.setItem("LOGOUT", false);
          return callback(response);
        } else if (response.messageID === 404) {
          toast.warn(response.message, toasterConfig);
          return callback(response);
        } else {
          switch (response.errors.msg) {
            case "WRONG_PASSWORD":
              toast.dismiss();
              toast.error("Wrong Password", toasterConfig);
              break;
            case "USER_INACTIVE":
              toast.dismiss();
              toast.error(
                "User is Inactive, Please contact administrator !",
                toasterConfig
              );
              break;
            case "USER_DOES_NOT_EXIST":
              toast.dismiss();
              toast.error("User Does Not Exist");
              break;
          }
          return callback();
        }
      }
    );
  };
};

export const checkLoginFirstTime = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/check-login-first-time`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      
      if (response && !response.errors) {
        if (response.code === 422) {
          toast.dismiss();
          //toast.error(response.message, toasterConfig);
          return callback();
        } else {
          if (response && response.token && !response.payment_verified) {
            toast.dismiss();
            toast.success(
              "Login Successfully, please complete your profile to proceed further",
              toasterConfig
            );
            if (!response.user.payment_verified) {
              localStorage.setItem(
                "USER",
                JSON.stringify({ id: response.user._id })
              );
            }
            const responseData = response.user;
            if (response.total_licenses) {
              responseData.total_licenses = response.total_licenses;
            } else {
              responseData.total_licenses = 0;
            }
            responseData.license_price = response.license_price;
            // console.log(responseData);
            dispatch({
              type: "USER_DETAILS",
              data: responseData,
            });
            dispatch({ type: "LOGIN", data: response });

            //localStorage.setItem("TOKEN", JSON.stringify(response.token));
            localStorage.setItem("LOGOUT", false);
            return callback(response);
          } else {
            toast.dismiss();
            toast.success(response.message, toasterConfig);
            return callback(response);
          }
        }
      } else if (response.messageID === 404) {
        toast.warn(response.message, toasterConfig);
        return callback(response);
      } else {
        switch (response.errors.msg) {
          case "WRONG_PASSWORD":
            toast.dismiss();
            toast.error("Wrong Password", toasterConfig);
            break;
          case "USER_INACTIVE":
            toast.dismiss();
            toast.error(
              "User is Inactive, Please contact administrator !",
              toasterConfig
            );
            break;
          case "USER_DOES_NOT_EXIST":
            toast.dismiss();
            toast.error("User Does Not Exist");
            break;
          case "No_PASSWORD_FOUND":
            toast.dismiss();
            toast.error("login failed, you haven't set your password yet");
            break;
        }
        return callback();
      }
    });
  };
};

export const createSecurityCode = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/user/create-security-code`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      
      if (response) {
        toast.success(
          "Your security code has been created successfully!",
          toasterConfig
        );
        return callback(response);
      } else if (response.messageID === 404) {
        toast.warn(response.message, toasterConfig);
        return callback(response);
      } else {
        return callback(response);
      }
    });
  };
};

export const continueToDashboard = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/user/continue-to-dashboard`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      
      if (response && !response.errors) {
        dispatch({
          type: "USER_DETAILS",
          data: {
            payment_verified: true,
            role: "user",
            firstname: response?.data?.firstname,
            lastname: response?.data?.lastname,
          },
        });
        toast.success("Your registered successfully!", toasterConfig);
        return callback(response);
      } else if (response.errors) {
        toast.error(response.errors.msg, toasterConfig);

        return callback();
      } else if (response?.messageID === 404) {
        toast.warn(response.message, toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

export const checkSecurityCode = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/check-security-code`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      
      if (response) {
        return callback(response);
      } else if (response.messageID === 404) {
        toast.warn(response.message, toasterConfig);
        return callback(response);
      } else {
        return callback(response);
      }
    });
  };
};

export const resendSecurityCode = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/resend-security-code`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      
      if (response) {
        return callback(response);
      } else if (response.messageID === 404) {
        toast.warn(response.message, toasterConfig);
        return callback(response);
      } else {
        return callback(response);
      }
    });
  };
};

// export const logout = (callback) => {
//   return (dispatch) => {
//     localStorage.removeItem("USER")
//     localStorage.removeItem("TOKEN");
//     localStorage.setItem("LOGOUT", true);
//     dispatch({ type: "LOGOUT", data: {} });
//     // toast.dismiss();
//     // toast.success("Logout Successfully", toasterConfig);
//     return callback();
//   };
// };

/**
 * @description function use to logout user
 * @param {*} params accepting object
 */
export const logout = (callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    console.log('logout function gets called')
    
    ApiClient.get(`${apiUrl}${PORT}/logout`, {}, token, dispatch, getState).then(
      (response) => {
        
        if (response && !response.errors) {

          signOut(auth);

          localStorage.removeItem("USER");
          localStorage.removeItem("TOKEN");
          localStorage.clear();
          localStorage.setItem("LOGOUT", true);
          dispatch({ type: "LOGOUT", data: {} });
          // dispatch({type: 'RESET_APP'})
          return callback();
        } else if (response.code === 404) {
          toast.warn(response.message);
        } else {
          toast.error(response.errors.msg, toasterConfig);
        }
      }
    );
  };
};

export const changePassword = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    
    ApiClient.post(
      `${apiUrl}${PORT}/change-password`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      
      if (response.errors) {
        switch (response.errors.msg) {
          case "WRONG_PASSWORD":
            toast.dismiss();
            toast.error("Old password is wrong", toasterConfig);
            break;
          case "NEW_PASSWORD_SAME_WITH_OLD":
            toast.dismiss();
            toast.error(
              "New password should not match with old password",
              toasterConfig
            );
            break;
        }
        return callback();
      } else if (response) {
        toast.success("Password has been changed successfully", toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * @description function use to update user details
 * @param {*} params accepting object
 */
export const updateUserDetails = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    
    ApiClient.put(
      `${apiUrl}${PORT}/user/update-user-details`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      
      if (response.errors) {
        toast.error(response.errors.msg, toasterConfig);

        return callback(response);
      } else if (response) {
        dispatch({ type: "USER_DETAILS", data: response.user });
        toast.dismiss();
        toast.success("Profile updated successfully", toasterConfig);

        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

export const updateProfile = (data, callback) => {
  return (dispatch, getState) => {
    
    const {
      user: { token },
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/users/updateProfile`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      
      if (response.errors) {
        toast.error(response.errors.msg, toasterConfig);

        return callback();
      } else if (response) {
        dispatch({ type: "UPDATE_PROFILE", data: response });
        toast.dismiss();
        toast.success("Profile updated successfully", toasterConfig);

        return callback(response);
      } else {
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

/**
 * @description function use for to update user profile image
 * @param {*} params accepting object
 */
export const uploadProfileImage = (data, callback) => {
  return (dispatch, getState) => {
    
    const {
      user: { token },
    } = getState();
    ApiClient.putFormData(
      `${apiUrl}${PORT}/user/update-profile-picture`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      
      if (response.errors) {
        if (response.errors.msg.code === "LIMIT_FILE_SIZE") {
          toast.warn(
            `${response.errors.msg.field}: ${response.errors.msg.message}`,
            toasterConfig
          );
        } else {
          toast.warn(response.errors.msg, toasterConfig);
        }
        return callback();
      } else if (response) {
        dispatch({
          type: "USER_DETAILS",
          data: { user_image: response.user.user_image },
        });
        toast.dismiss();
        toast.success(
          "Profile Image has been updated successfully",
          toasterConfig
        );
        return callback(response);
      } else {
        //toast.error(response.message);
        return callback();
      }
    });
  };
};

/**
 * @description function use for to update organization logo
 * @param {*} params accepting object
 */
export const uploadLogo = (data, id, callback) => {
  return (dispatch, getState) => {
    
    const {
      user: { token },
    } = getState();
    ApiClient.putFormData(
      `${apiUrl}${PORT}/users/uploadLogo/${id}`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      
      if (response.errors) {
        toast.warn(response.errors.msg, toasterConfig);
        return callback();
      } else if (response) {
        dispatch({ type: "UPDATE_LOGO", data: response });
        toast.dismiss();
        toast.success("Logo has been updated successfully", toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message);
        return callback();
      }
    });
  };
};

export const unauthorized = () => {
  return (dispatch) => {
    toast.error("Unauthorized Url", toasterConfig);
  };
};

/**
 * @description function use for to delete user profile image
 * @param {*} params accepting object
 */
export const deleteProfileImage = (callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.delete(
      `${apiUrl}${PORT}/users/deleteProfileImage`,
      token,
      dispatch, getState
    ).then((response) => {
      if (response && !response.errors) {
        dispatch({ type: "UPDATE_PROFILE", data: response });
        toast.dismiss();
        toast.success(
          "Profile Image has been deleted successfully",
          toasterConfig
        );
        callback(response);
      } else if (response.code === 404) {
        toast.warn(response.message);
      } else {
        toast.error(response.errors.msg, toasterConfig);
      }
    });
  };
};

/**
 * @description function use for to delete organization logo
 * @param {*} params accepting object
 */
export const deleteOrganizationLogo = (callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.delete(
      `${apiUrl}${PORT}/users/deleteOrganizationLogo`,
      token,
      dispatch, getState
    ).then((response) => {
      if (response && !response.errors) {
        dispatch({ type: "UPDATE_LOGO", data: response });
        toast.dismiss();
        toast.success("Logo has been deleted successfully", toasterConfig);
        callback(response);
      } else if (response.code === 404) {
        toast.warn(response.message);
      } else {
        toast.error(response.errors.msg, toasterConfig);
      }
    });
  };
};

/**
 * @description function use for to forgot password
 * @param {*} params accepting object
 */
export const forgotPassword = (data, callback) => {
  return async (dispatch, getState) => {
    
    const {
      user: { token },
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/forgot-password`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      
      if (response.errors) {
        switch (response.errors.msg) {
          case "USER_DOES_NOT_EXIST":
            toast.dismiss();
            toast.error("User Does Not Exist");
            break;
          default:
            toast.dismiss();
            toast.error(response.errors.msg);
            break;
        }
        return callback();
      } else if (response) {
        toast.dismiss();
        toast.success(
          "Please check your email for password reset link.",
          toasterConfig
        );
        return callback(response);
      } else {
        return callback();
      }
    });
  };
};

/**
 * @description function use for to forgot password
 * @param {*} params accepting object
 */
export const resetPassword = (data, callback) => {
  return (dispatch, getState) => {
    
    const {
      user: { token },
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/reset-password`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      
      if (response.errors) {
        switch (response.errors.msg) {
          case "USER_DOES_NOT_EXIST":
            toast.dismiss();
            toast.error("User Does Not Exist", toasterConfig);
            break;
          case "PASSWORD_EXPIRE":
            toast.dismiss();
            toast.error(
              "Reset password time has been expired. Please try again",
              toasterConfig
            );
            break;
          case "INVALID_CODE":
            toast.dismiss();
            toast.error("Code is invalid", toasterConfig);
            break;
        }
        return callback();
      } else if (response) {
        toast.dismiss();
        toast.success("Password has been changed successfully", toasterConfig);
        return callback(response);
      } else {
        //toast.error(response.message);
        return callback();
      }
    });
  };
};

/**
 * @description function use for to forgot password
 * @param {*} params accepting object
 */
export const passwordExpired = (data, callback) => {
  return (dispatch, getState) => {
    
    const {
      user: { token },
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/password-token-expired`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      
      if (response.errors) {
        switch (response.errors.msg) {
          case "ALREADY_RESET":
            toast.dismiss();
            toast.error("You have already reset your password", toasterConfig);
            break;
          case "PASSWORD_EXPIRE":
            toast.dismiss();
            toast.error(
              "Reset password time has been expired. Please try again",
              toasterConfig
            );
            break;
        }
        return callback();
      } else if (response) {
        return callback(response);
      } else {
        //toast.error(response.message);
        return callback();
      }
    });
  };
};

/**
 * @description function use to get user general info
 * @param {*} params accepting object
 */
export const getUserGeneralInfo = (id, params, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    
    ApiClient.get(
      `${apiUrl}${PORT}/user-general-info/get-user-info/${id}`,
      params,
      token,
      dispatch, getState
    ).then((response) => {
      
      if (response && !response.errors) {
        return callback(response);
      } else if (response?.code === 404) {
        toast.warn(response.message);
        return callback();
      } else {
        toast.error(response.errors.msg, toasterConfig);
        return callback();
      }
    });
  };
};

export const checkEmailExist = (email) => (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  return ApiClient.post(
    `${apiUrl}${PORT}/get-user-by-email`,
    { email: email },
    token,
    dispatch, getState
  );
};
export const getStaffUserDetails = (id) => (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  return ApiClient.get(
    `${apiUrl}${PORT}/user-general-info/get-user-information/${id}`,
    {},
    token,
    dispatch, getState
  );
};
