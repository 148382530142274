import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Form,
  FormGroup,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
} from "reactstrap";
import { updateClinicSettings } from "../../../../../context/actions/clinicSettings";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

const EditColorCode = ({
  className = "",
  closeNewPointPanel,
  appointmentType,
  appointmentColor,
}) => {
  const [color, setColor] = useState(null);
  const [duration, setDuration] = useState(null);

  const dispatch = useDispatch();
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );

  useEffect(() => {
    setColor(appointmentColor);
  }, [className]);

  const handleUpdate = () => {
    let update = {
      [appointmentType?.value]: color,
      clinic_id: clinic_settings.clinic_id,
      update_query: "color_preferences",
    };

    dispatch(updateClinicSettings(update, () => {}));

    let update2 = {
      [appointmentType?.value]: duration ? +duration : null,
      clinic_id: clinic_settings.clinic_id,
      update_query: "appointment_types_durations",
    };

    dispatch(updateClinicSettings(update2, () => {}));
    closeNewPointPanel();
  };

  useEffect(() => {
    if (
      clinic_settings &&
      clinic_settings.appointment_types_durations &&
      appointmentType
    ) {
      setDuration(
        clinic_settings.appointment_types_durations[appointmentType.value]
      );
    }
  }, [className, clinic_settings, appointmentType]);

  return (
    <>
      <div
        className={`ocular-new-point-panel-wrapper position-fixed d-flex flex-column overflow-auto ${className}`}
      >
        <div className="head d-flex align-items-center">
          <h2>{appointmentType?.label} Preferences</h2>
          <Button
            color="link"
            className="ms-auto panel-close-btn"
            onClick={() => {
              closeNewPointPanel();
              setColor(null);
            }}
          >
            <span className="material-symbols-outlined">close</span>
          </Button>
        </div>

        <div className="inner align-items-center mt-2">
          <Form>
            <FormGroup className="input-wrapper2 mb-2">
              <div className="">
                <input
                  type="color"
                  className="form-control"
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                />
              </div>
            </FormGroup>

            <FormGroup className="input-wrapper2 mb-2">
              <div className="form-floating position-relative text-input">
                <input
                  type="number"
                  className="form-control"
                  id="duration"
                  name="duration"
                  placeholder="Slot Duration"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                />
                <label htmlFor="duration">Slot Duration</label>
              </div>
            </FormGroup>

            <div className="btn-blc border_btn mt-3 text-end">
             
              <Button
                color="success"
                onClick={() => {
                  handleUpdate();
                }}
                className="add-btn"
              >
                UPDATE
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default EditColorCode;
