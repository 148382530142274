import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import BackButton from "../../../../shared/BackButton";
import "./index.scss";

const HeaderBlock = () => {
	const history = useHistory()
	return (
		<>
			<div className="header-block d-flex align-items-center">
				<div className="left-col">
					<BackButton onClick={() => history.goBack()} label="Back to List" />
				</div>
				
			</div>
		</>
	);
};

export default HeaderBlock;
