import React from "react";
import "./index.scss";
const NoRecord = ({ className = '', label = '' }) => {
	return (
		<>
			<div className={`no-data-blc d-flex align-items-center justify-content-center text-center h-100 ${className}`}>
				<div className="inner">
					{/*<span className="d-inline-flex">
						<img className="img-fluid" src={require('../../../assets/img/icons/error-pic.png')} alt="Error" />
	                 </span>*/}
					<h2>
						<span>Oops!!</span>
						No Record Found.
						{label}
					</h2>
				</div>
			</div>
		</>
	)
}

export default NoRecord;