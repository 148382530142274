import React from "react";
import { BsChevronLeft } from "react-icons/bs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Input, Table } from "reactstrap";
import { Icons } from "utils/contants/Icons";
import "./SinglePatientReportDetails.scss";
import LoaderUI from "shared/loader";
import { titleCaseString } from "utils/common";
import NodataFoundBlock from "shared/NoDataFoundBlock";

const SinglePatientReportDetails = () => {
  const history = useHistory();
  const tableData = [];

  return (
    <div id="singlePatient-report-details-container" className="p-1 mt-1">
      <div className="row">
        <div className="col-md-3">
          <div className="">
            <div className="d-flex">
              <div
                className="d-flex gap-2 align-items-center cmb-20 pointer"
                onClick={() => history.push("/reports/patient-records-report")}
              >
                <BsChevronLeft color="color-3EFD" />
                <span className="text-13-600 color-3EFD">VIEW ALL REPORTS</span>
              </div>
            </div>

            <div className="bg-filter-class">
              <div className="text-24-700 color-2E65">Configure Report</div>
              <div className="text-14-400 color-8693">
                Select filter and generate report
              </div>
              <div className="filter-block cmt-20 cmb-20">
                <div className="cmb-10">
                  <Input
                    type="select"
                    name="select"
                    // value={filterData?.speciesId}
                    placeholder="All Species"
                    onChange={() => {}}
                    // onChange={(e) => {
                    //   setFilterData((prev) => ({
                    //     ...prev,
                    //     speciesId: e?.target?.value,
                    //   }));
                    //   if (e?.target?.value) {
                    //     dispatch(getAllBreeds(e?.target?.value, () => {}));
                    //   }
                    // }}
                  >
                    <option value="">All</option>
                    {/* {species?.map((e, i) => {
                      return (
                        <option value={e?._id} key={i} id={e?._id}>
                          {e?.species_name}
                        </option>
                      );
                    })} */}
                  </Input>
                </div>
              </div>
              <div className="cmb-15">
                <div className="text-center color-2E65 text-13-600 cmt-20 pointer">
                  RESET FILTER
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-9 details-section">
          <div className="cpt-20 cpe-20 cpb-20">
            <div className="d-flex justify-content-between align-items-center cmb-20">
              <div>
                <div className="text-24-700 color-2E65">Patient Records</div>
                <p className="text-14-400 color-8693 text-wrap">
                  Appointment Schedule reports help track the clinic’s medical
                  supplies, medications, and equipment to ensure that everything
                  is well-stocked and up to date.
                </p>
              </div>
              <div className="d-flex gap-2 br-8">
                <div
                  className="cp-8 bg-8C9F28 pointer"
                  //   onClick={() => {
                  //     handleDownload();
                  //   }}
                >
                  <img src={Icons.download} alt="download" />
                </div>
                <div className="cp-8 bg-8C9F28 br-8">
                  <img src={Icons.share} alt="share" />
                </div>
              </div>
            </div>

            <div className="cmb-20 card-block row flex-wrap gy-3">
              <img src={Icons?.imGraph} alt="graph" className="w-100 h-100" />
            </div>

            <div className="table-one cmb-20">
              <div className="text-14-600 color-2E65 cmb-10">Petient</div>

              <Table responsive>
                <thead>
                  <tr>
                    <th>Patient Info.</th>
                    <th>VRN</th>
                    <th>Status</th>
                    <th>Wellness Plan</th>
                    <th>Animal</th>
                    <th>Breed</th>
                    <th>Gender</th>
                    <th>Owner</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.loading && (
                    <tr className="no-data-rw">
                      <td
                        colSpan="8"
                        className="d-flex justify-content-center cpt-50 cpb-50"
                      >
                        <LoaderUI />
                      </td>
                    </tr>
                  )}
                  {!tableData?.loading && tableData?.data?.docs?.length > 0 ? (
                    tableData?.data?.docs?.map((dataElem, dataIndex) => {
                      const {
                        _id,
                        pet_name,
                        pet_image,
                        vrn,
                        active,
                        speciesDetails,
                        breedDetails,
                        gender,
                        petOwner,
                      } = dataElem;

                      return (
                        <tr key={dataIndex}>
                          <td
                            className="text-nowrap"
                            onClick={() => {
                              history(
                                `/reports/patient-records-report/details/${_id}`
                              );
                            }}
                          >
                            <div className="d-flex gap-2">
                              <div className="pic d-inline-flex flex-shrink-0">
                                {pet_image ? (
                                  <img
                                    className="img-fluid w-100 h-100"
                                    src={pet_image}
                                    alt="Product"
                                    style={{
                                      borderRadius: "0",
                                    }}
                                  />
                                ) : (
                                  <img
                                    className="img-fluid w-100 h-100"
                                    src={Icons?.productThumbnailSsquare}
                                    alt="Product"
                                    style={{
                                      borderRadius: "0",
                                    }}
                                  />
                                )}
                              </div>
                              <div>{titleCaseString(pet_name)}</div>
                            </div>
                          </td>
                          <td>{vrn}</td>
                          <td className="text-nowrap">{active && "Active"}</td>
                          <td className="text-nowrap">Golden care plan</td>
                          <td className="text-nowrap">
                            {titleCaseString(speciesDetails?.species_name)}
                          </td>
                          <td className="text-nowrap">
                            {titleCaseString(breedDetails?.breed)}
                          </td>
                          <td className="text-nowrap">
                            {titleCaseString(gender)}
                          </td>
                          <td className="text-nowrap">
                            {titleCaseString(petOwner?.owner_name)}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="no-data-rw">
                      <td colSpan="11">
                        <NodataFoundBlock />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {/* <PaginationBlock
                limit={limit}
                page={page}
                pagingCounter={tableData?.data?.pagingCounter || 1}
                docLength={tableData?.data?.docs?.length || 0}
                docPage={tableData?.data?.page || 1}
                totalPages={tableData?.data?.totalPages || 1}
                totalDocs={tableData?.data?.totalDocs || 0}
                _handleLimit={(val) => {
                  setLimit(val);
                }}
                _handlePageChange={setPage}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinglePatientReportDetails;
