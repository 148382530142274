import React, { useEffect } from "react";

import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import ClinicProfile from "../../../../shared/ClinicProfile";
import Settings from "../../../clinic-module/clinic-settings"
import { Button } from "reactstrap";
import { sidebarPanelAction } from "../../../../context/actions/sidebarPanelAction";

const PatientDetail = () => {
  const dispatch = useDispatch();


  const sidebarPanelState = useSelector(
    (state) => state.sidebarPanelReducer.isSidePanel
  );
  const showSidePanel = () => {
    dispatch(sidebarPanelAction(!sidebarPanelState));
  };
  return (
    <>
      <div className="page-wrapper ">
        <div className="page-header-wrapper d-flex flex-shrink-0 toggle">
          <Button
            className="sidebar-toggle-btn d-inline-flex align-items-center"
            color="link"
            onClick={showSidePanel}
          >
            <span className="material-symbols-outlined">menu</span>
          </Button>
        </div>
        <div className="clinic-detail-wrapper position-relative d-flex flex-row overflow-auto super-setting">
          <div className="left-col clinic-profile d-flex flex-column overflow-auto">
            <ClinicProfile />
          </div>
          <div className="right-col setting-tab d-flex flex-fill overflow-auto">
            <Settings />
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientDetail;
