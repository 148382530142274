const initState = {
    loading: true,
    petDocumentList: false,
    openPetDocumentAddEditPanel: false
}

export const petDocumentReducer = (state = initState, action) => {
    switch (action.type) {
        case "PET_DOCUMENT_LOADING":
            return { ...state, loading: action.payload }

        case "PET_DOCUMENT_LIST":
            return { ...state, petDocumentList: action.payload }

        case "OPEN_CLOSE_ADD_EDIT_PET_DOCUMENT_PANEL":
            return { ...state, openPetDocumentAddEditPanel: action.payload }

        default:
            return state
    }
}