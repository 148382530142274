import React from "react";
import plusicongreen from "../../../assets/img/plusicongreen.png";
import { FaChevronDown } from "react-icons/fa";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClinicSettingsByClinicId } from "context/actions/clinicSettings";
import { getClinicDetailsByUserId } from "context/actions/clinic";

const PetDropdown = () => {
  const myRef = useRef();
  const dispatch = useDispatch();
  const { petOwnerPetList, petDetails } = useSelector(
    (state) => state.petOwnerDashboardReducer
  );

  const [show, setShow] = useState(false);

  const handleSelectPet = (data) => {
    dispatch({
      type: "SET_PET_DETAILS",
      payload: data || null,
    });

    if(data && data?.primary_clinic){
      console.log('getting petDetails')
      dispatch(getClinicSettingsByClinicId(
        {id: data?.primary_clinic}, 
        () => {}))
    }
  };

  const handleClickOutside = (e) => {
    if (myRef && myRef?.current && !myRef.current.contains(e.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  useEffect(() => {
    if (petOwnerPetList?.length > 0 && !petDetails?._id) {
      dispatch({
        type: "SET_PET_DETAILS",
        payload: petOwnerPetList[0],
      });
    
        if(petOwnerPetList[0] && petOwnerPetList[0]?.primary_clinic){
          console.log('getting petDetails')
          dispatch(getClinicDetailsByUserId({}))
          dispatch(getClinicSettingsByClinicId(
            {id: petOwnerPetList[0]?.primary_clinic}, 
            () => {}))
        }
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petOwnerPetList]);

  const { _id = "", pet_image, pet_name } = petDetails || {};

  return (
    <>
    <div className="position-relative">
      {petOwnerPetList?.length > 0 && (
        <div
          id="pet-dropdown-container"
          onClick={() => {
            setShow(!show);
          }}
        >
          <div className="f-center bg-eeee color-cfcf h-32 w-32 rounded">
            {pet_image ? (
              <img src={pet_image} alt="pet-img" />
            ) : (
              <span className="material-symbols-outlined">
                sound_detection_dog_barking
              </span>
            )}
          </div>
          <div className="text-16-400">{pet_name}</div>
          <div className="flex-grow-1 d-flex justify-content-end">
            <FaChevronDown color="#3D3EFD" />
          </div>
        </div>
      )}
      {show && (
        <div className="pet-dropdown-popover shadow" ref={myRef}>
          {petOwnerPetList.map((elm, index) => {
            return (
              <div
                key={index}
                className={`d-flex align-items-center gap-2 ${
                  _id === elm?._id ? "active-op" : ""
                }`}
                onClick={() => {
                  if (_id !== elm?._id) {
                    handleSelectPet(elm);
                  }
                  setShow(false);
                }}
              >
                <div className="f-center bg-eeee color-cfcf h-32 w-32 rounded pet-img">
                  {elm?.pet_image ? (
                    <img src={elm?.pet_image} alt="pet-img" />
                  ) : (
                    <span className="material-symbols-outlined">
                      sound_detection_dog_barking
                    </span>
                  )}
                </div>
                <div className="flex-grow-1 pet-info">
                  <div style={{textWrap: 'pretty', wordBreak: 'break-all'}}className="text-16-400">{elm?.pet_name || ""}</div>
                  <div className="text-13-400">VRN: {elm?.vrn || ""}</div>
                </div>
              </div>
            );
          })}
          <div onClick={() => {
          dispatch({
            type: "SET_CAN_HIDE_NEW_PET_MODAL",
            payload: true,
          })?.then(  
          dispatch({
            type: "SET_NEW_PET_MODAL",
            payload: true,
          }));
          }} className="add-block">
            <div className="d-flex">
              <img src={plusicongreen} alt="add" className="hw-19" />
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default PetDropdown;
