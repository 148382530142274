import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import Swal from 'sweetalert2';
import { Spinner } from "react-bootstrap";

import { apiUrl, PORT,  } from "environment";
export default function Payment({clientSecret = "", callback = () => {}}) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe, clientSecret]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: window.location.href,
      },
      redirect: "if_required"

    });
    console.log(paymentIntent)
    console.log(error)

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.

    if (error?.type === "card_error" ) {
      Swal.fire({
        title: "<strong>Policy Purchase Error</strong>",
        icon: "warning",
        html: `${error?.message}`,
        focusConfirm: false,
        confirmButtonText:"Ok",
        confirmButtonAriaLabel: "Ok",
       
      })
    } else if(error?.type === "validation_error"){

    
    
    }
    
    
    else if(error) {
      Swal.fire({
        title: "<strong>Policy Purchase Error</strong>",
        icon: "warning",
        html: `An unexpected error occurred. Please try again`,
        focusConfirm: false,
        confirmButtonText:"Ok",
        confirmButtonAriaLabel: "Ok",
       
      })
      setMessage("An unexpected error occurred.");
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      setMessage("Payment succeeded!");
      callback(paymentIntent)

      // handleSuccess();
    } else {
      setMessage("Your payment was not successful, please try again.");
      // handleOther();
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>

      <PaymentElement id="payment-element" options={paymentElementOptions} />

      {/* {message && <div id="payment-message">{message}</div>} */}

      <div className='d-flex flex-row align-items-center justify-content-between w-100'>
        <div className="d-flex flex-row align-items-center">
        <img style={{width: 89, height: 89}} src={require('../../assets/img/vertical/Vi-logo.png')} />

            <div className='d-flex flex-col w-100' >
              <span style={{color: 'black', fontSize: 20, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Provided by Vertical Insure, Inc.
              </span>
              <span style={{color: 'black', fontSize: 16, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>support@verticalinsure.com</span>
            </div>
          </div>
            <button  disabled={isLoading || !stripe || !elements} style={{maxWidth: 278, width: '100%', padding: '15px', height: 60, background: '#304AF5', borderRadius: 15,  left: 0, top: 6, textAlign: 'center', color: 'white', fontSize: 20, fontFamily: 'Inter', fontWeight: '700', wordWrap: 'break-word'}} type="submit"><span id="button-text">
          {isLoading ? <Spinner /> : "PURCHASE"}
        </span></button>
        </div> 
      
      {/* Show any error or success messages */}
    </form>
  );
}