import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Table } from "reactstrap";
import {
  updatePatientIntakeForm,
  deletePatientIntakeForm,
} from "context/actions/patientIntakeForm";
import { getFormAndTemplates } from "context/actions/formTemplates";
import SwitchCell from "shared/SwitchCell";
import { useSelector } from "react-redux";
import { RiDeleteBin5Line } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import PatientIntakeForm from "./PatientIntakeForm/PatientIntakeForm";

const PatientIntakeFormBlock = ({ list }) => {
  const dispatch = useDispatch();
  const [isAdd, setIsAdd] = useState(false);
  const [editData, setEditData] = useState(null);

  const handleUpdateFormStatus = (item) => {
    const formData = new FormData();
    formData.append("template_id", item._id);
    formData.append("is_active", !item.is_active);
    formData.append("pet_specie_id", item.pet_specie_id);
    formData.append("form_type", item.form_type);
    formData.append("form_title", item.form_title);
    formData.append("form_notes", item.form_notes);
    dispatch(
      updatePatientIntakeForm(formData, (res) => {
        if (res?.statusCode === 200) {
          getData();
        }
      })
    );
  };

  const handleDelete = async (id) => {
    let response = await dispatch(
      deletePatientIntakeForm({
        template_id: id,
        is_deleted: true,
      })
    );
    if (response?.statusCode === 200) {
      getData();
    }
  };

  // const handleDownload = (url) => {
  //   const downloadLink = document.createElement("a");
  //   downloadLink.href = url.replace("http:/", "http://");
  //   const fileName = url.split("/").pop();
  //   downloadLink.download = fileName;
  //   downloadLink.target = "_blank";
  //   document.body.appendChild(downloadLink);
  //   downloadLink.click();
  //   document.body.removeChild(downloadLink);
  // };

  const getData = () => {
    dispatch(getFormAndTemplates({}, () => { }));
  };

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  return (
    <>
      {isAdd && (
        <PatientIntakeForm
          editData={editData}
          onHide={() => {
            setIsAdd(false);
            setEditData(null);
          }}
        />
      )}

      <div className="form-template-blc">
        <div className="head d-flex">
          <div className="left-col">
            <h3>Patient Intake Forms</h3>
            <p></p>
          </div>
          {permissions?.add_patient_intake_forms && (
            <div className="right-col ms-auto">
              <Button
                onClick={() => {
                  setIsAdd(true);
                }}
                color="link"
                className="d-inline-flex align-items-center justify-content-center add-btn p-0"
              >
                <span className="icomoon-plus-btn"></span>
              </Button>
            </div>
          )}
        </div>
        {(list || []).length > 0 && (
          <div className="table-listing-blc overflow-auto">
            <Table responsive className="checkbox-listing">
              <thead>
                <tr>
                  <th style={{ minWidth: "150px" }}>Name</th>
                  <th style={{ minWidth: "150px" }}>Species</th>
                  <th style={{ minWidth: "200px" }}>Title</th>
                  <th style={{ width: "100%" }}>Notes</th>
                  <th>Active</th>
                  {permissions?.edit_patient_intake_forms && (
                    <th className="text-center">Action</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {(list || []).map((item, index) => (
                  <tr key={index}>
                    <td>{item.form_type}</td>
                    <td>{item?.speciesDetails?.species_name || ""}</td>
                    <td>{item.form_title}</td>
                    <td>{item.form_notes}</td>
                    <td>
                      <SwitchCell
                        className={item.is_active ? "" : "disable"}
                        onClick={() => {
                          permissions?.edit_patient_intake_forms &&
                            handleUpdateFormStatus(item);
                        }}
                      />
                    </td>
                    {permissions?.edit_patient_intake_forms && (
                      <td className="action-cell text-center">
                        {item?.form_url && (
                          <Button
                            onClick={() => {
                              dispatch({
                                type: "SET_FILE_VIEWER_DATA",
                                data: {
                                  title: item.form_title,
                                  fileURL: item?.form_url,
                                },
                              });
                            }}
                            color="link"
                            className="edit-btn"
                          >
                            <MdOutlineRemoveRedEye size={18} />
                          </Button>
                        )}
                        {/* {item?.form_url && (
                          <Button
                            onClick={() => {
                              handleDownload(item?.form_url);
                            }}
                            color="link"
                            className="edit-btn"
                          >
                            <TbCloudDownload size={18} />
                          </Button>
                        )} */}
                        <Button
                          color="link"
                          className="edit-btn"
                          onClick={() => {
                            setIsAdd(true);
                            setEditData(item);
                          }}
                        >
                          <MdEdit size={18} />
                        </Button>

                        <Button
                          onClick={() => {
                            handleDelete(item?._id);
                          }}
                          color="link"
                          className="delete-btn"
                        >
                          <RiDeleteBin5Line size={18} />
                        </Button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        {/* <NewPatientIntakeFormPopup
          handleSubmit={handlePatientIntakeFormSubmit}
          formValues={selectedRow}
          isOpen={isNewIntakeFormModalOpen}
          toggle={() => setIsNewIntakeFormModalOpen(!isNewIntakeFormModalOpen)}
        /> */}
      </div>
    </>
  );
};

export default PatientIntakeFormBlock;
