import React, { useState } from "react";
import { Button, Table, ButtonDropdown, DropdownToggle } from "reactstrap";
import { RiDeleteBin2Line } from "react-icons/ri";
import "./index.scss";
import { useSelector } from "react-redux";

const Preferences = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const inventoryData = [
    {
      product_name: "Amoxicilin",
      sku: "K56-AS88",
      category: "Medications",
      commission: "10",
    },
  ];

  const serviceData = [
    {
      service_name: "WBC Test",
      sku: "K56-AS88",
      category: "Medications",
      commission: "10",
    },
  ];

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  return (
    <>
      <div className="preferences-wrapper">
        <div className="preferences-section">
          <div className="left-col w-[25%]">
            <div className="header">Commissions</div>
            <div className="description"></div>
          </div>
          <div className="right-col w-[75%]">
            <div className="table-wrapper">
              <div className="header">
                <div className="left-col">
                  <h3>Services</h3>
                </div>
                {permissions?.edit_staff_management && (
                  <div className="right-col ms-auto d-inline-flex align-items-end">
                    <Button onClick={() => {}} color="link" className="add-btn">
                      <i className="icomoon-plus-btn"></i>
                    </Button>
                  </div>
                )}
              </div>

              <Table style={{ alignSelf: "stretch" }} striped responsive>
                <thead>
                  <tr>
                    <th>Service Name</th>
                    <th>Service Code</th>

                    <th>Commision %</th>
                    <th className="text-end">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {serviceData?.map((item, i) => (
                    <tr key={i}>
                      <td>
                        <Button
                          color="link"
                          className="p-0 text-start pic-cell pic-cell-type-2 d-inline-flex align-items-center w-100 truncate_btn"
                          onClick={() => {
                            // history.push(`/product-detail/${item?._id}`);
                          }}
                        >
                          <span>{item?.service_name}</span>
                        </Button>
                      </td>
                      <td>{item?.sku}</td>

                      <td>{item?.commission}%</td>

                      <td>
                        {permissions?.edit_staff_management && (
                          <div className="action-cell d-flex">
                            
                            <button className=" text-gray-400 hover:text-gray-500">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-6 h-6"
                              >
                                <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
                              </svg>
                            </button>
                            <button className=" text-gray-400 hover:text-gray-500">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="table-wrapper">
              <div className="header">
                <div className="left-col">
                  <h3>Products & Medicine</h3>
                </div>
                {permissions?.edit_staff_management && (
                  <div className="right-col ms-auto d-inline-flex align-items-end">
                    <Button onClick={() => {}} color="link" className="add-btn">
                      <i className="icomoon-plus-btn"></i>
                    </Button>
                  </div>
                )}
              </div>

              <Table style={{ alignSelf: "stretch" }} striped responsive>
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>SKU</th>
                    <th>Category</th>

                    <th>Commision %</th>
                    <th className="text-end">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {inventoryData?.map((item, i) => (
                    <tr key={i}>
                      <td>
                        <Button
                          color="link"
                          className="p-0 text-start pic-cell pic-cell-type-2 d-inline-flex align-items-center w-100 truncate_btn"
                          onClick={() => {
                            // history.push(`/product-detail/${item?._id}`);
                          }}
                        >
                          <div className="pic d-inline-flex flex-shrink-0">
                            {item?.image ? (
                              <img
                                className="img-fluid w-100 h-100"
                                src={item?.image}
                                alt="Product"
                                style={{
                                  borderRadius: "0",
                                }}
                              />
                            ) : (
                              <img
                                className="img-fluid w-100 h-100"
                                //src={require("../../../assets/img/vetRecorEMR/product-thumbnail-square.png")}
                                alt="Product"
                                style={{
                                  borderRadius: "0",
                                }}
                              />
                            )}
                          </div>
                          <span>{item?.product_name}</span>
                        </Button>
                      </td>
                      <td>{item?.sku}</td>

                      <td>{item?.category}</td>
                      <td>{item?.commission}%</td>

                      <td>
                        {permissions?.edit_staff_management && (
                          <div className="action-cell d-flex">
                           
                            <button className=" text-gray-400 hover:text-gray-500">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-6 h-6"
                              >
                                <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
                              </svg>
                            </button>
                            <button className=" text-gray-400 hover:text-gray-500">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Preferences;
