import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import "./index.scss";
import { AiOutlineSearch } from "react-icons/ai";
import { VscListUnordered } from "react-icons/vsc";
import { BsCircle } from "react-icons/bs";
import { MdMoving } from "react-icons/md";

import { MdCloudDownload } from "react-icons/md";
import { BsThreeDots } from "react-icons/bs";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TotalRevenueInvoice from "./totalRevenueInvoice";

import CheckBox from "../../../../../shared/CheckBox";
import SwitchCell from "../../../../../shared/SwitchCell";
import { PORT, apiUrl } from "../../../../../environment";
import NodataFoundBlock from "../../../../../shared/NoDataFoundBlock";
import { getInvoicesByPetId } from "context/actions/invoice";

const BackDrop = (props) => {
  return <div className="backdrop" onClick={props.closeDrawer}></div>;
};

export default function Invoices({ petId }) {
  const dispatch = useDispatch()
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const history = useHistory();
  const { user_details, token } = useSelector((state) => state.user);

  function handleOpenDrawerButton(invoiceData) {
    setDrawerOpen(!drawerOpen);
    setSelectedInvoice(drawerOpen ? null : invoiceData ? invoiceData : null);
  }
  function handleBackdropClick() {
    setDrawerOpen(false);
    setSelectedInvoice(null);
  }

  const formateDate = (date) => {
    let todayDate = new Date(date);
    let month = todayDate.getMonth() + 1;
    let formatted =
      todayDate.getFullYear() + "/" + month + "/" + todayDate.getDate();
    return formatted;
  };

  useEffect(() => {
    dispatch(getInvoicesByPetId(petId, (data) => {
      setInvoices(data?.response_data);
    }))
  }, []);

  return (
    <div>
      <div className="p-3 flex  align-items-center gap-y-3 justify-between">
        <div>
          <p className="text-[20px] text-[#282E65] font-[600]">Invoices</p>
        </div>
        {/* <div className="flex gap-2 items-center">
          <button
            type="button"
            className=" text-white bg-[#11CA9D] h-auto py-2 hover:bg-[#11CA9D]  font-medium rounded-lg text-sm px-4 py-2.5 mr-2 mb-2 uppercase shadow-md"
            onClick={() => history.push("/invoices/add-invoice")}
          >
            Add New
          </button>
        </div> */}
      </div>

      <div className="flex flex-col md:flex-row gap-2 px-2 sm:px-4 md:p-3 justify-between items-start flex-wrap">
        <div className="flex gap-2 lg:items-center flex-col lg:flex-row flex-wrap">
          <div className="relative w-full lg:w-52">
            <input
              type="text"
              id="first_name"
              className="bg-[#fff] w-full pl-11 border border-gray-300 text-gray-900 text-sm rounded-lg h-11 focus:outline-none"
              placeholder="Search"
              required
            />
            <span className="absolute top-[9px] left-[4px] pl-[5px]">
              <AiOutlineSearch size={25} color="#788693" />
            </span>
          </div>
          <div>
              <Input
                type="select"
                name="select"
                className="h-[42px] w-full md:w-52"
              >
                <option>Last 7 Days</option>
                <option>Last 2 days</option>
              </Input>
            </div>
            <div>
              <Input
                type="select"
                name="select"
                className="h-[42px] w-full md:w-52"
              >
                <option>All Status</option>
                <option>Partially paid</option>
                <option>fully paid</option>
              </Input>
            </div>
            <div>
              <Input
                type="select"
                name="select"
                className="h-[42px] w-full md:w-52"
              >
                <option>All Mode</option>
                <option>Cash</option>
                <option>Paypal</option>
                <option>Credit Card</option>
              </Input>
            </div>
            <div className="border-0 md:border-[2px]  md:rounded-lg md:px-2 py-2 show-status-toggle">
              <SwitchCell labelVisible={true} label={"Show Status"} />
            </div>
          
        </div>
        <div className="self-end md:self-start">
          <span className="border text-[14px] border-gray rounded-lg  font-['Inter'] flex items-center gap-1 px-[5px] py-2.5 mr-2">
            <VscListUnordered size={20} />
            Columns{" "}
          </span>
        </div>
      </div>
      {/*
      <div className="flex px-2 gap-2 invoice_stats">
        <InvoiceStatsItem
          bgClass={"background-dark"}
          stats="$52,800"
          statsIcon={<BsCircle size={30} color="#fff" />}
          subHeading="Total Revenue"
          handelOpnInvoiceDrawer={handleOpenDrawerButton}
        />
        <InvoiceStatsItem
          bgClass={"bg-grey"}
          subHeadingText="text-[#282E65]"
          stats="$10K"
          statsIcon={<MdMoving size={20} color="#11CA9D" />}
          statsPercent="+50%"
          subHeading="Video Call "
        />
        <InvoiceStatsItem
          bgClass={"bg-grey"}
          subHeadingText="text-[#282E65]"
          stats="$4500"
          statsIcon={<MdMoving size={20} color="#11CA9D" />}
          statsPercent="+25%"
          subHeading="Video Conference"
        />
        <InvoiceStatsItem
          bgClass={"bg-grey"}
          subHeadingText="text-[#282E65]"
          stats="$2800"
          statsIcon={<MdMoving size={20} color="#11CA9D" />}
          statsPercent="-10%"
          subHeading="Phone Call"
        />
        <InvoiceStatsItem
          bgClass={"bg-grey"}
          subHeadingText="text-[#282E65]"
          stats="$19500"
          statsIcon={<MdMoving size={20} color="#11CA9D" />}
          statsPercent="+30%"
          subHeading="In Clinic"
        />
      </div>
      */}
      <div>
        {drawerOpen ? (
          // <TotalRevenue
          //   show={drawerOpen}
          //   cancel={drawerOpen}
          //   setCancel={setDrawerOpen}
          // />
          <TotalRevenueInvoice
            show={drawerOpen}
            cancel={drawerOpen}
            setCancel={setDrawerOpen}
            invoiceData={selectedInvoice}
          />
        ) : (
          ""
        )}

        {drawerOpen && <BackDrop closeDrawer={handleBackdropClick} />}
      </div>
      <div className="min-h-96">
        <div className="relative p-3 sm:rounded-lg overflow-x-auto table-responsive">
          {invoices?.length > 0 ? (
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 table">
              <thead className="text-xs text-gray-700   dark:text-gray-400">
                <tr className="text-[#788C9F] border-b ">
                  <th
                    scope="col "
                    className="px-2 py-2 flex items-center"
                    style={{ minWidth: "130px" }}
                  >
                    <CheckBox
                      type="checkbox"
                      ischecked={false}
                      className="no-label"
                    />
                    <label
                      for="default-checkbox"
                      className="ml-2 text-sm font-bold text-[#788C9F]"
                    >
                      Order ID
                    </label>
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-2"
                    style={{ minWidth: "90px" }}
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-2"
                    style={{ minWidth: "130px" }}
                  >
                    Processed on
                  </th>

                  <th
                    scope="col"
                    className="px-2 py-2"
                    style={{ minWidth: "130px" }}
                  >
                    Date Due
                  </th>

                  <th
                    scope="col"
                    className="px-2 py-2"
                    style={{ minWidth: "100px" }}
                  >
                    Payer
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-2"
                    style={{ minWidth: "90px" }}
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-2"
                    style={{ minWidth: "130px" }}
                  >
                    Payment Method
                  </th>
                </tr>
              </thead>
              <tbody>
                {invoices?.map((data) => {
                  return (
                    <tr className="bg-[#E4E4E447] ">
                      <td className="flex items-center ml-2 py-2 px-2 owner-invoice-list" style={{borderBottom:"0"}}>
                        <CheckBox
                          type="checkbox"
                          ischecked={false}
                          className="no-label"
                        />
                        <label
                          for="default-checkbox"
                          className="ml-2 text-sm font-medium text-[#3D3EFD] cursor-pointer"
                          onClick={() => handleOpenDrawerButton(data)}
                        >
                          OD{data?._id.substring(5, 12)}
                        </label>
                      </td>
                      <td className="px-2 py-2 text-[#282E65]">
                        ${data.totalAmount}
                        {/* {data?.products.map((product, i) => {
                          let total = 0;
                          total = data?.products[i]?.amount + total;
                          return total;
                        })} */}
                      </td>
                      <td className="px-2 py-2 text-[#282E65]">
                        {formateDate(data?.issued_date)}
                      </td>
                      <td className="px-2 py-2 text-[#282E65]">
                        {formateDate(data?.due_date)}
                      </td>
                      <td className="px-2 py-2 flex items-center gap-1 text-[#282E65]">
                        <img
                          className="w-[35px] h-[35px] rounded-full"
                          src={data?.payer_detail[0]?.user_image}
                          alt="user"
                        />
                        <span className="text-[14px]" style={{wordBreak:"normal"}}>
                          {data?.payer_detail[0]?.firstname +
                            " " +
                            data?.payer_detail[0]?.lastname}
                        </span>
                      </td>
                      <td className="px-2 py-2">
                        <span
                          className={`status status-${data?.status?.replace(
                            /\s+/g,
                            "-"
                          )}`}
                        >
                          {data?.status}
                        </span>
                      </td>
                      <td className="px-2 py-2  w-[282px]">
                        <p className="flex w-full justify-between text-[#282E65]" style={{whiteSpace:"nowrap"}}>
                          Credit Card{" "}
                          <span className="flex gap-1  ms-2">
                            <MdCloudDownload size={23} color="#788C9F" />{" "}
                            <BsThreeDots size={23} color="#788C9F" />
                          </span>
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <NodataFoundBlock />
          )}
        </div>
      </div>
    </div>
  );
}
