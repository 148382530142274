import React, { useEffect, useState } from "react";
import {
  FormGroup,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  Button,
} from "reactstrap";
import ToogleItemBlock from "../../../../shared/ToogleItemBlock";
import FormBlock from "./FormBlock";
import CheckBox from "../../../../shared/CheckBox";
import BrowseFileInput from "../../../../shared/BrowseFileInput";
// import "./index.scss";
import BillingPreferences from "./BillingPreferences";
import LegalInfoFormBlock from "./LegalInfoFormBlock";
import InvoicePreferences from "./InvoicePreferences";
import BrandLogo from "./BrandLogo";
import { useDispatch, useSelector } from "react-redux";
import { updateClinicSettings } from "../../../../context/actions/clinicSettings";
import { getAllRace } from "../../../../context/actions/app-content";
import {
  doctorNameOnPortalList,
  lateNotificationCycles,
  weekdays,
} from "../../../../utils/constants";
import NodataFoundBlock from "../../../../shared/NoDataFoundBlock";
import LabReport from "./LabReport";

const ProfileSettings = () => {
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const [lateNotificationDropdown, setLateNotificationDropdown] =
    useState(false);
  const [raceDropdown, setRaceDropdown] = useState(false);
  const [weekDropdown, setWeekDropdown] = useState(false);

  const dispatch = useDispatch();
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );
  const { races } = useSelector((state) => state.appContentReducer);

  useEffect(() => {
    dispatch(getAllRace({}, () => {}));
  }, []);

  const handleUpdateSettings = (data) => {
    let body = {
      ...data,
      clinic_id: clinic_settings.clinic_id,
      update_query: "other_settings",
    };
    dispatch(updateClinicSettings(body, () => {}));
  };

  const handleUpdateNameLabel = (val) => {
    handleUpdateSettings({ doctor_name_format: val });
  };
  const handleUpdateLateNotificationCycle = (val) => {
    handleUpdateSettings({ late_notification_cycle: val });
  };
  const handleUpdateCalendarWeekStartDay = (val) => {
    handleUpdateSettings({ calendar_start_day: val?.toLowerCase() });
  };
  const handleUpdateCaptureRase = (val) => {
    handleUpdateSettings({ capture_race_details: val });
  };

  const handleRaceById = (races, id) => {
    if (races) {
      return races[races.findIndex((e) => e._id === id)]?.race || "";
    } else {
      return "";
    }
  };

  return (
    <>
      {!clinic_settings ? (
        <NodataFoundBlock />
      ) : (
        <div className="profile-setting-tab">
          <div className="tab-row d-flex">
            <div className="tab-col lft">
              <div className="info-block">
                <h3>General Information</h3>
              </div>
              {/* <div className="msg-rw d-flex align-items-center">
                <span className="icomoon-alert"></span>
                Domian name is required
              </div> */}
            </div>
            <div className="tab-col flex-fill">
              <div className="inner">
                <FormBlock />
              </div>
            </div>
          </div>
          {/* <div className="tab-row d-flex">
            <div className="tab-col lft">
              <div className="info-block">
                <h3>Billing Preferences</h3>
              </div>
            </div>
            <div className="tab-col flex-fill">
              <div className="inner0">
                <BillingPreferences />
              </div>
            </div>
          </div> */}
          <div className="tab-row d-flex">
            <div className="tab-col lft">
              <div className="info-block">
                <h3>Legal Information</h3>
              </div>
            </div>
            <div className="tab-col flex-fill">
              <div className="inner">
                <LegalInfoFormBlock />
              </div>
            </div>
          </div>
          <div className="tab-row d-flex">
            <div className="tab-col lft">
              <div className="info-block">
                <h3>Lab Reports</h3>
              </div>
            </div>
            <div className="tab-col flex-fill">
              <div className="inner">
                <LabReport />
              </div>
            </div>
          </div>
          <BrandLogo />
          {/* <InvoicePreferences /> */}

          <div className="tab-row d-flex">
            <div className="prefer-setting-blc">
              <div className="row-block d-flex align-items-center">
                <label>How do you want to show doctor name on portal?</label>
                <div className="form-item-blc d-flex align-items-center flex-fill">
                  <div className="form-group-wrapper d-flex">
                    <FormGroup className="input-wrapper2 no-floating flex-fill">
                      <div className="form-floating position-relative text-input">
                        <input
                          disabled
                          type="text"
                          className="form-control"
                          id="reply"
                          placeholder="Enter First Name"
                          value={
                            clinic_settings?.doctor_name_format == 0
                              ? "Lastname, Firstname"
                              : "Firstname, Lastname"
                          }
                        />
                      </div>
                    </FormGroup>
                    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                      <DropdownToggle color="link">
                        <i className="icomoon-angle-down"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        {doctorNameOnPortalList.map((item) => (
                          <DropdownItem
                            onClick={() => handleUpdateNameLabel(item.value)}
                          >
                            {item.label}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </ButtonDropdown>
                  </div>
                  <Button
                    color="link"
                    className="hint-btn d-inline-flex p-0 align-items-center justify-content-center"
                  >
                    <span className="d-inline-flex align-items-center justify-content-center rounded-circle">
                      ?
                    </span>
                  </Button>
                </div>
              </div>
              {/*
              <div className="row-block d-flex align-items-center">
                <label>
                  After how many minutes should clinic send running late
                  notification for call to pet owner?
                </label>
                <div className="form-item-blc d-flex align-items-center flex-fill">
                  <div className="form-group-wrapper d-flex">
                    <FormGroup className="input-wrapper2 no-floating flex-fill">
                      <div className="form-floating position-relative text-input">
                        <input
                          disabled
                          type="text"
                          className="form-control"
                          id="reply"
                          placeholder="Please Select"
                          value={
                            clinic_settings?.late_notification_cycle + " min."
                          }
                        />
                      </div>
                    </FormGroup>
                    <ButtonDropdown
                      isOpen={lateNotificationDropdown}
                      toggle={() =>
                        setLateNotificationDropdown(!lateNotificationDropdown)
                      }
                    >
                      <DropdownToggle color="link">
                        <i className="icomoon-angle-down"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        {lateNotificationCycles.map((item) => (
                          <DropdownItem
                            onClick={() =>
                              handleUpdateLateNotificationCycle(item)
                            }
                          >
                            {item} min
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </ButtonDropdown>
                  </div>
                  <Button
                    color="link"
                    className="hint-btn d-inline-flex p-0 align-items-center justify-content-center"
                  >
                    <span className="d-inline-flex align-items-center justify-content-center rounded-circle">
                      ?
                    </span>
                  </Button>
                </div>
                      </div>*/}

              <div className="row-block d-flex align-items-center">
                <label>Calendar Week Start Day</label>
                <div className="form-item-blc d-flex align-items-center flex-fill">
                  <div className="form-group-wrapper d-flex">
                    <FormGroup className="input-wrapper2 no-floating flex-fill">
                      <div className="form-floating position-relative text-input">
                        <input
                          disabled
                          type="text"
                          className="form-control"
                          id="reply"
                          placeholder="Please Select"
                          value={clinic_settings?.calendar_start_day}
                          style={{ textTransform: "capitalize" }}
                        />
                      </div>
                    </FormGroup>
                    <ButtonDropdown
                      isOpen={weekDropdown}
                      toggle={() => setWeekDropdown(!weekDropdown)}
                    >
                      <DropdownToggle color="link">
                        <i className="icomoon-angle-down"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        {weekdays.map((item) => (
                          <DropdownItem
                            onClick={() =>
                              handleUpdateCalendarWeekStartDay(item)
                            }
                            style={{ textTransform: "capitalize" }}
                          >
                            {item}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </ButtonDropdown>
                  </div>
                  <Button
                    color="link"
                    className="hint-btn d-inline-flex p-0 align-items-center justify-content-center"
                  >
                    <span className="d-inline-flex align-items-center justify-content-center rounded-circle">
                      ?
                    </span>
                  </Button>
                </div>
              </div>
              {/*
							<div className="row-block d-flex align-items-center">
								<label>Capture race details</label>
								<div className="form-item-blc d-flex align-items-center flex-fill">
									<div className="form-group-wrapper d-flex">
										<FormGroup className="input-wrapper2 no-floating flex-fill">
											<div className="form-floating position-relative text-input">
												<input disabled type="text" className="form-control" id="reply" placeholder="Please Select" value={handleRaceById(races, clinic_settings?.capture_race_details)} />
											</div>
										</FormGroup>
										<ButtonDropdown isOpen={raceDropdown} toggle={() => setRaceDropdown(!raceDropdown)}>
											<DropdownToggle color="link">
												<i className="icomoon-angle-down"></i>
											</DropdownToggle>
											<DropdownMenu >
												{(races || []).map((item) => <DropdownItem onClick={() => handleUpdateCaptureRase(item._id)} style={{ textTransform: "capitalize" }} value={item._id}>{item.race}</DropdownItem>)}
											</DropdownMenu>
										</ButtonDropdown>
									</div>
									<Button color="link" className="hint-btn d-inline-flex p-0 align-items-center justify-content-center">
										<span className="d-inline-flex align-items-center justify-content-center rounded-circle">?</span>
									</Button>
								</div>
							</div>*/}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileSettings;
