import React, { useEffect, useRef, useState , useCallback} from "react";
import { useSelector, useDispatch } from "react-redux";
import catrev from "../../../assets/img/catrev.png";
import { FiDownload } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import moment from "moment";
import { formatPhoneNumber } from "utils/common";
import { Input } from "reactstrap";
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";
import { payInvoice, sendInvoiceAsMail, updateInvoiceStatus } from "context/actions/invoice";
import { Modal, Table } from "react-bootstrap";
import { toast } from "react-toastify";


toast.configure();




export default function TotalRevenueInvoice({
  show,
  cancel,
  setCancel,
  invoiceData,
  setInvoices,
  petOwner,
  getData
}) {
  let drawerClasses = show
    ? "side-drawer open w-full md:w-[75%] lg:w-[50%]"
    : "side-drawer";

  const [subtotalValue, setSubtotalValue] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);

  const [reconcileModal, setReconcileModal] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState("")
  const [taxValue, setTaxValue] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const { token } = useSelector((state) => state.user);

  const ref = useRef();
  const dispatch = useDispatch();
  const { clinic_details } = useSelector((state) => state.clinicReducer);

  const [isDownload, setIsDownload] = useState(false);

  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );

  const downloadReport = useCallback( () => {
    setIsDownload(true);
    //setTimeout(() => {
      managePdfPages();
    //}, 500);
  }, [ref]);

  const reconcileInvoice = () => {
    if(!paymentMethod || paymentMethod === ""){
      toast.error("Please select a payment method")
    } else {


      updateStatus('Fully Paid', invoiceData?._id)
      setTimeout(() => {
        setCancel(!cancel);
        setReconcileModal(false)
      }, 100);
    }

  }
  const managePdfPages = () => {
    //var nodes = document.querySelectorAll(".invoice-download");



    const pdf = new jsPDF("p", "mm", "a4");
    console.log(ref?.current)

    // const addImageToPDF = (index, currentY,currentX) => {
    //const node = nodes[0];
    htmlToImage.toPng(ref.current, { cacheBust: true, }).then((dataUrl) => {
     

      //'use strict';
      const img = new Image();
      img.src = dataUrl;
     

      // Adjust image size and position as needed
      let pdfHeight;
      const pdfWidth = pdf.internal.pageSize.getWidth() - 20;
      console.log("pdfWidth: ", pdfWidth);
      // if (
      //   node.clientHeight > pdf.internal.pageSize.getHeight() ||
      //   node.clientHeight < pdf.internal.pageSize.getHeight()
      // ) {
      pdfHeight = (ref.current.clientHeight * pdfWidth) / ref.current.clientWidth;
      // } else {
      // pdfHeight = pdf.internal.pageSize.getHeight();
      // }

      pdf.addImage(img, "PNG", 10, 10, pdfWidth, pdfHeight);
      // currentY += pdfHeight;

      pdf.save(`invoice_IN${invoiceData?._id.substring(5, 12)}.pdf`);
      setTimeout(() => {
        setIsDownload(false);
      }, 3000);
    });
    // addImageToPDF(0, 0);
  };

  useEffect(() => {
    if (invoiceData) {
      let subTotalVal = 0;
      invoiceData.services?.forEach((ele) => {
        subTotalVal += parseFloat(ele.amount);
      });
      invoiceData.orders?.forEach((ele) => {
        subTotalVal += parseFloat(ele.amount);
      });
      invoiceData.lineItems?.forEach((ele) => {
        subTotalVal += parseFloat(ele.price);
      });
      invoiceData.tests?.forEach((ele) => {
        subTotalVal += parseFloat(ele.amount);
      });
      setSubtotalValue(subTotalVal);

      let discountTotalVal = 0;
      invoiceData.discounts.forEach((ele) => {
        if (ele.discount_name != "") {
          if (ele.discount_type == "amount") {
            let discountValInt = +ele.discount_percentage;
            discountTotalVal += discountValInt;
          } else if (ele.discount_type == "percentage") {
            let totalInt = +subTotalVal;
            let discountValInt = +ele.discount_percentage;
            let percentageVal = (totalInt * discountValInt) / 100;
            discountTotalVal += percentageVal;
          }
        }
      });

      setDiscountValue(discountTotalVal);

      let taxVal = 0;
      invoiceData.taxes.forEach((ele) => {
        if (ele.discount_name != "") {
          let totalInt = +subTotalVal;
          let taxValInt = +ele.tax_percentage;
          let percentageVal = (totalInt * taxValInt) / 100;
          taxVal += percentageVal;
        }
      });
      setTaxValue(taxVal);

      let finalVal = subTotalVal + taxVal - discountTotalVal;
      setTotalValue(finalVal);
    }
  }, [invoiceData]);


  const managePdfPagesEmail = () => {
    //var nodes = document.querySelectorAll(".invoice-download");
    const pdf = new jsPDF("p", "mm", "a4");


    // const addImageToPDF = (index, currentY,currentX) => {
    //const node = nodes[0];
    htmlToImage.toPng(ref.current).then((dataUrl) => {
      // 'use strict';
      const img = new Image();
      img.src = dataUrl;

      let pdfHeight;
      const pdfWidth = pdf.internal.pageSize.getWidth() - 20;

      pdfHeight = (ref.current.clientHeight * pdfWidth) / ref.current.clientWidth;

      pdf.addImage(img, "PNG", 10, 10, pdfWidth, pdfHeight);

      pdf.save(`${invoiceData?._id}.pdf`);


      setTimeout(() => {
        setCancel(!cancel);
      }, 3000);
    });
  };

  const saveReport = () => {
    setTimeout(() => {
      sendPdfAsMail();
    }, 500);
  };

  const sendPdfAsMail = () => {
    var nodes = document.querySelectorAll(".invoice_pdf");
    const pdf = new jsPDF("p", "mm", "a4");

    const node = nodes[0];
    htmlToImage.toPng(node).then((dataUrl) => {
      // 'use strict';
      const img = new Image();
      img.src = dataUrl;

      let pdfHeight;
      const pdfWidth = pdf.internal.pageSize.getWidth() - 20;

      pdfHeight = (node.clientHeight * pdfWidth) / node.clientWidth;

      pdf.addImage(img, "PNG", 10, 10, pdfWidth, pdfHeight);

      const pdfBlob = pdf.output("blob");

      // Create a File object from the Blob
      const pdfFile = new File([pdfBlob], `${invoiceData?._id}.pdf`, {
        type: "application/pdf",
      });

      let allData = {
        invoice: pdfFile,
        invoiceId: invoiceData?._id,
      };
      let formData = new FormData();
      for (const key in allData) {
        formData.append(key, allData[key]);
      }

      dispatch(
        sendInvoiceAsMail(formData, (response) => {
          setCancel(!cancel);
        })
      );
    });
  };

  const handlePayInvoice = () => {

    dispatch(payInvoice(invoiceData?._id,(res) => {
      if (res) {
        //console.log(res)
        window.open(res?.url, "_self");
      }
    } ))
  };

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  const updateStatus = async (value, id) => {
 

    dispatch(updateInvoiceStatus({ invoice_id: id, status: value, payment_method: paymentMethod}, (response) => {
      if (
        response?.statusCode === 200 &&
        response?.message === "Invoice updated successfully!"
      ) {
        getData();
      }
      return response?.response_data;
    }))
    // } catch (error) {
    //   console.error("Error fetching invoices:", error.message);
    //   throw error;
    // }
  };



  const getStatusOptions = () => {
    const status = invoiceData?.status;

    if (status === "Pending") {
      return ["Pending", "Partial Paid", "Fully Paid"];
    } else if (status === "Partial Paid") {
      return ["Partial Paid", "Fully Paid"];
    } else if (status === "Fully Paid") {
      return ["Fully Paid"];
    }

    return ["Pending", "Partial Paid", "Fully Paid"];
  };
  const paymentStatusOptions = getStatusOptions();

  const selectPaymentStatus = (e, selectedValue, invoice_id) => {
    e.preventDefault();

    Swal.fire({
      title:
        "<strong>Are you sure you want to change this invoice's status?</strong>",
      icon: "warning",
      html: `Once status is changed to Fully Paid, you cannot change it again`,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Confirm",
      confirmButtonAriaLabel: "Confirm",
      cancelButtonText: "Cancel",
      cancelButtonAriaLabel: "Cancel",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        updateStatus(selectedValue, invoice_id);
      } else if (result.isDenied) {
        Swal.fire("Your data is safe", "", "info");
      } else if (result.isDismissed) {
        Swal.fire("Your data is safe", "", "info");
      }
    });
  };

  if (invoiceData) {
    return (


      <>
      {reconcileModal &&
      <Modal show onHide={() => {setReconcileModal(false)}}>
        <Modal.Header>
          <h1>Reconcile Invoice</h1>
        </Modal.Header>
        <Modal.Body> 
          
          

          <div className="d-flex flex-col gap-[10px] w-100 justify-content-center align-items-center">
          <Input
              id="invoice-reconcile-payment-method"
              type="select"
              name="select"
              className="!h-[42px] shadow-none cursor-pointer font-['Inter'] gap-2 items-center justify-center text-[14px] flex rounded-lg"
              onChange={(e) =>
                setPaymentMethod(e?.target?.value)
              }
            >
              <option value="">Select a Payment Method</option>
              <option>PayJunction</option>
              <option>Cash</option>
              <option>Check</option>
            </Input>

            <button
              id="invoice-complete-reconcile"
              onClick={reconcileInvoice}
              className="w-[200px] h-[40px] bg-[#F8F8F8] cursor-pointer font-['Inter'] gap-2 items-center justify-center text-[14px] text-[#3D3EFD] flex  rounded-lg border border-gray"
            >
              Reconcile
            </button>
          </div>



        </Modal.Body>

      </Modal>}
      <div className={show ? drawerClasses : "p-4 w-[60%]"}>
        <div ref={ref}  id="invoice-download" className="invoice-download">
          <div className="invoice-id mb-3">
            <p className="flex justify-between">
              <span className="font-[700] font-['Inter']">
                Invoice ID: {invoiceData?._id}
              </span>
              <span
                className={`${isDownload ? "hidden" : "block"
                  } cursor-pointer icomoon-cancel`}
                onClick={() => {
                  setCancel(!cancel);
                }}
              >
                <MdClose size={25} />
              </span>
            </p>
          </div>
          <div className="w-full rounded-lg p-4 flex flex-col md:flex-row gap-y-3 justify-between bg-[#282E65] text-[#fff]">
            <div className="flex items-center gap-x-2">
              {clinic_settings?.invoice_preferences?.logo_url ? (
                <img
                  src={clinic_settings?.invoice_preferences?.logo_url}
             
                  className=" object-cover w-[30px] h-[30px]"
                />
              ) : (
                <img
                  src={catrev}
                 
                  className="object-cover w-[30px] h-[30px]"
                />
              )}

              <div className="">
                <p id="invoice-clinic-name" className="font-[600] text-[18px] font-['Inter'] w-[200px]">
                  {petOwner ? invoiceData?.clinic_details?.clinic_name : invoiceData?.clinic_details[0]?.clinic_name}
                </p>
                <p className="font-['Inter']  text-[14px]">
                  {petOwner ? invoiceData?.clinic_details?.email : invoiceData?.clinic_details[0]?.email}
                </p>
              </div>
            </div>
            <div>
              <p>
                <span className="text-[14px] font-['Inter']  block">
                  {petOwner ?
                    invoiceData?.clinic_details?.address
                      ? invoiceData?.clinic_details?.address + ","
                      : ""
                    : invoiceData?.clinic_details[0]?.address
                      ? invoiceData?.clinic_details[0]?.address + ","
                      : ""
                  }
                </span>
                <span className="text-[14px] font-['Inter']  block">
                  {invoiceData?.clinic_details[0]?.city}{" "}
                  {invoiceData?.clinic_details[0]?.city &&
                    invoiceData?.clinic_details[0]?.state &&
                    ","}{" "}
                  {invoiceData?.clinic_details[0]?.state}
                </span>
                <span className="text-[14px] font-['Inter']  block">
                  {invoiceData?.clinic_details[0]?.country}{" "}
                  {invoiceData?.clinic_details[0]?.country &&
                    invoiceData?.clinic_details[0]?.zipcode &&
                    ","}{" "}
                  {invoiceData?.clinic_details[0]?.zipcode}
                </span>
              </p>
            </div>
          </div>
          <div className="invoice-section pb-2">
            <div className="w-full rounded-lg p-4 flex flex-col gap-y-3 sm:flex-row justify-between bg-[#F8F8F8E5] text-[#282E65] mt-2">
              <div>
                <p>
                  <span id="invoice-number" className=" block font-[600] font-['Inter'] text-[18px]">
                    Invoice Number
                  </span>
                  <span id="invoice-id" className="text-[14px]  font-['Inter'] block">
                    {invoiceData?._id}
                  </span>
                  <span className="text-[14px]  font-['Inter'] block">
                    Issued Date:{" "}
                    {invoiceData.issued_date &&
                      moment(invoiceData.issued_date)
                        .utc()
                        .format("DD MMM YYYY")}
                  </span>
                  <span className="text-[14px]  font-['Inter'] block">
                    Due Date:{" "}
                    {invoiceData.due_date &&
                      moment(invoiceData.due_date).utc().format("DD MMM YYYY")}
                  </span>
                </p>
              </div>
              <div>
                <p>
                  <span className=" block font-[600] font-['Inter'] text-[18px]">
                    Billed to
                  </span>
                  <div id="invoice-customer-id" style={{display: 'none'}}>{invoiceData?.payer_detail?._id}</div>
                  <span id="invoice-customer-name"  className="text-[14px]  font-['Inter'] block">
                    {petOwner ? 
                      invoiceData?.payer_detail?.firstname +
                      " " +
                      invoiceData?.payer_detail?.lastname
                    :
                      invoiceData?.payer_detail?.firstname +
                        " " +
                        invoiceData?.payer_detail?.lastname
                  }
                  </span>
                  <span id="invoice-customer-email" className="text-[14px]  font-['Inter'] block">
                    {petOwner ? invoiceData?.payer_detail?.email : invoiceData?.payer_detail?.email}
                  </span>
                  <span id="invoice-customer-phone" className="text-[14px]  font-['Inter'] block">
                    {formatPhoneNumber(petOwner ? invoiceData?.payer_detail?.phone : invoiceData?.payer_detail?.phone)}
                  </span>
                </p>
              </div>
            </div>

            {invoiceData?.services?.length > 0 && (
              <div className="mt-2">
                <p className="flex justify-between border-b">
                  <span className="text=[#788C9F] text-[14px] font-[500] font-['Inter'] ">
                    Service
                  </span>
                  <span className="text=[#788C9F] text-[14px] font-[500] font-['Inter'] ">
                    Amount
                  </span>
                </p>
                {invoiceData?.services?.map((ele, index) => {
                  return (
                    <div>
                      <p
                        className={`flex justify-between border-b ${index % 2 == 0 ? "bg-[#E4E4E447]" : ""
                          } px-[10px] py-3`}
                      >
                        <span className="text=[#282E65] text-[14px] font-[600] font-['Inter'] ">
                          {ele.serviceName}
                        </span>
                        <span className="text=[#282E65] text-[14px] font-[500] font-['Inter'] ">
                          ${ele.amount.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      })}
                        </span>
                      </p>
                    </div>
                  );
                })}
              </div>
            )}

            {invoiceData?.orders?.length > 0 && (
              <div className="mt-2">
                <p className="flex justify-between border-b">
                  <span className="text=[#788C9F] text-[14px] font-[500] font-['Inter'] ">
                    Product
                  </span>
                  <span className="text=[#788C9F] text-[14px] font-[500]  font-['Inter'] ">
                    Amount
                  </span>
                </p>
                {invoiceData?.orders?.map((ele, index) => {
                  return (
                    <div>
                      <p
                        className={`flex justify-between border-b ${index % 2 == 0 ? "bg-[#E4E4E447]" : ""
                          } px-[10px] py-3`}
                      >
                        <span className="text=[#282E65] text-[14px] font-[600] font-['Inter'] ">
                          {ele.product_name}
                        </span>
                        <span className="text=[#282E65] text-[14px] font-[500] font-['Inter'] ">
                          ${ele.amount.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      })}
                        </span>
                      </p>
                    </div>
                  );
                })}
              </div>
            )}

            {invoiceData?.tests?.length > 0 && (
              <div className="mt-2">
                <p className="flex justify-between border-b">
                  <span className="text=[#788C9F] text-[14px] font-[500] font-['Inter'] ">
                    Diagnostic Service
                  </span>
                  <span className="text=[#788C9F] text-[14px] font-[500] font-['Inter'] ">
                    Amount
                  </span>
                </p>
                {invoiceData?.tests?.map((ele, index) => {
                  return (
                    <div>
                      <p
                        className={`flex justify-between border-b ${index % 2 == 0 ? "bg-[#E4E4E447]" : ""
                          } px-[10px] py-3`}
                      >
                        <span className="text=[#282E65] text-[14px] font-[600] font-['Inter'] ">
                          {ele.name}
                        </span>
                        <span className="text=[#282E65] text-[14px] font-[500] font-['Inter'] ">
                          ${ele.amount.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      })}
                        </span>
                      </p>
                    </div>
                  );
                })}
              </div>
            )}  

            {invoiceData?.lineItems?.length > 0 && (
              <div className="mt-2">
                <p className="flex justify-between border-b">
                  <span className="text=[#788C9F] text-[14px] font-[500] font-['Inter'] ">
                    Item
                  </span>
                  <span className="text=[#788C9F] text-[14px] font-[500] font-['Inter'] ">
                    Amount
                  </span>
                </p>
                {invoiceData.lineItems.map((ele, index) => {
                  return (
                    <div>
                      <p
                        className={`flex justify-between border-b ${index % 2 == 0 ? "bg-[#E4E4E447]" : ""
                          } px-[10px] py-3`}
                      >
                        <span className="text=[#282E65] text-[14px] font-[600] font-['Inter'] ">
                          {ele.line_item_name}
                        </span>
                        <span className="text=[#282E65] text-[14px] font-[500] font-['Inter'] ">
                          ${ele.price.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      })}
                        </span>
                      </p>
                    </div>
                  );
                })}
              </div>
            )}

            <div className="w-fit ml-auto my-3">
              <p className="flex justify-between text-[14px] font-['Inter'] text-[#1B212D]">
                <span>Subtotal </span>{" "}
                <span className="ml-3 mr-2">
                  $
                  {subtotalValue.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })}
                </span>
              </p>
              <p className="flex justify-between text-[14px] font-['Inter'] text-[#1B212D]">
                <span>Disount </span>{" "}
                <span className="ml-3 mr-2">
                  $
                  {discountValue.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })}
                </span>
              </p>
              <p className="flex justify-between text-[14px] font-['Inter'] text-[#1B212D]">
                <span>Tax </span>{" "}
                <span className="ml-3 mr-2">
                  $
                  {taxValue.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })}
                </span>
              </p>
              <p className="flex justify-between text-[14px] font-['Inter'] font-bold text-[#1B212D]">
                <span>Total </span>{" "}
                <span id="invoice-total" className="ml-3 mr-2">${totalValue.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                      })}</span>
              </p>
            </div>
          </div>
        </div>

        {permissions?.edit_invoices && (
          <div className="flex gap-3 justify-end">
            <Input
              type="select"
              name="select"
              className="!h-[42px] shadow-none w-[200px] cursor-pointer font-['Inter'] gap-2 items-center justify-center text-[14px] flex rounded-lg"
              onChange={(e) =>
                selectPaymentStatus(e, e.target.value, invoiceData?._id)
              }
              disabled={invoiceData?.status === "Fully Paid" ? true : false}
            >
              {paymentStatusOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Input>
            <button
              onClick={isDownload ? (() => {}) : downloadReport}
              className="w-[200px] h-[40px] bg-[#F8F8F8] cursor-pointer font-['Inter'] gap-2 items-center justify-center text-[14px] text-[#3D3EFD] flex  rounded-lg border border-gray"
            >
              <FiDownload size={25} color="#3D3EFD" /> Download
            </button>

            {permissions?.complete_invoice && invoiceData?.status !=='Fully Paid' && (
              <>
                <button
                  id="initiate-reconcile-payment"
                  onClick={() => {
                    setReconcileModal(true)
                  }}
                  className="w-[200px] h-[40px] bg-[#3D3EFD] curosr-pointer gap-2  text-['Inter'] items-center justify-center text-[14px] text-[#fff] flex  rounded-lg border border-[#3D3EFD] uppercase"
                >
                  Reconcile Payment
                </button>
                <button
                  onClick={() => {
                    handlePayInvoice();
                  }}
                  className="w-[200px] h-[40px] bg-[#3D3EFD] curosr-pointer gap-2  text-['Inter'] items-center justify-center text-[14px] text-[#fff] flex  rounded-lg border border-[#3D3EFD] uppercase"
                >
                  Pay Invoice
                </button>
                <button
                  onClick={() => {
                    saveReport();
                  }}
                  className="w-[200px] h-[40px] bg-[#3D3EFD] curosr-pointer gap-2  text-['Inter'] items-center justify-center text-[14px] text-[#fff] flex  rounded-lg border border-[#3D3EFD] uppercase"
                >
                  Send Invoice
                </button>
              </>
            )}
          </div>
        )}
      </div>
      </>
    );
  } else {
    return (
      <div className={show ? drawerClasses : "p-4 w-[60%]"}>
        <div className="mb-3">
          <p className="flex justify-between">
            <span className="font-[700] font-['Inter']">
              Invoice ID: OD513212
            </span>
            <span
              className={`${isDownload ? "hidden" : "block"
                } cursor-pointer icomoon-cancel`}
              onClick={() => {
                setCancel(!cancel);
              }}
            >
              <MdClose size={25} />
            </span>
          </p>
        </div>
        <div className="w-full rounded-lg p-4 flex flex-col md:flex-row gap-y-3 justify-between bg-[#282E65] text-[#fff]">
          <div className="flex items-center gap-x-2">
            <img src={catrev} className="w-10 h-10 object-cover" />
            <div className="">
              <p className="font-[600] text-[18px]  w-[200px]">
                Newlife Cat Care
              </p>
              <p className="  text-[14px]">sales@catcare.com</p>
            </div>
          </div>
          <div>
            <p>
              <span className="text-[14px]  block">55 Black Fox Farm Road</span>
              <span className="text-[14px]  block">Bouston, TX 88060</span>
              <span className="text-[14px]  block">
                Foomfield Hills, Michigan(MI), 58202
              </span>
            </p>
          </div>
        </div>
        <div className="w-full rounded-lg p-4 flex flex-col gap-y-3 sm:flex-row justify-between bg-[#F8F8F8E5] text-[#282E65] mt-2">
          <div>
            <p>
              <span className=" block font-[600] text-[18px]">
                Invoice Number
              </span>
              <span className="text-[14px]  block">MAG 2541420</span>
              <span className="text-[14px]  block">
                Issued Date: 10 aug 2022
              </span>
              <span className="text-[14px]  block">Due Date: 20 Aug 2022</span>
            </p>
          </div>
          <div>
            <p>
              <span className=" block font-[600] text-[18px]">Billed to</span>
              <span className="text-[14px]  block">Timothy Rotanson</span>
              <span className="text-[14px]  block">#8555 Rainy Day Drive</span>
              <span className="text-[14px]  block">Michigan, IM 58202</span>
            </p>
          </div>
        </div>

        <div className="mt-2">
          <p className="flex justify-between border-b">
            <span className="text=[#788C9F] text-[14px] font-[500] ">
              Service
            </span>
            <span className="text=[#788C9F] text-[14px] font-[500] ">
              Ammount
            </span>
          </p>
          <div>
            <p className="flex justify-between border-b bg-[#E4E4E447] px-[10px] py-3">
              <span className="text=[#282E65] text-[14px] font-[600] ">
                Video Call Consultancy
              </span>
              <span className="text=[#282E65] text-[14px] font-[500] ">
                $200.00
              </span>
            </p>
          </div>
          <div>
            <p className="flex justify-between border-b  px-[10px] py-3">
              <span className="text-[#282E65] text-[14px] font-[600] ">
                Suppliment : [Product Information will goes here]
              </span>
              <span className="text=[#282E65] text-[14px] font-[500] ">
                $50.00
              </span>
            </p>
          </div>
        </div>
        <div className="w-full">
          <div className="float-right ">
            <p className="flex justify-between text-[14px]  text-[#1B212D]">
              <span>Subtotal</span> <span>$250.00</span>
            </p>
            <p className="flex justify-between text-[14px]  text-[#1B212D]">
              <span>Disount</span> <span>$10.00</span>
            </p>
            <p className="flex justify-between text-[14px]  text-[#1B212D]">
              <span>Tax</span> <span>$40.00</span>
            </p>
            <p className="flex justify-between text-[14px]  font-bold text-[#1B212D]">
              <span>Total</span> <span>$200.00</span>
            </p>
          </div>
        </div>
        {permissions?.complete_invoice && (
          <div className="flex gap-3 float-right position-relative top-[100px] left-[117px]">
            <button
            className="w-[200px] h-[40px] bg-[#F8F8F8] cursor-pointer gap-2 items-center justify-center text-[14px] text-[#3D3EFD] flex  rounded-lg border border-gray"
            onClick={() => {
              downloadReport();
            }}
          >
              <FiDownload size={25} color="#3D3EFD" /> Download
            </button>
            {!petOwner && (
            <button
                onClick={() => {
                  saveReport();
                }}
                className="w-[200px] h-[40px] bg-[#3D3EFD] cursor-pointer gap-2 items-center justify-center text-[14px] text-[#fff] flex  rounded-lg border border-[#3D3EFD] uppercase"
              >
                Send Invoice
              </button>
            )}
        </div>
        )}
      </div>
      
    );
  }
}
