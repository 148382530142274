import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPetDetailsById } from "../../../context/actions/patient-module/patient/pet";
import { getAppointmentDetails } from "context/actions/appointment";
import EditAppointmentSections from "shared/EditAppointmentSections";
import PatientProfile from "../../../shared/PatientProfile";
import "./index.scss";

const EditAppointment = () => {
	const routeParams = useParams();
	const dispatch = useDispatch();
	const userDetails = useSelector(state => state.user.user_details)
	const petDetail=useSelector(state=>state.petReducer.petDetails)
	const [appointmentDetails, setAppointmentDetails] = useState(false)


	useEffect(() => {
		dispatch(getAppointmentDetails(routeParams.appointmentId,  (res) => {
			if(res){
				setAppointmentDetails(res);
				dispatch(getPetDetailsById(res?.patient_id?.id , {}, () => {

				}))
			}

		}))
	}, [routeParams])
	return (
		<>
			<div className="page-wrapper add-new-appointment-wrapper position-relative d-flex overflow-auto">
				<div className="left-col d-flex flex-column overflow-auto">
					<PatientProfile />
				</div>
				<div className="right-col flex-fill d-flex flex-column overflow-auto">
					<EditAppointmentSections appointmentDetails={appointmentDetails} userDetails={userDetails} patientData={petDetail} />
				</div>
			</div>
		</>
	);
};

export default EditAppointment;
