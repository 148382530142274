import React from "react";
import OrderInfo from "./OrderInfo";
import ClientInfo from "./ClientInfo";
import ServicesInfo from "./ServicesInfo";
import { MdClose } from "react-icons/md";
import { useSelector } from 'react-redux';

export default function ServiceDetails({
  show,
  cancel,
  setCancel,
  ServiceDetails,
}) {
  console.log("serviceDetails:", ServiceDetails);
  let drawerClasses = show
    ? "side-drawer open w-full md:w-[75%] lg:w-[50%] overflow-auto"
    : "side-drawer overflow-auto";

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  return (
    <div className={show ? drawerClasses : "p-4 w-[60%] overflow-auto"}>
      <div className="flex justify-between">
        <h1 className="text-[24px] py-3 font-bold">View Service</h1>
        <span
          className="cursor-pointer icomoon-cancel"
          onClick={() => {
            setCancel(!cancel);
          }}
        >
          <MdClose size={25} />
        </span>
      </div>
      <div className="w-full space-y-[2px]">
        <div className="w-full bg-gray-100 rounded-t-lg px-3 py-2 space-y-3">
          <OrderInfo ServiceDetails={ServiceDetails} />
        </div>
        <div className="w-full bg-gray-100 px-3 py-2 relative overflow-hidden">
          <ServicesInfo ServiceDetails={ServiceDetails} />
        </div>
        {/* <div className="w-full bg-gray-100 py-2 px-3 space-y-3">
          <ClientInfo />
        </div>
        <div className="w-full bg-gray-100 rounded-b-lg py-2 px-3 space-y-3">
          <h5 className="text-lg font-semibold  text-[#282E65]">
            Make Payment
          </h5>
          <ul>
            <li className="">
              <span className="w-1/2 md:w-44">Payment Mode</span>
              <span className="w-1/2 md:w-56 px-2.5 py-1.5">
                Cash on Delivery
              </span>
            </li>
          </ul>
        </div> */}
      </div>
      {permissions?.edit_services &&
        <div className="flex justify-content-end mt-3">
          {/* <button className="w-24 uppercase md:w-32 py-2 text-[#DE2B2B] text-sm md:text-base border duration-300 transition-colors hover:bg-gray-50 rounded-lg">
            Delete
      </button> */}
          <div className="inline-flex gap-x-2 items-center">
          
            <button className="w-24 uppercase md:w-32 py-2  text-sm md:text-base drop-shadow bg-[#3D3EFD] duration-300 text-white transition-colors hover:bg-blue-600 rounded-lg">
              Save
            </button>
          </div>
        </div>
      }
    </div>
  );
}
