import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineAlibaba, AiOutlineZoomIn, AiOutlineGateway, AiOutlineReload, AiOutlineSliders } from "react-icons/ai"

import {
  App,
  getDwvVersion,
  decoderScripts,
  ToolConfig,
  Index
} from 'dwv';
import { Row, Col, } from "reactstrap";
import './ReportImageViewer.scss';
import { faPlay, faPause, faForwardStep, faBackwardStep } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

decoderScripts.jpeg2000 = `${process.env.PUBLIC_URL}/assets/dwv/decoders/pdfjs/decode-jpeg2000.js`;
decoderScripts["jpeg-lossless"] = `${process.env.PUBLIC_URL}/assets/dwv/decoders/rii-mango/decode-jpegloss.js`;
decoderScripts["jpeg-baseline"] = `${process.env.PUBLIC_URL}/assets/dwv/decoders/pdfjs/decode-jpegbaseline.js`;
decoderScripts.rle = `${process.env.PUBLIC_URL}/assets/dwv/decoders/dwv/decode-rle.js`;

export const TransitionUp = React.forwardRef((props, ref) => (
  <div direction="up" {...props} ref={ref} />
))

class ReportImageViewer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      versions: {
        dwv: getDwvVersion(),
        react: React.version
      },
      tools: {
        Scroll: new ToolConfig(),
        ZoomAndPan: {},
        WindowLevel: {},
        Draw: {
          options: ['Rectangle'],
          type: 'factory'
        }
      },
      selectedTool: 'Select Tool',
      loadProgress: 0,
      dataLoaded: false,
      dwvApp: null,
      metaData: {},
      orientation: undefined,
      showDicomTags: false,
      dropboxDivId: 'dropBox',
      dropboxClassName: 'dropBox',
      borderClassName: 'dropBoxBorder',
      hoverClassName: 'hover',
      opacity: 100,
      wlCenter: 0,
      wlWidth: 0,
      wlInitCenter: 0,
      wlInitWidth: 0,
      scrollMax: 0,
      scrollVal: 0,
      isPlaying: false
    };
  }
  initPage = () => {

    // create app
    const app = new App();

    // initialise app
    app.init({
      "dataViewConfigs": { '*': [{ divId: 'layerGroup0' }] },
      "tools": this.state.tools
    });

    // load events
    let nLoadItem = null;
    let nReceivedLoadError = null;
    let nReceivedLoadAbort = null;
    let isFirstRender = null;
    app.addEventListener('loadstart', (/*event*/) => {
      // reset flags
      nLoadItem = 0;
      nReceivedLoadError = 0;
      nReceivedLoadAbort = 0;
      isFirstRender = true;
      // hide drop box
      //  this.showDropbox(app, false);
    });
    app.addEventListener("loadprogress", (event) => {
      this.setState({ loadProgress: event.loaded });
    });
    app.addEventListener('renderend', (/*event*/) => {
      if (isFirstRender) {
        isFirstRender = false;
        // available tools
        // let selectedTool = 'ZoomAndPan';

        // /*
        // if (app.canScroll()) {
        //   selectedTool = 'Scroll';
        // }*/
        // this.onChangeTool(selectedTool);
      }
    });
    app.addEventListener("load", (/*event*/) => {
      // set dicom tags
      this.setState({ metaData: app.getMetaData(0) });
      // set data loaded flag
      this.setState({ dataLoaded: true });

      const size = app.getImage(0).getGeometry().getSize();
      console.log('image size',size.get(3) - 1);
      this.setState({ scrollMax: size.get(3) - 1});



    });
    app.addEventListener('loadend', (/*event*/) => {
      if (nReceivedLoadError) {
        this.setState({ loadProgress: 0 });
        alert('Received errors during load. Check log for details.');
        // show drop box if nothing has been loaded
        if (!nLoadItem) {
          //this.props.handleClose()
          //  this.showDropbox(app, true);
        }
      }
      if (nReceivedLoadAbort) {
        this.setState({ loadProgress: 0 });
        alert('Load was aborted.');
        //  this.showDropbox(app, true);
      }
      const currentLayer = this.state.dwvApp?.getViewLayersByDataIndex(0)?.[0]
      const viewController = currentLayer?.getViewController()
      const { center, width } = viewController?.getWindowLevel() || {}
      this.setState({
        ...this.state,
        wlCenter: center,
        wlWidth: width,
        wlInitCenter: center,
        wlInitWidth: width
      })
    });
    app.addEventListener('loaditem', (/*event*/) => {
      ++nLoadItem;
    });
    app.addEventListener('loaderror', (event) => {
      console.error(event.error);
      ++nReceivedLoadError;
    });
    app.addEventListener('loadabort', (/*event*/) => {
      ++nReceivedLoadAbort;
    });
    // handle key events
    app.addEventListener('keydown', (event) => {
      app.defaultOnKeydown(event);
    });


    app.addEventListener('positionchange', () => {
      const lg = app.getLayerGroupByDivId('layerGroup0');
      const vc = lg.getActiveViewLayer().getViewController();

      console.log('current index',vc.getCurrentIndex())
      this.setState( {...this.state,  scrollVal:  vc.getCurrentIndex().get(3)})
    });



    // handle window resize
    window.addEventListener('resize', app.onResize);
    const existingFile = decodeURIComponent(this.props.match.params.url)
    app.loadURLs([existingFile]);
    // store
    this.setState({ dwvApp: app });




    // possible load from location
    app.loadFromUri(window.location.href);
  }
  componentDidMount() {
    this.initPage()
  }

  /**
   * Get the icon of a tool.
   *
   * @param {string} tool The tool name.
   * @returns {Icon} The associated icon.
   */
  getToolIcon = (tool) => {
    let res;
    if (tool === 'Scroll') {
      res = (<div />);
    } else if (tool === 'ZoomAndPan') {
      res = (<div />);
    } else if (tool === 'WindowLevel') {
      res = (<div />);
    } else if (tool === 'Draw') {
      res = (<div />);
    }
    return res;
  }

  /**
   * Handle a change tool event.
   * @param {string} tool The new tool name.
   */
  onChangeTool = (tool) => {
    if (this.state.dwvApp) {
      this.setState({ selectedTool: tool });
      this.state.dwvApp.setTool(tool);
      if (tool === 'Draw') {
        this.onChangeShape(this.state.tools.Draw.options[0]);
      }
    }
  }
  onChangeShape = (shape) => {
    if (this.state.dwvApp) {
      this.state.dwvApp.setToolFeatures({ shapeName: shape });
    }
  }
  onReset = () => {
    if (this.state.dwvApp) {
      this.state.dwvApp.setOpacity(1);
      this.state.dwvApp.resetDisplay();
      const activeLayer = this.state.dwvApp.getActiveLayerGroup()
      const activeDrwer = activeLayer?.getActiveDrawLayer()
      activeDrwer?.deleteDraws()
      this.setState({ ...this.state, opacity: 100, wlCenter: this.state.wlInitCenter, wlWidth: this.state.wlInitWidth });
    }
  }

  handleScrollChange = (e) => {

    const lg = this.state.dwvApp.getLayerGroupByDivId('layerGroup0');

    console.log(this.state.dwvApp.get)
    const vc = lg.getActiveViewLayer().getViewController();
    const index = vc.getCurrentIndex();
    const values = index.getValues();
    console.log(e.target.value)
    values[3] = parseInt(e.target.value);
    vc.setCurrentIndex(new Index(values));

  }


  handleBackward= () => {

    const lg = this.state.dwvApp.getLayerGroupByDivId('layerGroup0');
    const vc = lg.getActiveViewLayer().getViewController();
    const index = vc.getCurrentIndex();
    const values = index.getValues();

    if(values[3] !== 0 ){

      values[3] = values[3] - 1;
      vc.setCurrentIndex(new Index(values));
    }
  

  }

  handleForward= () => {

    const lg = this.state.dwvApp.getLayerGroupByDivId('layerGroup0');
    const vc = lg.getActiveViewLayer().getViewController();
    const index = vc.getCurrentIndex();
    const values = index.getValues();

    if(values[3] !== this.state.scrollMax ){

      values[3] = values[3] + 1;
      vc.setCurrentIndex(new Index(values));
    }
  

  }

  handlePlay = () => {

    const lg = this.state.dwvApp.getLayerGroupByDivId('layerGroup0');
    const viewController = lg.getActiveViewLayer().getViewController();
    viewController.play();
    this.setState({...this.state, isPlaying: viewController.isPlaying() })

  }

  handleTagsDialogOpen = () => {
    this.setState({ showDicomTags: true });
  }

  handleOpacityChange = (e) => {
    const val = +e.target.value
    this.state.dwvApp.setOpacity(val / 100)
    this.setState({ opacity: val });
  }
  handleWindowLevelChange = (e) => {
    const valID = e.target.id
    const val = +e.target.value
    let newWLCenter = valID === "center-range" ? val : this.state.wlCenter
    let newWLWidth = valID === "width-range" ? val : this.state.wlWidth
    this.setState({
      ...this.state,
      wlCenter: newWLCenter,
      wlWidth: newWLWidth,
    })
    const currentLayer = this.state.dwvApp.getViewLayersByDataIndex(0)?.[0]
    const viewController = currentLayer?.getViewController()
    viewController?.setWindowLevel(newWLCenter, newWLWidth)
  }



  render() {
    // const { classes } = this.props;

    const {
      // versions, tools, loadProgress, dataLoaded, metaData, 
      opacity, selectedTool, wlCenter,
      wlWidth, wlInitCenter,
      wlInitWidth } = this.state;

      console.log('dwv state', this.state);

    return (
      <div id="dwv" className='flex flex-col'>
        {/* <div className='icon-block-list'> */}
          {/*
          <div className={`img-icon-btn ${selectedTool === "Scroll" ? "active-icon" : ""}`} onClick={() => {
            this.onChangeTool("Scroll")
          }}>
            <span><AiOutlineAlibaba size={20} /></span>
            <span className='ic-title'>Position</span>
        </div>*/}
          {/* <div className={`img-icon-btn ${selectedTool === "ZoomAndPan" ? "active-icon" : ""}`} onClick={() => {
            this.onChangeTool("ZoomAndPan")
          }}>
            <span><AiOutlineZoomIn size={20} /></span>
            <span className='ic-title'>Zoom</span>
          </div> */}

          {/*
          <div className={`img-icon-btn ${selectedTool === "WindowLevel" ? "active-icon" : ""}`} onClick={() => {
            this.onChangeTool("WindowLevel")
          }}>
            <span><AiOutlineSliders size={20} /></span>
            <span className='ic-title'>Window Level</span>
        </div>*/}
          {/* <div className={`img-icon-btn ${selectedTool === "Draw" ? "active-icon" : ""}`} onClick={() => {
            this.onChangeTool("Draw")
          }}>
            <span><AiOutlineGateway size={20} /></span>
            <span className='ic-title'>Draw</span>
          </div>
          <div className="img-icon-btn" onClick={this.onReset}>
          <span className='ic-title'>Reset</span>
          </div> */}

          {/* <span onClick={this.onReset}>

            <AiOutlineReload size={20} />
          </span> */}
          {/* <span onClick={() => {
            const currentLayer = this.state.dwvApp.getViewLayersByDataIndex(0)?.[0]
            const viewController = currentLayer.getViewController()
            const ccurrent= viewController.getCurrentPosition()
            console.log(ccurrent);
            // viewController.setCurrentPosition([106.10,55.50,0],true)
            
          }}>
            TEST

          </span> */}


        {/* </div> */}

        <div className=''>
          <Row>

            {this.state.scrollMax > 0  &&

            <>

            <Col lg={2} className='mt-3 mb-3'>
              <label for="scroll-range" className="block text-xs font-medium text-gray-600">Image(s)</label>
            </Col>
            <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} lg={10}>
              <input max={this.state.scrollMax} id="scroll-range" type="range" min="0" value={this.state.scrollVal} className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" onChange={this.handleScrollChange} />
              <label>{this.state.scrollVal}</label>
              <div style={{display: 'flex', flexDirection: 'row', gap:'10px', alignContent: 'center', justifyContent: 'center' }}>
                <FontAwesomeIcon icon={faBackwardStep} onClick={this.handleBackward} />
                <FontAwesomeIcon icon={this.state.isPlaying ? faPause : faPlay} onClick={this.handlePlay} />
                <FontAwesomeIcon icon={faForwardStep} onClick={this.handleForward} />
              </div>
            </Col>
            </>}

            {/* <Col lg={2}>
              <label for="minmax-range" className="block text-xs font-medium text-gray-600">Opacity</label>
            </Col>
            <Col lg={10}>
              <input id="minmax-range" type="range" min="0" max="100" value={opacity} className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" onChange={this.handleOpacityChange} />
            </Col>

            <Col lg={12} className='mt-3 mb-2'>
              <label for="minmax-range" className="block mb-2 text-xs font-medium text-gray-900">Window Level</label>
            </Col>

            <Col lg={2} className='mb-3'>
              <label for="center-range" className="block text-xs font-medium text-gray-600 ">Center</label>
            </Col>
            <Col lg={10} className='mb-3'>
              <input id="center-range" type="range" min="0" max={wlInitCenter} value={wlCenter} className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" onChange={this.handleWindowLevelChange} />
            </Col>
            <Col lg={2}>
              <label for="width-range" className="block text-xs font-medium text-gray-600 ">Width</label>
            </Col>
            <Col lg={10}>
              <input id="width-range" type="range" min="0" max={wlInitWidth} value={wlWidth} className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" onChange={this.handleWindowLevelChange} />
            </Col> */}
          </Row>
        </div>


        <div style={{borderRadius: '6px'}}  id="layerGroup0" className="layerGroup" />



      </div>
    );
  }


} 

export default ReportImageViewer;
