import React from "react";
// import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import TableListFilterBlock from "./TableListFilterBlock";

const TableListSuperAdminFilterBlock = ({ className = "", handleSearch,searchText }) => {
  
  return (
    <>
      <div
        className={`table-supplier-filter-blc-wrapper d-flex align-items-center overflow-auto flex-shrink-0 ${className}`}
      >
        <div className="left-col">
          <TableListFilterBlock onSearch={handleSearch} searchText={searchText}/>
        </div>
        <div className="right-col ms-auto">
          <Button
            color="link"
            className="list-view-type-btn d-inline-flex align-items-center"
          >
            <span className="d-inline-flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="10"
                fill="none"
                viewBox="0 0 18 10"
              >
                <path
                  fill="#788693"
                  d="M1.074 1.75a.74.74 0 01-.525-.212A.706.706 0 01.324 1C.324.784.4.605.55.464A.74.74 0 011.074.25h12.2a.726.726 0 01.75.75.726.726 0 01-.75.75h-12.2zm0 4a.74.74 0 01-.525-.212A.706.706 0 01.324 5a.71.71 0 01.225-.538.742.742 0 01.525-.212h12.2a.728.728 0 01.75.75.726.726 0 01-.75.75h-12.2zm0 4c-.2 0-.375-.07-.525-.211A.71.71 0 01.324 9a.71.71 0 01.225-.538.742.742 0 01.525-.212h12.2a.728.728 0 01.75.75.728.728 0 01-.75.75h-12.2zm15.775.076a.84.84 0 01-.6-.238A.777.777 0 0116 9a.8.8 0 01.238-.588.797.797 0 01.587-.237c.234 0 .434.079.6.237a.78.78 0 01.25.588.797.797 0 01-.237.587.799.799 0 01-.588.238zm0-8a.84.84 0 01-.6-.238A.777.777 0 0116 1a.8.8 0 01.238-.588.797.797 0 01.587-.237c.234 0 .434.079.6.237a.78.78 0 01.25.588.797.797 0 01-.237.587.799.799 0 01-.588.238zm0 4a.84.84 0 01-.6-.238A.777.777 0 0116 5a.8.8 0 01.238-.588.797.797 0 01.587-.237c.234 0 .434.079.6.237a.78.78 0 01.25.588.797.797 0 01-.237.587.799.799 0 01-.588.238z"
                ></path>
              </svg>
            </span>
            Columns
          </Button>
        </div>
      </div>
    </>
  );
};

export default TableListSuperAdminFilterBlock;
