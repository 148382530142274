import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import AddProductsTable from "./add-products-table";
import PatientInfo from "./patient-info";
import Disabled from "../../shared/Disabled";
import { addInventoryPOS } from "../../context/actions/pointOfSale";

const AddService = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.user_details);
  const history = useHistory();
  const [formData, setFormData] = useState({});
	const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);
  const { petList } = useSelector(state => state.petReducer)

  const handleFormData = () => {
    const updatedFormData = {
      ...formData,
      // clinic_id: userDetails?._id,
      clinic_id: clinic_settings.clinic_id,
    };
    console.log("pos data: ", updatedFormData);
    dispatch(
      addInventoryPOS(updatedFormData, (res) => {
        history.push("/inventory");
      })
    );
  };
  return (
    <div className="px-3 py-1 space-y-3 ">
      <Disabled />
      {/*
      <header className="flex items-center justify-between py-2 ">
        <button
          onClick={() => {
            history.goBack();
          }}
          className="inline-flex items-center text-sm md:text-base uppercase gap-x-2 text-blue-500 tracking-tighter hover:underline underline-offset-2"
        >
          <FontAwesomeIcon icon={faChevronLeft} fontSize={23} />
          Back to List
        </button>
        <div className="inline-flex gap-x-2 items-center">
          <button className="w-24 uppercase md:w-32 py-2  text-sm md:text-base border duration-300 transition-colors hover:bg-gray-50 rounded-lg">
            Cancel
          </button>
          <button
            onClick={handleFormData}
            className="w-24 uppercase md:w-32 py-2  text-sm md:text-base drop-shadow bg-[#3D3EFD] duration-300 text-white transition-colors hover:bg-blue-600 rounded-lg"
          >
            Save
          </button>
        </div>
      </header>
      <section className="flex flex-col md:flex-row gap-5 ">
        <div className="w-full md:w-1/4 space-y-3">
          <h3 className="text-xl">
            Create New <br /> Point of Sale Order
          </h3>

          <ol className="relative text-gray-500 border-l-[3px] border-[#282E65] ml-4">
            <li className="mb-10 ml-8 space-y-1">
              <span className="absolute flex items-center justify-center w-10 h-10 bg-[#282E65] text-white rounded-full -left-[22px]">
                1
              </span>
              <h3 className="font-medium leading-tight text-gray-800">
                Patient Information
              </h3>
              <p className="text-sm">Add pet and client information</p>
            </li>
            <li className="mb-10 ml-8 space-y-1">
              <span className="absolute flex items-center justify-center w-10 h-10 bg-[#282E65] text-white rounded-full -left-[22px]">
                2
              </span>
              <h3 className="font-medium leading-tight text-gray-800">
                Add Product
              </h3>
              <p className="text-sm">Add all products with quantity to cart</p>
            </li>
            <li className="mb-10 ml-8 space-y-1">
              <span className="absolute flex items-center justify-center w-10 h-10 bg-[#282E65] text-white rounded-full -left-[22px]">
                3
              </span>
              <h3 className="font-medium leading-tight text-gray-800">
                Make Payment
              </h3>
              <p className="text-sm">
                After payment confirmation, client will recieved confirmation on
                mobile number.
              </p>
            </li>
          </ol>
        </div>
        <div className="w-full md:w-3/4 space-y-[2px]">
          <div className="w-full bg-gray-100 rounded-t-lg px-3 py-2 space-y-3">
            <PatientInfo setFormData={setFormData} />
          </div>
          <div className="w-full bg-gray-100 px-3 py-2 relative overflow-hidden">
            <AddProductsTable setFormData={setFormData} />
          </div>
           <div className="w-full bg-gray-100 py-2 px-3 space-y-3">
            <ClientInformation setFormData={setFormData} />
          </div> 
          <div className="w-full bg-gray-100 rounded-b-lg py-2 px-3 space-y-3">
            <h5 className="text-lg font-semibold  text-[#282E65]">
              Make Payment
            </h5>
            <ul>
              <li className="">
                <span className="w-1/2 md:w-44">Payment Mode</span>
                <span className="w-1/2 md:w-56 px-2.5 py-1.5">
                  Cash on Delivery
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>
        */}
    </div>
  );
};

export default AddService;
