import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../context/actions/user";
import { toast } from "react-toastify";
import { inactivityMessage } from "../utils/messages";
import { useIdleTimer } from "react-idle-timer";
import { timeout, toasterConfig } from "../utils/constants";
import Sidebar from "../shared/Sidebar";
import RightSidebar from "../shared/RightSidebar";
import { useToken } from "context/TokenContext";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";

const PrivateLayout = ({ children, className }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  let {user_details} = useSelector((state) => state.user);
  const [user, loading, error] = useAuthState(auth);


  useEffect(() => { 
    if (loading) return;
    else if (!user) {
      dispatch({ type: "LOGOUT", data: {} });
      history.push('/login')

    };}, [user, loading])


  // useEffect(() => { 
  //   if (loading) return;
  //   if (!user) {
  //     dispatch({action: "RESET_APP", data: {}})
  //     history.push('/login')

  //   };}, [user, loading])

  // const { isUpdateToken, setIsUpdateToken } = useToken()
  // useEffect(() => {
  //   setIsUpdateToken(!isUpdateToken)
  // }, [])
  console.log('gets to private layout first')

  // if (!(userDetails || localStorage.getItem("USER"))) {
    
  //   dispatch(
  //     logout(() => {
  //       history.push("/login");
  //     })
  //   );
  // }





  useEffect(()=> {

    if(user_details){
      if(user_details?.role === 'pet-owner') history.push('/pet-owner-health-snapshot')
      } else if(!user_details) {
        dispatch(
          logout(() => {
            history.push("/login");
          })
        );
    }
  }, [user_details])

  useEffect(()=> {

    if(user_details){
      if(user_details?.role === 'pet-owner') history.push('/pet-owner-health-snapshot')
      } else if(!user_details) {
        dispatch(
          logout(() => {
            history.push("/login");
          })
        );
    }
  }, [])

  const onIdle = () => {
    dispatch(
      logout(() => {
        history.push("/login");
        toast.success(inactivityMessage, toasterConfig);
      })
    );
  };
  useIdleTimer({
    timeout,
    onIdle,
  });
  return (
    <>
      <div className="main-wrapper d-flex flex-row flex-fill overflow-auto">
        <Sidebar />
        <div
          className="right-wrapper d-flex flex-column flex-fill overflow-auto main_wrapper1"
          style={{ minHeight: "100vh" , maxHeight: "100vh"}}
        >
          {children}
          {/* 
						{isLoading && <LoaderUI
								loader={isLoading}
								overlay={true}
								overlayRadius='rounded-0'
								size=""
							/>} 
					*/}
        </div>
        <RightSidebar />
      </div>
    </>
  );
};

export default PrivateLayout;
