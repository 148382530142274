import React, { useEffect,  useState } from "react";
import {
    InputGroup,
    Input,
     FormGroup, Label, Col, Button
   } from 'reactstrap';
import moment from "moment";
import { useDispatch } from "react-redux";
import { updateOrder, getOrdersWithCategoryByPetId } from "../../../../../../../context/actions/patient-module/orders";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {orderSchema} from "./schema.js"
import "./index.scss";
import { Formik, Form } from "formik";
import { updateDiagnosticOrder, cancelDiagnosticOrder } from "context/actions/patient-module/diagnostic-testing";


const EditOrderDetails = ({className = '', order = {}, closePanel}) => {

    //const [formVals, setFormVals] = useState({ ...order});
    const params = useParams();

	const petId = params.petId

    const dispatch = useDispatch();

    useEffect(() => {

	}, [])
    const handleUpdate = (formVals) => {
        const {item, service, item_details, service_details, category_id, category, test_details, ...values} = formVals;
        if(values?.first_dose){
            values.first_dose = moment(values?.first_dose).toDate() 
        }
        if(values?.second_dose){
            values.second_dose = moment(values?.second_dose).toDate() 
        }
        values.item =item_details?._id ||item_details?.id  ;

        if(test_details){
            dispatch(updateDiagnosticOrder(values,  (res) => {
                if (res?.status === true){
                    closePanel();
                    dispatch(getOrdersWithCategoryByPetId(petId, () => {}))
                }
    
             } ))
        }
        else  {
        dispatch(updateOrder(values, (res) => {
            if (res?.status) {
                closePanel();
                dispatch(getOrdersWithCategoryByPetId(petId, () => {}))}

         } ))
        }
    }
    const handleCancelDiagnostic = () => {
        if(order){
            dispatch(cancelDiagnosticOrder({_id : order?._id}, (res) => {
                if(res?.status) {
                    closePanel();
                    dispatch(getOrdersWithCategoryByPetId(petId, () => {}))
                }
            }))
        }
    }



    return (
        <div className={`new-order-panel-wrapper position-fixed d-flex flex-column overflow-auto 
        ${className}`}>
            <div className="new-order-details">
                        <h2>{order?.item_details?.product_name || order?.service_details?.service_name}</h2>


                        <Formik
                            initialValues={order}
                            enableReinitialize
                            validationSchema={orderSchema}
                            onSubmit={handleUpdate}
                        >
                        {(formik) => (


                        <Form>


                            {!order?.test_details?.test_name &&

                            <FormGroup row>
                                <Label sm={4} for="status" >Status</Label>
                                <Col sm={8}>
                                    <Input name="status"  id="status" placeholder="Select Status" value={formik.values?.status} onChange={formik.handleChange} type="select">
                                        <option>Select Status</option>
                                        <option>Ordered</option>
                                        <option>Active</option>
                                        <option>Completed</option>
                                        <option>Stopped</option>
                                    </Input>
                                </Col>

                            </FormGroup>}

                            {(order?.category?.category === 'vaccine' || order?.category?.category === 'Infusion' || order?.category?.category === 'medications') ? 
                            <>
                            <FormGroup row>
                                <Label sm={4} for="dose" >Dose</Label>
                                <Col sm={8}> 
                                    <InputGroup id="dose"  >
                                        <Input  name="dose"  type="number" min='1' onChange={formik.handleChange} value={formik?.values?.dose}  placeholder={'Add Dose'}/>
                                        <Input name="form"   id="form" placeholder="Select Unit" onChange={formik.handleChange} value={formik?.values?.form} type="select">
                                        
                                        
                                            <option>Select Form</option>
                                            <option>mL</option>
                                            <option>L</option>
                                            <option>tablet</option>
                                            <option>capsule</option>
                                            <option>mg</option>
                                            <option>g</option>
                                            <option>patch</option>
                                            <option>spray</option>
                                            <option>drop</option>
                                        </Input>
                                        
                                    </InputGroup>
                                </Col>
                            </FormGroup> 

                            {order?.category?.category === 'Infusion' &&

                            <FormGroup row>
                                <Label sm={4} for="rate" >Rate</Label>
                                <Col sm={8}> 
                                    <InputGroup  id="rate">
                                        <Input name="rate_val" type="number" min='1' onChange={formik.handleChange} value={formik?.values?.rate_val}  placeholder={'Add Rate Value'}/>
                                        <Input name="rate_time" placeholder="Select Unit" onChange={formik.handleChange} value={formik?.values?.rate_time} type="select">
                                            <option>Select Rate Duration</option>
                                            <option>mL/hr</option>
                                            <option>L/hr</option>
                                        </Input>
                                    </InputGroup>
                                </Col>
                            </FormGroup> 
                            }

                        
                            <FormGroup row>
                                <Label sm={4} for="route" >Route</Label>
                                <Col sm={8}>
                                    <Input name="route"  id="route" placeholder="Select Route" value={formik?.values?.route} onChange={formik.handleChange} type="select">
                                        <option>Select Route</option>
                                        <option>IM</option>
                                        <option>IV</option>
                                        <option>IV Push</option>
                                        <option>PO</option>
                                        <option>SubQ</option>
                                        <option>Rectal</option>
                                        <option>Inhalation</option>
                                        <option>Nasal</option>
                                        <option>Ocular</option>
                                        <option>Otic</option>
                                        <option>Topical</option>
                                        <option>Transdermal</option>
                                    </Input>
                                </Col>

                            </FormGroup>

                            <FormGroup row>
                                <Label sm={4} for="prn" >PRN</Label>
                                <Col sm={8}>
                                    <Input name="prn" id="prn" type="checkbox" checked={formik?.values?.prn} onChange={(e) =>  formik?.setFieldValue('prn', e.target.checked)}  />
                                </Col>
                            </FormGroup>
                            </>:
                            <>
                            </>}

                            {

                            order?.category?.category !== 'vaccine'   && !order?.test_details?.test_name && 

                            <FormGroup row>
                                <Label sm={4} for="frequency" >Frequency</Label>
                                <Col sm={8}>
                                    <Input name="frequency" id="frequency" placeholder="Select Frequency" value={formik?.values?.frequency} onChange={formik.handleChange} type="select">
                                        <option>Select Frequency</option>
                                        <option>q5m</option>
                                        <option>q15m</option>
                                        <option>q30m</option>
                                        <option>q2h</option>
                                        <option>q4h</option>
                                        <option>q6h</option>
                                        <option>q8h</option>
                                        <option>q12h</option>
                                        <option>q24h</option>
                                        <option>daily</option>
                                        <option>daily at bedtime</option>
                                        <option>BID</option>
                                        <option>TID</option>
                                        <option>QID</option>
                                        <option>every other day</option>
                                        <option>weekly</option>
                                        <option>bi-weekly</option>
                                        <option>monthly</option>
                                        <option>once</option>
                                    </Input>
                                </Col>

                            </FormGroup>

                            }

                            <FormGroup row>
                                <Label sm={4} for="first-dose" >{`${(order?.category?.category === 'vaccine' || order?.category?.category === 'Infusion' || order?.category?.category === 'medications') ?  'First Dose' : order?.test_details?.test_name ? 'Speciment Collection' : 'Start'} Date & Time`}</Label>
                                <Col sm={8}>
                                    <Input name="first_dose" id="first-dose" value={moment(formik?.values?.first_dose).format(moment.HTML5_FMT.DATETIME_LOCAL)} onChange={formik.handleChange} type="datetime-local" />
                                </Col>
                            </FormGroup>

                           

                            { (order?.category?.category !== 'vaccine' && formik?.values?.frequency !== 'once' && !order?.test_details) && 
                            <FormGroup row>
                                <Label sm={4}for="duration" >Duration</Label>
                                <Col sm={8}>
                                    <InputGroup id="duration">
                                        <Input name="duration_num" type="number" min="1" placeholder="Add Duration" onChange={formik.handleChange}value={formik?.values?.duration_num} />
                                        <Input name="duration_unit" placeholder="Select Duration" onChange={formik.handleChange} value={formik?.values?.duration_unit} type="select">
                                            <option>Select Unit</option>
                                            <option>Days</option>
                                            <option>Weeks</option>
                                        </Input>
                                    </InputGroup>
                                </Col>
                            </FormGroup> }

                            <FormGroup row>
                                <Label sm={4} for="notes" >Notes</Label>
                                <Col sm={8}>   
                                    <Input name="notes" id="notes" placeholder="Add Notes"value={formik?.values?.notes} onChange={formik?.handleChange} />
                                </Col>
                            </FormGroup>

                    <div className="button-section">
                        <div className="close-btn" onClick={closePanel}>
                                <i className="icomoon-cross"></i>
                        </div>
                        <div className="submit-btn" onClick={formik.handleSubmit}>
                            <i className="icomoon-check"></i>
                        </div>
                    </div>
                    {order?.idexx_code && 

                        <Button className="close-btn" style={{width: '132px', marginTop: '10px'}} onClick={handleCancelDiagnostic} color="danger">Cancel</Button>
                    }
                  

                    </Form> )}
                    </Formik>
            </div>
        </div>
    )
}

export default EditOrderDetails;