import React from "react";
import { format } from "date-fns";
import ImagePlaceholder from '../../../../../../../../shared/ImagePlaceholder';

const StaffProfileExperienceItem = ({ experience }) => {

	return (
		<>
			<div className="staff-profile-education-item d-flex h-100">
				<div className="pic d-inline-flex overflow-hidden flex-shrink-0">
					<img className="img-fluid" src={require('../../../../../../../../assets/img/vetRecorEMR/logo-thumbnail3.png')} alt="" />
					{/* <ImagePlaceholder /> */}
				</div>
				<div className="info">
					<h2 className="text-14-400 color-2E65">{experience?.company_name}</h2>
					<h3 className="text-14-400 color-8693">{experience?.designation}/{experience?.position}</h3>
					<h3 className="text-14-400 color-8693">{format(new Date(experience?.year_from), 'MMM yyyy')} - {experience?.designation ? 'Present' : format(new Date(experience?.year_to), 'MMM yyyy')}</h3>
					{/* <h3>Jul 2020 <span className="hyphen">-</span> Jun 2022 <span className="dot">·</span> 2 yrs</h3> */}
					<h3 className="text-14-400 color-8693">{experience?.company_address}</h3>
					{/* <ul className="thumbnail-list list-unstyled d-flex">
						<li>
							<span className="d-inline-flex overflow-hidden">
								<img className="img-fluid" src={require('../../../../../../../../assets/img/vetRecorEMR/certificate-thumbnail-sm.png')} alt="" />
							</span>
						</li>
						<li>
							<span className="d-inline-flex overflow-hidden">
								<img className="img-fluid" src={require('../../../../../../../../assets/img/vetRecorEMR/certificate-thumbnail-sm.png')} alt="" />
							</span>
						</li>
					</ul> */}
				</div>
			</div>
		</>
	);
};

export default StaffProfileExperienceItem;
