
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../../../api-client";
import { apiUrl, PORT } from "../../../../environment";
import { toasterConfig } from "../../../../utils/constants";

toast.configure();
/**
 * All Api endpoints to be call
*/

/**
 * get all vaccination dues details
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns 
 */
export const getAllPetDocumentsByPetId = (petId, params, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, } = getState();
        if (petId) {
            dispatch({ type: "PET_DOCUMENT_LOADING", payload: true })
            ApiClient.get(`${apiUrl}${PORT}/pet-document/get-all/${petId}`, params, token, dispatch, getState).then((response) => {
                dispatch({ type: "PET_DOCUMENT_LOADING", payload: false })
                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    dispatch({ type: "PET_DOCUMENT_LIST", payload: response?.response_data })
                    return callback(response);
                } else {
                    dispatch({ type: "PET_DOCUMENT_LIST", payload: [] })
                    return callback(response);
                }
            })
        } else {
            return callback({ status: false });
        }
    };
};


/**
 * get all vaccination dues details
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns 
 */
export const updatePetDocument = (formData, petId, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, } = getState();

        dispatch({ type: "PET_DOCUMENT_LOADING", payload: true })
        ApiClient.putFormData(`${apiUrl}${PORT}/pet-document/update`, formData, token, dispatch, getState).then((response) => {
            dispatch({ type: "PET_DOCUMENT_LOADING", payload: false })
            if (!response) {
                return callback();
            }
            else if (response?.status) {
                toast.success(response.message, toasterConfig);
                dispatch(getAllPetDocumentsByPetId(petId))
                // dispatch({ type: "PET_DOCUMENT_LIST", payload: response?.response_data })
                return callback(response);
            } else {
                //toast.error(response.message, toasterConfig);

                return callback(response);
            }
        })

    };
};


/**
 * get all vaccination dues details
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns 
 */
export const addNewPetDocument = (formData, petId, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, } = getState();

        dispatch({ type: "PET_DOCUMENT_LOADING", payload: true })
        ApiClient.postFormData(`${apiUrl}${PORT}/pet-document/add-new`, formData, token, dispatch, getState).then((response) => {
            dispatch({ type: "PET_DOCUMENT_LOADING", payload: false })
            if (!response) {
                return callback();
            }
            else if (response?.status) {
                toast.success(response.message, toasterConfig);
                dispatch(getAllPetDocumentsByPetId(petId))
                // dispatch({ type: "PET_DOCUMENT_LIST", payload: response?.response_data })
                return callback(response);
            } else {
                //toast.error(response.message, toasterConfig);

                return callback(response);
            }
        })

    };
};

/**
 * get all vaccination dues details
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns 
 */
export const addNewPetDocuments = (formData, petId, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, } = getState();

        dispatch({ type: "PET_DOCUMENT_LOADING", payload: true })
        ApiClient.postFormData(`${apiUrl}${PORT}/pet-document/add-new-documents`, formData, token, dispatch, getState).then((response) => {
            dispatch({ type: "PET_DOCUMENT_LOADING", payload: false })
            if (!response) {
                return callback();
            }
            else if (response?.status) {
                toast.success(response.message, toasterConfig);
                dispatch(getAllPetDocumentsByPetId(petId))
                // dispatch({ type: "PET_DOCUMENT_LIST", payload: response?.response_data })
                return callback(response);
            } else {
                //toast.error(response.message, toasterConfig);

                return callback(response);
            }
        })

    };
};


/**
 * @description function use for to delete organization logo
 * @param {*} params accepting object
 */
export const deletePetDocumentAction = (document_id, pet_id, callback) => {

    return (dispatch, getState) => {
        const {
            user: { token },
        } = getState();
        ApiClient.delete(`${apiUrl}${PORT}/pet-document/delete/${document_id}`, token, dispatch, getState).then(
            (response) => {
                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    toast.success(response.message, toasterConfig);
                    dispatch(getAllPetDocumentsByPetId(pet_id))
                    // dispatch({ type: "PET_DOCUMENT_LIST", payload: response?.response_data })
                    return callback(response);
                } else {
                    //toast.error(response.message, toasterConfig);

                    return callback(response);
                }
            }
        );
    };
};