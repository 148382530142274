import React from "react";
import { Input } from "reactstrap";
import "./index.scss";

const DropdownBlock = ({ className = '', listOptions = [], setSelectedPet }) => {

	const handleSelectChange = (e) => {
		setSelectedPet(e.target.value)
	}

	return (
		<>
			<div className={`dropdown-blc input-cell ${className}`}>
				<Input type="select" name="select" placeholder="Select Pet" onChange={handleSelectChange}>
					<option value="">All Pets</option>
					{
						listOptions && listOptions.map((ele) => {
							return (
								<option value={ele._id}>{ele.pet_name}</option>
							)
						})
					}
				</Input>
			</div>
		</>
	);
};

export default DropdownBlock;
