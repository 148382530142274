import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from "./../firebase"
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { logout } from './actions/user';

const TokenContext = createContext();

export const TokenProvider = ({ children }) => {
    const [isUpdateToken, setIsUpdateToken] = useState(true)
    const tokenContextValue = useTokenProvider(isUpdateToken);
    const value = { tokenContextValue, isUpdateToken, setIsUpdateToken }
    return (
        <TokenContext.Provider value={value}>
            {children}
        </TokenContext.Provider>
    );
};

export const useToken = () => {
    return useContext(TokenContext);
};

const useTokenProvider = (isUpdateToken) => {
    const dispatch = useDispatch()
    const history = useHistory()


    useEffect(() => {


        auth.onAuthStateChanged(function(user) {
            console.log(user, 'on auth state fire user')
            if(user){
                console.log('user is logged in on auth state fire user')
    
            }
            else{
               // dispatch(logout(() => {
                console.log('user is logged out on auth state fire user')

                // dispatch({ type: "LOGOUT", data: {} });


                //     history.push("/login")
                //}));
            }
        })
        console.log(auth.currentUser, 'fire user token context')
        if(!auth.currentUser) {
            // dispatch(logout(() => {
            //     dispatch({ type: "LOGOUT", data: {} });

            //     history.push("/login")
            // }));
        }
    }, [])
    useEffect(() => {
        const unsubscribe = auth.onIdTokenChanged(async (user) => {
            if (user) {
                const idTokenResult = await user.getIdTokenResult();
                const creationTime = idTokenResult.expirationTime;
                let exTime = moment(creationTime);
                let bTime = exTime.subtract(2, 'minutes')
                if (moment().isAfter(bTime)) {
                    try {
                        const refreshedToken = await user.getIdToken(true);
                        dispatch({ type: "UPDATE_TOKEN", data: refreshedToken });
                        //localStorage.setItem("TOKEN", JSON.stringify(refreshedToken));
                    } catch (error) {
                        console.error('Error refreshing token:', error);
                    }
                }
            }
        });

        return () => unsubscribe();
    }, [isUpdateToken]);

    return {};
};
