const initialState = {
  doctorAvailabilites: [],
};

export const doctorAvailabilitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_DOCTOR_AVAILABILITIES": {
      return { ...state, doctorAvailabilites: action.payload };
    }

    default:
      return state;
  }
};
