import React, { useEffect, useState } from "react";
import {
  Button,
  // ButtonDropdown,
  // DropdownToggle,
  Input,
  Modal,
  Spinner,
  Table,
} from "reactstrap";
import CheckBox from "../../../../../shared/CheckBox";
import PaginationBlock from "../../../../../shared/PaginationBlock";
import { useHistory } from "react-router-dom";
import goldenBach from "../../../../../assets/img/vetRecorEMR/pet-badge-icon-yellow.svg";
import { useDispatch } from "react-redux";
import {
  DeleteClinic,
  getAllSubClinic,
} from "../../../../../context/actions/clinic";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { petGenderValues } from "../../../../../utils/constants";
import AddNewClinicPanel from "../../AddNewClinicPanel";
import { getClinicPermission } from "context/actions/clinicSettings";
import {
  BsFillTrashFill,
  BsDownload,
} from "react-icons/bs";
import { exportCSVFile, formatPhoneNumber } from "../../../../../utils/common";

const ClinicBranch = ({ isAdd, setIsAdd }) => {
  const { clinicId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  const [isSubClinicForm, setIsSubClinicForm] = useState(false);
  const [subClinicEditData, setSubClinicEditData] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [IsDelete, setIsDelete] = useState("");
  const [isModal, setIsModal] = useState(false);

  const [timer, setTimer] = useState("");
  const [filterData, setFilterData] = useState({
    searchText: "",
    // city: "",
    // active: "0",
    // gender: "",
    // role: "",
    page: 1,
    limit: 10,
    data: [],
    totalPages: 0,
    totalDocs: 0,
  });

  const [ids, setIds] = useState("");
  const handleCheckBox = (type, id) => {
    if (type) {
      if (ids.length) {
        console.log("type",type)
        setIds([]);
      } else {
        setIds(filterData?.data?.map((o) => o._id));
        // setIds(filterData?.data?.filter((o) => !o?.is_master_clinic && o?._id));
      }
    } else {
      if (ids.includes(id)) {
        setIds(ids.filter((o) => o !== id));
      } else {
        setIds([...ids, id]);
      }
    }
  };
  const handleProfileClick = (item) => {
    const path = `/super-admin/clinic-setting/${item?._id}`;

    localStorage.setItem("super_clinic_id", clinicId)
    history.push(path);


    
    dispatch({ type: "GET_CLINIC", payload: item });
    dispatch({
      type: "SET_PRIMARY_CLINIC",
      payload: item?._id || "",
    });

    dispatch(getClinicPermission('admin',  ()=>{}))



  
  };
  const handleFilterChange = (e) => {
    const fielsName = e.target.name;
    const fielsValue = e.target.value;
    let oldData = filterData;
    oldData = {
      ...oldData,
      page: 1,
      totalPages: 0,
      totalDocs: 0,
      [fielsName]: fielsValue,
    };
    setFilterData(oldData);
    getClinics(oldData);
  };
  const handleSearch = (e) => {
    let value = e.target.value;
    let oldData = filterData;
    oldData = {
      ...oldData,
      page: 1,
      totalPages: 0,
      totalDocs: 0,
      searchText: value,
    };
    setFilterData(oldData);
    let time = timer;
    clearTimeout(time);
    time = setTimeout(() => {
      getClinics(oldData);
    }, 800);
    setTimer(time);
  };
  const getClinics = async (filterObject) => {
    let payload = filterObject;
    delete payload.data;
    delete payload.totalPages;
    delete payload.totalDocs;
    const queryParams = new URLSearchParams(payload).toString();
    const response = await dispatch(getAllSubClinic(clinicId, queryParams));
    setIds([]);
    setFilterData((prev) => {
      return {
        ...prev,
        page: response?.response_data?.page || 0,
        limit: response?.response_data?.limit || 0,
        totalPages: response?.response_data?.totalPages || 0,
        totalDocs: response?.response_data?.totalDocs || 0,
        data: response?.response_data?.docs || [],
      };
    });
  };

  const handleDeleteClinic = async () => {
    console.log("handleDeleteClinic-2")
    setDeleteLoading(true);
    let filterIds = ids.filter((items)=>items !== clinicId)
    let payload = IsDelete ? [IsDelete] : filterIds;
    const response = await dispatch(DeleteClinic({ clinicIds: payload }));
    if (response?.status) {
      getClinics(filterData);
    }
    setDeleteLoading(false);
    setIsModal(false);
    setIsDelete("");
    setIds("");
  };
  const header = ["Clinic Name","Subscription Plan", "activeUser", "Phone", "email","City"];

  const downloadCsvFile = () => {
    const userCheck = filterData?.data?.filter((ele) =>ids.includes(ele._id)
    );
    const csvBody = [];
    userCheck?.forEach((o) => {
      const { clinic_name, activeUser, phone, email }=o;
      csvBody.push([
        clinic_name,
        "-",
        activeUser,
        phone,
        email,
        "-",
      ]);
    });
    exportCSVFile(header, csvBody, "clinicStaff.csv");
  };
  useEffect(() => {
    if (subClinicEditData && !isSubClinicForm) {
      setIsSubClinicForm(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subClinicEditData]);

  useEffect(() => {
    if (isAdd) {
      setIsSubClinicForm(true);
    }
  }, [isAdd]);

  useEffect(() => {
    getClinics(filterData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data = [],
    limit = 0,
    page = 0,
    totalPages = 0,
    totalDocs = 0,
    gender,
    active,
    searchText,
  } = filterData;
  return (
    <>
      <Modal isOpen={isModal ? true : false}>
        <div className="relative">
          <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
            <svg
              className="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
            <p className="mb-4 text-gray-500 dark:text-gray-300">
              Are you sure you want to delete this item?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <button
                onClick={() => {
                  setIsModal(false);
                }}
                data-modal-toggle="deleteModal"
                type="button"
                className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                No, cancel
              </button>
              <button
                onClick={handleDeleteClinic}
                type="submit"
                className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900"
              >
                Yes, I'm sure{" "}
                {deleteLoading && (
                  <Spinner animation="border" role="status" size="sm" />
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div>
        <div
          className={`new-clinic-panel-overlay position-fixed ${
            isSubClinicForm || subClinicEditData ? "active" : ""
          }`}
        >
          <AddNewClinicPanel
            is_master_clinic={false}
            master_clinic_id={clinicId}
            isShow={isSubClinicForm || subClinicEditData}
            editData={subClinicEditData}
            handelSuccess={() => {
              setIsSubClinicForm(false);
              setIsAdd(false);
              setSubClinicEditData(null);
              getClinics(filterData);
            }}
            closePanel={() => {
              setIsSubClinicForm(false);
              setIsAdd(false);
              setSubClinicEditData(null);
            }}
          />
        </div>
      </div>

      <div className="table-listing-blc overflow-auto">
        <div
          className={`table-list-patients-filter-blc-wrapper d-flex align-items-center overflow-auto flex-shrink-0`}
        >
          <div className="left-col">
            <div className="table-list-filter-blc d-flex align-items-center">
              <div className="search-cell col-cell !w-40 position-relative mb-0">
                <span className="icomoon-search d-inline-flex align-items-center justify-content-center position-absolute"></span>
                <Input
                  type="text"
                  placeholder="Search"
                  value={searchText}
                  onChange={handleSearch}
                />
              </div>
              <div className="input-cell col-cell">
                <Input type="select" name="select" value="" onChange={() => {}}>
                  <option value="">All Cities</option>
                  {[].map((item, i) => (
                    <option key={i} value={item?.city}>
                      {item?.city}
                    </option>
                  ))}
                </Input>
              </div>
             
              <div className="input-cell col-cell">
                <Input
                  type="select"
                  name="active"
                  value={active}
                  onChange={handleFilterChange}
                >
                  <option value="">All Status</option>
                  <option value="0">Active</option>
                  <option value="1">Deactive</option>
                </Input>
              </div>
              
            </div>
          </div>
          <div className="right-col ms-auto">
            <div className="d-flex gap-2">
              {ids.length > 0 && (
                <>
                  <div className="d-flex align-items-center gap-2 border border-danger rounded text-danger ps-2 pe-2 cursor-pointer text-xs" onClick={()=>{setIsModal(true)}}>
                    <span className="d-inline-flex mb-0">
                      <BsFillTrashFill size={14} />
                    </span>
                    <span className="mb-0">Delete</span>
                  </div>
                  <div className="d-flex align-items-center gap-2 border border-dark rounded text-dark ps-3 pe-3 cursor-pointer text-xs" onClick={downloadCsvFile}>
                    <span className="d-inline-flex mb-0">
                      <BsDownload size={14} />
                    </span>
                    <span className="mb-0">CSV</span>
                  </div>
                </>
              )}

              <Button
                color="link"
                className="list-view-type-btn d-inline-flex align-items-center"
              >
                <span className="d-inline-flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="10"
                    fill="none"
                    viewBox="0 0 18 10"
                  >
                    <path
                      fill="#788693"
                      d="M1.074 1.75a.74.74 0 01-.525-.212A.706.706 0 01.324 1C.324.784.4.605.55.464A.74.74 0 011.074.25h12.2a.726.726 0 01.75.75.726.726 0 01-.75.75h-12.2zm0 4a.74.74 0 01-.525-.212A.706.706 0 01.324 5a.71.71 0 01.225-.538.742.742 0 01.525-.212h12.2a.728.728 0 01.75.75.726.726 0 01-.75.75h-12.2zm0 4c-.2 0-.375-.07-.525-.211A.71.71 0 01.324 9a.71.71 0 01.225-.538.742.742 0 01.525-.212h12.2a.728.728 0 01.75.75.728.728 0 01-.75.75h-12.2zm15.775.076a.84.84 0 01-.6-.238A.777.777 0 0116 9a.8.8 0 01.238-.588.797.797 0 01.587-.237c.234 0 .434.079.6.237a.78.78 0 01.25.588.797.797 0 01-.237.587.799.799 0 01-.588.238zm0-8a.84.84 0 01-.6-.238A.777.777 0 0116 1a.8.8 0 01.238-.588.797.797 0 01.587-.237c.234 0 .434.079.6.237a.78.78 0 01.25.588.797.797 0 01-.237.587.799.799 0 01-.588.238zm0 4a.84.84 0 01-.6-.238A.777.777 0 0116 5a.8.8 0 01.238-.588.797.797 0 01.587-.237c.234 0 .434.079.6.237a.78.78 0 01.25.588.797.797 0 01-.237.587.799.799 0 01-.588.238z"
                    ></path>
                  </svg>
                </span>
                Columns
              </Button>
            </div>
          </div>
        </div>
        <Table striped responsive className="checkbox-listing">
          <thead>
            <tr>
              <th>
                <CheckBox
                  type="checkbox"
                  className="no-label"
                  checked={ids.length > 0 && ids.length === data.length}
                  onChange={() => {
                    handleCheckBox("All",);
                  }}
                />
              </th>
              <th style={{ minWidth: "90px" }}>Clinic Name</th>
              <th style={{ minWidth: "97px" }}>Subscription Plan</th>
              <th style={{ minWidth: "100px" }}>Active Users</th>
              <th style={{ minWidth: "144px" }}>Phone</th>
              <th style={{ minWidth: "130px" }}>Email</th>
              <th style={{ minWidth: "80px" }}>City</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, i) => {
              const {
                _id: id,
                clinicLogo,
                clinic_name,
                activeUser,
                phone,
                email,
                is_master_clinic,
              } = item;
              return (
                <tr key={i}>
                  <td>
                    <CheckBox
                      type="checkbox"
                      className="no-label"
                      checked={ids.includes(id)}
                      onChange={() => {
                        handleCheckBox("", item?._id);
                      }}
                    />
                  </td>
                  <td>
                    <Button
                      color="link"
                      className="p-0 text-start pic-cell d-inline-flex align-items-center w-100"
                      onClick={() => {
                        handleProfileClick(item);
                      }}
                    >
                      <div className="pic d-inline-flex position-relative">
                        <img
                          className="img-fluid rounded-circle ! w-100 h-100"
                          src={clinicLogo}
                          alt="User"
                        />
                      </div>
                      {clinic_name}
                    </Button>
                  </td>
                  <td>
                    {item?.subscription_plan ? (
                      <div className="pic-cell d-inline-flex align-items-center w-100">
                        <div className="pic d-inline-flex position-relative">
                          <img
                            className="img-fluid rounded-circle !"
                            src={goldenBach}
                            alt="Patient"
                          />
                        </div>
                        Golden Care
                      </div>
                    ) : (
                      <>-</>
                    )}
                  </td>
                  <td>{activeUser}</td>
                  <td>{formatPhoneNumber(phone)}</td>

                  <td>{email}</td>
                  <td>-</td>
                  <td className="action-cell d-flex align-items-center">
                    
                        < > <Button
                        color="link"
                        className="edit-btn"
                        disabled={is_master_clinic}
                        onClick={() => {
                          setSubClinicEditData(item);
                        }}
                      >
                        <span className="icomoon-pencil"></span>
                      </Button>
                       <Button
                       color="link"
                       disabled={is_master_clinic}
                       onClick={(e) => {
                         e.preventDefault();
                         !is_master_clinic &&  setIsDelete(item?._id);
                         !is_master_clinic &&  setIsModal( true);
                       }}
                     >
                       <span className="d-flex align-items-center gap-2">
                         <span>
                       <BsFillTrashFill />  
                        
                         </span>
                       </span>
                     </Button>
                     </>
                 
                   
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <PaginationBlock
          limit={limit}
          page={page}
          pagingCounter={1}
          docLength={1}
          docPage={1}
          totalPages={totalPages}
          totalDocs={totalDocs}
          _handleLimit={() => {}}
          _handlePageChange={(pageNumber) => {
            let oldData = filterData;
            oldData.page = pageNumber;
            setFilterData(oldData);
            getClinics(oldData);
          }}
        />
      </div>
    </>
  );
};

export default ClinicBranch;
