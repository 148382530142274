import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import Overview from "./Overview";
import Notes from "./Notes";
import Flowsheets from "./Flowsheets";
import { useParams } from "react-router-dom";
import MedicalHistory from "./MedicalHistory";
import Documents from "./Documents";
import DiagnosticTesting from "./DiagnosticTesting";
import Visits from "./Visits";
import Orders from "./Orders";
import Mar from "./Mar";
import Messages from "./Messages";
import { useSelector } from "react-redux";
import CarePlans from "./CarePlans";
// import "./index.scss";

const TabScreens = () => {
  const params = useParams();
  const petId = params.petId;
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  return (
    <>
      <div className="tab-wrapper tab-patient-detail d-flex flex-column flex-fill overflow-auto">
        <Nav tabs className="flex-shrink-0">
          {/* <NavItem>
						
					</NavItem> */}
          <NavItem>
            {permissions?.view_medical_history && (
              <NavLink
                className={{ active: activeTab === "1" }}
                onClick={() => {
                  toggle("1");
                }}
              >
                Medical History
              </NavLink>
            )}
            {permissions?.view_patient_notes && (
              <NavLink
                className={{ active: activeTab === "2" }}
                onClick={() => {
                  toggle("2");
                }}
              >
                Notes
              </NavLink>
            )}

            {permissions?.view_flowsheets && (
              <NavLink
                className={{ active: activeTab === "3" }}
                onClick={() => {
                  toggle("3");
                }}
              >
                Flowsheets
              </NavLink>
            )}

            {/*permissions?.view_messages && <NavLink
							className={({ active: activeTab === '4' })}
							onClick={() => { toggle('4'); }}
						>
							Messages
					</NavLink>*/}

            {permissions?.view_visits && (
              <NavLink
                className={{ active: activeTab === "5" }}
                onClick={() => {
                  toggle("5");
                }}
              >
                Visits
              </NavLink>
            )}
            {permissions?.view_diagnostic_testing && (
              <NavLink
                className={{ active: activeTab === "7" }}
                onClick={() => {
                  toggle("7");
                }}
              >
                Diagnostic Testing
              </NavLink>
            )}

            {permissions?.view_orders && (
              <NavLink
                className={{ active: activeTab === "8" }}
                onClick={() => {
                  toggle("8");
                }}
              >
                Orders
              </NavLink>
            )}

            {permissions?.view_MAR && (
              <NavLink
                className={{ active: activeTab === "9" }}
                onClick={() => {
                  toggle("9");
                }}
              >
                MAR
              </NavLink>
            )}

            {
              // permissions?.view_documents &&
              <NavLink
                className={{ active: activeTab === "10" }}
                onClick={() => {
                  toggle("10");
                }}
              >
                Care Plans
              </NavLink>
            }
            {permissions?.view_documents && (
              <NavLink
                className={{ active: activeTab === "11" }}
                onClick={() => {
                  toggle("11");
                }}
              >
                Documents
              </NavLink>
            )}

            {/* {
							permissions?.view_patient_overview && <NavLink
								className={({ active: activeTab === '12' })}
								onClick={() => { toggle('12'); }}
							>
								Overview
							</NavLink>
						} */}
          </NavItem>
        </Nav>
        <TabContent
          activeTab={activeTab}
          className="d-flex flex-column flex-fill overflow-auto position-relative"
        >
          {/* <LoaderUI FullWindow={false} inContainer={true} overlay={true} /> */}
          {/* <NodataFoundBlock className="h-100" /> */}

          {permissions?.view_medical_history && (
            <TabPane tabId="1">
              <MedicalHistory />
            </TabPane>
          )}

          {permissions?.view_patient_notes && (
            <TabPane tabId="2">
              {/* <NotesInfo /> */}
              <Notes />
            </TabPane>
          )}
          {permissions?.view_flowsheets && (
            <TabPane tabId="3" className="h-100">
              <Flowsheets petId={petId} />
            </TabPane>
          )}
          {permissions?.view_messages && (
            <TabPane tabId="4">
              <Messages />
            </TabPane>
          )}

          {permissions?.view_visits && (
            <TabPane tabId="5">
              <Visits petId={petId} />
            </TabPane>
          )}

          {permissions?.view_diagnostic_testing && (
            <TabPane tabId="7">
              <DiagnosticTesting petId={petId} />
            </TabPane>
          )}
          {permissions?.view_orders && (
            <TabPane tabId="8">
              <Orders />
            </TabPane>
          )}

          {permissions?.view_MAR && (
            <TabPane tabId="9">
              <Mar petId={petId} />
            </TabPane>
          )}

          {
            (permissions?.view_medication_plans ||  permissions?.view_patient_treatment_plans ) &&
            <TabPane tabId="10">
              <CarePlans petId={petId} />
            </TabPane>
          }
          {permissions?.view_documents && (
            <TabPane tabId="11">
              <Documents petId={petId} />
            </TabPane>
          )}
          {/* {permissions?.view_patient_overview && (
            <TabPane tabId="12">
              <Overview petId={petId} />
            </TabPane>
          )} */}
        </TabContent>
      </div>
    </>
  );
};

export default TabScreens;
