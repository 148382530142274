import React from "react";
import "./index.scss";
import { ReactComponent as ComingSoon } from "../../assets/img/vetRecor/coming-soon.svg";

const Disabled = ({ className }) => (
  <>
    <div
      className={`coming-soon-wrapper d-flex flex-column flex-fill align-items-center justify-content-center text-center ${className}`}
    >
      <div className="inner mh-100">
        <div className="pic">
          <ComingSoon />
        </div>
        <div className="txt">
          <h2>Sorry! This feature is currently disabled</h2>
          <h3>Please contact your administrator to enable it</h3>
          <h4>
            Contact us at{" "}
            <a href="mailto:support@vetrecor.com">support@vetrecor.com</a>
          </h4>
        </div>
      </div>
    </div>
  </>
);

export default Disabled;
