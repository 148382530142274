import React from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import CheckBox from "../../../../shared/CheckBox";
import ImagePlaceholder from "../../../../shared/ImagePlaceholder";
import NodataFoundBlock from "../../../../shared/NoDataFoundBlock";
import { dateFromDateTime } from "../../../../utils/common";
import '../common-block-info.scss';
import { IoMdAddCircle } from "react-icons/io"

const GeneralInfoBlock = () => {
	const { inventoryDetails } = useSelector(state => state.inventoryReducer);

	const { permissionsData } = useSelector((state) => state.clinicPermissionReducer);
	const permissions = permissionsData;
	return (
		<>
			{!inventoryDetails ? <NodataFoundBlock /> :
				<div className="general-info position-relative">
					<h3 className="section-ttl">General Information</h3>
					<div className="inner">
						<div className="item-rw d-flex">
							<div className="label-cell">
								Product Name
							</div>
							<div className="desc-cell flex-fill">
								{inventoryDetails?.product_name}
							</div>
						</div>
						<div className="item-rw d-flex">
							<div className="label-cell">
								SKU
							</div>
							<div className="desc-cell flex-fill">
								{inventoryDetails?.sku}
							</div>
						</div>
						<div className="item-rw d-flex">
							<div className="label-cell">
								Category
							</div>
							<div className="desc-cell flex-fill" >
								{/* {inventoryDetails?.categoryDetails?.category} */}
								{inventoryDetails?.category?.category}
							</div>
						</div>
						<div className="item-rw d-flex">
							<div className="label-cell">
							Unit of measure
							</div>
							<div className="desc-cell flex-fill" >
								{/* {inventoryDetails?.categoryDetails?.category} */}
								{inventoryDetails?.unit_of_measure}
							</div>
						</div>
						<div className="item-rw d-flex">
							<div className="label-cell">
							Sub Package
							</div>
							<div className="desc-cell flex-fill" >
								{/* {inventoryDetails?.categoryDetails?.category} */}
								{inventoryDetails?.sub_package?.amount}{inventoryDetails?.sub_package?.unit_of_measure}
							</div>
						</div>
						<div className="item-rw d-flex">
							<div className="label-cell">
								Description
							</div>
							<div className="desc-cell flex-fill">
								{inventoryDetails?.description || '--'}
							</div>
						</div>
						<div className="item-rw d-flex">
							<div className="label-cell">
								Manufacturer Info.
							</div>
							<div className="desc-cell flex-fill" style={{textTransform: 'capitalize'}}>
								{inventoryDetails?.manufacture || '--' }
							</div>
						</div>
						
						
						<div className="item-rw d-flex">
							<div className="label-cell">
								Required for Scan Out
							</div>
							<div className="desc-cell flex-fill">
								<CheckBox checked={inventoryDetails?.vettech_scanout} label="Vet Tech" />
								<CheckBox checked={inventoryDetails?.doctor_scanout} label="Doctor" />
							</div>
						</div>
						<div className="item-rw d-flex">
							<div className="label-cell">
								Tags
							</div>
							<div className="desc-cell flex-fill">
								<div className="tags">
									{(inventoryDetails?.tags || []).map((item, i) =>
										<span key={i}>{item}</span>
									)}
								</div>
							</div>
						</div>
						{permissions?.edit_products &&
							<div className="item-rw d-flex ">
								<div className="label-cell">
									<img className="rounded-lg" src={inventoryDetails?.image} />
								</div>
								<button className="border-dashed border-2 rounded-lg p-[20px] border-gray-400 flex items-center justify-center">
									{/* <span className="material-icons">
										add_circle
									</span> */}
									<IoMdAddCircle size={25} color="#11CA9D" />
								</button>
								{/* <div className="desc-cell flex-fill">
									<div className="tags">
										{(inventoryDetails?.tags || []).map((item, i) =>
											<span key={i}>{item}</span>
										)}
									</div>
								</div> */}
							</div>
						}
						<div className="product-pics d-flex overflow-auto">
							<div className="item-list d-flex overflow-auto">
								{
									(inventoryDetails?.tag_images || []).map((item, i) => {
										return (
											<div key={i} className="item overflow-hidden flex-shrink-0">
												<img className="w-100 h-100" src={item} alt="Product" />
												<ImagePlaceholder />
											</div>
										)
									})
								}
								{(!inventoryDetails?.tag_images || inventoryDetails?.tag_images?.length == 0) && <ImagePlaceholder />}
							</div>
							{/* <Button color="link" className="add-btn radius d-flex align-items-center justify-content-center">
								<span className="material-icons">
									add_circle
								</span>
							</Button> */}
						</div>
					</div>
					<div className="qr_img">
						<img src={require('../../../../assets/img/qr.png')} alt="img" />
					</div>
				</div>}
		</>
	);
};

export default GeneralInfoBlock;
