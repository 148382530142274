const keysForAppointments = [
    { id: 'view_appointments', label: "Can view patient's appointment" },
    { id: 'edit_appointments', label: "Can edit patient's appointment" }
]

const keysForPatients = [
    { id: "view_patients", label: "Can view patients list" },
    { id: "add_patients", label: "Can add patient" },
    { id: "view_patients_details", label: "Can view patient's details" },
    { id: "edit_patients_details", label: "Can edit patient's details" },
    { id: "view_patient_overview", label: "Can view patient's overview" },
    { id: "edit_patient_overview", label: "Can edit patient's overview" },
    { id: "view_patient_notes", label: "Can view patient's notes" },
    { id: "edit_patient_notes", label: "Can edit patient's notes" },
    { id: "add_patient_notes", label: "Can add patient's notes" },
    { id: "view_flowsheets", label: "Can view patient's flowsheets" },
    { id: "edit_flowsheets", label: "Can edit patient's flowsheets" },
    { id: "remove_flowsheets", label: "Can remove patient's flowsheets" },
    { id: "view_medical_history", label: "Can view patient's medical history" },
    { id: "edit_medical_history", label: "Can edit patient's medical history" },
    { id: "view_visits", label: "Can view patient's visits" },
    { id: "edit_visits", label: "Can edit patient's visits" },
    {
        id: "view_diagnostic_testing",
        label: "Can view patient's diagnostic testing",
    },
    {
        id: "edit_diagnostic_testing",
        label: "Can edit patient's diagnostic testing",
    },
    { id: "view_orders", label: "Can view patient's orders" },
    { id: "edit_orders", label: "Can edit patient's orders" },
    { id: "edit_order_status", label: "Can edit patient's order status" },
    {
        id: "add_orders_with_doctor_cosign",
        label: "Can add orders with doctor cosign",
    },
    { id: "view_MAR", label: "Can view patient's MAR" },
    { id: "edit_MAR", label: "Can edit patient's MAR" },
    { id: "view_MAR_summary", label: "Can view patient's MAR summary" },
    { id: "view_documents", label: "Can view patient's documents" },
    { id: "edit_documents", label: "Can edit patient's documents" },
    {
        id: "edit_current_condition",
        label: "Can edit patient's current condition",
    },
    {
        id: "view_current_condition",
        label: "Can view patient's current condition",
    },
    { id: "view_medication_plans", label: "Can view patient's medication plans" },
    { id: "edit_medication_plans", label: "Can edit patient's medication plans" },
    { id: "view_patient_treatment_plans", label: "Can view patient's treatment plans" },
    { id: "edit_patient_treatment_plans", label: "Can edit patient's treatment plans" },

    { id: "add_allergy", label: "Can add patient's allergy tag" },
    { id: "add_behavior_tag", label: "Can add patient's behavior tag" },
];

const keysForPatientOwner = [
    { id: "view_patient_owners", label: "Can view patients owner list" },
    { id: "edit_patient_owners", label: "Can edit patient owner" },
];

const keysForOperatingRooms = [
    { id: "view_operating_rooms", label: "Can view operating rooms" },
    { id: "edit_operating_rooms", label: "Can edit operating rooms" },
];

const keysForBoarding = [
    { id: "view_boarding", label: "Can view boarding" },
    { id: "edit_boarding", label: "Can edit boarding" },
];

const keysForReferral = [
    { id: "view_referral_management", label: "Can view referral management" },
    { id: "edit_referral_management", label: "Can edit referral management" },
];

const keysForReports = [
    { id: "view_reports", label: "Can view reports" },
    { id: "edit_reports", label: "Can edit reports" },
];

const keysForMessages = [
    { id: "view_messages", label: "Can view messages" },
    { id: "edit_messages", label: "Can edit messages" },
];

const keysForInvoice = [
    { id: "view_invoices", label: "Can view invoices" },
    { id: "edit_invoices", label: "Can edit invoices" },
    { id: "complete_invoice", label: "Can complete invoice" },
    { id: "view_estimates", label: "Can view estimates" },
    { id: "edit_estimates", label: "Can edit estimates" },
    { id: "send_estimates", label: "Can send estimates" },
];

const keysForStaffManagement = [
    { id: "view_staff_management", label: "Can view staff management" },
    { id: "edit_staff_management", label: "Can edit staff management" },
];

const keysForWellnessPlan = [
    { id: "view_wellness_plans", label: "Can view wellness plans" },
    { id: "edit_wellness_plans", label: "Can edit wellness plans" },
];

const keysForLiveClinic = [
    { id: "view_live_clinic", label: "Can view live clinic" },
    { id: "edit_live_clinic", label: "Can edit live clinic" },
];

const keysInventory = [
    { id: "view_inventory", label: "Can view inventory" },
    { id: "edit_inventory", label: "Can edit inventory" },
    { id: "view_services", label: "Can view services" },
    { id: "edit_services", label: "Can edit services" },
    { id: "view_products", label: "Can view products" },
    { id: "edit_products", label: "Can edit products" },
    { id: "view_bundles", label: "Can view bundles" },
    { id: "edit_bundles", label: "Can edit bundles" },
    { id: "view_POS", label: "Can view POS" },
    { id: "edit_POS", label: "Can edit POS" },
    { id: "view_purchase_orders", label: "Can view purchase orders" },
    { id: "edit_purchase_order", label: "Can edit purchase order" },
    { id: "view_suppliers", label: "Can view suppliers" },
    { id: "edit_suppliers", label: "Can edit suppliers" },
    { id: "view_vaccine_schedules", label: "Can view vaccine schedules" },
    { id: "edit_vaccine_schedules", label: "Can edit vaccine schedules" },
    { id: "view_diagnostic_services", label: "Can view diagnostic services" },
    { id: "edit_diagnostic_services", label: "Can edit diagnostic service" }
];

const keysForClinicSetting = [
    { id: "use_clinic_settings", label: "Can use clinic setting" },
    { id: "edit_clinic_settings", label: "Can edit clinic setting" },
    { id: "add_discharge_instructions", label: "Can add discharge instructions" },
    {
        id: "edit_discharge_instructions",
        label: "Can edit discharge instructions",
    },
    { id: "add_patient_note_templates", label: "Can add patient note templates" },
    {
        id: "edit_patient_note_templates",
        label: "Can edit patient note templates",
    },
    { id: "view_forms_and_templates", label: "Can view forms and templates" },
    { id: "add_patient_intake_forms", label: "Can add patient intake forms" },
    { id: "edit_patient_intake_forms", label: "Can edit patient intake forms" },
    { id: "add_email_template", label: "Can add email template" },
    { id: "edit_email_template", label: "Can edit email template" },
    { id: "add_sms_template", label: "Can add sms template" },
    { id: "edit_sms_template", label: "Can edit sms template" },
    { id: "view_overview", label: "Can view overview" },
    { id: "add_documents", label: "Can add documents" },
    { id: "view_availability", label: "Can view availability" },
    { id: "edit_availability", label: "Can edit availability" },
    { id: "publish_own_schedule", label: "Can publish own schedule" },
    { id: "send_schedule_request", label: "Can send schedule request" },
    { id: "view_patient_stories", label: "Can view patient stories" },
    { id: "view_preferences", label: "Can view preferences" },
    { id: "view_commission", label: "Can view commission" },
    { id: "view_working_locations", label: "Can view working locations" },
    { id: "edit_working_locations", label: "Can edit working locations" },
    { id: "view_appointment_settings", label: "Can view appointment settings" },
    { id: "edit_appointment_settings", label: "Can edit appointment_settings" },
    { id: "view_other_preferences", label: "Can view other preferences" },
    { id: "edit_other_preferences", label: "Can edit other preferences" },
    { id: "view_pet_owner_preferences", label: "Can view pet owner preferences" },
    { id: "edit_pet_owner_preferences", label: "Can edit pet owner preferences" },
];

export const roleModulesList = [
    { title: "Appointment Module", keys: keysForAppointments },
    { title: "Patient Module", keys: keysForPatients },
    { title: "Patient Owner Module", keys: keysForPatientOwner },
    { title: "Operating Rooms Module", keys: keysForOperatingRooms },
    { title: "Boarding Module", keys: keysForBoarding },
    { title: "Referral Module", keys: keysForReferral },
    { title: "Reports Module", keys: keysForReports },
    { title: "Messages Module", keys: keysForMessages },
    { title: "Invoice Module", keys: keysForInvoice },
    { title: "Staff Management Module", keys: keysForStaffManagement },
    { title: "Wellness Plan Module", keys: keysForWellnessPlan },
    { title: "Live Clinic Module", keys: keysForLiveClinic },
    { title: "Inventory Module", keys: keysInventory },
    { title: "Clinic Setting Module", keys: keysForClinicSetting },
]