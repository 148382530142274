import * as Yup from "yup";
/**
 * @description declaration form schema for client validations
 */

export const packageSchema = Yup.object().shape({
  // unit_of_measure: Yup.string().required(),
  //upc: Yup.string(),
  // sub_package: Yup.object().shape({
  //   amount: Yup.number(),
  //   unit_of_measure: Yup.string().when("amount", {
  //     is: (amount) => amount !== undefined,
  //     then: Yup.string().required(
  //       "Unit of measure is required when amount is provided."
  //     ),
  //     otherwise: Yup.string(),
  //   }),
  // }),
  quantity: Yup.number().required(),
  manufacture_date: Yup.date().required(),
  expiration_date: Yup.date().min(
    Yup.ref("manufacture_date"),
    "Expiration date should be after the manufacture date"
  ),
  lot_number: Yup.string().required(),
});

/**
 * @description params intilaization
 */

export const packageInit = {
  // extends: null,
  lot_number: "",
  // unit_of_measure: undefined,
  // //upc: '',
  // sub_package: {
  //   unit_of_measure: undefined,
  //   amount: undefined,
  // },
  quantity: undefined,
  manufacture_date: "",
  expiration_date: "",
};
