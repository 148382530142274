import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import "./index.scss";
import { formatPhoneNumber } from "utils/common";

const ProfileContact = () => {
	const { petDetails } = useSelector(state => state.petReducer);
	const [isMore, setIsMore] = useState(false);
	const { permissionsData } = useSelector(
		(state) => state.clinicPermissionReducer
	  );
	const permissions = permissionsData;
	return (
		<>

			<div className="profile-contact row-block">
				<div className="head section-head d-flex align-items-center">
					<div className="left-col">
						Contact Person
					</div>
					{permissions?.edit_patients_details && (
						<div className="right-col ms-auto">
							<Button color="link" className="d-inline-flex add-btn">
								<span className="icomoon-plus-btn"></span>
							</Button>
						</div>
					)}
				</div>
				<div className="inner">
					{(petDetails?.contactPerson || []).map((item, index) => { // eslint-disable-line
						let comp = <div className="contact-box d-flex">
							<div className="pic rounded-circle overflow-hidden flex-shrink-0">
								<img className="img-fluid w-100 h-100" src={item?.image || require('../../../../../assets/img/vetRecorEMR/user-thumbnail.png')} alt="User" />
							</div>
							<div className="txt d-flex flex-column">
								<label>{`${item?.person_name} ${item?.primary ? '(Primary)' : ''}`}</label>
								<label>
									<span className="icon icomoon-phone-chat"></span>
									{formatPhoneNumber(item?.phone)}
								</label>
								<label>
									<span className="icon icomoon-location"></span>
									{item?.address}
								</label>
							</div>
						</div>
						if (!isMore && index < 1) {
							return comp;
						} else if (isMore) {
							return comp
						}
					})}

					<Button onClick={() => setIsMore(!isMore)} color="link" className="add-btn">
						{isMore ? 'Less' : petDetails?.contactPerson?.length > 1 ? '+' + (petDetails?.contactPerson?.length - 1).toString() + ' More' : ''}
					</Button>
				</div>
			</div>
		</>
	);
};

export default ProfileContact;
