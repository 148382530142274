import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { dobInYearMonthDays } from "../../../../utils/common";
import "./index.scss";

const ProfileDescription = () => {
	const petDetails = useSelector((state) => state.petReducer.petDetailsSide);
	console.log('right side pet details', petDetails)

	
	return (
		<>
			<div className="profile-description row-block">
				<p className="d-flex">
					<label>Date of Birth</label>
					<span>{petDetails?.dob ? moment(petDetails?.dob).format('MM/DD/YYYY') : '--'}</span>
				</p>
				<p className="d-flex">
					<label>Age</label>
					<span>{petDetails?.dob ? dobInYearMonthDays(new Date(petDetails?.dob || new Date())) + ' old' : '--' }</span>
				</p>
				<p className="d-flex">
					<label>Identity</label>
					<span>{petDetails?.gender || '--'} / {petDetails?.breed?.breed || '--'} / {petDetails?.color || '--'}</span>
				</p>
				<p className="d-flex">
					<label>Rabies Tag No.</label>
					<span>{petDetails?.rabies_number || '--'}</span>
				</p>
				<p className="d-flex">
					<label>License No.</label>
					<span>{petDetails?.license_number || '--'}</span>
				</p>
				<p className="d-flex">
					<label>Microchip No.</label>
					<span>{petDetails?.microchip_number || '--'}</span>
				</p>
				<p className="d-flex">
					<label>CPR</label>
					<span>{petDetails?.cpr || '--'}</span>
				</p>
				<p className="d-flex">
					<label>IV Locations</label>
					<span>{petDetails?.iv_locations ? typeof petDetails?.iv_locations === 'string' ? petDetails?.iv_locations : petDetails?.iv_locations?.map(e => e?.locationDetails?.filter(o => o?.location)?.map(i => i?.location).join(", ")).join(", ")  || "--": '--'}</span>
				</p>
				{/*
				<p className="d-flex">
					<label>Gender</label>
					<span>{petDetails?.gender || '--'}</span>
				</p>
				<p className="d-flex">
					<label>Breed</label>
					<span>{petDetails?.breed?.breed || '--'}</span>
				</p>
				<p className="d-flex">
					<label>Color</label>
					<span>{petDetails?.color || '--'}</span>
				</p>*/}
			</div>
		
		</>
	);
};

export default ProfileDescription;
