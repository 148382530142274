import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Table } from "reactstrap";
import ListingFilter from "./ListingFilter";
import { patientListPanelAction } from "../../../context/actions/patientListPanelAction";
import { useHistory } from "react-router";
import "./index.scss";
import { getAllPetsAddedBy } from "../../../context/actions/patient-module/patient/pet";
import { getAllPets } from "../../../context/actions/patient-module/patient/pet";
import { useState } from "react";
import NodataFoundBlock from "../../NoDataFoundBlock";
import ImagePlaceholder from "../../../shared/ImagePlaceholder";
import SortColumn from "../../Sort/Column";
import {
  getAllCity,
  getAllSpecies,
} from "../../../context/actions/app-content";
import { handleSortTable } from "../../../utils/constants";
import LoaderUI from "shared/loader";
import Spinner from "shared/Spinner";
import { useClinicSpecies } from "hooks/useClinicSpecies";
import { useClinicBreed } from "hooks/useClinicBreed";

const PatientListPanel = ({ className = "", clinicId, callable }) => {
  const clinicSpecies = useClinicSpecies()
  const clinicBreed = useClinicBreed();
  const dispatch = useDispatch();
  const [petLists, setPetLists] = useState(false);
  const [searchedPetLists, setSearchedPetLists] = useState(false);
  const [searchFilters, setSearchFilters] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const closePatientPanel = () => {
    dispatch(patientListPanelAction(false));
  };

  const handleGetAllPets = () => {
    setLoadingList(true);
    dispatch(
      getAllPets(clinicId, {}, (result) => {
        if (result && !result.errors) {
          setPetLists(result);
          setSearchedPetLists(result);
        } else {
          setPetLists(false);
          setSearchedPetLists(false);
        }
        setLoadingList(false);
      })
    );
  };

  useEffect(() => {
    handleGetAllPets();
    dispatch(getAllCity({}, () => {}));
    dispatch(getAllSpecies({}, () => {}));
  }, []);

  const handleFilterList = (searchData) => {
    return petLists.filter(function (item) {
      for (var key in searchData) {
        let itemKey;
        if (key === "city") {
          itemKey = item["pet_owner"][key];
        } else {
          itemKey = item[key].toLowerCase();
        }
        if (itemKey === undefined || !itemKey.includes(searchData[key]))
          return false;
      }
      return true;
    });
  };

  const handleFilterPetList = (searchVal) => {
    if (searchVal.length > 0) {
      const searchData = {
        ...searchFilters,
        pet_name: searchVal.toLowerCase(),
      };
      setSearchFilters(searchData);
      const filteredPet = handleFilterList(searchData);
      setSearchedPetLists(filteredPet);
    } else {
      setSearchedPetLists(petLists);
    }
  };

  const onSelectCity = (e) => {
    let value = e.target.value;
    if (value) {
      const searchData = { ...searchFilters, city: value };
      setSearchFilters(searchData);
      const filteredPet = handleFilterList(searchData);
      setSearchedPetLists(filteredPet);
    } else {
      setSearchedPetLists(petLists);
    }
  };

  const onSelectGender = (e) => {
    let value = e.target.value;
    if (value) {
      const searchData = { ...searchFilters, gender: value };
      setSearchFilters(searchData);
      const filteredPet = handleFilterList(searchData);
      setSearchedPetLists(filteredPet);
    } else {
      setSearchedPetLists(petLists);
    }
  };

  const onSelectSpecies = (e) => {
    let value = e.target.value;
    if (value) {
      const searchData = { ...searchFilters, species: value };
      setSearchFilters(searchData);
      const filteredPet = handleFilterList(searchData);
      setSearchedPetLists(filteredPet);
    } else {
      setSearchedPetLists(petLists);
    }
  };

  const handleColumnSort = (sortyOrder, columnName) => {
    const data = searchedPetLists.sort(handleSortTable(columnName, sortyOrder));
    setSearchedPetLists((searchedPetLists) => [...data]);
  };

  return (
    <>
      <div
        className={`patients-panel-wrapper position-fixed d-flex flex-column overflow-auto 
			${className}
			
			`}
      >
        <div className="head d-flex align-items-center">
          <h2>Select Patient</h2>
          <Button
            color="link"
            className="ms-auto panel-close-btn"
            onClick={closePatientPanel}
          >
            <span className="material-symbols-outlined">close</span>
          </Button>
        </div>
        <ListingFilter
          fieldVal={(value) => handleFilterPetList(value)}
          onSelectCity={onSelectCity}
          onSelectGender={onSelectGender}
          onSelectSpecies={onSelectSpecies}
        />
        {loadingList ? (
          <Spinner height={150} />
        ) : (
          <div className="inner overflow-auto">
            <div className="table-listing-blc overflow-auto">
              <Table responsive striped className="no-data-table">
                <thead>
                  <tr>
                    <th>
                      <Button
                        color="link"
                        className="d-inline-flex align-items-center sort-btn"
                      >
                        Patient Info.
                        <SortColumn
                          sortLatest={() => handleColumnSort(-1, "pet_name")}
                          sortOldest={() => handleColumnSort(1, "pet_name")}
                        />
                      </Button>
                    </th>
                    <th style={{ minWidth: "150px" }}>
                      <Button
                        color="link"
                        className="d-inline-flex align-items-center sort-btn"
                      >
                        Animal / Breed
                        <SortColumn
                          sortLatest={() => handleColumnSort(-1, "species")}
                          sortOldest={() => handleColumnSort(1, "species")}
                        />
                      </Button>
                    </th>
                    <th>
                      <Button
                        color="link"
                        className="d-inline-flex align-items-center sort-btn"
                      >
                        Gender
                        <SortColumn
                          sortLatest={() => handleColumnSort(-1, "gender")}
                          sortOldest={() => handleColumnSort(1, "gender")}
                        />
                      </Button>
                    </th>
                    <th>
                      <Button
                        color="link"
                        className="d-inline-flex align-items-center sort-btn"
                      >
                        City
                        <SortColumn
                          sortLatest={() => handleColumnSort(-1, "city")}
                          sortOldest={() => handleColumnSort(1, "city")}
                        />
                      </Button>
                    </th>
                    {/* <th >Kennel</th> */}
                    <th style={{ width: "100px" }}>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {searchedPetLists && searchedPetLists.length > 0 ? (
                    searchedPetLists.map((pet, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <div className="text-start pic-cell d-inline-flex align-items-center">
                              <div className="pic d-inline-flex position-relative">
                                {pet?.pet_image ? (
                                  <img
                                    className="img-fluid w-100 h-100"
                                    src={pet?.pet_image}
                                    alt="Patient"
                                  />
                                ) : (
                                  <ImagePlaceholder />
                                )}
                              </div>
                              {pet?.pet_name}
                            </div>
                          </td>
                          <td>
                            {clinicSpecies(pet?.breed?.species_id?._id) || pet?.breed?.species_id?.species_name} /{" "}
                            {clinicBreed(pet?.breed?._id) || pet?.breed?.breed}
                          </td>
                          <td style={{textTransform: 'capitalize'}}>{pet?.gender?.replace("-", " ")} </td>
                          <td>{pet?.pet_owner?.city}</td>
                          {/* <td className="w-[150px]">{pet?.isBoarded?<span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">Assigned</span>:<span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">pending</span>}</td> */}
                          <td>
                            <Button
                              color="link"
                              onClick={() => callable(pet?._id)}
                            >
                              Select
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="no-data-rw">
                      <td colSpan="5">
                        <NodataFoundBlock type={'search result'}/>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PatientListPanel;
