import React, { useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import db from "../../../../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { Icons } from "utils/contants/Icons";
import "./index.scss";
import { getPetListWithFilter } from "context/actions/patient-module/patient/pet";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useDebounce from "hooks/useDebounce";
import { getPetOwnersListV2WithFilter } from "context/actions/patient-module/pet-owner";
import { getAllStaffManagementUsers } from "context/actions/staffManagement";
import { useClinicSpecies } from "hooks/useClinicSpecies";
import { useClinicBreed } from "hooks/useClinicBreed";
const CreatePrivateChat = ({
  show,
  handleClose,
  staffMembersList,
  setStaffMembersList,
}) => {

  const clinicSpecies = useClinicSpecies()
  const dispatch = useDispatch();
  const history = useHistory();

  const clinicBreed = useClinicBreed()
  const { privateChatList } = useSelector((state) => state.realTimeChatReducer);
  const { petOwnerList } = useSelector((state) => state.petOwnerReducer);
  const { petList } = useSelector((state) => state.petReducer);
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );
  const userDetails = useSelector((state) => state.user.user_details);
  const [tab, setTab] = useState("patients");
  const [search, setSearch] = useState("");
  const searchDebouncedValue = useDebounce(search, 300);
  const onClose = () => {
    handleClose();
    setSearch("");
    setTab("patients");
  };

  const createGroupRoom = async (data) => {
    try {
      const roomsCollectionRef = collection(db, "privateChats");
      let payload = {
        createdAt: serverTimestamp(),
        createdBy: userDetails?._id,
        memberId: [userDetails?._id],
        members: [
          {
            id: userDetails?._id,
            role: userDetails?.role,
            img: userDetails?.user_image,
            name: userDetails?.firstname + " " + userDetails?.lastname || "",
            phone: userDetails?.phone || "",
            unreadCount: 0,
          },
        ],
      };
      if (tab === "patients") {
        const petDetails = {
          id: data?._id, 
          name: data?.pet_name,
          image: data?.pet_image || "",
          animal: clinicSpecies(data?.speciesDetails?._id) ||  data?.speciesDetails?.species_name,
          breed: clinicBreed(data?.breedDetails?.__id) ||  data?.breedDetails?.breed,
        };
        payload?.memberId?.push(data?.petOwner?._id);
        payload?.members?.push({
          id: data?.petOwner?._id,
          role: "pet-owner",
          img: data?.petOwner?.image || "",
          name: data?.petOwner?.owner_name,
          phone: data?.petOwner?.phone || "",
          unreadCount: 0,
        });
        payload = { ...payload, petDetails, chatType: "patient" };
      } else {
        const memberRole =
          data?.user?.user_type || data?.user_type || data?.user?.role;
        payload?.memberId?.push(data?.user?._id);
        payload?.members?.push({
          id: data?.user?._id,
          role: memberRole,
          img: data?.user?.user_image,
          name: data?.user?.firstname + " " + data?.user?.lastname || "",
          phone: data?.user?.phone || "",
          unreadCount: 0,
        });
        payload = {
          ...payload,
          chatType: memberRole === "pet-owner" ? "pet-owner" : "private",
        };
      }
      const isRoomExist = privateChatList?.find(
        (element) =>
          element?.memberId.includes(payload?.memberId[0]) &&
          element?.memberId.includes(payload?.memberId[1])
      );

      if (isRoomExist?.id) {
        history.push(`/message-center/private/${isRoomExist?.id}`);
        handleClose();
      } else {
        const roomDocRef = await addDoc(roomsCollectionRef, payload);
        console.log("✌️roomDocRef --->", roomDocRef);
        if (roomDocRef.id) {
          toast.success("New chat created.");
          handleClose();
          history.push(`/message-center/private/${roomDocRef.id}`);
          dispatch({ type: "MESSAGE_LIST", payload: [] });
        }
      }
    } catch (errors) {
      toast.error("Something went wrong!");
      console.log("errors", errors);
    }
  };
  const handleGetPetList = () => {
    dispatch(getPetListWithFilter({ page: 1, limit: 20 }, () => {}));
  };

  useEffect(() => {
    handleGetPetList();
  }, []);

  useEffect(() => {
    if (tab === "patients")
      dispatch(
        getPetListWithFilter(
          { page: 1, limit: 15, searchText: searchDebouncedValue },
          () => {}
        )
      );
    else if (tab === "Owner")
      dispatch(
        getPetOwnersListV2WithFilter({
          page: 1,
          limit: 15,
          role: "pet-owner",
          role: "pet-owner",
          searchText: searchDebouncedValue,
          clinic_id: clinic_settings.clinic_id,
        })
      );
    else if (tab === "Doctor" || tab === "Staff") {
      dispatch(
        getAllStaffManagementUsers(
        
          { searchText: search },
          (result) => {
            if (result && !result.errors) {
              setStaffMembersList(result);
            }
          }
        )
      );
    }
  }, [searchDebouncedValue]);

  const handleTabChange = (key) => {
    setTab(key);
    if (key === "patients" && search)
      dispatch(
        getPetListWithFilter(
          { page: 1, limit: 15, searchText: search },
          () => {}
        )
      );
    else if (key === "Owner" && search)
      dispatch(
        getPetOwnersListV2WithFilter({
          page: 1,
          limit: 15,
          role: "pet-owner",
          role: "pet-owner",
          searchText: search,
          clinic_id: clinic_settings.clinic_id,
        })
      );
    else if ((key === "Doctor" || key === "Staff") && search) {
      dispatch(
        getAllStaffManagementUsers(
      
          { searchText: search },
          (result) => {
            if (result && !result.errors) {
              setStaffMembersList(result);
            }
          }
        )
      );
    }
  };
  return (
    <Offcanvas
      show={show}
      onHide={onClose}
      backdrop="static"
      placement="end"
      className="select-user-list"
    >
      <Offcanvas.Header closeButton className="p-2">
        <Offcanvas.Title>Select User</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="px-2 pb-2 pt-0">
        <div className="d-flex flex-column h-100">
          <div className="d-flex align-items-center gap-2 flex-md-nowrap flex-wrap flex-md-row flex-column-reverse">
            <div className="mb-2 search-group-wrapp position-relative">
              <Icons.SearchIcon />
              <input
                type="text"
                className="form-control"
                placeholder="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <ul className="filter-list-tabs">
              <li
                className={`filter-tabs ${tab === "patients" ? "active" : ""}`}
                role="tab"
                onClick={() => handleTabChange("patients")}
              >
                Patients
              </li>
              <li
                className={`filter-tabs ${tab === "Owner" ? "active" : ""}`}
                role="tab"
                onClick={() => {
                  handleTabChange("Owner");
                }}
              >
                Patient Owners
              </li>
              <li
                className={`filter-tabs ${tab === "Doctor" ? "active" : ""}`}
                role="tab"
                onClick={() => handleTabChange("Doctor")}
              >
                Doctors
              </li>
              <li
                className={`filter-tabs ${tab === "Staff" ? "active" : ""}`}
                role="tab"
                onClick={() => handleTabChange("Staff")}
              >
                Staff
              </li>
            </ul>
          </div>
          {/*<p className="un_tetx my-1">Selecting a Patient starts a conversation visible to all Patient owners, caretakers, and involved doctors.</p>*/}

          <div className="table-listing-blc overflow-auto">
            <div className="table-responsive">
              {tab === "patients" ? (
                <table className="checkbox-listing table table-striped group-add-listing">
                  <thead>
                    <tr>
                      <th style={{ minWidth: 100 }}>Patient Info.</th>
                      <th style={{ minWidth: 188 }}>Animal / Breed</th>
                      <th style={{ minWidth: 100 }}>Gender</th>
                      <th style={{ minWidth: 100 }}>City</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {petList?.docs?.map((pet, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <button
                              type="button"
                              className="p-0 text-start pic-cell d-inline-flex align-items-center w-100 btn btn-link"
                            >
                              <div className="pic d-inline-flex position-relative">
                                <div className="img-placeholder position-relative d-flex align-items-center justify-content-center w-100 h-100 img-fluid w-50 h-50">
                                  {pet?.pet_image ? (
                                    <img src={pet?.pet_image} alt="i" />
                                  ) : (
                                    <span className="no-image d-inline-flex align-items-center justify-content-center position-absolute">
                                      <Icons.DummyImg />
                                    </span>
                                  )}
                                </div>
                              </div>
                              {pet?.pet_name}
                            </button>
                          </td>
                          <td>
                            {clinicSpecies(pet?.speciesDetails?._id) || pet?.speciesDetails?.species_name} /{" "}
                            {clinicBreed(pet?.breedDetails?._id) || pet?.breedDetails?.breed}
                          </td>
                          <td>{pet?.gender}</td>
                          <td>{pet?.petOwner?.city || "-"}</td>
                          <td>
                            <button
                              className="start-message-btn"
                              onClick={() => createGroupRoom(pet)}
                            >
                              Message
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <table className="checkbox-listing table table-striped group-add-listing">
                  <thead>
                    <tr>
                      <th style={{ minWidth: 245 }}>{tab} Name</th>
                      <th style={{ minWidth: 245 }}>City</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {tab === "Owner" ? (
                      <>
                        {petOwnerList?.docs?.map((ele, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                <button
                                  type="button"
                                  className="p-0 text-start pic-cell d-inline-flex align-items-center w-100 btn btn-link"
                                >
                                  <div className="pic d-inline-flex position-relative">
                                    <div className="img-placeholder position-relative d-flex align-items-center justify-content-center w-100 h-100 img-fluid w-50 h-50">
                                      {ele?.user_image ? (
                                        <img src={ele?.user_image} alt="i" />
                                      ) : (
                                        <span className="no-image d-inline-flex align-items-center justify-content-center position-absolute">
                                          <Icons.DummyImg />
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  {ele?.firstname} {ele?.lastname}
                                </button>
                              </td>
                              <td>{ele?.city || "-"}</td>
                              <td>
                                <button
                                  className="start-message-btn"
                                  onClick={() => createGroupRoom({ user: ele })}
                                >
                                  Message
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    ) : null}
                    {(tab === "Staff" || tab === "Doctor") && (
                      <>
                        {staffMembersList
                          ?.filter((data) => {
                            if (tab === "Doctor")
                              return data?.user_type === "doctor";
                            else return data?.user_type !== "doctor";
                          })
                          ?.map((ele, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  <button
                                    type="button"
                                    className="p-0 text-start pic-cell d-inline-flex align-items-center w-100 btn btn-link"
                                  >
                                    <div className="pic d-inline-flex position-relative">
                                      <div className="img-placeholder position-relative d-flex align-items-center justify-content-center w-100 h-100 img-fluid w-50 h-50">
                                        {ele?.user.user_image ? (
                                          <img
                                            src={ele?.user.user_image}
                                            alt="i"
                                          />
                                        ) : (
                                          <span className="no-image d-inline-flex align-items-center justify-content-center position-absolute">
                                            <Icons.DummyImg />
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    {ele?.user_type === "doctor" ? "Dr." : null}
                                    {ele?.user?.firstname} {ele?.user?.lastname}
                                  </button>
                                </td>
                                <td>{ele?.user?.city || "-"}</td>
                                <td>
                                  <button
                                    className="start-message-btn"
                                    onClick={() => createGroupRoom(ele)}
                                  >
                                    Message
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default CreatePrivateChat;
