import React from "react";
import { Button } from "reactstrap";
import "./index.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const ReportsItem = ({ className = "", data }) => {
  const history = useHistory();
  const { id, title, description, image, navigate } = data;
  return (
    <>
      <div className={`report-blc h-100 ${className}`}>
        <div className="inner">
          <img className="cardImage" src={image} alt="Report" />

          <div className="text-group">
            <h4>{title}</h4>
            {/* <p>{description}</p> */}

            <Button
              color="success"
              className="ms-auto"
              onClick={() => {
                if (navigate) {
                  history.push(`${navigate}`);
                }
              }}
            >
              Generate Report
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportsItem;
