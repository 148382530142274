import * as Yup from "yup";
/**
 * @description declaration form schema for client validations
 */
export const templateSchema = Yup.object().shape({
  title: Yup.string().required("Title field is required"),
  description: Yup.string(),
  fields: Yup.array(),
});

/**
 * @description params intilaization
 */

export const templateInit = {
  title: "",
  description: "",
  fields: [""],
};
