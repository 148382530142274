const initState = {
    inventory: false,
    inventoryDetails: false,
    productList: [],
    pointOfSales: [],
    services: {},
    productServiceList: [],
    productServiceDiagnosticList: [],  
    purchaseOrderFormData: {},
    bundles: [],
    singleProductInInventory: {},
    singleDiagnosticService: {}
}

export const inventoryReducer = (state = initState, action) => {
    switch (action.type) {
        case "GET_INVENTORIES":
            return { ...state, inventory: action.payload }

        case "GET_INVENTORY_DETAILS":
            return { ...state, inventoryDetails: action.payload }
        case "GET_TOTAL_INVENTORIES_BY_YEAR":
            return { ...state, inventories: action.payload };

        case "GET_PRODUCT_LIST":
            return { ...state, productList: action.payload }
        case "GET_PRODUCT_SERVICE_LIST":
            return { ...state, productServiceList: action.payload }

        case "GET_PRODUCT_SERVICE_DIAGNOSTIC_LIST":
            return { ...state, productServiceDiagnosticList: action.payload }

        case "GET_POINT_OF_SALES":
            return { ...state, pointOfSales: action.payload }

        case "GET_PURCHASE_ORDERS":
            return { ...state, purchaseOrders: action.payload }

        case "PURCHASE_ORDER_FORM_DATA":
            return { ...state, purchaseOrderFormData: action.payload }

        case "GET_SERVICES":
            return { ...state, services: action.payload }


        case "GET_BUNDLES":
            return { ...state, bundles: action.payload }

        case "Single_Product_Inventory":
            return { ...state, singleProductInInventory: action.payload }
        case "Single_Diagnostic_Service":
                return { ...state, singleDiagnosticService: action.payload }

        default:
            return state
    }
}