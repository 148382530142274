import React, { useEffect, useState } from "react";
import { Button, Table } from "reactstrap";
import CheckBox from "../../../../shared/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { getAllAuditLogs } from "../../../../context/actions/auditLogs";
import moment from "moment";
import { getAllRoles } from "../../../../context/actions/app-content";
// import AuditLogFilter from "./AuditLogFilter";
import PaginationBlock from "../../../../shared/PaginationBlock";
import UserPlaceholder from "../../../../shared/UserPlaceholder";
import SwitchCell from "../../../../shared/SwitchCell";
import AutomationFilter from "./AutomationFilter";
import {
    Modal, ModalFooter,
    ModalHeader, ModalBody
} from "reactstrap"

const Automation = () => {

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10)

    const dispatch = useDispatch();
    const { auditLogs } = useSelector(state => state.auditLogsReducer)
    const { roles } = useSelector(state => state.appContentReducer)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        handleGetAuditLogs()
    }, [page, limit])
    const handleGetAuditLogs = () => {
        setLoading(true);
        dispatch(getAllAuditLogs({ page, limit }, res => {
            setLoading(false);
        }))
        if (!roles) {
            dispatch(getAllRoles({}, () => { }))
        }
    }

    const _handlePageChange = (page) => {
        setPage(page)
    }
    // Modal open state
    const [modal, setModal] = React.useState(false);

    // Toggle for Modal
    const toggle = () => setModal(!modal);
    return (
        <>
            <div className="audit-log-blc">
                <AutomationFilter roles={roles || []} />
                <div className="table-listing-blc overflow-auto">
                    <Table responsive striped className="checkbox-listing">
                        <thead>
                            <tr>
                                <th style={{ minWidth: '172px' }}>Script Name</th>
                                <th style={{ minWidth: '200px' }}>Action Type</th>
                                <th>Ends On</th>
                                <th>Notes</th>
                                <th style={{ minWidth: '70px' }}>Publish</th>
                                <th style={{ minWidth: '85px' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <button className="btn-link" onClick={toggle}>Appointment Reminder</button>
                                </td>
                                <td>
                                    Appointment Reminder
                                </td>
                                <td>05/26/22  08:30
                                    /2022
                                    08:30
                                    :45
                                    pm
                                    am</td>
                                <td>
                                    1
                                </td>
                                <td>
                                    <SwitchCell className="className" label="Active" />
                                </td>
                                <td>
                                    <button className="d-inline-flex align-items-center justify-content-center btn edt_btn-link">
                                        <span className="icomoon-pencil" style={{ fontSize: "18px" }}></span>
                                    </button>
                                    <button className="btn edt_btn-link ms-3">
                                        <i className="icomoon-more" style={{ fontSize: "6px" }}></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button className="btn-link">[Value Information]</button>
                                </td>
                                <td>
                                    [Value Information]
                                </td>
                                <td>05/26/22  08:30
                                    /2022
                                    08:30
                                    :45
                                    pm
                                    am</td>
                                <td>
                                    <button className="btn-link">Add Description</button>
                                </td>
                                <td>
                                    <SwitchCell className="className" label="disable" />
                                </td>
                                <td>
                                    <button className="d-inline-flex align-items-center justify-content-center btn edt_btn-link">
                                        <span className="icomoon-pencil" style={{ fontSize: "18px" }}></span>
                                    </button>
                                    <button className="btn edt_btn-link ms-3">
                                        <i className="icomoon-more" style={{ fontSize: "6px" }}></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <PaginationBlock limit={limit}
                    page={page}
                    pagingCounter={auditLogs?.pagingCounter || 1}
                    docLength={auditLogs?.docs?.length || 0}
                    docPage={auditLogs.page || 1}
                    totalPages={auditLogs.totalPages || 1}
                    totalDocs={auditLogs.totalDocs || 0}
                    _handleLimit={(val) => { setLimit(val) }}
                    _handlePageChange={_handlePageChange} />
            </div>

            <Modal isOpen={modal} size='lg'
                toggle={toggle}
            >
                <ModalHeader>
                    Appointment Reminder
                    <Button color='link' onClick={toggle} className="modal-close-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
                            <mask id="mask0_3034_151677" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="29">
                                <rect y="0.5" width="28" height="28" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_3034_151677)">
                                <path d="M14.0013 15.8719L8.02188 21.8513C7.82774 22.0459 7.59931 22.1432 7.33657 22.1432C7.07431 22.1432 6.84587 22.0459 6.65127 21.8513C6.45667 21.6567 6.35938 21.4282 6.35938 21.166C6.35938 20.9032 6.45667 20.6748 6.65127 20.4807L12.6307 14.5013L6.65127 8.52188C6.45667 8.32774 6.35938 8.09931 6.35938 7.83657C6.35938 7.57431 6.45667 7.34587 6.65127 7.15127C6.84587 6.95667 7.07431 6.85938 7.33657 6.85938C7.59931 6.85938 7.82774 6.95667 8.02188 7.15127L14.0013 13.1307L19.9807 7.15127C20.1748 6.95667 20.4032 6.85938 20.666 6.85938C20.9282 6.85938 21.1567 6.95667 21.3513 7.15127C21.5459 7.34587 21.6432 7.57431 21.6432 7.83657C21.6432 8.09931 21.5459 8.32774 21.3513 8.52188L15.3719 14.5013L21.3513 20.4807C21.5459 20.6748 21.6432 20.9032 21.6432 21.166C21.6432 21.4282 21.5459 21.6567 21.3513 21.8513C21.1567 22.0459 20.9282 22.1432 20.666 22.1432C20.4032 22.1432 20.1748 22.0459 19.9807 21.8513L14.0013 15.8719Z" fill="#282E65" />
                            </g>
                        </svg>
                    </Button>
                </ModalHeader>
                <ModalBody className="px-3 pb-4 pt-2">
                    <img src={require('../../../../../src/assets/img/flow.svg').default} alt="" />
                    <div class="mt-2 mb-0 pt-1 d-flex justify-content-end">
                        <button type="button" className="d-flex btn btn-link bg-transparent text-center" style={{ minWidth: "140px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className="me-2">
                                <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#3D3EFD" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M5.83203 8.33203L9.9987 12.4987L14.1654 8.33203" stroke="#3D3EFD" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M10 12.5V2.5" stroke="#3D3EFD" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            Download</button>
                        <button type="button" className="btn btn-primary text-center" style={{ minWidth: "140px" }}>Edit</button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default Automation;