import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../../../api-client";
import { apiUrl, PORT } from "../../../../environment";
import { toasterConfig } from "../../../../utils/constants";

toast.configure();
/**
 * All Api endpoints to be call
 */

/**
 * get all pet details
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns
 */
export const getMedicationPlanByPetId = (petId, params, callback) => {
  return (dispatch, getState) => {
    const { user: { token }, } = getState();
    if (petId) {
      dispatch({ type: "MEDICATION_LOADING", payload: true })
      ApiClient.get(`${apiUrl}${PORT}/medication-plan/get-all/${petId}`, params, token, dispatch, getState).then((response) => {
        dispatch({ type: "MEDICATION_LOADING", payload: false })
        if (!response) {
          return callback();
        }
        else if (response?.status) {
          dispatch({ type: "GET_MEDIACTION_PLANS", payload: response?.response_data })
          return callback(response);
        } else {
          return callback(response);
        }
      })
    } else {
      return callback({ status: false });
    }
  };
};

export const updateMedicationReportStatus =
  (payload) => async (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    const response = await ApiClient.post(
      `${apiUrl}${PORT}/order/add-medication-report`,
      payload,
      token,
      dispatch, getState
    );
    if (response?.errors?.msg) {
      toast.error(response?.errors?.msg, toasterConfig);
    } else if (response?.statusCode === 500) {
      toast.error(response?.message, toasterConfig);
    } else {
    }
    return response;
  };

export const updateUnkownStatusData =
  (payload) => async (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    const response = await ApiClient.post(
      `${apiUrl}${PORT}/order/add-unknown-medication-report`,
      payload,
      token,
      dispatch, getState
    );
    if (response?.errors?.msg) {
      toast.error(response?.errors?.msg, toasterConfig);
    } else if (response?.statusCode === 500) {
      toast.error(response?.message, toasterConfig);
    } else {
    }
    return response;
  };
