import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
} from "reactstrap";
import { sidebarPanelAction } from "../../context/actions/sidebarPanelAction";
import PageHeader from "../../shared/PageHeader";
import ReportsItem from "./ReportsItem";
import "./index.scss";
import { Icons } from "utils/contants/Icons";

const Reports = () => {
  const dispatch = useDispatch();
  const sidebarPanelState = useSelector(
    (state) => state.sidebarPanelReducer.isSidePanel
  );

  const showSidePanel = () => {
    dispatch(sidebarPanelAction(!sidebarPanelState));
  };

  const reportsCard = [
    {
      id: 1,
      title: "Appointment Schedule",
      description:
        "A daily or weekly schedule of appointments, including patient names, appointment times, and reasons for the visit.",
      // image: Icons.appointmentScheduleImg,
      image: Icons.patientRecordImage,
      navigate: "/reports/appointment-schedule-report"
    },
    {
      id: 2,
      title: "Invoice and Estimates Report",
      description:
        "Reports detailing services rendered, fees charged, and outstanding balances. This helps in managing the clinic’s finances.",
      // image: Icons.billingInvoiceImage,
      image: Icons.patientRecordImage,
      navigate: "/reports/invoice-and-estimates-report"
    },
    {
      id: 3,
      title: "Inventory Management",
      description:
        "Inventory reports help track the clinic’s medical supplies, medications, and equipment to ensure that everything is well-stocked and up to date.",
      // image: Icons.inventoryManagementImage,
      image: Icons.patientRecordImage,
      navigate: "/reports/inventory-management"
    },
    {
      id: 4,
      title: "Referral Management Reports",
      description:
        "Detailed records of a patient’s medical history, including vaccination history, past illnesses, surgeries, and medications.",
      // image: Icons.inventoryManagementImage,
      image: Icons.patientRecordImage,
      navigate: "/reports/referral-management-report"
    },
    {
      id: 5,
      title: "Patient Records",
      description:
        "These reports include basic information about each patient, such as name, species, breed, age, weight, and contact details.",
      image: Icons.patientRecordImage,
      navigate: "/reports/patient-records-report"
    },
    {
      id: 6,
      title: "Staff Management Reports",
      description:
        "Detailed records of a patient’s medical history, including vaccination history, past illnesses, surgeries, and medications.",
      image: Icons.patientRecordImage,
      navigate: "/reports/staff-management-report"
    },
    {
      id: 7,
      title: "Boarding Report",
      description:
        "Detailed records of a patient’s medical history, including vaccination history, past illnesses, surgeries, and medications.",
      image: Icons.patientRecordImage,
      navigate: "/reports/boarding-report"
    },
    {
      id: 8,
      title: "Operating Room Report",
      description:
        "Detailed records of a patient’s medical history, including vaccination history, past illnesses, surgeries, and medications.",
      image: Icons.patientRecordImage,
      navigate: "/reports/operating-room-report"
    },
    // {
    //   id: 8,
    //   title: "Wellness Plan Reports",
    //   description:
    //     "Detailed records of a patient’s medical history, including vaccination history, past illnesses, surgeries, and medications.",
    //   image: Icons.patientRecordImage,
    //   navigate: "/reports/wellness-report"
    // },
  ];

  return (
    <>
      <div className="page-wrapper position-relative d-flex flex-column flex-fill overflow-auto">
        <div className="page-header-wrapper d-flex align-items-center flex-shrink-0 right-column-visible">
          <Button
            className="sidebar-toggle-btn d-inline-flex align-items-center"
            color="link"
            onClick={showSidePanel}
          >
            <span className="material-symbols-outlined">menu</span>
          </Button>
          <div className="row-block d-flex align-items-center flex-fill">
            <PageHeader
              className="dashboard-head flex-flex-shrink-0"
              title="Reports"
            ></PageHeader>
          </div>
        </div>
        <div className="page-content d-flex flex-column flex-fill overflow-auto">
          <div className="row flex-wrap gy-4">
            {reportsCard?.map((elem, index) => {
              return (
                <div className="col-md-3 col-sm-6" key={index}>
                  <ReportsItem data={elem} />
                </div>
              );
            })}
          </div>

          {/* <Container>
            <Row>
              <Col>
                <ReportsItem />
              </Col>
              <Col>
                <ReportsItem />
              </Col>
              <Col>
                <ReportsItem />
              </Col>
              <Col>
                <ReportsItem />
              </Col>
            </Row>

          </Container> */}
        </div>
      </div>
    </>
  );
};

export default Reports;
