
import React from "react";
import './index.scss';

const SwitchCell = ({
    className = "",
    labelVisible = false,
    label = '',
    onClick = () => { }
}) => {
    return (
        <>
            <span className={`swtichCell d-inline-flex align-items-center ${className}`}>
                <span onClick={onClick} className="switch-btn flex-fill d-inline-flex align-items-center flex-shrink-0">
                    <span className="icomoon-check icon"></span>
                    <span className="d-inline-flex rounded-circle circle-icon ms-auto"></span>
                </span>
                {
                    labelVisible && <span className="txt">{label}</span>
                }
            </span>
        </>
    );
}


export default SwitchCell;