import React, { useEffect, useState, useRef } from "react";
import { Button, Form, FormGroup, Input, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import './index.css';
import SwitchCell from "../../../../../shared/SwitchCell";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addKennel, getKennels, updateKennel } from "../../../../../context/actions/kennels";
import { toasterConfig } from "../../../../../utils/constants";
import axios from "axios";
import { PORT, apiUrl } from "../../../../../environment";
import Select from "react-select";

const AddKennel = ({ className = '', closeNewPointPanel, refresh, addPoint, selectedKennel, setSelectedKennel }) => {

    const token = useSelector(state => state?.user?.token)
    const userDetails = useSelector(state => state?.user?.user_details)
    const { petList } = useSelector((state) => state.petReducer);
    const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);
    const dispatch = useDispatch()

    let defaultInputState = {
        kennel_name: "",
        active: true
    }

    useEffect(() => {
        if (selectedKennel) {
            setInputs({
                kennel_name: selectedKennel.kennel_name,
                active: selectedKennel.active
            })
        }
        else {
            setInputs(defaultInputState)
        }
    }, [selectedKennel])

    const [inputs, setInputs] = useState(defaultInputState)

    const handleInputChange = (e) => {
        e.preventDefault();
        setInputs({ ...inputs, [e.target.name]: e.target.value })
    }

    const delay = (delayInms) => {
        return new Promise(resolve => setTimeout(resolve, delayInms));
    }

    const createUpdateOperatingRoom = () => {
        if (!selectedKennel) {
            let body = {
                ...inputs,
                clinic_id: clinic_settings.clinic_id,
                created_by: userDetails?._id
            }


            dispatch(addKennel(body, (res) => {
                if (res?.status) {
                    dispatch(getKennels(clinic_settings?.clinic_id, (res) => {
                        closeNewPointPanel()
                        setSelectedKennel(null)
                    }));

                }

            }))

            /*
            axios.post(`${apiUrl}${PORT}/kennel/add`, body, token)
                .then((res) => {
                    return res
                }).then(async(data) => {
                    await delay(100)
                    dispatch(
                        getKennels(userDetails?._id, (res) => {
                        })
                    );
                    setInputs(defaultInputState)
                    toast.success("Kennel created successfully", toasterConfig)
                    dispatch(
                        getKennels(clinic_settings?.clinic_id, (res) => {
                        })
                    );

                    closeNewPointPanel()
                    setSelectedKennel(null)
                }).catch((err) => {
                    toast.error("Something failed", toasterConfig)
                    console.log("err in op added", err.message)
                })*/
        }
        else {
            let body = {
                ...inputs,
                kennel_id: selectedKennel._id,
                clinic_id: clinic_settings.clinic_id,
                created_by: userDetails?._id
            }


            dispatch(updateKennel(body, () => {
                dispatch(getKennels(clinic_settings?.clinic_id, (res) => {
                    closeNewPointPanel()
                    setSelectedKennel(null)
                }));
            }))

            /*
            axios.post(`${apiUrl}${PORT}/kennel/update`, body, token)
                .then((res) => {
                    return res
                }).then((data) => {
                    dispatch(
                        getKennels(userDetails?._id, (res) => {
                        })
                    );
                    setInputs(defaultInputState)
                    toast.success("Kennel updated successfully", toasterConfig)
  
                    setSelectedKennel(null)
                    closeNewPointPanel()
                }).catch((err) => {
                    toast.error("Something failed", toasterConfig)
                    console.log("err in op added", err.message)
                })*/
        }

    }


    return (
        <>
            <div className={`ocular-new-point-panel-wrapper position-fixed d-flex flex-column overflow-auto ${className}`}>
                <div className="head d-flex align-items-center">
                    <h2>{selectedKennel ? "Edit Kennel" : "Add New Kennel"}</h2>
                    <Button color="link" className="ms-auto panel-close-btn" onClick={() => {
                        closeNewPointPanel()
                        setInputs(defaultInputState)
                        setSelectedKennel(null)
                    }}>
                        <span className="material-symbols-outlined">
                            close
                        </span>
                    </Button>
                </div>

                <div className="inner align-items-center mt-2">
                    <Form>
                        <div className="status-rw d-flex align-items-center my-2" onClick={(e) => {
                            e.target.name = "active"
                            e.target.value = !inputs.active
                            handleInputChange(e)
                        }}>
                            {/* <span className="swtich-cell2 d-inline-flex align-items-center">
                                <span class="icomoon-check icon"></span>
                                <span className="d-inline-flex rounded-circle circle-icon ms-auto"></span>
                            </span> */}
                            {
                                inputs.active ? (
                                    <SwitchCell className={''} labelVisible={true} label="Active" />
                                ) :
                                    (
                                        <SwitchCell className={'disable'} labelVisible={true} label="Inactive" />
                                    )
                            }



                        </div>
                        <FormGroup className="input-wrapper2 mb-2">
                            <div className="form-floating position-relative text-input">
                                <input type="text" className="form-control" id="kennel_name" name="kennel_name" placeholder="Room Title" value={inputs.kennel_name} onChange={handleInputChange} />
                                <label htmlFor="kennel_name">Kennel Name</label>
                            </div>
                        </FormGroup>
                        <div className="btn-blc border_btn mt-3 text-end">
                            {/*
                            <button type="button" class="flex-fill btn border-btn" onClick={() => {
                                closeNewPointPanel()
                                setInputs(defaultInputState)
                                setSelectedKennel(null)
                            }}>CANCEL</button>*/}
                            <Button color="success" onClick={() => { createUpdateOperatingRoom() }} className="add-btn">{selectedKennel ? "UPDATE" : "CREATE KENNEL"}</Button>
                        </div>

                    </Form>
                </div>
            </div >
        </>
    );
};

export default AddKennel;
