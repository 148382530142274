/*
 * @file: constants.js
 * @description: It contain constants used in application.
 */

import moment from "moment";

/*
 * @pagination: constants used in Pagination
 */
export const pagination = {
  page: 1,
  limit: 8,
  maxlimit: 1000,
  scrollLimit: 8,
};

export const toasterConfig = {
  autoClose: 3000,
  hideProgressBar: true,
};
export const PLAN_LIMITS = { free: 2, basic: 10, popular: 20, platinum: 30 };
/*
 * @fileType: constants used for filetypes
 */
export const fileType = {
  ZIP: "application/zip,application/x-zip-compressed,multipart/x-zip,.zip",
  PDF: "application/pdf",
  IMAGE: "image/*",
};

export const documentFilterItems = [
  {
    is_correct: 1,
    is_verified: 2,
    label: "CG VR",
    correct: "bg-success",
    verified: "bg-danger",
  },
  {
    is_correct: 2,
    is_verified: 1,
    label: "CR VG",
    correct: "bg-danger",
    verified: "bg-success",
  },
  {
    is_correct: 1,
    is_verified: 1,
    label: "CG VG",
    correct: "bg-success",
    verified: "bg-success",
  },
  {
    is_correct: 2,
    is_verified: 2,
    label: "CR VR",
    correct: "bg-danger",
    verified: "bg-danger",
  },
];

/*
 * @process: used to define different stages in file process
 */
export const processes = [
  "Extracting...",
  "Reading...",
  "Analyzing...",
  "Logical inference...",
  "Validating...",
  "Success",
];

/*
 * @maxScaleValue: used to define maximum pdf scale size
 */

export const maxScaleValue = 7;

export const maxLoadTime = 60;

export const intervalTime = 1000; //every 1 second

export const statusOptions = [
  { value: false, label: "Active" },
  { value: true, label: "Archived" },
];

export const appointmentTypes = [
  { value: "in-clinic", label: "In Clinic" },
  { value: "surgery", label: "Surgery" },
  { value: "phone-call", label: "Phone Call" },
  { value: "video-call", label: "Video Call" },
  { value: "appointment-block", label: "Appointment Block" }
];

export const queueOptions = [
  { value: "0", label: "All" },
  { value: "5", label: "Processed" },
  { value: "4", label: "In-Queue" },
];

export const roleItems = ["admin", "operator", "business"];

export const elasticSearchMaxLimit = 10;

export const REGEX = {
  NAME: /^[a-zA-Z\s]*$/,
  PASSWORD: /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/,
  NEW_PASSWORD:
    /^(?!\s)(?=.*[A-Z])(?=.*[!@#$&*%^)(_+])(?=.*[0-9])(?=.*[a-z]).[^-\s]{7,40}$/,
  PHONE: /^(\+\d{1,3}[- ]?)?\d{10}$/,
  NUMBER: /^[0-9]*$/,
  FLOAT_NUMBER: /^[+-]?([0-9]*[.])?[0-9]+$/,
  CARD_NUMBER: /^[0-9].{17}$/,
};

export const monthNames = [
  "null",
  "Jan",
  "Fab",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const barGraphOptions = [
  { value: "month", label: "Monthly" },
  { value: "quarter", label: "Quarterly" },
  { value: "year", label: "Yearly" },
];

export const pieChartOptions = [
  { value: "month", label: "Current Month" },
  { value: "year", label: "Current Year" },
];
export const imageExtension = ["jpeg", "jpg", "png", "JPEG", "JPG", "PNG"];

export const idleLogoutTime = 60000 * 15; // 15 minutes

export const timeout = 1000 * 60 * 15; // 15 minutes (idle logout timeout)

export const genderValues = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];

export const petGenderValues = [
  { value: "female", label: "Female" },
  { value: "spayed-female", label: "Spayed Female" },
  { value: "male", label: "Male" },
  { value: "neutered-male", label: "Neutered Male" },
];

export const petGenderValuesShort = 
  { "female": "F", 
   "spayed-female": "FS" ,
  "male": "M" ,
  "neutered-male": "MN" }

export const unsubscribeReasons = [
  {
    value: "Not satisfied with the services",
    label: "Not satisfied with the services",
  },
  { value: "Change of mind", label: "Change of mind" },
  { value: "Not enough pets", label: "Not enough pets" },
  {
    value: "I am not able to avail this service",
    label: "I am not able to avail this service",
  },
  { value: "other-reason", label: "Others" },
];

export const clinicFilterTypes = [
  { value: "all", label: "All" },
  { value: "clinic_name", label: "Clinic Name" },
  { value: "clinic_email", label: "Clinic Email" },
];

export const petOwnerTypes = [
  { value: "all", label: "All" },
  { value: "you", label: "You" },
  { value: "direct", label: "Direct" },
  { value: "reffered", label: "Referred" },
];

export const requestTypes = [
  { value: "all", label: "All" },
  { value: "in-progress", label: "In Progress" },
  { value: "accepted", label: "Accepted" },
  { value: "declined", label: "Declined" },
];

export const roundOffDecimal = (value) => {
  return Math.round(value * 10) / 10;
};

export const weightUnits = [
  { value: "kg", label: "kg" },
  // { value: 'g', label: 'g' },
  { value: "lbs", label: "lbs" },
  // { value: 'oz', label: 'oz' }
];

export const weightTolbs = (weight) => {
  return weight * 0.45359237;
};

export const lbsToWeight = (lbs) => {
  return lbs / 0.45359237;
};

export const getAge = (birth) => {
  const parseBirth = new Date(birth);
  const ageMS = Date.parse(Date()) - Date.parse(parseBirth);
  const age = new Date();
  age.setTime(ageMS);
  const ageYear = age.getFullYear() - 1970;

  // ageMonth = age.getMonth(); // Accurate calculation of the month part of the age
  // ageDay = age.getDate();    // Approximate calculation of the day part of the age
  const data = {
    year: ageYear,
    month: age.getMonth(),
    day: age.getDate(),
  };
  return data;
};

export const getTimeAgo = (date) => {
  return moment.utc(date).local().startOf("seconds").fromNow();
};

export const donutLabelsValue = (successRate) => {
  const data = {
    show: true,
    name: {
      show: true,
      fontSize: "15px",
      fontFamily: "Helvetica, Arial, sans-serif",
      fontWeight: 600,
      color: undefined,
      offsetY: -10,
      formatter: function (val) {
        return val;
      },
    },
    value: {
      show: true,
      fontSize: "16px",
      fontFamily: "Helvetica, Arial, sans-serif",
      fontWeight: 400,
      color: "#788C9F",
      offsetY: 6,
      formatter: function (val) {
        return val + "%";
      },
    },
    total: {
      show: true,
      showAlways: true,
      label: successRate + "%",
      fontSize: "22px",
      fontFamily: "Helvetica, Arial, sans-serif",
      fontWeight: 600,
      color: "#373d3f",
      formatter: function (w) {
        return "Success Rate";
      },
    },
  };
  return data;
};

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const clinicList = [
  { value: "Americas Animal Clinic", label: "Americas Animal Clinic" },
  { value: "American Pet Clinic", label: "American Pet Clinic" },
  {
    value: "American Veterinary Hospital",
    label: "American Veterinary Hospital",
  },
  { value: "Norwood Veterinary Clinic", label: "Norwood Veterinary Clinic" },
  { value: "Pet Health Centers", label: "Pet Health Centers" },
];

export const staffType = [
  { value: "admin", label: "Admin" },
  { value: "user", label: "User" },
];

export const staffTypeOptions = [
  { value: "all", label: "All" },
  { value: "admin", label: "Admin" },
  { value: "user", label: "User" },
];

export const errorMessage =
  "unable to handle this request, please try after sometime.";

export const docFileTypeErrorMessage =
  "Only .png, .jpg, .jpeg, .pdf, .xls, .xlsx, .csv, .doc and .docx file formats are allowed.";

export const weekdays = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];
export const lateNotificationCycles = [10, 20, 30, 40, 50, 60];
export const doctorNameOnPortalList = [
  { label: "Firstname, Lastname", value: 1 },
  { label: "Lastname, Firstname", value: 0 },
];

export const handleSortTable = (property, sortyOrder) => {
  var sortOrder = sortyOrder;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    let result;
    if (property === "city") {
      result =
        a["pet_owner"][property] < b["pet_owner"][property]
          ? -1
          : a["pet_owner"][property] > b["pet_owner"][property]
          ? 1
          : 0;
    } else {
      result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    }
    return result * sortOrder;
  };
};

export const getChatDate = (date) => {
  if (!moment.isMoment(date)) {
    date = moment(date); // ok for js date, milliseconds or string in ISO format
  }

  if (date.isSame(moment(), "day")) {
    return "Today, " + date.format("hh:mm a");
  } else if (date.isSame(moment().subtract(1, "d"), "day")) {
    return "Yesterday";
  } else if (date.isSame(moment(), "week")) {
    return date.format("dddd");
  } else {
    return date.format("MM/DD/YYYY");
  }
};

export const getCurrentTime = () => {
  var dt = new Date();
  var h = dt.getHours(),
    m = dt.getMinutes();
  const minutes = m.toString().length < 2 ? `0${m}` : m;
  var _time =
    h > 12
      ? "Today, " + (h - 12 + ":" + minutes + "pm")
      : "Today, " + (h + ":" + minutes + "am");
  return _time;
};

export const vitalKeys = [
  { key: "temperature", name: "Temperature" },
  { key: "temperature_method", name: "Temperature Method" },
  { key: "heart_rate", name: "Heart Rate" },
  // { key: "heart_rate_source", name: "Heart Rate Source" },
  { key: "heart_rate_method", name: "Heart Rate Method" },
  { key: "cardiac_rhythm", name: "Cardiac Rhythm" },
  { key: "respiratory_rate", name: "Respiratory Rate" },
  { key: "blood_pressure", name: "Blood Pressure" },
  { key: "blood_pressure_location", name: "Blood Pressure Location" },
  { key: "blood_pressure_method", name: "Blood Pressure Method" },
  { key: "cuff_size", name: "Cuff Size" },
  { key: "spo2_location", name: "SpO2 Location" },
  { key: "oxygen_flow_rate", name: "Oxygen Flow Rate" },
  { key: "oxygen_saturation", name: "Oxygen Saturation" },
  { key: "oxygen_delivery", name: "Oxygen Delivery" },
  // { key: "oxygen_delivery", name: "Oxygen Delivery" },
  { key: "pain_score", name: "Pain Score" },
  { key: "pain_goal", name: "Pain Goal" },
  { key: "pain_location", name: "Pain Location" },
  { key: "pain_reaction", name: "Pain Reaction" },
  { key: "pain_type", name: "Pain Type" },
  { key: "response_to_palpation", name: "Response To Palpatation" },
  { key: "body_tension", name: "Body Tension" },
  { key: "weight", name: "Weight" },
];