import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import AddPackages from "./AddPackages";
import CostAndTaxes from "./CostAndTaxes";
import ProductInfo from "./ProductInfo";
import SingleProductDetail from "./SingleProductDetail";
import SpecialNotes from "./SpecialNotes";
import SupplierInfo from "./SupplierInfo";
import { useDispatch, useSelector } from "react-redux";
import { addInventoryProduct } from "../../context/actions/inventory";



export default function AddProductInventory() {
  //const [isSpecialNotesSaved, setisSpecialNotesSaved] = useState(false);
  const [activeForm, setActiveForm] = useState("productInfo");
  const [formData, setFormData] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const cancleBtn = () => {
    localStorage.removeItem("productInventoryData");
    history.push("/inventory");
  };
  const saveBtn = () => {
    dispatch(
      addInventoryProduct(formData.values, (res) => {
        console.log("product added");
        history.push("/inventory");
      })
    );
  };

  // Render the current form component
  const renderFormComponent = () => {
    console.log('activeForm: ', activeForm);
    switch (activeForm) {
      // case "supplierInfo":
      //   return (
      //     <SupplierInfo
      //       setActiveForm={setActiveForm}
      //       updateFormData={setFormData}
      //       formData={formData}
      //     />
      //   );
      case "productInfo":
        return (
          <ProductInfo
            setActiveForm={setActiveForm}
            updateFormData={setFormData}
            formData={formData}
          />
        );
      case "costAndTaxes":
        return (
          <CostAndTaxes
            setActiveForm={setActiveForm}
            updateFormData={setFormData}
            formData={formData}
          />
        );

      /*
      case "addPackages":
        return (
          <AddPackages
            setActiveForm={setActiveForm}
            updateFormData={setFormData}
            formData={formData}
          />
        );
      */
      case "specialNotes":
        return (
          <SpecialNotes
            setActiveForm={setActiveForm}
            updateFormData={setFormData}
            formData={formData}
          />
        );
      case "singleProductDetail":
        return (
          <SingleProductDetail
            setActiveForm={setActiveForm}
            updateFormData={setFormData}
            formData={formData}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div>
      <div className="pl-4 flex justify-between items-baseline">
        <p
          onClick={() => {
            history.goBack();
          }}
          className="flex items-center cursor-pointer relative left-[-13px] pt-4"
        >
          <span className="px-2">
            <IoIosArrowBack size={20} color="#3D3EFD" />
          </span>{" "}
          <span className="font-['Roboto'] text-[#3D3EFD] font-[500] uppercase">
            Back to List
          </span>
        </p>
        {activeForm === "singleProductDetail" && (
          <div className="flex gap-2">
            
            <button
              onClick={cancleBtn}
              className="w-[150px] uppercase h-[40px] text-sm font-[600] text-center bg-[#fff] text-[#000] font-['Inter'] border rounded-lg"
            >
              Cancel
            </button>
            <button
              onClick={saveBtn}
              className="w-[150px] mr-3 uppercase h-[40px] text-sm font-[600] text-center bg-[#11CA9D] text-[#fff] font-['Inter'] border rounded-lg"
            >
              Save
            </button>
          </div>
        )}
      </div>

      <p className="text-[#282E65] pl-4 font-[500] mt-4">
        <span className="block">Add New</span>
        <span className="block"> Product in Inventory</span>
      </p>
      {/* stepper */}
      <div className="ml-[35px] mt-3 d-block d-xxl-flex d-xl-flex d-lg-flex product_layout_flex">
        <div className="basis-[18%]">
          <ol className="relative text-gray-500 border-l border-[#282E65]">
            {/* <li
              className="mb-10 ml-6 cursor-pointer"
              onClick={() => setActiveForm("supplierInfo")}
            >
              <span className="absolute flex items-center justify-center w-8 h-8  text-[#fff] bg-[#282E65] rounded-full -left-4 ring-4 ring-white ">
                1
              </span>
              <h3 className="font-medium leading-tight text-[#282E65] font-['Inter']">
                Supplier Information
              </h3>
            </li> */}
            <li
              className="mb-10 ml-6 cursor-pointer"
              onClick={() => setActiveForm("productInfo")}
            >
              <span className="absolute flex items-center justify-center w-8 h-8 text-[#fff] bg-[#282E65] rounded-full -left-4 ring-4 ring-white">
                1
              </span>
              <h3 className="font-medium leading-tight text-[#282E65] font-['Inter']">
                Product Information
              </h3>
            </li>
            <li
              className="mb-10 ml-6 cursor-pointer"
              onClick={() => setActiveForm("costAndTaxes")}
            >
              <span className="absolute flex items-center justify-center w-8 h-8 text-[#fff] bg-[#282E65] rounded-full -left-4 ring-4 ring-white">
                2
              </span>
              <h3 className="font-medium leading-tight text-[#282E65] font-['Inter']">
                Cost & Taxes
              </h3>
            </li>
            {/*

            <li
              className="mb-10 ml-6 cursor-pointer"
              onClick={() => setActiveForm("addPackages")}
            >
              <span className="absolute flex items-center justify-center w-8 h-8 text-[#fff] bg-[#282E65] rounded-full -left-4 ring-4 ring-white">
                3
              </span>
              <h3 className="font-medium leading-tight text-[#282E65] font-['Inter']">
                Packages
              </h3>
          </li>*/}
            <li
              className="mb-10 ml-6 cursor-pointer"
              onClick={() => setActiveForm("specialNotes")}
            >
              <span className="absolute flex items-center justify-center w-8 h-8 text-[#fff] bg-[#282E65] rounded-full -left-4 ring-4 ring-white">
                3
              </span>
              <h3 className="font-medium leading-tight text-[#282E65] font-['Inter']">
                Special Notes
              </h3>
            </li>
          </ol>
        </div>
        <div className="basis-[80%]">{renderFormComponent()}</div>
      </div>
    </div>
  );
}
