import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";
toast.configure();
/**
 * All Api endpoints to be call
 * Put -- /clinic-task/update
 * Get -- /clinic-task/get-all-clinic-appointments-with-tasks
 * Post -- /clinic-task/add-new
*/

/**
 * 
 * @param {Object} body
 * @param {Function} callback
 * @returns 
 */
export const createClinicTaskDetails = (body, callback) => {
    return (dispatch, getState) => {
        const { user: { token } } = getState();
        ApiClient.post(`${apiUrl}${PORT}/clinic-task/add-new`, body, token, dispatch, getState).then(response => {
            if (!response) {
                return callback();
            }
            else if (response?.status) {
               // dispatch(getAllClinicLiveAppointments())
                toast.success(response.message, toasterConfig);
                return callback(response);
            } else {
                //toast.error(response.message, toasterConfig);
                return callback(response);
            }
        })
    };
};

/**
 * 
 * @param {Object} body
 * @param {Function} callback
 * @returns 
 */
export const updateClinicTaskDetails = (body, callback) => {
    return (dispatch, getState) => {
        const { user: { token } } = getState();
        ApiClient.put(`${apiUrl}${PORT}/clinic-task/update`, body, token, dispatch, getState).then(response => {
            if (!response) {
                return callback();
            }
            else if (response?.status) {
                //dispatch(getAllClinicLiveAppointments())
                toast.success(response.message, toasterConfig);
                return callback(response);
            } else {
                //toast.error(response.message, toasterConfig);
                return callback(response);
            }
        })
    };
};

/**
 * 
 * @param {String} clinic_id
 * @param {Object} params
 * @returns 
 */
export const getAllClinicLiveAppointments = (clinic_id, params, callback) => {
    return (dispatch, getState) => {
        const { user: { token, user } } = getState();
        dispatch({ type: "CLINIC_TASK_LOADING", payload: true })
        ApiClient.get(`${apiUrl}${PORT}/clinic-task/get-all-clinic-appointments-with-tasks/${clinic_id}`, params, token, dispatch, getState).then((response) => {
            dispatch({ type: "GET_ALL_CLINIC_LIVE_APPOINTMENTS", payload: false })
            dispatch({ type: "CLINIC_TASK_LOADING", payload: false })
            if (!response) {
                return callback();
            }
            else if (response?.status) {
                dispatch({ type: "GET_ALL_CLINIC_LIVE_APPOINTMENTS", payload: response?.response_data })
                return callback(response);
            } else {
                //toast.error(response.message, toasterConfig);
                return callback(response);
            }
        })
    };
};

export const addClinicTaskPanelAction = (param) => {
    return (dispatch) => {
        dispatch({ type: "SET_ADD_TASK_PANEL", data: param });
    }
};

export const updateClinicTaskPanelAction = (param) => {
    return (dispatch) => {
        dispatch({ type: "SET_UPDATE_TASK_PANEL", data: param });
    }
};



