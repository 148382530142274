import React from "react";
import { Input } from "reactstrap";

const StoryFilterBlock2 = ({ className = '' }) => {

	return (
		<>
			<div className={`story-filter-blc2  d-flex align-items-center overflow-auto ${className}`}>
				<div className="search-cell col-cell position-relative mb-0">
					<span className="icomoon-search d-inline-flex align-items-center justify-content-center position-absolute"></span>
					<Input placeholder="Search" />
				</div>
				<div className="input-cell col-cell">
					<Input type="select" name="select">
						<option>Most Helpful</option>
						<option>item2</option>
					</Input>
				</div>
				<div className="input-cell col-cell">
					<Input type="select" name="select">
						<option>All Tags</option>
						<option>item2</option>
					</Input>
				</div>
			</div>
		</>
	);
};

export default StoryFilterBlock2;
