import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import ReportItem from "./ReportItem";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";

import AddEditPetXRayReport from "./ReportAddEditForm";

import { Accordion } from "react-bootstrap";
import { getAllReportsByPetId } from "context/actions/patient-module/overview/report";
const ReportsBlock = ({
  petId,
  title,
  viewAll,
  offCanvasReportClassName,
  currentDate,
}) => {
  const { loading, reports, openPetReportAddEditPanel } = useSelector(
    (state) => state.reportReducer
  );

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  const [selectedReport, setSelectedReport] = useState(null);
  const [reportCardLength, setReportCardLength] = useState(3);

  const dispatch = useDispatch();

  const openPetReportAddEditPanelFunction = () => {
    dispatch({
      type: "OPEN_CLOSE_ADD_EDIT_PET_REPORT_PANEL",
      payload: true,
    });
  };
  const closePetReportAddEditPanelFunction = () => {
    dispatch({
      type: "OPEN_CLOSE_ADD_EDIT_PET_REPORT_PANEL",
      payload: false,
    });
    setSelectedReport(null);
  };

  // useEffect(() => {
  //   if (petId) {
  //     dispatch(getAllReportsByPetId(petId, {}, () => {}));
  //   }
  // }, [petId, currentDate]);
  return (
    <>

      {reports && (
        <Accordion className="radiology">

          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 448 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
              </svg>
              <div className="head section-head d-flex align-items-center flex-grow-1">
                <div className="left-col">
                  <h3>{title ? title : "Radiology Reports"}</h3>
                </div>
                {/* <div className="right-col ms-auto d-flex justify-content-between align-items-center">
                  {permissions?.edit_patient_overview &&
                    <Button
                      color="link"
                      className="add-btn"
                      onClick={(e) => {
                        e.stopPropagation(); // Stop the event propagation
                        openPetReportAddEditPanelFunction();
                      }}
                    >
                      <i className="icomoon-plus-btn"></i>
                    </Button>
                  }
                </div> */}
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <div className="inner">
                <div className="reports-block">
                  <div className="item-col-wrapper d-flex flex-wrap">
                    {reports &&
                      reports
                        ?.map((item) => {
                          return (
                            <>
                              {" "}
                              <div
                                className={`${offCanvasReportClassName
                                    ? "offCanvas-Report-card"
                                    : "item-col"
                                  } mb-2`}
                              >
                                <ReportItem item={item} />
                              </div>
                            </>
                          );
                        })}
                  </div>
                </div>

                {/* {loading ? <Spinner height={150} /> : <div className="report-items-rw">
              <div className="item-col-wrapper d-flex flex-wrap">
                {(reports || []).map((item, index) => {
                  if (!isMore && index < 4) {
                    return <div key={index} className="item-col">
                      <ReportItem item={item} />
                    </div>
                  }
                  else if (isMore) {
                    return <div key={index} className="item-col">
                      <ReportItem item={item} />
                    </div>
                  }
                })}
              </div>
              <div className="more-item-col d-flex flex-column align-items-center justify-content-center">
                <Button onClick={() => setIsMore(!isMore)} color="link" className="more-item-btn">
                  {isMore ? 'Less' : reports?.length > 4 ? '+' + (reports?.length - 4).toString() + ' More' : ''}
                </Button>
              </div>
            </div>} */}





              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}

      <>
        <div
          className={`patient-panel-overlay position-fixed ${openPetReportAddEditPanel ? "active" : ""
            }`}
          onClick={() => closePetReportAddEditPanelFunction()}
        ></div>
        <AddEditPetXRayReport
          currentDate={currentDate}
          className={`${openPetReportAddEditPanel ? "active" : ""}`}
          closePetReportAddEditPanelFunction={
            closePetReportAddEditPanelFunction
          }
          selectedReport={selectedReport}
          setSelectedReport={setSelectedReport}
        />
      </>

      {/* <div className="reports-block">
        <div className="head section-head d-flex align-items-center">
          <div className="left-col">
            <h3>{title ? title :"Radiology Reports"}</h3>
          </div>
          <div className="right-col ms-auto d-inline-flex align-items-center">

          {
              permissions?.edit_patient_overview && viewAll ? (
                reports?.length > 3 && (
                  <div
                    className="text-14-400 color-2e65 pointer"
                    onClick={() => {
                      if (reports?.length > 3) {
                        if (reportCardLength === 3) {
                          setReportCardLength(reports?.length);
                        } else {
                          setReportCardLength(3);
                        }
                      }
                    }}
                  >
                    {reportCardLength === 3 ? "View All" : "View less"}
                  </div>
                )
            ):
            (<Button
              color="link"
              className="add-btn"
              onClick={openPetReportAddEditPanelFunction}
            >
              <i className="icomoon-plus-btn"></i>
            </Button>)
         
          }
          </div>
          
        </div>

        <div className="inner">
          <div className="report-items-rw">
            <div className="item-col-wrapper d-flex flex-wrap">
              {reports &&
                reports?.slice(0,viewAll ? reportCardLength: reports?.length)?.map((item) => {
                  return (
                    <>
                      {" "}
                      <div className={`${offCanvasReportClassName ? "offCanvas-Report-card" : "item-col"} mb-2`}>
                        <ReportItem item={item} />
                      </div>
                    </>
                  );
                })}

           
            </div>
          </div>


          {openPetReportAddEditPanel && (
            <>
              <div
                className={`patient-panel-overlay position-fixed ${
                  openPetReportAddEditPanel ? "active" : ""
                }`}
                onClick={() => closePetReportAddEditPanelFunction()}
              ></div>
              <AddEditPetXRayReport
                className={`${openPetReportAddEditPanel ? "active" : ""}`}
                closePetReportAddEditPanelFunction={
                  closePetReportAddEditPanelFunction
                }
                selectedReport={selectedReport}
                setSelectedReport={setSelectedReport}
              />
            </>
          )}
        </div>
      </div> */}
    </>
  );
};

export default ReportsBlock;
