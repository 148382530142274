
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../../../../api-client";
import { apiUrl, PORT } from "../../../../../environment";
import { toasterConfig } from "../../../../../utils/constants";

toast.configure();
/**
 * All Api endpoints to be call
*/

/**
 * get all Wounds by pet Id
 * @param {String} petId
 * @param {Object} params
 * @param {Function} callback
 * @returns 
 */
export const getDentalByPetId= (petId, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, } = getState();
        if (petId) {
            ApiClient.get(`${apiUrl}${PORT}/dental/get-all/${petId}`,{}, token, dispatch, getState).then((response) => {
                dispatch({ type: "DENTAL_LOADING", payload: false })
                if (!response) {
          
                    return callback();
                
                }
                else if (response?.status) {
                    dispatch({ type: "DENTAL", payload: response?.response_data })
                    //toast.success(response.message, toasterConfig);
                    return callback(response);
                } else {
                    dispatch({ type: "DENTAL", payload: [] })

                    //toast.error(response.message, toasterConfig);
                    return callback(response);
                }
            })
        } else {
            return callback({ status: false });
        }
    };
};



/**
 * @description function use to add new appointment
 * @param {*} params accepting object
 */
export const addNewDental = (data, callback) => {
    return (dispatch, getState) => {
        const { user: { token } } = getState();
        ApiClient.post(`${apiUrl}${PORT}/dental/add`, data, token, dispatch, getState).then((response) => {
            if (!response) {
                return callback();
            }
            else if (!response && response.errors) {
                toast.error(response.errors.msg, toasterConfig);
                return callback(response);
            } else if (response) {
                toast.dismiss();
                toast.success(response.msg, toasterConfig);
                return callback(response);
            } else {
                //toast.error(response.message, toasterConfig);
                return callback();
            }
        });
    };
};


/**
 * @description function use to add new appointment
 * @param {*} params accepting object
 */
export const addDentalNote = (data, callback) => {
    return (dispatch, getState) => {
        const { user: { token } } = getState();
        ApiClient.postFormData(`${apiUrl}${PORT}/dental/add-note`, data, token, dispatch, getState).then((response) => {
            if (!response) {
                return callback();
            }
            else if (!response && response.errors) {
                toast.error(response.errors.msg, toasterConfig);
                return callback(response);
            } else if (response) {
                toast.dismiss();
                toast.success(response.msg, toasterConfig);
                return callback(response);
            } else {
                //toast.error(response.message, toasterConfig);
                return callback();
            }
        });
    };
};










/**
 * 
 * @param {Object} body
 * @param {Function} callback
 * @returns 
 */
export const updateDental = (body, callback) => {
    return (dispatch, getState) => {
        const { user: { token } } = getState();
        ApiClient.put(`${apiUrl}${PORT}/dental/update`, body, token, dispatch, getState).then(response => {
            if (!response) {
                return callback();
            }
            else if (response?.status) {
                return callback(response);
            } else {
                //toast.error(response.message, toasterConfig);
                return callback(response);
            }
        })
    };
};
