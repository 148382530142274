import React from "react";
// import Availability from "./Availability";
//import Unavailability from "./Unavailability";
import AvailabilityOrUnavailability from "./AvailabilityOrUnavailability";
import "./index.css";

const AvailabilityUnavailaibility = ({ staffMemberDetails }) => {
  const staffMemberId = staffMemberDetails?._id || "";
  const doctorId = staffMemberDetails?.doctor?._id || "";
  return (
    <>
      <AvailabilityOrUnavailability
        staffMemberId={staffMemberId}
        doctorId={doctorId}
        status={1}
      />

      {/* <div className="staff-profile-overview-blc">
                <div className="row-block">
                    <div className="calendar-wrapper staff_schedule_table">
                        <div className="calendar-week-view overflow-auto">
                            <Availability staffMemberDetails={staffMemberDetails} />
                        </div>
                    </div>
                </div>
            </div> */}
    </>
  );
};

export default AvailabilityUnavailaibility;
