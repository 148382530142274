import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button, ButtonDropdown, DropdownToggle, Table } from "reactstrap";
import CheckBox from "../../../shared/CheckBox";
import LoaderUI from "../../../shared/loader";
import NodataFoundBlock from "../../../shared/NoDataFoundBlock";
import PaginationBlock from "../../../shared/PaginationBlock";
import TableListSupplierFilterWrapper from "../../../shared/TableListSupplierFilterWrapper";
import { getSuppliersByClinicId } from "../../../context/actions/inventory";

const Suppliers = () => {
  const history = useHistory();
  const userDetails = useSelector((state) => state.user.user_details);
  const {clinic_details} = useSelector(state => state.clinicReducer)
  const [dropdownOpen, setOpen] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const toggle = () => setOpen(!dropdownOpen);
  const dispatch = useDispatch();
  const { supplierData, loading } = useSelector(
    (state) => state.supplierReducer
  );
  // const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    handleGetSuppliers();
  }, [page, limit]);

  const handleGetSuppliers = () => {
    dispatch(
      getSuppliersByClinicId(/*userDetails?._id*/ clinic_details?._id, {page, limit}, (res) => {
        setSuppliers(res?.response_data);
        console.log(supplierData)
      })
    );
  };

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  if (loading) {
    return <LoaderUI />;
  }

  return (
    <>
      <div className="product-list-wrapper">
        <TableListSupplierFilterWrapper />
        {suppliers?.docs?.length > 0 ? (
          <div className="table-listing-blc overflow-auto">
            <Table striped responsive className="checkbox-listing">
              <thead>
                <tr>
                  <th>
                    <CheckBox
                      type="checkbox"
                      ischecked={false}
                      className="no-label"
                    />
                  </th>
                  <th style={{ minWidth: "150px" }}>Name</th>
                  <th style={{ minWidth: "160px" }}>Notes</th>
                  {permissions?.edit_suppliers &&
                    <th>&nbsp;</th>
                  }
                </tr>
              </thead>
              <tbody>
                {(suppliers?.docs || []).map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <CheckBox
                          type="checkbox"
                          ischecked={false}
                          className="no-label"
                        />
                      </td>
                      <td style={{ textTransform: "capitalize" }}>
                        {item?.supplier_name}
                      </td>
                      <td
                        className="text-clip-cell"
                        style={{
                          minWidth: "260px",
                          maxWidth: "260px",
                          width: "100%",
                        }}
                      >
                        {item?.description}
                      </td>
                      {permissions?.edit_suppliers &&
                        <td className="action-cell">
                          <Button color="link" className="edit-btn">
                            <span className="icomoon-pencil"></span>
                          </Button>
                          <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle color="link" className="more-btn">
                              <span className="icomoon-more"></span>
                            </DropdownToggle>
                            {/* <DropdownMenu>
                              <DropdownItem>Item One</DropdownItem>
                              <DropdownItem>Item Two</DropdownItem>
                            </DropdownMenu> */}
                          </ButtonDropdown>
                        </td>
                      }
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <PaginationBlock
              limit={limit}
              page={page}
              pagingCounter={supplierData?.pagingCounter || 1}
              docLength={supplierData?.docs?.length || 0}
              docPage={supplierData?.page || 1}
              totalPages={supplierData?.totalPages || 1}
              totalDocs={supplierData?.totalDocs || 0}
              _handleLimit={(val) => {
                setLimit(val);
              }}
              _handlePageChange={setPage}
            />
          </div>
        ) : (
          <NodataFoundBlock />
        )}
      </div>
    </>
  );
};

export default Suppliers;
