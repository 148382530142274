import React from "react";
import StaffProfileExperienceItem from './StaffProfileExperienceItem';
import NoDataFoundBlock from "../../../../../../../shared/NoDataFoundBlock";

const StaffProfileExperience = ({ experienceDetails }) => {
	const dummyData = [
		{
			id: 1,
			company_name: "Fuzz N' Furry Veterinary Clinic",
			designation: "Veterinarian",
			position: "Owner",
			year_from: "Jul 2007",
			year_to: "Jun 2013",
			company_address: "Western Australia, Australia",

		},
		{
			id: 2,
			company_name: "Veterinarian/Owner",
			designation: "Veterinarian",
			position: "Owner",
			year_from: "Jul 2007",
			year_to: "Jun 2013",
			company_address: "Western Australia, Australia",

		},

	]

	return (
		<>
			<div className="staff-profile-education responsive">
				<h4 className="section-title cmb-10">Experience</h4>
				<div className="inner d-flex flex-wrap">
					{
						!dummyData || dummyData?.length <= 0 ? <NoDataFoundBlock className="layout-sm" /> : dummyData && dummyData?.map((experience, index) => (
							<div key={index} className="col-cell d-flex flex-column">
								<StaffProfileExperienceItem experience={experience} />
							</div>
						))
					}
				</div>
			</div>
		</>
	);
};

export default StaffProfileExperience;
