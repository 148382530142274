/*********** Reduceres defined here *********/

import { persistCombineReducers } from "redux-persist";
import storage from "redux-persist/es/storage"; // default: localStorage if web, AsyncStorage if react-native
import encryptor from './encryptor';
import { user } from "./user";
import { sidebarPanelReducer } from "./sidebarPanelReducer";
import { clinicSettingsReducer } from "./clinicSettings";
import { waitingRoomReducer } from "./waitingRoom";
import { clinicReducer } from "./clinic";
import { appContentReducer } from "./app-content";
import { holidayReducer } from "./holidayReducer";
import { auditLogsReducer } from "./auditLogsReducer";
import { inventoryReducer } from "./inventoryReducer";
import { supplierReducer } from "./supplierReducer";
import { petReducer } from "./patient-module/petReducer";
import { petOwnerReducer } from "./patient-module/petOwnerReducer";
import { currentConditionReducer } from "./patient-module/overview/currentConditionReducer";
import { diagnosticTestingReducer } from "./patient-module/diagnosticTestingReducer";
import { medicationPlanReducer } from "./patient-module/overview/medicationPlanReducer";
import { reportReducer } from "./patient-module/overview/reportReducer";
import { patientListPanelReducer } from "./patientListPanelReducer";
import { medicalHistoryReducer } from "./patient-module/medicalHistoryReducer";
import { petDocumentReducer } from "./patient-module/petDocumentReducer";
import { referralReducer } from "./referralReducer";
import { orderReducer } from "./patient-module/orderReducer";
import { vitalTestingReducer } from "./patient-module/vitalTestingReducer";
import { intakeOutputReducer } from "./patient-module/intakeOutputReducer";
import { ivReducer } from "./patient-module/ivReducer";
import { marReducer } from "./patient-module/marsReducer";
import { appointmentDetailPanelReducer } from "./appointmentDetailReducer";
import { woundsReducer } from "./patient-module/woundsReducer";
import { drainsTubesReducer } from "./patient-module/drainsTubesReducer";
import { ocularReducer } from "./patient-module/ocularReducer";
import { bloodProductsReducer } from "./patient-module/bloodProductsReducer";
import { patientIntakeFormReducer } from "./patientIntakeFormReducer";
import { dentalReducer } from "./patient-module/dentalReducer";
import { notesReducer } from "./patient-module/notes";
//import {invoicesReducer } from './invoicesReducer';
import { surgeriesReducer } from "./surgeriesReducer";
import { kennelReducer } from "./kennelReducer";
import { taskAndReminderReducer } from "./taskAndReminderReducer";
import { operatingRoomReducer } from "./operatingRoomReducer";
import { clinicTaskReducer } from "./clinicTaskReducer";
import { dischargePatientReducer } from "./dischargePatientReducer";
import { doctorAvailabilitiesReducer } from "./doctorAvailabilitiesReducer";
import { vaccineScheduleReducer } from "./vaccineScheduleReducer";
import { vitalsTogglesReducer } from "./vitalToggleReducer";
import { petOwnerDashboardReducer } from "./petOwnerDashboardReducer";
import { allergyReducer } from "./allergyReducer";
import { behaviourReducer } from "./behaviourReducer";
import { realTimeChatReducer } from "./realTimeChat";
import { formTemplateReducer } from "./formTemplateReducer";
import { clinicPermissionReducer } from "./clinicPermissionReducer";
import { allPermissionReducer } from "./allPermissionReducer";
import { petOwnerDoctorListReducer } from "./pet-owner-doctorListreducer"
import { videoReducer } from "./videoReducer"

const userPersistConfig = {
  key: "front-end-app",
  storage: storage,
  transforms: [encryptor],
  blacklist: ["router", "loader"],
};
const combinedReducers = persistCombineReducers(userPersistConfig, {
  user,
  sidebarPanelReducer,
  waitingRoomReducer,
  clinicSettingsReducer,
  clinicReducer,
  appContentReducer,
  holidayReducer,
  auditLogsReducer,
  inventoryReducer,
  referralReducer,
  supplierReducer,
  petReducer,
  petOwnerReducer,
  currentConditionReducer,
  medicationPlanReducer,
  reportReducer,
  patientListPanelReducer,
  medicalHistoryReducer,
  petDocumentReducer,
  diagnosticTestingReducer,
  orderReducer,
  vitalTestingReducer,
  intakeOutputReducer,
  ivReducer,
  marReducer,
  appointmentDetailPanelReducer,
  woundsReducer,
  drainsTubesReducer,
  ocularReducer,
  bloodProductsReducer,

  patientIntakeFormReducer,

  surgeriesReducer,

  kennelReducer,
  operatingRoomReducer,

  taskAndReminderReducer,
  dentalReducer,
  notesReducer,
  clinicTaskReducer,
  dischargePatientReducer,
  doctorAvailabilitiesReducer,
  vaccineScheduleReducer,
  vitalsTogglesReducer,

  petOwnerDashboardReducer,
  allergyReducer,
  behaviourReducer,

  realTimeChatReducer,
  formTemplateReducer,
  clinicPermissionReducer,
  allPermissionReducer,
  petOwnerDoctorListReducer,
  videoReducer
  /*


  invoicesReducer,

  */
});


// export default (state, action) => {
//   if(action.type === 'RESET_APP'){
//     //adlocalStorage.removeItem('persist:root')
//     state = undefined
//   }
//   return combinedReducers(state, action)
// }

export default combinedReducers



