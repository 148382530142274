import * as Yup from "yup";

/**
 * @description declaration form schema for add pet  validations
 */
export const addTaskSchema = Yup.object().shape({
    task_name: Yup.string()
        .required("Task time is required"),
    task_time: Yup.string()
        .required("Task time is required"),
    reminder_time: Yup.string()
        .required("Reminder time is required"),
});


export const updateTaskSchema = Yup.object().shape({
    task_name: Yup.string()
        .required("Task time is required"),
    task_time: Yup.string()
        .required("Task time is required"),
    reminder_time: Yup.string()
        .required("Reminder time is required"),
    task_status:  Yup.object().shape({
        label: Yup.string(),
        value: Yup.string().required("Status is required"),
    }),
});

/**
 * @description params intilaization 
 */

export const addTaskInitialValue = {
    task_name: '',
    task_time: '',
    reminder_time: '',
}