import React, { useState } from "react";
import { Steps, Panel, Placeholder, ButtonGroup, Button } from 'rsuite';
import { useHistory } from "react-router-dom";
import './index.css';
import { IoIosArrowBack } from "react-icons/io";
import StepOrder from "./StepOrder";
import ListProduct from "./ListProduct";
import MakePayment from "./MakePayment";
import Disabled from "../../shared/Disabled";
import { useDispatch, useSelector } from "react-redux";


const AddPurchaseOrder = () => {
    const history = useHistory();
    const [step, setStep] = React.useState(0);
    const { purchaseOrderFormData } = useSelector((state) => state.inventoryReducer)
    const onChange = nextStep => {
        setStep(nextStep < 0 ? 0 : nextStep > 3 ? 3 : nextStep);
    };
    const onNext = () => onChange(step + 1);
    const onPrevious = () => onChange(step - 1);
    const [values, setValues] = useState({})
    const dispatch = useDispatch()
    const handleFormSubmit = (formValues) => {
        setValues({ ...values, ...formValues })
        onNext()
        dispatch({ type: "PURCHASE_ORDER_FORM_DATA", payload: { ...purchaseOrderFormData, ...formValues } })
    }
    console.log("purchaseOrderFormData", purchaseOrderFormData)
    function Paragraph(props) {
        if (props.value == '0') {
            return (
                <>
                    <StepOrder onSubmit={handleFormSubmit} />

                </>
            )
        }
        else if (props.value == '1') {
            return (
                <>
                    {/* <StepOrder onNext={onNext} /> */}
                    <ListProduct />
                    {/* <MakePayment /> */}
                </>
            )
        }
        else if (props.value == '2') {
            // if (props.value == '2') {
            return (
                <>
                    {/* <StepOrder onNext={onNext} />
                        <ListProduct /> */}
                    < MakePayment />
                </>
            )

            // }
            // else {
            //     return (
            //         <>
            //             {/* <StepOrder />
            //             <ListProduct />
            //             <MakePayment /> */}
            //         </>
            //     )
            // }
        }
        else {
            return (
                <>
                    {/* <StepOrder />
                        <ListProduct />
                        <MakePayment /> */}
                </>
            )
        }
    }

    if(true){
        return(<Disabled />)
    }

    return (
        <>
            <div className="pl-4 flex justify-between items-baseline">
                <p
                    className="flex items-center cursor-pointer relative left-[-13px] pt-4"
                >
                    <span className="px-2">
                        <IoIosArrowBack size={20} color="#3D3EFD" />
                    </span>{" "}
                    <span onClick={() => {
                        history.goBack();
                    }} className="font-['Roboto'] text-[#3D3EFD] font-[500] uppercase">
                        Back to List
                    </span>
                </p>
                <div className="flex gap-2">
                    <button
                        className="w-[150px] uppercase h-[40px] text-sm font-[600] text-center bg-[#fff] text-[#000] font-['Inter'] border rounded-lg"
                    >
                        Cancel
                    </button>
                    <button
                        className="w-[150px] mr-3 uppercase h-[40px] text-sm font-[600] text-center bg-[#3D3EFD] text-[#fff] font-['Inter'] border rounded-lg"
                    >
                        place order
                    </button>
                </div>
            </div>
            <p className="text-[#282E65] pl-4 font-[500] mt-4">
                <span className="block">Create New</span>
                <span className="block">Purchase Order</span>
            </p>
            <div className="stepper_row pl-4 mt-4">
                <div class="row w-100 mx-auto">
                    <div className="col-md-4">
                        <Steps current={step}>
                            <Steps.Item title="Setup Order" description="Add supplier information and supplier type" />
                            <Steps.Item title="List Products" description="Add all products with quantity." />
                            <Steps.Item title="Make Payment" description="After payment confirmation, Seller will received and proceed your order" />
                        </Steps>
                    </div>
                    <div className="col-md-8">
                        <Panel>
                            <Paragraph value={step} />
                        </Panel>
                        {/* <ButtonGroup>
                            <Button onClick={onPrevious} disabled={step === 0}>
                                Previous
                            </Button>
                            <Button onClick={onNext} disabled={step === 2}>
                                Next
                            </Button>
                        </ButtonGroup> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddPurchaseOrder;

