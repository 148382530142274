import React, { useState } from "react";
import { Offcanvas, Spinner } from "react-bootstrap";
import { FormGroup, Input, Label } from "reactstrap";
import ReactDatePicker from "react-datepicker";
import { CiCalendar } from "react-icons/ci";
import TimeIntarval from "../../StaffDetail/StaffProfileTabBlock/AvailabilityUnavailaibility/AvailabilityOrUnavailability/AddSchedule/TimeIntarval";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { addHoliday, getAllHoliday } from "context/actions/holiday";
import { toast } from "react-toastify";
import SwitchCell from "shared/SwitchCell";
import "./AddHoliday.scss";
import TimePicker from "shared/TimePicker";

const AddHoliday = ({ onHide }) => {
  const dispatch = useDispatch();
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const { holidays } = useSelector((state) => state.holidayReducer);
  const [resetCount, setResetCount] = useState(0);
  const [btnLoader, setBtnLoader] = useState(false);
  const [formValue, setFormValue] = useState({
    date: "",
    status: "",
    duration: "",
    from_time: "",
    to_time: "",
    memo: "",
    isRecurring: false,
  });
  const handleSave = async () => {
    let existId = holidays?.find(
      (o) =>
        moment(o.date, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format("DD-MM-YYYY") ===
        moment(date).format("DD-MM-YYYY")
    )?._id;
    if (existId) {
      toast.error("Holiday alreday exist for this date!");
    } else {
      setBtnLoader(true);
      const payload = {
        ...formValue,
        date: moment(formValue.date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        clinic_id: clinic_details?._id,
      };
      await dispatch(addHoliday(payload))
        .then((res) => {
          setBtnLoader(false);
          if (res?.status) {
            toast.success(res.message);
          }
          return res;
        })
        .then((res) => {
          if (res?.status) {
            setResetCount(resetCount + 1);
            dispatch(getAllHoliday({}, () => {}));
            onHide();
          }
        });
    }
  };
  const handleChange = (e) => {
    let targetID = e?.target?.id || e?.target?.name;
    let targetValue = e?.target?.value;
    setFormValue((prev) => {
      let newObj = { ...prev };
      if (targetID === "time") {
        newObj.from_time = e.target.startTime;
        newObj.to_time = e.target.endTime;
      } else {
        newObj[targetID] = targetValue;
      }
      if (targetID === "duration" && targetValue === "all-day") {
        newObj.from_time = "";
        newObj.to_time = "";
      }
      return newObj;
    });
  };
  const { date, status, duration, memo, from_time, to_time, isRecurring } =
    formValue;

  let isBtnAccess = false;
  if (date && status && duration && memo) {
    if (duration === "all-day") {
      isBtnAccess = true;
    } else {
      isBtnAccess = from_time && to_time ? true : false;
    }
  }
  return (
    <Offcanvas show onHide={onHide} placement="end" id="add-holiday-overlay">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Add New Holiday</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div>
          <div className="d-flex gap-2 mb-2">
            <FormGroup className="date-range-block w-100" floating>
              <div className="form-floating">
                <ReactDatePicker
                  selected={date}
                  placeholderText="MM/DD/YYYY"
                  onChange={(update) => {
                    handleChange({ target: { id: "date", value: update } });
                  }}
                />
                <span className="select-icon-block">
                  <CiCalendar />
                </span>
              </div>
            </FormGroup>
          </div>
          <div className="radio-container-block mb-2 fa-center gap-4">
            <div className="text-14-400 color-2E65 cme-8">Status</div>
            <div className="fa-center gap-3">
              <FormGroup className="mb-0">
                <Input
                  name="status"
                  type="radio"
                  value="open"
                  onChange={handleChange}
                  checked={status === "open"}
                />
                <Label className="mb-0 ms-2 text-16-400 color-2E65">Open</Label>
              </FormGroup>
              <FormGroup className="mb-0">
                <Input
                  name="status"
                  type="radio"
                  value="close"
                  onChange={handleChange}
                  checked={status === "close"}
                />
                <Label className="mb-0 ms-2 text-16-400 color-2E65">
                  Close
                </Label>
              </FormGroup>
            </div>
          </div>
          <div className="radio-container-block mb-2 fa-center gap-4">
            <div className="text-14-400 color-2E65">Time</div>
            <div className="fa-center gap-3 ms-3">
              <FormGroup className="mb-0">
                <Input
                  name="duration"
                  value="custom"
                  type="radio"
                  onChange={handleChange}
                  checked={duration === "custom"}
                />
                <Label className="mb-0 ms-2 text-16-400 color-2E65">
                  Partial Day
                </Label>
              </FormGroup>
              <FormGroup className="mb-0">
                <Input
                  name="duration"
                  value="all-day"
                  type="radio"
                  onChange={handleChange}
                  checked={duration === "all-day"}
                />
                <Label className="mb-0 ms-2 text-16-400 color-2E65">
                  All Day
                </Label>
              </FormGroup>
            </div>
          </div>
          {duration === "custom" && (
            <div className="mb-2 fa-center gap-2">
              <div className="w-50">
                <TimePicker
                  placeholder="Start time"
                  minIntarval="15"
                  id="from_time"
                  resetCount={resetCount}
                  onChange={(e) => {
                    handleChange({ target: e });
                  }}
                />
              </div>
              <div className="flex-grow-1">
                <TimePicker
                  placeholder="End time"
                  minIntarval="15"
                  id="to_time"
                  startTime={from_time}
                  resetCount={resetCount}
                  onChange={(e) => {
                    handleChange({ target: e });
                  }}
                />
              </div>
            </div>
          )}
          <div className="note-input-block mb-2">
            <input
              id="memo"
              type="text"
              placeholder="Add Notes"
              onChange={handleChange}
              value={memo}
            />
          </div>
          <div className="mb-2">
            <SwitchCell
              labelVisible
              label="Recurring"
              className={isRecurring ? "" : "disable"}
              onClick={() => {
                handleChange({
                  target: { id: "isRecurring", value: !isRecurring },
                });
              }}
            />
          </div>
          <div className="text-13-400 color-8693 mb-2">
            Stats description lorem Ipsum is simply dummy text of the printing
            and typesetting industry.
          </div>
          <div className="d-flex gap-2 justify-content-end">
            <button
              className="bg-ffff color-2E65 text-13-600 h-40 w-150 text-center br-8 uppercase b-2E65"
              onClick={onHide}
            >
              Cancel
            </button>
            <button
              className={`bg-CA9D color-ffff text-13-600 h-40 w-150 text-center br-8 uppercase ${
                isBtnAccess ? "" : "opacity-50"
              }`}
              onClick={() => {
                if (isBtnAccess && !btnLoader) {
                  handleSave();
                }
              }}
            >
              Create holiday
              {btnLoader && <Spinner size="sm" className="ms-2" />}
            </button>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AddHoliday;
