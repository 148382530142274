import React, { useEffect, useState } from "react";
import { Button, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import StepsBlock from "./StepsBlock";
import OwnerInfo from "./OwnerInfo";
import PetInfo from "./PetInfo";
import PlanBlock from "../../../shared/PlanBlock";
import HeaderBlock from "./HeaderBlock";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllergiesTags,
  getBehaviourTags,
} from "../../../context/actions/app-content";
import { useQuery } from "../../../utils/custom-hooks/useQuery";
import {
  addPetOwnerWithPetdetails,
  getPetAndOwnerDetails,
  getWellnessPlans,
  updatePetImage,
  updateWellNessPlane,
} from "../../../context/actions/patient-module/patient/pet";
import LoaderUI from "../../../shared/loader";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { toasterConfig } from "../../../utils/constants";
import SelectExistingOwner from "./SelectExistingOwner";
import Disabled from "../../../shared/Disabled";
import { addPatientDetails } from "../../../context/actions/patient-module/patient/pet";
import moment from "moment";

const AddPatientDetail = () => {
  const [activeTab, setActiveTab] = useState("1");
  const query = useQuery();
  const ownerId = query.get("ownerId");
  const petId = query.get("petId");
  const dispatch = useDispatch();
  const { petAndOwnerDetails, petFormData } = useSelector(
    (state) => state.petReducer
  );
  const { user } = useSelector((state) => state.user);
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const [ownerImage, setOwnerImage] = useState(null);
  const [petImage, setPetImage] = useState(null);
  const [step, setStep] = useState(0);
  const [selectPlan, setSelectPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ownerAndPetDetails, setOwnerAndPetDetails] = useState(null);
  const history = useHistory();

  const toggle = (tab) => {
    if (activeTab !== tab) {
      if (tab === "1") {
        history.replace("/patients/add-patient");
      }
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (ownerId && petId) {
      dispatch(
        getPetAndOwnerDetails({ ownerId: ownerId, petId }, (response) => {
          if (response?.status) {
            setSelectPlan(petAndOwnerDetails?.pet_details?.wellness_plan_id);
            setStep(2);
          } else {
            setStep(0);
            //reset form data
            dispatch({ type: "PET_FORM_DATA", payload: false });
          }
          setLoading(false);
        })
      );
    } else if (ownerId) {
      setStep(1);
      var dataCopy = { ...petFormData };
      // we should here delete all keys related to owner then pass to payload
      for (var key in dataCopy) {
        if (
          ["firstname", "lastname", "phone", "email", "address"].includes(key)
        ) {
          delete dataCopy[key];
        }
      }
      dispatch({ type: "PET_FORM_DATA", payload: { ...dataCopy } });
      setLoading(false);
    } else {
      setStep(0);
      //reset form data
      dispatch({ type: "PET_FORM_DATA", payload: false });
      setLoading(false);
    }
  }, [ownerId, petId]);

  const handelSubmitButton = () => {
    let values = { ...ownerAndPetDetails };

    // if pet owner already have an accound but no wellness plan then select plan
    if (ownerId && petId) {
      setLoading(true);
      // update only plan
      dispatch(
        updateWellNessPlane(
          {
            wellness_plan_id: selectPlan,
            pet_id: petId,
          },
          () => {
            setLoading(false);
            dispatch({ type: "PET_FORM_DATA", payload: false });

            history.replace(`/patient-detail/${petId}`);
          }
        )
      );
    }

    // add a case here for if we have selected the owner but the pet is new, then we just create the pet and update the image, as in the case below
    else {
      let formData = new FormData();
      for (let item in values) {
        if (values[item]) {
          if (item === "behaviour_tag" || item === "allergy_tag")
            formData.append(item, JSON.stringify(values[item]));
          else if (item === "dob")
            formData.append(item, moment(values[item]).toDate());
          else formData.append(item, values[item]);
        }
      }
      /*
			added_by: value?.added_by,
                firstname: value?.firstname,
                lastname: value?.lastname,
                phone: value?.phone,
                email: value?.email,
                address: value?.address,
                role: value?.role,
                clinic: value?.clinic,
                user_image: req?.file?.path ? imagePath : null
			*/

      if (ownerId) {
        formData.append("added_by", user?._id);
        formData.append("clinic", clinic_details?._id);
        formData.append("pet_owner", ownerId);
        setLoading(true);
        dispatch(
          addPatientDetails(formData, (response) => {
            setLoading(false);
            if (petImage && response?.status) {
              //here update pet image after create destails
              let petFormData = new FormData();
              petFormData.append("pet", response?.response_data?._id);
              petFormData.append("pet_image", petImage, petImage?.name);

              dispatch(
                updatePetImage(petFormData, () => {
                  setLoading(false);
                  if (response?.response_data) {
                    history.replace(
                      `/patient-detail/${response?.response_data?._id}`
                    );
                    dispatch({ type: "PET_FORM_DATA", payload: false });
                    setOwnerAndPetDetails(null);
                  }
                })
              );
            } else if (response?.status) {
              history.replace(
                `/patient-detail/${response?.response_data?._id}`
              );
              dispatch({ type: "PET_FORM_DATA", payload: false });
              setOwnerAndPetDetails(null);
            }
          })
        );
      } else {
        if (selectPlan) formData.append("wellness_plan_id", selectPlan);
        formData.append("added_by", user?._id);
        formData.append("clinic", clinic_details?._id);
        // formData.append("clinic_id", clinic_details?._id);

        formData.append("pet_owner_image", ownerImage);
        formData.append("role", "pet-owner");

        setLoading(true);
        dispatch(
          addPetOwnerWithPetdetails(formData, (response) => {
            // console.log("response",formData,response)
            setLoading(false);
            if (petImage && response?.status) {
              //here update pet image after create destails
              let petFormData = new FormData();
              petFormData.append(
                "pet",
                response?.response_data?.petDetails?._id
              );
              petFormData.append("pet_image", petImage, petImage?.name);

              dispatch(
                updatePetImage(petFormData, () => {
                  setLoading(false);
                  if (response?.response_data) {
                    setOwnerAndPetDetails(null);
                    dispatch({ type: "PET_FORM_DATA", payload: false });

                    history.replace(
                      `/patient-detail/${response?.response_data?.petDetails?._id}`
                    );
                  }

                  //wellness plan added then redired to details page

                  /*
						if (response?.response_data?.petDetails?.wellness_plan_id) {
							history.replace(`/patient-detail/${response?.response_data?.petDetails?._id}`)
						} else {
							toast.success(response?.message, toasterConfig)
							history.replace(`/add-patient-detail?petId=${response?.response_data?.petDetails?._id}&ownerId=${response?.response_data?.ownerDetails?._id}`);
						}*/
                })
              );
            } else if (response?.status) {
              setOwnerAndPetDetails(null);
              dispatch({ type: "PET_FORM_DATA", payload: false });
              history.replace(
                `/patient-detail/${response?.response_data?.petDetails?._id}`
              );
            }
          })
        );
      }
    }
  };

  const handleOwnerSubmit = (values) => {
    // console.log("values form data",values)
    setStep(1);
    setOwnerAndPetDetails((p) => (p = { ...p, ...values }));
    // this is redux state form persisting form data
    dispatch({ type: "PET_FORM_DATA", payload: { ...petFormData, ...values } });
  };
  const handlePetSubmit = (values) => {
    //setStep(2);
    setOwnerAndPetDetails((p) => (p = { ...p, ...values }));
    // this is redux state form persisting form data
    dispatch({ type: "PET_FORM_DATA", payload: { ...petFormData, ...values } });

    if(ownerAndPetDetails?.pet_name){

      handelSubmitButton();
    }

  };

  const handleCancelOrBack = () => {
    if (petId && ownerId) {
      history.go(0);
    } else {
      setStep(step - 1);
    }
  };
  const [wellnessPlans, setWellnessPlans] = useState([]);


  useEffect(() => {
    if(ownerAndPetDetails?.pet_name){

      handelSubmitButton();
    }

  }, [ownerAndPetDetails])

  useEffect(() => {
    setLoading(true);
    /*dispatch(getWellnessPlans(clinic_details._id, { active: true }, (resp) => {
			setWellnessPlans(resp.response_data)
		}))*/
    setPetImage(null);

    dispatch({ type: "GET_PET_AND_OWNER", payload: false });

    dispatch(getBehaviourTags({}, () => {}));
    dispatch(getAllergiesTags({}, () => {}));

    setOwnerAndPetDetails(null);
    dispatch({ type: "PET_FORM_DATA", payload: false });
    setLoading(false);
  }, []);

  let wellnessPlanObj = {
    Gold: {
      planType: "golden",
      planName: "Golden Care",
      planPriceStr: "Price $300 (One Time)",
      planPrice: 300,
      className: "golden-plan",
    },
    Silver: {
      planType: "silver",
      planName: "Silver Care",
      planPriceStr: "Price $100 (One Time)",
      planPrice: 100,
      className: "super-plan",
    },
    Bronze: {
      planType: "primary",
      planName: "Primary Care",
      planPriceStr: "Price $200 (One Time)",
      planPrice: 200,
      className: "primary-plan",
    },
  };

  return (
    <>
      {loading && <LoaderUI overlay={true} />}
      <div className="page-wrapper add-patient-wrapper position-relative d-flex flex-column overflow-auto">
        <HeaderBlock />
        <div className="inner-wrapper d-flex overflow-auto">
          <div className="left-col d-flex flex-column overflow-auto">
            <h2>Add New Patient</h2>
            <StepsBlock step={step} />
          </div>
          {!loading && (
            <div className="right-col flex-fill d-flex flex-column overflow-auto">
              <div className="patient-all-info w-100">
                {/* Step--1 */}
                {step == 0 && (
                  <div className="row-block">
                    <div className="head">
                      <h3>Owner Infromation</h3>
                    </div>
                    <div className="inner">
                      <div className="owner-tab-blc">
                        <Nav tabs className="flex-shrink-0">
                          <NavItem>
                            <NavLink
                              className={{ active: activeTab === "1" }}
                              onClick={() => {
                                toggle("1");
                              }}
                            >
                              New
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={{ active: activeTab === "2" }}
                              onClick={() => {
                                toggle("2");
                              }}
                            >
                              Existing
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent
                          activeTab={activeTab}
                          className="overflow-auto"
                        >
                          <TabPane tabId="1">
                            <OwnerInfo
                              step={step}
                              onSubmit={handleOwnerSubmit}
                              file={ownerImage}
                              onFileChange={(e) => setOwnerImage(e)}
                            />
                          </TabPane>
                          <TabPane tabId="2">
                            <SelectExistingOwner step={step} />
                          </TabPane>
                        </TabContent>
                      </div>
                    </div>
                  </div>
                )}
                {/* Step--2 */}
                {step == 1 && (
                  <div className="row-block">
                    <div className="head">
                      <h3>Pet Infromation</h3>
                    </div>
                    <div className="inner">
                      <PetInfo
                        step={step}
                        onBack={() => setStep(step - 1)}
                        onSubmit={handlePetSubmit}
                        file={petImage}
                        onFileChange={(e) =>
                          setPetImage(/*e.target.files[0]*/ e)
                        }
                      />
                    </div>
                  </div>
                )}
                {/* Step--3 */}
                {step == 2 && (
                  <div className="row-block">
                    <div className="head">
                      <h3>Select Plan</h3>
                    </div>
                    <div className="inner">
                      <div className="plans-wrapper d-flex flex-wrap">
                        {wellnessPlans?.map((ele) => {
                          return (
                            <div className="col-cell d-flex flex-column">
                              <PlanBlock
                                onSelect={() => setSelectPlan(ele._id)}
                                planType={wellnessPlanObj[ele.name].planType}
                                planName={wellnessPlanObj[ele.name].planName}
                                planPrice={
                                  wellnessPlanObj[ele.name].planPriceStr
                                }
                                className={wellnessPlanObj[ele.name].className}
                              />
                            </div>
                          );
                        })}

                        {/* <div className="col-cell d-flex flex-column">
											<PlanBlock onSelect={() => setSelectPlan("63031b1fe0a06f15643e8101")} planType={'golden'} planName={'Golden Care'} planPrice={'Price $300 (One Time)'} className="golden-plan" />
										</div>
										<div className="col-cell d-flex flex-column">
											<PlanBlock onSelect={() => setSelectPlan("63031b1fe0a06f15643e8102")} planType={'primary'} planName={'[Plan Name]'} planPrice={'Price $200 (One Time)'} className="primary-plan" />
										</div> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {step == 2 && (
                <div className="proceed-btns d-flex align-items-center justify-content-between">
                  <Button
                    color="link"
                    onClick={handleCancelOrBack}
                    className="btn btn-outline-light"
                  >
                    {petId && ownerId ? "Cancel" : "Back"}
                  </Button>
                  <Button
                    color="success"
                    onClick={handelSubmitButton}
                    className="btn btn-outline-light"
                  >{`${selectPlan ? "Save & Proceed" : "No Plan"}`}</Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AddPatientDetail;
