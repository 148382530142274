import React from "react";
import StaffprofileDocumentItem from './StaffprofileDocumentItem';
import './index.scss';
import NoDataFoundBlock from "../../../../../../../shared/NoDataFoundBlock";

const StaffprofileDocument = ({ documentDetails }) => {
	const dummyData = [
		{
			id: 1,
			document_name: "Dummy doc",
			document: { document: "dummy" },
			additional_info: "No info",
		},
		{
			id: 2,
			document_name: "Dummy doc",
			document: { document: "dummy" },
			additional_info: "No info",
		},
		{
			id: 3,
			document_name: "Dummy doc",
			document: { document: "dummy" },
			additional_info: "No info",
		},
		{
			id: 4,
			document_name: "Dummy doc",
			document: { document: "dummy" },
			additional_info: "No info",
		},
	]

	return (
		<>
			<div className="staff-profile-document">
				<h4 className="section-title cmb-10">Documents</h4>
				<div className="inner d-flex flex-wrap">
					{/*
						!dummyData || dummyData?.length <= 0 ? <NoDataFoundBlock className="layout-sm" /> : dummyData && dummyData?.map((document, index) => (
							<div key={index} className="col-cell d-flex flex-column">
								<StaffprofileDocumentItem document={document} />
							</div>
						))
						*/}
				</div>
			</div>
		</>
	);
};

export default StaffprofileDocument;