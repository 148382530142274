import moment from "moment";
import React, { useEffect, useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Table } from "reactstrap";
import DateRange from "shared/FormsInput/DateRange";
import { Icons } from "utils/contants/Icons";
import "./InvoiceAndEstimatesReport.scss";
import { getInvoiceRecordRecords } from "context/actions/clinic-reports";
import { getAllSpecies } from "context/actions/app-content";
import NodataFoundBlock from "shared/NoDataFoundBlock";
import { USDollar, titleCaseString } from "utils/common";
import LoaderUI from "shared/loader";
import PaginationBlock from "shared/PaginationBlock";

const InvoiceAndEstimatesReport = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const [endDate, setEndDate] = useState(null);
  const [limit, setLimit] = useState(5);
  const [paidPage, setPaidPage] = useState(1);
  const [paidPaging, setPaidPaging] = useState(1);
  const [genetatedPage, setGeneratedPage] = useState(1);
  const [genetatedPaging, setGeneratedPaging] = useState(1);
  const [fullyPaidPage, setFullyPaidPage] = useState(1);
  const [fullyPaidPaging, setFullyPaidPaging] = useState(1);
  const [discountPage, setDiscountPage] = useState(1);
  const [discountPaging, setDiscountPaging] = useState(1);
  const [estGeneratePage, setEstGeneratePage] = useState(1);
  const [estGeneratePaging, setEstGeneratePaging] = useState(1);
  const [filterObj, setFilterObj] = useState({
    start_date: "",
    end_date: "",
  });
  const [tableData, setTableData] = useState({
    data: [],
    loading: true,
  });

  const fetchTableData = (obj) => {
    dispatch(
      getInvoiceRecordRecords(
        { clinic_id: clinic_details?._id, ...obj },
        (res) => {
          setTableData((prev) => {
            return {
              ...prev,
              data: res,
              loading: false,
            };
          });
        }
      )
    );
  };

  useEffect(() => {
    fetchTableData(filterObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterObj]);

  useEffect(() => {
    dispatch(getAllSpecies({}, () => {}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (tableData?.data?.discount) {
  //     const updatedToPages = tableData?.data?.discount?.map((arr, _) => 1);
  //     setDiscountPage(updatedToPages);
  //     setDiscountPaging(updatedToPages);
  //   }
  // }, [tableData?.data?.discount]);

  const paidInvoicesPages = Math?.ceil(
    tableData?.data?.paidInvoices?.length / limit
  );
  const paidInvoicesTable = tableData?.data?.paidInvoices?.slice(
    (paidPage - 1) * limit,
    paidPage * limit
  );

  const generatedInvoicesPages = Math?.ceil(
    tableData?.data?.generatedInvoices?.length / limit
  );
  const generatedInvoicesTable = tableData?.data?.generatedInvoices?.slice(
    (genetatedPage - 1) * limit,
    genetatedPage * limit
  );

  const fullyPaidInvoicePages = Math?.ceil(
    tableData?.data?.fullyPaidInvoices?.[0]?.invoices?.length / limit
  );
  const fullyPaidInvoiceTable =
    tableData?.data?.fullyPaidInvoices?.[0]?.invoices?.slice(
      (fullyPaidPage - 1) * limit,
      fullyPaidPage * limit
    );

  const discountTotalPage = Math?.ceil(
    tableData?.data?.discount?.length / limit
  );
  const discountTableData = tableData?.data?.discount?.slice(
    (discountPage - 1) * limit,
    discountPage * limit
  );

  const estGenerateTotalPage = Math?.ceil(
    tableData?.data?.generatedEstimates?.length / limit
  );
  const estGenerateTable = tableData?.data?.generatedEstimates?.slice(
    (estGeneratePage - 1) * limit,
    estGeneratePage * limit
  );

  // const generatedInvoicesTable = tableData?.data?.generatedInvoices;
  // const fullyPaidInvoiceTable = tableData?.data?.fullyPaidInvoices;

  const discountTable = tableData?.data?.discount;
  const generatedEstimatesTable = tableData?.data?.generatedEstimates;

  // const getPaginatedData = (dataArray, tabIndex) => {
  //   const startIndex = (discountPage[tabIndex] - 1) * limit;
  //   const endIndex = startIndex + limit;
  //   return dataArray?.slice(startIndex, endIndex) || [];
  // };

  const handleDownload = () => {
    const csvContent = generateCSV();
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "Invoice And Estimates Report.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  const generateCSV = () => {
    let csv = "";
    if (tableData?.data?.paidInvoices?.length > 0) {
      csv += `\n\n ${"Invoices paid"}\nInvoice ID,Amount,Issued on,Date Due,Payer,Status,Payment Method\n`;
      tableData?.data?.paidInvoices?.forEach((item) => {
        const {
          _id,
          totalAmount,
          issued_date,
          due_date,
          payer_detail,
          status,
        } = item;

        // Add headers based on the first item in the category
        csv += `IN${_id?.substring(5, 12)}, $${totalAmount}, ${
          moment(issued_date)?.format("MM/DD/YYYY") || "-"
        },${moment(due_date)?.format("MM/DD/YYYY") || "-"},${
          payer_detail?.firstname + " " + payer_detail?.lastname
        },${status}, ${"Credit Card"}\n`;
      });
    }
    if (tableData?.data?.generatedInvoices?.length > 0) {
      csv += `\n\n ${"Invoices generated"}\nInvoice ID,Amount,Issued on,Date Due,Payer,Status,Payment Method\n`;
      tableData?.data?.generatedInvoices?.forEach((item) => {
        const {
          _id,
          totalAmount,
          issued_date,
          due_date,
          payer_detail,
          status,
        } = item;

        // Add headers based on the first item in the category
        csv += `IN${_id?.substring(5, 12)}, $${totalAmount}, ${
          moment(issued_date)?.format("MM/DD/YYYY") || "-"
        },${moment(due_date)?.format("MM/DD/YYYY") || "-"},${
          payer_detail?.firstname + " " + payer_detail?.lastname
        },${status}, ${"Credit Card"}\n`;
      });
    }
    if (tableData?.data?.fullyPaidInvoices?.[0]?.invoices?.length > 0) {
      csv += `\n\n ${"Fully Paid Invoices"}\nInvoice ID,Amount,Issued on,Date Due,Payer,Status,Payment Method\n`;
      tableData?.data?.generatedInvoices?.forEach((item) => {
        const {
          _id,
          totalAmount,
          issued_date,
          due_date,
          payer_detail,
          status,
        } = item;

        // Add headers based on the first item in the category
        csv += `IN${_id?.substring(5, 12)},$${totalAmount},${
          moment(issued_date)?.format("MM/DD/YYYY") || "-"
        },${moment(due_date)?.format("MM/DD/YYYY") || "-"},${
          payer_detail?.firstname + " " + payer_detail?.lastname
        },${status},${"Credit Card"}\n`;
      });
    }

    if (tableData?.data?.discount?.length > 0) {
      csv += `\n Discount\nDiscount Name,Total Discount Amount,Number Of Discount\n`;

      tableData?.data?.discount?.forEach((tabData) => {
        const { discount_name, invoices } = tabData;
        const totalAmount = invoices?.reduce(
          (total, item) => total + (item?.discount_amount || 0),
          0
        );
        // Add headers based on the first item in the category

        csv += `${titleCaseString(discount_name)},  ${
          totalAmount && USDollar.format(totalAmount)
        },${invoices?.length}\n`;
      });
    }
    if (tableData?.data?.generatedEstimates?.length > 0) {
      csv += `\n\n ${"Estimates Generated"}\nEstimates ID,Amount,Processed On,Payer\n`;
      tableData?.data?.generatedEstimates?.forEach((item) => {
        const { _id, totalAmount, createdAt, payer_detail } = item;

        // Add headers based on the first item in the category
        csv += `IN${_id?.substring(5, 12)}, $${totalAmount}, ${moment(
          createdAt
        )?.format("MM/DD/YYYY")},${
          payer_detail?.firstname + " " + payer_detail?.lastname
        },\n`;
      });
    }
    return csv;
  };

  return (
    <div id="invoiceAndEstimates-report-conatiner" className="p-1 mt-1">
      <div className="row">
        <div className="col-md-3">
          <div className="">
            <div className="d-flex">
              <div
                className="d-flex gap-2 align-items-center cmb-20 pointer"
                onClick={() => history.push("/reports")}
              >
                <BsChevronLeft color="color-3EFD" />
                <span className="text-13-600 color-3EFD">VIEW ALL REPORTS</span>
              </div>
            </div>

            <div className="bg-filter-class">
              <div className="text-24-700 color-2E65">Configure Report</div>
              <div className="text-14-400 color-8693">
                Select filter and generate report
              </div>
              <div className="filter-block cmt-20 cmb-20">
                <div className="cmb-10">
                  <DateRange
                    placeholder="Please Select Date Range" 
                    id="boarding"
                    name="boarding"
                    startDateProp={startDate}
                    endDateProp={endDate}
                    setStartDateProp={setStartDate}
                    setEndDateProp={setEndDate}
                    onChange={(sDate, eDate) => {
                      setFilterObj((prev) => ({
                        ...prev,
                        start_date: sDate
                          ? moment(sDate).format("YYYY-MM-DD")
                          : "",
                        end_date: eDate
                          ? moment(eDate).format("YYYY-MM-DD")
                          : "",
                      }));
                    }}
                    isClearable
                  />
                </div>
              </div>
              <div className="cmb-15">
                {/* <Button className="g-report">Generate Report</Button> */}
                <div
                  className="text-center color-2E65 text-13-600 cmt-20 pointer"
                  onClick={() => {
                    setFilterObj({
                      start_date: "",
                      end_date: "",
                    });
                    setStartDate(null);
                    setEndDate(null);
                  }}
                >
                  RESET FILTER
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-9 details-section">
          <div className="cpt-20 cpe-20 cpb-20">
            <div className="d-flex justify-content-between align-items-center cmb-20">
              <div>
                <div className="text-24-700 color-2E65">
                  Invoice And Estimates Report
                </div>
                {/* <p className="text-14-400 color-8693 text-wrap">
                  Invoice And Estimates Report help track the clinic’s medical
                  supplies, medications, and equipment to ensure that everything
                  is well-stocked and up to date.
                </p> */}
              </div>
              <div className="d-flex gap-2 br-8">
                <div
                  className="cp-8 bg-8C9F28 pointer"
                  onClick={() => {
                    handleDownload();
                  }}
                >
                  <img src={Icons.download} alt="download" />
                </div>
                <div className="cp-8 bg-8C9F28 br-8">
                  <img src={Icons.share} alt="share" />
                </div>
              </div>
            </div>

            <div className="table-one cmb-20">
              <div className="text-14-600 color-2E65 cmb-10">
                Invoices Paid ({tableData?.data?.paidInvoices?.length})
              </div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Invoice ID</th>
                    <th>Amount</th>
                    <th>Issued on</th>
                    <th>Date Due</th>
                    <th>Payer</th>
                    <th>Status</th>
                    <th>Payment Method</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.loading && (
                    <tr className="no-data-rw">
                      <td
                        colSpan="8"
                        className="d-flex justify-content-center cpt-50 cpb-50"
                      >
                        <LoaderUI />
                      </td>
                    </tr>
                  )}
                  {!tableData?.data?.loading &&
                  paidInvoicesTable?.length > 0 ? (
                    paidInvoicesTable?.map((dataElem, dataIndex) => {
                      const {
                        _id,
                        totalAmount,
                        issued_date,
                        due_date,
                        payer_detail,
                        status,
                      } = dataElem;

                      return (
                        <tr key={dataIndex}>
                          <td className="text-nowrap">
                            IN{_id?.substring(5, 12)}
                          </td>
                          <td className="text-nowrap">
                            $
                            {totalAmount?.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                          <td className="px-2 py-2 text-[#282E65]">
                            {moment(issued_date)?.format("MM/DD/YYYY")}
                          </td>

                          <td className="px-2 py-2 text-[#282E65]">
                            {moment(due_date)?.format("MM/DD/YYYY")}
                          </td>
                          <td className="px-2 py-2 flex flex-wrap items-center gap-1 text-[#282E65]">
                            <img
                              className="w-[35px] h-[35px] rounded-full"
                              src={payer_detail?.user_image}
                              alt="user"
                            />
                            <span className="text-[14px]">
                              {payer_detail?.firstname +
                                " " +
                                payer_detail?.lastname}
                            </span>
                          </td>
                          <td className="px-2 py-2 text-[#282E65]">{status}</td>
                          <td className="px-2 py-2 text-[#282E65]">
                            Credit Card
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="no-data-rw">
                      <td colSpan="11">
                        <NodataFoundBlock />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {tableData?.data?.paidInvoices?.length > 0 && (
                <PaginationBlock
                  limit={limit}
                  page={paidPage}
                  pagingCounter={paidPaging || 1}
                  docLength={limit || 0}
                  docPage={paidPage || 1}
                  totalPages={paidInvoicesPages || 1}
                  totalDocs={tableData?.data?.paidInvoices?.length || 0}
                  _handleLimit={(val) => {
                    setLimit(val);
                  }}
                  _handlePageChange={(e) => {
                    if (e > paidPage) {
                      const diff = e - paidPage;

                      setPaidPaging((prev) => prev + limit * diff);
                    } else if (e < paidPage) {
                      const diff = paidPage - e;
                      setPaidPaging((prev) => Math.max(prev - limit * diff, 0));
                    }
                    setPaidPage(e);
                  }}
                />
              )}
            </div>

            <div className="table-one cmb-20">
              <div className="text-14-600 color-2E65 cmb-10">
                Invoices Generated ({tableData?.data?.generatedInvoices?.length}
                )
              </div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Invoice ID</th>
                    <th>Amount</th>
                    <th>Issued on</th>
                    <th>Date Due</th>
                    <th>Payer</th>
                    <th>Status</th>
                    <th>Payment Method</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.loading && (
                    <tr className="no-data-rw">
                      <td
                        colSpan="8"
                        className="d-flex justify-content-center cpt-50 cpb-50"
                      >
                        <LoaderUI />
                      </td>
                    </tr>
                  )}
                  {!tableData?.data?.loading &&
                  generatedInvoicesTable?.length > 0 ? (
                    generatedInvoicesTable?.map((dataElem, dataIndex) => {
                      const {
                        _id,
                        totalAmount,
                        issued_date,
                        due_date,
                        payer_detail,
                        status,
                      } = dataElem;

                      return (
                        <tr key={dataIndex}>
                          <td className="text-nowrap">
                            IN{_id?.substring(5, 12)}
                          </td>
                          <td className="text-nowrap">
                            $
                            {totalAmount?.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                          <td className="px-2 py-2 text-[#282E65]">
                            {moment(issued_date)?.format("MM/DD/YYYY")}
                          </td>

                          <td className="px-2 py-2 text-[#282E65]">
                            {moment(due_date)?.format("MM/DD/YYYY")}
                          </td>
                          <td className="px-2 py-2 flex flex-wrap items-center gap-1 text-[#282E65]">
                            <img
                              className="w-[35px] h-[35px] rounded-full"
                              src={payer_detail?.user_image}
                              alt="user"
                            />
                            <span className="text-[14px]">
                              {payer_detail?.firstname +
                                " " +
                                payer_detail?.lastname}
                            </span>
                          </td>
                          <td className="px-2 py-2 text-[#282E65]">{status}</td>
                          <td className="px-2 py-2 text-[#282E65]">
                            Credit Card
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="no-data-rw">
                      <td colSpan="11">
                        <NodataFoundBlock />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {tableData?.data?.generatedInvoices?.length > 0 && (
                <PaginationBlock
                  limit={limit}
                  page={genetatedPage}
                  pagingCounter={genetatedPaging || 1}
                  docLength={limit || 0}
                  docPage={genetatedPage || 1}
                  totalPages={generatedInvoicesPages || 1}
                  totalDocs={tableData?.data?.generatedInvoices?.length || 0}
                  _handleLimit={(val) => {
                    setLimit(val);
                  }}
                  _handlePageChange={(e) => {
                    if (e > genetatedPage) {
                      const diff = e - genetatedPage;

                      setGeneratedPaging((prev) => prev + limit * diff);
                    } else if (e < genetatedPage) {
                      const diff = genetatedPage - e;
                      setGeneratedPaging((prev) =>
                        Math.max(prev - limit * diff, 0)
                      );
                    }
                    setGeneratedPage(e);
                  }}
                />
              )}
            </div>
            <div className="table-one cmb-20">
              <div className="text-14-600 color-2E65 cmb-10">
                Fully Paid Invoices (
                {tableData?.data?.fullyPaidInvoices?.[0]?.invoices?.length})
                (Total Revenue: {tableData?.data?.fullyPaidInvoices?.[0]?.totalRevenue &&
                  USDollar.format(
                    tableData?.data?.fullyPaidInvoices?.[0]?.totalRevenue
                  )}
                )
              </div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Invoice ID</th>
                    <th>Amount</th>
                    <th>Issued on</th>
                    <th>Date Due</th>
                    <th>Payer</th>
                    <th>Status</th>
                    <th>Payment Method</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.loading && (
                    <tr className="no-data-rw">
                      <td
                        colSpan="8"
                        className="d-flex justify-content-center cpt-50 cpb-50"
                      >
                        <LoaderUI />
                      </td>
                    </tr>
                  )}
                  {!tableData?.data?.loading &&
                  fullyPaidInvoiceTable?.length > 0 ? (
                    fullyPaidInvoiceTable?.map((dataElem, dataIndex) => {
                      const {
                        _id,
                        totalAmount,
                        issued_date,
                        due_date,
                        payer_detail,
                        status,
                      } = dataElem;

                      return (
                        <tr key={dataIndex}>
                          <td className="text-nowrap">
                            IN{_id?.substring(5, 12)}
                          </td>
                          <td className="text-nowrap">
                            $
                            {totalAmount?.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                          <td className="px-2 py-2 text-[#282E65]">
                            {moment(issued_date)?.format("MM/DD/YYYY")}
                          </td>

                          <td className="px-2 py-2 text-[#282E65]">
                            {moment(due_date)?.format("MM/DD/YYYY")}
                          </td>
                          <td className="px-2 py-2 flex flex-wrap items-center gap-1 text-[#282E65]">
                            <img
                              className="w-[35px] h-[35px] rounded-full"
                              src={payer_detail?.user_image}
                              alt="user"
                            />
                            <span className="text-[14px]">
                              {payer_detail?.firstname +
                                " " +
                                payer_detail?.lastname}
                            </span>
                          </td>
                          <td className="px-2 py-2 text-[#282E65]">{status}</td>
                          <td className="px-2 py-2 text-[#282E65]">
                            Credit Card
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="no-data-rw">
                      <td colSpan="11">
                        <NodataFoundBlock />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {tableData?.data?.fullyPaidInvoices?.[0]?.invoices?.length >
                0 && (
                <PaginationBlock
                  limit={limit}
                  page={fullyPaidPage}
                  pagingCounter={fullyPaidPaging || 1}
                  docLength={limit || 0}
                  docPage={fullyPaidPage || 1}
                  totalPages={fullyPaidInvoicePages || 1}
                  totalDocs={
                    tableData?.data?.fullyPaidInvoices?.[0]?.invoices?.length ||
                    0
                  }
                  _handleLimit={(val) => {
                    setLimit(val);
                  }}
                  _handlePageChange={(e) => {
                    if (e > fullyPaidPage) {
                      const diff = e - fullyPaidPage;

                      setFullyPaidPaging((prev) => prev + limit * diff);
                    } else if (e < fullyPaidPage) {
                      const diff = fullyPaidPage - e;
                      setFullyPaidPaging((prev) =>
                        Math.max(prev - limit * diff, 0)
                      );
                    }
                    setFullyPaidPage(e);
                  }}
                />
              )}
            </div>

            <div className="table-one cmb-20">
              <div className="text-14-600 color-2E65 cmb-10">
                Discount {`(${discountTable?.length})`}
              </div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Discount Name</th>
                    <th>Total Discount Amount</th>
                    <th>Number Of Uses</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.loading && (
                    <tr className="no-data-rw">
                      <td
                        colSpan="8"
                        className="d-flex justify-content-center cpt-50 cpb-50"
                      >
                        <LoaderUI />
                      </td>
                    </tr>
                  )}
                  {!tableData?.data?.loading &&
                  discountTableData?.length > 0 ? (
                    discountTableData?.map((dataElem, dataIndex) => {
                      const { discount_name, invoices } = dataElem;

                      const totalAmount = invoices?.reduce(
                        (total, item) => total + (item?.discount_amount || 0),
                        0
                      );

                      return (
                        <tr key={dataIndex}>
                          <td className="text-nowrap">
                            {titleCaseString(discount_name)}
                          </td>
                          <td className="text-nowrap">
                            {totalAmount && USDollar.format(totalAmount)}
                          </td>
                          <td className="px-2 py-2 text-[#282E65]">
                            {invoices?.length}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="no-data-rw">
                      <td colSpan="11">
                        <NodataFoundBlock />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {tableData?.data?.discount?.length > 0 && (
                <PaginationBlock
                  limit={limit}
                  page={discountPage}
                  pagingCounter={discountPaging || 1}
                  docLength={limit || 0}
                  docPage={discountPage || 1}
                  totalPages={discountTotalPage || 1}
                  totalDocs={tableData?.data?.discount?.length || 0}
                  _handleLimit={(val) => {
                    setLimit(val);
                  }}
                  _handlePageChange={(e) => {
                    if (e > discountPage) {
                      const diff = e - discountPage;

                      setDiscountPaging((prev) => prev + limit * diff);
                    } else if (e < discountPage) {
                      const diff = discountPage - e;
                      setDiscountPaging((prev) =>
                        Math.max(prev - limit * diff, 0)
                      );
                    }
                    setDiscountPage(e);
                  }}
                />
              )}
            </div>

            <div className="table-one cmb-20">
              <div className="text-14-600 color-2E65 cmb-10">
                Estimates Generated{" "}
                {`(${tableData?.data?.generatedEstimates?.length})`}
              </div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Estimates ID</th>
                    <th>Amount</th>
                    <th>Processed On</th>
                    <th>Payer</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.loading && (
                    <tr className="no-data-rw">
                      <td
                        colSpan="8"
                        className="d-flex justify-content-center cpt-50 cpb-50"
                      >
                        <LoaderUI />
                      </td>
                    </tr>
                  )}
                  {!tableData?.data?.loading && estGenerateTable?.length > 0 ? (
                    estGenerateTable?.map((dataElem, dataIndex) => {
                      const {
                        _id,
                        totalAmount,
                        createdAt,

                        payer_detail,
                      } = dataElem;

                      return (
                        <tr key={dataIndex}>
                          <td className="text-nowrap">
                            IN{_id?.substring(5, 12)}
                          </td>
                          <td className="text-nowrap">
                            $
                            {totalAmount?.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                          <td className="px-2 py-2 text-[#282E65]">
                            {moment(createdAt)?.format("MM/DD/YYYY")}
                          </td>

                          <td className="px-2 py-2 flex flex-wrap items-center gap-1 text-[#282E65]">
                            <img
                              className="w-[35px] h-[35px] rounded-full"
                              src={payer_detail?.user_image}
                              alt="user"
                            />
                            <span className="text-[14px]">
                              {payer_detail?.firstname +
                                " " +
                                payer_detail?.lastname}
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="no-data-rw">
                      <td colSpan="11">
                        <NodataFoundBlock />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {tableData?.data?.generatedEstimates?.length > 0 && (
                <PaginationBlock
                  limit={limit}
                  page={estGeneratePage}
                  pagingCounter={estGeneratePaging || 1}
                  docLength={limit || 0}
                  docPage={estGeneratePage || 1}
                  totalPages={estGenerateTotalPage || 1}
                  totalDocs={tableData?.data?.generatedEstimates?.length || 0}
                  _handleLimit={(val) => {
                    setLimit(val);
                  }}
                  _handlePageChange={(e) => {
                    if (e > estGeneratePage) {
                      const diff = e - estGeneratePage;

                      setEstGeneratePaging((prev) => prev + limit * diff);
                    } else if (e < estGeneratePage) {
                      const diff = estGeneratePage - e;
                      setEstGeneratePaging((prev) =>
                        Math.max(prev - limit * diff, 0)
                      );
                    }
                    setEstGeneratePage(e);
                  }}
                />
              )}
            </div>

            {/* {discountTable?.map((tabData, tabIndex) => {
              const totalItems = tabData?.invoices?.length;
              const totalPages = Math?.ceil(totalItems / limit);
              const paginatedData = getPaginatedData(
                tabData?.invoices,
                tabIndex
              );
              return (
                <div className="table-one cmb-20" key={tabIndex}>
                  <div className="text-14-500 color-2E65 cmb-10">{`Discount: ${titleCaseString(
                    tabData?.discount_name
                  )} (${tabData?.invoices?.length})`}</div>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Discount Name</th>
                        <th>Discount Amount</th>
                        <th>Discount Percantage</th>
                        <th>Discount Type</th>
                        <th>Due Date</th>
                        <th>Issued Date</th>
                        <th>Payer</th>
                        <th>Status</th>
                        <th>Total Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData?.loading && (
                        <tr className="no-data-rw">
                          <td
                            colSpan="8"
                            className="d-flex justify-content-center cpt-50 cpb-50"
                          >
                            <LoaderUI />
                          </td>
                        </tr>
                      )}

                      {!tableData?.loading &&
                      tabData?.invoices &&
                      paginatedData?.length > 0 ? (
                        paginatedData?.map((dataElem, dataIndex) => {
                          const {
                            discount_name,
                            discount_amount,
                            discount_percentage,
                            discount_type,
                            due_date,
                            issued_date,
                            payer_detail,
                            status,
                            totalAmount,
                          } = dataElem;
                          return (
                            <tr key={dataIndex}>
                              <td className="text-nowrap">
                                {titleCaseString(discount_name)}
                              </td>
                              <td className="text-nowrap">
                                ${discount_amount}
                              </td>
                              <td className="px-2 py-2 text-[#282E65]">
                                {discount_percentage}%
                              </td>

                              <td className="px-2 py-2 text-[#282E65]">
                                {titleCaseString(discount_type)}
                              </td>
                              <td className="text-nowrap">
                                {moment(due_date).format("DD-MM-YYYY")}
                              </td>
                              <td className="text-nowrap">
                                {moment(issued_date).format("DD-MM-YYYY")}
                              </td>
                              <td className="px-2 py-2 flex items-center flex-wrap gap-1 text-[#282E65]">
                                <img
                                  className="w-[35px] h-[35px] rounded-full"
                                  src={payer_detail?.user_image}
                                  alt="user"
                                />
                                <span className="text-[14px]">
                                  {payer_detail?.firstname +
                                    " " +
                                    payer_detail?.lastname}
                                </span>
                              </td>
                              <td className="text-nowrap px-2 py-2 text-[#282E65]">
                                {status}
                              </td>

                              <td className="text-nowrap">${totalAmount}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="no-data-rw">
                          <td colSpan="11">
                            <NodataFoundBlock />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <PaginationBlock
                    limit={limit}
                    page={discountPage[tabIndex]}
                    pagingCounter={discountPaging[tabIndex] || 1}
                    docLength={limit || 0}
                    docPage={discountPage[tabIndex] || 1}
                    totalPages={totalPages || 1}
                    totalDocs={tabData?.invoices?.length || 0}
                    _handleLimit={(val) => {
                      setLimit(val);
                    }}
                    _handlePageChange={(e) => {
                      if (e > discountPage[tabIndex]) {
                        const diff = e - discountPage[tabIndex];
                        setDiscountPaging((prev) => {
                          const updatedPaging = [...prev];
                          updatedPaging[tabIndex] =
                            updatedPaging[tabIndex] + limit * diff;
                          return updatedPaging;
                        });
                      } else if (e < discountPage[tabIndex]) {
                        const diff = discountPage[tabIndex] - e;

                        setDiscountPaging((prev) => {
                          const updatedPaging = [...prev];
                          updatedPaging[tabIndex] = Math.max(
                            updatedPaging[tabIndex] - limit * diff,
                            0
                          );
                          return updatedPaging;
                        });
                      }
                      setDiscountPage((prevPages) => {
                        const updatedPages = prevPages?.map((page, index) =>
                          index === tabIndex ? e : page
                        );
                        return updatedPages;
                      });
                    }}
                  />
                </div>
              );
            })} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceAndEstimatesReport;
