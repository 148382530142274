import React , { useState} from "react";
import { useSelector } from "react-redux";
import { Button, Input } from "reactstrap"; // eslint-disable-line
import { petGenderValues } from "../../../utils/constants"
import { useClinicSpecies } from "hooks/useClinicSpecies";

const TableListFilterBlock = (
	{
		filterParams,
		onSearch,
		onSelectGender,
		onSelectSpecies,
		onSelectCity,
		onSelectStatus,
	}
) => {

	const clinicSpecies = useClinicSpecies()

	const { cities, species } = useSelector(state => state.appContentReducer)
	const [searchText, setSearchText] =useState('') ;

	return (
		<>
			<div className="table-list-filter-blc d-flex align-items-center">
				<div className="search-cell col-cell position-relative">
					<span className="icomoon-search d-inline-flex align-items-center justify-content-center position-absolute"></span>
					<Input type="text" placeholder="Search" value={filterParams?.searchText} onChange={onSearch} />
				</div>
				<div className="input-cell col-cell">
					<Input type="select" name="select" value={filterParams?.city} onChange={onSelectCity}>
						<option value="">All Cities</option>
						{(cities || []).map((item, i) => <option key={i} value={item?.city}>{item?.city}</option>)}
					</Input>
				</div>
				<div className="input-cell col-cell">
					<Input type="select" name="select" value={filterParams?.species_id}  onChange={onSelectSpecies}>
						<option value="">All Patients</option>
						{(species || []).map((item, i) => <option key={i} value={item?._id}>{clinicSpecies(item?._id)  || item?.species_name}</option>)}
					</Input>
				</div>
				<div className="input-cell col-cell">
					<Input type="select" name="select"  value={filterParams?.status}  onChange={onSelectStatus}>
						<option value="">All Status</option>
						<option value={true}>Active</option>
						<option value={false}>Inactive</option>
					</Input>
				</div>
				<div className="input-cell col-cell">
					<Input type="select" name="select"  value={filterParams?.gender}  onChange={onSelectGender}>
						<option value="">All Gender</option>
						{petGenderValues.map((item, i) => <option key={i} value={item.value}>{item.label}</option>)}
					</Input>
				</div>
			</div>
		</>
	);
};

export default TableListFilterBlock;
