import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAndFilterDiagnosticResults } from "../../../../../../context/actions/patient-module/diagnostic-testing";
import CalendarFilterWeekDay from "../../../../../../shared/Calendar/CalendarFilterWeekDay";
import { Button, Input } from "reactstrap";
import './index.scss';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getAllReportsByPetId } from "context/actions/patient-module/overview/report";

const FilterBlock = ({ openPanel }) => {

    const params = useParams();

    const dispatch = useDispatch();


    const [viewData, setViewData] = useState(false);
    const [viewValue, setViewValue] = useState('day');

    const handleGetResults = () => {
        if (viewData) {
            let startDate, endDate
            let body = { pet_id: params.petId }
            if (viewData?.view_type === 'day') {
                startDate = (new Date(viewData?.year_value, viewData?.month_value - 1, viewData?.date_value)).toISOString()
                endDate = (new Date(viewData?.year_value, viewData?.month_value - 1, viewData?.date_value + 1)).toISOString()

            } else {
                startDate = (new Date(viewData?.year_value, viewData?.span_month - 1, viewData?.span_date)).toISOString()
                endDate = (new Date(viewData?.year_value, viewData?.month_value - 1, viewData?.date_value + 1)).toISOString()
            }

            body = { ...body, startDate: startDate, endDate: endDate }
            dispatch(getAndFilterDiagnosticResults(body, (result) => { }))
            // dispatch(getAllReportsByPetId(params.petId, {reportDate: startDate}));
        }

    }


    const handleFilterChange = (value) => {
        setViewData(value);
    }


    useEffect(() => {

        handleGetResults();
    }, [viewData])


    return (
        <>
            <div className="diagnostic-filter-block d-flex align-items-center">

                <CalendarFilterWeekDay sendCalendarView={(value) => {
                    setViewValue(value);
                }} calendarViewValue={(value) => {

                    handleFilterChange(value)
                }} />
                {/* <Button onClick={openPanel} color="link" className="ms-auto add-btn d-inline-flex">
                    <span className="material-icons">
                        add_circle
                    </span>
                </Button> */}



            </div>
        </>
    );
};

export default FilterBlock;
