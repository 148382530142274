import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Form,
  FormGroup,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  InputGroup,
} from "reactstrap";
import { updateClinicSettings } from "../../../../../context/actions/clinicSettings";
import { useDispatch, useSelector } from "react-redux";
import { useClinicSpecies } from "hooks/useClinicSpecies";
import "react-datepicker/dist/react-datepicker.css";

import "./index.css";

const EditSpeciesAges = ({
  className = "",
  closeNewPointPanel,
  species,
}) => {
  const clinicSpecies = useClinicSpecies();
  const [young, setYoung] = useState(null);
  const [elderly, setElderly] = useState(null);
  const dispatch = useDispatch();
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );

  useEffect(() => {
    // setDuration(appointmentColor);
    if (clinic_settings && clinic_settings?.species_ages) {
      setYoung(
        clinic_settings.species_ages[species?._id]?.young
      );
      setElderly(
        clinic_settings.species_ages[species?._id]?.elderly
      );
    }
  }, [className, clinic_settings]);

  const handleUpdate = () => {
    let update = {
      [species?._id]: {young: young, elderly: elderly},
      clinic_id: clinic_settings.clinic_id,
      update_query: "species_ages",
    };

    dispatch(updateClinicSettings(update, () => {}));
    closeNewPointPanel();
  };

  return (
    <>
      <div
        className={`ocular-new-point-panel-wrapper position-fixed d-flex flex-column overflow-auto ${className}`}
      >
        <div className="head d-flex align-items-center">
          <h2>{clinicSpecies(species?._id) || species?.species_name} Age Preferences</h2>
          <Button
            color="link"
            className="ms-auto panel-close-btn"
            onClick={() => {
              closeNewPointPanel();
              setYoung(null);
              setElderly(null)
            }}
          >
            <span className="material-symbols-outlined">close</span>
          </Button>
        </div>

        <div className="inner align-items-center mt-2">
          <Form>
            <h2 style={{marginBottom: '5px'}}>Young</h2>
            <FormGroup className="input-wrapper2 mb-2">
              <InputGroup>
                <div className="form-floating position-relative text-input">
                  <input
                    type="number"
                    className="form-control"
                    id="young-number"
                    name="duration"
                    placeholder="Number"
                    value={young?.value}
                    onChange={(e) => setYoung({...young, value: e.target.value})}
                  />
                  <label htmlFor="young-number">Value</label>
                </div>

                <div className="form-floating position-relative text-input">
                  <select
                    className="form-control"
                    id="young-unit"
                    name="unit"
                    placeholder="unit"
                    value={young?.unit}
                    onChange={(e) => setYoung({...young, unit: e.target.value})}
                  >
                    <option>Months</option> 
                    <option>Years</option> 
                  </select>
                  <label htmlFor="young-number">Unit</label>
                </div>
              </InputGroup>
            </FormGroup>

            <FormGroup className="input-wrapper2 mb-2">
            <h2 style={{marginBottom: '5px'}}>Elderly</h2>

              <InputGroup>
                <div className="form-floating position-relative text-input">
                  <input
                    type="number"
                    className="form-control"
                    id="elderly-number"
                    name="duration"
                    placeholder="Number"
                    value={elderly?.value}
                    onChange={(e) => setElderly({...elderly, value: e.target.value})}
                  />
                  <label htmlFor="elderly-number">Value</label>
                </div>

                <div className="form-floating position-relative text-input">
                  <select
                    className="form-control"
                    id="elderly-unit"
                    name="unit"
                    placeholder="unit"
                    value={elderly?.unit}
                    onChange={(e) => setElderly({...elderly, unit: e.target.value})}
                  >
                    <option>Months</option> 
                    <option>Years</option> 

                  </select>
                  <label htmlFor="elderly-number">Unit</label>
                </div>
              </InputGroup>
            </FormGroup>

            <div className="btn-blc mt-3 text-end">
              
              <Button
                color="success"
                onClick={() => {
                  handleUpdate();
                }}
                className="add-btn"
              >
                UPDATE
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default EditSpeciesAges;
