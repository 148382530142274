import { toast } from "react-toastify"
import ApiClient from "../../api-client"
import { apiUrl, PORT } from "../../environment"
import { toasterConfig } from "../../utils/constants";


// export const getReminders=(user,callback)=>{
//     debugger
//     return (dispatch,state)=>{
//         debugger

//         ApiClient.get(`${apiUrl}${PORT}/task/get-current/${user}`)
//         .then((res)=>{
//             debugger
//             console.log("rs",res)
//         }).then((data)=>{
//             debugger
//             console.log("data",data)
//         })
        
//     }
// }

export const addTask = (data, callback) => {
  //
  return (dispatch, getState) => {
    dispatch({ type: "REMINDERS_LOADING", payload: true });
    const {
      user: { token },
    } = getState();
    ApiClient.post(
      `${apiUrl}${PORT}/task/add`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      dispatch({ type: "REMINDERS_LOADING", payload: false });
      if (!response) {
        //
        return callback();
      } else if (!response && response.errors) {
        //
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response) {
        //
        toast.dismiss();
        toast.success(response.message, toasterConfig);
        dispatch(getReminders(data?.user, () => {}));
        return callback(response);
      } else {
        //
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};


export const updateTask = (data, callback) => {
  //
  return (dispatch, getState) => {
    dispatch({ type: "REMINDERS_LOADING", payload: true });
    const {
      user: { token, user_details},
    } = getState();
    ApiClient.put(
      `${apiUrl}${PORT}/task/update`,
      data,
      token,
      dispatch, getState
    ).then((response) => {
      dispatch({ type: "REMINDERS_LOADING", payload: false });
      if (!response) {
        //
        return callback();
      } else if (!response && response.errors) {
        //
        toast.error(response.errors.msg, toasterConfig);
        return callback(response);
      } else if (response) {
        //
        toast.dismiss();
        toast.success(response.message, toasterConfig);
        dispatch(getReminders(user_details?._id, () => {}));
        return callback(response);
      } else {
        //
        //toast.error(response.message, toasterConfig);
        return callback();
      }
    });
  };
};

export const getReminders = (user, callback) => {
    return (dispatch, getState) => {
    //   
      const {
        user: { token },
      } = getState();
      dispatch({type:  "REMINDERS_LOADING", payload: true})
      ApiClient.get(
        `${apiUrl}${PORT}/task/get-current/${user}`, {},
        token,
        dispatch, getState
      ).then((response) => {
        dispatch({type:  "REMINDERS_LOADING", payload: false})
      
        if (!response) {
          return callback();
        } else if (response) {
          dispatch({
            type: "GET_REMINDERS",
            payload: response?.response_data,
          });
          
          return callback(response);
        } else {
          toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    };
  };