import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";


/**
 * iFrame viewer for lab results inside of external immersion views
 * @returns {JSX.Element} Component template
 */

const LabResultViewer = () => {

  const dispatch = useDispatch();
  
  const labResultsViewerData = useSelector((state) => state.user.labResultsViewerData);

  /**
   * Function to handle closing modal
   */

  const handleClose = () => {

    dispatch({
      type: "SET_LAB_RESULTS_VIEWER_DATA", data: null
    });
  }

  useEffect(() => {
    console.log(labResultsViewerData, 'lab data')
  }, [labResultsViewerData])





  return (
    <>
  
      <Helmet>
          <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
          <script src="https://code.jquery.com/ui/1.12.1/jquery-ui.js"></script>
      </Helmet>




      {labResultsViewerData && (
        <Modal
          onHide={handleClose}
          fullscreen
          centered
          show
        >



          <Modal.Header closeButton>
            <Modal.Title>{labResultsViewerData?.title  || ""}</Modal.Title>
          </Modal.Header>

          {labResultsViewerData?.url?.includes("http") ? (
            <>
            <div style={{height: '100%'}} id="pims-immersion-iframe-container">
              <iframe id="pims-immersion-iframe-app" src={labResultsViewerData?.url} title="PIMS Immersion" />
            </div>
            <div id="pims-immersion-footer-bar" class="pims-immersion-footer-bar" style={{display: "none"}}>
            <p style={{color: 'white', padding: '8px', marginRight: '25px'}}>Test Orders & Results</p>
            <span id="pims-immersion-footer-bar-max-icon" class="pims-immersion-footer-bar-icon" title="maximize">
                <img src="./marga-assets/icons/Expand_Static.svg" />
            </span>
            <span id="pims-immersion-footer-bar-close-icon" class="pims-immersion-footer-bar-icon" title="close">
                <img src="./marga-assets/icons/Close_Static.svg" />
            </span>
            </div>
            </>
          ) : (
            <div className="text-22-500 color-2E65 f-center h-100">
              Invalid URL found.
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default LabResultViewer;
