import { Formik } from "formik";
import * as Yup from "yup";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Input,
  Col,
  FormGroup,
  Spinner,
} from "reactstrap";
import {
  addClinicStaff,
  editClinicStaff,
} from "../../../../../context/actions/clinic";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { petGenderValues } from "../../../../../utils/constants";
import PhoneNumberInput from "../../../../../shared/FormsInput/PhoneInput"

const NewUserModal = ({ editData, isStaffForm, handelSuccess, onHide }) => {
  const userDetail = useSelector((state) => state.user.user_details);
  const { clinicId } = useParams();
  const dispatch = useDispatch();
  const formRef = useRef();
  const [btnLoader, setBtnLoader] = useState(false);
  const onSubmit = (values) => {
    setBtnLoader(true);
    if (editData) {
      handleEdit(values);
    } else {
      handleAdd(values);
    }
  };
  const handleAdd = async (values) => {
    const response = await dispatch(addClinicStaff(values));
    if (response?.message === "mail_sent") {
      handelSuccess();
      resetForm();
    }
    setBtnLoader(false);
  };
  const handleEdit = async (values) => {
    const response = await dispatch(
      editClinicStaff({ ...values, staffID: editData?.userId })
    );
    if (response?.status) {
      handelSuccess();
      resetForm();
    }
    setBtnLoader(false);
  };
  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm();
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required."),
    firstname: Yup.string().required("First name is required."),
    lastname: Yup.string().required("Last name is required."),
    email: Yup.string()
      .required("Email is required.")
      .email("Email must be a valid email"),
    phone: Yup.string().required("Phone number is required."),
    role: Yup.string().required("role is required."),
  });

  const initialValues = {
    title: editData?.title || "",
    firstname: editData?.firstname || "",
    lastname: editData?.lastname || "",
    email: editData?.email || "",
    phone: editData?.phone || "",
    role: editData?.role || "",
    clinic_id: clinicId || "",
    added_by: userDetail?.id || "",
  };
  return (
    <>
      <Modal isOpen={isStaffForm} size="lg">
        <Formik
          enableReinitialize
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(props) => {
            const { values, handleChange, errors, touched, handleSubmit, setFieldValue } = props;
            const { title, firstname, lastname, email, phone, gender, role } =
              values;
            return (
              <form>
                <ModalHeader>
                  {editData
                    ? "Edit staff user details"
                    : "Create New staff User"}
                  <Button color="link" onClick={onHide}>
                    <i className="icomoon-close-square"></i>
                  </Button>
                </ModalHeader>
                <ModalBody>
                  <Row className="w-100 d-flex flex-row">
                    <Col xs={2}>
                      <FormGroup className="input-wrapper-clinic input-wrapper">
                        <div className="form-floating position-relative text-input">
                          <Input
                            type="text"
                            id="title"
                            name="title"
                            value={title}
                            onChange={handleChange}
                            placeholder="Add title"
                            invalid={errors?.title ? true : false}
                          />
                          <label htmlFor="title">Title</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs={5}>
                      <FormGroup className="input-wrapper-clinic input-wrapper">
                        <div className="form-floating position-relative text-input">
                          <Input
                            type="text"
                            id="firstname"
                            name="firstname"
                            value={firstname}
                            onChange={handleChange}
                            placeholder="Add First Name"
                            invalid={errors?.firstname ? true : false}
                          />
                          <label htmlFor="firstname">First Name</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs={5}>
                      <FormGroup className="input-wrapper-clinic input-wrapper">
                        <div className="form-floating position-relative text-input">
                          <Input
                            type="text"
                            id="lastname"
                            name="lastname"
                            value={lastname}
                            onChange={handleChange}
                            placeholder="Add Last Name"
                            invalid={errors?.lastname ? true : false}
                          />
                          <label htmlFor="lastname">Last Name</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup className="input-wrapper-clinic input-wrapper">
                        <div className="form-floating position-relative text-input">
                          <Input
                            type="text"
                            id="email"
                            name="email"
                            value={email}
                            onChange={handleChange}
                            placeholder="Add Email Address"
                            invalid={errors?.email ? true : false}
                          />
                          <label htmlFor="email">Email Address</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs={6}>

                      <PhoneNumberInput
                      style={{ maxWidth: "100%", height: '100%', color: 'rgba(29, 29, 29, 0.75)', fontSize: '16px', fontWeight: '600', fontFamily: 'Inter'}}
											placeholder="Phone Number"											 
											name="phone"
											 id="phone"
											error={errors.phone}
											touched={touched.phone}									
											 value={phone}
											 onChange={(e) => {setFieldValue('phone', '+'+e);}} />
                      {/* <FormGroup className="input-wrapper-clinic input-wrapper">
                        <div className="form-floating position-relative text-input">
                          <Input
                            type="text"
                            id="phone"
                            name="phone"
                            value={phone}
                            onChange={handleChange}
                            placeholder="Add Phone Number"
                            invalid={errors?.phone ? true : false}
                          />
                          <label htmlFor="phone">Phone Number</label>
                        </div>
                      </FormGroup> */}
                    </Col>
                    {/* <Col xs={6}>
                      <FormGroup className="input-wrapper-clinic select input-wrapper flex-fill">
                        <div className="form-floating position-relative text-input">
                          <Input
                            name="gender"
                            type="select"
                            id="gender"
                            value={gender}
                            onChange={handleChange}
                            placeholder="Select Gender"
                            className="form-control no-right-border"
                            invalid={errors?.gender ? true : false}
                          >
                            <option value="">Select Gender</option>
                            {petGenderValues.map((item, i) => (
                              <option key={i} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                         
                          </Input>
                          <label htmlFor="gender">Gender</label>
                        </div>
                      </FormGroup>
                    </Col> */}
                    <Col xs={6}>
                      <FormGroup className="input-wrapper-clinic select input-wrapper flex-fill">
                        <div className="form-floating position-relative text-input">
                          <Input
                            name="role"
                            type="select"
                            id="role"
                            value={role}
                            onChange={handleChange}
                            placeholder="Select Gender"
                            className="form-control no-right-border"
                            invalid={errors?.role ? true : false}
                          >
                            <option value="">Select Role</option>
                            <option value="admin">Admin</option>
                            <option value="doctor">Doctor</option>
                            <option value="vet-tech">Vet Tech</option>
                            <option value="receptionist">Receptionist</option>
                          </Input>
                          <label htmlFor="role">Role</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <div className="btn-blc border_btn mt-3 text-end">
                    <Button
                      className="flex-fill btn cancle-btn"
                      onClick={onHide}
                    >
                      CANCEL
                    </Button>
                    <Button
                      color="success"
                      type="button"
                      className="btn add-btn"
                      onClick={handleSubmit}
                    >
                      {editData ? "Submit" : "Add new"}
                      {btnLoader && (
                        <Spinner
                          animation="border"
                          role="status"
                          size="sm"
                          className="ms-2"
                        />
                      )}
                    </Button>
                  </div>
                </ModalFooter>
              </form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default NewUserModal;
