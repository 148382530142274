import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";

toast.configure();
/**
 * All Api endpoints to be call
 * Get -- /audit-log/get-all/:clinic_id
*/

/**
 * 
 * @param {Object} params
 * @returns 
 */
export const getAllAuditLogs = (params, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, clinicReducer: { clinic_details } } = getState();
        if (clinic_details) {
            ApiClient.get(`${apiUrl}${PORT}/audit-log/get-all/${clinic_details?._id}`, params, token, dispatch, getState).then((response) => {
                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    dispatch({ type: "GET_AUDIT_LOGS", payload: response?.response_data })
                    return callback(response);
                } else {
                    //toast.error(response.message, toasterConfig);
                    return callback(response);
                }
            })
        }
    };
};

/**
 * 
 * @param {Object} params
 * @param {Object} body
 * @param {Function} callback
 * @returns 
 */
export const searchAuditLogs = (body, params, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, clinicReducer: { clinic_details } } = getState();
        if (clinic_details) {
            body.clinic_id = clinic_details?._id
            ApiClient.post(`${apiUrl}${PORT}/audit-log/search?page=${params?.page || 1}&limit=${params?.limit || 5}`, body, token, dispatch, getState).then((response) => {
                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    dispatch({ type: "GET_AUDIT_LOGS", payload: response?.response_data })
                    return callback(response);
                } else {
                    //toast.error(response.message, toasterConfig);
                    return callback(response);
                }
            })
        }
    };
};


