import React, { useEffect, useState } from "react";
import catrev from "assets/img/catrev.png";
import { FiDownload } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { formatPhoneNumber } from "utils/common";

export default function TotalRevenueEstimate({
  show,
  cancel,
  setCancel,
  estimateData,
}) {
  let drawerClasses = show
    ? "side-drawer open w-full md:w-[75%] lg:w-[50%]"
    : "side-drawer";

  const [subtotalValue, setSubtotalValue] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);
  const [taxValue, setTaxValue] = useState(0);
  const [totalValue, setTotalValue] = useState(0);

  useEffect(() => {
    if (estimateData) {
      let subTotalVal = 0;
      estimateData?.services?.forEach((ele) => {
        subTotalVal += parseFloat(ele.amount);
      });
      estimateData?.orders?.forEach((ele) => {
        subTotalVal += parseFloat(ele.amount);
      });
      estimateData?.lineItems?.forEach((ele) => {
        subTotalVal += parseFloat(ele.price);
      });
      setSubtotalValue(subTotalVal);

      let discountTotalVal = 0;
      estimateData?.discounts.forEach((ele) => {
        if (ele.discount_name != "") {
          if (ele.discount_type == "amount") {
            let discountValInt = +ele.discount_percentage;
            discountTotalVal += discountValInt;
          } else if (ele.discount_type == "percentage") {
            let totalInt = +subTotalVal;
            let discountValInt = +ele.discount_percentage;
            let percentageVal = (totalInt * discountValInt) / 100;
            discountTotalVal += percentageVal;
          }
        }
      });

      setDiscountValue(discountTotalVal);

      let taxVal = 0;
      estimateData?.taxes.forEach((ele) => {
        if (ele.discount_name != "") {
          let totalInt = +subTotalVal;
          let taxValInt = +ele.tax_percentage;
          let percentageVal = (totalInt * taxValInt) / 100;
          taxVal += percentageVal;
        }
      });
      setTaxValue(taxVal);

      let finalVal = subTotalVal + taxVal - discountTotalVal;
      setTotalValue(finalVal.toFixed(2));
    }
  }, [estimateData]);

  if (estimateData) {
    return (
      <div className={show ? drawerClasses : "p-4 w-[60%]"}>
        <div className="mb-3">
          <p className="flex justify-between">
            <span className="font-[700] text-['Inter']">
              Estimate ID: ES{estimateData?._id.substring(5, 12)}
            </span>
            <span
              className="cursor-pointer icomoon-cancel"
              onClick={() => {
                setCancel(!cancel);
              }}
            >
              <MdClose size={25} />
            </span>
          </p>
        </div>
        <div className="w-full rounded-lg p-4 flex flex-col md:flex-row gap-y-3 justify-between bg-[#282E65] text-[#fff]">
          <div className="flex items-center gap-x-2">
            <img src={catrev} className="w-10 h-10 object-cover" />
            <div className="">
              <p className="font-[600] text-[18px] font-['Inter'] w-[200px]">
                {estimateData?.clinic_details[0]?.clinic_name}
              </p>
              <p className="font-['Inter']  text-[14px]">
                {estimateData?.clinic_details[0]?.email}
              </p>
            </div>
          </div>
          <div>
            <p>
              <span className="text-[14px] font-['Inter'] block">
                {estimateData?.clinic_details[0]?.address
                  ? estimateData?.clinic_details[0]?.address + ","
                  : ""}
              </span>
              <span className="text-[14px] font-['Inter'] block">
                {estimateData?.clinic_details[0]?.city}{" "}
                {estimateData?.clinic_details[0]?.city &&
                  estimateData?.clinic_details[0]?.state &&
                  ","}{" "}
                {estimateData?.clinic_details[0]?.state}
              </span>
              <span className="text-[14px] font-['Inter'] block">
                {estimateData?.clinic_details[0]?.country}{" "}
                {estimateData?.clinic_details[0]?.country &&
                  estimateData?.clinic_details[0]?.zipcode &&
                  ","}{" "}
                {estimateData?.clinic_details[0]?.zipcode}
              </span>
            </p>
          </div>
        </div>
        <div className="w-full rounded-lg p-4 flex flex-col gap-y-3 sm:flex-row justify-between bg-[#F8F8F8E5] text-[#282E65] mt-2">
          <div>
            <p>
              <span className="font-['Inter'] block font-[600] text-[18px]">
                Estimate Number
              </span>
              <span className="text-[14px] font-['Inter'] block">
                ES{estimateData?._id?.substring(5, 12)}
              </span>
            </p>
          </div>
          <div>
            <p>
              <span className="font-['Inter'] block font-[600] text-[18px]">
                Billed to
              </span>
              <span className="text-[14px] font-['Inter'] block">
                {estimateData?.payer_detail[0]?.firstname +
                  " " +
                  estimateData?.payer_detail[0]?.lastname}
              </span>
              <span className="text-[14px] font-['Inter'] block">
                {estimateData?.payer_detail[0]?.email}
              </span>
              <span className="text-[14px] font-['Inter'] block">
                {formatPhoneNumber(estimateData?.payer_detail[0]?.phone)}
              </span>
            </p>
          </div>
        </div>

        {estimateData?.services?.length > 0 && (
          <div className="mt-2">
            <p className="flex justify-between border-b">
              <span className="text=[#788C9F] text-[14px] font-[500] font-['Inter']">
                Service
              </span>
              <span className="text=[#788C9F] text-[14px] font-[500] font-['Inter']">
                Amount
              </span>
            </p>
            {estimateData?.services?.map((ele, index) => {
              return (
                <div>
                  <p
                    className={`flex justify-between border-b ${
                      index % 2 == 0 ? "bg-[#E4E4E447]" : ""
                    } px-[10px] py-3`}
                  >
                    <span className="text=[#282E65] text-[14px] font-[600] font-['Inter']">
                      {ele.serviceName}
                    </span>
                    <span className="text=[#282E65] text-[14px] font-[500] font-['Inter']">
                      ${ele.amount.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
                    </span>
                  </p>
                </div>
              );
            })}
          </div>
        )}

        {estimateData?.products?.length > 0 && (
          <div className="mt-2">
            <p className="flex justify-between border-b">
              <span className="text=[#788C9F] text-[14px] font-[500] font-['Inter']">
                Product
              </span>
              <span className="text=[#788C9F] text-[14px] font-[500] font-['Inter']">
                Amount
              </span>
            </p>
            {estimateData?.products?.map((ele, index) => {
              return (
                <div>
                  <p
                    className={`flex justify-between border-b ${
                      index % 2 == 0 ? "bg-[#E4E4E447]" : ""
                    } px-[10px] py-3`}
                  >
                    <span className="text=[#282E65] text-[14px] font-[600] font-['Inter']">
                      {ele.product_name}
                    </span>
                    <span className="text=[#282E65] text-[14px] font-[500] font-['Inter']">
                      ${ele?.amount?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
                    </span>
                  </p>
                </div>
              );
            })}
          </div>
        )}

        {estimateData?.lineItems?.length > 0 && (
          <div className="mt-2">
            <p className="flex justify-between border-b">
              <span className="text=[#788C9F] text-[14px] font-[500] font-['Inter']">
                Item
              </span>
              <span className="text=[#788C9F] text-[14px] font-[500] font-['Inter']">
                Amount
              </span>
            </p>
            {estimateData.lineItems.map((ele, index) => {
              return (
                <div>
                  <p
                    className={`flex justify-between border-b ${
                      index % 2 == 0 ? "bg-[#E4E4E447]" : ""
                    } px-[10px] py-3`}
                  >
                    <span className="text=[#282E65] text-[14px] font-[600] font-['Inter']">
                      {ele.line_item_name}
                    </span>
                    <span className="text=[#282E65] text-[14px] font-[500] font-['Inter']">
                      ${ele.price.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
                    </span>
                  </p>
                </div>
              );
            })}
          </div>
        )}

        <div className="w-full">
          <div className="float-right ">
            <p className="flex justify-between text-[14px] font-['Inter'] text-[#1B212D]">
              <span>Subtotal </span>{" "}
              <span className="ml-3 mr-2">${subtotalValue.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}</span>
            </p>
            <p className="flex justify-between text-[14px] font-['Inter'] text-[#1B212D]">
              <span>Disount </span>{" "}
              <span className="ml-3 mr-2">${discountValue.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}</span>
            </p>
            <p className="flex justify-between text-[14px] font-['Inter'] text-[#1B212D]">
              <span>Tax </span> <span className="ml-3 mr-2">${taxValue.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}</span>
            </p>
            <p className="flex justify-between text-[14px] font-['Inter'] font-bold text-[#1B212D]">
              <span>Total </span>{" "}
              <span className="ml-3 mr-2">${totalValue.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}</span>
            </p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={show ? drawerClasses : "p-4 w-[60%]"}>
        <div className="mb-3">
          <p className="flex justify-between">
            <span className="font-[700] text-['Inter']">
              Estimate ID: OD513212
            </span>
            <span
              className="cursor-pointer icomoon-cancel"
              onClick={() => {
                setCancel(!cancel);
              }}
            >
              <MdClose size={25} />
            </span>
          </p>
        </div>
        <div className="w-full rounded-lg p-4 flex flex-col md:flex-row gap-y-3 justify-between bg-[#282E65] text-[#fff]">
          <div className="flex items-center gap-x-2">
            <img src={catrev} className="w-10 h-10 object-cover" />
            <div className="">
              <p className="font-[600] text-[18px] font-['Inter'] w-[200px]">
                Newlife Cat Care
              </p>
              <p className="font-['Inter']  text-[14px]">sales@catcare.com</p>
            </div>
          </div>
          <div>
            <p>
              <span className="text-[14px] font-['Inter'] block">
                55 Black Fox Farm Road
              </span>
              <span className="text-[14px] font-['Inter'] block">
                Bouston, TX 88060
              </span>
              <span className="text-[14px] font-['Inter'] block">
                Foomfield Hills, Michigan(MI), 58202
              </span>
            </p>
          </div>
        </div>
        <div className="w-full rounded-lg p-4 flex flex-col gap-y-3 sm:flex-row justify-between bg-[#F8F8F8E5] text-[#282E65] mt-2">
          <div>
            <p>
              <span className="font-['Inter'] block font-[600] text-[18px]">
                Estimate Number
              </span>
              <span className="text-[14px] font-['Inter'] block">
                MAG 2541420
              </span>
              <span className="text-[14px] font-['Inter'] block">
                Issued Date: 10 aug 2022
              </span>
              <span className="text-[14px] font-['Inter'] block">
                Due Date: 20 Aug 2022
              </span>
            </p>
          </div>
          <div>
            <p>
              <span className="font-['Inter'] block font-[600] text-[18px]">
                Billed to
              </span>
              <span className="text-[14px] font-['Inter'] block">
                Timothy Rotanson
              </span>
              <span className="text-[14px] font-['Inter'] block">
                #8555 Rainy Day Drive
              </span>
              <span className="text-[14px] font-['Inter'] block">
                Michigan, IM 58202
              </span>
            </p>
          </div>
        </div>

        <div className="mt-2">
          <p className="flex justify-between border-b">
            <span className="text=[#788C9F] text-[14px] font-[500] font-['Inter']">
              Service
            </span>
            <span className="text=[#788C9F] text-[14px] font-[500] font-['Inter']">
              Ammount
            </span>
          </p>
          <div>
            <p className="flex justify-between border-b bg-[#E4E4E447] px-[10px] py-3">
              <span className="text=[#282E65] text-[14px] font-[600] font-['Inter']">
                Video Call Consultancy
              </span>
              <span className="text=[#282E65] text-[14px] font-[500] font-['Inter']">
                $200.00
              </span>
            </p>
          </div>
          <div>
            <p className="flex justify-between border-b  px-[10px] py-3">
              <span className="text-[#282E65] text-[14px] font-[600] font-['Inter']">
                Suppliment : [Product Information will goes here]
              </span>
              <span className="text=[#282E65] text-[14px] font-[500] font-['Inter']">
                $50.00
              </span>
            </p>
          </div>
        </div>
        <div className="w-full">
          <div className="float-right ">
            <p className="flex justify-between text-[14px] font-['Inter'] text-[#1B212D]">
              <span>Subtotal</span> <span>$250.00</span>
            </p>
            <p className="flex justify-between text-[14px] font-['Inter'] text-[#1B212D]">
              <span>Disount</span> <span>$10.00</span>
            </p>
            <p className="flex justify-between text-[14px] font-['Inter'] text-[#1B212D]">
              <span>Tax</span> <span>$40.00</span>
            </p>
            <p className="flex justify-between text-[14px] font-['Inter'] font-bold text-[#1B212D]">
              <span>Total</span> <span>$200.00</span>
            </p>
          </div>
        </div>

        <div className="flex gap-3 float-right position-relative top-[100px] left-[117px]">
          <button className="w-[200px] h-[40px] bg-[#F8F8F8] curosr-pointer gap-2 items-center justify-center text-[14px] text-[#3D3EFD] flex  rounded-lg border border-gray">
            <FiDownload size={25} color="#3D3EFD" /> Download
          </button>
          <button
            onClick={() => {}}
            className="w-[200px] h-[40px] bg-[#3D3EFD] curosr-pointer gap-2 items-center justify-center text-[14px] text-[#fff] flex  rounded-lg border border-[#3D3EFD] uppercase"
          >
            Send Estimate
          </button>
        </div>
      </div>
    );
  }
}
