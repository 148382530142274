import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { sidebarPanelAction } from "../../../../../context/actions/sidebarPanelAction";
import PageHeader from "../../../../../shared/PageHeader";
import CalendarFilter from "./CalendarFilter";

import CalendarMonthView from "../../../../../shared/Calendar/CalendarMonthView";

import CalendarFoot from "../../../../../shared/Calendar/CalendarFoot";

import { getAllAppointmentsOfPetOwner } from "../../../../../context/actions/appointment";
import PatientListPanel from "../../../../../shared/Calendar/PatientListPanel";

import { patientListPanelAction } from "../../../../../context/actions/patientListPanelAction";
import { setAppointmentDetailPanel } from "../../../../../context/actions/appointmentDetailPanelAction";
import AppointmentDetailsPanel from "../../../../../shared/Calendar/AppointmentDetailsPanel";
import "./index.scss";
import { getPetDetailsById } from "../../../../../context/actions/patient-module/patient/pet";
import { useHistory } from "react-router-dom";
import { CalendarWeekViewWithoutDoctors } from "../../../../../shared/Calendar/CalendarWeekView/CalendarWeekViewWithoutDoctors";
import CalendarWeekDayWithoutDoctors from "../../../../../shared/Calendar/CalendarWeekDay/CalendarWeekDayWithoutDoctors";


const CalenderAppointments = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userDetails = useSelector((state) => state.user.user_details);
    const { clinic_details } = useSelector(state => state.clinicReducer)
    const [calendarView, setCalendarView] = useState("month");
    const [calendarViewData, setCalendarViewData] = useState({
        span_date: new Date().getDate(),
        date_value: new Date().getDate(),
        month_value: new Date().getMonth(),
        span_month: new Date().getMonth(),
        year_value: new Date().getFullYear(),
    });

    const [doctorsList, setDoctorsList] = useState(false);
    const [appointments, setAppointments] = useState(false);
    const [appointmentsList, setAppointmentsList] = useState([]);

    const [selectedPet, setSelectedPet] = useState("")

    const sidebarPanelState = useSelector(
        (state) => state.sidebarPanelReducer.isSidePanel
    );
    const patientListPanelState = useSelector(
        (state) => state.patientListPanelReducer.isPatientListPanel
    );
    const appointmentDetailPanelState = useSelector(
        (state) => state.appointmentDetailPanelReducer.isAppointmentDetailPanel
    );
    const showSidePanel = () => {
        dispatch(sidebarPanelAction(!sidebarPanelState));
    };
    const { petAndOwnerDetails } = useSelector(state => state.petReducer);

    useEffect(() => {
        if (petAndOwnerDetails && userDetails && petAndOwnerDetails.pet_details) {

            let pet_ids = petAndOwnerDetails.pet_details.map((ele) => ele._id)
            dispatch(getAllAppointmentsOfPetOwner({
                patient_id: pet_ids,
                //user_id: userDetails?._id
            }, (resp) => {
                if (resp && !resp.errors) {
                    setAppointments(resp)
                    setAppointmentsList(resp)
                }
            }))

        }

    }, [petAndOwnerDetails, userDetails])

    useEffect(() => {
        if (selectedPet != "") {
            let filteredAppointments = appointments.filter((ele)=> ele.patient_id._id == selectedPet)
            setAppointmentsList(filteredAppointments)
        }
        else {
            setAppointmentsList(appointments)
        }
    }, [selectedPet])


    const openPatientPanel = () => {
        dispatch(patientListPanelAction(!patientListPanelState));
    };

    const addNewAppointmentUrl = (petId) => {
        history.push("/add-new-appointment/" + petId);
        dispatch(getPetDetailsById(petId), () => {
            dispatch(patientListPanelAction(false));
        });
    };



    // console.log("petAndOwnerDetails", petAndOwnerDetails)

    return (
        <>
            <div className="page-wrapper position-relative d-flex flex-column flex-fill overflow-auto">
                <div className="page-header-wrapper d-flex align-items-center flex-shrink-0 pb-0 right-column-visible">
                    <Button
                        className="sidebar-toggle-btn d-inline-flex align-items-center"
                        color="link"
                        onClick={showSidePanel}
                    >
                        <span className="material-symbols-outlined">menu</span>
                    </Button>
                    <div className="row-block d-flex align-items-center flex-fill">
                        <PageHeader
                            className="dashboard-head flex-flex-shrink-0"
                            title="Appointments"
                        ></PageHeader>
                        <div className="right-col ms-auto">
                            <div className="btn-blc">
                                <Button color="success" className="add-btn" onClick={openPatientPanel}>Add new</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-content no-header d-flex flex-column flex-fill overflow-auto pb-0">
                    <CalendarFilter sendCalendarView={(value) => {
                        setDoctorsList([])
                        setCalendarView(value)
                    }
                    } calendarViewValue={(value) => setCalendarViewData(value)} listOptions={petAndOwnerDetails && petAndOwnerDetails.pet_details} setSelectedPet={setSelectedPet} />
                    {/* {
                        calendarView === 'month' && doctorsList && doctorsList.length > 0 && <UserListMonth setClinicAppointment={setClinicAppointment} calendarView={calendarView} doctorsList={doctorsList} doctorAppointments={(value) => setAppointments(value)} />
                    } */}
                    <div className="calendar-wrapper d-flex flex-column flex-fill overflow-auto">
                        {
                            calendarView === 'month' ? <CalendarMonthView calendarViewData={calendarViewData} appointments={appointmentsList} /> :
                                (calendarView === 'week' ? <CalendarWeekViewWithoutDoctors calendarViewData={calendarViewData} appointments={appointmentsList} /> : <CalendarWeekDayWithoutDoctors appointments={appointmentsList} dateVal={`${('0' + calendarViewData.date_value).slice(-2)}-${('0' + calendarViewData.month_value).slice(-2)}-${calendarViewData.year_value}`} />)
                        }
                    </div>
                    <CalendarFoot />
                </div>
            </div>
            <div
                className={`patient-panel-overlay position-fixed ${patientListPanelState ? 'active' : ''}`}
                onClick={() => dispatch(patientListPanelAction(false))}></div>
            <PatientListPanel className={`${patientListPanelState ? 'active' : ''}`} clinicId={clinic_details?._id} callable={addNewAppointmentUrl} />

            <div
                className={`patient-panel-overlay position-fixed ${appointmentDetailPanelState ? "active" : ""
                    }`}
                onClick={() => dispatch(setAppointmentDetailPanel(false))}
            ></div>
            <AppointmentDetailsPanel
                className={`${appointmentDetailPanelState ? "active" : ""}`}
            />
        </>
    );
};

export default CalenderAppointments;

