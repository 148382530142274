import React, { useEffect, useState } from "react";
import CalendarFilter from "../../shared/Calendar/CalendarFilter";
import "../../shared/Calendar/CalendarFilter/index.scss";
import PageHeader from "../../shared/PageHeader";
import { useDispatch, useSelector } from "react-redux";

import { CalendarMonthViewOperatingRoom } from "../../shared/Calendar/CalendarMonthView";
import { CalendarWeekViewOperating } from "../../shared/Calendar/CalendarWeekView";

import SelectOperatingRoom from "./SelectOperatingRoom";
import { getOperatingRoomsByClinicId, getOperatingRoomsByClinicIdWithAppointments } from "../../context/actions/operatingRoomAction";
import { CalendarWeekDayForOperatingRoom } from "../../shared/Calendar/CalendarWeekDay";
import AppointmentDetailsPanel from "../../shared/Calendar/AppointmentDetailsPanel";
import { setAppointmentDetailPanel } from "../../context/actions/appointmentDetailPanelAction";

const BackDrop = (props) => {
  return <div className="backdrop" onClick={props.closeDrawer}></div>;
};

const UpdatedOperatingRoom = () => {
  const userDetails = useSelector((state) => state.user.user_details);
  const [calendarView, setCalendarView] = useState("month");
  const dispatch = useDispatch()

  const [calendarViewData, setCalendarViewData] = useState({
    span_date: new Date().getDate(),
    date_value: new Date().getDate(),
    month_value: new Date().getMonth(),
    span_month: new Date().getMonth(),
    year_value: new Date().getFullYear(),
  });

  const [show, setShow] = useState(false);

  const [surgeryData, setSurgeryData] = useState([]);
  // const [operatingRooms, setOperatingRooms] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isOperatingRoomAdded, setIsOperatingRoomAdded] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);
  const { operatingRooms } = useSelector(state => state.operatingRoomReducer);

  const [appointments, setAppointments] = useState([])

  const appointmentDetailPanelState = useSelector(
    (state) => state.appointmentDetailPanelReducer.isAppointmentDetailPanel
  );

  let drawerClasses = show ? "side-drawer open" : "side-drawer";


 const fetchData = async () => {
      const { date_value, month_value, year_value } = calendarViewData;
      const dateFormate =
        year_value +
        "-" +
        (month_value.length === 1 ? "0" + month_value : month_value) +
        "-" +
        date_value;

      try {
        let response;
        setSurgeryData(response.data);
      } catch (error) {
        console.log(error.message);
      }
    };
  useEffect(() => {
    const controller = new AbortController(); 

    fetchData();
    return () => {
      controller.abort();
    };
  }, [calendarView, calendarViewData]);

  useEffect(() => {
    console.log('opperating room added changed')
    dispatch(
      getOperatingRoomsByClinicIdWithAppointments(clinic_settings?.clinic_id, { active: true }, (res) => {
        if(res.status){
          let appointments = []
          operatingRooms?.map((e) =>{appointments = [...appointments, ...e.appointments]})
          setSelectedRoom(null)
          console.log(appointments, 'appointments');
          setSurgeryData(appointments) //need to flatten this array
          setAppointments(appointments)
        };
      })
    )
  }, [isOperatingRoomAdded]);

  useEffect(() => {
  dispatch(
    getOperatingRoomsByClinicIdWithAppointments(clinic_settings?.clinic_id, { active: true }, (res) => {
      if(res){
        let appointments = []
        operatingRooms?.map((e) =>{appointments = [...appointments, ...e.appointments]})
        setSelectedRoom(null)

        console.log(appointments, 'appointments');
        setSurgeryData(appointments) //need to flatten this array
        setAppointments(appointments)
      };
    })
  )    
}, []);

  return (
    <div className="page-wrapper position-relative d-flex flex-column flex-fill overflow-auto">
      <div className="page-header-wrapper d-flex align-items-center flex-shrink-0 pb-0 right-column-visible">
        <div className="row-block d-flex align-items-center flex-fill">

          <PageHeader
            className="dashboard-head flex-flex-shrink-0"
            title="Operating Rooms"
          ></PageHeader>
         
        </div>
      </div>
     <div className="page-content no-header d-flex flex-column flex-fill overflow-auto pb-0">
        <CalendarFilter
          sendCalendarView={(value) => {
            setCalendarView(value);
          }}
          calendarViewValue={(value) => setCalendarViewData(value)}
        />

        {calendarView === "month" && operatingRooms?.length > 0 && (
          <SelectOperatingRoom
            operatingRooms={operatingRooms}
            selectedRoom={selectedRoom}
            setSelectedRoom={setSelectedRoom}
            setAppointments={setAppointments}
          />
        )}

        <div className="calendar-wrapper d-flex flex-column flex-fill overflow-auto">
          {calendarView === "month" ? (
            <CalendarMonthViewOperatingRoom
              calendarViewData={calendarViewData}
              selectedRoom={selectedRoom}
              appointments={appointments}
            />
          ) : calendarView === "week" ? (
            <CalendarWeekViewOperating
              calendarViewData={calendarViewData}
              allRooms={operatingRooms}
              appointments={appointments}
            />
          ) : (

            <CalendarWeekDayForOperatingRoom operatingRooms={operatingRooms} dateVal={`${('0' + calendarViewData.date_value).slice(-2)}-${('0' + calendarViewData.month_value).slice(-2)}-${calendarViewData.year_value}`} />

           
          )}
        </div>
       
      </div>

      <div
        className={`patient-panel-overlay position-fixed ${appointmentDetailPanelState ? "active" : ""
          }`}
        onClick={() => dispatch(setAppointmentDetailPanel(false))}
      ></div>
      <AppointmentDetailsPanel
        className={`${appointmentDetailPanelState ? "active" : ""}`}
      />

     
    </div>
  );
};

export default UpdatedOperatingRoom;
