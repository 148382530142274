import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup"
import { Tab, Col, Row } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import TaskObject from "./TaskObject";
import LoaderUI from "../../loader";
import { addTask, getReminders, updateTask } from "../../../context/actions/taskAndReminder";
import { FormFeedback } from "reactstrap";
import Select from "react-select";
import { getAllStaffManagementUsers } from "../../../context/actions/staffManagement";
import { Formik, useFormik, Form } from "formik";
import "./index.scss"
import Spinner from "shared/Spinner";





const Tasks = () => {


    const [isOpen, setIsOpen] = useState(false);
    const userDetails = useSelector((state) => state.user.user_details);

    const [users, setUsers] = useState(false)

    const { reminders, loading } = useSelector((state) => state?.taskAndReminderReducer)
    const dispatch = useDispatch();


    const taskInitialValues = { type: '', name: '', notes: '', date: '', assigned_to: '' }

    const getData = () => {
        dispatch(
            getAllStaffManagementUsers( {}, (result) => {
                if (result && !result.errors) {
                    setUsers(result);
                } else {
                    setUsers(false);
                }
            })
        );
    };



    const validationSchema = Yup.object().shape({
        type: Yup.object().shape({
            value: Yup.string(),
            label: Yup.string()
        }).required(),
        name: Yup.string().required(),
        notes: Yup.string(),
        date: Yup.date(),
        assigned_to: Yup.object().shape({
            id: Yup.string(),
            value: Yup.string(),
            label: Yup.string()
        }).nullable()
    })

    const handleAddTask = (values, { resetForm }) => {

        Object.keys(values).forEach(key => {
            if (values[key] === '' || values[key] === null) {
                delete values[key];
            } else if (key === 'date') {
                values[key] = new Date(values[key])
            }
        });
        dispatch(addTask({ ...values, type: values?.type?.value, assigned_to: values?.assigned_to?.id, user: userDetails?._id }, () => {
            resetForm();
            setIsOpen(false);
        }))
    }

    const handleComplete = (id) => {

        dispatch(updateTask({ _id: id, completed: true }, () => {
        }))

    }


    const showRemoveIcon = true; // Set to true or false as needed

    function toggle() {
        setIsOpen((isOpen) => !isOpen);
    }

    useEffect(() => {

        dispatch(getReminders(userDetails?._id, () => { }))
        getData();
    }, [])

    return (

        <>

            {!isOpen ?
                <button className="message-collapse-form p-1 mb-2 d-flex justify-content-center w-100 mt-2" onClick={toggle}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <mask
                            id="mask0_9679_193425"
                            style={{ maskType: "alpha" }}
                            maskUnits="userSpaceOnUse"
                            x={0}
                            y={0}
                            width={24}
                            height={24}
                        >
                            <rect width={24} height={24} fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_9679_193425)">
                            <path
                                d="M12 16.75C12.2167 16.75 12.396 16.6793 12.538 16.538C12.6793 16.396 12.75 16.2167 12.75 16L12.75 12.75H16.025C16.225 12.75 16.396 12.679 16.538 12.537C16.6793 12.3957 16.75 12.2167 16.75 12C16.75 11.7833 16.6793 11.604 16.538 11.462C16.396 11.3207 16.2167 11.25 16 11.25H12.75L12.75 7.975C12.75 7.775 12.6793 7.60433 12.538 7.463C12.396 7.321 12.2167 7.25 12 7.25C11.7833 7.25 11.6043 7.321 11.463 7.463C11.321 7.60433 11.25 7.78333 11.25 8L11.25 11.25H7.975C7.775 11.25 7.60433 11.3207 7.463 11.462C7.321 11.604 7.25 11.7833 7.25 12C7.25 12.2167 7.321 12.3957 7.463 12.537C7.60433 12.679 7.78334 12.75 8 12.75H11.25L11.25 16.025C11.25 16.225 11.321 16.396 11.463 16.538C11.6043 16.6793 11.7833 16.75 12 16.75ZM12 21.5C10.6833 21.5 9.446 21.25 8.288 20.75C7.12934 20.25 6.125 19.575 5.275 18.725C4.425 17.875 3.75 16.8707 3.25 15.712C2.75 14.554 2.5 13.3167 2.5 12C2.5 10.6833 2.75 9.44567 3.25 8.287C3.75 7.129 4.425 6.125 5.275 5.275C6.125 4.425 7.12933 3.75 8.288 3.25C9.446 2.75 10.6833 2.5 12 2.5C13.3167 2.5 14.5543 2.75 15.713 3.25C16.871 3.75 17.875 4.425 18.725 5.275C19.575 6.125 20.25 7.129 20.75 8.287C21.25 9.44567 21.5 10.6833 21.5 12C21.5 13.3167 21.25 14.554 20.75 15.712C20.25 16.8707 19.575 17.875 18.725 18.725C17.875 19.575 16.871 20.25 15.713 20.75C14.5543 21.25 13.3167 21.5 12 21.5Z"
                                fill="#11CA9D"
                            />
                        </g>
                    </svg>


                </button>
                :

                <div className="collapse-content-wrapper mb-3">
                    <Formik
                        enableReinitialize
                        initialValues={taskInitialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleAddTask}

                    >
                        {(formik) => (
                            <Form>


                                <Select
                                    className="my-3 "
                                    isSearchable={false}
                                    styles={{

                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            height: '40px',
                                            borderRadius: '8px',

                                            backgroundColor: '#fff',
                                            border: '1px solid rgba(8, 8, 41, 0.1)',
                                            boxShadow: '0px 4px 10px rgba(61, 57, 137, 0.05)'

                                        }),
                                        placeholder: (baseStyles) => ({
                                            ...baseStyles,
                                            fontSize: '16px',
                                            fontWeight: '400',
                                            color: '#788693',
                                            fontFamily: 'Inter, sans-serif'
                                        }),

                                        input: (baseStyles) => ({
                                            ...baseStyles,
                                            fontSize: '16px',
                                            color: '#1d1d1d',
                                            fontFamily: 'Inter, sans-serif'
                                        }),
                                    }}
                                    options={[
                                        { value: 'task', label: 'Task' },
                                        { value: 'reminder', label: 'Reminder' },
                                    ]}
                                    placeholder={'Select Type'}
                                    value={formik.values.type}
                                    getOptionValue={(e) => e?.value}
                                    // isMulti={true}

                                    onChange={e => {
                                        console.log(e)
                                        formik?.setFieldValue('type', e)
                                    }}
                                />
                                {/*
                    <div className="mb-3">
                        <select
                            type="text"
                            className="form-control task-input"
                            placeholder="Add Type"
                            {...formik.getFieldProps('type')}>
                                <option value=''>Select A Type</option>

                                <option value='task'>Task</option>

                                <option value='reminder'>Reminder</option>

                        </select>
                    </div>*/}
                                {formik?.errors?.type && formik?.touched?.type ? (
                                    <FormFeedback invalid>{formik?.errors?.type}</FormFeedback>
                                ) : null}

                                <div className="mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Add Title"
                                        {...formik.getFieldProps('name')}

                                    />
                                </div>

                                {formik?.errors?.name && formik?.touched?.name ? (
                                    <FormFeedback invalid>{formik?.errors?.name}</FormFeedback>
                                ) : null}
                                <div className="mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Add Notes"
                                        {...formik.getFieldProps('notes')}


                                    />
                                </div>
                                {formik?.errors?.notes && formik?.touched?.notes ? (
                                    <FormFeedback invalid>{formik?.errors?.notes}</FormFeedback>
                                ) : null}
                                <div className="mb-3">
                                    <input
                                        type="datetime-local"
                                        className="form-control"
                                        placeholder="date and time"
                                        {...formik.getFieldProps('date')}

                                    />
                                </div>

                                {formik?.errors?.date && formik?.touched?.date ? (
                                    <FormFeedback invalid>{formik?.errors?.date}</FormFeedback>
                                ) : null}


                                <Select
                                    className="my-3 "
                                    isClearable
                                    styles={{

                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            height: '40px',
                                            borderRadius: '8px',

                                            backgroundColor: '#fff',
                                            border: '1px solid rgba(8, 8, 41, 0.1)',
                                            boxShadow: '0px 4px 10px rgba(61, 57, 137, 0.05)'

                                        }),
                                        placeholder: (baseStyles) => ({
                                            ...baseStyles,
                                            fontSize: '16px',
                                            fontWeight: '400',
                                            color: '#788693',
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal'
                                        }),

                                        input: (baseStyles) => ({
                                            ...baseStyles,
                                            fontSize: '16px',
                                            color: '#1d1d1d',
                                            fontFamily: 'Inter'
                                        }),
                                    }}
                                    options={users && users?.length > 0 && users?.map((data) => ({
                                        id: data?.user?._id,
                                        value: data?.user?.firstname,
                                        label: data?.user?.firstname + ' ' + data?.user?.lastname


                                    }))}
                                    placeholder={'Assign To'}
                                    value={formik.values.assigned_to}
                                    getOptionValue={(e) => e?.id}
                                    // isMulti={true}

                                    onChange={e => {
                                        console.log(e)
                                        formik?.setFieldValue('assigned_to', e)
                                    }}
                                />

                                {formik?.errors?.assigned_to && formik?.touched?.assigned_to ? (
                                    <p>{formik?.errors?.assigned_to}</p>
                                ) : null}

                                <div className="collapse-content-btn d-flex">
                                    <button onClick={toggle} type="button" className="flex-fill btn btn-link">
                                        Cancel
                                    </button>
                                    <button type="submit" className="flex-fill btn btn-link btn-bluee">
                                        Save
                                    </button>
                                </div>
                            </Form>)}
                    </Formik>
                </div>

            }
            <h6 className="font-medium mb-2">
                Tasks & Reminders
            </h6>
            {loading ? <div className="mx-auto">
                <Spinner height={150} />
            </div> : <div className="remind-title">
                <Tab.Container defaultActiveKey="first-key">
                    <Nav variant="pills">
                        <Nav.Item className="me-2 d-flex">
                            <Nav.Link eventKey="first-key">
                                All
                            </Nav.Link>
                            <Nav.Link eventKey="second-key">
                                Active
                            </Nav.Link>
                            <Nav.Link eventKey="third-key">
                                Tasks
                            </Nav.Link>
                            <Nav.Link eventKey="forth-key">
                                Reminders
                            </Nav.Link>
                            <Nav.Link eventKey="firth-key">
                                Archived
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content animation>
                        <Tab.Pane eventKey="first-key">
                            {reminders?.map((task) => (
                                <TaskObject task={task} />
                            ))}
                        </Tab.Pane>
                        <Tab.Pane eventKey="second-key">
                            {reminders?.filter(i => !i?.completed)?.map((task) => (
                                <TaskObject task={task} />
                            ))}

                        </Tab.Pane>
                        <Tab.Pane eventKey="third-key">

                            {reminders?.filter(i => i?.type === 'task')?.map((task) => (
                                <TaskObject task={task} />
                            ))}

                        </Tab.Pane>
                        <Tab.Pane eventKey="forth-key">

                            {reminders?.filter(i => i?.type === 'reminder')?.map((task) => (
                                <TaskObject task={task} />
                            ))}

                        </Tab.Pane>
                        <Tab.Pane eventKey="firth-key">
                            {reminders?.filter(i => i?.completed)?.map((task) => (
                                <TaskObject task={task} />
                            ))}

                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>}
        </>
    )
}

export default Tasks