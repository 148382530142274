import * as Yup from "yup";
/**
 * @description declaration form schema for client validations
 */



export const preexistingSchema = Yup.object().shape({
  
    preexisting: Yup.string()?.required('Please select an option')
});


export const breedSchema = Yup.object().shape({
  
    breed: Yup.object().shape({value: Yup?.string(), label: Yup.string()})?.required('Please select an option')
});
