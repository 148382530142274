import { getAllDoctors } from "context/actions/appointment";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { MdOutlinePets } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";
import NodataFoundBlock from "shared/NoDataFoundBlock";
import { useClinicSpecies } from "hooks/useClinicSpecies";

const RejectedRequests = () => {

  const clinicSpecies = useClinicSpecies()
  const dispatch = useDispatch();
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const [pageData, setPageData] = useState([]);
  const getPageData = () => {
    dispatch(
      getAllDoctors(clinic_details?._id, {}, (result) => {
        let resList = [];
        if (Array.isArray(result)) {
          result?.forEach((elm) => {
            const { user, appointments } = elm;
            let name = `${user.title} ${user.firstname} ${user.lastname}`;
            appointments.forEach((tElem) => {
              if (tElem?.isAppointmentAproved === 2) {
                resList.push({
                  ...tElem,
                  doctor_name: name,
                  doctor_image: user.user_image,
                });
              }
            });
          });
        }
        setPageData(resList);
      })
    );
  };
  useEffect(() => {
    getPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic_details]);
  return (
    <div className="ms-3 mt-2 me-2">
      <div className="page-content d-flex flex-column flex-fill overflow-auto">
        <div className="table-listing-blc overflow-auto">
          {pageData.length === 0 ? (
            <NodataFoundBlock />
          ) : (
            <Table responsive className="checkbox-listing">
              <thead>
                <tr>
                  <th style={{ minWidth: "222px" }}>Doctor</th>
                  <th>Patient</th>
                  <th>Date</th>
                  <th>Time</th>

                  <th style={{ minWidth: "150px" }}>Type</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {pageData.map((staffManagement, index) => {
                  const {
                    doctor_name,
                    doctor_image,
                    patient_id,
                    appointment_date,
                    appointment_type,
                    appointment_timings,
                  } = staffManagement;
                  const { pet_name, pet_image, breed } = patient_id;
                  return (
                    <tr key={index}>
                      <td
                        className="d-flex align-items-center gap-2"
                        style={{ minWidth: "222px" }}
                      >
                        {doctor_image ? (
                          <div className="f-center">
                            <img
                              src={doctor_image}
                              alt="doctor_image"
                              className="h-20 w-20 f-center rounded-circle"
                            />
                          </div>
                        ) : (
                          <div className="h-20 w-20 f-center rounded-circle bg-e3e3">
                            <FaUser color="#788C9F" size={8} />
                          </div>
                        )}
                        <div>{doctor_name}</div>
                      </td>
                      <td style={{ minWidth: "200px" }}>
                        <div className="fa-center gap-1 ">
                          {pet_image ? (
                            <div className="f-center">
                              <img
                                src={pet_image}
                                alt="pet_image"
                                className="h-20 w-20 br-4"
                              />
                            </div>
                          ) : (
                            <div className="bg-f8f8 f-center h-20 w-20 p-1">
                              <MdOutlinePets />
                            </div>
                          )}
                          <div className="text-13-400 color-2E65">
                            {pet_name} / {clinicSpecies(breed?.species_id?._id) || breed?.species_id?.species_name}
                          </div>
                        </div>
                      </td>

                      <td style={{ minWidth: "180px" }}>
                        {moment(appointment_date, "DD-MM-YYYY").format(
                          "MMM DD, YYYY"
                        )}
                      </td>
                      <td style={{ minWidth: "180px" }}>
                        {appointment_timings?.[0]?.appointment_time}
                      </td>

                      <td>{appointment_type}</td>
                      <td>
                        <span
                          className={`status-cell ${
                            !staffManagement?.user?.active && "inactive-cell"
                          }`}
                        >
                          Rejected
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    </div>
  );
};

export default RejectedRequests;
