import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";

toast.configure();

/**
 * @description function use to add new skill
 * @param {*} params accepting object
 */
export const addNewSkill = (data, callback) => {
    return (dispatch, getState) => {
        
        const { user: { token } } = getState();
        ApiClient.post(`${apiUrl}${PORT}/user-skill/add-new-skill`, data, token, dispatch, getState).then((response) => {
            
            if (!response) {
                return callback();
            }
            else if (!response && response.errors) {
                toast.error(response.errors.msg, toasterConfig);
                return callback(response);
            } else if (response) {
                toast.dismiss();
                toast.success(response.msg, toasterConfig);
                return callback(response);
            } else {
                //toast.error(response.message, toasterConfig);
                return callback();
            }
        });
    };
};

/**
 * @description function use to get all skills
 * @param {*} params accepting object
 */
export const getAllSkills = (id, params, callback) => {
    return (dispatch, getState) => {
        const { user: { token } } = getState();
        
        ApiClient.get(`${apiUrl}${PORT}/user-skill/get-all-skills/${id}`, params, token, dispatch, getState).then(
            (response) => {
                
                if (response && !response.errors) {
                    return callback(response);
                } else if (response?.code === 404) {
                    toast.warn(response.message);
                    return callback();
                } else {
                    toast.error(response.errors.msg, toasterConfig);
                    return callback();
                }
            }
        );
    };
};

/**
* @description function use to delete skill
* @param {*} params accepting object
*/
export const deleteSkill = (skillId, params, callback) => {
    return (dispatch, getState) => {
        const { user: { token } } = getState();
        
        ApiClient.put(`${apiUrl}${PORT}/user-skill/delete-skill/${skillId}`, params, token, dispatch, getState).then(
            (response) => {
                
                if (!response) {
                    return callback();
                }
                else if (!response && response.errors) {
                    toast.error(response.errors.msg, toasterConfig);
                    return callback(response);
                } else if (response) {
                    toast.dismiss();
                    toast.success(response.msg, toasterConfig);
                    return callback(response);
                } else {
                    //toast.error(response.message, toasterConfig);
                    return callback();
                }
            }
        );
    };
};