import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateClinicSettings } from "../../../../../context/actions/clinicSettings";
import { FormGroup, Input, InputGroup, Row, Button } from "reactstrap";
import { Formik, Form, FieldArray } from "formik";

import * as Yup from "yup"
const OtherPreferences = () => {
    const dispatch = useDispatch();
    const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);
    const [preferences, setPreferences] = useState({
        allow_video_consultancy: clinic_settings?.allow_video_consultancy,
        allow_telephonic_consultancy: clinic_settings?.allow_telephonic_consultancy,
        allow_conference_consultancy: clinic_settings?.allow_conference_consultancy,
        allow_inclinic_consultancy: clinic_settings?.allow_inclinic_consultancy,
        allow_exceed_weekly_hours: clinic_settings?.allow_exceed_weekly_hours,
        allow_waiting_room: clinic_settings?.allow_waiting_room,
        allow_vertical_insurance: clinic_settings?.allow_vertical_insurance,
        vaccination_due: {
            num: clinic_settings?.vaccination_due?.num,
            unit: clinic_settings?.vaccination_due?.unit,
        }
    })

    const reminderTimesInit = {
        reminder_times: preferences?.reminder_time?.length > 0 ? preferences?.reminder_time : []
    }


const vaccineScheduleSchema = Yup.object().shape({
  
  reminder_times: Yup.array().of(
    Yup.object().shape({
      unit: Yup.string().required(),
      value: Yup.number().required()
    })
  ),
});




    

    const handleUpdateSettings = (e, key) => {
        let value;
        if (key === 'num' || key === 'unit') {
            value = e.target.value;
        } else {
            value = e.target.checked;
        }
        let state = { ...preferences }
        let vaccination = { ...state };
        switch (key) {
            case "reminder_time":
                value = e.target.value;
                state = { ...state, reminder_time: value }

                break;
            case "allow_video_consultancy":
                value = e.target.checked;

                state = { ...state, allow_video_consultancy: value }
                break;
            case "allow_telephonic_consultancy":
                value = e.target.checked;

                state = { ...state, allow_telephonic_consultancy: value }
                break;
            case "allow_conference_consultancy":
                value = e.target.checked;

                state = { ...state, allow_conference_consultancy: value }
                break;
            case "allow_inclinic_consultancy":
                value = e.target.checked;

                state = { ...state, allow_inclinic_consultancy: value }
                break;
            case "allow_exceed_weekly_hours":
                value = e.target.checked;

                state = { ...state, allow_exceed_weekly_hours: value }
                break;
            case "allow_vertical_insurance":
                value = e.target.checked;

                state = { ...state, allow_vertical_insurance: value }
                break;
            case "allow_waiting_room":
                value = e.target.checked;

                state = { ...state, allow_waiting_room: value }
                break;
            case "num":
                 state = { ...state, vaccination_due:{...state.vaccination_due, num : value } }
                break;
            case "unit":
                state = { ...state, vaccination_due:{...state.vaccination_due, unit : value } }
                break;
            default:
                break;
        }
        console.log({...state})
        setPreferences(p => p = { ...state })
        let body = {
            ...state,
            clinic_id: clinic_settings.clinic_id,
            update_query: "other_settings",
        }
        // console.log(body)
        dispatch(updateClinicSettings(body, ()=> {}));
    }
    const { permissionsData } = useSelector((state) => state.clinicPermissionReducer );
    const permissions = permissionsData;
    return <>
        <div className="toggle-item-blc">
            
            
            <div className="item-rw d-flex">
                <div className="form-check form-switch">
                    <input disabled={!permissions?.edit_other_preferences} onClick={(e) => handleUpdateSettings(e, "allow_video_consultancy")} checked={!!preferences?.allow_video_consultancy} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                </div>
                <div className="txt">
                    <h4>Allow Video Call Consultancy</h4>

                </div>
            </div>
            <div className="item-rw d-flex">
                <div className="form-check form-switch">
                    <input disabled={!permissions?.edit_other_preferences} onClick={(e) => handleUpdateSettings(e, "allow_telephonic_consultancy")} checked={!!preferences?.allow_telephonic_consultancy} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                </div>
                <div className="txt">
                    <h4>Allow Telephonic Call Consultancy</h4>
                </div>
            </div>
            <div className="item-rw d-flex">
                <div className="form-check form-switch">
                    <input disabled={!permissions?.edit_other_preferences} onClick={(e) => handleUpdateSettings(e, "allow_conference_consultancy")} checked={!!preferences?.allow_conference_consultancy} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                </div>
                <div className="txt">
                    <h4>Allow Conference Call Consultancy</h4>
                </div>
            </div>
            <div className="item-rw d-flex">
                <div className="form-check form-switch">
                    <input disabled={!permissions?.edit_other_preferences} onClick={(e) => handleUpdateSettings(e, "allow_inclinic_consultancy")} checked={!!preferences?.allow_inclinic_consultancy} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                </div>
                <div className="txt">
                    <h4>Allow In-Clinic Consultancy</h4>
                </div>
            </div>
            <div className="item-rw d-flex">
                <div className="form-check form-switch">
                    <input disabled={!permissions?.edit_other_preferences} onClick={(e) => handleUpdateSettings(e, "allow_waiting_room")} checked={!!preferences?.allow_waiting_room} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                </div>
                <div className="txt">
                    <h4>Allow Waiting Room</h4>
                </div>
            </div>


            <div className="item-rw d-flex">
                <div className="form-check form-switch">
                    <input disabled={!permissions?.edit_other_preferences} onClick={(e) => handleUpdateSettings(e, "allow_vertical_insurance")} checked={!!preferences?.allow_vertical_insurance} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                </div>
                <div className="txt">
                    <h4>Allow Pet Owners to Purchase Vertical Pet Insurance</h4>
                </div>
            </div>

            
            <div className="item-rw d-flex">
                <div className="form-check form-switch">
                    <input disabled={!permissions?.edit_other_preferences} onClick={(e) => handleUpdateSettings(e, "allow_exceed_weekly_hours")} checked={!!preferences?.allow_exceed_weekly_hours} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                </div>
                <div className="txt">
                    <h4>Don't allow Doctors to exceed 40 hours per week. </h4>
                    <h5>Select this setting except doctors to exceed 40 hours limit to avoid overtime payment.  </h5>
                </div>
            </div>
            <div className="item-rw d-flex">
                <div className="grow flex flex-col justify-center items-start">
                    <Row className="flex mb-2">
                    <div className="row-block flex items-center w-full">
                        <h4>Display Vaccines Due Within</h4>
                        <div className="right-col flex-fill ml-2">
                        <InputGroup>
                            <Input
                            id="duration-value"
                            placeholder="Select Value"
                            onChange={(e) => {
                                handleUpdateSettings(e, 'num');
                            }}
                                        value={preferences?.vaccination_due?.num}
                            type="Number"
                            />
                            <Input
                            id="durations-unit"
                            placeholder="Select Unit"
                            onChange={(e) => {
                                handleUpdateSettings(e, 'unit');
                            }}
                            value={preferences?.vaccination_due?.unit}
                            type="select"
                        >
                            <option value="days">Days</option>
                            <option value="weeks">Weeks</option>
                            <option value="months">Months</option>
                        </Input>
                        </InputGroup>
                            
                        </div>
                    </div>
                    </Row>                    
                </div>
            </div>
        </div>
    </>
}

export default OtherPreferences;