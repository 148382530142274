import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../../../../api-client";
import { apiUrl, PORT } from "../../../../../environment";
import { toasterConfig } from "../../../../../utils/constants";

toast.configure();

export const getResultsByPetId = (petId, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    if (petId) {
      dispatch({ type: "VITAL_RESULTS_LOADING", payload: true });
      ApiClient.get(`${apiUrl}${PORT}/vital/get-all/${petId}`, {}, token, dispatch, getState).then(
        (response) => {
          dispatch({ type: "VITAL_RESULTS_LOADING", payload: false });
          if (!response) {
            return callback();
          } else if (response?.status) {
            dispatch({
              type: "VITAL_RESULTS",
              payload: response?.response_data,
            });
            return callback(response);
          } else {
            return callback(response);
          }
        }
      );
    } else {
      return callback({ status: false });
    }
  };
};

export const getAndFilterResults = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    if (token) {
      ApiClient.post(
        `${apiUrl}${PORT}/vital/get-all-with-filter`,
        body,
        token,
        dispatch, getState
      ).then((response) => {
        dispatch({ type: "VITAL_RESULTS_LOADING", payload: false });
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({ type: "VITAL_RESULTS", payload: response?.response_data });
          dispatch({
            type: "VITAL_RESULTS_ARR",
            payload: response?.response_data,
            // payload: response?.response_data[0]?.results,
          });
          return callback(response);
        } else {
          dispatch({
            type: "VITAL_RESULTS_ARR",
            payload: [],
          });
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    }
  };
};

export const getMostRecentVitalForPet = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    if (token) {
      ApiClient.post(
        `${apiUrl}${PORT}/vital/get-most-recent`,
        body,
        token,
        dispatch, getState
      ).then((response) => {
        dispatch({ type: "VITAL_RESULTS_LOADING", payload: false });
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({ type: "VITAL_RESULTS", payload: response?.response_data });
          dispatch({
            type: "VITAL_RESULTS_ARR",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          dispatch({
            type: "VITAL_RESULTS_ARR",
            payload: [],
          });
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    }
  };
};

export const addNewVital = (data, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.post(`${apiUrl}${PORT}/vital/add`, data, token, dispatch, getState).then(
      (response) => {
        if (!response) {
          return callback();
        } else if (!response && response.errors) {
          toast.error(response.errors.msg, toasterConfig);
          return callback(response);
        } else if (response) {
          toast.dismiss();
          toast.success(response.msg, toasterConfig);
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback();
        }
      }
    );
  };
};

export const getVitals = (payload) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  if (token) {
    return await ApiClient.post(
      `${apiUrl}${PORT}/vital/get-all-with-date-filter`,
      payload,
      token, dispatch, getState
    );
  }
};

export const addVitals = (payload) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  if (token) {
    const response = await ApiClient.post(
      `${apiUrl}${PORT}/vital/add`,
      payload,
      token, 
      dispatch, getState
    );

    return response;
  }
};

export const deleteVitals = (id) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  if (token) {
    const response = await ApiClient.put(
      `${apiUrl}${PORT}/vital/remove-vital/${id}`,
      {},
      token, dispatch, getState
    );
    if (response?.message) {
      if (response?.status) {
        toast.success(response.message, toasterConfig);
      } else {
        toast.error(response.message, toasterConfig);
      }
    }
    return response;
  }
};

export const updateVitals = (payload) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  if (token) {
    const response = await ApiClient.put(
      `${apiUrl}${PORT}/vital/update`,
      payload,
      token, dispatch, getState
    );
    if (response?.message) {
      if (response?.status) {
        toast.success(response.message, toasterConfig);
      } else {
        toast.error(response.message, toasterConfig);
      }
    }
    return response;
  }
};
