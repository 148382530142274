import { useDispatch } from "react-redux";
import { getBillingByPetId, payInvoiceBill } from "context/actions/allergies";
import "./InvoiceTab.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import moment from "moment";
import { FaSort } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import { Button, Input } from "reactstrap";
import NodataFoundBlock from "shared/NoDataFoundBlock";
import { DatePicker } from "rsuite";
import ReactDatePicker from "react-datepicker";
// import calender from "../../../assets/img/calendar_month.png";
import TotalRevenueInvoice from "../../../invoices-module/totalRevenueInvoice"
import { HiDownload } from "react-icons/hi";
import PaginationBlock from "shared/PaginationBlock";
import * as htmlToImage from "html-to-image";
import jsPDF from "jspdf";
import { petOwnerPayInvoice } from "context/actions/invoice";


const BackDrop = (props) => {
  return <div className="backdrop" onClick={props.closeDrawer}></div>;
};

const InvoiceTab = () => {
  const dispatch = useDispatch();
  const [invoicesData, setInvoicesData] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const { petDetails } = useSelector((state) => state.petOwnerDashboardReducer);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pageCount, setPageCount] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  let petId = petDetails?._id;

  const stDate = moment(startDate).format("YYYY-MM-DD");
  const eDate = moment(endDate).format("YYYY-MM-DD");

  const [isDownload, setIsDownload] = useState(false);
  const { token } = useSelector((state) => state.user);

  const getPageData = async () => {
    const res = await dispatch(
      getBillingByPetId({ petId: petDetails?._id, page, limit, stDate, eDate })
    );
    setInvoicesData(res.response_data.docs);
    setPageCount(res.response_data);
    // ,

    console.log(res);
  };
  // console.log(invoicesData, "invoicesDatainvoicesDatainvoicesData");

  useEffect(() => {
    getPageData();
  }, [page, limit, stDate, eDate, petDetails]);

  const downloadReport = () => {
    setIsDownload(true);
    setTimeout(() => {
      managePdfPages();
    }, 500);
  };

  const managePdfPages = () => {
    var nodes = document.querySelectorAll(".invoice-download");
    const pdf = new jsPDF("p", "mm", "a4");

    console.log(nodes, "nodesnodesnodes");

    // const addImageToPDF = (index, currentY,currentX) => {
    const node = nodes[0];
    htmlToImage.toPng(node).then((dataUrl) => {
      // 'use strict';
      const img = new Image();
      img.src = dataUrl;

      let pdfHeight;
      const pdfWidth = pdf.internal.pageSize.getWidth() - 20;
      console.log("pdfWidth: ", pdfWidth);

      pdfHeight = (node.clientHeight * pdfWidth) / node.clientWidth;

      pdf.addImage(img, "PNG", 10, 10, pdfWidth, pdfHeight);

      pdf.save(`invoice_OD.pdf`);
      setTimeout(() => {
        setIsDownload(false);
      }, 3000);
    });
  };

  const payInvoiceBill = (InvoiceId) => {
   dispatch(petOwnerPayInvoice(InvoiceId, (res) => {
      if (res) {
        window.open(res?.data?.url, "_blank");
      }
    }))

  };

  function handleOpenDrawerButton(invoiceData) {
    setDrawerOpen(!drawerOpen);
    setSelectedInvoice(drawerOpen ? null : invoiceData ? invoiceData : null);
  }
  function handleBackdropClick() {
    setDrawerOpen(false);
    setSelectedInvoice(null);
  }

  return (
    <div>
      <div className="sort_head">
        <div className="search-blc position-relative input-cell">
          <Input type="text" placeholder="Search" />
          <span className="position-absolute d-inline-flex align-items-center justify-content-center">
            <BsSearch className="h-4 w-4" />
          </span>
        </div>

        <div className="date">
          <>
            {/* <img src={calender} alt="dalender" /> */}
            <ReactDatePicker
              dateFormat="yyyy/MM/dd"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
            <span> -</span>
            <ReactDatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              dateFormat="yyyy/MM/dd"
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
          </>
        </div>

        {/* <div className="select">
          <select name="select" class="form-control h-auto">
            <option value="all">View By Roles</option>
            <option value="all">All</option>
            <option value="63735d1a8588212e140fe7c7">admin</option>
            <option value="63735d1a8588212e140fe7c8">staff</option>
            <option value="63735d1a8588212e140fe7c9">account</option>
            <option value="63735d1a8588212e140fe7ca">operation</option>
            <option value="63735d1a8588212e140fe7cb">client</option>
            <option value="63735d1a8588212e140fe7cc">others</option>
            <option value="63747b4eb82625c12bd377ed">clinic</option>
          </select>
        </div> */}
      </div>


        <div>
            {drawerOpen ? (
              <TotalRevenueInvoice
                show={drawerOpen}
                cancel={drawerOpen}
                setCancel={setDrawerOpen}
                invoiceData={selectedInvoice}
                petOwner={true}
              />
            ) : (
              ""
            )}

            {drawerOpen && <BackDrop closeDrawer={handleBackdropClick} />}
          </div>

      {invoicesData?.length > 0 ? (
        <>
          <div className="relative p-3 sm:rounded-lg overflow-x-auto responsive_table table-responsive">
            <Table>
              <thead>
                <tr>
                  <th>
                    <div>
                      Invoice ID <FaSort />
                    </div>
                  </th>
                  <th>
                    <div>
                      Issued On <FaSort />
                    </div>
                  </th>
                  <th>
                    <div>
                      Amount <FaSort />
                    </div>
                  </th>
                  <th>
                    <div>
                      Status <FaSort />
                    </div>
                  </th>
                  <th>
                    <div>
                      Payment on <FaSort />
                    </div>
                  </th>
                  <th>
                    <div>
                      Pet <FaSort />
                    </div>
                  </th>
                  <th>
                    <div>
                      Type <FaSort />
                    </div>
                  </th>
                  <th>
                    <div>
                      Appointment <FaSort />
                    </div>
                  </th>
                  <th>
                    <div>
                      Action <FaSort />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {invoicesData?.map((data) => {
                  return (
                    <tr>
                      <td  className="ml-2 text-sm font-medium text-[#3D3EFD] cursor-pointer"
                                onClick={() => handleOpenDrawerButton(data)}>IN{data?._id.substring(5, 12)}</td>
                      <td>
                        {moment(data?.issued_date)?.format("MM/DD/YYYY")}{" "}
                      </td>
                      <td> {data?.totalAmount} </td>
                      <td>
                        <span
                          className={`${
                            data?.status === "Pending"
                              ? "status-Pending"
                              : "status-Fully-Paid "
                          }`}
                        >
                          {data?.status}
                        </span>
                      </td>
                      <td> {moment(data?.createdAt)?.format("MM/DD/YYYY")} </td>
                      <td>
                        <div className="patient_detail">
                          <img src={data.patient_detail?.pet_image} alt="" />
                          <span> {data.patient_detail?.pet_name} </span>
                        </div>
                      </td>
                      <td>
                        {data?.appointment_type ? data?.appointment_type : "--"}
                      </td>
                      <td>{data?.appointment ? data?.appointment : "--"}</td>
                      <td>
                        {data?.status === "Pending" ? (
                          <p
                            className="pendding_bill"
                            onClick={() => {
                              payInvoiceBill(data?._id);
                            }}
                          >
                            Pay Bill
                          </p>
                        ) : (
                          <Button
                            className="download_btn"
                            onClick={downloadReport}
                          >
                            {/* <img src={Download} alt="Download" />  */}
                            <HiDownload />
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div className="billpagination">
            <PaginationBlock
              limit={limit}
              page={page}
              pagingCounter={pageCount?.pagingCounter || 1}
              docLength={1}
              docPage={1}
              totalPages={pageCount?.totalPages || 1}
              totalDocs={pageCount?.totalDocs || 1}
              _handleLimit={(val) => {
                setLimit(val);
              }}
              _handlePageChange={setPage}
            />
          </div>
        </>
      ) : (
        <NodataFoundBlock type={stDate || eDate ? 'search result' : '' } />
      )}
    </div>
  );
};

export default InvoiceTab;
