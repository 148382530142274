import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../../../api-client";
import { apiUrl, PORT } from "../../../../environment";
import { toasterConfig } from "../../../../utils/constants";

toast.configure();
/**
 * All Api endpoints to be call
 * Post   -- /pet/get-list
 */

/**
 *
 * @param {Object} body
 * @param {Function} callback
 * @returns
 */

export const getPetOwnersListWithFilter = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();
    if (user) {
      dispatch({ type: "PET_OWNER_LOADING", payload: true });
      ApiClient.post(
        `${apiUrl}${PORT}/user-general-info/get-pet-owners`,
        {
          role: body.role || "user",
          ...body,
        },
        token,
        dispatch, getState
      ).then((response) => {
        dispatch({ type: "PET_OWNER_LOADING", payload: false });
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({
            type: "GET_PET_OWNER_LIST",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    } else {
      return callback(null);
    }
  };
};

export const getPetOwnersListV2WithFilter = (body, callback) => {
  return (dispatch, getState) => {
    const {
      user: { token, user },
    } = getState();
    if (user) {
      dispatch({ type: "PET_OWNER_LOADING", payload: true });
      ApiClient.post(
        `${apiUrl}${PORT}/user-general-info/get-pet-owners-v2`,
        {
          role: body.role || "user",
          ...body,
        },
        token,
        dispatch, getState
      ).then((response) => {
        dispatch({ type: "PET_OWNER_LOADING", payload: false });
        if (!response) {
          return callback();
        } else if (response?.status) {
          dispatch({
            type: "GET_PET_OWNER_LIST",
            payload: response?.response_data,
          });
          return callback(response);
        } else {
          //toast.error(response.message, toasterConfig);
          return callback(response);
        }
      });
    } else {
      return callback(null);
    }
  };
};

export const addPetForOwner = (payload) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState();
  const response = await ApiClient.postFormData(
    `${apiUrl}${PORT}/pet/add-new-pet-details`,
    payload,
    token,
    dispatch, getState
  );
  if (!response?.status) {
    toast.error(response?.message, toasterConfig);
  }
  return response;
};
export const updatePetForOwner =
  (id, payload) => async (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    const response = await ApiClient.put(
      `${apiUrl}${PORT}/pet/update-pet-details/${id}`,
      payload,
      token,
      dispatch, getState
    );
    if (!response?.status) {
      toast.error(response?.message, toasterConfig);
    }
    return response;
  };

export const updatePetImageForOwner =
  (payload) => async (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    const response = await ApiClient.postFormData(
      `${apiUrl}${PORT}/pet/add-pet-image`,
      payload,
      token,
      dispatch, getState
    );
    if (!response?.status) {
      toast.error(response?.message, toasterConfig);
    }
    return response;
  };
