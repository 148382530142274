const initState = {
  vitalsToggles: {},
};

export const vitalsTogglesReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_VITALS_TOGGLES":
      return { ...state, vitalsToggles: action.payload };

    default:
      return state;
  }
};
