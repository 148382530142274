import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import AppointmentsList from "./AppointmentsList";
import { getPetOwnerPetAppointments } from "context/actions/pet-owner-dashboard";
import moment from "moment";
import Upcoming from "./Upcoming";
import CalendarFilter from "shared/Calendar/CalendarFilter";
import { Spinner } from "react-bootstrap";
import { Offcanvas } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import NoDataFound from "../NoDataFound";
import AppointmentDetails from "./AppointmentDetails";
import {
  getAppointmentDetails,
  updateAppointmentDetails,
} from "context/actions/appointment";
import { setAppointmentDetailData } from "context/actions/appointmentDetailPanelAction";
import AppointmentRequest from "./AppointmentRequest";
import { getAllHoliday } from "context/actions/holiday";
import "./PetOwnerAppointments.scss";
import NodataFoundBlock from "shared/NoDataFoundBlock";

const PetOwnerAppointments = () => {
  const dispatch = useDispatch();
  const { petDetails } = useSelector((state) => state.petOwnerDashboardReducer);
  const data = useSelector((state) => state.appointmentDetailPanelReducer.data);
  const { clinic_settings } = useSelector(state => state.clinicSettingsReducer);

  const [id, setId] = useState("");
  const [pageData, setPageData] = useState([]);
  const [upcommingAppointment, setUpcommingAppointment] = useState(null);
  const [isAddRequest, setIsAddRequest] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);
  const [calendarViewData, setCalendarViewData] = useState({
    span_date: new Date().getDate(),
    date_value: new Date().getDate(),
    month_value: new Date().getMonth(),
    span_month: new Date().getMonth(),
    year_value: new Date().getFullYear(),
    view_type: "month",
  });
  const [showOverlay, setShowOverlay] = useState(true);
  const [wSize, setWSize] = useState(window.innerWidth);
  const [appointmentData, setAppointmentData] = useState(false);

  const [updates, setUpdates] = useState(false);
  const handleStart = () => {
    if (appointmentData) {
      //setUpdates({ appointment_start: new Date() });

      if (appointmentData?.appointment_type === "video-call") {
        const win = window.open(
          `/video-chat/${appointmentData?._id}`,
          "_blank"
        );
        win.focus();
        //history.push(`/video-chat/${appointmentData?._id}`)
      }
    }
  };

  //new
  // useEffect(() => {
  //   if (data && updates) {
  //     dispatch(
  //       updateAppointmentDetails(data?._id, updates, () => {
  //         dispatch(
  //           getAppointmentDetails(data?._id, (res) => {
  //             dispatch(setAppointmentDetailData(res));
  //             setAppointmentData(res);
  //           })
  //         );
  //       })
  //     );
  //   }
  // }, [updates]);

  // useEffect(() => {
  //   setUpdates(false);
  // }, []);

  useEffect(() => {
    if (data) {
      dispatch(
        getAppointmentDetails(data?._id, (res) => {
          //dispatch(setAppointmentDetailData(res));

          //setUpdates(false);
          setAppointmentData(res);
        })
      );
    }
  }, [data]);

  const handleResize = () => {
    setWSize(window.innerWidth);
  };

  const afterToday = (item) => {
    //new logic for get data datetime wise
    let appointment_date = `${item?.appointment_date} ${item?.appointment_timings?.[0]?.slot_time}`;
    const appointmentDateTime = moment(appointment_date, "DD-MM-YYYY hh-mm a");
    const currentDateTime = moment();
    return appointmentDateTime.isSameOrAfter(currentDateTime);

    //old logic for get data only date wise
    // let date = moment(item.appointment_date, "DD-MM-YYYY");
    // let today = moment();
    // return date.isSameOrAfter(today);
  };
  const getPageData = async (filterData) => {
    const {
      view_type,
      span_date,
      span_month,
      date_value,
      month_value,
      year_value,
    } = filterData;
    let startDate = "";
    let endDate = "";
    if (view_type === "day") {
      let dayDate = `${date_value}-${month_value}-${year_value}`;
      startDate = moment(dayDate, "D-M-YYYY").format("DD-MM-YYYY");
      endDate = moment(dayDate, "D-M-YYYY").format("DD-MM-YYYY");
    } else if (view_type === "week") {
      let dayDate1 = `${span_date}-${span_month}-${year_value}`;
      let dayDate2 = `${date_value}-${month_value}-${year_value}`;
      startDate = moment(dayDate1, "D-M-YYYY").format("DD-MM-YYYY");
      endDate = moment(dayDate2, "D-M-YYYY").format("DD-MM-YYYY");
    } else if (view_type === "month") {
      const firstDate = `1-${month_value + 1}-${year_value}`;
      const lastdate = moment(firstDate, "D-M-YYYY")
        .endOf("month")
        .format("DD-MM-YYYY");
      startDate = moment(firstDate, "D-M-YYYY").format("DD-MM-YYYY");
      endDate = moment(lastdate, "D-M-YYYY").format("DD-MM-YYYY");
    } else {
      // Nothing
    }

    const payload = {
      pet_id: petDetails?._id,
      startDate: startDate,
      endDate: endDate,
    };
    const response = await dispatch(getPetOwnerPetAppointments(payload));
    const resData = response?.response_data || [];

    let upcomming = resData
      .filter(afterToday)
      ?.sort(
        (a, b) =>
          moment(
            `${a?.appointment_date} ${a?.appointment_timings?.[0]?.slot_time}`,
            "DD-MM-YYYY hh-mm a"
          ) -
          moment(
            `${b?.appointment_date} ${b?.appointment_timings?.[0]?.slot_time}`,
            "DD-MM-YYYY hh-mm a"
          )
      );
    setUpcommingAppointment(upcomming?.length > 0 ? upcomming : null);
    setPageData(response?.response_data || []);
    setPageLoader(false);
  };
  useEffect(() => {
    getPageData(calendarViewData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarViewData]);

  useEffect(() => {
    setPageLoader(true);
    getPageData(calendarViewData);
    window.addEventListener("resize", handleResize, false);
    if (petDetails?.clinic?.[0]) {
      dispatch(getAllHoliday(petDetails?.clinic?.[0], () => {}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petDetails]);
  const isOverlay = wSize < 1440;
  let appontmentList = pageData?.filter((o) => o.isAppointmentAproved === 1);
  let appontmentPendingList = pageData?.filter(
    (o) => o.isAppointmentAproved === 0
  );
  let displayList = [...appontmentList, ...appontmentPendingList];

  let isEmply =
    appontmentList?.length === 0 && appontmentPendingList?.length === 0;
  console.log(upcommingAppointment);
  return (
    <div id="pet-owner-appointments-container">
      {isAddRequest && (
        <AppointmentRequest
          handleSuccess={() => {
            setIsAddRequest(false);
            getPageData(calendarViewData);
          }}
          onHide={() => {
            setIsAddRequest(false);
          }}
        />
      )}
      {id && (
        <AppointmentDetails
          appointment_data={data}
          // handleStop={() => {
          //   setUpdates({ appointment_end: new Date() });
          //   window.location.reload();
          // }}
          handleStart={handleStart}
          id={id}
          onHide={() => {
            setId("");
          }}
        />
      )}
      {pageLoader ? (
        <div className="f-center h-100">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <div className="fb-center">
            <div className="text-24-700 color-2E65">Appointments</div>
            {clinic_settings?.pet_owner_preferences?.appointment_request &&

            <div>
              <Button
                color="success"
                className="add-btn btn btn-success"
                onClick={() => {
                  setIsAddRequest(true);
                }}
              >
                Request appointment
              </Button>
            </div>}
          </div>

          <div className="d-flex mt-3">
            <div className="flex-grow-1 pe-2">
              <CalendarFilter
                isHideDropdown
                sendCalendarView={() => {}}
                calendarViewValue={(value) =>
                  setCalendarViewData({ ...calendarViewData, ...value })
                }
              />
              {isEmply ? (
                <div className="f-center mt-5">
                  <NodataFoundBlock type="appointment" />
                </div>
              ) : (
                <div className="appoinments-list-container">
                  <div className="appoinments-list-inner">
                    {displayList?.map((elem, index) => {
                      return (
                        <div key={index} className="appoinments-list-card mb-2">
                          <AppointmentsList data={elem} setId={setId} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            {upcommingAppointment && (
              <div>
                {isOverlay ? (
                  <Offcanvas show={showOverlay} placement="end">
                    <Offcanvas.Body className="p-0">
                      <div className="p-1 gap-[10px] d-flex flex-column">
                        <div className="fb-center mb-2">
                          <div className="text-18-600 color-2E65">
                            Upcoming Appointments
                          </div>
                          <div
                            className="d-flex cursor-pointer"
                            onClick={() => {
                              setShowOverlay(false);
                            }}
                          >
                            <IoMdClose size={20} />
                          </div>
                        </div>
                        <Upcoming data={upcommingAppointment} setId={setId} />
                      </div>
                    </Offcanvas.Body>
                  </Offcanvas>
                ) : (
                  <div style={{gap: '10px', display: 'flex', flexDirection: 'column'}}>
                    <div className="text-18-600 color-2E65">
                      Upcoming Appointments
                    </div>
                    <Upcoming data={upcommingAppointment} setId={setId} />
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PetOwnerAppointments;
