import React from "react";
import { useField } from "formik";
import { FormFeedback, FormGroup, Input } from "reactstrap";
import "react-phone-input-2/lib/style.css";

import PhoneInput from "react-phone-input-2";
// import { FormattedMessage } from 'react-intl';
const PhoneNumberInput = (props) => {
  const [field, meta] = useField(props.name);
  const {className, onChange, value, ...inputprops} = props
  return (
    <>
      <FormGroup className={`input-wrapper ${props?.className}`}>

        {props.label && <><label >
                <span className={props.required && 'required'}>{props.label}</span>
              </label></>}
   
      
       

        <PhoneInput
        // country={'us'}
          placeholder="Please enter phone number"
          onChange={onChange}
          value={value}
          regions={['europe', 'north-america']}
          inputProps={{...inputprops}}
          className={`${props?.className}`}
          //autoComplete="off"
        />

{(meta?.error && meta.touched) && 
                        <FormFeedback className="d-block"> {meta.error}
                            
                        </FormFeedback>}

</FormGroup>
      </>
  );
};
export default PhoneNumberInput;