import React , { useState} from "react";
import { useSelector } from "react-redux";
import { Button, Input } from "reactstrap"; // eslint-disable-line

const TableListFilterBlock = (
	{
		filterParams,
		onSearch,

		onSelectStatus,
	}
) => {

	const { cities, species } = useSelector(state => state.appContentReducer)

	return (
		<>
			<div className="table-list-filter-blc d-flex align-items-center">
				<div className="search-cell col-cell position-relative">
					<span className="icomoon-search d-inline-flex align-items-center justify-content-center position-absolute"></span>
					<Input type="text" placeholder="Search" value={filterParams?.searchText} onChange={onSearch} />
				</div>
				<div className="input-cell col-cell">
					<Input type="select" name="select"  value={filterParams?.status}  onChange={onSelectStatus}>
						<option value="">All Status</option>
						<option value={true}>Active</option>
						<option value={false}>Inactive</option>
					</Input>
				</div>
			</div>
		</>
	);
};

export default TableListFilterBlock;
