import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  capitalizeFirstLetter,
  formatPhoneNumber,
} from "../../../../utils/common";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { Button, Table, DropdownToggle, ButtonDropdown } from "reactstrap";
import { TabContent, Nav, NavItem, NavLink } from "reactstrap";
import LoaderUI from "shared/loader";
import PageHeader from "shared/PageHeader";
import CheckBox from "shared/CheckBox";
import UserPlaceholder from "shared/UserPlaceholder";
import NoDataFoundBlock from "shared/NoDataFoundBlock";
import {
  getStaffManagementList
} from "context/actions/staffManagement";
import { sidebarPanelAction } from "context/actions/sidebarPanelAction";
// import TableListFilterWrapper from "./TableListFilterWrapper";
import NewUserModal from "./NewUserModal";
import TableListStaffManagementFilterWrapper from "shared/TableListStaffManagementFilterWrapper";
import ScheduleRequests from "./ScheduleRequests";
import moment from "moment";
import ClinicAvilability from "./ClinicAvilability";
import AddHoliday from "./AddHoliday";
import "./index.scss";
import PaginationBlock from "shared/PaginationBlock";

const StaffManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  //const userDetails = useSelector((state) => state.user.user_details);
  const [loader, showLoader] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [staffManagementUsers, setStaffManagementUsers] = useState(false);
  const [filterPayload, setFilterPayload] = useState({
    //userId: userDetails._id,
    status: "",
    searchText: "",
    staff_role: "",
    start_date: "",
    end_date: "",
  });

  const [newUser, setNewUser] = useState(false);
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const [activeTab, setActiveTab] = useState("1");
  const [isAddHoliday, setIsAddHoliday] = useState(false);

  useEffect(() => {
    getData(filterPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterPayload, page, limit]);

  const getData = () => {
    showLoader(true);
    dispatch(
      getStaffManagementList({ ...filterPayload, limit, page }, (res) => {
        if (res) {
          setStaffManagementUsers(res);
          showLoader(false);
        } else {
          setStaffManagementUsers(false);
          showLoader(false);
        }
      })
    );
  };

  const handleStaffRedirection = (staffManagement) => {
    history.push(`/staff-management/staff-details/${staffManagement._id}`, {
      user_id: staffManagement?.user?._id,
      doctor_id: staffManagement?.doctor?._id,
    });
  };

  const sidebarPanelState = useSelector(
    (state) => state.sidebarPanelReducer.isSidePanel
  );
  const showSidePanel = () => {
    dispatch(sidebarPanelAction(!sidebarPanelState));
  };

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );

  return (
    <>
      {loader && <LoaderUI overlay />}
      {isAddHoliday && (
        <AddHoliday
          onHide={() => {
            setIsAddHoliday(false);
          }}
        />
      )}
      <div className="page-wrapper position-relative d-flex flex-column flex-fill overflow-auto">
        <div className="page-header-wrapper d-flex align-items-center flex-shrink-0 right-column-visible">
          <Button
            className="sidebar-toggle-btn d-inline-flex align-items-center"
            color="link"
            onClick={showSidePanel}
          >
            <span className="material-symbols-outlined">menu</span>
          </Button>
          <div className="row-block d-flex align-items-center flex-fill">
            <PageHeader
              className="dashboard-head flex-flex-shrink-0"
              title="Staff Management"
            />
            {permissions?.edit_staff_management && activeTab === "1" && (
              <div className="right-col ms-auto">
                <div className="btn-blc">
                  <Button
                    color="success"
                    onClick={() => {
                      setNewUser(true);
                    }}
                    className="add-btn"
                  >
                    Add new
                  </Button>
                </div>
              </div>
            )}
            {activeTab === "3" && (
              <div className="right-col ms-auto">
                <div className="btn-blc">
                  <Button
                    color="success"
                    onClick={() => {
                      setIsAddHoliday(true);
                    }}
                    className="h-40 ps-3 pe-3"
                  >
                    Add Holiday
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="tab-wrapper tab-wrapper-staff-detail d-flex flex-column flex-fill overflow-auto">
          <Nav tabs className="flex-shrink-0">
            <NavItem>
              <NavLink
                className={{ active: activeTab === "1" }}
                onClick={() => {
                  setActiveTab("1");
                }}
              >
                Staff
              </NavLink>

              <NavLink
                className={{ active: activeTab === "2" }}
                onClick={() => {
                  setActiveTab("2");
                }}
              >
                Schedule Requests
              </NavLink>
              <NavLink
                className={{ active: activeTab === "3" }}
                onClick={() => {
                  setActiveTab("3");
                }}
              >
                Clinic Availability
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab} className="overflow-auto">
            {activeTab === "1" && (
              <>
          
                  <div className="page-content d-flex flex-column flex-fill overflow-auto">
                    <TableListStaffManagementFilterWrapper
                      className="responsive"
                      setFilterPayload={setFilterPayload}
                      filterPayload={filterPayload}
                    />


                    {!loader &&
                                    (!staffManagementUsers?.docs ||
                                      staffManagementUsers?.docs?.length <= 0) ? (
                                      <NoDataFoundBlock type={   filterPayload?.status !== "" || 
                                      filterPayload?.searchText !== "" ||
                                      filterPayload?.staff_role !== "" ||
                                      filterPayload?.start_date !== "" ||
                                      filterPayload?.end_date !== "" ? 'search result' : ''} />
                                    ) : (
                    <div className="table-listing-blc overflow-auto">
                      <Table responsive className="checkbox-listing">
                        <thead>
                          <tr>
                            <th>
                              <CheckBox
                                type="checkbox"
                                ischecked={false}
                                className="no-label"
                                onChange={() => {}}
                              />
                            </th>
                            <th style={{ minWidth: "222px" }}>Title</th>
                            <th>Gender</th>
                            <th>Status</th>
                            <th>Start Date</th>
                            <th>Type</th>
                            <th style={{ minWidth: "216px" }}>Phone</th>
                            <th>Email</th>
                            <th style={{ minWidth: "235px" }}>Address</th>

                            <th style={{ minWidth: "150px" }}>City</th>
                            <th className="text-end">&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          {staffManagementUsers?.docs?.length &&
                            staffManagementUsers?.docs?.map(
                              (staffManagement, index) => (
                                <tr key={index}>
                                  <td>
                                    <CheckBox
                                      type="checkbox"
                                      ischecked={false}
                                      className="no-label"
                                      onChange={() => {}}
                                    />
                                  </td>
                                  <td>
                                    <Button
                                      color="link"
                                      className="p-0 text-start pic-cell d-inline-flex align-items-center"
                                      onClick={() =>
                                        handleStaffRedirection(staffManagement)
                                      }
                                    >
                                      <div className="pic rounded-circle d-inline-flex overflow-hidden">
                                        {staffManagement?.user?.user_image ? (
                                          <img
                                            className="img-fluid"
                                            src={
                                              staffManagement?.user?.user_image
                                            }
                                            alt=""
                                          />
                                        ) : (
                                          <UserPlaceholder />
                                        )}
                                      </div>
                                      {staffManagement?.user?.firstname}{" "}
                                      {staffManagement?.user?.lastname}
                                    </Button>
                                  </td>
                                  <td>
                                    {staffManagement?.user?.gender ? (
                                      <>
                                        {capitalizeFirstLetter(
                                          staffManagement?.user?.gender
                                        )}
                                      </>
                                    ) : (
                                      <>--</>
                                    )}{" "}
                                  </td>
                                  <td>
                                    <span
                                      className={`status-cell ${
                                        !staffManagement?.user?.active &&
                                        "inactive-cell"
                                      }`}
                                    >
                                      {staffManagement?.user?.active
                                        ? "Active"
                                        : "Inactive"}
                                    </span>
                                  </td>
                                  <td>
                                    {moment(
                                      staffManagement?.user?.createdAt
                                    ).format("MM/DD/YYYY")}
                                  </td>
                                  <td>
                                    {staffManagement?.user_type &&
                                      capitalizeFirstLetter(
                                        staffManagement?.user_type
                                      )}
                                  </td>
                                  <td>
                                    {formatPhoneNumber(
                                      staffManagement?.user?.phone
                                    ) || "--"}
                                  </td>
                                  <td>{staffManagement?.user?.email}</td>
                                  <td>
                                    {staffManagement?.user_contact?.line1 ||
                                      "--"}
                                  </td>
                                  <td>
                                    {staffManagement?.user_contact?.city
                                      ? staffManagement?.user_contact?.city
                                      : "--"}
                                  </td>
                                  {permissions?.edit_staff_management && (
                                    <td className="action-cell">
                                      <Button color="link" className="edit-btn">
                                        <span className="icomoon-pencil"></span>
                                      </Button>
                                      <ButtonDropdown
                                        isOpen={dropdownOpen}
                                        toggle={toggle}
                                      >
                                        <DropdownToggle
                                          color="link"
                                          className="more-btn"
                                        >
                                          <span className="icomoon-more"></span>
                                        </DropdownToggle>
                                      </ButtonDropdown>
                                    </td>
                                  )}
                                </tr>
                              )
                            )}
                        </tbody>
                      </Table>
                      <PaginationBlock
                        limit={limit}
                        page={page}
                        pagingCounter={staffManagementUsers?.pagingCounter || 1}
                        docLength={staffManagementUsers?.docs?.length || 0}
                        docPage={staffManagementUsers.page || 1}
                        totalPages={staffManagementUsers.totalPages || 1}
                        totalDocs={staffManagementUsers.totalDocs || 0}
                        _handleLimit={(val) => {
                          setLimit(val);
                        }}
                        _handlePageChange={setPage}
                      />
                    </div>  )}
                  </div>
              </>
            )}
            {activeTab === "2" && <ScheduleRequests />}
            {activeTab === "3" && <ClinicAvilability />}
          </TabContent>
        </div>
      </div>

      {newUser && (
        <NewUserModal
          refresh={getData}
          openViewBlock={(value) => setNewUser(value)}
        />
      )}
    </>
  );
};

export default StaffManagement;
