import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Table,
} from "reactstrap";
import Species from "./Species";
import Breeds from "./Breeds";




const ReferenceData = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("0");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  

  return (
    <>
     
          <div className="tab-wrapper tab-wrapper-inventory  d-flex flex-column flex-fill">
            <Nav tabs className="flex-shrink-0">
              <NavItem>
                
                  <NavLink
                    className={{ active: activeTab === "0" }}
                    onClick={() => {
                      toggle("0");
                    }}
                  >
                    Species
                  </NavLink>
               
                  <NavLink
                    className={{ active: activeTab === "1" }}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Breeds
                  </NavLink>
              </NavItem>
             
            </Nav>
            <TabContent
              activeTab={activeTab}
              className="flex-fill"
            >
              <TabPane tabId="0">
                <Species />
              </TabPane>
              <TabPane tabId="1">
                <Breeds />
              
              </TabPane>
            </TabContent>
          </div>
              </>
  );
};

export default ReferenceData;
