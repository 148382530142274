import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Button, Table } from "reactstrap";
import { useHistory } from "react-router";
import "./index.scss";
import { useState } from "react";
import PatientProfile from "./PatientProfile";
import NodataFoundBlock from "../../NoDataFoundBlock";
import ImagePlaceholder from "../../../shared/ImagePlaceholder";
import { appointmentDetailPanelReducer } from "../../../context/reducers/appointmentDetailReducer";
import {
  setAppointmentDetailData,
  setAppointmentDetailPanel,
} from "../../../context/actions/appointmentDetailPanelAction";
import { getPetDetailsById } from "../../../context/actions/patient-module/patient/pet";
import ScheduleInformation from "./ScheduleInformation";
import ScheduleStatus from "./ScheduleStatus";
import VitalsAndNotes from "./VitalsAndNotes";
import PatientIntake from "./PatientIntake";
import {
  updateAppointmentDetails,
  getAppointmentDetails,
} from "../../../context/actions/appointment";
import { getAppointmentTimingDisplayValues } from "utils/calendar/constant";
import Spinner from "shared/Spinner";
import ChatRoom from "components/video-call-module/ChatRoom";
import { collection, doc, getDocs, query, where } from "firebase/firestore";
import db from "./../../../firebase";
import {
  fetchChatMessages,
  getSelectedRoomData,
} from "context/actions/realTimeChat";

const AppointmentDetailsPanel = ({
  className = "",
  rightSideCanvasShow = () => {},
}) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.appointmentDetailPanelReducer.data);
  const [appointmentData, setAppointmentData] = useState(false);
  const [updates, setUpdates] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [canSendMessage, setCanSendMessage] = useState(false);
  const [roomId, setRoomId] = useState(null);
  const closePanel = () => {
    dispatch(setAppointmentDetailPanel(false));
    rightSideCanvasShow();
  };
  const appointmentDetailsState = useSelector(
    (state) => state.appointmentDetailPanelReducer.isAppointmentDetailPanel
  );
  const handleStart = () => {
    if (appointmentData) {
      setUpdates({ appointment_start: new Date() });

      if (appointmentData?.appointment_type === "video-call") {
        const win = window.open(
          `/video-chat/${appointmentData?._id}`,
          "_blank"
        );
        win.focus();
        //history.push(`/video-chat/${appointmentData?._id}`)
      }
    }
  };
  useEffect(() => {
    setUpdates(false);
  }, []);

  const fetchAppointmentChatDetails = async (id) => {
    const q = query(
      collection(db, "chatRooms"),
      where("appointment_id", "==", id || "")
    );
    const querySnapshot = await getDocs(q);

    const roomId = querySnapshot.docs[0].id;
    const roomsRef = doc(db, "chatRooms", roomId);
    dispatch(
      fetchChatMessages(roomsRef, "", (res) => {
        if (res) {
          dispatch(
            getSelectedRoomData("group", roomId, (res) => {
              setRoomId(roomId);
            })
          );
        }
      })
    );
  };

  useEffect(() => {
    if (data && updates) {
      dispatch(
        updateAppointmentDetails(data?._id, updates, () => {
          dispatch(
            getAppointmentDetails(data?._id, (res) => {
              dispatch(setAppointmentDetailData(res));
              setAppointmentData(res);
            })
          );
        })
      );
    }
  }, [updates]);

  useEffect(() => {
    setUpdates(false);
  }, []);

  useEffect(() => {
    if (data) {
      setIsLoading(true);
      setRoomId(null);
      dispatch({ type: "MESSAGE_LIST", payload: [] });
      dispatch(
        getAppointmentDetails(data?._id, async (res) => {
          setUpdates(false);
          setAppointmentData(res);
          setIsLoading(false);
          if (res.appointment_type === "video-call") {
            if (appointmentDetailsState) {
              fetchAppointmentChatDetails(data?._id);
            }
            if (
              !res.appointment_end ||
              (res.appointment_end &&
                moment().isBefore(
                  moment(res?.appointment_end).add(48, "hours")
                ))
            ) {
              setCanSendMessage(true);
            } else {
              setCanSendMessage(false);
            }
          }
        })
      );
    }
  }, [data]);

  /*
     useEffect(() => {
       if(appointmentData)
         dispatch(getPetDetailsById(appointmentData?.patient_id?._id, {}, () => { }))
     }, [appointmentData])
     this is currently grabbing wrong pet details when patient is selected from normal patient select
   */

  return (
    <>
      <div
        className={`appointment-detail-panel-wrapper position-fixed d-flex flex-column overflow-auto 
			${className}
			
			`}
      >
        {isLoading ? (
          <Spinner height={150} />
        ) : (
          <>
            <div className="head d-flex align-items-center">
              {/* <h2>{`Schedule ${moment(data.appointment_date, 'DD/MM/YYYY').format('MM/DD')} ${data.appointment_time} `}</h2> */}
              <h2>{`Schedule ${moment(
                appointmentData?.appointment_date,
                "DD/MM/YYYY"
              ).format("MM/DD")} ${getAppointmentTimingDisplayValues(
                appointmentData?.appointment_timings
              ).join(", ")} `}</h2>
              <Button
                color="link"
                className="ms-auto panel-close-btn"
                onClick={closePanel}
              >
                <span className="material-symbols-outlined">close</span>
              </Button>
            </div>

            <div className="inner overflow-auto">
              <div className="d-flex flex-row gap-[10px]">
                <div className="d-flex flex-column">
                  <PatientProfile petId={appointmentData?.patient_id} />
                </div>
                <div className="d-flex schedule-cards flex-column">
                  <ScheduleInformation
                    appointment_data={appointmentData}
                    handleStop={() => {
                      setUpdates({ appointment_end: new Date() });
                    }}
                    handleStart={handleStart}
                  />
                  <ScheduleStatus
                    data={appointmentData.appointment_status}
                    handleUpdate={(val) => {
                      setUpdates({ appointment_status: val });
                    }}
                  />
                  <VitalsAndNotes
                    appointmentData={appointmentData}
                    handleNoteSave={(note) => {
                      setUpdates({ notes: note });
                    }}
                  />
                  {appointmentData?.patient_intake_forms?.length > 0 && (
                    <PatientIntake
                      data={appointmentData?.patient_intake_forms}
                    />
                  )}
                  {roomId &&
                    appointmentData?.appointment_type === "video-call" && (
                      <div className="appointment-chat ">
                        <ChatRoom
                          roomId={roomId}
                          isFullWidth={true}
                          canSendMessage={canSendMessage}
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AppointmentDetailsPanel;
