import { updateLegalInformation } from "context/actions/clinic";
import { getClinicSettings } from "context/actions/clinicSettings";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup } from "reactstrap";
const LegalInfoFormBlock = () => {
  const dispatch = useDispatch();
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );

  const [legalInfo, setLegalInfo] = useState({
    clinic_id: clinic_settings?.clinic_id,
    update_query: "legal_info",
    legal_name: clinic_settings?.legal_info?.legal_name,
    tax_id_number: clinic_settings?.legal_info?.tax_id_number,
  });

  const HandleChange = (e) => {
    const { name, value } = e.target;
    setLegalInfo((prv) => ({ ...prv, [name]: value }));
  };

  const handleInputBlur = async () => {
    try {
      await dispatch(updateLegalInformation(legalInfo));
      dispatch(getClinicSettings());
    } catch (error) {
      console.error("Error updating general info:", error);
    }
  };

  return (
    <>
      <div className="form-block">
        <FormGroup className="input-wrapper2">
          <div className="form-floating position-relative text-input">
            <input
              disabled={clinic_settings.edit_clinic_settings}
              type="text"
              className="form-control"
              id="registeredLegalName"
              name="legal_name"
              placeholder="Enter Clinician Name"
              value={legalInfo?.legal_name}
              onChange={HandleChange}
              onBlur={handleInputBlur}
            />
            <label for="registeredLegalName">Registered legal name</label>
          </div>
        </FormGroup>
        <FormGroup className="input-wrapper2">
          <div className="form-floating position-relative text-input">
            <input
              disabled={clinic_settings.edit_clinic_settings}
              type="text"
              className="form-control"
              name="tax_id_number"
              id="Taxdentificationnumber"
              placeholder="Enter First Name"
              value={legalInfo?.tax_id_number}
              onChange={HandleChange}
              onBlur={handleInputBlur}
            />
            <label for="Taxdentificationnumber">
              Tax Identification number
            </label>
          </div>
        </FormGroup>
      </div>
    </>
  );
};

export default LegalInfoFormBlock;
