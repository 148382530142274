import React, {useState} from "react"
import { Button } from "reactstrap"


const IOTableData = ({results, onClick, id, key }) => {
    const [isHovering, setIsHovering] = useState(false) 

    const sumResults = () => {
        let sum = 0;
        results?.map(res => sum += res?.value)
        return sum
    }



    return(

        <td key={key} id={id} onClick={onClick} onMouseOver={() =>{setIsHovering(true)}} onMouseLeave={() => {setIsHovering(false)}}>
            {results?.length === 0 ? 
                <Button color="link" style={isHovering ? {display: 'inline-flex'} : { visibility: 'hidden'}} className="add-btn">
                    <span className="icomoon-plus-btn"></span>
                </Button>
                :
                <div>{sumResults()}</div>
            }
        </td> 
        )
}

export default IOTableData