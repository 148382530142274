import React, { useEffect, useState } from "react";
import CalendarFilter from "../../shared/Calendar/CalendarFilter";
import "../../shared/Calendar/CalendarFilter/index.scss";
import { useDispatch, useSelector } from "react-redux";
import PatientListPanel from "../../shared/Calendar/PatientListPanel";
import { patientListPanelAction } from "../../context/actions/patientListPanelAction";
import PageHeader from "../../shared/PageHeader";
import AddBoarding from "./AddBoarding";
import moment from "moment";
import { getKennels } from "../../context/actions/kennels";
import { CalendarWeekViewBoarding } from "../../shared/Calendar/CalendarWeekView/CalendarWeekViewBoarding";
import { Button } from "reactstrap";
import DayData from "./DataView/DayData";
import MonthData from "./DataView/MonthData";
import ViewEditBoarding from "./ViewEditBoarding";
import CalendarFoot from "shared/Calendar/CalendarFoot";
import { getBoardingByClinicId, getBoardingByClinicIdWithDate } from "context/actions/boarding";

const UpdatedBoarding = () => {
  const dispatch = useDispatch();
  const { clinic_details } = useSelector((state) => state.clinicReducer);
  const [calendarView, setCalendarView] = useState("month");
  const [calendarViewData, setCalendarViewData] = useState({
    span_date: new Date().getDate(),
    date_value: new Date().getDate(),
    month_value: new Date().getMonth(),
    span_month: new Date().getMonth(),
    year_value: new Date().getFullYear(),
  });
  const [selectedPatient, setSelectPatient] = useState();
  const [selectedBoarding, setBoarding] = useState();
  const [show, setShow] = useState(false);
  const [view, setView] = useState(false);

  const [kennelInfo, setKennelInfo] = useState();
  const [isPetAddedToTheKennel, setIsPetAddedToTheKennel] = useState(false);
  const patientListPanelState = useSelector(
    (state) => state.patientListPanelReducer.isPatientListPanel
  );
  const allKennels = useSelector((state) => state?.kennelReducer?.kennels);
  
  const openPatientPanel = () => {
    dispatch(patientListPanelAction(!patientListPanelState));
  };

  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  // these function will reuse in future
  const filterList = () => {
    const { date_value, month_value, year_value } = calendarViewData;
    const selectedDate = new Date();
    selectedDate.setDate(parseInt(date_value));
    selectedDate.setMonth(parseInt(month_value) - 1);
    selectedDate.setFullYear(parseInt(year_value));
    const startDate = new Date(selectedDate);
    startDate.setDate(startDate.getDate() - selectedDate.getDay());
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);

    const filteredList = kennelInfo.filter((item) => {
      // const itemDate = new Date(item.date);
      const itemDate = new Date(item?.boarding_date);
      if (calendarView === "day") {
        return itemDate.getDay() === selectedDate.getDay();
      }
      if (calendarView === "month") {
        return (
          itemDate.getMonth() === selectedDate.getMonth() &&
          itemDate.getFullYear() === selectedDate.getFullYear()
        );
      }
      if (calendarView === "week") {
        return itemDate >= startDate && itemDate <= endDate;
      }
      return true;
    });
    return filteredList;
  };

  const fetchData = async () => {

    const { date_value, month_value, year_value } = calendarViewData;

    const dateFormate = `${year_value}-${
      month_value <= 9 ? "0" + month_value : month_value
    }-${date_value}`;

    const dateFormat = moment(dateFormate).toISOString();

    try {
      let response;
      if (calendarView === "day") {
        dispatch(getBoardingByClinicIdWithDate(dateFormat, response => setKennelInfo(response)))
      } else {
        dispatch(getBoardingByClinicId(response => setKennelInfo(response)))
      }

    } catch (error) {
      console.log("error in fetching boarding data", error.message);
    }
  };

  
  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarView, calendarViewData, isPetAddedToTheKennel]);

  useEffect(() => {
    dispatch(
      getKennels(clinic_details?._id, (res) => {
        console.log("kennels", res);
      })
    );
  }, []);

  const addPatientOnBoarding = (petId) => {
    setSelectPatient(petId);
    dispatch(patientListPanelAction(!patientListPanelState));
    setShow(true);
  };

  const selectPatientAgain = () => {
    setSelectPatient(false)
    dispatch(patientListPanelAction(!patientListPanelState));
    setShow(false)
  }

  function getKennelsName() {
    return allKennels.map((k) => k.kennel_name);
  }

  return (
    <div className="page-wrapper position-relative d-flex flex-column flex-fill overflow-auto">
      <div className="page-header-wrapper d-flex align-items-center flex-shrink-0 pb-0 right-column-visible">
        <div className="row-block d-flex align-items-center flex-fill">
          <PageHeader
            className="dashboard-head flex-flex-shrink-0"
            title="Boarding"
          ></PageHeader>
          {permissions?.edit_boarding && (
            <div className="right-col ms-auto">
              <div className="btn-blc">
                <Button
                  color="success"
                  className="add-btn"
                  onClick={openPatientPanel}
                >
                  Add new
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="page-content no-header d-flex flex-column flex-fill overflow-auto pb-0">
        <CalendarFilter
          sendCalendarView={(value) => {
            setCalendarView(value);
          }}
          calendarViewValue={(value) => setCalendarViewData(value)}
        />

        <div className="calendar-wrapper d-flex flex-column flex-fill overflow-auto">
          {calendarView === "month" ? (
            <MonthData
              setBoarding={(val) => {
                setView(true);
                setBoarding(val);
              }}
              calendarViewData={calendarViewData}
              appointments={kennelInfo}
              month={calendarViewData.month_value}
            />
          ) : calendarView === "week" ? (
            <CalendarWeekViewBoarding
              setBoarding={(val) => {
                setView(true);
                setBoarding(val);
              }}
              isBoarding={true}
              calendarViewData={calendarViewData}
              kennelData={kennelInfo}
              allKennels={allKennels}
            />
          ) : (
            <DayData
              setBoarding={(val) => {
                setView(true);
                setBoarding(val);
              }}
              columns={getKennelsName()}
              data={kennelInfo}
              dateVal={`${calendarViewData.year_value}-${(
                "0" + calendarViewData.month_value
              ).slice(-2)}-${("0" + calendarViewData.date_value).slice(-2)}`}
            />
          )}
        </div>
      </div>
      <div
        className={`patient-panel-overlay position-fixed ${
          patientListPanelState ? "active" : ""
        }`}
        onClick={() => dispatch(patientListPanelAction(false))}
      ></div>
      <PatientListPanel
        className={`${patientListPanelState ? "active" : ""}`}
        clinicId={clinic_details?._id}
        callable={addPatientOnBoarding}
      />
      {show && (
        <AddBoarding
          kennels={allKennels}
          petId={selectedPatient}
          show={show}
          changePatient={selectPatientAgain}
          setShow={setShow}
          isPetAddedToTheKennel={isPetAddedToTheKennel}
          setIsPetAddedToTheKennel={setIsPetAddedToTheKennel}
        />
      )}

      {view && (
        <ViewEditBoarding
          setShow={setView}
          update={fetchData}
          data={selectedBoarding}
        />
      )}
    </div>
  );
};

export default UpdatedBoarding;

const PetImage = ({ src }) => {
  return (
    <img
      src={src}
      alt="pet-img"
      className="w-14 h-full max-h-[100px] object-cover rounded-md"
    />
  );
};

const PetName = ({ name }) => {
  return (
    <p className="font-semibold text-sm mb-2 md:text-base xl:text-lg">
      {name}
    </p>
  );
};

const PetGender = ({ gender }) => {
  return (
    <p className="capitalize text-xs lg:text-sm xl:text-base mb-2">{gender}</p>
  );
};

const PetTypeAndBreed = ({ type, breed }) => {
  return (
    <p className="text-xs mb-2">
      {type} {breed && <span>({breed})</span>}
    </p>
  );
};

const PetPurpose = ({ purpose }) => {
  return <p className="text-sm font-medium mb-2">{purpose}</p>;
};

const ExpectedDischargeDate = ({ expectedDischargeDate }) => {
  return (
    <p className="text-sm mb-2">
      Expected Discharge date
      <br />
      <span className="mt-1">
        {/* {new Date(expectedDischargeDate).toLocaleDateString()} */}
        {expectedDischargeDate?.replace(/-/g, "/")}
      </span>
    </p>
  );
};

// eslint-disable-next-line no-unused-vars
const BoardingCard = ({ obj }) => {
  // console.log("boarding img",obj)
  return (
    <div className="flex flex-col items-center text-center">
      <PetImage src={obj.pet_image} />
      <PetName name={obj.pet_name} />
      {obj.gender && <PetGender gender={obj.gender} />}
      {obj.type && <PetTypeAndBreed type={obj.type} breed={obj.breed} />}
      {obj.boarding_purpose && <PetPurpose purpose={obj.boarding_purpose} />}
      {obj.boarding_date && (
        <ExpectedDischargeDate expectedDischargeDate={obj.boarding_date} />
      )}
    </div>
  );
};
