import React, { useState, useEffect } from 'react'
import { Offcanvas, Spinner } from 'react-bootstrap'
import './index.scss';
import { Button, Input, InputGroup } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import PasswordInput from '../../../../../shared/FormsInput/PasswordInput';
import { addIntegrationCredential, removeIntegrationCredential } from 'context/actions/clinicSettings';
import { updateIntegrationCredential } from 'context/actions/clinicSettings';
import { getAntechClinicResult } from '../../../../../context/actions/patient-module/diagnostic-testing';
import { loginSchema } from './schema';
import { Formik, Form } from 'formik';

const EditApp = ({ show, handleClose, integration }) => {


    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false);




    const dispatch = useDispatch();
    const { clinic_details } = useSelector(state => state.clinicReducer);


    const handleRemove = () => {

        setLoading(true)

        dispatch(removeIntegrationCredential(integration?._id, (response) => {
            setLoading(false);
            if(response?.status){
                handleClose();
            }

        }))

    }


    const viewAntechClinicLevel = () => {
        dispatch(getAntechClinicResult((res) => {
            if(res?.status && res?.response_data?.uiURL){
     
                dispatch({
                  type: "SET_LAB_RESULTS_VIEWER_DATA",
                  data: {
                    title: 'Antech Clinic Record',
                    url: res?.response_data?.uiURL
                  },
                });
      
            }
        }));
    }

    const submitForm = (data) => {

        setLoading(true)

        dispatch(updateIntegrationCredential({app: integration?.app, _id: integration?._id, ...data}, (response) => {
            setLoading(false);
            if(response?.status){
                handleClose();
            }
        }));



    }

   

    return (

        <Offcanvas show={show} onHide={handleClose} placement="end" className="select-group-list addnewrole gap-[20px]">
            <Offcanvas.Header closeButton className="">
                <Offcanvas.Title>Edit {integration?.app} Integration</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="px-2 pb-2 pt-0">
                <div className="d-flex  flex-column h-100">
                <Formik
							initialValues={{ClinicID: integration?.ClinicID,  username: integration?.username, frequency_value: integration?.frequency_value, frequency_unit: integration?.frequency_unit, partial_results: integration?.partial_results, view_pdf: integration?.view_pdf}}
							enableReinitialize
							validationSchema={loginSchema}
							onSubmit={submitForm}
						>
							{(formik) => (<Form style={{gap: '20px', display:'flex', flexDirection:'column'}}>

                            
								<Input
									name="username"
									type="text"
									placeholder="Enter Username"
									id="username"
									value={formik?.values?.username}
									onChange={formik.handleChange}
									icon={'icon'}
									required={true}
									className="username"
								/>
								<PasswordInput
									type="password"
									name="password"
									placeholder="Enter password"
									id="password"
									value={formik?.values?.password}
									onChange={formik.handleChange}
									icon={'icon'}
									required={false}
								/> 

                                {integration?.app === 'Idexx' &&
                                
                                <>

                                <div className="item-rw d-flex">
                                    <div className="form-check form-switch">
                                        <input name="partial_results" onChange={e => formik.setFieldValue('partial_results', e.target.checked)} checked={formik?.values?.partial_results} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                    </div>
                                    <div className="txt">
                                        <h4>Allow Partial Results</h4>
                                    </div>
                                </div>


                                <div className="item-rw d-flex">
                                    <div className="form-check form-switch">
                                        <input name="view_pdf" onChange={e => formik.setFieldValue('view_pdf', e.target.checked)} checked={formik?.values?.view_pdf} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                    </div>
                                    <div className="txt">
                                        <h4>View Result PDF</h4>
                                    </div>
                                </div>


                        
                                <label>Fetch Results Every:</label>

                                <InputGroup>

                                    <Input
                                    name="frequency_value"
                                    value={formik?.values?.frequency_value}
                                    placeholder="Enter Frequency"
                                    onChange={formik.handleChange}
                                    type="Number"
                                    />
                                    <Input
                                    placeholder="Select Frequency Unit"
                                    onChange={formik.handleChange}
                                    name={'frequency_unit'}
                                    value={formik?.values?.frequency_unit}
                                    type="select"
                                    >
                                    <option value="seconds">seconds</option>
                                    <option value="minutes">minutes</option>
                                </Input>
                        </InputGroup>
                                
                                
                                
                                
                                </>


                                }



                                    {integration?.app === 'Antech' &&       

                                    <>

                                    <label>Antech Clinic Id:</label>

                                    <Input
                                    name="ClinicID"
                                    value={formik?.values?.ClinicID}
                                    placeholder="Enter Clinic Id"
                                    onChange={formik.handleChange}
                                    type="String"
                                    />
                                    </>}



                                {integration?.app === 'Antech' && (
                                                    <div style={{maxWidth: '200px'}} className="share-report  border border-1 border-gray-100 rounded-lg text-[12px] font-semibold text-center justify-center mt-[10px] pt-[3px] pb-[3px]">
                                                        <button onClick={() => {viewAntechClinicLevel()}}>VIEW ALL RESULTS</button>
                                                    </div>)}


								<div className="btn-cell d-flex">
									<Button
										disabled={disabled}
										color="primary"
                                        type="submit"
									>
										Update {loading && <Spinner size="sm" className="ms-1" />}
									</Button>
								</div>
							</Form>)}
						</Formik>



                        <div className="btn-cell d-flex mt-[10px]">
                            <Button
                                color="danger"
                                onClick={handleRemove}
    
                            >
                                Remove {loading && <Spinner size="sm" className="ms-1" />}
                            </Button>
                        </div>



   

                </div>
            </Offcanvas.Body>
        </Offcanvas>

    )
}

export default EditApp;