import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { getSurgicalHistoryList } from "../../../../../../context/actions/patient-module/medical-history";
import UserPlaceholder from "../../../../../../shared/UserPlaceholder";
import { dateFromDateTime } from "../../../../../../utils/common";
import MedicalBreifBox from "../MedicalBreifBox";
import "./index.scss";
import NodataFoundBlock from "../../../../../../shared/NoDataFoundBlock";

const SurgicalHistoryBlock = ({ petId, add, viewAll }) => {
  const { permissionsData } = useSelector(
    (state) => state.clinicPermissionReducer
  );
  const permissions = permissionsData;
  const { surgicalHistory } = useSelector(
    (state) => state.medicalHistoryReducer
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [surgicalCardLength, setSurgicalCardLength] = useState(3);

  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );

  useEffect(() => {
    if (petId) {
      dispatch(
        getSurgicalHistoryList(petId, {}, () => {
          setLoading(false);
        })
      );
    }
  }, [petId]);
  return (
    <>
      <div className="surgical-history-block">
        <div className="head section-head d-flex align-items-center">
          <div className="left-col">
            <h3>Surgical History</h3>
          </div>
          <div className="right-col ms-auto d-inline-flex align-items-center">
            {permissions?.edit_medical_history && viewAll ? (
              surgicalHistory?.length > 3 && (
                <div
                  className="text-14-400 color-2e65 pointer"
                  onClick={() => {
                    if (surgicalHistory?.length > 3) {
                      if (surgicalCardLength === 3) {
                        setSurgicalCardLength(surgicalHistory?.length);
                      } else {
                        setSurgicalCardLength(3);
                      }
                    }
                  }}
                >
                  {surgicalCardLength === 3 ? "View All" : "View less"}
                </div>
              )
            ) : (
              <Button onClick={() => add()} color="link" className="add-btn">
                <i className="icomoon-plus-btn"></i>
              </Button>
            )}
          </div>
        </div>
        <div className="inner ">
          <div className="surgical-items-rw d-flex overflow-auto">
            {surgicalHistory?.length >= 1 ? (
              (surgicalHistory || [])
                ?.slice(
                  0,
                  viewAll ? surgicalCardLength : surgicalHistory?.length
                )
                ?.map((item, index) => {
                  let doctorFirstName =
                    clinic_settings?.doctor_name_format === 0
                      ? item?.doctor_details?.lastname
                      : item?.doctor_details?.firstname;
                  let doctorLastName =
                    clinic_settings?.doctor_name_format === 0
                      ? item?.doctor_details?.firstname
                      : item?.doctor_details?.lastname;
                  return (
                    <div key={index} className="item-col">
                      <MedicalBreifBox
                        data={{
                          title: item?.type,
                          description: item?.notes,
                          // nameWithTitle: `${item?.doctor_details?.title ? item?.doctor_details?.title + ' ' : ''} ${doctorFirstName} ${doctorLastName}`,
                          nameWithTitle: `${clinic_settings?.doctor_name_format === 0
                              ? `${item?.doctor_details?.title
                                ? item?.doctor_details?.title + " "
                                : ""
                              } 
						
						 ${doctorLastName}  ${" ,"} ${doctorFirstName}
						`
                              : `${item?.doctor_details?.title
                                ? item?.doctor_details?.title + " "
                                : ""
                              } 
						
						${doctorFirstName} ${doctorLastName}
						`
                            }`,
                          image: item?.doctor_details?.user_image,
                          date: dateFromDateTime(item?.date, "MM/DD/yyyy"),
                        }}
                      />
                    </div>
                  );
                })
            ) : (
              <NodataFoundBlock type={'medical history'} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(SurgicalHistoryBlock);
