const initState = {
    loading: false,
    supplierData: false
}

export const supplierReducer = (state = initState, action) => {
    switch (action.type) {
        case "SUPPLIER_LOADING":
            return { ...state, loading: action.payload }

        case "GET_SUPPLIERS":
            return { ...state, supplierData: action.payload }

        default:
            return state
    }
}