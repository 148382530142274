import * as Yup from "yup";
/**
 * @description declaration form schema for client validations
 */

export const noteSchema = Yup.object().shape({
  
    sections: Yup.array().required('Sections are required')
   
});

/**
 * @description params intilaization 
 */

export const noteInit = {

  // extends: null,
   sections: []
}