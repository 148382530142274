import moment from "moment";
// import { getAppointmentTimingDisplayValues } from "utils/calendar/constant";
import { IoMdTime } from "react-icons/io";
import { RiMapPinRangeLine } from "react-icons/ri";
import autoMode from "../../../../assets/img/autoMode.png";
import "./Upcoming.scss";
import { Button } from "reactstrap";

const Upcoming = ({ data, setId }) => {
  console.log("upcoming data", data);
  // const {
  //   _id,
  //   appointment_date,
  //   slot_time,
  //   doctor_id,
  //   // appointment_timings,
  //   clinic,
  //   reason,
  //   description,
  //   appointment_type,
  // } = data || {};
  return (
    <>
      {data?.map((elem, index) => {
        const {
          _id,
          appointment_date,
          slot_time,
          doctor_id,
           appointment_timings,
          clinic,
          reason,
          description,
          appointment_type,
        } = elem;
        return (
          <div
            key={index}
            id="upcoming-container"
            onClick={() => {
              setId(_id);
            }}
          >
            <div className="time-to-start-text text-13-500">
              Starts {" "}
              {moment(
                appointment_date + " " + appointment_timings[0]?.slot_time,
                "DD-MM-YYYY h:mm a"
              ).fromNow()}
            </div>
            <div className="d-flex gap-2 mt-2">
              <div className="f-center bg-eeee color-cfcf h-49 w-49 rounded-circle">
                {doctor_id?.user_image ? (
                  <img src={doctor_id?.user_image} alt="badge-img" />
                ) : (
                  <span className="material-symbols-outlined text-24-600">
                    person
                  </span>
                )}
              </div>
              <div className="flex-grow-1">
                <div className="text-16-400 color-2E65">
                  {doctor_id?.title} {doctor_id?.firstname}{" "}
                  {doctor_id?.lastname}
                </div>
                <div className="text-13-400 color-2E65">DVM</div>
              </div>
              <div className="pt-1">
                <img src={autoMode} alt="load" />
              </div>
            </div>
            <div className="fa-center gap-2 mt-2">
              <div className="calendar-icon">
                <IoMdTime />
              </div>
              <div className="fa-center gap-2 text-14-400 color-2E65">
                {`${moment(elem?.appointment_date, "DD-MM-YYYY ").format(
                  "MMM D, YYYY"
                )}  (${moment(
                  elem?.appointment_date +
                    " " +
                    elem?.appointment_timings?.sort(
                      (a, b) =>
                        moment(
                          `1970-01-01 ${a.slot_time}`,
                          `YYYY-MM-DD hh:mm a`
                        ).valueOf() -
                        moment(
                          `1970-01-01 ${b.slot_time}`,
                          "YYYY-MM-DD hh:mm a"
                        ).valueOf()
                    )?.[0]?.slot_time,
                  "DD-MM-YYYY h:mm a"
                ).fromNow()})`}
              </div>
            </div>
            <div className="fa-center gap-2 mt-2">
              <div>
                <RiMapPinRangeLine />
              </div>
              <div className="text-14-400 color-2E65">{clinic}</div>
            </div>

            {reason && (
              <div className="text-16-500 color-2E65 mt-2">{reason}</div>
            )}
            {description && (
              <div className="text-14-400 color-2E65 mt-2">{description}</div>
            )}
            {appointment_type.includes("video") && (
              <>
                <div className="start-video-btn mt-2">
                  {/* <div className="start-video-text text-13-600">Start Video Call</div> */}
                  <div className="btn-cell d-flex">
                    {elem?.appointment_start ? (
                      elem?.appointment_end ? (
                        <div
                          className={`appointment-status completed w-100 h-100`}
                        >
                          {elem?.appointment_type === "surgery"
                            ? "Surgery"
                            : "Appointment"}{" "}
                          Completed
                        </div>
                      ) : elem?.appointment_type === "video-call" ? (
                        <div
                          // onClick={joinVideoCall}
                          className="appointment-status in-progress w-100 h-100"
                        >
                          Join Video Call
                        </div>
                      ) : (
                        <div className="appointment-status in-progress w-100 h-100">
                          {elem?.appointment_type === "surgery"
                            ? "Surgery"
                            : "Appointment"}{" "}
                          In Progress
                        </div>
                      )
                    ) : (
                      <Button
                        color="primary"
                        className="w-100 h-100"
                        // onClick={() => {
                        //   handleStart();
                        // }}
                      >
                        Start{" "}
                        {elem?.appointment_type === "video-call"
                          ? "Video Call"
                          : "Appointment"}
                      </Button>
                    )}
                  </div>
                </div>
                <div className="f-center gap-3 mt-3">
                  <div className="underline text-14-400 color-2E65 cursor-pointer">
                    Cancel
                  </div>
                  <div className="underline text-14-400 color-2E65 cursor-pointer">
                    Delay
                  </div>
                  <div className="underline text-14-400 color-2E65 cursor-pointer">
                    Message
                  </div>
                </div>
              </>
            )}
          </div>
        );
      })}
    </>
  );
};

export default Upcoming;
