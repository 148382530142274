import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiClient from "../../api-client";
import { apiUrl, PORT } from "../../environment";
import { toasterConfig } from "../../utils/constants";

toast.configure();
/**
 * All Api endpoints to be call
 * Get   -- /inventory/get-all/:clinic_id
 * Get   -- /point-of-sale/get-all/:clinic_id
 * Get   -- /inventory/get-details/:inventory_id
 * Post  -- /inventory/add
 * Post  -- /inventory/add-description
 * Post  -- /inventory/add-tag
 * Post  -- /inventory/remove-tag
 * Post  -- /inventory/add-special-note
 * Post  -- /inventory/remove-special-note
 * Post  -- /inventory/add-package
 * Post  -- /inventory/add-tag-image/:inventory_id
 * Post  -- /inventory/remove-tag-image
 * Put   -- /inventory/update
 * Put   -- /inventory/update-package
 * Put   -- /inventory/update-image/:inventory_id
*/

/**
 * 
 * @param {Object} params
 * @param {Fuction} callback
 * @returns 
 */
export const getIncomingReferralsByClinicId = (params, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, clinicReducer: { clinic_details } } = getState();
        if (clinic_details) {
            //const clinic_id = "634d6d2efed373b11f11eb60";
            ApiClient.post(`${apiUrl}${PORT}/referrals/get-incoming/${clinic_details?._id}`, params, token, dispatch, getState).then((response) => {
                //ApiClient.get(`${apiUrl}${PORT}/referral/get-incoming/${clinic_id}`, params, token).then((response) => {
                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    dispatch({ type: "GET_INCOMING_REFERRALS", payload: response?.response_data })
                    return callback(response);
                } else {
                    //toast.error(response.message, toasterConfig);
                    return callback(response);
                }
            })
        } else {
            return callback(null)
        }
    };
};

/**
 * 
 * @param {Object} params
 * @param {Fuction} callback
 * @returns 
 */
export const getOutgoingReferralsByClinicId = (params, payload, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, clinicReducer: { clinic_details } } = getState();
        if (clinic_details) {
            //const clinic_id = "632192894b4f27858a3d9752";
            ApiClient.post(`${apiUrl}${PORT}/referrals/get-outgoing/${clinic_details?._id}?${params}`, payload, token, dispatch, getState).then((response) => {
                //ApiClient.get(`${apiUrl}${PORT}/referral/get-outgoing/${clinic_id}`, params, token).then((response) => {

                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    dispatch({ type: "GET_OUTGOING_REFERRALS", payload: response?.response_data })
                    return callback(response);
                } else {
                    //toast.error(response.message, toasterConfig);
                    return callback(response);
                }
            })
        } else {
            return callback(null)
        }
    };
};
/**
 * 
 * @param {Object} params
 * @param {Fuction} callback
 * @returns 
 */
export const getClinicListByClinicId = (callback) => {
    return (dispatch, getState) => {
        const { user: { token }, clinicReducer: { clinic_details } } = getState();
        if (clinic_details) {
            ApiClient.get(`${apiUrl}${PORT}/referrals/get-outgoing-clinics/${clinic_details?._id}`, {}, token, dispatch, getState).then((response) => {

                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    return callback(response);
                } else {
                    return callback(response);
                }
            })
        } else {
            return callback(null)
        }
    };
};
/**
 * 
 * @param {Object} params
 * @param {Fuction} callback
 * @returns 
 */
export const getIncomingClinicListClinicId = (callback) => {
    return (dispatch, getState) => {
        const { user: { token }, clinicReducer: { clinic_details } } = getState();
        if (clinic_details) {
            ApiClient.get(`${apiUrl}${PORT}/referrals/get-incoming-clinics/${clinic_details?._id}`, {}, token, dispatch, getState).then((response) => {

                if (!response) {
                    return callback();
                }
                else if (response?.status) {
                    return callback(response);
                } else {
                    return callback(response);
                }
            })
        } else {
            return callback(null)
        }
    };
};


/**
 * 
 * @param {Object} params
 * @param {Fuction} callback
 * @returns 
 */
export const sendReferral = (body, callback) => {
    return (dispatch, getState) => {
        const { user: { token }, clinicReducer: { clinic_details } } = getState();
        if (clinic_details) {
            body.referred_by = clinic_details?._id;
            //body.clin_id = "634d6d2efed373b11f11eb60";
            ApiClient.post(`${apiUrl}${PORT}/referrals/create/`, body, token, dispatch, getState).then((response) => {

                if (!response) {
                    return callback();
                }
                else if (!response && response.errors) {
                    toast.error(response.errors.msg, toasterConfig);
                    return callback(response);
                } else if (response) {
                    toast.dismiss();
                    toast.success(response.msg, toasterConfig);
                    return callback(response);
                } else {
                    //toast.error(response.message, toasterConfig);
                    return callback();
                }
            })
        }
    }
};



/**
 * @description function use to approve referral
 * @param {*} params accepting object
 */
export const approveReferral = (id, data, callback) => {
    return (dispatch, getState) => {

        const { user: { token } } = getState();
        ApiClient.put(`${apiUrl}${PORT}/referrals/approve/${id}`, data, token, dispatch, getState).then((response) => {

            if (!response) {
                return callback();
            }
            else if (!response && response.errors) {
                toast.error(response.errors.msg, toasterConfig);
                return callback(response);
            } else if (response) {
                toast.dismiss();
                toast.success(response.msg, toasterConfig);
                return callback(response);
            } else {
                //toast.error(response.message, toasterConfig);
                return callback();
            }
        });
    };
};






/**
 * @description function use to decline referral
 * @param {*} params accepting object
 */
export const declineReferral = (id, data, callback) => {
    return (dispatch, getState) => {

        const { user: { token } } = getState();
        ApiClient.put(`${apiUrl}${PORT}/referrals/decline/${id}`, data, token, dispatch, getState).then((response) => {

            if (!response) {
                return callback();
            }
            else if (!response && response.errors) {
                toast.error(response.errors.msg, toasterConfig);
                return callback(response);
            } else if (response) {
                toast.dismiss();
                toast.success(response.msg, toasterConfig);
                return callback(response);
            } else {
                //toast.error(response.message, toasterConfig);
                return callback();
            }
        });
    };
};















