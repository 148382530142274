import React, { useEffect } from "react";


const MessageFilter = () => {
    return (
        <>
            <div className="messages-block d-flex flex-row">
                <div className="right-col w-100">
                    <div className="filter-block">
                        <div className="list-section">
                            <div className="title">
                                Filter By
                            </div>
                            <div className="list">
                                <div className="list-item">
                                    <div className="icon"> <img src={require("../../../../../../assets/img/vetRecorEMR/user-thumbnail.png")} /></div>
                                    <div className="name">Dr. Martha William</div>
                                </div>
                                <div className="list-item">
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <mask id="mask0_6630_47063" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                                <rect width="20" height="20" fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_6630_47063)">
                                                <path d="M9.7303 17.5C9.4943 17.5 9.28964 17.4133 9.1163 17.24C8.9423 17.066 8.8553 16.861 8.8553 16.625V11.979L5.2723 14.062C5.06364 14.1733 4.8413 14.2013 4.6053 14.146C4.3693 14.09 4.19564 13.958 4.0843 13.75L3.8133 13.271C3.70264 13.0623 3.67497 12.84 3.7303 12.604C3.78564 12.368 3.91764 12.1943 4.1263 12.083L7.7093 10L4.1053 7.917C3.8973 7.80567 3.76197 7.62833 3.6993 7.385C3.63664 7.14233 3.66797 6.92367 3.7933 6.729L4.1053 6.25C4.23064 6.05533 4.40764 5.92333 4.6363 5.854C4.86564 5.78467 5.08464 5.81267 5.2933 5.938L8.8553 8.042V3.375C8.8553 3.139 8.9423 2.934 9.1163 2.76C9.28964 2.58667 9.4943 2.5 9.7303 2.5H10.2723C10.5083 2.5 10.713 2.58667 10.8863 2.76C11.0603 2.934 11.1473 3.139 11.1473 3.375V8.042L14.7093 5.938C14.918 5.81267 15.137 5.78467 15.3663 5.854C15.595 5.92333 15.772 6.05533 15.8973 6.25L16.2093 6.729C16.3346 6.92367 16.366 7.14233 16.3033 7.385C16.2406 7.62833 16.1053 7.80567 15.8973 7.917L12.2933 10L15.8763 12.083C16.085 12.1943 16.2203 12.3717 16.2823 12.615C16.345 12.8577 16.314 13.0763 16.1893 13.271L15.8973 13.75C15.772 13.9447 15.595 14.0767 15.3663 14.146C15.137 14.2153 14.918 14.1873 14.7093 14.062L11.1473 11.979V16.625C11.1473 16.861 11.0603 17.066 10.8863 17.24C10.713 17.4133 10.5083 17.5 10.2723 17.5H9.7303Z" fill="#282E65" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="name">[Medical Condition]</div>
                                </div>
                                <div className="list-item">
                                    <div className="icon"> <img src={require("../../../../../../assets/img/vetRecorEMR/user-thumbnail.png")} /></div>
                                    <div className="name">Dr. Martha William</div>
                                </div>
                                <div className="list-item">
                                    <div className="icon"> <img src={require("../../../../../../assets/img/vetRecorEMR/user-thumbnail.png")} /></div>
                                    <div className="name">Dr. Martha William</div>
                                </div>
                                <div className="list-item">
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <mask id="mask0_6630_47063" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                                <rect width="20" height="20" fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_6630_47063)">
                                                <path d="M9.7303 17.5C9.4943 17.5 9.28964 17.4133 9.1163 17.24C8.9423 17.066 8.8553 16.861 8.8553 16.625V11.979L5.2723 14.062C5.06364 14.1733 4.8413 14.2013 4.6053 14.146C4.3693 14.09 4.19564 13.958 4.0843 13.75L3.8133 13.271C3.70264 13.0623 3.67497 12.84 3.7303 12.604C3.78564 12.368 3.91764 12.1943 4.1263 12.083L7.7093 10L4.1053 7.917C3.8973 7.80567 3.76197 7.62833 3.6993 7.385C3.63664 7.14233 3.66797 6.92367 3.7933 6.729L4.1053 6.25C4.23064 6.05533 4.40764 5.92333 4.6363 5.854C4.86564 5.78467 5.08464 5.81267 5.2933 5.938L8.8553 8.042V3.375C8.8553 3.139 8.9423 2.934 9.1163 2.76C9.28964 2.58667 9.4943 2.5 9.7303 2.5H10.2723C10.5083 2.5 10.713 2.58667 10.8863 2.76C11.0603 2.934 11.1473 3.139 11.1473 3.375V8.042L14.7093 5.938C14.918 5.81267 15.137 5.78467 15.3663 5.854C15.595 5.92333 15.772 6.05533 15.8973 6.25L16.2093 6.729C16.3346 6.92367 16.366 7.14233 16.3033 7.385C16.2406 7.62833 16.1053 7.80567 15.8973 7.917L12.2933 10L15.8763 12.083C16.085 12.1943 16.2203 12.3717 16.2823 12.615C16.345 12.8577 16.314 13.0763 16.1893 13.271L15.8973 13.75C15.772 13.9447 15.595 14.0767 15.3663 14.146C15.137 14.2153 14.918 14.1873 14.7093 14.062L11.1473 11.979V16.625C11.1473 16.861 11.0603 17.066 10.8863 17.24C10.713 17.4133 10.5083 17.5 10.2723 17.5H9.7303Z" fill="#282E65" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="name">Appointments</div>
                                </div>
                                <div className="list-item">
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <mask id="mask0_6630_47063" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                                <rect width="20" height="20" fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_6630_47063)">
                                                <path d="M9.7303 17.5C9.4943 17.5 9.28964 17.4133 9.1163 17.24C8.9423 17.066 8.8553 16.861 8.8553 16.625V11.979L5.2723 14.062C5.06364 14.1733 4.8413 14.2013 4.6053 14.146C4.3693 14.09 4.19564 13.958 4.0843 13.75L3.8133 13.271C3.70264 13.0623 3.67497 12.84 3.7303 12.604C3.78564 12.368 3.91764 12.1943 4.1263 12.083L7.7093 10L4.1053 7.917C3.8973 7.80567 3.76197 7.62833 3.6993 7.385C3.63664 7.14233 3.66797 6.92367 3.7933 6.729L4.1053 6.25C4.23064 6.05533 4.40764 5.92333 4.6363 5.854C4.86564 5.78467 5.08464 5.81267 5.2933 5.938L8.8553 8.042V3.375C8.8553 3.139 8.9423 2.934 9.1163 2.76C9.28964 2.58667 9.4943 2.5 9.7303 2.5H10.2723C10.5083 2.5 10.713 2.58667 10.8863 2.76C11.0603 2.934 11.1473 3.139 11.1473 3.375V8.042L14.7093 5.938C14.918 5.81267 15.137 5.78467 15.3663 5.854C15.595 5.92333 15.772 6.05533 15.8973 6.25L16.2093 6.729C16.3346 6.92367 16.366 7.14233 16.3033 7.385C16.2406 7.62833 16.1053 7.80567 15.8973 7.917L12.2933 10L15.8763 12.083C16.085 12.1943 16.2203 12.3717 16.2823 12.615C16.345 12.8577 16.314 13.0763 16.1893 13.271L15.8973 13.75C15.772 13.9447 15.595 14.0767 15.3663 14.146C15.137 14.2153 14.918 14.1873 14.7093 14.062L11.1473 11.979V16.625C11.1473 16.861 11.0603 17.066 10.8863 17.24C10.713 17.4133 10.5083 17.5 10.2723 17.5H9.7303Z" fill="#282E65" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="name">Prescriptions</div>
                                </div>
                                <div className="list-item">
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <mask id="mask0_6630_47063" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                                <rect width="20" height="20" fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_6630_47063)">
                                                <path d="M9.7303 17.5C9.4943 17.5 9.28964 17.4133 9.1163 17.24C8.9423 17.066 8.8553 16.861 8.8553 16.625V11.979L5.2723 14.062C5.06364 14.1733 4.8413 14.2013 4.6053 14.146C4.3693 14.09 4.19564 13.958 4.0843 13.75L3.8133 13.271C3.70264 13.0623 3.67497 12.84 3.7303 12.604C3.78564 12.368 3.91764 12.1943 4.1263 12.083L7.7093 10L4.1053 7.917C3.8973 7.80567 3.76197 7.62833 3.6993 7.385C3.63664 7.14233 3.66797 6.92367 3.7933 6.729L4.1053 6.25C4.23064 6.05533 4.40764 5.92333 4.6363 5.854C4.86564 5.78467 5.08464 5.81267 5.2933 5.938L8.8553 8.042V3.375C8.8553 3.139 8.9423 2.934 9.1163 2.76C9.28964 2.58667 9.4943 2.5 9.7303 2.5H10.2723C10.5083 2.5 10.713 2.58667 10.8863 2.76C11.0603 2.934 11.1473 3.139 11.1473 3.375V8.042L14.7093 5.938C14.918 5.81267 15.137 5.78467 15.3663 5.854C15.595 5.92333 15.772 6.05533 15.8973 6.25L16.2093 6.729C16.3346 6.92367 16.366 7.14233 16.3033 7.385C16.2406 7.62833 16.1053 7.80567 15.8973 7.917L12.2933 10L15.8763 12.083C16.085 12.1943 16.2203 12.3717 16.2823 12.615C16.345 12.8577 16.314 13.0763 16.1893 13.271L15.8973 13.75C15.772 13.9447 15.595 14.0767 15.3663 14.146C15.137 14.2153 14.918 14.1873 14.7093 14.062L11.1473 11.979V16.625C11.1473 16.861 11.0603 17.066 10.8863 17.24C10.713 17.4133 10.5083 17.5 10.2723 17.5H9.7303Z" fill="#282E65" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="name">Vaccine</div>
                                </div>
                                <div className="list-item">
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <mask id="mask0_6630_47063" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                                <rect width="20" height="20" fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_6630_47063)">
                                                <path d="M9.7303 17.5C9.4943 17.5 9.28964 17.4133 9.1163 17.24C8.9423 17.066 8.8553 16.861 8.8553 16.625V11.979L5.2723 14.062C5.06364 14.1733 4.8413 14.2013 4.6053 14.146C4.3693 14.09 4.19564 13.958 4.0843 13.75L3.8133 13.271C3.70264 13.0623 3.67497 12.84 3.7303 12.604C3.78564 12.368 3.91764 12.1943 4.1263 12.083L7.7093 10L4.1053 7.917C3.8973 7.80567 3.76197 7.62833 3.6993 7.385C3.63664 7.14233 3.66797 6.92367 3.7933 6.729L4.1053 6.25C4.23064 6.05533 4.40764 5.92333 4.6363 5.854C4.86564 5.78467 5.08464 5.81267 5.2933 5.938L8.8553 8.042V3.375C8.8553 3.139 8.9423 2.934 9.1163 2.76C9.28964 2.58667 9.4943 2.5 9.7303 2.5H10.2723C10.5083 2.5 10.713 2.58667 10.8863 2.76C11.0603 2.934 11.1473 3.139 11.1473 3.375V8.042L14.7093 5.938C14.918 5.81267 15.137 5.78467 15.3663 5.854C15.595 5.92333 15.772 6.05533 15.8973 6.25L16.2093 6.729C16.3346 6.92367 16.366 7.14233 16.3033 7.385C16.2406 7.62833 16.1053 7.80567 15.8973 7.917L12.2933 10L15.8763 12.083C16.085 12.1943 16.2203 12.3717 16.2823 12.615C16.345 12.8577 16.314 13.0763 16.1893 13.271L15.8973 13.75C15.772 13.9447 15.595 14.0767 15.3663 14.146C15.137 14.2153 14.918 14.1873 14.7093 14.062L11.1473 11.979V16.625C11.1473 16.861 11.0603 17.066 10.8863 17.24C10.713 17.4133 10.5083 17.5 10.2723 17.5H9.7303Z" fill="#282E65" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="name">Products</div>
                                </div>
                            </div>
                        </div>


                        <div className="list-section">
                            <div className="title">
                                Files & Documents
                            </div>
                            <div className="list">
                                <div className="list-item">
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M4.58203 0.833984C3.89168 0.833984 3.33203 1.39363 3.33203 2.08398V17.9173C3.33203 18.6077 3.89168 19.1673 4.58203 19.1673H15.4154C16.1057 19.1673 16.6654 18.6077 16.6654 17.9173V5.83398L11.6654 0.833984H4.58203Z" fill="#20A464" />
                                            <path d="M4.58203 19.1673C3.89168 19.1673 3.33203 18.6077 3.33203 17.9173V17.709C3.33203 18.3993 3.89168 18.959 4.58203 18.959H15.4154C16.1057 18.959 16.6654 18.3993 16.6654 17.709V17.9173C16.6654 18.6077 16.1057 19.1673 15.4154 19.1673H4.58203Z" fill="#149456" />
                                            <path d="M4.58203 0.833984C3.89168 0.833984 3.33203 1.39363 3.33203 2.08398V2.29232C3.33203 1.60196 3.89168 1.04232 4.58203 1.04232H11.8737L11.6654 0.833984H4.58203Z" fill="#38AE74" />
                                            <path d="M16.6681 10.1035V5.83268L15.9389 5.10352H12.4225C12.1441 5.10352 12.0047 5.44012 12.2015 5.63699L16.6681 10.1035Z" fill="url(#paint0_linear_4923_186195)" />
                                            <path d="M11.668 0.833984L16.668 5.83398H12.918C12.2276 5.83398 11.668 5.27434 11.668 4.58398V0.833984Z" fill="#8ED1B1" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3346 9.79102V15.8327H6.66797V9.79102H13.3346ZM9.58464 10.6243H7.5013V11.666H9.58464V10.6243ZM10.418 11.666V10.6243H12.5013V11.666H10.418ZM9.58464 12.291H7.5013V13.3327H9.58464V12.291ZM10.418 13.3327V12.291H12.5013V13.3327H10.418ZM9.58464 13.9577H7.5013V14.9993H9.58464V13.9577ZM10.418 14.9993V13.9577H12.5013V14.9993H10.418Z" fill="white" />
                                            <defs>
                                                <linearGradient id="paint0_linear_4923_186195" x1="14.3887" y1="10.1035" x2="14.3887" y2="4.99935" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#20A464" />
                                                    <stop offset="1" stop-color="#207E55" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </div>
                                    <div className="name">[Filename.extname]</div>
                                </div>
                                <div className="list-item">
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433286 8.00042 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9969 7.34877 18.9424 4.80702 17.0677 2.93232C15.193 1.05762 12.6512 0.00306424 10 0ZM13.5036 10.64L8.88822 13.7169C8.77241 13.7943 8.63775 13.8388 8.49864 13.8455C8.35952 13.8523 8.22118 13.8212 8.09838 13.7555C7.97558 13.6898 7.87294 13.5919 7.80142 13.4724C7.7299 13.3529 7.69219 13.2162 7.69231 13.0769V6.92308C7.69219 6.7838 7.7299 6.6471 7.80142 6.52759C7.87294 6.40807 7.97558 6.31023 8.09838 6.24451C8.22118 6.1788 8.35952 6.14767 8.49864 6.15446C8.63775 6.16124 8.77241 6.20569 8.88822 6.28305L13.5036 9.35997C13.609 9.43021 13.6954 9.52538 13.7551 9.63703C13.8149 9.74868 13.8462 9.87336 13.8462 10C13.8462 10.1266 13.8149 10.2513 13.7551 10.363C13.6954 10.4746 13.609 10.5698 13.5036 10.64Z" fill="#409DE0" />
                                        </svg>
                                    </div>
                                    <div className="name">[Filename.extname]</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default MessageFilter;


