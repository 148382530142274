import { CiCircleCheck } from "react-icons/ci";
import { IoIosCloseCircleOutline } from "react-icons/io";
import moment from "moment";
import "./DatePopover.scss";

const DatePopover = ({
  defaultValue,
  value,
  onChange,
  setEditData,
  handleSave,
}) => {
  return (
    <div id="date-popover-container" className="shadow">
      <div>
        <input type="datetime-local" value={value || ""} onChange={onChange} />
      </div>
      <div className="d-flex align-items-center justify-content-end mt-2 gap-2">
        <IoIosCloseCircleOutline
          size={20}
          color="red"
          className="cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setEditData(null);
          }}
        />
        <CiCircleCheck
          size={20}
          color="#11ca9d"
          className="cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (
              moment(defaultValue, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
                "YYYY-MM-DDTHH:mm"
              ) ===
              moment(value, "YYYY-MM-DDTHH:mm:ss.SSS[Z]").format(
                "YYYY-MM-DDTHH:mm"
              )
            ) {
              setEditData(null);
            } else {
              handleSave();
            }
          }}
        />
      </div>
    </div>
  );
};

export default DatePopover;
