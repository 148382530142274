

const initState = {
    reminders:[],
    loading: false
 }
 
 export const taskAndReminderReducer = (state = initState, action) => {
     switch (action.type) {
         case "GET_REMINDERS":
             return { ...state, reminders: action.payload }
        case "REMINDERS_LOADING":
            return  {...state, loading: action.payload}
         default:
             return state
     }
 }