import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Table, DropdownToggle, ButtonDropdown } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import CheckBox from "../../../../shared/CheckBox";
import UserPlaceholder from "../../../../shared/UserPlaceholder";

import PaginationBlock from "../../../../shared/PaginationBlock";
import { dateFromDateTime } from "../../../../utils/common";
import NodataFoundBlock from "../../../../shared/NoDataFoundBlock";
import LoaderUI from "../../../../shared/loader";
import { getPetOwnersListV2WithFilter } from "../../../../context/actions/patient-module/pet-owner";
import TableListPatientsFilterWrapper from "../../../../shared/TableListPatientsFilterWrapper";
import TableListExistingPetOwnerFilterWrapper from "./TableListExistingPetOwnerFilterWrapper";
import SelectExistingTable from "./SelectExistingTable";

const SelectExistingOwner = ({ handleChangePetOwner }) => {
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [filterParams, setFilterParams] = useState({
    searchText: "",
    city: "",
    species_id: "",
    gender: "",
    //status: ''
  });

  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const dispatch = useDispatch();
  const { clinic_settings } = useSelector(
    (state) => state.clinicSettingsReducer
  );

  const handleProfileClickOwner = (e, id) => {
    e.preventDefault();
    history.push("/pet-owner", {
      owner_id: id,
    });
  };

  const handleGetOwnerList = () => {
    dispatch(
      getPetOwnersListV2WithFilter(
        {
          page,
          limit,
          role: "pet-owner",
          clinic_id: clinic_settings.clinic_id,
          ...filterParams,
        },
        () => {
          // setPage();
        }
      )
    );
  };

	useEffect(() => {
		if(page !== 1){
			handleGetOwnerList()
		}
	}, [page, limit])

	useEffect(() => {
		if(page !== 1) setPage(1)
		handleGetOwnerList();
	}, [filterParams])
	


  return (
    <>
      <TableListExistingPetOwnerFilterWrapper
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />
      {/*<TableListPatientsFilterWrapper className="overflow-hidden" filterParams={filterParams} setFilterParams={setFilterParams} />*/}

      <SelectExistingTable
        page={page}
        limit={limit}
        setPage={setPage}
        setLimit={setLimit}
        filterParams={filterParams}
        handleChangePetOwner={handleChangePetOwner}
      />
    </>
  );
};

export default SelectExistingOwner;
